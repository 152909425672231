import React from 'react'
import PropTypes from 'prop-types'

import { ListItem, Text } from '@campaignhub/suit-theme'

import useProject from '@hooks/useProject'

const CompactList = (props) => {
  const {
    attachment: { data },
    project: { id },
  } = props

  const { title } = data || {}

  const { urls: { documentsUrl } } = useProject({ id })

  return (
    <ListItem
      boxProps={{
        as: 'a',
        borderBottom: '1px solid',
        paddingX: 'large',
        paddingY: 'medium',
      }}
      href={documentsUrl}
      showAngleIcon
    >
      <Text fontSize="small">{title}</Text>
    </ListItem>
  )
}

CompactList.propTypes = {
  attachment: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
}

export default CompactList
