import React from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'

import {
  SidebarModal,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useEntityExternalPlatforms from '@hooks/useEntityExternalPlatforms'
import useIntegrations from '@hooks/useIntegrations'
import useLocalization from '@hooks/useLocalization'

import AddOrEditPlatform from '../../components/AddOrEditPlatform'

import localizedStrings from './localizedStrings'

const handleSubmit = (platform, project, submitFn) => {
  const { externalId, externalPlatformKey } = platform
  const existingData = digObject(project, 'data', {})
  const { source_platform: existingSourcePlatform } = existingData

  const existingExternalIds = digObject(existingData, 'external_ids', {})
  const updatedExternalIds = { ...existingExternalIds }

  delete updatedExternalIds[existingSourcePlatform]

  const formattedExternalId = isNaN(externalId) ? externalId : Number(externalId)

  if (formattedExternalId) updatedExternalIds[externalPlatformKey] = formattedExternalId

  const updatedData = {
    ...existingData,
    external_ids: updatedExternalIds,
    source_platform: externalPlatformKey,
  }

  submitFn({ id: project.id, data: updatedData })
}

const confirmSave = (platform, project, submitFn, strings) => {
  swal
    .fire({
      title: strings.swal?.title || 'Save Platform Details?',
      text: strings.swal?.text || 'Are you sure? Any incorrect details will affect future project imports.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: strings.swal?.confirmButtonText || 'Yes, Save it.',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        handleSubmit(platform, project, submitFn)
      }
    })
}

const SourcePlatform = (props) => {
  const {
    callbacks,
    callbacks: { submitFn },
    project,
    updating,
  } = props

  const { sourcePlatform } = useEntityExternalPlatforms(project)

  const {
    filteredPlatforms,
  } = useIntegrations({
    featureKeys: ['import_projects'],
    filters: {
      owner_id: project.organization_id,
      owner_type: 'Organization',
    },
  })

  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.sideBarModalHeader?.title || 'Source'}
        titleSecondLine={strings?.sideBarModalHeader.titleSecondLine || 'Platform'}
      />

      <AddOrEditPlatform
        callbacks={{
          handleSave: platform => confirmSave(platform, project, submitFn, strings),
          removePlatform: () => handleSubmit({}, project, submitFn),
        }}
        deps={[sourcePlatform.externalId]}
        entity={sourcePlatform}
        loading={updating}
        platforms={filteredPlatforms}
      />

    </>
  )
}

SourcePlatform.propTypes = {
  callbacks: PropTypes.shape({
    closeModal: PropTypes.func.isRequired,
    submitFn: PropTypes.func.isRequired,
  }).isRequired,
  project: PropTypes.object.isRequired,
  updating: PropTypes.bool.isRequired,
}

export default SourcePlatform
