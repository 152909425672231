import { useMemo } from 'react'

import { useModals, useSetState } from '@campaignhub/react-hooks'
import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import useBrandSelector from '@hooks/useBrandSelector'
import useOrganizationSelector from '@hooks/useOrganizationSelector'
import useReduxAction from '@hooks/useReduxAction'

import handleCallbackAction from '@functions/handleCallbackAction'

import CreateOrEditAssetLibraryModal from '@modals/CreateOrEditAssetLibraryModal'
import EditImageModal from '@modals/EditImageModal'
import ImportAssetLibrariesModal from '@modals/ImportAssetLibrariesModal'

import PageContent from './components/PageContent'

const requestOptions = {
  include_asset_library_default_image: true,
  include_asset_library_owner_title: true,
  include_image_sizes: ['thumb_200x120', 'thumb_660x360'],
}

const callbacks = (component, setState) => {
  const componentCallbacks = {
    CreateOrEditAssetLibraryModal: {
      closeModal: () => setState({ showCreateOrEditAssetLibraryModal: false }),
      createAssetLibrary: payload => handleCallbackAction(payload),
      deleteAssetLibrary: payload => handleCallbackAction(payload),
      updateAssetLibrary: payload => handleCallbackAction(payload),
    },
    EditImageModal: {
      closeModal: () => setState({ showEditImageModal: false }),
      deleteImage: payload => handleCallbackAction(payload),
    },
    ImportAssetLibrariesModal: {
      closeModal: () => setState({ showImportAssetLibrariesModal: false }),
      importImageLibraries: payload => handleCallbackAction(payload),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  showCreateOrEditAssetLibraryModal: false,
  showEditImageModal: false,
  showImportAssetLibrariesModal: false,
}

const ImageLibraries = () => {
  const [state, setState] = useSetState(defaultState)
  const { showCreateOrEditAssetLibraryModal, showEditImageModal, showImportAssetLibrariesModal } = state

  const { selectedOrganization } = useOrganizationSelector()
  const { selectedBrand } = useBrandSelector()

  useReduxAction(
    'assetLibraries',
    'loadAssetLibraries',
    {
      ...requestOptions,
      asset_type: 'image',
    },
    [],
  )

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const pageContext = useMemo(() => ({
    callbacks: {
      showCreateOrEditAssetLibraryModal: (payload) => {
        setModalData('CreateOrEditAssetLibraryModal', payload)
        setState({ showCreateOrEditAssetLibraryModal: true })
      },
      showEditImageModal: (payload) => {
        setModalData('EditImageModal', payload)
        setState({ showEditImageModal: true })
      },
      showImportAssetLibrariesModal: () => setState({ showImportAssetLibrariesModal: true }),
    },
  }), [modalContext])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent brand={selectedBrand} organization={selectedOrganization} />

        <CreateOrEditAssetLibraryModal
          callbacks={callbacks('CreateOrEditAssetLibraryModal', setState)}
          showModal={showCreateOrEditAssetLibraryModal}
        />

        <EditImageModal
          callbacks={callbacks('EditImageModal', setState)}
          showModal={showEditImageModal}
        />

        <ImportAssetLibrariesModal
          callbacks={callbacks('ImportAssetLibrariesModal', setState)}
          showModal={showImportAssetLibrariesModal}
        />

      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default ImageLibraries
