import React from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTrashAlt } from '@fortawesome/pro-solid-svg-icons'

import {
  Box, Image, Link, ListItem, Text,
} from '@campaignhub/suit-theme'

import useDigitalTemplatePageSection from '@hooks/useDigitalTemplatePageSection'
import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const confirmDelete = (section, deleteFn, strings = {}) => {
  swal
    .fire({
      title: strings.sweetAlert?.title || 'Delete Section?',
      text: strings.sweetAlert?.text || 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: strings.sweetAlert?.confirm || 'Yes, delete it.',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        deleteFn(section)
      }
    })
}

const DraggableSection = React.forwardRef((props, ref) => {
  const {
    callbacks: { deleteEntity },
    dragHandleProps,
    dragSnapshot,
    draggableProps,
    sectionId,
  } = props

  const { digitalTemplatePageSection, options: { anchor, title } } = useDigitalTemplatePageSection({ id: sectionId })

  const { isDragging } = dragSnapshot || {}

  const { strings } = useLocalization(localizedStrings)

  return (
    <ListItem
      alignItems="center"
      boxProps={{
        borderBottom: '1px dashed',
        alignItems: 'center',
        paddingY: 'large',
      }}
      disableHover
      flexShrink={0}
      forwardProps={{
        ...draggableProps,
        ...dragHandleProps,
      }}
      isDragging={isDragging}
      ref={ref}
    >
      <Box alignItems="center" justifyContent="center" flexShrink={0} height={35} width={35} marginRight="large">
        <Image boxProps={{ backgroundColor: 'hoverLightGrey' }} circle width={35}>
          <FontAwesomeIcon icon={faBars} />
        </Image>
      </Box>

      <Box flexDirection="column" justifyContent="center">
        <Text color="bodyFontColor" fontSize="small">
          {title || anchor || sectionId}
        </Text>

        <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="small">
          {`ID# ${sectionId}`}
        </Text>
      </Box>
      <Box justifyContent="right" marginRight="medium">
        <Link greyLink onClick={() => confirmDelete(digitalTemplatePageSection, deleteEntity, strings)}>
          <FontAwesomeIcon icon={faTrashAlt} />
        </Link>
      </Box>
    </ListItem>
  )
})

DraggableSection.propTypes = {
  dragHandleProps: PropTypes.object,
  dragSnapshot: PropTypes.object,
  draggableProps: PropTypes.object,
  sectionId: PropTypes.string.isRequired,
}

export default DraggableSection
