import React from 'react'
import PropTypes from 'prop-types'

import { Box, ImageWithButton } from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useImages from '@hooks/useImages'

import placeholderImageUrl from './assets/organization-image.svg'

const OrganizationImage = (props) => {
  const { organization, image } = props

  const {
    callbacks: { selectImage },
  } = useImages({ subjectId: organization.id, subjectType: 'Organization' })

  const defaultImageUrl = digObject(image, 'sizes.thumb_660x360', '')

  return (
    <Box
      backgroundColor="whiteGrey"
      border="1px solid"
      borderColor="lineColor"
      borderTopLeftRadius={5}
      borderTopRightRadius={5}
      flexDirection="column"
    >
      <ImageWithButton
        onClick={selectImage}
        placeholderUrl={placeholderImageUrl}
        url={defaultImageUrl}
      />
    </Box>
  )
}

OrganizationImage.propTypes = {
  image: PropTypes.object,
  organization: PropTypes.object.isRequired,
}

export default OrganizationImage
