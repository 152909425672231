import { digObject, toggleArray } from '@campaignhub/javascript-utils'
import { useSetState } from '@campaignhub/react-hooks'

type CustomFieldOptionsType = {
  input_type: string,
  option_values: [],
  tags: string[],
  width: number,
}

type EntityState = {
  [key: string]: any,
}

type SetEntityState= (state: EntityState) => void

const toggleTag = (
  tag: string,
  tags: string[],
  customFieldOptions: CustomFieldOptionsType,
  setEntityState: SetEntityState,
) => {
  const updatedOptions = {
    ...customFieldOptions,
    tags: toggleArray(tags, tag),
  }

  setEntityState({ options: updatedOptions })
}

const defaultState = {
  value: '',
}

type OptionsType = {
  setEntityState?: SetEntityState,
}

function useCustomFieldTags(customFieldOptions: CustomFieldOptionsType, options: OptionsType = {}) {
  const { setEntityState } = options

  const [state, setState] = useSetState(defaultState)
  const { value } = state

  const tags: string[] = digObject(customFieldOptions, 'tags', [])

  const tagExists = tags.some(tag => tag === value)

  return {
    callbacks: {
      setState,
      toggleTag: (tag: string) => toggleTag(tag, tags, customFieldOptions, setEntityState),
    },
    state,
    tagExists,
    tags,
  }
}

export default useCustomFieldTags
