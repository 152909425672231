import { useContext } from 'react'

import { digObject } from '@campaignhub/javascript-utils'

import { useThunkDispatch } from '@campaignhub/react-hooks'

import * as searchActions from '@redux/modules/search'

import SectionContext from '@contexts/sectionContext'

const generateUrl = (entity, isAdmin) => {
  const { id, type } = entity || {}

  if (!id || !type) return null

  if (isAdmin){
    const camelCaseType = `${type[0].toLowerCase() + type.slice(1)}s`

    if (camelCaseType === 'digitalTemplates') return `#/digitalTemplates/${id}/edit`
    if (camelCaseType === 'organizations') return `#/systemManager/organizations/${id}/edit`
    return `#/systemManager/${camelCaseType}/${id}/edit`
  }
  return `#/projects/${id}` // Client side can only search projects
}

const mapResults = (result, generateUrlFn) => {
  const {
    brands, digital_templates, organizations, projects, users,
  } = result || {}

  const presentResults = []
  const emptyResults = []

  if (brands){
    if (brands.length){
      presentResults.push({
        items: brands.map(brand => ({
          canLogin: false,
          entityType: 'Brand',
          href: generateUrlFn(brand),
          id: brand.id,
          key: brand.id,
          subtitle: null,
          title: brand.title,
        })),
        title: 'Brands',
      })
    } else {
      emptyResults.push({
        items: [{
          id: -1,
          key: -1,
          subtitle: 'Try searching again',
          title: 'No Brands found',
          priority: 0,
        }],
        title: 'Brands',
      })
    }
  }

  if (digital_templates){
    if (digital_templates.length){
      presentResults.push({
        items: digital_templates.map(digitalTemplate => ({
          canLogin: false,
          entityType: 'DigitalTemplate',
          href: generateUrlFn(digitalTemplate),
          id: digitalTemplate.id,
          key: digitalTemplate.id,
          subtitle: digitalTemplate.subtitle,
          title: digitalTemplate.title,
        })),
        title: 'Digital Templates',
      })
    } else {
      emptyResults.push({
        items: [{
          id: -1,
          key: -1,
          subtitle: 'Try searching again',
          title: 'No Digital Templates found',
          priority: 0,
        }],
        title: 'Digital Templates',
      })
    }
  }

  if (organizations){
    if (organizations.length){
      presentResults.push({
        items: organizations.map(organization => ({
          entityType: 'Organization',
          href: generateUrlFn(organization),
          id: organization.id,
          key: organization.id,
          canLogin: organization.onboarding,
          organizationId: organization.id,
          subtitle: organization.subtitle,
          title: organization.title,
        })),
        title: 'Organizations',
      })
    } else {
      emptyResults.push({
        items: [{
          id: -1,
          key: -1,
          subtitle: 'Try searching again',
          title: 'No Organizations found',
          priority: 0,
        }],
        title: 'Organizations',
      })
    }
  }

  if (projects){
    if (projects.length){
      presentResults.push({
        items: projects.map(project => ({
          canLogin: false,
          entityType: 'Project',
          href: generateUrlFn(project),
          id: project.id,
          key: project.id,
          priority: project.priority,
          subtitle: project.subtitle,
          title: project.title,
        })),
        title: 'Projects',
      })
    } else {
      emptyResults.push({
        items: [{
          id: -1,
          key: -1,
          subtitle: 'Try searching again',
          title: 'No Projects found',
          priority: 0,
        }],
        title: 'Projects',
      })
    }
  }

  if (users){
    if (users.length){
      presentResults.push({
        items: users.map(user => ({
          canLogin: false,
          entityType: 'User',
          href: generateUrlFn(user),
          id: user.id,
          key: user.id,
          subtitle: user.subtitle,
          title: user.title,
        })),
        title: 'Users',
      })
    } else {
      emptyResults.push({
        items: [{
          id: -1,
          key: -1,
          subtitle: 'Try searching again',
          title: 'No Users found',
          priority: 0,
        }],
        title: 'Users',
      })
    }
  }

  // Final failsafe if no searchEntities were specified
  // User should never see this because DashboardSearch should always be passed searchEntities
  if (!brands && !digital_templates && !organizations && !projects && !users){
    emptyResults.push({
      items: [{
        id: -1,
        key: -1,
        subtitle: 'Try searching again',
        title: 'No Results found',
        priority: 0,
      }],
      title: 'Results',
    })
  }

  return { groups: [...presentResults, ...emptyResults] }
}

const dashboardSearch = (options, searchEntities, dispatch) => {
  const string = digObject(options, 'string', '')

  if (string.length > 2){
    const { dashboardSearch: dashboardSearchFn } = searchActions

    const params = {
      hide_hidden_projects: true,
      search_entities: searchEntities,
      string,
    }

    return dispatch(dashboardSearchFn(params))
  }
  return Promise.resolve({ success: false })
}

const useDashboardSearch = (params = {}) => {
  const { searchEntities } = params

  const sectionContext = useContext(SectionContext)
  const { isAdmin } = sectionContext

  const generateUrlFn = entity => generateUrl(entity, isAdmin)

  const dispatch = useThunkDispatch()

  return {
    callbacks: {
      dashboardSearch: options => dashboardSearch(options, searchEntities, dispatch),
      mapResults: data => mapResults(data, generateUrlFn),
    },
  }
}

export default useDashboardSearch
