function buildMinMaxValues(key, filter){
  const values = {}

  const minKey = `min_${key}`
  const maxKey = `max_${key}`

  if (!filter) return values

  if (filter.value) values[minKey] = filter.value
  if (filter.valueMax) values[maxKey] = filter.valueMax

  return values
}

export default buildMinMaxValues
