import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBuilding, faHome, faStar, faStars, faUser, faUsers,
} from '@fortawesome/pro-light-svg-icons'

import { Box, ListItem, Text } from '@campaignhub/suit-theme'

import { camelCaseToTitleCase } from '@campaignhub/javascript-utils'

import useCustomFieldSet from '@hooks/useCustomFieldSet'
import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const icons = {
  CaseStudy: faStars,
  Contact: faUser,
  Organization: faBuilding,
  Project: faHome,
  ProjectComparable: faHome,
  Review: faStar,
  User: faUsers,
}

const CustomFieldSet = (props) => {
  const {
    fieldSet,
    fieldSet: {
      entity_type, id, title, owner_type,
    },
  } = props

  const { customFieldCount, urls: { editCustomFieldSetUrl } } = useCustomFieldSet(fieldSet)

  const {
    callbacks: { formatCount },
  } = useLocalization(localizedStrings)

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        as: 'a',
        borderBottom: '1px dashed',
        color: 'inherit',
        padding: 'large',
      }}
      href={editCustomFieldSetUrl}
      key={id}
      showAngleIcon
    >
      <Box
        alignItems="center"
        backgroundColor="backgroundColor"
        borderRadius={5}
        color="bodyFontLightColor"
        flexShrink={0}
        height={40}
        justifyContent="center"
        position="relative"
        width={40}
      >
        <FontAwesomeIcon icon={icons[entity_type] || faUsers} />
      </Box>

      <Box alignItems="center">
        <Box flexDirection="column" marginLeft="large">
          <Text color="bodyFontColor" fontSize="small">
            {title}
          </Text>

          <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="medium">
            {formatCount('customFields', { count: customFieldCount })}
          </Text>
        </Box>

        <Box display={['none', 'flex']} flexDirection="column" justifyContent="flex-start" width="180px">
          <Text color="bodyFontLightColor" fontSize="small" style={{ whiteSpace: 'nowrap' }}>
            {camelCaseToTitleCase(entity_type)}
          </Text>

          <Text color="faintGrey" fontSize="xxsmall" marginTop="medium" style={{ whiteSpace: 'nowrap' }}>
            {owner_type}
          </Text>
        </Box>
      </Box>
    </ListItem>
  )
}

CustomFieldSet.propTypes = {
  fieldSet: PropTypes.object.isRequired,
}

export default CustomFieldSet
