export default {
  default: {
    blankState: {
      title: 'No Templates',
      firstParagraph: 'Please contact support to get a',
      secondParagraph: 'template created or assigned to your account.',
    },
    button: 'Customise Templates',
  },
}
