import { useContext } from 'react'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { faFile } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Columns, DashboardModule, LoadingModule, PageHeader,
} from '@campaignhub/suit-theme'

import { sortArrayBy } from '@campaignhub/javascript-utils'

import PageContext from '@contexts/pageContext'

import useContact from '@hooks/useContact'
import useContacts from '@hooks/useContacts'
import useLocalization from '@hooks/useLocalization'
import useOrganizationSelector from '@hooks/useOrganizationSelector'
import useReduxAction from '@hooks/useReduxAction'
import useUserTypes from '@hooks/useUserTypes'

import PageFilterModule from '@components/PageFilterModule'

import ContactBlankState from '../ContactBlankState'

import localizedStrings from './localizedStrings'
import Contact from '../Contact'

const buildPageFilterFields = (strings, filteredUserTypes, users) => {
  const style = {
    marginBottom: 16,
  }

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        style,
      },
      key: 'string',
      label: strings.filters?.filterByTitle || 'Filter by Title',
    },
    {
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: strings.defaultSelectLabel || 'All',
        inputComponent: 'select',
        style,
      },
      key: 'user_type',
      label: strings.filters?.contactType || 'Contact Type',
      values: sortArrayBy(filteredUserTypes, 'asc', 'title').map(user_type => ({
        key: user_type.id,
        label: user_type.title,
      })),
    },
    {
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: strings.filters?.defaultSelectLabel || 'All',
        inputComponent: 'select',
        style,
      },
      key: 'user_id',
      label: strings.filters?.user || 'Users',
      values: sortArrayBy(Object.values(users), 'asc', 'full_name').map(user => ({
        key: user.id,
        label: user.full_name,
      })),
    },
    {
      entityKey: 'limit',
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: '100',
        inputComponent: 'select',
        style,
      },
      key: 'limit',
      label: strings.limitLabel || 'Limit',
      values: [
        { key: 150, label: '150' },
        { key: 200, label: '200' },
      ],
    },
  ]
}

const PageContent = (props) => {
  const { pageFilters } = props

  const { selectedOrganization } = useOrganizationSelector()

  const contactsPayload = useContacts({
    filters: {
      owner_id: selectedOrganization?.id,
      owner_type: 'Organization',
      ...pageFilters,
    },
    performHttpRequests: true,
  })

  const {
    callbacks: { loadMore }, canLoadMore, filteredContacts, hasContacts, loading,
  } = contactsPayload

  const pageContext = useContext(PageContext)
  const {
    callbacks: { resetFilters, updateFilters },
  } = pageContext

  useReduxAction('users', 'loadUsers', { }, [])
  const { users } = useSelector(reduxState => reduxState.entities)

  const contactPayload = useContact({ owner_id: selectedOrganization?.id, owner_type: 'Organization' })
  const {
    callbacks: { createOrEditContact },
  } = contactPayload

  const userTypesPayload = useUserTypes({ filters: { subjectType: 'Contact' } })
  const { filteredUserTypes } = userTypesPayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      <PageHeader
        actionContent={(
          <Button buttonStyle="primaryCreate" onClick={() => createOrEditContact({})} size="medium" width="auto">
            {strings.createButton || 'Create'}
          </Button>
        )}
        activeTabBarItemKey="contacts"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        tabBarItems={[
          {
            href: '#/overviews/contacts',
            icon: faFile,
            key: 'contacts',
            title: strings.title || 'Contacts',
          },
        ]}
        title={strings.title || 'Contacts'}
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && !hasContacts && <ContactBlankState />}

            {!loading
              && hasContacts
              && (
              <DashboardModule
                key="contacts"
                title={strings.title || 'Contacts'}
              >
                <Box flexDirection="column">
                  <Box flexDirection="column">
                    {filteredContacts.map(contact => (
                      <Contact contact={contact} />
                    ))}
                  </Box>

                  <DashboardModule.LoadMoreFooter
                    callbacks={{ loadMore }}
                    canLoadMore={canLoadMore}
                    entityCount={filteredContacts.length}
                    loading={loading}
                  />
                </Box>
              </DashboardModule>
              )}
          </Columns.Main>

          <Columns.Sidebar>
            <PageFilterModule
              callbacks={{ resetFilters, updateFilters }}
              filterFields={buildPageFilterFields(strings, filteredUserTypes, users)}
              pageFilters={pageFilters}
              title={strings.pageFilterModuleTitle || 'Filters'}
            />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

PageContent.propTypes = {
  pageFilters: PropTypes.object.isRequired,
}

export default PageContent
