import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import PropTypes from 'prop-types'

import { faCheckCircle, faGlobe, faPencil } from '@fortawesome/pro-light-svg-icons'

import { getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'
import {
  Box,
  Button,
  Columns,
  DashboardModule,
  LoadingModule,
  PageHeader,
} from '@campaignhub/suit-theme'

import PageFilterModule from '@components/PageFilterModule'

import PageContext from '@contexts/pageContext'

import useDocumentTemplates from '@hooks/useDocumentTemplates'
import { generateUrls } from '@hooks/useDocumentTemplate'
import useLocalization from '@hooks/useLocalization'

import DocumentTemplate from '../DocumentTemplate'
import DocumentTemplatesBlankState from '../DocumentTemplatesBlankState'

import localizedStrings from './localizedStrings'

const buildPageFilterFields = (strings) => {
  const style = {
    marginBottom: 16,
  }

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        style,
      },
      key: 'string',
      label: strings.filters?.title || 'Filter by Title',
    },
  ]
}

const PageContent = (props) => {
  const { pageFilters, selectedOrganization } = props

  useLocation()

  const { status } = parsePermittedQueryParams(getQueryParamsFromHash(), ['status'])
  const currentStatus = status || 'all'

  const entityKey = currentStatus === 'published' ? 'DocumentTemplates-ProductionReady' : 'DocumentTemplates-Draft'

  const pageContext = useContext(PageContext)
  const {
    callbacks: {
      resetFilters, showCreateDocumentTemplateModal, updateFilters,
    },
  } = pageContext

  const {
    filteredDocumentTemplates,
    hasDocumentTemplates,
    loading,
  } = useDocumentTemplates({
    entityKey,
    ownerId: selectedOrganization.id,
    ownerType: 'Organization',
    pageFilters,
    performHttpRequests: true,
    production_ready: currentStatus !== 'all' ? currentStatus === 'published' : undefined,
  })

  const {
    documentTemplatesIndexUrl,
    draftDocumentTemplates,
    publishedDocumentTemplates,
  } = generateUrls()

  const { strings } = useLocalization(localizedStrings)

  const title = strings.title ? `${strings.title || 'Document Templates'} | Engage` : 'Engage'

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <PageHeader
        activeTabBarItemKey={currentStatus}
        actionContent={(
          <Button buttonStyle="primaryCreate" onClick={showCreateDocumentTemplateModal} size="medium">
            {strings.createButton || 'Create'}
          </Button>
        )}
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        nestedNavigation
        tabBarItems={[
          {
            href: documentTemplatesIndexUrl,
            icon: faGlobe,
            key: 'all',
            title: strings.tabBarItems?.all || 'All',
          },
          {
            href: publishedDocumentTemplates,
            icon: faCheckCircle,
            key: 'published',
            title: strings.tabBarItems?.published || 'Published',
          },
          {
            href: draftDocumentTemplates,
            icon: faPencil,
            key: 'draft',
            title: strings.tabBarItems?.draft || 'Draft',
          },
        ]}
        title={strings.title || 'Document Templates'}
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && !hasDocumentTemplates && <DocumentTemplatesBlankState />}

            {!loading && hasDocumentTemplates && (
              <DashboardModule title={strings.title || 'Document Templates'}>
                <Box flexDirection="column">
                  {filteredDocumentTemplates.map(documentTemplate => (
                    <DocumentTemplate key={documentTemplate.id} documentTemplate={documentTemplate} />
                  ))}
                </Box>
              </DashboardModule>
            )}
          </Columns.Main>

          <Columns.Sidebar>
            <PageFilterModule
              callbacks={{ resetFilters, updateFilters }}
              filterFields={buildPageFilterFields(strings)}
              pageFilters={pageFilters}
              title={strings.filtersTitle || 'Filters'}
            />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

PageContent.propTypes = {
  pageFilters: PropTypes.object.isRequired,
  selectedOrganization: PropTypes.object.isRequired,
}

export default PageContent
