import React from 'react'
import PropTypes from 'prop-types'

import { ListItem, Text } from '@campaignhub/suit-theme'

import useComparable from '@hooks/useComparable'
import useProject from '@hooks/useProject'

import { capitalizeString } from '@functions/string'

const CompactList = (props) => {
  const {
    comparable,
    comparable: {
      source,
      project_id,
    },
  } = props

  const {
    fullAddress,
  } = useComparable(comparable)

  const { urls: { comparablesUrl } } = useProject({ id: project_id })

  return (
    <ListItem
      boxProps={{
        as: 'a',
        borderBottom: '1px solid',
        paddingX: 'large',
        paddingY: 'medium',
      }}
      href={comparablesUrl}
      showAngleIcon
    >
      <Text fontSize="small" marginRight="auto">
        {fullAddress}
      </Text>

      <Text color="bodyFontLightColor" fontSize="small">
        {capitalizeString(source)}
      </Text>
    </ListItem>
  )
}

CompactList.propTypes = {
  comparable: PropTypes.object.isRequired,
}

export default CompactList
