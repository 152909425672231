import { useSetState } from '@campaignhub/react-hooks'

const defaultState = {
  disclaimerAccepted: false,
}

function useIntegrationAuthorization(){
  const [state, setState] = useSetState(defaultState)
  const { disclaimerAccepted } = state

  return {
    callbacks: {
      toggleDisclaimerAcceptance: () => setState({ disclaimerAccepted: !disclaimerAccepted }),
    },
    disclaimerAccepted,
  }
}

export default useIntegrationAuthorization
