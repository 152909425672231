import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { faPencil } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Link, ListItem, Text,
} from '@campaignhub/suit-theme'

import useQuote from '@hooks/useQuote'

import generateRedirectUrl from '@functions/generateRedirectUrl'

const Quote = (props) => {
  const {
    callbacks: { formatCurrency, toggleQuote },
    quote,
    quote: { id, title, total },
    selected,
    selectedIndex,
  } = props

  const {
    urls: { editQuoteUrl },
  } = useQuote(quote)

  const externalQuotesUrl = `${editQuoteUrl}?redirect=${generateRedirectUrl({
    append: '&showModal=ManageEntityQuotesModal',
  })}`

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        borderBottom: '1px dashed',
        lineHeight: '1.3',
        paddingY: 'large',
      }}
      disableHover
      key={quote.id}
    >
      <Box paddingY="medium" marginRight="large" width="auto">
        <Box
          alignItems="center"
          backgroundColor={selected ? 'blue' : 'transparent'}
          border="1px solid"
          borderColor={selected ? 'blue' : 'lineColor'}
          borderRadius="100%"
          height={28}
          justifyContent="center"
          onClick={toggleQuote}
          style={{ cursor: 'pointer' }}
          width={28}
        >
          <Text color={selected ? 'white' : 'lineColor'} fontSize="xsmall">
            {selected ? selectedIndex + 1 : <FontAwesomeIcon icon={faCheck} />}
          </Text>
        </Box>
      </Box>

      <Box alignItems={['flex-start', 'center']} flexDirection={['column', 'row']}>
        <Box flexDirection="column" lineHeight="1.3" marginRight="auto" width="auto">
          <Text fontSize="small">{title}</Text>
          <Text color="bodyFontLightColor" fontSize="xsmall">
            {`ID #${id} - ${formatCurrency(total)}`}
          </Text>
        </Box>
      </Box>

      <Link href={externalQuotesUrl} greyLink textProps={{ fontSize: 'small' }}>
        <FontAwesomeIcon icon={faPencil} />
      </Link>
    </ListItem>
  )
}

Quote.propTypes = {
  callbacks: PropTypes.shape({
    formatCurrency: PropTypes.func.isRequired,
    toggleQuote: PropTypes.func.isRequired,
  }),
  quote: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  selectedIndex: PropTypes.number.isRequired,
}

export default Quote
