import React, { useContext, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

import PageContext from '@contexts/pageContext'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faEdit, faHome } from '@fortawesome/pro-light-svg-icons'

import { useSetState } from '@campaignhub/react-hooks'
import { digObject, getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'

import {
  Box, Button, CustomizableDashboard, IconWithMenu, PageHeader,
} from '@campaignhub/suit-theme'

import useCurrentUser from '@hooks/useCurrentUser'
import useDigitalPage from '@hooks/useDigitalPage'
import useLocalization from '@hooks/useLocalization'
import useOrganizationSelector from '@hooks/useOrganizationSelector'
import useProject, { useRelations } from '@hooks/useProject'
import useUser from '@hooks/useUser'

// ---- Dashboard Modules ----

import Performance, {
  configOptions as performanceConfigOptions,
  icon as performanceIcon,
} from '@sections/Client/components/DashboardModules/analytics/Performance'

import Comparables, {
  configOptions as comparablesConfigOptions,
  icon as comparablesIcon,
} from '@sections/Client/components/DashboardModules/Comparables'

import DigitalPages, {
  configOptions as digitalPageConfigOptions,
  icon as digitalPagesIcon,
} from '@sections/Client/components/DashboardModules/DigitalPages'

import Documents, {
  configOptions as documentConfigOptions,
  icon as documentsIcon,
} from '@sections/Client/components/DashboardModules/Documents'

import EventCalendars, {
  configOptions as eventCalendarsConfigOptions,
  icon as eventCalendarsIcon,
} from '@sections/Client/components/DashboardModules/Events'

import Media, {
  configOptions as mediaConfigOptions,
  icon as mediaIcon,
} from '@sections/Client/components/DashboardModules/Media'

import Quotes, {
  configOptions as quotesConfigOptions,
  icon as quotesIcon,
} from '@sections/Client/components/DashboardModules/Quotes'

// ---- End Dashboard Modules ----

import CreateProjectItemModal from '@modals/CreateProjectItemModal'

import Content from './components/Content'
import ContentSidebar from './components/ContentSidebar'
import localizedStrings from './localizedStrings'

const generateAvailableModules = (projectId, strings) => ({
  Performance: {
    component: Performance,
    configOptions: performanceConfigOptions(strings),
    icon: performanceIcon,
    id: 'Performance',
    mergeConfig: {
      queryParams: { entity_type: 'Project', entity_id: projectId },
    },
    title: strings.performance || 'Performance',
  },
  Comparables: {
    component: Comparables,
    configOptions: comparablesConfigOptions(strings),
    icon: comparablesIcon,
    id: 'Comparables',
    mergeConfig: {
      queryParams: { subject_type: 'Project', subject_id: projectId },
    },
    title: strings.comparablesTitle || 'Comparables',
  },
  DigitalPages: {
    component: DigitalPages,
    configOptions: digitalPageConfigOptions(strings),
    icon: digitalPagesIcon,
    id: 'DigitalPages',
    mergeConfig: {
      queryParams: { subject_type: 'Project', subject_id: projectId },
    },
    title: strings.digitalPagesTitle || 'Digital Pages',
  },
  Documents: {
    component: Documents,
    configOptions: documentConfigOptions(strings),
    icon: documentsIcon,
    id: 'Documents',
    mergeConfig: {
      queryParams: { subject_type: 'Project', subject_id: projectId },
    },
    title: strings.documentsTitle || 'Documents',
  },
  EventCalendars: {
    component: EventCalendars,
    configOptions: eventCalendarsConfigOptions(strings),
    icon: eventCalendarsIcon,
    id: 'EventCalendars',
    mergeConfig: {
      queryParams: { subject_type: 'Project', subject_id: projectId },
    },
    title: strings.eventCalendarsTitle || 'Event Calendars',
  },
  Media: {
    component: Media,
    configOptions: mediaConfigOptions(strings),
    icon: mediaIcon,
    id: 'Media',
    mergeConfig: {
      queryParams: { subject_type: 'Project', subject_id: projectId },
    },
    title: strings.imagesTitle || 'Media',
  },
  Quotes: {
    component: Quotes,
    configOptions: quotesConfigOptions(strings),
    icon: quotesIcon,
    id: 'Quotes',
    mergeConfig: {
      queryParams: { subject_type: 'Project', subject_id: projectId },
    },
    title: strings.quotesTitle || 'Quotes',
  },
})

const dashboardDefaultLayout = strings => ({
  columns: {
    main: {
      id: 'main',
      modules: [
        {
          key: 'DigitalPages-1',
          moduleId: 'DigitalPages',
        },
        {
          key: 'EventCalendars-1',
          moduleId: 'EventCalendars',
        },
        {
          key: 'Documents-1',
          moduleId: 'Documents',
        },
      ],
    },
    sidebar: {
      id: 'sidebar',
      modules: [
        {
          key: 'Performance-1',
          moduleId: 'Performance',
        },
        {
          key: 'Media-1',
          moduleId: 'Media',
        },
      ],
    },
  },
  custom: false,
  options: {
    'Performance-1': {
      customization: {
        chart_key: 'engagement_per_page_view',
        title: strings.engagementPerPageView || 'Engagement Per Page View',
      },
    },
    'DigitalPages-1': {
      customization: { display_mode: 'large-list', title: strings.digitalPagesTitle || 'Digital Pages' },
      queryParams: { limit: 50 },
    },
    'Documents-1': {
      customization: { display_mode: 'large-list', title: strings.documentsTitle || 'Documents' },
      queryParams: { limit: 50 },
    },
    'EventCalendars-1': {
      customization: { display_mode: 'large-list', title: strings.eventCalendarsTitle || 'Event Calendars' },
      queryParams: { limit: 50 },
    },
    'Media-1': {
      customization: { display_mode: 'large-list', title: strings.imagesTitle || 'Media' },
      queryParams: { limit: 50 },
    },
  },
})

const selectDigitalTemplateId = (templateId, selectFn) => selectFn(templateId).then(({ success, result, redirectUrl }) => {
  if (!success){
    toast.warning(result ? result[0] : 'An error occurred')
    return
  }

  if (redirectUrl){
    window.location = redirectUrl
  }
})

const callbacks = (component, state, setState, userCallbacks) => {
  const { editingDashboard } = state
  const { saveUserDashboard } = userCallbacks || {}

  const componentCallbacks = {
    CustomizableDashboard: {
      toggleEditDashboard: () => setState({ editingDashboard: !editingDashboard }),
      saveDashboard: (dashboardKey, activeLayout) => saveUserDashboard(dashboardKey, activeLayout),
    },
    CreateProjectItemModal: {
      closeModal: () => setState({ showCreateProjectItemModal: false }),
    },
    SelectDigitalTemplateModal: {
      closeModal: () => setState({ showSelectDigitalTemplateModal: false }),
      selectDigitalTemplateId: (templateId, selectFn) => selectDigitalTemplateId(templateId, selectFn),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  editingDashboard: false,
  showCreateProjectItemModal: false,
}

const Overview = (props) => {
  const { projectId } = props

  const [state, setState] = useSetState(defaultState)
  const { editingDashboard, showCreateProjectItemModal } = state

  const { projectType } = useRelations({ id: projectId })

  const {
    urls: { overviewUrl },
  } = useProject({ id: projectId })

  const { selectedOrganization } = useOrganizationSelector()

  const initDigitalPage = {
    owner_id: selectedOrganization.id,
    owner_type: 'Organization',
    subject_id: projectId,
    subject_type: 'Project',
  }

  const {
    callbacks: { duplicateDigitalPageModal, showSelectDigitalTemplateModal },
  } = useDigitalPage(initDigitalPage)

  const { callbacks: { showDuplicateDigitalPageModal } } = useContext(PageContext)

  const { currentUser } = useCurrentUser()
  const { callbacks: userCallbacks } = useUser(currentUser)

  const customizedLayout = digObject(currentUser, 'options.custom_dashboards.project_dashboard')

  const { digitalTemplateType, showModal } = parsePermittedQueryParams(getQueryParamsFromHash(), [
    'digitalTemplateType',
    'showModal',
  ])

  // Automatically Show Modal
  useEffect(() => {
    if (digitalTemplateType && showModal === 'SelectDigitalTemplateModal'){
      showSelectDigitalTemplateModal(digitalTemplateType)

      window.location.hash = window.location.hash.replace(
        `?showModal=${showModal}&digitalTemplateType=${digitalTemplateType}`,
        '',
      )
    }
  }, [digitalTemplateType, showModal])

  const { strings } = useLocalization(localizedStrings, { projectType })

  const availableModules = useMemo(() => generateAvailableModules(projectId, strings), [projectId])

  return (
    <>
      <PageHeader
        actionContent={
          !editingDashboard && (
            <>
              <Button
                buttonStyle="primaryCreate"
                onClick={() => setState({ showCreateProjectItemModal: true })}
                size="medium"
                style={{ marginRight: 8, width: 'auto' }}
              >
                {strings.createText || 'Create'}
              </Button>

              <IconWithMenu
                closeOnClick
                icon={(
                  <Button
                    buttonStyle="secondaryUtility"
                    icon={<FontAwesomeIcon icon={faCog} />}
                    size="medium"
                    style={{ height: 37 }}
                  />
                )}
              >
                <IconWithMenu.Menu listStyle={{ right: '-7px' }}>
                  <a key="edit" onClick={() => setState({ editingDashboard: !editingDashboard })} tabIndex="0">
                    <FontAwesomeIcon icon={faEdit} />{' '}
                    {editingDashboard
                      ? strings.saveDashboard || 'Save Dashboard'
                      : strings.customizeDashboard || 'Customize Dashboard'}
                  </a>
                </IconWithMenu.Menu>
              </IconWithMenu>
            </>
          )
        }
        activeTabBarItemKey="dashboard"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        nestedNavigation
        tabBarItems={[
          {
            href: overviewUrl,
            icon: faHome,
            key: 'dashboard',
            title: strings.dashboard || 'Dashboard',
          },
        ]}
        title={strings.title || 'Project Overview'}
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <CustomizableDashboard
          availableModules={availableModules}
          callbacks={callbacks('CustomizableDashboard', state, setState, userCallbacks)}
          columns={[
            { id: 'main', component: <Content />, title: strings.customizableDashboardMainTitle || 'Main' },
            {
              id: 'sidebar',
              component: (
                <ContentSidebar
                  callbacks={{ customizeDashboard: () => setState({ editingDashboard: !editingDashboard }) }}
                  projectId={projectId}
                />
              ),
              title: strings.customizableDashboardSidebarTitle || 'Sidebar',
            },
          ]}
          customizedLayout={customizedLayout}
          dashboardKey="project_dashboard"
          dashboardReady={!!currentUser.id}
          defaultLayout={dashboardDefaultLayout(strings)}
          editing={editingDashboard}
        />
      </Box>

      <CreateProjectItemModal
        callbacks={{
          ...callbacks('CreateProjectItemModal', state, setState, userCallbacks),
          showDuplicateDigitalPageModal,
          showSelectDigitalTemplateModal,
        }}
        showModal={showCreateProjectItemModal}
      />
    </>
  )
}

Overview.propTypes = {
  projectId: PropTypes.number.isRequired,
}

export default Overview
