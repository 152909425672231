export default {
  default: {
    header: {
      title: 'Template',
      titleSecondLine: 'Options',
    },
    formField: {
      button: 'Sort Sections',
      labelFieldSetTitle: '* Title',
      labelDataSource: 'Data Source',
      moreOptions: 'More Options',
      searchProjectsPlaceholder: 'Search for a project',
    },
    search: {
      title: 'PROJECTS',
    },
    footer: {
      button: 'Save Template',
    },
  },
}
