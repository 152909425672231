import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, Button, DashboardModule, Link,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import Team from './Team'
import localizedStrings from './localizedStrings'

const Teams = (props) => {
  const {
    callbacks: { manageProjectTeams },
    teams,
  } = props

  const { strings } = useLocalization(localizedStrings)

  return (
    <DashboardModule
      headerRight={(
        <Link onClick={manageProjectTeams} textProps={{ fontSize: 'small' }}>
          {strings.manage || 'Manage'}
        </Link>
      )}
      title={strings.title || 'Teams'}
    >
      {!!teams.length && (
        <Box paddingBottom={0} flexDirection="column">
          {teams.map(team => (
            <Team key={team.id} team={team} />
          ))}
        </Box>
      )}

      {!teams.length && (
        <Box padding="large">
          <Button buttonStyle="secondaryUtility" onClick={manageProjectTeams} size="medium">
            {strings.addButton || 'Add Team'}
          </Button>
        </Box>
      )}
    </DashboardModule>
  )
}

Teams.propTypes = {
  callbacks: PropTypes.shape({
    manageProjectTeams: PropTypes.func,
  }).isRequired,
  teams: PropTypes.array.isRequired,
}

export default Teams
