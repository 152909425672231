import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities } from '@redux/entities'

import api from '@functions/api'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/digitalPageMetricCollection/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/digitalPageMetricCollection/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/digitalPageMetricCollection/FETCH_FAILURE'

const CREATE_REQUEST = 'realbase/digitalPageMetricCollection/CREATE_REQUEST'
const CREATE_SUCCESS = 'realbase/digitalPageMetricCollection/CREATE_SUCCESS'
const CREATE_FAILURE = 'realbase/digitalPageMetricCollection/CREATE_FAILURE'

// Initial State
const initialState = {
  creating: false,
  errors: [],
  loaded: false,
  loading: false,
  updating: false,
}

// Actions
export function fetchRequest(){
  return {
    type: FETCH_REQUEST,
  }
}

export function fetchSuccess(){
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(){
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

// Action Creators
export function loadDigitalPageMetricCollections(options = {}){
  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = api('/digital_page_metric_collections.json', options)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entities, Schemas.DIGITAL_PAGE_METRIC_COLLECTION_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function buildMonthlyDigitalPageMetricCollections(options = {}){
  const config = {
    method: 'POST',
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api('/digital_page_metric_collections/build_monthly_metric_collections.json', options, config)
      .then(({ data }) => {
        dispatch(createSuccess())

        return { success: true, data: data.data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
