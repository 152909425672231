import React from 'react'
import PropTypes from 'prop-types'

import { FileUploadZone } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'
import useUppyAttachmentUpload from '@hooks/useUppyAttachmentUpload'

import localizedStrings from './localizedStrings'

const ModalAttachmentUpload = (props) => {
  const {
    attachmentType, height, options: customOptions, ownerId, ownerType, requestOptions, subjectId, subjectType,
  } = props

  const options = {
    attachmentParams: {
      attachment_type: attachmentType,
      owner_id: ownerId,
      owner_type: ownerType,
      subject_id: subjectId,
      subject_type: subjectType,
    },
    requestOptions,
    ...customOptions,
  }

  const uppy = useUppyAttachmentUpload(options, [subjectId, subjectType])

  const { strings } = useLocalization(localizedStrings)

  if (!uppy) return null

  return (
    <FileUploadZone uppy={uppy} subTitle={strings.maxFileSizeNote} height={height} />
  )
}

ModalAttachmentUpload.propTypes = {
  attachmentType: PropTypes.string.isRequired,
  height: PropTypes.string,
  options: PropTypes.object,
  ownerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ownerType: PropTypes.string.isRequired,
  requestOptions: PropTypes.object,
  subjectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  subjectType: PropTypes.string.isRequired,
}

ModalAttachmentUpload.defaultProps = {
  height: '180px',
}

export default ModalAttachmentUpload
