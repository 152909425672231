export default {
  default: {
    buttons: {
      goBack: 'Go Back',
    },
    formFieldLabels: {
      filter: 'Filter Templates',
      savedTemplates: 'Saved Form Templates',
      systemTemplates: 'System Templates',
    },
  },
}
