import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import {
  Box, Checkbox, ListItem, Text,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'
import useQuoteItem from '@hooks/useQuoteItem'

import { defaultRequestOptions } from '../../hooks/useQuoteBuilder'

const QuoteItem = React.forwardRef((props, ref) => {
  const {
    callbacks: { showCreateOrEditQuoteItemModal },
    dragHandleProps,
    dragSnapshot,
    draggableProps,
    hideDates,
    hidePrices,
    quoteItem,
  } = props

  const { isDragging } = dragSnapshot || {}

  const itemPayload = useQuoteItem(quoteItem, {
    callbacks: {
      showCreateOrEditQuoteItemModal,
    },
  })

  const {
    callbacks: { editQuoteItem, toggleItemSelection },
    quoteItem: {
      id, price, selected, title,
    },
    requiredByDate,
  } = itemPayload

  const {
    callbacks: { formatCurrency, formatDate },
  } = useLocalization({})

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        backgroundColor: 'white',
        borderBottom: '1px dashed',
        borderColor: 'lineColor',
        paddingY: 'medium',
      }}
      forwardProps={{
        ...draggableProps,
        ...dragHandleProps,
      }}
      isDragging={isDragging}
      key={id}
      onClick={() => editQuoteItem()}
      ref={ref}
      showAngleIcon
    >
      <Checkbox checked={selected} onClick={e => toggleItemSelection(e, defaultRequestOptions.quoteItem)} />

      <Box flexDirection="column" lineHeight="1.3">
        <Text color="bodyFontColor" fontSize="small" lineHeight="1.3">
          {title}
        </Text>

        {!hideDates && !!requiredByDate && (
          <Text color="bodyFontLightColor" fontSize="xsmall">
            {formatDate(requiredByDate, DateTime.DATE_FULL)}
          </Text>
        )}
      </Box>

      {!hidePrices && (
        <Text color="bodyFontLightColor" fontSize="small" lineHeight="1.3" marginLeft="auto">
          {price >= 0 ? formatCurrency(price) : `-${formatCurrency(Math.abs(price))}`}
        </Text>
      )}
    </ListItem>
  )
})

QuoteItem.propTypes = {
  callbacks: PropTypes.shape({
    showCreateOrEditQuoteItemModal: PropTypes.func.isRequired,
  }).isRequired,
  dragHandleProps: PropTypes.object,
  dragSnapshot: PropTypes.object,
  draggableProps: PropTypes.object,
  hideDates: PropTypes.bool,
  hidePrices: PropTypes.bool,
  quoteItem: PropTypes.object.isRequired,
}

export default QuoteItem
