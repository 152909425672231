import React from 'react'

import { BlankState } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

import blankQuotesUrl from './assets/quotes.svg'

const QuotesBlankState = () => {
  const { strings } = useLocalization(localizedStrings)

  return (
    <BlankState imageUrl={blankQuotesUrl} sidebar>
      <BlankState.Paragraph>
        {strings.paragraph || 'Quotes have not been created or imported yet. Get started by clicking the button below'}
      </BlankState.Paragraph>
    </BlankState>
  )
}

export default QuotesBlankState
