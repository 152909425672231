import { BlankState } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

import quotesImageUrl from './assets/quotes.svg'

const QuotesBlankState = () => {
  const { strings } = useLocalization(localizedStrings)

  return (
    <BlankState imageUrl={quotesImageUrl}>
      <BlankState.Title>{strings.title || 'No Quotes Created'}</BlankState.Title>

      <BlankState.Paragraph>
        {strings.paragraph
          || "You don't have any quotes available on this project. Create a quote to show your customers the services and products you'll be organising on their behalf and to clearly outline the costs associated with your services."}
      </BlankState.Paragraph>
    </BlankState>
  )
}

export default QuotesBlankState
