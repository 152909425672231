import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFire, faMugHot, faSnowflake } from '@fortawesome/pro-solid-svg-icons'

import { Box, Text } from '@campaignhub/suit-theme'

const EngagementStatusesBox = (props) => {
  const { cold, hot, warm } = props

  return (
    <Box justifyContent="flex-end" flex={1} lineHeight={1.3} width="calc(100% - 48px)">
      <Box fontSize="small" width="fit-content">
        <Box justifyContent="center" marginLeft="large" fontSize="xsmall" width={40}>
          <Text
            as="span"
            color="#BB6161"
            fontWeight="normal"
            marginRight="small"
            style={{ letterSpacing: '1px', textTransform: 'uppercase', whiteSpace: 'nowrap' }}
          >
            <FontAwesomeIcon icon={faFire} />
          </Text>

          <Text>{hot}</Text>
        </Box>

        <Box justifyContent="center" marginLeft="large" fontSize="xsmall" width={40}>
          <Text
            as="span"
            color="#D7B94E"
            fontWeight="normal"
            marginRight="small"
            style={{ letterSpacing: '1px', textTransform: 'uppercase', whiteSpace: 'nowrap' }}
          >
            <FontAwesomeIcon icon={faMugHot} />
          </Text>

          <Text>{warm}</Text>
        </Box>

        <Box justifyContent="center" marginLeft="large" fontSize="xsmall" width={40}>
          <Text
            as="span"
            color="#6180BB"
            fontWeight="normal"
            marginRight="small"
            style={{ letterSpacing: '1px', textTransform: 'uppercase', whiteSpace: 'nowrap' }}
          >
            <FontAwesomeIcon icon={faSnowflake} />
          </Text>

          <Text>{cold}</Text>
        </Box>
      </Box>
    </Box>
  )
}

EngagementStatusesBox.propTypes = {
  cold: PropTypes.number.isRequired,
  hot: PropTypes.number.isRequired,
  warm: PropTypes.number.isRequired,
}

export default EngagementStatusesBox
