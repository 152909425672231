export type ShareLinkModel = {
  cache_key: number,
  id: number,
  data: {},
  dates: {
    created: {
      date_time_with_timezone: string,
    },
    expiry: {
      date_time_with_timezone: string,
    },
    updated: {
      date_time_with_timezone: string,
    },
  },
  default: boolean,
  enabled: boolean,
  expires_at: string,
  notifications_enabled: boolean,
  options: {
    notification_recipients: { id: string, type: string }[],
  } | {},
  owner_id: number,
  owner_type: string,
  print_link: boolean,
  subject_id: number,
  subject_type: string,
  title: string,
  token: string,
  tracking_enabled: boolean,
  views: number,
}

export type ShareLinkRequestOptions = {
  include_share_link_analytics?: boolean,
  include_share_link_digital_page_views?: boolean,
  include_share_link_open_graph_data?: boolean,
  include_share_link_paths?: boolean,
}

const state = {
  default: false,
  enabled: false,
  expires_at: '',
  id: null,
  notifications_enabled: false,
  options: {},
  subject_id: null,
  subject_type: '',
  title: '',
  token: '',
  tracking_enabled: false,
}

export const requiredFields = [
  { key: 'subject_id' },
  { key: 'subject_type' },
  { key: 'title' },
]

export default state
