import { useMemo } from 'react'
import swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faPencil, faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, DashboardModule, Link, Table, Text,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import type { CustomFieldModel, NestedFieldModel } from '@models/customField'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

type DeleteNestedItemParams = {
  customField: CustomFieldModel,
  deleteNestedItem: (customFieldKey: string, item: NestedFieldModel) => void,
  item: NestedFieldModel,
  strings: { [key: string]: any },
}

const confirmDelete = (params: DeleteNestedItemParams) => {
  const {
    customField, deleteNestedItem, item, strings,
  } = params

  swal
    .fire({
      title: strings.confirmDelete?.title || 'Delete Item?',
      text: strings.confirmDelete?.text || 'Are you sure? It cannot be recovered.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: strings.confirmDelete?.confirmButtonText || 'Yes, delete it.',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        deleteNestedItem(customField.key, item)
      }
    })
}

const buildColumns = (customField: CustomFieldModel, callbacks: any, strings: { [key: string]: any }) => {
  const nestedFields = digObject(customField, 'data.fields', [])
  const { createOrEditNestedDataStoreArrayItem, deleteNestedItem } = callbacks

  const columns = nestedFields.map((field: NestedFieldModel) => ({
    dataKey: field.key,
    title: field.label,
    render: (columnValue: string | number) => (
      <Text key={field.key} variant="ellipsis">
        {columnValue}
      </Text>
    ),
  }))

  columns.push({
    cellProps: {
      textAlign: 'center',
    },
    dataKey: 'actions',
    title: strings.columnTitles?.actions || 'Actions',
    render: (_: any, item: NestedFieldModel) => (
      <Box justifyContent="center" key="actions">
        <Link
          greyLink
          onClick={() => createOrEditNestedDataStoreArrayItem({
            customFieldId: customField.id,
            nestedDataStoreItemId: item.id,
          })}
        >
          <FontAwesomeIcon icon={faPencil} />
        </Link>

        <Link
          greyLink
          onClick={() => confirmDelete({
            customField, deleteNestedItem, item, strings,
          })}
          textProps={{ marginLeft: 'medium' }}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </Link>
      </Box>
    ),
  })

  return columns
}

type NestedArrayFieldProps = {
  customField: CustomFieldModel,
  customFieldGroup: {
    fieldType?: string,
    id: string,
    items: CustomFieldModel[],
    label: string,
    tag: string,
  },
  customFieldsForm: {
    entityState: object,
  },
  nestedDataStoreArrayItemPayload: {
    callbacks: {
      createOrEditNestedDataStoreArrayItem: (params: any) => void,
      deleteNestedItem: (customFieldKey: string, item: NestedFieldModel) => void,
    },
  },
}

const NestedArrayField = (props: NestedArrayFieldProps) => {
  const {
    customField,
    customFieldGroup,
    customFieldsForm: { entityState },
    nestedDataStoreArrayItemPayload: { callbacks },
  } = props

  const { createOrEditNestedDataStoreArrayItem } = callbacks || {}

  const { label } = customFieldGroup
  const fieldValues = digObject(entityState, customField.key, [])

  const { strings } = useLocalization(localizedStrings)

  const memoizedColumns = useMemo(() => buildColumns(customField, callbacks, strings), [fieldValues.length])

  return (
    <DashboardModule contentBoxProps={{ flexDirection: 'column' }} title={label}>
      <Table
        boxProps={{ border: 'none', maxHeight: 300, overflowY: 'auto' }}
        columns={memoizedColumns}
        data={fieldValues}
        scroll={{ x: 800 }}
        stickyHeader
      />

      <DashboardModule.Footer
        boxProps={{
          borderTop: '1px solid',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          buttonStyle="secondaryEdit"
          icon={<FontAwesomeIcon icon={faPlus} />}
          marginLeft="auto"
          onClick={() => createOrEditNestedDataStoreArrayItem({ customFieldId: customField.id })}
          size={['medium', 'small']}
          width={['100%', 'fit-content']}
        >
          {strings.buttons?.add || 'Add Item'}
        </Button>
      </DashboardModule.Footer>
    </DashboardModule>
  )
}

export default NestedArrayField
