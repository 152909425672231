import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faEnvelope as faEnvelopeSolid, faUser as faUserSolid } from '@fortawesome/pro-solid-svg-icons'
import {
  faCog, faEnvelope, faTrashAlt, faUser, faUserPlus,
} from '@fortawesome/pro-light-svg-icons'

import {
  Box, IconWithMenu, Link, Text,
} from '@campaignhub/suit-theme'

import useDocumentRecipient, { getRecipientSubject, roleTitles, useRelations } from '@hooks/useDocumentRecipient'
import useLocalization from '@hooks/useLocalization'

import styles from './styles.module.scss'

import localizedStrings from './localizedStrings'

const DocumentRecipient = React.forwardRef((props, ref) => {
  const {
    callbacks,
    documentRecipient,
    dragHandleProps,
    dragSnapshot,
    draggableProps,
    isDraggable,
    showEditOptions,
  } = props

  const { deleteDocumentRecipient } = callbacks || {}

  const { isDragging } = dragSnapshot || {}

  const documentRecipientPayload = useDocumentRecipient(documentRecipient)
  const {
    callbacks: {
      createOrEditDocumentRecipient,
      deleteDocumentRecipient: deleteFn,
      toggleInPersonSigner,
    },
    documentRecipient: { in_person_signer, role_key, subject_id },
    recipientColor,
    userType,
  } = documentRecipientPayload

  const recipientRelations = useRelations(documentRecipient)
  const recipientSubject = getRecipientSubject(recipientRelations)

  const { strings } = useLocalization(localizedStrings)

  return (
    <Box
      alignItems="center"
      className={classnames(styles.root, isDragging ? styles.dragging : null)}
      variant="white"
      fontSize="small"
      marginBottom="small"
      padding="medium"
      ref={ref}
      {...draggableProps}
      {...dragHandleProps}
    >
      <Box
        alignItems="center"
        backgroundColor={isDraggable ? null : recipientColor}
        borderRadius={5}
        color={recipientColor}
        height={20}
        justifyContent="center"
        width={20}
      >
        <FontAwesomeIcon icon={faBars} />
      </Box>

      <Box flexDirection="column" marginLeft="medium" width="auto">
        <Text fontSize="small">
          {recipientSubject.full_name || userType.title || strings.notAssigned || 'Not Assigned'}
        </Text>

        <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="small">
          {recipientSubject.full_name && userType.title
            ? userType.title
            : roleTitles[role_key] || strings.title || 'Recipient'}
        </Text>
      </Box>

      <Box marginLeft="auto" width="auto">
        {subject_id && (
          <Box alignItems="center" marginRight="medium">
            <Text color="bodyFontLightColor" fontSize="xsmall" style={{ whiteSpace: 'nowrap' }}>
              <FontAwesomeIcon icon={in_person_signer ? faUserSolid : faEnvelopeSolid} style={{ marginRight: 8 }} />
              {in_person_signer ? strings.inPerson || 'In Person' : strings.remote || 'Email'}
            </Text>
          </Box>
        )}

        {showEditOptions && (
          <IconWithMenu
            icon={(
              <Box fontSize="xsmall" color="bodyFontLightColor">
                <FontAwesomeIcon icon={faCog} />
              </Box>
            )}
            style={{ marginLeft: 'auto', width: 'auto' }}
          >
            <IconWithMenu.Menu closeOnClick listStyle={{ right: '-15px' }} nibStyle={{ right: '12px' }}>
              {subject_id && (
                <>
                  <Link key="in-person-signer" onClick={() => toggleInPersonSigner()}>
                    <FontAwesomeIcon icon={in_person_signer ? faEnvelope : faUser} />{' '}
                    {in_person_signer
                      ? strings.menu?.remoteSign || 'Sign via Email'
                      : strings.menu?.inPersonSign || 'Sign in Person'}
                  </Link>
                </>
              )}

              <Link key="subject" onClick={() => createOrEditDocumentRecipient()}>
                <FontAwesomeIcon icon={faUserPlus} />{' '}
                {subject_id
                  ? strings.menu?.changeRecipient || 'Change Recipient'
                  : strings.menu?.assignRecipient || 'Assign Recipient'}
              </Link>

              {deleteDocumentRecipient && (
                <Link key="delete" onClick={() => deleteDocumentRecipient(deleteFn)}>
                  <FontAwesomeIcon icon={faTrashAlt} /> {strings.menu?.delete || 'Delete Recipient'}
                </Link>
              )}
            </IconWithMenu.Menu>
          </IconWithMenu>
        )}
      </Box>
    </Box>
  )
})

DocumentRecipient.propTypes = {
  callbacks: PropTypes.shape({
    deleteDocumentRecipient: PropTypes.func,
  }),
  documentRecipient: PropTypes.object.isRequired,
  dragHandleProps: PropTypes.object,
  dragSnapshot: PropTypes.object,
  draggableProps: PropTypes.object,
  isDraggable: PropTypes.bool,
  showEditOptions: PropTypes.bool,
}

export default DocumentRecipient
