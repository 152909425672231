import React from 'react'
import PropTypes from 'prop-types'
import { Route, Routes } from 'react-router-dom'

import Activities from './screens/Activities'
import Agreements from './screens/Agreements'
import Analytics from './screens/Analytics'
import Comparables from './screens/Comparables'
import Details from './screens/Details'
import DigitalPageList from './screens/DigitalPages/screens/DigitalPageList'
import Documents from './screens/Documents'
import Events from './screens/Events'
import Media from './screens/Media'
import Overview from './screens/Overview'
import FormResponses from './screens/FormResponses'
import QuoteDetails from './screens/QuoteDetails'
import Quotes from './screens/Quotes'

const ProjectRoutes = (props) => {
  const { projectId, project } = props

  return (
    <Routes>
      <Route exact index element={<Overview projectId={projectId} project={project} />} />
      <Route exact path="/activities" element={<Activities projectId={projectId} />} />
      <Route exact path="/agreements" element={<Agreements projectId={projectId} project={project} />} />
      <Route exact path="/analytics" element={<Analytics projectId={projectId} project={project} />} />
      <Route exact path="/comparables" element={<Comparables projectId={projectId} project={project} />} />
      <Route exact path="/digitalPages/*" element={<DigitalPageList projectId={projectId} project={project} />} />
      <Route exact path="/documents" element={<Documents projectId={projectId} project={project} />} />
      <Route exact path="/edit" element={<Details projectId={projectId} project={project} />} />
      <Route exact path="/events" element={<Events projectId={projectId} project={project} />} />
      <Route exact path="/formResponses" element={<FormResponses projectId={projectId} project={project} />} />
      <Route exact path="/media" element={<Media projectId={projectId} project={project} />} />
      <Route exact path="/quotes" element={<Quotes projectId={projectId} project={project} />} />
      <Route exact path="/quotes/:quoteId" element={<QuoteDetails projectId={projectId} project={project} />} />
    </Routes>
  )
}

ProjectRoutes.propTypes = {
  project: PropTypes.object.isRequired,
  projectId: PropTypes.number,
}

export default ProjectRoutes
