import { Box, Image, Text } from '@campaignhub/suit-theme'

import belongTogetherUrl from './assets/belongTogether.png'

const BelongTogether = () => (
  <Box flexDirection={['column', 'row']} marginTop="xlarge" alignItems="center" justifyContent="space-between">
    <Image
      height={170}
      borderRadius={5}
      width={['100%', '420px']}
      backgroundSize="contain"
      borderRadius={5}
      url={belongTogetherUrl}
    />

    <Box flexDirection="column" width={['100%', 'calc(100% - 452px)']}>
      <Text fontSize="medium" marginBottom="large">
        Some things belong together
      </Text>

      <Text color="bodyFontLightColor" lineHeight="1.5" fontSize="small">
        {`Our integration allows you to kick start your digital agreement in
               Real Time Agent without leaving the Engage platform. 
               Now you can prepare an agreement in minutes without adding unnecessary steps. 
               Push campaign, property, vendor and agent details from Engage into Real Time Agent and 
               prepare an agreement in minutes. Simplify your workflow 
              by reducing double handling and manual entries and improve efficiencies overall.`}
      </Text>
    </Box>
  </Box>
)

export default BelongTogether
