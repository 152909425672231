export default {
  default: {
    buttons: {
      create: 'Create Template',
    },
    formFieldLabels: {
      filter: 'Filter Templates',
      organization: 'Organisation',
      title: '* Template Title',
    },
    sideBarModalHeader: {
      title: 'Template',
      titleSecondLine: {
        library: 'Library',
        details: 'Details',
      },
    },
    sectionDividers: {
      selectTemplate: 'Select Template to Duplicate',
      templateDetails: 'Template Details',
    },
  },
}
