export default {
  default: {
    bulkUpdateIntegrations: 'Bulk Update Integrations',
    createButton: 'Create',
    setDefaultFeatures: 'Set Default Features',
    title: 'Integrations',
    tools: 'Tools',
    integrationPartner: {
      description: 'Learn how to generate an agreement with RTA integration',
      integrationPlatformKey: 'real_time_agent',
      title: 'Sign up to Real Time Agent',
    },
  },
}
