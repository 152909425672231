import { faTachometer } from '@fortawesome/pro-light-svg-icons'

import useLocalization from '@hooks/useLocalization'
import useReduxAction from '@hooks/useReduxAction'

import { Box, DashboardModule, Link } from '@campaignhub/suit-theme'

import useProject from '@hooks/useProject'
import useQuotes from '@hooks/useQuotes'

import CompactList from './CompactList'
import LargeList from './LargeList'

import quotesBlank from './assets/quotes.svg'

import localizedStrings from './localizedStrings'

export const icon = faTachometer

export const configOptions = (strings: Record<string, {}>) => ({
  title: {
    component: 'input',
    key: 'title',
    label: strings.labelTitle?.title || 'Title',
    optionKey: 'customization',
  },
  display_mode: {
    component: 'select',
    key: 'display_mode',
    label: strings.displayMode?.label || 'Display Mode',
    optionKey: 'customization',
    values: [
      { key: 'compact-list', label: strings.displayModeValues?.labelListCompact || 'List (Compact)' },
      { key: 'large-list', label: strings.displayModeValues?.labelListLarge || 'List (Large)' },
    ],
  },
  limit: {
    component: 'select',
    key: 'limit',
    label: strings.limit?.label || 'Limit',
    optionKey: 'queryParams',
    values: [
      { key: 5, label: '5' },
      { key: 10, label: '10' },
      { key: 25, label: '25' },
      { key: 50, label: '50' },
    ],
  },
})

const defaultRequestOptions = {
  include_quote_items: true,
  include_quote_sections: true,
}

interface QuoteProps {
  customOptions: {
    customization: {
      display_mode: string
      title: string
    }
    queryParams: {
      limit: number
      subject_id: number
      subject_type: string
    }
  }
  editing: boolean
  parentId: string
}

const Quotes = (props: QuoteProps) => {
  const {
    customOptions,
    customOptions: {
      queryParams: { limit, subject_id: subjectId, subject_type: subjectType },
    },
    editing,
    parentId,
  } = props

  const requestOptions = {
    ...defaultRequestOptions,
    ...customOptions.queryParams,
  }

  useReduxAction('quotes', 'loadQuotes', requestOptions, [parentId, JSON.stringify(requestOptions)])

  const quotesPayload = useQuotes({
    filters: {
      subjectId,
      subjectType,
    },
    limit,
  })

  const { filteredQuotes, hasQuotes, loading } = quotesPayload

  const {
    urls: { quotesUrl },
  } = useProject({ id: subjectId })

  const { customization } = customOptions
  const { display_mode, title } = customization || {}

  const { strings } = useLocalization(localizedStrings)

  return (
    <DashboardModule
      boxProps={{ marginBottom: 0 }}
      editing={editing}
      headerRight={
        <Link href={quotesUrl} textProps={{ fontSize: 'small' }}>
          {strings.manage || 'Manage'}
        </Link>
      }
      loading={loading}
      title={title || strings.title?.title || 'Quotes'}
    >
      {!loading && !hasQuotes && (
        <DashboardModule.BlankState imageUrl={quotesBlank}>
          <DashboardModule.BlankState.Title>
            {strings.blankState?.title || 'No Quotes Created'}
          </DashboardModule.BlankState.Title>

          <DashboardModule.BlankState.Paragraph>
            {strings.blankState?.paragraph ||
              "You don't have any quotes available on this project. Create a quote to show your customers the services and products you'll be organising on their behalf and to clearly outline the costs associated with your services."}
          </DashboardModule.BlankState.Paragraph>
        </DashboardModule.BlankState>
      )}

      {!loading && hasQuotes && (
        <Box flexDirection="column" flexWrap="wrap">
          {filteredQuotes.map(quote => {
            const ListComponent = display_mode === 'compact-list' ? CompactList : LargeList

            return <ListComponent key={quote.id} quote={quote} />
          })}
        </Box>
      )}
    </DashboardModule>
  )
}

export default Quotes
