import React from 'react'
import PropTypes from 'prop-types'

import { Box, Text } from '@campaignhub/suit-theme'

import { snakeToTitleCase } from '@campaignhub/javascript-utils'

const Legend = (props) => {
  const { payload } = props

  return (
    <Box alignItems="center" flexDirection="row" justifyContent="center" paddingTop="large">
      {payload.map((item) => {
        const { dataKey, color } = item

        return (
          <Box key={dataKey} width="auto">
            <Text fontSize="xxsmall" color={color} style={{ marginRight: 8 }}>
              {snakeToTitleCase(dataKey)}
            </Text>
          </Box>
        )
      })}
    </Box>
  )
}

Legend.propTypes = {
  payload: PropTypes.array,
}

export default Legend
