import type { AppDispatch } from '@redux/store'

import { useEffect } from 'react'

import { digObject, toggleArray } from '@campaignhub/javascript-utils'
import { useSetState, useThunkDispatch } from '@campaignhub/react-hooks'

import useIntegrations from '@hooks/useIntegrations'

import type { IntegrationPlatformModel } from '@models/types'

import * as integrationActions from '@redux/modules/integration'

const setupFeatureOptions = (supportedFeatureKeys: string[], featureOptions: string[]) => (supportedFeatureKeys.reduce((acc, key) => {
  if (featureOptions.includes(key)){
    acc[key] = true
  }

  if (!featureOptions.includes(key)){
    acc[key] = false
  }

  return acc
}, {}))

type BulkUpdateIntegrationFeaturesTypes = {
  dispatch: AppDispatch,
  payload: {
    featureOptions: string[],
    integrationIds: number[],
  },
  requestOptions: {[key: string]: string},
  supportedFeatureKeys: string[],
}

const bulkUpdateIntegrationFeatures = (params: BulkUpdateIntegrationFeaturesTypes) => {
  const {
    dispatch, payload, requestOptions, supportedFeatureKeys,
  } = params

  const { featureOptions, integrationIds } = payload
  const { bulkUpdateIntegrationFeatures: bulkFn } = integrationActions

  const updatedFeatureOptions = setupFeatureOptions(supportedFeatureKeys, featureOptions)

  const updatedPayload = {
    integrationIds,
    featureOptions: JSON.stringify(updatedFeatureOptions),
  }

  return dispatch(bulkFn(updatedPayload, requestOptions))
}

const toggleFeatureKeys = (entityKey, state, setState) => {
  const { selectedFeatureKeys } = state

  const updatedEntityKeys = toggleArray(selectedFeatureKeys, entityKey)

  setState({ selectedFeatureKeys: updatedEntityKeys })
}

const toggleIntegrationIds = (entityId, state, setState) => {
  const { selectedIntegrationIds } = state

  const updatedEntityIds = toggleArray(selectedIntegrationIds, entityId)

  setState({ selectedIntegrationIds: updatedEntityIds })
}

type DefaultStateType = {
  selectedIntegrationPlatform: Partial<IntegrationPlatformModel>,
  selectedFeatureKeys: string[],
  selectedIntegrationIds: number[],
}
const defaultState: DefaultStateType = {
  selectedIntegrationPlatform: {},
  selectedFeatureKeys: [],
  selectedIntegrationIds: [],
}

type UseBulkUpdateIntegrationFeaturesOptions = {
    ownerId: number,
    ownerType: string,
    subjectId?: number,
    subjectType?: string,
}

function useBulkUpdateIntegrationsFeatures(options: UseBulkUpdateIntegrationFeaturesOptions) {
  const {
    ownerId, ownerType, subjectId, subjectType,
  } = options

  const [state, setState] = useSetState(defaultState)
  const { selectedIntegrationPlatform } = state

  const integrationPayload = useIntegrations({
    filters: {
      integration_platform: selectedIntegrationPlatform.id,
      owner_id: ownerId,
      owner_type: ownerType,
      subject_id: subjectId,
      subject_type: subjectType,
    },
  })

  const { filteredIntegrations } = integrationPayload

  const supportedFeatures = digObject(selectedIntegrationPlatform, 'supported_features', [])

  const supportedFeatureKeys = supportedFeatures.map(feature => feature.key)
  const integrationIds = Object.values(filteredIntegrations).map(integration => integration.id)

  useEffect(() => {
    setState({
      selectedFeatureKeys: [...supportedFeatureKeys],
      selectedIntegrationIds: integrationIds,
    })
  }, [supportedFeatures.length])

  const dispatch = useThunkDispatch()

  return {
    callbacks: {
      bulkUpdateIntegrationFeatures: (payload, requestOptions) => bulkUpdateIntegrationFeatures({
        dispatch,
        payload,
        requestOptions,
        supportedFeatureKeys,
      }),
      setState,
      toggleFeatureKeys: entityKey => toggleFeatureKeys(entityKey, state, setState),
      toggleIntegrationIds: entityId => toggleIntegrationIds(entityId, state, setState),
    },
    integrationPayload,
    supportedFeatures,
    ...state,
  }
}

export default useBulkUpdateIntegrationsFeatures
