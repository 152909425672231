import { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { digObject, sortArrayBy } from '@campaignhub/javascript-utils'
import {
  useForm, useLatestEntity, useThunkDispatch, useWatchEntityUpdates,
} from '@campaignhub/react-hooks'

import defaultFormState, { requiredFields } from '@models/documentTemplatePage'

import { createDocumentTemplatePageItem } from '@hooks/useDocumentTemplatePageItem'

import * as documentTemplatePageActions from '@redux/modules/documentTemplatePage'

const watchEntityKeys = ['documentTemplatePageItems', 'images']

const createDocumentTemplatePage = (documentTemplatePage, dispatch, requestOptions) => {
  const { createDocumentTemplatePage: createFn } = documentTemplatePageActions
  return dispatch(createFn(documentTemplatePage, requestOptions))
}

const updateDocumentTemplatePage = (documentTemplatePage, dispatch, requestOptions) => {
  const { updateDocumentTemplatePage: updateFn } = documentTemplatePageActions
  return dispatch(updateFn(documentTemplatePage, requestOptions))
}

const deleteDocumentTemplatePage = (documentTemplatePage, dispatch, requestOptions) => {
  const { deleteDocumentTemplatePage: deleteFn } = documentTemplatePageActions
  return dispatch(deleteFn(documentTemplatePage, requestOptions))
}

const dragDropTemplatePageItem = (payload, dispatch) => {
  const {
    dropData: {
      dataset: {
        itemContext,
        itemContextIndex,
        itemCustomFieldId,
        itemKey,
        itemLabel,
        itemRawValue,
        itemRecipientId,
        itemType,
        itemValue,
      },
      dropTargetEntity: documentTemplatePage,
      height,
      width,
      x,
      y,
    },
    zoom = 1,
  } = payload

  const documentTemplatePageItem = {
    custom_field_id: itemCustomFieldId ? Number(itemCustomFieldId) : null,
    data_context: itemContext,
    data_context_index: Number(itemContextIndex),
    document_recipient_id: itemRecipientId,
    document_template_page_id: documentTemplatePage.id,
    height: height / zoom,
    item_type: itemType,
    key: itemKey,
    label: itemLabel,
    raw_value: itemRawValue,
    value: itemValue,
    width: width / zoom,
    x: x / zoom,
    y: y / zoom,
    z: 0,
  }

  return createDocumentTemplatePageItem(documentTemplatePageItem, dispatch)
}

export function useDocumentTemplatePageForm(documentTemplatePage = {}, options = {}){
  const { customRequiredFields = [] } = options

  const { creating, updating } = useSelector(reduxState => reduxState.documentTemplatePages)

  const documentTemplatePageForm = useForm(
    defaultFormState,
    {
      entity: documentTemplatePage,
      requiredFields: [...requiredFields, ...customRequiredFields],
      validateOn: 'change',
    },
    [documentTemplatePage.id, documentTemplatePage.cache_key],
  )

  return {
    ...documentTemplatePageForm,
    creating,
    updating,
  }
}

function useDocumentTemplatePage(initEntity){
  const { entity: documentTemplatePage } = useLatestEntity(initEntity, 'documentTemplatePages')
  const { document_template_id, id } = documentTemplatePage

  const {
    updatedEntities: {
      documentTemplatePageItems: documentTemplatePageItemsUpdatedAt,
      images: imagesUpdatedAt,
    },
  } = useWatchEntityUpdates(watchEntityKeys)

  const dispatch = useThunkDispatch()

  const entities = useSelector(reduxState => reduxState.entities)
  const { documentTemplatePageItems, images } = entities

  // Page Items
  const filteredPageItems = useMemo(() => {
    const array = Object.values(documentTemplatePageItems)
    const filtered = array.filter((documentTemplatePageItem) => {
      const { document_template_page_id } = documentTemplatePageItem

      return document_template_page_id === id
    })

    const sorted = sortArrayBy(filtered, 'asc', 'z')

    return sorted
  }, [id, documentTemplatePageItemsUpdatedAt])

  // Page Preview Images
  const filteredPagePreviewImages = useMemo(() => {
    const array = Object.values(images)
    const filtered = array.filter((image) => {
      const { subject_id, subject_type } = image
      const documentTemplatePageId = digObject(image, 'data.document_template_page_id')

      return subject_id === document_template_id && subject_type === 'DocumentTemplate' && documentTemplatePageId === id
    })

    const sorted = sortArrayBy(filtered, 'asc', 'sort')

    return sorted
  }, [id, imagesUpdatedAt])

  const pagePreviewImage = filteredPagePreviewImages[0] || {}

  return {
    callbacks: {
      createDocumentTemplatePage: (params, requestOptions) => createDocumentTemplatePage(params, dispatch, requestOptions),
      deleteDocumentTemplatePage: (params, requestOptions) => deleteDocumentTemplatePage(params, dispatch, requestOptions),
      dragDropTemplatePageItem: payload => dragDropTemplatePageItem(payload, dispatch),
      updateDocumentTemplatePage: (params, requestOptions) => updateDocumentTemplatePage(params, dispatch, requestOptions),
    },
    documentTemplatePage,
    filteredPageItems,
    filteredPagePreviewImages,
    pagePreviewImage,
  }
}

export default useDocumentTemplatePage
