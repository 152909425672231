import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, DashboardModule, Link, ListItem,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const Sidebar = (props) => {
  const { onboardingTasksPayload } = props
  const {
    callbacks: { toggleOnboardingTasksModule },
    loaded,
    minimized,
    onboardingCompleted,
    sortedTasks,
  } = onboardingTasksPayload

  const {
    strings,
  } = useLocalization(localizedStrings)

  if (!loaded || !minimized || onboardingCompleted){
    return null
  }

  return (
    <DashboardModule
      boxProps={{ marginBottom: 0, marginTop: 'large' }}
      headerRight={(
        <Link onClick={toggleOnboardingTasksModule} textProps={{ fontSize: 'small' }}>
          {strings?.buttons?.maximizeMaximize || 'Maximize'}
        </Link>
      )}
      title={strings?.titles?.onboardingTasks || 'Onboarding Tasks'}
    >
      <Box flexDirection="column">
        {sortedTasks.map((task) => {
          const { href, title } = task
          return <ListItem.Compact href={href}>{title}</ListItem.Compact>
        })}
      </Box>
    </DashboardModule>
  )
}

Sidebar.propTypes = {
  onboardingTasksPayload: PropTypes.object.isRequired,
}

export default Sidebar
