import { useMemo } from 'react'

import {
  matchFilterArrayIncludes, matchFilterNumber, matchFilterString, sortArrayBy,
} from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { ContactModel } from '@models/types'
import type { ModuleState } from '@redux/modules/types'

import defaultRequestOptions from '@sections/Client/packs/Dashboard/defaultRequestOptions'

const watchEntityKeys = ['contacts']

type ContactFilters = {
  owner_id?: number,
  owner_type?: string,
  string?: string,
  user_id?: number,
  user_type?: number,
}

type UseContactsOptions = {
  filters?: ContactFilters,
  performHttpRequests?: boolean,
}

function useContacts(options: UseContactsOptions = {}) {
  const { filters = {} } = options
  const {
    owner_id: filterOwnerId,
    owner_type: filterOwnerType,
    string: filterString,
    user_id: filterUserId,
    user_type: filterUserType,
  } = filters

  const {
    updatedEntities: { contacts: contactsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { contacts } = useSelector(reduxState => reduxState.entities)

  const filteredContacts = useMemo(() => {
    const filtered = Object.values(contacts).filter((contact: ContactModel) => {
      const {
        full_name,
        owner_id,
        owner_type,
        user_type_id,
        users_with_read_access_ids = [],
        users_with_write_access_ids = [],
      } = contact

      const contactUserIds = [...users_with_read_access_ids, ...users_with_write_access_ids]

      const ownerMatch = matchFilterNumber(owner_id, filterOwnerId)
      const ownerTypeMatch = matchFilterString(owner_type, filterOwnerType)
      const titleMatch = matchFilterString(full_name, filterString)
      const userTypeMatch = matchFilterNumber(user_type_id, filterUserType)
      const userMatch = filterUserId ? matchFilterArrayIncludes(contactUserIds, Number(filterUserId)) : true

      return ownerMatch && ownerTypeMatch && titleMatch && userMatch && userTypeMatch
    })

    return sortArrayBy(filtered, 'desc', 'id')
  }, [contactsUpdatedAt, JSON.stringify(filters)])

  const filteredContactsCount = filteredContacts.length
  const hasFilteredContacts = !!filteredContactsCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredContactsCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingContacts } = useReduxAction(
    'contacts',
    'loadContacts',
    {
      ...defaultRequestOptions.contact,
      ...filtersWithOffset,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredContacts,
    filteredContactsCount,
    hasContacts: hasFilteredContacts,
    loading: loadingContacts,
  }
}

export default useContacts
