const defaultRequestOptions = {
  attachment: {
    include_attachment_data_store_items: true,
    include_attachment_file: true,
  },
  award: {
    include_award_data_store_items: true,
    include_award_default_image: true,
    include_award_image_sizes: [
      'thumb_90x90',
      'thumb_120x80',
      'thumb_640x360',
      'thumb_660x360',
      'thumb_1200x627',
      'thumb_1280x720',
      'thumb_2000x1500',
      'thumb_3500x2500',
    ],
  },
  caseStudy: {
    include_case_study_address: true,
    include_case_study_data_store_items: true,
    include_case_study_default_image: true,
    include_case_study_image_sizes: ['thumb_640x360', 'thumb_1280x720', 'thumb_2000x1500', 'thumb_3500x2500'],
    include_case_study_images: true,
    include_case_study_user_ids: true,
  },
  comparable: {
    include_comparable_address: true,
    include_comparable_data_store_items: true,
    include_comparable_default_image: true,
    include_comparable_images: true,
    include_comparable_image_sizes: [
      'thumb_90x90',
      'thumb_120x80',
      'thumb_200x120',
      'thumb_640x360',
      'thumb_660x360',
      'thumb_1280x720',
      'thumb_2000x1500',
      'thumb_3500x2500',
    ],
  },
  digitalPage: {
    include_digital_page_assigned_images: true,
    include_digital_page_comparables: true,
    include_digital_page_data_store_items: true,
    include_digital_page_image_sizes: ['thumb_640x360', 'thumb_1280x720', 'thumb_2000x1500', 'thumb_3500x2500'],
    include_digital_page_reviews: true,
    include_digital_page_share_links: true,
    include_digital_page_shortcode_data: true,
    include_digital_page_subject: true,
  },
  digitalTemplate: {
    include_digital_template_digital_template_components: true,
    include_digital_template_pages: true,
    include_digital_template_preview_image: true,
    include_digital_template_sidebar_template: true,
    include_digital_template_type: true,
  },
  digitalTemplatePage: {
    include_digital_template_page_assigned_form_templates: true,
    include_digital_template_page_assigned_images: true,
    include_digital_template_page_image_sizes: [
      'thumb_640x360',
      'thumb_1280x720',
      'thumb_2000x1500',
      'thumb_3500x2500',
    ],
    include_digital_template_page_section_groups: true,
  },
  eventCalendar: {
    include_event_calendar_event_groups: true,
    include_event_calendar_events: true,
  },
  formTemplate: {
    include_form_template_fields: true,
  },
  image: {
    include_image_sizes: ['thumb_90x90', 'thumb_640x360', 'thumb_660x360', 'thumb_1200x627'],
  },
  organization: {
    include_organization_addresses: true,
    include_organization_awards: true,
    include_organization_users: true,
  },
  project: {
    include_project_address: true,
    include_project_attachments: true,
    include_project_case_studies: true,
    include_project_combined_user_ids: true,
    include_project_comparables: true,
    include_project_contacts: true,
    include_project_data_store_items: true,
    include_project_image_sizes: ['thumb_640x360', 'thumb_1280x720', 'thumb_2000x1500', 'thumb_3500x2500'],
    include_project_images: true,
    include_project_lead_users: true,
    include_project_organization: true,
    include_project_reviews: true,
    include_project_reviews_users_hash: true,
    include_project_support_users: true,
    include_project_tagged_image_sizes: ['thumb_640x360', 'thumb_1280x720', 'thumb_2000x1500', 'thumb_3500x2500'],
    include_project_tagged_images: true,
    include_project_target_audiences: true,
    include_project_teams: true,
  },
  quote: {
    include_quote_items: true,
    include_quote_sections: true,
  },
  review: {
    include_review_default_image: true,
    include_review_image_sizes: ['thumb_640x360', 'thumb_1280x720', 'thumb_2000x1500', 'thumb_3500x2500'],
    include_review_user_ids: true,
  },
  shareLink: {
    include_share_link_paths: true,
  },
  targetAudience: {
    include_target_audience_image: true,
    include_target_audience_image_sizes: ['thumb_640x360', 'thumb_1280x720', 'thumb_2000x1500', 'thumb_3500x2500'],
  },
  team: {
    include_team_default_image: true,
    include_team_image_sizes: ['thumb_640x360', 'thumb_1280x720', 'thumb_2000x1500', 'thumb_3500x2500'],
    include_team_lead_users: true,
    include_team_support_users: true,
  },
  user: {
    include_user_awards: true,
    include_user_default_image: true,
    include_user_image_sizes: ['thumb_640x360', 'thumb_1280x720', 'thumb_2000x1500', 'thumb_3500x2500'],
    include_user_tagged_image_sizes: ['thumb_640x360', 'thumb_1280x720', 'thumb_2000x1500', 'thumb_3500x2500'],
    include_user_tagged_images: true,
  },
}

export default defaultRequestOptions
