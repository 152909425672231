import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'

import { useModals, useSetState } from '@campaignhub/react-hooks'

import { MainContent, ModalContext, TopBar } from '@campaignhub/suit-theme'

import useFocusMode from '@hooks/useFocusMode'
import useIntercom from '@hooks/useIntercom'
import useMainNavigation from '@hooks/useMainNavigation'
import useNumericParams from '@hooks/useNumericParams'
import useProjectSourcePlatformSync from '@hooks/useProjectSourcePlatformSync'
import useReduxAction from '@hooks/useReduxAction'

import PageContext from '@contexts/pageContext'

import DashboardSearch from '@components/DashboardSearch'
import LoggedInUser from '@components/LoggedInUser'
import MainNavigation from '@sections/Client/components/MainNavigation'

import DuplicateDigitalPageModal from '@modals/DuplicateDigitalPageModal'
import SelectDigitalTemplateModal from '@modals/SelectDigitalTemplateModal'

import ProjectRoutes from './routes'

import Navigation from './components/Navigation'

import defaultRequestOptions from './defaultRequestOptions'

const projectRequestOptions = {
  ...defaultRequestOptions.contact,
  ...defaultRequestOptions.image,
  ...defaultRequestOptions.project,
  ...defaultRequestOptions.team,
  ...defaultRequestOptions.user,
}

const selectDigitalTemplateId = (templateId, selectFn) => selectFn(templateId).then(({ success, result, redirectUrl }) => {
  if (!success){
    toast.warning(result ? result[0] : 'An error occurred')
    return
  }

  if (redirectUrl){
    window.location = redirectUrl
  }
})

const duplicateDigitalPage = (params, duplicateFn) => duplicateFn(params).then(({ success, result, redirectUrl }) => {
  if (!success){
    toast.warning(result ? result[0] : 'An error occurred')
    return
  }

  if (redirectUrl){
    window.location = redirectUrl
  }
})

const callbacks = (component, setState) => {
  const componentCallbacks = {
    DuplicateDigitalPageModal: {
      closeModal: () => setState({ showDuplicateDigitalPageModal: false }),
      duplicateDigitalPage: (params, duplicateFn) => duplicateDigitalPage(params, duplicateFn),
    },
    SelectDigitalTemplateModal: {
      closeModal: () => setState({ showSelectDigitalTemplateModal: false }),
      selectDigitalTemplateId: (templateId, selectFn) => selectDigitalTemplateId(templateId, selectFn),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  showDuplicateDigitalPageModal: false,
  showSelectDigitalTemplateModal: false,
}

const Project = () => {
  const { projectId } = useNumericParams()

  const [state, setState] = useSetState(defaultState)
  const { showDuplicateDigitalPageModal, showSelectDigitalTemplateModal } = state

  // Load Project
  useReduxAction('projects', 'loadProject', projectRequestOptions, [projectId], {
    dispatchAction: (action, requestOptions) => action(projectId, requestOptions),
    shouldPerformFn: ({ loadedIds, loading }) => projectId && !loadedIds.includes(projectId) && !loading,
  })

  // Sync with Source Platform
  const { project } = useProjectSourcePlatformSync({ id: projectId }, projectRequestOptions)

  const { enabled: focusModeEnabled } = useFocusMode()

  const {
    callbacks: { toggleNavigation },
  } = useMainNavigation()

  useIntercom()

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const pageContext = {
    callbacks: {
      showSelectDigitalTemplateModal: (payload) => {
        setModalData('SelectDigitalTemplateModal', payload)
        setState({ showSelectDigitalTemplateModal: true })
      },
      showDuplicateDigitalPageModal: () => {
        setModalData('DuplicateDigitalPageModal')
        setState({ showDuplicateDigitalPageModal: true })
      },
    },
  }

  const title = project.title ? `${project.title} | Engage` : 'Engage'

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <Helmet>
          <title>{title}</title>
        </Helmet>

        {!focusModeEnabled && (
          <TopBar
            callbacks={{ toggleNavigation }}
            loggedInUserComponent={<LoggedInUser />}
            nestedNavigation
            searchComponent={<DashboardSearch searchEntities={['Project']} />}
          />
        )}

        {!focusModeEnabled && <MainNavigation minimized nestedNavigation={<Navigation />} />}

        <MainContent
          nestedNavigation
          offset={{ left: focusModeEnabled ? 0 : undefined, top: focusModeEnabled ? 0 : TopBar.topBarHeight }}
        >
          <ProjectRoutes projectId={projectId} project={project} />
        </MainContent>

        <SelectDigitalTemplateModal
          callbacks={callbacks('SelectDigitalTemplateModal', setState)}
          showModal={showSelectDigitalTemplateModal}
        />

        <DuplicateDigitalPageModal
          callbacks={callbacks('DuplicateDigitalPageModal', setState)}
          showModal={showDuplicateDigitalPageModal}
          projectId={projectId}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default Project
