import { Box, ListItem, Text } from '@campaignhub/suit-theme'

import useProjectTheme from '@hooks/useProjectTheme'
import useProject from '@hooks/useProject'

import { ProjectModel } from '@models/project'

import ProjectIcon from '@components/ProjectIcon'

interface CompactListProps {
  project: ProjectModel,
}

const CompactList = (props: CompactListProps) => {
  const {
    project,
    project: { title },
  } = props

  const { colors, projectTypeKey } = useProjectTheme(project)

  const {
    urls: { overviewUrl },
  } = useProject(project)

  return (
    <ListItem
      boxProps={{
        as: 'a',
        borderBottom: '1px solid',
        paddingX: 'large',
        paddingY: 'medium',
      }}
      href={overviewUrl}
      showAngleIcon
    >
      <ProjectIcon color={colors.solid} height={32} projectTypeKey={projectTypeKey} width={32} />

      <Box marginLeft="medium" justifyContent="center" flexDirection="column">
        <Text fontSize="small">{title}</Text>
      </Box>
    </ListItem>
  )
}

export default CompactList
