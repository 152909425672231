import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers } from '@fortawesome/pro-light-svg-icons'

import { Box, StatusBadge, Text } from '@campaignhub/suit-theme'

import { statusColor } from '@functions/status'

import useLocalization from '@hooks/useLocalization'
import { useRelations } from '@hooks/useAgreement'

import localizedStrings from './localizedStrings'

const AgreementDetails = (props) => {
  const {
    agreementPayload: {
      agreement,
      agreement: { title },
      filteredRecipients,
    },
  } = props

  const { status } = useRelations(agreement)

  const { callbacks: { formatCount } } = useLocalization(localizedStrings)

  return (
    <Box borderBottom="1px solid" borderColor="lineColor" padding="large">
      <Box justifyContent="center" flexDirection="column">
        <Text fontSize="small" marginBottom="small" variant="ellipsis">
          {title}
        </Text>

        <Text color="bodyFontLightColor" fontSize="xsmall">
          <FontAwesomeIcon icon={faUsers} style={{ marginRight: 5 }} />
          {formatCount('recipients', { count: filteredRecipients.length })}
        </Text>
      </Box>

      <Box marginLeft="auto" width="auto" marginY="auto">
        <StatusBadge color={statusColor(status.key)} ghost text={status.title} />
      </Box>
    </Box>
  )
}

AgreementDetails.propTypes = {
  agreementPayload: PropTypes.shape({
    agreement: PropTypes.object,
    filteredRecipients: PropTypes.array,
    status: PropTypes.object,
  }).isRequired,
}

export default AgreementDetails
