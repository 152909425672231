import React from 'react'

import { BlankState } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import imageLibrariesUrl from './assets/no-image-libraries.svg'
import localizedStrings from './localizedStrings'

const ImageLibrariesBlankState = () => {
  const { strings } = useLocalization(localizedStrings)

  return (
    <BlankState imageUrl={imageLibrariesUrl}>
      <BlankState.Title>
        {strings.title || 'No Image Libraries'}
      </BlankState.Title>

      <BlankState.Paragraph>
        {strings.paragraph || 'Your agency doesn’t have any image libraries yet. You can create image libraries to organize collections of images you want to use in your marketing pages.'}
      </BlankState.Paragraph>
    </BlankState>
  )
}

export default ImageLibrariesBlankState
