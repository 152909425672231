export default {
  default: {
    buttons: {
      update: 'Update User',
    },
    header: {
      title: 'Edit',
      titleSecondLine: 'User',
    },
    formFieldLabels: {
      firstName: '* First Name',
      lastName: '* Last Name',
      legalName: 'Legal Name',
      email: '* Email',
      mobile: 'Mobile',
      timeZone: '* Time Zone',
    },
  },
}
