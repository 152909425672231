export default {
  default: {
    buttons: {
      createTemplate: 'Create Template',
    },
    documentFieldLabels: {
      title: '* Title',
    },
  },
}
