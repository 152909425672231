import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { ModalContext, SidebarModal } from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useDigitalPageItem from '@hooks/useDigitalPageItem'

import Text from './components/Text'
import Video from './components/Video'
import Map from './components/Map'

const itemComponents = {
  video: Video,
  text: Text,
  map: Map,
}

const EditDigitalPageItemCustomDataModal = (props) => {
  const {
    callbacks, disableAnimation, disableOverlay, modalKey, showModal,
  } = props

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, `${modalKey}`, {})
  const { digitalPage, id, itemType } = modalPayload

  const digitalPageItemPayload = useDigitalPageItem(digitalPage, id)

  const Component = itemComponents[itemType]
  if (!Component){
    return null
  }
  return (
    <SidebarModal
      callbacks={callbacks}
      disableAnimation={disableAnimation}
      disableOverlay={disableOverlay}
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <Component digitalPageItemPayload={digitalPageItemPayload} {...props} />
    </SidebarModal>
  )
}

EditDigitalPageItemCustomDataModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  disableAnimation: PropTypes.bool,
  disableOverlay: PropTypes.bool,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

EditDigitalPageItemCustomDataModal.defaultProps = {
  modalKey: 'EditDigitalPageItemCustomDataModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <EditDigitalPageItemCustomDataModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
