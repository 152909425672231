import React from 'react'

import { BlankState, Button } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

import blankTargetAudiencesUrl from './assets/no-target-audiences.svg'

const TargetAudiencesBlankState = () => {
  const { strings } = useLocalization(localizedStrings)

  return (
    <BlankState imageUrl={blankTargetAudiencesUrl} sidebar>
      <BlankState.Paragraph>
        {strings.paragraph}
      </BlankState.Paragraph>

      <BlankState.Actions>
        <Button
          as="a"
          buttonStyle="primaryEdit"
          ghost
          href="#/admin/targetAudiences"
          size="medium"
          width="auto"
        >
          {strings.createButton}
        </Button>
      </BlankState.Actions>
    </BlankState>
  )
}

export default TargetAudiencesBlankState
