import { useEffect, useRef } from 'react'
import queryString from 'query-string'

import { digObject } from '@campaignhub/javascript-utils'
import { useSetState } from '@campaignhub/react-hooks'

import api, { baseURLWithoutVersion } from '@functions/api'

import { handleError } from '@redux/utils'

type DefaultStateType = {
  csrfToken: string,
}

const defaultState: DefaultStateType = {
  csrfToken: '',
}

type SetState = (state: Partial<DefaultStateType>) => void

const setCsrfToken = (setState:SetState) => {
  const promise = api('/csrf_token.json', {}, { baseURL: baseURLWithoutVersion, withCredentials: true })
    .then(({ data }) => {
      const csrfToken = digObject(data, 'data.csrf_token')
      setState({ csrfToken })

      return { success: true, data: csrfToken }
    })
    .catch((data) => {
      const errors = handleError(data)
      return { success: false, errors }
    })

  return promise
}

type OAuthIntegrationOptionsType = {
    queryParams?: {
      integration_platform_key: string,
      owner_id: number,
      owner_type: string,
      redirect: string,
      subject_id?: number,
      subject_type?: string,
    },
}
function useOAuthIntegration(authProviderKey: string, options:OAuthIntegrationOptionsType = {}) {
  const { queryParams } = options || {}

  const [state, setState] = useSetState(defaultState)
  const { csrfToken } = state

  const formRef = useRef()

  useEffect(() => {
    setCsrfToken(setState)
  }, [])

  const baseHref = `${baseURLWithoutVersion}/auth/${authProviderKey}`

  return {
    callbacks: {
      onClick: () => formRef.current.submit(),
    },
    csrfToken,
    href: queryParams ? `${baseHref}?${queryString.stringify(queryParams)}` : baseHref,
    formRef,
  }
}

export default useOAuthIntegration
