import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/pro-light-svg-icons'

import { Box, Button, FormField } from '@campaignhub/suit-theme'

import { useSetState } from '@campaignhub/react-hooks'

import isValidEmail from '@functions/isValidEmail'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const addCustomEmail = (email, props, setState) => {
  const {
    callbacks: { addCustomEmail: addCustomEmailFn },
  } = props

  addCustomEmailFn(email)
  setState({ email: '', showCustomEmailInput: false })
}

const defaultState = {
  email: '',
  showCustomEmailInput: false,
}

const CustomMobile = (props) => {
  const { boxProps, disabled } = props

  const [state, setState] = useSetState(defaultState)
  const { email, showCustomEmailInput } = state

  const { strings } = useLocalization(localizedStrings)

  return (
    <Box {...boxProps}>
      {!showCustomEmailInput && (
        <Button
          buttonStyle="ghostEdit"
          disabled={disabled}
          icon={<FontAwesomeIcon icon={faEnvelope} />}
          onClick={() => setState({ showCustomEmailInput: !showCustomEmailInput })}
          size="medium"
          width="100%"
        >
          {strings.buttons?.customEmail || 'Add Custom Email'}
        </Button>
      )}

      {showCustomEmailInput && (
        <FormField fontSize="small">
          <Box flexDirection="row" alignItems="center">
            <FormField style={{ width: 'calc(80% - 8px)' }}>
              <input name="email" value={email} onChange={e => setState({ email: e.target.value })} />
            </FormField>

            <Button
              disabled={!isValidEmail(email)}
              buttonStyle="primaryEdit"
              marginLeft="medium"
              onClick={() => addCustomEmail(email, props, setState)}
              size="medium"
              width="20%"
            >
              {strings.buttons?.add || 'Add'}
            </Button>
          </Box>
        </FormField>
      )}
    </Box>
  )
}

CustomMobile.propTypes = {
  boxProps: PropTypes.object,
  callbacks: PropTypes.shape({
    addCustomEmail: PropTypes.func,
  }).isRequired,
  disabled: PropTypes.bool,
}

export default CustomMobile
