import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import { Link, SidebarModal } from '@campaignhub/suit-theme'

import { capitalizeString } from '@functions/string'

import useLocalization from '@hooks/useLocalization'
import useRecipient from '../../hooks/useRecipient'

import localizedStrings from './localizedStrings'

const Recipient = (props) => {
  const { callbacks: { toggleRecipient }, recipient } = props

  const {
    callbacks: { editRecipient },
    imageUrl,
    mobileNumber,
    showEditIcon,
    title,
    type,
  } = useRecipient(recipient)

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal.User
      disableHover
      imageUrl={imageUrl}
      subtitle={
        mobileNumber
        || `${capitalizeString(type)} ${strings.noMobile || 'has no mobile'}`
        || 'No mobile number'
      }
      title={title}
    >
      {showEditIcon && (
        <Link
          greyLink
          onClick={editRecipient}
          textProps={{ fontSize: 'small', marginRight: 'medium' }}
        >
          <FontAwesomeIcon icon={faPencil} />
        </Link>
      )}

      <Link
        greyLink
        onClick={() => toggleRecipient(recipient)}
        textProps={{ fontSize: 'small' }}
      >
        <FontAwesomeIcon icon={faTrashAlt} />
      </Link>
    </SidebarModal.User>
  )
}

Recipient.propTypes = {
  callbacks: PropTypes.shape({
    toggleRecipient: PropTypes.func,
  }).isRequired,
  recipient: PropTypes.object,
}

export default Recipient
