import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons'

import {
  Box, Button, Link, Text,
} from '@campaignhub/suit-theme'

import { useSetState } from '@campaignhub/react-hooks'
import { stringToKey } from '@campaignhub/javascript-utils'

import FieldHolder from '@components/FieldHolder'

const updateOptions = (value, props) => {
  const { propertyKey, updateCallback } = props
  updateCallback(propertyKey, value)
}

const addOption = (options, props, state, setState) => {
  const { availableOptions, skipStringToKey } = props
  const { key, value } = state

  const selectedOptionValue = availableOptions && availableOptions.find(v => v.key === key)

  options.push({
    key: skipStringToKey && key ? key : stringToKey(value),
    value: selectedOptionValue ? selectedOptionValue.value : value,
  })

  updateOptions(options, props)

  // eslint-disable-next-line no-use-before-define
  setState(defaultState)
}

const removeOption = (option, options, props) => {
  const updatedOptions = options.filter(o => o.key !== option.key)
  updateOptions(updatedOptions, props)
}

const handleSelect = (e, setState) => {
  const selectedOption = e.target.options[e.target.selectedIndex]
  setState({ key: e.target.value, value: selectedOption.text })
}

const defaultState = {
  key: '',
  value: '',
}

const NestedOptions = (props) => {
  const {
    availableOptions, data, itemKeyRequired, propertyKey, style, label,
  } = props

  const [state, setState] = useSetState(defaultState)
  const { key, value } = state

  const options = data && data[propertyKey] ? [...data[propertyKey]] : []

  return (
    <>
      <FieldHolder boxProps={{ ...style }} direction="column" label={label}>
        <Box>
          {availableOptions && (
            <select onChange={e => handleSelect(e, setState)} value={key}>
              <option value="">Please Select...</option>
              {availableOptions.map(({ key: valueKey, value: valueTitle }) => (
                <option key={valueKey} value={valueKey}>
                  {valueTitle}
                </option>
              ))}
            </select>
          )}

          {!availableOptions && (
            <>
              {itemKeyRequired && (
                <input
                  onChange={e => setState({ key: e.target.value })}
                  placeholder="Key"
                  style={{ marginRight: 8, width: 100 }}
                  type="text"
                  value={key}
                />
              )}

              <input
                onChange={e => setState({ value: e.target.value })}
                placeholder="Value"
                style={{ width: itemKeyRequired ? 150 : 'calc(100% - 43px)' }}
                type="text"
                value={value}
              />
            </>
          )}

          <Button
            buttonStyle="ghostCreate"
            disabled={itemKeyRequired ? !key || !value : !value}
            onClick={() => addOption(options, props, state, setState)}
            size="medium"
            style={{ marginLeft: 8, width: 'auto' }}
          >
            Add
          </Button>
        </Box>
      </FieldHolder>

      <Box flexDirection="column" marginBottom="large">
        {options.map(option => (
          <Box
            alignItems="center"
            key={option.key}
            color="bodyFontColor"
            fontSize="small"
            justifyContent="space-between"
            marginBottom="medium"
            padding="medium"
            variant="whiteGrey"
          >
            <Box flexDirection="column">
              <Text variant="twoLineEllipsis">{option.value}</Text>
              <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="small" variant="twoLineEllipsis">
                {option.key}
              </Text>
            </Box>

            <Link greyLink onClick={() => removeOption(option, options, props)}>
              <FontAwesomeIcon icon={faTrashAlt} />
            </Link>
          </Box>
        ))}
      </Box>
    </>
  )
}

NestedOptions.propTypes = {
  availableOptions: PropTypes.array,
  data: PropTypes.object.isRequired,
  itemKeyRequired: PropTypes.bool,
  label: PropTypes.string.isRequired,
  propertyKey: PropTypes.string.isRequired,
  skipStringToKey: PropTypes.bool,
  style: PropTypes.object,
}

export default NestedOptions
