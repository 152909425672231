import React from 'react'
import PropTypes from 'prop-types'

const PieChartIcon = (props) => {
  const { fillColor, height, width } = props

  return (
    <svg width={width} height={height} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M35.7012 4.1025C32.2632 1.39788 27.9718 -0.0603068 23.6097 0.00191196C18.6439 0.0706619 13.961 2.03072 10.4237 5.52082C6.88984 9.00764 4.86704 13.6569 4.72765 18.612C4.71871 18.9277 4.83507 19.2262 5.05542 19.4528C5.27619 19.6799 5.5719 19.8049 5.88841 19.8049H16.4879C16.844 19.8049 17.1325 19.5163 17.1325 19.1603C17.1325 18.8043 16.844 18.5157 16.4879 18.5157H6.02075C6.18266 13.944 8.06478 9.65982 11.3293 6.43872C14.6286 3.1834 18.9962 1.35517 23.6275 1.29106C27.6916 1.2358 31.6972 2.59301 34.9042 5.11579C35.1839 5.33588 35.5893 5.28741 35.8094 5.00768C36.0296 4.72787 35.9811 4.3225 35.7012 4.1025Z"
          fill={fillColor}
        />
        <path
          d="M43.0379 18.848C42.9628 14.1391 41.1507 9.63419 37.9352 6.16292C37.6935 5.90176 37.2855 5.88612 37.0242 6.12812C36.7631 6.36995 36.7474 6.77789 36.9894 7.03914C39.9885 10.2768 41.6788 14.478 41.7487 18.8685C41.8227 23.5168 40.1247 27.9379 36.9646 31.333L24.3361 18.7043C24.2152 18.5835 24.0513 18.5156 23.8802 18.5156H19.4256C19.0695 18.5156 18.781 18.8042 18.781 19.1602C18.781 19.5162 19.0696 19.8048 19.4256 19.8048H23.6133L36.1466 32.3383C36.3634 32.5549 36.6621 32.6785 36.9685 32.6785C36.9739 32.6785 36.9794 32.6784 36.9847 32.6783C37.3006 32.6739 37.5938 32.5451 37.8105 32.3158C41.2615 28.662 43.118 23.879 43.0379 18.848Z"
          fill={fillColor}
        />
        <path
          d="M9.62654 22.2831H8.59512C8.23899 22.2831 7.9505 22.5717 7.9505 22.9278C7.9505 23.2838 8.23908 23.5724 8.59512 23.5724H9.62654C9.98266 23.5724 10.2712 23.2839 10.2712 22.9278C10.2712 22.5716 9.98258 22.2831 9.62654 22.2831Z"
          fill={fillColor}
        />
        <path
          d="M7.01362 35.084C6.78855 34.8418 6.5676 34.5902 6.35697 34.3365C6.12949 34.0625 5.72301 34.025 5.44912 34.2523C5.17515 34.4797 5.13751 34.8861 5.36499 35.1602C5.59083 35.4322 5.82768 35.7018 6.06873 35.9615C6.19583 36.0983 6.36831 36.1674 6.5413 36.1674C6.69823 36.1674 6.85575 36.1104 6.97984 35.9952C7.24066 35.7529 7.25579 35.345 7.01362 35.084Z"
          fill={fillColor}
        />
        <path
          d="M2.29802 24.357C2.27413 24.0547 2.25763 23.7478 2.24912 23.448C2.28719 23.0984 2.03772 22.7819 1.68761 22.7385C1.33466 22.6951 1.0124 22.9455 0.968568 23.2989C0.962725 23.3463 0.959717 23.3944 0.959717 23.4423C0.959717 23.4542 0.959889 23.4665 0.960232 23.4747C0.969342 23.8018 0.987131 24.1328 1.01283 24.4588C1.03955 24.7964 1.32186 25.0525 1.65478 25.0525C1.67171 25.0524 1.6889 25.0518 1.70626 25.0504C2.06118 25.0223 2.32612 24.7119 2.29802 24.357Z"
          fill={fillColor}
        />
        <path
          d="M10.0233 37.6801C9.75066 37.4933 9.47979 37.2963 9.21785 37.0946C8.93563 36.8773 8.53086 36.9298 8.3137 37.2118C8.09645 37.4939 8.14896 37.8986 8.43092 38.116C8.71185 38.3323 9.00215 38.5435 9.29442 38.7436C9.40579 38.82 9.53255 38.8565 9.65819 38.8565C9.86367 38.8565 10.0656 38.7584 10.1905 38.5761C10.3918 38.2825 10.3169 37.8814 10.0233 37.6801Z"
          fill={fillColor}
        />
        <path
          d="M13.7522 22.2831H12.7208C12.3647 22.2831 12.0762 22.5717 12.0762 22.9278C12.0762 23.2838 12.3648 23.5724 12.7208 23.5724H13.7522C14.1083 23.5724 14.3968 23.2839 14.3968 22.9278C14.3968 22.5716 14.1083 22.2831 13.7522 22.2831Z"
          fill={fillColor}
        />
        <path
          d="M3.04967 28.2572C2.95162 27.943 2.86095 27.6208 2.78 27.2994C2.69312 26.9541 2.34292 26.745 1.99754 26.8316C1.65224 26.9186 1.4429 27.2689 1.52978 27.6141C1.61649 27.9586 1.71386 28.3041 1.81905 28.6411C1.90516 28.9171 2.1597 29.094 2.43419 29.094C2.49778 29.094 2.56249 29.0845 2.62634 29.0645C2.96631 28.9584 3.15589 28.597 3.04967 28.2572Z"
          fill={fillColor}
        />
        <path
          d="M4.64907 31.8915C4.48339 31.6064 4.32354 31.3129 4.17393 31.0187C4.01262 30.7014 3.6247 30.5748 3.30708 30.7364C2.98979 30.8977 2.86338 31.2859 3.02486 31.6032C3.18522 31.9187 3.35666 32.2336 3.53438 32.5394C3.654 32.7453 3.87014 32.8602 4.09229 32.8602C4.2022 32.8602 4.31366 32.8321 4.41567 32.7728C4.7235 32.5939 4.828 32.1993 4.64907 31.8915Z"
          fill={fillColor}
        />
        <path
          d="M5.50084 22.2831H4.46942C4.11329 22.2831 3.8248 22.5717 3.8248 22.9278C3.8248 23.2838 4.11338 23.5724 4.46942 23.5724H5.50084C5.85697 23.5724 6.14546 23.2839 6.14546 22.9278C6.14546 22.5716 5.85697 22.2831 5.50084 22.2831Z"
          fill={fillColor}
        />
        <path
          d="M20.1127 35.176C19.7565 35.176 19.468 35.4645 19.468 35.8206V36.852C19.468 37.2081 19.7566 37.4966 20.1127 37.4966C20.4687 37.4966 20.7573 37.208 20.7573 36.852V35.8206C20.7573 35.4645 20.4687 35.176 20.1127 35.176Z"
          fill={fillColor}
        />
        <path
          d="M20.1126 39.3015C19.7565 39.3015 19.468 39.59 19.468 39.9461V40.9134C19.4329 41.2644 19.6868 41.579 20.0383 41.618C20.0624 41.6207 20.0865 41.6221 20.1103 41.6221C20.4345 41.6221 20.7136 41.3783 20.7501 41.0485C20.7548 41.006 20.7572 40.9626 20.7572 40.9196V39.9461C20.7572 39.5901 20.4687 39.3015 20.1126 39.3015Z"
          fill={fillColor}
        />
        <path
          d="M20.1127 31.0502C19.7565 31.0502 19.468 31.3387 19.468 31.6948V32.7263C19.468 33.0824 19.7566 33.3709 20.1127 33.3709C20.4687 33.3709 20.7573 33.0823 20.7573 32.7263V31.6948C20.7573 31.3387 20.4687 31.0502 20.1127 31.0502Z"
          fill={fillColor}
        />
        <path
          d="M20.1127 22.7989C19.7565 22.7989 19.468 23.0874 19.468 23.4435V24.4749C19.468 24.8311 19.7566 25.1195 20.1127 25.1195C20.4687 25.1195 20.7573 24.831 20.7573 24.4749V23.4435C20.7573 23.0875 20.4687 22.7989 20.1127 22.7989Z"
          fill={fillColor}
        />
        <path
          d="M13.5316 39.548C13.2239 39.426 12.9159 39.2938 12.616 39.155C12.2931 39.0058 11.9099 39.1461 11.7601 39.4692C11.6107 39.7924 11.7513 40.1755 12.0744 40.3251C12.3961 40.4738 12.7265 40.6158 13.0566 40.7466C13.1345 40.7775 13.2149 40.7921 13.2939 40.7921C13.5504 40.7921 13.7931 40.6378 13.8934 40.3848C14.0246 40.0539 13.8626 39.6791 13.5316 39.548Z"
          fill={fillColor}
        />
        <path
          d="M20.1127 26.9246C19.7565 26.9246 19.468 27.2131 19.468 27.5692V28.6006C19.468 28.9567 19.7566 29.2452 20.1127 29.2452C20.4687 29.2452 20.7573 28.9566 20.7573 28.6006V27.5692C20.7573 27.2131 20.4687 26.9246 20.1127 26.9246Z"
          fill={fillColor}
        />
        <path
          d="M17.3658 40.5889C17.0392 40.5385 16.71 40.4783 16.3871 40.4098C16.0396 40.3362 15.6967 40.5583 15.6228 40.9067C15.5489 41.255 15.7713 41.5971 16.1196 41.671C16.4659 41.7444 16.819 41.809 17.1692 41.863C17.2026 41.8681 17.2356 41.8706 17.2683 41.8706C17.5809 41.8706 17.8554 41.6428 17.9045 41.3242C17.959 40.9724 17.7177 40.6431 17.3658 40.5889Z"
          fill={fillColor}
        />
        <path
          d="M17.8778 22.2831H16.8464C16.4903 22.2831 16.2018 22.5717 16.2018 22.9278C16.2018 23.2838 16.4904 23.5724 16.8464 23.5724H17.8778C18.2339 23.5724 18.5224 23.2839 18.5224 22.9278C18.5224 22.5716 18.2339 22.2831 17.8778 22.2831Z"
          fill={fillColor}
        />
        <path
          d="M37.0585 37.1136L32.1528 32.2079C31.901 31.9563 31.4929 31.9563 31.2411 32.2079C30.9894 32.4597 30.9894 32.8678 31.2411 33.1196L36.0529 37.9315C32.9035 40.8623 28.8195 42.5538 24.5248 42.7064V26.4033L29.1639 31.0425C29.4157 31.2941 29.8238 31.2942 30.0756 31.0425C30.3273 30.7907 30.3273 30.3826 30.0756 30.1308L25.2164 25.2717C24.8833 24.9386 24.3872 24.8401 23.9519 25.0202C23.5167 25.2005 23.2357 25.6212 23.2357 26.0923V42.8392C23.2357 43.1557 23.3606 43.4514 23.5877 43.6721C23.8059 43.8843 24.0912 44.0002 24.3939 44.0002C24.405 44.0002 24.4162 44 24.4274 43.9997C29.133 43.8678 33.6112 42.0128 37.037 38.7766C37.2658 38.5603 37.3944 38.2676 37.3989 37.952C37.4032 37.6398 37.2791 37.3343 37.0585 37.1136Z"
          fill={fillColor}
        />
        <path
          d="M14.0925 31.0081C13.1599 31.0081 12.4012 31.7667 12.4012 32.6993C12.4012 33.6319 13.1599 34.3905 14.0925 34.3905C15.0251 34.3905 15.7837 33.6319 15.7837 32.6993C15.7837 31.7667 15.0251 31.0081 14.0925 31.0081ZM14.0925 33.1013C13.8708 33.1013 13.6905 32.921 13.6905 32.6993C13.6905 32.4777 13.8707 32.2974 14.0925 32.2974C14.3142 32.2974 14.4945 32.4777 14.4945 32.6993C14.4945 32.9209 14.3142 33.1013 14.0925 33.1013Z"
          fill={fillColor}
        />
        <path
          d="M9.84137 26.7571C8.90877 26.7571 8.15012 27.5156 8.15012 28.4482C8.15012 29.3808 8.90877 30.1395 9.84137 30.1395C10.774 30.1395 11.5325 29.3808 11.5325 28.4482C11.5325 27.5156 10.774 26.7571 9.84137 26.7571ZM9.84137 28.8503C9.61973 28.8503 9.43935 28.67 9.43935 28.4482C9.43935 28.2267 9.61965 28.0463 9.84137 28.0463C10.0629 28.0463 10.2433 28.2266 10.2433 28.4482C10.2433 28.6699 10.063 28.8503 9.84137 28.8503Z"
          fill={fillColor}
        />
        <path
          d="M14.5645 27.994C14.3137 27.7413 13.9055 27.74 13.6529 27.9909L9.37234 32.2419C9.11985 32.4928 9.11839 32.9009 9.36925 33.1536C9.49523 33.2805 9.661 33.3439 9.82661 33.3439C9.99083 33.3439 10.1551 33.2815 10.2809 33.1567L14.5614 28.9056C14.814 28.6546 14.8155 28.2466 14.5645 27.994Z"
          fill={fillColor}
        />
        <path
          d="M18.0401 14.3647H11.6488C11.2927 14.3647 11.0042 14.6533 11.0042 15.0093C11.0042 15.3653 11.2927 15.6539 11.6488 15.6539H18.0401C18.3962 15.6539 18.6847 15.3653 18.6847 15.0093C18.6847 14.6533 18.3962 14.3647 18.0401 14.3647Z"
          fill={fillColor}
        />
        <path
          d="M11.6446 11C11.2885 11 11 11.2886 11 11.6446C11 12.0007 11.2886 12.2892 11.6446 12.2892H11.6531C12.0092 12.2892 12.2935 12.0007 12.2935 11.6446C12.2935 11.2886 12.0008 11 11.6446 11Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="44" height="44" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

PieChartIcon.propTypes = {
  fillColor: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
}

PieChartIcon.defaultProps = {
  fillColor: '#000000',
  height: 40,
  width: 40,
}

export default PieChartIcon
