import React from 'react'

import { BlankState } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

import shareLinkImageUrl from './assets/share-link.svg'

const ShareLinkBlankState = () => {
  const { strings } = useLocalization(localizedStrings)

  return (
    <BlankState imageUrl={shareLinkImageUrl}>
      <BlankState.Title>{strings.title || "You haven't created any share links"}</BlankState.Title>
      <BlankState.Paragraph>
        {strings?.paragraph
          || 'This project has no share links created. You can create a digital page which will automatically generate proofing and tracking links'}
      </BlankState.Paragraph>
    </BlankState>
  )
}

export default ShareLinkBlankState
