import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignIn } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, DashboardModule, Text,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import IntegrationPlatformLogo from '@components/IntegrationPlatformLogo'

import useMixpanel from '@hooks/useMixpanel'
import type { MixPanelType } from '@hooks/useMixpanel'

import localizedStrings from './localizedStrings'
import useIntegrationPartner from './hooks/useIntegrationPartner'

type HandleOnClickParams = {
  dispatchMixpanelEvent: MixPanelType,
  valueProposition: (payload: object) => void,
}

const handleOnClick = (params: HandleOnClickParams) => {
  const { dispatchMixpanelEvent, valueProposition } = params

  dispatchMixpanelEvent('RTA Integrations CTA Click')
  valueProposition({})
}

interface IntegrationPartnerProps {
  description: string,
  integrationPlatformKey: string,
  marginTop?: string | number,
  title: string,
}

const IntegrationPartner = (props: IntegrationPartnerProps) => {
  const {
    description, integrationPlatformKey, marginTop, title,
  } = props

  const integrationPartnerPayload = useIntegrationPartner({ integrationPlatformKey })
  const {
    callbacks: { valueProposition },
  } = integrationPartnerPayload

  const {
    callbacks: { dispatchMixpanelEvent },
  } = useMixpanel()

  const { strings } = useLocalization(localizedStrings)

  return (
    <DashboardModule boxProps={{ marginBottom: 0, marginTop }} title={strings.title || 'Integration Partner'}>
      <Box flexDirection="column" padding="large">
        <Box flexDirection="column">
          <Box
            height={150}
            padding="large"
            border="1px solid"
            borderColor="lineColor"
            overflow="hidden"
            borderRadius={5}
            marginBottom="large"
          >
            <IntegrationPlatformLogo integrationPlatformKey={integrationPlatformKey} />
          </Box>

          <Box flexDirection="column">
            <Text fontSize="medium" lineHeight="1.3">
              {title}
            </Text>

            <Text fontSize="xsmall" marginTop="medium" lineHeight="1.3" color="bodyFontLightColor">
              {description}
            </Text>
          </Box>
        </Box>

        <Box
          alignItems="center"
          marginTop="medium"
          paddingTop="medium"
          backgroundColor="white"
          borderTop="1px solid"
          borderColor="lineColor"
          bottom="0"
          flexDirection="row"
        >
          <Button
            buttonStyle="primaryEdit"
            onClick={() => handleOnClick({ dispatchMixpanelEvent, valueProposition })}
            icon={<FontAwesomeIcon icon={faSignIn} />}
            size="medium"
          >
            {strings.getStarted || 'Get Started'}
          </Button>
        </Box>
      </Box>
    </DashboardModule>
  )
}

export default IntegrationPartner
