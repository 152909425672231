/* eslint-disable import/no-cycle */
import Animation from './components/elements/Animation'
import Button from './components/elements/Button'
import Container from './components/elements/Container'
import ContainerRepeater from './components/elements/ContainerRepeater'
import Counter from './components/elements/Counter'
import Icon from './components/elements/Icon'
import Image from './components/elements/Image'
import ImageCarousel from './components/elements/ImageCarousel'
import Input from './components/elements/Input'
import MediaContainer from './components/elements/MediaContainer'
import Placeholder from './components/elements/Placeholder'
import TextBlock from './components/elements/TextBlock'

// If you add an item here you also need to add the object partial
// that is created in functions/digitalTemplate (itemPartials)

const availableComponents = {
  advancedChart: Placeholder,
  animation: Animation,
  button: Button,
  carousel: Container,
  chart: Placeholder,
  container: Container,
  counter: Counter,
  dataArrayLoop: ContainerRepeater,
  formTemplate: Container,
  hashLink: Container,
  icon: Icon,
  iframe: Container,
  image: Image,
  imageCarousel: ImageCarousel,
  input: Input,
  map: Placeholder,
  mediaContainer: MediaContainer,
  state: Container,
  text: TextBlock,
  video: Placeholder,
}

export default availableComponents
