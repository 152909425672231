import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@campaignhub/suit-theme'

import { stringToTitleCase } from '@functions/string'

import FieldHolder from '@components/FieldHolder'
import GoogleAddressSearch from '@components/GoogleAddressSearch'
import SelectedListItem from '@components/SelectedListItem'

import useDigitalTemplateMapData from './hooks/useDigitalTemplateMapData'

const MapData = (props) => {
  const { callbacks: { updateData }, itemData } = props

  const mapDataPayload = useDigitalTemplateMapData({ itemData, updateData })
  const {
    callbacks: {
      setState, toggleLocation, updateComparableSource, updateComparableStatus, updateLocationSource,
    },
    comparableSource,
    comparableStatus,
    dataSource,
    locations,
    locationSource,
  } = mapDataPayload

  return (
    <>
      <FieldHolder label="Data Source">
        <select onChange={e => setState({ dataSource: e.target.value })} value={dataSource}>
          <option value="">Please Select...</option>
          <option key="custom" value="custom">
            Custom
          </option>
          <option key="contextual" value="contextual">
            Contextual
          </option>
        </select>
      </FieldHolder>

      {dataSource && dataSource !== 'custom' && (
        <FieldHolder boxProps={{ marginTop: 'large' }} label="Source">
          <select onChange={e => updateLocationSource(e.target.value)} value={locationSource}>
            <option value="">Please Select...</option>
            <option key="organization" value="organization">Organization</option>
            <option key="project" value="project">Project</option>
            <option key="project_comparables" value="project_comparables">Project Comparables</option>
          </select>
        </FieldHolder>
      )}

      {dataSource && dataSource !== 'custom' && locationSource === 'project_comparables' && (
        <Box flexDirection="row">
          <FieldHolder boxProps={{ marginTop: 'large' }} halfWidth label="Comparable Source">
            <select
              onChange={e => updateComparableSource(e.target.value)}
              value={comparableSource}
            >
              <option key="all" value="all">All</option>
              <option key="market" value="market">Market</option>
              <option key="organization" value="organization">Organization</option>
            </select>
          </FieldHolder>

          <FieldHolder boxProps={{ marginTop: 'large' }} halfWidth label="Comparable Status">
            <select
              onChange={e => updateComparableStatus(e.target.value)}
              value={comparableStatus}
            >
              <option key="all" value="all">All</option>
              <option key="current" value="current">Current</option>
              <option key="sold" value="sold">Sold</option>
            </select>
          </FieldHolder>
        </Box>
      )}

      {dataSource && dataSource === 'custom' && (
        <Box marginTop="large">
          <GoogleAddressSearch
            callbacks={{
              selectItem: location => toggleLocation({ ...location, source: dataSource }),
            }}
          />
        </Box>
      )}

      {!!locations.length && (
        <FieldHolder boxProps={{ marginTop: 'large' }} label="Selected Locations">
          {locations.map((location, index) => {
            const {
              region_name, state_name, street_number, street_name, source,
            } = location

            const title = source === 'custom' ? `${street_number} ${street_name}` : stringToTitleCase(source)
            const subTitle = source === 'custom' ? `${region_name} ${state_name}` : 'Address'

            return (
              <SelectedListItem
                boxProps={{ marginTop: 'medium' }}
                callbacks={{ toggleItem: () => toggleLocation(location) }}
                title={title}
                subTitle={subTitle}
                key={location.google_place_id || `${source}-${index}`}
              />
            )
          })}
        </FieldHolder>
      )}
    </>
  )
}

MapData.propTypes = {
  callbacks: PropTypes.shape({
    updateData: PropTypes.func.isRequired,
  }).isRequired,
  itemData: PropTypes.object.isRequired,
}

export default MapData
