import { useEffect, useState } from 'react'

import { useDebounce } from '@campaignhub/react-hooks'

import useSelector from '@hooks/useSelector'
import useOrganizations from '@hooks/useOrganizations'

type Options = {
  permittedOrganizationIds: Array<number>,
  filters: {
    brand_id: number,
    string?: string,
  },
}

const useManageDigitalTemplatePermissionsModal = (options: Options) => {
  const { permittedOrganizationIds, filters } = options

  const { brand_id, string } = filters

  const [selectedOrganizationIds, setSelectedOrganizationIds] = useState<number[]>([])

  useEffect(() => setSelectedOrganizationIds(permittedOrganizationIds || []), [])

  const debouncedString = useDebounce(string, 300)
  const saveEnabled = brand_id || debouncedString.length > 2 || !!selectedOrganizationIds.length

  const organizationsPayload = useOrganizations(
    {
      filters: { brand_id, string: debouncedString },
      performHttpRequests: true,
    },
  )
  const {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredOrganizationIds,
    hasOrganizations,
  } = organizationsPayload

  const organizations = useSelector(reduxState => reduxState.entities.organizations)

  const { updating } = useSelector(reduxState => reduxState.digitalTemplatesOrganizations)

  return {
    organizations,
    callbacks: {
      loadMore,
      setSelectedOrganizationIds,
    },
    canLoadMore,
    filteredOrganizationIds,
    filteredOrganizationsCount: filteredOrganizationIds?.length,
    hasActiveFilters: !!brand_id || !!string,
    hasOrganizationsToAssign: hasOrganizations,
    saveEnabled,
    selectedOrganizationIds,
    updating,
  }
}

export default useManageDigitalTemplatePermissionsModal
