import React, { useContext } from 'react'

import { Box, Button } from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/pro-solid-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'
import PageContext from '@contexts/pageContext'

import AreaChart from './components/AreaChart'
import BarChart from './components/BarChart'
import LineChart from './components/LineChart'
import PieChart from './components/PieChart'

import styles from './styles.module.scss'

const availableCharts = {
  areaChart: AreaChart,
  barChart: BarChart,
  lineChart: LineChart,
  pieChart: PieChart,
}

interface AdvancedChartProps {
  context: Record<string, unknown>,
  options: Record<string, unknown>,
  style: Record<string, unknown>,
}

export type ChartType = 'areaChart' | 'lineChart' | 'barChart' | 'pieChart'

const getSelectedChart = (chartType: string, data: []) => {
  if (!data.length){
    return availableCharts.barChart
  }

  return availableCharts[chartType as ChartType]
}

const AdvancedChart = (props: AdvancedChartProps) => {
  const { context: componentContext, options, style } = props

  // Context
  const pageContext = useContext(PageContext)
  const {
    callbacks, editing, shortcodeData,
  } = pageContext || {}

  const contextData = { ...shortcodeData, ...componentContext }

  const { manageDigitalPageFeature } = callbacks || {}

  const chartType:ChartType = digObject(options, 'chartType', 'lineChart')
  const chartIndex = digObject(options, 'chartNumber', 0)

  const chartData = digObject(contextData, `chart_data.${chartIndex}.data`, [])

  const SelectedChart = getSelectedChart(chartType, chartData)

  const primaryColor = digObject(options, 'primaryColor', '')
  const accentColor = digObject(options, 'accentColor', '')

  const chartHeight = digObject(style, 'height', '300px')
  const chartWidth = digObject(style, 'width', '100%')

  const chartInnerRadius = digObject(options, 'innerRadius', 160)
  const chartOuterRadius = digObject(options, 'outerRadius', 200)

  // This is because builders can remove the height accidentally.
  const formattedChartHeight = Number.isNaN(parseInt(chartHeight, 10)) ? 300 : parseInt(chartHeight, 10)

  if (!SelectedChart) return null

  const customDataPayload = {
    dataArray: chartData || [],
    dataKeys: ['values'],
  }

  return (
    <Box
      className={editing ? styles.root : null}
      display="block"
      minHeight={editing ? 100 : null}
      position="relative"
    >
      <SelectedChart
        dataPayload={customDataPayload}
        height={formattedChartHeight}
        width={chartWidth}
        chartOptions={{
          accentColor,
          disableLegend: true,
          innerRadius: chartInnerRadius,
          outerRadius: chartOuterRadius,
          primaryColor,
        }}
      />
      {editing && (
        <Box
          alignItems="center"
          className={styles.editOverlay}
          justifyContent="center"
          onClick={(e) => {
            e.stopPropagation()
            manageDigitalPageFeature('chart_data', options)
          }}
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
        >
          <Button buttonStyle="primaryEdit" icon={<FontAwesomeIcon icon={faPencil} />} size="medium" width="auto">
            Edit
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default AdvancedChart
