export default {
  default: {
    buttons: {
      void: 'Void Agreement',
      download: 'Download Agreement',
      history: 'View History',
    },
    formFieldLabels: {
      moreOptions: 'More Options',
    },
    sideBarModalHeader: {
      title: 'Agreement',
      titleSecondLine: 'Progress',
    },
    swal: {
      confirmButtonText: 'Yes, void it.',
      inputPlaceholder: 'Please add reason for voiding',
      text: 'Voiding the agreement will notify signers and will require you to resend the document.',
      title: 'Void Agreement?',
    },
    inPersonSigners: 'In Person Signing',
    remoteSigners: 'Remote Signing',
  },
}
