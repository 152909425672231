import React from 'react'
import PropTypes from 'prop-types'

import { Box, Text } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const QuoteTotal = (props) => {
  const { total } = props

  const {
    callbacks: { formatCurrency },
    strings,
  } = useLocalization(localizedStrings)

  return (
    <Box borderTop="1px solid" borderColor="lineColor" padding="large">
      <Box flexDirection="column" alignItems="end">
        <Text color="bodyFontLightColor" fontSize="xsmall" lineHeight="1.3" marginBottom="medium">
          {strings.quoteTotal || 'Quote Total'}
        </Text>

        <Text fontSize="large" marginLeft="auto">
          {formatCurrency(total)}
        </Text>
      </Box>
    </Box>
  )
}

QuoteTotal.propTypes = {
  total: PropTypes.string,
}

export default QuoteTotal
