import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignIn } from '@fortawesome/pro-light-svg-icons'

import { Box, Button, Text } from '@campaignhub/suit-theme'

// import VideoPlayer from '@components/digitalRenderer/components/VideoPlayer'

interface HeaderProps {
  callbacks: {
    dispatchMixpanelEvent: () => void,
  },
  isMobile: boolean,
}

const Header = (props: HeaderProps) => {
  const {
    callbacks: { dispatchMixpanelEvent },
    isMobile,
  } = props

  return (
    <Box flexDirection={['column', 'row']}>
      <Box flexDirection="column">
        <Box flexDirection="column">
          <Text fontSize="xlarge">Real Time Agent</Text>

          <Text color="bodyFontLightColor" marginTop="small" fontSize="xsmall">
            Removing paperwork and saving you time
          </Text>
        </Box>

        <Box flexDirection="column" marginTop="large" lineHeight="1.5">
          <Text fontSize="medium" marginBottom="small">
            About RTA
          </Text>

          <Text color="bodyFontLightColor" fontSize="small">
            {`Go digital with tools to improve your agency’s
              efficiency so your team can focus on what matters most.
              Real Time Agent is a powerful digital workflow solution that
              allows you to create and sign agreements, contracts and manage your
              private sale and auction campaigns with ease.`}
          </Text>

          <Box marginBottom={isMobile ? 'large' : null} onClick={() => dispatchMixpanelEvent()}>
            <Button
              icon={<FontAwesomeIcon icon={faSignIn} />}
              buttonStyle="primaryEdit"
              marginTop="large"
              as="a"
              href="https://www.realtimeagent.com.au/#get_started"
              size="medium"
              target="_blank"
              width="auto"
            >
              Get Started
            </Button>
          </Box>
        </Box>
      </Box>

      {/* <Box height="100%" marginLeft={isMobile ? null : 'large'} overflow="hidden" borderRadius={5} marginBottom="large">
        <VideoPlayer
          videoData={{
            videoId: 'CP6UcnCgQGo',
            videoProvider: 'youtube',
          }}
        />
      </Box> */}
    </Box>
  )
}

export default Header
