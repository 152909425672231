import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Button,
  InputSearch,
  FormField,
  LoadingModule,
  SectionDivider,
  SidebarModal,
} from '@campaignhub/suit-theme'

import DigitalTemplateTile from '@components/DigitalTemplateTile'
import EntitySelectBox from '@components/EntitySelectBox'

import useDigitalTemplate from '@hooks/useDigitalTemplate'
import useLocalization from '@hooks/useLocalization'
import useDuplicateDigitalTemplate from './hooks/useDuplicateDigitalTemplate'

import SelectedTemplate from './components/SelectedTemplate'
import TemplateListBlankState from './components/TemplateListBlankState'

import localizedStrings from './localizedStrings'

const DuplicateDigitalTemplateModal = (props) => {
  const { callbacks, modalKey, showModal } = props

  const { forkDigitalTemplate } = callbacks || {}

  const {
    callbacks: { setState },
    creating,
    canCreateTemplate,
    filteredDigitalTemplates,
    hasDigitalTemplates,
    hasMultipleOrganizations,
    images,
    loading,
    selectedDigitalTemplate,
    selectedOwner,
    state: { filterString, owner_id, title },
  } = useDuplicateDigitalTemplate()

  const {
    callbacks: { forkDigitalTemplate: forkFn },
  } = useDigitalTemplate(selectedDigitalTemplate)

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.sideBarModalHeader?.title || 'Template'}
        titleSecondLine={
          selectedDigitalTemplate.id
            ? strings.sideBarModalHeader?.titleSecondLine?.details || 'Details'
            : strings.sideBarModalHeader?.titleSecondLine?.library || 'Library'
        }
      />

      <SidebarModal.Content>
        <Box flexDirection="column">
          <LoadingModule loading={loading} times={3} />

          {!loading && (
            <FormField direction="column" label={strings.formFieldLabels?.filter || 'Filter Templates'}>
              <InputSearch onChange={e => setState({ filterString: e.target.value })} value={filterString} />
            </FormField>
          )}

          {!loading && !hasDigitalTemplates && <TemplateListBlankState />}

          {!loading && hasDigitalTemplates && !selectedDigitalTemplate.id && (
            <>
              <SectionDivider boxProps={{ marginY: 'large' }}>
                {strings.sectionDividers?.selectTemplate || 'SELECT TEMPLATE TO DUPLICATE'}
              </SectionDivider>

              {filteredDigitalTemplates.map((digitalTemplate) => {
                const { id, preview_image_id } = digitalTemplate
                const image = images[preview_image_id] || {}

                return (
                  <DigitalTemplateTile
                    digitalTemplate={digitalTemplate}
                    key={id}
                    onClick={() => setState({ selectedDigitalTemplateId: digitalTemplate.id })}
                    previewImage={image}
                    showAngleRight={false}
                  />
                )
              })}
            </>
          )}
          {!loading && selectedDigitalTemplate.id && (
            <>
              <SelectedTemplate
                callbacks={{ deselectTemplate: () => setState({ selectedDigitalTemplateId: null }) }}
                digitalTemplate={selectedDigitalTemplate}
              />

              <SectionDivider boxProps={{ marginY: 'large' }}>
                {strings.sectionDividers?.templateDetails || 'Template Details'}
              </SectionDivider>

              {hasMultipleOrganizations && (
                <FormField direction="column" label={strings.formFieldLabels.organization || '* Organisation'}>
                  <EntitySelectBox
                    entityKey="organizations"
                    name="owner_id"
                    sortDirection="asc"
                    sortKey="title"
                    value={owner_id}
                    onChange={e => setState({ owner_id: e.target.value })}
                  />
                </FormField>
              )}

              <FormField
                marginTop="large"
                direction="column"
                label={strings.formFieldLabels?.title || '* Template Title'}
              >
                <input onChange={e => setState({ title: e.target.value })} value={title} />
              </FormField>
            </>
          )}
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!canCreateTemplate}
          loading={creating || loading}
          onClick={() => forkDigitalTemplate(selectedDigitalTemplate, selectedOwner, title, forkFn)}
          size="large"
        >
          {strings.buttons?.create || 'Create Template'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

DuplicateDigitalTemplateModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

DuplicateDigitalTemplateModal.defaultProps = {
  modalKey: 'DuplicateDigitalTemplateModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <DuplicateDigitalTemplateModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
