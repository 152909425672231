import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import cloneDeep from 'lodash/cloneDeep'

import { digObject, modifyGroupedIdsSort } from '@campaignhub/javascript-utils'
import {
  useForm, useLatestEntity, useThunkDispatch, useWatchEntityUpdates,
} from '@campaignhub/react-hooks'

import defaultState from '@models/digitalTemplatePage'

import denormalizeEntity from '@functions/denormalizeEntity'
import generateID from '@functions/generateID'
import { itemPartials } from '@functions/digitalTemplate'

import { sortSections } from '@hooks/useDigitalTemplatePageSectionGroup'

import * as editorActions from '@redux/modules/digitalTemplateBuilder/editor'
import * as digitalTemplatePageActions from '@redux/modules/digitalTemplatePage'

const watchEntityKeys = ['digitalTemplatePageSectionGroups', 'digitalTemplatePages', 'digitalTemplatePageSections']

const createOrUpdatePageItemAttachment = (attachment, digitalTemplatePage, dispatch, options) => {
  const { createOrUpdatePageItemAttachment: createOrUpdateFn } = digitalTemplatePageActions
  return dispatch(createOrUpdateFn(attachment, digitalTemplatePage, options))
}

const importLayout = (updatedTemplatePage, dispatch, options = {}) => {
  const { importDigitalTemplatePageLayout: importFn } = digitalTemplatePageActions
  return dispatch(importFn(updatedTemplatePage, { include_digital_template_page_section_groups: true, ...options }))
}

const importExternalComponentStyles = (digitalTemplatePage, dispatch, options = {}) => {
  const { importDigitalTemplatePageExternalComponentStyles: importFn } = digitalTemplatePageActions
  return dispatch(
    importFn(digitalTemplatePage, {
      include_digital_template_digital_template_components: true,
      include_digital_template_page_digital_template: true,
      include_digital_template_page_section_groups: true,
      ...options,
    }),
  )
}

const importExternalImages = (digitalTemplatePage, dispatch, options = {}) => {
  const { importDigitalTemplatePageExternalImages: importFn } = digitalTemplatePageActions
  return dispatch(importFn(digitalTemplatePage, { include_digital_template_page_section_groups: true, ...options }))
}

const restoreLayoutFileFromAttachment = (attachmentId, digitalTemplatePage, dispatch, options = {}) => {
  const { restoreLayoutFileFromAttachment: restoreFn } = digitalTemplatePageActions
  return dispatch(restoreFn(digitalTemplatePage, attachmentId, { include_digital_template_page_section_groups: true, ...options }))
}

const updateGroupSectionSort = (digitalTemplatePage, sortedGroupSections, dispatch, requestOptions) => {
  const { updateDigitalPageGroupSectionSort: updateFn } = digitalTemplatePageActions

  const mergedRequestOptions = {
    include_digital_template_page_section_groups: true,
    ...requestOptions,
  }

  return dispatch(updateFn(digitalTemplatePage, sortedGroupSections, mergedRequestOptions))
}

const createSectionGroup = (digitalTemplatePage, entities, dispatch) => {
  const { updateCanvasItem: updateFn } = editorActions

  const partial = {
    ...itemPartials.sectionGroup,
    id: `group-${generateID()}`,
  }

  const denormalizedEntity = denormalizeEntity('digitalTemplatePage', digitalTemplatePage, entities)
  const updatedEntity = { ...denormalizedEntity }

  const sectionGroups = updatedEntity.section_groups ? [...updatedEntity.section_groups.filter(group => group)] : []
  sectionGroups.push(partial)

  updatedEntity.section_groups = sectionGroups

  return dispatch(updateFn('digitalTemplatePage', updatedEntity))
}

export const getSectionIds = (digitalTemplatePage, digitalTemplatePageSectionGroups) => {
  const sectionGroups = digObject(digitalTemplatePage, 'section_groups', [])

  const sectionCount = sectionGroups.reduce((acc, sectionGroupId) => {
    const sectionGroup = digitalTemplatePageSectionGroups[sectionGroupId] || {}
    const sectionIds = digObject(sectionGroup, 'sections', [])

    return [...acc, ...sectionIds]
  }, [])

  return sectionCount
}

const sortSectionGroupIds = (ids, sectionGroups) => ids.sort((a, b) => {
  const groupA = sectionGroups[a] || {}
  const groupB = sectionGroups[b] || {}

  const sortA = groupA.options ? groupA.options.sort : 0
  const sortB = groupB.options ? groupB.options.sort : 0

  return sortA > sortB ? 1 : -1
})

const setupSectionGroupState = (sortedSectionGroups, digitalTemplatePageSections, setSortedGroupSections) => {
  const sortedGroupSections = sortedSectionGroups.reduce((acc, sectionGroup) => {
    const { sections: sectionIds } = sectionGroup

    const filteredSections = sectionIds
      .map(sectionId => digitalTemplatePageSections[sectionId])
      .filter(section => section)

    const sortedSections = sortSections(filteredSections)
    const sortedSectionIds = sortedSections.map(section => section.id)

    const sortable = digObject(sectionGroup, 'options.sortable', false)
    if (sortable){
      acc[sectionGroup.id] = cloneDeep(sortedSectionIds)
    }

    return acc
  }, {})

  setSortedGroupSections(sortedGroupSections)
}

export function useDigitalTemplatePageForm(digitalTemplatePage){
  const digitalTemplatePageForm = useForm(defaultState, { entity: digitalTemplatePage }, [digitalTemplatePage.id])
  return digitalTemplatePageForm
}

function useDigitalTemplatePage(initDigitalTemplatePage){
  const { entity: digitalTemplatePage } = useLatestEntity(initDigitalTemplatePage, 'digitalTemplatePages')
  const { digital_template_id } = digitalTemplatePage

  const [sortedGroupSections, setSortedGroupSections] = useState({})

  const dispatch = useThunkDispatch()

  const entities = useSelector(reduxState => reduxState.entities)
  const { digitalTemplatePageSections, digitalTemplatePageSectionGroups } = entities

  const {
    updatedEntities: {
      digitalTemplatePageSectionGroups: digitalTemplatePageSectionGroupsUpdatedAt,
      digitalTemplatePages: digitalTemplatePagesUpdatedAt,
      digitalTemplatePageSections: digitalTemplatePageSectionsUpdatedAt,
    },
  } = useWatchEntityUpdates(watchEntityKeys)

  // Section Groups
  const sortedSectionGroups = useMemo(() => {
    const sectionGroupIds = digitalTemplatePage.section_groups || []
    const sortedIds = sortSectionGroupIds(sectionGroupIds, digitalTemplatePageSectionGroups)

    const mapped = sortedIds.map(groupId => digitalTemplatePageSectionGroups[groupId])
    return mapped.filter(g => g) // filter null values
  }, [digitalTemplatePagesUpdatedAt, digitalTemplatePageSectionGroupsUpdatedAt, digital_template_id])

  useEffect(() => {
    if (sortedSectionGroups.length) setupSectionGroupState(sortedSectionGroups, digitalTemplatePageSections, setSortedGroupSections)
  }, [digitalTemplatePageSectionsUpdatedAt, sortedSectionGroups.length])

  const updating = useSelector(reduxState => reduxState.digitalTemplatePages.updating)

  return {
    callbacks: {
      createOrUpdatePageItemAttachment: (attachment, requestOptions) => createOrUpdatePageItemAttachment(attachment, digitalTemplatePage, dispatch, requestOptions),
      createSectionGroup: () => createSectionGroup(digitalTemplatePage, entities, dispatch),
      importExternalComponentStyles: requestOptions => importExternalComponentStyles(digitalTemplatePage, dispatch, requestOptions),
      restoreLayoutFileFromAttachment: (attachmentId, requestOptions) => restoreLayoutFileFromAttachment(attachmentId, digitalTemplatePage, dispatch, requestOptions),
      importExternalImages: requestOptions => importExternalImages(digitalTemplatePage, dispatch, requestOptions),
      importLayout: (updatedTemplatePage, requestOptions) => importLayout(updatedTemplatePage, dispatch, requestOptions),
      modifySectionSort: (fromIndex, toIndex, payload) => setSortedGroupSections(modifyGroupedIdsSort(fromIndex, toIndex, payload, sortedGroupSections)),
      updateGroupSectionSort: requestOptions => updateGroupSectionSort(digitalTemplatePage, sortedGroupSections, dispatch, requestOptions),
    },
    digitalTemplatePage,
    sortedGroupSections,
    sortedSectionGroups,
    updating,
  }
}

export default useDigitalTemplatePage
