import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus } from '@fortawesome/pro-light-svg-icons'

import { Button, FormField } from '@campaignhub/suit-theme'

import SelectRecipient from '@components/DocumentTemplateBuilder/components/SelectRecipient'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const ReassignRecipientItems = (props) => {
  const {
    callbacks: { deleteAndReassignPageItems },
    reassignRecipientItemsPayload: {
      callbacks: { setState },
      remainingRecipients,
      targetRecipientId,
    },
  } = props

  useEffect(() => {
    setState({ targetRecipientId: remainingRecipients[0]?.id })
  }, [])

  const { strings } = useLocalization(localizedStrings)

  return (
    <FormField
      direction="column"
      label={strings.formFieldLabels?.selectRecipient || 'Select Recipient to Transfer Items to'}
      marginTop="large"
    >
      <SelectRecipient
        callbacks={{
          selectItem: recipient => setState({ targetRecipientId: recipient.id }),
        }}
        documentRecipients={remainingRecipients}
        selectedRecipientId={targetRecipientId}
      />

      <Button
        buttonStyle="secondaryUtility"
        icon={<FontAwesomeIcon icon={faUserPlus} />}
        marginTop="small"
        onClick={() => deleteAndReassignPageItems(targetRecipientId)}
        size="medium"
      >
        {strings.buttons?.delete || 'Delete Recipient and Reassign Items'}
      </Button>
    </FormField>
  )
}

ReassignRecipientItems.propTypes = {
  callbacks: PropTypes.shape({
    deleteAndReassignPageItems: PropTypes.func.isRequired,
  }).isRequired,
  reassignRecipientItemsPayload: PropTypes.shape({
    callbacks: PropTypes.shape({
      setState: PropTypes.func.isRequired,
    }).isRequired,
    remainingRecipients: PropTypes.array.isRequired,
    targetRecipientId: PropTypes.number,
  }).isRequired,
}

export default ReassignRecipientItems
