import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons'

import {
  Box, Button, Link, Text,
} from '@campaignhub/suit-theme'

import { useSetState } from '@campaignhub/react-hooks'
import { stringToKey } from '@campaignhub/javascript-utils'

import FieldHolder from '@components/FieldHolder'

const updateDefaultState = (defaultState, props) => {
  const { propertyKey, updateCallback } = props

  updateCallback(propertyKey, defaultState)
}

const addToDefaultState = (itemDefaultState, props, state, setState) => {
  const { key, value } = state

  const updatedDefaultState = {
    ...itemDefaultState,
    [key]: value,
  }

  updateDefaultState(updatedDefaultState, props)

  // eslint-disable-next-line no-use-before-define
  setState(defaultState)
}

const removeFromDefaultState = (key, itemDefaultState, props) => {
  // eslint-disable-next-line no-param-reassign
  delete itemDefaultState[key]

  updateDefaultState(itemDefaultState, props)
}

const defaultState = {
  key: '',
  value: '',
}

const DefaultState = (props) => {
  const {
    data, propertyKey, style, label,
  } = props

  const [state, setState] = useSetState(defaultState)
  const { key, value } = state

  const itemDefaultState = data && data[propertyKey] ? data[propertyKey] : {}

  return (
    <>
      <FieldHolder boxProps={{ ...style }} direction="column" label={label}>
        <Box>
          <input
            onChange={e => setState({ key: stringToKey(e.target.value) })}
            placeholder="Key"
            style={{ marginRight: 8, width: 100 }}
            type="text"
            value={key}
          />

          <input
            onChange={e => setState({ value: e.target.value })}
            placeholder="Value"
            style={{ width: 150 }}
            type="text"
            value={value}
          />

          <Button
            buttonStyle="ghostCreate"
            disabled={!key || !value}
            onClick={() => addToDefaultState(itemDefaultState, props, state, setState)}
            size="medium"
            style={{ marginLeft: 8, width: 'auto' }}
          >
            Add
          </Button>
        </Box>
      </FieldHolder>

      <Box flexDirection="column" marginBottom="large">
        {Object.keys(itemDefaultState).map((defaultStateKey) => {
          const defaultStateValue = itemDefaultState[defaultStateKey] || {}

          return (
            <Box
              alignItems="center"
              key={defaultStateKey}
              color="bodyFontColor"
              fontSize="small"
              justifyContent="space-between"
              marginBottom="medium"
              padding="medium"
              variant="whiteGrey"
            >
              <Box flexDirection="row">
                <Text marginRight="small" variant="twoLineEllipsis">{defaultStateKey}</Text>
                <Text color="bodyFontLightColor" fontSize="small" variant="twoLineEllipsis">
                  {defaultStateValue}
                </Text>
              </Box>

              <Link greyLink onClick={() => removeFromDefaultState(defaultStateKey, itemDefaultState, props)}>
                <FontAwesomeIcon icon={faTrashAlt} />
              </Link>
            </Box>
          )
        })}
      </Box>
    </>
  )
}

DefaultState.propTypes = {
  data: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  propertyKey: PropTypes.string.isRequired,
  style: PropTypes.object,
}

export default DefaultState
