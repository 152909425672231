export default {
  default: {
    title: 'Event Calendars',
    buttons: {
      create: 'Create',
    },
    VideoModule: {
      title: 'Event Calendars',
      paragraph: 'What is an event calendar and why should you use them in your digital proposals?',
    },
  },
}
