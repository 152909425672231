import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

import { useFilters, useModals, useSetState } from '@campaignhub/react-hooks'
import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'
import PdfDownloadProvider from '@components/PdfDownloadProvider'

import BuildingAttachmentModal from '@modals/BuildingAttachmentModal'
import DuplicateDigitalPageModal from '@modals/DuplicateDigitalPageModal'
import EditShareLinkModal from '@modals/EditShareLinkModal'
import ManageShareLinksModal from '@modals/ManageShareLinksModal'
import SelectDigitalTemplateModal from '@modals/SelectDigitalTemplateModal'
import SendSMSModal from '@modals/SendSMSModal'

import PdfDownloadContext from '@contexts/pdfDownloadContext'

import defaultRequestOptions from '@sections/Client/packs/Project/defaultRequestOptions'

import useLocalization from '@hooks/useLocalization'

import PageContent from './components/PageContent'
import localizedStrings from './localizedStrings'

const createShareLink = (shareLinkParams, createFn, editShareLink, strings) => {
  createFn(shareLinkParams, { ...defaultRequestOptions.shareLink }).then(({ success, errors, data }) => {
    const { entity: shareLink } = data
    if (!success){
      toast.warn(errors[0])
      return
    }

    editShareLink(shareLink)
    toast(strings.createShareLinkToast || 'Share Link Created Successfully')
  })
}

const deleteShareLink = (deleteFn, setState, strings) => {
  deleteFn().then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    toast(strings.deleteShareLink || 'Share Link Deleted Successfully')
    setState({ showEditShareLinkModal: false })
  })
}

const updateShareLink = (shareLinkParams, updateFn, setState, strings) => {
  updateFn(shareLinkParams).then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    toast(strings.updateShareLink || 'Share Link Updated Successfully')
    setState({ showEditShareLinkModal: false })
  })
}

const selectDigitalTemplateId = (templateId, selectFn) => selectFn(templateId).then(({ success, result, redirectUrl }) => {
  if (!success){
    toast.warning(result ? result[0] : 'An error occurred')
    return
  }

  if (redirectUrl){
    window.location = redirectUrl
  }
})

const duplicateDigitalPage = (params, duplicateFn) => duplicateFn(params).then(({ success, result, redirectUrl }) => {
  if (!success){
    toast.warning(result ? result[0] : 'An error occurred')
    return
  }

  if (redirectUrl){
    window.location = redirectUrl
  }
})

const sendSMS = (payload, sendSMSFn, setState, strings) => {
  const { recipients, message } = payload

  sendSMSFn(recipients, message).then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    toast(strings?.send || 'Messages queued for delivery')
    setState({ showSendSMSModal: false })
  })
}

const callbacks = (component, setState, strings) => {
  const componentCallbacks = {
    DuplicateDigitalPageModal: {
      closeModal: () => setState({ showDuplicateDigitalPageModal: false }),
      duplicateDigitalPage: (params, duplicateFn) => duplicateDigitalPage(params, duplicateFn, strings),
    },
    EditShareLinkModal: {
      closeModal: () => setState({ showEditShareLinkModal: false }),
      deleteShareLink: deleteFn => deleteShareLink(deleteFn, setState, strings),
      updateShareLink: (shareLinkParams, updateFn) => updateShareLink(shareLinkParams, updateFn, setState, strings),
    },
    ManageShareLinksModal: {
      closeModal: () => setState({ showManageShareLinksModal: false }),
      createShareLink: (shareLinkParams, createFn, editShareLink) => createShareLink(shareLinkParams, createFn, editShareLink, strings),
    },
    SelectDigitalTemplateModal: {
      closeModal: () => setState({ showSelectDigitalTemplateModal: false }),
      selectDigitalTemplateId: (templateId, selectFn) => selectDigitalTemplateId(templateId, selectFn),
    },
    SendSMSModal: {
      closeModal: () => setState({ showSendSMSModal: false }),
      sendSMS: (payload, sendSMSFn) => sendSMS(payload, sendSMSFn, setState, strings),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  showDuplicateDigitalPageModal: false,
  showEditShareLinkModal: false,
  showManageShareLinksModal: false,
  showSelectDigitalTemplateModal: false,
  showSendSMSModal: false,
}

const DigitalPageList = (props) => {
  const { projectId } = props

  const [state, setState] = useSetState(defaultState)
  const {
    showDuplicateDigitalPageModal,
    showEditShareLinkModal,
    showManageShareLinksModal,
    showSelectDigitalTemplateModal,
    showSendSMSModal,
  } = state

  const {
    callbacks: filterCallbacks,
    defaultFilters,
    pageFilters,
  } = useFilters()

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const pageContext = useMemo(() => ({
    callbacks: {
      ...filterCallbacks,
      showEditShareLinkModal: (payload) => {
        setModalData('EditShareLinkModal', payload)
        setState({ showEditShareLinkModal: true })
      },
      showManageShareLinksModal: (payload) => {
        setModalData('ManageShareLinksModal', payload)
        setState({ showManageShareLinksModal: true })
      },
      showSelectDigitalTemplateModal: (payload) => {
        setModalData('SelectDigitalTemplateModal', payload)
        setState({ showSelectDigitalTemplateModal: true })
      },
      showSendSMSModal: (payload) => {
        setModalData('SendSMSModal', payload)
        setState({ showSendSMSModal: true })
      },
      showDuplicateDigitalPageModal: () => {
        setModalData('DuplicateDigitalPageModal')
        setState({ showDuplicateDigitalPageModal: true })
      },
    },
    defaultFilters,
  }), [modalContext])

  const { strings } = useLocalization(localizedStrings)

  return (
    <PdfDownloadProvider>
      <PageContext.Provider value={pageContext}>
        <ModalContext.Provider value={modalContext}>
          <PageContent projectId={projectId} pageFilters={pageFilters} />

          <BuildingAttachmentModal context={PdfDownloadContext} />

          <EditShareLinkModal
            callbacks={callbacks('EditShareLinkModal', setState, strings)}
            disableAnalytics
            showModal={showEditShareLinkModal}
          />

          <ManageShareLinksModal
            callbacks={callbacks('ManageShareLinksModal', setState, strings)}
            showModal={showManageShareLinksModal}
          />

          <SelectDigitalTemplateModal
            callbacks={callbacks('SelectDigitalTemplateModal', setState)}
            showModal={showSelectDigitalTemplateModal}
          />

          <DuplicateDigitalPageModal
            callbacks={callbacks('DuplicateDigitalPageModal', setState, strings)}
            showModal={showDuplicateDigitalPageModal}
            projectId={projectId}
          />

          <SendSMSModal
            callbacks={callbacks('SendSMSModal', setState, strings)}
            showModal={showSendSMSModal}
          />
        </ModalContext.Provider>
      </PageContext.Provider>
    </PdfDownloadProvider>
  )
}

DigitalPageList.propTypes = {
  projectId: PropTypes.number,
}

export default DigitalPageList
