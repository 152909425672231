import { useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet-async'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartBar, faCog, faEdit } from '@fortawesome/pro-light-svg-icons'

import { useSetState } from '@campaignhub/react-hooks'
import { digObject } from '@campaignhub/javascript-utils'

import {
  Box, Button, CustomizableDashboard, IconWithMenu, PageHeader,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import Comparisons, {
  configOptions as comparisonsConfigOptions,
  icon as comparisonsIcon,
} from '@sections/Client/components/DashboardModules/analytics/Comparisons'

import Leaderboard, {
  configOptions as leaderboardConfigOptions,
  icon as leaderboardIcon,
} from '@sections/Client/components/DashboardModules/Leaderboard'

import Performance, {
  configOptions as performanceConfigOptions,
  icon as performanceIcon,
} from '@sections/Client/components/DashboardModules/analytics/Performance'

import Statistics, { icon as statisticsIcon } from '@sections/Client/components/DashboardModules/analytics/Statistics'

import Trends, {
  configOptions as trendsConfigOptions,
  icon as trendsIcon,
} from '@sections/Client/components/DashboardModules/analytics/Trends'

import useCurrentUser from '@hooks/useCurrentUser'
import useLocalization from '@hooks/useLocalization'
import useMixpanel from '@hooks/useMixpanel'
import useOrganizationsUser from '@hooks/useOrganizationsUser'
import useUser from '@hooks/useUser'

import ContentSidebar from './components/ContentSidebar'
import Content from './components/Content'
import localizedStrings from './localizedStrings'

const generateAvailableModules = (entity, strings) => ({
  Comparisons: {
    component: Comparisons,
    configOptions: comparisonsConfigOptions(strings),
    icon: comparisonsIcon,
    id: 'Comparisons',
    mergeConfig: {
      queryParams: { entity_type: entity.type, entity_id: entity.id },
    },
    title: strings.comparisons || 'Comparisons',
  },
  Leaderboard: {
    component: Leaderboard,
    configOptions: leaderboardConfigOptions(strings),
    icon: leaderboardIcon,
    id: 'Leaderboard',
    title: strings.leaderboard || 'Leaderboard',
  },
  Performance: {
    component: Performance,
    configOptions: performanceConfigOptions(strings),
    icon: performanceIcon,
    id: 'Performance',
    mergeConfig: {
      queryParams: { entity_type: entity.type, entity_id: entity.id },
    },
    title: strings.performance || 'Performance',
  },
  Statistics: {
    component: Statistics,
    icon: statisticsIcon,
    id: 'Statistics',
    title: strings.statistics || 'Statistics',
  },
  Trends: {
    component: Trends,
    configOptions: trendsConfigOptions(strings),
    icon: trendsIcon,
    id: 'Trends',
    mergeConfig: {
      queryParams: { entity_type: entity.type, entity_id: entity.id },
    },
    title: strings.trends || 'Trends',
  },
})

const dashboardDefaultLayout = strings => ({
  columns: {
    main: {
      id: 'main',
      modules: [
        {
          key: 'Statistics-1',
          moduleId: 'Statistics',
        },
        {
          key: 'Comparisons-1',
          moduleId: 'Comparisons',
        },
        {
          key: 'Leaderboard-1',
          moduleId: 'Leaderboard',
        },
        {
          key: 'Leaderboard-2',
          moduleId: 'Leaderboard',
        },
        {
          key: 'Comparisons-2',
          moduleId: 'Comparisons',
        },
        {
          key: 'Trends-1',
          moduleId: 'Trends',
        },
        {
          key: 'Trends-2',
          moduleId: 'DigitalPagesCreatedVsViewedPerDay',
        },
        {
          key: 'Trends-3',
          moduleId: 'DigitalPagesEngagementPerDay',
        },
      ],
    },
    sidebar: {
      id: 'sidebar',
      modules: [
        {
          key: 'Performance-1',
          moduleId: 'Performance',
        },
      ],
    },
  },
  custom: false,
  options: {
    'Comparisons-1': {
      customization: {
        metric_key: 'average_proposal_read_percentage',
        title: strings.averageProposalReadPercentage || 'Average Proposal Read Percentage',
      },
    },
    'Comparisons-2': {
      customization: {
        metric_key: 'total_proposals_created',
        title: strings.totalProposalsCreated || 'Total Proposals Created',
      },
    },
    'Leaderboard-1': {
      customization: { title: strings.templatesLeaderboard || 'Most Engaging Templates' },
      queryParams: { leaderboard: 'digital_template_leaderboard', month: 'current_month', status: 'pending' },
    },
    'Leaderboard-2': {
      customization: { title: strings.usersLeaderboard || 'Proposals Leaderboard' },
      queryParams: { leaderboard: 'user_leaderboard', month: 'current_month', status: 'all' },
    },
    'Performance-1': {
      customization: {
        chart_key: 'engagement_status_counts',
        title: strings.engagementStatusCounts || 'Engagement Status Counts',
      },
    },
    'Trends-1': {
      customization: {
        chart_key: 'digital_pages_accepted_vs_created_per_month',
        title: strings.acceptedVsCreatedPerMonth || 'Proposals Accepted vs Created',
      },
    },
    'Trends-2': {
      customization: {
        chart_key: 'digital_pages_created_vs_viewed_per_day',
        title: strings.digitalPagesCreatedvsViewedPerDay || 'Digital Pages Created vs Viewed Per Day',
      },
    },
    'Trends-3': {
      customization: {
        chart_key: 'digital_pages_engagement_per_day',
        title: strings.digitalPagesEngagementPerDay || 'Digital Pages Engagement Per Day',
      },
    },
    'Trends-4': {
      customization: {
        chart_key: 'digital_pages_views_vs_sent_ratio_per_day',
        title: strings.digitalPagesViewsVsSentRatioPerDay || 'Digital Pages Views Vs Sent Ratio Per Day',
      },
    },
  },
})

const callbacks = (component, state, setState, userCallbacks) => {
  const { editingDashboard } = state
  const { saveUserDashboard } = userCallbacks

  const componentCallbacks = {
    CustomizableDashboard: {
      toggleEditDashboard: () => setState({ editingDashboard: !editingDashboard }),
      saveDashboard: (dashboardKey, activeLayout) => saveUserDashboard(dashboardKey, activeLayout),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  editingDashboard: false,
}

const Analytics = () => {
  const [state, setState] = useSetState(defaultState)
  const { editingDashboard } = state

  const { currentUser } = useCurrentUser()
  const { callbacks: userCallbacks } = useUser(currentUser)

  const { isCurrentUserAdminForOrganization, selectedOrganization } = useOrganizationsUser()

  const chartDataEntity = isCurrentUserAdminForOrganization ? selectedOrganization : currentUser

  const customizedLayout = digObject(currentUser, 'options.custom_dashboards.analytics_dashboard')

  const pageContext = {
    callbacks: {},
  }

  const { strings } = useLocalization(localizedStrings)

  const availableModules = useMemo(
    () => generateAvailableModules(chartDataEntity, strings),
    [currentUser.id, selectedOrganization.id],
  )

  // Tracks the page load event
  const { callbacks: { dispatchMixpanelEvent } } = useMixpanel()
  useEffect(() => {
    dispatchMixpanelEvent('Organisation Analytics Load')
  }, [])

  return (
    <PageContext.Provider value={pageContext}>
      <Helmet>
        <title>{`${strings.helmet?.title} | Engage`}</title>
      </Helmet>

      <PageHeader
        actionContent={
          !editingDashboard && (
            <IconWithMenu
              closeOnClick
              icon={(
                <Button
                  buttonStyle="secondaryUtility"
                  icon={<FontAwesomeIcon icon={faCog} />}
                  size="medium"
                  style={{ height: 37 }}
                />
                )}
            >
              <IconWithMenu.Menu listStyle={{ right: '-7px' }}>
                <a key="edit" onClick={() => setState({ editingDashboard: !editingDashboard })} tabIndex="0">
                  <FontAwesomeIcon icon={faEdit} />{' '}
                  {editingDashboard
                    ? strings.pageHeader?.save || 'Save Dashboard'
                    : strings.pageHeader?.customize || 'Customize Dashboard'}
                </a>
              </IconWithMenu.Menu>
            </IconWithMenu>
          )
        }
        activeTabBarItemKey="analytics"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        nestedNavigation
        tabBarItems={[
          {
            href: '#/admin/analytics',
            icon: faChartBar,
            key: 'analytics',
            title: strings.pageHeader?.title || 'Analytics',
          },
        ]}
        title={strings.pageHeader?.title || 'Analytics'}
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <CustomizableDashboard
          availableModules={availableModules}
          callbacks={callbacks('CustomizableDashboard', state, setState, userCallbacks)}
          columns={[
            {
              id: 'main',
              component: <Content />,
              title: strings.customizableDashboard?.main || 'Main',
            },
            {
              id: 'sidebar',
              component: (
                <ContentSidebar
                  callbacks={{ customizeDashboard: () => setState({ editingDashboard: !editingDashboard }) }}
                />
              ),
              title: strings.customizableDashboard?.sidebar || 'Sidebar',
            },
          ]}
          customizedLayout={customizedLayout}
          dashboardKey="analytics_dashboard"
          dashboardReady={!!currentUser.id}
          defaultLayout={dashboardDefaultLayout(strings)}
          editing={editingDashboard}
        />
      </Box>
    </PageContext.Provider>
  )
}

export default Analytics
