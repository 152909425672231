import { Route, Routes } from 'react-router-dom'

import Overview from './screens/Overview'

const DashboardRoutes = () => (
  <Routes>
    <Route index element={<Overview />} />
  </Routes>
)

export default DashboardRoutes
