export default {
  default: {
    formFieldLabels: {
      findProject: 'Find a Project',
    },
    mapResults: {
      title: 'PROJECTS',
    },
    projectSearchPlaceholder: 'Search for a project',
  },
}
