import { useContext } from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/pro-light-svg-icons'

import {
  Box,
  Button,
  FormField,
  InputSearch,
  LoadingModule,
  ModalContext,
  SidebarModal,
  SidebarNoticeBox,
  SortableList,
  UserProfileImage,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useCaseStudy from '@hooks/useCaseStudy'
import useLocalization from '@hooks/useLocalization'
import useOrganizationsUser from '@hooks/useOrganizationsUser'
import useManageCaseStudies from './hooks/useManageCaseStudies'

import DraggableCaseStudy from './components/DraggableCaseStudy'

import localizedStrings from './localizedStrings'

const mapResults = (caseStudies, addresses, images, projects, strings) => {
  if (!caseStudies.length){
    return {
      groups: [
        {
          items: [
            {
              id: -1,
              key: -1,
              subtitle: strings.search?.noResults?.subtitle || 'Try searching again',
              title: strings.search?.noResults?.title || 'No Case Studies found',
              priority: 0,
            },
          ],
          key: 'CaseStudies',
          title: strings.search?.title || 'No Case Studies found',
        },
      ],
    }
  }

  return {
    groups: [
      {
        items: caseStudies.map((caseStudy) => {
          const image = images[caseStudy.default_image_id]
          const imageUrl = digObject(image, 'sizes.thumb_90x90', '')

          const project = projects[caseStudy.project_id] || {}
          const projectAddress = addresses[project.address_id]
          const caseStudyAddress = addresses[caseStudy.address_id]
          const address = caseStudyAddress || projectAddress || {}

          return {
            iconLeft: (
              <UserProfileImage imageUrl={imageUrl}>
                <FontAwesomeIcon icon={faHome} />
              </UserProfileImage>
            ),
            id: caseStudy.id,
            key: `CaseStudy-${caseStudy.id}`,
            text: address.full_address,
          }
        }),
        key: 'CaseStudies',
        title: strings.search?.title || 'No Case Studies found',
      },
    ],
  }
}

const ManageProjectCaseStudiesModal = (props) => {
  const {
    callbacks, disableAnimation, disableOverlay, modalKey, project: initProject, showModal,
  } = props

  const { updateProject } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const { callbacks: payloadCallbacks } = digObject(modalData, modalKey, {})
  const { loadShortcodeData: loadDataFn } = payloadCallbacks || {}

  const manageCaseStudiesPayload = useManageCaseStudies({
    owner_id: initProject.organization_id,
    owner_type: 'Organization',
    performHttpRequests: true,
    project: initProject,
  })

  const {
    callbacks: { modifyCaseStudiesSort, setState, toggleProjectCaseStudy },
    entities: { addresses, images, projects },
    filteredCaseStudies,
    loaded,
    loading: loadingCaseStudies,
    projectPayload,
    projectFormPayload,
    string,
  } = manageCaseStudiesPayload

  const initCaseStudy = { owner_id: initProject.organization_id, owner_type: 'Organization' }
  const { callbacks: { createOrEditCaseStudy } } = useCaseStudy(initCaseStudy)

  const {
    callbacks: { updateProject: updateFn },
    loading: loadingProject,
    updating: updatingProject,
  } = projectPayload

  const {
    entityState,
    entityState: { case_study_ids },
    setEntityState,
  } = projectFormPayload

  const loading = loadingCaseStudies || loadingProject

  const { isCurrentUserAdminForOrganization } = useOrganizationsUser()

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal
      callbacks={callbacks}
      disableAnimation={disableAnimation}
      disableOverlay={disableOverlay}
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.sideBarModalHeader?.title || 'Case'}
        titleSecondLine={strings.sideBarModalHeader?.titleSecondLine || 'Studies'}
      />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <FormField
            boxProps={{ marginBottom: 'large' }}
            direction="column"
            label={strings.formFieldLabels?.searchCaseStudies || 'Search Case Studies'}
          >
            <InputSearch
              callbacks={{
                selectItem: caseStudy => toggleProjectCaseStudy(caseStudy.id),
              }}
              closeOnSelect
              onChange={e => setState({ string: e.target.value })}
              results={mapResults(filteredCaseStudies, addresses, images, projects, strings)}
              showOnFocus
              value={string}
            />
          </FormField>

          <LoadingModule boxProps={{ marginTop: 'large' }} loading={loading} times={3} />

          {!loading && loaded && !case_study_ids.length && (
            <SidebarNoticeBox>
              <SidebarNoticeBox.Title>{strings.noticeBox?.title || 'Search Case Studies'}</SidebarNoticeBox.Title>
              <SidebarNoticeBox.Paragraph>
                {strings.noticeBox?.paragraph
                  || 'Case Studies assigned to your account can be searched above. If you have not created any yet click the button below'}
              </SidebarNoticeBox.Paragraph>
            </SidebarNoticeBox>
          )}

          {!loading && !!case_study_ids.length && (
            <SortableList
              callbacks={{
                onDragEnd: (fromIndex, toIndex, payload) => modifyCaseStudiesSort(fromIndex, toIndex, payload, { case_study_ids }, setEntityState),
              }}
            >
              <SortableList.Dropzone droppableId="case_study_ids">
                <Box flexDirection="column" flexShrink={0}>
                  {case_study_ids.map((id, index) => (
                    <SortableList.Item draggableId={`CaseStudy-${id}`} key={id} index={index}>
                      <DraggableCaseStudy
                        callbacks={{
                          removeCaseStudy: caseStudyId => toggleProjectCaseStudy(caseStudyId),
                        }}
                        caseStudyId={id}
                      />
                    </SortableList.Item>
                  ))}
                </Box>
              </SortableList.Dropzone>
            </SortableList>
          )}
        </Box>

        {isCurrentUserAdminForOrganization && (
          <FormField
            direction="column"
            boxProps={{ marginTop: 'large' }}
            label={strings.formFieldLabels?.moreOptions || 'More Options'}
          >
            <Box flexDirection="column" flexShrink={0}>
              <Button
                as="a"
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faHome} />}
                onClick={() => createOrEditCaseStudy({ toggleProjectCaseStudy })}
                size="medium"
              >
                {strings.buttons?.create || 'Create Case Study'}
              </Button>
            </Box>
          </FormField>
        )}
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          loading={updatingProject}
          loadingBubbleColor="white"
          onClick={() => updateProject(entityState, 'Case Studies', updateFn, loadDataFn)}
          size="large"
        >
          {strings.buttons?.save || 'Save'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

ManageProjectCaseStudiesModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  disableAnimation: PropTypes.bool,
  disableOverlay: PropTypes.bool,
  modalKey: PropTypes.string,
  project: PropTypes.object.isRequired,
  showModal: PropTypes.bool,
}

ManageProjectCaseStudiesModal.defaultProps = {
  modalKey: 'ManageProjectCaseStudiesModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <ManageProjectCaseStudiesModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
