export default {
  default: {
    form: {
      title: 'No forms available',
      paragraph: 'You either haven\'t created any forms, or you need to adjust your filters to return more results. If you haven\'t started yet, you can create one now from an existing proposal or from the button at the top of the page.',
    },
    microsite: {
      title: 'No microsites available',
      paragraph: 'You either haven\'t created any homepages, or you need to adjust your filters to return more results. You can create one now via the button at the top of the page. It will bring data from your existing digital pages and project.',
    },
    proposal: {
      title: 'No Proposals Created',
      paragraph: 'Create a proposal to get started and send it to your client. Proposals can be used to win listings, or educate and inform potential customers.',
    },
    agreement: {
      title: 'No agreements available',
      paragraph: 'You either haven\'t created any agreements, or you need to adjust your filters to return more results. You can create one now via the button at the top of the page.',
    },
  },
}
