import { useContext } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import GoogleMapReact from 'google-map-react'

import PageContext from '@contexts/pageContext'

import AnimationWrapper from '@components/digitalRenderer/components/AnimationWrapper'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/pro-solid-svg-icons'

import useDeviceStyle from '@hooks/useDeviceStyle'
import useDigitalPageItem from '@hooks/useDigitalPageItem'
import useDigitalPageMap from '@hooks/useDigitalPageMap'

import MapMarker from './components/MapMarkerImage'

import styles from './styles.module.scss'

const editAction = (e, params) => {
  const {
    callbacks: { editDigitalPageItemCustomData, selectCanvasItem },
    customizingTemplate,
    entityType,
    id,
    options,
    itemType,
  } = params

  e.preventDefault()
  e.stopPropagation()

  if (customizingTemplate){
    selectCanvasItem({ type: entityType, id }, e)
    return
  }

  editDigitalPageItemCustomData({
    ...options, entityType, id, itemType,
  })
}

const checkIfEditable = (customizingTemplate, editing, editable, featureKey) => {
  if (customizingTemplate){
    return true
  }

  return editing && (editable || !!featureKey)
}

const Map = (props) => {
  const {
    entityType,
    id,
    item_type,
    options,
    options: { editable, mapZoom, marker },
  } = props

  const style = useDeviceStyle(props)

  const pageContext = useContext(PageContext)
  const {
    callbacks, customizingTemplate, digitalPage, editing,
  } = pageContext || {}

  const { editDigitalPageItemCustomData, selectCanvasItem } = callbacks || {}

  const isEditable = checkIfEditable(customizingTemplate, editing, editable)

  const digitalPageItemPayload = useDigitalPageItem(digitalPage, id)

  // options used for map
  const { height, width } = marker || {}

  const locationPayload = useDigitalPageMap({ ...digitalPageItemPayload, ...marker })
  const { locations, mapMarkerImage } = locationPayload

  return (
    <AnimationWrapper options={options}>
      <div
        className={classnames(styles.root, styles.map_canvas, isEditable ? styles.editable : null)}
        onClick={
          isEditable
            ? e => editAction(e, {
              callbacks: {
                editDigitalPageItemCustomData,
                selectCanvasItem,
              },
              customizingTemplate,
              entityType,
              options,
              id,
              itemType: item_type,
            })
            : null
        }
        style={{ ...style }}

      >
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_PLACES_API_KEY }}
          libraries={['places', 'geometry', 'drawing', 'visualization']}
          defaultCenter={{
            lat: locations[0]?.latitude || -33.762390,
            lng: locations[0]?.longitude || 151.271271,
          }}
          defaultZoom={Number(mapZoom) || 10}
        >
          {
            locations.map(currentLocation => (
              <MapMarker
                key={currentLocation.id}
                lat={currentLocation.latitude || null}
                lng={currentLocation.longitude || null}
                mapMarkerImageUrl={mapMarkerImage.image_url || ''}
                markerHeight={Number(height) || 25}
                markerWidth={Number(width) || 25}
              />
            ))
          }
        </GoogleMapReact>

        {isEditable
        && (
          <div className={styles.editActions}>
            <div className={styles.icon} style={{ marginBottom: 2 }}>
              <FontAwesomeIcon icon={faPencil} />
            </div>
          </div>
        )}
      </div>
    </AnimationWrapper>
  )
}

Map.propTypes = {
  callbacks: PropTypes.shape({
    updateCustomData: PropTypes.func,
  }),
  entityType: PropTypes.string,
  id: PropTypes.string.isRequired,
  item_type: PropTypes.string,
  options: PropTypes.object,
}

Map.defaultProps = {
  options: {},
}

export default Map
