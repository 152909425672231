import PropTypes from 'prop-types'

import { Box, FormField, SidebarModal } from '@campaignhub/suit-theme'

import FieldHolder from '@components/FieldHolder'
import Input from '@components/Input'

import FlexProperties from './FlexProperties'
import GridProperties from './GridProperties'
import SizeAndPositioning from './SizeAndPositioning'
import FontOptions from './FontOptions'
import BackgroundProperties from './BackgroundProperties'
import BorderProperties from './BorderProperties'

const deviceStyleKeys = {
  default: 'style',
  tablet: 'tabletStyle',
  mobile: 'mobileStyle',
  print: 'printStyle',
}

const getStyleKey = targetDevice => deviceStyleKeys[targetDevice] || 'style'

const shouldRender = (entityType) => {
  const permittedEntities = ['digitalTemplatePageSection', 'digitalTemplatePageItem']
  return permittedEntities.includes(entityType)
}

const updateStyle = (name, value, props) => {
  const {
    selectedEntity,
    targetDevice,
    callbacks: { updateParam },
  } = props

  const styleKey = getStyleKey(targetDevice)

  const updatedStyle = {
    ...selectedEntity[styleKey],
    key: styleKey,
    [name]: value,
  }

  updateParam(styleKey, updatedStyle)
}

const Style = (props) => {
  const {
    callbacks: { setTargetDevice, updateParam },
    digitalTemplatePayload: { componentStyles },
    entityType,
    selectedEntity,
    selectedEntity: { componentStyleId, item_type },
    targetDevice,
  } = props

  if (!shouldRender(entityType, item_type)) return null

  const itemStyle = selectedEntity[getStyleKey(targetDevice)] || { key: getStyleKey(targetDevice) }

  return (
    <SidebarModal.ExpandableSectionBox defaultOpen label="Style">
      <Box flexDirection="column">
        <FormField boxProps={{ borderBottom: '1px lightGrey dashed', marginBottom: 'large' }} label="Device">
          <Box marginBottom="large">
            <select onChange={e => setTargetDevice(e.target.value)} value={targetDevice}>
              <option value="default">Desktop</option>
              <option value="mobile">Mobile</option>
              <option value="tablet">Tablet</option>
              <option value="print">Print</option>
            </select>
          </Box>
        </FormField>

        <FormField
          boxProps={{ borderBottom: '1px lightGrey dashed', marginBottom: 'large' }}
          label="Link Component Style"
        >
          <Box marginBottom="large">
            <select onChange={e => updateParam('componentStyleId', e.target.value)} value={componentStyleId}>
              <option value="">Please Select...</option>
              {componentStyles.map(style => (
                <option key={style.id} value={style.id}>
                  {style.title}
                </option>
              ))}
            </select>
          </Box>
        </FormField>

        <FormField
          boxProps={{ borderBottom: '1px lightGrey dashed', marginBottom: 'large' }}
          label="Size & Positioning"
        >
          <SizeAndPositioning
            itemStyle={itemStyle}
            selectedEntity={selectedEntity}
            styleProps={props}
            updateStyle={updateStyle}
          />
        </FormField>

        <FormField boxProps={{ borderBottom: '1px lightGrey dashed', marginBottom: 'large' }} label="Font Options">
          <FontOptions
            itemStyle={itemStyle}
            selectedEntity={selectedEntity}
            styleProps={props}
            updateStyle={updateStyle}
          />
        </FormField>

        <FormField boxProps={{ borderBottom: '1px lightGrey dashed', marginBottom: 'large' }} label="Background">
          <BackgroundProperties
            itemStyle={itemStyle}
            selectedEntity={selectedEntity}
            styleProps={props}
            itemType= {item_type}
            updateStyle={updateStyle}
          />
        </FormField>

        <FormField boxProps={{ borderBottom: '1px lightGrey dashed', marginBottom: 'large' }} label="Border">
          <BorderProperties
            itemStyle={itemStyle}
            selectedEntity={selectedEntity}
            styleProps={props}
            updateStyle={updateStyle}
          />
        </FormField>

        <FormField boxProps={{ borderBottom: '1px lightGrey dashed', marginBottom: 'large' }} label="Shadow">
          <Box marginTop="large" flexDirection="row" flexWrap="wrap">
            <Input
              boxProps={{ marginBottom: 'large' }}
              entityId={selectedEntity.id}
              data={itemStyle}
              parse={value => value || null}
              propertyKey="boxShadow"
              label="Box Shadow"
              updateCallback={(name, value) => updateStyle(name, value, props)}
            />
          </Box>
        </FormField>

        <FormField label="Arrangement Options">
          <Box marginTop="large" flexDirection="row" flexWrap="wrap">
            <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth label="Position">
              <select onChange={e => updateStyle('position', e.target.value, props)} value={itemStyle.position || ''}>
                <option value="">Default</option>
                <option value="static">Static</option>
                <option value="absolute">Absolute</option>
                <option value="fixed">Fixed</option>
                <option value="relative">Relative</option>
                <option value="sticky">Sticky</option>
                <option value="initial">Initial</option>
                <option value="inherit">Inherit</option>
              </select>
            </FieldHolder>

            <Input
              boxProps={{ marginBottom: 'large' }}
              data={itemStyle}
              entityId={selectedEntity.id}
              halfWidth
              inputType="number"
              label="Z Index"
              last
              propertyKey="zIndex"
              updateCallback={(name, value) => updateStyle(name, value, props)}
            />

            <FieldHolder boxProps={{ marginBottom: 'large' }} label="Display">
              <select onChange={e => updateStyle('display', e.target.value, props)} value={itemStyle.display || ''}>
                <option value="">Default</option>
                <option value="block">Block</option>
                <option value="flex">Flex</option>
                <option value="grid">Grid</option>
                <option value="inline">Inline</option>
                <option value="inline-block">Inline Block</option>
                <option value="inline-flex">Inline Flex</option>
                <option value="none">None</option>
              </select>
            </FieldHolder>

            {itemStyle.display === 'flex' && (
              <FlexProperties
                itemStyle={itemStyle}
                selectedEntity={selectedEntity}
                styleProps={props}
                updateStyle={updateStyle}
              />
            )}

            {itemStyle.display === 'grid' && (
              <GridProperties
                itemStyle={itemStyle}
                selectedEntity={selectedEntity}
                styleProps={props}
                updateStyle={updateStyle}
              />
            )}

            <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth label="Break (Before)">
              <select
                onChange={e => updateStyle('breakBefore', e.target.value, props)}
                value={itemStyle.breakBefore || ''}
              >
                <option value="">Default</option>
                <option value="auto">Auto</option>
                <option value="avoid">Avoid</option>
                <option value="always">Always</option>
                <option value="all">All</option>
                <option value="avoid-page">Avoid Page</option>
                <option value="page">Page</option>
                <option value="left">Left</option>
                <option value="right">Right</option>
                <option value="recto">Recto</option>
                <option value="verso">Verso</option>
                <option value="avoid-column">Avoid Column</option>
                <option value="column">Column</option>
                <option value="avoid-region">Avoid Region</option>
                <option value="region">Region</option>
              </select>
            </FieldHolder>

            <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth label="Break (After)" last>
              <select
                onChange={e => updateStyle('breakAfter', e.target.value, props)}
                value={itemStyle.breakAfter || ''}
              >
                <option value="">Default</option>
                <option value="auto">Auto</option>
                <option value="avoid">Avoid</option>
                <option value="always">Always</option>
                <option value="all">All</option>
                <option value="avoid-page">Avoid Page</option>
                <option value="page">Page</option>
                <option value="left">Left</option>
                <option value="right">Right</option>
                <option value="recto">Recto</option>
                <option value="verso">Verso</option>
                <option value="avoid-column">Avoid Column</option>
                <option value="column">Column</option>
                <option value="avoid-region">Avoid Region</option>
                <option value="region">Region</option>
              </select>
            </FieldHolder>
          </Box>
        </FormField>
      </Box>
    </SidebarModal.ExpandableSectionBox>
  )
}

Style.propTypes = {
  callbacks: PropTypes.shape({
    setTargetDevice: PropTypes.func.isRequired,
    updateEntity: PropTypes.func.isRequired,
    updateParam: PropTypes.func.isRequired,
  }),
  digitalTemplatePayload: PropTypes.object.isRequired,
  entityType: PropTypes.string,
  selectedEntity: PropTypes.object.isRequired,
  targetDevice: PropTypes.string.isRequired,
}

export default Style
