import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/pro-solid-svg-icons'

import {
  Box, Image, ListItem, Text,
} from '@campaignhub/suit-theme'

import useDigitalTemplatePageSection from '@hooks/useDigitalTemplatePageSection'

const DraggableSection = React.forwardRef((props, ref) => {
  const {
    dragHandleProps,
    dragSnapshot,
    draggableProps,
    sectionId,
  } = props

  const digitalTemplatePageSectionPayload = useDigitalTemplatePageSection({ id: sectionId })
  const { options: { anchor, title } } = digitalTemplatePageSectionPayload

  const { isDragging } = dragSnapshot || {}

  return (
    <ListItem
      alignItems="center"
      boxProps={{
        borderBottom: '1px dashed',
        alignItems: 'center',
        paddingY: 'large',
      }}
      disableHover
      flexShrink={0}
      forwardProps={{
        ...draggableProps,
        ...dragHandleProps,
      }}
      isDragging={isDragging}
      ref={ref}
    >
      <Box alignItems="center" justifyContent="center" flexShrink={0} height={35} width={35} marginRight="large">
        <Image boxProps={{ backgroundColor: 'hoverLightGrey' }} circle width={35}>
          <FontAwesomeIcon icon={faBars} />
        </Image>
      </Box>

      <Box flexDirection="column" justifyContent="center">
        <Text color="bodyFontColor" fontSize="small">
          {title || anchor || sectionId}
        </Text>

        <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="small">
          {`ID# ${sectionId}`}
        </Text>
      </Box>
    </ListItem>
  )
})

DraggableSection.propTypes = {
  dragHandleProps: PropTypes.object,
  dragSnapshot: PropTypes.object,
  draggableProps: PropTypes.object,
  sectionId: PropTypes.string.isRequired,
}

export default DraggableSection
