import React from 'react'
import { Route, Routes } from 'react-router-dom'

import DigitalPageMetrics from './screens/DigitalPageMetrics'
import Overview from './screens/Overview'

const Analytics = () => (
  <Routes>
    <Route index element={<Overview />} />
    <Route path="/digitalPageMetrics" element={<DigitalPageMetrics />} />
  </Routes>
)

export default Analytics
