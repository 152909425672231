import PropTypes from 'prop-types'

import { digObject } from '@campaignhub/javascript-utils'

import formatChartData from '@functions/chart'

import LineChart from '@components/charts/LineChart'

const availableCharts = {
  lineChart: LineChart,
}

const Chart = (props) => {
  const { context, options } = props

  const chartData = digObject(context, 'chart_data', [])
  const dataPayload = formatChartData(chartData, options)

  const chartType = digObject(options, 'chartType') || 'lineChart'

  const SelectedChart = availableCharts[chartType]

  return (
    <SelectedChart dataPayload={dataPayload} />
  )
}

Chart.propTypes = {
  context: PropTypes.object,
  options: PropTypes.object,
}

export default Chart
