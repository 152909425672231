import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { DateTime } from 'luxon'

import { digObject } from '@campaignhub/javascript-utils'
import { useSetState } from '@campaignhub/react-hooks'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBath, faBed, faCar, faCheck, faMapMarkerAlt, faPlus, faTrashAlt,
} from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Text,
} from '@campaignhub/suit-theme'

import useComparable from '@hooks/useComparable'
import useLocalization from '@hooks/useLocalization'
import useMixpanel from '@hooks/useMixpanel'

import localizedStrings from './localizedStrings'

const renderFeatureIcon = (count, type, bar) => (
  <>
    {count || 0} <FontAwesomeIcon icon={type} style={{ marginLeft: 5 }} />
    {bar && (
      <Text color="bodyFontLightColor" marginX="small">
        |
      </Text>
    )}
  </>
)

const selectProperty = (params) => {
  const {
    props: {
      createComparableFromProject,
      externalComparable,
      importComparable,
    },
    setState,
    dispatchMixpanelEvent,
  } = params

  const { data_provider, distance, id } = externalComparable || {}
  const { title: dataProvider } = data_provider || {}

  setState({ loading: true })

  if (dataProvider === 'engage'){
    createComparableFromProject({ id }).then(({ success, errors }) => {
      setState({ loading: false })
      if (!success){
        toast.warning(errors[0])
      }

      dispatchMixpanelEvent('Comparables Save', { integration_platform: 'engage' })
    })
    return
  }

  importComparable({ id, distance }).then(({ success, errors }) => {
    setState({ loading: false })
    if (!success){
      toast.warning(errors[0])
    }

    dispatchMixpanelEvent('Comparables Save', { integration_platform: dataProvider })
  })
}

// Format the date if it came from Domain or Pricefinder, other providers already return the correct format
const getFormattedSoldDate = (soldDate, dataProvider) => {
  if (!soldDate) return null

  if (dataProvider === 'DomainGroup' || dataProvider === 'Pricefinder'){
    return DateTime.fromISO(soldDate).toFormat('dd/MM/yyyy')
  }

  return soldDate
}

const defaultState = {
  loading: false,
}

const ComparableResult = (props) => {
  const { externalComparable, filteredComparables, viewMode } = props
  const {
    address: {
      address, post_code, state_name, suburb_name,
    },
    data_provider: { title: dataProvider },
    dates: {
      sold: { date_time: soldDate },
    },
    distance,
    features: { bathrooms, bedrooms, garages },
    image,
    price: { price, price_guide },
    raw_data: { saleType, rental },
    service_provider,
  } = externalComparable

  const [state, setState] = useSetState(defaultState)
  const { loading } = state

  const existingComparable = filteredComparables.find(
    comparable => comparable.data
      && comparable.data.external_ids
      && comparable.data.source_platform === externalComparable.data_provider.title.toLowerCase()
      && comparable.data.external_ids[comparable.data.source_platform] === externalComparable.id,
  )
  const added = !!existingComparable

  const {
    callbacks: { deleteComparable },
  } = useComparable(existingComparable)

  const formattedDistance = distance ? (distance / 1000).toFixed(2) : null
  const formattedSoldDate = getFormattedSoldDate(soldDate, dataProvider)

  // Need to use img tag for the tile as
  // Core Logic's image url comes with css filters (e.g. watermark) and our Image component doesn't accept those values.
  const url = digObject(image, 'thumb')

  const { callbacks: { dispatchMixpanelEvent } } = useMixpanel()

  const {
    callbacks: { formatCurrency },
    strings,
  } = useLocalization(localizedStrings)

  const formattedPrice = price ? formatCurrency(price, '$,.0f') : price_guide

  return (
    <Box
      border="1px solid"
      borderColor="lineColor"
      borderRadius={5}
      flexDirection="column"
      flexShrink={0}
      marginTop="large"
      padding="large"
    >
      <Box flexDirection="column" opacity={added ? 0.5 : 1}>
        <Box flexDirection="column">
          {viewMode === 'tile' && service_provider !== 'price_finder' && (
            <img alt="comparable_image" src={url} style={{ height: '140px', borderRadius: '5px' }} />
          )}
          <Box flexDirection="column" marginBottom="small">
            <Text fontSize="small" color="bodyFontColor" marginY="medium">
              {address}
            </Text>
            <Text fontSize="xsmall" color="bodyFontLightColor" marginBottom="medium">
              {suburb_name}, {state_name} {post_code}
            </Text>
          </Box>
        </Box>

        <Box>
          <Box flexDirection="column">
            <Box alignItems="center" fontSize="xsmall" color="bodyFontLightColor" marginBottom="medium">
              {renderFeatureIcon(bedrooms, faBed, true)}
              {renderFeatureIcon(bathrooms, faBath, true)}
              {renderFeatureIcon(garages, faCar, false)}

              {formattedDistance && (
                <>
                  <Text color="bodyFontLightColor" marginX="large">
                    |
                  </Text>
                  <Text color="bodyFontLightColor" fontSize="small">
                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                    &nbsp; {formattedDistance} {strings.distance || 'km away'}
                  </Text>
                </>
              )}
            </Box>

            {(price || price_guide) && (
              <Box color="bodyFontLightColor" fontSize="xsmall" marginBottom="large">
                {rental ? 'Rental' : saleType || strings.agentsAdvice || 'Agents Advice'}{' '}
                {`- ${formattedPrice}`}
                {formattedSoldDate && ` (${formattedSoldDate})`}
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      <Box alignItems="center" justifyContent="space-between">
        {!added && (
          <Button
            buttonStyle="secondaryEdit"
            icon={<FontAwesomeIcon icon={faPlus} />}
            loading={loading}
            onClick={() => selectProperty({ dispatchMixpanelEvent, props, setState })}
            size="medium"
            width="100%"
          >
            {strings.buttons?.addComparable || 'Add Comparable'}
          </Button>
        )}

        {added && (
          <>
            <Button
              buttonStyle="secondaryUtility"
              icon={<FontAwesomeIcon icon={faTrashAlt} />}
              loading={loading}
              onClick={() => deleteComparable()}
              size="medium"
              width="calc(50% - 4px)"
            >
              {strings.buttons?.remove || 'Remove'}
            </Button>

            <Button
              buttonStyle="secondaryCreate"
              icon={<FontAwesomeIcon icon={faCheck} />}
              loading={loading}
              size="medium"
              width="calc(50% - 4px)"
            >
              {strings.buttons?.added || 'Added'}
            </Button>
          </>
        )}
      </Box>
    </Box>
  )
}

ComparableResult.propTypes = {
  externalComparable: PropTypes.object.isRequired,
  filteredComparables: PropTypes.array.isRequired,
  viewMode: PropTypes.string.isRequired,
}

export default ComparableResult
