import React from 'react'
import PropTypes from 'prop-types'

import { Box, FormField, SortableList } from '@campaignhub/suit-theme'

import DocumentRecipient from '@components/ModalComponents/DocumentRecipient'

const DocumentRecipients = (props) => {
  const {
    callbacks: { deleteDocumentRecipient },
    manageRecipientsPayload: {
      callbacks: { modifyRecipientSort },
      droppableId,
      sortedRecipients,
    },
  } = props

  return (
    <SortableList
      callbacks={{
        onDragEnd: (fromIndex, toIndex, payload) => modifyRecipientSort(fromIndex, toIndex, payload),
      }}
    >
      <FormField direction="column" label="Recipients/Signers">
        <SortableList.Dropzone droppableId={droppableId}>
          <Box flexDirection="column">
            {sortedRecipients.map((documentRecipient, itemIndex) => (
              <SortableList.Item
                draggableId={`Recipient-${documentRecipient.id}`}
                key={documentRecipient.id}
                index={itemIndex}
              >
                <DocumentRecipient
                  callbacks={{ deleteDocumentRecipient }}
                  documentRecipient={documentRecipient}
                  isDraggable
                  showEditOptions
                />
              </SortableList.Item>
            ))}
          </Box>
        </SortableList.Dropzone>
      </FormField>
    </SortableList>
  )
}

DocumentRecipients.propTypes = {
  callbacks: PropTypes.shape({
    deleteDocumentRecipient: PropTypes.func,
  }),
  manageRecipientsPayload: PropTypes.shape({
    callbacks: PropTypes.shape({
      modifyRecipientSort: PropTypes.func.isRequired,
    }).isRequired,
    droppableId: PropTypes.string.isRequired,
    sortedRecipients: PropTypes.array.isRequired,
  }).isRequired,
}

export default DocumentRecipients
