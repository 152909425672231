import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, Button, FormField, SidebarModal,
} from '@campaignhub/suit-theme'

import useCurrentUser from '@hooks/useCurrentUser'
import useLocalization from '@hooks/useLocalization'

import EntitySelector from '@components/EntitySelector'

import localizedStrings from './localizedStrings'

const CreateNew = (props) => {
  const {
    callbacks: { createFormTemplate, duplicateFormTemplate },
    formTemplateFormPayload,
  } = props

  const {
    creating,
    entityState: {
      form_template_id,
      title,
      owner_id,
      owner_type,
    },
    handlers,
    saveEnabled,
    setEntityState,
  } = formTemplateFormPayload

  const { isAdmin } = useCurrentUser()

  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <FormField direction="column" label={strings.formFieldLabels?.title || '* Title'}>
            <input name="title" type="text" value={title} {...handlers} />
          </FormField>

          {isAdmin && (
            <>
              <FormField
                direction="column"
                boxProps={{ marginTop: 'large' }}
                label={strings.formField?.ownerType || 'Owner Type'}
              >
                <select name="owner_type" value={owner_type} {...handlers} data-validate-field-on="change">
                  <option value="">{strings.formField?.select || 'Please select owner type'}</option>
                  <option value="Brand">{strings.formField?.brand || 'Brand'}</option>
                  <option value="Organization">{strings.formField?.organization || 'Organization'}</option>
                </select>
              </FormField>

              {owner_type === 'Brand' && (
                <EntitySelector
                  boxProps={{ marginTop: 'large' }}
                  callbacks={{
                    selectItem: brand => setEntityState({ owner_id: brand.id }),
                  }}
                  entityKey="brands"
                  selectedItemId={owner_id}
                />
              )}

              {owner_type === 'Organization' && (
              <EntitySelector
                boxProps={{ marginTop: 'large' }}
                callbacks={{
                  selectItem: organization => setEntityState({ owner_id: organization.id }),
                }}
                entityKey="organizations"
                selectedItemId={owner_id}
              />
              )}
            </>
          )}
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={creating}
          onClick={form_template_id ? duplicateFormTemplate : createFormTemplate}
          size="large"
        >
          {strings.buttons?.createTemplate || 'Create Template'}
        </Button>
      </SidebarModal.Footer>
    </>
  )
}

CreateNew.propTypes = {
  callbacks: PropTypes.shape({
    createFormTemplate: PropTypes.func.isRequired,
    duplicateFormTemplate: PropTypes.func.isRequired,
  }).isRequired,
  formTemplateFormPayload: PropTypes.object.isRequired,
}

export default CreateNew
