import { digObject } from '@campaignhub/javascript-utils'

type NotificationGroupItem = {
  key: string,
  notificationMethodKeys: string[],
}

type NotificationGroup = Record<string, NotificationGroupItem[]>

export const notificationGroups: NotificationGroup = {
  digitalPages: [
    {
      key: 'digital_page_accepted',
      notificationMethodKeys: ['email', 'sms'],
    },
  ],
  formTemplates: [
    {
      key: 'form_response_submitted',
      notificationMethodKeys: ['email'],
    },
  ],
  shareLinks: [
    {
      key: 'share_link_viewed',
      notificationMethodKeys: ['email', 'sms'],
    },
  ],
}

export const notificationMethodKeysForGroup = (methodGroupKey: string, notificationMethodKey: string) => {
  const notificationGroup = digObject(notificationGroups, `${methodGroupKey}`, [])

  // eslint-disable-next-line max-len
  const notificationMethod: NotificationGroupItem = notificationGroup.find((group: NotificationGroupItem) => group.key === notificationMethodKey)

  const notificationMethodKeys: string[] = digObject(notificationMethod, 'notificationMethodKeys', [])

  return notificationMethodKeys
}
