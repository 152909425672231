import { ListItem, Text } from '@campaignhub/suit-theme'
import PropTypes from 'prop-types'

const VideoModuleBox = (props) => {
  const {
    videoPayload: {
      key, paragraph, title, videoUrl,
    },
    toggleVideoModule,
  } = props

  return (
    <ListItem
      boxProps={{
        as: 'a',
        alignItems: 'center',
        borderBottom: 'none',
        borderTop: '1px solid',
        borderColor: 'lineColor',
        padding: 'large',
      }}
      onClick={() => toggleVideoModule({
        key,
        paragraph,
        title,
        videoUrl,
      })}
      showAngleIcon
    >
      <Text color="bodyFontLightColor" fontSize="small" lineHeight="1.3">
        {title}
      </Text>
    </ListItem>
  )
}

VideoModuleBox.propTypes = {
  videoPayload: PropTypes.shape({
    key: PropTypes.string.isRequired,
    paragraph: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    videoUrl: PropTypes.string.isRequired,
  }).isRequired,
  toggleVideoModule: PropTypes.func,
}

export default VideoModuleBox
