import React from 'react'

import { BlankState } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import BlankTemplatesUrl from './assets/no-templates.svg'

import localizedStrings from './localizedStrings'

const QuoteTemplatesBlankState = () => {
  const { strings } = useLocalization(localizedStrings)

  return (
    <BlankState boxProps={{ marginBottom: ['large', 0] }} imageUrl={BlankTemplatesUrl}>
      <BlankState.Title>{strings.title || 'No Templates Available'}</BlankState.Title>
      <BlankState.Paragraph>
        {strings.paragraphOne
          || "You either haven't created any templates, or you need to adjust your filters to return more results."}
      </BlankState.Paragraph>
      <BlankState.Paragraph>
        {strings.paragraphTwo
          || "If you haven't started yet you can create one now from the button at the top of the page or from an existing Quote."}
      </BlankState.Paragraph>
    </BlankState>
  )
}

export default QuoteTemplatesBlankState
