import PropTypes from 'prop-types'

import {
  Box, Button, FormField, SidebarModal,
} from '@campaignhub/suit-theme'

import { stringToKey } from '@campaignhub/javascript-utils'

import useCurrentUser from '@hooks/useCurrentUser'
import useCustomFieldSet, { useCustomFieldSetForm } from '@hooks/useCustomFieldSet'
import useLocalization from '@hooks/useLocalization'
import useOrganizationTypes from '@hooks/useOrganizationTypes'
import useProjectTypes from '@hooks/useProjectTypes'

import entityTypes from '@functions/customFieldSet'

import EntitySelector from '@components/EntitySelector'

import localizedStrings from './localizedStrings'

const CreateCustomFieldSetModal = (props) => {
  const {
    callbacks, customFieldSet: initCustomFieldSet, modalKey, showModal,
  } = props

  const { isAdmin } = useCurrentUser()

  const { closeModal, createCustomFieldSet } = callbacks
  const {
    callbacks: { createCustomFieldSet: createFn },
    creating,
  } = useCustomFieldSet()

  const {
    entityState, handlers, saveEnabled, setEntityState,
  } = useCustomFieldSetForm(initCustomFieldSet)

  const {
    entity_type, key, options, owner_id, owner_type, title,
  } = entityState

  const projectTypePayload = useProjectTypes({ performHttpRequests: true })
  const { groupedProjectTypes } = projectTypePayload

  const organizationTypePayload = useOrganizationTypes({ performHttpRequests: true })
  const { organizationTypes } = organizationTypePayload

  const { strings } = useLocalization(localizedStrings)

  const customFieldSetPayload = {
    callbacks: {
      action: createFn,
      afterAction: closeModal,
    },
    entityParams: entityState,
    shouldRedirect: true,
    toastText: strings.toastText?.createCustomFieldSet || 'Custom Field Set Created Successfully',
  }

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.header?.title || 'Create New'}
        titleSecondLine={strings.header?.titleSecondLine || 'Custom Field Set'}
      />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          {isAdmin && (
            <>
              <FormField
                direction="column"
                boxProps={{ marginBottom: 'large' }}
                label={strings.formField?.ownerType || 'Owner Type'}
              >
                <select name="owner_type" value={owner_type} {...handlers} data-validate-field-on="change">
                  <option value="System">{strings.formField?.system || 'System'}</option>
                  <option value="Brand">{strings.formField?.brand || 'Brand'}</option>
                </select>
              </FormField>

              {owner_type === 'Brand' && (
                <EntitySelector
                  boxProps={{ marginBottom: 'large' }}
                  callbacks={{
                    selectItem: brand => setEntityState({ owner_id: brand.id }),
                  }}
                  entityKey="brands"
                  selectedItemId={owner_id}
                />
              )}
            </>
          )}

          <FormField
            direction="column"
            boxProps={{ marginBottom: 'large' }}
            label={strings.formField?.labelFieldSetTitle || 'Field Set Title'}
          >
            <input
              data-validate-field-on="change"
              name="title"
              placeholder={strings.formField?.inputPlaceholder || 'e.g Project Fields'}
              type="text"
              value={title}
              {...handlers}
            />
          </FormField>

          <FormField
            direction="column"
            boxProps={{ marginBottom: 'large' }}
            label={strings.formFieldLabels?.key || 'Key'}
          >
            <input
              name="key"
              placeholder={strings.formFieldLabels?.keyPlaceholder || 'e.g real_estate_sale_campaign'}
              type="text"
              value={key || ''}
              onChange={e => setEntityState({ key: stringToKey(e.target.value) })}
            />
          </FormField>

          <FormField
            direction="column"
            boxProps={{ marginBottom: 'large' }}
            label={strings.formField?.labelEntityType || 'Entity Type'}
          >
            <select name="entity_type" value={entity_type} {...handlers} data-validate-field-on="change">
              <option value="">{strings.formField?.option || 'Please Select...'}</option>
              {entityTypes.map(entityType => (
                <option key={entityType.key} value={entityType.key}>
                  {entityType.label}
                </option>
              ))}
            </select>
          </FormField>

          {entity_type === 'Project' && (
            <FormField direction="column" label="Project Type">
              <select name="options.filters.project_type_key" value={options.filters.project_type_key} {...handlers}>
                <option key=""> Please Select ...</option>
                {groupedProjectTypes.map(groupedProjectType => (
                  <optgroup key={groupedProjectType.id} label={groupedProjectType.title}>
                    {groupedProjectType.subtypes.map(subtype => (
                      <option key={subtype.id} value={subtype.key}>
                        {subtype.title}
                      </option>
                    ))}
                  </optgroup>
                ))}
              </select>
            </FormField>
          )}

          {(entity_type === 'Organization' || entity_type === 'User') && (
            <FormField label="Organization Type">
              <select
                name="options.filters.organization_type_key"
                value={options.filters.organization_type_key}
                {...handlers}
              >
                <option value="">Please Select...</option>
                {Object.values(organizationTypes).map(organizationType => (
                  <option key={organizationType.id} value={organizationType.key}>
                    {organizationType.title}
                  </option>
                ))}
              </select>
            </FormField>
          )}
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled || !(owner_type === 'Brand' ? !!owner_id : true)}
          loading={creating}
          onClick={() => createCustomFieldSet(customFieldSetPayload)}
          size="large"
        >
          {strings.button || 'Create Custom Field Set'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

CreateCustomFieldSetModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  customFieldSet: PropTypes.object,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

CreateCustomFieldSetModal.defaultProps = {
  modalKey: 'CreateCustomFieldSetModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <CreateCustomFieldSetModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
