import PropTypes from 'prop-types'

import {
  Button, FormField, LoadingModule, SidebarModal,
} from '@campaignhub/suit-theme'

import { useFilterString } from '@campaignhub/react-hooks'

import useFormTemplates from '@hooks/useFormTemplates'
import useLocalization from '@hooks/useLocalization'

import FormTemplate from './FormTemplate'

import localizedStrings from './localizedStrings'

const CreateFromTemplate = (props) => {
  const {
    callbacks: { selectScreen, selectTemplate },
    formTemplateFormPayload: { entityState: { owner_id, owner_type } },
  } = props

  const { onChange, value } = useFilterString()

  const formTemplatesPayload = useFormTemplates({
    filters: {
      string: value,
    },
    ownerId: owner_id,
    ownerType: owner_type,
  })

  const {
    filteredFormTemplates,
    hasFormTemplates,
    hasSystemTemplates,
    loading,
    systemTemplates,
  } = formTemplatesPayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      <SidebarModal.Content>
        <LoadingModule loading={loading} times={3} />

        {!loading && hasFormTemplates && (
          <>
            <FormField
              boxProps={{ marginBottom: 'large' }}
              direction="column"
              label={strings.formFieldLabels?.filter || 'Filter Templates'}
            >
              <input onChange={onChange} value={value} />
            </FormField>

            <FormField
              boxProps={{ marginBottom: 'large' }}
              direction="column"
              label={strings.formFieldLabels?.savedTemplates || 'Saved Form Templates'}
            >
              {filteredFormTemplates.map(template => (
                <FormTemplate
                  key={template.id}
                  callbacks={{ selectTemplate: () => selectTemplate(template) }}
                  formTemplate={template}
                />
              ))}
            </FormField>
          </>
        )}

        {!loading && hasSystemTemplates && (
          <FormField
            boxProps={{ marginBottom: 'large' }}
            direction="column"
            label={strings.formFieldLabels?.systemTemplates || 'System Templates'}
          >
            {systemTemplates.map(template => (
              <FormTemplate
                key={template.id}
                callbacks={{ selectTemplate: () => selectTemplate(template) }}
                formTemplate={template}
              />
            ))}
          </FormField>
        )}
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="secondary"
          onClick={() => selectScreen('')}
          size="medium"
        >
          {strings.buttons?.goBack || 'Go Back'}
        </Button>
      </SidebarModal.Footer>
    </>
  )
}

CreateFromTemplate.propTypes = {
  callbacks: PropTypes.shape({
    selectScreen: PropTypes.func.isRequired,
    selectTemplate: PropTypes.func.isRequired,
  }).isRequired,
  formTemplateFormPayload: PropTypes.shape({
    entityState: PropTypes.object.isRequired,
  }).isRequired,
}

export default CreateFromTemplate
