import React from 'react'
import PropTypes from 'prop-types'

import { Box, SidebarModal } from '@campaignhub/suit-theme'

import { availableFeatures } from '@functions/digitalTemplate'

import FeatureItem from './FeatureItem'

const Features = (props) => {
  const { digitalTemplatePayload, enabledFeatures } = props

  const {
    callbacks: { toggleDigitalTemplateFeature: toggleFeature },
  } = digitalTemplatePayload

  return (
    <SidebarModal.ExpandableSectionBox label="Features">
      <Box flexDirection="column">
        {availableFeatures.map((feature, index) => {
          const { key } = feature
          const featureEnabled = !!enabledFeatures.find(featureObj => featureObj.key === key)
          const lastInput = availableFeatures.length === index + 1

          return (
            <FeatureItem
              callbacks={{ toggle: () => toggleFeature(key) }}
              feature={feature}
              featureEnabled={featureEnabled}
              featureKey={key}
              key={key}
              lastInput={lastInput}
            />
          )
        })}
      </Box>
    </SidebarModal.ExpandableSectionBox>
  )
}

Features.propTypes = {
  digitalTemplatePayload: PropTypes.object.isRequired,
  enabledFeatures: PropTypes.array.isRequired,
}

export default Features
