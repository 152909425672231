import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import {
  BlankState, Box, Button, LoadingBubbles, SidebarModal,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import AgreementImage from './assets/agreements.svg'

import localizedStrings from './localizedStrings'

const ContextWrappedModal = (props) => {
  const { context } = props
  const contextProps = useContext(context)

  if (context){
    const mergedProps = {
      ...props,
      ...contextProps,
    }

    return <BuildingAgreementModal {...mergedProps} />
  }

  return <BuildingAgreementModal {...props} />
}

ContextWrappedModal.propTypes = {
  context: PropTypes.object,
}

const BuildingAgreementModal = (props) => {
  const { callbacks, modalKey, showModal } = props

  const { strings } = useLocalization(localizedStrings)

  if (!showModal){
    return null
  }

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings?.header?.title || 'Building'}
        titleSecondLine={strings?.header?.titleSecondLine || 'Agreement'}
      />

      <SidebarModal.Content hasFooter={false}>
        <Box flexDirection="column">
          <BlankState imageUrl={AgreementImage} sidebar>
            <BlankState.Paragraph>
              {strings.blankState?.paragraphOne
                || 'Have a quick break while we build your agreement. Once complete it will be available to download or send for signing'}
            </BlankState.Paragraph>

            <BlankState.Paragraph>
              {strings.blankState?.paragraphTwo
                || 'Please wait while we compile your Agreement in the background. It should only take a few moments. If you experience any issues please contact support.'}
            </BlankState.Paragraph>

            <BlankState.Actions>
              <Button
                buttonStyle="secondaryEdit"
                icon={<LoadingBubbles color="lightGrey" />}
                size="medium"
                width="auto"
              >
                {strings.blankState?.button || 'Please Wait...'}
              </Button>
            </BlankState.Actions>
          </BlankState>
        </Box>
      </SidebarModal.Content>
    </SidebarModal>
  )
}

BuildingAgreementModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

BuildingAgreementModal.defaultProps = {
  modalKey: 'BuildingAgreementModal',
}

export default ContextWrappedModal
