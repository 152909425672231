import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-light-svg-icons'

import {
  Button, FormField, LoadingModule, SidebarModal,
} from '@campaignhub/suit-theme'

import { useSetState } from '@campaignhub/react-hooks'

import useLocalization from '@hooks/useLocalization'
import useProjects from '@hooks/useProjects'
import useReduxAction from '@hooks/useReduxAction'

import Search from '@components/Search'

import localizedStrings from './localizedStrings'
import ProjectListItem from './components/ProjectListItem'

const mapProjectResults = ({ entities: projects }) => ({
  groups: [
    {
      key: 'projects',
      title: 'PROJECTS',
      items: projects.map(project => ({
        id: project.id,
        key: project.id,
        text: project.title,
      })),
    },
  ],
})

const defaultState = {
  selectedProject: {},
}

const SelectProjectModal = (props) => {
  const { callbacks, modalKey, showModal } = props
  const { closeModal, showCreateProjectModal, submitAction } = callbacks || {}

  const [state, setState] = useSetState(defaultState)
  const { selectedProject } = state

  const projectsPayload = useProjects({ limit: 5 })
  const { filteredProjects, hasFilteredProjects, loading } = projectsPayload

  useReduxAction('projects', 'loadProjects')

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.titleText || 'Select'}
        titleSecondLine={strings.titleSecondLine || 'Project'}
      />

      <SidebarModal.Content>
        <FormField direction="column" label={strings.formFieldLabels?.searchProjects || 'Search Projects'} marginTop="large">
          <Search
            callbacks={{
              selectItem: selected => setState({ selectedProject: selected }),
              mapResults: data => mapProjectResults(data),
            }}
            endpoint="/projects.json"
            placeholder={strings.selectProject || 'Select Project'}
            requestOptions={{ include_project_data_store_items: true }}
          />
        </FormField>

        <LoadingModule loading={loading} />

        {hasFilteredProjects && (
          <FormField
            direction="column"
            label={strings.formFieldLabels?.recentProjects || 'Select from Recent Projects'}
            marginTop="large"
          >
            {filteredProjects.map(project => (
              <ProjectListItem
                added={project.id === selectedProject.id}
                callbacks={{ selectProject: () => setState({ selectedProject: project }) }}
                project={project}
              />
            ))}
          </FormField>
        )}

        {selectedProject.id && (
          <FormField direction="column" label={strings.project || 'Project'} marginTop="large">
            <ProjectListItem
              callbacks={{ selectProject: () => setState({ selectedProject: {} }) }}
              project={selectedProject}
              selected
            />
          </FormField>
        )}

        <FormField direction="column" label={strings.formFieldLabels?.moreOptions || 'More Options'} marginTop="large">
          <Button
            buttonStyle="secondaryUtility"
            icon={<FontAwesomeIcon icon={faPlus} />}
            onClick={showCreateProjectModal}
            size="medium"
          >
            {strings.buttonText?.createProject || 'Create Project'}
          </Button>
        </FormField>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!selectedProject.id}
          loading={false}
          onClick={() => submitAction(selectedProject)}
          size="large"
        >
          {strings.buttonText?.selectProject || 'Select Project'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

SelectProjectModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

SelectProjectModal.defaultProps = {
  modalKey: 'SelectProjectModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <SelectProjectModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
