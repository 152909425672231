import { useContext } from 'react'
import { useSelector } from 'react-redux'

import { useLatestEntity } from '@campaignhub/react-hooks'

import { digObject } from '@campaignhub/javascript-utils'

import PageContext from '@contexts/pageContext'

const editFormResponse = (formResponse, showManageFormResponseModal) => new Promise((resolve, reject) => {
  if (showManageFormResponseModal){
    const payload = {
      formResponse,
    }

    showManageFormResponseModal(payload)

    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showManageFormResponseModal not defined in PageContext callbacks'))
})

export function useRelations(formResponse = {}){
  const { digital_template_id } = formResponse
  const formTemplateId = digObject(formResponse, 'data.form_template_id')

  const entities = useSelector(reduxState => reduxState.entities)
  const { digitalTemplates, formTemplates } = entities

  const digitalTemplate = digitalTemplates[digital_template_id] || {}
  const formTemplate = formTemplates[formTemplateId] || {}

  return {
    digitalTemplate,
    formTemplate,
  }
}

function useFormResponse(initFormResponse = {}){
  const { entity: dataStoreItem } = useLatestEntity(initFormResponse, 'dataStoreItems')

  const { callbacks } = useContext(PageContext)
  const { showManageFormResponseModal } = callbacks || {}

  const formResponseRelations = useRelations(dataStoreItem)
  const { digitalTemplate, formTemplate } = formResponseRelations

  const formResponseValues = JSON.parse(dataStoreItem.value)

  return {
    callbacks: {
      editFormResponse: () => editFormResponse(dataStoreItem, showManageFormResponseModal),
    },
    dataStoreItem,
    digitalTemplate,
    formResponseValues,
    formTemplate,
  }
}

export default useFormResponse
