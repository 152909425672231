import PropTypes from 'prop-types'
import swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Heading, SortableList,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import QuoteSection from '../QuoteSection'

import MoreOptions from './MoreOptions'
import QuoteTotal from './QuoteTotal'
import localizedStrings from './localizedStrings'

const confirmDelete = (deleteFn, redirectUrl, strings) => {
  swal
    .fire({
      title: strings.swal?.title || 'Delete Quote?',
      text: strings.swal?.text || 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: strings.swal?.confirmButtonText || 'Yes, Delete it.',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        deleteFn().then(() => {
          window.location.hash = redirectUrl
        })
      }
    })
}

const Quote = (props) => {
  const {
    quoteBuilderPayload: {
      callbacks,
      quotePayload,
      quotePayload: {
        callbacks: { deleteQuote, modifySectionItemSort },
        filteredSections,
        isSavedTemplate,
        lastSectionIndex,
        quote: { title, total },
        sortedSectionItemIds,
        urls: { quoteListUrl, quoteTemplateListUrl },
      },
    },
  } = props

  const redirectUrl = isSavedTemplate ? quoteTemplateListUrl : quoteListUrl

  const { strings } = useLocalization(localizedStrings)

  return (
    <Box flexDirection="column" marginBottom="large" variant="white">
      <Box
        alignItems={['flex-start', 'center']}
        borderBottom="1px solid"
        borderColor="lineColor"
        flexDirection={['column', 'row']}
        padding="large"
      >
        <Heading size="large" textProps={{ lineHeight: '1.3' }}>
          {title || strings.loading || 'Loading...'}
        </Heading>

        <Box
          borderTop={['1px dashed', 'none']}
          borderColor="lineColor"
          justifyContent="flex-end"
          marginTop={['large', 0]}
          paddingTop={['large', 0]}
          marginLeft="auto"
          width={['100%', 'auto']}
        >
          <MoreOptions quotePayload={quotePayload} />
        </Box>
      </Box>

      <SortableList
        callbacks={{
          onDragEnd: (fromIndex, toIndex, payload) => modifySectionItemSort(fromIndex, toIndex, payload),
        }}
      >
        {filteredSections.map((quoteSection, index) => {
          const { id } = quoteSection
          const sortedItemIds = sortedSectionItemIds[`Section-${id}`] || []

          return (
            <QuoteSection
              callbacks={callbacks}
              first={index === 0}
              index={index}
              key={quoteSection.id}
              last={lastSectionIndex === index}
              lastIndex={lastSectionIndex}
              quoteSection={quoteSection}
              quoteSections={filteredSections}
              sortedQuoteItemIds={sortedItemIds}
            />
          )
        })}
      </SortableList>

      <QuoteTotal total={total} />

      <Box borderTop="1px solid" borderColor="lineColor" padding="large">
        <Button
          buttonStyle="secondaryUtility"
          icon={<FontAwesomeIcon icon={faTrashAlt} />}
          onClick={() => confirmDelete(deleteQuote, redirectUrl, strings)}
          marginRight="medium"
          size="medium"
          width="auto"
        >
          {strings.deleteButton || 'Delete'}
        </Button>

        <Box marginLeft="auto" width="auto">
          <MoreOptions quotePayload={quotePayload} />
        </Box>
      </Box>
    </Box>
  )
}

Quote.propTypes = {
  quoteBuilderPayload: PropTypes.shape({
    callbacks: PropTypes.object.isRequired,
    quotePayload: PropTypes.shape({
      callbacks: PropTypes.shape({
        deleteQuote: PropTypes.func.isRequired,
        modifySectionItemSort: PropTypes.func.isRequired,
        saveAsTemplate: PropTypes.func.isRequired,
      }).isRequired,
      filteredSections: PropTypes.array.isRequired,
      isSavedTemplate: PropTypes.bool,
      lastSectionIndex: PropTypes.number,
      quote: PropTypes.object.isRequired,
      sortedSectionItemIds: PropTypes.object.isRequired,
      urls: PropTypes.shape({
        quoteListUrl: PropTypes.string.isRequired,
        quoteTemplateListUrl: PropTypes.string.isRequired,
      }),
    }).isRequired,
  }).isRequired,
}

export default Quote
