import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useSetState } from '@campaignhub/react-hooks'
import { toggleArray } from '@campaignhub/javascript-utils'

import useDigitalPages from '@hooks/useDigitalPages'
import useIntegrations from '@hooks/useIntegrations'
import useOrganizationSelector from '@hooks/useOrganizationSelector'
import useReduxAction from '@hooks/useReduxAction'

import defaultRequestOptions from '@sections/Client/packs/Dashboard/defaultRequestOptions'

const entityKeys = [
  'case_studies',
  'comparables',
  'documents',
  'event_calendars',
  'quotes',
  'reviews',
  'target_audiences',
]

const toggleEntityKey = (entityKey, state, setState) => {
  const { selectedEntityKeys } = state

  const updatedEntityKeys = toggleArray(selectedEntityKeys, entityKey)

  setState({ selectedEntityKeys: updatedEntityKeys })
}

const toggleAllEntityKeys = (state, setState) => {
  const { selectedEntityKeys } = state

  if (selectedEntityKeys.length === entityKeys.length){
    setState({ selectedEntityKeys: [] })
    return
  }
  setState({ selectedEntityKeys: entityKeys.map(item => item) })
}

const defaultState = {
  targetProjectId: null,
  sourceDigitalPageId: null,
  selectedEntityKeys: [],
  sourceProjectId: null,
}

const useDuplicateDigitalPage = () => {
  const [state, setState] = useSetState(defaultState)
  const { sourceDigitalPageId, sourceProjectId } = state

  const { loading: loadingDigitalPages } = useReduxAction(
    'digitalPages',
    'loadDigitalPages',
    {
      ...defaultRequestOptions.digitalPage,
      ...defaultRequestOptions.image,
      subject_id: sourceProjectId,
      subject_type: 'Project',
    },
    [sourceProjectId],
    {
      shouldPerformFn: ({ loading }) => !!sourceProjectId && !loading,
    },
  )

  const { digitalPages } = useSelector(reduxState => reduxState.entities)

  const sourceDigitalPage = digitalPages[sourceDigitalPageId] || {}

  const { filteredDigitalPages } = useDigitalPages({ subject_id: sourceProjectId })

  const { selectedOrganization } = useOrganizationSelector()

  const { hasIntegrations: hasQuoteIntegrations } = useIntegrations({
    featureKeys: ['quoting'],
    filters: {
      owner_id: selectedOrganization.id,
      owner_type: 'Organization',
    },
  })

  const filteredEntityKeys = entityKeys.filter(key => (hasQuoteIntegrations ? key !== 'quotes' : key))
  // To be removed later
  // The idea is to test if users are using comparables to duplicate
  const filteredEntityKeysWithoutComparables = filteredEntityKeys.filter(key => key !== 'comparables')

  useEffect(() => {
    setState({ selectedEntityKeys: filteredEntityKeysWithoutComparables })
  }, [])

  useEffect(() => {
    if (sourceProjectId && filteredDigitalPages.length === 1){
      setState({ sourceDigitalPageId: filteredDigitalPages[0]?.id })
    }
  }, [])

  return {
    callbacks: {
      setState,
      toggleAllEntityKeys: () => toggleAllEntityKeys(state, setState),
      toggleEntityKey: entityKey => toggleEntityKey(entityKey, state, setState),
    },
    entityKeys: filteredEntityKeys,
    filteredDigitalPages,
    loadingDigitalPages,
    sourceDigitalPage,
    ...state,
  }
}

export default useDuplicateDigitalPage
