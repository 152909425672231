import PropTypes from 'prop-types'

import { faCheck, faUser, faUsers } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import useReduxAction from '@hooks/useReduxAction'

import {
  Box, Button, Columns, LoadingModule, PageHeader,
} from '@campaignhub/suit-theme'

import Awards from '@components/Awards'
import EntityCustomFields from '@components/EntityCustomFields'
import VideoModule from '@components/VideoModule'

import handleCallbackAction from '@functions/handleCallbackAction'

import useEntityCustomFieldsForm from '@hooks/useEntityCustomFieldsForm'

import useLocalization from '@hooks/useLocalization'
import useOrganization from '@hooks/useOrganization'
import useOrganizationSelector from '@hooks/useOrganizationSelector'
import useUser, { useUserForm } from '@hooks/useUser'
import useVideoModules from '@hooks/useVideoModules'

import defaultRequestOptions from '@sections/Client/packs/Admin/defaultRequestOptions'

import Details from '../Details'
import NotificationOptions from '../NotificationOptions'
import OrganizationsUsers from '../OrganizationsUsers'
import Reviews from '../Reviews'
import Teams from '../Teams'
import UserImages from '../UserImages'

import localizedStrings from './localizedStrings'

const userRequestOptions = {
  user: {
    include_user_data_store_items: true,
    include_user_image_sizes: ['thumb_180x180', 'thumb_660x360'],
    include_user_images: true,
    include_user_organizations_users: true,
  },
}

const generateTabItems = (user, isSelectedOrganizationAdmin, strings, urls) => {
  const { full_name } = user
  const { editUserUrl, indexUsersUrl } = urls

  if (isSelectedOrganizationAdmin){
    return [
      {
        href: indexUsersUrl,
        icon: faUsers,
        key: 'users',
        title: strings.tabTitle || 'All Users',
      },
      {
        href: editUserUrl,
        icon: faUser,
        key: 'editUser',
        title: full_name || strings.title || 'Edit User',
      },
    ]
  }

  return [
    {
      href: editUserUrl,
      icon: faUser,
      key: 'editUser',
      title: full_name || strings.title || 'Edit User',
    },
  ]
}

const PageContent = (props) => {
  const { userId } = props

  const userPayload = useUser({ id: userId })
  const {
    callbacks: { updateUser: updateFn },
    loading,
    updating,
    urls,
    user,
  } = userPayload

  const userFormPayload = useUserForm(user)
  const { entityState, saveEnabled } = userFormPayload

  const { isSelectedOrganizationAdmin, selectedOrganization } = useOrganizationSelector()

  const {
    organizationType: { key: organizationTypeKey },
  } = useOrganization(selectedOrganization)

  useReduxAction(
    'users',
    'loadUser',
    {
      ...defaultRequestOptions.user,
      ...userRequestOptions.user,
    },
    [userId],
    {
      dispatchAction: (action, requestOptions) => action(userId, requestOptions),
      shouldPerformFn: (entityReducer) => {
        const { loadedIds } = entityReducer
        return userId && !loadedIds.includes(userId)
      },
    },
  )

  const customFieldsForm = useEntityCustomFieldsForm(user, selectedOrganization, {
    filterBy: { organization_type_key: organizationTypeKey },
    performHttpRequests: true,
  })

  const {
    callbacks: { buildNestedValues },
  } = customFieldsForm

  const videoModulePayload = useVideoModules({ key: 'users' })
  const { videosPayload } = videoModulePayload

  const { strings } = useLocalization(localizedStrings)

  const updateUserPayload = {
    callbacks: {
      action: updateFn,
    },
    entityParams: { userParams: entityState, customFields: buildNestedValues() },
    requestOptions: {
      ...defaultRequestOptions.user,
      ...defaultRequestOptions.image,
      ...defaultRequestOptions.team,
      ...userRequestOptions.user,
    },
    toastText: strings.updateUser || 'User Updated Successfully',
  }

  return (
    <>
      <PageHeader
        activeTabBarItemKey="editUser"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        nestedNavigation
        tabBarItems={generateTabItems(user, isSelectedOrganizationAdmin, strings, urls)}
        title={strings.title || 'Edit User'}
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && (
              <>
                <Details userFormPayload={userFormPayload} />

                <EntityCustomFields customFieldsForm={customFieldsForm} />

                <NotificationOptions userFormPayload={userFormPayload} />

                <Box justifyContent="flex-end" marginBottom="large" padding="large" variant="white">
                  <Button
                    buttonStyle="primaryEdit"
                    disabled={!saveEnabled}
                    icon={<FontAwesomeIcon icon={faCheck} />}
                    loading={updating}
                    onClick={() => handleCallbackAction(updateUserPayload)}
                    size="medium"
                    width={['100%', 175]}
                  >
                    {strings.saveButton || 'Save Details'}
                  </Button>
                </Box>
              </>
            )}
          </Columns.Main>

          <Columns.Sidebar>
            {!loading && (
              <>
                <UserImages user={user} />

                <Awards
                  ownerId={selectedOrganization?.id}
                  subjectId={userId}
                  subjectType="User"
                />

                <Reviews selectedUserId={userId} />

                <Teams selectedUserId={userId} />

                <OrganizationsUsers organization={selectedOrganization} user={user} />
              </>
            )}

            <VideoModule videosPayload={videosPayload} />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

PageContent.propTypes = {
  userId: PropTypes.number.isRequired,
}

export default PageContent
