import { Box, ListItem, Text } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'
import useQuote from '@hooks/useQuote'

import type { QuoteModel } from '@models/types'

interface LargeListProps {
  quote: QuoteModel
}

const LargeList = (props: LargeListProps) => {
  const {
    quote,
    quote: {
      dates: {
        updated: { date_time_with_timezone },
      },
      title,
      total,
    },
  } = props

  const {
    urls: { editQuoteUrl },
  } = useQuote(quote)

  const {
    callbacks: { formatCurrency, formatDate },
  } = useLocalization()

  return (
    <ListItem
      boxProps={{
        as: 'a',
        borderBottom: '1px solid',
        padding: 'large',
        justifyContent: 'center',
      }}
      href={editQuoteUrl}
      showAngleIcon
    >
      <Box flexDirection="row" alignItems="center">
        <Box flexDirection="column">
          <Text fontSize="small" marginRight="auto" marginBottom="medium">
            {title}
          </Text>

          <Text color="bodyFontLightColor" fontSize="xsmall">
            {formatDate(date_time_with_timezone)}
          </Text>
        </Box>

        <Text color="bodyFontLightColor" fontSize="xsmall">
          {formatCurrency(total)}
        </Text>
      </Box>
    </ListItem>
  )
}

export default LargeList
