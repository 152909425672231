import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, FormField, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useAgreement, { useAgreementForm } from '@hooks/useAgreement'
import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const confirmDelete = (deleteAgreement, deleteFn) => {
  swal
    .fire({
      title: 'Delete Agreement?',
      text: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        deleteAgreement(deleteFn)
      }
    })
}

const EditAgreementModal = (props) => {
  const {
    callbacks, disableAnimation, disableOverlay, modalKey, showModal,
  } = props
  const { deleteAgreement, updateAgreement } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const { agreement: initAgreement } = digObject(modalData, `${modalKey}`, {})
  const {
    callbacks: { deleteAgreement: deleteFn, updateAgreement: updateFn },
    agreement,
    filteredStatuses,
  } = useAgreement(initAgreement)

  const agreementFormPayload = useAgreementForm(agreement)
  const {
    entityState,
    entityState: {
      status_id,
      title,
    },
    errors,
    handlers,
    saveEnabled,
    updating,
  } = agreementFormPayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal
      callbacks={callbacks}
      disableAnimation={disableAnimation}
      disableOverlay={disableOverlay}
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.header?.title || 'Edit'}
        titleSecondLine={strings.header?.titleSecondLine || 'Agreement'}
      />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          {/* <FormField
            label={strings.formFieldLabels?.documentTemplate || 'Document Template'}
          >
          </FormField> */}

          <FormField
            direction="column"
            boxProps={{ marginBottom: 'large' }}
            errorMessage={errors.title}
            label={strings.formFieldLabels?.title || '* Title'}
          >
            <input data-validate-field-on="change" name="title" type="text" value={title} {...handlers} />
          </FormField>

          <FormField
            direction="column"
            boxProps={{ marginBottom: 'large' }}
            errorMessage={errors.status_id}
            label={strings.formFieldLabels?.status || 'Status'}
          >
            <select name="status_id" value={status_id || ''} {...handlers} data-validate-field-on="change">
              <option value="">{strings.option || 'Please Select...'}</option>
              {filteredStatuses.map(status => (
                <option key={status.id} value={status.id}>
                  {status.title}
                </option>
              ))}
            </select>
          </FormField>
        </Box>

        <FormField direction="column" label={strings.formFieldLabels?.moreOptions || 'More Options'}>
          <Box flexDirection="column" flexShrink={0}>
            <Button
              buttonStyle="secondaryUtility"
              onClick={() => confirmDelete(deleteAgreement, deleteFn)}
              icon={<FontAwesomeIcon icon={faTrashAlt} />}
              size="medium"
              width="100%"
            >
              {strings.buttons?.delete || 'Delete Agreement'}
            </Button>
          </Box>
        </FormField>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={updating}
          onClick={() => updateAgreement(entityState, updateFn)}
          size="large"
        >
          {strings.buttons?.save || 'Save'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

EditAgreementModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  disableAnimation: PropTypes.bool,
  disableOverlay: PropTypes.bool,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

EditAgreementModal.defaultProps = {
  modalKey: 'EditAgreementModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <EditAgreementModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
