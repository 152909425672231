import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { faFile } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Columns, DashboardModule, LoadingModule, PageHeader,
} from '@campaignhub/suit-theme'

import { digObject, sortArrayBy } from '@campaignhub/javascript-utils'

import PageContext from '@contexts/pageContext'

import useAgreements from '@hooks/useAgreements'
import useLocalization from '@hooks/useLocalization'
import useOrganizationSelector from '@hooks/useOrganizationSelector'
import useUserIntegrations from '@hooks/useUserIntegrations'
import useVideoModules from '@hooks/useVideoModules'

import IntegrationPartner from '@components/IntegrationPartner'
import PageFilterModule from '@components/PageFilterModule'
import VideoModule from '@components/VideoModule'

import Agreement from '../Agreement'
import ExternalDocument from '../ExternalDocument'
import AgreementBlankState from '../AgreementBlankState'

import localizedStrings from './localizedStrings'

const permittedStatusKeys = ['accepted', 'pending', 'processed', 'rejected']

const buildPageFilterFields = (entities, strings, formatString) => {
  const { statuses } = entities

  const boxProps = {
    marginBottom: 'large',
  }

  const style = {
    marginBottom: 16,
  }

  const filteredStatuses = Object.values(statuses).filter(status => permittedStatusKeys.includes(status.key))

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        style,
      },
      key: 'string',
      label: strings.filters?.filterByTitle || 'Filter by Title',
    },
    {
      component: 'EntitySelector',
      componentProps: {
        boxProps,
        entityKey: 'projects',
      },
      key: 'subject_id',
    },
    {
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: strings.defaultSelectLabel || 'All',
        inputComponent: 'select',
        style,
      },
      key: 'status',
      label: strings.filters?.status || 'Status',
      values: sortArrayBy(filteredStatuses, 'asc', 'title').map(status => ({
        key: status.key,
        label: formatString(strings.filters?.statusTitle, { status }) || status.title,
      })),
    },
  ]
}

const PageContent = (props) => {
  const { pageFilters } = props

  const agreementsPayload = useAgreements({
    performHttpRequests: true,
    subjectType: 'Project',
    ...pageFilters,
  })

  const {
    agreementGroupKeys, groupedAgreements, hasAgreements, loading,
  } = agreementsPayload

  const entities = useSelector(reduxState => reduxState.entities)

  const pageContext = useContext(PageContext)
  const {
    callbacks: { resetFilters, showSelectProjectModal, updateFilters },
    defaultFilters,
  } = pageContext

  const useIntegrationsPayload = useUserIntegrations({ integrationPlatformKey: 'real_time_agent' })
  const { hasIntegrationForKey } = useIntegrationsPayload

  const { selectedOrganizationAddress } = useOrganizationSelector()
  const selectedOrganizationState = digObject(selectedOrganizationAddress, 'state_name', '').toLowerCase()

  const shouldShowIntegrationPartner = selectedOrganizationState !== 'wa' && selectedOrganizationState !== 'nt'

  const videoModulePayload = useVideoModules({ key: 'projectAgreements' })
  const { videosPayload } = videoModulePayload

  const {
    callbacks: { formatString },
    strings,
  } = useLocalization(localizedStrings)

  return (
    <>
      <PageHeader
        actionContent={(
          <Button buttonStyle="primaryCreate" onClick={showSelectProjectModal} size="medium" width="auto">
            {strings.createButton || 'Create'}
          </Button>
        )}
        activeTabBarItemKey="Agreements"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        tabBarItems={[
          {
            href: '#/overviews/agreements',
            icon: faFile,
            key: 'Agreements',
            title: strings.title || 'Agreements',
          },
        ]}
        title={strings.title || 'Agreements'}
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && !hasAgreements && <AgreementBlankState />}

            {!loading
              && hasAgreements
              && agreementGroupKeys.map((groupKey) => {
                const agreements = groupedAgreements[groupKey]
                const AgreementComponent = groupKey === 'agreements' ? Agreement : ExternalDocument

                if (agreements.length){
                  return (
                    <DashboardModule
                      key={groupKey}
                      title={
                        groupKey === 'agreements'
                          ? strings.agreementTitle || 'Agreements'
                          : strings.externalDocumentTitle || 'External Documents'
                      }
                    >
                      <Box flexDirection="column">
                        {agreements.map(agreement => (
                          <AgreementComponent key={agreement.id} agreement={agreement} />
                        ))}
                      </Box>
                    </DashboardModule>
                  )
                }

                return null
              })}
          </Columns.Main>

          <Columns.Sidebar>
            <PageFilterModule
              callbacks={{ resetFilters, updateFilters }}
              defaultFilters={defaultFilters}
              filterFields={buildPageFilterFields(entities, strings, formatString)}
              pageFilters={pageFilters}
              title={strings.pageFilterModuleTitle || 'Filters'}
            />

            {!hasIntegrationForKey && shouldShowIntegrationPartner && (
              <IntegrationPartner
                description={
                  strings.integrationPartner?.description || 'Learn how to generate an agreement with RTA integration'
                }
                integrationPlatformKey={strings.integrationPartner?.integrationPlatformKey || 'real_time_agent'}
                title={strings.integrationPartner?.title || 'Sign up to Real Time Agent'}
              />
            )}

            {hasIntegrationForKey && (
              <VideoModule
                marginTop={!hasIntegrationForKey && shouldShowIntegrationPartner && 'large'}
                videosPayload={videosPayload}
              />
            )}
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

PageContent.propTypes = {
  pageFilters: PropTypes.object.isRequired,
}

export default PageContent
