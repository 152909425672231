import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, Button, DashboardModule, Link, FormField,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import User from './User'
import localizedStrings from './localizedStrings'

const Users = (props) => {
  const {
    callbacks: { manageProjectUsers },
    leadUsers,
    supportUsers,
  } = props

  const hasUsers = !!leadUsers.length || !!supportUsers.length

  const { strings } = useLocalization(localizedStrings)

  return (
    <DashboardModule
      headerRight={(
        <Link onClick={manageProjectUsers} textProps={{ fontSize: 'small' }}>
          {strings.manage || 'Manage'}
        </Link>
      )}
      title={strings.title}
    >
      <Box flexDirection="column" maxHeight={415} overflow="auto">
        {!!leadUsers.length && (
          <FormField labelProps={{ marginLeft: 'large' }} label={strings.lead} marginTop="large">
            {leadUsers.map((leadUser, index) => (
              <User key={leadUser.id} isFirstUser={index === 0} user={leadUser} />
            ))}
          </FormField>
        )}

        {!!supportUsers.length && (
          <FormField labelProps={{ marginLeft: 'large' }} label={strings.support} marginTop="large">
            {supportUsers.map((supportUser, index) => (
              <User key={supportUser.id} isFirstUser={index === 0} user={supportUser} />
            ))}
          </FormField>
        )}

        {!hasUsers && (
          <Box padding="large">
            <Button buttonStyle="secondaryUtility" onClick={manageProjectUsers} size="medium">
              {strings.addButton}
            </Button>
          </Box>
        )}
      </Box>
    </DashboardModule>
  )
}

Users.propTypes = {
  callbacks: PropTypes.shape({
    manageProjectUsers: PropTypes.func,
  }).isRequired,
  leadUsers: PropTypes.array.isRequired,
  supportUsers: PropTypes.array.isRequired,
}

export default Users
