import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { Button, ModalContext, SidebarModal } from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import DraggableUsers from '@components/ModalComponents/DraggableUsers'

import useLocalization from '@hooks/useLocalization'
import useProject, { useProjectForm } from '@hooks/useProject'
import useUsers from '@hooks/useUsers'

import localizedStrings from './localizedStrings'

const ManageProjectUsersModal = (props) => {
  const {
    callbacks, disableAnimation, disableOverlay, modalKey, project: initProject, showModal,
  } = props
  const { updateProject } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const { callbacks: payloadCallbacks } = digObject(modalData, modalKey, {})
  const { loadShortcodeData: loadDataFn } = payloadCallbacks || {}

  const {
    callbacks: { updateProject: updateFn },
    project,
    updating,
  } = useProject(initProject)

  const projectFormPayload = useProjectForm(project)

  const {
    entityState,
    entityState: { lead_user_ids, support_user_ids, team_ids },
    setEntityState,
  } = projectFormPayload

  const groupedUserIds = {
    lead_user_ids,
    support_user_ids,
  }

  const {
    callbacks: { modifyUserSort, toggleUsers },
  } = useUsers()

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal
      callbacks={callbacks}
      disableAnimation={disableAnimation}
      disableOverlay={disableOverlay}
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.sideBarModalHeader?.title || 'Manage'}
        titleSecondLine={strings.sideBarModalHeader?.titleSecondLine || 'Users'}
      />

      <SidebarModal.Content>
        <DraggableUsers
          callbacks={{
            modifyUserSort: (fromIndex, toIndex, payload) => modifyUserSort(fromIndex, toIndex, payload, groupedUserIds, setEntityState),
            toggleUsers: (userId, listName) => toggleUsers(userId, groupedUserIds, listName, setEntityState),
          }}
          userIds={groupedUserIds}
          showOnFocus
        />
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!(team_ids.length || lead_user_ids.length)}
          loading={updating}
          loadingBubbleColor="white"
          onClick={() => updateProject(entityState, 'Users', updateFn, loadDataFn)}
          size="large"
        >
          {strings.buttons?.save || 'Save'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

ManageProjectUsersModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  disableAnimation: PropTypes.bool,
  disableOverlay: PropTypes.bool,
  modalKey: PropTypes.string,
  project: PropTypes.object.isRequired,
  showModal: PropTypes.bool,
}

ManageProjectUsersModal.defaultProps = {
  modalKey: 'ManageProjectUsersModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <ManageProjectUsersModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
