import { useContext, useEffect } from 'react'

import { faUserTag } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Columns, DashboardModule, LoadingModule, PageHeader,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import PageFilterModule from '@components/PageFilterModule'
import TargetAudienceListItem from '@components/TargetAudienceListItem'

import type { TargetAudienceFilters } from '@hooks/useTargetAudiences'
import useBrandSelector from '@hooks/useBrandSelector'
import useCurrentUser from '@hooks/useCurrentUser'
import useIntercom from '@hooks/useIntercom'
import useMixpanel from '@hooks/useMixpanel'
import useTargetAudience from '@hooks/useTargetAudience'
import useTargetAudiences from '@hooks/useTargetAudiences'

import TargetAudiencesBlankState from '../../TargetAudiencesBlankList'

const buildPageFilterFields = (isAdmin: boolean) => {
  const boxProps = {
    marginBottom: 'large',
  }

  const style = {
    marginBottom: 16,
  }

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        style,
      },
      key: 'string',
      label: 'Filter by Title',
    },
    {
      callbacks: {
        shouldRender: () => isAdmin,
      },
      entityKey: 'owner_types',
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: 'All',
        inputComponent: 'select',
        style,
      },
      key: 'owner_type',
      label: 'Owner Type',
      values: [
        { key: 'Brand', label: 'Brand' },
        { key: 'System', label: 'System' },
        { key: 'Organization', label: 'Organization' },
      ],
    },
    {
      callbacks: {
        shouldRender: ({ owner_type }: { owner_type?: string }) => owner_type === 'Brand',
      },
      component: 'EntitySelector',
      componentProps: {
        entityKey: 'brands',
        boxProps,
      },
      key: 'brand_id',
    },
    {
      callbacks: {
        shouldRender: ({ owner_type }: { owner_type?: string }) => owner_type === 'Organization',
      },
      component: 'EntitySelector',
      componentProps: {
        entityKey: 'organizations',
        boxProps,
      },
      key: 'organization_id',
    },
    {
      callbacks: {
        shouldRender: () => !isAdmin,
      },
      component: 'EntitySelector',
      componentProps: {
        boxProps,
        entityKey: 'organizations',
      },
      key: 'organization_id',
    },
    {
      entityKey: 'limit',
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: '100',
        inputComponent: 'select',
        style,
      },
      key: 'limit',
      label: 'Limit',
      values: [
        { key: 150, label: '150' },
        { key: 200, label: '200' },
      ],
    },
  ]
}

interface PageContentProps {
  pageFilters: TargetAudienceFilters,
}

const PageContent = (props: PageContentProps) => {
  const { pageFilters } = props

  const { selectedBrand } = useBrandSelector()
  const { filteredTargetAudiences, hasTargetAudiences, loading } = useTargetAudiences({
    filters: pageFilters,
  })

  const initTargetAudience = {
    organization_type_id: selectedBrand.organization_type_id,
    owner_id: selectedBrand.id,
    owner_type: 'Brand',
  }

  const targetAudiencePayload = useTargetAudience(initTargetAudience)

  const {
    callbacks: { createOrEditTargetAudience },
  } = targetAudiencePayload

  const pageContext = useContext(PageContext)
  const {
    callbacks: { resetFilters, updateFilters },
  } = pageContext

  const { isAdmin, isBrandUser } = useCurrentUser()

  useIntercom({ hideWidget: !isBrandUser })

  // Tracks the page load event
  const { callbacks: { dispatchMixpanelEvent } } = useMixpanel()
  useEffect(() => {
    if (isBrandUser) dispatchMixpanelEvent('Target Audiences Load')
  }, [])

  return (
    <>
      <PageHeader
        actionContent={
          hasTargetAudiences && (
            <Button buttonStyle="primaryCreate" onClick={() => createOrEditTargetAudience()} size="medium">
              Create
            </Button>
          )
        }
        activeTabBarItemKey="target-audiences"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        tabBarItems={[
          {
            href: '#/systemManager/target-audiences',
            icon: faUserTag,
            key: 'target-audiences',
            title: 'Target Audiences',
          },
        ]}
        title="Target Audiences"
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && !hasTargetAudiences && <TargetAudiencesBlankState />}

            {!loading && hasTargetAudiences && (
              <DashboardModule title="Target Audiences">
                <Box flexDirection="column">
                  {filteredTargetAudiences.map(targetAudience => (
                    <TargetAudienceListItem key={targetAudience.id} targetAudience={targetAudience} />
                  ))}
                </Box>
              </DashboardModule>
            )}
          </Columns.Main>

          <Columns.Sidebar>
            <PageFilterModule
              callbacks={{ resetFilters, updateFilters }}
              filterFields={buildPageFilterFields(isAdmin)}
              pageFilters={pageFilters}
              title="Filters"
            />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

export default PageContent
