import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faToggleOn, faToggleOff } from '@fortawesome/pro-light-svg-icons'

import PageContext from '@contexts/pageContext'

import useDeviceStyle from '@hooks/useDeviceStyle'

import { shouldRenderPageItem } from '@functions/digitalTemplatePageItem'

import AnimationWrapper from '@components/digitalRenderer/components/AnimationWrapper'

import styles from './styles.module.scss'

const renderToggle = (editing, enabled, toggleSection) => {
  if (editing){
    return (
      <a className={styles.toggle} onClick={toggleSection} tabIndex="0">
        <FontAwesomeIcon icon={enabled ? faToggleOn : faToggleOff} className={styles.icon} />
      </a>
    )
  }

  return null
}

const Container = (props) => {
  const {
    callbacks: {
      updateCustomData,
    },
    children,
    context: componentContext,
    id,
    options,
  } = props

  const {
    flexDirection, flexWrap, forceFlexDirection, renderConditions, toggleable,
  } = options

  const style = useDeviceStyle(props)

  const pageContext = useContext(PageContext)
  const { customData, editing, shortcodeData } = pageContext || {}

  const contextData = { ...shortcodeData, ...componentContext }

  // Hidden
  const itemData = customData[id] || {}
  const disabled = itemData.disabled || false

  // Should Render
  const shouldRender = shouldRenderPageItem(renderConditions, contextData, options)
  if (!shouldRender || (!editing && disabled)) return null

  const toggleContainerFn = () => {
    if (updateCustomData){
      updateCustomData(id, {
        disabled: !disabled,
      })
    }
  }

  return (
    <AnimationWrapper options={options}>
      <div
        className={classnames(
          styles.root,
          flexDirection ? styles[flexDirection] : null,
          flexWrap ? styles[flexWrap] : null,
          forceFlexDirection ? styles.forceFlexDirection : null,
          disabled ? styles.disabled : null,
        )}
        style={style}
      >
        {children}
        {toggleable && renderToggle(editing, !disabled, toggleContainerFn)}
      </div>
    </AnimationWrapper>
  )
}

Container.propTypes = {
  callbacks: PropTypes.shape({
    updateCustomData: PropTypes.func,
  }),
  children: PropTypes.node,
  context: PropTypes.object,
  id: PropTypes.string.isRequired,
  options: PropTypes.object,
}

Container.defaultProps = {
  options: {},
}

export default Container
