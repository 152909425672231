export default {
  default: {
    buttons: {
      save: 'Save',
    },
    formFieldLabels: {
      uploadFiles: 'Upload Files',
      files: 'Files',
      fileSource: 'File Source',
      selectedFiles: 'Selected Files',
    },
    noticeBox: {
      title: 'Upload Files',
      paragraph: 'Files uploaded here will be stored on your project for use across digital pages.',
    },
    sideBarModalHeader: {
      title: 'Manage',
      titleSecondLine: 'Files',
    },
    selectBoxOptions: {
      fileLibrary: 'File Library',
      project: 'Project',
    },
  },
}
