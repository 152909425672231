import React from 'react'

import { BlankState } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import digitalPageImageUrl from './assets/no-digitalPages.svg'

import localizedStrings from '../../localizedStrings'

const DigitalPageListBlankState = () => {
  const { strings } = useLocalization(localizedStrings)

  return (
    <BlankState imageUrl={digitalPageImageUrl} sidebar>
      <BlankState.Title>{strings.blankStateTitle || 'No Digital Pages'}</BlankState.Title>
      <BlankState.Paragraph>
        {strings.blankStateParagraph || 'Try adjusting your filters to return more results.'}
      </BlankState.Paragraph>
    </BlankState>
  )
}
export default DigitalPageListBlankState
