import React from 'react'
import PropTypes from 'prop-types'

import { FormField } from '@campaignhub/suit-theme'

const FieldHolder = (props) => {
  const {
    boxProps, children, halfWidth, last, ...restProps
  } = props

  const width = halfWidth ? 'calc(50% - 4px)' : '100%'
  const marginRight = last ? 0 : 'medium'

  return (
    <FormField boxProps={{ marginRight, width, ...boxProps }} {...restProps}>
      {children}
    </FormField>
  )
}

FieldHolder.propTypes = {
  boxProps: PropTypes.object,
  children: PropTypes.node,
  halfWidth: PropTypes.bool,
  label: PropTypes.string,
  last: PropTypes.bool,
}

export default FieldHolder
