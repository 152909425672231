import React from 'react'
import PropTypes from 'prop-types'

import { Box, Text } from '@campaignhub/suit-theme'

const SaveFormModule = (props) => {
  const { boxProps, children, childrenLeft } = props

  return (
    <Box
      alignItems="center"
      justifyContent="space-between"
      marginBottom="large"
      padding="large"
      variant="white"
      {...boxProps}
    >
      <Text color="bodyFontLightColor" display={['none', 'block']} fontSize="small">
        {childrenLeft}
      </Text>

      <Box width={['100%', 'auto']}>
        {children}
      </Box>
    </Box>
  )
}

SaveFormModule.propTypes = {
  boxProps: PropTypes.object,
  children: PropTypes.node,
  childrenLeft: PropTypes.node,
}

export default SaveFormModule
