import { Box } from '@campaignhub/suit-theme'

import ComparableListItem from '../ComparableListItem'

interface ComparablesListProps {
  callbacks: { toggleSelectedComparable: (comparableId: number) => void },
  comparableIds: number[],
  selectedComparableIds: number[],
}
const ComparablesList = (props: ComparablesListProps) => {
  const {
    callbacks: { toggleSelectedComparable },
    comparableIds,
    selectedComparableIds,
  } = props

  return (
    <Box flexDirection="column">
      {comparableIds.map((id, index) => {
        const selected = selectedComparableIds.includes(id)

        return (
          <ComparableListItem
            callbacks={{
              toggleSelectedComparable: () => toggleSelectedComparable(id),
            }}
            selected={selected}
            comparableId={id}
          />
        )
      })}
    </Box>
  )
}

export default ComparablesList
