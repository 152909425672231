import React, { useContext } from 'react'
import { toast } from 'react-toastify'
import swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faGlobe, faTrash } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Columns, DashboardModule, LoadingModule, PageHeader, Tools,
} from '@campaignhub/suit-theme'

import CustomFieldsContext from '@contexts/customFieldsContext'

import useCustomFieldSet, { useCustomFieldSetForm } from '@hooks/useCustomFieldSet'
import useNumericParams from '@hooks/useNumericParams'
import useReduxAction from '@hooks/useReduxAction'

import defaultRequestOptions from '@sections/Admin/packs/SystemManager/defaultRequestOptions'

import CustomField from '../CustomField'
import CustomFieldBlankState from '../CustomFieldBlankState'
import CustomFieldSetDetails from '../CustomFieldSetDetails'

const confirmDelete = (deleteFn) => {
  swal
    .fire({
      title: 'Delete Custom Field?',
      text: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it.',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        deleteFn().then(({ success, errors }) => {
          if (!success && errors){
            toast.warning(errors[0])
            return
          }

          toast('Custom Field Set Deleted')

          window.location.href = '#/systemManager/customFieldSets'
        })
      }
    })
}

const updateCustomFieldSet = (entityParams, updateFn) => {
  updateFn(entityParams).then(({ success, errors }) => {
    if (!success && errors){
      toast.warn(errors[0])
      return
    }

    toast('Custom Field Set has been updated!')
  })
}

const PageContent = () => {
  const { customFieldSetId } = useNumericParams()

  useReduxAction(
    'customFieldSets',
    'loadCustomFieldSet',
    { ...defaultRequestOptions.customFieldSet },
    [customFieldSetId],
    {
      dispatchAction: action => action(customFieldSetId, defaultRequestOptions.customFieldSet),
      shouldPerformFn: (entityReducer) => {
        const { loadedIds, loading } = entityReducer
        return customFieldSetId && !loadedIds.includes(customFieldSetId) && !loading
      },
    },
  )

  const {
    callbacks: { deleteCustomFieldSet: deleteFn, duplicateCustomFieldSet, updateCustomFieldSet: updateFn },
    customFieldSetCanBeDeleted,
    customFieldSet,
    customFieldSet: { title },
    filteredCustomFields,
    hasCustomFields,
    loading,
    urls: { customFieldSetsIndexUrl, editCustomFieldSetUrl },
  } = useCustomFieldSet({ id: customFieldSetId })

  const customFieldSetForm = useCustomFieldSetForm(customFieldSet)
  const { entityState, saveEnabled, updating } = customFieldSetForm

  const {
    callbacks: { addCustomField },
  } = useContext(CustomFieldsContext)

  return (
    <>
      <PageHeader
        actionContent={(
          <Button
            buttonStyle="secondaryUtility"
            onClick={() => {
              window.location.href = customFieldSetsIndexUrl
            }}
            size="medium"
          >
            <Box display={['none', 'flex']}>Go Back</Box>
          </Button>
        )}
        activeTabBarItemKey="custom-fields"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        tabBarItems={[
          {
            href: editCustomFieldSetUrl,
            icon: faGlobe,
            key: 'custom-fields',
            title: 'Custom Fields',
          },
        ]}
        title={title || 'Custom Fields'}
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && (
              <>
                <CustomFieldSetDetails customFieldSetForm={customFieldSetForm} />

                <DashboardModule title="Fields">
                  <Box flexDirection="column">
                    {!hasCustomFields && <CustomFieldBlankState />}

                    {hasCustomFields && (
                      <Box flexDirection="column">
                        {filteredCustomFields.map(customField => (
                          <CustomField
                            callbacks={{
                              createOrEditCustomField: () => addCustomField({
                                customFieldId: customField.id,
                                customFieldSetEntityType: customFieldSet.entity_type,
                                customFieldSetId: customFieldSet.id,
                                tag: 'Custom Fields',
                              }),
                            }}
                            key={customField.id}
                            customField={customField}
                          />
                        ))}
                      </Box>
                    )}

                    <Box
                      borderTop="1px solid"
                      borderColor="lineColor"
                      padding="large"
                      style={{ alignItems: 'center', marginBottom: 'large' }}
                    >
                      <Box marginLeft="auto" width={['100%', 175]}>
                        <Button
                          buttonStyle="secondaryEdit"
                          onClick={() => addCustomField({
                            customFieldSetEntityType: customFieldSet.entity_type,
                            customFieldSetId,
                            tag: 'Custom Fields',
                          })}
                          size="medium"
                          style={{ width: '100%' }}
                        >
                          Add Custom Field
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </DashboardModule>

                <Box
                  variant="white"
                  padding="large"
                  style={{ alignItems: 'center', justifyContent: 'flex-end', marginBottom: 'large' }}
                >
                  <Button
                    buttonStyle="primaryCreate"
                    disabled={!saveEnabled}
                    loading={updating}
                    onClick={() => updateCustomFieldSet(entityState, updateFn)}
                    size="medium"
                    style={{ width: 'auto' }}
                  >
                    Save Details
                  </Button>
                </Box>
              </>
            )}
          </Columns.Main>

          <Columns.Sidebar>
            <Tools>
              <Button
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faCopy} />}
                onClick={() => duplicateCustomFieldSet()}
                size="medium"
              >
                Duplicate
              </Button>
              {customFieldSetCanBeDeleted && (
                <Button
                  boxProps={{ marginTop: 'medium' }}
                  buttonStyle="secondaryUtility"
                  icon={<FontAwesomeIcon icon={faTrash} />}
                  marginTop="medium"
                  onClick={() => confirmDelete(deleteFn)}
                  size="medium"
                >
                  Delete
                </Button>
              ) }

            </Tools>
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

export default PageContent
