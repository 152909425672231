import saferEval from 'safer-eval'

export function stripShortcode(string: string) {
  if (!string) return ''
  return string.replace(/[^\w.]/g, '')
}

type OptionsType = {
  returnRawValue?: boolean,
}

export function getShortCodeValue(string: string, data: object, options: OptionsType = {}) {
  if (!string) return ''

  const { returnRawValue } = options || {}
  const stripped = stripShortcode(string)
  if (stripped){
    const split = stripped.split('.')

    const value = split.reduce((obj, key) => {
      if (obj[key] === undefined) return {}
      if (obj[key] === null) return {}
      return obj[key]
    }, data)

    if (returnRawValue) return value
    if (typeof value === 'boolean') return value
    if (typeof value === 'object') return ''
    if (!value) return ''

    return typeof value === 'string' ? value : `${value}`
  }

  return ''
}

export function evaluateFunction(string: string) {
  const match = string.match(/`(.+)`/)
  if (match && match[1]){
    const result = saferEval(match[1])
    return result
  }

  return string
}

function replaceTextShortCodes(string: string, data: object, options = {}) {
  if (!string) return ''

  const regex = /({{([\w.])+}})/g

  const matches = string.match(regex)
  if (matches){
    let parsedString = string.slice()

    matches.forEach((match) => {
      const value = getShortCodeValue(match, data, options)
      parsedString = parsedString.replace(match, value)
    })

    return evaluateFunction(parsedString)
  }

  return evaluateFunction(string)
}

export function evaluateCondition(stringValue: string, operator: string, conditionValue: string) {
  if (operator === 'present') return !!stringValue
  if (operator === 'empty') return !stringValue
  if (operator === 'eq') return stringValue === conditionValue
  if (operator === 'unequal') return stringValue !== conditionValue
  if (operator === 'greater') return Number(stringValue) > Number(conditionValue)
  if (operator === 'less') return Number(stringValue) < Number(conditionValue)
  if (operator === 'includes') return stringValue.includes(conditionValue)
  if (operator === 'excludes') return !stringValue.includes(conditionValue)
  return false
}

type ConditionGroupType = {
  id: string,
  operator: string,
  string: string,
  value: string,
}

export function validateRenderConditionsGroup(conditionGroup: ConditionGroupType[], data: object) {
  if (conditionGroup && conditionGroup.length){
    let validConditionCount = 0

    conditionGroup.forEach((condition) => {
      const useRawValue = ['includes', 'excludes'].includes(condition.operator)
      const stringValue = replaceTextShortCodes(condition.string, data, { returnRawValue: useRawValue })
      const conditionValue = replaceTextShortCodes(condition.value, data, { returnRawValue: useRawValue })

      if (evaluateCondition(stringValue, condition.operator, conditionValue)){
        validConditionCount += 1
      }
    })

    return validConditionCount === conditionGroup.length
  }

  return true
}

export function validateRenderConditions(conditionGroups: ConditionGroupType[], data: object) {
  if (conditionGroups && conditionGroups.length){
    const isFirstConditionAnArray = Array.isArray(conditionGroups[0])

    if (isFirstConditionAnArray){
      // Filter out any empty groups as these will return true
      const filterdConditionGroups = conditionGroups.filter(group => !!group.length)

      if (!filterdConditionGroups.length) return true

      const valid = filterdConditionGroups.some(conditionGroup => validateRenderConditionsGroup(conditionGroup, data))

      return valid
    }

    return validateRenderConditionsGroup(conditionGroups, data)
  }

  return true
}

export default replaceTextShortCodes
