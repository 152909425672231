import { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { faUser, faBuilding } from '@fortawesome/pro-light-svg-icons'

import {
  InputSearch,
  FormField,
  LoadingBubbles,
  ModalContext,
  SegmentedControl,
  SidebarModal,
} from '@campaignhub/suit-theme'
import { digObject } from '@campaignhub/javascript-utils'
import { useDebounce, useSetState } from '@campaignhub/react-hooks'

import useDigitalPage from '@hooks/useDigitalPage'
import useDigitalTemplates from '@hooks/useDigitalTemplates'
import useLocalization from '@hooks/useLocalization'
import useOrganizationSelector from '@hooks/useOrganizationSelector'
import useOrganizationsUser from '@hooks/useOrganizationsUser'
import useReduxAction from '@hooks/useReduxAction'

import DigitalTemplateTile from '@components/DigitalTemplateTile'

import defaultRequestOptions from '@sections/Client/defaultRequestOptions'

import TemplateBlankState from './components/TemplateBlankState'
import localizedStrings from './localizedStrings'

const getFilteredTemplates = (params) => {
  const {
    currentUserTemplates,
    filterKey,
    filteredDigitalTemplates,
    isCurrentUserAdminForOrganization,
    nonUserTemplates,
  } = params

  if (filterKey === 'organization' || (filterKey === 'all' && !isCurrentUserAdminForOrganization)){
    return nonUserTemplates
  }

  if (filterKey === 'user') return currentUserTemplates

  return filteredDigitalTemplates
}

const defaultState = {
  filterString: '',
  filterKey: 'all',
}

const SelectDigitalTemplateModal = (props) => {
  const [state, setState] = useSetState(defaultState)
  const { filterString, filterKey } = state

  const {
    callbacks, disableAnimation, disableOverlay, modalKey, showModal,
  } = props
  const { selectDigitalTemplateId } = callbacks

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const { digitalPage, digitalTemplateTypeKey } = digObject(modalData, `${modalKey}`, {})

  const { selectedOrganization } = useOrganizationSelector()
  const { isCurrentUserAdminForOrganization } = useOrganizationsUser()

  const debouncedString = useDebounce(filterString, 300)

  const entityKey = `digitalTemplateTypeKey${digitalTemplateTypeKey}${debouncedString}`

  useReduxAction(
    'digitalTemplates',
    'loadDigitalTemplates',
    {
      ...defaultRequestOptions.digitalTemplate,
      ...defaultRequestOptions.image,
      digital_template_type_key: digitalTemplateTypeKey,
      entityKey,
      string: debouncedString,
    },
    [digitalTemplateTypeKey, debouncedString],
    {
      shouldPerformFn: ({ loadedForKeys }) => digitalTemplateTypeKey && !loadedForKeys.includes(entityKey),
    },
  )

  const entities = useSelector(reduxState => reduxState.entities)
  const { digitalTemplateTypes, images } = entities

  const digitalTemplateType = Object.values(digitalTemplateTypes)
    .find(templateType => templateType.key === digitalTemplateTypeKey) || {}

  const digitalTemplatesPayload = useDigitalTemplates({
    filters: {
      digital_template_type_key: digitalTemplateTypeKey,
      organization_id: selectedOrganization.id,
      production_ready: true,
      string: debouncedString,
    },
    status: 'current',
  })

  const {
    currentUserTemplates, filteredDigitalTemplates, hasDigitalTemplates, loading, nonUserTemplates,
  } = digitalTemplatesPayload

  const {
    callbacks: { selectDigitalTemplateId: selectFn },
  } = useDigitalPage(digitalPage)

  const filteredTemplates = getFilteredTemplates({
    currentUserTemplates,
    filterKey,
    filteredDigitalTemplates,
    isCurrentUserAdminForOrganization,
    nonUserTemplates,
  })

  useEffect(() => {
    if (!!currentUserTemplates.length && !isCurrentUserAdminForOrganization){
      setState({ filterKey: 'user' })
    }
  }, [currentUserTemplates.length, isCurrentUserAdminForOrganization])

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal
      callbacks={callbacks}
      disableAnimation={disableAnimation}
      disableOverlay={disableOverlay}
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.header?.title || 'Select'}
        titleSecondLine={strings.header?.titleSecondLine || 'Template'}
      />

      <SidebarModal.Content hasFooter={false}>
        {!!currentUserTemplates.length && !isCurrentUserAdminForOrganization && (
          <SegmentedControl
            activeButtonKey={filterKey}
            boxProps={{ marginBottom: 'large' }}
            buttons={[
              { key: 'user', icon: faUser, label: 'My Templates' },
              { key: 'organization', icon: faBuilding, label: 'Agency Templates' },
            ]}
            onClick={key => setState({ filterKey: key })}
          />
        )}

        <FormField
          boxProps={{ marginBottom: 'large' }}
          direction="column"
          label={strings.formFieldLabels?.filter || 'Filter Templates'}
          labelRight={loading && <LoadingBubbles color="rgba(0, 0, 0, 0.15)" style={{ width: 'auto' }} />}
        >
          <InputSearch
            onChange={e => setState({ filterString: e.target.value, searching: true })}
            value={filterString}
          />
        </FormField>

        {!loading && !hasDigitalTemplates && (
          <TemplateBlankState digitalTemplateType={digitalTemplateType.title || strings.title} />
        )}

        {!loading
          && hasDigitalTemplates
          && filteredTemplates.map((template) => {
            const previewImage = images[template.preview_image] || {}

            return (
              <DigitalTemplateTile
                key={template.id}
                callbacks={{
                  selectTemplate: templateId => selectDigitalTemplateId(templateId, selectFn),
                }}
                digitalTemplate={template}
                previewImage={previewImage}
              />
            )
          })}
      </SidebarModal.Content>
    </SidebarModal>
  )
}

SelectDigitalTemplateModal.propTypes = {
  callbacks: PropTypes.shape({
    closeModal: PropTypes.func.isRequired,
    selectDigitalTemplateId: PropTypes.func.isRequired,
  }).isRequired,
  disableAnimation: PropTypes.bool,
  disableOverlay: PropTypes.bool,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool.isRequired,
}

SelectDigitalTemplateModal.defaultProps = {
  modalKey: 'SelectDigitalTemplateModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <SelectDigitalTemplateModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
