export default {
  default: {
    buttons: {
      close: 'Close',
    },
    sideBarModalHeader: {
      title: 'Agreement',
      titleSecondLine: 'Events',
    },
  },
}
