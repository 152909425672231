import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import { Box, Text } from '@campaignhub/suit-theme'

import useDraggable from '@hooks/useDraggable'

const endDrag = (e) => {
  const { target } = e

  target.parentNode.removeChild(target)
}

const PageItem = (props) => {
  const {
    dataAttributes, fullWidth, icon, itemType, last, subTitle, title,
  } = props

  const itemRef = useRef()

  useDraggable(itemRef, {
    callbacks: {
      moveEnd: e => endDrag(e),
    },
    createElementClone: true,
    documentElementId: 'main-stage',
  })

  const margin = last ? 0 : '8px'
  const boxWidth = fullWidth ? '100%' : 'calc(50% - 4px)'

  return (
    <div
      className="draggable"
      data-item-type={itemType}
      ref={itemRef}
      style={{
        marginRight: margin,
        width: boxWidth,
        zIndex: 999,
      }}
      {...dataAttributes}
    >
      <Box
        alignItems="center"
        flexDirection="row"
        justifyContent="flex-start"
        marginBottom="medium"
        padding="medium"
        variant="white"
      >
        <Box alignItems="flex-start" color="bodyFontLightColor" marginRight="medium" width="auto">
          {icon}
        </Box>

        <Box flexDirection="column">
          <Text fontSize="xsmall" lineHeight={1.3}>{title}</Text>

          {!!subTitle && (<Text color="bodyFontLightColor" fontSize="xsmall" lineHeight={1.3}>{subTitle}</Text>)}
        </Box>

      </Box>
    </div>
  )
}

PageItem.propTypes = {
  dataAttributes: PropTypes.object,
  fullWidth: PropTypes.bool,
  icon: PropTypes.object,
  itemType: PropTypes.string.isRequired,
  last: PropTypes.bool,
  subTitle: PropTypes.string,
  title: PropTypes.string,
}

export default PageItem
