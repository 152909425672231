import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { digObject } from '@campaignhub/javascript-utils'
import {
  Box, FormField, InputSearch, LoadingModule, ModalContext, SectionDivider, SidebarModal,
} from '@campaignhub/suit-theme'

import DigitalTemplateTile from '@components/DigitalTemplateTile'

import useSelectSourceDigitalTemplate from './hooks/useSelectSourceDigitalTemplate'

const SelectDigitalTemplateSourceTemplateModal = (props) => {
  const {
    callbacks, modalKey, selectedDigitalTemplateId, showModal,
  } = props

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const { callbacks: modalCallbacks } = digObject(modalData, modalKey, {})
  const { selectTemplate: selectFn } = modalCallbacks || {}

  const selectSourceDigitalTemplatePayload = useSelectSourceDigitalTemplate({ selectedDigitalTemplateId })
  const {
    callbacks: { setState },
    images,
    filteredDigitalTemplatePartials,
    filteredDigitalTemplates,
    hasDigitalTemplatePartials,
    hasDigitalTemplates,
    loading,
    sourceTemplate,
    sourceTemplateImage,
    state: { filterString },
  } = selectSourceDigitalTemplatePayload

  return (
    <SidebarModal
      callbacks={callbacks}
      disableAnimation
      disableOverlay
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header callbacks={callbacks} title="Template" titleSecondLine="Library" />

      <SidebarModal.Content hasFooter={false}>
        <Box flexDirection="column">
          <FormField direction="column" label="Filter Templates">
            <InputSearch
              boxProps={{ marginBottom: 'large' }}
              onChange={e => setState({ filterString: e.target.value })}
              value={filterString}
            />
          </FormField>

          <LoadingModule loading={loading} times={3} />

          {!loading && !!sourceTemplate.id && !filterString && (
            <FormField direction="column" label="Current Source">
              <DigitalTemplateTile
                digitalTemplate={sourceTemplate}
                hoverEnabled={false}
                previewImage={sourceTemplateImage}
                showAngleRight={false}
              />
            </FormField>
          )}

          {!loading && hasDigitalTemplates && (
            <>
              <SectionDivider boxProps={{ marginBottom: 'large' }}>Templates</SectionDivider>

              {filteredDigitalTemplates.map((digitalTemplate) => {
                const { id, preview_image_id } = digitalTemplate
                const image = images[preview_image_id] || {}

                return (
                  <DigitalTemplateTile
                    digitalTemplate={digitalTemplate}
                    key={id}
                    onClick={() => selectFn(digitalTemplate)}
                    previewImage={image}
                  />
                )
              })}
            </>
          )}

          {!loading && hasDigitalTemplatePartials && (
            <>
              <SectionDivider boxProps={{ marginBottom: 'large' }}>Packs</SectionDivider>

              {filteredDigitalTemplatePartials.map((digitalTemplate) => {
                const { id, preview_image_id } = digitalTemplate
                const image = images[preview_image_id] || {}

                return (
                  <DigitalTemplateTile
                    digitalTemplate={digitalTemplate}
                    key={id}
                    onClick={() => selectFn(digitalTemplate)}
                    previewImage={image}
                  />
                )
              })}
            </>
          )}
        </Box>
      </SidebarModal.Content>
    </SidebarModal>
  )
}

SelectDigitalTemplateSourceTemplateModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  selectedDigitalTemplateId: PropTypes.number,
  showModal: PropTypes.bool,
}

SelectDigitalTemplateSourceTemplateModal.defaultProps = {
  modalKey: 'SelectDigitalTemplateSourceTemplateModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <SelectDigitalTemplateSourceTemplateModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
