export default {
  default: {
    buttons: {
      edit: 'Edit',
    },
    iconWithMenu: {
      pdf: 'Download Agreement',
    },
    formId: 'External ID #{id}',
  },
}
