import { useEffect } from 'react'

import { useSetState, useThunkDispatch } from '@campaignhub/react-hooks'
import { deepSetObject, digObject, snakeToTitleCase } from '@campaignhub/javascript-utils'

import useIntegrations from '@hooks/useIntegrations'
import useMixpanel from '@hooks/useMixpanel'
import useOrganizationSelector from '@hooks/useOrganizationSelector'
import useProject from '@hooks/useProject'
import useSelector from '@hooks/useSelector'
import { useAgreementForm } from '@hooks/useAgreement'

import type { AgreementModel } from '@models/agreement'
import type { AppDispatch } from '@redux/store'
import type { MixPanelType } from '@hooks/useMixpanel'

import * as agreementActions from '@redux/modules/agreement'

type ExternalDocumentTemplate = {
  id?: number,
  title?: string,
}

type ExternalAgreementPayload = {
  agreementParams: AgreementModel,
  externalDocumentTemplate: ExternalDocumentTemplate,
  requestOptions: { [key: string]: any },
  userId: number,
}

type CreateExternalFormParams = {
  dispatch: AppDispatch,
  dispatchMixpanelEvent: MixPanelType,
  externalAgreementPayload: ExternalAgreementPayload,
  selectedIntegrationPlatformKey: string,
}

const createExternalForm = (params: CreateExternalFormParams) => {
  const {
    dispatch,
    dispatchMixpanelEvent,
    externalAgreementPayload: {
      agreementParams, externalDocumentTemplate, requestOptions, userId,
    },
    selectedIntegrationPlatformKey,
  } = params

  const { createExternalForm: createfn } = agreementActions

  const agreementType = digObject(agreementParams, 'data.meta_fields.type')
  const propertyType = digObject(agreementParams, 'data.meta_fields.property_type')

  const options = {
    integration_platform_key: selectedIntegrationPlatformKey,
    organization_id: agreementParams.owner_id,
    external_document_template_id: externalDocumentTemplate?.id,
    user_id: userId,
    ...requestOptions,
  }

  const agreement = {
    ...agreementParams,
    title: externalDocumentTemplate.title || snakeToTitleCase(agreementType) || 'External Agreement',
    data: JSON.stringify(agreementParams.data),
  }

  return dispatch(createfn(agreement, options)).then((response) => {
    const { data, success } = response

    if (success){
      dispatchMixpanelEvent('Agreement Created', {
        integration_platform: selectedIntegrationPlatformKey,
        property_type: propertyType,
        type: agreementType,
      })

      return {
        success,
        data,
      }
    }

    return response
  })
}

type MetaField = {
 [key: string]: string,
}

const setupMetaFieldsState = (data: object, platformMetaFields: MetaField[]) => {
  const metaFields = platformMetaFields.reduce((acc, metaField) => {
    const { key } = metaField
    const agreementMetaFields = digObject(data, 'meta_fields', {})

    acc[key] = agreementMetaFields[key] || ''

    return acc
  }, {})

  return metaFields
}

const defaultState = {
  selectedIntegrationPlatformKey: '',
}

function useExternalForm(agreement: AgreementModel) {
  const [state, setState] = useSetState(defaultState)
  const { selectedIntegrationPlatformKey } = state

  const dispatch = useThunkDispatch()

  const agreementFormPayload = useAgreementForm(agreement)
  const { entityState, setEntityState } = agreementFormPayload
  const { data } = entityState

  const project = useProject({ id: agreement.subject_id })
  const { leadUsers } = project

  const { selectedOrganization } = useOrganizationSelector()
  const defaultPlatformKey = digObject(selectedOrganization, 'options.default_platforms.create_forms')

  const { filteredPlatforms } = useIntegrations({
    featureKeys: ['create_forms'],
    filters: {
      owner_id: selectedOrganization.id,
      owner_type: 'Organization',
    },
  })

  // Auto Select Organization default integration platform if set
  useEffect(() => {
    if (defaultPlatformKey){
      setState({ selectedIntegrationPlatformKey: defaultPlatformKey })
      return
    }

    // Auto Select first integration platform if only one available
    if (filteredPlatforms.length === 1){
      const selectedPlatform = filteredPlatforms[0]
      setState({ selectedIntegrationPlatformKey: selectedPlatform.key })
    }
  }, [defaultPlatformKey, filteredPlatforms.length])

  const selectedIntegrationPlatform = filteredPlatforms?.find(
    integrationPlatform => integrationPlatform.key === selectedIntegrationPlatformKey,
  )

  const platformMetaFields = digObject(selectedIntegrationPlatform, 'data.meta_fields')

  useEffect(() => {
    if (platformMetaFields){
      setEntityState({ data: deepSetObject(data, 'meta_fields', setupMetaFieldsState(data, platformMetaFields)) })
    }
  }, [defaultPlatformKey, platformMetaFields])

  const { creating } = useSelector(reduxState => reduxState.agreements)

  // Mixpanel Tracking
  const { callbacks: { dispatchMixpanelEvent } } = useMixpanel()

  return {
    callbacks: {
      createExternalForm: (externalAgreementPayload: ExternalAgreementPayload) => createExternalForm({
        dispatch,
        dispatchMixpanelEvent,
        externalAgreementPayload,
        selectedIntegrationPlatformKey,
      }),
      setEntityState,
      setState,
    },
    creating,
    entityState,
    filteredPlatforms,
    leadUsers,
    platformMetaFields,
    selectedIntegrationPlatform,
    ...state,
  }
}

export default useExternalForm
