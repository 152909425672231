import { useEffect } from 'react'

import { toast } from 'react-toastify'

import { faFileChartLine } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Columns, DashboardModule, FormField, Grid, PageHeader, SelectBox,
} from '@campaignhub/suit-theme'

import EntitySelector from '@components/EntitySelector'

import useBrandSelector from '@hooks/useBrandSelector'
import useCurrentUser from '@hooks/useCurrentUser'
import useDigitalTemplateTypes from '@hooks/useDigitalTemplateTypes'
import useIntegrationPlatforms from '@hooks/useIntegrationPlatforms'
import useIntercom from '@hooks/useIntercom'
import useMixpanel from '@hooks/useMixpanel'
import useStatuses from '@hooks/useStatuses'

import useReporting from '@hooks/useReporting'

const generateReport = (generateFn) => {
  generateFn().then(({ success, errors }) => {
    if (!success){
      toast.warning(errors[0])
      return
    }

    toast('Report has been queued for building.')
  })
}

const Reports = () => {
  const reportingPayload = useReporting()

  const {
    callbacks: { generateReport: generateFn, setState, shouldRenderField },
    loading,
    reportingForm: {
      entityState, errors, handlers, saveEnabled, setEntityState,
    },
    reportGroups,
    reportItems,
    selectedReportGroupKey,
    selectedReportItemKey,
    urls: { reportsIndexUrlAdmin },
  } = reportingPayload

  const filteredReportItems = reportItems.filter(reportItem => reportItem.report_group === selectedReportGroupKey)

  const {
    brand_id,
    end_date,
    hidden,
    integration_platform_key,
    organization_id,
    owner_id,
    owner_type,
    production_ready,
    start_date,
    status_key,
    template_type_id,
    user_id,
  } = entityState || {}

  const templateTypesPayload = useDigitalTemplateTypes()
  const { groupedTemplateTypes } = templateTypesPayload

  const { selectedBrand } = useBrandSelector()

  const integrationsPlatformPayload = useIntegrationPlatforms({ performHttpRequests: true })
  const { filteredIntegrationPlatforms } = integrationsPlatformPayload

  const statusesPayload = useStatuses({ performHttpRequests: true })
  const { statuses } = statusesPayload

  const permittedStatusKeys = ['pending', 'sent', 'accepted', 'rejected']
  const filteredStatuses = Object.values(statuses).filter(s => permittedStatusKeys.includes(s.key))

  const { isBrandUser } = useCurrentUser()

  useEffect(() => {
    if (selectedBrand?.id){
      setEntityState({ brand_id: selectedBrand?.id })
    }
  }, [selectedBrand?.id])

  useIntercom({ hideWidget: !isBrandUser })

  // Tracks the page load event
  const { callbacks: { dispatchMixpanelEvent } } = useMixpanel()
  useEffect(() => {
    if (isBrandUser) dispatchMixpanelEvent('Reports Load')
  }, [])

  return (
    <>
      <PageHeader
        activeTabBarItemKey="reports"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        tabBarItems={[
          {
            href: reportsIndexUrlAdmin,
            icon: faFileChartLine,
            key: 'reports',
            title: 'Reports',
          },
        ]}
        title="Reports"
      />
      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns style={{ marginTop: 16 }}>
          <Columns.Main>
            <DashboardModule title="Reports" boxProps={{ marginBottom: 'large' }}>
              <Box flexDirection="column">
                <Box flexDirection="column" padding="large">
                  <FormField label="Report Group" boxProps={{ marginBottom: selectedReportGroupKey ? 'large' : '' }}>
                    <SelectBox
                      callbacks={{
                        selectItem: item => setState({ selectedReportGroupKey: item.key }),
                      }}
                      items={reportGroups}
                      selectedItemKey={selectedReportGroupKey}
                      style={{ zIndex: 5 }}
                      ulStyle={{ maxHeight: 300, overflow: 'auto' }}
                    />
                  </FormField>

                  {!!selectedReportGroupKey && (
                    <FormField label="Report Type" boxProps={{ marginBottom: selectedReportItemKey ? 'large' : '' }}>
                      <SelectBox
                        callbacks={{
                          selectItem: item => setState({ selectedReportItemKey: item.key }),
                        }}
                        items={filteredReportItems}
                        selectedItemKey={selectedReportItemKey}
                        style={{ zIndex: 3 }}
                        ulStyle={{ maxHeight: 300, overflow: 'auto' }}
                      />
                    </FormField>
                  )}

                  <Grid gridColumnGap="medium" gridRowGap="large" gridTemplateColumns="repeat(2, 1fr)">
                    {shouldRenderField('integration_platform_key') && (
                      <FormField label="Integration Platform">
                        <select
                          name="integration_platform_key"
                          value={integration_platform_key}
                          {...handlers}
                        >
                          <option>Please Select...</option>
                          {filteredIntegrationPlatforms.map(integrationPlatform => (
                            <option key={integrationPlatform.id} value={integrationPlatform.key}>
                              {integrationPlatform.title}
                            </option>
                          ))}
                        </select>
                      </FormField>
                    )}

                    {shouldRenderField('start_date') && (
                      <FormField errorMessage={errors.start_date} label="Start Date">
                        <input name="start_date" type="date" value={start_date} {...handlers} />
                      </FormField>
                    )}

                    {shouldRenderField('end_date') && (
                      <FormField errorMessage={errors.end_date} label="End Date">
                        <input
                          data-validate-field-on="change"
                          min={start_date}
                          name="end_date"
                          type="date"
                          value={end_date || ''}
                          {...handlers}
                        />
                      </FormField>
                    )}

                    {shouldRenderField('brand_id') && !isBrandUser && (
                      <EntitySelector
                        callbacks={{
                          selectItem: brand => setEntityState({ brand_id: brand?.id }),
                        }}
                        entityKey="brands"
                        selectedItemId={brand_id}
                      />
                    )}

                    {shouldRenderField('organization_id') && (
                      <EntitySelector
                        callbacks={{
                          selectItem: organization => setEntityState({ organization_id: organization?.id }),
                        }}
                        entityKey="organizations"
                        selectedItemId={organization_id}
                      />
                    )}

                    {shouldRenderField('owner_type') && !isBrandUser && (
                      <FormField label="Owner Type">
                        <select
                          data-value-type="string"
                          name="owner_type"
                          value={owner_type}
                          data-validate-field-on="change"
                          {...handlers}
                        >
                          <option value="">Please select...</option>
                          <option value="System">System</option>
                          <option value="Organization">Organization</option>
                        </select>
                      </FormField>
                    )}

                    {shouldRenderField('owner_id') && owner_type === 'Organization' && !isBrandUser && (
                      <EntitySelector
                        callbacks={{
                          selectItem: user => setEntityState({ owner_id: user?.id }),
                        }}
                        entityKey="organizations"
                        selectedItemId={owner_id}
                      />
                    )}

                    {shouldRenderField('production_ready') && !isBrandUser && (
                      <FormField label="Production Ready">
                        <select
                          data-value-type="boolean"
                          name="production_ready"
                          value={production_ready}
                          {...handlers}
                        >
                          <option value="">Please select...</option>
                          <option value="false">No</option>
                          <option value="true">Yes</option>
                        </select>
                      </FormField>
                    )}

                    {shouldRenderField('status_key') && (
                      <FormField label="* Status">
                        <select
                          name="status_key"
                          value={status_key}
                          {...handlers}
                        >
                          <option>Please Select...</option>
                          {filteredStatuses.map(status => (
                            <option key={status.id} value={status.key}>
                              {status.title}
                            </option>
                          ))}
                        </select>
                      </FormField>
                    )}

                    {shouldRenderField('hidden') && !isBrandUser && (
                    <FormField
                      boxProps={{ flex: 1, marginBottom: ['large', 0] }}
                      label="Hidden"
                    >
                      <select
                        data-validate-field-on="change"
                        name="hidden"
                        value={hidden}
                        {...handlers}
                      >
                        <option value="">Please Select ...</option>
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                      </select>
                    </FormField>
                    )}

                    {shouldRenderField('template_type_id') && !isBrandUser && (
                      <FormField label="Template Type">
                        <select
                          name="template_type_id"
                          value={template_type_id}
                          {...handlers}
                        >
                          <option>Please Select...</option>
                          {groupedTemplateTypes.map(templateType => (
                            <optgroup key={templateType.id} label={templateType.title}>
                              {templateType.subtypes.map(subtype => (
                                <option key={subtype.id} value={subtype.id}>
                                  {subtype.title}
                                </option>
                              ))}
                            </optgroup>
                          ))}
                        </select>
                      </FormField>
                    )}

                    {shouldRenderField('user_id') && !isBrandUser && (
                      <EntitySelector
                        callbacks={{
                          selectItem: user => setEntityState({ user_id: user?.id }),
                        }}
                        entityKey="users"
                        entityTitleKey="full_name"
                        selectedItemId={user_id}
                      />
                    )}
                  </Grid>
                </Box>

                <Box borderTop="1px solid" borderColor="lineColor" padding="large" justifyContent="flex-end">
                  <Button
                    buttonStyle="primaryCreate"
                    disabled={!selectedReportItemKey || !saveEnabled}
                    loading={loading}
                    onClick={() => generateReport(generateFn)}
                    size="medium"
                    width={150}
                  >
                    Generate Report
                  </Button>
                </Box>
              </Box>
            </DashboardModule>
          </Columns.Main>
        </Columns>
      </Box>
    </>
  )
}
export default Reports
