import { DateTime } from 'luxon'

import { digObject } from '@campaignhub/javascript-utils'

import { useSetState, useThunkDispatch } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'

import * as activityActions from '@redux/modules/activity'

const supportedEntities = {
  CaseStudy: {
    entityKey: 'caseStudies',
    actionName: 'updateCaseStudy',
  },
  DigitalPage: {
    entityKey: 'digitalPages',
    actionName: 'updateDigitalPage',
  },
  Review: {
    entityKey: 'reviews',
    actionName: 'updateReview',
  },
  Team: {
    entityKey: 'teams',
    actionName: 'updateTeam',
  },
}

const currentTime = () => new Date().toISOString()

const recoverEntity = (activity, setState, dispatch) => {
  const { updateActivity } = activityActions

  const updatedData = {
    ...activity.data,
    recovered_at: currentTime(),
    recoverable_until: null,
  }

  setState({ performHttpRequests: true })

  return dispatch(updateActivity({ id: activity.id, data: JSON.stringify(updatedData) }))
}

const defaultState = {
  performHttpRequests: false,
}

function useRecoverableActivitySubject(activity){
  const { subject_id, subject_type } = activity

  const [state, setState] = useSetState(defaultState)
  const { performHttpRequests } = state

  const dispatch = useThunkDispatch()

  const supportedEntity = supportedEntities[subject_type] || {}
  const { actionName, entityKey } = supportedEntity

  // Update Entity
  useReduxAction(entityKey, actionName, {}, [entityKey, performHttpRequests], {
    dispatchAction: (action, requestOptions) => action({ id: subject_id, hidden: false }, requestOptions),
    shouldPerformFn: ({ loading }) => entityKey && !loading && performHttpRequests,
  })

  const recoverableUntil = digObject(activity, 'data.recoverable_until')
  const recoveredAt = digObject(activity, 'data.recovered_at')

  const recoverableUntilDate = recoverableUntil ? DateTime.fromISO(recoverableUntil).toFormat('yyyy-MM-dd') : null
  const currentDate = DateTime.fromISO(currentTime()).toFormat('yyyy-MM-dd')

  const recoverable = !recoveredAt && currentDate <= recoverableUntilDate

  return {
    callbacks: {
      recoverEntity: () => recoverEntity(activity, setState, dispatch),
    },
    recoverable,
    recoveredAt,
  }
}

export default useRecoverableActivitySubject
