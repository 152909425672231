import { useContext } from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArchive, faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import { useSetState } from '@campaignhub/react-hooks'

import { digObject } from '@campaignhub/javascript-utils'

import {
  Box, Button, FormField, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import useAddress from '@hooks/useAddress'
import useCaseStudy, { useCaseStudyForm } from '@hooks/useCaseStudy'
import useEntityCustomFieldsForm from '@hooks/useEntityCustomFieldsForm'
import useImages from '@hooks/useImages'
import useLocalization from '@hooks/useLocalization'
import useOrganizationSelector from '@hooks/useOrganizationSelector'

import DraggableImages from '@components/ModalComponents/DraggableImages'
import EntityCustomFields from '@components/EntityCustomFields'
import ModalImageUpload from '@components/ModalComponents/ModalImageUpload'
import ModalProject from '@components/ModalComponents/ModalProject'
import Search from '@components/Search'

import defaultRequestOptions from '@sections/Client/packs/Admin/defaultRequestOptions'

import CaseStudyCustomForm from './components/CaseStudyCustomForm'

import localizedStrings from './localizedStrings'

const confirmDelete = (params) => {
  const {
    closeModal, deleteCaseStudy, deleteFn, strings,
  } = params

  const deleteCaseStudyPayload = {
    callbacks: {
      action: deleteFn,
      afterAction: closeModal,
    },
    requestOptions: defaultRequestOptions.caseStudy,
    toastText: strings.toast?.deleteCaseStudyPayload || 'Case Study Deleted Successfully',
  }

  swal
    .fire({
      title: strings.sweetAlert?.delete?.title || 'Delete Case Study',
      text: strings.sweetAlert?.delete?.text || 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: strings.sweetAlert?.delete?.confirmButton || 'Yes, delete it.',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        deleteCaseStudy(deleteCaseStudyPayload)
      }
    })
}

const confirmUnarchiveCaseStudy = (params) => {
  const {
    callbacks: { updateCaseStudy }, closeModal, entityState, strings, updateFn, customFields,
  } = params

  const caseStudyPayload = {
    callbacks: {
      action: updateFn,
      afterAction: closeModal(),
    },
    entityParams: { caseStudyParams: { ...entityState, hidden: false }, customFields },
    requestOptions: defaultRequestOptions.caseStudy,
    toastText: strings.toast?.toggleCaseStudyStatus?.unarchive || 'Case Study Unarchived Successfully',
  }

  swal
    .fire({
      title: strings.sweetAlert?.toggleCaseStudyStatus?.unarchiveTitle || 'Unarchive Case Study?',
      text: strings.sweetAlert?.toggleCaseStudyStatus?.text || 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: strings.sweetAlert?.toggleCaseStudyStatus?.unarchiveButton || 'Yes, unarchive it.',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        updateCaseStudy(caseStudyPayload)
      }
    })
}

const confirmDeleteFromProjects = (params) => {
  const {
    closeModal, deleteFromProjectsFn, deleteFromProjects, strings,
  } = params

  const deleteCaseStudyFromProjectPayload = {
    callbacks: {
      action: deleteFromProjectsFn,
      afterAction: closeModal,
    },
    toastText: strings.toast?.deletedFromProjects || 'Case Study Removed from Projects',
  }

  swal
    .fire({
      title: strings.sweetAlert?.deleteFromProjects?.title || 'Remove from Projects?',
      text: strings.sweetAlert?.deleteFromProjects?.text || 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: strings.sweetAlert?.deleteFromProjects?.confirmButton || 'Yes, remove it.',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        deleteFromProjects(deleteCaseStudyFromProjectPayload)
      }
    })
}

const mapProjectResults = ({ entities: projects }, strings) => ({
  groups: [
    {
      key: 'projects',
      title: strings.formFieldLabels?.title || 'PROJECTS',
      items: projects.map(project => ({
        id: project.id,
        key: project.id,
        text: project.title,
      })),
    },
  ],
})

const validateProjectOrUserIdsPresent = (entityState) => {
  const { subject_id, subject_type, user_ids } = entityState
  const hasProject = !!(subject_id && subject_type)
  const hasUserIds = !!user_ids.length

  return hasProject || hasUserIds
}

const createOrUpdateCaseStudy = (params) => {
  const {
    callbacks,
    createFn,
    createOrEditCaseStudy,
    customFields,
    entityState,
    strings,
    toggleProjectCaseStudy,
    updateFn,
  } = params

  const { closeModal, createCaseStudy, updateCaseStudy } = callbacks

  const toastText = entityState.id
    ? strings.toast?.caseStudyUpdated || 'Case Study Updated Successfully'
    : strings.toast?.caseStudyCreated || 'Case Study Created Successfully'

  const actionFn = entityState.id ? updateFn : createFn

  const afterCaseStudyCreateCallback = (data) => {
    // Launch edit modal for new case study
    createOrEditCaseStudy({ caseStudy: data?.entity })

    if (typeof toggleProjectCaseStudy === 'function'){
      toggleProjectCaseStudy(data?.entity?.id)
    }
  }

  const afterActionCallback = data => (entityState.id ? closeModal() : afterCaseStudyCreateCallback(data))

  const caseStudyPayload = {
    callbacks: {
      action: actionFn,
      afterAction: ({ response: { data } }) => afterActionCallback(data),
    },
    entityParams: { caseStudyParams: entityState, customFields },
    requestOptions: defaultRequestOptions.caseStudy,
    toastText,
  }

  return entityState.id ? updateCaseStudy(caseStudyPayload) : createCaseStudy(caseStudyPayload)
}

const selectProject = (selectedProject, setState, setEntityState) => {
  setState({ selectedProject })
  setEntityState({
    address_attributes: {},
    subject_id: selectedProject.id,
    subject_type: 'Project',
  })
}

const defaultState = {
  selectedProject: {},
  selectedUsers: {},
  showAddressForm: false,
}

const CreateOrEditCaseStudyModal = (props) => {
  const {
    callbacks,
    callbacks: {
      closeModal, deleteCaseStudy, deleteFromProjects,
    },
    modalKey,
    showModal,
  } = props

  const [state, setState] = useSetState(defaultState)

  const { selectedProject } = state

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, `${modalKey}`, {})
  const { caseStudy, toggleProjectCaseStudy } = modalPayload

  const {
    address,
    callbacks: {
      createCaseStudy: createFn,
      createOrEditCaseStudy,
      deleteCaseStudy: deleteFn,
      deleteFromProjects: deleteFromProjectsFn,
      updateCaseStudy: updateFn,
    },
    creating,
    defaultImage,
    project: caseStudyProject,
    updating,
  } = useCaseStudy(caseStudy)

  const {
    callbacks: { createOrEditAddress },
  } = useAddress(address)

  const {
    entityState,
    entityState: { description, hidden, subject_id },
    handlers,
    saveEnabled,
    setEntityState,
  } = useCaseStudyForm(caseStudy)

  const {
    callbacks: { updateDefaultImage, modifyImageSort },
    hasImages,
    sortedImages,
  } = useImages({ subjectId: caseStudy.id, subjectType: 'CaseStudy' })

  const { selectedOrganization } = useOrganizationSelector()

  const customFieldsForm = useEntityCustomFieldsForm({ id: entityState.id, type: 'CaseStudy' }, selectedOrganization, {
    performHttpRequests: true,
    requiredOnly: !entityState.id,
  })

  const {
    callbacks: { buildNestedValues },
    saveEnabled: customFieldsSaveEnabled,
  } = customFieldsForm

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        title={
          entityState.id
            ? strings.sideBarModalHeader?.titleUpdate || 'Update'
            : strings.sideBarModalHeader?.titleCreate || 'Create'
        }
        titleSecondLine={strings.sideBarModalHeader?.titleSecondLine || 'Case Study'}
      />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <FormField direction="column" label={strings.formFieldLabels?.findAProject || 'Find a Project'}>
            <Search
              boxProps={{ marginBottom: 'large' }}
              callbacks={{
                selectItem: selected => selectProject(selected, setState, setEntityState),
                mapResults: data => mapProjectResults(data, strings),
              }}
              endpoint="/projects.json"
              placeholder={strings.projectSearchPlaceholder || 'Search for a project'}
              requestOptions={{ include_project_data_store_items: true }}
            />
          </FormField>

          {!!subject_id && (
            <ModalProject
              callbacks={{ removeProject: () => setEntityState({ subject_id: null, subject_type: '' }) }}
              project={selectedProject.id ? selectedProject : caseStudyProject}
            />
          )}

          {!subject_id && (
            <CaseStudyCustomForm
              address={address}
              callbacks={{ createOrEditAddress }}
              entityState={entityState}
              handlers={handlers}
              setEntityState={setEntityState}
            />
          )}

          <SidebarModal.Separator boxProps={{ marginTop: 'large' }} />

          <EntityCustomFields customFieldsForm={customFieldsForm} sidebar />

          {/* Case Study Description */}
          <FormField
            direction="column"
            label={strings.formFieldLabels?.caseStudyDescription || 'Case Study Description'}
            marginBottom="large"
            marginTop="large"
          >
            <textarea name="description" style={{ height: 80 }} type="text" value={description} {...handlers} />
          </FormField>
          {/* End Case Study Description */}

          {/* Image Upload */}
          {entityState.id && (
            <ModalImageUpload
              imageLabel={strings.formFieldLabels?.imageUpload || 'Case Study Image'}
              imageSizes={['thumb_90x90', 'thumb_660x360', 'thumb_200x120']}
              imageType="photo"
              ownerId={caseStudy.owner_id}
              ownerType="Organization"
              subjectId={caseStudy.id}
              subjectType="CaseStudy"
            />
          )}
          {/* End Image Upload */}

          {/* Images Thumbnails  */}
          {hasImages && (
            <DraggableImages
              callbacks={{ updateDefaultImage, modifyImageSort }}
              label={strings.formFieldLabels?.caseStudyImages || 'Case Study Images'}
              sortedImages={sortedImages}
            />
          )}

          {/* More Options */}
          {(!!defaultImage.id || !!caseStudy.id) && (
            <FormField
              direction="column"
              label={strings.formFieldLabels?.moreOptions || 'More Options'}
              marginTop="large"
            >
              <Button
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faTrashAlt} />}
                marginBottom="medium"
                onClick={() => confirmDeleteFromProjects({
                  closeModal,
                  deleteFromProjectsFn,
                  deleteFromProjects,
                  strings,
                })}
                size="medium"
                width="100%"
              >
                {strings.buttons?.removeFromProjects || 'Remove from Projects'}
              </Button>

              {hidden && (
                <Button
                  buttonStyle="secondaryUtility"
                  icon={<FontAwesomeIcon icon={faArchive} />}
                  marginBottom="medium"
                  onClick={() => confirmUnarchiveCaseStudy({
                    callbacks,
                    closeModal,
                    customFields: buildNestedValues(),
                    entityState,
                    strings,
                    updateFn,
                  })}
                  size="medium"
                  width="100%"
                >
                  {strings.buttons?.unarchiveCaseStudy || 'Unarchive Case Study'}
                </Button>
              )}

              {!!caseStudy.id && !!deleteCaseStudy && !hidden && (
                <Button
                  buttonStyle="secondaryUtility"
                  onClick={() => confirmDelete({
                    closeModal,
                    deleteCaseStudy,
                    deleteFn,
                    strings,
                  })}
                  icon={<FontAwesomeIcon icon={faTrashAlt} />}
                  size="medium"
                  width="100%"
                >
                  {strings.buttons?.deleteCaseStudy || 'Delete Case Study'}
                </Button>
              )}
            </FormField>
          )}
          {/* End More Options */}
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled || !validateProjectOrUserIdsPresent(entityState) || !customFieldsSaveEnabled}
          loading={creating || updating}
          loadingBubbleColor="white"
          onClick={() => createOrUpdateCaseStudy({
            entityState,
            customFields: buildNestedValues(),
            callbacks,
            createFn,
            updateFn,
            createOrEditCaseStudy,
            strings,
            toggleProjectCaseStudy,
          })}
          size="large"
        >
          {strings.buttons?.save || 'Save'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

CreateOrEditCaseStudyModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
  useCaseStudyFormPayload: PropTypes.object,
}

CreateOrEditCaseStudyModal.defaultProps = {
  modalKey: 'CreateOrEditCaseStudyModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <CreateOrEditCaseStudyModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
