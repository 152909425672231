import PropTypes from 'prop-types'

import {
  BarChart as Chart, Bar, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis,
} from 'recharts'

import { digObject } from '@campaignhub/javascript-utils'

import CustomLegend from '../Legend'
import CustomTooltip from '../Tooltip'

const BarChart = (props) => {
  const {

    chartOptions: {
      barGap,
      barSize,
      chartHeight,
      disableGrid,
      disableLegend,
      includeXAxis,
      layout,
    },
    dataPayload: { dataArray, dataKeys, theme },
    height,
    width,
  } = props

  return (
    <ResponsiveContainer width={width} height={height}>
      <Chart barGap={barGap} data={dataArray} height={chartHeight} layout={layout}>
        {!disableGrid && (
          <CartesianGrid stroke="rgba(0, 0, 0, 0.1)" horizontal={false} strokeDasharray="3 3" strokeWidth={1} />
        )}

        {dataKeys.map((dataKey) => {
          const border = digObject(theme, `${dataKey}.colors.border`, '#8884d8')
          const primary = digObject(theme, `${dataKey}.colors.primary`, '#8884d8')

          return (
            <Bar
              barSize={barSize}
              type="monotone"
              dataKey={dataKey}
              key={dataKey}
              stroke={border}
              fill={primary}
            />
          )
        })}

        {!includeXAxis
          && (
          <>
            <XAxis hide type={layout === 'vertical' ? 'number' : 'category'} />
            <YAxis hide dataKey="name" type={layout === 'vertical' ? 'category' : 'number'} />
          </>
          )}

        { includeXAxis
        && <XAxis axisLine={false} dataKey="name" fontSize={10} height={10} tickLine={false} /> }

        <Tooltip content={<CustomTooltip dataKeys={dataKeys} />} cursor={{ fill: 'none' }} />

        {!disableLegend && <Legend content={<CustomLegend />} verticalAlign="bottom" />}
      </Chart>
    </ResponsiveContainer>
  )
}

BarChart.propTypes = {
  chartOptions: {
    barGap: PropTypes.number,
    barSize: PropTypes.number,
    chartHeight: PropTypes.number,
    disableGrid: PropTypes.bool,
    disableLegend: PropTypes.bool,
    includeXAxis: PropTypes.bool,
    layout: PropTypes.string,
  },
  dataPayload: PropTypes.shape({
    dataArray: PropTypes.array.isRequired,
    dataKeys: PropTypes.array.isRequired,
    theme: PropTypes.object.isRequired,
  }).isRequired,

  height: PropTypes.number,

  width: PropTypes.string,
}

BarChart.defaultProps = {
  chartOptions: {
    barGap: 10,
  },
  height: 200,
  width: '100%',
}

export default BarChart
