export default {
  default: {
    formFieldLabels: {
      findProject: 'Find a Project',
      organization: '* {global.organizationType.shortName.singular}',
      platform: 'Platform',
      saleMethod: '* Sale or Lease',
    },
    createAddressButton: 'Enter Address Manually',
    importProjectButton: 'Import Project',
    lease: 'Lease',
    placeholderSearch: 'Search by address',
    projectsTitle: 'PROJECTS',
    sale: 'Sale',
  },
}
