import { useContext } from 'react'
import PropTypes from 'prop-types'

import { faCopy } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Columns, DashboardModule, LoadingModule, PageHeader,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import PageFilterModule from '@components/PageFilterModule'
import VideoModule from '@components/VideoModule'

import useLocalization from '@hooks/useLocalization'
import useOrganizationSelector from '@hooks/useOrganizationSelector'
import useQuote from '@hooks/useQuote'
import useQuoteTemplates from '@hooks/useQuoteTemplates'
import useVideoModules from '@hooks/useVideoModules'

import QuoteTemplate from '../QuoteTemplate'
import QuoteTemplatesBlankState from '../QuoteTemplatesBlankState'

import localizedStrings from './localizedStrings'

const buildPageFilterFields = (strings) => {
  const style = {
    marginBottom: 16,
  }

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        style,
      },
      key: 'string',
      label: strings.titleLabel || 'Filter by Title',
    },
  ]
}

const PageContent = (props) => {
  const { pageFilters } = props

  const { selectedOrganization } = useOrganizationSelector()

  const quotePayload = useQuote({ owner_type: 'Organization', owner_id: selectedOrganization.id, saved_template: true })
  const { callbacks: { newQuote } } = quotePayload

  const quoteTemplatesPayload = useQuoteTemplates({
    filters: {
      ownerId: selectedOrganization.id,
      ownerType: 'Organization',
      ...pageFilters,
    },
    performHttpRequests: true,
  })
  const { filteredQuoteTemplates, hasQuoteTemplates, loading } = quoteTemplatesPayload

  const pageContext = useContext(PageContext)
  const {
    callbacks: { resetFilters, updateFilters },
  } = pageContext

  const { strings } = useLocalization(localizedStrings)

  const videoModulePayload = useVideoModules({ key: 'quotes' })
  const { videosPayload } = videoModulePayload

  return (
    <>
      <PageHeader
        actionContent={(
          <Button buttonStyle="primaryCreate" onClick={() => newQuote()} size="medium">
            {strings.createButton || 'Create'}
          </Button>
        )}
        activeTabBarItemKey="list"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        nestedNavigation
        tabBarItems={[
          {
            href: '#/admin/quoteTemplates',
            icon: faCopy,
            key: 'list',
            title: strings.tabBarTitle || 'Quote Templates',
          },
        ]}
        title={strings.title || 'Quote Templates'}
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && !hasQuoteTemplates && <QuoteTemplatesBlankState />}

            {!loading && hasQuoteTemplates && (
              <DashboardModule title={strings.title || 'Quote Templates'}>
                <Box flexDirection="column">
                  {filteredQuoteTemplates.map(quote => (
                    <QuoteTemplate key={quote.id} quote={quote} />
                  ))}
                </Box>
              </DashboardModule>
            )}
          </Columns.Main>

          <Columns.Sidebar>
            <PageFilterModule
              callbacks={{ resetFilters, updateFilters }}
              filterFields={buildPageFilterFields(strings)}
              pageFilters={pageFilters}
              title={strings.filtersTitle || 'Filters'}
            />

            <VideoModule videosPayload={videosPayload} />

          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

PageContent.propTypes = {
  pageFilters: PropTypes.object.isRequired,
}

export default PageContent
