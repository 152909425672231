export default {
  default: {
    title: {
      title: 'Quotes',
    },
    labelTitle: {
      label: 'Title',
    },
    displayMode: {
      label: 'Display Mode',
    },
    displayModeValues: {
      labelListCompact: 'List (Compact)',
      labelListLarge: 'List (Large)',
    },
    limit: {
      label: 'Limit',
    },
    blankState: {
      title: 'No Quotes Created',
      paragraph: "You don't have any quotes available on this project. Create a quote to show your customers the services and products you'll be organising on their behalf and to clearly outline the costs associated with your services.",
    },
    manage: 'Manage',

  },
}
