export default {
  default: {
    buttons: {
      create: 'Create Contact',
      save: 'Save',
    },
    formFieldLabels: {
      moreOptions: 'More Options',
      searchContacts: 'Search Contacts',
    },
    mapResults: {
      title: 'CONTACTS',
    },
    noticeBox: {
      title: 'Search Contacts',
      paragraph:
        'Contacts assigned to your account can be searched above. If you have not created any yet click the button below',
    },
    sideBarModalHeader: {
      title: 'Manage',
      titleSecondLine: 'Contacts',
    },
  },
}
