import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faColumns } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, CustomizableDashboard, Tools,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'
import useVideoModules from '@hooks/useVideoModules'

import VideoModule from '@components/VideoModule'

import styles from './styles.module.scss'

import localizedStrings from './localizedStrings'

const ContentSidebar = React.forwardRef((props, ref) => {
  const {
    callbacks: { customizeDashboard },
    draggablePlaceholder,
    id,
    isDraggingOver,
    modules,
  } = props

  const { strings } = useLocalization(localizedStrings)

  const videoModulePayload = useVideoModules({ key: 'analytics' })
  const { videosPayload } = videoModulePayload

  return (
    <Box
      className={classnames(styles.root, isDraggingOver ? styles.draggingOver : null)}
      flexDirection="column"
      width={['100%', '100%', '375px']}
      paddingTop="large"
    >
      <Tools title={strings.tools || 'Tools'}>
        <Button
          buttonStyle="secondaryUtility"
          icon={<FontAwesomeIcon icon={faColumns} />}
          onClick={() => customizeDashboard()}
          size="medium"
        >
          {strings.button?.customize || 'Customise Dashboard'}
        </Button>
      </Tools>

      <VideoModule videosPayload={videosPayload} />

      <div ref={ref} style={{ flexGrow: 1, minHeight: '100vh' }}>
        {modules && <CustomizableDashboard.ModuleRenderer modules={modules} parentId={id} />}
        {draggablePlaceholder}
      </div>
    </Box>
  )
})

ContentSidebar.propTypes = {
  callbacks: PropTypes.shape({
    customizeDashboard: PropTypes.func.isRequired,
  }).isRequired,
  draggablePlaceholder: PropTypes.object,
  id: PropTypes.string,
  isDraggingOver: PropTypes.bool,
  modules: PropTypes.array,
}

export default ContentSidebar
