import { useSelector } from 'react-redux'

import { digObject } from '@campaignhub/javascript-utils'
import { useSetState } from '@campaignhub/react-hooks'

import useAddress from '@hooks/useAddress'
import useAgreement from '@hooks/useAgreement'
import useDocumentTemplate from '@hooks/useDocumentTemplate'
import useReduxAction from '@hooks/useReduxAction'

import calculateOptimalZoom from '../functions/calculateOptimalZoom'

const availableZooms = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.25, 2.5, 2.75, 3, 4, 5, 6, 10]

export const defaultRequestOptions = {
  agreement: {
    include_agreement_document_template: true,
    include_agreement_recipients: true,
    include_agreement_shortcode_data: true,
    include_agreement_subject: true,
    include_agreement_urls: true,
  },
  comparable: {
    include_comparable_address: true,
    include_comparable_data_store_items: true,
    include_comparable_default_image: true,
  },
  contact: {
    include_contact_address: true,
    include_contact_data_store_items: true,
    include_contact_users_with_read_access: true,
    include_contact_users_with_write_access: true,
  },
  documentTemplate: {
    include_document_template_custom_fields: true,
    include_document_template_page_preview_image_sizes: ['thumb_2000x1500'],
    include_document_template_page_preview_images: true,
    include_document_template_pages: true,
    include_document_template_recipients: true,
  },
  documentTemplatePage: {
    include_document_template_page_items: true,
  },
  project: {
    include_project_address: true,
    include_project_contacts: true,
    include_project_data_store_items: true,
  },
  quote: {
    include_quote_items: true,
    include_quote_sections: true,
  },
}

const modalKeys = {
  project: 'CreateOrEditAddressModal',
  project_comparables: 'ManageComparablesModal',
  quotes: 'ManageEntityQuotesModal',
  contact: 'CreateOrEditContactModal',
  user: 'EditUserModal',
}

const changeZoom = (zoom, setState) => {
  setState({
    zoom,
  })
}

const manageAgreementFeature = (agreement, featureKey, payload, callbacks) => new Promise((resolve, reject) => {
  const {
    createAddress, loadAgreementShortcodeData, updateAddress, updateAgreement,
  } = callbacks

  const modalKey = modalKeys[featureKey] || 'ManageAgreementBuilderModal'
  const showModalFn = callbacks[`show${modalKey}`]

  if (modalKey && showModalFn){
    const modalPayload = {
      agreement,
      callbacks: {
        createAddress,
        loadShortcodeData: loadAgreementShortcodeData,
        updateAddress,
        updateEntity: updateAgreement,
      },
      entity: agreement,
      featureKey,
      ...payload,
    }

    showModalFn(modalPayload)

    return resolve({ success: true, result: payload })
  }

  return reject(new Error(`show${modalKey} not defined in callbacks`))
})

const editAgreement = (agreement, callbacks) => new Promise((resolve, reject) => {
  const { showEditAgreementModal } = callbacks || {}

  if (showEditAgreementModal){
    const payload = {
      agreement,
    }

    showEditAgreementModal(payload)

    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showEditAgreementModal not defined in PageContext callbacks'))
})

const manageAgreementRecipients = (agreement, documentTemplate, callbacks) => {
  const { loadAgreementShortcodeData, showManageDocumentRecipientsModal, updateRecipientSortOrder } = callbacks || {}

  return new Promise((resolve, reject) => {
    if (showManageDocumentRecipientsModal){
      const payload = {
        callbacks: {
          loadShortcodeData: loadAgreementShortcodeData,
          updateRecipientSortOrder,
        },
        documentTemplate,
        owner: agreement,
      }

      showManageDocumentRecipientsModal(payload)

      return resolve({ success: true, result: payload })
    }

    return reject(new Error('showManageDocumentRecipientsModal not defined in callbacks'))
  })
}

const performZoomAction = (actionType, state, setState) => {
  const { zoom } = state

  const index = availableZooms.indexOf(zoom)
  const newIndex = actionType === 'increase' ? index + 1 : index - 1

  const newZoom = availableZooms[newIndex] || zoom

  changeZoom(newZoom, setState)
}

const defaultState = {
  zoom: 1,
}

function useAgreementBuilder(options = {}){
  const { callbacks, agreementId } = options || {}

  const [state, setState] = useSetState(defaultState)
  const { zoom } = state

  // Load Document Template
  useReduxAction(
    'agreements',
    'loadAgreement',
    {
      ...defaultRequestOptions.agreement,
      ...defaultRequestOptions.contact,
      ...defaultRequestOptions.documentTemplate,
      ...defaultRequestOptions.documentTemplatePage,
      ...defaultRequestOptions.project,
    },
    [agreementId],
    {
      dispatchAction: (action, requestOptions) => action(agreementId, requestOptions),
      shouldPerformFn: (entityReducer) => {
        const { loadedIds } = entityReducer
        return agreementId && !loadedIds.includes(agreementId)
      },
    },
  )

  const entities = useSelector(reduxState => reduxState.entities)
  const { documentTemplatePages } = entities

  const agreementPayload = useAgreement({ id: agreementId })
  const {
    agreement,
    agreement: { document_template_id },
    callbacks: { loadAgreementShortcodeData, updateAgreement, updateRecipientSortOrder },
  } = agreementPayload

  const {
    callbacks: { createAddress, updateAddress },
  } = useAddress({})

  const documentTemplatePayload = useDocumentTemplate({ id: document_template_id })
  const { documentTemplate, filteredPages } = documentTemplatePayload

  const firstDocumentTemplatePageId = digObject(filteredPages, '0.id')
  const firstDocumentTemplatePage = documentTemplatePages[firstDocumentTemplatePageId] || {}
  const { height, width } = firstDocumentTemplatePage

  return {
    agreementPayload,
    availableZooms,
    callbacks: {
      loadAgreementShortcodeData,
      editAgreement: () => editAgreement(agreement, callbacks),
      manageAgreementFeature: (featureKey, payload) => manageAgreementFeature(agreement, featureKey, payload, {
        ...callbacks,
        createAddress,
        loadAgreementShortcodeData,
        updateAddress,
        updateAgreement,
      }),
      manageAgreementRecipients: () => manageAgreementRecipients(agreement, documentTemplate, {
        ...callbacks,
        loadAgreementShortcodeData,
        updateRecipientSortOrder,
      }),
      performZoomAction: actionType => performZoomAction(actionType, state, setState),
      selectZoomValue: value => changeZoom(value, setState),
      zoomToBestFit: () => changeZoom(calculateOptimalZoom(height, width), setState),
      ...callbacks,
    },
    documentTemplatePayload,
    hasPages: !!filteredPages.length,
    zoom,
  }
}

export default useAgreementBuilder
