import PropTypes from 'prop-types'

import { Box, SortableList } from '@campaignhub/suit-theme'

import DraggableComparable from '../DraggableComparable'

const DraggableComparables = (props) => {
  const {
    callbacks: { modifyComparablesSort, toggleSelectedComparable },
    comparableIds,
    selectedComparableIds,
    showSelectedProjectComparable,
  } = props

  return (
    <SortableList callbacks={{ onDragEnd: modifyComparablesSort }}>
      <SortableList.Dropzone droppableId="comparableIds">
        <Box flexDirection="column">
          {comparableIds.map((id, index) => {
            const selected = selectedComparableIds.includes(id)

            return (
              <SortableList.Item draggableId={`Comparable-${id}`} key={id} index={index}>
                <DraggableComparable
                  callbacks={{
                    toggleSelectedComparable: () => toggleSelectedComparable(id),
                  }}
                  selected={selected}
                  showSelectedProjectComparable={showSelectedProjectComparable}
                  comparableId={id}
                />
              </SortableList.Item>
            )
          })}
        </Box>
      </SortableList.Dropzone>
    </SortableList>
  )
}

DraggableComparables.propTypes = {
  callbacks: PropTypes.object.isRequired,
  comparableIds: PropTypes.array.isRequired,
  selectedComparableIds: PropTypes.array.isRequired,
  showSelectedProjectComparable: PropTypes.bool.isRequired,
}

export default DraggableComparables
