import React from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'

import { Box, FileUploadZone } from '@campaignhub/suit-theme'

import useUppyImageUpload from '@hooks/useUppyImageUpload'

const ImageUpload = (props) => {
  const {
    imageSizes,
    imageType,
    ownerId,
    ownerType,
    padding,
    subjectId,
    subjectType,
  } = props

  const string = imageSizes.length
    ? queryString.stringify({ include_image_sizes: imageSizes }, { arrayFormat: 'bracket' })
    : ''

  const options = {
    endpoint: `images.json?${string}`,
    image_type: imageType,
    owner_id: ownerId,
    owner_type: ownerType,
    subject_id: subjectId,
    subject_type: subjectType,
  }

  const uppy = useUppyImageUpload(options, [imageType, ownerId, subjectId])

  return (
    <Box padding={padding}>
      <FileUploadZone boxProps={{ width: ['auto', '100%'] }} uppy={uppy} />
    </Box>
  )
}

ImageUpload.propTypes = {
  imageSizes: PropTypes.array,
  imageType: PropTypes.string.isRequired,
  ownerId: PropTypes.number.isRequired,
  ownerType: PropTypes.string.isRequired,
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  subjectId: PropTypes.number.isRequired,
  subjectType: PropTypes.string.isRequired,
}

ImageUpload.defaultProps = {
  imageSizes: [],
  padding: 'large',
}

export default ImageUpload
