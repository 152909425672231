export default {
  default: {
    formFieldLabels: {
      findProject: 'Find a Project',
    },
    mapResults: {
      title: 'PROJECTS',
    },
    projectSearchPlaceholder: 'Search for a project',
    selectedProject: 'Selected Project',
    selectFile: 'Select File',
  },
}
