import PropTypes from 'prop-types'

import {
  Box, Button, FormField, SidebarModal,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'
import useOrganizationSelector from '@hooks/useOrganizationSelector'

import localizedStrings from './localizedStrings'

const CreateNew = (props) => {
  const {
    callbacks: { createEventCalendar, selectScreen },
    eventCalendarFormPayload,
    eventCalendarPayload,
  } = props

  const { creating } = eventCalendarPayload

  const {
    entityState: {
      description,
      event_calendar_template_id,
      id,
      number_of_preparation_weeks,
      number_of_weeks,
      owner_id,
      saved_template,
      start_date,
      title,
    },
    errors,
    handlers,
    saveEnabled,
  } = eventCalendarFormPayload

  // For when we are creating a template from a calendar or vice versa
  const isCreatingFromSource = !!event_calendar_template_id || !!id

  const { sortedOrganizations } = useOrganizationSelector()

  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          {saved_template && (
            <FormField
              boxProps={{ marginBottom: 'large' }}
              direction="column"
              errorMessage={errors.owner}
              label={strings.formFieldLabels?.owner || '* Owner'}
            >
              <select data-validate-field-on="change" name="owner_id" value={owner_id} {...handlers}>
                <option value="">{strings.select || 'Please Select...'}</option>
                {sortedOrganizations.map(organization => (
                  <option key={organization.id} value={organization.id}>
                    {organization.title}
                  </option>
                ))}
              </select>
            </FormField>
          )}

          <FormField direction="column" label={strings?.formFieldLabels?.title || '* Title'}>
            <input name="title" type="text" value={title} {...handlers} />
          </FormField>

          <FormField
            direction="column"
            label={strings?.formFieldLabels?.description || 'Description'}
            marginTop="large"
          >
            <textarea name="description" type="text" value={description} style={{ height: 80 }} {...handlers} />
          </FormField>

          {!saved_template && (
            <FormField
              direction="column"
              label={strings?.formFieldLabels?.startDate || '* Start Date'}
              marginTop="large"
            >
              <input name="start_date" type="date" value={start_date} {...handlers} />
            </FormField>
          )}

          {!isCreatingFromSource && (
            <Box>
              <FormField
                boxProps={{ flexShrink: 1, marginRight: 'small' }}
                data-value-type="number"
                direction="column"
                label={strings.formFieldLabels?.preparationWeeks || 'Preparation Weeks'}
                marginTop="large"
              >
                <input
                  min="0"
                  name="number_of_preparation_weeks"
                  type="number"
                  value={number_of_preparation_weeks}
                  {...handlers}
                />
              </FormField>

              <FormField
                boxProps={{ flexShrink: 1, marginLeft: 'small' }}
                data-value-type="number"
                direction="column"
                label={strings.formFieldLabels?.weeks || 'Number of Weeks'}
                marginTop="large"
              >
                <input min="0" name="number_of_weeks" type="number" value={number_of_weeks} {...handlers} />
              </FormField>
            </Box>
          )}

          {!saved_template && (
            <FormField
              direction="column"
              label={strings.formFieldLabels?.moreOptions || 'More Options'}
              marginTop="large"
            >
              <Button buttonStyle="secondaryUtility" onClick={() => selectScreen('')} size="medium" width="100%">
                {strings.buttons?.goBack || 'Go Back'}
              </Button>
            </FormField>
          )}
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={creating}
          loadingBubbleColor="white"
          onClick={() => createEventCalendar()}
          size="large"
        >
          {saved_template
            ? strings.buttons?.createTemplate || 'Create Template'
            : strings.buttons?.createCalendar || 'Create Calendar'}
        </Button>
      </SidebarModal.Footer>
    </>
  )
}

CreateNew.propTypes = {
  callbacks: PropTypes.shape({
    createEventCalendar: PropTypes.func.isRequired,
    selectScreen: PropTypes.func.isRequired,
  }).isRequired,
  eventCalendarFormPayload: PropTypes.object.isRequired,
  eventCalendarPayload: PropTypes.shape({
    creating: PropTypes.bool.isRequired,
  }).isRequired,
}

export default CreateNew
