import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/pro-light-svg-icons'

import { Box, Link, Text } from '@campaignhub/suit-theme'

import useDocumentRecipient, { roleTitles } from '@hooks/useDocumentRecipient'
import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const DocumentRecipient = (props) => {
  const {
    callbacks: { showCreateOrEditDocumentRecipientModal },
    documentRecipient,
  } = props

  const documentRecipientPayload = useDocumentRecipient(documentRecipient)
  const {
    documentRecipient: { role_key },
    recipientColor,
    userType,
  } = documentRecipientPayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <Box flexDirection="column">
      <Box alignItems="center" fontSize="small" marginBottom="small" padding="medium" variant="white">
        <Box backgroundColor={recipientColor} borderRadius={5} height={20} width={20} />

        <Box flexDirection="column" justifyContent="center" marginLeft="medium" marginRight="auto">
          <Text fontSize="small">{roleTitles[role_key] || strings.title || 'Recipient'}</Text>

          <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="small">
            {userType.title || 'User'}
          </Text>
        </Box>

        <Link
          greyLink
          onClick={() => showCreateOrEditDocumentRecipientModal({ documentRecipient })}
          textProps={{ fontSize: 'small' }}
        >
          <FontAwesomeIcon icon={faPencil} />
        </Link>
      </Box>
    </Box>
  )
}

DocumentRecipient.propTypes = {
  callbacks: PropTypes.shape({
    showCreateOrEditDocumentRecipientModal: PropTypes.func.isRequired,
  }).isRequired,
  documentRecipient: PropTypes.object.isRequired,
}

export default DocumentRecipient
