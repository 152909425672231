import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@campaignhub/suit-theme'

import DigitalTemplateContext from '@contexts/digitalTemplateContext'

import useDigitalTemplate from '@hooks/useDigitalTemplate'
import useDigitalTemplatePage from '@hooks/useDigitalTemplatePage'

import SectionGroup from '@components/digitalRenderer/components/SectionGroup'

const Canvas = (props) => {
  const {
    digitalRendererPayload: {
      callbacks, digitalTemplate, digitalTemplatePage, targetDevice,
    },
  } = props

  const digitalTemplatePayload = useDigitalTemplate(digitalTemplate)
  const { componentStyles } = digitalTemplatePayload

  const digitalTemplatePagePayload = useDigitalTemplatePage(digitalTemplatePage)
  const { sortedSectionGroups } = digitalTemplatePagePayload

  return (
    <DigitalTemplateContext.Provider value={{ componentStyles, targetDevice }}>
      <Box
        alignSelf="center"
        backgroundColor="white"
        flexDirection="column"
        lineHeight="1.5"
        width="100%"
      >
        {sortedSectionGroups.map(sectionGroup => (
          <SectionGroup
            callbacks={callbacks}
            sectionGroup={sectionGroup}
            key={sectionGroup.id}
          />
        ))}
      </Box>
    </DigitalTemplateContext.Provider>
  )
}

Canvas.propTypes = {
  digitalRendererPayload: PropTypes.shape({
    callbacks: PropTypes.object.isRequired,
    digitalTemplate: PropTypes.object.isRequired,
    digitalTemplatePage: PropTypes.object.isRequired,
    targetDevice: PropTypes.string,
  }).isRequired,
}

export default Canvas
