import { useContext } from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserTag } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, FormField, LoadingModule, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useDigitalPage from '@hooks/useDigitalPage'
import useEventCalendar from '@hooks/useEventCalendar'
import useLocalization from '@hooks/useLocalization'
import useManageEventCalendars from './hooks/useManageEventCalendars'

import EventCalendar from './components/EventCalendar'
import EventCalendarsBlankState from './components/EventCalendarsBlankState'

import localizedStrings from './localizedStrings'

const ManageEventCalendarsModal = (props) => {
  const {
    callbacks, disableAnimation, disableOverlay, modalKey, project, showModal, entity: initDigitalPage,
  } = props
  const { updateDigitalPage } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const { digitalPage: modalDigitalPage } = digObject(modalData, `${modalKey}`, {})

  const digitalPage = modalDigitalPage?.id ? modalDigitalPage : initDigitalPage

  const {
    callbacks: { updateDigitalPage: updateDigitalPageFn },
    updating,
  } = useDigitalPage(digitalPage)

  const updateFn = updateDigitalPageFn || updateDigitalPage

  const {
    callbacks: { toggleAllEventCalendars, toggleEventCalendar },
    filteredEventCalendars,
    hasEventCalendars,
    loading,
    selectedCalendarIds,
  } = useManageEventCalendars(digitalPage, {
    performHttpRequests: !!digitalPage.id,
  })

  const initCalendar = {
    owner_id: project.organization_id,
    owner_type: 'Organization',
    subject_id: project.id,
    subject_type: 'Project',
  }

  const {
    callbacks: { createCalendar },
  } = useEventCalendar(initCalendar)

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal
      callbacks={callbacks}
      disableAnimation={disableAnimation}
      disableOverlay={disableOverlay}
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.sideBarModalHeader?.title || 'Event'}
        titleSecondLine={strings.sideBarModalHeader?.titleSecondLine || 'Calendars'}
      />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <LoadingModule loading={loading} times={3} />

          {!loading && !hasEventCalendars && <EventCalendarsBlankState />}

          {!loading && hasEventCalendars && (
            <FormField direction="column" label={strings.formFieldLabels?.targetAudiences || 'Available Calendars'}>
              {filteredEventCalendars.map((eventCalendar) => {
                const selectedIndex = selectedCalendarIds.findIndex(id => id === eventCalendar.id)
                const selected = selectedIndex !== -1

                return (
                  <EventCalendar
                    callbacks={{
                      toggleEventCalendar: () => toggleEventCalendar(eventCalendar.id),
                    }}
                    key={eventCalendar.id}
                    selected={selected}
                    selectedIndex={selectedIndex}
                    eventCalendar={eventCalendar}
                  />
                )
              })}
            </FormField>
          )}
        </Box>

        <FormField
          direction="column"
          boxProps={{ marginTop: 'large' }}
          label={strings.formFieldLabels?.moreOptions || 'More Options'}
        >
          <Box flexDirection="column" flexShrink={0}>
            {hasEventCalendars && (
              <Button
                buttonStyle="secondaryUtility"
                onClick={() => toggleAllEventCalendars(filteredEventCalendars)}
                size="medium"
                style={{ marginBottom: '8px' }}
              >
                {strings.buttons?.toggleAll || 'Toggle All'}
              </Button>
            )}

            <Button
              buttonStyle="secondaryUtility"
              icon={<FontAwesomeIcon icon={faUserTag} />}
              onClick={() => createCalendar()}
              size="medium"
            >
              {strings.buttons?.create || 'Create Event Calendar'}
            </Button>
          </Box>
        </FormField>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          loading={updating}
          loadingBubbleColor="white"
          onClick={() => updateDigitalPage({ id: digitalPage.id, event_calendar_ids: selectedCalendarIds }, updateFn)}
          size="large"
        >
          {strings.buttons?.save || 'Save'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

ManageEventCalendarsModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  disableAnimation: PropTypes.bool,
  disableOverlay: PropTypes.bool,
  entity: PropTypes.object,
  modalKey: PropTypes.string,
  project: PropTypes.object.isRequired,
  showModal: PropTypes.bool,
}

ManageEventCalendarsModal.defaultProps = {
  modalKey: 'ManageEventCalendarsModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <ManageEventCalendarsModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
