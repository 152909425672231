import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities, deleteEntity } from '@redux/entities'

import api from '@functions/api'

import { stringifyOptionValues, handleError } from '../utils'

const FETCH_REQUEST = 'realbase/digitalPage/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/digitalPage/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/digitalPage/FETCH_FAILURE'

const CREATE_REQUEST = 'realbase/digitalPage/CREATE_REQUEST'
const CREATE_SUCCESS = 'realbase/digitalPage/CREATE_SUCCESS'
const CREATE_FAILURE = 'realbase/digitalPage/CREATE_FAILURE'

const UPDATE_REQUEST = 'realbase/digitalPage/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realbase/digitalPage/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realbase/digitalPage/UPDATE_FAILURE'

const DELETE_REQUEST = 'realbase/digitalPage/DELETE_REQUEST'
const DELETE_SUCCESS = 'realbase/digitalPage/DELETE_SUCCESS'
const DELETE_FAILURE = 'realbase/digitalPage/DELETE_FAILURE'

const SHARE_REQUEST = 'realbase/digitalPage/SHARE_REQUEST'
const SHARE_SUCCESS = 'realbase/digitalPage/SHARE_SUCCESS'
const SHARE_FAILURE = 'realbase/digitalPage/SHARE_FAILURE'

// Initial State
const initialState = {
  creating: false,
  digitalTemplateId: null,
  deleting: false,
  errors: [],
  loaded: false,
  loadedForKeys: [],
  loadedIds: [],
  loadedShortcodeDataIds: [],
  loading: false,
  sandboxId: null,
  sharing: false,
  updating: false,
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
    loadedIds: payload.loadedIds,
    loadedShortcodeDataIds: payload.loadedShortcodeDataIds,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    sandboxId: payload.sandboxId,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(){
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest(){
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess(){
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors = []){
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

export function shareRequest(){
  return {
    type: SHARE_REQUEST,
  }
}

export function shareSuccess(){
  return {
    type: SHARE_SUCCESS,
  }
}

export function shareFailure(errors = []){
  return {
    type: SHARE_FAILURE,
    errors,
  }
}

// Action Creators
export function hydrateDigitalPage(digitalPageJson){
  return (dispatch) => {
    if (digitalPageJson.id){
      const normalizedJson = normalize(digitalPageJson, Schemas.DIGITAL_PAGE)
      dispatch(updateEntities(normalizedJson))
      return { success: true, data: digitalPageJson }
    }
    return { success: false, errors: ['Could not hydrate digital page without ID'] }
  }
}

export function hydrateDigitalPageShortcodeData(shortcodeDataJson){
  return (dispatch) => {
    if (shortcodeDataJson.id){
      const normalizedJson = normalize(shortcodeDataJson, Schemas.DIGITAL_PAGE_SHORTCODE_DATA)
      dispatch(updateEntities(normalizedJson))
      return { success: true, data: shortcodeDataJson }
    }
    return { success: false, errors: ['Could not hydrate digital page shortcode data without ID'] }
  }
}

export function fetchDigitalPageDataSets(digitalPageId, options = {}){
  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = api(`/digital_pages/${digitalPageId}/get_digital_page_data_sets.json`, options)
      .then(({ data }) => {
        dispatch(fetchSuccess())
        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))
        return { success: false, errors }
      })

    return promise
  }
}

export function fetchDigitalPageDataSeries(digitalPageId, options = {}){
  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = api(`/digital_pages/${digitalPageId}/get_digital_page_chart_data_filters.json`, options)
      .then(({ data }) => {
        dispatch(fetchSuccess())
        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))
        return { success: false, errors }
      })

    return promise
  }
}

export function loadDigitalPages(options){
  const { entityKey } = options || {}

  return (dispatch, getState) => {
    const loadedForKeys = [...getState().digitalPages.loadedForKeys]
    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('/digital_pages.json', options)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entities, Schemas.DIGITAL_PAGE_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function loadDigitalPage(digitalPageId, options){
  const newOptions = stringifyOptionValues(options)

  const config = {
    method: 'POST',
    body: JSON.stringify({
      ...newOptions,
    }),
  }

  return (dispatch, getState) => {
    const loadedIds = [...getState().digitalPages.loadedIds]
    if (!loadedIds.includes(digitalPageId)){
      loadedIds.push(digitalPageId)
    }

    dispatch(fetchRequest({ loadedIds }))

    const promise = api(`/digital_pages/${digitalPageId}.json`, {}, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.DIGITAL_PAGE)
        dispatch(updateEntities(normalizedJson))

        const sandboxId = digitalPageId === 'sandbox' ? data.entity?.id : null
        dispatch(fetchSuccess({ sandboxId }))

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function loadDigitalPageDigitalTemplate(digitalPageId, options){
  const { entityKey } = options || {}

  return (dispatch, getState) => {
    const loadedForKeys = [...getState().digitalPages.loadedForKeys]
    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api(`/digital_pages/${digitalPageId}/digital_template.json`, options)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.DIGITAL_TEMPLATE)
        dispatch(updateEntities(normalizedJson))

        dispatch(fetchSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function loadDigitalPageShortcodeData(digitalPage, options){
  return (dispatch, getState) => {
    const loadedShortcodeDataIds = [...getState().digitalPages.loadedShortcodeDataIds]
    if (!loadedShortcodeDataIds.includes(digitalPage.id)){
      loadedShortcodeDataIds.push(digitalPage.id)
    }

    dispatch(fetchRequest({ loadedShortcodeDataIds }))

    const promise = api(`/digital_pages/${digitalPage.id}/shortcode_data.json`, options)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.DIGITAL_PAGE_SHORTCODE_DATA)
        dispatch(updateEntities(normalizedJson))

        const sandboxId = digitalPage.id === 'sandbox' ? data.entity?.id : null
        dispatch(fetchSuccess({ sandboxId }))

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function createDigitalPage(digitalPage, options){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      digital_page: digitalPage,
    }),
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api('/digital_pages.json', options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.DIGITAL_PAGE)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function updateDigitalPage(digitalPage, options){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      digital_page: digitalPage,
    }),
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/digital_pages/${digitalPage.id}.json`, options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.DIGITAL_PAGE)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function updateDigitalPageItemImage(digitalPage, templatePageItemId, image, options = {}){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      digital_template_page_item_id: templatePageItemId,
      image_id: image.id,
    }),
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/digital_pages/${digitalPage.id}/update_page_item_image.json`, options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.DIGITAL_PAGE)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function deleteDigitalPage(digitalPage){
  const config = {
    method: 'DELETE',
  }

  return (dispatch) => {
    dispatch(deleteRequest())

    const promise = api(`/digital_pages/${digitalPage.id}.json`, {}, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.DIGITAL_PAGE)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(deleteFailure(errors))

        return { success: false, errors }
      })
    return promise
  }
}

export function acceptDigitalPage(digitalPage, shareLinkToken, options){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      token: shareLinkToken,
    }),
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/digital_pages/${digitalPage.id}/accept.json`, options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.DIGITAL_PAGE)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function buildDigitalPagePdfUrl(digitalPage, options){
  const config = {
    method: 'POST',
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/digital_pages/${digitalPage.id}/build_pdf_url.json`, options, config)
      .then(({ data }) => {
        dispatch(updateSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })
    return promise
  }
}

export function shareDigitalPage(digitalPage, recipients = [], message = ''){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      recipients,
      message_body: message,
    }),
  }

  return (dispatch) => {
    dispatch(shareRequest())

    const promise = api(`digital_pages/${digitalPage.id}/share_via_sms.json`, {}, config)
      .then(({ data }) => {
        dispatch(shareSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(shareFailure(errors))

        return { success: false, errors }
      })
    return promise
  }
}

export function duplicateDigitalPage(selectedEntities, sourceDigitalPageId, targetProjectId){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      selected_entities: selectedEntities,
      digital_page_id: sourceDigitalPageId,
      target_project_id: targetProjectId,
    }),
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api(`digital_pages/${sourceDigitalPageId}/duplicate_digital_page.json`, {}, config)
      .then(({ data }) => {
        dispatch(createSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })
    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loadedIds: action.loadedIds || state.loadedIds,
        loadedShortcodeDataIds: action.loadedShortcodeDataIds || state.loadedShortcodeDataIds,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        sandboxId: action.sandboxId || state.sandboxId,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors,
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors,
      }
    case SHARE_REQUEST:
      return {
        ...state,
        sharing: true,
      }
    case SHARE_SUCCESS:
      return {
        ...state,
        sharing: false,
        errors: [],
      }
    case SHARE_FAILURE:
      return {
        ...state,
        sharing: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
