export function capitalizeString(string: string) {
  if (!string || typeof string !== 'string') return ''
  return string[0].toUpperCase() + string.slice(1)
}

export function stringToTitleCase(string: string) {
  if (!string || typeof string !== 'string') return ''

  const array = string.split(' ')
  const titleCaseArray = array.map(str => capitalizeString(str))

  return titleCaseArray.join(' ')
}

export function hyphenCaseToSnakeCase(string: string) {
  if (!string || typeof string !== 'string') return ''
  return string.replace(/-/g, '_')
}

export function snakeCaseToCamelCase(string: string) {
  if (!string || typeof string !== 'string') return ''
  return string.replace(/(\_\w)/g, m => m[1].toUpperCase())
}

type ListType = Record<string, string>

export const formatListToString = (list: ListType = [], key: string, strings: string[]) => {
  // strings = { one: 'other', many: 'others' }
  const filteredList = list.filter(item => item)
  const [itemOne, itemTwo] = filteredList

  switch (filteredList.length){
    case 0:
      return ''
    case 1:
      return itemOne[key]
    case 2:
      return `${itemOne[key]} & ${itemTwo[key]}`
    case 3:
      return `${itemOne[key]}, ${itemTwo[key]} & 1 ${strings.one}`
    default:
      return `${itemOne[key]}, ${itemTwo[key]} & ${filteredList.length - 2} ${strings.many}`
  }
}
