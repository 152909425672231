import { useEffect, useMemo } from 'react'

import { digObject, sortArrayBy } from '@campaignhub/javascript-utils'
import { useSetState } from '@campaignhub/react-hooks'

import useOrganizationShortcodeList from '@hooks/useOrganizationShortcodeList'
import { recipientRoles } from '@hooks/useDocumentRecipient'

export const entityTypes = [
  {
    contextual: true,
    entityType: 'ProjectComparable',
    indexLimit: 10,
    key: 'project_comparables.all_sold',
    shortcodeListKey: 'project_comparables',
    singular: 'Comparable',
    title: 'Comparables (Sold)',
  },
  {
    contextual: false,
    entityType: 'Organization',
    key: 'organization',
    shortcodeListKey: 'organization',
    title: 'Organization',
  },
  {
    contextual: false,
    entityType: 'Project',
    key: 'project',
    shortcodeListKey: 'project',
    title: 'Project',
  },
  {
    contextual: true,
    entityType: 'Quote',
    indexLimit: 5,
    key: 'quotes',
    shortcodeListKey: 'quotes',
    singular: 'Quote',
    title: 'Quotes',
  },
]

const mapFieldValue = (params) => {
  const {
    customValue, isSelectedEntityContextual, key, selectedEntityIndex, selectedEntityKey, shortcode,
  } = params

  if (customValue){
    return customValue
  }

  if (isSelectedEntityContextual){
    return `{{${selectedEntityKey}.${selectedEntityIndex}.${key}}}`
  }

  return `{{${shortcode.value}}}`
}

const mapFields = (params) => {
  const {
    customValue, fields, isSelectedEntityContextual, selectedEntityIndex, selectedEntityKey, selectedEntityType,
  } = params

  const mapped = fields.map((shortcode) => {
    const {
      children, key, label, value,
    } = shortcode

    const field = {
      ...shortcode,
      label: isSelectedEntityContextual
        ? `${selectedEntityType.singular} ${selectedEntityIndex + 1} - ${label}`
        : label,
      value: mapFieldValue({
        customValue,
        isSelectedEntityContextual,
        key,
        selectedEntityIndex,
        selectedEntityKey,
        shortcode,
      }),
      rawValue: value,
    }

    if (children){
      field.children = mapFields({
        ...params,
        customValue: `{{${shortcode.value}}}`,
        fields: children,
      })
    }

    return field
  })

  return mapped
}

const defaultState = {
  selectedEntityIndex: 0,
  selectedEntityKey: 'project',
  selectedRecipientId: null,
}

function useAddDocumentTemplateField(options = {}){
  const { documentRecipients = [] } = options || {}

  const [state, setState] = useSetState(defaultState)
  const { selectedEntityIndex, selectedEntityKey, selectedRecipientId } = state

  // Autoselect first recipient
  useEffect(() => {
    if (!selectedRecipientId && documentRecipients.length){
      const firstRecipient = documentRecipients[0] || {}

      setState({
        selectedRecipientId: firstRecipient.id,
      })
    }
  }, [documentRecipients.length])

  const selectedEntityType = entityTypes.find(entityType => entityType.key === selectedEntityKey) || {}
  const isSelectedEntityContextual = selectedEntityType.contextual || false

  // Organization Shortcodes
  const organizationShortcodeListPayload = useOrganizationShortcodeList({ performHttpRequests: true })

  // Entity Shortcodes
  const shortcodesForEntityKey = digObject(organizationShortcodeListPayload, `organizationShortcodeList.${selectedEntityType.shortcodeListKey}.shortcodes`, [])

  const mappedEntityShortcodes = useMemo(() => {
    const filtered = shortcodesForEntityKey.filter(shortcode => !shortcode.advanced)

    const mapped = mapFields({
      fields: filtered,
      isSelectedEntityContextual,
      selectedEntityIndex,
      selectedEntityKey,
      selectedEntityType,
    })

    const sorted = sortArrayBy(mapped, 'asc', 'label')
    return sorted
  }, [selectedEntityKey, selectedEntityIndex, shortcodesForEntityKey.length])

  // Reset Selected Index
  useEffect(() => {
    if (selectedEntityIndex !== 0){
      setState({
        selectedEntityIndex: 0,
      })
    }
  }, [selectedEntityKey])

  const selectedRecipient = documentRecipients.find(recipient => recipient.id === selectedRecipientId) || {}
  const selectedRecipientIndex = documentRecipients.findIndex(recipient => recipient.id === selectedRecipientId)

  // Recipient Shortcodes
  const shortcodeKey = digObject(recipientRoles, `${selectedRecipient.role_key}.shortcodeKey`, '')
  const shortcodesForRecipientSubject = digObject(organizationShortcodeListPayload, `organizationShortcodeList.${shortcodeKey}.shortcodes`, [])

  const mappedRecipientShortcodes = useMemo(() => {
    const filtered = shortcodesForRecipientSubject.filter(shortcode => !shortcode.advanced)

    const sorted = sortArrayBy(filtered, 'asc', 'label')
    return sorted
  }, [selectedRecipientId, shortcodesForRecipientSubject.length])

  return {
    callbacks: {
      setState,
    },
    hasEntityShortcodes: !!mappedEntityShortcodes.length,
    hasRecipientShortcodes: !!mappedRecipientShortcodes.length,
    isSelectedEntityContextual,
    selectedEntityIndex,
    selectedEntityKey,
    selectedEntityType,
    selectedRecipient,
    selectedRecipientId,
    selectedRecipientIndex,
    shortcodesForEntityKey: mappedEntityShortcodes,
    shortcodesForRecipient: mappedRecipientShortcodes,
  }
}

export default useAddDocumentTemplateField
