import { useForm } from '@campaignhub/react-hooks'

import { isValidMobile } from '@campaignhub/javascript-utils'

import countryCallingCodes from '@functions/countryCallingCodes'

const defaultState = {
  countryCallingCode: '',
  mobile: '',
}

export const requiredFields = [
  { key: 'countryCallingCode' },
  { key: 'mobile', validation: isValidMobile, invalidMessage: 'Invalid Mobile format' },
]

function useCustomMobile() {
  const entity = {
    countryCallingCode: '',
    mobile: '',
  }

  const formPayload = useForm(defaultState, { entity, requiredFields, validateOn: 'change' }, [])

  return {
    countryCallingCodes,
    ...formPayload,
  }
}

export default useCustomMobile
