import { useMemo } from 'react'

import { matchFilterKey, sortArrayBy } from '@campaignhub/javascript-utils'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { StatusModel } from '@models/types'

type StatusFilters = {
  key?: string,
}

type UseStatusesOptions = {
  filters?: StatusFilters,
  performHttpRequests?: boolean,
}

function useStatuses(options: UseStatusesOptions) {
  const { filters = {}, performHttpRequests } = options
  const {
    key: filterKey,
  } = filters

  useReduxAction('statuses', 'loadStatuses', {}, [performHttpRequests], {
    shouldPerformFn: (entityReducer) => {
      const { errors, loaded, loading } = entityReducer
      return performHttpRequests && !loaded && !loading && !errors.length
    },
  })

  const { statuses } = useSelector(reduxState => reduxState.entities)

  const { loaded, loading } = useSelector(reduxState => reduxState.statuses)

  const filteredStatuses = useMemo(() => {
    const filtered = Object.values(statuses).filter((status: StatusModel) => {
      const { key } = status

      const keyMatch = matchFilterKey(key, filterKey)

      return keyMatch
    })

    return sortArrayBy(filtered, 'desc', 'title')
  }, [filterKey, JSON.stringify(options)])

  return {
    filteredStatuses,
    statuses,
    loaded,
    loading,
  }
}

export default useStatuses
