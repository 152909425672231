import React from 'react'
import PropTypes from 'prop-types'

import { BlankState } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import BlankStateImageUrl from './assets/comparables-blank-state.svg'
import localizedStrings from './localizedStrings'

const ComparablesBlankState = (props) => {
  const { projectType } = props

  const { strings } = useLocalization(localizedStrings, { projectType })

  return (
    <BlankState imageUrl={BlankStateImageUrl}>
      <BlankState.Title>{strings.title || 'No Comparables'}</BlankState.Title>
      <BlankState.Paragraph>
        {strings.paragraph || "This project has no comparable properties selected. You can search your own organization's listings, market sales and listings or create your own custom comparables if they're missing from the data supplier."}
      </BlankState.Paragraph>
    </BlankState>
  )
}

ComparablesBlankState.propTypes = {
  projectType: PropTypes.object.isRequired,
}

export default ComparablesBlankState
