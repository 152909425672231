import {
  useContext, useEffect, useMemo, useState,
} from 'react'

import {
  useForm, useLatestEntity, useSetState, useWatchEntityUpdates,
} from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import {
  digObject, launchModal, modifyGroupedIdsSort, multiFilteredObjectArraySelector, sortArrayBy,
} from '@campaignhub/javascript-utils'

import useDispatch from '@hooks/useDispatch'
import useMixpanel from '@hooks/useMixpanel'
import useSelector from '@hooks/useSelector'
import { findIntegrationPlatformByKey } from '@hooks/useIntegrationPlatforms'

import generateRedirectUrl from '@functions/generateRedirectUrl'

import * as quoteActions from '@redux/modules/quote'
import * as quoteTemplateActions from '@redux/modules/quoteTemplate'

import defaultFormState, { requiredFields } from '@models/quote'

import PageContext from '@contexts/pageContext'

import type { AppDispatch } from '@redux/store'
import type { DeleteParams } from '@redux/modules/types'
import type {
  QuoteItemModel, QuoteModel, QuoteRequestOptions,
} from '@models/types'

const watchEntityKeys = [
  'quoteItems',
  'quoteSections',
  'quotes',
]

export const quoteRequestOptions = {
  include_quote_items: true,
  include_quote_sections: true,
}

export const generateUrls = (quote?: Partial<QuoteModel>) => {
  const { id, subject_id } = quote || {}

  const externalPlatformKey = digObject(quote, 'data.source_platform')
  const externalId = digObject(quote, `data.external_ids.${externalPlatformKey}`)

  return {
    editQuoteTemplateUrl: `#/admin/quoteTemplates/${id}`,
    editQuoteUrl: externalId ? `#/external/quotes/${id}` : `#/projects/${subject_id}/quotes/${id}`,
    quoteListUrl: `#/projects/${subject_id}/quotes`,
    quoteTemplateListUrl: '#/admin/quoteTemplates',
  }
}

type CreateQuoteParams = {
  dispatch: AppDispatch,
  dispatchMixpanelEvent: (eventType: string, customPayload?: {}) => void,
  quoteParams: Partial<QuoteModel>,
  requestOptions?: QuoteRequestOptions,
}

const createQuote = (params: CreateQuoteParams) => {
  const {
    dispatch, dispatchMixpanelEvent, quoteParams, requestOptions,
  } = params
  const { createQuote: createFn } = quoteActions

  return dispatch(createFn(quoteParams, requestOptions)).then((result) => {
    const { data, success } = result

    if (success){
      const { entity, entity: { saved_template } } = data
      const { editQuoteUrl, editQuoteTemplateUrl } = generateUrls(entity)

      const url = saved_template
        ? `${editQuoteTemplateUrl}?redirect=${generateRedirectUrl({
          append: '&showModal=ManageEntityQuotesModal',
        })}`
        : `${editQuoteUrl}?redirect=${generateRedirectUrl({
          append: '&showModal=ManageEntityQuotesModal',
        })}`

      dispatchMixpanelEvent('Quotes Created', { created_from_template: false })

      return {
        ...result,
        redirectUrl: url,
      }
    }

    return result
  })
}

type CreateQuoteFromTemplatePaylod = {
  quote: QuoteModel,
  quoteTemplate: Partial<QuoteModel>,
}

type CreateQuoteFromTemplateParams = {
  dispatch: AppDispatch,
  dispatchMixpanelEvent: (eventType: string, customPayload?: {}) => void,
  payload: CreateQuoteFromTemplatePaylod,
  requestOptions?: QuoteRequestOptions,
}

const createQuoteFromTemplate = (params: CreateQuoteFromTemplateParams) => {
  const {
    dispatch, dispatchMixpanelEvent, payload, requestOptions,
  } = params
  const { quote, quoteTemplate } = payload

  const { restoreAsQuote: createFn } = quoteTemplateActions

  return dispatch(createFn(quote, quoteTemplate, requestOptions)).then((result) => {
    const { data, success } = result

    if (success){
      const { entity, entity: { saved_template } } = data
      const { editQuoteUrl, editQuoteTemplateUrl } = generateUrls(entity)

      const url = saved_template
        ? `${editQuoteTemplateUrl}?redirect=${generateRedirectUrl({
          append: '&showModal=ManageEntityQuotesModal',
        })}`
        : `${editQuoteUrl}?redirect=${generateRedirectUrl({
          append: '&showModal=ManageEntityQuotesModal',
        })}`

      dispatchMixpanelEvent('Quotes Created', { created_from_template: true })

      return {
        ...result,
        redirectUrl: url,
      }
    }

    return result
  })
}

type DeleteQuoteParams = {
  dispatch: AppDispatch,
  quote: DeleteParams<QuoteModel>,
}

const deleteQuote = (params: DeleteQuoteParams) => {
  const { dispatch, quote } = params
  const { deleteQuote: deleteFn } = quoteActions

  return dispatch(deleteFn(quote))
}

type LoadFromExternalPlatformParams = {
  dispatch: AppDispatch,
  integrationPlatformKey: string,
  options?: QuoteRequestOptions,
}

const loadFromExternalPlatform = (params: LoadFromExternalPlatformParams) => {
  const { dispatch, integrationPlatformKey, options } = params
  const { loadFromExternalPlatform: loadFn } = quoteActions

  return dispatch(loadFn(integrationPlatformKey, options))
}

type ImportFromExternalPlatformParams = {
  dispatch: AppDispatch,
  options?: QuoteRequestOptions,
  params: {
    externalPlatformKey: string,
    payload: {},
  },
}

const importFromExternalPlatform = (importParams: ImportFromExternalPlatformParams) => {
  const { dispatch, params, options } = importParams
  const { externalPlatformKey, payload } = params

  const { importFromExternalPlatform: importFn } = quoteActions

  return dispatch(importFn(externalPlatformKey, payload, options))
}

type LoadExternalPlatformManagementUrlParams = {
  dispatch: AppDispatch,
  externalPlatformKey: string,
  options?: QuoteRequestOptions,
  setState: ({ externalManagementUrl }: { externalManagementUrl: string }) => void,
}

const loadExternalPlatformManagementUrl = (params: LoadExternalPlatformManagementUrlParams) => {
  const {
    dispatch, externalPlatformKey, options, setState,
  } = params
  const { loadExternalPlatformManagementUrl: loadFn } = quoteActions

  return dispatch(loadFn(externalPlatformKey, options)).then((result) => {
    const url = digObject(result, 'data.url')
    if (url){
      setState({ externalManagementUrl: url })
    }

    return result
  })
}

type LoadExternalPlatformPdfUrlParams = {
  dispatch: AppDispatch,
  options?: QuoteRequestOptions,
  quote: QuoteModel,
}

const loadExternalPlatformPdfUrl = (params: LoadExternalPlatformPdfUrlParams) => {
  const { dispatch, quote, options } = params
  const { loadExternalPlatformPdfUrl: loadFn } = quoteActions

  return dispatch(loadFn(quote.id, options))
}

type LoadExternalPlatformManagementUrlForQuoteParams = {
  dispatch: AppDispatch,
  options?: QuoteRequestOptions,
  quote: QuoteModel,
  setState: ({ externalManagementUrl }: { externalManagementUrl: string }) => void,
}

const loadExternalPlatformManagementUrlForQuote = (params: LoadExternalPlatformManagementUrlForQuoteParams) => {
  const {
    dispatch, options = {}, quote, setState,
  } = params
  const { loadExternalPlatformManagementUrlForQuoteId: loadFn } = quoteActions

  return dispatch(loadFn(quote.id, options)).then((result) => {
    const url = digObject(result, 'data.url')
    if (url){
      setState({ externalManagementUrl: url })
    }

    return result
  })
}

type DownloadPdfParams = {
  dispatch: AppDispatch,
  isExternal: boolean,
  options?: QuoteRequestOptions,
  quote: Partial<QuoteModel>,
}

const downloadPdf = (params: DownloadPdfParams) => {
  const {
    dispatch, isExternal, options = {}, quote,
  } = params

  if (isExternal){
    return loadExternalPlatformPdfUrl({ dispatch, options, quote }).then((result) => {
      const { success, data } = result
      if (success && data?.url) window.open(data.url, '_blank')

      return result
    })
  }

  return {}
}

type DownloadPdfFromShareLinkParams = {
  dispatch: AppDispatch,
  options?: QuoteRequestOptions,
  quoteId: number,
  shareLinkToken: string,
}

const downloadPdfFromShareLink = (params: DownloadPdfFromShareLinkParams) => {
  const {
    dispatch, options = {}, quoteId, shareLinkToken,
  } = params
  const quote = { id: quoteId }

  const mergedOptions = {
    ...options,
    token: shareLinkToken,
  }

  return downloadPdf({
    dispatch, isExternal: true, options: mergedOptions, quote,
  })
}

type UpdateQuoteParams = {
  dispatch: AppDispatch,
  quote: QuoteModel,
  quoteParams: Partial<QuoteModel>,
  requestOptions?: QuoteRequestOptions,
}

const updateQuote = (params: UpdateQuoteParams) => {
  const {
    quote, quoteParams, dispatch, requestOptions,
  } = params

  const { updateQuote: updateFn } = quoteActions

  const updatedParams = {
    id: quote.id,
    ...quoteParams,
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

type ModifySectionItemSortPayload = {
  destinationDroppableId: number,
  sourceDroppableId: number,
}

type SetSortedFieldIdsFunction = (
  updateFn: (sortedItemIds: Record<string, number[]>)=> Record<string, number[]>) => void;

type ModifySectionItemSortParams = {
  fromIndex: number,
  payload: ModifySectionItemSortPayload,
  setSortedSectionItemIds: SetSortedFieldIdsFunction,
  setState: (state: { manuallySorted: boolean }) => void,
  toIndex: number,
}

const modifySectionItemSort = (params: ModifySectionItemSortParams) => {
  const {
    fromIndex, payload, setSortedSectionItemIds, setState, toIndex,
  } = params
  const { destinationDroppableId, sourceDroppableId } = payload

  setSortedSectionItemIds((sortedSectionItemIds) => {
    const groupedIds = modifyGroupedIdsSort(fromIndex, toIndex, payload, sortedSectionItemIds)

    return {
      ...sortedSectionItemIds,
      [destinationDroppableId]: groupedIds[destinationDroppableId],
      [sourceDroppableId]: groupedIds[sourceDroppableId],
    }
  })

  setState({ manuallySorted: true })
}

const setupSortedSectionItemIds = (
  quoteItems: QuoteItemModel[],
  setSortedSectionItemIds: SetSortedFieldIdsFunction,
) => {
  const grouped = quoteItems.reduce((acc, quoteItem) => {
    const { quote_section_id } = quoteItem

    const key = `Section-${quote_section_id}`
    if (!acc[key]){
      acc[key] = []
    }

    acc[key].push(quoteItem.id)
    return acc
  }, {})

  setSortedSectionItemIds(grouped)
}

type SaveQuoteAsTemplateParams = {
  dispatch: AppDispatch,
  existingQuote: QuoteModel,
  quoteTemplate: Partial<QuoteModel>,
  requestOptions?: QuoteRequestOptions,
}

const saveQuoteAsTemplate = (params: SaveQuoteAsTemplateParams) => {
  const {
    dispatch, existingQuote, quoteTemplate, requestOptions,
  } = params
  const { saveQuoteAsTemplate: saveFn } = quoteActions

  return dispatch(saveFn(quoteTemplate, existingQuote, requestOptions))
}

type UpdateQuoteSectionItemOrderParams = {
  dispatch: AppDispatch,
  quote: QuoteModel,
  sortedSectionItemIds: number[],
}

const updateQuoteSectionItemOrder = (params: UpdateQuoteSectionItemOrderParams) => {
  const { dispatch, quote, sortedSectionItemIds } = params
  const { updateQuoteSectionItemOrder: updateFn } = quoteActions

  return dispatch(updateFn(quote, sortedSectionItemIds, quoteRequestOptions))
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useQuoteForm(
  quote: Partial<QuoteModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const quoteForm = useForm(
    defaultFormState,
    { entity: quote, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [quote.id, quote.cache_key],
  )

  return {
    ...quoteForm,
  }
}

export const useRelations = (quote: Partial<QuoteModel> = {}) => {
  const {
    subject_type, subject_id, owner_type, owner_id,
  } = quote || {}

  const entities = useSelector(reduxState => reduxState.entities)
  const { organizations, projects } = entities

  const organization = owner_type === 'Organization' && owner_id ? organizations[owner_id] || {} : {}
  const project = subject_type === 'Project' && subject_id ? projects[subject_id] || {} : {}

  return {
    organization,
    project,
  }
}

const defaultState = {
  externalManagementUrl: '',
  manuallySorted: false,
}

type UseQuoteOptions = {
  callbacks?: {
    showCreateOrEditQuoteModal?: (payload: {}) => void,
  },
}

function useQuote(initEntity: Partial<QuoteModel> = {}, options: UseQuoteOptions = {}) {
  const { callbacks = {} } = options

  const { entity: quote }: { entity: QuoteModel} = useLatestEntity(initEntity, 'quotes')
  const { id, saved_template } = quote

  const [sortedSectionItemIds, setSortedSectionItemIds] = useState({})

  const [state, setState] = useSetState(defaultState)
  const { externalManagementUrl, manuallySorted } = state

  const pageContext = useContext(PageContext)
  const { callbacks: contextCallbacks = {} } = pageContext
  const dispatch = useDispatch()

  const {
    updatedEntities: {
      quoteItems: quoteItemsUpdatedAt,
      quotes: quotesUpdatedAt,
      quoteSections: quoteSectionsUpdatedAt,
    },
  } = useWatchEntityUpdates(watchEntityKeys)

  const entities = useSelector(reduxState => reduxState.entities)
  const { integrationPlatforms } = entities

  // Sections
  const filteredSections = useMemo(() => {
    const filtered = multiFilteredObjectArraySelector({ entities }, 'quoteSections', [
      { key: 'quote_id', value: id },
    ])
    const sorted = sortArrayBy(filtered, 'asc', 'sort')

    return sorted
  }, [quotesUpdatedAt, quoteItemsUpdatedAt, quoteSectionsUpdatedAt])

  // Items
  const filteredItems = useMemo(() => {
    const filtered = multiFilteredObjectArraySelector({ entities }, 'quoteItems', [
      { key: 'quote_id', value: id },
    ])
    const sorted = sortArrayBy(filtered, 'asc', 'sort')

    return sorted
  }, [quotesUpdatedAt, quoteItemsUpdatedAt, quoteSectionsUpdatedAt])

  const {
    creating, deleting, loading, updating,
  } = useSelector(reduxState => reduxState.quotes)

  // Handle item sorting in state
  useEffect(() => {
    setupSortedSectionItemIds(filteredItems, setSortedSectionItemIds)
  }, [filteredItems.length])

  // Commit the sorted items
  useEffect(() => {
    if (manuallySorted){
      updateQuoteSectionItemOrder({ dispatch, quote, sortedSectionItemIds })
      setState({ manuallySorted: false })
    }
  }, [JSON.stringify(sortedSectionItemIds), manuallySorted])

  const externalPlatformKey = digObject(quote, 'data.source_platform')
  const externalId = digObject(quote, `data.external_ids.${externalPlatformKey}`)

  const integrationPlatform = findIntegrationPlatformByKey(integrationPlatforms, externalPlatformKey)

  const isExternal = !!externalId
  const isSavedTemplate = saved_template

  const lastSectionIndex = filteredSections.length - 1

  // Mixpanel Tracking
  const { callbacks: { dispatchMixpanelEvent } } = useMixpanel()

  return {
    callbacks: {
      createQuote: (quoteParams: Partial<QuoteModel>, entityOptions?: QuoteRequestOptions) => (
        createQuote({
          quoteParams, dispatch, dispatchMixpanelEvent, requestOptions: entityOptions,
        })
      ),
      createQuoteFromTemplate: (
        payload: CreateQuoteFromTemplatePaylod,
        entityOptions?: QuoteRequestOptions,
      ) => createQuoteFromTemplate({
        dispatch, dispatchMixpanelEvent, payload, requestOptions: entityOptions,
      }),
      deleteQuote: () => deleteQuote({ dispatch, quote }),
      downloadPdf: () => downloadPdf({ dispatch, isExternal, quote }),
      downloadPdfFromShareLink: (
        quoteId: number,
        shareLinkToken: string,
        entityOptions?: QuoteRequestOptions,
      ) => downloadPdfFromShareLink({
        quoteId, shareLinkToken, dispatch, options: entityOptions,
      }),
      editQuote: () => launchModal({
        callbacks: callbacks?.showCreateOrEditQuoteModal ? callbacks : contextCallbacks,
        modalKey: 'CreateOrEditQuoteModal',
        payload: {
          callbacks: {
            updateQuote: (quoteParams: Partial<QuoteModel>, entityOptions?: QuoteRequestOptions) => (
              updateQuote({
                quote, quoteParams, dispatch, requestOptions: entityOptions,
              })
            ),
          },
          quote,
        },
      }),
      importFromExternalPlatform: (
        params: {
          externalPlatformKey: string,
          payload: {},
        },
        entityOptions?: QuoteRequestOptions,
      ) => importFromExternalPlatform({ dispatch, options: entityOptions, params }),
      loadExternalPlatformManagementUrl: (
        integrationPlatformKey: string,
        requestOptions: QuoteRequestOptions,
      ) => loadExternalPlatformManagementUrl({
        dispatch, externalPlatformKey: integrationPlatformKey, options: requestOptions, setState,
      }),
      loadExternalPlatformManagementUrlForQuote: (
        requestOptions: QuoteRequestOptions,
      ) => loadExternalPlatformManagementUrlForQuote({
        dispatch, options: requestOptions, quote, setState,
      }),
      loadExternalPlatformPdfUrl: () => loadExternalPlatformPdfUrl({ dispatch, quote }),
      loadFromExternalPlatform: (
        integrationPlatformKey: string,
        requestOptions: QuoteRequestOptions,
      ) => loadFromExternalPlatform({ dispatch, integrationPlatformKey, options: requestOptions }),
      manageExternalQuotesUrl: (
        integrationPlatformKey: string,
        externalProjectId: number,
      ) => `#/external/quotes/${integrationPlatformKey}/${externalProjectId}`,
      modifySectionItemSort: (
        fromIndex: number,
        toIndex: number,
        payload: ModifySectionItemSortPayload,
      ) => modifySectionItemSort({
        fromIndex, payload, setSortedSectionItemIds, setState, toIndex,
      }),
      newQuote: () => launchModal({
        callbacks: callbacks?.showCreateOrEditQuoteModal ? callbacks : contextCallbacks,
        modalKey: 'CreateOrEditQuoteModal',
        payload: {
          callbacks: {
            createQuote: (quoteParams: Partial<QuoteModel>, entityOptions?: QuoteRequestOptions) => (
              createQuote({
                quoteParams, dispatch, dispatchMixpanelEvent, requestOptions: entityOptions,
              })
            ),
          },
          quote,
        },
      }),
      saveAsTemplate: () => launchModal({
        callbacks: callbacks?.showCreateOrEditQuoteModal ? callbacks : contextCallbacks,
        modalKey: 'CreateOrEditQuoteModal',
        payload: {
          callbacks: {
            saveQuoteAsTemplate: (
              quoteTemplate: Partial<QuoteModel>,
              requestOptions: QuoteRequestOptions,
            ) => saveQuoteAsTemplate({
              dispatch, quoteTemplate, existingQuote: quote, requestOptions,
            }),
          },
          quote,
          savingAsTemplate: true,
        },
      }),
      updateQuote: (quoteParams: Partial<QuoteModel>, entityOptions?: QuoteRequestOptions) => (
        updateQuote({
          quote, quoteParams, dispatch, requestOptions: entityOptions,
        })
      ),
    },
    creating,
    deleting,
    externalId,
    externalManagementUrl,
    externalPlatformKey,
    filteredSections,
    integrationPlatform,
    isExternal,
    isSavedTemplate,
    lastSectionIndex,
    loading,
    quote,
    sortedSectionItemIds,
    updating,
    urls: generateUrls(quote),
  }
}

export default useQuote
