export default {
  default: {
    buttons: {
      addComparable: 'Add Comparable',
      save: 'Save',
      showAll: 'Show All',
      showSelected: 'Show Selected',
      toggleAll: 'Toggle All',
    },
    allComparables: 'All Comparables',
    source: {
      market: 'Market',
      organization: '{global.organizationType.shortName.singular}',
    },
    formFieldLabels: {
      filter: 'Filter Comparables',
      moreOptions: 'More Options',
    },
    noticeBox: {
      // eslint-disable-next-line max-len
      paragraph: 'This {global.projectType.shortName.singular|l|} has no comparables selected. Add one by clicking below.',
      title: 'No Comparables Saved',
    },
    sideBarModalHeader: {
      title: 'Manage',
      titleSecondLine: 'Comparables',
    },
  },
}
