import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

import { useModals, useSetState } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import useLocalization from '@hooks/useLocalization'

import EditImageModal from '@modals/EditImageModal'

import handleCallbackAction from '@functions/handleCallbackAction'

import defaultRequestOptions from '@sections/Client/packs/Project/defaultRequestOptions'

import PageContent from './components/PageContent'

import localizedStrings from './localizedStrings'

const bulkDeleteImages = (bulkDeleteFn, strings) => {
  bulkDeleteFn().then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    toast(strings.imagesDeleted || 'Images Deleted')
  })
}

const updateImage = (image, updateFn, setState, strings) => {
  updateFn(image, { ...defaultRequestOptions.projectImage }).then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    toast(strings.imageUpdated || 'Image Updated')
    setState({ showEditImageModal: false })
  })
}

const callbacks = (component, setState, strings) => {
  const componentCallbacks = {
    Media: {
      bulkDeleteImages: bulkDeleteFn => bulkDeleteImages(bulkDeleteFn, strings),
      setState: updatedState => setState(updatedState),
    },
    EditImageModal: {
      closeModal: () => setState({ showEditImageModal: false }),
      deleteImage: payload => handleCallbackAction(payload),
      updateImage: (image, updateFn) => updateImage(image, updateFn, setState, strings),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  galleryView: 'grid',
  showEditImageModal: false,
}

const Media = (props) => {
  const { projectId, project } = props

  const [state, setState] = useSetState(defaultState)
  const { galleryView, showEditImageModal } = state

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const pageContext = {
    callbacks: {
      showEditImageModal: (payload) => {
        setModalData('EditImageModal', payload)
        setState({ showEditImageModal: true })
      },
    },
    project,
  }

  const { strings } = useLocalization(localizedStrings)

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent
          callbacks={callbacks('Media', setState, strings)}
          galleryView={galleryView}
          project={project}
          projectId={projectId}
        />

        {/* Modals */}
        <EditImageModal callbacks={callbacks('EditImageModal', setState, strings)} showModal={showEditImageModal} />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

Media.propTypes = {
  project: PropTypes.object.isRequired,
  projectId: PropTypes.number,
}

export default Media
