import React from 'react'

import { BlankState } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

import teamsUrl from './assets/teams-blank-state.svg'

const TeamsBlankState = () => {
  const { strings } = useLocalization(localizedStrings)

  return (
    <BlankState boxProps={{ marginBottom: ['large', 0] }} imageUrl={teamsUrl}>
      <BlankState.Title>{strings.title || 'No Teams Created'}</BlankState.Title>
      <BlankState.Paragraph>
        {strings.paragraph || 'This project has no share links created. You can create a digital page which will automatically generate proofing and tracking links'}
      </BlankState.Paragraph>
    </BlankState>
  )
}

export default TeamsBlankState
