export default {
  default: {
    buttons: {
      download: 'Download PDF',
      goToProject: 'Go to Project',
      manageShareLinks: 'Manage Share Links',
      resetDefaultImage: 'Reset to Default Image',
      resetDefaultText: 'Reset to Default Text',
      resetDisabledSections: 'Reset Disabled Sections',
      resetSortedSections: 'Reset Sorted Sections',
      save: 'Save',
    },
    header: {
      title: 'Edit',
      titleSecondLine: 'Digital Page',
    },
    formFieldLabels: {
      digitalTemplate: 'Page Template',
      moreOptions: 'More Options',
      status: 'Status',
      title: 'Page Title',
    },
    swal: {
      confirmButton: 'Yes, reset them',
      confirmDisableTitle: 'Reset Disabled Sections?',
      confirmImageTitle: 'Reset Images?',
      confirmSectionsTitle: 'Reset Sections?',
      confirmTextTitle: 'Reset Texts?',
      text: 'Are you sure? This action cannot be undone.',
    },
    option: 'Please Select...',
  },
}
