import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons'

import { BlankState, Button } from '@campaignhub/suit-theme'

import { generateUrls } from '@hooks/useDocumentTemplate'
import useLocalization from '@hooks/useLocalization'

import BlankStateImageUrl from './assets/no-templates.svg'
import localizedStrings from './localizedStrings'

const TemplateBlankState = () => {
  const { strings } = useLocalization(localizedStrings)

  const { documentTemplatesIndexUrl } = generateUrls()

  return (
    <BlankState
      boxProps={{ flexDirection: 'column-reverse' }}
      contentProps={{ width: '100%', marginTop: 'large' }}
      forceMobile
      imageUrl={BlankStateImageUrl}
      sidebar
    >
      <BlankState.Title>{strings.blankState?.title || 'No Templates'}</BlankState.Title>

      <BlankState.Paragraph>
        {strings.blankState?.paragraph || 'Templates can be created in Admin settings.'}
      </BlankState.Paragraph>

      <BlankState.Actions>
        <Button
          as="a"
          buttonStyle="secondaryUtility"
          ghost
          href={documentTemplatesIndexUrl}
          icon={<FontAwesomeIcon icon={faInfoCircle} />}
          size="medium"
        >
          {strings.button || 'Create Templates'}
        </Button>
      </BlankState.Actions>
    </BlankState>
  )
}

export default TemplateBlankState
