import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserTie } from '@fortawesome/pro-light-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'

import {
  Box, Image, ListItem, Text,
} from '@campaignhub/suit-theme'

import useUser from '@hooks/useUser'

import EngagementStatusesBox from '@components/EngagementStatusesBox'
import IconInCircle from '@components/IconInCircle'

const UserListItem = (props) => {
  const { leaderboard } = props

  const {
    cold, hot, user_id, warm,
  } = leaderboard || {}

  const { defaultImage, user } = useUser({ id: user_id })
  const { full_name } = user

  const profileImageUrl = digObject(defaultImage, 'sizes.thumb_90x90')

  if (!user_id) return null

  return (
    <ListItem
      boxProps={{
        borderBottom: '1px solid',
        alignItems: 'center',
        padding: 'large',
      }}
      disableHover
    >
      {profileImageUrl ? (
        <Image
          boxProps={{ backgroundColor: 'hoverLightGrey', fontSize: 'small' }}
          circle
          height={30}
          url={profileImageUrl}
          width={30}
        />
      ) : (
        <IconInCircle backgroundColor="whiteGrey" border="1px solid" borderColor="hoverGrey" height={30} width={30}>
          <FontAwesomeIcon icon={faUserTie} />
        </IconInCircle>
      )}

      <Box marginLeft="large">
        <Text fontSize="small">{full_name}</Text>
      </Box>

      <EngagementStatusesBox cold={cold} hot={hot} warm={warm} />
    </ListItem>
  )
}

UserListItem.propTypes = {
  leaderboard: PropTypes.object.isRequired,
}

export default UserListItem
