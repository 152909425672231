import { useContext } from 'react'
import PropTypes from 'prop-types'

import { useSetState } from '@campaignhub/react-hooks'

import PageContext from '@contexts/pageContext'

import { isSectionEnabled } from '@functions/digitalPage'

const toggleSection = (context, props, setState) => {
  const { sectionId } = props

  const {
    callbacks: { updateDigitalPage: updateFn },
    digitalPage: { id, options },
  } = context

  setState({ updating: true })

  const updatedOptions = { ...options }
  const disabledSections = updatedOptions.disabled_sections ? [...updatedOptions.disabled_sections] : []

  const index = disabledSections.indexOf(sectionId)
  if (index === -1){
    disabledSections.push(sectionId)
  } else {
    disabledSections.splice(index, 1)
  }

  updatedOptions.disabled_sections = disabledSections

  const updatedDigitalPage = {
    id,
    options: updatedOptions,
  }

  updateFn(updatedDigitalPage).then(() => {
    setState({
      updating: false,
    })
  })
}

const defaultState = {
  updating: false,
}

const ToggleableSectionWrapper = (props) => {
  const [state, setState] = useSetState(defaultState)
  const { updating } = state

  const { render, sectionId } = props

  const pageContext = useContext(PageContext)
  const { digitalPage, editing } = pageContext

  const enabled = isSectionEnabled(digitalPage, sectionId)
  if (!editing && !enabled){
    return null
  }

  return render({
    editing,
    enabled,
    toggleSection: () => toggleSection(pageContext, props, setState),
    updating,
  })
}

ToggleableSectionWrapper.propTypes = {
  render: PropTypes.func.isRequired,
  sectionId: PropTypes.string.isRequired,
}

export default ToggleableSectionWrapper
