import React from 'react'
import PropTypes from 'prop-types'

import { SidebarModal } from '@campaignhub/suit-theme'

import FieldHolder from '@components/FieldHolder'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const shouldRender = (itemType) => {
  const permittedItemTypes = ['video']
  return permittedItemTypes.includes(itemType)
}

const updateOption = (name, value, props) => {
  const {
    selectedEntity,
    callbacks: { updateParam },
  } = props

  const updatedOptions = {
    ...selectedEntity.options,
    [name]: value,
  }

  updateParam('options', updatedOptions)
}

const renderVideoOptions = (selectedEntity, options, props, strings) => {
  if (selectedEntity.item_type === 'video'){
    return (
      <FieldHolder boxProps={{ marginBottom: 'large' }} label={strings.fieldHolders?.videoProvider || 'Video Provider'}>
        <select onChange={e => updateOption('videoProvider', e.target.value, props)} value={options.videoProvider}>
          <option value="">{strings.selectValues?.select || 'Select...'}</option>
          <option value="youtube">{strings.selectValues?.youtube || 'YouTube'}</option>
          <option value="vimeo">{strings.selectValues?.vimeo || 'Vimeo'}</option>
        </select>
      </FieldHolder>
    )
  }

  return null
}

const Options = (props) => {
  const {
    selectedEntity,
    selectedEntity: { item_type },
  } = props

  const { strings } = useLocalization(localizedStrings)

  if (!shouldRender(item_type)) return null

  const options = selectedEntity.options || {}

  return (
    <SidebarModal.ExpandableSectionBox defaultOpen label={strings.label || 'Options'}>
      {renderVideoOptions(selectedEntity, options, props, strings)}
    </SidebarModal.ExpandableSectionBox>
  )
}

Options.propTypes = {
  selectedEntity: PropTypes.object.isRequired,
}

export default Options
