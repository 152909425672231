export default {
  default: {
    blankState: {
      button: 'Please Wait...',
      paragraphOne: 'Have a quick break while we build your agreement. Once complete it will be available to download or send for signing',
      paragraphTwo: 'Please wait while we compile your Agreement in the background. It should only take a few moments. If you experience any issues please contact support.',
    },
    header: {
      title: 'Building',
      titleSecondLine: 'Agreement',
    },
  },
}
