export default {
  default: {
    cold: {
      legendValue: 'Cold {percentageValue}',
    },
    hot: {
      legendValue: 'Hot {percentageValue}',
    },
    warm: {
      legendValue: 'Warm {percentageValue}',
    },
  },
}
