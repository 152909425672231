export type AddressModel = {
  cache_key: number,
  id: number,
  country_id: number,
  full_address: string,
  google_place_id: number,
  is_default: boolean,
  latitude: string,
  longitude: string,
  lot_number: string,
  owner_id: number,
  owner_type: string,
  postcode: string,
  region_name: string,
  state_name: string,
  street_name: string,
  street_number: number,
  title: string,
  unit_number: number,
}

export type AddressRequestOptions = {
  include_address_owner?: boolean,
}

const state = {
  country_id: null,
  google_place_id: '',
  lot_number: '',
  postcode: '',
  region_name: '',
  state_name: '',
  id: null,
  is_default: false,
  owner_id: null,
  owner_type: '',
  street_name: '',
  street_number: '',
  title: '',
  unit_number: '',
}

export const requiredFields = [
  { key: 'postcode' },
  { key: 'region_name' },
  { key: 'state_name' },
  { key: 'street_name' },
  { key: 'street_number' },
]

export default state
