import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudUpload } from '@fortawesome/pro-light-svg-icons'

import PageContext from '@contexts/pageContext'

import { digObject } from '@campaignhub/javascript-utils'

import getClonedFieldId from '@functions/getClonedFieldId'
import replaceTextShortCodes from '@functions/replaceTextShortCodes'
import { shouldRenderPageItem } from '@functions/digitalTemplatePageItem'

import useDeviceStyle from '@hooks/useDeviceStyle'
import useDigitalRendererImage from '@hooks/useDigitalRendererImage'
import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

import styles from './styles.module.scss'

const checkIfEditable = (customizingTemplate, editing, editable) => {
  if (customizingTemplate){
    return true
  }

  return editing && editable
}

const editAction = (e, params) => {
  const {
    callbacks: { changeItemImage, editDigitalPageItemImage },
    customizingTemplate,
    entityType,
    id,
  } = params

  e.preventDefault()
  e.stopPropagation()

  if (customizingTemplate){
    changeItemImage({ type: entityType, id }, e)
    return
  }

  editDigitalPageItemImage({ id })
}

const imageStyle = (defaultStyle, imageUrl) => {
  const backgroundImage = imageUrl ? `url(${imageUrl})` : null

  return {
    ...defaultStyle,
    backgroundImage,
  }
}

const Image = (props) => {
  const {
    children,
    context: componentContext,
    data: { value: rawValue },
    entityType,
    id,
    image: imageParams,
    itemEntities,
    item_type: itemType,
    options,
  } = props

  // Can this image be changed by the user?
  const { editable, renderConditions } = options || {}

  const style = useDeviceStyle(props)

  const pageContext = useContext(PageContext)
  const {
    callbacks: { changeItemImage, editDigitalPageItemImage },
    customData,
    customizingTemplate,
    editing,
    shortcodeData,
  } = pageContext || {}

  const clonedFieldId = getClonedFieldId(rawValue)
  const clonedFieldRawValue = digObject(itemEntities, `${clonedFieldId}.image`)

  const contextData = { ...shortcodeData, ...componentContext }

  // Value
  const value = replaceTextShortCodes(rawValue, contextData)
  const imageItemRawValue = clonedFieldRawValue?.id || value

  const itemData = customData[clonedFieldId] || customData[id] || {}

  const { imageSizeUrl, imageRef } = useDigitalRendererImage(imageParams, {
    customData: itemData,
    editable,
    shortcodeImageId: imageItemRawValue,
  })

  // In edit mode and the image is editable by the user
  const isEditable = checkIfEditable(customizingTemplate, editing, editable)

  const ComponentTag = isEditable ? 'a' : 'span'

  const { strings } = useLocalization(localizedStrings)

  // Should Render
  const shouldRender = shouldRenderPageItem(renderConditions, contextData, options)
  if (!shouldRender) return null

  return (
    <ComponentTag
      className={isEditable ? classnames(styles.root, styles.editable) : styles.root}
      ref={imageRef}
      style={imageStyle(style, imageSizeUrl)}
      onClick={
        isEditable
          ? e => editAction(e, {
            callbacks: {
              changeItemImage,
              editDigitalPageItemImage,
            },
            customizingTemplate,
            entityType,
            id,
            itemType,
          })
          : null
      }
    >
      {children}

      {isEditable && (
        <span className={styles.changeText}>
          <FontAwesomeIcon icon={faCloudUpload} style={{ marginRight: 5 }} />
          {strings.spanText || 'Change'}
        </span>
      )}
    </ComponentTag>
  )
}

Image.propTypes = {
  children: PropTypes.node,
  context: PropTypes.object,
  data: PropTypes.object,
  entityType: PropTypes.string,
  id: PropTypes.string,
  image: PropTypes.object,
  itemEntities: PropTypes.object.isRequired,
  item_type: PropTypes.string,
  options: PropTypes.object,
}

export default Image
