export default {
  default: {
    approve: 'Approve',
    archiveTemplate: 'Archive Template',
    digitalTemplate: 'Digital Template #{id}',
    draft: 'Draft',
    editTemplate: 'Edit Template',
    inProduction: 'In Production',
    permissions: 'Permissions',
    unApprove: 'Unapprove',
    unarchiveTemplate: 'Unarchive Template',
  },
}
