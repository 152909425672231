import { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import cloneDeep from 'lodash/cloneDeep'

import { digObject, sortArrayBy } from '@campaignhub/javascript-utils'
import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import PageContext from '@contexts/pageContext'

import generateID from '@functions/generateID'
import { validateRenderConditions } from '@functions/replaceTextShortCodes'

import useDigitalPage, { useRelations as useDigitalPageRelations } from '@hooks/useDigitalPage'
import { mergeComponentStyles, useRelations as useDigitalTemplateRelations } from '@hooks/useDigitalTemplate'

const watchEntityKeys = ['digitalTemplates']

const filterItemChildren = (params) => {
  const { availableAnchorTags, digitalTemplatePageItems, parentItem } = params

  const items = []

  const parentItemIds = digObject(parentItem, 'items', [])

  if (parentItemIds.length){
    parentItemIds.forEach((parentItemId) => {
      const item = digitalTemplatePageItems[parentItemId] || {}
      const anchorTag = digObject(item, 'options.anchor')
      const index = availableAnchorTags.indexOf(anchorTag)

      if (!anchorTag || availableAnchorTags.includes(anchorTag)){
        const updatedItem = {
          ...item,
          options: {
            ...item.options,
            sort: index > -1 ? index : null,
          },
          items: filterItemChildren({ ...params, parentItem: item }),
        }

        items.push(updatedItem)
      }
    })
  }

  return sortArrayBy(items, 'asc', item => item.options?.sort)
}

const buildSections = (params) => {
  const {
    availableAnchorTags, entities, sectionIds,
  } = params

  const { digitalTemplatePageItems, digitalTemplatePageSections } = entities

  const updatedSections = sectionIds.map((sectionId, index) => {
    const section = digitalTemplatePageSections[sectionId] || {}

    const digitalTemplatePageItemAnchorTags = Object.values(digitalTemplatePageItems)
      .filter(digitalTemplatePageItem => !!digitalTemplatePageItem?.options?.anchor).filter(i => i)

    const sortedDigitalTemplatePageItems = digitalTemplatePageItemAnchorTags
      .sort((a, b) => availableAnchorTags.indexOf(a?.options?.anchor) - availableAnchorTags.indexOf(b?.options?.anchor))

    const sortedDigitalTemplatePageItemIds = sortedDigitalTemplatePageItems.map(sorted => sorted.id)

    return {
      ...cloneDeep(section),
      id: `${generateID()}-${index}`,
      items: filterItemChildren({
        availableAnchorTags, parentItem: section, digitalTemplatePageItems, sortedDigitalTemplatePageItemIds,
      }),
    }
  })

  return updatedSections
}

const buildSectionGroups = (params) => {
  const {
    availableAnchorTags, entities, sidebarTemplatePage, sortedGroupSections,
  } = params
  const { digitalTemplatePageSectionGroups } = entities
  const originalSectionGroupIds = digObject(sidebarTemplatePage, 'section_groups', [])

  const filteredSectionGroups = originalSectionGroupIds.reduce((acc, sectionGroupId) => {
    const sectionGroup = digitalTemplatePageSectionGroups[sectionGroupId] || {}
    const sectionIds = digObject(sectionGroup, 'sections', [])

    acc.push({
      ...sectionGroup,
      sections: buildSections({
        availableAnchorTags, entities, sectionIds, sortedGroupSections,
      }),
    })

    return acc
  }, [])

  return filteredSectionGroups
}

const getAvailableAnchorTags = (params) => {
  const {
    digitalTemplatePage, disabledSectionIds, entities, shortcodeData, sortedGroupSections,
  } = params

  const { digitalTemplatePageSectionGroups, digitalTemplatePageSections } = entities

  const templateSectionGroupIds = digObject(digitalTemplatePage, 'section_groups', [])

  const sortedDigitalTemplatePageSectionIds = templateSectionGroupIds
    .map(sectionGroupId => sortedGroupSections[sectionGroupId]).flat()

  const unSortedDigitalTemplatePageSectionIds = templateSectionGroupIds
    .map(sectionGroupId => digitalTemplatePageSectionGroups[sectionGroupId]?.sections).flat()

  const digitalTemplateSectionIds = [...sortedDigitalTemplatePageSectionIds, ...unSortedDigitalTemplatePageSectionIds]

  const filteredDigitalTemplateSectionIds = [...new Set(digitalTemplateSectionIds)]

  const availableAnchorTags = filteredDigitalTemplateSectionIds.reduce((acc, sectionId) => {
    const section = digitalTemplatePageSections[sectionId] || {}

    const anchorTag = digObject(section, 'options.anchor')

    const renderConditions = digObject(section, 'options.renderConditions')

    const shouldRender = validateRenderConditions(renderConditions, shortcodeData)

    if (anchorTag && shouldRender && !disabledSectionIds.includes(section?.id)){
      acc.push(anchorTag)
    }

    return acc
  }, [])

  return availableAnchorTags
}

function useDigitalPageSidebar(digitalPage){
  const {
    updatedEntities: { digitalTemplateComponents: digitalTemplateComponentsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  // Context
  const pageContext = useContext(PageContext)
  const { shortcodeData } = pageContext || {}

  const { digitalTemplate } = useDigitalPageRelations(digitalPage)
  const { sortedGroupSections } = useDigitalPage(digitalPage)

  const selectedPageId = useSelector(reduxState => reduxState.digitalTemplatePages.selectedId)

  const entities = useSelector(reduxState => reduxState.entities)
  const { digitalTemplateComponents, digitalTemplatePages } = entities

  const digitalTemplatePage = digitalTemplatePages[selectedPageId] || {}

  // Sidebar
  const { sidebarTemplate } = useDigitalTemplateRelations(digitalTemplate)

  const sidebarTemplateOptions = digObject(sidebarTemplate, 'options', {})

  // Component Styles
  const legacyComponentStyles = digObject(sidebarTemplateOptions, 'component_styles.styles', [])
  const sidebarTemplateComponentIds = digObject(sidebarTemplate, 'digital_template_component_ids', [])

  const componentStyles = useMemo(() => {
    const array = Object.values(digitalTemplateComponents)
    const filteredComponentStyles = array.filter(
      component => sidebarTemplateComponentIds.includes(component.id) && component.item_type === 'component_style',
    )

    return filteredComponentStyles
  }, [sidebarTemplate.id, digitalTemplateComponentsUpdatedAt])

  const sidebarTemplatePageId = digObject(sidebarTemplate, 'pages.0')
  const sidebarTemplatePage = digitalTemplatePages[sidebarTemplatePageId] || {}

  // Disabled Sections
  const disabledSectionIds = digObject(digitalPage, 'options.disabled_sections', [])

  // Disabled Anchor Tags
  const availableAnchorTags = getAvailableAnchorTags({
    digitalTemplatePage, disabledSectionIds, entities, shortcodeData, sortedGroupSections,
  })

  const filteredSectionGroups = buildSectionGroups({
    availableAnchorTags, entities, sidebarTemplatePage, sortedGroupSections,
  })

  return {
    availableAnchorTags,
    componentStyles: mergeComponentStyles(legacyComponentStyles, componentStyles),
    filteredSectionGroups,
  }
}

export default useDigitalPageSidebar
