import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCopy, faList, faPlus, faSearch,
} from '@fortawesome/pro-light-svg-icons'

import {
  Image, SidebarModal,
} from '@campaignhub/suit-theme'

import useComparable from '@hooks/useComparable'
import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const ComparableSourceSelector = (props) => {
  const {
    callbacks: { setModalState },
    projectId,
  } = props

  const initComparable = { project_id: projectId, source: 'organization' }
  const { callbacks: { createOrEditComparable } } = useComparable(initComparable)

  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      <SidebarModal.Button
        boxProps={{ paddingY: ['medium', 'large'] }}
        iconLeft={(
          <Image boxProps={{ backgroundColor: 'hoverLightGrey', fontSize: 'small' }} circle width={30}>
            <FontAwesomeIcon icon={faList} />
          </Image>
        )}
        onClick={() => setModalState({ action: 'browse' })}
        showAngleIcon
        subtitle={strings.buttons?.browse?.subtitle || 'Browse similar properties nearby'}
        title={strings.buttons?.browse?.title || 'Browse Comparables'}
      />

      <SidebarModal.Button
        boxProps={{ paddingY: ['medium', 'large'] }}
        iconLeft={(
          <Image boxProps={{ backgroundColor: 'hoverLightGrey', fontSize: 'small' }} circle width={30}>
            <FontAwesomeIcon icon={faSearch} />
          </Image>
        )}
        onClick={() => setModalState({ action: 'search' })}
        showAngleIcon
        subtitle={strings.buttons?.search?.subtitle || 'Find a specific property'}
        title={strings.buttons?.search?.title || 'Search Exact Match'}
      />

      <SidebarModal.Button
        boxProps={{ paddingY: ['medium', 'large'] }}
        iconLeft={(
          <Image boxProps={{ backgroundColor: 'hoverLightGrey', fontSize: 'small' }} circle width={30}>
            <FontAwesomeIcon icon={faPlus} />
          </Image>
        )}
        onClick={() => { createOrEditComparable() }}
        showAngleIcon
        subtitle={strings.buttons?.create?.subtitle || 'Create your own property reference'}
        title={strings.buttons?.create?.title || 'Add Manual Comparable'}
      />

      <SidebarModal.Button
        boxProps={{ paddingY: ['medium', 'large'] }}
        iconLeft={(
          <Image boxProps={{ backgroundColor: 'hoverLightGrey', fontSize: 'small' }} circle width={30}>
            <FontAwesomeIcon icon={faCopy} />
          </Image>
        )}
        onClick={() => setModalState({ action: 'clone' })}
        showAngleIcon
        subtitle={strings.buttons?.clone?.subtitle || 'Clone from existing comparables'}
        title={strings.buttons?.clone?.title || 'Use Existing Comparables'}
      />
    </>
  )
}

ComparableSourceSelector.propTypes = {
  callbacks: PropTypes.object.isRequired,
  projectId: PropTypes.number.isRequired,
}

export default ComparableSourceSelector
