import { MainContent, TopBar } from '@campaignhub/suit-theme'

import useMainNavigation from '@hooks/useMainNavigation'

import DashboardSearch from '@components/DashboardSearch'
import LoggedInUser from '@components/LoggedInUser'
import MainNavigation from '@sections/Admin/components/MainNavigation'

import SystemManagerRoutes from './routes'

const SystemManager = () => {
  const {
    callbacks: { toggleNavigation },
  } = useMainNavigation()

  return (
    <>
      <TopBar
        callbacks={{ toggleNavigation }}
        loggedInUserComponent={<LoggedInUser />}
        searchComponent={<DashboardSearch searchEntities={['Brand', 'DigitalTemplate', 'Organization', 'User']} />}
      />

      <MainNavigation />

      <MainContent offset={{ top: TopBar.topBarHeight }}>
        <SystemManagerRoutes />
      </MainContent>
    </>
  )
}

export default SystemManager
