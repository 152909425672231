import React from 'react'
import PropTypes from 'prop-types'

import RealEstateCommercialCampaignIcon from '@components/Icons/RealEstateCommercialCampaignIcon'
import RealEstateRentalCampaignIcon from '@components/Icons/RealEstateRentalCampaignIcon'
import RealEstateRuralCampaignIcon from '@components/Icons/RealEstateRuralCampaignIcon'
import RealEstateSaleCampaignIcon from '@components/Icons/RealEstateSaleCampaignIcon'

const icons = {
  real_estate_commercial_campaign: RealEstateCommercialCampaignIcon,
  real_estate_rental_campaign: RealEstateRentalCampaignIcon,
  real_estate_rural_campaign: RealEstateRuralCampaignIcon,
  real_estate_sale_campaign: RealEstateSaleCampaignIcon,
}

const ProjectIcon = (props) => {
  const {
    color, height, projectTypeKey, width,
  } = props

  const Icon = icons[projectTypeKey]

  if (!Icon){
    return null
  }

  return <Icon fillColor={color} height={height} width={width} />
}

ProjectIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  projectTypeKey: PropTypes.string,
  width: PropTypes.number,
}

export default ProjectIcon
