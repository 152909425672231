import PropTypes from 'prop-types'

import { faPhotoVideo } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Columns, DashboardModule, LoadingModule, PageHeader,
} from '@campaignhub/suit-theme'

import useImages from '@hooks/useImages'
import useLocalization from '@hooks/useLocalization'
import useProject from '@hooks/useProject'

import ImageUpload from '@components/ImageUpload'

import defaultRequestOptions from '@sections/Client/packs/Project/defaultRequestOptions'

import Gallery from '../Gallery'
import MediaBlankState from '../MediaBlankState'
import ProjectMediaTools from '../ProjectMediaTools'
import localizedStrings from './localizedStrings'

const PageContent = (props) => {
  const {
    callbacks, galleryView, project, projectId,
  } = props

  const { urls } = useProject({ id: projectId })
  const { mediaUrl } = urls

  const entityKey = `Project${projectId}`

  const imagesPayload = useImages({
    customRequestOptions: defaultRequestOptions.projectImage,
    entityKey,
    performHttpRequests: true,
    subjectId: projectId,
    subjectType: 'Project',
  })

  const { hasImages, loading } = imagesPayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      <PageHeader
        activeTabBarItemKey="media"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        nestedNavigation
        tabBarItems={[
          {
            href: mediaUrl,
            icon: faPhotoVideo,
            key: 'media',
            title: strings.tabTitle || 'Photos & Plans',
          },
        ]}
        title={strings.title || 'Media'}
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && !hasImages && (
              <Box marginBottom="large">
                <MediaBlankState />
              </Box>
            )}

            <Gallery callbacks={callbacks} galleryView={galleryView} imagesPayload={imagesPayload} />

            {project.id && (
              <DashboardModule title={strings.upload || 'Upload'}>
                <ImageUpload
                  imageSizes={['thumb_200x120', 'thumb_400x240', 'thumb_660x360', 'thumb_1320x720']}
                  imageType="photo"
                  ownerId={project.organization_id}
                  ownerType="Organization"
                  subjectId={project.id}
                  subjectType="Project"
                />
              </DashboardModule>
            )}
          </Columns.Main>

          <Columns.Sidebar>
            <LoadingModule loading={loading} times={3} />

            {hasImages && (
              <ProjectMediaTools callbacks={callbacks} galleryView={galleryView} imagesPayload={imagesPayload} />
            )}
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

PageContent.propTypes = {
  callbacks: PropTypes.object.isRequired,
  galleryView: PropTypes.string.isRequired,
  project: PropTypes.object.isRequired,
  projectId: PropTypes.number.isRequired,
}

export default PageContent
