import { useContext } from 'react'

import {
  Box, LoadingModule, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useActivities from '@hooks/useActivities'
import useOrganizationSelector from '@hooks/useOrganizationSelector'

import Activity from '@components/Activity'

import LoadMoreFooter from '@components/ModalComponents/LoadMoreFooter'
import ActivityBlankState from './components/ActivityBlankState'

const MODAL_KEY = 'ActivitiesModal'

type ModalCallbacks = {
  closeModal: () => void,
}

type ModalProps = {
  callbacks: ModalCallbacks,
  showModal: boolean,
}

const ActivitiesModal = (props: ModalProps) => {
  const { callbacks, showModal } = props

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const { filters } = modalPayload

  const useActivitiesPayload = useActivities({
    filters,
    performHttpRequests: true,
    requestOptions: {
      limit: 50,
      include_activity_user: true,
      include_user_default_image: true,
      include_user_image_sizes: ['thumb_90x90'],
    },
  })

  const {
    callbacks: { loadMore }, canLoadMore, filteredActivities, hasActivities, loading,
  } = useActivitiesPayload

  const { selectedOrganization } = useOrganizationSelector()

  return (
    <SidebarModal callbacks={callbacks} modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title="Activity" titleSecondLine="Log" />

      <SidebarModal.Content hasFooter={false}>
        <Box flexDirection="column" flexShrink={0}>
          <LoadingModule loading={loading} times={3} />

          {!loading && !hasActivities && <ActivityBlankState sidebar/>}

          {!loading && hasActivities && (
            <Box flexDirection="column" overflow="auto">
              {filteredActivities.map(activity => (
                <Activity
                  boxProps={{ padding: 'none', paddingY: 'large' }}
                  key={activity.id}
                  activity={activity}
                  organizationId={selectedOrganization.id}
                />
              ))}
            </Box>
          )}

          <LoadMoreFooter
            callbacks={{ loadMore }}
            canLoadMore={canLoadMore}
            entityCount={filteredActivities.length}
            loading={loading}
          />
        </Box>
      </SidebarModal.Content>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ModalProps) => (
  <SidebarModal.RenderController {...props}>
    <ActivitiesModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
