import { ReactNode } from 'react'

import { Grid } from '@campaignhub/suit-theme'

interface GridViewProps {
  itemComponent: ReactNode,
}

const GridView = (props: GridViewProps) => {
  const { itemComponent } = props

  return (
    <Grid gridGap="medium" gridTemplateColumns="repeat(2, 1fr)">
      {itemComponent}
    </Grid>
  )
}

export default GridView
