import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArchive,
  faCalendar,
  faChartBar,
  faCoins,
  faFile,
  faHandHoldingUsd,
  faHistory,
  faList,
  faPencil,
  faPhotoVideo,
  faReplyAll,
  faTachometer,
} from '@fortawesome/pro-light-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'

import IconInCircle from '@components/IconInCircle'

const generateNavigationItems = (props) => {
  const { project, strings } = props

  const projectId = digObject(project, 'id')

  return {
    sections: [
      {
        key: 'dashboard',
        visible: true,
        items: [
          {
            href: `#/projects/${projectId}`,
            iconComponent: <IconInCircle><FontAwesomeIcon icon={faTachometer} /></IconInCircle>,
            key: 'dashboard',
            title: strings.navigationItems?.overview || 'Overview',
            visible: true,
          },
        ],
      },
      {
        key: 'prepare',
        title: strings.navigationItems?.prepare || 'PREPARE',
        visible: true,
        items: [
          {
            href: `#/projects/${projectId}/edit`,
            iconComponent: <IconInCircle><FontAwesomeIcon icon={faPencil} /></IconInCircle>,
            key: 'details',
            title: strings.navigationItems?.details || 'Details',
            visible: true,
          },
          {
            href: `#/projects/${projectId}/comparables`,
            iconComponent: <IconInCircle><FontAwesomeIcon icon={faList} /></IconInCircle>,
            key: 'comparables',
            title: strings.navigationItems?.comparables || 'Comparables',
            visible: true,
          },
          {
            href: `#/projects/${projectId}/events`,
            iconComponent: <IconInCircle><FontAwesomeIcon icon={faCalendar} /></IconInCircle>,
            key: 'events',
            title: strings.navigationItems?.events || 'Events',
            visible: true,
          },
          {
            href: `#/projects/${projectId}/quotes`,
            iconComponent: <IconInCircle><FontAwesomeIcon icon={faCoins} /></IconInCircle>,
            key: 'quotes',
            title: strings.navigationItems?.quotes || 'Quotes',
            visible: true,
          },
          {
            href: `#/projects/${projectId}/media`,
            iconComponent: <IconInCircle><FontAwesomeIcon icon={faPhotoVideo} /></IconInCircle>,
            key: 'media',
            title: strings.navigationItems?.media || 'Media',
            visible: true,
          },
          {
            href: `#/projects/${projectId}/documents`,
            iconComponent: <IconInCircle><FontAwesomeIcon icon={faArchive} /></IconInCircle>,
            key: 'files',
            title: strings.navigationItems?.files || 'Files',
            visible: true,
          },
        ],
      },
      {
        key: 'share',
        title: strings.navigationItems?.share || 'SHARE',
        visible: true,
        items: [
          {
            href: `#/projects/${projectId}/digitalPages?digitalTemplateType=proposal`,
            iconComponent: <IconInCircle><FontAwesomeIcon icon={faHandHoldingUsd} /></IconInCircle>,
            key: 'proposals',
            title: strings.navigationItems?.proposals || 'Proposals',
            visible: true,
          },
          {
            href: `#/projects/${projectId}/agreements`,
            iconComponent: <IconInCircle><FontAwesomeIcon icon={faFile} /></IconInCircle>,
            key: 'agreements',
            title: strings.navigationItems?.agreements || 'Agreements',
            visible: true,
          },
        ],
      },
      {
        key: 'analyze',
        title: strings.navigationItems?.analyze || 'ANALYZE',
        visible: true,
        items: [
          {
            href: `#/projects/${projectId}/formResponses`,
            iconComponent: <IconInCircle><FontAwesomeIcon icon={faReplyAll} /></IconInCircle>,
            key: 'form-responses',
            title: strings.navigationItems?.formResponses || 'Form Responses',
            visible: true,
          },
          {
            href: `#/projects/${projectId}/analytics`,
            iconComponent: <IconInCircle><FontAwesomeIcon icon={faChartBar} /></IconInCircle>,
            key: 'analytics',
            title: strings.navigationItems?.analytics || 'Analytics',
            visible: true,
          },
          {
            href: `#/projects/${projectId}/activities`,
            iconComponent: <IconInCircle><FontAwesomeIcon icon={faHistory} /></IconInCircle>,
            key: 'activities',
            title: strings.navigationItems?.activities || 'Activities',
            visible: true,
          },
        ],
      },
    ],
  }
}

generateNavigationItems.propTypes = {
  project: PropTypes.object.isRequired,
}

export default generateNavigationItems
