import { useContext } from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserTag } from '@fortawesome/pro-light-svg-icons'

import { useSetState } from '@campaignhub/react-hooks'

import {
  Box, Button, FormField, LoadingModule, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useCurrentUser from '@hooks/useCurrentUser'
import useLocalization from '@hooks/useLocalization'
import useOrganizationsUser from '@hooks/useOrganizationsUser'
import useProject, { useProjectForm } from '@hooks/useProject'
import useManageTargetAudiences from './hooks/useManageTargetAudiences'

import TargetAudience from './components/TargetAudience'
import TargetAudiencesBlankState from './components/TargetAudiencesBlankState'

import localizedStrings from './localizedStrings'

const defaultState = {
  filterKey: 'organization',
}

const ManageProjectTargetAudiencesModal = (props) => {
  const {
    callbacks, disableAnimation, disableOverlay, modalKey, project: initProject, showModal,
  } = props
  const { updateProject } = callbacks || {}

  const [state, setState] = useSetState(defaultState)
  const { filterKey } = state

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const { callbacks: payloadCallbacks } = digObject(modalData, modalKey, {})
  const { loadShortcodeData: loadDataFn } = payloadCallbacks || {}

  const manageTargetAudiencesPayload = useManageTargetAudiences({
    performHttpRequests: true,
    filterKey,
  })

  const {
    callbacks: { toggleAllTargetAudiences, toggleProjectTargetAudience },
    filteredTargetAudiences,
    hasTargetAudiences,
    loading,
    selectedTargetAudiences,
  } = manageTargetAudiencesPayload

  const {
    callbacks: { updateProject: updateFn },
    organization,
    project,
    updating,
  } = useProject(initProject)

  const {
    entityState,
    entityState: { target_audience_ids },
    setEntityState,
  } = useProjectForm(project)

  const { currentUser } = useCurrentUser()
  const { isCurrentUserAdminForOrganization } = useOrganizationsUser({
    organization_id: organization.id,
    user_id: currentUser.id,
  })

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal
      callbacks={callbacks}
      disableAnimation={disableAnimation}
      disableOverlay={disableOverlay}
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.sideBarModalHeader?.title || 'Target'}
        titleSecondLine={strings.sideBarModalHeader?.titleSecondLine || 'Audiences'}
      />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <LoadingModule loading={loading} times={3} />

          {!loading && !hasTargetAudiences && <TargetAudiencesBlankState />}

          {!loading && hasTargetAudiences && (
            <FormField direction="column" label={strings.formFieldLabels?.targetAudiences || 'Available Audiences'}>
              <select name="filterKey" value={filterKey} onChange={e => setState({ filterKey: e.target.value })}>
                <option key=""> {strings.pleaseSelect || 'Please Select ...'} </option>

                <option key="organization" value="organization">
                  {strings.options?.organization || 'Organisation'}
                </option>

                <option key="brand" value="brand">
                  {strings.options?.brand || 'Brand'}
                </option>

                <option key="system" value="system">
                  {strings.options?.system || 'System'}
                </option>
              </select>
            </FormField>
          )}

          {!loading
            && hasTargetAudiences
            && selectedTargetAudiences.map((targetAudience) => {
              const selectedIndex = target_audience_ids.findIndex(id => id === targetAudience.id)
              const isSelected = selectedIndex !== -1

              return (
                <TargetAudience
                  callbacks={{
                    toggleTargetAudience: id => toggleProjectTargetAudience({
                      entityState,
                      setEntityState,
                      targetAudienceId: id,
                    }),
                  }}
                  isSelected={isSelected}
                  key={targetAudience.id}
                  selectedIndex={selectedIndex}
                  targetAudience={targetAudience}
                />
              )
            })}

          {!loading && !selectedTargetAudiences.length && <TargetAudiencesBlankState />}

        </Box>

        {!!selectedTargetAudiences.length
        && (
        <FormField
          direction="column"
          boxProps={{ marginTop: 'large' }}
          label={strings.formFieldLabels?.moreOptions || 'More Options'}
        >
          <Box flexDirection="column" flexShrink={0}>
            <Button
              buttonStyle="secondaryUtility"
              marginBottom="medium"
              onClick={() => toggleAllTargetAudiences({
                entityState,
                setEntityState,
                targetAudiences: filteredTargetAudiences,
              })}
              size="medium"
            >
              {strings.buttons?.toggleAll || 'Toggle All'}
            </Button>

            {isCurrentUserAdminForOrganization && (
            <Button
              as="a"
              buttonStyle="secondaryUtility"
              icon={<FontAwesomeIcon icon={faUserTag} />}
              href="#/admin/targetAudiences"
              size="medium"
            >
              {strings.buttons?.create || 'Create Target Audiences'}
            </Button>
            )}
          </Box>
        </FormField>
        )}
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          loading={updating}
          loadingBubbleColor="white"
          onClick={() => updateProject(entityState, 'Target Audiences', updateFn, loadDataFn)}
          size="large"
        >
          {strings.buttons?.save || 'Save'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

ManageProjectTargetAudiencesModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  disableAnimation: PropTypes.bool,
  disableOverlay: PropTypes.bool,
  modalKey: PropTypes.string,
  project: PropTypes.object.isRequired,
  showModal: PropTypes.bool,
}

ManageProjectTargetAudiencesModal.defaultProps = {
  modalKey: 'ManageProjectTargetAudiencesModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <ManageProjectTargetAudiencesModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
