import { getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'

function useFocusMode() {
  const { focusMode } = parsePermittedQueryParams(getQueryParamsFromHash(), ['focusMode'])

  return {
    enabled: focusMode === 'true',
  }
}

export default useFocusMode
