import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useLatestEntity, useWatchEntityUpdates } from '@campaignhub/react-hooks'
import { sortArrayBy } from '@campaignhub/javascript-utils'

import { ShareLinkModel } from '@models/shareLink'
import useReduxAction from '@hooks/useReduxAction'

const watchEntityKeys = ['digitalPageViews']

export const defaultRequestOptions = {
  shareLink: {
    include_share_link_analytics: true,
    include_share_link_digital_page_views: true,
  },
}

function useShareLinkAnalytics(initShareLink: Partial<ShareLinkModel> = {}) {
  const { entity: shareLink } = useLatestEntity(initShareLink, 'shareLinks')

  useReduxAction('shareLinks', 'loadShareLink', defaultRequestOptions.shareLink, [shareLink.id], {
    dispatchAction: (action, requestOptions) => action(shareLink.id, requestOptions),
    shouldPerformFn: (entityReducer) => {
      const { loadedIds, loading, errors } = entityReducer
      return shareLink.id && !loading && !loadedIds?.includes(shareLink.id) && !errors.length
    },
  })

  const entities = useSelector(reduxState => reduxState.entities)
  const { digitalPageViews } = entities

  const { loading } = useSelector(reduxState => reduxState.shareLinks)

  const {
    updatedEntities: { digitalPageViews: digitalPageViewsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const filteredPageViews = useMemo(() => {
    const filtered = Object.values(digitalPageViews).filter(pageView => pageView.share_link_id === shareLink.id)

    return sortArrayBy(filtered, 'asc', 'created_at')
  }, [digitalPageViewsUpdatedAt, shareLink.id])

  return {
    filteredPageViews,
    loading,
    shareLink,
  }
}

export default useShareLinkAnalytics
