import { useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  Button,
  FormField,
  Link,
  LoadingModule,
  SectionDivider,
  SidebarModal,
  SidebarNoticeBox,
} from '@campaignhub/suit-theme'

import useBrandSelector from '@hooks/useBrandSelector'
import useCurrentUser from '@hooks/useCurrentUser'
import useLocalization from '@hooks/useLocalization'
import useOrganizationSelector from '@hooks/useOrganizationSelector'

import EntitySelector from '@components/EntitySelector'
import IntegrationsBlankState from '@components/IntegrationsBlankState'

import useImportAssetLibraries from './hooks/useImportAssetLibraries'

import ImageLibrary from './components/ImageLibrary'

import localizedStrings from './localizedStrings'

const ImportAssetLibrariesModal = (props) => {
  const { callbacks, modalKey, showModal } = props
  const { closeModal, importImageLibraries } = callbacks

  const { isAdmin } = useCurrentUser()

  const importAssetLibrariesPayload = useImportAssetLibraries()

  const {
    alreadyImportedExternalIds,
    callbacks: {
      bulkImportFromExternalPlatform, setState, toggleAllAssetLibraries, toggleAssetLibrary,
    },
    externalImageLibraries,
    filteredPlatforms,
    hasExternalImageLibraries,
    hasIntegrations,
    hasSelectedImageLibraries,
    integrationsUrls: { integrationsUrl },
    loading,
    ownerId,
    ownerType,
    selectedImageLibrariesIds,
    selectedIntegrationPlatform,
    selectedIntegrationPlatformKey,
  } = importAssetLibrariesPayload

  // Owner can be either brand or an organization
  const { selectedOrganization, sortedOrganizationsWhereAdmin } = useOrganizationSelector()

  const { selectedBrand } = useBrandSelector({ autoSelect: true })

  // set default owner id and owner type for brand or organization
  useEffect(() => {
    if (selectedOrganization.id){
      setState({ ownerId: selectedOrganization.id, ownerType: 'Organization' })
    }
    if (selectedBrand.id){
      setState({ ownerId: selectedBrand.id, ownerType: 'Brand' })
    }
  }, [])

  const sortedOrganizations = sortedOrganizationsWhereAdmin.length
    ? sortedOrganizationsWhereAdmin
    : [selectedOrganization]

  const { callbacks: { formatString }, strings } = useLocalization(localizedStrings)

  const importImageLibrariesPayload = {
    callbacks: {
      action: bulkImportFromExternalPlatform,
      afterAction: closeModal,
    },
    entityParams: selectedImageLibrariesIds,
    toastText: strings.toast?.importImageLibraries || 'Import Image Libraries Queued',
  }
  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.title || 'Import'}
        titleSecondLine={strings.titleSecondLine || 'Image Libraries'}
      />

      <SidebarModal.Content>

        <LoadingModule loading={loading && !hasIntegrations} />

        {!loading && !hasIntegrations && (
        <IntegrationsBlankState
          buttonUrl={integrationsUrl}
          paragraph={
              strings?.blankState?.paragraph
              // eslint-disable-next-line max-len
              || 'To import asset libraries from another platform you’ll need to set up a suitable integration. Integrations can be managed in your client admin.'
            }
          sidebar
        />
        )}

        {hasIntegrations && (
          <>
            {!isAdmin && (
            <FormField
              direction="column"
              label={strings.importImageLibrariesIntoLabel || 'Importing Image Libraries into'}
            >
              <select
                data-validate-field-on="change"
                name="selected_organization_id"
                onChange={e => setState({ ownerId: Number(e.target.value), ownerType: 'Organization' })}
                value={ownerId}
              >
                <option value="">{strings.formFieldOption || 'Please Select...'}</option>
                {sortedOrganizations.map(organization => (
                  <option key={organization.id} value={organization.id}>
                    {organization.title}
                  </option>
                ))}
              </select>
            </FormField>
            )}

            {isAdmin && (
              <FormField
                direction="column"
                label="Select Owner Type"
              >
                <select
                  data-validate-field-on="change"
                  name="selected_owner_type"
                  onChange={e => setState({ ownerType: e.target.value })}
                  value={ownerType}
                >
                  <option value="">{strings.formFieldOption || 'Please Select...'}</option>
                  <option value="Brand">Brand</option>
                  <option value="Organization">Organization</option>
                </select>
              </FormField>
            )}

            {isAdmin && ownerType === 'Brand' && (
              <EntitySelector
                boxProps={{ marginTop: 'large' }}
                callbacks={{
                  selectItem: brand => setState({ ownerId: brand?.id }),
                }}
                entityKey="brands"
                selectedItemId={ownerId}
              />
            )}
            {isAdmin && ownerType === 'Organization' && (
              <EntitySelector
                boxProps={{ marginTop: 'large' }}
                callbacks={{
                  selectItem: organization => setState({ ownerId: organization?.id }),
                }}
                entityKey="organizations"
                selectedItemId={ownerId}
              />
            )}

            <FormField direction="column" label={strings.platformLabel || 'Platform'} marginTop="large">
              <select
                data-validate-field-on="change"
                onChange={e => setState({ selectedIntegrationPlatformKey: e.target.value })}
                value={selectedIntegrationPlatformKey}
              >
                <option value="">{strings.options || 'Please Select...'}</option>
                {filteredPlatforms.map(entity => (
                  <option key={entity.id} value={entity.key}>
                    {entity.title}
                  </option>
                ))}
              </select>
            </FormField>

            {/* Loading External Image Libraries */}
            <LoadingModule boxProps={{ marginTop: 'large' }} loading={loading && !!ownerId && !!ownerType} />

            {!loading && (
              <SectionDivider boxProps={{ marginTop: 'large' }}>
                {strings.availableImageLibraries || 'Available Image Libraries'}
              </SectionDivider>
            )}

            {!loading && !hasExternalImageLibraries && (
            <SidebarNoticeBox boxProps={{ marginTop: 'large' }}>
              <SidebarNoticeBox.Title>{strings.noticeBox?.title || 'Nothing to Import'}</SidebarNoticeBox.Title>

              <SidebarNoticeBox.Paragraph>
                {formatString(strings.noticeBox?.paragraph, {
                  integrationPlatformTitle:
                      selectedIntegrationPlatform.title || strings.externalPlatform || 'External Platform',
                })}
              </SidebarNoticeBox.Paragraph>
            </SidebarNoticeBox>
            )}

            {!loading && hasExternalImageLibraries && (
            <FormField
              direction="column"
              label={strings.selectImageLibrariesLabel || 'Select Image Libraries'}
              labelRight={(
                <Link onClick={() => toggleAllAssetLibraries()} textProps={{ fontSize: 'xsmall' }}>
                  {hasSelectedImageLibraries
                    ? strings.deselectAllButton || 'Deselect All'
                    : strings.selectAllButton || 'Select All'}
                </Link>
                )}
              marginTop="large"
            >
              {externalImageLibraries.map((imageLibrary) => {
                const { id } = imageLibrary

                return (
                  <ImageLibrary
                    alreadyImported={alreadyImportedExternalIds.includes(id)}
                    callbacks={{ toggleAssetLibrary: () => toggleAssetLibrary(id) }}
                    assetLibrary={imageLibrary}
                    checked={selectedImageLibrariesIds.includes(id)}
                    key={id}
                  />

                )
              })}
            </FormField>
            )}
          </>
        )}

      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!hasSelectedImageLibraries}
          onClick={() => importImageLibraries(importImageLibrariesPayload)}
          size="large"
        >
          {strings.importImageLibrariesButton || 'Import Image Libraries'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

ImportAssetLibrariesModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

ImportAssetLibrariesModal.defaultProps = {
  modalKey: 'ImportAssetLibrariesModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <ImportAssetLibrariesModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
