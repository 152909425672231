import { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartBar, faRobot, faList } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Columns, DashboardModule, LoadingModule, PageHeader, Tools,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import useDigitalPageMetricCollections from '@hooks/useDigitalPageMetricCollections'

import PageFilterModule from '@components/PageFilterModule'

import DigitalPageMetricCollection from '../DigitalPageMetricCollection'
import MetricCollectionBlankState from '../MetricCollectionBlankState'

const buildPageFilterFields = () => {
  const style = {
    marginBottom: 16,
  }

  const boxProps = {
    marginBottom: 'large',
  }

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: 'All',
        inputComponent: 'select',
        style,
      },
      key: 'owner_type',
      label: 'Entity Type',
      values: [
        { key: 'Brand', label: 'Brand' },
        { key: 'Organization', label: 'Organization' },
        { key: 'System', label: 'System' },
      ],
    },
    {
      callbacks: {
        shouldRender: state => state.owner_type === 'Brand',
      },
      component: 'EntitySelector',
      componentProps: {
        entityKey: 'brands',
        boxProps,
      },
      key: 'owner_id',
    },
    {
      callbacks: {
        shouldRender: state => state.owner_type === 'Organization',
      },
      component: 'EntitySelector',
      componentProps: {
        entityKey: 'organizations',
        boxProps,
      },
      key: 'owner_id',
    },
    {
      component: 'DynamicInput',
      componentProps: {
        dateFormat: 'DD/MM/YYYY',
        dateParseFormat: 'YYYY-MM-DD',
        fieldName: 'start_date',
        inputType: 'date',
        style,
      },
      key: 'start_date',
      label: 'Start Date',
    },
    {
      component: 'DynamicInput',
      componentProps: {
        dateFormat: 'DD/MM/YYYY',
        dateParseFormat: 'YYYY-MM-DD',
        fieldName: 'end_date',
        inputType: 'date',
        style,
      },
      key: 'end_date',
      label: 'End Date',
    },
  ]
}

const PageContent = (props) => {
  const { pageFilters } = props

  const digitalPageMetricCollectionPayload = useDigitalPageMetricCollections({
    filters: {
      ...pageFilters,
    },
    requestOptions: {
      include_digital_page_metric_collections_owner_title: true,
    },
    performHttpRequests: true,
  })
  const {
    callbacks: { loadMore },
    canLoadMore,
    filteredDigitalPageMetricCollections,
    filteredDigitalPageMetricCollectionsCount,
    hasfilteredDigitalPageMetricCollections,
    loading,
  } = digitalPageMetricCollectionPayload

  const pageContext = useContext(PageContext)
  const {
    callbacks: { resetFilters, showBuildDigitalPageMetricsModal, updateFilters },
  } = pageContext

  return (
    <>
      <Helmet>
        <title>Digital Page Metrics | Engage</title>
      </Helmet>

      <PageHeader
        activeTabBarItemKey="digital-page-metrics"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        tabBarItems={[
          {
            href: '#/systemManager/analytics',
            icon: faChartBar,
            key: 'analytics',
            title: 'Analytics',
          },
          {
            href: '#/systemManager/analytics/digitalPageMetrics',
            icon: faList,
            key: 'digital-page-metrics',
            title: 'Digital Page Metrics',
          },
        ]}
        title="Digital Page Metrics"
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && (
              <>
                {!hasfilteredDigitalPageMetricCollections && <MetricCollectionBlankState />}

                {hasfilteredDigitalPageMetricCollections && (
                  <DashboardModule title="Metric Collections">
                    <Box flexDirection="column">
                      <Box flexDirection="column">
                        {filteredDigitalPageMetricCollections.map(metricCollection => (
                          <DigitalPageMetricCollection
                            key={metricCollection.id}
                            digitalPageMetricCollection={metricCollection}
                          />
                        ))}
                      </Box>

                      <DashboardModule.LoadMoreFooter
                        callbacks={{ loadMore }}
                        canLoadMore={canLoadMore}
                        entityCount={filteredDigitalPageMetricCollectionsCount}
                        loading={loading}
                      />
                    </Box>
                  </DashboardModule>
                )}
              </>
            )}
          </Columns.Main>

          <Columns.Sidebar>
            <Tools>
              <Button
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faRobot} />}
                marginBottom="medium"
                onClick={() => showBuildDigitalPageMetricsModal()}
                size="medium"
              >
                Build Metrics
              </Button>
            </Tools>

            <PageFilterModule
              callbacks={{ resetFilters, updateFilters }}
              filterFields={buildPageFilterFields()}
              pageFilters={pageFilters}
              title="Filters"
            />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

PageContent.propTypes = {
  pageFilters: PropTypes.object.isRequired,
}

export default PageContent
