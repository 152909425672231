import { useLatestEntity } from '@campaignhub/react-hooks'

import { digObject } from '@campaignhub/javascript-utils'

function useDigitalPageFeature(featureKey, initShortcodeData){
  const { entity: digitalPageShortcodeData } = useLatestEntity(initShortcodeData, 'digitalPageShortcodeData')

  const featureStatuses = digObject(digitalPageShortcodeData, 'feature_completion_statuses', {})
  const featureCompleted = featureStatuses[featureKey] || false

  return {
    featureCompleted,
  }
}

export default useDigitalPageFeature
