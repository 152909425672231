export default {
  default: {
    buttons: {
      create: 'Create',
    },
    upload: 'Upload Files',
    addButton: 'Add File',
    title: 'Files',
    manage: 'Manage',
  },
}
