import React from 'react'
import { Route, Routes } from 'react-router-dom'

import EditUser from './screens/EditUser'
import UsersList from './screens/UsersList'

const Users = () => (
  <Routes>
    <Route index element={<UsersList />} />
    <Route path=":userId/edit" element={<EditUser />} />
  </Routes>
)

export default Users
