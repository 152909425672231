export default {
  default: {
    title: 'Quotes',
    createButton: 'Create',
    videoModule: {
      title: 'How to Import a Quote From Realhub',
      paragraph: "Add a Realhub Marketing Quote into your Proposals. The quotes will then appear in your proposals, on brand. You can also edit the Quote within Engage if you'd like things to be shown/hidden last minute.",
    },
  },
}
