import { useContext, useMemo } from 'react'
import cloneDeep from 'lodash/cloneDeep'

import {
  useForm, useLatestEntity, useWatchEntityUpdates,
} from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import {
  digObject, launchModal, multiFilteredObjectArraySelector, sortArrayBy,
} from '@campaignhub/javascript-utils'

import useDispatch from '@hooks/useDispatch'
import useSelector from '@hooks/useSelector'

import * as assetLibraryActions from '@redux/modules/assetLibrary'

import defaultFormState, { requiredFields } from '@models/assetLibrary'

import PageContext from '@contexts/pageContext'

import type { AppDispatch } from '@redux/store'
import type { DeleteParams } from '@redux/modules/types'
import type { AssetLibraryModel, AssetLibraryRequestOptions } from '@models/types'

const watchEntityKeys = ['attachments', 'images']

export const generateUrls = (assetLibrary?: Partial<AssetLibraryModel>) => {
  const { asset_type } = assetLibrary || {}

  return {
    editAssetLibraryUrl: `#/systemManager/${asset_type}Libraries`,
  }
}

type CreateAssetLibraryParams = {
  assetLibraryParams: Partial<AssetLibraryModel>,
  dispatch: AppDispatch,
  requestOptions?: AssetLibraryRequestOptions,
}

const createAssetLibrary = (params: CreateAssetLibraryParams) => {
  const { dispatch, assetLibraryParams, requestOptions } = params
  const { createAssetLibrary: createFn } = assetLibraryActions

  const { options } = assetLibraryParams
  const newAssetLibrary = {
    ...assetLibraryParams,
    options: JSON.stringify(options),
  }

  return dispatch(createFn(newAssetLibrary, requestOptions))
}

type UpdateAssetLibraryParams = {
  assetLibrary: AssetLibraryModel,
  assetLibraryParams: Partial<AssetLibraryModel>,
  dispatch: AppDispatch,
  requestOptions?: AssetLibraryRequestOptions,
}

const updateAssetLibrary = (params: UpdateAssetLibraryParams) => {
  const {
    dispatch, assetLibrary, assetLibraryParams, requestOptions,
  } = params
  const { updateAssetLibrary: updateFn } = assetLibraryActions

  const { options } = assetLibraryParams

  const updatedParams = {
    id: assetLibrary.id,
    ...assetLibraryParams,
  }

  if (options){
    const assetLibraryOptions = assetLibrary.options || {}
    updatedParams.options = JSON.stringify({
      ...cloneDeep(assetLibraryOptions),
      ...options,
    })
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

type DeleteAssetLibraryParams = {
  assetLibrary: DeleteParams<AssetLibraryModel>,
  dispatch: AppDispatch,
}

const deleteAssetLibrary = (params: DeleteAssetLibraryParams) => {
  const { dispatch, assetLibrary } = params
  const { deleteAssetLibrary: deleteFn } = assetLibraryActions

  return dispatch(deleteFn(assetLibrary))
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useAssetLibraryForm(
  assetLibrary: Partial<AssetLibraryModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const assetLibraryForm = useForm(
    defaultFormState,
    { entity: assetLibrary, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [assetLibrary.id, assetLibrary.cache_key],
  )

  return {
    ...assetLibraryForm,
  }
}

function useAssetLibrary(initEntity: Partial<AssetLibraryModel> = {}) {
  const { entity: assetLibrary }: { entity: AssetLibraryModel} = useLatestEntity(initEntity, 'assetLibraries')
  const { asset_type, dates } = assetLibrary || {}

  const {
    updatedEntities: {
      attachments: attachmentsUpdatedAt,
      images: imagesUpdatedAt,
    },
  } = useWatchEntityUpdates(watchEntityKeys)

  const dispatch = useDispatch()

  const { callbacks } = useContext(PageContext)

  const {
    assetLibraries: { creating, loading, updating },
    entities,
  } = useSelector(reduxState => reduxState)

  const attachmentFilters = [
    { key: 'subject_type', value: 'AssetLibrary' },
    { key: 'subject_id', value: assetLibrary.id },
  ]

  const filteredAttachments = useMemo(
    () => multiFilteredObjectArraySelector({ entities }, 'attachments', attachmentFilters),
    [attachmentsUpdatedAt, assetLibrary.id],
  )

  const imageFilters = [
    { key: 'subject_type', value: 'AssetLibrary' },
    { key: 'subject_id', value: assetLibrary.id },
  ]

  const filteredImages = useMemo(() => {
    const filtered = multiFilteredObjectArraySelector({ entities }, 'images', imageFilters)
    const sorted = sortArrayBy(filtered, 'asc', 'sort')

    return sorted
  }, [imagesUpdatedAt, assetLibrary.id])

  const defaultImage = filteredImages[0] || {}

  const createdDateTime = digObject(dates, 'created.date_time_with_timezone')

  const isFileLibrary = asset_type === 'file'
  const isImageLibrary = asset_type === 'image'

  const hasAttachments = !!filteredAttachments.length
  const hasImages = !!filteredImages.length

  const hasAssets = hasAttachments || hasImages

  return {
    assetLibrary,
    asset_type,
    attachments: filteredAttachments,
    callbacks: {
      createAssetLibrary: (
        assetLibraryParams: Partial<AssetLibraryModel>,
        entityOptions?: AssetLibraryRequestOptions,
      ) => (
        createAssetLibrary({ assetLibraryParams, dispatch, requestOptions: entityOptions })
      ),
      createOrEditAssetLibrary: (customPayload: {}) => launchModal({
        callbacks,
        modalKey: 'CreateOrEditAssetLibraryModal',
        payload: { assetLibrary, ...customPayload },
      }),
      deleteAssetLibrary: () => deleteAssetLibrary({ dispatch, assetLibrary }),
      updateAssetLibrary: (
        assetLibraryParams: Partial<AssetLibraryModel>,
        entityOptions?: AssetLibraryRequestOptions,
      ) => (
        updateAssetLibrary({
          assetLibrary, assetLibraryParams, dispatch, requestOptions: entityOptions,
        })
      ),
      createOrEditDataSet: (customPayload: {}) => launchModal({
        callbacks,
        modalKey: 'CreateOrEditDataSetModal',
        payload: { assetLibrary, ...customPayload },
      }),
    },
    createdDateTime,
    creating,
    defaultImage,
    fileLibrary: isFileLibrary ? assetLibrary : {},
    hasAssets,
    hasAttachments,
    hasImages,
    imageLibrary: isImageLibrary ? assetLibrary : {},
    images: filteredImages,
    isFileLibrary,
    isImageLibrary,
    loading,
    updating,
    urls: generateUrls(assetLibrary),
  }
}

export default useAssetLibrary
