import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'

import { Box, LoadingModule } from '@campaignhub/suit-theme'

import AgreementBlankState from '../AgreementBlankState'

import styles from './styles.module.scss'

const PDFPreview = (props) => {
  const {
    agreement: { urls },
    documentTemplatePage: { height, width },
    zoom,
  } = props

  const { external_pdf } = urls || {}

  const [numberOfPages, setNumberOfPages] = useState(null)

  const pageNumbers = [...Array(numberOfPages)].map((_, i) => i + 1)

  return (
    <Box height={height} width={width} flexShrink="0" margin="0 auto" position="relative">
      {external_pdf && (
        <Document
          file={external_pdf}
          loading={<LoadingModule loading times={3} boxProps={{ width }} />}
          onLoadSuccess={({ numPages }) => setNumberOfPages(numPages)}
          noData={<AgreementBlankState />}
        >
          {pageNumbers.map(pageNumber => (
            <Page className={styles.page} key={pageNumber} pageNumber={pageNumber} scale={zoom} />
          ))}
        </Document>
      )}
    </Box>
  )
}

PDFPreview.propTypes = {
  agreement: PropTypes.object.isRequired,
  documentTemplatePage: PropTypes.object.isRequired,
  zoom: PropTypes.number.isRequired,
}

export default PDFPreview
