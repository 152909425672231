export type CustomFieldSetModel = {
  cache_key: number,
  entity_type: string,
  id: number,
  key: string,
  options: {},
  owner_id: number,
  owner_title?: string,
  owner_type: string,
  title: string,
}

export type CustomFieldSetRequestOptions = {
  include_custom_field_set_fields?: boolean,
  include_custom_field_set_owner_title?: boolean,
}

const state = {
  entity_type: '',
  id: null,
  key: '',
  options: {
    filters: {},
  },
  owner_id: null,
  owner_title: '',
  owner_type: '',
  title: '',
}

export const requiredFields = [
  { key: 'entity_type' },
  { key: 'title' },
]

export default state
