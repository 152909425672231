import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Link, ListItem, SidebarModal,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useTeam from '@hooks/useTeam'

const DraggableTeam = React.forwardRef((props, ref) => {
  const {
    callbacks: { deleteTeam },
    teamId,
    dragHandleProps,
    dragSnapshot,
    draggableProps,
  } = props

  const { isDragging } = dragSnapshot || {}

  const { callbacks: { createOrEditTeam }, defaultImage, team } = useTeam({ id: teamId })
  const imageUrl = digObject(defaultImage, 'sizes.thumb_90x90', '')

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
      }}
      forwardProps={{
        ...draggableProps,
        ...dragHandleProps,
      }}
      isDragging={isDragging}
      key={teamId}
      ref={ref}
    >
      <Box flexDirection="column">
        <SidebarModal.User
          boxProps={{ paddingY: 'large' }}
          disableHover
          imageUrl={imageUrl}
          key={team.id}
          title={team.title}
        >
          <Link greyLink onClick={createOrEditTeam} textProps={{ fontSize: 'small', marginRight: 'medium' }}>
            <FontAwesomeIcon icon={faPencil} />
          </Link>

          <Link greyLink onClick={deleteTeam} textProps={{ fontSize: 'small' }}>
            <FontAwesomeIcon icon={faTrashAlt} />
          </Link>
        </SidebarModal.User>
      </Box>
    </ListItem>
  )
})

DraggableTeam.propTypes = {
  callbacks: PropTypes.shape({
    deleteTeam: PropTypes.func,
  }).isRequired,
  teamId: PropTypes.number.isRequired,
  dragHandleProps: PropTypes.object,
  dragSnapshot: PropTypes.object,
  draggableProps: PropTypes.object,
}

export default DraggableTeam
