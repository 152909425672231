import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'

import {
  Link, ListItem, SidebarModal,
} from '@campaignhub/suit-theme'

import { useRelations } from '@hooks/useUser'

const DraggableUser = React.forwardRef((props, ref) => {
  const {
    callbacks,
    disableDelete,
    dragHandleProps,
    dragSnapshot,
    draggableProps,
    user,
  } = props

  const { removeUser } = callbacks || {}
  const { full_name, id, job_title } = user

  const { isDragging } = dragSnapshot || {}

  const { defaultImage } = useRelations(user)
  const imageUrl = digObject(defaultImage, 'sizes.thumb_90x90', '')

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        backgroundColor: 'white',
        borderBottom: '1px dashed',
        borderColor: 'lineColor',
        paddingX: 'medium',
      }}
      forwardProps={{
        ...draggableProps,
        ...dragHandleProps,
      }}
      isDragging={isDragging}
      ref={ref}
    >
      <SidebarModal.User
        disableHover
        imageUrl={imageUrl}
        key={id}
        subtitle={job_title}
        title={full_name}
      >
        {removeUser && !disableDelete && (
          <Link
            greyLink
            onClick={() => removeUser(id)}
            textProps={{ fontSize: 'small' }}
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </Link>
        )}

        {(!removeUser || disableDelete) && (
          <Link
            greyLink
            textProps={{ fontSize: 'small' }}
          >
            <FontAwesomeIcon icon={faBars} />
          </Link>
        )}
      </SidebarModal.User>
    </ListItem>
  )
})

DraggableUser.propTypes = {
  callbacks: PropTypes.shape({
    removeUser: PropTypes.func,
  }),
  disableDelete: PropTypes.bool,
  dragHandleProps: PropTypes.object,
  dragSnapshot: PropTypes.object,
  draggableProps: PropTypes.object,
  user: PropTypes.object.isRequired,
}

export default DraggableUser
