import {
  Box, Checkbox, ListItem,
} from '@campaignhub/suit-theme'

import type { IntegrationModel } from '@models/integration'
import useIntegration from '@hooks/useIntegration'

interface IntegrationProps {
  bulkUpdateFeaturesPayload: {
    callbacks: {
      toggleIntegrationIds: (id: number) => void,
    },
    selectedIntegrationIds: number[],
  },
  integration: IntegrationModel,
}

const Integration = (props: IntegrationProps) => {
  const { bulkUpdateFeaturesPayload, integration: { id: integrationId } } = props

  const integrationPayload = useIntegration({ id: integrationId })
  const { ownerTitle } = integrationPayload

  const {
    callbacks: {
      toggleIntegrationIds,
    },
    selectedIntegrationIds,
  } = bulkUpdateFeaturesPayload

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        paddingY: 'medium',
      }}
      disableHover
      key={integrationId}
      onClick={() => toggleIntegrationIds(integrationId)}
    >
      <Checkbox
        checked={selectedIntegrationIds.includes(integrationId)}
        onClick={() => toggleIntegrationIds(integrationId)}
      />

      <Box color="bodyFontLightColor" fontSize="small">
        {ownerTitle}
      </Box>
    </ListItem>
  )
}

export default Integration
