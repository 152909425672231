import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { digObject } from '@campaignhub/javascript-utils'
import {
  Box, Button, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import useDocumentRecipient, { useDocumentRecipientForm } from '@hooks/useDocumentRecipient'
import useLocalization from '@hooks/useLocalization'

import AgreementRecipient from './screens/AgreementRecipient'
import DocumentTemplateRecipient from './screens/DocumentTemplateRecipient'

import localizedStrings from './localizedStrings'

const CreateOrEditDocumentRecipientModal = (props) => {
  const {
    callbacks,
    callbacks: { createDocumentRecipient, deleteAndReassignPageItems, updateDocumentRecipient },
    disableOverlay,
    documentRecipient: newDocumentRecipient,
    modalKey,
    showModal,
  } = props

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, `${modalKey}`, {})
  const { documentRecipient: existingDocumentRecipient } = modalPayload

  const documentRecipient = existingDocumentRecipient || newDocumentRecipient
  const newRecord = !documentRecipient.id

  const documentRecipientFormPayload = useDocumentRecipientForm(documentRecipient)
  const {
    creating,
    entityState,
    saveEnabled,
    updating,
  } = documentRecipientFormPayload

  const documentRecipientPayload = useDocumentRecipient(newRecord ? entityState : documentRecipient)
  const {
    callbacks: {
      createDocumentRecipient: createFn,
      deleteAndReassignPageItems: deleteFn,
      updateDocumentRecipient: updateFn,
    },
  } = documentRecipientPayload

  const actionFn = newRecord
    ? () => createDocumentRecipient(entityState, createFn)
    : () => updateDocumentRecipient(entityState, updateFn)

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal
      callbacks={callbacks}
      disableOverlay={disableOverlay}
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header
        callbacks={callbacks}
        title={
          newRecord
            ? strings.sideBarModalHeader?.titleCreate || 'Create'
            : strings.sideBarModalHeader?.titleUpdate || 'Update'
        }
        titleSecondLine={strings.sideBarModalHeader?.titleSecondLine || 'Recipient'}
      />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          {documentRecipient.owner_type === 'Agreement' && (
            <AgreementRecipient
              agreementId={documentRecipient.owner_id}
              documentRecipientFormPayload={documentRecipientFormPayload}
              documentRecipient={documentRecipient}
            />
          )}

          {documentRecipient.owner_type === 'DocumentTemplate' && (
            <DocumentTemplateRecipient
              callbacks={{ deleteAndReassignPageItems: targetId => deleteAndReassignPageItems(targetId, deleteFn) }}
              documentRecipient={documentRecipient}
              documentRecipientFormPayload={documentRecipientFormPayload}
              documentRecipientPayload={documentRecipientPayload}
            />
          )}
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={creating || updating}
          onClick={actionFn}
          size="large"
        >
          {strings.buttons?.save || 'Save Recipient'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

CreateOrEditDocumentRecipientModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  disableOverlay: PropTypes.bool,
  documentRecipient: PropTypes.object,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

CreateOrEditDocumentRecipientModal.defaultProps = {
  modalKey: 'CreateOrEditDocumentRecipientModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <CreateOrEditDocumentRecipientModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
