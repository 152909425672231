import React from 'react'
import PropTypes from 'prop-types'

import { Box, DashboardModule, FormField } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import countryCallingCodes from '@functions/countryCallingCodes'

import FormColumn from '@components/FormColumn'
import FormRow from '@components/FormRow'

import localizedStrings from './localizedStrings'

const Details = (props) => {
  const { userFormPayload } = props

  const {
    entityState: {
      country_calling_code, email, first_name, job_title, last_name, legal_name, mobile,
    },
    errors,
    handlers,
  } = userFormPayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <DashboardModule title={strings.title}>
      <Box flexDirection="column" padding="large">
        <FormRow>
          <FormColumn firstColumn>
            <FormField label={strings.formFieldLabels?.firstName || '* First Name'} errorMessage={errors.first_name}>
              <input type="text" name="first_name" value={first_name} {...handlers} />
            </FormField>
          </FormColumn>

          <FormColumn>
            <FormField label={strings.formFieldLabels?.lastName || '* Last Name'} errorMessage={errors.last_name}>
              <input type="text" name="last_name" value={last_name} {...handlers} />
            </FormField>
          </FormColumn>
        </FormRow>

        <FormRow>
          <FormColumn firstColumn>
            <FormField label={strings.formFieldLabels?.legalName || 'Legal Name'}>
              <input type="text" name="legal_name" value={legal_name} {...handlers} />
            </FormField>
          </FormColumn>

          <FormColumn>
            <FormField label={strings.formFieldLabels?.jobTitle || 'Job Title'}>
              <input type="text" name="job_title" value={job_title} {...handlers} />
            </FormField>
          </FormColumn>
        </FormRow>

        <FormRow>
          <FormColumn firstColumn>
            <FormField errorMessage={errors.email} label={strings.formFieldLabels?.email || '* Email Address'}>
              <input type="email" name="email" value={email} {...handlers} />
            </FormField>
          </FormColumn>

          <FormColumn>
            <Box flexDirection={['column', 'row']}>
              <FormField
                boxProps={{ flex: 1 }}
                errorMessage={errors.country_calling_code || errors.mobile}
                label={strings.formFieldLabels?.mobile || '* Phone'}
              >
                <Box style={{ width: '100%', marginRight: 'small', flexDirection: 'row' }}>
                  <select
                    name="country_calling_code"
                    style={{ marginRight: 5 }}
                    value={country_calling_code}
                    {...handlers}
                  >
                    <option value="">{strings.formFieldLabels?.select || 'Select...'}</option>

                    {countryCallingCodes.map(({ key, value, title }) => (
                      <option key={key} value={value}>
                        {title}
                      </option>
                    ))}
                  </select>

                  <FormField style={{ width: '70%' }}>
                    <input type="text" value={mobile} name="mobile" {...handlers} />
                  </FormField>
                </Box>
              </FormField>
            </Box>
          </FormColumn>
        </FormRow>
      </Box>
    </DashboardModule>
  )
}

Details.propTypes = {
  features: PropTypes.shape({
    canEditUser: PropTypes.bool,
  }),
  userFormPayload: PropTypes.object.isRequired,
}

export default Details
