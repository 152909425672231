import { useContext } from 'react'

import { useForm, useLatestEntity } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import useDispatch from '@hooks/useDispatch'
import useSelector from '@hooks/useSelector'

import * as addressActions from '@redux/modules/address'

import defaultFormState, { requiredFields } from '@models/address'

import PageContext from '@contexts/pageContext'

import type { AppDispatch } from '@redux/store'
import type { AddressModel, AddressRequestOptions } from '@models/types'

type CreateAddressParams = {
  addressParams: Partial<AddressModel>,
  dispatch: AppDispatch,
  requestOptions?: AddressRequestOptions,
}

const createAddress = (params: CreateAddressParams) => {
  const { addressParams, dispatch, requestOptions } = params
  const { createAddress: createFn } = addressActions

  return dispatch(createFn(addressParams, requestOptions))
}

type UpdateAddressParams = {
  address: AddressModel,
  addressParams: Partial<AddressModel>,
  dispatch: AppDispatch,
  requestOptions?: AddressRequestOptions,
}

const updateAddress = (params: UpdateAddressParams) => {
  const {
    address, addressParams, dispatch, requestOptions,
  } = params
  const { updateAddress: updateFn } = addressActions

  const updatedParams = {
    id: address.id,
    ...addressParams,
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

type CreateOrEditAddressParams = {
  dispatch: AppDispatch,
  showCreateOrEditAddressModal: (payload: {}) => void,
  address: AddressModel,
  entityState: {
    address_attributes?: Partial<AddressModel>,
  },
  setEntityState: ({ address_attributes }: { address_attributes: Partial<AddressModel> }) => void,
}

const createOrEditAddress = (params: CreateOrEditAddressParams) => new Promise((resolve, reject) => {
  const {
    dispatch, showCreateOrEditAddressModal, address, entityState, setEntityState,
  } = params

  if (showCreateOrEditAddressModal){
    const { address_attributes } = entityState || {}
    const hasAddressAttributes = address_attributes ? !!Object.keys(address_attributes).length : false

    const defaultPayload = {
      address: hasAddressAttributes ? address_attributes : address,
      callbacks: {
        createAddress: (
          addressParams: Partial<AddressModel>,
          requestOptions: AddressRequestOptions,
        ) => createAddress({ addressParams, dispatch, requestOptions }),
        updateAddress: (
          updatedParams: Partial<AddressModel>,
          requestOptions: AddressRequestOptions,
        ) => updateAddress({
          address, addressParams: updatedParams, dispatch, requestOptions,
        }),
      },
    }

    const entityStatePayload = {
      address: hasAddressAttributes ? address_attributes : address,
      callbacks: {
        createAddress: (newAddress: Partial<AddressModel>) => {
          setEntityState({ address_attributes: newAddress })
          return Promise.resolve({ success: true })
        },
        updateAddress: (newAddress: Partial<AddressModel>) => {
          setEntityState({ address_attributes: newAddress })
          return Promise.resolve({ success: true })
        },
      },
    }

    const payload = entityState ? entityStatePayload : defaultPayload

    showCreateOrEditAddressModal(payload)

    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showCreateOrEditAddressModal not defined in PageContext callbacks'))
})

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useAddressForm(
  address: Partial<AddressModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const addressForm = useForm(
    defaultFormState,
    { entity: address, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [address.id, address.cache_key],
  )

  return {
    ...addressForm,
  }
}

export function createStreetAddressString(address: Partial<AddressModel>) {
  const { street_name, street_number, unit_number } = address

  const streetAddress = unit_number
    ? `${unit_number}/${street_number} ${street_name}`
    : `${street_number} ${street_name}`

  return streetAddress
}

function useAddress(initEntity: Partial<AddressModel> = {}) {
  const { entity: address }: { entity: AddressModel} = useLatestEntity(initEntity, 'addresses')

  const streetAddress = createStreetAddressString(address)

  const dispatch = useDispatch()

  const { callbacks } = useContext(PageContext)
  const { showCreateOrEditAddressModal } = callbacks || {}

  const { creating, loading, updating } = useSelector(reduxState => reduxState.addresses)

  return {
    address,
    callbacks: {
      createAddress: (
        addressParams: Partial<AddressModel>,
        entityOptions?: AddressRequestOptions,
      ) => (createAddress({ addressParams, dispatch, requestOptions: entityOptions })),
      createOrEditAddress: (
        entityState: {
          address_attributes?: Partial<AddressModel>,
        },
        setEntityState: ({ address_attributes }: { address_attributes: Partial<AddressModel> }) => void,
      ) => createOrEditAddress({
        dispatch, showCreateOrEditAddressModal, address, entityState, setEntityState,
      }),
      updateAddress: (
        addressParams: Partial<AddressModel>,
        entityOptions?: AddressRequestOptions,
      ) => (updateAddress({
        address, addressParams, dispatch, requestOptions: entityOptions,
      })
      ),
    },
    creating,
    loading,
    streetAddress,
    updating,
  }
}

export default useAddress
