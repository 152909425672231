import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import PageContext from '@contexts/pageContext'

import getSortedSectionKeys from '@components/digitalRenderer/utils/getSortedSectionKeys'

import SortableSectionWrapper from './SortableSectionWrapper'

const sortComponents = (sortedKeys, components) => {
  if (sortedKeys && sortedKeys.length){
    return sortedKeys.map(key => components.find(c => c.key === key))
  }

  return components
}

const updateDigitalPageOptions = (context, name, value) => {
  const {
    callbacks: { updateDigitalPage: updateFn },
    digitalPage: { id, options: currentOptions },
  } = context

  const updated = {
    id,
    options: {
      ...currentOptions,
      [name]: value,
    },
  }

  updateFn(updated)
}

const moveComponent = (context, props, key, direction) => {
  const { components } = props
  const { digitalPage } = context

  const sortedKeys = [...getSortedSectionKeys(digitalPage, components)]
  const index = sortedKeys.indexOf(key)

  if ((index === 0 && direction === 'up') || (index + 1 === sortedKeys.length && direction === 'down')) return

  const newIndex = direction === 'up' ? index - 1 : index + 1
  sortedKeys.splice(newIndex, 0, sortedKeys.splice(index, 1)[0])

  updateDigitalPageOptions(context, 'sorted_section_keys', sortedKeys)
}

const wrapComponents = (components, context, props) => components.map(
  component => component && (
    <SortableSectionWrapper
      key={component.key}
      updateCallback={(key, direction) => moveComponent(context, props, key, direction)}
    >
      {component}
    </SortableSectionWrapper>
  ),
)

const SortableSectionsWrapper = (props) => {
  const { components, render, isSortable } = props

  const pageContext = useContext(PageContext)
  const { digitalPage, editing } = pageContext

  const sortedKeys = getSortedSectionKeys(digitalPage, components)
  const sortedComponents = sortComponents(sortedKeys, components)

  if (!components.length) return null

  return render({
    sortedComponents:
      editing && isSortable ? wrapComponents(sortedComponents, pageContext, props) : sortedComponents,
  })
}

SortableSectionsWrapper.propTypes = {
  components: PropTypes.array.isRequired,
  render: PropTypes.func.isRequired,
  isSortable: PropTypes.bool,
}

SortableSectionsWrapper.defaultProps = {
  isSortable: true,
}

export default SortableSectionsWrapper
