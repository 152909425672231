import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'

import { digObject } from '@campaignhub/javascript-utils'
import {
  Box, Button, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'
import useManageDocumentRecipients from './hooks/useManageDocumentRecipients'

import DocumentRecipients from './components/DocumentRecipients'
import UnassignedRecipients from './components/UnassignedRecipients'

import localizedStrings from './localizedStrings'

const confirmDelete = (params) => {
  const { deleteDocumentRecipient, deleteFn, strings } = params

  swal
    .fire({
      title: strings.swal?.title || 'Delete Recipient?',
      text: strings.swal?.text || 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: strings.swal?.confirmButtonText || 'Yes, delete it.',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        deleteDocumentRecipient(deleteFn)
      }
    })
}

const ManageDocumentRecipientsModal = (props) => {
  const {
    callbacks,
    callbacks: { deleteDocumentRecipient, updateRecipientSortOrder },
    disableOverlay,
    modalKey,
    ownerId,
    ownerType,
    showModal,
  } = props

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, `${modalKey}`, {})
  const { documentTemplate, callbacks: modalCallbacks } = modalPayload
  const { loadShortcodeData: loadShortcodeDataFn, updateRecipientSortOrder: updateFn } = modalCallbacks || {}

  const manageRecipientsPayload = useManageDocumentRecipients({ documentTemplate, ownerId, ownerType })
  const { sortedRecipientIds, updating } = manageRecipientsPayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal
      callbacks={callbacks}
      disableOverlay={disableOverlay}
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.sideBarModalHeader?.title || 'Manage'}
        titleSecondLine={strings.sideBarModalHeader?.titleSecondLine || 'Recipients'}
      />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <DocumentRecipients
            callbacks={{
              deleteDocumentRecipient: deleteFn => confirmDelete({ deleteDocumentRecipient, deleteFn, strings }),
            }}
            manageRecipientsPayload={manageRecipientsPayload}
          />

          <UnassignedRecipients
            manageRecipientsPayload={manageRecipientsPayload}
            ownerId={ownerId}
            ownerType={ownerType}
          />
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          loading={updating}
          onClick={() => updateRecipientSortOrder(sortedRecipientIds, updateFn, loadShortcodeDataFn)}
          size="large"
        >
          {strings.buttons?.save || 'Save Recipients'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

ManageDocumentRecipientsModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  disableOverlay: PropTypes.bool,
  modalKey: PropTypes.string,
  ownerId: PropTypes.number.isRequired,
  ownerType: PropTypes.string.isRequired,
  showModal: PropTypes.bool,
}

ManageDocumentRecipientsModal.defaultProps = {
  modalKey: 'ManageDocumentRecipientsModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <ManageDocumentRecipientsModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
