export default {
  default: {
    agreement: {
      paragraph: 'This {global.projectType.shortName.singular|l|} has no agreements. You can create one now from an existing proposal or from the button at the top of the page.',
    },
    all: {
      paragraph: 'This {global.projectType.shortName.singular|l|} has no pages. You can create one now from the button at the top of the page.',
    },
    form: {
      paragraph: 'This {global.projectType.shortName.singular|l|} has no forms. You can create one now from an existing proposal or from the button at the top of the page.',
    },
    microsite: {
      paragraph: 'This {global.projectType.shortName.singular|l|} has no homepages. You can create one now. It will bring data from your existing digital pages and {global.projectType.shortName.singular|l|}.',
    },
    proposal: {
      paragraph: 'Create a proposal to get started and send it to your client. Proposals can be used to win listings, or educate and inform potential customers.',
    },
    title: 'No {global.digitalTemplateType.title.plural|l|} Created',
  },
}
