import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'

import { ModalContext, SidebarModal } from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'
import { useSetState } from '@campaignhub/react-hooks'

import useAddress from '@hooks/useAddress'
import useIntegrations from '@hooks/useIntegrations'
import useLocalization from '@hooks/useLocalization'
import useOrganizationSelector from '@hooks/useOrganizationSelector'
import useProject, { useProjectForm } from '@hooks/useProject'

import ImportProject from './screens/ImportProject'
import NewProject from './screens/NewProject'
import localizedStrings from './localizedStrings'

const titleText = (screen, hasIntegrations, strings) => {
  if (hasIntegrations && screen === 'ImportProject') return strings.titleText?.import || 'Import'
  if (screen === 'NewProject') return strings.titleText?.create || 'Create'
  return strings.titleText?.create || 'Create'
}

const defaultState = {
  selectedScreen: 'ImportProject',
}

const showImportScreen = (setState, setEntityState) => {
  setState({ selectedScreen: 'ImportProject' })
  setEntityState({ address_attributes: {} })
}

const CreateProjectModal = (props) => {
  const { callbacks, modalKey, showModal } = props
  const { importProject, submitAction } = callbacks || {}

  const [state, setState] = useSetState(defaultState)
  const { selectedScreen } = state

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, `${modalKey}`, {})

  const organizationPayload = useOrganizationSelector()
  const { defaultProjectType, selectedOrganization } = organizationPayload

  const projectPayload = useProject({ organization_id: selectedOrganization.id })

  const projectFormPayload = useProjectForm({
    organization_id: selectedOrganization.id,
    project_type_id: defaultProjectType.id,
  })

  const {
    entityState,
    entityState: { address_attributes, organization_id },
    setEntityState,
  } = projectFormPayload

  const {
    callbacks: { createOrEditAddress },
  } = useAddress(address_attributes)

  const hasAddressAttributes = !!Object.keys(address_attributes).length

  const integrationsPayload = useIntegrations({
    featureKeys: ['import_projects'],
    includeSystemIntegrations: true,
    filters: {
      owner_id: organization_id,
      owner_type: 'Organization',
    },
  })

  const { hasIntegrations, loaded } = integrationsPayload

  useEffect(() => {
    if (hasAddressAttributes){
      setState({ selectedScreen: 'NewProject' })
    }
  }, [hasAddressAttributes])

  useEffect(() => {
    if (!hasIntegrations && loaded){
      createOrEditAddress(entityState, setEntityState)
    }
  }, [hasIntegrations, loaded])

  const { strings } = useLocalization(localizedStrings, { projectType: defaultProjectType })

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        title={titleText(selectedScreen, hasIntegrations, strings)}
        titleSecondLine={strings.titleSecondLine || 'Project'}
      />

      {(!selectedScreen || selectedScreen === 'ImportProject') && (
        <ImportProject
          callbacks={{
            createOrEditAddress: () => createOrEditAddress(entityState, setEntityState),
            importProject,
          }}
          integrationsPayload={integrationsPayload}
          modalPayload={modalPayload}
          organizationPayload={organizationPayload}
          projectFormPayload={projectFormPayload}
          projectPayload={projectPayload}
        />
      )}

      {selectedScreen === 'NewProject' && (
        <NewProject
          callbacks={{
            showImportScreen: () => showImportScreen(setState, setEntityState),
            submitAction,
          }}
          integrationsPayload={integrationsPayload}
          organizationPayload={organizationPayload}
          projectFormPayload={projectFormPayload}
          projectPayload={projectPayload}
        />
      )}
    </SidebarModal>
  )
}

CreateProjectModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

CreateProjectModal.defaultProps = {
  modalKey: 'CreateProjectModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <CreateProjectModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
