export default {
  default: {
    formFieldLabels: {
      projectPlaceholder: 'Search for a project',
      searchProject: 'Find a Project',
    },
    buttons: {
      archiveReview: 'Archive Review',
      unarchiveReview: 'Unarchive Review',
    },
    cancelButton: 'Cancel',
    createTitle: 'Create',
    deleteReview: 'Delete Review',
    moreOptions: 'More Options',
    projectList: 'PROJECTS',
    reviewImageLabel: 'Review Image',
    saveReview: 'Save Review',
    removeFromProjects: 'Remove from Projects',
    titleSecondLine: 'Review',
    updateTitle: 'Update',
    toast: {
      reviewCreated: 'Review Created Successfully',
      reviewDeletedFromProjects: 'Review Removed from Projects',
      reviewDeleted: 'Review Deleted Successfully',
      reviewUpdated: 'Review Updated Successfully',
      toggleReviewStatus: {
        unarchive: 'Review Unarchived Successfully',
      },
    },
    sweetAlert: {
      delete: {
        confirmButton: 'Yes, delete it.',
        text: 'Are you sure? ',
        title: 'Delete Review',
      },
      deleteFromProjects: {
        confirmButton: 'Yes, remove it.',
        text: 'Are you sure? ',
        title: 'Remove from Projects?',
      },
      toggleReviewStatus: {
        unarchiveButton: 'Yes, unarchive it.',
        text: 'Are you sure? ',
        unarchiveTitle: 'Unarchive Review?',
      },
    },
  },
}
