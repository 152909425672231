import { useContext } from 'react'
import cloneDeep from 'lodash/cloneDeep'

import { useForm, useLatestEntity } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import {
  deepSetObject, digObject, launchModal, toggleArray,
} from '@campaignhub/javascript-utils'

import useDispatch from '@hooks/useDispatch'
import useSelector from '@hooks/useSelector'

import * as customFieldActions from '@redux/modules/customField'

import defaultFormState, { requiredFields } from '@models/customField'

import PageContext from '@contexts/pageContext'

import type { AppDispatch } from '@redux/store'
import type { CustomFieldModel, CustomFieldRequestOptions } from '@models/types'
import type { DeleteParams } from '@redux/modules/types'
import type { NestedFieldModel } from '@models/customField'

type CreateCustomFieldParams = {
  customFieldParams: Partial<CustomFieldModel>,
  dispatch: AppDispatch,
  requestOptions?: CustomFieldRequestOptions,
}

const createCustomField = (params: CreateCustomFieldParams) => {
  const { customFieldParams, dispatch, requestOptions } = params
  const { data, options } = customFieldParams || {}

  const { createCustomField: createFn } = customFieldActions

  const updatedParams = {
    ...customFieldParams,
    data: JSON.stringify(data),
    options: JSON.stringify(options),
  }

  return dispatch(createFn(updatedParams, requestOptions))
}

type CustomFieldForm = {
  entityState: CustomFieldModel,
    setEntityState: ({ data }: { data: {}}) => void,
}

type CreateOrUpdateNestedFieldParams = {
  customFieldForm: CustomFieldForm,
  fieldParams: NestedFieldModel,
}

const createOrUpdateNestedField = (params: CreateOrUpdateNestedFieldParams) => {
  const {
    customFieldForm: {
      entityState,
      entityState: { data },
      setEntityState,
    },
    fieldParams,
  } = params

  const existingFields = digObject(entityState, 'data.fields', [])
  const updatedFields = toggleArray(existingFields, fieldParams, { useObjectKey: 'key' })

  setEntityState({ data: deepSetObject(data, 'fields', updatedFields) })

  return { success: true }
}

type DeleteNestedFieldParams = {
  customFieldForm: CustomFieldForm,
  field: NestedFieldModel,
}

const deleteNestedField = (params: DeleteNestedFieldParams) => {
  const {
    customFieldForm: {
      entityState,
      entityState: { data },
      setEntityState,
    },
    field,
  } = params

  const existingFields = digObject(entityState, 'data.fields', [])
  const updatedFields = toggleArray(existingFields, field, { deepCompare: true })

  setEntityState({ data: deepSetObject(data, 'fields', updatedFields) })

  return { success: true }
}

type UpdateCustomFieldParams = {
  customField: CustomFieldModel,
  customFieldParams: Partial<CustomFieldModel>,
  dispatch: AppDispatch,
  requestOptions?: CustomFieldRequestOptions,
}

const updateCustomField = (params: UpdateCustomFieldParams) => {
  const {
    customField, customFieldParams, dispatch, requestOptions,
  } = params
  const { data, options } = customFieldParams

  const { updateCustomField: updateFn } = customFieldActions

  const updatedParams = {
    id: customField.id,
    ...customFieldParams,
  }

  if (data){
    const customFieldData = customField.data || {}
    updatedParams.data = JSON.stringify({
      ...cloneDeep(customFieldData),
      ...data,
    })
  }

  if (options){
    const customFieldOptions = customField.options || {}
    updatedParams.options = JSON.stringify({
      ...cloneDeep(customFieldOptions),
      ...options,
    })
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

type DeleteCustomFieldParams = {
  customField: DeleteParams<CustomFieldModel>,
  dispatch: AppDispatch,
}

const deleteCustomField = (params: DeleteCustomFieldParams) => {
  const { customField, dispatch } = params
  const { deleteCustomField: deleteFn } = customFieldActions

  return dispatch(deleteFn(customField))
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useCustomFieldForm(
  customField: Partial<CustomFieldModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const customFieldForm = useForm(
    defaultFormState,
    { entity: customField, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [customField.id, customField.owner_id, customField.cache_key],
  )

  return {
    ...customFieldForm,
  }
}

export const useRelations = (customField: Partial<CustomFieldModel> = {}) => {
  const { owner_id, owner_type } = customField

  const { customFieldSets } = useSelector(reduxState => reduxState.entities)

  const customFieldSet = owner_type === 'CustomFieldSet' && owner_id
    ? customFieldSets[owner_id] || {}
    : {}

  return {
    customFieldSet,
  }
}

function useCustomField(initEntity: Partial<CustomFieldModel> = {}) {
  const { entity: customField }: { entity: CustomFieldModel} = useLatestEntity(initEntity, 'customFields')

  const dispatch = useDispatch()

  const { callbacks } = useContext(PageContext)

  const { customFieldSet } = useRelations(customField)

  const { creating } = useSelector(reduxState => reduxState.customFields)

  const fieldWidth = digObject(customField, 'options.width', 100)
  const optionValues = digObject(customField, 'options.option_values', [])

  return {
    callbacks: {
      createCustomField: (
        customFieldParams: Partial<CustomFieldModel>,
        entityOptions?: CustomFieldRequestOptions,
      ) => (
        createCustomField({ customFieldParams, dispatch, requestOptions: entityOptions })
      ),
      createOrEditCustomField: () => launchModal({
        callbacks,
        modalKey: 'CreateOrEditCustomFieldModal',
        payload: {
          callbacks: {
            createCustomField: (
              customFieldParams: Partial<CustomFieldModel>,
              entityOptions?: CustomFieldRequestOptions,
            ) => (
              createCustomField({ customFieldParams, dispatch, requestOptions: entityOptions })
            ),
            deleteCustomField: () => deleteCustomField({ customField, dispatch }),
            updateCustomField: (
              customFieldParams: Partial<CustomFieldModel>,
              entityOptions?: CustomFieldRequestOptions,
            ) => (
              updateCustomField({
                customField, customFieldParams, dispatch, requestOptions: entityOptions,
              })
            ),
          },
          customField,
          customFieldSet,
        },
      }),
      createOrEditNestedField: (
        { customFieldForm, field }: { customFieldForm: CustomFieldForm, field: NestedFieldModel},
      ) => launchModal({
        callbacks,
        modalKey: 'CreateOrEditNestedFieldModal',
        payload: {
          callbacks: {
            createOrUpdateNestedField: (
              fieldParams: NestedFieldModel,
            ) => createOrUpdateNestedField({ customFieldForm, fieldParams }),
            deleteNestedField: () => deleteNestedField({ customFieldForm, field }),
          },
          field,
        },
      }),
      deleteCustomField: () => deleteCustomField({ dispatch, customField }),
      updateCustomField: (
        customFieldParams: Partial<CustomFieldModel>,
        entityOptions?: CustomFieldRequestOptions,
      ) => (
        updateCustomField({
          customField, customFieldParams, dispatch, requestOptions: entityOptions,
        })
      ),
    },
    creating,
    customField,
    customFieldSet,
    fieldWidth,
    optionValues,
  }
}

export default useCustomField
