import React from 'react'
import { Helmet } from 'react-helmet-async'

import { useSetState } from '@campaignhub/react-hooks'

import useLocalization from '@hooks/useLocalization'

import PageContext from '@contexts/pageContext'

import PageContent from './components/PageContent'
import localizedStrings from './localizedStrings'

const defaultState = {
  pageFilters: {},
}

const Projects = () => {
  const [state, setState] = useSetState(defaultState)
  const { pageFilters } = state

  const pageContext = {
    callbacks: {
      resetFilters: (resetFn) => {
        if (resetFn) resetFn()
        setState({ pageFilters: {} })
      },
      updateFilters: (filters) => {
        setState({ pageFilters: filters })
      },
    },
  }

  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      <Helmet>
        <title>{`${strings.title} | Engage`}</title>
      </Helmet>

      <PageContext.Provider value={pageContext}>
        <PageContent pageFilters={pageFilters} />
      </PageContext.Provider>
    </>
  )
}

export default Projects
