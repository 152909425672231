import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus } from '@fortawesome/pro-light-svg-icons'

import { Box, Button } from '@campaignhub/suit-theme'

import DocumentRecipient from '@components/ModalComponents/DocumentRecipient'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const DocumentRecipients = (props) => {
  const {
    agreementPayload: { filteredRecipients, isSyncedWithElectronicSigningPlatform },
    callbacks: { manageAgreementRecipients },
  } = props

  const { strings } = useLocalization(localizedStrings)

  return (
    <Box flexDirection="column">
      {filteredRecipients.map(documentRecipient => (
        <DocumentRecipient documentRecipient={documentRecipient} key={documentRecipient.id} />
      ))}

      {!isSyncedWithElectronicSigningPlatform && (
        <Button
          buttonStyle="secondaryUtility"
          icon={<FontAwesomeIcon icon={faUserPlus} />}
          onClick={() => manageAgreementRecipients()}
          size="medium"
        >
          {strings.manageRecipients || 'Manage Recipients'}
        </Button>
      )}
    </Box>
  )
}

DocumentRecipients.propTypes = {
  callbacks: PropTypes.shape({
    manageAgreementRecipients: PropTypes.func.isRequired,
  }).isRequired,
  agreementPayload: PropTypes.shape({
    filteredRecipients: PropTypes.array.isRequired,
    isSyncedWithElectronicSigningPlatform: PropTypes.bool,
  }).isRequired,
}

export default DocumentRecipients
