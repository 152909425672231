import { CSSProperties, ReactNode } from 'react'

import classnames from 'classnames'

import styles from './styles.module.scss'

interface HintWrapperProps {
  alternate?: boolean,
  centredArrow?: boolean,
  children?: ReactNode,
  hint: string,
  hintStyle?: CSSProperties,
  renderChildrenOnly?: boolean,
  style?: CSSProperties,
}

const HintWrapper = (props: HintWrapperProps) => {
  const {
    alternate, centredArrow, children, hint, hintStyle, renderChildrenOnly, style,
  } = props

  const baseClassName = alternate ? classnames(styles.hint, styles.alternate) : styles.hint

  if (renderChildrenOnly){
    return children
  }

  return (
    <div className={styles.root} style={style}>
      {children}
      {hint && (
        <span
          className={centredArrow ? classnames(baseClassName, styles.centredArrow) : baseClassName}
          style={hintStyle}
        >
          {hint}
        </span>
      )}
    </div>
  )
}

export default HintWrapper
