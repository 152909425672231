const defaultRequestOptions = {
  brand: {
    include_brand_organizations: true,
    include_brand_data_store_items: true,
  },
  customFieldSet: {
    include_custom_field_set_fields: true,
    include_custom_field_set_owner_title: true,
  },
  digitalPageMetricCollection: {
    include_digital_page_metric_collections_owner_title: true,
  },
  image: {
    include_image_sizes: [
      'thumb_90x90',
      'thumb_200x120',
      'thumb_660x360',
      'thumb_1280x720',
      'thumb_2000x1500',
      'thumb_3500x2500',
    ],
  },
  targetAudience: {
    include_target_audience_image: true,
    include_target_audience_owner_title: true,
  },
  organization: {
    include_organization_addresses: true,
    include_organization_images: true,
    include_organization_default_image: true,
  },
  user: {
    include_user_brand_ids: true,
    include_user_brands_where_admin_ids: true,
    include_user_organization_ids: true,
  },
}

export default defaultRequestOptions
