import { useContext } from 'react'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

import { faHandHoldingUsd } from '@fortawesome/pro-light-svg-icons'

import { useSetState } from '@campaignhub/react-hooks'

import PageContext from '@contexts/pageContext'

import {
  Box, Button, Columns, LoadingModule, PageHeader,
} from '@campaignhub/suit-theme'

import { getQueryParamsFromHash, parsePermittedQueryParams, sortArrayBy } from '@campaignhub/javascript-utils'

import useDigitalPage from '@hooks/useDigitalPage'
import useDigitalPages from '@hooks/useDigitalPages'
import useLocalization from '@hooks/useLocalization'
import useOrganizationSelector from '@hooks/useOrganizationSelector'
import useReduxAction from '@hooks/useReduxAction'

import DigitalPageListItem from '@components/DigitalPageListItem'
import PageFilterModule from '@components/PageFilterModule'

import defaultRequestOptions from '@sections/Client/packs/Project/defaultRequestOptions'

import CreateProjectItemModal from '@modals/CreateProjectItemModal'

import DigitalPageBlankState from '../DigitalPageBlankState'
import localizedStrings from './localizedStrings'

const buildPageFilterFields = (params) => {
  const { entities: { digitalTemplateTypes }, parentDigitalTemplateType, strings } = params

  const style = {
    marginBottom: 16,
  }

  const boxProps = {
    marginBottom: 'large',
  }

  const filteredTemplateTypes = Object.values(digitalTemplateTypes).filter(
    type => type.parent_id === parentDigitalTemplateType.id,
  )

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        style,
      },
      key: 'string',
      label: strings.filters?.filterByTitle || 'Filter by Title',
    },
    {
      component: 'EntitySelector',
      componentProps: {
        boxProps,
        endpoint: 'digital_templates',
        entityKey: 'digitalTemplates',
      },
      key: 'digital_template_id',
    },
    {
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: strings.filters?.defaultSelectLabel || 'All',
        inputComponent: 'select',
        style,
      },
      key: 'digital_template_type_key',
      label: strings.filters?.filterType || 'Page Type',
      values: sortArrayBy(filteredTemplateTypes).map(digitalTemplateType => ({
        key: digitalTemplateType.key,
        label: digitalTemplateType.title,
      })),
    },
    {
      entityKey: 'limit',
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: '50',
        inputComponent: 'select',
        style,
      },
      key: 'limit',
      label: strings.filters?.limit || 'Limit',
      values: [
        { key: 100, label: '100' },
        { key: 150, label: '150' },
        { key: 200, label: '200' },
      ],
    },
  ]
}

const defaultState = {
  showCreateProjectItemModal: false,
}

const PageContent = (props) => {
  const { projectId, pageFilters } = props

  const [state, setState] = useSetState(defaultState)
  const { showCreateProjectItemModal } = state

  const { selectedOrganization } = useOrganizationSelector()

  const initDigitalPage = {
    owner_id: selectedOrganization.id,
    owner_type: 'Organization',
    subject_id: projectId,
    subject_type: 'Project',
  }

  const {
    callbacks: { showSelectDigitalTemplateModal },
  } = useDigitalPage(initDigitalPage)

  const pageContext = useContext(PageContext)
  const {
    callbacks: {
      resetFilters, showDuplicateDigitalPageModal, updateFilters,
    },
    defaultFilters,
  } = pageContext

  const entities = useSelector(reduxState => reduxState.entities)

  // useLocation to refresh the window.location. If not used location won't reload if URL changes
  useLocation()

  const { digitalTemplateType: templateTypeKey } = parsePermittedQueryParams(getQueryParamsFromHash(), [
    'digitalTemplateType',
  ])

  const entityKey = `Project${projectId}`

  useReduxAction(
    'digitalPages',
    'loadDigitalPages',
    {
      ...defaultRequestOptions.digitalPage,
      ...defaultRequestOptions.digitalTemplate,
      ...defaultRequestOptions.image,
      ...defaultRequestOptions.shareLink,
      ...pageFilters,
      digital_template_type: pageFilters.digital_template_type_key || templateTypeKey,
      entityKey,
      subject_id: projectId,
      subject_type: 'Project',
    },
    [projectId, JSON.stringify(pageFilters)],
    {
      shouldPerformFn: ({ loadedForKeys }) => projectId && !loadedForKeys.includes(entityKey),
    },
  )

  const { filteredDigitalPages, hasFilteredDigitalPages, loading } = useDigitalPages({
    subject_id: projectId,
    subject_type: 'Project',
    templateTypeKey,
    ...pageFilters,
  })

  const digitalTemplateType = Object.values(entities.digitalTemplateTypes).find(
    type => type.key === templateTypeKey,
  ) || { key: templateTypeKey || 'proposal' }

  const { strings } = useLocalization(localizedStrings, { digitalTemplateType })

  return (
    <>
      <PageHeader
        actionContent={(
          <Button
            buttonStyle="primaryCreate"
            onClick={() => setState({ showCreateProjectItemModal: true })}
            size="medium"
            style={{ marginRight: 8, width: 'auto' }}
          >
            {strings.createText || 'Create'}
          </Button>
        )}
        activeTabBarItemKey={templateTypeKey}
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        nestedNavigation
        tabBarItems={[
          {
            href: `#/projects/${projectId}/digitalPages?digitalTemplateType=proposal`,
            icon: faHandHoldingUsd,
            key: 'proposal',
            title: strings.proposalTab,
          },
        ]}
        title={templateTypeKey === 'all' ? strings.titleAll : strings.title}
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!hasFilteredDigitalPages && !loading && (
              <DigitalPageBlankState digitalTemplateTypeKey={templateTypeKey} projectId={projectId} />
            )}

            {hasFilteredDigitalPages
              && !loading
              && filteredDigitalPages.map(digitalPage => (
                <DigitalPageListItem digitalPage={digitalPage} key={digitalPage.id} />
              ))}
          </Columns.Main>

          <Columns.Sidebar>
            <PageFilterModule
              callbacks={{ resetFilters, updateFilters }}
              defaultFilters={defaultFilters}
              filterFields={buildPageFilterFields({
                entities,
                parentDigitalTemplateType: digitalTemplateType,
                strings,
              })}
              pageFilters={pageFilters}
              title={strings.filters?.filterTitle || 'Filters'}
            />

          </Columns.Sidebar>
        </Columns>
      </Box>

      <CreateProjectItemModal
        callbacks={{
          closeModal: () => setState({ showCreateProjectItemModal: false }),
          showDuplicateDigitalPageModal,
          showSelectDigitalTemplateModal,
        }}
        projectId={projectId}
        showModal={showCreateProjectItemModal}
      />
    </>
  )
}

PageContent.propTypes = {
  projectId: PropTypes.number.isRequired,
  pageFilters: PropTypes.object.isRequired,
}

export default PageContent
