export default {
  default: {
    formFieldLabels: {
      selectRecipient: 'Select Recipient to Transfer Items to',
    },
    buttons: {
      delete: 'Delete Recipient and Reassign Items',
    },
  },
}
