import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faToggleOn, faToggleOff } from '@fortawesome/pro-light-svg-icons'

import { Box, Text } from '@campaignhub/suit-theme'

const FeatureItem = (props) => {
  const {
    callbacks: { toggle },
    feature: { label },
    featureEnabled,
    featureKey,
    lastInput,
  } = props

  return (
    <Box
      color={featureEnabled ? 'green' : 'bodyFontLightColor'}
      key={`feature_${featureKey}`}
      marginBottom={lastInput ? null : 'large'}
      onClick={() => toggle(featureKey)}
      style={{ cursor: 'pointer' }}
      tabIndex="0"
    >
      <FontAwesomeIcon icon={featureEnabled ? faToggleOn : faToggleOff} />

      <Text color="bodyFontLightColor" style={{ marginLeft: '8px' }} fontSize="xsmall">
        {label}
      </Text>
    </Box>
  )
}

FeatureItem.propTypes = {
  callbacks: PropTypes.shape({
    toggle: PropTypes.func.isRequired,
  }),
  feature: PropTypes.object.isRequired,
  featureEnabled: PropTypes.bool,
  featureKey: PropTypes.string.isRequired,
  lastInput: PropTypes.bool,
}

export default FeatureItem
