import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import {
  ListItem, Text,
} from '@campaignhub/suit-theme'

const ProjectListItem = (props) => {
  const {
    added,
    callbacks,
    project,
    selected,
  } = props

  const { selectProject } = callbacks

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        border: '1px solid',
        borderColor: 'lineColor',
        borderRadius: 5,
        justifyContent: 'space-between',
        padding: 'large',
        marginBottom: 'medium',
      }}
      disableHover={added}
      forceBottomBorder
      onClick={selectProject}
    >
      <Text color={added ? 'lightGrey' : 'bodyFontColor'} fontSize="small">
        {project.title || project.text}
      </Text>

      <FontAwesomeIcon color={added ? 'lightGrey' : 'bodyFontColor'} icon={selected ? faTrashAlt : faPlusCircle} />
    </ListItem>
  )
}

ProjectListItem.propTypes = {
  added: PropTypes.bool,
  callbacks: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  selected: PropTypes.bool,
}

export default ProjectListItem
