import type { EventModel } from './event'

export type EventCalendarModel = {
  id: number,
  cache_key: number,
  data: {},
  description: string,
  event_calendar_template_id: number,
  events: EventModel[],
  number_of_preparation_weeks: number,
  number_of_weeks: number,
  owner_id: number,
  owner_type: string,
  saved_template: boolean,
  start_date: string,
  subject_id: number,
  subject_type: string,
  title: string,
}

export type EventCalendarRequestOptions = {
  include_event_calendar_event_count?: boolean,
  include_event_calendar_event_group_count?: boolean,
  include_event_calendar_events?: boolean,
  include_event_calendar_event_groups?: boolean,
}

const state = {
  id: null,
  data: {},
  description: '',
  event_calendar_template_id: null,
  number_of_preparation_weeks: 2,
  number_of_weeks: 6,
  owner_id: null,
  owner_type: 'Organization',
  saved_template: false,
  start_date: '',
  subject_id: null,
  subject_type: null,
  title: '',
}

export const requiredFields = [
  { key: 'owner_id' },
  { key: 'owner_type' },
  { key: 'title' },
]

export default state
