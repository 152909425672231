import React, { Fragment, useMemo } from 'react'

import { Box, NavigationLinkGroup, NestedNavigation } from '@campaignhub/suit-theme'

import useCurrentUser from '@hooks/useCurrentUser'
import useIntegrations from '@hooks/useIntegrations'
import useLocalization from '@hooks/useLocalization'
import useOrganizationSelector from '@hooks/useOrganizationSelector'

import Header from './components/Header'

import generateNavigationItems from './utils/generateNavigationItems'
import localizedStrings from './localizedStrings'

const Navigation = () => {
  const organizationSelectorPayload = useOrganizationSelector()
  const { isSelectedOrganizationAdmin, selectedOrganization } = organizationSelectorPayload

  const { currentUser } = useCurrentUser()

  const { hasIntegrations: hasQuoteIntegrations } = useIntegrations({
    featureKeys: ['quoting'],
    filters: {
      owner_id: selectedOrganization.id,
      owner_type: 'Organization',
    },
  })

  const { strings } = useLocalization(localizedStrings)

  const navigationItems = useMemo(() => {
    const items = generateNavigationItems({
      currentUser,
      hasQuoteIntegrations,
      isSelectedOrganizationAdmin,
      strings,
    })
    return items
  }, [hasQuoteIntegrations, selectedOrganization.id])

  return (
    <NestedNavigation>
      <Header organizationSelectorPayload={organizationSelectorPayload} />

      <Box flexDirection="column" flexShrink="0" padding="large">
        {/* Nav */}
        {navigationItems
          && navigationItems.sections.map(section => (
            <Fragment key={section.key}>
              {section.visible && section.title && <NestedNavigation.SectionHeader title={section.title} maximized />}

              {section.items
                && section.items.map(item => (
                  <NavigationLinkGroup key={item.key} link={item} secondaryNav sidebarMaximized />
                ))}
            </Fragment>
          ))}
        {/* End Nav */}
      </Box>
    </NestedNavigation>
  )
}

export default Navigation
