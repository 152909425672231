import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'
import useReduxAction from '@hooks/useReduxAction'

import { digObject, matchFilterString, matchFilterNumber } from '@campaignhub/javascript-utils'
import sortEntitiesByDate from '@functions/sortEntitiesByDate'

const watchEntityKeys = ['dataStoreItems', 'formTemplates']

function useFormResponses(options = {}){
  const { subjectId, subjectType } = options

  const {
    updatedEntities: { dataStoreItems: dataStoreItemsUpdatedAt, formTemplates: formTemplatesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  useReduxAction('formTemplates', 'loadFormTemplates')

  const entities = useSelector(reduxState => reduxState.entities)
  const { dataStoreItems, formTemplates } = entities

  const loading = useSelector(reduxState => reduxState.projects.loading)

  const filteredFormResponses = useMemo(() => {
    const filtered = Object.values(dataStoreItems).filter((dataStore) => {
      const { key, subject_id, subject_type } = dataStore

      const subjectMatch = subjectId && subjectType ? subject_type === subjectType && matchFilterNumber(subject_id, subjectId) : true
      const matchKey = matchFilterString(key, 'form_response')

      return matchKey && subjectMatch
    })

    return sortEntitiesByDate(filtered, 'asc', 'created')
  }, [dataStoreItemsUpdatedAt, JSON.stringify(options)])

  const groupedFormResponses = useMemo(
    () => filteredFormResponses.reduce((grouped, response) => {
      const formTemplateId = digObject(response, 'data.form_template_id')

      if (!formTemplateId) return grouped

      if (!grouped[formTemplateId]){
        const { title } = formTemplates[formTemplateId] || {}
        grouped[formTemplateId] = { formTemplateId, responses: [], templateTitle: title }
      }

      grouped[formTemplateId].responses.push(response)
      return grouped
    }, {}),
    [dataStoreItemsUpdatedAt, formTemplatesUpdatedAt],
  )

  const filteredFormResponsesCounts = filteredFormResponses.length

  const formCount = Object.values(groupedFormResponses).length

  const hasFormResponses = !!filteredFormResponses.length

  return {
    filteredFormResponses,
    filteredFormResponsesCounts,
    groupedFormResponses,
    formCount,
    hasFormResponses,
    loading,
  }
}

export default useFormResponses
