import { useContext } from 'react'
import Swal from 'sweetalert2'

import {
  Box, Button, FormField, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import { digObject, stringToKey } from '@campaignhub/javascript-utils'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import FieldOptionValues from '@components/FieldOptionValues'

import { useNestedCustomFieldForm } from './hooks/useNestedCustomField'

const MODAL_KEY = 'CreateOrEditNestedFieldModal'

type DeleteNestedFieldPayload = {
  callbacks: {
    action: (deleteNestedField: () => void) => void,
    afterAction: (closeModal: () => void) => void,
  },
  toastText: string,
}

type DeleteNestedFieldParams = {
  deleteNestedField: (payload: DeleteNestedFieldPayload) => void,
  payload: DeleteNestedFieldPayload,
}

const confirmDelete = (params: DeleteNestedFieldParams) => {
  const { deleteNestedField, payload } = params

  Swal.fire({
    title: 'Delete Nested Field?',
    text: 'Are you sure?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, remove it',
    confirmButtonColor: '#DD6B55',
  }).then(({ value }) => {
    if (value){
      deleteNestedField(payload)
    }
  })
}

type ModalCallbacks = {
  closeModal: () => void,
  createOrUpdateNestedField: (payload: HandleCallbackActionParams) => void,
  deleteNestedField: (payload: HandleCallbackActionParams) => void,
}

type ModalProps = {
  callbacks: ModalCallbacks,
  showModal: boolean,
}

const CreateOrEditNestedFieldModal = (props: ModalProps) => {
  const { callbacks, showModal } = props
  const { closeModal, createOrUpdateNestedField, deleteNestedField } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const { callbacks: modalCallbacks, field } = modalPayload

  const newRecord = !field.key

  const { createOrUpdateNestedField: createOrUpdateFn, deleteNestedField: deleteFn } = modalCallbacks || {}

  const nestedFieldFormPayload = useNestedCustomFieldForm(field)
  const {
    entityState,
    entityState: {
      key, label, input_type, field_type, options,
    },
    handlers,
    setEntityState,
    saveEnabled,
    errors,
  } = nestedFieldFormPayload

  const nestedFieldPayload = {
    callbacks: {
      action: createOrUpdateFn,
      afterAction: closeModal,
    },
    entityParams: entityState,
    toastText: 'Nested Field Created',
  }

  const deleteNestedFieldPayload = {
    callbacks: {
      action: deleteFn,
      afterAction: closeModal,
    },
    entityParams: entityState,
    toastText: 'Nested Field Deleted',
  }

  return (
    <SidebarModal callbacks={callbacks} modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title="Nested" titleSecondLine="Field" />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <FormField direction="column" boxProps={{ marginBottom: 'large' }} label="Field Label">
            <input
              name="label"
              onBlur={e => setEntityState({ key: stringToKey(e.target.value) })}
              onChange={e => setEntityState({ label: e.target.value })}
              placeholder="e.g Bedrooms"
              type="text"
              value={label || ''}
            />
          </FormField>

          <FormField boxProps={{ marginBottom: 'large' }} errorMessage={errors.key} label="* Key">
            <input type="text" name="key" value={key} {...handlers} />
          </FormField>

          <FormField
            boxProps={{ marginBottom: 'large' }}
            direction="column"
            errorMessage={errors.field_type}
            label="* Field Type"
          >
            <select
              name="field_type"
              value={field_type || ''}
              onChange={e => setEntityState({ field_type: e.target.value, input_type: null })}
            >
              <option value="">Please Select...</option>
              <option value="input">Input</option>
              <option value="select">Select Box</option>
              <option value="textarea">Text Area</option>
            </select>
          </FormField>

          {field_type === 'input' && (
            <FormField boxProps={{ marginBottom: 'large' }} direction="column" label="Input Type">
              <select name="input_type" value={input_type || ''} {...handlers}>
                <option value="">Please Select...</option>
                <option value="date">Date</option>
                <option value="datetime-local">Date Time</option>
                <option value="time">Time</option>
                <option value="email">Email</option>
                <option value="number">Number</option>
                <option value="text">Text</option>
                <option value="url">Url</option>
              </select>
            </FormField>
          )}

          {field_type === 'select' && (
            <>
              <SidebarModal.Separator />

              <FieldOptionValues callbacks={{ setEntityState }} options={options} />
            </>
          )}
        </Box>

        <FormField direction="column" label="More Options">
          <Box flexDirection="column" flexShrink={0}>
            <Button
              buttonStyle="secondaryUtility"
              onClick={() => confirmDelete({ payload: deleteNestedFieldPayload, deleteNestedField })}
              icon={<FontAwesomeIcon icon={faTrashAlt} />}
              size="medium"
              width="100%"
            >
              Delete Field
            </Button>
          </Box>
        </FormField>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          onClick={() => createOrUpdateNestedField(nestedFieldPayload)}
          size="large"
        >
          {newRecord ? 'Create Field' : 'Update Field'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ModalProps) => (
  <SidebarModal.RenderController {...props}>
    <CreateOrEditNestedFieldModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
