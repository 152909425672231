import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

import { useModals, useSetState } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import EditAttachmentModal from '@modals/EditAttachmentModal'
import ManageProjectAttachmentsModal from '@modals/ManageProjectAttachmentsModal'

import defaultRequestOptions from '@sections/Client/packs/Project/defaultRequestOptions'

import useLocalization from '@hooks/useLocalization'

import PageContent from './components/PageContent'
import localizedStrings from './localizedStrings'

const deleteAttachment = (deleteFn, setState, strings) => {
  deleteFn().then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    toast(strings.attachmentDeleted || 'File Deleted Successfully')
    setState({ showEditAttachmentModal: false })
  })
}

const updateAttachment = (attachmentParams, customFields, updateFn, setState, strings) => {
  updateFn(attachmentParams, customFields, defaultRequestOptions.attachment).then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    toast(strings.attachmentUpdated || 'File Updated Successfully')
    setState({ showEditAttachmentModal: false })
  })
}

const callbacks = (component, setState, strings) => {
  const componentCallbacks = {
    EditAttachmentModal: {
      closeModal: () => setState({ showEditAttachmentModal: false }),
      deleteAttachment: deleteFn => deleteAttachment(deleteFn, setState, strings),
      updateAttachment: (params, customFields, updateFn) => updateAttachment(params, customFields, updateFn, setState, strings),
    },

    ManageProjectAttachmentsModal: {
      closeModal: () => setState({ showManageProjectAttachmentsModal: false }),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  showEditAttachmentModal: false,
  showManageProjectAttachmentsModal: false,
}

const Documents = (props) => {
  const { projectId } = props

  const [state, setState] = useSetState(defaultState)
  const { showEditAttachmentModal, showManageProjectAttachmentsModal } = state

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const pageContext = {
    callbacks: {
      showEditAttachmentModal: (payload) => {
        setModalData('EditAttachmentModal', payload)
        setState({ showEditAttachmentModal: true })
      },
      showManageProjectAttachmentsModal: (payload) => {
        setModalData('ManageProjectAttachmentsModal', payload)
        setState({ showManageProjectAttachmentsModal: true })
      },
    },
  }

  const { strings } = useLocalization(localizedStrings)

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent
          callbacks={callbacks('ManageProjectAttachmentsModal', setState, strings)}
          project={{ id: projectId }}
        />

        <EditAttachmentModal
          callbacks={callbacks('EditAttachmentModal', setState, strings)}
          showModal={showEditAttachmentModal}
        />

        <ManageProjectAttachmentsModal
          callbacks={callbacks('ManageProjectAttachmentsModal', setState, strings)}
          project={{ id: projectId }}
          showModal={showManageProjectAttachmentsModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

Documents.propTypes = {
  projectId: PropTypes.number.isRequired,
}

export default Documents
