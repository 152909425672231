import PropTypes from 'prop-types'

import SectionContextProvider from '@components/SectionContextProvider'

import useBrands from '@hooks/useBrands'
import useIntegrationPlatforms from '@hooks/useIntegrationPlatforms'
import useIntegrations from '@hooks/useIntegrations'
import useRequestOrSupplySupportToken from '@hooks/useRequestOrSupplySupportToken'
import useRouterScrollToTop from '@hooks/useRouterScrollToTop'
import useUsers from '@hooks/useUsers'
import useUserTypes from '@hooks/useUserTypes'

import RequestOrSupplySupportTokenModal from '@modals/RequestOrSupplySupportTokenModal'

const Client = (props) => {
  const { children } = props

  useBrands({ performHttpRequests: true })
  useIntegrationPlatforms({ performHttpRequests: true })
  useIntegrations({ performHttpRequests: true })
  useUsers({ performHttpRequests: true })
  useUserTypes({ performHttpRequests: true })

  // Scroll Page to top when route changes
  useRouterScrollToTop()

  const requestOrSupplySupportTokenPayload = useRequestOrSupplySupportToken()

  return (
    <SectionContextProvider>
      {children}

      <RequestOrSupplySupportTokenModal {...requestOrSupplySupportTokenPayload} />
    </SectionContextProvider>
  )
}

Client.propTypes = {
  children: PropTypes.node,
}

export default Client
