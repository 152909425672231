import { useSetState } from '@campaignhub/react-hooks'
import { digObject } from '@campaignhub/javascript-utils'

import { useRelations } from '@hooks/useDocumentRecipient'
import useDocumentTemplate from '@hooks/useDocumentTemplate'

const defaultState = {
  showReassignRecipientItems: false,
  targetRecipientId: null,
}

function useReassignRecipientPageItems(documentRecipient = {}){
  const [state, setState] = useSetState(defaultState)
  const { showReassignRecipientItems, targetRecipientId } = state

  const { documentTemplate } = useRelations(documentRecipient)

  const documentTemplatePayload = useDocumentTemplate(documentTemplate)
  const filteredRecipients = digObject(documentTemplatePayload, 'filteredRecipients', [])

  const remainingRecipients = filteredRecipients.filter(recipient => recipient.id !== documentRecipient.id)

  return {
    callbacks: {
      setState,
    },
    hasRemainingRecipients: !!remainingRecipients.length,
    remainingRecipients,
    showReassignRecipientItems,
    targetRecipientId,
  }
}

export default useReassignRecipientPageItems
