import {
  Box, Button, FormField, SidebarModal,
} from '@campaignhub/suit-theme'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import useBrand, { useBrandForm } from '@hooks/useBrand'
import useOrganizationTypes from '@hooks/useOrganizationTypes'

const MODAL_KEY = 'CreateBrandModal'

interface CreateBrandModalProps {
  callbacks: {
    closeModal: () => void,
    createBrand: (payload: HandleCallbackActionParams) => void,
  },
  modalKey: 'CreateBrandModal',
  showModal: boolean,
}

const CreateBrandModal = (props: CreateBrandModalProps) => {
  const {
    callbacks,
    callbacks: { closeModal, createBrand },
    showModal,
  } = props

  const {
    callbacks: { createBrand: createFn },
    creating,
  } = useBrand()

  const {
    entityState,
    entityState: { organization_type_id, title },
    errors,
    handlers,
    saveEnabled,
  } = useBrandForm()

  const { organizationTypes } = useOrganizationTypes({ performHttpRequests: true })

  const brandPayload = {
    callbacks: {
      action: createFn,
      afterAction: closeModal,
    },
    entityParams: entityState,
    shouldRedirect: true,
    toastText: 'Brand Created Successfully',
  }

  return (
    <SidebarModal callbacks={callbacks} modalKey={MODAL_KEY} showModal={showModal} size="small">
      <SidebarModal.Header callbacks={callbacks} title="Create" titleSecondLine="Brand" />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <FormField direction="column" boxProps={{ marginBottom: 'large' }} errorMessage={errors.title} label="Title">
            <input name="title" value={title} type="text" {...handlers} data-validate-field-on="change" />
          </FormField>
        </Box>

        <Box flexDirection="column" flexShrink={0}>
          <FormField label="Organization Type" errorMessage={errors.organization_type_id}>
            <select
              name="organization_type_id"
              value={organization_type_id}
              {...handlers}
              data-validate-field-on="change"
            >
              <option value="">Please Select...</option>
              {Object.values(organizationTypes).map(organizationType => (
                <option key={organizationType.id} value={organizationType.id}>
                  {organizationType.title}
                </option>
              ))}
            </select>
          </FormField>
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={creating}
          onClick={() => createBrand(brandPayload)}
          size="large"
        >
          Create Brand
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <CreateBrandModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
