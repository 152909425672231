import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCog, faEyeSlash, faPencil, faFileAlt,
} from '@fortawesome/pro-light-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'
import {
  Box, Button, IconWithMenu, Link, ListItem, Text,
} from '@campaignhub/suit-theme'

import useQuote from '@hooks/useQuote'
import useLocalization from '@hooks/useLocalization'
import useManageEntityUserPermissions from '@hooks/useManageEntityUserPermissions'

import localizedStrings from './localizedStrings'

const QuoteTemplate = (props) => {
  const { quote } = props

  const quotePayload = useQuote(quote)
  const {
    callbacks: { updateQuote: updateFn },
    quote: { dates, title },
    urls: { editQuoteTemplateUrl },
  } = quotePayload

  const manageTemplateUserPayload = useManageEntityUserPermissions({ entity: quote, updateFn })
  const { callbacks: { manageEntityUserPermission } } = manageTemplateUserPayload

  const createdDateTime = digObject(dates, 'created.date_time_with_timezone')

  const { callbacks: { formatDate }, strings } = useLocalization({ localizedStrings })

  return (
    <ListItem
      boxProps={{ borderBottom: '1px solid', padding: 'large' }}
      disableHover
    >
      <Box
        alignItems="center"
        backgroundColor="backgroundColor"
        borderRadius={5}
        color="bodyFontLightColor"
        flexShrink={0}
        height={40}
        justifyContent="center"
        position="relative"
        width={40}
      >
        <FontAwesomeIcon icon={faFileAlt} />
      </Box>

      <Box flexDirection="column" justifyContent="center" marginLeft="large" minWidth="0">
        <Text fontSize="small">{title}</Text>

        <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="medium">
          {`Created ${formatDate(createdDateTime, DateTime.DATE_HUGE)}`}
        </Text>
      </Box>

      <Box flexDirection="row" justifyContent="flex-end" marginRight="0" minWidth="0">
        <IconWithMenu
          icon={(
            <Button
              buttonStyle="secondaryUtility"
              icon={<FontAwesomeIcon icon={faCog} />}
              size="medium"
              height={37}
            />
        )}
          style={{ marginLeft: 'auto', width: 'auto' }}
        >
          <IconWithMenu.Menu closeOnClick listStyle={{ right: '-7px' }}>
            <Link href={editQuoteTemplateUrl} key="edit">
              <FontAwesomeIcon icon={faPencil} /> {strings.editQuoteTemplate || 'Edit Quote Template' }
            </Link>

            <Link key="permissions" onClick={() => manageEntityUserPermission()}>
              <FontAwesomeIcon icon={faEyeSlash} /> {strings.permissions || 'Permissions'}
            </Link>
          </IconWithMenu.Menu>
        </IconWithMenu>
      </Box>
    </ListItem>
  )
}

QuoteTemplate.propTypes = {
  quote: PropTypes.object.isRequired,
}

export default QuoteTemplate
