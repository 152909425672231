export default {
  default: {
    readPercentage: '{average_read_percentage}%',
    labels: {
      averageReadPercentage: 'Avg Read %',
      averageReadTime: 'Avg Read Time',
      pagesCreated: 'Pages Created',
      views: 'Total Page Views',
    },
  },
}
