import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons'

import {
  Box, ListItem, Text,
} from '@campaignhub/suit-theme'

const PropertySuggestion = (props) => {
  const {
    added,
    comparable,
    isLast,
    onClickFn,
  } = props
  const { address, postcode, state_name } = comparable

  return (
    <ListItem
      borderColor={added ? 'white' : 'lineColor'}
      boxProps={{
        alignItems: 'center',
        border: '1px solid',
        borderRadius: 5,
        marginBottom: isLast ? 'medium' : 'none',
        marginTop: 'medium',
        padding: 'large',
      }}
      disableHover={added}
      flexShrink={0}
      forceBottomBorder
      onClick={added ? null : () => onClickFn(comparable.id)}
    >
      <Box flexDirection="column" justifyContent="center">
        <Text color={added ? 'lightGrey' : 'bodyFontColor'} fontSize="small">
          {address}
        </Text>

        {(state_name || postcode) && (
          <Text color={added ? 'lightGrey' : 'bodyFontLightColor'} fontSize="xsmall" marginTop="small">
            {state_name} {postcode}
          </Text>
        )}
      </Box>

      <FontAwesomeIcon color={added ? 'lightGrey' : 'bodyFontColor'} icon={faPlusCircle} />
    </ListItem>
  )
}

PropertySuggestion.propTypes = {
  added: PropTypes.bool,
  comparable: PropTypes.object.isRequired,
  isLast: PropTypes.bool,
  onClickFn: PropTypes.func,
}

export default PropertySuggestion
