import { useContext } from 'react'
import PropTypes from 'prop-types'

import {
  Box, Button, FormField, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import countryCallingCodes from '@functions/countryCallingCodes'
import timeZones from '@functions/timeZones'

import useUser, { useUserForm } from '@hooks/useUser'
import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const EditUserModal = (props) => {
  const { callbacks, modalKey, showModal } = props
  const { closeModal, updateUser } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const { user } = digObject(modalData, `${modalKey}`, {})

  const userPayload = useUser(user)
  const {
    callbacks: { updateUser: updateFn },
    creating,
    updating,
  } = userPayload

  const {
    entityState,
    entityState: {
      country_calling_code, first_name, last_name, legal_name, email, mobile, time_zone,
    },
    errors,
    handlers,
    saveEnabled,
    setEntityState,
  } = useUserForm(user)

  const { strings } = useLocalization(localizedStrings)

  const updateUserPayload = {
    callbacks: {
      action: updateFn,
      afterAction: closeModal,
    },
    entityParams: { userParams: entityState, customFields: [] },
    toastText: strings.updateUser || 'User Updated Successfully',
  }

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.header?.title || 'Edit'}
        titleSecondLine={strings.header?.titleSecondLine || 'User'}
      />

      <SidebarModal.Content>
        <Box flexDirection="column">
          <FormField
            boxProps={{ marginBottom: 'large' }}
            label={strings.formFieldLabels?.firstName || '* First Name'}
            errorMessage={errors.first_name}
          >
            <input name="first_name" type="text" value={first_name} {...handlers} />
          </FormField>

          <FormField
            boxProps={{ marginBottom: 'large' }}
            label={strings?.formFieldLabels?.lastName || '* Last Name'}
            errorMessage={errors.last_name}
          >
            <input name="last_name" type="text" value={last_name} {...handlers} />
          </FormField>

          <FormField boxProps={{ marginBottom: 'large' }} label={strings?.formFieldLabels?.legalName || 'Legal Name'}>
            <input name="legal_name" type="text" value={legal_name} {...handlers} />
          </FormField>

          <FormField
            boxProps={{ marginBottom: 'large' }}
            label={strings?.formFieldLabels?.email || '* Email'}
            errorMessage={errors.email}
          >
            <input name="email" type="email" value={email} {...handlers} />
          </FormField>

          <FormField
            boxProps={{ marginBottom: 'large' }}
            fontSize="small"
            label={strings?.formFieldLabels?.mobile || 'Mobile'}
          >
            <Box flexDirection="row" alignItems="center">
              <Box style={{ width: '30%', marginRight: 8 }}>
                <select
                  data-validate-field-on="change"
                  onChange={e => setEntityState({ country_calling_code: e.target.value })}
                  value={country_calling_code}
                >
                  <option value="">{strings.select?.option || 'Select...'}</option>
                  {countryCallingCodes.map(code => (
                    <option key={code.id} value={code.value}>
                      {code.title}
                    </option>
                  ))}
                </select>
              </Box>

              <FormField errorMessage={errors.mobile} style={{ width: '70%' }}>
                <input name="mobile" type="text" value={mobile} {...handlers} />
              </FormField>
            </Box>
          </FormField>

          <FormField
            boxProps={{ marginBottom: 'large' }}
            label={strings?.formFieldLabels?.timeZone || '* Time Zone'}
            errorMessage={errors.time_zone}
          >
            <select
              data-validate-field-on="change"
              onChange={e => setEntityState({ time_zone: e.target.value })}
              value={time_zone}
            >
              <option value="">{strings.option || 'Please Select...'}</option>
              {timeZones.map(timeZone => (
                <option key={timeZone} value={timeZone}>
                  {timeZone}
                </option>
              ))}
            </select>
          </FormField>
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={creating || updating}
          loadingBubbleColor="white"
          onClick={() => updateUser(updateUserPayload)}
          size="large"
        >
          {strings?.buttons?.update || 'Update User'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

EditUserModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

EditUserModal.defaultProps = {
  modalKey: 'EditUserModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <EditUserModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
