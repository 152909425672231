export default {
  default: {
    button: {
      subtitle: 'Custom and agency comparables',
      title: 'Skip Data Match',
    },
    introText: {
      paragraph: "In order to find the correct comparable properties you'll need to match the project you're working on with an identity code on that platform.",
      title: 'Match your project with a platform ID',
    },
    labels: {
      externalId: 'ID #{externalId}',
      noTarget: 'No Target',
      platform: 'Platform',
      potentialMatches: 'Potential Matches',
    },
    noAvailableProperties: 'No Available Properties',
    options: {
      pleaseSelect: 'Please select...',
    },
  },
}
