import { DateTime } from 'luxon'
import { format as d3 } from 'd3-format'

const formatCurrency = (currencyFormat: string, value: string) => {
  try {
    const format = currencyFormat || '$,.2f'
    return d3(format)(Number(value))
  } catch (error){
    return value
  }
}

const formatDate = (dateFormat: string, value: string): string => {
  try {
    return DateTime.fromISO(value).toFormat(dateFormat)
  } catch (error){
    return value
  }
}

const formatValue = (dataFormatter: string, dataFormat: string, value: string): string => {
  if (dataFormatter === 'currency'){
    return formatCurrency(dataFormat, value)
  }

  if (dataFormatter === 'date'){
    return formatDate(dataFormat, value)
  }

  return value
}

type UseDataFormatterOptions = {
  dataFormat: string,
  dataFormatter: string,
}

function useDataFormatter(value: string, options: UseDataFormatterOptions) {
  const { dataFormatter, dataFormat } = options || {}

  const formattedValue = value ? formatValue(dataFormatter, dataFormat, value) : ''

  return {
    formattedValue,
  }
}

export default useDataFormatter
