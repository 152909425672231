import PropTypes from 'prop-types'

import { Box, ListItem, Text } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const EventCalendarTemplate = (props) => {
  const {
    callbacks: { selectTemplate },
    eventCalendarTemplate: {
      description, event_count, event_group_count, title,
    },
  } = props

  const {
    callbacks: { formatCount },
  } = useLocalization(localizedStrings)

  return (
    <ListItem
      boxProps={{
        border: '1px solid',
        borderColor: 'lineColor',
        borderRadius: 5,
        marginBottom: 'medium',
        padding: 'large',
      }}
      forceBottomBorder
      onClick={() => selectTemplate()}
      showAngleIcon
    >
      <Box flexDirection="column">
        <Text fontSize="small">{title}</Text>

        <Text color="bodyFontLightColor" marginTop="small" fontSize="xsmall" variant="ellipsis">
          {description}
        </Text>

        <Text color="bodyFontLightColor" marginTop="small" fontSize="xsmall">
          {formatCount('events', { count: event_count })}
          {formatCount('eventGroups', { count: event_group_count })}
        </Text>
      </Box>
    </ListItem>
  )
}

EventCalendarTemplate.propTypes = {
  callbacks: PropTypes.shape({
    selectTemplate: PropTypes.func.isRequired,
  }).isRequired,
  eventCalendarTemplate: PropTypes.object.isRequired,
}

export default EventCalendarTemplate
