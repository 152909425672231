import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useSetState } from '@campaignhub/react-hooks'

import useDigitalTemplates from '@hooks/useDigitalTemplates'
import useOrganizationSelector from '@hooks/useOrganizationSelector'

const defaultRequestOptions = {
  digitalTemplate: {
    include_digital_template_preview_image: true,
  },
  image: {
    include_image_sizes: ['thumb_660x360'],
  },
}

const defaultState = {
  filterString: '',
  owner_id: null,
  owner_type: 'Organization',
  selectedDigitalTemplateId: null,
  title: '',
}

function useDuplicateDigitalTemplate(options = {}){
  const { ownerId, ownerType } = options || {}

  const [state, setState] = useSetState(defaultState)
  const {
    owner_id, filterString, selectedDigitalTemplateId, title,
  } = state

  const { hasMultipleOrganizations, selectedOrganization } = useOrganizationSelector()

  useEffect(() => {
    setState({
      owner_type: ownerType || 'Organization',
      owner_id: ownerId || selectedOrganization.id,
    })
  }, [ownerId, ownerType])

  const { creating, loadedForKeys, loading } = useSelector(reduxState => reduxState.digitalTemplates)

  const entities = useSelector(reduxState => reduxState.entities)
  const { digitalTemplates, images, organizations } = entities

  // Templates
  const digitalTemplatesEntityKey = 'DigitalTemplates-ProductionReady'
  const digitalTemplatesPayload = useDigitalTemplates({
    ...defaultRequestOptions.digitalTemplate,
    ...defaultRequestOptions.image,
    entityKey: digitalTemplatesEntityKey,
    exclude_digital_template_type_key: 'template_partial',
    filters: {
      string: filterString,
      template_partial: false,
    },
    performHttpRequests: !loadedForKeys.includes(digitalTemplatesEntityKey),
    status: 'current',
  })
  const { filteredDigitalTemplates } = digitalTemplatesPayload

  const selectedDigitalTemplate = digitalTemplates[selectedDigitalTemplateId] || {}

  const selectedOwner = organizations[owner_id] || {}

  const hasDigitalTemplates = !!filteredDigitalTemplates.length

  const canCreateTemplate = owner_id && selectedDigitalTemplate.id && !!title

  return {
    callbacks: {
      setState,
    },
    canCreateTemplate,
    creating,
    filteredDigitalTemplates,
    hasDigitalTemplates,
    hasMultipleOrganizations,
    images,
    loading,
    selectedDigitalTemplate,
    selectedOwner,
    state,
  }
}

export default useDuplicateDigitalTemplate
