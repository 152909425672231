import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, Image, ListItem, Text,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import { capitalizeString } from '@functions/string'

import useComparable from '@hooks/useComparable'
import useLocalization from '@hooks/useLocalization'
import useProject from '@hooks/useProject'

import useEntityCustomData from '@hooks/useEntityCustomData'

import placeholderImageUrl from '../assets/comparables-blank-state.svg'

import localizedStrings from './localizedStrings'

const LargeList = (props) => {
  const {
    comparable,
    comparable: { project_id, source },
    customFields,
  } = props

  const {
    defaultImage,
    fullAddress,
  } = useComparable(comparable)

  const customDataPayload = useEntityCustomData(comparable, customFields)
  const {
    customData: {
      bathrooms,
      bedrooms,
      carparks,
    },
  } = customDataPayload

  const { urls: { comparablesUrl } } = useProject({ id: project_id })

  const imageUrl = digObject(defaultImage, 'sizes.thumb_200x120', '')

  const { strings } = useLocalization(localizedStrings)

  return (
    <ListItem
      boxProps={{ as: 'a', borderBottom: '1px solid', padding: 'large' }}
      href={comparablesUrl}
      showAngleIcon
    >
      <Box width={110} marginRight="large">
        <Image
          borderRadius={5}
          boxProps={{ alignItems: 'flex-end', justifyContent: 'flex-start', padding: 'medium' }}
          height={60}
          marginRight="medium"
          placeholderUrl={placeholderImageUrl}
          url={imageUrl}
          width={100}
        />
      </Box>

      <Box flexDirection="row" alignItems="center">
        <Box flexDirection="column">
          <Text fontSize="small" marginRight="auto" marginBottom="medium">{fullAddress}</Text>

          <Text
            color="bodyFontLightColor"
            fontSize="xsmall"
            paddingTop="medium"
            variant="ellipsis"
          >
            {`${bedrooms || 0} ${strings.bed || 'Bed'} | `}
            {`${bathrooms || 0} ${strings.bath || 'Bath'} | `}
            {`${carparks || 0} ${strings.car || 'Car'} `}
          </Text>
        </Box>

        <Text color="bodyFontLightColor" fontSize="xsmall">
          {capitalizeString(source)}
        </Text>
      </Box>
    </ListItem>
  )
}

LargeList.propTypes = {
  comparable: PropTypes.object.isRequired,
  customFields: PropTypes.array.isRequired,
}

export default LargeList
