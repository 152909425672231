import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { modifyGroupedIdsSort, sortArrayBy } from '@campaignhub/javascript-utils'
import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useDocumentTemplate from '@hooks/useDocumentTemplate'

const watchEntityKeys = ['documentRecipients']

const modifyRecipientSort = (fromIndex, toIndex, payload, droppableId, sortedGroups, setSortedRecipientIds) => {
  const groupedIds = modifyGroupedIdsSort(fromIndex, toIndex, payload, sortedGroups)
  return setSortedRecipientIds(groupedIds[droppableId])
}

function useManageDocumentRecipients(options = {}){
  const { documentTemplate, ownerType, ownerId } = options || {}

  const droppableId = `${ownerType}-${ownerId}`

  const [sortedRecipientIds, setSortedRecipientIds] = useState([])

  const {
    updatedEntities: { documentRecipients: documentRecipientsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { updating: updatingAgreements } = useSelector(reduxState => reduxState.agreements)

  const entities = useSelector(reduxState => reduxState.entities)
  const { documentRecipients } = entities

  const filteredRecipients = useMemo(() => {
    const array = Object.values(documentRecipients)
    const filtered = array.filter((documentRecipient) => {
      const { owner_id, owner_type } = documentRecipient
      return owner_id === ownerId && owner_type === ownerType
    })

    const sorted = sortArrayBy(filtered, 'asc', 'sort')

    return sorted
  }, [ownerType, ownerId, documentRecipientsUpdatedAt])

  const sortedRecipients = useMemo(() => {
    const sorted = sortedRecipientIds.map(recipientId => documentRecipients[recipientId]).filter(i => i)
    return sorted
  }, [JSON.stringify(sortedRecipientIds), documentRecipientsUpdatedAt])

  useEffect(() => {
    if (filteredRecipients.length){
      const sortedIds = filteredRecipients.map(recipient => recipient.id)
      setSortedRecipientIds(sortedIds)
    }
  }, [ownerType, ownerId, documentRecipientsUpdatedAt])

  const { filteredRecipients: documentTemplateRecipients } = useDocumentTemplate(documentTemplate)

  const documentTemplateRecipientIds = filteredRecipients
    .map(recipient => recipient.document_template_recipient_id)
    .filter(id => id)

  const unassignedRecipients = documentTemplateRecipients.filter(
    recipient => !documentTemplateRecipientIds.includes(recipient.id),
  )

  return {
    callbacks: {
      modifyRecipientSort: (fromIndex, toIndex, payload) => modifyRecipientSort(
        fromIndex,
        toIndex,
        payload,
        droppableId,
        { [droppableId]: sortedRecipientIds },
        setSortedRecipientIds,
      ),
      setSortedRecipientIds,
    },
    droppableId,
    filteredRecipients,
    hasUnassignedRecipients: !!unassignedRecipients.length,
    ownerId,
    ownerType,
    sortedRecipientIds,
    sortedRecipients,
    unassignedRecipients,
    updating: updatingAgreements,
  }
}

export default useManageDocumentRecipients
