import React from 'react'
import { Route, Routes } from 'react-router-dom'

import Analytics from './screens/Analytics'
import ApiLogs from './screens/ApiLogs'
import BrandEdit from './screens/Brands/screens/BrandEdit'
import BrandList from './screens/Brands/screens/BrandList'
import ComponentStyles from './screens/ComponentStyles'
import CustomFieldSetList from './screens/CustomFields/screens/CustomFieldSetList'
import DataLibrary from './screens/DataLibrary'
import EditCustomFieldSet from './screens/CustomFields/screens/EditCustomFieldSet'
import FormTemplates from './screens/FormTemplates'
import ImageLibrary from './screens/ImageLibrary'
import Integrations from './screens/Integrations'
import OrganizationEdit from './screens/Organizations/screens/OrganizationEdit'
import OrganizationList from './screens/Organizations/screens/OrganizationList'
import Reports from './screens/Reports'
import TargetAudience from './screens/TargetAudiences'
import UserEdit from './screens/Users/screens/UserEdit'
import UserList from './screens/Users/screens/UserList'

const SystemManager = () => (
  <Routes>
    <Route path="/analytics/*" element={<Analytics />} />
    <Route path="/apiLogs" element={<ApiLogs />} />
    <Route path="/brands" element={<BrandList />} />
    <Route path="/brands/:brandId/edit" element={<BrandEdit />} />
    <Route path="/componentStyles" element={<ComponentStyles />} />
    <Route path="/customFieldSets" element={<CustomFieldSetList />} />
    <Route path="/customFieldSets/:customFieldSetId/edit" element={<EditCustomFieldSet />} />
    <Route path="/dataLibraries" element={<DataLibrary />} />
    <Route path="/formTemplates" element={<FormTemplates />} />
    <Route path="/imageLibraries" element={<ImageLibrary />} />
    <Route path="/integrations" element={<Integrations />} />
    <Route path="/organizations" element={<OrganizationList />} />
    <Route path="/organizations/:organizationId/edit" element={<OrganizationEdit />} />
    <Route path="/reports" element={<Reports />} />
    <Route path="/targetAudiences" element={<TargetAudience />} />
    <Route path="/users" element={<UserList />} />
    <Route path="/users/:userId/edit" element={<UserEdit />} />
  </Routes>
)

export default SystemManager
