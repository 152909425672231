import React from 'react'

import { BlankState } from '@campaignhub/suit-theme'

import blankImageUrl from './assets/CustomFieldsBlank.svg'

const CustomFieldSetBlankState = () => (
  <BlankState imageUrl={blankImageUrl}>
    <BlankState.Title>No Custom Fields</BlankState.Title>
    <BlankState.Paragraph>
      Try adjusting your filters to return more results.
    </BlankState.Paragraph>
  </BlankState>
)

export default CustomFieldSetBlankState
