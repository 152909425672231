import React from 'react'
import { toast } from 'react-toastify'

import { useModals, useSetState } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import useOrganizationSelector from '@hooks/useOrganizationSelector'

import CreateDocumentTemplateModal from '@modals/CreateDocumentTemplateModal'

import PageContent from './components/PageContent'

const createDocumentTemplate = (params, createFn) => {
  createFn(params).then(({ success, errors, redirectUrl }) => {
    if (!success){
      toast.warning(errors[0])
      return
    }
    if (redirectUrl){
      window.location.href = redirectUrl
    }
  })
}

const callbacks = (component, setState) => {
  const componentCallbacks = {
    CreateDocumentTemplateModal: {
      closeModal: () => setState({ showCreateDocumentTemplateModal: false }),
      createDocumentTemplate,
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  pageFilters: {},
  showCreateDocumentTemplateModal: false,
}

const DocumentTemplates = () => {
  const [state, setState] = useSetState(defaultState)
  const { pageFilters, showCreateDocumentTemplateModal } = state

  const { selectedOrganization } = useOrganizationSelector()

  const modalContext = useModals()

  const pageContext = {
    callbacks: {
      resetFilters: (resetFn) => {
        if (resetFn) resetFn()
        setState({ pageFilters: {} })
      },
      showCreateDocumentTemplateModal: () => setState({ showCreateDocumentTemplateModal: true }),
      updateFilters: (filters) => { setState({ pageFilters: filters }) },
    },
  }

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent pageFilters={pageFilters} selectedOrganization={selectedOrganization} />

        <CreateDocumentTemplateModal
          callbacks={callbacks('CreateDocumentTemplateModal', setState)}
          selectedOrganization={selectedOrganization}
          showModal={showCreateDocumentTemplateModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default DocumentTemplates
