import { useMemo } from 'react'

import { matchFilterString, sortArrayBy } from '@campaignhub/javascript-utils'

import type { ModuleState } from '@redux/modules/types'

import useReduxAction from '@hooks/useReduxAction'

export type DataSetModel = {
  owner_id: number | null,
  owner_type: string,
  subject_id: number | null,
  subject_type: string,
  title: string,
}

export type UseDataSetOptions = {
  filters?: Record<string, string | number | null>,
  performHttpRequests?: boolean,
  refetchData?: boolean,
}

function useDataSets(options: UseDataSetOptions = {}) {
  const { filters = {}, performHttpRequests, refetchData } = options

  const { string: fitlerString, brand_id: filterBrandId } = filters

  const { data: { entities: dataSets } = {}, loading } = useReduxAction(
    'attachments',
    'fetchDataSetsFromExternalPlatform',
    {
      string: fitlerString,
      ...filters,
    },
    [JSON.stringify(options), refetchData],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  const filteredDataSets = useMemo(() => {
    if (!dataSets) return []
    const filtered = dataSets?.filter((dataSet) => {
      const { brand_id, title } = dataSet

      const titleMatch = matchFilterString(title, fitlerString)
      const brandMatch = filterBrandId ? filterBrandId === brand_id : true
      return brandMatch && titleMatch
    })

    return sortArrayBy(filtered, 'desc', 'created_date')
  }, [JSON.stringify(options), dataSets?.length, loading])

  const hasDataSets = !!dataSets?.length

  return {
    filteredDataSets,
    hasDataSets,
    loading,
  }
}

export default useDataSets
