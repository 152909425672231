export default {
  default: {
    exportUsersButton: 'Export Users',
    reportQueuedMessage: 'Report has been queued for building.',
    filterByNameLabel: 'Filter by Name',
    filters: 'Filters',
    importUsersButton: 'Import Users',
    limitLabel: 'Limit',
    sortUsersButton: 'Sort Users',
    title: 'Users',
    titleAll: 'All Users',
    tools: 'Tools',
  },
}
