import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, Checkbox, ListItem, Text,
} from '@campaignhub/suit-theme'

import useDigitalPageFeature from '../../hooks/useDigitalPageFeature'

const Feature = (props) => {
  const {
    callbacks: { manageDigitalPageFeature },
    digitalPageId,
    feature: { key, label },
  } = props

  const { featureCompleted } = useDigitalPageFeature(key, { id: digitalPageId })

  return (
    <ListItem
      boxProps={{
        as: 'a',
        borderBottom: '1px dashed',
        paddingY: 'medium',
        paddingX: 'large',
      }}
      onClick={() => manageDigitalPageFeature(key)}
      showAngleIcon
      forceBottomBorder
    >
      <Box paddingY="medium" width="auto">
        <Checkbox checked={featureCompleted} />
      </Box>

      <Box alignItems="center" justifyContent="flex-start" minWidth="0" paddingRight="medium" width="auto">
        <Text color="bodyFontLightColor" fontSize="small" variant="ellipsis">
          {label}
        </Text>
      </Box>
    </ListItem>
  )
}

Feature.propTypes = {
  callbacks: PropTypes.shape({
    manageDigitalPageFeature: PropTypes.func,
  }).isRequired,
  digitalPageId: PropTypes.number,
  feature: PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
}

export default Feature
