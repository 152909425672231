export default {
  default: {
    customizableDashboard: {
      main: 'Main',
      sidebar: 'Sidebar',
    },
    analytics: {
      title: 'Analytics',
    },
    helmet: {
      title: 'Analytics',
    },
    pageHeader: {
      create: 'Create',
      customize: 'Customize Dashboard',
      save: 'Save Dashboard',
      title: 'Analytics',
    },
    acceptedVsCreatedPerMonth: 'Proposals Accepted vs Created',
    averageProposalReadPercentage: 'Average Proposal Read Percentage',
    comparisions: 'Comparisons',
    engagementStatusCounts: 'Engagement Status Counts',
    leaderboard: 'Leaderboard',
    templatesLeaderboard: 'Most Engaging Templates',
    usersLeaderboard: 'Proposals Leaderboard',
    performance: 'Performance',
    statistics: 'Statistics',
    trends: 'Trends',
    totalProposalsCreated: 'Total Proposals Created',
    DigitalPagesEventsPerDay: 'Digital Pages Created vs Viewed Per Day',
    digitalPagesEngagementPerDay: 'Digital Pages Engagement Per Day',
    digitalPagesViewsVsSentRatioPerDay: 'Digital Pages Views Vs Sent Ratio Per Day',
  },
}
