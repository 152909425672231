export default {
  default: {
    recipients: {
      type: 'count',
      none: 'No Recipients',
      one: '{count} Recipient',
      many: '{count} Recipients',
    },
  },
}
