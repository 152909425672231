import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe, faSignOut } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Columns, DashboardModule, LoadingModule, PageHeader, Text,
} from '@campaignhub/suit-theme'

import { getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'

import useAddresses from '@hooks/useAddresses'
import useNumericParams from '@hooks/useNumericParams'
import useOrganization, { useOrganizationForm } from '@hooks/useOrganization'
import useReduxAction from '@hooks/useReduxAction'
import useUserImpersonation from '@hooks/useUserImpersonation'
import useUsers from '@hooks/useUsers'

import handleCallbackAction from '@functions/handleCallbackAction'

import defaultRequestOptions from '@sections/Admin/packs/SystemManager/defaultRequestOptions'

import Addresses from '@components/Addresses'
import UserListItem from '@components/UserListItem'

import OrganizationDetails from '../OrganizationDetails'

const updateOrganization = (entityParams, customFields, updateFn) => {
  updateFn(entityParams, customFields).then(({ success, errors }) => {
    if (!success && errors){
      toast.warn(errors[0])
      return
    }

    toast('Organisation has been updated!')
  })
}

const requestOptions = {
  include_organization_users: true,
}

const PageContent = () => {
  const { organizationId } = useNumericParams()

  useReduxAction(
    'organizations',
    'loadOrganization',
    {
      ...defaultRequestOptions.organization,
      ...defaultRequestOptions.user,
      ...requestOptions,
    },
    [organizationId],
    {
      dispatchAction: action => action(organizationId, defaultRequestOptions.organization),
      shouldPerformFn: ({ loadedIds }) => organizationId && !loadedIds.includes(organizationId),
    },
  )

  useReduxAction('organizationTypes', 'loadOrganizationTypes')

  useReduxAction('brands', 'loadBrands')

  const { organizations } = useSelector(reduxState => reduxState.entities)
  const organization = organizations[organizationId] || {}

  const organizationPayload = useOrganization(organization)
  const {
    callbacks: { generateSupportToken, updateOrganization: updateFn },
    loading,
    updating,
  } = organizationPayload

  const userImpersonationPayload = useUserImpersonation()
  const { callbacks: { impersonateUser } } = userImpersonationPayload

  const organizationForm = useOrganizationForm(organization)
  const { entityState, saveEnabled } = organizationForm

  const { onboarding } = entityState

  const { filteredUsers, hasFilteredUsers } = useUsers({ filters: { organization_id: organization.id } })

  const { filteredAddresses } = useAddresses({ owner_id: organization.id, owner_type: 'Organization' })

  const { redirect } = parsePermittedQueryParams(getQueryParamsFromHash(), ['redirect'])

  const loginAsOrganizationPayload = {
    callbacks: {
      action: () => generateSupportToken(organization.id),
      afterAction: ({ response: { data } }) => impersonateUser(data),
    },
    entityParams: organization,
  }

  return (
    <>
      <PageHeader
        actionContent={(
          <>
            {onboarding && (
              <Button
                buttonStyle="secondaryEdit"
                icon={<FontAwesomeIcon icon={faSignOut} />}
                loading={loading}
                marginRight="medium"
                onClick={() => handleCallbackAction(loginAsOrganizationPayload)}
                size="medium"
                width="auto"
              >
                Login
              </Button>
            )}

            <Button buttonStyle="secondaryUtility" as="a" href={redirect || '/'} size="medium" width="auto">
              Back
            </Button>
          </>
        )}
        activeTabBarItemKey="organisation"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        tabBarItems={[
          {
            href: '#/',
            icon: faGlobe,
            key: 'organisation',
            title: 'Organisation',
          },
        ]}
        title="Organisation Details"
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && (
              <>
                <OrganizationDetails organizationForm={organizationForm} />

                <Addresses addresses={filteredAddresses} organization={organization} />

                {hasFilteredUsers && (
                  <DashboardModule title="Users">
                    <Box flexDirection="column" maxHeight={300} overflow="auto">
                      {filteredUsers.map(user => (
                        <UserListItem key={user.id} user={user} />
                      ))}
                    </Box>
                  </DashboardModule>
                )}

                <Box
                  variant="white"
                  padding="large"
                  style={{ alignItems: 'center', justifyContent: 'space-between', marginBottom: 'large' }}
                >
                  <Text color="bodyFontLightColor" display={['none', 'block']} fontSize="small">
                    Save organisation details
                  </Text>

                  <Button
                    buttonStyle="primaryCreate"
                    disabled={!saveEnabled}
                    loading={updating}
                    marginLeft="auto"
                    onClick={() => updateOrganization(entityState, [], updateFn)}
                    size="medium"
                    width="auto"
                  >
                    Save Details
                  </Button>
                </Box>
              </>
            )}
          </Columns.Main>
        </Columns>
      </Box>
    </>
  )
}

export default PageContent
