import { useEffect } from 'react'

import { useSetState, useThunkDispatch } from '@campaignhub/react-hooks'

import { toggleArray } from '@campaignhub/javascript-utils'

import * as metricCollectionActions from '@redux/modules/digitalPageMetricCollection'

const defaultEntityKeys = ['System', 'Brand', 'Organization']

const toggleEntityKey = (entityKey, state, setState) => {
  const { selectedEntityKeys } = state

  const updatedEntityKeys = toggleArray(selectedEntityKeys, entityKey)

  setState({ selectedEntityKeys: updatedEntityKeys })
}

const buildDigitalPageMetrics = (selectedEntityKeys, dispatch) => {
  const { buildMonthlyDigitalPageMetricCollections: buildFn } = metricCollectionActions
  return dispatch(buildFn({ entity_keys: selectedEntityKeys }))
}

const defaultState = {
  selectedEntityKeys: [],
}

const useBuildDigitalPageMetrics = () => {
  const [state, setState] = useSetState(defaultState)
  const { selectedEntityKeys } = state

  const dispatch = useThunkDispatch()

  useEffect(() => {
    setState({ selectedEntityKeys: defaultEntityKeys })
  }, [])

  return {
    callbacks: {
      buildDigitalPageMetrics: () => buildDigitalPageMetrics(selectedEntityKeys, dispatch),
      setState,
      toggleEntityKey: entityKey => toggleEntityKey(entityKey, state, setState),
    },
    defaultEntityKeys,
    state,
  }
}

export default useBuildDigitalPageMetrics
