// As new entities are added that allow custom field sets this file will need to be updated to stay in sync

interface EntityTypesType {
  key: string,
  label: string,
}

const entityTypes: Array<EntityTypesType> = [
  { key: 'Address', label: 'Address' },
  { key: 'Award', label: 'Award' },
  { key: 'Brand', label: 'Brand' },
  { key: 'CaseStudy', label: 'Case Study' },
  { key: 'Contact', label: 'Contact' },
  { key: 'Organization', label: 'Organisation' },
  { key: 'Project', label: 'Project' },
  { key: 'ProjectComparable', label: 'Project Comparable' },
  { key: 'Review', label: 'Review' },
  { key: 'User', label: 'User' },
]

export default entityTypes
