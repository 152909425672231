import React from 'react'
import PropTypes from 'prop-types'

import { Link, SidebarModal } from '@campaignhub/suit-theme'

import FieldHolder from '@components/FieldHolder'
import Input from '@components/Input'

import ShortcodeSearch from '@components/digitalTemplates/ShortcodeSearch'

import MapData from './MapData'

const shouldRender = (entityType, itemType) => {
  const permittedEntities = ['digitalTemplatePageSection']
  const permittedItemTypes = [
    'comparables', 'dataArrayLoop', 'icon', 'iframe', 'image', 'imageCarousel', 'map', 'reviews', 'text', 'video',
  ]
  return permittedEntities.includes(entityType) || permittedItemTypes.includes(itemType)
}
const updateData = (name, value, props) => {
  const {
    callbacks: { updateParam },
    selectedEntity,
  } = props

  const updatedStyle = {
    ...selectedEntity.data,
    [name]: value,
  }

  updateParam('data', updatedStyle)
}

const renderFormatter = (selectedEntity, itemData, props) => {
  const { id, item_type } = selectedEntity
  const { dataFormatter } = itemData

  if (item_type === 'text'){
    return (
      <>
        <FieldHolder boxProps={{ marginTop: 'large' }} label="Formatter">
          <select onChange={e => updateData('dataFormatter', e.target.value, props)} value={dataFormatter || ''}>
            <option value="">None</option>
            <option value="currency">Currency</option>
            <option value="date">Date</option>
          </select>
        </FieldHolder>

        {!!dataFormatter && (
          <>
            <Input
              boxProps={{ marginTop: 'large' }}
              data={itemData}
              entityId={id}
              label="Format"
              propertyKey="dataFormat"
              updateCallback={(name, value) => updateData(name, value, props)}
              version={itemData.dataFormat}
            />

            <Link
              textProps={{ alignSelf: 'flex-end', fontSize: 'xsmall', marginTop: 'medium' }}
              href={
                dataFormatter === 'date'
                  ? 'https://moment.github.io/luxon/#/formatting?id=table-of-tokens'
                  : 'https://github.com/d3/d3-format#api-reference'
              }
              target="_blank"
            >
              Formatting Options
            </Link>
          </>
        )}
      </>
    )
  }

  return null
}

const renderValue = (selectedEntity, itemData, props) => {
  const { id, item_type } = selectedEntity

  const unpermittedTypes = ['comparables', 'map', 'reviews']
  if (!unpermittedTypes.includes(item_type)){
    return (
      <Input
        data={itemData}
        entityId={id}
        label="Value"
        propertyKey="value"
        textarea
        updateCallback={(name, value) => updateData(name, value, props)}
        version={itemData.value}
      />
    )
  }

  return null
}

const renderShortcode = (selectedEntity, itemData, props) => {
  const { id, item_type } = selectedEntity

  const unpermittedTypes = ['icon', 'map']
  if (!unpermittedTypes.includes(item_type)){
    return (
      <Input
        boxProps={{ marginTop: 'large' }}
        data={itemData}
        entityId={id}
        label="Shortcode"
        propertyKey="shortcode"
        updateCallback={(name, value) => updateData(name, value, props)}
      />
    )
  }

  return null
}

const renderShortcodeSearch = (itemData, props) => {
  const {
    dataContext,
    selectedEntity: { item_type },
  } = props

  if (item_type === 'text' || (item_type === 'dataArrayLoop' && dataContext.length)){
    return (
      <ShortcodeSearch
        callbacks={{ selectItem: value => updateData('value', value, props) }}
        dataContext={dataContext}
        itemData={itemData}
      />
    )
  }

  return null
}

const renderMapData = (selectedEntity, itemData, props) => {
  const {
    selectedEntity: { item_type },
  } = props

  if (item_type === 'map'){
    return (
      <MapData
        selectedEntity={selectedEntity}
        callbacks={{ updateData: value => updateData('locations', value, props) }}
        itemData={itemData}
      />
    )
  }

  return null
}

const Data = (props) => {
  const {
    entityType,
    selectedEntity,
    selectedEntity: { item_type },
  } = props

  if (!shouldRender(entityType, item_type)) return null

  const itemData = selectedEntity.data || {}

  return (
    <SidebarModal.ExpandableSectionBox defaultOpen label="Data">
      {renderShortcodeSearch(itemData, props)}
      {renderValue(selectedEntity, itemData, props)}
      {renderShortcode(selectedEntity, itemData, props)}
      {renderFormatter(selectedEntity, itemData, props)}
      {renderMapData(selectedEntity, itemData, props)}
    </SidebarModal.ExpandableSectionBox>
  )
}

Data.propTypes = {
  dataContext: PropTypes.array,
  entityType: PropTypes.string,
  selectedEntity: PropTypes.object.isRequired,
}

Data.defaultProps = {
  dataContext: [],
}

export default Data
