import { useMemo } from 'react'

import { Helmet } from 'react-helmet-async'

import { useModals, useSetState } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import useLocalization from '@hooks/useLocalization'
import useOrganizationSelector from '@hooks/useOrganizationSelector'
import useOrganizationsUser from '@hooks/useOrganizationsUser'
import useReduxAction from '@hooks/useReduxAction'

import handleCallbackAction from '@functions/handleCallbackAction'

import CreateOrEditTeamModal from '@modals/CreateOrEditTeamModal'

import defaultRequestOptions from '@sections/Client/packs/Admin/defaultRequestOptions'

import localizedStrings from './localizedStrings'

import PageContent from './PageContent'

const callbacks = (component, setState) => {
  const componentCallbacks = {
    CreateOrEditTeamModal: {
      closeModal: () => setState({ showCreateOrEditTeamModal: false }),
      createTeam: payload => handleCallbackAction(payload),
      deleteTeam: payload => handleCallbackAction(payload),
      updateTeam: payload => handleCallbackAction(payload),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  pageFilters: {},
  showCreateOrEditTeamModal: false,
}

const Teams = () => {
  const [state, setState] = useSetState(defaultState)
  const { pageFilters, showCreateOrEditTeamModal } = state

  const { selectedOrganization } = useOrganizationSelector()
  const { isCurrentUserAdminForOrganization } = useOrganizationsUser({ organization_id: selectedOrganization.id })

  useReduxAction(
    'teams',
    'loadTeams',
    {
      ...defaultRequestOptions.image,
      ...defaultRequestOptions.team,
      ...pageFilters,
      owner_id: selectedOrganization.id,
      owner_type: 'Organization',
    },
    [selectedOrganization.id, JSON.stringify(pageFilters)],
  )

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const pageContext = useMemo(
    () => ({
      callbacks: {
        resetFilters: (resetFn) => {
          if (resetFn) resetFn()
          setState({ pageFilters: {} })
        },
        showCreateOrEditTeamModal: (payload) => {
          setModalData('CreateOrEditTeamModal', payload)
          setState({ showCreateOrEditTeamModal: true })
        },
        updateFilters: (filters) => {
          setState({ pageFilters: filters })
        },
      },
    }),
    [],
  )

  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      <Helmet>
        <title>{strings.title || 'Teams | Engage'}</title>
      </Helmet>

      <PageContext.Provider value={pageContext}>
        <ModalContext.Provider value={modalContext}>
          <PageContent
            isCurrentUserAdminForOrganization={isCurrentUserAdminForOrganization}
            pageFilters={pageFilters}
            selectedOrganization={selectedOrganization}
          />

          <CreateOrEditTeamModal
            callbacks={callbacks('CreateOrEditTeamModal', setState)}
            showModal={showCreateOrEditTeamModal}
          />
        </ModalContext.Provider>
      </PageContext.Provider>
    </>
  )
}

export default Teams
