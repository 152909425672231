import { useMemo } from 'react'

import {
  digObject, matchFilterKey, matchFilterNumber, sortArrayBy,
} from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { IntegrationModel } from '@models/types'
import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['integrations']

const generateUrls = () => ({
  integrationsSystemManagerUrl: '#/systemManager/integrations',
  integrationsUrl: '#/admin/integrations',
})

const matchIntegrationFeature = (integration: IntegrationModel, featureKeys: string[]) => {
  const options = digObject(integration, 'options', {})

  const featureEnabled = featureKeys.some(featureKey => options[featureKey] || false)

  return featureEnabled
}

interface GroupedIntegration {
  id: number,
  integrations: IntegrationModel[],
}

const groupIntegrationsByPlatform = (filteredIntegrations: IntegrationModel[]) => {
  const groupedIntegrations: Record<number, GroupedIntegration> = {}

  filteredIntegrations.forEach((integration) => {
    const { integration_platform_id } = integration

    if (!groupedIntegrations[integration_platform_id]){
      groupedIntegrations[integration_platform_id] = {
        id: integration_platform_id,
        integrations: [],
      }
    }

    groupedIntegrations[integration_platform_id].integrations.push(integration)

    return groupedIntegrations
  })

  return groupedIntegrations
}

type IntegrationFilters = {
  integration_platform?: number,
  owner_id?: number,
  owner_type?: string,
  subject_id?: number,
  subject_type?: string,
}

type UseIntegrationsOptions = {
  featureKeys?: string[],
  filters?: IntegrationFilters,
  includeSystemIntegrations?: boolean,
  performHttpRequests?: boolean,
}

function useIntegrations(options: UseIntegrationsOptions) {
  const { featureKeys, filters = {}, includeSystemIntegrations } = options
  const {
    integration_platform: filteredIntegrationPlatformId,
    owner_id: filteredOwnerId,
    owner_type: filteredOwnerType,
    subject_id: filteredSubjectId,
    subject_type: filteredSubjectType,
  } = filters

  const {
    updatedEntities: { integrations: integrationsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const entities = useSelector(reduxState => reduxState.entities)
  const { integrations, integrationPlatforms } = entities

  const filteredIntegrations = useMemo(() => {
    const filtered = Object.values(integrations).filter((integration: IntegrationModel) => {
      const {
        integration_platform_id, integration_scope, owner_id, owner_type, subject_id, subject_type,
      } = integration
      const featureMatch = featureKeys && featureKeys.length ? matchIntegrationFeature(integration, featureKeys) : true

      const platformMatch = matchFilterNumber(integration_platform_id, filteredIntegrationPlatformId)

      const ownerIdMatch = filteredOwnerId
        ? matchFilterNumber(owner_id, filteredOwnerId)
        : true

      const ownerTypeMatch = filteredOwnerType
        ? matchFilterKey(owner_type, filteredOwnerType)
        : true

      const subjectIdMatch = matchFilterNumber(subject_id, filteredSubjectId)

      const subjectTypeMatch = matchFilterKey(subject_type, filteredSubjectType)

      const scopeMatch = integration_scope === 'user' ? subjectIdMatch && subjectTypeMatch : true

      const systemMatch = includeSystemIntegrations ? owner_type === 'System' : false

      return featureMatch && scopeMatch && ((ownerIdMatch && ownerTypeMatch) || systemMatch) && platformMatch
    })

    return sortArrayBy(filtered, 'asc', 'title')
  }, [integrationsUpdatedAt, JSON.stringify(filters)])

  const filteredIntegrationsCount = filteredIntegrations.length
  const hasFilteredIntegrations = !!filteredIntegrationsCount

  const groupedIntegrations = groupIntegrationsByPlatform(filteredIntegrations)

  const filteredPlatformIds: number[] = Array.from(
    new Set(
      filteredIntegrations.map((integration) => {
        const { integration_platform_id } = integration
        const platform = integrationPlatforms[integration_platform_id] || {}
        return platform.id
      }),
    ),
  )

  const filteredPlatforms = filteredPlatformIds.map((platformId) => {
    const platform = integrationPlatforms[platformId] || {}
    return platform
  })

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredIntegrationsCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loaded, loading: loadingIntegrations } = useReduxAction(
    'integrations',
    'loadIntegrations',
    {
      include_system_integrations: !!includeSystemIntegrations,
      ...filtersWithOffset,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    entities,
    filteredIntegrations,
    filteredIntegrationsCount,
    filteredPlatforms: sortArrayBy(filteredPlatforms, 'asc', 'title'),
    groupedIntegrations,
    hasGroupedIntegrations: !!Object.values(groupedIntegrations).length,
    hasIntegrations: hasFilteredIntegrations,
    integrationPlatforms: sortArrayBy(Object.values(integrationPlatforms), 'asc', 'title'),
    loaded,
    loading: loadingIntegrations,
    urls: generateUrls(),
  }
}

export default useIntegrations
