import { useMemo } from 'react'

import useLocalization from '@hooks/useLocalization'

import { useModals, useSetState } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import handleCallbackAction from '@functions/handleCallbackAction'

import CreateOrEditAddressModal from '@modals/CreateOrEditAddressModal'
import CreateOrEditCaseStudyModal from '@modals/CreateOrEditCaseStudyModal'
import EditImageModal from '@modals/EditImageModal'

import ImportCaseStudiesModal from '@modals/ImportCaseStudiesModal'

import defaultRequestOptions from '@sections/Client/packs/Admin/defaultRequestOptions'

import PageContent from './components/PageContent'
import localizedStrings from './localizedStrings'

const callbacks = (component, setState) => {
  const componentCallbacks = {
    CreateOrEditAddressModal: {
      closeModal: () => setState({ showCreateOrEditAddressModal: false }),
      createAddress: payload => handleCallbackAction(payload),
      updateAddress: payload => handleCallbackAction(payload),
    },
    CreateOrEditCaseStudyModal: {
      closeModal: () => setState({ showCreateOrEditCaseStudyModal: false }),
      createCaseStudy: payload => handleCallbackAction({ ...payload, requestOptions: defaultRequestOptions.caseStudy }),
      deleteCaseStudy: payload => handleCallbackAction(payload),
      deleteFromProjects: payload => handleCallbackAction(payload),
      showAddressModal: () => setState({ showCreateOrEditAddressModal: true }),
      updateCaseStudy: payload => handleCallbackAction({ ...payload, requestOptions: defaultRequestOptions.caseStudy }),
    },
    EditImageModal: {
      closeModal: () => setState({ showEditImageModal: false }),
      deleteImage: payload => handleCallbackAction(payload),
    },
    ImportCaseStudiesModal: {
      closeModal: () => setState({ showImportCaseStudiesModal: false }),
      importCaseStudies: payload => handleCallbackAction(payload),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  pageFilters: {},
  showCreateOrEditAddressModal: false,
  showCreateOrEditCaseStudyModal: false,
  showEditImageModal: false,
  showImportCaseStudiesModal: false,
}

const CaseStudies = () => {
  const [state, setState] = useSetState(defaultState)
  const {
    pageFilters,
    showCreateOrEditAddressModal,
    showCreateOrEditCaseStudyModal,
    showEditImageModal,
    showImportCaseStudiesModal,
  } = state

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const pageContext = useMemo(
    () => ({
      callbacks: {
        resetFilters: (resetFn) => {
          if (resetFn) resetFn()
          setState({ pageFilters: {} })
        },
        showCreateOrEditAddressModal: (payload) => {
          setModalData('CreateOrEditAddressModal', payload)
          setState({ showCreateOrEditAddressModal: true })
        },
        showCreateOrEditCaseStudyModal: (payload) => {
          setModalData('CreateOrEditCaseStudyModal', payload)
          setState({ showCreateOrEditCaseStudyModal: true })
        },
        showEditImageModal: (payload) => {
          setModalData('EditImageModal', payload)
          setState({ showEditImageModal: true })
        },
        showImportCaseStudiesModal: () => setState({ showImportCaseStudiesModal: true }),
        updateFilters: (filters) => { setState({ pageFilters: filters }) },
      },
    }),
    [modalContext],
  )

  const { strings } = useLocalization(localizedStrings)

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent pageFilters={pageFilters} />

        <CreateOrEditCaseStudyModal
          callbacks={callbacks('CreateOrEditCaseStudyModal', setState, strings)}
          showModal={showCreateOrEditCaseStudyModal}
        />

        <CreateOrEditAddressModal
          callbacks={callbacks('CreateOrEditAddressModal', setState, strings)}
          headerTitle={strings.addressModalHeaderTitle || 'Case Study'}
          hiddenFields={['title']}
          showModal={showCreateOrEditAddressModal}
        />

        <ImportCaseStudiesModal
          callbacks={callbacks('ImportCaseStudiesModal', setState, strings)}
          showModal={showImportCaseStudiesModal}
        />

        <EditImageModal callbacks={callbacks('EditImageModal', setState, strings)} showModal={showEditImageModal} />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default CaseStudies
