/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect } from 'react'

import { toast } from 'react-toastify'

import {
  Box, Button, FileUploadZone, FormField, ModalContext, SidebarModal, Text,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import EntitySelector from '@components/EntitySelector'

import PageContext from '@contexts/pageContext'

import useCurrentUser from '@hooks/useCurrentUser'
import useMixpanel from '@hooks/useMixpanel'
import useUppyCsvUpload from '@hooks/useUppyCsvUpload'

import { useModalDataSetForm } from '@hooks/useDataSet'

import type { BrandModel } from '@models/brand'

type ModalCallbacks = {
  closeModal: () => void,
}

type ModalProps = {
  callbacks: ModalCallbacks,
  selectedBrand: Partial<BrandModel>,
  showModal: boolean,
}

const MODAL_KEY = 'CreateOrEditDataSetModal'

const CreateOrEditDataSetModal = (props: ModalProps) => {
  const {
    callbacks, callbacks: { closeModal }, selectedBrand, showModal,
  } = props
  const { callbacks: { dispatchMixpanelEvent } } = useMixpanel()

  const currentUserPayload = useCurrentUser()
  const { isAdmin, isBrandUser } = currentUserPayload

  const pageContext = useContext(PageContext)
  const {
    setRefetchData,
  } = pageContext

  const initDataSet = {
    owner_type: 'System',
    owner_id: 1,
  }

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const dataSetPayload = digObject(modalData, `${MODAL_KEY}`, {})
  const { assetLibrary, ...existingDataSet } = dataSetPayload

  const dataSet = existingDataSet?.id ? existingDataSet : initDataSet

  const {
    entityState,
    entityState: { title },
    handlers,
    saveEnabled,
    setEntityState,
  } = useModalDataSetForm(dataSet)

  useEffect(() => {
    if (isBrandUser){
      setEntityState({
        subject_id: selectedBrand.id,
        subject_type: 'Brand',
      })
    }
  }, [selectedBrand?.id])

  const saveButtonEnabled = saveEnabled && dataSet?.title?.length === 0

  const handleAfterCallbackAction = () => {
    setRefetchData(prev => !prev)
    toast.success('Your data file uploaded successfully.')
    closeModal()
    dispatchMixpanelEvent('Advanced Data Chart Data Set Saved')
  }

  const uppy = useUppyCsvUpload({
    uppyOptions: {},
    attachmentParams: { entityState },
    maxNumberOfFiles: 1,
    afterCreateCallback: () => handleAfterCallbackAction(),
  }, [entityState.subject_id])

  const handleDataSetSave = () => {
    closeModal()
  }

  return (
    <SidebarModal callbacks={callbacks} modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title="Upload" titleSecondLine="Data Set" />

      <SidebarModal.Content>
        <FormField direction="column" label="* Title">
          <input
            name="title"
            value={title}
            {...handlers}
            disabled={existingDataSet?.title}
          />
        </FormField>

        {isAdmin && (
          <EntitySelector
            boxProps={{ marginTop: 'large' }}
            label="* Brand"
            callbacks={{
              selectItem: selected => setEntityState(
                { ...entityState, subject_id: selected?.id, subject_type: 'Brand' },
              ),
            }}
            entityKey="brands"
            selectedItemId={entityState.subject_id}
            disabled={existingDataSet.brand_id}
          />
        )}

        {entityState.title && entityState.subject_id && !existingDataSet.title && (
          <FormField direction="column" label="* Upload CSV" marginTop="large">
            <FileUploadZone
              subTitle="Max 13mb each"
              uppy={uppy}
            />
          </FormField>
        )}

        {dataSet.filename && (
          <FormField direction="column" label="Uploaded File" marginTop="large">
            <Box paddingTop="medium">
              <Text fontSize="xsmall">{dataSet.filename}</Text>
            </Box>
            <Box paddingTop="small">
              <Text fontSize="xxsmall" color="bodyFontLightColor">Created at: {dataSet.created_date}</Text>
            </Box>
          </FormField>
        )}

      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveButtonEnabled || !isAdmin}
          onClick={handleDataSetSave}
          size="large"
        >
          Save
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ModalProps) => (
  <SidebarModal.RenderController {...props}>
    <CreateOrEditDataSetModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
