import React from 'react'
import PropTypes from 'prop-types'

import { Box, Text } from '@campaignhub/suit-theme'

const itemTypes = {
  digitalTemplatePageItem: 'Page Item',
  digitalTemplatePageSection: 'Section',
  digitalTemplatePageSectionGroup: 'Section Group',
}

const ItemInfo = (props) => {
  const { id, type } = props

  return (
    <Box
      alignItems="center"
      borderBottom="1px solid"
      borderColor="lineColor"
      flexDirection="column"
      justifyContent="center"
      padding="large"
    >
      <Text fontSize="small">{id}</Text>
      <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="medium">
        {itemTypes[type] || 'Page Item'}
      </Text>
    </Box>
  )
}

ItemInfo.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
}

export default ItemInfo
