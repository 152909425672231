import PropTypes from 'prop-types'
import swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArchive,
  faCog,
  faCopy,
  faEyeSlash,
  faFileExport,
  faFilePlus,
  faHistory,
  faPencil,
  faShareAlt,
  faSync,
  faThumbsDown,
  faThumbsUp,
  faUnlock,
} from '@fortawesome/pro-light-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'

import {
  Button, IconWithMenu, Link, ListItemWithImage, StatusBadge,
} from '@campaignhub/suit-theme'

import defaultRequestOptions from '@sections/Admin/packs/DigitalTemplateBuilder/defaultRequestOptions'

import useCurrentUser from '@hooks/useCurrentUser'
import useDigitalTemplate from '@hooks/useDigitalTemplate'

import AuthenticatedDownload from '@components/AuthenticatedDownload'
import DigitalPagePlaceholderImage from '@components/DigitalPagePlaceholderImage'

const confirmUnapproveTemplate = (toggleFn) => {
  swal
    .fire({
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Yes, unapprove it.',
      icon: 'warning',
      showCancelButton: true,
      text: 'Are you sure?',
      title: 'Unapprove Digital Template?',
      input: 'text',
      inputPlaceholder: 'Please add reason for unapproving.',
      inputValidator: (value) => {
        if (!value){
          return 'Please add reason for unapproving.'
        }

        return null
      },
    })
    .then(({ value }) => {
      if (value){
        toggleFn({ action_reason: value })
      }
    })
}

const confirmNewVersionDigitalTemplate = (params) => {
  const { newVersionDigitalTemplate } = params
  swal
    .fire({
      title: 'New version template?',
      text: 'Are you sure? This action will automatically archive this template.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, I am sure.',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        newVersionDigitalTemplate({
          ...defaultRequestOptions.digitalTemplate,
          ...defaultRequestOptions.image,
        })
      }
    })
}

const confirmToggleTemplateHidden = (params) => {
  const { hidden, toggleTemplateHidden } = params
  swal
    .fire({
      title: hidden ? 'Unarchive template?' : 'Archive template?',
      text: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, I am sure.',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        toggleTemplateHidden({
          ...defaultRequestOptions.digitalTemplate,
        })
      }
    })
}

const DigitalTemplate = (props) => {
  const { digitalTemplate } = props

  const digitalTemplatePayload = useDigitalTemplate(digitalTemplate)
  const {
    callbacks: {
      createOrUpdateComponentStyles,
      duplicateDigitalTemplate,
      managePermissions,
      newVersionDigitalTemplate,
      toggleTemplateHidden,
      unlockSessionLocks,
      toggleProductionReady: toggleFn,
      viewActivities,
    },
    digitalTemplate: {
      hidden, id, origin_template_id, production_ready, title,
    },
    digitalTemplateType: { key: digitalTemplateTypeKey, title: templateTypeTitle },
    firstPageId,
    previewImage,
    urls: { editDigitalTemplateUrl },
  } = digitalTemplatePayload

  const { isAdmin, isMasterAdmin } = useCurrentUser()

  const previewImageUrl = digObject(previewImage, 'sizes.thumb_660x360', '')

  return (
    <ListItemWithImage
      imageComponent={(
        <ListItemWithImage.Image
          href={!production_ready ? editDigitalTemplateUrl : null}
          imageBoxProps={{ backgroundColor: !previewImageUrl ? '#F2F9FB' : null }}
          url={previewImageUrl}
          title={title}
        >
          <DigitalPagePlaceholderImage
            color="#61ABBB"
            digitalTemplateTypeKey={digitalTemplateTypeKey}
            height="80%"
            width="80%"
          />
        </ListItemWithImage.Image>
      )}
    >
      <ListItemWithImage.Header
        href={production_ready ? null : editDigitalTemplateUrl}
        showAngleIcon={!production_ready}
      >
        <ListItemWithImage.HeaderLeft>
          <ListItemWithImage.HeaderText>{title}</ListItemWithImage.HeaderText>
          <StatusBadge
            color={production_ready ? 'tan' : 'mysteryGrey'}
            text={production_ready ? 'In Production' : 'Draft'}
          />
        </ListItemWithImage.HeaderLeft>
      </ListItemWithImage.Header>

      <ListItemWithImage.Footer>
        <ListItemWithImage.FooterLeft>
          <ListItemWithImage.FooterText boxProps={{ fontSize: 'small', marginBottom: 'small' }}>
            {`Template #${id} - ${templateTypeTitle}`}
          </ListItemWithImage.FooterText>
          <ListItemWithImage.FooterText boxProps={{ color: 'mysteryGrey', fontSize: 'xsmall' }}>
            {origin_template_id ? (
              <>
                <FontAwesomeIcon icon={faShareAlt} style={{ fontSize: '10px', marginRight: '5px' }} />
                Versioned from&nbsp;
                <Link href={`#/digitalTemplates/${origin_template_id}/edit`}>{`Template #${origin_template_id}`}</Link>
              </>
            ) : null}
          </ListItemWithImage.FooterText>
        </ListItemWithImage.FooterLeft>

        <ListItemWithImage.FooterRight>
          <IconWithMenu
            icon={(
              <Button
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faCog} />}
                size="medium"
                height={37}
              />
            )}
            style={{ marginLeft: 'auto', width: 'auto' }}
          >
            <IconWithMenu.Menu closeOnClick listStyle={{ right: '-7px' }}>
              {!production_ready && (
                <Link href={editDigitalTemplateUrl} key="edit">
                  <FontAwesomeIcon icon={faPencil} /> Edit Template
                </Link>
              )}

              <Link
                key="duplicate"
                onClick={() => duplicateDigitalTemplate({
                  ...defaultRequestOptions.digitalTemplate,
                  ...defaultRequestOptions.image,
                })}
              >
                <FontAwesomeIcon icon={faCopy} /> Duplicate
              </Link>

              <Link
                key="new-version"
                onClick={() => confirmNewVersionDigitalTemplate({ newVersionDigitalTemplate })}
              >
                <FontAwesomeIcon icon={faFilePlus} /> New Version
              </Link>

              <Link key="permissions" onClick={() => managePermissions()}>
                <FontAwesomeIcon icon={faEyeSlash} /> Permissions
              </Link>

              <Link
                key="approve"
                onClick={() => (production_ready ? confirmUnapproveTemplate(toggleFn) : toggleFn())}
              >
                <FontAwesomeIcon icon={production_ready ? faThumbsDown : faThumbsUp} />
                {production_ready ? 'Unapprove' : 'Approve'}
              </Link>

              {firstPageId && isAdmin && (
                <AuthenticatedDownload>
                  <Link href={`/digital_template_pages/${firstPageId}/export_layout_data.json`} key="export-file">
                    <FontAwesomeIcon icon={faFileExport} /> Export File
                  </Link>
                </AuthenticatedDownload>
              )}

              <Link key="component-styles" onClick={() => createOrUpdateComponentStyles()}>
                <FontAwesomeIcon icon={faSync} /> Rebuild Component Styles
              </Link>

              <Link key="archive" onClick={() => confirmToggleTemplateHidden({ hidden, toggleTemplateHidden })}>
                <FontAwesomeIcon icon={faArchive} /> {hidden ? 'Unarchive Template' : 'Archive Template'}
              </Link>

              {isMasterAdmin && (
                <Link key="unlock" onClick={() => unlockSessionLocks()}>
                  <FontAwesomeIcon icon={faUnlock} /> Unlock Sessions
                </Link>
              )}

              <Link key="activity-log" onClick={() => viewActivities()}>
                <FontAwesomeIcon icon={faHistory} /> Activity Log
              </Link>
            </IconWithMenu.Menu>
          </IconWithMenu>
        </ListItemWithImage.FooterRight>
      </ListItemWithImage.Footer>
    </ListItemWithImage>
  )
}

DigitalTemplate.propTypes = {
  digitalTemplate: PropTypes.object.isRequired,
}

export default DigitalTemplate
