import { Box } from '@campaignhub/suit-theme'

import FieldHolder from '@components/FieldHolder'
import Input from '@components/Input'

interface FontOptionsProps {
  itemStyle: {
    textAlign: string,
    textTransform: string,
    overflow: string,
  },
  selectedEntity: {
    id: number,
  },
  styleProps: {},
  updateStyle: (name: string, value: string, styleProps: {}) => void,
}

const FontOptions = (props: FontOptionsProps) => {
  const {
    itemStyle, styleProps, selectedEntity, updateStyle,
  } = props

  return (
    <Box marginTop="large" flexDirection="row" flexWrap="wrap">
      <Input
        boxProps={{ marginBottom: 'large' }}
        entityId={selectedEntity.id}
        data={itemStyle}
        halfWidth
        parse={value => value || null}
        propertyKey="fontFamily"
        label="Font Family"
        updateCallback={(name, value) => updateStyle(name, value, styleProps)}
      />

      <Input
        boxProps={{ marginBottom: 'large' }}
        entityId={selectedEntity.id}
        last
        halfWidth
        data={itemStyle}
        parse={value => (value ? Number(value) : null)}
        propertyKey="fontSize"
        label="Font Size"
        updateCallback={(name, value) => updateStyle(name, value, styleProps)}
      />

      <Input
        boxProps={{ marginBottom: 'large' }}
        entityId={selectedEntity.id}
        halfWidth
        data={itemStyle}
        parse={value => (value ? Number(value) : null)}
        propertyKey="letterSpacing"
        label="Letter Spacing"
        updateCallback={(name, value) => updateStyle(name, value, styleProps)}
      />

      <FieldHolder halfWidth label="Text Align" last>
        <select onChange={e => updateStyle('textAlign', e.target.value, styleProps)} value={itemStyle.textAlign || ''}>
          <option value="">Default</option>
          <option value="left">Left</option>
          <option value="center">Center</option>
          <option value="right">Right</option>
          <option value="justify">Justify</option>
        </select>
      </FieldHolder>

      <Input
        boxProps={{ marginBottom: 'large' }}
        entityId={selectedEntity.id}
        halfWidth
        data={itemStyle}
        propertyKey="color"
        label="Font Color"
        updateCallback={(name, value) => updateStyle(name, value, styleProps)}
      />

      <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth label="Text Transform" last>
        <select
          onChange={e => updateStyle('textTransform', e.target.value, styleProps)}
          value={itemStyle.textTransform || ''}
        >
          <option value="">Default</option>
          <option value="lowercase">Lowercase</option>
          <option value="uppercase">Uppercase</option>
          <option value="capitalize">Capitalize</option>
        </select>
      </FieldHolder>

      <Input
        boxProps={{ marginBottom: 'large' }}
        entityId={selectedEntity.id}
        halfWidth
        data={itemStyle}
        propertyKey="fontWeight"
        label="Font Weight"
        updateCallback={(name, value) => updateStyle(name, value, styleProps)}
      />

      <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth label="Font Style" last>
        <select onChange={e => updateStyle('fontStyle', e.target.value, styleProps)} value={itemStyle.fontStyle || ''}>
          <option value="">Default</option>
          <option value="normal">Normal</option>
          <option value="italic">Italic</option>
          <option value="oblique">Oblique</option>
          <option value="initial">Initial</option>
          <option value="inherit">Inherit</option>
        </select>
      </FieldHolder>

      <Input
        boxProps={{ marginBottom: 'large' }}
        entityId={selectedEntity.id}
        halfWidth
        data={itemStyle}
        propertyKey="lineHeight"
        label="Line Height"
        updateCallback={(name, value) => updateStyle(name, value, styleProps)}
      />

      <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth label="Overflow" last>
        <select onChange={e => updateStyle('overflow', e.target.value, styleProps)} value={itemStyle.overflow || ''}>
          <option value="">Default</option>
          <option value="visible">Visible</option>
          <option value="hidden">Hidden</option>
          <option value="scroll">Scroll</option>
          <option value="auto">Auto</option>
          <option value="initial">Initial</option>
          <option value="inherit">Inherit</option>
        </select>
      </FieldHolder>
    </Box>
  )
}

export default FontOptions
