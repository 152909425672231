import { useMemo, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'

import { faChartBar, faList } from '@fortawesome/pro-light-svg-icons'

import { useSetState } from '@campaignhub/react-hooks'
import { digObject } from '@campaignhub/javascript-utils'

import { Box, CustomizableDashboard, PageHeader } from '@campaignhub/suit-theme'

import useCurrentUser from '@hooks/useCurrentUser'
import useIntercom from '@hooks/useIntercom'
import useMixpanel from '@hooks/useMixpanel'
import useOrganizationsUser from '@hooks/useOrganizationsUser'
import useReduxAction from '@hooks/useReduxAction'
import useUser from '@hooks/useUser'

import PageContext from '@contexts/pageContext'

import defaultRequestOptions from '@sections/Admin/packs/SystemManager/defaultRequestOptions'

import Content from '../DashboardModules/Content'
import ContentSidebar from '../DashboardModules/ContentSidebar'

import Charts, { configOptions as chartsConfigOptions, icon as chartsIcon } from '../DashboardModules/Charts'

import Leaderboard, {
  configOptions as leaderboardConfigOptions,
  icon as leaderboardIcon,
} from '../DashboardModules/LeaderboardModule'

const generateAvailableModules = entity => ({
  Leaderboard: {
    component: Leaderboard,
    configOptions: leaderboardConfigOptions(),
    icon: leaderboardIcon,
    id: 'Leaderboard',
    title: 'Leaderboard',
  },
  Charts: {
    component: Charts,
    configOptions: chartsConfigOptions(),
    icon: chartsIcon,
    id: 'Charts',
    mergeConfig: {
      queryParams: { entity_type: entity.type, entity_id: entity.id },
    },
    title: 'Charts',
  },
})

const dashboardDefaultLayout = () => ({
  columns: {
    main: {
      id: 'main',
      modules: [
        {
          key: 'Leaderboard-1',
          moduleId: 'Leaderboard',
        },
        {
          key: 'Charts-1',
          moduleId: 'Charts',
        },
        {
          key: 'Charts-2',
          moduleId: 'Charts',
        },
        {
          key: 'Charts-3',
          moduleId: 'Charts',
        },
      ],
    },
    sidebar: {
      id: 'sidebar',
      modules: [],
    },
  },
  custom: false,
  options: {
    'Leaderboard-1': {
      customization: { title: 'Brand Leaderboard' },
      queryParams: {
        leaderboard: 'brand_leaderboard',
        limit: 5,
        month: 'current_month',
        status_key: 'all',
      },
    },
    'Charts-1': {
      customization: {
        chart_key: 'digital_pages_accepted_vs_created_per_month',
        title: 'Proposals Accepted vs Created',
      },
    },
    'Charts-2': {
      customization: {
        chart_key: 'total_organizations_created_per_month',
        title: 'Proposals Accepted vs Created',
      },
    },
    'Charts-3': {
      customization: {
        chart_key: 'total_page_views_created_per_month',
        title: 'Proposals Accepted vs Created',
      },
    },
  },
})

const callbacks = (component, state, setState, userCallbacks) => {
  const { editingDashboard } = state
  const { saveUserDashboard } = userCallbacks

  const componentCallbacks = {
    CustomizableDashboard: {
      toggleEditDashboard: () => setState({ editingDashboard: !editingDashboard }),
      saveDashboard: (dashboardKey, activeLayout) => saveUserDashboard(dashboardKey, activeLayout),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  editingDashboard: false,
}

const PageContent = () => {
  const [state, setState] = useSetState(defaultState)
  const { editingDashboard } = state

  useReduxAction(
    'digitalPageMetricCollections',
    'loadDigitalPageMetricCollections',
    {
      ...defaultRequestOptions.digitalPageMetricCollection,
    },
    [],
  )

  const pageContext = {
    callbacks: {},
  }

  const { currentUser, isBrandUser } = useCurrentUser()
  const { callbacks: userCallbacks } = useUser(currentUser)

  useIntercom({ hideWidget: !isBrandUser })

  const { isCurrentUserAdminForOrganization, selectedOrganization } = useOrganizationsUser()

  const chartDataEntity = isCurrentUserAdminForOrganization ? selectedOrganization : currentUser

  const customizedLayout = digObject(currentUser, 'options.custom_dashboards.admin_analytics_dashboard')

  const availableModules = useMemo(
    () => generateAvailableModules(chartDataEntity),
    [currentUser.id, selectedOrganization.id],
  )

  // Tracks the page load event
  const { callbacks: { dispatchMixpanelEvent } } = useMixpanel()
  useEffect(() => {
    if (isBrandUser) dispatchMixpanelEvent('Brand Analytics Load')
  }, [])

  return (
    <PageContext.Provider value={pageContext}>
      <Helmet>
        <title>Analytics | Engage</title>
      </Helmet>

      <PageHeader
        activeTabBarItemKey="analytics"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        tabBarItems={[
          {
            href: '#/systemManager/analytics',
            icon: faChartBar,
            key: 'analytics',
            title: 'Analytics',
          },
          {
            href: '#/systemManager/analytics/digitalPageMetrics',
            icon: faList,
            key: 'digital-page-metrics',
            title: 'Digital Page Metrics',
          },
        ]}
        title="Analytics"
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <CustomizableDashboard
          availableModules={availableModules}
          callbacks={callbacks('CustomizableDashboard', state, setState, userCallbacks)}
          columns={[
            {
              id: 'main',
              component: <Content />,
              title: 'Main',
            },
            {
              id: 'sidebar',
              component: (
                <ContentSidebar
                  callbacks={{ customizeDashboard: () => setState({ editingDashboard: !editingDashboard }) }}
                />
              ),
              title: 'Sidebar',
            },
          ]}
          customizedLayout={customizedLayout}
          dashboardKey="admin_analytics_dashboard"
          dashboardReady={!!currentUser.id}
          defaultLayout={dashboardDefaultLayout()}
          editing={editingDashboard}
        />
      </Box>
    </PageContext.Provider>
  )
}

export default PageContent
