import type { AddressModel } from './address'

export type ProjectModel = {
  cache_key: string,
  id: number,
  address_attributes: Partial<AddressModel>,
  address_id?: number,
  case_study_ids: number[],
  combined_user_ids: number[],
  contact_ids: number[],
  data: {},
  dates:{
    created: {
      date_time_with_timezone: string,
    },
    updated: {
      date_time_with_timezone: string,
    },
  },
  event_calendar_ids: number[],
  hidden: boolean,
  lead_user_ids: number[],
  organization_id: number,
  project_type_id: number,
  quote_ids: number[],
  reviews_users: [],
  support_user_ids: number[],
  target_audience_ids: number[],
  team_ids: number[],
  title: string,
  type: string,
}

export type ProjectRequestOptions = {
  include_project_address?: boolean,
  include_project_analytics?: boolean,
  include_project_attachments?: boolean,
  include_project_case_studies?: boolean,
  include_project_case_study_ids?: boolean,
  include_project_combined_user_ids?: boolean,
  include_project_comparables?: boolean,
  include_project_contacts?: boolean,
  include_project_data_store_items?: boolean,
  include_project_images?: boolean,
  include_project_lead_users?: boolean,
  include_project_organization?: boolean,
  include_project_reviews?: boolean,
  include_project_reviews_users_hash?: boolean,
  include_project_support_users?: boolean,
  include_project_tagged_images?: boolean,
  include_project_target_audience_ids?: boolean,
  include_project_target_audiences?: boolean,
  include_project_teams?: boolean,
}

const state = {
  address_attributes: {},
  case_study_ids: [],
  contact_ids: [],
  event_calendar_ids: [],
  id: null,
  lead_user_ids: [],
  organization_id: null,
  project_type_id: null,
  quote_ids: [],
  reviews_users: [],
  support_user_ids: [],
  target_audience_ids: [],
  team_ids: [],
  title: '',
}

export const requiredFields = [
  { key: 'organization_id' },
  { key: 'project_type_id' },
]

export default state
