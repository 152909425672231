import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useSetState } from '@campaignhub/react-hooks'

import findDataStoreItem from '@functions/findDataStoreItem'

import PageContext from '@contexts/pageContext'

import { getSubjectId } from '@hooks/useDigitalPageDataStoreItems'
import useDeviceStyle from '@hooks/useDeviceStyle'
import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

import styles from './styles.module.scss'

const updateDataStore = async (value, props) => {
  const {
    callbacks: { createOrUpdateDataStoreItem: createOrUpdateFn, updateShortcodeDataSubject: updateSubjectFn },
    options,
  } = props

  const {
    inputDataHidden,
    inputDataStoreBehaviour,
    inputDataStoreKey,
    inputDataStoreLabel,
    inputDataStoreSubject,
    inputDataStoreTags,
    inputRequired,
    inputSecured,
  } = options || {}

  const payload = {
    behaviour: inputDataStoreBehaviour,
    data: {
      hidden: inputDataHidden || false,
      required: inputRequired,
      secured: inputSecured || false,
      tags: inputDataStoreTags,
    },
    key: inputDataStoreKey,
    label: inputDataStoreLabel,
    subjectType: inputDataStoreSubject,
    value,
  }

  updateSubjectFn(inputDataStoreSubject, inputDataStoreKey, value)

  createOrUpdateFn(payload)
}

const isEditableByUser = (currentUser, inputEditableBy) => {
  if (inputEditableBy && currentUser){
    if (inputEditableBy === 'Agency'){
      return currentUser.actable_type === 'Agent' || currentUser.actable_type === 'OfficeAdministrator'
    }

    return inputEditableBy === currentUser.actable_type
  }

  return true
}

const changeCheckboxValue = (value, props, setState, strings) => {
  const friendlyValue = value ? strings.checkboxValueYes : strings.checkboxValueNo

  setState({ inputValue: friendlyValue })
  updateDataStore(friendlyValue, props)
}

const setInputState = async (props, inputValue) => {
  const {
    callbacks: { setState: setStateFn },
    options,
  } = props
  const { stateKey } = options || {}

  if (setStateFn){
    setStateFn({ [stateKey]: inputValue })
  }
}

const triggerFunction = async (trigger, props, inputValue) => {
  if (trigger === 'updateDataStore'){
    updateDataStore(inputValue, props)
    return
  }

  if (trigger === 'setState'){
    setInputState(props, inputValue)
    return
  }

  updateDataStore(inputValue, props)
}

const defaultState = {
  inputValue: '',
}

const Input = (props) => {
  const { options } = props

  const style = useDeviceStyle(props)

  const [state, setState] = useSetState(defaultState)
  const { inputValue } = state

  const pageContext = useContext(PageContext)
  const { currentUser, dataStoreItems, uuid } = pageContext

  const {
    inputComponent,
    inputDataStoreBehaviour,
    inputDataStoreKey,
    inputDataStoreSubject,
    inputEditableBy,
    inputOptions,
    inputPlaceholder,
    inputType,
    trigger,
  } = options || {}

  const dataStoreItem = findDataStoreItem(dataStoreItems, inputDataStoreBehaviour, inputDataStoreKey, {
    editorId: currentUser.id,
    editorType: 'User',
    groupKey: uuid,
    subjectId: getSubjectId(inputDataStoreSubject, pageContext),
    subjectType: inputDataStoreSubject,
  })

  const dataStoreItemValue = dataStoreItem.value || ''

  useEffect(() => {
    if (dataStoreItemValue){
      setState({ inputValue: dataStoreItemValue })
    }
  }, [dataStoreItemValue])

  const isEditable = isEditableByUser(currentUser, inputEditableBy)

  const { strings } = useLocalization(localizedStrings)

  if (!isEditable){
    return (
      <div className={styles.disabled} style={style}>
        {inputValue}
      </div>
    )
  }

  return (
    <div className={styles.root}>
      {inputComponent === 'input' && inputType === 'checkbox' && (
        <input
          checked={inputValue === 'Yes'}
          className={styles.input}
          onChange={e => changeCheckboxValue(e.target.checked, props, setState, strings)}
          style={style}
          type="checkbox"
        />
      )}

      {inputComponent === 'input' && inputType !== 'checkbox' && (
        <input
          className={styles.input}
          onBlur={() => updateDataStore(inputValue, props)}
          onChange={e => setState({ inputValue: e.target.value })}
          placeholder={inputPlaceholder}
          style={style}
          type={inputType || 'text'}
          value={inputValue}
        />
      )}

      {inputComponent === 'select' && (
        <select
          className={styles.input}
          value={inputValue}
          onChange={(e) => {
            setState({ inputValue: e.target.value })
            triggerFunction(trigger, props, e.target.value)
          }}
          style={style}
        >
          <option value="">{strings.optionValue || 'Please Select...'}</option>
          {inputOptions
            && inputOptions.map(option => (
              <option key={option.key} value={option.key}>
                {option.value}
              </option>
            ))}
        </select>
      )}

      {inputComponent === 'textarea' && (
        <textarea
          className={styles.input}
          onBlur={() => updateDataStore(inputValue, props, state)}
          onChange={e => setState({ inputValue: e.target.value })}
          placeholder={inputPlaceholder}
          style={style}
          value={inputValue}
        />
      )}
    </div>
  )
}

Input.propTypes = {
  callbacks: PropTypes.shape({
    createOrUpdateDataStoreItem: PropTypes.func,
  }),
  options: PropTypes.object,
}

Input.defaultProps = {
  options: {},
}

export default Input
