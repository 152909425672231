import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons'

import { Box, Image } from '@campaignhub/suit-theme'

interface MapMarkerImageProps {
  mapMarkerImageUrl: string,
  markerHeight: number,
  markerWidth: number,
}
const MapMarkerImage = (props: MapMarkerImageProps) => {
  const { mapMarkerImageUrl, markerHeight, markerWidth } = props

  return (
    <Box>
      { !mapMarkerImageUrl && <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" color="red" /> }

      { mapMarkerImageUrl && (
        <Image
          height={markerHeight}
          url={mapMarkerImageUrl}
          width={markerWidth}
        />
      )}
    </Box>
  )
}

export default MapMarkerImage
