export default {
  default: {
    buttons: {
      send: 'Send SMS',
    },
    formFieldLabels: {
      recipients: 'Recipients',
      messageBody: 'Message Body',
      searchUsers: 'Search Users',
    },
    sideBarModalHeader: {
      title: 'Send',
      titleSecondLine: 'SMS',
    },
    titles: {
      customize: 'Customize SMS',
    },
    placeholders: {
      messageBody: 'Add a Message (Optional)',
    },
    VideoModule: {
      title: 'Why SMS',
      paragraph: 'Why we recommend SMSing your Digital Proposals.',
    },
  },
}
