import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt } from '@fortawesome/pro-light-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'

import {
  Box, ListItem, StatusBadge, Text,
} from '@campaignhub/suit-theme'

import { generateUrls } from '@hooks/useDocumentTemplate'
import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const DocumentTemplate = (props) => {
  const { documentTemplate } = props
  const {
    dates, id, production_ready, title,
  } = documentTemplate || {}

  const createdDateTime = digObject(dates, 'created.date_time_with_timezone')

  const { editDocumentTemplateUrl } = generateUrls({ id })

  const {
    callbacks: { formatDate },
    strings,
  } = useLocalization(localizedStrings)

  return (
    <ListItem
      boxProps={{ as: 'a', borderBottom: '1px dashed', padding: 'large' }}
      disableHover={production_ready}
      href={production_ready ? null : editDocumentTemplateUrl}
      showAngleIcon={!production_ready}
    >
      <Box
        alignItems="center"
        backgroundColor="backgroundColor"
        borderRadius={5}
        color="bodyFontLightColor"
        flexShrink={0}
        height={40}
        justifyContent="center"
        position="relative"
        width={40}
      >
        <FontAwesomeIcon icon={faFileAlt} />
      </Box>

      <Box flexDirection="column" justifyContent="center" marginLeft="large" minWidth="0">
        <Text fontSize="small">{title}</Text>

        <Text color="bodyFontLightColor" fontSize="xsmall" paddingTop="medium">
          {strings.idText || 'ID'} #{id} | {strings.createdText || 'Created'}{' '}
          {formatDate(createdDateTime, DateTime.DATE_FULL)}
        </Text>
      </Box>

      <Box alignItems="center" width="auto">
        <StatusBadge
          color={production_ready ? 'blue' : 'faintGrey'}
          text={production_ready ? strings.published || 'Published' : strings.draft || 'Draft'}
        />
      </Box>
    </ListItem>
  )
}

DocumentTemplate.propTypes = {
  documentTemplate: PropTypes.shape({
    dates: PropTypes.object.isRequired,
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
}

export default DocumentTemplate
