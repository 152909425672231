import { useContext } from 'react'

import {
  Box, Button, ModalContext, SidebarModal, SortableList,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useImages from '@hooks/useImages'
import useLocalization from '@hooks/useLocalization'
import useOrganizationSelector from '@hooks/useOrganizationSelector'

import ModalImageUpload from '@components/ModalComponents/ModalImageUpload'

import ToggleListGridView from '@components/ToggleListGridView'

import type { ImageModel } from '@models/image'

import DraggableListItem from './components/DraggableListItem'
import GridItem from './components/GridItem'

import localizedStrings from './localizedStrings'

const MODAL_KEY = 'EditImagesModal'

type ModalCallbacks = {
  closeModal: () => void,
  onUpload?: () => void,
}

type ModalProps = {
  callbacks: ModalCallbacks,
  showModal: boolean,
}

const EditImagesModal = (props: ModalProps) => {
  const { callbacks, showModal } = props

  const { closeModal, onUpload } = callbacks

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, `${MODAL_KEY}`, {})
  const {
    callbacks: {
      modifyImageSort,
      updateDefaultImage,
    },
    subjectId,
    subjectType,
  } = modalPayload

  const imagesPayload = useImages({
    customRequestOptions: {
    },
    subjectId,
    subjectType,
    performHttpRequests: true,
  })

  const { hasImages, sortedImages } = imagesPayload

  const { selectedId } = useOrganizationSelector()

  const listItems = sortedImages.map((image: ImageModel, index: number) => (

    <SortableList.Item draggableId={`Image-${image.id}`} key={image.id} index={index}>
      <DraggableListItem
        image={image}
        key={image.id}
        updateDefaultImage={updateDefaultImage}
      />
    </SortableList.Item>
  ))

  const gridItems = sortedImages.map(
    (image: ImageModel) => <GridItem image={image} updateDefaultImage={updateDefaultImage} />,
  )

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal callbacks={callbacks} modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.sideBarModalHeader?.titleUpdate || 'Update'}
        titleSecondLine={strings.sideBarModalHeader?.titleSecondLine || 'Comparable'}
      />

      <SidebarModal.Content hasFooter>
        <Box flexDirection="column">
          <ModalImageUpload
            imageLabel={strings.formFieldLabels?.image || 'Comparable Images'}
            imageSizes={['thumb_90x90', 'thumb_660x360', 'thumb_200x120']}
            onUpload={onUpload}
            ownerId={selectedId}
            ownerType="Organization"
            subjectId={subjectId}
            subjectType={subjectType}
          />

          {hasImages && (
            <ToggleListGridView
              gridItems={gridItems}
              listItems={listItems}
              modifyImageSort={modifyImageSort}
              title={(strings.formFieldLabels?.images || 'Images')}
            />
          )}
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!hasImages}
          onClick={() => closeModal()}
          size="large"
        >
          {strings.buttons?.save || 'Save'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ModalProps) => (
  <SidebarModal.RenderController {...props}>
    <EditImagesModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
