export default {
  default: {
    blankState: {
      title: 'No Saved Templates',
      paragraph: 'Create a new calendar then save it as a template.',
    },
    formFieldLabels: {
      filter: 'Filter Templates',
      moreOptions: 'More Options',
      savedTemplates: 'Saved Calendar Templates',
    },
  },
}
