import React from 'react'
import PropTypes from 'prop-types'

import { Line, SidebarModal } from '@campaignhub/suit-theme'

import useDigitalPage from '@hooks/useDigitalPage'
import { getDigitalTemplateEnabledFeatures } from '@hooks/useDigitalTemplate'
import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

import Feature from './components/Feature'
import HeaderActionButtons from './components/HeaderActionButtons'
import PageDetails from './components/PageDetails'

const ManageDigitalPageFeaturesModal = (props) => {
  const {
    callbacks, digitalRendererPayload, modalKey, showModal,
  } = props

  const {
    callbacks: { manageDigitalPageFeature },
    digitalPage,
  } = digitalRendererPayload

  const digitalPagePayload = useDigitalPage(digitalPage)
  const { digitalTemplate } = digitalPagePayload

  // Getting enabled features form Digital Template
  const { enabledFeatures } = getDigitalTemplateEnabledFeatures(digitalTemplate)

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal
      callbacks={callbacks}
      disableAnimation
      disableOverlay
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.sideBarModalHeader?.title || 'Page'}
        titleSecondLine={strings.sideBarModalHeader?.titleSecondLine || 'Settings'}
      />

      <SidebarModal.Content boxProps={{ padding: 0 }} hasFooter={false}>
        <HeaderActionButtons boxProps={{ padding: 'large' }} digitalPagePayload={digitalPagePayload} />

        <Line margin={0} />

        <PageDetails digitalPagePayload={digitalPagePayload} />

        <Line margin={0} />

        {enabledFeatures.map((feature) => {
          const { parentKey } = feature

          if (!parentKey){
            return (
              <Feature
                callbacks={{ manageDigitalPageFeature }}
                digitalPageId={digitalPage.id}
                feature={feature}
                key={feature.key}
              />
            )
          }

          return null
        })}
      </SidebarModal.Content>
    </SidebarModal>
  )
}

ManageDigitalPageFeaturesModal.propTypes = {
  callbacks: PropTypes.object,
  digitalRendererPayload: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

ManageDigitalPageFeaturesModal.defaultProps = {
  modalKey: 'ManageDigitalPageFeaturesModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <ManageDigitalPageFeaturesModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
