import { toggleArray } from '@campaignhub/javascript-utils'

import useReduxAction from '@hooks/useReduxAction'
import useTargetAudiences from '@hooks/useTargetAudiences'

import type { TargetAudienceModel } from '@models/types'

import defaultRequestOptions from '@sections/Client/packs/Admin/defaultRequestOptions'

type ToggleProjectTargetAudienceParams = {
  entityState: { target_audience_ids: number[] },
  setEntityState: ({ target_audience_ids }: {target_audience_ids: number[]}) => void,
  targetAudienceId: number,
}

const toggleProjectTargetAudience = (params: ToggleProjectTargetAudienceParams) => {
  const { entityState, setEntityState, targetAudienceId } = params
  const { target_audience_ids } = entityState
  const updatedTargetAudienceIds = toggleArray(target_audience_ids, targetAudienceId)

  setEntityState({ target_audience_ids: updatedTargetAudienceIds })
}

type ToggleAllTargetAudiencesParams = {
  entityState: { target_audience_ids: number[] },
  setEntityState: ({ target_audience_ids }: {target_audience_ids: number[]}) => void,
  targetAudiences: TargetAudienceModel[],
}

const toggleAllTargetAudiences = (params: ToggleAllTargetAudiencesParams) => {
  const { entityState, setEntityState, targetAudiences } = params
  const { target_audience_ids } = entityState

  if (targetAudiences.length === target_audience_ids.length){
    setEntityState({ target_audience_ids: [] })
    return
  }

  setEntityState({ target_audience_ids: targetAudiences.map(targetAudience => (targetAudience.id)) })
}

type SelectFilteredTargetAudiencesParams = {
  brandTargetAudiences: TargetAudienceModel[],
  filterKey: string,
  organizationTargetAudiences: TargetAudienceModel[],
  systemTargetAudiences: TargetAudienceModel[],
}

const selectFilteredTargetAudiences = (params: SelectFilteredTargetAudiencesParams) => {
  const {
    brandTargetAudiences, filterKey, organizationTargetAudiences, systemTargetAudiences,
  } = params

  if (filterKey === 'organization' && organizationTargetAudiences.length) return organizationTargetAudiences
  if (filterKey === 'brand' && brandTargetAudiences.length) return brandTargetAudiences
  if (filterKey === 'system' && systemTargetAudiences.length) return systemTargetAudiences

  return []
}

type UseManageTargetAudiencesOptions = {
  filterKey: string,
  performHttpRequests?: boolean,
}

function useManageTargetAudiences(options: UseManageTargetAudiencesOptions) {
  const { filterKey, performHttpRequests } = options || {}

  useReduxAction(
    'targetAudiences',
    'loadTargetAudiences',
    {
      ...defaultRequestOptions.image,
      ...defaultRequestOptions.targetAudience,
    },
    [JSON.stringify(options)],
    {
      shouldPerformFn: (entityReducer) => {
        const {
          errors, loaded, loading,
        } = entityReducer
        return performHttpRequests && !loaded && !loading && !errors.length
      },
    },
  )

  const targetAudiencesPayload = useTargetAudiences({})
  const {
    brandTargetAudiences,
    filteredTargetAudiences,
    hasTargetAudiences,
    loading,
    organizationTargetAudiences,
    systemTargetAudiences,
  } = targetAudiencesPayload

  return {
    callbacks: {
      toggleProjectTargetAudience,
      toggleAllTargetAudiences,
    },
    selectedTargetAudiences: selectFilteredTargetAudiences({
      brandTargetAudiences,
      filterKey,
      organizationTargetAudiences,
      systemTargetAudiences,
    }),
    brandTargetAudiences,
    filteredTargetAudiences,
    hasTargetAudiences,
    loading,
    organizationTargetAudiences,
  }
}

export default useManageTargetAudiences
