import { useContext } from 'react'

import {
  Box, Button, ModalContext, SidebarModal, SortableList,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useLocalization from '@hooks/useLocalization'

import DraggableUser from '@components/ModalComponents/DraggableUser'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'
import useEditUserSort from './hooks/useEditUserSort'

import localizedStrings from './localizedStrings'

const MODAL_KEY = 'EditUserSortModal'

interface EditUserSortModalProps {
  callbacks: {
    closeModal: () => void,
    updateUserSort: (payload: HandleCallbackActionParams) => void,
  },
  showModal: boolean,
}

const EditUserSortModal = (props: EditUserSortModalProps) => {
  const { callbacks, showModal } = props
  const { closeModal, updateUserSort } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const {
    callbacks: { updateUserSort: updateSortFn },
    organization,
  } = digObject(modalData, `${MODAL_KEY}`, {})

  const {
    callbacks: { modifySort },
    sortedIds,
    sortedUsers,
  } = useEditUserSort(organization)

  const { strings } = useLocalization(localizedStrings)

  const updateUserPayload = {
    callbacks: {
      action: updateSortFn,
      afterAction: closeModal,
    },
    entityParams: sortedIds,
    toastText: strings.sortedUser || 'Users Sorted Successfully',
  }

  return (
    <SidebarModal callbacks={callbacks} modalKey={MODAL_KEY} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.sideBarModalHeader?.title || 'Sort'}
        titleSecondLine={strings.sideBarModalHeader?.titleSecondLine || 'Users'}
      />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <SortableList
            callbacks={{
              onDragEnd: (fromIndex, toIndex, payload) => modifySort(fromIndex, toIndex, payload),
            }}
          >
            <SortableList.Dropzone droppableId="sortedIds">
              <Box flexDirection="column">
                {sortedUsers.map((user, index) => (
                  <SortableList.Item draggableId={`User-${user.id}`} key={user.id} index={index}>
                    <DraggableUser user={user} />
                  </SortableList.Item>
                ))}
              </Box>
            </SortableList.Dropzone>
          </SortableList>
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button buttonStyle="primaryCreate" onClick={() => updateUserSort(updateUserPayload)} size="large">
          {strings.buttons?.save || 'Save'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <EditUserSortModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
