export default {
  default: {
    actionButtonText: {
      type: 'count',
      none: 'Select Quote',
      one: 'Import Quote',
      many: 'Import Quotes ({count})',
    },
    availableQuotes: 'Available Quotes',
    fetchQuotes: 'Fetch Quotes',
    manageQuotes: 'Manage {integrationPlatformTitle} Quotes',
    moreOptions: 'More Options',
    noticeBox: {
      title: 'Nothing to Import',
      paragraph: 'No Quotes Available. Create a quote in {integrationPlatformTitle} for it to be made available here.',
    },
    title: 'Import',
    subtitle: 'Quotes',
    toast: {
      quote: {
        importStarted: 'Quote Import Started!',
        importSuccess: 'External Quote {externalId} Imported Successfully',
        created: 'Quote Created Successfully',
      },
    },
  },
}
