import PropTypes from 'prop-types'

import { Box, NumberWithIconGrid } from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import DigitalPageIcon from '@components/Icons/DigitalPageIcon'
import ProjectIcon from '@components/Icons/ProjectIcon'
import TeamIcon from '@components/Icons/TeamIcon'
import UserIcon from '@components/Icons/UserIcon'

import useOrganizationRelatedEntityCounts from '@hooks/useOrganizationRelatedEntityCounts'

const entityKeys = ['teams', 'users', 'projects', 'digital_pages']

const RelatedEntityLinks = (props) => {
  const { organization } = props

  const { loading, relatedEntityCounts } = useOrganizationRelatedEntityCounts(organization, { entityKeys })
  const {
    digital_pages, projects, teams, users,
  } = relatedEntityCounts || {}

  return (
    <Box
      backgroundColor="white"
      border="1px solid"
      borderBottomLeftRadius={5}
      borderBottomRightRadius={5}
      borderColor="lineColor"
      borderTop="none"
      flexDirection="column"
      marginBottom="large"
    >
      {!loading && (
        <NumberWithIconGrid>
          <NumberWithIconGrid.Item
            href="#/admin/users"
            icon={<UserIcon width={40} height={40} fillColor="#8C8C8E" />}
            label="Users"
            value={String(digObject(users, 'count', 0))}
          />

          <NumberWithIconGrid.Item
            href="#/admin/teams"
            icon={<TeamIcon width={40} height={40} fillColor="#8C8C8E" />}
            label="Teams"
            value={String(digObject(teams, 'count', 0))}
          />

          <NumberWithIconGrid.Item
            href="#/overviews/projects?status=current"
            icon={<ProjectIcon width={40} height={40} fillColor="#8C8C8E" />}
            label="Projects"
            value={String(digObject(projects, 'count', 0))}
          />

          <NumberWithIconGrid.Item
            href="#/overviews/digitalPages?digitalTemplateType=proposal"
            icon={<DigitalPageIcon width={40} height={40} fillColor="#8C8C8E" />}
            label="Pages"
            value={String(digObject(digital_pages, 'count', 0))}
          />
        </NumberWithIconGrid>
      )}
    </Box>
  )
}

RelatedEntityLinks.propTypes = {
  organization: PropTypes.object.isRequired,
}

export default RelatedEntityLinks
