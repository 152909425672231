import React from 'react'
import PropTypes from 'prop-types'

import { Box, SortableList } from '@campaignhub/suit-theme'

import DraggableMedia from './components/DraggableMedia'

const ListView = (props) => {
  const { imagesPayload } = props
  const {
    bulkSelectedImageIds,
    callbacks,
    sortedImageIds,
    sortedImages,
  } = imagesPayload

  const { modifyImageSort } = callbacks

  return (
    <SortableList
      callbacks={{
        onDragEnd: (fromIndex, toIndex, payload) => modifyImageSort(fromIndex, toIndex, payload, { sortedImageIds }),
      }}
      droppableId="ProjectMediaListView"
    >
      <Box flexDirection="column">
        <SortableList.Dropzone droppableId="sortedImageIds">
          <Box flexDirection="column">
            {sortedImages.map((image, index) => {
              const selected = bulkSelectedImageIds.includes(image.id)

              return (
                <SortableList.Item draggableId={`Image-${image.id}`} key={image.id} index={index}>
                  <DraggableMedia
                    callbacks={callbacks}
                    image={image}
                    index={index}
                    key={image.id}
                    selected={selected}
                  />
                </SortableList.Item>
              )
            })}
          </Box>
        </SortableList.Dropzone>
      </Box>
    </SortableList>
  )
}

ListView.propTypes = {
  imagesPayload: PropTypes.shape({
    bulkSelectedImageIds: PropTypes.array.isRequired,
    callbacks: PropTypes.object.isRequired,
    sortedImages: PropTypes.array.isRequired,
    sortedImageIds: PropTypes.array.isRequired,
  }).isRequired,
}

export default ListView
