import { Box, Checkbox, ListItem } from '@campaignhub/suit-theme'

interface FeatureProps {
  bulkUpdateFeaturesPayload: {
    callbacks: {
      toggleFeatureKeys: (key: string) => void,
    },
  selectedFeatureKeys: string[],
  },
  feature: {
    key: string,
    label: string,
    supported: boolean,
  },
}

const SupportedFeatureListItem = (props: FeatureProps) => {
  const { bulkUpdateFeaturesPayload, feature } = props

  const {
    callbacks: {
      toggleFeatureKeys,
    },
    selectedFeatureKeys,
  } = bulkUpdateFeaturesPayload

  const { key, label } = feature

  return (
    <Box flexDirection="column">
      <ListItem
        boxProps={{
          alignItems: 'center',
          paddingY: 'medium',
        }}
        key={key}
        onClick={() => toggleFeatureKeys(key)}
      >
        <Checkbox
          checked={selectedFeatureKeys.includes(key)}
          onClick={() => toggleFeatureKeys(key)}
        />

        <Box color="bodyFontLightColor" fontSize="small">
          {label}
        </Box>
      </ListItem>
    </Box>

  )
}

export default SupportedFeatureListItem
