import React from 'react'
import PropTypes from 'prop-types'

import AssetLibrary from './AssetLibrary'
import Organization from './Organization'
import Project from './Project'
import ProjectComparable from './ProjectComparable'
import User from './User'

const components = {
  Organization,
  ProjectComparable,
  AssetLibrary,
  Project,
  User,
}

const LibrarySelector = (props) => {
  const { imageSelectPayload, imageSelectPayload: { selectedEntityType } } = props

  const Component = components[selectedEntityType]
  if (!Component){
    return null
  }

  return <Component imageSelectPayload={imageSelectPayload} />
}

LibrarySelector.propTypes = {
  imageSelectPayload: PropTypes.shape({
    selectedEntityType: PropTypes.string.isRequired,
  }).isRequired,
}

export default LibrarySelector
