import PropTypes from 'prop-types'

import { useSelector } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArchive, faCog, faEyeSlash, faPencil, faThumbsDown, faThumbsUp,
} from '@fortawesome/pro-light-svg-icons'

import {
  Button, IconWithMenu, Link, ListItemWithImage, StatusBadge,
} from '@campaignhub/suit-theme'
import { digObject } from '@campaignhub/javascript-utils'

import useDigitalTemplate from '@hooks/useDigitalTemplate'
import useLocalization from '@hooks/useLocalization'
import useManageEntityUserPermissions from '@hooks/useManageEntityUserPermissions'

import DigitalPagePlaceholderImage from '@components/DigitalPagePlaceholderImage'

import defaultRequestOptions from '../../../TemplateCustomizer/defaultRequestOptions'

import localizedStrings from './localizedStrings'

const TemplateListItem = (props) => {
  const { digitalTemplate } = props
  const { owner_id, owner_type } = digitalTemplate

  const digitalTemplatePayload = useDigitalTemplate(digitalTemplate)
  const {
    callbacks: {
      toggleTemplateHidden, updateDigitalTemplate: updateFn,
      toggleProductionReady: toggleFn,
    },
    digitalTemplate: {
      hidden, id, production_ready, title,
    },
    digitalTemplateType: { key: digitalTemplateTypeKey, title: templateTypeTitle },
    previewImage,
    urls: { customizeDigitalTemplateUrl },
  } = digitalTemplatePayload

  const manageTemplateUserPayload = useManageEntityUserPermissions({
    entity: digitalTemplate,
    updateFn,
  })

  const { callbacks: { manageEntityUserPermission } } = manageTemplateUserPayload

  const entities = useSelector(reduxState => reduxState.entities)
  const { organizations } = entities

  const organization = organizations[owner_id] || {}

  const isEditable = owner_type === 'Organization' && !!organization.id && !production_ready
  const isSystemOwned = owner_type === 'System'

  const previewImageUrl = digObject(previewImage, 'sizes.thumb_640x360', '')

  const {
    callbacks: { formatString },
    strings,
  } = useLocalization(localizedStrings)

  return (
    <ListItemWithImage
      imageComponent={(
        <ListItemWithImage.Image
          href={!isSystemOwned && !production_ready ? customizeDigitalTemplateUrl : null}
          imageBoxProps={{ backgroundColor: !previewImageUrl ? '#F2F9FB' : null }}
          url={previewImageUrl}
          title={title}
        >
          <DigitalPagePlaceholderImage
            color="#61ABBB"
            digitalTemplateTypeKey={digitalTemplateTypeKey}
            height="80%"
            width="80%"
          />
        </ListItemWithImage.Image>
      )}
    >
      <ListItemWithImage.Header href={isEditable ? customizeDigitalTemplateUrl : null} showAngleIcon={isEditable}>
        <ListItemWithImage.HeaderLeft>
          <ListItemWithImage.HeaderText>{title}</ListItemWithImage.HeaderText>
          <StatusBadge
            color={production_ready ? 'tan' : 'mysteryGrey'}
            text={production_ready ? strings.inProduction || 'In Production' : strings.draft || 'Draft'}
          />
        </ListItemWithImage.HeaderLeft>
      </ListItemWithImage.Header>

      <ListItemWithImage.Footer>
        <ListItemWithImage.FooterLeft>
          <ListItemWithImage.FooterText boxProps={{ fontSize: 'small', marginBottom: 'small' }}>
            {templateTypeTitle}
          </ListItemWithImage.FooterText>

          <ListItemWithImage.FooterText boxProps={{ color: 'mysteryGrey', fontSize: 'xsmall' }}>
            {formatString(strings.digitalTemplate, { id })}
          </ListItemWithImage.FooterText>
        </ListItemWithImage.FooterLeft>

        <ListItemWithImage.FooterRight>
          {!isSystemOwned && (
            <IconWithMenu
              icon={(
                <Button
                  buttonStyle="secondaryUtility"
                  icon={<FontAwesomeIcon icon={faCog} />}
                  size="medium"
                  height={37}
                />
              )}
              style={{ marginLeft: 'auto', width: 'auto' }}
            >
              <IconWithMenu.Menu closeOnClick listStyle={{ right: '-7px' }}>
                {isEditable && (
                  <Link href={customizeDigitalTemplateUrl} key="edit">
                    <FontAwesomeIcon icon={faPencil} /> {strings.editTemplate || 'Edit Template'}
                  </Link>
                )}

                <Link key="permissions" onClick={() => manageEntityUserPermission()}>
                  <FontAwesomeIcon icon={faEyeSlash} /> {strings.permissions || 'Permissions'}
                </Link>

                <Link
                  key="approve"
                  onClick={() => toggleFn()}
                >
                  <FontAwesomeIcon icon={production_ready ? faThumbsDown : faThumbsUp} />
                  {production_ready ? strings.unApprove || 'Unapprove' : strings.approve || 'Approve'}
                </Link>

                <Link key="archive" onClick={() => toggleTemplateHidden(defaultRequestOptions.digitalTemplate)}>
                  <FontAwesomeIcon icon={faArchive} />
                  {hidden
                    ? strings.unarchiveTemplate || 'Unarchive Template'
                    : strings.archiveTemplate || 'Archive Template'}
                </Link>
              </IconWithMenu.Menu>
            </IconWithMenu>
          )}
        </ListItemWithImage.FooterRight>
      </ListItemWithImage.Footer>
    </ListItemWithImage>
  )
}

TemplateListItem.propTypes = {
  digitalTemplate: PropTypes.object.isRequired,
}

export default TemplateListItem
