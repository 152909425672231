export default {
  default: {
    availableUsers: 'Available Users',
    blankState: {
      paragraph: 'To import users from another platform you’ll need to set up a suitable integration. Integrations can be managed in your client admin.',
    },
    deselectAllButton: 'Deselect All',
    importUserIntoLabel: 'Importing Users into',
    importUsersButton: 'Import Users',
    moreOptionsLabel: 'More Options',
    noticeBox: {
      title: 'Nothing to Import',
      paragraph: 'No Users Available. Create a user in {integrationPlatformTitle} for it to be made available here.',
    },
    toast: {
      importUsers: 'Import Users Queued',
    },
    platformLabel: 'Platform',
    searchUsersLabel: 'Search Users',
    selectAllButton: 'Select All',
    selectUsersLabel: 'Select Users',
    title: 'Import',
    titleSecondLine: 'Users',
    options: 'Please Select...',
  },
}
