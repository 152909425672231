export default {
  default: {
    digitalPageAccepted: {
      title: 'Page Accepted Notification',
      description: 'User will receive notification when a share link has been accepted by a viewer',
    },
    formResponseSubmitted: {
      title: 'Form Response Submitted',
      description: 'User will receive notification when a form response is submitted.',
    },
    shareLinkViewed: {
      title: 'Share Link Viewed',
      description: 'Receive a notification whenever a share link with tracking enabled is viewed',
    },
  },
}
