import { useMemo } from 'react'

import { useModals, useSetState } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import handleCallbackAction from '@functions/handleCallbackAction'

import BuildDigitalPageMetricsModal from '@modals/BuildDigitalPageMetricsModal'
import ViewDigitalPageMetricCollectionModal from '@modals/ViewDigitalPageMetricCollectionModal'

import PageContent from './components/PageContent'

const callbacks = (component, setState) => {
  const componentCallbacks = {
    BuildDigitalPageMetricsModal: {
      closeModal: () => setState({ showBuildDigitalPageMetricsModal: false }),
      buildDigitalPageMetrics: payload => handleCallbackAction(payload),
    },
    ViewDigitalPageMetricCollectionModal: {
      closeModal: () => setState({ showViewDigitalPageMetricCollectionModal: false }),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  pageFilters: {},
  showBuildDigitalPageMetricsModal: false,
  showViewDigitalPageMetricCollectionModal: false,
}

const DigitalPageMetrics = () => {
  const [state, setState] = useSetState(defaultState)
  const {
    pageFilters,
    showBuildDigitalPageMetricsModal,
    showViewDigitalPageMetricCollectionModal,
  } = state

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const pageContext = useMemo(
    () => ({
      callbacks: {
        resetFilters: (resetFn) => {
          if (resetFn) resetFn()
          setState({ pageFilters: {} })
        },
        showBuildDigitalPageMetricsModal: () => setState({ showBuildDigitalPageMetricsModal: true }),
        showViewDigitalPageMetricCollectionModal: (payload) => {
          setModalData('ViewDigitalPageMetricCollectionModal', payload)
          setState({ showViewDigitalPageMetricCollectionModal: true })
        },
        updateFilters: (filters) => { setState({ pageFilters: filters }) },
      },
    }),
    [],
  )

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent pageFilters={pageFilters} />

        <BuildDigitalPageMetricsModal
          callbacks={callbacks('BuildDigitalPageMetricsModal', setState)}
          showModal={showBuildDigitalPageMetricsModal}
        />

        <ViewDigitalPageMetricCollectionModal
          callbacks={callbacks('ViewDigitalPageMetricCollectionModal', setState)}
          showModal={showViewDigitalPageMetricCollectionModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default DigitalPageMetrics
