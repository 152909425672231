import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, DashboardModule, ListItem, Text,
} from '@campaignhub/suit-theme'

import useAddress from '@hooks/useAddress'
import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const Address = (props) => {
  const { address } = props

  const {
    address: { region_name },
    callbacks: { createOrEditAddress },
    streetAddress,
  } = useAddress(address)

  const { strings } = useLocalization(localizedStrings)

  return (
    <DashboardModule title={strings.title}>
      <Box flexDirection="column">
        <ListItem
          boxProps={{ alignItems: 'center', borderBottom: '1px dashed', padding: 'large' }}
          onClick={() => createOrEditAddress()}
          showAngleIcon
        >
          <Box flexDirection="column">
            <Text fontSize="small" marginBottom="medium">
              {streetAddress}
            </Text>

            <Text color="bodyFontLightColor" fontSize="xsmall">
              {region_name}
            </Text>
          </Box>
        </ListItem>
      </Box>
    </DashboardModule>
  )
}

Address.propTypes = {
  address: PropTypes.object.isRequired,
}

export default Address
