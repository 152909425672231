import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'

import {
  Box, Image, Link, ListItem, Text,
} from '@campaignhub/suit-theme'

import DigitalPagePlaceholderImage from '@components/DigitalPagePlaceholderImage'

import useDigitalTemplate from '@hooks/useDigitalTemplate'

const SelectedTemplate = (props) => {
  const {
    callbacks: { deselectTemplate },
    digitalTemplate,
    digitalTemplate: { id, title },
  } = props

  const { digitalTemplateType, previewImage } = useDigitalTemplate(digitalTemplate)
  const previewImageUrl = digObject(previewImage, 'sizes.thumb_660x360', '')

  return (
    <ListItem boxProps={{ alignItems: 'center', padding: 'medium' }} onClick={deselectTemplate}>
      <Box width={60} marginRight="medium">
        <Image
          borderRadius={5}
          boxProps={{ backgroundColor: !previewImageUrl ? '#F2F9FB' : null }}
          height={45}
          marginRight="medium"
          url={previewImageUrl}
          width={60}
        >
          {!previewImageUrl && (
            <DigitalPagePlaceholderImage
              color="#61ABBB"
              digitalTemplateTypeKey={digitalTemplateType.key}
              height="100%"
              width="100%"
            />
          )}
        </Image>
      </Box>

      <Box justifyContent="center" flexDirection="column" width="calc(100% - 75px)">
        <Text color="bodyFontColor" fontSize="small" marginBottom="small" variant="ellipsis">
          {title}
        </Text>
        <Text color="bodyFontLightColor" fontSize="small" marginBottom="xxsmall" variant="ellipsis">
          {`Template ID# ${id}`}
        </Text>
      </Box>

      <Link greyLink onClick={deselectTemplate} textProps={{ fontSize: 'small' }}>
        <FontAwesomeIcon icon={faTrashAlt} />
      </Link>
    </ListItem>
  )
}
SelectedTemplate.propTypes = {
  callbacks: PropTypes.shape({
    deselectTemplate: PropTypes.func,
  }).isRequired,
  digitalTemplate: PropTypes.object.isRequired,
}

export default SelectedTemplate
