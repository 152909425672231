import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import {
  Box, Button, LoadingModule, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useLocalization from '@hooks/useLocalization'

import useExternalAgreementEvents from './hooks/useExternalAgreementEvents'

import Event from './components/Event'

import localizedStrings from './localizedStrings'

const AgreementExternalEventsModal = (props) => {
  const { callbacks, modalKey, showModal } = props
  const { closeModal } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const { agreement } = digObject(modalData, modalKey, {})

  const eventsPayload = useExternalAgreementEvents(agreement)
  const { sortedEvents, loading } = eventsPayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.sideBarModalHeader?.title || 'Agreement'}
        titleSecondLine={strings.sideBarModalHeader?.titleSecondLine || 'Events'}
      />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <LoadingModule loading={loading} times={3} />

          {!!sortedEvents.length
            && !loading
            && sortedEvents.map((event, index) => {
              const key = `${index}${event.date_time_with_timezone}`

              return <Event event={event} key={key} />
            })}
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="secondary"
          onClick={closeModal}
          size="medium"
        >
          {strings?.buttons?.close || 'Close'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

AgreementExternalEventsModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

AgreementExternalEventsModal.defaultProps = {
  modalKey: 'AgreementExternalEventsModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <AgreementExternalEventsModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
