import { useContext, useEffect } from 'react'

import { digObject } from '@campaignhub/javascript-utils'

import {
  Box, Button, FormField, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import type { SidebarModalProps } from '@campaignhub/suit-theme'

import type { QuoteModel } from '@models/quote'

import ToggleableUserListItem from '@components/ToggleableUserListItem'

import useLocalization from '@hooks/useLocalization'
import useManageEntityUsers from '@hooks/useManageEnitityUsers'
import useOrganizationSelector from '@hooks/useOrganizationSelector'
import useUsers from '@hooks/useUsers'

import localizedStrings from './localizedStrings'

type EntityUserPermissionsPayload = {
  callbacks: {
    action: () => void,
    afterAction: (closeModal: () => void) => void,
  },
  entityParams: QuoteModel,
  toastText: string,
}

type EntityUserPermissionsModalProps = {
  callbacks: {
    closeModal: () => void,
    updateEntityUserPermissions: (payload:{}) => void,
  },
}

const ManageEntityUserPermissionsModal = (props: SidebarModalProps & EntityUserPermissionsModalProps) => {
  const {
    callbacks, modalKey = 'ManageEntityUserPermissionsModal', showModal,
  } = props
  const { closeModal, updateEntityUserPermissions } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const {
    callbacks: { updateFn },
    entity,
  } = digObject(modalData, `${modalKey}`, {})

  const data = entity.data || entity.options || {}
  const assignedUserIds = digObject(data, 'user_ids', [])

  const manageTemplateUsersPayload = useManageEntityUsers()

  const {
    callbacks: { selectUser, setState },
    currentlySelectedIds,
    showSelected,
    string,
  } = manageTemplateUsersPayload

  const updatedData = entity.data
    ? JSON.stringify({ ...data, user_ids: currentlySelectedIds })
    : { ...data, user_ids: currentlySelectedIds }

  const entityParams = entity.data ? { ...entity, data: updatedData } : { ...entity, options: updatedData }
  const { strings } = useLocalization(localizedStrings)

  const updateTemplatePayload: EntityUserPermissionsPayload = {
    callbacks: {
      action: updateFn,
      afterAction: closeModal,
    },
    entityParams,
    toastText: strings.toastText?.pemissionsUpdated || 'Permissions Updated',
  }

  useEffect(() => setState({ currentlySelectedIds: assignedUserIds }), [])

  const { selectedOrganization } = useOrganizationSelector()

  const usersPayload = useUsers(
    {
      filters: { organization_id: selectedOrganization.id, string },
      performHttpRequests: true,
    },
  )
  const { filteredUsers } = usersPayload

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title="Manage" titleSecondLine="Permissions" />

      <SidebarModal.Content>
        <FormField direction="column" boxProps={{ marginBottom: 'large' }} label="Search Users">
          <input
            name="title"
            placeholder="Filter user by name"
            onChange={e => setState({ string: e.target.value })}
            type="text"
            value={string}
          />
        </FormField>

        <Box flexDirection="column" flexShrink={0} marginBottom="large" maxHeight="80%" style={{ overflowY: 'auto' }}>
          {filteredUsers.map((user) => {
            const selected = currentlySelectedIds.includes(user.id)

            if ((showSelected && selected) || !showSelected){
              return (
                <ToggleableUserListItem
                  callbacks={{ toggleItem: userId => selectUser(userId) }}
                  key={user.id}
                  user={user}
                  selected={selected}
                />
              )
            }

            return null
          })}
        </Box>

        <FormField direction="column" label="More Options">
          <Box flexDirection="column" flexShrink={0}>
            <Button
              buttonStyle="secondaryUtility"
              onClick={() => setState({ showSelected: !showSelected })}
              size="medium"
            >
              {showSelected ? 'Show All' : 'Show Selected'}
            </Button>
          </Box>
        </FormField>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          onClick={() => updateEntityUserPermissions(updateTemplatePayload)}
          size="large"
        >
          Save Permissions
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: SidebarModalProps & EntityUserPermissionsModalProps) => (
  <SidebarModal.RenderController {...props}>
    <ManageEntityUserPermissionsModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
