import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan } from '@fortawesome/pro-light-svg-icons'

import {
  Box, FormField, LoadingModule, ModalContext, SidebarModal, SidebarNoticeBox,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useDigitalPageInteractions from '@hooks/useDigitalPageInteractions'
import useLocalization from '@hooks/useLocalization'
import useShareLinkAnalytics from '@hooks/useShareLinkAnalytics'

import Interaction from './components/Interaction'
import LinkView from './components/LinkView'
import ShareLink from './components/ShareLink'
import Statistics from './components/Statistics'

import localizedStrings from './localizedStrings'

const ShareLinkAnalyticsModal = (props) => {
  const { callbacks, modalKey, showModal } = props

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const { shareLink: initShareLink } = digObject(modalData, `${modalKey}`, {})

  const { filteredPageViews, loading, shareLink } = useShareLinkAnalytics(initShareLink)

  const { mergedInteractions } = useDigitalPageInteractions({ shareLinkId: shareLink.id })

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.sideBarModalHeader?.title || 'Link'}
        titleSecondLine={strings.sideBarModalHeader?.titleSecondLine || 'Analytics'}
      />

      <SidebarModal.Content boxProps={{ padding: 0 }} hasFooter={false}>
        <Box flexDirection="column" flexShrink={0}>
          <LoadingModule loading={loading} times={3} />

          {!loading && (
            <>
              <ShareLink shareLink={shareLink} viewCount={filteredPageViews.length} />

              <Box flexDirection="column" padding="large">
                {!filteredPageViews.length && (
                  <SidebarNoticeBox boxProps={{ marginBottom: 'large' }}>
                    <SidebarNoticeBox.Title>
                      <FontAwesomeIcon icon={faBan} /> {strings.noticeBox?.title || 'No Link Activity'}
                    </SidebarNoticeBox.Title>

                    <SidebarNoticeBox.Paragraph>
                      {strings.noticeBox?.paragraph
                        || 'Your link hasn’t been accessed yet. Once it’s been interacted with we’ll show you data about who’s been viewing it and when.'}
                    </SidebarNoticeBox.Paragraph>
                  </SidebarNoticeBox>
                )}

                <Statistics shareLink={shareLink} />

                {!!mergedInteractions.length && (
                  <FormField
                    direction="column"
                    label={strings.formFieldLabels?.ineractions || 'Interactions'}
                    marginTop="large"
                  >
                    {mergedInteractions.map(interaction => (
                      <Interaction interaction={interaction} key={`${interaction.key}${interaction.date}`} />
                    ))}
                  </FormField>
                )}

                {!!filteredPageViews.length && (
                  <FormField
                    direction="column"
                    label={strings.formFieldLabels?.linkViews || 'Link Views'}
                    marginTop="large"
                  >
                    {filteredPageViews.map(view => (
                      <LinkView view={view} key={view.id} />
                    ))}
                  </FormField>
                )}
              </Box>
            </>
          )}
        </Box>
      </SidebarModal.Content>
    </SidebarModal>
  )
}

ShareLinkAnalyticsModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

ShareLinkAnalyticsModal.defaultProps = {
  modalKey: 'ShareLinkAnalyticsModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <ShareLinkAnalyticsModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
