import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { DynamicInput, PageFilterModule as PageFilterModuleBase } from '@campaignhub/suit-theme'

import searchEndpoint from '@functions/search'

import useLocalization from '@hooks/useLocalization'

import EntitySelector from '@components/EntitySelector'

import localizedStrings from './localizedStrings'

const supportedComponents = {
  DynamicInput,
  EntitySelector,
}

const PageFilterModule = (props) => {
  const { callbacks, ...restProps } = props

  const entities = useSelector(reduxState => reduxState.entities)

  const { strings } = useLocalization(localizedStrings)

  return (
    <PageFilterModuleBase
      callbacks={{ ...callbacks, searchEndpoint }}
      entities={entities}
      strings={strings}
      supportedComponents={supportedComponents}
      {...restProps}
    />
  )
}

PageFilterModule.propTypes = {
  callbacks: PropTypes.shape({
    updateFilters: PropTypes.func.isRequired,
  }).isRequired,
  filterFields: PropTypes.array.isRequired,
  pageFilters: PropTypes.object.isRequired,
}

export default PageFilterModule
