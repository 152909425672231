import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/pro-light-svg-icons'

import { Box, Link, Text } from '@campaignhub/suit-theme'

import useAgreementPageItem from '../../../hooks/useAgreementPageItem'

import CustomField from './CustomField'

const PageItem = (props) => {
  const {
    agreement,
    callbacks: { manageAgreementFeature },
    documentTemplatePageItem,
    filteredRecipients,
    zoom,
  } = props

  // Page Item
  const agreementPageItemPayload = useAgreementPageItem({
    agreement,
    documentTemplatePageItem,
    filteredRecipients,
    zoom,
  })

  const {
    comparableOptions,
    dataContext,
    height,
    inputValue,
    isEditable,
    isEditableInline,
    label,
    projectAddress,
    recipientColor,
    recipientIsContact,
    recipientIsUser,
    recipientSubject,
    width,
    x,
    y,
    z,
  } = agreementPageItemPayload

  if (isEditableInline){
    return <CustomField agreementPageItemPayload={agreementPageItemPayload} zoom={zoom} />
  }

  return (
    <Box
      alignItems="center"
      borderColor={recipientColor}
      borderRadius={5}
      height={height}
      left={x}
      padding="small"
      position="absolute"
      top={y}
      width={width}
      zIndex={z}
    >
      <Text
        color={inputValue ? 'bodyFontColor' : 'bodyFontLightColor'}
        fontSize={10 * zoom}
        style={{ whiteSpace: 'pre-line' }}
      >
        {inputValue || label}
      </Text>

      {isEditable && (
        <Link
          onClick={() => manageAgreementFeature(dataContext, {
            address: projectAddress,
            contact: recipientIsContact ? recipientSubject : {},
            featureOptions: comparableOptions,
            user: recipientIsUser ? recipientSubject : {},
          })}
          textProps={{ fontSize: 10 * zoom, marginLeft: 'small' }}
        >
          <FontAwesomeIcon icon={faPencil} />
        </Link>
      )}
    </Box>
  )
}

PageItem.propTypes = {
  agreement: PropTypes.object.isRequired,
  callbacks: PropTypes.shape({
    manageAgreementFeature: PropTypes.func.isRequired,
  }).isRequired,
  documentTemplatePageItem: PropTypes.object.isRequired,
  filteredRecipients: PropTypes.array.isRequired,
  zoom: PropTypes.number.isRequired,
}

export default PageItem
