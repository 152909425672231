export default {
  default: {
    buttons: {
      hideExtraFields: 'Hide Extra Fields',
      save: 'Save',
      showAll: 'Show All Project Fields',
    },
    formFieldLabels: {
      moreOptions: 'More Options',
    },
    sideBarModalHeader: {
      title: 'Project',
      titleSecondLine: 'Details',
    },
  },
}
