import { useSelector } from 'react-redux'
import cloneDeep from 'lodash/cloneDeep'

import { digObject } from '@campaignhub/javascript-utils'

const filterEntityDataStoreItems = (dataStoreItems, entity) => {
  const array = Object.values(dataStoreItems)
  const filtered = array.filter((dataStoreItem) => {
    const { subject_type, subject_id } = dataStoreItem
    return subject_type === entity.type && subject_id === entity.id
  })

  return filtered
}

const findDataStoreItemValueForKey = (dataStoreItemsArray, dataStoreItemKey) => {
  const dataStoreItem = dataStoreItemsArray.find((item) => {
    const { key } = item
    return key === dataStoreItemKey
  }) || {}

  return dataStoreItem.value || ''
}

const mergeEntityCustomData = (entity, customData) => {
  const mergedData = {
    ...cloneDeep(entity),
    ...customData,
  }

  return mergedData
}

const buildNestedObject = (dataStoreItemValue, nestedFields, fieldType) => {
  const mappedValue = nestedFields.reduce((acc, fieldObject) => {
    const { key } = fieldObject

    if (fieldType === 'json_object_array' && dataStoreItemValue.id){
      acc.id = dataStoreItemValue.id
      acc[key] = dataStoreItemValue[key] || ''
    }

    if (fieldType === 'json_object'){
      acc[key] = dataStoreItemValue[key] || ''
    }

    return acc
  }, {})

  return mappedValue
}

const buildNestedArray = (dataStoreItemValue, nestedFields, fieldType) => {
  if (!Array.isArray(dataStoreItemValue)) return []

  const mappedValues = dataStoreItemValue.map(value => buildNestedObject(value, nestedFields, fieldType))

  return mappedValues
}

function useEntityCustomData(entity, filteredCustomFields, options = {}){
  const { keyPrefix, mergeData } = options

  const entities = useSelector(reduxState => reduxState.entities)
  const { dataStoreItems } = entities

  const filteredDataStoreItems = filterEntityDataStoreItems(dataStoreItems, entity)

  // Setup Custom Data object
  // { bedrooms: '2', bathrooms: '1' }
  const customData = filteredCustomFields.reduce((acc, customField) => {
    const { key: customFieldKey } = customField

    const key = keyPrefix ? `${keyPrefix}_${customFieldKey}` : customFieldKey

    const fields = digObject(customField, 'data.fields', [])
    const fieldType = customField.field_type

    const dataStoreItemValue = findDataStoreItemValueForKey(filteredDataStoreItems, customFieldKey)

    // Setup data store items for custom fields with nested fields
    if (fields.length && fieldType === 'json_object'){
      const parsedValue = dataStoreItemValue ? JSON.parse(dataStoreItemValue) : {}

      acc[key] = buildNestedObject(parsedValue, fields, fieldType)
    }

    // Setup data store items for custom fields with nested fields
    if (fields.length && fieldType === 'json_object_array'){
      const parsedValue = dataStoreItemValue ? JSON.parse(dataStoreItemValue) : []

      acc[key] = buildNestedArray(Object.values(parsedValue), fields, fieldType)
    }

    // eslint-disable-next-line no-param-reassign
    if (!fields.length){
      acc[key] = dataStoreItemValue
    }

    return acc
  }, {})

  return {
    customData,
    filteredCustomFields,
    filteredDataStoreItems,
    mergedEntityData: mergeData ? mergeEntityCustomData(entity, customData) : {},
  }
}

export default useEntityCustomData
