import isMobileBrowser from 'is-mobile'

import { DynamicInput, FormField } from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import type { CustomFieldModel, NestedFieldModel } from '@models/customField'

const isMobile = isMobileBrowser()

type Spans = {
  [key: number]: number,
}

const mobileSpans: Spans = {
  25: 1,
  50: 1,
  75: 2,
  100: 2,
}

const desktopSpans: Spans = {
  25: 1,
  50: 2,
  75: 3,
  100: 4,
}

const convertWidthToGridSpan = (fieldWidth: number) => {
  if (isMobile){
    return mobileSpans[fieldWidth] || 2
  }

  return desktopSpans[fieldWidth] || 4
}

type NestedObjectFieldProps = {
  customField: CustomFieldModel,
  customFieldsForm: {
    callbacks: {
      updateNestedObjectField: (customField: CustomFieldModel, nestedField: NestedFieldModel, value: string) => void,
    },
    entityState: CustomFieldModel,
  },
  nestedField: NestedFieldModel,
}

const NestedObjectField = (props: NestedObjectFieldProps) => {
  const {
    customField,
    customFieldsForm: {
      callbacks: { updateNestedObjectField },
      entityState,
    },
    nestedField,
  } = props

  const {
    label, field_type, input_type, width, key,
  } = nestedField

  const fieldValue = digObject(entityState, `${customField.key}.${key}`)
  const optionValues = digObject(nestedField, 'options.optionValues', [])

  return (
    <FormField
      boxProps={{ marginBottom: 'medium' }}
      label={label}
      style={{ gridColumnEnd: `span ${convertWidthToGridSpan(width)}` }}
    >
      <DynamicInput
        style={{ height: field_type === 'textarea' ? 80 : null }}
        currentValue={fieldValue || ''}
        inputComponent={field_type}
        inputType={input_type}
        values={optionValues.map((item: { key: string, value: string }) => ({ key: item.key, label: item.value }))}
        onChange={e => updateNestedObjectField(customField, nestedField, e.target.value)}
      />
    </FormField>
  )
}

export default NestedObjectField
