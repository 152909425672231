import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import {
  useDebounce, useDeepEffect, useSetState, useThunkDispatch,
} from '@campaignhub/react-hooks'

import { digObject, sortArrayBy, toggleArray } from '@campaignhub/javascript-utils'

import * as caseStudyActions from '@redux/modules/caseStudy'

import useEntityDefaultIntegrationPlatform from '@hooks/useEntityDefaultIntegrationPlatform'
import useIntegrations from '@hooks/useIntegrations'
import useIntegrationPlatforms from '@hooks/useIntegrationPlatforms'
import useOrganizationSelector from '@hooks/useOrganizationSelector'

const toggleCaseStudy = (id, state, setState) => {
  const { selectedCaseStudyIds } = state

  const updatedCaseStudyIds = toggleArray(selectedCaseStudyIds, id)

  setState({ selectedCaseStudyIds: updatedCaseStudyIds })
}

const toggleAllCaseStudies = (caseStudies, state, setState) => {
  const { selectedCaseStudyIds } = state

  if (selectedCaseStudyIds.length){
    setState({ selectedCaseStudyIds: [] })
    return
  }

  setState({ selectedCaseStudyIds: caseStudies.map(caseStudy => caseStudy.id) })
}

const loadFromExternalPlatform = (externalPlatformKey, requestOptions, dispatch, setState) => {
  const { loadFromExternalPlatform: loadFromExternalPlatformFn } = caseStudyActions

  dispatch(loadFromExternalPlatformFn(externalPlatformKey, requestOptions)).then(({ success, data }) => {
    if (success){
      setState({ externalCaseStudies: data, loading: false })
    }
    setState({ loading: false })
  })
}

const bulkImportFromExternalPlatform = (externalPlatformKey, payloadIds, dispatch, requestOptions) => {
  const { externalIds, organizationId } = payloadIds

  const { bulkImportFromExternalPlatform: importFn } = caseStudyActions
  const payload = {
    externalIds,
    organizationId,
  }

  return dispatch(importFn(externalPlatformKey, payload, requestOptions))
}

const getAlreadyImportedCaseStudyIds = (reduxCaseStudies, selectedOrganizationId, selectedIntegrationPlatformKey) => {
  const alreadyImportedExternalIds = Object.values(reduxCaseStudies).reduce((acc, caseStudy) => {
    const source_platform = digObject(caseStudy, 'data.source_platform')
    const external_id = digObject(caseStudy, `data.external_ids.${source_platform}`)
    const ownerId = digObject(caseStudy, 'owner_id')

    if (
      ownerId === selectedOrganizationId
      && source_platform === selectedIntegrationPlatformKey
      && external_id
    ){
      acc.push(external_id)
    }

    return acc
  }, [])

  return alreadyImportedExternalIds
}

const defaultState = {
  externalCaseStudies: [],
  loading: false,
  selectedIntegrationPlatformKey: '',
  selectedCaseStudyIds: [],
  string: '',
}

const useImportCaseStudies = () => {
  const [state, setState] = useSetState(defaultState)
  const {
    externalCaseStudies, loading, selectedIntegrationPlatformKey, selectedCaseStudyIds, string,
  } = state

  const dispatch = useThunkDispatch()

  const debouncedString = useDebounce(string, 300)

  const {
    callbacks: { selectOrganizationId },
    selectedOrganization,
    sortedOrganizationsWhereAdmin,
  } = useOrganizationSelector()

  const {
    filteredPlatforms,
    hasIntegrations,
    urls: integrationsUrls,
  } = useIntegrations({
    featureKeys: ['import_case_studies'],
    filters: {
      owner_id: selectedOrganization.id,
      owner_type: 'Organization',
    },
  })

  const { defaultPlatformKey } = useEntityDefaultIntegrationPlatform({ featureKey: 'import_case_studies' })

  // Integration Platform
  const { callbacks: { findIntegrationPlatformByKey } } = useIntegrationPlatforms()
  const selectedIntegrationPlatform = findIntegrationPlatformByKey(selectedIntegrationPlatformKey)

  const {
    entities: { caseStudies: reduxCaseStudies },
  } = useSelector(reduxState => reduxState)

  // Create an array of IDs for caseStudies that have already been imported
  // Push the external ID if the caseStudy is in the right organization, has
  // been imported from the right platform, and has an external_id
  const alreadyImportedExternalIds = getAlreadyImportedCaseStudyIds(
    reduxCaseStudies,
    selectedOrganization.id,
    selectedIntegrationPlatformKey,
  )

  // Re-fetch case studies whenever integration platform or search string change
  useEffect(() => {
    if (selectedIntegrationPlatformKey){
      const fetchRequestParams = {
        limit: 100,
        organization_id: selectedOrganization.id,
        string: debouncedString,
      }

      loadFromExternalPlatform(selectedIntegrationPlatformKey, fetchRequestParams, dispatch, setState)
      setState({ externalCaseStudies: [], loading: true })
    }
  }, [selectedIntegrationPlatformKey, selectedOrganization.id, debouncedString])

  // Select all case studies that have not already been imported
  useDeepEffect(() => {
    const notImportedExternalIds = externalCaseStudies
      .filter(externalCaseStudy => !alreadyImportedExternalIds.includes(externalCaseStudy.id))
      .map(externalCaseStudy => externalCaseStudy.id)

    setState({ selectedCaseStudyIds: notImportedExternalIds })
  }, [externalCaseStudies])

  // Check for a user/org's default integration platform, if none auto select first integration platform if only one available
  useEffect(() => {
    if (defaultPlatformKey){
      setState({ selectedIntegrationPlatformKey: defaultPlatformKey })
      return
    }

    if (filteredPlatforms.length === 1){
      const selectedPlatform = filteredPlatforms[0]
      setState({ selectedIntegrationPlatformKey: selectedPlatform.key })
    }
  }, [defaultPlatformKey, filteredPlatforms.length])

  return {
    alreadyImportedExternalIds,
    callbacks: {
      bulkImportFromExternalPlatform: (payloadIds, requestOptions) => bulkImportFromExternalPlatform(
        selectedIntegrationPlatformKey,
        payloadIds,
        dispatch,
        requestOptions,
      ),
      selectOrganizationId,
      setState,
      toggleAllCaseStudies: () => toggleAllCaseStudies(externalCaseStudies, state, setState),
      toggleCaseStudy: id => toggleCaseStudy(id, state, setState),
    },
    externalCaseStudies: sortArrayBy(externalCaseStudies, 'asc', 'full_name'),
    filteredPlatforms,
    hasExternalCaseStudies: !!externalCaseStudies.length,
    hasIntegrations,
    hasSelectedCaseStudies: !!selectedCaseStudyIds.length,
    integrationsUrls,
    loading,
    selectedIntegrationPlatform,
    selectedIntegrationPlatformKey,
    selectedOrganization,
    selectedCaseStudyIds,
    sortedOrganizationsWhereAdmin,
    string,
  }
}

export default useImportCaseStudies
