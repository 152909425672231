import { useMemo } from 'react'

import {
  digObject,
  matchFilterArrayIncludes,
  matchFilterKey,
  matchFilterString,
  modifyGroupedIdsSort,
  sortArrayBy,
  toggleGroupedIdsItem,
} from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useOrganizationSelector from '@hooks/useOrganizationSelector'
import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import { isAdminForOrganizationId, isOwnerForOrganizationId } from '@functions/user'

import defaultRequestOptions from '@sections/Client/defaultRequestOptions'

import type { UserModel } from '@models/types'
import type { ModuleState } from '@redux/modules/types'
import type { OrganizationModel } from '@models/organization'

const watchEntityKeys = ['users']

type ModifyUserPayload = {
  destinationDroppableId: number,
  sourceDroppableId: number,
}

type SetEntityState = (params: { [key: string]: number[] }) => void

// eslint-disable-next-line max-len
const modifyUserSort = (fromIndex: number, toIndex: number, payload: ModifyUserPayload, groupedUserIds: number[], setEntityState: SetEntityState) => {
  const { destinationDroppableId, sourceDroppableId } = payload
  const groupedIds = modifyGroupedIdsSort(fromIndex, toIndex, payload, groupedUserIds)
  return setEntityState({
    [destinationDroppableId]: groupedIds[destinationDroppableId],
    [sourceDroppableId]: groupedIds[sourceDroppableId],
  })
}

const toggleUsers = (userId: number, groupedUserIds: number[], listName: string, setEntityState: SetEntityState) => {
  const groupedIds = toggleGroupedIdsItem(userId, groupedUserIds, listName)

  return setEntityState({ [listName]: groupedIds[listName] })
}

type AccReturnType = {
  key: string,
  title: string,
  users: UserModel[],
}

const groupUsersByOrganizationRole = (
  users: UserModel[],
  selectedOrganization: OrganizationModel,
) => users.reduce<Record<string, AccReturnType>>(
  (acc, user) => {
    const isUserAdmin = isAdminForOrganizationId(user, selectedOrganization.id)
    const isUserOwner = isOwnerForOrganizationId(user, selectedOrganization.id)

    if (isUserAdmin){
      acc.admins?.users.push(user)
    }

    if (isUserOwner){
      acc.owners?.users.push(user)
    }

    if (!isUserOwner && !isUserAdmin){
      acc.users?.users.push(user)
    }

    return acc
  },
  {
    admins: { title: 'Admin', key: 'admins', users: [] },
    owners: { title: 'Owner', key: 'owners', users: [] },
    users: { title: 'User', key: 'users', users: [] },
  },
)

type UserFilters = {
  brand_id?: number,
  active?: string,
  organization_id?: number,
  role_id?: number,
  string?: string,
}

type UseUsersOptions = {
  filters?: UserFilters,
  groupUsers?: UserModel[],
  performHttpRequests?: boolean,
}

function useUsers(options: UseUsersOptions = {}) {
  const { filters = {}, groupUsers } = options
  const {
    brand_id: filterBrandId,
    active: filterActive,
    organization_id: filterOrganizationId,
    role_id: filterRoleId,
    string: filterString,
  } = filters

  const {
    updatedEntities: { users: usersUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { organizations, users } = useSelector(reduxState => reduxState.entities)

  const organization = organizations[filterOrganizationId] || {}
  const { sorted_user_ids, user_ids } = organization || []

  const selectedOrganizationPayload = useOrganizationSelector()
  const { selectedOrganization } = selectedOrganizationPayload

  const filteredUsers = useMemo(() => {
    const filtered = Object.values(users).filter((user: UserModel) => {
      const {
              email, full_name, hidden, id, role_id,
            } = user

      const brandIds = digObject(user, 'brand_ids', [])
      const brandsWhereAdminIds = digObject(user, 'brands_where_admin_ids', [])
      const organizationIds = digObject(user, 'organization_ids', [])

      const brandMatch = matchFilterArrayIncludes(brandIds.concat(brandsWhereAdminIds), Number(filterBrandId))
      const matchId = id.toString() === filterString
      const matchEmail = matchFilterString(email, filterString)
      const matchName = matchFilterString(full_name, filterString)
      const stringMatch = matchId || matchName || matchEmail

      const statusMatch = matchFilterKey(String(hidden), filterActive)
      const organizationMatch = matchFilterArrayIncludes(organizationIds, Number(filterOrganizationId))
      const roleMatch = filterRoleId ? Number(filterRoleId) === role_id : true

      return brandMatch && organizationMatch && roleMatch && statusMatch && stringMatch
    })

    return sortArrayBy(filtered, 'asc', (user) => {
      if (sorted_user_ids && sorted_user_ids !== user_ids){
        const index = sorted_user_ids.indexOf(user.id)

        return index
      }

      return user.full_name
    })
  }, [sorted_user_ids, usersUpdatedAt, JSON.stringify(filters)])

  const groupedUsers = groupUsers ? groupUsersByOrganizationRole(filteredUsers, selectedOrganization) : {}

  const filteredUsersCount = filteredUsers.length
  const hasFilteredUsers = !!filteredUsersCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredUsersCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingUsers } = useReduxAction(
    'users',
    'loadUsers',
    {
      ...defaultRequestOptions.user,
      ...defaultRequestOptions.image,
      ...filtersWithOffset,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
      modifyUserSort,
      toggleUsers,
    },
    canLoadMore,
    filteredUsers,
    filteredUsersCount,
    groupedUsers,
    hasFilteredUsers,
    loading: loadingUsers,
  }
}

export default useUsers
