import React from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

import {
  Box, Button, FormField, SidebarModal, SidebarNoticeBox,
} from '@campaignhub/suit-theme'

const supplySupportToken = (supplyFn, closeModal) => {
  supplyFn().then((result) => {
    const { errors, success } = result
    if (!success && errors){
      toast.warn(errors[0])
      return
    }

    toast('Token supplied to support team successfully.')
    closeModal()
  })
}

const Client = (props) => {
  const {
    callbacks: { closeModal, supplySupportToken: supplyFn },
    supportUser,
    updating,
  } = props

  return (
    <>
      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <SidebarNoticeBox>
            <SidebarNoticeBox.Title>Support Token Request</SidebarNoticeBox.Title>

            <SidebarNoticeBox.Paragraph>
              A user from support is requesting access to your account so that they can assist you with your query. By
              supplying your support token they will be able to access your account for a short period. If you did not
              request support please close this modal and contact support.
            </SidebarNoticeBox.Paragraph>
          </SidebarNoticeBox>

          <FormField direction="column" label="Support User">
            <input defaultValue={supportUser.full_name} disabled />
          </FormField>
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          loading={updating}
          onClick={() => supplySupportToken(supplyFn, closeModal)}
          size="large"
        >
          Grant Access
        </Button>
      </SidebarModal.Footer>
    </>
  )
}

Client.propTypes = {
  callbacks: PropTypes.shape({
    closeModal: PropTypes.func.isRequired,
    supplySupportToken: PropTypes.func.isRequired,
  }).isRequired,
  supportUser: PropTypes.object,
  updating: PropTypes.bool,
}

export default Client
