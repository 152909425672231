import React from 'react'
import PropTypes from 'prop-types'

import { ListItem, Text } from '@campaignhub/suit-theme'

import { statusColor } from '@functions/status'

import useDigitalPage from '@hooks/useDigitalPage'

const CompactList = (props) => {
  const { digitalPage } = props

  const digitalPagePayload = useDigitalPage(digitalPage)
  const {
    project: { title },
    status,
    urls: { editDigitalPageUrl },
  } = digitalPagePayload

  return (
    <ListItem.Compact
      contentRight={<Text color={statusColor(status.key)}>{status.title}</Text>}
      href={editDigitalPageUrl}
    >
      {title}
    </ListItem.Compact>
  )
}

CompactList.propTypes = {
  digitalPage: PropTypes.object.isRequired,
}

export default CompactList
