import React from 'react'
import { Route, Routes } from 'react-router-dom'

import Analytics from './screens/Analytics'
import Activities from './screens/Activities'
import CaseStudies from './screens/CaseStudies'
import CustomFields from './screens/CustomFields'
import DocumentTemplates from './screens/DocumentTemplates'
import EditOrganization from './screens/EditOrganization'
import EventCalendarTemplates from './screens/EventCalendarTemplates'
import FileLibraries from './screens/FileLibraries'
import FormTemplates from './screens/FormTemplates'
import ImageLibraries from './screens/ImageLibraries'
import Integrations from './screens/Integrations'
import QuoteTemplates from './screens/QuoteTemplates'
import Reviews from './screens/Reviews'
import TargetAudiences from './screens/TargetAudiences'
import Teams from './screens/Teams'
import Users from './screens/Users'

const AdminRoutes = () => (
  <Routes>
    <Route path="/analytics" element={<Analytics />} />
    <Route path="/activities" element={<Activities />} />
    <Route path="/caseStudies" element={<CaseStudies />} />
    <Route path="/customFieldSets/*" element={<CustomFields />} />
    <Route path="/documentTemplates" element={<DocumentTemplates />} />
    <Route path="/eventCalendarTemplates/*" element={<EventCalendarTemplates />} />
    <Route path="/fileLibraries" element={<FileLibraries />} />
    <Route path="/formTemplates" element={<FormTemplates />} />
    <Route path="/imageLibraries" element={<ImageLibraries />} />
    <Route path="/integrations" element={<Integrations />} />
    <Route path="/organizations/edit" element={<EditOrganization />} />
    <Route path="/quoteTemplates/*" element={<QuoteTemplates />} />
    <Route path="/reviews" element={<Reviews />} />
    <Route path="/targetAudiences" element={<TargetAudiences />} />
    <Route path="/teams/" element={<Teams />} />
    <Route path="/users/*" element={<Users />} />
  </Routes>
)

export default AdminRoutes
