import { useContext } from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'
import { toast } from 'react-toastify'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faStar, faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'

import {
  Box, Button, FormField, Image, ModalContext, SidebarModal, Text,
} from '@campaignhub/suit-theme'

import useCurrentUser from '@hooks/useCurrentUser'
import useImage from '@hooks/useImage'
import useLocalization from '@hooks/useLocalization'

import Tags from '@components/Tags'

import PlaceHolderImage from './assets/placeholderImage.png'
import localizedStrings from './localizedStrings'

const updateDefaultImage = (image, updateDefaultImageFn, strings) => {
  updateDefaultImageFn(image).then(({ success }) => {
    if (success) toast(strings.defaultImageToast?.updateSuccess || 'Default Image Updated Successfully')
    if (!success) toast.warning(strings.defaultImageToast?.updateFailed || 'Update Failed')
  })
}

const confirmDelete = (params) => {
  const {
    deleteImage, deleteFn, closeModal, strings,
  } = params

  const deleteImagePayload = {
    callbacks: {
      action: () => deleteFn(),
      afterAction: closeModal,
    },
    toastText: strings.toast?.deleteImage || 'Image Deleted Successfully',
  }
  swal
    .fire({
      title: strings.swal?.title || 'Delete Image?',
      text: strings.swal?.text || 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: strings.swal?.confirmButtonText || 'Yes, delete it.',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        deleteImage(deleteImagePayload)
      }
    })
}

const EditImageModal = (props) => {
  const {
    callbacks,
    callbacks: { closeModal, deleteImage },
    modalKey,
    showModal,
  } = props

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const {
    callbacks: { deleteImage: deleteFn, downloadImage, updateDefaultImage: updateDefaultImageFn },
    image,
  } = digObject(modalData, modalKey, {})

  const { id: subjectId, subject_type: subjectType, owner_id: ownerId } = image

  const shouldRenderTag = !!(subjectType && (subjectType === 'User' || subjectType === 'Project'))

  const { deleting, isDefaultImage, updating } = useImage(image)

  const imageUrl = digObject(image, 'sizes.thumb_660x360', '')
  const fileName = digObject(image, 'file_data.metadata.filename')
  const fileSize = digObject(image, 'file_data.metadata.size')

  const { isAdmin, isBrandUser } = useCurrentUser()

  const currentUserCanEdit = isAdmin || isBrandUser || image.owner_type !== 'Brand'

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.modalHeader?.title || 'Edit'}
        titleSecondLine={strings.modalHeader?.titleSecondLine || 'Image'}
      />

      <SidebarModal.Content>
        <Box backgroundImage={`url(${PlaceHolderImage})`} marginBottom="large" borderRadius={5}>
          <Image backgroundPosition="top center" borderRadius={5} height={230} url={imageUrl} />
        </Box>

        <FormField direction="column" label={strings.formFieldLabels?.fileName || 'File Name'} marginTop="large">
          <Text fontSize="xsmall">{fileName}</Text>
        </FormField>

        <FormField direction="column" label={strings.formFieldLabels?.fileSize || 'File Size'} marginTop="large">
          <Text fontSize="xsmall">{fileSize}</Text>
        </FormField>

        {shouldRenderTag && (
          <Tags
            ownerId={ownerId}
            ownerType="Organization"
            subjectId={subjectId}
            subjectType="Image"
            tagEntity={subjectType}
          />
        )}

        <FormField direction="column" label={strings.formFieldLabels?.moreOptions || 'More Options'} marginTop="large">
          <Button
            buttonStyle="secondaryUtility"
            ghost
            icon={<FontAwesomeIcon icon={faDownload} />}
            marginBottom="medium"
            onClick={e => downloadImage(e, imageUrl)}
            size="medium"
          >
            {strings.buttonText?.download || 'Download'}
          </Button>

          {currentUserCanEdit && (
            <Button
              buttonStyle="secondaryUtility"
              ghost
              icon={<FontAwesomeIcon icon={faTrashAlt} />}
              loading={deleting}
              marginBottom="medium"
              onClick={() => confirmDelete({
                deleteImage,
                deleteFn,
                closeModal,
                strings,
              })}
              size="medium"
            >
              {strings.buttonText?.delete || 'Delete Image'}
            </Button>
          )}

          {!isDefaultImage && (
            <Button
              buttonStyle="secondaryUtility"
              ghost
              icon={<FontAwesomeIcon icon={faStar} />}
              loading={updating}
              marginBottom="medium"
              onClick={() => updateDefaultImage(image, updateDefaultImageFn, strings)}
              size="medium"
            >
              {strings.buttonText?.makeDefaultImage || 'Make Default Image'}
            </Button>
          )}
        </FormField>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!currentUserCanEdit}
          loading={updating}
          loadingBubbleColor="white"
          onClick={() => closeModal()}
          size="large"
        >
          {strings.buttonText?.save || 'Save'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

EditImageModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

EditImageModal.defaultProps = {
  modalKey: 'EditImageModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <EditImageModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
