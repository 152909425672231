import { DateTime } from 'luxon'

import {
  digObject, sortArrayBy,
} from '@campaignhub/javascript-utils'

const sortEntitiesByDate = (
  entityArray: [],
  direction: 'asc' | 'desc',
  dateType: string,
) => sortArrayBy(entityArray, direction, (entity) => {
  const dateTime = digObject(entity, `dates.${dateType}.date_time_with_timezone`)
  return DateTime.fromISO(dateTime)
})

export default sortEntitiesByDate
