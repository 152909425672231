import { useMemo } from 'react'

import {
  digObject, matchFilterKey, matchFilterNumber, sortArrayBy,
} from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { SessionLockModel, SessionLockRequestOptions } from '@models/types'
import type { ModuleState } from '@redux/modules/types'
import sortEntitiesByDate from '@functions/sortEntitiesByDate'

const watchEntityKeys = ['sessionLocks']

type SessionLockFilters = {
  excludeUnlocked?: boolean,
  subjectId?: number,
  subjectType?: string,
}

type UseSessionLocksOptions = {
  filters?: SessionLockFilters,
  limit: number,
  performHttpRequests?: boolean,
  requestOptions?: SessionLockRequestOptions,
}

function useSessionLocks(options: UseSessionLocksOptions = {}) {
  const { filters = {}, requestOptions } = options
  const {
    excludeUnlocked: filterExcludeUnlocked,
    subjectId: filterSubjectId,
    subjectType: filterSubjectType,
  } = filters

  const {
    updatedEntities: { sessionLocks: sessionLocksUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { loaded } = useSelector(reduxState => reduxState.sessionLocks)

  const { sessionLocks } = useSelector(reduxState => reduxState.entities)

  const filteredSessionLocks = useMemo(() => {
    const filtered = Object.values(sessionLocks).filter((sessionLock: SessionLockModel) => {
      const { subject_id, subject_type } = sessionLock
      const unlockedAt = digObject(sessionLock, 'dates.unlocked.date_time_with_timezone')

      const lockedMatch = filterExcludeUnlocked ? !unlockedAt : true
      const subjectIdMatch = matchFilterNumber(subject_id, filterSubjectId)
      const subjectTypeMatch = matchFilterKey(subject_type, filterSubjectType)

      return lockedMatch && subjectIdMatch && subjectTypeMatch
    })

    if (options.limit){
      return sortEntitiesByDate(filtered, 'desc', 'updated').slice(0, options.limit)
    }

    return sortArrayBy(filtered, 'desc', 'id')
  }, [sessionLocksUpdatedAt, JSON.stringify(options)])

  const filteredSessionLocksCount = filteredSessionLocks.length
  const hasFilteredSessionLocks = !!filteredSessionLocksCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredSessionLocksCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingSessionLocks } = useReduxAction(
    'sessionLocks',
    'loadSessionLocks',
    {
      ...requestOptions,
      ...filtersWithOffset,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  const recentSessionLock = filteredSessionLocks[0] || {}

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredSessionLocks,
    filteredSessionLocksCount,
    hasFilteredSessionLocks,
    loaded,
    loading: loadingSessionLocks,
    recentSessionLock,
  }
}

export default useSessionLocks
