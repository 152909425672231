const defaultRequestOptions = {
  contact: {
    include_contact_address: true,
    include_contact_users_with_read_access: true,
    include_contact_users_with_write_access: true,
  },
  digitalPage: {
    include_digital_page_digital_template: true,
    include_digital_page_share_links: true,
    include_digital_page_subject: true,
    include_digital_page_total_views: true,
  },
  digitalTemplate: {
    include_digital_template_preview_image: true,
    include_digital_template_type: true,
  },
  image: {
    include_image_sizes: ['thumb_640x360'],
  },
  project: {
    include_project_address: true,
    include_project_combined_user_ids: true,
    include_project_lead_users: true,
    include_project_teams: true,
  },
  shareLink: {
    include_share_link_paths: true,
  },
}

export default defaultRequestOptions
