import PropTypes from 'prop-types'

import { Image } from '@campaignhub/suit-theme'

import placeholderImageUrl from './assets/placeholder.svg'

import platformLogos from './assets/logos'

const IntegrationPlatformLogo = (props) => {
  const {
    boxProps, integrationPlatformKey, size, ...imageProps
  } = props

  if (!integrationPlatformKey){
    return null
  }

  const logoUrl = platformLogos[integrationPlatformKey]

  return (
    <Image
      boxProps={{ padding: size === 'large' ? 'xlarge' : 'medium', ...boxProps }}
      placeholderUrl={placeholderImageUrl}
      style={{ backgroundOrigin: 'content-box' }}
      url={logoUrl}
      {...imageProps}
    />
  )
}

IntegrationPlatformLogo.propTypes = {
  boxProps: PropTypes.object,
  integrationPlatformKey: PropTypes.string,
  size: PropTypes.oneOf(['small', 'large']),
}

export default IntegrationPlatformLogo
