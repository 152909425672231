export default {
  default: {
    createButton: 'Create Library',
    tabTitle: 'Images',
    title: 'Image Libraries',
    brandImageTitle: 'Brand Image Libraries',
    importImages: 'Import Image Libraries',
    tools: 'Tools',
    VideoModule: {
      title: 'How to create image library and use them in your proposals',
      paragraph: 'Create an image library so that your teams can have quick access to edit the images in their proposals, keeping it on brand.',
    },
  },
}
