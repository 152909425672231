import { useContext } from 'react'
import swal from 'sweetalert2'

import {
  Box, Button, FormField, ModalContext, SidebarModal, SidebarNoticeBox,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import { useSetState } from '@campaignhub/react-hooks'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import FilterUsersContactsAndTeams from '@components/FilterUsersContactsAndTeams'
import ModalUsers from '@components/ModalComponents/ModalUsers'

import useLocalization from '@hooks/useLocalization'
import useUser from '@hooks/useUser'

import localizedStrings from './localizedStrings'

const MODAL_KEY = 'RemoveOrganizationsUserModal'

type ModalCallbacks = {
  closeModal: () => void,
  removeUserFromOrganization: (payload: HandleCallbackActionParams) => void,
}

type ModalProps = {
  callbacks: ModalCallbacks,
  showModal: boolean,
}

const defaultState = {
  target_user_id: null,
}

type ConfirmDeleteParams = {
  callbacks: {
    closeModal: () => void,
    removeUserFromOrganization: (payload: HandleCallbackActionParams) => void,
  },
  deleteFn: () => void,
  strings: {
    [key: string]: string,
   },
  targetUserId: number | null,
}

const confirmDelete = (params:ConfirmDeleteParams) => {
  const {
    deleteFn, callbacks, strings, targetUserId,
  } = params
  const { closeModal, removeUserFromOrganization } = callbacks

  const organizationUserPayload: HandleCallbackActionParams = {
    callbacks: {
      action: deleteFn,
      afterAction: closeModal,
    },
    entityParams: targetUserId,
    shouldRedirect: true,
    toastText: strings.toast?.orgainzationUserDeleted || 'User Removed from Organization',
  }

  swal
    .fire({
      title: strings.sweetAlert?.title || 'Remove User From Organization?',
      text: strings.sweetAlert?.text || 'Are you sure? ',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: strings.sweetAlert?.confirmButton || 'Yes, remove from organization.',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        removeUserFromOrganization(organizationUserPayload)
      }
    })
}

const RemoveOrganizationsUserModal = (props: ModalProps) => {
  const { callbacks, showModal } = props
  const { closeModal, removeUserFromOrganization } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})

  const {
    callbacks: {
      removeUserFromOrganization: deleteFn,
    },
    organizationsUser,
  } = modalPayload

  const { user: { full_name } } = useUser({ id: organizationsUser.user_id })

  const [state, setState] = useSetState(defaultState)
  const { target_user_id } = state

  const { callbacks: { formatString }, strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal callbacks={callbacks} modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.remove || 'Remove'}
        titleSecondLine={strings.agencyUser || 'Agency User'}
      />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <SidebarNoticeBox>
            <SidebarNoticeBox.Paragraph boxProps={{ marginTop: 0 }}>
              {formatString(strings.noticeBox?.description, {
                user_name: full_name,
              }) || `Please select a user to transfer ${full_name}'s items to.`}
            </SidebarNoticeBox.Paragraph>

            <SidebarNoticeBox.Paragraph boxProps={{ marginTop: 'medium' }}>
              {strings.noticeBox?.paragraphOne
               || 'All projects, case studies and contacts that are assigned to this user will be assigned to the new user selected.'}
            </SidebarNoticeBox.Paragraph>

            <SidebarNoticeBox.Paragraph boxProps={{ marginTop: 'medium' }}>
              {strings.noticeBox?.paragraphTwo
               || 'Note: Any awards and integrations will be deleted, as well as the user being removed from any reviews and teams they are assigned to.'}
            </SidebarNoticeBox.Paragraph>

            <SidebarNoticeBox.Paragraph boxProps={{ marginTop: 'medium' }}>
              {strings.noticeBox?.thisAction || 'This action '}
              <strong>{strings.noticeBox?.boldText || 'cannot be undone.'}</strong>
            </SidebarNoticeBox.Paragraph>
          </SidebarNoticeBox>

          <FormField direction="column" label={strings.label?.targetUser || '* Target User'} marginTop="medium">
            <FilterUsersContactsAndTeams
              callbacks={{
                selectItem: selected => setState({ target_user_id: selected?.id }),
              }}
              shouldShowOnFocus={false}
              selectedUserIds={[organizationsUser?.user_id, target_user_id]}
            />
          </FormField>

          <ModalUsers
            callbacks={{ deleteUser: () => setState({ target_user_id: null }) }}
            userIds={[target_user_id]}
          />
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryDestroy"
          disabled={!target_user_id}
          onClick={() => confirmDelete({
            callbacks: {
              closeModal,
              removeUserFromOrganization,
            },
            deleteFn,
            strings,
            targetUserId: target_user_id,
          })}
          size="large"
        >
          {strings.removeUser || 'Remove User'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ModalProps) => (
  <SidebarModal.RenderController {...props}>
    <RemoveOrganizationsUserModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
