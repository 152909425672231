import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import PageContext from '@contexts/pageContext'

import replaceTextShortCodes from '@functions/replaceTextShortCodes'
import { shouldRenderPageItem } from '@functions/digitalTemplatePageItem'

import useDeviceStyle from '@hooks/useDeviceStyle'

import AnimationWrapper from '@components/digitalRenderer/components/AnimationWrapper'

const Iframe = (props) => {
  const {
    context: componentContext,
    data: { value: rawValue },
    id,
    options,
    options: { renderConditions },
  } = props

  // Context
  const pageContext = useContext(PageContext)
  const { shortcodeData } = pageContext || {}

  const contextData = { ...shortcodeData, ...componentContext }

  // Value
  const value = replaceTextShortCodes(rawValue, contextData)

  // Style
  const style = useDeviceStyle(props)

  // Should Render
  const shouldRender = shouldRenderPageItem(renderConditions, contextData, options)
  if (!shouldRender) return null

  return (
    <AnimationWrapper options={options}>
      <div style={style}>
        <iframe
          allow="vr"
          allowFullScreen
          frameBorder="0"
          height="100%"
          src={value}
          title={`iframe-${id}`}
          width="100%"
        />
      </div>
    </AnimationWrapper>
  )
}

Iframe.propTypes = {
  context: PropTypes.object,
  data: PropTypes.object,
  id: PropTypes.string.isRequired,
  options: PropTypes.object,
}

Iframe.defaultProps = {
  data: {},
  options: {},
}

export default Iframe
