import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faColumns } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, CustomizableDashboard, Tools,
} from '@campaignhub/suit-theme'

import styles from './styles.module.scss'

const ContentSidebar = React.forwardRef((props, ref) => {
  const {
    callbacks: { customizeDashboard },
    draggablePlaceholder,
    id,
    isDraggingOver,
    modules,
  } = props

  return (
    <Box
      className={classnames(styles.root, isDraggingOver ? styles.draggingOver : null)}
      flexDirection="column"
      width={['100%', '100%', '375px']}
      paddingTop="large"
    >
      <Tools>
        <Button
          buttonStyle="secondaryUtility"
          icon={<FontAwesomeIcon icon={faColumns} />}
          onClick={customizeDashboard}
          size="medium"
        >
          Customise Dashboard
        </Button>
      </Tools>

      <div ref={ref} style={{ flexGrow: 1, minHeight: '100vh' }}>
        {modules && <CustomizableDashboard.ModuleRenderer modules={modules} parentId={id} />}
        {draggablePlaceholder}
      </div>
    </Box>
  )
})

ContentSidebar.propTypes = {
  callbacks: PropTypes.shape({
    customizeDashboard: PropTypes.func.isRequired,
  }).isRequired,
  draggablePlaceholder: PropTypes.object,
  id: PropTypes.string,
  isDraggingOver: PropTypes.bool,
  modules: PropTypes.array,
}

export default ContentSidebar
