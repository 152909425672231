import { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'

import { faGlobe } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Columns, DashboardModule, LoadingModule, PageHeader,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import entityTypes from '@functions/customFieldSet'

import PageFilterModule from '@components/PageFilterModule'

import useCurrentUser from '@hooks/useCurrentUser'
import useCustomFieldSets from '@hooks/useCustomFieldSets'
import useIntercom from '@hooks/useIntercom'
import useMixpanel from '@hooks/useMixpanel'

import CustomFieldSet from '../CustomFieldSet'
import CustomFieldSetBlankState from '../CustomFieldSetBlankState'

const buildPageFilterFields = () => {
  const style = {
    marginBottom: 16,
  }

  const boxProps = {
    marginBottom: 'large',
  }

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        style: {
          marginBottom: 16,
        },
      },
      key: 'string',
      label: 'Filter by Title',
    },
    {
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: 'All',
        inputComponent: 'select',
        style,
      },
      key: 'owner_type',
      label: 'Owner Type',
      values: [
        { key: 'Brand', label: 'Brand' },
        { key: 'Organization', label: 'Organization' },
        { key: 'System', label: 'System' },
      ],
    },
    {
      callbacks: {
        shouldRender: state => state.owner_type === 'Brand',
      },
      component: 'EntitySelector',
      componentProps: {
        entityKey: 'brands',
        boxProps,
      },
      key: 'owner_id',
    },
    {
      callbacks: {
        shouldRender: state => state.owner_type === 'Organization',
      },
      component: 'EntitySelector',
      componentProps: {
        entityKey: 'organizations',
        boxProps,
      },
      key: 'owner_id',
    },
    {
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: 'All',
        inputComponent: 'select',
        style,
      },
      key: 'entity_type',
      label: 'Filter by Entity Type',
      values: entityTypes.map(entityType => ({ key: entityType.key, label: entityType.label })),
    },
    {
      entityKey: 'limit',
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: '100',
        inputComponent: 'select',
        style,
      },
      key: 'limit',
      label: 'Limit',
      values: [
        { key: 150, label: '150' },
        { key: 200, label: '200' },
      ],
    },
  ]
}

const PageContent = (props) => {
  const { pageFilters } = props

  const customFieldSetsPayload = useCustomFieldSets({ filters: pageFilters })
  const {
    callbacks: { loadMore },
    canLoadMore,
    hasFieldSets,
    filteredCustomFieldSets,
    loading,
  } = customFieldSetsPayload

  const pageContext = useContext(PageContext)
  const {
    callbacks: { resetFilters, showCreateCustomFieldSetModal, updateFilters },
  } = pageContext

  const { isBrandUser } = useCurrentUser()

  useIntercom({ hideWidget: !isBrandUser })

  // Tracks the page load event
  const { callbacks: { dispatchMixpanelEvent } } = useMixpanel()
  useEffect(() => {
    if (isBrandUser) dispatchMixpanelEvent('Custom Fields Load')
  }, [])

  return (
    <>
      <Helmet>
        <title>Custom Field Sets | Engage</title>
      </Helmet>

      <PageHeader
        actionContent={(
          <Button buttonStyle="primaryCreate" onClick={() => showCreateCustomFieldSetModal()} size="medium">
            Create
          </Button>
        )}
        activeTabBarItemKey="custom-field-sets"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        tabBarItems={[
          {
            href: '#/systemManager/customFieldSets',
            icon: faGlobe,
            key: 'custom-field-sets',
            title: 'Custom Field Sets',
          },
        ]}
        title="Custom Field Sets"
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!hasFieldSets && !loading && <CustomFieldSetBlankState />}

            {hasFieldSets && !loading && (
              <DashboardModule title="Custom Field Sets">
                <Box flexDirection="column">
                  <Box flexDirection="column">
                    {filteredCustomFieldSets.map(fieldSet => (
                      <CustomFieldSet key={fieldSet.id} fieldSet={fieldSet} />
                    ))}
                  </Box>

                  <DashboardModule.LoadMoreFooter
                    callbacks={{ loadMore }}
                    canLoadMore={canLoadMore}
                    entityCount={filteredCustomFieldSets.length}
                    loading={loading}
                  />
                </Box>
              </DashboardModule>
            )}
          </Columns.Main>

          <Columns.Sidebar>
            <PageFilterModule
              callbacks={{ resetFilters, updateFilters }}
              filterFields={buildPageFilterFields()}
              pageFilters={pageFilters}
              title="Filters"
            />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

PageContent.propTypes = {
  pageFilters: PropTypes.object.isRequired,
}

export default PageContent
