import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import cloneDeep from 'lodash/cloneDeep'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import { digObject, multiFilteredObjectArraySelector, sortArrayBy } from '@campaignhub/javascript-utils'

import useReduxAction from '@hooks/useReduxAction'

const watchEntityKeys = ['customFieldSets']

const defaultRequestOptions = {
  customField: {
    include_custom_field_set_fields: true,
  },
}

const buildRequestOptions = (entityType, options = {}) => {
  const { filterBy } = options || {}

  const requestOptions = {
    ...defaultRequestOptions.customField,
    entityKey: `CustomFieldSet${entityType}`,
    entity_type: entityType,
  }

  if (filterBy){
    Object.keys(filterBy).forEach((key) => {
      requestOptions[`filter_by[${key}]`] = filterBy[key]
    })
  }

  return requestOptions
}

const filterCustomFields = (customFieldSet, options, entities) => {
  const { hidden, requiredOnly, tag } = options

  if (hidden) return []

  const filterParams = [
    { key: 'owner_id', value: customFieldSet.id },
    { key: 'owner_type', value: 'CustomFieldSet' },
  ]

  if (requiredOnly){
    filterParams.push({ key: 'required', value: true })
  }

  if (tag){
    filterParams.push({ key: 'options.tags', value: tag, options: { arrayFilterField: true } })
  }

  const customFields = multiFilteredObjectArraySelector({ entities }, 'customFields', filterParams)

  return sortArrayBy(customFields, 'asc', field => field.sort || field.label)
}

const groupCustomFieldsByTag = (customFields) => {
  const groups = {}

  customFields.forEach((customField) => {
    // Adding a default value 'Custom Fields' in case field is not tagged
    let tags = digObject(customField, 'options.tags', [])
    const jsonField = ['json_object', 'json_object_array'].includes(customField.field_type)

    if (!tags.length && !jsonField) tags.push('Custom Fields')
    if (jsonField) tags = [customField.label]

    tags.forEach((tag) => {
      // Create Group
      if (!groups[tag]){
        groups[tag] = {
          customFieldSetId: customField.owner_id,
          id: tag,
          items: [],
          fieldType: jsonField ? customField.field_type : null,
          label: tag,
          tag,
        }
      }

      // Add Field
      groups[tag].items.push(customField)
    })
  })

  return groups
}

// Removes number from label e.g. '001 - Basic Features' => 'Basic Features'
// if no number, just return the label e.g. 'Basic Features' => 'Basic Features'
export const generateCustomFieldLabels = (customFields) => {
  const array = Object.values(customFields).map((customField) => {
    const { label } = customField
    const [sort, strippedLabel] = label.split(' - ')

    return {
      ...cloneDeep(customField),
      label: strippedLabel || label,
      sort,
    }
  })

  return array
}

const sortCustomFieldGroups = (customFieldGroups) => {
  const array = Object.values(customFieldGroups)
  return sortArrayBy(array, 'asc', 'sort')
}

function useEntityTypeCustomFields(entityType, organization = {}, options = {}, deps = []){
  const {
    filterBy = {}, excludeJson, hidden, key: entity_key, performHttpRequests,
  } = options || {}

  const {
    updatedEntities: { customFieldSets: customFieldSetsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const entityKey = deps.length ? `CustomFieldSet${entityType}-${deps.join('-')}` : `CustomFieldSet${entityType}`

  // Load for entityType
  useReduxAction(
    'customFieldSets',
    'loadCustomFieldSets',
    buildRequestOptions(entityType, options),
    [performHttpRequests, entityType, ...deps],
    {
      dispatchAction: (action, requestOptions) => {
        const mergedOptions = {
          ...requestOptions,
          entityKey,
        }
        return action(mergedOptions)
      },
      shouldPerformFn: (entityReducer) => {
        const { errors, loadedForKeys, loading } = entityReducer

        const missingDepValues = deps ? deps.filter(i => i === undefined || i === null) : []

        return (
          performHttpRequests
          && !hidden
          && !missingDepValues.length
          && entityType
          && !loadedForKeys.includes(entityKey)
          && !loading
          && !errors.length
        )
      },
    },
  )

  const entities = useSelector(reduxState => reduxState.entities)

  const { loading } = useSelector(reduxState => reduxState.customFieldSets)

  // Find Custom Field Set
  const customFieldSet = useMemo(() => {
    const commonFilters = [{ key: 'entity_type', value: entityType }]

    if (entity_key){
      commonFilters.push({ key: 'key', value: entity_key })
    }

    const customFilters = Object.entries(filterBy).map(([key, value]) => {
      if (value !== null && value !== undefined) return { key: `options.filters.${key}`, value }
    })

    // Organization
    const organizationCustomFieldSets = multiFilteredObjectArraySelector({ entities }, 'customFieldSets', [
      ...commonFilters,
      ...customFilters,
      { key: 'owner_id', value: organization.id },
      { key: 'owner_type', value: organization.type },
    ])

    if (organizationCustomFieldSets.length) return organizationCustomFieldSets[0]

    // Brand
    const brandCustomFieldSets = multiFilteredObjectArraySelector({ entities }, 'customFieldSets', [
      ...commonFilters,
      ...customFilters,
      { key: 'owner_id', value: organization.brand_id },
      { key: 'owner_type', value: 'Brand' },
    ])

    if (brandCustomFieldSets.length) return brandCustomFieldSets[0]

    // System
    const systemCustomFieldSets = multiFilteredObjectArraySelector({ entities }, 'customFieldSets', [
      ...commonFilters,
      ...customFilters,
      { key: 'owner_type', value: 'System' },
    ])

    return systemCustomFieldSets[0] || {}
  }, [customFieldSetsUpdatedAt, entityType, organization.id, organization.type])

  // Filter Fields
  const filteredCustomFields = filterCustomFields(customFieldSet, options, entities)

  const nonJsonFields = filteredCustomFields.filter(
    field => !['json_object', 'json_object_array'].includes(field.field_type),
  )

  const filteredFields = excludeJson ? nonJsonFields : filteredCustomFields

  // Grouped Fields
  const groupedCustomFields = groupCustomFieldsByTag(filteredFields)
  const labelledCustomFields = generateCustomFieldLabels(groupedCustomFields)
  const sortedCustomFieldGroups = sortCustomFieldGroups(labelledCustomFields)

  // Required Fields
  const requiredFields = filteredCustomFields.filter(field => field.required).map(field => ({ key: field.key }))

  return {
    customFieldSet,
    filteredCustomFields,
    groupedCustomFields,
    sortedCustomFieldGroups,
    requiredFields,
    loading,
  }
}

export default useEntityTypeCustomFields
