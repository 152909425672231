import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import { Box, Link, ListItem } from '@campaignhub/suit-theme'

interface SelectedEntityProps {
  callbacks: {
    deselectEntity: () => void,
  },
  selectedEntityTitle: string,
}

const SelectedEntity = (props: SelectedEntityProps) => {
  const { callbacks: { deselectEntity }, selectedEntityTitle } = props

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        border: '1px solid',
        borderColor: 'lineColor',
        borderRadius: 5,
        color: 'darkGrey',
        height: 38,
        marginBottom: 'medium',
        padding: 'medium',
      }}
      disableHover
      forceBottomBorder
      key={selectedEntityTitle}
    >
      <Box
        color="darkGrey"
        fontSize="small"
      >
        {selectedEntityTitle}
      </Box>

      <Link
        greyLink
        onClick={() => deselectEntity()}
        textProps={{ fontSize: 'small' }}
      >
        <FontAwesomeIcon icon={faTrashAlt} style={{ color: '#404041' }} />
      </Link>
    </ListItem>
  )
}

export default SelectedEntity
