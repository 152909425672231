import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import { Box, Button, FormField } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'
import useReassignRecipientPageItems from './hooks/useReassignRecipientPageItems'

import ReassignRecipientItems from './components/ReassignRecipientItems'

import localizedStrings from './localizedStrings'

const DocumentTemplateRecipient = (props) => {
  const {
    callbacks: { deleteAndReassignPageItems },
    documentRecipient,
    documentRecipientFormPayload,
    documentRecipientPayload: {
      filteredUserTypes,
    },
  } = props

  const {
    entityState: {
      role_key,
      user_type_id,
    },
    handlers,
  } = documentRecipientFormPayload

  const reassignRecipientItemsPayload = useReassignRecipientPageItems(documentRecipient)
  const {
    callbacks: { setState },
    hasRemainingRecipients,
    showReassignRecipientItems,
  } = reassignRecipientItemsPayload

  const newRecord = !documentRecipient.id

  const { strings } = useLocalization(localizedStrings)

  return (
    <Box flexDirection="column" flexShrink={0}>
      <FormField direction="column" label={strings.formFieldLabels?.role || 'Role'}>
        <select name="role_key" value={role_key} {...handlers}>
          <option value="">{strings.selectOptions?.pleaseSelect || 'Please Select...'}</option>
          <option value="organization_owner">{strings.selectOptions?.organizationOwner || 'Organization Owner'}</option>
          <option value="project_contact">{strings.selectOptions?.projectContact || 'Project Contact'}</option>
          <option value="project_lead_user">{strings.selectOptions?.projectLeadUser || 'Project Lead User'}</option>
          <option value="project_support_user">
            {strings.selectOptions?.projectSupportUser || 'Project Support User'}
          </option>
        </select>
      </FormField>

      {!!filteredUserTypes.length && (
        <FormField direction="column" label={strings.formFieldLabels?.userType || 'Type'} marginTop="large">
          <select data-value-type="number" name="user_type_id" value={user_type_id} {...handlers}>
            <option value="">Please Select...</option>
            {filteredUserTypes.map(userType => (
              <option key={userType.id} value={userType.id}>
                {userType.title}
              </option>
            ))}
          </select>
        </FormField>
      )}

      {hasRemainingRecipients && !newRecord && (
        <FormField direction="column" label={strings.formFieldLabels?.moreOptions || 'More Options'} marginTop="large">
          <Button
            buttonStyle="secondaryUtility"
            icon={<FontAwesomeIcon icon={faTrashAlt} />}
            onClick={() => setState({ showReassignRecipientItems: !showReassignRecipientItems })}
            size="medium"
          >
            {showReassignRecipientItems
              ? strings.buttons?.cancel || 'Cancel'
              : strings.buttons?.delete || 'Delete Recipient'}
          </Button>
        </FormField>
      )}

      {showReassignRecipientItems && (
        <ReassignRecipientItems
          callbacks={{
            deleteAndReassignPageItems,
          }}
          documentRecipient={documentRecipient}
          reassignRecipientItemsPayload={reassignRecipientItemsPayload}
        />
      )}
    </Box>
  )
}

DocumentTemplateRecipient.propTypes = {
  callbacks: PropTypes.shape({
    deleteAndReassignPageItems: PropTypes.func.isRequired,
  }).isRequired,
  documentRecipient: PropTypes.object.isRequired,
  documentRecipientFormPayload: PropTypes.object.isRequired,
  documentRecipientPayload: PropTypes.shape({
    filteredUserTypes: PropTypes.array.isRequired,
  }).isRequired,
}

export default DocumentTemplateRecipient
