import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { deleteEntity, updateEntities } from '@redux/entities'

import api from '@functions/api'

import type { TeamModel } from '@models/types'
import type { AppDispatch } from '@redux/store'
import type {
  Action, DeleteParams, EntityOptions, Errors, FetchRequestPayload, RootReducerState, UpdateParams,
} from '@redux/modules/types'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/team/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/team/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/team/FETCH_FAILURE'

const CREATE_REQUEST = 'realbase/team/CREATE_REQUEST'
const CREATE_SUCCESS = 'realbase/team/CREATE_SUCCESS'
const CREATE_FAILURE = 'realbase/team/CREATE_FAILURE'

const UPDATE_REQUEST = 'realbase/team/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realbase/team/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realbase/team/UPDATE_FAILURE'

const DELETE_REQUEST = 'realbase/team/DELETE_REQUEST'
const DELETE_SUCCESS = 'realbase/team/DELETE_SUCCESS'
const DELETE_FAILURE = 'realbase/team/DELETE_FAILURE'

export type TeamModuleState = {
  creating: boolean,
  deleting: boolean,
  errors: string[],
  loaded: boolean,
  loadedForKeys: string[],
  loading: boolean,
  updating: boolean,
}

// Initial State
const initialState: TeamModuleState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loadedForKeys: [],
  loading: false,
  updating: false,
}

// Actions
export function fetchRequest(payload: FetchRequestPayload = {}) {
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
  }
}

export function fetchSuccess() {
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors: Errors = []) {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest() {
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess() {
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors: Errors = []) {
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest() {
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess() {
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors: Errors = []) {
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest() {
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess() {
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors: Errors = []) {
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Action Creators
export function loadTeams(options: EntityOptions = {}) {
  const { entityKey } = options

  return (dispatch: AppDispatch, getState: () => RootReducerState) => {
    const loadedForKeys = [...getState().teams.loadedForKeys]

    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('/teams.json', options)
      .then(({ data }: { data: { entities: TeamModel[] } }) => {
        const normalizedJson = normalize(data.entities, Schemas.TEAM_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function createTeam(team: Partial<TeamModel>, options: EntityOptions = {}) {
  const config = {
    method: 'POST',
    body: JSON.stringify({
      team,
    }),
  }

  return (dispatch: AppDispatch) => {
    dispatch(createRequest())

    const promise = api('/teams.json', options, config)
      .then(({ data }: { data: { entity: TeamModel } }) => {
        const normalizedJson = normalize(data.entity, Schemas.TEAM)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function updateTeam(
  team: UpdateParams<TeamModel>,
  options: EntityOptions = {},
) {
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      team,
    }),
  }

  return (dispatch: AppDispatch) => {
    dispatch(updateRequest())

    const promise = api(`/teams/${team.id}.json`, options, config)
      .then(({ data }: { data: { entity: TeamModel } }) => {
        const normalizedJson = normalize(data.entity, Schemas.TEAM)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function deleteTeam(team: DeleteParams<TeamModel>) {
  const config = {
    method: 'DELETE',
  }

  return (dispatch: AppDispatch) => {
    dispatch(deleteRequest())

    const promise = api(`/teams/${team.id}.json`, {}, config)
      .then(({ data }: { data: { entity: TeamModel } }) => {
        const normalizedJson = normalize(data.entity, Schemas.TEAM)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(deleteFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(
  state: TeamModuleState = initialState,
  action: Action = { type: '' },
): TeamModuleState {
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors || [],
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors || [],
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors || [],
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors || [],
      }
    default:
      return state
  }
}
