import cloneDeep from 'lodash/cloneDeep'

import { digObject, toggleArray } from '@campaignhub/javascript-utils'

const togglePageItemGalleryImage = (galleries, pageItemId, pageItemGallery, image, updateGalleries) => {
  const images = pageItemGallery.images || []
  const updatedImages = toggleArray(images, { id: image.id }, { deepCompare: true })

  const updatedPageItemGallery = {
    ...cloneDeep(pageItemGallery),
    images: updatedImages,
  }

  const updatedGalleries = {
    ...cloneDeep(galleries),
    [pageItemId]: updatedPageItemGallery,
  }

  updateGalleries(updatedGalleries)
}

function useDigitalTemplateGallery(options = {}){
  const { callbacks: { updateGalleries }, galleries, pageItemId } = options || {}

  const pageItemGallery = digObject(galleries, `${pageItemId}`, {})

  const selectedImages = pageItemGallery.images || []
  const selectedImageIds = selectedImages.map(image => image.id)

  return {
    callbacks: {
      togglePageItemGalleryImage: image => togglePageItemGalleryImage(galleries, pageItemId, pageItemGallery, image, updateGalleries),
    },
    pageItemGallery,
    selectedImageIds,
  }
}

export default useDigitalTemplateGallery
