import { useState } from 'react'
import { useSelector } from 'react-redux'
import isMobileBrowser from 'is-mobile'

import type { ImageModel } from '@models/image'

export type UseCarouselType = {
  carouselImages: ImageModel[],
}

export type ImagesType = Record<string, Partial<ImageModel>>

const useCarousel = (options: UseCarouselType) => {
  const { carouselImages = [] } = options || {}
  const { images }: { images: ImagesType } = useSelector(reduxState => reduxState.entities)
  const [currentSlide, setCurrentSlide] = useState(0)
  const totalSlides = carouselImages.length

  const showCarouselElements = totalSlides > 1

  return {
    callbacks: {
      goToNextSlide: () => setCurrentSlide(prevSlide => prevSlide + 1),
      goToPreviousSlide: () => setCurrentSlide(prevSlide => prevSlide - 1),
      goToSlide: (index: number) => setCurrentSlide(index),
    },
    currentSlide,
    isMobile: isMobileBrowser(),
    images,
    showCarouselElements,
    totalSlides,
  }
}

export default useCarousel
