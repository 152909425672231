import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faLink } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Image, ListItem, Text,
} from '@campaignhub/suit-theme'
import { digObject } from '@campaignhub/javascript-utils'

import useCaseStudy from '@hooks/useCaseStudy'
import useLocalization from '@hooks/useLocalization'

import { formatListToString } from '@functions/string'

import localizedStrings from './localizedStrings'

const CaseStudy = (props) => {
  const {
    caseStudy,
    caseStudy: { description, id },
  } = props

  const {
    address: { full_address },
    callbacks: { createOrEditCaseStudy },
    caseStudyUsers,
    defaultImage,
    hasProject,
  } = useCaseStudy(caseStudy)

  const {
    callbacks: { formatString },
    strings,
  } = useLocalization(localizedStrings)

  const defaultImageUrl = digObject(defaultImage, 'sizes.thumb_200x120', '')

  return (
    <ListItem boxProps={{ borderBottom: '1px dashed', padding: 'large' }} onClick={createOrEditCaseStudy} showAngleIcon>
      <Box
        alignItems="center"
        backgroundColor="whiteGrey"
        border="1px solid"
        borderColor="hoverGrey"
        borderRadius={5}
        height={60}
        justifyContent="center"
        marginRight="large"
        width={100}
      >
        {!defaultImageUrl && <FontAwesomeIcon icon={faHome} />}

        {defaultImageUrl && <Image borderRadius={5} height={60} marginRight="medium" url={defaultImageUrl} />}
      </Box>

      <Box flexDirection="column" justifyContent="center" minWidth="0">
        <Text fontSize="small">{full_address || formatString(strings.caseStudyTitle, { id }) || `Case Study #${id}`}</Text>

        <Text color="bodyFontColor" fontSize="xsmall" paddingTop="medium">
          {formatListToString(caseStudyUsers, 'full_name', strings.othersText)}
        </Text>

        <Text color="lightGrey" fontSize="xsmall" paddingTop="small" variant="ellipsis">
          {hasProject && (
            <Box>
              <FontAwesomeIcon icon={faLink} style={{ marginRight: 5 }} />
              {strings.linkedToProject || 'Linked To Project'}
            </Box>
          )}

          {!hasProject && description}
        </Text>
      </Box>
    </ListItem>
  )
}

CaseStudy.propTypes = {
  caseStudy: PropTypes.object.isRequired,
}

export default CaseStudy
