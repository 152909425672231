import { useSelector } from 'react-redux'
import isMobileBrowser from 'is-mobile'

import useEventCalendar from '@hooks/useEventCalendar'
import useReduxAction from '@hooks/useReduxAction'

import defaultRequestOptions from '../defaultRequestOptions'

const isMobile = isMobileBrowser()

function useCalendarBuilder(options = {}){
  const { callbacks, eventCalendarId } = options || {}

  const entities = useSelector(reduxState => reduxState.entities)
  const { eventCalendars } = entities

  const eventCalendar = eventCalendars[eventCalendarId] || {}

  const eventCalendarPayload = useEventCalendar(eventCalendar)

  // Load Event Calendar
  useReduxAction(
    'eventCalendars',
    'loadEventCalendar',
    {
      ...defaultRequestOptions.eventCalendar,
    },
    [eventCalendarId],
    {
      dispatchAction: (action, requestOptions) => action(eventCalendarId, requestOptions),
      shouldPerformFn: (entityReducer) => {
        const { loadedIds, loading } = entityReducer
        return eventCalendarId && !loadedIds.includes(eventCalendarId) && !loading
      },
    },
  )

  return {
    callbacks: {
      ...callbacks,
    },
    eventCalendarPayload,
    isMobile,
  }
}

export default useCalendarBuilder
