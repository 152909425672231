export type QuoteModel = {
  cache_key: number,
  id: number,
  data: {},
  dates: {
    created: {
      date_time_with_timezone: string,
    },
    updated: {
      date_time_with_timezone: string,
    },
  },
  owner_id: number,
  owner_type: string,
  saved_template?: boolean,
  subject_id?: number,
  subject_type?: string,
  title: string,
  total: number,
}

export type QuoteRequestOptions = {
  include_quote_items?: boolean,
  include_quote_sections?: boolean,
}

const state = {
  id: null,
  data: {},
  owner_id: null,
  owner_type: 'Organization',
  saved_template: false,
  subject_id: null,
  subject_type: null,
  title: '',
  total: 0,
}

export const requiredFields = [
  { key: 'owner_id' },
  { key: 'owner_type' },
  { key: 'title' },
]

export default state
