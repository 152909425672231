import { useContext } from 'react'

import { digObject } from '@campaignhub/javascript-utils'

import DigitalTemplateContext from '@contexts/digitalTemplateContext'
import PageContext from '@contexts/pageContext'

import findComponentStyle from '@functions/findComponentStyle'

export const getComponentStyleLimit = (componentStyle, targetDevice, options = {}) => {
  const fallbackLimit = options?.fallbackLimit || 100

  if (!componentStyle?.default) return fallbackLimit

  const targetDeviceLimit = digObject(componentStyle, `${targetDevice}.limit`)
  const defaultLimit = digObject(componentStyle, 'default.limit')

  return targetDeviceLimit || defaultLimit || fallbackLimit
}

// Remove keys with empty values
const cleanStyle = (style) => {
  if (!style) return {}

  const cleanedStyle = Object.keys(style).reduce((acc, key) => {
    const value = style[key]
    if (value !== undefined && value !== null && value !== ''){
      acc[key] = value
    }

    return acc
  }, {})

  return cleanedStyle
}

const mergeStyle = (baseStyle, deviceStyle, options = {}) => {
  const { prependStyle } = options || {}

  const mergedStyle = {
    ...prependStyle,
    ...baseStyle,
    ...deviceStyle,
  }

  delete mergedStyle.key

  return mergedStyle
}

function useDeviceStyle(params){
  const {
    componentStyleId, style = {}, mobileStyle = {}, tabletStyle = {}, printStyle = {},
  } = params

  const digitalTemplateContext = useContext(DigitalTemplateContext)
  const { componentStyles } = digitalTemplateContext

  const componentStyle = findComponentStyle(componentStyles, componentStyleId)

  const pageContext = useContext(PageContext)
  const { targetDevice } = pageContext

  const isMobile = targetDevice === 'mobile'
  const isTablet = targetDevice === 'tablet'
  const isPrint = targetDevice === 'print'

  const baseStyle = { ...cleanStyle(componentStyle.default), ...cleanStyle(componentStyle[targetDevice]), ...cleanStyle(style) }
  delete baseStyle.key // remove the key as it is not a valid style property

  // Mobile
  if (isMobile){
    return mergeStyle(baseStyle, cleanStyle(mobileStyle))
  }

  // Tablet
  if (isTablet){
    return mergeStyle(baseStyle, cleanStyle(tabletStyle))
  }

  // Print
  if (isPrint){
    return mergeStyle(baseStyle, cleanStyle(printStyle), { prependStyle: { overflow: 'hidden' } })
  }

  return baseStyle
}

export default useDeviceStyle
