import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { digObject } from '@campaignhub/javascript-utils'
import { Box, ModalContext, SidebarModal, SidebarNoticeBox } from '@campaignhub/suit-theme'

import useDigitalEditorSelectedEntity from '@hooks/useDigitalEditorSelectedEntity'
import useLocalization from '@hooks/useLocalization'

import Data from './components/Data'
import ItemInfo from './components/ItemInfo'
import Options from './components/Options'
import Tools from './components/Tools'
import localizedStrings from './localizedStrings'

const CustomizeDigitalTemplatePageItemModal = (props) => {
  const { callbacks, modalKey, showModal } = props

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const { callbacks: modalCallbacks, dataContext, selectedCanvasItem } = digObject(modalData, modalKey, {})
  const { deleteEntity: deleteFn, updateEntityParam: updateParamFn } = modalCallbacks || {}

  const { id, type } = selectedCanvasItem || {}

  const { selectedEntity } = useDigitalEditorSelectedEntity('selectedCanvasItem')
  const { item_type } = selectedEntity

  const canEditItem = item_type === 'section'
                    ? digObject(selectedEntity, 'options.toggleable', true)
                    : digObject(selectedEntity, 'options.editable', false)

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal
      callbacks={callbacks}
      disableAnimation
      disableOverlay
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.title || 'Edit Item'}
        titleSecondLine={strings.titleSecondLine || 'Details'}
      />

      <SidebarModal.Content boxProps={{ padding: 0 }} hasFooter={false}>
        <Box flexDirection="column">
          {canEditItem && (
            <>
              <Data
                callbacks={{ updateParam: (key, value) => updateParamFn(selectedEntity, key, value) }}
                dataContext={dataContext}
                entityType={type}
                selectedEntity={selectedEntity}
              />

              <Options
                callbacks={{ updateParam: (key, value) => updateParamFn(selectedEntity, key, value) }}
                dataContext={dataContext}
                selectedEntity={selectedEntity}
              />

              <Tools callbacks={{ deleteEntity: () => deleteFn(selectedEntity) }} />
            </>
          )}

          {!canEditItem && (
            <Box borderBottom="1px solid" borderColor="lineColor" padding="large">
              <SidebarNoticeBox boxProps={{ marginBottom: 0 }}>
                <SidebarNoticeBox.Paragraph boxProps={{ marginTop: 0 }}>
                  {strings.nonEditable || 'Selected item is not editable.'}
                </SidebarNoticeBox.Paragraph>
              </SidebarNoticeBox>
            </Box>
          )}

          <ItemInfo id={id} type={type} />
        </Box>
      </SidebarModal.Content>
    </SidebarModal>
  )
}

CustomizeDigitalTemplatePageItemModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

CustomizeDigitalTemplatePageItemModal.defaultProps = {
  modalKey: 'CustomizeDigitalTemplatePageItemModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <CustomizeDigitalTemplatePageItemModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
