import React from 'react'

import { Link, SidebarNoticeBox } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const CoreLogicAustralia = () => {
  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarNoticeBox>
      <SidebarNoticeBox.Title>{strings.title || 'End User Terms'}</SidebarNoticeBox.Title>

      <SidebarNoticeBox.Paragraph>
        {strings.paragraph || "By using Product Data, you confirm that you have read and accepted CoreLogic's End User Terms for the access and use of the Product Data."}
      </SidebarNoticeBox.Paragraph>

      <Link
        href="http://www.corelogic.com.au/endusertermsconditions"
        textProps={{ fontSize: 'xsmall', marginY: 'medium' }}
        target="_blank"
      >
        http://www.corelogic.com.au/endusertermsconditions
      </Link>
    </SidebarNoticeBox>
  )
}

CoreLogicAustralia.propTypes = {}

export default CoreLogicAustralia
