export default {
  default: {
    formFieldOptions: {
      admin: 'Admin',
      owner: 'Owner',
      user: 'User',
      true: 'True',
      false: 'Flase',
    },
    toast: {
      setDefault: 'Organization Set as Default',
      updateOrganizationUser: 'User Organization Settings Updated',
    },
    noticeBox: {
      description: 'You are editing',
      roleChange: "Changing role will alter this user's permission",
    },
    adminUser: 'Admin',
    approved: 'Approved',
    default: 'Default',
    removeFromOrganization: 'Remove from {global.organizationType.shortName.singular}',
    moreOptions: 'More Options',
    role: 'Role',
    saveChanges: 'Save Changes',
    setDefault: 'Set as Default',
    title: 'Update',
    titleSecondLine: '{global.organizationType.shortName.singular} Details',
    userRole: '{global.organizationType.shortName.singular} User',
  },
}
