import { DateTime } from 'luxon'

// eslint-disable-next-line import/prefer-default-export
export const validateStartAndEndDate = (start_date: string, end_date: string, operator: string) => {
  const endDate = DateTime.fromISO(end_date).toISODate() || ''
  const startDate = DateTime.fromISO(start_date).toISODate() || ''

  if (operator === '>') return startDate > endDate
  if (operator === '>=') return startDate >= endDate
  if (operator === '<') return startDate < endDate
  if (operator === '<=') return startDate <= endDate

  return false
}
