export type DigitalTemplateModel = {
  brand_id: number,
  digital_template_type_id: number,
  global: boolean,
  hidden: boolean,
  id: number,
  options?: any,
  origin_template_id?: number | null,
  preview_link: string,
  production_ready: boolean,
  sidebar_template_id?: number | null,
  title: string,
}

const state = {
  brand_id: null,
  digital_template_type_id: '',
  global: false,
  hidden: false,
  id: null,
  options: {
    component_styles: {
      dependencies: {
        fonts: [],
      },
      styles: [],
    },
    preview_link: '',
    print_footer: {
      height: '',
      source: '',
      start_at: '',
    },
    print_format: '',
    print_margin: '',
    print_orientation: '',
  },
  origin_template_id: null,
  preview_link: '',
  production_ready: false,
  sidebar_template_id: '',
  title: '',
}

export const requiredFields = [
  { key: 'brand_id' },
  { key: 'title' },
]

export default state
