import { faHistory } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Columns, DashboardModule, LoadingModule, PageHeader,
} from '@campaignhub/suit-theme'

import useActivities from '@hooks/useActivities'
import useLocalization from '@hooks/useLocalization'
import useOrganizationSelector from '@hooks/useOrganizationSelector'

import Activity from '@components/Activity'

import ActivityBlankState from './components/ActivityBlankState'
import localizedStrings from './localizedStrings'

const Activities = () => {
  const { selectedOrganization } = useOrganizationSelector()

  const useActivitiesPayload = useActivities({
    filters: {
      owner_id: selectedOrganization.id,
      owner_typ: 'Organization',
    },
    performHttpRequests: true,
    requestOptions: {
      limit: 50,
    },
  })

  const {
    filteredActivities,
    hasActivities,
    loading,
  } = useActivitiesPayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      <PageHeader
        activeTabBarItemKey="activities"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        nestedNavigation
        tabBarItems={[
          {
            icon: faHistory,
            key: 'activities',
            title: strings.title || 'Activity',
          },
        ]}
        title={strings.title || 'Activity'}
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && !hasActivities && (
              <ActivityBlankState />
            )}

            {!loading && hasActivities && (
              <DashboardModule title={strings.title || 'Activity'}>
                <Box flexDirection="column" overflow="auto">
                  {filteredActivities.map(activity => (
                    <Activity key={activity.id} activity={activity} organizationId={selectedOrganization.id} />
                  ))}
                </Box>
              </DashboardModule>
            )}
          </Columns.Main>
        </Columns>
      </Box>
    </>
  )
}

export default Activities
