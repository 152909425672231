import { useContext, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/pro-light-svg-icons'

import { Box, Button } from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import DigitalTemplateContext from '@contexts/digitalTemplateContext'
import PageContext from '@contexts/pageContext'

import findComponentStyle from '@functions/findComponentStyle'
import { findItemByType } from '@functions/digitalTemplate'
import { getShortCodeValue } from '@functions/replaceTextShortCodes'
import { shouldRenderPageItem } from '@functions/digitalTemplatePageItem'

import useDeviceStyle, { getComponentStyleLimit } from '@hooks/useDeviceStyle'

import { modalKeys } from '@sections/Client/packs/DigitalRenderer/hooks/useDigitalRenderer'

// eslint-disable-next-line import/no-cycle
import ElementContainer from '../../ElementContainer'

import styles from './styles.module.scss'

const filterData = (data, filters) => {
  const {
    containerRenderConditions, contextData, limit, offset,
  } = filters || {}

  const filterOffset = offset && offset !== 0 ? offset : 0
  const filterLimit = limit ? limit + filterOffset : null

  const filteredDataArray = data.filter(item => shouldRenderPageItem(containerRenderConditions, { ...contextData, ...item }, {}))

  const limited = filterLimit
    ? filteredDataArray.slice(filterOffset, filterLimit)
    : filteredDataArray.slice(filterOffset)

  return limited
}

const renderItems = (params) => {
  const {
    array, contextData, itemContainer, itemEntities, itemStyle,
  } = params

  return array.map((item, index) => {
    const { renderConditions } = itemContainer.options || {}
    const shouldRender = shouldRenderPageItem(renderConditions, { ...contextData, ...item }, {})

    if (!shouldRender) return null

    return (
      // eslint-disable-next-line react/no-array-index-key
      <div key={index} style={itemStyle}>
        <ElementContainer
          forwardContextToItems={item}
          itemEntities={itemEntities}
          section={itemContainer}
          renderNested
          render={({ itemComponents }) => itemComponents.map(component => component)}
        />
      </div>
    )
  })
}

const DataLoop = (props) => {
  const {
    callbacks: componentCallbacks,
    componentStyleId,
    context: componentContext,
    data: { value: rawValue },
    itemEntities,
    items,
    options,
    overrideDataValue,
  } = props

  const { setChildrenCount, render } = componentCallbacks || {}

  const {
    dataContext, limit: defaultLimit, offset, renderConditions,
  } = options || {}

  // Containers
  const itemContainer = findItemByType('container', items, itemEntities) || {}

  // Context
  const digitalTemplateContext = useContext(DigitalTemplateContext)
  const { componentStyles } = digitalTemplateContext

  const pageContext = useContext(PageContext)
  const {
    callbacks, editing, enabledFeatures, shortcodeData, targetDevice,
  } = pageContext || {}

  const { manageDigitalPageFeature } = callbacks || {}

  // Component Style
  const componentStyle = findComponentStyle(componentStyles, componentStyleId)
  const limit = getComponentStyleLimit(componentStyle, targetDevice, { fallbackLimit: defaultLimit })

  const contextData = { ...shortcodeData, ...componentContext }

  // Style
  const parentStyle = useDeviceStyle(props)
  const itemStyle = useDeviceStyle(itemContainer)

  const shortcodeValue = overrideDataValue || rawValue
  const array = getShortCodeValue(shortcodeValue, contextData, { returnRawValue: true }) || []

  const containerRenderConditions = digObject(itemContainer, 'options.renderConditions', [])

  // Check if the array is actually an array (or object is js)
  const filteredArray = array && Array.isArray(array)
    ? filterData(array, {
      containerRenderConditions, contextData, limit, offset,
    })
    : []

  useEffect(() => {
    if (filteredArray.length && setChildrenCount){
      setChildrenCount(filteredArray.length)
    }
  }, [filteredArray.length])

  // Check if we need to disable Edit functionality when the corresponding feature is turned off
  const modalDataContext = modalKeys[dataContext]?.dataContext || ''
  const enabledFeatureKey = !!modalDataContext && enabledFeatures.some(feature => feature.key === modalDataContext)

  const showEditFunctionality = editing && enabledFeatureKey

  // Custom Render
  const renderResult = useMemo(() => {
    if (render){
      return render({
        array: filteredArray,
        itemContainer,
        itemEntities,
        itemStyle,
        parentStyle,
      })
    }

    return null
  }, [filteredArray.length])

  // Should Render
  const shouldRender = shouldRenderPageItem(renderConditions, contextData, options)
  if (!shouldRender) return null

  // Custom Render Fn
  if (renderResult){
    return renderResult
  }

  return (
    <Box
      className={showEditFunctionality ? styles.root : null}
      display="block"
      minHeight={showEditFunctionality ? 100 : null}
      position="relative"
    >
      {showEditFunctionality && (
        <Box
          alignItems="center"
          className={styles.editOverlay}
          justifyContent="center"
          onClick={(e) => {
            e.stopPropagation()
            manageDigitalPageFeature(dataContext, options)
          }}
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
        >
          <Button buttonStyle="primaryEdit" icon={<FontAwesomeIcon icon={faPencil} />} size="medium" width="auto">
            Edit
          </Button>
        </Box>
      )}

      <Box style={parentStyle}>
        {renderItems({
          array: filteredArray,
          contextData,
          itemContainer,
          itemEntities,
          itemStyle,
        })}
      </Box>
    </Box>
  )
}

DataLoop.propTypes = {
  callbacks: PropTypes.shape({
    renderItems: PropTypes.func,
  }),
  componentStyleId: PropTypes.string,
  context: PropTypes.object,
  data: PropTypes.object,
  itemEntities: PropTypes.object,
  items: PropTypes.array,
  options: PropTypes.object,
  overrideDataValue: PropTypes.string,
}

DataLoop.defaultProps = {
  data: {},
  options: {},
}

export default DataLoop
