import { useEffect } from 'react'

import { digObject, sortArrayBy } from '@campaignhub/javascript-utils'

import { useThunkDispatch } from '@campaignhub/react-hooks'

import useCurrentUser from '@hooks/useCurrentUser'
import useSelector from '@hooks/useSelector'

import * as brandActions from '@redux/modules/brand'

import { AppDispatch } from '@redux/store'

import { BrandModel } from '@models/brand'

const selectBrandId = (brandId: number, dispatch: AppDispatch) => {
  const { selectBrandId: selectFn } = brandActions

  return dispatch(selectFn(brandId))
}

const autoSelectBrand = (brands: BrandModel[], dispatch: AppDispatch) => {
  // Ensure default exists before selecting
  const defaultBrand: Partial<BrandModel> = {}

  const brandId = defaultBrand?.id || digObject(brands[0], 'id')

  selectBrandId(brandId, dispatch)
}

type UseBrandSelectorOptions = {
  autoSelect?: boolean,
}

function useBrandSelector(options: UseBrandSelectorOptions = {}) {
  const { autoSelect } = options
  const dispatch = useThunkDispatch()

  const { brands } = useSelector(reduxState => reduxState.entities)
  const { loading, selectedId } = useSelector(state => state.brands)

  const selectedBrand = brands[selectedId] || {}

  const brandsArray = Object.values(brands)

  const sortedBrands = sortArrayBy(brandsArray, 'asc', 'title')

  const { loaded: currentUserLoaded } = useCurrentUser()

  useEffect(() => {
    if (brandsArray.length === 1){
      selectBrandId(brandsArray[0]?.id, dispatch)
    }
  }, [brandsArray.length])

  useEffect(() => {
    if (autoSelect && currentUserLoaded && !selectedId && sortedBrands.length){
      autoSelectBrand(sortedBrands, dispatch)
    }
  }, [sortedBrands.length, currentUserLoaded, autoSelect])

  return {
    loading,
    selectedBrand,
  }
}

export default useBrandSelector
