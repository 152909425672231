export type QuoteSectionModel = {
  cache_key: number,
  id: number,
  dates: {
    created: {
      date_time_with_timezone: string,
    },
    updated: {
      date_time_with_timezone: string,
    },
  },
  footer: string,
  header: string,
  options: {
    hide_dates: boolean,
    hide_prices: boolean,
    hide_subtotal: boolean,
  },
  quote_id: number,
  sort: number,
  title: string,
}

export type QuoteSectionRequestOptions = {}

const state = {
  footer: '',
  header: '',
  id: null,
  options: {
    hide_dates: false,
    hide_prices: false,
    hide_subtotal: false,
  },
  quote_id: null,
  sort: null,
  title: '',
}

export const requiredFields = [
  { key: 'quote_id' },
  { key: 'title' },
]

export default state
