import { useContext } from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUnlock } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, FormField, ModalContext, SidebarModal, SidebarNoticeBox,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import handleCallbackAction from '@functions/handleCallbackAction'

import useCurrentUser from '@hooks/useCurrentUser'
import useDigitalTemplate from '@hooks/useDigitalTemplate'
import useSessionLock, { useRelations } from '@hooks/useSessionLock'
import { useRelations as userUserRelations } from '@hooks/useUser'

const SessionLockedModal = (props) => {
  const {
    callbacks, callbacks: { closeModal }, modalKey, showModal,
  } = props

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const { sessionLock } = digObject(modalData, modalKey, {})

  const sessionLockRelationsPayload = useRelations(sessionLock)
  const { user } = sessionLockRelationsPayload

  const { defaultImage } = userUserRelations(user)
  const imageUrl = digObject(defaultImage, 'sizes.thumb_90x90', '')

  const digitalTemplatePayload = useDigitalTemplate({ id: sessionLock.subject_id })
  const { callbacks: { unlockSessionLocks }, urls: { systemManagerTemplateList } } = digitalTemplatePayload

  const { currentUser, isMasterAdmin } = useCurrentUser()

  const sessionLockPayload = useSessionLock(sessionLock)
  const {
    callbacks: { createSessionLock },
  } = sessionLockPayload

  const afterActionCallback = (success) => {
    closeModal()
    if (success){
      createSessionLock({
        locked_by_user_id: currentUser.id,
        subject_id: sessionLock.subject_id,
        subject_type: 'DigitalTemplate',
      })
    }
  }
  const unlockSessionPayload = {
    callbacks: {
      action: unlockSessionLocks,
      afterAction: ({ response: { success } }) => afterActionCallback(success),
    },
    entityParams: {},
    toastText: 'Template Unlocked',
  }

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header callbacks={callbacks} title="Template" titleSecondLine="Locked" />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <SidebarNoticeBox boxProps={{ marginBottom: 'medium' }}>
            <SidebarNoticeBox.Title>Template is being edited</SidebarNoticeBox.Title>

            <SidebarNoticeBox.Paragraph>
              This template has an active editing session. If you need to access the template please contact the user
              below, or look for the template open in another tab.
            </SidebarNoticeBox.Paragraph>
          </SidebarNoticeBox>

          <FormField label="Template User" marginTop="large">
            <SidebarModal.User
              boxProps={{ paddingY: 'large' }}
              disableHover
              imageUrl={imageUrl}
              key={user.id}
              title={user.full_name}
            />
          </FormField>

          {isMasterAdmin
            && (
            <FormField direction="column" label="More Options">
              <Box flexDirection="column" flexShrink={0}>
                <Button
                  buttonStyle="secondaryUtility"
                  icon={<FontAwesomeIcon icon={faUnlock} />}
                  onClick={() => handleCallbackAction(unlockSessionPayload)}
                  size="medium"
                  width="100%"
                >
                  Unlock Session
                </Button>
              </Box>
            </FormField>
            )}
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          as="a"
          buttonStyle="secondaryUtility"
          href={systemManagerTemplateList}
          size="large"
        >
          Go Back
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

SessionLockedModal.propTypes = {
  callbacks: PropTypes.object,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

SessionLockedModal.defaultProps = {
  modalKey: 'SessionLockedModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <SessionLockedModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
