/* eslint-disable max-len */
import { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import cloneDeep from 'lodash/cloneDeep'

import {
  useForm, useLatestEntity, useThunkDispatch, useWatchEntityUpdates,
} from '@campaignhub/react-hooks'

import {
  digObject, launchModal, pascalToSnakeCase, modifyGroupedIdsSort,
} from '@campaignhub/javascript-utils'

import generateRedirectUrl from '@functions/generateRedirectUrl'

import useDigitalTemplatePage from '@hooks/useDigitalTemplatePage'
import useMixpanel from '@hooks/useMixpanel'

import PageContext from '@contexts/pageContext'

import defaultState, { requiredFields } from '@models/digitalPage'

import * as digitalPageActions from '@redux/modules/digitalPage'

const watchEntityKeys = ['digitalPages', 'digitalTemplatePageSectionGroups', 'digitalTemplatePages', 'digitalTemplatePageSections']

const generateUrls = (digitalPage) => {
  const { id } = digitalPage

  return {
    editDigitalPageUrl: `#/digitalPages/${id}/edit?redirect=${generateRedirectUrl()}`,
  }
}

const createDigitalPage = (digitalPageParams, dispatch, dispatchMixpanelEvent, requestOptions) => {
  const { createDigitalPage: createFn } = digitalPageActions
  const { options, data: pageData } = digitalPageParams || {}

  const updatedParams = {
    ...digitalPageParams,
    options: JSON.stringify(options),
    data: JSON.stringify(pageData),
  }

  return dispatch(createFn(updatedParams, requestOptions)).then((response) => {
    const { success, data } = response
    if (success){
      const {
        entity: { digital_template_id, digital_template_type_key, id },
      } = data

      dispatchMixpanelEvent('Digital Page Created', {
        digital_template_id,
        digital_template_type: digital_template_type_key,
      })

      return {
        success,
        data,
        redirectUrl: `#/digitalPages/${id}/edit?redirect=${generateRedirectUrl()}`,
      }
    }

    return response
  })
}

const resetDefaultImage = (digitalPage, dispatch) => {
  const { updateDigitalPage: updateFn } = digitalPageActions
  const { id, data: currentData } = digitalPage

  const filterImageData = Object.keys(currentData)?.filter(data => !!currentData[data]?.imageId)

  const updatedFiltereData = filterImageData?.reduce((obj, key) => {
    obj[key] = { imageId: null }
    return obj
  }, {})

  const updatadData = {
    ...currentData,
    ...updatedFiltereData,
  }

  const updated = {
    id,
    data: JSON.stringify(updatadData),
  }

  return dispatch(updateFn(updated))
}

const resetDefaultText = (digitalPage, dispatch) => {
  const { updateDigitalPage: updateFn } = digitalPageActions
  const { id, data: currentData } = digitalPage

  const filterTextData = Object.keys(currentData).filter(data => !!currentData[data]?.value)

  const updatedFiltereData = filterTextData.reduce((obj, key) => {
    obj[key] = { value: '' }
    return obj
  }, {})

  const updatedData = {
    ...currentData,
    ...updatedFiltereData,
  }

  const updated = {
    id,
    data: JSON.stringify(updatedData),
  }

  return dispatch(updateFn(updated))
}

const resetDisabledSections = (digitalPage, name, dispatch) => {
  const { updateDigitalPage: updateFn } = digitalPageActions
  const { id, options: currentOptions } = digitalPage

  const updatedSections = {
    ...currentOptions,
    [name]: null,
  }

  const updated = {
    id,
    options: JSON.stringify(updatedSections),
  }

  return dispatch(updateFn(updated))
}

const resetSortedSections = (digitalPage, name, dispatch) => {
  const { updateDigitalPage: updateFn } = digitalPageActions
  const { id, options: currentOptions } = digitalPage

  const updatedSections = {
    ...currentOptions,
    [name]: null,
  }

  const updated = {
    id,
    options: JSON.stringify(updatedSections),
  }

  return dispatch(updateFn(updated))
}

const hydrateDigitalPageShortcodeData = (shortcodeData, dispatch, requestOptions) => {
  const { hydrateDigitalPageShortcodeData: hydrateFn } = digitalPageActions
  return dispatch(hydrateFn(shortcodeData, requestOptions))
}

const loadDigitalPageShortcodeData = (digitalPage, dispatch, requestOptions) => {
  const { loadDigitalPageShortcodeData: loadFn } = digitalPageActions
  return dispatch(loadFn(digitalPage, requestOptions))
}

const updateShortcodeDataSubject = (params) => {
  const {
    dispatch, key, shortcodeData, subjectType, value,
  } = params

  const subjectKey = pascalToSnakeCase(subjectType)
  const shortcodeDataSubject = digObject(shortcodeData, subjectKey, {})

  const updatedSubject = {
    ...shortcodeDataSubject,
    [key]: value,
  }

  const updatedShortcodeData = {
    ...cloneDeep(shortcodeData),
    [subjectKey]: updatedSubject,
  }

  return hydrateDigitalPageShortcodeData(updatedShortcodeData, dispatch)
}

const updateDigitalPage = (digitalPage, digitalPageParams, dispatch, requestOptions) => {
  const { updateDigitalPage: updateFn } = digitalPageActions
  const { data, options } = digitalPageParams

  const updatedParams = {
    ...digitalPageParams,
    id: digitalPage.id,
  }

  // Prevent overwriting page options accidentally
  // in case all options are not passed back in params object
  if (options){
    const pageOptions = digitalPage.options || {}
    updatedParams.options = JSON.stringify({
      ...cloneDeep(pageOptions),
      ...options,
    })
  }

  if (data){
    const pageData = digitalPage.data || {}
    updatedParams.data = JSON.stringify({
      ...cloneDeep(pageData),
      ...data,
    })
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

const acceptDigitalPage = (digitalPage, shareLinkToken, dispatch, requestOptions, dispatchMixpanelEvent) => {
  const { acceptDigitalPage: acceptFn } = digitalPageActions

  return dispatch(acceptFn(digitalPage, shareLinkToken, requestOptions)).then((response) => {
    const { success, data } = response

    if (success){
      const { entity: { id } } = data

      if (dispatchMixpanelEvent){
        dispatchMixpanelEvent('Proposal Acceptance', { Proposal_id: id })
      }

      return {
        success,
        data,
      }
    }

    return null
  })
}

const updatePageItemCustomData = (digitalPage, pageItemId, changes, dispatch, requestOptions) => {
  const data = digitalPage.data || {}

  if (pageItemId && Object.keys(changes).length){
    const updatedData = cloneDeep(data)
    updatedData[pageItemId] = {
      ...updatedData[pageItemId],
      ...changes,
    }

    const updatedDigitalPage = {
      data: updatedData,
    }

    return updateDigitalPage(digitalPage, updatedDigitalPage, dispatch, requestOptions)
  }

  return null
}

const deleteDigitalPage = (digitalPage, dispatch) => {
  const { deleteDigitalPage: deleteFn } = digitalPageActions
  return dispatch(deleteFn(digitalPage))
}

const selectDigitalTemplateId = (digitalTemplateId, digitalPage, dispatch, dispatchMixpanelEvent, requestOptions) => {
  const updatedDigitalPage = {
    ...digitalPage,
    digital_template_id: digitalTemplateId,
  }

  if (digitalPage.id){
    return updateDigitalPage(digitalPage, updatedDigitalPage, dispatch, requestOptions)
  }

  return createDigitalPage(updatedDigitalPage, dispatch, dispatchMixpanelEvent, requestOptions)
}

const selectDigitalTemplateModal = (
  digitalTemplateTypeKey,
  digitalPage,
  showSelectDigitalTemplateModal,
  modalOptions,
) => new Promise((resolve, reject) => {
  if (showSelectDigitalTemplateModal){
    const payload = {
      digitalPage,
      digitalTemplateTypeKey,
      modalOptions,
    }

    showSelectDigitalTemplateModal(payload)

    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showSelectDigitalTemplateModal not defined in PageContext callbacks'))
})

const editDigitalPage = (digitalPage, showEditDigitalPageModal, dispatch) => new Promise((resolve, reject) => {
  if (showEditDigitalPageModal){
    const payload = {
      callbacks: {
        updateDigitalPage: (digitalPageParams, requestOptions) => updateDigitalPage(digitalPage, digitalPageParams, dispatch, requestOptions),
      },
      digitalPage,
    }

    showEditDigitalPageModal(payload)

    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showEditDigitalPageModal not defined in PageContext callbacks'))
})

const manageShareLinks = (digitalPage, showManageShareLinksModal) => new Promise((resolve, reject) => {
  if (showManageShareLinksModal){
    const payload = {
      digitalPage,
    }

    showManageShareLinksModal(payload)

    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showManageShareLinksModal not defined in PageContext callbacks'))
})

const duplicateDigitalPage = (digitalPageParms, dispatch, dispatchMixpanelEvent) => {
  const { duplicateDigitalPage: duplicateFn } = digitalPageActions

  const { selectedEntityKeys, sourceDigitalPageId, targetProjectId } = digitalPageParms
  const comparableToggledOn = selectedEntityKeys?.includes('comparables')

  return dispatch(duplicateFn(selectedEntityKeys, sourceDigitalPageId, targetProjectId)).then((response) => {
    const { success, data } = response

    const {
      entity: { digital_template_id, digital_template_type_key, id },
    } = data || {}

    if (success){
      dispatchMixpanelEvent('Digital Page Duplicated', {
        comparables: comparableToggledOn,
        digital_template_id,
        digital_template_type: digital_template_type_key,
        targetProjectId,
      })

      return {
        success,
        data,
        redirectUrl: `#/digitalPages/${id}/edit?redirect=${generateRedirectUrl()}`,
      }
    }

    return response
  })
}

const updateDigitalPageSectionSort = (digitalPage, sortedGroupSections, dispatch) => {
  const { updateDigitalPage: updateFn } = digitalPageActions

  const { id } = digitalPage
  const options = digitalPage.options || {}

  const mappedSortedGroupSections = Object.values(sortedGroupSections).flat()

  const updatedOptions = JSON.stringify({
    ...options,
    sorted_section_keys: [...mappedSortedGroupSections],
  })

  const updated = {
    id,
    options: updatedOptions,
  }

  return dispatch(updateFn(updated))
}

export function useDigitalPageForm(digitalPage = {}){
  const dispatch = useThunkDispatch()

  const digitalPageForm = useForm(defaultState, { entity: digitalPage, requiredFields, validateOn: 'change' }, [
    digitalPage.id,
    digitalPage.cache_key,
  ])

  return {
    ...digitalPageForm,
    callbacks: {
      updateDigitalPage: (updatedParams, requestOptions) => updateDigitalPage(digitalPage, updatedParams, dispatch, requestOptions),
    },
  }
}

export function useRelations(digitalPage = {}){
  const {
    default_proofing_link_id,
    default_tracking_link_id,
    digital_template_id,
    owner_id,
    owner_type,
    status_id,
    subject_id,
    subject_type,
  } = digitalPage

  const entities = useSelector(reduxState => reduxState.entities)
  const {
    digitalPageShortcodeData,
    digitalTemplateTypes,
    digitalTemplates,
    organizations,
    projects,
    shareLinks,
    statuses,
  } = entities

  const defaultProofingLink = shareLinks[default_proofing_link_id] || {}
  const defaultTrackingLink = shareLinks[default_tracking_link_id] || {}
  const digitalTemplate = digitalTemplates[digital_template_id] || {}
  const digitalTemplateType = digitalTemplateTypes[digitalTemplate.digital_template_type_id] || {}
  const organization = owner_type === 'Organization' ? organizations[owner_id] || {} : {}
  const parentDigitalTemplateType = digitalTemplateTypes[digitalTemplateType.parent_id] || {}
  const project = subject_type === 'Project' ? projects[subject_id] || {} : {}
  const shortcodeData = digitalPageShortcodeData[digitalPage.id] || {}
  const status = statuses[status_id] || {}

  return {
    defaultProofingLink,
    defaultTrackingLink,
    digitalTemplate,
    digitalTemplateType,
    organization,
    parentDigitalTemplateType,
    project,
    shortcodeData,
    status,
  }
}

const setupSectionGroupState = (params) => {
  const {
    digitalPage, digitalPageSortedGroupSections, setSortedGroupSections, sortedSectionGroups,
  } = params
  const sortedGroupSections = sortedSectionGroups.reduce((acc, sectionGroup) => {
    const { sections: sectionIds } = sectionGroup

    const sortedSectionArray = digObject(digitalPageSortedGroupSections, `${sectionGroup.id}`, [])

    const sortedSectionKeys = digObject(digitalPage, 'options.sorted_section_keys', [])
    const sortedSectionIds = sortedSectionKeys.map(key => sectionIds.find(id => id === key)).filter(id => id)

    const combinedArray = [...sortedSectionIds, ...sortedSectionArray]
    const sorted = [...new Set(combinedArray)]

    const sortable = digObject(sectionGroup, 'options.sortable', false)
    if (sortable){
      acc[sectionGroup.id] = cloneDeep(sorted)
    }

    return acc
  }, {})

  setSortedGroupSections(sortedGroupSections)
}

const canModifySectionSort = (params) => {
  const {
    fromIndex, payload, setSortedGroupSections, sortedGroupSections, toIndex,
  } = params
  const { destinationDroppableId, sourceDroppableId } = payload

  if (sourceDroppableId !== destinationDroppableId) return

  setSortedGroupSections(modifyGroupedIdsSort(fromIndex, toIndex, payload, sortedGroupSections))
}

function useDigitalPage(initDigitalPage){
  const { entity: digitalPage } = useLatestEntity(initDigitalPage, 'digitalPages')
  const { data } = digitalPage
  const dispatch = useThunkDispatch()

  const {
    updatedEntities: {
      digitalTemplatePageSectionGroups: digitalTemplatePageSectionGroupsUpdatedAt,
      digitalTemplatePages: digitalTemplatePagesUpdatedAt,
      digitalTemplatePageSections: digitalTemplatePageSectionsUpdatedAt,
      digitalPages: digitalPagesUpdatedAt,
    },
  } = useWatchEntityUpdates(watchEntityKeys)

  const [sortedGroupSections, setSortedGroupSections] = useState([])

  const { creating, deleting, updating } = useSelector(reduxState => reduxState.digitalPages)

  const { statuses } = useSelector(reduxState => reduxState.entities)

  const { callbacks: { dispatchMixpanelEvent } } = useMixpanel()

  const pageContext = useContext(PageContext)
  const { callbacks, digitalTemplatePage } = pageContext

  const digitalTempaltePagePayload = useDigitalTemplatePage({ id: digitalTemplatePage?.id })
  const { sortedSectionGroups, sortedGroupSections: digitalPageSortedGroupSections } = digitalTempaltePagePayload

  const { showEditDigitalPageModal, showSelectDigitalTemplateModal, showManageShareLinksModal } = callbacks || {}

  const digitalPageRelations = useRelations(digitalPage)
  const {
    defaultProofingLink,
    defaultTrackingLink,
    digitalTemplate,
    digitalTemplateType,
    parentDigitalTemplateType,
    project,
    shortcodeData,
    status,
  } = digitalPageRelations

  const { enabled: shareLinkEnabled } = defaultTrackingLink

  const permittedStatusKeys = ['pending', 'sent', 'accepted', 'rejected']
  const filteredStatuses = Object.values(statuses).filter(s => permittedStatusKeys.includes(s.key))

  const featureCompletionStatuses = digObject(shortcodeData, 'feature_completion_statuses', {})
  const completedFeatureKeys = Object.keys(featureCompletionStatuses).filter(
    featureKey => featureCompletionStatuses[featureKey],
  )

  useEffect(() => {
    if (sortedSectionGroups.length){
      setupSectionGroupState({
        digitalPage, digitalPageSortedGroupSections, setSortedGroupSections, sortedSectionGroups,
      })
    }
  }, [digitalPagesUpdatedAt, digitalTemplatePageSectionGroupsUpdatedAt, digitalTemplatePageSectionsUpdatedAt, digitalTemplatePagesUpdatedAt, sortedSectionGroups.length, Object.keys(digitalPageSortedGroupSections).length])

  return {
    callbacks: {
      editSectionSort: () => launchModal({
        callbacks,
        modalKey: 'EditDigitalPageSectionSortModal',
        payload: {
          callbacks: {
            updateDigitalPageSectionSort: sortedGroupSections => updateDigitalPageSectionSort(digitalPage, sortedGroupSections, dispatch),
          },
          digitalPage,
        },
      }),
      acceptDigitalPage: (shareLinkToken, requestOptions) => acceptDigitalPage(digitalPage, shareLinkToken, dispatch, requestOptions, dispatchMixpanelEvent),
      createDigitalPage: (digitalPageParams, requestOptions) => createDigitalPage(digitalPageParams, dispatch, dispatchMixpanelEvent, requestOptions),
      deleteDigitalPage: () => deleteDigitalPage(digitalPage, dispatch),
      duplicateDigitalPage: digitalPageParams => duplicateDigitalPage(digitalPageParams, dispatch, dispatchMixpanelEvent),
      loadDigitalPageShortcodeData: requestOptions => loadDigitalPageShortcodeData(digitalPage, dispatch, requestOptions),
      manageShareLinks: () => manageShareLinks(digitalPage, showManageShareLinksModal),
      editDigitalPage: () => editDigitalPage(digitalPage, showEditDigitalPageModal, dispatch),
      resetDefaultImage: () => resetDefaultImage(digitalPage, dispatch),
      resetDefaultText: () => resetDefaultText(digitalPage, dispatch),
      resetDisabledSections: () => resetDisabledSections(digitalPage, 'disabled_sections', dispatch),
      resetSortedSections: () => resetSortedSections(digitalPage, 'sorted_section_keys', dispatch),
      selectDigitalTemplateId: (digitalTemplateId, requestOptions) => selectDigitalTemplateId(digitalTemplateId, digitalPage, dispatch, dispatchMixpanelEvent, requestOptions),
      showSelectDigitalTemplateModal: (digitalTemplateTypeKey, modalOptions) => selectDigitalTemplateModal(digitalTemplateTypeKey, digitalPage, showSelectDigitalTemplateModal, modalOptions),
      updateDigitalPage: (digitalPageParams, requestOptions) => updateDigitalPage(digitalPage, digitalPageParams, dispatch, requestOptions),
      updatePageItemCustomData: (pageItemId, changes, requestOptions) => updatePageItemCustomData(digitalPage, pageItemId, changes, dispatch, requestOptions),
      updateShortcodeDataSubject: (subjectType, key, value) => updateShortcodeDataSubject({
        dispatch, key, shortcodeData, subjectType, value,
      }),
      modifySectionSort: (fromIndex, toIndex, payload) => canModifySectionSort({
        fromIndex, payload, setSortedGroupSections, sortedGroupSections, toIndex,
      }),
    },
    completedFeatureKeys,
    creating,
    data: data || {},
    defaultProofingLink,
    defaultTrackingLink,
    deleting,
    digitalPage,
    digitalTemplate,
    digitalTemplateType,
    filteredStatuses,
    parentDigitalTemplateType,
    project,
    shareLinkEnabled,
    shortcodeData,
    sortedGroupSections,
    status,
    updating,
    urls: generateUrls(digitalPage),
  }
}

export default useDigitalPage
