import { useMemo } from 'react'

import { matchFilterNumber, sortArrayBy } from '@campaignhub/javascript-utils'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useSelector from '@hooks/useSelector'

import type { TagModel } from '@models/types'

const watchEntityKeys = ['tags']

type TagFilters = {
  ownerId?: number,
  ownerType?: string,
  subjectId?: number,
  subjectType?: string,
}

type UseTagsOptions = {
  filters?: TagFilters,
}

function useTags(options: UseTagsOptions) {
  const { filters = {} } = options
  const {
    ownerId: filterOwnerId,
    ownerType: filterOwnerType,
    subjectId: filterSubjectId,
    subjectType: filterSubjectType,
  } = filters

  const {
    updatedEntities: { tags: tagsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { tags } = useSelector(reduxState => reduxState.entities)

  const filteredTags = useMemo(() => {
    const filtered = Object.values(tags).filter((tag: TagModel) => {
      const {
        owner_id, owner_type, subject_id, subject_type,
      } = tag

      const ownerMatch = filterOwnerId && filterOwnerType
        ? owner_type === filterOwnerType && matchFilterNumber(owner_id, filterOwnerId)
        : true
      const subjectMatch = filterSubjectId && filterSubjectType
        ? subject_type === filterSubjectType && matchFilterNumber(subject_id, filterSubjectId)
        : true

      return ownerMatch && subjectMatch
    })

    return sortArrayBy(filtered, 'asc', 'title')
  }, [tagsUpdatedAt, JSON.stringify(filters)])

  const hasFilteredTags = !!filteredTags.length

  const { loading } = useSelector(reduxState => reduxState.tags)

  return {
    filteredTags,
    hasTags: hasFilteredTags,
    loading,
  }
}

export default useTags
