import React, { useContext, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { useSetState } from '@campaignhub/react-hooks'

import PageContext from '@contexts/pageContext'

import useDeviceStyle from '@hooks/useDeviceStyle'

import useDropTarget from '../../../hooks/useDropTarget'

import styles from './styles.module.scss'

const wrapContent = (options, children, contentHolderRef, shouldUseWidthStyle) => {
  const { contentWidthId, disableContentHolder } = options
  if (disableContentHolder) return children

  return (
    <div
      className={classnames(
        styles.contentHolder,
        contentWidthId && shouldUseWidthStyle ? styles[contentWidthId] : null,
      )}
      data-content-holder="true"
      ref={contentHolderRef}
    >
      {children}
    </div>
  )
}

const defaultState = {
  elementWidth: 0,
}

const DropTarget = (props) => {
  const {
    callbacks,
    callbacks: { selectCanvasItem },
    itemComponents,
    digitalTemplatePageSectionPayload: { digitalTemplatePageSection, editorEntity },
  } = props

  const [state, setState] = useSetState(defaultState)
  const { elementWidth } = state

  const { targetDevice } = useContext(PageContext)

  const style = useDeviceStyle(digitalTemplatePageSection)

  const options = digitalTemplatePageSection.options || {}

  const dropTargetRef = useRef(null)
  const contentHolderRef = useRef(null)

  const { isActive } = useDropTarget(dropTargetRef, editorEntity, {
    excludedTypes: ['section', 'sectionGroup'],
    callbacks,
  })

  useEffect(() => {
    if (contentHolderRef){
      setState({ elementWidth: contentHolderRef.current?.offsetWidth })
    }
  }, [contentHolderRef, targetDevice])

  // when the target device is set to Mobile
  // dynamic content holder still applies to the section
  // we used useRef to only apply changes when the element width (canvas width) is wider than 375

  const shouldUseWidthStyle = elementWidth > 375

  return (
    <div
      className={classnames(styles.root, isActive ? styles.active : null)}
      onClick={e => selectCanvasItem(editorEntity, e)}
      ref={dropTargetRef}
      style={style}
    >
      <div className={styles.label}>Section {digitalTemplatePageSection.id}</div>
      {wrapContent(
        options,
        itemComponents.map(component => component),
        contentHolderRef,
        shouldUseWidthStyle,
      )}
    </div>
  )
}

DropTarget.propTypes = {
  callbacks: PropTypes.shape({
    selectCanvasItem: PropTypes.func.isRequired,
  }).isRequired,
  digitalTemplatePageSectionPayload: PropTypes.shape({
    digitalTemplatePageSection: PropTypes.object,
    editorEntity: PropTypes.object,
  }).isRequired,
  itemComponents: PropTypes.array.isRequired,
}

export default DropTarget
