import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@campaignhub/suit-theme'

import DigitalTemplate from './DigitalTemplate'

const TemplateList = (props) => {
  const {
    digitalTemplatesPayload: { filteredDigitalTemplates },
  } = props

  return (
    <Box flexDirection="column">
      {filteredDigitalTemplates.map(digitalTemplate => (
        <DigitalTemplate key={digitalTemplate.id} digitalTemplate={digitalTemplate} />
      ))}
    </Box>
  )
}

TemplateList.propTypes = {
  digitalTemplatesPayload: PropTypes.shape({
    filteredDigitalTemplates: PropTypes.array.isRequired,
  }).isRequired,
}

export default TemplateList
