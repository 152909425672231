import { useContext } from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/pro-light-svg-icons'

import {
  Box,
  Button,
  FormField,
  InputSearch,
  LoadingModule,
  ModalContext,
  SidebarModal,
  UserProfileImage,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useLocalization from '@hooks/useLocalization'
import useReview from '@hooks/useReview'
import useManageReviews from './hooks/useManageReviews'

import Reviews from './components/Reviews'

import localizedStrings from './localizedStrings'

const mapResults = (images, leadUsers, supportUsers, strings) => {
  const groups = []

  if (leadUsers.length){
    const leadUserGroup = {
      key: 'leadUsers',
      title: strings.formFieldLabels?.leadTitle || 'LEAD USERS',
      items: leadUsers.map((leadUser) => {
        const image = images[leadUser.default_image_id]
        const imageUrl = digObject(image, 'sizes.thumb_90x90', '')

        return {
          iconLeft: (
            <UserProfileImage imageUrl={imageUrl}>
              <FontAwesomeIcon icon={faUser} />
            </UserProfileImage>
          ),
          id: leadUser.id,
          key: `User-${leadUser.id}`,
          secondaryText: leadUser.mobile,
          text: leadUser.full_name,
        }
      }),
    }

    groups.push(leadUserGroup)
  }

  if (supportUsers.length){
    const supportUserGroup = {
      key: 'supportUsers',
      title: strings.formFieldLabels?.supportTitle || 'SUPPORT USERS',
      items: supportUsers.map((supportUser) => {
        const image = images[supportUser.default_image_id]
        const imageUrl = digObject(image, 'sizes.thumb_90x90', '')

        return {
          iconLeft: (
            <UserProfileImage imageUrl={imageUrl}>
              <FontAwesomeIcon icon={faUser} />
            </UserProfileImage>
          ),
          id: supportUser.id,
          key: `User-${supportUser.id}`,
          secondaryText: supportUser.mobile,
          text: supportUser.full_name,
        }
      }),
    }

    groups.push(supportUserGroup)
  }

  return { groups }
}

const ManageProjectReviewsModal = (props) => {
  const {
    callbacks, disableAnimation, disableOverlay, modalKey, project, showModal,
  } = props
  const { updateProject } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const { callbacks: payloadCallbacks } = digObject(modalData, modalKey, {})
  const { loadShortcodeData: loadDataFn } = payloadCallbacks || {}

  const {
    urls: { indexReviewsUrl },
  } = useReview()

  const {
    callbacks: {
      selectUserId, setState, toggleAllReviews, toggleReview,
    },
    images,
    loading,
    projectPayload: {
      callbacks: { updateProject: updateFn },
      leadUsers,
      supportUsers,
      updating,
    },
    reviewsUsers,
    selectedUser,
    selectedUserHasReviews,
    selectedUserReviews,
    showSelectedReviews,
    string,
    usersWithReviews,
  } = useManageReviews(project)

  const selectedUserImage = images[selectedUser.default_image_id]

  const params = { id: project.id, reviews_users: reviewsUsers }

  const {
    callbacks: { formatCount },
    strings,
  } = useLocalization(localizedStrings)

  return (
    <SidebarModal
      callbacks={callbacks}
      disableAnimation={disableAnimation}
      disableOverlay={disableOverlay}
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.sideBarModalHeader?.title || 'Manage'}
        titleSecondLine={strings.sideBarModalHeader?.titleSecondLine || 'Reviews'}
      />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <FormField direction="column" label={strings.formFieldLabels?.users || 'Users'}>
            <InputSearch
              callbacks={{ selectItem: user => selectUserId(user.id) }}
              closeOnSelect
              results={mapResults(images, leadUsers, supportUsers, strings)}
              showOnFocus
            />
          </FormField>
        </Box>

        {/* Placeholder */}
        {!!selectedUser.id && !selectedUserHasReviews && (
          <SidebarModal.User
            disableHover
            imageUrl={digObject(selectedUserImage, 'sizes.thumb_90x90', '')}
            key={selectedUser.id}
            subtitle={strings.reviewCounts?.none || ' 0 Reviews'}
            title={selectedUser.full_name}
          />
        )}
        {/* End Placeholder */}

        {usersWithReviews.map(({ user, reviewCount }) => {
          const image = images[user.default_image_id]

          return (
            <SidebarModal.User
              imageUrl={digObject(image, 'sizes.thumb_90x90', '')}
              onClick={() => selectUserId(user.id)}
              key={user.id}
              subtitle={formatCount('reviewCounts', { count: reviewCount })}
              title={user.full_name}
            />
          )
        })}

        {!!selectedUser.id && (
          <FormField
            boxProps={{ marginTop: 'large' }}
            direction="column"
            label={strings.formFieldLabels?.searchReviews || 'Search Reviews'}
          >
            <InputSearch
              onChange={e => setState({ string: e.target.value })}
              value={string}
            />
          </FormField>
        )}

        <LoadingModule loading={loading} times={3} />

        {!loading && !!selectedUserReviews.length && (
          <Reviews
            callbacks={{ toggleReview }}
            reviews={selectedUserReviews}
            reviewsUsers={reviewsUsers}
            showSelectedReviews={showSelectedReviews}
            userId={selectedUser.id}
          />
        )}

        {!loading && !!selectedUser.id && (
          <FormField
            direction="column"
            boxProps={{ marginTop: 'large' }}
            label={strings.formFieldLabels?.moreOptions || 'More Options'}
          >
            <Box flexDirection="column" flexShrink={0}>
              {!!reviewsUsers.length && (
                <>
                  <Button
                    buttonStyle="secondaryUtility"
                    onClick={() => toggleAllReviews()}
                    size="medium"
                    style={{ marginBottom: '8px' }}
                  >
                    {strings.buttons?.toggleAll || 'Toggle All'}
                  </Button>

                  <Button
                    buttonStyle="secondaryUtility"
                    onClick={() => setState({ showSelectedReviews: !showSelectedReviews })}
                    size="medium"
                  >
                    {showSelectedReviews
                      ? strings.buttons?.showAll || 'Show All'
                      : strings.buttons?.showSelected || 'Show Selected'}
                  </Button>
                </>
              )}

              {!reviewsUsers.length && (
                <Button
                  as="a"
                  buttonStyle="secondaryUtility"
                  href={indexReviewsUrl}
                  size="medium"
                  style={{ marginBottom: '8px' }}
                >
                  {strings.buttons?.manage || 'Manage Reviews'}
                </Button>
              )}
            </Box>
          </FormField>
        )}
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          loading={updating}
          loadingBubbleColor="white"
          onClick={() => updateProject(params, 'Testimonials', updateFn, loadDataFn)}
          size="large"
        >
          {strings.buttons?.save || 'Save'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

ManageProjectReviewsModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  disableAnimation: PropTypes.bool,
  disableOverlay: PropTypes.bool,
  modalKey: PropTypes.string,
  project: PropTypes.object.isRequired,
  showModal: PropTypes.bool,
}

ManageProjectReviewsModal.defaultProps = {
  modalKey: 'ManageProjectReviewsModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <ManageProjectReviewsModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
