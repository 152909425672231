import { useEffect } from 'react'

import { digObject } from '@campaignhub/javascript-utils'
import { useSetState, useThunkDispatch } from '@campaignhub/react-hooks'

import * as shareLinkActions from '@redux/modules/shareLink'

import { AppDispatch } from '@redux/store'

import { ShareLinkModel } from '@models/types'

type DefaultState = {
  isValid: boolean,
  shareable: {},
  shareLink: Partial<ShareLinkModel>,
  tokenChecked: boolean,
}

const defaultState: DefaultState = {
  isValid: false,
  shareable: {},
  shareLink: {},
  tokenChecked: false,
}

type SetState = (state: Partial<typeof defaultState>) => void

type checkTokenValidParams = {
  dispatch: AppDispatch,
  setState: SetState,
  token: string,
}

const checkTokenValid = async (params: checkTokenValidParams) => {
  const { dispatch, setState, token } = params

  const { validateShareLinkToken } = shareLinkActions

  try {
    const { data } = await dispatch((validateShareLinkToken(token)))

    setState({
      isValid: digObject(data, 'valid', false),
      shareLink: digObject(data, 'entity', {}),
      tokenChecked: true,
    })
  } catch (error){
    setState({
      isValid: false,
      tokenChecked: true,
    })
  }
}

function useValidShareLink(token: string) {
  const [state, setState] = useSetState(defaultState)
  const {
    isValid, shareable, shareLink, tokenChecked,
  } = state

  const dispatch = useThunkDispatch()

  useEffect(() => {
    checkTokenValid({ dispatch, setState, token })
  }, [token])

  return {
    isValid,
    shareable,
    shareLink,
    tokenChecked,
  }
}

export default useValidShareLink
