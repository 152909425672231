export default {
  default: {
    confirmDelete: {
      title: 'Delete Item?',
      text: 'Are you sure? It cannot be recovered.',
      confirmButtonText: 'Yes, delete it.',
    },
    buttons: {
      add: 'Add Item',
    },
    columnTitles: {
      actions: 'Actions',
    },
  },
}
