import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import { digObject, snakeToTitleCase } from '@campaignhub/javascript-utils'

import {
  Box, Image, Text, Link, ListItem,
} from '@campaignhub/suit-theme'

import useDigitalPageTheme from '@hooks/useDigitalPageTheme'
import useDigitalTemplate from '@hooks/useDigitalTemplate'

import DigitalPagePlaceholderImage from '@components/DigitalPagePlaceholderImage'

const DigitalPageListItem = (props) => {
  const {
    callbacks: { selectDigitalPage },
    digitalPage,
    digitalPage: { id, digital_template_id, title },
    sourceDigitalPageId,
  } = props

  const digitalTemplatePayload = useDigitalTemplate({ id: digital_template_id })
  const { digitalTemplate, previewImage } = digitalTemplatePayload

  const digitalTemplateTitle = digObject(digitalTemplate, 'title', '')
  const previewImageUrl = digObject(previewImage, 'sizes.thumb_200x120', '')

  const { colors, digitalTemplateTypeKey } = useDigitalPageTheme(digitalPage)

  return (
    <ListItem
      boxProps={{ alignItems: 'center', padding: 'medium', marginBottom: 'medium' }}
      onClick={() => selectDigitalPage()}
    >
      <Image boxProps={{ marginRight: 'medium' }} borderRadius={5} height={45} url={previewImageUrl} width={60}>
        {!previewImageUrl && (
          <Box background={colors.background} borderRadius={5} height={45}>
            <DigitalPagePlaceholderImage
              color={colors.solid}
              digitalTemplateTypeKey={digitalTemplateTypeKey}
              height="100%"
              width="100%"
            />
          </Box>
        )}
      </Image>

      <Box justifyContent="center" flexDirection="column" width="calc(100% - 75px)">
        <Text color="bodyFontColor" fontSize="small" marginBottom="small" variant="ellipsis">
          {title || snakeToTitleCase(digitalTemplateTypeKey)}
        </Text>

        <Text color="bodyFontLightColor" fontSize="small" marginBottom="xxsmall" variant="ellipsis">
          {`ID# ${id} - ${digitalTemplateTitle} ID# ${digital_template_id}`}
        </Text>
      </Box>

      {!!sourceDigitalPageId && (
        <Link greyLink textProps={{ fontSize: 'small' }}>
          <FontAwesomeIcon icon={faTrashAlt} onClick={() => selectDigitalPage()} />
        </Link>
      )}
    </ListItem>
  )
}

DigitalPageListItem.propTypes = {
  callbacks: PropTypes.shape({
    selectDigitalPage: PropTypes.func.isRequired,
  }).isRequired,
  digitalPage: PropTypes.object.isRequired,
  sourceDigitalPageId: PropTypes.number,
}

export default DigitalPageListItem
