import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Box, CustomizableDashboard } from '@campaignhub/suit-theme'

import UserOnboardingTasks from '@sections/Client/components/UserOnboardingTasks/Main'

import styles from './styles.module.scss'

const Content = React.forwardRef((props, ref) => {
  const {
    draggablePlaceholder, id, isDraggingOver, onboardingTasksPayload, modules,
  } = props

  return (
    <Box
      className={classnames(styles.root, isDraggingOver ? styles.draggingOver : null)}
      flexDirection="column"
      marginRight={[0, 0, 'large']}
      width={['100%', '100%', 'calc(100% - 375px)']}
    >
      <UserOnboardingTasks onboardingTasksPayload={onboardingTasksPayload} />

      <div ref={ref} style={{ flexGrow: 1, minHeight: '100vh' }}>
        {modules && (
          <CustomizableDashboard.ModuleRenderer modules={modules} parentId={id} />
        )}
        {draggablePlaceholder}
      </div>
    </Box>
  )
})

Content.propTypes = {
  draggablePlaceholder: PropTypes.object,
  id: PropTypes.string,
  isDraggingOver: PropTypes.bool,
  modules: PropTypes.array,
  onboardingTasksPayload: PropTypes.object.isRequired,
}

export default Content
