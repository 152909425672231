/* eslint-disable import/no-cycle */
import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import useDeviceStyle from '@hooks/useDeviceStyle'

import { stripShortcode } from '@functions/replaceTextShortCodes'

import ContentWrapper from '@components/digitalTemplates/ContentWrapper'

import useDropTarget from '../../../hooks/useDropTarget'
import availableComponents from '../../../availableComponents'

import styles from './styles.module.scss'

const renderElements = (container, options) => {
  const {
    callbacks, dataContext, itemEntities, selectedItem,
  } = options

  return (
    <div className={styles.item}>
      <ContentWrapper
        availableComponents={availableComponents}
        callbacks={callbacks}
        dataContext={dataContext}
        itemEntities={itemEntities}
        section={container}
        selectedItem={selectedItem}
        renderNested
        render={({ itemComponents }) => itemComponents.map(component => component)}
      />
    </div>
  )
}

const getUpdatedDataContext = (props) => {
  const {
    data, dataContext, id, item_type, itemEntities,
  } = props

  const item = itemEntities[id] || {}
  const updatedDataContext = item_type === 'dataArrayLoop' && item.options?.sort === 0 ? [] : [...dataContext]

  if (item_type === 'dataArrayLoop'){
    const shortcodeValue = stripShortcode(data?.value)
    updatedDataContext.push(shortcodeValue)
  }

  return updatedDataContext
}

const ContainerRepeater = (props) => {
  const {
    id, item_type, onClick,
  } = props

  const {
    callbacks, entityType, isSelected, itemEntities, selectedItem, ...container
  } = props

  const style = useDeviceStyle(props)

  const dropTargetRef = useRef(null)

  const { isActive } = useDropTarget(dropTargetRef, { id, type: entityType }, {
    acceptedTypes: ['container'],
    callbacks,
  })

  // Get array of data loop contexts eg ['teams', 'lead_users']
  const updatedDataContext = getUpdatedDataContext(props)

  return (
    <div
      className={classnames(styles.root, isActive ? styles.active : null, isSelected ? styles.selected : null)}
      onClick={onClick}
      style={style}
      ref={dropTargetRef}
    >
      <div className={styles.label}>
        {item_type} {id}
      </div>
      {renderElements(container, {
        callbacks, dataContext: updatedDataContext, itemEntities, selectedItem,
      })}
    </div>
  )
}

ContainerRepeater.propTypes = {
  callbacks: PropTypes.object,
  data: PropTypes.object,
  dataContext: PropTypes.array,
  entityType: PropTypes.string,
  id: PropTypes.string,
  isSelected: PropTypes.bool,
  itemEntities: PropTypes.object,
  item_type: PropTypes.string,
  items: PropTypes.array,
  onClick: PropTypes.func,
  selectedItem: PropTypes.object,
  style: PropTypes.object,
}

ContainerRepeater.defaultProps = {
  dataContext: [],
  items: [],
}

export default ContainerRepeater
