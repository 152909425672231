import React from 'react'
import PropTypes from 'prop-types'

import { Box, Text } from '@campaignhub/suit-theme'

const Statistic = (props) => {
  const {
    boxProps, label, value, fullWidth, icon,
  } = props

  return (
    <Box
      border="1px solid"
      borderColor="lineColor"
      borderRadius={5}
      marginBottom="medium"
      padding="large"
      position="relative"
      width={fullWidth ? '100%' : 'calc(50% - 4px)'}
      {...boxProps}
    >

      <Box justifyContent="center" flexDirection="column">
        <Text color="bodyFontLightColor" fontSize="xsmall">{label}</Text>
        <Text fontSize="medium" marginTop="small">
          {value}
        </Text>
      </Box>

      {icon && (
        <Box alignItems="center" justifyContent="flex-end" marginLeft="auto" width="auto">
          {icon}
        </Box>
      )}
    </Box>
  )
}

Statistic.propTypes = {
  boxProps: PropTypes.object,
  fullWidth: PropTypes.bool,
  icon: PropTypes.object,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}

export default Statistic
