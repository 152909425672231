import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

type UseRolesOptions = {
  performHttpRequests?: boolean,
}

function useRoles(options: UseRolesOptions = {}) {
  const { performHttpRequests } = options

  useReduxAction('roles', 'loadRoles', {}, [performHttpRequests], {
    shouldPerformFn: (entityReducer) => {
      const { errors, loaded, loading } = entityReducer
      return performHttpRequests && !loaded && !loading && !errors.length
    },
  })

  const { roles } = useSelector(reduxState => reduxState.entities)

  const { loaded, loading } = useSelector(reduxState => reduxState.roles)

  return {
    loaded,
    loading,
    roles,
  }
}

export default useRoles
