import { Box } from '@campaignhub/suit-theme'

import FieldHolder from '@components/FieldHolder'
import Input from '@components/Input'

interface BackgroundPropertiesProps {
  itemStyle: {
    backgroundColor: string,
    backgroundPosition: string,
    backgroundRepeat: string,
    backgroundSize: string,
    webkitBackgroundClip: string,
  },
  itemType: string,
  selectedEntity: {
    id: number,
  },
  styleProps: {},
  updateStyle: (name: string, value: string, styleProps: {}) => void,
}

const BackgroundProperties = (props: BackgroundPropertiesProps) => {
  const {
    itemStyle, itemType, selectedEntity, styleProps, updateStyle,
  } = props

  return (
    <Box marginTop="large" flexDirection="row" flexWrap="wrap">
      <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth label="Background Clip">
        <select
          onChange={e => updateStyle('webkitBackgroundClip', e.target.value, styleProps)}
          value={itemStyle.webkitBackgroundClip || ''}
        >
          <option value="">Please Select ...</option>
          <option value="text">Text</option>
          <option value="padding-box">Padding Box</option>
          <option value="content-box">Content Box</option>
          <option value="border-box">Border Box</option>
        </select>
      </FieldHolder>

      <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth label="BG Repeat" last>
        <select
          onChange={e => updateStyle('backgroundRepeat', e.target.value, styleProps)}
          value={itemStyle.backgroundRepeat || ''}
        >
          <option value="no-repeat">No Repeat</option>
          <option value="repeat">Repeat</option>
          <option value="repeat-x">Repeat X</option>
          <option value="repeat-y">Repeat Y</option>
          <option value="space">Space</option>
          <option value="round">Round</option>
        </select>
      </FieldHolder>

      <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth label="BG Position">
        <select
          onChange={e => updateStyle('backgroundPosition', e.target.value, styleProps)}
          value={itemStyle.backgroundPosition || ''}
        >
          <option value="center left">Center Left</option>
          <option value="center center">Center Center</option>
          <option value="center right">Center Right</option>
          <option value="top left">Top Left</option>
          <option value="top center">Top Center</option>
          <option value="top right">Top Right</option>
          <option value="bottom left">Bottom Left</option>
          <option value="bottom center">Bottom Center</option>
          <option value="bottom right">Bottom Right</option>
        </select>
      </FieldHolder>

      <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth label="Background Size" last>
        <select
          onChange={e => updateStyle('backgroundSize', e.target.value, styleProps)}
          value={itemStyle.backgroundSize || ''}
        >
          <option value="">Default</option>
          <option value="contain">Contain</option>
          <option value="cover">Cover</option>
        </select>
      </FieldHolder>

      <FieldHolder halfWidth label="Background">
        <Input
          boxProps={{ marginBottom: 'large' }}
          data={itemStyle}
          entityId={selectedEntity.id}
          fullWidth
          propertyKey={itemStyle.backgroundColor ? 'backgroundColor' : 'background'}
          updateCallback={(name, value) => updateStyle(name, value, styleProps)}
        />
      </FieldHolder>

      <FieldHolder halfWidth label="Image Effect" last>
        <Input
          boxProps={{ marginBottom: 'large' }}
          data={itemStyle}
          entityId={selectedEntity.id}
          fullWidth
          parse={value => value || null}
          propertyKey="filter"
          updateCallback={(name, value) => updateStyle(name, value, styleProps)}
        />
      </FieldHolder>

      {itemType === 'mediaContainer' && (
        <>
          <FieldHolder label="Video URL">
            <Input
              boxProps={{ marginBottom: 'large' }}
              data={itemStyle}
              entityId={selectedEntity.id}
              fullWidth
              propertyKey="videoUrl"
              updateCallback={(name, value) => updateStyle(name, value, styleProps)}
            />
          </FieldHolder>

          <FieldHolder label="Video Poster Link">
            <Input
              boxProps={{ marginBottom: 'large' }}
              data={itemStyle}
              entityId={selectedEntity.id}
              fullWidth
              parse={value => value || null}
              propertyKey="videoPoster"
              updateCallback={(name, value) => updateStyle(name, value, styleProps)}
            />
          </FieldHolder>
        </>
      )}
    </Box>
  )
}

export default BackgroundProperties
