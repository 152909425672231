export default {
  default: {
    buttons: {
      addPlatform: 'Add External Platform',
    },
    formFieldLabels: {
      moreOptions: 'More Options',
    },
    sideBarModalHeader: {
      title: 'Connected',
      titleSecondLine: 'Platforms',
    },
  },
}
