import { useSetState } from '@campaignhub/react-hooks'

const defaultState = {
  slideCount: 0,
}

function useCarousel(pageItemId, options = {}){
  const {
    carouselAutoplay, carouselCustomGallery, carouselHeight, carouselRenderMode, carouselVisibleSlides, carouselWidth,
  } = options || {}

  const dataLoopValue = carouselCustomGallery ? `{{galleries.${pageItemId}.images}}` : null

  const [state, setState] = useSetState(defaultState)
  const { slideCount } = state

  const isModal = carouselRenderMode === 'modal'

  return {
    autoplay: carouselAutoplay || false,
    callbacks: {
      setState,
    },
    dataLoopValue,
    height: carouselHeight,
    isModal,
    slideCount,
    visibleSlides: carouselVisibleSlides || 1,
    width: carouselWidth,
  }
}

export default useCarousel
