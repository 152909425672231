import React from 'react'
import PropTypes from 'prop-types'

import { Box, Text } from '@campaignhub/suit-theme'
import { digObject } from '@campaignhub/javascript-utils'

import { snakeCaseToCamelCase } from '@functions/string'

import useLocalization from '@hooks/useLocalization'

import NotificationMethod from '../NotificationMethod'

import localizedStrings from './localizedStrings'

const NotificationGroup = (props) => {
  const {
    callbacks, disabledNotifications, lastGroup, notificationGroup,
  } = props

  const { strings } = useLocalization(localizedStrings)

  return (
    <Box
      borderBottom={lastGroup ? 'none' : '1px solid'}
      borderColor="lineColor"
      flexDirection="column"
      paddingX="large"
      paddingY="medium"
    >
      {notificationGroup.map((notificationType) => {
        const { key, notificationMethodKeys } = notificationType
        const stringKey = snakeCaseToCamelCase(key)

        const disabledMethodsForNotificationKey = digObject(disabledNotifications, key, [])

        return (
          <Box flexDirection="column" key={key}>
            <Text marginY="medium" fontSize="small">
              {strings[stringKey]?.title}
            </Text>
            <Text color="bodyFontLightColor" fontSize="xsmall" lineHeight={1.3}>
              {strings[stringKey]?.description}
            </Text>

            <Box flexDirection="column" marginTop="medium">
              {notificationMethodKeys.map((notificationMethodKey) => {
                const disabled = disabledMethodsForNotificationKey.includes(notificationMethodKey)

                return (
                  <NotificationMethod
                    callbacks={callbacks}
                    disabled={disabled}
                    key={notificationMethodKey}
                    notificationMethodKey={notificationMethodKey}
                    notificationKey={key}
                  />
                )
              })}
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}

NotificationGroup.propTypes = {
  callbacks: PropTypes.object,
  disabledNotifications: PropTypes.object,
  lastGroup: PropTypes.bool,
  notificationGroup: PropTypes.array,
}

export default NotificationGroup
