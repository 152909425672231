import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, Grid,
} from '@campaignhub/suit-theme'

import Preview from './components/Preview'
import Thumbnail from './components/Thumbnail'

const GridView = (props) => {
  const { imagesPayload } = props || {}
  const {
    bulkSelectedImageIds,
    bulkSelecting,
    callbacks,
    selectedImage,
    sortedImages,
  } = imagesPayload

  return (
    <Box flexDirection="column" flexShrink="0">
      {selectedImage.id && <Preview callbacks={callbacks} image={selectedImage} />}

      <Grid
        gridGap="medium"
        gridAutoRows="1fr"
        gridTemplateColumns="repeat(auto-fill, minmax(180px, 1fr))"
        gridTemplateRows="repeat(auto-fill, minmax(140px, 1fr))"
        padding="large"
      >
        {sortedImages.map((image) => {
          const selectionIndex = bulkSelectedImageIds.findIndex(imageId => image.id === imageId)

          return (
            <Thumbnail
              bulkSelecting={bulkSelecting}
              callbacks={callbacks}
              image={image}
              key={image.id}
              selectionIndex={selectionIndex}
            />
          )
        })}
      </Grid>
    </Box>
  )
}

GridView.propTypes = {
  imagesPayload: PropTypes.shape({
    selectedImage: PropTypes.object,
    sortedImages: PropTypes.array.isRequired,
  }).isRequired,
}

export default GridView
