import { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile, faFileImage } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Columns, DashboardModule, LoadingModule, PageHeader, Tools,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import useAssetLibraries from '@hooks/useAssetLibraries'
import useAssetLibrary from '@hooks/useAssetLibrary'
import useBrandSelector from '@hooks/useBrandSelector'
import useCurrentUser from '@hooks/useCurrentUser'
import useIntercom from '@hooks/useIntercom'
import useReduxAction from '@hooks/useReduxAction'

import PageFilterModule from '@components/PageFilterModule'

import ImageLibrary from '../ImageLibrary'
import ImageLibrariesBlankState from '../ImageLibraryBlankState'

const buildPageFilterFields = (isAdmin) => {
  const boxProps = {
    marginBottom: 'large',
  }

  const style = {
    marginBottom: 16,
  }

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        style,
      },
      key: 'string',
      label: 'Filter by Title',
    },
    {
      callbacks: {
        shouldRender: () => isAdmin,
      },
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: 'All',
        inputComponent: 'select',
        style,
      },
      key: 'owner_type',
      label: 'Owner Type',
      values: [
        { key: 'Brand', label: 'Brand' },
        { key: 'Organization', label: 'Organization' },
      ],
    },
    {
      callbacks: {
        shouldRender: state => state.owner_type === 'Brand',
      },
      component: 'EntitySelector',
      componentProps: {
        entityKey: 'brands',
        boxProps,
      },
      key: 'owner_id',
    },
    {
      callbacks: {
        shouldRender: state => state.owner_type === 'Organization',
      },
      component: 'EntitySelector',
      componentProps: {
        entityKey: 'organizations',
        boxProps,
      },
      key: 'owner_id',
    },
    {
      entityKey: 'limit',
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: '100',
        inputComponent: 'select',
        style,
      },
      key: 'limit',
      label: 'Limit',
      values: [
        { key: 150, label: '150' },
        { key: 200, label: '200' },
      ],
    },
  ]
}

const PageContent = (props) => {
  const { isAdmin, pageFilters } = props

  useReduxAction('brands', 'loadBrands', {})

  const { selectedBrand } = useBrandSelector()

  const initImageLibrary = {
    asset_type: 'image',
    owner_type: isAdmin ? '' : 'Brand',
    owner_id: isAdmin ? null : selectedBrand.id,
  }

  const assetLibrariesPayload = useAssetLibraries({
    filters: {
      asset_type: 'image',
      ...pageFilters,
    },
  })

  const {
    filteredAssetLibraries: filteredImageLibraries,
    hasAssetLibraries: hasImageLibraries,
    loading,
  } = assetLibrariesPayload

  const {
    callbacks: { createOrEditAssetLibrary },
    urls: { editAssetLibraryUrl },
  } = useAssetLibrary(initImageLibrary)

  const pageContext = useContext(PageContext)
  const {
    callbacks: { resetFilters, showImportAssetLibrariesModal, updateFilters },
  } = pageContext

  const { isBrandUser } = useCurrentUser()

  useIntercom({ hideWidget: !isBrandUser })

  return (
    <>
      <Helmet>
        <title>Image Libraries| Engage</title>
      </Helmet>

      <PageHeader
        activeTabBarItemKey="images"
        actionContent={(
          <Button buttonStyle="primaryCreate" onClick={createOrEditAssetLibrary} size="medium">
            Create Library
          </Button>
        )}
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        tabBarItems={[
          {
            href: editAssetLibraryUrl,
            icon: faFileImage,
            key: 'images',
            title: 'Images',
          },
        ]}
        title="Image Libraries"
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && !hasImageLibraries && <ImageLibrariesBlankState />}

            {!loading && hasImageLibraries && (
              <DashboardModule title="Image Libraries">
                <Box flexDirection="column">
                  {filteredImageLibraries.map(imageLibrary => (
                    <ImageLibrary key={imageLibrary.id} imageLibrary={imageLibrary} />
                  ))}
                </Box>
              </DashboardModule>
            )}
          </Columns.Main>

          <Columns.Sidebar>
            <Tools title="Tools">
              <Button
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faFile} />}
                marginBottom="medium"
                onClick={() => showImportAssetLibrariesModal()}
                size="medium"
              >
                Import Image Libraries
              </Button>
            </Tools>

            <PageFilterModule
              callbacks={{ resetFilters, updateFilters }}
              filterFields={buildPageFilterFields(isAdmin)}
              pageFilters={pageFilters}
              title="Filters"
            />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

PageContent.propTypes = {
  pageFilters: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool,
}

export default PageContent
