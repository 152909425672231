import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/pro-light-svg-icons'

import {
  Box, DashboardModule, Link, ListItem, Text,
} from '@campaignhub/suit-theme'

import useEventCalendar from '@hooks/useEventCalendar'
import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const EventCalendarListItem = (props) => {
  const { eventCalendar } = props

  const {
    eventCalendar: { title },
    filteredEvents,
    urls: { editEventCalendarUrl },
  } = useEventCalendar(eventCalendar)

  const { callbacks: { formatDate }, strings } = useLocalization(localizedStrings)

  return (
    <DashboardModule
      title={title}
      headerRight={(
        <Link href={editEventCalendarUrl} textProps={{ fontSize: 'small' }}>
          {strings.manage || 'Manage'}
        </Link>
      )}
    >
      <Box flexDirection="column">
        {!!filteredEvents.length
          && filteredEvents.map((event) => {
            const {
              dates: {
                start: { date_time_with_timezone },
              },
              id,
            } = event

            return (
              <ListItem
                boxProps={{
                  as: 'a',
                  alignItems: 'center',
                  borderBottom: '1px dashed',
                  padding: 'large',
                }}
                href={`${editEventCalendarUrl}?event_id=${id}`}
                key={id}
                showAngleIcon
              >
                <Box flexDirection="row">
                  <Text fontSize="small" marginRight="auto">
                    {event.title || 'Title'}
                  </Text>

                  <Text color="bodyFontLightColor" fontSize="small">
                    <FontAwesomeIcon icon={faCalendar} style={{ marginRight: '5px' }} />
                    {formatDate(date_time_with_timezone, { weekday: 'long', month: 'long', day: 'numeric' })}
                  </Text>
                </Box>
              </ListItem>
            )
          })}

        {!filteredEvents.length && (
          <ListItem
            boxProps={{
              as: 'a',
              alignItems: 'center',
              borderBottom: '1px dashed',
              padding: 'large',
            }}
            href={editEventCalendarUrl}
            showAngleIcon
          >
            <Text color="blue" fontSize="small" marginRight="auto">
              {strings?.addEvents || 'Add Events'}
            </Text>
          </ListItem>
        )}
      </Box>
    </DashboardModule>
  )
}

EventCalendarListItem.propTypes = {
  eventCalendar: PropTypes.object.isRequired,
}

export default EventCalendarListItem
