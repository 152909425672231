import React, { useCallback } from 'react'
import { Slider, Slide } from 'pure-react-carousel'
import isEqual from 'react-fast-compare'

// eslint-disable-next-line import/no-cycle
import ElementContainer from '../../../ElementContainer'

function propsAreEqual(prevProps, nextProps){
  return isEqual(prevProps.shortcodeData, nextProps.shortcodeData)
}

const SlidesRenderer = React.memo((props) => {
  const {
    callbacks: { setState }, children, dataLoopValue,
  } = props

  const renderSlides = useCallback((params) => {
    const {
      array, itemContainer, itemEntities, itemStyle,
    } = params

    return array.map((item, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Slide key={index}>
        <div style={itemStyle}>
          <ElementContainer
            forwardContextToItems={item}
            itemEntities={itemEntities}
            section={itemContainer}
            renderNested
            render={({ itemComponents }) => itemComponents.map(component => component)}
          />
        </div>
      </Slide>
    ))
  })

  return (
    <Slider>
      {React.Children.map(children, (child) => {
        const itemType = child?.props?.item_type

        if (itemType === 'dataArrayLoop'){
          return React.cloneElement(child, {
            callbacks: {
              render: renderSlides,
              setChildrenCount: count => setState({ slideCount: count }),
            },
            overrideDataValue: dataLoopValue,
          })
        }

        return child
      })}
    </Slider>
  )
}, propsAreEqual)

export default SlidesRenderer
