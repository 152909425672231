import PropTypes from 'prop-types'

import {
  Button,
  FormField,
  Link,
  LoadingModule,
  SectionDivider,
  SidebarModal,
  SidebarNoticeBox,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import IntegrationsBlankState from '@components/IntegrationsBlankState'

import Review from './components/Review'
import localizedStrings from './localizedStrings'
import useImportReviews from './hooks/useImportReviews.js'

const ImportReviewsModal = (props) => {
  const { callbacks, modalKey, showModal } = props
  const { closeModal, importReviews } = callbacks

  const importReviewsPayload = useImportReviews()

  const {
    alreadyImportedExternalIds,
    callbacks: {
      bulkImportFromExternalPlatform,
      filterReviews,
      selectOrganizationId,
      setState,
      toggleAllReviews,
      toggleReview,
    },
    filteredPlatforms,
    filteredReviews,
    hasExternalReviews,
    hasIntegrations,
    hasSelectedReviews,
    integrationsUrls: { integrationsUrl },
    loading,
    selectedIntegrationPlatform,
    selectedIntegrationPlatformKey,
    selectedOrganization,
    selectedReviewIds,
    sortedOrganizationsWhereAdmin,
  } = importReviewsPayload

  const sortedOrganizations = sortedOrganizationsWhereAdmin.length
    ? sortedOrganizationsWhereAdmin
    : [selectedOrganization]

  const {
    callbacks: { formatString },
    strings,
  } = useLocalization(localizedStrings)

  const importReviewsCallbackPayload = {
    callbacks: {
      action: bulkImportFromExternalPlatform,
      afterAction: closeModal,
    },
    entityParams: { externalIds: selectedReviewIds, organizationId: selectedOrganization.id },
    toastText: strings.importReview || 'Import Reviews Queued',
  }

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.title || 'Import'}
        titleSecondLine={strings.titleSecondLine || 'Reviews'}
      />

      <SidebarModal.Content>
        <LoadingModule loading={loading && !hasIntegrations} />

        {!loading && !hasIntegrations && (
          <IntegrationsBlankState
            buttonUrl={integrationsUrl}
            paragraph={
              strings?.blankState?.paragraph
              || 'To import reviews from another platform you’ll need to set up a suitable integration. Integrations can be managed in your client admin.'
            }
            sidebar
          />
        )}

        {hasIntegrations && (
          <>
            <FormField direction="column" label={strings.importReviewsIntoLabel || 'Importing Reviews into'}>
              <select
                data-validate-field-on="change"
                name="selected_organization_id"
                onChange={e => selectOrganizationId(e.target.value)}
                value={selectedOrganization.id}
              >
                <option value="">{strings.formFieldOption || 'Please Select...'}</option>
                {sortedOrganizations.map(organization => (
                  <option key={organization.id} value={organization.id}>
                    {organization.title}
                  </option>
                ))}
              </select>
            </FormField>

            <FormField direction="column" label={strings.platformLabel || 'Platform'} marginTop="large">
              <select
                data-validate-field-on="change"
                onChange={e => setState({ selectedIntegrationPlatformKey: e.target.value })}
                value={selectedIntegrationPlatformKey}
              >
                <option value="">{strings.options || 'Please Select...'}</option>
                {filteredPlatforms.map(entity => (
                  <option key={entity.id} value={entity.key}>
                    {entity.title}
                  </option>
                ))}
              </select>
            </FormField>

            <FormField direction="column" label={strings.searchReviewsLabel || 'Search Reviews'} marginTop="large">
              <input onChange={e => filterReviews(e.target.value.toLowerCase())} />
            </FormField>

            {/* Loading External Reviews */}
            <LoadingModule boxProps={{ marginTop: 'large' }} loading={loading} />

            {!loading && (
              <SectionDivider boxProps={{ marginTop: 'large' }}>
                {strings.availableReviews || 'Available Reviews'}
              </SectionDivider>
            )}

            {!loading && !hasExternalReviews && (
              <SidebarNoticeBox boxProps={{ marginTop: 'large' }}>
                <SidebarNoticeBox.Title>{strings.noticeBox?.title || 'Nothing to Import'}</SidebarNoticeBox.Title>

                <SidebarNoticeBox.Paragraph>
                  {formatString(strings.noticeBox?.paragraph, {
                    integrationPlatformTitle:
                      selectedIntegrationPlatform.title || strings.externalPlatform || 'External Platform',
                  })}
                </SidebarNoticeBox.Paragraph>
              </SidebarNoticeBox>
            )}

            {!loading && hasExternalReviews && (
              <FormField
                direction="column"
                label={strings.selectReviewsLabel || 'Select Reviews'}
                labelRight={(
                  <Link onClick={() => toggleAllReviews()} textProps={{ fontSize: 'xsmall' }}>
                    {hasSelectedReviews
                      ? strings.deselectAllButton || 'Deselect All'
                      : strings.selectAllButton || 'Select All'}
                  </Link>
                )}
                marginTop="large"
              >
                {filteredReviews.map((review) => {
                  const { id } = review

                  return (
                    <Review
                      alreadyImported={alreadyImportedExternalIds.includes(id)}
                      callbacks={{ toggleReview: () => toggleReview(id) }}
                      review={review}
                      checked={selectedReviewIds.includes(id)}
                      key={id}
                    />
                  )
                })}
              </FormField>
            )}
          </>
        )}
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!hasSelectedReviews}
          onClick={() => importReviews(importReviewsCallbackPayload)}
          size="large"
        >
          {strings.importReviewsButton || 'Import Reviews'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

ImportReviewsModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

ImportReviewsModal.defaultProps = {
  modalKey: 'ImportReviewsModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <ImportReviewsModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
