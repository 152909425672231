export default {
  default: {
    buttons: {
      createCalendar: 'Create Calendar',
      createTemplate: 'Create Template',
    },
    formFieldLabels: {
      owner: '* Owner',
      preparationWeeks: 'Preparation Weeks',
      startDate: '* Start Date',
      title: '* Title',
      description: 'Description',
      weeks: 'Number of Weeks',
    },
  },
}
