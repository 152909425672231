import { useSelector } from 'react-redux'

import { modifyGroupedIdsSort, toggleArray } from '@campaignhub/javascript-utils'

import { useDebounce, useSetState } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useProject, { useProjectForm } from '@hooks/useProject'

import filterUnselectedEntities from '@functions/filterUnselectedEntities'

import defaultRequestOptions from '@sections/Client/packs/Admin/defaultRequestOptions'

const requestOptions = {
  include_image_sizes: ['thumb_90x90', 'thumb_660x360'],
}

const modifyCaseStudiesSort = (fromIndex, toIndex, payload, caseStudyIds, setEntityState) => {
  const groupedIds = modifyGroupedIdsSort(fromIndex, toIndex, payload, caseStudyIds)

  return setEntityState({
    case_study_ids: groupedIds.case_study_ids,
  })
}

const toggleProjectCaseStudy = (caseStudyId, entityState, setEntityState) => {
  const { case_study_ids } = entityState
  const updatedCaseStudyIds = toggleArray(case_study_ids, caseStudyId)

  setEntityState({ case_study_ids: updatedCaseStudyIds })
}

const defaultState = {
  string: '',
}

function useManageCaseStudies(options = {}){
  const {
    owner_id, owner_type, performHttpRequests, project: initProject,
  } = options || {}

  const [state, setState] = useSetState(defaultState)
  const { string } = state

  const debouncedString = useDebounce(string, 300)

  const entityKey = `Organization${owner_id}${debouncedString}`

  useReduxAction(
    'caseStudies',
    'loadCaseStudies',
    {
      ...defaultRequestOptions.caseStudy,
      ...requestOptions,
      entityKey,
      full_address: debouncedString,
      owner_id,
      owner_type,
    },
    [JSON.stringify(options), debouncedString],
    {
      shouldPerformFn: ({ loadedForKeys }) => (!loadedForKeys.includes(entityKey) && performHttpRequests) || !!debouncedString,
    },
  )

  const entities = useSelector(reduxState => reduxState.entities)

  const { loading, loaded } = useSelector(reduxState => reduxState.caseStudies)

  const projectPayload = useProject(initProject)
  const { project } = projectPayload

  const projectFormPayload = useProjectForm(project)
  const {
    entityState,
    entityState: { case_study_ids },
    setEntityState,
  } = projectFormPayload

  const filterUnselectedCaseStudies = filterUnselectedEntities(entities, 'caseStudies', case_study_ids)
  const filteredCaseStudies = filterUnselectedCaseStudies.filter(caseStudy => !caseStudy?.hidden)

  return {
    callbacks: {
      modifyCaseStudiesSort,
      setState,
      toggleProjectCaseStudy: caseStudyId => toggleProjectCaseStudy(caseStudyId, entityState, setEntityState),
    },
    entities,
    filteredCaseStudies,
    loaded,
    loading,
    projectPayload,
    projectFormPayload,
    string,
  }
}

export default useManageCaseStudies
