const defaultRequestOptions = {
  digitalTemplate: {
    include_digital_template_page_ids: true,
    include_digital_template_permission_ids: true,
    include_digital_template_preview_image: true,
    include_digital_template_type: true,
  },
  image: {
    include_image_sizes: ['thumb_660x360'],
  },
}

export default defaultRequestOptions
