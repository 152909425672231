import isValidPrice from '@functions/isValidPrice'

export type QuoteItemModel = {
  cache_key: number,
  id: number,
  data: {},
  dates:{
    created: {
      date_time_with_timezone: string,
    },
    item_date: {
      date_time_with_timezone: string,
    },
    updated: {
      date_time_with_timezone: string,
    },
  },
  description: string,
  item_date: string,
  price: string,
  quote_id: number,
  quote_section_id: number,
  quote_total?: number,
  selected: boolean,
  sort: number,
  title: string,
}

export type QuoteItemRequestOptions = {
  include_quote_item_quote_total?: boolean,
}

const state = {
  description: '',
  id: null,
  price: '',
  price_ex_tax: '',
  quote_id: null,
  quote_section_id: null,
  item_date: '',
  selected: true,
  sort: null,
  title: '',
}

export const requiredFields = [
  { key: 'price', validation: isValidPrice, invalidMessage: 'Invalid Price format' },
  { key: 'quote_id' },
  { key: 'quote_section_id' },
  { key: 'title' },
]

export default state
