type GenerateRedirectUrlOptions = {
  append?: string,
  fullHref?: string,
}

function generateRedirectUrl(options: GenerateRedirectUrlOptions = {}) {
  if (!window) return null

  const { append, fullHref } = options || {}

  const {
    location: {
      hash, href, pathname, search,
    },
  } = window

  let fullPath = pathname + search + hash

  if (append){
    fullPath += fullPath.includes('?') ? `&${append}` : `?${append}`
  }

  // Just use full href
  // i.e. http://localhost:3001/#/admin/integrations
  if (fullHref) fullPath = href

  return encodeURIComponent(fullPath)
}

export default generateRedirectUrl
