import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'

import {
  Link, SidebarModal,
} from '@campaignhub/suit-theme'

import { useRelations } from '@hooks/useUser'

const User = (props) => {
  const {
    callbacks: { deleteUser },
    disableDelete,
    isLastUser,
    user,
  } = props
  const { full_name, id, mobile } = user

  const { defaultImage } = useRelations(user)
  const imageUrl = digObject(defaultImage, 'sizes.thumb_90x90', '')

  return (
    <SidebarModal.User
      boxProps={{ paddingBottom: isLastUser ? 'none' : 'large' }}
      disableHover
      imageUrl={imageUrl}
      key={id}
      subtitle={mobile}
      title={full_name}
    >
      {!disableDelete && (
        <Link
          greyLink
          onClick={() => deleteUser(id)}
          textProps={{ fontSize: 'small' }}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </Link>
      )}
    </SidebarModal.User>
  )
}

User.propTypes = {
  callbacks: PropTypes.shape({
    deleteUser: PropTypes.func.isRequired,
  }).isRequired,
  disableDelete: PropTypes.bool.isRequired,
  isLastUser: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
}

export default User
