export default {
  default: {
    createButton: 'Create',
    title: 'Calendar Templates',
    filtersTitle: 'Filters',
    filters: {
      title: 'Filter by Title',
    },
  },
}
