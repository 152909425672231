import { useForm, useLatestEntity } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import useDispatch from '@hooks/useDispatch'

import getPermittedTags from '@functions/getPermittedTags'

import * as tagActions from '@redux/modules/tag'

import defaultFormState, { requiredFields } from '@models/tag'

import type { AppDispatch } from '@redux/store'
import type { DeleteParams } from '@redux/modules/types'
import type { TagModel, TagRequestOptions } from '@models/types'

type SelectTagParams = {
  dispatch: AppDispatch,
  requestOptions?: TagRequestOptions,
  tag: Partial<TagModel>,
  tagParams: {
    selectedTagKey: string,
    tagEntity: string,
  },
}

const selectTag = (params: SelectTagParams) => {
  const {
    dispatch, requestOptions, tag, tagParams,
  } = params
  const { selectedTagKey, tagEntity } = tagParams

  const { createTag: createFn } = tagActions

  const permittedTags = getPermittedTags(tagEntity)

  const updatedTag = {
    ...tag,
    key: permittedTags.find(tags => tags.key === selectedTagKey)?.key || '',
    title: permittedTags.find(tags => tags.key === selectedTagKey)?.value || '',
  }

  return dispatch(createFn(updatedTag, requestOptions))
}

type DeleteTagParams = {
  dispatch: AppDispatch,
  tag: DeleteParams<TagModel>,
}

const deleteTag = (params: DeleteTagParams) => {
  const { dispatch, tag } = params
  const { deleteTag: deleteFn } = tagActions

  return dispatch(deleteFn(tag))
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useTagForm(
  tag: Partial<TagModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const tagForm = useForm(
    defaultFormState,
    { entity: tag, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [tag.id],
  )

  return {
    ...tagForm,
  }
}

function useTag(initEntity: Partial<TagModel> = {}) {
  const { entity: tag }: { entity: TagModel} = useLatestEntity(initEntity, 'tags')

  const dispatch = useDispatch()

  return {
    callbacks: {
      deleteTag: () => deleteTag({ dispatch, tag }),
      selectTag: (
        tagParams: {
          selectedTagKey: string,
          tagEntity: string,
        },
        entityOptions?: TagRequestOptions,
      ) => (
        selectTag({
          dispatch, requestOptions: entityOptions, tag, tagParams, 
        })
      ),
    },
    tag,
  }
}

export default useTag
