import React, { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import PropTypes from 'prop-types'

import { faGlobe } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Columns, DashboardModule, LoadingModule, PageHeader,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'
import useBrands from '@hooks/useBrands'

import PageFilterModule from '@components/PageFilterModule'

import BrandListItem from '../BrandListItem'
import BrandBlankState from '../BrandsBlankState'

const buildPageFilterFields = () => {
  const style = {
    marginBottom: 16,
  }

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        style,
      },
      key: 'string',
      label: 'Filter by Title',
    },
    {
      entityKey: 'limit',
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: '100',
        inputComponent: 'select',
        style,
      },
      key: 'limit',
      label: 'Limit',
      values: [
        { key: 150, label: '150' },
        { key: 200, label: '200' },
      ],
    },
  ]
}

const PageContent = (props) => {
  const { pageFilters } = props

  const useBrandsPayload = useBrands({ filters: pageFilters, performHttpRequests: true })
  const {
    callbacks: { loadMore },
    canLoadMore,
    filteredBrands,
    hasBrands,
    loading,
  } = useBrandsPayload

  const pageContext = useContext(PageContext)
  const {
    callbacks: { resetFilters, showCreateBrandModal, updateFilters },
  } = pageContext

  return (
    <>
      <Helmet>
        <title>Brands | Engage</title>
      </Helmet>

      <PageHeader
        actionContent={(
          <Button buttonStyle="primaryCreate" onClick={showCreateBrandModal} size="medium">
            Create
          </Button>
        )}
        activeTabBarItemKey="brands"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        tabBarItems={[
          {
            href: '#/systemManager/brands',
            icon: faGlobe,
            key: 'brands',
            title: 'Brands',
          },
        ]}
        title="Brands"
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!hasBrands && !loading && <BrandBlankState />}

            {!loading && hasBrands && (
              <DashboardModule title="Brands">
                <Box flexDirection="column">
                  <Box flexDirection="column">
                    {filteredBrands.map(brand => (
                      <BrandListItem key={brand.id} brand={brand} />
                    ))}
                  </Box>

                  <DashboardModule.LoadMoreFooter
                    callbacks={{ loadMore }}
                    canLoadMore={canLoadMore}
                    entityCount={filteredBrands.length}
                    loading={loading}
                  />
                </Box>
              </DashboardModule>
            )}
          </Columns.Main>

          <Columns.Sidebar>
            <PageFilterModule
              callbacks={{ resetFilters, updateFilters }}
              filterFields={buildPageFilterFields()}
              pageFilters={pageFilters}
              title="Filters"
            />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

PageContent.propTypes = {
  pageFilters: PropTypes.object.isRequired,
}

export default PageContent
