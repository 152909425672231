import { useMemo } from 'react'

import { matchFilterArrayIncludes, matchFilterString, sortArrayBy } from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { CaseStudyModel, CaseStudyRequestOptions } from '@models/types'
import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['caseStudies']

type CaseStudyFilters = {
  full_address?: string,
  hidden?: boolean,
  owner_id?: number,
  owner_type?: string,
  user_id?: number,
}

type UseCaseStudiesOptions = {
  filters?: CaseStudyFilters,
  performHttpRequests?: boolean,
  requestOptions?: CaseStudyRequestOptions,
}

function useCaseStudies(options: UseCaseStudiesOptions) {
  const { filters, requestOptions } = options
  const {
    full_address: filterFullAddress,
    hidden: filterHidden,
    owner_id: filterOwnerId,
    owner_type: filterOwnerType,
    user_id: filterUserId,
  } = filters || {}

  const {
    updatedEntities: { caseStudies: caseStudiesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { addresses, caseStudies, projects } = useSelector(reduxState => reduxState.entities)

  const filteredCaseStudies = useMemo(() => {
    const filtered = Object.values(caseStudies).filter((caseStudy: CaseStudyModel) => {
      const caseStudyAddress = addresses[caseStudy.address] || {}

      const project = caseStudy?.subject_type === 'Project' ? projects[caseStudy?.subject_id] || {} : {}
      const projectAddress = addresses[project.address_id] || {}

      const caseStudyFullAddress = caseStudyAddress.full_address || projectAddress.full_address

      const addressMatch = matchFilterString(caseStudyFullAddress, filterFullAddress)
      const matchHidden = filterHidden === caseStudy?.hidden
      const ownerMatch = caseStudy.owner_id === filterOwnerId && caseStudy.owner_type === filterOwnerType
      const userIdMatch = matchFilterArrayIncludes(caseStudy.user_ids, Number(filterUserId))

      return addressMatch && ownerMatch && matchHidden && userIdMatch
    })

    return sortArrayBy(filtered, 'asc', 'title')
  }, [caseStudiesUpdatedAt, JSON.stringify(filters)])

  const filteredCaseStudiesCount = filteredCaseStudies.length
  const hasFilteredCaseStudies = !!filteredCaseStudiesCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredCaseStudiesCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingCaseStudies } = useReduxAction(
    'caseStudies',
    'loadCaseStudies',
    {
      ...requestOptions,
      ...filtersWithOffset,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredCaseStudies,
    filteredCaseStudiesCount,
    hasCaseStudies: hasFilteredCaseStudies,
    loading: loadingCaseStudies,
  }
}

export default useCaseStudies
