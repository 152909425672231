import React from 'react'
import PropTypes from 'prop-types'

import {
  Button,
  FormField,
  Link,
  LoadingModule,
  SectionDivider,
  SidebarModal,
  SidebarNoticeBox,
} from '@campaignhub/suit-theme'

import useCurrentUser from '@hooks/useCurrentUser'
import useLocalization from '@hooks/useLocalization'

import EntitySelector from '@components/EntitySelector'
import IntegrationsBlankState from '@components/IntegrationsBlankState'

import User from './components/User'
import localizedStrings from './localizedStrings'
import useImportUsers from './hooks/useImportUsers'

const ImportUsersModal = (props) => {
  const { callbacks, modalKey, showModal } = props
  const { closeModal, importUsers } = callbacks

  const {
    alreadyImportedExternalIds,
    callbacks: {
      bulkImportFromExternalPlatform, selectOrganizationId, setState, toggleAllUsers, toggleUser,
    },
    externalUsers,
    filteredPlatforms,
    hasExternalUsers,
    hasIntegrations,
    hasSelectedUsers,
    integrationsUrls: { integrationsUrl },
    loading,
    selectedId,
    selectedIntegrationPlatform,
    selectedIntegrationPlatformKey,
    selectedOrganization,
    selectedUserIds,
    sortedOrganizationsWhereAdmin,
    string,
  } = useImportUsers()

  const { isAdmin } = useCurrentUser()

  const {
    callbacks: { formatString },
    strings,
  } = useLocalization(localizedStrings)

  const importUsersPayload = {
    callbacks: {
      action: bulkImportFromExternalPlatform,
      afterAction: closeModal,
    },
    entityParams: { externalIds: selectedUserIds, organizationId: selectedOrganization.id },
    toastText: strings.toast?.importUsers || 'Import Users Queued',
  }

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.title || 'Import'}
        titleSecondLine={strings.titleSecondLine || 'Users'}
      />

      <SidebarModal.Content>
        <LoadingModule loading={loading && !hasIntegrations} />

        {!loading && !hasIntegrations && (
          <IntegrationsBlankState
            buttonUrl={integrationsUrl}
            paragraph={
              strings?.blankState?.paragraph
              || 'To import users from another platform you’ll need to set up a suitable integration. Integrations can be managed in your client admin.'
            }
            sidebar
          />
        )}

        {hasIntegrations && (
          <>
            {!isAdmin && (
              <FormField
                boxProps={{ marginBottom: 'large' }}
                direction="column"
                label={strings.importUserIntoLabel || 'Importing Users into'}
              >
                <select
                  data-validate-field-on="change"
                  name="selected_organization_id"
                  onChange={e => selectOrganizationId(e.target.value)}
                  value={selectedOrganization.id}
                >
                  <option value="">{strings.formFieldOption || 'Please Select...'}</option>
                  {sortedOrganizationsWhereAdmin.map(organization => (
                    <option key={organization.id} value={organization.id}>
                      {organization.title}
                    </option>
                  ))}
                </select>
              </FormField>
            )}

            {isAdmin && (
              <EntitySelector
                boxProps={{ marginBottom: 'large' }}
                callbacks={{
                  selectItem: organization => selectOrganizationId(organization ? organization.id : null),
                }}
                entityKey="organizations"
                selectedItemId={selectedId}
              />
            )}

            <FormField direction="column" label={strings.platformLabel || 'Platform'}>
              <select
                data-validate-field-on="change"
                onChange={e => setState({ selectedIntegrationPlatformKey: e.target.value })}
                value={selectedIntegrationPlatformKey}
              >
                <option value="">{strings.options || 'Please Select...'}</option>
                {filteredPlatforms.map(entity => (
                  <option key={entity.id} value={entity.key}>
                    {entity.title}
                  </option>
                ))}
              </select>
            </FormField>

            <FormField direction="column" label={strings.searchUsersLabel || 'Search Users'} marginTop="large">
              <input onChange={e => setState({ string: e.target.value })} value={string} />
            </FormField>

            {/* Loading External Users */}
            <LoadingModule boxProps={{ marginTop: 'large' }} loading={loading} />

            {!loading && (
              <SectionDivider boxProps={{ marginTop: 'large' }}>
                {strings.availableUsers || 'Available Users'}
              </SectionDivider>
            )}

            {!loading && !hasExternalUsers && (
              <SidebarNoticeBox boxProps={{ marginTop: 'large' }}>
                <SidebarNoticeBox.Title>{strings.noticeBox?.title || 'Nothing to Import'}</SidebarNoticeBox.Title>

                <SidebarNoticeBox.Paragraph>
                  {formatString(strings.noticeBox?.paragraph, {
                    integrationPlatformTitle: selectedIntegrationPlatform.title || 'External Platform',
                  })}
                </SidebarNoticeBox.Paragraph>
              </SidebarNoticeBox>
            )}

            {!loading && hasExternalUsers && (
              <FormField
                direction="column"
                label={strings.selectUsersLabel || 'Select Users'}
                labelRight={(
                  <Link onClick={toggleAllUsers} textProps={{ fontSize: 'xsmall' }}>
                    {hasSelectedUsers
                      ? strings.deselectAllButton || 'Deselect All'
                      : strings.selectAllButton || 'Select All'}
                  </Link>
                )}
                marginTop="large"
              >
                {externalUsers.map((user) => {
                  const { id } = user

                  return (
                    <User
                      alreadyImported={alreadyImportedExternalIds.includes(id)}
                      callbacks={{ toggleUser: () => toggleUser(id) }}
                      checked={selectedUserIds.includes(id)}
                      key={id}
                      user={user}
                    />
                  )
                })}
              </FormField>
            )}
          </>
        )}
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!hasSelectedUsers}
          onClick={() => importUsers(importUsersPayload)}
          size="large"
        >
          {strings.importUsersButton || 'Import Users'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

ImportUsersModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

ImportUsersModal.defaultProps = {
  modalKey: 'ImportUsersModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <ImportUsersModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
