import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities } from '@redux/entities'

import api from '@functions/api'

import type { CustomFieldModel, UserModel } from '@models/types'
import type { AppDispatch } from '@redux/store'
import type {
  Action, EntityOptions, Errors, FetchRequestPayload, RootReducerState, UpdateParams,
} from '@redux/modules/types'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/user/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/user/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/user/FETCH_FAILURE'

const UPDATE_REQUEST = 'realbase/user/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realbase/user/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realbase/user/UPDATE_FAILURE'

export type UserModuleState = {
  creating: boolean,
  errors: string[],
  loaded: boolean,
  loadedForKeys: string[],
  loadedIds: number[],
  loading: boolean,
  updating: boolean,
}

// Initial State
const initialState: UserModuleState = {
  creating: false,
  errors: [],
  loaded: false,
  loadedIds: [],
  loadedForKeys: [],
  loading: false,
  updating: false,
}

// Actions
export function fetchRequest(payload: FetchRequestPayload = {}) {
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess() {
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors: Errors = []) {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function updateRequest() {
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess() {
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors: Errors = []) {
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

// Action Creators
export function hydrateUser(userJson: UserModel) {
  return (dispatch: AppDispatch) => {
    if (userJson.id){
      const normalizedJson = normalize(userJson, Schemas.USER)
      dispatch(updateEntities(normalizedJson))
      return { success: true as const, data: userJson }
    }

    return { success: false, errors: ['Could not hydrate user without ID'] }
  }
}

export function loadUsers(options: EntityOptions = {}) {
  const { entityKey } = options

  return (dispatch: AppDispatch, getState: () => RootReducerState) => {
    const loadedForKeys = [...getState().users.loadedForKeys]

    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('/users.json', options)
      .then(({ data }: { data: { entities: UserModel[] } }) => {
        const normalizedJson = normalize(data.entities, Schemas.USER_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function loadUser(userId: number, options: EntityOptions = {}) {
  const { entityKey } = options

  return (dispatch: AppDispatch, getState: () => RootReducerState) => {
    const state = getState()
    const loadedIds = [...state.users.loadedIds]
    const loadedForKeys = [...state.users.loadedForKeys]

    if (userId && !loadedIds.includes(userId)){
      loadedIds.push(userId)
    }

    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    // Set Loading
    dispatch(fetchRequest({ loadedForKeys, loadedIds }))

    const promise = api(`/users/${userId}.json`, options)
      .then(({ data }: { data: { entity: UserModel } }) => {
        const normalizedJson = normalize(data.entity, Schemas.USER)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function loadUserOnboardingTasks(user: UserModel, options: EntityOptions = {}) {
  return (dispatch: AppDispatch) => {
    dispatch(fetchRequest())

    const promise = api(`/users/${user.id}/onboarding_tasks.json`, options)
      .then(({ data }) => {
        dispatch(fetchSuccess())

        return { success: true as const, data: data.data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function updateUser(
  user: UpdateParams<UserModel>,
  dataStoreItemValues: CustomFieldModel[],
  options: EntityOptions = {},
) {
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      data_store_item_values: dataStoreItemValues || [],
      user,
    }),
  }

  return (dispatch: AppDispatch) => {
    dispatch(updateRequest())

    const promise = api(`/users/${user.id}.json`, options, config)
      .then(({ data }: { data: { entity: UserModel } }) => {
        const normalizedJson = normalize(data.entity, Schemas.USER)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function generateSupportToken(user: UserModel, options: EntityOptions = {}) {
  const config = {
    method: 'PUT',
  }

  return (dispatch: AppDispatch) => {
    dispatch(updateRequest())

    const promise = api(`/users/${user.id}/generate_support_token.json`, options, config)
      .then(({ data }: { data: { entity: UserModel } }) => {
        const normalizedJson = normalize(data.entity, Schemas.USER)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function revokeSupportToken(user: UserModel, options: EntityOptions = {}) {
  const config = {
    method: 'PUT',
  }

  return (dispatch: AppDispatch) => {
    dispatch(updateRequest())

    const promise = api(`/users/${user.id}/revoke_support_token.json`, options, config)
      .then(({ data }: { data: { entity: UserModel } }) => {
        const normalizedJson = normalize(data.entity, Schemas.USER)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function requestSupportToken(user: UserModel, options: EntityOptions = {}) {
  const config = {
    method: 'POST',
  }

  return (dispatch: AppDispatch) => {
    dispatch(updateRequest())

    const promise = api(`/users/${user.id}/request_support_token.json`, options, config)
      .then(({ data }) => {
        dispatch(updateSuccess())

        return { success: true as const, data: data.data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function supplySupportToken(supportUser: UserModel, options: EntityOptions = {}) {
  const config = {
    method: 'POST',
  }

  return (dispatch: AppDispatch) => {
    dispatch(updateRequest())

    const promise = api(`/users/${supportUser.id}/supply_support_token.json`, options, config)
      .then(({ data }) => {
        dispatch(updateSuccess())

        return { success: true as const, data: data.data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function loadFromExternalPlatform(externalPlatformKey: string, options: EntityOptions = {}) {
  return () => {
    const promise = api(`/users/fetch_from_external_platform/${externalPlatformKey}.json`, options)
      .then(({ data }) => ({ success: true as const, data: data.data }))
      .catch((data) => {
        const errors = handleError(data)
        return { success: false as const, errors }
      })

    return promise
  }
}

type Payload = {
  externalIds: number[],
  organizationId: number,
}

export function bulkImportFromExternalPlatform(
  externalPlatformKey: string,
  payload: Payload,
  options: EntityOptions = {},
) {
  const { externalIds, organizationId } = payload

  const config = {
    method: 'POST',
    body: JSON.stringify({
      external_ids: externalIds,
      organization_id: organizationId,
    }),
  }

  return () => {
    const promise = api(`/users/bulk_import_from_external_platform/${externalPlatformKey}.json`, options, config)
      .then(({ data }) => ({ success: true as const, data: data.data }))
      .catch((data) => {
        const errors = handleError(data)
        return { success: false as const, errors }
      })

    return promise
  }
}

export function impersonateUser(supportToken: string, options: EntityOptions = {}) {
  const config = {
    method: 'PUT',
  }

  return (dispatch: AppDispatch) => {
    dispatch(updateRequest())

    const promise = api(`/users/impersonate_user/${supportToken}.json`, options, config)
      .then(({ data }: { data: { entity: UserModel } }) => {
        const normalizedJson = normalize(data.entity, Schemas.USER)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function unlinkImpersonation(options: EntityOptions = {}) {
  const config = {
    method: 'PUT',
  }

  return (dispatch: AppDispatch) => {
    dispatch(updateRequest())

    const promise = api('/users/unlink_impersonation.json', options, config)
      .then(({ data }: { data: { entity: UserModel } }) => {
        const normalizedJson = normalize(data.entity, Schemas.USER)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(
  state: UserModuleState = initialState,
  action: Action = { type: '' },
): UserModuleState {
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loadedIds: action.loadedIds || state.loadedIds,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors || [],
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors || [],
      }
    default:
      return state
  }
}
