export default {
  default: {
    customFields: {
      type: 'count',
      none: 'No Fields',
      one: '{count} Field',
      many: '{count} Fields',
    },
  },
}
