import React from 'react'
import PropTypes from 'prop-types'

const DigitalPageIcon = (props) => {
  const { fillColor, height, width } = props

  return (
    <svg width={width} height={height} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M43.1406 37.6406H42.5391V15.8135C42.5391 13.5019 40.7038 11.6116 38.4141 11.521V0.859375C38.4141 0.384705 38.0294 0 37.5547 0H6.44531C5.97064 0 5.58594 0.384705 5.58594 0.859375V11.521C3.29617 11.6116 1.46094 13.5019 1.46094 15.8135V37.6406H0.859375C0.384705 37.6406 0 38.0253 0 38.5V39.7031C0 42.0724 1.92755 44 4.29688 44H39.7031C42.0724 44 44 42.0724 44 39.7031V38.5C44 38.0253 43.6153 37.6406 43.1406 37.6406ZM40.8203 15.8135V37.6406H38.4141V13.2418C39.7558 13.3307 40.8203 14.4499 40.8203 15.8135ZM7.30469 20.1094H36.6953V37.6406H7.30469V20.1094ZM36.6953 1.71875V4.64062H25.9246C25.4499 4.64062 25.0652 5.02533 25.0652 5.5C25.0652 5.97467 25.4499 6.35938 25.9246 6.35938H36.6953V18.3906H7.30469V6.35938H17.9035C18.3782 6.35938 18.7629 5.97467 18.7629 5.5C18.7629 5.02533 18.3782 4.64062 17.9035 4.64062H7.30469V1.71875H36.6953ZM3.17969 15.8135C3.17969 14.4499 4.24417 13.3307 5.58594 13.2418V37.6406H3.17969V15.8135ZM42.2812 39.7031C42.2812 41.1248 41.1248 42.2812 39.7031 42.2812H4.29688C2.87521 42.2812 1.71875 41.1248 1.71875 39.7031V39.3594H42.2812V39.7031Z"
        fill={fillColor}
      />
      <path
        d="M23.5468 39.9609H20.446C19.9714 39.9609 19.5867 40.3456 19.5867 40.8203C19.5867 41.295 19.9714 41.6797 20.446 41.6797H23.5468C24.0215 41.6797 24.4062 41.295 24.4062 40.8203C24.4062 40.3456 24.0215 39.9609 23.5468 39.9609Z"
        fill={fillColor}
      />
      <path
        d="M32.0483 14.3586C32.2161 14.5265 32.436 14.6104 32.6559 14.6104C32.8758 14.6104 33.096 14.5265 33.2635 14.3586L34.6395 12.9826C34.9752 12.6469 34.9752 12.1031 34.6395 11.7674L33.2635 10.3914C32.9278 10.0557 32.384 10.0557 32.0483 10.3914C31.7126 10.7267 31.7126 11.2709 32.0483 11.6066L32.8167 12.375L32.0483 13.1434C31.7126 13.4791 31.7126 14.0233 32.0483 14.3586V14.3586Z"
        fill={fillColor}
      />
      <path
        d="M11.9517 10.3914C11.616 10.0557 11.0718 10.0557 10.7365 10.3914L9.36047 11.7674C9.02478 12.1031 9.02478 12.6469 9.36047 12.9826L10.7365 14.3586C10.9043 14.5265 11.1242 14.6104 11.3441 14.6104C11.564 14.6104 11.7838 14.5265 11.9517 14.3586C12.2874 14.0233 12.2874 13.4791 11.9517 13.1434L11.1833 12.375L11.9517 11.6066C12.2874 11.2709 12.2874 10.7267 11.9517 10.3914Z"
        fill={fillColor}
      />
      <path
        d="M28.5826 28.1875H33.2233C33.6979 28.1875 34.0826 27.8028 34.0826 27.3281V22.6875C34.0826 22.2128 33.6979 21.8281 33.2233 21.8281H28.5826C28.108 21.8281 27.7233 22.2128 27.7233 22.6875V27.3281C27.7233 27.8028 28.108 28.1875 28.5826 28.1875ZM29.442 23.5469H32.3639V26.4688H29.442V23.5469Z"
        fill={fillColor}
      />
      <path
        d="M19.6223 28.1875H24.2629C24.7376 28.1875 25.1223 27.8028 25.1223 27.3281V22.6875C25.1223 22.2128 24.7376 21.8281 24.2629 21.8281H19.6223C19.1476 21.8281 18.7629 22.2128 18.7629 22.6875V27.3281C18.7629 27.8028 19.148 28.1875 19.6223 28.1875V28.1875ZM20.4817 23.5469H23.4036V26.4688H20.4817V23.5469Z"
        fill={fillColor}
      />
      <path
        d="M15.4174 21.8281H10.7767C10.3021 21.8281 9.91736 22.2128 9.91736 22.6875V27.3281C9.91736 27.8028 10.3021 28.1875 10.7767 28.1875H15.4174C15.892 28.1875 16.2767 27.8028 16.2767 27.3281V22.6875C16.2767 22.2128 15.892 21.8281 15.4174 21.8281ZM14.558 26.4688H11.6361V23.5469H14.558V26.4688Z"
        fill={fillColor}
      />
      <path
        d="M15.4174 30.25H10.7767C10.3021 30.25 9.91736 30.6347 9.91736 31.1094C9.91736 31.584 10.3021 31.9688 10.7767 31.9688H15.4174C15.892 31.9688 16.2767 31.584 16.2767 31.1094C16.2767 30.6347 15.892 30.25 15.4174 30.25Z"
        fill={fillColor}
      />
      <path
        d="M24.3314 30.25H19.6908C19.2161 30.25 18.8314 30.6347 18.8314 31.1094C18.8314 31.584 19.2161 31.9688 19.6908 31.9688H24.3314C24.8058 31.9688 25.1908 31.584 25.1908 31.1094C25.1908 30.6347 24.8058 30.25 24.3314 30.25Z"
        fill={fillColor}
      />
      <path
        d="M33.2451 30.25H28.6044C28.1298 30.25 27.7451 30.6347 27.7451 31.1094C27.7451 31.584 28.1298 31.9688 28.6044 31.9688H33.2451C33.7197 31.9688 34.1044 31.584 34.1044 31.1094C34.1044 30.6347 33.7197 30.25 33.2451 30.25Z"
        fill={fillColor}
      />
      <path
        d="M15.4174 33.6875H10.7767C10.3021 33.6875 9.91736 34.0722 9.91736 34.5469C9.91736 35.0215 10.3021 35.4062 10.7767 35.4062H15.4174C15.892 35.4062 16.2767 35.0215 16.2767 34.5469C16.2767 34.0722 15.892 33.6875 15.4174 33.6875Z"
        fill={fillColor}
      />
      <path
        d="M24.3314 33.6875H19.6908C19.2161 33.6875 18.8314 34.0722 18.8314 34.5469C18.8314 35.0215 19.2161 35.4062 19.6908 35.4062H24.3314C24.8058 35.4062 25.1908 35.0215 25.1908 34.5469C25.1908 34.0722 24.8058 33.6875 24.3314 33.6875Z"
        fill={fillColor}
      />
      <path
        d="M33.2451 33.6875H28.6044C28.1298 33.6875 27.7451 34.0722 27.7451 34.5469C27.7451 35.0215 28.1298 35.4062 28.6044 35.4062H33.2451C33.7197 35.4062 34.1044 35.0215 34.1044 34.5469C34.1044 34.0722 33.7197 33.6875 33.2451 33.6875Z"
        fill={fillColor}
      />
      <path
        d="M22 6.35938C22.2259 6.35938 22.4478 6.2674 22.6076 6.1076C22.7674 5.94782 22.8594 5.72592 22.8594 5.5C22.8594 5.27408 22.7674 5.05218 22.6076 4.8924C22.4478 4.7326 22.2259 4.64062 22 4.64062C21.7741 4.64062 21.5522 4.7326 21.3924 4.8924C21.2326 5.05218 21.1406 5.27408 21.1406 5.5C21.1406 5.72592 21.2326 5.94782 21.3924 6.1076C21.5522 6.2674 21.7741 6.35938 22 6.35938Z"
        fill={fillColor}
      />
    </svg>
  )
}

DigitalPageIcon.propTypes = {
  fillColor: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
}

DigitalPageIcon.defaultProps = {
  fillColor: '#000000',
  height: 40,
  width: 40,
}

export default DigitalPageIcon
