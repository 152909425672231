import { useSelector } from 'react-redux'

import { useLatestEntity, useThunkDispatch } from '@campaignhub/react-hooks'

import { baseURL } from '@functions/api'

import * as digitalPageViewActions from '@redux/modules/digitalPageView'

const createDigitalPageView = (digitalPageView, dispatch, requestOptions) => {
  const { createDigitalPageView: createFn } = digitalPageViewActions
  return dispatch(createFn(digitalPageView, requestOptions))
}

const attachAnalyticsData = (digitalPageView, params, token) => {
  const url = `${baseURL}/digital_page_views/${digitalPageView.id}/attach_analytics_data.json`

  const beaconPayload = {
    digital_page_view: {
      ...params,
    },
    token,
  }

  navigator.sendBeacon(url, JSON.stringify(beaconPayload))
}

export function useRelations(digitalPageView){
  const {
    digital_page_id,
    owner_id,
    owner_type,
    share_link_id,
  } = digitalPageView || {}

  const entities = useSelector(reduxState => reduxState.entities)
  const {
    digitalPages,
    organizations,
    shareLinks,
  } = entities

  const digitalPage = digitalPages[digital_page_id] || {}
  const organization = owner_type === 'Organization' ? organizations[owner_id] || {} : {}
  const shareLink = shareLinks[share_link_id] || {}

  return {
    digitalPage,
    organization,
    shareLink,
  }
}

function useDigitalPageView(initEntity){
  const { entity: digitalPageView } = useLatestEntity(initEntity, 'digitalPageViews')

  const dispatch = useThunkDispatch()

  return {
    callbacks: {
      attachAnalyticsData: (params, token) => attachAnalyticsData(digitalPageView, params, token),
      createDigitalPageView: (params, requestOptions) => createDigitalPageView(params, dispatch, requestOptions),
    },
    digitalPageView,
  }
}

export default useDigitalPageView
