import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities } from '@redux/entities'
import { deepSetObject } from '@campaignhub/javascript-utils'

import api from '@functions/api'

import { handleError } from '../utils'

const UPDATE_REQUEST = 'realbase/digitalTemplatesOrganization/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realbase/digitalTemplatesOrganization/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realbase/digitalTemplatesOrganization/UPDATE_FAILURE'

// Initial State
const initialState = {
  updating: false,
  errors: [],
}

// Actions
export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

// Action Creators
export function bulkUpdateIds(digitalTemplate, organizationIds, options){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      organization_ids: organizationIds,
    }),
  }

  return (dispatch, getState) => {
    // Set Loading
    dispatch(updateRequest())

    const promise = api(`/digital_templates/${digitalTemplate.id}/update_permissions.json`, options, config)
      .then(({ data }) => {
        const state = getState()
        const templateCopy = { ...state.entities.digitalTemplates[digitalTemplate.id] }
        const updatedIds = data.entities.map(digitalTemplateOrganization => digitalTemplateOrganization.organization_id)
        const updatedTemplate = deepSetObject(templateCopy, 'permission_ids.organization_ids', updatedIds)

        const normalizedDigitalTemplate = normalize(updatedTemplate, Schemas.DIGITAL_TEMPLATE)
        dispatch(updateEntities(normalizedDigitalTemplate))
        dispatch(updateSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case UPDATE_REQUEST:
      return { ...state, updating: true }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
