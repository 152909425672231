import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { faGlobe, faPencil } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Columns, DashboardModule, Heading, LoadingModule, PageHeader,
} from '@campaignhub/suit-theme'

import CustomFieldsContext from '@contexts/customFieldsContext'

import SaveFormModule from '@components/SaveFormModule'

import useCustomFieldSet from '@hooks/useCustomFieldSet'
import useEntityTypeCustomFields from '@hooks/useEntityTypeCustomFields'
import useLocalization from '@hooks/useLocalization'
import useNumericParams from '@hooks/useNumericParams'
import useReduxAction from '@hooks/useReduxAction'

import defaultRequestOptions from '@sections/Client/packs/Admin/defaultRequestOptions'

import CustomField from '../CustomField'

import localizedStrings from './localizedStrings'

const PageContent = (props) => {
  const { organization } = props

  const { customFieldSetId } = useNumericParams()

  useReduxAction('customFieldSets', 'loadCustomFieldSet', defaultRequestOptions.customFieldSet, [customFieldSetId], {
    dispatchAction: action => action(customFieldSetId, defaultRequestOptions.customFieldSet),
    shouldPerformFn: (entityReducer) => {
      const { loadedIds, loading } = entityReducer
      return customFieldSetId && !loadedIds.includes(customFieldSetId) && !loading
    },
  })

  const {
    customFieldSet,
    customFieldSet: { entity_type, key, title },
    loading,
    urls: { customFieldSetsIndexUrl, editCustomFieldSetUrl },
  } = useCustomFieldSet({ id: customFieldSetId })

  const customFieldPayload = useEntityTypeCustomFields(entity_type, organization, { excludeJson: true, key })
  const { sortedCustomFieldGroups } = customFieldPayload

  const {
    callbacks: { addCustomField },
  } = useContext(CustomFieldsContext)

  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      <PageHeader
        actionContent={(
          <Button
            buttonStyle="primaryCreate"
            onClick={() => addCustomField({
              customFieldSetEntityType: customFieldSet.entity_type,
              customFieldSetId: customFieldSet.id,
              tag: 'Custom Fields',
            })}
            size="medium"
          >
            <Box>{strings.buttons?.create || 'Create'}</Box>
          </Button>
        )}
        activeTabBarItemKey="edit-custom-field"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        heading={(
          <Heading textProps={{ variant: 'ellipsis' }}>
            {title || strings.pageHeader?.placeholder || 'Custom Field Sets'}
          </Heading>
        )}
        nestedNavigation
        tabBarItems={[
          {
            href: customFieldSetsIndexUrl,
            icon: faGlobe,
            key: 'custom-fields',
            title: strings.pageHeader?.title || 'Custom Fields',
          },
          {
            href: editCustomFieldSetUrl,
            icon: faPencil,
            key: 'edit-custom-field',
            title: title || strings.edit || 'Edit Custom Field',
          },
        ]}
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && (
              <>
                {sortedCustomFieldGroups.map((fieldGroup) => {
                  const {
                    id, items, label, tag,
                  } = fieldGroup

                  return (
                    <DashboardModule
                      headerRight={
                        addCustomField ? (
                          <DashboardModule.Link
                            onClick={() => addCustomField({
                              customFieldSetEntityType: customFieldSet.entity_type,
                              customFieldSetId: customFieldSet.id,
                              tag,
                            })}
                          >
                            {strings.buttons?.addField || 'Add Field to Group'}
                          </DashboardModule.Link>
                        ) : null
                      }
                      key={id}
                      title={label}
                    >
                      <Box flexDirection="column">
                        {items.map(customField => (
                          <CustomField
                            callbacks={{
                              createOrEditCustomField: () => addCustomField({
                                customFieldId: customField.id,
                                customFieldSetEntityType: customFieldSet.entity_type,
                                customFieldSetId: customFieldSet.id,
                                tag,
                              }),
                            }}
                            key={customField.id}
                            customField={customField}
                          />
                        ))}
                      </Box>
                    </DashboardModule>
                  )
                })}
              </>
            )}

            <SaveFormModule childrenLeft={strings.goBack || 'Back to Custom Field Sets'}>
              <Button
                as="a"
                buttonStyle="secondaryUtility"
                href={customFieldSetsIndexUrl}
                marginLeft="auto"
                size="medium"
                width="auto"
              >
                {strings.buttons?.goBack || 'Go Back'}
              </Button>
            </SaveFormModule>
          </Columns.Main>
        </Columns>
      </Box>
    </>
  )
}

PageContent.propTypes = {
  organization: PropTypes.object.isRequired,
}

export default PageContent
