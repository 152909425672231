import type { EntitiesState } from '@redux/entities'

export default function filterUnselectedEntities(
  entities: EntitiesState,
  entityType: string,
  entityIds: number[] = [],
) {
  const entitiesToFilter = entities[entityType] || {}

  return Object.values(entitiesToFilter).filter(entity => !entityIds.includes(entity.id))
}
