import { useContext } from 'react'
import PropTypes from 'prop-types'

import { faFile } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Columns, DashboardModule, LoadingModule, PageHeader,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import PageContext from '@contexts/pageContext'

import IntegrationPartner from '@components/IntegrationPartner'
import VideoModule from '@components/VideoModule'

import useAddress from '@hooks/useAddress'
import useAgreement from '@hooks/useAgreement'
import useAgreements from '@hooks/useAgreements'
import useIntegrations from '@hooks/useIntegrations'
import useLocalization from '@hooks/useLocalization'
import useProject from '@hooks/useProject'
import useUserIntegrations from '@hooks/useUserIntegrations'
import useVideoModules from '@hooks/useVideoModules'

import Agreement from '../Agreement'
import AgreementBlankState from '../AgreementBlankState'
import Contacts from '../Contacts'
import ExternalDocument from '../ExternalDocument'
import Users from '../Users'

import localizedStrings from './localizedStrings'

const PageContent = (props) => {
  const { project: initProject } = props

  const {
    callbacks: { manageProjectContacts, manageProjectUsers },
    filteredContacts,
    leadUsers,
    loading: loadingProject,
    project,
    project: { address_id },
    supportUsers,
    urls: { agreementsUrl },
  } = useProject(initProject)

  const entityKey = `Project${project.id}`

  const agreementsPayload = useAgreements({
    entityKey,
    performHttpRequests: true,
    subjectId: project.id,
    subjectType: 'Project',
  })
  const {
    agreementGroupKeys, groupedAgreements, hasAgreements, loading,
  } = agreementsPayload

  const addressPayload = useAddress({ id: address_id })
  const { address } = addressPayload
  const projectState = digObject(address, 'state_name', '').toLowerCase()

  const integrationsPayload = useIntegrations({
    featureKeys: ['create_forms', 'import_document_templates'],
    filters: {
      owner_id: project.organization_id,
      owner_type: 'Organization',
    },
  })

  const { hasIntegrations } = integrationsPayload

  const initAgreement = {
    owner_id: project.organization_id,
    owner_type: 'Organization',
    subject_id: project.id,
    subject_type: 'Project',
    title: project.title,
  }

  const useIntegrationsPayload = useUserIntegrations({ integrationPlatformKey: 'real_time_agent' })
  const { hasIntegrationForKey } = useIntegrationsPayload

  const { callbacks: { selectDocumentTemplate } } = useAgreement(initAgreement)

  const pageContext = useContext(PageContext)
  const {
    callbacks: { showCreateExternalFormModal },
  } = pageContext

  const videoModulePayload = useVideoModules({ key: 'projectAgreements' })
  const { videosPayload } = videoModulePayload

  const shouldShowIntegrationPartner = projectState !== 'wa' && projectState !== 'nt'
  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      <PageHeader
        actionContent={(
          <Button
            buttonStyle="primaryCreate"
            onClick={hasIntegrations ? showCreateExternalFormModal : selectDocumentTemplate}
            size="medium"
            width="auto"
          >
            {strings.buttons?.create || 'Create'}
          </Button>
        )}
        activeTabBarItemKey="Dashboard"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        nestedNavigation
        tabBarItems={[
          {
            href: agreementsUrl,
            icon: faFile,
            key: 'Dashboard',
            title: strings.title || 'Agreements',
          },
        ]}
        title={strings.title || 'Agreements'}
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && !hasAgreements && <AgreementBlankState />}

            {!loading
              && hasAgreements
              && agreementGroupKeys.map((groupKey) => {
                const agreements = groupedAgreements[groupKey]
                const AgreementComponent = groupKey === 'agreements' ? Agreement : ExternalDocument

                if (agreements.length){
                  return (
                    <DashboardModule key={groupKey} title={strings[groupKey] || 'Agreements'}>
                      <Box flexDirection="column">
                        {agreements.map(agreement => (
                          <AgreementComponent key={agreement.id} agreement={agreement} />
                        ))}
                      </Box>
                    </DashboardModule>
                  )
                }

                return null
              })}
          </Columns.Main>

          <Columns.Sidebar>
            <LoadingModule loading={loadingProject} times={3} />
            {!loadingProject && (
              <>
                <Users callbacks={{ manageProjectUsers }} leadUsers={leadUsers} supportUsers={supportUsers} />

                <Contacts callbacks={{ manageProjectContacts }} contacts={filteredContacts} />

                {!hasIntegrationForKey && shouldShowIntegrationPartner && (
                  <IntegrationPartner
                    // eslint-disable-next-line max-len
                    description={strings.integrationPartner?.description || 'Learn how to generate an agreement with RTA integration'}
                    integrationPlatformKey={strings.integrationPartner?.integrationPlatformKey || 'real_time_agent'}
                    title={strings.integrationPartner?.title || 'Sign up to Real Time Agent'}
                  />
                )}

                {hasIntegrationForKey && (
                  <VideoModule
                    marginTop={!hasIntegrationForKey && shouldShowIntegrationPartner && 'large'}
                    videosPayload={videosPayload}
                  />
                )}
              </>
            )}
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

PageContent.propTypes = {
  project: PropTypes.object.isRequired,
}

export default PageContent
