import { BlankState } from '@campaignhub/suit-theme'
import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

import blankActivitiesUrl from './assets/no-activity.svg'

interface ActivityBlankStateProps {
  sidebar?: boolean,
}

const ActivityBlankState = (props: ActivityBlankStateProps) => {
  const { sidebar } = props
  const { strings } : {strings: LocalizedStringsType} = useLocalization(localizedStrings)

  return (
    <BlankState
      imageUrl={blankActivitiesUrl}
      sidebar={sidebar}
    >
      <BlankState.Title>{strings.title || 'No Activity'}</BlankState.Title>

      <BlankState.Paragraph>
        {strings.paragraph || 'There is no activity for this {global.organizationType.shortName.singular}.'}
      </BlankState.Paragraph>
    </BlankState>
  )
}

ActivityBlankState.defaultProps = {
  sidebar: false,
}

export default ActivityBlankState
