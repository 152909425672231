export default {
  default: {
    noId: 'No External ID',
    swal: {
      confirmButtonText: 'Yes, Remove it.',
      title: 'Remove Platform?',
      text: 'Are you sure?',
    },
    unknownPlatform: 'Unknown Platform',
  },
}
