import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faSearch } from '@fortawesome/pro-light-svg-icons'

import {
  Box,
  Button,
  FormField,
  SectionDivider,
  SelectBox,
  SidebarModal,
  SidebarNoticeBox,
} from '@campaignhub/suit-theme'

import useIntegrationPlatforms from '@hooks/useIntegrationPlatforms'
import useLocalization from '@hooks/useLocalization'

import generateRedirectUrl from '@functions/generateRedirectUrl'

import useImportQuote from './hooks/useImportQuote'

import localizedStrings from './localizedStrings'

const importExternalQuoteIds = (params) => {
  const {
    callbacks,
    formatString,
    importFn,
    project,
    selectedExternalIds,
    selectedIntegrationPlatformKey,
    strings,
  } = params

  const { afterImportExternalQuoteIds, closeModal, importExternalQuoteId } = callbacks

  const afterActionCallback = (response) => {
    closeModal()
    if (afterImportExternalQuoteIds){ afterImportExternalQuoteIds(response) }
  }

  selectedExternalIds.forEach((externalId) => {
    const createQuoteFromTemplatePayload = {
      callbacks: {
        action: importFn,
        afterAction: ({ response }) => afterActionCallback(response),
      },
      entityParams: {
        externalPlatformKey: selectedIntegrationPlatformKey,
        payload: { externalId, organizationId: project.organization_id, projectId: project.id },
      },
      shouldRedirect: true,
      toastText: formatString(strings.toast?.importSuccess, { externalId })
      || `External Quote ${externalId} Imported Successfully`,
    }
    importExternalQuoteId(createQuoteFromTemplatePayload)
  })
}

const ImportQuote = (props) => {
  const {
    callbacks,
    callbacks: { importExternalQuoteId },
    integrationsPayload,
    integrationsPayload: {
      filteredPlatforms,
    },
    project,
  } = props

  const importQuotePayload = useImportQuote(integrationsPayload, project)
  const {
    callbacks: {
      importFromExternalPlatform: importFn, loadFromExternalPlatform, setState, toggleExternalId,
    },
    state: {
      externalProjectId, loaded, loading, results, selectedExternalIds, selectedIntegrationPlatformKey,
    },
    urls: {
      manageExternalQuotesUrl,
    },
  } = importQuotePayload

  // Integration Platform
  const integrationPlatformsPayload = useIntegrationPlatforms()
  const { callbacks: { findIntegrationPlatformByKey } } = integrationPlatformsPayload

  const integrationPlatform = findIntegrationPlatformByKey(selectedIntegrationPlatformKey)

  const externalQuotesUrl = `${manageExternalQuotesUrl}?redirect=${generateRedirectUrl({
    append: '&showModal=CreateQuoteModal',
  })}`

  const {
    callbacks: { formatCount, formatCurrency, formatString },
    strings,
  } = useLocalization(localizedStrings)

  return (
    <>
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.title || 'Import'}
        titleSecondLine={strings.subtitle || 'Quote'}
      />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <FormField direction="column" boxProps={{ marginBottom: 'large' }} label="Platform">
            <SelectBox
              callbacks={{
                selectItem: platform => setState({ selectedIntegrationPlatformKey: platform.key }),
              }}
              items={filteredPlatforms}
              selectedItemKey={selectedIntegrationPlatformKey}
              style={{ zIndex: 3 }}
              ulStyle={{ maxHeight: 300, overflow: 'auto' }}
            />
          </FormField>

          <FormField
            direction="column"
            boxProps={{ marginBottom: 'small' }}
            label={`${integrationPlatform.title || 'External'} Campaign ID`}
          >
            <input
              type="text"
              value={externalProjectId}
              onChange={e => setState({ externalProjectId: e.target.value })}
            />
          </FormField>

          <Button
            buttonStyle="secondaryEdit"
            icon={<FontAwesomeIcon icon={faSearch} />}
            loading={loading}
            onClick={() => loadFromExternalPlatform()}
            size="medium"
          >
            {strings.fetchQuotes || 'Fetch Quotes'}
          </Button>

          {loaded
          && (
          <SectionDivider boxProps={{ marginTop: 'large' }}>
            {strings.availableQuotes || 'Available Quotes'}
          </SectionDivider>
          )}

          {loaded && !results.length && (
            <SidebarNoticeBox boxProps={{ marginTop: 'large' }}>
              <SidebarNoticeBox.Title>{strings.noticeBox?.title || 'Nothing to Import'}</SidebarNoticeBox.Title>
              <SidebarNoticeBox.Paragraph>
                {formatString(strings.noticeBox?.paragraph, {
                  integrationPlatformTitle: integrationPlatform.title || 'External Platform',
                })}
              </SidebarNoticeBox.Paragraph>
            </SidebarNoticeBox>
          )}

          {!!results.length && (
            <Box flexDirection="column">
              {results.map((externalQuote) => {
                const { id, title, total } = externalQuote

                return (
                  <SidebarModal.SelectItem
                    callbacks={{ selectItem: () => toggleExternalId(id) }}
                    key={id}
                    selected={selectedExternalIds.includes(id)}
                    title={title}
                    subtitle={`ID #${id} - ${formatCurrency(total)}`}
                  />
                )
              })}
            </Box>
          )}

          {loaded && !!externalProjectId && !!results.length && (
            <FormField
              boxProps={{ marginTop: 'large' }}
              direction="column"
              label={strings.moreOptions || 'More Options'}
            >
              <Button
                as="a"
                buttonStyle="secondaryUtility"
                href={externalQuotesUrl}
                icon={<FontAwesomeIcon icon={faPencil} />}
                size="medium"
              >
                {formatString(strings.manageQuotes, {
                  integrationPlatformTitle: integrationPlatform.title || 'External Platform',
                })}
              </Button>
            </FormField>
          )}
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        {loaded && !!results.length && (
          <Button
            buttonStyle="primaryCreate"
            disabled={!selectedExternalIds.length}
            onClick={() => importExternalQuoteIds({
              callbacks,
              formatString,
              importExternalQuoteId,
              importFn,
              project,
              selectedExternalIds,
              selectedIntegrationPlatformKey,
              strings,
            })}
            size="large"
          >
            {formatCount('actionButtonText', { count: selectedExternalIds.length })}
          </Button>
        )}

        {loaded && !!externalProjectId && !results.length && (
          <Button as="a" buttonStyle="primaryEdit" href={externalQuotesUrl} size="large">
            {formatString(strings.manageQuotes, {
              integrationPlatformTitle: integrationPlatform.title || 'External Platform',
            })}
          </Button>
        )}
      </SidebarModal.Footer>
    </>
  )
}

ImportQuote.propTypes = {
  callbacks: PropTypes.object,
  integrationsPayload: PropTypes.shape({
    entities: PropTypes.object.isRequired,
    filteredIntegrations: PropTypes.array.isRequired,
    filteredPlatforms: PropTypes.array.isRequired,
    hasIntegrations: PropTypes.bool,
  }).isRequired,
  project: PropTypes.object,
}

export default ImportQuote
