export type FormTemplateModel = {
  cache_key: number,
  id: number,
  dates: {
    created: {
      date_time_with_timezone: string,
    },
    updated: {
      date_time_with_timezone: string,
    },
  },
  form_template_id?: number,
  notifications_enabled: boolean,
  options: { [key: string] : any },
  owner_id: number,
  owner_type: string,
  production_ready: boolean,
  title: string,
}

export type FormTemplateRequestOptions = {
  include_form_template_fields?: boolean,
}

const state = {
  id: null,
  notifications_enabled: false,
  options: {},
  owner_id: null,
  owner_type: 'Organization',
  production_ready: false,
  title: '',
}

export const requiredFields = [
  { key: 'owner_id' },
  { key: 'owner_type' },
  { key: 'title' },
]

export default state
