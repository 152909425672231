export default {
  default: {
    blankState: {
      title: 'No Trend Data to Display',
      paragraph: 'Once pages have been created and viewed trend data will be recorded and displayed.',
    },
    chartKeyValues: {
      acceptedVsCreatedPerMonth: 'Pages Accepted vs Created per Month',
      engagementPercentage: 'Engagement Status Percentage',
      totalPageViewsPerMonth: 'Total Page Views per Month',
      digitalPagesCreatedvsViewedPerDay: 'Digital Pages Created vs Viewed Per Day',
      digitalPagesEngagementPerDay: 'Digital Pages Engagement Per Day',
    },
    manage: 'Manage',
  },
}
