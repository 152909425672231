import { useContext } from 'react'

import PropTypes from 'prop-types'

import {
  Box, Button, FormField, ModalContext, SidebarModal, SidebarNoticeBox,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import { digObject } from '@campaignhub/javascript-utils'

import EntitySelector from '@components/EntitySelector'

import useCurrentUser from '@hooks/useCurrentUser'
import useOrganizationsUser, { useOrganizationsUserForm } from '@hooks/useOrganizationsUser'

import localizedStrings from './localizedStrings'

const AddUserToOrganizationModal = (props) => {
  const {
    callbacks, modalKey, organizationsUser: initOrganizationsUser, showModal,
  } = props
  const { closeModal, createOrganizationsUser } = callbacks

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext
  const {
    callbacks: { createOrganizationsUser: createFn },
  } = digObject(modalData, modalKey, {})

  const {
    callbacks: { getUserLevelKey, handleUserLevelChange },
    creating,
    isCurrentUserAdminForOrganization,
    isCurrentUserOwnerForOrganization,
    user: { organization_ids },
  } = useOrganizationsUser(initOrganizationsUser)

  const {
    entityState,
    entityState: { organization_id },
    saveEnabled,
    setEntityState,
  } = useOrganizationsUserForm(initOrganizationsUser)

  const { isAdmin } = useCurrentUser()

  const { strings } = useLocalization(localizedStrings)

  const addToOrganizationPayload = {
    callbacks: {
      action: createFn,
      afterAction: closeModal,
    },
    entityParams: entityState,
    toastText: strings.toast?.created || 'User Added to Organization',
  }

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.title || 'Join'}
        titleSecondLine={strings.titleSecondLine || 'Organization'}
      />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <SidebarNoticeBox>
            {(isAdmin || isCurrentUserOwnerForOrganization) && (
              <>
                <SidebarNoticeBox.Title>{strings.noticeBox?.titles?.owner || 'Owner'}</SidebarNoticeBox.Title>
                <SidebarNoticeBox.Paragraph boxProps={{ marginBottom: 'large' }}>
                  {strings.noticeBox?.paragraphs?.owner || 'Full access to the system and billing information'}
                </SidebarNoticeBox.Paragraph>
              </>
            )}

            <SidebarNoticeBox.Title>{strings.noticeBox?.titles?.admin || 'Admin'}</SidebarNoticeBox.Title>
            <SidebarNoticeBox.Paragraph boxProps={{ marginBottom: 'large' }}>
              {strings.noticeBox?.paragraphs?.admin
                || 'Full access to the system, restricted access to billing information'}
            </SidebarNoticeBox.Paragraph>

            <SidebarNoticeBox.Title>{strings.noticeBox?.titles?.user || 'User'}</SidebarNoticeBox.Title>
            <SidebarNoticeBox.Paragraph>
              {strings.noticeBox?.paragraphs?.user
                || 'Limited access to account settings and projects, restricted access to billing information'}
            </SidebarNoticeBox.Paragraph>
          </SidebarNoticeBox>

          <EntitySelector
            boxProps={{ marginBottom: 'large' }}
            callbacks={{
              selectItem: organization => setEntityState({
                organization_id: organization ? organization.id : null,
              }),
            }}
            entityKey="organizations"
            selectedIds={organization_ids}
          />

          {!!organization_id && (
            <FormField
              direction="column"
              label={strings.formFieldLabels?.role || 'Role'}
              boxProps={{ marginBottom: 'large' }}
            >
              <select
                data-value-type="boolean"
                name="userLevel"
                value={getUserLevelKey(entityState)}
                onChange={e => handleUserLevelChange({userLevelKey: e.target.value, setEntityState})}
              >
                {(isAdmin || isCurrentUserAdminForOrganization) && (
                  <option value="admin">{strings.formFieldOptions?.admin || 'Admin'}</option>
                )}
                {(isAdmin || isCurrentUserOwnerForOrganization) && (
                  <option value="owner">{strings.formFieldOptions?.owner || 'Owner'}</option>
                )}
                <option value="user">{strings.formFieldOptions?.user || 'User'}</option>
              </select>
            </FormField>
          )}
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={creating}
          onClick={() => createOrganizationsUser(addToOrganizationPayload)}
          size="large"
        >
          {strings.joinButton || 'Add to Organization'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

AddUserToOrganizationModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  organizationsUser: PropTypes.object.isRequired,
  showModal: PropTypes.bool,
}

AddUserToOrganizationModal.defaultProps = {
  modalKey: 'AddUserToOrganizationModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <AddUserToOrganizationModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
