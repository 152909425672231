import { useMemo } from 'react'

import { matchFilterArrayIncludes, matchFilterString, sortArrayBy } from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { TeamModel, TeamRequestOptions } from '@models/types'
import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['teams']

type TeamFilters = {
  owner_id?: number,
  owner_type?: string,
  string?: string,
  user_id?: number,
}

type UseTeamsOptions = {
  filters?: TeamFilters,
  performHttpRequests?: boolean,
  requestOptions?: TeamRequestOptions,
}

function useTeams(options: UseTeamsOptions = {}) {
  const { filters = {}, requestOptions } = options
  const {
    owner_id: filterOwnerId,
    owner_type: filterOwnerType,
    string: filterString,
    user_id: filterUserId,
  } = filters

  const {
    updatedEntities: { teams: teamsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { teamMembers, teams } = useSelector(reduxState => reduxState.entities)

  const filteredTeams = useMemo(() => {
    const filtered = Object.values(teams).filter((team: TeamModel) => {
      const { owner_id, owner_type, title } = team

      const teamMembersArray = Object.values(teamMembers).filter(teamMember => teamMember.team_id === team.id)
      const teamMembersUserIds = teamMembersArray.map(teamMember => teamMember.user_id)

      const titleMatch = matchFilterString(title, filterString)
      const userIdMatch = matchFilterArrayIncludes(teamMembersUserIds, Number(filterUserId))
      const ownerMatch = filterOwnerId && filterOwnerType
        ? owner_id === filterOwnerId && owner_type === filterOwnerType
        : true

      return titleMatch && ownerMatch && userIdMatch
    })

    return sortArrayBy(filtered, 'asc', 'title')
  }, [teamsUpdatedAt, JSON.stringify(filters)])

  const filteredTeamsCount = filteredTeams.length
  const hasFilteredTeams = !!filteredTeamsCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredTeamsCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingTeams } = useReduxAction(
    'teams',
    'loadTeams',
    {
      ...requestOptions,
      ...filtersWithOffset,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredTeams,
    filteredTeamsCount,
    hasTeams: hasFilteredTeams,
    loading: loadingTeams,
  }
}

export default useTeams
