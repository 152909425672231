import { OrganizationModel, UserModel } from '@models/types'

export const isAllowedNonAdminPath = (currentUser: UserModel) => {
  const allowedPaths = [
    '#/admin/analytics',
    '#/admin/caseStudies?hidden=false',
    '#/admin/integrations',
    '#/admin/reviews?hidden=false',
    '#/admin/teams',
    `#/admin/users/${currentUser.id}/edit`,
  ]

  return allowedPaths.some(path => window.location.hash.includes(path))
}

type AuthroizeOrRedirectUserType = {
  currentUser: UserModel,
  organizationsWhereAdminIds: number[],
  selectedOrganization: OrganizationModel,
}

const authorizeOrRedirectUser = (params: AuthroizeOrRedirectUserType) => {
  const { currentUser, organizationsWhereAdminIds, selectedOrganization } = params

  if (!currentUser.id || !selectedOrganization.id || organizationsWhereAdminIds.includes(selectedOrganization.id)){
    return
  }

  // Return if on allowed page
  if (isAllowedNonAdminPath(currentUser)){
    return
  }

  // If user is not admin, redirect to edit user
  if (organizationsWhereAdminIds.length){
    return
  }

  window.location.hash = `#/admin/users/${currentUser.id}/edit`
}

export default authorizeOrRedirectUser
