import api from '@functions/api'

import type { AppDispatch } from '@redux/store'
import type {
  Action, EntityOptions, Errors, FetchRequestPayload,
} from '@redux/modules/types'

import { UserModel } from '@models/types'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/currentUser/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/currentUser/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/currentUser/FETCH_FAILURE'

export type CurrentUserModuleState = {
  errors: string[],
  loaded: boolean,
  loading: boolean,
  result: {},
}

// Initial State
const initialState: CurrentUserModuleState = {
  errors: [],
  loaded: false,
  loading: false,
  result: {},
}

// Actions
export function fetchRequest(payload: FetchRequestPayload = {}) {
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess(payload: Partial<UserModel> = {}) {
  return {
    type: FETCH_SUCCESS,
    payload,
  }
}

export function fetchFailure(errors: Errors = []) {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

// Action Creators
export function hydrateCurrentUser(user: Partial<UserModel>) {
  return (dispatch: AppDispatch) => {
    if (user.id){
      dispatch(fetchSuccess(user))

      return { success: true as const, data: user }
    }
    return { success: false as const, errors: ['Could not hydrate user without ID'] }
  }
}

export function loadCurrentUser(options: EntityOptions = {}) {
  return (dispatch: AppDispatch) => {
    // Set Loading
    dispatch(fetchRequest())

    const promise = api('/users/me.json', options)
      .then(({ data }: { data: { entity: UserModel } }) => {
        dispatch(fetchSuccess(data.entity))

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(
  state: CurrentUserModuleState = initialState,
  action: Action = { type: '' },
): CurrentUserModuleState {
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
        result: action.payload,
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors || [],
      }
    default:
      return state
  }
}
