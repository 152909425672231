import React from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCog, faEdit, faFilePlus, faTrashAlt,
} from '@fortawesome/pro-light-svg-icons'

import { Button, IconWithMenu } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const confirmDelete = (deleteFn, redirectUrl, strings) => {
  swal
    .fire({
      title: strings.swal?.title || 'Delete Quote?',
      text: strings.swal?.text || 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: strings.swal?.confirmButtonText || 'Yes, Delete it.',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        deleteFn().then(() => {
          window.location.hash = redirectUrl
        })
      }
    })
}

const MoreOptions = (props) => {
  const {
    quotePayload: {
      callbacks: { deleteQuote, editQuote, saveAsTemplate },
      isSavedTemplate,
      urls: { quoteListUrl, quoteTemplateListUrl },
    },
  } = props

  const redirectUrl = isSavedTemplate ? quoteTemplateListUrl : quoteListUrl

  const { strings } = useLocalization(localizedStrings)

  return (
    <IconWithMenu
      closeOnClick
      icon={(
        <Button
          buttonStyle="secondaryUtility"
          icon={<FontAwesomeIcon icon={faCog} />}
          size="medium"
          style={{ height: 37 }}
        />
      )}
    >
      <IconWithMenu.Menu listStyle={{ right: '-7px' }}>
        <a key="edit" onClick={() => editQuote()} tabIndex="0">
          <FontAwesomeIcon icon={faEdit} /> {strings.menu?.editDetails || 'Edit Details'}
        </a>

        {!isSavedTemplate && (
          <a key="save" onClick={() => saveAsTemplate()} tabIndex="0">
            <FontAwesomeIcon icon={faFilePlus} /> {strings.menu?.saveTemplate || 'Save as Template'}
          </a>
        )}

        <a key="delete" onClick={() => confirmDelete(deleteQuote, redirectUrl, strings)} tabIndex="0">
          <FontAwesomeIcon icon={faTrashAlt} /> {strings.menu?.delete || 'Delete'}
        </a>
      </IconWithMenu.Menu>
    </IconWithMenu>
  )
}

MoreOptions.propTypes = {
  quotePayload: PropTypes.shape({
    callbacks: PropTypes.shape({
      deleteQuote: PropTypes.func.isRequired,
      editQuote: PropTypes.func.isRequired,
      saveAsTemplate: PropTypes.func.isRequired,
    }).isRequired,
    isSavedTemplate: PropTypes.bool,
    urls: PropTypes.shape({
      quoteListUrl: PropTypes.string,
      quoteTemplateListUrl: PropTypes.string,
    }).isRequired,
  }).isRequired,
}

export default MoreOptions
