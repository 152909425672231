export type AssetLibraryModel = {
  cache_key: number,
  id: number,
  asset_type: string,
  data: {},
  dates:{
    created: {
      date_time_with_timezone: string,
    },
    updated: {
      date_time_with_timezone: string,
    },
  },
  options: {},
  owner_id: number,
  owner_type: string,
  title: string,
}

export type AssetLibraryRequestOptions = {}

const state = {
  asset_type: '',
  data: {},
  id: null,
  options: {},
  owner_id: null,
  owner_type: '',
  title: '',
}

export const requiredFields = [
  { key: 'asset_type' },
  { key: 'owner_id' },
  { key: 'owner_type' },
  { key: 'title' },
]

export default state
