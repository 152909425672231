export const mappedStatusKeys = {
  signing_complete: 'completed',
}

export function docusignStatusFromParams(params){
  const statusKey = params.event

  return mappedStatusKeys[statusKey]
}

export default docusignStatusFromParams
