import React from 'react'
import PropTypes from 'prop-types'

import ContentWrapper from '@components/digitalTemplates/ContentWrapper'
import availableComponents from '../../availableComponents'

const ElementContainer = props => <ContentWrapper availableComponents={availableComponents} {...props} />

ElementContainer.propTypes = {
  forwardContextToItems: PropTypes.object,
  render: PropTypes.func.isRequired,
  renderNested: PropTypes.bool,
  section: PropTypes.object.isRequired,
}

export default ElementContainer
