import { useSetState } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'

import { digObject } from '@campaignhub/javascript-utils'

const defaultState = {
  showAllLeaderboard: false,
}

function useLeaderboard(options = {}){
  const {
    digital_template_type_key, month, parentId, status_key, leaderboard,
  } = options

  const [state, setState] = useSetState(defaultState)
  const { showAllLeaderboard } = state

  const leaderboardOptions = {
    digital_template_type_key,
    leaderboard,
    month,
    status_key,
  }

  const { data, loading } = useReduxAction('leaderboard', 'fetchLeaderboard', leaderboardOptions, [
    leaderboard,
    month,
    parentId,
    status_key,
  ])
  const result = digObject(data, 'data', [])

  const shouldShowShowAll = result.length > 5 && !showAllLeaderboard

  const leaderboardArray = showAllLeaderboard ? result : Object.values(result).slice(0, 5)

  const hasLeaderboardResults = !!result.length

  return {
    callbacks: {
      toggleShowLeaderboard: () => setState({
        showAllLeaderboard: !showAllLeaderboard,
      }),
    },
    hasLeaderboardResults,
    leaderboardArray,
    loading,
    shouldShowShowAll,
  }
}

export default useLeaderboard
