export default {
  default: {
    buttons: {
      save: 'Save',
    },
    formFieldLabels: {
      image: 'Comparable Images',
      images: 'Images',
    },
    sideBarModalHeader: {
      titleUpdate: 'Update',
      titleSecondLine: 'Comparable',
    },
  },
}
