import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  matchFilterArrayIncludes, matchFilterKey, matchFilterString, sortArrayBy,
} from '@campaignhub/javascript-utils'
import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'

const watchEntityKeys = ['digitalTemplates', 'digitalTemplateComponents']

function useDigitalTemplateComponents(options = {}){
  const { filters, performHttpRequests, requestOptions } = options

  const {
    digitalTemplateId,
    itemType,
  } = options || {}

  const { brand_id: brandId, string } = filters

  const {
    updatedEntities: {
      digitalTemplates: digitalTemplatesUpdatedAt,
      digitalTemplateComponents: digitalTemplateComponentsUpdatedAt,
    },
  } = useWatchEntityUpdates(watchEntityKeys)

  const entities = useSelector(reduxState => reduxState.entities)
  const { digitalTemplateComponents } = entities

  const { loading } = useSelector(state => state.digitalTemplateComponents)

  const filteredDigitalTemplateComponents = useMemo(() => {
    const array = Object.values(digitalTemplateComponents)
    const filtered = array.filter((digitalTemplateComponent) => {
      const {
        brand_id, digital_template_ids, id, item_type, title,
      } = digitalTemplateComponent

      const digitalTemplateMatch = digitalTemplateId
        ? matchFilterArrayIncludes(digital_template_ids, Number(digitalTemplateId))
        : true
      const matchItemType = matchFilterKey(item_type, itemType)
      const titleMatch = matchFilterString(title, string) || Number(string) === id
      const brandMatch = brandId ? brand_id === brandId : true

      return brandMatch && digitalTemplateMatch && matchItemType && titleMatch
    })

    return sortArrayBy(filtered, 'asc', 'title')
  }, [digitalTemplatesUpdatedAt, digitalTemplateComponentsUpdatedAt, JSON.stringify(options)])

  const hasDigitalTemplateComponents = !!filteredDigitalTemplateComponents.length

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredDigitalTemplateComponents.length,
    performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
  } = loadMorePayload

  useReduxAction(
    'digitalTemplateComponents',
    'loadDigitalTemplateComponents',
    {
      brand_id: brandId,
      item_type: itemType,
      ...requestOptions,
      ...filtersWithOffset,
    },
    [performHttpRequests, filtersWithOffset],
    {
      shouldPerformFn: () => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredDigitalTemplateComponents,
    hasDigitalTemplateComponents,
    loading,
  }
}

export default useDigitalTemplateComponents
