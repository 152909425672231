import {
  Box, Form, InputSearch, LoadingModule, SectionDivider,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import DocumentTemplateListItem from '../DocumentTemplateListItem'

import localizedStrings from './localizedStrings'

export type DocumentTemplate = {
  cost: number,
  id: number,
  title: string,
}

type TemplateGroup = {
  id: number,
  templates: DocumentTemplate[],
  title: string,
}

type DocumentTemplatesProps = {
  externalDocumentTemplatesPayload: {
    callbacks: {
      toggleDocumentTemplate: (template: DocumentTemplate) => void,
      setState: (state: {}) => void,
    },
    filterString: string,
    filteredDocumentTemplateGroups: {
      [key: string]: TemplateGroup,
    },
    hasSelectedDocumentTemplate: boolean,
    loading: boolean,
    selectedDocumentTemplate: DocumentTemplate,
  },
}

const DocumentTemplates = (props: DocumentTemplatesProps) => {
  const { externalDocumentTemplatesPayload } = props

  const {
    callbacks: { toggleDocumentTemplate, setState },
    filterString,
    filteredDocumentTemplateGroups,
    hasSelectedDocumentTemplate,
    loading,
    selectedDocumentTemplate,
  } = externalDocumentTemplatesPayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <Box flexDirection="column">
      {hasSelectedDocumentTemplate && (
        <DocumentTemplateListItem
          callbacks={{
            toggleDocumentTemplate: () => toggleDocumentTemplate({}),
          }}
          documentTemplate={selectedDocumentTemplate}
          selected={hasSelectedDocumentTemplate}
        />
      )}

      {!hasSelectedDocumentTemplate && (
        <>
          <Form.Field
            direction="column"
            label={strings.formFieldLabels?.filterTemplates || 'Filter Templates'}
            marginTop="large"
          >
            <InputSearch onChange={e => setState({ filterString: e.target.value })} value={filterString} />
          </Form.Field>

          <Box flexDirection="column" marginTop={loading ? 'large' : 'none'}>
            <LoadingModule loading={loading} times={3} />

            {filteredDocumentTemplateGroups.map((templateGroup: TemplateGroup) => {
              const { templates, title: groupTitle } = templateGroup

              return (
                <Box flexDirection="column" key={templateGroup.id}>
                  <SectionDivider boxProps={{ marginY: 'large' }}>{groupTitle}</SectionDivider>

                  {templates.map((template: DocumentTemplate) => (
                    <DocumentTemplateListItem
                      callbacks={{
                        toggleDocumentTemplate: () => toggleDocumentTemplate(template),
                      }}
                      documentTemplate={template}
                      key={template.id}
                      selected={hasSelectedDocumentTemplate}
                    />
                  ))}
                </Box>
              )
            })}
          </Box>
        </>
      )}
    </Box>
  )
}

export default DocumentTemplates
