export default {
  default: {
    stepsToComplete: {
      many: 'Hey {name}, {count} more steps to complete your onboarding',
      none: 'All Tasks Complete',
      one: 'Hey {name}, {count} more step to complete your onboarding',
      type: 'count',
    },
    titles: {
      welcome: 'Welcome to Engage',
    },
  },
}
