import { useEffect, useMemo } from 'react'

import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

import { useModals, useSetState } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'
import PageContext from '@contexts/pageContext'

import EditShareLinkModal from '@modals/EditShareLinkModal'
import ShareLinkAnalyticsModal from '@modals/ShareLinkAnalyticsModal'

import useLocalization from '@hooks/useLocalization'
import useMixpanel from '@hooks/useMixpanel'

import openModalAndCloseAllOthers from '@functions/openModalAndCloseAllOthers'

import PageContent from './components/PageContent'

import localizedStrings from './localizedStrings'

const modalKeys = [
  'EditShareLinkModal',
  'ShareLinkAnalyticsModal',
]

const deleteShareLink = (deleteFn, setState, strings) => {
  deleteFn().then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    toast(strings.toast?.shareLink?.deleted || 'Share Link Deleted Successfully')
    setState({ showEditShareLinkModal: false })
  })
}

const updateShareLink = (shareLinkParams, updateFn, setState, strings) => {
  updateFn(shareLinkParams).then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    toast(strings.toast?.shareLink?.updated || 'Share Link Updated Successfully')
    setState({ showEditShareLinkModal: false })
  })
}

const callbacks = (component, setState, strings) => {
  const componentCallbacks = {
    EditShareLinkModal: {
      closeModal: () => setState({ showEditShareLinkModal: false }),
      deleteShareLink: deleteFn => deleteShareLink(deleteFn, setState, strings),
      updateShareLink: (shareLinkParams, updateFn) => updateShareLink(shareLinkParams, updateFn, setState, strings),
    },
    ShareLinkAnalyticsModal: {
      closeModal: () => setState({ showShareLinkAnalyticsModal: false }),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  showShareLinkAnalyticsModal: false,
}

const Analytics = (props) => {
  const { project } = props

  const [state, setState] = useSetState(defaultState)
  const { showEditShareLinkModal, showShareLinkAnalyticsModal } = state

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const pageContext = useMemo(
    () => ({
      callbacks: {
        showEditShareLinkModal: (payload) => {
          setModalData('EditShareLinkModal', payload)
          setState({ showEditShareLinkModal: true })
        },
        showShareLinkAnalyticsModal: (payload) => {
          setModalData('ShareLinkAnalyticsModal', payload)
          openModalAndCloseAllOthers('ShareLinkAnalyticsModal', modalKeys, setState)
        },
      },
    }),
    [],
  )

  // Tracks the page load event
  const { callbacks: { dispatchMixpanelEvent } } = useMixpanel()
  useEffect(() => {
    dispatchMixpanelEvent('Project Analytics Load')
  }, [])

  const { strings } = useLocalization(localizedStrings)

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent project={project} />

        <ShareLinkAnalyticsModal
          callbacks={callbacks('ShareLinkAnalyticsModal', setState, strings)}
          showModal={showShareLinkAnalyticsModal}
        />

        <EditShareLinkModal
          callbacks={callbacks('EditShareLinkModal', setState, strings)}
          disableSMS
          showModal={showEditShareLinkModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

Analytics.propTypes = {
  project: PropTypes.object.isRequired,
}

export default Analytics
