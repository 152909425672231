import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faUsers } from '@fortawesome/pro-solid-svg-icons'

import { Box, ListItem, Text } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'
import useProject, { useRelations } from '@hooks/useProject'
import useProjectTheme from '@hooks/useProjectTheme'

import { ProjectModel } from '@models/project'

import ProjectIcon from '@components/ProjectIcon'

import { formatListToString } from '@functions/string'

import localizedStrings from './localizedStrings'

interface LargeListProps {
  project: ProjectModel,
}

const LargeList = (props: LargeListProps) => {
  const {
    project,
    project: { title },
  } = props

  const {
    filteredTeams,
    leadUsers,
    urls: { overviewUrl },
  } = useProject(project)

  const hasTeams = !!filteredTeams.length

  const { organization } = useRelations(project)

  const { colors, projectTypeKey } = useProjectTheme(project)

  const { strings } = useLocalization(localizedStrings)

  const teamOrUsersString = formatListToString(
    hasTeams ? filteredTeams : leadUsers,
    hasTeams ? 'title' : 'full_name',
    strings.assigned,
  )

  return (
    <ListItem boxProps={{ as: 'a', borderBottom: '1px solid', padding: 'large' }} href={overviewUrl} showAngleIcon>
      <Box
        alignItems="center"
        justifyContent="center"
        background={colors.background}
        borderRadius={50}
        flexShrink={0}
        height={50}
        width={50}
        marginRight="large"
      >
        <ProjectIcon color={colors.solid} height={32} projectTypeKey={projectTypeKey} width={32} />
      </Box>

      <Box justifyContent="center" flexDirection="column">
        <Text fontSize="small">{title}</Text>

        <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="medium" variant="ellipsis">
          {organization.title}
        </Text>

        <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="medium">
          <FontAwesomeIcon icon={hasTeams || leadUsers.length > 1 ? faUsers : faUser} style={{ marginRight: '5px' }} />
          {teamOrUsersString}
        </Text>
      </Box>
    </ListItem>
  )
}

export default LargeList
