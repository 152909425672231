import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faSearch } from '@fortawesome/pro-light-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'
import { useSetState } from '@campaignhub/react-hooks'

import {
  Box, Image, Link, LoadingBubbles, Text,
} from '@campaignhub/suit-theme'

import DigitalPagePlaceholderImage from '@components/DigitalPagePlaceholderImage'

import useDigitalTemplate from '@hooks/useDigitalTemplate'
import useLocalization from '@hooks/useLocalization'

import styles from './styles.module.scss'

import localizedStrings from './localizedStrings'

const showPreview = (e, previewLink) => {
  e.stopPropagation()
  window.open(previewLink)
}

const selectTemplate = (props, setState) => {
  const {
    callbacks,
    digitalTemplate: { id },
  } = props
  const { selectTemplate: selectFn } = callbacks || {}

  if (selectFn){
    setState({ loading: true })

    selectFn(id).then(() => {
      setState({ loading: false })
    })
  }
}

const defaultState = {
  loading: false,
}

const DigitalTemplateTile = (props) => {
  const {
    digitalTemplate, hoverEnabled, onClick, previewImage, showAngleRight,
  } = props

  const [state, setState] = useSetState(defaultState)
  const { loading } = state

  const { digitalTemplateType } = useDigitalTemplate(digitalTemplate)

  const { id, title } = digitalTemplate
  const previewLink = digObject(digitalTemplate, 'options.preview_link', null)
  const previewImageUrl = digObject(previewImage, 'sizes.thumb_660x360', '')

  const { strings } = useLocalization(localizedStrings)

  return (
    <Box
      className={classnames(styles.root, hoverEnabled ? styles.hover : null)}
      border="1px solid"
      borderColor="lineColor"
      borderRadius={5}
      flexDirection="column"
      flexShrink={0}
      marginBottom="large"
      onClick={onClick || (() => selectTemplate(props, setState))}
    >
      <Image
        borderRadius="5px 5px 0 0"
        boxProps={{ backgroundColor: !previewImageUrl ? '#F2F9FB' : null, height: 180, width: '100%' }}
        url={previewImageUrl}
      >
        {!previewImageUrl && (
          <DigitalPagePlaceholderImage
            color="#61ABBB"
            digitalTemplateTypeKey={digitalTemplateType.key}
            height="100%"
            width="100%"
          />
        )}
      </Image>

      <Box borderTop="1px solid" borderColor="lineColor" padding="large">
        <Box flexDirection="column" width="80%" alignItems="flexStart">
          {loading && (
            <Box marginBottom="small">
              <LoadingBubbles style={{ width: 'fit-content' }} />
            </Box>
          )}

          {!loading && (
            <Text fontSize="small" marginBottom="small" maxWidth="95%" variant="ellipsis">
              {title}
            </Text>
          )}

          <Text fontSize="xsmall" color="mysteryGrey">
            {strings.id} #{id}
          </Text>
        </Box>

        <Box alignItems="center" fontSize="large" justifyContent="center" marginLeft="auto" width="fit-content">
          {previewLink && (
            <Link
              greyLink
              fontSize="small"
              onClick={e => showPreview(e, previewLink)}
              textProps={{ marginRight: 'large' }}
            >
              <FontAwesomeIcon icon={faSearch} />
            </Link>
          )}

          {showAngleRight && <FontAwesomeIcon icon={faAngleRight} className={styles.angleRight} />}
        </Box>
      </Box>
    </Box>
  )
}

DigitalTemplateTile.propTypes = {
  callbacks: PropTypes.shape({
    selectTemplate: PropTypes.func,
  }),
  digitalTemplate: PropTypes.object.isRequired,
  hoverEnabled: PropTypes.bool,
  onClick: PropTypes.func,
  previewImage: PropTypes.object,
  showAngleRight: PropTypes.bool,
}

DigitalTemplateTile.defaultProps = {
  hoverEnabled: true,
  showAngleRight: true,
}

export default DigitalTemplateTile
