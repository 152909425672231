import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhotoVideo } from '@fortawesome/pro-light-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'

import {
  Box, Image, ListItem, Text,
} from '@campaignhub/suit-theme'

import useImage from '@hooks/useImage'
import useLocalization from '@hooks/useLocalization'

import PlaceHolderImage from '../assets/placeholderImage.png'

const LibraryImage = (props) => {
  const { callbacks: { updateDefaultImage }, image, image: { dates } } = props

  const {
    callbacks: { editImage },
  } = useImage(image)

  const defaultImageUrl = digObject(image, 'sizes.thumb_200x120', '')
  const fileName = digObject(image, 'file_data.metadata.filename')
  const createdDateTime = digObject(dates, 'created.date_time_with_timezone')

  const { callbacks: { formatDate } } = useLocalization()

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        paddingY: 'large',
      }}
      onClick={() => editImage(updateDefaultImage)}
      showAngleIcon
    >
      <Box
        backgroundImage={`url(${PlaceHolderImage})`}
        borderRadius={5}
        height={50}
        marginRight="large"
        padding="large"
        variant="center"
        width={70}
      >
        {!defaultImageUrl && (
          <FontAwesomeIcon icon={faPhotoVideo} />
        )}

        {defaultImageUrl && (
          <Image
            borderRadius={5}
            height={50}
            url={defaultImageUrl}
            width={70}
          />
        )}
      </Box>

      <Box flexDirection="column" width="75%">
        <Text variant="ellipsis" fontSize="small" color="bodyFontColor">
          {fileName}
        </Text>

        <Text variant="ellipsis" color="bodyFontLightColor" fontSize="xsmall" marginTop="medium">
          {`Created ${formatDate(createdDateTime, DateTime.DATE_FULL)}`}
        </Text>
      </Box>
    </ListItem>
  )
}

LibraryImage.propTypes = {
  callbacks: PropTypes.object.isRequired,
  image: PropTypes.object.isRequired,
}

export default LibraryImage
