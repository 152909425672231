import React from 'react'

import {
  Box, FormField, SortableList,
} from '@campaignhub/suit-theme'

import DraggableSection from '../DraggableSection'

interface SectionGroupProps {
   sectionGroupKey: string,
   sectionIds: string[],
}

const SectionGroup = React.forwardRef((props: SectionGroupProps, ref) => {
  const {
    sectionGroupKey, sectionIds,
  } = props

  return (
    <FormField boxProps={{ marginBottom: 'large' }} label={`Section Group ID# ${sectionGroupKey}`} ref={ref}>
      <SortableList.Dropzone droppableId={sectionGroupKey}>
        <Box flexDirection="column">
          {!!sectionIds.length && sectionIds.map((sectionId, index) => (
            <SortableList.Item draggableId={`Section-${sectionId}`} key={sectionId} index={index}>
              <DraggableSection sectionId={sectionId} />
            </SortableList.Item>
          ))}
        </Box>
      </SortableList.Dropzone>
    </FormField>
  )
})

export default SectionGroup
