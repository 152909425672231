import { schema } from 'normalizr'

// Circular Dependencies - Use the define method
// const item = new schema.Entity('item')
// const child = new schema.Entity('child')
// item.define({ children: [child] })

// Define Entities
// START ENTITY DEFINITIONS
const activity = new schema.Entity('activities')
const address = new schema.Entity('addresses')
const agreement = new schema.Entity('agreements')
const agreementShortcodeData = new schema.Entity('agreementShortcodeData')
const apiLog = new schema.Entity('apiLogs')
const assetLibrary = new schema.Entity('assetLibraries')
const attachment = new schema.Entity('attachments')
const award = new schema.Entity('awards')
const brand = new schema.Entity('brands')
const campaign = new schema.Entity('campaigns')
const caseStudy = new schema.Entity('caseStudies')
const chartData = new schema.Entity('chartData')
const comparable = new schema.Entity('comparables')
const contact = new schema.Entity('contacts')
const country = new schema.Entity('countries')
const customField = new schema.Entity('customFields')
const customFieldSet = new schema.Entity('customFieldSets')
const dataStoreItem = new schema.Entity('dataStoreItems')
const digitalPage = new schema.Entity('digitalPages')
const digitalPageMetricCollection = new schema.Entity('digitalPageMetricCollections')
const digitalPageShortcodeData = new schema.Entity('digitalPageShortcodeData')
const digitalPageView = new schema.Entity('digitalPageViews')
const digitalTemplate = new schema.Entity('digitalTemplates')
const digitalTemplateComponent = new schema.Entity('digitalTemplateComponents')
const digitalTemplatePage = new schema.Entity('digitalTemplatePages')
const digitalTemplatePageItem = new schema.Entity('digitalTemplatePageItems')
const digitalTemplatePageSection = new schema.Entity('digitalTemplatePageSections')
const digitalTemplatePageSectionGroup = new schema.Entity('digitalTemplatePageSectionGroups')
const digitalTemplateType = new schema.Entity('digitalTemplateTypes')
const documentRecipient = new schema.Entity('documentRecipients')
const documentTemplate = new schema.Entity('documentTemplates')
const documentTemplatePage = new schema.Entity('documentTemplatePages')
const documentTemplatePageItem = new schema.Entity('documentTemplatePageItems')
const event = new schema.Entity('events')
const eventCalendar = new schema.Entity('eventCalendars')
const eventGroup = new schema.Entity('eventGroups')
const formTemplate = new schema.Entity('formTemplates')
const image = new schema.Entity('images')
const integration = new schema.Entity('integrations')
const integrationPlatform = new schema.Entity('integrationPlatforms')
const organization = new schema.Entity('organizations')
const organizationShortcodeList = new schema.Entity('organizationShortcodeLists')
const organizationType = new schema.Entity('organizationTypes')
const organizationsUser = new schema.Entity('organizationsUsers')
const project = new schema.Entity('projects')
const projectType = new schema.Entity('projectTypes')
const quote = new schema.Entity('quotes')
const quoteItem = new schema.Entity('quoteItems')
const quoteSection = new schema.Entity('quoteSections')
const review = new schema.Entity('reviews')
const role = new schema.Entity('roles')
const sessionLock = new schema.Entity('sessionLocks')
const shareLink = new schema.Entity('shareLinks')
const status = new schema.Entity('statuses')
const system = new schema.Entity('systems')
const tag = new schema.Entity('tags')
const targetAudience = new schema.Entity('targetAudiences')
const team = new schema.Entity('teams')
const teamMember = new schema.Entity('teamMembers')
const user = new schema.Entity('users')
const userType = new schema.Entity('userTypes')
// END ENTITY DEFINITIONS

activity.define({
  user,
})

address.define({
  project,
})

agreement.define({
  document_template: documentTemplate,
  project,
  recipients: [documentRecipient],
  shortcode_data: agreementShortcodeData,
})

assetLibrary.define({
  attachments: [attachment],
  default_image: image,
  images: [image],
})

attachment.define({
  data_store_items: [dataStoreItem],
})

award.define({
  data_store_items: [dataStoreItem],
  default_image: image,
})

brand.define({
  data_store_items: [dataStoreItem],
  organizations: [organization],
})

caseStudy.define({
  address,
  data_store_items: [dataStoreItem],
  default_image: image,
  images: [image],
  project,
})

comparable.define({
  address,
  data_store_items: [dataStoreItem],
  default_image: image,
  images: [image],
})

contact.define({
  address,
  data_store_items: [dataStoreItem],
  users: [user],
  user_type: userType,
})

customFieldSet.define({
  fields: [customField],
})

digitalPage.define({
  assigned_images: [image],
  data_store_items: [dataStoreItem],
  default_proofing_link: shareLink,
  default_tracking_link: shareLink,
  digital_page_views: [digitalPageView],
  digital_template: digitalTemplate,
  project,
  reviews: [review],
  share_links: [shareLink],
  shortcode_data: digitalPageShortcodeData,
  status,
})

digitalTemplate.define({
  digital_template_components: [digitalTemplateComponent],
  digital_template_type: digitalTemplateType,
  images: [image],
  origin_template: digitalTemplate,
  pages: [digitalTemplatePage],
  preview_image: image,
  session_locks: [sessionLock],
  sidebar_template: digitalTemplate,
})

digitalTemplatePage.define({
  assigned_images: [image],
  assigned_form_templates: [formTemplate],
  attachments: [attachment],
  digital_template: digitalTemplate,
  images: [image],
  pages: [digitalTemplatePage],
  section_groups: [digitalTemplatePageSectionGroup],
})

digitalTemplatePageSectionGroup.define({
  sections: [digitalTemplatePageSection],
})

digitalTemplatePageSection.define({
  items: [digitalTemplatePageItem],
})

digitalTemplatePageItem.define({
  items: [digitalTemplatePageItem],
})

documentTemplate.define({
  custom_fields: [customField],
  default_image: image,
  page_preview_images: [image],
  pages: [documentTemplatePage],
  recipients: [documentRecipient],
})

documentTemplatePage.define({
  items: [documentTemplatePageItem],
})

eventCalendar.define({
  event_groups: [eventGroup],
  events: [event],
})

formTemplate.define({
  fields: [customField],
})

integrationPlatform.define({
  image,
})

organization.define({
  addresses: [address],
  awards: [award],
  data_store_items: [dataStoreItem],
  default_image: image,
  images: [image],
  organization_type: organizationType,
  users: [user],
})

project.define({
  address,
  attachments: [attachment],
  case_studies: [caseStudy],
  comparables: [comparable],
  contacts: [contact],
  data_store_items: [dataStoreItem],
  images: [image],
  lead_users: [user],
  organization,
  reviews: [review],
  support_users: [user],
  target_audiences: [targetAudience],
  teams: [team],
})

quote.define({
  items: [quoteItem],
  sections: [quoteSection],
})

review.define({
  address,
  default_image: image,
})

sessionLock.define({
  user,
})

shareLink.define({
  digital_page_views: [digitalPageView],
})

targetAudience.define({
  image,
})

team.define({
  default_image: image,
  lead_users: [user],
  support_users: [user],
  team_members: [teamMember],
})

teamMember.define({
  user,
})

user.define({
  awards: [award],
  brand,
  data_store_items: [dataStoreItem],
  default_image: image,
  images: [image],
  organizations_users: [organizationsUser],
  organizations: [organization],
  reviews: [review],
  role,
  tagged_images: [image],
})

// Export Schemas
export const Schemas = {
// START SCHEMA DEFINITION
  ACTIVITY: activity,
  ACTIVITY_ARRAY: [activity],
  ADDRESS: address,
  ADDRESS_ARRAY: [address],
  AGREEMENT: agreement,
  AGREEMENT_ARRAY: [agreement],
  AGREEMENT_SHORTCODE_DATA: agreementShortcodeData,
  API_LOG: apiLog,
  API_LOG_ARRAY: [apiLog],
  ASSET_LIBRARY: assetLibrary,
  ASSET_LIBRARY_ARRAY: [assetLibrary],
  ATTACHMENT: attachment,
  ATTACHMENT_ARRAY: [attachment],
  AWARD: award,
  AWARD_ARRAY: [award],
  BRAND: brand,
  BRAND_ARRAY: [brand],
  CAMPAIGN: campaign,
  CAMPAIGN_ARRAY: [campaign],
  CASE_STUDY: caseStudy,
  CASE_STUDY_ARRAY: [caseStudy],
  CHART_DATA: chartData,
  COMPARABLE: comparable,
  COMPARABLE_ARRAY: [comparable],
  CONTACT: contact,
  CONTACT_ARRAY: [contact],
  COUNTRY: country,
  COUNTRY_ARRAY: [country],
  CUSTOM_FIELD: customField,
  CUSTOM_FIELD_ARRAY: [customField],
  CUSTOM_FIELD_SET: customFieldSet,
  CUSTOM_FIELD_SET_ARRAY: [customFieldSet],
  DATA_STORE_ITEM: dataStoreItem,
  DATA_STORE_ITEM_ARRAY: [dataStoreItem],
  DIGITAL_PAGE: digitalPage,
  DIGITAL_PAGE_ARRAY: [digitalPage],
  DIGITAL_PAGE_METRIC_COLLECTION: digitalPageMetricCollection,
  DIGITAL_PAGE_METRIC_COLLECTION_ARRAY: [digitalPageMetricCollection],
  DIGITAL_PAGE_SHORTCODE_DATA: digitalPageShortcodeData,
  DIGITAL_PAGE_VIEW: digitalPageView,
  DIGITAL_PAGE_VIEW_ARRAY: [digitalPageView],
  DIGITAL_TEMPLATE: digitalTemplate,
  DIGITAL_TEMPLATE_ARRAY: [digitalTemplate],
  DIGITAL_TEMPLATE_COMPONENT: digitalTemplateComponent,
  DIGITAL_TEMPLATE_COMPONENT_ARRAY: [digitalTemplateComponent],
  DIGITAL_TEMPLATE_PAGE: digitalTemplatePage,
  DIGITAL_TEMPLATE_PAGE_ARRAY: [digitalTemplatePage],
  DIGITAL_TEMPLATE_PAGE_ITEM: digitalTemplatePageItem,
  DIGITAL_TEMPLATE_PAGE_ITEM_ARRAY: [digitalTemplatePageItem],
  DIGITAL_TEMPLATE_PAGE_SECTION: digitalTemplatePageSection,
  DIGITAL_TEMPLATE_PAGE_SECTION_ARRAY: [digitalTemplatePageSection],
  DIGITAL_TEMPLATE_PAGE_SECTION_GROUP: digitalTemplatePageSectionGroup,
  DIGITAL_TEMPLATE_PAGE_SECTION_GROUP_ARRAY: [digitalTemplatePageSectionGroup],
  DIGITAL_TEMPLATE_TYPE: digitalTemplateType,
  DIGITAL_TEMPLATE_TYPE_ARRAY: [digitalTemplateType],
  DOCUMENT_RECIPIENT: documentRecipient,
  DOCUMENT_RECIPIENT_ARRAY: [documentRecipient],
  DOCUMENT_TEMPLATE: documentTemplate,
  DOCUMENT_TEMPLATE_ARRAY: [documentTemplate],
  DOCUMENT_TEMPLATE_PAGE: documentTemplatePage,
  DOCUMENT_TEMPLATE_PAGE_ARRAY: [documentTemplatePage],
  DOCUMENT_TEMPLATE_PAGE_ITEM: documentTemplatePageItem,
  DOCUMENT_TEMPLATE_PAGE_ITEM_ARRAY: [documentTemplatePageItem],
  EVENT: event,
  EVENT_ARRAY: [event],
  EVENT_CALENDAR: eventCalendar,
  EVENT_CALENDAR_ARRAY: [eventCalendar],
  EVENT_GROUP: eventGroup,
  EVENT_GROUP_ARRAY: [eventGroup],
  FORM_TEMPLATE: formTemplate,
  FORM_TEMPLATE_ARRAY: [formTemplate],
  IMAGE: image,
  IMAGE_ARRAY: [image],
  INTEGRATION: integration,
  INTEGRATION_ARRAY: [integration],
  INTEGRATION_PLATFORM: integrationPlatform,
  INTEGRATION_PLATFORM_ARRAY: [integrationPlatform],
  ORGANIZATION: organization,
  ORGANIZATIONS_USER: organizationsUser,
  ORGANIZATIONS_USERS_ARRAY: [organizationsUser],
  ORGANIZATION_ARRAY: [organization],
  ORGANIZATION_SHORTCODE_LIST: organizationShortcodeList,
  ORGANIZATION_TYPE: organizationType,
  ORGANIZATION_TYPE_ARRAY: [organizationType],
  PROJECT: project,
  PROJECT_ARRAY: [project],
  PROJECT_TYPE: projectType,
  PROJECT_TYPE_ARRAY: [projectType],
  QUOTE: quote,
  QUOTE_ARRAY: [quote],
  QUOTE_ITEM: quoteItem,
  QUOTE_ITEM_ARRAY: [quoteItem],
  QUOTE_SECTION: quoteSection,
  QUOTE_SECTION_ARRAY: [quoteSection],
  REVIEW: review,
  REVIEW_ARRAY: [review],
  ROLE: role,
  ROLE_ARRAY: [role],
  SESSION_LOCK: sessionLock,
  SESSION_LOCK_ARRAY: [sessionLock],
  SHARE_LINK: shareLink,
  SHARE_LINK_ARRAY: [shareLink],
  STATUS: status,
  STATUS_ARRAY: [status],
  SYSTEM: system,
  SYSTEM_ARRAY: [system],
  TAG: tag,
  TAG_ARRAY: [tag],
  TARGET_AUDIENCE: targetAudience,
  TARGET_AUDIENCE_ARRAY: [targetAudience],
  TEAM: team,
  TEAM_ARRAY: [team],
  TEAM_MEMBER: teamMember,
  TEAM_MEMBER_ARRAY: [teamMember],
  USER: user,
  USER_ARRAY: [user],
  USER_TYPE: userType,
  USER_TYPE_ARRAY: [userType],
// END SCHEMA DEFINITION
}

export default Schemas
