import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import { digObject } from '@campaignhub/javascript-utils'

import {
  Box, Image, ListItem, Text,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const DetailList = (props) => {
  const {
    image,
    image: {
      dates: {
        created: { date_time_with_timezone: createdDateTime },
      },
    },
  } = props

  const filename = digObject(image, 'file_data.metadata.filename', '')
  const imageUrl = digObject(image, 'sizes.thumb_200x120', '')

  const { callbacks: { formatDate }, strings } = useLocalization(localizedStrings)

  return (
    <ListItem
      boxProps={{ borderBottom: '1px solid', padding: 'large' }}
    >
      <Image
        borderRadius={5}
        boxProps={{ marginRight: 'large' }}
        height={60}
        url={imageUrl}
        width={90}
      />

      <Box justifyContent="flex-start" flexDirection="column" minWidth="0" paddingRight="medium" width="auto">
        <Text fontSize="small" variant="ellipsis">
          {filename}
        </Text>

        <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="medium">
          {`${(strings.createdText || 'Created')} ${formatDate(createdDateTime, DateTime.DATE_HUGE)}`}
        </Text>
      </Box>
    </ListItem>
  )
}

DetailList.propTypes = {
  image: PropTypes.object.isRequired,
}

export default DetailList
