import { useSelector } from 'react-redux'
import isMobileBrowser from 'is-mobile'

import { useSetState } from '@campaignhub/react-hooks'

import useFormTemplate from '@hooks/useFormTemplate'
import useReduxAction from '@hooks/useReduxAction'

import defaultRequestOptions from '../defaultRequestOptions'

const isMobile = isMobileBrowser()

const addFormField = (formTemplate, showCreateOrEditCustomFieldModal, setState) => new Promise((resolve, reject) => {
  if (showCreateOrEditCustomFieldModal){
    const payload = {
      formTemplate,
    }

    setState({ selectedCustomFieldId: null })
    showCreateOrEditCustomFieldModal(payload)

    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showCreateOrEditCustomFieldModal not defined in callbacks'))
})

const editFormField = (customField, setState, showCreateOrEditCustomFieldModal) => {
  setState({ selectedCustomFieldId: customField.id })

  return new Promise((resolve, reject) => {
    if (showCreateOrEditCustomFieldModal){
      const payload = {
        customField,
      }

      showCreateOrEditCustomFieldModal(payload)

      return resolve({ success: true, result: payload })
    }

    return reject(new Error('showCreateOrEditCustomFieldModal not defined in callbacks'))
  })
}

const defaultState = {
  selectedCustomFieldId: null,
}

function useFormBuilder(options = {}){
  const { callbacks, formTemplateId } = options || {}
  const { showCreateOrEditCustomFieldModal } = callbacks || {}

  const [state, setState] = useSetState(defaultState)
  const { selectedCustomFieldId } = state

  const entities = useSelector(reduxState => reduxState.entities)
  const { customFields, formTemplates } = entities

  const formTemplate = formTemplates[formTemplateId] || {}

  const formTemplatePayload = useFormTemplate(formTemplate)

  // Load Form Template
  useReduxAction(
    'formTemplates',
    'loadFormTemplate',
    {
      ...defaultRequestOptions.formTemplate,
    },
    [formTemplateId],
    {
      dispatchAction: (action, requestOptions) => action(formTemplateId, requestOptions),
      shouldPerformFn: (entityReducer) => {
        const { loadedIds, loading } = entityReducer
        return formTemplateId && !loadedIds.includes(formTemplateId) && !loading
      },
    },
  )

  return {
    callbacks: {
      addFormField: () => addFormField(formTemplate, showCreateOrEditCustomFieldModal, setState),
      clearSelectedCustomFieldId: () => setState({ selectedCustomFieldId: null }),
      editFormField: customField => editFormField(customField, setState, showCreateOrEditCustomFieldModal),
      ...callbacks,
    },
    formTemplatePayload,
    isMobile,
    selectedCustomField: customFields[selectedCustomFieldId] || {},
    selectedCustomFieldId,
  }
}

export default useFormBuilder
