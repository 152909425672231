import {
  // faBrowser,
  faFileCheck,
  faChartBar,
  faContactBook,
  faHandHoldingUsd,
  faHome,
  // faQuestionCircle,
  faStore,
  faTachometer,
  faUserCrown,
  faWrench,
} from '@fortawesome/pro-light-svg-icons'
import { UserModel } from '@models/user'

type GenerateMainNavigationItemsProps = {
  callbacks: {
    unlinkImpersonation: () => void,
  },
  currentUser: UserModel,
  hasOrganizationsWhereAdmin: boolean,
  impersonatedUser: UserModel,
  isImpersonatingUser: boolean,
  isMobileDevice: boolean,
  strings: {
    navigationItems: Record<string, string>,
  },
}

const generateMainNavigationItems = (props: GenerateMainNavigationItemsProps) => {
  const {
    callbacks: { unlinkImpersonation },
    currentUser,
    hasOrganizationsWhereAdmin,
    impersonatedUser,
    isImpersonatingUser,
    isMobileDevice,
    strings,
  } = props

  return {
    sections: [
      {
        key: 'dashboard',
        visible: true,
        items: [
          {
            href: '#/',
            icon: faTachometer,
            key: 'dashboard',
            title: strings.navigationItems?.dashboard || 'Dashboard',
            visible: true,
          },
        ],
      },
      {
        key: 'overviews',
        title: 'Overviews',
        visible: true,
        items: [
          {
            href: '#/admin/analytics',
            icon: faChartBar,
            key: 'analytics',
            title: strings.navigationItems?.analytics || 'Analytics',
            visible: true,
          },
          {
            href: '#/overviews/projects?status=current',
            icon: faHome,
            key: 'projects',
            title: strings.navigationItems?.project || 'Projects',
            visible: true,
          },
          {
            href: '#/overviews/digitalPages?digitalTemplateType=proposal',
            icon: faHandHoldingUsd,
            key: 'proposals',
            title: strings.navigationItems?.proposals || 'Proposals',
            visible: true,
          },
          {
            href: '#/overviews/agreements',
            icon: faFileCheck,
            key: 'agreements',
            title: strings.navigationItems?.agreements || 'Agreements',
            visible: true,
          },
          {
            href: '#/overviews/contacts',
            icon: faContactBook,
            key: 'contacts',
            title: strings.navigationItems?.contacts || 'Contacts',
            visible: true,
          },
        ],
      },
      {
        key: 'admin',
        title: 'Admin',
        visible: true,
        items: [
          {
            href: hasOrganizationsWhereAdmin ? '#/admin/organizations/edit' : `#/admin/users/${currentUser.id}/edit`,
            icon: faStore,
            key: 'admin',
            title: strings.navigationItems?.accountAdmin || 'Account Admin',
            visible: true,
          },
          {
            href: '#/templates',
            icon: faWrench,
            key: 'templates',
            title: strings.navigationItems?.templates || 'Templates',
            visible: !isMobileDevice && hasOrganizationsWhereAdmin,
          },
        ],
      },
      {
        key: 'impersonation',
        title: 'Impersonating',
        visible: isImpersonatingUser,
        items: [
          {
            icon: faUserCrown,
            key: 'user',
            title: impersonatedUser.full_name,
            visible: true,
          },
          {
            icon: faHome,
            key: 'back-to-admin',
            onClick: () => unlinkImpersonation(),
            title: 'Back to Admin', // Dont localize, only admins see this
            visible: true,
          },
        ],
      },
    ],
  }
}

export default generateMainNavigationItems
