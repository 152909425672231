import PropTypes from 'prop-types'

import { Box, ListItem, Text } from '@campaignhub/suit-theme'

import useCurrentUser from '@hooks/useCurrentUser'
import { generateUrls, useRelations } from '@hooks/useUser'

const User = (props) => {
  const { user } = props

  const {
    email, first_name, last_name, mobile,
  } = user

  const { role } = useRelations(user)

  const { isAdmin, isBrandUser } = useCurrentUser()

  const isAdminOrBrandUser = isAdmin || isBrandUser

  const { editUserUrl } = generateUrls(isAdminOrBrandUser, user)

  return (
    <ListItem
      boxProps={{
        as: 'a',
        alignItems: 'center',
        borderBottom: '1px dashed',
        padding: 'large',
      }}
      href={editUserUrl}
      showAngleIcon
    >
      <Box flexDirection="column">
        <Text fontSize="small" marginBottom="small">
          {first_name} {last_name}
        </Text>

        <Text color="faintGrey" fontSize="xsmall">
          {email}
        </Text>
      </Box>

      <Box flexDirection="column" justifyContent="flex-end" marginLeft="auto" textAlign="right">
        <Text fontSize="small" marginBottom="small">
          {mobile}
        </Text>

        <Text color="faintGrey" fontSize="xsmall">
          {role.title || 'User'}
        </Text>
      </Box>
    </ListItem>
  )
}

User.propTypes = {
  user: PropTypes.object.isRequired,
}

export default User
