export default {
  default: {
    formFieldLabels: {
      findUser: 'Find a User',
    },
    mapResults: {
      title: '{global.organizationType.shortName.singular}',
    },
    userSearchPlaceholder: 'Search for a user',
  },
}
