import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'
import {
  Box, Button, FormField, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import useEventGroup, { useEventGroupForm } from '@hooks/useEventGroup'
import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const confirmDelete = (deleteEventGroup, deleteFn, strings) => {
  swal
    .fire({
      title: strings.swal?.title || 'Delete Group?',
      text: strings.swal?.text || 'Are you sure? All events within the group will be deleted as well.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: strings.swal?.confirmButtonText || 'Yes, delete it',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        deleteEventGroup(deleteFn)
      }
    })
}

const changePreparationPhase = (e, handlers, setEntityState, strings) => {
  const { onChange } = handlers
  const { value } = e.target

  if (value === 'true'){
    setEntityState({
      subtitle: strings.preparation || 'Preparation',
    })
  }

  onChange(e)
}

const CreateOrEditEventGroupModal = (props) => {
  const {
    callbacks,
    callbacks: { createEventGroup, deleteEventGroup, updateEventGroup },
    eventGroup: newEventGroup,
    isTemplate,
    modalKey,
    showModal,
  } = props

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, `${modalKey}`, {})
  const { callbacks: modalCallbacks, eventGroup: existingEventGroup } = modalPayload
  const { createEventGroup: createFn, updateEventGroup: updateFn } = modalCallbacks || {}

  const eventGroup = existingEventGroup || newEventGroup
  const newRecord = !eventGroup.id

  const eventGroupPayload = useEventGroup(eventGroup)
  const {
    callbacks: { deleteEventGroup: deleteFn },
  } = eventGroupPayload

  const eventGroupFormPayload = useEventGroupForm(eventGroup, {isTemplate})
  const {
    creating,
    deleting,
    entityState,
    entityState: {
      days_from_start,
      description,
      duration_in_days,
      end_date,
      preparation_group,
      start_date,
      subtitle,
      title,
    },
    errors,
    handlers,
    saveEnabled,
    setEntityState,
    updating,
  } = eventGroupFormPayload

  const actionFn = newRecord
    ? () => createEventGroup(entityState, createFn)
    : () => updateEventGroup(entityState, updateFn)

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal callbacks={callbacks} disableOverlay modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header callbacks={callbacks} title={newRecord ? 'Create' : 'Edit'} titleSecondLine="Group" />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <FormField direction="column" label={strings.labelTitle || 'Title'}>
            <input name="title" type="text" value={title} {...handlers} />
          </FormField>

          <FormField direction="column" label={strings.labelPreparationPhase || 'Preparation Phase?'} marginTop="large">
            <select
              data-value-type="boolean"
              onChange={e => changePreparationPhase(e, handlers, setEntityState, strings)}
              name="preparation_group"
              value={preparation_group}
            >
              <option value="false">{strings.optionNo || 'No'}</option>
              <option value="true">{strings.optionYes || 'Yes'}</option>
            </select>
          </FormField>

          <FormField direction="column" label={strings.labelSubtitle || 'Subtitle'} marginTop="large">
            <input name="subtitle" type="text" value={subtitle} {...handlers} />
          </FormField>

          <FormField direction="column" label={strings.labelDescription || 'Description'} marginTop="large">
            <textarea name="description" style={{ height: 80 }} value={description} {...handlers} />
          </FormField>

          {!isTemplate && (
            <Box>
              <FormField
                boxProps={{ width: 'calc(50% - 4px)', marginRight: 'small' }}
                direction="column"
                label={strings.labelStartDay || 'Start Date'}
                marginTop="large"
              >
                <input name="start_date" type="date" value={start_date} {...handlers} />
              </FormField>

              <FormField
                boxProps={{ width: 'calc(50% - 4px)', marginLeft: 'small' }}
                direction="column"
                label={strings.labelEndDate || 'End Date'}
                marginTop="large"
              >
                <input
                  disabled={!start_date}
                  min={start_date}
                  name="end_date"
                  type="date"
                  value={end_date}
                  {...handlers}
                />
              </FormField>
            </Box>
          )}

          {isTemplate && (
            <Box>
              <FormField
                boxProps={{ flexShrink: 1, marginRight: 'small' }}
                data-value-type="number"
                direction="column"
                label={strings.labelDaysFromStart || 'Days from Start'}
                marginTop="large"
              >
                <input name="days_from_start" type="number" value={days_from_start} {...handlers} />
              </FormField>

              <FormField
                boxProps={{ flexShrink: 1, marginLeft: 'small' }}
                data-value-type="number"
                direction="column"
                errorMessage={errors.duration_in_days}
                hideErrorMessage
                label={strings.labelDuration || 'Duration (Days)'}
                marginTop="large"
              >
                <input disabled min="1" name="duration_in_days" type="number" value={duration_in_days} {...handlers} />
              </FormField>
            </Box>
          )}

          <FormField direction="column" label={strings.labelMoreOptions || 'More Options'} marginTop="large">
            <Button
              buttonStyle="secondaryUtility"
              disabled={!saveEnabled}
              icon={<FontAwesomeIcon icon={faTrashAlt} />}
              loading={deleting}
              onClick={() => confirmDelete(deleteEventGroup, deleteFn, strings)}
              size="medium"
            >
              {strings.deleteButton || 'Delete Group'}
            </Button>
          </FormField>
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={creating || updating}
          onClick={actionFn}
          size="large"
        >
          {strings.saveButton || 'Save Group'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

CreateOrEditEventGroupModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  eventGroup: PropTypes.object,
  isTemplate: PropTypes.bool,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

CreateOrEditEventGroupModal.defaultProps = {
  modalKey: 'CreateOrEditEventGroupModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <CreateOrEditEventGroupModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
