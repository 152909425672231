import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, Checkbox, ListItem,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const ImageLibrary = (props) => {
  const {
    alreadyImported,
    assetLibrary,
    callbacks: { toggleAssetLibrary },
    checked,
  } = props

  const { title } = assetLibrary

  const { strings } = useLocalization(localizedStrings)

  return (
    <ListItem.WithCheckbox
      boxProps={{ border: 'none' }}
      checkbox={(
        <Checkbox
          checked={checked}
          onClick={() => toggleAssetLibrary()}
        />
      )}
    >
      <Box
        color={alreadyImported ? 'lightGrey' : 'bodyFontLightColor'}
        fontSize="small"
      >
        {title} {alreadyImported && ` ${strings.imported || '(Imported)'}`}
      </Box>
    </ListItem.WithCheckbox>
  )
}

ImageLibrary.propTypes = {
  alreadyImported: PropTypes.bool,
  assetLibrary: PropTypes.object,
  callbacks: PropTypes.object.isRequired,
  checked: PropTypes.bool,
}

export default ImageLibrary
