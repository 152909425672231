import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { useSetState } from '@campaignhub/react-hooks'

import PageContext from '@contexts/pageContext'

import { shouldRenderPageItem } from '@functions/digitalTemplatePageItem'

import useDeviceStyle from '@hooks/useDeviceStyle'

// eslint-disable-next-line import/no-cycle
import ElementContainer from '../../ElementContainer'

const State = (props) => {
  const {
    context: componentContext, itemEntities, id, options,
  } = props

  const { defaultState, renderConditions } = options || {}

  const [state, setState] = useSetState(defaultState || {})

  // Context
  const pageContext = useContext(PageContext)
  const { shortcodeData } = pageContext || {}

  const contextData = { ...shortcodeData, ...componentContext }

  // Container
  const stateItemContainer = itemEntities[id] || {}

  // Style
  const style = useDeviceStyle(props)

  // Should Render
  const shouldRender = shouldRenderPageItem(renderConditions, contextData, options)
  if (!shouldRender) return null

  return (
    <div style={style}>
      <ElementContainer
        callbacks={{
          setState,
        }}
        forwardContextToItems={{
          ...state,
        }}
        itemEntities={itemEntities}
        section={stateItemContainer}
        renderNested
        render={({ itemComponents }) => itemComponents.map(component => component)}
      />
    </div>
  )
}

State.propTypes = {
  context: PropTypes.object,
  itemEntities: PropTypes.object.isRequired,
  id: PropTypes.string,
  options: PropTypes.object.isRequired,
}

export default State
