import { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Box, ModalContext, SidebarModal } from '@campaignhub/suit-theme'

import { useSetState } from '@campaignhub/react-hooks'

import { digObject } from '@campaignhub/javascript-utils'

import useEventCalendar, { useEventCalendarForm } from '@hooks/useEventCalendar'
import useLocalization from '@hooks/useLocalization'

import CreateFromTemplate from './screens/CreateFromTemplate'
import CreateNew from './screens/CreateNew'
import DefaultState from './screens/DefaultState'

import localizedStrings from './localizedStrings'

const selectScreen = (selectedScreen, setState, setEntityState) => {
  setEntityState({ event_calendar_template_id: null, start_date: '', title: '' })
  setState({ selectedScreen })
}

const selectTemplate = (eventCalendarTemplate, setState, setEntityState) => {
  setEntityState({
    description: eventCalendarTemplate?.description,
    event_calendar_template_id: eventCalendarTemplate.id,
    title: eventCalendarTemplate.title,
  })
  setState({ selectedScreen: 'CreateNew' })
}

const defaultState = {
  selectedScreen: '',
}

const CreateEventCalendarModal = (props) => {
  const {
    callbacks, disableAnimation, disableOverlay, modalKey, showModal,
  } = props
  const { createEventCalendar } = callbacks || {}

  const [state, setState] = useSetState(defaultState)
  const { selectedScreen } = state

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, modalKey, {})
  const {
    callbacks: { createEventCalendar: createFn },
    eventCalendar,
    selectedScreen: initSelectedScreen,
  } = modalPayload

  const customRequiredFields = eventCalendar?.saved_template ? [] : [{ key: 'start_date' }]

  const eventCalendarFormPayload = useEventCalendarForm(eventCalendar, { customRequiredFields })
  const { entityState, setEntityState } = eventCalendarFormPayload

  const eventCalendarPayload = useEventCalendar(eventCalendar)

  useEffect(() => {
    if (initSelectedScreen) setState({ selectedScreen: initSelectedScreen })
  }, [initSelectedScreen])

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        disableAnimation={disableAnimation}
        disableOverlay={disableOverlay}
        title={strings.sideBarModalHeader?.title || 'Event'}
        titleSecondLine={strings.sideBarModalHeader?.titleSecondLine || 'Calendars'}
      />

      <Box flexDirection="column" flexShrink={0}>
        {!selectedScreen && (
          <DefaultState callbacks={{ selectScreen: screen => selectScreen(screen, setState, setEntityState) }} />
        )}

        {selectedScreen === 'CreateNew' && (
          <CreateNew
            callbacks={{
              createEventCalendar: () => createEventCalendar(entityState, createFn),
              selectScreen: screen => selectScreen(screen, setState, setEntityState),
            }}
            eventCalendarFormPayload={eventCalendarFormPayload}
            eventCalendarPayload={eventCalendarPayload}
          />
        )}

        {selectedScreen === 'CreateFromTemplate' && (
          <CreateFromTemplate
            callbacks={{
              selectTemplate: templateId => selectTemplate(templateId, setState, setEntityState),
              selectScreen: screen => selectScreen(screen, setState, setEntityState),
            }}
            eventCalendarFormPayload={eventCalendarFormPayload}
          />
        )}
      </Box>
    </SidebarModal>
  )
}

CreateEventCalendarModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  disableAnimation: PropTypes.bool,
  disableOverlay: PropTypes.bool,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

CreateEventCalendarModal.defaultProps = {
  modalKey: 'CreateEventCalendarModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <CreateEventCalendarModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
