export default {
  default: {
    availableReviews: 'Available Reviews',
    blankState: {
      paragraph:
        'To import reviews from another platform you’ll need to set up a suitable integration. Integrations can be managed in your client admin.',
    },
    deselectAllButton: 'Deselect All',
    externalPlatform: 'External Platform',
    importReviewsIntoLabel: 'Importing Reviews into',
    importReviewsButton: 'Import Reviews',
    moreOptionsLabel: 'More Options',
    noticeBox: {
      title: 'Nothing to Import',
      paragraph:
        'No Reviews Available. Create a review in {integrationPlatformTitle} for it to be made available here.',
    },
    platformLabel: 'Platform',
    importReview: 'Import Reviews Queued',
    searchReviewsLabel: 'Search Reviews',
    selectAllButton: 'Select All',
    selectReviewsLabel: 'Select Reviews',
    title: 'Import',
    titleSecondLine: 'Reviews',
    options: 'Please Select...',
  },
}
