export default {
  default: {
    addressSearch: 'Search for an exact match property',
    agencyProperties: 'Your Agency Properties',
    marketProperties: 'Market Data Properties',
    noAgencyProperties: 'No Agency Property Results.',
    noMarketProperties: 'No Market Data Property Results.',
    propertySearch: 'Exact Match',
    resetMatch: 'Reset Property Match',
    results: {
      type: 'count',
      none: '{count} found that are a close match to',
      one: '{count} found that is a close match to',
      many: '{count} found that are a close match to',
    },
  },
}
