import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

type UseSystemsOptions = {
  performHttpRequests?: boolean,
}

function useSystems(options: UseSystemsOptions = {}) {
  const { performHttpRequests } = options

  const { loading: loadingSystems } = useReduxAction('systems', 'loadSystems', {}, [performHttpRequests], {
    shouldPerformFn: (entityReducer) => {
      const { errors, loaded, loading } = entityReducer
      return performHttpRequests && !loaded && !loading && !errors.length
    },
  })

  const { systems } = useSelector(reduxState => reduxState.entities)

  return {
    loading: loadingSystems,
    systems,
  }
}

export default useSystems
