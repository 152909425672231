export default {
  default: {
    navigationItems: {
      accountSettings: 'Account Settings',
      activities: 'Activities',
      caseStudies: 'Case Studies',
      customFields: 'Custom Fields',
      documents: 'Documents',
      eventCalendars: 'Calendars',
      fileLibrary: 'File Library',
      forms: 'Forms',
      integrations: 'Integrations',
      libraries: 'LIBRARIES',
      organizationDetails: '{global.organizationType.shortName.singular} Details',
      quotes: 'Quotes',
      reviews: 'Reviews',
      templates: 'TEMPLATES',
      targetAudiences: 'Target Audiences',
      teams: 'Teams',
      users: 'Users',
    },
  },
}
