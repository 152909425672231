export type OrganizationsUserModel = {
  cache_key: number,
  id: number,
  admin: boolean,
  approved: boolean,
  approved_by: number,
  default: boolean,
  organization_id: number,
  owner: boolean,
  sort: number,
  user_id: number,
}

export type OrganizationsUserRequestOptions = {}

const state = {
  admin: false,
  approved: false,
  approved_by: null,
  id: null,
  organization_id: null,
  owner: false,
  user_id: null,
}

export const requiredFields = [
  { key: 'organization_id' },
  { key: 'user_id' },
]

export default state
