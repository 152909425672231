import type { DataStoreItemModel } from '@models/dataStoreItem'

type ParamsType = {
  editorId: number,
  editorType: string,
  groupKey: string,
  subjectId: number,
  subjectType: string,
}

function findDataStoreItem(dataStoreItems: DataStoreItemModel[], behaviour: string, key: string, params: ParamsType = {}) {
  if (!key) return {}

  const {
    editorId, editorType, groupKey, subjectId, subjectType,
  } = params || {}

  const dataStoreItem = dataStoreItems.find((item) => {
    const {
      editor_id,
      editor_type,
      group_key,
      key: itemKey,
      subject_id,
      subject_type,
    } = item

    const keysAndSubjectMatch = itemKey === key && subject_type === subjectType && subject_id === subjectId

    if (behaviour === 'group'){
      return keysAndSubjectMatch && groupKey && group_key === groupKey
    }

    if (behaviour === 'replace'){
      return keysAndSubjectMatch
    }

    return (
      keysAndSubjectMatch
      && ((groupKey && group_key === groupKey) || (editor_type === editorType && editor_id === editorId))
    )
  })

  return dataStoreItem || {}
}

export default findDataStoreItem
