import { useMemo } from 'react'

import { matchFilterString, multiFilteredObjectArraySelector, sortArrayBy } from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import defaultRequestOptions from '@sections/Client/packs/Admin/defaultRequestOptions'

import type { FormTemplateModel, FormTemplateRequestOptions } from '@models/types'
import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['formTemplates']

type FormTemplateFilters = {
  brand_id?: number,
  organization_id?: number,
  owner_type?: string,
  production_ready?: boolean,
  string?: string,
}

type UseFormTemplatesOptions = {
  entityKey?: string,
  filters?: FormTemplateFilters,
  ownerId?: number,
  ownerType?: string,
  performHttpRequests?: boolean,
  requestOptions?: FormTemplateRequestOptions,
}

function useFormTemplates(options: UseFormTemplatesOptions = {}) {
  const {
    entityKey, filters = {}, ownerId, ownerType, requestOptions,
  } = options

  const {
    brand_id: filterBrandId,
    organization_id: filterOrganizationId,
    owner_type: filterOwnerType,
    production_ready: filterProductionReady,
    string: filterString,
  } = filters

  const {
    updatedEntities: { formTemplates: formTemplatesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { formTemplates } = useSelector(reduxState => reduxState.entities)

  const filteredFormTemplates = useMemo(() => {
    const filtered = Object.values(formTemplates).filter((formTemplate: FormTemplateModel) => {
      const {
        id, owner_id, owner_type, production_ready, title,
      } = formTemplate

      // If ownerType and OwnerId supplied for specific match
      const ownerMatch = ownerType === 'Organization' || ownerType === 'Brand'
        ? ownerId === owner_id && ownerType === owner_type
        : true

      const productionStatus = typeof filterProductionReady !== 'undefined'
        ? String(filterProductionReady) === String(production_ready)
        : true

      // Brand or organization match
      const brandMatch = filterBrandId && filterOwnerType === 'Brand'
        ? owner_id === filterBrandId && owner_type === 'Brand'
        : true

      const organizationMatch = filterOrganizationId && filterOwnerType === 'Organization'
        ? owner_id === filterOrganizationId && owner_type === 'Organization'
        : true

      const systemMatch = filterOwnerType === 'System' ? owner_type === 'System' && owner_id === 1 : true
      const idMatch = id.toString() === filterString
      const titleMatch = matchFilterString(title, filterString)
      const matchString = idMatch || titleMatch

      return brandMatch && matchString && organizationMatch && ownerMatch && productionStatus && systemMatch
    })

    return sortArrayBy(filtered, 'asc', 'title')
  }, [formTemplatesUpdatedAt, JSON.stringify(filters)])

  const filteredFormTemplatesCount = filteredFormTemplates.length
  const hasFilteredFormTemplates = !!filteredFormTemplatesCount

  const systemTemplates = useSelector(reduxState => multiFilteredObjectArraySelector(
    reduxState,
    'formTemplates',
    [{ key: 'owner_type', value: 'System' }],
  ))
  const hasSystemTemplates = !!systemTemplates.length

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredFormTemplatesCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingFormTemplates } = useReduxAction(
    'formTemplates',
    'loadFormTemplates',
    {
      entityKey,
      owner_id: ownerId,
      owner_type: ownerType,
      production_ready: filterProductionReady,
      ...defaultRequestOptions.formTemplate,
      ...requestOptions,
      ...filtersWithOffset,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredFormTemplates,
    filteredFormTemplatesCount,
    hasFormTemplates: hasFilteredFormTemplates,
    hasSystemTemplates,
    loading: loadingFormTemplates,
    systemTemplates,
  }
}

export default useFormTemplates
