export default {
  default: {
    confirmDelete: {
      title: 'Delete Item?',
      text: 'Are you sure? ',
      confirmButtonText: 'Yes, delete it.',
    },
    labelTools: 'Tools',
    deleteButton: 'Delete Item',
  },
}
