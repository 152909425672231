import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink, faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Image, Link, ListItem, Text,
} from '@campaignhub/suit-theme'

const ModalProject = (props) => {
  const {
    callbacks: { removeProject },
    project: {
      secondaryText, text, subtitle, title,
    },
  } = props

  return (
    <ListItem boxProps={{ alignItems: 'center' }} disableHover>
      <Image boxProps={{ backgroundColor: 'hoverLightGrey', fontSize: 'small' }} circle height={30} width={30}>
        <FontAwesomeIcon icon={faLink} />
      </Image>

      <Box flexDirection="column" marginLeft="large">
        <Text fontSize="small">{text || title}</Text>

        {(secondaryText || subtitle) && (
          <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="medium">
            {secondaryText || subtitle}
          </Text>
        )}
      </Box>

      <Link greyLink onClick={removeProject} textProps={{ fontSize: 'small' }}>
        <FontAwesomeIcon icon={faTrashAlt} />
      </Link>
    </ListItem>
  )
}

ModalProject.propTypes = {
  callbacks: PropTypes.shape({
    removeProject: PropTypes.func,
  }).isRequired,
  project: PropTypes.shape({
    secondaryText: PropTypes.string,
    subtitle: PropTypes.string,
    text: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
}

export default ModalProject
