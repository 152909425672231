export default {
  default: {
    createFromTemplate: {
      title: 'Use a Template',
      subtitle: 'Choose from saved templates',
    },
		createNew: {
      title: 'Empty Calendar',
      subtitle: 'Start with a blank canvas',
		},
  },
}
