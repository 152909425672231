import PropTypes from 'prop-types'

import {
  Box, Button, Columns, DashboardModule, LoadingModule, PageHeader,
} from '@campaignhub/suit-theme'
import { faArchive } from '@fortawesome/pro-light-svg-icons'

import useAttachments from '@hooks/useAttachments'
import useLocalization from '@hooks/useLocalization'
import useOrganizationSelector from '@hooks/useOrganizationSelector'
import useProject from '@hooks/useProject'

import AttachmentUpload from '@components/AttachmentUpload'

import defaultRequestOptions from '@sections/Client/packs/Project/defaultRequestOptions'

import Document from '../Document'
import DocumentsBlankState from '../DocumentsBlankState'

import localizedStrings from './localizedStrings'

const PageContent = (props) => {
  const { project: initProject } = props

  const {
    callbacks: { manageProjectAttachments },
    loading,
    organization,
    project,
  } = useProject(initProject)

  const entityKey = `Project${project.id}`

  const { selectedOrganization } = useOrganizationSelector()

  const attachmentsPayload = useAttachments(
    {
      entityKey,
      filters: {
        ownerId: selectedOrganization.id,
        ownerType: selectedOrganization.type,
        subjectId: project.id,
        subjectType: 'Project',
      },
      performHttpRequests: true,
      requestOptions: defaultRequestOptions.attachment,
    },
  )

  const { filteredAttachments, hasAttachments } = attachmentsPayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      <PageHeader
        actionContent={(
          <Button buttonStyle="primaryCreate" onClick={manageProjectAttachments} size="medium" width="auto">
            {strings.buttons?.create || 'Create'}
          </Button>
        )}
        activeTabBarItemKey="files"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        nestedNavigation
        tabBarItems={[
          {
            icon: faArchive,
            key: 'files',
            title: strings.tabTitle || 'Files',
          },
        ]}
        title={strings.title || 'Files'}
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && !hasAttachments && (
              <Box marginBottom="large">
                <DocumentsBlankState />
              </Box>
            )}

            {hasAttachments && (
              <DashboardModule title={strings.title || 'Files'}>
                <Box flexDirection="column" maxHeight={415} overflow="auto">
                  {filteredAttachments.map(attachment => (
                    <Document key={attachment.id} attachment={attachment} project={project} />
                  ))}
                </Box>
              </DashboardModule>
            )}

            {project.id && organization.id && (
              <DashboardModule title={strings.upload || 'Upload Files'}>
                <AttachmentUpload
                  ownerId={organization.id}
                  ownerType="Organization"
                  requestOptions={defaultRequestOptions.attachment}
                  subjectId={project.id}
                  subjectType="Project"
                />
              </DashboardModule>
            )}
          </Columns.Main>
        </Columns>
      </Box>
    </>
  )
}

PageContent.propTypes = {
  callbacks: PropTypes.shape({
    manageProjectAttachments: PropTypes.func.isRequired,
  }),
  project: PropTypes.object.isRequired,
}

export default PageContent
