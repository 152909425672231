import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { digObject } from '@campaignhub/javascript-utils'
import { Button, FormField, SidebarModal } from '@campaignhub/suit-theme'

import EntitySelector from '@components/EntitySelector'

const Sidebar = (props) => {
  const {
    digitalTemplateFormPayload: {
      callbacks: { updateDigitalTemplate },
      entityState,
      entityState: { options, sidebar_template_id },
      handlers,
      setEntityState,
      updating,
    },
  } = props

  const entityKey = 'DigitalTemplates-TemplatePartials'
  const { loadedForKeys } = useSelector(reduxState => reduxState.digitalTemplates)

  const sidebarOptions = digObject(options, 'sidebar', {})
  const { defaultState, width } = sidebarOptions

  return (
    <SidebarModal.ExpandableSectionBox label="Sidebar">
      <EntitySelector
        boxProps={{ marginBottom: 'large' }}
        callbacks={{
          selectItem: digitalTemplate => setEntityState({
            sidebar_template_id: digitalTemplate ? digitalTemplate.id : null,
          }),
        }}
        entityKey="digitalTemplates"
        searchRequestOptions={{
          digital_template_type_key: 'template_sidebar',
          entityKey,
          performHttpRequests: !loadedForKeys.includes(entityKey),
          template_partial: true,
        }}
        endpoint="digital_templates"
        selectedItemId={Number(sidebar_template_id)}
      />

      <FormField boxProps={{ marginBottom: 'large' }} label="Width">
        <input name="options.sidebar.width" value={width} type="text" {...handlers} />
      </FormField>

      <FormField label="Default State">
        <select name="options.sidebar.defaultState" value={defaultState} {...handlers}>
          <option value="">Hidden</option>
          <option value="show">Show</option>
          <option value="sticky">Sticky</option>
        </select>
      </FormField>

      <Button
        buttonStyle="ghostCreate"
        loading={updating}
        marginTop="large"
        onClick={() => updateDigitalTemplate(entityState)}
        size="medium"
      >
        Save Sidebar Settings
      </Button>
    </SidebarModal.ExpandableSectionBox>
  )
}

Sidebar.propTypes = {
  digitalTemplateFormPayload: PropTypes.shape({
    callbacks: PropTypes.shape({
      updateDigitalTemplate: PropTypes.func.isRequired,
    }).isRequired,
    entityState: PropTypes.object.isRequired,
    setEntityState: PropTypes.func.isRequired,
    handlers: PropTypes.object.isRequired,
    saveEnabled: PropTypes.bool,
    updating: PropTypes.bool,
  }).isRequired,
}

export default Sidebar
