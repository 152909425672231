import React, { useContext } from 'react'
import cloneDeep from 'lodash/cloneDeep'

import { useForm, useLatestEntity } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import { digObject, launchModal } from '@campaignhub/javascript-utils'

import useDispatch from '@hooks/useDispatch'
import useSelector from '@hooks/useSelector'

import * as imageActions from '@redux/modules/image'

import defaultFormState, { requiredFields } from '@models/image'

import PageContext from '@contexts/pageContext'

import type { AppDispatch } from '@redux/store'
import type { DeleteParams } from '@redux/modules/types'
import type { ImageModel } from '@models/types'

type UpdateImageParams = {
  dispatch: AppDispatch,
  image: ImageModel,
  imageParams: Partial<ImageModel>,
}

const updateImage = (params: UpdateImageParams) => {
  const {
    dispatch, image, imageParams,
  } = params
  const { data } = imageParams

  const { updateImage: updateFn } = imageActions

  const updatedParams = {
    id: image.id,
    ...imageParams,
  }

  if (data){
    const imageData = image.data || {}
    updatedParams.data = JSON.stringify({
      ...cloneDeep(imageData),
      ...data,
    })
  }

  return dispatch(updateFn(updatedParams))
}

type DeleteImageParams = {
  dispatch: AppDispatch,
  image: DeleteParams<ImageModel>,
}

const deleteImage = (params: DeleteImageParams) => {
  const { dispatch, image } = params
  const { deleteImage: deleteFn } = imageActions

  return dispatch(deleteFn(image))
}

const downloadImage = (e: React.MouseEvent, url: string) => {
  if (!url) return

  e.stopPropagation()
  e.preventDefault()
  window.open(url)
}

const selectImage = (image:ImageModel, dispatch: AppDispatch) => {
  const { selectImageId: selectFn } = imageActions
  return dispatch(selectFn(image.id))
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useImageForm(
  image: Partial<ImageModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const imageForm = useForm(
    defaultFormState,
    { entity: image, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [image.id, image.owner_id, image.cache_key],
  )

  return {
    ...imageForm,
  }
}

function useImage(initEntity: Partial<ImageModel> = {}) {
  const { entity: image }: { entity: ImageModel} = useLatestEntity(initEntity, 'images')
  const { dates } = image

  const { deleting, loading, updating } = useSelector(reduxState => reduxState.images)

  const dispatch = useDispatch()

  const { callbacks } = useContext(PageContext)

  const isDefaultImage = image.sort === 0

  const createdDateTime = digObject(dates, 'created.date_time_with_timezone')

  return {
    callbacks: {
      deleteImage: () => deleteImage({ dispatch, image }),
      downloadImage,
      editImage: (updateDefaultImage: ()=> void) => launchModal({
        callbacks,
        modalKey: 'EditImageModal',
        payload: {
          callbacks: {
            deleteImage: () => deleteImage({ dispatch, image }),
            downloadImage,
            updateDefaultImage,
            updateImage: (imageParams: Partial<ImageModel>) => (
              updateImage({
                image, imageParams, dispatch,
              })
            ),
          },
          image,
        },
      }),
      selectImage: () => selectImage(image, dispatch),
      updateImage: (imageParams: Partial<ImageModel>) => (
        updateImage({
          image, imageParams, dispatch,
        })
      ),
    },
    createdDateTime,
    deleting,
    image,
    isDefaultImage,
    loading,
    updating,
  }
}

export default useImage
