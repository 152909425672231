import { useSelector } from 'react-redux'

import { digObject } from '@campaignhub/javascript-utils'
import { useLatestEntity } from '@campaignhub/react-hooks'

function useDigitalTemplatePageItem(options = {}){
  const { digitalTemplatePageItemId } = options

  const { entity: digitalTemplatePageItem } = useLatestEntity(
    { id: digitalTemplatePageItemId },
    'digitalTemplatePageItems',
  )

  const entities = useSelector(reduxState => reduxState.entities)
  const { attachments } = entities

  const itemAttachment = Object.values(attachments).find((attachment) => {
    const data = digObject(attachment, 'data', {})

    if (!data) return {}

    return data.digital_template_page_item_id === digitalTemplatePageItemId
  })

  return {
    digitalTemplatePageItem,
    itemAttachment,
  }
}

export default useDigitalTemplatePageItem
