import { useContext } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, FormField, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import { HandleCallbackActionParams, digObject } from '@campaignhub/javascript-utils'

import useAward, { useAwardForm } from '@hooks/useAward'
import useEntityCustomFieldsForm from '@hooks/useEntityCustomFieldsForm'
import useImage from '@hooks/useImage'
import useOrganizationSelector from '@hooks/useOrganizationSelector'
import useLocalization from '@hooks/useLocalization'

import confirmDelete from '@functions/entity'

import EntityCustomFields from '@components/EntityCustomFields'
import ModalImageUpload from '@components/ModalComponents/ModalImageUpload'

import { AwardModel } from '@models/award'

import localizedStrings from './localizedStrings'

type CreateOrEditAwardModalCallbacks = {
  closeModal: () => void,
  createAward: (payload: HandleCallbackActionParams) => void,
  deleteAward: (payload: HandleCallbackActionParams) => void,
  updateAward: (payload: HandleCallbackActionParams) => void,
}

type CreateOrEditAwardModalProps = {
  award: Partial<AwardModel>,
  callbacks: CreateOrEditAwardModalCallbacks,
  modalKey?: string,
  showModal: () => void,
}

const CreateOrEditAwardModal = (props: CreateOrEditAwardModalProps) => {
  const {
    award: initAward, callbacks, modalKey, showModal,
  } = props
  const {
    closeModal, createAward, deleteAward, updateAward,
  } = callbacks

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const awardPayload = digObject(modalData, `${modalKey}`, {})
  const { award: existingAward } = awardPayload

  const award = existingAward?.id ? existingAward : initAward

  const {
    callbacks: {
      createAward: createFn,
      createOrEditAward,
      deleteAward: deleteFn,
      updateAward: updateFn,
    },
    creating,
    defaultImage,
    updating,
  } = useAward(award)

  const {
    callbacks: { deleteImage },
    deleting,
  } = useImage(defaultImage)

  const defaultImageUrl = digObject(defaultImage, 'sizes.thumb_660x360', '')

  const awardFormPayload = useAwardForm(award)
  const {
    entityState,
    entityState: { body, title },
    errors,
    handlers,
    saveEnabled,
  } = awardFormPayload

  const { selectedOrganization } = useOrganizationSelector()

  const customFieldsForm = useEntityCustomFieldsForm({ id: entityState.id, type: 'Award' }, selectedOrganization, {
    performHttpRequests: true,
  })

  const {
    callbacks: { buildNestedValues },
    saveEnabled: customFieldsSaveEnabled,
  } = customFieldsForm

  const { strings } = useLocalization(localizedStrings)

  const afterActionCallback = (data: {entity: {}}) => (entityState.id
    ? closeModal()
    : createOrEditAward({ award: data?.entity }))

  const createOrUpdateAwardPayload = {
    callbacks: {
      action: entityState.id ? updateFn : createFn,
      afterAction: ({ response: { data } }: {response: { data: { entity: {} }}}) => afterActionCallback(data),
    },
    entityParams: { award: entityState, customFields: buildNestedValues() },
    requestOptions: {
      include_award_data_store_items: true,
    },
    toastText: `Award ${entityState.id ? 'Updated' : 'Created'} Successfully.`,
  }

  const confirmDeletePayload = {
    callbacks: {
      afterAction: closeModal,
      deleteEntity: deleteAward,
      deleteFn,
    },
    strings: {
      entityName: 'Award',
    },
  }

  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        title={award?.id ? strings.updateTitle || 'Update' : strings.createTitle || 'Create'}
        titleSecondLine={strings.titleSecondLine || 'Award'}
      />

      <SidebarModal.Content>
        <FormField
          direction="column"
          label={strings.formFieldLabels?.title || '* Title'}
          errorMessage={errors.title}
        >
          <input data-validate-field-on="change" name="title" type="text" value={title} {...handlers} />
        </FormField>

        <FormField
          direction="column"
          label={strings.formFieldLabels?.body || 'Body'}
          marginTop="large"
          errorMessage={errors.body}
        >
          <textarea name="body" style={{ height: 80 }} type="text" value={body} {...handlers} />
        </FormField>

        <EntityCustomFields customFieldsForm={customFieldsForm} sidebar />

        <Box flexShrink={0} flexDirection="column">
          {entityState?.id && (
            <ModalImageUpload
              imageLabel={strings.awardImageLabel || 'Award Image'}
              imageSizes={['thumb_660x360', 'thumb_200x120', 'thumb_120x80']}
              imageUrl={defaultImageUrl}
              ownerId={award?.owner_id}
              ownerType="Organization"
              subjectId={award?.id}
              subjectType="Award"
            />
          )}

          {!!award?.id && (
            <FormField direction="column" label={strings.moreOptions || 'More Options'} marginTop="large">
              {!!defaultImage.id && <ModalImageUpload.ReplaceImage loading={deleting} onClick={() => deleteImage()} />}

              <Button
                buttonStyle="secondaryUtility"
                onClick={() => confirmDelete(confirmDeletePayload)}
                icon={<FontAwesomeIcon icon={faTrashAlt} />}
                size="medium"
                width="100%"
              >
                {strings.deleteAward || 'Delete Award'}
              </Button>
            </FormField>
          )}
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="secondary"
          onClick={() => closeModal()}
          size="medium"
          style={{ marginRight: 4, width: 'calc(50% - 4px)' }}
        >
          {strings.cancelButton || 'Cancel'}
        </Button>

        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled || !customFieldsSaveEnabled}
          loading={creating || updating}
          onClick={
            entityState.id
              ? () => createAward(createOrUpdateAwardPayload)
              : () => updateAward(createOrUpdateAwardPayload)
          }
          size="medium"
          style={{ marginLeft: 4, width: 'calc(50% - 4px)' }}
        >
          {entityState?.id ? strings.saveAward || 'Save Award' : strings.createAward || 'Create Award'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

CreateOrEditAwardModal.defaultProps = {
  modalKey: 'CreateOrEditAwardModal',
}

const LazyLoadedModal = (props: CreateOrEditAwardModalProps) => (
  <SidebarModal.RenderController {...props}>
    <CreateOrEditAwardModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
