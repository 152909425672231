import { useContext } from 'react'
import { Helmet } from 'react-helmet-async'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faPlus } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Columns, DashboardModule, LoadingModule, PageHeader,
} from '@campaignhub/suit-theme'

import useAssetLibrary from '@hooks/useAssetLibrary'
import useDataSets from '@hooks/useDataSets'

import PageContext from '@contexts/pageContext'

import PageFilterModule from '@components/PageFilterModule'

import DataSetListItem from '../DataSetListItem'
import DataSetsBlankState from '../DataSetsBlankState'

const buildPageFilterFields = (isAdmin: boolean|string) => {
  const boxProps = {
    marginBottom: 'large',
  }

  const style = {
    marginBottom: 16,
  }

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        style,
      },
      key: 'string',
      label: 'Filter by Title',
    },
    {
      callbacks: {
        shouldRender: () => isAdmin,
      },
      component: 'EntitySelector',
      componentProps: {
        boxProps,
        entityKey: 'brands',
      },
      key: 'brand_id',
    },
    {
      entityKey: 'limit',
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: '100',
        inputComponent: 'select',
        style,
      },
      key: 'limit',
      label: 'Limit',
      values: [
        { key: 150, label: '150' },
        { key: 200, label: '200' },
      ],
    },
  ]
}

interface PageContentProps {
  isAdmin: boolean | string,
  pageFilters: Record<string, any>,
  selectedBrand: Record<string, any>,
}

const PageContent = (props: PageContentProps) => {
  const { isAdmin, pageFilters, selectedBrand } = props

  const pageContext = useContext(PageContext)
  const {
    callbacks: { resetFilters, updateFilters },
    refetchData,
  } = pageContext

  const initDataSetLibrary = {
    asset_type: 'file',
    owner_type: 'System',
    owner_id: 1,
  }

  const assetLibraryPayload = useAssetLibrary(initDataSetLibrary)
  const {
    callbacks: { createOrEditDataSet },
  } = assetLibraryPayload

  const dataSetPayload = useDataSets({
    filters: {
      owner_id: isAdmin ? null : selectedBrand.id,
      owner_type: isAdmin ? null : 'Brand',
      ...pageFilters,
    },
    performHttpRequests: !!isAdmin || !!selectedBrand.id,
    refetchData,
  })

  const { filteredDataSets, loading, hasDataSets } = dataSetPayload

  return (
    <>
      <Helmet>
        <title>Data Library | Engage</title>
      </Helmet>

      <PageHeader
        activeTabBarItemKey="dataSet"
        actionContent={(
          <Button
            icon={<FontAwesomeIcon icon={faPlus} />}
            buttonStyle="primaryCreate"
            onClick={() => createOrEditDataSet({})}
            size="medium"
          >
            Create
          </Button>
        )}
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        tabBarItems={[
          {
            href: '#/systemManager/dataLibraries',
            icon: faHome,
            key: 'dataSet',
            title: 'All Data Sets',
          },
        ]}
        title="Data Library"
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>

            <LoadingModule loading={loading} times={3} />

            {!loading && !hasDataSets && <DataSetsBlankState />}

            {!loading && hasDataSets && (
            <DashboardModule title="Data Sets">
              <Box flexDirection="column">
                {filteredDataSets.map(dataSet => (
                  <DataSetListItem key={dataSet.key} dataSet={dataSet} callbacks={{ createOrEditDataSet }} />
                ))}
              </Box>

            </DashboardModule>
            )}

          </Columns.Main>

          <Columns.Sidebar>
            <PageFilterModule
              callbacks={{ resetFilters, updateFilters, selectedBrand }}
              filterFields={buildPageFilterFields(isAdmin)}
              pageFilters={pageFilters}
              title="Filters"
            />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

export default PageContent
