import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhotoVideo } from '@fortawesome/pro-light-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'

import {
  Box, Image, ListItem, Text,
} from '@campaignhub/suit-theme'

import useImage from '@hooks/useImage'

const DraggableImage = React.forwardRef((props, ref) => {
  const {
    callbacks: { updateDefaultImage },
    dragHandleProps,
    dragSnapshot,
    draggableProps,
    image,
  } = props

  const {
    callbacks: { editImage },
  } = useImage(image)

  const { isDragging } = dragSnapshot || {}

  const imageUrl = digObject(image, 'sizes.thumb_90x90', '')
  const fileName = digObject(image, 'file_data.metadata.filename')

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        border: '1px solid',
        borderRadius: 5,
        marginBottom: 'large',
        padding: 'large',
      }}
      forceBottomBorder
      forwardProps={{
        ...draggableProps,
        ...dragHandleProps,
      }}
      isDragging={isDragging}
      ref={ref}
      onClick={() => editImage(updateDefaultImage)}
      showAngleIcon
    >
      <Box
        backgroundColor="whiteGrey"
        borderRadius={5}
        height={50}
        marginRight="large"
        padding="large"
        variant="center"
        width={70}
      >
        {!imageUrl && <FontAwesomeIcon icon={faPhotoVideo} />}

        {imageUrl && <Image borderRadius={5} height={50} url={imageUrl} width={70} />}
      </Box>

      <Box flexDirection="column" width="75%">
        <Text variant="ellipsis" fontSize="small" color="bodyFontColor">
          {fileName}
        </Text>
      </Box>
    </ListItem>
  )
})

DraggableImage.propTypes = {
  callbacks: PropTypes.object.isRequired,
  dragHandleProps: PropTypes.object,
  dragSnapshot: PropTypes.object,
  draggableProps: PropTypes.object,
  image: PropTypes.object.isRequired,
}

export default DraggableImage
