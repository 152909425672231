export type TargetAudienceModel = {
  cache_key: number,
  id: number,
  description: string,
  hidden: boolean,
  key: string,
  organization_type_id: number,
  owner_id: number,
  owner_type: string,
  title: string,
}

export type TargetAudienceRequestOptions = {
  include_target_audience_image?: boolean,
  include_target_audience_owner_title?: boolean,
}

const state = {
  description: '',
  id: null,
  key: '',
  organization_type_id: '',
  owner_id: null,
  owner_type: '',
  title: '',
}

export const requiredFields = [
  { key: 'key' },
  { key: 'title' },
]

export default state
