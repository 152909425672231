export default {
  default: {
    toast: {
      address: {
        created: 'Address Created Successfully',
        updated: 'Address Updated Successfully',
      },
      attachment: {
        deleted: 'Document Deleted Successfully',
        updated: 'Document Updated Successfully',
      },
      contact: {
        created: 'Contact Created Successfully',
        deleted: 'Contact Deleted Successfully',
        updated: 'Contact Updated Successfully',
      },
      customField: {
        created: 'Custom Field Created Successfully',
      },
      project: {
        updated: 'Updated Successfully',
      },
      team: {
        created: 'Team Created Successfully',
        updated: 'Team Updated Successfully',
      },
      user: {
        updated: 'User Updated Successfully',
      },
    },
  },
}
