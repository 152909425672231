import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

import 'pure-react-carousel/dist/react-carousel.es.css'

import { digObject, getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'
import { useModals, useSetState, useShowModal } from '@campaignhub/react-hooks'
import {
  Box, LoadingModule, MainContent, ModalContext,
} from '@campaignhub/suit-theme'

import PdfDownloadProvider from '@components/PdfDownloadProvider'

import useIntercom from '@hooks/useIntercom'
import useLocalization from '@hooks/useLocalization'
import useNumericParams from '@hooks/useNumericParams'

import PageContext from '@contexts/pageContext'
import PdfDownloadContext from '@contexts/pdfDownloadContext'

import openModalAndCloseAllOthers from '@functions/openModalAndCloseAllOthers'

import handleCallbackAction from '@functions/handleCallbackAction'

import BuildingAttachmentModal from '@modals/BuildingAttachmentModal'
import CreateEventCalendarModal from '@modals/CreateEventCalendarModal'
import CreateOrEditAddressModal from '@modals/CreateOrEditAddressModal'
import CreateOrEditCaseStudyModal from '@modals/CreateOrEditCaseStudyModal'
import CreateOrEditComparableModal from '@modals/CreateOrEditComparableModal'
import CreateOrEditContactModal from '@modals/CreateOrEditContactModal'
import CreateOrEditTeamModal from '@modals/CreateOrEditTeamModal'
import CreateQuoteModal from '@modals/CreateQuoteModal'
import EditAttachmentModal from '@modals/EditAttachmentModal'
import EditDigitalPageItemCustomDataModal from '@modals/EditDigitalPageItemCustomDataModal'
import EditDigitalPageModal from '@modals/EditDigitalPageModal'
import EditDigitalPageSectionSortModal from '@modals/EditDigitalPageSectionSortModal'
import EditImageModal from '@modals/EditImageModal'
import EditImagesModal from '@modals/EditImagesModal'
import EditShareLinkModal from '@modals/EditShareLinkModal'
import EditUserModal from '@modals/EditUserModal'
import FindComparablesModal from '@modals/FindComparablesModal'
import GetSuburbDataModal from '@modals/GetSuburbDataModal'
import ManageComparablesModal from '@modals/ManageComparablesModal'
import ManageDigitalPageFeaturesModal from '@modals/ManageDigitalPageFeaturesModal'
import ManageEntityQuotesModal from '@modals/ManageEntityQuotesModal'
import ManageEventCalendarsModal from '@modals/ManageEventCalendarsModal'
import ManageProjectAttachmentsModal from '@modals/ManageProjectAttachmentsModal'
import ManageProjectCaseStudiesModal from '@modals/ManageProjectCaseStudiesModal'
import ManageProjectChartsModal from '@modals/ManageProjectChartsModal'
import ManageProjectContactsModal from '@modals/ManageProjectContactsModal'
import ManageProjectDataStoreItemsModal from '@modals/ManageProjectDataStoreItemsModal'
import ManageProjectReviewsModal from '@modals/ManageProjectReviewsModal'
import ManageProjectTargetAudiencesModal from '@modals/ManageProjectTargetAudiencesModal'
import ManageProjectTeamsModal from '@modals/ManageProjectTeamsModal'
import ManageProjectUsersModal from '@modals/ManageProjectUsersModal'
import ManageShareLinksModal from '@modals/ManageShareLinksModal'
import SelectDigitalTemplateModal from '@modals/SelectDigitalTemplateModal'
import SelectImageModal from '@modals/SelectImageModal'
import SendSMSModal from '@modals/SendSMSModal'

import useMixpanel from '@hooks/useMixpanel'
import useDigitalRenderer from './hooks/useDigitalRenderer'

import defaultRequestOptions from './defaultRequestOptions'

import DigitalPage from './components/DigitalPage'
import DigitalPageSidebar from './components/DigitalPageSidebar'
import Header from './components/Header'

import localizedStrings from './localizedStrings'

const modalKeys = [
  'CreateEventCalendarModal',
  'CreateOrEditAddressModal',
  'CreateOrEditCaseStudyModal',
  'CreateOrEditComparableModal',
  'CreateOrEditContactModal',
  'CreateOrEditTeamModal',
  'CreateQuoteModal',
  'EditAttachmentModal',
  'EditDigitalPageItemCustomDataModal',
  'EditDigitalPageModal',
  'EditShareLinkModal',
  'EditUserModal',
  'FindComparablesModal',
  'GetSuburbDataModal',
  'ManageComparablesModal',
  'ManageDigitalPageFeaturesModal',
  'ManageEntityQuotesModal',
  'ManageEventCalendarsModal',
  'ManageProjectAttachmentsModal',
  'ManageProjectCaseStudiesModal',
  'ManageProjectChartsModal',
  'ManageProjectContactsModal',
  'ManageProjectDataStoreItemsModal',
  'ManageProjectReviewsModal',
  'ManageProjectTargetAudiencesModal',
  'ManageProjectTeamsModal',
  'ManageProjectUsersModal',
  'ManageShareLinksModal',
  'SelectDigitalTemplateModal',
  'SelectImageModal',
  'SendSMSModal',
]

const deleteAttachment = (deleteFn, setState, strings) => {
  deleteFn().then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    toast(strings.toast?.attachment?.deleted || 'Document Deleted Successfully')
    setState({ showEditAttachmentModal: false })
  })
}

const updateAttachment = (attachmentParams, customFields, updateFn, setState, strings) => {
  updateFn(attachmentParams, customFields, defaultRequestOptions.attachment).then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    toast(strings.toast?.attachment?.updated || 'Document Updated Successfully')
    setState({ showEditAttachmentModal: false })
  })
}

const createEventCalendar = (calendarParams, createFn, digitalPage, updateDigitalPage) => {
  createFn(calendarParams, defaultRequestOptions.eventCalendar).then(({
    success, data, errors, redirectUrl,
  }) => {
    if (!success){
      toast.warn(errors[0])
      return
    }

    const {
      entity: { id: eventCalendarId },
    } = data
    const existingEventCalendarIds = digObject(digitalPage, 'event_calendar_ids', [])

    updateDigitalPage(
      { event_calendar_ids: [...existingEventCalendarIds, eventCalendarId] },
      defaultRequestOptions.digitalPage,
    ).then(({ success: digitalPageSuccess, errors: digitalPageErrors }) => {
      if (!digitalPageSuccess){
        toast.warn(digitalPageErrors[0])
        return
      }

      if (redirectUrl){
        window.location.href = redirectUrl
      }
    })
  })
}

const afterImportExternalQuoteIds = (digitalPage, response, updateDigitalPageFn) => {
  const { data } = response

  const {
    entity: { id: quoteId },
  } = data

  const existingQuoteIds = digObject(digitalPage, 'quote_ids', [])
  if (!existingQuoteIds.includes(quoteId)){
    existingQuoteIds.push(quoteId)
  }

  updateDigitalPageFn({ quote_ids: existingQuoteIds }, defaultRequestOptions.digitalPage).then(
    ({ success, errors }) => {
      if (!success){
        toast.warn(errors[0])
      }
    },
  )
}

const createShareLink = (shareLinkParams, createFn, editShareLink, strings) => {
  createFn(shareLinkParams, { ...defaultRequestOptions.shareLink }).then(({ success, errors, data }) => {
    const { entity: shareLink } = data
    if (!success){
      toast.warn(errors[0])
      return
    }

    editShareLink(shareLink)
    toast(strings.toast?.shareLink?.created || 'Share Link Created Successfully')
  })
}

const deleteShareLink = (deleteFn, setState, strings) => {
  deleteFn().then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    toast(strings.toast?.shareLink?.deleted || 'Share Link Deleted Successfully')
    openModalAndCloseAllOthers('ManageDigitalPageFeaturesModal', modalKeys, setState)
  })
}

const updateShareLink = (shareLinkParams, updateFn, setState, strings) => {
  updateFn(shareLinkParams).then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    toast(strings.toast?.shareLink?.updated || 'Share Link Updated Successfully')
    openModalAndCloseAllOthers('ManageDigitalPageFeaturesModal', modalKeys, setState)
  })
}

const sendSMS = (payload, sendSMSFn, setState, strings) => {
  const { recipients, message } = payload

  sendSMSFn(recipients, message).then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    toast(strings.toast?.shareLink?.send || 'Messages queued for delivery')
    setState({ showSendSMSModal: false, showEditShareLinkModal: true })
  })
}

const updateDigitalPage = (params, updateFn, setState, strings) => {
  updateFn(params, defaultRequestOptions.digitalPage).then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }
    toast(strings.toast?.digitalPageUpdated || 'Digital Page Updated Successfully')
    openModalAndCloseAllOthers('ManageDigitalPageFeaturesModal', modalKeys, setState)
  })
}

const updateDigitalPageItemCustomData = (params, updateFn, setState, strings) => {
  updateFn(params).then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }
    toast(strings.toast?.digitalPageUpdated || 'Digital Page Updated Successfully')
    openModalAndCloseAllOthers('ManageDigitalPageFeaturesModal', modalKeys, setState)
  })
}

const updateProject = (payload) => {
  const {
    customFields, loadDataFn, params, setState, strings, updateFn, updateKey,
  } = payload

  updateFn(params, customFields, {
    ...defaultRequestOptions.caseStudy,
    ...defaultRequestOptions.project,
    ...defaultRequestOptions.review,
    ...defaultRequestOptions.targetAudience,
    ...defaultRequestOptions.team,
    ...defaultRequestOptions.user,
  }).then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    toast(`${updateKey} ${strings.toast?.projectUpdated || 'Updated Successfully'}`)
    openModalAndCloseAllOthers('ManageDigitalPageFeaturesModal', modalKeys, setState)
    loadDataFn()
  })
}

const selectDigitalTemplateId = (templateId, selectFn, setState) => selectFn(templateId, {
  ...defaultRequestOptions.attachment,
  ...defaultRequestOptions.caseStudy,
  ...defaultRequestOptions.comparable,
  ...defaultRequestOptions.digitalPage,
  ...defaultRequestOptions.digitalTemplate,
  ...defaultRequestOptions.digitalTemplatePage,
  ...defaultRequestOptions.formTemplate,
  ...defaultRequestOptions.image,
  ...defaultRequestOptions.project,
  ...defaultRequestOptions.review,
  ...defaultRequestOptions.shareLink,
  ...defaultRequestOptions.targetAudience,
  ...defaultRequestOptions.team,
  ...defaultRequestOptions.user,
}).then(({ success, errors }) => {
  if (!success){
    toast.warning(errors[0])
    return
  }

  setState({ showSelectDigitalTemplateModal: false })
})

const updateDigitalPageComparables = (updateFn, digitalPageParams, loadDataFn, setState, strings) => {
  updateFn(digitalPageParams, defaultRequestOptions.comparable).then(({ success, errors }) => {
    if (!success && errors){
      toast.warn(errors[0])
      return
    }

    toast(strings.toast?.comparable?.sortUpdated || 'Comparables Updated Successfully')
    setState({ showManageComparablesModal: false })
    loadDataFn()
  })
}

const deleteImage = (deleteFn, strings) => {
  deleteFn().then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    toast(strings.imageDeleted || 'Image Deleted Successfully')
  })
}

const callbacks = (component, setState, stringsPayload, customPayload = {}) => {
  const { strings } = stringsPayload

  const {
    digitalPage, isMobileDevice, loadShortcodeDataFn, updateDigitalPageFn, onUpload,
  } = customPayload

  const componentCallbacks = {
    CreateOrEditAddressModal: {
      closeModal: () => setState({
        showCreateOrEditAddressModal: false,
        showEditProjectAddressModal: false,
      }),
      createAddress: payload => handleCallbackAction(payload),
      updateAddress: payload => handleCallbackAction(payload),
    },
    CreateOrEditCaseStudyModal: {
      closeModal: () => setState({
        showCreateOrEditCaseStudyModal: false,
        showManageDigitalPageFeaturesModal: false,
        showManageProjectCaseStudiesModal: true,
      }),
      createCaseStudy: payload => handleCallbackAction({ ...payload, requestOptions: defaultRequestOptions.caseStudy }),
      updateCaseStudy: payload => handleCallbackAction({ ...payload, requestOptions: defaultRequestOptions.caseStudy }),
    },
    CreateOrEditComparableModal: {
      closeModal: () => setState({ showCreateOrEditComparableModal: false, showManageDigitalPageFeaturesModal: true }),
      createComparable: payload => handleCallbackAction({ ...payload, requestOptions: defaultRequestOptions.comparable }),
      deleteComparable: payload => handleCallbackAction(payload),
      updateComparable: payload => handleCallbackAction({ ...payload, requestOptions: defaultRequestOptions.comparable }),
      loadShortcodeData: () => loadShortcodeDataFn,
    },
    CreateOrEditContactModal: {
      closeModal: () => setState({
        showCreateOrEditContactModal: false,
        showManageProjectContactsModal: true,
      }),
      createContact: payload => handleCallbackAction({ ...payload, requestOptions: defaultRequestOptions.contact }),
      deleteContact: payload => handleCallbackAction({ ...payload, requestOptions: defaultRequestOptions.contact }),
      updateContact: payload => handleCallbackAction({ ...payload, requestOptions: defaultRequestOptions.contact }),
    },
    CreateOrEditTeamModal: {
      closeModal: () => setState({
        showCreateOrEditTeamModal: false,
        showManageDigitalPageFeaturesModal: false,
        showManageProjectTeamsModal: true,
      }),
      createTeam: payload => handleCallbackAction(payload),
      updateTeam: payload => handleCallbackAction(payload),
    },
    CreateEventCalendarModal: {
      closeModal: () => setState({ showCreateEventCalendarModal: false, showManageDigitalPageFeaturesModal: true }),
      createEventCalendar: (calendarParams, createFn) => createEventCalendar(calendarParams, createFn, digitalPage, updateDigitalPageFn),
    },
    CreateQuoteModal: {
      closeModal: () => setState({ showCreateQuoteModal: false, showManageDigitalPageFeaturesModal: true }),
      createQuoteFromTemplate: payload => handleCallbackAction({ ...payload, requestOptions: defaultRequestOptions.quote }),
      createQuote: payload => handleCallbackAction({ ...payload, requestOptions: defaultRequestOptions.quote }),
      importExternalQuoteId: payload => handleCallbackAction({ ...payload, requestOptions: defaultRequestOptions.quote }),
      afterImportExternalQuoteIds: response => afterImportExternalQuoteIds(digitalPage, response, updateDigitalPageFn),
    },
    EditAttachmentModal: {
      closeModal: () => setState({ showEditAttachmentModal: false }),
      deleteAttachment: deleteFn => deleteAttachment(deleteFn, setState, strings),
      updateAttachment: (params, customFields, updateFn) => updateAttachment(params, customFields, updateFn, setState, strings),
    },
    EditShareLinkModal: {
      closeModal: () => setState({ showEditShareLinkModal: false, showManageDigitalPageFeaturesModal: true }),
      deleteShareLink: deleteFn => deleteShareLink(deleteFn, setState, strings),
      updateShareLink: (shareLinkParams, updateFn) => updateShareLink(shareLinkParams, updateFn, setState, strings),
    },
    EditDigitalPageModal: {
      closeModal: () => setState({ showEditDigitalPageModal: false, showManageDigitalPageFeaturesModal: true }),
      updateDigitalPage: (digitalPageParams, updateFn) => updateDigitalPage(digitalPageParams, updateFn, setState, strings),
    },
    EditDigitalPageSectionSortModal: {
      closeModal: () => setState({ showEditDigitalPageSectionSortModal: false, showManageDigitalPageFeaturesModal: true }),
      updateDigitalPageSectionSort: payload => handleCallbackAction(payload),
    },
    EditImageModal: {
      closeModal: () => setState({ showEditImageModal: false }),
      deleteImage: payload => handleCallbackAction(payload),
    },
    EditImagesModal: {
      closeModal: () => setState({ showEditImagesModal: false }),
      onUpload,
    },
    EditDigitalPageItemCustomDataModal: {
      closeModal: () => setState({ showEditDigitalPageItemCustomDataModal: false, showManageDigitalPageFeaturesModal: true }),
      updateDigitalPageItemCustomData: (params, updateFn) => updateDigitalPageItemCustomData(params, updateFn, setState, strings),
    },
    EditUserModal: {
      closeModal: () => setState({ showEditUserModal: false, showSendSMSModal: true }),
      updateUser: payload => handleCallbackAction({ ...payload, requestOptions: defaultRequestOptions.user }),
    },
    FindComparablesModal: {
      closeModal: () => setState({ showFindComparablesModal: false, showManageDigitalPageFeaturesModal: true }),
    },
    GetSuburbDataModal: {
      closeModal: () => setState({ showGetSuburbDataModal: false, showManageDigitalPageFeaturesModal: true }),
      updateDigitalPage: (params, updateFn) => updateDigitalPage(params, updateFn, setState, strings),
    },
    ManageComparablesModal: {
      closeModal: () => setState({ showManageComparablesModal: false, showManageDigitalPageFeaturesModal: true }),
      updateDigitalPageComparables: (updateFn, digitalPageParams, loadDataFn) => updateDigitalPageComparables(updateFn, digitalPageParams, loadDataFn, setState, strings),
    },
    ManageDigitalPageFeaturesModal: {
      closeModal: isMobileDevice ? () => setState({ showManageDigitalPageFeaturesModal: false }) : null,
    },
    ManageEntityQuotesModal: {
      closeModal: () => setState({ showManageEntityQuotesModal: false, showManageDigitalPageFeaturesModal: true }),
      showCreateQuotesModal: () => setState({ showCreateQuoteModal: true }),
      updateEntity: (params, updateFn) => updateDigitalPage(params, updateFn, setState, strings),
    },
    ManageEventCalendarsModal: {
      closeModal: () => setState({ showManageEventCalendarsModal: false, showManageDigitalPageFeaturesModal: true }),
      updateDigitalPage: (params, updateFn) => updateDigitalPage(params, updateFn, setState, strings),
    },
    ManageProjectAttachmentsModal: {
      closeModal: () => setState({ showManageProjectAttachmentsModal: false }),
      updateDigitalPage: (params, updateFn) => updateDigitalPage(params, updateFn, setState, strings),
    },
    ManageProjectCaseStudiesModal: {
      closeModal: () => setState({ showManageProjectCaseStudiesModal: false, showManageDigitalPageFeaturesModal: true }),
      updateProject: (params, updateKey, updateFn, loadDataFn) => updateProject({
        params,
        customFields: [],
        updateKey,
        updateFn,
        setState,
        strings,
        loadDataFn,
      }),
    },
    ManageProjectChartsModal: {
      closeModal: () => setState({ showManageProjectChartsModal: false, showManageDigitalPageFeaturesModal: true }),
      updateDigitalPage: (params, updateFn) => updateDigitalPage(params, updateFn, setState, strings),
    },
    ManageProjectContactsModal: {
      closeModal: () => setState({ showManageProjectContactsModal: false }),
      updateProject: (params, updateKey, updateFn, loadDataFn) => updateProject({
        params,
        customFields: [],
        updateKey,
        updateFn,
        setState,
        strings,
        loadDataFn,
      }),
    },
    ManageProjectDataStoreItemsModal: {
      closeModal: () => setState({ showManageProjectDataStoreItemsModal: false, showManageDigitalPageFeaturesModal: true }),
      updateProject: (params, updateKey, customFields, updateFn, loadDataFn) => updateProject({
        params,
        customFields,
        updateKey,
        updateFn,
        setState,
        strings,
        loadDataFn,
      }),
    },
    ManageProjectReviewsModal: {
      closeModal: () => setState({ showManageProjectReviewsModal: false, showManageDigitalPageFeaturesModal: true }),
      updateProject: (params, updateKey, updateFn, loadDataFn) => updateProject({
        params,
        customFields: [],
        updateKey,
        updateFn,
        setState,
        strings,
        loadDataFn,
      }),
    },
    ManageProjectTargetAudiencesModal: {
      closeModal: () => setState({ showManageProjectTargetAudiencesModal: false, showManageDigitalPageFeaturesModal: true }),
      updateProject: (params, updateKey, updateFn, loadDataFn) => updateProject({
        params,
        customFields: [],
        updateKey,
        updateFn,
        setState,
        strings,
        loadDataFn,
      }),
    },
    ManageProjectTeamsModal: {
      closeModal: () => setState({ showManageProjectTeamsModal: false, showManageDigitalPageFeaturesModal: true }),
      updateProject: (params, updateKey, updateFn, loadDataFn) => updateProject({
        params,
        customFields: [],
        updateKey,
        updateFn,
        setState,
        strings,
        loadDataFn,
      }),
    },
    ManageProjectUsersModal: {
      closeModal: () => setState({ showManageProjectUsersModal: false, showManageDigitalPageFeaturesModal: true }),
      updateProject: (params, updateKey, updateFn, loadDataFn) => updateProject({
        params,
        customFields: [],
        updateKey,
        updateFn,
        setState,
        strings,
        loadDataFn,
      }),
    },
    ManageShareLinksModal: {
      closeModal: () => setState({ showManageShareLinksModal: false, showManageDigitalPageFeaturesModal: true }),
      createShareLink: (shareLinkParams, createFn, editShareLink) => createShareLink(shareLinkParams, createFn, editShareLink, strings),
    },
    SelectDigitalTemplateModal: {
      closeModal: () => setState({ showSelectDigitalTemplateModal: false, showManageDigitalPageFeaturesModal: true }),
      selectDigitalTemplateId: (templateId, selectFn) => selectDigitalTemplateId(templateId, selectFn, setState),
    },
    SelectImageModal: {
      closeModal: () => setState({ showSelectImageModal: false, showManageDigitalPageFeaturesModal: true }),
      deleteImage: deleteFn => deleteImage(deleteFn, strings),
    },
    SendSMSModal: {
      closeModal: () => setState({ showSendSMSModal: false, showManageDigitalPageFeaturesModal: true }),
      sendSMS: (payload, sendSMSFn) => sendSMS(payload, sendSMSFn, setState, strings),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  showCreateEventCalendarModal: false,
  showCreateOrEditAddressModal: false,
  showCreateOrEditCaseStudyModal: false,
  showCreateOrEditComparableModal: false,
  showCreateOrEditContactModal: false,
  showCreateOrEditTeamModal: false,
  showCreateQuoteModal: false,
  showEditAttachmentModal: false,
  showEditDigitalPageItemCustomDataModal: false,
  showEditDigitalPageModal: false,
  showEditDigitalPageSectionSortModal: false,
  showEditImageModal: false,
  showEditImagesModal: false,
  showEditProjectAddressModal: false,
  showEditShareLinkModal: false,
  showEditUserModal: false,
  showFindComparablesModal: false,
  showGetSuburbDataModal: false,
  showManageComparablesModal: false,
  showManageDigitalPageFeaturesModal: false,
  showManageEntityQuotesModal: false,
  showManageEventCalendarsModal: false,
  showManageProjectAttachmentsModal: false,
  showManageProjectCaseStudiesModal: false,
  showManageProjectChartsModal: false,
  showManageProjectContactsModal: false,
  showManageProjectDataStoreItemsModal: false,
  showManageProjectReviewsModal: false,
  showManageProjectTargetAudiencesModal: false,
  showManageProjectTeamsModal: false,
  showManageProjectUsersModal: false,
  showManageShareLinksModal: false,
  showSelectDigitalTemplateModal: false,
  showSelectImageModal: false,
  showSendSMSModal: false,
}

const DigitalRenderer = (props) => {
  const {
    digitalPageId, editing: initEditing, shareLink, shareLinkToken,
  } = props

  const [state, setState] = useSetState(defaultState)
  const {
    showCreateEventCalendarModal,
    showCreateOrEditAddressModal,
    showCreateOrEditCaseStudyModal,
    showCreateOrEditComparableModal,
    showCreateOrEditContactModal,
    showCreateOrEditTeamModal,
    showCreateQuoteModal,
    showEditAttachmentModal,
    showEditDigitalPageItemCustomDataModal,
    showEditDigitalPageModal,
    showEditDigitalPageSectionSortModal,
    showEditImageModal,
    showEditImagesModal,
    showEditProjectAddressModal,
    showEditShareLinkModal,
    showEditUserModal,
    showFindComparablesModal,
    showGetSuburbDataModal,
    showManageComparablesModal,
    showManageDigitalPageFeaturesModal,
    showManageEntityQuotesModal,
    showManageEventCalendarsModal,
    showManageProjectAttachmentsModal,
    showManageProjectCaseStudiesModal,
    showManageProjectChartsModal,
    showManageProjectContactsModal,
    showManageProjectDataStoreItemsModal,
    showManageProjectReviewsModal,
    showManageProjectTargetAudiencesModal,
    showManageProjectTeamsModal,
    showManageProjectUsersModal,
    showManageShareLinksModal,
    showSelectDigitalTemplateModal,
    showSelectImageModal,
    showSendSMSModal,
  } = state

  const { digitalPageId: digitalPageIdParam } = useNumericParams()

  const pageId = digitalPageId || digitalPageIdParam

  const modalContext = useModals()
  const {
    callbacks: { registerModal, setModalData },
  } = modalContext

  const digitalRendererPayload = useDigitalRenderer({
    callbacks: {
      showCreateEventCalendarModal: (payload) => {
        setModalData('CreateEventCalendarModal', payload)
        setState({ showCreateEventCalendarModal: true })
      },
      showCreateOrEditCaseStudyModal: (payload) => {
        setModalData('CreateOrEditCaseStudyModal', payload)
        setState({ showCreateOrEditCaseStudyModal: true })
      },
      showCreateOrEditAddressModal: (payload) => {
        setModalData('CreateOrEditAddressModal', payload)
        setState({ showCreateOrEditAddressModal: true })
      },
      showCreateOrEditComparableModal: (payload) => {
        setModalData('CreateOrEditComparableModal', payload)
        setState({ showCreateOrEditComparableModal: true })
      },
      showCreateOrEditContactModal: (payload) => {
        setModalData('CreateOrEditContactModal', payload)
        setState({ showCreateOrEditContactModal: true })
      },
      showCreateOrEditTeamModal: (payload) => {
        setModalData('CreateOrEditTeamModal', payload)
        setState({ showCreateOrEditTeamModal: true })
      },
      showCreateCalendarEventModal: (payload) => {
        setModalData('CreateCalendarEventModal', payload)
        setState({ showCreateCalendarEventModal: true })
      },
      showCreateQuoteModal: (payload) => {
        setModalData('CreateQuoteModal', payload)
        setState({ showCreateQuoteModal: true })
      },
      showEditAttachmentModal: (payload) => {
        setModalData('EditAttachmentModal', payload)
        setState({ showEditAttachmentModal: true })
      },
      showEditDigitalPageModal: (payload) => {
        setModalData('EditDigitalPageModal', payload)
        setState({ showEditDigitalPageModal: true })
      },
      showEditDigitalPageSectionSortModal: (payload) => {
        setModalData('EditDigitalPageSectionSortModal', payload)
        openModalAndCloseAllOthers('EditDigitalPageSectionSortModal', modalKeys, setState)
      },
      showEditImageModal: (payload) => {
        setModalData('EditImageModal', payload)
        setState({ showEditImageModal: true })
      },
      showEditImagesModal: (payload) => {
        setModalData('EditImagesModal', payload)
        setState({ showEditImagesModal: true })
      },
      showEditDigitalPageItemCustomDataModal: (payload) => {
        setModalData('EditDigitalPageItemCustomDataModal', payload)
        openModalAndCloseAllOthers('EditDigitalPageItemCustomDataModal', modalKeys, setState)
      },
      showEditProjectAddressModal: (payload) => {
        setModalData('CreateOrEditAddressModal', payload)
        setState({ showEditProjectAddressModal: true })
      },
      showGetSuburbDataModal: (payload) => {
        setModalData('GetSuburbDataModal', payload)
        setState({ showGetSuburbDataModal: true })
      },
      showEditShareLinkModal: (payload) => {
        setModalData('EditShareLinkModal', payload)
        openModalAndCloseAllOthers('EditShareLinkModal', modalKeys, setState)
      },
      showEditUserModal: (payload) => {
        setModalData('EditUserModal', payload)
        setState({ showEditUserModal: true })
      },
      showFindComparablesModal: (payload) => {
        setModalData('FindComparablesModal', payload)
        setState({ showFindComparablesModal: true })
      },
      GetSuburbDataModal: (payload) => {
        setModalData('GetSuburbDataModal', payload)
        setState({ GetSuburbDataModal: true })
      },
      showManageComparablesModal: (payload) => {
        setModalData('ManageComparablesModal', payload)
        setState({ showManageComparablesModal: true })
      },
      showManageEntityQuotesModal: (payload) => {
        setModalData('ManageEntityQuotesModal', payload)
        openModalAndCloseAllOthers('ManageEntityQuotesModal', modalKeys, setState)
      },
      showManageEventCalendarsModal: (payload) => {
        setModalData('ManageEventCalendarsModal', payload)
        openModalAndCloseAllOthers('ManageEventCalendarsModal', modalKeys, setState)
      },
      showManageProjectCaseStudiesModal: (payload) => {
        setModalData('ManageProjectCaseStudiesModal', payload)
        openModalAndCloseAllOthers('ManageProjectCaseStudiesModal', modalKeys, setState)
      },
      showManageProjectChartsModal: (payload) => {
        setModalData('ManageProjectChartsModal', payload)
        openModalAndCloseAllOthers('ManageProjectChartsModal', modalKeys, setState)
      },

      showManageDigitalPageFeaturesModal: (payload) => {
        setModalData('ManageDigitalPageFeaturesModal', payload)
        setState({ showManageDigitalPageFeaturesModal: true })
      },
      showManageProjectAttachmentsModal: (payload) => {
        setModalData('ManageProjectAttachmentsModal', payload)
        setState({ showManageProjectAttachmentsModal: true })
      },
      showManageProjectContactsModal: (payload) => {
        setModalData('ManageProjectContactsModal', payload)
        setState({ showManageProjectContactsModal: true })
      },
      showManageProjectDataStoreItemsModal: (payload) => {
        setModalData('ManageProjectDataStoreItemsModal', payload)
        openModalAndCloseAllOthers('ManageProjectDataStoreItemsModal', modalKeys, setState)
      },
      showManageProjectReviewsModal: (payload) => {
        setModalData('ManageProjectReviewsModal', payload)
        openModalAndCloseAllOthers('ManageProjectReviewsModal', modalKeys, setState)
      },
      showManageProjectTargetAudiencesModal: (payload) => {
        setModalData('ManageProjectTargetAudiencesModal', payload)
        openModalAndCloseAllOthers('ManageProjectTargetAudiencesModal', modalKeys, setState)
      },
      showManageProjectTeamsModal: (payload) => {
        setModalData('ManageProjectTeamsModal', payload)
        openModalAndCloseAllOthers('ManageProjectTeamsModal', modalKeys, setState)
      },
      showManageProjectUsersModal: (payload) => {
        setModalData('ManageProjectUsersModal', payload)
        openModalAndCloseAllOthers('ManageProjectUsersModal', modalKeys, setState)
      },
      showManageShareLinksModal: (payload) => {
        setModalData('ManageShareLinksModal', payload)
        setState({ showManageShareLinksModal: true })
      },
      showSelectDigitalTemplateModal: (payload) => {
        setModalData('SelectDigitalTemplateModal', payload)
        setState({ showSelectDigitalTemplateModal: true })
      },
      showSelectImageModal: (payload) => {
        setModalData('SelectImageModal', payload)
        openModalAndCloseAllOthers('SelectImageModal', modalKeys, setState)
      },
      showSendSMSModal: (payload) => {
        setModalData('SendSMSModal', payload)
        openModalAndCloseAllOthers('SendSMSModal', modalKeys, setState)
      },
    },
    digitalPageId: pageId,
    editing: initEditing,
    shareLink,
    shareLinkToken,
  })

  const {
    callbacks: { loadDigitalPageShortcodeData: loadShortcodeDataFn, updateDigitalPage: updateDigitalPageFn },
    digitalPage,
    digitalTemplatePage,
    editing,
    isMobileDevice,
    loading,
    previewing,
    project,
  } = digitalRendererPayload

  const previewOrEditMode = editing || previewing

  const { callbacks: { dispatchMixpanelEvent } } = useMixpanel()

  useEffect(() => {
    // Wait for the register function otherwise the modal
    // doesn't get added to the open modals array
    if (!isMobileDevice && registerModal){
      setState({
        showManageDigitalPageFeaturesModal: true,
      })
    }
  }, [])

  useIntercom({ horizontalPadding: !previewing ? 400 : 20 })

  const stringsPayload = useLocalization(localizedStrings)

  const { showModal } = parsePermittedQueryParams(getQueryParamsFromHash(), ['showModal'])

  // Open the modal when redirect from external quote OR event calendars
  useShowModal({
    modalKey: showModal,
    options: {
      callbacks: digitalRendererPayload.callbacks,
    },
  })

  return (
    <PdfDownloadProvider>
      <PageContext.Provider value={digitalRendererPayload}>
        <ModalContext.Provider value={modalContext}>
          <MainContent
            offset={{ left: 0, top: previewOrEditMode ? 71 : 0 }}
            width={!isMobileDevice && editing ? 'calc(100% - 375px)' : '100%'}
            style={{ display: 'flex', flexDirection: 'column', paddingBottom: 0 }}
          >
            {previewOrEditMode && (
              <Header
                digitalRendererPayload={digitalRendererPayload}
                callbacks={{
                  closeModals: () => openModalAndCloseAllOthers('ManageDigitalPageFeaturesModal', modalKeys, setState),
                }}
              />
            )}

            {(loading || !digitalTemplatePage.id) && (
            <Box flexDirection="column" marginTop="large" paddingX="large">
              <LoadingModule loading={loading || !digitalTemplatePage.id} times={3} />
            </Box>
            )}

            {(!loading || digitalTemplatePage.id) && <DigitalPage digitalRendererPayload={digitalRendererPayload} />}
          </MainContent>

          <DigitalPageSidebar digitalRendererPayload={digitalRendererPayload} />

          <BuildingAttachmentModal context={PdfDownloadContext} />

          {editing && (
          <>
            <CreateOrEditAddressModal
              callbacks={callbacks('CreateOrEditAddressModal', setState, stringsPayload)}
              disableAnimation
              disableOverlay
              hiddenFields={['title']}
              showModal={showEditProjectAddressModal || showCreateOrEditAddressModal}
            />

            <CreateOrEditCaseStudyModal
              callbacks={callbacks('CreateOrEditCaseStudyModal', setState, stringsPayload)}
              showModal={showCreateOrEditCaseStudyModal}
            />

            <CreateOrEditComparableModal
              callbacks={callbacks('CreateOrEditComparableModal', setState, stringsPayload, {
                loadShortcodeDataFn,
              })}
              showModal={showCreateOrEditComparableModal}
            />

            <CreateOrEditContactModal
              callbacks={callbacks('CreateOrEditContactModal', setState, stringsPayload)}
              showModal={showCreateOrEditContactModal}
            />

            <CreateOrEditTeamModal
              callbacks={callbacks('CreateOrEditTeamModal', setState, stringsPayload)}
              disableAnimation
              disableOverlay
              showModal={showCreateOrEditTeamModal}
            />

            <CreateEventCalendarModal
              callbacks={callbacks('CreateEventCalendarModal', setState, stringsPayload, {
                digitalPage,
                updateDigitalPageFn,
              })}
              disableAnimation
              disableOverlay
              showModal={showCreateEventCalendarModal}
            />

            <CreateQuoteModal
              callbacks={callbacks('CreateQuoteModal', setState, stringsPayload, {
                digitalPage,
                updateDigitalPageFn,
              })}
              disableAnimation
              disableOverlay
              project={project}
              showModal={showCreateQuoteModal}
            />

            <EditAttachmentModal
              callbacks={callbacks('EditAttachmentModal', setState, stringsPayload)}
              disableAnimation
              disableOverlay
              showModal={showEditAttachmentModal}
            />

            <ManageProjectContactsModal
              callbacks={callbacks('ManageProjectContactsModal', setState, stringsPayload)}
              project={{ id: project.id }}
              showModal={showManageProjectContactsModal}
            />

            <EditDigitalPageModal
              callbacks={callbacks('EditDigitalPageModal', setState, stringsPayload)}
              disableAnimation
              disableOverlay
              showModal={showEditDigitalPageModal}
            />

            <EditDigitalPageSectionSortModal
              callbacks={callbacks('EditDigitalPageSectionSortModal', setState, stringsPayload)}
              digitalTemplatePage={digitalTemplatePage}
              showModal={showEditDigitalPageSectionSortModal}
            />

            <EditImageModal
              callbacks={callbacks('EditImageModal', setState, stringsPayload)}
              showModal={showEditImageModal}
            />

            <EditImagesModal
              callbacks={callbacks('EditImagesModal', setState, stringsPayload, {
                onUpload: () => dispatchMixpanelEvent('Comparable Photo Upload', {
                  Digital_template_id: digitalPage?.digital_template_id,
                }),
              })}
              showModal={showEditImagesModal}
            />

            <EditDigitalPageItemCustomDataModal
              callbacks={callbacks('EditDigitalPageItemCustomDataModal', setState, stringsPayload)}
              disableAnimation
              disableOverlay
              showModal={showEditDigitalPageItemCustomDataModal}
            />

            <EditShareLinkModal
              callbacks={callbacks('EditShareLinkModal', setState, stringsPayload)}
              disableAnalytics
              disableAnimation
              disableOverlay
              showModal={showEditShareLinkModal}
            />

            <EditUserModal
              callbacks={callbacks('EditUserModal', setState, stringsPayload)}
              disableAnimation
              showModal={showEditUserModal}
            />

            <FindComparablesModal
              callbacks={callbacks('FindComparablesModal', setState, stringsPayload)}
              disableAnimation
              projectId={project.id}
              showModal={showFindComparablesModal}
            />

            <GetSuburbDataModal
              callbacks={callbacks('GetSuburbDataModal', setState, stringsPayload)}
              disableAnimation
              disableOverlay
              showModal={showGetSuburbDataModal}
            />

            <ManageComparablesModal
              callbacks={callbacks('ManageComparablesModal', setState, stringsPayload)}
              disableAnimation
              disableOverlay
              project={project}
              showModal={showManageComparablesModal}
            />

            <ManageDigitalPageFeaturesModal
              callbacks={callbacks('ManageDigitalPageFeaturesModal', setState, stringsPayload, { isMobileDevice })}
              digitalRendererPayload={digitalRendererPayload}
              showModal={showManageDigitalPageFeaturesModal}
            />

            <ManageEntityQuotesModal
              callbacks={{
                ...callbacks('ManageEntityQuotesModal', setState, stringsPayload, loadShortcodeDataFn),
                updateDigitalPageFn,
              }}
              disableAnimation
              disableOverlay
              entity={digitalPage}
              showModal={showManageEntityQuotesModal}
              subjectId={project.id}
              subjectType="Project"
            />

            <ManageEventCalendarsModal
              callbacks={{
                ...callbacks('ManageEventCalendarsModal', setState, stringsPayload),
                updateDigitalPageFn,
              }}
              disableAnimation
              disableOverlay
              entity={digitalPage}
              project={project}
              showModal={showManageEventCalendarsModal}
            />

            <ManageProjectAttachmentsModal
              callbacks={callbacks('ManageProjectAttachmentsModal', setState, stringsPayload)}
              project={project}
              showModal={showManageProjectAttachmentsModal}
            />

            <ManageProjectCaseStudiesModal
              callbacks={callbacks('ManageProjectCaseStudiesModal', setState, stringsPayload)}
              disableAnimation
              disableOverlay
              project={project}
              showModal={showManageProjectCaseStudiesModal}
            />

            <ManageProjectChartsModal
              callbacks={callbacks('ManageProjectChartsModal', setState, stringsPayload)}
              disableAnimation
              disableOverlay
              project={project}
              showModal={showManageProjectChartsModal}
            />

            <ManageProjectDataStoreItemsModal
              callbacks={callbacks('ManageProjectDataStoreItemsModal', setState, stringsPayload)}
              disableAnimation
              disableOverlay
              project={project}
              showModal={showManageProjectDataStoreItemsModal}
            />

            <ManageProjectReviewsModal
              callbacks={callbacks('ManageProjectReviewsModal', setState, stringsPayload)}
              disableAnimation
              disableOverlay
              project={project}
              showModal={showManageProjectReviewsModal}
            />

            <ManageProjectTargetAudiencesModal
              callbacks={callbacks('ManageProjectTargetAudiencesModal', setState, stringsPayload)}
              disableAnimation
              disableOverlay
              project={project}
              showModal={showManageProjectTargetAudiencesModal}
            />

            <ManageProjectTeamsModal
              callbacks={callbacks('ManageProjectTeamsModal', setState, stringsPayload)}
              disableAnimation
              disableOverlay
              project={project}
              showModal={showManageProjectTeamsModal}
            />

            <ManageProjectUsersModal
              callbacks={callbacks('ManageProjectUsersModal', setState, stringsPayload)}
              disableAnimation
              disableOverlay
              project={project}
              showModal={showManageProjectUsersModal}
            />

            <ManageShareLinksModal
              callbacks={callbacks('ManageShareLinksModal', setState, stringsPayload)}
              disableAnimation
              disableOverlay
              showModal={showManageShareLinksModal}
            />

            <SelectDigitalTemplateModal
              callbacks={callbacks('SelectDigitalTemplateModal', setState, stringsPayload)}
              disableAnimation
              disableOverlay
              showModal={showSelectDigitalTemplateModal}
            />

            <SelectImageModal
              callbacks={callbacks('SelectImageModal', setState, stringsPayload)}
              disableAnimation
              disableOverlay
              projectId={project.id}
              defaultSelectedEntity={{ type: 'Project', id: project.id }}
              showModal={showSelectImageModal}
            />

            <SendSMSModal
              callbacks={callbacks('SendSMSModal', setState, stringsPayload)}
              disableAnimation
              disableOverlay
              showModal={showSendSMSModal}
            />
          </>
          )}
        </ModalContext.Provider>
      </PageContext.Provider>
    </PdfDownloadProvider>
  )
}

DigitalRenderer.propTypes = {
  digitalPageId: PropTypes.number,
  editing: PropTypes.bool,
  shareLink: PropTypes.object,
  shareLinkToken: PropTypes.string,
}

export default DigitalRenderer
