import classnames from 'classnames'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-light-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'

import { Box, Text } from '@campaignhub/suit-theme'

import {
  ButtonBack, ButtonNext, CarouselProvider, DotGroup, Image, Slide, Slider,
} from 'pure-react-carousel'

import 'pure-react-carousel/dist/react-carousel.es.css'

import type { ImageModel } from '@models/image'
import useCarousel from './hooks/useCarousel'

import CustomDotGroup from '../CustomDotGroup'

import styles from './styles.module.scss'

interface CarouselProps {
  images: ImageModel[],
  style: Record<string, string>,
}

const Carousel = (props: CarouselProps) => {
  const { images: carouselImages, style } = props

  const {
    callbacks: {
      goToNextSlide,
      goToPreviousSlide,
      goToSlide,
    },
    currentSlide,
    images,
    isMobile,
    showCarouselElements,
    totalSlides,
  } = useCarousel({ carouselImages })

  return (
    <CarouselProvider
      currentSlide={currentSlide}
      naturalSlideWidth={150}
      naturalSlideHeight={105}
      totalSlides={totalSlides}
      visibleSlides={1}
    >
      <Box flexDirection="column" alignItems="center" justifyContent="center">
        {showCarouselElements && (
          <ButtonBack
            className={classnames(styles.navigationButton, styles.left)}
            onClick={goToPreviousSlide}
          >
            <span className={styles.inner}>
              <FontAwesomeIcon icon={faAngleLeft} />
            </span>
          </ButtonBack>
        )}

        {showCarouselElements ? (
          <Slider
            style={{
              height: 'auto' || style?.carousel_height,
              width: isMobile ? '320px' : '850px',
            }}
          >
            {carouselImages.map((carouselImage, index) => {
              const image = images[carouselImage.id]
              const imageUrl = digObject(image, 'sizes.thumb_2000x1500', '')
              const externalImageUrl = digObject(carouselImage, 'data.external_url', '')

              return (
                // eslint-disable-next-line react/no-array-index-key
                <Slide index={index} key={index}>
                  <Image src={imageUrl || externalImageUrl} hasMasterSpinner={false} style={{ objectFit: 'cover' }} />
                </Slide>
              )
            })}
          </Slider>
        ) : (
          <Image
            src={
                digObject(images[carouselImages[0]?.id], 'image_url', '')
                || digObject(images[carouselImages[0]?.id], 'data.external_url', '')
              }
            hasMasterSpinner={false}
            style={{ objectFit: 'cover', width: isMobile ? '320px' : '850px' }}
          />
        )}

        {showCarouselElements && (
          <ButtonNext
            className={classnames(styles.navigationButton, styles.right)}
            onClick={goToNextSlide}
          >
            <span className={styles.inner}>
              <FontAwesomeIcon icon={faAngleRight} />
            </span>
          </ButtonNext>
        )}
      </Box>

      {!isMobile && showCarouselElements && (
        <DotGroup
          style={{ display: 'flex', justifyContent: 'center', margin: '64px' }}
          showAsSelectedForCurrentSlideOnly
          renderDots={() => (
            <CustomDotGroup
              carouselImages={carouselImages}
              currentSlide={currentSlide}
              images={images}
              goToSlide={goToSlide}
            />
          )}
        />
      )}

      <Text fontSize="small" style={{ textAlign: 'center' }}>{`${currentSlide + 1} of ${totalSlides}`}</Text>
    </CarouselProvider>
  )
}

export default Carousel
