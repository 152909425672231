export default {
  default: {
    buttons: {
      save: 'Save',
    },
    sortedUser: 'Users Sorted Successfully',
    sideBarModalHeader: {
      title: 'Sort',
      titleSecondLine: 'Users',
    },
  },
}
