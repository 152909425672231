import { ReactNode } from 'react'

import { Box, SortableList } from '@campaignhub/suit-theme'

interface ListViewProps {
  itemComponent: ReactNode,
  modifyImageSort: (fromIndex: number, toIndex: number) => void,
}

const ListView = (props: ListViewProps) => {
  const { itemComponent, modifyImageSort } = props

  return (
    <SortableList
      callbacks={{
        onDragEnd: (fromIndex, toIndex) => modifyImageSort(fromIndex, toIndex),
      }}
    >
      <Box flexDirection="column">
        <SortableList.Dropzone droppableId="sortedIds">
          <Box flexDirection="column">
            {itemComponent}
          </Box>
        </SortableList.Dropzone>
      </Box>
    </SortableList>
  )
}

export default ListView
