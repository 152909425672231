import { toast } from 'react-toastify'

import { MainContent, ModalContext } from '@campaignhub/suit-theme'
import { digObject } from '@campaignhub/javascript-utils'
import { useModals, useSetState } from '@campaignhub/react-hooks'

import PageContext from '@contexts/pageContext'
import PdfDownloadContext from '@contexts/pdfDownloadContext'

import PdfDownloadProvider from '@components/PdfDownloadProvider'

import openModalAndCloseAllOthers from '@functions/openModalAndCloseAllOthers'

import useDigitalTemplateEditor, { defaultRequestOptions } from '@hooks/useDigitalTemplateEditor'
import useNumericParams from '@hooks/useNumericParams'

import AddDigitalTemplatePageItemModal from '@modals/AddDigitalTemplatePageItemModal'
import BuildingAttachmentModal from '@modals/BuildingAttachmentModal'
import CreateDigitalTemplateComponentModal from '@modals/CreateDigitalTemplateComponentModal'
import CreateOrEditComponentStyleModal from '@modals/CreateOrEditComponentStyleModal'
import EditDigitalTemplateModal from '@modals/EditDigitalTemplateModal'
import EditDigitalTemplatePageItemModal from '@modals/EditDigitalTemplatePageItemModal'
import EditDigitalTemplateSectionSortModal from '@modals/EditDigitalTemplateSectionSortModal'
import ManageDigitalTemplateAssetsModal from '@modals/ManageDigitalTemplateAssetsModal'
import SessionLockedModal from '@modals/SessionLockedModal'

import PageContent from './components/PageContent'

const modalKeys = [
  'AddDigitalTemplatePageItemModal',
  'CreateDigitalTemplateComponentModal',
  'CreateOrEditComponentStyleModal',
  'EditDigitalTemplateModal',
  'EditDigitalTemplatePageItemModal',
  'EditDigitalTemplateSectionSortModal',
  'ManageDigitalTemplateAssetsModal',
  'SessionLockedModal',
]

const createDigitalTemplateComponent = (params) => {
  const { componentParams, createFn, setState } = params

  createFn(componentParams).then(({ success, errors }) => {
    if (!success && errors){
      toast.warn(errors[0])
      return
    }

    toast('Component Created.')
    openModalAndCloseAllOthers('EditDigitalTemplateModal', modalKeys, setState)
  })
}

const updateDigitalTemplateComponent = (params) => {
  const {
    componentParams, requestOptions, setState, updateFn,
  } = params
  updateFn(componentParams, requestOptions).then(({ success, errors }) => {
    if (!success && errors){
      toast.warn(errors[0])
      return
    }

    toast('Component Updated.')
    openModalAndCloseAllOthers('EditDigitalTemplateModal', modalKeys, setState)
  })
}

const createDigitalTemplateComponentStyle = (params) => {
  const {
    componentParams, createFn, digitalTemplate, updateDigitalTemplate, setState,
  } = params

  createFn(componentParams).then(({ success, data, errors }) => {
    if (!success){
      toast.warn(errors[0])
      return
    }

    const {
      entity: { id: componentStyleId },
    } = data
    const existingComponentIds = digObject(digitalTemplate, 'digital_template_component_ids', [])

    const updatedDigitalTemplate = {
      id: digitalTemplate.id,
      digital_template_component_ids: [...existingComponentIds, componentStyleId],
    }

    updateDigitalTemplate(updatedDigitalTemplate, defaultRequestOptions.digitalTemplate).then(
      ({ success: digitalTemplateSuccess, errors: digitalTemplateErrors }) => {
        if (!digitalTemplateSuccess){
          toast.warn(digitalTemplateErrors[0])
          return
        }

        toast('Component Style Created.')
        openModalAndCloseAllOthers('EditDigitalTemplateModal', modalKeys, setState)
      },
    )
  })
}

const forkDigitalTemplateComponent = (params) => {
  const {
    componentParams, digitalTemplate, forkFn, setState,
  } = params

  const updatedComponentParam = {
    ...componentParams,
    digital_template_id: digitalTemplate.id,
  }

  const mergedOptions = {
    ...defaultRequestOptions.digitalTemplate,
    ...defaultRequestOptions.digitalTemplatePage,
  }
  forkFn(updatedComponentParam, mergedOptions).then(({ success, errors }) => {
    if (!success){
      toast.warn(errors[0])
      return
    }

    toast('Component Style Cloned.')
    openModalAndCloseAllOthers('EditDigitalTemplateModal', modalKeys, setState)
  })
}

const deleteItem = (deleteFn, entity, setState) => {
  deleteFn(entity).then(({ success, errors }) => {
    if (!success){
      toast.warning(errors[0])
      return
    }

    toast('Item Deleted Successfully.')
    openModalAndCloseAllOthers('EditDigitalTemplateModal', modalKeys, setState)
  })
}

const deleteSection = (deleteFn, entity) => {
  deleteFn(entity).then(({ success, errors }) => {
    if (!success){
      toast.warning(errors[0])
      return
    }

    toast('Section Deleted Successfully.')
  })
}

const updateGroupSectionSort = (sortFn, setState) => {
  sortFn().then(({ success, errors }) => {
    if (!success){
      toast.warning(errors[0])
      return
    }

    toast('Sections sorted successfully')
    openModalAndCloseAllOthers('EditDigitalTemplateModal', modalKeys, setState)
  })
}

const callbacks = (component, props, setState, customPayload) => {
  const { digitalTemplate, isMobileDevice, updateDigitalTemplate } = customPayload

  const componentCallbacks = {
    AddDigitalTemplatePageItemModal: {
      closeModal: isMobileDevice ? () => setState({ showAddDigitalTemplatePageItemModal: false }) : null,
    },
    CreateDigitalTemplateComponentModal: {
      closeModal: () => setState({
        showEditDigitalTemplateModal: true,
        showCreateDigitalTemplateComponentModal: false,
      }),
      createDigitalTemplateComponent: (componentParams, createFn) => createDigitalTemplateComponent({
        componentParams,
        createFn,
        setState,
      }),
    },
    CreateOrEditComponentStyleModal: {
      closeModal: () => setState({ showCreateOrEditComponentStyleModal: false, showEditDigitalTemplateModal: true }),
      createDigitalTemplateComponentStyle: (componentParams, createFn) => createDigitalTemplateComponentStyle({
        componentParams,
        createFn,
        digitalTemplate,
        updateDigitalTemplate,
        setState,
      }),
      forkDigitalTemplateComponent: (componentParams, forkFn) => forkDigitalTemplateComponent({
        componentParams,
        forkFn,
        digitalTemplate,
        updateDigitalTemplate,
        setState,
      }),
      updateDigitalTemplateComponent: (componentParams, updateFn, requestOptions) => updateDigitalTemplateComponent({
        componentParams,
        requestOptions,
        setState,
        updateFn,
      }),
    },
    EditDigitalTemplateModal: {
      closeModal: isMobileDevice ? () => setState({ showEditDigitalTemplateModal: false }) : null,
    },
    EditDigitalTemplateSectionSortModal: {
      closeModal: () => setState({
        showEditDigitalTemplateModal: true,
        showEditDigitalTemplateSectionSortModal: false,
      }),
      deleteEntity: (entity, deleteFn) => deleteSection(deleteFn, entity),
      updateGroupSectionSort: sortFn => updateGroupSectionSort(sortFn, setState),
    },
    EditDigitalTemplatePageItemModal: {
      closeModal: isMobileDevice ? () => setState({ showEditDigitalTemplatePageItemModal: false }) : null,
      deleteItem: (deleteFn, entity) => deleteItem(deleteFn, entity, setState),
    },
    ManageDigitalTemplateAssetsModal: {
      closeModal: () => setState({ showManageDigitalTemplateAssetsModal: false, showEditDigitalTemplateModal: true }),
    },
    SessionLockedModal: {
      closeModal: () => setState({ showSessionLockedModal: false, showEditDigitalTemplateModal: true }),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  showAddDigitalTemplatePageItemModal: false,
  showCreateDigitalTemplateComponentModal: false,
  showCreateOrEditComponentStyleModal: false,
  showEditDigitalTemplateModal: false,
  showEditDigitalTemplatePageItemModal: false,
  showEditDigitalTemplateSectionSortModal: false,
  showManageDigitalTemplateAssetsModal: false,
  showSessionLockedModal: false,
}

const Editor = (props) => {
  const { digitalTemplateId } = useNumericParams()

  const [state, setState] = useSetState(defaultState)
  const {
    showAddDigitalTemplatePageItemModal,
    showCreateDigitalTemplateComponentModal,
    showCreateOrEditComponentStyleModal,
    showEditDigitalTemplateModal,
    showEditDigitalTemplatePageItemModal,
    showEditDigitalTemplateSectionSortModal,
    showManageDigitalTemplateAssetsModal,
    showSessionLockedModal,
  } = state

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const digitalEditorPayload = useDigitalTemplateEditor(digitalTemplateId, {
    callbacks: {
      showCreateDigitalTemplateComponentModal: (payload) => {
        setModalData('CreateDigitalTemplateComponentModal', payload)
        openModalAndCloseAllOthers('CreateDigitalTemplateComponentModal', modalKeys, setState)
      },
      showAddDigitalTemplatePageItemModal: () => {
        openModalAndCloseAllOthers('AddDigitalTemplatePageItemModal', modalKeys, setState)
      },
      showCreateOrEditComponentStyleModal: (payload) => {
        setModalData('CreateOrEditComponentStyleModal', payload)
        openModalAndCloseAllOthers('CreateOrEditComponentStyleModal', modalKeys, setState)
      },
      showEditDigitalTemplateModal: (payload) => {
        setModalData('EditDigitalTemplateModal', payload)
        openModalAndCloseAllOthers('EditDigitalTemplateModal', modalKeys, setState)
      },
      showEditDigitalTemplatePageItemModal: (payload) => {
        setModalData('EditDigitalTemplatePageItemModal', payload)
        openModalAndCloseAllOthers('EditDigitalTemplatePageItemModal', modalKeys, setState)
      },
      showEditDigitalTemplateSectionSortModal: (payload) => {
        setModalData('EditDigitalTemplateSectionSortModal', payload)
        openModalAndCloseAllOthers('EditDigitalTemplateSectionSortModal', modalKeys, setState)
      },
      showManageDigitalTemplateAssetsModal: (payload) => {
        setModalData('ManageDigitalTemplateAssetsModal', payload)
        setState({ showManageDigitalTemplateAssetsModal: true })
      },
      showSessionLockedModal: (payload) => {
        setModalData('SessionLockedModal', payload)
        openModalAndCloseAllOthers('SessionLockedModal', modalKeys, setState)
      },
    },
  })

  const {
    callbacks: { updateDigitalTemplate },
    digitalTemplate,
    isMobileDevice,
    previewing,
  } = digitalEditorPayload

  return (
    <div id="main-stage" style={{ position: 'relative' }}>
      <PdfDownloadProvider>
        <PageContext.Provider value={digitalEditorPayload}>
          <ModalContext.Provider value={modalContext}>
            <MainContent offset={{ left: 0 }} width={isMobileDevice || previewing ? '100%' : 'calc(100% - 375px)'}>
              <PageContent digitalEditorPayload={digitalEditorPayload} digitalTemplateId={digitalTemplateId} />
            </MainContent>

            <BuildingAttachmentModal context={PdfDownloadContext} />

            {!previewing && (
              <>
                <AddDigitalTemplatePageItemModal
                  callbacks={callbacks('AddDigitalTemplatePageItemModal', props, setState, {
                    digitalTemplate,
                    isMobileDevice,
                    updateDigitalTemplate,
                  })}
                  showModal={showAddDigitalTemplatePageItemModal}
                />

                <CreateDigitalTemplateComponentModal
                  callbacks={callbacks('CreateDigitalTemplateComponentModal', props, setState, {})}
                  showModal={showCreateDigitalTemplateComponentModal}
                />

                <EditDigitalTemplateModal
                  callbacks={callbacks('EditDigitalTemplateModal', props, setState, { isMobileDevice })}
                  digitalTemplate={digitalTemplate}
                  showModal={showEditDigitalTemplateModal}
                />

                <EditDigitalTemplatePageItemModal
                  callbacks={callbacks('EditDigitalTemplatePageItemModal', props, setState, { isMobileDevice })}
                  showModal={showEditDigitalTemplatePageItemModal}
                />

                <EditDigitalTemplateSectionSortModal
                  callbacks={callbacks('EditDigitalTemplateSectionSortModal', props, setState, {})}
                  showModal={showEditDigitalTemplateSectionSortModal}
                />

                <CreateOrEditComponentStyleModal
                  callbacks={callbacks('CreateOrEditComponentStyleModal', props, setState, {
                    digitalTemplate,
                    updateDigitalTemplate,
                  })}
                  showModal={showCreateOrEditComponentStyleModal}
                />

                <ManageDigitalTemplateAssetsModal
                  callbacks={callbacks('ManageDigitalTemplateAssetsModal', props, setState, {})}
                  showModal={showManageDigitalTemplateAssetsModal}
                />

                <SessionLockedModal
                  callbacks={callbacks('SessionLockedModal', props, setState, {})}
                  showModal={showSessionLockedModal}
                />
              </>
            )}
          </ModalContext.Provider>
        </PageContext.Provider>
      </PdfDownloadProvider>
    </div>
  )
}

Editor.propTypes = {}

export default Editor
