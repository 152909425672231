export default {
  default: {
    swal: {
      title: 'Delete Integration?',
      text: 'Are you sure?',
      confirmButtonText: 'Yes, delete it',
    },
    buttonDeleteIntegration: 'Delete Integration',
    buttonSave: 'Save',
    featureCount: {
      none: '0 Features',
      many: '{count} Features',
      one: '1 Features',
      type: 'count',
    },
    labelMoreOptions: 'More Options',
    labelSupported: 'Supported Feature List',
    labelUser: 'User',
    options: 'OPTIONS',
    titleCreate: 'Create',
    titleEdit: 'Edit',
    titleSecondLine: 'Integration',
    toast: {
      integrationCreated: 'Integration Created Successfully',
      integrationDeleted: 'Integration Deleted Successfully',
      integrationUpdated: 'Integration Updated Successfully',
    },
  },
}
