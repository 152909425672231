import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faArrowsRotate } from '@fortawesome/pro-solid-svg-icons'

import { Box, Link, Text } from '@campaignhub/suit-theme'

import { digObject, snakeToTitleCase } from '@campaignhub/javascript-utils'

import useAttachment from '@hooks/useAttachment'
import useLocalization from '@hooks/useLocalization'

import type { AttachmentModel } from '@models/attachment'

type AttachmentProps = {
  attachment: AttachmentModel,
  callbacks: {
    restoreBackup: () => void,
  },
}

const Attachment = (props: AttachmentProps) => {
  const { callbacks, attachment } = props

  const { restoreBackup } = callbacks || {}

  const attachmentPayload = useAttachment(attachment)
  const {
    attachment: {
      dates: {
        created: { date_time_with_timezone },
      },
      data,
    },
    urls: { downloadUrl },
  } = attachmentPayload

  const attachmentKey = digObject(data, 'key', '')
  const attachmentTitle = digObject(data, 'title', '')

  const {
    callbacks: { formatDate },
  } = useLocalization({})

  return (
    <Box
      alignItems="center"
      color="bodyFontColor"
      fontSize="small"
      marginBottom="small"
      padding="medium"
      variant="whiteGrey"
    >
      <Box flexDirection="column">
        <Text fontSize="xsmall" color="bodyFontLightColor" variant="twoLineEllipsis">
          {snakeToTitleCase(attachmentTitle) || attachmentKey}
        </Text>

        <Text fontSize="xxsmall" marginTop="small" color="bodyFontLightColor" variant="twoLineEllipsis">
          {formatDate(date_time_with_timezone, {
            weekday: 'long',
            day: 'numeric',
            month: 'long',
            hour: '2-digit',
            minute: '2-digit',
          })}
        </Text>
      </Box>

      <Box marginLeft="auto" width="auto">
        {downloadUrl && (
          <Link greyLink href={downloadUrl} textProps={{ marginRight: 'medium' }} target="_blank">
            <FontAwesomeIcon icon={faDownload} />
          </Link>
        )}

        <Link greyLink onClick={() => restoreBackup()}>
          <FontAwesomeIcon icon={faArrowsRotate} />
        </Link>
      </Box>
    </Box>
  )
}

export default Attachment
