export function getShareLinkTokenFromUrl(pathname: string) {
  if (!pathname) return null

  // Example
  // engage.realbase.io/share/H9yNyGAaCmAHT14tk9Ks
  const matches = pathname.match(/\/share\/([\w\d_-]+)\/?/)
  if (!matches) return null

  return matches[1]
}

export default getShareLinkTokenFromUrl
