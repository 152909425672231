import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, Button, FormField, SidebarModal,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import useManageExternalPlatforms from '../../hooks/useManageExternalPlatforms'

import AddOrEditPlatform from '../../components/AddOrEditPlatform'
import ExternalPlatform from './ExternalPlatform'
import ExternalPlatformsBlankState from './ExternalPlatformsBlankState'
import localizedStrings from './localizedStrings'

const handleSave = (platform, externalPlatformIds, setState) => {
  const { externalId, externalPlatformKey } = platform
  const updatedPlatformIds = { ...externalPlatformIds }

  const formattedExternalId = isNaN(externalId) ? externalId : Number(externalId)
  updatedPlatformIds[externalPlatformKey] = formattedExternalId

  setState({ editing: false, externalPlatformIds: updatedPlatformIds })
}

const ExternalPlatforms = (props) => {
  const {
    callbacks,
    callbacks: { submitFn },
    project,
    updating,
  } = props

  const {
    callbacks: { removePlatform, setState },
    editing,
    externalPlatformIds,
    externalPlatforms,
    filteredPlatforms,
    projectImportPlatforms,
    selectedPlatform,
    updatedProjectData,
  } = useManageExternalPlatforms(project)

  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.sideBarModalHeader?.title || 'Connected'}
        titleSecondLine={strings.sideBarModalHeader?.titleSecondLine || 'Platforms'}
      />

      {!editing && (
        <>
          <SidebarModal.Content>
            <Box flexShrink={0} flexDirection="column">
              {!externalPlatforms.length && (
                <ExternalPlatformsBlankState callbacks={{ addPlatform: () => setState({ editing: true }) }} />
              )}

              {externalPlatforms.map(externalPlatform => (
                <ExternalPlatform
                  callbacks={{
                    editPlatform: platform => setState({ editing: true, selectedPlatform: platform }),
                  }}
                  externalPlatform={externalPlatform}
                  key={externalPlatform.externalPlatformKey}
                />
              ))}

              {!!externalPlatforms.length && (
                <FormField direction="column" label={strings.formFieldLabels?.moreOptions || 'More Options'}>
                  <Button
                    buttonStyle="secondaryUtility"
                    onClick={() => setState({ editing: true })}
                    size="medium"
                    style={{ marginBottom: '8px' }}
                  >
                    {strings.buttons?.addPlatform || 'Add External Platform'}
                  </Button>
                </FormField>
              )}
            </Box>
          </SidebarModal.Content>

          <SidebarModal.Footer>
            <Button
              buttonStyle="primaryCreate"
              loading={updating}
              onClick={() => submitFn({ id: project.id, data: updatedProjectData })}
              size="large"
            >
              {strings.button?.save || 'Save'}
            </Button>
          </SidebarModal.Footer>
        </>
      )}

      {editing && (
        <AddOrEditPlatform
          callbacks={{
            handleSave: platform => handleSave(platform, externalPlatformIds, setState),
            removePlatform,
          }}
          deps={[selectedPlatform.externalId]}
          disablePlatformSelect={!!selectedPlatform.externalId}
          entity={selectedPlatform}
          platforms={selectedPlatform.externalId ? projectImportPlatforms : filteredPlatforms}
        />
      )}
    </>
  )
}

ExternalPlatforms.propTypes = {
  callbacks: PropTypes.shape({
    closeModal: PropTypes.func.isRequired,
    submitFn: PropTypes.func.isRequired,
  }).isRequired,
  project: PropTypes.object.isRequired,
  updating: PropTypes.bool.isRequired,
}

export default ExternalPlatforms
