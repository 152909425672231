import { AddressModel } from '@models/address'

export type DataStoreItemModel = {
  cache_key: string,
  id: number,
  data: {},
  digital_page_id: number,
  digital_template_id: number,
  editor_id: number,
  editor_type: string,
  formatted_value: string,
  group_key: string,
  key: string,
  label: string,
  owner_id: number,
  owner_type: string,
  subject_id: number,
  subject_type: string,
  value: string,
}

export type ComparableModel = {
  id: number,
  address_attributes: Partial<AddressModel>,
  address_id: number,
  cache_key: string,
  data_store_items: DataStoreItemModel[],
  data: {},
  project_id: number,
  sort: number,
  source: string,
}

export type ComparableRequestOptions = {
  include_comparable_address?: boolean,
  include_comparable_data_store_items?: boolean,
  include_comparable_default_image?: boolean,
  include_comparable_image_sizes: string[],
}

const state = {
  address_attributes: {},
  data: {},
  id: null,
  project_id: null,
  source: '',
}

export const requiredFields = []

export default state
