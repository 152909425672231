export default {
  default: {
    buttons: {
      save: 'Save',
    },
    formFieldLabels: {
      dataSeries: '*Data Series',
      dataSet: '*Data Set',
      hintMessage: 'Start typing to search',
      searchDataSeries: 'Search Data Series',
      searchDataSets: 'Search Data Sets',
    },
    sideBarModalHeader: {
      title: 'Manage',
      titleSecondLine: 'Chart',
    },
  },
}
