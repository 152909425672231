import React from 'react'
import PropTypes from 'prop-types'

import { Image, InputSearch } from '@campaignhub/suit-theme'

import useGooglePlaces from '@hooks/useGooglePlaces'
import useLocalization from '@hooks/useLocalization'

import poweredByGoogleUrl from './assets/powered_by_google.png'

import localizedStrings from './localizedStrings'

const mapResults = (results, strings) => ({
  groups: [
    {
      key: 'results',
      items: results.map((result) => {
        const { description, place_id } = result

        return {
          ...result,
          key: place_id,
          text: description,
        }
      }),
      title: strings.search?.title || 'Address',
    },
  ],
})

const GoogleAddressSearch = (props) => {
  const {
    callbacks: { selectItem },
  } = props

  const {
    string,
    callbacks: { setState, selectPlace },
    results,
  } = useGooglePlaces()

  const { strings } = useLocalization(localizedStrings)

  const mappedResults = mapResults(results, strings)

  return (
    <InputSearch
      callbacks={{
        selectItem: place => selectPlace(place.place_id, selectItem),
      }}
      filterItems={false}
      onChange={e => setState({ string: e.target.value })}
      results={mappedResults}
      value={string}
      listAction={(
        <Image
          backgroundPosition="left top"
          backgroundSize="contain"
          url={poweredByGoogleUrl}
          width="100%"
          height={14}
        />
      )}
    />
  )
}

GoogleAddressSearch.propTypes = {
  callbacks: PropTypes.shape({
    selectItem: PropTypes.func.isRequired,
  }).isRequired,
}

export default GoogleAddressSearch
