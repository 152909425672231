import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import useDeviceStyle from '@hooks/useDeviceStyle'

import styles from './styles.module.scss'

const Input = (props) => {
  const {
    id, isSelected, onClick, options,
  } = props

  const {
    inputComponent, inputOptions, inputPlaceholder, inputType,
  } = options || {}

  const style = useDeviceStyle(props)

  return (
    <div className={classnames(styles.root, isSelected ? styles.selected : null)} onClick={onClick}>
      <div className={styles.label}>Input {id}</div>

      {inputComponent === 'input' && (
        <input className={styles.input} placeholder={inputPlaceholder} style={style} type={inputType || 'text'} />
      )}

      {inputComponent === 'select' && (
        <select className={styles.input} style={style}>
          {inputOptions
            && inputOptions.map(option => (
              <option key={option.key} value={option.value}>
                {option.value}
              </option>
            ))}
        </select>
      )}

      {inputComponent === 'textarea' && (
        <textarea className={styles.input} placeholder={inputPlaceholder} style={style} />
      )}
    </div>
  )
}

Input.propTypes = {
  id: PropTypes.string,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  options: PropTypes.object,
}

Input.defaultProps = {
  options: {},
}

export default Input
