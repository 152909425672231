import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { digObject } from '@campaignhub/javascript-utils'
import { useForm, useThunkDispatch, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useOrganizationSelector from '@hooks/useOrganizationSelector'
import { getAvailableFeatures, groupPlatformsByFeature } from '@hooks/useIntegrationPlatforms'
import { updateOrganization } from '@hooks/useOrganization'
import { updateUser } from '@hooks/useUser'

const watchEntityKeys = ['integrationPlatforms']

export const getDefaultPlatformsByFeatureKey = (params) => {
  const {
    entityId,
    entityType,
    entities: { organizations, users },
    selectedOrganization,
  } = params

  if (entityType === 'User'){
    const user = users[entityId] || {}
    const defaultPlatforms = digObject(user, 'options.default_platforms')

    if (defaultPlatforms) return defaultPlatforms

    const organization = organizations[user.default_organization_id] || {}
    return digObject(organization, 'options.default_platforms', {})
  }

  if (entityType === 'Organization'){
    const organization = organizations[entityId] || {}
    return digObject(organization, 'options.default_platforms', {})
  }

  return digObject(selectedOrganization, 'options.default_platforms', {})
}

const getEntity = (entityType, entityId, entities) => {
  const { organizations, users } = entities
  if (entityType === 'Organization') return organizations[entityId] || {}
  if (entityType === 'User') return users[entityId] || {}
  return {}
}

const updateEntity = (entity, defaultPlatforms, dispatch) => {
  const updatedOptions = {
    ...entity.options,
    default_platforms: defaultPlatforms,
  }

  if (entity.type === 'Organization'){
    const updatedOrganization = { id: entity.id, options: updatedOptions }

    return updateOrganization({
      organization: entity,
      organizationParams: updatedOrganization,
      customFields: [],
      dispatch,
    })
  }

  if (entity.type === 'User'){
    const updatedUser = { id: entity.id, options: updatedOptions }

    return updateUser({
      dispatch,
      payload: { userParams: updatedUser, customFields: [] },
      user: entity,
    })
  }

  return null
}

function useEntityDefaultIntegrationPlatforms(options = {}){
  const { entityType, entityId, featureKey } = options || {}

  const {
    updatedEntities: { integrationPlatforms: integrationPlatformsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const dispatch = useThunkDispatch()

  const entities = useSelector(reduxState => reduxState.entities)
  const { integrationPlatforms } = entities

  const { selectedOrganization } = useOrganizationSelector()

  const entity = getEntity(entityType, entityId, entities)

  // const availableFeatures = [{ key: 'quoting', label: 'Quoting', supported: true }]
  const availableFeatures = useMemo(() => {
    const features = getAvailableFeatures(integrationPlatforms)
    return features
  }, [integrationPlatformsUpdatedAt])

  // const groupedIntegrationPlatformsByFeature = { quoting: [{ key: 'campaigntrack', label: 'Campaigntrack', id: 1 }] }
  const groupedIntegrationPlatformsByFeature = useMemo(() => {
    const grouped = groupPlatformsByFeature(integrationPlatforms)
    return grouped
  }, [integrationPlatformsUpdatedAt])

  // const defaultPlatforms = { quoting: 'campaigntrack' }
  const defaultPlatforms = getDefaultPlatformsByFeatureKey({
    entityType, entityId, entities, selectedOrganization,
  })

  // const defaultPlatformKeyForFeatureKey = 'campaigntrack' if featureKey is 'quoting'
  const defaultPlatformKeyForFeatureKey = defaultPlatforms[featureKey] || ''

  const formPayload = useForm(
    defaultPlatforms,
    { entity: {}, requiredFields: [] },
    [integrationPlatformsUpdatedAt, entityId, entityType],
  )

  return {
    availableFeatures,
    callbacks: {
      updateEntity: updatedDefaultPlatforms => updateEntity(entity, updatedDefaultPlatforms, dispatch),
    },
    defaultPlatformKeyForFeatureKey,
    defaultPlatforms,
    formPayload,
    groupedIntegrationPlatformsByFeature,
  }
}

export default useEntityDefaultIntegrationPlatforms
