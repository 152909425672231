import { useContext, useMemo } from 'react'

import { useForm, useLatestEntity, useWatchEntityUpdates } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import { launchModal, multiFilteredObjectArraySelector, sortArrayBy } from '@campaignhub/javascript-utils'

import useDispatch from '@hooks/useDispatch'
import useSelector from '@hooks/useSelector'

import * as teamActions from '@redux/modules/team'

import defaultFormState, { requiredFields } from '@models/team'

import PageContext from '@contexts/pageContext'

import type { AppDispatch } from '@redux/store'
import type { DeleteParams } from '@redux/modules/types'
import type { TeamModel, TeamRequestOptions } from '@models/types'

const watchEntityKeys = ['images', 'teamMembers', 'users']

export const generateUrls = () => ({
  teamsIndexUrl: '#/admin/teams',
})

type CreateTeamParams = {
  dispatch: AppDispatch,
  requestOptions?: TeamRequestOptions,
  teamParams: Partial<TeamModel>,
}

const createTeam = (params: CreateTeamParams) => {
  const { dispatch, teamParams, requestOptions } = params
  const { grouped_user_ids } = teamParams

  const { createTeam: createFn } = teamActions

  const updatedTeam = {
    ...teamParams,
    grouped_user_ids: JSON.stringify(grouped_user_ids),
  }

  return dispatch(createFn(updatedTeam, requestOptions))
}

type UpdateTeamParams = {
  dispatch: AppDispatch,
  requestOptions?: TeamRequestOptions,
  team: TeamModel,
  teamParams: Partial<TeamModel>,
}

const updateTeam = (params: UpdateTeamParams) => {
  const {
    dispatch, team, teamParams, requestOptions,
  } = params
  const { grouped_user_ids } = teamParams

  const { updateTeam: updateFn } = teamActions

  const updatedParams = {
    id: team.id,
    ...teamParams,
    grouped_user_ids: JSON.stringify(grouped_user_ids),
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

type DeleteTeamParams = {
  dispatch: AppDispatch,
  team: DeleteParams<TeamModel>,
}

const deleteTeam = (params: DeleteTeamParams) => {
  const { dispatch, team } = params
  const { deleteTeam: deleteFn } = teamActions

  return dispatch(deleteFn(team))
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useTeamForm(
  team: Partial<TeamModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const teamForm = useForm(
    defaultFormState,
    { entity: team, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [team.id, team.cache_key],
  )

  return {
    ...teamForm,
  }
}

function useTeam(initEntity: Partial<TeamModel> = {}) {
  const { entity: team }: { entity: TeamModel} = useLatestEntity(initEntity, 'teams')

  const dispatch = useDispatch()

  const {
    updatedEntities: { images: imagesUpdatedAt, teamMembers: teamMembersUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { callbacks } = useContext(PageContext)

  const {
    entities,
    teams: { creating, updating },
  } = useSelector(state => state)

  const { teamMembers: entityTeamMembers } = entities

  const imageFilters = [
    { key: 'subject_id', value: team.id },
    { key: 'subject_type', value: 'Team' },
  ]

  const filteredImages = useMemo(() => {
    const filtered = multiFilteredObjectArraySelector({ entities }, 'images', imageFilters)
    const sorted = sortArrayBy(filtered, 'asc', 'sort')

    return sorted
  }, [imagesUpdatedAt, team.id])

  const defaultImage = filteredImages[0] || {}

  const teamMembers = useMemo(
    () => Object.values(entityTeamMembers).filter(teamMember => teamMember.team_id === team.id),
    [teamMembersUpdatedAt, team.id],
  )

  return {
    callbacks: {
      createOrEditTeam: (customPayload: {}) => launchModal({
        callbacks,
        modalKey: 'CreateOrEditTeamModal',
        payload: { team, ...customPayload },
      }),
      createTeam: (
        teamParams: Partial<TeamModel>,
        entityOptions?: TeamRequestOptions,
      ) => (
        createTeam({ teamParams, dispatch, requestOptions: entityOptions })
      ),
      deleteTeam: () => deleteTeam({ dispatch, team }),
      updateTeam: (
        teamParams: Partial<TeamModel>,
        entityOptions?: TeamRequestOptions,
      ) => (
        updateTeam({
          team, teamParams, dispatch, requestOptions: entityOptions,
        })
      ),
    },
    defaultImage,
    filteredImages,
    creating,
    team,
    teamMembers,
    updating,
    urls: generateUrls(),
  }
}

export default useTeam
