import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'

import {
  Box, Link, SidebarModal,
} from '@campaignhub/suit-theme'

import useTeam from '@hooks/useTeam'

const ModalTeam = (props) => {
  const { callbacks: { deleteTeam }, teamId } = props

  const { teams } = useSelector(reduxState => reduxState.entities)
  const team = teams[teamId] || {}

  const { defaultImage } = useTeam(team)
  const imageUrl = digObject(defaultImage, 'sizes.thumb_90x90', '')

  return (
    <Box flexDirection="column">
      <SidebarModal.User
        boxProps={{ paddingY: 'large' }}
        disableHover
        imageUrl={imageUrl}
        key={team.id}
        title={team.title}
      >
        <Link
          greyLink
          onClick={() => deleteTeam(team)}
          textProps={{ fontSize: 'small' }}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </Link>
      </SidebarModal.User>
    </Box>
  )
}

ModalTeam.propTypes = {
  callbacks: PropTypes.object.isRequired,
  teamId: PropTypes.number.isRequired,
}

export default ModalTeam
