import { useContext } from 'react'
import { DateTime } from 'luxon'

import { useForm, useLatestEntity } from '@campaignhub/react-hooks'

import { digObject, launchModal } from '@campaignhub/javascript-utils'

import useDispatch from '@hooks/useDispatch'
import useSelector from '@hooks/useSelector'

import * as eventActions from '@redux/modules/event'

import defaultState, { requiredFields } from '@models/event'

import PageContext from '@contexts/pageContext'

import type { AppDispatch } from '@redux/store'
import type { DeleteParams } from '@redux/modules/types'
import type { EventModel, EventRequestOptions } from '@models/types'

type CreateEventParams = {
  dispatch: AppDispatch,
  eventParams: Partial<EventModel>,
  requestOptions?: EventRequestOptions,
}

export const createEvent = (params: CreateEventParams) => {
  const { dispatch, eventParams, requestOptions } = params
  const { createEvent: createFn } = eventActions

  return dispatch(createFn(eventParams, requestOptions))
}

type UpdateEventParams = {
  dispatch: AppDispatch,
  event: EventModel,
  eventParams: Partial<EventModel>,
  requestOptions?: EventRequestOptions,
}

export const updateEvent = (params: UpdateEventParams) => {
  const {
    dispatch, event, eventParams, requestOptions,
  } = params
  const { updateEvent: updateFn } = eventActions

  const updatedParams = {
    id: event?.id,
    ...eventParams,
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

type DeleteEventParams = {
  dispatch: AppDispatch,
  event: DeleteParams<EventModel>,
}

const deleteEvent = (params: DeleteEventParams) => {
  const { dispatch, event } = params
  const { deleteEvent: deleteFn } = eventActions

  return dispatch(deleteFn(event))
}

type DuplicateEventParams = {
  dispatch: AppDispatch,
  event: DeleteParams<EventModel>,
}

export const duplicateEvent = (params: DuplicateEventParams) => {
  const { dispatch, event } = params
  const { duplicateEvent: duplicateFn } = eventActions

  return dispatch(duplicateFn(event))
}

// Changes the date only and leaves time as is
type NewDate = {
  day: string,
  month: string,
  year: string,
}

export const changeEventStartDateString = (event: EventModel, newDate: NewDate) => {
  const startDateString = digObject(event, 'dates.start.date_time_with_timezone')

  if (startDateString && newDate){
    const startDateObj = DateTime.fromISO(startDateString).set({
      year: newDate.year,
      month: newDate.month,
      day: newDate.day,
    })

    return startDateObj.toISO()
  }

  return startDateString
}

export function useEventForm(event: Partial<EventModel>) {
  const { creating, deleting, updating } = useSelector(reduxState => reduxState.events)

  // Map entity so we can set start and end dates
  const endDateString = digObject(event, 'dates.end.date_time_with_timezone')
  const startDateString = digObject(event, 'dates.start.date_time_with_timezone')

  const entity = {
    ...event,
    end_date:
      event.id && endDateString ? DateTime.fromISO(endDateString).toFormat("yyyy-MM-dd'T'HH:mm") : event.end_date,
    start_date:
      event.id && startDateString ? DateTime.fromISO(startDateString).toFormat("yyyy-MM-dd'T'HH:mm") : event.start_date,
  }

  const eventForm = useForm(defaultState, { entity, requiredFields, validateOn: 'change' }, [event.id, event.cache_key])

  return {
    creating,
    deleting,
    updating,
    ...eventForm,
  }
}

export const useRelations = (event: Partial<EventModel> = {}) => {
  const { event_calendar_id, event_group_id } = event || {}

  const entities = useSelector(reduxState => reduxState.entities)
  const { eventCalendars, eventGroups } = entities

  const eventCalendar = event_calendar_id ? eventCalendars[event_calendar_id] || {} : {}
  const eventGroup = event_group_id ? eventGroups[event_group_id] || {} : {}

  return {
    eventCalendar,
    eventGroup,
  }
}

function useEvent(initEntity: Partial<EventModel> = {}) {
  const { entity: event }: { entity: EventModel} = useLatestEntity(initEntity, 'events')

  const dispatch = useDispatch()

  const { callbacks } = useContext(PageContext)

  const endDateString = digObject(event, 'dates.end.date_time_with_timezone')
  const startDateString = digObject(event, 'dates.start.date_time_with_timezone')

  const endTime = endDateString
    ? DateTime.fromISO(endDateString).toLocaleString(DateTime.TIME_24_SIMPLE)
    : digObject(event, 'data.end_time', '')

  const startTime = startDateString
    ? DateTime.fromISO(startDateString).toLocaleString(DateTime.TIME_24_SIMPLE)
    : digObject(event, 'data.start_time', '')

  return {
    callbacks: {
      createEvent: (
        eventParams: Partial<EventModel>,
        entityOptions?: EventRequestOptions,
      ) => (
        createEvent({ dispatch, eventParams, requestOptions: entityOptions })
      ),
      createOrEditEvent: () => launchModal({
        callbacks,
        modalKey: 'CreateOrEditEventModal',
        payload: {
          callbacks: {
            createEvent: (
              eventParams: Partial<EventModel>,
              entityOptions?: EventRequestOptions,
            ) => (
              createEvent({ dispatch, eventParams, requestOptions: entityOptions })
            ),
            updateEvent: (
              eventParams: Partial<EventModel>,
              entityOptions?: EventRequestOptions,
            ) => (
              updateEvent({
                dispatch, event, eventParams, requestOptions: entityOptions,
              })
            ),
          },
          event,
        },
      }),
      deleteEvent: () => deleteEvent({ dispatch, event }),
      duplicateEvent: () => duplicateEvent({ dispatch, event }),
      updateEvent: (
        eventParams: Partial<EventModel>,
        entityOptions?: EventRequestOptions,
      ) => (
        updateEvent({
          dispatch, event, eventParams, requestOptions: entityOptions,
        })
      ),
    },
    endTime,
    event,
    startTime,
  }
}

export default useEvent
