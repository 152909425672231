import { useMemo } from 'react'

import { matchFilterNumber, matchFilterString, sortArrayBy } from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { AssetLibraryModel, AssetLibraryRequestOptions } from '@models/types'
import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['assetLibraries']

type MatchBrandOrOrganizationParams = {
  filterBrandId: number,
  filterOrganizationId: number,
  assetLibrary: AssetLibraryModel,
}
const matchBrandOrOrganization = (params: MatchBrandOrOrganizationParams) => {
  const { filterBrandId, filterOrganizationId, assetLibrary } = params
  const { owner_type, owner_id } = assetLibrary

  const brandMatch = owner_type === 'Brand' ? matchFilterNumber(owner_id, filterBrandId) : false
  const organizationMatch = owner_type === 'Organization' ? matchFilterNumber(owner_id, filterOrganizationId) : false

  return brandMatch || organizationMatch
}

type AssetLibraryFilters = {
  asset_type?: string,
  brand_id?: number,
  organization_id?: number,
  owner_id?: number,
  owner_type?: string,
  string?: string,
}

type UseAssetLibrariesOptions = {
  filters?: AssetLibraryFilters,
  performHttpRequests?: boolean,
  requestOptions?: AssetLibraryRequestOptions,
}

function useAssetLibraries(options: UseAssetLibrariesOptions) {
  const { filters = {}, requestOptions } = options
  const {
    asset_type: filterAssetType,
    brand_id: filterBrandId,
    organization_id: filterOrganizationId,
    owner_id: filterOwnerId,
    owner_type: filterOwnerType,
    string: filterString,
  } = filters

  const {
    updatedEntities: { assetLibraries: assetLibrariesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { assetLibraries } = useSelector(reduxState => reduxState.entities)

  const filteredAssetLibraries = useMemo(() => {
    const filtered = Object.values(assetLibraries).filter((assetLibrary: AssetLibraryModel) => {
      const {
        asset_type: assetType, owner_id, owner_type, title,
      } = assetLibrary

      const assetTypeMatch = filterAssetType ? assetType === filterAssetType : true

      const organizationOrBrandMatch = filterBrandId && filterOrganizationId
        ? matchBrandOrOrganization({ filterBrandId, filterOrganizationId, assetLibrary })
        : true
      const ownerMatch = filterOwnerId
        ? matchFilterNumber(owner_id, filterOwnerId) && owner_type === filterOwnerType
        : true
      const titleMatch = matchFilterString(title, filterString)

      return assetTypeMatch && organizationOrBrandMatch && ownerMatch && titleMatch
    })

    return sortArrayBy(filtered, 'asc', 'title')
  }, [assetLibrariesUpdatedAt, JSON.stringify(options)])

  const filteredAssetLibrariesCount = filteredAssetLibraries.length

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredAssetLibrariesCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingAssetLibraries } = useReduxAction(
    'assetLibraries',
    'loadAssetLibraries',
    {
      ...requestOptions,
      ...filtersWithOffset,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  const filteredBrandAssetLibraries = filteredAssetLibraries.filter(assetLibrary => assetLibrary.owner_type === 'Brand')
  const filteredOrganizationAssetLibraries = filteredAssetLibraries.filter(
    assetLibrary => assetLibrary.owner_type === 'Organization',
  )

  const hasAssetLibraries = !!filteredAssetLibraries.length
  const hasBrandAssetLibraries = !!filteredBrandAssetLibraries.length
  const hasOrganizationAssetLibraries = !!filteredOrganizationAssetLibraries.length

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredAssetLibraries,
    filteredBrandAssetLibraries,
    filteredOrganizationAssetLibraries,
    hasAssetLibraries,
    hasBrandAssetLibraries,
    hasOrganizationAssetLibraries,
    loading: loadingAssetLibraries,
  }
}

export default useAssetLibraries
