import { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { toast } from 'react-toastify'

import { ModalContext } from '@campaignhub/suit-theme'
import { useModals, useSetState } from '@campaignhub/react-hooks'

import PageContext from '@contexts/pageContext'

import handleCallbackAction from '@functions/handleCallbackAction'

import CreateOrEditQuoteModal from '@modals/CreateOrEditQuoteModal'
import ManageEntityUserPermissionsModal from '@modals/ManageEntityUserPermissionsModal'

import PageContent from './components/PageContent'

const createQuote = (quote, createFn, setState) => {
  createFn(quote).then((result) => {
    const { errors, success } = result
    if (!success){
      toast.warn(errors[0])
      return
    }

    toast('Quote Template Created.')

    setState({ showCreateOrEditQuoteModal: false })
  })
}

const callbacks = (component, setState) => {
  const componentCallbacks = {
    CreateOrEditQuoteModal: {
      closeModal: () => setState({ showCreateOrEditQuoteModal: false }),
      createQuote: (params, createFn) => createQuote(params, createFn, setState),
    },
    ManageEntityUserPermissionsModal: {
      updateEntityUserPermissions: payload => handleCallbackAction(payload),
      closeModal: () => setState({ showManageEntityUserPermissionsModal: false }),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  pageFilters: {},
  showCreateOrEditQuoteModal: false,
  showManageQuoteTemplateUserPermissionsModal: false,
}

const QuoteList = () => {
  const [state, setState] = useSetState(defaultState)
  const { pageFilters, showCreateOrEditQuoteModal, showManageEntityUserPermissionsModal } = state

  const modalContext = useModals()
  const { callbacks: { setModalData } } = modalContext

  const pageContext = useMemo(
    () => ({
      callbacks: {
        resetFilters: (resetFn) => {
          if (resetFn) resetFn()
          setState({ pageFilters: {} })
        },
        showCreateOrEditQuoteModal: (payload) => {
          setModalData('CreateOrEditQuoteModal', payload)
          setState({ showCreateOrEditQuoteModal: true })
        },
        showManageEntityUserPermissionsModal: (payload) => {
          setModalData('ManageEntityUserPermissionsModal', payload)
          setState({ showManageEntityUserPermissionsModal: true })
        },
        updateFilters: (filters) => { setState({ pageFilters: filters }) },
      },
    }),
    [],
  )

  return (
    <>
      <Helmet>
        <title>Quote Templates | Engage</title>
      </Helmet>

      <PageContext.Provider value={pageContext}>
        <ModalContext.Provider value={modalContext}>
          <PageContent pageFilters={pageFilters} />

          <CreateOrEditQuoteModal
            callbacks={callbacks('CreateOrEditQuoteModal', setState)}
            showModal={showCreateOrEditQuoteModal}
          />

          <ManageEntityUserPermissionsModal
            callbacks={callbacks('ManageEntityUserPermissionsModal', setState)}
            showModal={showManageEntityUserPermissionsModal}
          />
        </ModalContext.Provider>
      </PageContext.Provider>
    </>
  )
}

QuoteList.propTypes = {}

export default QuoteList
