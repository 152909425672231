import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, Checkbox, ListItem,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const CaseStudy = (props) => {
  const {
    alreadyImported,
    callbacks: { toggleCaseStudy },
    checked,
    caseStudy,
  } = props

  const { address_title } = caseStudy

  const { strings } = useLocalization(localizedStrings)

  return (
    <ListItem.WithCheckbox
      boxProps={{ border: 'none' }}
      checkbox={(
        <Checkbox
          checked={checked}
          onClick={toggleCaseStudy}
        />
      )}
    >
      <Box
        color={alreadyImported ? 'lightGrey' : 'bodyFontLightColor'}
        fontSize="small"
      >
        {address_title} {alreadyImported && ` ${strings.imported || '(Imported)'}`}
      </Box>
    </ListItem.WithCheckbox>
  )
}

CaseStudy.propTypes = {
  alreadyImported: PropTypes.bool,
  callbacks: PropTypes.object.isRequired,
  caseStudy: PropTypes.object,
  checked: PropTypes.bool,
}

export default CaseStudy
