import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import useDigitalTemplatePageSectionGroup from '@hooks/useDigitalTemplatePageSectionGroup'

import SortableSectionsWrapper from '@components/digitalRenderer/components/SortableSectionsWrapper'

import AutoscaledDataLoopSections from './AutoscaledDataLoopSections'
import Section from './Section'

import styles from './styles.module.scss'

const sortSections = sectionArray => sectionArray.sort((a, b) => {
  const sortA = a.options ? a.options.sort : 0
  const sortB = b.options ? b.options.sort : 0

  return sortA > sortB ? 1 : -1
})

const registerComponents = (sections, options) => {
  const { callbacks, isSidebar, sectionGroup } = options
  const sorted = sortSections(sections)

  return sorted
    .filter(section => section)
    .map((section, index) => {
      const sectionProps = {
        callbacks,
        index,
        isSidebar,
        section,
        sectionGroup,
      }

      return section && (
        <Fragment key={section.id}>
          <Section key={`section-${section.id}`} {...sectionProps} />
          <AutoscaledDataLoopSections key={`autoscaled-${section.id}`} {...sectionProps} />
        </Fragment>
      )
    })
}

const SectionGroup = (props) => {
  const {
    callbacks, isSidebar, sectionGroup, style, useSuppliedEntity,
  } = props

  const sectionGroupPayload = useDigitalTemplatePageSectionGroup(sectionGroup, { useSuppliedEntity })
  const { sticky, sortable, sortedSections } = sectionGroupPayload

  return (
    <div className={classnames(styles.root, sticky ? styles.sticky : null)} style={style}>
      <SortableSectionsWrapper
        components={registerComponents(sortedSections, { callbacks, isSidebar, sectionGroup })}
        isSidebar={isSidebar}
        isSortable={sortable}
        render={({ sortedComponents }) => sortedComponents.map(section => section)}
      />
    </div>
  )
}

SectionGroup.propTypes = {
  callbacks: PropTypes.object.isRequired,
  isSidebar: PropTypes.bool,
  sectionGroup: PropTypes.object.isRequired,
  style: PropTypes.object,
  useSuppliedEntity: PropTypes.bool,
}

export default SectionGroup
