export default {
  default: {
    menu: {
      brand: 'Brand',
      industry: 'Industry',
      organization: '{global.organizationType.shortName.singular}',
      you: 'You',
    },
  },
}
