import { useContext, useMemo } from 'react'
import cloneDeep from 'lodash/cloneDeep'

import { useForm, useLatestEntity, useWatchEntityUpdates } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import { launchModal, multiFilteredObjectArraySelector, sortArrayBy } from '@campaignhub/javascript-utils'

import useDispatch from '@hooks/useDispatch'
import useSelector from '@hooks/useSelector'

import * as organizationActions from '@redux/modules/organization'

import defaultFormState, { requiredFields } from '@models/organization'

import PageContext from '@contexts/pageContext'

import type { AppDispatch } from '@redux/store'
import type { CustomFieldModel, OrganizationModel, OrganizationRequestOptions } from '@models/types'

const watchEntityKeys = ['images']

export const generateUrls = (organization?: Partial<OrganizationModel>) => {
  const { id } = organization || {}

  const {
    location: { hash },
  } = window

  const packName = hash.match(/#\/([a-zA-Z]+)(\/.+)/)?.[1] || 'admin'

  return {
    organizationsIndexUrl: `#/${packName}/organizations`,
    editOrganizationUrl: `#/${packName}/organizations/${id}/edit`,
  }
}

type LoadShortcodeListParams = {
  dispatch: AppDispatch,
  organization: OrganizationModel,
  requestOptions?: OrganizationRequestOptions,
}

const loadShortcodeList = (params: LoadShortcodeListParams) => {
  const { dispatch, organization, requestOptions } = params

  const { loadShortcodeList: loadFn } = organizationActions
  return dispatch(loadFn(organization, requestOptions))
}

type GenerateSupportTokenParams = {
  dispatch: AppDispatch,
  organizationId: number,
}

const generateSupportToken = (params: GenerateSupportTokenParams) => {
  const { dispatch, organizationId } = params

  const { generateSupportToken: generateTokenFn } = organizationActions
  return dispatch(generateTokenFn(organizationId))
}

type CreateOrganizationParams = {
  dispatch: AppDispatch,
  organizationParams: Partial<OrganizationModel>,
  requestOptions?: OrganizationRequestOptions,
}

const createOrganization = (params: CreateOrganizationParams) => {
  const { dispatch, organizationParams, requestOptions } = params
  const { options } = organizationParams || {}

  const { createOrganization: createFn } = organizationActions

  const updatedParams = {
    ...organizationParams,
    options: JSON.stringify(options),
  }

  return dispatch(createFn(updatedParams, requestOptions)).then((response) => {
    const { success, data } = response
    if (success){
      const {
        entity: { id },
      } = data

      return {
        success,
        data,
        redirectUrl: `#/systemManager/organizations/${id}/edit`,
      }
    }

    return response
  })
}

type UpdateOrganizationParams = {
  customFields: CustomFieldModel[],
  dispatch: AppDispatch,
  organization: OrganizationModel,
  organizationParams: Partial<OrganizationModel>,
  requestOptions?: OrganizationRequestOptions,
}

export const updateOrganization = (params: UpdateOrganizationParams) => {
  const {
    customFields, dispatch, organization, organizationParams, requestOptions,
  } = params
  const { options } = organizationParams

  const { updateOrganization: updateFn } = organizationActions

  const updatedParams = {
    id: organization.id,
    ...organizationParams,
  }

  if (options){
    const organizationOptions = organization.options || {}
    updatedParams.options = JSON.stringify({
      ...cloneDeep(organizationOptions),
      ...options,
    })
  }

  return dispatch(updateFn(updatedParams, customFields, requestOptions))
}

type UpdateUserSortParams = {
  dispatch: AppDispatch,
  options: OrganizationRequestOptions,
  organization: OrganizationModel,
  sortedIds: number[],
}

const updateUserSort = (params: UpdateUserSortParams) => {
  const {
    dispatch, options, organization, sortedIds,
  } = params
  const { updateUserSortOrder: updateSortFn } = organizationActions

  return dispatch(updateSortFn(organization, sortedIds, options))
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useOrganizationForm(
  organization: Partial<OrganizationModel> = {},
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const organizationForm = useForm(
    defaultFormState,
    { entity: organization, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [organization.id, organization.cache_key],
  )

  return {
    ...organizationForm,
  }
}

export const useRelations = (organization: Partial<OrganizationModel> = {}) => {
  const { brand_id, id } = organization

  const { brands, organizationShortcodeLists } = useSelector(reduxState => reduxState.entities)

  const brand = brand_id ? brands[brand_id] || {} : {}
  const shortcodeList = id ? organizationShortcodeLists[id] || {} : {}

  return {
    brand,
    shortcodeList,
  }
}

function useOrganization(initEntity: Partial<OrganizationModel> = {}) {
  const { entity: organization }: { entity: OrganizationModel} = useLatestEntity(initEntity, 'organizations')
  const { organization_type_id } = organization

  const dispatch = useDispatch()

  const {
    updatedEntities: { images: imagesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { callbacks } = useContext(PageContext)

  const entities = useSelector(reduxState => reduxState.entities)
  const { countries, organizationTypes } = entities

  const organizationType = organizationTypes[organization_type_id] || {}

  const { creating, loading, updating } = useSelector(reduxState => reduxState.organizations)
  const { brand, shortcodeList } = useRelations(organization)

  const defaultAddresses = multiFilteredObjectArraySelector({ entities }, 'addresses', [
    { key: 'owner_id', value: organization.id },
    { key: 'owner_type', value: 'Organization' },
    { key: 'is_default', value: true },
  ]) || []

  const defaultAddress = defaultAddresses[0] || {}

  const country = countries[defaultAddress.country_id] || {}

  const defaultImage = useMemo(() => {
    const organizationImages = multiFilteredObjectArraySelector({ entities }, 'images', [
      { key: 'subject_id', value: organization.id },
      { key: 'subject_type', value: 'Organization' },
    ]) || []

    const sortedImages = sortArrayBy(organizationImages, 'asc', 'sort')

    return sortedImages[0]
  }, [imagesUpdatedAt, organization.id])

  return {
    callbacks: {
      createOrganization: (
        organizationParams: Partial<OrganizationModel>,
        entityOptions?: OrganizationRequestOptions,
      ) => (
        createOrganization({ organizationParams, dispatch, requestOptions: entityOptions })
      ),
      editUserSort: () => launchModal({
        callbacks,
        modalKey: 'EditUserSortModal',
        payload: {
          callbacks: {
            updateUserSort: (
              sortedIds: number[],
              entityOptions: OrganizationRequestOptions,
            ) => updateUserSort({
              organization, sortedIds, dispatch, options: entityOptions,
            }),
          },
          organization,
        },
      }),
      generateSupportToken: (organizationId: number) => generateSupportToken({ organizationId, dispatch }),
      loadShortcodeList: (
        entityOptions: OrganizationRequestOptions,
      ) => loadShortcodeList({ organization, dispatch, requestOptions: entityOptions }),
      updateOrganization: (
        organizationParams: Partial<OrganizationModel>,
        customFields: CustomFieldModel[],
        entityOptions?: OrganizationRequestOptions,
      ) => (
        updateOrganization({
          customFields, organization, organizationParams, dispatch, requestOptions: entityOptions,
        })
      ),
    },
    brand,
    country,
    creating,
    defaultAddress,
    defaultImage,
    loading,
    organization,
    organizationType,
    shortcodeList,
    updating,
    urls: generateUrls(organization),
  }
}

export default useOrganization
