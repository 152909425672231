import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, Button, DashboardModule, SortableList, Text,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

import CustomField from '../CustomField'
import FormBuilderBlankState from '../FormBuilderBlankState'

const FormTemplate = (props) => {
  const {
    formBuilderPayload: {
      callbacks: {
        addFormField,
        editFormField,
      },
      formTemplatePayload: {
        callbacks: {
          modifyItemSort,
        },
        customFields,
        formTemplate,
        sortedFields,
      },
    },
  } = props

  const droppableId = `FormTemplate-${formTemplate.id}`
  const sortedFormFieldIds = sortedFields[droppableId] || []
  const hasFields = !!sortedFormFieldIds.length

  const { strings } = useLocalization(localizedStrings)

  return (
    <SortableList
      callbacks={{
        onDragEnd: (fromIndex, toIndex, payload) => modifyItemSort(fromIndex, toIndex, payload),
      }}
    >
      <Box flexDirection="column" padding="large">
        {!hasFields && <FormBuilderBlankState />}

        <DashboardModule title={strings.title || 'Fields'}>
          <Box flexDirection="column">
            <SortableList.Dropzone droppableId={droppableId}>
              <Box flexDirection="column" padding="large">
                {!hasFields && <Text color="bodyFontLightColor" fontSize="small">{strings.text || 'No Fields available in this template'}</Text>}

                {sortedFormFieldIds.map((customFieldId, itemIndex) => {
                  const customField = customFields[customFieldId] || {}
                  const last = itemIndex + 1 === sortedFormFieldIds.length

                  return (
                    <SortableList.Item draggableId={`Field-${customField.id}`} key={customField.id} index={itemIndex}>
                      <CustomField callbacks={{ editFormField }} customField={customField} last={last} />
                    </SortableList.Item>
                  )
                })}
              </Box>
            </SortableList.Dropzone>

            <DashboardModule.Footer>
              <DashboardModule.FooterRight>
                <Button
                  buttonStyle="secondaryEdit"
                  onClick={() => addFormField()}
                  size="medium"
                  style={{ width: '100%' }}
                >
                  {strings.button || 'Add Field'}
                </Button>
              </DashboardModule.FooterRight>
            </DashboardModule.Footer>
          </Box>
        </DashboardModule>
      </Box>
    </SortableList>
  )
}

FormTemplate.propTypes = {
  formBuilderPayload: PropTypes.shape({
    callbacks: PropTypes.shape({
      addFormField: PropTypes.func.isRequired,
      editFormField: PropTypes.func.isRequired,
    }).isRequired,
    formTemplatePayload: PropTypes.shape({
      callbacks: PropTypes.shape({
        modifyItemSort: PropTypes.func.isRequired,
      }).isRequired,
      customFields: PropTypes.object.isRequired,
      formTemplate: PropTypes.object.isRequired,
      id: PropTypes.number,
      sortedFields: PropTypes.object.isRequired,
    }).isRequired,
    isMobile: PropTypes.bool,
  }).isRequired,
}

export default FormTemplate
