import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers } from '@fortawesome/pro-light-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'

import {
  Box, ListItem, Image, Text,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'
import useTeam from '@hooks/useTeam'

import localizedStrings from './localizedStrings'

const Team = (props) => {
  const { team } = props
  const { title } = team

  const {
    callbacks: { createOrEditTeam },
    defaultImage,
    teamMembers,
  } = useTeam(team)

  const defaultImageUrl = digObject(defaultImage, 'sizes.thumb_200x120', '')

  const teamMemberCount = teamMembers.length

  const {
    callbacks: { formatCount },
  } = useLocalization(localizedStrings)

  return (
    <ListItem
      boxProps={{ borderBottom: '1px dashed', padding: 'large' }}
      onClick={() => createOrEditTeam()}
      showAngleIcon
    >
      <Box
        alignItems="center"
        backgroundColor="whiteGrey"
        border="1px solid"
        borderColor="hoverGrey"
        borderRadius={5}
        height={60}
        justifyContent="center"
        marginRight="large"
        width={100}
      >
        {!defaultImageUrl && <FontAwesomeIcon icon={faUsers} />}

        {defaultImageUrl && <Image borderRadius={5} height={60} marginRight="medium" url={defaultImageUrl} />}
      </Box>

      <Box flexDirection="column" justifyContent="center" minWidth="0">
        <Text fontSize="small">{title}</Text>

        <Text color="lightGrey" fontSize="xsmall" paddingTop="medium" variant="ellipsis">
          <Box alignItems="center">
            <FontAwesomeIcon icon={faUsers} style={{ marginRight: 5 }} />
            {formatCount('teamMemberCount', { count: teamMemberCount })}
          </Box>
        </Text>
      </Box>
    </ListItem>
  )
}

Team.propTypes = {
  team: PropTypes.object.isRequired,
}

export default Team
