import { digObject } from '@campaignhub/javascript-utils'

import type { UserModel } from '@models/user'

export function isMasterAdmin(roleKey: string) {
  const keys = ['admin']
  return roleKey && keys.includes(roleKey)
}

export function isAdmin(roleKey: string) {
  const keys = ['admin', 'support']
  return roleKey && keys.includes(roleKey)
}

export function isBrandUser(roleKey: string) {
  const keys = ['brand_user']
  return roleKey && keys.includes(roleKey)
}

export function isOrganizationUser(roleKey: string) {
  const keys = ['organization_user']
  return roleKey && keys.includes(roleKey)
}

export function isAdminForOrganizationId(user: Partial<UserModel>, organizationId: number) {
  const organizationsWhereAdminIds = digObject(user, 'organizations_where_admin_ids', [])
  return !!organizationId && organizationsWhereAdminIds.includes(organizationId)
}

export function isOwnerForOrganizationId(user: Partial<UserModel>, organizationId: number) {
  const organizationsWhereOwnerIds = digObject(user, 'organizations_where_owner_ids', [])
  return !!organizationId && organizationsWhereOwnerIds.includes(organizationId)
}

export function isDefaultOrganization(user: Partial<UserModel>, organizationId: number) {
  if (!user || !organizationId || !user.default_organization_id || !user.organization_ids?.length){
    return false
  }

  return organizationId === user.default_organization_id
}

export function generateUserRoleString(user: Partial<UserModel>, organizationId: number) {
  if (isAdminForOrganizationId(user, organizationId)) return 'Organisation Admin'
  if (isOwnerForOrganizationId(user, organizationId)) return 'Organisation Owner'
  return 'Organisation User'
}
