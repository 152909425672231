import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhotoVideo } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Image, ListItem, Text,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useAssetLibrary from '@hooks/useAssetLibrary'
import useLocalization from '@hooks/useLocalization'

const ImageLibrary = (props) => {
  const { imageLibrary } = props
  const { title, owner_title } = imageLibrary || {}

  const {
    callbacks: { createOrEditAssetLibrary },
    defaultImage,
    createdDateTime,
  } = useAssetLibrary(imageLibrary)

  const defaultImageUrl = digObject(defaultImage, 'sizes.thumb_200x120', '')

  const { callbacks: { formatDate } } = useLocalization()

  return (
    <ListItem
      boxProps={{ borderBottom: '1px dashed', padding: 'large' }}
      onClick={createOrEditAssetLibrary}
      showAngleIcon
    >
      <Box
        alignItems="center"
        backgroundColor="whiteGrey"
        border="1px solid"
        borderColor="hoverGrey"
        borderRadius={5}
        height={60}
        justifyContent="center"
        marginRight="large"
        width={100}
      >
        {!defaultImageUrl && (
          <FontAwesomeIcon icon={faPhotoVideo} />
        )}

        {defaultImageUrl && (
          <Image
            borderRadius={5}
            height={60}
            url={defaultImageUrl}
          />
        )}
      </Box>

      <Box flexDirection="column" justifyContent="center">
        <Text fontSize="small" color="bodyFontLightColor">
          {title}
        </Text>

        {createdDateTime && (
          <Text fontSize="xsmall" marginTop="medium" color="faintGrey">
            {`Created ${formatDate(createdDateTime, DateTime.DATE_HUGE)}`}
          </Text>
        )}

        <Text fontSize="xsmall" marginTop="medium" color="bodyFontLightColor">
          {owner_title}
        </Text>
      </Box>
    </ListItem>
  )
}

ImageLibrary.propTypes = {
  imageLibrary: PropTypes.object.isRequired,
}

export default ImageLibrary
