import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faToggleOn, faToggleOff } from '@fortawesome/pro-light-svg-icons'

import { Box, Text } from '@campaignhub/suit-theme'

const ToggleButton = (props) => {
  const {
    boxProps, onClick, children, enabled, toggleProps,
  } = props

  return (
    <Box color={enabled ? 'green' : 'bodyFontLightColor'} marginBottom="large" {...boxProps}>
      {children && (
        <Box flexDirection="column" marginRight="large">
          {children}
        </Box>
      )}

      <Box
        alignItems="center"
        onClick={onClick}
        padding="medium"
        style={{ cursor: 'pointer' }}
        variant="white"
        width="auto"
        {...toggleProps}
      >
        <FontAwesomeIcon icon={enabled ? faToggleOn : faToggleOff} />
        <Text color={enabled ? 'green' : 'bodyFontLightColor'} fontSize="xsmall" marginLeft="medium">
          {enabled ? 'On' : 'Off'}
        </Text>
      </Box>
    </Box>
  )
}

ToggleButton.propTypes = {
  boxProps: PropTypes.object,
  children: PropTypes.node,
  enabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  toggleProps: PropTypes.object,
}

export default ToggleButton
