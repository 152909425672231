import React from 'react'
import { Route, Routes } from 'react-router-dom'

import Quote from './screens/Quote'

const External = () => (
  <Routes>
    <Route exact path="/quotes/:platformKey/:externalProjectId" element={<Quote />} />
    <Route exact path="/quotes/:quoteId" element={<Quote singleQuote />} />
  </Routes>
)

export default External
