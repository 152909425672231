export default {
  default: {
    link: {
      hideSearch: 'Hide Search',
      addUser: 'Add User',
    },
    labels: {
      leadLabel: 'Lead',
      supportLabel: 'Support',
    },
    searchPlaceholder: 'Search for users',
    buttonAddUser: 'Click to Add User',
    leaderDropZone: 'Drag user here to add to leaders',
    supportDropZone: 'Drag user here to add to support',
  },
}
