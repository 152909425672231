import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, Image, ListItem, Text,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useComparable from '@hooks/useComparable'
import useLocalization from '@hooks/useLocalization'

import useEntityCustomData from '@hooks/useEntityCustomData'

import placeholderImageUrl from './assets/default_campaign_image_400x400.png'

import ComparableStatus from '../ComparableStatus'
import localizedStrings from './localizedStrings'

const formatPrice = (price, formatCurrency) => {
  if (isNaN(price)) return price
  return formatCurrency(price, '$,.0f')
}

const Comparable = (props) => {
  const { comparable, customFields } = props

  const {
    callbacks: {
      createOrEditComparable,
    },
    defaultImage,
    fullAddress,
  } = useComparable(comparable)

  const customDataPayload = useEntityCustomData(comparable, customFields)
  const {
    customData: {
      bathrooms,
      bedrooms,
      carparks,
      price,
      property_type,
      sale_type,
      sold,
    },
  } = customDataPayload

  const imageUrl = digObject(defaultImage, 'sizes.thumb_200x120', '')

  const { callbacks: { formatCurrency }, strings } = useLocalization(localizedStrings)

  return (
    <ListItem
      boxProps={{ borderBottom: '1px solid', padding: 'large' }}
      onClick={createOrEditComparable}
      showAngleIcon
    >
      <Box width={110} marginRight="large">
        <Image
          borderRadius={5}
          boxProps={{ alignItems: 'flex-end', justifyContent: 'flex-start', padding: 'medium' }}
          height={60}
          marginRight="medium"
          placeholderUrl={placeholderImageUrl}
          url={imageUrl}
          width={100}
        >
          <ComparableStatus propertyType={property_type} sold={sold === 'true'} />
        </Image>
      </Box>

      <Box flexDirection="column" justifyContent="center" minWidth="0">
        <Text fontSize="small">{fullAddress}</Text>

        <Text color="bodyFontLightColor" fontSize="xsmall" paddingTop="medium" variant="ellipsis">
          {`${bedrooms || 0} ${strings.bed || 'Bed'}`}
          {` | ${bathrooms || 0} ${strings.bath || 'Bath'}`}
          {` | ${carparks || 0} ${strings.car || 'Car'}`}
          {!!sale_type && ` - ${sale_type}`}
          {!!price && ` - ${formatPrice(price, formatCurrency)}`}
        </Text>
      </Box>
    </ListItem>
  )
}

Comparable.propTypes = {
  comparable: PropTypes.object,
  customFields: PropTypes.array.isRequired,
}

export default Comparable
