import { faCalendar } from '@fortawesome/pro-light-svg-icons'

import useReduxAction from '@hooks/useReduxAction'
import useLocalization from '@hooks/useLocalization'

import { Box, DashboardModule, Link } from '@campaignhub/suit-theme'

import useEventCalendars from '@hooks/useEventCalendars'
import useProject from '@hooks/useProject'

import CompactList from './CompactList'
import LargeList from './LargeList'

import eventCalendarsBlankState from './assets/event-calendars.svg'

import localizedStrings from './localizedStrings'

export const icon = faCalendar

export const configOptions = (strings: Record<string, {}>) => ({
  title: {
    component: 'input',
    key: 'title',
    label: strings.labelTitle?.title || 'Title',
    optionKey: 'customization',
  },
  display_mode: {
    component: 'select',
    key: 'display_mode',
    label: strings.displayMode?.label || 'Display Mode',
    optionKey: 'customization',
    values: [
      { key: 'compact-list', label: strings.displayModeValues?.labelListCompact || 'List (Compact)' },
      { key: 'large-list', label: strings.displayModeValues?.labelListLarge || 'List (Large)' },
    ],
  },
  limit: {
    component: 'select',
    key: 'limit',
    label: strings.limit?.label || 'Limit',
    optionKey: 'queryParams',
    values: [
      { key: 5, label: '5' },
      { key: 10, label: '10' },
      { key: 25, label: '25' },
      { key: 50, label: '50' },
    ],
  },
})

const defaultRequestOptions = {
  include_event_calendar_event_groups: true,
  include_event_calendar_events: true,
}

interface EventCalendarsProps {
  customOptions: {
    customization: {
      display_mode: string,
      title: string,
    },
    queryParams: {
      limit: number,
      subject_id: number,
      subject_type: string,
    },
  },
  editing: boolean,
  parentId: string,
}

const EventCalendars = (props: EventCalendarsProps) => {
  const {
    customOptions,
    customOptions: {
      queryParams: { limit, subject_id, subject_type },
    },
    editing,
    parentId,
  } = props

  const requestOptions = {
    ...defaultRequestOptions,
    ...customOptions.queryParams,
  }

  useReduxAction('eventCalendars', 'loadEventCalendars', requestOptions, [parentId, JSON.stringify(requestOptions)])

  const { hasEventCalendars, filteredEventCalendars, loading } = useEventCalendars({
    filters: {
      subjectId: subject_id,
      subjectType: subject_type,
    },
    limit,
  })

  const {
    urls: { eventsUrl },
  } = useProject({ id: subject_id })

  const { customization } = customOptions
  const { display_mode, title } = customization || {}
  const { strings } = useLocalization(localizedStrings)

  return (
    <DashboardModule
      boxProps={{ marginBottom: 0 }}
      editing={editing}
      headerRight={(
        <Link href={eventsUrl} textProps={{ fontSize: 'small' }}>
          {strings.manage || 'Manage'}
        </Link>
      )}
      loading={loading}
      title={title || strings.title?.title || 'Events'}
    >
      {!loading && !hasEventCalendars && (
        <DashboardModule.BlankState imageUrl={eventCalendarsBlankState}>
          <DashboardModule.BlankState.Title>
            {strings.blankState?.title || 'No Calendars Available'}
          </DashboardModule.BlankState.Title>

          <DashboardModule.BlankState.Paragraph>
            {strings.blankState?.paragraph
              || 'There are no calendars available in this project yet. If you’re integrated with a marketing platform that exports calendars you can import them here, or you can create one from scratch.'}
          </DashboardModule.BlankState.Paragraph>
        </DashboardModule.BlankState>
      )}

      {!loading && hasEventCalendars && (
        <Box flexDirection="column" flexWrap="wrap">
          {filteredEventCalendars.map((eventCalendar) => {
            const ListComponent = display_mode === 'compact-list' ? CompactList : LargeList

            return <ListComponent key={eventCalendar.id} eventCalendar={eventCalendar} />
          })}
        </Box>
      )}
    </DashboardModule>
  )
}

export default EventCalendars
