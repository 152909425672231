import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './styles.module.scss'

const ContentHolder = (props) => {
  const { children, contentWidthId } = props

  return (
    <div className={classnames(styles.contentHolder, contentWidthId ? styles[contentWidthId] : null)}>{children}</div>
  )
}

ContentHolder.propTypes = {
  children: PropTypes.node.isRequired,
  contentWidthId: PropTypes.string,
}

export default ContentHolder
