export default {
  default: {
    formFieldLabels: {
      body: 'Body',
      title: '* Title',
    },
    cancelButton: 'Cancel',
    createTitle: 'Create',
    createAward: 'Create Award',
    deleteAward: 'Delete Award',
    moreOptions: 'More Options',
    awardImageLabel: 'Award Image',
    saveAward: 'Save Award',
    titleSecondLine: 'Award',
    updateTitle: 'Update',
    toast: {
      awardCreated: 'Award Created Successfully',
      awardDeleted: 'Award Deleted Successfully',
      awardUpdated: 'Award Updated Successfully',
    },
    sweetAlert: {
      delete: {
        confirmButton: 'Yes, delete it.',
        text: 'Are you sure? ',
        title: 'Delete Award',
      },
    },
  },
}
