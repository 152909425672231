import React from 'react'

import { BlankState } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

import agreementsImageUrl from './assets/agreements.svg'

const AgreementBlankState = () => {
  const { strings } = useLocalization(localizedStrings)

  return (
    <BlankState imageUrl={agreementsImageUrl}>
      <BlankState.Title>
        {strings.title || 'No Agreements Available'}
      </BlankState.Title>

      <BlankState.Paragraph>
        {strings.paragraph || 'There are no agreements available in this project yet. You can create one now using the button at the top of the page.'}
      </BlankState.Paragraph>
    </BlankState>
  )
}

export default AgreementBlankState
