export default {
  default: {
    chartKeyValues: {
      engagementPerPageView: 'Engagement per page view',
      engagementPercentage: 'Engagement Status Percentage',
    },
    label: {
      title: 'Title',
    },
    performance: 'Performance',
    chartKey: {
      label: 'Chart Type',
    },
    comparablesTitle: 'Comparables',
    createText: 'Create',
    customizableDashboardMainTitle: 'Main',
    customizableDashboardSidebarTitle: 'Sidebar',
    customizeDashboard: 'Customize Dashboard',
    dashboard: 'Dashboard',
    digitalPagesTitle: 'Digital Pages',
    documentsTitle: 'Documents',
    engagementPerPageView: 'Engagement per Page View',
    eventCalendarsTitle: 'Event Calendars',
    mediaTitle: 'Media',
    quotesTitle: 'Quotes',
    saveDashboard: 'Save Dashboard',
    title: 'Project Overview',
  },
}
