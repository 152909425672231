export default {
  default: {
    buttons: {
      cancel: 'Cancel',
      save: 'Save',
    },
    formFieldLabels: {
      country: 'Country',
      lotNumber: 'Lot Number',
      postcode: '* Post Code',
      state: '* State',
      streetName: '* Street Name',
      streetNumber: '* Street Number',
      suburb: '* Suburb',
      title: 'Title',
      unitNumber: 'Unit Number',
    },
    sideBarModalHeader: {
      update: 'Update',
      title: 'Manage',
      titleSecondLine: 'Address',
    },
    toast: {
      addressCreated: 'Address Created Successfully',
      addressUpdated: 'Address Updated Successfully',
    },
  },
}
