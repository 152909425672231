import React from 'react'
import PropTypes from 'prop-types'

import { faTachometer } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Columns, LoadingModule, PageHeader,
} from '@campaignhub/suit-theme'

import useEntityCustomFieldsForm from '@hooks/useEntityCustomFieldsForm'
import useLocalization from '@hooks/useLocalization'
import useOrganizationSelector from '@hooks/useOrganizationSelector'
import useProject, { useProjectForm } from '@hooks/useProject'

import EntityCustomFields from '@components/EntityCustomFields'
import SaveFormModule from '@components/SaveFormModule'

import Address from '../Address'
import Contacts from '../Contacts'
import Documents from '../Documents'
import ExternalPlatforms from '../ExternalPlatforms'
import Organization from '../Organization'
import Teams from '../Teams'
import Users from '../Users'

import localizedStrings from './localizedStrings'
import ProjectType from '../ProjectType'

const PageContent = (props) => {
  const {
    callbacks: { updateProject },
    projectId,
  } = props

  const {
    address,
    callbacks: {
      manageProjectAttachments,
      manageProjectContacts,
      manageProjectExternalPlatforms,
      manageProjectTeams,
      manageProjectUsers,
      updateProject: updateFn,
    },
    filteredContacts,
    filteredTeams,
    leadUsers,
    loading,
    organization,
    project,
    projectType: { key: projectTypeKey },
    supportUsers,
    updating,
    urls: { editUrl },
  } = useProject({ id: projectId })

  const projectFormPayload = useProjectForm(project)
  const { entityState } = projectFormPayload

  const { hasMultipleOrganizations, sortedOrganizations } = useOrganizationSelector()

  const customFieldsForm = useEntityCustomFieldsForm(
    project,
    organization,
    {
      filterBy: { project_type_key: projectTypeKey },
      performHttpRequests: true,
    },
    [projectTypeKey],
  )

  const {
    callbacks: { buildNestedValues },
  } = customFieldsForm

  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      <PageHeader
        activeTabBarItemKey="details"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        nestedNavigation
        tabBarItems={[
          {
            href: editUrl,
            icon: faTachometer,
            key: 'details',
            title: strings.title || 'Details',
          },
        ]}
        title={strings.title || 'Details'}
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && (
              <>
                <Address address={address} />

                {hasMultipleOrganizations && (
                  <Organization organizations={sortedOrganizations} projectFormPayload={projectFormPayload} />
                )}

                <ProjectType projectFormPayload={projectFormPayload} />

                <EntityCustomFields customFieldsForm={customFieldsForm} />

                <SaveFormModule childrenLeft={strings.saveText || 'Save Project'}>
                  <Button
                    buttonStyle="primaryCreate"
                    disabled={false}
                    loading={updating}
                    onClick={() => updateProject(entityState, buildNestedValues(), updateFn)}
                    size="medium"
                    style={{ width: '100%' }}
                  >
                    {strings.saveButtonText || 'Save Details'}
                  </Button>
                </SaveFormModule>
              </>
            )}
          </Columns.Main>

          <Columns.Sidebar>
            <LoadingModule loading={loading} times={3} />

            {!loading && (
              <>
                <Users callbacks={{ manageProjectUsers }} leadUsers={leadUsers} supportUsers={supportUsers} />

                <Teams callbacks={{ manageProjectTeams }} teams={filteredTeams} />

                <Contacts callbacks={{ manageProjectContacts }} contacts={filteredContacts} />

                <Documents callbacks={{ manageProjectAttachments }} project={project} />

                <ExternalPlatforms callbacks={{ manageProjectExternalPlatforms }} project={project} />
              </>
            )}
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

PageContent.propTypes = {
  callbacks: PropTypes.shape({
    updateProject: PropTypes.func.isRequired,
  }).isRequired,
  projectId: PropTypes.number,
}

export default PageContent
