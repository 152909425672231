export default {
  default: {
    formFieldLabels: {
      email: '* Email Address',
      firstName: '* First Name',
      jobTitle: 'Job Title',
      lastName: '* Last Name',
      legalName: 'Legal Name',
      mobile: '* Phone',
      select: 'Select...',
    },
    title: 'Details',
  },
}
