import PropTypes from 'prop-types'

import { Button, PageHeader } from '@campaignhub/suit-theme'

import { getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const Header = (props) => {
  const { calendarBuilderPayload: { isMobile } } = props

  const { redirect } = parsePermittedQueryParams(getQueryParamsFromHash(), ['redirect'])

  const { strings } = useLocalization(localizedStrings)

  return (
    <PageHeader
      boxProps={{ justifyContent: 'flex-start' }}
      offset={{ left: 0, top: 0 }}
      width={!isMobile ? 'calc(100% - 375px)' : '100%'}
      title={strings.title || 'Calendar'}
      actionContent={(
        <Button as="a" href={redirect || '/'} buttonStyle="primaryCreate" size="medium">
          {strings.goBack || 'Go Back'}
        </Button>
      )}
    />
  )
}

Header.propTypes = {
  calendarBuilderPayload: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
}

export default Header
