export default {
  default: {
    buttons: {
      save: 'Save',
      manage: 'Manage Quotes',
    },
    formFieldLabels: {
      availableQuotes: 'Available Quotes',
      moreOptions: 'More Options',
    },
    sideBarModalHeader: {
      title: 'Manage',
      titleSecondLine: 'Quotes',
    },
  },
}
