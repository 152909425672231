import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, FormField, ModalContext, SidebarModal, Text,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import { capitalizeString } from '@functions/string'

import useDigitalPage from '@hooks/useDigitalPage'
import useFormResponse from '@hooks/useFormResponse'
import useLocalization from '@hooks/useLocalization'
import localizedStrings from './localizedStrings'

const ManageFormResponseModal = (props) => {
  const { callbacks, modalKey, showModal } = props

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext
  const { formResponse } = digObject(modalData, modalKey, {})

  const {
    dates: {
      created: { date_time_with_timezone },
    },
    digital_page_id,
    label,
  } = formResponse || {}

  const { formResponseValues, formTemplate } = useFormResponse(formResponse)
  const { title: templateTitle } = formTemplate

  const {
    digitalPage,
    urls: { editDigitalPageUrl },
  } = useDigitalPage({ id: digital_page_id })

  const { title } = digitalPage

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.title || 'Form'}
        titleSecondLine={strings.titleSecondLine || 'Response'}
      />

      <SidebarModal.Content boxProps={{ padding: 0 }}>
        <Box flexDirection="row" padding="large">
          <FormField
            boxProps={{ lineHeight: 1.3 }}
            direction="column"
            label={strings.formFieldLabel?.responseFor || 'Response for'}
          >
            <Box alignItems="center" flexDirection="row">
              <Box flexDirection="column">
                <Text fontSize="small">{title || label}</Text>

                <Text color="mysteryGrey" fontSize="xsmall" marginTop="small">
                  {templateTitle}
                </Text>
              </Box>

              <Button
                as="a"
                buttonStyle="ghostEdit"
                href={editDigitalPageUrl}
                icon={<FontAwesomeIcon icon={faEye} />}
                marginLeft="medium"
                size="medium"
                style={{ height: 37 }}
                width="auto"
              >
                {strings.button?.view || 'View'}
              </Button>
            </Box>
          </FormField>
        </Box>

        <FormField
          boxProps={{ backgroundColor: 'whiteGrey', padding: 'large' }}
          direction="column"
          label={strings.formFieldLabel?.responseReceived || 'Response Received'}
        >
          <Text marginTop="medium" fontSize="xsmall">
            {DateTime.fromISO(date_time_with_timezone).toFormat('EEE ff a')}
          </Text>
        </FormField>

        <Box flexDirection="column" width="auto" padding="large" fontSize="xsmall">
          {Object.entries(formResponseValues).map(([key, value]) => (
            <FormField boxProps={{ marginBottom: 'large' }} direction="column" label={capitalizeString(key)}>
              <Text marginTop="small">{value}</Text>
            </FormField>
          ))}
        </Box>
      </SidebarModal.Content>
    </SidebarModal>
  )
}

ManageFormResponseModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

ManageFormResponseModal.defaultProps = {
  modalKey: 'ManageFormResponseModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <ManageFormResponseModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
