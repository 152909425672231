import React from 'react'
import PropTypes from 'prop-types'

import { faFile } from '@fortawesome/pro-light-svg-icons'

import { TopBar } from '@campaignhub/suit-theme'

import useDashboardSearch from './useDashboardSearch'
import SearchResult from './components/SearchResult'

const DashboardSearch = (props) => {
  const { searchEntities } = props

  const {
    callbacks: {
      dashboardSearch,
      mapResults,
    },
  } = useDashboardSearch({ searchEntities })

  return (
    <TopBar.Search
      callbacks={{ search: dashboardSearch }}
      mapResults={data => mapResults(data)}
      renderResult={entity => <SearchResult key={entity.id} icon={faFile} result={entity} />}
    />
  )
}

DashboardSearch.propTypes = {
  searchEntities: PropTypes.array.isRequired,
}

export default DashboardSearch
