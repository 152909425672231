export default {
  default: {
    formFieldLabels: {
      findComparable: 'Find a Comparable',
    },
    mapResults: {
      title: 'COMPARABLES',
    },
    comparableSearchPlaceholder: 'Search for a comparable',
  },
}
