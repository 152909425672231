import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import isMobileBrowser from 'is-mobile'
import cloneDeep from 'lodash/cloneDeep'

import { digObject } from '@campaignhub/javascript-utils'
import { useSetState, useThunkDispatch, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'

import * as digitalTemplatePageActions from '@redux/modules/digitalTemplatePage'
import * as editorActions from '@redux/modules/digitalTemplateBuilder/editor'

import useDigitalEditorSelectedEntity from '@hooks/useDigitalEditorSelectedEntity'
import useDigitalTemplateFonts from '@hooks/useDigitalTemplateFonts'
import useOrganizationSelector from '@hooks/useOrganizationSelector'
import { copySectionToSectionGroup } from '@hooks/useDigitalTemplatePageSection'
import { getDigitalTemplateEnabledFeatures, useRelations } from '@hooks/useDigitalTemplate'

import isPrintMode from '@components/digitalRenderer/utils/isPrintMode'

import defaultRequestOptions from '../defaultRequestOptions'

const isMobile = isMobileBrowser()
const isTablet = isMobileBrowser({ tablet: true })
const isMobileDevice = isMobile || isTablet

const watchEntityKeys = ['digitalTemplatePageSectionGroups', 'digitalTemplatePages', 'digitalTemplates']

// Not declaring in Templates index as
// we dont want to load all of the relations when rendering the
// list view.

const autoSelectPage = (digitalTemplate, selectedPageId, dispatch) => {
  const { selectPageId: selectFn } = digitalTemplatePageActions

  const firstPageId = digObject(digitalTemplate, 'pages.0')

  if ((!selectedPageId || selectedPageId !== firstPageId) && firstPageId){
    dispatch(selectFn(firstPageId))
  }
}

const getTargetDevice = (printMode) => {
  if (printMode) return 'print'
  if (isMobile) return 'mobile'
  if (isTablet) return 'tablet'
  return 'default'
}

const updateEntityParam = (entityType, entity, key, value, dispatch) => {
  const { updateCanvasItem: updateFn } = editorActions

  const updatedEntity = {
    ...entity,
    [key]: value,
  }

  return dispatch(updateFn(entityType, updatedEntity))
}

const addImage = (selectedEntityRef, image, dispatch) => {
  const { updateCanvasItem: updateFn } = editorActions

  const entity = selectedEntityRef.current
  const { entityType } = entity || {}

  const updatedEntity = {
    ...cloneDeep(entity),
    image: {
      id: image.id,
    },
  }

  return dispatch(updateFn(entityType, updatedEntity))
}

const changeItemImage = (
  digitalTemplate,
  selectedEntityRef,
  item,
  e,
  dispatch,
  showManageDigitalTemplateAssetsModal,
) => {
  const { selectCanvasItem: selectFn } = editorActions

  e.stopPropagation()

  dispatch(selectFn(item))

  return new Promise((resolve, reject) => {
    if (showManageDigitalTemplateAssetsModal){
      const payload = {
        callbacks: {
          selectImage: image => addImage(selectedEntityRef, image, dispatch),
        },
        digitalTemplate,
      }

      showManageDigitalTemplateAssetsModal(payload)

      return resolve({ success: true, result: payload })
    }

    return reject(new Error('showManageDigitalTemplateAssetsModal not defined in PageContext callbacks'))
  })
}

const selectCanvasItem = (digitalTemplate, item, e, dispatch, showCustomizeDigitalTemplatePageItemModal) => {
  const { deleteCanvasItem: deleteFn, selectCanvasItem: selectFn, updateCanvasItem: updateFn } = editorActions

  const { type } = item || {}

  e.stopPropagation()

  dispatch(selectFn(item))

  return new Promise((resolve, reject) => {
    if (showCustomizeDigitalTemplatePageItemModal){
      const payload = {
        callbacks: {
          deleteEntity: entity => dispatch(deleteFn(type, entity)),
          updateEntity: updatedEntity => dispatch(updateFn(type, updatedEntity)),
          updateEntityParam: (entity, key, value) => updateEntityParam(type, entity, key, value, dispatch),
        },
        digitalTemplate,
        selectedCanvasItem: item,
      }

      showCustomizeDigitalTemplatePageItemModal(payload)

      return resolve({ success: true, result: payload })
    }

    return reject(new Error('showCustomizeDigitalTemplatePageItemModal not defined in PageContext callbacks'))
  })
}

const selectSourceTemplateSection = (digitalTemplatePage, insertSectionPayload, section, setState, dispatch) => {
  const { index, sectionGroup } = insertSectionPayload

  return copySectionToSectionGroup(digitalTemplatePage, sectionGroup, section, index, dispatch).then((result) => {
    setState({
      insertSectionPayload: {},
      templateViewMode: 'default',
    })

    return result
  })
}

const viewSourceTemplateSections = (sectionPayload, setState, showSelectDigitalTemplateSourceTemplateModal) => {
  setState({
    insertSectionPayload: sectionPayload,
    templateViewMode: 'source',
  })

  return new Promise((resolve, reject) => {
    if (showSelectDigitalTemplateSourceTemplateModal){
      const payload = {
        ...sectionPayload,
        callbacks: {
          ...sectionPayload.callbacks,
          selectTemplate: digitalTemplate => setState({ sourceTemplateId: digitalTemplate.id }),
        },
      }

      showSelectDigitalTemplateSourceTemplateModal(payload)

      return resolve({ success: true, result: payload })
    }

    return reject(new Error('showSelectDigitalTemplateSourceTemplateModal not defined in PageContext callbacks'))
  })
}

const editSectionSort = (digitalTemplatePage, showEditDigitalTemplateSectionSortModal, dispatch) => new Promise((resolve, reject) => {
  if (showEditDigitalTemplateSectionSortModal){
    const { deleteCanvasItem: deleteFn } = editorActions

    const payload = {
      callbacks: {
        deleteEntity: entity => dispatch(deleteFn(entity.entityType, entity)),
      },
      digitalTemplatePage,
    }

    showEditDigitalTemplateSectionSortModal(payload)

    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showEditDigitalTemplateSectionSortModal not defined in PageContext callbacks'))
})

const defaultState = {
  insertSectionPayload: {},
  previewing: false,
  selectedDigitalPageId: null,
  sourceTemplateId: null,
  templateViewMode: 'default',
}

function useDigitalTemplateCustomizer(options = {}){
  const {
    callbacks,
    callbacks: {
      copySectionToGroup,
      showCustomizeDigitalTemplatePageItemModal,
      showEditDigitalTemplateSectionSortModal,
      showManageDigitalTemplateAssetsModal,
      showSelectDigitalTemplateSourceTemplateModal,
    },
    digitalTemplateId,
  } = options || {}

  const [state, setState] = useSetState(defaultState)
  const {
    insertSectionPayload, previewing, selectedDigitalPageId, sourceTemplateId, templateViewMode,
  } = state

  const dispatch = useThunkDispatch()

  const selectedPageId = useSelector(reduxState => reduxState.digitalTemplatePages.selectedId)
  const loadingDigitalTemplates = useSelector(reduxState => reduxState.digitalTemplates.loading)

  const entities = useSelector(reduxState => reduxState.entities)
  const {
    digitalPages, digitalPageShortcodeData, digitalTemplatePages, digitalTemplates, organizationShortcodeLists,
  } = entities

  const digitalPage = digitalPages[selectedDigitalPageId] || {}

  const digitalTemplate = digitalTemplates[digitalTemplateId] || {}
  const digitalTemplatePage = digitalTemplatePages[selectedPageId] || {}

  const digitalTemplateRelations = useRelations(digitalTemplate)
  const { originTemplate } = digitalTemplateRelations

  const { selectedOrganization } = useOrganizationSelector()

  // Set origin as source
  useEffect(() => {
    if (originTemplate.id){
      setState({
        sourceTemplateId: originTemplate.id,
      })
    }
  }, [originTemplate.id])

  const sourceTemplate = digitalTemplates[sourceTemplateId] || {}
  const sourceTemplatePageId = digObject(sourceTemplate, 'pages.0')
  const sourceTemplatePage = digitalTemplatePages[sourceTemplatePageId] || {}

  const {
    updatedEntities: { digitalTemplates: digitalTemplatesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  // Load Template
  useReduxAction(
    'digitalTemplates',
    'loadDigitalTemplate',
    {
      ...defaultRequestOptions.attachment,
      ...defaultRequestOptions.digitalTemplate,
      ...defaultRequestOptions.digitalTemplatePage,
    },
    [digitalTemplateId],
    {
      dispatchAction: (action, requestOptions) => action(digitalTemplateId, requestOptions),
      shouldPerformFn: (entityReducer) => {
        const { loading } = entityReducer
        return digitalTemplateId && !loading
      },
    },
  )

  // Load Source Template
  useReduxAction(
    'digitalTemplates',
    'loadDigitalTemplate',
    {
      ...defaultRequestOptions.attachment,
      ...defaultRequestOptions.digitalTemplate,
      ...defaultRequestOptions.digitalTemplatePage,
    },
    [sourceTemplateId],
    {
      dispatchAction: (action, requestOptions) => action(sourceTemplateId, requestOptions),
      shouldPerformFn: (entityReducer) => {
        const { loadedIds, loading } = entityReducer
        return (
          sourceTemplateId
          && sourceTemplateId !== originTemplate.id
          && !loadedIds.includes(sourceTemplateId)
          && !loading
        )
      },
    },
  )

  // Load Template Fonts
  useDigitalTemplateFonts(digitalTemplate)

  // Load Shortcode List
  useReduxAction('organizations', 'loadShortcodeList', {}, [digitalTemplate.id, previewing], {
    dispatchAction: (action, requestOptions) => action({ id: selectedOrganization.id }, requestOptions),
    shouldPerformFn: ({ loadedShortcodeListIds, loading }) => !loadedShortcodeListIds.includes(selectedOrganization.id) && !loading,
  })

  // Load Shortcode Data
  useReduxAction(
    'digitalPages',
    'loadDigitalPageShortcodeData',
    {
      digital_template_id: digitalTemplate.id,
    },
    [digitalTemplate.id],
    {
      dispatchAction: (action, requestOptions) => action({ id: 'sandbox' }, requestOptions),
      shouldPerformFn: (entityReducer) => {
        const { loadedShortcodeDataIds, loading } = entityReducer
        return digitalTemplate.id && !loadedShortcodeDataIds.includes('sandbox') && !loading
      },
    },
  )

  const sandboxDigitalPageId = useSelector(reduxState => reduxState.digitalPages.sandboxId)
  useEffect(() => {
    if (sandboxDigitalPageId){
      setState({
        selectedDigitalPageId: sandboxDigitalPageId,
      })
    }
  }, [sandboxDigitalPageId])

  const shortcodeData = digitalPageShortcodeData[selectedDigitalPageId] || {}

  const selectedEntityRef = useRef()
  const { selectedEntity } = useDigitalEditorSelectedEntity('selectedCanvasItem')
  selectedEntityRef.current = selectedEntity

  const shortcodeList = organizationShortcodeLists[selectedOrganization.id] || {}
  const shortcodeListArray = Object.values(shortcodeList).filter(item => typeof item === 'object' && item !== null)

  // Autoselect Page
  useEffect(
    () => autoSelectPage(digitalTemplate, selectedPageId, dispatch),
    [digitalTemplate.id, digitalTemplatesUpdatedAt],
  )

  const viewingSource = templateViewMode === 'source'
  const currentTemplatePage = viewingSource ? sourceTemplatePage : digitalTemplatePage

  // Scroll to top when page changes
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [currentTemplatePage.id])

  const printMode = isPrintMode()

  // Digital Template Features for Render Conditions
  const { enabledFeatures } = getDigitalTemplateEnabledFeatures(digitalTemplate)

  return {
    callbacks: {
      changeItemImage: viewingSource
        ? null
        : (item, e) => changeItemImage(
          digitalTemplate,
          selectedEntityRef,
          item,
          e,
          dispatch,
          showManageDigitalTemplateAssetsModal,
        ),
      editSectionSort: () => editSectionSort(digitalTemplatePage, showEditDigitalTemplateSectionSortModal, dispatch),
      selectCanvasItem: viewingSource
        ? null
        : (item, e) => selectCanvasItem(digitalTemplate, item, e, dispatch, showCustomizeDigitalTemplatePageItemModal),
      selectTemplateSection: section => copySectionToGroup(() => selectSourceTemplateSection(digitalTemplatePage, insertSectionPayload, section, setState, dispatch)),
      setState,
      togglePreviewMode: () => setState({ previewing: !previewing }),
      updateCustomData: (entity, changes) => updateEntityParam(entity.entityType, entity, 'data', changes, dispatch),
      updateTemplateViewMode: viewMode => setState({ templateViewMode: viewMode }),
      viewSourceTemplateSections: sectionPayload => viewSourceTemplateSections(sectionPayload, setState, showSelectDigitalTemplateSourceTemplateModal),
      ...callbacks,
    },
    currentTemplatePage,
    currentUser: {},
    customData: {},
    customizingTemplate: !previewing && !viewingSource,
    dataStoreItems: [],
    digitalPage,
    digitalTemplate,
    digitalTemplatePage,
    editing: false,
    enabledFeatures,
    explodeSections: viewingSource,
    isMobile,
    isMobileDevice,
    loading: loadingDigitalTemplates,
    previewing,
    shortcodeData: {
      ...shortcodeData,
      customizingTemplate: true,
    },
    shortcodeListArray,
    sourceTemplate,
    sourceTemplateId,
    sourceTemplatePage,
    targetDevice: getTargetDevice(printMode),
    templateViewMode,
    viewingSource,
  }
}

export default useDigitalTemplateCustomizer
