import React from 'react'
import PropTypes from 'prop-types'

import { digObject } from '@campaignhub/javascript-utils'

const PagePreviewImage = (props) => {
  const { canvasPayload: { size: { height, width } }, image } = props

  const previewImageUrl = digObject(image, 'sizes.thumb_2000x1500')

  return (
    <g>
      <image
        height={height}
        opacity="1"
        preserveAspectRatio="none"
        style={{ opacity: 1, pointerEvents: 'none' }}
        width={width}
        x="0"
        xlinkHref={previewImageUrl}
        xmlnsXlink="http://www.w3.org/1999/xlink"
        y="0"
      />
    </g>
  )
}

PagePreviewImage.propTypes = {
  canvasPayload: PropTypes.shape({
    size: PropTypes.shape({
      height: PropTypes.number,
      width: PropTypes.number,
    }).isRequired,
  }),
  image: PropTypes.object.isRequired,
}

export default PagePreviewImage
