import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faDownload, faPaperPlane, faPencil, faWrench,
} from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, FormField, SidebarModal,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import useDocumentTemplate from '@hooks/useDocumentTemplate'
import useLocalization from '@hooks/useLocalization'
import { useRelations } from '@hooks/useAgreement'

import BuildAgreementContext from '../../contexts/buildAgreementContext'

import AgreementDetails from './components/AgreementDetails'
import DocumentRecipients from './components/DocumentRecipients'
import Feature from './components/Feature'

import localizedStrings from './localizedStrings'

const confirmSend = (sendAgreement, sendFn) => {
  swal
    .fire({
      title: 'Send Agreement?',
      text: 'Are you sure?',
      icon: 'success',
      showCancelButton: true,
      confirmButtonText: 'Yes, send for signing',
      confirmButtonColor: '#43ac6a',
    })
    .then(({ value }) => {
      if (value){
        sendAgreement(sendFn)
      }
    })
}

const ManageAgreementBuilderModal = (props) => {
  const { callbacks, modalKey, showModal } = props
  const { deleteExternalAgreement, sendExternalAgreement } = callbacks || {}

  const buildAgreementContext = useContext(BuildAgreementContext)
  const {
    callbacks: { createExternalAgreement },
  } = buildAgreementContext

  const pageContext = useContext(PageContext)
  const {
    callbacks: { editAgreement, manageAgreementFeature, manageAgreementRecipients },
    agreementPayload,
    agreementPayload: {
      callbacks: { deleteExternalAgreement: deleteFn, sendExternalAgreement: sendFn },
      agreement,
      agreement: { urls },
      electronicSigningExternalId,
      isVoided,
      isPending,
    },
    hasPages,
    isMobile,
  } = pageContext

  const { documentTemplate } = useRelations(agreement)

  const { external_pdf } = urls || ''

  const { enabledFeatures } = useDocumentTemplate(documentTemplate)

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal
      callbacks={callbacks}
      disableAnimation
      disableOverlay
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      {isMobile && (
        <SidebarModal.Header
          callbacks={callbacks}
          title={strings.modalHeader?.title || 'Manage'}
          titleSecondLine={strings.modalHeader?.titleSecondLine || 'Agreement'}
        />
      )}

      <SidebarModal.Content boxProps={{ padding: 0 }} hasFooter={false}>
        <Box flexDirection="column">
          <Box borderBottom="1px solid" borderColor="lineColor" padding="large">
            {!electronicSigningExternalId && (
              <Button
                buttonStyle="primaryEdit"
                disabled={!hasPages || isVoided}
                flexShrink="1"
                icon={<FontAwesomeIcon icon={faWrench} />}
                marginLeft="small"
                onClick={() => createExternalAgreement(agreement)}
                size="medium"
              >
                {strings.buttons?.build || 'Build Agreement'}
              </Button>
            )}

            {electronicSigningExternalId && (
              <Button
                buttonStyle="primaryEdit"
                disabled={!hasPages || isVoided}
                flexShrink="1"
                icon={<FontAwesomeIcon icon={faPaperPlane} />}
                marginLeft="small"
                onClick={() => confirmSend(sendExternalAgreement, sendFn)}
                size="medium"
              >
                {strings.buttons?.send || 'Send Agreement'}
              </Button>
            )}
          </Box>

          <Box borderBottom="1px solid" borderColor="lineColor">
            <AgreementDetails callbacks={{ editAgreement }} agreementPayload={agreementPayload} />
          </Box>
        </Box>

        <Box borderBottom="1px solid" borderColor="lineColor" flexDirection="column" padding="large">
          <FormField direction="column" label="* Recipient/Signer Roles">
            <DocumentRecipients callbacks={{ manageAgreementRecipients }} agreementPayload={agreementPayload} />
          </FormField>
        </Box>

        {!electronicSigningExternalId
          && enabledFeatures.map(feature => (
            <Feature
              callbacks={{ manageAgreementFeature }}
              agreementId={agreement.id}
              feature={feature}
              key={feature.key}
            />
          ))}

        {!!electronicSigningExternalId && (
          <Box flexDirection="column" padding="large">
            <FormField direction="column" label={strings.formFieldLabels?.moreOptions || 'More Options'}>
              <Button
                as="a"
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faDownload} />}
                marginBottom="medium"
                href={external_pdf}
                size="medium"
                width="100%"
              >
                {strings.buttons?.download || 'Download Agreement'}
              </Button>

              {isPending && (
                <Button
                  buttonStyle="secondaryUtility"
                  icon={<FontAwesomeIcon icon={faPencil} />}
                  onClick={() => deleteExternalAgreement(deleteFn)}
                  size="medium"
                  width="100%"
                >
                  {strings.buttons?.edit || 'Edit Agreement'}
                </Button>
              )}

            </FormField>
          </Box>
        )}
      </SidebarModal.Content>
    </SidebarModal>
  )
}

ManageAgreementBuilderModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

ManageAgreementBuilderModal.defaultProps = {
  modalKey: 'ManageAgreementBuilderModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <ManageAgreementBuilderModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
