import { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

import { useModals, useSetState, useShowModal } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import handleCallbackAction from '@functions/handleCallbackAction'

import AgreementExternalEventsModal from '@modals/AgreementExternalEventsModal'
import AgreementProgressModal from '@modals/AgreementProgressModal'
import CreateExternalFormModal from '@modals/CreateExternalFormModal'
import CreateOrEditAddressModal from '@modals/CreateOrEditAddressModal'
import CreateOrEditContactModal from '@modals/CreateOrEditContactModal'
import EditUserModal from '@modals/EditUserModal'
import ManageProjectContactsModal from '@modals/ManageProjectContactsModal'
import ManageProjectUsersModal from '@modals/ManageProjectUsersModal'
import RTAValuePropositionModal from '@modals/RTAValuePropositionModal'
import SelectDocumentTemplateModal from '@modals/SelectDocumentTemplateModal'

import defaultRequestOptions from '@sections/Client/packs/Project/defaultRequestOptions'

import useIntegrations from '@hooks/useIntegrations'
import useLocalization from '@hooks/useLocalization'
import useMixpanel from '@hooks/useMixpanel'
import useOrganizationSelector from '@hooks/useOrganizationSelector'

import { useLocation } from 'react-router-dom'

import PageContent from './components/PageContent'

import localizedStrings from './localizedStrings'

const selectDocumentTemplateId = (templateId, selectFn) => {
  selectFn(templateId).then((result) => {
    const { errors, success, redirectUrl } = result
    if (!success){
      toast.warning(errors[0])
      return
    }

    if (redirectUrl){
      window.location = redirectUrl
    }
  })
}

const updateProject = (params, customFields, updateKey, updateFn, setState, strings) => {
  updateFn(params, customFields, {
    ...defaultRequestOptions.project,
  }).then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    toast(`${updateKey} ${strings.toast?.project?.updated}` || 'Updated Successfully.')
    setState({
      showManageProjectContactsModal: false,
      showManageProjectUsersModal: false,
    })
  })
}

const generateExternalAgreementSigningUrl = (recipientId, generateUrlFn) => {
  generateUrlFn(recipientId, 'AgreementProgressModal').then((result) => {
    const { errors, success, data } = result
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    if (data?.url){
      window.location = data.url
    }
  })
}

const voidExternalAgreement = (voidReason, voidFn, setState, strings) => {
  voidFn(voidReason).then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    toast(strings.toast?.agreement?.voided || 'Agreement Voided.')
    setState({ showAgreementProgressModal: false })
  })
}

const editExternalForm = (generateUrlFn) => {
  generateUrlFn().then((result) => {
    const { errors, success, data } = result
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    if (data?.url){
      window.open(data.url, '_blank')
    }
  })
}

const closeAgreementProgressModal = (setState) => {
  setState({ showAgreementProgressModal: false })

  const url = window.location.href.split('?')[0]
  window.location.href = url
}

const callbacks = (component, setState, strings) => {
  const componentCallbacks = {
    AgreementExternalEventsModal: {
      closeModal: () => setState({ showAgreementExternalEventsModal: false }),
    },
    AgreementProgressModal: {
      closeModal: () => closeAgreementProgressModal(setState),
      voidExternalAgreement: (voidReason, voidFn) => voidExternalAgreement(voidReason, voidFn, setState, strings),
      generateExternalAgreementSigningUrl: (recipientId, generateUrlFn) => generateExternalAgreementSigningUrl(recipientId, generateUrlFn),
    },
    CreateOrEditAddressModal: {
      closeModal: () => setState({ showCreateOrEditAddressModal: false }),
      createAddress: payload => handleCallbackAction(payload),
      updateAddress: payload => handleCallbackAction(payload),
    },
    CreateOrEditContactModal: {
      closeModal: () => setState({ showCreateOrEditContactModal: false }),
      createContact: payload => handleCallbackAction({ ...payload, requestOptions: defaultRequestOptions.contact }),
      deleteContact: payload => handleCallbackAction({ ...payload, requestOptions: defaultRequestOptions.contact }),
      updateContact: payload => handleCallbackAction({ ...payload, requestOptions: defaultRequestOptions.contact }),
    },
    CreateExternalFormModal: {
      closeModal: () => setState({ showCreateExternalFormModal: false }),
      createExternalForm: payload => handleCallbackAction(payload),
    },
    EditUserModal: {
      closeModal: () => setState({ showEditUserModal: false }),
      updateUser: payload => handleCallbackAction({ ...payload, requestOptions: defaultRequestOptions.user }),
    },
    ManageProjectContactsModal: {
      closeModal: () => setState({ showManageProjectContactsModal: false }),
      updateProject: (params, updateKey, updateFn) => updateProject(params, [], updateKey, updateFn, setState, strings),
    },
    ManageProjectUsersModal: {
      closeModal: () => setState({ showManageProjectUsersModal: false }),
      updateProject: (params, updateKey, updateFn) => updateProject(params, [], updateKey, updateFn, setState, strings),
    },
    RTAValuePropositionModal: {
      closeModal: () => setState({ showRTAValuePropositionModal: false }),
    },
    SelectDocumentTemplateModal: {
      closeModal: () => setState({ showSelectDocumentTemplateModal: false }),
      selectDocumentTemplateId: (templateId, selectFn) => selectDocumentTemplateId(templateId, selectFn, strings),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  showAgreementExternalEventsModal: false,
  showAgreementProgressModal: false,
  showCreateExternalFormModal: false,
  showCreateOrEditAddressModal: false,
  showCreateOrEditContactModal: false,
  showEditUserModal: false,
  showManageProjectContactsModal: false,
  showManageProjectUsersModal: false,
  showRTAValuePropositionModal: false,
  showSelectDocumentTemplateModal: false,
}

const Agreements = (props) => {
  const { projectId, project } = props
  useLocation()

  const [state, setState] = useSetState(defaultState)
  const {
    showAgreementExternalEventsModal,
    showAgreementProgressModal,
    showCreateExternalFormModal,
    showCreateOrEditAddressModal,
    showCreateOrEditContactModal,
    showEditUserModal,
    showManageProjectContactsModal,
    showManageProjectUsersModal,
    showRTAValuePropositionModal,
    showSelectDocumentTemplateModal,
  } = state

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const { strings } = useLocalization(localizedStrings)

  const pageContext = useMemo(
    () => ({
      callbacks: {
        editExternalForm,
        showAgreementExternalEventsModal: (payload) => {
          setModalData('AgreementExternalEventsModal', payload)
          setState({ showAgreementExternalEventsModal: true })
        },
        showAgreementProgressModal: (payload) => {
          setModalData('AgreementProgressModal', payload)
          setState({ showAgreementProgressModal: true })
        },
        showCreateExternalFormModal: () => {
          setState({ showCreateExternalFormModal: true })
        },
        showCreateOrEditAddressModal: (payload) => {
          setModalData('CreateOrEditAddressModal', payload)
          setState({ showCreateOrEditAddressModal: true })
        },
        showCreateOrEditContactModal: (payload) => {
          setModalData('CreateOrEditContactModal', payload)
          setState({ showCreateOrEditContactModal: true })
        },
        showEditUserModal: (payload) => {
          setModalData('EditUserModal', payload)
          setState({ showEditUserModal: true })
        },
        showManageProjectContactsModal: (payload) => {
          setModalData('ManageProjectContactsModal', payload)
          setState({ showManageProjectContactsModal: true })
        },
        showManageProjectUsersModal: (payload) => {
          setModalData('ManageProjectUsersModal', payload)
          setState({ showManageProjectUsersModal: true })
        },
        showRTAValuePropositionModal: (payload) => {
          setModalData('RTAValuePropositionModal', payload)
          setState({ showRTAValuePropositionModal: true })
        },
        showSelectDocumentTemplateModal: (payload) => {
          setModalData('SelectDocumentTemplateModal', payload)
          setState({ showSelectDocumentTemplateModal: true })
        },
        voidExternalAgreement: (voidReason, voidFn) => voidExternalAgreement(voidReason, voidFn, setState, strings),
      },
    }),
    [modalContext],
  )

  const { selectedOrganization } = useOrganizationSelector()

  const integrationsPayload = useIntegrations({
    featureKeys: ['create_forms', 'import_document_templates'],
    filters: {
      owner_id: selectedOrganization.organization_id,
      owner_type: 'Organization',
    },
  })

  const { hasIntegrations } = integrationsPayload

  const modalKey = hasIntegrations ? 'CreateExternalFormModal' : 'SelectDocumentTemplateModal'

  // Automatically show modal
  useShowModal({
    modalKey: 'AgreementProgressModal',
    options: {
      callbacks: pageContext.callbacks,
    },
  })

  useShowModal({
    modalKey,
    options: {
      callbacks: pageContext.callbacks,
    },
  })

  // Tracks the page load event
  const { callbacks: { dispatchMixpanelEvent } } = useMixpanel()

  useEffect(() => {
    dispatchMixpanelEvent('Project Agreements Load')
  }, [])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent project={project} />

        <AgreementExternalEventsModal
          callbacks={callbacks('AgreementExternalEventsModal', setState, strings)}
          showModal={showAgreementExternalEventsModal}
        />

        <AgreementProgressModal
          callbacks={callbacks('AgreementProgressModal', setState, strings)}
          showModal={showAgreementProgressModal}
        />

        <CreateOrEditAddressModal
          callbacks={callbacks('CreateOrEditAddressModal', setState, strings)}
          hiddenFields={['title']}
          showModal={showCreateOrEditAddressModal}
        />

        <CreateOrEditContactModal
          callbacks={callbacks('CreateOrEditContactModal', setState, strings)}
          showModal={showCreateOrEditContactModal}
        />

        <CreateExternalFormModal
          agreement={{
            owner_id: project.organization_id,
            owner_type: 'Organization',
            subject_id: project.id,
            subject_type: 'Project',
          }}
          callbacks={callbacks('CreateExternalFormModal', setState, strings)}
          showModal={showCreateExternalFormModal}
        />

        <EditUserModal callbacks={callbacks('EditUserModal', setState, strings)} showModal={showEditUserModal} />

        <ManageProjectContactsModal
          callbacks={callbacks('ManageProjectContactsModal', setState, strings)}
          project={{ id: projectId }}
          showModal={showManageProjectContactsModal}
        />

        <ManageProjectUsersModal
          callbacks={callbacks('ManageProjectUsersModal', setState, strings)}
          project={{ id: projectId }}
          showModal={showManageProjectUsersModal}
        />

        <SelectDocumentTemplateModal
          callbacks={callbacks('SelectDocumentTemplateModal', setState, strings)}
          disableOverlay
          showModal={showSelectDocumentTemplateModal}
        />

        <RTAValuePropositionModal
          callbacks={callbacks('RTAValuePropositionModal', setState, strings)}
          disableOverlay
          showModal={showRTAValuePropositionModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

Agreements.propTypes = {
  projectId: PropTypes.number,
  project: PropTypes.object.isRequired,
}

export default Agreements
