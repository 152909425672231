import { useEffect, useState } from 'react'
import Uppy from '@uppy/core'
import XHRUpload from '@uppy/xhr-upload'

import { useThunkDispatch } from '@campaignhub/react-hooks'

import { getAccessToken } from '@functions/accessToken'
import { baseURL } from '@functions/api'

import * as imageActions from '@redux/modules/image'

const initializeUppy = (options, dispatch, onUpload) => {
  const {
    allowedFileTypes,
    data,
    endpoint,
    fieldName,
    image_type,
    owner_id,
    owner_type,
    requestOptions = {},
    subject_id,
    subject_type,
  } = options

  const stringifiedData = JSON.stringify(data || {})

  const uppy = new Uppy({
    debug: false,
    autoProceed: true,
    method: 'post',
    restrictions: {
      allowedFileTypes: allowedFileTypes ? allowedFileTypes.split(',') : ['image/*'],
    },
  })
    .use(XHRUpload, {
      endpoint: `${baseURL}/${endpoint}`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      formData: true,
      fieldName: fieldName || 'image[file]',
      getResponseError(responseText){
        return new Error(JSON.parse(responseText).message)
      },
    })
    .on('file-added', (file) => {
      uppy.setFileMeta(file.id, {
        'image[data]': stringifiedData,
        'image[image_type]': image_type || 'photo',
        'image[owner_id]': owner_id,
        'image[owner_type]': owner_type,
        'image[subject_id]': subject_id,
        'image[subject_type]': subject_type,
        ...requestOptions,
      })
    })
    .on('upload-success', (_, response) => {
      const { body: { entity: image } } = response
      const { addImage } = imageActions

      dispatch(addImage(image))

      if (onUpload){
        onUpload()
      }
    })

  return uppy
}

const checkRequiredOptions = (options = {}) => {
  const requiredOptions = ['endpoint', 'subject_id', 'subject_type']
  const optionKeys = Object.keys(options)

  return requiredOptions.every(key => optionKeys.includes(key))
}

function useUppyImageUpload(options = {}, deps = [], onUpload){
  const [uppy, setUppy] = useState(undefined)

  const dispatch = useThunkDispatch()

  useEffect(() => {
    if (checkRequiredOptions(options)){
      return setUppy(initializeUppy(options, dispatch, onUpload))
    }

    return () => {
      if (uppy){
        uppy.close()
        setUppy(undefined)
      }
    }
  }, [...deps])

  return uppy
}

export default useUppyImageUpload
