import React from 'react'
import PropTypes from 'prop-types'

import {
  Cell, Legend, Pie, PieChart, ResponsiveContainer,
} from 'recharts'

import { formatDataForPieChart } from '@hooks/useChartData'

import CustomLegend from './CustomLegend'

const HalfPieChart = (props) => {
  const {
    dataPayload: { dataArray, theme },
    height,
    width,
  } = props

  const formattedData = formatDataForPieChart(dataArray)

  return (
    <ResponsiveContainer width={width} height={height}>
      <PieChart>
        <Pie
          data={formattedData}
          startAngle={180}
          endAngle={0}
          innerRadius={height - (height / 2.5)}
          cy={150}
          outerRadius={height - (height / 4)}
          fill="#8884d8"
          paddingAngle={5}
          dataKey="value"
        >
          {formattedData.map((data) => {
            const { colors } = theme[data.name] || {}

            return (
              <Cell key={data.name} fill={colors.secondary} stroke={colors.primary} strokeWidth={2} />
            )
          })}
        </Pie>

        <Legend
          content={<CustomLegend />}
          verticalAlign="bottom"
        />
      </PieChart>
    </ResponsiveContainer>
  )
}

HalfPieChart.propTypes = {
  dataPayload: PropTypes.shape({
    dataArray: PropTypes.array.isRequired,
    dataKeys: PropTypes.array.isRequired,
    theme: PropTypes.object.isRequired,
  }).isRequired,
  height: PropTypes.number,
  width: PropTypes.string,
}

HalfPieChart.defaultProps = {
  height: 200,
  width: '100%',
}

export default HalfPieChart
