import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@campaignhub/suit-theme'

const FormColumn = (props) => {
  const { boxProps, children, firstColumn } = props

  const leftMargin = firstColumn ? 0 : [0, 'medium']
  const marginBottom = firstColumn ? ['large', 0] : 0

  return (
    <Box
      marginLeft={leftMargin}
      marginBottom={marginBottom}
      width={['100%', '50%']}
      {...boxProps}
    >
      {children}
    </Box>
  )
}

FormColumn.propTypes = {
  boxProps: PropTypes.object,
  children: PropTypes.node,
  firstColumn: PropTypes.bool,
}

export default FormColumn
