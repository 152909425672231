import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { digObject } from '@campaignhub/javascript-utils'
import { Box, ModalContext, SidebarModal } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'
import useDigitalEditorSelectedEntity from '@hooks/useDigitalEditorSelectedEntity'
import useDigitalTemplate from '@hooks/useDigitalTemplate'

import Attachment from './components/Attachment'
import Clipboard from './components/Clipboard'
import Data from './components/Data'
import Gallery from './components/Gallery'
import ItemInfo from './components/ItemInfo'
import Options from './components/Options'
import RenderConditions from './components/RenderConditions'
import Style from './components/Style'
import Tools from './components/Tools'

const EditDigitalTemplatePageItemModal = (props) => {
  const { callbacks, modalKey, showModal } = props

  const { deleteItem } = callbacks

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const pageContext = useContext(PageContext)
  const {
    callbacks: { createDigitalTemplateComponent, setTargetDevice },
    targetDevice,
  } = pageContext

  const {
    callbacks: modalCallbacks,
    dataContext,
    digitalTemplate,
    selectedCanvasItem,
  } = digObject(modalData, modalKey, {})

  const { deleteEntity: deleteFn, updateEntity: updateFn, updateEntityParam: updateParamFn } = modalCallbacks || {}

  const { id, type } = selectedCanvasItem || {}

  const selectedEntityPayload = useDigitalEditorSelectedEntity('selectedCanvasItem')
  const {
    editorState: { copiedCanvasItem },
    selectedEntity,
  } = selectedEntityPayload

  const digitalTemplatePayload = useDigitalTemplate(digitalTemplate)
  const {
    callbacks: { setDigitalTemplateFeature },
  } = digitalTemplatePayload

  return (
    <SidebarModal
      callbacks={callbacks}
      disableAnimation
      disableOverlay
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header callbacks={callbacks} title="Edit Item" titleSecondLine="Details" />

      <SidebarModal.Content boxProps={{ padding: 0 }} hasFooter={false}>
        <Box flexDirection="column">
          <ItemInfo id={id} type={type} />

          {!!copiedCanvasItem.id && <Clipboard copiedCanvasItem={copiedCanvasItem} />}

          <Attachment digitalTemplatePayload={digitalTemplatePayload} selectedEntity={selectedEntity} />

          <RenderConditions
            callbacks={{ updateParam: (key, value) => updateParamFn(selectedEntity, key, value) }}
            entityType={type}
            selectedEntity={selectedEntity}
          />

          <Data
            callbacks={{ updateParam: (key, value) => updateParamFn(selectedEntity, key, value) }}
            dataContext={dataContext}
            entityType={type}
            selectedEntity={selectedEntity}
          />

          <Options
            callbacks={{
              updateEntity: updatedEntity => updateFn(updatedEntity),
              updateParam: (key, value) => updateParamFn(selectedEntity, key, value),
              setDigitalTemplateFeature,
            }}
            digitalTemplatePayload={digitalTemplatePayload}
            entityType={type}
            selectedEntity={selectedEntity}
          />

          <Gallery digitalTemplatePayload={digitalTemplatePayload} entityType={type} selectedEntity={selectedEntity} />

          <Style
            callbacks={{
              setTargetDevice,
              updateEntity: updatedEntity => updateFn(updatedEntity),
              updateParam: (key, value) => updateParamFn(selectedEntity, key, value),
            }}
            digitalTemplatePayload={digitalTemplatePayload}
            entityType={type}
            selectedEntity={selectedEntity}
            targetDevice={targetDevice}
          />

          <Tools
            callbacks={{
              deleteEntity: () => deleteItem(deleteFn, selectedEntity),
              createDigitalTemplateComponent,
            }}
            selectedEntity={selectedEntity}
          />
        </Box>
      </SidebarModal.Content>
    </SidebarModal>
  )
}

EditDigitalTemplatePageItemModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

EditDigitalTemplatePageItemModal.defaultProps = {
  modalKey: 'EditDigitalTemplatePageItemModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <EditDigitalTemplatePageItemModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
