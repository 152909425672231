export default {
  default: {
    title: 'New',
    titleSecondLine: 'Quote',
    button: {
      emptyQuote: 'Empty Quote',
      emptyQuoteSubTitle: 'Start with a blank canvas',
      templateSubTitle: 'Choose from saved templates',
      templateTitle: 'Use a Template',
    },
    toast: {
      quoteCreated: 'Quote Created Successfully',
    },
  },
}
