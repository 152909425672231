import React from 'react'
import PropTypes from 'prop-types'

import { Box, DashboardModule, FormField } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import countryCallingCodes from '@functions/countryCallingCodes'

import localizedStrings from './localizedStrings'

const OrganizationDetails = (props) => {
  const { organizationForm } = props
  const {
    entityState: {
      business_name,
      business_number,
      country_calling_code,
      email,
      license_number,
      phone,
      short_name,
      shortcode_title,
      title,
      url,
    },
    errors,
    handlers,
  } = organizationForm

  const { strings } = useLocalization(localizedStrings)

  return (
    <DashboardModule boxProps={{ marginBottom: 'large' }} title={strings.title}>
      <Box flexDirection="column" padding="large">
        <FormField
          boxProps={{ marginBottom: 'large' }}
          label={strings.formFieldLabels?.title}
          errorMessage={errors.title}
        >
          <input type="text" name="title" value={title} {...handlers} />
        </FormField>

        <Box flexDirection={['column', 'row']} marginBottom="large">
          <FormField
            label={strings.formFieldLabels?.shortcodeTitle || 'Shortcode Title'}
            errorMessage={errors.email}
            boxProps={{ flex: 1, marginBottom: ['large', 0], marginRight: [0, 'medium'] }}
          >
            <input type="text" value={shortcode_title} name="shortcode_title" {...handlers} />
          </FormField>

          <FormField label={strings.formFieldLabels?.shortName || 'Short Name'} boxProps={{ flex: 1 }}>
            <input type="text" value={short_name} name="short_name" {...handlers} />
          </FormField>
        </Box>

        <FormField boxProps={{ marginBottom: 'large' }} label={strings.formFieldLabels?.businessName}>
          <input type="text" name="business_name" value={business_name} {...handlers} />
        </FormField>

        <Box flexDirection={['column', 'row']} marginBottom="large">
          <FormField
            label={strings.formFieldLabels?.businessNumber || 'Business Number'}
            boxProps={{ flex: 1, marginBottom: ['large', 0], marginRight: [0, 'medium'] }}
          >
            <input type="text" value={business_number} name="business_number" {...handlers} />
          </FormField>

          <FormField label={strings.formFieldLabels?.licenseNumber || 'licenseNumber'} boxProps={{ flex: 1 }}>
            <input type="text" value={license_number} name="license_number" {...handlers} />
          </FormField>
        </Box>

        <Box flexDirection={['column', 'row']} marginBottom="large">
          <FormField
            label={strings.formFieldLabels?.email || '* Email'}
            errorMessage={errors.email}
            boxProps={{ flex: 1, marginBottom: ['large', 0], marginRight: [0, 'medium'] }}
          >
            <input type="text" value={email} name="email" {...handlers} />
          </FormField>

          <FormField label={strings.formFieldLabels?.url || 'Url'} boxProps={{ flex: 1 }}>
            <input type="text" value={url} name="url" {...handlers} />
          </FormField>
        </Box>

        <Box flexDirection={['column', 'row']}>
          <Box width={['100%', 'calc(50% - 4px)']}>
            <FormField label={strings.formFieldLabels?.phone || 'Phone'} boxProps={{ flex: 1 }}>
              <Box style={{ width: '100%', marginRight: 'small', flexDirection: 'row' }}>
                <select
                  name="country_calling_code"
                  style={{ marginRight: 5 }}
                  value={country_calling_code}
                  {...handlers}
                >
                  <option value="">{strings.select?.option || 'Select...'}</option>

                  {countryCallingCodes.map(({ key, value, title }) => (
                    <option key={key} value={value}>
                      {title}
                    </option>
                  ))}
                </select>

                <FormField style={{ width: '70%' }}>
                  <input type="text" value={phone} name="phone" {...handlers} />
                </FormField>
              </Box>
            </FormField>
          </Box>
        </Box>
      </Box>
    </DashboardModule>
  )
}

OrganizationDetails.propTypes = {
  organizationForm: PropTypes.object.isRequired,
}

export default OrganizationDetails
