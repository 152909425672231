import { useMemo } from 'react'

import {
  digObject, matchFilterArrayIncludes, matchFilterNumber, matchFilterString, sortArrayBy,
} from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useCurrentUser from '@hooks/useCurrentUser'
import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { EventCalendarModel } from '@models/types'
import type { ModuleState } from '@redux/modules/types'
import sortEntitiesByDate from '@functions/sortEntitiesByDate'

import defaultRequestOptions from '@sections/Client/defaultRequestOptions'

const watchEntityKeys = ['eventCalendars']

type EventCalendarFilters = {
  isSavedTemplate?: boolean,
  ownerId?: number,
  ownerType?: string,
  string?: string,
  subjectId?: number,
  subjectType?: string,
}

type UseEventCalendarsOptions = {
  filters?: EventCalendarFilters,
  limit?: number,
  performHttpRequests?: boolean,
}

function useEventCalendars(options: UseEventCalendarsOptions = {}) {
  const { filters = {} } = options
  const {
    isSavedTemplate: filterIsSavedTemplate,
    ownerId: filterOwnerId,
    ownerType: filterOwnerType,
    string: filterString,
    subjectId: filterSubjectId,
    subjectType: filterSubjectType,
  } = filters

  const {
    updatedEntities: { eventCalendars: eventCalendarsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { eventCalendars } = useSelector(reduxState => reduxState.entities)

  const filteredEventCalendars: EventCalendarModel[] = useMemo(() => {
    const filtered = Object.values(eventCalendars).filter((eventCalendar: EventCalendarModel) => {
      const {
        owner_id, owner_type, saved_template, subject_id, subject_type, title,
      } = eventCalendar

      const ownerMatch = matchFilterNumber(owner_id, filterOwnerId) && matchFilterString(owner_type, filterOwnerType)
      const subjectMatch = matchFilterNumber(subject_id, filterSubjectId)
        && matchFilterString(subject_type, filterSubjectType)
      const titleMatch = matchFilterString(title, filterString)
      const templateMatch = !!filterIsSavedTemplate === saved_template

      return ownerMatch && subjectMatch && templateMatch && titleMatch
    })

    if (options.limit){
      return sortEntitiesByDate(filtered, 'asc', 'updated').slice(0, options.limit)
    }

    return sortArrayBy(filtered, 'asc', 'title')
  }, [eventCalendarsUpdatedAt, JSON.stringify(filters)])

  const { currentUser } = useCurrentUser()

  const currentUserEventCalendarTemplates = useMemo(() => {
    const filtered = filteredEventCalendars.filter((eventCalendar) => {
      const userIds = digObject(eventCalendar, 'data.user_ids', [])

      return matchFilterArrayIncludes(userIds, Number(currentUser?.id))
    })

    return filtered
  }, [currentUser.id, eventCalendarsUpdatedAt, JSON.stringify(options)])

  const nonCurrentUserEventCalendarTemplates = useMemo(() => {
    const filtered = filteredEventCalendars.filter((eventCalendar) => {
      const userIds = digObject(eventCalendar, 'data.user_ids', [])

      return !userIds.length
    })

    return filtered
  }, [currentUser.id, eventCalendarsUpdatedAt, JSON.stringify(options)])

  const filteredEventCalendarsCount = filteredEventCalendars.length
  const hasFilteredEventCalendars = !!filteredEventCalendarsCount
  const hasCurrentUserEventCalendarTemplates = !!currentUserEventCalendarTemplates.length
  const hasNonCurrentUserEventCalendarTemplates = !!nonCurrentUserEventCalendarTemplates.length

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredEventCalendarsCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const entityKey = `${filterSubjectType}${filterSubjectId}`

  const { loading: loadingEventCalendars, loaded } = useReduxAction(
    'eventCalendars',
    'loadEventCalendars',
    {
      ...defaultRequestOptions.eventCalendar,
      ...filtersWithOffset,
      entityKey,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  const { loading: loadingTemplates } = useSelector(reduxState => reduxState.eventCalendarTemplates)

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    currentUserEventCalendarTemplates,
    filteredEventCalendars,
    hasCurrentUserEventCalendarTemplates,
    hasEventCalendars: hasFilteredEventCalendars,
    hasNonCurrentUserEventCalendarTemplates,
    loaded,
    loading: loadingEventCalendars,
    loadingTemplates,
    nonCurrentUserEventCalendarTemplates,
  }
}

export default useEventCalendars
