import { useContext } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/pro-solid-svg-icons'

import replaceTextShortCodes from '@functions/replaceTextShortCodes'

import PageContext from '@contexts/pageContext'

import VideoPlayer from '@components/digitalRenderer/components/VideoPlayer'

import styles from './styles.module.scss'

const editAction = (e, params) => {
  const {
    callbacks: { editDigitalPageItemCustomData, selectCanvasItem },
    customizingTemplate,
    entityType,
    id,
    itemType,
  } = params

  e.preventDefault()
  e.stopPropagation()

  if (customizingTemplate){
    selectCanvasItem({ type: entityType, id }, e)
    return
  }

  editDigitalPageItemCustomData({ entityType, id, itemType })
}

const Video = (props) => {
  const {
    data: { value },
    entityType,
    id,
    item_type,
    options: { videoProvider },
  } = props

  const pageContext = useContext(PageContext)

  const {
    callbacks, customData, customizingTemplate, editing, shortcodeData,
  } = pageContext || {}

  const { editDigitalPageItemCustomData, selectCanvasItem } = callbacks || {}

  const contextData = { ...shortcodeData }

  // Extracting The Value of Shortcode
  const shortcodeValue = replaceTextShortCodes(value, contextData)

  const itemData = customData[id] || {}

  return (
    <div className={classnames(styles.root, customizingTemplate || editing ? styles.editable : null)}>
      <VideoPlayer
        videoData={{
          videoId: itemData.videoId || shortcodeValue || value,
          videoProvider: itemData.videoProvider || videoProvider,
        }}
      />

      {(customizingTemplate || editing) && (
        <div className={styles.editActions}>
          <div
            className={styles.icon}
            onClick={e => editAction(e, {
              callbacks: {
                editDigitalPageItemCustomData,
                selectCanvasItem,
              },
              customizingTemplate,
              entityType,
              id,
              itemType: item_type,
            })}
            style={{ marginBottom: 2 }}
          >
            <FontAwesomeIcon icon={faPencil} />
          </div>
        </div>
      )}
    </div>
  )
}

Video.propTypes = {
  data: PropTypes.object,
  entityType: PropTypes.string,
  id: PropTypes.string.isRequired,
  item_type: PropTypes.string,
  options: PropTypes.object,
}

Video.defaultProps = {
  data: {},
  options: {},
}

export default Video
