import React from 'react'
import PropTypes from 'prop-types'

import { useSetState } from '@campaignhub/react-hooks'

import useLocalization from '@hooks/useLocalization'
import useQuote from '@hooks/useQuote'

import CreateFromTemplate from './components/CreateFromTemplate'
import DefaultState from './components/DefaultState'
import localizedStrings from './localizedStrings'

const localCallbacks = (component, props, setState) => {
  const {
    callbacks: { closeModal, createQuote, createQuoteFromTemplate },
  } = props

  const componentCallbacks = {
    CreateFromTemplate: {
      closeModal,
      createQuoteFromTemplate,
      selectScreen: screenKey => setState({ selectedScreen: screenKey }),
    },
    DefaultState: {
      closeModal,
      createQuote,
      selectScreen: screenKey => setState({ selectedScreen: screenKey }),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  selectedScreen: null,
}

const NewQuote = (props) => {
  const {
    project: { id, organization_id },
  } = props

  const [state, setState] = useSetState(defaultState)
  const { selectedScreen } = state

  const { strings } = useLocalization(localizedStrings)

  const initQuote = {
    owner_type: 'Organization',
    owner_id: organization_id,
    subject_type: 'Project',
    subject_id: id,
    title: strings.title || 'Project Quote',
  }

  const quotePayload = useQuote(initQuote)

  return (
    <>
      {!selectedScreen && (
        <DefaultState callbacks={localCallbacks('DefaultState', props, setState)} quotePayload={quotePayload} />
      )}

      {selectedScreen === 'CreateFromTemplate' && (
        <CreateFromTemplate
          callbacks={localCallbacks('CreateFromTemplate', props, setState)}
          quotePayload={quotePayload}
        />
      )}
    </>
  )
}

NewQuote.propTypes = {
  project: PropTypes.object.isRequired,
}

export default NewQuote
