import React from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'

import { Button, SidebarModal } from '@campaignhub/suit-theme'

const confirmDelete = (deleteFn, itemType) => {
  swal
    .fire({
      title: `Delete ${itemType}?`,
      text: 'Are you sure? ',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it.',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        deleteFn()
      }
    })
}

const Tools = (props) => {
  const {
    callbacks: { createDigitalTemplateComponent, deleteEntity: deleteFn },
    selectedEntity,
    selectedEntity: { item_type },
  } = props

  return (
    <SidebarModal.ExpandableSectionBox label="Tools">
      <Button
        buttonStyle="secondaryCreate"
        marginBottom="large"
        onClick={() => createDigitalTemplateComponent(selectedEntity)}
        size="medium"
        style={{ width: 'auto' }}
      >
        Save {item_type || 'Item'}
      </Button>

      <Button
        buttonStyle="secondaryDestroy"
        onClick={() => confirmDelete(deleteFn, item_type)}
        size="medium"
        style={{ width: 'auto' }}
      >
        Delete {item_type || 'Item'}
      </Button>
    </SidebarModal.ExpandableSectionBox>
  )
}

Tools.propTypes = {
  callbacks: PropTypes.shape({
    createDigitalTemplateComponent: PropTypes.func.isRequired,
    deleteEntity: PropTypes.func.isRequired,
  }),
  selectedEntity: PropTypes.object,
}

export default Tools
