import React from 'react'
import PropTypes from 'prop-types'

import { faChartBar } from '@fortawesome/pro-light-svg-icons'

import useLocalization from '@hooks/useLocalization'

import { Box, DashboardModule, Link } from '@campaignhub/suit-theme'

import useLeaderboard from './hooks/useLeaderboard'

import UserListItem from './UserListItem'
import DigitalTemplateListItem from './DigitalTemplateListItem'
import DigitalTemplateListItemHeader from './DigitalTemplateListItemHeader'

import LeaderboardImageUrl from './assets/analytics.svg'

import localizedStrings from './localizedStrings'

export const icon = faChartBar

export const configOptions = strings => ({
  title: {
    component: 'input',
    key: 'title',
    label: strings.labelTitle?.title || 'Title',
    optionKey: 'customization',
  },
  status: {
    component: 'select',
    key: 'status',
    label: strings.status?.label || 'Status',
    optionKey: 'queryParams',
    values: [
      { key: 'all', label: strings.statusValues?.all || 'All' },
      { key: 'accepted', label: strings.statusValues?.accepted || 'Accepted' },
      { key: 'pending', label: strings.statusValues?.pending || 'Pending' },
      { key: 'sent', label: strings.statusValues?.sent || 'Sent' },
    ],
  },
  month: {
    component: 'select',
    key: 'month',
    label: strings.month?.label || 'Month',
    optionKey: 'queryParams',
    values: [
      { key: 'current_month', label: strings.monthValues?.currentMonth || 'Current Month' },
      { key: 'previous_month', label: strings.monthValues?.previousMonth || 'Previous Month' },
    ],
  },
  leaderboard: {
    component: 'select',
    key: 'leaderboard',
    label: strings.leaderboard?.label || 'Leaderboard',
    optionKey: 'queryParams',
    values: [
      { key: 'user_leaderboard', label: strings.leaderboardValues?.userLeaderboard || 'User Leaderboard' },
      {
        key: 'digital_template_leaderboard',
        label: strings.leaderboardValues?.templateLeaderboard || 'Digital Template Leaderboard',
      },
    ],
  },
})

const Leaderboard = (props) => {
  const { customOptions, editing } = props

  const {
    parentId,
    queryParams: { month, status, leaderboard },
    customization,
  } = customOptions

  const {
    callbacks: { toggleShowLeaderboard },
    hasLeaderboardResults,
    leaderboardArray,
    loading,
    shouldShowShowAll,
  } = useLeaderboard({
    digital_template_type_key: 'proposal',
    month,
    parentId,
    leaderboard,
    status_key: status,
  })

  const { title } = customization || {}

  const Component = leaderboard === 'user_leaderboard' ? UserListItem : DigitalTemplateListItem

  const { strings } = useLocalization(localizedStrings)

  return (
    <DashboardModule
      boxProps={{ marginBottom: 0 }}
      editing={editing}
      flexDirection="column"
      headerRight={
        shouldShowShowAll && (
          <Link onClick={toggleShowLeaderboard} textProps={{ fontSize: 'small' }}>
            {strings.buttons?.showAll || 'Show All'}
          </Link>
        )
      }
      loading={loading}
      title={title || strings.title}
    >
      {!hasLeaderboardResults && !loading && (
        <DashboardModule.BlankState imageUrl={LeaderboardImageUrl}>
          <DashboardModule.BlankState.Title>
            {strings.blankState?.title || 'No Leaderboard Available'}
          </DashboardModule.BlankState.Title>

          <DashboardModule.BlankState.Paragraph>
            {strings.blankState?.paragraphOne || 'Try adjusting your filters.'}
          </DashboardModule.BlankState.Paragraph>
        </DashboardModule.BlankState>
      )}

      {hasLeaderboardResults && !loading && (
        <Box flexDirection="column">
          {leaderboard === 'digital_template_leaderboard' && (
            <DigitalTemplateListItemHeader leaderboard={leaderboard} />
          )}

          {leaderboardArray.map(leaderboardStats => (
            <Component
              key={leaderboardStats.user_id || leaderboardStats.digital_template_id}
              leaderboard={leaderboardStats}
            />
          ))}
        </Box>
      )}
    </DashboardModule>
  )
}

Leaderboard.propTypes = {
  customOptions: PropTypes.object.isRequired,
  editing: PropTypes.bool,
}

export default Leaderboard
