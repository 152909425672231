import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-light-svg-icons'

import { Box, Button, DashboardModule } from '@campaignhub/suit-theme'

import useAddress from '@hooks/useAddress'
import useLocalization from '@hooks/useLocalization'

import Address from './Address'
import localizedStrings from './localizedStrings'

const Addresses = (props) => {
  const { addresses, organization } = props

  const initAddress = { owner_id: organization.id, owner_type: 'Organization' }
  const { callbacks: { createOrEditAddress } } = useAddress(initAddress)

  const hasAddresses = !!addresses.length

  const { strings } = useLocalization(localizedStrings)

  return (
    <DashboardModule boxProps={{ marginBottom: 'large' }} title={strings.title || 'Address'}>
      <Box flexDirection="column">
        <Box flexDirection="column">
          {addresses.map(address => (
            <Address key={address.id} address={address} />
          ))}
        </Box>

        {!hasAddresses && (
          <Box padding="large" style={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <Button
              buttonStyle="ghostEdit"
              icon={<FontAwesomeIcon icon={faPlus} />}
              marginLeft="auto"
                // createOrEditAddress has to be called this way to avoid sending the event through as an arg
              onClick={() => createOrEditAddress()}
              size="medium"
              width="auto"
            >
              {strings.createButton || 'Add Address'}
            </Button>
          </Box>
        )}
      </Box>
    </DashboardModule>
  )
}

Addresses.propTypes = {
  addresses: PropTypes.array.isRequired,
  organization: PropTypes.object.isRequired,
}

Addresses.Address = Address

export default Addresses
