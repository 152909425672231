import { useContext } from 'react'
import { useSelector } from 'react-redux'
import cloneDeep from 'lodash/cloneDeep'

import { digObject } from '@campaignhub/javascript-utils'

import DigitalTemplateContext from '@contexts/digitalTemplateContext'
import PageContext from '@contexts/pageContext'

import denormalizeEntity from '@functions/denormalizeEntity'
import findComponentStyle from '@functions/findComponentStyle'
import generateID from '@functions/generateID'
import { findItemByType } from '@functions/digitalTemplate'
import { getShortCodeValue } from '@functions/replaceTextShortCodes'
import { isSectionEnabled } from '@functions/digitalPage'
import { shouldRenderPageItem } from '@functions/digitalTemplatePageItem'

import { getComponentStyleLimit } from '@hooks/useDeviceStyle'

const cloneItemChildren = (params) => {
  const { dataLoopItem, parentItem } = params

  const items = []

  const parentItems = digObject(parentItem, 'items', [])
  if (parentItems.length){
    parentItems.forEach((item) => {
      const shouldClone = digObject(item, 'options.cloneWhenAutoscaling', false)
      const cloned = digObject(item, 'options.cloned', false)

      // Add the dataArrayLoop as is
      if (dataLoopItem.id === item.id){
        items.push(dataLoopItem)
      }

      if (shouldClone && !cloned){
        const clonedItem = cloneDeep(item)
        const updatedItem = {
          ...clonedItem,
          id: generateID(),
          items: cloneItemChildren({ ...params, parentItem: clonedItem }),
          options: {
            ...clonedItem.options,
            cloneWhenAutoscaling: false,
            cloned: true,
          },
        }

        items.push(updatedItem)
      }
    })
  }

  return items
}

const cloneSectionItems = (params) => {
  const {
    dataLoopItem, section,
  } = params

  const items = cloneItemChildren({ dataLoopItem, parentItem: section })

  return items
}

const autoscaleSection = (params) => {
  const {
    digitalTemplatePageSection,
    dataLoopItem,
    loopLimit,
    offset,
  } = params

  const clonedSection = cloneDeep(digitalTemplatePageSection)
  const shouldCloneItems = digObject(clonedSection, 'options.loopAutoscalingCloneItems', false)

  const duplicatedSection = {
    ...clonedSection,
    id: `${clonedSection.id}-${offset}`,
    items: shouldCloneItems
      ? cloneSectionItems({
        dataLoopItem,
        section: clonedSection,
      })
      : [dataLoopItem.id],
    options: {
      ...clonedSection.options,
      autoscaled: true,
      autoscaleOffset: offset,
      loopAutoscaling: false,
      loopLimit,
      toggleable: false,
    },
  }

  return duplicatedSection
}

const setupAutoscaledSections = (params) => {
  const {
    additionalSectionsRequired,
    dataLoopItem,
    digitalTemplatePageSection,
    loopLimit,
  } = params || {}

  const autoscaledSections = []

  for (let offset = 1; offset <= additionalSectionsRequired; offset += 1){
    const duplicatedSection = autoscaleSection({
      dataLoopItem,
      digitalTemplatePageSection,
      loopLimit,
      offset,
    })

    autoscaledSections.push(duplicatedSection)
  }

  return autoscaledSections
}

function useDataLoopAutoscaling(normalizedSection){
  const digitalTemplateContext = useContext(DigitalTemplateContext)
  const { componentStyles, targetDevice } = digitalTemplateContext

  const pageContext = useContext(PageContext)
  const { digitalPage, shortcodeData = {} } = pageContext

  const sectionEnabled = isSectionEnabled(digitalPage, normalizedSection.id)

  const entities = useSelector(reduxState => reduxState.entities)
  const { digitalTemplatePageItems } = entities

  const digitalTemplatePageSection = denormalizeEntity('digitalTemplatePageSection', normalizedSection, entities)

  const sectionItemIds = digObject(normalizedSection, 'items', [])

  // Autoscale - Automatically add more sections if data loop content
  // contains more items that can be rendered
  const loopAutoscaling = digObject(digitalTemplatePageSection, 'options.loopAutoscaling', false)

  const normalizedDataLoopItem = findItemByType('dataArrayLoop', sectionItemIds, digitalTemplatePageItems) || {}
  const dataLoopItem = denormalizeEntity('digitalTemplatePageItem', normalizedDataLoopItem, entities)

  const rawValue = digObject(dataLoopItem, 'data.value', '')
  const dataArray = getShortCodeValue(rawValue, shortcodeData, { returnRawValue: true })

  // Find first container in the data loop item to evaluate render conditions
  const firstContainer = dataLoopItem.items?.find(item => item?.item_type === 'container')
  const renderConditions = digObject(firstContainer, 'options.renderConditions', [])

  const filteredDataArray = Object.values(dataArray)?.filter(data => shouldRenderPageItem(renderConditions, data))

  // Check if the array is actually an array (or object is js)
  const processDataArray = filteredDataArray && Array.isArray(filteredDataArray)

  // Component Style
  const componentStyle = findComponentStyle(componentStyles, dataLoopItem.componentStyleId)
  const loopLimit = getComponentStyleLimit(componentStyle, targetDevice)

  // Return early if we have nothing to process
  if (!sectionEnabled || !loopAutoscaling || !dataLoopItem.id || !processDataArray){
    return {
      autoscaledSections: [],
    }
  }

  const dataArrayCount = filteredDataArray.length

  const totalSectionsRequired = Math.ceil(dataArrayCount / loopLimit)
  const additionalSectionsRequired = totalSectionsRequired - 1

  const autoscaledSections = setupAutoscaledSections({
    additionalSectionsRequired,
    dataLoopItem,
    digitalTemplatePageSection,
    loopLimit,
  })

  return {
    autoscaledSections,
  }
}

export default useDataLoopAutoscaling
