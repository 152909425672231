import React from 'react'
import { Route, Routes } from 'react-router-dom'

import Editor from './screens/Editor'
import TemplateList from './screens/TemplateList'

const DigitalTemplateBuilder = () => (
  <Routes>
    <Route path="/:digitalTemplateId/edit" element={<Editor />} />
    <Route path="/template-list" element={<TemplateList />} />
  </Routes>
)

export default DigitalTemplateBuilder
