export default {
  default: {
    help: 'Need Help?',
    paragraph:
      'Generate a support token to grant temporary access to support if they need to log in and help you with something.',
    supportToken: 'Support Token',
    buttonGenerate: 'Generate Token',
    revoke: 'Revoke',
  },
}
