import { useContext } from 'react'
import { useSelector } from 'react-redux'
import cloneDeep from 'lodash/cloneDeep'

import { useForm, useLatestEntity, useThunkDispatch } from '@campaignhub/react-hooks'

import { digObject, launchModal } from '@campaignhub/javascript-utils'

import PageContext from '@contexts/pageContext'

import defaultState, { requiredFields } from '@models/digitalTemplateComponent'

import * as digitalTemplateComponentActions from '@redux/modules/digitalTemplateComponent'

const createDigitalTemplateComponent = (params) => {
  const { componentParams, dispatch, requestOptions } = params
  const { createDigitalTemplateComponent: createFn } = digitalTemplateComponentActions

  return dispatch(createFn(componentParams, requestOptions))
}

const createDigitalTemplateComponentStyle = (params) => {
  const { componentParams, dispatch, requestOptions } = params
  const { data } = componentParams || {}
  const { createDigitalTemplateComponent: createFn } = digitalTemplateComponentActions

  const updatedParams = {
    ...componentParams,
    data: JSON.stringify(data),
  }

  return dispatch(createFn(updatedParams, requestOptions))
}

const updateDigitalTemplateComponent = (params) => {
  const {
    digitalTemplateComponent, componentParams, dispatch, requestOptions,
  } = params

  const { updateDigitalTemplateComponent: updateFn } = digitalTemplateComponentActions
  const { data } = componentParams

  const updatedParams = {
    ...componentParams,
    id: digitalTemplateComponent.id,
  }

  if (data){
    const componentData = digitalTemplateComponent.data || {}
    updatedParams.data = JSON.stringify({
      ...cloneDeep(componentData),
      ...data,
    })
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

export function useDigitalTemplateComponentForm(digitalTemplateComponent = {}){
  const digitalTemplateComponentForm = useForm(defaultState, { entity: digitalTemplateComponent, requiredFields }, [
    digitalTemplateComponent.id,
    digitalTemplateComponent.cache_key,
  ])

  return {
    ...digitalTemplateComponentForm,
  }
}

function useDigitalTemplateComponent(initDigitalTemplateComponent = {}){
  const { entity: digitalTemplateComponent } = useLatestEntity(
    initDigitalTemplateComponent,
    'digitalTemplateComponents',
  )

  const pageContext = useContext(PageContext)
  const { callbacks } = pageContext

  const needUpdateConfirmation = digObject(digitalTemplateComponent, 'digital_template_ids', []).length > 1

  const dispatch = useThunkDispatch()

  const { creating } = useSelector(reduxState => reduxState.digitalTemplateComponents)

  return {
    callbacks: {
      createDigitalTemplateComponent: (componentParams, requestOptions) => createDigitalTemplateComponent({
        componentParams,
        dispatch,
        requestOptions,
      }),
      createDigitalTemplateComponentStyle: (componentParams, requestOptions) => createDigitalTemplateComponentStyle({
        componentParams,
        dispatch,
        requestOptions,
      }),
      updateDigitalTemplateComponent: (componentParams, requestOptions) => updateDigitalTemplateComponent({
        digitalTemplateComponent,
        componentParams,
        dispatch,
        requestOptions,
      }),
      viewActivities: () => launchModal({
        callbacks,
        modalKey: 'ActivitiesModal',
        payload: {
          filters: {
            recipient_id: digitalTemplateComponent.id,
            recipient_type: 'DigitalTemplateComponent',
          },
        },
      }),
    },
    creating,
    digitalTemplateComponent,
    needUpdateConfirmation,
  }
}

export default useDigitalTemplateComponent
