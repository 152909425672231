import { useContext } from 'react'
import { useSelector } from 'react-redux'

import { useRealtimeSubscription, useSetState, useThunkDispatch } from '@campaignhub/react-hooks'
import { digObject } from '@campaignhub/javascript-utils'

import RealtimeContext from '@contexts/realtimeContext'

import useMixpanel from '@hooks/useMixpanel'

import type { AppDispatch } from '@redux/store'
import type { DigitalPageModel } from '@models/digitalPage'
import type { MixPanelType } from '@hooks/useMixpanel'

import * as digitalPageActions from '@redux/modules/digitalPage'

const defaultState = {
  building: false,
  selectedDigitalPageId: null as null | number,
  showModal: false,
}

const getBuildOptions = (digitalPageId: number) => ({
  broadcast_build_completion: true,
  broadcast_channel: 'DigitalPages',
  broadcast_event: `DigitalPage.${digitalPageId}.downloadPreparation.complete`,
})

type AblyPayload = {
  filters: { id: number },
  payload: DigitalPageModel,
}

type SetState = (state: Partial<typeof defaultState>) => void

type RequestOptionsType = { [key:string]: boolean }

type BuildPdfParams = {
  digitalPage: DigitalPageModel,
  dispatch: AppDispatch,
  dispatchMixpanelEvent: MixPanelType,
  requestOptions: RequestOptionsType,
  setState: SetState,
}

const buildPdf = (params: BuildPdfParams) => {
  const {
    digitalPage, dispatch, dispatchMixpanelEvent, requestOptions, setState,
  } = params

  const { buildDigitalPagePdfUrl } = digitalPageActions

  setState({
    building: true,
    selectedDigitalPageId: digitalPage.id,
    showModal: true,
  })

  const mergedOptions = {
    ...getBuildOptions(digitalPage.id),
    ...requestOptions,
  }

  dispatchMixpanelEvent('Digital Page Downloaded', { digital_page_id: digitalPage.id })

  return dispatch(buildDigitalPagePdfUrl(digitalPage, mergedOptions))
}

type BuildCompleteType = {
  digitalPageId: number,
  dispatch: AppDispatch,
  result: AblyPayload,
  setState: SetState,
}

const buildComplete = (params: BuildCompleteType) => {
  const {
    digitalPageId, result, setState, dispatch,
  } = params

  const { hydrateDigitalPage } = digitalPageActions

  const {
    filters: { id },
    payload,
  } = result

  if (id === digitalPageId){
    dispatch(hydrateDigitalPage(payload))

    setState({
      building: false,
      showModal: true,
    })
  }
}

function usePdfDownload() {
  const [state, setState] = useSetState(defaultState)
  const { selectedDigitalPageId } = state

  const realtimeContext = useContext(RealtimeContext)
  const { clientRef } = realtimeContext

  const dispatch = useThunkDispatch()

  const { digitalPages } = useSelector(reduxState => reduxState.entities)
  const digitalPage = digitalPages[selectedDigitalPageId] || {}

  const url = digObject(digitalPage, 'options.pdf_url')

  const buildOptions = getBuildOptions(selectedDigitalPageId)
  const { broadcast_channel, broadcast_event } = buildOptions

  // Tracks the page load event
  const { callbacks: { dispatchMixpanelEvent } } = useMixpanel()

  useRealtimeSubscription({
    apiKey: process.env.REACT_APP_ABLY_PUBLIC_KEY,
    callbacks: {
      success: (result: AblyPayload) => buildComplete({
        digitalPageId: selectedDigitalPageId, result, setState, dispatch,
      }),
    },
    channelName: broadcast_channel,
    clientRef,
    eventName: broadcast_event,
  }, [selectedDigitalPageId])

  return {
    ...state,
    attachment: { file: { url } },
    callbacks: {
      buildPdf: (selectedDigitalPage: Partial<DigitalPageModel>, requestOptions: RequestOptionsType) => buildPdf({
        digitalPage: selectedDigitalPage,
        dispatch,
        dispatchMixpanelEvent,
        requestOptions,
        setState,
      }),
      closeModal: () => setState({ showModal: false }),
      setState,
    },
  }
}

export default usePdfDownload
