import { useContext, useMemo } from 'react'

import { useForm, useLatestEntity, useWatchEntityUpdates } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import { launchModal, multiFilteredObjectArraySelector, sortArrayBy } from '@campaignhub/javascript-utils'

import useDispatch from '@hooks/useDispatch'
import useSelector from '@hooks/useSelector'

import * as awardActions from '@redux/modules/award'

import defaultFormState, { requiredFields } from '@models/award'

import PageContext from '@contexts/pageContext'

import type { AppDispatch } from '@redux/store'
import type { DeleteParams } from '@redux/modules/types'
import type { AwardModel, AwardRequestOptions } from '@models/types'
import { CustomFieldModel } from '@models/customField'

const watchEntityKeys = ['images']

export const generateUrls = (award?: Partial<AwardModel>) => {
  const { id } = award || {}

  return {
    editAwardDetailsUrl: `#/sectionName/awards/${id}/edit/details`,
    editAwardUrl: `#/sectionName/awards/${id}/edit/`,
    awardsIndexUrl: '#/sectionName/awards',
  }
}

type CreateAwardParams = {
  awardParams: {
    award: Partial<AwardModel>,
    customFields: CustomFieldModel[],
  },
  dispatch: AppDispatch,
  requestOptions?: AwardRequestOptions,
}

const createAward = (params: CreateAwardParams) => {
  const {
    awardParams: { award, customFields }, dispatch, requestOptions,
  } = params
  const { createAward: createFn } = awardActions

  return dispatch(createFn(award, customFields, requestOptions))
}

type UpdateAwardParams = {
  award: AwardModel,
  awardParams: {
    award: Partial<AwardModel>,
    customFields: CustomFieldModel[],
  },
  dispatch: AppDispatch,
  requestOptions?: AwardRequestOptions,
}

const updateAward = (params: UpdateAwardParams) => {
  const {
    dispatch, award, awardParams, requestOptions,
  } = params
  const { updateAward: updateFn } = awardActions

  const updatedParams = {
    id: award.id,
    ...awardParams.award,
  }

  return dispatch(updateFn(updatedParams, awardParams.customFields, requestOptions))
}

type DeleteAwardParams = {
  award: DeleteParams<AwardModel>,
  dispatch: AppDispatch,
}

const deleteAward = (params: DeleteAwardParams) => {
  const { dispatch, award } = params
  const { deleteAward: deleteFn } = awardActions

  return dispatch(deleteFn(award))
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useAwardForm(
  award: Partial<AwardModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const awardForm = useForm(
    defaultFormState,
    { entity: award, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [award.id, award.cache_key],
  )

  return {
    ...awardForm,
  }
}

function useAward(initEntity: Partial<AwardModel> = {}) {
  const { entity: award }: { entity: AwardModel} = useLatestEntity(initEntity, 'awards')

  const dispatch = useDispatch()

  const { callbacks } = useContext(PageContext)

  const entities = useSelector(reduxState => reduxState.entities)
  const {
    creating, loading, updating,
  } = useSelector(reduxState => reduxState.awards)

  const {
    updatedEntities: { images: imagesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const imageFilters = [
    { key: 'subject_type', value: 'Award' },
    { key: 'subject_id', value: award.id },
  ]

  const filteredImages = useMemo(() => {
    const filtered = multiFilteredObjectArraySelector({ entities }, 'images', imageFilters)
    const sorted = sortArrayBy(filtered, 'asc', 'sort')

    return sorted
  }, [imagesUpdatedAt, award.id])

  const defaultImage = filteredImages[0] || {}

  return {
    callbacks: {
      createAward: (
        awardParams: {
          award: Partial<AwardModel>,
          customFields: CustomFieldModel[],
        },
        entityOptions?: AwardRequestOptions,
      ) => (
        createAward({
          awardParams, dispatch, requestOptions: entityOptions,
        })
      ),
      createOrEditAward: (customPayload: {}) => launchModal({
        callbacks,
        modalKey: 'CreateOrEditAwardModal',
        payload: { award, ...customPayload },
      }),
      deleteAward: () => deleteAward({ dispatch, award }),
      updateAward: (
        awardParams: {
          award: Partial<AwardModel>,
          customFields: CustomFieldModel[],
        },
        entityOptions?: AwardRequestOptions,
      ) => (
        updateAward({
          award, awardParams, dispatch, requestOptions: entityOptions,
        })
      ),
    },
    award,
    creating,
    defaultImage,
    loading,
    updating,
  }
}

export default useAward
