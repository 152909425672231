import React from 'react'

import { BlankState } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import blankImageUrl from './assets/no-brands.svg'

import localizedStrings from './localizedStrings'

const BrandBlankState = () => {
  const { strings } = useLocalization(localizedStrings)

  return (
    <BlankState imageUrl={blankImageUrl}>
      <BlankState.Title>{strings.blankState?.title || 'No Brands to Display'}</BlankState.Title>

      <BlankState.Paragraph>
        {strings.blankState?.paragraph || 'Try adjusting your filters to return more results.'}
      </BlankState.Paragraph>
    </BlankState>
  )
}

export default BrandBlankState
