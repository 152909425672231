import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import useDeviceStyle from '@hooks/useDeviceStyle'

import useDropTarget from '../../../hooks/useDropTarget'

import styles from './styles.module.scss'

const Container = React.memo((props) => {
  const {
    callbacks,
    children,
    entityType,
    id,
    isSelected,
    item_type,
    onClick,
    options: { flexDirection, flexWrap },
  } = props

  const style = useDeviceStyle(props)

  const dropTargetRef = useRef(null)

  const { isActive } = useDropTarget(dropTargetRef, { id, type: entityType }, {
    excludedTypes: ['section', 'sectionGroup'],
    callbacks,
  })

  return (
    <div
      className={classnames(
        styles.root,
        flexDirection ? styles[flexDirection] : null,
        flexWrap ? styles[flexWrap] : null,
        isActive ? styles.active : null,
        isSelected ? styles.selected : null,
      )}
      onClick={onClick}
      style={style}
      ref={dropTargetRef}
    >
      <div className={styles.label}>{item_type} {id}</div>
      {children}
    </div>
  )
})

Container.propTypes = {
  callbacks: PropTypes.object,
  children: PropTypes.node,
  entityType: PropTypes.string,
  id: PropTypes.string,
  isSelected: PropTypes.bool,
  item_type: PropTypes.string,
  onClick: PropTypes.func,
  options: PropTypes.object,
}

Container.defaultProps = {
  callbacks: {},
  options: {},
}

export default Container
