import { useContext, useMemo } from 'react'

import { useForm, useLatestEntity, useWatchEntityUpdates } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import { launchModal, multiFilteredObjectArraySelector, sortArrayBy } from '@campaignhub/javascript-utils'

import useDispatch from '@hooks/useDispatch'
import useSelector from '@hooks/useSelector'

import * as targetAudienceActions from '@redux/modules/targetAudience'

import defaultFormState, { requiredFields } from '@models/targetAudience'

import PageContext from '@contexts/pageContext'

import type { AppDispatch } from '@redux/store'
import type { TargetAudienceModel, TargetAudienceRequestOptions } from '@models/types'

const watchEntityKeys = ['digitalTemplates', 'images']

type CreateTargetAudienceParams = {
  dispatch: AppDispatch,
  requestOptions?: TargetAudienceRequestOptions,
  targetAudienceParams: Partial<TargetAudienceModel>,
}

const createTargetAudience = (params: CreateTargetAudienceParams) => {
  const { dispatch, targetAudienceParams, requestOptions } = params
  const { createTargetAudience: createFn } = targetAudienceActions

  return dispatch(createFn(targetAudienceParams, requestOptions))
}

type UpdateTargetAudienceParams = {
  dispatch: AppDispatch,
  requestOptions?: TargetAudienceRequestOptions,
  targetAudience: TargetAudienceModel,
  targetAudienceParams: Partial<TargetAudienceModel>,
}

const updateTargetAudience = (params: UpdateTargetAudienceParams) => {
  const {
    dispatch, requestOptions, targetAudience, targetAudienceParams,
  } = params
  const { updateTargetAudience: updateFn } = targetAudienceActions

  const updatedParams = {
    id: targetAudience.id,
    ...targetAudienceParams,
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

type DeleteTargetAudienceParams = {
  dispatch: AppDispatch,
  targetAudience: TargetAudienceModel,
}

const deleteTargetAudience = (params: DeleteTargetAudienceParams) => {
  const { dispatch, targetAudience } = params
  const updatedTargetAudience = {
    id: targetAudience.id,
    hidden: true,
  }

  return updateTargetAudience({ dispatch, targetAudience, targetAudienceParams: updatedTargetAudience })
}

type DeleteFromProjectsParams = {
  dispatch: AppDispatch,
  targetAudience: TargetAudienceModel,
}

const deleteFromProjects = (params: DeleteFromProjectsParams) => {
  const { targetAudience, dispatch } = params
  const { deleteFromProjects: deleteFromProjectsFn } = targetAudienceActions

  return dispatch(deleteFromProjectsFn(targetAudience))
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useTargetAudienceForm(
  targetAudience: Partial<TargetAudienceModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const targetAudienceForm = useForm(
    defaultFormState,
    { entity: targetAudience, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [targetAudience.id, targetAudience.cache_key],
  )

  return {
    ...targetAudienceForm,
  }
}

function useTargetAudience(initEntity: Partial<TargetAudienceModel> = {}) {
  const { entity: targetAudience }: { entity: TargetAudienceModel} = useLatestEntity(initEntity, 'targetAudiences')

  const dispatch = useDispatch()

  const { callbacks } = useContext(PageContext)

  const {
    updatedEntities: { images: imagesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const entities = useSelector(reduxState => reduxState.entities)

  const { creating, updating } = useSelector(reduxState => reduxState.targetAudiences)

  const imageFilters = [
    { key: 'subject_id', value: targetAudience.id },
    { key: 'subject_type', value: 'TargetAudience' },
  ]

  const filteredImages = useMemo(() => {
    const filtered = multiFilteredObjectArraySelector({ entities }, 'images', imageFilters)
    const sorted = sortArrayBy(filtered, 'asc', 'sort')

    return sorted
  }, [imagesUpdatedAt, targetAudience.id])

  const defaultImage = filteredImages[0] || {}

  return {
    callbacks: {
      createOrEditTargetAudience: (customPayload: {}) => launchModal({
        callbacks,
        modalKey: 'CreateOrEditTargetAudienceModal',
        payload: { targetAudience, ...customPayload },
      }),
      createTargetAudience: (
        targetAudienceParams: Partial<TargetAudienceModel>,
        entityOptions?: TargetAudienceRequestOptions,
      ) => (
        createTargetAudience({ targetAudienceParams, dispatch, requestOptions: entityOptions })
      ),
      deleteFromProjects: () => deleteFromProjects({ dispatch, targetAudience }),
      deleteTargetAudience: () => deleteTargetAudience({ dispatch, targetAudience }),
      updateTargetAudience: (
        targetAudienceParams: Partial<TargetAudienceModel>,
        entityOptions?: TargetAudienceRequestOptions,
      ) => (
        updateTargetAudience({
          targetAudience, targetAudienceParams, dispatch, requestOptions: entityOptions,
        })
      ),
    },
    creating,
    defaultImage,
    filteredImages,
    targetAudience,
    updating,
  }
}

export default useTargetAudience
