import React from 'react'
import PropTypes from 'prop-types'

import useDigitalTemplatePageSection from '@hooks/useDigitalTemplatePageSection'

import ContentWrapper from '@components/digitalTemplates/ContentWrapper'

import availableComponents from '../../availableComponents'

import DropTarget from './DropTarget'

const Section = React.memo((props) => {
  const {
    callbacks, digitalTemplatePageSection: initSection, selectedCanvasItem,
  } = props

  const sectionPayload = useDigitalTemplatePageSection(initSection)
  const { digitalTemplatePageSection, digitalTemplatePageItems } = sectionPayload

  return (
    <ContentWrapper
      availableComponents={availableComponents}
      callbacks={callbacks}
      itemEntities={digitalTemplatePageItems}
      renderNested
      section={digitalTemplatePageSection}
      selectedItem={selectedCanvasItem}
      render={({ itemComponents }) => (
        <DropTarget
          callbacks={callbacks}
          digitalTemplatePageSectionPayload={sectionPayload}
          itemComponents={itemComponents}
        />
      )}
    />
  )
})

Section.propTypes = {
  callbacks: PropTypes.shape({
    dropItem: PropTypes.func.isRequired,
    selectCanvasItem: PropTypes.func.isRequired,
  }).isRequired,
  digitalTemplatePageSection: PropTypes.object.isRequired,
  selectedCanvasItem: PropTypes.object,
}

export default Section
