export default {
  default: {
    itemTypes: {
      digitalTemplatePageItem: 'Page Item',
      digitalTemplatePageSection: 'Section ',
      digitalTemplatePageSectionGroup: 'Section Group',
      pageItem: 'Page Item',
    },
  },
}
