import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/pro-light-svg-icons'
import { faList, faStar as faStarSolid } from '@fortawesome/pro-solid-svg-icons'

import { toggleArray } from '@campaignhub/javascript-utils'

import {
  Box, Button, FormField, SidebarModal,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'
import useOrganizationsUser from '@hooks/useOrganizationsUser'

import ModalAddress from '@components/ModalComponents/ModalAddress'
import ModalUsers from '@components/ModalComponents/ModalUsers'
import FilterUsersContactsAndTeams from '@components/FilterUsersContactsAndTeams'

import localizedStrings from './localizedStrings'

const ratingOptions = [1, 2, 3, 4, 5]

const toggleReviewUsers = (userId, entityState, setEntityState) => {
  const { user_ids } = entityState

  const updatedUsers = toggleArray(user_ids, userId)

  setEntityState({ user_ids: updatedUsers })
}

const ReviewForm = (props) => {
  const {
    address,
    callbacks: { createOrEditAddress },
    reviewFormPayload,
  } = props

  const {
    entityState, errors, handlers, setEntityState,
  } = reviewFormPayload

  const {
    address_attributes, body, owner_id, rating, reviewer_name, title, user_ids,
  } = entityState

  const { currentUser, isCurrentUserAdminForOrganization } = useOrganizationsUser({ organization_id: owner_id })

  const hasAddressAttributes = !!Object.keys(address_attributes).length
  const hasAddress = hasAddressAttributes || !!Object.keys(address).length

  const { strings } = useLocalization(localizedStrings)

  return (
    <Box flexDirection="column" flexShrink={0}>
      {/* Add Address */}
      {!hasAddress && (
        <Button
          buttonStyle="ghostEdit"
          icon={<FontAwesomeIcon icon={faList} />}
          marginBottom="large"
          onClick={() => createOrEditAddress(entityState, setEntityState)}
          size="medium"
          width="100%"
        >
          {strings.formFieldLabels?.manualAddress || 'Enter Address manually'}
        </Button>
      )}

      {hasAddress && (
        <>
          <ModalAddress
            address={hasAddressAttributes ? address_attributes : address}
            callbacks={{ editAddress: () => createOrEditAddress(entityState, setEntityState) }}
          />

          <SidebarModal.Separator />

          {/* Add Users */}
          <FormField boxProps={{ marginTop: 'large' }} label={strings.formFieldLabels?.searchUsers || '* Search Users'}>
            <FilterUsersContactsAndTeams
              callbacks={{ selectItem: selected => toggleReviewUsers(selected.id, entityState, setEntityState) }}
              selectedUserIds={user_ids}
            />
          </FormField>

          {!!user_ids.length && (
            <FormField boxProps={{ marginTop: 'large' }} label={strings.formFieldLabels?.reviewUsers || 'Review Users'}>
              <ModalUsers
                callbacks={{ deleteUser: userId => toggleReviewUsers(userId, entityState, setEntityState) }}
                disableDeleteForUserIds={!isCurrentUserAdminForOrganization ? [currentUser.id] : []}
                userIds={user_ids}
              />
            </FormField>
          )}
          {/* End Add Users */}

          <SidebarModal.Separator boxProps={{ marginTop: 'large' }} />

          <FormField
            boxProps={{ marginTop: 'large' }}
            direction="column"
            errorMessage={errors.reviewer_name}
            label={strings.formFieldLabels?.vendorName || '* Vendor Name(s)'}
          >
            <input type="text" name="reviewer_name" value={reviewer_name} {...handlers} />
          </FormField>

          <FormField
            boxProps={{ marginTop: 'large' }}
            direction="column"
            errorMessage={errors.rating}
            label={strings.formFieldLabels?.rating || '* Rating/Satisfaction'}
          >
            <Box alignItems="center" height={37} marginBottom="medium" marginLeft="small">
              {ratingOptions.map(option => (
                <Box
                  key={option}
                  color="blue"
                  fontSize="20px"
                  marginRight="small"
                  onClick={() => setEntityState({ rating: option })}
                  style={{ cursor: 'pointer' }}
                  width="auto"
                >
                  <FontAwesomeIcon icon={rating >= option ? faStarSolid : faStar} />
                </Box>
              ))}
            </Box>
          </FormField>

          <FormField
            boxProps={{ marginTop: 'large' }}
            direction="column"
            errorMessage={errors.title}
            label={strings.formFieldLabels?.reviewTitle || '* Review Title'}
          >
            <input data-validate-field-on="change" type="text" name="title" value={title} {...handlers} />
          </FormField>

          <FormField
            boxProps={{ marginTop: 'large' }}
            direction="column"
            errorMessage={errors.body}
            label={strings.formFieldLabels?.review || '* Review'}
          >
            <textarea data-validate-field-on="change" name="body" value={body} {...handlers} style={{ height: 100 }} />
          </FormField>
        </>
      )}
    </Box>
  )
}

ReviewForm.propTypes = {
  address: PropTypes.object,
  callbacks: PropTypes.object,
  reviewFormPayload: PropTypes.object,
}

export default ReviewForm
