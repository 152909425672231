import { Box, FormField } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'
import useReduxAction from '@hooks/useReduxAction'
import useTag from '@hooks/useTag'
import useTags from '@hooks/useTags'

import localizedStrings from './localizedStrings'
import Tag from './components/Tag'
import TagSelector from './components/TagSelector'

interface TagsProps {
  ownerId: number,
  ownerType: string,
  subjectId: number,
  subjectType: string,
  tagEntity: 'Project' | 'User',
}

const Tags = (props: TagsProps) => {
  const {
    ownerId, ownerType, subjectId, subjectType, tagEntity,
  } = props

  useReduxAction('tags', 'loadTags', {}, [])

  const tagsPayload = useTags({
    filters: {
      ownerId,
      ownerType,
      subjectId,
      subjectType,
    },
  })

  const { filteredTags, hasTags } = tagsPayload

  const initTag = {
    owner_id: ownerId,
    owner_type: ownerType,
    subject_id: subjectId,
    subject_type: subjectType,
  }

  const {
    callbacks: { selectTag: selectFn },
  } = useTag(initTag)

  const { strings } = useLocalization(localizedStrings)

  return (
    <FormField boxProps={{ marginTop: 'large' }} label={strings.tag || 'Tag'}>
      <TagSelector hasTags={hasTags} selectFn={selectFn} tagEntity={tagEntity} />

      {hasTags && (
        <Box
          alignItems="baseline"
          border="1px solid"
          borderColor="lineColor"
          borderRadius={5}
          minHeight={30}
          flexWrap="wrap"
          marginTop="medium"
          padding="medium"
        >
          {filteredTags.map(tag => (
            <Tag key={tag.id} tag={tag} />
          ))}
        </Box>
      )}
    </FormField>
  )
}

export default Tags
