import React from 'react'
import PropTypes from 'prop-types'
import { Duration } from 'luxon'

import { Box, ListItem, Text } from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import EngagementStatusBadge from '@components/EngagementStatusBadge'

import useEngagementStatus from '@hooks/useEngagementStatus'
import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const LinkView = (props) => {
  const {
    view,
    view: { engagement_status, read_percentage, seconds_on_page },
  } = props

  const createdDateTime = digObject(view, 'dates.created.date_time_with_timezone')

  const {
    values: { minutes, seconds },
  } = Duration.fromObject({ seconds: seconds_on_page }).shiftTo('minutes', 'seconds')

  const { engagementStatusTheme } = useEngagementStatus(engagement_status)

  const {
    callbacks: { formatDate, formatString },
    strings,
  } = useLocalization(localizedStrings)

  return (
    <ListItem boxProps={{ borderBottom: '1px dashed', paddingY: 'large' }} disableHover forceBottomBorder>
      <Box flexDirection="column">
        <Text fontSize="xsmall">
          {`${formatString(strings.readPercentage, { readPercentage: read_percentage })} ${
            minutes > 0 ? `${minutes}m ${seconds}s` : `${seconds}s`
          }`}
        </Text>

        <Text
          color="bodyFontLightColor"
          fontSize="xxsmall"
          marginTop="small"
          style={{ textTransform: 'uppercase', flexShrink: 0 }}
        >
          {formatDate(createdDateTime, {
            weekday: 'short',
            day: 'numeric',
            month: 'long',
            hour: '2-digit',
            minute: '2-digit',
          })}
        </Text>
      </Box>

      <EngagementStatusBadge
        boxProps={{ marginRight: 'auto', marginY: 'auto', width: 'auto' }}
        engagementStatusTheme={engagementStatusTheme}
      />
    </ListItem>
  )
}

LinkView.propTypes = {
  view: PropTypes.shape({
    engagement_status: PropTypes.string,
    read_percentage: PropTypes.number,
    seconds_on_page: PropTypes.number,
  }).isRequired,
}

export default LinkView
