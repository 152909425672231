import { useEffect } from 'react'

import { toggleArray } from '@campaignhub/javascript-utils'
import { useDeepEffect, useSetState } from '@campaignhub/react-hooks'

import useEntityDefaultIntegrationPlatform from '@hooks/useEntityDefaultIntegrationPlatform'
import useEntityExternalPlatforms from '@hooks/useEntityExternalPlatforms'
import useQuote from '@hooks/useQuote'

const loadFromExternalPlatform = (loadFn, platformKey, externalId, setState) => {
  setState({ loading: true, results: [], selectedExternalIds: [] })

  return loadFn(platformKey, { external_project_id: externalId }).then((result) => {
    const { data } = result

    const newState = {
      loaded: true,
      loading: false,
    }

    if (data && data.length){
      newState.results = data
      newState.selectedExternalIds = data.map(quote => quote.id)
    }

    setState(newState)
    return result
  })
}

const defaultState = {
  externalProjectId: '',
  loaded: false,
  loading: false,
  results: [],
  selectedExternalIds: [],
  selectedIntegrationPlatformKey: '',
}

function useImportQuote(integrationsPayload, project){
  const {
    filteredPlatforms,
  } = integrationsPayload

  const [state, setState] = useSetState(defaultState)
  const {
    externalProjectId, loaded, selectedExternalIds, selectedIntegrationPlatformKey,
  } = state

  const quotePayload = useQuote({})
  const {
    callbacks: { importFromExternalPlatform, loadFromExternalPlatform: loadFn, manageExternalQuotesUrl },
  } = quotePayload

  const { defaultPlatformKey } = useEntityDefaultIntegrationPlatform({ featureKey: 'quoting' })

  // External Platforms
  const externalPlatformsPayload = useEntityExternalPlatforms(project)
  const { externalIds } = externalPlatformsPayload

  // Check for a user/org's default integration platform,
  // If none, auto select first platform that has an external ID for project
  useDeepEffect(() => {
    if (defaultPlatformKey){
      setState({
        externalProjectId: externalIds[defaultPlatformKey] || '',
        selectedIntegrationPlatformKey: defaultPlatformKey,
      })
      return
    }

    if (filteredPlatforms.length){
      const quotingPlatform = filteredPlatforms.find((integrationPlatform) => {
        const { key } = integrationPlatform
        return !!externalIds[key]
      }) || {}

      setState({
        selectedIntegrationPlatformKey: quotingPlatform.key,
        externalProjectId: externalIds[quotingPlatform.key] || '',
      })
    }
  }, [defaultPlatformKey, filteredPlatforms.length, externalIds])

  // Load Quotes From External Platform
  useEffect(() => {
    if (!loaded && selectedIntegrationPlatformKey && externalProjectId){
      loadFromExternalPlatform(loadFn, selectedIntegrationPlatformKey, externalProjectId, setState)
    }
  }, [selectedIntegrationPlatformKey, externalProjectId, loaded])

  return {
    callbacks: {
      importFromExternalPlatform,
      loadFromExternalPlatform: () => loadFromExternalPlatform(loadFn, selectedIntegrationPlatformKey, externalProjectId, setState),
      setState,
      toggleExternalId: id => setState({ selectedExternalIds: toggleArray(selectedExternalIds, id) }),
    },
    state,
    urls: {
      manageExternalQuotesUrl: manageExternalQuotesUrl(selectedIntegrationPlatformKey, externalProjectId),
    },
  }
}

export default useImportQuote
