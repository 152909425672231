export default {
  default: {
    toast: {
      shareLink: {
        deleted: 'Share Link Deleted Successfully',
        updated: 'Share Link Updated Successfully',
      },
    },
  },
}
