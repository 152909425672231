import { useSelector } from 'react-redux'

import { digObject } from '@campaignhub/javascript-utils'

import useDataStoreItem from '@hooks/useDataStoreItem'

export const getSubjectId = (subjectType, context) => {
  if (subjectType === 'Project'){
    return digObject(context, 'project.id')
  }

  return digObject(context, 'digitalPage.id')
}

const createOrUpdateDataStoreItem = (payload, options, requestOptions) => {
  const {
    callbacks: { createOrUpdateDataStoreItem: createOrUpdateFn, loadDigitalPageShortcodeData: loadShortCodeDataFn },
    currentUser,
    digitalPage,
    digitalTemplate,
    shareLinkToken,
    uuid,
  } = options

  const {
    behaviour, data, key, label, subjectType, value,
  } = payload

  const dataStoreItem = {
    data: JSON.stringify(data),
    digital_page_id: digitalPage.id,
    digital_template_id: digitalTemplate.id,
    editor_id: currentUser.id,
    editor_type: currentUser.id ? 'User' : null,
    group_key: uuid,
    key,
    label,
    owner_id: digitalPage.owner_id,
    owner_type: digitalPage.owner_type,
    subject_id: getSubjectId(subjectType, options),
    subject_type: subjectType,
    value,
  }

  createOrUpdateFn(dataStoreItem, { token: shareLinkToken, behaviour, ...requestOptions }).then((response) => {
    const { success } = response
    if (success){
      loadShortCodeDataFn()
    }
  })
}

function useDigitalPageDataStoreItems(options = {}){
  const {
    callbacks, currentUser, digitalPage, digitalTemplate, project, shareLinkToken,
  } = options || {}

  const dataStoreItems = useSelector(reduxState => Object.values(reduxState.entities.dataStoreItems))

  const dataStoreItemPayload = useDataStoreItem({})
  const { callbacks: dataStoreItemCallbacks } = dataStoreItemPayload

  return {
    callbacks: {
      createOrUpdateDataStoreItem: (payload, requestOptions) => createOrUpdateDataStoreItem(
        payload,
        {
          callbacks: {
            ...callbacks,
            ...dataStoreItemCallbacks,
          },
          currentUser,
          digitalPage,
          digitalTemplate,
          project,
          shareLinkToken,
        },
        requestOptions,
      ),
    },
    dataStoreItems,
  }
}

export default useDigitalPageDataStoreItems
