import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { useModals, useSetState } from '@campaignhub/react-hooks'

import { MainContent, ModalContext, TopBar } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import useMainNavigation from '@hooks/useMainNavigation'
import useReduxAction from '@hooks/useReduxAction'

import DashboardSearch from '@components/DashboardSearch'
import LoggedInUser from '@components/LoggedInUser'
import MainNavigation from '@sections/Admin/components/MainNavigation'

import ActivitiesModal from '@modals/ActivitiesModal'
import CreateDigitalTemplateModal from '@modals/CreateDigitalTemplateModal'
import ManageDigitalTemplatePermissionsModal from '@modals/ManageDigitalTemplatePermissionsModal'

import PageContent from './components/PageContent'

const bulkUpdatePermissions = (organizationIds, updateFn, setState) => {
  updateFn(organizationIds).then(({ success, errors }) => {
    if (!success){
      toast.warning(errors[0])
      return
    }

    setState({ showManageDigitalTemplatePermissionsModal: false })
    toast('Permissions updated successfully')
  })
}

const createDigitalTemplate = (params, createFn) => {
  createFn(params).then(({ success, errors, redirectUrl }) => {
    if (!success){
      toast.warn(errors[0])
      return
    }
    if (redirectUrl){
      window.location.href = redirectUrl
    }
  })
}

const callbacks = (component, setState) => {
  const componentCallbacks = {
    ActivitiesModal: {
      closeModal: () => setState({ showActivitiesModal: false }),
    },
    CreateDigitalTemplateModal: {
      closeModal: () => setState({ showCreateDigitalTemplateModal: false }),
      submitAction: (entityParams, createFn) => createDigitalTemplate(entityParams, createFn),
    },
    ManageDigitalTemplatePermissionsModal: {
      closeModal: () => setState({ showManageDigitalTemplatePermissionsModal: false }),
      bulkUpdatePermissions: (organizationIds, updateFn) => bulkUpdatePermissions(organizationIds, updateFn, setState),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  pageFilters: {},
  showActivitiesModal: false,
  showCreateDigitalTemplateModal: false,
  showManageDigitalTemplatePermissionsModal: false,
}

const TemplateList = () => {
  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const [state, setState] = useSetState(defaultState)
  const {
    pageFilters,
    showActivitiesModal,
    showCreateDigitalTemplateModal,
    showManageDigitalTemplatePermissionsModal,
  } = state

  useReduxAction('digitalTemplateTypes', 'loadDigitalTemplateTypes', {}, [], {
    shouldPerformFn: (entityReducer) => {
      const { loaded } = entityReducer
      return !loaded
    },
  })

  const { entities } = useSelector(reduxState => reduxState)
  const { brands } = entities
  const brandsArray = Object.values(brands)

  useEffect(() => {
    if (brandsArray.length === 1){
      const id = brandsArray[0]?.id
      setState({ pageFilters: { brand_id: id } })
    }
  }, [brandsArray.length])

  const {
    callbacks: { toggleNavigation },
  } = useMainNavigation()

  const pageContext = useMemo(
    () => ({
      callbacks: {
        showActivitiesModal: (payload) => {
          setModalData('ActivitiesModal', payload)
          setState({ showActivitiesModal: true })
        },
        showCreateDigitalTemplateModal: () => {
          setState({ showCreateDigitalTemplateModal: true })
        },
        showManageDigitalTemplatePermissionsModal: (payload) => {
          setModalData('ManageDigitalTemplatePermissionsModal', payload)
          setState({ showManageDigitalTemplatePermissionsModal: true })
        },
        resetFilters: (resetFn) => {
          if (resetFn) resetFn()
          setState({ pageFilters: {} })
        },
        updateFilters: (filters) => {
          setState({ pageFilters: filters })
        },
      },
    }),
    [],
  )

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <TopBar
          callbacks={{ toggleNavigation }}
          loggedInUserComponent={<LoggedInUser />}
          searchComponent={<DashboardSearch searchEntities={['Brand', 'DigitalTemplate', 'Organization', 'User']} />}
        />

        <MainNavigation />

        <MainContent offset={{ top: TopBar.topBarHeight }}>
          <PageContent pageFilters={pageFilters} />
        </MainContent>

        <ActivitiesModal callbacks={callbacks('ActivitiesModal', setState)} showModal={showActivitiesModal} />

        <CreateDigitalTemplateModal
          callbacks={callbacks('CreateDigitalTemplateModal', setState)}
          showModal={showCreateDigitalTemplateModal}
        />

        <ManageDigitalTemplatePermissionsModal
          callbacks={callbacks('ManageDigitalTemplatePermissionsModal', setState)}
          showModal={showManageDigitalTemplatePermissionsModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default TemplateList
