import React from 'react'
import PropTypes from 'prop-types'

import { Grid, SidebarModal } from '@campaignhub/suit-theme'

import TemplateImage from './TemplateImage'

import useDigitalTemplateGallery from './hooks/useDigitalTemplateGallery'

const shouldRender = (itemType) => {
  const permittedItemTypes = [
    'carousel',
  ]
  return permittedItemTypes.includes(itemType)
}

const Gallery = (props) => {
  const {
    digitalTemplatePayload: {
      callbacks: { updateGalleries },
      galleries,
      templateImages,
    },
    selectedEntity: { id, item_type, options },
  } = props

  const { carouselCustomGallery } = options || {}

  const templateGalleryPayload = useDigitalTemplateGallery({
    callbacks: { updateGalleries },
    galleries,
    pageItemId: id,
  })

  const { callbacks: { togglePageItemGalleryImage }, selectedImageIds } = templateGalleryPayload

  if (!shouldRender(item_type) || !carouselCustomGallery) return null

  return (
    <SidebarModal.ExpandableSectionBox defaultOpen label="Gallery">
      <Grid
        gridGap="medium"
        gridAutoRows="1fr"
        gridTemplateColumns="repeat(auto-fill, minmax(102px, 1fr))"
        gridTemplateRows="repeat(auto-fill, minmax(80px, 1fr))"
        marginBottom="medium"
      >
        {templateImages.map(image => (
          <TemplateImage
            key={image.id}
            image={image}
            onClick={() => togglePageItemGalleryImage(image)}
            selected={selectedImageIds.includes(image.id)}
          />
        ))}
      </Grid>
    </SidebarModal.ExpandableSectionBox>
  )
}

Gallery.propTypes = {
  digitalTemplatePayload: PropTypes.shape({
    callbacks: PropTypes.shape({
      updateGalleries: PropTypes.func.isRequired,
    }).isRequired,
    galleries: PropTypes.object.isRequired,
    templateImages: PropTypes.array.isRequired,
  }).isRequired,
  selectedEntity: PropTypes.object.isRequired,
}

export default Gallery
