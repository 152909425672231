import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import queryString from 'query-string'
import isMobileBrowser from 'is-mobile'

import { digObject } from '@campaignhub/javascript-utils'
import {
  useSetState, useThunkDispatch, useUniqueId, useWatchEntityUpdates,
} from '@campaignhub/react-hooks'

import useAddress from '@hooks/useAddress'
import useAnalyticsTracker from '@hooks/useAnalyticsTracker'
import useCurrentUser from '@hooks/useCurrentUser'
import useDigitalPage from '@hooks/useDigitalPage'
import useDigitalPageDataStoreItems from '@hooks/useDigitalPageDataStoreItems'
import useDigitalTemplateFonts from '@hooks/useDigitalTemplateFonts'
import useQuote from '@hooks/useQuote'
import useReduxAction from '@hooks/useReduxAction'
import { getDigitalTemplateEnabledFeatures, useRelations as useDigitalTemplateRelations } from '@hooks/useDigitalTemplate'
import { useRelations as useProjectRelations } from '@hooks/useProject'

import isPrintMode from '@components/digitalRenderer/utils/isPrintMode'

import * as digitalPageActions from '@redux/modules/digitalPage'
import * as digitalTemplatePageActions from '@redux/modules/digitalTemplatePage'

import defaultRequestOptions from '../defaultRequestOptions'

const isMobile = isMobileBrowser()
const isTablet = isMobileBrowser({ tablet: true })
const isMobileDevice = isMobile || isTablet

const watchEntityKeys = ['digitalTemplatePageSectionGroups', 'digitalTemplatePages', 'digitalTemplates']

export const modalKeys = {
  chart_data: {
    dataContext: 'chart_data',
    modalKey: 'ManageProjectChartsModal',
  },
  case_studies: {
    dataContext: 'case_studies',
    modalKey: 'ManageProjectCaseStudiesModal',
  },
  commission: {
    dataContext: 'commission',
    modalKey: 'ManageProjectDataStoreItemsModal',
  },
  cover_letter: {
    dataContext: 'cover_letter',
    modalKey: 'ManageProjectDataStoreItemsModal',
  },
  event_calendars: {
    dataContext: 'event_calendars',
    modalKey: 'ManageEventCalendarsModal',
  },
  image: {
    dataContext: 'image',
    modalKey: 'SelectImageModal',
  },
  key_features: {
    dataContext: 'key_features',
    modalKey: 'ManageProjectDataStoreItemsModal',
  },
  project_combined_users: {
    dataContext: 'users',
    modalKey: 'ManageProjectUsersModal',
  },
  contacts: {
    dataContext: 'contacts',
    modalKey: 'ManageProjectContactsModal',
  },
  project_comparables: {
    dataContext: 'project_comparables',
    modalKey: 'ManageComparablesModal',
  },
  project_documents: {
    dataContext: 'project_documents',
    modalKey: 'ManageProjectAttachmentsModal',
  },
  project_lead_users: {
    dataContext: 'users',
    modalKey: 'ManageProjectUsersModal',
  },
  project_support_users: {
    dataContext: 'users',
    modalKey: 'ManageProjectUsersModal',
  },
  quotes: {
    dataContext: 'quotes',
    modalKey: 'ManageEntityQuotesModal',
  },
  reviews: {
    dataContext: 'reviews',
    modalKey: 'ManageProjectReviewsModal',
  },
  sale_details: {
    dataContext: 'sale_details',
    modalKey: 'ManageProjectDataStoreItemsModal',
  },
  project_details: {
    dataContext: 'project_details',
    modalKey: 'ManageProjectDataStoreItemsModal',
  },
  suburb_data: {
    dataContext: 'suburb_data',
    modalKey: 'GetSuburbDataModal',
  },
  support_users: {
    dataContext: 'users',
    modalKey: 'ManageProjectUsersModal',
  },
  target_audiences: {
    dataContext: 'target_audiences',
    modalKey: 'ManageProjectTargetAudiencesModal',
  },
  teams: {
    dataContext: 'teams',
    modalKey: 'ManageProjectTeamsModal',
  },
  users: {
    dataContext: 'users',
    modalKey: 'ManageProjectUsersModal',
  },
  video: {
    dataContext: 'video',
    modalKey: 'EditDigitalPageCustomDataModal',
  },
}

const getTargetDevice = (printMode) => {
  if (printMode) return 'print'
  if (isMobile) return 'mobile'
  if (isTablet) return 'tablet'
  return 'default'
}

const selectImage = (digitalPage, templatePageItemId, image, dispatch) => {
  const { updateDigitalPageItemImage: updateFn } = digitalPageActions

  const requestOptions = {
    ...defaultRequestOptions.digitalPage,
  }

  return dispatch(updateFn(digitalPage, templatePageItemId, image, requestOptions))
}

const autoSelectPage = (digitalTemplate, selectedPageId, dispatch) => {
  const { selectPageId: selectFn } = digitalTemplatePageActions

  const pages = digitalTemplate.pages || []
  const firstPageId = pages[0]

  if ((!selectedPageId || selectedPageId !== firstPageId) && firstPageId){
    dispatch(selectFn(firstPageId))
  }
}

const checkDebugMode = () => {
  const { debug } = queryString.parse(window.location.search)
  return debug === 'true'
}

const editDigitalPageItemCustomData = (digitalPage, itemPayload, callbacks) => {
  const { showEditDigitalPageItemCustomDataModal, updatePageItemCustomData } = callbacks || {}

  return new Promise((resolve, reject) => {
    if (showEditDigitalPageItemCustomDataModal){
      const payload = {
        callbacks: {
          updatePageItemCustomData,
        },
        digitalPage,
        ...itemPayload,
      }

      showEditDigitalPageItemCustomDataModal(payload)

      return resolve({ success: true, result: payload })
    }

    return reject(new Error('showEditDigitalPageItemCustomDataModal not defined in callbacks'))
  })
}

const editDigitalPageItemImage = (digitalPage, itemPayload, showSelectImageModal, dispatch) => new Promise((resolve, reject) => {
  if (showSelectImageModal){
    const payload = {
      callbacks: {
        selectImage: image => selectImage(digitalPage, itemPayload.id, image, dispatch),
      },
      digitalPage,
    }

    showSelectImageModal(payload)

    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showSelectImageModal not defined in callbacks'))
})

const manageDigitalPageFeature = (digitalPage, featureKey, address, callbacks, featureOptions) => new Promise((resolve, reject) => {
  const {
    loadDigitalPageShortcodeData, createAddress, updateAddress, updateDigitalPage,
  } = callbacks

  const dataLoopNestedContext = digObject(featureOptions, 'nestedContext')

  const updatedFeatureKey = dataLoopNestedContext === '.reviews' ? 'reviews' : featureKey

  const modalKey = modalKeys[updatedFeatureKey]?.modalKey || 'ManageDigitalPageFeaturesModal'
  const showModalFn = callbacks[`show${modalKey}`]

  if (modalKey && showModalFn){
    const payload = {
      address,
      callbacks: {
        loadShortcodeData: loadDigitalPageShortcodeData,
        createAddress,
        updateAddress,
        updateEntity: updateDigitalPage,
      },
      digitalPage,
      entity: digitalPage,
      featureOptions,
      updatedFeatureKey,
    }

    showModalFn(payload)

    return resolve({ success: true, result: payload })
  }

  return reject(new Error(`show${modalKey} not defined in callbacks`))
})

const submitFormResponse = (params) => {
  const {
    digitalPage, formState, shareLink, shareLinkToken, submitFn, uuid,
  } = params

  const requestOptions = {
    digital_page_id: digitalPage.id,
    share_link_id: shareLink.id,
    token: shareLinkToken,
    uuid,
  }

  return submitFn(formState, requestOptions)
}

const toggleCarousel = (key, index, setState) => {
  setState(state => ({
    ...state,
    [key]: !state[key],
    [`${key}SelectedIndex`]: index ? Number(index) : 0,
  }))
}

const toggleDigitalPageSidebar = (defaultSidebarState, showDigitalPageSidebar, setState, targetDevice) => {
  const showSidebar = (defaultSidebarState === 'sticky' && targetDevice === 'default') ? true : !showDigitalPageSidebar

  setState({ showDigitalPageSidebar: showSidebar })
}

const togglePreviewMode = (previewing, setState) => {
  const nextPreviewValue = !previewing

  setState({
    editing: !nextPreviewValue,
    previewing: nextPreviewValue,
  })
}

const updateCustomData = (digitalPage, id, changes, dispatch) => {
  const { updateDigitalPage: updateFn } = digitalPageActions
  const customData = digitalPage.data || {}

  if (id && Object.keys(changes).length){
    const updatedData = { ...customData }
    updatedData[id] = {
      ...updatedData[id],
      ...changes,
    }

    const updatedDigitalPage = {
      id: digitalPage.id,
      data: JSON.stringify(updatedData),
    }

    return dispatch(updateFn(updatedDigitalPage))
  }
}

const defaultState = {
  editing: false,
  previewing: false,
  showDigitalPageSidebar: false,
}

function useDigitalRenderer(options = {}){
  const {
    callbacks,
    callbacks: { showEditDigitalPageItemCustomDataModal, showSelectImageModal },
    digitalPageId,
    editing: initEditing,
    shareLink = {},
    shareLinkToken,
  } = options || {}

  const dispatch = useThunkDispatch()

  const [state, setState] = useSetState(defaultState)
  const {
    editing, previewing, showDigitalPageSidebar,
  } = state

  const [toggleableComponents, setToggleableComponents] = useState({})

  const selectedPageId = useSelector(reduxState => reduxState.digitalTemplatePages.selectedId)

  const entities = useSelector(reduxState => reduxState.entities)
  const {
    digitalPages, digitalTemplatePages, digitalTemplatePageSectionGroups, images,
  } = entities

  const digitalPagesReducer = useSelector(reduxState => reduxState.digitalPages)
  const { loadedShortcodeDataIds, loading } = digitalPagesReducer

  const currentUserPayload = useCurrentUser()
  const { currentUser } = currentUserPayload

  const digitalPage = digitalPages[digitalPageId] || {}
  const digitalTemplatePage = digitalTemplatePages[selectedPageId] || {}

  // Dont show loading if reloading shortcodes only
  const shouldShowLoading = !digitalTemplatePage.id && loading && !loadedShortcodeDataIds.includes(digitalPageId)

  const digitalPagePayload = useDigitalPage(digitalPage)
  const {
    callbacks: {
      acceptDigitalPage,
      editSectionSort,
      loadDigitalPageShortcodeData,
      resetDefaultImage,
      resetDefaultText,
      resetDisabledSections,
      resetSortedSections,
      updateDigitalPage,
      updatePageItemCustomData,
      updateShortcodeDataSubject,
    },
    completedFeatureKeys,
    digitalTemplate,
    project,
    shortcodeData,
  } = digitalPagePayload

  // Digital Template
  const { enabledFeatures } = getDigitalTemplateEnabledFeatures(digitalTemplate)

  // Sidebar
  const { sidebarTemplate } = useDigitalTemplateRelations(digitalTemplate)
  const sidebarTemplatePageId = digObject(sidebarTemplate, 'pages.0')
  const sidebarTemplatePage = digitalTemplatePages[sidebarTemplatePageId] || {}

  const sidebarOptions = digObject(digitalTemplate, 'options.sidebar', {})
  const { defaultState: defaultSidebarState } = sidebarOptions

  const printMode = isPrintMode()

  useEffect(() => {
    if ((defaultSidebarState === 'show' || (defaultSidebarState === 'sticky' && !isMobile)) && !printMode){
      setState({
        showDigitalPageSidebar: true,
      })
    }
  }, [defaultSidebarState])

  const { address } = useProjectRelations(project)
  const { callbacks: addressCallbacks } = useAddress(address)

  const previewImage = images[digitalTemplate.preview_image_id] || {}

  const {
    updatedEntities: { digitalTemplates: digitalTemplatesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const debugging = checkDebugMode()

  // Set initial editing mode
  useEffect(() => {
    setState({ editing: initEditing })
  }, [initEditing])

  // Load Digital Page
  useReduxAction(
    'digitalPages',
    'loadDigitalPage',
    {
      ...defaultRequestOptions.attachment,
      ...defaultRequestOptions.award,
      ...defaultRequestOptions.caseStudy,
      ...defaultRequestOptions.comparable,
      ...defaultRequestOptions.contact,
      ...defaultRequestOptions.digitalPage,
      ...defaultRequestOptions.digitalTemplate,
      ...defaultRequestOptions.digitalTemplatePage,
      ...defaultRequestOptions.formTemplate,
      ...defaultRequestOptions.image,
      ...defaultRequestOptions.organization,
      ...defaultRequestOptions.project,
      ...defaultRequestOptions.review,
      ...defaultRequestOptions.shareLink,
      ...defaultRequestOptions.targetAudience,
      ...defaultRequestOptions.team,
      ...defaultRequestOptions.user,
      token: shareLinkToken,
    },
    [digitalPageId],
    {
      dispatchAction: (action, requestOptions) => action(digitalPageId, requestOptions),
      shouldPerformFn: (entityReducer) => {
        const { loadedIds, loading: loadingDigitalPages } = entityReducer
        return digitalPageId && !loadedIds.includes(digitalPageId) && !loadingDigitalPages
      },
    },
  )

  const entityKey = `DigitalPage-${digitalPageId}-DigitalTemplate`

  useReduxAction(
    'digitalPages',
    'loadDigitalPageDigitalTemplate',
    {
      ...defaultRequestOptions.digitalTemplate,
      ...defaultRequestOptions.digitalTemplatePage,
      ...defaultRequestOptions.formTemplate,
      entityKey,
      token: shareLinkToken,
    },
    [digitalPageId],
    {
      dispatchAction: (action, requestOptions) => action(digitalPageId, requestOptions),
      shouldPerformFn: (entityReducer) => {
        const { loadedForKeys, loading: loadingDigitalPage } = entityReducer
        return digitalPageId && !loadedForKeys.includes(entityKey) && !loadingDigitalPage
      },
    },
  )

  // Load Template Fonts
  useDigitalTemplateFonts(digitalTemplate)

  // Autoselect Page
  useEffect(
    () => autoSelectPage(digitalTemplate, selectedPageId, dispatch),
    [digitalTemplate.id, digitalTemplatesUpdatedAt],
  )

  const [uuid] = useUniqueId({
    persistKey: window.location.pathname,
  })

  // Data Store
  const dataStoreItemPayload = useDigitalPageDataStoreItems({
    callbacks: {
      loadDigitalPageShortcodeData: () => loadDigitalPageShortcodeData({ token: shareLinkToken }),
    },
    currentUser,
    digitalPage,
    digitalTemplate,
    project,
    shareLinkToken,
    uuid,
  })

  const {
    callbacks: { createOrUpdateDataStoreItem },
    dataStoreItems,
  } = dataStoreItemPayload

  // Galleries
  const galleries = digObject(digitalTemplate, 'options.galleries', {})

  // Quote
  const quotePayload = useQuote({})
  const {
    callbacks: { downloadPdfFromShareLink },
  } = quotePayload

  // Analytics and Link Tracking
  const analyticsTrackerPayload = useAnalyticsTracker({
    completedFeatureKeys,
    digitalPage,
    digitalTemplatePage,
    digitalTemplatePageSectionGroups,
    shareLink,
    trackScrollPosition: true,
    uuid,
  })

  const {
    callbacks: { logAnalyticsEvent },
  } = analyticsTrackerPayload

  // Only data in the shortcodeData object will be used to render
  // so if you require something else merge it in

  const payload = {
    callbacks: {
      acceptDigitalPage: () => acceptDigitalPage(shareLinkToken, { include_digital_page_shortcode_data: true }),
      createOrUpdateDataStoreItem,
      downloadPdfFromShareLink,
      editSectionSort,
      editDigitalPageItemImage: itemPayload => editDigitalPageItemImage(digitalPage, itemPayload, showSelectImageModal, dispatch),
      editDigitalPageItemCustomData: itemPayload => editDigitalPageItemCustomData(digitalPage, itemPayload, {
        showEditDigitalPageItemCustomDataModal,
        updatePageItemCustomData,
      }),
      loadDigitalPageShortcodeData,
      logAnalyticsEvent,
      manageDigitalPageFeature: (featureKey, featureOptions) => manageDigitalPageFeature(
        digitalPage,
        featureKey,
        address,
        {
          ...addressCallbacks,
          ...callbacks,
          loadDigitalPageShortcodeData,
          updateDigitalPage,
        },
        featureOptions,
      ),
      resetDefaultImage,
      resetDefaultText,
      resetDisabledSections,
      resetSortedSections,
      submitFormResponse: (formState, submitFn) => submitFormResponse({
        digitalPage,
        formState,
        shareLink,
        shareLinkToken,
        submitFn,
        uuid,
      }),
      toggleCarousel: (key, index) => toggleCarousel(key, index, setToggleableComponents),
      toggleDigitalPageSidebar: () => toggleDigitalPageSidebar(defaultSidebarState, showDigitalPageSidebar, setState, getTargetDevice(printMode)),
      togglePreviewMode: () => togglePreviewMode(previewing, setState),
      updateCustomData: (id, changes) => updateCustomData(digitalPage, id, changes, dispatch),
      updateDigitalPage,
      updateShortcodeDataSubject,
      ...callbacks,
    },
    currentUser,
    customData: digitalPage.data || {},
    dataStoreItems,
    debugging,
    digitalPage,
    digitalTemplate,
    digitalTemplatePage,
    editing,
    enabledFeatures,
    globalState: state,
    isMobileDevice,
    isShareLink: !!shareLinkToken,
    loading: shouldShowLoading,
    previewImage,
    previewing,
    printMode,
    project,
    shareLinkToken,
    shortcodeData: {
      ...shortcodeData,
      editing,
      galleries,
      uuid,
      printMode,
      targetDevice: getTargetDevice(printMode),
    },
    showDigitalPageSidebar,
    sidebarOptions,
    sidebarTemplatePage,
    targetDevice: getTargetDevice(printMode),
    toggleableComponents,
    uuid,
  }

  // eslint-disable-next-line no-console
  if (debugging) console.log(payload)

  return payload
}

export default useDigitalRenderer
