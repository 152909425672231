export default {
  default: {
    organizationComparablesTitle: '{global.organizationType.shortName.singular} Comparables',
    buttons: {
      addComparable: 'Add Comparable',
    },
    marketComparablesTitle: 'Market Comparables',
    title: 'Comparables',
    VideoModule: {
      title: 'Comparables',
      paragraph: 'Adding comparables into your digital proposals and the data providers Engage integrates with.',
    },
  },
}
