const state = {
  document_template_recipient_id: null,
  id: null,
  owner_id: null,
  owner_type: 'DocumentTemplate',
  role_key: '',
  sort: null,
  subject_id: null,
  subject_type: '',
  user_type_id: '',
}

export const requiredFields = [
  { key: 'owner_id' },
  { key: 'owner_type' },
  { key: 'role_key' },
]

export default state
