import React from 'react'

import useReduxAction from '@hooks/useReduxAction'
import useOrganizationSelector from '@hooks/useOrganizationSelector'

import defaultRequestOptions from '@sections/Client/packs/Admin/defaultRequestOptions'

import PageContent from './components/PageContent'

const CustomFieldSetList = () => {
  const { selectedOrganization } = useOrganizationSelector()
  const entityKey = `Organization${selectedOrganization.id}`

  useReduxAction(
    'customFieldSets',
    'loadCustomFieldSets',
    {
      ...defaultRequestOptions.customFieldSet,
      brand_id: selectedOrganization.brand_id,
      entityKey,
      organization_id: selectedOrganization.id,
    },
    [selectedOrganization.id],
    {
      shouldPerformFn: ({ loadedForKeys }) => selectedOrganization.id && !loadedForKeys.includes(entityKey),
    },
  )

  return (
    <PageContent selectedOrganization={selectedOrganization} />
  )
}

export default CustomFieldSetList
