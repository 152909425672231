import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, ListItem, Text,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import useIntegration from '@hooks/useIntegration'
import useIntegrationPlatform from '@hooks/useIntegrationPlatform'
import useOrganizationSelector from '@hooks/useOrganizationSelector'

import IntegrationPlatformLogo from '@components/IntegrationPlatformLogo'

import Integration from '../Integration'
import localizedStrings from './localizedStrings'

const IntegrationPlatform = (props) => {
  const {
    groupedIntegration: { id, integrations },
  } = props

  const { integrationPlatforms } = useSelector(reduxState => reduxState.entities)
  const integrationPlatform = integrationPlatforms[id] || {}
  const { key, title } = integrationPlatform

  const { integrationScope, isOauth, supportedFeaturesCount } = useIntegrationPlatform(integrationPlatform)

  const { selectedOrganization } = useOrganizationSelector()

  const initIntegration = {
    integration_platform_id: id,
    owner_id: selectedOrganization.id,
    owner_type: 'Organization',
    integration_scope: integrationScope,
  }

  const {
    callbacks: { authorizeIntegration, createOrEditIntegration },
  } = useIntegration(initIntegration)

  const {
    callbacks: { formatCount },
    strings,
  } = useLocalization(localizedStrings)

  if (integrations.length){
    return (
      <Box variant="white" marginBottom="large" flexDirection="column">
        <Box alignItems="center">
          <IntegrationPlatformLogo
            backgroundSize="contain"
            integrationPlatformKey={key}
            height={68}
            width={100}
          />

          <ListItem
            boxProps={{
              border: '1px solid',
              borderTop: 0,
              borderRight: 0,
              padding: 'large',
              alignItems: 'center',
            }}
            disableHover
          >
            <Box flexDirection="column" justifyContent="center">
              <Text fontSize="small">{title}</Text>
              <Box alignItems="center" fontSize="small" marginTop="medium">
                <Text color="bodyFontLightColor">
                  {formatCount('supportedFeatures', { count: supportedFeaturesCount })}
                </Text>
              </Box>
            </Box>
          </ListItem>
        </Box>

        <Box borderTop="1px solid" borderColor="lineColor" flexDirection="column" maxHeight={300} overflow="auto">
          {integrations.map(integration => (
            <Integration key={integration.id} integration={integration} />
          ))}
        </Box>

        {integrationScope === 'user' && (
          <Box
            borderTop="1px solid"
            borderColor="lineColor"
            padding="large"
            style={{ alignItems: 'center', justifyContent: 'space-between' }}
          >
            <Box marginLeft="auto" width="auto">
              <Button
                buttonStyle="secondaryEdit"
                icon={<FontAwesomeIcon icon={faPlus} />}
                onClick={isOauth ? authorizeIntegration : createOrEditIntegration}
                size="medium"
                style={{ width: 'auto' }}
              >
                {strings.createButton || 'Add Integration'}
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    )
  }

  return null
}

IntegrationPlatform.propTypes = {
  groupedIntegration: PropTypes.shape({
    id: PropTypes.number.isRequired,
    integrations: PropTypes.array,
  }).isRequired,
}

export default IntegrationPlatform
