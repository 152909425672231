import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

import { useModals, useSetState } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'
import PageContext from '@contexts/pageContext'

import CreateEventCalendarModal from '@modals/CreateEventCalendarModal'

import defaultRequestOptions from '@sections/Client/packs/Project/defaultRequestOptions'

import generateRedirectUrl from '@functions/generateRedirectUrl'

import PageContent from './components/PageContent'

const createEventCalendar = (calendarParams, createFn) => {
  createFn(
    calendarParams,
    { ...defaultRequestOptions.eventCalendar, ...defaultRequestOptions.eventCalendarTemplate },
  ).then(({ success, errors, redirectUrl }) => {
    if (!success){
      toast.warn(errors[0])
      return
    }

    if (redirectUrl){
      window.location.href = redirectUrl
    }
  })
}

const callbacks = (component, props, setState) => {
  const componentCallbacks = {
    CreateEventCalendarModal: {
      closeModal: () => setState({ showCreateEventCalendarModal: false }),
      createEventCalendar: (calendarParams, createFn) => createEventCalendar(calendarParams, createFn),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  showCreateEventCalendarModal: false,
}

const Events = (props) => {
  const { project, projectId } = props

  const [state, setState] = useSetState(defaultState)
  const { showCreateEventCalendarModal } = state

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const pageContext = useMemo(() => ({
    callbacks: {
      showCreateEventCalendarModal: (payload) => {
        setModalData('CreateEventCalendarModal', payload)
        setState({ showCreateEventCalendarModal: true })
      },
    },
  }), [])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent projectId={projectId} project={project} />

        <CreateEventCalendarModal
          callbacks={callbacks('CreateEventCalendarModal', props, setState)}
          showModal={showCreateEventCalendarModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

Events.propTypes = {
  projectId: PropTypes.number,
  project: PropTypes.object.isRequired,
}

export default Events
