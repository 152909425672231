import React from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import { faChartPieAlt } from '@fortawesome/pro-light-svg-icons'

import useChartData from '@hooks/useChartData'
import { engagementStatusThemes } from '@hooks/useEngagementStatus'
import useLocalization from '@hooks/useLocalization'
import useProject from '@hooks/useProject'

import { Box, DashboardModule, Link } from '@campaignhub/suit-theme'

import AreaChart from '@components/charts/AreaChart'
import BarChart from '@components/charts/BarChart'
import HalfPieChart from '@components/charts/HalfPieChart'

import analyticsImageUrl from './assets/analytics.svg'

import localizedStrings from './localizedStrings'

export const icon = faChartPieAlt

const getChartDetails = (chartKey, projectId, strings) => {
  const chartDetails = {
    engagement_per_page_view: {
      title: strings.chartKeyValues?.engagementPerPageView || 'Engagement per Page View',
      ChartComponent: AreaChart,
      excludedDataKeys: ['read_percentage', 'name', 'seconds_on_page'],
    },
    engagement_status_counts: {
      chartProps: projectId
        ? null
        : {
          chartOptions: {
            barGap: 15,
            barSize: 8,
            chartHeight: 108,
            disableGrid: true,
            layout: 'vertical',
          },
          height: 108,
        },
      component: projectId ? HalfPieChart : BarChart,
      excludedDataKeys: ['name'],
      title: strings.chartKeyValues?.engagementPercentage || 'Engagement Status Percentage',
    },
  }

  return chartDetails[chartKey] || {}
}

export const configOptions = strings => ({
  title: {
    component: 'input',
    key: 'title',
    label: strings.label?.title || 'Title',
    optionKey: 'customization',
  },
  chart_key: {
    component: 'select',
    key: 'chart_key',
    label: strings.chartKey?.label || 'Chart Type',
    optionKey: 'customization',
    values: [
      {
        key: 'engagement_per_page_view',
        label: strings.chartKeyValues?.engagementPerPageView || 'Engagement per Page View',
      },
      {
        key: 'engagement_status_counts',
        label: strings.chartKeyValues?.engagementPercentage || 'Engagement Status Percentage',
      },
    ],
  },
})

const themes = {
  engagement_status_counts: engagementStatusThemes,
}

const Performance = (props) => {
  const { customOptions, editing } = props

  const {
    customization,
    queryParams: { entity_id, entity_type },
  } = customOptions

  const { chart_key, title } = customization || {}

  const { projectId } = useParams()

  const {
    urls: { analyticsUrl },
  } = useProject({ id: projectId })

  const { strings } = useLocalization(localizedStrings)

  const chartDetailsPayload = getChartDetails(chart_key, projectId, strings)
  const {
    excludedDataKeys, title: chartTitle, chartProps, component,
  } = chartDetailsPayload

  const ChartComponent = component || AreaChart

  const dataPayload = useChartData(chart_key, {
    entityId: entity_id,
    entityType: entity_type,
    excludedDataKeys,
    theme: themes[chart_key],
  })
  const { dataArray, loading } = dataPayload

  return (
    <DashboardModule
      boxProps={{ marginBottom: 0 }}
      editing={editing}
      headerRight={
        projectId && (
          <Link href={analyticsUrl} textProps={{ fontSize: 'small' }}>
            {strings.manage || 'Manage'}
          </Link>
        )
      }
      loading={loading}
      title={title || chartTitle || 'Analytics'}
    >
      {!loading && !dataArray.length && (
        <DashboardModule.BlankState imageUrl={analyticsImageUrl}>
          <DashboardModule.BlankState.Title>
            {strings.blankState?.title || 'No Chart Data to Display'}
          </DashboardModule.BlankState.Title>

          <DashboardModule.BlankState.Paragraph>
            {strings.blankState?.paragraph || 'Once pages have been viewed chart data will be recorded and displayed.'}
          </DashboardModule.BlankState.Paragraph>
        </DashboardModule.BlankState>
      )}

      {!loading && !!dataArray.length && (
        <Box flexDirection="column" flexWrap="wrap" padding="medium">
          <ChartComponent dataPayload={dataPayload} width="100%" {...chartProps} />
        </Box>
      )}
    </DashboardModule>
  )
}

Performance.propTypes = {
  customOptions: PropTypes.object.isRequired,
  editing: PropTypes.bool,
}

export default Performance
