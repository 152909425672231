export type DigitalTemplatePageModel = {
  digital_template_id: number,
  id: number,
  layout_file: object,
  sort: number,
  title: string,
}

const state = {
  digital_template_id: null,
  id: null,
  layout_file: {},
  sort: '',
  title: '',
}

export default state
