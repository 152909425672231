type HandleErrorParams = {
  response?: {
    data?: {
      errors?: string[],
    },
  },
}

type stringifyOptionValuesParams = {
  options: {},
}

export const stringifyOptionValues = (options: stringifyOptionValuesParams) => {
  const optionsBody = Object.keys(options || {}).reduce((acc, key) => {
    const value = options[key]
    acc[key] = value === true ? 'true' : value

    return acc
  }, {})

  return optionsBody
}

export const handleError = (params: HandleErrorParams = {}) => {
  const { response } = params
  console.log('Raw Error', response?.data)
  return response?.data?.errors || ['Something went wrong']
}

export default handleError
