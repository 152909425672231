import { denormalize, normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities, deleteEntity } from '@redux/entities'

import entitySchema from '@redux/entitySchema'

import { removeNullNodes, findEditableImageItemIds } from '@functions/digitalTemplate'
import { updateDigitalTemplatePage, deleteDigitalTemplateItemAttachments } from '@redux/modules/digitalTemplatePage'

const CHANGE_ATTRIBUTE = 'realbase/digitalTemplateBuilder/editor/CHANGE_ATTRIBUTE'
const COPY_CANVAS_ITEM = 'realbase/digitalTemplateBuilder/editor/COPY_CANVAS_ITEM'
const SELECT_CANVAS_ITEM = 'realbase/digitalTemplateBuilder/editor/SELECT_CANVAS_ITEM'

// Initial State
const initialState = {
  copiedCanvasItem: {},
  selectedAttributesPanel: 'details',
  selectedCanvasItem: {},
}

// Actions
export function changeAttribute(name, value){
  return {
    type: CHANGE_ATTRIBUTE,
    name,
    value,
  }
}

export function copyCanvasItem(item){
  return {
    type: COPY_CANVAS_ITEM,
    item,
  }
}

export function selectCanvasItem(item){
  return {
    type: SELECT_CANVAS_ITEM,
    item,
  }
}

// Helpers
function getCurrentPage(state){
  const { digitalTemplatePages } = state.entities
  const { selectedId } = state.digitalTemplatePages

  return digitalTemplatePages[selectedId]
}

// Action Creators
export function updateCanvasItem(entityType, entity, options = {}){
  return (dispatch, getState) => {
    // Update the local store
    const normalizedJson = normalize(entity, entitySchema[entityType])
    dispatch(updateEntities(normalizedJson))

    // Get Current Page
    const state = getState()
    const templatePage = getCurrentPage(state)

    if (templatePage){
      // Denormalize the page so we can update
      const denormalizedPage = denormalize(templatePage, Schemas.DIGITAL_TEMPLATE_PAGE, state.entities)

      const updatedPage = {
        id: templatePage.id,
        layout_data: JSON.stringify({
          section_groups: denormalizedPage.section_groups ? removeNullNodes(denormalizedPage.section_groups) : [],
        }),
      }

      // Update DB
      dispatch(updateDigitalTemplatePage(updatedPage, options))
    }
  }
}

export function deleteCanvasItem(entityType, entity){
  return (dispatch, getState) => {
    // Update the local store
    const normalizedJson = normalize(entity, entitySchema[entityType])
    dispatch(deleteEntity(normalizedJson))

    // Get Current Page
    const state = getState()
    const templatePage = getCurrentPage(state)

    if (templatePage){
      // Denormalize the page so we can update
      const denormalizedPage = denormalize(templatePage, Schemas.DIGITAL_TEMPLATE_PAGE, state.entities) || {}
      const { section_groups } = denormalizedPage
      const sectionGroups = section_groups || []
      const updatedSectionGroups = removeNullNodes(sectionGroups)
      const digitalTemplateItemIds = findEditableImageItemIds(entityType, entity)

      const updatedPage = {
        id: templatePage.id,
        layout_data: JSON.stringify({
          section_groups: updatedSectionGroups,
        }),
      }

      // Update DB
      dispatch(updateDigitalTemplatePage(updatedPage))
      return dispatch(deleteDigitalTemplateItemAttachments({ id: templatePage.id }, digitalTemplateItemIds))
    }
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  const { type } = action

  switch (type){
    case CHANGE_ATTRIBUTE:
      return {
        ...state,
        [action.name]: action.value,
      }
    case COPY_CANVAS_ITEM:
      return {
        ...state,
        copiedCanvasItem: { ...action.item },
      }
    case SELECT_CANVAS_ITEM:
      return {
        ...state,
        selectedCanvasItem: { ...action.item },
      }
    default:
      return state
  }
}
