import React from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'

import {
  Box, DashboardModule, FileUploadZone, Grid, Text,
} from '@campaignhub/suit-theme'

import useImages from '@hooks/useImages'
import useLocalization from '@hooks/useLocalization'
import useOrganizationSelector from '@hooks/useOrganizationSelector'
import useUppyImageUpload from '@hooks/useUppyImageUpload'

import UserImage from './UserImage'
import localizedStrings from './localizedStrings'

const UserImages = (props) => {
  const { user } = props

  const { selectedOrganization } = useOrganizationSelector()

  const imageSizes = ['thumb_180x180', 'thumb_660x360']

  const string = imageSizes.length
    ? queryString.stringify({ include_image_sizes: imageSizes }, { arrayFormat: 'bracket' })
    : ''

  const options = {
    endpoint: `images.json?${string}`,
    imageType: 'photo',
    owner_id: selectedOrganization.id,
    owner_type: 'Organization',
    subject_id: user.id,
    subject_type: 'User',
  }

  const {
    callbacks: { updateDefaultImage },
    hasImages,
    sortedImages,
  } = useImages({ subjectId: user.id, subjectType: 'User' })

  const uppy = useUppyImageUpload(options, ['photo'])

  const { strings } = useLocalization(localizedStrings)

  return (
    <DashboardModule boxProps={{ marginBottom: 'large' }} title={strings.title || 'User Images'}>
      <Box flexDirection="column" padding="large">
        <Text color="faintGrey" fontSize="xsmall" marginBottom={[0, 0, 'large']} lineHeight={1.3}>
          {strings.description
            || " Your user images will be displayed across Engage so team members can easily identify you and, in the case of users and team members who appear in marketing, they will be used across print and digital products. If your user images are going to be printed make sure they're the largest size possible."}
        </Text>

        <FileUploadZone uppy={uppy} />

        {hasImages && (
          <Grid
            gridAutoRows="1fr"
            gridColumnGap="medium"
            gridRowGap="medium"
            gridTemplateColumns="repeat(auto-fill, minmax(100px, 1fr))"
            gridTemplateRows="repeat(auto-fill, minmax(100px, 1fr))"
            marginTop="large"
          >
            {sortedImages.map((image, index) => (
              <UserImage
                key={image.id}
                callbacks={{ updateDefaultImage }}
                isDefault={index === 0}
                image={image}
              />
            ))}
          </Grid>
        )}
      </Box>
    </DashboardModule>
  )
}

UserImages.propTypes = {
  user: PropTypes.object.isRequired,
}

export default UserImages
