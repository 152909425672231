import { useEffect } from 'react'
import PropTypes from 'prop-types'

import { faUser, faBuilding } from '@fortawesome/pro-light-svg-icons'

import {
  Button, FormField, LoadingModule, SegmentedControl, SidebarModal, SidebarNoticeBox,
} from '@campaignhub/suit-theme'

import { useFilterString, useSetState } from '@campaignhub/react-hooks'

import useEventCalendars from '@hooks/useEventCalendars'
import useLocalization from '@hooks/useLocalization'
import useReduxAction from '@hooks/useReduxAction'
import useOrganizationsUser from '@hooks/useOrganizationsUser'

import defaultRequestOptions from '@sections/Client/defaultRequestOptions'
import EventCalendarTemplate from './EventCalendarTemplate'

import localizedStrings from './localizedStrings'

const getFilteredTemplates = (params) => {
  const {
    currentUserEventCalendarTemplates,
    filterKey,
    filteredEventCalendars,
    isCurrentUserAdminForOrganization,
    nonCurrentUserEventCalendarTemplates,
  } = params

  if (filterKey === 'organization' || (filterKey === 'all' && !isCurrentUserAdminForOrganization)){
    return nonCurrentUserEventCalendarTemplates
  }

  if (filterKey === 'user') return currentUserEventCalendarTemplates

  return filteredEventCalendars
}

const defaultState = {
  filterKey: 'all',
}

const CreateFromTemplate = (props) => {
  const [state, setState] = useSetState(defaultState)
  const { filterKey } = state

  const {
    callbacks: { selectScreen, selectTemplate },
    eventCalendarFormPayload: { entityState: { owner_id, owner_type } },
  } = props

  const { loading } = useReduxAction(
    'eventCalendarTemplates',
    'loadEventCalendarTemplates',
    defaultRequestOptions.eventCalendarTemplate,
  )

  const { onChange, value } = useFilterString()

  const eventCalendarsPayload = useEventCalendars({
    filters: {
      isSavedTemplate: true,
      ownerId: owner_id,
      ownerType: owner_type,
      string: value,
    },
  })

  const {
    currentUserEventCalendarTemplates,
    filteredEventCalendars,
    hasEventCalendars,
    hasCurrentUserEventCalendarTemplates,
    hasNonCurrentUserEventCalendarTemplates,
    nonCurrentUserEventCalendarTemplates,
  } = eventCalendarsPayload

  const { isCurrentUserAdminForOrganization } = useOrganizationsUser()

  const filteredTemplates = getFilteredTemplates({
    currentUserEventCalendarTemplates,
    filterKey,
    filteredEventCalendars,
    nonCurrentUserEventCalendarTemplates,
  })

  useEffect(() => {
    if (hasCurrentUserEventCalendarTemplates && !isCurrentUserAdminForOrganization){
      setState({ filterKey: 'user' })
    }
  }, [currentUserEventCalendarTemplates.length, isCurrentUserAdminForOrganization])

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal.Content hasFooter={false}>
      <LoadingModule loading={loading} times={3} />

      {hasCurrentUserEventCalendarTemplates && hasNonCurrentUserEventCalendarTemplates
      && !isCurrentUserAdminForOrganization && (
        <SegmentedControl
          activeButtonKey={filterKey}
          boxProps={{ marginBottom: 'large' }}
          buttons={[
            { key: 'user', icon: faUser, label: 'My Templates' },
            { key: 'organization', icon: faBuilding, label: 'Agency Templates' },
          ]}
          onClick={key => setState({ filterKey: key })}
        />
      )}

      {!loading && (
        <>
          <FormField
            boxProps={{ marginBottom: 'large' }}
            direction="column"
            label={strings.formFieldLabels?.filter || 'Filter Templates'}
          >
            <input onChange={onChange} value={value} />
          </FormField>

          <FormField
            direction="column"
            label={strings.formFieldLabels?.savedTemplates || 'Saved Calendar Templates'}
          >
            {filteredTemplates.map(template => (
              <EventCalendarTemplate
                key={template.id}
                callbacks={{ selectTemplate: () => selectTemplate(template) }}
                eventCalendarTemplate={template}
              />
            ))}
          </FormField>

          {!hasEventCalendars && (
            <SidebarNoticeBox boxProps={{ marginBottom: 'medium' }}>
              <SidebarNoticeBox.Title>{strings.blankState?.title || 'No Saved Templates'}</SidebarNoticeBox.Title>

              <SidebarNoticeBox.Paragraph>
                {strings.blankState?.paragraph || 'Create a new calendar then save it as a template.'}
              </SidebarNoticeBox.Paragraph>
            </SidebarNoticeBox>
          )}

          <FormField
            direction="column"
            label={strings.formFieldLabels?.moreOptions || 'More Options'}
            marginTop="medium"
          >
            <Button
              buttonStyle="secondaryUtility"
              onClick={() => selectScreen('')}
              size="medium"
              width="100%"
            >
              {strings.buttons?.goBack || 'Go Back'}
            </Button>
          </FormField>
        </>
      )}
    </SidebarModal.Content>
  )
}

CreateFromTemplate.propTypes = {
  callbacks: PropTypes.shape({
    selectScreen: PropTypes.func.isRequired,
    selectTemplate: PropTypes.func.isRequired,
  }).isRequired,
  eventCalendarFormPayload: PropTypes.shape({
    entityState: PropTypes.object.isRequired,
  }).isRequired,
}

export default CreateFromTemplate
