import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@campaignhub/suit-theme'

import CustomMobile from '@components/CustomMobile'
import CustomEmail from '@components/CustomEmail'

const addCustomRecipient = (recipient, props) => {
  const { callbacks: { addRecipient } } = props

  addRecipient(recipient)
}

const CustomRecipientInputs = (props) => {
  const { recipientLimitReached, showCustomEmail, showCustomMobile } = props

  return (
    <Box flexDirection="column">
      {showCustomMobile && (
        <CustomMobile
          boxProps={{ marginTop: 'medium' }}
          callbacks={{
            addCustomMobile: ({ countryCallingCode, mobile }) => addCustomRecipient(
              { country_calling_code: countryCallingCode, mobile, type: 'sms' },
              props,
            ),
          }}
          disabled={recipientLimitReached}
        />
      )}

      {showCustomEmail && (
        <CustomEmail
          boxProps={{ marginTop: 'medium' }}
          callbacks={{
            addCustomEmail: email => addCustomRecipient({ email, type: 'email' }, props),
          }}
          disabled={recipientLimitReached}
        />
      )}
    </Box>
  )
}

CustomRecipientInputs.propTypes = {
  recipientLimitReached: PropTypes.bool,
  showCustomEmail: PropTypes.bool,
  showCustomMobile: PropTypes.bool,
}

export default CustomRecipientInputs
