import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'

import groupAndSortTypes from '@functions/groupAndSortTypes'

const watchEntityKeys = [
  'projectTypes',
]

function useProjectTypes(options = {}){
  const { performHttpRequests } = options || {}

  const {
    updatedEntities: {
      projectTypes: projectTypesUpdatedAt,
    },
  } = useWatchEntityUpdates(watchEntityKeys)

  useReduxAction('projectTypes', 'loadProjectTypes', {}, [performHttpRequests], {
    shouldPerformFn: (entityReducer) => {
      const { errors, loaded, loading } = entityReducer
      return performHttpRequests && !loaded && !loading && !errors.length
    },
  })

  const { projectTypes } = useSelector(reduxState => reduxState.entities)

  const groupedProjectTypes = useMemo(() => {
    const grouped = groupAndSortTypes(projectTypes)
    return Object.values(grouped)
  }, [projectTypesUpdatedAt])

  const { loaded, loading } = useSelector(reduxState => reduxState.projectTypes)

  return {
    groupedProjectTypes,
    loaded,
    loading,
    projectTypes,
  }
}

export default useProjectTypes
