import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  digObject, matchFilterNumber, modifyGroupedIdsSort, sortArrayBy, toggleArray,
} from '@campaignhub/javascript-utils'

import { useSetState, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'

const watchEntityKeys = ['attachments']

export const defaultRequestOptions = {
  assetLibrary: {
    include_asset_library_default_image: true,
    include_asset_library_images: true,
  },
  comparable: {
    include_comparable_address: true,
    include_comparable_default_image: true,
  },
  image: {
    include_image_sizes: ['thumb_90x90', 'thumb_200x120', 'thumb_660x360'],
  },
  organization: {
    include_organization_default_image: true,
    include_organization_images: true,
  },
  user: {
    include_user_default_image: true,
    include_user_images: true,
  },
}

const modifyAttachmentSort = (fromIndex, toIndex, payload, selectedAttachmentIds, setState) => {
  const groupedIds = modifyGroupedIdsSort(fromIndex, toIndex, payload, selectedAttachmentIds)

  return setState({
    selectedAttachmentIds: groupedIds.selectedAttachmentIds,
  })
}

const filterEntityAttachments = (selectedAttachmentIds, entityType, entityId, attachments) => {
  const filtered = Object.values(attachments).filter((attachment) => {
    const { subject_id, subject_type } = attachment

    const subjectIdMatch = matchFilterNumber(subject_id, entityId)
    const subjectTypeMatch = subject_type === entityType
    const selectedAttachmentMatch = !selectedAttachmentIds.includes(attachment.id)

    return subjectIdMatch && subjectTypeMatch && selectedAttachmentMatch
  })

  return filtered
}
const getSelectedEntity = (entities, entityType, entityId) => {
  const {
    projects, assetLibraries,
  } = entities

  if (entityType === 'AssetLibrary'){
    return assetLibraries[entityId] || {}
  }

  if (entityType === 'Project'){
    return projects[entityId] || {}
  }

  return {}
}

const toggleDigitalPageAttachment = (attachmentId, state, setState) => {
  const { selectedAttachmentIds } = state
  const updatedAttachmentIds = toggleArray(selectedAttachmentIds, attachmentId)

  setState({ selectedAttachmentIds: updatedAttachmentIds })
}

const selectEntity = (entity, setState) => {
  const { id, type } = entity

  setState({
    selectedEntityId: id,
    selectedEntityType: type,
  })
}

const defaultState = {
  selectedAttachmentIds: [],
  selectedEntityAttachment: {},
  selectedEntityId: null,
  selectedEntityType: '',
}

function useManageDigitalPageAttachments(options = {}){
  const { digitalPage, performHttpRequests } = options
  const { owner_id, owner_type } = digitalPage

  const {
    updatedEntities: { attachments: attachmentsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys, { customUseSelectorFn: useSelector })

  const [state, setState] = useSetState(defaultState)
  const {
    selectedAttachmentIds, selectedEntityAttachment, selectedEntityId, selectedEntityType,
  } = state

  const digitalPageAttachmentIds = digObject(digitalPage, 'options.project_document_ids', [])

  const entities = useSelector(reduxState => reduxState.entities)
  const { attachments, attachments: { loading } } = entities

  const selectedEntity = getSelectedEntity(entities, selectedEntityType, selectedEntityId)

  useReduxAction(
    'assetLibraries',
    'loadAssetLibraries',
    { ...defaultRequestOptions.assetLibrary },
    [selectedEntityType],
    {
      shouldPerformFn: (entityReducer) => {
        const { loaded, errors } = entityReducer
        return selectedEntityType === 'AssetLibrary' && !loading && !loaded && !errors.length
      },
    },
  )

  const entityKey = `Organization${owner_id}`

  useReduxAction(
    'attachments',
    'loadAttachments',
    {
      owner_id,
      owner_type,
      subject_type: selectedEntityType,
    },
    [selectedEntityType],
    {
      shouldPerformFn: (entityReducer) => {
        const {
          errors, loadedForKeys,
        } = entityReducer

        return performHttpRequests && !loading && !errors.length && !loadedForKeys.includes(entityKey)
      },
    },
  )

  // Filter Entity attachments
  const filteredEntityAttachments = useMemo(() => {
    const filtered = filterEntityAttachments(selectedAttachmentIds, selectedEntityType, selectedEntityId, attachments)
    const sorted = sortArrayBy(filtered, 'asc', 'sort')

    return sorted
  }, [selectedAttachmentIds, selectedEntityType, selectedEntityId, attachmentsUpdatedAt])

  useEffect(() => {
    if (digitalPage.id){
      setState({
        selectedAttachmentIds: digitalPageAttachmentIds,
        selectedEntityId: digitalPage.subject_id,
        selectedEntityType: digitalPage.subject_type,
      })
    }
  }, [digitalPage.id])

  const hasFilteredAttachments = !!selectedAttachmentIds.length

  return {
    attachments,
    callbacks: {
      modifyAttachmentSort,
      toggleDigitalPageAttachment: attachmentId => toggleDigitalPageAttachment(attachmentId, state, setState),
      selectEntity: library => selectEntity(library, setState),
      setState,
    },
    filteredEntityAttachments,
    hasFilteredAttachments,
    loading,
    selectedAttachmentIds,
    selectedEntity,
    selectedEntityAttachment,
    selectedEntityId,
    selectedEntityType,
  }
}

export default useManageDigitalPageAttachments
