import { useContext } from 'react'

import { faFileAlt } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Columns, LoadingModule, PageHeader,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import { generateUrls } from '@hooks/useProject'
import useFocusMode from '@hooks/useFocusMode'
import useLocalization from '@hooks/useLocalization'
import useQuotes from '@hooks/useQuotes'
import useVideoModules from '@hooks/useVideoModules'

import VideoModule from '@components/VideoModule'

import { ProjectModel } from '@models/project'

import QuotesBlankState from '../QuotesBlankState'
import QuoteListItem from '../QuoteListItem'

import localizedStrings from './localizedStrings'

interface PageContentProps {
  projectId: number,
  project: ProjectModel,
}

const PageContent = (props: PageContentProps) => {
  const { projectId, project } = props

  const quotesPayload = useQuotes({
    filters: {
      subjectId: project.id,
      subjectType: 'Project',
    },
    performHttpRequests: !!project.id,
  })

  const { filteredQuotes, hasQuotes, loading } = quotesPayload

  const pageContext = useContext(PageContext)
  const {
    callbacks: { showCreateQuoteModal },
  } = pageContext

  const videoModulePayload = useVideoModules({ key: 'projectQuotes' })
  const { videosPayload } = videoModulePayload

  const { quotesUrl } = generateUrls({ id: projectId })

  const { enabled: focusModeEnabled } = useFocusMode()
  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      <PageHeader
        actionContent={(
          <Button buttonStyle="primaryCreate" onClick={() => showCreateQuoteModal()} size="medium" width="auto">
            {strings.createButton || 'Create'}
          </Button>
        )}
        activeTabBarItemKey="quotes"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        nestedNavigation
        offset={{ left: focusModeEnabled ? 0 : undefined, top: focusModeEnabled ? 0 : undefined }}
        tabBarItems={[
          {
            href: quotesUrl,
            icon: faFileAlt,
            key: 'quotes',
            title: strings.title || 'Quotes',
          },
        ]}
        title={strings.title || 'Quotes'}
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && !hasQuotes && <QuotesBlankState />}

            {!loading && filteredQuotes.map(quote => <QuoteListItem key={quote.id} quote={quote} />)}
          </Columns.Main>

          <Columns.Sidebar>
            <LoadingModule loading={loading} times={3} />

            <VideoModule videosPayload={videosPayload} />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

export default PageContent
