export default {
  default: {
    createButton: 'Create',
    defaultSelectLabel: 'All',
    title: 'Contacts',
    filterTitle: 'Filter by Title',
    limitLabel: 'Limit',
    pageFilterModuleTitle: 'Filters',
    filter: {
      contactType: 'Contact Type',
    },
  },
}
