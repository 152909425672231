import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserTie } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Image, ListItem, Text,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'
import IconInCircle from '@components/IconInCircle'

import useCurrentUser from '@hooks/useCurrentUser'
import { generateUrls, useRelations } from '@hooks/useUser'

const UserListItem = (props) => {
  const { user } = props
  const {
    email, full_name, job_title, mobile,
  } = user

  const { defaultImage } = useRelations(user)

  const imageUrl = digObject(defaultImage, 'sizes.thumb_90x90')

  const { isAdmin } = useCurrentUser()

  const { editUserUrl } = generateUrls(isAdmin, user)

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        as: 'a',
        borderBottom: '1px dashed',
        padding: 'large',
        color: 'inherit',
      }}
      href={editUserUrl}
      showAngleIcon
    >
      {imageUrl
        ? (
          <Image
            boxProps={{ backgroundColor: 'hoverLightGrey', fontSize: 'small' }}
            circle
            height={30}
            url={imageUrl}
            width={30}
          />
        ) : (
          <IconInCircle
            backgroundColor="whiteGrey"
            border="1px solid"
            borderColor="hoverGrey"
            height={30}
            width={60}
          >
            <FontAwesomeIcon
              icon={faUserTie}
            />
          </IconInCircle>
        )}

      <Box flexDirection="column" marginLeft="large" maxWidth="50%">
        <Text fontSize="small" marginBottom="small" variant="ellipsis">
          {full_name}
        </Text>

        <Text color="faintGrey" fontSize="xsmall" variant="ellipsis">
          {email}
        </Text>
      </Box>

      <Box flexDirection="column" justifyContent="flex-end" marginLeft="auto" textAlign="right">
        <Text fontSize="small">
          {mobile}
        </Text>

        {!!job_title && (
          <Text color="faintGrey" fontSize="xsmall" marginTop="small">
            {job_title}
          </Text>
        )}
      </Box>
    </ListItem>
  )
}

UserListItem.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    full_name: PropTypes.string,
    id: PropTypes.number,
    job_title: PropTypes.string,
    mobile: PropTypes.string,
  }).isRequired,
}

export default UserListItem
