import { useMemo } from 'react'

import { digObject, matchFilterString, sortArrayBy } from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useCurrentUser from '@hooks/useCurrentUser'
import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import defaultRequestOptions from '@sections/Client/defaultRequestOptions'

import type { QuoteModel, QuoteRequestOptions } from '@models/types'

const watchEntityKeys = ['quotes']

type QuoteTemplateFilters = {
  ownerId?: number,
  ownerType?: string,
  string?: string,
}

type UseQuoteTemplatesOptions = {
  filters?: QuoteTemplateFilters,
  performHttpRequests?: boolean,
  requestOptions?: QuoteRequestOptions,
}

function useQuoteTemplates(options: UseQuoteTemplatesOptions = {}) {
  const { filters = {} } = options
  const {
    ownerId: filterOwnerId,
    ownerType: filterOwnerType,
    string: filterString,
  } = filters

  const {
    updatedEntities: { quotes: quotesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { currentUser } = useCurrentUser()

  const { quotes } = useSelector(reduxState => reduxState.entities)

  const filteredQuoteTemplates = useMemo(() => {
    const filtered = Object.values(quotes).filter((quote: QuoteModel) => {
      const {
        owner_id, owner_type, saved_template, title,
      } = quote

      const ownerIdMatch = filterOwnerId ? owner_id === filterOwnerId : true
      const ownerTypeMatch = filterOwnerType ? owner_type === filterOwnerType : true
      const templateMatch = saved_template === true
      const titleMatch = matchFilterString(title, filterString)

      return ownerIdMatch && ownerTypeMatch && templateMatch && titleMatch
    })

    return sortArrayBy(filtered, 'asc', 'title')
  }, [quotesUpdatedAt, JSON.stringify(filters)])

  const filteredQuoteTemplatesCount = filteredQuoteTemplates.length
  const hasFilteredQuoteTemplates = !!filteredQuoteTemplatesCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredQuoteTemplatesCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingQuoteTemplates } = useReduxAction(
    'quoteTemplates',
    'loadQuoteTemplates',
    {
      ...defaultRequestOptions.quote,
      owner_id: filterOwnerId,
      owner_type: filterOwnerType,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: (entityReducer) => {
        const { errors, loaded, loading } = entityReducer
        return performHttpRequests && !loaded && !loading && !errors.length
      },
    },
  )

  const currentUserQuoteTemplates = useMemo(() => {
    const filtered = filteredQuoteTemplates.filter((quoteTemplate: Partial<QuoteModel>) => {
      const userIds = digObject(quoteTemplate, 'data.user_ids', [])

      return currentUser.id ? userIds.includes(Number(currentUser.id)) : true
    })
    return filtered
  }, [currentUser.id, quotesUpdatedAt, JSON.stringify(options)])

  const nonUserQuoteTemplates = useMemo(() => {
    const filtered = filteredQuoteTemplates.filter((quoteTemplate: Partial<QuoteModel>) => {
      const userIds = digObject(quoteTemplate, 'data.user_ids', [])

      return !userIds.length
    })

    return filtered
  }, [currentUser.id, quotesUpdatedAt, JSON.stringify(options)])

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    currentUserQuoteTemplates,
    filteredQuoteTemplates,
    filteredQuoteTemplatesCount,
    hasQuoteTemplates: hasFilteredQuoteTemplates,
    loading: loadingQuoteTemplates,
    nonUserQuoteTemplates,
  }
}

export default useQuoteTemplates
