export default {
  default: {
    sweetAlert: {
      confirmButton: 'Yes, delete it.',
      text: 'Are you sure? ',
      title: 'Delete Team?',
    },
    formFieldLabels: {
      description: 'Description',
      title: '* Title',
      moreOptions: 'More Options',
    },
    toast: {
      created: 'Team Created Successfully',
      updated: 'Team Updated Successfully',
      deleted: 'Team Deleted Successfully',
    },
    createTitle: 'Create',
    cancelTeam: 'Cancel',
    teamMembers: 'TEAM MEMBERS',
    deleteTeam: 'Delete Team',
    teamImageLabel: 'Team Image',
    saveTeam: 'Save',
    titleSecondLine: 'Team',
    updateTitle: 'Update',
  },
}
