export type CustomFieldModel = {
  cache_key: number,
  id: number,
  data: {},
  entity_type: string,
  field_type: string,
  hint: string,
  input_type: string,
  key: string,
  label: string,
  options: {},
  owner_id: number,
  owner_type: string,
  placeholder: string,
  required: boolean,
}

export type NestedFieldModel = {
  field_type: string,
  input_type: string,
  key: string,
  label: string,
  options: object,
  width: number | string,
}

export type CustomFieldRequestOptions = {}

const state = {
  data: {},
  entity_type: '',
  field_type: '',
  hint: '',
  id: null,
  input_type: 'text',
  key: '',
  label: '',
  options: {
    option_values: [],
    tags: ['Custom Fields'],
    width: 100,
  },
  owner_id: null,
  owner_type: '',
  placeholder: '',
  required: false,
}

export const requiredFields = [
  { key: 'field_type' },
  { key: 'label' },
]

export default state
