import PropTypes from 'prop-types'

import {
  Button,
  DashboardModule,
  FormField,
  Link,
  LoadingModule,
  SectionDivider,
  SidebarModal,
  SidebarNoticeBox,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import IntegrationsBlankState from '@components/IntegrationsBlankState'
import EntitySelector from '@components/EntitySelector'

import useImportOrganizations from './hooks/useImportOrganizations'

import Organization from './components/Organization'

const ImportOrganizationsModal = (props) => {
  const {
    callbacks, callbacks: { closeModal, importOrganizations }, modalKey, showModal,
  } = props

  const importOrganizationPayload = useImportOrganizations()
  const {
    active,
    alreadyImportedExternalIds,
    brandId,
    callbacks: {
      bulkImportFromExternalPlatform, loadMore, setState, toggleAllOrganizations, toggleOrganization,
    },
    canLoadMore,
    externalOrganizations,
    filteredPlatforms,
    hasExternalOrganizations,
    hasIntegrations,
    hasSelectedOrganizations,
    integrationsUrls: { integrationsUrl },
    limit,
    loading,
    selectedIntegrationPlatform,
    selectedIntegrationPlatformKey,
    selectedOrganizationsIds,
  } = importOrganizationPayload

  const importPayload = {
    callbacks: {
      action: bulkImportFromExternalPlatform,
      afterAction: closeModal,
    },
    entityParams: selectedOrganizationsIds,
    toastText: 'Import Organizations Queued',
  }

  const {
    callbacks: { formatString },
  } = useLocalization()
  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        title="Import"
        titleSecondLine="Organizations"
      />

      <SidebarModal.Content>
        <LoadingModule loading={loading && !hasIntegrations} />

        {!loading && !hasIntegrations && (
          <IntegrationsBlankState
            buttonUrl={integrationsUrl}
            // eslint-disable-next-line max-len
            paragraph="To import organizations from another platform you’ll need to set up a suitable integration. Integrations can be managed in your client admin."
            sidebar
          />
        )}

        {hasIntegrations && (
          <>
            <FormField
              direction="column"
              label="Importing Organizations into"
            >
              <EntitySelector
                boxProps={{ marginTop: 'large' }}
                callbacks={{
                  selectItem: brand => setState({ brandId: brand?.id }),
                }}
                entityKey="brands"
                selectedItemId={brandId}
              />
            </FormField>

            <FormField direction="column" label="Platform" marginTop="large">
              <select
                data-validate-field-on="change"
                onChange={e => setState({ selectedIntegrationPlatformKey: e.target.value })}
                value={selectedIntegrationPlatformKey}
              >
                <option value="">Please Select...</option>
                {filteredPlatforms.map(entity => (
                  <option key={entity.id} value={entity.key}>
                    {entity.title}
                  </option>
                ))}
              </select>
            </FormField>

            <FormField direction="column" label="Limit" marginTop="large">
              <select
                data-validate-field-on="change"
                onChange={e => setState({ limit: e.target.value })}
                value={limit}
              >
                <option key="50" value="50">50</option>
                <option key="100" value="100">100</option>
                <option key="150" value="150">150</option>
                <option key="200" value="200">200</option>
                <option key="250" value="250">250</option>
              </select>
            </FormField>

            <FormField direction="column" label="Active" marginTop="large">
              <select
                data-validate-field-on="change"
                onChange={e => setState({ active: e.target.value })}
                value={active}
              >
                <option key="true" value> Yes </option>
                <option key="true" value={false}> No </option>
              </select>
            </FormField>

            {/* Loading External Organizations */}
            <LoadingModule boxProps={{ marginTop: 'large' }} loading={loading} />

            {!loading && (
              <SectionDivider boxProps={{ marginTop: 'large' }}>
                Available Organizations
              </SectionDivider>
            )}

            {!loading && !hasExternalOrganizations && (
              <SidebarNoticeBox boxProps={{ marginTop: 'large' }}>
                <SidebarNoticeBox.Title>Nothing to Import</SidebarNoticeBox.Title>

                <SidebarNoticeBox.Paragraph>
                  {formatString(
                    // eslint-disable-next-line max-len
                    'No Organizations Available. Create an organization in {integrationPlatformTitle} for it to be made available here.',
                    {
                      integrationPlatformTitle:
                      selectedIntegrationPlatform.title || 'External Platform',
                    },
                  )}
                </SidebarNoticeBox.Paragraph>
              </SidebarNoticeBox>
            )}

            {!loading && hasExternalOrganizations && (
              <FormField
                direction="column"
                label="Select Organizations"
                labelRight={(
                  <Link onClick={toggleAllOrganizations} textProps={{ fontSize: 'xsmall' }}>
                    {hasSelectedOrganizations
                      ? 'Deselect All'
                      : 'Select All'}
                  </Link>
                )}
                marginTop="large"
              >
                {externalOrganizations.map((organization) => {
                  const { id } = organization

                  return (
                    <Organization
                      alreadyImported={alreadyImportedExternalIds.includes(id)}
                      callbacks={{ toggleOrganization: () => toggleOrganization(id) }}
                      organization={organization}
                      checked={selectedOrganizationsIds.includes(id)}
                      key={id}
                    />
                  )
                })}
              </FormField>
            )}

            <DashboardModule.LoadMoreFooter
              callbacks={{ loadMore }}
              canLoadMore={canLoadMore}
              entityCount={externalOrganizations.length}
              loading={loading}
            />
          </>
        )}
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!hasSelectedOrganizations}
          onClick={() => importOrganizations(importPayload)}
          size="large"
        >
          Import Organizations
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

ImportOrganizationsModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

ImportOrganizationsModal.defaultProps = {
  modalKey: 'ImportOrganizationsModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <ImportOrganizationsModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
