import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, Button, FileInput, FormField, SidebarModal,
} from '@campaignhub/suit-theme'

import { useSetState } from '@campaignhub/react-hooks'

import useDigitalTemplatePage from '@hooks/useDigitalTemplatePage'

const shouldRender = (itemType) => {
  const permittedItemTypes = ['animation']
  return permittedItemTypes.includes(itemType)
}

const defaultState = {
  attachmentFileData: null,
  fileName: '',
}

const Attachment = (props) => {
  const {
    digitalTemplatePayload: { digitalTemplate, selectedDigitalTemplatePage },
    selectedEntity,
    selectedEntity: { item_type },
  } = props

  const [state, setState] = useSetState(defaultState)
  const { attachmentFileData, fileName } = state

  const {
    callbacks: { createOrUpdatePageItemAttachment: createOrUpdateFn },
  } = useDigitalTemplatePage(selectedDigitalTemplatePage)

  const attachmentParams = {
    digital_template_page_item_id: selectedEntity.id,
    file: attachmentFileData,
    file_name: fileName,
    owner_id: digitalTemplate.id,
    owner_type: 'DigitalTemplate',
    subject_id: selectedDigitalTemplatePage.id,
    subject_type: 'DigitalTemplatePage',
  }

  if (!shouldRender(item_type)) return null

  return (
    <SidebarModal.ExpandableSectionBox label="Attachment">
      <FormField label="Import File (.json)">
        <Box flexDirection="column">
          <FileInput
            name="import_file"
            input={{
              onChange: (fileData, file) => setState({ attachmentFileData: fileData, fileName: file.name }),
            }}
          />

          <Button
            as="a"
            buttonStyle="secondaryCreate"
            onClick={() => createOrUpdateFn(attachmentParams)}
            marginTop="large"
            size="medium"
          >
            Save File
          </Button>
        </Box>
      </FormField>
    </SidebarModal.ExpandableSectionBox>
  )
}

Attachment.propTypes = {
  digitalTemplatePayload: PropTypes.object.isRequired,
  selectedEntity: PropTypes.object.isRequired,
}

export default Attachment
