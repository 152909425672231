import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, Button, DashboardModule, FormField, Link,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import Contact from '../../../../components/Contact'

import localizedStrings from './localizedStrings'

const Contacts = (props) => {
  const {
    callbacks: { manageProjectContacts },
    contacts,
  } = props

  const { strings } = useLocalization(localizedStrings)

  return (
    <DashboardModule
      headerRight={(
        <Link onClick={manageProjectContacts} textProps={{ fontSize: 'small' }}>
          {strings.manage || 'Manage'}
        </Link>
      )}
      title={strings.title || 'Contacts'}
    >
      <Box flexDirection="column" maxHeight={415} overflow="auto">
        {!!contacts.length && (
          <FormField labelProps={{ marginLeft: 'large' }} label={strings.lead}>
            {contacts.map(contact => (
              <Contact key={contact.id} contact={contact} />
            ))}
          </FormField>
        )}

        {!contacts.length && (
          <Box padding="large">
            <Button buttonStyle="secondaryUtility" onClick={manageProjectContacts} size="medium">
              {strings.addButton || 'Add Contact'}
            </Button>
          </Box>
        )}
      </Box>
    </DashboardModule>
  )
}

Contacts.propTypes = {
  callbacks: PropTypes.shape({
    manageProjectContacts: PropTypes.func.isRequired,
  }),
  contacts: PropTypes.array.isRequired,
}

export default Contacts
