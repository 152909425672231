import React from 'react'

import { ModuleBlankState } from '@campaignhub/suit-theme'

import blankImageUrl from './assets/CustomFieldsBlank.svg'

const CustomFieldBlankState = () => (
  <ModuleBlankState imageUrl={blankImageUrl}>
    <ModuleBlankState.Title>No Custom Fields to Display</ModuleBlankState.Title>

    <ModuleBlankState.Paragraph>
      Custom Fields can be created and added to a Custom Field Set for use across the platform.
    </ModuleBlankState.Paragraph>
  </ModuleBlankState>
)

export default CustomFieldBlankState
