export default {
  default: {
    create: 'Create',
    filters: {
      createDateAfter: 'Created After',
      createDateBefore: 'Created Before',
      defaultSelectLabel: 'All',
      filterByTitle: 'Filter by Project or Digital Page Title',
      filterType: '{global.digitalTemplateType.title.singular} Type',
      filterTitle: 'Filters',
      limit: 'Limit',
      organization: '{global.organizationType.shortName.singular}',
      status: 'Status',
      statusTitle: '{global.status.title}',
      user: 'User',
    },
    tabs: {
      engagementStatus: {
        all: 'All',
        cold: 'Cold',
        hot: 'Hot',
        warm: 'Warm',
      },
      templateTypes: {
        proposal: 'Proposal',
      },
    },
    title: '{global.digitalTemplateType.title.plural}',
  },
}
