import cloneDeep from 'lodash/cloneDeep'
import uniqueId from 'lodash/uniqueId'

import { digObject } from '@campaignhub/javascript-utils'

import denormalizeEntity from '@functions/denormalizeEntity'
import generateID from '@functions/generateID'

// When adding params to partials check
// app/services/digital_templates/setup_base_template.rb
// to see an change are required

// Do not sort alphabetically
export const availableFeatures = [
  { key: 'awards', label: 'Awards', parentKey: 'users' },
  { key: 'case_studies', label: 'Case Studies' },
  {
    key: 'commission_fee',
    label: 'Commission Fee',
    parentKey: 'commission',
  },
  {
    key: 'commission_structure',
    label: 'Commission Structure',
    parentKey: 'commission',
  },
  {
    key: 'commission',
    label: 'Commission',
    checkEnabledFn: supportedFeatureKeys => supportedFeatureKeys.includes('commission_fee') || supportedFeatureKeys.includes('commission_structure'),
  },
  { key: 'project_comparables', label: 'Comparables' },
  { key: 'contacts', label: 'Contacts' },
  { key: 'cover_letter', label: 'Cover Letter' },
  { key: 'event_calendars', label: 'Event Calendars' },
  { key: 'form_responses', label: 'Form Responses' },
  { key: 'key_features', label: 'Key Features' },
  {
    key: 'method_of_sale',
    label: 'Method Of Sale',
    parentKey: 'sale_details',
  },
  {
    key: 'price_guide',
    label: 'Price Guide',
    parentKey: 'sale_details',
  },
  { key: 'project_documents', label: 'Project Documents' },
  { key: 'quotes', label: 'Quotes' },
  { key: 'reviews', label: 'Reviews' },
  {
    key: 'sale_details',
    label: 'Sales Details',
    checkEnabledFn: supportedFeatureKeys => supportedFeatureKeys.includes('method_of_sale')
    || supportedFeatureKeys.includes('price_guide'),
  },
  {
    key: 'project_details',
    label: 'Project Details',
    checkEnabledFn: supportedFeatureKeys => supportedFeatureKeys.includes('method_of_sale')
     || supportedFeatureKeys.includes('price_guide')
     || supportedFeatureKeys.includes('sales_details'),
  },
  { key: 'suburb_data', label: 'Suburb Data' },
  { key: 'target_audiences', label: 'Target Audiences' },
  { key: 'teams', label: 'Teams' },
  { key: 'users', label: 'Users' },
]

export const availableChartFeatures = [
  { key: 'suburb_data', label: 'Suburb Data' },
  { key: 'chart_data', label: 'Chart Data' },
]

const partialBase = {
  id: null,
  style: {},
}

export const itemPartials = {
  animation: {
    ...partialBase,
    data: {
      value: null,
    },
    item_type: 'animation',
    style: {
      width: '100%',
      height: '200px',
    },
  },
  button: {
    ...partialBase,
    data: {
      value: null,
    },
    item_type: 'button',
    options: {
      text: 'Button',
      color: 'green',
    },
  },
  buttonTrigger: {
    ...partialBase,
    item_Type: 'buttonTrigger',
    options: {
      action: 'toggleMenu',
      icon: 'menu',
    },
  },
  carousel: {
    ...partialBase,
    item_type: 'carousel',
    items: [
      {
        id: generateID(),
        data: {
          value: null,
        },
        entityType: 'digitalTemplatePageItem',
        item_type: 'dataArrayLoop',
        items: [
          {
            id: 'container',
            item_type: 'container',
            items: [],
            entityType: 'digitalTemplatePageItem',
            style: {
              width: '100%',
              height: '200px',
            },
          },
        ],
        options: { sort: 1 },
        style: {
          width: '100%',
          height: '100%',
        },
      },
    ],
    style: {
      width: '100%',
      height: '200px',
    },
  },
  chart: {
    ...partialBase,
    data: {
      value: 'Chart',
    },
    item_type: 'chart',
    style: {
      width: '100%',
      height: '400px',
    },
  },
  advancedChart: {
    ...partialBase,
    item_type: 'advancedChart',
    items: [],
    style: {
      width: '100%',
      height: '500px',
    },
  },
  container: {
    ...partialBase,
    item_type: 'container',
    items: [],
    style: {
      width: '100%',
      height: '200px',
    },
  },
  counter: {
    ...partialBase,
    item_type: 'counter',
    items: [],
    style: {
      width: '100%',
      height: '200px',
    },
  },
  dataArrayLoop: {
    ...partialBase,
    item_type: 'dataArrayLoop',
    items: [],
    style: {
      width: '100%',
      height: '200px',
    },
  },
  formTemplate: {
    ...partialBase,
    item_type: 'formTemplate',
    items: [],
    style: {
      width: '100%',
      height: '200px',
    },
  },
  hashLink: {
    ...partialBase,
    item_type: 'hashLink',
    items: [],
    style: {
      width: '100%',
      height: '200px',
    },
  },
  icon: {
    ...partialBase,
    data: {
      shortcode: null,
    },
    item_type: 'icon',
    style: {
      width: 'auto',
      height: 'auto',
    },
  },
  iframe: {
    ...partialBase,
    item_type: 'iframe',
    style: {
      width: '100%',
      height: '260px',
    },
  },
  image: {
    ...partialBase,
    data: {
      value: null,
    },
    item_type: 'image',
    style: {
      width: '200px',
      height: '260px',
    },
  },
  input: {
    ...partialBase,
    item_type: 'input',
    options: {
      inputComponent: 'input',
    },
    style: {
      width: '100%',
    },
  },
  map: {
    ...partialBase,
    item_type: 'map',
    style: {
      width: '100%',
      height: '200px',
    },
  },
  mediaContainer: {
    ...partialBase,
    item_type: 'mediaContainer',
    items: [],
    style: {
      width: '100%',
      height: '200px',
    },
  },
  imageCarousel: {
    ...partialBase,
    data: {
      value: null,
    },
    item_type: 'imageCarousel',
    style: {
      carousel_height: '500px',
      carousel_width: '60vw',
      width: '200px',
      height: '260px',
    },
  },
  section: {
    ...partialBase,
    item_type: 'section',
    items: [],
    options: {
      toggleable: true,
    },
  },
  sectionGroup: {
    id: null,
    item_type: 'sectionGroup',
    options: {
      sortable: true,
    },
    sections: [],
  },
  state: {
    ...partialBase,
    item_type: 'state',
    items: [],
    options: {
      defaultState: {},
    },
    style: {
      width: '100%',
      height: '200px',
    },
  },
  text: {
    ...partialBase,
    data: {
      value: 'Text',
    },
    item_type: 'text',
    options: {},
  },
  video: {
    ...partialBase,
    data: {
      value: null,
    },
    item_type: 'video',
    options: {
      videoProvider: 'youtube',
    },
  },
}

export function findEntity(type, id, entities){
  const { digitalTemplatePageItems, digitalTemplatePageSectionGroups, digitalTemplatePageSections } = entities

  if (type === 'digitalTemplatePageSectionGroup'){
    return { ...digitalTemplatePageSectionGroups[id], entityType: type } || {}
  }

  if (type === 'digitalTemplatePageSection') return { ...digitalTemplatePageSections[id], entityType: type } || {}
  if (type === 'digitalTemplatePageItem') return { ...digitalTemplatePageItems[id], entityType: type } || {}

  return {}
}

export function updateEntityItemIds(entity){
  if (entity && entity.items){
    return entity.items.map(item => ({
      ...item,
      id: `${generateID()}${uniqueId()}`,
      items: updateEntityItemIds(item),
    }))
  }

  return []
}

export function updateEntityIds(entity){
  const clone = cloneDeep(entity)
  clone.id = `${generateID()}${uniqueId()}`
  clone.items = updateEntityItemIds(clone)

  return clone
}

export function copyEntity(type, id, entities){
  const copiedEntity = findEntity(type, id, entities)
  const denormalizedEntity = denormalizeEntity(type, copiedEntity, entities)

  const newEntity = updateEntityIds(denormalizedEntity)

  return newEntity
}

function matchItemAttribute(item, matchAttribute){
  if (!matchAttribute) return true

  const value = digObject(item, matchAttribute.key)
  return value === matchAttribute.value
}

export function findItemByType(itemType, items, entities, options = {}){
  const { matchAttribute } = options

  let array = []
  let itemMatch

  if (items && entities){
    array = items.map((item) => {
      if (typeof item === 'object'){
        return item
      }

      return entities[item] || {}
    })
  }

  array.some((item) => {
    const { item_type } = item || {}

    if (item_type === itemType && matchItemAttribute(item, matchAttribute)){
      itemMatch = item
      return true
    }

    const nestedItemIds = digObject(item, 'items', [])
    if (nestedItemIds.length){
      const nestedItem = findItemByType(itemType, nestedItemIds, entities, options)
      if (nestedItem){
        itemMatch = nestedItem
        return true
      }
    }

    return false
  })

  return itemMatch
}

export function removeNullNodes(sectionGroups = []){
  const updated = sectionGroups.reduce((sectionGroupsResult, sectionGroup) => {
    if (sectionGroup){
      const updatedSectionGroup = cloneDeep(sectionGroup)
      const { sections } = sectionGroup
      const updatedSections = sections ? [...sections] : []

      updatedSectionGroup.sections = updatedSections.reduce((result, section) => {
        if (section){
          const updatedSection = { ...section }
          const { items } = updatedSection
          const updatedItems = items ? [...items] : []

          updatedSection.items = updatedItems.filter(item => item) || []

          result.push(updatedSection)
        }

        return result
      }, [])

      sectionGroupsResult.push(updatedSectionGroup)
    }

    return sectionGroupsResult
  }, [])

  return updated
}

export function findEditableImageItemIds(entityType, entity){
  if (entityType === 'digitalTemplatePageSection') return entity.items || []
  if (entityType === 'digitalTemplatePageItem'){
    const { item_type, id } = entity

    if (item_type === 'image') return [id]

    return []
  }

  return []
}

export const templateTypes = [
  {
    key: 'agreement',
    title: 'Agreement',
  },
  {
    key: 'form',
    title: 'Form',
  },
  {
    key: 'microsite',
    title: 'Microsite',
  },
  {
    key: 'proposal',
    title: 'Proposal',
  },
]

export default findItemByType
