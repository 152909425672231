import { useEffect } from 'react'
import PropTypes from 'prop-types'

import { useSetState } from '@campaignhub/react-hooks'
import { SidebarModal } from '@campaignhub/suit-theme'

import useIntegrations from '@hooks/useIntegrations'

import ImportQuote from './screens/ImportQuote'
import NewQuote from './screens/NewQuote'

const defaultState = {
  selectedScreen: 'NewQuote',
}

const CreateQuoteModal = (props) => {
  const {
    callbacks, disableAnimation, disableOverlay, modalKey, project, showModal,
  } = props

  const [state, setState] = useSetState(defaultState)
  const { selectedScreen } = state

  const { organization_id } = project

  const integrationsPayload = useIntegrations({
    featureKeys: ['quoting'],
    filters: {
      owner_id: organization_id,
      owner_type: 'Organization',
    },
  })
  const { hasIntegrations, loaded } = integrationsPayload

  useEffect(() => {
    if (hasIntegrations && loaded){
      setState({ selectedScreen: 'ImportQuote' })
    }
  }, [hasIntegrations, loaded])

  return (
    <SidebarModal
      callbacks={callbacks}
      disableAnimation={disableAnimation}
      disableOverlay={disableOverlay}
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      {selectedScreen === 'NewQuote' && <NewQuote callbacks={callbacks} modalKey={modalKey} project={project} />}

      {selectedScreen === 'ImportQuote' && (
        <ImportQuote callbacks={callbacks} integrationsPayload={integrationsPayload} project={project} />
      )}
    </SidebarModal>
  )
}

CreateQuoteModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  disableAnimation: PropTypes.bool,
  disableOverlay: PropTypes.bool,
  modalKey: PropTypes.string,
  project: PropTypes.object.isRequired,
  showModal: PropTypes.bool,
}

CreateQuoteModal.defaultProps = {
  modalKey: 'CreateQuoteModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <CreateQuoteModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
