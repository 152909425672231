import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, Checkbox, ListItem,
} from '@campaignhub/suit-theme'

const Organization = (props) => {
  const {
    alreadyImported,
    callbacks: { toggleOrganization },
    checked,
    organization,
  } = props

  const { title } = organization

  return (
    <ListItem.WithCheckbox
      boxProps={{ border: 'none' }}
      checkbox={(
        <Checkbox
          checked={checked}
          onClick={() => toggleOrganization()}
        />
      )}
    >
      <Box
        color={alreadyImported ? 'lightGrey' : 'bodyFontLightColor'}
        fontSize="small"
      >
        {title} {alreadyImported && '(Imported)'}
      </Box>
    </ListItem.WithCheckbox>
  )
}

Organization.propTypes = {
  alreadyImported: PropTypes.bool,
  callbacks: PropTypes.object.isRequired,
  checked: PropTypes.bool,
  organization: PropTypes.object,
}

export default Organization
