import { ReactNode, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTasks, faTh } from '@fortawesome/pro-light-svg-icons'

import { Box, DashboardModule, Link } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

import GridView from './components/GridView'
import ListView from './components/ListView'
import SortableNoticeBox from './components/SortableNoticeBox'

interface ToggleListGridViewProps {
  gridItems: ReactNode,
  listItems: ReactNode,
  modifyImageSort: (fromIndex: number, toIndex: number) => void,
  title?: string,
}

const ToggleListGridView = (props: ToggleListGridViewProps) => {
  const {
    gridItems, listItems, modifyImageSort, title,
  } = props

  const [listView, setListView] = useState(true)

  const { strings } = useLocalization(localizedStrings)

  return (
    <DashboardModule
      boxProps={{ marginTop: 'large', border: 'none' }}
      headerRight={(
        <Link
          onClick={() => setListView(!listView)}
          textProps={{ fontSize: 'small' }}
        >
          <FontAwesomeIcon icon={listView ? faTh : faTasks} />{' '}
          {listView ? (strings.gridView || 'Grid View') : (strings.listView || 'List View')}
        </Link>
      )}
      title={title}
    >
      <Box flexDirection="column" border="none" marginTop="medium">
        {!listView && <GridView itemComponent={gridItems} />}

        {listView && (
          <>
            <SortableNoticeBox />

            <Box flexDirection="column" overflowY="auto">
              <ListView itemComponent={listItems} modifyImageSort={modifyImageSort} />
            </Box>
          </>
        )}
      </Box>
    </DashboardModule>
  )
}

export default ToggleListGridView
