import cloneDeep from 'lodash/cloneDeep'
import deepmerge from 'deepmerge'

import * as ModelTypes from '@models/types'

import type { ModuleState } from '@redux/modules/types'

const DELETE_ENTITY = 'realbase/entity/DELETE_ENTITY'
const REPLACE_ENTITY = 'realbase/entity/REPLACE_ENTITY'
const UPDATE_ENTITY = 'realbase/entity/UPDATE_ENTITY'

export type EntitiesState = {
// START ENTITY TYPES
  [key: string]: {},
  _updates: {},
  activities: { [key: number]: ModelTypes.ActivityModel },
  addresses: { [key: number]: ModelTypes.AddressModel },
  agreementShortcodeData: {},
  agreements: {},
  apiLogs: { [key: number]: ModelTypes.ApiLogModel },
  assetLibraries: { [key: number]: ModelTypes.AssetLibraryModel },
  attachments: { [key: number]: ModelTypes.AttachmentModel },
  awards: { [key: number]: ModelTypes.AwardModel },
  brands: { [key: number]: ModelTypes.BrandModel },
  caseStudies: { [key: number]: ModelTypes.CaseStudyModel },
  chartData: {},
  comparables: { [key: number]: ModelTypes.ComparableModel },
  contacts: { [key: number]: ModelTypes.ContactModel },
  countries: {[key: number]: ModelTypes.CountryModel },
  customFieldSets: { [key: number]: ModelTypes.CustomFieldSetModel },
  customFields: { [key: number]: ModelTypes.CustomFieldModel },
  dataStoreItems: {},
  digitalPageMetricCollections: {},
  digitalPageShortcodeData: {},
  digitalPageViews: {},
  digitalPages: {},
  digitalTemplateComponents: {},
  digitalTemplatePageItems: {},
  digitalTemplatePageSectionGroups: {},
  digitalTemplatePageSections: {},
  digitalTemplatePages: {},
  digitalTemplateTypes: {},
  digitalTemplates: {},
  documentRecipients: {},
  documentTemplatePageItems: {},
  documentTemplatePages: {},
  documentTemplates: {},
  eventCalendars: { [key: number]: ModelTypes.EventCalendarModel },
  eventGroups: { [key: number]: ModelTypes.EventGroupModel },
  events: { [key: number]: ModelTypes.EventModel },
  formTemplates: { [key: number]: ModelTypes.FormTemplateModel },
  images: { [key: number]: ModelTypes.ImageModel },
  integrationPlatforms: { [key: number]: ModelTypes.IntegrationPlatformModel },
  integrations: { [key: number]: ModelTypes.IntegrationModel },
  organizationShortcodeLists: { [key: number] : {} },
  organizationTypes: { [key: number]: ModelTypes.OrganizationTypeModel },
  organizations: { [key: number]: ModelTypes.OrganizationModel },
  organizationsUsers: { [key: number]: ModelTypes.OrganizationsUserModel },
  projectTypes: {},
  projects: { [key: number]: ModelTypes.ProjectModel },
  quoteItems: { [key: number]: ModelTypes.QuoteItemModel },
  quoteSections: { [key: number]: ModelTypes.QuoteSectionModel },
  quotes: { [key: number]: ModelTypes.QuoteModel },
  reviews: { [key: number]: ModelTypes.ReviewModel },
  roles: { [key: number]: ModelTypes.RoleModel },
  sessionLocks: { [key: number]: ModelTypes.SessionLockModel },
  shareLinks: { [key: number]: ModelTypes.ShareLinkModel },
  statuses: { [key: number]: ModelTypes.StatusModel },
  systems: { [key: number]: ModelTypes.SystemModel },
  tags: { [key: number]: ModelTypes.TagModel },
  targetAudiences: { [key: number]: ModelTypes.TargetAudienceModel },
  teamMembers: { [key: number]: ModelTypes.TeamMemberModel },
  teams: { [key: number]: ModelTypes.TeamModel },
  userTypes: { [key: number]: ModelTypes.UserTypeModel },
  users: { [key: number]: ModelTypes.UserModel },
// END ENTITY TYPES
}

const initialState: EntitiesState = {
// START ENTITIES STATE
  _updates: {},
  activities: {},
  addresses: {},
  agreementShortcodeData: {},
  agreements: {},
  apiLogs: {},
  assetLibraries: {},
  attachments: {},
  awards: {},
  brands: {},
  caseStudies: {},
  chartData: {},
  comparables: {},
  contacts: {},
  countries: {},
  customFieldSets: {},
  customFields: {},
  dataStoreItems: {},
  digitalPageMetricCollections: {},
  digitalPageShortcodeData: {},
  digitalPageViews: {},
  digitalPages: {},
  digitalTemplateComponents: {},
  digitalTemplatePageItems: {},
  digitalTemplatePageSectionGroups: {},
  digitalTemplatePageSections: {},
  digitalTemplatePages: {},
  digitalTemplateTypes: {},
  digitalTemplates: {},
  documentRecipients: {},
  documentTemplatePageItems: {},
  documentTemplatePages: {},
  documentTemplates: {},
  eventCalendars: {},
  eventGroups: {},
  events: {},
  formTemplates: {},
  images: {},
  integrationPlatforms: {},
  integrations: {},
  organizationShortcodeLists: {},
  organizationTypes: {},
  organizations: {},
  organizationsUsers: {},
  projectTypes: {},
  projects: {},
  quoteItems: {},
  quoteSections: {},
  quotes: {},
  reviews: {},
  roles: {},
  sessionLocks: {},
  shareLinks: {},
  statuses: {},
  systems: {},
  tags: {},
  targetAudiences: {},
  teamMembers: {},
  teams: {},
  userTypes: {},
  users: {},
// END ENTITIES STATE
}

type EntitiesActionFunctionPayload = {
  entities: EntitiesState,
}

type ReducerResult = EntitiesActionFunctionPayload['entities'] & {
  type: string,
}

type Action = Partial<EntitiesState> & Partial<ModuleState> & {
  type?: string,
}

export function updateEntities(payload: EntitiesActionFunctionPayload): ReducerResult {
  return { type: UPDATE_ENTITY, ...payload.entities }
}

export function replaceEntity(payload: EntitiesActionFunctionPayload): ReducerResult {
  return { type: REPLACE_ENTITY, ...payload.entities }
}

export function deleteEntity(payload: EntitiesActionFunctionPayload): ReducerResult {
  return { type: DELETE_ENTITY, ...payload.entities }
}

function cleanKeys(
  keys: (keyof Action)[] = [],
  removeKeys: (keyof Action)[] = [],
): (keyof EntitiesState)[] {
  removeKeys.forEach((removeKey) => {
    const index = keys.indexOf(removeKey)
    keys.splice(index, 1)
  })

  return keys
}

// Reducers
function addEntities(state: EntitiesState, action: Action): EntitiesState {
  const keys = cleanKeys(Object.keys(action), ['type'])
  const newState = { ...state }

  // Don't merge arrays
  const dontMerge = (_, source) => source
  const mergeOptions = { arrayMerge: dontMerge }

  keys.forEach((key) => {
    newState[key] = deepmerge(newState[key], action[key], mergeOptions)
  })

  return newState
}

function replaceEntities(state: EntitiesState, action: Action): EntitiesState {
  const keys = cleanKeys(Object.keys(action), ['type'])
  const newState = cloneDeep(state)

  keys.forEach((key) => {
    if (newState[key]){
      newState[key] = cloneDeep(state[key])

      // Keys of the item we need to replace
      const itemKeys = Object.keys(action[key])
      itemKeys.forEach((itemKey) => {
        newState[key][itemKey] = action[key][itemKey]
      })
    }
  })

  return newState
}

function removeEntities(state: EntitiesState, action: Action): EntitiesState {
  const keys: (keyof EntitiesState)[] = cleanKeys(Object.keys(action), ['type'])
  const newState = { ...state }

  keys.forEach((key) => {
    if (newState[key]){
      newState[key] = cloneDeep(state[key])

      // Keys of the item we need to remove
      const itemKeys = Object.keys(action[key])
      itemKeys.forEach((itemKey) => {
        delete newState[key][itemKey]
      })
    }
  })

  return newState
}

export default function reducer(state: EntitiesState = initialState, action: Action = {}) {
  switch (action.type){
    case UPDATE_ENTITY:
      return addEntities(state, action)
    case REPLACE_ENTITY:
      return replaceEntities(state, action)
    case DELETE_ENTITY:
      return removeEntities(state, action)
    default:
      return state
  }
}
