import { faFileAlt } from '@fortawesome/pro-light-svg-icons'

import { Box, Button, PageHeader } from '@campaignhub/suit-theme'

import { getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'

import { generateUrls } from '@hooks/useProject'
import useLocalization from '@hooks/useLocalization'
import useNumericParams from '@hooks/useNumericParams'

import { ProjectModel } from '@models/project'

import QuoteBuilder from '@components/QuoteBuilder'

import localizedStrings from './localizedStrings'

interface PageContentProps {
  project: ProjectModel,
}

const PageContent = (props: PageContentProps) => {
  const { project } = props

  const { quoteId } = useNumericParams()

  const { redirect } = parsePermittedQueryParams(getQueryParamsFromHash(), ['redirect'])

  const { quotesUrl } = generateUrls({ id: project.id })

  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      <PageHeader
        actionContent={
          redirect && (
            <Button as="a" href={redirect || '/'} buttonStyle="secondaryUtility" size="medium">
              {strings.buttons?.goBack || 'Go Back'}
            </Button>
          )
        }
        activeTabBarItemKey="edit"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        nestedNavigation
        tabBarItems={[
          {
            href: quotesUrl,
            icon: faFileAlt,
            key: 'quotes',
            title: strings.allQuotes || 'All Quotes',
          },
          {
            href: `#/projects/${project.id}/quotes/${quoteId}`,
            icon: faFileAlt,
            key: 'edit',
            title: `${strings.quote || 'Quote'} #${quoteId}`,
          },
        ]}
        title={strings.editQuote || 'Edit Quote'}
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <QuoteBuilder quoteId={quoteId} />
      </Box>
    </>
  )
}

export default PageContent
