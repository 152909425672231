import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, Link, ListItem, Text,
} from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'

const SelectedListItem = (props) => {
  const {
    boxProps,
    callbacks: { toggleItem },
    canDelete,
    subTitle,
    title,
  } = props

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        border: '1px solid',
        borderColor: 'lineColor',
        borderRadius: 5,
        justifyContent: 'space-between',
        padding: 'large',
        ...boxProps,
      }}
      disableHover
      forceBottomBorder
    >
      <Box flexDirection="column">
        <Text fontSize="small">{title}</Text>

        {subTitle && (
          <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="medium">
            {subTitle}
          </Text>
        )}
      </Box>

      {canDelete && (
      <Link
        greyLink
        onClick={() => toggleItem()}
        textProps={{ fontSize: 'small' }}
      >
        <FontAwesomeIcon icon={faTrashAlt} />
      </Link>
      )}

    </ListItem>
  )
}

SelectedListItem.propTypes = {
  boxProps: PropTypes.object,
  callbacks: PropTypes.shape({
    toggleItem: PropTypes.func.isRequired,
  }).isRequired,
  canDelete: PropTypes.bool,
  subTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
}
export default SelectedListItem
