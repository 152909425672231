import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/pro-light-svg-icons'

import { DashboardModule, Grid } from '@campaignhub/suit-theme'

import buildEventGroupCellData from '@functions/buildEventGroupCellData'

import useEventGroup from '@hooks/useEventGroup'

import GroupCell from './GroupCell'

const EventGroup = (props) => {
  const { callbacks: { dragDropEvent }, eventGroup, isMobile } = props

  const eventGroupPayload = useEventGroup(eventGroup)
  const {
    callbacks: {
      editEventGroup,
    },
    eventGroup: {
      days_from_start, duration_in_days, title, subtitle,
    },
    endDateObj,
    filteredEvents,
    startDateObj,
  } = eventGroupPayload

  const cells = buildEventGroupCellData({
    daysFromStart: days_from_start,
    durationInDays: duration_in_days,
    endDateObj,
    events: filteredEvents,
    startDateObj,
  })

  const cellCount = cells.length

  const gridColumnCount = isMobile ? 1 : 7
  const gridRowCount = Math.ceil(cellCount / gridColumnCount)

  return (
    <DashboardModule
      boxProps={{ marginBottom: 'small' }}
      headerRight={(
        <DashboardModule.Link onClick={editEventGroup}>
          <FontAwesomeIcon icon={faPencil} />
        </DashboardModule.Link>
      )}
      title={subtitle ? `${title} - ${subtitle}` : title}
    >
      <Grid gridTemplateColumns={['repeat(1, 1fr)', 'repeat(7, 1fr)']} width={['auto', '100%']}>
        {cells.map((cellData, index) => {
          const lastColumn = (index + 1) % gridColumnCount === 0
          const lastRow = Math.ceil((index + 1) / gridColumnCount) === gridRowCount

          return (
            <GroupCell
              callbacks={{ dragDropEvent }}
              cellData={cellData}
              eventGroup={eventGroup}
              key={cellData.id}
              lastColumn={lastColumn}
              lastRow={lastRow}
            />
          )
        })}
      </Grid>
    </DashboardModule>
  )
}

EventGroup.propTypes = {
  callbacks: PropTypes.shape({
    dragDropEvent: PropTypes.func.isRequired,
  }).isRequired,
  eventGroup: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
}

export default EventGroup
