import { useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCog, faEdit, faHome, faSignOut,
} from '@fortawesome/pro-light-svg-icons'

import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons'

import { useModals, useSetState } from '@campaignhub/react-hooks'
import { digObject } from '@campaignhub/javascript-utils'

import {
  Box, Button, CustomizableDashboard, IconWithMenu, ModalContext, PageHeader,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import DigitalPages, {
  configOptions as digitalPageConfigOptions,
  icon as digitalPagesIcon,
} from '@sections/Client/components/DashboardModules/DigitalPages'

import Leaderboard, {
  configOptions as leaderboardConfigOptions,
  icon as leaderboardIcon,
} from '@sections/Client/components/DashboardModules/Leaderboard'

import Projects, {
  configOptions as projectConfigOptions,
  icon as projectsIcon,
} from '@sections/Client/components/DashboardModules/Projects'

import useCurrentUser from '@hooks/useCurrentUser'
import useExternalProjectParams from '@hooks/useExternalProjectParams'
import useLocalization from '@hooks/useLocalization'
import useMixpanel from '@hooks/useMixpanel'
import useOrganization from '@hooks/useOrganization'
import useOrganizationSelector from '@hooks/useOrganizationSelector'
import useUser from '@hooks/useUser'
import useUserOnboardingTasks from '@hooks/useUserOnboardingTasks'

import handleCallbackAction from '@functions/handleCallbackAction'

import CreateOrEditAddressModal from '@modals/CreateOrEditAddressModal'
import CreateProjectModal from '@modals/CreateProjectModal'

import DashboardModuleNotice from '@components/DashboardModuleNotice'

import ContentSidebar from './components/ContentSidebar'
import Content from './components/Content'
import localizedStrings from './localizedStrings'

const generateAvailableModules = (organizations, strings) => ({
  DigitalPages: {
    component: DigitalPages,
    configOptions: digitalPageConfigOptions(strings),
    icon: digitalPagesIcon,
    id: 'DigitalPages',
    title: strings.digitalPages?.title || 'Digital Pages',
  },
  Leaderboard: {
    component: Leaderboard,
    configOptions: leaderboardConfigOptions(strings),
    icon: leaderboardIcon,
    id: 'Leaderboard',
    title: strings.leaderboard?.title || 'Proposals Leaderboard',
  },
  Projects: {
    component: Projects,
    configOptions: projectConfigOptions(organizations, strings),
    icon: projectsIcon,
    id: 'Projects',
    title: strings.projects?.title || 'Projects',
  },
})

const dashboardDefaultLayout = strings => ({
  columns: {
    main: {
      id: 'main',
      modules: [
        {
          key: 'Projects-1',
          moduleId: 'Projects',
        },
        {
          key: 'DigitalPages-1',
          moduleId: 'DigitalPages',
        },
      ],
    },
    sidebar: {
      id: 'sidebar',
      modules: [],
    },
  },
  custom: false,
  options: {
    'DigitalPages-1': {
      customization: { display_mode: 'list', title: strings.digitalPages?.title || 'Digital Pages' },
      queryParams: { limit: 25 },
    },
    'Leaderboard-1': {
      customization: { title: strings.templatesLeaderboard || 'Most Engaging Templates' },
      queryParams: { leaderboard: 'digital_template_leaderboard', month: 'currentMonth', status: 'pending' },
    },
    'Leaderboard-2': {
      customization: { title: strings.usersLeaderboard || 'Proposals Leaderboard' },
      queryParams: { leaderboard: 'user_leaderboard', month: 'currentMonth', status: 'pending' },
    },
    'Projects-1': {
      customization: { display_mode: 'list', title: strings.projects?.title || 'Projects' },
      queryParams: { limit: 25 },
    },
  },
})

const createProject = (projectParams, customFields, createFn) => {
  createFn(projectParams, customFields).then(({ success, errors, redirectUrl }) => {
    if (!success){
      toast.warn(errors[0])
      return
    }

    if (redirectUrl){
      window.location.href = redirectUrl
    }
  })
}

const updateOrganization = (entityParams, customFields, updateFn, strings) => {
  updateFn(entityParams, customFields).then(({ success, errors }) => {
    if (!success && errors){
      toast.warn(errors[0])
      return
    }

    toast(strings.toast?.organization?.revokeAccess || 'Access Revoked Successfully')
  })
}

const importProject = (externalId, integrationPlatformKey, organizationId, importFn, requestOptions) => {
  importFn(externalId, integrationPlatformKey, organizationId, requestOptions).then(
    ({ success, errors, redirectUrl }) => {
      if (!success){
        toast.warn(errors[0])
        return
      }

      if (redirectUrl){
        window.location.href = redirectUrl
      }
    },
  )
}

const callbacks = (component, state, setState, userCallbacks) => {
  const { editingDashboard } = state
  const { saveUserDashboard } = userCallbacks

  const componentCallbacks = {
    CreateOrEditAddressModal: {
      closeModal: () => setState({ showCreateOrEditAddressModal: false }),
      createAddress: payload => handleCallbackAction(payload),
      updateAddress: payload => handleCallbackAction(payload),
    },
    CreateProjectModal: {
      closeModal: () => setState({ showCreateProjectModal: false }),
      importProject,
      submitAction: (projectParams, customFields, createFn) => createProject(projectParams, customFields, createFn),
    },
    CustomizableDashboard: {
      toggleEditDashboard: () => setState({ editingDashboard: !editingDashboard }),
      saveDashboard: (dashboardKey, activeLayout) => saveUserDashboard(dashboardKey, activeLayout),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  editingDashboard: false,
  showCreateOrEditAddressModal: false,
  showCreateProjectModal: false,
}

const Overview = () => {
  const [state, setState] = useSetState(defaultState)
  const { editingDashboard, showCreateOrEditAddressModal, showCreateProjectModal } = state

  const { currentUser } = useCurrentUser()
  const { callbacks: userCallbacks } = useUser(currentUser)

  const { selectedOrganization, sortedOrganizations } = useOrganizationSelector()

  const customizedLayout = digObject(currentUser, 'options.custom_dashboards.organization_dashboard')

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const pageContext = useMemo(
    () => ({
      callbacks: {
        showCreateOrEditAddressModal: (payload) => {
          setModalData('CreateOrEditAddressModal', payload)
          setState({ showCreateOrEditAddressModal: true })
        },
      },
    }),
    [],
  )

  useExternalProjectParams({ setState, setModalData })

  const { strings } = useLocalization(localizedStrings)

  const availableModules = useMemo(() => generateAvailableModules(sortedOrganizations, strings), [JSON.stringify(strings)])

  const organizationPayload = useOrganization(selectedOrganization)
  const {
    callbacks: { updateOrganization: updateFn },
    loading,
    organization,
    organization: { onboarding },
  } = organizationPayload

  const onboardingTasksPayload = useUserOnboardingTasks({ performHttpRequests: true })

  const { callbacks: { dispatchMixpanelEvent } } = useMixpanel()

  useEffect(() => {
    dispatchMixpanelEvent('Dashboard Load')
  }, [])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <Helmet>
          <title>{`${strings.helmet?.title} | Engage`}</title>
        </Helmet>

        <PageHeader
          actionContent={
            !editingDashboard && (
              <>
                <Button
                  buttonStyle="primaryCreate"
                  onClick={() => setState({ showCreateProjectModal: true })}
                  size="medium"
                  style={{ marginRight: 8, width: 'auto' }}
                >
                  {strings.pageHeader?.create || 'Create'}
                </Button>

                <IconWithMenu
                  closeOnClick
                  icon={(
                    <Button
                      buttonStyle="secondaryUtility"
                      icon={<FontAwesomeIcon icon={faCog} />}
                      size="medium"
                      style={{ height: 37 }}
                    />
                  )}
                >
                  <IconWithMenu.Menu listStyle={{ right: '-7px' }}>
                    <a key="edit" onClick={() => setState({ editingDashboard: !editingDashboard })} tabIndex="0">
                      <FontAwesomeIcon icon={faEdit} />{' '}
                      {editingDashboard
                        ? strings.pageHeader?.save || 'Save Dashboard'
                        : strings.pageHeader?.customize || 'Customize Dashboard'}
                    </a>
                  </IconWithMenu.Menu>
                </IconWithMenu>
              </>
            )
          }
          activeTabBarItemKey="dashboard"
          boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
          tabBarItems={[
            {
              href: '#/',
              icon: faHome,
              key: 'dashboard',
              title: strings.pageHeader?.dashboard || 'Dashboard',
            },
          ]}
          title={strings.pageHeader?.title || 'Dashboard'}
        />

        <Box flexDirection="column" paddingX="large" paddingTop={[112, 105]}>
          {!loading && onboarding && (
            <DashboardModuleNotice
              icon={faTriangleExclamation}
              title={strings.dashboardModuleNotice?.title || 'Onboarding Mode'}
              body={
                strings.dashboardModuleNotice?.body
                || 'Onboarding mode allows our support team to access your account to assist with account setup. It can be turned off by selecting revoke access.'
              }
              colors={{ background: '#FBF3F2', solid: '#BB6161' }}
              boxProps={{ marginTop: 'large' }}
            >
              <Box alignItems="center" width="auto" style={{ whiteSpace: 'nowrap' }}>
                <Button
                  buttonStyle="secondaryEdit"
                  icon={<FontAwesomeIcon icon={faSignOut} />}
                  loading={loading}
                  marginRight="medium"
                  onClick={() => updateOrganization({ ...organization, onboarding: false }, [], updateFn, strings)}
                  size="medium"
                  width="auto"
                >
                  {strings.dashboardModuleNotice.buttonText || 'Revoke Access'}
                </Button>
              </Box>
            </DashboardModuleNotice>
          )}

          <CustomizableDashboard
            availableModules={availableModules}
            callbacks={callbacks('CustomizableDashboard', state, setState, userCallbacks)}
            columns={[
              {
                id: 'main',
                component: <Content onboardingTasksPayload={onboardingTasksPayload} />,
                title: strings.customizableDashboard?.main || 'Main',
              },
              {
                id: 'sidebar',
                component: <ContentSidebar
                  callbacks={{ customizeDashboard: () => setState({ editingDashboard: !editingDashboard }) }}
                  onboardingTasksPayload={onboardingTasksPayload}
                />,
                title: strings.customizableDashboard?.sidebar || 'Sidebar',
              },
            ]}
            customizedLayout={customizedLayout}
            dashboardKey="organization_dashboard"
            dashboardReady={!!currentUser.id}
            defaultLayout={dashboardDefaultLayout(strings)}
            editing={editingDashboard}
          />
        </Box>

        <CreateProjectModal
          callbacks={callbacks('CreateProjectModal', state, setState, userCallbacks)}
          showModal={showCreateProjectModal}
        />

        <CreateOrEditAddressModal
          callbacks={callbacks('CreateOrEditAddressModal', state, setState, userCallbacks)}
          headerTitle={strings.addressModalHeaderTitle || 'Project'}
          hiddenFields={['title']}
          showModal={showCreateOrEditAddressModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default Overview
