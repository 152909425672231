export default {
  default: {
    formFieldLabels: {
      manualAddress: 'Enter Address manually',
      rating: '* Rating/Satisfaction',
      review: '* Review',
      reviewTitle: '* Review Title',
      reviewUsers: 'Review Users',
      searchUsers: '* Search Users',
      vendorName: '* Vendor Name(s)',
    },
  },
}
