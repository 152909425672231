import { useContext, useMemo } from 'react'
import cloneDeep from 'lodash/cloneDeep'

import { useForm, useLatestEntity, useWatchEntityUpdates } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import {
  deepSetObject, digObject, generateApplicationUrl, launchModal, matchFilterNumber, sortArrayBy,
} from '@campaignhub/javascript-utils'

import useCurrentUser from '@hooks/useCurrentUser'
import useDispatch from '@hooks/useDispatch'
import useSelector from '@hooks/useSelector'

import generateRedirectUrl from '@functions/generateRedirectUrl'

import * as userActions from '@redux/modules/user'

import defaultFormState, { requiredFields } from '@models/user'

import PageContext from '@contexts/pageContext'

import type { AppDispatch } from '@redux/store'
import type { CustomFieldModel, UserModel, UserRequestOptions } from '@models/types'

const watchEntityKeys = ['dataStoreItems', 'images']

export const generateUrls = (isAdminOrBrandUser: boolean | string, user?: Partial<UserModel>) => {
  const { id, realbase_id } = user || {}

  const { applicationUrlWithHash } = generateApplicationUrl({
    applicationKey: 'realbase',
    environment: process.env.REACT_APP_APPLICATION_ENV,
    hash: `/#/account/users/${realbase_id}/edit/`,
  })

  const editUrl = isAdminOrBrandUser
    ? `#/systemManager/users/${id}/edit?redirect=${generateRedirectUrl()}`
    : `#/admin/users/${id}/edit`

  const editUserUrl = process.env.REACT_APP_APPLICATION_ENV !== 'production' ? applicationUrlWithHash : editUrl

  return {
    editUserUrl: editUrl,
    indexUsersUrl: '#/admin/users',
  }
}

type GenerateSupportTokenParams = {
  dispatch: AppDispatch,
  requestOptions: UserRequestOptions,
  user: UserModel,
}

const generateSupportToken = (params: GenerateSupportTokenParams) => {
  const { dispatch, requestOptions, user } = params
  const { generateSupportToken: generateFn } = userActions

  return dispatch(generateFn(user, requestOptions))
}

type RevokeSupportTokenParams = {
  dispatch: AppDispatch,
  requestOptions: UserRequestOptions,
  user: UserModel,
}

const revokeSupportToken = (params: RevokeSupportTokenParams) => {
  const { dispatch, requestOptions, user } = params
  const { revokeSupportToken: revokeFn } = userActions

  return dispatch(revokeFn(user, requestOptions))
}

type RequestSupportTokenParams = {
  dispatch: AppDispatch,
  requestOptions: UserRequestOptions,
  user: UserModel,
}

const requestSupportToken = (params: RequestSupportTokenParams) => {
  const { dispatch, requestOptions, user } = params
  const { requestSupportToken: requestFn } = userActions

  return dispatch(requestFn(user, requestOptions))
}

type UserPayload = {
  customFields: CustomFieldModel[],
  userParams: Partial<UserModel>,
}

type UpdateUserParams = {
  dispatch: AppDispatch,
  payload: UserPayload,
  requestOptions?: UserRequestOptions,
  user: UserModel,
}

export const updateUser = (params: UpdateUserParams) => {
  const {
    dispatch, payload, requestOptions, user,
  } = params

  const { userParams, customFields } = payload
  const { options } = userParams

  const { updateUser: updateFn } = userActions

  const updatedParams = {
    id: user.id,
    ...userParams,
  }

  // Prevent overwriting user options accidentally
  // in case all options are not passed back in params object
  if (options){
    const userOptions = user.options || {}
    updatedParams.options = JSON.stringify({
      ...cloneDeep(userOptions),
      ...options,
    })
  }

  return dispatch(updateFn(updatedParams, customFields, requestOptions))
}

type SaveUserDashboardParams = {
  activeLayout: string,
  dashboardKey: string,
  dispatch: AppDispatch,
  requestOptions: UserRequestOptions,
  user: UserModel,
}

const saveUserDashboard = (params: SaveUserDashboardParams) => {
  const {
    dashboardKey, activeLayout, user, dispatch, requestOptions,
  } = params
  const updatedUser = deepSetObject(user, `options.custom_dashboards.${dashboardKey}`, activeLayout)
  const updatedUserPayload = { userParams: updatedUser, customFields: [] }

  return updateUser({
    dispatch, payload: updatedUserPayload, requestOptions, user,
  })
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useUserForm(
  user: Partial<UserModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const userForm = useForm(
    defaultFormState,
    { entity: user, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [user.id, user.cache_key],
  )

  return {
    ...userForm,
  }
}

export const useRelations = (user: Partial<UserModel> = {}) => {
  const { default_image_id, role_id } = user

  const entities = useSelector(reduxState => reduxState.entities)

  const { images, roles } = entities
  const defaultImage = default_image_id ? images[default_image_id] || {} : {}
  const role = role_id ? roles[role_id] || {} : {}

  return {
    defaultImage,
    entities,
    role,
  }
}

function useUser(initEntity: Partial<UserModel> = {}) {
  const { entity: user }: { entity: UserModel} = useLatestEntity(initEntity, 'users')

  const dispatch = useDispatch()

  const {
    updatedEntities: { dataStoreItems: dataStoreItemsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { default_organization_id } = user

  const { creating, loading, updating } = useSelector(reduxState => reduxState.users)

  const userRelations = useRelations(user)
  const { defaultImage, role } = userRelations

  const { callbacks } = useContext(PageContext)

  const entities = useSelector(reduxState => reduxState.entities)
  const { dataStoreItems, organizations } = entities

  const filteredDataStoreItems = useMemo(() => {
    const filtered = Object.values(dataStoreItems)
      .filter(dataStoreItem => matchFilterNumber(user.id, dataStoreItem.subject_id))

    return sortArrayBy(filtered, 'asc', 'title')
  }, [user.id, dataStoreItemsUpdatedAt])

  const userOrganizationIds = digObject(user, 'organization_ids', []) as number[]
  const userOrganizations = userOrganizationIds.map(id => organizations[id]).filter(org => org)

  const { isAdmin, isBrandUser } = useCurrentUser()

  const isAdminOrBrandUser = isAdmin || isBrandUser

  return {
    callbacks: {
      editUser: () => launchModal({
        callbacks,
        modalKey: 'CreateOrEditUserModal',
        payload: {
          callbacks: {
            updateUser: (userPayload: UserPayload, entityOptions?: UserRequestOptions) => (
              updateUser({
                dispatch, payload: userPayload, requestOptions: entityOptions, user,
              })
            ),
          },
          user,
        },
      }),
      generateSupportToken: (
        entityOptions: UserRequestOptions,
      ) => generateSupportToken({ dispatch, requestOptions: entityOptions, user }),
      requestSupportToken: (
        entityOptions: UserRequestOptions,
      ) => requestSupportToken({ dispatch, requestOptions: entityOptions, user }),
      revokeSupportToken: (
        entityOptions: UserRequestOptions,
      ) => revokeSupportToken({ user, dispatch, requestOptions: entityOptions }),
      saveUserDashboard: (
        dashboardKey: string,
        activeLayout: string,
        entityOptions: UserRequestOptions,
      ) => saveUserDashboard({
        activeLayout, dashboardKey, dispatch, requestOptions: entityOptions, user,
      }),
      updateUser: (userPayload: UserPayload, entityOptions?: UserRequestOptions) => (
        updateUser({
          dispatch, payload: userPayload, requestOptions: entityOptions, user,
        })
      ),
    },
    creating,
    defaultImage,
    default_organization_id,
    filteredDataStoreItems,
    hasOrganizations: !!userOrganizations.length,
    loading,
    role,
    updating,
    user,
    userOrganizations,
    urls: generateUrls(isAdminOrBrandUser, user),
  }
}

export default useUser
