import React from 'react'
import PropTypes from 'prop-types'

import FormResponseField from '../FormResponseField'

const FormResponseFields = (props) => {
  const { responseFields } = props

  return (
    <>
      {Object.entries(responseFields).map(([key, value]) => (
        <FormResponseField label={key} value={value} />
      ))}
    </>
  )
}

FormResponseFields.propTypes = {
  responseFields: PropTypes.object,
}

export default FormResponseFields
