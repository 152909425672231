import {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react'
import PropTypes from 'prop-types'
import { useAuth0 } from '@auth0/auth0-react'

import { getAccessToken } from '@functions/accessToken'

import useCountries from '@hooks/useCountries'
import useCurrentUser from '@hooks/useCurrentUser'
import useDigitalTemplateTypes from '@hooks/useDigitalTemplateTypes'
import useIntegrationPlatforms from '@hooks/useIntegrationPlatforms'
import useMixpanel from '@hooks/useMixpanel'
import useOrganizations from '@hooks/useOrganizations'
import useOrganizationSelector from '@hooks/useOrganizationSelector'
import useOrganizationTypes from '@hooks/useOrganizationTypes'
import useProjectTypes from '@hooks/useProjectTypes'
import useRoles from '@hooks/useRoles'
import useStatuses from '@hooks/useStatuses'

import AdminRoutes from '@sections/Admin/routes'
import ClientRoutes from '@sections/Client/routes'
import Share from '@sections/Share'
import Login from '@sections/Auth/Login'

const adminRoles = ['Admin', 'Support']
const brandRoles = ['Brand Administrator']

// When logging in the token is not instantly available
// We wait and check 0.1 seconds later for it to be available

type SetAccessToken = (token: string | null) => Dispatch<SetStateAction<string | null>>

const waitForAccessToken = (setAccessToken: SetAccessToken) => {
  const intervalId = setInterval(() => {
    const token = getAccessToken()
    if (token){
      clearInterval(intervalId)
      setAccessToken(token)
    }
  }, 100)
}

type AuthenticationControllerProps = {
  shareLinkToken: string,
}

const AuthenticationController = (props: AuthenticationControllerProps) => {
  const { shareLinkToken } = props

  const [accessToken, setAccessToken] = useState(null)

  const auth0Payload = useAuth0()
  const { isAuthenticated, user } = auth0Payload

  useEffect(() => {
    if (!shareLinkToken){
      waitForAccessToken(setAccessToken)
    }
  }, [shareLinkToken])

  const { 'https://www.realhubapp.com/role': userRole } = user || {}

  // Access token needs to be present to make requests
  const isAuthenticatedBrandUser = accessToken && isAuthenticated && brandRoles.includes(userRole)
  const isAuthenticatedUser = accessToken && isAuthenticated && !adminRoles.includes(userRole)
  const isAuthenticatedAdmin = accessToken && isAuthenticated && adminRoles.includes(userRole)

  const performHttpRequests = isAuthenticatedUser || isAuthenticatedAdmin

  // Need these entities on client side for localization.
  // If a user is not an admin we redirect to pages that are accessible
  const {
    callbacks: { selectOrganizationId },
    selectedOrganization,
  } = useOrganizationSelector({ autoSelect: isAuthenticatedUser })

  const currentUserPayload = useCurrentUser({ performHttpRequests })
  const { currentUser, impersonatedUser, isImpersonatingUser } = currentUserPayload

  useCountries({ performHttpRequests })
  useDigitalTemplateTypes({ performHttpRequests })
  useIntegrationPlatforms({ performHttpRequests })
  useOrganizationTypes({ performHttpRequests })
  useOrganizations({ performHttpRequests })
  useProjectTypes({ performHttpRequests })
  useRoles({ performHttpRequests })
  useStatuses({ performHttpRequests })

  const mixpanelPayload = useMixpanel()
  const {
    callbacks: { initMixpanel },
  } = mixpanelPayload

  // Initializing Mixpanel
  initMixpanel()

  // Auto Select Impersonated User Organization
  useEffect(() => {
    if (impersonatedUser.default_organization_id){
      selectOrganizationId(impersonatedUser.default_organization_id)
    }
  }, [impersonatedUser.default_organization_id])

  if (shareLinkToken){
    return <Share token={shareLinkToken} />
  }

  if (!accessToken || !isAuthenticated || !currentUser.id){
    return <Login />
  }

  if ((isAuthenticatedAdmin || isAuthenticatedBrandUser) && !isImpersonatingUser){
    return <AdminRoutes />
  }

  if (!selectedOrganization.id){
    // On Client side we want to make sure loading screen
    // is shown until Organization is selected
    return <Login />
  }

  return <ClientRoutes />
}

AuthenticationController.propTypes = {
  shareLinkToken: PropTypes.string,
}

export default AuthenticationController
