export default {
  default: {
    buttons: {
      enterAddress: 'Enter Address Manually',
    },
    formFieldLabels: {
      caseStudyUsers: 'Case Study Users',
      searchUsers: '* Search Users',
    },
  },
}
