export default {
  default: {
    formFieldLabels: {
      findAssetLibrary: 'Find File Library',
    },
    mapResults: {
      organization: {
        title: '{global.organizationType.shortName.singular}',
        secondaryText: '{global.organizationType.shortName.singular} Library',
      },
      brand: {
        title: 'Brand',
        secondaryText: 'Brand Library',
      },
    },
    imageLibrarySearchPlaceholder: 'Search for file library',
    selectedFileLibrary: 'Selected File Library',
  },
}
