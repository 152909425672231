interface CustomLabelProps {
  x: number,
  y: number,
  value: number,
}

const CustomLabel = (props: CustomLabelProps) => {
  const { x, y, value } = props

  return (

    <text x={x} y={y} dy={-8} fontSize={10} textAnchor="middle">
      {value}
    </text>
  )
}

export default CustomLabel
