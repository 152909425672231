import agentboxUrl from './agentbox.svg'
import boxdiceUrl from './boxdice.svg'
import campaigntrackUrl from './campaigntrack.svg'
import coreLogicUrl from './core_logic.svg'
import domainGroupUrl from './domain_group.svg'
import harcourtsUrl from './harcourts.svg'
import nurtureCloudUrl from './nurture_cloud_logo.svg'
import pdolUrl from './pdol.svg'
import pricefinderUrl from './pricefinder.svg'
import realhubUrl from './realhub.svg'
import realtimeAgent from './realTimeAgent.svg'
import reiFormsUrl from './rei_forms.svg'
import reinzLogoUrl from './reinzLogo.svg'
import rexUrl from './rex.svg'
import vaultreUrl from './vaultre.svg'

const platformLogos = {
  agentbox: agentboxUrl,
  boxdice: boxdiceUrl,
  campaigntrack: campaigntrackUrl,
  core_logic_australia: coreLogicUrl,
  core_logic_new_zealand: coreLogicUrl,
  domain_group: domainGroupUrl,
  harcourts_one_australia: harcourtsUrl,
  harcourts_one_new_zealand: harcourtsUrl,
  nurture_cloud: nurtureCloudUrl,
  pdol: pdolUrl,
  pricefinder: pricefinderUrl,
  real_time_agent: realtimeAgent,
  realhub: realhubUrl,
  rei_forms: reiFormsUrl,
  reinz: reinzLogoUrl,
  rex: rexUrl,
  vaultre: vaultreUrl,
}

export default platformLogos
