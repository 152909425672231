import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, ListItem, Text,
} from '@campaignhub/suit-theme'

import useIntegrationPlatform from '@hooks/useIntegrationPlatform'

import IntegrationPlatformLogo from '@components/IntegrationPlatformLogo'

const ExternalPlatform = (props) => {
  const { externalId, integrationPlatform } = props

  const integrationPlatformPayload = useIntegrationPlatform(integrationPlatform)
  const { integrationPlatform: { key, title } } = integrationPlatformPayload

  return (
    <ListItem boxProps={{ borderBottom: '1px solid', padding: 'large' }} disableHover>
      <Box
        border="1px solid"
        borderColor="lineColor"
        borderRadius={5}
        height={40}
        marginRight="large"
        padding="large"
        variant="center"
        width={60}
      >
        <IntegrationPlatformLogo
          backgroundSize="contain"
          border="1px solid"
          borderColor="lineColor"
          borderRadius={5}
          height={40}
          integrationPlatformKey={key}
          width={60}
        />
      </Box>

      <Box flexDirection="column" justifyContent="center">
        <Text fontSize="small">
          {title || 'Unknown Platform'}
        </Text>

        <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="medium">
          {externalId || 'No External ID'}
        </Text>
      </Box>
    </ListItem>
  )
}

ExternalPlatform.propTypes = {
  externalId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  integrationPlatform: PropTypes.object.isRequired,
}

export default ExternalPlatform
