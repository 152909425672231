import React from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, FormField, SidebarModal,
} from '@campaignhub/suit-theme'

import { useForm } from '@campaignhub/react-hooks'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const confirmDelete = (removeFn, strings) => {
  swal
    .fire({
      title: strings.swal?.title || 'Remove Platform?',
      text: strings.swal?.text || 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: strings.swal?.confirmButtonText || 'Yes, Remove it.',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        removeFn()
      }
    })
}

const requiredFields = [
  { key: 'externalId' },
  { key: 'externalPlatformKey' },
]

const defaultFormState = {
  externalId: '',
  externalPlatformKey: '',
}

const AddOrEditPlatform = (props) => {
  const {
    callbacks: { handleSave, removePlatform }, deps, disablePlatformSelect, entity, loading, platforms,
  } = props

  const formPayload = useForm(
    defaultFormState,
    { entity, requiredFields, validateOn: 'change' },
    [...deps],
  )

  const {
    entityState,
    entityState: { externalId, externalPlatformKey },
    handlers,
    saveEnabled,
    setEntityState,
  } = formPayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      <SidebarModal.Content>
        <Box flexShrink={0} flexDirection="column">
          <FormField direction="column" label={strings.formFieldLabels?.platform || '* Platform'}>
            <select
              disabled={disablePlatformSelect}
              name="externalPlatformKey"
              value={externalPlatformKey}
              onChange={e => setEntityState({ externalPlatformKey: e.target.value, externalId: '' })}
            >
              <option value="">{strings.pleaseSelect || 'Please Select...'}</option>

              {platforms.map(platform => (
                <option key={platform.key} value={platform.key}>{platform.title}</option>
              ))}
            </select>
          </FormField>

          <FormField direction="column" label={strings.formFieldLabels?.id || '* ID'} marginTop="large">
            <input name="externalId" value={externalId} {...handlers} />
          </FormField>

          {entity.externalId && (
            <FormField
              direction="column"
              label={strings.formFieldLabels?.moreOptions || 'More Options'}
              marginTop="large"
            >
              <Button
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faTrashAlt} />}
                onClick={() => confirmDelete(removePlatform, strings)}
                size="medium"
                style={{ marginBottom: '8px' }}
              >
                {strings.buttons?.removePlatform || 'Remove Platform'}
              </Button>
            </FormField>
          )}
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={loading}
          onClick={() => handleSave(entityState)}
          size="large"
        >
          {strings.buttons?.save || 'Save Platform'}
        </Button>
      </SidebarModal.Footer>
    </>
  )
}

AddOrEditPlatform.propTypes = {
  callbacks: PropTypes.shape({
    handleSave: PropTypes.func.isRequired,
    removePlatform: PropTypes.func.isRequired,
  }).isRequired,
  deps: PropTypes.array,
  disablePlatformSelect: PropTypes.bool,
  entity: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  platforms: PropTypes.array.isRequired,
}

AddOrEditPlatform.defaultProps = {
  deps: [],
}

export default AddOrEditPlatform
