import PropTypes from 'prop-types'

import {
  FormField, LoadingModule, SectionDivider, SidebarModal,
} from '@campaignhub/suit-theme'

import { useSetState } from '@campaignhub/react-hooks'

import useLocalization from '@hooks/useLocalization'

import useIntegrationPlatforms from '@hooks/useIntegrationPlatforms'
import useOrganizationSelector from '@hooks/useOrganizationSelector'

import useCurrentUser from '@hooks/useCurrentUser'
import IntegrationPlatformBlankState from './components/IntegrationPlatformBlankState'
import IntegrationPlatformTile from './components/IntegrationPlatformTile'
import localizedStrings from './localizedStrings'

const defaultState = {
  string: '',
}

const SelectIntegrationPlatformModal = (props) => {
  const { callbacks, modalKey, showModal } = props

  const [state, setState] = useSetState(defaultState)
  const { string } = state

  const { isSelectedOrganizationAdmin } = useOrganizationSelector()
  const { isAdmin } = useCurrentUser()

  const integrationPlatformPayload = useIntegrationPlatforms({
    filters: {
      integrationScope: (isSelectedOrganizationAdmin || isAdmin) ? null : 'user',
      string,
    },
  })
  const { filteredIntegrationPlatforms, hasIntegrationPlatforms, loading } = integrationPlatformPayload

  const priorityIntegrationPlatformKeys = ['real_time_agent', 'realhub', 'campaigntrack']

  const priorityIntegrationPlatforms = filteredIntegrationPlatforms
    .filter(integrationPlatform => priorityIntegrationPlatformKeys.includes(integrationPlatform.key))
    .sort((a, b) => priorityIntegrationPlatformKeys.indexOf(a.key) - priorityIntegrationPlatformKeys.indexOf(b.key))

  // eslint-disable-next-line max-len
  const sortedIntegrationPlatforms = Array.from(new Set([...priorityIntegrationPlatforms, ...filteredIntegrationPlatforms]))

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.header?.title || 'Select'}
        titleSecondLine={strings.header?.titleSecondLine || 'Platform'}
      />

      <SidebarModal.Content hasFooter={false}>
        <FormField direction="column" label={strings.formField?.label || 'Filter Platforms'}>
          <input
            onChange={e => setState({ string: e.target.value })}
            placeholder={strings.formField?.placeholder || 'Find a platform'}
            value={string}
          />
        </FormField>

        <SectionDivider boxProps={{ marginY: 'medium', flexShrink: 0 }}>
          {strings.divider?.text || 'PLATFORMS'}
        </SectionDivider>

        <LoadingModule loading={loading} times={3} />

        {!loading && !hasIntegrationPlatforms && <IntegrationPlatformBlankState />}

        {!loading
          && hasIntegrationPlatforms
          && sortedIntegrationPlatforms.map(platform => (
            <IntegrationPlatformTile key={platform.id} integrationPlatform={platform} />
          ))}
      </SidebarModal.Content>
    </SidebarModal>
  )
}

SelectIntegrationPlatformModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

SelectIntegrationPlatformModal.defaultProps = {
  modalKey: 'SelectIntegrationPlatformModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <SelectIntegrationPlatformModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
