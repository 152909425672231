import React from 'react'
import PropTypes from 'prop-types'

import { FileUploadZone } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'
import useUppyAttachmentUpload from '@hooks/useUppyAttachmentUpload'

import localizedStrings from './localizedStrings'

const AttachmentUpload = (props) => {
  const {
    attachmentType,
    options: customOptions,
    ownerId,
    ownerType,
    requestOptions,
    subjectId,
    subjectType,
  } = props

  const options = {
    attachmentParams: {
      attachment_type: attachmentType,
      owner_id: ownerId,
      owner_type: ownerType,
      subject_id: subjectId,
      subject_type: subjectType,
    },
    requestOptions,
    ...customOptions,
  }

  const uppy = useUppyAttachmentUpload(options, [subjectId, subjectType])

  const { strings } = useLocalization(localizedStrings)

  if (!uppy) return null

  return (
    <FileUploadZone
      boxProps={{ margin: 'large', width: ['auto', '100%'] }}
      subTitle={strings.maxFileSizeNote || 'Max 50mb each'}
      uppy={uppy}
    />
  )
}

AttachmentUpload.propTypes = {
  attachmentType: PropTypes.string,
  options: PropTypes.object,
  ownerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ownerType: PropTypes.string.isRequired,
  requestOptions: PropTypes.object,
  subjectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  subjectType: PropTypes.string.isRequired,
}

export default AttachmentUpload
