import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, Image, ListItem, StatusBadge, Text,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import DigitalPagePlaceholderImage from '@components/DigitalPagePlaceholderImage'
import EngagementStatusBadge from '@components/EngagementStatusBadge'

import useDigitalPage from '@hooks/useDigitalPage'
import useDigitalPageTheme from '@hooks/useDigitalPageTheme'
import useDigitalTemplate from '@hooks/useDigitalTemplate'
import useEngagementStatus from '@hooks/useEngagementStatus'

import { statusColor } from '@functions/status'

const Tile = (props) => {
  const { digitalPage } = props

  const digitalPagePayload = useDigitalPage(digitalPage)
  const {
    digitalPage: { engagement_status },
    digitalTemplate,
    project: { title },
    status,
    urls: { editDigitalPageUrl },
  } = digitalPagePayload

  const { previewImage } = useDigitalTemplate(digitalTemplate)
  const previewImageUrl = digObject(previewImage, 'sizes.thumb_200x120', '')

  const { engagementStatusTheme } = useEngagementStatus(engagement_status)
  const { colors, digitalTemplateTypeKey } = useDigitalPageTheme(digitalPage)

  return (
    <Box
      flexDirection="column"
      flexShrink="0"
      variant="white"
    >
      <Image
        borderTopRadius="5px 5px 0 0"
        boxProps={{
          as: 'a',
          height: '150px',
          href: editDigitalPageUrl,
          position: 'relative',
        }}
        url={previewImageUrl}
      >
        <EngagementStatusBadge
          boxProps={{
            paddingY: 'small',
            position: 'absolute',
            top: 8,
            left: 8,
            width: '65px',
          }}
          engagementStatusTheme={engagementStatusTheme}
          invertColors
        />

        {!previewImageUrl && (
        <Box
          background={colors.background}
          justifyContent="center"
        >
          <DigitalPagePlaceholderImage
            color={colors.solid}
            digitalTemplateTypeKey={digitalTemplateTypeKey}
            height={150}
            width={150}
          />
        </Box>
        )}

        <StatusBadge
          boxProps={{
            position: 'absolute',
            left: 8,
            bottom: 8,
          }}
          color={statusColor()}
          text={status.title}
        />
      </Image>

      <ListItem
        boxProps={{ as: 'a', padding: 'large' }}
        href={editDigitalPageUrl}
        showAngleIcon
      >
        <Box justifyContent="center" flexDirection="column">
          <Text fontSize="small" variant="ellipsis">{title}</Text>
          <Text color="bodyFontLightColor" variant="ellipsis" fontSize="xsmall" marginTop="medium">
            {digitalTemplate.title}
          </Text>
        </Box>
      </ListItem>
    </Box>
  )
}

Tile.propTypes = {
  digitalPage: PropTypes.object.isRequired,
}

export default Tile
