export default {
  default: {
    sweetAlert: {
      confirmButton: 'Yes, delete it.',
      text: 'Are you sure? ',
      title: 'Delete Item?',
    },
    formFieldLabels: {
      title: '* Title',
      price: 'Price',
      date: 'Date',
      description: 'Description',
      moreOptions: 'More Options',
    },
    deleteButton: 'Delete Item',
    saveButton: 'Save Item',
    createTitle: 'Create',
    editTitle: 'Edit',
    titleSecondLine: 'Quote Item',
    toastText: {
      created: 'Quote Saved and New Line Added.',
      updated: 'Quote Item Updated.',
    },
  },
}
