import { useEffect, useState } from 'react'
import interact from 'interactjs'

const validateItemType = (itemType, acceptedTypes, excludedTypes) => {
  if (excludedTypes.length) return !excludedTypes.includes(itemType)
  if (acceptedTypes.length) return acceptedTypes.includes(itemType)
  return false
}

const onDragEnter = (e, acceptedTypes, excludedTypes, setIsActive) => {
  const itemType = e.relatedTarget.getAttribute('data-item-type')

  if (validateItemType(itemType, acceptedTypes, excludedTypes)){
    setIsActive(true)
  }
}

const onDragLeave = (setIsActive) => {
  setIsActive(false)
}

const getDropIndex = (droppedItem, dropTarget, dropRect) => {
  const { children: directChildren } = dropTarget
  const { y } = droppedItem

  let dropIndex = 0

  // If we have a content holder we need to use its children
  const contentHolder = Array.from(dropTarget.children).find(child => child.dataset.contentHolder)
  const children = contentHolder ? contentHolder.children : directChildren

  for (let i = 0; i <= children.length; i += 1){
    const childRect = children[i] ? interact.getElementRect(children[i]) : null
    if (childRect){
      const relY = childRect.top - dropRect.top

      // The item has been dropped above this element
      if (y < relY) break

      dropIndex += 1
    }
  }

  return dropIndex
}

const onDrop = (e, entity, acceptedTypes, excludedTypes, dropItemFn, setIsActive) => {
  const itemType = e.relatedTarget.getAttribute('data-item-type')

  if (validateItemType(itemType, acceptedTypes, excludedTypes)){
    setIsActive(false)

    const dropRect = interact.getElementRect(e.target)
    const dragRect = interact.getElementRect(e.relatedTarget)

    const x = dragRect.left - dropRect.left
    const y = dragRect.top - dropRect.top
    const { height, width } = dragRect

    if (dropItemFn){
      const dropIndex = getDropIndex({ y }, e.target, dropRect)

      const dropData = {
        dataset: { ...e.relatedTarget.dataset },
        dropIndex,
        entity,
        itemType,
        x,
        y,
        width,
        height,
      }

      dropItemFn(dropData)
    }
  }
}

function useDropTarget(dropElement, entity, options = {}){
  const { acceptedTypes = [], callbacks, excludedTypes = [] } = options || {}
  const { dropItem: dropItemFn } = callbacks || {}

  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    if (!dropElement || !dropElement.current){
      return undefined
    }

    const interactable = interact(dropElement.current)

    interactable
      .dropzone({
        accept: '.draggable',
        overlap: 'pointer',
        ondragenter: e => onDragEnter(e, acceptedTypes, excludedTypes, setIsActive),
        ondragleave: () => onDragLeave(setIsActive),
        ondrop: e => onDrop(e, entity, acceptedTypes, excludedTypes, dropItemFn, setIsActive),
      })

    return () => (interactable ? interactable.unset() : null)
  }, [options.disabled])

  return {
    isActive,
  }
}

export default useDropTarget
