export default {
  default: {
    addressModalHeaderTitle: 'Project',
    component: {
      title: '{global.digitalTemplateType.title.plural}',
    },
    toast: {
      shareLinkCreated: 'Share Link Created Successfully',
      shareLinkDeleted: 'Share Link Deleted Successfully',
      shareLinkUpdated: 'Share Link Updated Successfully',
      send: 'Messages queued for delivery',
    },
  },
}
