import React from 'react'
import PropTypes from 'prop-types'

import { Box, FormField } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'
import useReduxAction from '@hooks/useReduxAction'

import Search from '@components/Search'

import SelectedEntityItem from '../SelectedEntityItem'

import localizedStrings from './localizedStrings'

const mapProjectResults = ({ entities: projects }, strings) => ({
  groups: [
    {
      key: 'projects',
      title: strings.mapResults?.title || 'PROJECTS',
      items: projects.map(project => ({
        id: project.id,
        key: project.id,
        text: project.title,
      })),
    },
  ],
})

const Project = (props) => {
  const {
    fileSelectPayload: {
      callbacks: { selectEntity, setState },
      filteredEntityAttachments,
      selectedAttachmentIds,
      selectedEntity,
      selectedEntityType,
      selectedEntityId,
    },
  } = props

  // Load Selected Project
  useReduxAction(
    'projects',
    'loadProject',
    {},
    [selectedEntityType, selectedEntityId],
    {
      dispatchAction: (action, requestOptions) => action(selectedEntityId, requestOptions),
      shouldPerformFn: (entityReducer) => {
        const { loadedIds, loading, errors } = entityReducer
        return (
          selectedEntityType === 'Project'
          && selectedEntityId
          && !loadedIds.includes(selectedEntityId)
          && !loading
          && !errors.length
        )
      },
    },
  )

  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      {!selectedEntity.id && (
        <FormField
          boxProps={{ marginBottom: 'large' }}
          label={strings.formFieldLabels?.findProject || 'Find a Project'}
        >
          <Search
            callbacks={{
              selectItem: selected => selectEntity({ type: 'Project', id: selected.id }),
              mapResults: data => mapProjectResults(data, strings),
            }}
            endpoint="/projects.json"
            placeholder={strings.projectSearchPlaceholder || 'Search for a project'}
          />
        </FormField>
      )}

      {!!selectedEntity.id && (
        <>
          <FormField
            boxProps={{ marginBottom: 'large' }}
            label={strings.selectedProject || 'Selected Project'}
          >
            <SelectedEntityItem
              assetLibrary={selectedEntity}
              callbacks={{ removeSelectedEntity: () => selectEntity({ type: 'Project', id: 0 }) }}
            />
          </FormField>

          <FormField
            boxProps={{ marginBottom: 'large' }}
            label={strings.selectFile || 'Select File'}
          >
            <Box>
              <select
                onChange={e => setState({ selectedAttachmentIds: [...selectedAttachmentIds, Number(e.target.value)] })}
                value=""
              >
                <option value="">{strings.select?.option || 'Select...'}</option>
                {filteredEntityAttachments.map(attachment => (
                  <option key={attachment.data?.id} value={attachment.id}>
                    {attachment.data?.title}
                  </option>
                ))}
              </select>
            </Box>
          </FormField>
        </>
      )}
    </>
  )
}

Project.propTypes = {
  fileSelectPayload: PropTypes.shape({
    callbacks: PropTypes.shape({
      selectEntity: PropTypes.func.isRequired,
      setState: PropTypes.func.isRequired,
    }).isRequired,
    selectedAttachmentIds: PropTypes.array,
    filteredEntityAttachments: PropTypes.array,
    selectedEntity: PropTypes.object.isRequired,
    selectedEntityId: PropTypes.number.isRequired,
    selectedEntityType: PropTypes.string.isRequired,
  }).isRequired,
}

export default Project
