import { useForm } from '@campaignhub/react-hooks'

import type { UseFormOptions } from '@campaignhub/react-hooks'

export type DataSetModel = {
  owner_id: number | null,
  owner_type: string,
  subject_id: number | null,
  subject_type: string,
  title: string,
  brand_id?: number | null,
  filename?: string,
}

export const requiredFields: Array<{ key: keyof DataSetModel }> = [
  { key: 'owner_id' },
  { key: 'owner_type' },
  { key: 'subject_id' },
  { key: 'subject_type' },
  { key: 'title' },
]

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useModalDataSetForm(
  dataSet: Partial<DataSetModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options

  const defaultFormState: DataSetModel = {
    owner_id: dataSet.owner_id || 1,
    owner_type: dataSet.owner_type || 'System',
    subject_id: dataSet.brand_id || null,
    subject_type: dataSet.subject_type || '',
    title: dataSet.title || '',
    filename: dataSet.filename || '',
  }

  const dataSetForm = useForm(
    defaultFormState,
    { entity: dataSet, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [],
  )

  return {
    ...dataSetForm,
  }
}
