import React from 'react'

import { BlankState } from '@campaignhub/suit-theme'

import BlankStateImageUrl from './assets/no-form-templates.svg'

const FormTemplatesBlankState = () => (
  <BlankState boxProps={{ marginBottom: ['large', 0] }} imageUrl={BlankStateImageUrl}>
    <BlankState.Title>
      No Form Templates
    </BlankState.Title>

    <BlankState.Paragraph>
      You either haven't created any templates, or you need to adjust your filters to return more results.
    </BlankState.Paragraph>

    <BlankState.Paragraph>
      If you haven't started yet you can create one now from the button at the top of the page or from an existing Form.
    </BlankState.Paragraph>
  </BlankState>
)

export default FormTemplatesBlankState
