import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { digObject } from '@campaignhub/javascript-utils'
import { useSetState } from '@campaignhub/react-hooks'

import replaceTextShortCodes from '@functions/replaceTextShortCodes'

import useDataStoreItem from '@hooks/useDataStoreItem'
import useDocumentRecipient, {
  getRecipientSubject,
  useRelations as useRecipientRelations,
} from '@hooks/useDocumentRecipient'

import { useRelations as useProjectRelations } from '@hooks/useProject'

const adjustValueForZoom = (value, zoom) => value * zoom

const editableDataContextKeys = ['contact', 'project', 'project_comparables', 'user']

const inlineEditableDataContextKeys = ['project']

const nonEditableItemTypes = ['date', 'initial', 'signature']

const getInputComponent = (documentTemplatePageItem) => {
  const { item_type } = documentTemplatePageItem

  if (['text'].includes(item_type)){
    return 'input'
  }

  return item_type
}

const getSubject = (dataContext, agreement) => {
  if (dataContext === 'project'){
    return {
      id: digObject(agreement, 'project_id'),
      type: 'Project',
    }
  }

  return {}
}

const findDataStoreItem = (params = {}) => {
  const {
    dataStoreItems, isCustomField, key, subjectId, subjectType,
  } = params

  if (!isCustomField){
    return {}
  }

  const dataStoreItem = dataStoreItems.find((item) => {
    const { key: itemKey, subject_id, subject_type } = item

    return itemKey === key && subject_id === subjectId && subject_type === subjectType
  })

  return dataStoreItem || {}
}

const updateDataStore = (params) => {
  const {
    agreement,
    createOrUpdateDataStoreItem: createFn,
    dataStoreItemSubject,
    documentTemplatePageItem: { key, label, required },
    value,
  } = params

  const payload = {
    behaviour: 'replace',
    data: {
      hidden: false,
      required,
      secured: false,
    },
    key,
    label,
    owner_id: agreement.owner_id,
    owner_type: agreement.owner_type,
    subject_id: dataStoreItemSubject.id,
    subject_type: dataStoreItemSubject.type,
    value,
  }

  return createFn(payload)
}

const getShortcodeValue = (params) => {
  const {
    documentTemplatePageItem: { document_recipient_id, key, value },
    isEditableInline,
    recipientShortcodeData,
    shortcodeData,
  } = params

  if (isEditableInline){
    return ''
  }

  if (!value && document_recipient_id){
    const recipientData = recipientShortcodeData.find(recipient => recipient.document_template_recipient_id === document_recipient_id)
      || {}
    return recipientData[key] || ''
  }

  return replaceTextShortCodes(value, shortcodeData)
}

const getDataContext = (params) => {
  const { documentRecipient, rawDataContext } = params

  if (!rawDataContext && documentRecipient.subject_type === 'User') return 'user'
  if (!rawDataContext && documentRecipient.subject_type === 'Contact') return 'contact'

  return rawDataContext.split('.')[0]
}

const defaultState = {
  inputValue: '',
}

function useAgreementPageItem(options = {}){
  const {
    agreement,
    documentTemplatePageItem,
    documentTemplatePageItem: {
      height, item_type, key, width, x, y, z,
    },
    filteredRecipients,
    zoom = 1,
  } = options || {}

  const [state, setState] = useSetState(defaultState)
  const { inputValue } = state

  // Recipient
  const documentRecipient = filteredRecipients.find(
    recipient => recipient.document_template_recipient_id === documentTemplatePageItem.document_recipient_id,
  ) || {}
  const documentRecipientPayload = useDocumentRecipient(documentRecipient)
  const { recipientColor } = documentRecipientPayload

  const recipientRelations = useRecipientRelations(documentRecipient)
  const recipientSubject = getRecipientSubject(recipientRelations)

  const rawDataContext = digObject(documentTemplatePageItem, 'options.data_context', '')
  const rawValue = digObject(documentTemplatePageItem, 'options.raw_value', '')
  const dataContextIndex = digObject(documentTemplatePageItem, 'options.data_context_index')

  const dataContext = getDataContext({ documentRecipient, rawDataContext })

  const rawComparableFilters = rawDataContext.split('.')[1] || ''
  const comparableFilters = rawComparableFilters.split('_')

  const comparableOptions = dataContext === 'project_comparables'
    ? { comparableSource: comparableFilters[0], comparableStatus: comparableFilters[1] }
    : null

  const customFieldId = digObject(documentTemplatePageItem, 'options.custom_field_id')
  const isCustomField = !!customFieldId

  const isEditable = !nonEditableItemTypes.includes(item_type) && editableDataContextKeys.includes(dataContext)
  const isEditableInline = isCustomField && inlineEditableDataContextKeys.includes(dataContext)

  const entities = useSelector(reduxState => reduxState.entities)
  const { agreementShortcodeData, customFields, dataStoreItems } = entities

  const shortcodeData = agreementShortcodeData[agreement.id] || {}
  const recipientShortcodeData = shortcodeData.recipients || []

  const shortcodeValue = getShortcodeValue({
    documentTemplatePageItem,
    isEditableInline,
    recipientShortcodeData,
    shortcodeData,
  })

  const dataStoreItemSubject = getSubject(dataContext, agreement)

  // Custom Field
  const customField = customFields[customFieldId] || {}

  // Data Store Value
  const dataStoreItem = findDataStoreItem({
    dataStoreItems: Object.values(dataStoreItems),
    isCustomField,
    key,
    subjectId: dataStoreItemSubject.id,
    subjectType: dataStoreItemSubject.type,
  })

  const dataStoreItemValue = dataStoreItem.value || ''

  useEffect(() => {
    setState({ inputValue: dataStoreItemValue })
  }, [dataStoreItemValue])

  const dataStoreItemPayload = useDataStoreItem(dataStoreItem)
  const {
    callbacks: { createOrUpdateDataStoreItem },
  } = dataStoreItemPayload

  // Project
  const projectId = digObject(shortcodeData, 'project.id')
  const { address: projectAddress } = useProjectRelations({ id: projectId })

  const inputComponent = getInputComponent(documentTemplatePageItem)

  return {
    callbacks: {
      setState,
      updateDataStore: value => updateDataStore({
        agreement,
        createOrUpdateDataStoreItem,
        dataStoreItemSubject,
        documentTemplatePageItem,
        value,
      }),
    },
    ...documentTemplatePageItem,
    comparableOptions,
    customField,
    dataContext,
    dataContextIndex,
    height: adjustValueForZoom(height, zoom),
    inputComponent,
    inputValue: isEditableInline ? inputValue : shortcodeValue,
    isCheckbox: inputComponent === 'checkbox',
    isChecked: rawValue === inputValue,
    isCustomField,
    isEditable,
    isEditableInline,
    projectAddress,
    rawValue,
    recipientColor,
    recipientIsContact: recipientSubject.type === 'Contact',
    recipientIsUser: recipientSubject.type === 'User',
    recipientSubject,
    width: adjustValueForZoom(width, zoom),
    x: adjustValueForZoom(x, zoom),
    y: adjustValueForZoom(y, zoom),
    z,
  }
}

export default useAgreementPageItem
