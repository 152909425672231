import { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import type { AwardModel } from '@models/award'
import type { RootReducerState } from '@redux/modules'

const watchEntityKeys = ['awards']

type ModifyAwardSortTypes = {
  fromIndex: number,
  setSortedIds: (value: (currentIds: number[]) => number[]) => void,
  toIndex: number,
}

const modifyAwardSort = (params : ModifyAwardSortTypes) => {
  const { fromIndex, toIndex, setSortedIds } = params
  setSortedIds((currentIds: number[]) => {
    const sortedIds = [...currentIds]
    const [removedItemId] = sortedIds.splice(fromIndex, 1)
    sortedIds.splice(toIndex, 0, removedItemId)

    return sortedIds
  })
}

type UseEditAwardSortType = {
    filteredAwards: AwardModel[],
}

function useEditAwardSort(options: UseEditAwardSortType) {
  const { filteredAwards } = options || {}

  const [sortedIds, setSortedIds] = useState<number[]>([])

  const entities = useSelector((reduxState: RootReducerState) => reduxState.entities)
  const { awards } = entities

  const {
    updatedEntities: { awards: awardsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const sortedAwards = useMemo(() => {
    const sorted = sortedIds.map(awardId => awards[awardId])

    return sorted
  }, [JSON.stringify(sortedIds), awardsUpdatedAt])

  // Handle item sorting in state
  useEffect(() => {
    const sortedAwardIds = filteredAwards.map(item => item.id)
    setSortedIds(sortedAwardIds)
  }, [filteredAwards.length, awardsUpdatedAt])

  return {
    callbacks: {
      modifyAwardSort: (fromIndex: number, toIndex: number) => modifyAwardSort({ fromIndex, setSortedIds, toIndex }),
    },
    sortedAwards,
    sortedIds,
  }
}

export default useEditAwardSort
