import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExchange } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, IconWithMenu, Link, Text,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import OrganizationIcon from '@components/Icons/OrganizationIcon'

import localizedStrings from './localizedStrings'

const Header = (props) => {
  const {
    organizationSelectorPayload: {
      callbacks: { selectOrganizationId },
      hasMultipleOrganizationsWhereAdmin,
      isSelectedOrganizationAdmin,
      organizationType,
      selectedOrganization,
      sortedOrganizationsWhereAdmin,
    },
  } = props

  const {
    callbacks: { formatString },
    strings,
  } = useLocalization(localizedStrings)

  return (
    <Box borderBottom="1px solid" borderColor="lineColor" flexDirection="column" padding="large">
      <Box
        alignItems="center"
        background="#F2FBF8"
        borderRadius={86}
        flexShrink={0}
        height={86}
        justifyContent="center"
        width={86}
      >
        <OrganizationIcon fillColor="#61BB95" width={54} height={54} />
      </Box>

      <Box alignItems="center" justifyContent="space-between" paddingTop="xlarge">
        <Box flexDirection="column" justifyContent="flex-start" minWidth="0" width="auto">
          <Text fontSize="small" lineHeight="1.3">
            {isSelectedOrganizationAdmin
              ? selectedOrganization.title || 'All Organizations'
              : strings.settings || 'Admin Settings'}
          </Text>

          <Text color="bodyFontLightColor" fontSize="xsmall" lineHeight="1.3" marginTop="small">
            {organizationType.title}
          </Text>
        </Box>

        {hasMultipleOrganizationsWhereAdmin && (
          <IconWithMenu
            icon={(
              <Button
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faExchange} />}
                size="small"
                wrapperTag="span"
              />
            )}
          >
            <IconWithMenu.Menu closeOnClick listStyle={{ left: '-160px', right: '-12px' }} onClick>
              {sortedOrganizationsWhereAdmin.map(organization => (
                <Link key={organization.id} onClick={() => selectOrganizationId(organization.id)} tabIndex="0">
                  <Text variant="ellipsis" width={180}>
                    {organization.title
                      || formatString(strings.title, { id: organization.id })
                      || `Organisation #${organization.id}`}
                  </Text>
                </Link>
              ))}
            </IconWithMenu.Menu>
          </IconWithMenu>
        )}
      </Box>
    </Box>
  )
}

Header.propTypes = {
  organizationSelectorPayload: PropTypes.shape({
    callbacks: PropTypes.shape({
      selectOrganizationId: PropTypes.func.isRequired,
    }).isRequired,
    hasMultipleOrganizationsWhereAdmin: PropTypes.bool,
    isSelectedOrganizationAdmin: PropTypes.bool,
    organizationType: PropTypes.object.isRequired,
    selectedOrganization: PropTypes.object.isRequired,
    sortedOrganizationsWhereAdmin: PropTypes.array.isRequired,
  }).isRequired,
}

export default Header
