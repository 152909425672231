import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'

import { getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'
import {
  Box, Button, LoadingModule, PageHeader,
} from '@campaignhub/suit-theme'

import useIntegrationPlatforms from '@hooks/useIntegrationPlatforms'
import useIntercom from '@hooks/useIntercom'
import useLocalization from '@hooks/useLocalization'
import useNumericParams from '@hooks/useNumericParams'
import useQuote from '@hooks/useQuote'
import useReduxAction from '@hooks/useReduxAction'

import localizedStrings from './localizedStrings'

const refreshExternalQuote = (quotePayload, redirect) => {
  const {
    callbacks: { importFromExternalPlatform },
    externalId,
    externalPlatformKey,
    quote: { owner_id, subject_id },
  } = quotePayload

  const importPayload = {
    externalId,
    organizationId: owner_id,
    projectId: subject_id,
  }

  importFromExternalPlatform({ externalPlatformKey, payload: importPayload }).then(() => {
    window.location.href = redirect
  })
}

const Quote = (props) => {
  const { singleQuote } = props

  const { externalProjectId, platformKey } = useParams()
  const { quoteId } = useNumericParams()

  const { showModal, redirect } = parsePermittedQueryParams(getQueryParamsFromHash(), ['redirect', 'showModal'])

  // Load Quote
  useReduxAction('quotes', 'loadQuote', {}, [quoteId], {
    dispatchAction: (action, options) => action(quoteId, options),
    shouldPerformFn: ({ loadedIds, loading }) => quoteId && !loadedIds.includes(quoteId) && !loading,
  })

  const quotePayload = useQuote({ id: quoteId })
  const {
    callbacks: { loadExternalPlatformManagementUrl, loadExternalPlatformManagementUrlForQuote },
    creating,
    externalManagementUrl,
    externalPlatformKey,
    loading,
    quote: { id },
    urls: { quoteListUrl },
  } = quotePayload

  // Individual Quote
  useEffect(() => {
    if (id && singleQuote){
      loadExternalPlatformManagementUrlForQuote()
    }
  }, [id, singleQuote])

  // All Quotes
  useEffect(() => {
    if (!singleQuote && platformKey && externalProjectId){
      loadExternalPlatformManagementUrl(platformKey, { external_project_id: externalProjectId })
    }
  }, [singleQuote, platformKey, externalProjectId])

  // Integration Platform
  const integrationPlatformsPayload = useIntegrationPlatforms()
  const {
    callbacks: { findIntegrationPlatformByKey },
  } = integrationPlatformsPayload
  const integrationPlatform = findIntegrationPlatformByKey(platformKey || externalPlatformKey)

  useIntercom({ hideWidget: true })

  const {
    callbacks: { formatString },
    strings,
  } = useLocalization(localizedStrings)

  return (
    <>
      <PageHeader
        actionContent={(
          <Button
            as="a"
            buttonStyle="primaryEdit"
            href={!singleQuote && redirect ? redirect : null}
            loading={creating}
            marginLeft="small"
            onClick={singleQuote ? () => refreshExternalQuote(quotePayload, redirect || quoteListUrl) : null}
            size="medium"
            width="auto"
          >
            {strings.saveButtonText || 'Save & Go Back'}
          </Button>
        )}
        boxProps={{ justifyContent: 'flex-start' }}
        offset={{ left: 0, top: 0 }}
        title={formatString(strings.title, { integrationPlatformTitle: integrationPlatform.title }) || 'External Quote'}
        width="100%"
      />

      <Box
        flexDirection="column"
        height="calc(100vh - 70px)"
        marginTop={70}
        padding={loading ? 'large' : null}
        width="100%"
      >
        <LoadingModule loading={loading} times={3} />

        <iframe
          title="External Quote"
          style={{ height: '100%', minWidth: '100%', width: '1px' }}
          src={externalManagementUrl}
        />
      </Box>
    </>
  )
}

Quote.propTypes = {
  singleQuote: PropTypes.bool,
}

export default Quote
