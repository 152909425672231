import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, ListItem, StatusBadge, Text,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'
import useOrganizationsUser from '@hooks/useOrganizationsUser'

import localizedStrings from './localizedStrings'

const Organization = (props) => {
  const {
    features: { canEditUser, isSelf },
    organizationsUser,
  } = props

  const {
    callbacks: { editOrganizationsUser },
    hasAvailableOrganizationsToJoin,
    organization: { title },
    organizationsUser: { default: isDefault, admin },
  } = useOrganizationsUser(organizationsUser)

  const { strings } = useLocalization(localizedStrings)

  const canEdit = canEditUser || isSelf

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        borderBottom: '1px dashed',
        justifyContent: 'space-between',
        padding: 'large',
      }}
      disableHover={!canEdit}
      forceBottomBorder={canEdit && !!hasAvailableOrganizationsToJoin}
      onClick={canEdit ? editOrganizationsUser : null}
      showAngleIcon={canEdit}
    >
      <Box flexDirection="column" justifyContent="center" lineHeight={1.3} marginLeft="medium">
        <Text color="mysteryGrey" fontSize="small" marginBottom="small">
          {title}
        </Text>

        <Text color="faintGrey" fontSize="xsmall">
          {admin ? strings.admin || 'Organization Admin' : strings.user || 'Organization User'}
        </Text>
      </Box>

      {isDefault && (
        <Box alignItems="center" color="faintGrey" fontSize="small" justifyContent="flex-end">
          <StatusBadge color="grey" ghost text="default" />
        </Box>
      )}
    </ListItem>
  )
}

Organization.propTypes = {
  features: PropTypes.shape({
    canEditUser: PropTypes.bool,
    isSelf: PropTypes.bool,
  }),
  organizationsUser: PropTypes.object.isRequired,
}

export default Organization
