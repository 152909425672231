import React from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'
import { toast } from 'react-toastify'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faColumns, faTrashAlt, faTrashUndo } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, DashboardModule,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'
import useProject from '@hooks/useProject'

import localizedStrings from './localizedStrings'

const updateProject = (projectParams, updateFn, strings) => {
  updateFn(projectParams).then(({ success, result }) => {
    if (!success){
      toast.warning(result[0])
    }

    toast(strings.toast?.updated || 'Project successfully updated')
  })
}

const archiveProject = (projectParams, archiveProjectFn, strings) => {
  const { hidden } = projectParams

  swal
    .fire({
      confirmButtonColor: hidden ? '#DD6B55' : '#43ac6a',
      confirmButtonText: hidden
        ? strings.swal?.archiveConfirmButtonText || 'Yes, archive it.'
        : strings.swal?.unarchiveConfirmButtonText || 'Yes, unarchive it.',
      icon: 'warning',
      showCancelButton: true,
      text: strings.swal?.text || 'Are you sure?',
      title: hidden
        ? strings.swal?.archiveTitle || 'Archive Project?'
        : strings.swal?.unarchiveTitle || 'Unarchive Project?',
    })
    .then(({ value }) => {
      if (value){
        updateProject(projectParams, archiveProjectFn, strings)
      }
    })
}

const Tools = (props) => {
  const { callbacks: { customizeDashboard }, projectId } = props

  const useProjectPayload = useProject({ id: projectId })
  const {
    callbacks: {
      updateProject: updateFn,
    },
    project: { hidden },
  } = useProjectPayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <DashboardModule boxProps={{ marginBottom: 0, marginTop: 'large' }} title={strings.title || 'Tools'}>
      <Box flexDirection="column" maxHeight={415} overflow="auto">
        <Box flexDirection="column" padding="large">
          <Button
            buttonStyle="secondaryUtility"
            icon={<FontAwesomeIcon icon={faColumns} />}
            marginBottom="medium"
            onClick={customizeDashboard}
            size="medium"
          >
            {strings.button?.customize || 'Customise Dashboard'}
          </Button>

          <Button
            buttonStyle="secondaryUtility"
            icon={<FontAwesomeIcon icon={hidden ? faTrashUndo : faTrashAlt} />}
            onClick={() => archiveProject({ id: projectId, hidden: !hidden }, updateFn, strings)}
            size="medium"
          >
            {hidden
              ? strings.button?.unarchive || 'Unarchive Project'
              : strings.button?.archive || 'Archive Project'}
          </Button>
        </Box>
      </Box>
    </DashboardModule>
  )
}

Tools.propTypes = {
  callbacks: PropTypes.shape({
    customizeDashboard: PropTypes.func.isRequired,
  }).isRequired,
  projectId: PropTypes.number,
}

export default Tools
