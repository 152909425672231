import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faHistory, faUser } from '@fortawesome/pro-light-svg-icons'

import { digObject, snakeToTitleCase } from '@campaignhub/javascript-utils'

import {
  Box, Button, Image, Link, ListItem, Text,
} from '@campaignhub/suit-theme'

import useActivity from '@hooks/useActivity'
import useLocalization from '@hooks/useLocalization'
import useRecoverableActivitySubject from '@hooks/useRecoverableActivitySubject'
import { useRelations as useUserRelations } from '@hooks/useUser'

import { capitalizeString } from '@functions/string'
import { isAdminForOrganizationId, isOwnerForOrganizationId } from '@functions/user'

import AuthenticatedDownload from '@components/AuthenticatedDownload'

import localizedStrings from './localizedStrings'

const generateUserRoleString = (user, organizationId, strings) => {
  if (isAdminForOrganizationId(user, organizationId)) return strings.officeAdmin || 'Office Administrator'
  if (isOwnerForOrganizationId(user, organizationId)) return strings.owner || 'Owner'
  return strings.user || 'User'
}

const renderAdditionalParams = (activity) => {
  if (activity){
    const { data } = activity
    const actionReason = digObject(data, 'action_reason', '')

    // Production Ready Reason
    if (actionReason){
      return (
        <Text as="span" color="bodyFontLightColor">
          Action Reason: {actionReason}
        </Text>
      )
    }
  }

  return null
}

const Activity = (props) => {
  const { activity, boxProps, organizationId } = props

  const {
    dates: {
      created: { date_time_with_timezone },
    },
    subject_id,
    subject_type,
  } = activity

  const acitivityPayload = useActivity(activity)
  const {
    action,
    color,
    urls: { downloadActivityUrl },
    user,
  } = acitivityPayload

  const { defaultImage } = useUserRelations(user)
  const { full_name } = user || {}

  const imageUrl = digObject(defaultImage, 'sizes.thumb_90x90', '')

  const recoverableEntityPayload = useRecoverableActivitySubject(activity)
  const {
    callbacks: { recoverEntity },
    recoverable,
    recoveredAt,
  } = recoverableEntityPayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <ListItem
      boxProps={{
        alignItems: 'center', borderBottom: '1px solid', padding: 'large', ...boxProps,
      }}
      disableHover
    >
      <Image
        boxProps={{ backgroundColor: 'hoverLightGrey', fontSize: 'small' }}
        circle
        height={30}
        width={30}
        url={imageUrl}
      >
        {!imageUrl && (
          <Box color="bodyFontColor" width="auto">
            <FontAwesomeIcon icon={faUser} />
          </Box>
        )}
      </Image>

      <Box flexDirection="column" justifyContent="center" marginLeft="large" minWidth="0">
        <Box>
          <Text as="span" fontSize="xsmall" fontWeight="bold">
            {full_name || strings.userRemoved || 'User Removed'}
          </Text>
          &nbsp;
          <Text as="span" color="bodyFontLightColor" fontSize="xsmall">
            ({generateUserRoleString(user, organizationId, strings)})
          </Text>
        </Box>

        <Box alignItems="center" flexWrap="wrap" lineHeight={1.3}>
          <Text as="span" color={color} fontSize="xsmall" marginRight="small">
            {snakeToTitleCase(action)}
          </Text>

          <Text as="span" color="bodyFontLightColor" fontSize="xsmall" marginRight="small">
            {capitalizeString(subject_type)} #{subject_id}
          </Text>

          {recoveredAt && (
            <Text as="span" color="bodyFontLightColor" fontSize="xsmall" marginRight="small">
              {`(${strings.recovered || 'Recovered'} ${DateTime.fromISO(recoveredAt).toFormat('EEE ff')})`}
            </Text>
          )}
        </Box>

        <Box marginTop="small" color="bodyFontLightColor" fontSize="xsmall">
          {renderAdditionalParams(activity)}
        </Box>

        <Box marginTop="small" color="bodyFontLightColor" fontSize="xsmall">
          {DateTime.fromISO(date_time_with_timezone).toFormat('EEE ff')}
        </Box>
      </Box>

      <AuthenticatedDownload>
        <Link
          greyLink
          href={downloadActivityUrl}
          key="download"
          textProps={{ marginRight: 'medium' }}
        >
          <FontAwesomeIcon icon={faDownload} />
        </Link>
      </AuthenticatedDownload>

      {recoverable && (
        <Button
          buttonStyle="secondaryEdit"
          icon={<FontAwesomeIcon icon={faHistory} />}
          onClick={() => recoverEntity()}
          size="tiny"
          width="auto"
        >
          {strings.recover || 'Recover'}
        </Button>
      )}
    </ListItem>
  )
}

Activity.propTypes = {
  activity: PropTypes.object,
  boxProps: PropTypes.object,
  organizationId: PropTypes.number,
}

export default Activity
