import { useSetState, useThunkDispatch } from '@campaignhub/react-hooks'
import { matchFilterString } from '@campaignhub/javascript-utils'

import type { AppDispatch } from '@redux/store'

import * as documentTemplateActions from '@redux/modules/documentTemplate'

type DocumentTemplate = {
  code: string,
  cost: number,
  id: number,
  title: string,
}

type DocumentTemplateGroup = {
  id: number,
  title: string,
  templates: DocumentTemplate[],
}

type DefaultState = {
  documentTemplateGroups: object | DocumentTemplateGroup[],
  loading: boolean,
  filterString: string,
  selectedDocumentTemplate: object | DocumentTemplate,
}

type LoadExternalTemplateParams = {
  integrationPlatformKey: string,
  dispatch: AppDispatch,
  setState: (state: Partial<DefaultState>) => void,
}

const loadExternalDocumentTemplates = (params: LoadExternalTemplateParams) => {
  const { integrationPlatformKey, dispatch, setState } = params
  const { fetchFromExternalPlatform } = documentTemplateActions

  setState({ loading: true })

  return dispatch(fetchFromExternalPlatform(integrationPlatformKey)).then((response) => {
    const { success, data } = response

    if (success){
      setState({ loading: false, documentTemplateGroups: data })
    }

    setState({ loading: false })
  })
}

const defaultState: DefaultState = {
  documentTemplateGroups: [],
  filterString: '',
  loading: false,
  selectedDocumentTemplate: {},
}

function useExternalDocumentTemplates() {
  const [state, setState] = useSetState(defaultState)
  const {
    documentTemplateGroups, filterString, loading, selectedDocumentTemplate,
  } = state

  const dispatch = useThunkDispatch()

  const filteredDocumentTemplateGroups = documentTemplateGroups?.reduce((acc, templateGroup: DocumentTemplateGroup) => {
    const { templates } = templateGroup

    const filteredTemplates = templates.filter(template => matchFilterString(template.title, filterString))

    if (filteredTemplates.length){
      const updatedTemplateGroup = {
        ...templateGroup,
        templates: filteredTemplates,
      }

      acc.push(updatedTemplateGroup)
    }

    return acc
  }, [])

  const hasSelectedDocumentTemplate = !!selectedDocumentTemplate.id

  return {
    callbacks: {
      loadExternalDocumentTemplates: (integrationPlatformKey: string) => loadExternalDocumentTemplates({
        integrationPlatformKey,
        dispatch,
        setState,
      }),
      toggleDocumentTemplate: (documentTemplate: DocumentTemplate) => setState({
        selectedDocumentTemplate: documentTemplate,
      }),
      setState,
    },
    filterString,
    filteredDocumentTemplateGroups,
    hasSelectedDocumentTemplate,
    loading,
    selectedDocumentTemplate,
  }
}

export default useExternalDocumentTemplates
