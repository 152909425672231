import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

type UseOrganizationTypesOptions = {
  performHttpRequests?: boolean,
}

function useOrganizationTypes(options: UseOrganizationTypesOptions = {}) {
  const { performHttpRequests } = options

  useReduxAction('organizationTypes', 'loadOrganizationTypes', {}, [performHttpRequests], {
    shouldPerformFn: (entityReducer) => {
      const { errors, loaded, loading } = entityReducer
      return performHttpRequests && !loaded && !loading && !errors.length
    },
  })

  const { organizationTypes } = useSelector(reduxState => reduxState.entities)

  const { loaded, loading } = useSelector(reduxState => reduxState.organizationTypes)

  return {
    loaded,
    loading,
    organizationTypes,
  }
}

export default useOrganizationTypes
