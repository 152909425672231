import { FC, ReactNode } from 'react'

import SectionContextProvider from '@components/SectionContextProvider'

import useReduxAction from '@hooks/useReduxAction'
import useRequestOrSupplySupportToken from '@hooks/useRequestOrSupplySupportToken'
import useRouterScrollToTop from '@hooks/useRouterScrollToTop'
import useSystems from '@hooks/useSystems'

import RequestOrSupplySupportTokenModal from '@modals/RequestOrSupplySupportTokenModal'

type AdminProps = {
  children: ReactNode,
}

const Admin: FC<AdminProps> = ({ children }) => {
  useReduxAction('integrations', 'loadIntegrations', { owner_type: 'System' })

  // Scroll Page to top when route changes
  useRouterScrollToTop()

  useSystems({ performHttpRequests: true })

  const requestOrSupplySupportTokenPayload = useRequestOrSupplySupportToken()

  return (
    <SectionContextProvider isAdmin>
      {children}

      <RequestOrSupplySupportTokenModal {...requestOrSupplySupportTokenPayload} />
    </SectionContextProvider>
  )
}

export default Admin
