export default {
  default: {
    buttons: {
      edit: 'Edit',
      view: 'View',
    },
    iconWithMenu: {
      history: 'View History',
      pdf: 'Download Agreement',
      void: 'Void Agreement',
    },
    recipients: {
      type: 'count',
      none: 'No Recipients',
      one: '{count} Recipient',
      many: '{count} Recipients',
    },
    swal: {
      confirmButtonText: 'Yes, void it.',
      inputPlaceholder: 'Please add reason for voiding',
      text: 'Voiding the agreement will notify signers and will require you to resend the document.',
      title: 'Void Agreement?',
    },
    toast: {
      agreement: {
        voided: 'Agreement Voided.',
      },
    },
  },
}
