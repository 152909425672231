import React from 'react'
import PropTypes from 'prop-types'

import { digObject } from '@campaignhub/javascript-utils'
import { Box, Button, SidebarModal } from '@campaignhub/suit-theme'

import EntitySelector from '@components/EntitySelector'
import FieldHolder from '@components/FieldHolder'
import Input from '@components/Input'

import DefaultState from './DefaultState'
import NestedOptions from './NestedOptions'
import DefaultImage from './DefaultImage'

const shouldRender = (entityType, itemType) => {
  const permittedEntities = ['digitalTemplatePageSectionGroup', 'digitalTemplatePageSection']
  const permittedItemTypes = [
    'advancedChart',
    'animation',
    'button',
    'carousel',
    'chart',
    'container',
    'counter',
    'dataArrayLoop',
    'formTemplate',
    'hashLink',
    'icon',
    'iconPosition',
    'image',
    'imageCarousel',
    'input',
    'map',
    'state',
    'text',
    'video',
  ]
  return permittedEntities.includes(entityType) || permittedItemTypes.includes(itemType)
}

const updateOption = (name, value, props) => {
  const {
    selectedEntity,
    callbacks: { updateParam },
  } = props

  const updatedOptions = {
    ...selectedEntity.options,
    [name]: value,
  }
  updateParam('options', updatedOptions)
}

const removeImage = (selectedEntity, props) => {
  const {
    callbacks: { updateEntity },
  } = props

  const updatedEntity = {
    ...selectedEntity,
    image: {
      id: null,
    },
    style: {
      ...selectedEntity.style,
      backgroundImage: null,
    },
  }

  updateEntity(updatedEntity)
}

const renderAnchorId = (entityType, selectedEntity, options, props) => {
  const permittedTypes = ['hashLink']

  if (entityType === 'digitalTemplatePageSection' || permittedTypes.includes(selectedEntity.item_type)){
    return (
      <Input
        boxProps={{ marginBottom: 'large' }}
        entityId={selectedEntity.id}
        data={options}
        last
        propertyKey="anchor"
        label="Anchor"
        updateCallback={(name, value) => updateOption(name, value, props)}
      />
    )
  }

  return null
}

const renderAnimationOptions = (selectedEntity, options, props) => {
  const permittedTypes = ['animation', 'button', 'container', 'text']

  if (permittedTypes.includes(selectedEntity.item_type)){
    return (
      <Box flexDirection="row" flexWrap="wrap">
        {!['animation'].includes(selectedEntity.item_type) && (
          <>
            <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth label="Animation Type">
              <select
                onChange={e => updateOption('animationType', e.target.value, props)}
                value={options.animationType || ''}
              >
                <option value="">None</option>
                <option value="Bounce">Bounce</option>
                <option value="Fade">Fade</option>
                <option value="Flip">Flip</option>
                <option value="HeadShake">HeadShake</option>
                <option value="Pulse">Pulse</option>
                <option value="Zoom">Zoom</option>
              </select>
            </FieldHolder>

            <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth last label="Direction">
              <select
                onChange={e => updateOption('animationDirection', e.target.value, props)}
                value={options.animationDirection || ''}
              >
                <option value="">Clear</option>
                <option value="bottom">Bottom</option>
                <option value="left">Left</option>
                <option value="right">Right</option>
                <option value="top">Top</option>
              </select>
            </FieldHolder>

            <Input
              boxProps={{ marginBottom: 'large' }}
              data={options}
              entityId={selectedEntity.id}
              halfWidth
              inputType="number"
              propertyKey="animationDelay"
              label="Delay (ms)"
              updateCallback={(name, value) => updateOption(name, value, props)}
            />

            <Input
              boxProps={{ marginBottom: 'large' }}
              data={options}
              entityId={selectedEntity.id}
              halfWidth
              inputType="number"
              last
              propertyKey="animationDuration"
              label="Duration (ms)"
              updateCallback={(name, value) => updateOption(name, value, props)}
            />
          </>
        )}

        <FieldHolder boxProps={{ marginBottom: 'large' }} label="Loop">
          <select
            onChange={e => updateOption('animationLoop', e.target.value === 'true', props)}
            value={options.animationLoop || ''}
          >
            <option value="">Default</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </FieldHolder>
      </Box>
    )
  }

  return null
}

const renderButtonOptions = (selectedEntity, options, props) => {
  if (selectedEntity.item_type === 'button'){
    const {
      inputDataStoreBehaviour, inputDataStoreSubject, trigger, version,
    } = options || {}

    const triggers = options.triggers || []

    return (
      <Box flexDirection="column">
        <Input
          boxProps={{ marginBottom: 'large' }}
          key="text"
          entityId={selectedEntity.id}
          data={options}
          propertyKey="text"
          label="Text"
          updateCallback={(name, value) => updateOption(name, value, props)}
        />

        <Input
          boxProps={{ marginBottom: 'large' }}
          key="href"
          entityId={selectedEntity.id}
          data={options}
          propertyKey="href"
          label="URL"
          updateCallback={(name, value) => updateOption(name, value, props)}
        />

        <FieldHolder boxProps={{ marginBottom: 'large' }} key="target" label="Target">
          <select onChange={e => updateOption('target', e.target.value, props)} value={options.target}>
            <option value="">Default</option>
            <option value="_blank">Blank (New Window/Tab)</option>
            <option value="_self">Self (Current Window)</option>
          </select>
        </FieldHolder>

        <Box flexDirection="row" flexWrap="wrap">
          <FieldHolder boxProps={{ marginBottom: 'large' }} key="buttonStyle" label="Button Style">
            <select onChange={e => updateOption('buttonStyle', e.target.value, props)} value={options.buttonStyle}>
              <option value="">Not Selected</option>
              <option value="primaryCreate">Primary (Create)</option>
              <option value="primaryEdit">Primary (Edit)</option>
              <option value="primaryDestroy">Primary (Destroy)</option>
              <option value="primaryUtility">Primary (Utility)</option>
              <option value="ghostCreate">Ghost (Create)</option>
              <option value="ghostEdit">Ghost (Edit)</option>
              <option value="ghostDestroy">Ghost (Destroy)</option>
              <option value="ghostUtility">Ghost (Utility)</option>
              <option value="secondary">Secondary</option>
              <option value="secondaryCreate">Secondary (Create)</option>
              <option value="secondaryEdit">Secondary (Edit)</option>
              <option value="secondaryDestroy">Secondary (Destroy)</option>
              <option value="secondaryUtility">Secondary (Utility)</option>
            </select>
          </FieldHolder>

          <FieldHolder boxProps={{ marginBottom: 'large' }} key="size" halfWidth label="Size">
            <select onChange={e => updateOption('size', e.target.value, props)} value={options.size}>
              <option value="tiny">Tiny</option>
              <option value="small">Small</option>
              <option value="medium">Medium</option>
              <option value="large">Large</option>
            </select>
          </FieldHolder>

          <FieldHolder boxProps={{ marginBottom: 'large' }} key="icon" halfWidth last label="Icon">
            <Input
              key="icon"
              entityId={selectedEntity.id}
              data={options}
              propertyKey="icon"
              updateCallback={(name, value) => updateOption(name, value, props)}
            />
          </FieldHolder>

          {options.icon && (
            <>
              <FieldHolder boxProps={{ marginBottom: 'large' }} key="iconPosition" halfWidth label="Icon Position">
                <select
                  onChange={e => updateOption('iconPosition', e.target.value, props)}
                  value={options.iconPosition}
                >
                  <option value="left">Left</option>
                  <option value="right">Right</option>
                </select>
              </FieldHolder>

              <FieldHolder boxProps={{ marginBottom: 'large' }} key="iconMargin" halfWidth last label="Icon Margin">
                <Input
                  key="iconMargin"
                  entityId={selectedEntity.id}
                  data={options}
                  propertyKey="iconMargin"
                  updateCallback={(name, value) => updateOption(name, value, props)}
                />
              </FieldHolder>
            </>
          )}
        </Box>

        <FieldHolder boxProps={{ marginBottom: 'large' }} key="trigger" last label="Trigger">
          <select onChange={e => updateOption('trigger', e.target.value, props)} value={options.trigger}>
            <option value="">None</option>
            <option value="acceptDigitalPage">Accept Digital Page</option>
            <option value="buildPdf">Build PDF</option>
            <option value="callUser">Call User</option>
            <option value="downloadAttachment">Download Attachment</option>
            <option value="downloadQuote">Download Quote</option>
            <option value="emailUser">Email User</option>
            <option value="multiple">Multiple Triggers</option>
            <option value="setState">Set State</option>
            <option value="toggleCarousel">Toggle Carousel</option>
            <option value="toggleDigitalPageSidebar">Toggle Menu</option>
            <option value="updateDataStore">Update Data Store</option>
          </select>
        </FieldHolder>

        {trigger === 'multiple' && (
          <NestedOptions
            availableOptions={[
              { key: 'acceptDigitalPage', value: 'Accept Digital Page' },
              { key: 'regroupFormResponse', value: 'Regroup Form Response' },
              { key: 'submitFormResponse', value: 'Submit Form Response' },
              { key: 'updateDataStore', value: 'Update Data Store' },
            ]}
            data={options}
            entityId={selectedEntity.id}
            label="Select Triggers"
            propertyKey="triggers"
            skipStringToKey
            style={{ marginBottom: 'medium' }}
            updateCallback={(name, value) => updateOption(name, value, props)}
          />
        )}

        {(trigger === 'submitFormResponse'
          || (trigger === 'multiple'
            && triggers.find(t => ['submitFormResponse', 'regroupFormResponse'].includes(t.key)))) && (
            <Input
              boxProps={{ marginBottom: 'large' }}
              key="text"
              entityId={selectedEntity.id}
              data={options}
              propertyKey="formDataStoreTag"
              label="Tag"
              updateCallback={(name, value) => updateOption(name, value, props)}
            />
        )}

        {trigger === 'toggleCarousel' && (
          <Input
            boxProps={{ marginBottom: 'large' }}
            entityId={selectedEntity.id}
            data={options}
            propertyKey="carouselKey"
            label="Carousel Key"
            updateCallback={(name, value) => updateOption(name, value, props)}
          />
        )}

        {(trigger === 'setState' || triggers.find(t => t.key === 'setState')) && (
          <Box flexDirection="column">
            <Input
              boxProps={{ marginBottom: 'large' }}
              entityId={selectedEntity.id}
              data={options}
              propertyKey="stateKey"
              label="State Key"
              updateCallback={(name, value) => updateOption(name, value, props)}
              version={version}
            />

            <Input
              boxProps={{ marginBottom: 'large' }}
              entityId={selectedEntity.id}
              data={options}
              propertyKey="stateValue"
              label="State Value"
              updateCallback={(name, value) => updateOption(name, value, props)}
              version={version}
            />
          </Box>
        )}

        {(trigger === 'updateDataStore' || triggers.find(t => t.key === 'updateDataStore')) && (
          <Box flexDirection="column">
            <FieldHolder boxProps={{ marginBottom: 'large' }} label="Data Store Subject">
              <select
                onChange={e => updateOption('inputDataStoreSubject', e.target.value, props)}
                value={inputDataStoreSubject}
              >
                <option value="">Select...</option>
                <option value="DigitalPage">Digital Page</option>
                <option value="Project">Project</option>
              </select>
            </FieldHolder>

            <Input
              boxProps={{ marginBottom: 'large' }}
              entityId={selectedEntity.id}
              data={options}
              propertyKey="inputDataStoreKey"
              label="Data Store Key"
              updateCallback={(name, value) => updateOption(name, value, props)}
              version={version}
            />

            <Input
              boxProps={{ marginBottom: 'large' }}
              entityId={selectedEntity.id}
              data={options}
              propertyKey="inputDataStoreValue"
              label="Data Store Value"
              updateCallback={(name, value) => updateOption(name, value, props)}
              version={version}
            />

            <FieldHolder boxProps={{ marginBottom: 'large' }} label="Data Store Behaviour">
              <select
                onChange={e => updateOption('inputDataStoreBehaviour', e.target.value, props)}
                value={inputDataStoreBehaviour}
              >
                <option value="">Select...</option>
                <option value="add">Add</option>
                <option value="group">Group</option>
                <option value="replace">Replace</option>
              </select>
            </FieldHolder>
          </Box>
        )}
      </Box>
    )
  }

  return null
}

const renderChartOptions = (selectedEntity, options, props) => {
  const allowedDataKeys = {
    suburb_data: [
      { label: 'House Median Price', value: 'house_median_price_series' },
      { label: 'Unit Median Price', value: 'unit_median_price_series' },
    ],
  }

  const chartDataKeyOptions = allowedDataKeys[options.chartContext]

  const allowedCharts = {
    house_median_price_series: [{ label: 'Line Chart', value: 'lineChart' }],
    unit_median_price_series: [{ label: 'Line Chart', value: 'lineChart' }],
  }

  const chartTypeOptions = allowedCharts[options.chartDataKey]

  if (selectedEntity.item_type === 'chart'){
    return (
      <>
        <FieldHolder boxProps={{ marginBottom: 'large' }} label="Data Context">
          <select onChange={e => updateOption('chartContext', e.target.value, props)} value={options.chartContext}>
            <option value="">Please Select...</option>
            <option value="suburb_data">Suburb Data</option>
          </select>
        </FieldHolder>

        {options.chartContext && (
          <>
            <FieldHolder boxProps={{ marginBottom: 'large' }} label="Data Series">
              <select onChange={e => updateOption('chartDataKey', e.target.value, props)} value={options.chartDataKey}>
                <option value="">Please Select...</option>
                {chartDataKeyOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </FieldHolder>

            {options.chartDataKey && (
              <FieldHolder boxProps={{ marginBottom: 'large' }} label="Chart Type">
                <select onChange={e => updateOption('chartType', e.target.value, props)} value={options.chartType}>
                  <option value="">Please Select...</option>
                  {chartTypeOptions.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </FieldHolder>
            )}
          </>
        )}
      </>
    )
  }

  return null
}

const renderAdvancedChartOptions = (selectedEntity, options, props) => {
  if (selectedEntity.item_type === 'advancedChart'){
    return (
      <>
        <FieldHolder boxProps={{ marginBottom: 'large' }} label="Chart Number">
          <select onChange={e => updateOption('chartNumber', e.target.value, props)} value={options.chartNumber}>
            <option value="">Please Select...</option>
            <option value="0">First Chart</option>
            <option value="1">Second Chart</option>
            <option value="2">Third Chart</option>
            <option value="3">Fourth Chart</option>
            <option value="4">Fifth Chart</option>
          </select>
        </FieldHolder>

        <FieldHolder boxProps={{ marginBottom: 'large' }} label="Chart Type">
          <select onChange={e => updateOption('chartType', e.target.value, props)} value={options.chartType}>
            <option value="">Please Select...</option>
            <option value="areaChart">Area Chart</option>
            <option value="barChart">Bar Chart</option>
            <option value="lineChart">Line Chart</option>
            <option value="pieChart">Pie Chart</option>
          </select>
        </FieldHolder>

        <Box flexDirection="row" flexWrap="wrap">
          <Input
            boxProps={{ marginBottom: 'large' }}
            data={options}
            entityId={selectedEntity.id}
            halfWidth
            propertyKey="primaryColor"
            label="Chart Primary Color"
            updateCallback={(name, value) => updateOption(name, value, props)}
          />

          <Input
            boxProps={{ marginBottom: 'large' }}
            data={options}
            entityId={selectedEntity.id}
            halfWidth
            last
            propertyKey="accentColor"
            label="Chart Accent Color"
            updateCallback={(name, value) => updateOption(name, value, props)}
          />
        </Box>

        {options.chartType === 'pieChart' && (
          <Box flexDirection="row" flexWrap="wrap">
            <Input
              boxProps={{ marginBottom: 'large' }}
              data={options}
              entityId={selectedEntity.id}
              halfWidth
              propertyKey="innerRadius"
              label="Pie Chart Inner Radius"
              updateCallback={(name, value) => updateOption(name, value, props)}
            />

            <Input
              boxProps={{ marginBottom: 'large' }}
              data={options}
              entityId={selectedEntity.id}
              halfWidth
              last
              propertyKey="outerRadius"
              label="Pie Chart Outer Radius"
              updateCallback={(name, value) => updateOption(name, value, props)}
            />
          </Box>
        )}
      </>
    )
  }

  return null
}

const renderContentWidth = (entityType, options, props) => {
  if (entityType === 'digitalTemplatePageSection'){
    return (
      <FieldHolder boxProps={{ marginBottom: 'large' }} label="Content Width">
        <select onChange={e => updateOption('contentWidthId', e.target.value, props)} value={options.contentWidthId}>
          <option value="">Not Selected</option>
          <option value="small">Small</option>
          <option value="medium">Medium</option>
          <option value="wide">Wide</option>
          <option value="extra-wide">Extra Wide</option>
          <option value="full-width">Full Width</option>
          <option value="six-fifty">650px</option>
          <option value="eight-fifty">850px</option>
        </select>
      </FieldHolder>
    )
  }

  return null
}

const renderCounterOptions = (selectedEntity, options, props) => {
  if (selectedEntity.item_type === 'counter'){
    return (
      <Box flexDirection="row" flexWrap="wrap">
        <Input
          boxProps={{ marginBottom: 'large' }}
          data={options}
          entityId={selectedEntity.id}
          halfWidth
          propertyKey="counterPrefix"
          label="Prefix"
          updateCallback={(name, value) => updateOption(name, value, props)}
        />

        <Input
          boxProps={{ marginBottom: 'large' }}
          data={options}
          entityId={selectedEntity.id}
          halfWidth
          last
          propertyKey="counterSuffix"
          label="Suffix"
          updateCallback={(name, value) => updateOption(name, value, props)}
        />

        <Input
          boxProps={{ marginBottom: 'large' }}
          data={options}
          entityId={selectedEntity.id}
          halfWidth
          inputType="number"
          propertyKey="counterDecimals"
          label="Decimals"
          updateCallback={(name, value) => updateOption(name, value, props)}
        />

        <Input
          boxProps={{ marginBottom: 'large' }}
          data={options}
          entityId={selectedEntity.id}
          halfWidth
          last
          propertyKey="counterSeparator"
          label="Separator"
          updateCallback={(name, value) => updateOption(name, value, props)}
        />
      </Box>
    )
  }

  return null
}

const renderDisableContentHolder = (entityType, options, props) => {
  if (entityType === 'digitalTemplatePageSection'){
    return (
      <FieldHolder boxProps={{ marginBottom: 'large' }} label="Content Holder">
        <select
          onChange={e => updateOption('disableContentHolder', e.target.value === 'true', props)}
          value={options.disableContentHolder}
        >
          <option value="">Enabled</option>
          <option value="true">Disabled</option>
        </select>
      </FieldHolder>
    )
  }

  return null
}

const renderEditable = (selectedEntity, options, props) => {
  const permittedTypes = ['advancedChart', 'counter', 'image', 'map', 'text']

  if (permittedTypes.includes(selectedEntity.item_type)){
    return (
      <FieldHolder boxProps={{ marginBottom: 'large' }} label="Editable">
        <select onChange={e => updateOption('editable', e.target.value === 'true', props)} value={options.editable}>
          <option value="false">No</option>
          <option value="true">Yes</option>
        </select>
      </FieldHolder>
    )
  }

  return null
}

const renderCloneWhenAutoscaling = (selectedEntity, options, props) => {
  const permittedTypes = ['animation', 'button', 'container', 'image', 'imageCarousel', 'text']

  if (permittedTypes.includes(selectedEntity.item_type)){
    return (
      <FieldHolder boxProps={{ marginBottom: 'large' }} label="Clone when Autoscaling">
        <select
          onChange={e => updateOption('cloneWhenAutoscaling', e.target.value === 'true', props)}
          value={options.cloneWhenAutoscaling}
        >
          <option value="false">No</option>
          <option value="true">Yes</option>
        </select>
      </FieldHolder>
    )
  }

  return null
}

const renderFormTemplateOptions = (selectedEntity, options, props) => {
  if (selectedEntity.item_type === 'formTemplate'){
    const { formTemplateId } = options

    return (
      <Box flexDirection="column">
        <EntitySelector
          boxProps={{ marginBottom: 'large' }}
          callbacks={{
            selectItem: formTemplate => updateOption('formTemplateId', formTemplate ? formTemplate.id : null, props),
          }}
          endpoint="form_templates"
          entityKey="formTemplates"
          selectedItemId={formTemplateId}
          showEntityId
        />
      </Box>
    )
  }

  return null
}

const renderCarouselOptions = (selectedEntity, options, props) => {
  if (selectedEntity.item_type === 'carousel'){
    const { carouselAutoplay, carouselCustomGallery, carouselRenderMode } = options

    return (
      <Box flexDirection="column">
        <FieldHolder boxProps={{ marginBottom: 'large' }} label="Render Mode">
          <select
            onChange={e => updateOption('carouselRenderMode', e.target.value, props)}
            value={carouselRenderMode || ''}
          >
            <option value="">Select...</option>
            <option value="inline">Inline</option>
            <option value="modal">Modal</option>
          </select>
        </FieldHolder>

        <Box>
          <Input
            boxProps={{ marginBottom: 'large' }}
            entityId={selectedEntity.id}
            data={options}
            halfWidth
            inputType="number"
            parse={value => Number(value)}
            propertyKey="carouselWidth"
            label="Width"
            updateCallback={(name, value) => updateOption(name, value, props)}
          />

          <Input
            boxProps={{ marginBottom: 'large' }}
            entityId={selectedEntity.id}
            data={options}
            halfWidth
            inputType="number"
            parse={value => Number(value)}
            propertyKey="carouselHeight"
            label="Height"
            last
            updateCallback={(name, value) => updateOption(name, value, props)}
          />
        </Box>

        <Box>
          <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth label="Autoplay">
            <select
              onChange={e => updateOption('carouselAutoplay', e.target.value === 'true', props)}
              value={carouselAutoplay || ''}
            >
              <option value="false">No</option>
              <option value="true">Yes</option>
            </select>
          </FieldHolder>

          <Input
            boxProps={{ marginBottom: 'large' }}
            entityId={selectedEntity.id}
            data={options}
            halfWidth
            inputMinValue="1"
            inputType="number"
            parse={value => Number(value)}
            placeholder="1"
            propertyKey="carouselVisibleSlides"
            label="Visible Slides"
            last
            updateCallback={(name, value) => updateOption(name, value, props)}
          />
        </Box>

        <Box>
          <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth label="Custom Gallery">
            <select
              onChange={e => updateOption('carouselCustomGallery', e.target.value === 'true', props)}
              value={carouselCustomGallery || ''}
            >
              <option value="false">No</option>
              <option value="true">Yes</option>
            </select>
          </FieldHolder>
        </Box>
      </Box>
    )
  }

  return null
}

const renderImageOptions = (selectedEntity, props) => {
  const imageId = digObject(selectedEntity, 'image.id')

  if ((selectedEntity.item_type === 'image' || selectedEntity.item_type === 'imageCarousel') && imageId){
    return (
      <FieldHolder boxProps={{ marginBottom: 'large' }} label="Assigned Image">
        <Button
          buttonStyle="secondaryDestroy"
          onClick={() => removeImage(selectedEntity, props)}
          size="medium"
          style={{ width: '100%' }}
        >
          Remove
        </Button>
      </FieldHolder>
    )
  }

  return null
}

const renderInputOptions = (selectedEntity, options, props) => {
  if (selectedEntity.item_type === 'input'){
    const {
      inputComponent,
      inputDataHidden,
      inputDataStoreBehaviour,
      inputDataStoreSubject,
      inputEditableBy,
      inputRequired,
      inputSecured,
      inputType,
      trigger,
      version,
    } = options

    return (
      <Box flexDirection="column">
        <FieldHolder boxProps={{ marginBottom: 'large' }} label="Component">
          <select onChange={e => updateOption('inputComponent', e.target.value, props)} value={inputComponent}>
            <option value="">Select...</option>
            <option value="input">Input</option>
            <option value="select">Select Box</option>
            <option value="textarea">Text Area</option>
          </select>
        </FieldHolder>
        {inputComponent === 'input' && (
          <FieldHolder boxProps={{ marginBottom: 'large' }} label="Type">
            <select onChange={e => updateOption('inputType', e.target.value, props)} value={inputType}>
              <option value="">Select...</option>
              <option value="checkbox">Checkbox</option>
              <option value="date">Date</option>
              <option value="email">Email</option>
              <option value="number">Number</option>
              <option value="range">Range</option>
              <option value="text">Text</option>
            </select>
          </FieldHolder>
        )}
        {inputComponent !== 'select' && (
          <Input
            boxProps={{ marginBottom: 'large' }}
            entityId={selectedEntity.id}
            data={options}
            propertyKey="inputPlaceholder"
            label="Placeholder"
            updateCallback={(name, value) => updateOption(name, value, props)}
          />
        )}
        {inputComponent === 'select' && (
          <>
            <NestedOptions
              data={options}
              entityId={selectedEntity.id}
              itemKeyRequired
              label="Option Values"
              propertyKey="inputOptions"
              skipStringToKey
              style={{ marginBottom: 'medium' }}
              updateCallback={(name, value) => updateOption(name, value, props)}
            />

            <FieldHolder boxProps={{ marginBottom: 'large' }} key="trigger" last label="Trigger">
              <select
                onChange={e => updateOption('trigger', e.target.value, props)}
                value={options.trigger || 'updateDataStore'}
              >
                <option value="">None</option>
                <option value="setState">Set State</option>
                <option value="updateDataStore">Update Data Store</option>
              </select>
            </FieldHolder>

            {/* Setting the state for trigger  */}
            {trigger === 'setState' && (
              <Box flexDirection="column">
                <Input
                  boxProps={{ marginBottom: 'large' }}
                  entityId={selectedEntity.id}
                  data={options}
                  propertyKey="stateKey"
                  label="State Key"
                  updateCallback={(name, value) => updateOption(name, value, props)}
                  version={version}
                />

                <Input
                  boxProps={{ marginBottom: 'large' }}
                  entityId={selectedEntity.id}
                  data={options}
                  propertyKey="stateValue"
                  label="State Value"
                  updateCallback={(name, value) => updateOption(name, value, props)}
                  version={version}
                />
              </Box>
            )}
          </>
        )}

        {(trigger !== 'setState' || inputComponent !== 'select') && (
          <>
            <FieldHolder boxProps={{ marginBottom: 'large' }} label="Data Store Subject">
              <select
                onChange={e => updateOption('inputDataStoreSubject', e.target.value, props)}
                value={inputDataStoreSubject}
              >
                <option value="">Select...</option>
                <option value="DigitalPage">Digital Page</option>
                <option value="Project">Project</option>
              </select>
            </FieldHolder>
            <Input
              boxProps={{ marginBottom: 'large' }}
              data={options}
              entityId={selectedEntity.id}
              label="Data Store Label"
              propertyKey="inputDataStoreLabel"
              updateCallback={(name, value) => updateOption(name, value, props)}
              version={version}
            />
            <Input
              boxProps={{ marginBottom: 'large' }}
              data={options}
              entityId={selectedEntity.id}
              label="Data Store Key"
              propertyKey="inputDataStoreKey"
              updateCallback={(name, value) => updateOption(name, value, props)}
              version={version}
            />

            <NestedOptions
              data={options}
              entityId={selectedEntity.id}
              label="Data Store Tags"
              propertyKey="inputDataStoreTags"
              style={{ flexDirection: 'column', marginBottom: 'medium' }}
              updateCallback={(name, value) => updateOption(name, value, props)}
            />
          </>
        )}

        <Box flexDirection="row" flexWrap="wrap">
          <FieldHolder boxProps={{ marginBottom: 'large' }} label="Editable By">
            <select onChange={e => updateOption('inputEditableBy', e.target.value, props)} value={inputEditableBy}>
              <option value="">Anyone</option>
              <option value="Agency">Agency</option>
              <option value="OfficeAdministrator">Office Admins</option>
              <option value="Agent">Agents</option>
              <option value="Contact">Recipient</option>
            </select>
          </FieldHolder>

          <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth label="Required">
            <select
              onChange={e => updateOption('inputRequired', e.target.value === 'true', props)}
              value={inputRequired}
            >
              <option value="">Select...</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </FieldHolder>

          <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth last label="Behaviour">
            <select
              onChange={e => updateOption('inputDataStoreBehaviour', e.target.value, props)}
              value={inputDataStoreBehaviour}
            >
              <option value="">Select...</option>
              <option value="add">Add</option>
              <option value="group">Group</option>
              <option value="replace">Replace</option>
            </select>
          </FieldHolder>

          <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth label="Secured">
            <select onChange={e => updateOption('inputSecured', e.target.value === 'true', props)} value={inputSecured}>
              <option value="">Select...</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </FieldHolder>

          <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth last label="Hidden">
            <select
              onChange={e => updateOption('inputDataHidden', e.target.value === 'true', props)}
              value={inputDataHidden}
            >
              <option value="">Select...</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </FieldHolder>
        </Box>
      </Box>
    )
  }

  return null
}

renderInputOptions.propTypes = {
  dataStoreItemKeys: PropTypes.array,
}

const renderMapOptions = (selectedEntity, options, props) => {
  if (selectedEntity.item_type === 'map'){
    const { digitalTemplatePayload } = props

    const { image, height, width } = selectedEntity.options?.marker || {}
    const { id } = image || {}

    return (
      <Box flexDirection="column">
        <DefaultImage
          digitalTemplatePayload={digitalTemplatePayload}
          selectedEntity={selectedEntity}
          callbacks={{
            updateImage: imageId => updateOption('marker', { image: { id: imageId }, height, width }, props),
          }}
        />

        <FieldHolder>
          <Box flexDirection="row">
            <Input
              boxProps={{ marginBottom: 'large' }}
              data={options}
              entityId={selectedEntity.id}
              halfWidth
              inputType="text"
              propertyKey="markerWidth"
              label="Map Marker Width"
              updateCallback={(name, value) => updateOption('marker', { image: { id }, height, width: value }, props)}
            />
            <Input
              boxProps={{ marginBottom: 'large' }}
              data={options}
              entityId={selectedEntity.id}
              halfWidth
              inputType="text"
              propertyKey="markerHeight"
              label="Map Marker Height"
              updateCallback={(name, value) => updateOption('marker', { image: { id }, height: value, width }, props)}
            />
          </Box>
        </FieldHolder>

        <Input
          boxProps={{ marginBottom: 'large' }}
          data={options}
          entityId={selectedEntity.id}
          halfWidth
          inputType="number"
          propertyKey="mapZoom"
          label="Map Zoom"
          updateCallback={(name, value) => updateOption(name, value, props)}
        />
      </Box>
    )
  }

  return null
}

const renderStateOptions = (selectedEntity, options, props) => {
  if (selectedEntity.item_type === 'state'){
    return (
      <DefaultState
        data={options}
        entityId={selectedEntity.id}
        label="Default State"
        propertyKey="defaultState"
        style={{ marginBottom: 'medium' }}
        updateCallback={(name, value) => updateOption(name, value, props)}
      />
    )
  }

  return null
}

const getUpdatedValue = (value, options) => {
  const {
    comparableSource, comparableStatus, teamIndex, nestedContext, userIndex,
  } = options || {}

  const comparableContext = options ? `${comparableSource || 'all'}_${comparableStatus || 'all'}` : 'all_all'

  switch (value){
    case 'project_comparables':
      return `${value}.${comparableContext}`
    case 'teams':
      return teamIndex ? `${value}${teamIndex}${nestedContext}` : value
    case 'project_lead_users':
      return userIndex ? `${value}${userIndex}${nestedContext}` : value
    default:
      return value
  }
}

const handleDataContextChange = (selectedEntity, value, props) => {
  const {
    callbacks: { setDigitalTemplateFeature, updateEntity },
    options,
  } = props

  const updatedValue = getUpdatedValue(value, options)

  const updatedEntity = {
    ...selectedEntity,
    data: {
      ...selectedEntity.data,
      value: `{{${updatedValue}}}`,
    },
    options: {
      ...selectedEntity.options,
      dataContext: value,
    },
  }

  updateEntity(updatedEntity)
  setDigitalTemplateFeature(value)
}

const renderDataContexts = (selectedEntity, options, props) => {
  const { dataContext } = options
  const { item_type } = selectedEntity

  if (item_type === 'dataArrayLoop'){
    return (
      <FieldHolder boxProps={{ marginBottom: 'large' }} label="Data Context">
        <select onChange={e => handleDataContextChange(selectedEntity, e.target.value, props)} value={dataContext}>
          <option value="">Please Select</option>
          <option value="case_studies">Case Studies</option>
          <option value="contacts">Contacts</option>
          <option value="event_calendars">Calendars</option>
          <option value="organization_users">Organization Users</option>
          <option value="project.key_features">Key Features</option>
          <option value="project_combined_users">Combined Users</option>
          <option value="project_comparables">Comparables</option>
          <option value="project_documents">Documents</option>
          <option value="project_images">Images</option>
          <option value="project_lead_users">Lead Users</option>
          <option value="project_support_users">Support Users</option>
          <option value="quotes">Quotes</option>
          <option value="reviews">Reviews</option>
          <option value="suburb_data">Suburb Data</option>
          <option value="chart_data">Chart Data</option>
          <option value="target_audiences">Target Audiences</option>
          <option value="teams">Teams</option>
        </select>
      </FieldHolder>
    )
  }

  return null
}
const updateArrayLoopContext = (arrayLoopKey, optionKey, value, props) => {
  const {
    selectedEntity,
    selectedEntity: { options },
  } = props

  const updatedOptions = {
    ...options,
    [optionKey]: value,
  }

  const updatedEntity = {
    ...selectedEntity,
    options: updatedOptions,
  }

  updateOption(optionKey, value, props)
  handleDataContextChange(updatedEntity, arrayLoopKey, { ...props, options: updatedOptions })
}

const renderDataLoopOptions = (options, props) => {
  const { dataContext } = options

  const contextIndexArray = [
    { key: '.0', label: 'First' },
    { key: '.1', label: 'Second' },
    { key: '.2', label: 'Third' },
    { key: '.3', label: 'Fourth' },
    { key: '.4', label: 'Fifth' },
  ]

  if (dataContext === 'project_comparables'){
    return (
      <Box flexDirection="row">
        <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth label="Comparable Source">
          <select
            onChange={e => updateArrayLoopContext('project_comparables', 'comparableSource', e.target.value, props)}
            value={options.comparableSource}
          >
            <option value="all">All</option>
            <option value="market">Market</option>
            <option value="organization">Organization</option>
          </select>
        </FieldHolder>

        <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth last label="Comparable Status">
          <select
            onChange={e => updateArrayLoopContext('project_comparables', 'comparableStatus', e.target.value, props)}
            value={options.comparableStatus}
          >
            <option value="all">All</option>
            <option value="sold">Sold</option>
            <option value="current">Current</option>
          </select>
        </FieldHolder>
      </Box>
    )
  }

  if (dataContext === 'teams'){
    return (
      <Box flexDirection="row">
        <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth label="Team">
          <select
            onChange={e => updateArrayLoopContext('teams', 'teamIndex', e.target.value, props)}
            value={options.teamIndex}
          >
            <option value="">Please Select</option>
            {contextIndexArray.map(context => (
              <option key={context.key} value={context.key}>
                {context.label}
              </option>
            ))}
          </select>
        </FieldHolder>

        <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth last label="Nested Context">
          <select
            onChange={e => updateArrayLoopContext('teams', 'nestedContext', e.target.value, props)}
            value={options.nestedContext}
          >
            <option value="">Please Select</option>
            <option value=".combined_users">Combined Users</option>
            <option value=".lead_users">Lead Users</option>
            <option value=".support_users">Support Users</option>
          </select>
        </FieldHolder>
      </Box>
    )
  }

  if (dataContext === 'project_lead_users'){
    return (
      <Box flexDirection="row">
        <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth label="User">
          <select
            onChange={e => updateArrayLoopContext('project_lead_users', 'userIndex', e.target.value, props)}
            value={options.userIndex}
          >
            <option value="">Please Select</option>
            {contextIndexArray.map(context => (
              <option key={context.key} value={context.key}>
                {context.label}
              </option>
            ))}
          </select>
        </FieldHolder>

        <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth last label="Nested Context">
          <select
            onChange={e => updateArrayLoopContext('project_lead_users', 'nestedContext', e.target.value, props)}
            value={options.nestedContext}
          >
            <option value="">Please Select</option>
            <option value=".awards">Awards</option>
            <option value=".reviews">Reviews</option>
          </select>
        </FieldHolder>
      </Box>
    )
  }

  return null
}

const renderLoopAutoscaling = (entityType, options, props) => {
  if (entityType === 'digitalTemplatePageSection'){
    return (
      <>
        <FieldHolder boxProps={{ marginBottom: 'large' }} label="Autoscaling w/ Data Loop">
          <select
            onChange={e => updateOption('loopAutoscaling', e.target.value === 'true', props)}
            value={options.loopAutoscaling}
          >
            <option value="false">No</option>
            <option value="true">Yes</option>
          </select>
        </FieldHolder>

        <FieldHolder boxProps={{ marginBottom: 'large' }} label="Clone Items">
          <select
            onChange={e => updateOption('loopAutoscalingCloneItems', e.target.value === 'true', props)}
            value={options.loopAutoscalingCloneItems}
          >
            <option value="false">No</option>
            <option value="true">Yes - Item option must also be enabled</option>
          </select>
        </FieldHolder>
      </>
    )
  }

  return null
}

const renderOffsetLimit = (selectedEntity, options, props) => {
  const { item_type } = selectedEntity
  const permitted = ['dataArrayLoop']

  if (permitted.includes(item_type)){
    return (
      <Box marginBottom="large" flexDirection="row">
        <Input
          data={options}
          entityId={selectedEntity.id}
          halfWidth
          inputType="number"
          parse={value => Number(value)}
          propertyKey="offset"
          label="Offset"
          updateCallback={(name, value) => updateOption(name, value, props)}
        />

        <Input
          data={options}
          entityId={selectedEntity.id}
          halfWidth
          inputType="number"
          last
          parse={value => Number(value)}
          propertyKey="limit"
          label="Limit"
          updateCallback={(name, value) => updateOption(name, value, props)}
        />
      </Box>
    )
  }

  return null
}

const renderOptional = (entityType, selectedEntity, options, props) => {
  const permittedTypes = ['section']

  if (entityType === 'digitalTemplatePageSection' || permittedTypes.includes(selectedEntity.item_type)){
    return (
      <FieldHolder boxProps={{ marginBottom: 'large' }} label="Optional">
        <select
          onChange={e => updateOption('optional', e.target.value === 'true', props)}
          value={options.optional || false}
        >
          <option value="false">No</option>
          <option value="true">Yes</option>
        </select>
      </FieldHolder>
    )
  }

  return null
}

const renderSortable = (entityType, options, props) => {
  if (entityType === 'digitalTemplatePageSectionGroup'){
    return (
      <FieldHolder boxProps={{ marginBottom: 'large' }} label="Sortable">
        <select onChange={e => updateOption('sortable', e.target.value === 'true', props)} value={options.sortable}>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
      </FieldHolder>
    )
  }

  return null
}

const renderSort = (selectedEntity, options, props) => (
  <Input
    boxProps={{ marginBottom: 'large' }}
    entityId={selectedEntity.id}
    data={options}
    parse={value => Number(value)}
    propertyKey="sort"
    label="Sort Position"
    updateCallback={(name, value) => updateOption(name, value, props)}
  />
)

const renderSticky = (entityType, options, props) => {
  if (entityType === 'digitalTemplatePageSectionGroup'){
    return (
      <FieldHolder boxProps={{ marginBottom: 'large' }} last label="Sticky">
        <select onChange={e => updateOption('sticky', e.target.value === 'true', props)} value={options.sticky || ''}>
          <option value="false">No</option>
          <option value="true">Yes</option>
        </select>
      </FieldHolder>
    )
  }

  return null
}

const renderTitle = (entityType, selectedEntity, options, props) => {
  const permittedTypes = ['section']

  if (entityType === 'digitalTemplatePageSection' || permittedTypes.includes(selectedEntity.item_type)){
    return (
      <Input
        boxProps={{ marginBottom: 'large' }}
        entityId={selectedEntity.id}
        data={options}
        propertyKey="title"
        label="Title"
        updateCallback={(name, value) => updateOption(name, value, props)}
      />
    )
  }

  return null
}

const renderToggleable = (entityType, selectedEntity, options, props) => {
  const permittedTypes = ['container']

  if (entityType === 'digitalTemplatePageSection' || permittedTypes.includes(selectedEntity.item_type)){
    return (
      <FieldHolder boxProps={{ marginBottom: 'large' }} label="Toggleable">
        <select
          onChange={e => updateOption('toggleable', e.target.value === 'true', props)}
          value={options.toggleable || ''}
        >
          <option value="">Default</option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
      </FieldHolder>
    )
  }

  return null
}

const renderIconLibrary = (entityType, selectedEntity, options, props) => {
  const permittedTypes = ['icon', 'button']

  if (permittedTypes.includes(selectedEntity.item_type)){
    return (
      <FieldHolder boxProps={{ marginBottom: 'large' }} label="Icon Library">
        <select onChange={e => updateOption('iconLibrary', e.target.value, props)} value={options.iconLibrary || ''}>
          <option value="pro-light">Light</option>
          <option value="pro-solid">Solid</option>
          <option value="free-brands">Brands</option>
        </select>
      </FieldHolder>
    )
  }

  return null
}

const renderVideoOptions = (selectedEntity, options, props) => {
  if (selectedEntity.item_type === 'video'){
    return (
      <FieldHolder boxProps={{ marginBottom: 'large' }} label="Video Provider">
        <select onChange={e => updateOption('videoProvider', e.target.value, props)} value={options.videoProvider}>
          <option value="">Select...</option>
          <option value="youtube">YouTube</option>
          <option value="vimeo">Vimeo</option>
        </select>
      </FieldHolder>
    )
  }

  return null
}

const renderShowWhenEditing = (selectedEntity, options, props) => {
  const permittedTypes = ['button', 'container', 'dataArrayLoop', 'iframe', 'image', 'imageCarousel', 'text']

  if (permittedTypes.includes(selectedEntity.item_type)){
    return (
      <FieldHolder boxProps={{ marginBottom: 'large' }} label="Show Item When Editing">
        <select
          onChange={e => updateOption('showWhenEditing', e.target.value === 'true', props)}
          value={options.showWhenEditing}
        >
          <option value="false">No</option>
          <option value="true">Yes</option>
        </select>
      </FieldHolder>
    )
  }

  return null
}

const renderMakeItCarousel = (selectedEntity, options, props) => {
  const permittedTypes = ['image']

  if (permittedTypes.includes(selectedEntity.item_type)){
    return (
      <FieldHolder boxProps={{ marginBottom: 'large' }} label="Make it Carousel">
        <select
          onChange={e => updateOption('makeItCarousel', e.target.value === 'true', props)}
          value={options.makeItCarousel}
        >
          <option value="false">No</option>
          <option value="true">Yes</option>
        </select>
      </FieldHolder>
    )
  }

  return null
}

const Options = (props) => {
  const {
    entityType,
    selectedEntity,
    selectedEntity: { item_type },
  } = props

  if (!shouldRender(entityType, item_type)) return null

  const options = selectedEntity.options || {}

  return (
    <SidebarModal.ExpandableSectionBox defaultOpen label="Options">
      {renderTitle(entityType, selectedEntity, options, props)}
      {renderSortable(entityType, options, props)}
      {renderSticky(entityType, options, props)}
      {renderDisableContentHolder(entityType, options, props)}
      {renderAnchorId(entityType, selectedEntity, options, props)}
      {renderContentWidth(entityType, options, props)}
      {renderToggleable(entityType, selectedEntity, options, props)}
      {renderOptional(entityType, selectedEntity, options, props)}
      {renderDataContexts(selectedEntity, options, props)}
      {renderButtonOptions(selectedEntity, options, props)}
      {renderChartOptions(selectedEntity, options, props)}
      {renderAdvancedChartOptions(selectedEntity, options, props)}
      {renderDataLoopOptions(options, props)}
      {renderStateOptions(selectedEntity, options, props)}
      {renderLoopAutoscaling(entityType, options, props)}
      {renderOffsetLimit(selectedEntity, options, props)}
      {renderImageOptions(selectedEntity, props)}
      {renderVideoOptions(selectedEntity, options, props)}
      {renderIconLibrary(entityType, selectedEntity, options, props)}
      {renderAnimationOptions(selectedEntity, options, props)}
      {renderCarouselOptions(selectedEntity, options, props)}
      {renderInputOptions(selectedEntity, options, props)}
      {renderCounterOptions(selectedEntity, options, props)}
      {renderMapOptions(selectedEntity, options, props)}
      {renderSort(selectedEntity, options, props)}
      {renderEditable(selectedEntity, options, props)}
      {renderShowWhenEditing(selectedEntity, options, props)}
      {renderCloneWhenAutoscaling(selectedEntity, options, props)}
      {renderFormTemplateOptions(selectedEntity, options, props)}
      {renderMakeItCarousel(selectedEntity, options, props)}
    </SidebarModal.ExpandableSectionBox>
  )
}

Options.propTypes = {
  entityType: PropTypes.string,
  selectedEntity: PropTypes.object.isRequired,
}

export default Options
