export default {
  default: {
    button: {
      edit: 'Edit',
      share: 'Share',
    },
    iconWithMenu: {
      accept: 'Mark as Accepted',
      delete: 'Delete {templateType}',
      generatePdf: 'Generate PDF',
      goToProject: 'Go to Project',
      pending: 'Mark as Pending',
    },
    statusTitle: '{global.status.title}',
    templateId: 'Template ID #{id}',
    views: {
      type: 'count',
      none: 'Not Viewed',
      one: '{count} View',
      many: '{count} Views',
    },
    toast: {
      digitalPageDeleted: '{templateType} Deleted Successfully',
      digitalPageUpdated: 'Digital Page Updated Successfully',
    },
    swal: {
      confirmButtonText: 'Yes, delete it.',
      text: 'Are you sure?',
      title: 'Delete {templateType}?',
    },
  },
}
