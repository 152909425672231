import React from 'react'
import PropTypes from 'prop-types'

import { digObject } from '@campaignhub/javascript-utils'
import { DynamicInput, FormField } from '@campaignhub/suit-theme'

import useCustomField from '@hooks/useCustomField'

const FormInput = (props) => {
  const {
    callbacks: { onChange },
    context: componentContext,
    options,
  } = props

  const { customFieldId, inputKey } = options || {}

  const formState = digObject(componentContext, 'formState', {})

  const itemPayload = useCustomField({ id: customFieldId })
  const {
    customField: {
      field_type, input_type, placeholder, required,
    },
    optionValues,
  } = itemPayload

  return (
    <FormField direction="column">
      <DynamicInput
        currentValue={formState[inputKey] !== undefined ? formState[inputKey] : ''}
        inputComponent={field_type}
        inputType={input_type}
        name={inputKey}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        style={{ height: field_type === 'textarea' ? 80 : null }}
        values={optionValues.map(item => ({ key: item.key, label: item.value }))}
      />
    </FormField>
  )
}

FormInput.propTypes = {
  callbacks: PropTypes.object.isRequired,
  context: PropTypes.shape({
    formState: PropTypes.object.isRequired,
  }).isRequired,
  options: PropTypes.object.isRequired,
}

export default FormInput
