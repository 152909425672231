export default {
  default: {
    buttons: {
      createTemplate: 'Create Template',
    },
    formFieldLabels: {
      title: '* Title',
    },
    formField: {
      ownerType: 'Owner Type',
      organization: 'Organization',
      brand: 'Brand',
      select: 'Please select owner type',
    },
  },
}
