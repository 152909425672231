import api from '@functions/api'

export function queueReportExport(url: string, options: object) {
  return api(url, options)
    .then(({ data }: { data: { queued: true }}) => ({
      success: true as const, data,
    }))
    .catch((data: { errors: string[] }) => {
      const errors = data.errors || ['Error creating report']
      return { success: false as const, errors }
    })
}

export default queueReportExport
