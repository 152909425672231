import React from 'react'
import PropTypes from 'prop-types'

import { Button, SidebarModal } from '@campaignhub/suit-theme'

import useRenderConditionGroups from '@hooks/useRenderConditionGroups'

import RenderConditionGroup from './RenderConditionGroup'

const RenderConditions = (props) => {
  const {
    callbacks: { updateParam },
    selectedEntity,
  } = props

  const renderConditionsPayload = useRenderConditionGroups({ callbacks: { updateParam }, selectedEntity })
  const {
    callbacks: {
      addCondition, createConditionGroup, deleteCondition, deleteGroup,
    },
    renderConditionGroups,
  } = renderConditionsPayload

  return (
    <SidebarModal.ExpandableSectionBox defaultOpen label="Render Conditions">
      <Button
        buttonStyle="ghostCreate"
        onClick={() => createConditionGroup()}
        size="medium"
        width="auto"
        marginBottom="large"
      >
        Create Render Condition Group
      </Button>

      {Object.keys(renderConditionGroups).map((groupKey) => {
        const renderConditions = renderConditionGroups[groupKey] || []

        return (
          <RenderConditionGroup
            callbacks={{
              addCondition,
              deleteCondition,
              deleteGroup,
            }}
            groupKey={groupKey}
            key={groupKey}
            renderConditions={renderConditions}
          />
        )
      })}

    </SidebarModal.ExpandableSectionBox>
  )
}

RenderConditions.propTypes = {
  callbacks: PropTypes.shape({
    updateParam: PropTypes.func.isRequired,
  }),
  selectedEntity: PropTypes.object.isRequired,
}

export default RenderConditions
