export default {
  default: {
    analytics: 'Analytics',
    blankState: {
      title: 'No Comparison Data to Display',
      paragraph: 'Once pages have been created and viewed comparison data will be recorded and displayed',
    },
    metricKeyValues: {
      averageProposalReadPercentage: 'Average Proposal Read Percentage',
      averageProposalSecondsOnPage: 'Average Proposal Seconds on Page',
      averageProposalsAcceptedPerUser: 'Average Proposals Accepted per User',
      averageProposalsCreatedPerUser: 'Average Proposals Created per User',
      totalProposalsAccepted: 'Total Proposals Accepted',
      totalProposalsCreated: 'Total Proposals Created',
    },
    manage: 'Manage',
  },
}
