import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faUser } from '@fortawesome/pro-light-svg-icons'

import {
  FormField, InputSearch, Link, SidebarModal, UserProfileImage,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const mapResults = (users, strings) => {
  const results = {
    groups: [
      {
        key: 'organization',
        title: strings.mapResults?.title || 'ORGANIZATION',
        items: Object.values(users)
          .map((user) => {
            const image = user.image || {}
            return {
              ...user,
              iconLeft: (
                <UserProfileImage image={image} imageKey="square">
                  <FontAwesomeIcon icon={faUser} />
                </UserProfileImage>
              ),
              secondaryText: user.mobile || 'No Mobile',
              text: user.full_name,
              key: `User-${user.id}`,
            }
          }),
      },
    ],
  }

  return results
}

const User = (props) => {
  const {
    imageSelectPayload: {
      callbacks: { selectEntity },
      images,
      selectedEntity,
    },
  } = props

  const { full_name, id, mobile_number } = selectedEntity
  const image = images[selectedEntity.image] || {}

  const users = useSelector(reduxState => reduxState.entities.users)

  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      <FormField label={strings.formFieldLabels?.findUser || 'Find a User'}>
        <InputSearch
          callbacks={{ selectItem: item => selectEntity({ type: 'User', id: item.id }) }}
          closeOnSelect
          placeholder={strings.comparableSearchPlaceholder || 'Search for a user'}
          results={mapResults(users, strings)}
          showOnFocus
        />
      </FormField>

      {!!selectedEntity.id && (
        <SidebarModal.User
          disableHover
          image={image}
          imageKey="sizes.thumb_200x120"
          key={id}
          subtitle={mobile_number}
          title={full_name}
        >
          <Link greyLink onClick={() => selectEntity({ type: 'User', id: 0 })} textProps={{ fontSize: 'small' }}>
            <FontAwesomeIcon icon={faTrashAlt} />
          </Link>
        </SidebarModal.User>
      )}
    </>
  )
}

User.propTypes = {
  imageSelectPayload: PropTypes.shape({
    callbacks: PropTypes.shape({
      selectEntity: PropTypes.func.isRequired,
    }).isRequired,
    images: PropTypes.object.isRequired,
    selectedEntity: PropTypes.object.isRequired,
    selectedEntityId: PropTypes.number.isRequired,
    selectedEntityType: PropTypes.string.isRequired,
  }).isRequired,
}

export default User
