import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus } from '@fortawesome/pro-light-svg-icons'

import { Box, Button } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import DocumentRecipient from './DocumentRecipient'

import localizedStrings from './localizedStrings'

const DocumentRecipients = (props) => {
  const {
    callbacks: { showCreateOrEditDocumentRecipientModal },
    documentTemplatePayload: { filteredRecipients },
  } = props

  const { strings } = useLocalization(localizedStrings)

  return (
    <Box flexDirection="column">
      {filteredRecipients.map(documentRecipient => (
        <DocumentRecipient
          callbacks={{ showCreateOrEditDocumentRecipientModal }}
          documentRecipient={documentRecipient}
          key={documentRecipient.id}
        />
      ))}

      <Button
        buttonStyle="secondaryUtility"
        icon={<FontAwesomeIcon icon={faUserPlus} />}
        onClick={() => showCreateOrEditDocumentRecipientModal()}
        size="medium"
      >
        {strings.buttons?.add || 'Add Recipient Role'}
      </Button>
    </Box>
  )
}

DocumentRecipients.propTypes = {
  callbacks: PropTypes.shape({
    showCreateOrEditDocumentRecipientModal: PropTypes.func.isRequired,
  }).isRequired,
  documentTemplatePayload: PropTypes.shape({
    filteredRecipients: PropTypes.array.isRequired,
  }).isRequired,
}

export default DocumentRecipients
