import { useEffect } from 'react'

import { useSelector } from 'react-redux'

import { useSetState } from '@campaignhub/react-hooks'

import { digObject, toggleArray } from '@campaignhub/javascript-utils'

import useAgreement from '@hooks/useAgreement'

const generateSubjectProps = (subject, defaultImage) => {
  const imageUrl = digObject(defaultImage, 'sizes.thumb_90x90', '')

  if (subject.type === 'User'){
    return {
      imageUrl,
      key: subject.id,
      subtitle: subject.type,
      title: subject.full_name,
    }
  }

  if (subject.type === 'Contact'){
    return {
      key: subject.id,
      subtitle: subject.type,
      title: `${subject.first_name} ${subject.last_name}`,
    }
  }

  return null
}

const toggleRecipient = (params) => {
  const {
    selected, setEntityState, state, setState,
  } = params
  const { assignedContactIds, assignedUserIds } = state

  if (selected.type === 'User'){
    const updateUserIds = toggleArray(assignedUserIds, selected.id)

    setEntityState()
    setState({ assignedUserIds: updateUserIds })
  }

  if (selected.type === 'Contact'){
    const updateContactIds = toggleArray(assignedContactIds, selected.id)

    setEntityState()
    setState({ assignedContactIds: updateContactIds })
  }
}

const defaultState = {
  assignedContactIds: [],
  assignedUserIds: [],
}

const useEditAgreementRecipient = (options = {}) => {
  const { agreementId, subjectId, subjectType } = options

  const [state, setState] = useSetState(defaultState)
  const { assignedContactIds, assignedUserIds } = state

  const entities = useSelector(reduxState => reduxState.entities)
  const { contacts, images, users } = entities

  const { filteredRecipients } = useAgreement({ id: agreementId })

  const agreementContactIds = filteredRecipients
    .map(recipient => (recipient.subject_type === 'Contact' ? recipient.subject_id : null))
    .filter(id => id)

  const agreementUserIds = filteredRecipients
    .map(recipient => (recipient.subject_type === 'User' ? recipient.subject_id : null))
    .filter(id => id)

  useEffect(() => {
    if (agreementContactIds){
      setState({ assignedContactIds: agreementContactIds })
    }

    if (agreementUserIds){
      setState({ assignedUserIds: agreementUserIds })
    }
  }, [agreementContactIds.length, agreementUserIds.length])

  const subject = subjectType === 'User' ? users[subjectId] || {} : contacts[subjectId] || {}

  const defaultImage = images[subject.default_image_id] || {}

  const subjectProps = generateSubjectProps(subject, defaultImage)

  return {
    callbacks: {
      toggleRecipient: (selected, setEntityState) => toggleRecipient({
        selected, setEntityState, state, setState,
      }),
    },
    assignedContactIds,
    assignedUserIds,
    subjectProps,
  }
}

export default useEditAgreementRecipient
