export type EventGroupModel = {
  cache_key: number,
  id: number,
  data: string,
  dates: {
    created: {
      date_time_with_timezone: string,
    },
    end: {
      date_time_with_timezone: string,
    },
    start: {
      date_time_with_timezone: string,
    },
    updated: {
      date_time_with_timezone: string,
    },
  },
  days_from_start: number,
  description: string,
  duration_in_days: number,
  event_calendar_id: number,
  preparation_group?: boolean,
  start_date?: string,
  subtitle: string,
  title: string,
}

export type EventGroupRequestOptions = {}

const state = {
  days_from_start: 0,
  description: '',
  duration_in_days: 7,
  end_date: '',
  event_calendar_id: '',
  id: null,
  preparation_group: false,
  start_date: '',
  subtitle: '',
  title: '',
}

export const requiredFields = [
  { key: 'duration_in_days', validation: (value: string | number) => Number(value) > 0 },
  { key: 'event_calendar_id' },
  { key: 'title' },
]

export default state
