import { Box, ListItem, Text } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

interface DataSetListItemProps {
  callbacks: {
    createOrEditDataSet: (payload: {}) => void,
  },
  dataSet: {
    created_date: string,
    title: string,
  },
}
const DataSetListItem = (props: DataSetListItemProps) => {
  const { callbacks: { createOrEditDataSet }, dataSet, dataSet: { created_date, title } } = props

  const { callbacks: { formatDate } } = useLocalization({})

  return (
    <ListItem
      boxProps={{ as: 'a', borderBottom: '1px dashed', padding: 'large' }}
      onClick={() => createOrEditDataSet(dataSet)}
      showAngleIcon
    >
      <Box flexDirection="column" justifyContent="flex-start" minWidth="0" paddingRight="medium" width="auto">
        <Text fontSize="small" variant="ellipsis">
          {title}
        </Text>

        <Text fontSize="xsmall" marginTop="medium" color="faintGrey">
          {`Uploaded on ${formatDate(created_date, {
            weekday: 'short',
            month: 'long',
            day: 'numeric',
            year: 'numeric',
          })} `}
        </Text>
      </Box>
    </ListItem>
  )
}

export default DataSetListItem
