import { useContext } from 'react'
import PropTypes from 'prop-types'

import Bounce from 'react-reveal/Bounce'
import Fade from 'react-reveal/Fade'
import Flip from 'react-reveal/Flip'
import HeadShake from 'react-reveal/HeadShake'
import Pulse from 'react-reveal/Pulse'
import Zoom from 'react-reveal/Zoom'

import PageContext from '@contexts/pageContext'

const animationComponents = {
  Bounce,
  Fade,
  Flip,
  HeadShake,
  Pulse,
  Zoom,
}

const AnimationWrapper = (props) => {
  const { children, options } = props
  const {
    animationDirection, animationType, animationDelay, animationDuration, animationLoop,
  } = options || {}

  const pageContext = useContext(PageContext)
  const { customizingTemplate, editing, printMode } = pageContext

  if (
    !customizingTemplate
    && !editing
    && !printMode
    && children
    && animationType
    && animationComponents[animationType]
  ){
    const Animation = animationComponents[animationType]
    const animationProps = {
      [animationDirection]: !!animationDirection,
      delay: animationDelay || null,
      duration: animationDuration || 1000,
      forever: animationLoop || false,
    }

    return <Animation {...animationProps}>{children}</Animation>
  }

  return children
}

AnimationWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  options: PropTypes.object.isRequired,
}

export default AnimationWrapper
