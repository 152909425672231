import { digObject } from '@campaignhub/javascript-utils'

import type { DigitalPageModel } from '@models/digitalPage'

export const digitalPageColors = {
  green: {
    solid: '#61BB95',
    background: '#F2FBF8',
  },
  red: {
    solid: '#BB6161',
    background: '#FBF3F2',
  },
  orange: {
    solid: '#BB7C61',
    background: '#FBF7F2',
  },
  blue: {
    solid: '#6180BB',
    background: '#F2F5FB',
  },
  purple: {
    solid: '#8361BB',
    background: '#F7F2FB',
  },
  teal: {
    solid: '#61ABBB',
    background: '#F2F9FB',
  },
}

export const digitalPageLetterColorKeys = {
  a: 'green',
  b: 'green',
  c: 'green',
  d: 'green',
  e: 'red',
  f: 'red',
  g: 'red',
  h: 'red',
  i: 'orange',
  j: 'orange',
  k: 'orange',
  l: 'orange',
  m: 'blue',
  n: 'blue',
  o: 'blue',
  p: 'blue',
  q: 'blue',
  r: 'purple',
  s: 'purple',
  t: 'purple',
  u: 'purple',
  v: 'teal',
  w: 'teal',
  x: 'teal',
  y: 'teal',
  z: 'teal',
}

export const isSectionEnabled = (digitalPage: DigitalPageModel, sectionId: number) => {
  const disabledSections = digObject(digitalPage, 'options.disabled_sections', [])
  return !disabledSections.includes(sectionId)
}

export default digitalPageColors
