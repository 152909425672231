import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import { Box, ListItem, Text } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

const Event = (props) => {
  const {
    event: {
      date_time_with_timezone,
      event_body,
      ip_address,
      user_name,
    },
  } = props

  const eventDate = DateTime.fromISO(date_time_with_timezone).toFormat("yyyy-MM-dd'T'HH:mm")

  const {
    callbacks: { formatDate },
  } = useLocalization()

  return (
    <ListItem
      boxProps={{ alignItems: 'center', borderBottom: '1px dashed', paddingY: 'large' }}
      disableHover
      forceBottomBorder
    >
      <Box flexDirection="column">
        <Text fontSize="xsmall">{`${user_name} from ${ip_address}`}</Text>

        {!!event_body && (
          <Text color="bodyFontLightColor" fontSize="xsmall" lineHeight={1.3} marginTop="small">
            {event_body}
          </Text>
        )}

        <Text
          color="bodyFontLightColor"
          fontSize="xxsmall"
          marginTop="small"
          style={{ textTransform: 'uppercase', flexShrink: 0 }}
        >
          {formatDate(eventDate, {
            weekday: 'short',
            day: 'numeric',
            month: 'long',
            hour: '2-digit',
            minute: '2-digit',
          })}
        </Text>
      </Box>
    </ListItem>
  )
}

Event.propTypes = {
  event: PropTypes.shape({
    date_time_with_timezone: PropTypes.string.isRequired,
    event_body: PropTypes.string,
    ip_address: PropTypes.string.isRequired,
    user_name: PropTypes.string.isRequired,
  }).isRequired,
}

export default Event
