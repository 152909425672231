import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faPencil } from '@fortawesome/pro-light-svg-icons'

import { Button, PageHeader } from '@campaignhub/suit-theme'

import { getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'

import useLocalization from '@hooks/useLocalization'

import DigitalPageMenuItems from './components/DigitalPageMenuItems'
import localizedStrings from './localizedStrings'

const handlePreviewMode = (params) => {
  const { closeModals, togglePreviewMode } = params

  closeModals()
  togglePreviewMode()
}

const Header = (props) => {
  const {
    callbacks: {
      closeModals,
    },
    digitalRendererPayload,
    digitalRendererPayload: {
      callbacks: { togglePreviewMode },
      editing,
      isMobileDevice,
      previewing,
    },
  } = props

  const { redirect } = parsePermittedQueryParams(getQueryParamsFromHash(), ['redirect'])

  const { strings } = useLocalization(localizedStrings)

  return (
    <PageHeader
      boxProps={{ justifyContent: 'flex-start' }}
      offset={{ left: 0, top: 0 }}
      width={!isMobileDevice && editing ? 'calc(100% - 375px)' : '100%'}
      title={strings.title || 'Digital Page'}
      actionContent={(
        <>
          <DigitalPageMenuItems digitalRendererPayload={digitalRendererPayload} />

          <Button
            buttonStyle={previewing ? 'primaryEdit' : 'ghostEdit'}
            icon={<FontAwesomeIcon icon={previewing ? faPencil : faEye} />}
            marginRight="medium"
            marginLeft="medium"
            onClick={() => handlePreviewMode({ closeModals, togglePreviewMode })}
            size="medium"
            width="auto"
          >
            {!isMobileDevice && (previewing ? strings.edit || 'Edit' : strings.preview || 'Preview')}
          </Button>

          <Button as="a" href={redirect || '/'} buttonStyle="primaryCreate" size="medium" width="auto">
            {strings.goBack || 'Go Back'}
          </Button>
        </>
      )}
    />
  )
}

Header.propTypes = {
  callbacks: {
    closeModals: PropTypes.func.isRequired,
  },
  digitalRendererPayload: PropTypes.shape({
    callbacks: PropTypes.shape({
      showManageDigitalPageFeaturesModal: PropTypes.func.isRequired,
      togglePreviewMode: PropTypes.func.isRequired,
    }).isRequired,
    digitalPage: PropTypes.object,
    editing: PropTypes.bool,
    isMobileDevice: PropTypes.bool,
    previewing: PropTypes.bool,
  }).isRequired,
}

export default Header
