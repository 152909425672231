import { useMemo } from 'react'

import { useModals, useSetState } from '@campaignhub/react-hooks'
import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import CustomFieldsContext from '@contexts/customFieldsContext'

import handleCallbackAction from '@functions/handleCallbackAction'

import CreateOrEditCustomFieldModal from '@modals/CreateOrEditCustomFieldModal'
import CreateOrEditNestedFieldModal from '@modals/CreateOrEditNestedFieldModal'
import DuplicateCustomFieldSetModal from '@modals/DuplicateCustomFieldSetModal'

import useEntityCustomField from '@hooks/useEntityCustomField'

import PageContent from './components/PageContent'

const callbacks = (component, setState) => {
  const componentCallbacks = {
    DuplicateCustomFieldSetModal: {
      closeModal: () => setState({ showDuplicateCustomFieldSetModal: false }),
      duplicateCustomFieldSetForOwner: payload => handleCallbackAction(payload),
    },
    CreateOrEditCustomFieldModal: {
      closeModal: () => setState({ showCreateOrEditCustomFieldModal: false }),
      createCustomField: payload => handleCallbackAction(payload),
      deleteCustomField: payload => handleCallbackAction(payload),
      updateCustomField: payload => handleCallbackAction(payload),
    },
    CreateOrEditNestedFieldModal: {
      closeModal: () => setState({ showCreateOrEditNestedFieldModal: false }),
      createOrUpdateNestedField: payload => handleCallbackAction(payload),
      deleteNestedField: payload => handleCallbackAction(payload),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  pageFilters: {},
  showCreateOrEditNestedFieldModal: false,
  showDuplicateCustomFieldSetModal: false,
}

const EditCustomFieldSet = () => {
  const [state, setState] = useSetState(defaultState)
  const { showCreateOrEditNestedFieldModal, showDuplicateCustomFieldSetModal } = state

  const entityCustomFieldSetPayload = useEntityCustomField({
    callbacks: {
      setupLocalCallbacks: setLocalState => callbacks('CreateOrEditCustomFieldModal', setLocalState),
    },
  })

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const pageContext = useMemo(
    () => ({
      callbacks: {
        showDuplicateCustomFieldSetModal: (payload) => {
          setModalData('DuplicateCustomFieldSetModal', payload)
          setState({ showDuplicateCustomFieldSetModal: true })
        },
        showCreateOrEditNestedFieldModal: (payload) => {
          setModalData('CreateOrEditNestedFieldModal', payload)
          setState({ showCreateOrEditNestedFieldModal: true })
        },
      },
    }),
    [modalContext],
  )

  return (
    <CustomFieldsContext.Provider value={entityCustomFieldSetPayload}>
      <PageContext.Provider value={pageContext}>
        <ModalContext.Provider value={modalContext}>
          <PageContent />

          <CreateOrEditCustomFieldModal {...entityCustomFieldSetPayload} clickSafeZone />

          <CreateOrEditNestedFieldModal
            callbacks={callbacks('CreateOrEditNestedFieldModal', setState)}
            clickSafeZone
            showModal={showCreateOrEditNestedFieldModal}
          />

          <DuplicateCustomFieldSetModal
            callbacks={callbacks('DuplicateCustomFieldSetModal', setState)}
            showModal={showDuplicateCustomFieldSetModal}
          />
        </ModalContext.Provider>
      </PageContext.Provider>
    </CustomFieldsContext.Provider>
  )
}

export default EditCustomFieldSet
