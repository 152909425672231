import { isValidMobile } from '@campaignhub/javascript-utils'

import isValidEmail from '@functions/isValidEmail'

import { AddressModel } from './address'

export type ContactModel = {
  cache_key: number,
  id: number,
  address_attributes?: Partial<AddressModel>,
  address_id?: number,
  country_calling_code: string,
  email: string,
  first_name: string,
  full_name: string,
  last_name: string,
  legal_name: string,
  mobile: string,
  owner_id: number,
  owner_type: string,
  phone: string,
  project_ids?: number[],
  realbase_id: number,
  realbase_type: string,
  user_type_id: number,
  users_with_read_access_ids?: number[],
  users_with_write_access_ids?: number[],
}

export type ContactRequestOptions = {
  include_contact_address?: boolean,
  include_contact_data_store_items?: boolean,
  include_contact_project_ids?: boolean,
  include_contact_user_type?: boolean,
  include_contact_users_with_read_access?: boolean,
  include_contact_users_with_write_access?: boolean,
}

const state = {
  address_attributes: {},
  country_calling_code: '+61',
  email: '',
  first_name: '',
  id: null,
  last_name: '',
  legal_name: '',
  mobile: '',
  owner_id: null,
  owner_type: '',
  phone: '',
  project_ids: [],
  user_type_id: '',
  users_with_read_access_ids: [],
  users_with_write_access_ids: [],
}

export const requiredFields = [
  { key: 'email', validation: isValidEmail, invalidMessage: 'Invalid Email format' },
  { key: 'first_name' },
  { key: 'last_name' },
  { key: 'mobile', validation: isValidMobile, invalidMessage: 'Invalid Mobile format' },
  { key: 'user_type_id' },
  { key: 'users_with_write_access_ids', validation: (userIds: number[]) => !!userIds.length },
]

export default state
