interface CustomYAxisProps {
  payload: {
    value: number,
  },
  x: number,
  y: number,
}

const CustomYAxis = (props: CustomYAxisProps) => {
  const {
    payload: { value }, x, y,
  } = props

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={10}
        dy={8}
        fontSize={12}
        textAnchor="middle"
      >
        {value}
      </text>
    </g>

  )
}

export default CustomYAxis
