import { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList, faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, FormField, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import { digObject, toggleArray } from '@campaignhub/javascript-utils'

import useAddress from '@hooks/useAddress'
import useContact, { useContactForm } from '@hooks/useContact'
import useCurrentUser from '@hooks/useCurrentUser'
import useEntityCustomFieldsForm from '@hooks/useEntityCustomFieldsForm'
import useLocalization from '@hooks/useLocalization'
import useOrganizationSelector from '@hooks/useOrganizationSelector'
import useUserTypes from '@hooks/useUserTypes'

import EntityCustomFields from '@components/EntityCustomFields'
import FilterUsersContactsAndTeams from '@components/FilterUsersContactsAndTeams'
import ModalAddress from '@components/ModalComponents/ModalAddress'
import ModalUsers from '@components/ModalComponents/ModalUsers'

import countryCallingCodes from '@functions/countryCallingCodes'

import localizedStrings from './localizedStrings'

const toggleContactReadUsers = (userId, entityState, setEntityState) => {
  const { users_with_read_access_ids } = entityState

  const updatedContactUsers = toggleArray(users_with_read_access_ids, userId)

  setEntityState({ users_with_read_access_ids: updatedContactUsers })
}

const createOrUpdateContact = (params) => {
  const {
    afterActionCallback: customAfterActionCallback, callbacks, createFn, customFields, entityState, updateFn, strings,
  } = params

  const {
    closeModal, createContact, loadShortcodeDataFn, updateContact,
  } = callbacks

  const toastText = entityState.id
    ? strings.toast?.contactUpdated || 'Contact Updated Successfully'
    : strings.toast?.contactCreated || 'Contact Created Successfully'

  const actionFn = entityState.id ? updateFn : createFn

  const afterActionCallback = (response) => {
    closeModal()

    if (loadShortcodeDataFn) loadShortcodeDataFn()
    if (customAfterActionCallback) customAfterActionCallback(response)
  }

  const contactsPayload = {
    callbacks: {
      action: actionFn,
      afterAction: ({ response }) => afterActionCallback(response),
    },
    entityParams: { contact: entityState, customFields },
    toastText,
  }

  return entityState.id ? updateContact(contactsPayload) : createContact(contactsPayload)
}

const confirmDelete = (params) => {
  const {
    afterActionCallback: customAfterActionCallback, callbacks, deleteFn, strings,
  } = params

  const { closeModal, deleteContact, loadShortcodeDataFn } = callbacks

  const afterActionCallback = (response) => {
    closeModal()

    if (loadShortcodeDataFn) loadShortcodeDataFn()
    if (customAfterActionCallback) customAfterActionCallback(response)
  }

  const deleteContactPayload = {
    callbacks: {
      action: deleteFn,
      afterAction: ({ response }) => afterActionCallback(response),
    },
    toastText: strings.toast?.contactDeleted || 'Contact Deleted Successfully',
  }

  swal
    .fire({
      title: strings.sweetAlert?.title || 'Delete Contact?',
      text: strings.sweetAlert?.text || 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: strings.sweetAlert?.confirm || 'Yes, delete it.',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        deleteContact(deleteContactPayload)
      }
    })
}

const CreateOrEditContactModal = (props) => {
  const {
    callbacks, disableDelete, modalKey, showModal,
  } = props

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, `${modalKey}`, {})
  const { callbacks: { afterActionCallback }, contact } = modalPayload

  const {
    address,
    callbacks: { createContact: createFn, updateContact: updateFn, deleteContact: deleteFn },
    creating,
    updating,
  } = useContact(contact)

  const {
    entityState,
    entityState: {
      address_attributes,
      country_calling_code,
      email,
      first_name,
      last_name,
      legal_name,
      mobile,
      phone,
      user_type_id,
      users_with_read_access_ids,
      users_with_write_access_ids,
    },
    errors,
    handlers,
    saveEnabled,
    setEntityState,
  } = useContactForm(contact)

  const { currentUser, hasOrganizationsWhereAdmin } = useCurrentUser()

  const newRecord = !contact.id

  const isContactOwner = contact?.users_with_write_access_ids?.includes(currentUser?.id)

  const disableFormFields = isContactOwner || newRecord ? false : !hasOrganizationsWhereAdmin

  const disableDeleteForIds = isContactOwner || hasOrganizationsWhereAdmin
    ? []
    : [...users_with_read_access_ids, ...users_with_write_access_ids]

  const {
    callbacks: { createOrEditAddress },
  } = useAddress(address)

  const { filteredUserTypes } = useUserTypes({ filters: { subjectType: 'Contact' } })

  const hasAddressAttributes = !!Object.keys(address_attributes).length
  const hasAddress = hasAddressAttributes || !!address.id

  const { selectedOrganization } = useOrganizationSelector()

  const customFieldsForm = useEntityCustomFieldsForm({ id: entityState.id, type: 'Contact' }, selectedOrganization, {
    performHttpRequests: true,
  })

  const {
    callbacks: { buildNestedValues },
    saveEnabled: customFieldsSaveEnabled,
  } = customFieldsForm

  useEffect(() => {
    if (newRecord && !hasOrganizationsWhereAdmin){
      setEntityState({ users_with_write_access_ids: [currentUser?.id] })
    }
  }, [])

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        title={newRecord ? strings.header?.title?.create || 'Create' : strings.header?.title?.edit || 'Edit'}
        titleSecondLine={strings.header?.titleSecondLine || 'Contact'}
      />

      <SidebarModal.Content>
        <Box flexDirection="column">
          <FormField
            direction="column"
            label={strings.formFieldLabels?.userType || '* User Type'}
            errorMessage={errors.user_type_id}
          >
            <select disabled={disableFormFields} name="user_type_id" value={user_type_id} {...handlers}>
              <option value="">Please Select...</option>

              {filteredUserTypes.map(userType => (
                <option key={userType.id} value={userType.id}>
                  {userType.title}
                </option>
              ))}
            </select>
          </FormField>

          <FormField
            direction="column"
            label={strings.formFieldLabels?.firstName || '* First Name'}
            marginTop="large"
            errorMessage={errors.first_name}
          >
            <input disabled={disableFormFields} name="first_name" type="text" value={first_name} {...handlers} />
          </FormField>

          <FormField
            direction="column"
            label={strings.formFieldLabels?.lastName || '* Last Name'}
            marginTop="large"
            errorMessage={errors.last_name}
          >
            <input disabled={disableFormFields} name="last_name" type="text" value={last_name} {...handlers} />
          </FormField>

          <FormField
            direction="column"
            label={strings.formFieldLabels?.legalName || 'Legal Name'}
            marginTop="large"
          >
            <input disabled={disableFormFields} name="legal_name" type="text" value={legal_name} {...handlers} />
          </FormField>

          <FormField
            direction="column"
            label={strings.formFieldLabels?.email || '* Email'}
            marginTop="large"
            errorMessage={errors.email}
          >
            <input disabled={disableFormFields} name="email" type="text" value={email} {...handlers} />
          </FormField>

          <FormField
            direction="column"
            label={strings.formFieldLabels?.phone || 'Phone'}
            marginTop="large"
          >
            <input disabled={disableFormFields} name="phone" type="text" value={phone} {...handlers} />
          </FormField>

          <FormField
            direction="column"
            errorMessage={errors.mobile}
            label={strings.formFieldLabels?.mobile || '* Mobile'}
            marginTop="large"
          >
            <Box>
              <select
                disabled={disableFormFields}
                name="country_calling_code"
                style={{ width: 140, marginRight: 8 }}
                value={country_calling_code}
                {...handlers}
              >
                {countryCallingCodes.map(({ key, value, title }) => (
                  <option key={key} value={value}>
                    {title}
                  </option>
                ))}
              </select>

              <input disabled={disableFormFields} name="mobile" type="text" value={mobile} {...handlers} />
            </Box>
          </FormField>

          {hasOrganizationsWhereAdmin && !users_with_write_access_ids?.length && (
          <FormField
            direction="column"
            marginTop="large"
            label={strings?.formFieldLabels?.contactOwner || '* Contact Owner'}
          >
            <FilterUsersContactsAndTeams
              callbacks={{ selectItem: selected => setEntityState({ users_with_write_access_ids: [selected?.id] }) }}
              disabled={disableFormFields}
              selectedUserIds={users_with_write_access_ids}
            />
          </FormField>
          )}

          {!!users_with_write_access_ids.length && (
            <FormField
              boxProps={{ marginTop: 'large' }}
              label={strings?.contactOwner || 'Contact Owner'}
            >
              <ModalUsers
                callbacks={{ deleteUser: () => setEntityState({ users_with_write_access_ids: [] }) }}
                disableDeleteForUserIds={disableDeleteForIds}
                userIds={users_with_write_access_ids}
              />
            </FormField>
          )}

          <FormField
            direction="column"
            marginTop="large"
            label={strings?.formFieldLabels?.contactViewers || 'Contact Viewers'}
          >
            <FilterUsersContactsAndTeams
              callbacks={{ selectItem: selected => toggleContactReadUsers(selected.id, entityState, setEntityState) }}
              disabled={disableFormFields}
              selectedUserIds={[...users_with_read_access_ids, ...users_with_write_access_ids]}
            />
          </FormField>

          {!!users_with_read_access_ids.length && (
            <FormField
              boxProps={{ marginTop: 'large' }}
              label={strings?.formFieldLabels?.contactViewers || 'Contact Viewers'}
            >
              <ModalUsers
                callbacks={{ deleteUser: userId => toggleContactReadUsers(userId, entityState, setEntityState) }}
                disableDeleteForUserIds={disableDeleteForIds}
                userIds={users_with_read_access_ids}
              />
            </FormField>
          )}

          <Box marginTop="large">
            {!hasAddress && !disableFormFields && (
              <Button
                buttonStyle="ghostEdit"
                icon={<FontAwesomeIcon icon={faList} />}
                onClick={() => createOrEditAddress(entityState, setEntityState)}
                size="medium"
                width="100%"
              >
                {strings.buttons?.address || 'Add Address'}
              </Button>
            )}

            {hasAddress && (
              <FormField
                boxProps={{ marginTop: 'large' }}
                label={strings?.formFieldLabels?.contactAddress || 'Contact Address'}
              >
                <ModalAddress
                  address={hasAddressAttributes ? address_attributes : address}
                  callbacks={{ editAddress: () => createOrEditAddress(entityState, setEntityState) }}
                  disableEdit={disableFormFields}
                />
              </FormField>
            )}
          </Box>

          <EntityCustomFields customFieldsForm={customFieldsForm} sidebar />

          {!disableDelete && !disableFormFields && (
            <FormField
              direction="column"
              label={strings.formFieldLabels?.moreOptions || 'More Options'}
              marginTop="large"
            >
              <Button
                buttonStyle="secondaryUtility"
                onClick={() => confirmDelete({
                  afterActionCallback, callbacks, deleteFn, strings,
                })}
                icon={<FontAwesomeIcon icon={faTrashAlt} />}
                size="medium"
                width="100%"
              >
                {strings.buttons?.delete || 'Delete Contact'}
              </Button>
            </FormField>
          )}
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled || disableFormFields || !customFieldsSaveEnabled}
          loading={creating || updating}
          loadingBubbleColor="white"
          onClick={() => createOrUpdateContact({
            afterActionCallback,
            callbacks,
            createFn,
            customFields: buildNestedValues(),
            entityState,
            updateFn,
            strings,
          })}
          size="large"
        >
          {newRecord ? strings.header?.title?.create || 'Create' : strings.buttons?.save || 'Save'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

CreateOrEditContactModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  disableDelete: PropTypes.bool,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

CreateOrEditContactModal.defaultProps = {
  modalKey: 'CreateOrEditContactModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <CreateOrEditContactModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
