import { useContext } from 'react'

import { digObject } from '@campaignhub/javascript-utils'
import { useForm } from '@campaignhub/react-hooks'

import PageContext from '@contexts/pageContext'

import getVideoUrlId from '@functions/getVideoUrlId'
import replaceTextShortCodes from '@functions/replaceTextShortCodes'

const autoFillVideoDetails = (entityState, setEntityState) => {
  const { videoUrl } = entityState
  const { videoId, videoProvider } = getVideoUrlId(videoUrl)

  setEntityState({ videoId, videoProvider })
}

const defaultState = {
  videoId: '',
  videoProvider: '',
  videoUrl: '',
}

const requiredFields = []

function useVideoPageItem(digitalPageItemPayload){
  const {
    digitalTemplatePageItemId,
    pageItemData: { videoId, videoProvider, videoUrl },
    templatePageItemPayload,
  } = digitalPageItemPayload

  const pageContext = useContext(PageContext)
  const { shortcodeData } = pageContext || {}

  const contextData = { ...shortcodeData }

  const defaultVideoId = digObject(templatePageItemPayload, 'digitalTemplatePageItem.data.value')
  const defaultVideoProvider = digObject(templatePageItemPayload, 'digitalTemplatePageItem.options.videoProvider')

  const shortcodeValue = replaceTextShortCodes(defaultVideoId, contextData)

  const entity = {
    videoId: videoId || shortcodeValue || defaultVideoId,
    videoProvider: videoProvider || defaultVideoProvider,
    videoUrl,
  }

  const formPayload = useForm(defaultState, { entity, requiredFields }, [digitalTemplatePageItemId, videoId])
  const { entityState, setEntityState } = formPayload

  return {
    callbacks: {
      autoFillVideoDetails: () => autoFillVideoDetails(entityState, setEntityState),
    },
    formPayload,
  }
}

export default useVideoPageItem
