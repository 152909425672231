import React from 'react'
import { Helmet } from 'react-helmet-async'
import PropTypes from 'prop-types'

import { faArchive } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Columns, DashboardModule, LoadingModule, PageHeader,
} from '@campaignhub/suit-theme'

import useAssetLibraries from '@hooks/useAssetLibraries'
import useAssetLibrary from '@hooks/useAssetLibrary'
import useLocalization from '@hooks/useLocalization'

import FileLibrariesBlankState from '../FileLibrariesBlankState'
import FileLibrary from '../FileLibrary'
import localizedStrings from './localizedStrings'

const PageContent = (props) => {
  const { organization } = props

  const initFileLibrary = {
    asset_type: 'file',
    owner_id: organization.id,
    owner_type: 'Organization',
  }

  const {
    filteredAssetLibraries: filteredFileLibraries,
    hasAssetLibraries: hasFileLibraries,
    loading,
  } = useAssetLibraries({
    filters: initFileLibrary,
  })

  const {
    callbacks: { createOrEditAssetLibrary },
    urls: { editAssetLibraryUrl },
  } = useAssetLibrary(initFileLibrary)

  const { strings } = useLocalization(localizedStrings)

  const title = strings.title ? `${strings.title || 'File Libraries'} | Engage` : 'Engage'

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <PageHeader
        activeTabBarItemKey="files"
        actionContent={(
          <Button buttonStyle="primaryCreate" onClick={createOrEditAssetLibrary} size="medium">
            {strings.createButton || 'Create Library'}
          </Button>
        )}
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        nestedNavigation
        tabBarItems={[
          {
            href: editAssetLibraryUrl,
            icon: faArchive,
            key: 'files',
            title: strings.tabTitle || 'Files',
          },
        ]}
        title={strings.title || 'File Libraries'}
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && !hasFileLibraries && <FileLibrariesBlankState />}

            {!loading && hasFileLibraries && (
              <DashboardModule title={strings.title || 'File Libraries'}>
                <Box flexDirection="column">
                  {filteredFileLibraries.map(fileLibrary => (
                    <FileLibrary key={fileLibrary.id} fileLibrary={fileLibrary} />
                  ))}
                </Box>
              </DashboardModule>
            )}
          </Columns.Main>
        </Columns>
      </Box>
    </>
  )
}

PageContent.propTypes = {
  organization: PropTypes.object.isRequired,
}

export default PageContent
