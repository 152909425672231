import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faPencil } from '@fortawesome/pro-light-svg-icons'
import { faGripVertical } from '@fortawesome/pro-solid-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'

import {
  Box, Checkbox, Image, Link, ListItem, Text,
} from '@campaignhub/suit-theme'

import useComparable from '@hooks/useComparable'

const DraggableComparable = React.forwardRef((props, ref) => {
  const {
    callbacks: { toggleSelectedComparable },
    comparableId,
    draggableProps,
    dragHandleProps,
    dragSnapshot,
    selected,
  } = props

  const useComparablePayload = useComparable({ id: comparableId })
  const {
    address,
    callbacks: {
      createOrEditComparable,
    },
    defaultImage,
  } = useComparablePayload
  const { full_address, postcode, state_name } = address

  const imageUrl = digObject(defaultImage, 'sizes.thumb_90x90', '')

  const { isDragging } = dragSnapshot || {}

  return (
    <ListItem
      alignItems="center"
      boxProps={{
        alignItems: 'center',
        paddingY: 'large',
      }}
      disableHover
      flexShrink={0}
      forwardProps={{
        ...draggableProps,
        ...dragHandleProps,
      }}
      isDragging={isDragging}
      ref={ref}
    >

      <Box color="#cacaca" fontSize="small" width="auto" marginRight="medium">
        <FontAwesomeIcon icon={faGripVertical} />
      </Box>

      <Checkbox
        boxProps={{ marginRight: 'medium' }}
        onClick={() => toggleSelectedComparable(comparableId)}
        checked={selected}
      />

      <Image
        boxProps={{ backgroundColor: 'hoverLightGrey', fontSize: 'small' }}
        borderRadius={5}
        height={40}
        width={65}
        url={imageUrl}
      >
        {!imageUrl && <FontAwesomeIcon icon={faHome} />}
      </Image>

      <Box flexDirection="column" justifyContent="center" marginLeft="large" marginRight="large">
        <Text color="bodyFontColor" fontSize="small">
          {full_address}
        </Text>

        {(state_name || postcode) && (
          <Text fontSize="xsmall" color="bodyFontLightColor" marginTop="small">
            {state_name} {postcode}
          </Text>
        )}
      </Box>

      <Link greyLink onClick={createOrEditComparable} textProps={{ fontSize: 'small' }}>
        <FontAwesomeIcon icon={faPencil} />
      </Link>
    </ListItem>
  )
})

DraggableComparable.propTypes = {
  callbacks: PropTypes.shape({
    toggleSelectedComparable: PropTypes.func.isRequired,
  }).isRequired,
  comparableId: PropTypes.number.isRequired,
  draggableProps: PropTypes.object,
  dragHandleProps: PropTypes.object,
  dragSnapshot: PropTypes.object,
  selected: PropTypes.bool,
  showSelectedProjectComparable: PropTypes.bool,
}

export default DraggableComparable
