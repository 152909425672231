export default {
  default: {
    formFieldLabels: {
      role: 'Role',
      selectOrganization: '{global.organizationType.shortName.singular}',
      selectText: 'Please Select...',
    },
    toast: {
      created: 'User Added to Organization',
    },
    noticeBox: {
      titles: {
        owner: 'Owner',
        admin: 'Admin',
        user: 'User',
      },
      paragraphs: {
        owner: 'Full access to the system and billing information',
        admin: 'Full access to the system, restricted access to billing information',
        user: 'Limited access to account settings and projects, restricted access to billing information',
      },
    },
    joinButton: 'Join {global.organizationType.shortName.singular}',
    title: 'Join',
    titleSecondLine: '{global.organizationType.shortName.singular}',
  },
}
