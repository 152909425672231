import React from 'react'
import PropTypes from 'prop-types'
import { Duration } from 'luxon'

import { Box, NumberWithIconGrid } from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import DigitalPageIcon from '@components/Icons/DigitalPageIcon'
import PieChartIcon from '@components/Icons/PieChartIcon'
import TimeWithPageIcon from '@components/Icons/TimeWithPageIcon'
import UserIcon from '@components/Icons/UserIcon'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const Statistics = (props) => {
  const { project } = props

  const analytics = digObject(project, 'analytics', {})
  const {
    average_read_percentage, average_read_time, digital_pages, total_page_views,
  } = analytics

  const {
    values: { minutes, seconds },
  } = Duration.fromObject({ seconds: average_read_time }).shiftTo('minutes', 'seconds')

  const {
    callbacks: { formatString },
    strings,
  } = useLocalization(localizedStrings)

  return (
    <Box backgroundColor="white" border="1px solid" borderColor="lineColor" borderRadius={5} flexDirection="column">
      <NumberWithIconGrid>
        <NumberWithIconGrid.Item
          disableHover
          icon={<UserIcon width={40} height={40} fillColor="#8C8C8E" />}
          label={strings.labels?.views || 'Total Page Views'}
          value={String(total_page_views)}
        />

        <NumberWithIconGrid.Item
          disableHover
          icon={<PieChartIcon width={40} height={40} fillColor="#8C8C8E" />}
          label={strings.labels?.averageReadPercentage || 'Avg Read %'}
          value={formatString(strings.readPercentage, { average_read_percentage })}
        />

        <NumberWithIconGrid.Item
          disableHover
          icon={<TimeWithPageIcon width={40} height={40} fillColor="#8C8C8E" />}
          label={strings.labels?.averageReadTime || 'Avg Read Time'}
          value={minutes > 0 ? `${minutes}m ${seconds}s` : `${seconds}s`}
        />

        <NumberWithIconGrid.Item
          disableHover
          icon={<DigitalPageIcon width={38} height={38} fillColor="#8C8C8E" />}
          label={strings.labels?.pagesCreated || 'Pages Created'}
          value={String(digital_pages)}
        />
      </NumberWithIconGrid>
    </Box>
  )
}

Statistics.propTypes = {
  project: PropTypes.object.isRequired,
}

export default Statistics
