import { useMemo } from 'react'

import { Helmet } from 'react-helmet-async'

import useLocalization from '@hooks/useLocalization'

import { useModals, useSetState } from '@campaignhub/react-hooks'
import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import useOrganizationSelector from '@hooks/useOrganizationSelector'
import useOrganizationsUser from '@hooks/useOrganizationsUser'

import handleCallbackAction from '@functions/handleCallbackAction'

import CreateOrEditAddressModal from '@modals/CreateOrEditAddressModal'
import CreateOrEditReviewModal from '@modals/CreateOrEditReviewModal'
import ImportReviewsModal from '@modals/ImportReviewsModal'

import PageContent from './components/PageContent'
import localizedStrings from './localizedStrings'

const callbacks = (component, setState) => {
  const componentCallbacks = {
    CreateOrEditAddressModal: {
      closeModal: () => setState({ showCreateOrEditAddressModal: false }),
      createAddress: payload => handleCallbackAction(payload),
      updateAddress: payload => handleCallbackAction(payload),
    },
    CreateOrEditReviewModal: {
      closeModal: () => setState({ showCreateOrEditReviewModal: false }),
      createReview: payload => handleCallbackAction(payload),
      deleteFromProjects: payload => handleCallbackAction(payload),
      deleteReview: payload => handleCallbackAction(payload),
      updateReview: payload => handleCallbackAction(payload),
    },
    ImportReviewsModal: {
      closeModal: () => setState({ showImportReviewsModal: false }),
      importReviews: payload => handleCallbackAction(payload),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  pageFilters: {},
  showCreateOrEditAddressModal: false,
  showCreateOrEditReviewModal: false,
  showImportReviewsModal: false,
}

const Reviews = () => {
  const [state, setState] = useSetState(defaultState)
  const {
    pageFilters, showCreateOrEditAddressModal, showCreateOrEditReviewModal, showImportReviewsModal,
  } = state

  const { selectedOrganization } = useOrganizationSelector()
  const { isCurrentUserAdminForOrganization } = useOrganizationsUser({ organization_id: selectedOrganization.id })

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const pageContext = useMemo(
    () => ({
      callbacks: {
        showCreateOrEditAddressModal: (payload) => {
          setModalData('CreateOrEditAddressModal', payload)
          setState({ showCreateOrEditAddressModal: true })
        },
        showCreateOrEditReviewModal: (payload) => {
          setModalData('CreateOrEditReviewModal', payload)
          setState({ showCreateOrEditReviewModal: true })
        },
        resetFilters: (resetFn) => {
          if (resetFn) resetFn()
          setState({ pageFilters: {} })
        },
        showImportReviewsModal: () => setState({ showImportReviewsModal: true }),
        updateFilters: (filters) => {
          setState({ pageFilters: filters })
        },
      },
    }),
    [modalContext],
  )

  const { strings } = useLocalization(localizedStrings)

  const title = strings.title ? `${strings.title} | Engage` : 'Engage'

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <PageContext.Provider value={pageContext}>
        <ModalContext.Provider value={modalContext}>
          <PageContent
            isCurrentUserAdminForOrganization={isCurrentUserAdminForOrganization}
            pageFilters={pageFilters}
          />

          <CreateOrEditAddressModal
            callbacks={callbacks('CreateOrEditAddressModal', setState)}
            headerTitle={strings.addressModalHeaderTitle || 'Review'}
            hiddenFields={['title']}
            showModal={showCreateOrEditAddressModal}
          />

          <CreateOrEditReviewModal
            callbacks={callbacks('CreateOrEditReviewModal', setState)}
            showModal={showCreateOrEditReviewModal}
          />

          <ImportReviewsModal
            callbacks={callbacks('ImportReviewsModal', setState)}
            showModal={showImportReviewsModal}
          />
        </ModalContext.Provider>
      </PageContext.Provider>
    </>
  )
}

export default Reviews
