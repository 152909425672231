import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '@campaignhub/suit-theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync } from '@fortawesome/pro-light-svg-icons'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const ReplaceImage = (props) => {
  const { loading, onClick } = props

  const { strings } = useLocalization(localizedStrings)

  return (
    <Button
      buttonStyle="secondaryUtility"
      icon={<FontAwesomeIcon icon={faSync} />}
      loading={loading}
      marginBottom="medium"
      onClick={onClick}
      size="medium"
    >
      {strings.button || 'Replace Image'}
    </Button>
  )
}

ReplaceImage.propTypes = {
  loading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}

export default ReplaceImage
