import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities } from '@redux/entities'

import api from '@functions/api'

import type { CustomFieldModel, OrganizationModel } from '@models/types'
import type { AppDispatch } from '@redux/store'
import type {
  Action, EntityOptions, Errors, FetchRequestPayload, RootReducerState, UpdateParams,
} from '@redux/modules/types'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/organization/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/organization/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/organization/FETCH_FAILURE'

const CREATE_REQUEST = 'realbase/organization/CREATE_REQUEST'
const CREATE_SUCCESS = 'realbase/organization/CREATE_SUCCESS'
const CREATE_FAILURE = 'realbase/organization/CREATE_FAILURE'

const UPDATE_REQUEST = 'realbase/organization/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realbase/organization/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realbase/organization/UPDATE_FAILURE'

const DELETE_REQUEST = 'realbase/organization/DELETE_REQUEST'
const DELETE_SUCCESS = 'realbase/organization/DELETE_SUCCESS'
const DELETE_FAILURE = 'realbase/organization/DELETE_FAILURE'

const SELECT_ORGANIZATION_ID = 'realbase/organization/SELECT_ORGANIZATION_ID'

export type OrganizationModuleState = {
  creating: boolean,
  deleting: boolean,
  errors: string[],
  loaded: boolean,
  loadedForKeys: string[],
  loadedIds: number[],
  loadedShortcodeListIds: number[],
  loading: boolean,
  sandboxId: null | number,
  selectedId: null | number,
  updating: boolean,
}

// Initial State
const initialState: OrganizationModuleState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loadedForKeys: [],
  loadedIds: [],
  loadedShortcodeListIds: [],
  loading: false,
  sandboxId: null,
  selectedId: null,
  updating: false,
}

// Actions
export function fetchRequest(payload: FetchRequestPayload = {}) {
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
    loadedIds: payload.loadedIds,
    loadedShortcodeListIds: payload.loadedShortcodeListIds,
  }
}

export function fetchSuccess(payload: FetchRequestPayload = {}) {
  return {
    type: FETCH_SUCCESS,
    sandboxId: payload.sandboxId,
  }
}

export function fetchFailure(errors: Errors = []) {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest() {
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess() {
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors: Errors = []) {
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest() {
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess() {
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors: Errors = []) {
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest() {
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess() {
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors: Errors = []) {
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

export function selectOrganizationId(organizationId: number) {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: SELECT_ORGANIZATION_ID,
      selectedId: organizationId,
    })
  }
}

// Action Creators
export function loadOrganizations(options: EntityOptions = {}) {
  const { entityKey } = options

  return (dispatch: AppDispatch, getState: () => RootReducerState) => {
    const loadedForKeys = [...getState().organizations.loadedForKeys]

    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('/organizations.json', options)
      .then(({ data }: { data: { entities: OrganizationModel[] } }) => {
        const normalizedJson = normalize(data.entities, Schemas.ORGANIZATION_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function loadOrganization(organizationId: number, options: EntityOptions = {}) {
  const { entityKey } = options

  return (dispatch: AppDispatch, getState: () => RootReducerState) => {
    const state = getState()
    const loadedIds = [...state.organizations.loadedIds]
    const loadedForKeys = [...state.organizations.loadedForKeys]

    if (organizationId && !loadedIds.includes(organizationId)){
      loadedIds.push(organizationId)
    }

    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    // Set Loading
    dispatch(fetchRequest({ loadedForKeys, loadedIds }))

    const promise = api(`/organizations/${organizationId}.json`, options)
      .then(({ data }: { data: { entity: OrganizationModel } }) => {
        const normalizedJson = normalize(data.entity, Schemas.ORGANIZATION)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function loadRelatedEntityCounts(organizationId: number, options: EntityOptions = {}) {
  return (dispatch: AppDispatch) => {
    dispatch(fetchRequest())

    const promise = api(`/organizations/${organizationId}/entity_counts.json`, options)
      .then(({ data }: { data: { data: Record<string, number[]>} }) => {
        dispatch(fetchSuccess())

        return { success: true as const, data: data.data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function generateSupportToken(organizationId: number, options: EntityOptions = {}) {
  return (dispatch: AppDispatch) => {
    dispatch(fetchRequest())

    const promise = api(`/organizations/${organizationId}/generate_support_token.json`, options)
      .then(({ data }: { data: { data: string } }) => {
        dispatch(fetchSuccess())

        return { success: true as const, data: data.data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function createOrganization(organization: Partial<OrganizationModel>, options: EntityOptions = {}) {
  const config = {
    method: 'POST',
    body: JSON.stringify({
      organization,
    }),
  }

  return (dispatch: AppDispatch) => {
    dispatch(createRequest())

    const promise = api('/organizations.json', options, config)
      .then(({ data }: { data: { entity: OrganizationModel } }) => {
        const normalizedJson = normalize(data.entity, Schemas.ORGANIZATION)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function updateOrganization(
  organization: UpdateParams<OrganizationModel>,
  dataStoreItemValues: CustomFieldModel[],
  options: EntityOptions = {},
) {
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      data_store_item_values: dataStoreItemValues || [],
      organization,
    }),
  }

  return (dispatch: AppDispatch) => {
    dispatch(updateRequest())

    const promise = api(`/organizations/${organization.id}.json`, options, config)
      .then(({ data }: { data: { entity: OrganizationModel } }) => {
        const normalizedJson = normalize(data.entity, Schemas.ORGANIZATION)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function updateUserSortOrder(organization: OrganizationModel, sortedIds: number[], options: EntityOptions = {}) {
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      sorted_user_ids: sortedIds,
    }),
  }

  return (dispatch: AppDispatch) => {
    dispatch(updateRequest())

    const promise = api(`/organizations/${organization.id}/update_user_sort_order.json`, options, config)
      .then(({ data }: { data: { entity: OrganizationModel } }) => {
        const normalizedJson = normalize(data.entity, Schemas.ORGANIZATION)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function loadShortcodeList(organization: OrganizationModel, options: EntityOptions = {}) {
  return (dispatch: AppDispatch, getState: () => RootReducerState) => {
    const loadedShortcodeListIds: number[] = [...getState().organizations.loadedShortcodeListIds]
    if (!loadedShortcodeListIds.includes(organization.id)){
      loadedShortcodeListIds.push(organization.id)
    }

    dispatch(fetchRequest({ loadedShortcodeListIds }))

    const promise = api(`/organizations/${organization.id}/shortcode_list.json`, options)
      .then(({ data }: { data: { entity: Record<string, string> }}) => {
        const normalizedJson = normalize(data.entity, Schemas.ORGANIZATION_SHORTCODE_LIST)
        dispatch(updateEntities(normalizedJson))

        const sandboxId = String(organization.id) === 'sandbox' ? data.entity?.id : null
        dispatch(fetchSuccess({ sandboxId }))

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function loadFromExternalPlatform(externalPlatformKey: string, options: EntityOptions = {}) {
  return () => {
    const promise = api(`/organizations/fetch_from_external_platform/${externalPlatformKey}.json`, options)
      .then(({ data }) => ({ success: true, data: data.data }))
      .catch((data) => {
        const errors = handleError(data)
        return { success: false as const, errors }
      })

    return promise
  }
}

type Payload = {
  brand_id: number,
  externalIds: number[],
}

export function bulkImportFromExternalPlatform(
  externalPlatformKey: string,
  payload: Payload,
  options: EntityOptions = {},
) {
  const {
    brand_id, externalIds,
  } = payload

  const config = {
    method: 'POST',
    body: JSON.stringify({
      brand_id,
      external_ids: externalIds,
      integration_platform_key: externalPlatformKey,
    }),
  }

  return () => {
    // eslint-disable-next-line max-len
    const promise = api(`/organizations/bulk_import_from_external_platform/${externalPlatformKey}.json`, options, config)
      .then(({ data }) => ({ success: true, data: data.data }))
      .catch((data) => {
        const errors = handleError(data)
        return { success: false as const, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(
  state: OrganizationModuleState = initialState,
  action: Action = { type: '' },
): OrganizationModuleState {
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loadedIds: action.loadedIds || state.loadedIds,
        loadedShortcodeListIds: action.loadedShortcodeListIds || state.loadedShortcodeListIds,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        sandboxId: action.sandboxId || state.sandboxId,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors || [],
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors || [],
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors || [],
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors || [],
      }
    case SELECT_ORGANIZATION_ID:
      return {
        ...state,
        selectedId: action.selectedId,
      }
    default:
      return state
  }
}
