import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { Box } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import { shouldRenderPageItem } from '@functions/digitalTemplatePageItem'

import useDeviceStyle from '@hooks/useDeviceStyle'
import useDigitalPageForm from '@hooks/useDigitalPageForm'
import useFormTemplate from '@hooks/useFormTemplate'

// eslint-disable-next-line import/no-cycle
import ElementContainer from '../../ElementContainer'

const currentTime = () => new Date().toISOString()

const handleSubmit = (params) => {
  const {
    entityState,
    formTemplate,
    loadDigitalPageShortcodeData,
    logAnalyticsEvent,
    setState,
    shareLinkToken,
    submitFn,
    submitFormResponse,
  } = params

  setState({ loading: true, errors: [] })

  submitFormResponse(entityState, submitFn).then((result) => {
    const { success, errors } = result
    if (!success){
      setState({ loading: false, errors })
      return
    }

    logAnalyticsEvent({
      date: currentTime(),
      key: 'form_submission',
      meta: { id: formTemplate.id, title: formTemplate.title },
    })

    loadDigitalPageShortcodeData({ token: shareLinkToken })

    setState({ loading: false, success: true })
  })
}

const FormTemplate = (props) => {
  const {
    context: componentContext, id, itemEntities, options,
  } = props

  const {
    formTemplateId, renderConditions,
  } = options || {}

  // Context
  const pageContext = useContext(PageContext)
  const {
    callbacks: { loadDigitalPageShortcodeData, logAnalyticsEvent, submitFormResponse },
    shareLinkToken,
    shortcodeData,
  } = pageContext || {}

  const contextData = { ...shortcodeData, ...componentContext }

  // Style
  const style = useDeviceStyle(props)

  const digitalPageFormPayload = useDigitalPageForm(formTemplateId, {
    digitalTemplatePageItemId: id,
  })

  const {
    callbacks: {
      setState,
    },
    digitalPageForm: {
      handlers: {
        onChange,
      },
      entityState,
      saveEnabled,
    },
    errors,
    hasErrors,
    loading,
    success,
    formTemplate,
    formTemplatePageItem,
  } = digitalPageFormPayload

  const formTemplatePayload = useFormTemplate(formTemplate)
  const { callbacks: { submitFormResponse: submitFn } } = formTemplatePayload

  // Should Render
  const shouldRender = shouldRenderPageItem(renderConditions, contextData, options)
  if (!shouldRender) return null

  return (
    <div style={style}>
      {hasErrors && <Box>{errors[0]}</Box>}

      <ElementContainer
        callbacks={{
          onChange,
          onClick: () => handleSubmit({
            entityState,
            formTemplate,
            loadDigitalPageShortcodeData,
            logAnalyticsEvent,
            setState,
            shareLinkToken,
            submitFn,
            submitFormResponse,
          }),
        }}
        forwardContextToItems={{
          buttonDisabled: !saveEnabled,
          buttonLoading: loading,
          formState: entityState,
          success,
        }}
        itemEntities={itemEntities}
        section={formTemplatePageItem}
        renderNested
        render={({ itemComponents }) => itemComponents.map(component => component)}
      />
    </div>
  )
}

FormTemplate.propTypes = {
  context: PropTypes.object,
  id: PropTypes.string.isRequired,
  itemEntities: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
}

export default FormTemplate
