import React from 'react'
import PropTypes from 'prop-types'
import api from '@functions/api'
import { digObject } from '@campaignhub/javascript-utils'

const openUrl = (href) => {
  const config = {
    responseType: 'blob',
    transformResponse: (r) => r,
  }
  
  return api(href, {}, config).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', response.headers.filename)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  })
}

const AuthenticatedDownload = (props) => {
  const { children } = props
  const href = digObject(children, 'props.href')

  return React.cloneElement(children, {
    href: null,
    onClick: () => openUrl(href),
  })
}

AuthenticatedDownload.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AuthenticatedDownload
