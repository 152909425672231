export default {
  default: {
    members: {
      type: 'count',
      none: 'No Members',
      one: '{count} Member',
      many: '{count} Members',
    },
  },
}
