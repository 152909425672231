import React from 'react'
import PropTypes from 'prop-types'

import {
  AreaChart as Chart, Area, CartesianGrid, Legend, ResponsiveContainer, Tooltip,
} from 'recharts'

import { digObject } from '@campaignhub/javascript-utils'

import CustomLegend from '../Legend'
import CustomTooltip from '../Tooltip'

const AreaChart = (props) => {
  const {
    chartHeight,
    dataPayload: { dataArray, dataKeys, theme },
    disableLegend,
    gradient,
    height,
    width,
  } = props

  return (
    <ResponsiveContainer width={width} height={height}>
      <Chart data={dataArray} height={chartHeight}>
        <CartesianGrid stroke="rgba(0, 0, 0, 0.1)" horizontal={false} strokeDasharray="3 3" strokeWidth={1} />

        {gradient
          && dataKeys.map((dataKey) => {
            const primary = digObject(theme, `${dataKey}.colors.primary`, '#8884d8')

            return (
              <defs key={dataKey}>
                <linearGradient id={dataKey} x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={primary} stopOpacity={0.9} />
                  <stop offset="95%" stopColor={primary} stopOpacity={0} />
                </linearGradient>
              </defs>
            )
          })}

        {dataKeys.map((dataKey) => {
          const border = digObject(theme, `${dataKey}.colors.border`, '#8884d8')
          const primary = digObject(theme, `${dataKey}.colors.primary`, '#8884d8')

          return (
            <Area
              type="monotone"
              dataKey={dataKey}
              key={dataKey}
              stroke={border}
              fill={gradient ? `url(#${dataKey})` : primary}
            />
          )
        })}

        <Tooltip content={<CustomTooltip dataKeys={dataKeys} />} />

        {!disableLegend && <Legend content={<CustomLegend />} verticalAlign="bottom" />}
      </Chart>
    </ResponsiveContainer>
  )
}

AreaChart.propTypes = {
  chartHeight: PropTypes.number,
  dataPayload: PropTypes.shape({
    dataArray: PropTypes.array.isRequired,
    dataKeys: PropTypes.array.isRequired,
    theme: PropTypes.object,
  }).isRequired,
  disableLegend: PropTypes.bool,
  gradient: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.string,
}

AreaChart.defaultProps = {
  gradient: true,
  height: 200,
  width: '100%',
}

export default AreaChart
