import { useMemo } from 'react'

import { useLatestEntity, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import { digObject, multiFilteredObjectArraySelector } from '@campaignhub/javascript-utils'

import useSelector from '@hooks/useSelector'

import type { IntegrationPlatformModel } from '@models/types'

const watchEntityKeys = ['images']

const getDataFields = (
  integrationPlatform: IntegrationPlatformModel,
) => digObject(integrationPlatform, 'data.fields', [])

const getSupportedFeatures = (integrationPlatform: IntegrationPlatformModel) => {
  const features = digObject(integrationPlatform, 'supported_features', []) as Record<string, string>[]

  // Feature must be supported e.g. true
  const supportedFeatures = Object.values(features).filter(feature => feature.supported)

  return supportedFeatures
}

const getRequiredDataFields = (platformDataFields: Record<string, string>[]) => {
  const requiredFields = platformDataFields.filter(field => field.required)
  return requiredFields
}

const getOptionalDataFields = (platformDataFields: Record<string, string>[]) => {
  const optionalFields = platformDataFields.filter(field => !field.required)
  return optionalFields
}

function useIntegrationPlatform(initEntity: Partial<IntegrationPlatformModel> = {}) {
  const { entity: integrationPlatform }: { entity: IntegrationPlatformModel} = useLatestEntity(initEntity, 'integrationPlatforms')

  const entities = useSelector(reduxState => reduxState.entities)

  const {
    updatedEntities: { images: imagesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const imageFilters = [
    { key: 'subject_type', value: 'IntegrationPlatform' },
    { key: 'subject_id', value: integrationPlatform.id },
  ]

  const defaultImage = useMemo(() => (
    multiFilteredObjectArraySelector({ entities }, 'images', imageFilters)[0] || {}
  ), [imagesUpdatedAt, integrationPlatform.id])

  const platformDataFields = getDataFields(integrationPlatform)

  const hasDisclaimer = digObject(integrationPlatform, 'data.has_disclaimer', false)
  const saleMethodRequired = digObject(integrationPlatform, 'data.sale_method_required', false)

  const integrationScope = digObject(integrationPlatform, 'data.integration_scope')
  const oauthProviderKey = digObject(integrationPlatform, 'data.oauth_provider_key')
  const isOauth = !!oauthProviderKey

  const supportedFeatures = getSupportedFeatures(integrationPlatform)
  const supportedFeatureKeys = supportedFeatures.map(feature => feature.key)
  const supportedFeaturesCount = supportedFeatures.length

  const isNativeApplication = digObject(integrationPlatform, 'data.native_application', false)

  return {
    dataFields: platformDataFields,
    defaultImage,
    hasDisclaimer,
    integrationPlatform,
    integrationScope,
    isNativeApplication,
    isOauth,
    oauthProviderKey,
    optionalDataFields: getOptionalDataFields(platformDataFields),
    requiredDataFields: getRequiredDataFields(platformDataFields),
    saleMethodRequired,
    supportedFeatureKeys,
    supportedFeatures,
    supportedFeaturesCount,
  }
}

export default useIntegrationPlatform
