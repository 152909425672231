import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faBuilding } from '@fortawesome/pro-light-svg-icons'

import {
  Box, FormField, Image, InputSearch, Link, ListItem, Text, UserProfileImage,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useLocalization from '@hooks/useLocalization'
import useOrganization from '@hooks/useOrganization'

import localizedStrings from './localizedStrings'

const mapResults = (organizations, images, strings) => {
  const results = {
    groups: [
      {
        key: 'organization',
        title: strings.mapResults?.title || 'ORGANIZATION',
        items: Object.values(organizations).map((organization) => {
          const image = images[organization.default_image_id] || {}
          const imageUrl = digObject(image, 'sizes.thumb_90x90')

          return {
            ...organization,
            iconLeft: (
              <UserProfileImage imageUrl={imageUrl}>
                <FontAwesomeIcon icon={faBuilding} />
              </UserProfileImage>
            ),
            secondaryText: organization.email || 'No Email',
            text: organization.title,
            key: `Organization-${organization.id}`,
          }
        }),
      },
    ],
  }

  return results
}

const Organization = (props) => {
  const {
    imageSelectPayload: {
      callbacks: { selectEntity },
      images,
      selectedEntity,
    },
  } = props

  const { id, title } = selectedEntity
  const { defaultImage } = useOrganization({ id })
  const defaultImageUrl = digObject(defaultImage, 'sizes.thumb_90x90', '')

  const organizations = useSelector(reduxState => reduxState.entities.organizations)

  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      <FormField label={strings.formFieldLabels?.findOrganization || 'Find an Organsation'}>
        <InputSearch
          callbacks={{ selectItem: item => selectEntity({ type: 'Organization', id: item.id }) }}
          closeOnSelect
          placeholder={strings.organizationSearchPlaceholder || 'Search for an organsation'}
          results={mapResults(organizations, images, strings)}
          showOnFocus
        />
      </FormField>

      {!!selectedEntity.id && (
        <ListItem
          boxProps={{
            alignItems: 'center',
            borderBottom: '1px dashed',
            borderColor: 'lineColor',
            paddingY: 'large',
          }}
          disableHover
          key={id}
        >
          <Box marginRight="medium" width="auto">
            <Image
              boxProps={{ backgroundColor: 'hoverLightGrey', fontSize: 'small' }}
              circle
              height={30}
              width={30}
              url={defaultImageUrl}
            >
              {!defaultImageUrl && (
                <Box color="bodyFontColor" width="auto">
                  <FontAwesomeIcon icon={faBuilding} />
                </Box>
              )}
            </Image>
          </Box>

          <Box flexDirection="column">
            <Text fontSize="small">{title}</Text>
          </Box>

          <Link
            greyLink
            onClick={() => selectEntity({ type: 'Organization', id: 0 })}
            textProps={{ fontSize: 'small' }}
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </Link>
        </ListItem>
      )}
    </>
  )
}

Organization.propTypes = {
  imageSelectPayload: PropTypes.shape({
    callbacks: PropTypes.shape({
      selectEntity: PropTypes.func.isRequired,
    }).isRequired,
    images: PropTypes.object.isRequired,
    selectedEntity: PropTypes.object.isRequired,
    selectedEntityId: PropTypes.number.isRequired,
    selectedEntityType: PropTypes.string.isRequired,
  }).isRequired,
}

export default Organization
