import { useSelector } from 'react-redux'
import { DateTime } from 'luxon'

import { digObject, snakeToTitleCase } from '@campaignhub/javascript-utils'
import useReduxAction from '@hooks/useReduxAction'

const endpoints: Record<string, string> = {
  benchmark_comparison: '/charts/digital_page_metric_collections/benchmark_comparison_for_metric_keys.json',
  digital_pages_accepted_vs_created_per_month: '/charts/digital_pages/accepted_vs_created_per_month.json',
  digital_pages_engagement_per_day: '/charts/digital_pages/engagement_per_day.json',
  digital_pages_created_vs_viewed_per_day: '/charts/digital_pages/created_vs_viewed_per_day.json',
  digital_pages_views_vs_sent_ratio_per_day: '/charts/digital_pages/views_vs_sent_ratio_per_day.json',
  engagement_per_page_view: '/charts/digital_page_views/engagement_per_page_view_for_entity.json',
  engagement_status_counts: '/charts/digital_pages/engagement_status_counts_for_entity.json',
  total_agreements_created_per_month: 'charts/agreements/total_created_per_month.json',
  total_organizations_created_per_month: '/charts/organizations/total_created_per_month.json',
  total_page_views_created_per_month: '/charts/digital_page_views/total_created_per_month.json',
}

const defaultThemeColors = [
  { primary: '#8361BB', border: '#8361BB' },
  { primary: '#61ABBB', border: '#61ABBB' },
  { primary: '#99BB61', border: '#99BB61' },
  { primary: '#61BB95', border: '#61BB95' },
  { primary: '#6180BB', border: '#6180BB' },
  { primary: '#BBA761', border: '#BBA761' },
]

type UseChartDataOptions = {
  chartDataKey?: string,
  entityType?: string,
  entityId?: number,
  metricKey?: string,
  metricKeys?: string[],
  performHttpRequests?: boolean,
  theme?: string,
}

type GenerateThemePaylaod = {
  dataKeys?: string[],
}

const generateTheme = (payload: GenerateThemePaylaod) => {
  const { dataKeys = [] } = payload || {}

  const theme = dataKeys.reduce((acc, dataKey, index) => {
    acc[dataKey] = {
      colors: defaultThemeColors[index] || defaultThemeColors[0],
      title: snakeToTitleCase(dataKey),
    }

    return acc
  }, {})

  return theme as { colors: string[], title: string }
}

const generateDataKey = (chartKey: string, options: UseChartDataOptions = {}) => {
  const optionsString = Object.keys(options).reduce((acc, optionKey) => {
    const optionValue = digObject(options, `${optionKey}`, '')
    if (optionValue){
      return `${acc}-${optionKey}-${optionValue}`
    }

    return acc
  }, '')

  return `${chartKey}${optionsString}`
}

type GetDataArrayForMetricKeyPaylaod = {
  dataPayload: {
    data: Array<Record<string, string>>,
  },
  metricKeys: string[],
  metricKey?: string,
}

const getDataArrayForMetricKey = (payload: GetDataArrayForMetricKeyPaylaod): Array<Record<string, string>> => {
  const { dataPayload, metricKeys, metricKey: suppliedMetricKey } = payload

  if (metricKeys){
    const metricKey = suppliedMetricKey || metricKeys[0]
    return digObject(dataPayload, `data.${metricKey}`, [])
  }

  return dataPayload.data || []
}

type GetChartDataPaylaod = {
  dataPayload: {
    data: Array<Record<string, string>>,
  },
  excludedDataKeys: string[],
  metricKey?: string,
}

const getChartData = (payload: GetChartDataPaylaod) => {
  const { dataPayload, excludedDataKeys, metricKey } = payload || {}

  const metricKeys = digObject(dataPayload, 'data.metric_keys')

  const dataArray = getDataArrayForMetricKey({ dataPayload, metricKey, metricKeys })

  const firstDataObject = dataArray[0] || {}
  const dataKeys = Object.keys(firstDataObject)
    .filter(k => !excludedDataKeys.includes(k))

  return {
    dataArray,
    dataKeys,
  }
}

type GetLatestDataForMetricKeyPayload = {
  dataPayload: {
    data: Array<Record<string, string>>,
  },
  metricKey?: string,
}

const getLatestDataForMetricKey = (payload: GetLatestDataForMetricKeyPayload) => {
  const { dataPayload, metricKey } = payload
  const metricKeyPayload = digObject(dataPayload, `data.${metricKey}`, [])

  // Get the latest complete data set which is the previous month
  const latestData = metricKeyPayload.find(
    data => data.name === DateTime.now().plus({ months: -1 }).toFormat('yyyy-MM'),
  )

  return latestData || {}
}

export const calculatePercentageChange = (dataArray: [], selectedDataPoint: string) => {
  const previousMonthsData = dataArray.slice(-3, -2)
  const currentMonthsData = dataArray.slice(-2, -1)

  const currentTotalForDataPoint = digObject(currentMonthsData[0], selectedDataPoint, 0)
  const previousMonthTotalForDataPoint = digObject(previousMonthsData[0], selectedDataPoint, 0)

  // eslint-disable-next-line max-len
  const percentageCalculation = ((currentTotalForDataPoint - previousMonthTotalForDataPoint) / previousMonthTotalForDataPoint) * 100
  // eslint-disable-next-line max-len
  const percentChange = Number.isNaN(percentageCalculation) && previousMonthTotalForDataPoint !== 0 ? percentageCalculation : 0

  return percentChange
}

type DataArray = { name: string, value: string }[]

export const formatDataForPieChart = (dataArray: DataArray) => {
  const formattedData = dataArray.reduce((acc, dataObject) => {
    Object.keys(dataObject).map(key => acc.push({ name: key, value: dataObject[key] }))

    return acc
  }, [])

  return formattedData
}

function useChartData(chartKey: string, options: UseChartDataOptions = {}) {
  const {
    chartDataKey: suppliedChartDataKey,
    entityType,
    entityId,
    metricKey,
    metricKeys,
    performHttpRequests = true,
    theme,
  } = options

  const excludedDataKeys: string[] = digObject(options, 'excludedDataKeys', [])

  const endpoint = endpoints[chartKey] || ''

  const chartDataKey = suppliedChartDataKey || generateDataKey(chartKey, options)

  const chartOptions = {
    chart_data_key: chartDataKey,
    entity_id: entityId,
    entity_type: entityType,
    metric_keys: metricKeys,
  }

  const { loading } = useReduxAction('charts', 'loadChartData', chartOptions, [chartDataKey, performHttpRequests], {
    dispatchAction: (action, requestOptions) => action(endpoint, requestOptions),
    shouldPerformFn: (entityReducer) => {
      const { loadedForKeys } = entityReducer

      return !!endpoint && performHttpRequests && !loadedForKeys.includes(chartDataKey)
    },
  })

  const entities = useSelector(reduxState => reduxState.entities)
  const { chartData } = entities

  const dataPayload = chartData[chartDataKey] || {}

  const { dataArray, dataKeys } = getChartData({ dataPayload, excludedDataKeys, metricKey })

  const defaultTheme = !theme ? generateTheme({ dataKeys }) : {}

  return {
    chartDataKey,
    dataArray,
    dataKeys,
    dataPayload,
    loading,
    latestDataForMetricKey: getLatestDataForMetricKey({ dataPayload, metricKey }),
    theme: theme || defaultTheme,
  }
}

export default useChartData
