export default {
  default: {
    sweetAlert: {
      confirmButton: 'Yes, delete it.',
      text: 'Are you sure? ',
      title: 'Delete Section?',
    },
    formFieldLabels: {
      footer: 'Footer',
      header: 'Header',
      hideDates: 'Hide Dates',
      hidePrices: 'Hide Prices',
      hideSubtoal: 'Hide Subtotal',
      moreOptions: 'More Options',
      no: 'No',
      position: 'Position',
      title: '* Title',
      yes: 'Yes',
    },
    buttons: {
      moveDown: 'Move Section Down',
      moveUp: 'Move Section Up',
    },
    createTitle: 'Create',
    deleteButton: 'Delete Section',
    editTitle: 'Edit',
    saveButton: 'Save Section',
    titleSecondLine: 'Quote Section',
  },
}
