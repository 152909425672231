// example urls to strip:
// https://www.youtube.com/watch?v=_E7O-XTFVYE&t=10
// https://youtu.be/_E7O-XTFVYE
// https://vimeo.com/393081930

export default function getVideoUrlId(string: string) {
  let match = {
    videoId: string,
    videoProvider: '',
  }

  const regexes = [
    {
      test: /(?:https)?(?::)?(?:\/\/)?(?:www)?(?:\.)?(?:youtube.com)?(?:\/)?(?:watch)?(?:\?)?(?:v)?(?:\W)?([^"&?\/\.\s]{11})/,
      provider: 'youtube',
    },
    {
      test: /(?:https:\/\/)?youtu.be\/([^"&?\/\s]{11})/,
      provider: 'youtube',
    },
    {
      test: /(?:https)?(?::)?(?:\/\/)?(?:www)?(?:\.)?vimeo.com\/([0-9]{4,16})/,
      provider: 'vimeo',
    },
    {
      test: /([0-9]{4,16})/,
      provider: 'vimeo',
    },
  ]

  regexes.some(({ test, provider }) => {
    if (string.match(test)){
      match = {
        videoId: string.match(test)[1],
        videoProvider: provider,
      }
      return true
    }
    return false
  })

  return match
}
