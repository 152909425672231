import { useContext } from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/pro-light-svg-icons'
import { faArrowsH, faSlidersH } from '@fortawesome/pro-solid-svg-icons'

import {
  Box, Button, Columns, LoadingModule, PageHeader, Tools,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import PageContext from '@contexts/pageContext'

import useAddress from '@hooks/useAddress'
import useIntegrations from '@hooks/useIntegrations'
import useLocalization from '@hooks/useLocalization'
import useUserIntegrations from '@hooks/useUserIntegrations'
import useVideoModules from '@hooks/useVideoModules'

import IntegrationPartner from '@components/IntegrationPartner'
import IntegrationsBlankState from '@components/IntegrationsBlankState'
import VideoModule from '@components/VideoModule'

import IntegrationPlatform from '../IntegrationPlatform'
import localizedStrings from './localizedStrings'

const PageContent = (props) => {
  const { currentUser, isSelectedOrganizationAdmin, selectedOrganization } = props

  const pageContext = useContext(PageContext)
  const {
    callbacks: {
      showBulkUpdateIntegrationsFeaturesModal,
      showManageEntityDefaultIntegrationPlatformsModal,
      showSelectIntegrationPlatformModal,
    },
  } = pageContext

  const integrationPayload = useIntegrations({
    filters: {
      owner_id: selectedOrganization.id,
      owner_type: 'Organization',
      subject_id: isSelectedOrganizationAdmin ? null : currentUser.id,
      subject_type: isSelectedOrganizationAdmin ? null : 'User',
    },
  })
  const { groupedIntegrations, hasGroupedIntegrations, loading } = integrationPayload

  const videoModulePayload = useVideoModules({ key: 'integrations' })
  const { videosPayload } = videoModulePayload

  const useIntegrationsPayload = useUserIntegrations({ integrationPlatformKey: 'real_time_agent' })
  const { hasIntegrationForKey } = useIntegrationsPayload

  const addressPayload = useAddress({ id: selectedOrganization.default_address_id })
  const { address } = addressPayload
  const organizationState = digObject(address, 'state_name', '').toLowerCase()

  const shouldShowIntegrationPartner = organizationState !== 'wa' && organizationState !== 'nt'

  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      <PageHeader
        activeTabBarItemKey="integrations"
        actionContent={(
          <Button
            buttonStyle="primaryCreate"
            onClick={() => showSelectIntegrationPlatformModal()}
            size="medium"
            width="auto"
          >
            {strings.createButton || 'Create'}
          </Button>
        )}
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        nestedNavigation
        tabBarItems={[
          {
            href: '#/admin/integrations',
            icon: faCog,
            key: 'integrations',
            title: strings.title || 'Integrations',
          },
        ]}
        title={strings.title || 'Integrations'}
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />
            {!loading && !hasGroupedIntegrations && <IntegrationsBlankState />}

            {!loading
              && hasGroupedIntegrations
              && Object.values(groupedIntegrations).map(groupedIntegration => (
                <IntegrationPlatform groupedIntegration={groupedIntegration} key={groupedIntegration.id} />
              ))}
          </Columns.Main>

          <Columns.Sidebar>
            <Tools title={strings.tools || 'Tools'}>
              <Button
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faSlidersH} />}
                onClick={() => showManageEntityDefaultIntegrationPlatformsModal()}
                size="medium"
                width="auto"
              >
                {strings.setDefaultFeatures || 'Set Default Features'}
              </Button>

              {isSelectedOrganizationAdmin && (
                <Button
                  buttonStyle="secondaryUtility"
                  icon={<FontAwesomeIcon icon={faArrowsH} />}
                  onClick={() => showBulkUpdateIntegrationsFeaturesModal()}
                  size="medium"
                  marginTop="medium"
                  width="auto"
                >
                  {strings.bulkUpdateIntegrations || 'Bulk Update Integrations'}
                </Button>
              )}
            </Tools>

            {!hasIntegrationForKey && shouldShowIntegrationPartner && (
              <IntegrationPartner
                // eslint-disable-next-line max-len
                description={strings.integrationPartner?.description || 'Learn how to generate an agreement with RTA integration'}
                integrationPlatformKey={strings.integrationPartner?.integrationPlatformKey || 'real_time_agent'}
                title={strings.integrationPartner?.title || 'Sign up to Real Time Agent'}
              />
            )}

            <VideoModule
              marginTop={!hasIntegrationForKey && shouldShowIntegrationPartner && 'large'}
              videosPayload={videosPayload}
            />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

PageContent.propTypes = {
  currentUser: PropTypes.object,
  isSelectedOrganizationAdmin: PropTypes.bool,
  selectedOrganization: PropTypes.object.isRequired,
}

export default PageContent
