import React from 'react'
import PropTypes from 'prop-types'

import PdfDownloadContext from '@contexts/pdfDownloadContext'

import usePdfDownload from '@hooks/usePdfDownload'

const PdfDownloadProvider = (props) => {
  const { children } = props

  const pdfDownloadPayload = usePdfDownload()

  return (
    <PdfDownloadContext.Provider value={pdfDownloadPayload}>
      {children}
    </PdfDownloadContext.Provider>
  )
}

PdfDownloadProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PdfDownloadProvider
