import { useEffect } from 'react'
import  { VideoModuleType, VideoModulesLibraryType } from '@functions/videoModulesLibrary'

import { useSetState } from '@campaignhub/react-hooks'

import { digObject } from '@campaignhub/javascript-utils'

type DefaultStateTypes = {
  openKey: string,
  videoModuleUrl: string,
  videoParagraph: string,
  videoTitle: string,
}

const defaultState = {
  videoModuleUrl: '',
  videoParagraph: '',
  videoTitle: '',
}

const toggleVideoModule = (params: VideoModuleType, { openKey }: DefaultStateTypes, setState: any) : void => {
  const {
    key, videoUrl, title, paragraph,
  } = params

  setState({
    openKey: openKey !== key ? key : null,
    videoModuleUrl: videoUrl,
    videoParagraph: paragraph,
    videoTitle: title,
  })
}

function useVideoModule(videosPayload: VideoModulesLibraryType) {
  const [state, setState] = useSetState(defaultState)

  useEffect(
    () => setState({
      openKey: digObject(videosPayload[0], 'key', ''),
      videoModuleUrl: digObject(videosPayload[0], 'videoUrl', ''),
      videoParagraph: digObject(videosPayload[0], 'paragraph', ''),
      videoTitle: digObject(videosPayload[0], 'title', ''),
    }),
    [],
  )

  const shouldShowFooter = videosPayload.length > 1

  return {
    callbacks: {
      toggleVideoModule: (params: VideoModuleType) => toggleVideoModule(params, state, setState),
    },
    shouldShowFooter,
    state,
  }
}

export default useVideoModule
