import { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile, faUsers } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Columns, DashboardModule, LoadingModule, PageHeader, Tools,
} from '@campaignhub/suit-theme'

import { sortArrayBy } from '@campaignhub/javascript-utils'

import useCurrentUser from '@hooks/useCurrentUser'
import useIntercom from '@hooks/useIntercom'
import useMixpanel from '@hooks/useMixpanel'
import useUsers from '@hooks/useUsers'

import PageContext from '@contexts/pageContext'

import PageFilterModule from '@components/PageFilterModule'
import UserListItem from '@components/UserListItem'

import UsersBlankState from '../../UsersBlankState'

const buildPageFilterFields = (entities) => {
  const { roles } = entities

  const boxProps = {
    marginBottom: 'large',
  }

  const style = {
    marginBottom: 16,
  }

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        style,
      },
      key: 'string',
      label: 'Filter by Name, Email or ID',
    },
    {
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: 'All',
        inputComponent: 'select',
        style,
      },
      key: 'role_id',
      label: 'Role',
      values: sortArrayBy(Object.values(roles), 'asc', 'title').map(role => ({
        key: role.id,
        label: role.title,
      })),
    },
    {
      component: 'EntitySelector',
      componentProps: {
        boxProps,
        entityKey: 'brands',
      },
      key: 'brand_id',
    },
    {
      callbacks: {
        buildSearchRequestOptions: state => ({ brand_id: state.brand_id }),
        shouldRender: state => !!state.brand_id,
      },
      component: 'EntitySelector',
      componentProps: {
        boxProps,
        entityKey: 'organizations',
      },
      dependentKey: 'brand_id',
      key: 'organization_id',
    },
    {
      entityKey: 'hidden',
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: 'All',
        inputComponent: 'select',
        style,
      },
      key: 'active',
      label: 'Active',
      values: [
        { key: 'true', label: 'Yes' },
        { key: 'false', label: 'No' },
      ],
    },
  ]
}

const PageContent = (props) => {
  const { pageFilters } = props

  const useUsersPayload = useUsers({ filters: pageFilters, performHttpRequests: true })

  const {
    callbacks: { loadMore }, canLoadMore, filteredUsers, hasFilteredUsers, loading,
  } = useUsersPayload

  const pageContext = useContext(PageContext)
  const {
    callbacks: { resetFilters, showImportUsersModal, updateFilters },
  } = pageContext

  const entities = useSelector(reduxState => reduxState.entities)

  const { isBrandUser } = useCurrentUser()

  useIntercom({ hideWidget: !isBrandUser })

  // Tracks the page load event
  const { callbacks: { dispatchMixpanelEvent } } = useMixpanel()
  useEffect(() => {
    if (isBrandUser) dispatchMixpanelEvent('Users Load')
  }, [])

  return (
    <>
      <PageHeader
        activeTabBarItemKey="all-users"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        tabBarItems={[
          {
            href: '#/systemManager/users',
            icon: faUsers,
            key: 'all-users',
            title: 'Users',
          },
        ]}
        title="Users"
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && !hasFilteredUsers && <UsersBlankState />}

            {!loading && hasFilteredUsers && (
              <DashboardModule title="Users">
                <Box flexDirection="column">
                  <Box flexDirection="column">
                    {filteredUsers.map(user => (
                      <UserListItem key={user.id} user={user} />
                    ))}
                  </Box>

                  <DashboardModule.LoadMoreFooter
                    callbacks={{ loadMore }}
                    canLoadMore={canLoadMore}
                    entityCount={filteredUsers.length}
                    loading={loading}
                  />
                </Box>
              </DashboardModule>
            )}
          </Columns.Main>

          <Columns.Sidebar>
            <Tools>
              <Button
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faFile} />}
                marginBottom="medium"
                onClick={() => showImportUsersModal()}
                size="medium"
              >
                Import Users
              </Button>
            </Tools>

            <PageFilterModule
              callbacks={{ resetFilters, updateFilters }}
              filterFields={buildPageFilterFields(entities)}
              pageFilters={pageFilters}
              title="Filters"
            />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

PageContent.propTypes = {
  pageFilters: PropTypes.object,
}

export default PageContent
