import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare } from '@fortawesome/pro-solid-svg-icons'

import { SelectBox } from '@campaignhub/suit-theme'

import { recipientColors, roleTitles } from '@hooks/useDocumentRecipient'

const mapSelectBoxItems = (filteredRecipients, userTypes) => {
  const mappedItems = filteredRecipients.map((documentRecipient, index) => {
    const { id, role_key, user_type_id } = documentRecipient
    const userType = userTypes[user_type_id] || {}

    return {
      customIcon: <FontAwesomeIcon icon={faSquare} style={{ color: recipientColors[index], marginRight: 8 }} />,
      id,
      key: id,
      title: userType.title || roleTitles[role_key] || '',
    }
  })

  return mappedItems
}

const SelectRecipient = (props) => {
  const {
    callbacks: { selectItem },
    documentRecipients,
    selectedRecipientId,
  } = props

  const entities = useSelector(reduxState => reduxState.entities)
  const { userTypes } = entities

  return (
    <SelectBox
      callbacks={{
        selectItem,
      }}
      items={mapSelectBoxItems(documentRecipients, userTypes)}
      selectedItemKey={selectedRecipientId}
      style={{ zIndex: 3 }}
      ulStyle={{ maxHeight: 300, overflow: 'auto' }}
    />
  )
}

SelectRecipient.propTypes = {
  callbacks: PropTypes.shape({
    selectItem: PropTypes.func.isRequired,
  }).isRequired,
  documentRecipients: PropTypes.array.isRequired,
  selectedRecipientId: PropTypes.number,
}

export default SelectRecipient
