export default {
  default: {
    addedSuburbData: 'Added Suburb Data',
    buttons: {
      cancel: 'Cancel',
      fetch: 'Fetch Data',
      save: 'Save',
    },
    formFieldLabels: {
      platformLabel: 'Platform',
      searchSuburb: 'Search Suburb',
      suburb: 'Suburb',
    },
    options: 'Please Select...',
    sideBarModalHeader: {
      title: 'Suburb',
      titleSecondLine: 'Data',
    },
    sideBarNoticeBox: {
      title: 'Add Suburb Data',
      paragraph: 'To import suburb data for your page please search for the desired suburb and click Fetch Data. This will add the suburb data to the page.',
    },
  },
}
