import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'

import { Box, Text } from '@campaignhub/suit-theme'

const SelectableIcon = (props) => {
  const {
    callbacks: { toggleSelected },
    isSelected,
    selectedIndex,
  } = props

  return (
    <Box
      alignItems="center"
      backgroundColor={isSelected ? 'blue' : 'transparent'}
      border="1px solid"
      borderColor={isSelected ? 'blue' : 'lineColor'}
      borderRadius="100%"
      height={28}
      justifyContent="center"
      onClick={toggleSelected}
      style={{ cursor: 'pointer' }}
      width={28}
    >
      <Text color={isSelected ? 'white' : 'lineColor'} fontSize="xsmall">
        {isSelected ? selectedIndex + 1 : <FontAwesomeIcon icon={faCheck} />}
      </Text>
    </Box>
  )
}

SelectableIcon.propTypes = {
  callbacks: PropTypes.shape({
    toggleSelected: PropTypes.func.isRequired,
  }).isRequired,
  isSelected: PropTypes.bool.isRequired,
  selectedIndex: PropTypes.number,
}

export default SelectableIcon
