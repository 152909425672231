export default {
  default: {
    buttonText: {
      save: 'Save',
    },
    sortedAward: 'Awards Sorted Successfully',
    modalHeader: {
      title: 'Sort',
      titleSecondLine: 'Awards',
    },
  },
}
