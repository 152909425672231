import { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { sortArrayBy } from '@campaignhub/javascript-utils'
import {
  useForm, useLatestEntity, useThunkDispatch, useWatchEntityUpdates,
} from '@campaignhub/react-hooks'

import defaultFormState, { requiredFields } from '@models/documentTemplatePageItem'

import * as documentTemplatePageItemActions from '@redux/modules/documentTemplatePageItem'

const watchEntityKeys = ['documentTemplatePageItems']

export const createDocumentTemplatePageItem = (documentTemplatePageItem, dispatch, requestOptions) => {
  const { createDocumentTemplatePageItem: createFn } = documentTemplatePageItemActions
  return dispatch(createFn(documentTemplatePageItem, requestOptions))
}

const updateDocumentTemplatePageItem = (documentTemplatePageItem, dispatch, requestOptions) => {
  const { updateDocumentTemplatePageItem: updateFn } = documentTemplatePageItemActions
  return dispatch(updateFn(documentTemplatePageItem, requestOptions))
}

const deleteDocumentTemplatePageItem = (documentTemplatePageItem, dispatch, requestOptions) => {
  const { deleteDocumentTemplatePageItem: deleteFn } = documentTemplatePageItemActions
  return dispatch(deleteFn(documentTemplatePageItem, requestOptions))
}

export function useDocumentTemplatePageItemForm(documentTemplatePageItem = {}, options = {}){
  const { customRequiredFields = [] } = options

  const { creating, updating } = useSelector(reduxState => reduxState.documentTemplatePageItems)

  const documentTemplatePageItemForm = useForm(
    defaultFormState,
    {
      entity: documentTemplatePageItem,
      requiredFields: [...requiredFields, ...customRequiredFields],
      validateOn: 'change',
    },
    [documentTemplatePageItem.id, documentTemplatePageItem.cache_key],
  )

  return {
    ...documentTemplatePageItemForm,
    creating,
    updating,
  }
}

export function useRelations(documentTemplatePageItem = {}){
  const {
    document_recipient_id,
    document_template_page_id,
  } = documentTemplatePageItem || {}

  const entities = useSelector(reduxState => reduxState.entities)
  const {
    documentRecipients,
    documentTemplatePages,
  } = entities

  const documentRecipient = documentRecipients[document_recipient_id] || {}
  const documentTemplatePage = documentTemplatePages[document_template_page_id] || {}

  return {
    documentRecipient,
    documentTemplatePage,
  }
}

function useDocumentTemplatePageItem(initEntity){
  const { entity: documentTemplatePageItem } = useLatestEntity(initEntity, 'documentTemplatePageItems')
  const { options, document_template_page_id, id } = documentTemplatePageItem
  const { custom_field_id } = options || {}

  const {
    updatedEntities: {
      documentTemplatePageItems: documentTemplatePageItemsUpdatedAt,
    },
  } = useWatchEntityUpdates(watchEntityKeys)

  const dispatch = useThunkDispatch()

  const entities = useSelector(reduxState => reduxState.entities)
  const { documentTemplatePages } = entities

  const documentTemplatePage = documentTemplatePages[document_template_page_id] || {}
  const { document_template_id } = documentTemplatePage

  const isCustomField = !!custom_field_id

  return {
    callbacks: {
      createDocumentTemplatePageItem: (params, requestOptions) => createDocumentTemplatePageItem(params, dispatch, requestOptions),
      deleteDocumentTemplatePageItem: requestOptions => deleteDocumentTemplatePageItem(documentTemplatePageItem, dispatch, requestOptions),
      updateDocumentTemplatePageItem: (params, requestOptions) => updateDocumentTemplatePageItem({
        document_template_id, document_template_page_id, id, ...params,
      }, dispatch, requestOptions),
    },
    documentTemplatePageItem,
    isCustomField,
  }
}

export default useDocumentTemplatePageItem
