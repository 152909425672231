import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useSetState, useThunkDispatch } from '@campaignhub/react-hooks'

import {
  digObject, modifyGroupedIdsSort, sortArrayBy, toggleArray,
} from '@campaignhub/javascript-utils'

import useComparables from '@hooks/useComparables'

import * as comparableActions from '@redux/modules/comparable'

const toggleSelectedComparable = (params) => {
  const { comparableId, setState, state } = params

  const { selectedComparableIds } = state
  const updatedcomparableIds = toggleArray(selectedComparableIds, comparableId)

  setState({ selectedComparableIds: updatedcomparableIds })
}

const toggleAllProjectComparable = (params) => {
  const { state, setState } = params
  const { comparableIds, selectedComparableIds } = state

  if (selectedComparableIds.length){
    setState({ selectedComparableIds: [] })
    return
  }

  setState({ selectedComparableIds: comparableIds })
}

const updateComparableSort = (params) => {
  const { dispatch, requestOptions, sortOrder } = params

  const { updateSortOrder } = comparableActions

  return dispatch(updateSortOrder(sortOrder, requestOptions))
}

const modifyComparablesSort = (params) => {
  const {
    comparableIds, dispatch, fromIndex, payload, requestOptions, setState, toIndex,
  } = params

  const groupedIds = modifyGroupedIdsSort(fromIndex, toIndex, payload, { comparableIds })
  setState({ comparableIds: groupedIds.comparableIds })

  return updateComparableSort({
    dispatch, requestOptions, sortOrder: groupedIds.comparableIds,
  })
}

const defaultState = {
  comparableIds: [],
  filterString: '',
  selectedComparableIds: [],
  showSelectedProjectComparable: false,
}

const useManageComparables = (project, options = {}) => {
  const {
    comparableSource, comparableStatus, digitalPage,
  } = options

  const [state, setState] = useSetState(defaultState)
  const { comparableIds, filterString } = state

  const dispatch = useThunkDispatch()

  const { loading, updating } = useSelector(reduxState => reduxState.comparables)

  const { filteredComparables } = useComparables({
    filters: {
      comparableSource,
      comparableStatus,
      projectId: project.id,
      string: filterString,
    },
  })

  const digitalPageComparableIds = digObject(digitalPage, 'comparable_ids', [])

  const sortedIds = sortArrayBy(filteredComparables, 'asc', 'sort').map(comparable => comparable.id)

  // Set comparableIds into state when modal opens
  // Or when the filteredComparables change to handle create or update
  useEffect(() => {
    const sortedComparableIds = digitalPageComparableIds.length ? digitalPageComparableIds : sortedIds
    setState({ comparableIds: sortedIds, selectedComparableIds: sortedComparableIds })
  }, [digitalPageComparableIds.length])

  useEffect(() => {
    setState({ comparableIds: sortedIds })
  }, [JSON.stringify(filteredComparables)])

  return {
    callbacks: {
      modifyComparablesSort: (fromIndex, toIndex, payload, requestOptions) => modifyComparablesSort({
        comparableIds, dispatch, fromIndex, payload, requestOptions, setState, toIndex,
      }),
      toggleSelectedComparable: comparableId => toggleSelectedComparable({
        comparableId, setState, state,
      }),
      updateComparableSort: requestOptions => updateComparableSort({ dispatch, requestOptions, sortOrder: comparableIds }),
      toggleAllProjectComparable: () => toggleAllProjectComparable({ state, setState }),
      setState,
    },
    loading,
    state,
    updating,
  }
}

export default useManageComparables
