import React from 'react'
import PropTypes from 'prop-types'

import useNumericParams from '@hooks/useNumericParams'

import AgreementBuilder from '@components/AgreementBuilder'

const AgreementBuilderPack = () => {
  const { agreementId } = useNumericParams()

  return (
    <AgreementBuilder agreementId={agreementId} />
  )
}

AgreementBuilderPack.propTypes = {}

export default AgreementBuilderPack
