import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartBar } from '@fortawesome/pro-solid-svg-icons'

import {
  Box, Image, ListItem, Text,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'
import useShareLink from '@hooks/useShareLink'

import localizedStrings from './localizedStrings'

const ShareLink = (props) => {
  const {
    shareLink,
    shareLink: { title, tracking_enabled },
    viewCount,
  } = props

  const {
    callbacks: { editShareLink },
  } = useShareLink(shareLink)

  const {
    callbacks: { formatCount },
    strings,
  } = useLocalization(localizedStrings)

  const trackingStatus = tracking_enabled
    ? strings.trackingEnabled || 'Tracking Enabled'
    : strings.trackingDisabled || 'Tracking Disabled'

  return (
    <ListItem
      boxProps={{ borderBottom: '1px solid', padding: 'large' }}
      onClick={() => editShareLink()}
      showAngleIcon
    >
      <Box alignItems="center" justifyContent="center" flexShrink={0} height={35} width={35} marginRight="large">
        <Image boxProps={{ backgroundColor: 'hoverLightGrey' }} circle width={35}>
          <FontAwesomeIcon icon={faChartBar} />
        </Image>
      </Box>

      <Box justifyContent="center" flexDirection="column">
        <Text fontSize="small">{title || strings.untitled || 'Untitled Link'}</Text>
        <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="medium">
          {`${trackingStatus} | ${formatCount('viewCount', { count: viewCount })}`}
        </Text>
      </Box>
    </ListItem>
  )
}

ShareLink.propTypes = {
  shareLink: PropTypes.object.isRequired,
  viewCount: PropTypes.number.isRequired,
}

export default ShareLink
