import { useDraggable } from '@campaignhub/react-hooks'

const adjustValueForZoom = (value, zoom) => value * zoom

const moveEnd = (coords, updateDocumentTemplatePageItem) => {
  const {
    height, width, x, y,
  } = coords

  const updatedItem = {
    height,
    width,
    x,
    y,
  }

  updateDocumentTemplatePageItem(updatedItem)
}

function useDraggablePageItem(draggableRef, options = {}){
  const {
    callbacks,
    documentTemplatePageItem: {
      height: rawHeight,
      width: rawWidth,
      x: rawX,
      y: rawY,
    },
    selected,
    zoom = 1,
  } = options || {}

  const { updateDocumentTemplatePageItem } = callbacks || {}

  const dragPosition = useDraggable(draggableRef, {
    anchorSize: 6,
    callbacks: {
      moveEnd: coords => moveEnd(coords, updateDocumentTemplatePageItem),
    },
    disabled: false,
    dragHandleClassName: 'dragHandle',
    position: {
      height: rawHeight,
      width: rawWidth,
      x: rawX,
      y: rawY,
    },
    resizable: true,
    resizableMargin: 5,
    restriction: 'parent',
    selected,
    translateElement: false,
    zoom,
  })

  const {
    height: stateHeight,
    width: stateWidth,
    x: stateX,
    y: stateY,
  } = dragPosition

  return {
    dragPosition,
    height: adjustValueForZoom(stateHeight, zoom),
    width: adjustValueForZoom(stateWidth, zoom),
    x: adjustValueForZoom(stateX, zoom),
    y: adjustValueForZoom(stateY, zoom),
  }
}

export default useDraggablePageItem
