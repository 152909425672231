import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, Button, FormField, SidebarModal,
} from '@campaignhub/suit-theme'

import EntitySelector from '@components/EntitySelector'

import useDigitalTemplate, { useDigitalTemplateForm } from '@hooks/useDigitalTemplate'
import useDigitalTemplateTypes from '@hooks/useDigitalTemplateTypes'
import useLocalization from '@hooks/useLocalization'
import useReduxAction from '@hooks/useReduxAction'

import localizedStrings from './localizedStrings'

const CreateDigitalTemplateModal = (props) => {
  const { callbacks, modalKey, showModal } = props

  useReduxAction('brands', 'loadBrands')

  const { submitAction } = callbacks
  const {
    callbacks: { createDigitalTemplate: createFn },
  } = useDigitalTemplate()

  const templateTypesPayload = useDigitalTemplateTypes()
  const { groupedTemplateTypes } = templateTypesPayload

  const {
    entityState, handlers, saveEnabled, setEntityState, creating,
  } = useDigitalTemplateForm()

  const { brand_id, digital_template_type_id, title } = entityState

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.header?.title || 'Create'}
        titleSecondLine={strings.header?.titleSecondLine || 'Template'}
      />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <EntitySelector
            boxProps={{ marginBottom: 'large' }}
            callbacks={{
              selectItem: brand => setEntityState({ brand_id: brand ? brand.id : null }),
            }}
            entityKey="brands"
            selectedItemId={brand_id}
          />

          <FormField
            direction="column"
            boxProps={{ marginBottom: 'large' }}
            label={strings.formField?.labelTemplateType || 'Template Type'}
          >
            <select name="digital_template_type_id" value={digital_template_type_id} {...handlers}>
              <option>Please Select...</option>
              {groupedTemplateTypes.map(templateType => (
                <optgroup key={templateType.id} label={templateType.title}>
                  {templateType.subtypes.map(subtype => (
                    <option key={subtype.id} value={subtype.id}>
                      {subtype.title}
                    </option>
                  ))}
                </optgroup>
              ))}
            </select>
          </FormField>

          <FormField
            direction="column"
            boxProps={{ marginBottom: 'large' }}
            label={strings.formField?.labelTitle || 'Title'}
          >
            <input name="title" value={title} type="text" {...handlers} data-validate-field-on="change" />
          </FormField>
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={creating}
          onClick={() => submitAction(entityState, createFn)}
          size="large"
        >
          {strings.button || 'Create Template'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

CreateDigitalTemplateModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

CreateDigitalTemplateModal.defaultProps = {
  modalKey: 'CreateDigitalTemplateModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <CreateDigitalTemplateModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
