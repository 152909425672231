export default {
  default: {
    header: {
      title: 'Select',
      titleSecondLine: 'Template',
    },
    formFieldLabels: {
      filter: 'Filter Templates',
    },
    title: 'proposal',
  },
}
