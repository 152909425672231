import { ModalContext } from '@campaignhub/suit-theme'
import { useModals } from '@campaignhub/react-hooks'

import CustomFieldsContext from '@contexts/customFieldsContext'

import handleCallbackAction from '@functions/handleCallbackAction'

import CreateOrEditCustomFieldModal from '@modals/CreateOrEditCustomFieldModal'

import useEntityCustomField from '@hooks/useEntityCustomField'
import useOrganizationSelector from '@hooks/useOrganizationSelector'

import PageContent from './components/PageContent'

const callbacks = (component, setState) => {
  const componentCallbacks = {
    CreateOrEditCustomFieldModal: {
      closeModal: () => setState({ showCreateOrEditCustomFieldModal: false }),
      createCustomField: payload => handleCallbackAction(payload),
      deleteCustomField: payload => handleCallbackAction(payload),
      updateCustomField: payload => handleCallbackAction(payload),
    },
  }

  return componentCallbacks[component] || {}
}

const EditCustomFieldSet = () => {
  const { selectedOrganization } = useOrganizationSelector()

  const modalContext = useModals()

  const entityCustomFieldSetPayload = useEntityCustomField({
    callbacks: {
      setupLocalCallbacks: setState => callbacks('CreateOrEditCustomFieldModal', setState),
    },
  })

  return (
    <CustomFieldsContext.Provider value={entityCustomFieldSetPayload}>
      <ModalContext.Provider value={modalContext}>
        <PageContent organization={selectedOrganization} />

        <CreateOrEditCustomFieldModal {...entityCustomFieldSetPayload} clickSafeZone />
      </ModalContext.Provider>
    </CustomFieldsContext.Provider>
  )
}

export default EditCustomFieldSet
