import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'

import groupAndSortTypes from '@functions/groupAndSortTypes'

const watchEntityKeys = [
  'digitalTemplateTypes',
]

function useDigitalTemplateTypes(options = {}){
  const { performHttpRequests } = options || {}

  const {
    updatedEntities: {
      digitalTemplateTypes: digitalTemplateTypesUpdatedAt,
    },
  } = useWatchEntityUpdates(watchEntityKeys)

  // Load Types
  useReduxAction('digitalTemplateTypes', 'loadDigitalTemplateTypes', {}, [performHttpRequests], {
    shouldPerformFn: (entityReducer) => {
      const { errors, loaded, loading } = entityReducer
      return performHttpRequests && !loaded && !loading && !errors.length
    },
  })

  const digitalTemplateTypes = useSelector(reduxState => reduxState.entities.digitalTemplateTypes)

  const groupedTemplateTypes = useMemo(() => {
    const grouped = groupAndSortTypes(digitalTemplateTypes)
    return Object.values(grouped)
  }, [digitalTemplateTypesUpdatedAt])

  return {
    entities: {
      digitalTemplateTypes,
    },
    groupedTemplateTypes,
  }
}

export default useDigitalTemplateTypes
