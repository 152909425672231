import { useContext } from 'react'

import { useSetState } from '@campaignhub/react-hooks'
import { digObject } from '@campaignhub/javascript-utils'
import {
  Button, EntityMultiSelect, FormField, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import useManageDigitalTemplatePermissionsModal from './hooks/useManageDigitalTemplatesPermissionsModal'

const MODAL_KEY = 'ManageDigitalTemplatePermissionsModal'

const defaultState = {
  string: '',
}

interface ManageDigitalTemplatePermissionsModalProps {
  callbacks:{
    bulkUpdatePermissions: () => void,
  },
  modalKey: string,
  showModal: boolean,
}

const ManageDigitalTemplatePermissionsModal = (props: ManageDigitalTemplatePermissionsModalProps) => {
  const { callbacks, modalKey = MODAL_KEY, showModal } = props
  const { bulkUpdatePermissions } = callbacks

  const [state, setState] = useSetState(defaultState)
  const { string } = state

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext
  const {
    callbacks: { bulkUpdatePermissions: bulkUpdateFn },
    digitalTemplate,
    digitalTemplate: { brand_id },
  } = digObject(modalData, modalKey, {})

  const organizationIds = digObject(digitalTemplate, 'permission_ids.organization_ids', [])

  const useManageOrganizationPermissionsModalPayload = useManageDigitalTemplatePermissionsModal({
    permittedOrganizationIds: organizationIds,
    filters: { brand_id, string },
  })
  const {
    callbacks: { loadMore, setSelectedOrganizationIds },
    canLoadMore,
    filteredOrganizationIds,
    filteredOrganizationsCount,
    hasOrganizationsToAssign,
    organizations,
    saveEnabled,
    selectedOrganizationIds,
    updating,
  } = useManageOrganizationPermissionsModalPayload

  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header callbacks={callbacks} title="Manage" titleSecondLine="Permissions" />

      <SidebarModal.Content>
        <FormField direction="column" boxProps={{ marginBottom: 'large' }} label="Search Organizations">
          <input
            name="title"
            placeholder="Filter organization by name"
            onChange={e => setState({ string: e.target.value })}
            type="text"
            value={string}
          />
        </FormField>

        {(hasOrganizationsToAssign || !!selectedOrganizationIds.length) && (
          <EntityMultiSelect
            filteredEntityIds={hasOrganizationsToAssign ? filteredOrganizationIds : []}
            boxProps={{ marginTop: 'large', maxHeight: '85%' }}
            callbacks={{
              loadMore,
              setSelectedIds: setSelectedOrganizationIds,
            }}
            canLoadMore={canLoadMore}
            entities={organizations}
            entityCount={filteredOrganizationsCount}
            selectedIds={selectedOrganizationIds}
          />
        )}
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={updating}
          onClick={() => bulkUpdatePermissions(selectedOrganizationIds, bulkUpdateFn)}
          size="large"
        >
          Save Permissions
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ManageDigitalTemplatePermissionsModalProps) => (
  <SidebarModal.RenderController {...props}>
    <ManageDigitalTemplatePermissionsModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
