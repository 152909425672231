import React from 'react'
import PropTypes from 'prop-types'

import RentalPrelistIcon from '@components/Icons/RentalPrelistIcon'
import RentalPriceUpdate from '@components/Icons/RentalPriceUpdate'
import RentalProposalIcon from '@components/Icons/RentalProposalIcon'
import SalesPrelistIcon from '@components/Icons/SalesPrelistIcon'
import SalesPriceUpdate from '@components/Icons/SalesPriceUpdate'
import SalesProposalIcon from '@components/Icons/SalesProposalIcon'

const icons = {
  rental_prelist: RentalPrelistIcon,
  rental_price_update: RentalPriceUpdate,
  rental_proposal: RentalProposalIcon,
  sales_prelist: SalesPrelistIcon,
  sales_price_update: SalesPriceUpdate,
  sales_proposal: SalesProposalIcon,
}

const DigitalPagePlaceholderImage = (props) => {
  const {
    color, digitalTemplateTypeKey, height, width,
  } = props

  const Icon = icons[digitalTemplateTypeKey]

  if (!Icon){
    return null
  }

  return <Icon fillColor={color} height={height} width={width} />
}

DigitalPagePlaceholderImage.propTypes = {
  color: PropTypes.string,
  digitalTemplateTypeKey: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default DigitalPagePlaceholderImage
