import React from 'react'
import PropTypes from 'prop-types'

import { Box, DashboardModule } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import DataPointSelector from './components/DataPointSelector'
import ProposalsAccepted from './components/ProposalsAccepted'
import ReadPercentage from './components/ReadPercentage'
import TimeOnPage from './components/TimeOnPage'

import localizedStrings from './localizedStrings'

const Averages = (props) => {
  const {
    callbacks, chartDataKey, editing, loading, selectedDataPoint,
  } = props

  const { strings } = useLocalization(localizedStrings)

  return (
    <DashboardModule
      boxProps={{ marginBottom: 0 }}
      editing={editing}
      headerRight={<DataPointSelector callbacks={callbacks} />}
      loading={loading}
      title={strings[selectedDataPoint]}
    >
      <Box flexDirection="column" padding="large">
        <ProposalsAccepted chartDataKey={chartDataKey} selectedDataPoint={selectedDataPoint} />

        <ReadPercentage chartDataKey={chartDataKey} selectedDataPoint={selectedDataPoint} />

        <TimeOnPage chartDataKey={chartDataKey} selectedDataPoint={selectedDataPoint} />
      </Box>
    </DashboardModule>
  )
}

Averages.propTypes = {
  callbacks: PropTypes.object.isRequired,
  chartDataKey: PropTypes.string.isRequired,
  editing: PropTypes.bool,
  loading: PropTypes.bool,
  selectedDataPoint: PropTypes.string.isRequired,
}

export default Averages
