/* eslint-disable max-len */
import React from 'react'
import PropTypes from 'prop-types'

import { Box, FormField, SidebarModal } from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBrowser,
  faChartLine,
  faChartWaterfall,
  faFileAlt,
  faFilm,
  faFontCase,
  faHandPointUp,
  faIcons,
  faImagePolaroid,
  faKeyboard,
  faLink,
  faMap,
  faRectangleLandscape,
  faRectangleVerticalHistory,
  faRectangleWide,
  faRepeat,
  faServer,
  faSortNumericUp,
  faTv,
  faVideo,
  faWind,
} from '@fortawesome/pro-light-svg-icons'

import ComponentLibrary from './components/ComponentLibrary'
import PageItem from './components/PageItem'

const AddDigitalTemplatePageItemModal = (props) => {
  const { callbacks, modalKey, showModal } = props

  return (
    <SidebarModal
      callbacks={callbacks}
      disableAnimation
      disableOverlay
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header callbacks={callbacks} title="Add" titleSecondLine="Item" />

      <SidebarModal.Content hasFooter={false}>
        <ComponentLibrary />

        <FormField direction="column" label="Basic">
          <Box flexDirection="row" flexWrap="wrap">
            <PageItem title="Text" itemType="text" icon={<FontAwesomeIcon icon={faFontCase} />} />
            <PageItem title="Image" itemType="image" icon={<FontAwesomeIcon icon={faImagePolaroid} />} last />
            <PageItem title="Button" itemType="button" icon={<FontAwesomeIcon icon={faHandPointUp} />} />
            <PageItem title="Container" itemType="container" icon={<FontAwesomeIcon icon={faRectangleWide} />} last />
            <PageItem title="Video" itemType="video" icon={<FontAwesomeIcon icon={faVideo} />} />
            <PageItem title="Section" itemType="section" icon={<FontAwesomeIcon icon={faRectangleLandscape} />} last />
            <PageItem title="Counter" itemType="counter" icon={<FontAwesomeIcon icon={faSortNumericUp} />} />
            <PageItem title="Carousel" itemType="carousel" icon={<FontAwesomeIcon icon={faFilm} />} last />
            <PageItem title="HashLink" itemType="hashLink" icon={<FontAwesomeIcon icon={faLink} />} />
            <PageItem title="IFrame" itemType="iframe" icon={<FontAwesomeIcon icon={faBrowser} />} last />
            <PageItem title="Icon" itemType="icon" icon={<FontAwesomeIcon icon={faIcons} />} />
            <PageItem title="Animation" itemType="animation" icon={<FontAwesomeIcon icon={faWind} />} last />
            <PageItem title="Map" itemType="map" icon={<FontAwesomeIcon icon={faMap} />} />
          </Box>
        </FormField>

        <FormField direction="column" label="Advanced" marginTop="medium">
          <Box flexDirection="row" flexWrap="wrap">
            <PageItem title="Data Loop" itemType="dataArrayLoop" icon={<FontAwesomeIcon icon={faRepeat} />} />
            <PageItem title="Input" itemType="input" icon={<FontAwesomeIcon icon={faKeyboard} />} last />
            <PageItem title="Form Template" itemType="formTemplate" icon={<FontAwesomeIcon icon={faFileAlt} />} />
            <PageItem title="Suburb Data Chart" itemType="chart" icon={<FontAwesomeIcon icon={faChartLine} />} last />
            <PageItem title="State" itemType="state" icon={<FontAwesomeIcon icon={faServer} />} />
            <PageItem title="Media Container" itemType="mediaContainer" last icon={<FontAwesomeIcon icon={faTv} />} />
            <PageItem title="Image Carousel" itemType="imageCarousel" icon={<FontAwesomeIcon icon={faRectangleVerticalHistory} />} />
            <PageItem title="Advanced Chart" itemType="advancedChart" icon={<FontAwesomeIcon icon={faChartWaterfall} />} last />
          </Box>
        </FormField>
      </SidebarModal.Content>
    </SidebarModal>
  )
}

AddDigitalTemplatePageItemModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

AddDigitalTemplatePageItemModal.defaultProps = {
  modalKey: 'AddDigitalTemplatePageItemModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <AddDigitalTemplatePageItemModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
