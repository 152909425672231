import { digObject } from '@campaignhub/javascript-utils'

import type { ComponentStyleModel } from '@models/componentStyle'

function findComponentStyle(componentStyles: ComponentStyleModel[], componentStyleId: string) {
  if (!componentStyles || !componentStyleId){
    return {}
  }

  const componentStyle = componentStyles.find((style) => {
    if (typeof style.id === 'number'){
      return style.id === Number(componentStyleId)
    }

    return style.id === componentStyleId
  })

  const style = digObject(componentStyle, 'data.style') || componentStyle

  return style || {}
}

export default findComponentStyle
