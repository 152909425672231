import { isValidEmail, isValidMobile } from '@campaignhub/javascript-utils'

export type UserModel = {
  cache_key: number,
  id: number,
  country_calling_code: string,
  dates: {
    created: {
      date_time_with_timezone: string,
    },
    updated: {
      date_time_with_timezone: string,
    },
  },
  default_image_id?: number,
  default_organization_id?: number,
  email: string,
  first_name: string,
  full_name: string,
  hidden: boolean,
  job_title: string,
  last_name: string,
  legal_name: string,
  mobile: string,
  options: {},
  organization_ids?: number[],
  organizations_user_ids?: number[],
  realbase_id: number,
  realbase_type?: string,
  role_id: number,
  support_token_valid: boolean,
  support_token: string,
  time_zone: string,
}

export type UserRequestOptions = {
  include_user_role?: boolean,
  include_user_default_image?: boolean,
  include_user_tagged_images?: boolean,
  include_user_images?: boolean,
  include_user_impersonated_user?: boolean,
  include_user_organization_ids?: boolean,
  include_user_organizations_where_admin_ids?: boolean,
  include_user_organizations_where_admin_and_owner_ids?: boolean,
  include_user_organizations_where_user_ids?: boolean,
  include_user_organizations_where_owner_ids?: boolean,
  include_user_brand_ids?: boolean,
  include_user_default_brand?: boolean,
  include_user_brands_where_admin_ids?: boolean,
  include_user_reviews?: boolean,
  include_user_default_organization_id?: boolean,
  include_user_default_organization?: boolean,
  include_user_data_store_items?: boolean,
  include_user_organizations?: boolean,
  include_user_organizations_users?: boolean,
  include_user_onboard_status?: boolean,
  include_user_awards?: boolean,
}

const state = {
  country_calling_code: '',
  email: '',
  first_name: '',
  id: null,
  job_title: '',
  last_name: '',
  legal_name: '',
  mobile: '',
  options: {},
  realbase_id: null,
  realbase_type: null,
  role_id: null,
  time_zone: '',
}

export const requiredFields = [
  { key: 'country_calling_code' },
  { key: 'email', validation: isValidEmail, invalidMessage: 'Invalid Email format' },
  { key: 'first_name' },
  { key: 'last_name' },
  { key: 'mobile', validation: isValidMobile, invalidMessage: 'Invalid Mobile format' },
  { key: 'role_id' },
]

export default state
