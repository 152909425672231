import React from 'react'
import PropTypes from 'prop-types'

import AssetLibrary from './AssetLibrary'
import Project from './Project'

const components = {
  AssetLibrary,
  Project,
}

const FileLibrarySelector = (props) => {
  const {
    fileSelectPayload,
    fileSelectPayload: { selectedEntityType },
    project,
  } = props

  const Component = components[selectedEntityType]
  if (!Component){
    return null
  }

  return <Component fileSelectPayload={fileSelectPayload} project={project} />
}

FileLibrarySelector.propTypes = {
  fileSelectPayload: PropTypes.shape({
    selectedEntityType: PropTypes.string.isRequired,
  }).isRequired,
  project: PropTypes.object.isRequired,
}

export default FileLibrarySelector
