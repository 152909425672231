import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/pro-light-svg-icons'

import {
  Box, ListItem, Text,
} from '@campaignhub/suit-theme'

import useIntegration from '@hooks/useIntegration'

const Integration = (props) => {
  const { integration } = props

  const { callbacks: { createOrEditIntegration }, ownerTitle } = useIntegration(integration)

  return (
    <ListItem
      boxProps={{
        as: 'a',
        alignItems: 'center',
        borderBottom: '1px dashed',
        padding: 'large',
      }}
      onClick={createOrEditIntegration}
      showAngleIcon
    >
      <Box flexDirection="column">
        <Text color="bodyFontLightColor" fontSize="small" lineHeight="1.3">
          <FontAwesomeIcon icon={faLink} /> {ownerTitle}
        </Text>
      </Box>
    </ListItem>
  )
}

Integration.propTypes = {
  integration: PropTypes.object.isRequired,
}

export default Integration
