export default {
  default: {
    title: {
      title: 'Projects',
    },
    labelTitle: {
      label: 'Title',
    },
    displayMode: {
      label: 'Display Mode',
    },
    displayModeValues: {
      labelListCompact: 'List (Compact)',
      labelListLarge: 'List (Large)',
    },
    limit: {
      label: 'Limit',
    },
    blankState: {
      title: 'No Projects to Display',
      paragraph: 'You either have no projects or the filters defined returned no results. Create a new project or change your filters.',
    },
    manage: 'Manage',

  },
}
