import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, Button, FormField, SidebarModal,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const CreateNew = (props) => {
  const {
    callbacks: { createDocumentTemplate },
    documentTemplateFormPayload,
  } = props

  const {
    creating,
    entityState: {
      title,
    },
    handlers,
    saveEnabled,
  } = documentTemplateFormPayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <FormField direction="column" label={strings.documentFieldLabels?.title || '* Title'}>
            <input name="title" type="text" value={title} {...handlers} />
          </FormField>
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={creating}
          onClick={createDocumentTemplate}
          size="large"
        >
          {strings.buttons?.createTemplate || 'Create Template'}
        </Button>
      </SidebarModal.Footer>
    </>
  )
}

CreateNew.propTypes = {
  callbacks: PropTypes.shape({
    createDocumentTemplate: PropTypes.func.isRequired,
  }).isRequired,
  documentTemplateFormPayload: PropTypes.object.isRequired,
}

export default CreateNew
