import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfo, faPlay } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, DashboardModule, Text,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import getVideoUrlId from '@functions/getVideoUrlId'

import VideoPlayer from '@components/digitalRenderer/components/VideoPlayer'

import VideoModuleBox from './components/VideoModuleBox'

import useVideoModule from './hooks/useVideoModule'

import localizedStrings from './localizedStrings'

const VideoModule = (props) => {
  const { learnMoreUrl, marginTop, videosPayload } = props

  const videoModulePayload = useVideoModule(videosPayload)
  const {
    callbacks: { toggleVideoModule },
    shouldShowFooter,
    state: { videoModuleUrl, videoParagraph, videoTitle },
  } = videoModulePayload

  const { videoId, videoProvider } = getVideoUrlId(videoModuleUrl)

  const { strings } = useLocalization(localizedStrings)

  return (
    <DashboardModule boxProps={{ marginBottom: 0, marginTop }} title={strings.title || 'Training & Tips'}>
      <Box flexDirection="column">
        <Box flexDirection="column" padding="large">
          <Box height={179} overflow="hidden" borderRadius={5} marginBottom="large">
            <VideoPlayer
              videoData={{
                videoId,
                videoProvider,
              }}
            />
          </Box>

          <Box flexDirection="column">
            <Text fontSize="medium" lineHeight="1.3">
              {videoTitle}
            </Text>

            {!!videoParagraph && (
              <Text fontSize="xsmall" marginTop="medium" lineHeight="1.3" color="bodyFontLightColor">
                {videoParagraph}
              </Text>
            )}
          </Box>
        </Box>

        {shouldShowFooter
          && videosPayload.map(videoPayload => (
            <VideoModuleBox toggleVideoModule={toggleVideoModule} videoPayload={videoPayload} />
          ))}

        <Box
          alignItems="center"
          backgroundColor="white"
          borderTop="1px solid"
          borderColor="lineColor"
          bottom="0"
          flexDirection="row"
          height="80px"
          padding="large"
        >
          <Button
            as="a"
            buttonStyle="primaryEdit"
            href={videoModuleUrl}
            icon={<FontAwesomeIcon icon={faPlay} />}
            marginRight="small"
            size="medium"
            style={{ width: 'calc(50% - 4px)' }}
            target="_blank"
          >
            {strings.watchNow || 'Watch Now'}
          </Button>

          <Button
            as="a"
            buttonStyle="secondaryUtility"
            href={learnMoreUrl}
            icon={<FontAwesomeIcon icon={faInfo} />}
            size="medium"
            style={{ marginLeft: 4, width: 'calc(50% - 4px)' }}
            target="_blank"
          >
            {strings.learnMore || 'Learn More'}
          </Button>
        </Box>
      </Box>
    </DashboardModule>
  )
}

VideoModule.propTypes = {
  learnMoreUrl: PropTypes.string,
  videosPayload: PropTypes.array.isRequired,
  marginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

VideoModule.defaultProps = {
  learnMoreUrl: 'https://youtube.com/playlist?list=PLO2Gu1JTh6FmGd0O9rn1bZaNeo5Fk-W4m',
}

export default VideoModule
