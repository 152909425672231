import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList } from '@fortawesome/pro-solid-svg-icons'

import {
  Box, Button, FormField, SidebarModal,
} from '@campaignhub/suit-theme'

import useAddress from '@hooks/useAddress'
import useEntityCustomFieldsForm from '@hooks/useEntityCustomFieldsForm'
import useLocalization from '@hooks/useLocalization'
import useProjectTypes from '@hooks/useProjectTypes'

import EntityCustomFields from '@components/EntityCustomFields'
import EntitySelectBox from '@components/EntitySelectBox'
import FilterUsersContactsAndTeams from '@components/FilterUsersContactsAndTeams'
import ModalAddress from '@components/ModalComponents/ModalAddress'
import ModalTeam from '@components/ModalComponents/ModalTeam'
import ModalUsers from '@components/ModalComponents/ModalUsers'

import localizedStrings from './localizedStrings'

const NewProject = (props) => {
  const {
    callbacks: { showImportScreen, submitAction },
    integrationsPayload: { hasIntegrations },
    organizationPayload,
    projectFormPayload,
    projectPayload,
  } = props

  const { hasMultipleOrganizations, selectedOrganization } = organizationPayload

  const {
    callbacks: {
      addProjectUserOrTeam, createProject: createFn, deleteProjectTeam, deleteProjectUser,
    },
    creating,
  } = projectPayload

  const {
    entityState,
    entityState: {
      address_attributes, lead_user_ids, organization_id, project_type_id, team_ids,
    },
    errors,
    handlers,
    saveEnabled,
    setEntityState,
  } = projectFormPayload

  const { groupedProjectTypes } = useProjectTypes()

  const {
    callbacks: { createOrEditAddress },
  } = useAddress(address_attributes)

  const customFieldsForm = useEntityCustomFieldsForm(
    { type: 'Project' },
    selectedOrganization,
    { performHttpRequests: true, requiredOnly: true },
  )

  const {
    callbacks: { buildNestedValues },
    saveEnabled: customFieldsSaveEnabled,
  } = customFieldsForm

  const hasAddressAttributes = !!Object.keys(address_attributes).length

  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <Box flexDirection="column">
            {hasAddressAttributes && (
              <ModalAddress address={address_attributes} callbacks={{ editAddress: () => createOrEditAddress() }} />
            )}
          </Box>

          {hasAddressAttributes && (
            <>
              <SidebarModal.Separator />

              {hasMultipleOrganizations && (
                <FormField
                  direction="column"
                  boxProps={{ marginTop: 'large' }}
                  label={strings.formFieldLabels?.organization || '* Organisation'}
                  errorMessage={errors.organization_id}
                >
                  <EntitySelectBox
                    entityKey="organizations"
                    name="organization_id"
                    sortDirection="asc"
                    sortKey="title"
                    value={organization_id}
                    {...handlers}
                  />
                </FormField>
              )}

              <FormField
                direction="column"
                boxProps={{ marginTop: 'large' }}
                label={strings.formFieldLabels?.projectType || '* Project Type'}
                errorMessage={errors.project_type_id}
              >
                <select name="project_type_id" value={project_type_id} {...handlers}>
                  {groupedProjectTypes.map(projectType => (
                    <optgroup key={projectType.id} label={projectType.title}>
                      {projectType.subtypes.map(subtype => (
                        <option key={subtype.id} value={subtype.id}>
                          {subtype.title}
                        </option>
                      ))}
                    </optgroup>
                  ))}
                </select>
              </FormField>

              <EntityCustomFields customFieldsForm={customFieldsForm} sidebar />

              <SidebarModal.Separator boxProps={{ marginTop: 'large' }} />

              <FormField
                direction="column"
                boxProps={{ marginTop: 'large' }}
                label={strings.formFieldLabels?.searchLabel || '* Search Users and Teams'}
              >
                <FilterUsersContactsAndTeams
                  callbacks={{
                    selectItem: selected => addProjectUserOrTeam(selected, 'lead_user_ids', entityState, setEntityState),
                  }}
                  selectedTeamIds={team_ids}
                  selectedUserIds={lead_user_ids}
                  shouldSearchTeams
                />
              </FormField>

              {!!lead_user_ids.length && (
                <FormField
                  direction="column"
                  boxProps={{ marginTop: 'large' }}
                  label={strings.formFieldLabels?.usersLabel || 'Users'}
                >
                  <ModalUsers
                    callbacks={{
                      deleteUser: userId => deleteProjectUser(userId, 'lead_user_ids', entityState, setEntityState),
                    }}
                    userIds={lead_user_ids}
                  />
                </FormField>
              )}

              {!!team_ids.length && (
                <FormField
                  direction="column"
                  boxProps={{ marginTop: 'large' }}
                  label={strings.formFieldLabels?.teamLabel || 'Teams'}
                >
                  {team_ids.map(team_id => (
                    <ModalTeam
                      callbacks={{ deleteTeam: team => deleteProjectTeam(team.id, entityState, setEntityState) }}
                      key={team_id}
                      teamId={team_id}
                    />
                  ))}
                </FormField>
              )}
            </>
          )}

          {hasIntegrations && (
            <FormField direction="column" label={strings.moreOptions || 'More Options'} marginTop="large">
              <Box flexDirection="column">
                <Button
                  buttonStyle="secondaryUtility"
                  icon={<FontAwesomeIcon icon={faList} />}
                  marginBottom="large"
                  onClick={showImportScreen}
                  size="medium"
                  width="100%"
                >
                  {strings.importButton || 'Import Project'}
                </Button>
              </Box>
            </FormField>
          )}
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={
            !customFieldsSaveEnabled
            || !(team_ids.length || lead_user_ids.length)
            || !hasAddressAttributes
            || !saveEnabled
          }
          loading={creating}
          onClick={() => submitAction(entityState, buildNestedValues(), createFn)}
          size="large"
        >
          {strings.createButton || 'Create'}
        </Button>
      </SidebarModal.Footer>
    </>
  )
}

NewProject.propTypes = {
  callbacks: PropTypes.object.isRequired,
  integrationsPayload: PropTypes.shape({
    hasIntegrations: PropTypes.bool,
  }).isRequired,
  organizationPayload: PropTypes.object.isRequired,
  projectFormPayload: PropTypes.object.isRequired,
  projectPayload: PropTypes.object.isRequired,
}

export default NewProject
