import { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

import {
  faArchive, faHome, faPuzzlePiece, faUser,
} from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Columns, LoadingModule, PageHeader,
} from '@campaignhub/suit-theme'

import { getQueryParamsFromHash, parsePermittedQueryParams, sortArrayBy } from '@campaignhub/javascript-utils'

import PageContext from '@contexts/pageContext'

import PageFilterModule from '@components/PageFilterModule'

import useCurrentUser from '@hooks/useCurrentUser'
import useDigitalTemplates from '@hooks/useDigitalTemplates'
import useIntercom from '@hooks/useIntercom'
import useMixpanel from '@hooks/useMixpanel'

import defaultRequestOptions from '@sections/Admin/packs/DigitalTemplateBuilder/defaultRequestOptions'

import TemplateListBlankState from './components/TemplateListBlankState'
import TemplateList from '../TemplateList'

const getActiveTabBarKey = (currentStatus, ownerType, isTemplatePartial) => {
  if (isTemplatePartial) return 'partials'

  const ownerKey = ownerType === 'Organization' ? 'client' : 'system'
  if (currentStatus === 'current') return `current-${ownerKey}`
  if (currentStatus === 'archived') return `archived-${ownerKey}`
  return currentStatus
}

const buildPageFilterFields = (entities) => {
  const { digitalTemplateTypes } = entities || {}

  const boxProps = {
    marginBottom: 'large',
  }

  const style = {
    marginBottom: 16,
  }

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        style,
      },
      key: 'string',
      label: 'Filter by Title or ID',
    },
    {
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: 'All',
        inputComponent: 'select',
        style,
      },
      key: 'digital_template_sub_type_key',
      label: 'Template Type',
      values: sortArrayBy(Object.values(digitalTemplateTypes), 'asc', 'title')
        .filter(type => !!type.parent_id) // Only show child types i.e. ones with parents
        .map(type => ({
          key: type.key,
          label: type.title,
        })),
    },
    {
      component: 'EntitySelector',
      componentProps: {
        boxProps,
        entityKey: 'brands',
      },
      key: 'brand_id',
    },
    {
      callbacks: {
        buildSearchRequestOptions: state => ({ brand_id: state.brand_id }),
        shouldRender: state => !!state.brand_id,
      },
      component: 'EntitySelector',
      componentProps: {
        boxProps,
        entityKey: 'organizations',
      },
      dependentKey: 'brand_id',
      key: 'organization_id',
    },
    {
      entityKey: 'limit',
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: '100',
        inputComponent: 'select',
        style,
      },
      key: 'limit',
      label: 'Limit',
      values: [
        { key: 150, label: '150' },
        { key: 200, label: '200' },
        { key: 300, label: '300' },
      ],
    },
    {
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: 'All',
        inputComponent: 'select',
        style,
      },
      key: 'production_ready',
      label: 'Production Status',
      values: [
        { key: true, label: 'Approved' },
        { key: false, label: 'Draft' },
      ],
    },
  ]
}

const PageContent = (props) => {
  const { pageFilters } = props

  // useLocation to refresh the window.location. If not used location won't reload if URL changes
  useLocation()

  const { ownerType, status, templatePartial } = parsePermittedQueryParams(getQueryParamsFromHash(), [
    'status',
    'templatePartial',
    'ownerType',
  ])
  const currentStatus = status || 'current'
  const templateOwnerType = ownerType || 'System'
  const isTemplatePartial = templatePartial === 'true'

  const activeTabBarItemKey = getActiveTabBarKey(currentStatus, templateOwnerType, isTemplatePartial)

  const digitalTemplatesPayload = useDigitalTemplates({
    ...defaultRequestOptions.digitalTemplate,
    ...defaultRequestOptions.image,
    entityKey: {},
    filters: {
      ...pageFilters,
      owner_type: templateOwnerType,
      template_partial: isTemplatePartial,
    },
    performHttpRequests: true,
    status: currentStatus,
  })

  const { hasDigitalTemplates, loading } = digitalTemplatesPayload

  const pageContext = useContext(PageContext)
  const {
    callbacks: { resetFilters, showCreateDigitalTemplateModal, updateFilters },
  } = pageContext

  const { entities } = useSelector(reduxState => reduxState)

  const { isBrandUser } = useCurrentUser()

  useIntercom({ hideWidget: !isBrandUser })

  // Tracks the page load event
  const { callbacks: { dispatchMixpanelEvent } } = useMixpanel()
  useEffect(() => {
    if (isBrandUser) dispatchMixpanelEvent('Digital Templates Load')
  }, [])

  return (
    <>
      <PageHeader
        actionContent={(
          <Button buttonStyle="primaryCreate" onClick={showCreateDigitalTemplateModal} size="medium">
            Create
          </Button>
        )}
        activeTabBarItemKey={activeTabBarItemKey}
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        tabBarItems={[
          {
            href: '#/digitalTemplates/template-list?status=current',
            icon: faHome,
            key: 'current-system',
            title: 'Templates (System)',
          },
          {
            href: '#/digitalTemplates/template-list?status=current&ownerType=Organization',
            icon: faUser,
            key: 'current-client',
            title: 'Templates (Client)',
          },
          {
            href: '#/digitalTemplates/template-list?status=current&templatePartial=true',
            icon: faPuzzlePiece,
            key: 'partials',
            title: 'Partials',
          },
          {
            href: '#/digitalTemplates/template-list?status=archived&ownerType=Organization',
            icon: faUser,
            key: 'archived-client',
            title: 'Archived (Client)',
          },
          {
            href: '#/digitalTemplates/template-list?status=archived',
            icon: faArchive,
            key: 'archived-system',
            title: 'Archived (System)',
          },
        ]}
        title="Digital Templates"
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && hasDigitalTemplates && <TemplateList digitalTemplatesPayload={digitalTemplatesPayload} />}
            {!loading && !hasDigitalTemplates && <TemplateListBlankState />}
          </Columns.Main>

          <Columns.Sidebar>
            <PageFilterModule
              callbacks={{ resetFilters, updateFilters }}
              filterFields={buildPageFilterFields(entities)}
              pageFilters={pageFilters}
              title="Filters"
            />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

PageContent.propTypes = {
  pageFilters: PropTypes.object.isRequired,
}

export default PageContent
