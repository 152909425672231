import { useContext } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { faHome } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Columns, DashboardModule, LoadingModule, PageHeader,
} from '@campaignhub/suit-theme'

import { sortArrayBy } from '@campaignhub/javascript-utils'

import PageContext from '@contexts/pageContext'

import useCurrentUser from '@hooks/useCurrentUser'
import useLocalization from '@hooks/useLocalization'
import useTeam from '@hooks/useTeam'
import useTeams from '@hooks/useTeams'
import useVideoModules from '@hooks/useVideoModules'

import PageFilterModule from '@components/PageFilterModule'
import VideoModule from '@components/VideoModule'

import localizedStrings from './localizedStrings'

import Team from '../Team'
import TeamsBlankState from '../TeamsBlankState'

const buildPageFilterFields = (entities, isCurrentUserAdminForOrganization, strings) => {
  const style = {
    marginBottom: 16,
  }
  const { users } = entities
  const usersArray = Object.values(users)

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        style,
      },
      key: 'string',
      label: strings.titleLabel || 'Filter by Title',
    },
    {
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: strings.defaultSelectLabel || 'All',
        inputComponent: 'select',
        style,
      },
      hidden: !isCurrentUserAdminForOrganization,
      key: 'user_id',
      label: strings.userLabel || 'User',
      values: sortArrayBy(usersArray, 'asc', 'full_name').map(user => ({
        key: user.id,
        label: user.full_name,
      })),
    },
  ]
}

const PageContent = (props) => {
  const { isCurrentUserAdminForOrganization, pageFilters, selectedOrganization } = props

  const { currentUser } = useCurrentUser()
  const { entities } = useSelector(reduxState => reduxState)

  const filterOptions = {
    owner_id: selectedOrganization.id,
    owner_type: 'Organization',
    ...pageFilters,
    user_id: isCurrentUserAdminForOrganization ? pageFilters.user_id : currentUser.id,
  }

  const { filteredTeams, hasTeams, loading } = useTeams({ filters: filterOptions })

  const {
    callbacks: { resetFilters, updateFilters },
  } = useContext(PageContext)

  const initTeam = { owner_id: selectedOrganization.id, owner_type: 'Organization' }
  if (!isCurrentUserAdminForOrganization) initTeam.lead_user_ids = [currentUser.id]

  const {
    callbacks: { createOrEditTeam },
    urls: { teamsIndexUrl },
  } = useTeam(initTeam)

  const videoModulePayload = useVideoModules({ key: 'teams' })
  const { videosPayload } = videoModulePayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      <PageHeader
        activeTabBarItemKey="teams"
        actionContent={(
          <Button buttonStyle="primaryCreate" onClick={() => createOrEditTeam()} size="medium">
            {strings.create || 'Create'}
          </Button>
        )}
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        nestedNavigation
        tabBarItems={[
          {
            href: teamsIndexUrl,
            icon: faHome,
            key: 'teams',
            title: strings.title || 'Teams',
          },
        ]}
        title={strings.title || 'Teams'}
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && hasTeams && (
              <DashboardModule title={strings.title || 'Teams'}>
                <Box flexDirection="column">
                  <Box flexDirection="column">
                    {filteredTeams.map(team => (
                      <Team key={team.id} team={team} />
                    ))}
                  </Box>

                  <DashboardModule.Footer>
                    <DashboardModule.FooterRight>
                      <Button
                        buttonStyle="secondaryEdit"
                        onClick={() => createOrEditTeam()}
                        size="medium"
                        style={{ width: '100%' }}
                      >
                        {strings.addTeam || 'Add Team'}
                      </Button>
                    </DashboardModule.FooterRight>
                  </DashboardModule.Footer>
                </Box>
              </DashboardModule>
            )}
            {!loading && !hasTeams && <TeamsBlankState />}
          </Columns.Main>

          <Columns.Sidebar>
            <PageFilterModule
              callbacks={{ resetFilters, updateFilters }}
              filterFields={buildPageFilterFields(entities, isCurrentUserAdminForOrganization, strings)}
              pageFilters={pageFilters}
              title={strings.filters || 'Filters'}
            />

            <VideoModule videosPayload={videosPayload} />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

PageContent.propTypes = {
  isCurrentUserAdminForOrganization: PropTypes.bool.isRequired,
  pageFilters: PropTypes.object.isRequired,
  selectedOrganization: PropTypes.object.isRequired,
}

export default PageContent
