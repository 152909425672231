import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useLatestEntity, useThunkDispatch, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import * as digitalTemplatePageActions from '@redux/modules/digitalTemplatePage'

const watchEntityKeys = [
  'digitalTemplatePageSectionGroups',
  'digitalTemplatePages',
]

export const copySectionToSectionGroup = (digitalTemplatePage, sectionGroup, section, index, dispatch) => {
  const { copySectionToSectionGroup: copyFn } = digitalTemplatePageActions

  const requestOptions = {
    include_digital_template_page_section_groups: true,
  }

  return dispatch(copyFn(digitalTemplatePage, { index, sectionGroupId: sectionGroup.id, section }, requestOptions))
}

function useDigitalTemplatePageSection(initSection){
  const { entity: section } = useLatestEntity(initSection, 'digitalTemplatePageSections')
  const { id, options } = section

  const dispatch = useThunkDispatch()

  const entities = useSelector(reduxState => reduxState.entities)
  const { digitalTemplatePages, digitalTemplatePageSectionGroups, digitalTemplatePageItems } = entities

  const {
    updatedEntities: {
      digitalTemplatePageSectionGroups: digitalTemplatePageSectionGroupsUpdatedAt,
      digitalTemplatePages: digitalTemplatePagesUpdatedAt,
    },
  } = useWatchEntityUpdates(watchEntityKeys)

  const editorEntity = { type: 'digitalTemplatePageSection', id }

  // Section Group
  const sectionGroup = useMemo(() => {
    const array = Object.values(digitalTemplatePageSectionGroups)
    const group = array.find(({ sections }) => {
      const sectionIds = sections || []
      return sectionIds.includes(id)
    })

    return group || {}
  }, [digitalTemplatePageSectionGroupsUpdatedAt, id])

  // Template Page
  const digitalTemplatePage = useMemo(() => {
    const array = Object.values(digitalTemplatePages)
    const page = array.find(({ section_groups }) => {
      const sectionGroupIds = section_groups || []
      return sectionGroupIds.includes(sectionGroup.id)
    })

    return page || {}
  }, [digitalTemplatePagesUpdatedAt, sectionGroup.id])

  return {
    callbacks: {
      copySectionToSectionGroup: (selectedSectionGroup, selectedSection, index) => copySectionToSectionGroup(digitalTemplatePage, selectedSectionGroup, selectedSection, index, dispatch),
    },
    digitalTemplatePage,
    digitalTemplatePageItems,
    digitalTemplatePageSection: section,
    editorEntity,
    items: section.items || [],
    options: options || {},
    sectionGroup,
  }
}

export default useDigitalTemplatePageSection
