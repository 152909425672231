import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList } from '@fortawesome/pro-solid-svg-icons'

import {
  Box, Button, FormField, Image, SelectBox, SidebarModal,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useLocalization from '@hooks/useLocalization'

import EntitySelectBox from '@components/EntitySelectBox'
import ModalProject from '@components/ModalComponents/ModalProject'
import Search from '@components/Search'

import useImportProject from './hooks/useImportProject'

import localizedStrings from './localizedStrings'
import placeholderImageUrl from './assets/default_campaign_image_400x400.png'

const buildRequestOptions = (saleMethodRequired, organization_id, state) => {
  const { saleMethod } = state
  const requestOptions = {}

  if (organization_id) requestOptions.organization_id = organization_id
  if (saleMethodRequired) requestOptions.sale_method = saleMethod

  return requestOptions
}

const mapProjectResults = ({ data: projects }, strings) => ({
  groups: [
    {
      key: 'projects',
      title: strings.projectsTitle || 'PROJECTS',
      items: Object.values(projects)?.map((project) => {
        const imageUrl = digObject(project, 'image.thumb')

        return {
          iconLeft: (
            <Box alignItems="center" width={35}>
              <Image circle width={35} url={imageUrl} placeholderUrl={placeholderImageUrl} />
            </Box>
          ),
          id: project.id,
          key: project.id,
          secondaryText: `External ID: ${project.id}`,
          text: project.address,
        }
      }),
    },
  ],
})

const ImportProject = (props) => {
  const {
    callbacks: { createOrEditAddress, importProject },
    integrationsPayload,
    organizationPayload: { hasMultipleOrganizations },
    projectFormPayload,
    projectPayload,
  } = props

  const {
    callbacks: { importProject: importFn },
    creating,
  } = projectPayload

  const {
    entityState: { organization_id },
    errors,
    handlers,
  } = projectFormPayload

  const {
    filteredPlatforms,
    hasIntegrations,
  } = integrationsPayload

  const importPayload = useImportProject(props)

  const {
    callbacks: { setState },
    saleMethodRequired,
    state,
    state: { saleMethod, selectedIntegrationPlatformKey, selectedProject },
  } = importPayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      <SidebarModal.Content>
        {hasIntegrations && (
          <Box flexDirection="column" flexShrink={0}>
            <Box flexDirection="column">
              {hasMultipleOrganizations && (
                <FormField
                  direction="column"
                  boxProps={{ marginBottom: 'large' }}
                  label={strings.formFieldLabels.organization || '* Organization'}
                  errorMessage={errors.organization_id}
                >
                  <EntitySelectBox
                    entityKey="organizations"
                    name="organization_id"
                    sortDirection="asc"
                    sortKey="title"
                    value={organization_id}
                    {...handlers}
                  />
                </FormField>
              )}

              <FormField
                direction="column"
                boxProps={{ marginBottom: 'large' }}
                label={strings.formFieldLabels?.platform || 'Platform'}
              >
                <SelectBox
                  callbacks={{
                    selectItem: platform => setState({ selectedIntegrationPlatformKey: platform.key }),
                  }}
                  items={filteredPlatforms}
                  selectedItemKey={selectedIntegrationPlatformKey}
                  style={{ zIndex: 3 }}
                  ulStyle={{ maxHeight: 300, overflow: 'auto' }}
                />
              </FormField>

              {saleMethodRequired && (
                <FormField
                  boxProps={{ marginBottom: 'large' }}
                  direction="column"
                  label={strings.formFieldLabels?.saleMethod || '* Sale or Lease'}
                >
                  <select value={saleMethod} onChange={e => setState({ saleMethod: e.target.value })}>
                    <option value="sale">{strings.sale || 'Sale'}</option>
                    <option value="lease">{strings.lease || 'Lease'}</option>
                  </select>
                </FormField>
              )}

              {!!selectedIntegrationPlatformKey && (
                <FormField
                  direction="column"
                  boxProps={{ marginBottom: 'large' }}
                  label={strings.formFieldLabels?.findProject || 'Find a Project'}
                >
                  <Search
                    callbacks={{
                      selectItem: selected => setState({ selectedProject: selected }),
                      mapResults: data => mapProjectResults(data, strings),
                    }}
                    debounceInterval={800}
                    endpoint={`projects/fetch_from_external_platform/${selectedIntegrationPlatformKey}.json`}
                    placeholder={strings.placeholderSearch || 'Search by address'}
                    requestOptions={buildRequestOptions(saleMethodRequired, organization_id, state)}
                    forwardProps={{ showOnFocus: true }}
                  />
                </FormField>
              )}
            </Box>

            {!!selectedProject.id && (
              <ModalProject
                callbacks={{ removeProject: () => setState({ selectedProject: {} }) }}
                project={selectedProject}
              />
            )}
          </Box>
        )}

        {!selectedProject.id && (
          <Button
            buttonStyle="ghostEdit"
            icon={<FontAwesomeIcon icon={faList} />}
            marginBottom="large"
            onClick={createOrEditAddress}
            size="medium"
            width="100%"
          >
            {strings.createAddressButton || 'Enter Address Manually'}
          </Button>
        )}
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!selectedProject.id}
          loading={creating}
          onClick={() => importProject(
            selectedProject.id,
            selectedIntegrationPlatformKey,
            organization_id,
            importFn,
            buildRequestOptions(saleMethodRequired, null, state),
          )}
          size="large"
        >
          {strings.importProjectButton || 'Import Project'}
        </Button>
      </SidebarModal.Footer>
    </>
  )
}

ImportProject.propTypes = {
  callbacks: PropTypes.object,
  integrationsPayload: PropTypes.object,
  modalPayload: PropTypes.object.isRequired,
  organizationPayload: PropTypes.object,
  projectFormPayload: PropTypes.object,
  projectPayload: PropTypes.object,
}

export default ImportProject
