import { useEffect } from 'react'

import {
  Box, Button, DynamicInput, Form, SelectBox, SidebarModal,
} from '@campaignhub/suit-theme'

import { deepSetObject } from '@campaignhub/javascript-utils'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import useExternalDocumentTemplates from '@hooks/useExternalDocumentTemplates'
import useIntegrationPlatform from '@hooks/useIntegrationPlatform'

import type { AgreementModel } from '@models/agreement'
import useExternalForm from './hooks/useExternalForm'

import DocumentTemplates from './components/DocumentTemplates'
import type { DocumentTemplate } from './components/DocumentTemplates'

const MODAL_KEY = 'CreateExternalFormModal'

type ModalCallbacks = {
  closeModal: () => void,
  createExternalForm: (payload: HandleCallbackActionParams) => void,
}

type ModalProps = {
  agreement: AgreementModel,
  callbacks: ModalCallbacks,
  showModal: boolean,
}

type CreateExternalFormPayload = {
  callbacks: {
    action: (agreementParams: AgreementModel, requestOptions: object) => void,
    afterAction: () => void,
  },
  entityParams: {
    agreementParams: AgreementModel,
    selectedDocumentTemplate?: DocumentTemplate,
  },
  toastText: string,
}

const CreateExternalFormModal = (props: ModalProps) => {
  const { agreement, callbacks, showModal } = props
  const { closeModal, createExternalForm } = callbacks || {}

  const externalFormPayload = useExternalForm(agreement)
  const {
    callbacks: { createExternalForm: createFn, setEntityState, setState },
    creating,
    entityState,
    entityState: {
      data,
      data: { meta_fields },
    },
    filteredPlatforms,
    leadUsers,
    platformMetaFields,
    selectedIntegrationPlatform,
  } = externalFormPayload

  const integrationPlatformPayload = useIntegrationPlatform(selectedIntegrationPlatform)
  const { supportedFeatureKeys } = integrationPlatformPayload

  const hasExternalTemplatesFeature = supportedFeatureKeys.includes('import_document_templates')

  const externalDocumentTemplatesPayload = useExternalDocumentTemplates()
  const {
    callbacks: { loadExternalDocumentTemplates },
    selectedDocumentTemplate,
  } = externalDocumentTemplatesPayload

  useEffect(() => {
    if (hasExternalTemplatesFeature){
      loadExternalDocumentTemplates(selectedIntegrationPlatform?.key)
    }
  }, [selectedIntegrationPlatform?.id])

  // To be used with handleCallbackAction
  const createExternalFormPayload: CreateExternalFormPayload = {
    callbacks: {
      action: createFn,
      afterAction: closeModal,
    },
    entityParams: {
      agreementParams: entityState,
      externalDocumentTemplate: selectedDocumentTemplate,
      userId: leadUsers[0]?.id,
    },
    toastText: 'Agreement Created',
  }

  const saveEnabled = true

  return (
    <SidebarModal callbacks={callbacks} modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title="Create External" titleSecondLine="Agreement" />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <Form.Field direction="column" boxProps={{ marginBottom: 'medium' }} label="Platform">
            <SelectBox
              callbacks={{
                selectItem: platform => setState({ selectedIntegrationPlatformKey: platform.key }),
              }}
              items={filteredPlatforms}
              selectedItemKey={selectedIntegrationPlatform?.key}
              style={{ zIndex: 3 }}
              ulStyle={{ maxHeight: 300, overflow: 'auto' }}
            />
          </Form.Field>

          {hasExternalTemplatesFeature && (
            <DocumentTemplates externalDocumentTemplatesPayload={externalDocumentTemplatesPayload} />
          )}

          {meta_fields
            && platformMetaFields
            && platformMetaFields.map((metaField) => {
              const {
                input_type, key, label, option_values,
              } = metaField

              return (
                <Form.Field boxProps={{ marginBottom: 'medium' }} label={label} key={key}>
                  <DynamicInput
                    currentValue={meta_fields[key] || ''}
                    inputComponent={input_type}
                    name={key}
                    values={option_values.map(item => ({ key: item.key, label: item.label }))}
                    onChange={e => setEntityState({ data: deepSetObject(data, `meta_fields.${key}`, e.target.value) })}
                  />
                </Form.Field>
              )
            })}
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={creating}
          onClick={() => createExternalForm(createExternalFormPayload)}
          size="large"
        >
          {saveEnabled ? 'Create Agreement' : 'Complete Fields'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ModalProps) => (
  <SidebarModal.RenderController {...props}>
    <CreateExternalFormModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
