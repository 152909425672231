export default {
  default: {
    header: {
      title: 'Manage',
      titleSecondLine: 'Filters',
    },
    label: 'More Options',
    resetFiltersButton: 'Reset Filters',
    applyFiltersButton: 'Apply Filters',
  },
}
