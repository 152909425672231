import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Box } from '@campaignhub/suit-theme'

import useDigitalTemplatePageSectionGroup from '@hooks/useDigitalTemplatePageSectionGroup'

import useDropTarget from '../hooks/useDropTarget'

import Section from './Section'
import styles from './styles.module.scss'

const SectionGroup = React.memo((props) => {
  const {
    callbacks,
    callbacks: { selectCanvasItem },
    digitalTemplatePageSectionGroup,
    selectedCanvasItem,
  } = props

  const sectionGroupPayload = useDigitalTemplatePageSectionGroup(digitalTemplatePageSectionGroup)
  const {
    digitalTemplatePageSectionGroup: { id },
    editorEntity,
    sortedSections,
  } = sectionGroupPayload

  const dropTargetRef = useRef(null)

  const { isActive } = useDropTarget(
    dropTargetRef,
    { id, type: 'digitalTemplatePageSectionGroup' },
    {
      acceptedTypes: ['section'],
      callbacks,
    },
  )

  return (
    <Box
      className={classnames(styles.root, isActive ? styles.active : null)}
      onClick={e => selectCanvasItem(editorEntity, e)}
      ref={dropTargetRef}
    >
      <Box className={styles.label}>Group {id}</Box>

      {sortedSections.map(section => (
        <Section
          callbacks={callbacks}
          key={section.id}
          digitalTemplatePageSection={section}
          selectedCanvasItem={selectedCanvasItem}
        />
      ))}

      <Box className={styles.dropZone}>Drop Sections Here</Box>
    </Box>
  )
})

SectionGroup.propTypes = {
  callbacks: PropTypes.shape({
    dropItem: PropTypes.func.isRequired,
    selectCanvasItem: PropTypes.func.isRequired,
  }).isRequired,
  digitalTemplatePageSectionGroup: PropTypes.object.isRequired,
  selectedCanvasItem: PropTypes.object.isRequired,
}

export default SectionGroup
