import cloneDeep from 'lodash/cloneDeep'

import { useForm, useLatestEntity } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import useDispatch from '@hooks/useDispatch'
import useSelector from '@hooks/useSelector'

import * as brandActions from '@redux/modules/brand'

import defaultFormState, { requiredFields } from '@models/brand'

import type { AppDispatch } from '@redux/store'
import type { BrandModel, BrandRequestOptions, CustomFieldModel } from '@models/types'

export const generateUrls = (brand?: Partial<BrandModel>) => {
  const { id } = brand || {}

  return {
    editBrandUrl: `#/systemManager/brands/${id}/edit`,
    brandsIndexUrl: '#/systemManager/brands',
  }
}

type CreateBrandParams = {
  brandParams: Partial<BrandModel>,
  dispatch: AppDispatch,
  requestOptions?: BrandRequestOptions,
}

const createBrand = (params: CreateBrandParams) => {
  const { dispatch, brandParams, requestOptions } = params
  const { options } = brandParams || {}

  const { createBrand: createFn } = brandActions

  const updatedParams = {
    ...brandParams,
    options: JSON.stringify(options),
  }

  return dispatch(createFn(updatedParams, requestOptions)).then((response) => {
    const { success, data } = response
    if (success){
      const {
        entity: { id },
      } = data

      const { editBrandUrl } = generateUrls({ id })

      return {
        success,
        data,
        redirectUrl: editBrandUrl,
      }
    }

    return response
  })
}

type UpdateBrandParams = {
  brand: BrandModel,
  brandParams: Partial<BrandModel>,
  customFields: CustomFieldModel[],
  dispatch: AppDispatch,
  requestOptions?: BrandRequestOptions,
}

const updateBrand = (params: UpdateBrandParams) => {
  const {
    brand, brandParams, customFields, dispatch, requestOptions,
  } = params
  const { options } = brandParams

  const { updateBrand: updateFn } = brandActions

  const updatedParams = {
    id: brand.id,
    ...brandParams,
  }

  if (options){
    const brandOptions = brand.options || {}
    updatedParams.options = JSON.stringify({
      ...cloneDeep(brandOptions),
      ...options,
    })
  }

  return dispatch(updateFn(updatedParams, customFields, requestOptions))
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useBrandForm(
  brand: Partial<BrandModel> = {},
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const brandForm = useForm(
    defaultFormState,
    { entity: brand, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [brand.id, brand.cache_key],
  )

  return {
    ...brandForm,
  }
}

function useBrand(initEntity: Partial<BrandModel> = {}) {
  const { entity: brand }: { entity: BrandModel} = useLatestEntity(initEntity, 'brands')

  const dispatch = useDispatch()

  const { creating, loading, updating } = useSelector(reduxState => reduxState.brands)

  return {
    brand,
    callbacks: {
      createBrand: (
        brandParams: Partial<BrandModel>,
        entityOptions?: BrandRequestOptions,
      ) => (
        createBrand({ brandParams, dispatch, requestOptions: entityOptions })
      ),
      updateBrand: (
        brandParams: Partial<BrandModel>,
        customFields: CustomFieldModel[],
        entityOptions?: BrandRequestOptions,
      ) => (
        updateBrand({
          brand, brandParams, customFields, dispatch, requestOptions: entityOptions,
        })
      ),
    },
    brand,
    creating,
    loading,
    updating,
    urls: generateUrls(brand),
  }
}

export default useBrand
