import React from 'react'
import PropTypes from 'prop-types'

import { useRealtimeClient } from '@campaignhub/react-hooks'

import RealtimeContext from '@contexts/realtimeContext'

const RealtimeProvider = (props) => {
  const { children } = props

  const realtimeClientPayload = useRealtimeClient({
    apiKey: process.env.REACT_APP_ABLY_PUBLIC_KEY,
  })

  return (
    <RealtimeContext.Provider value={realtimeClientPayload}>
      {children}
    </RealtimeContext.Provider>
  )
}

RealtimeProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default RealtimeProvider
