import { useMemo } from 'react'

import { matchFilterKey, matchFilterString, sortArrayBy } from '@campaignhub/javascript-utils'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useDispatch from '@hooks/useDispatch'
import useSelector from '@hooks/useSelector'

import * as targetAudienceActions from '@redux/modules/targetAudience'

import type { AppDispatch } from '@redux/store'
import type { OrganizationModel, TargetAudienceModel, TargetAudienceRequestOptions } from '@models/types'

const watchEntityKeys = ['targetAudiences']

const copyTargetAudiencesToOrganization = (
  organization: OrganizationModel,
  dispatch: AppDispatch,
  requestOptions?: TargetAudienceRequestOptions,
) => {
  const { copyTargetAudiencesToOrganization: copyFn } = targetAudienceActions

  return dispatch(copyFn(organization, requestOptions))
}

export type TargetAudienceFilters = {
  brand_id?: number,
  organization_id?: number,
  owner_type?: string,
  string?: string,
}

type UseTargetAudiencesOptions = {
  filters?: TargetAudienceFilters,
}

function useTargetAudiences(options: UseTargetAudiencesOptions) {
  const { filters = {} } = options
  const {
    brand_id: filterBrandId,
    organization_id: filterOrganizationId,
    owner_type: filterOwnerType,
    string: filterString,
  } = filters

  const {
    updatedEntities: { targetAudiences: targetAudiencesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const dispatch = useDispatch()
  const { targetAudiences } = useSelector(reduxState => reduxState.entities)

  const { loading } = useSelector(state => state.targetAudiences)

  const filteredTargetAudiences = useMemo(() => {
    const filtered = Object.values(targetAudiences).filter((targetAudience: TargetAudienceModel) => {
      const { owner_id, owner_type, title } = targetAudience

      const active = !targetAudience.hidden
      const brandMatch = filterBrandId
        ? owner_type === 'Brand' && filterBrandId === owner_id
        : true
      const organizationMatch = filterOrganizationId
        ? owner_type === 'Organization' && filterOrganizationId === owner_id
        : true
      const ownerTypeMatch = matchFilterKey(owner_type, filterOwnerType)
      const systemMatch = filterOwnerType === 'System' ? owner_type === 'System' : true
      const titleMatch = matchFilterString(title, filterString)

      return (
        active
        && brandMatch
        && organizationMatch
        && ownerTypeMatch
        && systemMatch
        && titleMatch
      )
    })

    return sortArrayBy(filtered, 'asc', 'title')
  }, [targetAudiencesUpdatedAt, JSON.stringify(filters)])

  const brandTargetAudiences = filteredTargetAudiences.filter(
    targetAudience => targetAudience?.owner_type === 'Brand',
  )

  const organizationTargetAudiences = filteredTargetAudiences.filter(
    targetAudience => targetAudience?.owner_type === 'Organization',
  )

  const systemTargetAudiences = filteredTargetAudiences
    .filter(targetAudience => targetAudience?.owner_type === 'System')

  const hasTargetAudiences = !!filteredTargetAudiences.length

  return {
    brandTargetAudiences,
    callbacks: {
      copyTargetAudiencesToOrganization: (
        organization: OrganizationModel,
        requestOptions: TargetAudienceRequestOptions,
      ) => copyTargetAudiencesToOrganization(
        organization,
        dispatch,
        requestOptions,
      ),
    },
    filteredTargetAudiences,
    hasTargetAudiences,
    loading,
    organizationTargetAudiences,
    systemTargetAudiences,
  }
}

export default useTargetAudiences
