import PropTypes from 'prop-types'

import { Box, FormField } from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import FilterUsersContactsAndTeams from '@components/FilterUsersContactsAndTeams'

import useLocalization from '@hooks/useLocalization'

import CustomRecipientInputs from './components/CustomRecipientInputs'
import Recipient from './components/Recipient'

import localizedStrings from './localizedStrings'

const getRecipientLimitText = (recipientLimitReached, strings) => {
  if (!recipientLimitReached){
    return strings.formFieldLabels?.recipientsLimit || 'Recipients (Limit 3)'
  }

  return strings.formFieldLabels?.recipientsLimitReached || 'Recipients (Limit Reached)'
}

const NotificationRecipients = (props) => {
  const {
    entity, entityForm, permittedCustomRecipientTypes, useEntityHook,
  } = props

  const showCustomRecipients = !!permittedCustomRecipientTypes?.length
  const showCustomEmail = showCustomRecipients && permittedCustomRecipientTypes.includes('email')
  const showCustomMobile = showCustomRecipients && permittedCustomRecipientTypes.includes('mobile')

  const {
    entityState,
    entityState: { options },
    setEntityState,
  } = entityForm

  const {
    callbacks: { toggleNotificationRecipient },
  } = useEntityHook(entity)

  const notificationRecipients = digObject(options, 'notification_recipients', [])

  const userIds = notificationRecipients.map(recipient => recipient.id).filter(r => r)

  const recipientLimitReached = showCustomMobile ? notificationRecipients.length >= 3 : false

  const { strings } = useLocalization(localizedStrings)

  return (
    <Box flexDirection="column" marginTop="medium">
      <FormField direction="column" label={strings.formFieldLabels?.searchUsers || 'Search Users'}>
        <FilterUsersContactsAndTeams
          callbacks={{
            selectItem: selected => toggleNotificationRecipient(
              {
                entityState,
                recipient: { id: selected.id, type: 'user' },
                setEntityState,
              },
            ),
          }}
          disabled={recipientLimitReached}
          disabledText={strings.disabledText || 'Recipients (Limit Reached)'}
          isNotificationRecipient
          selectedUserIds={userIds}
        />
      </FormField>

      {!!notificationRecipients.length && (
        <FormField
          direction="column"
          boxProps={{ marginTop: 'large' }}
          label={
            showCustomMobile
              ? getRecipientLimitText(recipientLimitReached, strings)
              : strings.formFieldLabels?.recipients || 'Recipients'
          }
        >
          {notificationRecipients.map(recipient => (
            <Recipient
              callbacks={{
                toggleRecipient: () => toggleNotificationRecipient({ entityState, recipient, setEntityState }),
              }}
              recipient={recipient}
              showCustomEmail={showCustomEmail}
              showCustomMobile={showCustomMobile}
            />
          ))}
        </FormField>
      )}

      {showCustomRecipients && (
        <CustomRecipientInputs
          callbacks={{
            addRecipient: recipient => toggleNotificationRecipient(
              { entityState, recipient, setEntityState },
            ),
          }}
          recipients={notificationRecipients}
          recipientLimitReached={recipientLimitReached}
          showCustomEmail={showCustomEmail}
          showCustomMobile={showCustomMobile}
        />
      )}
    </Box>
  )
}

NotificationRecipients.defaultProps = {
  permittedCustomRecipientTypes: ['email', 'mobile'],
}

NotificationRecipients.propTypes = {
  entity: PropTypes.object.isRequired,
  entityForm: PropTypes.object.isRequired,
  permittedCustomRecipientTypes: PropTypes.array,
  useEntityHook: PropTypes.func.isRequired,
}

export default NotificationRecipients
