import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faTrashAlt, faUser } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Image, Link, ListItem, Text,
} from '@campaignhub/suit-theme'

const icons = {
  Organization: faBuilding,
  User: faUser,
}

const SelectedEntity = (props) => {
  const { callbacks, selectedEntity } = props
  const {
    id, imageUrl, text, type,
  } = selectedEntity
  const { deselectEntity } = callbacks || {}

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        borderBottom: '1px dashed',
        borderColor: 'lineColor',
        paddingY: 'large',
      }}
      disableHover
      key={id}
    >
      <Box marginRight="medium" width="auto">
        <Image
          boxProps={{ backgroundColor: 'hoverLightGrey', fontSize: 'small' }}
          circle
          height={30}
          width={30}
          url={imageUrl}
        >
          {!imageUrl && (
            <Box color="bodyFontColor" width="auto">
              <FontAwesomeIcon icon={icons[type] || faUser} />
            </Box>
          )}
        </Image>
      </Box>

      <Box>
        <Text fontSize="small">{text}</Text>
      </Box>

      <Link
        greyLink
        onClick={() => deselectEntity()}
        textProps={{ fontSize: 'small' }}
      >
        <FontAwesomeIcon icon={faTrashAlt} />
      </Link>
    </ListItem>
  )
}

export default SelectedEntity
