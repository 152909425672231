import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, Checkbox, ListItem,
} from '@campaignhub/suit-theme'

const User = (props) => {
  const {
    alreadyImported,
    callbacks: { toggleUser },
    checked,
    user,
  } = props

  const { full_name, role } = user

  return (
    <ListItem.WithCheckbox
      boxProps={{ border: 'none' }}
      checkbox={(
        <Checkbox
          checked={checked}
          onClick={toggleUser}
        />
      )}
    >
      <Box
        color={alreadyImported ? 'lightGrey' : 'bodyFontLightColor'}
        fontSize="small"
      >
        {`${full_name}  ${alreadyImported ? ' (Imported)' : ` | ${role}`}`}
      </Box>
    </ListItem.WithCheckbox>
  )
}

User.propTypes = {
  alreadyImported: PropTypes.bool,
  callbacks: PropTypes.object.isRequired,
  checked: PropTypes.bool,
  user: PropTypes.object,
}

export default User
