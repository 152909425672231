import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, Image, ListItem, Text,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useLocalization from '@hooks/useLocalization'
import useReview, { useRelations } from '@hooks/useReview'

import localizedStrings from './localizedStrings'
import defaultCampaignImageUrl from './assets/default_campaign_image_75x75.png'

const Review = (props) => {
  const { review: initReview } = props

  const {
    defaultImage,
    review: { rating, reviewer_name, title },
  } = useReview(initReview)

  const {
    address: { full_address },
  } = useRelations(initReview)

  const defaultImageUrl = digObject(defaultImage, 'sizes.thumb_120x80', '')

  const {
    callbacks: { formatCount },
  } = useLocalization(localizedStrings)

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        as: 'a',
        borderBottom: '1px dashed',
        padding: 'large',
      }}
      disableHover
      forceBottomBorder
    >
      <Image
        borderRadius={5}
        height={40}
        marginRight="medium"
        placeholderUrl={defaultCampaignImageUrl}
        url={defaultImageUrl}
        width={60}
      />

      <Box flexDirection="column" justifyContent="center" lineHeight={1.3} marginLeft="medium" width="65%">
        <Text color="bodyFontColor" fontSize="xsmall" marginBottom={full_address ? 'small' : 0} variant="ellipsis">
          {title}
        </Text>

        <Text color="bodyFontLightColor" fontSize="xsmall" marginBottom="small" variant="ellipsis">
          {full_address}
        </Text>

        <Text color="faintGrey" fontSize="xsmall" variant="ellipsis">
          {reviewer_name}
        </Text>
      </Box>

      <Text color="faintGrey" fontSize="xsmall" textAlign="right" width="100%">
        {formatCount('starCount', { count: rating })}
      </Text>
    </ListItem>
  )
}

Review.propTypes = {
  review: PropTypes.object.isRequired,
}

export default Review
