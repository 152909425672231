import { useMemo } from 'react'

import {
  matchFilterKey, matchFilterNumber, matchFilterString, sortArrayBy,
} from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { CustomFieldSetModel, CustomFieldSetRequestOptions } from '@models/types'
import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['customFieldSets']

const generateMergedFieldSets = (customFieldSets: CustomFieldSetModel[]) => {
  // Split field sets by owners (organization, brand, system)
  const brandOwnedFieldSets = customFieldSets.filter(fieldSet => fieldSet.owner_type === 'Brand')
  const organizationOwnedFieldSets = customFieldSets.filter(fieldSet => fieldSet.owner_type === 'Organization')
  const systemOwnedFieldSets = customFieldSets.filter(fieldSet => fieldSet.owner_type === 'System')

  const brandOwnedFieldSetEntityTypes = brandOwnedFieldSets.map(fieldSet => fieldSet.entity_type)
  const organizationOwnedFieldSetEntityTypes = organizationOwnedFieldSets.map(fieldSet => fieldSet.entity_type)

  // Find remaining brand owned field sets that organization does not have
  const remainingBrandFieldSets = brandOwnedFieldSets.filter(
    fieldSet => !organizationOwnedFieldSetEntityTypes.includes(fieldSet.entity_type),
  )

  // Find remaining system owned field sets that organization and brand does not have
  const remainingSystemFieldSets = systemOwnedFieldSets.filter(
    fieldSet => !brandOwnedFieldSetEntityTypes.includes(fieldSet.entity_type)
      && !organizationOwnedFieldSetEntityTypes.includes(fieldSet.entity_type),
  )

  // Merge the field sets so that the organization can see all field sets
  return [...organizationOwnedFieldSets, ...remainingBrandFieldSets, ...remainingSystemFieldSets]
}

type CustomFieldSetFilters = {
  entity_type?: string,
  owner_id?: number,
  owner_type?: string,
  string?: string,
}

type UseCustomFieldSetsOptions = {
  brandId: number,
  filters?: CustomFieldSetFilters,
  organizationId: number,
  performHttpRequests?: boolean,
  requestOptions?: CustomFieldSetRequestOptions,
}

function useCustomFieldSets(options: UseCustomFieldSetsOptions = {}) {
  const {
    brandId, filters = {}, organizationId, requestOptions,
  } = options
  const {
    entity_type: filterEntityType,
    owner_id: filterOwnerId,
    owner_type: filterOwnerType,
    string: filterString,
  } = filters

  const {
    updatedEntities: { customFieldSets: customFieldSetsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { customFieldSets } = useSelector(reduxState => reduxState.entities)

  const filteredCustomFieldSets = useMemo(() => {
    const filtered = Object.values(customFieldSets).filter((customFieldSet: CustomFieldSetModel) => {
      const {
        entity_type, owner_id, owner_type, title,
      } = customFieldSet

      const brandMatch = brandId && owner_type === 'Brand' ? brandId === owner_id : true
      const organizationMatch = organizationId && owner_type === 'Organization' ? organizationId === owner_id : true
      const systemMatch = owner_type === 'System'

      const entityMatch = filterEntityType ? matchFilterKey(entity_type, filterEntityType) : true
      const ownerIdMatch = filterOwnerId ? matchFilterNumber(owner_id, filterOwnerId) : true
      const ownerType = filterOwnerType ? matchFilterString(owner_type, filterOwnerType) : true

      const matchTitle = matchFilterString(title, filterString)

      return matchTitle && (brandMatch || organizationMatch || systemMatch) && entityMatch && ownerIdMatch && ownerType
    })

    return sortArrayBy(filtered, 'asc', 'title')
  }, [customFieldSetsUpdatedAt, JSON.stringify(filters)])

  const mergedFieldSets = generateMergedFieldSets(filteredCustomFieldSets)

  const filteredCustomFieldSetsCount = filteredCustomFieldSets.length
  const hasFilteredCustomFieldSets = !!filteredCustomFieldSetsCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredCustomFieldSetsCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingCustomFieldSets } = useReduxAction(
    'customFieldSets',
    'loadCustomFieldSets',
    {
      ...requestOptions,
      ...filtersWithOffset,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredCustomFieldSets,
    filteredCustomFieldSetsCount,
    hasFieldSets: hasFilteredCustomFieldSets,
    loading: loadingCustomFieldSets,
    mergedFieldSets,
  }
}

export default useCustomFieldSets
