import { useSelector } from 'react-redux'
import { useSetState } from '@campaignhub/react-hooks'

const defaultState = {
  selectedEntity: {},
}

function useManageEntityDefaultIntegrationPlatforms(options = {}){
  const [state, setState] = useSetState(defaultState)
  const { selectedEntity } = state

  const entities = useSelector(reduxState => reduxState.entities)
  const { images, organizations, users } = entities

  const updatingOrganizations = useSelector(reduxState => reduxState.organizations.updating)
  const updatingUsers = useSelector(reduxState => reduxState.users.updating)

  return {
    callbacks: {
      setState,
    },
    images,
    organizations,
    selectedEntity,
    updating: updatingOrganizations || updatingUsers,
    users,
  }
}

export default useManageEntityDefaultIntegrationPlatforms
