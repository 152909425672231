import { Box } from '@campaignhub/suit-theme'

import FieldHolder from '@components/FieldHolder'
import Input from '@components/Input'

import QuadInput from '../QuadInput'

interface BorderPropertiesProps {
  itemStyle: {
    borderStyle: string,
    key: string,
  },
  selectedEntity: {
    id: number,
  },
  styleProps: {},
  updateStyle: (name: string, value: string, styleProps: {}) => void,
}

const BorderProperties = (props: BorderPropertiesProps) => {
  const {
    itemStyle, styleProps, selectedEntity, updateStyle,
  } = props

  return (
    <Box marginTop="large" flexDirection="row" flexWrap="wrap">
      <Input
        boxProps={{ marginBottom: 'large' }}
        entityId={selectedEntity.id}
        data={itemStyle}
        parse={value => value || null}
        propertyKey="border"
        label="Border"
        updateCallback={(name, value) => updateStyle(name, value, styleProps)}
      />

      <Input
        boxProps={{ marginBottom: 'large' }}
        entityId={selectedEntity.id}
        data={itemStyle}
        halfWidth
        parse={value => value || null}
        propertyKey="borderColor"
        label="Border Color"
        updateCallback={(name, value) => updateStyle(name, value, styleProps)}
      />

      <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth label="Border Style" last>
        <select
          onChange={e => updateStyle('borderStyle', e.target.value, styleProps)}
          value={itemStyle.borderStyle || ''}
        >
          <option value="">Default</option>
          <option value="dashed">Dashed</option>
          <option value="dotted">Dotted</option>
          <option value="double">Double</option>
          <option value="groove">Groove</option>
          <option value="hidden">Hidden</option>
          <option value="inset">Inset</option>
          <option value="outset">Outset</option>
          <option value="ridge">Ridge</option>
          <option value="solid">Solid</option>
        </select>
      </FieldHolder>

      <QuadInput
        boxProps={{ marginBottom: 'large' }}
        entityId={selectedEntity.id}
        itemStyle={itemStyle}
        propertyKeys={['borderTop', 'borderRight', 'borderBottom', 'borderLeft']}
        label="Border (px)"
        updateCallback={(name, value) => updateStyle(name, value, styleProps)}
      />

      <QuadInput
        boxProps={{ marginBottom: 'large' }}
        entityId={selectedEntity.id}
        itemStyle={itemStyle}
        propertyKeys={[
          'borderTopLeftRadius',
          'borderTopRightRadius',
          'borderBottomRightRadius',
          'borderBottomLeftRadius',
        ]}
        label="Border Radius (px)"
        updateCallback={(name, value) => updateStyle(name, value, styleProps)}
      />
    </Box>
  )
}

export default BorderProperties
