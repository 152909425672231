import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { useDropTarget } from '@campaignhub/react-hooks'

import useDocumentTemplatePage from '@hooks/useDocumentTemplatePage'

import useBuilderCanvas from '../../hooks/useBuilderCanvas'

import PageItem from './PageItem'
import PagePreviewImage from './PagePreviewImage'

import styles from './styles.module.scss'

const DocumentTemplatePage = (props) => {
  const { documentTemplatePage, zoom } = props

  const documentTemplatePagePayload = useDocumentTemplatePage(documentTemplatePage)
  const { callbacks: { dragDropTemplatePageItem }, filteredPageItems, pagePreviewImage } = documentTemplatePagePayload

  const canvasPayload = useBuilderCanvas({ documentTemplatePage, zoom })
  const { size: { height, width } } = canvasPayload

  const dropTargetRef = useRef(null)

  const { isActive } = useDropTarget(dropTargetRef, documentTemplatePage, {
    acceptedTypes: ['checkbox', 'date', 'initial', 'select', 'signature', 'text'],
    callbacks: {
      dropItem: dropData => dragDropTemplatePageItem({ dropData, zoom }),
    },
  }, [zoom])

  return (
    <svg
      className={classnames(styles.root, isActive ? styles.active : null)}
      onClick={e => e.stopPropagation()}
      ref={dropTargetRef}
      style={{ width, height }}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <PagePreviewImage canvasPayload={canvasPayload} image={pagePreviewImage} />

      {filteredPageItems.map(documentTemplatePageItem => (
        <PageItem
          documentTemplatePageItem={documentTemplatePageItem}
          key={documentTemplatePageItem.id}
          zoom={zoom}
        />
      ))}
    </svg>
  )
}

DocumentTemplatePage.propTypes = {
  documentTemplatePage: PropTypes.object.isRequired,
  zoom: PropTypes.number,
}

export default DocumentTemplatePage
