import { useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'

import { useModals, useSetState, useShowModal } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import useIntegrations from '@hooks/useIntegrations'
import useLocalization from '@hooks/useLocalization'
import useMixpanel from '@hooks/useMixpanel'
import useOrganizationSelector from '@hooks/useOrganizationSelector'

import PageContext from '@contexts/pageContext'

import handleCallbackAction from '@functions/handleCallbackAction'

import AgreementExternalEventsModal from '@modals/AgreementExternalEventsModal'
import AgreementProgressModal from '@modals/AgreementProgressModal'
import CreateOrEditAddressModal from '@modals/CreateOrEditAddressModal'
import CreateProjectModal from '@modals/CreateProjectModal'
import RTAValuePropositionModal from '@modals/RTAValuePropositionModal'
import SelectProjectModal from '@modals/SelectProjectModal'

import PageContent from './components/PageContent'

import localizedStrings from './localizedStrings'

const voidExternalAgreement = (voidReason, voidFn, setState, strings) => {
  voidFn(voidReason).then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    toast(strings.toast?.agreement?.voided || 'Agreement Voided.')
    setState({ showAgreementProgressModal: false })
  })
}

const closeAgreementProgressModal = (setState) => {
  setState({ showAgreementProgressModal: false })

  const url = window.location.href.split('?')[0]
  window.location.href = url
}

const editExternalForm = (generateUrlFn) => {
  generateUrlFn().then((result) => {
    const { errors, success, data } = result
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    if (data?.url){
      window.open(data.url, '_blank')
    }
  })
}

const generateExternalAgreementSigningUrl = (recipientId, generateUrlFn) => {
  generateUrlFn(recipientId, 'AgreementProgressModal').then((result) => {
    const { errors, success, data } = result
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    if (data?.url){
      window.location = data.url
    }
  })
}

const selectProject = (project, hasIntegrations) => {
  const modalKey = hasIntegrations ? 'SelectDocumentTemplateSourceModal' : 'SelectDocumentTemplateModal'
  const url = `#/projects/${project.id}/agreements?showModal=${modalKey}`

  if (url){
    window.location.href = url
  }
}

const importProject = (
  externalId,
  integrationPlatformKey,
  organizationId,
  importFn,
  requestOptions,
  hasIntegrations,
) => {
  importFn(externalId, integrationPlatformKey, organizationId, requestOptions).then(
    ({ success, errors, redirectUrl }) => {
      if (!success){
        toast.warn(errors[0])
        return
      }

      const modalKey = (redirectUrl && hasIntegrations)
        ? 'SelectDocumentTemplateSourceModal'
        : 'SelectDocumentTemplateModal'
      const url = redirectUrl?.concat(`/agreements?showModal=${modalKey}`)
      window.location.href = url
    },
  )
}

const createProject = (projectParams, customFields, createFn, hasIntegrations) => {
  createFn(projectParams, customFields).then(({ success, errors, redirectUrl }) => {
    if (!success){
      toast.warn(errors[0])
      return
    }
    const modalKey = (redirectUrl && hasIntegrations)
      ? 'SelectDocumentTemplateSourceModal'
      : 'SelectDocumentTemplateModal'

    const url = redirectUrl?.concat(`/agreements?showModal=${modalKey}`)
    window.location.href = url
  })
}

const defaultState = {
  pageFilters: {},
  showAgreementExternalEventsModal: false,
  showAgreementProgressModal: false,
  showCreateOrEditAddressModal: false,
  showCreateProjectModal: false,
  showRTAValuePropositionModal: false,
  showSelectProjectModal: false,
}

const callbacks = (component, setState, strings, options = {}) => {
  const { hasIntegrations } = options

  const componentCallbacks = {
    AgreementExternalEventsModal: {
      closeModal: () => setState({ showAgreementExternalEventsModal: false }),
    },
    AgreementProgressModal: {
      closeModal: () => closeAgreementProgressModal(setState),
      voidExternalAgreement: (voidReason, voidFn) => voidExternalAgreement(voidReason, voidFn, setState, strings),
      generateExternalAgreementSigningUrl: (recipientId, generateUrlFn) => generateExternalAgreementSigningUrl(recipientId, generateUrlFn),
    },
    CreateOrEditAddressModal: {
      closeModal: () => setState({ showCreateOrEditAddressModal: false }),
      createAddress: payload => handleCallbackAction(payload),
      updateAddress: payload => handleCallbackAction(payload),
    },
    CreateProjectModal: {
      closeModal: () => setState({ showCreateProjectModal: false }),
      importProject: (externalId, integrationPlatformKey, organizationId, importFn, requestOptions, hasIntegrations) => importProject(externalId, integrationPlatformKey, organizationId, importFn, requestOptions, hasIntegrations),
      submitAction: (projectParams, customFields, createFn) => createProject(projectParams, customFields, createFn, hasIntegrations),
    },
    RTAValuePropositionModal: {
      closeModal: () => setState({ showRTAValuePropositionModal: false }),
    },
    SelectProjectModal: {
      closeModal: () => setState({ showSelectProjectModal: false }),
      showCreateProjectModal: () => setState({ showCreateProjectModal: true }),
      submitAction: project => selectProject(project, hasIntegrations),
    },
  }

  return componentCallbacks[component] || {}
}

const Agreements = () => {
  const [state, setState] = useSetState(defaultState)
  const {
    pageFilters,
    showAgreementExternalEventsModal,
    showAgreementProgressModal,
    showCreateOrEditAddressModal,
    showCreateProjectModal,
    showRTAValuePropositionModal,
    showSelectProjectModal,
  } = state

  const { strings } = useLocalization(localizedStrings)

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const pageContext = useMemo(
    () => ({
      callbacks: {
        editExternalForm,
        showAgreementExternalEventsModal: (payload) => {
          setModalData('AgreementExternalEventsModal', payload)
          setState({ showAgreementExternalEventsModal: true })
        },
        showAgreementProgressModal: (payload) => {
          setModalData('AgreementProgressModal', payload)
          setState({ showAgreementProgressModal: true })
        },
        showCreateOrEditAddressModal: (payload) => {
          setModalData('CreateOrEditAddressModal', payload)
          setState({ showCreateOrEditAddressModal: true })
        },
        showCreateProjectModal: () => setState({ showCreateProjectModal: true }),
        showRTAValuePropositionModal: (payload) => {
          setModalData('RTAValuePropositionModal', payload)
          setState({ showRTAValuePropositionModal: true })
        },
        showSelectProjectModal: () => setState({ showSelectProjectModal: true }),
        resetFilters: (resetFn) => {
          if (resetFn) resetFn()
          setState({ pageFilters: {} })
        },
        updateFilters: (filters) => {
          setState({ pageFilters: filters })
        },
        voidExternalAgreement: (voidReason, voidFn) => voidExternalAgreement(voidReason, voidFn, setState, strings),
      },
    }),
    [modalContext],
  )

  const { selectedOrganization } = useOrganizationSelector()

  const integrationsPayload = useIntegrations({
    featureKeys: ['create_forms', 'import_document_templates'],
    filters: {
      owner_id: selectedOrganization.organization_id,
      owner_type: 'Organization',
    },
  })
  const { hasIntegrations } = integrationsPayload

  // Automatically show modal
  useShowModal({
    modalKey: 'AgreementProgressModal',
    options: {
      callbacks: pageContext.callbacks,
    },
  })

  // Tracks the page load event
  const { callbacks: { dispatchMixpanelEvent } } = useMixpanel()

  useEffect(() => {
    dispatchMixpanelEvent('Organisation Agreements Load')
  }, [])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent pageFilters={pageFilters} />

        <AgreementExternalEventsModal
          callbacks={callbacks('AgreementExternalEventsModal', setState, strings)}
          showModal={showAgreementExternalEventsModal}
        />

        <AgreementProgressModal
          callbacks={callbacks('AgreementProgressModal', setState, strings)}
          showModal={showAgreementProgressModal}
        />

        <CreateOrEditAddressModal
          callbacks={callbacks('CreateOrEditAddressModal', setState, strings)}
          headerTitle={strings.addressModalHeaderTitle || 'Project'}
          hiddenFields={['title']}
          showModal={showCreateOrEditAddressModal}
        />

        <CreateProjectModal
          callbacks={callbacks('CreateProjectModal', setState, strings, { hasIntegrations })}
          showModal={showCreateProjectModal}
        />

        <RTAValuePropositionModal
          callbacks={callbacks('RTAValuePropositionModal', setState, strings)}
          disableOverlay
          showModal={showRTAValuePropositionModal}
        />

        <SelectProjectModal
          callbacks={callbacks('SelectProjectModal', setState, strings, { hasIntegrations })}
          showModal={showSelectProjectModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default Agreements
