import { Route, Routes } from 'react-router-dom'

import DigitalPages from './screens/DigitalPages'
import Overview from './screens/Overview'
import Projects from './screens/Projects'
import Agreements from './screens/Agreements'
import Contacts from './screens/Contacts'

const DashboardRoutes = () => (
  <Routes>
    <Route path="/overviews/digitalPages" element={<DigitalPages />} />
    <Route path="/overviews/projects" element={<Projects />} />
    <Route path="/overviews/agreements" element={<Agreements />} />
    <Route path="/overviews/contacts" element={<Contacts />} />
    <Route index element={<Overview />} />
  </Routes>
)

export default DashboardRoutes
