import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import type { AppDispatch } from '@redux/store'

import { useSetState, useThunkDispatch } from '@campaignhub/react-hooks'

import {
  digObject, findArrayIndex, stringToKey, toggleArray,
} from '@campaignhub/javascript-utils'

import { stringToTitleCase } from '@functions/string'

import useCurrentUser from '@hooks/useCurrentUser'
import useIntegrations from '@hooks/useIntegrations'
import useOrganizationSelector from '@hooks/useOrganizationSelector'

import * as suburbActions from '@redux/modules/suburb'
import { DigitalPageModel } from '@models/digitalPage'
import { CountryModel } from '@models/country'

export type SuburbModel = {
  key: string,
  platform: string,
  country_id?: number,
  postcode?: string,
  region_name?: string,
  state_name?: string,
  title: string,
}

export type DefaultState = {
  addedSuburbs: Partial<SuburbModel>[],
  loading?: boolean,
  selectedIntegrationPlatformKey?: string,
  selectedSuburb?: Partial<SuburbModel>,
}

export type SetStateType = (payload: DefaultState) => void

const deleteSuburb = (suburb: SuburbModel, state: DefaultState, setState: SetStateType) => {
  const { addedSuburbs } = state

  const updatedSuburbs = toggleArray(addedSuburbs, suburb, { deepCompare: true })

  return setState({ addedSuburbs: updatedSuburbs })
}

const formatSuburbData = (suburbDataKey: string) => {
  // "domain_group_australia_nsw_2072_gordon"
  const split = suburbDataKey.split('_')

  // suburb
  const suburbParts = split.splice(-4, 4).reverse() // ['gordon', '2072', 'nsw', 'australia']
  const capitalizedStateInitials = suburbParts.splice(2, 1)[0].toUpperCase() // 'NSW'
  const suburb = `${suburbParts[0]} ${capitalizedStateInitials} ${suburbParts[1]}` // ['gordon', 'NSW', '2072']
  const suburbTitle = stringToTitleCase(suburb) // 'Gordon NSW 2072'

  // platform
  const platformParts = split.join(' ') // 'domain group'
  const platformTitle = stringToTitleCase(platformParts) // 'Domain Group'

  return {
    key: suburbDataKey,
    platform: platformTitle,
    title: suburbTitle,
  }
}

const addNewSuburb = (suburbDataKey: string, addedSuburbs: SuburbModel[], setState: SetStateType) => {
  const newSuburb = formatSuburbData(suburbDataKey)

  const currentSuburbs = [...addedSuburbs]
  const index = findArrayIndex(currentSuburbs, newSuburb, { deepCompare: true })

  // Don't add suburb if already exists
  if (index === -1){
    const updatedSuburbs = [...currentSuburbs, newSuburb]
    setState({ addedSuburbs: updatedSuburbs })
  }
}

const setAddedSuburbData = (suburbDataArray: SuburbModel[], setState: SetStateType) => {
  const formattedSuburbDataArray = suburbDataArray.map(suburbDataKey => formatSuburbData(suburbDataKey))

  setState({ addedSuburbs: formattedSuburbDataArray })
}

const fetchSuburbData = (
  state: DefaultState,
  setState: SetStateType,
  dispatch: AppDispatch,
  options: Record<string, any>,
) => {
  const { fetchSuburbData: fetchFn } = suburbActions
  const { addedSuburbs, selectedIntegrationPlatformKey, selectedSuburb } = state
  const {
    country_id, postcode, state_name, region_name,
  } = selectedSuburb!
  const { organizationId } = options

  const params = {
    country_id,
    integration_platform_key: selectedIntegrationPlatformKey,
    post_code: postcode,
    organization_id: organizationId,
    state_name,
    suburb_name: region_name,
  }

  setState({ loading: true })

  dispatch(fetchFn(params)).then(({ success, data }) => {
    if (success){
      const dataStoreItem = digObject(data, 'entity', {})

      // Add Fetched Suburb to local state
      if (dataStoreItem.key){
        addNewSuburb(dataStoreItem.key, addedSuburbs, setState)
      }

      setState({
        loading: false,
        selectedSuburb: {},
      })
    }

    setState({ loading: false })
  })
}

const refreshSuburbData = (
  suburb: SuburbModel,
  setState:SetStateType,
  dispatch: AppDispatch,
  options: Record<string, any>,
  resync: boolean,
  countries: CountryModel[],
) => {
  const { fetchSuburbData: fetchFn } = suburbActions

  const { platform } = suburb
  const integrationPlatformKey = stringToKey(platform)

  const split = suburb.key.split('_')
  const suburbDataParts = split.splice(-4, 4).reverse() // ['gordon', '2072', 'nsw', 'australia']

  // We need to pass countryId
  const selectedCountry = countries.find(country => stringToKey(country.title) === suburbDataParts[3])

  const postcode = suburbDataParts[1]
  const stateName = suburbDataParts[2]
  const suburbName = suburbDataParts[0]

  const { organizationId } = options

  const params = {
    country_id: selectedCountry?.id,
    integration_platform_key: integrationPlatformKey,
    post_code: postcode,
    organization_id: organizationId,
    resync_data: resync,
    state_name: stateName,
    suburb_name: suburbName,
  }

  setState({ loading: true })

  dispatch(fetchFn(params)).then(({ success }) => {
    if (success){
      setState({
        loading: false,
        selectedSuburb: {},
      })
    }

    setState({ loading: false })
  })
}

const defaultState: DefaultState = {
  addedSuburbs: [],
  loading: false,
  selectedIntegrationPlatformKey: '',
  selectedSuburb: {},
}

export type UseSuburbDataOptions = {
  digitalPage?: Partial<DigitalPageModel>,
  organizationId?: number,
}

function useSuburbData(options: UseSuburbDataOptions = {}) {
  const { digitalPage } = options
  const [state, setState] = useSetState(defaultState)
  const {
    addedSuburbs,
    loading,
    selectedIntegrationPlatformKey,
    selectedSuburb,
  } = state

  const { countries } = useSelector(reduxState => reduxState.entities)

  const dispatch = useThunkDispatch()

  const currentUserPayload = useCurrentUser()
  const { currentUser } = currentUserPayload

  const { selectedOrganization } = useOrganizationSelector()
  const defaultPlatformKey = digObject(selectedOrganization, 'options.default_platforms.get_suburb_data')

  const { filteredPlatforms } = useIntegrations({
    featureKeys: ['get_suburb_data'],
    filters: {
      subject_id: currentUser.id,
      subject_type: 'User',
    },
  })

  const suburbData = digObject(digitalPage, 'options.suburb_data', [])

  const hasAddedSuburbs = !!addedSuburbs?.length
  const hasSelectedSuburb = !!selectedSuburb?.region_name

  // Auto Select Organization default integration platform if set
  useEffect(() => {
    if (defaultPlatformKey){
      return setState({ selectedIntegrationPlatformKey: defaultPlatformKey })
    }

    // Auto Select first integration platform if only one available (Domain)
    if (filteredPlatforms.length === 1){
      const selectedPlatform = filteredPlatforms[0]
      setState({ selectedIntegrationPlatformKey: selectedPlatform.key })
    }
  }, [defaultPlatformKey, filteredPlatforms.length])

  // select Added Suburbs
  useEffect(() => {
    if (suburbData.length){
      setAddedSuburbData(suburbData, setState)
    }
  }, [suburbData.length])

  // To map integrations platform and filter out duplicates
  const addedSuburbsIntegrationPlatforms: string[] = hasAddedSuburbs
    ? [...new Set(addedSuburbs.map(suburb => suburb?.platform))]
    : []

  return {
    addedSuburbs,
    addedSuburbsIntegrationPlatforms,
    callbacks: {
      deleteSuburb: (selected: SuburbModel) => deleteSuburb(selected, state, setState),
      fetchSuburbData: () => fetchSuburbData(state, setState, dispatch, options),
      refreshSuburbData:
      (suburb: SuburbModel) => refreshSuburbData(suburb, setState, dispatch, options, true, Object.values(countries)),
    },
    filteredPlatforms,
    hasAddedSuburbs,
    hasSelectedSuburb,
    loading,
    saveEnabled: !hasSelectedSuburb,
    selectedIntegrationPlatformKey,
    selectedSuburb,
    setState,
  }
}

export default useSuburbData
