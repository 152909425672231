import { useEffect, useMemo } from 'react'

import { useModals, useSetState } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import useBrandSelector from '@hooks/useBrandSelector'
import useCurrentUser from '@hooks/useCurrentUser'
import useMixpanel from '@hooks/useMixpanel'
import useReduxAction from '@hooks/useReduxAction'

import handleCallbackAction from '@functions/handleCallbackAction'

import CreateOrEditAssetLibraryModal from '@modals/CreateOrEditAssetLibraryModal'
import EditImageModal from '@modals/EditImageModal'
import ImportAssetLibrariesModal from '@modals/ImportAssetLibrariesModal'

import PageContent from './components/PageContent'

const requestOptions = {
  include_asset_library_default_image: true,
  include_asset_library_owner_title: true,
  include_image_sizes: ['thumb_200x120', 'thumb_660x360'],
}

const callbacks = (component, setState) => {
  const componentCallbacks = {
    CreateOrEditAssetLibraryModal: {
      closeModal: () => setState({ showCreateOrEditAssetLibraryModal: false }),
      createAssetLibrary: payload => handleCallbackAction(payload),
      deleteAssetLibrary: payload => handleCallbackAction(payload),
      updateAssetLibrary: payload => handleCallbackAction(payload),
    },
    EditImageModal: {
      closeModal: () => setState({ showEditImageModal: false }),
      deleteImage: payload => handleCallbackAction(payload),
    },
    ImportAssetLibrariesModal: {
      closeModal: () => setState({ showImportAssetLibrariesModal: false }),
      importImageLibraries: payload => handleCallbackAction(payload),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  pageFilters: {},
  showCreateOrEditAssetLibraryModal: false,
  showEditImageModal: false,
  showImportAssetLibrariesModal: false,
}

const ImageLibrary = () => {
  const [state, setState] = useSetState(defaultState)

  const {
    pageFilters, showCreateOrEditAssetLibraryModal, showEditImageModal, showImportAssetLibrariesModal,
  } = state

  useReduxAction(
    'assetLibraries',
    'loadAssetLibraries',
    {
      asset_type: 'image',
      ...pageFilters,
      ...requestOptions,
    },
    [JSON.stringify(pageFilters)],
  )

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  useReduxAction('brands', 'loadBrands', {})

  const { selectedBrand } = useBrandSelector()

  useEffect(() => {
    if (selectedBrand?.length === 1){
      setState({ pageFilters: { brand_id: selectedBrand?.id } })
    }
  }, [selectedBrand.length])

  const pageContext = useMemo(() => ({
    callbacks: {
      resetFilters: (resetFn) => {
        if (resetFn) resetFn()
        setState({ pageFilters: {} })
      },
      showCreateOrEditAssetLibraryModal: (payload) => {
        setModalData('CreateOrEditAssetLibraryModal', payload)
        setState({ showCreateOrEditAssetLibraryModal: true })
      },
      showEditImageModal: (payload) => {
        setModalData('EditImageModal', payload)
        setState({ showEditImageModal: true })
      },
      showImportAssetLibrariesModal: () => setState({ showImportAssetLibrariesModal: true }),
      updateFilters: (filters) => {
        setState({ pageFilters: filters })
      },
    },
  }), [modalContext])

  const { isAdmin, isBrandUser } = useCurrentUser()

  // Tracks the page load event
  const { callbacks: { dispatchMixpanelEvent } } = useMixpanel()
  useEffect(() => {
    if (isBrandUser) dispatchMixpanelEvent('Image Library Load')
  }, [])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent isAdmin={isAdmin} pageFilters={pageFilters} />

        <CreateOrEditAssetLibraryModal
          callbacks={callbacks('CreateOrEditAssetLibraryModal', setState)}
          showModal={showCreateOrEditAssetLibraryModal}
        />

        <EditImageModal
          callbacks={callbacks('EditImageModal', setState)}
          showModal={showEditImageModal}
        />

        <ImportAssetLibrariesModal
          callbacks={callbacks('ImportAssetLibrariesModal', setState)}
          showModal={showImportAssetLibrariesModal}
        />

      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default ImageLibrary
