import PropTypes from 'prop-types'

import { Box, ListItem, Text } from '@campaignhub/suit-theme'

import useDigitalPage from '@hooks/useDigitalPage'
import useDigitalTemplate from '@hooks/useDigitalTemplate'
import useEngagementStatus from '@hooks/useEngagementStatus'
import useLocalization from '@hooks/useLocalization'
import useShareLinks from '@hooks/useShareLinks'

import EngagementStatusBadge from '@components/EngagementStatusBadge'

import ShareLink from '../ShareLink'

import localizedStrings from './localizedStrings'

const ShareLinkGroup = (props) => {
  const {
    digitalPage,
    digitalPage: {
      engagement_status, id, title, total_views,
    },
  } = props

  const { digitalTemplate } = useDigitalPage(digitalPage)

  const { filteredShareLinks } = useShareLinks({
    filters: {
      printLink: false,
      subjectId: id,
      subjectType: 'DigitalPage',
      trackingEnabled: true,
    },
  })

  const {
    digitalTemplate: { title: digitalTemplateTitle },
  } = useDigitalTemplate(digitalTemplate)

  const { engagementStatusTheme } = useEngagementStatus(engagement_status)

  const {
    callbacks: { formatCount },
  } = useLocalization(localizedStrings)

  return (
    <Box variant="white" marginBottom="large" flexDirection="column">
      <Box alignItems="center">
        <ListItem
          boxProps={{
            alignItems: 'center',
            borderBottom: '1px solid',
            padding: 'large',
          }}
          disableHover
        >
          <Box flexDirection="column" marginRight="auto">
            <Text fontSize="small">{title}</Text>
            <Box alignItems="center" fontSize="xsmall" marginTop="medium">
              <Text color="bodyFontLightColor">{digitalTemplateTitle}</Text>
            </Box>
          </Box>

          <Box marginLeft="medium" justifyContent="center" flexDirection="column" width={100}>
            <EngagementStatusBadge engagementStatusTheme={engagementStatusTheme} />

            <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="medium" textAlign="center">
              {formatCount('views', { count: total_views })}
            </Text>
          </Box>
        </ListItem>
      </Box>

      <Box borderTop="1px solid" borderColor="lineColor" flexDirection="column" maxHeight={300} overflow="auto">
        {filteredShareLinks.map(shareLink => (
          <ShareLink key={shareLink.id} shareLink={shareLink} />
        ))}
      </Box>
    </Box>
  )
}

ShareLinkGroup.propTypes = {
  digitalPage: PropTypes.shape({
    engagement_status: PropTypes.string,
    id: PropTypes.number,
    title: PropTypes.string,
    total_views: PropTypes.number,
  }).isRequired,
}

export default ShareLinkGroup
