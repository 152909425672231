import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/pro-light-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'

import {
  Box, Button, Text,
} from '@campaignhub/suit-theme'

import useImage from '@hooks/useImage'
import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const GridView = (props) => {
  const { callbacks: { updateDefaultImage }, image } = props

  const createdDateTime = digObject(image, 'dates.created.date_time_with_timezone')
  const fileName = digObject(image, 'file_data.metadata.filename', '')
  const imageUrl = digObject(image, 'sizes.thumb_1320x720', '')

  const { callbacks: { editImage } } = useImage(image)

  const { callbacks: { formatDate }, strings } = useLocalization(localizedStrings)

  return (
    <Box borderBottom="1px solid" borderColor="lineColor" flexDirection="column" flexShrink="0" padding="large">
      <img alt={strings.imageAltText} src={imageUrl} style={{ width: '100%', height: 'auto' }} />

      <Box alignItems="center" marginTop="large">
        <Box display={['none', 'flex']} flexDirection="column">
          <Text fontSize="small">
            {fileName}
          </Text>

          <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="small">
            {`${strings.createdText || 'Created'} ${formatDate(createdDateTime, DateTime.DATE_HUGE)}`}
          </Text>
        </Box>

        <Box marginLeft={[0, 'auto']} width={['100%', 220]}>
          <Button
            buttonStyle="secondaryUtility"
            icon={<FontAwesomeIcon icon={faCog} />}
            onClick={() => editImage(updateDefaultImage)}
            size="medium"
          >
            {strings.manageImage || 'Manage Image'}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

GridView.propTypes = {
  callbacks: PropTypes.object.isRequired,
  image: PropTypes.object.isRequired,
}

export default GridView
