import React from 'react'

import { BlankState } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

import contactsImageUrl from './assets/contacts.svg'

const ContactBlankState = () => {
  const { strings } = useLocalization(localizedStrings)

  return (
    <BlankState imageUrl={contactsImageUrl}>
      <BlankState.Title>
        {strings.title || 'No Contacts Available'}
      </BlankState.Title>

      <BlankState.Paragraph>
        {strings.paragraph || 'There are no contacts available in this project yet. You can create one now using the button at the top of the page.'}
      </BlankState.Paragraph>
    </BlankState>
  )
}

export default ContactBlankState
