export default {
  default: {
    addFilters: 'Add Filters',
    clear: 'Clear all filters',
    labels: {
      marketCategory: 'Market Category',
    },
    manageFilters: 'Manage ({activeFiltersCount}) Filters',
    options: {
      marketAll: 'All in the market',
      marketCurrent: 'Listed in the market',
      marketRental: 'Rental Comparables in the market',
      marketSold: 'Sold in the market',
      organizationAll: 'All in your organization',
      organizationCurrent: 'Listed by your organization',
      organizationSold: 'Sold by your organization',
      pleaseSelect: 'Please Select...',
    },
    save: 'Save Filters',
  },
}
