import { isValidEmail } from '@campaignhub/javascript-utils'

export type OrganizationModel = {
  cache_key: number,
  id: number,
  brand_id: number,
  business_name: string,
  business_number: string,
  country_calling_code: string,
  data: {},
  default_address_id?: number,
  email: string,
  license_number: string,
  onboarding: false,
  options: {},
  organization_type_id: string,
  phone: string,
  realbase_id: number,
  realbase_type?: string,
  short_name: string,
  shortcode_title: string,
  title: string,
  url: string,
}

export type OrganizationRequestOptions = {
  include_organization_addresses?: boolean,
  include_organization_awards?: boolean,
  include_organization_data_store_items?: boolean,
  include_organization_default_image?: boolean,
  include_organization_images?: boolean,
  include_organization_shortcode_list?: boolean,
  include_organization_sorted_user_ids?: boolean,
  include_organization_type?: boolean,
  include_organization_users_where_admin_ids?: boolean,
  include_organization_users_where_owner_ids?: boolean,
  include_organization_users?: boolean,
  include_organizations_user_ids?: boolean,
}

const state = {
  brand_id: '',
  business_name: '',
  business_number: '',
  country_calling_code: '',
  data: {},
  email: '',
  id: null,
  license_number: '',
  onboarding: false,
  organization_type_id: '',
  phone: '',
  realbase_id: null,
  realbase_type: null,
  short_name: '',
  shortcode_title: '',
  title: '',
  url: '',
}

export const requiredFields = [
  { key: 'brand_id' },
  { key: 'email', validation: isValidEmail, invalidMessage: 'Invalid Email format' },
  { key: 'organization_type_id' },
  { key: 'title' },
]

export default state
