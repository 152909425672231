import { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import type { LogoutOptions, RedirectLoginOptions } from '@auth0/auth0-react'

import {
  Box, Heading, Image, LoadingBubbles, Link, Text,
} from '@campaignhub/suit-theme'

import useMixpanel from '@hooks/useMixpanel'

import LogoMarkUrl from './assets/logo-mark.svg'

type RenderLoginLinkParams = {
  error?: Error,
  loginWithRedirect: (options?: RedirectLoginOptions | undefined) => Promise<void>,
  logout: (options?: LogoutOptions | undefined) => void,
}

const renderLoginLink = (params: RenderLoginLinkParams) => {
  const { error, loginWithRedirect, logout } = params
  if (error){
    return <Link onClick={() => logout()}>Try Another Account</Link>
  }

  return (
    <Box marginLeft="medium" width="auto">
      <Link onClick={() => loginWithRedirect({ application: 'engage' })}>Login</Link>
      <Text color="bodyFontLightColor" marginX="small"> or </Text>
      <Link onClick={() => logout()}>Logout</Link>
    </Box>
  )
}

const Login = () => {
  const authPayload = useAuth0()
  const {
    error, isAuthenticated, isLoading, loginWithRedirect, logout, user,
  } = authPayload

  const { 'https://auth.realbase.io/id': realbaseId } = user || {}

  const { callbacks: { dispatchMixpanelEvent } } = useMixpanel()

  useEffect(() => {
    if (!error && !isAuthenticated && !isLoading){
      localStorage.removeItem('loginEventTracked')

      loginWithRedirect({
        application: process.env.REACT_APP_APPLICATION_NAME,
        appState: {
          redirectPath: window.location.hash,
        },
      })
    }

    if (isAuthenticated){
      if (!localStorage.getItem('loginEventTracked')){
        dispatchMixpanelEvent('Login', { realbase_id: `Realbase ID #${realbaseId}` })

        localStorage.setItem('loginEventTracked', 'true')
      }
    }
  }, [error, isAuthenticated, isLoading, loginWithRedirect])

  return (
    <Box
      alignItems="center"
      backgroundColor="whiteGrey"
      height="100vh"
      justifyContent="center"
      padding={['large', 'xxlarge']}
      width="100%"
    >
      <Box alignItems="center" flexDirection="column" justifyContent="center" width={['100%', '400px']}>
        <Image backgroundSize="contain" url={LogoMarkUrl} width={50} height={50} />

        <Heading textProps={{ color: 'bodyFontLightColor', marginTop: 'xlarge' }}>
          {error ? 'Error' : 'Loading Application...'}
        </Heading>

        {!error && <LoadingBubbles color="rgba(0, 0, 0, 0.15)" style={{ marginTop: 32 }} />}

        {error && <Text marginTop="xlarge">{error.message}</Text>}

        <Box alignItems="center" fontSize="xsmall" justifyContent="center" marginTop="xxlarge">
          <Text color="bodyFontLightColor" marginRight="small">
            Not Loading?
          </Text>
          {renderLoginLink({ error, loginWithRedirect, logout })}
        </Box>
      </Box>
    </Box>
  )
}

export default Login
