import { useMemo } from 'react'

import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { toast } from 'react-toastify'

import {
  useFilters, useModals, useSetState, useShowModal,
} from '@campaignhub/react-hooks'

import { getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'

import { ModalContext } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import PageContext from '@contexts/pageContext'
import PdfDownloadProvider from '@components/PdfDownloadProvider'

import handleCallbackAction from '@functions/handleCallbackAction'

import BuildingAttachmentModal from '@modals/BuildingAttachmentModal'
import CreateOrEditAddressModal from '@modals/CreateOrEditAddressModal'
import CreateOrEditContactModal from '@modals/CreateOrEditContactModal'
import CreateProjectModal from '@modals/CreateProjectModal'
import EditShareLinkModal from '@modals/EditShareLinkModal'
import EditUserModal from '@modals/EditUserModal'
import ManageShareLinksModal from '@modals/ManageShareLinksModal'
import SelectProjectModal from '@modals/SelectProjectModal'
import SendSMSModal from '@modals/SendSMSModal'
import ShareLinkAnalyticsModal from '@modals/ShareLinkAnalyticsModal'

import PdfDownloadContext from '@contexts/pdfDownloadContext'

import defaultRequestOptions from '@sections/Client/packs/Dashboard/defaultRequestOptions'

import localizedStrings from './localizedStrings'
import PageContent from './components/PageContent'

const createShareLink = (shareLinkParams, createFn, editShareLink, strings) => {
  createFn(shareLinkParams, { ...defaultRequestOptions.shareLink }).then(({ success, errors, data }) => {
    if (!success){
      toast.warn(errors[0])
      return
    }

    const { entity: shareLink } = data

    editShareLink(shareLink)
    toast(strings.toast?.shareLinkCreated || 'Share Link Created Successfully')
  })
}

const deleteShareLink = (deleteFn, setState, strings) => {
  deleteFn().then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    toast(strings.toast?.shareLinkDeleted || 'Share Link Deleted Successfully')
    setState({ showEditShareLinkModal: false })
  })
}

const updateShareLink = (shareLinkParams, updateFn, setState, strings) => {
  updateFn(shareLinkParams).then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    toast(strings.toast?.shareLinkUpdated || 'Share Link Updated Successfully')
    setState({ showEditShareLinkModal: false })
  })
}

const sendSMS = (payload, sendSMSFn, setState, strings) => {
  const { recipients, message } = payload

  sendSMSFn(recipients, message).then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    toast(strings.toast?.send || 'Messages queued for delivery')
    setState({ showSendSMSModal: false })
  })
}

const selectProject = (project, templateTypeKey) => {
  const url = `#/projects/${project.id}?showModal=SelectDigitalTemplateModal&digitalTemplateType=${templateTypeKey}`

  if (url){
    window.location.href = url
  }
}

const createProject = (projectParams, customFields, createFn, templateTypeKey) => {
  createFn(projectParams, customFields).then(({ success, errors, redirectUrl }) => {
    if (!success){
      toast.warn(errors[0])
      return
    }

    if (redirectUrl && templateTypeKey){
      const url = redirectUrl?.concat(`?showModal=SelectDigitalTemplateModal&digitalTemplateType=${templateTypeKey}`)
      window.location.href = url
    }
  })
}

const importProject = (
  externalId,
  integrationPlatformKey,
  organizationId,
  importFn,
  requestOptions,
  templateTypeKey,
) => {
  importFn(externalId, integrationPlatformKey, organizationId, requestOptions).then(
    ({ success, errors, redirectUrl }) => {
      if (!success){
        toast.warn(errors[0])
        return
      }

      if (redirectUrl && templateTypeKey){
        const url = redirectUrl?.concat(`?showModal=SelectDigitalTemplateModal&digitalTemplateType=${templateTypeKey}`)
        window.location.href = url
      }
    },
  )
}

const callbacks = (component, setState, strings, templateTypeKey) => {
  const componentCallbacks = {
    CreateOrEditAddressModal: {
      closeModal: () => setState({ showCreateOrEditAddressModal: false }),
      createAddress: payload => handleCallbackAction(payload),
      updateAddress: payload => handleCallbackAction(payload),
    },
    CreateProjectModal: {
      closeModal: () => setState({ showCreateProjectModal: false }),
      importProject: (externalId, integrationPlatformKey, organizationId, importFn, requestOptions) => importProject(externalId, integrationPlatformKey, organizationId, importFn, requestOptions, templateTypeKey),
      submitAction: (projectParams, customFields, createFn) => createProject(projectParams, customFields, createFn, templateTypeKey),
    },
    CreateOrEditContactModal: {
      closeModal: () => setState({ showCreateOrEditContactModal: false, showSendSMSModal: true }),
      deleteContact: payload => handleCallbackAction({ ...payload, requestOptions: defaultRequestOptions.contact }),
      updateContact: payload => handleCallbackAction({ ...payload, requestOptions: defaultRequestOptions.contact }),
    },
    EditShareLinkModal: {
      closeModal: () => setState({ showEditShareLinkModal: false }),
      deleteShareLink: deleteFn => deleteShareLink(deleteFn, setState, strings),
      updateShareLink: (shareLinkParams, updateFn) => updateShareLink(shareLinkParams, updateFn, setState, strings),
    },
    EditUserModal: {
      closeModal: () => setState({ showEditUserModal: false, showSendSMSModal: true }),
      updateUser: payload => handleCallbackAction(payload),
    },
    ManageShareLinksModal: {
      closeModal: () => setState({ showManageShareLinksModal: false }),
      createShareLink: (shareLinkParams, createFn, editShareLink) => createShareLink(shareLinkParams, createFn, editShareLink, strings),
    },
    SelectProjectModal: {
      closeModal: () => setState({ showSelectProjectModal: false }),
      showCreateProjectModal: () => setState({ showCreateProjectModal: true }),
      submitAction: project => selectProject(project, templateTypeKey),
    },
    SendSMSModal: {
      closeModal: () => setState({ showSendSMSModal: false }),
      sendSMS: (payload, sendSMSFn) => sendSMS(payload, sendSMSFn, setState, strings),
    },
    ShareLinkAnalyticsModal: {
      closeModal: () => setState({ showShareLinkAnalyticsModal: false }),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  showCreateOrEditAddressModal: false,
  showCreateOrEditContactModal: false,
  showCreateProjectModal: false,
  showEditShareLinkModal: false,
  showEditUserModal: false,
  showManageShareLinksModal: false,
  showSelectProjectModal: false,
  showSendSMSModal: false,
  showShareLinkAnalyticsModal: false,
}

const DigitalPages = () => {
  const [state, setState] = useSetState(defaultState)
  const {
    showCreateOrEditAddressModal,
    showCreateOrEditContactModal,
    showCreateProjectModal,
    showEditShareLinkModal,
    showEditUserModal,
    showManageShareLinksModal,
    showSelectProjectModal,
    showSendSMSModal,
    showShareLinkAnalyticsModal,
  } = state

  const { entities } = useSelector(reduxState => reduxState)

  const {
    callbacks: filterCallbacks,
    defaultFilters,
    pageFilters,
  } = useFilters({ defaultFilters: { owner_type: 'Organization' } })

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const pageContext = useMemo(() => ({
    callbacks: {
      ...filterCallbacks,
      showCreateOrEditAddressModal: (payload) => {
        setModalData('CreateOrEditAddressModal', payload)
        setState({ showCreateOrEditAddressModal: true })
      },
      showCreateProjectModal: () => setState({ showCreateProjectModal: true }),
      showCreateOrEditContactModal: (payload) => {
        setModalData('CreateOrEditContactModal', payload)
        setState({ showCreateOrEditContactModal: true })
      },
      showManageShareLinksModal: (payload) => {
        setModalData('ManageShareLinksModal', payload)
        setState({ showManageShareLinksModal: true })
      },
      showEditShareLinkModal: (payload) => {
        setModalData('EditShareLinkModal', payload)
        setState({
          showEditShareLinkModal: true,
          showShareLinkAnalyticsModal: false,
        })
      },
      showEditUserModal: (payload) => {
        setModalData('EditUserModal', payload)
        setState({ showEditUserModal: true })
      },
      showSendSMSModal: (payload) => {
        setModalData('SendSMSModal', payload)
        setState({ showSendSMSModal: true })
      },
      showShareLinkAnalyticsModal: (payload) => {
        setModalData('ShareLinkAnalyticsModal', payload)
        setState({ showShareLinkAnalyticsModal: true })
      },
      showSelectProjectModal: () => setState({ showSelectProjectModal: true }),
    },
    defaultFilters,
  }), [modalContext])

  const { digitalTemplateType: templateTypeKey } = parsePermittedQueryParams(getQueryParamsFromHash(), [
    'digitalTemplateType',
  ])

  // Automatically Show Modal
  useShowModal({
    modalKey: 'SelectProjectModal',
    options: {
      callbacks: pageContext.callbacks,
    },
  })

  const digitalTemplateType = Object.values(entities.digitalTemplateTypes).find(
    type => type.key === templateTypeKey,
  ) || { key: templateTypeKey }

  const { strings } = useLocalization(localizedStrings, { digitalTemplateType })

  return (
    <PdfDownloadProvider>
      <PageContext.Provider value={pageContext}>
        <ModalContext.Provider value={modalContext}>
          <Helmet>
            <title>{`${strings.component?.title || 'Engage'}  | Engage`}</title>
          </Helmet>

          <PageContent digitalTemplateType={digitalTemplateType} pageFilters={pageFilters} />

          <BuildingAttachmentModal context={PdfDownloadContext} />

          <CreateOrEditAddressModal
            callbacks={callbacks('CreateOrEditAddressModal', setState, strings, templateTypeKey)}
            headerTitle={strings.addressModalHeaderTitle || 'Project'}
            hiddenFields={['title']}
            showModal={showCreateOrEditAddressModal}
          />

          <CreateOrEditContactModal
            callbacks={callbacks('CreateOrEditContactModal', setState, strings, templateTypeKey)}
            showModal={showCreateOrEditContactModal}
          />

          <CreateProjectModal
            callbacks={callbacks('CreateProjectModal', setState, strings, templateTypeKey)}
            showModal={showCreateProjectModal}
          />

          <EditShareLinkModal
            callbacks={callbacks('EditShareLinkModal', setState, strings, templateTypeKey)}
            showModal={showEditShareLinkModal}
          />

          <EditUserModal
            callbacks={callbacks('EditUserModal', setState, strings, templateTypeKey)}
            showModal={showEditUserModal}
          />

          <ManageShareLinksModal
            callbacks={callbacks('ManageShareLinksModal', setState, strings, templateTypeKey)}
            showModal={showManageShareLinksModal}
          />

          <SelectProjectModal
            callbacks={callbacks('SelectProjectModal', setState, strings, templateTypeKey)}
            showModal={showSelectProjectModal}
          />

          <SendSMSModal callbacks={callbacks('SendSMSModal', setState, strings)} showModal={showSendSMSModal} />

          <ShareLinkAnalyticsModal
            callbacks={callbacks('ShareLinkAnalyticsModal', setState, strings)}
            showModal={showShareLinkAnalyticsModal}
          />
        </ModalContext.Provider>
      </PageContext.Provider>
    </PdfDownloadProvider>
  )
}

export default DigitalPages
