import React from 'react'

import { BlankState } from '@campaignhub/suit-theme'

import blankImageUrl from './assets/no-api-logs.svg'

const ApiLogsBlankState = () => (
  <BlankState imageUrl={blankImageUrl}>
    <BlankState.Title>No API Logs to Display</BlankState.Title>

    <BlankState.Paragraph>
      Try adjusting your filters to return more results.
    </BlankState.Paragraph>
  </BlankState>
)

export default ApiLogsBlankState
