export type EventModel = {
  cache_key: number,
  id: number,
  data: {},
  days_from_start: string,
  description: string,
  duration_in_days: number,
  end_time: string,
  event_calendar_id: number,
  event_group_id: number,
  start_time: string,
  title: string,
}

export type EventRequestOptions = {}

const state = {
  data: {},
  days_from_start: '',
  description: '',
  duration_in_days: 1,
  end_date: '',
  end_time: '',
  event_calendar_id: '',
  event_group_id: '',
  id: null,
  start_date: '',
  start_time: '',
  title: '',
}

export const requiredFields = [
  { key: 'duration_in_days', validation: (value: string | number) => Number(value) > 0 },
  { key: 'event_calendar_id' },
  { key: 'event_group_id' },
  { key: 'title' },
]

export default state
