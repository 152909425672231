import { useSelector } from 'react-redux'

import { digObject, sortArrayBy } from '@campaignhub/javascript-utils'
import { IntegrationPlatformModel, ProjectModel } from '@models/types'

type ExternalIds = Record<string, number>

const findPlatformByKey = (
  integrationPlatformsArray: IntegrationPlatformModel[],
  integrationPlatformKey: string,
) => {
  const platform = integrationPlatformsArray.find(
    integrationPlatform => integrationPlatform.key === integrationPlatformKey,
  )

  return platform || {}
}

const getExternalPlatforms = (
  sourcePlatformKey: string,
  externalIds:ExternalIds,
  integrationPlatformsArray: IntegrationPlatformModel[],
) => {
  const platformKeys = Object.keys(externalIds).filter(key => key !== sourcePlatformKey)

  const externalPlatforms = platformKeys.map(key => ({
    externalId: externalIds[key],
    externalPlatformKey: key,
    integrationPlatform: findPlatformByKey(integrationPlatformsArray, key),
  }))

  const sortedExternalPlatforms = sortArrayBy(externalPlatforms, 'asc', 'externalPlatformKey')

  return sortedExternalPlatforms
}

const getSourcePlatform = (
  sourcePlatformKey: string,
  externalIds: ExternalIds,
  integrationPlatformsArray: IntegrationPlatformModel[],
) => {
  if (!sourcePlatformKey){
    return {}
  }

  const externalId = externalIds[sourcePlatformKey]

  return {
    externalPlatformKey: sourcePlatformKey,
    externalId,
    integrationPlatform: findPlatformByKey(integrationPlatformsArray, sourcePlatformKey),
  }
}

function useEntityExternalPlatforms(entity:ProjectModel) {
  const externalIds = digObject(entity, 'external_ids', {})
  const sourcePlatformKey = digObject(entity, 'source_platform')
  const sourcePlatformOptions = digObject(entity, 'source_options', {})

  const entities = useSelector(reduxState => reduxState.entities)
  const { integrationPlatforms } = entities

  const integrationPlatformsArray = Object.values(integrationPlatforms) as IntegrationPlatformModel[]

  const externalPlatforms = getExternalPlatforms(sourcePlatformKey, externalIds, integrationPlatformsArray)
  const sourcePlatform = getSourcePlatform(sourcePlatformKey, externalIds, integrationPlatformsArray)

  const hasExternalPlatforms = !!externalPlatforms.length
  const hasSourcePlatform = !!sourcePlatform.externalId

  return {
    externalIds,
    externalPlatforms,
    hasExternalPlatforms,
    hasSourcePlatform,
    sourcePlatform,
    sourcePlatformOptions,
  }
}

export default useEntityExternalPlatforms
