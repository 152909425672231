import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, FormField, SidebarModal,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import useLocalization from '@hooks/useLocalization'

import { useDigitalTemplateForm } from '@hooks/useDigitalTemplate'

import Search from '@components/Search'

import localizedStrings from './localizedStrings'

const mapProjectResults = ({ entities: projects }, strings) => ({
  groups: [
    {
      key: 'projects',
      title: strings.search?.title || 'PROJECTS',
      items: projects.map(project => ({
        id: project.id,
        key: project.id,
        text: project.title,
      })),
    },
  ],
})

const ManageTemplateCustomizerModal = (props) => {
  const {
    callbacks,
    callbacks: { updateDigitalTemplate },
    digitalCustomizerPayload: {
      digitalTemplate,
      isMobile,
    },
    modalKey,
    showModal,
  } = props

  const pageContext = useContext(PageContext)
  const {
    callbacks: { editSectionSort },
  } = pageContext

  const digitalTemplateFormPayload = useDigitalTemplateForm(digitalTemplate)
  const {
    callbacks: { updateDigitalTemplate: updateFn },
    entityState,
    entityState: { title },
    handlers,
    updating,
  } = digitalTemplateFormPayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal
      callbacks={callbacks}
      disableAnimation
      disableOverlay={!isMobile}
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.header?.title || 'Template'}
        titleSecondLine={strings.header?.titleSecondLine || 'Options'}
      />

      <SidebarModal.Content>
        <Box flexDirection="column">
          <FormField
            direction="column"
            label={strings.formField?.labelFieldSetTitle || '* Title'}
          >
            <input name="title" type="text" value={title} {...handlers} />
          </FormField>

          <FormField direction="column" label={strings.formField?.moreOptions || 'More Options'} marginTop="large">
            <Button
              buttonStyle="secondaryUtility"
              loading={updating}
              icon={<FontAwesomeIcon icon={faSort} />}
              loadingBubbleColor="white"
              onClick={() => editSectionSort()}
              size="medium"
            >
              {strings.formField?.button || 'Sort Sections'}
            </Button>
          </FormField>

          <FormField
            direction="column"
            label={strings.formField?.labelDataSource || 'Data Source'}
            marginTop="large"
          >
            <Search
              boxProps={{ marginBottom: 'large' }}
              callbacks={{
                selectItem: item => console.log(item),
                mapResults: data => mapProjectResults(data, strings),
              }}
              endpoint="/projects.json"
              placeholder={strings.formField?.searchProjectsPlaceholder || 'Search for a project'}
            />
          </FormField>
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          loading={updating}
          loadingBubbleColor="white"
          onClick={() => updateDigitalTemplate(entityState, updateFn)}
          size="large"
        >
          {strings.footer?.button || 'Save Template'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

ManageTemplateCustomizerModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  digitalCustomizerPayload: PropTypes.shape({
    digitalTemplate: PropTypes.object,
    callbacks: PropTypes.shape({
      setState: PropTypes.func.isRequired,
    }).isRequired,
    isMobile: PropTypes.bool,
    previewing: PropTypes.bool,
  }).isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

ManageTemplateCustomizerModal.defaultProps = {
  modalKey: 'ManageTemplateCustomizerModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <ManageTemplateCustomizerModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
