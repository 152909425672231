import {
  useEffect, useMemo, useRef, useState,
} from 'react'
import cloneDeep from 'lodash/cloneDeep'

import {
  useForm, useLatestEntity, useSetState, useWatchEntityUpdates,
} from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import {
  deepSetObject, digObject, modifyGroupedIdsSort, sortArrayBy, toggleArray,
} from '@campaignhub/javascript-utils'

import useCurrentUser from '@hooks/useCurrentUser'
import useDispatch from '@hooks/useDispatch'
import useSelector from '@hooks/useSelector'

import generateRedirectUrl from '@functions/generateRedirectUrl'

import * as formTemplateActions from '@redux/modules/formTemplate'

import defaultFormState, { requiredFields } from '@models/formTemplate'

import type { AppDispatch } from '@redux/store'
import type { DeleteParams } from '@redux/modules/types'
import type { CustomFieldModel, FormTemplateModel, FormTemplateRequestOptions } from '@models/types'

const watchEntityKeys = ['customFields', 'formTemplates']

export const generateUrls = (isUserAdminOrBrand: boolean | '', formTemplate?: Partial<FormTemplateModel>) => {
  const { id } = formTemplate || {}

  return {
    draftFormTemplates: isUserAdminOrBrand
      ? '#/systemManager/formTemplates?status=draft'
      : '#/admin/formTemplates?status=draft',
    editFormTemplateUrl: `#/formTemplates/${id}/edit?redirect=${generateRedirectUrl(isUserAdminOrBrand)}`,
    formTemplatesIndexUrl: isUserAdminOrBrand ? '#/systemManager/formTemplates' : '#/admin/formTemplates',
    publishedFormTemplates: isUserAdminOrBrand
      ? '#/systemManager/formTemplates?status=published'
      : '#/admin/formTemplates?status=published',
  }
}

type ModifyItemSortPayload = {
  destinationDroppableId: number,
  sourceDroppableId: number,
}

type ModifyItemSortParams = {
  fromIndex: number,
  payload: ModifyItemSortPayload,
  setSortedItemIds: (setSortFn: (sortedItemIds: Record<string, number[]>) => Record<string, number[]>) => void,
  setState: ({ manuallySorted }: { manuallySorted: boolean }) => void,
  toIndex: number,
}

const modifyItemSort = (params: ModifyItemSortParams) => {
  const {
    fromIndex, payload, setSortedItemIds, setState, toIndex,
  } = params
  const { destinationDroppableId, sourceDroppableId } = payload

  setSortedItemIds((sortedItemIds) => {
    const groupedIds = modifyGroupedIdsSort(fromIndex, toIndex, payload, sortedItemIds)

    return {
      ...sortedItemIds,
      [destinationDroppableId]: groupedIds[destinationDroppableId],
      [sourceDroppableId]: groupedIds[sourceDroppableId],
    }
  })

  setState({ manuallySorted: true })
}

type SetSortedFieldIdsFunction = (sortedFieldIds: Record<string, number[]>) => void;

const setupSortedFieldIds = (filteredFields: CustomFieldModel[], setSortedFieldIds: SetSortedFieldIdsFunction) => {
  const grouped: Record<string, number[]> = filteredFields.reduce((acc, customField) => {
    const { owner_id } = customField

    const key = `FormTemplate-${owner_id}`
    if (!acc[key]){
      acc[key] = []
    }

    acc[key].push(customField.id)
    return acc
  }, {})

  setSortedFieldIds(grouped)
}

type SubmitFormResponseParams = {
  dispatch: AppDispatch,
  formState: Partial<FormTemplateModel>,
  formTemplate: FormTemplateModel,
  options?: FormTemplateRequestOptions,
}

const submitFormResponse = (params: SubmitFormResponseParams) => {
  const {
    dispatch, formState, formTemplate, options,
  } = params
  const { submitFormResponse: submitFn } = formTemplateActions

  return dispatch(submitFn(formTemplate, formState, options))
}

type UpdateFormTemplateFieldSortParams = {
  dispatch: AppDispatch,
  formTemplate: FormTemplateModel,
  options?: FormTemplateRequestOptions,
  sortedFields: Record<string, number[]>,
}

const updateFormTemplateFieldSort = (params: UpdateFormTemplateFieldSortParams) => {
  const {
    dispatch, formTemplate, options, sortedFields,
  } = params
  const { updateFormTemplateFieldSort: updateFn } = formTemplateActions
  return dispatch(updateFn(formTemplate, sortedFields, options))
}

type CreateFormTemplateParams = {
  dispatch: AppDispatch,
  formTemplateParams: Partial<FormTemplateModel>,
  isUserAdminOrBrand: boolean | '',
  requestOptions?: FormTemplateRequestOptions,
}

const createFormTemplate = (params: CreateFormTemplateParams) => {
  const {
    dispatch, formTemplateParams, isUserAdminOrBrand, requestOptions,
  } = params
  const { options } = formTemplateParams || {}

  const { createFormTemplate: createFn } = formTemplateActions

  const updatedParams = {
    ...formTemplateParams,
    options: JSON.stringify(options),
  }

  return dispatch(createFn(updatedParams, requestOptions)).then((response) => {
    const { success, data } = response

    if (success){
      const {
        entity: { id },
      } = data

      const { editFormTemplateUrl } = generateUrls(isUserAdminOrBrand, { id })

      return {
        success,
        data,
        redirectUrl: editFormTemplateUrl,
      }
    }

    return response
  })
}

type UpdateFormTemplateParams = {
  dispatch: AppDispatch,
  formTemplate: FormTemplateModel,
  formTemplateParams: Partial<FormTemplateModel>,
  requestOptions?: FormTemplateRequestOptions,
}

const updateFormTemplate = (params: UpdateFormTemplateParams) => {
  const {
    dispatch, formTemplate, formTemplateParams, requestOptions,
  } = params
  const { options } = formTemplateParams

  const { updateFormTemplate: updateFn } = formTemplateActions

  const updatedParams = {
    id: formTemplate.id,
    ...formTemplateParams,
  }

  if (options){
    const formTemplateOptions = formTemplate.options || {}
    updatedParams.options = JSON.stringify({
      ...cloneDeep(formTemplateOptions),
      ...options,
    })
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

type ToggleNotificationRecipientParams = {
  recipient: { id: number, type: string },
  entityState: FormTemplateModel,
  setEntityState: ({ options }: { options: { [key: string] : any } }) => void,
}

const toggleNotificationRecipient = (params: ToggleNotificationRecipientParams) => {
  const { recipient, entityState, setEntityState } = params
  const { options } = entityState
  const notification_recipients = digObject(options, 'notification_recipients', [])

  const updatedRecipients = toggleArray(notification_recipients, recipient, { deepCompare: true })

  setEntityState({ options: deepSetObject(options, 'notification_recipients', updatedRecipients) })
}

type DeleteFormTemplateParams = {
  dispatch: AppDispatch,
  formTemplate: DeleteParams<FormTemplateModel>,
  isUserAdminOrBrand: boolean | '',
}

const deleteFormTemplate = (params: DeleteFormTemplateParams) => {
  const { dispatch, formTemplate, isUserAdminOrBrand } = params
  const { deleteFormTemplate: deleteFn } = formTemplateActions

  return dispatch(deleteFn(formTemplate)).then((response) => {
    const { success } = response

    if (success){
      const { formTemplatesIndexUrl } = generateUrls(isUserAdminOrBrand)

      return {
        ...response,
        redirectUrl: formTemplatesIndexUrl,
      }
    }

    return response
  })
}

type DuplicateFormTemplateParams = {
  dispatch: AppDispatch,
  formTemplateParams: Partial<FormTemplateModel>,
  isUserAdminOrBrand: boolean | '',
  requestOptions?: FormTemplateRequestOptions,
}

const duplicateFormTemplate = (params: DuplicateFormTemplateParams) => {
  const {
    dispatch, formTemplateParams, isUserAdminOrBrand, requestOptions,
  } = params
  const { duplicateFormTemplate: duplicateFn } = formTemplateActions
  const { options } = formTemplateParams || {}

  const updatedParams = {
    ...formTemplateParams,
    options: JSON.stringify(options),
  }

  return dispatch(duplicateFn(updatedParams, requestOptions)).then((response) => {
    const { success, data } = response
    if (success){
      const {
        entity: { id },
      } = data

      const { editFormTemplateUrl } = generateUrls(isUserAdminOrBrand, { id })

      return {
        success,
        data,
        redirectUrl: editFormTemplateUrl,
      }
    }

    return response
  })
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useFormTemplateForm(
  formTemplate: Partial<FormTemplateModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const { creating, loading, updating } = useSelector(reduxState => reduxState.formTemplates)

  const formTemplateForm = useForm(
    defaultFormState,
    { entity: formTemplate, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [formTemplate.id, formTemplate.cache_key],
  )

  return {
    ...formTemplateForm,
    creating,
    loading,
    updating,
  }
}

const defaultState = {
  manuallySorted: false,
}

function useFormTemplate(initEntity: Partial<FormTemplateModel> = {}) {
  const { entity: formTemplate }: { entity: FormTemplateModel} = useLatestEntity(initEntity, 'formTemplates')
  const { id } = formTemplate

  const [sortedFields, setSortedFieldIds] = useState({})

  const [state, setState] = useSetState(defaultState)
  const { manuallySorted } = state

  const dispatch = useDispatch()

  const { isAdmin, isBrandUser } = useCurrentUser()
  const isUserAdminOrBrand = isAdmin || isBrandUser

  const {
    updatedEntities: {
      customFields: customFieldsUpdatedAt,
      formTemplates: formTemplatesUpdatedAt,
    },
  } = useWatchEntityUpdates(watchEntityKeys)

  const entitiesRef = useRef()
  entitiesRef.current = useSelector(reduxState => reduxState.entities)
  const { customFields } = entitiesRef.current

  // Fields
  const filteredFields = useMemo(() => {
    const array: CustomFieldModel[] = Object.values(customFields)
    const filtered = array.filter((customField) => {
      const { owner_type, owner_id } = customField
      return owner_type === 'FormTemplate' && owner_id === id
    })

    const sorted = sortArrayBy(filtered, 'asc', 'sort')

    return sorted
  }, [id, customFieldsUpdatedAt, formTemplatesUpdatedAt])

  // Handle item sorting in state
  useEffect(() => {
    setupSortedFieldIds(filteredFields, setSortedFieldIds)
  }, [filteredFields.length])

  // Commit the sorted items
  useEffect(() => {
    if (manuallySorted){
      updateFormTemplateFieldSort({ dispatch, formTemplate, sortedFields })
      setState({ manuallySorted: false })
    }
  }, [JSON.stringify(sortedFields), manuallySorted])

  return {
    callbacks: {
      createFormTemplate: (
        formTemplateParams: Partial<FormTemplateModel>,
        entityOptions?: FormTemplateRequestOptions,
      ) => (
        createFormTemplate({
          dispatch, formTemplateParams, isUserAdminOrBrand, requestOptions: entityOptions,
        })
      ),
      deleteFormTemplate: () => deleteFormTemplate({ dispatch, formTemplate, isUserAdminOrBrand }),
      duplicateFormTemplate: (
        formTemplateParams: Partial<FormTemplateModel>,
        entityOptions?: FormTemplateRequestOptions,
      ) => duplicateFormTemplate({
        dispatch, isUserAdminOrBrand, formTemplateParams, requestOptions: entityOptions,
      }),
      modifyItemSort: (
        fromIndex: number,
        toIndex: number,
        payload: ModifyItemSortPayload,
      ) => modifyItemSort({
        fromIndex, payload, setState, setSortedItemIds: setSortedFieldIds, toIndex,
      }),
      submitFormResponse: (
        formState: Partial<FormTemplateModel>,
        entityOptions?: FormTemplateRequestOptions,
      ) => submitFormResponse({
        formTemplate, formState, dispatch, options: entityOptions,
      }),
      toggleNotificationRecipient,
      updateFormTemplate: (
        formTemplateParams: Partial<FormTemplateModel>,
        entityOptions?: FormTemplateRequestOptions,
      ) => (
        updateFormTemplate({
          formTemplate, formTemplateParams, dispatch, requestOptions: entityOptions,
        })
      ),
    },
    customFields,
    filteredFields,
    formTemplate,
    sortedFields,
    urls: generateUrls(isUserAdminOrBrand, formTemplate),

  }
}

export default useFormTemplate
