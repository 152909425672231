import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useLatestEntity, useSetState } from '@campaignhub/react-hooks'

import type { OrganizationModel, UserModel } from '@models/types'

import useUsers from '@hooks/useUsers'

type ModifyUserPayload = {
  destinationDroppableId: number,
  sourceDroppableId: number,
}

type DefaultState = {
  sortedIds: number[],
}

const defaultState: DefaultState = {
  sortedIds: [],
}

function useEditUserSort(initOrganization: Partial<OrganizationModel>) {
  const { entity: organization } = useLatestEntity(initOrganization, 'organizations')

  const [state, setState] = useSetState(defaultState)
  const { sortedIds } = state

  const { sorted_user_ids } = organization || []

  useEffect(() => {
    if (organization.id){
      setState({ sortedIds: sorted_user_ids })
    }
  }, [organization.id])

  const {
    callbacks: { modifyUserSort },
  } = useUsers()

  const { users } = useSelector(reduxState => reduxState.entities)
  const sortedUsers: UserModel[] = sortedIds.map(id => users[id]).filter(u => u)

  return {
    callbacks: {
      // eslint-disable-next-line max-len
      modifySort: (fromIndex: number, toIndex: number, payload:ModifyUserPayload) => modifyUserSort(fromIndex, toIndex, payload, { sortedIds }, setState),
    },
    sortedIds,
    sortedUsers,
  }
}

export default useEditUserSort
