export default {
  default: {
    helmet: {
      title: 'Custom Field Sets | Engage',
    },
    pageHeader: {
      back: 'Back',
      title: 'Custom Field Sets',
    },
  },
}
