import PropTypes from 'prop-types'

import { Box } from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'
import PageContext from '@contexts/pageContext'

import Canvas from '../Canvas'
import TemplateFooter from './components/TemplateFooter'

const DigitalPage = (props) => {
  const {
    digitalRendererPayload,
    digitalRendererPayload: {
      digitalPage,
      digitalTemplate: { options },
      editing,
      previewing,
      printMode,
    },
  } = props

  const footerLink = digObject(options, 'footer_link')
  const footerTheme = digObject(options, 'footer_theme')

  const themeComponent = (
    <PageContext.Provider value={digitalRendererPayload}>
      <Box flexDirection="column" lineHeight="1.5" position="relative">
        <Canvas digitalRendererPayload={digitalRendererPayload} />

        {!printMode && (
          <TemplateFooter dark={footerTheme === 'dark'} link={footerLink || 'https://engagedigital.io'} />
        )}
      </Box>
    </PageContext.Provider>
  )

  if (!editing && !previewing && digitalPage.id){
    return <>{themeComponent}</>
  }

  return themeComponent
}

DigitalPage.propTypes = {
  digitalRendererPayload: PropTypes.shape({
    digitalPage: PropTypes.object,
    digitalTemplate: PropTypes.object,
    digitalTemplateId: PropTypes.number,
    editing: PropTypes.bool,
    previewing: PropTypes.bool,
    printMode: PropTypes.bool,
    shareLinkToken: PropTypes.string,
  }).isRequired,
}

export default DigitalPage
