export default {
  default: {
    saveButton: 'Save Details',
    title: 'Edit User',
    tabTitle: 'All Users',
    updateUser: 'User Updated Successfully',
    VideoModule: {
      title: 'Notifications',
      paragraph: 'Why are your notifications important and how can you manage them within your account.',
      titleTwo: 'How to Set Up Individual Users Profile',
      paragraphTwo: 'Learn how to setup new users so they appear automatically in each new proposal. An Admin or Agent user can do this.',
    },
  },
}
