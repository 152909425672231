/* eslint-disable max-len */
import { useEffect, useMemo } from 'react'

import { useModals, useSetState, useShowModal } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'
import PageContext from '@contexts/pageContext'

import useMixpanel from '@hooks/useMixpanel'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import handleCallbackAction from '@functions/handleCallbackAction'

import CreateQuoteModal from '@modals/CreateQuoteModal'

import { ProjectModel } from '@models/project'

import defaultRequestOptions from '@sections/Client/packs/Project/defaultRequestOptions'

import PageContent from './components/PageContent'

type DefaultState = {
  showCreateQuoteModal: boolean,
}

const defaultState = {
  showCreateQuoteModal: false,
}

type SetState = (state: Partial<DefaultState>) => void

const callbacks = (component: keyof typeof componentCallbacks, setState: SetState) => {
  const componentCallbacks = {
    CreateQuoteModal: {
      closeModal: () => setState({ showCreateQuoteModal: false }),
      createQuote: (payload: HandleCallbackActionParams) => handleCallbackAction({ ...payload, requestOptions: defaultRequestOptions.quote }),
      createQuoteFromTemplate: (payload: HandleCallbackActionParams) => handleCallbackAction({ ...payload, requestOptions: defaultRequestOptions.quote }),
      importExternalQuoteId: (payload: HandleCallbackActionParams) => handleCallbackAction({ ...payload, requestOptions: defaultRequestOptions.quote }),
    },
  }

  return componentCallbacks[component] || {}
}

interface QuotesProps {
  projectId: number,
  project: ProjectModel,
}

const Quotes = (props: QuotesProps) => {
  const { projectId, project } = props

  const [state, setState] = useSetState(defaultState)
  const { showCreateQuoteModal } = state

  const modalContext = useModals()

  const pageContext = useMemo(
    () => ({
      callbacks: {
        showCreateQuoteModal: () => setState({ showCreateQuoteModal: true }),
      },
    }),
    [],
  )

  // Automatically Show Modal
  useShowModal({
    modalKey: 'CreateQuoteModal',
    options: {
      callbacks: pageContext.callbacks,
    },
  })

  // Tracks the page load event
  const { callbacks: { dispatchMixpanelEvent } } = useMixpanel()

  useEffect(() => {
    dispatchMixpanelEvent('Quotes Load')
  }, [])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent projectId={projectId} project={project} />

        <CreateQuoteModal
          callbacks={callbacks('CreateQuoteModal', setState)}
          project={project}
          showModal={showCreateQuoteModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default Quotes
