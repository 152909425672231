import React from 'react'
import PropTypes from 'prop-types'

import { Button, FormField, SidebarModal } from '@campaignhub/suit-theme'

const Print = (props) => {
  const {
    digitalTemplateFormPayload: {
      callbacks: { updateDigitalTemplate },
      entityState,
      entityState: { options },
      handlers,
      updating,
    },
  } = props

  const {
    print_footer: { height, source, start_at }, print_format, print_margin, print_orientation,
  } = options || {}

  return (
    <SidebarModal.ExpandableSectionBox label="Print">
      <FormField boxProps={{ marginBottom: 'large' }} label="Orientation">
        <select name="options.print_orientation" value={print_orientation} {...handlers}>
          <option value="">Default</option>
          <option value="landscape">Landscape</option>
          <option value="portrait">Portrait</option>
        </select>
      </FormField>

      <FormField boxProps={{ marginBottom: 'large' }} label="Format">
        <input name="options.print_format" type="text" value={print_format} {...handlers} />
      </FormField>

      <FormField boxProps={{ marginBottom: 'large' }} label="Margin">
        <input name="options.print_margin" type="text" value={print_margin} {...handlers} />
      </FormField>

      <FormField boxProps={{ marginBottom: 'large' }} label="Footer Source">
        <textarea name="options.print_footer.source" style={{ height: 80 }} type="text" value={source} {...handlers} />
      </FormField>

      <FormField boxProps={{ marginBottom: 'large' }} label="Footer Height">
        <input name="options.print_footer.height" type="text" value={height} {...handlers} />
      </FormField>

      <FormField boxProps={{ marginBottom: 'large' }} label="Footer Start Page">
        <input name="options.print_footer.start_at" type="number" value={start_at} {...handlers} />
      </FormField>

      <Button
        buttonStyle="ghostCreate"
        loading={updating}
        onClick={() => updateDigitalTemplate(entityState)}
        size="medium"
      >
        Save Print Settings
      </Button>
    </SidebarModal.ExpandableSectionBox>
  )
}

Print.propTypes = {
  callbacks: PropTypes.shape({
    updateParam: PropTypes.func.isRequired,
  }),
  digitalTemplateFormPayload: PropTypes.object.isRequired,
}

export default Print
