export default {
  default: {
    caseStudyTitle: 'Case Study #{id}',
    linkedToProject: 'Linked To Project',
    othersText: {
      one: 'other',
      many: 'others',
    },
  },
}
