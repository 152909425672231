export default {
  default: {
    buttons: {
      create: 'Create',
    },
    integrationPartner: {
      description: 'Learn how to generate an agreement with RTA integration',
      integrationPlatformKey: 'real_time_agent',
      title: 'Sign up to Real Time Agent',
    },
    title: 'Agreements',
    agreements: 'Agreements',
    externalDocuments: 'External Documents',
  },
}
