import { useContext } from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendarPlus, faCalendarWeek, faSave, faTrashAlt,
} from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, FormField, SidebarModal,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import useEvent from '@hooks/useEvent'
import useEventCalendar, { useEventCalendarForm } from '@hooks/useEventCalendar'
import useEventGroup from '@hooks/useEventGroup'
import useLocalization from '@hooks/useLocalization'

import Event from './components/Event'

import localizedStrings from './localizedStrings'

const confirmDelete = (deleteEventCalendar, deleteFn, strings) => {
  swal
    .fire({
      title: strings.swal?.title || 'Delete Calendar?',
      text: strings.swal?.text || 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: strings.swal?.confirmButtonText || 'Yes, delete it',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        deleteEventCalendar(deleteFn)
      }
    })
}

const ManageCalendarBuilderModal = (props) => {
  const {
    callbacks,
    callbacks: { updateEventCalendar, deleteEventCalendar },
    modalKey,
    showModal,
  } = props

  const pageContext = useContext(PageContext)
  const {
    eventCalendarPayload: {
      callbacks: { deleteEventCalendar: deleteFn, updateEventCalendar: updateFn },
      eventCalendar,
      filteredEvents,
      isTemplate,
      nextEventGroup,
    },
    isMobile,
  } = pageContext

  const eventCalendarFormPayload = useEventCalendarForm(eventCalendar)
  const {
    entityState,
    entityState: { description, title },
    handlers,
    saveEnabled,
    updating,
  } = eventCalendarFormPayload

  const eventCalendarPayload = useEventCalendar(eventCalendar)
  const {
    callbacks: { createTemplateFromCalendar },
  } = eventCalendarPayload

  // Need this so we can set the modal to start after last event group
  const eventGroupPayload = useEventGroup(nextEventGroup)
  const {
    callbacks: { editEventGroup },
    eventGroup,
  } = eventGroupPayload

  const initEvent = {
    event_calendar_id: eventGroup.event_calendar_id,
  }

  const eventPayload = useEvent(initEvent)
  const {
    callbacks: { createOrEditEvent },
  } = eventPayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal
      callbacks={callbacks}
      disableAnimation
      disableOverlay
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      {isMobile && (
        <SidebarModal.Header
          callbacks={callbacks}
          title={strings.titleManage || 'Manage'}
          titleSecondLine={strings.titleSecondLine || 'Calendar'}
        />
      )}

      <SidebarModal.Content boxProps={{ padding: 0 }}>
        <Box flexDirection="column">
          <Box borderBottom="1px solid" borderColor="lineColor" padding="large">
            <Button
              buttonStyle="secondaryUtility"
              flexShrink="1"
              icon={<FontAwesomeIcon icon={faCalendarWeek} />}
              marginRight="small"
              onClick={editEventGroup}
              size="medium"
              width="50%"
            >
              {strings.buttonAddGroup || 'Add Group'}
            </Button>

            <Button
              buttonStyle="primaryEdit"
              flexShrink="1"
              icon={<FontAwesomeIcon icon={faCalendarPlus} />}
              marginLeft="small"
              onClick={createOrEditEvent}
              size="medium"
              width="50%"
            >
              {strings.buttonAddEvent || 'Add Event'}
            </Button>
          </Box>
        </Box>

        <Box flexDirection="column" padding="large">
          <FormField direction="column" label={strings.labelTitle || '* Title'}>
            <input name="title" type="text" value={title} {...handlers} />
          </FormField>

          <FormField
            direction="column"
            label={strings?.formFieldLabels?.labelDescription || 'Description'}
            marginTop="large"
          >
            <textarea name="description" type="text" value={description} style={{ height: 80 }} {...handlers} />
          </FormField>

          <FormField direction="column" label={strings.labelEvents || 'Events'} marginTop="large">
            {filteredEvents.map(event => (
              <Event key={event.id} event={event} />
            ))}
          </FormField>

          <FormField direction="column" label={strings.labelMoreOptions || 'More Options'} marginTop="large">
            <Button
              buttonStyle="secondaryUtility"
              onClick={() => confirmDelete(deleteEventCalendar, deleteFn, strings)}
              icon={<FontAwesomeIcon icon={faTrashAlt} />}
              size="medium"
              width="100%"
            >
              {!isTemplate
                ? strings.buttonDeleteCalendar || 'Delete Calendar'
                : strings.buttonDeleteTemplate || 'Delete Template'}
            </Button>

            {!isTemplate && (
              <Button
                buttonStyle="secondaryUtility"
                onClick={() => createTemplateFromCalendar()}
                icon={<FontAwesomeIcon icon={faSave} />}
                marginTop="small"
                size="medium"
                width="100%"
              >
                {strings.buttonSaveAsTemplate || 'Save as Template'}
              </Button>
            )}
          </FormField>
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={updating}
          onClick={() => updateEventCalendar(entityState, updateFn)}
          size="large"
        >
          {!isTemplate ? strings.buttonSaveCalendar || 'Save Calendar' : strings.buttonSaveTemplate || 'Save Template'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

ManageCalendarBuilderModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

ManageCalendarBuilderModal.defaultProps = {
  modalKey: 'ManageCalendarBuilderModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <ManageCalendarBuilderModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
