import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/pro-light-svg-icons'

import {
  Box, ListItem, Text,
} from '@campaignhub/suit-theme'

import useCurrentUser from '@hooks/useCurrentUser'
import useIntegration from '@hooks/useIntegration'

import type { IntegrationModel } from '@models/integration'

interface IntegrationProps {
  integration : IntegrationModel,
}

const Integration = (props: IntegrationProps) => {
  const { integration } = props

  const { callbacks: { createOrEditIntegration }, ownerTitle } = useIntegration(integration)

  const { isMasterAdmin } = useCurrentUser()

  const disableUpdate = integration.owner_type === 'System' ? !isMasterAdmin : false

  return (
    <ListItem
      boxProps={{
        as: 'a',
        alignItems: 'center',
        borderBottom: '1px dashed',
        padding: 'large',
      }}
      disableHover={disableUpdate}
      onClick={disableUpdate ? null : () => createOrEditIntegration()}
      showAngleIcon
    >
      <Box flexDirection="column">
        <Text color="bodyFontLightColor" fontSize="small" lineHeight="1.3">
          <FontAwesomeIcon icon={faLink} /> {integration.owner_type === 'System' ? 'Engage' : ownerTitle}
        </Text>
      </Box>
    </ListItem>
  )
}

export default Integration
