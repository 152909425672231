import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolderOpen, faLink, faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Image, Link, ListItem, Text,
} from '@campaignhub/suit-theme'

const SelectedEntityItem = (props) => {
  const {
    assetLibrary: { title, type },
    callbacks: { removeSelectedEntity },
  } = props

  const faIcon = type === 'AssetLibrary' ? faFolderOpen : faLink

  return (
    <ListItem boxProps={{ alignItems: 'center' }} disableHover>
      <Image boxProps={{ backgroundColor: 'hoverLightGrey', fontSize: 'small' }} circle height={30} width={30}>
        <FontAwesomeIcon icon={faIcon} />
      </Image>

      <Box flexDirection="column" marginLeft="large">
        <Text fontSize="small">{title}</Text>
      </Box>

      <Link greyLink onClick={removeSelectedEntity} textProps={{ fontSize: 'small' }}>
        <FontAwesomeIcon icon={faTrashAlt} />
      </Link>
    </ListItem>
  )
}

SelectedEntityItem.propTypes = {
  callbacks: PropTypes.shape({
    removeSelectedEntity: PropTypes.func,
  }).isRequired,
  assetLibrary: PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
}

export default SelectedEntityItem
