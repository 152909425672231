import { useSelector } from 'react-redux'

import { toggleArray } from '@campaignhub/javascript-utils'
import { useSetState } from '@campaignhub/react-hooks'

const checkMobilesPresent = (entities, contactIds, userIds) => {
  const { contacts, users } = entities

  if (contactIds.length){
    const contactsWithMobiles = contactIds.map(id => contacts[id] || {}).filter(c => c.mobile) || []
    if (contactsWithMobiles.length !== contactIds.length){ return false }
  }

  if (userIds.length){
    const usersWithMobiles = userIds.map(id => users[id] || {}).filter(u => u.mobile) || []
    if (usersWithMobiles.length !== userIds.length){ return false }
  }

  return true
}

const mapRecipient = (initRecipient) => {
  const {
    countryCallingCode, key, mobile, id,
  } = initRecipient

  // Recipient is Custom Mobile
  if (!key && mobile){
    return {
      country_calling_code: countryCallingCode,
      isRecipient: true,
      key: `Custom - ${mobile}`,
      mobile,
      title: 'Custom Mobile',
      type: 'custom',
    }
  }

  // Recipient is Contact
  if (key.match(/Contact/)){
    return {
      contact_id: id,
      isRecipient: true,
      key,
      type: 'contact',
    }
  }

  // Recipient is User
  if (key.match(/User/)){
    return {
      isRecipient: true,
      key,
      type: 'user',
      user_id: id,
    }
  }

  return {}
}

const toggleRecipient = (initRecipient, recipients, setState) => {
  const recipient = initRecipient.isRecipient ? initRecipient : mapRecipient(initRecipient)

  const updatedRecipients = toggleArray(recipients, recipient, { deepCompare: true })
  setState({ recipients: updatedRecipients })
}

const defaultState = {
  message: '',
  recipients: [],
}

function useSendSMS(){
  const [state, setState] = useSetState(defaultState)
  const { message, recipients } = state

  const contactIds = recipients.map(recipient => recipient.contact_id).filter(r => r) || []
  const userIds = recipients.map(recipient => recipient.user_id).filter(r => r) || []

  const entities = useSelector(reduxState => reduxState.entities)

  const saveEnabled = (userIds.length || contactIds.length) ? checkMobilesPresent(entities, contactIds, userIds) : !!recipients.length

  return {
    callbacks: {
      setMessage: message => setState({ message }),
      toggleRecipient: recipient => toggleRecipient(recipient, recipients, setState),
    },
    contactIds,
    hasRecipients: !!recipients.length,
    message,
    recipients,
    setState,
    saveEnabled,
    userIds,
  }
}

export default useSendSMS
