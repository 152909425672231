export default {
  default: {
    submitButton: {
      back: 'Go Back',
      browse: 'Browse',
      default: 'Make a Selection',
      finish: 'Finish',
      search: 'Search',
    },
    title: {
      browse: 'Browse',
      clone: 'Clone',
      comparables: 'Comparables',
      create: 'Create',
      default: 'Find',
      search: 'Search',
    },
    authorizeIntegrationButton: 'Authorize Integration',
  },
}
