import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'

import {
  Box, Button, FormField, Grid, Link, SidebarModal, ModalContext,
} from '@campaignhub/suit-theme'
import { useSetState } from '@campaignhub/react-hooks'
import { digObject } from '@campaignhub/javascript-utils'

import ModalImageUpload from '@components/ModalComponents/ModalImageUpload'

import useLocalization from '@hooks/useLocalization'
import useOrganizationSelector from '@hooks/useOrganizationSelector'
import useImageSelect from './hooks/useImageSelect'

import LibrarySelector from './components/LibrarySelector'
import Thumbnail from './components/Thumbnail'

import localizedStrings from './localizedStrings'

const confirmDelete = (deleteFn, deleteCallback, strings) => {
  swal
    .fire({
      title: strings.swal?.title || 'Delete Image?',
      text: strings.swal?.text || 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: strings.swal?.confirmButtonText || 'Yes, delete it.',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        deleteCallback(deleteFn)
      }
    })
}

const tileSizes = {
  large: {
    width: 320,
    height: 200,
  },
  small: {
    width: 158,
    height: 110,
  },
}

const defaultState = {
  viewMode: 'small',
}

const SelectImageModal = (props) => {
  const {
    callbacks,
    callbacks: { closeModal, deleteImage },
    creating,
    disableAnimation,
    disableOverlay,
    defaultSelectedEntity,
    modalKey,
    projectId,
    showModal,
  } = props

  const [state, setState] = useSetState(defaultState)
  const { viewMode } = state

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const { callbacks: payloadCallbacks, selectedEntity: payloadSelectedEntity } = digObject(modalData, `${modalKey}`, {})
  const { selectImage } = payloadCallbacks || {}

  const preselectedEntity = payloadSelectedEntity || defaultSelectedEntity

  const imageSelectPayload = useImageSelect({ projectId, preselectedEntity })
  const {
    callbacks: { setState: setImageSelectState },
    filteredImages,
    hasImages,
    loading,
    selectedEntity,
    selectedEntityId,
    selectedEntityType,
  } = imageSelectPayload

  const tileSize = tileSizes[viewMode] || {}

  const { selectedOrganization } = useOrganizationSelector()

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal
      callbacks={callbacks}
      disableAnimation={disableAnimation}
      disableOverlay={disableOverlay}
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.sideBarModalHeader?.title || 'Select'}
        titleSecondLine={strings.sideBarModalHeader?.titleSecondLine || 'Image'}
      />

      <SidebarModal.Content hasFooter={false}>
        <Box flexDirection="column">
          <FormField
            boxProps={{ marginBottom: 'large' }}
            direction="column"
            label={strings.formFieldLabels?.imageSource || 'Image Source'}
          >
            <select
              onChange={e => setImageSelectState({ selectedEntityId: 0, selectedEntityType: e.target.value })}
              value={selectedEntityType}
            >
              <option key="AssetLibrary" value="AssetLibrary">
                {strings.selectBoxOptions?.imageLibrary || 'Image Library'}
              </option>
              <option key="ProjectComparable" value="ProjectComparable">
                {strings.selectBoxOptions?.comparable || 'Comparable'}
              </option>
              <option key="Organization" value="Organization">
                {strings.selectBoxOptions?.organization || 'Organization'}
              </option>
              <option key="Project" value="Project">
                {strings.selectBoxOptions?.project || 'Project'}
              </option>
              <option key="User" value="User">
                {strings.selectBoxOptions?.user || 'User'}
              </option>
            </select>
          </FormField>

          <LibrarySelector imageSelectPayload={imageSelectPayload} />

          {selectedEntityId !== 0 && selectedEntityType && (
            <FormField
              boxProps={{ marginTop: 'large' }}
              direction="column"
              label="Images"
              labelRight={(
                <Link
                  onClick={() => setState({ viewMode: viewMode === 'large' ? 'small' : 'large' })}
                  textProps={{ fontSize: 'xsmall' }}
                >
                  {viewMode === 'large'
                    ? strings.viewMode?.small || 'View Small Tiles'
                    : strings.viewMode?.large || 'View Large Tiles'}
                </Link>
              )}
            >
              <Box flexDirection="column">
                {hasImages && !loading && !!selectedEntity.id && (
                  <Grid
                    gridGap="medium"
                    gridAutoRows="1fr"
                    gridTemplateColumns={`repeat(auto-fill, minmax(${tileSize.width}px, 1fr))`}
                    gridTemplateRows={`repeat(auto-fill, minmax(${tileSize.height}px, 1fr))`}
                    marginBottom="medium"
                  >
                    {filteredImages.map(image => (
                      <Thumbnail
                        callbacks={{
                          confirmDelete: deleteFn => confirmDelete(deleteFn, deleteImage, strings),
                          selectImage,
                        }}
                        key={image.id}
                        image={image}
                        viewMode={viewMode}
                        creating={creating}
                      />
                    ))}
                  </Grid>
                )}

                <ModalImageUpload
                  imageLabel={strings.formFieldLabels?.imageUpload || 'an Image'}
                  imageSizes={['thumb_660x360', 'thumb_200x120']}
                  imageType="photo"
                  ownerId={selectedEntity.owner_id || selectedOrganization.id}
                  ownerType={selectedEntity.owner_type || 'Organization'}
                  subjectId={selectedEntityId}
                  subjectType={selectedEntityType}
                />
              </Box>
            </FormField>
          )}
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="secondary"
          onClick={closeModal}
          size="medium"
        >
          {strings?.buttons?.close || 'Close'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

SelectImageModal.propTypes = {
  callbacks: PropTypes.shape({
    closeModal: PropTypes.func.isRequired,
    deleteImage: PropTypes.func.isRequired,
  }).isRequired,
  creating: PropTypes.bool,
  defaultSelectedEntity: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
  }),
  disableAnimation: PropTypes.bool,
  disableOverlay: PropTypes.bool,
  modalKey: PropTypes.string,
  projectId: PropTypes.number,
  showModal: PropTypes.bool,
}

SelectImageModal.defaultProps = {
  defaultSelectedEntity: {},
  modalKey: 'SelectImageModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <SelectImageModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
