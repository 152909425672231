import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faPencil } from '@fortawesome/pro-light-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'

import {
  Box, Checkbox, Image, Link, ListItem, Text,
} from '@campaignhub/suit-theme'

import useComparable from '@hooks/useComparable'

interface ComparableListItemProps {
  callbacks: { toggleSelectedComparable: (comparableId: number) => void },
  comparableId: number,
  selected: boolean,
}

const ComparableListItem = (props: ComparableListItemProps) => {
  const {
    callbacks: { toggleSelectedComparable },
    comparableId,
    selected,
  } = props

  const useComparablePayload = useComparable({ id: comparableId })
  const {
    address,
    callbacks: { createOrEditComparable },
    defaultImage,
  } = useComparablePayload
  const { full_address, postcode, state_name } = address

  const imageUrl = digObject(defaultImage, 'sizes.thumb_200x120', '')

  if (!selected) return null

  return (
    <ListItem
      alignItems="center"
      boxProps={{
        alignItems: 'center',
        paddingY: 'large',
      }}
      disableHover
      flexShrink={0}
    >
      <Checkbox
        boxProps={{ marginRight: 'medium' }}
        onClick={() => toggleSelectedComparable(comparableId)}
        checked={selected}
      />

      <Image
        boxProps={{ backgroundColor: 'hoverLightGrey', fontSize: 'small' }}
        borderRadius={5}
        height={40}
        width={65}
        url={imageUrl}
      >
        {!imageUrl && <FontAwesomeIcon icon={faHome} />}
      </Image>

      <Box flexDirection="column" justifyContent="center" marginLeft="large" marginRight="large">
        <Text color="bodyFontColor" fontSize="small">
          {full_address}
        </Text>

        {(state_name || postcode) && (
          <Text fontSize="xsmall" color="bodyFontLightColor" marginTop="small">
            {state_name} {postcode}
          </Text>
        )}
      </Box>

      <Link greyLink onClick={createOrEditComparable} textProps={{ fontSize: 'small' }}>
        <FontAwesomeIcon icon={faPencil} />
      </Link>
    </ListItem>
  )
}

export default ComparableListItem
