import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { ModalContext } from '@campaignhub/suit-theme'
import { useModals, useSetState } from '@campaignhub/react-hooks'

import useNumericParams from '@hooks/useNumericParams'
import useOrganizationSelector from '@hooks/useOrganizationSelector'

import PageContext from '@contexts/pageContext'

import handleCallbackAction from '@functions/handleCallbackAction'

import defaultRequestOptions from '@sections/Admin/packs/SystemManager/defaultRequestOptions'

import AddUserToOrganizationModal from '@modals/AddUserToOrganizationModal'
import EditOrganizationsUserModal from '@modals/EditOrganizationsUserModal'
import RemoveOrganizationsUserModal from '@modals/RemoveOrganizationsUser'

import PageContent from './components/PageContent'

const callbacks = (component, setState) => {
  const componentCallbacks = {
    AddUserToOrganizationModal: {
      closeModal: () => setState({ showAddUserToOrganizationModal: false }),
      createOrganizationsUser: payload => handleCallbackAction(payload),
    },
    EditOrganizationsUserModal: {
      closeModal: () => setState({ showEditOrganizationsUserModal: false }),
      deleteOrganizationsUser: payload => handleCallbackAction(payload),
      setDefaultOrganization: payload => handleCallbackAction(payload),
      updateOrganizationsUser: payload => handleCallbackAction({ ...payload, requestOptions: defaultRequestOptions.user }),
    },
    RemoveOrganizationsUserModal: {
      closeModal: () => setState({ showEditOrganizationsUserModal: false, showRemoveOrganizationsUserModal: false }),
      removeUserFromOrganization: payload => handleCallbackAction(payload),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  showAddUserToOrganizationModal: false,
  showEditOrganizationsUserModal: false,
  showRemoveOrganizationsUserModal: false,
}

const UserEdit = () => {
  const [state, setState] = useSetState(defaultState)
  const { showAddUserToOrganizationModal, showEditOrganizationsUserModal, showRemoveOrganizationsUserModal } = state

  const { userId } = useNumericParams()
  const { selectedOrganization } = useOrganizationSelector()

  const { users } = useSelector(reduxState => reduxState.entities)
  const user = users[userId] || {}

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const pageContext = useMemo(
    () => ({
      callbacks: {
        showAddUserToOrganizationModal: (payload) => {
          setModalData('AddUserToOrganizationModal', payload)
          setState({ showAddUserToOrganizationModal: true })
        },
        showEditOrganizationsUserModal: (payload) => {
          setModalData('EditOrganizationsUserModal', payload)
          setState({ showEditOrganizationsUserModal: true })
        },
        showRemoveOrganizationsUserModal: (payload) => {
          setModalData('RemoveOrganizationsUserModal', payload)
          setState({ showRemoveOrganizationsUserModal: true })
        },
      },
    }),
    [modalContext],
  )

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent user={user} userId={userId} />

        <AddUserToOrganizationModal
          callbacks={callbacks('AddUserToOrganizationModal', setState)}
          organizationsUser={{
            approved: true,
            organization_id: selectedOrganization.id,
            user_id: user.id,
          }}
          showModal={showAddUserToOrganizationModal}
        />

        <EditOrganizationsUserModal
          callbacks={callbacks('EditOrganizationsUserModal', setState)}
          showModal={showEditOrganizationsUserModal}
        />

        <RemoveOrganizationsUserModal
          callbacks={callbacks('RemoveOrganizationsUserModal', setState)}
          showModal={showRemoveOrganizationsUserModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default UserEdit
