export default {
  default: {
    blankStateText: 'No Activity on this Project',
    title: 'Project Activity',
    VideoModule: {
      title: 'Activities (how to recover data)',
      paragraph: "Deleted a proposal accidentally and need to recover it? Don't stress. You can recover the data within 30 days of deleting.",
    },
  },
}
