import { useMemo } from 'react'

import { useModals, useSetState } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import useOrganizationSelector from '@hooks/useOrganizationSelector'

import handleCallbackAction from '@functions/handleCallbackAction'

import CreateOrEditTargetAudienceModal from '@modals/CreateOrEditTargetAudienceModal'

import PageContent from './components/PageContent'

const callbacks = (component, setState) => {
  const componentCallbacks = {
    CreateOrEditTargetAudienceModal: {
      closeModal: () => setState({ showCreateOrEditTargetAudienceModal: false }),
      deleteFromProjects: payload => handleCallbackAction(payload),
      createTargetAudience: payload => handleCallbackAction(payload),
      deleteTargetAudience: payload => handleCallbackAction(payload),
      updateTargetAudience: payload => handleCallbackAction(payload),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  pageFilters: {},
  showCreateOrEditTargetAudienceModal: false,
}

const TargetAudiences = () => {
  const [state, setState] = useSetState(defaultState)
  const { pageFilters, showCreateOrEditTargetAudienceModal } = state

  const { selectedOrganization } = useOrganizationSelector()

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const pageContext = useMemo(
    () => ({
      callbacks: {
        showCreateOrEditTargetAudienceModal: (payload) => {
          setModalData('CreateOrEditTargetAudienceModal', payload)
          setState({ showCreateOrEditTargetAudienceModal: true })
        },
        resetFilters: (resetFn) => {
          if (resetFn) resetFn()
          setState({ pageFilters: {} })
        },
        updateFilters: (filters) => {
          setState({ pageFilters: filters })
        },
      },
    }),
    [modalContext],
  )

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent organization={selectedOrganization} pageFilters={pageFilters} />

        <CreateOrEditTargetAudienceModal
          callbacks={callbacks('CreateOrEditTargetAudienceModal', setState)}
          showModal={showCreateOrEditTargetAudienceModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default TargetAudiences
