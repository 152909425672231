export default {
  default: {
    header: {
      title: 'Create',
      titleSecondLine: 'Template',
    },
    formField: {
      labelBrand: 'Brand',
      labelTemplateType: 'Template Type',
      labelTitle: 'Title',
    },
    button: 'Create Template',
    search: {
      brandPlaceholder: 'Search for a brand',
    },
  },
}
