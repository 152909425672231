import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'
import { DateTime, Interval } from 'luxon'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy,faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'
import {
  Box, Button, FormField, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import useEvent, { useEventForm, useRelations } from '@hooks/useEvent'
import useEventCalendar from '@hooks/useEventCalendar'
import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const confirmDelete = (deleteEvent, deleteFn, strings) => {
  swal
    .fire({
      title: strings.swal?.title || 'Delete Event?',
      text: strings.swal?.text || 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: strings.swal?.confirmButtonText || 'Yes, delete it',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        deleteEvent(deleteFn)
      }
    })
}

const handleStartDateChange = (eventCalendarPayload, eventStartDate, days_from_start, setEntityState) => {
  const { callbacks: { findGroupForEvent }, isTemplate, calendarStartDate } = eventCalendarPayload

  let daysDiff = days_from_start

  if (!isTemplate){
    const diff = Interval.fromDateTimes(calendarStartDate, eventStartDate)
    daysDiff = Math.floor(diff.length('days'))
  }

  const group = findGroupForEvent(daysDiff)

  if (group?.id){
    setEntityState({ days_from_start: daysDiff, event_group_id: group.id })
  }
}

const handleEndDateChange = (endDate, startDate, setEntityState) => {
  const diff = Interval.fromDateTimes(DateTime.fromISO(startDate), DateTime.fromISO(endDate))
  const daysDiff = Math.floor(diff.length('days')) + 1

  setEntityState({ duration_in_days: daysDiff})
}

const CreateOrEditEventModal = (props) => {
  const {
    callbacks,
    callbacks: { createEvent, deleteEvent, duplicateEvent, updateEvent },
    event: newEvent,
    isTemplate,
    modalKey,
    showModal,
  } = props

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, `${modalKey}`, {})
  const { callbacks: modalCallbacks, event: existingEvent } = modalPayload
  const { createEvent: createFn, updateEvent: updateFn } = modalCallbacks || {}

  const initEvent = existingEvent || newEvent

  const eventPayload = useEvent(initEvent)
  const {
    callbacks: {
      deleteEvent: deleteFn,
      duplicateEvent: duplicateFn,
    },
    event,
  } = eventPayload
  const newRecord = !event.id

  const { eventCalendar } = useRelations(event)

  const eventCalendarPayload = useEventCalendar(eventCalendar)
  const {
    calendarEndDate,
    calendarStartDate,
    filteredEventGroups,
  } = eventCalendarPayload

  const eventFormPayload = useEventForm(event)
  const {
    creating,
    deleting,
    entityState,
    entityState: {
      days_from_start,
      description,
      duration_in_days,
      end_date,
      end_time,
      event_group_id,
      start_date,
      start_time,
      title,
    },
    errors,
    handlers,
    saveEnabled,
    setEntityState,
    updating,
  } = eventFormPayload

  const eventStartDate = DateTime.fromISO(start_date)

  useEffect(() => {
    if (start_date || days_from_start){
      handleStartDateChange(eventCalendarPayload, eventStartDate, days_from_start, setEntityState)
    }
  }, [eventStartDate.toFormat('yyyy-MM-dd'), days_from_start])

  const actionFn = newRecord ? () => createEvent(entityState, createFn) : () => updateEvent(entityState, updateFn)

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header callbacks={callbacks} title={newRecord ? 'Create' : 'Edit'} titleSecondLine="Event" />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <FormField direction="column" label={strings.labelTitle || '* Title'}>
            <input name="title" type="text" value={title} {...handlers} />
          </FormField>

          <FormField direction="column" label={strings.labelDescription || 'Description'} marginTop="large">
            <textarea name="description" type="text" value={description} style={{ height: 80 }} {...handlers} />
          </FormField>

          {!isTemplate && (
            <Box flexDirection="column" flexShrink={0}>
              <FormField direction="column" label={strings.labelStartDate || 'Start Date'} marginTop="large">
                <input
                  max={calendarEndDate.toFormat("yyyy-MM-dd'T'T")}
                  min={calendarStartDate.toFormat("yyyy-MM-dd'T'T")}
                  name="start_date"
                  onBlur={e => setEntityState({
                    end_date: DateTime.fromISO(e.target.value).plus({ hours: 1 }).toFormat("yyyy-MM-dd'T'HH:mm"),
                  })}
                  onChange={e => setEntityState({ start_date: e.target.value })}
                  type="datetime-local"
                  value={start_date}
                />
              </FormField>

              <FormField direction="column" label={strings.labelEndDate || 'End Date'} marginTop="large">
                <input
                  max={calendarEndDate.toFormat("yyyy-MM-dd'T'T")}
                  min={calendarStartDate.toFormat("yyyy-MM-dd'T'T")}
                  name="end_date"
                  type="datetime-local"
                  value={end_date}
                  onBlur={e => handleEndDateChange(e.target.value, start_date, setEntityState)}
                  onChange={e => setEntityState({ end_date: e.target.value })}
                />
              </FormField>
            </Box>
          )}

          {isTemplate && (
            <>
              <Box>
                <FormField
                  boxProps={{ width: 'calc(50% - 4px)', marginLeft: 'small' }}
                  data-value-type="number"
                  direction="column"
                  label={strings.labelDaysFromStart || 'Days from Start'}
                  marginTop="large"
                >
                  <input name="days_from_start" type="number" value={days_from_start} {...handlers} />
                </FormField>

                <FormField
                  boxProps={{ width: 'calc(50% - 4px)', marginLeft: 'small' }}
                  data-value-type="number"
                  direction="column"
                  errorMessage={errors.duration_in_days}
                  hideErrorMessage
                  label={strings.labelDuration || 'Duration (Days)'}
                  marginTop="large"
                >
                  <input min="1" name="duration_in_days" type="number" value={duration_in_days} {...handlers} />
                </FormField>
              </Box>

              <Box>
                <FormField
                  boxProps={{ width: 'calc(50% - 4px)', marginLeft: 'small' }}
                  data-value-type="number"
                  direction="column"
                  label={strings.labelStartTime || 'Start Time'}
                  marginTop="large"
                >
                  <input
                    name="start_time"
                    type="time"
                    value={start_time}
                    onBlur={e => setEntityState({
                      end_time: DateTime.fromISO(e.target.value).plus({ hours: 1 }).toFormat('HH:mm'),
                    })}
                    onChange={e => setEntityState({ start_time: e.target.value })}
                  />
                </FormField>

                <FormField
                  boxProps={{ width: 'calc(50% - 4px)', marginLeft: 'small' }}
                  data-value-type="number"
                  direction="column"
                  label={strings.LabelEndTime || 'End Time'}
                  marginTop="large"
                >
                  <input name="end_time" type="time" value={end_time} {...handlers} />
                </FormField>
              </Box>
            </>
          )}

          <FormField direction="column" label={strings.labelGroup || '* Group'} marginTop="large">
            <select name="event_group_id" type="text" value={event_group_id} {...handlers}>
              <option value="">{strings.formFieldOption || 'Please Select...'}</option>
              {filteredEventGroups.map(eventGroup => (
                <option key={eventGroup.id} value={eventGroup.id}>
                  {eventGroup.title}
                </option>
              ))}
            </select>
          </FormField>

          <FormField direction="column" label={strings.labelMoreOptions || 'More Options'} marginTop="large">
            <Button
              buttonStyle="secondaryUtility"
              disabled={!saveEnabled}
              icon={<FontAwesomeIcon icon={faTrashAlt} />}
              loading={deleting}
              onClick={() => confirmDelete(deleteEvent, deleteFn, strings)}
              size="medium"
            >
              {strings.deleteButton || 'Delete Event'}
            </Button>
            
            <Button
              buttonStyle="secondaryUtility"
              disabled={!saveEnabled}
              icon={<FontAwesomeIcon icon={faCopy} />}
              loading={creating}
              marginTop="medium"
              onClick={() => duplicateEvent(duplicateFn)}
              size="medium"
            >
              {strings.duplicateButton || 'Duplicate Event'}
            </Button>
          </FormField>
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={creating || updating}
          onClick={actionFn}
          size="large"
        >
          {strings.saveButton || 'Save Event'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

CreateOrEditEventModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  event: PropTypes.object,
  isTemplate: PropTypes.bool,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

CreateOrEditEventModal.defaultProps = {
  modalKey: 'CreateOrEditEventModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <CreateOrEditEventModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
