export type VideoModuleType = {
  key: string,
  paragraph: string,
  title: string,
  videoUrl: string,
}

export type VideoModulesLibraryType = {
  [key:string]: VideoModuleType[],
}

const videoModulesLibrary: VideoModulesLibraryType = {
  activities: [
    {
      key: 'lessonOne',
      paragraph: "Deleted a proposal accidentally and need to recover it? Don't stress. You can recover the data within 30 days of deleting.",
      title: 'Activities (how to recover data)',
      videoUrl: 'https://youtu.be/qNv3k4WZ-AE',
    },
  ],
  analytics: [
    {
      key: 'lessonOne',
      paragraph: 'Analytics and what hot, warm & cold proposals mean.',
      title: 'Analytics',
      videoUrl: 'https://youtu.be/-SXF_mbaiSk',
    },
  ],
  calendarTemplates: [
    {
      key: 'lessonOne',
      paragraph: 'Learn how to create event calendars.',
      title: 'Event Calendars',
      videoUrl: 'https://youtu.be/kapZURtODak',
    },
    {
      key: 'lessonTwo',
      paragraph: 'Permission your calendar templates so only the owner/user of this calendar has access to it',
      title: 'Permissions against calendar templates',
      videoUrl: 'https://youtu.be/b7WK-axdcTo',
    },
  ],
  caseStudies: [
    {
      key: 'lessonOne',
      paragraph: 'What is a case study and why should you include these in your proposal.',
      title: 'Case Studies',
      videoUrl: 'https://youtu.be/pO6Alv6FR0s',
    },
  ],
  clientDashboard: [
    {
      key: 'lessonOne',
      paragraph: 'What is the Generate Support Token feature?',
      title: 'Support Token',
      videoUrl: 'https://youtu.be/xGkU338I0vQ',
    },
    {
      key: 'lessonTwo',
      paragraph: 'Add new sections to customise the main screen.',
      title: 'How to customise your dashboard',
      videoUrl: 'https://youtu.be/Lwvl9joaDbc',
    },
  ],
  clientTemplates: [
    {
      key: 'lessonOne',
      paragraph: 'How to create custom templates and edit as needed. Change text, layout, links and images.',
      title: 'How to create custom templates',
      videoUrl: 'https://youtu.be/kyz1Mxob9CU',
    },
    {
      key: 'lessonTwo',
      paragraph: 'Find a section within any template and add to a new template.',
      title: 'How to Add a New Page to a Template',
      videoUrl: 'https://youtu.be/nzfiEqR_e_U',
    },
    {
      key: 'lessonThree',
      paragraph: 'Permission your digital templates so only the owner/user has access to use them',
      title: 'Permissions against versioned templates',
      videoUrl: 'https://youtu.be/MKqiDOy_EPY',
    },
  ],
  editShareLinks: [
    {
      key: 'lessonOne',
      paragraph: 'How to Share and Track Your Proposal and the difference between Proofing and Tracking.',
      title: 'Sharing & Tracking',
      videoUrl: 'https://youtu.be/1WUut-YNLzM',
    },
    {
      key: 'lessonTwo',
      paragraph: 'How the notification system works in Engage.',
      title: 'Why am I receiving notifications in my proposal?',
      videoUrl: 'https://youtu.be/abO7DUcKKVU',
    },
  ],
  events: [
    {
      key: 'lessonOne',
      paragraph: 'What is an event calendar and why should you use them in your digital proposals?',
      title: 'Event Calendars',
      videoUrl: 'https://youtu.be/kapZURtODak',
    },
  ],
  imageLibraries: [
    {
      key: 'lessonOne',
      paragraph: 'Create an image library so that your teams can have quick access to edit the images in their proposals, keeping it on brand.',
      title: 'How to create image library and use them in your proposals',
      videoUrl: 'https://youtu.be/WiSAk4S-Wbw',
    },
  ],
  integrations: [
    {
      key: 'lessonOne',
      paragraph: 'Create an integration between Engage and Agent Box.',
      title: 'Agent Box Integrations',
      videoUrl: 'https://youtu.be/YNjIMJbIVbA',
    },
    {
      key: 'lessonTwo',
      paragraph: 'Create an integration between Engage and Rex.',
      title: 'Rex Integration',
      videoUrl: 'https://youtu.be/doDYEyN9BRA',
    },
    {
      key: 'lessonThree',
      paragraph: 'Create an integration between Engage and Vault.',
      title: 'Vault Integration',
      videoUrl: 'https://youtu.be/YNjIMJbIVbA',
    },
    {
      key: 'lessonFour',
      paragraph: 'Create an integration between Engage and REINZ.',
      title: 'REINZ Integrations',
      videoUrl: 'https://youtu.be/Uw1Jz2Jfm3M',
    },
    {
      key: 'lessonFive',
      paragraph: 'Create an integration between Engage and Real Time Agent.',
      title: 'Real Time Agent Integrations',
      videoUrl: 'https://youtu.be/kMvYEGhFSbc',
    },
  ],
  manageShareLinks: [
    {
      key: 'lessonOne',
      paragraph: 'How to Send Your Proposal from the Engage platform via email and SMS.',
      title: 'Sending Your Proposal',
      videoUrl: 'https://youtu.be/6aBckHPZ-6M',
    },
  ],
  organizations: [
    {
      key: 'lessonOne',
      paragraph: 'How to add awards to your agency.',
      title: 'How to add agency awards?',
      videoUrl: 'https://youtu.be/rzDybM8Wtic',
    },
    {
      key: 'lessonTwo',
      paragraph: 'Toggling between Engage accounts in Account Admin and what this means?',
      title: 'How to toggle between different agencies?',
      videoUrl: 'https://youtu.be/atdZMvMoNU0',
    },
  ],
  projectAgreements: [
    {
      key: 'lessonOne',
      paragraph: 'Learn how to generate an agreement with RTA integration.',
      title: 'How to generate an agreement',
      videoUrl: 'https://youtu.be/r9TKBehTBOI',
    },
  ],
  projectAnalytics: [
    {
      key: 'lessonOne',
      paragraph: 'Learn what data is available for tracking within your Engage platform.',
      title: 'Data Tracking',
      videoUrl: 'https://youtu.be/wCdQ_d9RPcs',
    },
    {
      key: 'lessonTwo',
      paragraph: 'A powerful part of the system. An important tool that allows you to view and track where your vendors spend the most time on the proposal.',
      title: 'Proposal Data & Analytics',
      videoUrl: 'https://youtu.be/CWMXV6p-aU8',
    },
  ],
  projectComparables: [
    {
      key: 'lessonOne',
      paragraph: 'Adding comparables into your digital proposals and the data providers Engage integrates with.',
      title: 'How to add comparables',
      videoUrl: 'https://youtu.be/SZgkVzb1Thk',
    },
  ],
  projectDashboard: [
    {
      key: 'lessonOne',
      paragraph: "Need to start a proposal? You'll need to create a project first. Learn how by watching this short tutorial.",
      title: 'How to create a project',
      videoUrl: 'https://youtu.be/FPpiMgtS9o8',
    },
    {
      key: 'lessonTwo',
      paragraph: "Learn how you can import and launch a campaign from Realhub to Engage.",
      title: 'How to launch a campaign from Realhub to Engage',
      videoUrl: 'https://youtu.be/J0SEXxT9zYc',
    },
  ],
  projectOverview: [
    {
      key: 'lessonOne',
      paragraph: 'Duplicate a previous proposal into a new property. Select specific items to duplicate, or duplicate all.',
      title: 'How to duplicate a proposal',
      videoUrl: 'https://youtu.be/2NI7siAiU1A',
    },
    {
      key: 'lessonTwo',
      paragraph: 'What are project details and where do they feed?',
      title: 'Project details',
      videoUrl: 'https://youtu.be/nCkqKM-_iSc',
    },
    {
      key: 'lessonThree',
      paragraph: 'Learn about your CRM.',
      title: 'Did you know your CRM is the source of truth?',
      videoUrl: 'https://youtu.be/tBF7rHzDHcY',
    },
    {
      key: 'lessonFour',
      paragraph: 'Learn how to create a master project.',
      title: 'How to make a master project?',
      videoUrl: 'https://youtu.be/et_1EW50KOk',
    },
  ],
  proposals: [
    {
      key: 'lessonOne',
      paragraph: 'Learn how to change status to accepted, pending and, sent.',
      title: 'Change the Status of the proposal',
      videoUrl: 'https://youtu.be/_3jGPlPaqtw',
    },
  ],
  projectQuotes: [
    {
      key: 'lessonOne',
      paragraph: "Add a Realhub Marketing Quote into your Proposals. The quotes will then appear in your proposals, on brand. You can also edit the Quote within Engage if you'd like things to be shown/hidden last minute.",
      title: 'How to Import a Quote From Realhub',
      videoUrl: 'https://youtu.be/BrQ-Tn9KEAM',
    },
  ],
  quotes: [
    {
      key: 'lessonOne',
      paragraph: 'Permission your quote templates so only the owner/user of this quote has access to it',
      title: 'Permissions against quote templates',
      videoUrl: 'https://youtu.be/C_UpHFUM2D8',
    },
    {
      key: 'lessonTwo',
      paragraph: '',
      title: 'How to use Engage Quotes',
      videoUrl: 'https://youtu.be/ccmAlRFVn6g',
    },
  ],
  reviews: [
    {
      key: 'lessonOne',
      paragraph: 'What Are Reviews and Why They Should Be Used.',
      title: 'Reviews',
      videoUrl: 'https://youtu.be/CWMkMXhF7NU',
    },
    {
      key: 'lessonTwo',
      paragraph: 'Social proof is important. Learn how to add testimonials into your proposals very easily for yourself and across your teams.',
      title: 'How to create and add testimonials to the proposal',
      videoUrl: 'https://youtu.be/wyY5RZISom4',
    },
    {
      key: 'lessonThree',
      paragraph: 'Have existing testimonials in Realhub? You can easily import them into the new system.',
      title: 'How to import reviews from Realhub',
      videoUrl: 'https://youtu.be/IFpdY825Azc',
    },
  ],
  sendSMS: [
    {
      key: 'lessonOne',
      paragraph: 'Why we recommend SMSing your Digital Proposals.',
      title: 'Why SMS',
      videoUrl: 'https://youtu.be/-CYb1ifp80g',
    },
  ],
  suburbData: [
    {
      key: 'lessonOne',
      paragraph: 'Learn how to get the best out of suburb data.',
      title: 'How to add suburb data?',
      videoUrl: 'https://youtu.be/AWmS4xF7BWY',
    },
  ],
  targeAudiences: [
    {
      key: 'lessonOne',
      paragraph: 'What is a target audience, why should you use it and how you can use through the Engage platform.',
      title: 'Target Audiences',
      videoUrl: 'https://youtu.be/OZ4rsP-sX30',
    },
    {
      key: 'lessonTwo',
      paragraph: 'Learn how to create a target audience.',
      title: 'Creating agency target audiences',
      videoUrl: 'https://youtu.be/lhpysXcLxuE',
    },
  ],
  teams: [
    {
      key: 'lessonOne',
      paragraph: 'Use the teams function so that everyone can have access to the account and proposals. Your team will then automatically show in future proposal documents.',
      title: 'How to create Teams',
      videoUrl: 'https://youtu.be/QTZOLzxgR8I',
    },
  ],
  users: [
    {
      key: 'lessonOne',
      paragraph: 'Why are your notifications important and how can you manage them within your account.',
      title: 'Notifications',
      videoUrl: 'https://youtu.be/flP6_6QG5zk',
    },
    {
      key: 'lessonTwo',
      paragraph: 'Learn how to setup new users so they appear automatically in each new proposal. An Admin or Agent user can do this.',
      title: 'How to Set Up Individual Users Profile',
      videoUrl: 'https://youtu.be/QR6TWi2SaZE',
    },
    {
      key: 'lessonThree',
      paragraph: 'How to add your awards to your agent profile',
      title: 'Adding awards to your profile',
      videoUrl: 'https://youtu.be/Z6Lfm9QzeV8',
    },
  ],
  userlists: [
    {
      key: 'lessonOne',
      paragraph: 'Learn about user groups in Engage',
      title: 'What is the difference between an Agent, Admin, Support, Team user?',
      videoUrl: 'https://youtu.be/b-BgXCKT7AU',
    },
  ],
}

export default videoModulesLibrary
