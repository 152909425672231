import React from 'react'
import PropTypes from 'prop-types'

import { digObject } from '@campaignhub/javascript-utils'

import {
  Box, Image,
} from '@campaignhub/suit-theme'

const Thumbnail = (props) => {
  const { image } = props

  const imageUrl = digObject(image, 'sizes.thumb_200x120', '')

  return (
    <Box
      flexDirection="column"
      flexShrink="0"
    >
      <Image
        borderRadius={5}
        height={100}
        url={imageUrl}
        width="100%"
      />
    </Box>
  )
}

Thumbnail.propTypes = {
  image: PropTypes.object.isRequired,
}
export default Thumbnail
