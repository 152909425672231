import React from 'react'

/*
 * Use this context to provide data to a specific page
 * Use RealtimeContext.Provider in the page
 * Generally we use it to supply page specific params e.g. callbacks or props
 * that need to be available through deeply nested components
 */

export type RealtimeContextPayload = {
  [key: string]: any,
}

const data = {}

const RealtimeContext = React.createContext<RealtimeContextPayload>(data)

export default RealtimeContext
