export default {
  default: {
    sideBarModalHeader: {
      title: 'Source',
      titleSecondLine: 'Platform',
    },
    swal: {
      confirmButtonText: 'Yes, Save it.',
      title: 'Save Platform Details?',
      text: 'Are you sure? Any incorrect details will affect project imports.',
    },
  },
}
