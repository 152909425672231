import { useContext } from 'react'

import { digObject } from '@campaignhub/javascript-utils'
import { useFilterString } from '@campaignhub/react-hooks'

import PageContext from '@contexts/pageContext'

const filterItems = (value, listObject) => listObject.shortcodes?.filter((code) =>
  `${listObject.label} ${code.label}`.toLowerCase().includes(value)
  || `${listObject.value}.${code.value}`.toLowerCase().includes(value),
) || []

const findListItem = (shortcodeList, shortcode) => shortcodeList.find((item) => {
  if (Array.isArray(item.value)) return item.value.includes(shortcode)
  return item.value === shortcode
})

const findDataLoopValue = (shortcodeList, dataContext) => {
  if (!dataContext.length) return null

  // Find list item using first item in context array
  const item = findListItem(shortcodeList, dataContext.shift())

  // if there are still strings in the context array, run this function again
  if (dataContext.length > 0 && item?.shortcodes) {
    return findDataLoopValue(item.shortcodes, dataContext)
  }

  return item
}

const filterList = (shortcodeList, dataContext) => {
  const dataLoopValue = findDataLoopValue(shortcodeList, dataContext)

  // Always want to return shortcodes accessible without data loop
  const baseValues = Object.values(shortcodeList).filter((item) => !item.contextual_item)

  // Return relevant data loop shortcodes before general ones
  if (dataLoopValue) baseValues.unshift(dataLoopValue)

  return baseValues
}

const mapResults = (value, shortcodeListArray, dataContext) => {
  if (!shortcodeListArray) throw 'shortcodeListArray is not defined in page context'

  const groups = []
  const filteredList = filterList(shortcodeListArray, dataContext)

  filteredList.forEach((listObject) => {
    const filteredItems = filterItems(value, listObject)

    const group = {
      key: listObject.label,
      title: listObject.label,
      items: filteredItems.map((shortcode) => ({
        value: shortcode.value,
        key: `${listObject.key}_${shortcode.key}`,
        text: shortcode.label,
      })),
    }

    if (!!filteredItems.length) groups.push(group)
  })

  return { groups }
}

const getResults = (value, shortcodeListArray, dataContext) => {
  try {
    return mapResults(value, shortcodeListArray, dataContext)
  } catch (error) {
    console.log(error)
    return {}
  }
}

function useShortcodeSearch(options = {}) {
  const dataContext = digObject(options, 'dataContext', [])

  const { shortcodeListArray } = useContext(PageContext)

  const { onChange, value } = useFilterString()

  const results = getResults(value.toLowerCase(), shortcodeListArray, [...dataContext])

  return {
    callbacks: { onChange },
    results,
    value,
  }
}

export default useShortcodeSearch
