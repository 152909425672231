import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

import { useModals, useSetState } from '@campaignhub/react-hooks'
import { Columns, LoadingModule, ModalContext } from '@campaignhub/suit-theme'

import useIntercom from '@hooks/useIntercom'

import CreateOrEditQuoteItemModal from '@modals/CreateOrEditQuoteItemModal'
import CreateOrEditQuoteModal from '@modals/CreateOrEditQuoteModal'
import CreateOrEditQuoteSectionModal from '@modals/CreateOrEditQuoteSectionModal'

import handleCallbackAction from '@functions/handleCallbackAction'

import useLocalization from '@hooks/useLocalization'
import useQuoteBuilder, { defaultRequestOptions } from './hooks/useQuoteBuilder'

import QuoteBuilderContextProvider from './components/QuoteBuilderContextProvider'
import Quote from './components/Quote'
import localizedStrings from './localizedStrings'

const createQuoteSection = (quoteSection, createFn, setState, strings) => {
  createFn(quoteSection).then((result) => {
    const { errors, success } = result
    if (!success){
      toast.warn(errors[0])
      return
    }

    toast(strings.toast?.section?.created || 'Quote Section Created.')

    setState({ showCreateOrEditQuoteSectionModal: false })
  })
}

const deleteQuoteItem = (deleteFn, setState, strings) => {
  deleteFn(defaultRequestOptions.quoteItem).then((result) => {
    const { errors, success } = result
    if (!success){
      toast.warn(errors[0])
      return
    }

    toast(strings.toast?.item?.deleted || 'Quote Item Deleted.')

    setState({ showCreateOrEditQuoteItemModal: false })
  })
}

const deleteQuoteSection = (deleteFn, setState, strings) => {
  deleteFn(defaultRequestOptions.quoteItem).then((result) => {
    const { errors, success } = result
    if (!success){
      toast.warn(errors[0])
      return
    }

    toast(strings.toast?.section?.deleted || 'Quote Section Deleted.')

    setState({ showCreateOrEditQuoteSectionModal: false })
  })
}

const saveQuoteAsTemplate = (quoteTemplate, createFn, setState, strings) => {
  createFn(quoteTemplate).then((result) => {
    const { errors, success } = result
    if (!success){
      toast.warn(errors[0])
      return
    }

    toast(strings.toast?.template?.created || 'Quote Template Created.')

    setState({ showCreateOrEditQuoteModal: false })
  })
}

const updateQuote = (quote, updateFn, setState, strings) => {
  updateFn(quote).then((result) => {
    const { errors, success } = result
    if (!success){
      toast.warn(errors[0])
      return
    }

    toast(strings.toast?.updated || 'Quote Updated.')

    setState({ showCreateOrEditQuoteModal: false })
  })
}

const updateQuoteSection = (quoteSection, updateFn, setState, strings) => {
  updateFn(quoteSection).then((result) => {
    const { errors, success } = result
    if (!success){
      toast.warn(errors[0])
      return
    }

    toast(strings.toast?.section?.updated || 'Quote Section Updated.')

    setState({ showCreateOrEditQuoteSectionModal: false })
  })
}

const callbacks = (component, setState, strings) => {
  const componentCallbacks = {
    CreateOrEditQuoteModal: {
      closeModal: () => setState({ showCreateOrEditQuoteModal: false }),
      saveQuoteAsTemplate: (templateParams, createFn) => saveQuoteAsTemplate(templateParams, createFn, setState, strings),
      updateQuote: (params, updateFn) => updateQuote(params, updateFn, setState, strings),
    },
    CreateOrEditQuoteItemModal: {
      closeModal: () => setState({ showCreateOrEditQuoteItemModal: false }),
      createQuoteItem: payload => handleCallbackAction(payload),
      deleteQuoteItem: deleteFn => deleteQuoteItem(deleteFn, setState, strings),
      updateQuoteItem: payload => handleCallbackAction(payload),
    },
    CreateOrEditQuoteSectionModal: {
      closeModal: () => setState({ showCreateOrEditQuoteSectionModal: false }),
      createQuoteSection: (params, createFn) => createQuoteSection(params, createFn, setState, strings),
      deleteQuoteSection: deleteFn => deleteQuoteSection(deleteFn, setState, strings),
      updateQuoteSection: (params, updateFn) => updateQuoteSection(params, updateFn, setState, strings),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  showCreateOrEditQuoteItemModal: false,
  showCreateOrEditQuoteModal: false,
  showCreateOrEditQuoteSectionModal: false,
}

const QuoteBuilder = (props) => {
  const { quoteId } = props

  const [state, setState] = useSetState(defaultState)
  const { showCreateOrEditQuoteItemModal, showCreateOrEditQuoteModal, showCreateOrEditQuoteSectionModal } = state

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const quoteBuilderPayload = useQuoteBuilder({
    callbacks: {
      closeModal: modalKey => setState({ [`show${modalKey}`]: false }),
      showCreateOrEditQuoteItemModal: (payload) => {
        setModalData('CreateOrEditQuoteItemModal', payload)
        setState({ showCreateOrEditQuoteItemModal: true })
      },
      showCreateOrEditQuoteModal: (payload) => {
        setModalData('CreateOrEditQuoteModal', payload)
        setState({ showCreateOrEditQuoteModal: true })
      },
      showCreateOrEditQuoteSectionModal: (payload) => {
        setModalData('CreateOrEditQuoteSectionModal', payload)
        setState({ showCreateOrEditQuoteSectionModal: true })
      },
    },
    quoteId,
  })

  const {
    quotePayload: {
      loading,
      quote: { id },
    },
  } = quoteBuilderPayload

  useIntercom({ hideWidget: true })

  const { strings } = useLocalization(localizedStrings)

  return (
    <ModalContext.Provider value={modalContext}>
      <QuoteBuilderContextProvider value={quoteBuilderPayload}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && <Quote quoteBuilderPayload={quoteBuilderPayload} />}
          </Columns.Main>
        </Columns>

        <CreateOrEditQuoteItemModal
          callbacks={callbacks('CreateOrEditQuoteItemModal', setState, strings)}
          clickSafeZone
          showModal={showCreateOrEditQuoteItemModal}
        />

        <CreateOrEditQuoteModal
          callbacks={callbacks('CreateOrEditQuoteModal', setState, strings)}
          showModal={showCreateOrEditQuoteModal}
        />

        <CreateOrEditQuoteSectionModal
          callbacks={callbacks('CreateOrEditQuoteSectionModal', setState, strings)}
          quoteSection={{ quote_id: id }}
          clickSafeZone
          showModal={showCreateOrEditQuoteSectionModal}
        />
      </QuoteBuilderContextProvider>
    </ModalContext.Provider>
  )
}

QuoteBuilder.propTypes = {
  quoteId: PropTypes.number.isRequired,
}

export default QuoteBuilder
