import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'

import { Box, ListItem, Text } from '@campaignhub/suit-theme'

import type { ReviewModel } from '@models/review'

interface ReviewsProps {
  callbacks: {
    toggleReview:(reviewId: number)=> void,
  },
  reviews: Array<ReviewModel>,
  reviewsUsers: Array<object>,
  showSelectedReviews: boolean,
  userId: number,
}

const Reviews = (props: ReviewsProps) => {
  const {
    callbacks: { toggleReview },
    reviews,
    reviewsUsers,
    showSelectedReviews,
    userId,
  } = props

  return (
    <Box flexDirection="column" maxHeight="80%" style={{ overflowY: 'auto' }}>
      {reviews.map((review) => {
        const { address: { full_address }, reviewer_name, title } = review

        const selectedIndex = reviewsUsers.findIndex(
          reviewObject => reviewObject.review_id === review.id && reviewObject.user_id === userId,
        )
        const selected = selectedIndex !== -1

        if ((showSelectedReviews && selected) || !showSelectedReviews){
          return (
            <ListItem
              boxProps={{
                alignItems: 'center',
                lineHeight: '1.3',
                marginTop: 'large',
              }}
              disableHover
              key={review.id}
            >
              <Box paddingY="medium" marginRight="large" width="auto">
                <Box
                  alignItems="center"
                  backgroundColor={selected ? 'blue' : 'transparent'}
                  border="1px solid"
                  borderColor={selected ? 'blue' : 'lineColor'}
                  borderRadius="100%"
                  height={28}
                  justifyContent="center"
                  onClick={() => toggleReview(review.id)}
                  style={{ cursor: 'pointer' }}
                  width={28}
                >
                  <Text color={selected ? 'white' : 'lineColor'} fontSize="xsmall">
                    {selected ? selectedIndex + 1 : <FontAwesomeIcon icon={faCheck} />}
                  </Text>
                </Box>
              </Box>

              <Box alignItems={['flex-start', 'center']} flexDirection={['column', 'row']} marginLeft="auto">
                <Box flexDirection="column" lineHeight="1.3" marginRight="auto" width="auto">
                  <Text fontSize="small">{full_address}</Text>
                  <Text color="bodyFontLightColor" fontSize="xsmall" variant="twoLineEllipsis">
                    {reviewer_name} | {title}
                  </Text>
                </Box>
              </Box>
            </ListItem>
          )
        }

        return null
      })}
    </Box>
  )
}

export default Reviews
