import React from 'react'
import PropTypes from 'prop-types'

const BarGraphIcon = (props) => {
  const { fillColor, height, width } = props

  return (
    <svg width={width} height={height} viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 13.75H19.6875C19.8438 13.75 20 13.9062 20 14.0625V14.6875C20 14.8828 19.8438 15 19.6875 15H0.625C0.273438 15 0 14.7266 0 14.375V0.3125C0 0.15625 0.117188 0 0.3125 0H0.9375C1.09375 0 1.25 0.15625 1.25 0.3125V13.75ZM6.25 8.59314V7.8125C6.25 7.65625 6.09375 7.5 5.9375 7.5H5.3125C5.11719 7.5 5 7.65625 5 7.8125V9.31482L3.25983 10.3195C3.11633 10.4023 3.06717 10.5858 3.14999 10.7292L3.45001 11.2489C3.53284 11.3923 3.71631 11.4415 3.8598 11.3586L19.7418 2.18921C19.8853 2.10645 19.9344 1.92285 19.8516 1.77942L19.5516 1.25977C19.4688 1.11633 19.2853 1.06714 19.1418 1.15002L17.5 2.0979V1.5625C17.5 1.40625 17.3438 1.25 17.1875 1.25H16.5625C16.3672 1.25 16.25 1.40625 16.25 1.5625V2.81958L13.7424 4.26733C13.7094 4.13367 13.5735 4.01538 13.4375 4.01538H12.8125C12.6172 4.01538 12.5 4.17163 12.5 4.32788V4.98462L10 6.42798V2.8125C10 2.65625 9.84375 2.5 9.6875 2.5H9.0625C8.86719 2.5 8.75 2.65625 8.75 2.8125V7.14966L6.25 8.59314ZM8.75 10.4641V10.9375C8.75 11.1328 8.86719 11.25 9.0625 11.25H9.6875C9.84375 11.25 10 11.1328 10 10.9375V9.74243L8.75 10.4641ZM12.5 10.9375V8.29907L13.75 7.57739V10.9375C13.75 11.1328 13.5938 11.25 13.4375 11.25H12.8125C12.6172 11.25 12.5 11.1328 12.5 10.9375ZM16.5625 11.25H17.1875C17.3438 11.25 17.5 11.1328 17.5 10.9375V5.41223L16.25 6.13403V10.9375C16.25 11.1328 16.3672 11.25 16.5625 11.25Z"
        fill={fillColor}
      />
    </svg>
  )
}

BarGraphIcon.propTypes = {
  fillColor: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
}

BarGraphIcon.defaultProps = {
  fillColor: '#000000',
  height: 40,
  width: 40,
}

export default BarGraphIcon
