import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { useSetState } from '@campaignhub/react-hooks'

import FieldHolder from '@components/FieldHolder'

const setupState = (props, setState) => {
  const {
    entityId, propertyKey, data, version,
  } = props

  setState({
    entityId,
    key: data.key,
    value: data[propertyKey] || '',
    version: version || '',
  })
}

const handleUpdate = (value, props) => {
  const { parse, propertyKey, updateCallback } = props

  const parsedValue = parse ? parse(value) : value
  updateCallback(propertyKey, parsedValue)
}

const renderWrappedInput = (children, props) => {
  const {
    boxProps, halfWidth, last, label, wrapInput,
  } = props

  if (wrapInput){
    return (
      <FieldHolder boxProps={boxProps} halfWidth={halfWidth} last={last} label={label}>
        {children}
      </FieldHolder>
    )
  }

  return children
}

const defaultState = {}

const Input = (props) => {
  const {
    data, entityId, inputMinValue, inputType, placeholder, style, textarea, version,
  } = props

  const { key } = data || {}

  const [state, setState] = useSetState(defaultState)
  const { value } = state

  useEffect(() => {
    setupState(props, setState)
  }, [entityId, key, version])

  return renderWrappedInput(
    textarea ? (
      <textarea
        onBlur={e => handleUpdate(e.target.value, props)}
        onChange={e => setState({ value: e.target.value })}
        placeholder={placeholder}
        style={{ height: 140, ...style }}
        value={value || ''}
      />
    ) : (
      <input
        min={inputMinValue}
        onBlur={e => handleUpdate(e.target.value, props)}
        onChange={e => setState({ value: e.target.value })}
        placeholder={placeholder}
        style={style}
        type={inputType || 'text'}
        value={value || ''}
      />
    ),
    props,
  )
}

Input.propTypes = {
  halfWidth: PropTypes.bool,
  inputType: PropTypes.string,
  label: PropTypes.string,
  last: PropTypes.bool,
  parse: PropTypes.func,
  placeholder: PropTypes.string,
  propertyKey: PropTypes.string.isRequired,
  style: PropTypes.object,
  textarea: PropTypes.bool,
  updateCallback: PropTypes.func.isRequired,
  version: PropTypes.string,
  wrapInput: PropTypes.bool,
}

Input.defaultProps = {
  data: {},
  wrapInput: true,
  version: '',
}

export default Input
