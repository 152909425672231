import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/pro-light-svg-icons'

import {
  useDebounce, useSetState,
} from '@campaignhub/react-hooks'

import { digObject } from '@campaignhub/javascript-utils'

import { InputSearch, UserProfileImage } from '@campaignhub/suit-theme'

import filterUnselectedEntities from '@functions/filterUnselectedEntities'
import { notificationMethodKeysForGroup } from '@functions/notification'

import useLocalization from '@hooks/useLocalization'
import useOrganizationSelector from '@hooks/useOrganizationSelector'
import useReduxAction from '@hooks/useReduxAction'

import localizedStrings from './localizedStrings'

const getFilteredRecipientUsers = (users, key, notificationMethodKeys) => {
  const filteredRecipientUsers = users?.filter((user) => {
    const disabledNotificationMethodKeys = digObject(user, `options.disabled_notifications.${key}`, [])

    return disabledNotificationMethodKeys.length !== notificationMethodKeys.length
  })

  return filteredRecipientUsers
}
const mapResults = (props, images, contacts, teams, users, disabled, strings) => {
  const { shouldSearchContacts, shouldSearchTeams, shouldSearchUsers } = props
  const groups = []

  if (shouldSearchContacts && contacts){
    const contactGroup = {
      key: 'contacts',
      title: strings.contactsTitle || 'CONTACTS',
      items: disabled
        ? []
        : contacts.map(contact => ({
          iconLeft: (
            <UserProfileImage>
              <FontAwesomeIcon icon={faUser} />
            </UserProfileImage>
          ),
          id: contact.id,
          key: `Contact-${contact.id}`,
          secondaryText: contact.mobile,
          text: contact.full_name,
          type: 'Contact',
        })),
    }

    groups.push(contactGroup)
  }

  if (shouldSearchTeams && teams){
    const teamGroup = {
      key: 'teams',
      title: strings.teamsTitle || 'TEAMS',
      items: disabled
        ? []
        : teams.map((team) => {
          const image = images[team.default_image_id]
          const imageUrl = digObject(image, 'sizes.thumb_90x90', '')

          return {
            iconLeft: (
              <UserProfileImage imageUrl={imageUrl}>
                <FontAwesomeIcon icon={faUser} />
              </UserProfileImage>
            ),
            id: team.id,
            key: `Team-${team.id}`,
            text: team.title,
            type: 'Team',
          }
        }),
    }

    groups.push(teamGroup)
  }

  if (shouldSearchUsers && users){
    const userGroup = {
      key: 'users',
      title: strings.usersTitle || 'USERS',
      items: disabled
        ? []
        : users.map((user) => {
          const image = images[user.default_image_id]
          const imageUrl = digObject(image, 'sizes.thumb_90x90', '')

          return {
            iconLeft: (
              <UserProfileImage imageUrl={imageUrl}>
                <FontAwesomeIcon icon={faUser} />
              </UserProfileImage>
            ),
            id: user.id,
            key: `User-${user.id}`,
            imageUrl,
            secondaryText: `${user.mobile} - ID #${user.id}`,
            text: user.full_name,
            type: 'User',
          }
        }),
    }

    groups.push(userGroup)
  }

  return { groups }
}

const teamOptions = {
  include_image_sizes: ['thumb_90x90', 'thumb_660x360'],
  include_team_default_image: true,
}

const userOptions = {
  include_image_sizes: ['thumb_90x90', 'thumb_660x360'],
  include_user_default_image: true,
  include_user_organization_ids: true,
  include_user_role: true,
}

const defaultState = {
  string: '',
}

const FilterUsersContactsAndTeams = (props) => {
  const {
    boxProps,
    callbacks,
    disabled,
    disabledText,
    forwardProps,
    isNotificationRecipient,
    placeholder,
    projectId,
    requestOptions,
    selectedContactIds,
    selectedTeamIds,
    selectedUserIds,
    shouldSearchContacts,
    shouldSearchTeams,
    shouldShowOnFocus,
  } = props

  const entityKey = `Project${projectId}`

  useReduxAction(
    'contacts',
    'loadContacts',
    {
      entityKey,
      project_id: projectId,
      ...requestOptions,
    },
    [projectId],
    {
      shouldPerformFn:
        ({ loadedForKeys }) => shouldSearchContacts && projectId && !loadedForKeys.includes(entityKey),
    },
  )

  useReduxAction('teams', 'loadTeams', { ...teamOptions, ...requestOptions }, [], {
    shouldPerformFn: (entityReducer) => {
      const { loaded } = entityReducer
      return shouldSearchTeams && !loaded
    },
  })

  const [state, setState] = useSetState(defaultState)
  const { string } = state

  const { selectedOrganization } = useOrganizationSelector()

  const debouncedString = useDebounce(string, 300)

  useReduxAction('users', 'loadUsers', {
    organization_id: selectedOrganization?.id,
    string: debouncedString,
    ...userOptions,
  }, [debouncedString], {
    shouldPerformFn:
    ({ loadedForKeys }) => !loadedForKeys.includes(entityKey) || debouncedString || isNotificationRecipient,
  })

  const entities = useSelector(reduxState => reduxState.entities)
  const { images } = entities

  const unselectedContacts = filterUnselectedEntities(entities, 'contacts', selectedContactIds)
  const filteredTeams = filterUnselectedEntities(entities, 'teams', selectedTeamIds)
  const unselectedUsers = filterUnselectedEntities(entities, 'users', selectedUserIds)

  const filteredOrganizationUsers = selectedOrganization?.id
    ? unselectedUsers?.filter(user => user?.organization_ids?.includes(Number(selectedOrganization.id)))
    : unselectedUsers

  const notificationMethodKeys = notificationMethodKeysForGroup('shareLinks', 'share_link_viewed')

  const filteredRecipientUsers = getFilteredRecipientUsers(
    filteredOrganizationUsers,
    'share_link_viewed',
    notificationMethodKeys,
  )

  const filteredUsers = isNotificationRecipient ? filteredRecipientUsers : filteredOrganizationUsers

  const filteredContacts = projectId
    ? unselectedContacts.filter(contact => contact?.project_ids?.includes(Number(projectId)))
    : unselectedContacts

  const { strings } = useLocalization(localizedStrings)

  return (
    <InputSearch
      boxProps={boxProps}
      callbacks={callbacks}
      closeOnSelect
      customBlankStateText={disabled ? disabledText : null}
      disabled={disabled}
      placeholder={placeholder}
      results={mapResults(props, images, filteredContacts, filteredTeams, filteredUsers, disabled, strings)}
      showOnFocus={shouldShowOnFocus}
      {...forwardProps}
      value={string}
      onChange={e => setState({ string: e.target.value })}
    />
  )
}

FilterUsersContactsAndTeams.defaultProps = {
  isNotificationRecipient: false,
  shouldShowOnFocus: true,
  selectedContactIds: [],
  selectedTeamIds: [],
  selectedUserIds: [],
  shouldSearchUsers: true,
}

FilterUsersContactsAndTeams.propTypes = {
  boxProps: PropTypes.object,
  callbacks: PropTypes.shape({
    selectItem: PropTypes.func.isRequired,
  }).isRequired,
  disableRemoveSelf: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledText: PropTypes.string,
  forwardProps: PropTypes.object,
  isNotificationRecipient: PropTypes.bool,
  placeholder: PropTypes.string,
  projectId: PropTypes.number,
  requestOptions: PropTypes.object,
  selectedContactIds: PropTypes.array,
  selectedTeamIds: PropTypes.array,
  selectedUserIds: PropTypes.array,
  shouldSearchContacts: PropTypes.bool,
  shouldSearchTeams: PropTypes.bool,
  shouldSearchUsers: PropTypes.bool,
  shouldShowOnFocus: PropTypes.bool,
}

export default FilterUsersContactsAndTeams
