import PropTypes from 'prop-types'

import {
  Button,
  FormField,
  Link,
  LoadingModule,
  SectionDivider,
  SidebarModal,
  SidebarNoticeBox,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import IntegrationsBlankState from '@components/IntegrationsBlankState'

import CaseStudy from './components/CaseStudy'
import localizedStrings from './localizedStrings'
import useImportCaseStudies from './hooks/useImportCaseStudies.js'

const ImportCaseStudiesModal = (props) => {
  const { callbacks, modalKey, showModal } = props
  const { closeModal, importCaseStudies } = callbacks

  const {
    alreadyImportedExternalIds,
    callbacks: {
      bulkImportFromExternalPlatform,
      selectOrganizationId,
      setState,
      toggleAllCaseStudies,
      toggleCaseStudy,
    },
    externalCaseStudies,
    filteredPlatforms,
    hasExternalCaseStudies,
    hasIntegrations,
    hasSelectedCaseStudies,
    integrationsUrls: {
      integrationsUrl,
    },
    loading,
    selectedIntegrationPlatform,
    selectedIntegrationPlatformKey,
    selectedOrganization,
    selectedCaseStudyIds,
    sortedOrganizationsWhereAdmin,
    string,
  } = useImportCaseStudies()

  const { callbacks: { formatString }, strings } = useLocalization(localizedStrings)

  const importCaseStudiesPayload = {
    callbacks: {
      action: bulkImportFromExternalPlatform,
      afterAction: closeModal,
    },
    entityParams: { externalIds: selectedCaseStudyIds, organizationId: selectedOrganization?.id },
    toastText: strings.toast?.importCaseStudies || 'Import Case Studies Queued',
  }

  return (
    <SidebarModal
      callbacks={callbacks}
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.title || 'Import'}
        titleSecondLine={strings.titleSecondLine || 'Case Studies'}
      />

      <SidebarModal.Content>
        <LoadingModule loading={loading && !hasIntegrations} />

        {!loading && !hasIntegrations && (
          <IntegrationsBlankState
            buttonUrl={integrationsUrl}
            paragraph={
              strings?.blankState?.paragraph
              || 'To import case studies from another platform you’ll need to set up a suitable integration. Integrations can be managed in your client admin.'
            }
            sidebar
          />
        )}

        {hasIntegrations && (
          <>
            <FormField direction="column" label={strings.importCaseStudiesIntoLabel || 'Importing Case Studies into'}>
              <select
                data-validate-field-on="change"
                name="selected_organization_id"
                onChange={e => selectOrganizationId(e.target.value)}
                value={selectedOrganization.id}
              >
                <option value="">{strings.formFieldOption || 'Please Select...'}</option>
                {sortedOrganizationsWhereAdmin.map(organization => (
                  <option key={organization.id} value={organization.id}>
                    {organization.title}
                  </option>
                ))}
              </select>
            </FormField>

            <FormField direction="column" label={strings.platformLabel || 'Platform'} marginTop="large">
              <select
                data-validate-field-on="change"
                onChange={e => setState({ selectedIntegrationPlatformKey: e.target.value })}
                value={selectedIntegrationPlatformKey}
              >
                <option value="">{strings.options || 'Please Select...'}</option>
                {filteredPlatforms.map(entity => (
                  <option key={entity.id} value={entity.key}>
                    {entity.title}
                  </option>
                ))}
              </select>
            </FormField>

            <FormField direction="column" label={strings.searchCaseStudiesLabel || 'Search Case Studies'} marginTop="large">
              <input onChange={e => setState({ string: e.target.value })} value={string} />
            </FormField>

            {/* Loading External Case Studies */}
            <LoadingModule boxProps={{ marginTop: 'large' }} loading={loading} />

            {!loading && <SectionDivider boxProps={{ marginTop: 'large' }}>{strings.availableCaseStudies || 'Available Case Studies'}</SectionDivider>}

            {!loading && !hasExternalCaseStudies && (
              <SidebarNoticeBox boxProps={{ marginTop: 'large' }}>
                <SidebarNoticeBox.Title>
                  {strings.noticeBox?.title || 'Nothing to Import'}
                </SidebarNoticeBox.Title>

                <SidebarNoticeBox.Paragraph>
                  {formatString(strings.noticeBox?.paragraph, {
                    integrationPlatformTitle: selectedIntegrationPlatform.title || 'External Platform',
                  })}
                </SidebarNoticeBox.Paragraph>
              </SidebarNoticeBox>
            )}

            {!loading && hasExternalCaseStudies && (
              <FormField
                direction="column"
                label={strings.selectCaseStudiesLabel || 'Select Case Studies'}
                labelRight={(
                  <Link onClick={toggleAllCaseStudies} textProps={{ fontSize: 'xsmall' }}>
                    {hasSelectedCaseStudies
                      ? strings.deselectAllButton || 'Deselect All'
                      : strings.selectAllButton || 'Select All'}
                  </Link>
                )}
                marginTop="large"
              >
                {externalCaseStudies.map((caseStudy) => {
                  const { id } = caseStudy

                  return (
                    <CaseStudy
                      alreadyImported={alreadyImportedExternalIds.includes(id)}
                      callbacks={{ toggleCaseStudy: () => toggleCaseStudy(id) }}
                      caseStudy={caseStudy}
                      checked={selectedCaseStudyIds.includes(id)}
                      key={id}
                    />
                  )
                })}
              </FormField>
            )}
          </>
        )}
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!hasSelectedCaseStudies}
          onClick={() => importCaseStudies(importCaseStudiesPayload)}
          size="large"
        >
          {strings.importCaseStudiesButton || 'Import Case Studies'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

ImportCaseStudiesModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

ImportCaseStudiesModal.defaultProps = {
  modalKey: 'ImportCaseStudiesModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <ImportCaseStudiesModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
