export default {
  default: {
    events: {
      none: '0 Events | ',
      one: '1 Event | ',
      many: '{count} Events | ',
      type: 'count',
    },
    eventGroups: {
      none: '0 Weeks',
      one: '1 Week',
      many: '{count} Weeks',
      type: 'count',
    },
    editEventCalendarTemplate: 'Edit Event Calendar Template',
    permissions: 'Permissions',
  },
}
