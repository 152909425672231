import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'
import { toast } from 'react-toastify'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBan, faCog, faDownload, faEye, faHistory, faPencil, faUsers,
} from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, IconWithMenu, Link, ListItem, StatusBadge, Text,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import { statusColor } from '@functions/status'

import useAgreement, { useRelations } from '@hooks/useAgreement'
import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const confirmVoidAgreement = (voidCallback, voidFn, strings) => {
  swal
    .fire({
      confirmButtonColor: '#DD6B55',
      confirmButtonText: strings.swal?.confirmButtonText || 'Yes, void it.',
      icon: 'warning',
      input: 'text',
      inputPlaceholder: strings.swal?.inputPlaceholder || 'Please add reason for voiding',
      inputValidator: (value) => {
        if (!value){
          return strings.swal?.inputPlaceholder || 'Please add reason for voiding.'
        }

        return null
      },
      showCancelButton: true,
      text:
        strings.swal?.text || 'Voiding the agreement will notify signers and will require you to resend the document.',
      title: strings.swal?.title || 'Void Agreement?',
    })
    .then(({ value }) => {
      if (value){
        voidCallback(value, voidFn)

        toast(strings.toast?.agreement?.voided || 'Agreement Voided.')
      }
    })
}

const Agreement = (props) => {
  const { agreement } = props

  const agreementPayload = useAgreement(agreement)
  const {
    agreement: { title, urls },
    callbacks: { viewAgreementEvents, viewAgreementProgress, voidExternalAgreement: voidFn },
    filteredRecipients,
    isPending,
    isSyncedWithElectronicSigningPlatform,
    urls: { editAgreementUrl },
  } = agreementPayload

  const { external_pdf: externalPdfUrl } = urls || {}

  const { status } = useRelations(agreement)

  const recipientCount = filteredRecipients.length

  const pageContext = useContext(PageContext)
  const {
    callbacks: { voidExternalAgreement: voidCallback },
  } = pageContext

  const {
    callbacks: { formatCount },
    strings,
  } = useLocalization(localizedStrings)

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        borderBottom: '1px dashed',
        padding: 'large',
      }}
      disableHover
    >
      <Box flexDirection="column">
        <Text fontSize="small" marginBottom="medium">
          {title}
        </Text>

        <Text color="bodyFontLightColor" fontSize="xsmall" marginBottom="medium">
          <FontAwesomeIcon icon={faUsers} style={{ marginRight: 5 }} />
          {formatCount('recipients', { count: recipientCount })}
        </Text>

        <StatusBadge color={statusColor(status.key)} ghost text={status.title} />
      </Box>

      <Box marginRight="medium" width="auto">
        <Button
          as={isPending ? 'a' : null}
          buttonStyle="secondaryEdit"
          href={isPending ? editAgreementUrl : null}
          onClick={isPending ? null : viewAgreementProgress}
          icon={<FontAwesomeIcon icon={isPending ? faPencil : faEye} />}
          size="medium"
          width="auto"
        >
          {isPending ? strings.buttons?.edit || 'Edit' : strings.buttons?.view || 'View'}
        </Button>

        {isSyncedWithElectronicSigningPlatform && (
          <IconWithMenu
            icon={(
              <Button
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faCog} />}
                marginLeft="medium"
                size="medium"
                style={{ height: 37 }}
                width="auto"
              />
          )}
            style={{ width: 'auto' }}
          >
            <IconWithMenu.Menu closeOnClick listStyle={{ right: '-7px' }}>
              <Link key="history" onClick={() => viewAgreementEvents()}>
                <FontAwesomeIcon icon={faHistory} /> {strings.iconWithMenu?.history || 'View History'}
              </Link>

              {!!externalPdfUrl && (
                <Link key="pdf" href={externalPdfUrl}>
                  <FontAwesomeIcon icon={faDownload} /> {strings.iconWithMenu?.pdf || 'Download Agreement'}
                </Link>
              )}

              {!isPending && (
                <Link key="void" onClick={() => confirmVoidAgreement(voidCallback, voidFn, strings)}>
                  <FontAwesomeIcon icon={faBan} /> {strings.iconWithMenu?.void || 'Void Agreement'}
                </Link>
              )}
            </IconWithMenu.Menu>
          </IconWithMenu>
        )}
      </Box>
    </ListItem>
  )
}

Agreement.propTypes = {
  agreement: PropTypes.object.isRequired,
}

export default Agreement
