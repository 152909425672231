export default {
  default: {
    buttonText: 'Manually Set Property Match Id',
    label: 'Manual ID Match',
    placeholder: 'E.g. AAX2101',
    swal: {
      title: 'Manually Set Property Match?',
      text: 'Are you sure? You are manually setting or overriding the {title} property match id. This can be undone by clicking the pencil icon near the top of the page.',
      confirm: 'Yes, Set Property Match Id.',
    },
  },
}
