import { useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  Box, Button, Checkbox, FormField, ListItem, LoadingModule, SidebarModal,
} from '@campaignhub/suit-theme'

import { snakeToTitleCase } from '@campaignhub/javascript-utils'

import useDigitalPage from '@hooks/useDigitalPage'
import useLocalization from '@hooks/useLocalization'

import EntitySelector from '@components/EntitySelector'

import useDuplicateDigitalPage from './hooks/useDuplicateDigitalPage'

import DigitalPageListBlankState from './components/DigitalPageListBlankState'
import DigitalPageListItem from './components/DigitalPageListItem'

import localizedStrings from './localizedStrings'

const DuplicateDigitalPageModal = (props) => {
  const {
    callbacks, modalKey, projectId, showModal,
  } = props

  const { duplicateDigitalPage } = callbacks || {}

  const {
    entityKeys,
    filteredDigitalPages,
    loadingDigitalPages,
    selectedEntityKeys,
    sourceDigitalPage,
    sourceDigitalPageId,
    sourceProjectId,
    targetProjectId,
    callbacks: { setState, toggleAllEntityKeys, toggleEntityKey },
  } = useDuplicateDigitalPage()

  const digitalPagePayload = useDigitalPage({ id: sourceDigitalPageId })
  const {
    creating,
    callbacks: { duplicateDigitalPage: duplicateFn },
  } = digitalPagePayload

  useEffect(() => {
    setState({ targetProjectId: projectId })
  }, [])

  useEffect(() => {
    if (filteredDigitalPages?.length === 1 && !!sourceProjectId){
      setState({ sourceDigitalPageId: filteredDigitalPages[0]?.id })
    }
  }, [filteredDigitalPages.length, sourceProjectId])

  const { strings } = useLocalization(localizedStrings)

  const labelString = sourceDigitalPageId
    ? strings.selectedDigitalPages || 'Selected Digital Page'
    : strings.selectDigitalPages || 'Select Digital Page'

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal}>
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.sideBarModalHeader?.title || 'Duplicate'}
        titleSecondLine={strings.sideBarModalHeader?.titleSecondLine || 'Digital Page'}
      />

      <SidebarModal.Content hasFooter={false}>
        <Box flexDirection="column">
          <EntitySelector
            boxProps={{ marginBottom: 'large' }}
            callbacks={{
              selectItem: project => setState({
                sourceProjectId: project ? project.id : null,
                sourceDigitalPageId: null,
              }),
            }}
            entityKey="projects"
            selectedItemId={sourceProjectId}
          />

          <LoadingModule loading={loadingDigitalPages} times={3} />

          {!loadingDigitalPages && !!sourceProjectId && (
            <FormField direction="column" label={filteredDigitalPages.length ? labelString : ''}>
              {!!sourceDigitalPageId && (
                <DigitalPageListItem
                  callbacks={{ selectDigitalPage: () => setState({ sourceDigitalPageId: null }) }}
                  digitalPage={sourceDigitalPage}
                  key={sourceDigitalPage.id}
                  sourceDigitalPageId={sourceDigitalPageId}
                />
              )}

              {!sourceDigitalPageId && !filteredDigitalPages.length && <DigitalPageListBlankState />}

              {!sourceDigitalPageId
                && filteredDigitalPages.map(digitalPage => (
                  <DigitalPageListItem
                    callbacks={{ selectDigitalPage: () => setState({ sourceDigitalPageId: digitalPage.id }) }}
                    digitalPage={digitalPage}
                    key={digitalPage.id}
                    sourceDigitalPageId={sourceDigitalPageId}
                  />
                ))}
            </FormField>
          )}

          {!!sourceDigitalPageId && (
            <FormField
              marginTop="large"
              direction="column"
              label={strings.selectedEntities || 'Select Items to Duplicate'}
            >
              {entityKeys.map(entityKey => (
                <ListItem.WithCheckbox
                  boxProps={{ border: 'none' }}
                  checkbox={(
                    <Checkbox
                      checked={selectedEntityKeys.includes(entityKey)}
                      onClick={() => toggleEntityKey(entityKey)}
                    />
                  )}
                  key={entityKey}
                  onClick={() => toggleEntityKey(entityKey)}
                >
                  <Box color="bodyFontLightColor" fontSize="small">
                    {snakeToTitleCase(entityKey)}
                  </Box>
                </ListItem.WithCheckbox>
              ))}

              <FormField direction="column" label={strings.moreOptions || 'More Options'} marginTop="large">
                <Box flexDirection="column" flexShrink={0}>
                  <Button
                    buttonStyle="secondaryUtility"
                    marginBottom="small"
                    onClick={() => toggleAllEntityKeys()}
                    size="medium"
                  >
                    {strings.ToggleAll || 'Toggle All'}
                  </Button>
                </Box>
              </FormField>
            </FormField>
          )}
        </Box>
      </SidebarModal.Content>

      {!!sourceDigitalPageId && (
        <SidebarModal.Footer>
          <Button
            buttonStyle={creating ? 'disabled' : 'primaryCreate'}
            loading={creating}
            onClick={() => duplicateDigitalPage(
              {
                selectedEntityKeys,
                sourceDigitalPageId,
                targetProjectId,
              },
              duplicateFn,
            )}
            size="large"
          >
            {strings.buttonText?.create || 'Create Proposal'}
          </Button>
        </SidebarModal.Footer>
      )}

    </SidebarModal>
  )
}

DuplicateDigitalPageModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  projectId: PropTypes.number.isRequired,
  showModal: PropTypes.bool,
}

DuplicateDigitalPageModal.defaultProps = {
  modalKey: 'DuplicateDigitalPageModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <DuplicateDigitalPageModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
