export default {
  default: {
    toast: {
      item: {
        created: 'Quote Item Created.',
        deleted: 'Quote Item Deleted.',
        updated: 'Quote Item Updated.',
      },
      section: {
        created: 'Quote Section Created.',
        deleted: 'Quote Section Deleted.',
        updated: 'Quote Section Updated.',
      },
      template: {
        created: 'Quote Template Created.',
      },
      updated: 'Quote Updated.',
    },
  },
}
