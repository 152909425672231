import React from 'react'
import PropTypes from 'prop-types'

import PageContext from '@contexts/pageContext'

const BuilderContextProvider = (props) => {
  const { children, value } = props

  return (
    <PageContext.Provider value={value}>
      {children}
    </PageContext.Provider>
  )
}

BuilderContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.object.isRequired,
}

export default BuilderContextProvider
