import React from 'react'
import PropTypes from 'prop-types'

import useDataLoopAutoscaling from '@hooks/useDataLoopAutoscaling'

import Section from '../Section'

const AutoscaledDataLoopSections = (props) => {
  const { section: initSection, ...restProps } = props

  const dataLoopAutoscalingPayload = useDataLoopAutoscaling(initSection)
  const { autoscaledSections } = dataLoopAutoscalingPayload

  if (!autoscaledSections.length){
    return null
  }

  return autoscaledSections.map(section => (
    <Section key={section.id} section={section} {...restProps} />
  ))
}

AutoscaledDataLoopSections.propTypes = {
  section: PropTypes.object.isRequired,
}

export default AutoscaledDataLoopSections
