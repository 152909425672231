import { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'

import swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faTrashAlt } from '@fortawesome/pro-solid-svg-icons'

import {
  Box, Button, FormField, Link, SidebarModal, Text,
} from '@campaignhub/suit-theme'

import { useSetState } from '@campaignhub/react-hooks'

import { digObject, toggleArray } from '@campaignhub/javascript-utils'

import PageContext from '@contexts/pageContext'

import EntitySelector from '@components/EntitySelector'

import { defaultRequestOptions } from '@hooks/useDigitalTemplateEditor'

const confirmDeletion = (params) => {
  const { componentStyle, requestOptions, removeFn } = params
  swal
    .fire({
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Yes, delete them.',
      icon: 'warning',
      showCancelButton: true,
      text: 'Are you sure?',
      title: 'Delete Component Style?',
    })
    .then(({ value }) => {
      if (value){
        removeFn(componentStyle, requestOptions)
      }
    })
}

const updateDigitalTemplateComponentIds = (component, props) => {
  const {
    digitalTemplatePayload: { callbacks, digitalTemplate },
  } = props
  const { updateDigitalTemplate } = callbacks || {}

  const digitalTemplateComponentIds = digObject(digitalTemplate, 'digital_template_component_ids', [])
  const updatedComponentIds = toggleArray(digitalTemplateComponentIds, component.id)
  const updatedTemplate = { id: digitalTemplate.id, digital_template_component_ids: updatedComponentIds }

  return updateDigitalTemplate(updatedTemplate, defaultRequestOptions.digitalTemplate)
}

const defaultState = {
  brand_id: null,
}

const ComponentStyles = (props) => {
  const { digitalTemplatePayload } = props

  const {
    callbacks: { removeComponentStyle: removeFn },
    digitalTemplate,
    componentStyles,
  } = digitalTemplatePayload

  const pageContext = useContext(PageContext)
  const { callbacks: { showCreateOrEditComponentStyleModal } } = pageContext || {}

  const [state, setState] = useSetState(defaultState)
  const { brand_id } = state

  const initDigitalTemplateComponent = {
    brand_id: digitalTemplate.brand_id,
    item_type: 'component_style',
  }

  useEffect(() => {
    if (digitalTemplate.brand_id){
      setState({ brand_id: digitalTemplate.brand_id })
    }
  }, [digitalTemplate.brand_id])

  return (
    <SidebarModal.ExpandableSectionBox label="Component Styles">
      <Box flexDirection="column">
        <EntitySelector
          boxProps={{ marginBottom: 'large' }}
          callbacks={{
            selectItem: brand => setState({ brand_id: brand ? brand.id : null }),
          }}
          entityKey="brands"
          selectedItemId={Number(brand_id)}
        />

        <EntitySelector
          boxProps={{ marginBottom: 'large' }}
          callbacks={{
            selectItem: digitalTemplateComponent => updateDigitalTemplateComponentIds(digitalTemplateComponent, props),
          }}
          entityKey="digitalTemplateComponents"
          endpoint="/digital_template_components"
          showSelectedEntity={false}
          searchRequestOptions={{
            brand_id,
            item_type: 'component_style',
          }}
        />

        {!!componentStyles.length && (
          <FormField label="Current Styles">
            <Box flexDirection="column" marginBottom="medium">
              {componentStyles.map(componentStyle => (
                <Box
                  alignItems="center"
                  color="bodyFontColor"
                  fontSize="small"
                  key={componentStyle.id}
                  marginBottom="medium"
                  padding="medium"
                  variant="whiteGrey"
                >
                  <Text color="bodyFontLightColor" variant="twoLineEllipsis">
                    {componentStyle.title}
                  </Text>

                  <Box marginLeft="auto" width="auto">
                    <Link
                      greyLink
                      onClick={() => showCreateOrEditComponentStyleModal({ digitalTemplateComponent: componentStyle })}
                      textProps={{ marginRight: 'medium' }}
                    >
                      <FontAwesomeIcon icon={faPencil} />
                    </Link>

                    <Link
                      greyLink
                      onClick={() => confirmDeletion({ componentStyle, requestOptions: defaultRequestOptions.digitalTemplate, removeFn })}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </Link>
                  </Box>
                </Box>
              ))}
            </Box>
          </FormField>
        )}

        <Box flexDirection="column">
          <Button
            buttonStyle="ghostCreate"
            onClick={() => showCreateOrEditComponentStyleModal({
              digitalTemplateComponent: initDigitalTemplateComponent,
            })}
            size="medium"
            style={{ width: 'auto' }}
          >
            Create Component Style
          </Button>
        </Box>
      </Box>
    </SidebarModal.ExpandableSectionBox>
  )
}

ComponentStyles.propTypes = {
  digitalTemplatePayload: PropTypes.object.isRequired,
}

export default ComponentStyles
