import PropTypes from 'prop-types'

import {
  Box, Image, ListItem, Text,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useTargetAudience from '@hooks/useTargetAudience'

import SelectableIcon from '@components/SelectableIcon'

import placeHolderImage from './assets/placeholderImage.png'
import placeholderImageUrl from './assets/default_campaign_image_400x400.png'

const TargetAudience = (props) => {
  const {
    callbacks: { toggleTargetAudience },
    isSelected,
    selectedIndex,
    targetAudience,
    targetAudience: { title, description },
  } = props

  const { defaultImage } = useTargetAudience(targetAudience)
  const defaultImageUrl = digObject(defaultImage, 'sizes.thumb_200x120', '')

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        lineHeight: '1.3',
        marginTop: 'large',
      }}
      disableHover
      key={targetAudience.id}
    >
      <Box paddingY="medium" marginRight="large" width="auto">
        <SelectableIcon
          callbacks={{ toggleSelected: () => toggleTargetAudience(targetAudience.id) }}
          isSelected={isSelected}
          selectedIndex={selectedIndex}
        />
      </Box>

      <Box width={100} borderRadius={5} backgroundImage={`url(${placeHolderImage})`} marginRight="large">
        <Image
          borderRadius={5}
          height={60}
          marginRight="medium"
          placeholderUrl={placeholderImageUrl}
          url={defaultImageUrl}
          width={90}
        />
      </Box>

      <Box flexDirection="column" justifyContent="center" minWidth="0">
        <Text fontSize="small">{title}</Text>

        {description && (
          <Text
            color="bodyFontLightColor"
            fontSize="xsmall"
            lineHeight={1.3}
            paddingTop="medium"
            variant="twoLineEllipsis"
          >
            {description}
          </Text>
        )}
      </Box>
    </ListItem>
  )
}

TargetAudience.propTypes = {
  callbacks: PropTypes.shape({
    toggleTargetAudience: PropTypes.func,
  }).isRequired,
  isSelected: PropTypes.bool.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  targetAudience: PropTypes.object.isRequired,
}

export default TargetAudience
