import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, DashboardModule, DynamicInput, FormField,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const Organization = (props) => {
  const { organizations, projectFormPayload } = props

  const { entityState: { organization_id }, setEntityState } = projectFormPayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <DashboardModule title={strings.title}>
      <Box direction="column" padding="large">
        <FormField>
          <DynamicInput
            currentValue={organization_id}
            inputComponent="select"
            name={String(organization_id)}
            required
            values={organizations.map(organization => ({ key: organization.id, label: organization.title }))}
            onChange={e => setEntityState({ organization_id: e.target.value })}
          />
        </FormField>
      </Box>
    </DashboardModule>
  )
}

Organization.propTypes = {
  organizations: PropTypes.array.isRequired,
  projectFormPayload: PropTypes.object.isRequired,
}

export default Organization
