import React from 'react'
import PropTypes from 'prop-types'
import { HashLink as Link } from 'react-router-hash-link'

import useDeviceStyle from '@hooks/useDeviceStyle'

import AnimationWrapper from '@components/digitalRenderer/components/AnimationWrapper'

import styles from './styles.module.scss'

const HashLink = (props) => {
  const {
    children,
    options,
  } = props

  const {
    anchor,
  } = options

  const style = useDeviceStyle(props)

  return (
    <AnimationWrapper options={options}>
      <div
        className={styles.root}
        style={style}
      >
        <Link
          smooth
          to={`#${anchor}`}
        >
          {children}
        </Link>
      </div>
    </AnimationWrapper>
  )
}

HashLink.propTypes = {
  children: PropTypes.node,
  options: PropTypes.object,
}

HashLink.defaultProps = {
  options: {},
}

export default HashLink
