import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { faChartBar } from '@fortawesome/pro-light-svg-icons'

import { Grid, Box } from '@campaignhub/suit-theme'

import { useSetState } from '@campaignhub/react-hooks'

import useChartData from '@hooks/useChartData'
import useLocalization from '@hooks/useLocalization'
import useOrganizationsUser from '@hooks/useOrganizationsUser'

import Averages from './components/Averages'
import StatisticWithGraph from './components/StatisticWithGraph'

import localizedStrings from './localizedStrings'

export const icon = faChartBar

const metricKeys = [
  'average_proposal_read_percentage',
  'average_proposal_seconds_on_page',
  'average_proposals_accepted_per_user',
  'total_proposals_accepted',
  'total_proposals_created',
]

const defaultState = {
  selectedDataPoint: 'you',
}

const Statistics = (props) => {
  const { editing } = props

  const [state, setState] = useSetState(defaultState)
  const { selectedDataPoint } = state

  const { isCurrentUserAdminForOrganization } = useOrganizationsUser()

  const { chartDataKey, loading } = useChartData('benchmark_comparison', {
    metricKeys,
  })

  useEffect(() => {
    if (isCurrentUserAdminForOrganization){
      setState({ selectedDataPoint: 'your_organization' })
    }
  }, [isCurrentUserAdminForOrganization])

  const { strings } = useLocalization(localizedStrings)

  return (
    <Box flexDirection="column" flexShrink="0">
      <Grid
        gridGap="large"
        gridAutoRows="1fr"
        gridTemplateColumns="repeat(auto-fit, minmax(260px, 3fr))"
        gridTemplateRows="repeat(auto-fill, minmax(155px, 1fr))"
      >
        <Averages
          callbacks={{ setState }}
          chartDataKey={chartDataKey}
          editing={editing}
          loading={loading}
          selectedDataPoint={selectedDataPoint}
        />

        <StatisticWithGraph
          chartDataKey={chartDataKey}
          description={strings.proposalsCreated || 'Total Created (Month)'}
          editing={editing}
          metricKey="total_proposals_created"
          selectedDataPoint={selectedDataPoint}
        />

        <StatisticWithGraph
          chartDataKey={chartDataKey}
          description={strings.proposalsAccepted || 'Total Accepted (Month)'}
          editing={editing}
          metricKey="total_proposals_accepted"
          selectedDataPoint={selectedDataPoint}
        />
      </Grid>
    </Box>
  )
}

Statistics.propTypes = {
  editing: PropTypes.bool,
}

export default Statistics
