import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons'

import { BlankState, Button } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import BulkSelectImageUrl from './assets/select-images.svg'
import localizedStrings from './localizedStrings'

const BulkSelectBanner = (props) => {
  const { imagesPayload: { bulkSelecting, callbacks: { saveBulkSelectSort } } } = props

  const { strings } = useLocalization(localizedStrings)

  if (!bulkSelecting){
    return null
  }

  return (
    <BlankState boxProps={{ marginBottom: 'large' }} imageUrl={BulkSelectImageUrl} variant="white">
      <BlankState.Title>{strings.blankStateTitle || 'Click on images to sort'}</BlankState.Title>

      <BlankState.Paragraph>
        {strings.blankStateText || 'You can choose the order in which images are sorted. Click on the images to set the order you’d like them to appear.'}
      </BlankState.Paragraph>

      <BlankState.Actions>
        <Button
          buttonStyle="secondaryUtility"
          icon={<FontAwesomeIcon icon={faCheckCircle} />}
          onClick={() => saveBulkSelectSort()}
          marginBottom="medium"
          size="medium"
        >
          {strings.saveImageSort || 'Save Image Sort'}
        </Button>
      </BlankState.Actions>
    </BlankState>
  )
}

BulkSelectBanner.propTypes = {
  imagesPayload: PropTypes.shape({
    bulkSelecting: PropTypes.bool,
    callbacks: PropTypes.shape({
      saveBulkSelectSort: PropTypes.func.isRequired,
    }).isRequired,
  }).isRequired,
}

export default BulkSelectBanner
