import { useContext, useMemo } from 'react'

import {
  launchModal, matchFilterNumber, matchFilterString, sortArrayBy,
} from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useDispatch from '@hooks/useDispatch'
import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import * as awardActions from '@redux/modules/award'

import type { AwardModel, AwardRequestOptions } from '@models/types'
import type { ModuleState } from '@redux/modules/types'
import type { AppDispatch } from '@redux/store'
import PageContext from '@contexts/pageContext'

const watchEntityKeys = ['awards']

type UpdateAwardSortOrderTypes = {
  awardIds: number[],
  dispatch: AppDispatch,
  requestOptions?: AwardRequestOptions,
}

const updateAwardSortOrder = (params: UpdateAwardSortOrderTypes) => {
  const { awardIds, dispatch, requestOptions } = params
  const { updateAwardSortOrder: updateFn } = awardActions

  return dispatch(updateFn(awardIds, requestOptions))
}

type AwardFilters = {
  owner_id?: number,
  owner_type?: string,
  subject_id?: number,
  subject_type?: string,
}

type UseAwardsOptions = {
  filters?: AwardFilters,
  performHttpRequests?: boolean,
  requestOptions?: AwardRequestOptions,
}

function useAwards(options: UseAwardsOptions) {
  const { filters = {}, requestOptions } = options
  const {
    owner_id: filterOwnerId,
    owner_type: filterOwnerType,
    subject_id: filterSubjectId,
    subject_type: filterSubjectType,
  } = filters

  const {
    updatedEntities: { awards: awardsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { awards } = useSelector(reduxState => reduxState.entities)

  const dispatch = useDispatch()

  const filteredAwards = useMemo(() => {
    const filtered = Object.values(awards).filter((award: AwardModel) => {
      const {
        owner_id, owner_type, subject_id, subject_type,
      } = award

      const ownerIdMatch = filterOwnerId ? matchFilterNumber(filterOwnerId, owner_id) : true
      const ownerTypeMatch = filterOwnerType ? matchFilterString(filterOwnerType, owner_type) : true
      const subjectIdMatch = filterSubjectId ? matchFilterNumber(filterSubjectId, subject_id) : true
      const subjectTypeMatch = filterSubjectType ? matchFilterString(filterSubjectType, subject_type) : true

      return ownerIdMatch && ownerTypeMatch && subjectIdMatch && subjectTypeMatch
    })

    return sortArrayBy(filtered, 'asc', 'sort')
  }, [awardsUpdatedAt, JSON.stringify(filters)])

  const filteredAwardsCount = filteredAwards.length
  const hasFilteredAwards = !!filteredAwardsCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredAwardsCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingAwards } = useReduxAction(
    'awards',
    'loadAwards',
    {
      ...requestOptions,
      ...filtersWithOffset,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  const pageContext = useContext(PageContext)
  const { callbacks } = pageContext

  return {
    callbacks: {
      editAwardSort: () => launchModal({
        callbacks,
        modalKey: 'EditAwardSortModal',
        payload: {
          callbacks: {
            updateAwardSortOrder: (
              awardIds: number[],
              entityOptions?: AwardRequestOptions,
            ) => updateAwardSortOrder({ awardIds, dispatch, requestOptions: entityOptions }),
          },
        },
      }),
      updateAwardSortOrder: (
        awardIds: number[],
        entityOptions?: AwardRequestOptions,
      ) => updateAwardSortOrder({ awardIds, dispatch, requestOptions: entityOptions }),
      loadMore,
    },
    canLoadMore,
    filteredAwards,
    filteredAwardsCount,
    hasAwards: hasFilteredAwards,
    loading: loadingAwards,
  }
}

export default useAwards
