import React from 'react'
import { Route, Routes } from 'react-router-dom'

import EditCustomFieldSet from './screens/EditCustomFieldSet'
import CustomFieldSetList from './screens/CustomFieldSetList'

const CustomFields = () => (
  <Routes>
    <Route index element={<CustomFieldSetList />} />
    <Route path="/:customFieldSetId/edit" element={<EditCustomFieldSet />} />
  </Routes>
)

export default CustomFields
