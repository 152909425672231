import { useEffect, useMemo } from 'react'

import { ModalContext } from '@campaignhub/suit-theme'

import { useModals, useSetState } from '@campaignhub/react-hooks'

import PageContext from '@contexts/pageContext'

import useLocalization from '@hooks/useLocalization'
import useMixpanel from '@hooks/useMixpanel'

import handleCallbackAction from '@functions/handleCallbackAction'

import CreateOrEditAddressModal from '@modals/CreateOrEditAddressModal'
import CreateOrEditContactModal from '@modals/CreateOrEditContactModal'

import defaultRequestOptions from '@sections/Client/packs/Dashboard/defaultRequestOptions'

import PageContent from './components/PageContent'

import localizedStrings from './localizedStrings'

const callbacks = (component, setState) => {
  const componentCallbacks = {
    CreateOrEditAddressModal: {
      closeModal: () => setState({ showCreateOrEditAddressModal: false }),
      createAddress: payload => handleCallbackAction({ ...payload, requestOptions: defaultRequestOptions.contact }),
      updateAddress: payload => handleCallbackAction({ ...payload, requestOptions: defaultRequestOptions.contact }),
    },
    CreateOrEditContactModal: {
      closeModal: () => setState({ showCreateOrEditContactModal: false }),
      createContact: payload => handleCallbackAction({ ...payload, requestOptions: defaultRequestOptions.contact }),
      deleteContact: payload => handleCallbackAction({ ...payload, requestOptions: defaultRequestOptions.contact }),
      updateContact: payload => handleCallbackAction({ ...payload, requestOptions: defaultRequestOptions.contact }),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  pageFilters: {},
  showCreateOrEditAddressModal: false,
  showCreateOrEditContactModal: false,
}

const Contacts = () => {
  const [state, setState] = useSetState(defaultState)
  const {
    pageFilters,
    showCreateOrEditAddressModal,
    showCreateOrEditContactModal,
  } = state

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const pageContext = useMemo(
    () => ({
      callbacks: {
        resetFilters: (resetFn) => {
          if (resetFn) resetFn()
          setState({ pageFilters: {} })
        },
        showCreateOrEditAddressModal: (payload) => {
          setModalData('CreateOrEditAddressModal', payload)
          setState({ showCreateOrEditAddressModal: true })
        },
        showCreateOrEditContactModal: (payload) => {
          setModalData('CreateOrEditContactModal', payload)
          setState({ showCreateOrEditContactModal: true })
        },
        updateFilters: (filters) => {
          setState({ pageFilters: filters })
        },
      },
    }),
    [modalContext],
  )

  const { strings } = useLocalization(localizedStrings)

  const { callbacks: { dispatchMixpanelEvent } } = useMixpanel()

  useEffect(() => {
    dispatchMixpanelEvent('Contacts Load')
  }, [])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent pageFilters={pageFilters} />

        <CreateOrEditAddressModal
          callbacks={callbacks('CreateOrEditAddressModal', setState)}
          headerTitle={strings.addressModalHeaderTitle || 'Contact'}
          hiddenFields={['title']}
          showModal={showCreateOrEditAddressModal}
        />

        <CreateOrEditContactModal
          callbacks={callbacks('CreateOrEditContactModal', setState)}
          showModal={showCreateOrEditContactModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default Contacts
