export default {
  default: {
    formFieldLabels: {
      organization: '* Organisation',
      projectType: '* Project Type',
      searchLabel: '* Search Users and Teams',
      usersLabel: 'Users',
      teamLabel: 'Teams',
    },
    moreOptions: 'More Options',
    importButton: 'Import Project',
    createButton: 'Create Project',
  },
}
