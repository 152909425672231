export default {
  default: {
    filters: {
      address: 'Filter by Address',
      defaultSelectLabel: 'All',
      user: 'User',
    },
    createButton: 'Create',
    archivedTitle: 'Archived',
    addButton: 'Add Review',
    tabTitle: 'Current',
    title: 'Reviews',
    importReviews: 'Import Reviews',
    VideoModule: {
      paragraphOne: 'What Are Reviews and Why They Should Be Used.',
      paragraphTwo: 'Social proof is important. Learn how to add testimonials into your proposals very easily for yourself and across your teams.',
      paragraphThree: 'Have existing testimonials in Realhub? You can easily import them into the new system.',
      titleOne: 'Reviews',
      titleTwo: 'How to create and add testimonials to the proposal',
      titleThree: 'How to import reviews from Realhub',
    },
    filtersTitle: 'Filters',
    tools: 'Tools',
  },
}
