export type ProjectColoursParams = {
  background:string,
  solid: string,
}

export const projectColours: Record<string, ProjectColoursParams> = {
  purple: {
    solid: '#6661BB',
    background: '#F2F4FB',
  },
  red: {
    solid: '#BB6161',
    background: '#FBF3F2',
  },
  orange: {
    solid: '#BB7C61',
    background: '#FBF7F2',
  },
  yellow: {
    solid: '#BBA761',
    background: '#FAFBF2',
  },
  green: {
    solid: '#99BB61',
    background: '#F4FBF2',
  },
  pink: {
    solid: '#A961BB',
    background: '#FBF2FB',
  },
  teal: {
    solid: '#61ABBB',
    background: '#F2F9FB',
  },
  blue: {
    solid: '#6180BB',
    background: '#F2F5FB',
  },
  fuscia: {
    solid: '#8361BB',
    background: '#F7F2FB',
  },
  darkGreen: {
    solid: '#61BB95',
    background: '#F2FBF8',
  },
}

export const projectLetterColorKeys: Record<string, string> = {
  a: 'purple',
  b: 'purple',
  c: 'purple',
  d: 'red',
  e: 'red',
  f: 'red',
  g: 'orange',
  h: 'orange',
  i: 'yellow',
  j: 'yellow',
  k: 'green',
  l: 'green',
  m: 'green',
  n: 'pink',
  o: 'pink',
  p: 'pink',
  q: 'teal',
  r: 'teal',
  s: 'teal',
  t: 'blue',
  u: 'blue',
  v: 'blue',
  w: 'fuscia',
  x: 'fuscia',
  y: 'darkGreen',
  z: 'darkGreen',
}

export default projectColours
