export default {
  default: {
    title: 'Document Template Builder | Engage',
    toasts: {
      recipient: {
        created: 'Recipient Created.',
        updated: 'Recipient Updated.',
        deleted: 'Recipient Deleted and Items Reassigned.',
      },
      field: {
        updated: 'Field Updated.',
        deleted: 'Field Deleted.',
      },
      customField: {
        created: 'Custom Field Created.',
      },
      documentTemplate: {
        updated: 'Document Template Updated.',
        deleted: 'Document Template Deleted.',
      },
    },
  },
}
