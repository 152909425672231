import useReduxAction from '@hooks/useReduxAction'

import { digObject } from '@campaignhub/javascript-utils'

function useSystemManagerLeaderboard(options = {}){
  const {
    digital_template_type_key, leaderboard, limit, month, status_key,
  } = options

  const leaderboardOptions = {
    digital_template_type_key,
    leaderboard,
    month,
    status_key,
  }

  const { data, loading } = useReduxAction('leaderboard', 'fetchLeaderboard', leaderboardOptions, [
    leaderboard,
    month,
    status_key,
  ])

  const result = digObject(data, 'data', [])

  const filteredLeaderboard = limit ? result.slice(0, Number(limit)) : result

  const hasfilteredLeaderboard = !!result.length

  return {
    filteredLeaderboard,
    hasfilteredLeaderboard,
    loading,
  }
}

export default useSystemManagerLeaderboard
