import { useEffect } from 'react'

import { Box } from '@campaignhub/suit-theme'
import { useSetState } from '@campaignhub/react-hooks'

import FieldHolder from '@components/FieldHolder'

const defaultState = {
  key: 'style',
}

interface QuadInputProps {
  boxProps: {},
  entityId: number,
  itemStyle:{
    key: string,
  },
  propertyKeys: string[],
  label: string,
  updateCallback: (name: string, value: string) => void,
}

type SetState = (state: Partial<typeof defaultState>) => void

const setupState = (props: QuadInputProps, setState: SetState) => {
  const { entityId, propertyKeys, itemStyle } = props

  const state = {
    entityId,
    key: itemStyle.key || 'style',
  }

  propertyKeys.forEach((key) => {
    state[key] = itemStyle[key] || ''
  })

  setState(state)
}

const handleUpdate = (key: string, value: string, props: QuadInputProps) => {
  const { updateCallback } = props

  // Allow integers and 'auto'
  const parsedValue = value === 'auto' ? 'auto' : Number(value)
  updateCallback(key, parsedValue)
}

const QuadInput = (props: QuadInputProps) => {
  const {
    boxProps,
    entityId,
    itemStyle: { key },
    propertyKeys,
    label,

  } = props

  const [state, setState] = useSetState(defaultState)

  useEffect(() => {
    setupState(props, setState)
  }, [entityId, key])

  return (
    <FieldHolder label={label}>
      <Box flexDirection="row">
        {propertyKeys.map((propertyKey, index) => {
          const value = state[propertyKey] || ''
          const lastInput = propertyKeys.length === index + 1

          return (
            <Box
              key={propertyKey}
              marginRight={lastInput ? 0 : 5}
              width="calc((100% - 15px)/4)"
              {...boxProps}
            >
              <input
                onBlur={e => handleUpdate(propertyKey, e.target.value, props)}
                onChange={e => setState({ [propertyKey]: e.target.value })}
                placeholder="0"
                type="text"
                value={value}
              />
            </Box>
          )
        })}
      </Box>
    </FieldHolder>
  )
}

export default QuadInput
