import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import {
  Box, Button, FormField, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useDigitalTemplateComponent, { useDigitalTemplateComponentForm } from '@hooks/useDigitalTemplateComponent'

import EntitySelector from '@components/EntitySelector'

const CreateDigitalTemplateComponentModal = (props) => {
  const { callbacks, modalKey, showModal } = props

  const { createDigitalTemplateComponent } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const { digitalTemplate, selectedEntity } = digObject(modalData, `${modalKey}`, {})

  const initDigitalTemplateComponent = {
    item_type: 'component_partial',
    data: {
      component: selectedEntity,
      digital_template_id: digitalTemplate.id,
    },
  }

  const digitalTemplateComponentPayload = useDigitalTemplateComponent(initDigitalTemplateComponent)
  const {
    callbacks: { createDigitalTemplateComponent: createFn },
    creating,
    digitalTemplateComponent,
  } = digitalTemplateComponentPayload

  const digitalTemplateComponentForm = useDigitalTemplateComponentForm(digitalTemplateComponent)
  const {
    entityState,
    entityState: {
      brand_id,
      description,
      title,
    },
    handlers,
    saveEnabled,
    setEntityState,
  } = digitalTemplateComponentForm

  return (
    <SidebarModal
      callbacks={callbacks}
      disableAnimation
      disableOverlay
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header callbacks={callbacks} title="Create" titleSecondLine="Component" />
      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <EntitySelector
            boxProps={{ marginBottom: 'large' }}
            callbacks={{
              selectItem: brand => setEntityState({ brand_id: brand ? brand.id : null }),
            }}
            entityKey="brands"
            selectedItemId={Number(brand_id)}
          />

          <FormField
            direction="column"
            boxProps={{ marginBottom: 'large' }}
            data-validate-field-on="change"
            label="* Title"
          >
            <input name="title" type="text" value={title} {...handlers} />
          </FormField>

          <FormField direction="column" boxProps={{ marginBottom: 'large' }} label="Description">
            <textarea name="description" type="text" value={description} {...handlers} />
          </FormField>
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={creating}
          onClick={() => createDigitalTemplateComponent(entityState, createFn)}
          size="large"
        >
          Create Component
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

CreateDigitalTemplateComponentModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

CreateDigitalTemplateComponentModal.defaultProps = {
  modalKey: 'CreateDigitalTemplateComponentModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <CreateDigitalTemplateComponentModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
