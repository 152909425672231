export default {
  default: {
    title: 'Agreement Builder | Engage',
    toasts: {
      address: {
        created: 'Address Created.',
        updated: 'Address Updated.',
      },
      agreement: {
        deleted: 'Agreement Deleted',
        updated: 'Agreement Updated.',
        sortOrder: 'Sort Order has been updated.',
        sent: 'Agreement Sent for Signing',
      },
      comparable: {
        created: 'Comparable Created.',
        deleted: 'Comparable Deleted.',
        sortUpdated: 'Comparable Sort Updated.',
        updated: 'Comparable Updated.',
      },
      contact: {
        created: 'Contact Created.',
        deleted: 'Contact Deleted.',
        updated: 'Contact Updated.',
      },
      quote: {
        importStarted: 'Quote Import Started!',
        importSuccess: 'External Quote {externalId} Imported Successfully',
        created: 'Quote Created Successfully',
      },
      recipient: {
        created: 'Recipient Created.',
        deleted: 'Recipient Deleted.',
        updated: 'Recipient Updated.',
      },
    },
  },
}
