import { useForm } from '@campaignhub/react-hooks'

const nestedFieldState = {
  key: '',
  label: '',
  input_type: '',
  field_type: '',
  options: {},
}

const requiredFields = [
  { key: 'field_type' },
  { key: 'label' },
  { key: 'key' },
]

export function useNestedCustomFieldForm(nestedField = {}) {
  const nestedFieldForm = useForm(nestedFieldState, { entity: nestedField, requiredFields, validateOn: 'change' }, [
    nestedField.key,
  ])

  return {
    ...nestedFieldForm,
  }
}

function useNestedCustomField(initField = {}) {
  const { key } = initField

  return {
    callbacks: {},
  }
}

export default useNestedCustomField
