import React from 'react'
import PropTypes from 'prop-types'

import { Box, DashboardModule } from '@campaignhub/suit-theme'

import FormResponse from '../FormResponse'

const FormResponseGroup = (props) => {
  const { formResponseGroup } = props
  const { formTemplateId, responses, templateTitle } = formResponseGroup

  return (
    <DashboardModule key={formTemplateId} title={templateTitle}>
      <Box flexDirection="column">
        {responses.map(response => (
          <FormResponse formResponse={response} key={response.id} />
        ))}
      </Box>
    </DashboardModule>
  )
}

FormResponseGroup.propTypes = {
  formResponseGroup: PropTypes.object.isRequired,
}

export default FormResponseGroup
