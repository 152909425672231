import { useLatestEntity } from '@campaignhub/react-hooks'

import useDigitalPage from '@hooks/useDigitalPage'
import useDigitalTemplatePageItem from '@hooks/useDigitalTemplatePageItem'

function useDigitalPageItem(initDigitalPage, digitalTemplatePageItemId){
  const { entity: digitalPage } = useLatestEntity(initDigitalPage, 'digitalPages')

  const digitalPagePayload = useDigitalPage(digitalPage)
  const { callbacks: { updatePageItemCustomData }, data } = digitalPagePayload

  const pageItemData = data[digitalTemplatePageItemId] || {}

  const templatePageItemPayload = useDigitalTemplatePageItem({ digitalTemplatePageItemId })

  return {
    callbacks: {
      updatePageItemCustomData: (changes, requestOptions) => updatePageItemCustomData(digitalTemplatePageItemId, changes, requestOptions),
    },
    digitalPagePayload,
    digitalTemplatePageItemId,
    pageItemData,
    templatePageItemPayload,
  }
}

export default useDigitalPageItem
