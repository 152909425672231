import { Route, Routes, HashRouter as Router } from 'react-router-dom'

import Admin from './packs/Admin'
import AgreementBuilder from './packs/AgreementBuilder'
import CalendarBuilder from './packs/CalendarBuilder'
import Client from '.'
import Dashboard from './packs/Dashboard'
import DigitalRenderer from './packs/DigitalRenderer'
import DocumentBuilder from './packs/DocumentBuilder'
import External from './packs/External'
import FormBuilder from './packs/FormBuilder'
import ImportExternalProject from './packs/ImportExternalProject'
import Project from './packs/Project'
import Templates from './packs/Templates'

const ClientRoutes = () => (
  <Router>
    <Client>
      <Routes>
        <Route path="/agreements/:agreementId/edit" element={<AgreementBuilder editing />} />
        <Route path="/digitalPages/:digitalPageId/edit" element={<DigitalRenderer editing />} />
        <Route path="/documentTemplates/:documentTemplateId/edit" element={<DocumentBuilder editing />} />
        <Route path="/eventCalendars/:eventCalendarId/edit" element={<CalendarBuilder editing />} />
        <Route
          path="importExternalProject/:integration_platform_key/:external_id"
          element={<ImportExternalProject />}
        />
        <Route path="/formTemplates/:formTemplateId/edit" element={<FormBuilder editing />} />
        <Route path="/projects/:projectId/*" element={<Project />} />
        <Route path="/admin/*" element={<Admin />} />
        <Route path="/external/*" element={<External />} />
        <Route path="/templates/*" element={<Templates />} />
        <Route path="/*" element={<Dashboard />} />
      </Routes>
    </Client>
  </Router>
)

export default ClientRoutes
