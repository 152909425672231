import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import CountUp from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck, faPencil, faTimes, faUndo,
} from '@fortawesome/pro-solid-svg-icons'

import { useSetState } from '@campaignhub/react-hooks'

import PageContext from '@contexts/pageContext'
import useDeviceStyle from '@hooks/useDeviceStyle'

import replaceTextShortCodes from '@functions/replaceTextShortCodes'

import replaceCustomData from '@components/digitalRenderer/utils/replaceCustomData'

import styles from './styles.module.scss'

const toggleEditMode = (setState, e) => {
  e.stopPropagation()
  setState({ editMode: true })
}

const resetEdit = (data, setState) => {
  const { id, shortcode, updateCustomData } = data

  if (updateCustomData){
    updateCustomData(id, {
      shortcode,
      text: null,
    })

    setState({ editMode: false })
  }
}

const saveEdit = (data, setState) => {
  const {
    id, inputValue, shortcode, updateCustomData,
  } = data

  if (updateCustomData){
    updateCustomData(id, {
      shortcode,
      text: inputValue,
    })
  }

  setState({ editMode: false })
}

const cancelEdit = (value, setState) => {
  setState({
    editMode: false,
    inputValue: value,
  })
}

const defaultState = {
  editMode: false,
  inputHeight: 100,
  inputValue: '',
}

const Counter = (props) => {
  const {
    callbacks: { updateCustomData },
    context: componentContext,
    data: { shortcode, value: rawValue },
    id,
    options,
  } = props

  const {
    counterPrefix,
    counterSuffix,
    counterDecimals,
    counterSeparator,
    editable,
  } = options || {}

  // State
  const [state, setState] = useSetState(defaultState)
  const { editMode, inputValue } = state

  // Context
  const pageContext = useContext(PageContext)
  const { editing, shortcodeData } = pageContext

  const contextData = { ...shortcodeData, ...componentContext }

  // Value
  const value = replaceCustomData(id, pageContext) || replaceTextShortCodes(rawValue, contextData)

  // Effects
  useEffect(() => {
    if (inputValue !== value){
      setState({ inputValue: value })
    }
  }, [value])

  // Style
  const style = useDeviceStyle(props)

  const isEditable = editing && editable && updateCustomData

  if (editMode){
    return (
      <div className={styles.editContainer}>
        <input
          className={styles.editInput}
          onChange={e => setState({ inputValue: e.target.value })}
          style={style}
          type="number"
          value={inputValue}
        />
        <div className={styles.editActions}>
          <a
            className={classnames(styles.icon, styles.blue)}
            onClick={() => resetEdit({ id, shortcode, updateCustomData }, setState)}
            style={{ marginRight: 2 }}
            tabIndex="0"
          >
            <FontAwesomeIcon icon={faUndo} />
          </a>

          <a
            className={classnames(styles.icon, styles.green)}
            onClick={() => saveEdit({
              id, inputValue, shortcode, updateCustomData,
            }, setState)}
            style={{ marginRight: 2 }}
            tabIndex="0"
          >
            <FontAwesomeIcon icon={faCheck} />
          </a>

          <a className={classnames(styles.icon, styles.red)} onClick={() => cancelEdit(value, setState)} tabIndex="0">
            <FontAwesomeIcon icon={faTimes} />
          </a>
        </div>
      </div>
    )
  }

  return (
    <div
      className={classnames(styles.root, isEditable ? styles.editable : null)}
      onClick={isEditable ? e => toggleEditMode(setState, e) : null}
      style={style}
    >
      <VisibilitySensor>
        {({ isVisible }) => (
          <div>
            {isVisible ? (
              <CountUp
                start={0}
                end={value ? Number(value) : 100}
                suffix={counterSuffix}
                prefix={counterPrefix}
                decimal={counterSeparator}
                decimals={counterDecimals}
              />
            ) : (
              '0'
            )}
          </div>
        )}
      </VisibilitySensor>

      {isEditable && (
        <div className={styles.editActions}>
          <div className={styles.icon} style={{ marginBottom: 2 }}>
            <FontAwesomeIcon icon={faPencil} />
          </div>
        </div>
      )}
    </div>
  )
}

Counter.propTypes = {
  callbacks: PropTypes.shape({
    updateCustomData: PropTypes.func,
  }),
  context: PropTypes.object,
  data: PropTypes.object,
  id: PropTypes.string.isRequired,
  options: PropTypes.object,
}

Counter.defaultProps = {
  data: {},
  options: {},
}

export default Counter
