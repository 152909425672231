import { useContext } from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoins } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, FormField, LoadingModule, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useLocalization from '@hooks/useLocalization'

import useManageEntityQuotes from './hooks/useManageEntityQuotes'

import Quote from './components/Quote'
import QuotesBlankState from './components/QuotesBlankState'

import localizedStrings from './localizedStrings'

const ManageEntityQuotesModal = (props) => {
  const {
    callbacks,
    disableAnimation,
    disableOverlay,
    entity: initEntity,
    modalKey,
    showModal,
    subjectId,
    subjectType,
  } = props
  const { showCreateQuotesModal, updateEntity, updateDigitalPageFn } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const { entity: modalEntity = {}, callbacks: modalCallbacks } = digObject(modalData, modalKey, {})
  const entity = modalEntity?.id ? modalEntity : initEntity

  const { updateEntity: modalDataUpdateFn } = modalCallbacks || {}
  const updateFn = modalDataUpdateFn || updateDigitalPageFn

  const {
    callbacks: { toggleEntityQuote },
    filteredQuotes,
    hasQuotes,
    loading,
    selectedQuoteIds,
  } = useManageEntityQuotes(entity, { performHttpRequests: !!entity.id, subjectId, subjectType })

  const {
    callbacks: { formatCurrency },
    strings,
  } = useLocalization(localizedStrings)

  return (
    <SidebarModal
      callbacks={callbacks}
      disableAnimation={disableAnimation}
      disableOverlay={disableOverlay}
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.sideBarModalHeader?.title || 'Manage'}
        titleSecondLine={strings.sideBarModalHeader?.titleSecondLine || 'Quotes'}
      />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <LoadingModule loading={loading} times={3} />

          {!loading && !hasQuotes && <QuotesBlankState />}

          {!loading && hasQuotes && (
            <FormField direction="column" label={strings.formFieldLabels?.availableQuotes || 'Available Quotes'}>
              {filteredQuotes.map((quote) => {
                const selectedIndex = selectedQuoteIds.findIndex(id => id === quote.id)
                const selected = selectedIndex !== -1

                return (
                  <Quote
                    callbacks={{
                      formatCurrency,
                      toggleQuote: () => toggleEntityQuote(quote.id),
                    }}
                    key={quote.id}
                    quote={quote}
                    selected={selected}
                    selectedIndex={selectedIndex}
                  />
                )
              })}
            </FormField>
          )}
        </Box>

        <FormField
          direction="column"
          boxProps={{ marginTop: 'large' }}
          label={strings.formFieldLabels?.moreOptions || 'More Options'}
        >
          <Box flexDirection="column" flexShrink={0}>
            <Button
              buttonStyle="secondaryUtility"
              icon={<FontAwesomeIcon icon={faCoins} />}
              onClick={() => showCreateQuotesModal()}
              size="medium"
            >
              {strings.buttons?.manage || 'Manage Quotes'}
            </Button>
          </Box>
        </FormField>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          onClick={() => updateEntity({ id: entity.id, quote_ids: selectedQuoteIds }, updateFn)}
          size="large"
        >
          {strings.buttons?.save || 'Save'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

ManageEntityQuotesModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  disableAnimation: PropTypes.bool,
  disableOverlay: PropTypes.bool,
  entity: PropTypes.object,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
  subjectId: PropTypes.number,
  subjectType: PropTypes.string,
}

ManageEntityQuotesModal.defaultProps = {
  modalKey: 'ManageEntityQuotesModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <ManageEntityQuotesModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
