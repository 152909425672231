import React from 'react'

import { BlankState } from '@campaignhub/suit-theme'
import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

import blankActivitiesUrl from './assets/no-activity.svg'

const ActivityBlankState = () => {
  const { strings } = useLocalization(localizedStrings)

  return (
    <BlankState imageUrl={blankActivitiesUrl}>
      <BlankState.Title>{strings.title || 'There is no activity for this organization'}</BlankState.Title>
      <BlankState.Paragraph>
        {strings.paragraph || 'No Activity'}
      </BlankState.Paragraph>
    </BlankState>
  )
}

export default ActivityBlankState
