export default {
  default: {
    sweetAlert: {
      delete: {
        confirmButton: 'Yes, delete it.',
        text: 'Are you sure? ',
        title: 'Delete Target Audience?',
      },
      deleteFromProjects: {
        confirmButton: 'Yes, remove it.',
        text: 'Are you sure? ',
        title: 'Remove from Projects?',
      },
    },
    formFieldLabels: {
      key: 'Key',
      description: 'Description',
      title: 'Title',
      titlePlaceholder: 'e.g. First Home Buyers',
      descriptionPlaceholder: 'e.g. Young buyers looking to enter the market for the first time',
      keyPlaceholder: 'e.g. first_home_buyers',
      moreOptions: 'More Options',
      brand: 'Brand',
      system: 'System',
      organizationType: 'Organization Type',
      ownerType: 'Owner Type',
    },
    toast: {
      deletedFromProjects: 'Target Audience Removed from Projects',
      targetAudienceCreated: 'Target Audience Created Successfully',
      targetAudienceDeleted: 'Target Audience Deleted Successfully',
      targetAudienceUpdated: 'Target Audience Updated Successfully',
    },
    createTitle: 'Create',
    deleteTargetAudience: 'Delete',
    targetImageLabel: 'Target Audience Image',
    saveTargetAudience: 'Save',
    titleSecondLine: 'Target Audience',
    updateTitle: 'Update',
    removeFromProjects: 'Remove from Projects',
  },
}
