import { useMemo } from 'react'

import {
  matchFilterKey, matchFilterNumber, matchFilterString, sortArrayBy,
} from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { OrganizationModel } from '@models/types'
import type { ModuleState } from '@redux/modules/types'

import defaultRequestOptions from '@sections/Client/defaultRequestOptions'

const watchEntityKeys = ['organizations']

type OrganizationFilters = {
  brand_id?: number,
  onboarding?: string,
  string?: string,
}

type UseOrganizationsOptions = {
  filters?: OrganizationFilters,
  performHttpRequests?: boolean,
}

function useOrganizations(options: UseOrganizationsOptions) {
  const { filters = {} } = options
  const {
    brand_id: filterBrandId,
    onboarding: filterOnboarding,
    string: filterString,
  } = filters

  const {
    updatedEntities: { organizations: organizationsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const selectedId = useSelector(state => state?.organizations?.selectedId) as number

  const { organizations } = useSelector(reduxState => reduxState.entities)

  const filteredOrganizations = useMemo(() => {
    const filtered = Object.values(organizations).filter((organization: OrganizationModel) => {
      const {
        brand_id, id, onboarding, realbase_id, title,
      } = organization

      const idMatch = id.toString() === filterString
      const brandMatch = matchFilterNumber(brand_id, filterBrandId)
      const onboardingMatch = matchFilterKey(String(onboarding), filterOnboarding)
      const realbaseIdMatch = realbase_id && realbase_id.toString() === filterString
      const titleMatch = matchFilterString(title, filterString)
      const matchString = idMatch || titleMatch || realbaseIdMatch

      return brandMatch && matchString && onboardingMatch
    })

    return sortArrayBy(filtered, 'asc', 'title')
  }, [organizationsUpdatedAt, JSON.stringify(filters)])

  const filteredOrganizationsCount = filteredOrganizations.length
  const hasFilteredOrganizations = !!filteredOrganizationsCount

  const filteredOrganizationIds = filteredOrganizations.map(organization => organization.id)

  const selectedOrganization = organizations[selectedId] || {}

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredOrganizationsCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingOrganizations } = useReduxAction(
    'organizations',
    'loadOrganizations',
    {
      ...filtersWithOffset,
      ...defaultRequestOptions.organization,
      ...defaultRequestOptions.organizationType,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredOrganizations,
    filteredOrganizationIds,
    hasOrganizations: hasFilteredOrganizations,
    loading: loadingOrganizations,
    selectedOrganization,
    selectedOrganizationId: selectedId,
  }
}

export default useOrganizations
