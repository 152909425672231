import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { MainNavigation as BaseNavigation } from '@campaignhub/suit-theme'

import useCurrentUser from '@hooks/useCurrentUser'
import useMainNavigation from '@hooks/useMainNavigation'

import systemBrandPayload from '@components/systemBrands/default'
import generateMainNavigationItems from '@sections/Admin/utils/generateMainNavigationItems'

interface MainNavigationProps {
  minimized: boolean,
  nestedNavigation: React.ReactNode,
}

const MainNavigation = (props: MainNavigationProps) => {
  const { minimized, nestedNavigation } = props

  const { isAdmin } = useCurrentUser()
  const navigationItems = useMemo(() => generateMainNavigationItems(isAdmin), [isAdmin])

  const { callbacks: { toggleNavigation }, showNavigation } = useMainNavigation()

  return (
    <BaseNavigation
      callbacks={{ toggleSidebar: toggleNavigation }}
      minimized={minimized}
      navigationItems={navigationItems}
      nestedNavigation={nestedNavigation}
      nestedNavigationWidth={240}
      showNavigation={showNavigation}
      systemBrandPayload={systemBrandPayload}
    />
  )
}

export default MainNavigation
