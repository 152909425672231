import React from 'react'
import PropTypes from 'prop-types'

import { Box, DashboardModule, FormField } from '@campaignhub/suit-theme'

import entityTypes from '@functions/customFieldSet'

import useCurrentUser from '@hooks/useCurrentUser'
import useOrganizationTypes from '@hooks/useOrganizationTypes'
import useProjectTypes from '@hooks/useProjectTypes'

const CustomFieldSetDetails = (props) => {
  const { customFieldSetForm } = props

  const {
    entityState: {
      entity_type, options, owner_title, owner_type, title,
    },
    errors,
    handlers,
  } = customFieldSetForm

  const projectTypePayload = useProjectTypes({ performHttpRequests: true })
  const { groupedProjectTypes } = projectTypePayload

  const organizationTypePayload = useOrganizationTypes({ performHttpRequests: true })
  const { organizationTypes } = organizationTypePayload

  const { isAdmin } = useCurrentUser()

  return (
    <DashboardModule boxProps={{ marginBottom: 'large' }} title="Field Set Details">
      <Box flexDirection="column" padding="large">
        <FormField boxProps={{ marginBottom: 'large' }} errorMessage={errors.title} label="Title">
          <input
            data-validate-field-on="change"
            name="title"
            placeholder="e.g Project Fields"
            type="text"
            value={title}
            {...handlers}
          />
        </FormField>

        <FormField boxProps={{ marginBottom: 'large' }} errorMessage={errors.entity_type} label="Entity Type">
          <select name="entity_type" value={entity_type} {...handlers} data-validate-field-on="change">
            <option value="">Please Select...</option>
            {entityTypes.map(entityType => (
              <option key={entityType.key} value={entityType.key}>
                {entityType.label}
              </option>
            ))}
          </select>
        </FormField>

        {isAdmin && entity_type === 'Project' && (
        <FormField boxProps={{ marginBottom: 'large' }} direction="column" label="Project Type">
          <select name="options.filters.project_type_key" value={options.filters.project_type_key} {...handlers}>
            <option key=""> Please Select ...</option>
            {groupedProjectTypes.map(groupedProjectType => (
              <optgroup key={groupedProjectType.id} label={groupedProjectType.title}>
                {groupedProjectType.subtypes.map(subtype => (
                  <option key={subtype.id} value={subtype.key}>
                    {subtype.title}
                  </option>
                ))}
              </optgroup>
            ))}
          </select>
        </FormField>
        )}

        {isAdmin && (entity_type === 'Organization' || entity_type === 'User') && (
        <FormField boxProps={{ marginBottom: 'large' }} label="Organization Type">
          <select
            name="options.filters.organization_type_key"
            value={options.filters.organization_type_key}
            {...handlers}
          >
            <option value="">Please Select...</option>
            {Object.values(organizationTypes).map(organizationType => (
              <option key={organizationType.id} value={organizationType.key}>
                {organizationType.title}
              </option>
            ))}
          </select>
        </FormField>
        )}

        <FormField label="Owner Type">
          <input disabled readOnly type="text" value={owner_type} />
        </FormField>

        <FormField label="Owner Title" marginTop="large">
          <input disabled readOnly type="text" value={owner_title} />
        </FormField>
      </Box>
    </DashboardModule>
  )
}

CustomFieldSetDetails.propTypes = {
  customFieldSetForm: PropTypes.object.isRequired,
}

export default CustomFieldSetDetails
