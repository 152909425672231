import React from 'react'
import { Helmet } from 'react-helmet-async'

import useLocalization from '@hooks/useLocalization'

import PageContent from './components/PageContent'

import localizedStrings from './localizedStrings'

const QuoteEdit = () => {
  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      <Helmet>
        <title>{strings.title || 'Edit Quote Template | Engage'}</title>
      </Helmet>

      <PageContent />
    </>
  )
}

QuoteEdit.propTypes = {}

export default QuoteEdit
