import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { deleteEntity, updateEntities } from '@redux/entities'

import api from '@functions/api'

import type { CaseStudyModel } from '@models/types'
import type { AppDispatch } from '@redux/store'
import type {
  Action, DeleteParams, EntityOptions, Errors, FetchRequestPayload, RootReducerState, UpdateParams,
} from '@redux/modules/types'

import { CustomFieldModel } from '@models/customField'
import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/caseStudy/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/caseStudy/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/caseStudy/FETCH_FAILURE'

const CREATE_REQUEST = 'realbase/caseStudy/CREATE_REQUEST'
const CREATE_SUCCESS = 'realbase/caseStudy/CREATE_SUCCESS'
const CREATE_FAILURE = 'realbase/caseStudy/CREATE_FAILURE'

const UPDATE_REQUEST = 'realbase/caseStudy/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realbase/caseStudy/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realbase/caseStudy/UPDATE_FAILURE'

const DELETE_REQUEST = 'realbase/caseStudy/DELETE_REQUEST'
const DELETE_SUCCESS = 'realbase/caseStudy/DELETE_SUCCESS'
const DELETE_FAILURE = 'realbase/caseStudy/DELETE_FAILURE'

export type CaseStudyModuleState = {
  creating: boolean,
  deleting: boolean,
  errors: string[],
  loaded: boolean,
  loadedForKeys: string[],
  loading: boolean,
  updating: boolean,
}

// Initial State
const initialState: CaseStudyModuleState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loadedForKeys: [],
  loading: false,
  updating: false,
}

// Actions
export function fetchRequest(payload: FetchRequestPayload = {}) {
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
  }
}

export function fetchSuccess() {
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors: Errors = []) {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest() {
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess() {
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors: Errors = []) {
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest() {
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess() {
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors: Errors = []) {
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest() {
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess() {
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors: Errors = []) {
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Action Creators
export function loadCaseStudies(options: EntityOptions = {}) {
  const { entityKey } = options

  return (dispatch: AppDispatch, getState: () => RootReducerState) => {
    const loadedForKeys = [...getState().caseStudies.loadedForKeys]

    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('/case_studies.json', options)
      .then(({ data }: { data: { entities: CaseStudyModel[] } }) => {
        const normalizedJson = normalize(data.entities, Schemas.CASE_STUDY_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function createCaseStudy(
  caseStudy: Partial<CaseStudyModel>,
  customFields?: Partial<CustomFieldModel>,
  options: EntityOptions = {},
) {
  const config = {
    method: 'POST',
    body: JSON.stringify({
      case_study: caseStudy,
      data_store_item_values: customFields || [],
    }),
  }

  return (dispatch: AppDispatch) => {
    dispatch(createRequest())

    const promise = api('/case_studies.json', options, config)
      .then(({ data }: { data: { entity: CaseStudyModel } }) => {
        const normalizedJson = normalize(data.entity, Schemas.CASE_STUDY)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function updateCaseStudy(
  caseStudy: UpdateParams<CaseStudyModel>,
  customFields?: Partial<CustomFieldModel>,
  options: EntityOptions = {},
) {
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      case_study: caseStudy,
      data_store_item_values: customFields || [],
    }),
  }

  return (dispatch: AppDispatch) => {
    dispatch(updateRequest())

    const promise = api(`/case_studies/${caseStudy.id}.json`, options, config)
      .then(({ data }: { data: { entity: CaseStudyModel } }) => {
        const normalizedJson = normalize(data.entity, Schemas.CASE_STUDY)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function deleteCaseStudy(caseStudy: DeleteParams<CaseStudyModel>) {
  const config = {
    method: 'DELETE',
  }

  return (dispatch: AppDispatch) => {
    dispatch(deleteRequest())

    const promise = api(`/case_studies/${caseStudy.id}.json`, {}, config)
      .then(({ data }: { data: { entity: CaseStudyModel } }) => {
        const normalizedJson = normalize(data.entity, Schemas.CASE_STUDY)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(deleteFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function loadFromExternalPlatform(externalPlatformKey: string, options: EntityOptions = {}) {
  return () => {
    const promise = api(`/case_studies/fetch_from_external_platform/${externalPlatformKey}.json`, options)
      .then(({ data }) => ({ success: true, data: data.data }))
      .catch((data) => {
        const errors = handleError(data)
        return { success: false as const, errors }
      })

    return promise
  }
}

type Payload = {
  externalIds: number[],
  organizationId: number,
}

export function bulkImportFromExternalPlatform(
  externalPlatformKey: string,
  payload: Payload,
  options: EntityOptions = {},
) {
  const { externalIds, organizationId } = payload

  const config = {
    method: 'POST',
    body: JSON.stringify({
      external_ids: externalIds,
      organization_id: organizationId,
    }),
  }

  return () => {
    const promise = api(`/case_studies/bulk_import_from_external_platform/${externalPlatformKey}.json`, options, config)
      .then(({ data }) => ({ success: true, data: data.data }))
      .catch((data) => {
        const errors = handleError(data)
        return { success: false as const, errors }
      })

    return promise
  }
}

export function deleteFromProjects(caseStudy: Partial<CaseStudyModel>) {
  const config = {
    method: 'DELETE',
  }

  return (dispatch: AppDispatch) => {
    dispatch(deleteRequest())

    const promise = api(`/case_studies/${caseStudy.id}/delete_from_projects.json`, {}, config)
      .then(({ data }) => {
        dispatch(deleteSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(deleteFailure(errors))

        return { success: false as const, errors }
      })
    return promise
  }
}

// Reducer
export default function reducer(
  state: CaseStudyModuleState = initialState,
  action: Action = { type: '' },
): CaseStudyModuleState {
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors || [],
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors || [],
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors || [],
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors || [],
      }
    default:
      return state
  }
}
