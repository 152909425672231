import { useEffect, useState } from 'react'
import interact from 'interactjs'

function onStartDrag(e, options){
  const {
    callbacks: { dragStart },
    createElementClone,
    documentElementId,
    draggingClassName,
  } = options

  if (dragStart) dragStart()

  const { interaction } = e

  e.preventDefault()

  if (createElementClone && interaction.pointerIsDown && !interaction.interacting()){
    const original = e.currentTarget
    const originalRect = interact.getElementRect(original)
    const clone = original.cloneNode(true)

    // position the clone
    clone.style.position = 'absolute'
    clone.style.top = `${originalRect.top}px`
    clone.style.left = `${originalRect.left}px`
    clone.style.width = `${originalRect.width}px`
    clone.style.height = `${originalRect.height}px`
    clone.style.zIndex = originalRect.zIndex

    // Add Dragging Class
    if (draggingClassName) clone.classList.add(draggingClassName)

    // insert the clone to the page
    document.getElementById(documentElementId).appendChild(clone)

    // start a drag interaction targeting the clone
    interaction.start({ name: 'drag' }, e.interactable, clone)
  }
}

function onDragMove(e, options, setCoords){
  const {
    createElementClone,
  } = options

  const { dx, dy } = e

  setCoords(currentCoords => ({
    ...currentCoords,
    x: currentCoords.x + dx,
    y: currentCoords.y + dy,
  }))

  if (createElementClone){
    // Store the dragged position in the data-x/data-y attribute
    const { target } = e

    const x = (parseFloat(target.getAttribute('data-x')) || 0) + e.dx
    const y = (parseFloat(target.getAttribute('data-y')) || 0) + e.dy

    // Translate the element
    target.style.webkitTransform = `translate(${x}px, ${y}px)`
    target.style.transform = `translate(${x}px, ${y}px)`

    // Update the position attributes
    target.setAttribute('data-x', x)
    target.setAttribute('data-y', y)
  }
}

function onEndMove(e, options){
  const {
    callbacks: { moveEnd },
  } = options

  if (moveEnd) moveEnd(e)
}

const defaultState = {
  x: 0,
  y: 0,
}

function useDraggable(dragElement, options = {}){
  const {
    disabled, position, selected,
  } = options

  const {
    x, y,
  } = position || {}

  const [coords, setCoords] = useState(defaultState)

  useEffect(() => {
    setCoords(prevState => ({
      x: x || x === 0 ? x : prevState.x,
      y: y || y === 0 ? y : prevState.y,
    }))
  }, [x, y])

  useEffect(() => {
    if (!dragElement || !dragElement.current){
      return null
    }

    const interactable = interact(dragElement.current)

    interactable
      .draggable({
        onmove: e => onDragMove(e, options, setCoords),
        onend: e => onEndMove(e, options),
      })
      .on('move', e => onStartDrag(e, options))

    return () => (interactable ? interactable.unset() : null)
  }, [disabled, selected])

  return coords
}

export default useDraggable
