import getStartedOneUrl from './getStartedOne.svg'
import getStartedTwoUrl from './getStartedTwo.svg'
import getStartedThreeUrl from './getStartedThree.svg'

const icons = {
  getStartedOne: getStartedOneUrl,
  getStartedTwo: getStartedTwoUrl,
  getStartedThree: getStartedThreeUrl,
}

export default icons
