export default {
  default: {
    navigationItems: {
      activities: 'Activities',
      agreements: 'Agreements',
      analytics: 'Analytics',
      analyze: 'ANALYZE',
      comparables: 'Comparables',
      details: 'Details',
      events: 'Events',
      files: 'Files',
      formResponses: 'Form Responses',
      media: 'Media',
      overview: 'Overview',
      prepare: 'PREPARE',
      proposals: 'Proposals',
      quotes: 'Quotes',
      share: 'SHARE',
    },
  },
}
