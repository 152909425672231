export default {
  default: {
    buttons: {
      save: 'Save',
    },
    sideBarModalHeader: {
      title: 'Manage',
      titleSecondLine: 'Users',
    },
  },
}
