import swal from 'sweetalert2'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLock, faLockOpen, faPaperPlane, faUser,
} from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Columns, DashboardModule, LoadingModule, PageHeader, Text, Tools,
} from '@campaignhub/suit-theme'

import handleCallbackAction from '@functions/handleCallbackAction'

import { getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'

import useCurrentUser from '@hooks/useCurrentUser'
import useOrganizationsUser from '@hooks/useOrganizationsUser'
import useOrganizationsUsers from '@hooks/useOrganizationsUsers'
import useReduxAction from '@hooks/useReduxAction'
import useUser, { useUserForm } from '@hooks/useUser'

import OrganizationsUser from '../OrganizationsUser'

import UserDetails from '../UserDetails'

const requestOptions = {
  include_user_brand_ids: true,
  include_user_brands_where_admin_ids: true,
  include_user_organization_ids: true,
  include_user_organizations: true,
  include_user_organizations_users: true,
}

const requestSupportToken = (requestFn) => {
  requestFn().then(({ success, errors }) => {
    if (!success && errors){
      toast.warn(errors[0])
      return
    }

    toast('Token request sent.')
  })
}

const confirmHideUser = (params) => {
  const { entityState, updateFn, user } = params

  const hideUserPayload = {
    callbacks: {
      action: updateFn,
    },
    entityParams: { userParams: { ...entityState, hidden: !user.hidden }, customFields: [] },
    toastText: user.hidden ? 'User Activated Successfully' : 'User Deactivated Successfully',
  }

  swal
    .fire({
      confirmButtonColor: '#DD6B55',
      confirmButtonText: user.hidden ? 'Yes, activate user.' : 'Yes, deactivate user.',
      icon: 'warning',
      showCancelButton: true,
      text: user.hidden ? 'This user will become accessible.' : 'This user will no longer be accessible.',
      title: user.hidden ? 'Activate User' : 'Deactivate user',
    })
    .then(({ value }) => {
      if (value){
        handleCallbackAction(hideUserPayload)
      }
    })
}

const PageContent = (props) => {
  const { user, userId } = props

  const userPayload = useUser(user)
  const {
    callbacks: { requestSupportToken: requestTokenFn, updateUser: updateFn },
    loading,
    updating,
  } = userPayload

  const userForm = useUserForm(user)
  const { entityState, saveEnabled } = userForm

  const { filteredOrganizationsUsers } = useOrganizationsUsers({ userId })

  const {
    callbacks: { addUserToOrganization },
  } = useOrganizationsUser({ user_id: userId })

  useReduxAction('users', 'loadUser', requestOptions, [userId], {
    dispatchAction: action => action(userId, requestOptions),
    shouldPerformFn: ({ loadedIds, loading: loadingUser }) => userId && !loadedIds.includes(userId) && !loadingUser,
  })

  const { isAdmin } = useCurrentUser()

  const { redirect } = parsePermittedQueryParams(getQueryParamsFromHash(), ['redirect'])

  const updateUserPayload = {
    callbacks: {
      action: updateFn,
    },
    entityParams: { userParams: entityState, customFields: [] },
    toastText: 'User Updated Successfully',
  }

  return (
    <>
      <PageHeader
        actionContent={(
          <Button buttonStyle="secondaryUtility" as="a" href={redirect || '/'} size="medium">
            Back
          </Button>
        )}
        activeTabBarItemKey="user"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        tabBarItems={[
          {
            href: '#/',
            icon: faUser,
            key: 'user',
            title: 'User',
          },
        ]}
        title="User Details"
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && (
              <>
                <UserDetails userForm={userForm} />

                <DashboardModule title="Organizations">
                  <Box flexDirection="column">
                    {!!filteredOrganizationsUsers.length && (
                      <Box flexDirection="column">
                        {filteredOrganizationsUsers.map(organizationsUser => (
                          <OrganizationsUser key={organizationsUser.id} organizationsUser={organizationsUser} />
                        ))}
                      </Box>
                    )}

                    <DashboardModule.Footer>
                      <DashboardModule.FooterRight>
                        <Button
                          buttonStyle="secondaryEdit"
                          onClick={() => addUserToOrganization()}
                          size="medium"
                          style={{ width: '100%' }}
                        >
                          Add Organization
                        </Button>
                      </DashboardModule.FooterRight>
                    </DashboardModule.Footer>
                  </Box>
                </DashboardModule>

                <Box
                  variant="white"
                  padding="large"
                  style={{ alignItems: 'center', justifyContent: 'space-between', marginBottom: 'large' }}
                >
                  <Text color="bodyFontLightColor" display={['none', 'block']} fontSize="small">
                    Save user details
                  </Text>

                  <Box width={['100%', 175]}>
                    <Button
                      buttonStyle="primaryCreate"
                      disabled={!saveEnabled}
                      loading={updating}
                      onClick={() => handleCallbackAction(updateUserPayload)}
                      size="medium"
                      style={{ width: '100%' }}
                    >
                      Save Details
                    </Button>
                  </Box>
                </Box>
              </>
            )}
          </Columns.Main>

          <Columns.Sidebar>
            {isAdmin && (
              <Tools title="Tools">
                <Button
                  buttonStyle="secondary"
                  icon={<FontAwesomeIcon icon={user.hidden ? faLockOpen : faLock} />}
                  onClick={() => confirmHideUser({ entityState, updateFn, user })}
                  size="medium"
                >
                  {user.hidden ? 'Activate User' : 'Deactivate User'}
                </Button>

                <Button
                  buttonStyle="secondary"
                  icon={<FontAwesomeIcon icon={faPaperPlane} />}
                  marginTop="medium"
                  onClick={() => requestSupportToken(requestTokenFn)}
                  size="medium"
                >
                  Request Support Token
                </Button>
              </Tools>
            )}
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

PageContent.propTypes = {
  user: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
}

export default PageContent
