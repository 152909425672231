import PropTypes from 'prop-types'

import { faChartBar } from '@fortawesome/pro-light-svg-icons'

import useChartData from '@hooks/useChartData'

import { Box, DashboardModule } from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import BarChart from '@components/charts/BarChart'
import LineChart from '@components/charts/LineChart'

import analyticsImageUrl from './assets/analytics.svg'

export const icon = faChartBar

const getChartDetails = (chartKey) => {
  const chartDetails = {
    total_page_views_created_per_month: {
      title: 'Total Page Views per Month',
      component: LineChart,
      excludedDataKeys: ['name'],
    },
    total_agreements_created_per_month: {
      title: 'Total Agreements Created per Month',
      component: LineChart,
      excludedDataKeys: ['name'],
    },
    total_organizations_created_per_month: {
      title: 'Total Organizations Created per Month',
      component: LineChart,
      excludedDataKeys: ['name'],
    },
    digital_pages_accepted_vs_created_per_month: {
      title: 'Total Digital Pages Created vs Accepted',
      component: LineChart,
      excludedDataKeys: ['name'],
    },
    digital_pages_created_vs_viewed_per_day: {
      title: 'Digital Pages Created vs Viewed Per Day',
      component: LineChart,
      excludedDataKeys: ['name'],
      options: { chartOptions: { includeXAxis: true } },
    },
    digital_pages_engagement_per_day: {
      title: 'Digital Pages Engagement Per Day',
      component: LineChart,
      excludedDataKeys: ['name'],
      options: { chartOptions: { includeXAxis: true } },
    },
    digital_pages_views_vs_sent_ratio_per_day: {
      title: 'Digital Pages Views Vs Sent Ratio Per Day',
      component: BarChart,
      excludedDataKeys: ['name', 'unit'],
      options: { chartOptions: { barSize: 25, includeXAxis: true } },
    },
  }

  return chartDetails[chartKey] || {}
}

export const configOptions = () => ({
  chart_key: {
    component: 'select',
    key: 'chart_key',
    label: 'Chart Type',
    optionKey: 'customization',
    values: [
      {
        key: 'digital_pages_accepted_vs_created_per_month',
        label: 'Pages Accepted vs Created per Month',
      },
      {
        key: 'total_agreements_created_per_month',
        label: 'Total Agreements Created per Month',
      },
      {
        key: 'total_organizations_created_per_month',
        label: 'Total Organizations Created per Month',
      },
      {
        key: 'total_page_views_created_per_month',
        label: 'Total Page Views per Month',
      },
      {
        key: 'digital_pages_created_vs_viewed_per_day',
        label: 'Digital Pages Created vs Viewed Per Day',
      },
      {
        key: 'digital_pages_engagement_per_day',
        label: 'Digital Page Engagement Per Day',
      },
      {
        key: 'digital_pages_views_vs_sent_ratio_per_day',
        label: 'Digital Page Views Vs Sent Ratio Per Day',
      },
    ],
  },
})

const Charts = (props) => {
  const { customOptions, editing } = props

  const {
    customization,
    queryParams: { entity_id, entity_type },
  } = customOptions

  const { chart_key } = customization || {}

  const chartDetailsPayload = getChartDetails(chart_key)
  const {
    excludedDataKeys, title: chartTitle, component,
  } = chartDetailsPayload

  const ChartComponent = component || LineChart

  const chartOptions = digObject(chartDetailsPayload, 'options.chartOptions', {})

  const dataPayload = useChartData(chart_key, {
    entityId: entity_id,
    entityType: entity_type,
    excludedDataKeys,
  })
  const { dataArray, loading } = dataPayload

  return (
    <DashboardModule editing={editing} loading={loading} title={chartTitle}>
      {!loading && !dataArray.length && (
        <DashboardModule.BlankState imageUrl={analyticsImageUrl}>
          <DashboardModule.BlankState.Title>No Chart Data to Display</DashboardModule.BlankState.Title>

          <DashboardModule.BlankState.Paragraph>
            Once chart data has been recorded it will be displayed here.
          </DashboardModule.BlankState.Paragraph>
        </DashboardModule.BlankState>
      )}

      {!loading && !!dataArray.length && (
        <Box flexDirection="column" flexWrap="wrap" padding="medium">
          <ChartComponent
            chartOptions={chartOptions}
            dataPayload={dataPayload}
            height={200}
            width="100%"
          />
        </Box>
      )}
    </DashboardModule>
  )
}

Charts.propTypes = {
  customOptions: PropTypes.object.isRequired,
  editing: PropTypes.bool,
}

export default Charts
