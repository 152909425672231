import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities, deleteEntity } from '@redux/entities'

import api from '@functions/api'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/documentTemplate/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/documentTemplate/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/documentTemplate/FETCH_FAILURE'

const CREATE_REQUEST = 'realbase/documentTemplate/CREATE_REQUEST'
const CREATE_SUCCESS = 'realbase/documentTemplate/CREATE_SUCCESS'
const CREATE_FAILURE = 'realbase/documentTemplate/CREATE_FAILURE'

const UPDATE_REQUEST = 'realbase/documentTemplate/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realbase/documentTemplate/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realbase/documentTemplate/UPDATE_FAILURE'

const DELETE_REQUEST = 'realbase/documentTemplate/DELETE_REQUEST'
const DELETE_SUCCESS = 'realbase/documentTemplate/DELETE_SUCCESS'
const DELETE_FAILURE = 'realbase/documentTemplate/DELETE_FAILURE'

// Initial State
const initialState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loadedForKeys: [],
  loadedIds: [],
  loading: false,
  updating: false,
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess(){
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(){
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest(){
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess(){
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors = []){
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Action Creators
export function loadDocumentTemplates(options = {}){
  const { entityKey } = options || {}

  return (dispatch, getState) => {
    const loadedForKeys = [...getState().documentTemplates.loadedForKeys]
    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('/document_templates.json', options)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entities, Schemas.DOCUMENT_TEMPLATE_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function loadDocumentTemplate(documentTemplateId, options){
  return (dispatch, getState) => {
    const loadedIds = [...getState().documentTemplates.loadedIds]
    if (!loadedIds.includes(documentTemplateId)){
      loadedIds.push(documentTemplateId)
    }

    dispatch(fetchRequest({ loadedIds }))

    const promise = api(`/document_templates/${documentTemplateId}.json`, options)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.DOCUMENT_TEMPLATE)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function createDocumentTemplate(documentTemplate, options = {}){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      document_template: documentTemplate,
    }),
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api('/document_templates.json', options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.DOCUMENT_TEMPLATE)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function updateDocumentTemplate(documentTemplate, options = {}){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      document_template: documentTemplate,
    }),
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/document_templates/${documentTemplate.id}.json`, options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.DOCUMENT_TEMPLATE)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function deleteDocumentTemplate(documentTemplate){
  const config = {
    method: 'DELETE',
  }

  return (dispatch) => {
    dispatch(deleteRequest())

    const promise = api(`/document_templates/${documentTemplate.id}.json`, {}, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.DOCUMENT_TEMPLATE)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(deleteFailure(errors))

        return { success: false, errors }
      })
    return promise
  }
}

export function processUploadedPdf(documentTemplate, attachmentAttributes, options = {}){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      attachment_attributes: attachmentAttributes,
    }),
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/document_templates/${documentTemplate.id}/process_uploaded_pdf.json`, options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.DOCUMENT_TEMPLATE)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function fetchFromExternalPlatform(externalPlatformKey, options = {}){
  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = api(`/document_templates/fetch_from_external_platform/${externalPlatformKey}.json`, options)
      .then(({ data }) => {
        dispatch(fetchSuccess())

        return { success: true, data: data.data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedIds: action.loadedIds || state.loadedIds,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors,
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
