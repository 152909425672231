import React from 'react'
import PropTypes from 'prop-types'

import { FormField } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'
import useReduxAction from '@hooks/useReduxAction'

import ModalProject from '@components/ModalComponents/ModalProject'
import Search from '@components/Search'

import localizedStrings from './localizedStrings'

const mapProjectResults = ({ entities: projects }, strings) => ({
  groups: [
    {
      key: 'projects',
      title: strings.mapResults?.title || 'PROJECTS',
      items: projects.map(project => ({
        id: project.id,
        key: project.id,
        text: project.title,
      })),
    },
  ],
})

const Project = (props) => {
  const {
    imageSelectPayload: {
      callbacks: { selectEntity },
      selectedEntity,
      selectedEntityType,
      selectedEntityId,
    },
  } = props

  // Load Selected Project
  useReduxAction(
    'projects',
    'loadProject',
    {},
    [selectedEntityType, selectedEntityId],
    {
      dispatchAction: (action, requestOptions) => action(selectedEntityId, requestOptions),
      shouldPerformFn: (entityReducer) => {
        const { loadedIds, loading, errors } = entityReducer
        return (
          selectedEntityType === 'Project'
          && selectedEntityId
          && !loadedIds.includes(selectedEntityId)
          && !loading
          && !errors.length
        )
      },
    },
  )

  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      <FormField label={strings.formFieldLabels?.findProject || 'Find a Project'}>
        <Search
          boxProps={{ marginBottom: 'large' }}
          callbacks={{
            selectItem: selected => selectEntity({ type: 'Project', id: selected.id }),
            mapResults: data => mapProjectResults(data, strings),
          }}
          endpoint="/projects.json"
          placeholder={strings.projectSearchPlaceholder || 'Search for a project'}
        />
      </FormField>

      {!!selectedEntity.id && (
        <ModalProject
          callbacks={{ removeProject: () => selectEntity({ type: 'Project', id: 0 }) }}
          project={selectedEntity}
        />
      )}
    </>
  )
}

Project.propTypes = {
  imageSelectPayload: PropTypes.shape({
    callbacks: PropTypes.shape({
      selectEntity: PropTypes.func.isRequired,
    }).isRequired,
    selectedEntity: PropTypes.object.isRequired,
    selectedEntityId: PropTypes.number.isRequired,
    selectedEntityType: PropTypes.string.isRequired,
  }).isRequired,
}

export default Project
