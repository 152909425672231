import { useSetState } from '@campaignhub/react-hooks'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBath, faBed, faCar, faCheck, faMapMarkerAlt, faPlus, faTrashAlt, IconName,
} from '@fortawesome/pro-light-svg-icons'

import { Box, Button, Text } from '@campaignhub/suit-theme'

import RealEstateRentalCampaignIcon from '@components/Icons/RealEstateRentalCampaignIcon'
import RealEstateSaleCampaignIcon from '@components/Icons/RealEstateSaleCampaignIcon'

import handleCallbackAction from '@functions/handleCallbackAction'

import useComparable from '@hooks/useComparable'
import useLocalization from '@hooks/useLocalization'

import defaultRequestOptions from '@sections/Client/packs/Project/defaultRequestOptions'

import localizedStrings from './localizedStrings'

type CloneComparableResultProps = {
  sourceComparable: {
    id: number,
    data_provider: {
      title: string,
    },
    distance: string,
  },
  filteredComparables: Array<object>,
  targetProjectId: number,
}

type RenderFeatureIcon = {
  count: number,
  type: IconName,
  bar: boolean,
}

type CloneComparable = {
  cloneComparableFn: Function,
  setState: Function,
  strings: {
    toastText?:{
      clone: string,
    },
  },
  sourceComparableId: number,
  targetProjectId: number,
}

const cloneComparable = (params:CloneComparable) => {
  const {
    cloneComparableFn,
    setState,
    strings,
    sourceComparableId,
    targetProjectId,
  } = params

  setState({ loading: true })

  const cloneComparablePayload = {
    callbacks: {
      action: cloneComparableFn,
      afterAction: () => setState({ loading: false }),
    },
    entityParams: { sourceComparableId, targetProjectId },
    requestOptions: {
      ...defaultRequestOptions.comparable,
      ...defaultRequestOptions.image,
    },
    toastText: strings.toastText?.clone || 'Comparable Cloned Successfully',
  }

  handleCallbackAction(cloneComparablePayload)
}

const renderFeatureIcon = (params: RenderFeatureIcon) => {
  const { count, type, bar } = params
  return (
    <>
      {count || 0} <FontAwesomeIcon icon={type} style={{ marginLeft: 5 }} />
      {bar && (
      <Text color="bodyFontLightColor" marginX="small">
        |
      </Text>
      )}
    </>
  )
}

const defaultState = {
  loading: false,
}

const CloneComparableResult = (props: CloneComparableResultProps) => {
  const { sourceComparable, filteredComparables, targetProjectId } = props

  const useComprablePayload = useComparable(sourceComparable)

  const {
    address: {
      full_address, post_code, region_name, state_name,
    },
    comparableFeatures: {
      bathrooms, bedrooms, distance, garages, property_type, price, price_guide, rental, saleType, soldDate,
    },
  } = useComprablePayload

  const [state, setState] = useSetState(defaultState)
  const { loading } = state

  const existingComparable = filteredComparables.find(
    comparable => comparable?.data?.cloned_from_id === sourceComparable.id,
  )
  const added = !!existingComparable

  const {
    callbacks: { cloneComparable: cloneComparableFn, deleteComparable },
  } = useComparable(existingComparable)

  const formattedDistance = distance ? (distance / 1000).toFixed(2) : null

  const {
    callbacks: { formatCurrency },
    strings,
  } = useLocalization(localizedStrings)

  return (
    <Box
      border="1px solid"
      borderColor="lineColor"
      borderRadius={5}
      flexDirection="column"
      flexShrink={0}
      marginTop="large"
      padding="large"
    >
      <Box flexDirection="column" opacity={added ? 0.5 : 1}>
        <Box>
          {property_type === 'Unit' ? <RealEstateRentalCampaignIcon /> : <RealEstateSaleCampaignIcon />}

          <Box flexDirection="column" marginLeft="medium" marginBottom="small">
            <Text fontSize="small" color="bodyFontColor" marginBottom="medium">
              {full_address}
            </Text>

            <Text fontSize="xsmall" color="bodyFontLightColor" marginBottom="medium">
              {region_name}, {state_name} {post_code}
            </Text>
          </Box>
        </Box>

        <Box>
          <Box flexDirection="column">
            <Box alignItems="center" fontSize="xsmall" color="bodyFontLightColor" marginBottom="medium">
              {renderFeatureIcon({ count: bedrooms, type: faBed, bar: true })}
              {renderFeatureIcon({ count: bathrooms, type: faBath, bar: true })}
              {renderFeatureIcon({ count: garages, type: faCar, bar: false })}

              {formattedDistance && (
                <>
                  <Text color="bodyFontLightColor" marginX="large">
                    |
                  </Text>
                  <Text color="bodyFontLightColor" fontSize="small">
                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                    &nbsp; {formattedDistance} {strings.distance || 'km away'}
                  </Text>
                </>
              )}
            </Box>

            <Box color="bodyFontLightColor" fontSize="xsmall" marginBottom="large">
              {rental ? 'Rental' : (saleType || strings.agentsAdvice || 'Agents Advice')}{' '}
              {price ? `- ${formatCurrency(price, '$,.0f')}` : price_guide || strings.undisclosed || '- Undisclosed'}
              {soldDate && ` (${soldDate})`}
            </Box>
          </Box>
        </Box>
      </Box>

      <Box alignItems="center" justifyContent="space-between">
        {!added && (
          <Button
            buttonStyle="secondaryEdit"
            icon={<FontAwesomeIcon icon={faPlus} />}
            loading={loading}
            onClick={() => cloneComparable({
              cloneComparableFn,
              setState,
              strings,
              sourceComparableId: sourceComparable.id,
              targetProjectId,
            })}
            size="medium"
            width="100%"
          >
            {strings.buttons?.addComparable || 'Add Comparable'}
          </Button>
        )}

        {added && (
          <>
            <Button
              buttonStyle="secondaryUtility"
              icon={<FontAwesomeIcon icon={faTrashAlt} />}
              loading={loading}
              onClick={() => { deleteComparable() }}
              size="medium"
              width="calc(50% - 4px)"
            >
              {strings.buttons?.remove || 'Remove'}
            </Button>

            <Button
              buttonStyle="secondaryCreate"
              icon={<FontAwesomeIcon icon={faCheck} />}
              loading={loading}
              size="medium"
              width="calc(50% - 4px)"
            >
              {strings.buttons?.added || 'Added'}
            </Button>
          </>
        )}
      </Box>
    </Box>
  )
}

export default CloneComparableResult
