import React, { Fragment, useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  Box, ListItem, NavigationLinkGroup, NestedNavigation, Text,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'
import useNumericParams from '@hooks/useNumericParams'
import useProject, { useRelations } from '@hooks/useProject'
import useProjectTheme from '@hooks/useProjectTheme'

import ProjectIcon from '@components/ProjectIcon'

import generateNavigationItems from './utils/generateNavigationItems'

import localizedStrings from './localizedStrings'

const Navigation = () => {
  const { projectId } = useNumericParams()

  const entities = useSelector(reduxState => reduxState.entities)
  const { projects } = entities

  const project = projects[projectId] || {}
  const {
    urls: { editUrl },
  } = useProject(project)

  const { projectType } = useRelations(project)

  const { strings } = useLocalization(localizedStrings)

  const navigationItems = useMemo(() => {
    const items = generateNavigationItems({ project, strings })
    return items
  }, [project.id])

  const { colors } = useProjectTheme(project)

  return (
    <NestedNavigation>
      <Box flexDirection="column" flexShrink="0">
        {/* Address */}
        <Box flexDirection="column">
          <Box flexDirection="column" justifyContent="center" padding="large">
            <Box
              alignItems="center"
              background={colors.background}
              borderRadius={86}
              flexShrink={0}
              height={86}
              justifyContent="center"
              width={86}
            >
              <ProjectIcon color={colors.solid} height={54} projectTypeKey={projectType.key} width={54} />
            </Box>
          </Box>

          <ListItem
            boxProps={{
              as: 'a',
              borderBottom: '1px solid',
              padding: 'large',
            }}
            forceBottomBorder
            href={editUrl}
            showAngleIcon
          >
            <Box flexDirection="column" justifyContent="flex-start" minWidth="0" paddingRight="medium" width="auto">
              <Text fontSize="small" lineHeight="1.3">{project.title}</Text>
              <Text color="bodyFontLightColor" fontSize="xsmall" lineHeight="1.3" marginTop="small">
                {projectType.title}
              </Text>
            </Box>
          </ListItem>
        </Box>
        {/* End Address */}

        {/* Nav */}
        <Box flexDirection="column" padding="large">
          {navigationItems
            && navigationItems.sections.map(section => (
              <Fragment key={section.key}>
                {section.visible && section.title && <NestedNavigation.SectionHeader title={section.title} maximized />}

                {section.items
                  && section.items.map(item => (
                    <NavigationLinkGroup key={item.key} link={item} secondaryNav sidebarMaximized />
                  ))}
              </Fragment>
            ))}
        </Box>

        {/* End Nav */}
      </Box>
    </NestedNavigation>
  )
}

export default Navigation
