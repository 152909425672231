import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { Box } from '@campaignhub/suit-theme'

import BuilderContext from '../../contexts/builderContext'

import DocumentTemplatePage from '../DocumentTemplatePage'

import DocumentTemplateBlankState from './DocumentTemplateBlankState'

const Canvas = () => {
  const builderContext = useContext(BuilderContext)
  const {
    callbacks: {
      selectTemplatePageItemId,
    },
    documentTemplatePayload: {
      documentTemplate,
      filteredPages,
      hasPages,
    },
    updating,
    zoom,
  } = builderContext

  return (
    <Box
      bottom="0"
      flexDirection="column"
      flexShrink="0"
      left="0"
      onClick={() => selectTemplatePageItemId(null)}
      overflow="auto"
      paddingBottom="xxlarge"
      paddingLeft="xxlarge"
      paddingRight="xxlarge"
      paddingTop="xlarge"
      position="absolute"
      right={[0, 375, 375]}
      top="0"
    >
      {!hasPages && <DocumentTemplateBlankState documentTemplate={documentTemplate} loading={updating} />}

      {filteredPages.map(documentTemplatePage => (
        <DocumentTemplatePage key={documentTemplatePage.id} documentTemplatePage={documentTemplatePage} zoom={zoom} />
      ))}
    </Box>
  )
}

Canvas.propTypes = {}

export default Canvas
