import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, DashboardModule, FormField,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'
import useProjectTypes from '@hooks/useProjectTypes'

import localizedStrings from './localizedStrings'

const ProjectType = (props) => {
  const { projectFormPayload } = props

  const { entityState: { project_type_id }, setEntityState } = projectFormPayload

  const projectTypePayload = useProjectTypes({ performHttpRequests: true })
  const { groupedProjectTypes } = projectTypePayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <DashboardModule title={strings.title || 'Project Type'}>
      <Box direction="column" padding="large">
        <FormField>
          <select
            name="project_type_id"
            value={project_type_id}
            onChange={e => setEntityState({ project_type_id: e.target.value })}
          >
            <option key=""> {strings.pleaseSelect || 'Please Select ...'} </option>
            {groupedProjectTypes.map(groupedProjectType => (
              <optgroup key={groupedProjectType.id} label={groupedProjectType.title}>
                {groupedProjectType.subtypes.map(subtype => (
                  <option key={subtype.id} value={subtype.id}>
                    {subtype.title}
                  </option>
                ))}
              </optgroup>
            ))}
          </select>
        </FormField>
      </Box>
    </DashboardModule>
  )
}

ProjectType.propTypes = {
  groupedProjectTypes: PropTypes.array.isRequired,
  projectFormPayload: PropTypes.object.isRequired,
}

export default ProjectType
