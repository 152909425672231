import React from 'react'
import PropTypes from 'prop-types'

import { Box, SidebarModal } from '@campaignhub/suit-theme'

import { availableChartFeatures } from '@functions/digitalTemplate'

import FeatureItem from '../Features/FeatureItem'

const Features = (props) => {
  const { digitalTemplatePayload } = props

  const {
    callbacks: { toggleDigitalTemplateChartFeature: toggleChartFeature },
    digitalTemplateChartFeatures,
  } = digitalTemplatePayload

  return (
    <SidebarModal.ExpandableSectionBox label="Chart Features">
      <Box flexDirection="column">
        {availableChartFeatures.map((feature, index) => {
          const { key } = feature
          const featureEnabled = !!digitalTemplateChartFeatures.find(featureObj => featureObj.key === key)
          const lastInput = availableChartFeatures.length === index + 1

          return (
            <FeatureItem
              callbacks={{ toggle: () => toggleChartFeature(key) }}
              feature={feature}
              featureEnabled={featureEnabled}
              featureKey={key}
              key={key}
              lastInput={lastInput}
            />
          )
        })}
      </Box>
    </SidebarModal.ExpandableSectionBox>
  )
}

Features.propTypes = {
  digitalTemplatePayload: PropTypes.shape({
    callbacks: PropTypes.shape({
      toggleDigitalTemplateChartFeature: PropTypes.func,
    }).isRequired,
    digitalTemplateChartFeatures: PropTypes.array,
  }).isRequired,
}

export default Features
