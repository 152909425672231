import { useForm, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useEntityCustomData from '@hooks/useEntityCustomData'
import useEntityTypeCustomFields from '@hooks/useEntityTypeCustomFields'

import { deepSetObject, digObject } from '@campaignhub/javascript-utils'

const watchEntityKeys = ['customFields', 'dataStoreItems']

const findCustomFieldForKey = (customFieldsArray, customFieldKey) => {
  const customField = customFieldsArray.find((item) => {
    const { key } = item
    return key === customFieldKey
  })

  return customField || {}
}

const buildNestedValues = (state, customFieldsArray) => {
  const keys = Object.keys(state)

  const nestedValues = keys.map((key) => {
    const customField = findCustomFieldForKey(customFieldsArray, key)
    const jsonField = ['json_object', 'json_object_array'].includes(customField.field_type)

    const dataStoreItemValue = jsonField ? JSON.stringify(state[key]) : state[key]

    return {
      custom_field_id: customField.id,
      data_store_item_value: dataStoreItemValue || '',
    }
  })

  return nestedValues
}

const updateNestedDataStoreObjectFieldValue = (params) => {
  const {
    customField,
    customFieldsForm: {
      entityState,
      setEntityState,
    },
    nestedField,
    value,
  } = params

  const currentObject = digObject(entityState, `${customField.key}`, {})
  const updatedObject = deepSetObject(currentObject, nestedField.key, value)

  setEntityState({ [customField.key]: updatedObject })
}

function useEntityCustomFieldsForm(entity, organization, options = {}, deps = []){
  const {
    filterBy, hidden, performHttpRequests, requiredOnly, tag,
  } = options || {}

  const {
    updatedEntities: { customFields: customFieldsUpdatedAt, dataStoreItems: dataStoreItemsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const entityCustomFieldsPayload = useEntityTypeCustomFields(
    entity.type,
    organization,
    {
      filterBy,
      hidden,
      performHttpRequests,
      requiredOnly,
      tag,
    },
    deps,
  )

  const { filteredCustomFields, requiredFields } = entityCustomFieldsPayload

  // Setup default state
  const customDataPayload = useEntityCustomData(entity, filteredCustomFields)
  const { customData } = customDataPayload

  const formPayload = useForm(customData, { entity: customData, requiredFields }, [
    entity.type,
    entity.id,
    customFieldsUpdatedAt,
    dataStoreItemsUpdatedAt,
  ])
  const { entityState } = formPayload

  return {
    callbacks: {
      buildNestedValues: () => buildNestedValues(entityState, filteredCustomFields),
      updateNestedObjectField: (customField, nestedField, value) => updateNestedDataStoreObjectFieldValue({
        customField,
        customFieldsForm: formPayload,
        nestedField,
        value,
      }),
    },
    ...entityCustomFieldsPayload,
    ...formPayload,
    requiredFields,
    owner: organization || {},
  }
}

export default useEntityCustomFieldsForm
