export default {
  default: {
    toast: {
      address: {
        created: 'Address Created Successfully',
        updated: 'Address Updated Successfully',
      },
      attachment: {
        deleted: 'Attachment Deleted Successfully',
        updated: 'Attachment Updated Successfully',
      },
      caseStudy: {
        created: 'Case Study Created Successfully',
        updated: 'Case Study Updated Successfully',
      },
      comparable: {
        created: 'Comparable Created Successfully',
        deleted: 'Comparable Deleted Successfully',
        sortUpdated: 'Comparables Updated Successfully',
        updated: 'Comparable Updated Successfully',
      },
      contact: {
        created: 'Contact Created Successfully',
        deleted: 'Contact Deleted Successfully',
        updated: 'Contact Updated Successfully',
      },
      digitalPageUpdated: 'Digital Page Updated Successfully',
      imageDeleted: 'Image Deleted Successfully',
      projectUpdated: 'Updated Successfully',
      shareLink: {
        created: 'Share Link Created Successfully',
        deleted: 'Share Link Deleted Successfully',
        updated: 'Share Link Updated Successfully',
        send: 'Messages Queued for Delivery',
      },
      team: {
        created: 'Team Created Successfully',
        updated: 'Team Updated Successfully',
      },
      user: {
        updated: 'User Updated Successfully',
      },
    },
  },
}
