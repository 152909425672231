import React from 'react'
import PropTypes from 'prop-types'
import { faReplyAll } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Columns, LoadingModule, PageHeader,
} from '@campaignhub/suit-theme'

import useFormResponses from '@hooks/useFormResponses'

import useLocalization from '@hooks/useLocalization'

import FormResponseBlankState from '../FormResponseBlankState'
import FormResponsesStats from '../FormResponsesStats'
import FormResponseGroup from '../FormResponseGroup'
import localizedStrings from './localizedStrings'

const PageContent = (props) => {
  const { projectId } = props

  const {
    filteredFormResponsesCounts, groupedFormResponses, formCount, hasFormResponses, loading,
  } = useFormResponses({
    subjectId: projectId,
    subjectType: 'Project',
  })

  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      <PageHeader
        activeTabBarItemKey="form-responses"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        nestedNavigation
        tabBarItems={[
          {
            href: `#/projects/${projectId}/formResponses`,
            icon: faReplyAll,
            key: 'form-responses',
            title: strings.title || 'Form Responses',
          },
        ]}
        title={strings.title || 'Form Responses'}
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && !hasFormResponses && <FormResponseBlankState />}

            {!loading && hasFormResponses && (
              <>
                {Object.values(groupedFormResponses).map((formResponseGroup) => {
                  const { formTemplateId } = formResponseGroup
                  return <FormResponseGroup key={formTemplateId} formResponseGroup={formResponseGroup} />
                })}
              </>
            )}
          </Columns.Main>

          <Columns.Sidebar>
            <FormResponsesStats filteredFormResponsesCounts={filteredFormResponsesCounts} formCount={formCount} />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

PageContent.propTypes = {
  projectId: PropTypes.number.isRequired,
}

export default PageContent
