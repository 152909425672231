import React from 'react'
import PropTypes from 'prop-types'

import { DynamicInput } from '@campaignhub/suit-theme'

import useCustomField from '@hooks/useCustomField'

const CustomField = (props) => {
  const {
    agreementPageItemPayload: {
      callbacks: {
        setState,
        updateDataStore,
      },
      customField,
      height,
      inputValue,
      isCheckbox,
      isChecked,
      rawValue,
      recipientColor,
      width,
      x,
      y,
    },
    zoom,
  } = props

  const itemPayload = useCustomField(customField)
  const {
    customField: {
      field_type, key, input_type, placeholder, required,
    },
    optionValues,
  } = itemPayload

  return (
    <DynamicInput
      currentValue={isCheckbox ? isChecked : inputValue}
      inputComponent={isCheckbox ? 'checkbox' : field_type}
      inputType={input_type}
      name={key}
      onBlur={isCheckbox ? null : e => updateDataStore(e.target.value)}
      onChange={
        isCheckbox
          ? e => updateDataStore(!isChecked && e.target.value === 'on' ? rawValue : '')
          : e => setState({ inputValue: e.target.value })
      }
      placeholder={placeholder}
      required={required}
      style={{
        backgroundColor: `${recipientColor}17`,
        border: '1px solid',
        borderColor: recipientColor,
        borderRadius: 5,
        fontSize: 10 * zoom,
        height,
        left: x,
        margin: isCheckbox ? 0 : null,
        position: 'absolute',
        top: y,
        width,
      }}
      values={optionValues.map(item => ({ key: item.key, label: item.value }))}
    />
  )
}

CustomField.propTypes = {
  agreementPageItemPayload: PropTypes.shape({
    callbacks: PropTypes.shape({
      setState: PropTypes.func.isRequired,
      updateDataStore: PropTypes.func.isRequired,
    }).isRequired,
    customField: PropTypes.object.isRequired,
    height: PropTypes.number.isRequired,
    inputValue: PropTypes.string.isRequired,
    isCheckbox: PropTypes.bool,
    isChecked: PropTypes.bool,
    rawValue: PropTypes.string.isRequired,
    recipientColor: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }).isRequired,
  zoom: PropTypes.number.isRequired,
}

export default CustomField
