import {
  Cell,
  Pie,
  PieChart as Chart,
  ResponsiveContainer,
} from 'recharts'

import { PieChartProps, useActiveIndex } from '../../hooks/useChartHooks'
import RenderActiveShape from '../PieChartActiveShape'

const PieChart = (props: PieChartProps) => {
  const {
    chartOptions: {
      accentColor,
      primaryColor,
      innerRadius,
      outerRadius,
    },
    dataPayload: { dataArray = [] } = {},
    width = '100%',
    height = 600,
  } = props

  const { activeIndex, setActiveIndex } = useActiveIndex(dataArray)

  const onPieEnter = (_: Event, index: number) => {
    setActiveIndex(index)
  }

  return (
    <ResponsiveContainer width={width} height={height}>
      <Chart>
        <Pie
          activeIndex={activeIndex as number | number[] | undefined}
          activeShape={<RenderActiveShape {...props} dataArray={dataArray} />}
          data={dataArray}
          cx="50%"
          cy="50%"
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          dataKey="value"
          onMouseEnter={(e, index) => onPieEnter(e, index)}
        >
          {dataArray.map((entry, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Cell key={`cell-${index}`} fill={entry.main ? primaryColor : accentColor} />
          ))}
        </Pie>
      </Chart>
    </ResponsiveContainer>
  )
}

export default PieChart
