import {
  useCallback, useContext, useEffect, useState,
} from 'react'
import { useSelector } from 'react-redux'

import { digObject } from '@campaignhub/javascript-utils'

import PageContext from '@contexts/pageContext'

const imageSizes = [
  {
    key: 'thumb_640x360',
    height: 360,
    width: 640,
  },
  {
    key: 'thumb_1280x720',
    height: 720,
    width: 1280,
  },
  {
    key: 'thumb_2000x1500',
    height: 1500,
    width: 2000,
  },
  {
    key: 'thumb_3500x2500',
    height: 2500,
    width: 3500,
  },
]

export const getImageSizeKey = (imageSize, targetDevice) => {
  const { imageHeight, imageWidth } = imageSize || {}

  if (!imageHeight || !imageWidth){
    return ''
  }

  const scaledHeight = targetDevice === 'print' ? imageHeight * 2 : imageHeight
  const scaledWidth = targetDevice === 'print' ? imageWidth * 2 : imageWidth

  const selectedSize = imageSizes.find(size => size.height > scaledHeight && size.width > scaledWidth) || {}

  return selectedSize.key || 'thumb_3500x2500'
}

const defaultState = {
  imageHeight: 0,
  imageWidth: 0,
}

function useDigitalRendererImage(imageParams, options = {}){
  const {
    customData, editable, providedRef, shortcodeImageId,
  } = options || {}

  const [imageSize, setImageSize] = useState(defaultState)

  const imageRef = useCallback((node) => {
    if (node !== null){
      setImageSize({
        imageHeight: node.offsetHeight,
        imageWidth: node.offsetWidth,
      })
    }
  }, [])

  useEffect(() => {
    if (providedRef?.current){
      setImageSize({
        imageHeight: providedRef.current.offsetHeight,
        imageWidth: providedRef.current.offsetWidth,
      })
    }
  }, [])

  const customImageId = editable ? digObject(customData, 'imageId') : null
  const defaultImageId = digObject(imageParams, 'id')

  const imageId = customImageId || shortcodeImageId || defaultImageId

  const pageContext = useContext(PageContext)
  const { targetDevice } = pageContext

  const entities = useSelector(reduxState => reduxState.entities)
  const { images } = entities

  const image = images[imageId] || {}

  const imageSizeKey = getImageSizeKey(imageSize, targetDevice)
  const imageSizeUrl = imageSizeKey ? digObject(image, `sizes.${imageSizeKey}`) : undefined

  return {
    image,
    imageRef,
    imageSizeKey,
    imageSizeUrl,
  }
}

export default useDigitalRendererImage
