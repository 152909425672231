import React from 'react'
import PropTypes from 'prop-types'

import { Box, ListItem, Text } from '@campaignhub/suit-theme'

import useProject from '@hooks/useProject'

const LargeList = (props) => {
  const {
    attachment: { data },
    project: { id },
  } = props

  const { file_name, title } = data || {}

  const { urls: { documentsUrl } } = useProject({ id })

  return (
    <ListItem
      boxProps={{
        as: 'a',
        borderBottom: '1px solid',
        padding: 'large',
      }}
      href={documentsUrl}
      showAngleIcon
    >
      <Box justifyContent="center" flexDirection="column">
        <Text fontSize="small" lineHeight={1.3} marginBottom="small" variant="ellipsis">
          {title}
        </Text>

        <Text color="faintGrey" lineHeight={1.3} fontSize="xsmall">
          {file_name}
        </Text>
      </Box>
    </ListItem>
  )
}

LargeList.propTypes = {
  attachment: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
}

export default LargeList
