import React from 'react'
import PropTypes from 'prop-types'

import { Box, Text } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const itemTypes = strings => ({
  digitalTemplatePageItem: strings.itemTypes.digitalTemplatePageItem || 'Page Item',
  digitalTemplatePageSection: strings.itemTypes.digitalTemplatePageSection || 'Section',
  digitalTemplatePageSectionGroup: strings.itemTypes.digitalTemplatePageSectionGroup || 'Section Group',
})

const ItemInfo = (props) => {
  const { id, type } = props

  const { strings } = useLocalization(localizedStrings)

  return (
    <Box
      alignItems="center"
      borderBottom="1px solid"
      borderColor="lineColor"
      flexDirection="column"
      justifyContent="center"
      padding="large"
    >
      <Text fontSize="small">{id}</Text>
      <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="medium">
        {itemTypes[type] || strings.itemTypes.pageItem || 'Page Item'}
      </Text>
    </Box>
  )
}

ItemInfo.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
}

export default ItemInfo
