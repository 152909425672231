import { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, FormField, LoadingModule, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import { useSetState } from '@campaignhub/react-hooks'

import { digObject } from '@campaignhub/javascript-utils'

import useEntityCustomFieldsForm from '@hooks/useEntityCustomFieldsForm'
import useLocalization from '@hooks/useLocalization'
import useProject from '@hooks/useProject'

import EntityCustomFields from '@components/EntityCustomFields'

import localizedStrings from './localizedStrings'

const filterTags = {
  commission: 'Commission',
  cover_letter: 'Cover Letter',
  key_features: 'Key Features',
  sale_details: 'Sale Details',
  project_details: 'Project Details'
}

const defaultState = {
  tag: '',
}

const ManageProjectDataStoreItemsModal = (props) => {
  const {
    callbacks, disableAnimation, disableOverlay, modalKey, project: initProject, showModal,
  } = props
  const { updateProject } = callbacks || {}

  const [state, setState] = useSetState(defaultState)
  const { tag } = state

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const { callbacks: payloadCallbacks, updatedFeatureKey } = digObject(modalData, modalKey, {})
  const { loadShortcodeData: loadDataFn } = payloadCallbacks || {}

  const {
    callbacks: { updateProject: updateFn },
    organization,
    project: { id },
    projectType: { key: projectTypeKey },
    updating,
  } = useProject(initProject)

  // Need to show all fields when project details is pressed
  const updatedTag = tag !== 'Project Details' ? tag : ''

  const customFieldsForm = useEntityCustomFieldsForm(
    { id, type: 'Project' },
    organization,
    {
      filterBy: { project_type_key: projectTypeKey },
      performHttpRequests: true,
      tag: updatedTag,
    },
    [projectTypeKey],
  )

  const {
    callbacks: { buildNestedValues },
    loading,
  } = customFieldsForm

  useEffect(() => {
    if (updatedFeatureKey){
      setState({ tag: filterTags[updatedFeatureKey] })
    }
  }, [updatedFeatureKey])

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal
      callbacks={callbacks}
      disableAnimation={disableAnimation}
      disableOverlay={disableOverlay}
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.sideBarModalHeader?.title || 'Project'}
        titleSecondLine={strings.sideBarModalHeader?.titleSecondLine || 'Details'}
      />

      <SidebarModal.Content boxProps={{ paddingTop: 0 }}>
        <LoadingModule boxProps={{ marginTop: 'large' }} loading={loading} times={3} />

        <Box flexDirection="column">
          {!loading && <EntityCustomFields customFieldsForm={customFieldsForm} sidebar />}
        </Box>

        <FormField boxProps={{ marginTop: 'large' }} label={strings.formFieldLabels?.moreOptions || 'More Options'}>
          <Box flexDirection="column">
            {!!tag && (
              <Button
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faHome} />}
                onClick={() => setState({ tag: '' })}
                size="medium"
              >
                {strings.buttons?.showAll || 'Show All Project Fields'}
              </Button>
            )}

            {!tag && (
              <Button
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faHome} />}
                onClick={() => setState({ tag: filterTags[updatedFeatureKey] || '' })}
                size="medium"
              >
                {strings.buttons?.hideExtraFields || 'Hide Extra Fields'}
              </Button>
            )}
          </Box>
        </FormField>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          loading={updating}
          loadingBubbleColor="white"
          onClick={() => updateProject({ id }, 'Project', buildNestedValues(), updateFn, loadDataFn)}
          size="large"
        >
          {strings.buttons?.save || 'Save'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

ManageProjectDataStoreItemsModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  disableAnimation: PropTypes.bool,
  disableOverlay: PropTypes.bool,
  modalKey: PropTypes.string,
  project: PropTypes.object.isRequired,
  showModal: PropTypes.bool,
}

ManageProjectDataStoreItemsModal.defaultProps = {
  modalKey: 'ManageProjectDataStoreItemsModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <ManageProjectDataStoreItemsModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
