import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import {
  Box, Checkbox, Image, ListItem, Text,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useImage from '@hooks/useImage'
import useLocalization from '@hooks/useLocalization'

import BoxBackgroundImage from './assets/placeholderImage.png'
import PlaceholderImageUrl from './assets/default_campaign_image_400x400.png'

import localizedStrings from './localizedStrings'

const DraggableMedia = React.forwardRef((props, ref) => {
  const {
    callbacks: { bulkSelectImageId, updateDefaultImage },
    dragHandleProps,
    dragSnapshot,
    draggableProps,
    image,
    index,
    selected,
  } = props

  const { isDragging } = dragSnapshot || {}

  const createdDateTime = digObject(image, 'dates.created.date_time_with_timezone')
  const fileName = digObject(image, 'file_data.metadata.filename', '')
  const imageUrl = digObject(image, 'sizes.thumb_200x120', '')

  const { callbacks: { editImage } } = useImage(image)

  const { callbacks: { formatDate }, strings } = useLocalization(localizedStrings)

  return (
    <Box>
      <ListItem
        boxProps={{
          alignItems: 'center',
          borderBottom: '1px dashed',
          borderColor: 'lineColor',
          padding: 'large',
        }}
        forceBottomBorder
        forwardProps={{
          ...draggableProps,
          ...dragHandleProps,
        }}
        isDragging={isDragging}
        key={image.id}
        onClick={() => editImage(updateDefaultImage)}
        ref={ref}
        showAngleIcon
      >
        <Checkbox
          checked={selected}
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()

            bulkSelectImageId(image.id)
          }}
        />
        <Box backgroundImage={`url(${BoxBackgroundImage})`} borderRadius={5} width={90} marginRight="large">
          <Image
            borderRadius={5}
            height={60}
            placeholderUrl={PlaceholderImageUrl}
            url={imageUrl}
          >
            <Box height="100%" position="relative">
              <Box
                alignItems="center"
                backgroundColor="bodyFontColor"
                borderRadius="100%"
                color="white"
                fontSize="xsmall"
                height="20px"
                justifyContent="center"
                left="small"
                position="absolute"
                top="small"
                width="20px"
              >
                {index + 1}
              </Box>
            </Box>
          </Image>
        </Box>

        <Box justifyContent="flex-start" flexDirection="column" minWidth="0" paddingRight="medium" width="auto">
          <Text fontSize="small" variant="ellipsis">
            {fileName}
          </Text>

          <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="medium">
            {`${strings.createdText || 'Created'} ${formatDate(createdDateTime, DateTime.DATE_HUGE)}`}
          </Text>
        </Box>
      </ListItem>
    </Box>
  )
})

DraggableMedia.propTypes = {
  callbacks: PropTypes.object.isRequired,
  dragHandleProps: PropTypes.object,
  dragSnapshot: PropTypes.object,
  draggableProps: PropTypes.object,
  image: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  selected: PropTypes.bool.isRequired,
}

export default DraggableMedia
