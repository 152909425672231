import { useMemo } from 'react'

import { useSetState } from '@campaignhub/react-hooks'

import PageContext from '@contexts/pageContext'

import useReduxAction from '@hooks/useReduxAction'

import handleCallbackAction from '@functions/handleCallbackAction'

import CreateBrandModal from '@modals/CreateBrandModal'

import PageContent from './components/PageContent'

const callbacks = (component, setState) => {
  const componentCallbacks = {
    CreateBrandModal: {
      closeModal: () => setState({ showCreateBrandModal: false }),
      createBrand: payload => handleCallbackAction(payload),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  pageFilters: {},
  showCreateBrandModal: false,
}

const BrandList = () => {
  const [state, setState] = useSetState(defaultState)
  const { pageFilters, showCreateBrandModal } = state

  const pageContext = useMemo(
    () => ({
      callbacks: {
        resetFilters: (resetFn) => {
          if (resetFn) resetFn()
          setState({ pageFilters: {} })
        },
        showCreateBrandModal: () => setState({ showCreateBrandModal: true }),
        updateFilters: (filters) => { setState({ pageFilters: filters }) },
      },
    }),
    [],
  )

  return (
    <PageContext.Provider value={pageContext}>
      <PageContent pageFilters={pageFilters} />

      <CreateBrandModal
        callbacks={callbacks('CreateBrandModal', setState)}
        showModal={showCreateBrandModal}
      />
    </PageContext.Provider>
  )
}

export default BrandList
