import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { deleteEntity, updateEntities } from '@redux/entities'

import api from '@functions/api'

import type { OrganizationsUserModel, UserModel } from '@models/types'
import type { AppDispatch } from '@redux/store'
import type {
  Action, DeleteParams, EntityOptions, Errors, UpdateParams,
} from '@redux/modules/types'

import { handleError } from '../utils'

const CREATE_REQUEST = 'realbase/organizationsUser/CREATE_REQUEST'
const CREATE_SUCCESS = 'realbase/organizationsUser/CREATE_SUCCESS'
const CREATE_FAILURE = 'realbase/organizationsUser/CREATE_FAILURE'

const UPDATE_REQUEST = 'realbase/organizationsUser/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realbase/organizationsUser/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realbase/organizationsUser/UPDATE_FAILURE'

const DELETE_REQUEST = 'realbase/organizationsUser/DELETE_REQUEST'
const DELETE_SUCCESS = 'realbase/organizationsUser/DELETE_SUCCESS'
const DELETE_FAILURE = 'realbase/organizationsUser/DELETE_FAILURE'

export type OrganizationsUserModuleState = {
  creating: boolean,
  deleting: boolean,
  errors: string[],
  loading: boolean,
  updating: boolean,
}

// Initial State
const initialState: OrganizationsUserModuleState = {
  creating: false,
  deleting: false,
  errors: [],
  loading: false,
  updating: false,
}

// Actions
export function createRequest() {
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess() {
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors: Errors = []) {
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest() {
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess() {
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors: Errors = []) {
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest() {
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess() {
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors: Errors = []) {
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Action Creators
export function createOrganizationsUser(
  organizationsUser: Partial<OrganizationsUserModel>,
  options: EntityOptions = {},
) {
  const config = {
    method: 'POST',
    body: JSON.stringify({
      organizations_user: organizationsUser,
    }),
  }

  return (dispatch: AppDispatch) => {
    dispatch(createRequest())

    const promise = api('/organizations_users.json', options, config)
      .then(({ data }: { data: { entity: OrganizationsUserModel } }) => {
        const normalizedJson = normalize(data.entity, Schemas.ORGANIZATIONS_USER)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function updateOrganizationsUser(
  organizationsUser: UpdateParams<OrganizationsUserModel>,
  options: EntityOptions = {},
) {
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      organizations_user: organizationsUser,
    }),
  }

  return (dispatch: AppDispatch) => {
    dispatch(updateRequest())

    const promise = api(`/organizations_users/${organizationsUser.id}.json`, options, config)
      .then(({ data }: { data: { entity: OrganizationsUserModel } }) => {
        const normalizedJson = normalize(data.entity, Schemas.ORGANIZATIONS_USER)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function setOrganizationsUserAsDefault(organizationsUser: OrganizationsUserModel, options: EntityOptions = {}) {
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      organizations_user: organizationsUser,
    }),
  }

  return (dispatch: AppDispatch) => {
    dispatch(updateRequest())

    const promise = api(`/organizations_users/${organizationsUser.id}/set_as_default.json`, options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entities, Schemas.ORGANIZATIONS_USERS_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function deleteOrganizationsUser(organizationsUser: DeleteParams<OrganizationsUserModel>) {
  const config = {
    method: 'DELETE',
  }

  return (dispatch: AppDispatch) => {
    dispatch(deleteRequest())

    const promise = api(`/organizations_users/${organizationsUser.id}.json`, {}, config)
      .then(({ data }: { data: { entity: OrganizationsUserModel } }) => {
        const normalizedJson = normalize(data.entity, Schemas.ORGANIZATIONS_USER)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(deleteFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function removeUserFromOrganization(organizationsUser: OrganizationsUserModel, targetUserId: number, user: UserModel) {
  const config = {
    method: 'DELETE',
    body: JSON.stringify({
      target_user_id: targetUserId,
    }),
  }

  return (dispatch: AppDispatch) => {
    dispatch(deleteRequest())

    const promise = api(`/organizations_users/${organizationsUser.id}/remove_user.json`, {}, config)
      .then(({ data }: { data: { entity: OrganizationsUserModel } }) => {
        const normalizedJson = normalize(data.entity, Schemas.ORGANIZATIONS_USER)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        const updatedOrganizationIds = data.entity.user_organization_ids

        const updatedUser = {
          ...user,
          organization_ids: updatedOrganizationIds,
        }

        const updatedUserNormalized = normalize(updatedUser, Schemas.USER)
        dispatch(updateEntities(updatedUserNormalized))

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(deleteFailure(errors))

        return { success: false as const, errors }
      })
    return promise
  }
}

// Reducer
export default function reducer(
  state: OrganizationsUserModuleState = initialState,
  action: Action = { type: '' },
): OrganizationsUserModuleState {
  switch (action.type){
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors || [],
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors || [],
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors || [],
      }
    default:
      return state
  }
}
