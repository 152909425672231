import { useContext } from 'react'
import PropTypes from 'prop-types'

import {
  Box, Button, ModalContext, SidebarModal, SortableList,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useLocalization from '@hooks/useLocalization'

import useDigitalPage from '@hooks/useDigitalPage'
import SectionGroup from './components/SectionGroup'

import localizedStrings from './localizedStrings'

type DigitalPageSortPayload = {
callbacks: {
  action: () => void,
  afterAction: ()=> void,
},
entityParams: {},
toastText: string,
}

interface EditDigitalPageSectionSortModalProps {
callbacks: {
  closeModal: () => void,
  updateDigitalPageSectionSort: (payload: DigitalPageSortPayload) => void,
},
modalKey: string,
showModal: boolean,
}

const EditDigitalPageSectionSortModal = (props: EditDigitalPageSectionSortModalProps) => {
  const { callbacks, modalKey, showModal } = props

  const { updateDigitalPageSectionSort, closeModal } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalDataPayload = digObject(modalData, `${modalKey}`, {})
  const {
    callbacks: { updateDigitalPageSectionSort: editSortFn },
    digitalPage,
  } = modalDataPayload

  const {
    callbacks: { modifySectionSort },
    updating,
    sortedGroupSections,
  } = useDigitalPage(digitalPage)

  const { strings } = useLocalization(localizedStrings)

  const digitalPageSortPayload = {
    callbacks: {
      action: editSortFn,
      afterAction: closeModal,
    },
    entityParams: sortedGroupSections,
    toastText: strings.toast?.sectionSort || 'Sections Sorted Successfully.',
  }

  return (
    <SidebarModal
      callbacks={callbacks}
      disableAnimation
      disableOverlay
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.header?.title || 'Sort'}
        titleSecondLine={strings.header?.titleSecondLine || 'Sections'}
      />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <SortableList
            callbacks={{
              onDragEnd: (fromIndex, toIndex, payload) => modifySectionSort(fromIndex, toIndex, payload),
            }}
          >
            {Object.keys(sortedGroupSections).map((sectionGroupKey, index) => {
              const sectionIds = sortedGroupSections[sectionGroupKey] || []

              return (
                <SectionGroup
                  index={index}
                  key={sectionGroupKey}
                  sectionIds={sectionIds}
                  sectionGroupKey={sectionGroupKey}
                />
              )
            })}
          </SortableList>
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          loading={updating}
          onClick={() => updateDigitalPageSectionSort(digitalPageSortPayload)}
          size="large"
        >
          {strings.buttons?.save || 'Save'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

EditDigitalPageSectionSortModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

EditDigitalPageSectionSortModal.defaultProps = {
  modalKey: 'EditDigitalPageSectionSortModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <EditDigitalPageSectionSortModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
