export default {
  default: {
    formFieldLabels: {
      users: 'Users',
      moreOptions: 'More Options',
      leadTitle: 'LEAD USERS',
      searchReviews: 'Search Reviews',
      supportTitle: 'SUPPORT USERS',
    },
    sideBarModalHeader: {
      title: 'Manage',
      titleSecondLine: 'Reviews',
    },
    reviewCounts: {
      none: '0 Reviews',
      one: '{count} Review',
      many: '{count} Reviews',
      type: 'count',
    },
    buttons: {
      create: ' Create',
      save: 'Save',
      toggleAll: 'Toggle All',
      manage: 'Manage Reviews',
      showAll: 'Show All',
      showSelected: 'Show Selected',
    },
  },
}
