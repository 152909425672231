import { digObject } from '@campaignhub/javascript-utils'

import { useRelations as useDigitalPageRelations } from '@hooks/useDigitalPage'

import { useRelations as useProjectRelations } from '@hooks/useProject'

import { digitalPageColors, digitalPageLetterColorKeys } from '@functions/digitalPage'

const getDigitalPageColors = (letter) => {
  const digitalPageLetterColor = digitalPageLetterColorKeys[letter] || 'teal'
  const digitalPageColor = digitalPageColors[digitalPageLetterColor]

  return digitalPageColor
}

function useDigitalPageTheme(digitalPage){
  const { project, digitalTemplateType } = useDigitalPageRelations(digitalPage)

  const { address } = useProjectRelations(project)

  const streetName = digObject(address, 'street_name', '')
  const streetNameFirstLetter = streetName.substring(0, 1)?.toLowerCase()

  const digitalTemplateTypeKey = digitalTemplateType.key

  return {
    colors: getDigitalPageColors(streetNameFirstLetter),
    digitalTemplateTypeKey,
  }
}

export default useDigitalPageTheme
