/* eslint-disable max-len */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignIn } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Grid, Image, Text,
} from '@campaignhub/suit-theme'

import icons from './assets/icons'

const GettingStartedItems = [
  {
    description: `Click on ‘Get Started’, fill in the Enquiry form, 
    and a member from the team will be in contact with you to book a demo`,
    iconKey: 'getStartedOne',
  },
  {
    description:
      'Once the demo is completed, we’ll get you set up to use the product by configuring our Agreement and Contract forms to suit your agencies unique requirements',
    iconKey: 'getStartedTwo',
  },
  {
    description:
      'Group training sessions will be run by our team to ensure your team is ready to begin using the product',
    iconKey: 'getStartedThree',
  },
]

interface GettingStartedProps {
  callbacks: {
    dispatchMixpanelEvent: () => void,
  },
  isMobile: boolean,
}

const GettingStarted = (props: GettingStartedProps) => {
  const {
    callbacks: { dispatchMixpanelEvent },
    isMobile,
  } = props

  const gridColumn = isMobile ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)'
  const gridRow = isMobile ? null : 'repeat(2, 1fr)'

  return (
    <Box marginY="xlarge" backgroundColor="backgroundColor" padding="large">
      <Box flexDirection="column">
        <Text fontSize="medium" marginBottom="large">
          Getting started with RTA
        </Text>

        <Grid gridGap="large" gridTemplateColumns={gridColumn} gridTemplateRows={gridRow}>
          {GettingStartedItems.map((item) => {
            const icon = icons[item.iconKey]

            return (
              <Box alignItems="center">
                <Image boxProps={{ marginRight: 'medium' }} height={40} width={40} url={icon} />
                <Text fontSize="small" color="bodyFontLightColor" lineHeight="1.5">
                  {item.description}
                </Text>
              </Box>
            )
          })}
        </Grid>

        <Box onClick={() => dispatchMixpanelEvent()}>
          <Button
            icon={<FontAwesomeIcon icon={faSignIn} />}
            buttonStyle="primaryEdit"
            as="a"
            marginTop="large"
            href="https://www.realtimeagent.com.au/#get_started"
            size="medium"
            target="_blank"
            width="auto"
          >
            Get Started
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default GettingStarted
