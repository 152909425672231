import { useMemo, useRef } from 'react'
import cloneDeep from 'lodash/cloneDeep'

import { useForm, useLatestEntity, useWatchEntityUpdates } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import {
  digObject, launchModal, multiFilteredObjectArraySelector, sortArrayBy,
} from '@campaignhub/javascript-utils'

import useDispatch from '@hooks/useDispatch'
import useSelector from '@hooks/useSelector'
import useQuote from '@hooks/useQuote'

import * as quoteSectionActions from '@redux/modules/quoteSection'

import defaultFormState, { requiredFields } from '@models/quoteSection'

import type { AppDispatch } from '@redux/store'
import type { DeleteParams } from '@redux/modules/types'
import type { QuoteSectionModel, QuoteSectionRequestOptions } from '@models/types'

const watchEntityKeys = [
  'quoteItems',
  'quotes',
  'quoteSections',
]

type MoveQuoteSectionIdPayload = {
  dispatch: AppDispatch,
  filteredSections: QuoteSectionModel[],
  fromIndex: number,
  quoteId: number,
  requestOptions?: QuoteSectionRequestOptions,
  sectionId: number,
  toIndex: number,
}

const moveQuoteSectionId = (payload: MoveQuoteSectionIdPayload) => {
  const { updateQuoteSectionSortOrder: updateFn } = quoteSectionActions
  const {
    dispatch,
    filteredSections,
    fromIndex,
    quoteId,
    requestOptions,
    sectionId,
    toIndex,
  } = payload

  const updatedSectionIds = [...filteredSections.map(section => section.id)]

  // If we are creating a new section we need to add it to the array first
  // so we can sort it correctly
  const currentSectionIndex = updatedSectionIds.findIndex(id => id === sectionId)
  if (currentSectionIndex === -1) updatedSectionIds.push(sectionId)

  updatedSectionIds.splice(toIndex, 0, updatedSectionIds.splice(fromIndex, 1)[0])

  return dispatch(updateFn(quoteId, updatedSectionIds, requestOptions))
}

type CreateQuoteSectionParams = {
  currentIndex: number,
  dispatch: AppDispatch,
  filteredSections: QuoteSectionModel[],
  lastIndex: number,
  quoteSection: QuoteSectionModel,
  requestOptions?: QuoteSectionRequestOptions,
}

const createQuoteSection = (params: CreateQuoteSectionParams) => {
  const {
    currentIndex, dispatch, filteredSections, lastIndex, quoteSection, requestOptions,
  } = params
  const { createQuoteSection: createFn } = quoteSectionActions

  const { options } = quoteSection
  const newQuoteSection = {
    ...quoteSection,
    options: JSON.stringify(options),
  }

  return dispatch(createFn(newQuoteSection, requestOptions)).then((result) => {
    const { data, success } = result
    if (success && data){
      moveQuoteSectionId({
        dispatch,
        filteredSections,
        fromIndex: lastIndex + 1,
        quoteId: quoteSection.quote_id,
        requestOptions,
        sectionId: digObject(data, 'entity.id'),
        toIndex: currentIndex + 1,
      })
    }

    return result
  })
}

type UpdateQuoteSectionParams = {
  dispatch: AppDispatch,
  quoteSection: QuoteSectionModel,
  quoteSectionParams: Partial<QuoteSectionModel>,
  requestOptions?: QuoteSectionRequestOptions,
}

const updateQuoteSection = (params: UpdateQuoteSectionParams) => {
  const {
    dispatch, quoteSection, quoteSectionParams, requestOptions,
  } = params
  const { options } = quoteSectionParams

  const { updateQuoteSection: updateFn } = quoteSectionActions

  const updatedParams = {
    id: quoteSection.id,
    ...quoteSectionParams,
  }

  if (options){
    const sectionOptions = quoteSection.options || {}
    updatedParams.options = JSON.stringify({
      ...cloneDeep(sectionOptions),
      ...options,
    })
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

type DeleteQuoteSectionParams = {
  dispatch: AppDispatch,
  quoteSection: DeleteParams<QuoteSectionModel>,
}

const deleteQuoteSection = (params: DeleteQuoteSectionParams) => {
  const { dispatch, quoteSection } = params
  const { deleteQuoteSection: deleteFn } = quoteSectionActions

  return dispatch(deleteFn(quoteSection))
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useQuoteSectionForm(
  quoteSection: Partial<QuoteSectionModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const quoteSectionForm = useForm(
    defaultFormState,
    { entity: quoteSection, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [quoteSection.id, quoteSection.cache_key],
  )

  return {
    ...quoteSectionForm,
  }
}

type UseQuoteSectionOptions = {
  callbacks: {
    showCreateOrEditQuoteSectionModal?: (payload: {}) => void,
  },
}
function useQuoteSection(initEntity: Partial<QuoteSectionModel> = {}, options: UseQuoteSectionOptions = {}) {
  const { callbacks } = options || {}

  const { entity: quoteSection }: { entity: QuoteSectionModel} = useLatestEntity(initEntity, 'quoteSections')
  const { id } = quoteSection

  const quotePayload = useQuote({ id: quoteSection?.quote_id })
  const { filteredSections } = quotePayload
  const {
    updatedEntities: {
      quotes: quotesUpdatedAt,
      quoteItems: quoteItemsUpdatedAt,
      quoteSections: quoteSectionsUpdatedAt,
    },
  } = useWatchEntityUpdates(watchEntityKeys)

  const dispatch = useDispatch()

  const { creating, deleting, updating } = useSelector(reduxState => reduxState.quoteSections)

  const entitiesRef = useRef()
  entitiesRef.current = useSelector(reduxState => reduxState.entities)
  const { quoteItems } = entitiesRef.current

  // Items
  const filteredItems = useMemo(() => {
    const filtered = multiFilteredObjectArraySelector({ entities: entitiesRef.current }, 'quoteItems', [
      { key: 'quote_section_id', value: id },
    ])
    const sorted = sortArrayBy(filtered, 'asc', 'sort')

    return sorted
  }, [id, quotesUpdatedAt, quoteItemsUpdatedAt, quoteSectionsUpdatedAt])

  const subtotal = filteredItems
    .filter((item => item.selected))
    .reduce((acc, item) => acc + parseFloat(digObject(item, 'price', 0)), 0)

  const hideDates = digObject(quoteSection, 'options.hide_dates', false)
  const hidePrices = digObject(quoteSection, 'options.hide_prices', false)
  const hideSubtotal = digObject(quoteSection, 'options.hide_subtotal', false)

  return {
    callbacks: {
      addQuoteSection: (lastIndex: number, currentIndex: number) => launchModal({
        callbacks,
        modalKey: 'CreateOrEditQuoteSectionModal',
        payload: {
          callbacks: {
            createQuoteSection: (
              quoteSectionParams: QuoteSectionModel,
              entityOptions?: QuoteSectionRequestOptions,
            ) => (
              createQuoteSection({
                currentIndex,
                dispatch,
                filteredSections,
                lastIndex,
                quoteSection: quoteSectionParams,
                requestOptions: entityOptions,
              })
            ),
          },
        },
      }),
      createQuoteSection: (
        quoteSectionParams: QuoteSectionModel,
        lastIndex: number,
        currentIndex: number,
        entityOptions?: QuoteSectionRequestOptions,
      ) => (
        createQuoteSection({
          currentIndex,
          dispatch,
          filteredSections,
          lastIndex,
          quoteSection: quoteSectionParams,
          requestOptions: entityOptions,
        })
      ),
      deleteQuoteSection: () => deleteQuoteSection({ dispatch, quoteSection }),
      editQuoteSection: (params: {}) => launchModal({
        callbacks,
        modalKey: 'CreateOrEditQuoteSectionModal',
        payload: {
          ...params,
          callbacks: {
            updateQuoteSection: (
              quoteSectionParams: Partial<QuoteSectionModel>,
              entityOptions?: QuoteSectionRequestOptions,
            ) => (
              updateQuoteSection({
                quoteSection, quoteSectionParams, dispatch, requestOptions: entityOptions,
              })
            ),
            ...params.callbacks,
          },
          quoteSection,
        },
      }),
      moveQuoteSectionId: (
        sectionId: number,
        fromIndex: number,
        toIndex: number,
        requestOptions?: QuoteSectionRequestOptions,
      ) => moveQuoteSectionId({
        dispatch,
        filteredSections,
        fromIndex,
        quoteId: quoteSection.quote_id,
        requestOptions,
        sectionId,
        toIndex,
      }),
    },
    creating,
    deleting,
    filteredItems,
    hideDates,
    hidePrices,
    hideSubtotal,
    quoteItems,
    quoteSection,
    subtotal,
    updating,
  }
}

export default useQuoteSection
