import React from 'react'

import PropTypes from 'prop-types'

import { Box, ListItem, Text } from '@campaignhub/suit-theme'

import useEntityTheme from '@hooks/useEntityTheme'

import EngagementStatusesBox from '@components/EngagementStatusesBox'

const OrganizationLeaderboard = (props) => {
  const {
    digitalPageMetricCollection: {
      cold, warm, hot, title,
    },
  } = props

  const { colors, entityFirstLetter } = useEntityTheme(title)

  return (
    <ListItem boxProps={{ alignItems: 'center', borderBottom: '1px dashed', padding: 'large' }} disableHover>
      <Box alignItems="center" minWidth="0" paddingRight="medium" width="auto">
        <Box
          alignItems="center"
          backgroundColor={colors.background}
          borderRadius={30}
          color={colors.solid}
          height={30}
          justifyContent="center"
          marginRight="medium"
          width={30}
        >
          {entityFirstLetter.toUpperCase()}
        </Box>

        <Text fontSize="small" variant="ellipsis">
          {title}
        </Text>
      </Box>

      <EngagementStatusesBox cold={cold} hot={hot} warm={warm} />
    </ListItem>
  )
}

OrganizationLeaderboard.propTypes = {
  digitalPageMetricCollection: PropTypes.object.isRequired,
}
export default OrganizationLeaderboard
