import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArchive,
  faBuilding,
  faCalendarAlt,
  faChartBar,
  faCog,
  faFileAlt,
  faHandsHeart,
  faHistory,
  faHome,
  faImages,
  faPencil,
  faStar,
  faUserTag,
  faUsers,
} from '@fortawesome/pro-light-svg-icons'

import IconInCircle from '@components/IconInCircle'

const generateNavigationItems = (props) => {
  const {
    currentUser, hasQuoteIntegrations, isSelectedOrganizationAdmin, strings,
  } = props

  return ({
    sections: [
      {
        key: 'dashboard',
        visible: isSelectedOrganizationAdmin,
        items: [
          {
            href: `#/admin/users/${currentUser.id}/edit`,
            iconComponent: <IconInCircle><FontAwesomeIcon icon={faUsers} /></IconInCircle>,
            key: 'edit-user',
            title: strings.navigationItems?.accountSettings || 'Account Settings',
            visible: !isSelectedOrganizationAdmin,
          },
          {
            href: '#/admin/organizations/edit',
            iconComponent: <IconInCircle><FontAwesomeIcon icon={faBuilding} /></IconInCircle>,
            key: 'organization-details',
            title: strings.navigationItems?.organizationDetails || 'Organization Details',
            visible: isSelectedOrganizationAdmin,
          },
          {
            href: '#/admin/caseStudies?hidden=false',
            iconComponent: <IconInCircle><FontAwesomeIcon icon={faHome} /></IconInCircle>,
            key: 'case-studies',
            title: strings.navigationItems?.caseStudies || 'Case Studies',
            visible: true,
          },
          {
            href: '#/admin/teams/',
            iconComponent: <IconInCircle><FontAwesomeIcon icon={faHandsHeart} /></IconInCircle>,
            key: 'teams',
            title: strings.navigationItems?.teams || 'Teams',
            visible: true,
          },
          {
            href: '#/admin/users',
            iconComponent: <IconInCircle><FontAwesomeIcon icon={faUsers} /></IconInCircle>,
            key: 'users',
            title: strings.navigationItems?.users || 'Users',
            visible: isSelectedOrganizationAdmin,
          },
          {
            href: '#/admin/reviews?hidden=false',
            iconComponent: <IconInCircle><FontAwesomeIcon icon={faStar} /></IconInCircle>,
            key: 'reviews',
            title: strings.navigationItems?.reviews || 'Reviews',
            visible: true,
          },
          {
            href: '#/admin/targetAudiences',
            iconComponent: <IconInCircle><FontAwesomeIcon icon={faUserTag} /></IconInCircle>,
            key: 'target-audiences',
            title: strings.navigationItems?.targetAudiences || 'Target Audiences',
            visible: isSelectedOrganizationAdmin,
          },
          {
            href: '#/admin/integrations',
            iconComponent: <IconInCircle><FontAwesomeIcon icon={faCog} /></IconInCircle>,
            key: 'integrations',
            title: strings.navigationItems?.integrations || 'Integrations',
            visible: true,
          },
          {
            href: '#/admin/customFieldSets',
            iconComponent: <IconInCircle><FontAwesomeIcon icon={faPencil} /></IconInCircle>,
            key: 'custom-field-sets',
            title: strings.navigationItems?.customFields || 'Custom Fields',
            visible: isSelectedOrganizationAdmin,
          },
          {
            href: '#/admin/analytics',
            iconComponent: <IconInCircle><FontAwesomeIcon icon={faChartBar} /></IconInCircle>,
            key: 'analytics',
            title: strings.navigationItems?.analytics || 'Analytics',
            visible: isSelectedOrganizationAdmin,
          },
          {
            href: '#/admin/activities',
            iconComponent: <IconInCircle><FontAwesomeIcon icon={faHistory} /></IconInCircle>,
            key: 'activities',
            title: strings.navigationItems?.activities || 'Activities',
            visible: true,
          },
        ],
      },
      {
        key: 'templates',
        title: strings.navigationItems?.templates || 'TEMPLATES',
        visible: isSelectedOrganizationAdmin,
        items: [
          {
            href: '#/admin/eventCalendarTemplates',
            iconComponent: <IconInCircle><FontAwesomeIcon icon={faCalendarAlt} /></IconInCircle>,
            key: 'event-calendar-templates',
            title: strings.navigationItems?.eventCalendars || 'Calendars',
            visible: isSelectedOrganizationAdmin,
          },
          {
            href: '#/admin/documentTemplates',
            iconComponent: <IconInCircle><FontAwesomeIcon icon={faFileAlt} /></IconInCircle>,
            key: 'document-templates',
            title: strings.navigationItems?.documents || 'Documents',
            visible: isSelectedOrganizationAdmin,
          },
          {
            href: '#/admin/formTemplates',
            iconComponent: <IconInCircle><FontAwesomeIcon icon={faFileAlt} /></IconInCircle>,
            key: 'form-templates',
            title: strings.navigationItems?.forms || 'Forms',
            visible: isSelectedOrganizationAdmin,
          },
          {
            href: '#/admin/quoteTemplates',
            iconComponent: <IconInCircle><FontAwesomeIcon icon={faFileAlt} /></IconInCircle>,
            key: 'quote-templates',
            title: strings.navigationItems?.quotes || 'Quotes',
            visible: isSelectedOrganizationAdmin && !hasQuoteIntegrations,
          },
        ],
      },
      {
        key: 'libraries',
        title: strings.navigationItems?.libraries || 'LIBRARIES',
        visible: isSelectedOrganizationAdmin,
        items: [
          {
            href: '#/admin/fileLibraries',
            iconComponent: <IconInCircle><FontAwesomeIcon icon={faArchive} /></IconInCircle>,
            key: 'libraries-files',
            title: strings.navigationItems?.fileLibrary || 'File Library',
            visible: isSelectedOrganizationAdmin,
          },
          {
            href: '#/admin/imageLibraries',
            iconComponent: <IconInCircle><FontAwesomeIcon icon={faImages} /></IconInCircle>,
            key: 'libraries-images',
            title: strings.navigationItems?.imageLibrary || 'Image Library',
            visible: isSelectedOrganizationAdmin,
          },
        ],
      },
    ],
  })
}

export default generateNavigationItems
