import { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'

import { faArchive, faUser, faHome } from '@fortawesome/pro-light-svg-icons'

import {
  Button, Box, Columns, LoadingModule, PageHeader,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import useDigitalTemplates from '@hooks/useDigitalTemplates'
import useLocalization from '@hooks/useLocalization'
import useMixpanel from '@hooks/useMixpanel'
import useOrganizationSelector from '@hooks/useOrganizationSelector'
import useVideoModules from '@hooks/useVideoModules'
import { generateUrls } from '@hooks/useDigitalTemplate'

import PageFilterModule from '@components/PageFilterModule'
import VideoModule from '@components/VideoModule'

import defaultRequestOptions from '../../defaultRequestOptions'

import localizedStrings from './localizedStrings'

import TemplateListBlankState from '../TemplateListBlankState'
import TemplateListItem from '../TemplateListItem'

const getActiveTabBarKey = (currentStatus, ownerType) => {
  const ownerKey = ownerType === 'Organization' ? 'agency' : 'system'
  if (currentStatus === 'current') return `current-${ownerKey}`
  if (currentStatus === 'archived') return 'archived'
  return currentStatus
}

const buildPageFilterFields = (strings) => {
  const style = {
    marginBottom: 16,
  }

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        style,
      },
      key: 'string',
      label: strings.filters?.title || 'Filter by Title',
    },
  ]
}

const PageContent = (props) => {
  const { pageFilters } = props

  const { ownerType, status } = parsePermittedQueryParams(getQueryParamsFromHash(), ['status', 'ownerType'])

  const currentStatus = status || 'current'
  const owner_type = ownerType || 'Organization'

  const { loadedForKeys } = useSelector(reduxState => reduxState.digitalTemplates)

  const entityKey = `DigitalTemplates-${currentStatus}`

  const { selectedOrganization } = useOrganizationSelector()

  const digitalTemplatesPayload = useDigitalTemplates({
    ...defaultRequestOptions.digitalTemplate,
    ...defaultRequestOptions.image,
    entityKey,
    filters: {
      ...pageFilters,
      brand_id: selectedOrganization?.brand_id,
      owner_type,
      production_ready: owner_type === 'System' ? true : undefined,
      template_partial: false,
    },
    performHttpRequests: !loadedForKeys.includes(entityKey),
    status: currentStatus,
  })

  const { filteredDigitalTemplates, hasDigitalTemplates, loading } = digitalTemplatesPayload

  const pageContext = useContext(PageContext)

  const {
    callbacks: { resetFilters, showDuplicateDigitalTemplateModal, updateFilters },
  } = pageContext

  const activeTabBarItemKey = getActiveTabBarKey(currentStatus, owner_type)

  const videoModulePayload = useVideoModules({ key: 'clientTemplates' })
  const { videosPayload } = videoModulePayload

  const { strings } = useLocalization(localizedStrings)

  // Tracks the page load event
  const { callbacks: { dispatchMixpanelEvent } } = useMixpanel()
  useEffect(() => {
    dispatchMixpanelEvent('Digital Templates Load')
  }, [])

  const {
    agencyArchivedDigitalTemplateUrl,
    agnecyDigitalTemplateUrl,
    brandDigitalTemplateUrl,
  } = generateUrls()

  return (
    <>
      <PageHeader
        actionContent={(
          <Button buttonStyle="primaryCreate" onClick={() => showDuplicateDigitalTemplateModal()} size="medium">
            {strings.create || 'Create'}
          </Button>
        )}
        activeTabBarItemKey={activeTabBarItemKey}
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        tabBarItems={[
          {
            href: agnecyDigitalTemplateUrl,
            icon: faUser,
            key: 'current-agency',
            title: strings.agencyTemplates || 'Agency Templates',
          },
          {
            href: brandDigitalTemplateUrl,
            icon: faHome,
            key: 'current-system',
            title: strings.brandTemplates || 'Brand Templates',
          },
          {
            href: agencyArchivedDigitalTemplateUrl,
            icon: faArchive,
            key: 'archived',
            title: strings.archived || 'Archived',
          },
        ]}
        title={strings.title || 'Digital Templates'}
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && !hasDigitalTemplates && <TemplateListBlankState />}

            {!loading && hasDigitalTemplates && (
              <Box flexDirection="column">
                {filteredDigitalTemplates.map(digitalTemplate => (
                  <TemplateListItem key={digitalTemplate.id} digitalTemplate={digitalTemplate} />
                ))}
              </Box>
            )}
          </Columns.Main>

          <Columns.Sidebar>
            <PageFilterModule
              callbacks={{ resetFilters, updateFilters }}
              filterFields={buildPageFilterFields(strings)}
              pageFilters={pageFilters}
              title={strings.filterTitle || 'Filters'}
            />

            <VideoModule videosPayload={videosPayload} />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

PageContent.propTypes = {
  pageFilters: PropTypes.object.isRequired,
}

export default PageContent
