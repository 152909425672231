import React, { ReactNode, useRef } from 'react'
import classnames from 'classnames'

import useDeviceStyle from '@hooks/useDeviceStyle'
import useDigitalRendererImage from '@hooks/useDigitalRendererImage'

import { ImageModel } from '@models/image'
import useDropTarget from '../../../hooks/useDropTarget'

import styles from './styles.module.scss'

const imageStyle = (defaultStyle: {}, imageSizeUrl: string) => {
  const backgroundImage = imageSizeUrl ? `url(${imageSizeUrl})` : null

  return {
    ...defaultStyle,
    backgroundImage,
  }
}

interface ImageCarouselProps {
  callbacks: {},
  children: ReactNode,
  entityType: string,
  id: string,
  image: Partial<ImageModel>,
  isSelected: boolean,
  onClick: () => void,
}

const ImageCarousel = React.memo((props: ImageCarouselProps) => {
  const {
    callbacks,
    children,
    entityType,
    id,
    image: imageParams,
    isSelected,
    onClick,
  } = props

  const dropTargetRef = useRef(null)

  const style = useDeviceStyle(props)

  const { imageSizeUrl } = useDigitalRendererImage(imageParams, {
    providedRef: dropTargetRef,
  })

  const { isActive } = useDropTarget(dropTargetRef, { id, type: entityType }, {
    acceptedTypes: ['container', 'image.id', 'text'],
    callbacks,
  })

  return (
    <div
      className={classnames(
        styles.root,
        isActive ? styles.active : null,
        isSelected ? styles.selected : null,
      )}
      onClick={onClick}
      style={imageStyle(style, imageSizeUrl)}
      ref={dropTargetRef}
    >
      <div className={styles.label}>Image {id}</div>
      {children}
    </div>
  )
})

export default ImageCarousel
