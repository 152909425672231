import {
  Box, Button, FormField, InputSearch, SidebarModal,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import IntegrationPlatformLogo from '@components/IntegrationPlatformLogo'

import type { OrganizationModel } from '@models/organization'
import type { UserModel } from '@models/user'
import type { IntegrationPlatformModel, IntegrationModel } from '@models/types'

import Integration from './components/Integration'
import IntegrationPlatform from './components/IntegrationPlatform'
import SupportedFeatureListItem from './components/SupportedFeatureListItem'

import localizedStrings from './localizedStrings'
import useBulkUpdateIntegrationsFeatures from './hooks/useBulkUpdateIntegrationsFeatures'

const MODAL_KEY = 'BulkUpdateIntegrationsFeaturesModal'

const mapResults = (filteredPlatforms: IntegrationPlatformModel, strings: {[key: string]: string}) => {
  const groups = []

  if (filteredPlatforms){
    const filteredPlatformsGroup = {
      key: 'integration_platform',
      title: strings.mapResults?.integrationTitle || 'INTEGRATION PLATFORM',
      items: Object.values(filteredPlatforms).map(platform => ({
        ...platform,
        iconLeft: (
          <IntegrationPlatformLogo
            backgroundSize="contain"
            integrationPlatformKey={platform?.key}
            height={50}
            width={80}
          />
        ),
        text: platform.title,
        key: platform.key,
      })),
    }

    groups.push(filteredPlatformsGroup)
  }

  return { groups }
}

interface BulkUpdateIntegrationsFeaturesModalProps {
  callbacks: {
    bulkUpdateIntegrationFeatures: () => void,
    closeModal: () => void,
  },
  currentUser: UserModel,
  isSelectedOrganizationAdmin: boolean,
  selectedOrganization: OrganizationModel,
  showModal: boolean,
}

const BulkUpdateIntegrationsFeaturesModal = (props: BulkUpdateIntegrationsFeaturesModalProps) => {
  const {
    callbacks, currentUser, isSelectedOrganizationAdmin, selectedOrganization, showModal,
  } = props

  const { closeModal, bulkUpdateIntegrationFeatures } = callbacks || {}

  const bulkUpdateFeaturesPayload = useBulkUpdateIntegrationsFeatures({
    ownerId: selectedOrganization.id,
    ownerType: 'Organization',
    subjectId: isSelectedOrganizationAdmin ? null : currentUser.id,
    subjectType: isSelectedOrganizationAdmin ? null : 'User',
  })

  const {
    callbacks: {
      bulkUpdateIntegrationFeatures: bulkFn,
      setState,
    },
    integrationPayload: {
      filteredIntegrations,
      filteredPlatforms,
      hasIntegrations,
      loading,
    },
    selectedFeatureKeys,
    selectedIntegrationIds,
    selectedIntegrationPlatform,
    supportedFeatures,
  } = bulkUpdateFeaturesPayload

  const { strings } = useLocalization(localizedStrings)

  const bulkUpdateIntegrationFeaturesPayload = {
    callbacks: {
      action: bulkFn,
      afterAction: closeModal,
    },
    entityParams: { featureOptions: selectedFeatureKeys, integrationIds: selectedIntegrationIds },
    toastText: strings.toast?.IntegrationsUpdated || 'Integrations Updated Successfully',
  }

  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={MODAL_KEY} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.header.title || 'Bulk Update'}
        titleSecondLine={strings.header?.titleSecondLine || 'Integration Features'}
      />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          {!selectedIntegrationPlatform.id && (
            <FormField label={strings.options?.defaultEntity || 'Bulk Update'}>
              <InputSearch
                callbacks={{ selectItem: item => setState({ selectedIntegrationPlatform: item }) }}
                closeOnSelect
                placeholder={strings.options?.searchIntegrationPlatform || 'Search for an integration platform'}
                results={mapResults(filteredPlatforms, strings)}
                showOnFocus
              />
            </FormField>
          )}

          {selectedIntegrationPlatform.id && (
            <FormField label={strings.options.defaultEntity || 'Bulk Update'}>
              <IntegrationPlatform
                selectedIntegrationPlatform={selectedIntegrationPlatform}
                callbacks={{ deselectIntegrationPlatform: () => setState({ selectedIntegrationPlatform: {} }) }}
              />
            </FormField>
          )}

          {!loading
          && selectedIntegrationPlatform.id
          && hasIntegrations && (
            <FormField boxProps={{ marginBottom: 'large' }} label="Integrations">
              <Box flexDirection="column" maxHeight={350} overflow="auto" marginTop="medium">
                { filteredIntegrations.map((integration: IntegrationModel) => (
                  <Integration
                    integration={integration}
                    bulkUpdateFeaturesPayload={bulkUpdateFeaturesPayload}
                    key={integration.id}
                  />
                ))}
              </Box>
            </FormField>
          )}

          {!loading
          && selectedIntegrationPlatform.id
          && supportedFeatures.length && (
            <FormField boxProps={{ marginBottom: 'large' }} label="Supported Features">
              <Box flexDirection="column" marginTop="medium">
                {supportedFeatures.map(feature => (
                  <SupportedFeatureListItem feature={feature} bulkUpdateFeaturesPayload={bulkUpdateFeaturesPayload} key={feature.id} />
                ))}
              </Box>
            </FormField>
          )}
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!selectedIntegrationIds.length}
          onClick={() => bulkUpdateIntegrationFeatures(bulkUpdateIntegrationFeaturesPayload)}
          size="large"
        >
          {strings.footer?.button || 'Save'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <BulkUpdateIntegrationsFeaturesModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
