const state = {
  collaboration_disabled: false,
  document_recipient_id: null,
  document_template_page_id: null,
  height: '',
  id: null,
  item_type: '',
  key: '',
  label: '',
  locked: false,
  options: {
    custom_field_id: '',
    input_type: '',
    placeholder: '',
  },
  required: false,
  value: '',
  width: '',
  x: '',
  y: '',
  z: '',
}

export const requiredFields = [
  { key: 'document_template_page_id' },
  { key: 'item_type' },
  { key: 'key' },
]

export default state
