import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import {
  useDebounce, useDeepEffect, useSetState, useThunkDispatch,
} from '@campaignhub/react-hooks'

import { digObject, sortArrayBy, toggleArray } from '@campaignhub/javascript-utils'

import * as assetLibraryActions from '@redux/modules/assetLibrary'

import useEntityDefaultIntegrationPlatform from '@hooks/useEntityDefaultIntegrationPlatform'
import useIntegrations from '@hooks/useIntegrations'
import useIntegrationPlatforms from '@hooks/useIntegrationPlatforms'

const toggleAllAssetLibraries = (externalImageLibraries, state, setState) => {
  const { selectedImageLibrariesIds } = state

  if (selectedImageLibrariesIds.length){
    setState({ selectedImageLibrariesIds: [] })
    return
  }

  setState({ selectedImageLibrariesIds: externalImageLibraries.map(imageLibrary => imageLibrary.id) })
}

const toggleAssetLibrary = (id, state, setState) => {
  const { selectedImageLibrariesIds } = state
  const updatedImageLibraryIds = toggleArray(selectedImageLibrariesIds, id)

  setState({ selectedImageLibrariesIds: updatedImageLibraryIds })
}

const loadFromExternalPlatform = (externalPlatformKey, requestOptions, dispatch, setState) => {
  const { loadFromExternalPlatform: loadFromExternalPlatformFn } = assetLibraryActions

  const { asset_type, owner_id, owner_type } = requestOptions

  if (asset_type && owner_id && owner_type){
    dispatch(loadFromExternalPlatformFn(externalPlatformKey, requestOptions)).then(({ success, data }) => {
      if (success){
        setState({ externalImageLibraries: data, loading: false })
      }
      setState({ loading: false })
    })
  }
}

// eslint-disable-next-line max-len
const bulkImportFromExternalPlatform = (externalIds, dispatch, requestOptions, externalPlatformKey, state) => {
  const { bulkImportFromExternalPlatform: importFn } = assetLibraryActions
  const { ownerId, ownerType } = state

  const payload = {
    asset_type: 'image',
    externalIds,
    owner_id: ownerId,
    owner_type: ownerType,
  }

  return dispatch(importFn(externalPlatformKey, payload, requestOptions))
}

// eslint-disable-next-line max-len
const getAlreadyImportedAssetLibraryIds = (reduxAssetLibraries, ownerId, ownerType, selectedIntegrationPlatformKey) => {
  const alreadyImportedExternalIds = Object.values(reduxAssetLibraries).reduce((acc, assetLibrary) => {
    const source_platform = digObject(assetLibrary, 'data.source_platform')
    const external_id = digObject(assetLibrary, `data.external_ids.${source_platform}`)
    const owner_id = digObject(assetLibrary, 'owner_id')
    const owner_type = digObject(assetLibrary, 'owner_type')

    if (
      owner_id === ownerId
      && owner_type === ownerType
      && source_platform === selectedIntegrationPlatformKey
      && external_id
    ){
      acc.push(external_id)
    }

    return acc
  }, [])

  return alreadyImportedExternalIds
}

const defaultState = {
  externalImageLibraries: [],
  loading: false,
  ownerId: null,
  ownerType: '',
  selectedImageLibrariesIds: [],
  selectedIntegrationPlatformKey: '',
}

const useImportAssetLibraries = () => {
  const [state, setState] = useSetState(defaultState)
  const {
    externalImageLibraries,
    loading,
    ownerId,
    ownerType,
    selectedImageLibrariesIds,
    selectedIntegrationPlatformKey,
  } = state

  const dispatch = useThunkDispatch()

  const {
    filteredPlatforms,
    hasIntegrations,
    urls: integrationsUrls,
  } = useIntegrations({ featureKeys: ['import_asset_libraries'] })

  const { defaultPlatformKey } = useEntityDefaultIntegrationPlatform({ featureKey: 'import_asset_libraries' })

  // Integration Platform
  const { callbacks: { findIntegrationPlatformByKey } } = useIntegrationPlatforms()
  const selectedIntegrationPlatform = findIntegrationPlatformByKey(selectedIntegrationPlatformKey)

  const {
    entities: { assetLibraries: reduxAssetLibraries },
  } = useSelector(reduxState => reduxState)

  // Create an array of IDs for image libraries that have already been imported
  // Push the external ID if the image library is in the right organization, has
  // been imported from the right platform, and has an external_id
  const alreadyImportedExternalIds = getAlreadyImportedAssetLibraryIds(
    reduxAssetLibraries,
    ownerId,
    ownerType,
    selectedIntegrationPlatformKey,
  )

  // Re-fetch image libraries whenever integration platform or owner id or owner type changes
  useEffect(() => {
    if (!!ownerId && !!ownerType && selectedIntegrationPlatformKey){
      const fetchRequestParams = {
        asset_type: 'image',
        limit: 100,
        owner_id: ownerId,
        owner_type: ownerType,
      }

      loadFromExternalPlatform(selectedIntegrationPlatformKey, fetchRequestParams, dispatch, setState)
      setState({ externalImageLibraries: [], loading: true })
    } else {
      setState({ externalImageLibraries: [], loading: false })
    }
  }, [ownerId, ownerType, selectedIntegrationPlatformKey])

  // Select all image libraries that have not already been imported
  useDeepEffect(() => {
    const notImportedExternalIds = externalImageLibraries
      .filter(externalImageLibrary => !alreadyImportedExternalIds.includes(externalImageLibrary.id))
      .map(externalImageLibrary => externalImageLibrary.id)

    setState({ selectedImageLibrariesIds: notImportedExternalIds })
  }, [externalImageLibraries])

  // Check for a user/org's default integration platform,
  // if none auto select first integration platform if only one available
  useEffect(() => {
    if (defaultPlatformKey){
      setState({ selectedIntegrationPlatformKey: defaultPlatformKey })
      return
    }

    if (filteredPlatforms.length === 1){
      const selectedPlatform = filteredPlatforms[0]
      setState({ selectedIntegrationPlatformKey: selectedPlatform.key })
    }
  }, [defaultPlatformKey, filteredPlatforms.length])

  return {
    alreadyImportedExternalIds,
    callbacks: {
      bulkImportFromExternalPlatform: (externalIds, requestOptions) => bulkImportFromExternalPlatform(
        externalIds,
        dispatch,
        requestOptions,
        selectedIntegrationPlatformKey,
        state,
      ),
      setState,
      toggleAllAssetLibraries: () => toggleAllAssetLibraries(externalImageLibraries, state, setState),
      toggleAssetLibrary: id => toggleAssetLibrary(id, state, setState),
    },
    externalImageLibraries: sortArrayBy(externalImageLibraries, 'asc', 'full_name'),
    filteredPlatforms,
    hasExternalImageLibraries: !!externalImageLibraries.length,
    hasIntegrations,
    hasSelectedImageLibraries: !!selectedImageLibrariesIds.length,
    integrationsUrls,
    loading,
    ownerId,
    ownerType,
    selectedImageLibrariesIds,
    selectedIntegrationPlatform,
    selectedIntegrationPlatformKey,
  }
}

export default useImportAssetLibraries
