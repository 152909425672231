import { useContext } from 'react'
import cloneDeep from 'lodash/cloneDeep'

import { useForm, useLatestEntity } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import { digObject, launchModal } from '@campaignhub/javascript-utils'

import useDispatch from '@hooks/useDispatch'
import useSelector from '@hooks/useSelector'

import * as attachmentActions from '@redux/modules/attachment'

import defaultFormState, { requiredFields } from '@models/attachment'

import PageContext from '@contexts/pageContext'

import type { AppDispatch } from '@redux/store'
import type { DeleteParams } from '@redux/modules/types'
import type { AttachmentModel, AttachmentRequestOptions, CustomFieldModel } from '@models/types'

type CreateAttachmentParams = {
  attachmentParams: Partial<AttachmentModel>,
  dispatch: AppDispatch,
  requestOptions?: AttachmentRequestOptions,
}

const createAttachment = (params: CreateAttachmentParams) => {
  const { dispatch, attachmentParams, requestOptions } = params
  const { createAttachment: createFn } = attachmentActions

  const { data } = attachmentParams
  const newAttachment = {
    ...attachmentParams,
    data: JSON.stringify(data),
  }

  return dispatch(createFn(newAttachment, requestOptions))
}

type UpdateAttachmentParams = {
  attachment: AttachmentModel,
  attachmentParams: Partial<AttachmentModel>,
  customFields: CustomFieldModel[],
  dispatch: AppDispatch,
  requestOptions?: AttachmentRequestOptions,
}

const updateAttachment = (params: UpdateAttachmentParams) => {
  const {
    dispatch, attachment, attachmentParams, customFields, requestOptions,
  } = params
  const { updateAttachment: updateFn } = attachmentActions

  const { data } = attachmentParams

  const updatedParams = {
    id: attachment.id,
    ...attachmentParams,
  }

  if (data){
    const attachmentData = attachment.data || {}
    updatedParams.data = JSON.stringify({
      ...cloneDeep(attachmentData),
      ...data,
    })
  }

  return dispatch(updateFn(updatedParams, customFields, requestOptions))
}

type DeleteAttachmentParams = {
  attachment: DeleteParams<AttachmentModel>,
  dispatch: AppDispatch,
}

const deleteAttachment = (params: DeleteAttachmentParams) => {
  const { dispatch, attachment } = params
  const { deleteAttachment: deleteFn } = attachmentActions

  return dispatch(deleteFn(attachment))
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useAttachmentForm(
  attachment: Partial<AttachmentModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const attachmentForm = useForm(
    defaultFormState,
    { entity: attachment, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [attachment.id, attachment.cache_key],
  )

  return {
    ...attachmentForm,
  }
}

export const useRelations = (attachment: Partial<AttachmentModel> = {}) => {
  const {
    owner_id,
    owner_type,
    subject_id,
    subject_type,
  } = attachment

  const {
    projects,
    organizations,
  } = useSelector(reduxState => reduxState.entities)

  const organization = owner_type === 'Organization' && owner_id ? organizations[owner_id] || {} : {}
  const project = subject_type === 'Project' && subject_id ? projects[subject_id] || {} : {}

  return {
    organization,
    project,
  }
}

function useAttachment(initEntity: Partial<AttachmentModel> = {}) {
  const { entity: attachment }: { entity: AttachmentModel} = useLatestEntity(initEntity, 'attachments')
  const { dates } = attachment

  const dispatch = useDispatch()

  const { callbacks } = useContext(PageContext)

  const {
    creating, loading, updating,
  } = useSelector(reduxState => reduxState.attachments)

  const createdDateTime = digObject(dates, 'created.date_time_with_timezone')
  const fileName = digObject(attachment, 'data.file_name', '')
  const title = digObject(attachment, 'data.title', '')
  const downloadUrl = digObject(attachment, 'file.url', '')

  return {
    attachment,
    callbacks: {
      createAttachment: (attachmentParams: Partial<AttachmentModel>, entityOptions?: AttachmentRequestOptions) => (
        createAttachment({ attachmentParams, dispatch, requestOptions: entityOptions })
      ),
      editAttachment: (customPayload: {}) => launchModal({
        callbacks,
        modalKey: 'EditAttachmentModal',
        payload: { attachment, ...customPayload },
      }),
      deleteAttachment: () => deleteAttachment({ dispatch, attachment }),
      updateAttachment: (
        attachmentParams: Partial<AttachmentModel>,
        customFields: CustomFieldModel[],
        entityOptions?: AttachmentRequestOptions,
      ) => (
        updateAttachment({
          attachment, attachmentParams, customFields, dispatch, requestOptions: entityOptions,
        })
      ),
    },
    creating,
    createdDateTime,
    fileName,
    loading,
    title,
    updating,
    urls: { downloadUrl },
  }
}

export default useAttachment
