import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities } from '@redux/entities'

import api from '@functions/api'

import type { CountryModel } from '@models/types'
import type { AppDispatch } from '@redux/store'
import type { Action, EntityOptions, Errors } from '@redux/modules/types'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/country/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/country/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/country/FETCH_FAILURE'

export type CountryModuleState = {
  errors: string[],
  loaded: boolean,
  loading: boolean,
}

// Initial State
const initialState = {
  errors: [],
  loaded: false,
  loading: false,
}

// Actions
export function fetchRequest() {
  return {
    type: FETCH_REQUEST,
  }
}

export function fetchSuccess() {
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors: Errors = []) {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

// Action Creators
export function loadCountries(options: EntityOptions = {}) {
  return (dispatch: AppDispatch) => {
    dispatch(fetchRequest())

    const promise = api('/countries.json', options)
      .then(({ data }: { data: { entities: CountryModel[] } }) => {
        const normalizedJson = normalize(data.entities, Schemas.COUNTRY_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(
  state: CountryModuleState = initialState,
  action: Action = { type: '' },
): CountryModuleState {
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors || [],
      }
    default:
      return state
  }
}
