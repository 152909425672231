const replaceCustomData = (componentId, { customData }) => {
  // IMPORTANT - We use value here instead of text
  if (componentId && customData){
    const itemData = customData[componentId] || {}
    return itemData.value || ''
  }

  return ''
}

export default replaceCustomData
