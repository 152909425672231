import React from 'react'

import { BlankState } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'
import blankTargetAudiencesUrl from './assets/no-target-audiences.svg'
import localizedStrings from './localizedStrings'

const TargetAudiencesBlankState = () => {
  const { strings } = useLocalization(localizedStrings)

  return (
    <BlankState boxProps={{ marginBottom: ['large', 0] }} imageUrl={blankTargetAudiencesUrl}>
      <BlankState.Title>
        {strings.title || 'No Target Audiences Created'}
      </BlankState.Title>
      <BlankState.Paragraph>
        {strings.paragraph || 'Target audiences have not been created yet. Get started by clicking the button below'}
      </BlankState.Paragraph>
    </BlankState>
  )
}

export default TargetAudiencesBlankState
