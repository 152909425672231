import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBuilding, faCity, faCog, faStore, faUser,
} from '@fortawesome/pro-light-svg-icons'

import { Box, IconWithMenu, Link } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const DataPointSelector = (props) => {
  const {
    callbacks: { setState },
  } = props

  const { strings } = useLocalization(localizedStrings)

  return (
    <Box marginLeft="auto" width="auto" marginRight={['medium', 0]}>
      <IconWithMenu
        icon={(
          <Box fontSize="xsmall" color="bodyFontLightColor">
            <FontAwesomeIcon icon={faCog} />
          </Box>
        )}
        style={{ marginLeft: 'auto', width: 'auto' }}
      >
        <IconWithMenu.Menu closeOnClick listStyle={{ right: '-24px' }}>
          <Link key="you" onClick={() => setState({ selectedDataPoint: 'you' })}>
            <FontAwesomeIcon icon={faUser} /> {strings.menu?.you || 'You'}
          </Link>

          <Link key="organization" onClick={() => setState({ selectedDataPoint: 'your_organization' })}>
            <FontAwesomeIcon icon={faStore} /> {strings.menu?.organization}
          </Link>

          <Link key="brand" onClick={() => setState({ selectedDataPoint: 'your_brand' })}>
            <FontAwesomeIcon icon={faBuilding} /> {strings.menu?.brand || 'Brand'}
          </Link>

          <Link key="industry" onClick={() => setState({ selectedDataPoint: 'industry' })}>
            <FontAwesomeIcon icon={faCity} /> {strings.menu?.industry || 'Industry'}
          </Link>
        </IconWithMenu.Menu>
      </IconWithMenu>
    </Box>
  )
}

DataPointSelector.propTypes = {
  callbacks: PropTypes.shape({
    setState: PropTypes.func.isRequired,
  }).isRequired,
}

export default DataPointSelector
