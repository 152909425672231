import { useEffect, useMemo } from 'react'

import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'

import { faHome } from '@fortawesome/pro-light-svg-icons'

import { useModals, useSetState } from '@campaignhub/react-hooks'

import {
  Box, Button, Columns, LoadingModule, ModalContext, PageHeader,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import handleCallbackAction from '@functions/handleCallbackAction'

import CreateOrEditAddressModal from '@modals/CreateOrEditAddressModal'
import CreateOrEditAwardModal from '@modals/CreateOrEditAwardModal'
import EditAwardSortModal from '@modals/EditAwardSortModal'
import SelectImageModal from '@modals/SelectImageModal'

import Awards from '@components/Awards'
import EntityCustomFields from '@components/EntityCustomFields'
import VideoModule from '@components/VideoModule'

import useAddresses from '@hooks/useAddresses'
import useEntityCustomFieldsForm from '@hooks/useEntityCustomFieldsForm'
import useLocalization from '@hooks/useLocalization'
import useMixpanel from '@hooks/useMixpanel'
import useOrganization, { useOrganizationForm } from '@hooks/useOrganization'
import useOrganizationSelector from '@hooks/useOrganizationSelector'
import useReduxAction from '@hooks/useReduxAction'
import useVideoModules from '@hooks/useVideoModules'

import defaultRequestOptions from '@sections/Client/packs/Admin/defaultRequestOptions'

import Addresses from '@components/Addresses'
import SaveFormModule from '@components/SaveFormModule'

import OrganizationDetails from './components/OrganizationDetails'
import OrganizationImage from './components/OrganizationImage'
import RelatedEntityLinks from './components/RelatedEntityLinks'

import localizedStrings from './localizedStrings'

const updateOrganization = (entityParams, customFields, requestOptions, updateFn, message) => {
  updateFn(entityParams, customFields, requestOptions).then(({ success, errors }) => {
    if (!success && errors){
      toast.warn(errors[0])
      return
    }

    toast(message || 'Organisation successfully updated!')
  })
}

const deleteImage = (deleteFn, strings) => {
  deleteFn().then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    toast(strings.imageDeleted || 'Image Deleted Successfully')
  })
}

const callbacks = (component, setState, strings) => {
  const componentCallbacks = {
    CreateOrEditAddressModal: {
      closeModal: () => setState({ showCreateOrEditAddressModal: false }),
      createAddress: payload => handleCallbackAction(payload),
      updateAddress: payload => handleCallbackAction(payload),
    },
    CreateOrEditAwardModal: {
      closeModal: () => setState({ showCreateOrEditAwardModal: false }),
      createAward: payload => handleCallbackAction(payload),
      deleteAward: payload => handleCallbackAction(payload),
      updateAward: payload => handleCallbackAction(payload),
    },
    EditAwardSortModal: {
      closeModal: () => setState({ showEditAwardSortModal: false }),
      updateAwardSortOrder: payload => handleCallbackAction(payload),
    },
    SelectImageModal: {
      closeModal: () => setState({ showSelectImageModal: false }),
      deleteImage: deleteFn => deleteImage(deleteFn, strings),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  showCreateOrEditAddressModal: false,
  showEditAwardSortModal: false,
  showCreateOrEditAwardModal: false,
  showSelectImageModal: false,
}

const EditOrganization = () => {
  const { selectedOrganization } = useOrganizationSelector()

  const [state, setState] = useSetState(defaultState)
  const {
    showCreateOrEditAddressModal, showCreateOrEditAwardModal, showEditAwardSortModal, showSelectImageModal,
  } = state

  useReduxAction(
    'organizations',
    'loadOrganization',
    {
      ...defaultRequestOptions.organization,
      ...defaultRequestOptions.image,
    },
    [selectedOrganization.id],
    {
      dispatchAction: (action, requestOptions) => action(selectedOrganization.id, requestOptions),
      shouldPerformFn: ({ loadedIds }) => selectedOrganization.id && !loadedIds.includes(selectedOrganization.id),
    },
  )

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const pageContext = useMemo(() => ({
    callbacks: {
      showCreateOrEditAddressModal: (payload) => {
        setModalData('CreateOrEditAddressModal', payload)
        setState({ showCreateOrEditAddressModal: true })
      },
      showCreateOrEditAwardModal: (payload) => {
        setModalData('CreateOrEditAwardModal', payload)
        setState({ showCreateOrEditAwardModal: true })
      },
      showEditAwardSortModal: (payload) => {
        setModalData('EditAwardSortModal', payload)
        setState({ showEditAwardSortModal: true })
      },
      showSelectImageModal: (payload) => {
        setModalData('SelectImageModal', payload)
        setState({ showSelectImageModal: true })
      },
      setState,
    },
  }), [])

  const organizationPayload = useOrganization(selectedOrganization)
  const {
    callbacks: { updateOrganization: updateFn },
    defaultImage,
    loading,
    organization,
    updating,
  } = organizationPayload

  const organizationForm = useOrganizationForm(selectedOrganization)
  const { entityState, saveEnabled } = organizationForm

  const { filteredAddresses } = useAddresses({ owner_id: selectedOrganization.id, owner_type: 'Organization' })

  const customFieldsForm = useEntityCustomFieldsForm(
    selectedOrganization, // this is the entity
    selectedOrganization, // this is the owner
    { performHttpRequests: true },
  )

  const {
    callbacks: { buildNestedValues },
  } = customFieldsForm

  const videoModulePayload = useVideoModules({ key: 'organizations' })
  const { videosPayload } = videoModulePayload

  const { strings } = useLocalization(localizedStrings)

  // Tracks the page load event
  const { callbacks: { dispatchMixpanelEvent } } = useMixpanel()
  useEffect(() => {
    dispatchMixpanelEvent('Agency Details Load')
  }, [])

  return (
    <>
      <Helmet>
        <title>{strings.title || ''} | Engage</title>
      </Helmet>

      <PageContext.Provider value={pageContext}>
        <ModalContext.Provider value={modalContext}>
          <PageHeader
            activeTabBarItemKey="organization-details"
            boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
            nestedNavigation
            tabBarItems={[
              {
                href: '#/admin/organizations/edit',
                icon: faHome,
                key: 'organization-details',
                title: strings.title || 'Organization Details',
              },
            ]}
            title={strings.title || 'Organization Details'}
          />

          <Box paddingX="large" paddingTop={[112, 105]}>
            <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
              <Columns.Main>
                <LoadingModule loading={loading} times={3} />

                {!loading && (
                  <>
                    <OrganizationDetails organizationForm={organizationForm} />

                    <Addresses addresses={filteredAddresses} organization={selectedOrganization} />

                    <EntityCustomFields customFieldsForm={customFieldsForm} />

                    <SaveFormModule childrenLeft={strings.saveText || 'Organisation'}>
                      <Button
                        buttonStyle="primaryCreate"
                        disabled={!saveEnabled}
                        loading={updating}
                        marginLeft="auto"
                        onClick={() => updateOrganization(
                          entityState,
                          buildNestedValues(),
                          defaultRequestOptions.organization,
                          updateFn,
                          strings.organizationUpdated,
                        )}
                        size="medium"
                        width="auto"
                      >
                        {strings.saveButton || 'Save Details'}
                      </Button>
                    </SaveFormModule>
                  </>
                )}
              </Columns.Main>

              <Columns.Sidebar>
                <OrganizationImage image={defaultImage} organization={organization} />

                <RelatedEntityLinks organization={selectedOrganization} />

                <Awards
                  ownerId={selectedOrganization?.id}
                  subjectId={selectedOrganization?.id}
                  subjectType="Organization"
                />

                <VideoModule videosPayload={videosPayload} />

              </Columns.Sidebar>
            </Columns>
          </Box>

          <CreateOrEditAddressModal
            callbacks={callbacks('CreateOrEditAddressModal', setState, strings)}
            hiddenFields={[]}
            showModal={showCreateOrEditAddressModal}
          />

          <CreateOrEditAwardModal
            award={{
              owner_id: selectedOrganization.id,
              owner_type: 'Organization',
              subject_id: selectedOrganization?.id,
              subject_type: 'Organization',
            }}
            callbacks={callbacks('CreateOrEditAwardModal', setState, strings)}
            showModal={showCreateOrEditAwardModal}
          />

          <EditAwardSortModal
            callbacks={callbacks('EditAwardSortModal', setState)}
            ownerId={selectedOrganization?.id}
            subjectId={selectedOrganization?.id}
            subjectType="Organization"
            showModal={showEditAwardSortModal}
          />

          <SelectImageModal
            callbacks={callbacks('SelectImageModal', setState, strings)}
            defaultSelectedEntity={{ type: 'Organization', id: selectedOrganization.id }}
            showModal={showSelectImageModal}
          />
        </ModalContext.Provider>
      </PageContext.Provider>
    </>
  )
}

export default EditOrganization
