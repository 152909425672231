import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { deleteEntity, updateEntities } from '@redux/entities'

import api from '@functions/api'

import type { CustomFieldModel, ProjectModel } from '@models/types'
import type { AppDispatch } from '@redux/store'
import type {
  Action, DeleteParams, EntityOptions, Errors, FetchRequestPayload, RootReducerState, UpdateParams,
} from '@redux/modules/types'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/project/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/project/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/project/FETCH_FAILURE'

const CREATE_REQUEST = 'realbase/project/CREATE_REQUEST'
const CREATE_SUCCESS = 'realbase/project/CREATE_SUCCESS'
const CREATE_FAILURE = 'realbase/project/CREATE_FAILURE'

const UPDATE_REQUEST = 'realbase/project/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realbase/project/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realbase/project/UPDATE_FAILURE'

const DELETE_REQUEST = 'realbase/project/DELETE_REQUEST'
const DELETE_SUCCESS = 'realbase/project/DELETE_SUCCESS'
const DELETE_FAILURE = 'realbase/project/DELETE_FAILURE'

export type ProjectModuleState = {
  creating: boolean,
  deleting: boolean,
  errors: string[],
  loaded: boolean,
  loadedForKeys: string[],
  loadedIds: number[],
  loading: boolean,
  updating: boolean,
}

// Initial State
const initialState: ProjectModuleState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loadedIds: [],
  loadedForKeys: [],
  loading: false,
  updating: false,
}

// Actions
export function fetchRequest(payload: FetchRequestPayload = {}) {
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess() {
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors: Errors = []) {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest() {
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess() {
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors: Errors = []) {
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest() {
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess() {
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors: Errors = []) {
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest() {
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess() {
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors: Errors = []) {
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Action Creators
export function loadProjects(options: EntityOptions = {}) {
  const { entityKey } = options

  return (dispatch: AppDispatch, getState: () => RootReducerState) => {
    const loadedForKeys = [...getState().projects.loadedForKeys]

    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('/projects.json', options)
      .then(({ data }: { data: { entities: ProjectModel[] } }) => {
        const normalizedJson = normalize(data.entities, Schemas.PROJECT_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function loadProject(projectId: number, options: EntityOptions = {}) {
  return (dispatch: AppDispatch, getState: () => RootReducerState) => {
    const state = getState()
    const loadedIds = [...state.projects.loadedIds]

    if (projectId && !loadedIds.includes(projectId)){
      loadedIds.push(projectId)
    }
    // Set Loading
    dispatch(fetchRequest({ loadedIds }))

    const promise = api(`/projects/${projectId}.json`, options)
      .then(({ data }: { data: { entity: ProjectModel } }) => {
        const normalizedJson = normalize(data.entity, Schemas.PROJECT)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function createProject(
  project: Partial<ProjectModel>,
  customFields: CustomFieldModel[],
  options: EntityOptions = {},
) {
  const config = {
    method: 'POST',
    body: JSON.stringify({
      data_store_item_values: customFields || [],
      project,
    }),
  }

  return (dispatch: AppDispatch) => {
    dispatch(createRequest())

    const promise = api('/projects.json', options, config)
      .then(({ data }: { data: { entity: ProjectModel } }) => {
        const normalizedJson = normalize(data.entity, Schemas.PROJECT)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function importProject(
  externalId: number,
  integrationPlatformKey: string,
  organizationId: number,
  options: EntityOptions = {},
) {
  const config = {
    method: 'POST',
    body: JSON.stringify({
      external_id: externalId,
      organization_id: organizationId,
    }),
  }

  return (dispatch: AppDispatch) => {
    dispatch(createRequest())

    const promise = api(`/projects/import_from_external_platform/${integrationPlatformKey}.json`, options, config)
      .then(({ data }: { data: { entity: ProjectModel } }) => {
        const normalizedJson = normalize(data.entity, Schemas.PROJECT)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function updateProject(
  project: UpdateParams<ProjectModel>,
  customFields: CustomFieldModel[],
  options: EntityOptions = {},
) {
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      data_store_item_values: customFields || [],
      project,
    }),
  }

  return (dispatch: AppDispatch) => {
    dispatch(updateRequest())

    const promise = api(`/projects/${project.id}.json`, options, config)
      .then(({ data }: { data: { entity: ProjectModel } }) => {
        const normalizedJson = normalize(data.entity, Schemas.PROJECT)

        const updateEntityOptions = {
          mergeOptions: {
            customMerge: (key: string) => {
              if (key === 'external_ids'){
                return (_, source: string) => source
              }

              return undefined
            },
          },
        }

        dispatch(updateEntities(normalizedJson, updateEntityOptions))
        dispatch(updateSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function deleteProject(project: DeleteParams<ProjectModel>) {
  const config = {
    method: 'DELETE',
  }

  return (dispatch: AppDispatch) => {
    dispatch(deleteRequest())

    const promise = api(`/projects/${project.id}.json`, {}, config)
      .then(({ data }: { data: { entity: ProjectModel } }) => {
        const normalizedJson = normalize(data.entity, Schemas.PROJECT)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(deleteFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function findByExternalDetails(options: EntityOptions = {}) {
  return (dispatch: AppDispatch) => {
    dispatch(fetchRequest())

    const promise = api('/projects/find_by_external_details.json', options)
      .then(({ data }: { data: { entity: ProjectModel } }) => {
        const normalizedJson = normalize(data.entity, Schemas.PROJECT)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(
  state: ProjectModuleState = initialState,
  action: Action = { type: '' },
): ProjectModuleState {
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loadedIds: action.loadedIds || state.loadedIds,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors || [],
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors || [],
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors || [],
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors || [],
      }
    default:
      return state
  }
}
