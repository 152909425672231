import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faUser } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Link, ListItem, Text, UserProfileImage,
} from '@campaignhub/suit-theme'

import useContact from '@hooks/useContact'

const Contact = (props) => {
  const { contact, contact: { first_name, last_name, mobile } } = props

  const contactPayload = useContact(contact)
  const { callbacks: { createOrEditContact }, userType } = contactPayload

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        borderBottom: '1px dashed',
        padding: 'large',
      }}
      disableHover
    >
      <Box marginRight="medium" width="auto">
        <UserProfileImage>
          <FontAwesomeIcon icon={faUser} />
        </UserProfileImage>
      </Box>

      <Box flexDirection="column" marginLeft="large">
        <Text fontSize="small" marginBottom="small">
          {first_name} {last_name}
        </Text>

        <Text color="faintGrey" fontSize="xsmall">
          {userType.title} - {mobile}
        </Text>
      </Box>

      <Link greyLink onClick={createOrEditContact} textProps={{ fontSize: 'small' }}>
        <FontAwesomeIcon icon={faPencil} />
      </Link>
    </ListItem>
  )
}

Contact.propTypes = {
  contact: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    mobile: PropTypes.string,
  }).isRequired,
}

export default Contact
