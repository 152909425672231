import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, Checkbox, ListItem,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const Review = (props) => {
  const {
    alreadyImported,
    callbacks: { toggleReview },
    checked,
    review,
  } = props

  const { address_title } = review

  const { strings } = useLocalization(localizedStrings)

  return (
    <ListItem.WithCheckbox
      boxProps={{ border: 'none' }}
      checkbox={(
        <Checkbox
          checked={checked}
          onClick={toggleReview}
        />
      )}
    >
      <Box
        color={alreadyImported ? 'lightGrey' : 'bodyFontLightColor'}
        fontSize="small"
      >
        {address_title} {alreadyImported && ` ${strings.imported || '(Imported)'}`}
      </Box>
    </ListItem.WithCheckbox>
  )
}

Review.propTypes = {
  alreadyImported: PropTypes.bool,
  callbacks: PropTypes.object.isRequired,
  review: PropTypes.object,
  checked: PropTypes.bool,
}

export default Review
