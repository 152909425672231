import React, {
  useContext, useEffect, useMemo, useState,
} from 'react'

import {
  launchModal, multiFilteredObjectArraySelector, sortArrayBy, toggleArray,
} from '@campaignhub/javascript-utils'

import { useSetState, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useDispatch from '@hooks/useDispatch'
import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import defaultRequestOptions from '@sections/Client/defaultRequestOptions'

import * as imageActions from '@redux/modules/image'

import type { ImageModel, ImageRequestOptions } from '@models/types'

import { AppDispatch } from '@redux/store'
import PageContext from '@contexts/pageContext'

const watchEntityKeys = ['images']

type DefaultStateType = {
  bulkSelectedImageIds: number[],
  bulkSelecting: boolean,
  manuallySorted: boolean,
}

const defaultState: DefaultStateType = {
  bulkSelectedImageIds: [],
  bulkSelecting: false,
  manuallySorted: false,
}

type SetSortedIds = React.Dispatch<React.SetStateAction<number[]>>
type SetState = (state: Partial<DefaultStateType>) => void

const selectImageId = (imageId: number, dispatch: AppDispatch) => {
  const { selectImageId: selectFn } = imageActions
  return dispatch(selectFn(imageId))
}

type UpdateImageSortOrderParams = {
  dispatch: AppDispatch,
  imagesIds: number[],
  requestOptions?: ImageRequestOptions,
}
const updateImageSortOrder = (params: UpdateImageSortOrderParams) => {
  const { dispatch, imagesIds, requestOptions } = params
  const { updateImageSortOrder: updateFn } = imageActions

  return dispatch(updateFn(imagesIds, requestOptions))
}

type UpdateDefaultImageParams = {
  dispatch: AppDispatch,
  image: ImageModel,
  setSortedIds: SetSortedIds,
  sortedIds: number[],
}

const updateDefaultImage = async (params: UpdateDefaultImageParams) => {
  const {
    image, sortedIds, setSortedIds, dispatch,
  } = params
  const imageId = image.id
  const index = sortedIds.indexOf(imageId)

  if (index !== -1){
    sortedIds.splice(index, 1)
    sortedIds.unshift(imageId)

    setSortedIds(sortedIds)
    const updatedImageSortOrder = await updateImageSortOrder({
      dispatch,
      imagesIds: sortedIds,
    })

    return updatedImageSortOrder
  }

  return { success: false, errors: ['Error updating default image'] }
}

type ModifyImageSortParams = {
  fromIndex: number,
  toIndex: number,
  setSortedIds: SetSortedIds,
  setState: SetState,
}

const modifyImageSort = (params: ModifyImageSortParams) => {
  const {
    fromIndex, toIndex, setSortedIds, setState,
  } = params

  setSortedIds((currentIds) => {
    const sortedIds: number[] = [...currentIds]
    const [removedItemId] = sortedIds.splice(fromIndex, 1)
    sortedIds.splice(toIndex, 0, removedItemId)

    return sortedIds
  })

  setState({ manuallySorted: true })
}

type SaveBulkSelectSortParams = {
  bulkSelectedImageIds: number[],
  sortedImages: ImageModel[],
  dispatch: AppDispatch,
  setSortedIds: SetSortedIds,
  setState: SetState,
  requestOptions: ImageRequestOptions,
}

const saveBulkSelectSort = (params: SaveBulkSelectSortParams) => {
  const {
    bulkSelectedImageIds, sortedImages, dispatch, setSortedIds, setState, requestOptions,
  } = params
  const unselectedImageIds = sortedImages.filter(image => !bulkSelectedImageIds.includes(image.id)).map(i => i.id)

  const sortedIds = [...bulkSelectedImageIds, ...unselectedImageIds]

  setSortedIds(sortedIds)
  setState({ bulkSelectedImageIds: [], bulkSelecting: false })

  updateImageSortOrder({ dispatch, imagesIds: sortedIds, requestOptions })
}

type toggleAllImagesParams = {
  bulkSelectedImageIds: number[],
  deselectAll: boolean,
  setState: SetState,
  sortedImages: ImageModel[],
}
const toggleAllImages = (params: toggleAllImagesParams) => {
  const {
    bulkSelectedImageIds, sortedImages, setState, deselectAll = false,
  } = params
  // All Selected - Select None
  if (bulkSelectedImageIds.length === sortedImages.length || deselectAll === true){
    setState({ bulkSelectedImageIds: [] })
    return
  }

  setState({ bulkSelectedImageIds: sortedImages.map(image => image.id) })
}

type BulkDeleteImagesParams = {
  bulkSelectedImageIds: number[],
  dispatch: AppDispatch,
  setState: SetState,
  requestOptions: ImageRequestOptions,
}

const bulkDeleteImages = async (params: BulkDeleteImagesParams) => {
  const {
    bulkSelectedImageIds, dispatch, setState, requestOptions,
  } = params
  const { bulkDelete: bulkDeleteFn } = imageActions

  const response = await dispatch(bulkDeleteFn(bulkSelectedImageIds, requestOptions))
  setState({ bulkSelectedImageIds: [] })

  return response
}

type UseImagesOptions = {
  customRequestOptions?: ImageRequestOptions,
  entityKey?: string,
  limit?: number,
  performHttpRequests?: boolean,
  subjectId?: number,
  subjectType?: number,
}

function useImages(options: UseImagesOptions) {
  const {
    customRequestOptions, entityKey, limit, performHttpRequests, subjectId, subjectType,
  } = options

  const requestOptions = {
    ...defaultRequestOptions.image,
    ...customRequestOptions,
    entityKey,
    subject_id: subjectId,
    subject_type: subjectType,
  }

  // Load Images
  useReduxAction(
    'images',
    'loadImages',
    requestOptions,
    [performHttpRequests, subjectId, subjectType],
    {
      shouldPerformFn: ({ loading }) => performHttpRequests && !loading,
    },
  )

  const [sortedIds, setSortedIds] = useState<number[]>([])

  const [state, setState] = useSetState(defaultState)
  const { bulkSelectedImageIds, bulkSelecting, manuallySorted } = state

  const dispatch = useDispatch()

  const { callbacks } = useContext(PageContext)
  const { setState: setStateCallback } = callbacks || {}

  const {
    updatedEntities: { images: imagesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { loading, selectedId } = useSelector(reduxState => reduxState.images)
  const entities = useSelector(reduxState => reduxState.entities)
  const { images } = entities

  const selectedImage = images[selectedId] || {}

  const imageFilters = [
    { key: 'subject_id', value: subjectId },
    { key: 'subject_type', value: subjectType },
  ]

  const filteredImages = useMemo(() => {
    const filtered = multiFilteredObjectArraySelector({ entities }, 'images', imageFilters)

    if (limit){
      return sortArrayBy(filtered, 'asc', 'sort').slice(0, limit)
    }

    return sortArrayBy(filtered, 'asc', 'sort')
  }, [imagesUpdatedAt, JSON.stringify(options)])

  const sortedImages = useMemo(() => {
    const sorted = sortedIds.map(imageId => images[imageId]).filter(i => i?.image_url)

    return sorted
  }, [JSON.stringify(sortedIds), imagesUpdatedAt])

  // Handle item sorting in state
  useEffect(() => {
    const sortedImageIds = filteredImages.map(item => item.id)
    setSortedIds(sortedImageIds)
  }, [filteredImages.length, imagesUpdatedAt])

  // Commit the sorted items
  useEffect(() => {
    if (manuallySorted){
      updateImageSortOrder({ imagesIds: sortedIds, dispatch })
      setState({ manuallySorted: false })
    }
  }, [JSON.stringify(sortedIds), manuallySorted])

  const hasImages = !!sortedImages.length
  const hasSelectedImages = !!bulkSelectedImageIds.length
  const allSelected = bulkSelectedImageIds.length === sortedImages.length

  return {
    allSelected,
    bulkSelectedImageIds,
    bulkSelecting,
    callbacks: {
      bulkDeleteImages: (entityOptions: ImageRequestOptions) => bulkDeleteImages(
        {
          bulkSelectedImageIds, dispatch, setState, requestOptions: entityOptions,
        },
      ),
      bulkSelectImageId: (imageId: number) => setState(
        { bulkSelectedImageIds: toggleArray(bulkSelectedImageIds, imageId) },
      ),
      editImages: () => launchModal({
        callbacks,
        modalKey: 'EditImagesModal',
        payload: {
          callbacks: {
            modifyImageSort: (fromIndex: number, toIndex: number) => modifyImageSort(
              {
                fromIndex, toIndex, setSortedIds, setState,
              },
            ),
            updateDefaultImage: (image: ImageModel) => updateDefaultImage({
              image, sortedIds, setSortedIds, dispatch,
            }),
          },
          sortedImages,
          subjectId,
          subjectType,
        },
      }),
      modifyImageSort: (fromIndex: number, toIndex: number) => modifyImageSort(
        {
          fromIndex, toIndex, setSortedIds, setState,
        },
      ),
      saveBulkSelectSort: (entityOptions: ImageRequestOptions) => saveBulkSelectSort(
        {
          bulkSelectedImageIds, dispatch, setSortedIds, setState, sortedImages, requestOptions: entityOptions,
        },
      ),
      selectImage: () => launchModal({
        callbacks,
        modalKey: 'SelectImageModal',
        payload: {
          callbacks: {
            selectImage: (image: ImageModel) => {
              const imageIds = sortedIds.includes(image.id) ? sortedIds : [...sortedIds, image.id]

              updateDefaultImage({
                dispatch, image, setSortedIds, sortedIds: imageIds,
              })
              setStateCallback({ showSelectImageModal: false })
            },
          },
        },
      }),
      selectImageId: (imageId: number) => selectImageId(imageId, dispatch),
      setState,
      toggleAllImages: (deselectAll: boolean) => toggleAllImages({
        bulkSelectedImageIds, sortedImages, setState, deselectAll,
      }),
      updateDefaultImage: (image: ImageModel) => updateDefaultImage({
        image, sortedIds, setSortedIds, dispatch,
      }),
      updateImageSortOrder: (
        imagesIds: number[],
        entityOptions: ImageRequestOptions,
      ) => updateImageSortOrder({ imagesIds, dispatch, requestOptions: entityOptions }),
    },
    filteredImages,
    hasImages,
    hasSelectedImages,
    loading,
    selectedId,
    selectedImage,
    sortedImageIds: sortedIds,
    sortedImages,
  }
}

export default useImages
