import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'

import { useModals, useSetState } from '@campaignhub/react-hooks'
import { MainContent, ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import useIntercom from '@hooks/useIntercom'
import useLocalization from '@hooks/useLocalization'
import useNumericParams from '@hooks/useNumericParams'

import handleCallbackAction from '@functions/handleCallbackAction'

import CreateOrEditCustomFieldModal from '@modals/CreateOrEditCustomFieldModal'
import ManageFormBuilderModal from '@modals/ManageFormBuilderModal'

import useFormBuilder from './hooks/useFormBuilder'

import defaultRequestOptions from './defaultRequestOptions'

import localizedStrings from './localizedStrings'

import FormTemplate from './components/FormTemplate'
import Header from './components/Header'

const addFormField = (setState, clearSelectedCustomFieldId) => {
  clearSelectedCustomFieldId()
  setState({ showCreateOrEditCustomFieldModal: true })
}

const callbacks = (component, setState, formBuilderCallbacks) => {
  const { clearSelectedCustomFieldId } = formBuilderCallbacks || {}

  const componentCallbacks = {
    CreateOrEditCustomFieldModal: {
      closeModal: () => setState({ showCreateOrEditCustomFieldModal: false }),
      createCustomField: payload => handleCallbackAction(payload),
      deleteCustomField: payload => handleCallbackAction(payload),
      updateCustomField: payload => handleCallbackAction(payload),
    },
    ManageFormBuilderModal: {
      addFormField: () => addFormField(setState, clearSelectedCustomFieldId),
      closeModal: () => setState({ showManageFormBuilderModal: false }),
      deleteFormTemplate: payload => handleCallbackAction(payload),
      updateFormTemplate: payload => handleCallbackAction({ ...payload, requestOptions: defaultRequestOptions.formTemplate }),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  showCreateOrEditCustomFieldModal: false,
  showManageFormBuilderModal: false,
}

const FormBuilder = () => {
  const [state, setState] = useSetState(defaultState)
  const { showCreateOrEditCustomFieldModal, showManageFormBuilderModal } = state

  const { formTemplateId } = useNumericParams()

  const modalContext = useModals()
  const {
    callbacks: { registerModal, setModalData },
  } = modalContext

  const formBuilderPayload = useFormBuilder({
    callbacks: {
      showCreateOrEditCustomFieldModal: (payload) => {
        setModalData('CreateOrEditCustomFieldModal', payload)
        setState({ showCreateOrEditCustomFieldModal: true })
      },
    },
    formTemplateId,
  })

  const {
    callbacks: formBuilderCallbacks, isMobile, selectedCustomFieldId, selectedCustomField,
  } = formBuilderPayload

  useEffect(() => {
    // Wait for the register function otherwise the modal
    // doesn't get added to the open modals array
    if (!isMobile && registerModal){
      setState({
        showManageFormBuilderModal: true,
      })
    }
  }, [isMobile])

  useIntercom({horizontalPadding: 400 })

  const { strings } = useLocalization(localizedStrings)

  const title = strings.title || 'Form Builder | Engage'

  return (
    <div id="main-stage" style={{ position: 'relative' }}>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <PageContext.Provider value={formBuilderPayload}>
        <ModalContext.Provider value={modalContext}>
          <MainContent offset={{ left: 0, top: 71 }} width={!isMobile ? 'calc(100% - 375px)' : '100%'}>
            <Header formBuilderPayload={formBuilderPayload} />

            <FormTemplate formBuilderPayload={formBuilderPayload} />

            <CreateOrEditCustomFieldModal
              callbacks={callbacks('CreateOrEditCustomFieldModal', setState, strings)}
              customField={
                selectedCustomFieldId
                  ? selectedCustomField
                  : { owner_type: 'FormTemplate', owner_id: formTemplateId, entity_type: 'FormTemplate' }
              }
              hiddenFieldKeys={['tags', 'width']}
              showModal={showCreateOrEditCustomFieldModal}
            />

            <ManageFormBuilderModal
              callbacks={callbacks('ManageFormBuilderModal', setState, formBuilderCallbacks)}
              showModal={showManageFormBuilderModal}
            />
          </MainContent>
        </ModalContext.Provider>
      </PageContext.Provider>
    </div>
  )
}

FormBuilder.propTypes = {}

export default FormBuilder
