export default {
  default: {
    button: {
      archive: 'Archive Project',
      customize: 'Customise Dashboard',
      unarchive: 'Unarchive Project',
    },
    swal: {
      archiveConfirmButtonText: 'Yes, archive it.',
      archiveTitle: 'Archive Project?',
      text: 'Are you sure?',
      unarchiveConfirmButtonText: 'Yes, unarchive it.',
      unarchiveTitle: 'Unarchive Project?',
    },
    title: 'Tools',
    toast: {
      updated: 'Project successfully updated',
    },
  },
}
