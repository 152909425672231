import React from 'react'
import PropTypes from 'prop-types'

import {
  ListItem, Text,
} from '@campaignhub/suit-theme'

const FormTemplate = (props) => {
  const {
    callbacks: { selectTemplate },
    formTemplate: { title },
  } = props

  return (
    <ListItem
      boxProps={{
        border: '1px solid',
        borderColor: 'lineColor',
        borderRadius: 5,
        marginBottom: 'medium',
        padding: 'large',
      }}
      forceBottomBorder
      onClick={selectTemplate}
      showAngleIcon
    >
      <Text fontSize="small" variant="ellipsis">{title}</Text>
    </ListItem>
  )
}

FormTemplate.propTypes = {
  callbacks: PropTypes.shape({
    selectTemplate: PropTypes.func.isRequired,
  }).isRequired,
  formTemplate: PropTypes.object.isRequired,
}

export default FormTemplate
