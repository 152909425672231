import React from 'react'

import { BlankState } from '@campaignhub/suit-theme'

import BlankStateImageUrl from './assets/no-form-templates.svg'

const FormTemplatesBlankState = () => (
  <BlankState boxProps={{ marginBottom: ['large', 0] }} imageUrl={BlankStateImageUrl}>
    <BlankState.Title>No Component Styles</BlankState.Title>

    <BlankState.Paragraph>
      You either haven't created any Styles, or you need to adjust your filters to return more results.
    </BlankState.Paragraph>
  </BlankState>
)

export default FormTemplatesBlankState
