import React, { useEffect, useMemo } from 'react'

import { ModalContext } from '@campaignhub/suit-theme'
import { useModals, useSetState } from '@campaignhub/react-hooks'

import PageContext from '@contexts/pageContext'

import useBrandSelector from '@hooks/useBrandSelector'
import useCurrentUser from '@hooks/useCurrentUser'
import useReduxAction from '@hooks/useReduxAction'

import CreateOrEditDataSetModal from '@modals/CreateOrEditDataSetModal'

import PageContent from './components/PageContent'

const callbacks = (
  component: string,
  setState: React.Dispatch<React.SetStateAction<any>>,
) => {
  const componentCallbacks: { [key: string]: { closeModal: () => void } } = {
    CreateOrEditDataSetModal: {
      closeModal: () => {
        setState({ showCreateOrEditDataSetModal: false })
      },
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  pageFilters: {},
  showCreateOrEditDataSetModal: false,
}

const DataLibrary = () => {
  const [state, setState] = useSetState(defaultState)
  const { pageFilters, showCreateOrEditDataSetModal } = state
  const [refetchData, setRefetchData] = useSetState(false)

  useReduxAction('brands', 'loadBrands', {})

  const { selectedBrand } = useBrandSelector()

  const { isAdmin } = useCurrentUser()

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  useEffect(() => {
    if (selectedBrand?.length === 1){
      setState({ pageFilters: { brand_id: selectedBrand?.id } })
    }
  }, [selectedBrand.length])

  const pageContext = useMemo(() => ({
    callbacks: {
      resetFilters: (resetFn: Function) => {
        if (resetFn) resetFn()
        setState({ pageFilters: {} })
      },
      showCreateOrEditDataSetModal: (payload: any) => {
        setModalData('CreateOrEditDataSetModal', payload)
        setState({ showCreateOrEditDataSetModal: true })
      },
      updateFilters: (filters: any) => {
        setState({ pageFilters: filters })
      },
    },
    refetchData,
    setRefetchData,
  }), [modalContext, pageFilters, selectedBrand, showCreateOrEditDataSetModal])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent
          isAdmin={isAdmin}
          pageFilters={pageFilters}
          selectedBrand={selectedBrand}
        />

        <CreateOrEditDataSetModal
          callbacks={callbacks('CreateOrEditDataSetModal', setState)}
          selectedBrand={selectedBrand}
          showModal={showCreateOrEditDataSetModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default DataLibrary
