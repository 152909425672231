import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import { faChartLine } from '@fortawesome/pro-light-svg-icons'

import useChartData from '@hooks/useChartData'
import useLocalization from '@hooks/useLocalization'
import useProject from '@hooks/useProject'

import { Box, DashboardModule, Link } from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import BarChart from '@components/charts/BarChart'
import LineChart from '@components/charts/LineChart'

import analyticsImageUrl from './assets/analytics.svg'

import localizedStrings from './localizedStrings'

export const icon = faChartLine

const getChartDetails = (chartKey, strings) => {
  const chartDetails = {
    digital_pages_accepted_vs_created_per_month: {
      title: strings.chartKeyValues?.acceptedVsCreatedPerMonth || 'Pages Accepted vs Created per Month',
      component: LineChart,
      excludedDataKeys: ['name'],
    },
    digital_pages_engagement_per_day: {
      title: strings.chartKeyValues?.digitalPagesEngagementPerDay || 'Digital Pages Engagement Per Day',
      component: LineChart,
      excludedDataKeys: ['name'],
      options: { chartOptions: { includeXAxis: true } },
    },
    digital_pages_created_vs_viewed_per_day: {
      title: strings.chartKeyValues?.digitalPagesCreatedvsViewedPerDay || 'Digital Pages Created vs Viewed Per Day',
      component: LineChart,
      excludedDataKeys: ['name'],
      options: { chartOptions: { includeXAxis: true } },
    },
    digital_pages_views_vs_sent_ratio_per_day: {
      title: strings.chartKeyValues?.digitalPagesViewsVsSentPerDay || 'Digital Pages Views Vs Sent Ratio Per Day',
      component: BarChart,
      excludedDataKeys: ['name', 'unit'],
      options: { chartOptions: { barSize: 25, includeXAxis: true } },
    },
    total_page_views_created_per_month: {
      title: strings.chartKeyValues?.totalPageViewsPerMonth || 'Total Page Views per Month',
      component: LineChart,
      excludedDataKeys: ['name'],
    },
  }

  return chartDetails[chartKey] || {}
}

export const configOptions = strings => ({
  chart_key: {
    component: 'select',
    key: 'chart_key',
    label: strings.chartKey?.label || 'Chart Type',
    optionKey: 'customization',
    values: [
      {
        key: 'digital_pages_accepted_vs_created_per_month',
        label: strings.chartKeyValues?.acceptedVsCreatedPerMonth || 'Pages Accepted vs Created per Month',
      },
      {
        key: 'digital_pages_engagement_per_day',
        label: strings.chartKeyValues?.digitalPagesEngagementPerDay || 'Digital Pages Engagement Per Day',
      },
      {
        key: 'digital_pages_created_vs_viewed_per_day',
        label: strings.chartKeyValues?.digitalPagesCreatedvsViewedPerDay || 'Digital Pages Created vs Viewed Per Day',
      },
      {
        key: 'digital_pages_views_vs_sent_ratio_per_day',
        label: strings.chartKeyValues?.digitalPagesViewsVsSentPerDay || 'Digital Pages Views Vs Sent Ratio Per Day',
      },
      {
        key: 'total_page_views_created_per_month',
        label: strings.chartKeyValues?.totalPageViewsPerMonth || 'Total Page Views per Month',
      },
    ],
  },
})

const Trends = (props) => {
  const { customOptions, editing } = props

  const {
    customization,
    queryParams: { entity_id, entity_type },
  } = customOptions

  const { chart_key } = customization || {}

  const { projectId } = useParams()

  const {
    urls: { analyticsUrl },
  } = useProject({ id: projectId })

  const { strings } = useLocalization(localizedStrings)

  const chartDetailsPayload = getChartDetails(chart_key, strings)
  const {
    excludedDataKeys, title: chartTitle, component,
  } = chartDetailsPayload

  const ChartComponent = component || LineChart

  const chartOptions = digObject(chartDetailsPayload, 'options.chartOptions', {})

  const dataPayload = useChartData(chart_key, {
    entityId: entity_id,
    entityType: entity_type,
    excludedDataKeys,
  })
  const { dataArray, loading } = dataPayload

  return (
    <DashboardModule
      boxProps={{ marginBottom: 0 }}
      editing={editing}
      headerRight={
        projectId ? (
          <Link href={analyticsUrl} textProps={{ fontSize: 'small' }}>
            {strings.manage || 'Manage'}
          </Link>
        ) : null
      }
      loading={loading}
      title={chartTitle || 'Trends'}
    >
      {!loading && !dataArray.length && (
        <DashboardModule.BlankState imageUrl={analyticsImageUrl}>
          <DashboardModule.BlankState.Title>
            {strings.blankState?.title || 'No Trend Data to Display'}
          </DashboardModule.BlankState.Title>

          <DashboardModule.BlankState.Paragraph>
            {strings.blankState?.paragraph
              || 'Once pages have been created and viewed trend data will be recorded and displayed.'}
          </DashboardModule.BlankState.Paragraph>
        </DashboardModule.BlankState>
      )}

      {!loading && !!dataArray.length && (
        <Box flexDirection="column" flexWrap="wrap" padding="medium">
          <ChartComponent
            chartOptions={chartOptions}
            dataPayload={dataPayload}
            height={200}
            width="100%"
          />
        </Box>
      )}
    </DashboardModule>
  )
}

Trends.propTypes = {
  customOptions: PropTypes.object.isRequired,
  editing: PropTypes.bool,
}

export default Trends
