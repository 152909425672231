import React from 'react'
import PropTypes from 'prop-types'

import { DashboardGuideModule } from '@campaignhub/suit-theme'

// import guideImageUrl from './assets/select-section-small.svg'
import guideImageUrl from './assets/select-section.svg'

const SelectSectionHint = () => (
  <DashboardGuideModule boxProps={{ marginBottom: 'large' }} imageUrl={guideImageUrl}>
    <DashboardGuideModule.Title>Choose a Section</DashboardGuideModule.Title>
    <DashboardGuideModule.Paragraph>
      Choose from the sections below, or find sections within any template in your library by changing template in the
      sidebar.
    </DashboardGuideModule.Paragraph>
  </DashboardGuideModule>
)

SelectSectionHint.propTypes = {}

export default SelectSectionHint
