import { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, FormField, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'
import { digObject } from '@campaignhub/javascript-utils'

import useLocalization from '@hooks/useLocalization'
import useMixpanel from '@hooks/useMixpanel'
import useShareLink from '@hooks/useShareLink'
import useShareLinks from '@hooks/useShareLinks'
import useVideoModules from '@hooks/useVideoModules'

import VideoModule from '@components/VideoModule'

import ShareLink from './components/ShareLink'
import localizedStrings from './localizedStrings'

const ManageShareLinksModal = (props) => {
  const {
    callbacks, disableAnimation, disableOverlay, modalKey, showModal,
  } = props
  const { createShareLink } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const { digitalPage } = digObject(modalData, `${modalKey}`, {})

  const { filteredShareLinks } = useShareLinks({
    filters: {
      printLink: false,
      subjectId: digitalPage.id,
      subjectType: 'DigitalPage',
    },
  })

  const initShareLink = {
    notifications_enabled: true,
    owner_id: digitalPage.owner_id,
    owner_type: 'Organization',
    subject_id: digitalPage.id,
    subject_type: 'DigitalPage',
    title: `Tracking Link ${DateTime.now().toISODate()}`,
  }

  const {
    callbacks: { createShareLink: createFn, editShareLink },
    shareLink,
  } = useShareLink(initShareLink)

  const videoModulePayload = useVideoModules({ key: 'manageShareLinks' })
  const { videosPayload } = videoModulePayload

  const { callbacks: { dispatchMixpanelEvent } } = useMixpanel()

  useEffect(() => {
    dispatchMixpanelEvent('Digital Page Shared')
  }, [])

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal
      callbacks={callbacks}
      disableAnimation={disableAnimation}
      disableOverlay={disableOverlay}
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.sideBarModalHeader?.title || 'Share'}
        titleSecondLine={strings.sideBarModalHeader?.titleSecondLine || 'Links'}
      />
      <SidebarModal.Content hasFooter={false}>
        {filteredShareLinks.map((link, index) => (
          <ShareLink isFirst={index === 0} key={link.id} shareLink={link} />
        ))}

        <FormField label={strings.formFieldLabels?.moreOptions || 'More Options'} marginTop="large">
          <Box flexDirection="column">
            <Button
              buttonStyle="secondaryUtility"
              icon={<FontAwesomeIcon icon={faPlus} />}
              onClick={() => createShareLink(shareLink, createFn, editShareLink)}
              size="medium"
              marginBottom="medium"
            >
              {strings.buttons?.createNewLink || 'Create New Link'}
            </Button>
          </Box>
        </FormField>

        <Box marginTop="medium">
          <VideoModule videosPayload={videosPayload} />
        </Box>
      </SidebarModal.Content>
    </SidebarModal>
  )
}

ManageShareLinksModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  disableAnimation: PropTypes.bool,
  disableOverlay: PropTypes.bool,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

ManageShareLinksModal.defaultProps = {
  modalKey: 'ManageShareLinksModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <ManageShareLinksModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
