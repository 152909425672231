import React from 'react'
import PropTypes from 'prop-types'

import { Box, ListItem, Text } from '@campaignhub/suit-theme'

const BrandListItem = (props) => {
  const { brand: { id, title } } = props

  return (
    <ListItem
      boxProps={{ as: 'a', borderBottom: '1px dashed', padding: 'large' }}
      href={`#/systemManager/brands/${id}/edit`}
      showAngleIcon
    >
      <Box justifyContent="flex-start" minWidth="0" paddingRight="medium" width="auto">
        <Text fontSize="small" variant="ellipsis">
          {title}
        </Text>
      </Box>
    </ListItem>
  )
}

BrandListItem.propTypes = {
  brand: PropTypes.object.isRequired,
}

export default BrandListItem
