import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobeAsia, faUser } from '@fortawesome/pro-solid-svg-icons'

import { Box, ListItem, Text } from '@campaignhub/suit-theme'

const CustomFieldSet = (props) => {
  const {
    fieldSet: {
      entity_type, id, owner_title, owner_type, title,
    },
  } = props

  return (
    <ListItem
      boxProps={{
        alignItems: 'center', as: 'a', borderBottom: '1px dashed', padding: 'large',
      }}
      href={`#/systemManager/customFieldSets/${id}/edit`}
      showAngleIcon
    >
      <Box flexDirection="column" justifyContent="flex-start" minWidth="0" paddingRight="medium" width="auto">
        <Text fontSize="small" variant="ellipsis">
          <FontAwesomeIcon icon={owner_type === 'System' ? faGlobeAsia : faUser} /> {entity_type}
        </Text>

        <Text
          color="bodyFontLightColor"
          fontSize="xsmall"
          marginTop="medium"
          style={{ whiteSpace: 'nowrap' }}
        >
          {owner_title}
        </Text>
      </Box>

      <Box display={['none', 'flex']} justifyContent="flex-end" marginLeft="auto" width="auto">
        <Text color="bodyFontLightColor" fontSize="small" lineHeight={1.3} style={{ whiteSpace: 'nowrap' }}>
          {title}
        </Text>
      </Box>
    </ListItem>
  )
}

CustomFieldSet.propTypes = {
  fieldSet: PropTypes.object.isRequired,
}

export default CustomFieldSet
