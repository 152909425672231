import { digObject, toggleArray } from '@campaignhub/javascript-utils'

type ToggleOptionValuesType = {
  key: string,
  label: string,
  options: Record<string, {}>,
  optionValues: {
    input_type: string,
    option_values: [],
    tags: string[],
    width: number,
  },
  setEntityState: (state: Record<string, any>) => void,
}

const toggleOptionValues = (params: ToggleOptionValuesType) => {
  const {
    key, label, optionValues, options, setEntityState,
  } = params

  const updatedOptionValues = toggleArray(optionValues, { key, value: label }, { deepCompare: true })

  const updatedOptions = {
    ...options,
    option_values: updatedOptionValues,
  }

  setEntityState({ options: updatedOptions })
}

export type FieldOptions = {
  option_values: Record<string, {key: string, value:string}>[],
}

type FieldOptionsType = {
    fieldKey: string,
    setEntityState: (state: Record<string, any>) => void,
}

function useFieldOptionValues(fieldOptions: FieldOptions, options: FieldOptionsType) {
  const optionValues = digObject(fieldOptions, 'option_values', [])
  const { fieldKey, setEntityState } = options || {}

  const optionValueExists = optionValues.some(optionValue => optionValue.key === fieldKey)

  return {
    callbacks: {
      toggleOptionValues: (key: string, label: string) => toggleOptionValues({
        key, label, optionValues, fieldOptions, setEntityState,
      }),
    },
    optionValueExists,
  }
}

export default useFieldOptionValues
