export default {
  default: {
    button: {
      customize: 'Customise Dashboard',
    },
    VideoModule: {
      title: 'Analytics',
      paragraph: 'Analytics and what hot, warm & cold proposals mean.',
    },
    tools: 'Tools',
  },
}
