import { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'

import { ModalContext } from '@campaignhub/suit-theme'
import { useModals, useSetState } from '@campaignhub/react-hooks'

import useCurrentUser from '@hooks/useCurrentUser'
import useLocalization from '@hooks/useLocalization'
import useNumericParams from '@hooks/useNumericParams'
import useOrganizationSelector from '@hooks/useOrganizationSelector'

import handleCallbackAction from '@functions/handleCallbackAction'

import PageContext from '@contexts/pageContext'

import defaultRequestOptions from '@sections/Client/packs/Admin/defaultRequestOptions'

import AddUserToOrganizationModal from '@modals/AddUserToOrganizationModal'
import CreateOrEditAwardModal from '@modals/CreateOrEditAwardModal'
import EditAwardSortModal from '@modals/EditAwardSortModal'
import EditImageModal from '@modals/EditImageModal'
import EditOrganizationsUserModal from '@modals/EditOrganizationsUserModal'
import RemoveOrganizationsUserModal from '@modals/RemoveOrganizationsUser'

import PageContent from './components/PageContent'
import localizedStrings from './localizedStrings'

const callbacks = (component, setState) => {
  const componentCallbacks = {
    AddUserToOrganizationModal: {
      closeModal: () => setState({ showAddUserToOrganizationModal: false }),
      createOrganizationsUser: payload => handleCallbackAction(payload),
    },
    CreateOrEditAwardModal: {
      closeModal: () => setState({ showCreateOrEditAwardModal: false }),
      createAward: payload => handleCallbackAction(payload),
      deleteAward: payload => handleCallbackAction(payload),
      updateAward: payload => handleCallbackAction(payload),
    },
    EditAwardSortModal: {
      closeModal: () => setState({ showEditAwardSortModal: false }),
      updateAwardSortOrder: payload => handleCallbackAction(payload),
    },
    EditOrganizationsUserModal: {
      closeModal: () => setState({ showEditOrganizationsUserModal: false }),
      deleteOrganizationsUser: payload => handleCallbackAction(payload),
      setDefaultOrganization: payload => handleCallbackAction(payload),
      updateOrganizationsUser: payload => handleCallbackAction({
        ...payload,
        requestOptions: defaultRequestOptions.user,
      }),
    },
    EditImageModal: {
      closeModal: () => setState({ showEditImageModal: false }),
      deleteImage: payload => handleCallbackAction(payload),
    },
    RemoveOrganizationsUserModal: {
      closeModal: () => setState({ showEditOrganizationsUserModal: false, showRemoveOrganizationsUserModal: false }),
      removeUserFromOrganization: payload => handleCallbackAction(payload),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  showAddUserToOrganizationModal: false,
  showCreateOrEditAwardModal: false,
  showEditAwardSortModal: false,
  showEditImageModal: false,
  showEditOrganizationsUserModal: false,
  showRemoveOrganizationsUserModal: false,
}

const EditUser = () => {
  const [state, setState] = useSetState(defaultState)
  const {
    showAddUserToOrganizationModal,
    showCreateOrEditAwardModal,
    showEditAwardSortModal,
    showEditImageModal,
    showEditOrganizationsUserModal,
    showRemoveOrganizationsUserModal,
  } = state

  const { userId } = useNumericParams()
  const { selectedOrganization } = useOrganizationSelector()
  const { currentUser } = useCurrentUser()

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const pageContext = useMemo(
    () => ({
      callbacks: {
        showAddUserToOrganizationModal: (payload) => {
          setModalData('AddUserToOrganizationModal', payload)
          setState({ showAddUserToOrganizationModal: true })
        },
        showCreateOrEditAwardModal: (payload) => {
          setModalData('CreateOrEditAwardModal', payload)
          setState({ showCreateOrEditAwardModal: true })
        },
        showEditAwardSortModal: (payload) => {
          setModalData('EditAwardSortModal', payload)
          setState({ showEditAwardSortModal: true })
        },
        showEditImageModal: (payload) => {
          setModalData('EditImageModal', payload)
          setState({ showEditImageModal: true })
        },
        showEditOrganizationsUserModal: (payload) => {
          setModalData('EditOrganizationsUserModal', payload)
          setState({ showEditOrganizationsUserModal: true })
        },
        showRemoveOrganizationsUserModal: (payload) => {
          setModalData('RemoveOrganizationsUserModal', payload)
          setState({ showRemoveOrganizationsUserModal: true })
        },
      },
    }),
    [modalContext],
  )

  const { strings } = useLocalization(localizedStrings)

  const title = strings.title ? `${strings.title} | Engage` : 'Engage'

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <PageContext.Provider value={pageContext}>
        <ModalContext.Provider value={modalContext}>
          <PageContent userId={userId} />

          <AddUserToOrganizationModal
            callbacks={callbacks('AddUserToOrganizationModal', setState, strings)}
            organizationsUser={{
              approved: true,
              approved_by: currentUser.id,
              organization_id: selectedOrganization.id,
              user_id: userId,
            }}
            showModal={showAddUserToOrganizationModal}
          />

          <EditImageModal callbacks={callbacks('EditImageModal', setState, strings)} showModal={showEditImageModal} />

          <EditOrganizationsUserModal
            callbacks={callbacks('EditOrganizationsUserModal', setState, strings)}
            showModal={showEditOrganizationsUserModal}
          />

          <CreateOrEditAwardModal
            award={{
              owner_id: selectedOrganization.id,
              owner_type: 'Organization',
              subject_id: userId,
              subject_type: 'User',
            }}
            callbacks={callbacks('CreateOrEditAwardModal', setState, strings)}
            showModal={showCreateOrEditAwardModal}
          />

          <EditAwardSortModal
            callbacks={callbacks('EditAwardSortModal', setState)}
            ownerId={selectedOrganization?.id}
            subjectId={userId}
            subjectType="User"
            showModal={showEditAwardSortModal}
          />

          <RemoveOrganizationsUserModal
            callbacks={callbacks('RemoveOrganizationsUserModal', setState, strings)}
            showModal={showRemoveOrganizationsUserModal}
          />
        </ModalContext.Provider>
      </PageContext.Provider>
    </>
  )
}

export default EditUser
