import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import { Link, SidebarModal } from '@campaignhub/suit-theme'

import useContact from '@hooks/useContact'

const Contact = (props) => {
  const {
    callbacks: { deleteContact },
    contactId,
  } = props

  const {
    callbacks: { createOrEditContact },
    contact: {
      id, mobile, first_name, last_name,
    },
  } = useContact({ id: contactId })

  return (
    <SidebarModal.User
      boxProps={{ paddingY: 'large' }}
      disableHover
      key={id}
      subtitle={mobile}
      title={`${first_name} ${last_name}`}
    >
      <Link greyLink onClick={() => createOrEditContact({})} textProps={{ fontSize: 'small', marginRight: 'medium' }}>
        <FontAwesomeIcon icon={faPencil} />
      </Link>

      <Link greyLink onClick={deleteContact} textProps={{ fontSize: 'small' }}>
        <FontAwesomeIcon icon={faTrashAlt} />
      </Link>
    </SidebarModal.User>
  )
}

Contact.propTypes = {
  callbacks: PropTypes.shape({
    deleteContact: PropTypes.func,
    toggleContact: PropTypes.func,
  }).isRequired,
  contactId: PropTypes.number.isRequired,
}

export default Contact
