import React from 'react'
import PropTypes from 'prop-types'

const mapItems = (parentItem, itemEntities) => {
  if (parentItem.items && itemEntities){
    return parentItem.items.map((item) => {
      if (typeof item === 'object'){
        return item
      }

      return itemEntities[item] || {}
    })
  }

  return parentItem.items || []
}

const wrapItems = (availableComponents, items, options = {}) => items.map((item) => {
  const Component = item ? availableComponents[item.item_type] : null
  if (Component){
    const {
      callbacks,
      dataContext,
      forwardContextToItems,
      itemEntities,
      mergeItemOptions,
      renderNested,
      selectedItem,
    } = options

    const { selectCanvasItem } = callbacks || {}
    const selectObject = { type: 'digitalTemplatePageItem', id: item.id }

    const mergedItem = {
      ...item,
      options: {
        ...item.options,
        ...mergeItemOptions,
      },
    }

    if (renderNested){
      const nestedItems = mapItems(mergedItem, itemEntities)

      return (
        <Component
          callbacks={callbacks}
          context={forwardContextToItems}
          dataContext={dataContext}
          entityType="digitalTemplatePageItem"
          itemEntities={itemEntities}
          isSelected={selectedItem.id === mergedItem.id}
          key={item.id}
          onClick={selectCanvasItem ? e => selectCanvasItem(selectObject, e, { dataContext }) : null}
          selectedItem={selectedItem}
          {...mergedItem}
        >
          {wrapItems(availableComponents, nestedItems, options)}
        </Component>
      )
    }

    return (
      <Component
        key={mergedItem.id}
        entityType="digitalTemplatePageItem"
        entityId={mergedItem.id}
        isSelected={selectedItem.id === mergedItem.id}
        onClick={selectCanvasItem ? e => selectCanvasItem(selectObject, e) : null}
        {...mergedItem}
      />
    )
  }

  return null
})

const ContentWrapper = (props) => {
  const {
    availableComponents,
    callbacks,
    dataContext,
    forwardContextToItems,
    itemEntities,
    mergeItemOptions,
    render,
    renderNested,
    section,
    selectedItem,
  } = props

  const itemArray = mapItems(section, itemEntities)

  return render({
    items: itemArray,
    itemComponents: wrapItems(availableComponents, itemArray, {
      callbacks,
      dataContext,
      forwardContextToItems,
      itemEntities,
      mergeItemOptions,
      renderNested,
      selectedItem,
    }),
  })
}

ContentWrapper.propTypes = {
  availableComponents: PropTypes.object.isRequired,
  callbacks: PropTypes.shape({
    selectCanvasItem: PropTypes.func,
  }),
  dataContext: PropTypes.array,
  forwardContextToItems: PropTypes.object,
  render: PropTypes.func.isRequired,
  renderNested: PropTypes.bool,
  section: PropTypes.object.isRequired,
  selectedItem: PropTypes.object,
}

ContentWrapper.defaultProps = {
  callbacks: {},
  renderNested: false,
  selectedItem: {},
}

export default ContentWrapper
