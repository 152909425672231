import { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import PropTypes from 'prop-types'

import { faCheckCircle, faGlobe, faPencil } from '@fortawesome/pro-light-svg-icons'

import { getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'
import {
  Box,
  Button,
  Columns,
  DashboardModule,
  LoadingModule,
  PageHeader,
} from '@campaignhub/suit-theme'

import PageFilterModule from '@components/PageFilterModule'

import PageContext from '@contexts/pageContext'

import useFormTemplates from '@hooks/useFormTemplates'
import useLocalization from '@hooks/useLocalization'
import { generateUrls } from '@hooks/useFormTemplate'

import FormTemplate from '../FormTemplate'
import FormTemplatesBlankState from '../FormTemplatesBlankState'

import localizedStrings from './localizedStrings'

const buildPageFilterFields = (strings) => {
  const style = {
    marginBottom: 16,
  }

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        style,
      },
      key: 'string',
      label: strings.filters?.title || 'Filter by Title or ID',
    },
  ]
}

const PageContent = (props) => {
  const { pageFilters, selectedOrganization } = props

  useLocation()

  const { status } = parsePermittedQueryParams(getQueryParamsFromHash(), ['status'])
  const currentStatus = status || 'all'

  const entityKey = currentStatus === 'published' ? 'FormTemplates-ProductionReady' : 'FormTemplates-Draft'

  const pageContext = useContext(PageContext)
  const {
    callbacks: {
      resetFilters, showCreateFormTemplateModal, updateFilters,
    },
  } = pageContext

  const formTemplatePayload = useFormTemplates({
    entityKey,
    filters: {
      production_ready: currentStatus !== 'all' ? currentStatus === 'published' : undefined,
      ...pageFilters,
    },
    ownerId: selectedOrganization.id,
    ownerType: 'Organization',
    performHttpRequests: true,
  })

  const { filteredFormTemplates, hasFormTemplates, loading } = formTemplatePayload

  const {
    draftFormTemplates,
    formTemplatesIndexUrl,
    publishedFormTemplates,
  } = generateUrls(false, {})

  const { strings } = useLocalization(localizedStrings)

  const title = strings.title ? `${strings.title || 'Form Templates'} | Engage` : 'Engage'

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <PageHeader
        activeTabBarItemKey={currentStatus}
        actionContent={(
          <Button buttonStyle="primaryCreate" onClick={() => showCreateFormTemplateModal()} size="medium">
            {strings.createButton || 'Create'}
          </Button>
        )}
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        nestedNavigation
        tabBarItems={[
          {
            href: formTemplatesIndexUrl,
            icon: faGlobe,
            key: 'all',
            title: strings.tabBarItems?.all || 'All',
          },
          {
            href: publishedFormTemplates,
            icon: faCheckCircle,
            key: 'published',
            title: strings.tabBarItems?.published || 'Published',
          },
          {
            href: draftFormTemplates,
            icon: faPencil,
            key: 'draft',
            title: strings.tabBarItems?.draft || 'Draft',
          },
        ]}
        title={strings.title || 'Form Templates'}
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && !hasFormTemplates && <FormTemplatesBlankState />}

            {!loading && hasFormTemplates && (
              <DashboardModule title={strings.title || 'Form Templates'}>
                <Box flexDirection="column">
                  {filteredFormTemplates.map(formTemplate => (
                    <FormTemplate key={formTemplate.id} formTemplate={formTemplate} />
                  ))}
                </Box>
              </DashboardModule>
            )}
          </Columns.Main>

          <Columns.Sidebar>
            <PageFilterModule
              callbacks={{ resetFilters, updateFilters }}
              filterFields={buildPageFilterFields(strings)}
              pageFilters={pageFilters}
              title={strings.filtersTitle || 'Filters'}
            />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

PageContent.propTypes = {
  pageFilters: PropTypes.object.isRequired,
  selectedOrganization: PropTypes.object.isRequired,
}

export default PageContent
