export default {
  default: {
    availableImageLibraries: 'Available Image Libraries',
    blankState: {
      paragraph:
        'To import asset libraries from another platform you’ll need to set up a suitable integration. Integrations can be managed in your client admin.',
    },
    deselectAllButton: 'Deselect All',
    externalPlatform: 'External Platform',
    importImageLibrariesIntoLabel: 'Importing Image Libraries into',
    importImageLibrariesButton: 'Import Image Libraries',
    moreOptionsLabel: 'More Options',
    noticeBox: {
      title: 'Nothing to Import',
      paragraph:
        'No Image Libraries Available. Create an image library in {integrationPlatformTitle} for it to be made available here.',
    },
    platformLabel: 'Platform',
    selectAllButton: 'Select All',
    selectImageLibrariesLabel: 'Select Image Libraries',
    title: 'Import',
    titleSecondLine: 'Image Libraries',
    options: 'Please Select...',
    toast: {
      importImageLibraries: 'Import Image Libraries Queued',
    },
  },
}
