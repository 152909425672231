import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { deleteEntity, updateEntities } from '@redux/entities'

import api from '@functions/api'

import type { QuoteSectionModel } from '@models/types'
import type { AppDispatch } from '@redux/store'
import type {
  Action, DeleteParams, EntityOptions, Errors, UpdateParams,
} from '@redux/modules/types'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/quoteSection/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/quoteSection/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/quoteSection/FETCH_FAILURE'

const CREATE_REQUEST = 'realbase/quoteSection/CREATE_REQUEST'
const CREATE_SUCCESS = 'realbase/quoteSection/CREATE_SUCCESS'
const CREATE_FAILURE = 'realbase/quoteSection/CREATE_FAILURE'

const UPDATE_REQUEST = 'realbase/quoteSection/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realbase/quoteSection/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realbase/quoteSection/UPDATE_FAILURE'

const DELETE_REQUEST = 'realbase/quoteSection/DELETE_REQUEST'
const DELETE_SUCCESS = 'realbase/quoteSection/DELETE_SUCCESS'
const DELETE_FAILURE = 'realbase/quoteSection/DELETE_FAILURE'

export type QuoteSectionModuleState = {
  creating: boolean,
  deleting: boolean,
  errors: string[],
  loaded: boolean,
  loading: boolean,
  updating: boolean,
}

// Initial State
const initialState: QuoteSectionModuleState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loading: false,
  updating: false,
}

// Actions
export function fetchRequest() {
  return {
    type: FETCH_REQUEST,
  }
}

export function fetchSuccess() {
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors: Errors = []) {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest() {
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess() {
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors: Errors = []) {
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest() {
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess() {
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors: Errors = []) {
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest() {
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess() {
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors: Errors = []) {
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Action Creators
export function createQuoteSection(quoteSection: Partial<QuoteSectionModel>, options: EntityOptions = {}) {
  const { quote_id } = quoteSection

  const config = {
    method: 'POST',
    body: JSON.stringify({
      quote_section: quoteSection,
    }),
  }

  return (dispatch: AppDispatch) => {
    dispatch(createRequest())

    const promise = api(`/quotes/${quote_id}/sections.json`, options, config)
      .then(({ data }: { data: { entity: QuoteSectionModel } }) => {
        const normalizedJson = normalize(data.entity, Schemas.QUOTE_SECTION)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function updateQuoteSection(
  quoteSection: UpdateParams<QuoteSectionModel>,
  options: EntityOptions = {},
) {
  const { id, quote_id } = quoteSection

  const config = {
    method: 'PUT',
    body: JSON.stringify({
      quote_section: quoteSection,
    }),
  }

  return (dispatch: AppDispatch) => {
    dispatch(updateRequest())

    const promise = api(`/quotes/${quote_id}/sections/${id}.json`, options, config)
      .then(({ data }: { data: { entity: QuoteSectionModel } }) => {
        const normalizedJson = normalize(data.entity, Schemas.QUOTE_SECTION)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function updateQuoteSectionSortOrder(quoteId: number, sortedSectionIds: number[], options: EntityOptions = {}) {
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      sorted_section_ids: sortedSectionIds,
    }),
  }

  return (dispatch: AppDispatch) => {
    // Set Loading
    dispatch(updateRequest())

    const promise = api(`/quotes/${quoteId}/sections/update_sort_order.json`, options, config)
      .then(({ data }: { data: { entities: QuoteSectionModel[] } }) => {
        const normalizedJson = normalize(data.entities, Schemas.QUOTE_SECTION_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function deleteQuoteSection(quoteSection: DeleteParams<QuoteSectionModel>) {
  const { id, quote_id } = quoteSection

  const config = {
    method: 'DELETE',
  }

  return (dispatch: AppDispatch) => {
    dispatch(deleteRequest())

    const promise = api(`/quotes/${quote_id}/sections/${id}.json`, {}, config)
      .then(({ data }: { data: { entity: QuoteSectionModel } }) => {
        const normalizedJson = normalize(data.entity, Schemas.QUOTE_SECTION)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(deleteFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(
  state: QuoteSectionModuleState = initialState,
  action: Action = { type: '' },
): QuoteSectionModuleState {
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors || [],
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors || [],
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors || [],
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors || [],
      }
    default:
      return state
  }
}
