import { useContext } from 'react'
import PropTypes from 'prop-types'

import PageContext from '@contexts/pageContext'

import { shouldRenderPageItem } from '@functions/digitalTemplatePageItem'

import WrappedCarousel from './WrappedCarousel'

const Carousel = (props) => {
  const {
    children, context: componentContext, id, options,
  } = props

  const { renderConditions } = options

  const pageContext = useContext(PageContext)
  const {
    callbacks, editing, shortcodeData, toggleableComponents,
  } = pageContext || {}

  const contextData = { ...shortcodeData, ...componentContext }

  // Modal Open
  const modalIsOpen = toggleableComponents ? toggleableComponents[id] : false

  // Should Render
  const shouldRender = shouldRenderPageItem(renderConditions, contextData, options)
  if (!shouldRender) return null

  return (
    <WrappedCarousel
      callbacks={callbacks}
      editing={editing}
      id={id}
      modalIsOpen={modalIsOpen}
      options={options}
      shortcodeData={shortcodeData}
    >
      {children}
    </WrappedCarousel>
  )
}

Carousel.propTypes = {
  children: PropTypes.node,
  context: PropTypes.object,
  id: PropTypes.string.isRequired,
  options: PropTypes.object,
}

Carousel.defaultProps = {
  options: {},
}

export default Carousel
