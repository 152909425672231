import { useEffect } from 'react'
import { toast } from 'react-toastify'

import { useModals, useSetState } from '@campaignhub/react-hooks'
import { MainContent, ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import openModalAndCloseAllOthers from '@functions/openModalAndCloseAllOthers'

import useLocalization from '@hooks/useLocalization'
import useNumericParams from '@hooks/useNumericParams'

import CustomizeDigitalTemplatePageItemModal from '@modals/CustomizeDigitalTemplatePageItemModal'
import EditDigitalTemplateSectionSortModal from '@modals/EditDigitalTemplateSectionSortModal'
import ManageDigitalTemplateAssetsModal from '@modals/ManageDigitalTemplateAssetsModal'
import ManageTemplateCustomizerModal from '@modals/ManageTemplateCustomizerModal'
import SelectDigitalTemplateSourceTemplateModal from '@modals/SelectDigitalTemplateSourceTemplateModal'

import useDigitalTemplateCustomizer from './hooks/useDigitalTemplateCustomizer'

import defaultRequestOptions from './defaultRequestOptions'

import Canvas from './components/Canvas'
import Header from './components/Header'
import SelectSectionHint from './components/SelectSectionHint'

import localizedStrings from './localizedStrings'

export const modalKeys = [
  'CustomizeDigitalTemplatePageItemModal',
  'ManageDigitalTemplateAssetsModal',
  'SelectDigitalTemplateSourceTemplateModal',
]

const copySectionToGroup = (copyFn, setState, strings) => {
  copyFn().then((result) => {
    const { success, errors } = result

    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    toast(strings.toast?.sectionAdded || 'Section added to template successfully.')
    openModalAndCloseAllOthers('ManageTemplateCustomizerModal', modalKeys, setState)
  })
}

const deleteEntity = (entity, deleteFn, strings) => {
  deleteFn(entity).then((result) => {
    const { success, errors } = result

    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    toast(strings.toast?.deleted || 'Page item deleted successfully.')
  })
}

const updateDigitalTemplate = (digitalTemplate, updateFn, strings) => {
  updateFn(digitalTemplate, defaultRequestOptions.digitalTemplate).then((result) => {
    const { success, errors } = result

    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    toast(strings.toast?.updated || 'Template updated successfully.')
  })
}

const updateGroupSectionSort = (sortFn, setState, strings) => {
  sortFn().then(({ success, errors }) => {
    if (!success){
      toast.warning(errors[0])
      return
    }

    toast(strings.toast?.sorted || 'Sections sorted successfully.')
    openModalAndCloseAllOthers('EditDigitalTemplateModal', modalKeys, setState)
  })
}

const updateItemImage = (image, updateFn, setState) => {
  openModalAndCloseAllOthers('ManageTemplateCustomizerModal', modalKeys, setState)
  updateFn(image)
}

const callbacks = (component, props, setState, strings) => {
  const componentCallbacks = {
    CustomizeDigitalTemplatePageItemModal: {
      closeModal: () => setState({ showCustomizeDigitalTemplatePageItemModal: false }),
    },
    EditDigitalTemplateSectionSortModal: {
      closeModal: () => setState({ showEditDigitalTemplateSectionSortModal: false }),
      deleteEntity: (entity, deleteFn) => deleteEntity(entity, deleteFn, strings),
      updateGroupSectionSort: sortFn => updateGroupSectionSort(sortFn, setState, strings),
    },
    ManageDigitalTemplateAssetsModal: {
      closeModal: () => setState({ showManageDigitalTemplateAssetsModal: false }),
      selectImage: (image, updateFn) => updateItemImage(image, updateFn, setState),
    },
    ManageTemplateCustomizerModal: {
      updateDigitalTemplate: (digitalTemplate, updateFn) => updateDigitalTemplate(digitalTemplate, updateFn, strings),
    },
    SelectDigitalTemplateSourceTemplateModal: {
      closeModal: () => setState({ showSelectDigitalTemplateSourceTemplateModal: false }),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  showCustomizeDigitalTemplatePageItemModal: false,
  showEditDigitalTemplateSectionSortModal: false,
  showManageDigitalTemplateAssetsModal: false,
  showManageTemplateCustomizerModal: false,
  showSelectDigitalTemplateSourceTemplateModal: false,
}

const TemplateCustomizer = (props) => {
  const { digitalTemplateId } = useNumericParams()

  const [state, setState] = useSetState(defaultState)
  const {
    showCustomizeDigitalTemplatePageItemModal,
    showEditDigitalTemplateSectionSortModal,
    showManageDigitalTemplateAssetsModal,
    showManageTemplateCustomizerModal,
    showSelectDigitalTemplateSourceTemplateModal,
  } = state

  const modalContext = useModals()
  const { callbacks: { registerModal, setModalData } } = modalContext

  const { strings } = useLocalization(localizedStrings)

  const digitalCustomizerPayload = useDigitalTemplateCustomizer({
    callbacks: {
      copySectionToGroup: copyFn => copySectionToGroup(copyFn, setState, strings),
      showCustomizeDigitalTemplatePageItemModal: (payload) => {
        setModalData('CustomizeDigitalTemplatePageItemModal', payload)
        openModalAndCloseAllOthers('CustomizeDigitalTemplatePageItemModal', modalKeys, setState)
      },
      showEditDigitalTemplateSectionSortModal: (payload) => {
        setModalData('EditDigitalTemplateSectionSortModal', payload)
        openModalAndCloseAllOthers('EditDigitalTemplateSectionSortModal', modalKeys, setState)
      },
      showManageDigitalTemplateAssetsModal: (payload) => {
        setModalData('ManageDigitalTemplateAssetsModal', payload)
        openModalAndCloseAllOthers('ManageDigitalTemplateAssetsModal', modalKeys, setState)
      },
      showSelectDigitalTemplateSourceTemplateModal: (payload) => {
        setModalData('SelectDigitalTemplateSourceTemplateModal', payload)
        openModalAndCloseAllOthers('SelectDigitalTemplateSourceTemplateModal', modalKeys, setState)
      },
    },
    digitalTemplateId,
  })

  const {
    currentTemplatePage, isMobile, isMobileDevice, sourceTemplateId, viewingSource,
  } = digitalCustomizerPayload

  useEffect(() => {
    // Wait for the register function otherwise the modal
    // doesn't get added to the open modals array
    if (!isMobile && registerModal){
      setState({
        showManageTemplateCustomizerModal: true,
      })
    }
  }, [isMobile])

  return (
    <PageContext.Provider value={digitalCustomizerPayload}>
      <ModalContext.Provider value={modalContext}>
        <MainContent
          offset={{ left: 0, top: 71 }}
          width={!isMobileDevice ? 'calc(100% - 375px)' : '100%'}
          style={{ flexDirection: 'column', padding: 16 }}
        >
          <Header digitalCustomizerPayload={digitalCustomizerPayload} />

          {viewingSource && <SelectSectionHint />}

          {!!currentTemplatePage.id && <Canvas digitalCustomizerPayload={digitalCustomizerPayload} />}
        </MainContent>

        <CustomizeDigitalTemplatePageItemModal
          callbacks={callbacks('CustomizeDigitalTemplatePageItemModal', props, setState, strings)}
          showModal={showCustomizeDigitalTemplatePageItemModal}
        />

        <EditDigitalTemplateSectionSortModal
          callbacks={callbacks('EditDigitalTemplateSectionSortModal', props, setState, strings)}
          showModal={showEditDigitalTemplateSectionSortModal}
        />

        <ManageTemplateCustomizerModal
          callbacks={callbacks('ManageTemplateCustomizerModal', props, setState, strings)}
          digitalCustomizerPayload={digitalCustomizerPayload}
          showModal={showManageTemplateCustomizerModal}
        />

        <ManageDigitalTemplateAssetsModal
          callbacks={callbacks('ManageDigitalTemplateAssetsModal', props, setState, strings)}
          draggable={false}
          showModal={showManageDigitalTemplateAssetsModal}
        />

        <SelectDigitalTemplateSourceTemplateModal
          callbacks={callbacks('SelectDigitalTemplateSourceTemplateModal', props, setState, strings)}
          selectedDigitalTemplateId={sourceTemplateId}
          showModal={showSelectDigitalTemplateSourceTemplateModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

TemplateCustomizer.propTypes = {}

export default TemplateCustomizer
