import { useSetState } from '@campaignhub/react-hooks'
import { toggleArray } from '@campaignhub/javascript-utils'

type DefaultState = {
  currentlySelectedIds: number[],
  showSelected: boolean,
  string: string,
}

const selectUser = (userId: number, state: any, setState: (state: Partial<DefaultState>) => void) => {
  const { currentlySelectedIds } = state
  const updatedSelection = toggleArray(currentlySelectedIds, userId)

  setState({ currentlySelectedIds: updatedSelection })
}

const defaultState = {
  currentlySelectedIds: [],
  showSelected: false,
  string: '',
}

interface Result {
  callbacks: {
    selectUser: (userId: number) => void,
    setState: (state: Partial<DefaultState>) => void,
  },
  currentlySelectedIds: number[],
  showSelected: boolean,
  string: string,
}

function useManageEntityUsers(): Result {
  const [state, setState] = useSetState(defaultState)
  const { currentlySelectedIds, string, showSelected } = state

  return {
    callbacks: {
      selectUser: userId => selectUser(userId, state, setState),
      setState,
    },
    currentlySelectedIds,
    showSelected,
    string,
  }
}

export default useManageEntityUsers
