export default {
  default: {
    header: {
      title: 'Bulk Update',
      titleSecondLine: 'Integration Features',
    },
    options: {
      defaultEntity: 'Integration Platform',
      searchIntegrationPlatform: 'Search for an integration platform',
    },
    footer: {
      button: 'Save',
    },
    mapResults: {
      integrationTitle: 'INTEGRATION PLATFORM',
    },
    toast: {
      IntegrationsUpdated: 'Integrations Updated Successfully',
    },
  },
}
