import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  digObject, matchFilterKey, matchFilterString, sortArrayBy,
} from '@campaignhub/javascript-utils'
import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useCurrentUser from '@hooks/useCurrentUser'
import useOrganizationSelector from '@hooks/useOrganizationSelector'
import useReduxAction from '@hooks/useReduxAction'

const watchEntityKeys = ['digitalTemplates']

const matchStatus = (status, digitalTemplate) => (status === 'archived' ? digitalTemplate.hidden : !digitalTemplate.hidden)

const matchTemplatePartial = (filterTemplatePartial, parentDigitalTemplateType) => {
  const { key } = parentDigitalTemplateType

  // We need to filter section packs / partials out by default
  if (filterTemplatePartial){
    return key === 'template_partial'
  }

  return key !== 'template_partial'
}

function useDigitalTemplates(options = {}){
  const {
    filters,
    performHttpRequests,
    status,
  } = options || {}

  const {
    brand_id: filterBrandId,
    digital_template_type_key: filterTemplateTypeKey,
    digital_template_sub_type_key: filterTemplateSubTypeKey,
    organization_id: filterOrganizationId,
    owner_type: filterOwnerType,
    production_ready: filterProductionReady,
    string,
    template_partial: filterTemplatePartial,
  } = filters || {}

  const {
    updatedEntities: { digitalTemplates: digitalTemplatesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  // Load Templates
  useReduxAction(
    'digitalTemplates',
    'loadDigitalTemplates',
    {
      ...filters,
      ...options,
      digital_template_type_key: filterTemplateTypeKey || filterTemplateSubTypeKey,
      include_digital_template_permission_ids: true,
      status,
    },
    [performHttpRequests, JSON.stringify(options)],
    {
      shouldPerformFn: () => performHttpRequests,
    },
  )

  const { currentUser } = useCurrentUser()
  const { selectedOrganization } = useOrganizationSelector()

  const entities = useSelector(state => state.entities)
  const { digitalTemplates, digitalTemplateTypes } = entities

  const { loading } = useSelector(state => state.digitalTemplates)

  const filteredDigitalTemplates = useMemo(() => {
    const filtered = Object.values(digitalTemplates).filter((digitalTemplate) => {
      const {
        brand_id, digital_template_type_id, id, owner_type, permission_ids, production_ready, title,
      } = digitalTemplate

      const selectedOrganizationId = filterOrganizationId || selectedOrganization?.id

      const digitalTemplateType = digitalTemplateTypes[digital_template_type_id] || {}
      const parentDigitalTemplateType = digitalTemplateTypes[digitalTemplateType.parent_id] || {}
      const organizationIds = digObject(permission_ids, 'organization_ids', [])

      const brandMatch = filterBrandId ? brand_id === Number(filterBrandId) : true
      const organizationMatch = selectedOrganizationId ? organizationIds.includes(Number(selectedOrganizationId)) : true
      const ownerTypeMatch = matchFilterKey(owner_type, filterOwnerType)
      const productionStatus = status !== 'archived'
        && typeof filterProductionReady !== 'undefined' && !!filterProductionReady
        ? String(filterProductionReady) === String(production_ready)
        : true
      const statusMatch = matchStatus(status, digitalTemplate)
      const stringMatch = matchFilterString(title, string) || Number(string) === id
      const templateSubTypeMatch = filterTemplateSubTypeKey
        ? digitalTemplateType.key === filterTemplateSubTypeKey
        : true
      const templateTypeMatch = filterTemplateTypeKey && filterTemplateTypeKey !== 'all'
        ? parentDigitalTemplateType.key === filterTemplateTypeKey
        : true
      const templatePartialMatch = matchTemplatePartial(filterTemplatePartial, parentDigitalTemplateType)

      return (
        brandMatch
        && organizationMatch
        && ownerTypeMatch
        && productionStatus
        && statusMatch
        && stringMatch
        && templatePartialMatch
        && templateSubTypeMatch
        && templateTypeMatch
      )
    })

    return sortArrayBy(filtered, 'asc', 'title')
  }, [digitalTemplatesUpdatedAt, JSON.stringify(options)])

  const currentUserTemplates = useMemo(() => {
    const filtered = filteredDigitalTemplates.filter((digitalTemplate) => {
      const userIds = digObject(digitalTemplate, 'options.user_ids', [])

      return currentUser.id ? userIds.includes(Number(currentUser.id)) : true
    })

    return filtered
  }, [currentUser.id, digitalTemplatesUpdatedAt, JSON.stringify(options)])

  const nonUserTemplates = useMemo(() => {
    const filtered = filteredDigitalTemplates.filter((digitalTemplate) => {
      const userIds = digObject(digitalTemplate, 'options.user_ids', [])

      return !userIds.length
    })

    return filtered
  }, [currentUser.id, digitalTemplatesUpdatedAt, JSON.stringify(options)])

  const hasDigitalTemplates = !!filteredDigitalTemplates.length

  return {
    currentUserTemplates,
    entities,
    filteredDigitalTemplates,
    hasDigitalTemplates,
    loading,
    nonUserTemplates,
  }
}

export default useDigitalTemplates
