export default {
  default: {
    swal: {
      title: 'Delete Event?',
      text: 'Are you sure?',
      confirmButtonText: 'Yes, delete it',
    },
    formFieldOption: 'Please Select...',
    deleteButton: 'Delete Event',
    duplicateButton: 'Duplicate Event',
    saveButton: 'Save Event',
    labelTitle: '* Title',
    labelDescription: 'Description',
    labelStartDate: 'Start Date',
    labelEndDate: 'End Date',
    labelDaysFromStart: 'Days from Start',
    labelDuration: 'Duration (Days)',
    labelStartTime: 'Start Time',
    LabelEndTime: 'End Time',
    labelGroup: '* Group',
    labelMoreOptions: 'More Options',
  },
}
