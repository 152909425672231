import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@campaignhub/suit-theme'

import styles from './styles.module.scss'

const FormRow = (props) => {
  const {
    boxProps, children, flexDirection,
  } = props

  return (
    <Box
      flexDirection={flexDirection || ['column', 'row']}
      marginBottom="large"
      className={styles.root}
      {...boxProps}
    >
      {children}
    </Box>
  )
}

FormRow.propTypes = {
  boxProps: PropTypes.object,
  children: PropTypes.node,
  flexDirection: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
}

export default FormRow
