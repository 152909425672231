import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileEdit, faPlus } from '@fortawesome/pro-light-svg-icons'

import { Image, SidebarModal } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const DefaultState = (props) => {
  const {
    callbacks,
    callbacks: { closeModal, createQuote, selectScreen },
    quotePayload: {
      callbacks: { createQuote: createFn },
      quote,
    },
  } = props

  const { strings } = useLocalization(localizedStrings)

  const createQuotePayload = {
    callbacks: {
      action: createFn,
      afterAction: closeModal,
    },
    entityParams: quote,
    shouldRedirect: true,
    toastText: strings.toast?.quoteCreated || 'Quote Created Successfully',
  }

  return (
    <>
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.title || 'New'}
        titleSecondLine={strings.titleSecondLine || 'Quote'}
      />

      <SidebarModal.Content hasFooter={false}>
        <SidebarModal.Button
          boxProps={{ paddingY: ['medium', 'large'] }}
          iconLeft={(
            <Image boxProps={{ backgroundColor: 'hoverLightGrey', fontSize: 'small' }} circle width={30}>
              <FontAwesomeIcon icon={faFileEdit} />
            </Image>
          )}
          onClick={() => selectScreen('CreateFromTemplate')}
          showAngleIcon
          subtitle={strings.button?.templateSubTitle || 'Choose from saved templates'}
          title={strings.button?.templateTitle || 'Use a Template'}
        />

        <SidebarModal.Button
          boxProps={{ paddingY: ['medium', 'large'] }}
          iconLeft={(
            <Image boxProps={{ backgroundColor: 'hoverLightGrey', fontSize: 'small' }} circle width={30}>
              <FontAwesomeIcon icon={faPlus} />
            </Image>
          )}
          onClick={() => createQuote(createQuotePayload)}
          showAngleIcon
          subtitle={strings.button?.emptyQuoteSubTitle || 'Start with a blank canvas'}
          title={strings.button?.emptyQuote || 'Empty Quote'}
        />
      </SidebarModal.Content>
    </>
  )
}

DefaultState.propTypes = {
  callbacks: PropTypes.shape({
    closeModal: PropTypes.func.isRequired,
    createQuote: PropTypes.func.isRequired,
    selectScreen: PropTypes.func.isRequired,
  }).isRequired,
  quotePayload: PropTypes.shape({
    callbacks: PropTypes.shape({
      createQuote: PropTypes.func.isRequired,
    }).isRequired,
    quote: PropTypes.object.isRequired,
  }).isRequired,
}

export default DefaultState
