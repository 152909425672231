export default {
  default: {
    formFieldLabels: {
      interactions: 'Interactions',
      linkViews: 'Link Views',
      moreOptions: 'More Options',
    },
    noticeBox: {
      paragraph: 'Your link hasn’t been accessed yet. Once it’s been interacted with we’ll show you data about who’s been viewing it and when.',
      title: 'No Link Activity',
    },
    sideBarModalHeader: {
      title: 'Link',
      titleSecondLine: 'Analytics',
    },
  },
}
