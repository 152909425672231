export default {
  default: {
    swal: {
      title: 'Delete Group?',
      text: 'Are you sure? All events within the group will be deleted as well.',
      confirmButtonText: 'Yes, delete it',
    },
    optionYes: 'Yes',
    optionNo: 'No',
    deleteButton: 'Delete Group',
    saveButton: 'Save Group',
    preparation: 'Preparation',
    labelTitle: 'Title',
    labelPreparationPhase: 'Preparation Phase',
    labelSubtitle: 'Subtitle',
    labelDescription: 'Description',
    labelStartDay: 'Start Date',
    labelEndDate: 'End Date',
    labelDaysFromStart: 'Days from Start',
    labelDuration: 'Duration (Days)',
    labelMoreOptions: 'More Options',
  },
}
