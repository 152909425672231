export default {
  default: {
    copyLink: 'Copy Link',
    toast: 'Share Link Url Copied to Clipboard',
    trackingDisabled: 'Tracking Disabled',
    trackingEnabled: 'Tracking Enabled',
    untitled: 'Untitled Link',
    viewCount: {
      one: '1 View',
      many: '{count} Views',
      none: 'Not Viewed',
      type: 'count',
    },
  },
}
