export default {
  default: {
    buttonText: {
      close: 'Close',
      createProject: 'Create Project',
      selectProject: 'Select Project',
    },
    formFieldLabels: {
      moreOptions: 'More Options',
      searchProjects: 'Search Projects',
      recentProjects: 'Select from Recent Projects',
    },
    project: 'Project',
    selectProject: 'Select Project',
    titleText: 'Select',
    titleSecondLine: 'Project',
  },
}
