export default {
  default: {
    swal: {
      title: 'Delete Recipient?',
      text: 'Are you sure?',
      confirmButtonText: 'Yes, delete it.',
    },
    sideBarModalHeader: {
      title: 'Manage',
      titleSecondLine: 'Recipients',
    },
    buttons: {
      save: 'Save Recipients',
    },
  },
}
