import { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import PropTypes from 'prop-types'

import { faCheckCircle, faGlobe, faPencil } from '@fortawesome/pro-light-svg-icons'

import { getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'
import {
  Box,
  Button,
  Columns,
  DashboardModule,
  LoadingModule,
  PageHeader,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import useCurrentUser from '@hooks/useCurrentUser'
import { generateUrls } from '@hooks/useFormTemplate'
import useFormTemplates from '@hooks/useFormTemplates'
import useIntercom from '@hooks/useIntercom'

import PageFilterModule from '@components/PageFilterModule'

import FormTemplate from '../FormTemplate'
import FormTemplatesBlankState from '../FormTemplatesBlankState'

const buildPageFilterFields = (isAdmin) => {
  const boxProps = {
    marginBottom: 'large',
  }

  const style = {
    marginBottom: 16,
  }

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        style,
      },
      key: 'string',
      label: 'Filter by Title or ID',
    },
    {
      entityKey: 'owner_types',
      callbacks: {
        shouldRender: () => isAdmin,
      },
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: 'All',
        inputComponent: 'select',
        style,
      },
      key: 'owner_type',
      label: 'Owner Type',
      values: [
        { key: 'Brand', label: 'Brand' },
        { key: 'System', label: 'System' },
        { key: 'Organization', label: 'Organization' },
      ],
    },
    {
      callbacks: {
        shouldRender: state => state.owner_type === 'Brand',
      },
      component: 'EntitySelector',
      componentProps: {
        entityKey: 'brands',
        boxProps,
      },
      key: 'brand_id',
    },
    {
      callbacks: {
        shouldRender: state => state.owner_type === 'Organization',
      },
      component: 'EntitySelector',
      componentProps: {
        entityKey: 'organizations',
        boxProps,
      },
      key: 'organization_id',
    },
    {
      entityKey: 'limit',
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: '100',
        inputComponent: 'select',
        style,
      },
      key: 'limit',
      label: 'Limit',
      values: [
        { key: 150, label: '150' },
        { key: 200, label: '200' },
      ],
    },
  ]
}

const PageContent = (props) => {
  const { pageFilters } = props
  useLocation()

  const { status } = parsePermittedQueryParams(getQueryParamsFromHash(), ['status'])
  const currentStatus = status || 'all'

  const pageContext = useContext(PageContext)
  const {
    callbacks: {
      resetFilters, showCreateFormTemplateModal, updateFilters,
    },
  } = pageContext

  const formTemplatePayload = useFormTemplates({
    filters: {
      production_ready: currentStatus !== 'all' ? currentStatus === 'published' : undefined,
      ...pageFilters,
    },
    performHttpRequests: true,
  })

  const { filteredFormTemplates, hasFormTemplates, loading } = formTemplatePayload

  const {
    draftFormTemplates,
    formTemplatesIndexUrl,
    publishedFormTemplates,
  } = generateUrls({})

  const { isAdmin, isBrandUser } = useCurrentUser()

  useIntercom({ hideWidget: !isBrandUser })

  return (
    <>
      <Helmet>
        <title>Form Templates | Engage</title>
      </Helmet>

      <PageHeader
        activeTabBarItemKey={currentStatus}
        actionContent={(
          <Button buttonStyle="primaryCreate" onClick={() => showCreateFormTemplateModal()} size="medium">
            Create
          </Button>
        )}
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        tabBarItems={[
          {
            href: formTemplatesIndexUrl,
            icon: faGlobe,
            key: 'all',
            title: 'All',
          },
          {
            href: publishedFormTemplates,
            icon: faCheckCircle,
            key: 'published',
            title: 'Published',
          },
          {
            href: draftFormTemplates,
            icon: faPencil,
            key: 'draft',
            title: 'Draft',
          },
        ]}
        title="Form Templates"
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && !hasFormTemplates && <FormTemplatesBlankState />}

            {!loading && hasFormTemplates && (
              <DashboardModule title="Form Templates">
                <Box flexDirection="column">
                  {filteredFormTemplates.map(formTemplate => (
                    <FormTemplate key={formTemplate.id} formTemplate={formTemplate} />
                  ))}
                </Box>
              </DashboardModule>
            )}
          </Columns.Main>

          <Columns.Sidebar>
            <PageFilterModule
              callbacks={{ resetFilters, updateFilters }}
              filterFields={buildPageFilterFields(isAdmin)}
              pageFilters={pageFilters}
              title="Filters"
            />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

PageContent.propTypes = {
  pageFilters: PropTypes.object.isRequired,
}

export default PageContent
