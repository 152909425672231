import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faUsers } from '@fortawesome/pro-light-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'

import {
  Box, Image, Link, ListItem, Text,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'
import useTeam from '@hooks/useTeam'

import localizedStrings from './localizedStrings'
import placeholderImage from './assets/teams-placeholder.png'

const Team = (props) => {
  const { team } = props

  const {
    callbacks: { createOrEditTeam },
    defaultImage,
    team: { title },
  } = useTeam(team)

  const imageUrl = digObject(defaultImage, 'sizes.thumb_200x120', '')
  const teamMembers = digObject(team, 'team_members', [])

  const teamMemberCount = teamMembers.length

  const {
    callbacks: { formatCount },
  } = useLocalization(localizedStrings)

  return (
    <ListItem boxProps={{ alignItems: 'center', borderBottom: '1px solid', padding: 'large' }} disableHover>
      <Box width={110}>
        <Image
          border="1px solid"
          borderColor="lineColor"
          borderRadius={5}
          height={40}
          marginRight="medium"
          placeholderUrl={placeholderImage}
          url={imageUrl}
          width={60}
        />
      </Box>

      <Box flexDirection="column" justifyContent="center" minWidth="0">
        <Text fontSize="small">{title}</Text>

        <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="medium" variant="ellipsis">
          <Box alignItems="center">
            <FontAwesomeIcon icon={faUsers} style={{ marginRight: 5 }} />
            {formatCount('members', { count: teamMemberCount })}
          </Box>
        </Text>
      </Box>

      <Link greyLink onClick={createOrEditTeam} textProps={{ fontSize: 'small' }}>
        <FontAwesomeIcon icon={faPencil} />
      </Link>
    </ListItem>
  )
}

Team.propTypes = {
  callbacks: PropTypes.shape({
    deleteTeam: PropTypes.func.isRequired,
  }),
  team: PropTypes.object.isRequired,
}

export default Team
