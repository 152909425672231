import isMobileBrowser from 'is-mobile'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Columns, SidebarModal, Text,
} from '@campaignhub/suit-theme'

import useMixpanel from '@hooks/useMixpanel'

import AboutIntegration from './components/AboutIntegration'
import BelongTogether from './components/BelongTogether'
import GettingStarted from './components/GettingStarted'
import Header from './components/Header'

const isMobile = isMobileBrowser()

const MODAL_KEY = 'RTAValuePropositionModal'

type ModalCallbacks = {
  closeModal: () => void,
}

type ModalProps = {
  callbacks: ModalCallbacks,
  showModal: boolean,
}

const RTAValuePropositionModal = (props: ModalProps) => {
  const {
    callbacks,
    callbacks: { closeModal },
    showModal,
  } = props

  const {
    callbacks: { dispatchMixpanelEvent },
  } = useMixpanel()

  return (
    <SidebarModal callbacks={callbacks} modalKey={MODAL_KEY} size="fullscreen" showModal={showModal}>
      <Box
        backgroundColor="white"
        borderBottom="1px solid"
        borderColor="lineColor"
        justifyContent="flex-end"
        padding="medium"
      >
        <Text fontSize={['xxlarge', 'xlarge']} onClick={closeModal} padding="large" style={{ cursor: 'pointer' }}>
          <FontAwesomeIcon icon={faTimes} />
        </Text>
      </Box>

      <SidebarModal.Content hasHeader={false} hasFooter={false} boxProps={{ padding: 0 }}>
        <Columns
          boxProps={{
            backgroundColor: 'backgroundColor',
            justifyContent: 'center',
            marginBottom: 'large',
            padding: 'large',
          }}
          flexDirection={['column', 'column', 'row']}
        >
          <Columns.Main width={['100%', '100%', '1200px']}>
            <Box
              flexDirection="column"
              padding="large"
              backgroundColor="white"
              marginBottom={['large', 'xlarge']}
              border="1px solid"
              borderColor="lineColor"
              borderRadius={5}
            >
              <Header
                callbacks={{
                  dispatchMixpanelEvent: () => dispatchMixpanelEvent('RTA Value Proposition Getting Started Click'),
                }}
                isMobile={isMobile}
              />

              <AboutIntegration />

              <BelongTogether />

              <GettingStarted
                callbacks={{
                  dispatchMixpanelEvent: () => dispatchMixpanelEvent('RTA Value Proposition Getting Started Click'),
                }}
                isMobile={isMobile}
              />
            </Box>
          </Columns.Main>
        </Columns>
      </SidebarModal.Content>
    </SidebarModal>
  )
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <RTAValuePropositionModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
