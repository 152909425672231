import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@campaignhub/suit-theme'

import useDocumentTemplatePage from '@hooks/useDocumentTemplatePage'

import useBuilderCanvas from '../../hooks/useBuilderCanvas'

import PageItem from './PageItem'
import PagePreviewImage from './PagePreviewImage'

const DocumentTemplatePage = (props) => {
  const {
    agreement,
    callbacks: { manageAgreementFeature },
    documentTemplatePage,
    filteredRecipients,
    zoom,
  } = props

  const documentTemplatePagePayload = useDocumentTemplatePage(documentTemplatePage)
  const { filteredPageItems, pagePreviewImage } = documentTemplatePagePayload

  const canvasPayload = useBuilderCanvas({ documentTemplatePage, zoom })
  const { size: { height, width } } = canvasPayload

  return (
    <Box
      border="1px solid"
      borderColor="lineColor"
      flexShrink="0"
      height={height}
      margin="16px auto"
      onClick={e => e.stopPropagation()}
      position="relative"
      width={width}
    >
      <PagePreviewImage image={pagePreviewImage} />

      {filteredPageItems.map(documentTemplatePageItem => (
        <PageItem
          agreement={agreement}
          callbacks={{ manageAgreementFeature }}
          documentTemplatePageItem={documentTemplatePageItem}
          filteredRecipients={filteredRecipients}
          key={documentTemplatePageItem.id}
          zoom={zoom}
        />
      ))}
    </Box>
  )
}

DocumentTemplatePage.propTypes = {
  agreement: PropTypes.object.isRequired,
  callbacks: PropTypes.object.isRequired,
  documentTemplatePage: PropTypes.object.isRequired,
  filteredRecipients: PropTypes.array.isRequired,
  zoom: PropTypes.number.isRequired,
}

export default DocumentTemplatePage
