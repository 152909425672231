import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, FormField, Text,
} from '@campaignhub/suit-theme'

import useIntegrationPlatform from '@hooks/useIntegrationPlatform'
import useLocalization from '@hooks/useLocalization'

import IntegrationPlatformLogo from '@components/IntegrationPlatformLogo'

import localizedStrings from './localizedStrings'

const ModalIntegrationPlatform = (props) => {
  const { integrationPlatform } = props

  const {
    integrationPlatform: { description, key, title },
  } = useIntegrationPlatform(integrationPlatform)

  const { strings } = useLocalization(localizedStrings)

  return (
    <FormField boxProps={{ marginBotton: 'large' }} direction="column" label={strings.labelIntegration || 'Integration'}>
      <Box position="relative" variant="white">
        <Box
          alignItems="center"
          backgroundColor="whiteGrey"
          borderRight="1px solid"
          borderColor="lineColor"
          justifyContent="center"
          width={100}
        >
          <IntegrationPlatformLogo
            backgroundSize="contain"
            integrationPlatformKey={key}
            height={60}
            width="100%"
          />
        </Box>

        <Box justifyContent="center" flexDirection="column" padding="large">
          <Text fontSize="medium">{title}</Text>
          <Text color="bodyFontLightColor" fontSize="xsmall" lineHeight={1.3} marginTop="medium">
            {description || strings.description || 'Integration Platform'}
          </Text>
        </Box>
      </Box>
    </FormField>
  )
}

ModalIntegrationPlatform.propTypes = {
  integrationPlatform: PropTypes.object.isRequired,
}

export default ModalIntegrationPlatform
