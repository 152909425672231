import { forwardRef } from 'react'

import type { DraggableProps, DraggableProvided } from 'react-beautiful-dnd'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faBars } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Image, Link, ListItem, Text,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useAward from '@hooks/useAward'

import type { AwardModel } from '@models/award'

interface DraggableAwardProps {
  award: AwardModel,
  draggableProps: DraggableProps,
  dragHandleProps: DraggableProvided['dragHandleProps'],
  dragSnapshot: {
    isDragging: boolean,
  },
}
const DraggableAward = forwardRef((props: DraggableAwardProps, ref) => {
  const {
    award, dragHandleProps, dragSnapshot, draggableProps,
  } = props

  const { isDragging } = dragSnapshot || {}

  const awardPayload = useAward(award)
  const {
    award: { body, title },
    defaultImage,
  } = awardPayload

  const defaultImageUrl = digObject(defaultImage, 'sizes.thumb_120x80', '')

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        backgroundColor: 'white',
        borderBottom: '1px dashed',
        borderColor: 'lineColor',
        paddingY: 'medium',
      }}
      disableHover
      forwardProps={{
        ...draggableProps,
        ...dragHandleProps,
      }}
      isDragging={isDragging}
      ref={ref}
    >
      <Image
        boxProps={{ backgroundColor: 'hoverLightGrey', fontSize: 'small' }}
        borderRadius={5}
        height={30}
        width={30}
        url={defaultImageUrl}
      >
        {!defaultImageUrl && <FontAwesomeIcon icon={faHome} />}
      </Image>

      <Box flexDirection="column" justifyContent="center" minWidth="0" marginLeft="large">
        <Text fontSize="small">{title}</Text>

        {!!body && (
          <Text fontSize="xsmall" color="bodyFontLightColor" variant="ellipsis" marginTop="small">
            {body}
          </Text>
        )}
      </Box>

      <Link greyLink textProps={{ fontSize: 'small', marginLeft: 'large' }}>
        <FontAwesomeIcon icon={faBars} />
      </Link>
    </ListItem>
  )
})

export default DraggableAward
