import { Helmet } from 'react-helmet-async'

import useReduxAction from '@hooks/useReduxAction'

import DigitalTemplateBuilderRoutes from './routes'

const DigitalTemplateBuilder = () => {
  useReduxAction('users', 'loadUsers', {}, [])

  return (
    <>
      <Helmet>
        <title>Digital Templates | Engage</title>
      </Helmet>

      <DigitalTemplateBuilderRoutes />
    </>
  )
}

export default DigitalTemplateBuilder
