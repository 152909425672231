export default {
  default: {
    buttons: {
      delete: 'Delete Field',
      save: 'Save',
    },
    formFieldLabels: {
      fieldKey: '* Field Key',
      fieldLabel: '* Field Label',
      fieldType: '* Field Type',
      fieldWidth: 'Field Width',
      formKeyPlaceholder: 'e.g property_bedrooms',
      formLabelPlaceholder: 'e.g Bedrooms',
      inputType: 'Input Type',
      moreOptions: 'More Options',
      required: 'Required',
    },
    formFieldOptions: {
      date: 'Date',
      dateTime: 'Date Time',
      email: 'Email',
      input: 'Input',
      jsonObject: 'JSON Object',
      jsonObjectArray: 'JSON Object Array',
      no: 'No',
      number: 'Number',
      projectType: '* Project Type',
      selectBox: 'Select Box',
      selectOption: 'Please Select...',
      text: 'Text',
      textArea: 'Text Area',
      time: 'Time',
      url: 'Url',
      yes: 'Yes',
    },
    sideBarModalHeader: {
      titleCreate: 'Create',
      titleSecondLine: 'Field',
      titleUpdate: 'Update',
    },
    viewMode: {
      advanced: 'Advanced View',
      basic: 'Basic View',
    },
    sweetAlert: {
      confirm: 'Yes, delete it.',
      text: 'Are you sure?',
      title: 'Delete Custom Field?',
    },
    toast: {
      customFieldCreated: 'Custom Field Created Successfully',
      customFieldDeleted: 'Custom Field Deleted Successfully',
      customFieldUpdated: 'Custom Field Updated Successfully',
    },
  },
}
