import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-light-svg-icons'

import { Box, Button, DashboardModule } from '@campaignhub/suit-theme'

import useFeature from '@hooks/useFeature'
import useLocalization from '@hooks/useLocalization'
import useOrganizationsUser from '@hooks/useOrganizationsUser'
import useOrganizationsUsers from '@hooks/useOrganizationsUsers'

import OrganizationsUser from './OrganizationsUser'

import localizedStrings from './localizedStrings'

const OrganizationsUsers = (props) => {
  const { organization, user } = props

  const { filteredOrganizationsUsers } = useOrganizationsUsers({ userId: user.id })

  const {
    callbacks: { addUserToOrganization },
    hasAvailableOrganizationsToJoin,
  } = useOrganizationsUser({ organization_id: organization.id, user_id: user.id })

  const { canEditUser, isSelf } = useFeature({ user })

  const { strings } = useLocalization(localizedStrings)

  return (
    <DashboardModule title={strings.title || 'Organisations'} boxProps={{ marginBottom: 'large' }}>
      <Box flexDirection="column">
        <Box flexDirection="column" maxHeight={415} overflow="auto">
          {filteredOrganizationsUsers.map(organizationsUser => (
            <OrganizationsUser
              features={{ canEditUser, isSelf }}
              key={organizationsUser.id}
              organizationsUser={organizationsUser}
            />
          ))}
        </Box>

        {hasAvailableOrganizationsToJoin && canEditUser && !isSelf && (
        <Button
          buttonStyle="secondaryUtility"
          icon={<FontAwesomeIcon icon={faPlus} />}
          size="medium"
          onClick={addUserToOrganization}
          style={{ margin: 16, width: 'calc(100% - 32px)' }}
        >
          {strings.addButton || 'Add to Organisation'}
        </Button>
        )}
      </Box>
    </DashboardModule>
  )
}

OrganizationsUsers.propTypes = {
  organization: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
}

export default OrganizationsUsers
