import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCog, faDownload, faFileAlt, faPencil,
} from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, IconWithMenu, Link, ListItem, Text,
} from '@campaignhub/suit-theme'

import { snakeToTitleCase } from '@campaignhub/javascript-utils'

import PageContext from '@contexts/pageContext'

import useAgreement from '@hooks/useAgreement'
import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const ExternalDocument = (props) => {
  const { agreement } = props

  const agreementPayload = useAgreement(agreement)
  const {
    agreement: { title, urls },
    callbacks: { generateExternalFormUrl },
    createFormExternalId,
    createFormPlatformKey,
  } = agreementPayload

  const { external_form_pdf: externalFormPdfUrl } = urls || {}

  const pageContext = useContext(PageContext)
  const {
    callbacks: { editExternalForm },
  } = pageContext

  const {
    callbacks: { formatString },
    strings,
  } = useLocalization(localizedStrings)

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        borderBottom: '1px dashed',
        padding: 'large',
      }}
      disableHover
    >
      <Box flexDirection="column">
        <Text fontSize="small" marginBottom="medium">
          {title}
        </Text>

        <Text color="bodyFontLightColor" fontSize="xsmall" marginBottom="medium">
          <FontAwesomeIcon icon={faFileAlt} style={{ marginRight: 5 }} />
          {`${snakeToTitleCase(createFormPlatformKey)} | ${formatString(strings.formId, {
            id: createFormExternalId,
          })}`}
        </Text>
      </Box>

      <Box marginRight="medium" width="auto">
        <Button
          buttonStyle="secondaryEdit"
          onClick={() => editExternalForm(generateExternalFormUrl)}
          icon={<FontAwesomeIcon icon={faPencil} />}
          marginRight="medium"
          size="medium"
          width="auto"
        >
          {strings.buttons?.edit || 'Edit'}
        </Button>

        <IconWithMenu
          icon={(
            <Button
              buttonStyle="secondaryUtility"
              icon={<FontAwesomeIcon icon={faCog} />}
              size="medium"
              style={{ height: 37 }}
            />
          )}
          style={{ width: 'auto' }}
        >
          <IconWithMenu.Menu closeOnClick listStyle={{ right: '-7px' }}>
            <Link key="pdf" href={externalFormPdfUrl}>
              <FontAwesomeIcon icon={faDownload} /> {strings.iconWithMenu?.pdf || 'Download Agreement'}
            </Link>
          </IconWithMenu.Menu>
        </IconWithMenu>
      </Box>
    </ListItem>
  )
}

ExternalDocument.propTypes = {
  agreement: PropTypes.object.isRequired,
}

export default ExternalDocument
