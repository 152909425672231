import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, SidebarModal,
} from '@campaignhub/suit-theme'

import useDocumentTemplate, { useDocumentTemplateForm } from '@hooks/useDocumentTemplate'
import useLocalization from '@hooks/useLocalization'

import CreateNew from './screens/CreateNew'

import localizedStrings from './localizedStrings'

const CreateDocumentTemplateModal = (props) => {
  const {
    callbacks, modalKey, selectedOrganization, showModal,
  } = props
  const { createDocumentTemplate } = callbacks || {}

  const documentTemplatePayload = useDocumentTemplate({})
  const {
    callbacks: {
      createDocumentTemplate: createFn,
    },
  } = documentTemplatePayload

  const initDocumentTemplate = {
    owner_id: selectedOrganization.id,
    owner_type: 'Organization',
  }

  const documentTemplateFormPayload = useDocumentTemplateForm(initDocumentTemplate)
  const { entityState } = documentTemplateFormPayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.sideBarModalHeader?.titleCreate || 'Create'}
        titleSecondLine={strings.sideBarModalHeader?.subtitleDocumentTemplate || 'Document Template'}
      />

      <Box flexDirection="column" flexShrink={0}>
        <CreateNew
          callbacks={{
            createDocumentTemplate: () => createDocumentTemplate(entityState, createFn),
          }}
          documentTemplateFormPayload={documentTemplateFormPayload}
        />
      </Box>
    </SidebarModal>
  )
}

CreateDocumentTemplateModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  selectedOrganization: PropTypes.object.isRequired,
  showModal: PropTypes.bool,
}

CreateDocumentTemplateModal.defaultProps = {
  modalKey: 'CreateDocumentTemplateModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <CreateDocumentTemplateModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
