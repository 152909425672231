import { useContext } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers } from '@fortawesome/pro-light-svg-icons'

import {
  Box,
  Button,
  FormField,
  InputSearch,
  LoadingModule,
  ModalContext,
  SidebarModal,
  SidebarNoticeBox,
} from '@campaignhub/suit-theme'

import { digObject, toggleArray } from '@campaignhub/javascript-utils'

import filterUnselectedEntities from '@functions/filterUnselectedEntities'

import useContact from '@hooks/useContact'
import useLocalization from '@hooks/useLocalization'
import useProject, { useProjectForm } from '@hooks/useProject'
import useReduxAction from '@hooks/useReduxAction'

import Contact from './components/Contact'

import localizedStrings from './localizedStrings'

const toggleProjectContact = (contactId, entityState, setEntityState) => {
  const { contact_ids } = entityState

  const updatedContactIds = toggleArray(contact_ids, contactId)

  setEntityState({ contact_ids: updatedContactIds })
}

const toggleContact = (response, entityState, setEntityState) => {
  const {
    data: { entity },
  } = response

  toggleProjectContact(entity.id, entityState, setEntityState)
}

const mapResults = (contacts, strings) => {
  const results = {
    groups: [
      {
        key: 'organization',
        title: strings.mapResults?.title || 'CONTACTS',
        items: Object.values(contacts).map(contact => ({
          ...contact,
          secondaryText: contact.mobile,
          text: `${contact.first_name} ${contact.last_name}`,
          key: `Contact-${contact.id}`,
        })),
      },
    ],
  }

  return results
}

const ManageProjectContactsModal = (props) => {
  const {
    callbacks, disableAnimation, modalKey, project: initProject, showModal,
  } = props
  const { updateProject } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const { callbacks: payloadCallbacks } = digObject(modalData, modalKey, {})
  const { loadShortcodeData: loadDataFn } = payloadCallbacks || {}

  const {
    callbacks: { updateProject: updateFn },
    project,
    updating,
  } = useProject(initProject)

  const {
    entityState,
    entityState: { contact_ids, organization_id },
    setEntityState,
  } = useProjectForm(project)

  const {
    callbacks: { createOrEditContact },
  } = useContact({ owner_id: organization_id, owner_type: 'Organization' })

  const {
    contacts: { loading },
    entities,
  } = useSelector(reduxState => reduxState)

  useReduxAction('contacts', 'loadContacts', {})

  const filteredContacts = filterUnselectedEntities(entities, 'contacts', contact_ids)

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal
      callbacks={callbacks}
      disableAnimation={disableAnimation}
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.sideBarModalHeader?.title || 'Manage'}
        titleSecondLine={strings.sideBarModalHeader?.titleSecondLine || 'Contacts'}
      />

      <SidebarModal.Content>
        <FormField label={strings.formFieldLabel?.searchContacts || 'Search Contacts'}>
          <InputSearch
            callbacks={{
              selectItem: contact => toggleProjectContact(contact.id, entityState, setEntityState),
            }}
            results={mapResults(filteredContacts, strings)}
            closeOnSelect
            showOnFocus
          />
        </FormField>

        <LoadingModule boxProps={{ marginTop: 'large' }} loading={loading} times={3} />

        {!loading && !contact_ids.length && (
          <SidebarNoticeBox boxProps={{ marginTop: 'large', marginBottom: 0 }}>
            <SidebarNoticeBox.Title>{strings.noticeBox?.title || 'Search Contacts'}</SidebarNoticeBox.Title>
            <SidebarNoticeBox.Paragraph>
              {strings.noticeBox?.paragraph
                || 'Contacts assigned to your account can be searched above. If you have not created any yet click the button below'}
            </SidebarNoticeBox.Paragraph>
          </SidebarNoticeBox>
        )}

        {!loading
          && !!contact_ids.length
          && contact_ids.map(contactId => (
            <Contact
              callbacks={{
                deleteContact: () => toggleProjectContact(contactId, entityState, setEntityState),
              }}
              contactId={contactId}
              key={contactId}
            />
          ))}

        <FormField boxProps={{ marginTop: 'large' }} label={strings.formFieldLabels?.moreOptions || 'More Options'}>
          <Box flexDirection="column">
            <Button
              buttonStyle="secondaryUtility"
              icon={<FontAwesomeIcon icon={faUsers} />}
              onClick={() => createOrEditContact({
                afterActionCallback: response => toggleContact(response, entityState, setEntityState),
              })}
              size="medium"
            >
              {strings.buttons?.create || 'Create Contact'}
            </Button>
          </Box>
        </FormField>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          loading={updating}
          loadingBubbleColor="white"
          onClick={() => updateProject(entityState, 'Contacts', updateFn, loadDataFn)}
          size="large"
        >
          {strings.buttons?.save || 'Save'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

ManageProjectContactsModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  disableAnimation: PropTypes.bool,
  modalKey: PropTypes.string,
  project: PropTypes.object.isRequired,
  showModal: PropTypes.bool,
}

ManageProjectContactsModal.defaultProps = {
  modalKey: 'ManageProjectContactsModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <ManageProjectContactsModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
