import React from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle, faTasks, faTimesCircle, faTrashAlt,
} from '@fortawesome/pro-light-svg-icons'

import { Button, Tools } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const confirmDeletion = (bulkDeleteImages, bulkDeleteFn, strings) => {
  swal
    .fire({
      confirmButtonColor: '#DD6B55',
      confirmButtonText: strings.swal?.confirmButtonText || 'Yes, delete them.',
      icon: 'warning',
      showCancelButton: true,
      text: strings.swal?.text || 'Are you sure? They cannot be recovered.',
      title: strings.swal?.title || 'Delete Selected Images?',
    })
    .then(({ value }) => {
      if (value){
        bulkDeleteImages(bulkDeleteFn)
      }
    })
}

const ProjectMediaTools = (props) => {
  const { callbacks, galleryView, imagesPayload } = props

  const { bulkDeleteImages } = callbacks

  const {
    allSelected,
    bulkSelecting,
    callbacks: {
      bulkDeleteImages: bulkDeleteFn,
      saveBulkSelectSort,
      setState: setImagesState,
      toggleAllImages,
    },
    hasSelectedImages,
  } = imagesPayload || {}

  const { strings } = useLocalization(localizedStrings)

  return (
    <Tools title={strings.tools || 'Tools'}>
      {galleryView !== 'list' && (
        <Button
          buttonStyle="secondaryUtility"
          icon={<FontAwesomeIcon icon={bulkSelecting ? faCheckCircle : faTasks} />}
          marginBottom={hasSelectedImages ? 'medium' : 0}
          onClick={bulkSelecting
            ? () => saveBulkSelectSort()
            : () => setImagesState({ bulkSelecting: !bulkSelecting })}
          size="medium"
        >
          {bulkSelecting ? (strings.saveImageSort || 'Save Image Sort') : (strings.bulkSelectButton || 'Bulk Select Images')}
        </Button>
      )}

      {galleryView === 'list' && (
        <Button
          buttonStyle="secondaryUtility"
          icon={<FontAwesomeIcon icon={allSelected ? faTimesCircle : faCheckCircle} />}
          onClick={toggleAllImages}
          marginBottom={hasSelectedImages ? 'medium' : 0}
          size="medium"
        >
          {allSelected ? (strings.deselectAllButton || 'Deselect All') : (strings.selectAllButton || 'Select All')}
        </Button>
      )}

      {hasSelectedImages && (
        <Button
          buttonStyle="secondaryUtility"
          icon={<FontAwesomeIcon icon={faTrashAlt} />}
          onClick={() => confirmDeletion(bulkDeleteImages, bulkDeleteFn, strings)}
          size="medium"
        >
          {strings.deleteButton || 'Delete'}
        </Button>
      )}
    </Tools>
  )
}

ProjectMediaTools.propTypes = {
  callbacks: PropTypes.shape({
    bulkDeleteImages: PropTypes.func.isRequired,
  }).isRequired,
  galleryView: PropTypes.string.isRequired,
  imagesPayload: PropTypes.shape({
    allSelected: PropTypes.bool.isRequired,
    callbacks: PropTypes.shape({
      bulkDeleteImages: PropTypes.func.isRequired,
      toggleAllImages: PropTypes.func.isRequired,
      setState: PropTypes.func.isRequired,
    }).isRequired,
  }).isRequired,
}

export default ProjectMediaTools
