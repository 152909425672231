import { MainContent, TopBar } from '@campaignhub/suit-theme'

import useIntercom from '@hooks/useIntercom'
import useMainNavigation from '@hooks/useMainNavigation'

import DashboardSearch from '@components/DashboardSearch'
import LoggedInUser from '@components/LoggedInUser'
import MainNavigation from '@sections/Client/components/MainNavigation'

import DashboardRoutes from './routes'

const Dashboard = () => {
  const { callbacks: { toggleNavigation } } = useMainNavigation()

  useIntercom()

  return (
    <>
      <TopBar
        callbacks={{ toggleNavigation }}
        loggedInUserComponent={<LoggedInUser />}
        searchComponent={<DashboardSearch searchEntities={['Project']} />}
      />

      <MainNavigation />

      <MainContent offset={{ top: TopBar.topBarHeight }}>
        <DashboardRoutes />
      </MainContent>
    </>
  )
}

export default Dashboard
