import React from 'react'
import PropTypes from 'prop-types'

import { Image } from '@campaignhub/suit-theme'

import styles from './styles.module.scss'

const AssetThumbnail = (props) => {
  const {
    callbacks: { selectImage },
    image,
  } = props

  const { image_url } = image

  return (
    <Image
      borderRadius={5}
      boxProps={{
        onClick: selectImage,
      }}
      className={styles.root}
      width="100%"
      height="100%"
      url={image_url}
    />
  )
}

AssetThumbnail.propTypes = {
  callbacks: PropTypes.shape({
    selectImage: PropTypes.func,
  }),
  image: PropTypes.object.isRequired,
}

export default AssetThumbnail
