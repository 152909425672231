import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCodeBranch } from '@fortawesome/pro-light-svg-icons'

import { BlankState, Button } from '@campaignhub/suit-theme'

import BlankStateImageUrl from './assets/no-integrations.svg'

const IntegrationsBlankState = (props) => {
  const {
    buttonText, buttonUrl, paragraph, sidebar, title,
  } = props

  return (
    <BlankState
      imageUrl={BlankStateImageUrl}
      sidebar={sidebar}
    >
      <BlankState.Title>{title}</BlankState.Title>

      <BlankState.Paragraph>{paragraph}</BlankState.Paragraph>

      {buttonUrl && (
        <BlankState.Actions>
          <Button
            as="a"
            buttonStyle="secondaryEdit"
            href={buttonUrl}
            icon={<FontAwesomeIcon icon={faCodeBranch} />}
            size="medium"
          >
            {buttonText}
          </Button>
        </BlankState.Actions>
      )}
    </BlankState>
  )
}

IntegrationsBlankState.propTypes = {
  buttonText: PropTypes.string,
  buttonUrl: PropTypes.string,
  paragraph: PropTypes.string,
  sidebar: PropTypes.bool,
  title: PropTypes.string,
}

IntegrationsBlankState.defaultProps = {
  buttonText: 'Manage Integrations',
  paragraph: 'Your organisation doesn’t have any system integrations. System integrations help automate the flow of information between multiple platforms and eliminate needless repetitive data entry.',
  title: 'No Integrations Connected',
}

export default IntegrationsBlankState
