import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library as iconLibrary, findIconDefinition } from '@fortawesome/fontawesome-svg-core'

import { fas } from '@fortawesome/pro-solid-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

import { useSetState } from '@campaignhub/react-hooks'
import { Button as BaseButton } from '@campaignhub/suit-theme'

import useDeviceStyle from '@hooks/useDeviceStyle'

iconLibrary.add(fas, fal, fab)

const LIBRARY_MAP = {
  'pro-solid': 'fas',
  'pro-light': 'fal',
  'free-brands': 'fab',
}

const getIcon = async (iconValue, library, setState) => {
  try {
    const iconDefinition = findIconDefinition({
      prefix: LIBRARY_MAP[library || 'pro-light'],
      iconName: iconValue.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase().replace(/^fa-/, ''),
    })

    if (iconDefinition) setState({ icon: iconDefinition })
  } catch {
    toast.warning(`Icon ${iconValue} not found`)
  }
}

const defaultState = {
  icon: null,
}

const Button = (props) => {
  const { onClick, options } = props

  const [state, setState] = useSetState(defaultState)
  const { icon } = state

  const {
    buttonStyle,
    href,
    icon: iconValue,
    iconLibrary: library,
    iconMargin,
    iconPosition,
    text,
    textProps,
    ...buttonOptions
  } = options

  const style = useDeviceStyle(props)

  useEffect(() => {
    if (iconValue) getIcon(iconValue, library, setState)
  }, [iconValue, library])

  return (
    <BaseButton
      buttonStyle={buttonStyle}
      flexGap="100px"
      icon={icon && <FontAwesomeIcon icon={icon} />}
      iconMargin={iconMargin}
      iconPosition={iconPosition}
      onClick={onClick}
      style={style}
      {...buttonOptions}
    >
      {text}
    </BaseButton>
  )
}

Button.propTypes = {
  data: PropTypes.object,
  onClick: PropTypes.func,
  options: PropTypes.object,
  style: PropTypes.object,
}

Button.defaultProps = {
  data: {},
  options: {},
}

export default Button
