import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignature } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, StatusBadge, Text,
} from '@campaignhub/suit-theme'

import useDocumentRecipient from '@hooks/useDocumentRecipient'
import useLocalization from '@hooks/useLocalization'

import { statusColor } from '@functions/status'

import localizedStrings from './localizedStrings'

const DocumentRecipient = (props) => {
  const {
    agreementPayload: { creating },
    callbacks: { generateSigningUrl },
    documentRecipient,
    temporaryStatus,
  } = props

  const documentRecipientPayload = useDocumentRecipient(documentRecipient, { temporaryStatus })
  const {
    documentRecipient: { email, legal_name, in_person_signer },
    isCompleted,
    recipientColor,
    status,
  } = documentRecipientPayload

  const {
    callbacks: { formatString },
    strings,
  } = useLocalization(localizedStrings)

  return (
    <Box flexDirection="column" marginTop="medium">
      <Box alignItems="center" fontSize="small" padding="medium" variant="white">
        <Box backgroundColor={recipientColor} borderRadius={5} height={20} width={20} />

        <Box flexDirection="column" marginLeft="medium" width="auto" marginRight="auto">
          <Text fontSize="small">{legal_name}</Text>

          <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="small">
            {email}
          </Text>
        </Box>

        <StatusBadge color={statusColor(status.key)} ghost text={status.title} />
      </Box>

      {!isCompleted && generateSigningUrl && in_person_signer && (
        <Button
          buttonStyle="secondaryEdit"
          icon={<FontAwesomeIcon icon={faSignature} />}
          loading={creating}
          marginTop="medium"
          onClick={() => generateSigningUrl()}
          size="medium"
          width="auto"
        >
          {formatString(strings.signAs, { name: legal_name })}
        </Button>
      )}
    </Box>
  )
}

DocumentRecipient.propTypes = {
  agreementPayload: PropTypes.shape({
    creating: PropTypes.bool.isRequired,
  }).isRequired,
  callbacks: PropTypes.shape({
    generateSigningUrl: PropTypes.func,
  }),
  documentRecipient: PropTypes.object.isRequired,
  temporaryStatus: PropTypes.object,
}

export default DocumentRecipient
