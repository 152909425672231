import React from 'react'

import { DashboardGuideModule } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

import guideImageUrl from './assets/form-builder-blank-state.svg'

const FormBuilderBlankState = () => {
  const { strings } = useLocalization(localizedStrings)

  return (
    <DashboardGuideModule boxProps={{ marginBottom: 'large' }} imageUrl={guideImageUrl}>
      <DashboardGuideModule.Title>{strings.title || 'Add a field to get started'}</DashboardGuideModule.Title>

      <DashboardGuideModule.Paragraph>
        {strings.paragraph
            || 'You can add fields to this form template and use the form in digital pages to receive information from clients easily.'}
      </DashboardGuideModule.Paragraph>
    </DashboardGuideModule>
  )
}

export default FormBuilderBlankState
