export default {
  default: {
    copyLinkButton: 'Copy Link',
    deleteLinkButton: 'Delete Link',
    header: {
      title: 'Edit',
      titleSecondLine: 'Share Link',
    },
    clearExpiry: 'Remove Expiry Date',
    expiryDate: 'Expires:',
    setExpiry: 'Set Expiry Date:',
    moreOptions: 'More Options',
    saveButton: 'Save',
    selectBoxLabel: 'Share Link',
    sendButton: 'Send via SMS',
    viewAnalytics: 'View Analytics',
    swal: {
      title: 'Delete Share Link?',
      text: 'Are you sure?',
      confirmButtonText: 'Yes, delete it',
    },
    toggleButton: {
      enabledTitle: 'Link Active',
      disabledTitle: 'Link Inactive',
      enabledText: 'Your link is active and can be viewed by anyone with link access.',
      disabledText: 'Your link is inactive and can no longer be viewed.',
    },
    toast: {
      copySuccess: 'Copied to clipboard',
    },
    VideoModule: {
      title: 'Sharing & Tracking',
      paragraph: 'How to Share and Track Your Proposal and the difference between Proofing and Tracking.',
    },
    title: 'Title',
  },
}
