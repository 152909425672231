import { useEffect } from 'react'

import { getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'

import { useThunkDispatch } from '@campaignhub/react-hooks'

import * as projectActions from '@redux/modules/project'

const buildRedirectUrl = (id) => {
  const {
    digitalTemplateType, showModal,
  } = parsePermittedQueryParams(getQueryParamsFromHash(), ['digitalTemplateType', 'showModal'])

  let url = `#/projects/${id}`
  if (showModal && digitalTemplateType) url += `?showModal=${showModal}&digitalTemplateType=${digitalTemplateType}`
  if (!showModal && digitalTemplateType) url += `/digitalPages?digitalTemplateType=${digitalTemplateType}`

  return url
}

const findByExternalDetails = (externalId, integrationPlatformKey, dispatch) => {
  const { findByExternalDetails: findFn } = projectActions

  return dispatch(findFn({ external_id: externalId, platform_key: integrationPlatformKey })).then((response) => {
    const { success, data } = response

    if (success){
      const { entity: { id } } = data

      return {
        success,
        data,
        redirectUrl: buildRedirectUrl(id),
      }
    }

    return response
  })
}

const findExistingOrCreateNewProject = async (externalId, integrationPlatformKey, callbacks, dispatch, showModal) => {
  const { success, redirectUrl } = await findByExternalDetails(externalId, integrationPlatformKey, dispatch, showModal)

  if (success){
    window.location.href = redirectUrl
    return
  }

  const { setModalData, setState } = callbacks

  setState({ showCreateProjectModal: true })
  setModalData('CreateProjectModal', { externalId, integrationPlatformKey })
}

function useExternalProjectParams(callbacks = {}){
  const dispatch = useThunkDispatch()

  const {
    external_id, integration_platform_key,
  } = parsePermittedQueryParams(getQueryParamsFromHash(), ['external_id', 'integration_platform_key'])

  useEffect(() => {
    if (external_id && integration_platform_key){
      findExistingOrCreateNewProject(external_id, integration_platform_key, callbacks, dispatch)
    }
  }, [external_id, integration_platform_key])
}

export default useExternalProjectParams
