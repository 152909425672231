export default {
  default: {
    comparableTypes: {
      marketAll: 'All Market',
      marketCurrent: 'Current Market',
      marketRental: 'Current Rental',
      marketSold: 'Sold Market',
      organizationAll: 'All {global.organizationType.shortName.singular}',
      organizationCurrent: 'Current {global.organizationType.shortName.singular}',
      organizationSold: 'Sold {global.organizationType.shortName.singular}',
    },
    comparable: 'Comparable',
    listViewMode: 'List View',
    propertiesReturned: 'properties returned',
    tileViewMode: 'Tile View',
  },
}
