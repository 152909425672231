import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Link, ListItem, Text,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

type DocumentTemplateProps = {
  callbacks: {
    toggleDocumentTemplate: () => void,
  },
  documentTemplate: {
    cost: number,
    id: number,
    title: string,
  },
  selected: boolean,
}

const DocumentTemplateListItem = (props: DocumentTemplateProps) => {
  const {
    callbacks: { toggleDocumentTemplate },
    documentTemplate: { cost, id, title },
    selected,
  } = props

  const { callbacks: { formatCurrency, formatString }, strings } = useLocalization(localizedStrings)

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        borderBottom: '1px dashed',
        paddingX: 'medium',
        paddingY: 'medium',
      }}
      onClick={() => toggleDocumentTemplate()}
    >
      <Box alignItems="center">
        <Box justifyContent="center" flexDirection="column">
          <Text fontSize="small" lineHeight={1.3}>{title}</Text>

          <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="medium">
            {`${formatString(strings.templateId, { id })} | ${formatCurrency(cost / 100)}`}
          </Text>

        </Box>

        {selected && (
          <Link
            greyLink
            onClick={() => toggleDocumentTemplate()}
            textProps={{ fontSize: 'small' }}
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </Link>
        )}
      </Box>

    </ListItem>
  )
}

export default DocumentTemplateListItem
