export default {
  default: {
    deleteButtonText: 'Delete Library',
    formFieldLabels: {
      libraryContents: 'Library Contents',
      moreOptions: 'More Options',
      title: '* Title',
      uploadFiles: 'Files',
      uploadImages: 'Images',
    },
    modalHeader: {
      titleCreate: 'Create',
      titleUpdate: 'Update',
      titleSecondLine: '{asset_type} Library',
    },
    noContents: 'No Contents, please add above.',
    saveButtonText: 'Save',
    swal: {
      title: 'Delete Asset Library?',
      text: 'Are you sure?',
      confirmButtonText: 'Yes, delete it.',
    },
    toast: {
      assetLibraryUpdated: 'Asset Library Updated Successfully',
      assetLibraryCreated: 'Asset Library Created Successfully',
      assetLibraryDeleted: 'Asset Library Deleted  Successfully',
    },
  },
}
