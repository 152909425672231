import React from 'react'
import PropTypes from 'prop-types'

import SectionContext from '@contexts/sectionContext'

const SectionContextProvider = (props) => {
  const { children, isAdmin } = props

  return (
    <SectionContext.Provider value={{ isAdmin }}>
      {children}
    </SectionContext.Provider>
  )
}

SectionContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  isAdmin: PropTypes.bool,
}

export default SectionContextProvider
