import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'

import { useLocation } from 'react-router-dom'

import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArchive, faFile, faHome } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Columns, DashboardModule, LoadingModule, PageHeader, Tools,
} from '@campaignhub/suit-theme'

import { getQueryParamsFromHash, parsePermittedQueryParams, sortArrayBy } from '@campaignhub/javascript-utils'

import PageContext from '@contexts/pageContext'

import PageFilterModule from '@components/PageFilterModule'
import VideoModule from '@components/VideoModule'

import useCaseStudies from '@hooks/useCaseStudies'
import useCaseStudy from '@hooks/useCaseStudy'
import useCurrentUser from '@hooks/useCurrentUser'
import useLocalization from '@hooks/useLocalization'
import useOrganizationSelector from '@hooks/useOrganizationSelector'
import useOrganizationsUser from '@hooks/useOrganizationsUser'
import useVideoModules from '@hooks/useVideoModules'

import defaultRequestOptions from '@sections/Client/packs/Admin/defaultRequestOptions'

import CaseStudiesBlankState from '../CaseStudiesBlankState'
import CaseStudy from '../CaseStudy'
import localizedStrings from './localizedStrings'

const buildPageFilterFields = (entities, isCurrentUserAdminForOrganization, strings) => {
  const style = {
    marginBottom: 16,
  }

  const { users } = entities
  const usersArray = Object.values(users)

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        style,
      },
      key: 'full_address',
      label: strings.filters?.address || 'Filter by Address',
    },
    {
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: strings.filters?.defaultSelectLabel || 'All',
        inputComponent: 'select',
        style,
      },
      hidden: !isCurrentUserAdminForOrganization,
      key: 'user_id',
      label: strings.filters?.user || 'User',
      values: sortArrayBy(usersArray, 'asc', 'full_name').map(user => ({
        key: user.id,
        label: user.full_name,
      })),
    },
  ]
}

const PageContent = (props) => {
  const { pageFilters } = props

  // useLocation to refresh the window.location. If not used location won't reload if URL changes
  useLocation()

  const { hidden } = parsePermittedQueryParams(getQueryParamsFromHash(), ['hidden'])
  const isHidden = hidden === 'true'

  const { entities } = useSelector(reduxState => reduxState)
  const { currentUser, hasOrganizationsWhereAdmin } = useCurrentUser()
  const { selectedOrganization } = useOrganizationSelector()
  const { isCurrentUserAdminForOrganization } = useOrganizationsUser({ organization_id: selectedOrganization.id })

  const caseStudiesPayload = useCaseStudies({
    filters: {
      hidden: isHidden,
      owner_id: selectedOrganization.id,
      owner_type: 'Organization',
      user_id: isCurrentUserAdminForOrganization ? pageFilters.user_id : currentUser.id,
      ...pageFilters,
    },
    requestOptions: {
      ...defaultRequestOptions.caseStudy,
      ...defaultRequestOptions.image,
    },
    performHttpRequests: true,
  })
  const { filteredCaseStudies, hasCaseStudies, loading } = caseStudiesPayload

  const pageContext = useContext(PageContext)
  const {
    callbacks: { resetFilters, showImportCaseStudiesModal, updateFilters },
  } = pageContext

  const initCaseStudy = { owner_id: selectedOrganization.id, owner_type: 'Organization' }
  if (!isCurrentUserAdminForOrganization) initCaseStudy.user_ids = [currentUser.id]

  const {
    callbacks: { createOrEditCaseStudy },
    urls: { hiddenCaseStudiesIndexUrl, caseStudiesIndexUrl },
  } = useCaseStudy(initCaseStudy)

  const videoModulePayload = useVideoModules({ key: 'caseStudies' })
  const { videosPayload } = videoModulePayload

  const { strings } = useLocalization(localizedStrings)

  const title = strings.title ? `${strings.title} | Engage` : 'Engage'

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <PageHeader
        actionContent={(
          <Button buttonStyle="primaryCreate" onClick={() => createOrEditCaseStudy()} size="medium">
            {strings.createButton || 'Create'}
          </Button>
        )}
        activeTabBarItemKey={isHidden ? 'hidden' : 'current'}
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        nestedNavigation
        tabBarItems={[
          {
            href: caseStudiesIndexUrl,
            icon: faHome,
            key: 'current',
            title: strings.currentTitle || 'Current',
          },
          {
            href: hiddenCaseStudiesIndexUrl,
            icon: faArchive,
            key: 'hidden',
            title: strings.archivedTitle || 'Archived',
          },
        ]}
        title={strings.title || 'Case Studies'}
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && hasCaseStudies && (
              <DashboardModule title={strings.title || 'Case Studies'}>
                <Box flexDirection="column">
                  <Box flexDirection="column">
                    {filteredCaseStudies.map(caseStudy => (
                      <CaseStudy key={caseStudy.id} caseStudy={caseStudy} />
                    ))}
                  </Box>

                  <DashboardModule.Footer>
                    <DashboardModule.FooterRight>
                      <Button
                        buttonStyle="secondaryEdit"
                        onClick={() => createOrEditCaseStudy()}
                        size="medium"
                        style={{ width: '100%' }}
                      >
                        {strings.addCaseStudy || 'Add Case Study'}
                      </Button>
                    </DashboardModule.FooterRight>
                  </DashboardModule.Footer>
                </Box>
              </DashboardModule>
            )}

            {!loading && !hasCaseStudies && <CaseStudiesBlankState />}
          </Columns.Main>

          <Columns.Sidebar>
            <Tools title={strings.tools || 'Tools'}>
              {hasOrganizationsWhereAdmin && (
                <Button
                  buttonStyle="secondaryUtility"
                  icon={<FontAwesomeIcon icon={faFile} />}
                  marginBottom="medium"
                  onClick={() => showImportCaseStudiesModal()}
                  size="medium"
                >
                  {strings.importCaseStudies || 'Import Case Studies'}
                </Button>
              )}
            </Tools>

            <PageFilterModule
              callbacks={{ resetFilters, updateFilters }}
              filterFields={buildPageFilterFields(entities, isCurrentUserAdminForOrganization, strings)}
              pageFilters={pageFilters}
              title={strings.filtersTitle || 'Filters'}
            />

            <VideoModule videosPayload={videosPayload} />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

PageContent.propTypes = {
  pageFilters: PropTypes.object.isRequired,
}

export default PageContent
