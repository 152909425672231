import FieldHolder from '@components/FieldHolder'
import Input from '@components/Input'

interface FlexPropertiesProps {
  itemStyle: {
    alignItems: string,
    flexDirection: string,
    justifyContent: string,
  },
  selectedEntity: {
    id: number,
  },
  styleProps: {},
  updateStyle: (name: string, value: string, styleProps: {}) => void,
}

const FlexProperties = (props: FlexPropertiesProps) => {
  const {
    itemStyle, styleProps, selectedEntity, updateStyle,
  } = props

  return (
    <>
      <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth label="Flex Direction">
        <select
          onChange={e => updateStyle('flexDirection', e.target.value, styleProps)}
          value={itemStyle.flexDirection || ''}
        >
          <option value="">Default</option>
          <option value="column">Column</option>
          <option value="column-reverse">Column (Reverse)</option>
          <option value="row">Row</option>
          <option value="row-reverse">Row (Reverse)</option>
        </select>
      </FieldHolder>

      <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth label="Flex Wrap" last>
        <select onChange={e => updateStyle('flexWrap', e.target.value, styleProps)} value={itemStyle.flexWrap || ''}>
          <option value="">Default</option>
          <option value="nowrap">No Wrap</option>
          <option value="wrap">Wrap</option>
          <option value="wrap-reverse">Wrap (Reverse)</option>
        </select>
      </FieldHolder>

      <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth label="Align Items">
        <select
          onChange={e => updateStyle('alignItems', e.target.value, styleProps)}
          value={itemStyle.alignItems || ''}
        >
          <option value="">Default</option>
          <option value="flex-start">Start</option>
          <option value="center">Center</option>
          <option value="flex-end">End</option>
        </select>
      </FieldHolder>

      <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth label="Justify Content" last>
        <select
          onChange={e => updateStyle('justifyContent', e.target.value, styleProps)}
          value={itemStyle.justifyContent || ''}
        >
          <option value="">Default</option>
          <option value="flex-start">Start</option>
          <option value="center">Center</option>
          <option value="flex-end">End</option>
          <option value="space-around">Space Around</option>
          <option value="space-between">Space Between</option>
          <option value="space-evenly">Space Evenly</option>
        </select>
      </FieldHolder>

      <Input
        boxProps={{ marginBottom: 'large' }}
        data={itemStyle}
        entityId={selectedEntity.id}
        halfWidth
        propertyKey="flexGrow"
        label="Flex Grow"
        updateCallback={(name, value) => updateStyle(name, value, styleProps)}
      />

      <Input
        boxProps={{ marginBottom: 'large' }}
        data={itemStyle}
        entityId={selectedEntity.id}
        halfWidth
        last
        propertyKey="flexShrink"
        label="Flex Shrink"
        updateCallback={(name, value) => updateStyle(name, value, styleProps)}
      />

      <Input
        boxProps={{ marginBottom: 'large' }}
        data={itemStyle}
        entityId={selectedEntity.id}
        halfWidth
        propertyKey="flexBasis"
        label="Flex Basis"
        updateCallback={(name, value) => updateStyle(name, value, styleProps)}
      />

      <Input
        boxProps={{ marginBottom: 'large' }}
        data={itemStyle}
        entityId={selectedEntity.id}
        halfWidth
        last
        propertyKey="gap"
        label="Gap"
        updateCallback={(name, value) => updateStyle(name, value, styleProps)}
      />
    </>
  )
}

export default FlexProperties
