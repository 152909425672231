import {
  Sector,
} from 'recharts'

import isMobile from 'is-mobile'

import { ActiveShapeProps, useCurrentProvider } from '../../hooks/useChartHooks'

const RenderActiveShape = (props: ActiveShapeProps) => {
  const { dataArray, chartOptions } = props
  const { currentProvider, currentProviderPercentage } = useCurrentProvider(dataArray!)
  const { primaryColor } = chartOptions
  const mobile = isMobile()
  const RADIAN = Math.PI / 180

  const {
    cx, cy, endAngle, fill, innerRadius, midAngle, outerRadius, payload,
    percent, startAngle, value,
  } = props

  const sin = Math.sin(-RADIAN * midAngle!)
  const cos = Math.cos(-RADIAN * midAngle!)
  const sx = cx! + (outerRadius! + 10) * cos
  const sy = cy! + (outerRadius! + 10) * sin
  const mx = cx! + (outerRadius! + 30) * cos
  const my = cy! + (outerRadius! + 30) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 22
  const ey = my
  const textAnchor = cos >= 0 ? 'start' : 'end'

  return (
    <g>
      {mobile
        ? (
          <>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill="#333">
              {`${payload!.name} = ${value}`}
            </text>
            <text x={cx} y={cy} dy={30} textAnchor="middle" fill="#999">
              {`(Rate ${(percent! * 100).toFixed(2)}%)`}
            </text>
          </>
        ) : (
          <>
            <text x={cx} y={cy} dy={2} textAnchor="middle" fill={primaryColor}>
              {`${currentProvider!.name} = ${currentProvider!.value}`}
            </text>
            <text x={cx} y={cy} dy={20} textAnchor="middle" fill="#999">
              {`(Rate ${(currentProviderPercentage!).toFixed(2)}%)`}
            </text>
            <h1>This thing</h1>
          </>
        )}
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius! + 6}
        outerRadius={outerRadius! + 10}
        fill={fill}
      />
      {!mobile
        && (
        <>
          <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
          <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
          <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">
            {`${payload!.name} = ${value}`}
          </text>
          <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
            {`(Rate ${(percent! * 100).toFixed(2)}%)`}
          </text>
        </>
        )}
    </g>
  )
}

export default RenderActiveShape
