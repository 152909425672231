import { useEffect } from 'react'

import { useSetState, useThunkDispatch } from '@campaignhub/react-hooks'

import * as organizationActions from '@redux/modules/organization'
import { AppDispatch } from '@redux/store'

import type { OrganizationModel } from '@models/types'

type DefaultState = {
  errors: string[],
  loaded: boolean,
  loading: boolean,
  relatedEntityCounts: {},
}

const defaultState: DefaultState = {
  errors: [],
  loaded: false,
  loading: false,
  relatedEntityCounts: {},
}

type GetRelatedEntityCountsParams = {
  dispatch: AppDispatch,
  entityKeys?: string[],
  organizationId: number,
  setState: (state: Partial<DefaultState>) => void,
}

const getRelatedEntityCounts = (params: GetRelatedEntityCountsParams) => {
  const {
    dispatch, entityKeys, organizationId, setState,
  } = params
  const { loadRelatedEntityCounts: loadFn } = organizationActions

  return dispatch(loadFn(organizationId, { entity_keys: entityKeys })).then(({ success, data, errors }) => {
    if (!success){
      setState({
        errors, loaded: true, loading: false, relatedEntityCounts: {},
      })
    }

    setState({ loaded: true, loading: false, relatedEntityCounts: data })
  })
}

type UseOrganizationRelatedEntityCountsOptions = {
  entityKeys?: string[],
}

function useOrganizationRelatedEntityCounts(
  organization: OrganizationModel,
  options: UseOrganizationRelatedEntityCountsOptions = {},
) {
  const { entityKeys } = options

  const [state, setState] = useSetState(defaultState)

  const dispatch = useThunkDispatch()

  useEffect(() => {
    if (organization.id){
      getRelatedEntityCounts({
        dispatch, entityKeys, organizationId: organization.id as number, setState,
      })
    }
  }, [organization.id])

  return {
    ...state,
  }
}

export default useOrganizationRelatedEntityCounts
