import PropTypes from 'prop-types'
import isMobileBrowser from 'is-mobile'
import { MainNavigation as BaseNavigation } from '@campaignhub/suit-theme'

import useCurrentUser from '@hooks/useCurrentUser'
import useLocalization from '@hooks/useLocalization'
import useMainNavigation from '@hooks/useMainNavigation'
import useUserImpersonation from '@hooks/useUserImpersonation'

import systemBrandPayload from '@components/systemBrands/default'
import generateMainNavigationItems from '@sections/Client/utils/generateMainNavigationItems'

import localizedStrings from './localizedStrings'

interface MainNavigationProps {
  minimized: boolean,
  nestedNavigation: boolean,
}

const MainNavigation = (props: MainNavigationProps) => {
  const { minimized, nestedNavigation } = props

  const mainNavigationPayload = useMainNavigation()
  const { callbacks: { toggleNavigation }, showNavigation } = mainNavigationPayload

  const {
    currentUser, hasOrganizationsWhereAdmin, impersonatedUser, isImpersonatingUser,
  } = useCurrentUser()

  const userImpersonationPayload = useUserImpersonation()
  const { callbacks: { unlinkImpersonation } } = userImpersonationPayload

  const isMobile = isMobileBrowser()
  const isTablet = isMobileBrowser({ tablet: true })
  const isMobileDevice = isMobile || isTablet

  const { strings } = useLocalization(localizedStrings)

  return (
    <BaseNavigation
      callbacks={{ toggleSidebar: toggleNavigation }}
      minimized={minimized}
      navigationItems={generateMainNavigationItems({
        callbacks: { unlinkImpersonation },
        currentUser,
        hasOrganizationsWhereAdmin,
        impersonatedUser,
        isImpersonatingUser,
        isMobileDevice,
        strings,
      })}
      nestedNavigation={nestedNavigation}
      nestedNavigationWidth={240}
      showNavigation={showNavigation}
      systemBrandPayload={systemBrandPayload}
    />
  )
}

MainNavigation.propTypes = {
  minimized: PropTypes.bool,
  nestedNavigation: PropTypes.node,
}

export default MainNavigation
