import type { AddressModel } from './address'

export type ReviewModel = {
  id: number,
  address_attributes?: Partial<AddressModel>,
  address_id?: number,
  body: string,
  cache_key: number,
  data?: {},
  hidden?: boolean,
  owner_id: number,
  owner_type: string,
  rating: number,
  reviewer_name: string,
  title: string,
  user_ids: Array<number>,
}

export type ReviewRequestOptions = {
  include_review_address?: boolean,
  include_review_default_image?: boolean,
  include_review_user_ids?: boolean,
}

const state = {
  address_attributes: {},
  body: '',
  data: {},
  hidden: false,
  id: null,
  owner_id: null,
  owner_type: '',
  rating: 5,
  reviewer_name: '',
  title: '',
  user_ids: [],
}

export const requiredFields = [
  { key: 'body' },
  { key: 'owner_id' },
  { key: 'owner_type' },
  { key: 'rating' },
  { key: 'reviewer_name' },
  { key: 'title' },
  { key: 'user_ids', validation: (userIds: Array<number>) => !!userIds.length },
]

export default state
