import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { useOutsideClick } from '@campaignhub/react-hooks'
import { Box } from '@campaignhub/suit-theme'

import DigitalTemplateContext from '@contexts/digitalTemplateContext'

import useDigitalPageSidebar from '@hooks/useDigitalPageSidebar'

import SectionGroup from '@components/digitalRenderer/components/SectionGroup'

import styles from './styles.module.scss'

const DigitalPageSidebar = (props) => {
  const {
    digitalRendererPayload: {
      callbacks: { toggleDigitalPageSidebar },
      digitalPage,
      sidebarOptions: { width },
      showDigitalPageSidebar,
      targetDevice,
    },
  } = props

  const digitalTemplateSidebarPayload = useDigitalPageSidebar(digitalPage)
  const { componentStyles, filteredSectionGroups } = digitalTemplateSidebarPayload

  const contentEl = useRef()
  const [isClickedOutside, setIsClickedOutside] = useOutsideClick(contentEl, { enabled: showDigitalPageSidebar })

  useEffect(() => {
    if (!showDigitalPageSidebar) setIsClickedOutside(false)
    if (isClickedOutside && showDigitalPageSidebar){
      toggleDigitalPageSidebar()
      setIsClickedOutside(false)
    }
  }, [isClickedOutside, showDigitalPageSidebar])

  if (!showDigitalPageSidebar){
    return null
  }

  return (
    <DigitalTemplateContext.Provider value={{ componentStyles, targetDevice }}>
      <Box
        backgroundColor="white"
        borderRight="1px solid"
        borderColor="lineColor"
        bottom="0"
        className={styles.root}
        flexDirection="column"
        left="0"
        position="fixed"
        ref={contentEl}
        top="0"
        width={width || 240}
        zIndex="999"
      >
        {filteredSectionGroups.map(sectionGroup => (
          <SectionGroup
            callbacks={{}}
            isSidebar
            key={sectionGroup.id}
            sectionGroup={sectionGroup}
            style={{ height: '100%' }}
            useSuppliedEntity
          />
        ))}
      </Box>
    </DigitalTemplateContext.Provider>
  )
}

DigitalPageSidebar.propTypes = {
  digitalRendererPayload: PropTypes.object.isRequired,
}

export default DigitalPageSidebar
