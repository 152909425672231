import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, FormField, SortableList,
} from '@campaignhub/suit-theme'

import DraggableImage from '@components/ModalComponents/DraggableImage'

const DraggableImages = (props) => {
  const {
    callbacks: { updateDefaultImage, modifyImageSort },
    label,
    sortedImages,
  } = props

  return (
    <FormField
      direction="column"
      label={label}
      marginTop="large"
    >
      <Box flexDirection="column" flexShrink={0}>
        <SortableList
          callbacks={{
            onDragEnd: (fromIndex, toIndex, payload) => modifyImageSort(fromIndex, toIndex, payload),
          }}
        >
          <SortableList.Dropzone droppableId="sortedIds">
            <Box flexDirection="column">
              {sortedImages.map((image, index) => (
                <SortableList.Item draggableId={`Image-${image.id}`} key={image.id} index={index}>
                  <DraggableImage callbacks={{ updateDefaultImage }} image={image} />
                </SortableList.Item>
              ))}
            </Box>
          </SortableList.Dropzone>
        </SortableList>
      </Box>
    </FormField>
  )
}

DraggableImages.propTypes = {
  callbacks: PropTypes.object.isRequired,
  label: PropTypes.string,
  sortedImages: PropTypes.array,
}

export default DraggableImages
