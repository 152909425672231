import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/pro-solid-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'

import useImage from '@hooks/useImage'

import { Box, Image } from '@campaignhub/suit-theme'

import PlaceHolderImage from './assets/placeholderImage.png'

const UserImage = (props) => {
  const {
    callbacks: { updateDefaultImage },
    image: initImage,
    isDefault,
  } = props

  const {
    callbacks: { editImage },
    image,
  } = useImage(initImage)

  const imageUrl = digObject(image, 'sizes.thumb_180x180', '')

  return (
    <Box backgroundImage={`url(${PlaceHolderImage})`} borderRadius={5}>
      <Image
        borderRadius={5}
        backgroundPosition="top center"
        forwardProps={{
          onClick: () => editImage(updateDefaultImage),
          style: { cursor: 'pointer' },
        }}
        url={imageUrl}
      >
        {isDefault && (
          <Box
            alignItems="flex-end"
            color="white"
            fontSize="small"
            height="100%"
            justifyContent="flex-end"
            padding="medium"
          >
            <FontAwesomeIcon icon={faStar} />
          </Box>
        )}
      </Image>
    </Box>
  )
}

UserImage.propTypes = {
  callbacks: PropTypes.object.isRequired,
  image: PropTypes.object.isRequired,
  isDefault: PropTypes.bool,
}

export default UserImage
