export default {
  default: {
    button: {
      addCustomField: 'Add Custom Field',
    },
    documentTemplateField: {
      initial: 'Initial',
      signature: 'Signature',
    },
    formField: {
      autoPopulated: 'Auto Populated',
      completedBy: 'Completed By',
      recipient: 'Recipient',
    },
    header: {
      title: 'Add Document',
      secondLine: 'Field',
    },
  },
}
