import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImages } from '@fortawesome/pro-light-svg-icons'

import { Box, Button, SidebarModal } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import useDigitalTemplate, { getDigitalTemplateEnabledFeatures, useDigitalTemplateForm } from '@hooks/useDigitalTemplate'

import ToggleTargetDeviceButton from '@components/ToggleTargetDeviceButton'

import TemplatePageBackups from './components/TemplatePageBackups'
import ChartFeatures from './components/ChartFeatures'
import ComponentStyles from './components/ComponentStyles'
import Details from './components/Details'
import Features from './components/Features'
import Fonts from './components/Fonts'
import Footer from './components/Footer'
import ImportAndExport from './components/ImportAndExport'
import Print from './components/Print'
import SectionSort from './components/SectionSort'
import Sidebar from './components/Sidebar'
import TemplateImage from './components/TemplateImage'

const EditDigitalTemplateModal = (props) => {
  const {
    callbacks, digitalTemplate, modalKey, showModal,
  } = props

  const digitalTemplateFormPayload = useDigitalTemplateForm(digitalTemplate)

  const digitalTemplatePayload = useDigitalTemplate(digitalTemplate)
  const { isSidebarTemplate, previewImage, shareImage } = digitalTemplatePayload

  const { enabledFeatures } = getDigitalTemplateEnabledFeatures(digitalTemplate)

  const pageContext = useContext(PageContext)
  const {
    callbacks: { editSectionSort, manageDigitalTemplateAssets, setTargetDevice },
    isMobileDevice,
    targetDevice,
  } = pageContext

  return (
    <SidebarModal
      callbacks={callbacks}
      disableAnimation
      disableOverlay
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      {isMobileDevice && <SidebarModal.Header callbacks={callbacks} title="Edit Template" titleSecondLine="Settings" />}

      <SidebarModal.Content boxProps={{ padding: 0 }} hasFooter={false} hasHeader={!!isMobileDevice}>
        <Box flexDirection="column">
          <Box borderBottom="1px solid" borderColor="lineColor" padding="large">
            <ToggleTargetDeviceButton
              boxProps={{ width: 'calc(50% - 4px)' }}
              callbacks={{ setTargetDevice }}
              targetDevice={targetDevice}
            />

            <Button
              buttonStyle="secondaryUtility"
              height={37}
              icon={<FontAwesomeIcon icon={faImages} />}
              marginLeft="small"
              onClick={manageDigitalTemplateAssets}
              size="medium"
              width="calc(50% - 4px)"
            >
              Assets
            </Button>
          </Box>

          <Details digitalTemplateFormPayload={digitalTemplateFormPayload} />
          <ComponentStyles digitalTemplatePayload={digitalTemplatePayload} />
          <Features digitalTemplatePayload={digitalTemplatePayload} enabledFeatures={enabledFeatures} />
          <ChartFeatures digitalTemplatePayload={digitalTemplatePayload} />
          <Print digitalTemplateFormPayload={digitalTemplateFormPayload} />
          <Fonts digitalTemplatePayload={digitalTemplatePayload} />
          {!isSidebarTemplate && (
            <>
              <Sidebar digitalTemplateFormPayload={digitalTemplateFormPayload} />
              <Footer digitalTemplateFormPayload={digitalTemplateFormPayload} />
              <TemplateImage
                image={previewImage}
                imageLabel="Preview Image"
                imageType="preview_image"
                ownerId={digitalTemplate.owner_id}
                ownerType={digitalTemplate.owner_type}
                subjectId={digitalTemplate.id}
                subjectType="DigitalTemplate"
              />
              <TemplateImage
                image={shareImage}
                imageLabel="Share Image"
                imageType="share_image"
                ownerId={digitalTemplate.owner_id}
                ownerType={digitalTemplate.owner_type}
                subjectId={digitalTemplate.id}
                subjectType="DigitalTemplate"
              />
            </>
          )}
          <ImportAndExport digitalTemplatePayload={digitalTemplatePayload} />
          <TemplatePageBackups digitalTemplatePayload={digitalTemplatePayload} />
          <SectionSort callbacks={{ editSectionSort }} />
        </Box>
      </SidebarModal.Content>
    </SidebarModal>
  )
}

EditDigitalTemplateModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  digitalTemplate: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

EditDigitalTemplateModal.defaultProps = {
  modalKey: 'EditDigitalTemplateModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <EditDigitalTemplateModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
