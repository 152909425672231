import PropTypes from 'prop-types'

import handleCallbackAction from '@functions/handleCallbackAction'

import CreateOrEditCustomFieldModal from '@modals/CreateOrEditCustomFieldModal'
import CreateOrEditNestedDataStoreArrayItemModal from '@modals/CreateOrEditNestedDataStoreArrayItemModal'

import useBrandSelector from '@hooks/useBrandSelector'
import useEntityCustomField from '@hooks/useEntityCustomField'
import useNestedDataStoreArrayItem from '@hooks/useNestedDataStoreArrayItem'
import useOrganizationSelector from '@hooks/useOrganizationSelector'

import Field from './components/Field'
import FieldGroup from './components/FieldGroup'

const callbacks = (component, setState) => {
  const componentCallbacks = {
    CreateOrEditCustomFieldModal: {
      closeModal: () => setState({ showCreateOrEditCustomFieldModal: false }),
      createCustomField: payload => handleCallbackAction(payload),
    },
  }

  return componentCallbacks[component] || {}
}

const EntityCustomFields = (props) => {
  const {
    customFieldsForm,
    customFieldsForm: {
      owner: { id: ownerId, type: ownerType },
      sortedCustomFieldGroups,
    },
    sidebar,
  } = props

  const { selectedBrand } = useBrandSelector()

  const { selectedOrganization } = useOrganizationSelector()

  const brandId = ownerType === 'Brand' && ownerId ? ownerId : selectedBrand.id

  const organizationId = ownerType === 'Organization' && ownerId ? ownerId : selectedOrganization.id

  const brandOrOrganizationId = brandId || organizationId

  const entityCustomFieldPayload = useEntityCustomField({
    callbacks: {
      setupLocalCallbacks: setState => callbacks('CreateOrEditCustomFieldModal', setState, brandOrOrganizationId),
    },
  })

  const nestedDataStoreArrayItemPayload = useNestedDataStoreArrayItem({ customFieldsForm })

  return (
    <>
      {sortedCustomFieldGroups.map(customFieldGroup => (
        <FieldGroup
          callbacks={callbacks}
          customFieldGroup={customFieldGroup}
          customFieldsForm={customFieldsForm}
          entityCustomFieldPayload={entityCustomFieldPayload}
          key={customFieldGroup.id}
          nestedDataStoreArrayItemPayload={nestedDataStoreArrayItemPayload}
          sidebar={sidebar}
        />
      ))}

      <CreateOrEditCustomFieldModal {...entityCustomFieldPayload} viewMode="basic" clickSafeZone />

      <CreateOrEditNestedDataStoreArrayItemModal {...nestedDataStoreArrayItemPayload} clickSafeZone />
    </>
  )
}

EntityCustomFields.propTypes = {
  customFieldsForm: PropTypes.object.isRequired,
  sidebar: PropTypes.bool,
}

EntityCustomFields.Field = Field
EntityCustomFields.FieldGroup = FieldGroup

export default EntityCustomFields
