import { ProjectModel } from '@models/project'

import PageContent from './components/PageContent'

interface QuoteDetailsProps {
  project: ProjectModel,
}

const QuoteDetails = (props: QuoteDetailsProps) => {
  const { project } = props

  return <PageContent project={project} />
}

export default QuoteDetails
