export default {
  default: {
    button: {
      addField: 'Add Field',
      delete: 'Delete Form',
      save: 'Save Form',
      setDraft: 'Set As Draft',
      setPublished: 'Set As Published',
    },
    formFieldLabels: {
      title: '* Title',
      moreOptions: 'More Options',
      ownerType: 'Owner Type',
    },
    notifications: {
      title: 'Notifications',
      text: 'Notify users of new form responses (email)',
    },
    optionValues: {
      ownerType: {
        brand: 'Brand',
        organization: 'Organization',
        system: 'System',
      },
    },
    sideBarModalHeader: {
      title: 'Manage',
      titleSecondLine: 'Form',
    },
    swal: {
      title: 'Delete Form?',
      text: 'Are you sure?',
      confirmButtonText: 'Yes, delete it.',
    },
    toast: {
      formDeleted: 'Form Template Deleted Successfully',
      formUpdated: 'Form Template Updated Successfully',
    },
  },
}
