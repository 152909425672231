import { useContext, useMemo } from 'react'
import cloneDeep from 'lodash/cloneDeep'

import { useForm, useLatestEntity, useWatchEntityUpdates } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import {
  digObject, launchModal, multiFilteredObjectArraySelector, sortArrayBy,
} from '@campaignhub/javascript-utils'

import useDispatch from '@hooks/useDispatch'
import useSelector from '@hooks/useSelector'

import * as reviewActions from '@redux/modules/review'

import defaultFormState, { requiredFields } from '@models/review'

import PageContext from '@contexts/pageContext'

import type { AppDispatch } from '@redux/store'
import type { DeleteParams } from '@redux/modules/types'
import type { ReviewModel, ReviewRequestOptions, UserModel } from '@models/types'
import { AddressModel } from '@models/address'

const watchEntityKeys = ['images']

export const generateUrls = () => ({
  hiddenReviewsIndexUrl: '#/admin/reviews?hidden=true',
  indexReviewsUrl: '#/admin/reviews?hidden=false',
})

type CreateReviewParams = {
  dispatch: AppDispatch,
  requestOptions?: ReviewRequestOptions,
  reviewParams: Partial<ReviewModel>,
}

const createReview = (params: CreateReviewParams) => {
  const { dispatch, reviewParams, requestOptions } = params
  const { createReview: createFn } = reviewActions

  const { data } = reviewParams

  const review = {
    ...reviewParams,
    data: JSON.stringify(data),
  }

  return dispatch(createFn(review, requestOptions))
}

type UpdateReviewParams = {
  dispatch: AppDispatch,
  requestOptions?: ReviewRequestOptions,
  review: ReviewModel,
  reviewParams: Partial<ReviewModel>,
}

const updateReview = (params: UpdateReviewParams) => {
  const {
    dispatch, review, reviewParams, requestOptions,
  } = params
  const { data } = reviewParams

  const { updateReview: updateFn } = reviewActions

  const updatedParams = {
    id: review.id,
    ...reviewParams,
  }

  if (data){
    const reviewData = review.data || {}
    updatedParams.data = JSON.stringify({
      ...cloneDeep(reviewData),
      ...data,
    })
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

type DeleteReviewParams = {
  dispatch: AppDispatch,
  review: DeleteParams<ReviewModel>,
}

const deleteReview = (params: DeleteReviewParams) => {
  const { dispatch, review } = params
  const { deleteReview: deleteFn } = reviewActions

  return dispatch(deleteFn(review))
}

type DeleteFromProjectParams = {
  dispatch: AppDispatch,
  review: DeleteParams<ReviewModel>,
}

const deleteFromProjects = (params: DeleteFromProjectParams) => {
  const { dispatch, review } = params
  const { deleteFromProjects: deleteFromProjectsFn } = reviewActions

  return dispatch(deleteFromProjectsFn(review))
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useReviewForm(
  review: Partial<ReviewModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const reviewForm = useForm(
    defaultFormState,
    { entity: review, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [review.id, review.cache_key],
  )

  return {
    ...reviewForm,
  }
}

export const useRelations = (review: Partial<ReviewModel> = {}) => {
  const { address_id } = review
  const userIds = digObject(review, 'user_ids', []) as number[]

  const entities = useSelector(reduxState => reduxState.entities)
  const { addresses, users } = entities

  const address = address_id ? addresses[address_id] || {} : {}
  const reviewUsers = userIds.map(id => users[id]).filter((user: UserModel) => user)

  return {
    address,
    reviewUsers,
  }
}

function useReview(initEntity: Partial<ReviewModel> = {}) {
  const { entity: review }: { entity: ReviewModel} = useLatestEntity(initEntity, 'reviews')
  const { title } = review

  const dispatch = useDispatch()

  const { callbacks } = useContext(PageContext)

  const { address, reviewUsers } = useRelations(review)

  const entities = useSelector(reduxState => reduxState.entities)
  const {
    creating, loading, updating,
  } = useSelector(reduxState => reduxState.reviews)

  const {
    updatedEntities: { images: imagesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const imageFilters = [
    { key: 'subject_type', value: 'Review' },
    { key: 'subject_id', value: review.id },
  ]

  const filteredImages = useMemo(() => {
    const filtered = multiFilteredObjectArraySelector({ entities }, 'images', imageFilters)
    const sorted = sortArrayBy(filtered, 'asc', 'sort')

    return sorted
  }, [imagesUpdatedAt, review.id])

  const defaultImage = filteredImages[0] || {}

  return {
    callbacks: {
      createOrEditAddress: (
        entityState: ReviewModel,
        setEntityState: ({ address_attributes }: { address_attributes: Partial<AddressModel>}) => void,
      ) => launchModal({
        callbacks,
        modalKey: 'CreateOrEditAddressModal',
        payload: {
          callbacks: {
            createAddress: (newAddress: Partial<AddressModel>) => setEntityState({ address_attributes: newAddress }),
            updateAddress: (newAddress: Partial<AddressModel>) => setEntityState({ address_attributes: newAddress }),
          },
          address: entityState.address_attributes && Object.keys(entityState.address_attributes).length
            ? entityState?.address_attributes
            : address,
        },
      }),
      createOrEditReview: (customPayload: {}) => launchModal({
        callbacks,
        modalKey: 'CreateOrEditReviewModal',
        payload: { review, ...customPayload },
      }),
      createReview: (reviewParams: Partial<ReviewModel>, entityOptions?: ReviewRequestOptions) => (
        createReview({ reviewParams, dispatch, requestOptions: entityOptions })
      ),
      deleteFromProjects: () => deleteFromProjects({ dispatch, review }),
      deleteReview: () => deleteReview({ dispatch, review }),
      updateReview: (reviewParams: Partial<ReviewModel>, entityOptions?: ReviewRequestOptions) => (
        updateReview({
          review, reviewParams, dispatch, requestOptions: entityOptions,
        })
      ),
    },
    address,
    creating,
    defaultImage,
    filteredImages,
    loading,
    review,
    reviewUsers,
    title,
    updating,
    urls: generateUrls(),
  }
}

export default useReview
