import React from 'react'
import PropTypes from 'prop-types'

import { Box, ListItem, Text } from '@campaignhub/suit-theme'

import useIntegration from '@hooks/useIntegration'
import useIntegrationPlatform from '@hooks/useIntegrationPlatform'
import useOrganizationSelector from '@hooks/useOrganizationSelector'
import useLocalization from '@hooks/useLocalization'

import IntegrationPlatformLogo from '@components/IntegrationPlatformLogo'

import localizedStrings from './localizedStrings'

const IntegrationPlatformTile = (props) => {
  const {
    integrationPlatform,
    integrationPlatform: {
      description, id, key, title,
    },
  } = props

  const integrationPlatformPayload = useIntegrationPlatform(integrationPlatform)
  const { integrationScope, isOauth } = integrationPlatformPayload

  const { selectedOrganization } = useOrganizationSelector()

  const initIntegration = {
    integration_platform_id: id,
    owner_id: selectedOrganization.id,
    owner_type: 'Organization',
    integration_scope: integrationScope,
  }

  const { callbacks: { authorizeIntegration, createOrEditIntegration } } = useIntegration(initIntegration)

  const { strings } = useLocalization(localizedStrings)

  return (
    <Box
      as="a"
      flexDirection="column"
      flexShrink={0}
      marginBottom="large"
      onClick={isOauth ? authorizeIntegration : createOrEditIntegration}
      style={{ cursor: 'pointer' }}
      variant="white"
    >
      <IntegrationPlatformLogo
        backgroundSize="contain"
        borderRadius="5px 5px 0 0"
        height={120}
        integrationPlatformKey={key}
        size="large"
        width="100%"
      />

      <ListItem
        boxProps={{
          alignItems: 'center',
          borderTop: '1px solid',
          padding: 'large',
        }}
        showAngleIcon
      >
        <Box flexDirection="column" justifyContent="center">
          <Text fontSize="small">{title}</Text>
          <Box alignItems="center" fontSize="small" marginTop="medium">
            <Text color="bodyFontLightColor">{description || strings.text || 'Integration Platform'}</Text>
          </Box>
        </Box>
      </ListItem>
    </Box>
  )
}

IntegrationPlatformTile.propTypes = {
  integrationPlatform: PropTypes.object.isRequired,
}

export default IntegrationPlatformTile
