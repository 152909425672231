export default {
  default: {
    title: 'Leaderboard',
    buttons: {
      showAll: 'Show All',
    },
    labelTitle: {
      label: 'Title',
    },
    status: {
      label: 'Status',
    },
    statusValues: {
      accepted: 'Accepted',
      all: 'All',
      pending: 'Pending',
      sent: 'Sent',
    },
    month: {
      label: 'Month',
    },
    monthValues: {
      currentMonth: 'Current Month',
      previousMonth: 'Previous Month',
    },
    leaderboard: {
      label: 'Leaderboard',
      pageTitle: 'Page Title',
    },
    leaderboardValues: {
      userLeaderboard: 'User Leaderboard',
      templateLeaderboard: 'Digital Template Leaderboard',
    },
    blankState: {
      title: 'No Leaderboard Available',
      paragraphOne: 'Try adjusting your filters.',
    },
  },
}
