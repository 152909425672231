import {
  faArrowsH, faBath, faBed, faCar, faExpandArrows, faMapMarkerAlt, faUsdCircle,
} from '@fortawesome/pro-light-svg-icons'

const filters = {
  area: {
    id: 'area',
    icon: faExpandArrows,
    placeholderMax: 'max',
    placeholderMin: 'min',
    title: 'Area (m²)',
    value: '',
    valueMax: '',
    width: 50,
  },
  bathrooms: {
    id: 'bathrooms',
    icon: faBath,
    placeholderMax: 'max',
    placeholderMin: 'min',
    title: 'Bathrooms',
    value: '',
    valueMax: '',
    width: 50,
  },
  bedrooms: {
    id: 'bedrooms',
    icon: faBed,
    placeholderMax: 'max',
    placeholderMin: 'min',
    title: 'Bedrooms',
    value: '',
    valueMax: '',
    width: 50,
  },
  carparks: {
    id: 'carparks',
    icon: faCar,
    placeholderMax: 'max',
    placeholderMin: 'min',
    title: 'Carparks',
    value: '',
    valueMax: '',
    width: 50,
  },
  latitude: {
    id: 'latitude',
    hideValueMax: true,
    icon: faMapMarkerAlt,
    title: 'Latitude',
    value: '',
    width: 100,
  },
  longitude: {
    id: 'longitude',
    hideValueMax: true,
    icon: faMapMarkerAlt,
    title: 'Longitude',
    value: '',
    width: 100,
  },
  start_date: {
    id: 'start_date',
    dateField: true,
    hideValueMax: true,
    title: 'Start Date',
    value: '',
    width: 50,
  },
  end_date: {
    id: 'end_date',
    dateField: true,
    hideValueMax: true,
    title: 'End Date',
    value: '',
    width: 50,
  },
  price: {
    id: 'price',
    icon: faUsdCircle,
    placeholderMax: 'max',
    placeholderMin: 'min',
    title: 'Price',
    value: '',
    valueMax: '',
    width: 100,
  },
  property_type: {
    id: 'property_type',
    selectValues: [
      { title: 'Please Select...', value: '' },
      { title: 'House', value: 'house' },
      { title: 'Unit', value: 'unit' },
    ],
    title: 'Type',
    value: '',
    width: 100,
  },
  radius: {
    id: 'radius',
    hideValueMax: true,
    icon: faArrowsH,
    title: 'Max distance from target property (km)',
    value: '5',
    width: 100,
  },
  sort: {
    id: 'sort',
    selectValues: [
      { title: 'Date (Ascending)', value: 'date' },
      { title: 'Date (Descending)', value: '-date' },
      { title: 'Distance (Ascending)', value: 'distance' },
      { title: 'Distance (Descending)', value: '-distance' },
      { title: 'Price (Ascending)', value: 'price' },
      { title: 'Price (Descending)', value: '-price' },
    ],
    title: 'Sort By',
    value: '-date',
    width: 100,
  },
}

export default filters
