import { useEffect } from 'react'
import { useSetState } from '@campaignhub/react-hooks'
import { digObject, toggleArray } from '@campaignhub/javascript-utils'
import { useSelector } from 'react-redux'

import useQuotes from '@hooks/useQuotes'

const toggleEntityQuote = (quoteId, state, setState) => {
  const { selectedQuoteIds } = state
  const updatedQuoteIds = toggleArray(selectedQuoteIds, quoteId)

  setState({ selectedQuoteIds: updatedQuoteIds })
}

const defaultState = {
  selectedQuoteIds: [],
}

function useManageEntityQuotes(entity, options = {}){
  const { performHttpRequests, subjectId, subjectType } = options || {}

  const [state, setState] = useSetState(defaultState)
  const { selectedQuoteIds } = state

  const entityQuoteIds = digObject(entity, 'quote_ids', [])

  const entities = useSelector(reduxState => reduxState.entities)
  const { quotes } = entities

  const quoteIds = Object.values(quotes).map(quote => quote.id)

  const filteredEntityQuoteIds = entityQuoteIds.filter(id => quoteIds.includes(id))

  const { filteredQuotes, hasQuotes, loading } = useQuotes({
    filters: {
      subjectId,
      subjectType,
    },
    performHttpRequests,
  })

  useEffect(() => {
    if (entity.id){
      setState({ selectedQuoteIds: filteredEntityQuoteIds })
    }
  }, [entity.id, filteredEntityQuoteIds.length])

  return {
    callbacks: {
      toggleEntityQuote: quoteId => toggleEntityQuote(quoteId, state, setState),
    },
    filteredQuotes,
    hasQuotes,
    loading,
    selectedQuoteIds,
  }
}

export default useManageEntityQuotes
