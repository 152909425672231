import React, { useContext } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaste } from '@fortawesome/pro-light-svg-icons'

import { Box, FormField } from '@campaignhub/suit-theme'

import { useSetState } from '@campaignhub/react-hooks'

import { digObject, toggleArray } from '@campaignhub/javascript-utils'

import PageContext from '@contexts/pageContext'

import EntitySelector from '@components/EntitySelector'

import useDigitalTemplate from '@hooks/useDigitalTemplate'

import { defaultRequestOptions } from '@hooks/useDigitalTemplateEditor'

import PageItem from '../PageItem'

const mappedEntityTypes = {
  digitalTemplatePageSectionGroup: 'Section Group',
  digitalTemplatePageSection: 'Section',
  digitalTemplatePageItem: 'Page Item',
}

const toggleDigitalTemplateComponent = (params) => {
  const { digitalTemplateComponent, digitalTemplate, callbacks } = params
  const { updateDigitalTemplate } = callbacks || {}

  const digitalTemplateComponentIds = digObject(digitalTemplate, 'digital_template_component_ids', [])
  const updatedComponentIds = toggleArray(digitalTemplateComponentIds, digitalTemplateComponent.id)
  const updatedTemplate = { id: digitalTemplate.id, digital_template_component_ids: updatedComponentIds }

  updateDigitalTemplate(updatedTemplate, defaultRequestOptions.digitalTemplate)
}

const defaultState = {
  brand_id: null,
}

const ComponentLibrary = () => {
  const [state, setState] = useSetState(defaultState)
  const { brand_id } = state

  const { callbacks, digitalTemplate } = useContext(PageContext)

  const { componentPartials } = useDigitalTemplate(digitalTemplate)

  return (
    <Box flexDirection="column">
      <Box flexDirection="column" style={{ zIndex: 99999 }}>
        <EntitySelector
          boxProps={{ marginBottom: 'large' }}
          callbacks={{
            selectItem: digitalTemplateComponent => toggleDigitalTemplateComponent({
              callbacks,
              digitalTemplateComponent,
              digitalTemplate,
            }),
          }}
          entityKey="digitalTemplateComponents"
          endpoint="/digital_template_components"
          showSelectedEntity={false}
          searchRequestOptions={{
            brand_id,
            item_type: 'component_partial',
          }}
        />

        <EntitySelector
          boxProps={{ marginBottom: 'large' }}
          callbacks={{
            selectItem: brand => setState({ brand_id: brand ? brand.id : null }),
          }}
          entityKey="brands"
          selectedItemId={Number(brand_id)}
        />
      </Box>

      {!!componentPartials.length && (
        <FormField label="Current Components">
          <Box flexDirection="column" marginBottom="medium">
            {componentPartials.map((digitalTemplateComponent) => {
              const { data: { component } } = digitalTemplateComponent

              return (
                <PageItem
                  dataAttributes={{
                    'data-digital-template-component-id': digitalTemplateComponent.id,
                    'data-entity-type': component.entityType,
                  }}
                  fullWidth
                  icon={<FontAwesomeIcon icon={faPaste} />}
                  itemType={component.item_type}
                  key={digitalTemplateComponent.id}
                  subTitle={mappedEntityTypes[component.entityType]}
                  title={digitalTemplateComponent.title}
                />
              )
            })}
          </Box>
        </FormField>
      )}
    </Box>
  )
}

export default ComponentLibrary
