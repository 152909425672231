export default {
  default: {
    buttons: {
      addField: 'Add Field to Group',
      create: 'Create',
      goBack: 'Go Back',
    },
    goBack: 'Back to Custom Field Sets',
    pageHeader: {
      placeholder: 'Custom Field Sets',
      title: 'Custom Fields',
    },
    edit: 'Edit Custom Field',
  },
}
