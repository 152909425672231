export default {
  default: {
    title: {
      title: 'Files',
    },
    labelTitle: {
      label: 'Title',
    },
    displayMode: {
      label: 'Display Mode',
    },
    displayModeValues: {
      labelListCompact: 'List (Compact)',
      labelListLarge: 'List (Large)',
    },
    limit: {
      label: 'Limit',
    },
    blankState: {
      title: 'No Files Uploaded',
      paragraph: 'You don’t have any files stored against this project. You can upload files and use them in digital pages to send more information to clients easily.',
    },
    manage: 'Manage',

  },
}
