import swal from 'sweetalert2'
import { toast } from 'react-toastify'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faSort, faUndoAlt } from '@fortawesome/pro-light-svg-icons'

import { Button, IconWithMenu, Link } from '@campaignhub/suit-theme'

import useDigitalPage from '@hooks/useDigitalPage'
import useLocalization from '@hooks/useLocalization'

import { DigitalPageModel } from '@models/digitalPage'

import localizedStrings from './localizedStrings'

type ConfirmResetSectionsType = {
  resetSortedSections: (resetFn: Function) => void,
  resetFn: () => void,
  strings: {
    swal:{ [key: string]: string },
    toast:{ [key: string]: string },
  },
  title: string,
}

type ConfirmDisabledSectionsType = {
  resetDisabledSections: (resetDisableFn: Function) => void,
  resetDisableFn: () => void,
  strings: {
    swal:{ [key: string]: string },
    toast:{ [key: string]: string },
  },
  title: string,
}

type ConfirmResetDefaultTextType = {
  resetDefaultText: (resetTextFn: Function) => void,
  resetTextFn: () => void,
  strings: {
    swal:{ [key: string]: string },
    toast:{ [key: string]: string },
  },
  title: string,
}

type ConfirmResetDefaultImageType = {
  resetDefaultImage: (resetImageFn: Function) => void,
  resetImageFn: () => void,
  strings: {
    swal:{ [key: string]: string },
    toast:{ [key: string]: string },
  },
  title: string,
}

const confirmResetSections = (params: ConfirmResetSectionsType) => {
  const { resetSortedSections, resetFn, strings } = params
  swal
    .fire({
      title: strings.swal?.confirmSectionsTitle || 'Reset Sections?',
      text: strings.swal?.text || 'Are you sure? This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: strings.swal?.confirmButton || 'Yes, reset it',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        resetSortedSections(resetFn)
        toast(strings.toast?.digitalPageUpdated || 'Digital Page Updated Successfully')
      }
    })
}

const confirmDisabledSections = (params: ConfirmDisabledSectionsType) => {
  const { resetDisabledSections, resetDisableFn, strings } = params
  swal
    .fire({
      title: strings.swal?.confirmDisableTitle || 'Reset Disabled Sections?',
      text: strings.swal?.text || 'Are you sure? This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: strings.swal?.confirmButton || 'Yes, reset them',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        resetDisabledSections(resetDisableFn)
        toast(strings.toast?.digitalPageUpdated || 'Digital Page Updated Successfully')
      }
    })
}

const confirmResetDefaultText = (params: ConfirmResetDefaultTextType) => {
  const { resetDefaultText, resetTextFn, strings } = params
  swal
    .fire({
      title: strings.swal?.confirmTextTitle || 'Reset Texts?',
      text: strings.swal?.text || 'Are you sure? This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: strings.swal?.confirmButton || 'Yes, reset them',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        resetDefaultText(resetTextFn)
        toast(strings.toast?.digitalPageUpdated || 'Digital Page Updated Successfully')
      }
    })
}

const confirmResetDefaultImage = (params: ConfirmResetDefaultImageType) => {
  const { resetDefaultImage, resetImageFn, strings } = params
  swal
    .fire({
      title: strings.swal?.confirmImageTitle || 'Reset Images?',
      text: strings.swal?.text || 'Are you sure? This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: strings.swal?.confirmButton || 'Yes, reset them',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        resetDefaultImage(resetImageFn)
        toast(strings.toast?.digitalPageUpdated || 'Digital Page Updated Successfully')
      }
    })
}

interface DigitalPageMenuItemsProps {
  digitalRendererPayload: {
    callbacks: {
      showManageDigitalPageFeaturesModal: () => void,
    },
    digitalPage: DigitalPageModel,
    isMobileDevice: boolean,
  },
}

const DigitalPageMenuItems = (props: DigitalPageMenuItemsProps) => {
  const {
    digitalRendererPayload: {
      callbacks: {
        resetDefaultImage, resetDefaultText, resetDisabledSections, resetSortedSections, showManageDigitalPageFeaturesModal,
      },
      digitalPage,
      isMobileDevice,
    },
  } = props

  const digitalPagePayload = useDigitalPage(digitalPage)
  const {
    callbacks: {
      editSectionSort,
      resetDefaultImage: resetImageFn,
      resetDefaultText: resetTextFn,
      resetDisabledSections: resetDisableFn,
      resetSortedSections: resetFn,
    },
  } = digitalPagePayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <IconWithMenu
      icon={(
        <Button
          buttonStyle="secondaryUtility"
          icon={<FontAwesomeIcon icon={faCog} />}
          size="medium"
          style={{ height: 37 }}
          marginRight="medium"
        />
        )}
      style={{ marginLeft: 'auto', marginRight: 'medium', width: 'auto' }}
    >
      <IconWithMenu.Menu closeOnClick listStyle={{ right: '-24px' }}>
        {isMobileDevice && (
        <Link
          key="settings"
          onClick={() => showManageDigitalPageFeaturesModal()}
        >
          <FontAwesomeIcon icon={faCog} /> {strings.pageSettings || 'Page Settings'}
        </Link>
        )}

        <Link
          key="sort-sections"
          onClick={() => editSectionSort()}
        >
          <FontAwesomeIcon icon={faSort} /> {strings.sortSections || 'Sort Sections'}
        </Link>

        <Link
          key="reset-sorted-sections"
          onClick={() => confirmResetSections({ resetSortedSections, resetFn, strings })}
        >
          <FontAwesomeIcon icon={faUndoAlt} /> {strings.resetSortedSections || 'Reset Sorted Sections'}
        </Link>

        <Link
          key="reset-disabled-sections"
          onClick={() => confirmDisabledSections({ resetDisabledSections, resetDisableFn, strings })}
        >
          <FontAwesomeIcon icon={faUndoAlt} /> {strings.disabledSections || 'Reset Disabled Sections'}
        </Link>

        <Link
          key="reset-default-text"
          onClick={() => confirmResetDefaultText({ resetDefaultText, resetTextFn, strings })}

        >
          <FontAwesomeIcon icon={faUndoAlt} /> {strings.resetDefaultText || 'Reset to Default Text'}
        </Link>

        <Link
          key="reset-default-image"
          onClick={() => confirmResetDefaultImage({ resetDefaultImage, resetImageFn, strings })}

        >
          <FontAwesomeIcon icon={faUndoAlt} /> {strings.resetDefaultImage || 'Reset to Default Image'}
        </Link>

      </IconWithMenu.Menu>
    </IconWithMenu>
  )
}

export default DigitalPageMenuItems
