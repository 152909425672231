import PropTypes from 'prop-types'

import {
  Box, Button, FormField, SidebarModal,
} from '@campaignhub/suit-theme'

import EntitySelectBox from '@components/EntitySelectBox'

import useOrganization, { useOrganizationForm } from '@hooks/useOrganization'
import useReduxAction from '@hooks/useReduxAction'

const CreateOrganizationModal = (props) => {
  const {
    callbacks, callbacks: { closeModal, createOrganization }, modalKey, showModal,
  } = props

  useReduxAction('organizationTypes', 'loadOrganizationTypes')

  const {
    callbacks: { createOrganization: createFn },
    creating,
  } = useOrganization()

  const {
    entityState, errors, handlers, saveEnabled,
  } = useOrganizationForm()

  const {
    brand_id, email, organization_type_id, title,
  } = entityState

  const organizationPayload = {
    callbacks: {
      action: createFn,
      afterAction: closeModal,
    },
    entityParams: entityState,
    shouldRedirect: true,
    toastText: 'Organization Created Successfully',
  }

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header callbacks={callbacks} title="Create" titleSecondLine="Organization" />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <FormField
            direction="column"
            boxProps={{ marginBottom: 'large' }}
            errorMessage={errors.organization_type_id}
            label="Organization Type"
          >
            <EntitySelectBox
              entityKey="organizationTypes"
              name="organization_type_id"
              sortDirection="asc"
              sortKey="title"
              value={organization_type_id}
              {...handlers}
            />
          </FormField>

          <FormField
            direction="column"
            boxProps={{ marginBottom: 'large' }}
            errorMessage={errors.brand_id}
            label="Brand"
          >
            <EntitySelectBox
              entityKey="brands"
              name="brand_id"
              sortDirection="asc"
              sortKey="title"
              value={brand_id}
              {...handlers}
            />
          </FormField>

          <FormField direction="column" boxProps={{ marginBottom: 'large' }} errorMessage={errors.title} label="Title">
            <input name="title" value={title} type="text" {...handlers} data-validate-field-on="change" />
          </FormField>

          <FormField direction="column" boxProps={{ marginBottom: 'large' }} errorMessage={errors.email} label="Email">
            <input name="email" value={email} type="text" {...handlers} data-validate-field-on="change" />
          </FormField>
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={creating}
          onClick={() => createOrganization(organizationPayload)}
          size="large"
        >
          Create Organization
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

CreateOrganizationModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

CreateOrganizationModal.defaultProps = {
  modalKey: 'CreateOrganizationModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <CreateOrganizationModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
