export default {
  default: {
    buttons: {
      add: 'Add Field',
      delete: 'Delete Template',
      save: 'Save Template',
      setDraft: 'Set As Draft',
      setPublished: 'Set As Published',
    },
    formFieldLabels: {
      moreOptions: 'More Options',
      roles: '* Recipient/Signer Roles',
      title: '* Title',
    },
    sideBarModalHeader: {
      title: 'Manage',
      titleSecondLine: 'Document Template',
    },
    swal: {
      confirmButtonText: 'Yes, delete it',
      icon: 'warning',
      text: 'Are you sure?',
      title: 'Delete Document Template?',
    },
  },
}
