import { Box, ListItem, Text } from '@campaignhub/suit-theme'

import useQuote from '@hooks/useQuote'

import type { QuoteModel } from '@models/types'

interface CompactListProps {
  quote: QuoteModel,
}

const CompactList = (props: CompactListProps) => {
  const {
    quote,
    quote: { title },
  } = props

  const {
    urls: { editQuoteUrl },
  } = useQuote(quote)

  return (
    <ListItem
      boxProps={{
        as: 'a',
        borderBottom: '1px solid',
        paddingX: 'large',
        paddingY: 'medium',
      }}
      href={editQuoteUrl}
      showAngleIcon
    >
      <Box justifyContent="center" flexDirection="column">
        <Text fontSize="small">{title}</Text>
      </Box>
    </ListItem>
  )
}

export default CompactList
