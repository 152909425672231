import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/pro-solid-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'

import PageContext from '@contexts/pageContext'

import getClonedFieldId from '@functions/getClonedFieldId'
import getStringFeatureKeys from '@functions/getStringFeatureKeys'
import isValueBlank from '@functions/isValueBlank'
import replaceTextShortCodes from '@functions/replaceTextShortCodes'
import { shouldRenderPageItem } from '@functions/digitalTemplatePageItem'

import useDataFormatter from '@hooks/useDataFormatter'
import useDeviceStyle from '@hooks/useDeviceStyle'

import replaceCustomData from '@components/digitalRenderer/utils/replaceCustomData'
import AnimationWrapper from '@components/digitalRenderer/components/AnimationWrapper'

import styles from './styles.module.scss'

const checkIfEditable = (customizingTemplate, editing, editable, featureKey) => {
  if (customizingTemplate){
    return true
  }

  // Preview & Edit, only set text is editable
  return editing && (editable || !!featureKey)
}

const editAction = (e, params) => {
  const {
    callbacks: { editDigitalPageItemCustomData, manageDigitalPageFeature, selectCanvasItem },
    customizingTemplate,
    entityType,
    featureKey,
    id,
    itemType,
  } = params

  e.preventDefault()
  e.stopPropagation()

  if (customizingTemplate){
    selectCanvasItem({ type: entityType, id }, e)
    return
  }

  if (featureKey){
    manageDigitalPageFeature(featureKey)
    return
  }

  editDigitalPageItemCustomData({ entityType, id, itemType })
}

const getTextValue = (customizingTemplate, id, pageContext, rawValue, contextData) => {
  if (customizingTemplate){
    return rawValue
  }

  return replaceCustomData(id, pageContext) || replaceTextShortCodes(rawValue, contextData)
}

const TextBlock = (props) => {
  const {
    context: componentContext,
    data: { dataFormatter, dataFormat, value: rawValue },
    entityType,
    id,
    itemEntities,
    item_type,
    options,
    options: { editable, renderConditions },
  } = props

  // Context
  const pageContext = useContext(PageContext)
  const {
    callbacks, customizingTemplate, editing, shortcodeData,
  } = pageContext || {}

  const { editDigitalPageItemCustomData, manageDigitalPageFeature, selectCanvasItem } = callbacks || {}

  const contextData = { ...shortcodeData, ...componentContext }

  // Check if we cloning another field's value
  const clonedFieldId = getClonedFieldId(rawValue)
  const clonedFieldRawValue = digObject(itemEntities, `${clonedFieldId}.data.value`)

  // Text Value
  const textItemId = clonedFieldId || id
  const textItemRawValue = clonedFieldRawValue || rawValue

  const value = getTextValue(customizingTemplate, textItemId, pageContext, textItemRawValue, contextData)

  // Format Text
  const { formattedValue } = useDataFormatter(value, { dataFormatter, dataFormat })

  // Style
  const style = useDeviceStyle(props)

  // Should Render
  const shouldRender = shouldRenderPageItem(renderConditions, contextData, options)
  const shouldHideFormattedValue = isValueBlank(formattedValue) && !editing

  if (!shouldRender || shouldHideFormattedValue) return null

  // Get Shortcodes
  const featureKeys = getStringFeatureKeys(rawValue)
  const featureKey = featureKeys[0]

  // Functions
  const isEditable = checkIfEditable(customizingTemplate, editing, editable, featureKey)

  return (
    <AnimationWrapper options={options}>
      <div
        className={classnames(styles.root, isEditable ? styles.editable : null)}
        onClick={
          isEditable
            ? e => editAction(e, {
              callbacks: {
                editDigitalPageItemCustomData,
                manageDigitalPageFeature,
                selectCanvasItem,
              },
              customizingTemplate,
              entityType,
              featureKey,
              id,
              itemType: item_type,
            })
            : null
        }
        style={{ ...style, minHeight: isValueBlank(formattedValue) && editing ? '20px' : style.minHeight }}
      >
        {formattedValue}
        {isEditable && (
          <div className={styles.editActions}>
            <div className={styles.icon} style={{ marginBottom: 2 }}>
              <FontAwesomeIcon icon={faPencil} />
            </div>
          </div>
        )}
      </div>
    </AnimationWrapper>
  )
}

TextBlock.propTypes = {
  callbacks: PropTypes.shape({
    updateCustomData: PropTypes.func,
  }),
  context: PropTypes.object,
  data: PropTypes.object,
  entityType: PropTypes.string,
  id: PropTypes.string.isRequired,
  itemEntities: PropTypes.object.isRequired,
  item_type: PropTypes.string,
  options: PropTypes.object,
}

TextBlock.defaultProps = {
  data: {},
  options: {},
}

export default TextBlock
