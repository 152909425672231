export type BrandModel = {
  cache_key: number,
  id: number,
  options: {},
  organization_type_id: number,
  realbase_id: number,
  realbase_type: string,
  title: string,
}

export type BrandRequestOptions = {
  include_brand_organizations?: boolean,
  include_brand_data_store_items?: boolean,
}

const state = {
  id: null,
  organization_type_id: '',
  realbase_id: null,
  realbase_type: null,
  title: '',
}

export const requiredFields = [
  { key: 'title' },
  { key: 'organization_type_id' },
]

export default state
