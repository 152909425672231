import React, { useContext } from 'react'

import { Box } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import DocumentTemplatePage from '../DocumentTemplatePage'
import PDFPreview from '../PDFPreview'

const Canvas = () => {
  const pageContext = useContext(PageContext)
  const {
    agreementPayload: { agreement, filteredRecipients, isSyncedWithElectronicSigningPlatform },
    callbacks,
    documentTemplatePayload: { filteredPages },
    zoom,
  } = pageContext

  return (
    <Box
      bottom="0"
      flexDirection="column"
      flexShrink="0"
      left="0"
      overflow="auto"
      paddingBottom="xxlarge"
      paddingLeft="xxlarge"
      paddingRight="xxlarge"
      paddingTop="xlarge"
      position="absolute"
      right={[0, 375, 375]}
      top="0"
    >
      {!isSyncedWithElectronicSigningPlatform
        && filteredPages.map(documentTemplatePage => (
          <DocumentTemplatePage
            agreement={agreement}
            callbacks={callbacks}
            documentTemplatePage={documentTemplatePage}
            filteredRecipients={filteredRecipients}
            key={documentTemplatePage.id}
            zoom={zoom}
          />
        ))}

      {isSyncedWithElectronicSigningPlatform && (
        <PDFPreview
          agreement={agreement}
          zoom={zoom}
          documentTemplatePage={filteredPages[0] || {}}
        />
      )}
    </Box>
  )
}

export default Canvas
