import { useEffect, useState } from 'react'

import Uppy from '@uppy/core'
import XHRUpload from '@uppy/xhr-upload'

import { toast } from 'react-toastify'

import { baseURL } from '@functions/api'
import { getAccessToken } from '@functions/accessToken'

import '@uppy/core/dist/style.css'
import '@uppy/drag-drop/dist/style.css'
import '@uppy/status-bar/dist/style.css'

interface UseUppyCsvUploadOptions {
  afterCreateCallback?: (response: any) => void,
  attachmentParams: { title: string, subject_id: number },
  maxNumberOfFiles?: number,
  uppyOptions?: object,
}

const initializeUppy = (options: UseUppyCsvUploadOptions) => {
  const {
    afterCreateCallback,
    attachmentParams,
    maxNumberOfFiles = 1,
    uppyOptions = {},
  } = options || {}

  const uppy: Uppy = new Uppy({
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles,
      allowedFileTypes: ['.csv'],
    },
    ...uppyOptions,
  })
    .use(XHRUpload, {
      endpoint: `${baseURL}/attachments/forward_csv_file`,
      method: 'POST',
      headers: { Authorization: `Bearer ${getAccessToken()}` },
      fieldName: 'file',
      formData: true,
      responseType: 'text',
      getResponseError: responseText => new Error(JSON.parse(responseText).message),
    })
    .on('file-added', file => uppy.setFileMeta(file.id, { data: JSON.stringify(attachmentParams) }))
    .on('upload-success', (file, response) => {
      uppy.setFileState(file!.id, {
        ...uppy.getFile(file!.id),
        progress: { ...file!.progress, uploadComplete: true, uploadFailed: false },
      })
      if (afterCreateCallback){
        afterCreateCallback(response)
      }
    })
    .on('upload-error', (file, error, response) => {
      uppy.setFileState(file!.id, {
        ...uppy.getFile(file!.id),
        progress: { ...file!.progress, uploadComplete: false, uploadFailed: true },
      })
      toast.error(`Failed to upload ${file!.name}: ${response!.body.error}`)
    })

  return uppy
}

const useUppyCsvUpload = (options: UseUppyCsvUploadOptions, deps: any[]) => {
  const [uppy, setUppy] = useState<Uppy | null>(null)

  useEffect(() => {
    if (options.attachmentParams.subject_id !== null && !uppy){
      const initializedUppy = initializeUppy(options)
      setUppy(initializedUppy)
    }

    return () => {
      if (uppy){
        uppy.close()
        setUppy(null)
      }
    }
  }, [options, ...deps])

  return uppy
}

export default useUppyCsvUpload
