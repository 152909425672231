import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, DashboardModule, Link,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'
import useReviews from '@hooks/useReviews'
import { generateUrls } from '@hooks/useReview'

import defaultRequestOptions from '@sections/Client/packs/Admin/defaultRequestOptions'

import Review from './Review'
import localizedStrings from './localizedStrings'

const Reviews = (props) => {
  const { selectedUserId } = props

  const reviewsPayload = useReviews({
    filters: {
      user_id: selectedUserId,
    },
    requestOptions: {
      include_image_sizes: ['thumb_660x360', 'thumb_120x80'],
      include_review_default_image: true,
      ...defaultRequestOptions.review,
    },
    performHttpRequests: !!selectedUserId,
  })
  const { filteredReviews, hasReviews, loading } = reviewsPayload

  const { indexReviewsUrl } = generateUrls()

  const { strings } = useLocalization(localizedStrings)

  return (
    <DashboardModule
      boxProps={{ marginBottom: 'large' }}
      headerRight={hasReviews && (
        <Link href={indexReviewsUrl} textProps={{ fontSize: 'small' }}>
          {strings.manage || 'Manage'}
        </Link>
      )}
      loading={loading}
      title={strings.title || 'Reviews'}
    >
      <Box flexDirection="column">
        <Box flexDirection="column" maxHeight={415} overflow="auto">
          {filteredReviews.map(review => (
            <Review key={review.id} review={review} />
          ))}
        </Box>

        {!hasReviews && (
          <Button
            as="a"
            buttonStyle="secondaryUtility"
            href={indexReviewsUrl}
            icon={<FontAwesomeIcon icon={faPlus} />}
            size="medium"
            style={{ margin: 16, width: 'calc(100% - 32px)' }}
          >
            {strings.manageButton || 'Manage Reviews'}
          </Button>
        )}
      </Box>
    </DashboardModule>
  )
}

Reviews.propTypes = {
  selectedUserId: PropTypes.number,
}

export default Reviews
