import PropTypes from 'prop-types'

import { faList } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Columns, DashboardModule, LoadingModule, PageHeader,
} from '@campaignhub/suit-theme'

import useComparable from '@hooks/useComparable'
import useComparables from '@hooks/useComparables'
import useEntityTypeCustomFields from '@hooks/useEntityTypeCustomFields'
import useLocalization from '@hooks/useLocalization'
import useProject from '@hooks/useProject'
import useVideoModules from '@hooks/useVideoModules'

import VideoModule from '@components/VideoModule'

import Comparable from '../Comparable'
import ComparableBlankState from '../ComparablesBlankState'
import localizedStrings from './localizedStrings'

const PageContent = (props) => {
  const { projectId } = props

  const { projectType, organization, urls } = useProject({ id: projectId })
  const { comparablesUrl } = urls

  const useComparablesPayload = useComparables({
    filters: {
      projectId,
    },
    performHttpRequests: true,
  })
  const {
    hasComparables,
    hasMarketComparables,
    hasOrganizationComparables,
    loading,
    marketComparables,
    organizationComparables,
  } = useComparablesPayload

  const initComparable = { project_id: projectId }
  const {
    callbacks: { showFindComparablesModal },
  } = useComparable(initComparable)

  const { filteredCustomFields } = useEntityTypeCustomFields('ProjectComparable', organization, {
    performHttpRequests: true,
  })

  const videoModulePayload = useVideoModules({ key: 'projectComparables' })
  const { videosPayload } = videoModulePayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      <PageHeader
        actionContent={(
          <Button buttonStyle="primaryCreate" onClick={() => showFindComparablesModal()} size="medium">
            {`+ ${strings.buttons?.addComparable || 'Add Comparable'}`}
          </Button>
        )}
        activeTabBarItemKey="comparables"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        nestedNavigation
        tabBarItems={[
          {
            href: comparablesUrl,
            icon: faList,
            key: 'comparables',
            title: strings.title || 'Comparables',
          },
        ]}
        title={strings.title || 'Comparables'}
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && (
              <>
                {hasMarketComparables && (
                  <DashboardModule title={strings.marketComparablesTitle || 'Market Comparables'}>
                    <Box flexDirection="column">
                      {marketComparables.map(comparable => (
                        <Comparable key={comparable.id} comparable={comparable} customFields={filteredCustomFields} />
                      ))}
                    </Box>
                  </DashboardModule>
                )}

                {hasOrganizationComparables && (
                  <DashboardModule title={strings.organizationComparablesTitle || 'Agency Comparables'}>
                    <Box flexDirection="column">
                      {organizationComparables.map(comparable => (
                        <Comparable key={comparable.id} comparable={comparable} customFields={filteredCustomFields} />
                      ))}
                    </Box>
                  </DashboardModule>
                )}

                {!hasComparables && <ComparableBlankState projectType={projectType} />}
              </>
            )}
          </Columns.Main>

          <Columns.Sidebar>
            <VideoModule videosPayload={videosPayload} />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

PageContent.propTypes = {
  projectId: PropTypes.number,
}

export default PageContent
