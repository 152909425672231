import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers } from '@fortawesome/pro-light-svg-icons'

import {
  Box,
  Button,
  FormField,
  LoadingModule,
  ModalContext,
  SidebarModal,
  SidebarNoticeBox,
  SortableList,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import FilterUsersContactsAndTeams from '@components/FilterUsersContactsAndTeams'

import useLocalization from '@hooks/useLocalization'
import useOrganizationsUser from '@hooks/useOrganizationsUser'
import useProject, { useProjectForm } from '@hooks/useProject'
import useTeam from '@hooks/useTeam'

import DraggableTeam from './components/DraggableTeam'

import localizedStrings from './localizedStrings'

const requestOptions = {
  include_team_lead_users: true,
  include_team_member_user: true,
  include_team_support_users: true,
  include_team_team_members: true,
}

const ManageProjectTeamsModal = (props) => {
  const {
    callbacks, disableAnimation, disableOverlay, modalKey, project: initProject, showModal,
  } = props
  const { updateProject } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const { callbacks: payloadCallbacks } = digObject(modalData, modalKey, {})
  const { loadShortcodeData: loadDataFn } = payloadCallbacks || {}

  const {
    callbacks: {
      addProjectUserOrTeam, deleteProjectTeam, modifyTeamsSort, updateProject: updateFn,
    },
    loading: loadingProject,
    project,
    updating,
  } = useProject(initProject)

  const initTeam = { owner_id: project.organization_id, owner_type: 'Organization' }

  const {
    callbacks: { createOrEditTeam },
  } = useTeam(initTeam)

  const projectFormPayload = useProjectForm(project)
  const {
    entityState,
    entityState: { lead_user_ids, team_ids },
    setEntityState,
  } = projectFormPayload

  const { loading: loadingTeams } = useSelector(reduxState => reduxState.teams)
  const loading = loadingProject || loadingTeams

  const { isCurrentUserAdminForOrganization } = useOrganizationsUser()

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal
      callbacks={callbacks}
      disableAnimation={disableAnimation}
      disableOverlay={disableOverlay}
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.sideBarModalHeader?.title || 'Manage'}
        titleSecondLine={strings.sideBarModalHeader?.titleSecondLine || 'Team'}
      />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <FormField direction="column" label={strings.formFieldLabel?.searchTeams || 'Search Teams'}>
            <FilterUsersContactsAndTeams
              callbacks={{
                selectItem: selected => addProjectUserOrTeam(selected, 'team_ids', entityState, setEntityState),
              }}
              requestOptions={requestOptions}
              selectedTeamIds={team_ids}
              shouldSearchTeams
              shouldSearchUsers={false}
            />
          </FormField>

          <LoadingModule boxProps={{ marginTop: 'large' }} loading={loading} times={3} />

          {!loading && !team_ids.length && (
            <SidebarNoticeBox boxProps={{ marginTop: 'large' }}>
              <SidebarNoticeBox.Title>{strings.noticeBox?.title || 'Search Teams'}</SidebarNoticeBox.Title>
              <SidebarNoticeBox.Paragraph>
                {strings.noticeBox?.paragraph
                  || 'Teams assigned to your account can be searched above. If you have not created any yet click the button below'}
              </SidebarNoticeBox.Paragraph>
            </SidebarNoticeBox>
          )}

          {!loading && !!team_ids.length && (
            <SortableList
              callbacks={{
                onDragEnd: (fromIndex, toIndex, payload) => modifyTeamsSort(fromIndex, toIndex, payload, { team_ids }, setEntityState),
              }}
            >
              <SortableList.Dropzone droppableId="team_ids">
                <Box flexDirection="column" flexShrink={0}>
                  {team_ids.map((team_id, index) => (
                    <SortableList.Item draggableId={`Team-${team_id}`} key={team_id} index={index}>
                      <DraggableTeam
                        callbacks={{
                          deleteTeam: () => deleteProjectTeam(team_id, entityState, setEntityState),
                        }}
                        key={team_id}
                        teamId={team_id}
                      />
                    </SortableList.Item>
                  ))}
                </Box>
              </SortableList.Dropzone>
            </SortableList>
          )}

          {isCurrentUserAdminForOrganization && (
            <FormField
              direction="column"
              boxProps={{ marginTop: 'large' }}
              label={strings.formFieldLabels?.moreOptions || 'More Options'}
            >
              <Box flexDirection="column" flexShrink={0}>
                <Button
                  as="a"
                  buttonStyle="secondaryUtility"
                  icon={<FontAwesomeIcon icon={faUsers} />}
                  onClick={() => createOrEditTeam({
                    addProjectUserOrTeam: team => addProjectUserOrTeam(team, 'team_ids', entityState, setEntityState),
                  })}
                  size="medium"
                >
                  {strings.buttons?.create || 'Create Team'}
                </Button>
              </Box>
            </FormField>
          )}
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!(team_ids.length || lead_user_ids.length)}
          loading={updating}
          loadingBubbleColor="white"
          onClick={() => updateProject(entityState, 'Teams', updateFn, loadDataFn)}
          size="large"
        >
          {strings.buttons?.save || 'Save'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

ManageProjectTeamsModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  disableAnimation: PropTypes.bool,
  disableOverlay: PropTypes.bool,
  modalKey: PropTypes.string,
  project: PropTypes.object.isRequired,
  showModal: PropTypes.bool,
}

ManageProjectTeamsModal.defaultProps = {
  modalKey: 'ManageProjectTeamsModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <ManageProjectTeamsModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
