import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faPencil, faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Image, Link, ListItem, Text,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useCaseStudy, { useRelations } from '@hooks/useCaseStudy'

const DraggableCaseStudy = React.forwardRef((props, ref) => {
  const {
    callbacks: { removeCaseStudy },
    caseStudyId,
    dragHandleProps,
    dragSnapshot,
    draggableProps,
  } = props

  const { isDragging } = dragSnapshot || {}

  const {
    callbacks: { createOrEditCaseStudy },
    caseStudy,
    defaultImage,
  } = useCaseStudy({ id: caseStudyId })

  const {
    address: { full_address },
  } = useRelations(caseStudy)

  const imageUrl = digObject(defaultImage, 'sizes.thumb_90x90', '')

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        marginBottom: 'large',
      }}
      disableHover
      forwardProps={{
        ...draggableProps,
        ...dragHandleProps,
      }}
      isDragging={isDragging}
      ref={ref}
    >
      <Image
        boxProps={{ backgroundColor: 'hoverLightGrey', fontSize: 'small' }}
        circle
        height={30}
        width={30}
        url={imageUrl}
      >
        {!imageUrl && <FontAwesomeIcon icon={faHome} />}
      </Image>

      <Box flexDirection="column" marginLeft="large">
        <Text fontSize="small" marginBottom="small">
          {full_address}
        </Text>
      </Box>

      <Link greyLink onClick={() => createOrEditCaseStudy()} textProps={{ fontSize: 'small', marginRight: 'medium' }}>
        <FontAwesomeIcon icon={faPencil} />
      </Link>

      <Link greyLink onClick={() => removeCaseStudy(caseStudy.id)} textProps={{ fontSize: 'small' }}>
        <FontAwesomeIcon icon={faTrashAlt} />
      </Link>
    </ListItem>
  )
})

DraggableCaseStudy.propTypes = {
  callbacks: PropTypes.shape({
    removeCaseStudy: PropTypes.func.isRequired,
  }).isRequired,
  caseStudyId: PropTypes.number.isRequired,
  dragHandleProps: PropTypes.object,
  dragSnapshot: PropTypes.object,
  draggableProps: PropTypes.object,
}

export default DraggableCaseStudy
