import { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'

import { useModals, useSetState } from '@campaignhub/react-hooks'
import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import useLocalization from '@hooks/useLocalization'

import useCurrentUser from '@hooks/useCurrentUser'
import useOrganizationSelector from '@hooks/useOrganizationSelector'
import useReduxAction from '@hooks/useReduxAction'

import defaultRequestOptions from '@sections/Client/packs/Admin/defaultRequestOptions'

import handleCallbackAction from '@functions/handleCallbackAction'

import AuthorizeIntegrationModal from '@modals/AuthorizeIntegrationModal'
import BulkUpdateIntegrationsFeaturesModal from '@modals/BulkUpdateIntegrationsFeaturesModal'
import CreateOrEditIntegrationModal from '@modals/CreateOrEditIntegrationModal'
import ManageEntityDefaultIntegrationPlatformsModal from '@modals/ManageEntityDefaultIntegrationPlatformsModal'
import RTAValuePropositionModal from '@modals/RTAValuePropositionModal'
import SelectIntegrationPlatformModal from '@modals/SelectIntegrationPlatformModal'

import PageContent from './components/PageContent'
import localizedStrings from './localizedStrings'

const callbacks = (component, setState) => {
  const componentCallbacks = {
    AuthorizeIntegrationModal: {
      closeModal: () => setState({ showAuthorizeIntegrationModal: false }),
    },
    BulkUpdateIntegrationsFeaturesModal: {
      closeModal: () => setState({ showBulkUpdateIntegrationsFeaturesModal: false }),
      bulkUpdateIntegrationFeatures: payload => handleCallbackAction(payload),
    },
    CreateOrEditIntegrationModal: {
      closeModal: () => setState({
        showCreateOrEditIntegrationModal: false,
        showSelectIntegrationPlatformModal: false,
      }),
      updateIntegration: payload => handleCallbackAction(payload),
      createIntegration: payload => handleCallbackAction(payload),
      deleteIntegration: payload => handleCallbackAction(payload),
    },
    ManageEntityDefaultIntegrationPlatformsModal: {
      closeModal: () => setState({ showManageEntityDefaultIntegrationPlatformsModal: false }),
      updateEntity: payload => handleCallbackAction(payload),
    },
    RTAValuePropositionModal: {
      closeModal: () => setState({ showRTAValuePropositionModal: false }),
    },
    SelectIntegrationPlatformModal: {
      closeModal: () => setState({ showSelectIntegrationPlatformModal: false }),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  showAuthorizeIntegrationModal: false,
  showBulkUpdateIntegrationsFeaturesModal: false,
  showCreateOrEditIntegrationModal: false,
  showManageEntityDefaultIntegrationPlatformsModal: false,
  showRTAValuePropositionModal: false,
  showSelectIntegrationPlatformModal: false,
}

const Integrations = () => {
  const [state, setState] = useSetState(defaultState)
  const {
    showAuthorizeIntegrationModal,
    showBulkUpdateIntegrationsFeaturesModal,
    showCreateOrEditIntegrationModal,
    showManageEntityDefaultIntegrationPlatformsModal,
    showRTAValuePropositionModal,
    showSelectIntegrationPlatformModal,
  } = state

  const { isSelectedOrganizationAdmin, selectedOrganization } = useOrganizationSelector()

  const { currentUser } = useCurrentUser()

  const entityKey = isSelectedOrganizationAdmin ? `Organization${selectedOrganization.id}` : `User${currentUser.id}`

  useReduxAction('integrationPlatforms', 'loadIntegrationPlatforms', {
    ...defaultRequestOptions.integrationPlatform,
    ...defaultRequestOptions.image,
  })

  useReduxAction(
    'integrations',
    'loadIntegrations',
    {
      ...defaultRequestOptions.integration,
      entityKey,
      owner_id: selectedOrganization.id,
      owner_type: 'Organization',
      subject_id: isSelectedOrganizationAdmin ? null : currentUser.id,
      subject_type: isSelectedOrganizationAdmin ? null : 'User',
    },
    [currentUser.id, isSelectedOrganizationAdmin, selectedOrganization.id],
    {
      shouldPerformFn: ({ loadedForKeys }) => !loadedForKeys.includes(entityKey),
    },
  )

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const pageContext = useMemo(
    () => ({
      callbacks: {
        showAuthorizeIntegrationModal: (payload) => {
          setModalData('AuthorizeIntegrationModal', payload)
          setState({ showAuthorizeIntegrationModal: true })
        },
        showCreateOrEditIntegrationModal: (payload) => {
          setModalData('CreateOrEditIntegrationModal', payload)
          setState({ showCreateOrEditIntegrationModal: true })
        },
        showManageEntityDefaultIntegrationPlatformsModal: () => setState({
          showManageEntityDefaultIntegrationPlatformsModal: true,
        }),
        showBulkUpdateIntegrationsFeaturesModal: () => setState({
          showBulkUpdateIntegrationsFeaturesModal: true,
        }),
        showRTAValuePropositionModal: (payload) => {
          setModalData('RTAValuePropositionModal', payload)
          setState({ showRTAValuePropositionModal: true })
        },
        showSelectIntegrationPlatformModal: () => setState({ showSelectIntegrationPlatformModal: true }),
      },
    }),
    [],
  )

  const { strings } = useLocalization(localizedStrings)

  const title = strings.title ? `${strings.title} | Engage` : 'Engage'

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <Helmet>
          <title>{title}</title>
        </Helmet>

        <PageContent
          currentUser={currentUser}
          isSelectedOrganizationAdmin={isSelectedOrganizationAdmin}
          selectedOrganization={selectedOrganization}
        />

        <AuthorizeIntegrationModal
          callbacks={callbacks('AuthorizeIntegrationModal', setState, strings)}
          showModal={showAuthorizeIntegrationModal}
        />

        <CreateOrEditIntegrationModal
          callbacks={callbacks('CreateOrEditIntegrationModal', setState)}
          showModal={showCreateOrEditIntegrationModal}
        />

        <SelectIntegrationPlatformModal
          callbacks={callbacks('SelectIntegrationPlatformModal', setState)}
          showModal={showSelectIntegrationPlatformModal}
        />

        <ManageEntityDefaultIntegrationPlatformsModal
          callbacks={callbacks('ManageEntityDefaultIntegrationPlatformsModal', setState)}
          showModal={showManageEntityDefaultIntegrationPlatformsModal}
        />

        <BulkUpdateIntegrationsFeaturesModal
          callbacks={callbacks('BulkUpdateIntegrationsFeaturesModal', setState)}
          currentUser={currentUser}
          isSelectedOrganizationAdmin={isSelectedOrganizationAdmin}
          selectedOrganization={selectedOrganization}
          showModal={showBulkUpdateIntegrationsFeaturesModal}
        />

        <RTAValuePropositionModal
          callbacks={callbacks('RTAValuePropositionModal', setState, strings)}
          disableOverlay
          showModal={showRTAValuePropositionModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default Integrations
