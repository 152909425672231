import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, Grid,
} from '@campaignhub/suit-theme'

import Thumbnail from './Thumbnail'

const TileList = (props) => {
  const {
    filteredImages,
  } = props

  return (
    <Box flexDirection="column" flexShrink="0">
      <Grid
        gridGap="medium"
        gridAutoRows="1fr"
        gridTemplateColumns="repeat(auto-fill, minmax(100px, 1fr))"
        gridTemplateRows="repeat(auto-fill, minmax(100px, 1fr))"
        padding="medium"
      >
        {filteredImages.map(image => (
          <Thumbnail
            image={image}
            key={image.id}
          />
        ))}
      </Grid>
    </Box>
  )
}

TileList.propTypes = {
  filteredImages: PropTypes.array.isRequired,
}

export default TileList
