import PropTypes from 'prop-types'

import { faList } from '@fortawesome/pro-light-svg-icons'

import useEntityTypeCustomFields from '@hooks/useEntityTypeCustomFields'
import useLocalization from '@hooks/useLocalization'
import useReduxAction from '@hooks/useReduxAction'

import { Box, DashboardModule, Link } from '@campaignhub/suit-theme'

import useComparables from '@hooks/useComparables'
import useProject from '@hooks/useProject'

import CompactList from './CompactList'
import LargeList from './LargeList'

import comparablesBlankState from './assets/comparables-blank-state.svg'

import localizedStrings from './localizedStrings'

export const icon = faList

export const configOptions = strings => ({
  title: {
    component: 'input',
    key: 'title',
    label: strings.labelTitle?.title || 'Title',
    optionKey: 'customization',
  },
  display_mode: {
    component: 'select',
    key: 'display_mode',
    label: strings.displayMode?.label || 'Display Mode',
    optionKey: 'customization',
    values: [
      { key: 'compact-list', label: strings.displayModeValues?.labelListCompact || 'List (Compact)' },
      { key: 'large-list', label: strings.displayModeValues?.labelListLarge || 'List (Large)' },
    ],
  },
  comparable: {
    component: 'select',
    key: 'comparableSource',
    label: 'Comparable Source',
    optionKey: 'queryParams',
    values: [
      { key: 'all', label: strings.comparable?.all || 'All' },
      { key: 'market', label: strings.comparable?.market || 'Market' },
      { key: 'organization', label: strings.comparable?.agency || 'Agency' },
    ],
  },
  limit: {
    component: 'select',
    key: 'limit',
    label: strings.limit?.label || 'Limit',
    optionKey: 'queryParams',
    values: [
      { key: 5, label: '5' },
      { key: 10, label: '10' },
      { key: 25, label: '25' },
      { key: 50, label: '50' },
    ],
  },
})

const defaultRequestOptions = {
  include_comparable_address: true,
  include_comparable_data_store_items: true,
  include_comparable_default_image: true,
  include_image_sizes: ['thumb_200x120'],
}

const Comparables = (props) => {
  const {
    customOptions,
    customOptions: { queryParams: { comparableSource, limit, subject_id } },
    editing,
    parentId,
  } = props

  const requestOptions = {
    ...defaultRequestOptions,
    ...customOptions.queryParams,
  }

  useReduxAction('comparables', 'loadComparables', requestOptions, [
    parentId,
    JSON.stringify(requestOptions),
  ])

  const { customization } = customOptions
  const { display_mode, title } = customization || {}

  const {
    hasComparables,
    loading,
    filteredComparables,
  } = useComparables({
    filters: {
      comparableSource,
      limit,
      projectId: subject_id,
    },
  })

  const {
    organization,
    urls: { comparablesUrl },
  } = useProject({ id: subject_id })

  const {
    filteredCustomFields,
  } = useEntityTypeCustomFields('ProjectComparable', organization, { performHttpRequests: true })

  const { strings } = useLocalization(localizedStrings)

  return (
    <DashboardModule
      boxProps={{ marginBottom: 0 }}
      editing={editing}
      headerRight={(
        <Link
          href={comparablesUrl}
          textProps={{ fontSize: 'small' }}
        >
          {strings.manage || 'Manage'}
        </Link>
      )}
      loading={loading}
      title={title || strings.title?.title || 'Comparables'}
    >
      {!loading && !hasComparables && (
        <DashboardModule.BlankState imageUrl={comparablesBlankState}>
          <DashboardModule.BlankState.Title>{strings.blankState?.title || 'No Comparables'}</DashboardModule.BlankState.Title>

          <DashboardModule.BlankState.Paragraph>
            {strings.blankState?.paragraph || "This project has no comparable properties selected. You can search your own organization's listings, market sales and listings or create your own custom comparables if they're missing from the data supplier."}
          </DashboardModule.BlankState.Paragraph>
        </DashboardModule.BlankState>
      )}

      {!loading && hasComparables && (
        <Box flexDirection="column" flexWrap="wrap">
          {filteredComparables.map((comparable) => {
            const ListComponent = display_mode === 'compact-list' ? CompactList : LargeList

            return (
              <ListComponent
                key={comparable.id}
                comparable={comparable}
                customFields={filteredCustomFields}
              />
            )
          })}
        </Box>
      )}
    </DashboardModule>
  )
}

Comparables.propTypes = {
  customOptions: PropTypes.object.isRequired,
  editing: PropTypes.bool,
  parentId: PropTypes.string,
}

export default Comparables
