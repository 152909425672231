export default {
  default: {
    header: {
      title: 'Select',
      titleSecondLine: 'Platform',
    },
    formField: {
      label: 'Filter Platforms',
      placeholder: 'Find a platform',
    },
    divider: {
      text: 'PLATFORMS',
    },
  },
}
