import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGripDotsVertical } from '@fortawesome/pro-solid-svg-icons'

import {
  Box, Image, ListItem, Text,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useImage from '@hooks/useImage'

import type { ImageModel } from '@models/image'

import placeholderImageUrl from '../../assets/default_campaign_image_400x400.png'

interface DraggableListItemProps {
  dragHandleProps?: {},
  dragSnapshot?: {
    isDragging: boolean,
  },
  draggableProps?: {},
  image: ImageModel,
  updateDefaultImage: (image: ImageModel) => void,
}

const DraggableListItem = React.forwardRef((props: DraggableListItemProps, ref) => {
  const {
    dragHandleProps,
    dragSnapshot,
    draggableProps,
    image,
    updateDefaultImage,
  } = props

  const { isDragging } = dragSnapshot || {}

  const fileName = digObject(image, 'file_data.metadata.filename', '')
  const fileSize = digObject(image, 'file_data.metadata.size', '')
  const imageUrl = digObject(image, 'sizes.thumb_200x120', '')

  const { callbacks: { editImage } } = useImage(image)

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        padding: 'medium',
      }}
      forwardProps={{
        ...draggableProps,
        ...dragHandleProps,
      }}
      isDragging={isDragging}
      key={image.id}
      onClick={() => editImage(() => updateDefaultImage(image))}
      ref={ref}
      showAngleIcon
    >
      <FontAwesomeIcon icon={faGripDotsVertical} color="#cacaca" style={{ marginRight: '8px' }} />

      <Image
        borderRadius={5}
        height={60}
        marginRight="medium"
        placeholderUrl={placeholderImageUrl}
        url={imageUrl}
        width={90}
      />

      <Box flexDirection="column" minWidth="0" paddingLeft="medium" width="auto">
        <Text fontSize="small" variant="ellipsis">
          {fileName}
        </Text>

        <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="medium">
          {fileSize}
        </Text>
      </Box>
    </ListItem>
  )
})

export default DraggableListItem
