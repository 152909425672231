import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-solid-svg-icons'

import { Box, Text } from '@campaignhub/suit-theme'

const SectionSort = (props) => {
  const {
    callbacks: { editSectionSort },
  } = props

  return (
    <Box
      alignItems="center"
      borderBottom="1px solid"
      borderColor="lineColor"
      flexDirection="row"
      flexShrink="0"
      justifyContent="space-between"
      onClick={() => editSectionSort()}
      padding="large"
      style={{ cursor: 'pointer' }}
    >
      <Text color="bodyFontLightColor" fontSize="xsmall" fontWeight="500" letterSpacing="0.5px">
        Sort Sections
      </Text>

      <Text fontSize="small" color="lightGrey">
        <FontAwesomeIcon icon={faPlus} />
      </Text>
    </Box>
  )
}

SectionSort.propTypes = {
  callbacks: PropTypes.shape({
    editSectionSort: PropTypes.func.isRequired,
  }),
}

export default SectionSort
