import React from 'react'
import PropTypes from 'prop-types'

import { digObject } from '@campaignhub/javascript-utils'

import { Box, Text } from '@campaignhub/suit-theme'

import useChartData from '@hooks/useChartData'
import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const ProposalsAccepted = (props) => {
  const { chartDataKey, selectedDataPoint } = props

  const chartDataPayload = useChartData('benchmark_comparison', {
    chartDataKey,
    metricKey: 'average_proposals_accepted_per_user',
    performHttpRequests: false,
  })
  const { latestDataForMetricKey } = chartDataPayload

  const proposalsAcceptedForDataPoint = digObject(latestDataForMetricKey, selectedDataPoint, 0)

  const { strings } = useLocalization(localizedStrings)

  return (
    <Box alignItems="center" justifyContent="space-between">
      <Text color="bodyFontLightColor" fontSize="small">
        {strings.proposalsAccepted?.title || 'Average Proposals Accepted'}
      </Text>

      <Text fontSize="xxxlarge" color="darkGrey">
        {`${Math.round(proposalsAcceptedForDataPoint)}%`}
      </Text>
    </Box>
  )
}

ProposalsAccepted.propTypes = {
  chartDataKey: PropTypes.string.isRequired,
  selectedDataPoint: PropTypes.string.isRequired,
}

export default ProposalsAccepted
