export default {
  default: {
    starCount: {
      one: '1 Star',
      many: '{count} Stars',
      none: '',
      type: 'count',
    }
  },
}
