import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus } from '@fortawesome/pro-light-svg-icons'

import { useSetState } from '@campaignhub/react-hooks'

import {
  Box, Button, FormField, Link, SortableList, Text,
} from '@campaignhub/suit-theme'

import FilterUsersContactsAndTeams from '@components/FilterUsersContactsAndTeams'
import DraggableUser from '@components/ModalComponents/DraggableUser'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const defaultState = {
  showTeamLeadSearch: false,
  showTeamSupportSearch: false,
}

const DraggableUsers = (props) => {
  const {
    callbacks: { modifyUserSort, toggleUsers },
    disableDeleteForUserIds,
    userIds: { lead_user_ids, support_user_ids },
  } = props

  const allUserIds = [...lead_user_ids, ...support_user_ids]

  const [state, setState] = useSetState(defaultState)
  const { showTeamLeadSearch, showTeamSupportSearch } = state

  const { users } = useSelector(reduxState => reduxState.entities)

  const selectedLeadUsers = lead_user_ids.map(id => users[id]).filter(u => u)
  const selectedSupportUsers = support_user_ids.map(id => users[id]).filter(u => u)

  const { strings } = useLocalization(localizedStrings)

  return (
    <SortableList callbacks={{ onDragEnd: modifyUserSort }}>
      {/* Team Leads */}
      <Box flexDirection="column" marginY="large">
        <FormField
          label={strings.labels?.leadLabel || 'Lead'}
          labelRight={
            selectedLeadUsers.length ? (
              <Link
                textProps={{ fontSize: 'xsmall' }}
                onClick={() => setState({ showTeamLeadSearch: !showTeamLeadSearch })}
              >
                {showTeamLeadSearch ? strings.link?.hideSearch || 'Hide Search' : strings.link?.addUser || 'Add User'}
                <FontAwesomeIcon icon={faUserPlus} style={{ marginLeft: 5 }} />
              </Link>
            ) : null
          }
        >
          {showTeamLeadSearch && (
            <FilterUsersContactsAndTeams
              callbacks={{
                selectItem: (selected) => {
                  toggleUsers(selected.id, 'lead_user_ids')
                  setState({ showTeamLeadSearch: false })
                },
              }}
              placeholder={strings.searchPlaceholder || 'Search for users'}
              selectedUserIds={allUserIds}
            />
          )}

          {!selectedLeadUsers.length && !showTeamLeadSearch && (
            <Button
              buttonStyle="secondaryUtility"
              icon={<FontAwesomeIcon icon={faUserPlus} />}
              onClick={() => setState({ showTeamLeadSearch: true })}
              size="medium"
              marginTop="small"
            >
              {strings.buttonAddUser || 'Click to Add User'}
            </Button>
          )}

          <SortableList.Dropzone droppableId="lead_user_ids">
            <Box flexDirection="column">
              {selectedLeadUsers.map((user, index) => (
                <SortableList.Item draggableId={`User-${user.id}`} key={user.id} index={index}>
                  <DraggableUser
                    callbacks={{ removeUser: userId => toggleUsers(userId, 'lead_user_ids') }}
                    disableDelete={disableDeleteForUserIds.includes(user.id)}
                    user={user}
                  />
                </SortableList.Item>
              ))}

              {!!allUserIds.length && !selectedLeadUsers.length && (
                <Box
                  alignItems="center"
                  backgroundColor="whiteGrey"
                  border="1px dashed"
                  borderColor="lineColor"
                  borderRadius="5px"
                  height="62px"
                  justifyContent="center"
                  marginTop="large"
                >
                  <Text color="lightGrey" fontSize="small">
                    {strings.leaderDropZone || 'Drag user here to add to leaders'}
                  </Text>
                </Box>
              )}
            </Box>
          </SortableList.Dropzone>
        </FormField>
      </Box>
      {/* End Team Leads */}

      {/* Support */}
      <Box flexDirection="column" marginBottom="large">
        <FormField
          label={strings.labels?.supportLabel || 'Support'}
          labelRight={
            selectedSupportUsers.length ? (
              <Link
                textProps={{ fontSize: 'xsmall' }}
                onClick={() => setState({ showTeamSupportSearch: !showTeamSupportSearch })}
              >
                {showTeamSupportSearch
                  ? strings.link?.hideSearch || 'Hide Search'
                  : strings.link?.addUser || 'Add User'}
                <FontAwesomeIcon icon={faUserPlus} style={{ marginLeft: 5 }} />
              </Link>
            ) : null
          }
        >
          {showTeamSupportSearch && (
            <FilterUsersContactsAndTeams
              callbacks={{
                selectItem: (selected) => {
                  toggleUsers(selected.id, 'support_user_ids')
                  setState({ showTeamSupportSearch: false })
                },
              }}
              placeholder={strings.searchPlaceholder || 'Search for users'}
              selectedUserIds={allUserIds}
            />
          )}

          {!selectedSupportUsers.length && !showTeamSupportSearch && (
            <Button
              buttonStyle="secondaryUtility"
              icon={<FontAwesomeIcon icon={faUserPlus} />}
              onClick={() => setState({ showTeamSupportSearch: true })}
              size="medium"
              marginTop="small"
            >
              {strings.buttonAddUser || 'Click to Add User'}
            </Button>
          )}

          <SortableList.Dropzone droppableId="support_user_ids">
            <Box flexDirection="column">
              {selectedSupportUsers.map((user, index) => (
                <SortableList.Item draggableId={`User-${user.id}`} key={user.id} index={index}>
                  <DraggableUser
                    callbacks={{ removeUser: userId => toggleUsers(userId, 'support_user_ids') }}
                    disableDelete={disableDeleteForUserIds.includes(user.id)}
                    user={user}
                  />
                </SortableList.Item>
              ))}

              {!!allUserIds.length && !selectedSupportUsers.length && (
                <Box
                  alignItems="center"
                  backgroundColor="whiteGrey"
                  border="1px dashed"
                  borderColor="lineColor"
                  borderRadius="5px"
                  height="62px"
                  justifyContent="center"
                  marginTop="large"
                >
                  <Text color="lightGrey" fontSize="small">
                    {strings.supportDropZone || 'Drag user here to add to support'}
                  </Text>
                </Box>
              )}
            </Box>
          </SortableList.Dropzone>
        </FormField>
      </Box>
      {/* End Support */}
    </SortableList>
  )
}

DraggableUsers.propTypes = {
  callbacks: PropTypes.object.isRequired,
  disableDeleteForUserIds: PropTypes.array.isRequired,
  userIds: PropTypes.object.isRequired,
}

DraggableUsers.defaultProps = {
  disableDeleteForUserIds: [],
}

export default DraggableUsers
