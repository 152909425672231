import { useContext } from 'react'
import { Helmet } from 'react-helmet-async'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faLock } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Columns, LoadingModule, PageHeader, Tools,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import useIntegrations from '@hooks/useIntegrations'

import { digObject } from '@campaignhub/javascript-utils'

import ButtonWithOauth from '@components/ButtonWithOauth'
import IntegrationsBlankState from '@components/IntegrationsBlankState'
import PageFilterModule from '@components/PageFilterModule'

import IntegrationPlatform from '../IntegrationPlatform'

interface integrationPlatformsArrayObject {
  id: string,
  title: string,
}

const filterSystemIntegrationPlatforms = (integrationPlatforms: Array<integrationPlatformsArrayObject>) => {
  const filteredSystemIntegrationPlatforms = integrationPlatforms.filter((integrationPlatform) => {
    const integrationPlatformScope = digObject(integrationPlatform, 'data.integration_scope', '')

    return !integrationPlatformScope || integrationPlatformScope === 'system'
  })

  return filteredSystemIntegrationPlatforms
}

const buildPageFilterFields = (integrationPlatforms: Array<integrationPlatformsArrayObject>) => {
  const style = {
    marginBottom: 16,
  }

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: 'All',
        inputComponent: 'select',
        style,
      },
      key: 'integration_platform',
      label: 'Filter by Integration Platform',
      values: integrationPlatforms.map(platform => ({ key: platform.id, label: platform.title })),
    },
  ]
}

interface PageContentProps {
  pageFilters: {
    [key: string]: any,
  },
}

const PageContent = (props: PageContentProps) => {
  const { pageFilters } = props

  const integrationPayload = useIntegrations({
    filters: { ...pageFilters },
    includeSystemIntegrations: true,
    performHttpRequests: true,
  })

  const pageContext = useContext(PageContext)
  const {
    callbacks: { resetFilters, updateFilters },
    defaultFilters,
  } = pageContext

  const {
    groupedIntegrations,
    hasGroupedIntegrations,
    integrationPlatforms,
    loading,
    urls: { integrationsSystemManagerUrl },
  } = integrationPayload

  const systemIntegrationPlatforms = filterSystemIntegrationPlatforms(integrationPlatforms)

  return (
    <>
      <Helmet>
        <title>Integrations | Engage</title>
      </Helmet>

      <PageHeader
        activeTabBarItemKey="integrations"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        tabBarItems={[
          {
            href: integrationsSystemManagerUrl,
            icon: faCog,
            key: 'integrations',
            title: 'Integrations',
          },
        ]}
        title="Integrations"
      />

      <Box flexDirection="column" paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />
            {!loading && !hasGroupedIntegrations && <IntegrationsBlankState />}

            {!loading
              && hasGroupedIntegrations
              && Object.values(groupedIntegrations).map(groupedIntegration => (
                <IntegrationPlatform groupedIntegration={groupedIntegration} key={groupedIntegration.id} />
              ))}
          </Columns.Main>

          <Columns.Sidebar>
            <PageFilterModule
              callbacks={{ resetFilters, updateFilters }}
              defaultFilters={defaultFilters}
              filterFields={buildPageFilterFields(systemIntegrationPlatforms)}
              pageFilters={pageFilters}
              title="Filters"
            />

            <Tools>
              <ButtonWithOauth
                authProviderKey="docusign"
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faLock} />}
                queryParams={{
                  integration_platform_key: 'docusign',
                  owner_key: 'engage',
                  owner_type: 'System',
                  redirect: window.location.href,
                }}
                size="medium"
              >
                Authorize Docusign
              </ButtonWithOauth>
            </Tools>
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

export default PageContent
