import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'

import { faGlobe } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Columns, DashboardModule, LoadingModule, PageHeader,
} from '@campaignhub/suit-theme'

import useCustomFieldSets from '@hooks/useCustomFieldSets'
import useLocalization from '@hooks/useLocalization'

import CustomFieldSet from '../CustomFieldSet'

import localizedStrings from './localizedStrings'

const PageContent = (props) => {
  const { selectedOrganization } = props

  const { mergedFieldSets, loading } = useCustomFieldSets({
    brandId: selectedOrganization.brand_id,
    organizationId: selectedOrganization.id,
  })

  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      <Helmet>
        <title>{strings.helmet?.title || 'Custom Field Sets | Engage'}</title>
      </Helmet>

      <PageHeader
        activeTabBarItemKey="custom-field-sets"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        nestedNavigation
        tabBarItems={[
          {
            href: '#/admin/customFieldSets',
            icon: faGlobe,
            key: 'custom-field-sets',
            title: strings.pageHeader?.title || 'Custom Field Sets',
          },
        ]}
        title={strings.pageHeader?.title || 'Custom Field Sets'}
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && (
              <DashboardModule title={strings.pageHeader?.title || 'Custom Field Sets'}>
                <Box flexDirection="column">
                  {mergedFieldSets.map(fieldSet => (
                    <CustomFieldSet key={fieldSet.id} fieldSet={fieldSet} />
                  ))}
                </Box>
              </DashboardModule>
            )}
          </Columns.Main>
        </Columns>
      </Box>
    </>
  )
}

PageContent.propTypes = {
  selectedOrganization: PropTypes.object.isRequired,
}

export default PageContent
