export default {
  default: {
    digitalTemplateType: {
      agreement: {
        title: { singular: 'Agreement', plural: 'Agreements' },
      },
      form: {
        title: { singular: 'Form', plural: 'Forms' },
      },
      microsite: {
        title: { singular: 'Homepage', plural: 'Homepages' },
      },
      proposal: {
        title: { singular: 'Proposal', plural: 'Proposals' },
      },
      all: {
        title: { singular: 'Digital Page', plural: 'Digital Pages' },
      },
    },
    integrationPlatform: {
      realbase: { title: 'Realbase' },
      campaigntrack: { title: 'Campaigntrack' },
      realhub: { title: 'Realhub' },
    },
    organizationType: {
      realEstateAgency: {
        shortName: { singular: 'Agency', plural: 'Agencies' },
        title: { singular: 'Real Estate Agency', plural: 'Real Estate Agencies' },
      },
    },
    projectType: {
      realEstate: {
        shortName: { singular: 'Campaign', plural: 'Campaigns' },
        title: { singular: 'Campaign', plural: 'Campaigns' },
      },
      realEstateSaleCampaign: {
        shortName: { singular: 'Campaign', plural: 'Campaigns' },
        title: { singular: 'Sale Campaign', plural: 'Sale Campaigns' },
      },
      realEstateRentalCampaign: {
        shortName: { singular: 'Campaign', plural: 'Campaigns' },
        title: { singular: 'Rental Campaign', plural: 'Rental Campaigns' },
      },
    },
    role: {
      admin: {
        title: { singular: 'Admin', plural: 'Admins' },
      },
      support: {
        title: { singular: 'Support', plural: 'Support' },
      },
      accountManager: {
        title: { singular: 'Account Manager', plural: 'Account Managers' },
      },
      salesExecutive: {
        title: { singular: 'Sales Executive', plural: 'Sales Executives' },
      },
      brandUser: {
        title: { singular: 'Brand User', plural: 'Brand Users' },
      },
      organizationUser: {
        title: { singular: 'Organization User', plural: 'Organization Users' },
      },
      contact: {
        title: { singular: 'Contact', plural: 'Contacts' },
      },
    },
    status: {
      pending: { title: 'Pending' },
      approved: { title: 'Approved' },
      processing: { title: 'Processing' },
      processed: { title: 'Processed' },
      sent: { title: 'Sent' },
      urgent: { title: 'Urgent' },
      accepted: { title: 'Accepted' },
      rejected: { title: 'Rejected' },
      awaitingReview: { title: 'Awaiting Review' },
      awaitingQc: { title: 'Awaiting QC' },
    },
  },
}
