export default {
  default: {
    header: {
      title: 'Set Default',
      titleSecondLine: 'Integrations',
    },
    options: {
      defaultEntity: 'Set Default for',
      placeholder: 'No Default Set',
      searchUserPlaceholder: 'Search for a user or organisation',
    },
    footer: {
      button: 'Save',
    },
    mapResults: {
      usersTitle: 'USERS',
      organizationTitle: 'ORGANISATION',
      noEmail: 'No Email',
    },
    toast: {
      defaultIntegrationUpdated: 'Default Integration Updated Successfully',
    },
  },
}
