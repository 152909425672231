import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faHandHoldingUsd,
  faHandsHeart,
  faHome,
  faImages,
  faLink,
  faStar,
  faUserTag,
  faUsers,
} from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, DashboardModule, ListItem, Text,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'
import localizedStrings from './localizedStrings'

import Header from './components/Header'

const icons = {
  asset_libraries: faImages,
  case_studies: faHome,
  crm_integration: faLink,
  projects: faHome,
  proposals: faHandHoldingUsd,
  reviews: faStar,
  target_audiences: faUserTag,
  teams: faHandsHeart,
}

const Main = (props) => {
  const { onboardingTasksPayload } = props
  const {
    callbacks: { showCompleted, toggleOnboardingTasksModule },
    loaded,
    minimized,
    onboardingCompleted,
    sortedTasks,
  } = onboardingTasksPayload

  const { strings } = useLocalization(localizedStrings)

  if (!loaded || minimized || onboardingCompleted){
    return null
  }

  return (
    <DashboardModule
      boxProps={{ marginBottom: 0, marginTop: 'large' }}
      customHeader={<Header onboardingTasksPayload={onboardingTasksPayload} />}
    >
      <Box flexDirection="column">
        <Box flexDirection="column">
          {sortedTasks.map((task) => {
            const {
              body, completed, href, key, title,
            } = task

            return (
              <ListItem
                boxProps={{
                  alignItems: 'center',
                  as: 'a',
                  borderBottom: '1px dashed',
                  padding: 'large',
                }}
                href={href}
                key={key}
                showAngleIcon
              >
                <Box alignItems="center">
                  <Box
                    alignItems="center"
                    backgroundColor="backgroundColor"
                    borderRadius={5}
                    color="bodyFontLightColor"
                    height={40}
                    justifyContent="center"
                    position="relative"
                    width={40}
                  >
                    <FontAwesomeIcon icon={icons[key] || faUsers} />
                  </Box>

                  <Box flexDirection="column" marginLeft="large">
                    <Text
                      color={completed ? 'faintGrey' : 'bodyFontColor'}
                      fontSize="small"
                      marginBottom="small"
                      style={{ textDecoration: completed ? 'line-through' : 'none' }}
                    >
                      {title}
                    </Text>

                    <Text color="faintGrey" fontSize="xsmall">
                      {body}
                    </Text>
                  </Box>
                </Box>
              </ListItem>
            )
          })}
        </Box>

        <DashboardModule.Footer>
          <DashboardModule.FooterRight>
            <Button buttonStyle="secondaryEdit" onClick={() => showCompleted()} marginRight="medium" size="medium" width="auto">
              {strings?.buttons?.showCompleted || 'Show Completed'}
            </Button>

            <Button buttonStyle="secondaryEdit" onClick={() => toggleOnboardingTasksModule()} size="medium" width="auto">
              {strings?.buttons?.minimize || 'Minimize'}
            </Button>
          </DashboardModule.FooterRight>
        </DashboardModule.Footer>
      </Box>
    </DashboardModule>
  )
}

Main.propTypes = {
  callbacks: PropTypes.shape({
    updateUser: PropTypes.func.isRequired,
  }),
  onboardingTasksPayload: PropTypes.object.isRequired,
}

export default Main
