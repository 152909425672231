import { useContext } from 'react'
import cloneDeep from 'lodash/cloneDeep'

import { DateTime } from 'luxon'

import { useForm, useLatestEntity } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import {
  deepSetObject, digObject, launchModal, toggleArray,
} from '@campaignhub/javascript-utils'

import useDispatch from '@hooks/useDispatch'
import useSelector from '@hooks/useSelector'

import * as shareLinkActions from '@redux/modules/shareLink'
import * as digitalPageActions from '@redux/modules/digitalPage'

import defaultFormState, { requiredFields } from '@models/shareLink'

import PageContext from '@contexts/pageContext'

import type { AppDispatch } from '@redux/store'
import type { DeleteParams } from '@redux/modules/types'
import type { DigitalPageModel } from '@models/digitalPage'
import type { ShareLinkModel, ShareLinkRequestOptions } from '@models/types'

type Recipient = { id: string, type: string }

type CreateShareLinkParams = {
  dispatch: AppDispatch,
  requestOptions?: ShareLinkRequestOptions,
  shareLinkParams: Partial<ShareLinkModel>,
}

const createShareLink = (params: CreateShareLinkParams) => {
  const { dispatch, shareLinkParams, requestOptions } = params
  const { createShareLink: createFn } = shareLinkActions

  const { options } = shareLinkParams

  const newShareLink = {
    ...shareLinkParams,
    options: JSON.stringify(options),
  }

  return dispatch(createFn(newShareLink, requestOptions))
}

type SMSShareLinkParams = {
  digitalPage: DigitalPageModel,
  dispatch: AppDispatch,
  message: string,
  recipients?: Recipient[],
}

const SMSShareLink = (params: SMSShareLinkParams) => {
  const {
    digitalPage, recipients, message, dispatch,
  } = params
  const { shareDigitalPage: shareFn } = digitalPageActions

  return dispatch(shareFn(digitalPage, recipients, message))
}

type UpdateShareLinkParams = {
  dispatch: AppDispatch,
  requestOptions?: ShareLinkRequestOptions,
  shareLink: ShareLinkModel,
  shareLinkParams: Partial<ShareLinkModel>,
}

const updateShareLink = (params: UpdateShareLinkParams) => {
  const {
    dispatch, shareLink, shareLinkParams, requestOptions,
  } = params
  const { options } = shareLinkParams

  const { updateShareLink: updateFn } = shareLinkActions

  const updatedParams = {
    id: shareLink.id,
    ...shareLinkParams,
  }

  if (options){
    const shareLinkOptions = shareLink.data || {}
    updatedParams.options = JSON.stringify({
      ...cloneDeep(shareLinkOptions),
      ...options,
    })
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

type DeleteShareLinkParams = {
  dispatch: AppDispatch,
  shareLink: DeleteParams<ShareLinkModel>,
}

const deleteShareLink = (params: DeleteShareLinkParams) => {
  const { dispatch, shareLink } = params
  const { deleteShareLink: deleteFn } = shareLinkActions

  return dispatch(deleteFn(shareLink))
}

type ToggleNotificationRecipientParams = {
  entityState: Partial<ShareLinkModel>,
  recipient: { id: string, type: string },
  setEntityState: (options: {}) => void,
}

const toggleNotificationRecipient = (params: ToggleNotificationRecipientParams) => {
  const { entityState, setEntityState, recipient } = params
  const {
    options,
    options: { notification_recipients },
  } = entityState
  const updatedRecipients = toggleArray(notification_recipients, recipient, { deepCompare: true })

  setEntityState({ options: deepSetObject(options, 'notification_recipients', updatedRecipients) })
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useShareLinkForm(
  shareLink: Partial<ShareLinkModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const expiryDateString = digObject(shareLink, 'dates.expiry.date_time_with_timezone')

  const entity = {
    ...shareLink,
    expires_at:
      shareLink.id && expiryDateString
        ? DateTime.fromISO(expiryDateString).toFormat('yyyy-MM-dd')
        : shareLink.expires_at,
  }

  const shareLinkForm = useForm(
    defaultFormState,
    { entity, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [shareLink.id, shareLink.cache_key],
  )

  return {
    ...shareLinkForm,
  }
}

export const useRelations = (shareLink: Partial<ShareLinkModel> = {}) => {
  const { subject_id, subject_type } = shareLink

  const { digitalPages } = useSelector(reduxState => reduxState.entities)

  const digitalPage = subject_type === 'DigitalPage' && subject_id ? digitalPages[subject_id] || {} : {}

  return {
    digitalPage,
  }
}

function useShareLink(initEntity: Partial<ShareLinkModel> = {}) {
  const { entity: shareLink }: { entity: ShareLinkModel} = useLatestEntity(initEntity, 'shareLinks')

  const dispatch = useDispatch()

  const { callbacks } = useContext(PageContext)

  const { digitalPage } = useRelations(shareLink)

  const { updating } = useSelector(reduxState => reduxState.shareLinks)

  const shareLinkUrl = digObject(shareLink, 'paths.public', '')

  const isAcceptedShareLink = digObject(digitalPage, 'options.accepted_share_link_id') === shareLink?.id

  return {
    callbacks: {
      createShareLink: (shareLinkParams: Partial<ShareLinkModel>, entityOptions?: ShareLinkRequestOptions) => (
        createShareLink({ shareLinkParams, dispatch, requestOptions: entityOptions })
      ),
      deleteShareLink: () => deleteShareLink({ dispatch, shareLink }),
      editShareLink: (suppliedShareLink: string) => launchModal({
        callbacks,
        modalKey: 'EditShareLinkModal',
        payload: {
          callbacks: {
            deleteShareLink: () => deleteShareLink({ dispatch, shareLink }),
            updateShareLink: (shareLinkParams: Partial<ShareLinkModel>, entityOptions?: ShareLinkRequestOptions) => (
              updateShareLink({
                shareLink, shareLinkParams, dispatch, requestOptions: entityOptions,
              })
            ),
          },
          shareLink: suppliedShareLink || shareLink,
        },
      }),
      sendSMS: () => launchModal({
        callbacks,
        modalKey: 'SendSMSModal',
        payload: {
          callbacks: {
            sendSMS: (
              recipients: Recipient[],
              message: string,
            ) => SMSShareLink({
              digitalPage, dispatch, message, recipients,
            }),
          },
          digitalPage,
          prependText: shareLinkUrl,
        },
      }),
      toggleNotificationRecipient,
      updateShareLink: (shareLinkParams: Partial<ShareLinkModel>, entityOptions?: ShareLinkRequestOptions) => (
        updateShareLink({
          shareLink, shareLinkParams, dispatch, requestOptions: entityOptions,
        })
      ),
      viewShareLinkAnalytics: () => launchModal({
        callbacks,
        modalKey: 'ShareLinkAnalyticsModal',
        payload: { shareLink },
      }),
    },
    digitalPage,
    isAcceptedShareLink,
    shareLink,
    shareLinkUrl,
    trackingEnabled: digObject(shareLink, 'tracking_enabled', false),
    updating,
  }
}

export default useShareLink
