import { useMemo } from 'react'
import { toast } from 'react-toastify'

import { useModals, useSetState } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import useReduxAction from '@hooks/useReduxAction'
import useOrganizationSelector from '@hooks/useOrganizationSelector'

import handleCallbackAction from '@functions/handleCallbackAction'

import CreateEventCalendarModal from '@modals/CreateEventCalendarModal'
import ManageEntityUserPermissionsModal from '@modals/ManageEntityUserPermissionsModal'

import defaultRequestOptions from '@sections/Client/defaultRequestOptions'

import PageContent from './components/PageContent'

const createEventCalendar = (calendarParams, createFn, setState) => {
  createFn(calendarParams, defaultRequestOptions.eventCalendarTemplate).then(({ success, errors, redirectUrl }) => {
    if (!success){
      toast.warning(errors[0])
      return
    }

    if (redirectUrl){
      window.location.href = redirectUrl
    }

    setState({ showCreateEventCalendarModal: false })
  })
}

const callbacks = (component, setState) => {
  const componentCallbacks = {
    CreateEventCalendarModal: {
      createEventCalendar: (params, createFn) => createEventCalendar(params, createFn, setState),
      closeModal: () => setState({ showCreateEventCalendarModal: false }),
    },
    ManageEntityUserPermissionsModal: {
      updateEntityUserPermissions: payload => handleCallbackAction(payload),
      closeModal: () => setState({ showManageEntityUserPermissionsModal: false }),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  pageFilters: {},
  showCreateEventCalendarModal: false,
  showManageEntityUserPermissionsModal: false,
}

const EventCalendarTemplates = () => {
  const [state, setState] = useSetState(defaultState)
  const { pageFilters, showCreateEventCalendarModal, showManageEntityUserPermissionsModal } = state

  const { selectedOrganization } = useOrganizationSelector()

  useReduxAction(
    'eventCalendarTemplates',
    'loadEventCalendarTemplates',
    {
      ...defaultRequestOptions.eventCalendarTemplate,
      ...pageFilters,
      owner_id: selectedOrganization.id,
      owner_type: 'Organization',
    },
    [selectedOrganization.id, JSON.stringify(pageFilters)],
  )

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const pageContext = useMemo(
    () => ({
      callbacks: {
        resetFilters: (resetFn) => {
          if (resetFn) resetFn()
          setState({ pageFilters: {} })
        },
        showCreateEventCalendarModal: (payload) => {
          setModalData('CreateEventCalendarModal', payload)
          setState({ showCreateEventCalendarModal: true })
        },
        showManageEntityUserPermissionsModal: (payload) => {
          setModalData('ManageEntityUserPermissionsModal', payload)
          setState({ showManageEntityUserPermissionsModal: true })
        },
        updateFilters: (filters) => { setState({ pageFilters: filters }) },
      },
    }),
    [],
  )

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent pageFilters={pageFilters} selectedOrganization={selectedOrganization} />

        <CreateEventCalendarModal
          callbacks={callbacks('CreateEventCalendarModal', setState)}
          showModal={showCreateEventCalendarModal}
        />

        <ManageEntityUserPermissionsModal
          callbacks={callbacks('ManageEntityUserPermissionsModal', setState)}
          showModal={showManageEntityUserPermissionsModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default EventCalendarTemplates
