import PropTypes from 'prop-types'

import {
  Box, Button, SidebarModal, SortableList,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import useAwards from '@hooks/useAwards'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import useEditAwardSort from './hooks/useEditAwardSort'

import localizedStrings from './localizedStrings'

import DraggableAward from './components/DraggableAward'

const MODAL_KEY = 'RemoveOrganizationsUserModal'

type ModalCallbacks = {
  closeModal: () => void,
  updateAwardSortOrder: (payload: HandleCallbackActionParams) => Promise<object>,
}

interface EditAwardSortModalProps {
  callbacks: ModalCallbacks,
  ownerId: number,
  showModal: boolean,
  subjectId: number,
  subjectType: string,
}

const EditAwardSortModal = (props: EditAwardSortModalProps) => {
  const {
    callbacks, ownerId, showModal, subjectId, subjectType,
  } = props

  const { closeModal, updateAwardSortOrder } = callbacks || {}

  const awardsPayload = useAwards({
    filters: {
      owner_id: ownerId,
      owner_type: 'Organization',
      subject_id: subjectId,
      subject_type: subjectType,
    },
    performHttpRequests: true,
    requestOptions: {
      include_award_data_store_items: true,
      include_award_default_image: true,
      include_image_sizes: ['thumb_120x80', 'thumb_660x360'],
    },
  })
  const {
    callbacks: { updateAwardSortOrder: updateAwardSortFn },
    filteredAwards,
  } = awardsPayload

  const editAwardSortPayload = useEditAwardSort({ filteredAwards })
  const {
    callbacks: { modifyAwardSort },
    sortedAwards,
    sortedIds,
  } = editAwardSortPayload

  const { strings } = useLocalization(localizedStrings)

  const updateAwardPayload = {
    callbacks: {
      action: updateAwardSortFn,
      afterAction: closeModal,
    },
    entityParams: sortedIds,
    toastText: strings.sortedAward || 'Awards Sorted Successfully',
  }

  return (
    <SidebarModal callbacks={callbacks} modalKey={MODAL_KEY} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.modalHeader?.title || 'Sort'}
        titleSecondLine={strings.modalHeader?.titleSecondLine || 'Awards'}
      />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <SortableList
            callbacks={{
              onDragEnd: (fromIndex, toIndex, payload) => modifyAwardSort(fromIndex, toIndex, payload),
            }}
          >
            <SortableList.Dropzone droppableId="sortedIds">
              <Box flexDirection="column">
                {sortedAwards.map((award, index) => (
                  <SortableList.Item draggableId={`Award-${award.id}`} key={award.id} index={index}>
                    <DraggableAward award={award} />
                  </SortableList.Item>
                ))}
              </Box>
            </SortableList.Dropzone>
          </SortableList>
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button buttonStyle="primaryCreate" onClick={() => updateAwardSortOrder(updateAwardPayload)} size="large">
          {strings.buttonText?.save || 'Save'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

EditAwardSortModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  showModal: PropTypes.bool,
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <EditAwardSortModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
