import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Link, ListItem, Text,
} from '@campaignhub/suit-theme'

import useAttachment from '@hooks/useAttachment'

const DraggableAttachment = React.forwardRef((props, ref) => {
  const {
    attachmentId,
    callbacks: { removeAttachment },
    dragHandleProps,
    dragSnapshot,
    draggableProps,
    projectType,
  } = props

  const { isDragging } = dragSnapshot || {}

  const {
    callbacks: { editAttachment },
    fileName,
    title,
  } = useAttachment({ id: attachmentId })

  const customPayload = {
    filterCustomFieldsBy: { project_type_key: projectType?.key },
  }

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        marginBottom: 'large',
      }}
      disableHover
      forwardProps={{
        ...draggableProps,
        ...dragHandleProps,
      }}
      isDragging={isDragging}
      ref={ref}
    >
      <Box flexDirection="column" width="75%">
        <Text variant="ellipsis" fontSize="small" color="bodyFontColor">
          {title}
        </Text>

        <Text variant="ellipsis" marginTop="small" fontSize="xsmall" color="faintGrey">
          {fileName}
        </Text>
      </Box>

      <Box marginLeft="auto" justifyContent="flex-end">
        <Link
          onClick={() => editAttachment(customPayload)}
          greyLink
          textProps={{ marginLeft: 'medium' }}
        >
          <FontAwesomeIcon icon={faPencil} />
        </Link>

        <Link onClick={() => removeAttachment(attachmentId)} greyLink textProps={{ marginLeft: 'medium' }}>
          <FontAwesomeIcon icon={faTrashAlt} />
        </Link>
      </Box>
    </ListItem>
  )
})

DraggableAttachment.propTypes = {
  attachmentId: PropTypes.number.isRequired,
  callbacks: PropTypes.shape({
    removeAttachment: PropTypes.func.isRequired,
  }).isRequired,
  dragHandleProps: PropTypes.object,
  dragSnapshot: PropTypes.object,
  draggableProps: PropTypes.object,
  projectType: PropTypes.object.isRequired,
}

export default DraggableAttachment
