import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, ListItem, Text,
} from '@campaignhub/suit-theme'

import useAttachment from '@hooks/useAttachment'
import { useRelations } from '@hooks/useProject'

const Document = (props) => {
  const { attachment, project } = props

  const { projectType } = useRelations(project)

  const attachmentPayload = useAttachment(attachment)
  const {
    callbacks: { editAttachment },
    fileName,
    title,
  } = attachmentPayload

  const customPayload = {
    filterCustomFieldsBy: { project_type_key: projectType.key },
  }

  return (
    <ListItem
      boxProps={{
        as: 'a',
        alignItems: 'center',
        borderBottom: '1px dashed',
        justifyContent: 'space-between',
        padding: 'large',
      }}
      onClick={() => editAttachment(customPayload)}
      showAngleIcon
    >
      <Box flexDirection="column" marginRight="small" width="75%">
        <Text fontSize="small" lineHeight={1.3} marginBottom="small" variant="ellipsis">
          {title}
        </Text>

        <Text color="faintGrey" fontSize="xsmall" variant="ellipsis">
          {fileName}
        </Text>
      </Box>
    </ListItem>
  )
}

Document.propTypes = {
  attachment: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
}

export default Document
