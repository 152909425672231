import React from 'react'
import PropTypes from 'prop-types'

import { Box, SidebarModal } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import CoreLogicAustralia from './CoreLogicAustralia'

import localizedStrings from './localizedStrings'

const availableComponents = {
  core_logic_au: CoreLogicAustralia,
}

const Disclaimer = (props) => {
  const {
    accepted,
    callbacks: { toggleDisclaimerAcceptance },
    integrationPlatformKey,
  } = props

  const Component = availableComponents[integrationPlatformKey]

  const { strings } = useLocalization(localizedStrings)

  if (!Component){
    return null
  }

  return (
    <Box flexDirection="column" marginTop="large">
      <Component accepted={accepted} />

      <SidebarModal.SelectItem
        callbacks={{ selectItem: () => toggleDisclaimerAcceptance() }}
        selected={accepted}
        title={strings.title || "I've read & accept the terms and conditions"}
      />
    </Box>
  )
}

Disclaimer.propTypes = {
  accepted: PropTypes.bool,
  callbacks: PropTypes.shape({
    toggleDisclaimerAcceptance: PropTypes.func.isRequired,
  }).isRequired,
  integrationPlatformKey: PropTypes.string.isRequired,
}

export default Disclaimer
