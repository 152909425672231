import { digObject, sortArrayBy } from '@campaignhub/javascript-utils'

const shouldSortTimeSeries = {
  house_median_price_series: true,
  unit_median_price_series: true,
}

type OptionsType = {
  chartContext: string,
  chartDataKey: string,
  chartType: string,
  sort:number,
}

type ChartDataDetailsType = {
  median_price?: number,
  name?: number,
}

type ChartDataType = {
  house_median_price_series: ChartDataDetailsType[],
  unit_median_price_series: ChartDataDetailsType[],
}

const formatChartData = (chartData: ChartDataType, options: OptionsType) => {
  const chartDataKey: string = digObject(options, 'chartDataKey', '')
  const dataSeries: ChartDataDetailsType[] = digObject(chartData, `${chartDataKey}`, [])

  const canSortData: boolean = !!dataSeries.length && shouldSortTimeSeries[chartDataKey]

  const dataArray = canSortData ? sortArrayBy(dataSeries, 'asc', 'name') : dataSeries

  // get the keys that aren't 'name'
  const firstDataObject = dataArray[0] || {}
  const dataKeys = Object.keys(firstDataObject).filter(key => key !== 'name')

  return {
    dataArray,
    dataKeys,
  }
}

export default formatChartData
