import { digObject } from '@campaignhub/javascript-utils'
import useCurrentUser from '@hooks/useCurrentUser'
import useIntegrationPlatform from '@hooks/useIntegrationPlatform'
import useIntegrations from '@hooks/useIntegrations'
import useOrganizationSelector from '@hooks/useOrganizationSelector'

import { IntegrationModel } from '@models/integration'

type IntegrationPlatform = {
  id: number,
  key: String,
}
type Options = {
  filteredPlatforms: Array<IntegrationPlatform>,
  selectedIntegrationPlatformKey: String,
}

const hasIntegrationExpired = (integration: IntegrationModel) => {
  const { data } = integration || {}
  const { refresh_token_expires_at } = data || {}

  const expires_at = digObject(integration, 'dates.expires.date_time_with_timezone')

  const today = new Date()

  const refreshTokenExpiry = new Date(refresh_token_expires_at)
  const refreshTokenExpired = (today.getTime() >= refreshTokenExpiry.getTime())

  const accessTokenExpiry = new Date(expires_at)
  const accessTokenExpired = (today.getTime() >= accessTokenExpiry.getTime())

  return accessTokenExpired && refreshTokenExpired
}

const useAuthorizeIntegration = (options: Options) => {
  const { filteredPlatforms, selectedIntegrationPlatformKey } = options || {}

  const selectedIntegrationPlatform = filteredPlatforms
    ?.find(integrationPlatform => integrationPlatform.key === selectedIntegrationPlatformKey)

  const { oauthProviderKey } = useIntegrationPlatform(selectedIntegrationPlatform)

  const { selectedOrganization } = useOrganizationSelector()
  const { currentUser } = useCurrentUser()

  const { filteredIntegrations } = useIntegrations({
    filters: {
      integration_platform: selectedIntegrationPlatform?.id,
      owner_id: selectedOrganization.id,
      owner_type: 'Organization',
      subject_id: currentUser.id,
      subject_type: 'User',
    },
  })

  const integration = filteredIntegrations.find(
    integrations => integrations.integration_platform_id === selectedIntegrationPlatform?.id,
  )

  return {
    integration,
    integrationExpired: hasIntegrationExpired(integration),
    oauthProviderKey,
    selectedIntegrationPlatform,
  }
}

export default useAuthorizeIntegration
