export default {
  default: {
    boolSelect: {
      no: 'No',
      yes: 'Yes',
    },
    button: {
      delete: 'Delete Field',
      save: 'Save Field',
    },
    divider: 'Size & Position',
    formField: {
      completedBy: 'Completed By',
      disableCollaboration: 'Disable Collaboration',
      height: 'Height',
      key: 'Key *',
      label: 'Label',
      locked: 'Locked',
      moreOptions: 'More Options',
      placeholder: 'Placeholder',
      required: 'Required',
      textType: 'Text Type',
      type: 'Type *',
      value: 'Value',
      width: 'Width',
      x: 'X',
      y: 'Y',
    },
    header: {
      secondLine: 'Field',
      title: 'Edit',
    },
    select: {
      checkbox: 'Checkbox',
      default: 'Default',
      dropdown: 'Dropdown',
      email: 'Email',
      initial: 'Initial',
      number: 'Number',
      pleaseSelect: 'Please Select...',
      signature: 'Signature',
      text: 'Text',
    },
    swal: {
      confirmButton: 'Yes, delete it',
      text: 'Are you sure?',
      title: 'Delete Field?',
    },
  },
}
