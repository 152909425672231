import mixpanel from 'mixpanel-browser'
import { generateUserRoleString } from '@functions/user'

import useBrandSelector from '@hooks/useBrandSelector'
import useCurrentUser from '@hooks/useCurrentUser'
import useOrganizationSelector from '@hooks/useOrganizationSelector'

import { BrandModel, OrganizationModel, UserModel } from '@models/types'

export type MixPanelType = (eventType: string, customPayload?: Record<string, string | number>) => void

export const mixpanelDefaultConfig = {
  api_transport: 'sendBeacon',
  batch_requests: true,
  debug: process.env.REACT_APP_APPLICATION_ENV !== 'production',
  persistence: 'localStorage',
}

export const mixpanelDefaultSuperProperties = {
  Product: 'Engage',
}

type DispatchMixpanelEventType = {
  currentUser: Partial<UserModel>,
  customOptions?: Record<string, string>,
  eventType: string,
  isBrandUser: boolean | string,
  selectedBrand: BrandModel,
  selectedOrganization: OrganizationModel,
}

const dispatchMixpanelEvent = (params: DispatchMixpanelEventType) => {
  const {
    currentUser, customOptions, eventType, isBrandUser, selectedBrand, selectedOrganization,
  } = params

  const { id, realbase_id, brand = {} } = currentUser

  if (!eventType) return null

  return mixpanel.track(eventType, {
    brand: selectedBrand?.title || brand?.title,
    brand_hub: isBrandUser,
    business_unit: 'Engage',
    onboarding: selectedOrganization?.onboarding || false,
    organization: selectedOrganization?.title,
    realbase_id: `Realbase ID #${realbase_id}`,
    role: generateUserRoleString(currentUser, selectedOrganization?.id),
    user_id: id,
    window_location: window.location.href,
    ...customOptions,
  })
}

type MixPanelOptions = {
  shouldTrackPageView?: boolean,
}

const initMixpanel = (shouldTrackPageView: boolean) => {
  mixpanel.init(
    process.env.REACT_APP_MIXPANEL_TOKEN,
    { ...mixpanelDefaultConfig, track_pageview: shouldTrackPageView },
  )

  return mixpanel.register({ ...mixpanelDefaultSuperProperties })
}

function useMixpanel(options: MixPanelOptions = {}) {
  const { shouldTrackPageView = false } = options

  const currentUserPayload = useCurrentUser()
  const { currentUser, isBrandUser } = currentUserPayload

  const organizationSelectorPayload = useOrganizationSelector()
  const { selectedOrganization } = organizationSelectorPayload

  const brandSelectorPayload = useBrandSelector({ autoSelect: true })
  const { selectedBrand } = brandSelectorPayload

  return {
    callbacks: {
      dispatchMixpanelEvent: (eventType: string, customOptions?: Record<string, any>) => dispatchMixpanelEvent({
        currentUser,
        customOptions,
        eventType,
        isBrandUser,
        selectedBrand,
        selectedOrganization,
      }),
      initMixpanel: () => initMixpanel(shouldTrackPageView),
    },
  }
}

export default useMixpanel
