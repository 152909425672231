import {
  faFire, faMugHot, faSnowflake, IconDefinition,
} from '@fortawesome/pro-solid-svg-icons'

export type EngagementStatusThemesType = {
 [key: string]: {
  colors: {
    border:string,
    primary: string,
    secondary: string,
  },
  icon: IconDefinition,
  title: string,
 },
}

export const engagementStatusThemes: EngagementStatusThemesType = {
  cold: {
    colors: {
      primary: '#6180BB',
      secondary: '#F2F5FB',
      border: '#DCE3F1',
    },
    icon: faSnowflake,
    title: 'Cold',
  },
  hot: {
    colors: {
      primary: '#BB6161',
      secondary: '#FBF3F2',
      border: '#F1DDDC',
    },
    icon: faFire,
    title: 'Hot',
  },
  warm: {
    colors: {
      primary: '#D7B94E',
      secondary: '#FAFBF2',
      border: '#F5F1DA',
    },
    icon: faMugHot,
    title: 'Warm',
  },
}

function useEngagementStatus(statusKey: string) {
  return {
    engagementStatusTheme: engagementStatusThemes[statusKey] || {},
  }
}

export default useEngagementStatus
