type SupportedShortcodesType = Record<string, string>

const supportedShortcodes: SupportedShortcodesType = {
  'project.commission_fee': 'commission',
  'project.commission_structure': 'commission',
  'project.cover_letter': 'cover_letter',
  'project.full_address': 'reviews',
  'project.method_of_sale': 'sale_details',
  'project.price_guide': 'sale_details',
  'project.title': 'project_details',
}

function getStringShortcodes(string: string) {
  if (!string) return []

  const regex = /\{\{(.\S+)\}\}/g
  const matches = string.match(regex)

  return matches || []
}

function getStringFeatureKeys(string: string) {
  const shortcodes = getStringShortcodes(string)

  // Remove brackets {{ }}
  const featureKeys = shortcodes.map((shortcode: string) => {
    const cleaned = shortcode.replace(/\{|\{|\}|\}/g, '')
    return supportedShortcodes[cleaned]
  }).filter(k => k)

  const uniqueKeys = [...new Set(featureKeys)]

  return uniqueKeys
}

export default getStringFeatureKeys
