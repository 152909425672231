import React from 'react'
import PropTypes from 'prop-types'

import { faGreaterThanEqual } from '@fortawesome/pro-light-svg-icons'

import useChartData from '@hooks/useChartData'
import useLocalization from '@hooks/useLocalization'

import { Box, DashboardModule } from '@campaignhub/suit-theme'

import LineChart from '@components/charts/LineChart'

import analyticsImageUrl from './assets/analytics.svg'

import localizedStrings from './localizedStrings'

export const icon = faGreaterThanEqual

const getChartDetails = (metricKey, strings) => {
  const chartDetails = {
    total_proposals_created: {
      title: strings.metricKeyValues?.totalProposalsCreated || 'Total Proposals Created',
      component: LineChart,
      excludedDataKeys: ['name'],
    },
    average_proposal_read_percentage: {
      title: strings.metricKeyValues?.averageProposalReadPercentage || 'Average Proposal Read Percentage',
      component: LineChart,
      excludedDataKeys: ['name'],
    },
    average_proposal_seconds_on_page: {
      title: strings.metricKeyValues?.averageProposalSecondsOnPage || 'Average Proposal Seconds on Page',
      component: LineChart,
      excludedDataKeys: ['name'],
    },
    average_proposals_accepted_per_user: {
      title: strings.metricKeyValues?.averageProposalsAcceptedPerUser || 'Average Proposals Accepted per User',
      component: LineChart,
      excludedDataKeys: ['name'],
    },
    average_proposals_created_per_user: {
      title: strings.metricKeyValues?.averageProposalsCreatedPerUser || 'Average Proposals Created per User',
      component: LineChart,
      excludedDataKeys: ['name'],
    },
    total_proposals_accepted: {
      title: strings.metricKeyValues?.totalProposalsAccepted || 'Total Proposals Accepted',
      component: LineChart,
      excludedDataKeys: ['name'],
    },
  }

  return chartDetails[metricKey] || {}
}

export const configOptions = strings => ({
  metric_key: {
    component: 'select',
    key: 'metric_key',
    label: strings.metricKey?.label || 'Comparison Type',
    optionKey: 'customization',
    values: [
      {
        key: 'average_proposal_read_percentage',
        label: strings.metricKeyValues?.averageProposalReadPercentage || 'Average Proposal Read Percentage',
      },
      {
        key: 'average_proposal_seconds_on_page',
        label: strings.metricKeyValues?.averageProposalSecondsOnPage || 'Average Proposal Seconds on Page',
      },
      {
        key: 'average_proposals_accepted_per_user',
        label: strings.metricKeyValues?.averageProposalsAcceptedPerUser || 'Average Proposals Accepted per User',
      },
      {
        key: 'average_proposals_created_per_user',
        label: strings.metricKeyValues?.averageProposalsCreatedPerUser || 'Average Proposals Created per User',
      },
      {
        key: 'total_proposals_accepted',
        label: strings.metricKeyValues?.totalProposalsAccepted || 'Total Proposals Accepted',
      },
      {
        key: 'total_proposals_created',
        label: strings.metricKeyValues?.totalProposalsCreated || 'Total Proposals Created',
      },
    ],
  },
})

const Comparisons = (props) => {
  const { customOptions, editing } = props

  const {
    customization,
    queryParams: { entity_id, entity_type },
  } = customOptions

  const { metric_key } = customization || {}

  const { strings } = useLocalization(localizedStrings)

  const { excludedDataKeys, title: chartTitle, component } = getChartDetails(metric_key, strings)
  const ChartComponent = component || LineChart

  const dataPayload = useChartData('benchmark_comparison', {
    entityId: entity_id,
    entityType: entity_type,
    excludedDataKeys,
    metricKeys: [metric_key],
  })
  const { dataArray, loading } = dataPayload

  return (
    <DashboardModule
      boxProps={{ marginBottom: 0 }}
      editing={editing}
      loading={loading}
      title={chartTitle || strings.analytics || 'Analytics'}
    >
      {!loading && !dataArray.length && (
        <DashboardModule.BlankState imageUrl={analyticsImageUrl}>
          <DashboardModule.BlankState.Title>
            {strings.blankState?.title || 'No Comparison Data to Display'}
          </DashboardModule.BlankState.Title>

          <DashboardModule.BlankState.Paragraph>
            {strings.blankState?.paragraph
              || 'Once pages have been created and viewed comparison data will be recorded and displayed'}
          </DashboardModule.BlankState.Paragraph>
        </DashboardModule.BlankState>
      )}

      {!loading && !!dataArray.length && (
        <Box flexDirection="column" flexWrap="wrap" padding="medium">
          <ChartComponent dataPayload={dataPayload} height={200} width="100%" />
        </Box>
      )}
    </DashboardModule>
  )
}

Comparisons.propTypes = {
  customOptions: PropTypes.object.isRequired,
  editing: PropTypes.bool,
}

export default Comparisons
