import { useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import PropTypes from 'prop-types'

import { faGlobe } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Columns, DashboardModule, LoadingModule, PageHeader,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import useCurrentUser from '@hooks/useCurrentUser'
import useDigitalTemplateComponents from '@hooks/useDigitalTemplateComponents'
import useIntercom from '@hooks/useIntercom'
import useMixpanel from '@hooks/useMixpanel'

import PageFilterModule from '@components/PageFilterModule'

import ComponentStylesBlankState from '../ComponentStylesBlankState'
import ComponentStyle from '../ComponentStyle'

const buildPageFilterFields = (isAdmin) => {
  const boxProps = {
    marginBottom: 'large',
  }

  const style = {
    marginBottom: 16,
  }

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        style,
      },
      key: 'string',
      label: 'Filter by Title or ID',
    },
    {
      callbacks: {
        shouldRender: () => isAdmin,
      },
      component: 'EntitySelector',
      componentProps: {
        entityKey: 'brands',
        boxProps,
      },
      key: 'brand_id',
    },
    {
      entityKey: 'limit',
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: '100',
        inputComponent: 'select',
        style,
      },
      key: 'limit',
      label: 'Limit',
      values: [
        { key: 150, label: '150' },
        { key: 200, label: '200' },
      ],
    },
  ]
}

const PageContent = (props) => {
  const { pageFilters } = props

  const pageContext = useContext(PageContext)
  const {
    callbacks: { resetFilters, showCreateOrEditComponentStyleModal, updateFilters },
  } = pageContext

  const digitalTemplateComponentsPayload = useDigitalTemplateComponents({
    filters: pageFilters,
    item_type: 'component_style',
    performHttpRequests: true,
    requestOptions: {
      include_digital_template_component_digital_template_ids: true,
    },
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filteredDigitalTemplateComponents,
    hasDigitalTemplateComponents,
    loading,
  } = digitalTemplateComponentsPayload

  const { isAdmin, isBrandUser } = useCurrentUser()

  useIntercom({ hideWidget: !isBrandUser })

  // Tracks the page load event
  const { callbacks: { dispatchMixpanelEvent } } = useMixpanel()
  useEffect(() => {
    if (isBrandUser) dispatchMixpanelEvent('Component Styles Load')
  }, [])

  return (
    <>
      <Helmet>
        <title>Component Styles | Engage</title>
      </Helmet>

      <PageHeader
        activeTabBarItemKey="all"
        actionContent={(
          <Button
            buttonStyle="primaryCreate"
            onClick={() => showCreateOrEditComponentStyleModal({
              digitalTemplateComponent: { item_type: 'component_style' },
            })}
            size="medium"
          >
            Create
          </Button>
        )}
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        tabBarItems={[
          {
            href: '#/systemManager/componentStyles',
            icon: faGlobe,
            key: 'all',
            title: 'All Styles',
          },
        ]}
        title="Component Styles"
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && !hasDigitalTemplateComponents && <ComponentStylesBlankState />}

            {!loading && hasDigitalTemplateComponents && (
              <DashboardModule title="Component Styles">
                <Box flexDirection="column">
                  <Box flexDirection="column">
                    {filteredDigitalTemplateComponents.map(componentStyle => (
                      <ComponentStyle key={componentStyle.id} componentStyle={componentStyle} />
                    ))}
                  </Box>

                  <DashboardModule.LoadMoreFooter
                    callbacks={{ loadMore }}
                    canLoadMore={canLoadMore}
                    entityCount={filteredDigitalTemplateComponents.length}
                    loading={loading}
                  />
                </Box>
              </DashboardModule>
            )}
          </Columns.Main>

          <Columns.Sidebar>
            <PageFilterModule
              callbacks={{ resetFilters, updateFilters }}
              filterFields={buildPageFilterFields(isAdmin)}
              pageFilters={pageFilters}
              title="Filters"
            />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

PageContent.propTypes = {
  pageFilters: PropTypes.object.isRequired,
}

export default PageContent
