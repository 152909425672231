import { useContext } from 'react'
import { useSelector } from 'react-redux'

import { digObject } from '@campaignhub/javascript-utils'
import { useRealtimeSubscription, useSetState, useThunkDispatch } from '@campaignhub/react-hooks'

import RealtimeContext from '@contexts/realtimeContext'

import useCurrentUser from '@hooks/useCurrentUser'
import type { UserModel } from '@models/user'

import { isAdmin, isOrganizationUser } from '@functions/user'

import * as userActions from '@redux/modules/user'
import type { AppDispatch } from '@redux/store'

type ResultType = {
  filters: {
    support_user_id: number,
    user_id: Number,
  },
  payload: {
    action: string,
    support_token?: string,
    support_user: Partial<UserModel>,
    user: Partial<UserModel>,
  },
}

type DefaultState= {
  action: string,
  result?: ResultType | null,
  showModal: boolean,
}

const defaultState: DefaultState = {
  action: '',
  result: null,
  showModal: false,
}

const shouldShowModal = (action: string, currentUser: UserModel, result: ResultType, userRoleKey: string) => {
  const supportUser = digObject(result, 'payload.support_user', {})

  if (action === 'request'){
    return isOrganizationUser(userRoleKey)
  }

  if (action === 'supply'){
    return isAdmin(userRoleKey) && currentUser.id === supportUser.id
  }

  return false
}

const supplySupportToken = (user: UserModel, dispatch: AppDispatch, requestOptions: Record<string, any>) => {
  const { supplySupportToken: supplyFn } = userActions
  return dispatch(supplyFn(user, requestOptions))
}

type SetState = (state: Partial<typeof defaultState>) => void

type HandleResultParams = {
  currentUser: UserModel,
  result: ResultType,
  setState: SetState,
  userRoleKey: string,
}
const handleResult = (payload: HandleResultParams) => {
  const {
    currentUser, result, setState, userRoleKey,
  } = payload

  const action = digObject(result, 'payload.action')

  setState({
    action,
    showModal: shouldShowModal(action, currentUser, result, userRoleKey),
    result,
  })
}

type RequestOrSupplySupportTokenType = {
  userId?: number,
}

function useRequestOrSupplySupportToken(options: RequestOrSupplySupportTokenType = {}) {
  const { userId: suppliedUserId } = options || {}

  const [state, setState] = useSetState(defaultState)
  const { result } = state

  const realtimeContext = useContext(RealtimeContext)
  const { clientRef } = realtimeContext

  const dispatch = useThunkDispatch()

  const { updating } = useSelector(reduxState => reduxState.users)

  const { currentUser, userRoleKey } = useCurrentUser()
  const userId = suppliedUserId || currentUser.id

  useRealtimeSubscription(
    {
      apiKey: process.env.REACT_APP_ABLY_PUBLIC_KEY,
      callbacks: {
        success: (r: ResultType) => handleResult({
          currentUser,
          result: r,
          userRoleKey,
          setState,
        }),
      },
      channelName: 'support',
      clientRef,
      eventName: `user.${userId}`,
    },
    [currentUser.id, userId],
  )

  const user: UserModel = digObject(result, 'payload.user', {})
  const supportUser: UserModel = digObject(result, 'payload.support_user', {})
  const supportToken: string = digObject(result, 'payload.support_token')

  return {
    ...state,
    callbacks: {
      closeModal: () => setState({ showModal: false }),
      setState,
      supplySupportToken:
      (requestOptions: Record<string, any>) => supplySupportToken(supportUser, dispatch, requestOptions),
    },
    currentUser,
    isAdmin: isAdmin(userRoleKey),
    isOrganizationUser: isOrganizationUser(userRoleKey),
    supportUser,
    supportToken,
    user,
    updating,
  }
}

export default useRequestOrSupplySupportToken
