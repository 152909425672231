import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList } from '@fortawesome/pro-solid-svg-icons'

import { toggleArray } from '@campaignhub/javascript-utils'

import {
  Button, FormField, SidebarModal,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'
import useOrganizationsUser from '@hooks/useOrganizationsUser'

import FilterUsersContactsAndTeams from '@components/FilterUsersContactsAndTeams'
import ModalAddress from '@components/ModalComponents/ModalAddress'
import ModalUsers from '@components/ModalComponents/ModalUsers'

import localizedStrings from './localizedStrings'

const toggleCaseStudyUsers = (userId, entityState, setEntityState) => {
  const { user_ids } = entityState

  const updatedUsers = toggleArray(user_ids, userId)

  setEntityState({ user_ids: updatedUsers })
}

const CaseStudyCustomForm = (props) => {
  const {
    address,
    callbacks: { createOrEditAddress },
    entityState,
    setEntityState,
  } = props

  const {
    address_attributes,
    owner_id,
    user_ids,
  } = entityState

  const { currentUser, isCurrentUserAdminForOrganization } = useOrganizationsUser({ organization_id: owner_id })

  const hasAddressAttributes = !!Object.keys(address_attributes).length
  const hasAddress = hasAddressAttributes || !!Object.keys(address).length

  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      {/* Add Address */}
      {!hasAddress && (
        <Button
          buttonStyle="ghostEdit"
          icon={<FontAwesomeIcon icon={faList} />}
          onClick={() => createOrEditAddress(entityState, setEntityState)}
          size="medium"
          width="100%"
        >
          {strings.buttons?.enterAddress}
        </Button>
      )}

      {hasAddress && (
        <>
          <ModalAddress
            address={hasAddressAttributes ? address_attributes : address}
            callbacks={{ editAddress: () => createOrEditAddress(entityState, setEntityState) }}
          />
          {/* End Add Address */}

          <SidebarModal.Separator />

          {/* Add Users */}
          <FormField boxProps={{ marginTop: 'large' }} label={strings.formFieldLabels?.searchUsers}>
            <FilterUsersContactsAndTeams
              callbacks={{ selectItem: selected => toggleCaseStudyUsers(selected.id, entityState, setEntityState) }}
              selectedUserIds={user_ids}
            />
          </FormField>

          {!!user_ids.length && (
            <FormField boxProps={{ marginTop: 'large' }} label={strings.formFieldLabels?.caseStudyUsers}>
              <ModalUsers
                callbacks={{ deleteUser: userId => toggleCaseStudyUsers(userId, entityState, setEntityState) }}
                disableDeleteForUserIds={!isCurrentUserAdminForOrganization ? [currentUser.id] : []}
                userIds={user_ids}
              />
            </FormField>
          )}
          {/* End Add Users */}
        </>
      )}
    </>
  )
}

CaseStudyCustomForm.propTypes = {
  address: PropTypes.object,
  callbacks: PropTypes.object,
  entityState: PropTypes.object,
  setEntityState: PropTypes.func,
}

export default CaseStudyCustomForm
