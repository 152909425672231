import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useSetState, useThunkDispatch } from '@campaignhub/react-hooks'
import { digObject } from '@campaignhub/javascript-utils'

import useCurrentUser from '@hooks/useCurrentUser'
import useOrganizationSelector from '@hooks/useOrganizationSelector'
import useOrganizationShortcodeList from '@hooks/useOrganizationShortcodeList'
import { generateUrls } from '@hooks/useCustomFieldSet'

import * as customFieldActions from '@redux/modules/customField'

const createCustomFieldForOwner = (params) => {
  const {
    customFieldParams,
    customFieldSetEntityType,
    customFieldSetKey,
    dispatch,
    requestOptions,
    selectedOrganization,
  } = params

  const { createCustomFieldForOwner: createForOwnerFn } = customFieldActions
  const { options: customFieldOptions } = customFieldParams

  const customField = {
    ...customFieldParams,
    options: JSON.stringify(customFieldOptions),
  }

  const options = {
    brand_id: selectedOrganization.brand_id,
    custom_field_set_entity_type: customFieldSetEntityType,
    custom_field_set_key: customFieldSetKey,
    organization_id: selectedOrganization.id,
    ...requestOptions,
  }

  return dispatch(createForOwnerFn(customField, options)).then((response) => {
    const { success, data } = response
    if (success){
      const {
        entity: { owner_id },
      } = data

      const { editCustomFieldSetUrl } = generateUrls({ id: owner_id })

      return {
        success,
        data,
        redirectUrl: editCustomFieldSetUrl,
      }
    }

    return response
  })
}

const addCustomField = (params, setState) => {
  const {
    customFieldId, customFieldSetEntityType, customFieldSetId, tag,
  } = params

  return setState({
    customFieldId,
    customFieldSetEntityType,
    customFieldSetId,
    selectedTag: tag,
    showCreateOrEditCustomFieldModal: true,
  })
}

const defaultState = {
  customFieldId: null,
  customFieldSetEntityType: '',
  customFieldSetId: null,
  customFieldSetKey: '',
  selectedTag: '',
  showCreateOrEditCustomFieldModal: false,
}

function useEntityCustomField(options = {}){
  const {
    callbacks: { setupLocalCallbacks },
  } = options || {}

  const [state, setState] = useSetState(defaultState)
  const {
    customFieldId,
    customFieldSetEntityType,
    customFieldSetId,
    customFieldSetKey,
    selectedTag,
    showCreateOrEditCustomFieldModal,
  } = state

  const dispatch = useThunkDispatch()

  const { defaultProjectType, organizationType, selectedOrganization } = useOrganizationSelector()

  const organizationShortcodeListPayload = useOrganizationShortcodeList()
  const {
    callbacks: { loadOrganizationShortcodeList },
  } = organizationShortcodeListPayload

  const currentUserPayload = useCurrentUser()
  const { userRoleKey } = currentUserPayload

  const entities = useSelector(reduxState => reduxState.entities)
  const { customFields, customFieldSets } = entities

  const customField = customFields[customFieldId] || {}
  const customFieldSet = customFieldSets[customFieldSetId || customField.owner_id] || {}

  const { owner_id, owner_type } = customFieldSet

  const isOrganizationUser = userRoleKey === 'organization_user'
  const duplicateForOwner = !customFieldSet.id

  const customFieldSetIsBrandOwned = isOrganizationUser && owner_type === 'Brand' && owner_id === selectedOrganization.brand_id
  const customFieldSetIsSystemOwned = isOrganizationUser && owner_type === 'System'

  const initCustomField = {
    entity_type: customFieldSetEntityType,
    field_type: customField.field_type || 'input',
    owner_type: 'CustomFieldSet',
    owner_id: customFieldSetIsSystemOwned ? null : customFieldSet?.id,
    options: {
      tags: [selectedTag],
      input_type: digObject(customField, 'options.input_type', 'text'),
    },
  }

  const customFieldSetFilterType = customFieldSetEntityType === 'User' || customFieldSetEntityType === 'Organization' ? 'Organization' : 'Project'

  useEffect(() => {
    if (customFieldSet.id){
      setState({ customFieldSetKey: customFieldSet.key })
    }

    if (!customFieldSet.id){
      setState({
        customFieldSetKey: customFieldSetFilterType === 'Project' ? defaultProjectType.key : organizationType.key,
      })
    }
  }, [customFieldSet.id, customFieldSetFilterType])

  return {
    ...state,
    callbacks: {
      addCustomField: params => addCustomField(params, setState),
      closeModal: () => setState({ showCreateOrEditCustomFieldModal: false }),
      createCustomFieldForOwner: (customFieldParams, requestOptions) => createCustomFieldForOwner({
        customFieldParams,
        customFieldSetEntityType,
        customFieldSetKey,
        dispatch,
        requestOptions,
        selectedOrganization,
      }),
      loadOrganizationShortcodeList,
      setCustomFieldSetKey: key => setState({ customFieldSetKey: key }),
      ...setupLocalCallbacks(setState),
    },
    customField: customField.id ? customField : initCustomField,
    customFieldSet,
    customFieldSetFilterType,
    customFieldSetIsBrandOwned,
    customFieldSetIsSystemOwned,
    duplicateForOwner,
    isOrganizationUser,
    selectedOrganization,
    showModal: showCreateOrEditCustomFieldModal,
  }
}

export default useEntityCustomField
