import React from 'react'
import PropTypes from 'prop-types'

import { digObject } from '@campaignhub/javascript-utils'

import { Box, Text } from '@campaignhub/suit-theme'

import useChartData from '@hooks/useChartData'
import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const ReadPercentage = (props) => {
  const { chartDataKey, selectedDataPoint } = props

  const chartDataPayload = useChartData('benchmark_comparison', {
    chartDataKey,
    metricKey: 'average_proposal_read_percentage',
    performHttpRequests: false,
  })
  const { latestDataForMetricKey } = chartDataPayload

  const readPercentageForDataPoint = digObject(latestDataForMetricKey, selectedDataPoint, 0)

  const { strings } = useLocalization(localizedStrings)

  return (
    <Box alignItems="center" justifyContent="space-between" marginTop="large">
      <Text color="bodyFontLightColor" fontSize="small">
        {strings.readPercentage?.title || 'Average Read Percentage'}
      </Text>

      <Text fontSize="xxxlarge" color="darkGrey">
        {`${Math.round(readPercentageForDataPoint)}%`}
      </Text>
    </Box>
  )
}

ReadPercentage.propTypes = {
  chartDataKey: PropTypes.string.isRequired,
  selectedDataPoint: PropTypes.string.isRequired,
}

export default ReadPercentage
