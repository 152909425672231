import React from 'react'

/*
 * Use this context to provide data to a specific page
 * Use PdfDownloadContext.Provider in the page
 * Generally we use it to supply page specific params e.g. callbacks or props
 * that need to be available through deeply nested components
 */

export type PdfDownloadContextPayload = {
  [key: string]: any,
}

const data = {}

const PdfDownloadContext = React.createContext<PdfDownloadContextPayload>(data)

export default PdfDownloadContext
