import { ReactNode } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons'

import { Box, Text } from '@campaignhub/suit-theme'

interface DaashboardModuleNoticeProps {
  body: string,
  boxProps?: object,
  colors: {
    background: string,
    solid: string,
  },
  children?: ReactNode,
  icon: FontAwesomeIcon,
  title: string,
}

const DashboardModuleNotice = (props: DaashboardModuleNoticeProps) => {
  const {
    body,
    boxProps,
    colors,
    children,
    icon,
    title,
  } = props

  return (
    <Box
      alignItems="center"
      background="white"
      border="1px solid"
      borderRadius={5}
      borderColor="lineColor"
      display="inline-flex"
      flexDirection="row"
      justifyContent="center"
      padding="large"
      width="100%"
      {...boxProps}
    >
      <Box
        alignItems="center"
        background={colors.background}
        borderRadius={50}
        color="orange"
        flexShrink={0}
        height={50}
        justifyContent="center"
        marginRight="medium"
        width={50}
      >
        <Text fontSize="xlarge" color={colors.solid}>
          <FontAwesomeIcon icon={icon || faTriangleExclamation} />
        </Text>
      </Box>

      <Box flexDirection="column" fontSize="small">
        <Text>{title}</Text>

        <Text fontSize="xsmall" color="bodyFontLightColor" lineHeight="1.3" marginTop="medium">
          {body}
        </Text>
      </Box>

      {children}
    </Box>

  )
}

export default DashboardModuleNotice
