import PropTypes from 'prop-types'

import { faHistory } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Columns, DashboardModule, LoadingModule, PageHeader,
} from '@campaignhub/suit-theme'

import useActivities from '@hooks/useActivities'
import useLocalization from '@hooks/useLocalization'
import useVideoModules from '@hooks/useVideoModules'
import { useRelations as useProjectRelations } from '@hooks/useProject'

import Activity from '@components/Activity'
import VideoModule from '@components/VideoModule'

import ActivityBlankState from './components/ActivityBlankState'
import localizedStrings from './localizedStrings'

const Activities = (props) => {
  const { projectId } = props

  const useActivitiesPayload = useActivities({
    filters: {
      recipient_id: projectId,
      recipient_type: 'Project',
    },
    performHttpRequests: true,
  })

  const { filteredActivities, hasActivities, loading } = useActivitiesPayload

  const { organization } = useProjectRelations({ id: projectId })

  const videoModulePayload = useVideoModules({ key: 'activities' })
  const { videosPayload } = videoModulePayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      <PageHeader
        activeTabBarItemKey="activities"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        nestedNavigation
        tabBarItems={[
          {
            icon: faHistory,
            key: 'activities',
            title: strings.title || 'Project Activity',
          },
        ]}
        title={strings.title || 'Project Activity'}
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && !hasActivities && <ActivityBlankState />}

            {!loading && hasActivities && (
              <DashboardModule title={strings.title || 'Project Activity'}>
                <Box flexDirection="column" overflow="auto">
                  {filteredActivities.map(activity => (
                    <Activity key={activity.id} activity={activity} organizationId={organization.id} />
                  ))}
                </Box>
              </DashboardModule>
            )}
          </Columns.Main>

          <Columns.Sidebar>
            <VideoModule videosPayload={videosPayload} />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

Activities.propTypes = {
  projectId: PropTypes.number,
}

export default Activities
