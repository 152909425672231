import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import { digObject } from '@campaignhub/javascript-utils'
import { useDebounce, useSetState } from '@campaignhub/react-hooks'

import { AddressModel } from '@models/address'

import useOrganization from '@hooks/useOrganization'
import useOrganizationSelector from '@hooks/useOrganizationSelector'

export type CountryModel = {
  active: boolean,
  calling_code: string,
  code: string,
  currency_code: string,
  currency_symbol: string,
  id: number,
  tax_rate: number,
  title: string,
}

export type Countries = {
  [key: string]: CountryModel,
}

const valueKeys = {
  administrative_area_level_1: 'short_name',
  country: 'long_name',
  locality: 'long_name',
  postal_code: 'long_name',
  route: 'long_name',
  street_number: 'long_name',
  sublocality: 'long_name',
  subpremise: 'long_name',
}

const mapCountryId = (countryTitle: string, countries: Countries) => {
  const country_id = Object.values(countries).find(country => country.title === countryTitle)?.id

  return country_id
}

export const findAddressPartValue = (addressComponents: Partial<AddressModel>[], key: string, countries = {}) => {
  const part = addressComponents.find((addressComponent) => {
    const addressTypes = digObject(addressComponent, 'types', [])
    return addressTypes.includes(key)
  }) || {}

  // Map the key to engage value
  const valueKey = valueKeys[key] || {}

  if (key === 'country'){
    const countryTitle = part[valueKey] || ''
    return mapCountryId(countryTitle, countries)
  }

  return part[valueKey] || ''
}

type AddressComponent = {
  long_name: string,
  short_name: string,
  types: string[],
}

type Location = {
  lat: string,
  lng: string,
}

type ViewPort = {
  east: string,
  north: string,
  south: string,
  west: string,
}

export type PlaceType = {
  address_components: Record<string | number, AddressComponent>[],
  geometry: {
    location: Location,
    viewPort: ViewPort,
  },
}

export const mapGeocodedAddress = (place: PlaceType, placeId: number, countries: Countries[]) => {
  const { address_components, geometry } = place

  const addressParts = {
    country_id: findAddressPartValue(address_components, 'country', countries),
    google_place_id: placeId,
    latitude: geometry.location.lat(),
    longitude: geometry.location.lng(),
    postcode: findAddressPartValue(address_components, 'postal_code'),
    region_name: findAddressPartValue(address_components, 'sublocality')
    || findAddressPartValue(address_components, 'locality'),
    state_name: findAddressPartValue(address_components, 'administrative_area_level_1'),
    street_name: findAddressPartValue(address_components, 'route'),
    street_number: findAddressPartValue(address_components, 'street_number'),
    unit_number: findAddressPartValue(address_components, 'subpremise'),
  }

  return addressParts
}

const getPlacePredictions = (
  string: string,
  countryCode: string,
  autocompleteService: {},
  setState: ({ results }: {results: []})=> void,
) => {
  autocompleteService.current.getPlacePredictions(
    {
      input: string,
      componentRestrictions: { country: countryCode },
    },
    (predictions: []) => {
      setState({ results: predictions || [] })
    },
  )
}

export type SelectPlaceParams = {
  placeId: number,
  selectFn: () => void,
  placesService: Record<string, string | {}>,
  countries: Countries,
}

const selectPlace = (params: SelectPlaceParams) => {
  const {
    placeId, selectFn, placesService, countries,
  } = params

  placesService.current?.getDetails(
    {
      placeId,
      fields: ['address_components', 'geometry'],
    },
    (place: PlaceType) => selectFn(mapGeocodedAddress(place, placeId, countries)),
  )
}

const defaultState = {
  string: '',
  results: [],
}

function useGooglePlaces() {
  const [state, setState] = useSetState(defaultState)
  const { string, results } = state

  const { countries } = useSelector(reduxState => reduxState.entities)

  const autocompleteService = useRef()

  if (!autocompleteService.current){
    autocompleteService.current = new window.google.maps.places.AutocompleteService()
  }

  const placesService = useRef()

  if (!placesService.current){
    placesService.current = new window.google.maps.places.PlacesService(document.createElement('div'))
  }

  const { selectedOrganization } = useOrganizationSelector()
  const { country: organizationCountry } = useOrganization(selectedOrganization)

  const country: CountryModel = organizationCountry?.id
    ? organizationCountry
    : Object.values(countries).find(c => c.code === 'AU') || {}

  const debouncedString = useDebounce(string, 300)

  useEffect(() => {
    if (debouncedString.length >= 2){
      getPlacePredictions(debouncedString, country.code?.toLowerCase(), autocompleteService, setState)
    }

    // Clearing input
    if (!debouncedString.length){
      setState(defaultState)
    }
  }, [debouncedString])

  return {
    callbacks: {
      setState,
      selectPlace: (placeId: number, selectFn: () => void) => selectPlace({
        placeId, selectFn, placesService, countries,
      }),
    },
    country,
    results,
    string,
  }
}

export default useGooglePlaces
