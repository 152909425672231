const padding = {
  width: 375,
  height: 70,
}

function calculateOptimalZoom(templatePageHeight, templatePageWidth){
  let zoom = 1

  if (templatePageHeight && templatePageWidth){
    const windowSize = {
      width: window.innerWidth - padding.width,
      height: window.innerHeight - padding.height,
    }

    const ratio = {
      width: windowSize.width / templatePageWidth,
      height: windowSize.height / templatePageHeight,
    }

    const selectedRatio = ratio.width < ratio.height ? ratio.width : ratio.height

    const zooms = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.25, 2.5, 2.75, 3, 4, 5, 6, 10]
    zoom = zooms.reduce((prev, current) => (Math.abs(current - selectedRatio) < Math.abs(prev - selectedRatio) ? current : prev))

    // Drop a zoom level if the template is bigger than the screen
    if (windowSize.width < templatePageWidth * zoom || windowSize.height < templatePageHeight * zoom){
      const index = zooms.indexOf(zoom)
      zoom = index > 0 ? zooms[index - 1] : zooms[index]
    }
  }

  return zoom
}

export default calculateOptimalZoom
