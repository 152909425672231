import React from 'react'
import PropTypes from 'prop-types'

import VimeoPlayer from '@components/VimeoPlayer'
import YouTubePlayer from '@components/YouTubePlayer'

const VideoPlayer = (props) => {
  const { videoData: { videoId, videoProvider } } = props

  if (videoProvider === 'vimeo'){
    return <VimeoPlayer width="100%" height="100%" videoId={videoId} />
  }

  if (videoProvider === 'youtube'){
    return <YouTubePlayer width="100%" height="100%" videoId={videoId} />
  }

  return null
}

VideoPlayer.propTypes = {
  videoData: PropTypes.object,
}

export default VideoPlayer
