export default {
  default: {
    buttons: {
      create: 'Create Target Audiences',
      save: 'Save',
      toggleAll: 'Toggle All',
    },
    formFieldLabels: {
      moreOptions: 'More Options',
      targetAudiences: 'Available Audiences',
    },
    options: {
      brand: 'Brand',
      system: 'System',
      organization: 'Organisation',
    },
    sideBarModalHeader: {
      title: 'Target',
      titleSecondLine: 'Audiences',
    },
  },
}
