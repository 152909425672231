import { DateTime } from 'luxon'

interface DefaultState {
  brand_id: string,
  end_date: string | null,
  start_date: string | null,
  hidden?: null,
  organization_id?: string,
  status_key?: string,
  owner_id?: number,
  owner_type?: string,
  production_ready?: string,
  template_type_id?: string,
  user_id?: string,
  integration_platform_key?: string,
}

interface RequiredField {
  key: string,
}

interface ReportModel {
  defaultState: DefaultState,
  requiredFields: RequiredField[],
}

const startOfMonth = DateTime.now().startOf('month')
const startOfLastMonth = startOfMonth.minus({ months: 1 })

const endDate = startOfMonth.toISODate()
const startDate = startOfLastMonth.toISODate()

const reportModels: { [key: string]: ReportModel } = {
  client_admin_users_created: {
    defaultState: {
      brand_id: '',
      end_date: DateTime.now().toISODate(),
      hidden: null,
      organization_id: '',
      start_date: DateTime.now().minus({ years: 5 }).toISODate(),
    },
    requiredFields: [
      { key: 'end_date' },
      { key: 'start_date' },
    ],
  },
  users_created: {
    defaultState: {
      brand_id: '',
      end_date: endDate,
      hidden: null,
      organization_id: '',
      start_date: startDate,
    },
    requiredFields: [
      { key: 'end_date' },
      { key: 'start_date' },
    ],
  },
  users_with_multiple_organization: {
    defaultState: {
      brand_id: '',
      end_date: endDate,
      start_date: startDate,
    },
    requiredFields: [
      { key: 'end_date' },
      { key: 'start_date' },
    ],
  },
  organization_created: {
    defaultState: {
      brand_id: '',
      end_date: endDate,
      start_date: startDate,
    },
    requiredFields: [
      { key: 'end_date' },
      { key: 'start_date' },
    ],
  },
  digital_page_count_per_organization: {
    defaultState: {
      brand_id: '',
      end_date: endDate,
      start_date: startDate,
    },
    requiredFields: [
      { key: 'end_date' },
      { key: 'start_date' },
    ],
  },
  digital_page_count_per_user: {
    defaultState: {
      brand_id: '',
      end_date: endDate,
      organization_id: '',
      start_date: startDate,
    },
    requiredFields: [
      { key: 'end_date' },
      { key: 'start_date' },
    ],
  },
  digital_pages_status: {
    defaultState: {
      brand_id: '',
      end_date: endDate,
      organization_id: '',
      start_date: startDate,
      status_key: '',
    },
    requiredFields: [
      { key: 'end_date' },
      { key: 'start_date' },
      { key: 'status_key' },
    ],
  },
  digital_template_created: {
    defaultState: {
      brand_id: '',
      end_date: endDate,
      owner_id: 1,
      owner_type: 'System',
      production_ready: '',
      start_date: startDate,
      template_type_id: '',
      user_id: '',
    },
    requiredFields: [
      { key: 'end_date' },
      { key: 'start_date' },
    ],
  },
  enabled_integrations: {
    defaultState: {
      brand_id: '',
      end_date: endDate,
      integration_platform_key: '',
      organization_id: '',
      start_date: startDate,
    },
    requiredFields: [
      { key: 'end_date' },
      { key: 'integration_platform_key' },
      { key: 'start_date' },
    ],
  },
  template_use_per_organization: {
    defaultState: {
      brand_id: '',
      end_date: endDate,
      start_date: startDate,
    },
    requiredFields: [
      { key: 'brand_id' },
      { key: 'end_date' },
      { key: 'start_date' },
    ],
  },
}

export default reportModels
