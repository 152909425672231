import swal from 'sweetalert2'

import { FormField, SidebarModal, SidebarNoticeBox } from '@campaignhub/suit-theme'

import useAttachments from '@hooks/useAttachments'
import useDigitalTemplatePage from '@hooks/useDigitalTemplatePage'

import type { DigitalTemplatePageModel } from '@models/digitalTemplatePage'

import Attachment from './Attachment'

type RestoreLayoutParams = {
  attachmentId: number,
  restoreLayoutFn: (attachmentId: number) => void,
}

const confirmRestoreLayout = (params: RestoreLayoutParams) => {
  const { restoreLayoutFn, attachmentId } = params

  swal
    .fire({
      title: 'Restore Layout',
      text: 'Are you sure? This change cannot be undone',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, restore layout.',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        restoreLayoutFn(attachmentId)
      }
    })
}

type TemplatePageBackupsProps = {
  digitalTemplatePayload: {
    selectedDigitalTemplatePage: DigitalTemplatePageModel,
  },
}

const TemplatePageBackups = (props: TemplatePageBackupsProps) => {
  const {
    digitalTemplatePayload: { selectedDigitalTemplatePage },
  } = props

  const attachmentsPayload = useAttachments(
    {
      filters: {
        attachmentType: 'json_file',
        subjectType: 'DigitalTemplatePage',
        subjectId: selectedDigitalTemplatePage.id,
      },
      performHttpRequests: true,
    },
  )

  const { filteredAttachments, hasAttachments } = attachmentsPayload

  const {
    callbacks: { restoreLayoutFileFromAttachment: restoreLayoutFn },
  } = useDigitalTemplatePage(selectedDigitalTemplatePage)

  return (
    <SidebarModal.ExpandableSectionBox label="Template Backups">
      <FormField label={hasAttachments ? 'Layout Files' : 'No Saved Layout'} boxProps={{ marginTop: 'medium' }}>
        {!hasAttachments && (
          <SidebarNoticeBox.Paragraph>
            The layout file will save automatically when the template is approved for production
          </SidebarNoticeBox.Paragraph>
        )}

        {hasAttachments
          && filteredAttachments.map(attachment => (
            <Attachment
              callbacks={{
                restoreBackup: () => confirmRestoreLayout({ restoreLayoutFn, attachmentId: attachment.id }),
              }}
              attachment={attachment}
              key={attachment.id}
            />
          ))}
      </FormField>
    </SidebarModal.ExpandableSectionBox>
  )
}

export default TemplatePageBackups
