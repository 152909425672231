import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, ListItem, StatusBadge, Text,
} from '@campaignhub/suit-theme'

import { statusColor } from '@functions/status'

import { useRelations } from '@hooks/useAgreement'

const AgreementDetails = (props) => {
  const {
    callbacks: { editAgreement },
    agreementPayload: {
      agreement,
      agreement: { title },
    },
  } = props

  const { status } = useRelations(agreement)

  return (
    <ListItem boxProps={{ padding: 'large' }} onClick={editAgreement} showAngleIcon>
      <Box flexDirection="column">
        <Box justifyContent="center" flexDirection="column">
          <Text color="bodyFontLightColor" fontSize="small" marginBottom="small" variant="ellipsis">
            {title}
          </Text>
          <StatusBadge color={statusColor(status.key)} ghost text={status.title} />
        </Box>
      </Box>
    </ListItem>
  )
}

AgreementDetails.propTypes = {
  callbacks: PropTypes.shape({
    editAgreement: PropTypes.func,
  }).isRequired,
  agreementPayload: PropTypes.shape({
    agreement: PropTypes.object,
    status: PropTypes.object,
  }).isRequired,
}

export default AgreementDetails
