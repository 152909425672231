import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { toast } from 'react-toastify'

import { useModals, useSetState } from '@campaignhub/react-hooks'
import { MainContent, ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import useIntercom from '@hooks/useIntercom'
import useNumericParams from '@hooks/useNumericParams'

import CreateOrEditEventModal from '@modals/CreateOrEditEventModal'
import CreateOrEditEventGroupModal from '@modals/CreateOrEditEventGroupModal'
import CreateEventCalendarModal from '@modals/CreateEventCalendarModal'
import ManageCalendarBuilderModal from '@modals/ManageCalendarBuilderModal'

import useLocalization from '@hooks/useLocalization'

import useCalendarBuilder from './hooks/useCalendarBuilder'

import defaultRequestOptions from './defaultRequestOptions'

import EventCalendar from './components/EventCalendar'
import Header from './components/Header'

import localizedStrings from './localizedStrings'

const updateEventCalendar = (eventCalendar, updateFn, setState, strings) => {
  updateFn(eventCalendar, defaultRequestOptions.eventCalendar).then(({ success, errors }) => {
    if (!success){
      toast.warning(errors[0])
      return
    }

    setState({ showCreateOrEditEventCalendarModal: false })
    toast(strings.toast?.updateEventCalendar || 'Calendar Updated Successfully')
  })
}

const deleteEventCalendar = (deleteFn, setState, strings) => {
  deleteFn().then(({ success, errors, redirectUrl }) => {
    if (!success){
      toast.warning(errors[0])
      return
    }

    if (redirectUrl){
      window.location.href = redirectUrl
    }

    setState({ showCreateOrEditEventCalendarModal: false })
    toast(strings.toast?.deleteEventCalendar || 'Calendar Deleted Successfully')
  })
}

const createEvent = (event, createFn, setState, strings) => {
  createFn(event, defaultRequestOptions.event).then(({ success, errors }) => {
    if (!success){
      toast.warning(errors[0])
      return
    }

    setState({ showCreateOrEditEventModal: false })
    toast(strings.toast?.event?.created || 'Event Created Successfully')
  })
}

const createEventCalendar = (calendarParams, createFn, setState, strings) => {
  const requestOptions = calendarParams.saved_template
    ? defaultRequestOptions.eventCalendarTemplate
    : defaultRequestOptions.eventCalendar

  createFn(calendarParams, requestOptions).then(({ success, errors, redirectUrl }) => {
    if (!success){
      toast.warning(errors[0])
      return
    }

    if (redirectUrl){
      window.location.href = redirectUrl
    }

    setState({ showCreateEventCalendarModal: false })
    toast(strings.toast?.createTemplate || 'Template Created Successfully')
  })
}

const deleteEvent = (deleteFn, setState, strings) => {
  deleteFn().then(({ success, errors }) => {
    if (!success){
      toast.warning(errors[0])
      return
    }

    setState({ showCreateOrEditEventModal: false })
    toast(strings.toast?.event?.deleted || 'Event Deleted Successfully')
  })
}

const duplicateEvent = (duplicateFn, setState, strings) => {
  duplicateFn().then(({ success, errors }) => {
    if (!success){
      toast.warning(errors[0])
      return
    }

    setState({ showCreateOrEditEventModal: false })
    toast(strings.toast?.event?.duplicated || 'Event Duplcated Successfully')
  })
}

const updateEvent = (event, updateFn, setState, strings) => {
  updateFn(event, defaultRequestOptions.event).then(({ success, errors }) => {
    if (!success){
      toast.warning(errors[0])
      return
    }

    setState({ showCreateOrEditEventModal: false })
    toast(strings.toast?.event?.updated || 'Event Updated Successfully')
  })
}

const createEventGroup = (eventGroup, createFn, setState, strings) => {
  createFn(eventGroup, defaultRequestOptions.eventGroup).then(({ success, errors }) => {
    if (!success){
      toast.warning(errors[0])
      return
    }

    setState({ showCreateOrEditEventGroupModal: false })
    toast(strings.toast?.eventGroup?.created || 'Group Created Successfully')
  })
}

const deleteEventGroup = (deleteFn, setState, strings) => {
  deleteFn().then(({ success, errors }) => {
    if (!success){
      toast.warning(errors[0])
      return
    }

    setState({ showCreateOrEditEventGroupModal: false })
    toast(strings.toast?.eventGroup?.deleted || 'Group Deleted Successfully')
  })
}

const updateEventGroup = (eventGroup, updateFn, setState, strings) => {
  updateFn(eventGroup, defaultRequestOptions.eventGroup).then(({ success, errors }) => {
    if (!success){
      toast.warning(errors[0])
      return
    }

    setState({ showCreateOrEditEventGroupModal: false })
    toast(strings.toast?.eventGroup?.updated || 'Group Updated Successfully')
  })
}

const callbacks = (component, setState, strings) => {
  const componentCallbacks = {
    CreateEventCalendarModal: {
      createEventCalendar: (params, createFn) => createEventCalendar(params, createFn, setState, strings),
      closeModal: () => setState({ showCreateEventCalendarModal: false }),
    },
    CreateOrEditEventModal: {
      closeModal: () => setState({ showCreateOrEditEventModal: false }),
      createEvent: (params, createFn) => createEvent(params, createFn, setState, strings),
      deleteEvent: deleteFn => deleteEvent(deleteFn, setState, strings),
      duplicateEvent: duplcateFn => duplicateEvent(duplcateFn, setState, strings),
      updateEvent: (params, updateFn) => updateEvent(params, updateFn, setState, strings),
    },
    CreateOrEditEventGroupModal: {
      closeModal: () => setState({ showCreateOrEditEventGroupModal: false }),
      createEventGroup: (params, createFn) => createEventGroup(params, createFn, setState, strings),
      deleteEventGroup: deleteFn => deleteEventGroup(deleteFn, setState, strings),
      updateEventGroup: (params, updateFn) => updateEventGroup(params, updateFn, setState, strings),
    },
    ManageCalendarBuilderModal: {
      closeModal: () => setState({ showManageCalendarBuilderModal: false }),
      updateEventCalendar: (params, updateFn) => updateEventCalendar(params, updateFn, setState, strings),
      deleteEventCalendar: deleteFn => deleteEventCalendar(deleteFn, setState, strings),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  showCreateEventCalendarModal: false,
  showCreateOrEditEventGroupModal: false,
  showCreateOrEditEventModal: false,
  showManageCalendarBuilderModal: false,
}

const CalendarBuilder = () => {
  const [state, setState] = useSetState(defaultState)
  const {
    showCreateOrEditEventGroupModal, showCreateOrEditEventModal, showManageCalendarBuilderModal, showCreateEventCalendarModal,
  } = state

  const { eventCalendarId } = useNumericParams()

  const modalContext = useModals()
  const {
    callbacks: { registerModal, setModalData },
  } = modalContext

  const calendarBuilderPayload = useCalendarBuilder({
    callbacks: {
      showCreateOrEditEventGroupModal: (payload) => {
        setModalData('CreateOrEditEventGroupModal', payload)
        setState({ showCreateOrEditEventGroupModal: true })
      },
      showCreateOrEditEventModal: (payload) => {
        setModalData('CreateOrEditEventModal', payload)
        setState({ showCreateOrEditEventModal: true })
      },
      showCreateEventCalendarModal: (payload) => {
        setModalData('CreateEventCalendarModal', payload)
        setState({ showCreateEventCalendarModal: true })
      },
    },
    eventCalendarId,
  })

  const { eventCalendarPayload: { isTemplate }, isMobile } = calendarBuilderPayload

  useEffect(() => {
    // Wait for the register function otherwise the modal
    // doesn't get added to the open modals array
    if (!isMobile && registerModal){
      setState({
        showManageCalendarBuilderModal: true,
      })
    }
  }, [isMobile])

  useIntercom({ horizontalPadding: 400 })

  const { strings } = useLocalization(localizedStrings)

  const title = `${strings.helmet?.title || 'Calendar Builder'} | ${strings.helmet?.titleEngage || 'Engage'}`

  return (
    <div id="main-stage" style={{ position: 'relative' }}>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <PageContext.Provider value={calendarBuilderPayload}>
        <ModalContext.Provider value={modalContext}>
          <MainContent
            offset={{ left: 0, top: 71 }}
            width={!isMobile ? 'calc(100% - 375px)' : '100%'}
          >
            <Header calendarBuilderPayload={calendarBuilderPayload} />

            <EventCalendar
              baseModalOpen={showManageCalendarBuilderModal}
              calendarBuilderPayload={calendarBuilderPayload}
            />

            <CreateOrEditEventModal
              callbacks={callbacks('CreateOrEditEventModal', setState, strings)}
              event={{ event_calendar_id: eventCalendarId }}
              isTemplate={isTemplate}
              showModal={showCreateOrEditEventModal}
            />

            <CreateOrEditEventGroupModal
              callbacks={callbacks('CreateOrEditEventGroupModal', setState, strings)}
              eventGroup={{ event_calendar_id: eventCalendarId }}
              isTemplate={isTemplate}
              showModal={showCreateOrEditEventGroupModal}
            />

            <ManageCalendarBuilderModal
              callbacks={callbacks('ManageCalendarBuilderModal', setState, strings)}
              showModal={showManageCalendarBuilderModal}
            />

            <CreateEventCalendarModal
              callbacks={callbacks('CreateEventCalendarModal', setState, strings)}
              showModal={showCreateEventCalendarModal}
            />
          </MainContent>
        </ModalContext.Provider>
      </PageContext.Provider>
    </div>
  )
}

export default CalendarBuilder
