import { useContext } from 'react'
import { useSelector } from 'react-redux'

import { useRealtimeSubscription, useThunkDispatch } from '@campaignhub/react-hooks'
import { getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'

import RealtimeContext from '@contexts/realtimeContext'

import useAgreement from '@hooks/useAgreement'

import { docusignStatusFromParams } from '@functions/integrationPlatforms/docusign'

import * as agreementActions from '@redux/modules/agreement'

const getMappedStatusKey = (paramsPayload, platformKey) => {
  if (platformKey === 'docusign') return docusignStatusFromParams(paramsPayload)

  return null
}

const buildTemporaryStatuses = (params) => {
  const {
    documentRecipientId, electronicSigningPlatformKey, queryParamsPayload, statuses,
  } = params

  const mappedStatusKey = getMappedStatusKey(queryParamsPayload, electronicSigningPlatformKey)
  const temporaryStatus = Object.values(statuses).find(status => status.key === mappedStatusKey)

  if (temporaryStatus){
    return { [documentRecipientId]: temporaryStatus }
  }

  return {}
}

const getOptions = agreementId => ({
  broadcast_channel: 'agreements',
  broadcast_event: `agreement.${agreementId}.job.complete`,
})

const hydrateAgreement = (agreementId, result, dispatch) => {
  const { hydrateAgreement: hydrateFn } = agreementActions

  const {
    filters: { agreement_id },
    payload,
  } = result

  if (agreement_id === agreementId){
    dispatch(hydrateFn(payload))
  }
}

function useAgreementProgress(initAgreement = {}){
  const queryParamsPayload = getQueryParamsFromHash()

  const { agreementId, documentRecipientId } = parsePermittedQueryParams(queryParamsPayload, [
    'agreementId',
    'documentRecipientId',
  ])

  const { statuses } = useSelector(reduxState => reduxState.entities)

  const { agreement, electronicSigningPlatformKey } = useAgreement({ id: agreementId || initAgreement.id })

  const recipientTemporaryStatuses = buildTemporaryStatuses({
    documentRecipientId,
    electronicSigningPlatformKey,
    queryParamsPayload,
    statuses,
  })

  const realtimeContext = useContext(RealtimeContext)
  const { clientRef } = realtimeContext

  const dispatch = useThunkDispatch()

  const broadcastOptions = getOptions(agreementId)
  const { broadcast_channel, broadcast_event } = broadcastOptions

  useRealtimeSubscription(
    {
      apiKey: process.env.REACT_APP_ABLY_PUBLIC_KEY,
      callbacks: {
        success: result => hydrateAgreement(agreementId, result, dispatch),
      },
      channelName: broadcast_channel,
      clientRef,
      eventName: broadcast_event,
    },
    [agreementId],
  )

  return {
    agreement,
    recipientTemporaryStatuses,
  }
}

export default useAgreementProgress
