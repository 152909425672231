import { digObject } from '@campaignhub/javascript-utils'

import { useRelations } from '@hooks/useProject'

import type { ProjectModel } from '@models/project'

import { projectColours, projectLetterColorKeys } from '@functions/project'

const getProjectColour = (letter: string) => {
  const projectLetterColour = projectLetterColorKeys[letter] || 'yellow'
  const projectColour = projectColours[projectLetterColour]

  return projectColour
}

function useProjectTheme(project: ProjectModel) {
  const { address, projectType } = useRelations(project)

  const streetName = digObject(address, 'street_name', '')
  const streetNameFirstLetter = streetName.substring(0, 1)?.toLowerCase()

  const projectTypeKey: string = projectType.key

  return {
    colors: getProjectColour(streetNameFirstLetter),
    projectTypeKey,
  }
}

export default useProjectTheme
