import React from 'react'

import { BlankState } from '@campaignhub/suit-theme'

import blankStateImage from './assets/user-tiles.svg'

const UsersBlankState = () => (
  <BlankState imageUrl={blankStateImage}>
    <BlankState.Title>No Users</BlankState.Title>
    <BlankState.Paragraph>Try adjusting your filters to return more results.</BlankState.Paragraph>
  </BlankState>
)

export default UsersBlankState
