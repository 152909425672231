import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  digObject,
  matchFilterKey,
  matchFilterNumber,
  matchFilterString,
  sortArrayBy,
} from '@campaignhub/javascript-utils'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'

import defaultRequestOptions from '@sections/Client/defaultRequestOptions'

const watchEntityKeys = ['agreements']

function useAgreements(options = {}){
  const {
    entityKey,
    performHttpRequests,
    subjectId,
    subjectType,
    string,
    status: filterStatusKey,
    subject_id: filterSubjectId,
  } = options

  const requestOptions = {
    ...defaultRequestOptions.agreement,
    entityKey,
    subject_id: subjectId,
    subject_type: subjectType,
  }

  useReduxAction('agreements', 'loadAgreements', requestOptions, [performHttpRequests, JSON.stringify(options)], {
    shouldPerformFn: ({ errors, loadedForKeys, loading }) => {
      if (entityKey) return performHttpRequests && !loadedForKeys.includes(entityKey)
      return performHttpRequests && !loading && !errors.length
    },
  })

  const {
    updatedEntities: { agreements: agreementsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { agreements, statuses } = useSelector(reduxState => reduxState.entities)

  const loading = useSelector(state => state.agreements.loading)

  const filteredAgreements = useMemo(() => {
    const filtered = Object.values(agreements).filter((agreement) => {
      const {
        status_id, subject_id, subject_type, title,
      } = agreement

      const status = statuses[status_id] || {}

      const subjectIdMatch = matchFilterNumber(subject_id, subjectId)
      const ownerIdMatch = matchFilterNumber(subject_id, filterSubjectId)
      const subjectTypeMatch = matchFilterKey(subject_type, subjectType)
      const stringMatch = title ? matchFilterString(title, string) : true
      const statusMatch = filterStatusKey && filterStatusKey !== 'all' ? status.key === filterStatusKey : true

      return ownerIdMatch && statusMatch && stringMatch && subjectIdMatch && subjectTypeMatch
    })

    return sortArrayBy(filtered, 'desc', 'id')
  }, [agreementsUpdatedAt, JSON.stringify(options)])

  const groupedAgreements = useMemo(() => {
    const grouped = filteredAgreements.reduce(
      (acc, agreement) => {
        const createFormPlatformKey = digObject(agreement, 'data.create_form_platform')
        const createFormExternalId = digObject(agreement, `data.external_ids.${createFormPlatformKey}`)

        if (createFormExternalId){
          acc.externalDocuments.push(agreement)
        }

        if (!createFormExternalId){
          acc.agreements.push(agreement)
        }

        return acc
      },
      { agreements: [], externalDocuments: [] },
    )

    return grouped
  }, [agreementsUpdatedAt, JSON.stringify(options)])

  const agreementGroupKeys = Object.keys(groupedAgreements)

  return {
    agreementGroupKeys,
    filteredAgreements,
    groupedAgreements,
    hasAgreements: !!filteredAgreements.length,
    loading,
  }
}

export default useAgreements
