import React from 'react'
import PropTypes from 'prop-types'

import { SidebarModal } from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaste } from '@fortawesome/pro-light-svg-icons'

import PageItem from '@modals/AddDigitalTemplatePageItemModal/components/PageItem'

const Clipboard = (props) => {
  const { copiedCanvasItem } = props

  return (
    <SidebarModal.ExpandableSectionBox defaultOpen label="Clipboard">
      <PageItem
        dataAttributes={{
          'data-entity-id': copiedCanvasItem.id,
          'data-entity-type': copiedCanvasItem.entityType,
        }}
        fullWidth
        icon={<FontAwesomeIcon icon={faPaste} />}
        itemType={copiedCanvasItem.item_type}
        title={`Copied Item (${copiedCanvasItem.item_type} ${copiedCanvasItem.id})`}
      />
    </SidebarModal.ExpandableSectionBox>
  )
}

Clipboard.propTypes = {
  copiedCanvasItem: PropTypes.object.isRequired,
}

export default Clipboard
