import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendar, faCog, faEyeSlash, faPencil,
} from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, IconWithMenu, Link, ListItem, Text,
} from '@campaignhub/suit-theme'

import useEventCalendar from '@hooks/useEventCalendar'
import useLocalization from '@hooks/useLocalization'
import useManageEntityUserPermissions from '@hooks/useManageEntityUserPermissions'

import localizedStrings from './localizedStrings'

const EventCalendarTemplate = (props) => {
  const {
    eventCalendarTemplate,
    eventCalendarTemplate: {
      event_count,
      event_group_count,
      title,
    },
  } = props

  const {
    callbacks: { updateEventCalendar: updateFn },
    urls: { editEventCalendarUrl },
  } = useEventCalendar(eventCalendarTemplate)

  const manageTemplateUserPayload = useManageEntityUserPermissions({
    entity: eventCalendarTemplate,
    updateFn,
  })

  const { callbacks: { manageEntityUserPermission } } = manageTemplateUserPayload

  const {
    callbacks: { formatCount }, strings,
  } = useLocalization(localizedStrings)

  return (
    <ListItem
      boxProps={{
        as: 'a', borderBottom: '1px solid', padding: 'large', color: 'inherit',
      }}
      disableHover
    >
      <Box
        alignItems="center"
        backgroundColor="backgroundColor"
        borderRadius={5}
        color="bodyFontLightColor"
        flexShrink={0}
        height={40}
        justifyContent="center"
        position="relative"
        width={40}
      >
        <FontAwesomeIcon icon={faCalendar} />
      </Box>

      <Box flexDirection="column" justifyContent="center" marginLeft="large" minWidth="0">
        <Text fontSize="small">{title}</Text>

        <Text color="bodyFontLightColor" fontSize="xsmall" paddingTop="medium">
          {formatCount('events', { count: event_count })}{formatCount('eventGroups', { count: event_group_count })}
        </Text>
      </Box>

      <Box flexDirection="row" justifyContent="flex-end" marginRight="0" minWidth="0">
        <IconWithMenu
          icon={(
            <Button
              buttonStyle="secondaryUtility"
              icon={<FontAwesomeIcon icon={faCog} />}
              size="medium"
              height={37}
            />
        )}
          style={{ marginLeft: 'auto', width: 'auto' }}
        >
          <IconWithMenu.Menu closeOnClick listStyle={{ right: '-7px' }}>
            <Link href={editEventCalendarUrl} key="edit">
              <FontAwesomeIcon icon={faPencil} /> {strings.editEventCalendarTemplate || 'Edit Event Calendar Template' }
            </Link>

            <Link
              key="permissions"
              onClick={() => manageEntityUserPermission()}
            >
              <FontAwesomeIcon icon={faEyeSlash} /> {strings.permissions || 'Permissions'}
            </Link>
          </IconWithMenu.Menu>
        </IconWithMenu>
      </Box>
    </ListItem>
  )
}

EventCalendarTemplate.propTypes = {
  eventCalendarTemplate: PropTypes.object.isRequired,
}

export default EventCalendarTemplate
