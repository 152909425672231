import { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, FormField, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import { digObject, stringToKey } from '@campaignhub/javascript-utils'

import EntitySelector from '@components/EntitySelector'
import EntitySelectBox from '@components/EntitySelectBox'
import ModalImageUpload from '@components/ModalComponents/ModalImageUpload'

import defaultRequestOptions from '@sections/Client/packs/Admin/defaultRequestOptions'

import useCurrentUser from '@hooks/useCurrentUser'
import useImage from '@hooks/useImage'
import useLocalization from '@hooks/useLocalization'
import useTargetAudience, { useTargetAudienceForm } from '@hooks/useTargetAudience'

import localizedStrings from './localizedStrings'

const confirmDelete = (params) => {
  const { deleteFn, props, strings } = params

  const {
    callbacks: { closeModal, deleteTargetAudience },
  } = props

  const targetAudiencePayload = {
    callbacks: {
      action: deleteFn,
      afterAction: closeModal,
    },
    toastText: strings.toast?.targetAudienceDeleted || 'Target Audience Deleted Successfully',
  }

  swal
    .fire({
      title: strings.sweetAlert?.delete?.title || 'Delete Target Audience?',
      text: strings.sweetAlert?.delete?.text || 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: strings.sweetAlert?.delete?.confirmButton || 'Yes, delete it.',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        deleteTargetAudience(targetAudiencePayload)
      }
    })
}

const confirmDeleteFromProjects = (params) => {
  const { deleteFromProjectsFn, props, strings } = params
  const {
    callbacks: { closeModal, deleteFromProjects },
  } = props

  const targetAudiencePayload = {
    callbacks: {
      action: deleteFromProjectsFn,
      afterAction: closeModal,
    },
    toastText: strings.toast?.deletedFromProjects || 'Target Audience Removed from Projects',
  }

  swal
    .fire({
      title: strings.sweetAlert?.deleteFromProjects?.title || 'Remove from Projects?',
      text: strings.sweetAlert?.deleteFromProjects?.text || 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: strings.sweetAlert?.deleteFromProjects?.confirmButton || 'Yes, remove it.',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        deleteFromProjects(targetAudiencePayload)
      }
    })
}

const createOrUpdateTargetAudience = (params) => {
  const {
    createFn, createOrEditTargetAudience, entityState, props, strings, updateFn,
  } = params

  const {
    callbacks: { closeModal, createTargetAudience, updateTargetAudience },
  } = props

  const toastText = entityState.id
    ? strings.toast?.targetAudienceUpdated || 'Target Audience Updated Successfully'
    : strings.toast?.targetAudienceCreated || 'Target Audience Created Successfully'

  const actionFn = entityState.id ? updateFn : createFn
  const afterActionCallback = data => (entityState.id ? closeModal() : createOrEditTargetAudience({ targetAudience: data?.entity }))

  const targetAudiencePayload = {
    callbacks: {
      action: actionFn,
      afterAction: ({ response: { data } }) => afterActionCallback(data),
    },
    entityParams: entityState,
    requestOptions: defaultRequestOptions.targetAudience,
    toastText,
  }

  if (entityState.id) return updateTargetAudience(targetAudiencePayload)
  createTargetAudience(targetAudiencePayload)
}

const CreateOrEditTargetAudienceModal = (props) => {
  const { callbacks, modalKey, showModal } = props

  const { isAdmin, isOrganizationUser } = useCurrentUser({ performHttpRequests: true })

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const targetAudiencePayload = digObject(modalData, `${modalKey}`, {})
  const { targetAudience } = targetAudiencePayload

  const {
    callbacks: {
      createOrEditTargetAudience,
      createTargetAudience: createFn,
      deleteFromProjects: deleteFromProjectsFn,
      deleteTargetAudience: deleteFn,
      updateTargetAudience: updateFn,
    },
    creating,
    defaultImage,
    updating,
  } = useTargetAudience(targetAudience)

  const {
    callbacks: { deleteImage },
    deleting,
  } = useImage(defaultImage)

  const defaultImageUrl = digObject(defaultImage, 'sizes.thumb_660x360', '')

  const targetAudienceForm = useTargetAudienceForm(targetAudience)

  const {
    entityState,
    entityState: {
      description, key, organization_type_id, owner_id, owner_type, title,
    },
    errors,
    handlers,
    saveEnabled,
    setEntityState,
  } = targetAudienceForm

  const { brands } = useSelector(reduxState => reduxState.entities)
  const brand = owner_type === 'Brand' ? brands[owner_id] : {}

  useEffect(() => {
    if (brand?.id){
      setEntityState({ organization_type_id: brand?.organization_type_id })
    }
  }, [brand?.id])

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        title={entityState.id ? strings.updateTitle || 'Update' : strings.createTitle || 'Create'}
        titleSecondLine={strings.titleSecondLine || 'Target Audience'}
      />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          {isAdmin && (
            <>
              <FormField
                direction="column"
                boxProps={{ marginBottom: 'large' }}
                label={strings.formFieldLabels?.ownerType || 'Owner Type'}
              >
                <select name="owner_type" value={owner_type} {...handlers} data-validate-field-on="change">
                  <option value="System">{strings.formFieldLabels?.system || 'System'}</option>
                  <option value="Brand">{strings.formFieldLabels?.brand || 'Brand'}</option>
                </select>
              </FormField>

              {owner_type === 'Brand' && (
                <EntitySelector
                  boxProps={{ marginBottom: 'large' }}
                  callbacks={{
                    selectItem: selectedBrand => setEntityState({
                      owner_id: selectedBrand?.id,
                      owner_type: 'Brand',
                    }),
                  }}
                  entityKey="brands"
                  selectedItemId={owner_id}
                />
              )}
              {owner_type === 'System' && (
                <FormField
                  direction="column"
                  boxProps={{ marginBottom: 'large' }}
                  label={strings.formFieldLabels?.organizationType || 'Organization Type'}
                >
                  <EntitySelectBox
                    entityKey="organizationTypes"
                    name="organization_type_id"
                    sortDirection="asc"
                    sortKey="title"
                    value={organization_type_id}
                    {...handlers}
                  />
                </FormField>
              )}
            </>
          )}
          <FormField direction="column" errorMessage={errors.title} label={strings.formFieldLabels?.title || 'Title'}>
            <input
              data-validate-field-on="change"
              name="title"
              placeholder={strings.formFieldLabels?.titlePlaceholder || 'Title'}
              type="text"
              value={title}
              onBlur={e => setEntityState({ key: stringToKey(e.target.value) })}
              onChange={e => setEntityState({ title: e.target.value })}
            />
          </FormField>

          <FormField direction="column" boxProps={{ marginTop: 'large' }} label={strings.formFieldLabels?.key || 'Key'}>
            <input
              name="key"
              placeholder={strings.formFieldLabels?.keyPlaceholder || 'Key'}
              type="text"
              value={key}
              {...handlers}
            />
          </FormField>

          <FormField direction="column" label={strings.formFieldLabels?.description} marginTop="large">
            <textarea
              name="description"
              placeholder={strings.formFieldLabels?.descriptionPlaceholder || 'Description'}
              type="text"
              value={description}
              style={{ height: 120 }}
              {...handlers}
            />
          </FormField>

          {entityState.id && (
            <ModalImageUpload
              imageLabel={strings.targetImageLabel || 'Target Audience Image'}
              imageSizes={['thumb_660x360', 'thumb_200x120']}
              imageUrl={defaultImageUrl}
              ownerId={targetAudience.owner_id}
              ownerType="Organization"
              subjectId={targetAudience.id}
              subjectType="TargetAudience"
            />
          )}
        </Box>

        {entityState.id && (
          <FormField
            direction="column"
            label={strings.formFieldLabels?.moreOptions || 'More Options'}
            marginTop="large"
          >
            {!!defaultImage.id && <ModalImageUpload.ReplaceImage loading={deleting} onClick={() => deleteImage()} />}
            {isOrganizationUser && (
              <Button
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faTrashAlt} />}
                marginBottom="medium"
                onClick={() => confirmDeleteFromProjects({ deleteFromProjectsFn, props, strings })}
                size="medium"
                width="100%"
              >
                {strings.removeFromProjects || 'Remove from Projects'}
              </Button>
            )}

            <Button
              buttonStyle="secondaryUtility"
              icon={<FontAwesomeIcon icon={faTrashAlt} />}
              onClick={() => confirmDelete({ deleteFn, props, strings })}
              size="medium"
              width="100%"
            >
              {strings.deleteTargetAudience || 'Delete'}
            </Button>
          </FormField>
        )}
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={creating || updating}
          onClick={() => createOrUpdateTargetAudience({
            entityState,
            props,
            createFn,
            updateFn,
            createOrEditTargetAudience,
            strings,
          })}
          size="large"
        >
          {strings.saveTargetAudience || 'Save'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

CreateOrEditTargetAudienceModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

CreateOrEditTargetAudienceModal.defaultProps = {
  modalKey: 'CreateOrEditTargetAudienceModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <CreateOrEditTargetAudienceModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
