import PropTypes from 'prop-types'

import { Box, Text } from '@campaignhub/suit-theme'

import { digObject, snakeToTitleCase } from '@campaignhub/javascript-utils'

const ToolTip = (props) => {
  const { dataKeys, active, payload } = props

  if (active && payload?.length){
    const itemPayload = payload[0]?.payload || {}

    return (
      <Box fontSize="xxsmall" flexDirection="column" lineHeight={1.3} padding="medium" variant="white">
        <Text>{payload[0].payload?.name}</Text>

        {dataKeys.map((key) => {
          const itemValue = digObject(itemPayload, key, '')
          const itemUnit = digObject(itemPayload, 'unit', '')

          return (
            <Text color="bodyFontLightColor" key={key}>
              {`${snakeToTitleCase(key)} : ${itemValue}${itemUnit}`}
            </Text>
          )
        })}
      </Box>
    )
  }

  return null
}

ToolTip.propTypes = {
  active: PropTypes.bool,
  dataKeys: PropTypes.array,
  payload: PropTypes.array,
}

export default ToolTip
