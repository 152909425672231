import PropTypes from 'prop-types'

import {
  Box, Button, DashboardModule, Link,
} from '@campaignhub/suit-theme'

import useAttachments from '@hooks/useAttachments'
import useLocalization from '@hooks/useLocalization'
import useOrganizationSelector from '@hooks/useOrganizationSelector'

import defaultRequestOptions from '@sections/Client/packs/Project/defaultRequestOptions'

import Document from './Document'
import localizedStrings from './localizedStrings'

const Documents = (props) => {
  const {
    callbacks: { manageProjectAttachments },
    project,
  } = props

  const entityKey = `Project${project.id}`

  const { selectedOrganization } = useOrganizationSelector()

  const attachmentsPayload = useAttachments(
    {
      entityKey,
      filters: {
        ownerId: selectedOrganization.id,
        ownerType: selectedOrganization.type,
        subjectId: project.id,
        subjectType: 'Project',
      },
      performHttpRequests: true,
      requestOptions: defaultRequestOptions.attachment,
    },
  )

  const { filteredAttachments, hasAttachments } = attachmentsPayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <DashboardModule
      headerRight={(
        <Link onClick={manageProjectAttachments} textProps={{ fontSize: 'small' }}>
          {strings.manage || 'Manage'}
        </Link>
      )}
      title={strings.title || 'Documents'}
    >
      <Box flexDirection="column" maxHeight={415} overflow="auto">
        {filteredAttachments.map(attachment => (
          <Document key={attachment.id} attachment={attachment} project={project} />
        ))}

        {!hasAttachments && (
          <Box padding="large">
            <Button buttonStyle="secondaryUtility" onClick={manageProjectAttachments} size="medium">
              {strings.addButton || 'Add Document'}
            </Button>
          </Box>
        )}
      </Box>
    </DashboardModule>
  )
}

Documents.propTypes = {
  callbacks: PropTypes.shape({
    manageProjectAttachments: PropTypes.func.isRequired,
  }),
  project: PropTypes.object.isRequired,
}

export default Documents
