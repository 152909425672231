import useContact from '@hooks/useContact'
import useUser from '@hooks/useUser'

import { digObject } from '@campaignhub/javascript-utils'

const editRecipientEntity = (entityType, createOrEditContact, editUser) => {
  if (entityType === 'contact'){
    return createOrEditContact({})
  }

  if (entityType === 'user'){
    return editUser()
  }

  return null
}

function useRecipient(recipient = {}){
  const {
    contact_id, mobile, title, type, user_id,
  } = recipient

  const initContact = contact_id ? { id: contact_id } : {}
  const { callbacks: { createOrEditContact }, contact } = useContact(initContact)

  const initUser = user_id ? { id: user_id } : {}
  const { callbacks: { editUser }, defaultImage, user } = useUser(initUser)

  const entity = contact_id ? contact : user
  const { full_name, mobile: entityMobile } = entity

  const defaultImageUrl = digObject(defaultImage, 'sizes.thumb_90x90', '')
  const mobileNumber = entity.id ? entityMobile : mobile
  const recipientTitle = entity.id ? full_name : title

  const showEditIcon = type === 'contact' || type === 'user'

  return {
    callbacks: {
      editRecipient: () => editRecipientEntity(type, createOrEditContact, editUser),
    },
    imageUrl: defaultImageUrl,
    mobileNumber,
    showEditIcon,
    title: recipientTitle,
    type,
  }
}

export default useRecipient
