import { Box, ListItem, Text } from '@campaignhub/suit-theme'

import useEventCalendar from '@hooks/useEventCalendar'

import useLocalization from '@hooks/useLocalization'

import type { EventCalendarModel } from '@models/types'

import localizedStrings from './localizedStrings'

interface LargeListProps {
  eventCalendar: EventCalendarModel,
}

const LargeList = (props: LargeListProps) => {
  const {
    eventCalendar,
    eventCalendar: { title, id, events },
  } = props

  const {
    urls: { editEventCalendarUrl },
  } = useEventCalendar(eventCalendar)

  const {
    callbacks: { formatCount },
    strings,
  } = useLocalization(localizedStrings)

  return (
    <ListItem
      boxProps={{
        as: 'a',
        alignItems: 'center',
        borderBottom: '1px solid',
        padding: 'large',
      }}
      href={`${editEventCalendarUrl}?event_id=${id}`}
      key={id}
      showAngleIcon
    >
      <Box alignItems="center" flexDirection="row">
        <Text fontSize="small" marginRight="auto" lineHeight={1.3} marginBottom="small">
          {title || strings.title || 'Title'}
        </Text>

        <Text color="bodyFontLightColor" fontSize="small">
          {formatCount('events', { count: events.length })}
        </Text>
      </Box>
    </ListItem>
  )
}

export default LargeList
