import { useSelector } from 'react-redux'

import useReduxAction from '@hooks/useReduxAction'

type UseCountries = {
  performHttpRequests?: boolean,
}

function useCountries(options: UseCountries = {}) {
  const { performHttpRequests } = options

  useReduxAction('countries', 'loadCountries', {}, [performHttpRequests], {
    shouldPerformFn: (entityReducer) => {
      const { errors, loaded, loading } = entityReducer
      return performHttpRequests && !loaded && !loading && !errors.length
    },
  })

  const { countries } = useSelector(reduxState => reduxState.entities)

  return {
    countries,
  }
}

export default useCountries
