import React from 'react'
import PropTypes from 'prop-types'

import { InputSearch } from '@campaignhub/suit-theme'

import FieldHolder from '@components/FieldHolder'

import useShortcodeSearch from './hooks/useShortcodeSearch'

const handleShortcodeSelect = (item, itemData, selectItem) => {
  const existingValue = itemData.value
  const selectedValue = `{{${item.value}}}`
  const updatedValue = existingValue === 'Text' || !existingValue ? selectedValue : `${existingValue} ${selectedValue}`

  selectItem(updatedValue)
}

const ShortcodeSearch = (props) => {
  const {
    callbacks: { selectItem },
    dataContext,
    itemData,
  } = props

  const {
    callbacks: { onChange },
    results,
    value,
  } = useShortcodeSearch({ dataContext })

  return (
    <FieldHolder boxProps={{ marginBottom: 'large' }} label="Shortcode Search">
      <InputSearch
        callbacks={{ selectItem: item => handleShortcodeSelect(item, itemData, selectItem) }}
        closeOnSelect
        filterItems={false}
        onChange={onChange}
        results={results}
        showOnFocus
        value={value}
      />
    </FieldHolder>
  )
}

ShortcodeSearch.propTypes = {
  callbacks: PropTypes.shape({
    selectItem: PropTypes.func,
  }),
  dataContext: PropTypes.array,
  itemData: PropTypes.object,
}

ShortcodeSearch.defaultProps = {
  dataContext: [],
}

export default ShortcodeSearch
