import { useMemo } from 'react'

import { matchFilterNumber, matchFilterString, sortArrayBy } from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { ApiLogModel, ApiLogRequestOptions } from '@models/types'
import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['apiLogs']

export const generateUrls = () => ({
  apiLogsIndexUrlAdmin: '#/systemManager/apiLogs',
})

type ApiLogFilters = {
  event?: string,
  integration_platform_id?: number,
  owner_id?: number,
  owner_type?: string,
  success?: 'true' | 'false',
}

type UseApiLogsOptions = {
  filters?: ApiLogFilters,
  performHttpRequests?: boolean,
  requestOptions?: ApiLogRequestOptions,
}

function useApiLogs(options: UseApiLogsOptions) {
  const { filters = {}, requestOptions } = options
  const {
    event: filterEvent,
    integration_platform_id: filterIntegrationPlatformId,
    owner_id: filterOwnerId,
    owner_type: filterOwnerType,
    success: filterSuccess,
  } = filters

  const {
    updatedEntities: { apiLogs: apiLogsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { apiLogs } = useSelector(reduxState => reduxState.entities)

  const filteredApiLogs = useMemo(() => {
    const filtered = Object.values(apiLogs).filter((apiLog: ApiLogModel) => {
      const {
        event, integration_platform_id, owner_id, owner_type, success,
      } = apiLog

      const eventMatch = filterEvent ? matchFilterString(filterEvent, event) : true
      const integrationPlatformMatch = filterIntegrationPlatformId
        ? matchFilterNumber(filterIntegrationPlatformId, integration_platform_id)
        : true
      const successMatch = filterSuccess ? success === (filterSuccess === 'true') : true
      const ownerIdMatch = filterOwnerId ? matchFilterNumber(filterOwnerId, owner_id) : true
      const ownerTypeMatch = filterOwnerType ? matchFilterString(filterOwnerType, owner_type) : true

      return eventMatch && integrationPlatformMatch && ownerIdMatch && ownerTypeMatch && successMatch
    })

    return sortArrayBy(filtered, 'desc', 'id')
  }, [apiLogsUpdatedAt, JSON.stringify(options)])

  const filteredApiLogsCount = filteredApiLogs.length
  const hasFilteredApiLogs = !!filteredApiLogsCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredApiLogsCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingApiLogs } = useReduxAction(
    'apiLogs',
    'loadApiLogs',
    {
      ...requestOptions,
      ...filtersWithOffset,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredApiLogs,
    filteredApiLogsCount,
    hasApiLogs: hasFilteredApiLogs,
    loading: loadingApiLogs,
    urls: generateUrls(),
  }
}

export default useApiLogs
