import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStars } from '@fortawesome/pro-light-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'

import {
  Box, Image, ListItem, Text,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import useReview from '@hooks/useReview'

import localizedStrings from './localizedStrings'

const Review = (props) => {
  const { review } = props

  const {
    address: { full_address },
    callbacks: { createOrEditReview },
    defaultImage,
    review: { id, reviewer_name, title },
  } = useReview(review)

  const {
    callbacks: { formatString },
    strings,
  } = useLocalization(localizedStrings)

  const defaultImageUrl = digObject(defaultImage, 'sizes.thumb_200x120', '')

  return (
    <ListItem
      boxProps={{ as: 'a', borderBottom: '1px solid', padding: 'large' }}
      onClick={() => createOrEditReview()}
      showAngleIcon
    >
      <Box
        alignItems="center"
        backgroundColor="whiteGrey"
        border="1px solid"
        borderColor="hoverGrey"
        borderRadius={5}
        height={60}
        justifyContent="center"
        marginRight="large"
        width={100}
      >
        {!defaultImageUrl && <FontAwesomeIcon icon={faStars} />}

        {defaultImageUrl && <Image borderRadius={5} height={60} marginRight="medium" url={defaultImageUrl} />}
      </Box>

      <Box justifyContent="center" flexDirection="column" minWidth="0">
        <Text fontSize="small">{full_address || formatString(strings.reviewTitle, { id })}</Text>

        <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="medium">
          {reviewer_name}
        </Text>

        <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="medium" variant="ellipsis">
          {title}
        </Text>
      </Box>
    </ListItem>
  )
}

Review.propTypes = {
  review: PropTypes.object.isRequired,
}

export default Review
