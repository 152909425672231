import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/pro-light-svg-icons'

import { Box, Text } from '@campaignhub/suit-theme'

import useEvent from '@hooks/useEvent'

import styles from './styles.module.scss'

const Event = (props) => {
  const { event } = props

  const eventPayload = useEvent(event)
  const { callbacks: { createOrEditEvent }, event: { event_group_id, title } } = eventPayload

  return (
    <Box
      as="a"
      border="1px solid"
      borderColor="lineColor"
      borderRadius={5}
      className={styles.root}
      marginBottom="small"
      onClick={createOrEditEvent}
      padding="medium"
    >
      <Text color={event_group_id ? 'bodyFontColor' : 'lineColor'} fontSize="small">
        {title}
      </Text>

      <Text color="bodyFontLightColor" fontSize="small" marginLeft="auto">
        <FontAwesomeIcon icon={faPencil} />
      </Text>
    </Box>
  )
}

Event.propTypes = {
  event: PropTypes.object.isRequired,
}

export default Event
