export default {
  default: {
    archived: 'Archived',
    create: 'Create',
    draft: 'Draft',
    filters: {
      title: 'Filter by Title',
    },
    VideoModule: {
      title: 'How to create custom templates',
      paragraph: 'How to create custom templates and edit as needed. Change text, layout, links and images.',
      titleTwo: 'How to Add a New Page to a Template',
      paragraphTwo: 'Find a section within any template and add to a new template.',
    },
    published: 'Published',
    title: 'Digital Templates',
    filterTitle: 'Filters',
    brandTemplates: 'Brand Templates',
    agencyTemplates: 'Agency Templates',
  },
}
