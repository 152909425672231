import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Link, Text,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import IntegrationPlatformLogo from '@components/IntegrationPlatformLogo'

import localizedStrings from './localizedStrings'

const ExternalPlatform = (props) => {
  const {
    callbacks: { editPlatform }, externalPlatform: { externalId, integrationPlatform },
  } = props

  const { key, title } = integrationPlatform || {}

  const { strings } = useLocalization(localizedStrings)

  return (
    <Box alignItems="center" marginBottom="large">
      <Box
        border="1px solid"
        borderColor="lineColor"
        borderRadius={5}
        height={40}
        marginRight="large"
        padding="large"
        variant="center"
        width={60}
      >
        <IntegrationPlatformLogo
          backgroundSize="contain"
          border="1px solid"
          borderColor="lineColor"
          borderRadius={5}
          height={40}
          integrationPlatformKey={key}
          width={60}
        />
      </Box>

      <Box alignItems="center">
        <Box flexDirection="column" justifyContent="center">
          <Text fontSize="small">{title || strings.unknownPlatform || 'Unknown Platform'}</Text>

          <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="medium">
            {externalId || strings.noId || 'No External ID'}
          </Text>
        </Box>

        <Box fontSize="small" flex={0}>
          <Link
            greyLink
            onClick={() => editPlatform({ externalId, externalPlatformKey: key })}
          >
            <FontAwesomeIcon icon={faPencil} />
          </Link>
        </Box>
      </Box>
    </Box>
  )
}

ExternalPlatform.propTypes = {
  callbacks: PropTypes.shape({
    editPlatform: PropTypes.func.isRequired,
  }).isRequired,
  externalPlatform: PropTypes.shape({
    externalId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    integrationPlatform: PropTypes.object,
  }).isRequired,
}

export default ExternalPlatform
