export default {
  default: {
    inPerson: 'In Person',
    notAssigned: 'Not Assigned',
    remote: 'Email',
    title: 'Recipient',
    menu: {
      assignRecipient: 'Assign Recipient',
      changeRecipient: 'Change Recipient',
      delete: 'Delete Recipient',
      edit: 'Edit',
      inPersonSign: 'Sign in Person',
      remoteSign: 'Sign via Email',
    },
  },
}
