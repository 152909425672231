import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/pro-solid-svg-icons'

import {
  Box, Text,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const getText = (propertyType, sold, strings) => {
  if (propertyType === 'rental') return strings.forRent || 'For Rent'
  if (sold) return strings.sold || 'Sold'
  return strings.forSale || 'For Sale'
}

const ComparableStatus = (props) => {
  const { propertyType, sold } = props

  const { strings } = useLocalization(localizedStrings)

  return (
    <Box
      alignItems="center"
      backgroundColor="black"
      borderRadius={5}
      color="white"
      fontSize="xsmall"
      opacity={0.8}
      padding="small"
      width="fit-content"
    >
      <Text
        color={sold ? 'green' : 'blue'}
        fontSize="xxsmall"
        marginRight="small"
      >
        <FontAwesomeIcon icon={faCircle} />
      </Text>
      {getText(propertyType, sold, strings)}
    </Box>
  )
}

ComparableStatus.propTypes = {
  propertyType: PropTypes.string,
  sold: PropTypes.bool.isRequired,
}

export default ComparableStatus
