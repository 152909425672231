import { useContext } from 'react'

import { launchModal } from '@campaignhub/javascript-utils'

import PageContext from '@contexts/pageContext'

type IntergrationPartnerOptions = {
  integrationPlatformKey: string,
}

function useIntegrationPartner(options: IntergrationPartnerOptions = {}) {
  const { integrationPlatformKey = 'real_time_agent' } = options

  const { callbacks } = useContext(PageContext)

  return {
    callbacks: {
      valueProposition: (customPayload: {}) => launchModal({
        callbacks,
        modalKey: 'RTAValuePropositionModal',
        payload: { integrationPlatformKey, ...customPayload },
      }),
    },
  }
}

export default useIntegrationPartner
