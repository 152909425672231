import PropTypes from 'prop-types'

import {
  LineChart as Chart, Line, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis,
} from 'recharts'

import { digObject } from '@campaignhub/javascript-utils'

import CustomLegend from '../Legend'
import CustomTooltip from '../Tooltip'

const LineChart = (props) => {
  const {
    chartOptions,
    dataPayload: {
      dataArray, dataKeys, theme,
    },
    height,
    width,
  } = props

  return (
    <ResponsiveContainer width={width} height={height}>
      <Chart
        data={dataArray}
        margin={{ right: 15, left: 15 }}
      >
        <CartesianGrid stroke="rgba(0, 0, 0, 0.1)" horizontal={false} strokeDasharray="3 3" strokeWidth={2} />

        {dataKeys.map((dataKey) => {
          const border = digObject(theme, `${dataKey}.colors.border`, '#8884d8')

          return <Line type="monotone" dataKey={dataKey} key={dataKey} stroke={border} strokeWidth={2} />
        })}

        { chartOptions?.includeXAxis
        && <XAxis axisLine={false} dataKey="name" fontSize={10} height={10} tickLine={false} /> }

        <Tooltip
          content={(
            <CustomTooltip dataKeys={dataKeys} />
          )}
        />

        <Legend
          content={<CustomLegend />}
          verticalAlign="bottom"
        />
      </Chart>
    </ResponsiveContainer>
  )
}

LineChart.propTypes = {
  chartOptions: PropTypes.object,
  dataPayload: PropTypes.shape({
    dataArray: PropTypes.array.isRequired,
    dataKeys: PropTypes.array.isRequired,
    includeXAxis: PropTypes.bool,
    theme: PropTypes.object.isRequired,
  }).isRequired,
  height: PropTypes.number,
  width: PropTypes.string,
}

LineChart.defaultProps = {
  height: 200,
  width: '100%',
}

export default LineChart
