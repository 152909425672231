import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { digObject, matchFilterNumber } from '@campaignhub/javascript-utils'
import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

const watchEntityKeys = ['digitalPageViews']

const mergeInteractions = (digitalPageViews) => {
  const mergedInteractions = digitalPageViews.reduce((acc, digitalPageView) => {
    const interactions = digObject(digitalPageView, 'data.interactions', [])
    return [...acc, ...interactions]
  }, [])

  return mergedInteractions
}

function useDigitalPageInteractions(options = {}){
  const { digitalPageId, shareLinkId } = options || {}

  const {
    updatedEntities: { digitalPageViews: digitalPageViewsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const entities = useSelector(reduxState => reduxState.entities)
  const { digitalPageViews } = entities

  const filteredDigitalPageViews = useMemo(() => {
    const array = Object.values(digitalPageViews)
    const filtered = array.filter((digitalPageView) => {
      const { digital_page_id, share_link_id } = digitalPageView

      const matchDigitalPage = matchFilterNumber(digital_page_id, digitalPageId)
      const matchShareLink = matchFilterNumber(share_link_id, shareLinkId)

      return matchDigitalPage && matchShareLink
    })

    return filtered
  }, [digitalPageViewsUpdatedAt, JSON.stringify(options)])

  const mergedInteractions = mergeInteractions(filteredDigitalPageViews)

  return {
    mergedInteractions,
  }
}

export default useDigitalPageInteractions
