import { useMemo } from 'react'

import { matchFilterNumber, sortArrayBy } from '@campaignhub/javascript-utils'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useSelector from '@hooks/useSelector'

import type { OrganizationsUserModel } from '@models/types'

const watchEntityKeys = ['organizationsUsers']

type UseOrganizationsUsersOptions = {
  userId?: number,
}

function useOrganizationsUsers(options: UseOrganizationsUsersOptions) {
  const { userId } = options

  const {
    updatedEntities: { organizationsUsers: organizationsUsersUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { organizations, organizationsUsers } = useSelector(reduxState => reduxState.entities)

  const { loading } = useSelector(state => state.organizationsUsers)

  const filteredOrganizationsUsers = useMemo(() => {
    const filtered = Object.values(organizationsUsers).filter((organizationsUser: OrganizationsUserModel) => {
      const { user_id } = organizationsUser

      const matchUser = matchFilterNumber(user_id, userId)

      return matchUser
    })

    return sortArrayBy(filtered, 'asc', (organizationsUser) => {
      const { organization_id } = organizationsUser
      const organization = organizations[organization_id] || {}

      return organization.title || organization.id
    })
  }, [organizationsUsersUpdatedAt, JSON.stringify(options)])

  return {
    filteredOrganizationsUsers,
    loading,
  }
}

export default useOrganizationsUsers
