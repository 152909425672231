import React from 'react'
import PropTypes from 'prop-types'

import { deepSetObject, digObject, toggleArray } from '@campaignhub/javascript-utils'
import { DashboardModule, Box } from '@campaignhub/suit-theme'

import { notificationGroups } from '@functions/notification'

import useLocalization from '@hooks/useLocalization'

import NotificationGroup from './components/NotificationGroup'

import localizedStrings from './localizedStrings'

const updateOptions = (typeKey, methodKey, entityState, setEntityState) => {
  const { options } = entityState
  const disabledNotificationMethods = digObject(options, `disabled_notifications.${typeKey}`, [])
  const updatedNotificationMethods = toggleArray(disabledNotificationMethods, methodKey)

  setEntityState({
    options: deepSetObject(options, `disabled_notifications.${typeKey}`, updatedNotificationMethods),
  })
}

const NotificationOptions = (props) => {
  const { userFormPayload } = props
  const {
    entityState,
    entityState: { options },
    setEntityState,
  } = userFormPayload

  const disabledNotifications = digObject(options, 'disabled_notifications', {})

  const { strings } = useLocalization(localizedStrings)

  return (
    <DashboardModule title={strings.title || 'Notification Options'} boxProps={{ marginBottom: 'large' }}>
      <Box flexDirection="column">
        {Object.keys(notificationGroups).map((notificationGroupKey, i) => (
          <NotificationGroup
            callbacks={{
              updateNotifications: (typeKey, methodKey) => updateOptions(typeKey, methodKey, entityState, setEntityState),
            }}
            disabledNotifications={disabledNotifications}
            key={notificationGroupKey}
            notificationGroup={notificationGroups[notificationGroupKey] || {}}
            lastGroup={Object.values(notificationGroups).length === i + 1}
          />
        ))}
      </Box>
    </DashboardModule>
  )
}

NotificationOptions.propTypes = {
  userFormPayload: PropTypes.object.isRequired,
}

export default NotificationOptions
