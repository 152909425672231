import React from 'react'
import PropTypes from 'prop-types'

import { Button, FormField, SidebarModal } from '@campaignhub/suit-theme'

import useDigitalTemplateTypes from '@hooks/useDigitalTemplateTypes'

import EntitySelector from '@components/EntitySelector'

const Details = (props) => {
  const {
    digitalTemplateFormPayload: {
      callbacks: { updateDigitalTemplate },
      entityState,
      entityState: {
        brand_id,
        digital_template_type_id,
        global,
        options: { preview_link },
        title,
      },
      handlers,
      saveEnabled,
      updating,
      setEntityState,
    },
  } = props

  const templateTypesPayload = useDigitalTemplateTypes()
  const { groupedTemplateTypes } = templateTypesPayload

  return (
    <SidebarModal.ExpandableSectionBox label="Details">
      <FormField boxProps={{ marginBottom: 'large' }} label="Global">
        <select data-value-type="boolean" name="global" value={global} {...handlers}>
          <option value="false">No</option>
          <option value="true">Yes</option>
        </select>
      </FormField>

      {!global && (
        <EntitySelector
          boxProps={{ marginBottom: 'large' }}
          callbacks={{
            selectItem: brand => setEntityState({ brand_id: brand ? brand.id : null }),
          }}
          entityKey="brands"
          selectedItemId={Number(brand_id)}
        />
      )}

      <FormField boxProps={{ marginBottom: 'large' }} label="Page Name">
        <input name="title" value={title} type="text" {...handlers} />
      </FormField>

      <FormField boxProps={{ marginBottom: 'large' }} label="Type">
        <select name="digital_template_type_id" value={digital_template_type_id} {...handlers}>
          <option>Please Select...</option>
          {groupedTemplateTypes.map(templateType => (
            <optgroup key={templateType.id} label={templateType.title}>
              {templateType.subtypes.map(subtype => (
                <option key={subtype.id} value={subtype.id}>
                  {subtype.title}
                </option>
              ))}
            </optgroup>
          ))}
        </select>
      </FormField>

      <FormField boxProps={{ marginBottom: 'large' }} label="Preview Link">
        <input name="options.preview_link" type="text" value={preview_link} {...handlers} />
      </FormField>

      <FormField label="Tag Manager ID">
        <input type="text" />
      </FormField>

      <Button
        buttonStyle="ghostCreate"
        disabled={!saveEnabled}
        loading={updating}
        marginTop="large"
        onClick={() => updateDigitalTemplate(entityState)}
        size="medium"
      >
        Save Details
      </Button>
    </SidebarModal.ExpandableSectionBox>
  )
}

Details.propTypes = {
  digitalTemplateFormPayload: PropTypes.shape({
    callbacks: PropTypes.shape({
      updateDigitalTemplate: PropTypes.func.isRequired,
    }).isRequired,
    entityState: PropTypes.object.isRequired,
    handlers: PropTypes.object.isRequired,
    saveEnabled: PropTypes.bool,
    setEntityState: PropTypes.func.isRequired,
    updating: PropTypes.bool,
  }).isRequired,
}

export default Details
