import React from 'react'
import PropTypes from 'prop-types'

import QuoteBuilderContext from '../../contexts/quoteBuilderContext'

const QuoteBuilderContextProvider = (props) => {
  const { children, value } = props

  return (
    <QuoteBuilderContext.Provider value={value}>
      {children}
    </QuoteBuilderContext.Provider>
  )
}

QuoteBuilderContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.object.isRequired,
}

export default QuoteBuilderContextProvider
