import React from 'react'

import { BlankState } from '@campaignhub/suit-theme'

import metricCollectionImageUrl from './assets/metric-collection.svg'

const MetricCollectionBlankState = () => (
  <BlankState imageUrl={metricCollectionImageUrl}>
    <BlankState.Title>Could not find any metric collections.</BlankState.Title>
    <BlankState.Paragraph>
      Build monthly metric collections or change your filter settings to return more results
    </BlankState.Paragraph>
  </BlankState>
)

export default MetricCollectionBlankState
