import { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { sortArrayBy } from '@campaignhub/javascript-utils'
import {
  useForm, useLatestEntity, useThunkDispatch, useWatchEntityUpdates,
} from '@campaignhub/react-hooks'

import PageContext from '@contexts/pageContext'

import defaultFormState, { requiredFields } from '@models/documentRecipient'

import * as documentRecipientActions from '@redux/modules/documentRecipient'

const watchEntityKeys = ['userTypes']

export const recipientColors = [
  '#0c9bfb',
  '#85abc5',
  '#78C0B7',
  '#E29873',
  '#8e4162',
  '#8361BB',
  '#BB6161',
  '#61BB95',
  '#6180BB',
  '#6661BB',
  '#61ABBB',
  '#D7B94E',
]

export const roleTitles = {
  organization_owner: 'Organization Owner',
  project_contact: 'Project Contact',
  project_lead_user: 'Project Lead',
  project_support_user: 'Project Support',
}

export const recipientRoles = {
  organization_owner: {
    entityType: 'User',
    key: 'organization_owner',
    shortcodeKey: 'user',
    title: roleTitles.organization_owner,
  },
  project_contact: {
    entityType: 'Contact',
    key: 'project_contact',
    shortcodeKey: 'contact',
    title: roleTitles.project_contact,
  },
  project_lead_user: {
    entityType: 'User',
    key: 'project_lead_user',
    shortcodeKey: 'user',
    title: roleTitles.project_lead_user,
  },
  project_support_user: {
    entityType: 'User',
    key: 'project_support_user',
    shortcodeKey: 'user',
    title: roleTitles.project_support_user,
  },
}

const createDocumentRecipient = (documentRecipient, dispatch, requestOptions) => {
  const { createDocumentRecipient: createFn } = documentRecipientActions
  return dispatch(createFn(documentRecipient, requestOptions))
}

const updateDocumentRecipient = (documentRecipient, dispatch, requestOptions) => {
  const { updateDocumentRecipient: updateFn } = documentRecipientActions
  return dispatch(updateFn(documentRecipient, requestOptions))
}

const deleteDocumentRecipient = (documentRecipient, dispatch, requestOptions) => {
  const { deleteDocumentRecipient: deleteFn } = documentRecipientActions
  return dispatch(deleteFn(documentRecipient, requestOptions))
}

const deleteAndReassignPageItems = (params) => {
  const {
    dispatch, documentTemplate, documentRecipient, targetRecipientId,
  } = params

  const { deleteAndReassignPageItems: deleteFn } = documentRecipientActions

  return dispatch(deleteFn(documentRecipient, targetRecipientId, documentTemplate))
}

export const getRecipientSubject = (recipientRelations) => {
  const { contact, user } = recipientRelations

  if (user.id){
    return user
  }

  return contact
}

const createOrEditDocumentRecipient = (documentRecipient, callbacks) => new Promise((resolve, reject) => {
  const { showCreateOrEditDocumentRecipientModal } = callbacks || {}

  if (showCreateOrEditDocumentRecipientModal){
    const payload = {
      documentRecipient,
    }

    showCreateOrEditDocumentRecipientModal(payload)

    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showCreateOrEditDocumentRecipientModal not defined in PageContext callbacks'))
})

export function useDocumentRecipientForm(documentRecipient = {}, options = {}){
  const { customRequiredFields = [] } = options

  const { creating, updating } = useSelector(reduxState => reduxState.documentRecipients)

  const documentRecipientForm = useForm(
    defaultFormState,
    { entity: documentRecipient, requiredFields: [...requiredFields, ...customRequiredFields], validateOn: 'change' },
    [documentRecipient.id, documentRecipient.cache_key],
  )

  return {
    ...documentRecipientForm,
    creating,
    updating,
  }
}

export function useRelations(documentRecipient = {}){
  const {
    owner_id, owner_type, status_id, subject_id, subject_type, user_type_id,
  } = documentRecipient

  const entities = useSelector(reduxState => reduxState.entities)
  const {
    contacts, documentTemplates, statuses, users, userTypes,
  } = entities

  const documentTemplate = owner_type === 'DocumentTemplate' ? documentTemplates[owner_id] || {} : {}
  const contact = subject_type === 'Contact' ? contacts[subject_id] || {} : {}
  const status = statuses[status_id] || {}
  const user = subject_type === 'User' ? users[subject_id] || {} : {}
  const userType = userTypes[user_type_id] || {}

  return {
    contact,
    documentTemplate,
    status,
    user,
    userType,
  }
}

function useDocumentRecipient(initEntity = {}, options = {}){
  const { temporaryStatus } = options || {}

  const { entity: documentRecipient } = useLatestEntity(initEntity, 'documentRecipients')
  const {
    id, in_person_signer, role_key, sort,
  } = documentRecipient

  const dispatch = useThunkDispatch()

  const {
    updatedEntities: { userTypes: userTypesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const entities = useSelector(reduxState => reduxState.entities)
  const { userTypes } = entities

  const { callbacks } = useContext(PageContext)

  const { documentTemplate, status: recipientStatus, userType } = useRelations(documentRecipient)

  const recipientColor = recipientColors[sort] || ''

  const filteredUserTypes = useMemo(() => {
    const { entityType } = recipientRoles[role_key] || {}

    const filtered = Object.values(userTypes).filter((type) => {
      const { subject_type } = type
      return entityType === subject_type
    })

    const sorted = sortArrayBy(filtered, 'asc', 'title')

    return sorted
  }, [id, role_key, userTypesUpdatedAt])

  const status = temporaryStatus || recipientStatus
  const isCompleted = status.key === 'completed'

  return {
    callbacks: {
      createDocumentRecipient: (params, requestOptions) => createDocumentRecipient(params, dispatch, requestOptions),
      createOrEditDocumentRecipient: () => createOrEditDocumentRecipient(documentRecipient, callbacks),
      deleteAndReassignPageItems: targetRecipientId => deleteAndReassignPageItems({
        documentTemplate,
        documentRecipient,
        targetRecipientId,
        dispatch,
      }),
      deleteDocumentRecipient: () => deleteDocumentRecipient(documentRecipient, dispatch),
      toggleInPersonSigner: () => updateDocumentRecipient({ ...documentRecipient, in_person_signer: !in_person_signer }, dispatch),
      updateDocumentRecipient: (params, requestOptions) => updateDocumentRecipient(params, dispatch, requestOptions),
    },
    documentRecipient,
    filteredUserTypes,
    isCompleted,
    recipientColor,
    status,
    userType,
  }
}

export default useDocumentRecipient
