export default {
  default: {
    buttons: {
      save: 'Save',
    },
    formFieldLabels: {
      videoUrl: 'URL',
      videoId: 'Video ID',
      videoProvider: 'Provider',
    },
    selectVideoProvider: {
      selectDefault: 'Please Select...',
      youtube: 'Youtube',
      vimeo: 'Vimeo',
    },
    title: 'Edit',
    titleSecondLine: 'Video',
  },
}
