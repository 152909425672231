import { useMemo } from 'react'
import { useFilters, useModals, useSetState } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'
import type { PageContextPayload } from '@contexts/pageContext'

import handleCallbackAction from '@functions/handleCallbackAction'
import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import AuthorizeIntegrationModal from '@modals/AuthorizeIntegrationModal'
import CreateOrEditIntegrationModal from '@modals/CreateOrEditIntegrationModal'
import ManageEntityDefaultIntegrationPlatformsModal from '@modals/ManageEntityDefaultIntegrationPlatformsModal'

import PageContent from './components/PageContent'

const callbacks = (component: string, setState: any) => {
  const componentCallbacks = {
    AuthorizeIntegrationModal: {
      closeModal: () => setState({ showAuthorizeIntegrationModal: false }),
    },
    CreateOrEditIntegrationModal: {
      closeModal: () => setState({
        showCreateOrEditIntegrationModal: false,
      }),
      updateIntegration: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      deleteIntegration: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
    ManageEntityDefaultIntegrationPlatformsModal: {
      closeModal: () => setState({ showManageEntityDefaultIntegrationPlatformsModal: false }),
      updateEntity: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  showAuthorizeIntegrationModal: false,
  showCreateOrEditIntegrationModal: false,
  showManageEntityDefaultIntegrationPlatformsModal: false,
}

const Integrations = () => {
  const [state, setState] = useSetState(defaultState)
  const {
    showAuthorizeIntegrationModal,
    showCreateOrEditIntegrationModal,
    showManageEntityDefaultIntegrationPlatformsModal,
  } = state

  const {
    callbacks: filterCallbacks,
    defaultFilters,
    pageFilters,
  } = useFilters()

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const pageContext: PageContextPayload = useMemo(
    () => ({
      callbacks: {
        ...filterCallbacks,
        showAuthorizeIntegrationModal: (payload: HandleCallbackActionParams) => {
          setModalData('AuthorizeIntegrationModal', payload)
          setState({ showAuthorizeIntegrationModal: true })
        },
        showCreateOrEditIntegrationModal: (payload: HandleCallbackActionParams) => {
          setModalData('CreateOrEditIntegrationModal', payload)
          setState({ showCreateOrEditIntegrationModal: true })
        },
        showManageEntityDefaultIntegrationPlatformsModal: () => setState({
          showManageEntityDefaultIntegrationPlatformsModal: true,
        }),
      },
      defaultFilters,
    }),
    [],
  )

  useReduxAction('users', 'loadUsers', { }, [])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent pageFilters={pageFilters} />

        <AuthorizeIntegrationModal
          callbacks={callbacks('AuthorizeIntegrationModal', setState)}
          showModal={showAuthorizeIntegrationModal}
        />

        <CreateOrEditIntegrationModal
          callbacks={callbacks('CreateOrEditIntegrationModal', setState)}
          showModal={showCreateOrEditIntegrationModal}
        />

        <ManageEntityDefaultIntegrationPlatformsModal
          callbacks={callbacks('ManageEntityDefaultIntegrationPlatformsModal', setState)}
          showModal={showManageEntityDefaultIntegrationPlatformsModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

Integrations.propTypes = {}

export default Integrations
