import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities } from '@redux/entities'

import api from '@functions/api'

import type { BrandModel, CustomFieldModel } from '@models/types'
import type { AppDispatch } from '@redux/store'
import type {
  Action, EntityOptions, Errors, FetchRequestPayload, RootReducerState, UpdateParams,
} from '@redux/modules/types'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/brand/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/brand/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/brand/FETCH_FAILURE'

const CREATE_REQUEST = 'realbase/brand/CREATE_REQUEST'
const CREATE_SUCCESS = 'realbase/brand/CREATE_SUCCESS'
const CREATE_FAILURE = 'realbase/brand/CREATE_FAILURE'

const UPDATE_REQUEST = 'realbase/brand/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realbase/brand/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realbase/brand/UPDATE_FAILURE'

const DELETE_REQUEST = 'realbase/brand/DELETE_REQUEST'
const DELETE_SUCCESS = 'realbase/brand/DELETE_SUCCESS'
const DELETE_FAILURE = 'realbase/brand/DELETE_FAILURE'

const SELECT_BRAND_ID = 'realbase/brand/SELECT_BRAND_ID'

export type BrandModuleState = {
  creating: boolean,
  deleting: boolean,
  errors: string[],
  loaded: boolean,
  loadedForKeys: string[],
  loadedIds: number[],
  loading: boolean,
  selectedId: null | number,
  updating: boolean,
}

// Initial State
const initialState: BrandModuleState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loadedIds: [],
  loadedForKeys: [],
  loading: false,
  selectedId: null,
  updating: false,
}

// Actions
export function fetchRequest(payload: FetchRequestPayload = {}) {
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess() {
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors: Errors = []) {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest() {
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess() {
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors: Errors = []) {
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest() {
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess() {
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors: Errors = []) {
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest() {
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess() {
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors: Errors = []) {
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

export function selectBrandId(brandId: number) {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: SELECT_BRAND_ID,
      selectedId: brandId,
    })
  }
}

// Action Creators
export function loadBrands(options: EntityOptions = {}) {
  const { entityKey } = options

  return (dispatch: AppDispatch, getState: () => RootReducerState) => {
    const loadedForKeys = [...getState().brands.loadedForKeys]

    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('/brands.json', options)
      .then(({ data }: { data: { entities: BrandModel[] } }) => {
        const normalizedJson = normalize(data.entities, Schemas.BRAND_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function loadBrand(brandId: number, options: EntityOptions = {}) {
  return (dispatch: AppDispatch, getState: () => RootReducerState) => {
    const state = getState()
    const loadedIds = [...state.brands.loadedIds]
    const loadedForKeys = [...state.brands.loadedForKeys]

    if (brandId && !loadedIds.includes(brandId)){
      loadedIds.push(brandId)
    }

    // Set Loading
    dispatch(fetchRequest({ loadedForKeys, loadedIds }))

    const promise = api(`/brands/${brandId}.json`, options)
      .then(({ data }: { data: { entity: BrandModel } }) => {
        const normalizedJson = normalize(data.entity, Schemas.BRAND)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function createBrand(brand: Partial<BrandModel>, options: EntityOptions = {}) {
  const config = {
    method: 'POST',
    body: JSON.stringify({
      brand,
    }),
  }

  return (dispatch: AppDispatch) => {
    dispatch(createRequest())

    const promise = api('/brands.json', options, config)
      .then(({ data }: { data: { entity: BrandModel } }) => {
        const normalizedJson = normalize(data.entity, Schemas.BRAND)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function updateBrand(
  brand: UpdateParams<BrandModel>,
  dataStoreItemValues: CustomFieldModel[],
  options: EntityOptions = {},
) {
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      brand,
      data_store_item_values: dataStoreItemValues || [],
    }),
  }

  return (dispatch: AppDispatch) => {
    dispatch(updateRequest())

    const promise = api(`/brands/${brand.id}.json`, options, config)
      .then(({ data }: { data: { entity: BrandModel } }) => {
        const normalizedJson = normalize(data.entity, Schemas.BRAND)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(
  state: BrandModuleState = initialState,
  action: Action = { type: '' },
): BrandModuleState {
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loadedIds: action.loadedIds || state.loadedIds,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors || [],
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors || [],
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors || [],
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors || [],
      }
    case SELECT_BRAND_ID:
      return {
        ...state,
        selectedId: action.selectedId,
      }
    default:
      return state
  }
}
