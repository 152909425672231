import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCode } from '@fortawesome/pro-light-svg-icons'

import useDeviceStyle from '@hooks/useDeviceStyle'

import styles from './styles.module.scss'

const renderText = (props) => {
  const { data: { shortcode, value } } = props

  const match = value.match(/`(.+)`/)
  if (match) return <><FontAwesomeIcon icon={faCode} /> {shortcode}</>

  return value
}

const TextBlock = React.memo((props) => {
  const {
    id,
    isSelected,
    onClick,
  } = props

  const style = useDeviceStyle(props)

  return (
    <div
      className={classnames(
        styles.root,
        isSelected ? styles.selected : null,
      )}
      onClick={onClick}
      style={style}
    >
      <div className={styles.label}>Text {id}</div>
      {renderText(props)}
    </div>
  )
})

TextBlock.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
}

TextBlock.defaultProps = {
  data: {},
}

export default TextBlock
