export default {
  default: {
    toast: {
      created: 'Field Created.',
      deleted: 'Field Deleted.',
      updated: 'Field Updated.',
    },
    title: 'Form Builder | Engage',
  },
}
