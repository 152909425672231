import Swal from 'sweetalert2'

import { stringToTitleCase } from '@campaignhub/javascript-utils'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import type { DeleteEntity } from '@redux/modules/types'

type ConfirmDeleteParams = {
  callbacks: {
    afterAction?: Function,
    deleteEntity: (payload: HandleCallbackActionParams) => Promise<object>,
    deleteFn: DeleteEntity,
  },
  options?: {
    entityParams?: HandleCallbackActionParams['entityParams'],
    requestOptions?: HandleCallbackActionParams['requestOptions'],
    shouldRedirect?: HandleCallbackActionParams['shouldRedirect'],
  },
  strings: {
    deleteString?: 'archive' | 'delete' | 'remove',
    entityName: string,
  },
}

export const confirmDelete = (params: ConfirmDeleteParams) => {
  const { callbacks, options, strings } = params

  const { afterAction, deleteEntity, deleteFn } = callbacks
  const { entityName, deleteString = 'delete' } = strings

  const { entityParams, requestOptions, shouldRedirect } = options || {}

  const title = `${deleteString} ${entityName}?` // Delete Agency?
  const toastText = `${entityName} ${deleteString}d` // Agency Deleted
  const confirmButtonText = `Yes, ${deleteString} it.` // Yes, delete it.

  const payload = {
    callbacks: {
      action: deleteFn,
      afterAction,
    },
    entityParams,
    requestOptions,
    shouldRedirect,
    toastText: stringToTitleCase(toastText),
  }

  Swal
    .fire({
      confirmButtonColor: '#DD6B55',
      confirmButtonText,
      icon: 'warning',
      showCancelButton: true,
      text: 'Are you sure?',
      title: stringToTitleCase(title),
    })
    .then(({ value }) => {
      if (value){
        deleteEntity(payload)
      }
    })
}

export default confirmDelete
