import { useMemo } from 'react'
import { toast } from 'react-toastify'

import { useModals, useSetState } from '@campaignhub/react-hooks'
import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import useLocalization from '@hooks/useLocalization'
import useOrganizationSelector from '@hooks/useOrganizationSelector'
import useReduxAction from '@hooks/useReduxAction'

import defaultRequestOptions from '@sections/Client/packs/Admin/defaultRequestOptions'

import handleCallbackAction from '@functions/handleCallbackAction'

import CreateOrEditAssetLibraryModal from '@modals/CreateOrEditAssetLibraryModal'
import EditAttachmentModal from '@modals/EditAttachmentModal'

import PageContent from './components/PageContent'
import localizedStrings from './localizedStrings'

const deleteAttachment = (deleteFn, setState, strings) => {
  deleteFn().then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    toast(strings.attachmentDeleted || 'Attachment Deleted')
    setState({ showEditAttachmentModal: false })
  })
}

const updateAttachment = (attachment, customFields, updateFn, setState, strings) => {
  updateFn(attachment, customFields, defaultRequestOptions.attachment).then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    toast(strings.attachmentUpdated || 'Attachment Updated')
    setState({ showEditAttachmentModal: false })
  })
}

const callbacks = (component, setState, strings) => {
  const componentCallbacks = {
    CreateOrEditAssetLibraryModal: {
      closeModal: () => setState({ showCreateOrEditAssetLibraryModal: false }),
      createAssetLibrary: payload => handleCallbackAction(payload),
      deleteAssetLibrary: payload => handleCallbackAction(payload),
      updateAssetLibrary: payload => handleCallbackAction(payload),
    },
    EditAttachmentModal: {
      closeModal: () => setState({ showEditAttachmentModal: false }),
      deleteAttachment: deleteFn => deleteAttachment(deleteFn, setState, strings),
      updateAttachment: (attachment, customFields, updateFn) => updateAttachment(attachment, customFields, updateFn, setState, strings),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  showCreateOrEditAssetLibraryModal: false,
  showEditAttachmentModal: false,
}

const FileLibraries = () => {
  const [state, setState] = useSetState(defaultState)
  const { showCreateOrEditAssetLibraryModal, showEditAttachmentModal } = state

  const { selectedOrganization } = useOrganizationSelector()

  useReduxAction(
    'assetLibraries',
    'loadAssetLibraries',
    {
      asset_type: 'file',
      owner_id: selectedOrganization.id,
      owner_type: 'Organization',
    },
    [selectedOrganization.id],
  )

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const pageContext = useMemo(
    () => ({
      callbacks: {
        showCreateOrEditAssetLibraryModal: (payload) => {
          setModalData('CreateOrEditAssetLibraryModal', payload)
          setState({ showCreateOrEditAssetLibraryModal: true })
        },
        showEditAttachmentModal: (payload) => {
          setModalData('EditAttachmentModal', payload)
          setState({ showEditAttachmentModal: true })
        },
      },
    }),
    [],
  )

  const { strings } = useLocalization(localizedStrings)

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent organization={selectedOrganization} />

        <CreateOrEditAssetLibraryModal
          callbacks={callbacks('CreateOrEditAssetLibraryModal', setState, strings)}
          showModal={showCreateOrEditAssetLibraryModal}
        />

        <EditAttachmentModal
          callbacks={callbacks('EditAttachmentModal', setState, strings)}
          showModal={showEditAttachmentModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default FileLibraries
