import { useSelector } from 'react-redux'

import { useSetState } from '@campaignhub/react-hooks'

import useDigitalTemplates from '@hooks/useDigitalTemplates'

const defaultRequestOptions = {
  digitalTemplate: {
    include_digital_template_preview_image: true,
  },
  image: {
    include_image_sizes: ['thumb_660x360'],
  },
}

const defaultState = {
  filterString: '',
}

function useSelectSourceDigitalTemplate(options = {}){
  const { selectedDigitalTemplateId } = options || {}

  const [state, setState] = useSetState(defaultState)
  const { filterString } = state

  const { loadedForKeys, loading } = useSelector(reduxState => reduxState.digitalTemplates)

  const entities = useSelector(reduxState => reduxState.entities)
  const { digitalTemplates, images } = entities

  // Templates
  const digitalTemplatesEntityKey = 'DigitalTemplates-ProductionReady'
  const digitalTemplatesPayload = useDigitalTemplates({
    ...defaultRequestOptions.digitalTemplate,
    ...defaultRequestOptions.digitalTemplatePage,
    ...defaultRequestOptions.image,
    entityKey: digitalTemplatesEntityKey,
    exclude_digital_template_type_key: 'template_partial',
    filters: {
      production_ready: true,
      string: filterString,
      template_partial: false,
    },
    performHttpRequests: !loadedForKeys.includes(digitalTemplatesEntityKey),
  })
  const { filteredDigitalTemplates } = digitalTemplatesPayload

  // Template Partials
  const digitalTemplatePartialsEntityKey = 'DigitalTemplates-TemplatePartials'
  const digitalTemplatePartialsPayload = useDigitalTemplates({
    ...defaultRequestOptions.digitalTemplate,
    ...defaultRequestOptions.digitalTemplatePage,
    ...defaultRequestOptions.image,
    digital_template_type_key: 'template_partial',
    entityKey: digitalTemplatePartialsEntityKey,
    filters: {
      production_ready: true,
      string: filterString,
      template_partial: true,
    },
    performHttpRequests: !loadedForKeys.includes(digitalTemplatePartialsEntityKey),
  })
  const { filteredDigitalTemplates: filteredDigitalTemplatePartials } = digitalTemplatePartialsPayload

  const sourceTemplate = digitalTemplates[selectedDigitalTemplateId] || {}
  const sourceTemplateImage = images[sourceTemplate.preview_image_id] || {}

  const hasDigitalTemplates = !!filteredDigitalTemplates.length
  const hasDigitalTemplatePartials = !!filteredDigitalTemplatePartials.length

  return {
    callbacks: {
      setState,
    },
    filteredDigitalTemplatePartials: filteredDigitalTemplatePartials.filter(t => t.id !== selectedDigitalTemplateId),
    filteredDigitalTemplates: filteredDigitalTemplates.filter(t => t.id !== selectedDigitalTemplateId),
    hasDigitalTemplatePartials,
    hasDigitalTemplates,
    images,
    loading,
    sourceTemplate,
    sourceTemplateImage,
    state,
  }
}

export default useSelectSourceDigitalTemplate
