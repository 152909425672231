import React from 'react'
import PropTypes from 'prop-types'

import { Image, SectionDivider, SidebarModal } from '@campaignhub/suit-theme'

import ProposalImageUrl from './assets/proposal.svg'
import TemplateImageUrl from './assets/templates.svg'

const CreateProjectItemModal = (props) => {
  const {
    callbacks, modalKey, showModal,
  } = props

  const { showDuplicateDigitalPageModal, showSelectDigitalTemplateModal } = callbacks

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header callbacks={callbacks} title="Create" titleSecondLine="Item" />
      <SidebarModal.Content hasFooter={false}>
        <>
          <SidebarModal.Button
            boxProps={{ paddingY: ['xlarge', 'large'] }}
            iconLeft={<Image backgroundSize="contain" height={40} width={40} url={ProposalImageUrl} />}
            onClick={() => showSelectDigitalTemplateModal('proposal', { hideModalOverlay: true })}
            showAngleIcon
            subtitle="Build pre-lists, proposals and more"
            title="Create Proposal"
          />

          <SectionDivider boxProps={{ marginY: 'large' }}>or</SectionDivider>

          <SidebarModal.Button
            boxProps={{ paddingY: ['xlarge', 'large'] }}
            iconLeft={<Image backgroundSize="contain" height={40} width={40} url={TemplateImageUrl} />}
            onClick={() => showDuplicateDigitalPageModal()}
            showAngleIcon
            subtitle="Duplicate from existing page"
            title="Duplicate"
          />
        </>
      </SidebarModal.Content>

    </SidebarModal>
  )
}

CreateProjectItemModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

CreateProjectItemModal.defaultProps = {
  modalKey: 'CreateProjectItemModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <CreateProjectItemModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
