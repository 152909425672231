export default {
  default: {
    menu: {
      delete: 'Delete',
      editDetails: 'Edit Details',
      saveTemplate: 'Save as Template',
    },
    swal: {
      title: 'Delete Quote?',
      text: 'Are you sure?',
      confirmButtonText: 'Yes, Delete it.',
    },
  },
}
