import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import CountUp from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'

import useDeviceStyle from '@hooks/useDeviceStyle'

import styles from './styles.module.scss'

const Counter = (props) => {
  const {
    data: { value },
    id,
    isSelected,
    onClick,
    options: {
      counterSuffix, counterPrefix, counterSeparator, counterDecimals,
    },
  } = props

  const integerEnd = Number(value)

  const style = useDeviceStyle(props)

  return (
    <div className={classnames(styles.root, isSelected ? styles.selected : null)} onClick={onClick} style={style}>
      <div className={styles.label}>Counter {id}</div>
      <VisibilitySensor>
        {({ isVisible }) => (
          <div>
            {isVisible ? (
              <CountUp
                start={0}
                end={integerEnd || 100}
                suffix={counterSuffix}
                prefix={counterPrefix}
                decimal={counterSeparator}
                decimals={counterDecimals}
              />
            ) : (
              '0'
            )}
          </div>
        )}
      </VisibilitySensor>
    </div>
  )
}

Counter.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  options: PropTypes.shape({
    counterSeparator: PropTypes.string,
    counterDecimals: PropTypes.number,
    counterPrefix: PropTypes.string,
    counterSuffix: PropTypes.string,
  }),
}

Counter.defaultProps = {
  data: {},
  options: {},
}

export default Counter
