import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Box, Text } from '@campaignhub/suit-theme'

const EngagementStatusBadge = (props) => {
  const {
    boxProps, engagementStatusTheme: { colors, icon, title }, invertColors,
  } = props

  const { primary, secondary, border } = colors || {}

  return (
    <Box
      alignItems="center"
      border="1px solid"
      borderRadius="5px"
      backgroundColor={invertColors ? primary : secondary}
      borderColor={border}
      display="inline-flex"
      justifyContent="center"
      padding="medium"
      width="100%"
      {...boxProps}
    >
      <Text
        as="span"
        color={invertColors ? secondary : primary}
        fontSize="xxsmall"
        fontWeight="normal"
        style={{ letterSpacing: '1px', textTransform: 'uppercase', whiteSpace: 'nowrap' }}
      >
        <FontAwesomeIcon icon={icon} />{' '}{title}
      </Text>
    </Box>
  )
}

EngagementStatusBadge.propTypes = {
  boxProps: PropTypes.object,
  engagementStatusTheme: PropTypes.shape({
    colors: PropTypes.object,
    icon: PropTypes.object,
    title: PropTypes.string,
  }).isRequired,
  invertColors: PropTypes.bool,
}

export default EngagementStatusBadge
