export default {
  default: {
    buttons: {
      save: 'Save Recipient',
    },
    sideBarModalHeader: {
      titleCreate: 'Create',
      titleSecondLine: 'Recipient',
      titleUpdate: 'Update',
    },
  },
}
