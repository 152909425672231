export default {
  default: {
    buttons: {
      create: 'Create Event Calendar',
      save: 'Save',
      toggleAll: 'Toggle All',
    },
    formFieldLabels: {
      moreOptions: 'More Options',
      targetAudiences: 'Available Calendars',
    },
    sideBarModalHeader: {
      title: 'Event',
      titleSecondLine: 'Calendars',
    },
  },
}
