import PropTypes from 'prop-types'

import { faChartBar } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Columns, LoadingModule, PageHeader,
} from '@campaignhub/suit-theme'

import useDigitalPages from '@hooks/useDigitalPages'
import useLocalization from '@hooks/useLocalization'
import useReduxAction from '@hooks/useReduxAction'
import useVideoModules from '@hooks/useVideoModules'

import defaultRequestOptions from '@sections/Client/packs/Project/defaultRequestOptions'

import VideoModule from '@components/VideoModule'

import ShareLinkBlankState from '../ShareLinkBlankState'
import ShareLinkGroup from '../ShareLinkGroup'
import Statistics from '../Statistics'

import localizedStrings from './localizedStrings'

const PageContent = (props) => {
  const { project } = props

  const entityKey = `Project${project.id}`

  useReduxAction(
    'digitalPages',
    'loadDigitalPages',
    {
      ...defaultRequestOptions.digitalPage,
      ...defaultRequestOptions.shareLink,
      entityKey,
      subject_id: project.id,
      subject_type: 'Project',
    },
    [project.id],
    {
      shouldPerformFn: ({ loadedForKeys }) => project.id && !loadedForKeys.includes(entityKey),
    },
  )

  const { filteredDigitalPages, hasFilteredDigitalPages, loading } = useDigitalPages({
    subject_id: project.id,
    subject_type: 'Project',
  })

  const videoModulePayload = useVideoModules({ key: 'projectAnalytics' })
  const { videosPayload } = videoModulePayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      <PageHeader
        activeTabBarItemKey="analytics"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        nestedNavigation
        tabBarItems={[
          {
            href: `#/projects/${project.id}/analytics`,
            icon: faChartBar,
            key: 'analytics',
            title: strings.title || 'Analytics',
          },
        ]}
        title={strings.title || 'Analytics'}
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && (
              <>
                {!hasFilteredDigitalPages && <ShareLinkBlankState />}

                {hasFilteredDigitalPages
                  && filteredDigitalPages.map(digitalPage => (
                    <ShareLinkGroup digitalPage={digitalPage} key={digitalPage.id} />
                  ))}
              </>
            )}
          </Columns.Main>

          <Columns.Sidebar>
            <LoadingModule loading={loading} times={3} />

            {!loading && <Statistics project={project} />}

            <Box marginTop="large">
              <VideoModule videosPayload={videosPayload} />
            </Box>
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

PageContent.propTypes = {
  project: PropTypes.object.isRequired,
}

export default PageContent
