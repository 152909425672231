import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faPlus, faEye } from '@fortawesome/pro-light-svg-icons'

import useLocalization from '@hooks/useLocalization'

import { Box, ListItem } from '@campaignhub/suit-theme'

import localizedStrings from './localizedStrings'

const DigitalTemplateListItemHeader = () => {
  const { strings } = useLocalization(localizedStrings)

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        borderBottom: '1px solid',
        padding: 'large',
      }}
      disableHover
    >
      <Box alignItems="center" color="bodyFontLightColor" fontSize="xsmall" justifyContent="space-between">
        <Box alignItems="center" style={{ whiteSpace: 'nowrap' }}>
          {strings.pageTitle || 'Page Title'}
        </Box>

        <Box fontSize="small" width="fit-content">
          <Box justifyContent="center" marginRight="large" width={20}>
            <FontAwesomeIcon icon={faPlus} />
          </Box>

          <Box justifyContent="center" width={20}>
            <FontAwesomeIcon icon={faEye} />
          </Box>
        </Box>
      </Box>
    </ListItem>
  )
}

export default DigitalTemplateListItemHeader
