import { projectColours, projectLetterColorKeys } from '@functions/project'

const getEntityColor = (letter: string) => {
  const entityLetterColour = projectLetterColorKeys[letter] || 'yellow'
  const entityColour = projectColours[entityLetterColour]

  return entityColour
}

function useEntityTheme(entityTitle: string) {
  const entityFirstLetter = entityTitle.substring(0, 1)?.toLowerCase()

  return {
    colors: getEntityColor(entityFirstLetter),
    entityFirstLetter,
    entityTitle,
  }
}

export default useEntityTheme
