import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities } from '@redux/entities'

import api from '@functions/api'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/chart/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/chart/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/chart/FETCH_FAILURE'

// Initial State
const initialState = {
  errors: [],
  loaded: false,
  loadedForKeys: [],
  loading: false,
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
  }
}

export function fetchSuccess(){
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

// Action Creators
export function loadChartData(endpoint, options = {}){
  const { chart_data_key } = options

  return (dispatch, getState) => {
    const loadedForKeys = [...getState().charts.loadedForKeys]
    if (chart_data_key && !loadedForKeys.includes(chart_data_key)){
      loadedForKeys.push(chart_data_key)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api(endpoint, options)
      .then(({ data }) => {
        const normalizedJson = normalize(data.data, Schemas.CHART_DATA)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
