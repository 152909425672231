import React from 'react'
import PropTypes from 'prop-types'

import useCreateExternalAgreement from '@hooks/useCreateExternalAgreement'

import BuildAgreementContext from '../../contexts/buildAgreementContext'

const BuildAgreementContextProvider = (props) => {
  const { children } = props

  const createExternalAgreementPayload = useCreateExternalAgreement()

  return (
    <BuildAgreementContext.Provider value={createExternalAgreementPayload}>
      {children}
    </BuildAgreementContext.Provider>
  )
}

BuildAgreementContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default BuildAgreementContextProvider
