import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import {
  Box, FormField, Link, SidebarModal,
} from '@campaignhub/suit-theme'

import FilterUsersContactsAndTeams from '@components/FilterUsersContactsAndTeams'

import useLocalization from '@hooks/useLocalization'

import useEditAgreementRecipient from './hooks/useEditAgreementRecipient'

import localizedStrings from './localizedStrings'

const AgreementRecipient = (props) => {
  const { agreementId, documentRecipientFormPayload } = props

  const {
    entityState: { subject_id, subject_type },
    setEntityState,
  } = documentRecipientFormPayload

  const editAgreementRecipientPayload = useEditAgreementRecipient({
    agreementId,
    subjectId: subject_id,
    subjectType: subject_type,
  })

  const {
    assignedContactIds,
    assignedUserIds,
    callbacks: { toggleRecipient },
    subjectProps,
  } = editAgreementRecipientPayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <Box flexDirection="column" flexShrink={0}>
      <FormField direction="column" label={strings.formFieldLabels?.searchUsers || 'Search Users and Contacts'}>
        <FilterUsersContactsAndTeams
          callbacks={{
            selectItem: selected => toggleRecipient(selected, () => setEntityState({ subject_id: selected.id, subject_type: selected.type })),
          }}
          disabled={!!subject_id}
          disabledText={strings.disabledText || 'User Assigned'}
          selectedContactIds={assignedContactIds}
          selectedUserIds={assignedUserIds}
          shouldSearchContacts
        />
      </FormField>

      {subject_id && (
        <SidebarModal.User disableHover {...subjectProps}>
          <Link
            greyLink
            onClick={() => toggleRecipient(
              { type: subject_type, id: subject_id },
              () => setEntityState({ subject_id: null, subject_type: null }),
            )}
            textProps={{ fontSize: 'small' }}
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </Link>
        </SidebarModal.User>
      )}
    </Box>
  )
}

AgreementRecipient.propTypes = {
  agreementId: PropTypes.number.isRequired,
  documentRecipientFormPayload: PropTypes.object.isRequired,
}

export default AgreementRecipient
