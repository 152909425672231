import { useContext } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArchive, faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, FormField, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import { useDeepEffect, useSetState } from '@campaignhub/react-hooks'

import { digObject } from '@campaignhub/javascript-utils'

import useAddress, { useAddressForm } from '@hooks/useAddress'
import useImage from '@hooks/useImage'
import useLocalization from '@hooks/useLocalization'
import useReduxAction from '@hooks/useReduxAction'
import useReview, { useReviewForm } from '@hooks/useReview'
import { useRelations } from '@hooks/useProject'

import ModalImageUpload from '@components/ModalComponents/ModalImageUpload'
import Search from '@components/Search'

import defaultRequestOptions from '@sections/Client/packs/Admin/defaultRequestOptions'

import ReviewForm from './components/ReviewForm'
import localizedStrings from './localizedStrings'

const confirmUnarchiveReview = (params) => {
  const {
    callbacks: { updateReview }, closeModal, entityState, strings, updateFn,
  } = params

  const reviewPayload = {
    callbacks: {
      action: updateFn,
      afterAction: closeModal(),
    },
    entityParams: { ...entityState, hidden: false },
    requestOptions: defaultRequestOptions.review,
    toastText: strings.toast?.toggleReviewStatus?.unarchive || 'Review Unarchived Successfully',
  }

  swal
    .fire({
      title: strings.sweetAlert?.toggleReviewStatus?.unarchiveTitle || 'Unarchive Review?',
      text: strings.sweetAlert?.toggleReviewStatus?.text || 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: strings.sweetAlert?.toggleReviewStatus?.unarchiveButton || 'Yes, unarchive it.',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        updateReview(reviewPayload)
      }
    })
}

const confirmDelete = (params) => {
  const { deleteFn, callbacks, strings } = params
  const { closeModal, deleteReview } = callbacks

  const targetAudiencePayload = {
    callbacks: {
      action: deleteFn,
      afterAction: closeModal,
    },
    requestOptions: defaultRequestOptions.review,
    toastText: strings.toast?.reviewDeleted || 'Review Deleted Successfully',
  }

  swal
    .fire({
      title: strings.sweetAlert?.delete?.title || 'Delete Review?',
      text: strings.sweetAlert?.delete?.text || 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: strings.sweetAlert?.delete?.confirmButton || 'Yes, delete it.',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        deleteReview(targetAudiencePayload)
      }
    })
}

const confirmDeleteFromProjects = (params) => {
  const { deleteFromProjectsFn, callbacks, strings } = params
  const { closeModal, deleteFromProjects } = callbacks

  const reviewPayload = {
    callbacks: {
      action: deleteFromProjectsFn,
      afterAction: closeModal,
    },
    toastText: strings.toast?.reviewDeletedFromProjects || 'Review Removed from Projects',
  }

  swal
    .fire({
      title: strings.sweetAlert?.deleteFromProjects?.title || 'Remove from Projects?',
      text: strings.sweetAlert?.deleteFromProjects?.text || 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: strings.sweetAlert?.deleteFromProjects?.confirmButton || 'Yes, remove it.',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        deleteFromProjects(reviewPayload)
      }
    })
}

const mapProjectResults = ({ entities: projects }, strings) => ({
  groups: [
    {
      key: 'projects',
      title: strings.projectList || 'PROJECTS',
      items: projects.map(project => ({
        id: project.id,
        key: project.id,
        text: project.title,
      })),
    },
  ],
})

const createOrUpdateReview = (params) => {
  const {
    entityState, callbacks, createFn, updateFn, createOrEditReview, strings,
  } = params
  const { closeModal, createReview, updateReview } = callbacks

  const toastText = entityState.id
    ? strings.toast?.reviewUpdated || 'Review Updated Successfully'
    : strings.toast?.reviewCreated || 'Review Created Successfully'

  const actionFn = entityState.id ? updateFn : createFn
  const afterActionCallback = data => (entityState.id ? closeModal() : createOrEditReview({ review: data?.entity }))

  const reviewPayload = {
    callbacks: {
      action: actionFn,
      afterAction: ({ response: { data } }) => afterActionCallback(data),
    },
    entityParams: entityState,
    requestOptions: defaultRequestOptions.review,
    toastText,
  }

  return entityState.id ? updateReview(reviewPayload) : createReview(reviewPayload)
}

const defaultState = {
  selectedProject: {},
}

const CreateOrEditReviewModal = (props) => {
  const { callbacks, modalKey, showModal } = props
  const { closeModal } = callbacks

  const [state, setState] = useSetState(defaultState)
  const { selectedProject } = state

  useReduxAction('projects', 'loadProject', {}, [selectedProject.id], {
    dispatchAction: action => action(selectedProject.id, { ...defaultRequestOptions.project }),
    shouldPerformFn: (entityReducer) => {
      const { loadedIds, loading } = entityReducer
      return selectedProject.id && !loadedIds.includes(selectedProject.id) && !loading
    },
  })

  const { projects } = useSelector(reduxState => reduxState.entities)
  const project = projects[selectedProject.id] || {}
  const userIds = digObject(project, 'lead_user_ids', [])

  const { address: projectAddress } = useRelations(project)

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const reviewPayload = digObject(modalData, `${modalKey}`, {})
  const { review } = reviewPayload

  const {
    address,
    creating,
    callbacks: {
      createOrEditReview,
      createReview: createFn,
      deleteFromProjects: deleteFromProjectsFn,
      deleteReview: deleteFn,
      updateReview: updateFn,
    },
    defaultImage,
    updating,
  } = useReview(review)

  const {
    callbacks: { createOrEditAddress },
  } = useAddress(address)

  const {
    callbacks: { deleteImage },
    deleting,
  } = useImage(defaultImage)

  const defaultImageUrl = digObject(defaultImage, 'sizes.thumb_660x360', '')

  const reviewFormPayload = useReviewForm(review)
  const {
    entityState,
    entityState: { address_attributes, hidden },
    saveEnabled,
    setEntityState,
  } = reviewFormPayload

  const { entityState: projectAddressForm } = useAddressForm(projectAddress)

  useDeepEffect(() => {
    if (project.id){
      const reviewAddress = {
        ...projectAddressForm,
        id: null,
      }

      setEntityState({
        address_attributes: reviewAddress,
        user_ids: userIds,
      })
    }
  }, [project.id, projectAddressForm])

  const hasAddressAttributes = !!Object.keys(address_attributes).length
  const hasAddress = hasAddressAttributes || !!Object.keys(address).length

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        title={review.id ? strings.updateTitle || 'Update' : strings.createTitle || 'Create'}
        titleSecondLine={strings.titleSecondLine || 'Review'}
      />

      <SidebarModal.Content>
        <FormField direction="column" label={strings.formFieldLabels?.searchProject || 'Find a Project'}>
          <Search
            boxProps={{ marginBottom: 'large' }}
            callbacks={{
              selectItem: selected => setState({ selectedProject: selected }),
              mapResults: data => mapProjectResults(data, strings),
            }}
            endpoint="/projects.json"
            placeholder={strings.formFieldLabels?.projectPlaceholder || 'Search for a project'}
          />
        </FormField>

        <ReviewForm address={address} callbacks={{ createOrEditAddress }} reviewFormPayload={reviewFormPayload} />

        <Box flexShrink={0} flexDirection="column">
          {entityState.id && (
            <ModalImageUpload
              imageLabel={strings.reviewImageLabel || 'Review Image'}
              imageSizes={['thumb_660x360', 'thumb_200x120', 'thumb_120x80']}
              imageUrl={defaultImageUrl}
              ownerId={review.owner_id}
              ownerType="Organization"
              subjectId={review.id}
              subjectType="Review"
            />
          )}

          {!!review.id && (
            <FormField direction="column" label={strings.moreOptions || 'More Options'} marginTop="large">
              {!!defaultImage.id && <ModalImageUpload.ReplaceImage loading={deleting} onClick={() => deleteImage()} />}

              <Button
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faTrashAlt} />}
                marginBottom="medium"
                onClick={() => confirmDeleteFromProjects({ callbacks, deleteFromProjectsFn, strings })}
                size="medium"
                width="100%"
              >
                {strings.removeFromProjects || 'Remove from Projects'}
              </Button>

              {hidden && (
                <Button
                  buttonStyle="secondaryUtility"
                  icon={<FontAwesomeIcon icon={faArchive} />}
                  marginBottom="medium"
                  onClick={() => confirmUnarchiveReview({
                    callbacks,
                    closeModal,
                    strings,
                    entityState,
                    updateFn,
                  })}
                  size="medium"
                  width="100%"
                >
                  {strings.buttons?.unarchiveReview || 'Unarchive Review'}
                </Button>
              )}

              {!hidden && (
                <Button
                  buttonStyle="secondaryUtility"
                  onClick={() => confirmDelete({ callbacks, deleteFn, strings })}
                  icon={<FontAwesomeIcon icon={faTrashAlt} />}
                  size="medium"
                  width="100%"
                >
                  {strings.deleteReview || 'Delete Review'}
                </Button>
              )}
            </FormField>
          )}
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="secondary"
          onClick={() => closeModal()}
          size="medium"
          style={{ marginRight: 4, width: 'calc(50% - 4px)' }}
        >
          {strings.cancelButton || 'Cancel'}
        </Button>

        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled || !hasAddress}
          loading={creating || updating}
          onClick={() => createOrUpdateReview({
            callbacks,
            createFn,
            createOrEditReview,
            entityState,
            strings,
            updateFn,
          })}
          size="medium"
          style={{ marginLeft: 4, width: 'calc(50% - 4px)' }}
        >
          {strings.saveReview || 'Save Review'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

CreateOrEditReviewModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

CreateOrEditReviewModal.defaultProps = {
  modalKey: 'CreateOrEditReviewModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <CreateOrEditReviewModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
