import React from 'react'
import PropTypes from 'prop-types'

import { FormField } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'
import useOrganizationTypes from '@hooks/useOrganizationTypes'
import useProjectTypes from '@hooks/useProjectTypes'

import localizedStrings from './localizedStrings'

const CustomFieldSetKeySelector = (props) => {
  const {
    callbacks: { setCustomFieldSetKey },
    customFieldSetKey,
    customFieldSetFilterType,
  } = props

  const { groupedProjectTypes } = useProjectTypes()

  const { organizationTypes } = useOrganizationTypes()

  const { strings } = useLocalization(localizedStrings)

  return (
    <FormField
      direction="column"
      boxProps={{ marginBottom: 'large' }}
      label={
        customFieldSetFilterType === 'Project'
          ? strings.formFieldLabels?.projectType || '* Project Type'
          : strings.formFieldLabels?.organizationType || '* Organization Type'
      }
    >
      {customFieldSetFilterType === 'Project' && (
        <select name="customFieldSetKey" value={customFieldSetKey} onChange={e => setCustomFieldSetKey(e.target.value)}>
          {groupedProjectTypes.map(projectType => (
            <optgroup key={projectType.id} label={projectType.title}>
              {projectType.subtypes.map(subtype => (
                <option key={subtype.id} value={subtype.key}>
                  {subtype.title}
                </option>
              ))}
            </optgroup>
          ))}
        </select>
      )}

      {customFieldSetFilterType === 'Organization' && (
        <select name="customFieldSetKey" value={customFieldSetKey} onChange={e => setCustomFieldSetKey(e.target.value)}>
          {Object.values(organizationTypes).map(organizationType => (
            <option key={organizationType.id} value={organizationType.key}>
              {organizationType.title}
            </option>
          ))}
        </select>
      )}
    </FormField>
  )
}

CustomFieldSetKeySelector.propTypes = {
  callbacks: PropTypes.shape({
    setCustomFieldSetKey: PropTypes.func.isRequired,
  }).isRequired,
  customFieldSetKey: PropTypes.string,
  customFieldSetFilterType: PropTypes.string,
}

export default CustomFieldSetKeySelector
