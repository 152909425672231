import { useEffect, useMemo } from 'react'

import PropTypes from 'prop-types'

import { useModals, useSetState, useShowModal } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import { getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'

import handleCallbackAction from '@functions/handleCallbackAction'

import CreateOrEditAddressModal from '@modals/CreateOrEditAddressModal'
import CreateOrEditComparableModal from '@modals/CreateOrEditComparableModal'
import EditImageModal from '@modals/EditImageModal'
import EditImagesModal from '@modals/EditImagesModal'
import FindComparablesModal from '@modals/FindComparablesModal'

import useMixpanel from '@hooks/useMixpanel'

import defaultRequestOptions from '@sections/Client/packs/Project/defaultRequestOptions'

import PageContent from './components/PageContent'

const callbacks = (component, setState, onAction) => {
  const componentCallbacks = {
    CreateOrEditComparableModal: {
      closeModal: () => setState({ showCreateOrEditComparableModal: false }),
      createComparable: payload => handleCallbackAction({ ...payload, requestOptions: defaultRequestOptions.comparable }),
      deleteComparable: payload => handleCallbackAction(payload),
      showAddressModal: () => setState({ showCreateOrEditAddressModal: true }),
      updateComparable: payload => handleCallbackAction({ ...payload, requestOptions: defaultRequestOptions.comparable }),
    },
    CreateOrEditAddressModal: {
      closeModal: () => setState({ showCreateOrEditAddressModal: false }),
      createAddress: payload => handleCallbackAction(payload),
      updateAddress: payload => handleCallbackAction(payload),
    },
    EditImageModal: {
      closeModal: () => setState({ showEditImageModal: false }),
      deleteImage: payload => handleCallbackAction(payload),
    },
    EditImagesModal: {
      closeModal: () => setState({ showEditImagesModal: false }),
      onUpload: onAction,
    },
    FindComparablesModal: {
      closeModal: () => setState({ showFindComparablesModal: false }),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  showCreateOrEditAddressModal: false,
  showCreateOrEditComparableModal: false,
  showEditImageModal: false,
  showEditImagesModal: false,
  showFindComparablesModal: false,
}

const Comparables = (props) => {
  const { projectId } = props

  const [state, setState] = useSetState(defaultState)
  const {
    showCreateOrEditAddressModal,
    showCreateOrEditComparableModal,
    showEditImageModal,
    showEditImagesModal,
    showFindComparablesModal,
  } = state

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const pageContext = useMemo(
    () => ({
      callbacks: {
        showCreateOrEditAddressModal: (payload) => {
          setModalData('CreateOrEditAddressModal', payload)
          setState({ showCreateOrEditAddressModal: true })
        },
        showCreateOrEditComparableModal: (payload) => {
          setModalData('CreateOrEditComparableModal', payload)
          setState({ showCreateOrEditComparableModal: true })
        },
        showEditImageModal: (payload) => {
          setModalData('EditImageModal', payload)
          setState({ showEditImageModal: true })
        },
        showEditImagesModal: (payload) => {
          setModalData('EditImagesModal', payload)
          setState({ showEditImagesModal: true })
        },
        showFindComparablesModal: () => setState({ showFindComparablesModal: true }),
      },
    }),
    [modalContext],
  )
  const { showModal } = parsePermittedQueryParams(getQueryParamsFromHash(), ['showModal'])

  // Automatically show modal
  useShowModal({
    modalKey: showModal,
    options: {
      callbacks: pageContext.callbacks,
    },
  })

  // Tracks the page load event
  const { callbacks: { dispatchMixpanelEvent } } = useMixpanel()

  useEffect(() => {
    dispatchMixpanelEvent('Comparables Load')
  }, [])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent projectId={projectId} />

        <FindComparablesModal
          callbacks={callbacks('FindComparablesModal', setState)}
          projectId={projectId}
          showModal={showFindComparablesModal}
        />

        <CreateOrEditComparableModal
          callbacks={callbacks('CreateOrEditComparableModal', setState)}
          showModal={showCreateOrEditComparableModal}
        />

        <CreateOrEditAddressModal
          callbacks={callbacks('CreateOrEditAddressModal', setState)}
          hiddenFields={['title']}
          showModal={showCreateOrEditAddressModal}
        />

        <EditImageModal
          callbacks={callbacks('EditImageModal', setState)}
          showModal={showEditImageModal}
        />

        <EditImagesModal
          callbacks={callbacks('EditImagesModal', setState, () => dispatchMixpanelEvent('Comparable Photo Upload'))}
          showModal={showEditImagesModal}
        />

      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

Comparables.propTypes = {
  projectId: PropTypes.number,
}

export default Comparables
