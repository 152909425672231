export type CaseStudyModel = {
  cache_key: number,
  address_attributes?: {},
  address_id?: number,
  data?: {},
  description: string,
  hidden?: boolean,
  id: number,
  is_default?: boolean,
  owner_id: number,
  owner_type: string,
  subject_id?: number | null,
  subject_type?: string | null,
  user_ids: number[],
}

export type CaseStudyRequestOptions = {
  include_case_study_address?: boolean,
  include_case_study_data_store_items?: boolean,
  include_case_study_default_image?: boolean,
  include_case_study_images?: boolean,
  include_case_study_subject?: boolean,
  include_case_study_user_ids?: boolean,
}

const state = {
  address_attributes: {},
  data: {},
  description: '',
  id: null,
  hidden: false,
  is_default: false,
  owner_id: null,
  owner_type: '',
  subject_id: null,
  subject_type: '',
  user_ids: [],
}

export const requiredFields = [
  { key: 'owner_id' },
  { key: 'owner_type' },
]

export default state
