import AdvancedChart from './components/elements/AdvancedChart'
import Button from './components/elements/Button'
import Carousel from './components/elements/Carousel'
import Chart from './components/elements/Chart'
import Container from './components/elements/Container'
import Counter from './components/elements/Counter'
import DataLoop from './components/elements/DataLoop'
import FormInput from './components/elements/FormInput'
import FormTemplate from './components/elements/FormTemplate'
import HashLink from './components/elements/HashLink'
import Icon from './components/elements/Icon'
import Iframe from './components/elements/Iframe'
import Image from './components/elements/Image'
import ImageCarousel from './components/elements/ImageCarousel'
import Input from './components/elements/Input'
import Map from './components/elements/Map'
import MediaContainer from './components/elements/MediaContainer'
import State from './components/elements/State'
import TextBlock from './components/elements/TextBlock'
import Video from './components/elements/Video'

const availableComponents = {
  advancedChart: AdvancedChart,
  button: Button,
  carousel: Carousel,
  chart: Chart,
  container: Container,
  counter: Counter,
  dataArrayLoop: DataLoop,
  formInput: FormInput,
  formTemplate: FormTemplate,
  hashLink: HashLink,
  icon: Icon,
  iframe: Iframe,
  image: Image,
  imageCarousel: ImageCarousel,
  input: Input,
  map: Map,
  mediaContainer: MediaContainer,
  state: State,
  text: TextBlock,
  video: Video,
}

export function getAvailableComponents(options = {}){
  const components = { ...availableComponents }

  if (options.excludeComponent){
    delete components[options.excludeComponent]
  }

  return components
}

export default availableComponents
