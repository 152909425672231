import { useContext } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons'

import {
  Box,
  Button,
  Form,
  FormField,
  InputSearch,
  ModalContext,
  SidebarModal,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useDigitalPage from '@hooks/useDigitalPage'
import useLocalization from '@hooks/useLocalization'
import useMixpanel from '@hooks/useMixpanel'
import type { MixPanelType } from '@hooks/useMixpanel'

import HintWrapper from '@components/HintWrapper'

import useDigitalPageChartData from './hooks/useDigitalPageChartData'

import SelectedEntity from './components/SelectedEntity'

import localizedStrings from './localizedStrings'

type ModalCallbacks = {
  updateDigitalPage: (payload: {}) => void,
}

interface ManageProjectChartsModalProps {
  callbacks: ModalCallbacks,
  disableAnimation: boolean,
  disableOverlay: boolean,
  showModal: boolean,
}

export type MapResultsType = {
  data: Record<string, []>,
  resultKey: string,
  resultTitle: string,
}

type ChartDataType = {
  chart_number: number,
  data_series: string,
  data_set: string,
  key: string,
}

type DigitalPageParamsType = {
  options?: {
    chartData?: Array<ChartDataType>,
  },
}

type HandleChartUpdateAndTrackParams = {
  digital_template_id?: number,
  digitalPageParams: any| DigitalPageParamsType,
  dispatchMixpanelEvent?: MixPanelType,
  updateDigitalPage: (arg1: DigitalPageParamsType, arg2: () => void) => void,
  updateDigitalPageFn: () => void,
}

const mapResults = (params: MapResultsType) => {
  const { data = {}, resultKey, resultTitle } = params
  const { entities = [] } = data

  const results = {
    groups: [
      {
        key: resultKey,
        title: resultTitle,
        items: Object.values(entities).map(entity => ({
          ...entity,
          text: entity.title,
          key: entity.key,
        })),
      },
    ],
  }

  return results
}

const MODAL_KEY = 'ManageProjectChartsModal'

const handleChartUpdateAndTrack = (params: HandleChartUpdateAndTrackParams) => {
  const {
    digital_template_id, digitalPageParams, dispatchMixpanelEvent, updateDigitalPage, updateDigitalPageFn,
  } = params

  if (dispatchMixpanelEvent){
    dispatchMixpanelEvent('Chart Saved', { digital_template_id })
  }

  updateDigitalPage(digitalPageParams, updateDigitalPageFn)
}

const ManageProjectChartsModal = (props: ManageProjectChartsModalProps) => {
  const {
    callbacks, disableAnimation, disableOverlay, showModal,
  } = props

  const { updateDigitalPage } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const { digitalPage: initDigitalPage } = digObject(modalData, `${MODAL_KEY}`, {})
  const { chartNumber } = digObject(modalData, `${MODAL_KEY}.featureOptions`, '0')

  const {
    callbacks: { updateDigitalPage: updateDigitalPageFn },
    digitalPage,
    updating,
  } = useDigitalPage(initDigitalPage)

  const digitalPageChartDataPayload = useDigitalPageChartData({ digitalPage, chartNumber })
  const {
    callbacks: { buildChartDataPayload, setState },
    state: {
      filterDataSeriesString,
      filterDataSetString,
      selectedDataSeries,
      selectedDataSeriesKey,
      selectedDataSet,
      selectedDataSetKey,
    },
    dataSeriesPayload,
    dataSetsPayload,
  } = digitalPageChartDataPayload

  const { callbacks: { dispatchMixpanelEvent } } = useMixpanel()

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal
      callbacks={callbacks}
      disableAnimation={disableAnimation}
      disableOverlay={disableOverlay}
      modalKey={MODAL_KEY}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.sideBarModalHeader?.title || 'Manage'}
        titleSecondLine={strings.sideBarModalHeader?.titleSecondLine || 'Chart'}
      />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          {!selectedDataSet && (
            <Form.Field
              boxProps={{ marginBottom: 'large' }}
              label={strings.formFieldLabels?.searchDataSets || 'Search Data Sets'}
            >
              <InputSearch
                onChange={e => setState({ filterDataSetString: e.target.value })}
                value={filterDataSetString}
                callbacks={{
                  selectItem: item => setState({
                    selectedDataSet: item.title,
                    selectedDataSetKey: item.key,
                  }),
                }}
                closeOnSelect
                placeholder="Search for a data set"
                results={mapResults({ data: dataSetsPayload, resultKey: 'dataSets', resultTitle: 'Data Sets' })}
                showOnFocus
              />
            </Form.Field>
          )}

          {selectedDataSet && (
            <FormField label={strings.formFieldLabels?.dataSet || '*Data Set'}>
              <SelectedEntity
                selectedEntityTitle={selectedDataSet}
                callbacks={{
                  deselectEntity: () => setState({
                    selectedDataSeries: '',
                    selectedDataSeriesKey: '',
                    selectedDataSet: '',
                    selectedDataSetKey: '',
                  }),
                }}
              />
            </FormField>
          )}

          {!selectedDataSeries && selectedDataSet && (
            <Form.Field
              boxProps={{ marginBottom: 'large' }}
              label={(
                <>
                  <Box marginRight="small">{strings.formFieldLabels?.searchDataSeries || '*Search Data Series'}</Box>

                  <HintWrapper hint={strings.formFieldLabels?.hintMessage || 'Start typing to search'}>
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </HintWrapper>
                </>
              )}
            >
              <InputSearch
                callbacks={{
                  selectItem: item => setState({
                    selectedDataSeries: item.title,
                    selectedDataSeriesKey: item.key,
                  }),
                }}
                closeOnSelect
                showOnFocus
                onChange={e => setState({ filterDataSeriesString: e.target.value })}
                results={mapResults({ data: dataSeriesPayload, resultKey: 'dataSeries', resultTitle: 'Data Series' })}
                value={filterDataSeriesString}
              />
            </Form.Field>
          )}

          {selectedDataSeries && (
            <Form.Field
              label={(
                <>
                  <Box marginRight="small">{strings.formFieldLabels?.dataSeries || '*Data Series'}</Box>

                  <HintWrapper hint={strings.formFieldLabels?.hintMessage || 'Start typing to search'}>
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </HintWrapper>
                </>
              )}
            >

              <SelectedEntity
                selectedEntityTitle={selectedDataSeries}
                callbacks={{
                  deselectEntity: () => setState({ selectedDataSeries: '', selectedDataSeriesKey: '' }),
                }}
              />
            </Form.Field>
          )}
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          loading={updating}
          disabled={!selectedDataSeriesKey && !!selectedDataSetKey}
          loadingBubbleColor="white"
          onClick={() => handleChartUpdateAndTrack({
            digital_template_id: (digitalPage?.digital_template_id),
            digitalPageParams: buildChartDataPayload(),
            dispatchMixpanelEvent,
            updateDigitalPage,
            updateDigitalPageFn,
          })}
          size="large"
        >
          {strings.buttons?.save || 'Save'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ManageProjectChartsModalProps) => (
  <SidebarModal.RenderController {...props}>
    <ManageProjectChartsModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
