import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolderOpen } from '@fortawesome/pro-light-svg-icons'

import {
  Box, ListItem, Text,
} from '@campaignhub/suit-theme'

import useAssetLibrary from '@hooks/useAssetLibrary'
import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const FileLibrary = (props) => {
  const { fileLibrary } = props
  const { title } = fileLibrary

  const {
    callbacks: { createOrEditAssetLibrary },
    createdDateTime,
  } = useAssetLibrary(fileLibrary)

  const { callbacks: { formatDate }, strings } = useLocalization(localizedStrings)

  return (
    <ListItem
      boxProps={{ borderBottom: '1px dashed', padding: 'large' }}
      onClick={createOrEditAssetLibrary}
      showAngleIcon
    >
      <Box
        alignItems="center"
        backgroundColor="whiteGrey"
        border="1px solid"
        borderColor="hoverGrey"
        borderRadius={5}
        height={60}
        justifyContent="center"
        marginRight="large"
        width={100}
      >
        <FontAwesomeIcon icon={faFolderOpen} />
      </Box>

      <Box flexDirection="column" justifyContent="center">
        <Text fontSize="small" color="bodyFontLightColor">
          {title}
        </Text>

        {createdDateTime && (
          <Text fontSize="xsmall" marginTop="medium" color="faintGrey">
            {`${strings.createdText || 'Created'} ${formatDate(createdDateTime, DateTime.DATE_HUGE)}`}
          </Text>
        )}
      </Box>
    </ListItem>
  )
}

FileLibrary.propTypes = {
  fileLibrary: PropTypes.object.isRequired,
}

export default FileLibrary
