import PropTypes from 'prop-types'

import {
  Box, FormField,
} from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const spans = {
  25: 1,
  50: 1,
  75: 2,
  100: 2,
}

const convertWidthToGridSpan = fieldWidth => spans[fieldWidth] || 2

const Filter = (props) => {
  const { changeFilter, filter } = props
  const {
    dateField,
    hideValueMax,
    icon,
    id,
    placeholderMax,
    placeholderMin,
    selectValues,
    title,
    value,
    valueMax,
    width,
  } = filter

  return (
    <FormField
      label={title}
      marginTop="medium"
      style={{ gridColumnEnd: `span ${convertWidthToGridSpan(width)}` }}
    >
      {!!selectValues && (
        <select onChange={e => changeFilter(id, 'value', e.target.value)} value={value}>
          {selectValues.map(option => (
            <option key={option.value} value={option.value}>
              {option.title}
            </option>
          ))}
        </select>
      )}

      <Box alignItems="center">
        {!!icon && <FontAwesomeIcon icon={icon} />}

        {!selectValues && !dateField && (
          <Box flex={2}>
            <input
              onChange={e => changeFilter(id, 'value', e.target.value)}
              placeholder={placeholderMin}
              style={{ marginLeft: 8 }}
              value={value}
            />

            {!hideValueMax && (
              <input
                onChange={e => changeFilter(id, 'valueMax', e.target.value)}
                placeholder={placeholderMax}
                style={{ marginLeft: 8 }}
                value={valueMax}
              />
            )}
          </Box>
        )}

      </Box>

      {dateField && (
        <Box flex={2}>
          <input
            onChange={e => changeFilter(id, 'value', e.target.value)}
            type="date"
            value={value}
          />
        </Box>
      )}
    </FormField>
  )
}

Filter.propTypes = {
  changeFilter: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
}

export default Filter
