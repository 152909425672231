import React from 'react'

import { BlankState } from '@campaignhub/suit-theme'
import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

import blankCaseStudiesUrl from './assets/no-case-studies.svg'

const CaseStudiesBlankState = () => {
  const { strings } = useLocalization(localizedStrings)

  return (
    <BlankState boxProps={{ marginBottom: ['large', 0] }} imageUrl={blankCaseStudiesUrl}>
      <BlankState.Title>{strings.title || 'No Case Studies Created'}</BlankState.Title>
      <BlankState.Paragraph>
        {strings.paragraph
          || "You either haven't created any case studies, or you need to adjust your filters to return more results. If you haven't started yet you can create one now from the button at the top of the page."}
      </BlankState.Paragraph>
    </BlankState>
  )
}

export default CaseStudiesBlankState
