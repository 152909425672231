export default {
  default: {
    title: {
      title: 'Media',
    },
    labelTitle: {
      label: 'Title',
    },
    displayMode: {
      label: 'Display Mode',
    },
    displayModeValues: {
      labelListTile: 'List (Tile)',
      labelListLarge: 'List (Detail)',
    },
    limit: {
      label: 'Limit',
    },
    blankState: {
      title: 'No Images Uploaded',
      paragraph: 'You don’t have any images stored against this project. You can upload images and use them in digital pages to send more information to clients easily.',
    },
    manage: 'Manage',

  },
}
