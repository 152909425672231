import { useContext } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTrashAlt, faUserCog } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, FormField, ModalContext, SidebarNoticeBox, SidebarModal,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import useCurrentUser from '@hooks/useCurrentUser'
import useLocalization from '@hooks/useLocalization'
import useOrganizationsUser, { useOrganizationsUserForm } from '@hooks/useOrganizationsUser'

import localizedStrings from './localizedStrings'

const MODAL_KEY = 'EditOrganizationsUserModal'

interface EditOrganizationsUserModalProps {
  callbacks: {
    closeModal: () => void,
    setDefaultOrganization: (payload: HandleCallbackActionParams) => void,
    updateOrganizationsUser: (payload: HandleCallbackActionParams) => void,
  },
  showModal: boolean,
}

const EditOrganizationsUserModal = (props: EditOrganizationsUserModalProps) => {
  const { callbacks, showModal } = props
  const { closeModal, setDefaultOrganization, updateOrganizationsUser } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext
  const modalPayload = digObject(modalData, MODAL_KEY, {})

  const {
    callbacks: { updateOrganizationsUser: updateFn, setOrganizationsUserAsDefault: setDefaultFn },
    organizationsUser: initOrganizationsUser,
  } = modalPayload

  const { isAdmin } = useCurrentUser()

  const {
    callbacks: { getUserLevelKey, handleUserLevelChange, removeOrganizationsUser },
    canUserLeave,
    isCurrentUserAdminForOrganization,
    isCurrentUserOwnerForOrganization,
    isSelf,
    organization,
    organizationsUser,
    organizationsUser: { default: isDefault },
    updating,
  } = useOrganizationsUser(initOrganizationsUser)

  const userFormPayload = useOrganizationsUserForm(organizationsUser)
  const {
    entityState,
    entityState: { admin, approved },
    setEntityState,
    saveEnabled,
  } = userFormPayload

  const { strings } = useLocalization(localizedStrings)

  const defaultOrganizationPayload = {
    callbacks: {
      action: setDefaultFn,
      afterAction: closeModal,
    },
    toastText: strings.toast?.setDefault || 'Organization Set as Default',
  }

  const updatedOrganizationUserPayload = {
    callbacks: {
      action: updateFn,
      afterAction: closeModal,
    },
    entityParams: entityState,
    toastText: strings.toast?.updateOrganizationUser || 'User Organization Settings Updated',
  }

  return (
    <SidebarModal callbacks={callbacks} modalKey={MODAL_KEY} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.title || 'Update'}
        titleSecondLine={strings.titleSecondLine || 'Organization Details'}
      />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <SidebarNoticeBox>
            <SidebarNoticeBox.Paragraph boxProps={{ marginTop: 0 }}>
              {strings.noticeBox?.description || 'You are editing'} <strong>{organization.title}</strong>
            </SidebarNoticeBox.Paragraph>

            {isCurrentUserAdminForOrganization && !isSelf && (
              <SidebarNoticeBox.Paragraph boxProps={{ marginTop: 8 }}>
                {strings.noticeBox?.roleChange || "Changing role will alter this user's permissions"}
              </SidebarNoticeBox.Paragraph>
            )}
          </SidebarNoticeBox>

          <FormField direction="column" label={strings.role || 'Role'} boxProps={{ marginBottom: 'large' }}>
            {((!isAdmin && !isCurrentUserAdminForOrganization) || isSelf) && (
              <input value={admin ? strings.adminUser || 'Admin' : strings.userRole || 'Organization User'} disabled />
            )}

            {(isAdmin || isCurrentUserAdminForOrganization) && !isSelf && (
              <select
                data-value-type="boolean"
                name="userLevel"
                value={getUserLevelKey(entityState)}
                onChange={e => handleUserLevelChange({ userLevelKey: e.target.value, setEntityState })}
              >
                {(isAdmin || isCurrentUserAdminForOrganization) && (
                  <option value="admin">{strings.formFieldOptions?.admin || 'Admin'}</option>
                )}
                {(isAdmin || isCurrentUserOwnerForOrganization) && (
                  <option value="owner">{strings.formFieldOptions?.owner || 'Owner'}</option>
                )}
                <option value="user">{strings.formFieldOptions?.user || 'User'}</option>
              </select>
            )}
          </FormField>

          {isAdmin && (
            <FormField direction="column" label={strings.approved || 'Approved'} boxProps={{ marginBottom: 'large' }}>
              <select
                data-value-type="boolean"
                name="approved"
                value={approved}
                onChange={e => setEntityState({ approved: e.target.value })}
              >
                <option value="true">{strings.formFieldOptions?.true || 'True'}</option>
                <option value="false">{strings.formFieldOptions?.false || 'False'}</option>
              </select>
            </FormField>
          )}

          <FormField direction="column" label={strings.moreOptions || 'More Options'}>
            <Box flexDirection="column">
              <Button
                buttonStyle="secondaryUtility"
                disabled={isDefault}
                icon={<FontAwesomeIcon icon={isDefault ? faCheck : faUserCog} />}
                marginBottom="medium"
                onClick={() => setDefaultOrganization(defaultOrganizationPayload)}
                size="medium"
              >
                {isDefault ? strings.default || 'Default' : strings.setDefault || 'Set as Default'}
              </Button>

              {canUserLeave && !isSelf && (
                <Button
                  buttonStyle="secondaryUtility"
                  icon={<FontAwesomeIcon icon={faTrashAlt} />}
                  marginBottom="medium"
                  onClick={() => removeOrganizationsUser()}
                  size="medium"
                >
                  {strings.removeFromOrganization || 'Remove from Organization'}
                </Button>
              )}
            </Box>
          </FormField>
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={updating}
          onClick={() => updateOrganizationsUser(updatedOrganizationUserPayload)}
          size="large"
        >
          {strings.saveChanges || 'Save Changes'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <EditOrganizationsUserModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
