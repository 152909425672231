import { MainContent, TopBar } from '@campaignhub/suit-theme'

import authorizeOrRedirectUser from '@functions/authorizeOrRedirectUser'

import useCurrentUser from '@hooks/useCurrentUser'
import useIntercom from '@hooks/useIntercom'
import useMainNavigation from '@hooks/useMainNavigation'
import useOrganizationSelector from '@hooks/useOrganizationSelector'
import useUsers from '@hooks/useUsers'

import DashboardSearch from '@components/DashboardSearch'
import LoggedInUser from '@components/LoggedInUser'
import MainNavigation from '@sections/Client/components/MainNavigation'

import AdminRoutes from './routes'
import Navigation from './components/Navigation'

const Admin = () => {
  const {
    callbacks: { toggleNavigation },
  } = useMainNavigation()

  useIntercom()

  const { currentUser, organizationsWhereAdminIds } = useCurrentUser()

  const { selectedOrganization } = useOrganizationSelector({ autoSelectAdmin: true })

  authorizeOrRedirectUser({ currentUser, organizationsWhereAdminIds, selectedOrganization })

  useUsers({ performHttpRequests: true })

  // TODO - Load Brands, Organizations

  return (
    <>
      <TopBar
        callbacks={{ toggleNavigation }}
        loggedInUserComponent={<LoggedInUser />}
        nestedNavigation
        searchComponent={<DashboardSearch searchEntities={['Project']} />}
      />

      <MainNavigation minimized nestedNavigation={<Navigation />} />

      <MainContent nestedNavigation offset={{ top: TopBar.topBarHeight }}>
        <AdminRoutes />
      </MainContent>
    </>
  )
}

export default Admin
