import React from 'react'
import PropTypes from 'prop-types'

import { DashboardModule, NumberWithIconGrid } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import FormIcon from '@components/Icons/FormIcon'
import ResponsesIcon from '@components/Icons/ResponsesIcon'

import localizedStrings from './localizedStrings'

const FormResponsesStats = (props) => {
  const { filteredFormResponsesCounts, formCount } = props

  const { strings } = useLocalization(localizedStrings)

  return (
    <DashboardModule title={strings.title || 'Stats'}>
      <NumberWithIconGrid>
        <NumberWithIconGrid.Item
          disableHover
          label={strings.response || 'Responses'}
          icon={<ResponsesIcon width={40} height={40} fillColor="#8C8C8E" />}
          value={filteredFormResponsesCounts}
        />

        <NumberWithIconGrid.Item
          disableHover
          icon={<FormIcon width={40} height={40} fillColor="#8C8C8E" />}
          label={strings.forms || 'Forms'}
          value={formCount}
        />
      </NumberWithIconGrid>
    </DashboardModule>
  )
}

FormResponsesStats.propTypes = {
  filteredFormResponsesCounts: PropTypes.number.isRequired,
  formCount: PropTypes.object.isRequired,
}

export default FormResponsesStats
