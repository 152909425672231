import { useMemo } from 'react'

import {
  digObject, matchFilterKey, matchFilterNumber, sortArrayBy,
} from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import sortEntitiesByDate from '@functions/sortEntitiesByDate'

import defaultRequestOptions from '@sections/Client/packs/Project/defaultRequestOptions'

import type { ComparableModel } from '@models/types'
import type { DataStoreItemModel } from '@models/comparable'

const watchEntityKeys = ['comparables']

const matchSoldStatus = (statusFilter: string, dataStoreItems: DataStoreItemModel[]) => {
  const dataStoreItem = dataStoreItems.find(item => item.key === 'sold')

  if (dataStoreItem && statusFilter === 'sold') return dataStoreItem.value === 'true'
  if (dataStoreItem && statusFilter === 'current') return dataStoreItem.value === 'false'
  return false
}

type ComparableFilters = {
  comparableSource?: string,
  comparableStatus?: string,
  limit?: number,
  projectId?: number,
  string?: string,
}

type UseComparablesOptions = {
  filters?: ComparableFilters,
  performHttpRequests?: boolean,
}

function useComparables(options: UseComparablesOptions = {}) {
  const { filters = {} } = options

  const {
    comparableSource: filterComparableSource,
    comparableStatus: filterComparableStatus,
    projectId: filterProjectId,
    string: filterString,
  } = filters

  const {
    updatedEntities: { comparables: comparablesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const {
    entities: { addresses, comparables, dataStoreItems },
    projects: { loading }, // comparables are loaded as an inclusion on projects, not by index route
  } = useSelector(reduxState => reduxState)

  const filteredComparables = useMemo(() => {
    const filtered = Object.values(comparables).filter((comparable: ComparableModel) => {
      const { address_id, project_id, source } = comparable

      const comparableAddress = address_id ? addresses[address_id] || {} : {}
      const fullAddress = digObject(comparableAddress, 'full_address', '')
      const dataStoreIds: number[] = digObject(comparable, 'data_store_items', [])
      const comparableDataStoreItems = dataStoreIds.map(item => dataStoreItems[item]).filter(i => i)

      const addressMatch = filterString ? fullAddress.toLowerCase().includes(filterString) : true
      const comparableMatch = matchFilterNumber(project_id, filterProjectId)
      const sourceMatch = matchFilterKey(source, filterComparableSource)
      const soldStatusMatch = filterComparableStatus && filterComparableStatus !== 'all'
        ? matchSoldStatus(filterComparableStatus, comparableDataStoreItems)
        : true

      return addressMatch && comparableMatch && sourceMatch && soldStatusMatch
    })

    if (filters?.limit){
      return sortEntitiesByDate(filtered, 'desc', 'updated').slice(0, filters?.limit)
    }

    return sortArrayBy(filtered, 'desc', 'id')
  }, [comparablesUpdatedAt, JSON.stringify(filters)])

  const sortedComparables = filteredComparables.reduce(
    (acc, comparable) => {
      acc[comparable.source].push(comparable)
      return acc
    },
    { organization: [], market: [] },
  )

  const { organization: organizationComparables, market: marketComparables } = sortedComparables

  const filteredComparablesCount = filteredComparables.length
  const hasFilteredComparables = !!filteredComparablesCount
  const hasMarketComparables = !!marketComparables.length
  const hasOrganizationComparables = !!organizationComparables.length

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredComparablesCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const entityKey = `Project${filterProjectId}`

  useReduxAction(
    'comparables',
    'loadComparables',
    {
      ...defaultRequestOptions.comparable,
      ...defaultRequestOptions.image,
      ...filtersWithOffset,
      entityKey,
      project_id: filterProjectId,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loadedForKeys }) => filterProjectId && !loadedForKeys.includes(entityKey),
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredComparables,
    hasComparables: hasFilteredComparables,
    hasMarketComparables,
    hasOrganizationComparables,
    loading,
    marketComparables,
    organizationComparables,
  }
}

export default useComparables
