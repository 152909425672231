import { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

import {
  faFire, faGlobe, faHandHoldingUsd, faMugHot, faSnowflake,
} from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Columns, LoadingModule, PageHeader,
} from '@campaignhub/suit-theme'

import { sortArrayBy } from '@campaignhub/javascript-utils'
import { useSetState } from '@campaignhub/react-hooks'

import PageContext from '@contexts/pageContext'

import useDigitalPages from '@hooks/useDigitalPages'
import useLocalization from '@hooks/useLocalization'
import useReduxAction from '@hooks/useReduxAction'
import useVideoModules from '@hooks/useVideoModules'

import defaultRequestOptions from '@sections/Client/packs/Dashboard/defaultRequestOptions'

import DigitalPageListItem from '@components/DigitalPageListItem'
import PageFilterModule from '@components/PageFilterModule'
import VideoModule from '@components/VideoModule'

import DigitalPageBlankState from '../DigitalPageBlankState'

import localizedStrings from './localizedStrings'

const permittedStatusKeys = ['accepted', 'pending', 'processed', 'rejected']

const buildPageFilterFields = (entities, parentDigitalTemplateType, strings, formatString) => {
  const {
    digitalTemplateTypes, organizations, statuses,
  } = entities

  const style = {
    marginBottom: 16,
  }

  const boxProps = {
    marginBottom: 'large',
  }

  const filteredStatuses = Object.values(statuses).filter(status => permittedStatusKeys.includes(status.key))

  const filteredTemplateTypes = Object.values(digitalTemplateTypes).filter(
    type => type.parent_id === parentDigitalTemplateType.id,
  )

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        style,
      },
      key: 'string',
      label: strings.filters?.filterByTitle || 'Filter by Project or Digital Page Title',
    },
    {
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: strings.filters?.defaultSelectLabel || 'All',
        inputComponent: 'select',
        style,
      },
      key: 'owner_id',
      label: strings.filters?.organization,
      values: sortArrayBy(Object.values(organizations), 'asc', 'title').map(organization => ({
        key: organization.id,
        label: organization.title,
      })),
    },
    {
      component: 'EntitySelector',
      componentProps: {
        boxProps,
        endpoint: 'digital_templates',
        entityKey: 'digitalTemplates',
      },
      key: 'digital_template_id',
    },
    {
      component: 'EntitySelector',
      componentProps: {
        entityKey: 'users',
        entityTitleKey: 'full_name',
        boxProps,
      },
      key: 'user_id',
    },
    {
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: strings.filters?.defaultSelectLabel || 'All',
        inputComponent: 'select',
        style,
      },
      key: 'digital_template_type_key',
      label: strings.filters?.filterType || 'Page Type',
      values: sortArrayBy(filteredTemplateTypes).map(digitalTemplateType => ({
        key: digitalTemplateType.key,
        label: digitalTemplateType.title,
      })),
    },
    {
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: strings.filters?.defaultSelectLabel || 'All',
        inputComponent: 'select',
        style,
      },
      key: 'status',
      label: strings.filters?.status || 'Status',
      values: sortArrayBy(filteredStatuses, 'asc', 'title').map(status => ({
        key: status.key,
        label: formatString(strings.filters?.statusTitle, { status }) || status.title,
      })),
    },
    {
      component: 'DynamicInput',
      componentProps: {
        dateFormat: 'DD/MM/YYYY',
        dateParseFormat: 'YYYY-MM-DD',
        fieldName: 'created_date_end',
        inputType: 'date',
        style,
      },
      key: 'created_date_end',
      label: strings.filters?.createDateAfter || 'Created After',
    },
    {
      component: 'DynamicInput',
      componentProps: {
        dateFormat: 'DD/MM/YYYY',
        dateParseFormat: 'YYYY-MM-DD',
        fieldName: 'created_date_start',
        inputType: 'date',
        style,
      },
      key: 'created_date_start',
      label: strings.filters?.createDateBefore || 'Created Before',
    },
    {
      entityKey: 'limit',
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: '50',
        inputComponent: 'select',
        style,
      },
      key: 'limit',
      label: strings.filters?.limit || 'Limit',
      values: [
        { key: 100, label: '100' },
        { key: 150, label: '150' },
        { key: 200, label: '200' },
      ],
    },
  ]
}

const generateTabBarItems = (templateTypeKey, setState, strings) => {
  if (templateTypeKey === 'proposal'){
    return [
      {
        href: '#/overviews/digitalPages?digitalTemplateType=proposal',
        icon: faGlobe,
        key: 'all',
        onClick: () => setState({ engagementStatus: 'all' }),
        title: strings.tabs?.engagementStatus?.all || 'All',
      },
      {
        href: '#/overviews/digitalPages?digitalTemplateType=proposal',
        icon: faFire,
        key: 'hot',
        onClick: () => setState({ engagementStatus: 'hot' }),
        title: strings.tabs?.engagementStatus?.hot || 'Hot',
      },
      {
        href: '#/overviews/digitalPages?digitalTemplateType=proposal',
        icon: faMugHot,
        key: 'warm',
        onClick: () => setState({ engagementStatus: 'warm' }),
        title: strings.tabs?.engagementStatus?.warm || 'Warm',
      },
      {
        href: '#/overviews/digitalPages?digitalTemplateType=proposal',
        icon: faSnowflake,
        key: 'cold',
        onClick: () => setState({ engagementStatus: 'cold' }),
        title: strings.tabs?.engagementStatus?.cold || 'Cold',
      },
    ]
  }

  return [
    {
      href: '#/overviews/digitalPages?digitalTemplateType=proposal',
      icon: faHandHoldingUsd,
      key: 'proposal',
      title: strings.tabs?.templateTypes?.proposals || 'Proposals',
    },
  ]
}

const defaultState = {
  engagementStatus: 'all',
}

const PageContent = (props) => {
  const { digitalTemplateType, pageFilters } = props

  const [state, setState] = useSetState(defaultState)
  const { engagementStatus } = state

  // useLocation to refresh the window.location. If not used location won't reload if URL changes
  useLocation()

  const templateTypeKey = digitalTemplateType.key || 'proposal'

  const { loading } = useReduxAction(
    'digitalPages',
    'loadDigitalPages',
    {
      ...defaultRequestOptions.digitalPage,
      ...defaultRequestOptions.digitalTemplate,
      ...defaultRequestOptions.image,
      ...defaultRequestOptions.project,
      ...defaultRequestOptions.shareLink,
      ...pageFilters,
      digital_template_type: pageFilters.digital_template_type_key || templateTypeKey,
      engagement_status: engagementStatus,
    },
    [engagementStatus, templateTypeKey, JSON.stringify(pageFilters)],
  )

  const pageContext = useContext(PageContext)
  const {
    callbacks: {
      resetFilters, showSelectProjectModal, updateFilters,
    },
    defaultFilters,
  } = pageContext

  const filterCount = Object.keys(pageFilters).length

  const entities = useSelector(reduxState => reduxState.entities)

  const { filteredDigitalPages, hasFilteredDigitalPages } = useDigitalPages({
    templateTypeKey,
    engagementStatus,
    ...pageFilters,
  })

  useEffect(() => resetFilters(), [templateTypeKey])

  const videoModulePayload = useVideoModules({ key: 'proposals' })
  const { videosPayload } = videoModulePayload

  const { callbacks: { formatString }, strings } = useLocalization(localizedStrings, { digitalTemplateType })

  return (
    <>
      <PageHeader
        actionContent={(
          <Button buttonStyle="primaryCreate" onClick={() => showSelectProjectModal()} size="medium">
            {strings.create || 'Create'}
          </Button>
        )}
        activeTabBarItemKey={engagementStatus}
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        tabBarItems={generateTabBarItems(templateTypeKey, setState, strings)}
        title={strings.title || 'Proposals'}
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!hasFilteredDigitalPages && !loading && (
              <DigitalPageBlankState digitalTemplateType={templateTypeKey} filterCount={filterCount} />
            )}

            {!loading && hasFilteredDigitalPages && (
              <Box flexDirection="column">
                {filteredDigitalPages.map(digitalPage => (
                  <DigitalPageListItem key={digitalPage.id} digitalPage={digitalPage} showProjectTitle />
                ))}
              </Box>
            )}
          </Columns.Main>

          <Columns.Sidebar>
            <PageFilterModule
              callbacks={{ resetFilters, updateFilters }}
              defaultFilters={defaultFilters}
              filterFields={buildPageFilterFields(entities, digitalTemplateType, strings, formatString)}
              pageFilters={pageFilters}
              title={strings.filters?.filterTitle || 'Filters'}
            />

            <VideoModule videosPayload={videosPayload} />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

PageContent.propTypes = {
  digitalTemplateType: PropTypes.object.isRequired,
  pageFilters: PropTypes.object.isRequired,
}

export default PageContent
