export default {
  default: {
    buttons: {
      add: 'Add',
      customEmail: 'Add Custom Email',
    },
    select: {
      option: 'Select...',
    },
  },
}
