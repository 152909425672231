import { useEffect } from 'react'

import { digObject } from '@campaignhub/javascript-utils'
import { useSetState } from '@campaignhub/react-hooks'

import useEntityExternalPlatforms from '@hooks/useEntityExternalPlatforms'
import useIntegrations from '@hooks/useIntegrations'

const removePlatform = (key, externalPlatformIds, setState) => {
  const updatedPlatformIds = { ...externalPlatformIds }
  delete updatedPlatformIds[key]

  setState({ editing: false, externalPlatformIds: updatedPlatformIds })
}

const defaultState = {
  editing: false,
  externalPlatformIds: {},
  selectedPlatform: {},
}

const useManageExternalPlatforms = (project) => {
  const data = digObject(project, 'data', {})
  const externalIds = digObject(project, 'external_ids', {})

  const [state, setState] = useSetState(defaultState)
  const { editing, externalPlatformIds, selectedPlatform } = state

  // Gets values from existing project
  const {
    hasExternalPlatforms,
  } = useEntityExternalPlatforms(project)

  const updatedProjectData = { ...data, external_ids: externalPlatformIds }

  // Dynamic values from state
  const {
    externalPlatforms,
    sourcePlatform,
  } = useEntityExternalPlatforms(updatedProjectData)

  useEffect(() => {
    setState({ externalPlatformIds: externalIds })
    if (!hasExternalPlatforms) setState({ editing: true })
  }, [project.id])

  const {
    filteredPlatforms: projectImportPlatforms,
  } = useIntegrations({
    featureKeys: ['import_case_studies', 'import_comparables', 'import_users', 'publish_projects', 'quoting'],
    includeSystemIntegrations: true,
    filters: {
      owner_id: project.organization_id,
      owner_type: 'Organization',
    },
  })

  const existingPlatformKeys = Object.keys(externalIds)
  const filteredPlatforms = projectImportPlatforms.filter(platform => (
    !existingPlatformKeys.includes(platform.key || sourcePlatform.key)
  ))

  return {
    callbacks: {
      removePlatform: () => removePlatform(selectedPlatform.externalPlatformKey, externalPlatformIds, setState),
      setState,
    },
    editing,
    externalPlatformIds,
    externalPlatforms,
    filteredPlatforms,
    projectImportPlatforms,
    selectedPlatform,
    updatedProjectData,
  }
}

export default useManageExternalPlatforms
