export default {
  default: {
    formFieldLabels: {
      businessName: 'Business Name',
      businessNumber: 'Business Number',
      email: '* Email',
      licenseNumber: 'License Number',
      phone: 'Phone',
      shortName: 'Short Name',
      shortcodeTitle: 'Shortcode Title',
      title: '* {global.organizationType.shortName.singular} Title',
      url: 'URL',
    },
    title: '{global.organizationType.shortName.singular} Details',
    select: {
      option: 'Select...',
    },
  },
}
