import React from 'react'

import { BlankState } from '@campaignhub/suit-theme'
import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

import blankResponseUrl from './assets/no-response.svg'

const FormResponseBlankState = () => {
  const { strings } = useLocalization(localizedStrings)

  return (
    <BlankState imageUrl={blankResponseUrl}>
      <BlankState.Title>{strings.title || 'No Responses Collected'}</BlankState.Title>
      <BlankState.Paragraph>
        {strings.paragraphOne
          || 'Forms can be used to collect any information you need associated with a project. If you haven’t created a digital page with a form and sent it yet you’ll need to do that first. Once someone responds to a form the results will be available here.'}
      </BlankState.Paragraph>

      <BlankState.Paragraph>
        {strings.paragraphTwo || 'Once someone responds to a form the results will be available here.'}
      </BlankState.Paragraph>
    </BlankState>
  )
}

export default FormResponseBlankState
