import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { digObject } from '@campaignhub/javascript-utils'

import {
  Box, Button, FormField, ModalContext, SidebarModal, SidebarNoticeBox, SortableList,
} from '@campaignhub/suit-theme'

import useAttachment from '@hooks/useAttachment'
import useDigitalPage from '@hooks/useDigitalPage'
import useLocalization from '@hooks/useLocalization'
import { useRelations } from '@hooks/useProject'

import defaultRequestOptions from '@sections/Client/packs/Admin/defaultRequestOptions'

import ModalAttachmentUpload from '@components/ModalComponents/ModalAttachmentUpload'

import useManageDigitalPageAttachments from './hooks/useManageDigitalPageAttachments'

import FileLibrarySelector from './components/FileLibrarySelector'

import localizedStrings from './localizedStrings'
import DraggableAttachment from './components/DraggableAttachment'

const handleCreateAttachment = (response, editAttachment, projectType) => {
  const { success, data } = response

  if (success){
    const { entity } = data

    const customPayload = {
      attachment: entity,
      filterCustomFieldsBy: { project_type_key: projectType.key },
    }

    editAttachment(customPayload)
  }
}

const ManageProjectAttachmentsModal = (props) => {
  const {
    callbacks,
    callbacks: { closeModal, updateDigitalPage },
    modalKey,
    project: initProject,
    showModal,
  } = props

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const { digitalPage = {} } = digObject(modalData, `${modalKey}`, {})

  const { project, projectType } = useRelations(initProject)
  const {
    callbacks: { updateDigitalPage: updateFn },
    updating,
  } = useDigitalPage(digitalPage)

  const fileSelectPayload = useManageDigitalPageAttachments({ digitalPage, performHttpRequests: true })

  const {
    callbacks: { modifyAttachmentSort, toggleDigitalPageAttachment, setState },
    hasFilteredAttachments,
    loading,
    selectedAttachmentIds,
    selectedEntityType,
  } = fileSelectPayload

  const { strings } = useLocalization(localizedStrings)

  const {
    callbacks: { editAttachment },
  } = useAttachment()

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.sideBarModalHeader?.title || 'Manage'}
        titleSecondLine={strings.sideBarModalHeader?.titleSecondLine || 'Files'}
      />

      <SidebarModal.Content>
        <Box flexDirection="column">
          <FormField
            boxProps={{ marginBottom: 'large' }}
            direction="column"
            label={strings.formFieldLabels?.fileSource || 'File Source'}
          >
            <select
              onChange={e => setState({ selectedEntityType: e.target.value })}
              value={selectedEntityType}
            >
              <option key="AssetLibrary" value="AssetLibrary">
                {strings.selectBoxOptions?.fileLibrary || 'File Library'}
              </option>
              <option key="Project" value="Project">
                {strings.selectBoxOptions?.project || 'Project'}
              </option>
            </select>
          </FormField>

          <FileLibrarySelector fileSelectPayload={fileSelectPayload} project={project} />

          {!hasFilteredAttachments && (
          <SidebarNoticeBox boxProps={{ marginTop: 'large' }}>
            <SidebarNoticeBox.Title>
              {strings.noticeBox?.title || 'Upload Files'}
            </SidebarNoticeBox.Title>

            <SidebarNoticeBox.Paragraph>
              {strings.noticeBox?.paragraph
                || 'Files uploaded here will be stored on your project for use across digital pages.'}
            </SidebarNoticeBox.Paragraph>
          </SidebarNoticeBox>
          )}

          {!loading && hasFilteredAttachments && (
            <FormField
              boxProps={{ marginBottom: 'large' }}
              direction="column"
              label={strings.formFieldLabels?.selectedFiles || 'Selected Files'}
            >
              <SortableList
                callbacks={{
                  onDragEnd: (fromIndex, toIndex, payload) => modifyAttachmentSort(
                    fromIndex,
                    toIndex,
                    payload,
                    { selectedAttachmentIds },
                    setState,
                  ),
                }}
              >
                <SortableList.Dropzone droppableId="selectedAttachmentIds">
                  <Box flexDirection="column" flexShrink={0}>
                    {selectedAttachmentIds.map((id, index) => (
                      <SortableList.Item draggableId={`Attachment-${id}`} key={id} index={index}>
                        <DraggableAttachment
                          callbacks={{
                            removeAttachment: attachmentId => toggleDigitalPageAttachment(attachmentId),
                          }}
                          attachmentId={id}
                          projectType={projectType}
                        />
                      </SortableList.Item>
                    ))}
                  </Box>
                </SortableList.Dropzone>
              </SortableList>
            </FormField>
          )}

          <FormField direction="column" label={strings.formFieldLabels?.uploadFiles || 'Upload Files'}>
            <ModalAttachmentUpload
              attachmentType="project_document"
              options={{
                afterCreateCallback: response => handleCreateAttachment(response, editAttachment, projectType),
                maxNumberOfFiles: 1,
              }}
              ownerId={project.organization_id}
              ownerType="Organization"
              requestOptions={defaultRequestOptions.attachment}
              subjectId={project.id}
              subjectType="Project"
            />
          </FormField>
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          loading={updating}
          loadingBubbleColor="white"
          onClick={digitalPage.id
            ? () => updateDigitalPage({ id: digitalPage.id, project_document_ids: selectedAttachmentIds }, updateFn)
            : closeModal}
          size="large"
        >
          {strings.buttons?.save || 'Save'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

ManageProjectAttachmentsModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  project: PropTypes.object.isRequired,
  showModal: PropTypes.bool,
}

ManageProjectAttachmentsModal.defaultProps = {
  modalKey: 'ManageProjectAttachmentsModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <ManageProjectAttachmentsModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
