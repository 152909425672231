import { useContext, useMemo } from 'react'
import { DateTime } from 'luxon'

import { useForm, useLatestEntity, useWatchEntityUpdates } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import { digObject, launchModal, sortArrayBy } from '@campaignhub/javascript-utils'

import useDispatch from '@hooks/useDispatch'
import useSelector from '@hooks/useSelector'

import { validateStartAndEndDate } from '@functions/validateStartAndEndDate'

import * as eventGroupActions from '@redux/modules/eventGroup'

import defaultState, { requiredFields } from '@models/eventGroup'

import PageContext from '@contexts/pageContext'

import type { AppDispatch } from '@redux/store'
import type { DeleteParams } from '@redux/modules/types'
import type { EventGroupModel, EventGroupRequestOptions } from '@models/types'

const watchEntityKeys = ['events']

type CreateEventGroupParams = {
  dispatch: AppDispatch,
  eventGroupParams: Partial<EventGroupModel>,
  requestOptions?: EventGroupRequestOptions,
}

const createEventGroup = (params: CreateEventGroupParams) => {
  const { dispatch, eventGroupParams, requestOptions } = params
  const { createEventGroup: createFn } = eventGroupActions

  return dispatch(createFn(eventGroupParams, requestOptions))
}

type UpdateEventGroupParams = {
  dispatch: AppDispatch,
  eventGroup: EventGroupModel,
  eventGroupParams: Partial<EventGroupModel>,
  requestOptions?: EventGroupRequestOptions,
}

const updateEventGroup = (params: UpdateEventGroupParams) => {
  const {
    dispatch, eventGroup, eventGroupParams, requestOptions,
  } = params
  const { updateEventGroup: updateFn } = eventGroupActions

  const updatedParams = {
    id: eventGroup.id,
    ...eventGroupParams,
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

type DeleteEventGroupParams = {
  dispatch: AppDispatch,
  eventGroup: DeleteParams<EventGroupModel>,
}

const deleteEventGroup = (params: DeleteEventGroupParams) => {
  const { dispatch, eventGroup } = params
  const { deleteEventGroup: deleteFn } = eventGroupActions

  return dispatch(deleteFn(eventGroup))
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
  isTemplate?: boolean
}

export function useEventGroupForm(
  eventGroup: Partial<EventGroupModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { isTemplate } = options

  const templateCalenderCustomRequiredFields = [{ key: 'days_from_start' }]
  const nonTemplateCalenderCustomRequiredFields = [
    {
      key: 'end_date',
      validation: (
        value: string,
        entityState: EventGroupModel,
      ) => validateStartAndEndDate(entityState.start_date, value, '<='),
    },
    { key: 'start_date' },
  ]

  const customRequiredFields = isTemplate
    ? templateCalenderCustomRequiredFields
    : nonTemplateCalenderCustomRequiredFields

  const { creating, deleting, updating } = useSelector(reduxState => reduxState.eventGroups)

  // Map entity so we can set start and end dates
  const endDateString = digObject(eventGroup, 'dates.end.date_time_with_timezone')
  const startDateString = digObject(eventGroup, 'dates.start.date_time_with_timezone')

  const entity = {
    ...eventGroup,
    end_date: endDateString ? DateTime.fromISO(endDateString).toISODate() : '',
    start_date: startDateString ? DateTime.fromISO(startDateString).toISODate() : '',
  }

  const eventGroupForm = useForm(defaultState, {
    entity,
    requiredFields: [...requiredFields, ...customRequiredFields],
    validateOn: 'change',
  }, [
    entity.id,
  ])

  return {
    creating,
    deleting,
    updating,
    ...eventGroupForm,
  }
}

function useEventGroup(initEntity: Partial<EventGroupModel> = {}) {
  const { entity: eventGroup }: { entity: EventGroupModel} = useLatestEntity(initEntity, 'eventGroups')
  const { id } = eventGroup

  const {
    updatedEntities: { events: eventsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const dispatch = useDispatch()

  const { callbacks } = useContext(PageContext)

  const { events } = useSelector(reduxState => reduxState.entities)

  // Events
  const filteredEvents = useMemo(() => {
    const array = Object.values(events)
    const filtered = array.filter((event) => {
      const { event_group_id } = event
      return event_group_id === id
    })

    return sortArrayBy(filtered, 'asc', 'dates.start.integer')
  }, [id, eventsUpdatedAt])

  const startDateString = digObject(eventGroup, 'dates.start.date_time_with_timezone')
  const endDateString = digObject(eventGroup, 'dates.end.date_time_with_timezone')

  const endDateObj = endDateString ? DateTime.fromISO(endDateString) : null
  const startDateObj = startDateString ? DateTime.fromISO(startDateString) : null

  return {
    callbacks: {
      deleteEventGroup: () => deleteEventGroup({ dispatch, eventGroup }),
      editEventGroup: () => launchModal({
        callbacks,
        modalKey: 'CreateOrEditEventGroupModal',
        payload: {
          callbacks: {
            createEventGroup: (
              eventGroupParams: Partial<EventGroupModel>,
              entityOptions?: EventGroupRequestOptions,
            ) => (
              createEventGroup({ eventGroupParams, dispatch, requestOptions: entityOptions })
            ),
            updateEventGroup: (
              eventGroupParams: Partial<EventGroupModel>,
              entityOptions?: EventGroupRequestOptions,
            ) => (
              updateEventGroup({
                eventGroup, eventGroupParams, dispatch, requestOptions: entityOptions,
              })
            ),
          },
          eventGroup,
        },
      }),
    },
    endDateObj,
    eventGroup,
    filteredEvents,
    startDateObj,
  }
}

export default useEventGroup
