export default {
  default: {
    header: {
      title: 'Create New',
      titleSecondLine: 'Custom Field Set',
    },
    formField: {
      inputPlaceholder: 'e.g Project Fields',
      key: 'Key',
      labelEntityType: 'Entity Type',
      labelFieldSetTitle: 'Field Set Title',
      option: 'Please Select...',
      keyPlaceholder: 'e.g real_estate_sale_campaign',
    },
    button: 'Create Custom Field Set',
    toastText: {
      createCustomFieldSet: 'Custom Field Set Created Successfully',
    },
  },
}
