import PropTypes from 'prop-types'

import {
  Box, SidebarModal,
} from '@campaignhub/suit-theme'

import { useSetState } from '@campaignhub/react-hooks'

import useFormTemplate, { useFormTemplateForm } from '@hooks/useFormTemplate'
import useLocalization from '@hooks/useLocalization'

import CreateFromTemplate from './screens/CreateFromTemplate'
import CreateNew from './screens/CreateNew'
import DefaultState from './screens/DefaultState'

import localizedStrings from './localizedStrings'

const selectScreen = (selectedScreen, setState, setEntityState) => {
  setEntityState({ form_template_id: null, title: '' })
  setState({ selectedScreen })
}

const selectTemplate = (formTemplate, setState, setEntityState) => {
  setEntityState({ form_template_id: formTemplate.id, title: formTemplate.title })
  setState({ selectedScreen: 'CreateNew' })
}

const getModalHeader = (selectedScreen, strings) => {
  if (selectedScreen === 'CreateNew'){
    return strings.sideBarModalHeader?.titleCreate || 'Create'
  }

  // 'CreateFromTemplate'
  return strings.sideBarModalHeader?.titleSelect || 'Select'
}

const defaultState = {
  selectedScreen: '',
}

const CreateFormTemplateModal = (props) => {
  const {
    callbacks, modalKey, ownerId, ownerType, showModal,
  } = props
  const { closeModal, createFormTemplate, duplicateFormTemplate } = callbacks || {}

  const [state, setState] = useSetState(defaultState)
  const { selectedScreen } = state

  const formTemplatePayload = useFormTemplate({})
  const {
    callbacks: {
      createFormTemplate: createFn,
      duplicateFormTemplate: duplicateFn,
    },
  } = formTemplatePayload

  const initFormTemplate = {
    owner_id: ownerId,
    owner_type: ownerType,
  }
  const formTemplateFormPayload = useFormTemplateForm(initFormTemplate)
  const { entityState, setEntityState } = formTemplateFormPayload

  const { strings } = useLocalization(localizedStrings)

  const createFormTemplatePayload = {
    callbacks: {
      action: createFn,
      afterAction: closeModal,
    },
    entityParams: entityState,
    shouldRedirect: true,
  }

  const duplicateFormTemplatePayload = {
    callbacks: {
      action: duplicateFn,
      afterAction: closeModal,
    },
    entityParams: entityState,
    shouldRedirect: true,
  }

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        title={selectedScreen
          ? getModalHeader(selectedScreen, strings)
          : (strings.sideBarModalHeader?.titleForm || 'Form')}
        titleSecondLine={selectedScreen
          ? (strings.sideBarModalHeader?.subtitleFormTemplate || 'Form Template')
          : (strings.sideBarModalHeader?.subtitleTemplate || 'Template')}
      />

      <Box flexDirection="column" flexShrink={0}>
        {!selectedScreen && (
          <DefaultState
            callbacks={{ selectScreen: screen => selectScreen(screen, setState, setEntityState) }}
          />
        )}

        {selectedScreen === 'CreateNew' && (
          <CreateNew
            callbacks={{
              createFormTemplate: () => createFormTemplate(createFormTemplatePayload),
              duplicateFormTemplate: () => duplicateFormTemplate(duplicateFormTemplatePayload),
            }}
            formTemplateFormPayload={formTemplateFormPayload}
            formTemplatePayload={formTemplatePayload}
          />
        )}

        {selectedScreen === 'CreateFromTemplate' && (
          <CreateFromTemplate
            callbacks={{
              selectTemplate: templateId => selectTemplate(templateId, setState, setEntityState),
              selectScreen: screen => selectScreen(screen, setState, setEntityState),
            }}
            formTemplateFormPayload={formTemplateFormPayload}
          />
        )}
      </Box>
    </SidebarModal>
  )
}

CreateFormTemplateModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  ownerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ownerType: PropTypes.string.isRequired,
  showModal: PropTypes.bool,
}

CreateFormTemplateModal.defaultProps = {
  modalKey: 'CreateFormTemplateModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <CreateFormTemplateModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
