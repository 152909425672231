import React from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import { Button, SidebarModal } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const confirmDelete = (deleteFn, strings) => {
  swal
    .fire({
      title: strings.confirmDelete.title || 'Delete Item?',
      text: strings.confirmDelete.text || 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: strings.confirmDelete.confirmButtonText || 'Yes, delete it.',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        deleteFn()
      }
    })
}

const Tools = (props) => {
  const {
    callbacks: { deleteEntity: deleteFn },
  } = props

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal.ExpandableSectionBox defaultOpen label={strings.labelTools || 'Tools'}>
      <Button
        buttonStyle="secondaryDestroy"
        icon={<FontAwesomeIcon icon={faTrashAlt} />}
        onClick={() => confirmDelete(deleteFn, strings)}
        size="medium"
        style={{ width: 'auto' }}
      >
        {strings.deleteButton || 'Delete Item'}
      </Button>
    </SidebarModal.ExpandableSectionBox>
  )
}

Tools.propTypes = {
  callbacks: PropTypes.shape({
    deleteEntity: PropTypes.func.isRequired,
  }),
}

export default Tools
