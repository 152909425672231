import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { deleteEntity, updateEntities } from '@redux/entities'

import api from '@functions/api'

import type { TagModel } from '@models/types'
import type { AppDispatch } from '@redux/store'
import type {
  Action, DeleteParams, EntityOptions, Errors,
} from '@redux/modules/types'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/tag/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/tag/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/tag/FETCH_FAILURE'

const CREATE_REQUEST = 'realbase/tag/CREATE_REQUEST'
const CREATE_SUCCESS = 'realbase/tag/CREATE_SUCCESS'
const CREATE_FAILURE = 'realbase/tag/CREATE_FAILURE'

const UPDATE_REQUEST = 'realbase/tag/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realbase/tag/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realbase/tag/UPDATE_FAILURE'

const DELETE_REQUEST = 'realbase/tag/DELETE_REQUEST'
const DELETE_SUCCESS = 'realbase/tag/DELETE_SUCCESS'
const DELETE_FAILURE = 'realbase/tag/DELETE_FAILURE'

export type TagModuleState = {
  creating: boolean,
  deleting: boolean,
  errors: string[],
  loaded: boolean,
  loading: boolean,
  updating: boolean,
}

// Initial State
const initialState: TagModuleState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loading: false,
  updating: false,
}

// Actions
export function fetchRequest() {
  return {
    type: FETCH_REQUEST,
  }
}

export function fetchSuccess() {
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors: Errors = []) {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest() {
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess() {
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors: Errors = []) {
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest() {
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess() {
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors: Errors = []) {
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest() {
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess() {
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors: Errors = []) {
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Action Creators
export function loadTags(options: EntityOptions = {}) {
  return (dispatch: AppDispatch) => {
    dispatch(fetchRequest())

    const promise = api('/tags.json', options)
      .then(({ data }: { data: { entities: TagModel[] } }) => {
        const normalizedJson = normalize(data.entities, Schemas.TAG_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function createTag(tag: Partial<TagModel>, options: EntityOptions = {}) {
  const config = {
    method: 'POST',
    body: JSON.stringify({
      tag,
    }),
  }

  return (dispatch: AppDispatch) => {
    dispatch(createRequest())

    const promise = api('/tags.json', options, config)
      .then(({ data }: { data: { entity: TagModel } }) => {
        const normalizedJson = normalize(data.entity, Schemas.TAG)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function deleteTag(tag: DeleteParams<TagModel>) {
  const config = {
    method: 'DELETE',
  }

  return (dispatch: AppDispatch) => {
    dispatch(deleteRequest())

    const promise = api(`/tags/${tag.id}.json`, {}, config)
      .then(({ data }: { data: { entity: TagModel } }) => {
        const normalizedJson = normalize(data.entity, Schemas.TAG)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(deleteFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(
  state: TagModuleState = initialState,
  action: Action = { type: '' },
): TagModuleState {
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors || [],
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors || [],
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors || [],
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors || [],
      }
    default:
      return state
  }
}
