import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { useDraggable } from '@campaignhub/react-hooks'
import { Box, Text } from '@campaignhub/suit-theme'

import useEvent from '@hooks/useEvent'

import styles from './styles.module.scss'

const Event = (props) => {
  const { event } = props

  const eventPayload = useEvent(event)
  const { callbacks: { createOrEditEvent }, event: { id, title }, startTime } = eventPayload

  const itemRef = useRef(null)

  useDraggable(itemRef, {
    callbacks: {},
    documentElementId: 'main-stage',
    draggingClassName: styles.dragging,
  })

  return (
    <Box
      backgroundColor="#EAF6FF"
      borderRadius={5}
      className={classnames('draggable', styles.root)}
      data-action="update"
      data-entity-id={id}
      data-entity-type="Event"
      data-item-type="event"
      flexDirection="column"
      marginBottom="small"
      onClick={createOrEditEvent}
      padding="medium"
      ref={itemRef}
    >
      <Text color="bodyFontLightColor" fontSize="xsmall" lineHeight={1.3}>
        {title}
      </Text>

      <Text color="bodyFontLightColor" fontSize="xxsmall" marginTop="small">
        {startTime}
      </Text>
    </Box>
  )
}

Event.propTypes = {
  event: PropTypes.object.isRequired,
}

export default Event
