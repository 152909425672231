import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faPencil } from '@fortawesome/pro-solid-svg-icons'

import {
  Box, Link, ListItem, Text,
} from '@campaignhub/suit-theme'

import generateRedirectUrl from '@functions/generateRedirectUrl'

import useEventCalendar from '@hooks/useEventCalendar'
import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const EventCalendar = (props) => {
  const {
    callbacks: { toggleEventCalendar },
    selected,
    selectedIndex,
    eventCalendar,
    eventCalendar: { title },
  } = props

  const {
    filteredEvents,
    urls: { digitalPageEventCalendarUrl },
  } = useEventCalendar(eventCalendar)

  const editEventCalendarRedirectUrl = `${digitalPageEventCalendarUrl}?redirect=${generateRedirectUrl({
    append: '&showModal=ManageEventCalendarsModal',
  })}`

  const {
    callbacks: { formatCount },
  } = useLocalization(localizedStrings)

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        lineHeight: '1.3',
        marginTop: 'large',
      }}
      disableHover
    >
      <Box paddingY="medium" marginRight="large" width="auto">
        <Box
          alignItems="center"
          backgroundColor={selected ? 'blue' : 'transparent'}
          border="1px solid"
          borderColor={selected ? 'blue' : 'lineColor'}
          borderRadius="100%"
          height={28}
          justifyContent="center"
          onClick={() => toggleEventCalendar()}
          style={{ cursor: 'pointer' }}
          width={28}
        >
          <Text color={selected ? 'white' : 'lineColor'} fontSize="xsmall">
            {selected ? selectedIndex + 1 : <FontAwesomeIcon icon={faCheck} />}
          </Text>
        </Box>
      </Box>

      <Box flexDirection="column" justifyContent="center" minWidth="0">
        <Text fontSize="small">{title}</Text>
        <Text color="bodyFontLightColor" fontSize="xsmall">
          {formatCount('events', { count: filteredEvents.length })}
        </Text>
      </Box>

      <Link greyLink href={editEventCalendarRedirectUrl} textProps={{ fontSize: 'small', marginRight: 'medium' }}>
        <FontAwesomeIcon icon={faPencil} />
      </Link>
    </ListItem>
  )
}

EventCalendar.propTypes = {
  callbacks: PropTypes.shape({
    toggleEventCalendar: PropTypes.func,
  }).isRequired,
  eventCalendar: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  selectedIndex: PropTypes.number.isRequired,
}

export default EventCalendar
