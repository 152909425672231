import { Route, Routes, HashRouter as Router } from 'react-router-dom'

import Admin from '.'
import Dashboard from './packs/Dashboard'
import DigitalTemplateBuilder from './packs/DigitalTemplateBuilder'
import FormBuilder from '../Client/packs/FormBuilder'
import SystemManager from './packs/SystemManager'

const AdminRoutes = () => (
  <Router>
    <Admin>
      <Routes>
        <Route index element={<Dashboard />} />
        <Route path="/digitalTemplates/*" element={<DigitalTemplateBuilder />} />
        <Route path="/formTemplates/:formTemplateId/edit" element={<FormBuilder editing />} />
        <Route path="/systemManager/*" element={<SystemManager />} />
      </Routes>
    </Admin>
  </Router>
)

export default AdminRoutes
