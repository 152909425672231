import React from 'react'
import PropTypes from 'prop-types'

import { faPhotoVideo } from '@fortawesome/pro-light-svg-icons'

import useLocalization from '@hooks/useLocalization'
import useProject from '@hooks/useProject'
import useReduxAction from '@hooks/useReduxAction'

import { Box, DashboardModule, Link } from '@campaignhub/suit-theme'

import useImages from '@hooks/useImages'

import TileList from './TileList'
import DetailList from './DetailList'

import mediaBlank from './assets/mediaBlank.svg'

import localizedStrings from './localizedStrings'

export const icon = faPhotoVideo

export const configOptions = strings => ({
  title: {
    component: 'input',
    key: 'title',
    label: strings.labelTitle?.title || 'Title',
    optionKey: 'customization',
  },
  display_mode: {
    component: 'select',
    key: 'display_mode',
    label: strings.displayMode?.label || 'Display Mode',
    optionKey: 'customization',
    values: [
      { key: 'tile-list', label: strings.displayModeValues?.labelListTile || 'List (Tile)' },
      { key: 'detail-list', label: strings.displayModeValues?.labelListLarge || 'List (Detail)' },
    ],
  },
  limit: {
    component: 'select',
    key: 'limit',
    label: strings.limit?.label || 'Limit',
    optionKey: 'queryParams',
    values: [
      { key: 5, label: '5' },
      { key: 10, label: '10' },
      { key: 25, label: '25' },
      { key: 50, label: '50' },
    ],
  },
})

const defaultRequestOptions = {
  include_image_sizes: ['thumb_90x90', 'thumb_200x120', 'thumb_660x360'],
}

const Media = (props) => {
  const {
    customOptions,
    customOptions: {
      queryParams: { limit, subject_id, subject_type },
    },
    editing,
    parentId,
  } = props

  const {
    urls: { mediaUrl },
  } = useProject({ id: subject_id })

  const requestOptions = {
    ...defaultRequestOptions,
    ...customOptions.queryParams,
  }

  useReduxAction('images', 'loadImages', requestOptions, [parentId, JSON.stringify(requestOptions)])

  const imagesPayload = useImages({ limit, subjectId: subject_id, subjectType: subject_type })
  const { filteredImages, hasImages, loading } = imagesPayload

  const { customization } = customOptions
  const { display_mode, title } = customization || {}

  const { strings } = useLocalization(localizedStrings)

  return (
    <DashboardModule
      boxProps={{ marginBottom: 0 }}
      editing={editing}
      headerRight={(
        <Link href={mediaUrl} textProps={{ fontSize: 'small' }}>
          {strings.manage || 'Manage'}
        </Link>
      )}
      loading={loading}
      title={title || strings.title?.title || 'Media'}
    >
      {!loading && !hasImages && (
        <DashboardModule.BlankState imageUrl={mediaBlank}>
          <DashboardModule.BlankState.Title>
            {strings.blankState?.title || 'No Images Uploaded'}
          </DashboardModule.BlankState.Title>

          <DashboardModule.BlankState.Paragraph>
            {strings.blankState?.paragraph
              || 'You don’t have any images stored against this project. You can upload images and use them in digital pages to send more information to clients easily.'}
          </DashboardModule.BlankState.Paragraph>
        </DashboardModule.BlankState>
      )}

      {!loading && hasImages && (
        <Box flexDirection="column" flexWrap="wrap" padding="medium">
          {display_mode === 'detail-list' && filteredImages.map(image => <DetailList key={image.id} image={image} />)}
          {display_mode !== 'detail-list' && <TileList filteredImages={filteredImages} />}
        </Box>
      )}
    </DashboardModule>
  )
}

Media.propTypes = {
  customOptions: PropTypes.object.isRequired,
  editing: PropTypes.bool,
  parentId: PropTypes.string,
}

export default Media
