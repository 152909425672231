import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faMobile } from '@fortawesome/pro-light-svg-icons'

import { Box, ListItem, Text } from '@campaignhub/suit-theme'

import ToggleButton from '@components/ToggleButton'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const icons = {
  email: faEnvelope,
  sms: faMobile,
}

const NotificationMethod = (props) => {
  const {
    callbacks: { updateNotifications },
    disabled,
    notificationKey,
    notificationMethodKey,
  } = props

  const { strings } = useLocalization(localizedStrings)

  return (
    <ListItem boxProps={{ alignItems: 'center', border: 'none', marginBottom: 'medium' }} disableHover>
      <Box alignItems="center" fontSize="xsmall">
        <Box
          color={disabled ? 'faintGrey' : 'bodyFontLightColor'}
          justifyContent="center"
          marginRight="small"
          width={16}
        >
          <FontAwesomeIcon icon={icons[notificationMethodKey]} />
        </Box>
        <Text color={disabled ? 'faintGrey' : ''}>{strings[notificationMethodKey]?.title}</Text>
      </Box>

      <ToggleButton
        boxProps={{
          justifyContent: 'space-between',
          marginBottom: 'none',
          marginLeft: 'auto',
          width: 'auto',
        }}
        onClick={() => updateNotifications(notificationKey, notificationMethodKey)}
        toggleProps={{ paddingY: 'small', paddingX: 'medium' }}
        enabled={!disabled}
      />
    </ListItem>
  )
}

NotificationMethod.propTypes = {
  callbacks: PropTypes.shape({
    updateNotifications: PropTypes.func.isRequired,
  }),
  disabled: PropTypes.bool,
  notificationKey: PropTypes.string,
  notificationMethodKey: PropTypes.string,
}

export default NotificationMethod
