import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useSetState } from '@campaignhub/react-hooks'

import PageContext from '@contexts/pageContext'

import useReduxAction from '@hooks/useReduxAction'

import handleCallbackAction from '@functions/handleCallbackAction'

import CreateOrganizationModal from '@modals/CreateOrganizationModal'
import ImportOrganizationsModal from '@modals/ImportOrganizationsModal'

import PageContent from './components/PageContent'

const callbacks = (component, setState) => {
  const componentCallbacks = {
    CreateOrganizationModal: {
      closeModal: () => setState({ showCreateOrganizationModal: false }),
      createOrganization: payload => handleCallbackAction(payload),
    },
    ImportOrganizationsModal: {
      closeModal: () => setState({ showImportOrganizationsModal: false }),
      importOrganizations: payload => handleCallbackAction(payload),

    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  pageFilters: {},
  showCreateOrganizationModal: false,
  showImportOrganizationsModal: false,
}

const OrganizationList = () => {
  const [state, setState] = useSetState(defaultState)
  const { pageFilters, showCreateOrganizationModal, showImportOrganizationsModal } = state

  useReduxAction('brands', 'loadBrands', {})

  const { entities } = useSelector(reduxState => reduxState)
  const { brands } = entities
  const brandsArray = Object.values(brands)

  useEffect(() => {
    if (brandsArray.length === 1){
      setState({ pageFilters: { brand_id: brandsArray[0]?.id } })
    }
  }, [brandsArray.length])

  const pageContext = useMemo(() => ({
    callbacks: {
      resetFilters: (resetFn) => {
        if (resetFn) resetFn()
        setState({ pageFilters: {} })
      },
      showCreateOrganizationModal: () => setState({ showCreateOrganizationModal: true }),
      showImportOrganizationsModal: () => setState({ showImportOrganizationsModal: true }),
      updateFilters: (filters) => {
        setState({ pageFilters: filters })
      },
    },
  }), [])

  return (
    <PageContext.Provider value={pageContext}>
      <PageContent pageFilters={pageFilters} />

      <CreateOrganizationModal
        callbacks={callbacks('CreateOrganizationModal', setState)}
        showModal={showCreateOrganizationModal}
      />

      <ImportOrganizationsModal
        callbacks={callbacks('ImportOrganizationsModal', setState)}
        showModal={showImportOrganizationsModal}
      />
    </PageContext.Provider>
  )
}

export default OrganizationList
