const state = {
  id: null,
  brand_id: null,
  data: {
    component: {},
    style: {
      default: {},
      mobile: {},
      print: {},
      tablet: {},
    },
  },
  description: '',
  hidden: false,
  item_type: '',
  locked: false,
  title: '',
}

export const requiredFields = [
  { key: 'title' },
  { key: 'item_type' },
]

export default state
