import api from '@functions/api'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/digitalPage/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/digitalPage/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/digitalPage/FETCH_FAILURE'

// Initial State
const initialState = {
  errors: [],
  loading: false,
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
    loadedIds: payload.loadedIds,
    loadedShortcodeDataIds: payload.loadedShortcodeDataIds,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    sandboxId: payload.sandboxId,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function fetchSuburbData(options = {}){
  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = api('/suburbs/get_suburb_data.json', options)
      .then(({ data }) => {
        dispatch(fetchSuccess())
        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))
        return { success: false, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
