import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, ListItem, Text,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

const QuoteTemplate = (props) => {
  const {
    callbacks: { selectTemplate },
    quoteTemplate: { title, total },
  } = props

  const {
    callbacks: { formatCurrency },
  } = useLocalization()

  return (
    <ListItem
      boxProps={{
        border: '1px solid',
        borderColor: 'lineColor',
        borderRadius: 5,
        marginBottom: 'medium',
        padding: 'large',
      }}
      forceBottomBorder
      onClick={selectTemplate}
      showAngleIcon
    >
      <Box flexDirection="column">
        <Text fontSize="small">{title}</Text>

        <Text color="bodyFontLightColor" marginTop="small" fontSize="xsmall">
          {formatCurrency(total)}
        </Text>
      </Box>
    </ListItem>
  )
}

QuoteTemplate.propTypes = {
  callbacks: PropTypes.shape({
    selectTemplate: PropTypes.func.isRequired,
  }).isRequired,
  quoteTemplate: PropTypes.object.isRequired,
}

export default QuoteTemplate
