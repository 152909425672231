import { useContext } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { useLocation } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArchive, faFile, faStar } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Columns, DashboardModule, LoadingModule, PageHeader, Tools,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'
import useVideoModules from '@hooks/useVideoModules'

import { getQueryParamsFromHash, parsePermittedQueryParams, sortArrayBy } from '@campaignhub/javascript-utils'

import PageContext from '@contexts/pageContext'

import PageFilterModule from '@components/PageFilterModule'
import VideoModule from '@components/VideoModule'

import useCurrentUser from '@hooks/useCurrentUser'
import useOrganizationSelector from '@hooks/useOrganizationSelector'
import useReview from '@hooks/useReview'
import useReviews from '@hooks/useReviews'

import defaultRequestOptions from '@sections/Client/packs/Admin/defaultRequestOptions'

import localizedStrings from './localizedStrings'
import Review from '../Review'
import ReviewsBlankState from '../ReviewsBlankState'

const buildPageFilterFields = (entities, isCurrentUserAdminForOrganization, strings) => {
  const style = {
    marginBottom: 16,
  }

  const { users } = entities
  const usersArray = Object.values(users)

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        style,
      },
      key: 'full_address',
      label: strings.filters?.address || 'Filter by Address',
    },
    {
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: strings.filters?.defaultSelectLabel || 'All',
        inputComponent: 'select',
        style,
      },
      hidden: !isCurrentUserAdminForOrganization,
      key: 'user_id',
      label: strings.filters?.user || 'User',
      values: sortArrayBy(usersArray, 'asc', 'full_name').map(user => ({
        key: user.id,
        label: user.full_name,
      })),
    },
  ]
}

const PageContent = (props) => {
  const { isCurrentUserAdminForOrganization, pageFilters } = props

  // useLocation to refresh the window.location. If not used location won't reload if URL changes
  useLocation()

  const { hidden } = parsePermittedQueryParams(getQueryParamsFromHash(), ['hidden'])
  const isHidden = hidden === 'true'

  const { currentUser } = useCurrentUser()
  const { selectedOrganization } = useOrganizationSelector()

  const { filteredReviews, hasReviews, loading } = useReviews({
    filters: {
      ...pageFilters,
      hidden: isHidden,
      owner_id: selectedOrganization.id,
      owner_type: 'Organization',
      user_id: isCurrentUserAdminForOrganization ? pageFilters.user_id : currentUser.id,
    },
    requestOptions: {
      ...defaultRequestOptions.review,
      include_image_sizes: ['thumb_200x120', 'thumb_660x360'],
      include_review_default_image: true,
    },
    performHttpRequests: true,
  })

  const initReview = {
    owner_id: selectedOrganization.id,
    owner_type: 'Organization',
  }

  if (!isCurrentUserAdminForOrganization) initReview.user_ids = [currentUser.id]

  const {
    callbacks: { createOrEditReview },
    urls: { hiddenReviewsIndexUrl, indexReviewsUrl },
  } = useReview(initReview)

  const { entities } = useSelector(reduxState => reduxState)

  const pageContext = useContext(PageContext)
  const {
    callbacks: { resetFilters, showImportReviewsModal, updateFilters },
  } = pageContext

  const videoModulePayload = useVideoModules({ key: 'reviews' })
  const { videosPayload } = videoModulePayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      <PageHeader
        actionContent={(
          <Button buttonStyle="primaryCreate" onClick={() => createOrEditReview()} size="medium">
            {strings.createButton || 'Create'}
          </Button>
        )}
        activeTabBarItemKey={isHidden ? 'hidden' : 'current'}
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        nestedNavigation
        tabBarItems={[
          {
            href: indexReviewsUrl,
            icon: faStar,
            key: 'current',
            title: strings.tabTitle || 'Current',
          },
          {
            href: hiddenReviewsIndexUrl,
            icon: faArchive,
            key: 'hidden',
            title: strings.archivedTitle || 'Archived',
          },
        ]}
        title={strings.title || 'Current'}
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && hasReviews && (
              <DashboardModule title={strings.title}>
                <Box flexDirection="column">
                  <Box flexDirection="column">
                    {filteredReviews.map(review => (
                      <Review key={review.id} review={review} />
                    ))}
                  </Box>

                  <DashboardModule.Footer>
                    <DashboardModule.FooterRight>
                      <Button
                        buttonStyle="secondaryEdit"
                        onClick={() => createOrEditReview()}
                        size="medium"
                        style={{ width: '100%' }}
                      >
                        {strings.addButton || 'Add Review'}
                      </Button>
                    </DashboardModule.FooterRight>
                  </DashboardModule.Footer>
                </Box>
              </DashboardModule>
            )}

            {!loading && !hasReviews && <ReviewsBlankState />}
          </Columns.Main>

          <Columns.Sidebar>
            <Tools title={strings.tools || 'Tools'}>
              <Button
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faFile} />}
                marginBottom="medium"
                onClick={() => showImportReviewsModal()}
                size="medium"
              >
                {strings.importReviews || 'Import Reviews'}
              </Button>
            </Tools>

            <PageFilterModule
              callbacks={{ resetFilters, updateFilters }}
              filterFields={buildPageFilterFields(entities, isCurrentUserAdminForOrganization, strings)}
              pageFilters={pageFilters}
              title={strings.filtersTitle || 'Filters'}
            />

            <VideoModule videosPayload={videosPayload} />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

PageContent.propTypes = {
  isCurrentUserAdminForOrganization: PropTypes.bool.isRequired,
  pageFilters: PropTypes.object.isRequired,
}

export default PageContent
