import { useContext, useEffect } from 'react'

import { useRealtimeSubscription, useSetState, useThunkDispatch } from '@campaignhub/react-hooks'
import { digObject } from '@campaignhub/javascript-utils'

import RealtimeContext from '@contexts/realtimeContext'

import useIntegrations from '@hooks/useIntegrations'
import useOrganizationSelector from '@hooks/useOrganizationSelector'

import * as agreementActions from '@redux/modules/agreement'

const getOptions = (agreementId, integrationPlatformKey) => ({
  broadcast_completion: true,
  broadcast_channel: 'agreements',
  broadcast_event: `agreement.${agreementId}.createExternal.complete`,
  integration_platform_key: integrationPlatformKey,
})

const createExternalAgreement = (params) => {
  const {
    agreement, selectedIntegrationPlatformKey, setState, dispatch, options,
  } = params
  const { createExternalAgreement: createFn } = agreementActions

  setState({
    building: true,
    selectedAgreementId: agreement.id,
    showModal: true,
  })

  const mergedOptions = {
    ...getOptions(agreement.id, selectedIntegrationPlatformKey),
    ...options,
  }

  return dispatch(createFn(agreement, mergedOptions))
}

const buildComplete = (agreementId, result, setState, dispatch) => {
  const { hydrateAgreement } = agreementActions

  const {
    filters: { agreement_id },
    payload,
  } = result

  if (agreement_id === agreementId){
    dispatch(hydrateAgreement(payload))

    setState({
      building: false,
      showModal: false,
    })
  }
}

const defaultState = {
  building: false,
  selectedAgreementId: null,
  selectedIntegrationPlatformKey: '',
  showModal: false,
}

function useCreateExternalAgreement(){
  const [state, setState] = useSetState(defaultState)
  const { selectedAgreementId, selectedIntegrationPlatformKey } = state

  const realtimeContext = useContext(RealtimeContext)
  const { clientRef } = realtimeContext

  const dispatch = useThunkDispatch()

  const { selectedOrganization } = useOrganizationSelector()
  const defaultPlatformKey = digObject(selectedOrganization, 'options.default_platforms.electronic_signing')

  const { filteredPlatforms } = useIntegrations({ featureKeys: ['electronic_signing'] })

  // Auto Select Organization default integration platform if set
  useEffect(() => {
    if (defaultPlatformKey){
      setState({ selectedIntegrationPlatformKey: defaultPlatformKey })
      return
    }

    // Auto Select first integration platform if only one available (Domain)
    if (filteredPlatforms.length === 1){
      const selectedPlatform = filteredPlatforms[0]
      setState({ selectedIntegrationPlatformKey: selectedPlatform.key })
    }
  }, [defaultPlatformKey, filteredPlatforms.length])

  const options = getOptions(selectedAgreementId, selectedIntegrationPlatformKey)
  const { broadcast_channel, broadcast_event } = options

  useRealtimeSubscription(
    {
      apiKey: process.env.REACT_APP_ABLY_PUBLIC_KEY,
      callbacks: {
        success: result => buildComplete(selectedAgreementId, result, setState, dispatch),
      },
      channelName: broadcast_channel,
      clientRef,
      eventName: broadcast_event,
    },
    [selectedAgreementId],
  )

  return {
    ...state,
    callbacks: {
      createExternalAgreement: (agreement, requestOptions) => createExternalAgreement({
        agreement,
        selectedIntegrationPlatformKey,
        setState,
        dispatch,
        requestOptions,
      }),
      closeModal: () => setState({ showModal: false }),
      setState,
    },
  }
}

export default useCreateExternalAgreement
