export default {
  default: {
    createButton: 'Create',
    tabBarItems: {
      all: 'All',
      draft: 'Draft',
      published: 'Published',
    },
    filters: {
      title: 'Filter by Title or ID',
    },
    title: 'Form Templates',
    tools: 'Tools',
    filtersTitle: 'Filters',
  },
}
