import React from 'react'
import PropTypes from 'prop-types'

import { Box, Text } from '@campaignhub/suit-theme'

import { capitalizeString } from '@functions/string'

const FormResponseField = (props) => {
  const { label, value } = props

  return (
    <Box flexDirection="column">
      <Box alignItems="center" flexDirection="row" fontSize="xsmall">
        <Text color="faintGrey" marginRight="large" marginTop="small" variant="ellipsis" width="25%">
          {capitalizeString(label)}
        </Text>

        <Text marginTop="small" variant="ellipsis" width="75%">
          {value}
        </Text>
      </Box>
    </Box>
  )
}

FormResponseField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
}

export default FormResponseField
