import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFire, faSnowflake, faMugHot } from '@fortawesome/pro-solid-svg-icons'

import { Box, Text } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const icons = {
  cold: faSnowflake,
  hot: faFire,
  warm: faMugHot,
}

const CustomLegend = (props) => {
  const { payload } = props

  const {
    callbacks: { formatNumber, formatString },
    strings,
  } = useLocalization(localizedStrings)

  return (
    <Box alignItems="center" flexDirection="row" justifyContent="center" marginTop="large" padding="medium">
      {payload.map((item) => {
        const {
          payload: { percent, stroke },
          value,
        } = item

        return (
          <Box key={value} width="auto">
            <Text fontSize="xxsmall" color={stroke} style={{ marginRight: 5 }}>
              <FontAwesomeIcon icon={icons[value]} />
            </Text>

            <Text fontSize="xxsmall" color="bodyFontLightColor" style={{ marginRight: 8 }}>
              {formatString(strings[value]?.legendValue, { percentageValue: formatNumber(percent, '.0%') })}
            </Text>
          </Box>
        )
      })}
    </Box>
  )
}

CustomLegend.propTypes = {
  payload: PropTypes.array,
}

export default CustomLegend
