import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons'

import { Box } from '@campaignhub/suit-theme'

const ModalController = (props) => {
  const { callbacks: { closeModal }, children } = props

  return (
    <Box
      alignItems="center"
      backgroundColor="rgba(0, 0, 0, 0.7)"
      bottom="0"
      justifyContent="center"
      left="0"
      padding="large"
      position="fixed"
      right="0"
      top="0"
      zIndex="9999"
    >
      {children}

      <Box
        as="a"
        color="white"
        fontSize="xxlarge"
        onClick={closeModal}
        position="absolute"
        top="large"
        right="large"
        width="auto"
        style={{ cursor: 'pointer' }}
      >
        <FontAwesomeIcon icon={faTimes} />
      </Box>
    </Box>
  )
}

ModalController.propTypes = {
  callbacks: PropTypes.shape({
    closeModal: PropTypes.func.isRequired,
  }).isRequired,
  children: PropTypes.node,
}

export default ModalController
