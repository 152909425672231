import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, FormField, SortableList, SidebarNoticeBox,
} from '@campaignhub/suit-theme'

import DraggableSection from '../DraggableSection'

const SectionGroup = React.forwardRef((props, ref) => {
  const { callbacks, sectionGroupKey, sectionIds } = props

  return (
    <FormField boxProps={{ marginBottom: 'large' }} label={`Section Group ID# ${sectionGroupKey}`} ref={ref}>
      <SortableList.Dropzone droppableId={sectionGroupKey}>
        <Box flexDirection="column">
          {!!sectionIds.length && sectionIds.map((sectionId, index) => (
            <SortableList.Item draggableId={`Section-${sectionId}`} key={sectionId} index={index}>
              <DraggableSection callbacks={callbacks} sectionId={sectionId} />
            </SortableList.Item>
          ))}

          {!sectionIds.length && (
            <SidebarNoticeBox>
              <SidebarNoticeBox.Paragraph boxProps={{ marginTop: 0, fontSize: 'small' }}>
                Drag section here to add to Section Group
              </SidebarNoticeBox.Paragraph>
            </SidebarNoticeBox>
          )}
        </Box>
      </SortableList.Dropzone>
    </FormField>
  )
})

SectionGroup.propTypes = {
  callbacks: PropTypes.object.isRequired,
  sectionGroupKey: PropTypes.string.isRequired,
  sectionIds: PropTypes.array.isRequired,
}

export default SectionGroup
