import { useSetState } from '@campaignhub/react-hooks'

import { digObject, toggleArray } from '@campaignhub/javascript-utils'

const updateLocations = (locations, location, updateData) => {
  const updatedLocations = toggleArray(locations, location, { deepCompare: true })
  updateData(updatedLocations)
}

const toggleLocation = (location, locations, updateData) => {
  if (location.source === 'project_comparables'){
    const alteredLocation = { source: location.source }
    const alteredLocations = locations.filter(loc => loc.source !== 'project_comparables')

    if (locations.filter(loc => loc.source === 'project_comparables').length){
      alteredLocations.push({ source: 'project_comparables' })
    }

    updateLocations(alteredLocations, alteredLocation, updateData)
    return
  }
  updateLocations(locations, location, updateData)
}

const updateLocationSource = (param, locations, setState, updateData) => {
  setState({ locationSource: param })

  if (param) toggleLocation({ source: param }, locations, updateData)
}

const updateComparableSource = (comparableStatus, param, locations, setState, updateData) => {
  setState({ comparableSource: param })

  const updatedLocations = locations.filter(location => location.source !== 'project_comparables')
  updatedLocations.push({
    source: 'project_comparables',
    comparable_source: param,
    comparable_status: comparableStatus,
  })

  updateData(updatedLocations)
}

const updateComparableStatus = (comparableSource, param, locations, setState, updateData) => {
  setState({ comparableStatus: param })

  const updatedLocations = locations.filter(location => location.source !== 'project_comparables')
  updatedLocations.push({
    source: 'project_comparables',
    comparable_source: comparableSource,
    comparable_status: param,
  })

  updateData(updatedLocations)
}

const defaultState = {
  comparableSource: 'all',
  comparableStatus: 'all',
  dataSource: '',
  locationSource: '',

}

const useDigitalTemplateMapData = (options = {}) => {
  const { itemData, updateData } = options
  const locations = digObject(itemData, 'locations', [])

  const [state, setState] = useSetState(defaultState)

  const {
    comparableSource,
    comparableStatus,
    dataSource,
    locationSource,
  } = state

  return {
    callbacks: {
      setState,
      toggleLocation: location => toggleLocation(location, locations, updateData),
      updateComparableSource: param => updateComparableSource(comparableStatus, param, locations, setState, updateData),
      updateComparableStatus: param => updateComparableStatus(comparableSource, param, locations, setState, updateData),
      updateLocationSource: param => updateLocationSource(param, locations, setState, updateData),
    },
    comparableSource,
    comparableStatus,
    dataSource,
    locations,
    locationSource,
  }
}

export default useDigitalTemplateMapData
