export default {
	default: {
		buttons: {
			addPlatform: 'Add External Platform',
		},
		noticeBox: {
			paragraph: 'External Platforms can be connected for things such as exporting projects and importing comparables.',
			title: 'No External Platforms',
		},
	},
}