export default {
  default: {
    title: {
      title: 'Events',
    },
    labelTitle: {
      label: 'Title',
    },
    displayMode: {
      label: 'Display Mode',
    },
    displayModeValues: {
      labelListCompact: 'List (Compact)',
      labelListLarge: 'List (Large)',
    },
    limit: {
      label: 'Limit',
    },
    blankState: {
      title: 'No Calendars Available',
      paragraph: 'There are no calendars available in this project yet. If you’re integrated with a marketing platform that exports calendars you can import them here, or you can create one from scratch.',
    },
    manage: 'Manage',
  },
}
