import { useEffect } from 'react'

import { useSetState } from '@campaignhub/react-hooks'

import useEntityDefaultIntegrationPlatform from '@hooks/useEntityDefaultIntegrationPlatform'
import useIntegrationPlatform from '@hooks/useIntegrationPlatform'

const setExternalDetails = (externalId, integrationPlatformKey, integrationsPayload, setState) => {
  const {
    filteredIntegrations,
    filteredPlatforms,
  } = integrationsPayload

  const selectedPlatform = filteredPlatforms.find(platform => platform.key === integrationPlatformKey) || {}
  const selectedIntegration = filteredIntegrations.find(integration => (
    integration.integration_platform_id === selectedPlatform.id
  ))

  if (selectedIntegration){
    setState({
      selectedIntegrationPlatformKey: integrationPlatformKey,
      selectedProject: { id: externalId, title: `${selectedPlatform.title} #${externalId}` },
    })
  }
}

const defaultState = {
  saleMethod: 'sale',
  selectedIntegrationPlatformKey: '',
  selectedProject: {},
}

function useImportProject(options = {}){
  const {
    integrationsPayload,
    integrationsPayload: {
      entities: { integrationPlatforms },
      filteredIntegrations,
      filteredPlatforms,
    },
    modalPayload: { externalId, integrationPlatformKey },
  } = options

  const [state, setState] = useSetState(defaultState)
  const { selectedIntegrationPlatformKey } = state

  const selectedIntegrationPlatform = filteredPlatforms.find(
    platform => platform.key === selectedIntegrationPlatformKey,
  ) || {}

  const { saleMethodRequired } = useIntegrationPlatform(selectedIntegrationPlatform)

  const { defaultPlatformKey } = useEntityDefaultIntegrationPlatform({ featureKey: 'import_projects' })

  // Check for a user/org's default integration platform, if none auto select first integration platform if only one available
  useEffect(() => {
    if (defaultPlatformKey && !integrationPlatformKey){
      setState({ selectedIntegrationPlatformKey: defaultPlatformKey })
      return
    }

    if (filteredIntegrations.length === 1 && !integrationPlatformKey){
      const selectedIntegration = filteredIntegrations[0] || {}
      const selectedPlatform = integrationPlatforms[selectedIntegration.integration_platform_id] || {}
      setState({ selectedIntegrationPlatformKey: selectedPlatform.key })
    }
  }, [defaultPlatformKey, filteredIntegrations.length])

  // Auto Select if params are provided
  useEffect(() => {
    if (integrationPlatformKey){
      setExternalDetails(externalId, integrationPlatformKey, integrationsPayload, setState)
    }
  }, [filteredIntegrations.length, integrationPlatformKey])

  return {
    callbacks: {
      setState,
    },
    saleMethodRequired,
    state,
  }
}

export default useImportProject
