import React from 'react'
import PropTypes from 'prop-types'

import { digObject } from '@campaignhub/javascript-utils'
import { Image } from '@campaignhub/suit-theme'

const TemplateImage = (props) => {
  const { image, onClick, selected } = props

  const imageUrl = digObject(image, 'sizes.thumb_640x360')

  return (
    <Image
      borderRadius={5}
      boxProps={{
        onClick,
        opacity: selected ? 1 : 0.3,
      }}
      width="100%"
      height="100%"
      style={{ cursor: 'pointer' }}
      url={imageUrl}
    />
  )
}

TemplateImage.propTypes = {
  image: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
}

export default TemplateImage
