export default {
  default: {
    disabledSections: 'Reset Disabled Sections',
    pageSettings: 'Page Settings',
    resetDefaultImage: 'Reset to Default Image',
    resetDefaultText: 'Reset to Default Text',
    resetSortedSections: 'Reset Sorted Sections',
    sortSections: 'Sort Sections',
    swal: {
      confirmButton: 'Yes, reset them',
      confirmDisableTitle: 'Reset Disabled Sections?',
      confirmImageTitle: 'Reset Images?',
      confirmSectionsTitle: 'Reset Sections?',
      confirmTextTitle: 'Reset Texts?',
      text: 'Are you sure? This action cannot be undone.',
    },
    toast: {
      digitalPageUpdated: 'Digital Page Updated Successfully',
    },
  },
}
