import React from 'react'

import { BlankState } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import blankReviewsUrl from './assets/no-reviews.svg'
import localizedStrings from './localizedStrings'

const ReviewsBlankState = () => {
  const { strings } = useLocalization(localizedStrings)

  return (
    <BlankState boxProps={{ marginBottom: ['large', 0] }} imageUrl={blankReviewsUrl}>
      <BlankState.Title>{strings.title || 'No Reviews'}</BlankState.Title>
      <BlankState.Paragraph>
        {strings.paragraph || "You either haven't created any reviews, or you need to adjust your filters to return more results. If you haven't started yet you can create one now from the button at the top of the page."}
      </BlankState.Paragraph>
    </BlankState>
  )
}

export default ReviewsBlankState
