import React from 'react'
import PropTypes from 'prop-types'

import { Box, ListItem, Text } from '@campaignhub/suit-theme'

import useDigitalPageMetricCollection from '@hooks/useDigitalPageMetricCollection'

const DigitalPageMetricCollection = (props) => {
  const {
    digitalPageMetricCollection,
    digitalPageMetricCollection: {
      key, owner_id, owner_title, owner_type, total_proposals_created,
    },
  } = props

  const { callbacks: { viewDigitalPageMetricCollection } } = useDigitalPageMetricCollection(digitalPageMetricCollection)

  return (
    <ListItem
      boxProps={{ borderBottom: '1px dashed', padding: 'large' }}
      onClick={viewDigitalPageMetricCollection}
      showAngleIcon
    >
      <Box flexDirection="column" minWidth="0" paddingRight="medium" width="auto">
        <Text fontSize="small" variant="ellipsis">
          {owner_title}
        </Text>

        <Text color="bodyFontLightColor" fontSize="xsmall" variant="ellipsis">
          {`${owner_type} ID #${owner_id}`}
        </Text>
      </Box>

      <Box flexDirection="column" justifyContent="flex-end" marginLeft="auto" width="auto">
        <Box flexDirection="row">
          <Text color="bodyFontLightColor" fontSize="small" marginRight="small" style={{ whiteSpace: 'nowrap' }}>
            Proposals Created:
          </Text>
          <Text fontSize="small">{total_proposals_created}</Text>
        </Box>

        <Text color="bodyFontLightColor" fontSize="xsmall" variant="ellipsis" marginLeft="auto">
          {key}
        </Text>
      </Box>
    </ListItem>
  )
}

DigitalPageMetricCollection.propTypes = {
  digitalPageMetricCollection: PropTypes.object.isRequired,
}

export default DigitalPageMetricCollection
