export default {
  default: {
    addressCreated: 'Address Created Successfully',
    addressUpdated: 'Address Updated Successfully',
    imageDeleted: 'Image Deleted Successfully',
    organizationUpdated: '{global.organizationType.shortName.singular} successfully updated!',
    saveButton: 'Save Details',
    saveText: 'Save {global.organizationType.shortName.singular}',
    title: '{global.organizationType.shortName.singular} Details',
  },
}
