import useDispatch from '@hooks/useDispatch'
import useSelector from '@hooks/useSelector'

import * as systemActions from '@redux/modules/system'

import type { AppDispatch } from '@redux/store'
import type { SystemModel } from '@models/types'
import { digObject } from '@campaignhub/javascript-utils'

type UpdateSystemParams = {
  dispatch: AppDispatch,
  system: SystemModel,
  systemParams: Partial<SystemModel>,
}

const updateSystem = (params: UpdateSystemParams) => {
  const {
    dispatch, system, systemParams,
  } = params
  const { updateSystem: updateFn } = systemActions

  const updatedParams = {
    id: system.id,
    ...systemParams,
  }

  return dispatch(updateFn(updatedParams))
}

function useSystem() {
  const dispatch = useDispatch()

  const { systems } = useSelector(reduxState => reduxState.entities)

  const system = Object.values(systems).find(s => s.key === 'engage') || {} as SystemModel

  const { updating } = useSelector(reduxState => reduxState.systems)

  const smsProcessingDisabled = digObject(system, 'options.sms_processing_disabled', false)

  return {
    callbacks: {
      updateSystem: (systemParams: Partial<SystemModel>) => (
        updateSystem({
          system, systemParams, dispatch,
        })
      ),
    },
    smsProcessingDisabled,
    system,
    updating,
  }
}

export default useSystem
