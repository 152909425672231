import React from 'react'
import PropTypes from 'prop-types'

import { Box, ListItem, Text } from '@campaignhub/suit-theme'

const CustomField = (props) => {
  const {
    callbacks: { createOrEditCustomField },
    customField: { field_type, label },
  } = props

  return (
    <ListItem
      boxProps={{ as: 'a', borderBottom: '1px dashed', padding: 'large' }}
      onClick={createOrEditCustomField}
      showAngleIcon
    >
      <Box justifyContent="flex-start" minWidth="0" paddingRight="medium" width="auto">
        <Text fontSize="small" variant="ellipsis">
          {label}
        </Text>
      </Box>
      <Box display={['none', 'flex']} justifyContent="flex-end" marginLeft="auto" width="auto">
        <Text color="bodyFontLightColor" fontSize="small" lineHeight={1.3} style={{ whiteSpace: 'nowrap' }}>
          {field_type}
        </Text>
      </Box>
    </ListItem>
  )
}

CustomField.propTypes = {
  callbacks: PropTypes.shape({
    createOrEditCustomField: PropTypes.func,
  }).isRequired,
  customField: PropTypes.object.isRequired,
}

export default CustomField
