import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'

import { digObject } from '@campaignhub/javascript-utils'
import { Box, FormField, Grid, ModalContext, SidebarModal, SidebarNoticeBox } from '@campaignhub/suit-theme'

import useCurrentUser from '@hooks/useCurrentUser'
import useDigitalEditorSelectedEntity from '@hooks/useDigitalEditorSelectedEntity'
import useDigitalTemplate from '@hooks/useDigitalTemplate'
import useLocalization from '@hooks/useLocalization'

import ImageUpload from '@components/ImageUpload'

import DraggableAssetThumbnail from './components/DraggableAssetThumbnail'
import SelectableAssetThumbnail from './components/SelectableAssetThumbnail'

import localizedStrings from './localizedStrings'

const confirmDelete = (image, deleteFn, strings) => {
  swal
    .fire({
      title: strings.swal?.title || 'Delete Image?',
      text: strings.swal?.text || 'Are you sure? ',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: strings.swal?.confirmButtonText || 'Yes, delete it.',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        deleteFn(image)
      }
    })
}

const ManageDigitalTemplateAssetsModal = (props) => {
  const {
    callbacks,
    callbacks: { selectImage },
    draggable,
    modalKey,
    showModal,
  } = props

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const { callbacks: modalCallbacks, digitalTemplate } = digObject(modalData, modalKey, {})
  const { deleteImage: deleteFn, selectImage: selectFn } = modalCallbacks

  const { selectedEntity } = useDigitalEditorSelectedEntity('selectedCanvasItem')

  const canEditImage = digObject(selectedEntity, 'options.editable', false)

  const { owner_id, owner_type, id } = digitalTemplate || {}

  const { templateImages } = useDigitalTemplate(digitalTemplate)

  const { isAdmin, isBrandUser } = useCurrentUser()
  const isUserAdminOrBrand = isAdmin || isBrandUser

  const AssetThumbnail = draggable ? DraggableAssetThumbnail : SelectableAssetThumbnail

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal
      callbacks={callbacks}
      disableAnimation
      disableOverlay
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.titleTemplate || 'Template'}
        titleSecondLine={strings.titleTemplateSecondLine || 'Assets'}
      />

      <SidebarModal.Content hasFooter={false}>
        {(canEditImage || isUserAdminOrBrand) && (
          <Box flexDirection="column">
            <FormField label={strings.titleUploadAsset || 'Upload Asset'}>
              <ImageUpload
                imageSizes={['thumb_640x360', 'thumb_1280x720', 'thumb_2000x1500', 'thumb_3500x2500']}
                imageType="template_image"
                ownerId={owner_id}
                ownerType={owner_type}
                padding={0}
                subjectId={id}
                subjectType="DigitalTemplate"
              />
            </FormField>

            {!!templateImages.length && (
              <FormField label={strings.titleTemplateAssets || 'Template Assets'} marginTop="large">
                <Grid
                  gridGap="medium"
                  gridAutoRows="1fr"
                  gridTemplateColumns="repeat(auto-fill, minmax(158px, 1fr))"
                  gridTemplateRows="repeat(auto-fill, minmax(110px, 1fr))"
                >
                  {templateImages.map(image => (
                    <AssetThumbnail
                      callbacks={{
                        deleteImage: deleteFn ? () => confirmDelete(image, deleteFn, strings) : null,
                        selectImage: selectImage ? () => selectImage(image, selectFn) : null,
                      }}
                      image={image}
                      key={image.id}
                    />
                  ))}
                </Grid>
              </FormField>
            )}
          </Box>
        )}

        {!canEditImage && !isUserAdminOrBrand && (
          <SidebarNoticeBox>
            <SidebarNoticeBox.Paragraph boxProps={{ marginTop: 0 }}>
              {strings.nonEditable || 'Selected item is not editable.'}
            </SidebarNoticeBox.Paragraph>
          </SidebarNoticeBox>
        )}
      </SidebarModal.Content>
    </SidebarModal>
  )
}

ManageDigitalTemplateAssetsModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  draggable: PropTypes.bool,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

ManageDigitalTemplateAssetsModal.defaultProps = {
  draggable: true,
  modalKey: 'ManageDigitalTemplateAssetsModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <ManageDigitalTemplateAssetsModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
