import { useContext } from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'

import {
  Box, Button, FormField, LoadingModule, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import { capitalizeString } from '@functions/string'

import useAssetLibrary, { useAssetLibraryForm } from '@hooks/useAssetLibrary'
import useCurrentUser from '@hooks/useCurrentUser'
import useImages from '@hooks/useImages'
import useLocalization from '@hooks/useLocalization'
import useReduxAction from '@hooks/useReduxAction'

import defaultRequestOptions from '@sections/Client/packs/Admin/defaultRequestOptions'

import EntitySelector from '@components/EntitySelector'
import ModalAttachmentUpload from '@components/ModalComponents/ModalAttachmentUpload'
import ModalImageUpload from '@components/ModalComponents/ModalImageUpload'

import Attachment from './components/Attachment'
import LibraryImage from './components/LibraryImage'

import localizedStrings from './localizedStrings'

const confirmDelete = (params) => {
  const {
    closeModal, deleteAssetLibrary, deleteFn, strings,
  } = params

  const deleteAssetLibraryPayload = {
    callbacks: {
      action: () => deleteFn(),
      afterAction: closeModal,
    },
    toastText: strings.toast?.assetLibraryDeleted || 'Asset Library Deleted Successfully',
  }

  swal
    .fire({
      title: strings.swal?.title || 'Delete Asset Library?',
      text: strings.swal?.text || 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: strings.swal?.confirmButtonText || 'Yes, delete it.',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        deleteAssetLibrary(deleteAssetLibraryPayload)
      }
    })
}

const createOrUpdateAssetLibrary = (params) => {
  const {
    callbacks,
    createFn,
    createOrEditAssetLibrary,
    entityState,
    strings,
    updateFn,
  } = params

  const { closeModal, createAssetLibrary, updateAssetLibrary } = callbacks

  const toastText = entityState.id
    ? strings.toast?.assetLibraryUpdated || 'Asset Library Updated Successfully'
    : strings.toast?.assetLibraryCreated || 'Asset Library Created Successfully'

  const actionFn = entityState.id ? updateFn : createFn

  const afterActionCallbacks = data => (entityState.id
    ? closeModal()
    : createOrEditAssetLibrary({ assetLibrary: data?.entity }))

  const assetLibraryPayload = {
    callbacks: {
      action: actionFn,
      afterAction: ({ response: { data } }) => afterActionCallbacks(data),
    },
    entityParams: entityState,
    toastText,
  }

  return entityState.id ? updateAssetLibrary(assetLibraryPayload) : createAssetLibrary(assetLibraryPayload)
}

const CreateOrEditAssetLibraryModal = (props) => {
  const { callbacks, modalKey, showModal } = props
  const { closeModal, deleteAssetLibrary } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, `${modalKey}`, {})

  const { assetLibrary } = modalPayload

  const { isAdmin, isBrandUser } = useCurrentUser()

  const {
    entityState,
    entityState: { title },
    handlers,
    saveEnabled,
    setEntityState,
  } = useAssetLibraryForm(assetLibrary)

  const {
    assetLibrary: latestAssetLibrary,
    assetLibrary: {
      asset_type, id, owner_id, owner_type,
    },
    attachments,
    callbacks: {
      createAssetLibrary: createFn,
      createOrEditAssetLibrary,
      deleteAssetLibrary: deleteFn,
      updateAssetLibrary: updateFn,
    },
    creating,
    hasAssets,
    hasAttachments,
    isFileLibrary,
    isImageLibrary,
    loading,
    updating,
  } = useAssetLibrary(entityState)

  const {
    callbacks: { updateDefaultImage },
    hasImages,
    sortedImages,
  } = useImages({ subjectId: entityState.id, subjectType: 'AssetLibrary' })

  useReduxAction(
    'assetLibraries',
    'loadAssetLibrary',
    { ...defaultRequestOptions.assetLibrary, ...defaultRequestOptions.image },
    [assetLibrary?.id],
    {
      dispatchAction: (action, requestOptions) => action(assetLibrary.id, requestOptions),
      shouldPerformFn: ({ loadedIds }) => assetLibrary.id && !loadedIds.includes(assetLibrary.id),
    },
  )

  const currentUserCanEdit = isAdmin || isBrandUser || assetLibrary.owner_type !== 'Brand'

  const {
    callbacks: { formatString },
    strings,
  } = useLocalization(localizedStrings)

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        title={
          entityState.id ? strings.modalHeader?.titleUpdate || 'Update' : strings.modalHeader?.titleCreate || 'Create'
        }
        titleSecondLine={
          formatString(strings.modalHeader?.titleSecondLine, { asset_type: capitalizeString(asset_type) })
          || `${capitalizeString(asset_type)} Library`
        }
      />

      <SidebarModal.Content>
        {currentUserCanEdit && (
          <FormField direction="column" label={strings.formFieldLabels?.title || '* Title'}>
            <input data-validate-field-on="change" name="title" type="text" value={title} {...handlers} />
          </FormField>
        )}

        {isAdmin && (
          <EntitySelector
            boxProps={{ marginTop: 'large' }}
            callbacks={{
              selectItem: selected => setEntityState({
                owner_id: selected?.id,
                owner_type: 'Brand',
              }),
            }}
            entityKey="brands"
            selectedItemId={owner_id}
          />
        )}

        {assetLibrary.id && (
          <>
            {currentUserCanEdit && (
              <>
                {isFileLibrary && (
                  <FormField
                    direction="column"
                    label={strings.formFieldLabels?.uploadFiles || 'Files'}
                    marginTop="large"
                  >
                    <ModalAttachmentUpload
                      attachmentType="library_file"
                      ownerId={owner_id}
                      ownerType={owner_type}
                      requestOptions={defaultRequestOptions.attachment}
                      subjectId={assetLibrary.id}
                      subjectType="AssetLibrary"
                    />
                  </FormField>
                )}

                {isImageLibrary && (
                  <ModalImageUpload
                    imageLabel={strings.formFieldLabels?.uploadImages || 'Images'}
                    imageSizes={['thumb_660x360', 'thumb_200x120', 'thumb_120x80']}
                    ownerId={owner_id}
                    ownerType={owner_type}
                    subjectId={id}
                    subjectType="AssetLibrary"
                  />
                )}
              </>
            )}

            <FormField
              direction="column"
              label={
                `Library ${capitalizeString(asset_type)}s`
                || strings.formFieldLabels?.libraryContents
                || 'Library Contents'
              }
              marginTop="large"
            >
              <LoadingModule loading={loading} />

              {!loading && !hasAssets && (
                <Box variant="whiteGrey" color="mysteryGrey" fontSize="small" marginBottom="large" padding="large">
                  {strings.noContents || 'No Contents, please add above.'}
                </Box>
              )}

              {!loading
                && hasAttachments
                && attachments.map(attachment => <Attachment key={attachment.id} attachment={attachment} />)}

              {!loading
                && hasImages
                && sortedImages.map(image => (
                  <LibraryImage
                    assetLibrary={latestAssetLibrary}
                    callbacks={{ updateDefaultImage }}
                    currentUserCanEdit={currentUserCanEdit}
                    image={image}
                    key={image.id}
                  />
                ))}
            </FormField>

            {currentUserCanEdit && assetLibrary.id && (
              <FormField
                direction="column"
                label={strings.formFieldLabels?.moreOptions || 'More Options'}
                marginTop="large"
              >
                <Button
                  buttonStyle="secondaryUtility"
                  icon={<FontAwesomeIcon icon={faTrashAlt} />}
                  onClick={() => confirmDelete({
                    closeModal, deleteAssetLibrary, deleteFn, strings,
                  })}
                  size="medium"
                  width="100%"
                >
                  {strings.deleteButtonText || 'Delete Library'}
                </Button>
              </FormField>
            )}
          </>
        )}
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled || !currentUserCanEdit}
          loading={creating || updating}
          onClick={() => createOrUpdateAssetLibrary({
            callbacks,
            createFn,
            createOrEditAssetLibrary,
            entityState,
            strings,
            updateFn,
          })}
          size="large"
        >
          {strings.saveButtonText || 'Save'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

CreateOrEditAssetLibraryModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

CreateOrEditAssetLibraryModal.defaultProps = {
  modalKey: 'CreateOrEditAssetLibraryModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <CreateOrEditAssetLibraryModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
