import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faEye, faPencil } from '@fortawesome/pro-light-svg-icons'

import { Button, PageHeader } from '@campaignhub/suit-theme'

import { getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const Header = (props) => {
  const {
    digitalCustomizerPayload: {
      callbacks: { showManageDigitalPageFeaturesModal, togglePreviewMode, updateTemplateViewMode },
      isMobileDevice,
      previewing,
      templateViewMode,
    },
  } = props

  const { redirect } = parsePermittedQueryParams(getQueryParamsFromHash(), ['redirect'])

  const { strings } = useLocalization(localizedStrings)

  return (
    <PageHeader
      boxProps={{ justifyContent: 'flex-start' }}
      offset={{ left: 0, top: 0 }}
      width={!isMobileDevice ? 'calc(100% - 375px)' : '100%'}
      title={strings.title || 'Customize Template'}
      actionContent={(
        <>
          <Button
            buttonStyle={previewing ? 'primaryEdit' : 'ghostEdit'}
            icon={<FontAwesomeIcon icon={previewing ? faPencil : faEye} />}
            marginRight="medium"
            onClick={togglePreviewMode}
            size="medium"
            width="auto"
          >
            {previewing ? 'Customize' : 'Preview'}
          </Button>

          {isMobileDevice && (
            <Button
              buttonStyle="secondaryUtility"
              icon={<FontAwesomeIcon icon={faCog} />}
              marginRight="medium"
              onClick={showManageDigitalPageFeaturesModal}
              size="medium"
              width="auto"
            />
          )}

          {templateViewMode === 'default' && (
            <Button as="a" href={redirect || '#/templates'} buttonStyle="primaryCreate" size="medium" width="auto">
              {strings.goBack || 'Go Back'}
            </Button>
          )}

          {templateViewMode === 'source' && (
            <Button
              as="a"
              buttonStyle="secondaryUtility"
              onClick={() => updateTemplateViewMode('default')}
              size="medium"
              width="auto"
            >
              {strings.cancel || 'Cancel'}
            </Button>
          )}
        </>
      )}
    />
  )
}

Header.propTypes = {
  digitalCustomizerPayload: PropTypes.shape({
    callbacks: PropTypes.shape({
      showManageDigitalPageFeaturesModal: PropTypes.func.isRequired,
      togglePreviewMode: PropTypes.func.isRequired,
      updateTemplateViewMode: PropTypes.func.isRequired,
    }).isRequired,
    isMobileDevice: PropTypes.bool,
    previewing: PropTypes.bool,
    templateViewMode: PropTypes.string,
  }).isRequired,
}

export default Header
