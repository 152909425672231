import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckSquare, faCrown, faHome, faWrench, faUser,
} from '@fortawesome/pro-light-svg-icons'

import {
  Box, Image, ListItem, Text,
} from '@campaignhub/suit-theme'

const icons = {
  average_proposals_accepted_per_user: faUser,
  average_proposals_created_per_project: faHome,
  maximum_proposals_created_by_user: faCrown,
  total_proposals_accepted: faCheckSquare,
  total_proposals_created: faWrench,
}

const Metric = (props) => {
  const { metricKey, label, value } = props

  return (
    <ListItem boxProps={{ alignItems: 'center', paddingX: 'none', paddingY: 'medium' }} disableHover>
      <Box alignItems="center" justifyContent="center" flexShrink={0} height={28} width={28} marginRight="medium">
        <Image boxProps={{ backgroundColor: 'hoverLightGrey' }} circle width={28}>
          <FontAwesomeIcon icon={icons[metricKey] || faWrench} />
        </Image>
      </Box>

      <Box justifyContent="space-between" flexDirection="row">
        <Text color="bodyFontLightColor" fontSize="xsmall">
          {label}
        </Text>
        <Text fontSize="small">{value}</Text>
      </Box>
    </ListItem>
  )
}

Metric.propTypes = {
  metricKey: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}

export default Metric
