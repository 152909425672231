export default {
  default: {
    disabledText: 'Recipient Limit Reached',
    formFieldLabels: {
      recipients: 'Recipients',
      recipientsLimit: 'Recipients (Limit 3)',
      recipientsLimitReached: 'Recipients (Limit Reached)',
      searchUsers: 'Search Users',
    },
  },
}
