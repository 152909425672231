export default {
  default: {
    formFieldLabels: {
      moreOptions: 'More Options',
    },
    modalHeader: {
      title: 'Manage',
      titleSecondLine: 'Agreement',
    },
    buttons: {
      build: 'Build Agreement',
      download: 'Download Agreement',
      edit: 'Edit Agreement',
      send: 'Send Agreement',
    },
  },
}
