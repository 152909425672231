import React from 'react'
import PropTypes from 'prop-types'

import { SidebarModal } from '@campaignhub/suit-theme'

import Input from '@components/Input'
import ShortcodeSearch from '@components/digitalTemplates/ShortcodeSearch'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const shouldRender = (itemType) => {
  const permittedItemTypes = ['comparables', 'dataArrayLoop', 'image', 'reviews', 'text', 'video']
  return permittedItemTypes.includes(itemType)
}

const updateData = (name, value, props) => {
  const {
    callbacks: { updateParam },
    selectedEntity,
  } = props

  const updatedStyle = {
    ...selectedEntity.data,
    [name]: value,
  }

  updateParam('data', updatedStyle)
}

const renderValue = (selectedEntity, itemData, props, strings) => {
  const { id } = selectedEntity

  return (
    <Input
      data={itemData}
      entityId={id}
      label={strings.value || 'Value'}
      propertyKey="value"
      textarea
      updateCallback={(name, value) => updateData(name, value, props)}
      version={itemData.value}
    />
  )
}

const renderShortcode = (selectedEntity, itemData, props, strings) => {
  const { id, item_type } = selectedEntity

  const unpermittedTypes = ['icon', 'video']
  if (!unpermittedTypes.includes(item_type)){
    return (
      <Input
        boxProps={{ marginTop: 'large' }}
        data={itemData}
        entityId={id}
        label={strings.shortcode || 'Shortcode'}
        propertyKey="shortcode"
        updateCallback={(name, value) => updateData(name, value, props)}
      />
    )
  }

  return null
}

const renderShortcodeSearch = (itemData, props) => {
  const {
    dataContext,
    selectedEntity: { item_type },
  } = props

  if (item_type === 'text' || (item_type === 'dataArrayLoop' && dataContext.length)){
    return (
      <ShortcodeSearch
        callbacks={{ selectItem: value => updateData('value', value, props) }}
        dataContext={dataContext}
        itemData={itemData}
      />
    )
  }

  return null
}

const Data = (props) => {
  const {
    selectedEntity,
    selectedEntity: { item_type },
  } = props

  const { strings } = useLocalization(localizedStrings)

  if (!shouldRender(item_type)) return null

  const itemData = selectedEntity.data || {}

  return (
    <SidebarModal.ExpandableSectionBox defaultOpen label={strings.data || 'Data'}>
      {renderShortcodeSearch(itemData, props)}
      {renderValue(selectedEntity, itemData, props, strings)}
      {renderShortcode(selectedEntity, itemData, props, strings)}
    </SidebarModal.ExpandableSectionBox>
  )
}

Data.propTypes = {
  selectedEntity: PropTypes.shape({
    data: PropTypes.object,
    id: PropTypes.string,
    item_type: PropTypes.string,
  }),
}

export default Data
