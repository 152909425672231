import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons'

import { useSetState } from '@campaignhub/react-hooks'

import { digObject } from '@campaignhub/javascript-utils'

import {
  Box, Button, FormField, Link, SidebarModal, Text,
} from '@campaignhub/suit-theme'

const confirmDelete = (action, font, modifyFn) => {
  swal
    .fire({
      title: 'Delete Font?',
      text: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it.',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        modifyFn(action, font)
      }
    })
}

const defaultState = {
  fontValue: '',
}

const Fonts = (props) => {
  const { digitalTemplatePayload } = props

  const {
    callbacks: { modifyDigitalTemplateFonts: modifyFn },
    digitalTemplateOptions,
  } = digitalTemplatePayload

  const [state, setState] = useSetState(defaultState)
  const { fontValue } = state

  const fonts = digObject(digitalTemplateOptions, 'fonts', [])

  useEffect(() => setState({ fontValue: '' }), [fonts.length])

  return (
    <SidebarModal.ExpandableSectionBox label="Fonts">
      <Box flexDirection="column">
        {!!fonts && !!fonts.length && (
          <FormField label="Current Fonts">
            <Box flexDirection="column" marginBottom="medium">
              {fonts.map(font => (
                <Box
                  alignItems="center"
                  color="bodyFontColor"
                  fontSize="small"
                  key={font}
                  marginBottom="medium"
                  padding="medium"
                  variant="whiteGrey"
                >
                  <Text color="bodyFontLightColor" variant="twoLineEllipsis">
                    {font}
                  </Text>

                  <Box marginLeft="auto" width="auto">
                    <Link greyLink onClick={() => confirmDelete('delete', font, modifyFn)}>
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </Link>
                  </Box>
                </Box>
              ))}
            </Box>
          </FormField>
        )}

        <FormField boxProps={{ marginBottom: 'large' }} label="Add Font">
          <Box flexDirection="column">
            <input
              onChange={e => setState({ fontValue: e.target.value })}
              style={{ marginBottom: 8 }}
              value={fontValue}
            />

            <Button
              buttonStyle="ghostCreate"
              disabled={!fontValue}
              onClick={() => modifyFn('add', fontValue)}
              size="medium"
              style={{ width: 'auto' }}
            >
              Add
            </Button>
          </Box>
        </FormField>
      </Box>
    </SidebarModal.ExpandableSectionBox>
  )
}

Fonts.propTypes = {
  callbacks: PropTypes.shape({
    toggleDigitalTemplateFonts: PropTypes.func.isRequired,
  }),
  digitalTemplatePayload: PropTypes.object.isRequired,
}

export default Fonts
