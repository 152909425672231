import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, Button, DashboardModule, FormField,
} from '@campaignhub/suit-theme'

import useUserImpersonation from '@hooks/useUserImpersonation'

const ImpersonateUser = () => {
  const userImpersonationPayload = useUserImpersonation()
  const { callbacks: { impersonateUser, setState }, supportToken } = userImpersonationPayload

  return (
    <DashboardModule title="Login as User">
      <Box flexDirection="column" padding="large">
        <FormField label="Support Code">
          <Box>
            <input onChange={e => setState({ supportToken: e.target.value })} type="text" value={supportToken} />

            <Button
              buttonStyle="primaryEdit"
              disabled={supportToken.length < 6}
              marginLeft="medium"
              onClick={() => impersonateUser()}
              size="medium"
              width="auto"
            >
              Login
            </Button>
          </Box>
        </FormField>
      </Box>
    </DashboardModule>
  )
}

ImpersonateUser.propTypes = {}

export default ImpersonateUser
