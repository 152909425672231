import { useMemo } from 'react'

import { matchFilterString, sortArrayBy } from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { BrandModel, BrandRequestOptions } from '@models/types'
import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['brands']

type BrandFilters = {
  string?: string,
}

type UseBrandsOptions = {
  filters?: BrandFilters,
  performHttpRequests?: boolean,
  requestOptions?: BrandRequestOptions,
}

function useBrands(options: UseBrandsOptions) {
  const { filters = {}, requestOptions } = options
  const {
    string: filterString,
  } = filters

  const {
    updatedEntities: { brands: brandsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { brands } = useSelector(reduxState => reduxState.entities)

  const filteredBrands = useMemo(() => {
    const filtered = Object.values(brands).filter((brand: BrandModel) => {
      const { title } = brand

      const matchString = matchFilterString(title, filterString)

      return matchString
    })

    return sortArrayBy(filtered, 'asc', 'title')
  }, [brandsUpdatedAt, JSON.stringify(options)])

  const filteredBrandsCount = filteredBrands.length
  const hasFilteredBrands = !!filteredBrandsCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredBrandsCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingBrands } = useReduxAction(
    'brands',
    'loadBrands',
    {
      ...requestOptions,
      ...filtersWithOffset,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredBrands,
    filteredBrandsCount,
    hasBrands: hasFilteredBrands,
    loading: loadingBrands,
  }
}

export default useBrands
