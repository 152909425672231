export default {
  default: {
    toast: {
      updateEventCalendar: 'Calendar Updated Successfully',
      deleteEventCalendar: 'Calendar Deleted Successfully',
      createTemplate: 'Template Created Successfully',
      event: {
        created: 'Event Created Successfully',
        deleted: 'Event Deleted Successfully',
        duplicated: 'Event Duplicated Successfully',
        updated: 'Event Updated Successfully',
      },
      eventGroup: {
        created: 'Group Created Successfully',
        deleted: 'Group Deleted Successfully',
        updated: 'Group Updated Successfully',
      },
    },
    helmet: {
      title: 'Calendar Builder',
      titleEngage: 'Engage',
    },
  },
}
