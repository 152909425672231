import React from 'react'

import { BlankState } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import blankStateImage from './assets/user-tiles.svg'

import localizedStrings from './localizedStrings'

const UsersBlankState = () => {
  const { strings } = useLocalization(localizedStrings)

  return (
    <BlankState imageUrl={blankStateImage}>
      <BlankState.Title>{strings.title || 'No Users'}</BlankState.Title>
      <BlankState.Paragraph>{strings.paragraph || 'Try adjusting your filters to return more results.'}</BlankState.Paragraph>
    </BlankState>
  )
}

export default UsersBlankState
