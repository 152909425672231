import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, Button, FormField, Tag,
} from '@campaignhub/suit-theme'

import useCustomFieldTags from '@hooks/useCustomFieldTags'

const FieldTags = (props) => {
  const { callbacks: { setEntityState }, options } = props

  const customFieldTagsPayload = useCustomFieldTags(options, { setEntityState })
  const {
    callbacks: { setState, toggleTag }, state: { value }, tagExists, tags,
  } = customFieldTagsPayload

  return (
    <FormField boxProps={{ marginBottom: 'large' }} direction="column" label="Tags">
      <Box>
        <input value={value} onChange={e => setState({ value: e.target.value })} type="text" />

        <Button
          buttonStyle={tagExists ? 'secondaryDestroy' : 'secondaryCreate'}
          onClick={() => toggleTag(value)}
          marginLeft="small"
          size="small"
          width="auto"
        >
          {tagExists ? 'Remove' : 'Add'}
        </Button>
      </Box>

      {!!tags.length && (
        <Box marginTop="medium">
          {tags.map(tag => (
            <Tag boxProps={{ onClick: () => toggleTag(tag) }} key={tag}>
              {tag}
            </Tag>
          ))}
        </Box>
      )}
    </FormField>
  )
}

FieldTags.propTypes = {
  callbacks: PropTypes.shape({
    setEntityState: PropTypes.func.isRequired,
  }).isRequired,
  options: PropTypes.shape({
    tags: PropTypes.array.isRequired,
  }).isRequired,
}

export default FieldTags
