import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt } from '@fortawesome/pro-light-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'

import {
  Box, ListItem, StatusBadge, Text,
} from '@campaignhub/suit-theme'

import useCurrentUser from '@hooks/useCurrentUser'
import { generateUrls } from '@hooks/useFormTemplate'
import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const FormTemplate = (props) => {
  const { formTemplate } = props
  const {
    dates, id, production_ready, title,
  } = formTemplate || {}

  const createdDateTime = digObject(dates, 'created.date_time_with_timezone')

  const { isAdmin, isBrandUser } = useCurrentUser()
  const isUserAdminOrBrand = isAdmin || isBrandUser

  const { editFormTemplateUrl } = generateUrls(isUserAdminOrBrand, formTemplate)
  const { callbacks: { formatDate }, strings } = useLocalization(localizedStrings)

  return (
    <ListItem
      boxProps={{ as: 'a', borderBottom: '1px dashed', padding: 'large' }}
      href={editFormTemplateUrl}
      showAngleIcon
    >
      <Box
        alignItems="center"
        backgroundColor="backgroundColor"
        borderRadius={5}
        color="bodyFontLightColor"
        flexShrink={0}
        height={40}
        justifyContent="center"
        position="relative"
        width={40}
      >
        <FontAwesomeIcon icon={faFileAlt} />
      </Box>

      <Box flexDirection="column" justifyContent="center" marginLeft="large" minWidth="0">
        <Text fontSize="small">{title}</Text>

        <Text color="bodyFontLightColor" fontSize="xsmall" paddingTop="medium">
          {strings.idText || 'ID'} #{id} | {strings.createdText || 'Created'}{' '}
          {formatDate(createdDateTime, DateTime.DATE_FULL)}
        </Text>
      </Box>

      <Box marginRight="medium" width="auto" flexDirection="row">
        <StatusBadge
          boxProps={{
            height: '50%',
            alignSelf: 'center',
          }}
          color={production_ready ? 'blue' : 'faintGrey'}
          text={production_ready ? 'Published' : 'Draft'}
        />
      </Box>
    </ListItem>
  )
}

FormTemplate.propTypes = {
  formTemplate: PropTypes.object.isRequired,
}

export default FormTemplate
