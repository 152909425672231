import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faGlobe, faPencil } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, IconWithMenu, Link, ListItem, StatusBadge, Text,
} from '@campaignhub/suit-theme'

import generateRedirectUrl from '@functions/generateRedirectUrl'

import useOrganization from '@hooks/useOrganization'
import useOrganizationsUser from '@hooks/useOrganizationsUser'

const OrganizationsUser = (props) => {
  const { organizationsUser } = props

  const {
    callbacks: { editOrganizationsUser },
    organization,
    organization: { title },
    organizationsUser: { default: isDefault, admin },
  } = useOrganizationsUser(organizationsUser)

  const {
    urls: { editOrganizationUrl },
  } = useOrganization(organization)

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        borderBottom: '1px dashed',
        justifyContent: 'space-between',
        padding: 'large',
      }}
      disableHover
    >
      <Box flexDirection="column" justifyContent="center" lineHeight={1.3}>
        <Text color="mysteryGrey" fontSize="small" marginBottom="small">
          {title}
        </Text>

        <Text color="faintGrey" fontSize="xsmall">
          {admin ? 'Organization Admin' : 'Organization User'}
        </Text>
      </Box>

      {isDefault && (
        <Box alignItems="center" color="faintGrey" fontSize="small" marginRight="large" justifyContent="flex-end">
          <StatusBadge color="grey" ghost text="default" />
        </Box>
      )}

      <IconWithMenu
        icon={(
          <Button
            buttonStyle="secondaryUtility"
            icon={<FontAwesomeIcon icon={faCog} />}
            size="medium"
            height={37}
          />
        )}
        style={{ marginLeft: 'auto', width: 'auto' }}
      >
        <IconWithMenu.Menu closeOnClick listStyle={{ right: '-7px' }}>
          <Link href={`${editOrganizationUrl}?redirect=${generateRedirectUrl()}`} key="organization">
            <FontAwesomeIcon icon={faGlobe} /> Go to Organization
          </Link>

          <Link key="permissions" onClick={editOrganizationsUser}>
            <FontAwesomeIcon icon={faPencil} /> Edit
          </Link>
        </IconWithMenu.Menu>
      </IconWithMenu>
    </ListItem>
  )
}

OrganizationsUser.propTypes = {
  features: PropTypes.shape({
    canEditUser: PropTypes.bool,
    isSelf: PropTypes.bool,
  }),
  organizationsUser: PropTypes.object.isRequired,
}

export default OrganizationsUser
