import { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'

import PropTypes from 'prop-types'

import { faHome, faUserTag } from '@fortawesome/pro-light-svg-icons'

import { getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'

import {
  Box, Button, Columns, DashboardModule, LoadingModule, PageHeader,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import defaultRequestOptions from '@sections/Client/packs/Admin/defaultRequestOptions'

import useLocalization from '@hooks/useLocalization'
import useReduxAction from '@hooks/useReduxAction'
import useTargetAudience from '@hooks/useTargetAudience'
import useTargetAudiences from '@hooks/useTargetAudiences'
import useVideoModules from '@hooks/useVideoModules'

import PageFilterModule from '@components/PageFilterModule'
import VideoModule from '@components/VideoModule'

import localizedStrings from './localizedStrings'

import TargetAudiencesBlankState from '../TargetAudiencesBlankState'
import TargetAudience from '../TargetAudience'

const buildPageFilterFields = (strings) => {
  const style = {
    marginBottom: 16,
  }

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        style,
      },
      key: 'string',
      label: strings.filterByNameLabel || 'Filter by Name',
    },
    {
      entityKey: 'limit',
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: '100',
        inputComponent: 'select',
        style,
      },
      key: 'limit',
      label: strings.limitLabel || 'Limit',
      values: [
        { key: 150, label: '150' },
        { key: 200, label: '200' },
      ],
    },
  ]
}

const getActiveTabBarKey = (currentOwnerType) => {
  if (currentOwnerType === 'Organization') return 'target-audiences-agency'
  if (currentOwnerType === 'Brand') return 'target-audiences-brand'
  return 'target-audiences-agency'
}

const PageContent = (props) => {
  const { organization, pageFilters } = props

  // useLocation to refresh the window.location. If not used location won't reload if URL changes
  useLocation()

  const { ownerType } = parsePermittedQueryParams(getQueryParamsFromHash(), ['ownerType'])
  const currentOwnerType = ownerType || 'Organization'

  const activeTabBarItemKey = getActiveTabBarKey(currentOwnerType)

  const initTargetAudience = {
    ...pageFilters,
    owner_id: organization.id,
    owner_type: 'Organization',
    organization_type_id: organization.organization_type_id,
  }

  useReduxAction(
    'targetAudiences',
    'loadTargetAudiences',
    {
      ...pageFilters,
      ...defaultRequestOptions.image,
      ...defaultRequestOptions.targetAudience,
      owner_type: currentOwnerType,
    },
    [currentOwnerType, JSON.stringify(pageFilters)],
  )

  const targetAudiencesPayload = useTargetAudiences({
    filters: {
      ...pageFilters,
      owner_type: currentOwnerType,
    },
  })

  const { filteredTargetAudiences, hasTargetAudiences, loading } = targetAudiencesPayload

  const {
    callbacks: { createOrEditTargetAudience },
  } = useTargetAudience(initTargetAudience)

  const pageContext = useContext(PageContext)
  const {
    callbacks: { resetFilters, updateFilters },
  } = pageContext

  const videoModulePayload = useVideoModules({ key: 'targeAudiences' })
  const { videosPayload } = videoModulePayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      <Helmet>
        <title>{strings.helmetTitle || 'Target Audiences | Engage'}</title>
      </Helmet>

      <PageHeader
        actionContent={
          hasTargetAudiences && (
            <Button buttonStyle="primaryCreate" onClick={() => createOrEditTargetAudience()} size="medium">
              {strings.createButton || 'Create'}
            </Button>
          )
        }
        activeTabBarItemKey={activeTabBarItemKey}
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        nestedNavigation
        tabBarItems={[
          {
            href: '#/admin/targetAudiences?ownerType=Organization',
            icon: faUserTag,
            key: 'target-audiences-agency',
            title: strings.titleOrganization || 'Target Audiences (Agency)',
          },
          {
            href: '#/admin/targetAudiences?ownerType=Brand',
            icon: faHome,
            key: 'target-audiences-brand',
            title: strings.titleBrand || 'Target Audiences (Brand)',
          },
        ]}
        title={strings.title || 'Target Audiences'}
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && hasTargetAudiences && (
              <DashboardModule title={strings.title || 'Target Audiences'}>
                <Box flexDirection="column">
                  <Box flexDirection="column">
                    {filteredTargetAudiences.map((targetAudience, index) => {
                      const lastItem = filteredTargetAudiences.length === index + 1

                      return (
                        <TargetAudience key={targetAudience.id} lastItem={lastItem} targetAudience={targetAudience} />
                      )
                    })}
                  </Box>

                  <DashboardModule.Footer>
                    <DashboardModule.FooterRight>
                      <Button
                        buttonStyle="secondaryEdit"
                        onClick={() => createOrEditTargetAudience()}
                        size="medium"
                        style={{ width: '100%' }}
                      >
                        {strings.button || 'Add Target Audience'}
                      </Button>
                    </DashboardModule.FooterRight>
                  </DashboardModule.Footer>
                </Box>
              </DashboardModule>
            )}

            {!loading && !hasTargetAudiences && (
              <TargetAudiencesBlankState organization={organization} ownerType={ownerType} />
            )}
          </Columns.Main>

          <Columns.Sidebar>
            <PageFilterModule
              callbacks={{ resetFilters, updateFilters }}
              filterFields={buildPageFilterFields(strings)}
              pageFilters={pageFilters}
              title={strings.filters || 'Filters'}
            />

            <VideoModule videosPayload={videosPayload} />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

PageContent.propTypes = {
  organization: PropTypes.object.isRequired,
  pageFilters: PropTypes.object,
}

export default PageContent
