export default {
  default: {
    formFieldLabels: {
      findOrganization: 'Find {global.organizationType.shortName.singular}',
    },
    mapResults: {
      title: '{global.organizationType.shortName.singular}',
    },
    organizationSearchPlaceholder: 'Search for {global.organizationType.shortName.singular}',
  },
}
