import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { EntitySelector as EntitySelectorBase } from '@campaignhub/suit-theme'

import searchEndpoint from '@functions/search'

import useReduxAction from '@hooks/useReduxAction'

export const entitySettings = {
  brands: {
    actionName: 'loadBrand',
    groupTitle: 'BRANDS',
    key: 'brands',
    label: 'Brand',
    labelPlural: 'Brands',
  },
  digitalTemplates: {
    actionName: 'loadDigitalTemplate',
    groupTitle: 'Digital Templates',
    key: 'digitalTemplates',
    label: 'Digital Template',
    labelPlural: 'Digital Templates',
  },
  digitalTemplateComponents: {
    actionName: 'loadDigitalTemplateComponent',
    groupTitle: 'Digital Template Components',
    key: 'digitalTemplateComponents',
    label: 'Digital Template Component',
    labelPlural: 'Digital Template Components',
  },
  formTemplates: {
    actionName: 'loadFormTemplate',
    groupTitle: 'FORM TEMPLATES',
    key: 'formTemplates',
    label: 'Form Template',
    labelPlural: 'Form Templates',
  },
  organizations: {
    actionName: 'loadOrganization',
    groupTitle: 'ORGANIZATIONS',
    key: 'organizations',
    label: 'Organization',
    labelPlural: 'Organizations',
  },
  projects: {
    actionName: 'loadProject',
    groupTitle: 'PROJECTS',
    key: 'projects',
    label: 'Project',
    labelPlural: 'Projects',
  },
  users: {
    actionName: 'loadUser',
    groupTitle: 'USERS',
    key: 'users',
    label: 'User',
    labelPlural: 'Users',
  },
}

const EntitySelector = (props) => {
  const { callbacks, ...restProps } = props

  const entities = useSelector(reduxState => reduxState.entities)

  return (
    <EntitySelectorBase
      callbacks={{
        ...callbacks,
        getSearchEntities: result => result.entities,
        search: searchEndpoint,
      }}
      entities={entities}
      entitySettings={entitySettings}
      useReduxAction={useReduxAction}
      {...restProps}
    />
  )
}

EntitySelector.propTypes = {
  callbacks: PropTypes.shape({
    selectItem: PropTypes.func.isRequired,
  }).isRequired,
  endpoint: PropTypes.string,
  entityKey: PropTypes.string.isRequired,
  entityTitleKey: PropTypes.string,
  requestOptions: PropTypes.object,
  selectedIds: PropTypes.array,
  selectedItemId: PropTypes.number,
  showEntityId: PropTypes.bool,
}

EntitySelector.defaultProps = {
  entityTitleKey: 'title',
  selectedIds: [],
}

export default EntitySelector
