import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import copy from 'copy-to-clipboard'
import { toast } from 'react-toastify'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboard, faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'

import useDraggable from '@hooks/useDraggable'

import styles from './styles.module.scss'

const copyImageUrl = (imageUrl) => {
  copy(imageUrl)
  toast('Image URL Copied to Clipboard')
}

const endDrag = (e) => {
  const { target } = e
  target.parentNode.removeChild(target)
}

const DraggableAssetThumbnail = (props) => {
  const {
    callbacks: { deleteImage },
    image,
  } = props

  const imageUrl = digObject(image, 'sizes.thumb_640x360', '')

  const itemRef = useRef()

  useDraggable(itemRef, {
    callbacks: {
      moveEnd: e => endDrag(e),
    },
    createElementClone: true,
    documentElementId: 'main-stage',
    draggingClassName: styles.dragging,
  })

  return (
    <div
      className={classnames(styles.image, 'draggable')}
      data-image-id={image.id}
      data-item-type="image.id"
      ref={itemRef}
      style={{
        backgroundImage: `url(${imageUrl})`,
        zIndex: 999,
      }}
    >
      <span className={styles.overlay}>
        <a onClick={() => deleteImage(image)} tabIndex="0">
          <FontAwesomeIcon icon={faTrashAlt} />
        </a>

        <a onClick={() => copyImageUrl(imageUrl)} style={{ marginLeft: 10 }} tabIndex="0">
          <FontAwesomeIcon icon={faClipboard} />
        </a>
      </span>
    </div>
  )
}

DraggableAssetThumbnail.propTypes = {
  callbacks: PropTypes.shape({
    deleteImage: PropTypes.func,
  }),
  image: PropTypes.object.isRequired,
}

export default DraggableAssetThumbnail
