import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import {
  BlankState, Box, Button, FormField, LoadingBubbles, SidebarModal,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

import PrintHandsImage from './assets/print.svg'

const ContextWrappedModal = (props) => {
  const { context } = props
  const contextProps = useContext(context)

  if (context){
    const mergedProps = {
      ...props,
      ...contextProps,
    }

    return <BuildingAttachmentModal {...mergedProps} />
  }

  return <BuildingAttachmentModal {...props} />
}

ContextWrappedModal.propTypes = {
  context: PropTypes.object,
}

const downloadPdf = (options = {}, props) => {
  const {
    attachment: { file },
    callbacks: { closeModal },
  } = props

  window.open(file.url)
  if (!options.cancelCloseModal) closeModal()
}

const renderContent = (props, strings) => {
  const { attachment, building } = props

  if (!building){
    return (
      <BlankState imageUrl={PrintHandsImage} sidebar>
        <BlankState.Paragraph>
          {strings?.blankState?.buildComplete?.paragraph
            || 'Congratulations. Your PDF is now available for download at the following url, or by clicking the download button below.'}
        </BlankState.Paragraph>

        <BlankState.Paragraph>
          <FormField label={strings?.blankState?.buildComplete?.formFieldLabel || 'File Url'}>
            <input type="text" defaultValue={attachment.file?.url} />
          </FormField>
        </BlankState.Paragraph>

        <BlankState.Actions>
          <Button
            buttonStyle="primaryEdit"
            onClick={() => downloadPdf({}, props)}
            size="medium"
            target="_blank"
            width="auto"
          >
            {strings?.blankState?.buildComplete?.button || 'Download PDF'}
          </Button>
        </BlankState.Actions>
      </BlankState>
    )
  }

  return (
    <BlankState imageUrl={PrintHandsImage} sidebar>
      <BlankState.Paragraph>
        {strings?.blankState?.building?.paragraphOne
          || 'Have a quick break while we build your document. Larger, more image dense files require compiling to produce an optimized file that can be printed locally or by one of your print suppliers.'}
      </BlankState.Paragraph>

      <BlankState.Paragraph>
        {strings?.blankState?.building?.paragraphTwo
          || 'Please wait while we compile your PDF in the background. It should only take a few moments. If you experience any issues please contact support.'}
      </BlankState.Paragraph>

      <BlankState.Actions>
        <Button buttonStyle="secondaryEdit" icon={<LoadingBubbles color="lightGrey" />} size="medium" width="auto">
          {strings?.blankState?.building?.button || 'Please Wait...'}
        </Button>
      </BlankState.Actions>
    </BlankState>
  )
}

const BuildingAttachmentModal = (props) => {
  const {
    building, callbacks, modalKey, showModal,
  } = props

  const { strings } = useLocalization(localizedStrings)

  if (!showModal){
    return null
  }

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        title={
          building ? strings?.header?.building?.title || 'Building' : strings?.header?.buildComplete?.title || 'PDF'
        }
        titleSecondLine={
          building
            ? strings?.header?.building?.titleSecondLine || 'PDF'
            : strings?.header?.buildComplete?.titleSecondLine || 'Ready'
        }
      />

      <SidebarModal.Content hasFooter={false}>
        <Box flexDirection="column">{renderContent(props, strings)}</Box>
      </SidebarModal.Content>
    </SidebarModal>
  )
}

BuildingAttachmentModal.propTypes = {
  building: PropTypes.bool,
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

BuildingAttachmentModal.defaultProps = {
  modalKey: 'BuildingAttachmentModal',
}

export default ContextWrappedModal
