import { useContext } from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, FormField, ModalContext, SidebarModal, SectionDivider,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useImage from '@hooks/useImage'
import useLocalization from '@hooks/useLocalization'
import useOrganizationsUser from '@hooks/useOrganizationsUser'
import useTeam, { useTeamForm } from '@hooks/useTeam'
import useUsers from '@hooks/useUsers'

import ModalImageUpload from '@components/ModalComponents/ModalImageUpload'

import defaultRequestOptions from '@sections/Client/packs/Admin/defaultRequestOptions'

import DraggableUsers from '@components/ModalComponents/DraggableUsers'

import localizedStrings from './localizedStrings'

const confirmDelete = (params) => {
  const { callbacks, deleteFn, strings } = params
  const { closeModal, deleteTeam } = callbacks

  const teamsPayload = {
    callbacks: {
      action: deleteFn,
      afterAction: closeModal,
    },
    toastText: strings.toast?.deleted || 'Team Deleted Successfully',
  }

  swal
    .fire({
      title: strings.sweetAlert?.title || 'Delete Team?',
      text: strings.sweetAlert?.text || 'Are you sure? ',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: strings.sweetAlert?.confirmButton || 'Yes, delete it.',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        deleteTeam(teamsPayload)
      }
    })
}

const createOrUpdateTeam = (params) => {
  const {
    addProjectUserOrTeam, createFn, createOrEditTeam, entityState, callbacks, strings, updateFn,
  } = params

  const { closeModal, createTeam, updateTeam } = callbacks

  const actionFn = entityState.id ? updateFn : createFn
  const toastText = entityState.id
    ? strings.toast?.updated || 'Team Updated Successfully'
    : strings.toast?.created || 'Team Created Successfully'

  const afterTeamCreateCallback = (data) => {
    // Launch edit modal for new Team
    createOrEditTeam({ team: data?.entity })

    if (typeof addProjectUserOrTeam === 'function'){
      // Automatically add the team to project entityState
      addProjectUserOrTeam({ id: data?.entity?.id, key: 'Team' })
    }
  }

  const afterActionCallback = data => (entityState.id ? closeModal() : afterTeamCreateCallback(data))

  const teamsPayload = {
    callbacks: {
      action: actionFn,
      afterAction: ({ response: { data } }) => afterActionCallback(data),
    },
    entityParams: entityState,
    requestOptions: defaultRequestOptions.team,
    shouldRedirect: true,
    toastText,
  }

  return entityState.id ? updateTeam(teamsPayload) : createTeam(teamsPayload)
}

const CreateOrEditTeamModal = (props) => {
  const {
    callbacks, disableAnimation, disableOverlay, modalKey, showModal,
  } = props

  const { closeModal, deleteTeam } = callbacks

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, `${modalKey}`, {})
  const { team, addProjectUserOrTeam } = modalPayload

  const {
    callbacks: {
      createOrEditTeam, createTeam: createFn, deleteTeam: deleteFn, updateTeam: updateFn,
    },
    creating,
    defaultImage,
    updating,
  } = useTeam(team)

  const {
    entityState,
    entityState: {
      description, lead_user_ids, owner_id, support_user_ids, title,
    },
    errors,
    handlers,
    saveEnabled,
    setEntityState,
  } = useTeamForm(team)

  const {
    callbacks: { deleteImage },
    deleting,
  } = useImage(defaultImage)

  const defaultImageUrl = digObject(defaultImage, 'sizes.thumb_660x360', '')

  const groupedUserIds = {
    lead_user_ids,
    support_user_ids,
  }

  const {
    callbacks: { modifyUserSort, toggleUsers },
  } = useUsers()

  const { currentUser, isCurrentUserAdminForOrganization } = useOrganizationsUser({ organization_id: owner_id })

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        disableAnimation={disableAnimation}
        disableOverlay={disableOverlay}
        title={team.id ? strings.updateTitle || 'Update' : strings.createTitle || 'Create'}
        titleSecondLine={strings.titleSecondLine}
      />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <FormField
            direction="column"
            boxProps={{ marginBottom: 'large' }}
            errorMessage={errors.title}
            label={strings.formFieldLabels?.title || '* Title'}
          >
            <input data-validate-field-on="change" name="title" type="text" value={title} {...handlers} />
          </FormField>

          {team.id && (
            <FormField
              boxProps={{ marginBottom: 'large' }}
              errorMessage={errors.description}
              label={strings.formFieldLabels?.description || 'Description'}
            >
              <textarea name="description" type="text" style={{ height: 120 }} value={description} {...handlers} />
            </FormField>
          )}

          {team.id && (
            <>
              <SectionDivider>{strings.teamMembers || 'TEAM MEMBERS'}</SectionDivider>

              {/* Drag n Drop */}
              <DraggableUsers
                callbacks={{
                  modifyUserSort: (fromIndex, toIndex, payload) => modifyUserSort(fromIndex, toIndex, payload, groupedUserIds, setEntityState),
                  toggleUsers: (userId, listName) => toggleUsers(userId, groupedUserIds, listName, setEntityState),
                }}
                disableDeleteForUserIds={!isCurrentUserAdminForOrganization ? [currentUser.id] : []}
                showOnFocus
                userIds={groupedUserIds}
              />
              {/* End Drag n Drop */}

              <SidebarModal.Separator />

              {/* Image Upload */}
              <ModalImageUpload
                imageLabel={strings.teamImageLabel || 'Team Image'}
                imageSizes={['thumb_90x90', 'thumb_660x360', 'thumb_200x120']}
                imageUrl={defaultImageUrl}
                ownerId={team.owner_id}
                ownerType="Organization"
                subjectId={team.id}
                subjectType="Team"
              />
              {/* End Image Upload */}

              {/* Options */}
              {(!!defaultImage.id || !!deleteTeam) && (
                <FormField
                  direction="column"
                  label={strings.formFieldLabels?.moreOptions || 'More Options'}
                  marginTop="large"
                >
                  {!!defaultImage.id && (
                    <ModalImageUpload.ReplaceImage loading={deleting} onClick={() => deleteImage()} />
                  )}

                  {!!deleteTeam && (
                    <Button
                      buttonStyle="secondaryUtility"
                      onClick={() => confirmDelete({ callbacks, deleteFn, strings })}
                      icon={<FontAwesomeIcon icon={faTrashAlt} />}
                      size="medium"
                      width="100%"
                    >
                      {strings.deleteTeam || 'Delete Team'}
                    </Button>
                  )}
                </FormField>
              )}
              {/* End Options */}
            </>
          )}
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="secondary"
          onClick={() => closeModal()}
          size="medium"
          style={{ marginRight: 4, width: 'calc(50% - 4px)' }}
        >
          {strings.cancelTeam || 'Cancel'}
        </Button>

        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={creating || updating}
          loadingBubbleColor="white"
          onClick={() => createOrUpdateTeam({
            addProjectUserOrTeam,
            callbacks,
            createFn,
            createOrEditTeam,
            entityState,
            strings,
            updateFn,
          })}
          size="medium"
          style={{ marginLeft: 4, width: 'calc(50% - 4px)' }}
        >
          {strings.saveTeam || 'Save'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

CreateOrEditTeamModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  disableAnimation: PropTypes.bool,
  disableOverlay: PropTypes.bool,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

CreateOrEditTeamModal.defaultProps = {
  modalKey: 'CreateOrEditTeamModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <CreateOrEditTeamModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
