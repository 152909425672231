export default {
  default: {
    buttons: {
      save: 'Save',
    },
    formFieldLabels: {
      value: 'Value',
    },
    title: 'Edit',
    titleSecondLine: 'Text',
  },
}
