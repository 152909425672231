import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, Image, ListItem, StatusBadge, Text,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import DigitalPagePlaceholderImage from '@components/DigitalPagePlaceholderImage'
import EngagementStatusBadge from '@components/EngagementStatusBadge'

import useDigitalPage from '@hooks/useDigitalPage'
import useDigitalPageTheme from '@hooks/useDigitalPageTheme'
import useDigitalTemplate from '@hooks/useDigitalTemplate'
import useEngagementStatus from '@hooks/useEngagementStatus'
import useLocalization from '@hooks/useLocalization'

import { statusColor } from '@functions/status'

import localizedStrings from './localizedStrings'

const LargeList = (props) => {
  const { digitalPage } = props
  const { total_views } = digitalPage

  const digitalPagePayload = useDigitalPage(digitalPage)
  const {
    digitalPage: { engagement_status },
    digitalTemplate,
    project: { title },
    status,
    urls: { editDigitalPageUrl },
  } = digitalPagePayload

  const { previewImage } = useDigitalTemplate(digitalTemplate)
  const previewImageUrl = digObject(previewImage, 'sizes.thumb_200x120', '')

  const { engagementStatusTheme } = useEngagementStatus(engagement_status)

  const { colors, digitalTemplateTypeKey } = useDigitalPageTheme(digitalPage)

  const {
    callbacks: { formatCount },
  } = useLocalization(localizedStrings)

  return (
    <ListItem
      boxProps={{ as: 'a', borderBottom: '1px solid', padding: 'large' }}
      href={editDigitalPageUrl}
      showAngleIcon
    >
      <Box width={110} marginRight="large">
        <Image borderRadius={5} height={62} marginRight="medium" url={previewImageUrl} width={100}>
          {!previewImageUrl && (
            <Box background={colors.background} borderRadius={5} height={62}>
              <DigitalPagePlaceholderImage
                color={colors.solid}
                height={62}
                digitalTemplateTypeKey={digitalTemplateTypeKey}
                width={100}
              />
            </Box>
          )}
        </Image>
      </Box>

      <Box justifyContent="center" flexDirection="column">
        <Text fontSize="small">{title}</Text>

        <Text color="bodyFontLightColor" fontSize="xsmall" marginY="medium">
          {digitalTemplate.title}
        </Text>

        <StatusBadge color={statusColor(status.key)} ghost text={status.title} />
      </Box>

      <Box justifyContent="center" flexDirection="column" width={82}>
        <EngagementStatusBadge engagementStatusTheme={engagementStatusTheme} />

        <Text
          color="bodyFontLightColor"
          fontSize="xsmall"
          marginTop="medium"
          style={{ whiteSpace: 'nowrap' }}
          textAlign="center"
        >
          {formatCount('views', { count: total_views })}
        </Text>
      </Box>
    </ListItem>
  )
}

LargeList.propTypes = {
  digitalPage: PropTypes.object.isRequired,
}

export default LargeList
