const defaultRequestOptions = {
  eventCalendar: {
    include_event_calendar_event_groups: true,
    include_event_calendar_events: true,
  },
  eventCalendarTemplate: {
    include_event_calendar_event_count: true,
    include_event_calendar_event_group_count: true,
    include_event_calendar_event_groups: true,
    include_event_calendar_events: true,
  },
}

export default defaultRequestOptions
