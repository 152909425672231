export default {
  default: {
    navigationItems: {
      accountAdmin: 'Account Admin',
      agreements: 'Agreements',
      analytics: 'Analytics',
      contacts: 'Contacts',
      dashboard: 'Dashboard',
      forms: 'Forms',
      generateSupportToken: 'Generate Token',
      pages: 'Pages',
      project: 'Projects',
      proposals: 'Proposals',
      templates: 'Templates',
      training: 'Training',
    },
  },
}
