export default {
  default: {
    buttons: {
      save: 'Save',
    },
    disabledText: 'User Assigned',
    formFieldLabels: {
      searchUsers: 'Search Users and Contacts',
    },
  },
}
