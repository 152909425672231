export default {
  default: {
    subtitles: {
      mobile: 'Custom Mobile',
      email: 'Custom Email',
      user: {
        allEnabled: 'Email & SMS',
        emailOnly: 'Email Only',
        smsOnly: 'SMS Only',
      },
    },
  },
}
