const defaultRequestOptions = {
  assetLibrary: {
    include_asset_library_attachments: true,
    include_asset_library_default_image: true,
    include_asset_library_images: true,
    include_asset_library_owner_title: true,
    include_attachment_file: true,
  },
  attachment: {
    include_attachment_file: true,
  },
  award: {
    include_award_data_store_items: true,
    include_award_default_image: true,
    include_award_image_sizes: [
      'thumb_90x90',
      'thumb_120x80',
      'thumb_640x360',
      'thumb_660x360',
      'thumb_1200x627',
      'thumb_1280x720',
      'thumb_2000x1500',
      'thumb_3500x2500',
    ],
  },
  caseStudy: {
    include_case_study_address: true,
    include_case_study_data_store_items: true,
    include_case_study_default_image: true,
    include_case_study_images: true,
    include_case_study_subject: true,
    include_case_study_user_ids: true,
    include_project_address: true,
  },
  customFieldSet: {
    include_custom_field_set_fields: true,
  },
  eventCalendarTemplate: {
    include_event_calendar_event_count: true,
    include_event_calendar_event_group_count: true,
  },
  formTemplate: {
    include_form_template_fields: true,
  },
  image: {
    include_image_sizes: [
      'thumb_90x90',
      'thumb_200x120',
      'thumb_660x360',
      'thumb_1280x720',
      'thumb_2000x1500',
      'thumb_3500x2500',
    ],
  },
  integrationPlatform: {
    include_integration_platform_image: true,
  },
  organization: {
    include_organization_data_store_items: true,
    include_organization_default_image: true,
    include_organization_images: true,
    include_organization_sorted_user_ids: true,
  },
  project: {
    include_project_address: true,
    include_project_lead_users: true,
  },
  review: {
    include_review_address: true,
    include_review_user_ids: true,
  },
  targetAudience: {
    include_target_audience_image: true,
  },
  team: {
    include_team_default_image: true,
    include_team_lead_users: true,
    include_team_member_user: true,
    include_team_support_users: true,
    include_team_team_members: true,
  },
  user: {
    include_user_awards: true,
    include_user_default_image: true,
    include_user_organization_ids: true,
    include_user_organizations_where_admin_ids: true,
    include_user_organizations_where_owner_ids: true,
    include_user_role: true,
  },
}

export default defaultRequestOptions
