import useDispatch from '@hooks/useDispatch'
import useOrganizationSelector from '@hooks/useOrganizationSelector'
import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import * as organizationActions from '@redux/modules/organization'

import type { AppDispatch } from '@redux/store'
import type { OrganizationModel, OrganizationRequestOptions } from '@models/types'

type LoadOrganizationShortcodeListParams = {
  dispatch: AppDispatch,
  organization: OrganizationModel,
  requestOptions?: OrganizationRequestOptions,
}

const loadOrganizationShortcodeList = (params: LoadOrganizationShortcodeListParams) => {
  const { dispatch, organization, requestOptions } = params
  const { loadShortcodeList: loadFn } = organizationActions

  return dispatch(loadFn(organization, requestOptions))
}

type UseOrganizationShortcodeListOptions = {
  organizationId?: number,
  performHttpRequests?: boolean,
}

function useOrganizationShortcodeList(options: UseOrganizationShortcodeListOptions = {}) {
  const { organizationId: suppliedOrganizationId, performHttpRequests } = options

  const dispatch = useDispatch()

  const { selectedOrganization } = useOrganizationSelector()

  const organizationId = suppliedOrganizationId || selectedOrganization.id

  const entities = useSelector(reduxState => reduxState.entities)
  const { organizationShortcodeLists } = entities

  const organizationShortcodeList = organizationShortcodeLists[organizationId] || {}

  useReduxAction('organizations', 'loadShortcodeList', {}, [organizationId], {
    dispatchAction: (action, requestOptions) => action({ id: organizationId }, requestOptions),
    shouldPerformFn: (entityReducer) => {
      const { loadedShortcodeListIds } = entityReducer
      return performHttpRequests && organizationId && !loadedShortcodeListIds.includes(organizationId)
    },
  })

  return {
    callbacks: {
      loadOrganizationShortcodeList: (
        requestOptions: OrganizationRequestOptions,
      ) => loadOrganizationShortcodeList({ dispatch, organization: selectedOrganization, requestOptions }),
    },
    organizationShortcodeList,
  }
}

export default useOrganizationShortcodeList
