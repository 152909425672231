import React, { useContext, useRef } from 'react'
import PropTypes from 'prop-types'

import useDocumentRecipient from '@hooks/useDocumentRecipient'
import useDocumentTemplatePageItem, { useRelations } from '@hooks/useDocumentTemplatePageItem'

import BuilderContext from '../../../contexts/builderContext'

import useDraggablePageItem from '../../../hooks/useDraggablePageItem'

const anchorSize = 6

const PageItem = (props) => {
  const {
    documentTemplatePageItem,
    zoom,
  } = props

  const builderContext = useContext(BuilderContext)
  const { callbacks: { selectTemplatePageItemId }, selectedTemplatePageItemId } = builderContext

  const selected = selectedTemplatePageItemId === documentTemplatePageItem.id

  // Document Template Page Item
  const documentTemplatePageItemPayload = useDocumentTemplatePageItem(documentTemplatePageItem)
  const {
    callbacks: {
      updateDocumentTemplatePageItem,
    },
    documentTemplatePageItem: {
      id,
      label,
    },
  } = documentTemplatePageItemPayload

  const { documentRecipient } = useRelations(documentTemplatePageItem)

  // Recipient
  const documentRecipientPayload = useDocumentRecipient(documentRecipient)
  const { recipientColor } = documentRecipientPayload

  // Draggable
  const draggableRef = useRef()
  const draggablePayload = useDraggablePageItem(draggableRef, {
    callbacks: {
      updateDocumentTemplatePageItem,
    },
    documentTemplatePageItem,
    selected,
    zoom,
  })

  const {
    height, width, x, y,
  } = draggablePayload

  const anchorDisplay = selected ? 'block' : 'none'
  const fill = recipientColor
  const opacity = selected ? '0.35' : '0.08'

  return (
    <g
      onClick={() => selectTemplatePageItemId(id)}
      ref={draggableRef}
      style={{
        boxSizing: 'border-box',
      }}
    >
      {/* Item Box */}
      <rect
        fill={fill}
        fillOpacity={opacity}
        height={height}
        stroke={recipientColor}
        style={{ fillOpacity: opacity, boxSizing: 'border-box' }}
        width={width}
        x={x}
        y={y}
      />
      {/* End Item Box */}

      {/* Label */}
      <text
        style={{
          textAnchor: 'start',
          fontSize: 10,
          fontFamily: 'sans-serif',
          opacity: 0.5,
        }}
        textAnchor="start"
        x={x + 2 * zoom}
        y={y + 7 + 2 * zoom}
      >
        <tspan>{label}</tspan>
      </text>
      {/* End Label */}

      {/* Anchors - TopLeft, TopRight, BottomLeft, BottomRight */}
      <rect
        fill={fill}
        height={anchorSize}
        style={{ display: anchorDisplay, padding: 0, cursor: 'nwse-resize' }}
        width={anchorSize}
        x={x - anchorSize}
        y={y - anchorSize}
      />
      <rect
        className="dragHandle"
        fill={fill}
        height={anchorSize}
        style={{ display: anchorDisplay, padding: 0, cursor: 'nesw-resize' }}
        width={anchorSize}
        x={x + width}
        y={y - anchorSize}
      />
      <rect
        className="dragHandle"
        fill={fill}
        height={anchorSize}
        style={{ display: anchorDisplay, padding: 0, cursor: 'nesw-resize' }}
        width={anchorSize}
        x={x - anchorSize}
        y={y + height}
      />
      <rect
        className="dragHandle"
        fill={fill}
        height={anchorSize}
        style={{ display: anchorDisplay, padding: 0, cursor: 'nwse-resize' }}
        width={anchorSize}
        x={x + width}
        y={y + height}
      />
      {/* End Anchors */}
    </g>
  )
}

PageItem.propTypes = {
  documentTemplatePageItem: PropTypes.object.isRequired,
  zoom: PropTypes.number,
}

export default PageItem
