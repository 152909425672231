import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import cloneDeep from 'lodash/cloneDeep'

import { digObject } from '@campaignhub/javascript-utils'
import { useForm, useSetState } from '@campaignhub/react-hooks'

import { findItemByType } from '@functions/digitalTemplate'
import generateID from '@functions/generateID'

import useFormTemplate from '@hooks/useFormTemplate'

const findContainerItemTypes = (params) => {
  const { digitalTemplatePageItems, fieldContainer } = params

  const fieldContainerItemIds = digObject(fieldContainer, 'items', [])

  const labelItem = findItemByType('text', fieldContainerItemIds, digitalTemplatePageItems, {
    matchAttribute: { key: 'data.value', value: '{{label}}' },
  })

  const hintItem = findItemByType('text', fieldContainerItemIds, digitalTemplatePageItems, {
    matchAttribute: { key: 'data.value', value: '{{hint}}' },
  })

  const inputItem = findItemByType('input', fieldContainerItemIds, digitalTemplatePageItems)

  return {
    hintItem,
    inputItem,
    labelItem,
  }
}

const setupFieldItems = (params) => {
  const {
    digitalTemplatePageItems, fieldContainer, filteredFields,
  } = params

  const { hintItem, inputItem, labelItem } = findContainerItemTypes({ digitalTemplatePageItems, fieldContainer })

  const items = filteredFields.reduce((acc, customField, index) => {
    const {
      field_type, hint, id, input_type, key, label, placeholder,
    } = customField

    const clonedContainer = cloneDeep(fieldContainer)
    const container = {
      ...clonedContainer,
      id: `${generateID()}-${index}`,
      items: [],
    }

    // Label
    if (label && labelItem){
      container.items.push({
        ...cloneDeep(labelItem),
        id: `label-${generateID()}`,
        data: {
          value: label,
        },
      })
    }

    // Hint
    if (hint && hintItem){
      container.items.push({
        ...cloneDeep(hintItem),
        id: `hint-${generateID()}`,
        data: {
          value: hint,
        },
      })
    }

    // Input
    if (inputItem){
      const clonedInput = cloneDeep(inputItem)

      container.items.push({
        ...clonedInput,
        id: `input-${generateID()}`,
        item_type: 'formInput',
        options: {
          ...clonedInput.options,
          customFieldId: id,
          inputComponent: field_type,
          inputKey: key,
          inputPlaceholder: placeholder,
          inputType: input_type,
        },
      })
    }

    acc.push(container)

    return acc
  }, [])

  return items
}

const setupFormDefaultState = (filteredFields) => {
  const state = filteredFields.reduce((acc, field) => {
    const { key } = field
    acc[key] = ''
    return acc
  }, {})

  return state
}

const setupFormRequiredFields = (filteredFields) => {
  const requiredFields = filteredFields.reduce((acc, field) => {
    const { key, required } = field

    if (required){
      acc.push({
        key,
      })
    }

    return acc
  }, [])

  return requiredFields
}

const updateFormTemplatePageItem = (params) => {
  const {
    buttonItem,
    formTemplatePageItem,
    items,
  } = params

  const updatedItem = {
    ...cloneDeep(formTemplatePageItem),
    items: [
      ...items,
      buttonItem,
    ],
  }

  return updatedItem
}

const defaultState = {
  errors: [],
  loading: false,
  success: true,
}

function useDigitalPageForm(formTemplateId, options = {}){
  const { digitalTemplatePageItemId } = options || {}

  const [state, setState] = useSetState(defaultState)
  const { errors, loading, success } = state

  const entities = useSelector(reduxState => reduxState.entities)
  const { formTemplates, digitalTemplatePageItems } = entities

  const formTemplate = formTemplates[formTemplateId] || {}
  const formTemplatePageItem = digitalTemplatePageItems[digitalTemplatePageItemId] || {}

  const formTemplatePayload = useFormTemplate(formTemplate)
  const { filteredFields } = formTemplatePayload

  // Setup Form Default State
  const formDefaultState = useMemo(() => setupFormDefaultState(filteredFields), [formTemplate.id])
  const formRequiredFields = useMemo(() => setupFormRequiredFields(filteredFields), [formTemplate.id])

  const digitalPageForm = useForm(
    formDefaultState,
    { entity: {}, requiredFields: formRequiredFields, validateOn: 'change' },
    [formTemplate.id],
  )

  // Find Form Components
  // e.g. Label, Hint and Input
  const formTemplateItemIds = digObject(formTemplatePageItem, 'items', [])

  const fieldContainer = findItemByType('container', formTemplateItemIds, digitalTemplatePageItems) || {}

  const buttonItem = findItemByType('button', formTemplateItemIds, digitalTemplatePageItems) || {}

  // Setup New Items using Fields
  const items = useMemo(() => {
    const fieldItems = setupFieldItems({
      buttonItem,
      digitalTemplatePageItems,
      fieldContainer,
      filteredFields,
    })

    return fieldItems
  }, [formTemplate.id])

  // Replace FormTemplate items with new ones
  const updatedFormTemplatePageItem = useMemo(() => {
    const updatedPageItem = updateFormTemplatePageItem({
      buttonItem,
      formTemplatePageItem,
      items,
    })

    return updatedPageItem
  }, [formTemplate.id])

  return {
    buttonItem,
    callbacks: {
      setState,
    },
    digitalPageForm,
    errors,
    fieldContainer,
    formTemplate,
    formTemplatePageItem: updatedFormTemplatePageItem,
    hasErrors: !!errors.length,
    items,
    loading,
    success,
  }
}

export default useDigitalPageForm
