export default {
  default: {
    buttonText: {
      delete: 'Delete Image',
      download: 'Download',
      makeDefaultImage: 'Make Default Image',
      save: 'Save',
    },
    defaultImageToast: {
      updateFailed: 'Update Failed',
      updateSuccess: 'Default Image Updated Successfully',
    },
    formFieldLabels: {
      title: 'Library',
      fileName: 'File Name',
      fileSize: 'File Size',
      moreOptions: 'More Options',
    },
    modalHeader: {
      title: 'Edit',
      titleSecondLine: 'Image',
    },
    swal: {
      title: 'Delete Image?',
      text: 'Are you sure?',
      confirmButtonText: 'Yes, delete it.',
    },
    toast: {
      deleteImage: 'Image Deleted Successfully',
    },
  },
}
