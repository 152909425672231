import React from 'react'
import PropTypes from 'prop-types'
import { Duration } from 'luxon'

import { digObject } from '@campaignhub/javascript-utils'

import { Box, Text } from '@campaignhub/suit-theme'

import useChartData from '@hooks/useChartData'
import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const TimeOnPage = (props) => {
  const { chartDataKey, selectedDataPoint } = props

  const chartDataPayload = useChartData('benchmark_comparison', {
    chartDataKey,
    metricKey: 'average_proposal_seconds_on_page',
    performHttpRequests: false,
  })
  const { latestDataForMetricKey } = chartDataPayload

  const timeOnPageForDataPoint = digObject(latestDataForMetricKey, selectedDataPoint, 0)

  const {
    values: { minutes, seconds },
  } = Duration.fromObject({ seconds: timeOnPageForDataPoint }).shiftTo('minutes', 'seconds')

  const {
    callbacks: { formatNumber },
    strings,
  } = useLocalization(localizedStrings)

  return (
    <Box alignItems="center" justifyContent="space-between" marginTop="large">
      <Text color="bodyFontLightColor" fontSize="small">
        {strings.timeOnPage?.title || 'Average Time on Page'}
      </Text>

      <Text fontSize="xxxlarge" color="#00BEAB">
        {minutes > 0
          ? `${formatNumber(minutes, '.0f')}m ${formatNumber(seconds, '.0f')}s`
          : `${formatNumber(seconds, '.0f')}s`}
      </Text>
    </Box>
  )
}

TimeOnPage.propTypes = {
  chartDataKey: PropTypes.string.isRequired,
  selectedDataPoint: PropTypes.string.isRequired,
}

export default TimeOnPage
