export default {
  default: {
    agreementTab: 'Agreements',
    allTab: 'All',
    formTab: 'Forms',
    micrositeTab: 'Homepages',
    proposalTab: 'Proposals',
    title: '{global.digitalTemplateType.title.plural}',
    titleAll: 'All Pages',
    createText: 'Create',
    filters: {
      defaultSelectLabel: 'All',
      filterByTitle: 'Filter by Title',
      filterTitle: 'Filters',
      filterType: 'Page Type',
      limit: 'Limit',
    },
  },
}
