export default {
  default: {
    button: {
      save: 'Save',
    },
    sideBarModalHeader: {
      titleCreate: 'Create',
      subtitleDocumentTemplate: 'Document Template',
    },
  },
}
