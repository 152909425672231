import React from 'react'
import PropTypes from 'prop-types'
import isMobileBrowser from 'is-mobile'

import { DynamicInput, FormField } from '@campaignhub/suit-theme'

import useCustomField from '@hooks/useCustomField'

const isMobile = isMobileBrowser()

const mobileSpans = {
  25: 1,
  50: 1,
  75: 2,
  100: 2,
}

const desktopSpans = {
  25: 1,
  50: 2,
  75: 3,
  100: 4,
}

const convertWidthToGridSpan = (fieldWidth) => {
  if (isMobile){
    return mobileSpans[fieldWidth] || 2
  }

  return desktopSpans[fieldWidth] || 4
}

const Field = (props) => {
  const {
    customField,
    customFieldsForm: { entityState, handlers },
  } = props

  const customFieldPayload = useCustomField(customField)
  const {
    customField: {
      field_type,
      key,
      label,
      options: {
        input_type,
      },
    },
    fieldWidth,
    optionValues,
  } = customFieldPayload

  return (
    <FormField
      boxProps={{ marginBottom: 'medium' }}
      label={label}
      style={{ gridColumnEnd: `span ${convertWidthToGridSpan(fieldWidth)}` }}
    >
      <DynamicInput
        style={{ height: field_type === 'textarea' ? 80 : null }}
        currentValue={entityState[key] || ''}
        inputComponent={field_type}
        inputType={input_type}
        name={key}
        values={optionValues.map(item => ({ key: item.key, label: item.value }))}
        {...handlers}
      />
    </FormField>
  )
}

Field.propTypes = {
  customField: PropTypes.object.isRequired,
  customFieldsForm: PropTypes.shape({
    entityState: PropTypes.object.isRequired,
    handlers: PropTypes.shape({}),
  }).isRequired,
}

export default Field
