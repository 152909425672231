import { useEffect } from 'react'
import PropTypes from 'prop-types'

import { faUser, faBuilding } from '@fortawesome/pro-light-svg-icons'

import { useFilterString, useSetState } from '@campaignhub/react-hooks'
import {
  Button,
  FormField,
  LoadingModule,
  SegmentedControl,
  SidebarModal,
  SidebarNoticeBox,
} from '@campaignhub/suit-theme'

import useQuoteTemplates from '@hooks/useQuoteTemplates'
import useLocalization from '@hooks/useLocalization'
import useOrganizationsUser from '@hooks/useOrganizationsUser'

import QuoteTemplate from './QuoteTemplate'
import localizedStrings from './localizedStrings'

const getFilteredTemplates = (params) => {
  const {
    currentUserQuoteTemplates,
    filterKey,
    filteredQuoteTemplates,
    isCurrentUserAdminForOrganization,
    nonUserQuoteTemplates,
  } = params

  if (filterKey === 'organization' || (filterKey === 'all' && !isCurrentUserAdminForOrganization)){
    return nonUserQuoteTemplates
  }

  if (filterKey === 'user') return currentUserQuoteTemplates

  return filteredQuoteTemplates
}

const defaultState = {
  filterKey: 'all',
}

const CreateFromTemplate = (props) => {
  const [state, setState] = useSetState(defaultState)
  const { filterKey } = state

  const {
    callbacks: { closeModal, createQuoteFromTemplate, selectScreen },
    quotePayload: {
      callbacks: { createQuoteFromTemplate: createFn },
      quote,
    },
  } = props

  const { onChange, value } = useFilterString()

  const quoteTemplatesPayload = useQuoteTemplates({
    filters: {
      string: value,
    },
    performHttpRequests: true,
  })

  const { isCurrentUserAdminForOrganization } = useOrganizationsUser()

  const {
    currentUserQuoteTemplates, filteredQuoteTemplates, hasQuoteTemplates, loading, nonUserQuoteTemplates,
  } = quoteTemplatesPayload

  const filteredTemplates = getFilteredTemplates({
    currentUserQuoteTemplates,
    filterKey,
    filteredQuoteTemplates,
    isCurrentUserAdminForOrganization,
    nonUserQuoteTemplates,
  })

  useEffect(() => {
    if (!!currentUserQuoteTemplates.length && !isCurrentUserAdminForOrganization){
      setState({ filterKey: 'user' })
    }
  }, [currentUserQuoteTemplates.length, isCurrentUserAdminForOrganization])

  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      <SidebarModal.Header
        title={strings.title || 'Create'}
        titleSecondLine={strings.titleSecondLine || 'From Template'}
      />

      <SidebarModal.Content>
        <LoadingModule loading={loading} times={3} />

        {!!currentUserQuoteTemplates.length && !isCurrentUserAdminForOrganization && (
          <SegmentedControl
            activeButtonKey={filterKey}
            boxProps={{ marginBottom: 'large' }}
            buttons={[
              { key: 'user', icon: faUser, label: 'My Templates' },
              { key: 'organization', icon: faBuilding, label: 'Agency Templates' },
            ]}
            onClick={key => setState({ filterKey: key })}
          />
        )}

        {!loading && (
          <>
            <FormField
              boxProps={{ marginBottom: 'large' }}
              direction="column"
              label={strings.filterTemplates || 'Filter Templates'}
            >
              <input onChange={onChange} value={value} />
            </FormField>

            <FormField direction="column" label={strings.savedTemplates || 'Saved Templates'}>
              {filteredTemplates
                && filteredTemplates.map((quoteTemplate) => {
                  const updatedQuote = {
                    ...quote,
                    title: quoteTemplate.title || quote.title,
                  }

                  const createQuoteFromTemplatePayload = {
                    callbacks: {
                      action: createFn,
                      afterAction: closeModal,
                    },
                    entityParams: { quote: updatedQuote, quoteTemplate },
                    shouldRedirect: true,
                    toastText: strings.toast?.quoteCreated || 'Quote Created Successfully',
                  }

                  return (
                    <QuoteTemplate
                      key={quoteTemplate.id}
                      callbacks={{ selectTemplate: () => createQuoteFromTemplate(createQuoteFromTemplatePayload) }}
                      quoteTemplate={quoteTemplate}
                    />
                  )
                })}
            </FormField>
          </>
        )}

        {!hasQuoteTemplates && (
          <SidebarNoticeBox boxProps={{ marginBottom: 'medium' }}>
            <SidebarNoticeBox.Title>{strings.noticeBox?.title || 'No Saved Templates'}</SidebarNoticeBox.Title>

            <SidebarNoticeBox.Paragraph>
              {strings.noticeBox?.paragraph || 'Create a new quote then save it as a template.'}
            </SidebarNoticeBox.Paragraph>
          </SidebarNoticeBox>
        )}
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button buttonStyle="secondaryUtility" onClick={() => selectScreen('')} size="large" width="100%">
          {strings.backButton || 'Go Back'}
        </Button>
      </SidebarModal.Footer>
    </>
  )
}

CreateFromTemplate.propTypes = {
  callbacks: PropTypes.object.isRequired,
  quotePayload: PropTypes.shape({
    callbacks: PropTypes.shape({
      createQuoteFromTemplate: PropTypes.func.isRequired,
    }).isRequired,
    quote: PropTypes.object,
  }).isRequired,
}

export default CreateFromTemplate
