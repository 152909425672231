import React from 'react'
import PropTypes from 'prop-types'

import { Button, PageHeader, PageZoomControl } from '@campaignhub/suit-theme'

import { getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'

const Header = (props) => {
  const {
    documentTemplateBuilderPayload: {
      availableZooms,
      callbacks: { performZoomAction, selectZoomValue, zoomToBestFit },
      documentTemplatePayload: {
        hasPages,
      },
      isMobile,
      zoom,
    },
  } = props

  const { redirect } = parsePermittedQueryParams(getQueryParamsFromHash(), ['redirect'])

  return (
    <PageHeader
      boxProps={{ justifyContent: 'flex-start' }}
      offset={{ left: 0, top: 0 }}
      width={!isMobile ? 'calc(100% - 375px)' : '100%'}
      title="Document Template"
      actionContent={(
        <>
          {hasPages && (
            <PageZoomControl
              availableZooms={availableZooms}
              callbacks={{
                performZoomAction,
                selectZoomValue,
                zoomToBestFit,
              }}
              selectedZoom={zoom}
              showBestFitOption
            />
          )}

          <Button
            as="a"
            href={redirect || '/'}
            buttonStyle="primaryCreate"
            marginLeft="small"
            size="medium"
            width="auto"
          >
            Go Back
          </Button>
        </>
      )}
    />
  )
}

Header.propTypes = {
  documentTemplateBuilderPayload: PropTypes.shape({
    availableZooms: PropTypes.array.isRequired,
    callbacks: PropTypes.shape({
      performZoomAction: PropTypes.func.isRequired,
      selectZoomValue: PropTypes.func.isRequired,
      zoomToBestFit: PropTypes.func.isRequired,
    }).isRequired,
    documentTemplatePayload: PropTypes.shape({
      hasPages: PropTypes.bool,
    }).isRequired,
    isMobile: PropTypes.bool,
    zoom: PropTypes.number,
  }).isRequired,
}

export default Header
