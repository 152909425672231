export default {
  default: {
    gridView: 'Grid View',
    listView: 'List View',
    sideBarNoticeBox: {
      title: 'Change Image Sequence',
      // eslint-disable-next-line max-len
      description: 'Drag and drop images in the order of your choice to resequence them. Top 20 images will show on the proposal.',
    },
  },
}
