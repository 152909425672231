import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-light-svg-icons'

import { Box, Button, FormField } from '@campaignhub/suit-theme'

import { useSetState } from '@campaignhub/react-hooks'

import useCustomMobile from '@hooks/useCustomMobile'
import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const addCustomMobile = (entityState, props, setState) => {
  const { callbacks: { addCustomMobile: addCustomMobileFn } } = props

  addCustomMobileFn(entityState)
  setState({ showCustomMobileInputs: false })
}

const defaultState = {
  showCustomMobileInputs: false,
}

const CustomMobile = (props) => {
  const { boxProps, disabled } = props

  const [state, setState] = useSetState(defaultState)
  const { showCustomMobileInputs } = state

  const {
    countryCallingCodes,
    entityState,
    entityState: { countryCallingCode, mobile },
    errors,
    handlers,
    saveEnabled,
    setEntityState,
  } = useCustomMobile()

  const { strings } = useLocalization(localizedStrings)

  return (
    <Box {...boxProps}>
      {!showCustomMobileInputs && (
      <Button
        buttonStyle="ghostEdit"
        disabled={disabled}
        icon={<FontAwesomeIcon icon={faPlus} />}
        onClick={() => setState({ showCustomMobileInputs: !showCustomMobileInputs })}
        size="medium"
        width="100%"
      >
        {strings.buttons?.customMobile || 'Add Custom Mobile'}
      </Button>
      )}

      {showCustomMobileInputs && (
        <FormField fontSize="small">
          <Box flexDirection="row" alignItems="center">
            <Box style={{ width: '30%', marginRight: 8 }}>
              <select
                onChange={e => setEntityState({ countryCallingCode: e.target.value })}
                value={countryCallingCode}
              >
                <option value="">{strings.select?.option || 'Select...'}</option>
                {countryCallingCodes.map(code => (
                  <option key={code.id} value={code.value}>
                    {code.title}
                  </option>
                ))}
              </select>
            </Box>

            <FormField style={{ width: '50%' }} errorMessage={errors.mobile}>
              <input name="mobile" value={mobile} {...handlers} />
            </FormField>

            <Button
              buttonStyle="primaryEdit"
              disabled={!saveEnabled}
              marginLeft="medium"
              onClick={() => addCustomMobile(entityState, props, setState)}
              size="medium"
              width="20%"
            >
              {strings.buttons?.add || 'Add'}
            </Button>
          </Box>
        </FormField>
      )}
    </Box>
  )
}

CustomMobile.propTypes = {
  boxProps: PropTypes.object,
  callbacks: PropTypes.shape({
    addCustomMobile: PropTypes.func,
  }).isRequired,
  disabled: PropTypes.bool,
}

export default CustomMobile
