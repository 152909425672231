import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { toast } from 'react-toastify'

import PageContext from '@contexts/pageContext'

import { MainContent, ModalContext, TopBar } from '@campaignhub/suit-theme'

import { useModals, useSetState } from '@campaignhub/react-hooks'

import DuplicateDigitalTemplateModal from '@modals/DuplicateDigitalTemplateModal'
import ManageEntityUserPermissionsModal from '@modals/ManageEntityUserPermissionsModal'

import MainNavigation from '@sections/Client/components/MainNavigation'
import DashboardSearch from '@components/DashboardSearch'
import LoggedInUser from '@components/LoggedInUser'

import useLocalization from '@hooks/useLocalization'
import useMainNavigation from '@hooks/useMainNavigation'

import handleCallbackAction from '@functions/handleCallbackAction'

import localizedStrings from './localizedStrings'
import PageContent from './components/PageContent'

import defaultRequestOptions from '../TemplateCustomizer/defaultRequestOptions'

const forkDigitalTemplate = (originalTemplate, owner, title, forkFn) => {
  forkFn(originalTemplate, owner, title, defaultRequestOptions.digitalTemplate).then(
    ({ success, errors, redirectUrl }) => {
      if (!success){
        toast.warn(errors[0])
        return
      }
      if (redirectUrl){
        window.location.href = redirectUrl
      }
    },
  )
}

const callbacks = (component, setState) => {
  const componentCallbacks = {
    DuplicateDigitalTemplateModal: {
      closeModal: () => setState({ showDuplicateDigitalTemplateModal: false }),
      forkDigitalTemplate: (originalTemplate, owner, title, forkFn) => forkDigitalTemplate(originalTemplate, owner, title, forkFn, setState),
    },
    ManageEntityUserPermissionsModal: {
      updateEntityUserPermissions: payload => handleCallbackAction(payload),
      closeModal: () => setState({ showManageEntityUserPermissionsModal: false }),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  pageFilters: {},
  showDuplicateDigitalTemplateModal: false,
  showManageEntityUserPermissionsModal: false,
}

const TemplateList = () => {
  const {
    callbacks: { toggleNavigation },
  } = useMainNavigation()

  useLocation()

  const [state, setState] = useSetState(defaultState)
  const { pageFilters, showDuplicateDigitalTemplateModal, showManageEntityUserPermissionsModal } = state

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const pageContext = useMemo(
    () => ({
      callbacks: {
        resetFilters: (resetFn) => {
          if (resetFn) resetFn()
          setState({ pageFilters: {} })
        },
        showDuplicateDigitalTemplateModal: () => {
          setState({ showDuplicateDigitalTemplateModal: true })
        },
        showManageEntityUserPermissionsModal: (payload) => {
          setModalData('ManageEntityUserPermissionsModal', payload)
          setState({ showManageEntityUserPermissionsModal: true })
        },
        updateFilters: (filters) => {
          setState({ pageFilters: filters })
        },
      },
    }),
    [],
  )

  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      <Helmet>
        <title>{`${strings.title || 'Digital Templates'} | Engage`}</title>
      </Helmet>

      <PageContext.Provider value={pageContext}>
        <ModalContext.Provider value={modalContext}>
          <TopBar
            callbacks={{ toggleNavigation }}
            loggedInUserComponent={<LoggedInUser />}
            searchComponent={<DashboardSearch searchEntities={['Project']} />}
          />

          <MainNavigation />

          <MainContent offset={{ top: TopBar.topBarHeight }}>
            <PageContent pageFilters={pageFilters} />
          </MainContent>

          <DuplicateDigitalTemplateModal
            callbacks={callbacks('DuplicateDigitalTemplateModal', setState)}
            showModal={showDuplicateDigitalTemplateModal}
          />

          <ManageEntityUserPermissionsModal
            callbacks={callbacks('ManageEntityUserPermissionsModal', setState)}
            showModal={showManageEntityUserPermissionsModal}
          />
        </ModalContext.Provider>
      </PageContext.Provider>
    </>
  )
}

export default TemplateList
