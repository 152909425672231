import { useMemo } from 'react'
import { DateTime } from 'luxon'

import {
  digObject, matchFilterArrayIncludes, matchFilterNumber, matchFilterString, sortArrayBy,
} from '@campaignhub/javascript-utils'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useSelector from '@hooks/useSelector'

import type { ProjectModel } from '@models/types'
import sortEntitiesByDate from '@functions/sortEntitiesByDate'

const watchEntityKeys = ['projects']

const matchFilterStartDate = (date: string, filterDate: string) => {
  if (!!date && !!filterDate) return date >= filterDate

  return false
}

const matchFilterEndDate = (date: string, filterDate: string) => {
  if (!!date && !!filterDate) return date <= filterDate

  return false
}

type ProjectFilters = {
  created_date_end?: string,
  created_date_start?: string,
  end_date?: string,
  organization_id?: number,
  project_type_id?: number,
  start_date?: string,
  status?: string,
  string?: string,
  user_id?: number,
}

type UseProjectsOptions = {
  filters?: ProjectFilters,
  limit?: number,
}

function useProjects(options: UseProjectsOptions = {}) {
  const { filters = {} } = options
  const {
    created_date_end: filterCreateEndDateString,
    created_date_start: filterCreateStartDateString,
    end_date: filterEndDateString,
    organization_id: filterOrganizationId,
    project_type_id: filterProjectTypeId,
    start_date: filterStartDateString,
    status: filterStatus,
    string: filterString,
    user_id: filterUserId,
  } = filters

  const {
    updatedEntities: { projects: projectsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { projects } = useSelector(reduxState => reduxState.entities)

  const filterEndDate = filterEndDateString ? DateTime.fromFormat(filterEndDateString, 'yyyy-MM-dd') : null
  const filterStartDate = filterStartDateString ? DateTime.fromFormat(filterStartDateString, 'yyyy-MM-dd') : null

  const filterCreateEndDate = filterCreateEndDateString
    ? DateTime.fromFormat(filterCreateEndDateString, 'yyyy-MM-dd')
    : null
  const filterCreateStartDate = filterCreateStartDateString
    ? DateTime.fromFormat(filterCreateStartDateString, 'yyyy-MM-dd')
    : null

  const filteredProjects = useMemo(() => {
    const filtered = Object.values(projects).filter((project: ProjectModel) => {
      const {
        combined_user_ids, hidden, organization_id, project_type_id, dates, title,
      } = project

      const startDateString = digObject(dates, 'start.date_time_with_timezone')
      const startDate = startDateString ? DateTime.fromISO(startDateString) : null

      const createdDateString = digObject(dates, 'created.date_time_with_timezone')
      const createDate = createdDateString ? DateTime.fromISO(createdDateString) : null

      const endDateMatch = filterEndDate ? matchFilterEndDate(startDate, filterEndDate) : true
      const ownerMatch = matchFilterNumber(organization_id, filterOrganizationId)
      const projectTypeMatch = matchFilterNumber(project_type_id, filterProjectTypeId)
      const startDateMatch = filterStartDate ? matchFilterStartDate(startDate, filterStartDate) : true
      const createdAfterDateMatch = filterCreateStartDate ? matchFilterEndDate(createDate, filterCreateStartDate) : true
      const createBeforeDateMatch = filterCreateEndDate ? matchFilterStartDate(createDate, filterCreateEndDate) : true
      const statusMatch = filterStatus === 'archived' ? hidden : !hidden
      const titleMatch = matchFilterString(title, filterString)
      const userIdMatch = filterUserId ? matchFilterArrayIncludes(combined_user_ids, Number(filterUserId)) : true

      return (
        createBeforeDateMatch
        && createdAfterDateMatch
        && endDateMatch
        && ownerMatch
        && projectTypeMatch
        && startDateMatch
        && statusMatch
        && titleMatch
        && userIdMatch
      )
    })

    if (options.limit){
      return sortEntitiesByDate(filtered, 'desc', 'updated').slice(0, options.limit)
    }

    return sortArrayBy(filtered, 'desc', 'id')
  }, [projectsUpdatedAt, JSON.stringify(options)])

  const hasFilteredProjects = !!filteredProjects.length

  const { loading } = useSelector(state => state.projects)

  return {
    filteredProjects,
    hasFilteredProjects,
    loading,
  }
}

export default useProjects
