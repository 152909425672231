import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import ToggleSection from '@components/ModalComponents/ToggleSection'

import localizedStrings from './localizedStrings'

const ShareLinkFeatureToggles = (props) => {
  const { shareLinkForm } = props
  const {
    entityState: { notifications_enabled, tracking_enabled },
    setEntityState,
  } = shareLinkForm

  const { strings } = useLocalization(localizedStrings)

  return (
    <Box flexDirection="column">
      <ToggleSection
        callbacks={{
          toggleFn: () => setEntityState({ tracking_enabled: !tracking_enabled }),
        }}
        enabled={tracking_enabled}
        sectionTitle={strings.headers?.tracking || 'Tracking'}
        text={strings.toggleButtons?.tracking?.text || 'Views will be tracked and recorded'}
        title={strings.toggleButtons?.tracking?.title || 'Tracking and Insights'}
      />

      <ToggleSection
        callbacks={{
          toggleFn: () => setEntityState({ notifications_enabled: !notifications_enabled }),
        }}
        enabled={notifications_enabled}
        sectionTitle={strings.headers?.notifications || 'Notifications'}
        text={strings.toggleButtons?.notifications?.text || 'Notify users when this link is viewed'}
        title={strings.toggleButtons?.notifications?.title || 'Notifications'}
      />
    </Box>
  )
}

ShareLinkFeatureToggles.propTypes = {
  shareLinkForm: PropTypes.shape({
    entityState: PropTypes.shape({
      notifications_enabled: PropTypes.bool.isRequired,
      tracking_enabled: PropTypes.bool.isRequired,
    }),
    setEntityState: PropTypes.func.isRequired,
  }).isRequired,
}

export default ShareLinkFeatureToggles
