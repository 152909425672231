import { useEffect, useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFile, faFileExport, faSort, faUsers,
} from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Columns, DashboardModule, LoadingModule, PageHeader, Tools,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import PageFilterModule from '@components/PageFilterModule'
import VideoModule from '@components/VideoModule'

import useLocalization from '@hooks/useLocalization'
import useOrganization from '@hooks/useOrganization'
import useOrganizationSelector from '@hooks/useOrganizationSelector'
import useReporting from '@hooks/useReporting'
import useUsers from '@hooks/useUsers'
import useVideoModules from '@hooks/useVideoModules'

import UserListItem from '../UserListItem'
import UsersBlankState from '../UsersBlankState'

import localizedStrings from './localizedStrings'

const generateReport = (generateFn, reportQueuedMessage) => {
  generateFn().then(({ success, errors }) => {
    if (!success){
      toast.warning(errors[0])
      return
    }

    toast(reportQueuedMessage)
  })
}

const buildPageFilterFields = (strings) => {
  const style = {
    marginBottom: 16,
  }

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        style,
      },
      key: 'string',
      label: strings.filterByNameLabel || 'Filter by Name',
    },
    {
      entityKey: 'limit',
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: '100',
        inputComponent: 'select',
        style,
      },
      key: 'limit',
      label: strings.limitLabel || 'Limit',
      values: [
        { key: 150, label: '150' },
        { key: 200, label: '200' },
      ],
    },
  ]
}

const PageContent = (props) => {
  const { pageFilters } = props

  const { selectedOrganization } = useOrganizationSelector()

  const { callbacks: { editUserSort } } = useOrganization(selectedOrganization)

  const useUsersPayload = useUsers({
    filters: { ...pageFilters, organization_id: selectedOrganization.id },
    groupUsers: true,
    performHttpRequests: true,
  })

  const {
    callbacks: { loadMore }, canLoadMore, groupedUsers, hasFilteredUsers, loading,
  } = useUsersPayload

  const pageContext = useContext(PageContext)
  const {
    callbacks: { resetFilters, showImportUsersModal, updateFilters },
  } = pageContext

  const videoModulePayload = useVideoModules({ key: 'userlists' })
  const { videosPayload } = videoModulePayload

  const { strings } = useLocalization(localizedStrings)

  const reportingPayload = useReporting()

  const {
    callbacks: { generateReport: generateFn, setState },
    reportingForm: {
      setEntityState,
    },
  } = reportingPayload

  useEffect(() => {
    if (selectedOrganization?.id){
      setEntityState({
        brand_id: selectedOrganization?.brand_id,
        hidden: false,
        organization_id: selectedOrganization?.id,
      })
    }
  }, [selectedOrganization, selectedOrganization?.id])

  useEffect(() => {
    if (selectedOrganization?.id){
      setState({
        selectedReportGroupKey: 'user',
        selectedReportItemKey: 'users_created',
      })
    }
  }, [selectedOrganization?.id])

  return (
    <>
      <Helmet>
        <title>{`${strings.title} | Engage`}</title>
      </Helmet>

      <PageHeader
        activeTabBarItemKey="users"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        nestedNavigation
        tabBarItems={[
          {
            href: '#/admin/users',
            icon: faUsers,
            key: 'users',
            title: strings.titleAll || 'All Users',
          },
        ]}
        title={strings.titleAll || 'All Users'}
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && !hasFilteredUsers && <UsersBlankState />}

            {!loading
              && hasFilteredUsers
              && Object.values(groupedUsers).map((roleGroup) => {
                const { users, key } = roleGroup

                return (!!users.length && (
                  <DashboardModule title={`${roleGroup.title}s`} key={key}>
                    <Box flexDirection="column">
                      <Box flexDirection="column">
                        {users.map(user => (
                          <UserListItem key={user.id} user={user} />
                        ))}
                      </Box>

                      <DashboardModule.LoadMoreFooter
                        callbacks={{ loadMore }}
                        canLoadMore={canLoadMore}
                        entityCount={users.length}
                        loading={loading}
                      />
                    </Box>
                  </DashboardModule>
                )
                )
              })}
          </Columns.Main>

          <Columns.Sidebar>
            <Tools title={strings.tools || 'Tools'}>
              <Button
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faFile} />}
                marginBottom="medium"
                onClick={() => showImportUsersModal()}
                size="medium"
              >
                {strings.importUsersButton || 'Import Users'}
              </Button>

              <Button
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faSort} />}
                marginBottom="medium"
                onClick={() => editUserSort()}
                size="medium"
              >
                {strings.sortUsersButton || 'Sort Users'}
              </Button>

              <Button
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faFileExport} />}
                marginBottom="medium"
                onClick={
                  () => generateReport(
                    generateFn,
                    strings.reportQueuedMessage || 'Report has been queued for building.',
                  )
                }
                size="medium"
              >
                {strings.exportUsersButton || 'Export Users'}
              </Button>
            </Tools>

            <PageFilterModule
              callbacks={{ resetFilters, updateFilters }}
              filterFields={buildPageFilterFields(strings)}
              pageFilters={pageFilters}
              title={strings.filters || 'Filters'}
            />

            <VideoModule videosPayload={videosPayload} />

          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

PageContent.propTypes = {
  pageFilters: PropTypes.object,
}

export default PageContent
