import useCurrentUser from './useCurrentUser'
import useIntegrations from './useIntegrations'

type UserIntegrationsType = {
  integrationPlatformKey: string,
  ownerId: number,
  ownerType:'string',
  subjectId: number,
  subjectType: 'User',
}

function useUserIntegrations(options: UserIntegrationsType = {}) {
  const {
    integrationPlatformKey, ownerId, ownerType,
  } = options

  const currentUserPayload = useCurrentUser()
  const { currentUser } = currentUserPayload

  const integrationPayload = useIntegrations({
    filters: {
      owner_id: ownerId,
      owner_type: ownerType,
      subject_id: currentUser?.id,
      subject_type: 'User',
    },
  })
  const { filteredPlatforms } = integrationPayload

  const integrationPlatformForKey = filteredPlatforms.find(platform => platform?.key === integrationPlatformKey) || {}

  return {
    hasIntegrationForKey: integrationPlatformForKey.hasOwnProperty('id'),
    integrationPlatformForKey,
  }
}

export default useUserIntegrations
