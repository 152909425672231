export default {
  default: {
    toast: {
      attachment: {
        deleted: 'File Deleted Successfully',
        updated: 'File Updated Successfully',
      },
    },
  },
}
