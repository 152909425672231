import { useEffect } from 'react'

import { useLatestEntity } from '@campaignhub/react-hooks'

import { digObject } from '@campaignhub/javascript-utils'

import WebFont from 'webfontloader'

function filterGoogleFonts(fontArray){
  return fontArray.filter(fontString => !fontString.match(/hoefler|typekit|monotype/))
}

// hoefler:7800780/6293432:Gotham A, Gotham B
const filterHoeflerFonts = (fontArray) => {
  const hoeflerFonts = fontArray.filter(fontString => fontString.match(/hoefler/)).map((font) => {
    const fontKey = font.split(':')[1]
    const fontFamilies = font.split(':')[2]?.split(',')

    return {
      families: fontFamilies,
      urls: [`https://cloud.typography.com/${fontKey}/css/fonts.css`],
    }
  })

  return hoeflerFonts
}

const loadFonts = async (fontArray) => {
  if (!fontArray.length) return

  const newFontArray = [...fontArray]

  // Google fonts are any fonts that do not match the regex as they are the default
  const googleFonts = filterGoogleFonts(newFontArray)
  const hoeflerFonts = filterHoeflerFonts(newFontArray)
  const typeKitFonts = newFontArray.filter(fontString => fontString.match(/typekit/)).map(font => font.split(':')[1])

  const loaderOptions = {}

  if (googleFonts.length){
    loaderOptions.google = {
      families: googleFonts,
    }
  }

  if (hoeflerFonts.length){
    loaderOptions.custom = {
      families: hoeflerFonts[0]?.families,
      urls: hoeflerFonts[0]?.urls,
    }
  }

  if (typeKitFonts.length){
    loaderOptions.typekit = {
      id: typeKitFonts[0],
    }
  }

  WebFont.load(loaderOptions)
}

const queueWebFontLoad = (templateFonts) => {
  setTimeout(() => {
    loadFonts(templateFonts)
  }, 0)
}

function useDigitalTemplateFonts(initDigitalTemplate = {}){
  const { entity: digitalTemplate } = useLatestEntity(initDigitalTemplate, 'digitalTemplates')

  const templateFonts = digObject(digitalTemplate, 'options.fonts', [])

  useEffect(() => {
    if (digitalTemplate.id && templateFonts.length){
      queueWebFontLoad(templateFonts)
    }
  }, [digitalTemplate.id, templateFonts.length])
}

export default useDigitalTemplateFonts
