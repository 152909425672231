export default {
  default: {
    backButton: 'Go Back',
    filterTemplates: 'Filter Templates',
    savedTemplates: 'Saved Templates',
    title: 'Create',
    titleSecondLine: 'From Template',
    noticeBox: {
      paragraph: 'Create a new quote then save it as a template.',
      title: 'No Saved Templates',
    },
  },
}
