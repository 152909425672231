import React from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

import { useSetState } from '@campaignhub/react-hooks'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faPlus } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, ListItem, Text,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'
import useMixpanel from '@hooks/useMixpanel'

import localizedStrings from './localizedStrings'

const selectProperty = (params) => {
  const { dispatchMixpanelEvent, props, setState } = params
  const { comparable, onClickFn } = props
  const { id } = comparable || {}

  setState({ loading: true })

  onClickFn({ id }).then(({ success, errors }) => {
    setState({ loading: false })
    if (!success){
      toast.warning(errors[0])
    }

    dispatchMixpanelEvent('Comparables Save', { integration_platform: comparable?.service_provider })
  })
}

const defaultState = {
  loading: false,
}

const PropertySuggestionWithButton = (props) => {
  const {
    added,
    comparable,
  } = props
  const { address, postcode, state_name } = comparable

  const [state, setState] = useSetState(defaultState)
  const { loading } = state

  const { strings } = useLocalization(localizedStrings)

  const { callbacks: { dispatchMixpanelEvent } } = useMixpanel()

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        border: '1px solid',
        borderColor: 'lineColor',
        borderRadius: 5,
        marginTop: 'medium',
        padding: 'large',
      }}
      disableHover
      flexDirection="column"
      flexShrink={0}
      forceBottomBorder
    >
      <Box flexDirection="column" justifyContent="center">
        <Text color={added ? 'lightGrey' : 'bodyFontColor'} fontSize="small">
          {address}
        </Text>

        {(state_name || postcode) && (
          <Text color={added ? 'lightGrey' : 'bodyFontLightColor'} fontSize="xsmall" marginTop="small">
            {state_name} {postcode}
          </Text>
        )}
      </Box>

      {!added && (
        <Button
          buttonStyle="secondaryEdit"
          icon={<FontAwesomeIcon icon={faPlus} />}
          loading={loading}
          onClick={() => selectProperty({ dispatchMixpanelEvent, props, setState })}
          marginTop="large"
          size="medium"
          width="100%"
        >
          {strings.addComparable || 'Add Comparable'}
        </Button>
      )}

      {added && (
        <Button
          buttonStyle="secondaryCreate"
          icon={<FontAwesomeIcon icon={faCheck} />}
          marginTop="large"
          size="medium"
          width="100%"
        >
          {strings.added || 'Added'}
        </Button>
      )}
    </ListItem>
  )
}

PropertySuggestionWithButton.propTypes = {
  added: PropTypes.bool,
  comparable: PropTypes.object.isRequired,
  onClickFn: PropTypes.func,
}

export default PropertySuggestionWithButton
