import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import {
  useDebounce, useLatestEntity, useSetState, useThunkDispatch,
} from '@campaignhub/react-hooks'
import { digObject, toggleArray } from '@campaignhub/javascript-utils'

import useProject from '@hooks/useProject'

import * as reviewActions from '@redux/modules/review'

const setupState = (project, entities, setState) => {
  const { reviews } = entities
  const reviewIds = digObject(project, 'review_ids', [])
  const projectReviews = reviewIds.map(reviewId => reviews[reviewId]).filter(review => !review.hidden)

  const projectUserIds = digObject(project, 'combined_user_ids', [])

  const reviewsUsers = projectReviews.reduce((acc, review) => {
    const userIds = digObject(review, 'user_ids', [])
    const reviewObjects = userIds.map(userId => ({ review_id: review.id, user_id: userId }))
    const filteredReviewObjects = reviewObjects.filter(reviewObject => projectUserIds.includes(reviewObject?.user_id))

    return [...acc, ...filteredReviewObjects]
  }, [])

  setState({ reviewsUsers })
}

const toggleReview = (reviewId, state, setState) => {
  const { selectedUserId, reviewsUsers } = state

  const review = {
    review_id: reviewId,
    user_id: selectedUserId,
  }

  const updateSelectedReviews = toggleArray(reviewsUsers, review, { deepCompare: true })
  setState({ reviewsUsers: updateSelectedReviews })
}

const toggleAllReviews = (reviews, state, setState) => {
  const { selectedUserId, reviewsUsers } = state

  if (reviewsUsers.length === reviews.length){
    setState({ reviewsUsers: [] })
    return
  }

  setState({ reviewsUsers: reviews.map(review => ({ review_id: review.id, user_id: selectedUserId })) })
}

const loadReviewsForUser = (searchParams, dispatch, setState) => {
  const { debouncedString, selectedUserId } = searchParams || {}
  const { searchReviews: searchFn } = reviewActions

  setState({ loading: true, reviews: [], errors: [] })

  const options = {
    string: debouncedString,
    user_id: selectedUserId,
    include_review_address: true,
    include_review_user_ids: true,
  }

  dispatch(searchFn(options)).then((result) => {
    const { success, data, errors } = result
    if (!success){
      setState({ errors, loading: false })
    }

    setState({
      errors: [],
      loading: false,
      reviews: data.entities,
    })
  })
}

const defaultState = {
  errors: [],
  loading: false,
  reviews: [],
  reviewsUsers: [],
  selectedUserId: null,
  showSelectedReviews: false,
  string: '',
}

function useManageReviews(initProject){
  const { entity: project } = useLatestEntity(initProject, 'projects')

  const [state, setState] = useSetState(defaultState)
  const {
    loading, reviews, reviewsUsers, selectedUserId, showSelectedReviews, string,
  } = state

  const debouncedString = useDebounce(string, 300)

  const dispatch = useThunkDispatch()

  const entities = useSelector(reduxState => reduxState.entities)
  const { images, users } = entities

  const projectPayload = useProject(project)

  const userIdsWithReviews = [...new Set(reviewsUsers.map(review => review.user_id))]

  const usersWithReviews = userIdsWithReviews.reverse().map((userId) => {
    const user = users[userId] || {}
    return {
      reviewCount: reviewsUsers.filter(review => review.user_id === userId).length,
      user,
    }
  })

  useEffect(() => {
    if (selectedUserId){
      loadReviewsForUser({ debouncedString, selectedUserId }, dispatch, setState)
    }
  }, [debouncedString, selectedUserId])

  useEffect(() => {
    if (project.id){
      setupState(project, entities, setState)
    }
  }, [project.id])

  const selectedUser = users[selectedUserId] || {}
  const selectedUserHasReviews = userIdsWithReviews.includes(selectedUser.id)

  const selectedUserReviews = reviews.filter(review => !review?.hidden && review.user_ids.includes(selectedUserId))

  return {
    callbacks: {
      selectUserId: userId => setState({ selectedUserId: userId }),
      toggleReview: id => toggleReview(id, state, setState),
      toggleAllReviews: () => toggleAllReviews(reviews, state, setState),
      setState,
    },
    images,
    loading,
    project,
    projectPayload,
    reviews,
    reviewsUsers,
    selectedUser,
    selectedUserHasReviews,
    selectedUserReviews,
    showSelectedReviews,
    string,
    usersWithReviews,
  }
}

export default useManageReviews
