export default {
  default: {
    createButton: 'Add Integration',
    supportedFeatures: {
      type: 'count',
      none: '{count} Supported Features',
      one: '{count} Supported Feature',
      many: '{count} Supported Features',
    },
  },
}
