export default {
  default: {
    blankState: {
      title: 'No Chart Data to Display',
      paragraph: 'Once pages have been viewed chart data will be recorded and displayed',
    },
    chartKeyValues: {
      engagementPerPageView: 'Engagement per Page View',
      engagementPercentage: 'Engagement Status Percentage',
    },
    manage: 'Manage',
  },
}
