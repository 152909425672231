import { useContext } from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'
import { toast } from 'react-toastify'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleCheck,
  faCog,
  faDownload,
  faHome,
  faMinusCircle,
  faPencil,
  faShare,
  faTrashAlt,
} from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, IconWithMenu, Link, ListItemWithImage, StatusBadge, Text,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import handleCallbackAction from '@functions/handleCallbackAction'

import DigitalPagePlaceholderImage from '@components/DigitalPagePlaceholderImage'
import EngagementStatusBadge from '@components/EngagementStatusBadge'

import { generateUrls } from '@hooks/useProject'
import useDigitalPage from '@hooks/useDigitalPage'
import useDigitalPageTheme from '@hooks/useDigitalPageTheme'
import useDigitalTemplate from '@hooks/useDigitalTemplate'
import useEngagementStatus from '@hooks/useEngagementStatus'
import useLocalization from '@hooks/useLocalization'
import useStatuses from '@hooks/useStatuses'

import PdfDownloadContext from '@contexts/pdfDownloadContext'

import { statusColor } from '@functions/status'

import localizedStrings from './localizedStrings'

const deleteDigitalPage = (deleteFn, strings, formatString, digitalTemplateType) => {
  deleteFn().then(({ success, errors }) => {
    if (!success){
      toast.warning(errors[0])
      return
    }

    toast(
      formatString(strings.toast?.digitalPageDeleted, { templateType: digitalTemplateType.title })
        || `${digitalTemplateType.title} Deleted Successfully`,
    )
  })
}

const confirmDelete = (deleteFn, strings, formatString, digitalTemplateType) => {
  swal
    .fire({
      title:
        formatString(strings.iconWithMenu?.delete, { templateType: digitalTemplateType.title })
        || `Delete ${digitalTemplateType.title}`,
      text: strings.swal?.text || 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: strings.swal?.confirmButtonText || 'Yes, delete it.',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        deleteDigitalPage(deleteFn, strings, formatString, digitalTemplateType)
      }
    })
}

const DigitalPageListItem = (props) => {
  const { digitalPage, showProjectTitle } = props

  const digitalPagePayload = useDigitalPage(digitalPage)
  const {
    callbacks: { deleteDigitalPage: deleteFn, manageShareLinks, updateDigitalPage: updateFn },
    digitalPage: { engagement_status, total_views },
    digitalTemplate,
    digitalTemplate: { id, title },
    digitalTemplateType,
    project: { id: projectId, title: projectTitle },
    status,
    urls: { editDigitalPageUrl },
  } = digitalPagePayload

  const { overviewUrl } = generateUrls({ id: projectId })

  const pdfDownloadContext = useContext(PdfDownloadContext)
  const {
    callbacks: { buildPdf },
  } = pdfDownloadContext

  const { previewImage } = useDigitalTemplate(digitalTemplate)
  const previewImageUrl = digObject(previewImage, 'sizes.thumb_640x360', '')

  const { engagementStatusTheme } = useEngagementStatus(engagement_status)

  const { colors, digitalTemplateTypeKey } = useDigitalPageTheme(digitalPage)

  const { filteredStatuses } = useStatuses({ performHttpRequests: true })

  const acceptedStatus = filteredStatuses.find(filteredStatus => filteredStatus.key === 'accepted')
  const pendingStatus = filteredStatuses.find(filteredStatus => filteredStatus.key === 'pending')

  const isAccepted = status?.id === acceptedStatus?.id

  const {
    callbacks: { formatCount, formatString },
    strings,
  } = useLocalization(localizedStrings, { status })

  const statusButtonText = isAccepted
    ? strings.iconWithMenu?.pending || 'Mark as Pending'
    : strings.iconWithMenu?.accept || 'Mark as Accepted'

  const updateDigitalPagePayload = {
    callbacks: {
      action: updateFn,
    },
    entityParams: { status_id: isAccepted ? pendingStatus?.id : acceptedStatus?.id },
    toastText: strings.toast?.digitalPageUpdated || 'Digital Page Updated Successfully',
  }

  return (
    <ListItemWithImage
      imageComponent={(
        <ListItemWithImage.Image
          href={editDigitalPageUrl}
          imageBoxProps={{ backgroundColor: !previewImageUrl ? colors.background : null }}
          url={previewImageUrl}
          title="Template Title"
        >
          <DigitalPagePlaceholderImage
            color={colors.solid}
            digitalTemplateTypeKey={digitalTemplateTypeKey}
            height="80%"
            width="80%"
          />
        </ListItemWithImage.Image>
      )}
    >
      <ListItemWithImage.Header href={editDigitalPageUrl}>
        <Box flexDirection="column" marginRight="auto">
          <Text fontSize="small" marginBottom={['small', 'medium']}>
            {showProjectTitle ? projectTitle : title}
          </Text>

          <StatusBadge color={statusColor(status.key)} ghost text={formatString(strings.statusTitle) || status.title} />
        </Box>

        <Box justifyContent="center" flexDirection="column" width={82}>
          <EngagementStatusBadge engagementStatusTheme={engagementStatusTheme} />

          <Text
            color="bodyFontLightColor"
            fontSize="xsmall"
            marginTop="medium"
            textAlign="center"
            style={{ whiteSpace: 'nowrap' }}
          >
            {formatCount('views', { count: total_views })}
          </Text>
        </Box>
      </ListItemWithImage.Header>

      <ListItemWithImage.Footer boxProps={{ flexWrap: ['wrap', 'wrap', 'no-wrap'] }}>
        <ListItemWithImage.FooterLeft boxProps={{ display: ['none', 'none', 'flex'], marginRight: ['none', 'auto'] }}>
          <ListItemWithImage.FooterText boxProps={{ color: 'bodyFontLightColor' }}>
            {formatString(strings.templateId, { id }) || `Template ID #${id}`}
          </ListItemWithImage.FooterText>

          <ListItemWithImage.FooterText boxProps={{ marginTop: 'small' }}>{title}</ListItemWithImage.FooterText>
        </ListItemWithImage.FooterLeft>

        <ListItemWithImage.FooterRight boxProps={{ marginLeft: 'none', marginTop: 'none' }}>
          <IconWithMenu
            icon={(
              <Button
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faCog} />}
                size="medium"
                style={{ height: 37 }}
              />
            )}
            style={{ width: 'auto' }}
          >
            <IconWithMenu.Menu closeOnClick listStyle={{ right: '-7px' }}>
              <Link key="project" href={overviewUrl}>
                <FontAwesomeIcon icon={faHome} /> {strings.iconWithMenu?.goToProject || 'Go to Project'}
              </Link>

              <Link key="pdf" onClick={() => buildPdf(digitalPage)}>
                <FontAwesomeIcon icon={faDownload} /> {strings.iconWithMenu?.generatePdf || 'Generate PDF'}
              </Link>

              <Link key="delete" onClick={() => confirmDelete(deleteFn, strings, formatString, digitalTemplateType)}>
                <FontAwesomeIcon icon={faTrashAlt} />{' '}
                {formatString(strings.iconWithMenu?.delete, { templateType: digitalTemplateType.title })
                  || `Delete ${digitalTemplateType.title}`}
              </Link>

              <Link key="accept" onClick={() => handleCallbackAction(updateDigitalPagePayload)}>
                <FontAwesomeIcon icon={isAccepted ? faMinusCircle : faCircleCheck} /> {statusButtonText}
              </Link>
            </IconWithMenu.Menu>
          </IconWithMenu>

          <Box flexDirection="row">
            <Button
              buttonStyle="secondaryUtility"
              icon={<FontAwesomeIcon icon={faShare} />}
              marginLeft="medium"
              onClick={() => manageShareLinks()}
              size="medium"
              style={{ height: 37 }}
              width="auto"
            >
              {strings.button?.share || 'Share'}
            </Button>

            <Button
              as="a"
              buttonStyle="primaryEdit"
              href={editDigitalPageUrl}
              icon={<FontAwesomeIcon icon={faPencil} />}
              marginLeft="medium"
              size="medium"
              style={{ height: 37 }}
              width="auto"
            >
              {strings.button?.edit || 'Edit'}
            </Button>
          </Box>
        </ListItemWithImage.FooterRight>
      </ListItemWithImage.Footer>
    </ListItemWithImage>
  )
}

DigitalPageListItem.propTypes = {
  digitalPage: PropTypes.object.isRequired,
  showProjectTitle: PropTypes.bool,
}

export default DigitalPageListItem
