import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolderOpen } from '@fortawesome/pro-light-svg-icons'

import {
  Box, FormField, InputSearch, UserProfileImage,
} from '@campaignhub/suit-theme'

import useAssetLibraries from '@hooks/useAssetLibraries'
import useLocalization from '@hooks/useLocalization'

import SelectedEntityItem from '../SelectedEntityItem'

import localizedStrings from './localizedStrings'

const mapResults = (brandAssetLibraries, organizationAssetLibraries, files, strings) => {
  const groups = []
  const organizationAssetLibrariesArray = Object.values(organizationAssetLibraries)
  const brandAssetLibrariesArray = Object.values(brandAssetLibraries)

  if (organizationAssetLibrariesArray.length){
    const organizationAssetGroup = {
      key: 'organization',
      title: strings.mapResults?.organization?.title || 'ORGANIZATION',
      items: organizationAssetLibrariesArray
        .map(library => ({
          ...library,
          iconLeft: (
            <UserProfileImage>
              <FontAwesomeIcon icon={faFolderOpen} />
            </UserProfileImage>
          ),
          secondaryText: strings.mapResults?.organization?.secondaryText || 'Agency Library',
          text: library.title,
          key: `AssetLibrary-${library.id}`,
        })),
    }

    groups.push(organizationAssetGroup)
  }

  if (brandAssetLibrariesArray.length){
    const brandAssetGroup = {
      key: 'brand',
      title: strings.mapResults?.brand?.title || 'BRAND',
      items: Object.values(brandAssetLibraries)
        .map(library => ({
          ...library,
          iconLeft: (
            <UserProfileImage>
              <FontAwesomeIcon icon={faFolderOpen} />
            </UserProfileImage>
          ),
          secondaryText: strings.mapResults?.organization?.secondaryText || 'Brand Library',
          text: library.title,
          key: `AssetLibrary-${library.id}`,
        })),
    }

    groups.push(brandAssetGroup)
  }

  return { groups }
}

const AssetLibrary = (props) => {
  const {
    fileSelectPayload: {
      attachments,
      callbacks: { selectEntity, setState },
      filteredEntityAttachments,
      selectedAttachmentIds,
      selectedEntity,
    },
  } = props

  const assetLibraryPayload = useAssetLibraries({
    filters: {
      asset_type: 'file',
    },
  })
  const { filteredBrandAssetLibraries, filteredOrganizationAssetLibraries } = assetLibraryPayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      {!selectedEntity.id && (
      <FormField
        boxProps={{ marginBottom: 'large' }}
        label={strings.formFieldLabels?.findAssetLibrary || 'Find File Library'}
      >
        <InputSearch
          callbacks={{ selectItem: item => selectEntity({ type: 'AssetLibrary', id: item.id }) }}
          closeOnSelect
          placeholder={strings.fileLibrarySearchPlaceholder || 'Search for file library'}
          results={mapResults(filteredBrandAssetLibraries, filteredOrganizationAssetLibraries, attachments, strings)}
          showOnFocus
        />
      </FormField>
      )}

      {!!selectedEntity.id && (
        <>
          <FormField
            boxProps={{ marginBottom: 'large' }}
            label={strings.selectedFileLibrary || 'Selected File Library'}
          >
            <SelectedEntityItem
              assetLibrary={selectedEntity}
              callbacks={{ removeSelectedEntity: () => selectEntity({ type: 'AssetLibrary', id: 0 }) }}
            />
          </FormField>

          <FormField
            boxProps={{ marginBottom: 'large' }}
            label={strings.selectFile || 'Select File'}
          >
            <Box>
              <select
                onChange={e => setState({ selectedAttachmentIds: [...selectedAttachmentIds, Number(e.target.value)] })}
                value=""
              >
                <option value="">{strings.select?.option || 'Select...'}</option>
                {filteredEntityAttachments.map(attachment => (
                  <option key={attachment.data?.id} value={attachment.id}>
                    {attachment.data?.title}
                  </option>
                ))}
              </select>
            </Box>
          </FormField>
        </>
      )}
    </>
  )
}

AssetLibrary.propTypes = {
  fileSelectPayload: PropTypes.shape({
    attachments: PropTypes.object.isRequired,
    callbacks: PropTypes.shape({
      selectEntity: PropTypes.func.isRequired,
      setState: PropTypes.func.isRequired,
    }).isRequired,
    filteredEntityAttachments: PropTypes.array,
    selectedAttachmentIds: PropTypes.array,
    selectedEntity: PropTypes.object.isRequired,
    selectedEntityID: PropTypes.object.isRequired,
  }).isRequired,
}

export default AssetLibrary
