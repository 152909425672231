import { useEffect } from 'react'

import {
  Button, DynamicInput, FormField, SidebarModal,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import { useSetState } from '@campaignhub/react-hooks'

import type { CustomFieldModel, NestedFieldModel } from '@models/customField'

import { capitalizeString } from '@functions/string'

const MODAL_KEY = 'CreateOrEditNestedDataStoreArrayItemModal'

type ModalCallbacks = {
  closeModal: () => void,
  createOrUpdateNestedItem: () => void,
  setupNestedDataStoreItemState: () => { [key: string]: any },
}

type ModalProps = {
  callbacks: ModalCallbacks,
  showModal: boolean,
  nestedFields: NestedFieldModel[],
  nestedDataStoreItem: { id: string | number },
  customField: CustomFieldModel,
}

type State = {
  [key: string]: any,
}

type CreateOrUpdateParams = {
  callbacks: ModalCallbacks,
  createOrUpdateFn: (state: State) => void,
  state: State,
}

const createOrUpdateNestedItem = (params: CreateOrUpdateParams) => {
  const { callbacks, createOrUpdateFn, state } = params
  const { closeModal } = callbacks || {}

  createOrUpdateFn(state)

  if (closeModal){
    closeModal()
  }
}

const CreateOrEditNestedDataStoreArrayItemModal = (props: ModalProps) => {
  const { callbacks, showModal } = props
  const { setupNestedDataStoreItemState, createOrUpdateNestedItem: createOrUpdateFn } = callbacks || {}

  const {
    customField: { label },
    nestedDataStoreItem,
    nestedFields,
  } = props

  const [state, setState] = useSetState({})

  useEffect(() => {
    if (nestedDataStoreItem.id){
      return setState(nestedDataStoreItem)
    }

    return setState(setupNestedDataStoreItemState())
  }, [nestedDataStoreItem.id])

  return (
    <SidebarModal callbacks={callbacks} modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header
        callbacks={callbacks}
        title={nestedDataStoreItem.id ? 'Edit' : 'Create'}
        titleSecondLine={label}
      />

      <SidebarModal.Content>
        {nestedFields.map((nestedField) => {
          const {
            field_type, input_type, key, label: nestedFieldLabel,
          } = nestedField

          const optionValues = digObject(nestedField, 'options.option_values', [])

          return (
            <FormField boxProps={{ marginBottom: 'medium' }} label={capitalizeString(nestedFieldLabel)} key={key}>
              <DynamicInput
                currentValue={state[key] || ''}
                inputComponent={field_type}
                inputType={input_type}
                name={key}
                onChange={e => setState({ [key]: e.target.value })}
                style={{ height: field_type === 'textarea' ? 80 : null }}
                values={optionValues.map((item: { key: string, value: string }) => ({
                  key: item.key,
                  label: item.value,
                }))}
              />
            </FormField>
          )
        })}
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          onClick={() => createOrUpdateNestedItem({ callbacks, createOrUpdateFn, state })}
          size="large"
        >
          {nestedDataStoreItem.id ? 'Update' : 'Create'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ModalProps) => (
  <SidebarModal.RenderController {...props}>
    <CreateOrEditNestedDataStoreArrayItemModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
