import { useContext } from 'react'

import { digObject } from '@campaignhub/javascript-utils'
import { useForm } from '@campaignhub/react-hooks'

import PageContext from '@contexts/pageContext'
import replaceTextShortCodes from '@functions/replaceTextShortCodes'

const defaultState = {
  value: '',
}

const requiredFields = []

function useTextPageItem(digitalPageItemPayload){
  const {
    digitalTemplatePageItemId,
    pageItemData: { value },
    templatePageItemPayload,
  } = digitalPageItemPayload

  const pageContext = useContext(PageContext)
  const { shortcodeData } = pageContext || {}

  const contextData = { ...shortcodeData }

  const defaultValue = digObject(templatePageItemPayload, 'digitalTemplatePageItem.data.value')

  const shortcodeValue = replaceTextShortCodes(defaultValue, contextData)

  const entity = {
    value: value || shortcodeValue || defaultValue,
  }

  const formPayload = useForm(defaultState, { entity, requiredFields }, [digitalTemplatePageItemId])

  return {
    formPayload,
  }
}

export default useTextPageItem
