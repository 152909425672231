import { useContext, useMemo, useRef } from 'react'
import { DateTime } from 'luxon'

import { useForm, useLatestEntity, useWatchEntityUpdates } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import { generateRedirectUrl, launchModal, sortArrayBy } from '@campaignhub/javascript-utils'

import useDispatch from '@hooks/useDispatch'
import useMixpanel from '@hooks/useMixpanel'
import useSelector from '@hooks/useSelector'
import { changeEventStartDateString, createEvent, updateEvent } from '@hooks/useEvent'

import * as eventCalendarActions from '@redux/modules/eventCalendar'
import * as eventCalendarTemplateActions from '@redux/modules/eventCalendarTemplate'

import defaultFormState, { requiredFields } from '@models/eventCalendar'

import PageContext from '@contexts/pageContext'

import type { AppDispatch } from '@redux/store'
import type { DeleteParams, RootReducerState } from '@redux/modules/types'
import type {
  EventCalendarModel, EventCalendarRequestOptions, EventGroupModel, EventModel,
} from '@models/types'

const watchEntityKeys = ['events', 'eventGroups']

export const generateUrls = (eventCalendar?: Partial<EventCalendarModel>) => {
  const { id, subject_id } = eventCalendar || {}

  return {
    digitalPageEventCalendarUrl: `#/eventCalendars/${id}/edit`,
    editEventCalendarUrl: `#/eventCalendars/${id}/edit?redirect=${generateRedirectUrl()}`,
    eventCalendarsIndexUrl: '#/admin/eventCalendarTemplates',
    eventsIndexUrl: `#/projects/${subject_id}/events`,
  }
}

const findGroupForEvent = (
  eventDaysFromStart: number,
  filteredEventGroups: EventGroupModel[],
) => filteredEventGroups.find((eventGroup) => {
  const { days_from_start, duration_in_days } = eventGroup
  return eventDaysFromStart >= days_from_start && eventDaysFromStart < (days_from_start + duration_in_days)
})

type CreateEventCalendarParams = {
  dispatch: AppDispatch,
  dispatchMixpanelEvent?: (eventType: string, customPayload?: {}) => void,
  eventCalendarParams: Partial<EventCalendarModel>,
  requestOptions?: EventCalendarRequestOptions,
}

const createEventCalendar = (params: CreateEventCalendarParams) => {
  const {
    dispatch, dispatchMixpanelEvent, eventCalendarParams, requestOptions,
  } = params

  const { createEventCalendar: createCalendarFn, createFromTemplate } = eventCalendarActions

  const createFn = eventCalendarParams.event_calendar_template_id ? createFromTemplate : createCalendarFn

  return dispatch(createFn(eventCalendarParams, requestOptions)).then((response) => {
    const { success, data } = response
    if (success){
      const {
        entity: { id },
      } = data

      const { digitalPageEventCalendarUrl } = generateUrls({ id })

      const editEventCalendarRedirectUrl = `${digitalPageEventCalendarUrl}?redirect=${generateRedirectUrl({
        append: 'showModal=ManageEventCalendarsModal',
      })}`

      if (dispatchMixpanelEvent){
        dispatchMixpanelEvent('Calendar Created', {
          created_from_template: !!eventCalendarParams.event_calendar_template_id,
        })
      }

      return {
        success,
        data,
        redirectUrl: editEventCalendarRedirectUrl,
      }
    }

    return response
  })
}

type SaveCalendarAsTemplateParams = {
  dispatch: AppDispatch,
  eventCalendar: Partial<EventCalendarModel>,
  eventCalendarTemplate: {},
  requestOptions?: EventCalendarRequestOptions,
}

const saveCalendarAsTemplate = (params: SaveCalendarAsTemplateParams) => {
  const {
    eventCalendar, eventCalendarTemplate, dispatch, requestOptions,
  } = params

  const { createFromCalendar: createFn } = eventCalendarTemplateActions

  return dispatch(createFn(eventCalendar, eventCalendarTemplate, requestOptions)).then((response) => {
    const { success, data } = response
    if (success){
      const {
        entity: { id },
      } = data

      const { digitalPageEventCalendarUrl } = generateUrls({ id })

      const editEventCalendarRedirectUrl = `${digitalPageEventCalendarUrl}?redirect=${generateRedirectUrl({
        append: '&showModal=ManageEventCalendarsModal',
      })}`

      return {
        success,
        data,
        redirectUrl: editEventCalendarRedirectUrl,
      }
    }

    return response
  })
}

type UpdateEventCalendarParams = {
  dispatch: AppDispatch,
  eventCalendar: EventCalendarModel,
  eventCalendarParams: Partial<EventCalendarModel>,
  requestOptions?: EventCalendarRequestOptions,
}

const updateEventCalendar = (params: UpdateEventCalendarParams) => {
  const {
    dispatch, eventCalendar, eventCalendarParams, requestOptions,
  } = params
  const { updateEventCalendar: updateFn } = eventCalendarActions

  const updatedParams = {
    id: eventCalendar.id,
    ...eventCalendarParams,
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

type DeleteEventCalendarParams = {
  dispatch: AppDispatch,
  eventCalendar: DeleteParams<EventCalendarModel>,
}

const deleteEventCalendar = (params: DeleteEventCalendarParams) => {
  const { dispatch, eventCalendar } = params
  const { deleteEventCalendar: deleteFn } = eventCalendarActions

  return dispatch(deleteFn(eventCalendar)).then((response) => {
    const { success } = response

    if (success){
      const { saved_template, subject_id } = eventCalendar

      return {
        ...response,
        redirectUrl: saved_template ? '#/admin/eventCalendarTemplates' : `#/projects/${subject_id}/events`,
      }
    }

    return response
  })
}

type DragDropEventPaload = {
  cellData: {
    date: string,
    daysFromStart: string,
  },
  dropData: {
    dataset: {
      action: string,
      entityId: number,
    },
    dropTargetEntity: EventGroupModel,
  },
}

type DragDropEventParams = {
  dispatch: AppDispatch,
  entitiesRef:{
    current: {
      events: EventModel[],
    },
  },
  payload: DragDropEventPaload,
}

const dragDropEvent = (params: DragDropEventParams) => {
  const { dispatch, entitiesRef, payload } = params
  const {
    cellData: { date, daysFromStart },
    dropData: { dataset: { action, entityId }, dropTargetEntity: eventGroup },
  } = payload

  const { events } = entitiesRef.current

  const event = events[entityId] || {}

  const updatedEvent = {
    days_from_start: daysFromStart,
    event_calendar_id: eventGroup.event_calendar_id,
    event_group_id: eventGroup.id,
    id: event.id,
    start_date: changeEventStartDateString(event, date),
  }

  const actionFn = action === 'update' ? updateEvent : createEvent

  return actionFn({ eventParams: updatedEvent, dispatch })
}

type SetupNextEventGroupParams = {
  eventCalendar: Partial<EventCalendarModel>,
  lastEventGroup: Partial<EventGroupModel>,
}
const setupNextEventGroup = (params: SetupNextEventGroupParams) => {
  const { eventCalendar, lastEventGroup } = params
  const { id } = eventCalendar
  const { days_from_start, duration_in_days } = lastEventGroup

  const nextGroupDaysFromStart = (days_from_start || 0) + (duration_in_days || 0)
  const weekNumber = duration_in_days === 7 ? (nextGroupDaysFromStart / duration_in_days) + 1 : null

  return {
    days_from_start: nextGroupDaysFromStart,
    duration_in_days: 7,
    event_calendar_id: id,
    title: weekNumber ? `Week ${weekNumber}` : '',
  }
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useEventCalendarForm(
  eventCalendar: Partial<EventCalendarModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const { creating, updating } = useSelector(reduxState => reduxState.eventCalendars)

  const eventCalendarForm = useForm(
    defaultFormState,
    { entity: eventCalendar, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [eventCalendar.id, eventCalendar.cache_key],
  )

  return {
    creating,
    updating,
    ...eventCalendarForm,
  }
}

function useEventCalendar(initEntity: Partial<EventCalendarModel> = {}) {
  const { entity: eventCalendar }: { entity: EventCalendarModel} = useLatestEntity(initEntity, 'eventCalendars')
  const { id, saved_template } = eventCalendar

  const {
    updatedEntities: { events: eventsUpdatedAt, eventGroups: eventGroupsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const dispatch = useDispatch()

  const { callbacks } = useContext(PageContext)

  const isTemplate = !!saved_template

  const { creating } = useSelector(reduxState => reduxState.eventCalendars)

  const entitiesRef = useRef()
  entitiesRef.current = useSelector((reduxState: RootReducerState) => reduxState.entities)
  const { events, eventGroups } = entitiesRef.current

  // Event Groups
  const filteredEventGroups = useMemo(() => {
    const array: EventGroupModel[] = Object.values(eventGroups)
    const filtered = array.filter((eventGroup) => {
      const { event_calendar_id } = eventGroup
      return event_calendar_id === id
    })

    const sorted = sortArrayBy(filtered, 'asc', (eventGroup) => {
      const { days_from_start, duration_in_days } = eventGroup
      return days_from_start + duration_in_days
    })

    return sorted
  }, [id, eventsUpdatedAt, eventGroupsUpdatedAt])

  // Events
  const filteredEvents = useMemo(() => {
    const array: EventModel[] = Object.values(events)
    const filtered = array.filter((event) => {
      const { event_calendar_id } = event
      return event_calendar_id === id
    })

    const sorted = sortArrayBy(filtered, 'asc', (event) => {
      const { days_from_start, duration_in_days } = event
      return days_from_start + duration_in_days
    })

    return sorted
  }, [id, eventsUpdatedAt])

  const lastEventGroup = filteredEventGroups[filteredEventGroups.length - 1] || {}
  const nextEventGroup = setupNextEventGroup({ eventCalendar, lastEventGroup })

  const weekOneStart = filteredEventGroups[0]?.dates.start.date_time_with_timezone
  const calendarStartDate = DateTime.fromISO(weekOneStart)

  const lastWeekStart = lastEventGroup.dates?.start.date_time_with_timezone
  const calendarEndDate = DateTime.fromISO(lastWeekStart).plus({ days: lastEventGroup.duration_in_days - 1 })

  // Mixpanel Tracking
  const { callbacks: { dispatchMixpanelEvent } } = useMixpanel()

  return {
    calendarEndDate,
    calendarStartDate,
    callbacks: {
      createCalendar: () => launchModal({
        callbacks,
        modalKey: 'CreateEventCalendarModal',
        payload: {
          callbacks: {
            createEventCalendar: (
              eventCalendarParams: Partial<EventCalendarModel>,
              entityOptions?: EventCalendarRequestOptions,
            ) => (
              createEventCalendar({
                eventCalendarParams, dispatch, dispatchMixpanelEvent, requestOptions: entityOptions,
              })
            ),
          },
          eventCalendar,
        },
      }),
      createEventCalendar: (
        eventCalendarParams: Partial<EventCalendarModel>,
        entityOptions?: EventCalendarRequestOptions,
      ) => (
        createEventCalendar({
          eventCalendarParams, dispatch, dispatchMixpanelEvent, requestOptions: entityOptions,
        })
      ),
      createTemplate: () => launchModal({
        callbacks,
        modalKey: 'CreateEventCalendarModal',
        payload: {
          callbacks: {
            createEventCalendar: (
              eventCalendarParams: Partial<EventCalendarModel>,
              entityOptions?: EventCalendarRequestOptions,
            ) => (
              createEventCalendar({ eventCalendarParams, dispatch, requestOptions: entityOptions })
            ),
          },
          eventCalendar,
          selectedScreen: 'CreateNew',
        },
      }),
      createTemplateFromCalendar: () => launchModal({
        callbacks,
        modalKey: 'CreateEventCalendarModal',
        payload: {
          callbacks: {
            // eslint-disable-next-line max-len
            createEventCalendar: (eventCalendarTemplate: Partial<EventCalendarModel>, entityOptions: EventCalendarRequestOptions) => saveCalendarAsTemplate({
              eventCalendar, eventCalendarTemplate, dispatch, requestOptions: entityOptions,
            }),
          },
          eventCalendar: { ...eventCalendar, saved_template: true, title: '' },
          selectedScreen: 'CreateNew',
        },
      }),
      deleteEventCalendar: () => deleteEventCalendar({ dispatch, eventCalendar }),
      dragDropEvent: (payload: DragDropEventPaload) => dragDropEvent({ dispatch, entitiesRef, payload }),
      findGroupForEvent: (eventDaysFromStart: number) => findGroupForEvent(eventDaysFromStart, filteredEventGroups),
      saveCalendarAsTemplate: (
        eventCalendarTemplate: {},
        entityOptions?: EventCalendarRequestOptions,
      ) => saveCalendarAsTemplate({
        eventCalendar, eventCalendarTemplate, dispatch, requestOptions: entityOptions,
      }),
      updateEventCalendar: (
        eventCalendarParams: Partial<EventCalendarModel>,
        entityOptions?: EventCalendarRequestOptions,
      ) => (
        updateEventCalendar({
          eventCalendar, eventCalendarParams, dispatch, requestOptions: entityOptions,
        })
      ),
    },
    creating,
    eventCalendar,
    filteredEventGroups,
    filteredEvents,
    isTemplate,
    lastEventGroup,
    nextEventGroup,
    urls: generateUrls(eventCalendar),
  }
}

export default useEventCalendar
