import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'
import {
  Box, Button, FormField, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import useQuote, { useQuoteForm } from '@hooks/useQuote'

const confirmDelete = (deleteQuote, deleteFn) => {
  swal
    .fire({
      title: 'Delete Quote?',
      text: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it.',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        deleteQuote(deleteFn)
      }
    })
}

const CreateOrEditQuoteModal = (props) => {
  const {
    callbacks,
    callbacks: {
      createQuote, deleteQuote, saveQuoteAsTemplate, updateQuote,
    },
    modalKey,
    quote: newQuote,
    showModal,
  } = props

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, `${modalKey}`, {})
  const { callbacks: modalCallbacks, quote: existingQuote, savingAsTemplate } = modalPayload
  const { createQuote: createFn, saveQuoteAsTemplate: saveFn, updateQuote: updateFn } = modalCallbacks || {}

  const quote = existingQuote || newQuote
  const newRecord = !quote.id

  const quotePayload = useQuote(quote)
  const {
    callbacks: { deleteQuote: deleteFn },
    creating,
    deleting,
    isSavedTemplate,
    updating,
  } = quotePayload

  const quoteFormPayload = useQuoteForm(quote)
  const {
    entityState,
    entityState: {
      title,
    },
    handlers,
    saveEnabled,
  } = quoteFormPayload

  const actionFn = newRecord
    ? () => createQuote(entityState, createFn)
    : () => updateQuote(entityState, updateFn)

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        title={newRecord || savingAsTemplate ? 'Create' : 'Edit'}
        titleSecondLine={savingAsTemplate || isSavedTemplate ? 'Template' : 'Quote'}
      />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <FormField direction="column" label="* Title">
            <input name="title" type="text" value={title} {...handlers} />
          </FormField>

          {!savingAsTemplate && !newRecord && (
            <FormField direction="column" label="More Options" marginTop="large">
              <Button
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faTrashAlt} />}
                onClick={() => confirmDelete(deleteQuote, deleteFn)}
                size="medium"
                style={{ marginBottom: '8px' }}
              >
                Delete Quote
              </Button>
            </FormField>
          )}
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={creating || deleting || updating}
          loadingBubbleColor="white"
          onClick={savingAsTemplate ? () => saveQuoteAsTemplate(entityState, saveFn) : actionFn}
          size="large"
        >
          {savingAsTemplate || isSavedTemplate ? 'Save Template' : 'Save Quote'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

CreateOrEditQuoteModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  quote: PropTypes.object,
  showModal: PropTypes.bool,
}

CreateOrEditQuoteModal.defaultProps = {
  modalKey: 'CreateOrEditQuoteModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <CreateOrEditQuoteModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
