import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { Box } from '@campaignhub/suit-theme'

import User from './User'

const ModalUsers = (props) => {
  const { callbacks, disableDeleteForUserIds, userIds } = props

  const { users } = useSelector(reduxState => reduxState.entities)

  const selectedUsers = userIds.map(id => users[id]).filter(u => u)

  return (
    <Box flexDirection="column">
      {selectedUsers.map((user, index) => (
        <User
          key={user.id}
          callbacks={callbacks}
          disableDelete={disableDeleteForUserIds.includes(user.id)}
          isLastUser={selectedUsers.length === index + 1}
          user={user}
        />
      ))}
    </Box>
  )
}

ModalUsers.propTypes = {
  callbacks: PropTypes.object.isRequired,
  disableDeleteForUserIds: PropTypes.array,
  userIds: PropTypes.array.isRequired,
}

ModalUsers.defaultProps = {
  disableDeleteForUserIds: [],
}

export default ModalUsers
