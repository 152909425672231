import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faPencil } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Link, ListItem, Text,
} from '@campaignhub/suit-theme'

import useAttachment from '@hooks/useAttachment'
import { useRelations } from '@hooks/useProject'

const Document = (props) => {
  const { attachment: initAttachment, project } = props

  const { projectType } = useRelations(project)

  const attachmentPayload = useAttachment(initAttachment)
  const {
    callbacks: { editAttachment },
    fileName,
    title,
    urls: { downloadUrl },
  } = attachmentPayload

  const customPayload = {
    filterCustomFieldsBy: { project_type_key: projectType.key },
  }

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        borderBottom: '1px dashed',
        justifyContent: 'space-between',
        padding: 'large',
      }}
      disableHover
    >
      <Box flexDirection="column" marginRight="small" width="75%">
        <Text fontSize="small" lineHeight={1.3} marginBottom="small" variant="ellipsis">
          {title}
        </Text>

        <Text color="faintGrey" fontSize="xsmall" variant="ellipsis">
          {fileName}
        </Text>
      </Box>

      <Box justifyContent="flex-end" alignItems="center" color="faintGrey" fontSize="small" width="fit-content">
        {downloadUrl && (
          <Link greyLink href={downloadUrl} target="_blank">
            <FontAwesomeIcon icon={faDownload} />
          </Link>
        )}

        <Link
          greyLink
          onClick={() => editAttachment(customPayload)}
          textProps={{ fontSize: 'small', marginLeft: 'medium' }}
        >
          <FontAwesomeIcon icon={faPencil} />
        </Link>
      </Box>
    </ListItem>
  )
}

Document.propTypes = {
  attachment: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
}

export default Document
