import { digObject } from '@campaignhub/javascript-utils'

const getCanvasSize = (documentTemplatePage, zoom) => {
  const size = {
    height: digObject(documentTemplatePage, 'height', 0) * zoom,
    width: digObject(documentTemplatePage, 'width', 0) * zoom,
  }

  return size
}

function useBuilderCanvas(options = {}){
  const {
    documentTemplatePage,
    zoom,
  } = options || {}

  return {
    size: getCanvasSize(documentTemplatePage, zoom),
    zoom,
  }
}

export default useBuilderCanvas
