import React from 'react'

import { BlankState } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import BlankStateImageUrl from './assets/no-templates.svg'
import localizedStrings from './localizedStrings'

const TemplateListBlankState = () => {
  const { strings } = useLocalization(localizedStrings)

  return (
    <BlankState imageUrl={BlankStateImageUrl}>
      <BlankState.Title>{strings.title}</BlankState.Title>

      <BlankState.Paragraph>
        {strings.subTitle || "Don't just market to your clients, engage with them."}
      </BlankState.Paragraph>

      <BlankState.Paragraph>
        {strings.paragraph ||
          "To professionally pitch for a seller's business you need to present an informative and comprehensive document outlining your experience, knowledge and suggestions."}
      </BlankState.Paragraph>

      <BlankState.Paragraph>
        {strings.adjustFilters || 'Adjust your filters to return more results.'}
      </BlankState.Paragraph>
    </BlankState>
  )
}

export default TemplateListBlankState
