export type AttachmentModel = {
  cache_key: number,
  id: number,
  attachment_type: string,
  data: {},
  dates:{
    created: {
      date_time_with_timezone: string,
    },
    updated: {
      date_time_with_timezone: string,
    },
  },
  owner_id: number,
  owner_type: string,
  subject_id: number,
  subject_type: string,
}

export type AttachmentRequestOptions = {}

const state = {
  id: null,
  data: {
    description: '',
    title: '',
  },
  owner_id: null,
  owner_type: '',
  subject_id: null,
  subject_type: '',
}

export const requiredFields = [
  { key: 'owner_id' },
  { key: 'owner_type' },
  { key: 'subject_id' },
  { key: 'subject_type' },
]

export default state
