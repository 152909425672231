import { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile, faFileImage } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Columns, DashboardModule, LoadingModule, PageHeader, Tools,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import useAssetLibraries from '@hooks/useAssetLibraries'
import useAssetLibrary from '@hooks/useAssetLibrary'
import useLocalization from '@hooks/useLocalization'
import useVideoModules from '@hooks/useVideoModules'

import VideoModule from '@components/VideoModule'

import ImageLibrariesBlankState from '../ImageLibrariesBlankState'
import ImageLibrary from '../ImageLibrary'
import localizedStrings from './localizedStrings'

const PageContent = (props) => {
  const { brand, organization } = props

  const initImageLibrary = {
    asset_type: 'image',
    owner_id: organization.id,
    owner_type: 'Organization',
  }

  const assetImageLibraryPayload = useAssetLibraries({
    filters: {
      asset_type: 'image',
      organization_id: organization.id,
      brand_id: brand.id,
    },
  })
  const {
    filteredBrandAssetLibraries: filteredBrandImageLibraries,
    filteredOrganizationAssetLibraries: filteredOrganizationImageLibraries,
    hasAssetLibraries: hasImageLibraries,
    hasBrandAssetLibraries: hasBrandImageLibraries,
    hasOrganizationAssetLibraries: hasOrganizationImageLibraries,
    loading,
  } = assetImageLibraryPayload

  const {
    callbacks: { createOrEditAssetLibrary },
    urls: { editAssetLibraryUrl },
  } = useAssetLibrary(initImageLibrary)

  const pageContext = useContext(PageContext)
  const {
    callbacks: { showImportAssetLibrariesModal },
  } = pageContext

  const videoModulePayload = useVideoModules({ key: 'imageLibraries' })
  const { videosPayload } = videoModulePayload

  const { strings } = useLocalization(localizedStrings)

  const title = strings.title ? `${strings.title || 'Image Libraries'} | Engage` : 'Engage'

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <PageHeader
        activeTabBarItemKey="images"
        actionContent={(
          <Button buttonStyle="primaryCreate" onClick={() => createOrEditAssetLibrary()} size="medium">
            {strings.createButton || 'Create Library'}
          </Button>
        )}
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        nestedNavigation
        tabBarItems={[
          {
            href: editAssetLibraryUrl,
            icon: faFileImage,
            key: 'images',
            title: strings.tabTitle || 'Images',
          },
        ]}
        title={strings.title || 'Image Libraries'}
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && !hasImageLibraries && <ImageLibrariesBlankState />}

            {!loading && hasOrganizationImageLibraries && (
              <DashboardModule title={strings.title || 'Image Libraries'}>
                <Box flexDirection="column">
                  {filteredOrganizationImageLibraries.map(imageLibrary => (
                    <ImageLibrary key={imageLibrary.id} imageLibrary={imageLibrary} />
                  ))}
                </Box>
              </DashboardModule>
            )}

            {!loading && hasBrandImageLibraries && (
              <DashboardModule title={strings.brandImageTitle || 'Brand Image Libraries'}>
                <Box flexDirection="column">
                  {filteredBrandImageLibraries.map(imageLibrary => (
                    <ImageLibrary key={imageLibrary.id} imageLibrary={imageLibrary} />
                  ))}
                </Box>
              </DashboardModule>
            )}
          </Columns.Main>

          <Columns.Sidebar>
            <Tools title={strings.tools || 'Tools'}>
              <Button
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faFile} />}
                marginBottom="medium"
                onClick={() => showImportAssetLibrariesModal()}
                size="medium"
              >
                {strings.importImages || 'Import Image Libraries'}
              </Button>
            </Tools>

            <VideoModule videosPayload={videosPayload} />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

PageContent.propTypes = {
  organization: PropTypes.object.isRequired,
  brand: PropTypes.object.isRequired,
}

export default PageContent
