import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { Box, Button } from '@campaignhub/suit-theme'

import { getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'

import SaveFormModule from '@components/SaveFormModule'

import useEvent from '@hooks/useEvent'
import useEventGroup from '@hooks/useEventGroup'
import useLocalization from '@hooks/useLocalization'

import EventGroup from '../EventGroup'

import localizedStrings from './localizedStrings'

const EventCalendar = (props) => {
  const {
    baseModalOpen,
    calendarBuilderPayload: {
      eventCalendarPayload: {
        callbacks: { dragDropEvent },
        filteredEventGroups,
        isTemplate,
        nextEventGroup,
      },
      isMobile,
    },
  } = props

  const { event_id } = parsePermittedQueryParams(getQueryParamsFromHash(), ['event_id'])

  const eventPayload = useEvent({ id: event_id })
  const { callbacks: { createOrEditEvent } } = eventPayload

  // Need this so we can set the modal to start after last event group
  const eventGroupPayload = useEventGroup(nextEventGroup)
  const { callbacks: { editEventGroup } } = eventGroupPayload

  useEffect(() => {
    if (!isMobile && event_id && baseModalOpen){
      createOrEditEvent()
    }
  }, [isMobile, event_id, baseModalOpen])

  const { strings } = useLocalization(localizedStrings)

  return (
    <Box flexDirection="column" padding="large">
      {filteredEventGroups.map(eventGroup => (
        <EventGroup
          callbacks={{ dragDropEvent }}
          eventGroup={eventGroup}
          isMobile={isMobile}
          isTemplate={isTemplate}
          key={eventGroup.id}
        />
      ))}

      <SaveFormModule>
        <Button
          buttonStyle="secondaryEdit"
          disabled={false}
          loading={false}
          onClick={editEventGroup}
          size="medium"
          style={{ width: '100%' }}
        >
          {strings.button || 'Add Another Group'}
        </Button>
      </SaveFormModule>
    </Box>
  )
}

EventCalendar.propTypes = {
  baseModalOpen: PropTypes.bool.isRequired,
  calendarBuilderPayload: PropTypes.shape({
    eventCalendarPayload: PropTypes.shape({
      callbacks: PropTypes.shape({
        dragDropEvent: PropTypes.func.isRequired,
      }).isRequired,
      filteredEventGroups: PropTypes.array.isRequired,
      isTemplate: PropTypes.bool,
      nextEventGroup: PropTypes.object.isRequired,
    }).isRequired,
    isMobile: PropTypes.bool,
  }).isRequired,
}

export default EventCalendar
