import marketingQuotesUrl from './marketingQuotes.svg'
import savesYouTimeUrl from './savesYouTime.svg'
import setUpUrl from './setUp.svg'

const icons = {
  marketingQuotes: marketingQuotesUrl,
  savesYouTime: savesYouTimeUrl,
  setUp: setUpUrl,
}

export default icons
