import PropTypes from 'prop-types'

import { ModalContext } from '@campaignhub/suit-theme'
import { useModals, useSetState } from '@campaignhub/react-hooks'

import ManageFormResponseModal from '@modals/ManageFormResponseModal'

import defaultRequestOptions from '@sections/Client/packs/Project/defaultRequestOptions'

import useReduxAction from '@hooks/useReduxAction'

import PageContext from '@contexts/pageContext'

import PageContent from './components/PageContent'

const callbacks = (component, setState) => {
  const componentCallbacks = {
    ManageFormResponseModal: {
      closeModal: () => setState({ showManageFormResponseModal: false }),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  showManageFormResponseModal: false,
}

const FormResponses = (props) => {
  const { projectId } = props

  const [state, setState] = useSetState(defaultState)
  const { showManageFormResponseModal } = state

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const entityKey = `Project${projectId}`

  useReduxAction(
    'digitalPages',
    'loadDigitalPages',
    {
      ...defaultRequestOptions.digitalPage,
      ...defaultRequestOptions.digitalTemplate,
      entityKey,
      subject_id: projectId,
      subject_type: 'Project',
    },
    [projectId],
    {
      shouldPerformFn: ({ loadedForKeys }) => projectId && !loadedForKeys.includes(entityKey),
    },
  )

  const pageContext = {
    callbacks: {
      showManageFormResponseModal: (payload) => {
        setModalData('ManageFormResponseModal', payload)
        setState({ showManageFormResponseModal: true })
      },
    },
  }

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent projectId={projectId} />

        <ManageFormResponseModal
          callbacks={callbacks('ManageFormResponseModal', setState)}
          project={{ id: projectId }}
          showModal={showManageFormResponseModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

FormResponses.propTypes = {
  projectId: PropTypes.number,
}

export default FormResponses
