import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@campaignhub/suit-theme'

import DigitalTemplateContext from '@contexts/digitalTemplateContext'

import useDigitalTemplate from '@hooks/useDigitalTemplate'
import useDigitalTemplatePage from '@hooks/useDigitalTemplatePage'

import SectionGroup from '@components/digitalRenderer/components/SectionGroup'

const Canvas = (props) => {
  const {
    callbacks,
    digitalCustomizerPayload: {
      callbacks: customizerCallbacks,
      currentTemplatePage,
      digitalTemplate,
      explodeSections,
      sourceTemplate,
      targetDevice,
    },
  } = props

  const digitalTemplatePayload = useDigitalTemplate(digitalTemplate)
  const { componentStyles: digitalTemplateComponentStyles } = digitalTemplatePayload

  const sourceTemplatePayload = useDigitalTemplate(sourceTemplate)
  const { componentStyles: sourceTemplateComponentStyles } = sourceTemplatePayload

  const componentStyles = [...digitalTemplateComponentStyles, ...sourceTemplateComponentStyles]

  const digitalTemplatePagePayload = useDigitalTemplatePage(currentTemplatePage)
  const { sortedSectionGroups } = digitalTemplatePagePayload

  return (
    <DigitalTemplateContext.Provider
      value={{ componentStyles, targetDevice }}
    >
      <Box
        alignSelf="center"
        backgroundColor={explodeSections ? 'transparent' : 'white'}
        border={explodeSections ? 'none' : '1px solid'}
        borderColor="lineColor"
        flexDirection="column"
        lineHeight="1.5"
        style={{ boxShadow: explodeSections ? 'none' : '2px 2px 8px rgba(0, 0, 0, 0.05)' }}
        width="100%"
      >
        {sortedSectionGroups.map(sectionGroup => (
          <SectionGroup
            callbacks={{ ...callbacks, ...customizerCallbacks }}
            sectionGroup={sectionGroup}
            key={sectionGroup.id}
          />
        ))}
      </Box>
    </DigitalTemplateContext.Provider>
  )
}

Canvas.propTypes = {
  callbacks: PropTypes.shape({
    updateCustomData: PropTypes.func.isRequired,
  }).isRequired,
  digitalCustomizerPayload: PropTypes.shape({
    callbacks: PropTypes.shape({
      selectCanvasItem: PropTypes.func,
    }).isRequired,
    currentTemplatePage: PropTypes.object,
    digitalTemplate: PropTypes.object.isRequired,
    explodeSections: PropTypes.bool,
    sourceTemplate: PropTypes.object,
    targetDevice: PropTypes.string,
  }).isRequired,
}

export default Canvas
