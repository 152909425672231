export default {
  default: {
    addCaseStudy: 'Add Case Study',
    archivedTitle: 'Archived',
    createButton: 'Create',
    currentTitle: 'Current',
    importCaseStudies: 'Import Case Studies',
    title: 'Case Studies',
    tools: 'Tools',
    VideoModule: {
      title: 'Case Studies',
      paragraph: 'What is a case study and why should you include these in your proposal.',
    },
    filters: {
      address: 'Filter by Address',
      defaultSelectLabel: 'All',
      user: 'User',
    },
    filtersTitle: 'Filters',
  },
}
