export default {
  default: {
    buttons: {
      createNewLink: 'Create New Link',
    },
    formFieldLabels: {
      moreOptions: 'More Options',
    },
    sideBarModalHeader: {
      title: 'Share',
      titleSecondLine: 'Links',
    },
    VideoModule: {
      title: 'Sending Your Proposal',
      paragraph: 'How to Send Your Proposal from the Engage platform via email and SMS.',
    },
  },
}
