import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faUser } from '@fortawesome/pro-light-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'

import {
  Box, Button, FormField, InputSearch, SidebarModal, UserProfileImage,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import useEntityDefaultIntegrationPlatforms from './hooks/useEntityDefaultIntegrationPlatforms'
import useManageEntityDefaultIntegrationPlatforms from './hooks/useManageEntityDefaultIntegrationPlatforms'

import SelectedEntity from './components/SelectedEntity'

import localizedStrings from './localizedStrings'

const mapResults = (organizations, users, images, strings) => {
  const groups = []

  if (organizations){
    const organizationGroup = {
      key: 'organization',
      title: strings.mapResults?.organizationTitle || 'ORGANISATION',
      items: Object.values(organizations).map((organization) => {
        const image = images[organization.default_image_id] || {}
        const imageUrl = digObject(image, 'sizes.thumb_90x90')

        return {
          ...organization,
          iconLeft: (
            <UserProfileImage imageUrl={imageUrl}>
              <FontAwesomeIcon icon={faBuilding} />
            </UserProfileImage>
          ),
          secondaryText: organization.email || strings.mapResults?.noEmail || 'No Email',
          text: organization.title,
          key: `Organization-${organization.id}`,
        }
      }),
    }

    groups.push(organizationGroup)
  }

  if (users){
    const userGroup = {
      key: 'users',
      title: strings.mapResults?.usersTitle || 'USERS',
      items: Object.values(users).map((user) => {
        const image = images[user.default_image_id] || {}
        const imageUrl = digObject(image, 'sizes.thumb_90x90', '')

        return {
          ...user,
          iconLeft: (
            <UserProfileImage imageUrl={imageUrl}>
              <FontAwesomeIcon icon={faUser} />
            </UserProfileImage>
          ),
          id: user.id,
          key: `User-${user.id}`,
          imageUrl,
          secondaryText: user.mobile,
          text: user.full_name,
        }
      }),
    }

    groups.push(userGroup)
  }

  return { groups }
}

const ManageEntityDefaultIntegrationPlatformsModal = (props) => {
  const { callbacks, modalKey, showModal } = props
  const { closeModal, updateEntity } = callbacks || {}

  const payload = useManageEntityDefaultIntegrationPlatforms()
  const {
    callbacks: { setState },
    images,
    organizations,
    selectedEntity,
    updating,
    users,
  } = payload

  const {
    availableFeatures,
    callbacks: { updateEntity: updateFn },
    formPayload,
    groupedIntegrationPlatformsByFeature,
  } = useEntityDefaultIntegrationPlatforms({ entityType: selectedEntity.type, entityId: selectedEntity.id })

  const { entityState, handlers } = formPayload

  const { strings } = useLocalization(localizedStrings)

  const entityDefaultIntegrationPlatformsPayload = {
    callbacks: {
      action: updateFn,
      afterAction: closeModal,
    },
    entityParams: entityState,
    toastText: strings.toast?.defaultIntegrationUpdated || 'Default Integration Updated Successfully',
  }
  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.header.title || 'Set Default'}
        titleSecondLine={strings.header?.titleSecondLine || 'Integrations'}
      />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          {!selectedEntity.id && (
            <FormField label={strings.options?.defaultEntity || 'Set Default For'}>
              <InputSearch
                callbacks={{ selectItem: item => setState({ selectedEntity: item }) }}
                closeOnSelect
                placeholder={strings.options?.searchUserPlaceholder || 'Search for a user or organisation'}
                results={mapResults(organizations, users, images, strings)}
                showOnFocus
              />
            </FormField>
          )}

          {selectedEntity.id && (
            <FormField label={strings.options.defaultEntity || 'Set Default For'}>
              <SelectedEntity
                selectedEntity={selectedEntity}
                callbacks={{ deselectEntity: () => setState({ selectedEntity: {} }) }}
              />
            </FormField>
          )}

          {selectedEntity.id
            && availableFeatures.map((feature) => {
              const { key, label } = feature
              const platforms = groupedIntegrationPlatformsByFeature[key] || []

              return (
                <FormField direction="column" label={label} key={key} marginTop="large">
                  <select data-validate-field-on="change" name={key} value={entityState[key]} {...handlers}>
                    <option value="">{strings.options?.placeholder || 'No Default Set'}</option>
                    {platforms.map(platform => (
                      <option key={platform.key} value={platform.key}>
                        {platform.label}
                      </option>
                    ))}
                  </select>
                </FormField>
              )
            })}
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          loading={updating}
          onClick={() => updateEntity(entityDefaultIntegrationPlatformsPayload)}
          size="large"
        >
          {strings.footer?.button || 'Save'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

ManageEntityDefaultIntegrationPlatformsModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

ManageEntityDefaultIntegrationPlatformsModal.defaultProps = {
  modalKey: 'ManageEntityDefaultIntegrationPlatformsModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <ManageEntityDefaultIntegrationPlatformsModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
