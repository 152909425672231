import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers } from '@fortawesome/pro-light-svg-icons'

import {
  Box, ListItem, Text,
} from '@campaignhub/suit-theme'

import useContact from '@hooks/useContact'
import useLocalization from '@hooks/useLocalization'

import { formatListToString } from '@functions/string'

import localizedStrings from './localizedStrings'

const Contact = (props) => {
  const { contact: initContact } = props

  const contactPayload = useContact(initContact)
  const {
    callbacks: { createOrEditContact },
    contact,
    readAccessUsers,
    userType,
    writeAccessUsers,
  } = contactPayload

  const { email, full_name, mobile } = contact || {}

  const contactUserList = [...writeAccessUsers, ...readAccessUsers]

  const { strings } = useLocalization(localizedStrings)

  const contactUserListString = formatListToString(contactUserList, 'full_name', strings.othersText)

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        as: 'a',
        borderBottom: '1px dashed',
        padding: 'large',
        color: 'inherit',
      }}
      onClick={() => createOrEditContact({})}
      showAngleIcon
    >
      <Box flexDirection="column" maxWidth="50%">
        <Text fontSize="small" marginBottom="small" variant="ellipsis">
          {full_name}
        </Text>

        <Text color="faintGrey" fontSize="xsmall" marginBottom="medium" variant="ellipsis">
          {email}
        </Text>

        {!!contactUserListString.length && (
        <Text color="bodyFontLightColor" fontSize="xsmall">
          <FontAwesomeIcon icon={faUsers} style={{ marginRight: 5 }} />
          {contactUserListString}
        </Text>
        )}

      </Box>

      <Box flexDirection="column" justifyContent="flex-end" marginLeft="auto" textAlign="right">
        <Text fontSize="small">
          {mobile}
        </Text>

        <Text color="faintGrey" fontSize="xsmall" marginTop="small">
          {userType.title}
        </Text>
      </Box>
    </ListItem>
  )
}
Contact.propTypes = {
  contact: PropTypes.object.isRequired,
}

export default Contact
