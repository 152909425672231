type Colors = Record<string, string>

export function statusColor(statusKey: string, fallbackColor: string = 'black') {
  const colors: Colors = {
    awaiting_qc: '#e78d2d',
    awaiting_review: '#e78d2d',
    accepted: '#8c9fbf',
    approved: '#8c9fbf',
    pending: '#e78d2d',
    processed: '#d0c6b1',
    processing: '#b69deb',
    rejected: '#e2001a',
    sent: '#d0c6b1',
    urgent: '#e2001a',
    completed: '#43ac6a',
    voided: '#DD6B55',
  }

  return colors[statusKey] || fallbackColor
}

export default statusColor
