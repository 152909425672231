import React from 'react'
import PropTypes from 'prop-types'

import { Box, ListItem, Text } from '@campaignhub/suit-theme'

import useAddress from '@hooks/useAddress'

const Address = (props) => {
  const { address } = props

  const {
    full_address,
    postcode,
    state_name,
    title,
  } = address

  const {
    callbacks: { createOrEditAddress },
  } = useAddress(address)

  return (
    <ListItem
      boxProps={{ alignItems: 'center', borderBottom: '1px dashed', padding: 'large' }}
      onClick={() => createOrEditAddress()}
      showAngleIcon
    >
      <Box flexDirection="column">
        {title && (
          <Text fontSize="small" marginBottom="medium">
            {title}
          </Text>
        )}
        <Text color="bodyFontLightColor" fontSize="xsmall">
          {full_address} {postcode} {state_name}
        </Text>
      </Box>
    </ListItem>
  )
}

Address.propTypes = {
  address: PropTypes.object.isRequired,
}

export default Address
