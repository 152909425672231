export default {
  default: {
    buttons: {
      create: 'Create Proposal',
    },
    sideBarModalHeader: {
      title: 'Duplicate',
      titleSecondLine: 'Digital Page',
    },
    formFieldLabels: {
      searchProject: 'Search Project',
      searchResultTitle: 'PROJECTS',
      moreOptions: 'More Options',
    },
    blankStateTitle: 'No Digital Pages',
    blankStateParagraph: 'Try adjusting your filters to return more results.',
    projectSearchPlaceholder: 'Search for a project',
    selectDigitalPages: 'Select Digital Page',
    selectedDigitalPages: 'Selected Digital Page',
    selectedEntities: 'Select Items to Duplicate',
    ToggleAll: 'Toggle All',
  },
}
