import React from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Link, Tag as TagItem, Text,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'
import useTag from '@hooks/useTag'

import localizedStrings from './localizedStrings'

const confirmDeleteTag = (deleteFn, strings) => {
  swal
    .fire({
      title: strings.swal?.title || 'Delete Tag?',
      text: strings.swal?.text || 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: strings.swal?.confirmButtonText || 'Yes, delete it.',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        deleteFn()
      }
    })
}

const Tag = (props) => {
  const {
    tag,
    tag: { title },
  } = props

  const {
    callbacks: { deleteTag: deleteFn },
  } = useTag(tag)

  const { strings } = useLocalization(localizedStrings)

  return (
    <Box alignItems="center" justifyContent="space-between">
      <TagItem boxProps={{ alignItems: 'center', flexWrap: 'wrap' }}>
        <Text>{title}</Text>
      </TagItem>

      <Link
        onClick={() => confirmDeleteTag(deleteFn, strings)}
        textProps={{ fontSize: 'small', marginRight: 'small' }}
        tabIndex="0"
      >
        <FontAwesomeIcon color="red" fontSize="large" icon={faTrashAlt} />
      </Link>
    </Box>
  )
}

Tag.propTypes = {
  tag: PropTypes.object,
}

export default Tag
