import React from 'react'
import PropTypes from 'prop-types'

import { digObject } from '@campaignhub/javascript-utils'
import { Image } from '@campaignhub/suit-theme'

const PagePreviewImage = (props) => {
  const { image } = props

  const previewImageUrl = digObject(image, 'sizes.thumb_2000x1500')

  return (
    <Image
      backgroundSize="100% 100%"
      boxProps={{
        bottom: 0,
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        zIndex: 0,
      }}
      url={previewImageUrl}
    />
  )
}

PagePreviewImage.propTypes = {
  image: PropTypes.object.isRequired,
}

export default PagePreviewImage
