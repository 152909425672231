export default {
  default: {
    swal: {
      title: 'Delete Calendar?',
      text: 'Are you sure?',
      confirmButtonText: 'Yes, delete it',
    },
    buttonAddEvent: 'Add Event',
    buttonAddGroup: 'Add Group',
    buttonDeleteCalendar: 'Delete Calendar',
    buttonDeleteTemplate: 'Delete Template',
    buttonSaveAsTemplate: 'Save as Template',
    buttonSaveCalendar: 'Save Calendar',
    buttonSaveTemplate: 'Save Template',
    labelDescription: 'Description',
    labelEvents: 'Events',
    labelMoreOptions: 'More Options',
    labelTitle: '* Title',
    titleManage: 'Manage',
    titleSecondLine: 'Calendar',
    labelUsers: 'Users',
  },
}
