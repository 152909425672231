import React from 'react'
import PropTypes from 'prop-types'

const OrganizationIcon = (props) => {
  const { fillColor, height, width } = props

  return (
    <svg width={width} height={height} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.2338 11.2241C23.535 11.4441 23.94 11.4442 24.2413 11.2241C24.9374 10.7157 26.5256 9.3963 27.183 7.53589C27.2433 7.36525 27.2958 7.19006 27.339 7.01053C27.3959 6.77458 27.2506 6.53721 27.0147 6.48038C26.847 6.4402 26.6786 6.50178 26.5748 6.6254C26.5325 6.67577 26.501 6.73645 26.4845 6.80475C26.0297 8.69341 24.2864 10.1005 23.7375 10.5037C23.047 9.9967 20.8789 8.21645 20.8789 5.88043C20.8789 4.57603 21.7571 3.47287 22.9533 3.13117C23.2026 3.05996 23.4656 3.02182 23.7375 3.02182C24.9757 3.02182 26.0672 3.81143 26.4536 4.98659C26.5295 5.21715 26.7778 5.34266 27.0084 5.26678C27.239 5.19096 27.3644 4.94258 27.2886 4.71202C26.7832 3.17528 25.3562 2.14286 23.7375 2.14286C23.4458 2.14286 23.1619 2.17643 22.8894 2.23991C22.5857 2.31063 22.2961 2.41849 22.0257 2.55841C20.8235 3.18041 20 4.43604 20 5.88038C20 8.64735 22.4751 10.6701 23.2338 11.2241Z" fill={fillColor} />
      <path d="M23.7374 3.80197C22.5912 3.80197 21.6588 4.73443 21.6588 5.88064C21.6588 7.02685 22.5912 7.95931 23.7374 7.95931C24.8836 7.95931 25.816 7.02685 25.816 5.88064C25.816 5.80367 25.8118 5.72767 25.8037 5.65285C25.6949 4.6591 24.8828 3.87376 23.8773 3.80662C23.831 3.80354 23.7844 3.80197 23.7374 3.80197ZM23.7374 7.08035C23.0759 7.08035 22.5377 6.5421 22.5377 5.88058C22.5377 5.21906 23.0759 4.68081 23.7374 4.68081C24.3989 4.68081 24.9371 5.21906 24.9371 5.88058C24.9371 6.5421 24.3989 7.08035 23.7374 7.08035Z" fill={fillColor} />
      <path d="M7.098 4.62876H5.4238C5.31196 4.62876 5.20535 4.65158 5.10838 4.69281C4.91538 4.77488 4.76059 4.92988 4.67879 5.12302C4.63784 5.2197 4.61518 5.32594 4.61518 5.43738V7.11158C4.61518 7.55746 4.97792 7.9202 5.4238 7.9202H7.098C7.54388 7.9202 7.90662 7.55746 7.90662 7.11158V5.43738C7.90662 4.9915 7.54388 4.62876 7.098 4.62876ZM7.09049 7.10413H5.43125V5.44489H7.09049V7.10413Z" fill={fillColor} />
      <path d="M11.2606 4.62876H9.58632C9.14049 4.62876 8.77775 4.9915 8.77775 5.43738V7.11158C8.77775 7.55746 9.14049 7.9202 9.58632 7.9202H11.2606C11.7065 7.9202 12.0692 7.55746 12.0692 7.11158V5.43738C12.0692 4.9915 11.7065 4.62876 11.2606 4.62876ZM11.2531 7.10413H9.59383V5.44489H11.2531V7.10413Z" fill={fillColor} />
      <path d="M13.7488 7.92025H15.423C15.8689 7.92025 16.2317 7.55751 16.2317 7.11164V5.43738C16.2317 4.9915 15.8689 4.62876 15.423 4.62876H13.7488C13.3029 4.62876 12.9402 4.9915 12.9402 5.43738V7.11158C12.9402 7.55751 13.3029 7.92025 13.7488 7.92025ZM13.7563 5.44489H15.4156V7.10413H13.7563V5.44489Z" fill={fillColor} />
      <path d="M7.098 9.72275H5.4238C4.97792 9.72275 4.61518 10.0855 4.61518 10.5314V12.2056C4.61518 12.6515 4.97792 13.0142 5.4238 13.0142H7.098C7.54388 13.0142 7.90662 12.6515 7.90662 12.2056V10.5314C7.90662 10.0855 7.54388 9.72275 7.098 9.72275ZM7.09049 12.1981H5.43125V10.5389H7.09049V12.1981Z" fill={fillColor} />
      <path d="M11.2606 9.72275H9.58632C9.14049 9.72275 8.77775 10.0855 8.77775 10.5314V12.2056C8.77775 12.6515 9.14049 13.0142 9.58632 13.0142H11.2606C11.7065 13.0142 12.0692 12.6515 12.0692 12.2056V10.5314C12.0692 10.0855 11.7065 9.72275 11.2606 9.72275ZM11.2531 12.1981H9.59383V10.5389H11.2531V12.1981Z" fill={fillColor} />
      <path d="M13.7488 13.0142H15.423C15.8689 13.0142 16.2317 12.6515 16.2317 12.2056V10.5314C16.2317 10.0855 15.8689 9.72275 15.423 9.72275H13.7488C13.3029 9.72275 12.9402 10.0855 12.9402 10.5314V12.2056C12.9402 12.6515 13.3029 13.0142 13.7488 13.0142ZM13.7563 10.5388H15.4156V12.1981H13.7563V10.5388Z" fill={fillColor} />
      <path d="M7.098 14.8167H5.4238C4.97792 14.8167 4.61518 15.1795 4.61518 15.6254V17.2996C4.61518 17.7454 4.97792 18.1082 5.4238 18.1082H7.098C7.54388 18.1082 7.90662 17.7454 7.90662 17.2996V15.6254C7.90662 15.1795 7.54388 14.8167 7.098 14.8167ZM7.09049 17.2921H5.43125V15.6329H7.09049V17.2921Z" fill={fillColor} />
      <path d="M11.2606 14.8167H9.58632C9.14049 14.8167 8.77775 15.1795 8.77775 15.6254V17.2996C8.77775 17.7454 9.14049 18.1082 9.58632 18.1082H11.2606C11.7065 18.1082 12.0692 17.7454 12.0692 17.2996V15.6254C12.0692 15.1795 11.7065 14.8167 11.2606 14.8167ZM11.2531 17.2921H9.59383V15.6329H11.2531V17.2921Z" fill={fillColor} />
      <path d="M7.098 19.9107H5.4238C4.97792 19.9107 4.61518 20.2735 4.61518 20.7193V22.3936C4.61518 22.8394 4.97792 23.2022 5.4238 23.2022H7.098C7.54388 23.2022 7.90662 22.8394 7.90662 22.3936V20.7193C7.90662 20.2735 7.54388 19.9107 7.098 19.9107ZM7.09049 22.3861H5.43125V20.7269H7.09049V22.3861Z" fill={fillColor} />
      <path d="M11.2606 19.9107H9.58632C9.14049 19.9107 8.77775 20.2735 8.77775 20.7193V22.3936C8.77775 22.8394 9.14049 23.2022 9.58632 23.2022H11.2606C11.7065 23.2022 12.0692 22.8394 12.0692 22.3936V20.7193C12.0692 20.2735 11.7065 19.9107 11.2606 19.9107ZM11.2531 22.3861H9.59383V20.7269H11.2531V22.3861Z" fill={fillColor} />
      <path d="M15.4132 18.617H16.9987C17.3832 18.617 17.696 18.3042 17.696 17.9198V16.3745C17.696 15.99 17.3832 15.6772 16.9987 15.6772H15.4132C15.0287 15.6772 14.7159 15.99 14.7159 16.3745V17.9198C14.7159 18.3042 15.0287 18.617 15.4132 18.617ZM15.532 16.4933H16.8799V17.8009H15.532V16.4933Z" fill={fillColor} />
      <path d="M15.4132 21.9528H16.9987C17.3832 21.9528 17.696 21.64 17.696 21.2555V19.7103C17.696 19.3258 17.3832 19.013 16.9987 19.013H15.4132C15.0287 19.013 14.7159 19.3258 14.7159 19.7103V21.2555C14.7159 21.64 15.0287 21.9528 15.4132 21.9528ZM15.532 19.8291H16.8799V21.1367H15.532V19.8291Z" fill={fillColor} />
      <path d="M15.4132 25.2883H16.9987C17.3832 25.2883 17.696 24.9755 17.696 24.591V23.0457C17.696 22.6612 17.3832 22.3484 16.9987 22.3484H15.4132C15.0287 22.3484 14.7159 22.6612 14.7159 23.0457V24.591C14.7159 24.9755 15.0287 25.2883 15.4132 25.2883ZM15.532 23.1645H16.8799V24.4722H15.532V23.1645Z" fill={fillColor} />
      <path d="M19.0338 18.617H20.6194C21.0039 18.617 21.3167 18.3042 21.3167 17.9198V16.3745C21.3167 15.99 21.0039 15.6772 20.6194 15.6772H19.0338C18.6493 15.6772 18.3365 15.99 18.3365 16.3745V17.9198C18.3365 18.3042 18.6493 18.617 19.0338 18.617ZM19.1526 16.4933H20.5005V17.8009H19.1526V16.4933Z" fill={fillColor} />
      <path d="M19.0338 21.9528H20.6194C21.0039 21.9528 21.3167 21.64 21.3167 21.2555V19.7103C21.3167 19.3258 21.0039 19.013 20.6194 19.013H19.0338C18.6493 19.013 18.3365 19.3258 18.3365 19.7103V21.2555C18.3365 21.64 18.6493 21.9528 19.0338 21.9528ZM19.1526 19.8291H20.5005V21.1367H19.1526V19.8291Z" fill={fillColor} />
      <path d="M19.0338 25.2883H20.6194C21.0039 25.2883 21.3167 24.9755 21.3167 24.591V23.0457C21.3167 22.6612 21.0039 22.3484 20.6194 22.3484H19.0338C18.6493 22.3484 18.3365 22.6612 18.3365 23.0457V24.591C18.3365 24.9755 18.6493 25.2883 19.0338 25.2883ZM19.1526 23.1645H20.5005V24.4722H19.1526V23.1645Z" fill={fillColor} />
      <path d="M22.6544 18.617H24.2399C24.6244 18.617 24.9372 18.3042 24.9372 17.9198V16.3745C24.9372 15.99 24.6244 15.6772 24.2399 15.6772H22.6544C22.2699 15.6772 21.9571 15.99 21.9571 16.3745V17.9198C21.9571 18.3042 22.2699 18.617 22.6544 18.617ZM22.7732 16.4933H24.1211V17.8009H22.7732V16.4933Z" fill={fillColor} />
      <path d="M22.6544 21.9528H24.2399C24.6244 21.9528 24.9372 21.64 24.9372 21.2555V19.7103C24.9372 19.3258 24.6244 19.013 24.2399 19.013H22.6544C22.2699 19.013 21.9571 19.3258 21.9571 19.7103V21.2555C21.9571 21.64 22.2699 21.9528 22.6544 21.9528ZM22.7732 19.8291H24.1211V21.1367H22.7732V19.8291Z" fill={fillColor} />
      <path d="M22.6544 25.2883H24.2399C24.6244 25.2883 24.9372 24.9755 24.9372 24.591V23.0457C24.9372 22.6612 24.6244 22.3484 24.2399 22.3484H22.6544C22.2699 22.3484 21.9571 22.6612 21.9571 23.0457V24.591C21.9571 24.9755 22.2699 25.2883 22.6544 25.2883ZM22.7732 23.1645H24.1211V24.4722H22.7732V23.1645Z" fill={fillColor} />
      <path d="M25.8969 24.2384C26.1848 23.8991 26.4573 23.5461 26.713 23.1806V14.5426C26.713 13.9722 26.2489 13.5082 25.6785 13.5082H18.2741V3.52728C18.2741 2.76393 17.653 2.14286 16.8897 2.14286H8.76554C8.26297 2.38701 7.77686 2.65975 7.30934 2.95898H16.8896C17.203 2.95898 17.4579 3.21394 17.4579 3.52728V13.5082H13.9746C13.4042 13.5082 12.9401 13.9722 12.9401 14.5426V23.9271C12.9401 24.1526 13.1227 24.3352 13.3482 24.3352C13.5736 24.3352 13.7562 24.1526 13.7562 23.9271V14.5426C13.7562 14.4223 13.8542 14.3243 13.9746 14.3243H25.6785C25.7989 14.3243 25.8969 14.4223 25.8969 14.5426V24.2384Z" fill={fillColor} />
      <path d="M13.7563 27.0902V25.8349C13.7563 25.6095 13.5736 25.4268 13.3482 25.4268C13.1228 25.4268 12.9401 25.6095 12.9401 25.8349V27.0902H7.38679C7.86232 27.3903 8.35679 27.6631 8.868 27.9064H21.132C21.6432 27.6631 22.1377 27.3903 22.6132 27.0902H13.7563Z" fill={fillColor} />
      <path d="M26.713 24.3713C28.7698 21.8039 30 18.5456 30 15C30 13.19 29.6794 11.4548 29.0919 9.84824C28.9184 9.30359 28.5 8.28571 28.2143 8.57143C28.0022 8.78347 27.9708 8.95116 27.9919 9.05088C28.0038 9.07695 28.0157 9.10306 28.0275 9.12921C28.8358 10.9201 29.2857 12.9075 29.2857 15C29.2857 18.0428 28.3344 20.8634 26.713 23.1806V24.3713Z" fill={fillColor} />
      <path d="M15 0C6.71573 0 0 6.71573 0 15C0 19.9611 2.40845 24.3596 6.1199 27.0902L7.35182 27.9064C8.21007 28.4161 9.1235 28.8426 10.081 29.1748C11.622 29.7095 13.2771 30 15 30C16.7229 30 18.378 29.7095 19.919 29.1748C21.3502 28.6782 22.6829 27.971 23.8801 27.0902H22.6132C22.1377 27.3903 21.6432 27.6631 21.132 27.9064C19.274 28.7908 17.1948 29.2857 15 29.2857C12.8052 29.2857 10.7261 28.7908 8.868 27.9064C8.35679 27.6631 7.86232 27.3903 7.38679 27.0902C5.82345 26.1037 4.46477 24.8224 3.38884 23.3243C3.09523 22.9155 2.82268 22.4905 2.57277 22.051C1.38988 19.9706 0.714286 17.5642 0.714286 15C0.714286 12.4358 1.38988 10.0294 2.57277 7.94901V22.051C2.82268 22.4905 3.09523 22.9155 3.38884 23.3243V6.67572C3.78229 6.12789 4.21354 5.60905 4.67879 5.12302C4.76059 4.92988 4.91538 4.77488 5.10838 4.69281C5.78216 4.04604 6.51921 3.46469 7.30934 2.95898C7.77686 2.65975 8.26297 2.38701 8.76554 2.14286C10.6495 1.22763 12.7648 0.714286 15 0.714286C17.5538 0.714286 19.9511 1.38441 22.0257 2.55841C22.2961 2.41849 22.5857 2.31063 22.8894 2.23991C20.5974 0.819814 17.8944 0 15 0Z" fill={fillColor} />
      <path d="M23.8801 27.0902C24.6048 26.5571 25.2798 25.9603 25.8969 25.3082V24.2384C24.9557 25.3474 23.8484 26.3108 22.6132 27.0902H23.8801Z" fill={fillColor} />
      <path d="M25.8969 25.3082L26.713 24.3713V23.1806C26.4573 23.5461 26.1848 23.8991 25.8969 24.2384V25.3082Z" fill={fillColor} />
      <path fillRule="evenodd" clipRule="evenodd" d="M27.9919 9.05088C28.0038 9.07695 28.0157 9.10306 28.0275 9.12921C28.0129 9.11197 27.9992 9.08553 27.9919 9.05088Z" fill={fillColor} />
    </svg>

  )
}

OrganizationIcon.propTypes = {
  fillColor: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
}

OrganizationIcon.defaultProps = {
  fillColor: '#000000',
  height: 40,
  width: 40,
}

export default OrganizationIcon
