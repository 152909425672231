import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTasks, faTh } from '@fortawesome/pro-light-svg-icons'

import {
  DashboardModule, Link,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import BulkSelectBanner from './BulkSelectBanner'
import GridView from './GridView'
import ListView from './ListView'

import localizedStrings from './localizedStrings'

const filterItemTitle = {
  grid: 'Grid View',
  list: 'List View',
}

const Gallery = (props) => {
  const {
    callbacks: { setState },
    galleryView,
    imagesPayload,
  } = props

  const { bulkSelecting, hasImages } = imagesPayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      {galleryView === 'grid' && bulkSelecting && (
        <BulkSelectBanner imagesPayload={imagesPayload} />
      )}

      {hasImages && (
        <DashboardModule
          boxProps={{ marginBottom: 'large' }}
          headerRight={(
            <Link
              onClick={() => {
                setState({ galleryView: galleryView === 'list' ? 'grid' : 'list' })
              }}
              textProps={{ fontSize: 'small' }}
            >
              <FontAwesomeIcon icon={galleryView === 'list' ? faTh : faTasks} />{' '}
              {galleryView === 'list' ? (strings.gridView || 'Grid View') : (strings.listView || 'List View')}
            </Link>
          )}
          title={filterItemTitle[galleryView] || (strings.gridView || 'Grid View')}
        >
          {galleryView === 'grid' && <GridView imagesPayload={imagesPayload} />}
          {galleryView === 'list' && <ListView imagesPayload={imagesPayload} />}
        </DashboardModule>
      )}
    </>
  )
}

Gallery.propTypes = {
  callbacks: PropTypes.shape({
    setState: PropTypes.func.isRequired,
  }).isRequired,
  galleryView: PropTypes.string.isRequired,
  imagesPayload: PropTypes.object.isRequired,
}

export default Gallery
