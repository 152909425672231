import { digObject } from '@campaignhub/javascript-utils'

import { validateRenderConditions } from '@functions/replaceTextShortCodes'

export const shouldRenderPageItem = (conditions, data, options = {}) => {
  const { showWhenEditing } = options || {}
  const editing = digObject(data, 'editing', false)

  if (editing && showWhenEditing){
    return true
  }

  return validateRenderConditions(conditions, data)
}

export default shouldRenderPageItem
