import { useMemo } from 'react'

import { sortArrayBy } from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import sortEntitiesByDate from '@functions/sortEntitiesByDate'

import defaultRequestOptions from '@sections/Client/defaultRequestOptions'

import type { QuoteModel, QuoteRequestOptions } from '@models/types'

const watchEntityKeys = ['quotes']

type QuoteFilters = {
  subjectId?: number,
  subjectType?: string,
}

type UseQuotesOptions = {
  filters?: QuoteFilters,
  limit?: number,
  performHttpRequests?: boolean,
  requestOptions?: QuoteRequestOptions,
}

function useQuotes(options: UseQuotesOptions = {}) {
  const { filters = {} } = options
  const {
    subjectId: filterSubjectId,
    subjectType: filterSubjectType,
  } = filters

  const {
    updatedEntities: { quotes: quotesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { quotes } = useSelector(reduxState => reduxState.entities)

  const filteredQuotes = useMemo(() => {
    const filtered = Object.values(quotes).filter((quote: QuoteModel) => {
      const { subject_id, subject_type } = quote

      const subjectIdMatch = subject_id === filterSubjectId
      const subjectTypeMatch = subject_type === filterSubjectType

      return subjectIdMatch && subjectTypeMatch
    })

    if (options.limit){
      return sortEntitiesByDate(filtered, 'desc', 'updated').slice(0, limit)
    }

    return sortArrayBy(filtered, 'asc', 'title')
  }, [quotesUpdatedAt, JSON.stringify(filters)])

  const filteredQuotesCount = filteredQuotes.length
  const hasFilteredQuotes = !!filteredQuotesCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredQuotesCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingQuotes } = useReduxAction(
    'quotes',
    'loadQuotes',
    {
      ...filtersWithOffset,
      ...defaultRequestOptions.quote,
      subject_id: filterSubjectId,
      subject_type: filterSubjectType,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: (entityReducer) => {
        const { errors, loaded, loading } = entityReducer
        return performHttpRequests && !loaded && !loading && !errors.length
      },
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredQuotes,
    filteredQuotesCount,
    hasQuotes: hasFilteredQuotes,
    loading: loadingQuotes,
  }
}

export default useQuotes
