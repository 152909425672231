import { useMemo } from 'react'

import {
  digObject, matchFilterKey, matchFilterString, sortArrayBy,
} from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import defaultRequestOptions from '@sections/Client/defaultRequestOptions'

import type { IntegrationPlatformModel, IntegrationPlatformRequestOptions } from '@models/types'
import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['integrationPlatforms', 'integrations']

export type IntegrationPlatforms = Record<string, IntegrationPlatformModel>

export const getAvailableFeatures = (integrationPlatforms: IntegrationPlatforms) => {
  const availableFeatures = Object.values(integrationPlatforms).reduce((acc, integrationPlatform) => {
    const supportedFeatures = digObject(integrationPlatform, 'supported_features', []) as Record<string, string>[]

    supportedFeatures.forEach((feature) => {
      // Check that we havent already added the feature key
      if (feature.supported && !acc.find(availableFeature => availableFeature.key === feature.key)){
        acc.push(feature)
      }
    })

    return acc
  }, [])

  return availableFeatures
}

export const groupPlatformsByFeature = (integrationPlatforms: IntegrationPlatforms) => {
  const groupedPlatforms = Object.values(integrationPlatforms).reduce((acc, integrationPlatform) => {
    const { id, key, title } = integrationPlatform

    const supportedFeatures = digObject(integrationPlatform, 'supported_features', []) as Record<string, string>[]

    supportedFeatures.forEach((feature) => {
      const { key: featureKey } = feature
      // Setup the key
      if (feature.supported && !acc[featureKey]){
        acc[featureKey] = []
      }

      acc[featureKey].push({ id, key, label: title })
    })

    return acc
  }, {})

  return groupedPlatforms
}

export const findIntegrationPlatformByKey = (integrationPlatforms: IntegrationPlatforms, platformKey: string) => {
  if (!platformKey) return {}

  const integrationPlatform = Object.values(integrationPlatforms).find(platform => platform.key === platformKey)
  return integrationPlatform || {}
}

type IntegrationPlatformFilters = {
  integrationScope?: string,
  string?: string,
}

type UseIntegrationPlatformsOptions = {
  filters?: IntegrationPlatformFilters,
  performHttpRequests?: boolean,
  requestOptions?: IntegrationPlatformRequestOptions,
}

function useIntegrationPlatforms(options: UseIntegrationPlatformsOptions = {}) {
  const { filters = {} } = options
  const {
    integrationScope: filterIntegrationScope,
    string: filterString,
  } = filters

  const {
    updatedEntities: { integrationPlatforms: integrationPlatformsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { integrationPlatforms } = useSelector(reduxState => reduxState.entities)

  const filteredIntegrationPlatforms = useMemo(() => {
    const filtered = Object.values(integrationPlatforms).filter((integrationPlatform: IntegrationPlatformModel) => {
      const scope = digObject(integrationPlatform, 'data.integration_scope')
      const { title } = integrationPlatform

      const integrationScopeMatch = matchFilterKey(scope, filterIntegrationScope)
      const titleMatch = matchFilterString(title, filterString)

      return integrationScopeMatch && titleMatch && !integrationPlatform.hidden
    })

    return sortArrayBy(filtered, 'asc', 'title')
  }, [integrationPlatformsUpdatedAt, JSON.stringify(filters)])

  const filteredIntegrationPlatformsCount = filteredIntegrationPlatforms.length
  const hasFilteredIntegrationPlatforms = !!filteredIntegrationPlatformsCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredIntegrationPlatformsCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingIntegrationPlatforms } = useReduxAction(
    'integrationPlatforms',
    'loadIntegrationPlatforms',
    {
      ...defaultRequestOptions.integrationPlatform,
      ...filtersWithOffset,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      findIntegrationPlatformByKey: (
        platformKey: string,
      ) => findIntegrationPlatformByKey(integrationPlatforms, platformKey),
      loadMore,
    },
    canLoadMore,
    filteredIntegrationPlatforms,
    filteredIntegrationPlatformsCount,
    hasIntegrationPlatforms: hasFilteredIntegrationPlatforms,
    integrationPlatforms,
    loading: loadingIntegrationPlatforms,
  }
}

export default useIntegrationPlatforms
