import { useSelector } from 'react-redux'

import { digObject } from '@campaignhub/javascript-utils'

import useCurrentUser from '@hooks/useCurrentUser'
import useOrganizationSelector from '@hooks/useOrganizationSelector'

const findDefaultPlatform = (entity, featureKey) => {
  const defaultPlatforms = digObject(entity, 'options.default_platforms', {})
  return defaultPlatforms[featureKey]
}

const getDefaultPlatformForFeatureKey = (params) => {
  const {
    selectedOrganization,
    featureKey,
    suppliedOrganization,
    user,
  } = params

  const userDefaultPlatform = findDefaultPlatform(user, featureKey) || ''
  const suppliedOrganizationDefaultPlatform = findDefaultPlatform(suppliedOrganization, featureKey) || ''

  if (userDefaultPlatform) return userDefaultPlatform

  if (suppliedOrganizationDefaultPlatform) return suppliedOrganizationDefaultPlatform

  return findDefaultPlatform(selectedOrganization, featureKey) || ''
}

function useEntityDefaultIntegrationPlatform(options = {}){
  const { featureKey, organizationId } = options

  const { organizations, users } = useSelector(reduxState => reduxState.entities)

  const { currentUser } = useCurrentUser()

  const user = users[currentUser.id] || {}

  // optional organization
  const suppliedOrganization = organizations[organizationId] || {}

  // users default organization
  const { selectedOrganization } = useOrganizationSelector()

  const defaultPlatformKey = getDefaultPlatformForFeatureKey({
    selectedOrganization,
    featureKey,
    suppliedOrganization,
    user,
  })

  return {
    defaultPlatformKey,
  }
}

export default useEntityDefaultIntegrationPlatform
