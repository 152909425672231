import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  Box, LoadingModule, MainContent, TopBar,
} from '@campaignhub/suit-theme'

import useMainNavigation from '@hooks/useMainNavigation'
import useNumericParams from '@hooks/useNumericParams'

import LoggedInUser from '@components/LoggedInUser'
import DashboardSearch from '@components/DashboardSearch'

import MainNavigation from '@sections/Client/components/MainNavigation'

import useImportExternalProject from './hooks/useImportExternalProject'

const ImportExternalProject = () => {
  const { external_id } = useNumericParams()
  const { integration_platform_key } = useParams()

  const { callbacks: { toggleNavigation } } = useMainNavigation()

  const { callbacks: { importProject } } = useImportExternalProject(external_id)

  if (!integration_platform_key || !external_id){
    toast.warn('Something went wrong')
    window.location.href = '#/'
  }

  useEffect(() => {
    if (integration_platform_key && external_id){
      importProject(integration_platform_key)
    }
  }, [])

  return (
    <>
      <TopBar
        callbacks={{ toggleNavigation }}
        loggedInUserComponent={<LoggedInUser />}
        searchComponent={<DashboardSearch searchEntities={['Project']} />}
      />

      <MainNavigation />

      <MainContent offset={{ top: TopBar.topBarHeight }}>
        <Box flexDirection="column" justifyContent="center" marginTop="large" paddingTop={70} paddingX="large">
          <LoadingModule loading times={3} />
        </Box>
      </MainContent>
    </>
  )
}

export default ImportExternalProject
