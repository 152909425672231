import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Box } from '@campaignhub/suit-theme'
import { useDraggable } from '@campaignhub/react-hooks'

import styles from './styles.module.scss'

const DocumentTemplateField = (props) => {
  const {
    boxProps,
    documentRecipientId,
    icon,
    iconColor,
    itemContext,
    itemContextIndex,
    itemCustomFieldId,
    itemKey,
    itemRawValue,
    itemType,
    label,
    value,
  } = props

  const itemRef = useRef(null)

  useDraggable(itemRef, {
    callbacks: {},
    createElementClone: true,
    documentElementId: 'main-stage',
    draggableZIndex: 99999,
    draggingClassName: styles.dragging,
  })

  return (
    <Box
      className="draggable"
      data-item-context-index={itemContextIndex}
      data-item-context={itemContext}
      data-item-custom-field-id={itemCustomFieldId}
      data-item-key={itemKey}
      data-item-label={label}
      data-item-raw-value={itemRawValue}
      data-item-recipient-id={documentRecipientId}
      data-item-type={itemType}
      data-item-value={value}
      fontSize="small"
      marginBottom="small"
      paddingX="large"
      paddingY="medium"
      ref={itemRef}
      variant="white"
      {...boxProps}
    >
      <Box color={iconColor} width={24}>
        <FontAwesomeIcon icon={icon} />
      </Box>

      {label}
    </Box>
  )
}

DocumentTemplateField.propTypes = {
  boxProps: PropTypes.object,
  documentRecipientId: PropTypes.number,
  icon: PropTypes.object,
  iconColor: PropTypes.string,
  itemContext: PropTypes.string,
  itemContextIndex: PropTypes.number,
  itemCustomFieldId: PropTypes.number,
  itemKey: PropTypes.string,
  itemRawValue: PropTypes.string,
  itemType: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
}

export default DocumentTemplateField
