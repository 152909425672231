import React from 'react'
import PropTypes from 'prop-types'

import { SidebarModal } from '@campaignhub/suit-theme'

import Client from './components/Client'
import Admin from './components/Admin'

const RequestOrSupplySupportTokenModal = (props) => {
  const {
    callbacks, isAdmin, isOrganizationUser, modalKey, showModal,
  } = props

  return (
    <SidebarModal
      callbacks={callbacks}
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header callbacks={callbacks} title="Support" titleSecondLine="Request" />

      {isOrganizationUser && <Client {...props} />}
      {isAdmin && <Admin {...props} />}
    </SidebarModal>
  )
}

RequestOrSupplySupportTokenModal.propTypes = {
  action: PropTypes.oneOf(['', 'supply', 'request']),
  callbacks: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool,
  isOrganizationUser: PropTypes.bool,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

RequestOrSupplySupportTokenModal.defaultProps = {
  modalKey: 'RequestOrSupplySupportTokenModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <RequestOrSupplySupportTokenModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
