import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt, faPlus } from '@fortawesome/pro-light-svg-icons'

import { Image, SidebarModal } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const DefaultState = (props) => {
  const { callbacks: { selectScreen } } = props

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal.Content>
      <SidebarModal.Button
        iconLeft={(
          <Image boxProps={{ backgroundColor: 'hoverLightGrey', fontSize: 'small' }} circle width={30}>
            <FontAwesomeIcon icon={faFileAlt} />
          </Image>
        )}
        onClick={() => selectScreen('CreateFromTemplate')}
        showAngleIcon
        subtitle={strings.createFromTemplate?.subtitle || 'Choose from saved templates'}
        title={strings.createFromTemplate?.title || 'Use a Template'}
      />

      <SidebarModal.Button
        iconLeft={(
          <Image boxProps={{ backgroundColor: 'hoverLightGrey', fontSize: 'small' }} circle width={30}>
            <FontAwesomeIcon icon={faPlus} />
          </Image>
        )}
        onClick={() => selectScreen('CreateNew')}
        showAngleIcon
        subtitle={strings.createNew?.subtitle || 'Start with a blank canvas'}
        title={strings.createNew?.title || 'New Form'}
      />
    </SidebarModal.Content>
  )
}

DefaultState.propTypes = {
  callbacks: PropTypes.shape({
    selectScreen: PropTypes.func.isRequired,
  }).isRequired,
}

export default DefaultState
