import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, FormField, Grid, SectionDivider, SidebarModal,
} from '@campaignhub/suit-theme'

import useDocumentTemplatePageItem, { useDocumentTemplatePageItemForm } from '@hooks/useDocumentTemplatePageItem'
import useLocalization from '@hooks/useLocalization'

import BuilderContext from '../../contexts/builderContext'

import SelectRecipient from '../../components/SelectRecipient'

import localizedStrings from './localizedStrings'

const confirmDelete = (deleteDocumentTemplatePageItem, deleteFn, strings) => {
  swal
    .fire({
      title: strings.swal?.title || 'Delete Field?',
      text: strings.swal?.text || 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: strings.swal?.confirmButton || 'Yes, delete it',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        deleteDocumentTemplatePageItem(deleteFn)
      }
    })
}

const EditDocumentTemplateFieldModal = (props) => {
  const {
    callbacks,
    callbacks: { deleteDocumentTemplatePageItem, updateDocumentTemplatePageItem },
    modalKey,
    showModal,
  } = props

  const builderContext = useContext(BuilderContext)
  const {
    documentTemplatePayload: {
      filteredRecipients,
    },
    selectedTemplatePageItemId,
  } = builderContext

  const documentTemplatePageItemPayload = useDocumentTemplatePageItem({ id: selectedTemplatePageItemId })
  const {
    callbacks: {
      deleteDocumentTemplatePageItem: deleteFn,
      updateDocumentTemplatePageItem: updateFn,
    },
    documentTemplatePageItem,
    isCustomField,
  } = documentTemplatePageItemPayload

  const documentTemplatePageItemFormPayload = useDocumentTemplatePageItemForm(documentTemplatePageItem)
  const {
    entityState,
    entityState: {
      collaboration_disabled,
      document_recipient_id,
      height,
      item_type,
      key,
      label,
      locked,
      options: { input_type, placeholder },
      required,
      value,
      width,
      x,
      y,
    },
    handlers,
    saveEnabled,
    setEntityState,
  } = documentTemplatePageItemFormPayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal callbacks={callbacks} disableOverlay modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.header?.title || 'Edit'}
        titleSecondLine={strings.header?.secondLine || 'Field'}
      />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <FormField direction="column" label={strings.formField?.completedBy || 'Completed By'}>
            <SelectRecipient
              callbacks={{
                selectItem: recipient => setEntityState({ document_recipient_id: recipient.id }),
              }}
              documentRecipients={filteredRecipients}
              selectedRecipientId={document_recipient_id}
            />
          </FormField>

          {isCustomField && (
            <FormField direction="column" label={strings.formField?.label || 'Label'} marginTop="large">
              <input disabled name="label" type="text" value={label} readOnly />
            </FormField>
          )}

          {!isCustomField && (
            <>
              <FormField direction="column" label={strings.formField?.type || 'Type *'} marginTop="large">
                <select name="item_type" value={item_type} {...handlers}>
                  <option value="">{strings.select?.pleaseSelect || 'Please Select...'}</option>
                  <option value="checkbox">{strings.select?.checkbox || 'Checkbox'}</option>
                  <option value="select">{strings.select?.dropdown || 'Dropdown'}</option>
                  <option value="initial">{strings.select?.initial || 'Initial'}</option>
                  <option value="signature">{strings.select?.signature || 'Signature'}</option>
                  <option value="text">{strings.select?.text || 'Text'}</option>
                </select>
              </FormField>

              {['text'].includes(item_type) && (
                <>
                  <FormField direction="column" label={strings.formField?.textType || 'Text Type'} marginTop="large">
                    <select name="options.input_type" value={input_type} {...handlers}>
                      <option value="">{strings.select?.default || 'Default'}</option>
                      <option value="email">{strings.select?.email || 'Email'}</option>
                      <option value="number">{strings.select?.number || 'Number'}</option>
                    </select>
                  </FormField>

                  <FormField
                    direction="column"
                    label={strings.formField?.placeholder || 'Placeholder'}
                    marginTop="large"
                  >
                    <input name="options.placeholder" type="text" value={placeholder} {...handlers} />
                  </FormField>
                </>
              )}

              <FormField direction="column" label={strings.formField?.label || 'Label'} marginTop="large">
                <input name="label" type="text" value={label} {...handlers} />
              </FormField>

              <FormField direction="column" label={strings.formField?.key || 'Key *'} marginTop="large">
                <input name="key" type="text" value={key} {...handlers} />
              </FormField>

              {['text'].includes(item_type) && (
                <FormField direction="column" label={strings.formField?.value || 'Value'} marginTop="large">
                  <textarea name="value" style={{ height: 80 }} type="text" value={value} {...handlers} />
                </FormField>
              )}

              <FormField direction="column" label={strings.formField?.required || 'Required'} marginTop="large">
                <select data-value-type="boolean" name="required" value={required} {...handlers}>
                  <option value="false">{strings.boolSelect?.no || 'No'}</option>
                  <option value="true">{strings.boolSelect?.yes || 'Yes'}</option>
                </select>
              </FormField>

              <FormField direction="column" label={strings.formField?.locked || 'Locked'} marginTop="large">
                <select data-value-type="boolean" name="locked" value={locked} {...handlers}>
                  <option value="false">{strings.boolSelect?.no || 'No'}</option>
                  <option value="true">{strings.boolSelect?.yes || 'Yes'}</option>
                </select>
              </FormField>

              <FormField
                direction="column"
                label={strings.formField?.disableCollaboration || 'Disable Collaboration'}
                marginTop="large"
              >
                <select
                  data-value-type="boolean"
                  name="collaboration_disabled"
                  value={collaboration_disabled}
                  {...handlers}
                >
                  <option value="false">{strings.boolSelect?.no || 'No'}</option>
                  <option value="true">{strings.boolSelect?.yes || 'Yes'}</option>
                </select>
              </FormField>
            </>
          )}

          <SectionDivider boxProps={{ marginY: 'large' }}>
            {strings.divider || 'Size & Position'}
          </SectionDivider>

          <Grid gridColumnGap="medium" gridTemplateColumns="repeat(2, 1fr)">
            <FormField direction="column" label={strings.formField?.x || 'X'}>
              <input name="x" type="text" value={x} {...handlers} />
            </FormField>

            <FormField direction="column" label={strings.formField?.y || 'Y'}>
              <input name="y" type="text" value={y} {...handlers} />
            </FormField>

            <FormField direction="column" label={strings.formField?.width || 'Width'} marginTop="large">
              <input name="width" type="text" value={width} {...handlers} />
            </FormField>

            <FormField direction="column" label={strings.formField?.height || 'Height'} marginTop="large">
              <input name="height" type="text" value={height} {...handlers} />
            </FormField>
          </Grid>

          <FormField direction="column" label={strings.formField?.moreOptions || 'More Options'} marginTop="large">
            <Button
              buttonStyle="secondaryUtility"
              icon={<FontAwesomeIcon icon={faTrashAlt} />}
              onClick={() => confirmDelete(deleteDocumentTemplatePageItem, deleteFn, strings)}
              size="medium"
            >
              {strings.button?.delete || 'Delete Field'}
            </Button>
          </FormField>
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          onClick={() => updateDocumentTemplatePageItem(entityState, updateFn)}
          size="large"
        >
          {strings.button?.save || 'Save Field'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

EditDocumentTemplateFieldModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

EditDocumentTemplateFieldModal.defaultProps = {
  modalKey: 'EditDocumentTemplateFieldModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <EditDocumentTemplateFieldModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
