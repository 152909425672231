import { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage, faList, faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, FormField, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useAddress from '@hooks/useAddress'
import useComparable, { useComparableForm } from '@hooks/useComparable'
import useEntityCustomFieldsForm from '@hooks/useEntityCustomFieldsForm'
import useLocalization from '@hooks/useLocalization'
import useOrganizationSelector from '@hooks/useOrganizationSelector'

import EntityCustomFields from '@components/EntityCustomFields'
import ModalAddress from '@components/ModalComponents/ModalAddress'

import useImages from '@hooks/useImages'
import localizedStrings from './localizedStrings'

const createOrUpdateComparable = (params) => {
  const {
    callbacks, createFn, createOrEditComparable, customFields, entityState, strings, updateFn,
  } = params

  const {
    closeModal, createComparable, loadShortcodeDataFn, updateComparable,
  } = callbacks

  const toastText = entityState.id
    ? strings.toast?.comparableUpdated || 'Comparable Updated Successfully'
    : strings.toast?.comparableCreated || 'Comparable Created Successfully'

  const actionFn = entityState.id ? updateFn : createFn

  const afterActionCallback = (data) => {
    if (entityState.id)closeModal()
    if (!entityState.id) createOrEditComparable({ comparable: data?.entity })
    if (typeof loadShortcodeDataFn === 'function')loadShortcodeDataFn()
  }

  const comparablesPayload = {
    callbacks: {
      action: actionFn,
      afterAction: ({ response: { data } }) => afterActionCallback(data),
    },
    entityParams: { comparableParams: entityState, customFields },
    toastText,
  }

  return entityState.id ? updateComparable(comparablesPayload) : createComparable(comparablesPayload)
}

const confirmDelete = (params) => {
  const {
    callbacks, deleteFn, strings,
  } = params

  const { closeModal, deleteComparable, loadShortcodeDataFn } = callbacks

  const afterActionCallback = () => {
    closeModal()
    if (typeof loadShortcodeDataFn === 'function')loadShortcodeDataFn()
  }
  const deleteComparablePayload = {
    callbacks: {
      action: deleteFn,
      afterAction: () => afterActionCallback(),
    },
    toastText: strings.toast?.comparableDeleted || 'Comparable Deleted Successfully',
  }

  swal
    .fire({
      title: strings.sweetAlert?.title || 'Delete Comparable?',
      text: strings.sweetAlert?.text || 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: strings.sweetAlert?.confirm || 'Yes, delete it.',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        deleteComparable(deleteComparablePayload)
      }
    })
}

const CreateOrEditComparableModal = (props) => {
  const { callbacks, modalKey, showModal } = props

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, `${modalKey}`, {})
  const { comparable } = modalPayload

  const useComparableFormPayload = useComparableForm(comparable)
  const {
    entityState, handlers, saveEnabled, setEntityState,
  } = useComparableFormPayload

  const { address_attributes, source } = entityState
  const hasAddressAttributes = !!Object.keys(address_attributes).length

  const {
    address,
    callbacks: {
      createComparable: createFn,
      createOrEditComparable,
      deleteComparable: deleteFn,
      updateComparable: updateFn,
    },
    creating,
    loading,
    updating,
  } = useComparable(comparable)
  const hasAddress = hasAddressAttributes || !!address.id

  const useAddressPayload = useAddress(address)
  const {
    callbacks: { createOrEditAddress },
  } = useAddressPayload

  const { selectedOrganization } = useOrganizationSelector()

  const customFieldsForm = useEntityCustomFieldsForm(
    { id: comparable.id, type: 'ProjectComparable' },
    selectedOrganization,
    { performHttpRequests: true },
  )

  const {
    callbacks: { buildNestedValues },
    saveEnabled: customFieldsSaveEnabled,
  } = customFieldsForm

  const imagesPayload = useImages({
    customRequestOptions: {
      include_image_sizes: ['thumb_200x120', 'thumb_660x360'],
    },
    subjectId: comparable.id,
    subjectType: 'ProjectComparable',
    performHttpRequests: true,
  })

  const { callbacks: { editImages } } = imagesPayload

  // Launch CreateOrEditAddressModal if there is no address
  useEffect(() => {
    if (!hasAddress) createOrEditAddress(entityState, setEntityState)
  }, [])

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        title={
          comparable.id
            ? strings.sideBarModalHeader?.titleUpdate || 'Update'
            : strings.sideBarModalHeader?.titleCreate || 'Create'
        }
        titleSecondLine={strings.sideBarModalHeader?.titleSecondLine || 'Comparable'}
      />

      <SidebarModal.Content hasFooter>
        <Box flexDirection="column" flexShrink={0}>
          {!hasAddress && (
            <Button
              buttonStyle="ghostEdit"
              icon={<FontAwesomeIcon icon={faList} />}
              onClick={() => createOrEditAddress(entityState, setEntityState)}
              size="medium"
              width="100%"
            >
              {strings.buttons?.enterAddress || 'Enter Address'}
            </Button>
          )}

          {hasAddress && (
            <>
              <ModalAddress
                address={hasAddressAttributes ? address_attributes : address}
                callbacks={{ editAddress: () => createOrEditAddress(entityState, setEntityState) }}
              />

              <EntityCustomFields customFieldsForm={customFieldsForm} sidebar />

              <FormField direction="column" marginTop="large" label={strings.formFieldLabels?.source || 'Source'}>
                <select data-validate-field-on="change" name="source" value={source} {...handlers}>
                  <option key="organization" value="organization">
                    {strings.sourceValues?.organization || 'Agency'}
                  </option>
                  <option key="market" value="market">
                    {strings.sourceValues?.market || 'Market'}
                  </option>
                </select>
              </FormField>
            </>
          )}

          <FormField
            direction="column"
            label={strings.formFieldLabels?.moreOptions || 'More Options'}
            marginTop="large"
          >
            <Button
              buttonStyle="secondaryUtility"
              marginBottom="medium"
              onClick={() => editImages()}
              icon={<FontAwesomeIcon icon={faImage} />}
              size="medium"
              width="100%"
            >
              {strings.buttons?.manageImages || 'Manage Images'}
            </Button>

            <Button
              buttonStyle="secondaryUtility"
              onClick={() => confirmDelete({
                callbacks, deleteFn, strings,
              })}
              icon={<FontAwesomeIcon icon={faTrashAlt} />}
              size="medium"
              width="100%"
            >
              {strings.buttons?.deleteComparable || 'Delete Comparable'}
            </Button>
          </FormField>
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled || !customFieldsSaveEnabled || !hasAddress}
          loading={creating || loading || updating}
          loadingBubbleColor="white"
          onClick={() => createOrUpdateComparable(
            {
              customFields: buildNestedValues(),
              callbacks,
              createFn,
              entityState,
              createOrEditComparable,
              strings,
              updateFn,
            },
          )}
          size="large"
        >
          {strings.buttons?.save || 'Save'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

CreateOrEditComparableModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

CreateOrEditComparableModal.defaultProps = {
  modalKey: 'CreateOrEditComparableModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <CreateOrEditComparableModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
