import { DateTime } from 'luxon'

import { useForm, useLatestEntity } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import { digObject, launchModal } from '@campaignhub/javascript-utils'

import useDispatch from '@hooks/useDispatch'
import useMixpanel from '@hooks/useMixpanel'
import useSelector from '@hooks/useSelector'

import * as quoteItemActions from '@redux/modules/quoteItem'

import defaultFormState, { requiredFields } from '@models/quoteItem'

import type { AppDispatch } from '@redux/store'
import type { DeleteParams } from '@redux/modules/types'
import type { QuoteItemModel, QuoteItemRequestOptions, QuoteSectionModel } from '@models/types'

type CreateQuoteItemParams = {
  dispatch: AppDispatch,
  dispatchMixpanelEvent: (eventType: string, customPayload?: {}) => void,
  quoteItemParams: Partial<QuoteItemModel>,
  requestOptions?: QuoteItemRequestOptions,
}

const createQuoteItem = (params: CreateQuoteItemParams) => {
  const {
    dispatch, dispatchMixpanelEvent, quoteItemParams, requestOptions,
  } = params

  const {
    title, price, quote_id, quoteType,
  } = quoteItemParams

  const { createQuoteItem: createFn } = quoteItemActions

  return dispatch(createFn(quoteItemParams, requestOptions)).then((response) => {
    const { success, data } = response

    if (success){
      dispatchMixpanelEvent('Quote Item Save', {
        title,
        price,
        quote_id,
        quote_type: quoteType,
      })

      return {
        success,
        data,
      }
    }

    return response
  })
}

type UpdateQuoteItemParams = {
  dispatch: AppDispatch,
  quoteItem: QuoteItemModel,
  quoteItemParams: Partial<QuoteItemModel>,
  requestOptions?: QuoteItemRequestOptions,
}

const updateQuoteItem = (params: UpdateQuoteItemParams) => {
  const {
    dispatch, quoteItem, quoteItemParams, requestOptions,
  } = params
  const { updateQuoteItem: updateFn } = quoteItemActions

  const updatedParams = {
    id: quoteItem.id,
    ...quoteItemParams,
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

type DeleteQuoteItemParams = {
  dispatch: AppDispatch,
  quoteItem: DeleteParams<QuoteItemModel>,
}

const deleteQuoteItem = (params: DeleteQuoteItemParams) => {
  const { dispatch, quoteItem } = params
  const { deleteQuoteItem: deleteFn } = quoteItemActions

  return dispatch(deleteFn(quoteItem))
}

type ToggleItemSelectionParams = {
  dispatch: AppDispatch,
  e: React.MouseEvent,
  quoteItem: QuoteItemModel,
  requestOptions: QuoteItemRequestOptions,
}

const toggleItemSelection = (params: ToggleItemSelectionParams) => {
  const {
    dispatch, e, quoteItem, requestOptions,
  } = params

  e.preventDefault()
  e.stopPropagation()

  const updatedItem = {
    ...quoteItem,
    selected: !quoteItem.selected,
  }

  return updateQuoteItem({
    dispatch, quoteItem, quoteItemParams: updatedItem, requestOptions,
  })
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useQuoteItemForm(
  quoteItem: Partial<QuoteItemModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const requiredByDateString = digObject(quoteItem, 'dates.item_date.date_time_with_timezone', '')

  const entity = {
    ...quoteItem,
    item_date:
      quoteItem.id && requiredByDateString
        ? DateTime.fromISO(requiredByDateString).toFormat('yyyy-MM-dd')
        : quoteItem.item_date,
  }

  const quoteItemForm = useForm(
    defaultFormState,
    { entity, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [quoteItem.id, quoteItem.cache_key],
  )

  return {
    ...quoteItemForm,
  }
}

type UseQuoteItemOptions = {
  callbacks:{
    [key: string]: (payload?: { [key: string]: any } | undefined) => void,
  },
}
// eslint-disable-next-line default-param-last
function useQuoteItem(initEntity: Partial<QuoteItemModel> = {}, options: UseQuoteItemOptions) {
  const { callbacks } = options || {}

  const { entity: quoteItem }: { entity: QuoteItemModel} = useLatestEntity(initEntity, 'quoteItems')

  const requiredByDateString = digObject(quoteItem, 'dates.item_date.date_time_with_timezone', '')
  const requiredByDate = requiredByDateString ? DateTime.fromISO(requiredByDateString).toFormat('yyyy-MM-dd') : ''

  const dispatch = useDispatch()

  const { creating, deleting, updating } = useSelector(reduxState => reduxState.quoteItems)

  // Mixpanel Tracking
  const { callbacks: { dispatchMixpanelEvent } } = useMixpanel()

  return {
    callbacks: {
      addQuoteItem: (quoteSection: QuoteSectionModel) => launchModal({
        callbacks,
        modalKey: 'CreateOrEditQuoteItemModal',
        payload: {
          callbacks: {
            createQuoteItem: (quoteItemParams: Partial<QuoteItemModel>, entityOptions?: QuoteItemRequestOptions) => (
              createQuoteItem({
                dispatch, dispatchMixpanelEvent, quoteItemParams, requestOptions: entityOptions,
              })
            ),
          },
          quoteItem: { quote_id: quoteSection.quote_id, quote_section_id: quoteSection.id },
        },
      }),
      deleteQuoteItem: () => deleteQuoteItem({ dispatch, quoteItem }),
      editQuoteItem: () => launchModal({
        callbacks,
        modalKey: 'CreateOrEditQuoteItemModal',
        payload: {
          callbacks: {
            updateQuoteItem: (quoteItemParams: Partial<QuoteItemModel>, entityOptions?: QuoteItemRequestOptions) => (
              updateQuoteItem({
                quoteItem, quoteItemParams, dispatch, requestOptions: entityOptions,
              })
            ),
          },
          quoteItem,
        },
      }),
      toggleItemSelection: (
        e: React.MouseEvent,
        requestOptions: QuoteItemRequestOptions,
      ) => toggleItemSelection({
        dispatch, e, quoteItem, requestOptions,
      }),
      updateQuoteItem: (quoteItemParams: Partial<QuoteItemModel>, entityOptions?: QuoteItemRequestOptions) => (
        updateQuoteItem({
          quoteItem, quoteItemParams, dispatch, requestOptions: entityOptions,
        })
      ),
    },
    creating,
    requiredByDate,
    deleting,
    updating,
    quoteItem,
  }
}

export default useQuoteItem
