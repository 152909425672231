import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp, faArrowDown } from '@fortawesome/pro-solid-svg-icons'

import { Box, DashboardModule, Text } from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useChartData, { calculatePercentageChange } from '@hooks/useChartData'
import useLocalization from '@hooks/useLocalization'

import AreaChart from '@components/charts/AreaChart'

import localizedStrings from './localizedStrings'

const StatisticWithGraph = (props) => {
  const {
    chartDataKey, description, editing, metricKey, selectedDataPoint,
  } = props

  const chartDataPayload = useChartData('benchmark_comparison', {
    chartDataKey,
    metricKey,
    performHttpRequests: false,
  })
  const { dataArray, latestDataForMetricKey, loading } = chartDataPayload

  const currentTotalForDataPoint = digObject(latestDataForMetricKey, selectedDataPoint, 0)

  const percentageChange = calculatePercentageChange(dataArray, selectedDataPoint)
  const positiveChange = percentageChange >= 0

  const {
    callbacks: { formatNumber },
    strings,
  } = useLocalization(localizedStrings)

  return (
    <DashboardModule
      boxProps={{ marginBottom: 0 }}
      editing={editing}
      loading={loading}
      title={strings[selectedDataPoint]}
    >
      <Box flexDirection="column" padding="large">
        <Box alignItems="center" flexDirection="row">
          <Text fontSize="xxxlarge" color="darkGrey" marginRight="medium">
            {currentTotalForDataPoint}
          </Text>

          <Text color={positiveChange ? 'green' : 'red'} fontSize="xsmall">
            {`${formatNumber(percentageChange, '.0f')}%`}
            <FontAwesomeIcon icon={positiveChange ? faArrowUp : faArrowDown} style={{ marginLeft: '5px' }} />
          </Text>
        </Box>

        <Text color="bodyFontLightColor" fontSize="small" marginTop="medium">
          {description}
        </Text>

        <Box marginTop="large">
          <AreaChart
            dataPayload={{
              dataArray: dataArray.slice(-4, -1),
              dataKeys: [selectedDataPoint],
            }}
            chartHeight={50}
            disableLegend
            height={50}
          />
        </Box>
      </Box>
    </DashboardModule>
  )
}

StatisticWithGraph.propTypes = {
  chartDataKey: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  editing: PropTypes.bool,
  metricKey: PropTypes.string.isRequired,
  selectedDataPoint: PropTypes.string.isRequired,
}

export default StatisticWithGraph
