import React from 'react'
import PropTypes from 'prop-types'

import { FormField } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import DocumentRecipient from '@components/ModalComponents/DocumentRecipient'

import localizedStrings from './localizedStrings'

const UnassignedRecipients = (props) => {
  const {
    manageRecipientsPayload: { hasUnassignedRecipients, unassignedRecipients },
    ownerId,
    ownerType,
  } = props

  const { strings } = useLocalization(localizedStrings)

  if (hasUnassignedRecipients){
    return (
      <FormField direction="column" label={strings.formFieldLabels?.unassigned || 'Unassigned'} marginTop="large">
        {unassignedRecipients.map((documentRecipient) => {
          const { id, ...documentRecipientParams } = documentRecipient
          return (
            <DocumentRecipient
              documentRecipient={{
                ...documentRecipientParams,
                document_template_recipient_id: documentRecipient.id,
                owner_id: ownerId,
                owner_type: ownerType,
              }}
              key={documentRecipient.id}
              showEditOptions
            />
          )
        })}
      </FormField>
    )
  }

  return null
}

UnassignedRecipients.propTypes = {
  manageRecipientsPayload: PropTypes.shape({
    hasUnassignedRecipients: PropTypes.bool,
    unassignedRecipients: PropTypes.array.isRequired,
  }).isRequired,
  ownerId: PropTypes.number.isRequired,
  ownerType: PropTypes.string.isRequired,
}

export default UnassignedRecipients
