import { useMemo } from 'react'

import {
  matchFilterKey, matchFilterNumber, sortArrayBy,
} from '@campaignhub/javascript-utils'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import sortEntitiesByDate from '@functions/sortEntitiesByDate'

import type { AttachmentModel, AttachmentRequestOptions } from '@models/types'

import defaultRequestOptions from '@sections/Client/defaultRequestOptions'

const watchEntityKeys = ['attachments']

type AttachmentFilters = {
  attachmentType?: string,
  limit?: number,
  ownerId?: number,
  ownerType?: string,
  subjectId?: number,
  subjectType?: string,
}

type UseAttachmentsOptions = {
  entityKey?: string,
  filters?: AttachmentFilters,
  performHttpRequests?: boolean,
  requestOptions?: AttachmentRequestOptions,
}

function useAttachments(options: UseAttachmentsOptions) {
  const {
    entityKey, filters = {}, performHttpRequests, requestOptions,
  } = options
  const {
    attachmentType: filterAttachmentType,
    limit,
    ownerId: filterOwnerId,
    ownerType: filterOwnerType,
    subjectId: filterSubjectId,
    subjectType: filterSubjectType,
  } = filters

  useReduxAction(
    'attachments',
    'loadAttachments',
    {
      ...defaultRequestOptions.attachment,
      ...requestOptions,
      entityKey,
      owner_id: filterOwnerId,
      owner_type: filterOwnerType,
      subject_id: filterSubjectId,
      subject_type: filterSubjectType,
    },
    [performHttpRequests, JSON.stringify(options)],
    {
      shouldPerformFn: ({
        errors, loaded, loadedForKeys, loading,
      }) => {
        if (entityKey) return performHttpRequests && !loadedForKeys.includes(entityKey)
        return performHttpRequests && !loaded && !loading && !errors.length
      },
    },
  )

  const {
    updatedEntities: { attachments: attachmentsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { attachments } = useSelector(reduxState => reduxState.entities)

  const filteredAttachments = useMemo(() => {
    const filtered = Object.values(attachments).filter((attachment: AttachmentModel) => {
      const {
        attachment_type, owner_id, owner_type, subject_id, subject_type,
      } = attachment

      const attachmentTypeMatch = matchFilterKey(attachment_type, filterAttachmentType)
      const ownerIdMatch = matchFilterNumber(owner_id, filterOwnerId)
      const ownerTypeMatch = matchFilterKey(owner_type, filterOwnerType)
      const subjectIdMatch = matchFilterNumber(subject_id, filterSubjectId)
      const subjectTypeMatch = matchFilterKey(subject_type, filterSubjectType)

      return attachmentTypeMatch && ownerIdMatch && ownerTypeMatch && subjectIdMatch && subjectTypeMatch
    })

    if (limit){
      return sortEntitiesByDate(filtered, 'desc', 'updated').slice(0, limit)
    }

    return sortArrayBy(filtered, 'desc', 'id')
  }, [attachmentsUpdatedAt, JSON.stringify(filters)])

  const filteredAttachmentsCount = filteredAttachments.length
  const hasFilteredAttachments = !!filteredAttachmentsCount

  return {
    filteredAttachments,
    hasAttachments: hasFilteredAttachments,
  }
}

export default useAttachments
