import { useMemo } from 'react'

import { matchFilterNumber, sortArrayBy } from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { ActivityModel, ActivityRequestOptions } from '@models/types'
import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['activities']

type ActivityFilters = {
  recipient_id?: number,
  recipient_type?: string,
}

type UseActivitiesOptions = {
  filters?: ActivityFilters,
  performHttpRequests?: true,
  requestOptions?: ActivityRequestOptions,
}

function useActivities(options: UseActivitiesOptions) {
  const { filters = {}, requestOptions } = options
  const {
    recipient_id: filterRecipientId,
    recipient_type: filterRecipientType,
  } = filters

  const {
    updatedEntities: { activities: activitiesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { activities } = useSelector(reduxState => reduxState.entities)

  const filteredActivities = useMemo(() => {
    const filtered = Object.values(activities).filter((activity: ActivityModel) => {
      const { recipient_id, recipient_type } = activity

      const recipientMatch = filterRecipientId && filterRecipientType
        ? recipient_type === filterRecipientType && matchFilterNumber(recipient_id, filterRecipientId)
        : true

      return recipientMatch
    })

    return sortArrayBy(filtered, 'desc', 'id')
  }, [activitiesUpdatedAt, JSON.stringify(options)])

  const filteredActivitiesCount = filteredActivities.length
  const hasActivities = !!filteredActivitiesCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredActivitiesCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingActivities } = useReduxAction(
    'activities',
    'loadActivities',
    {
      ...requestOptions,
      ...filtersWithOffset,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredActivities,
    filteredActivitiesCount,
    hasActivities,
    loading: loadingActivities,
  }
}

export default useActivities
