export default {
  default: {
    statusTitle: '{global.status.title}',
    views: {
      type: 'count',
      none: 'Not Viewed',
      one: '{count} View',
      many: '{count} Views',
    },
  },
}
