import { useContext } from 'react'
import PropTypes from 'prop-types'

import { digObject } from '@campaignhub/javascript-utils'

import {
  Box, Button, FormField, ModalContext, SectionDivider, SidebarModal,
} from '@campaignhub/suit-theme'

import CustomMobile from '@components/CustomMobile'
import FilterUsersContactsAndTeams from '@components/FilterUsersContactsAndTeams'
import VideoModule from '@components/VideoModule'

import useLocalization from '@hooks/useLocalization'
import useVideoModules from '@hooks/useVideoModules'

import defaultRequestOptions from '@sections/Client/packs/Project/defaultRequestOptions'

import useSendSMS from './hooks/useSendSMS'

import Recipient from './components/Recipient'

import localizedStrings from './localizedStrings'

const handleSubmit = (entityState, sendSMSCallback, sendSMSFn, prependText) => {
  const { message, recipients } = entityState

  const fullMessage = prependText ? `${message}\n${prependText}` : message

  sendSMSCallback({ recipients, message: fullMessage }, sendSMSFn)
}

const SendSMSModal = (props) => {
  const {
    callbacks, disableAnimation, disableOverlay, modalKey, showModal,
  } = props
  const { sendSMS: sendSMSCallback } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const {
    callbacks: { sendSMS: sendSMSFn },
    digitalPage: { subject_id },
    prependText,
  } = digObject(modalData, modalKey, {})

  const {
    callbacks: { setMessage, toggleRecipient },
    contactIds,
    hasRecipients,
    message,
    recipients,
    saveEnabled,
    userIds,
  } = useSendSMS()

  const videoModulePayload = useVideoModules({ key: 'sendSMS' })
  const { videosPayload } = videoModulePayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal
      callbacks={callbacks}
      disableAnimation={disableAnimation}
      disableOverlay={disableOverlay}
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.sideBarModalHeader?.title || 'Send'}
        titleSecondLine={strings.sideBarModalHeader?.titleSecondLine || 'SMS'}
      />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <FormField direction="column" label={strings.formFieldLabels?.searchUsers || 'Search Users'}>
            <FilterUsersContactsAndTeams
              callbacks={{ selectItem: selected => toggleRecipient(selected) }}
              projectId={subject_id}
              requestOptions={defaultRequestOptions.contact}
              selectedContactIds={contactIds}
              selectedUserIds={userIds}
              shouldSearchContacts
            />
          </FormField>

          {hasRecipients && (
            <FormField
              direction="column"
              boxProps={{ marginTop: 'large' }}
              label={strings.formFieldLabels?.recipients || 'Recipients'}
            >
              {recipients.map(recipient => (
                <Recipient key={recipient.key} callbacks={{ toggleRecipient }} disableHover recipient={recipient} />
              ))}
            </FormField>
          )}

          <CustomMobile
            boxProps={{ marginTop: !hasRecipients ? 'large' : null }}
            callbacks={{ addCustomMobile: customMobile => toggleRecipient(customMobile) }}
          />

          {hasRecipients && (
            <>
              <SectionDivider boxProps={{ marginY: 'large' }}>
                {strings.titles?.customize || 'Customize SMS'}
              </SectionDivider>

              <FormField direction="column" label={strings.formFieldLabels?.messageBody || 'Message Body'}>
                <textarea
                  name="message"
                  onChange={e => setMessage(e.target.value)}
                  placeholder={strings.placeholders?.messageBody || 'Add a Message (Optional)'}
                  style={{ height: 80 }}
                  value={message}
                />
              </FormField>
            </>
          )}
        </Box>

        <Box marginTop="large">
          <VideoModule videosPayload={videosPayload} />
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          onClick={() => handleSubmit({ message, recipients }, sendSMSCallback, sendSMSFn, prependText)}
          size="large"
        >
          {strings.buttons?.send || 'Send SMS'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

SendSMSModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  disableAnimation: PropTypes.bool,
  disableOverlay: PropTypes.bool,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

SendSMSModal.defaultProps = {
  modalKey: 'SendSMSModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <SendSMSModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
