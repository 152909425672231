import { HashRouter as Router } from 'react-router-dom'

import useValidShareLink from '@hooks/useValidShareLink'

import DigitalRenderer from '@sections/Client/packs/DigitalRenderer'

interface ShareProps {
  token: string,
}

const Share = (props: ShareProps) => {
  const { token } = props

  const {
    shareLink, isValid, tokenChecked,
  } = useValidShareLink(token)

  if (tokenChecked && !isValid){
    window.location.href = '/'
  }

  // TODO - Loading State

  const { subject_id, subject_type } = shareLink || {}

  if (subject_type === 'DigitalPage'){
    return (
      <Router>
        <DigitalRenderer
          digitalPageId={subject_id}
          editing={false}
          shareLink={shareLink}
          shareLinkToken={token}
        />
      </Router>
    )
  }

  return null
}

export default Share
