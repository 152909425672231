import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImages, faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import {
  Box, FormField, Image, InputSearch, Link, ListItem, Text, UserProfileImage,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useAssetLibraries from '@hooks/useAssetLibraries'
import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const mapResults = (brandAssetLibraries, organizationAssetLibraries, images, strings) => {
  const results = {
    groups: [
      {
        key: 'organization',
        title: strings.mapResults?.organization?.title || 'ORGANIZATION',
        items: Object.values(organizationAssetLibraries)
          .filter(library => library.owner_type === 'Organization' || library.owner_type === 'Brand')
          .map((library) => {
            const image = images[library.default_image_id] || {}
            const imageUrl = digObject(image, 'sizes.thumb_90x90')
            return {
              ...library,
              iconLeft: (
                <UserProfileImage imageUrl={imageUrl}>
                  <FontAwesomeIcon icon={faImages} />
                </UserProfileImage>
              ),
              secondaryText: strings.mapResults?.organization?.secondaryText || 'Agency Library',
              text: library.title,
              key: `AssetLibrary-${library.id}`,
            }
          }),
      },
      {
        key: 'brand',
        title: strings.mapResults?.brand?.title || 'BRAND',
        items: Object.values(brandAssetLibraries)
          .filter(library => library.owner_type === 'Organization' || library.owner_type === 'Brand')
          .map((library) => {
            const image = images[library.default_image_id] || {}
            const imageUrl = digObject(image, 'sizes.thumb_90x90')
            return {
              ...library,
              iconLeft: (
                <UserProfileImage imageUrl={imageUrl}>
                  <FontAwesomeIcon icon={faImages} />
                </UserProfileImage>
              ),
              secondaryText: strings.mapResults?.organization?.secondaryText || 'Agency Library',
              text: library.title,
              key: `AssetLibrary-${library.id}`,
            }
          }),
      },
    ],
  }

  return results
}

const AssetLibrary = (props) => {
  const {
    imageSelectPayload: {
      callbacks: { selectEntity },
      images,
      selectedEntity,
    },
  } = props

  const { default_image_id, owner_type, title } = selectedEntity
  const defaultImage = images[default_image_id] || {}
  const defaultImageUrl = digObject(defaultImage, 'sizes.thumb_200x120', '')

  const assetLibraryPayload = useAssetLibraries({
    filters: {
      asset_type: 'image',
    },
  })
  const { filteredBrandAssetLibraries, filteredOrganizationAssetLibraries } = assetLibraryPayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      <FormField label={strings.formFieldLabels?.findAssetLibrary || 'Find Image Library'}>
        <InputSearch
          callbacks={{ selectItem: item => selectEntity({ type: 'AssetLibrary', id: item.id }) }}
          closeOnSelect
          placeholder={strings.imageLibrarySearchPlaceholder || 'Search for image library'}
          results={mapResults(filteredBrandAssetLibraries, filteredOrganizationAssetLibraries, images, strings)}
          showOnFocus
        />
      </FormField>

      {!!selectedEntity.id && (
        <ListItem
          boxProps={{
            alignItems: 'center',
            borderBottom: '1px dashed',
            borderColor: 'lineColor',
            paddingY: 'large',
          }}
          disableHover
        >
          <Box marginRight="medium" width="auto">
            <Image url={defaultImageUrl} circle width={30} height={30} />
          </Box>

          <Box flexDirection="column">
            <Text fontSize="small">{title}</Text>
            <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="small">
              {`${owner_type} Library`}
            </Text>
          </Box>

          <Link
            greyLink
            onClick={() => selectEntity({ type: 'AssetLibrary', id: 0 })}
            textProps={{ fontSize: 'small' }}
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </Link>
        </ListItem>
      )}
    </>
  )
}

AssetLibrary.propTypes = {
  imageSelectPayload: PropTypes.shape({
    callbacks: PropTypes.shape({
      selectEntity: PropTypes.func.isRequired,
    }).isRequired,
    images: PropTypes.object.isRequired,
    selectedEntity: PropTypes.object.isRequired,
  }).isRequired,
}

export default AssetLibrary
