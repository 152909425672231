export default {
  default: {
    buttonText: {
      close: 'Close',
      delete: 'Delete Attachment',
      download: 'Download',
      save: 'Save',
    },
    formFieldLabels: {
      attachment: 'Attachment',
      attachmentDescription: 'Attachment Description',
      attachmentTitle: 'Attachment Title',
      moreOptions: 'More Options',
    },
    modalHeader: {
      title: 'Edit',
      titleSecondLine: 'Attachment',
    },
    swal: {
      title: 'Delete Attachment?',
      text: 'Are you sure?',
      confirmButtonText: 'Yes, delete it.',
    },
  },
}
