import { digObject } from '@campaignhub/javascript-utils'

import { Box, Image } from '@campaignhub/suit-theme'

import type { ImageModel } from '@models/image'

interface CustomDotGroupProps {
  carouselImages: ImageModel[],
  currentSlide: number,
  goToSlide: (index: number) => void,
  images: ImageModel[],
}

const CustomDotGroup = (props: CustomDotGroupProps) => {
  const {
    carouselImages, currentSlide, goToSlide, images,
  } = props

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {carouselImages.map((carouselImage, index) => {
        const image = images[carouselImage.id]
        const imageUrl = digObject(image, 'sizes.thumb_90x90', '')
        const externalImageUrl = digObject(carouselImage, 'data.external_url', '')

        return (
          <Box
            padding="small"
            style={{
              cursor: 'pointer',
              padding: 'large',
              opacity: index === currentSlide ? 2 : 0.5,
            }}
            key={image?.id}
            onClick={() => goToSlide(index)}
          >
            <Image
              height={60}
              width={60}
              url={imageUrl || externalImageUrl}
            />
          </Box>

        )
      })}
    </Box>

  )
}
export default CustomDotGroup
