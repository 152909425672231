export default {
  default: {
    buttons: {
      addConnected: 'Add Connected Platform',
      addSource: 'Add Source Platform',
    },
    connectedPlatforms: 'Connected Platforms',
    sourcePlatform: 'Source Platform',
  },
}
