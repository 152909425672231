import PropTypes from 'prop-types'

import { Box, FormField } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import getPermittedTags from '@functions/getPermittedTags'

import localizedStrings from './localizedStrings'

const TagSelector = (props) => {
  const { hasTags, selectFn, tagEntity } = props

  const permittedTags = getPermittedTags(tagEntity)

  const { strings } = useLocalization(localizedStrings)

  if (hasTags) return null

  return (
    <Box marginTop="medium" justifyContent="space-between">
      <FormField boxProps={{ flexShrink: 1 }}>
        <select onChange={e => selectFn({ selectedTagKey: e.target.value, tagEntity })}>
          <option value="">{strings.select?.option || 'Select...'}</option>
          {permittedTags.map(tag => (
            <option key={tag.id} value={tag.key}>
              {tag.value}
            </option>
          ))}
        </select>
      </FormField>
    </Box>
  )
}

TagSelector.propTypes = {
  hasTags: PropTypes.bool,
  tagEntity: PropTypes.string,
  selectFn: PropTypes.func.isRequired,
}

export default TagSelector
