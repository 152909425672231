import React from 'react'
import PropTypes from 'prop-types'

import { Box, Grid } from '@campaignhub/suit-theme'

import Tile from './Tile'

const Tiles = (props) => {
  const {
    filteredDigitalPages,
  } = props

  return (
    <Box flexDirection="column" flexShrink="0" padding="medium">
      <Grid
        gridGap="large"
        gridAutoRows="1fr"
        gridTemplateColumns="repeat(auto-fill, minmax(250px, 1fr))"
        gridTemplateRows="repeat(auto-fill, minmax(200px, 1fr))"
        padding="medium"
      >
        {filteredDigitalPages.map(digitalPage => (
          <Tile
            digitalPage={digitalPage}
            key={digitalPage.id}
          />
        ))}
      </Grid>
    </Box>
  )
}

Tiles.propTypes = {
  filteredDigitalPages: PropTypes.array.isRequired,
}

export default Tiles
