import React from 'react'
import PropTypes from 'prop-types'

import { Box, Button, DashboardModule, FormField, ModuleBlankState } from '@campaignhub/suit-theme'

import useCurrentUser from '@hooks/useCurrentUser'
import useUser from '@hooks/useUser'
import useLocalization from '@hooks/useLocalization'

import TasksImageUrl from './assets/Token.svg'

import localizedStrings from './localizedStrings'

const Support = () => {
  const { currentUser, isImpersonatingUser } = useCurrentUser()

  const userPayload = useUser(currentUser)
  const {
    callbacks: { generateSupportToken, revokeSupportToken },
    user: { support_token_valid, support_token },
  } = userPayload

  const { strings } = useLocalization(localizedStrings)

  if (isImpersonatingUser) {
    return null
  }

  return (
    <Box flexDirection="column">
      <DashboardModule boxProps={{ marginBottom: 'large', marginTop: 'small' }} title="Help & Support">
        <Box flexDirection="column">
          <ModuleBlankState imageUrl={TasksImageUrl}>
            <ModuleBlankState.Title>{strings.help || 'Need Help?'}</ModuleBlankState.Title>

            <ModuleBlankState.Paragraph>
              {strings.paragraph ||
                'Generate a support token to grant temporary access to support if they need to log in and help you with something.'}
            </ModuleBlankState.Paragraph>
          </ModuleBlankState>

          <Box flexDirection="column" padding="large" paddingTop="0">
            <FormField direction="column" label={strings.supportToken || 'Support Token'}>
              {!support_token_valid && (
                <Button buttonStyle="secondaryUtility" size="medium" onClick={() => generateSupportToken()}>
                  {strings.buttonGenerate || 'Generate Token'}
                </Button>
              )}

              {support_token_valid && (
                <Box>
                  <input defaultValue={support_token} type="text" />

                  <Button
                    buttonStyle="secondaryDestroy"
                    margin="0"
                    marginLeft="medium"
                    size="medium"
                    onClick={() => revokeSupportToken()}
                    width="auto"
                  >
                    {strings.revoke || 'Revoke'}
                  </Button>
                </Box>
              )}
            </FormField>
          </Box>
        </Box>
      </DashboardModule>
    </Box>
  )
}

Support.propTypes = {}

export default Support
