import { ReactNode, useContext, useState } from 'react'

import PageContext from '@contexts/pageContext'

import { digObject } from '@campaignhub/javascript-utils'

import getClonedFieldId from '@functions/getClonedFieldId'
import replaceTextShortCodes from '@functions/replaceTextShortCodes'
import { shouldRenderPageItem } from '@functions/digitalTemplatePageItem'

import useDeviceStyle from '@hooks/useDeviceStyle'
import useDigitalRendererImage from '@hooks/useDigitalRendererImage'

import type { ImageModel } from '@models/image'

import CarouselModal from './components/CarouselModal'

import styles from './styles.module.scss'

const imageStyle = (defaultStyle: {}, imageUrl: string) => {
  const backgroundImage = imageUrl ? `url(${imageUrl})` : null

  return {
    ...defaultStyle,
    backgroundImage,
  }
}

interface ImageCarouselProps {
  children: ReactNode,
  context: {
    images: ImageModel[],
  },
  data: {
    value: string,
  },
  id: string,
  image: ImageModel,
  itemEntities: {},
  options?: {
    renderConditions: string,
  },
}

const ImageCarousel = (props: ImageCarouselProps) => {
  const {
    children,
    context: componentContext,
    data: { value: rawValue },
    id,
    image: imageParams,
    itemEntities,
    options,
  } = props

  const { renderConditions } = options || {}

  const style = useDeviceStyle(props)

  const pageContext = useContext(PageContext)
  const {
    customData,
    shortcodeData,
  } = pageContext || {}

  const clonedFieldId = getClonedFieldId(rawValue)
  const clonedFieldRawValue = digObject(itemEntities, `${clonedFieldId}.image`)

  const contextData = { ...shortcodeData, ...componentContext }

  // Value
  const value = replaceTextShortCodes(rawValue, contextData)
  const imageItemRawValue = clonedFieldRawValue?.id || value

  const itemData = customData[clonedFieldId] || customData[id] || {}

  const { imageSizeUrl, imageRef } = useDigitalRendererImage(imageParams, {
    customData: itemData,
    shortcodeImageId: imageItemRawValue,
  })

  // Get the images from data context for carousel
  const { images } = componentContext || {}

  // Launch the modal
  const [showModal, setShowModal] = useState(false)

  // Should Render
  const shouldRender = shouldRenderPageItem(renderConditions, contextData, options)
  if (!shouldRender) return null

  return (
    <>
      <span
        className={styles.root}
        ref={imageRef}
        style={imageStyle(style, imageSizeUrl)}
        onClick={() => setShowModal(true)}
      >
        {children}
      </span>

      {showModal && (
        <CarouselModal
          callbacks={{ closeModal: () => setShowModal(false) }}
          carouselImages={images}
          style={style}
        />
      )}
    </>
  )
}

export default ImageCarousel
