import React from 'react'
import PropTypes from 'prop-types'

import { faChartBar } from '@fortawesome/pro-light-svg-icons'

import { Box, DashboardModule } from '@campaignhub/suit-theme'

import OrganizationLeaderboard from '../../OrganizationLeaderboard'
import BrandLeaderboard from '../../BrandLeaderboard'

import LeaderboardImageUrl from './assets/analytics.svg'
import useSystemManagerLeaderboard from '../../hooks/useSystemManagerLeaderboard'

export const icon = faChartBar

export const configOptions = () => ({
  title: {
    component: 'input',
    key: 'title',
    label: 'Title',
    optionKey: 'customization',
  },
  status_key: {
    component: 'select',
    key: 'status_key',
    label: 'Status',
    optionKey: 'queryParams',
    values: [
      { key: 'all', label: 'Created' },
      { key: 'accepted', label: 'Accepted' },
    ],
  },
  type: {
    component: 'select',
    key: 'leaderboard',
    label: 'Filter By',
    optionKey: 'queryParams',
    values: [
      { key: 'organization_leaderboard', label: 'Organization' },
      { key: 'brand_leaderboard', label: 'Brand' },
    ],
  },
  month: {
    component: 'select',
    key: 'month',
    label: 'Month',
    optionKey: 'queryParams',
    values: [
      { key: 'current_month', label: 'Current Month' },
      { key: 'previous_month', label: 'Previous Month' },
    ],
  },
  limit: {
    component: 'select',
    key: 'limit',
    label: 'Limit',
    optionKey: 'queryParams',
    values: [
      { key: 5, label: '5' },
      { key: 10, label: '10' },
      { key: 25, label: '25' },
      { key: 50, label: '50' },
    ],
  },
})

const LeaderboardModule = (props) => {
  const { customOptions, editing } = props

  const {
    queryParams: {
      leaderboard, limit, month, status_key,
    },
  } = customOptions

  const { filteredLeaderboard, hasfilteredLeaderboard, loading } = useSystemManagerLeaderboard({
    digital_template_type_key: 'proposal',
    leaderboard,
    limit,
    month,
    status_key,
  })

  const dashboardModuleTitle = leaderboard === 'organization_leaderboard'
    ? 'Organization Digital Page Leaderboard'
    : 'Brand Digital Page Leaderboard'
  const Component = leaderboard === 'organization_leaderboard' ? OrganizationLeaderboard : BrandLeaderboard

  return (
    <DashboardModule
      boxProps={{ marginBottom: 0 }}
      editing={editing}
      flexDirection="column"
      loading={loading}
      title={dashboardModuleTitle}
    >
      {!hasfilteredLeaderboard && (
        <DashboardModule.BlankState imageUrl={LeaderboardImageUrl}>
          <DashboardModule.BlankState.Title>No Leaderboard Available</DashboardModule.BlankState.Title>

          <DashboardModule.BlankState.Paragraph>Try adjusting your filters.</DashboardModule.BlankState.Paragraph>
        </DashboardModule.BlankState>
      )}

      {hasfilteredLeaderboard && (
        <Box flexDirection="column">
          {filteredLeaderboard.map((metricCollection, index) => (
            <Component key={index} digitalPageMetricCollection={metricCollection} />
          ))}
        </Box>
      )}
    </DashboardModule>
  )
}

LeaderboardModule.propTypes = {
  customOptions: PropTypes.object.isRequired,
  editing: PropTypes.bool,
}

export default LeaderboardModule
