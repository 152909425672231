import { useContext } from 'react'
import cloneDeep from 'lodash/cloneDeep'

import { useForm, useLatestEntity } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import { launchModal, multiFilteredObjectArraySelector, sortArrayBy } from '@campaignhub/javascript-utils'

import useCurrentUser from '@hooks/useCurrentUser'
import useDispatch from '@hooks/useDispatch'
import useSelector from '@hooks/useSelector'

import * as customFieldSetActions from '@redux/modules/customFieldSet'

import defaultFormState, { requiredFields } from '@models/customFieldSet'

import PageContext from '@contexts/pageContext'

import type { AppDispatch } from '@redux/store'
import type { DeleteParams } from '@redux/modules/types'
import type { CustomFieldSetModel, CustomFieldSetRequestOptions } from '@models/types'

export const generateUrls = (customFieldSet?: Partial<CustomFieldSetModel>) => {
  const { id } = customFieldSet || {}

  const {
    location: { hash },
  } = window

  const packName = hash.match(/#\/([a-zA-Z]+)(\/.+)/)?.[1] || 'admin'

  return {
    customFieldSetsIndexUrl: `#/${packName}/customFieldSets`,
    editCustomFieldSetUrl: `#/${packName}/customFieldSets/${id}/edit`,
  }
}

type CreateCustomFieldSetParams = {
  customFieldSetParams: Partial<CustomFieldSetModel>,
  dispatch: AppDispatch,
  requestOptions?: CustomFieldSetRequestOptions,
}

const createCustomFieldSet = (params: CreateCustomFieldSetParams) => {
  const { customFieldSetParams, dispatch, requestOptions } = params
  const { options } = customFieldSetParams || {}

  const { createCustomFieldSet: createFn } = customFieldSetActions

  const updatedParams = {
    ...customFieldSetParams,
    options: JSON.stringify(options),
  }

  return dispatch(createFn(updatedParams, requestOptions)).then((response) => {
    const { success, data } = response
    if (success){
      const {
        entity: { id },
      } = data

      const { editCustomFieldSetUrl } = generateUrls({ id })

      return {
        success,
        data,
        redirectUrl: editCustomFieldSetUrl,
      }
    }

    return response
  })
}

type OwnerParams = {
  ownerId: number,
  ownerType: string,
}

type DuplicateCustomFieldSetForOwnerParams = {
  customFieldSet: CustomFieldSetModel,
  dispatch: AppDispatch,
  ownerParams: OwnerParams,
  requestOptions?: CustomFieldSetRequestOptions,
}

const duplicateCustomFieldSetForOwner = (params: DuplicateCustomFieldSetForOwnerParams) => {
  const {
    customFieldSet, dispatch, ownerParams, requestOptions,
  } = params
  const { ownerId, ownerType } = ownerParams || {}

  const { duplicateCustomFieldSetForOwner: duplicateFn } = customFieldSetActions

  const updatedOptions = {
    ...requestOptions,
    owner_id: ownerId,
    owner_type: ownerType,
  }

  return dispatch(duplicateFn(customFieldSet, updatedOptions)).then((response) => {
    const { success, data } = response
    if (success){
      const {
        entity: { id },
      } = data

      const { editCustomFieldSetUrl } = generateUrls({ id })

      return {
        success,
        data,
        redirectUrl: editCustomFieldSetUrl,
      }
    }

    return response
  })
}

type UpdateCustomFieldSetParams = {
  customFieldSet: CustomFieldSetModel,
  customFieldSetParams: Partial<CustomFieldSetModel>,
  dispatch: AppDispatch,
  requestOptions?: CustomFieldSetRequestOptions,
}

const updateCustomFieldSet = (params: UpdateCustomFieldSetParams) => {
  const {
    dispatch, customFieldSet, customFieldSetParams, requestOptions,
  } = params
  const { options } = customFieldSetParams

  const { updateCustomFieldSet: updateFn } = customFieldSetActions

  const updatedParams = {
    id: customFieldSet.id,
    ...customFieldSetParams,
  }

  if (options){
    const customFieldSetOptions = customFieldSet.options || {}
    updatedParams.options = JSON.stringify({
      ...cloneDeep(customFieldSetOptions),
      ...options,
    })
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

type DeleteCustomFieldSetParams = {
  customFieldSet: DeleteParams<CustomFieldSetModel>,
  dispatch: AppDispatch,
}

const deleteCustomFieldSet = (params: DeleteCustomFieldSetParams) => {
  const { dispatch, customFieldSet } = params
  const { deleteCustomFieldSet: deleteFn } = customFieldSetActions

  return dispatch(deleteFn(customFieldSet))
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useCustomFieldSetForm(
  customFieldSet: Partial<CustomFieldSetModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const customFieldSetForm = useForm(
    defaultFormState,
    { entity: customFieldSet, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [customFieldSet.id, customFieldSet.cache_key],
  )

  return {
    ...customFieldSetForm,
  }
}

function useCustomFieldSet(initEntity: Partial<CustomFieldSetModel> = {}) {
  const { entity: customFieldSet }: { entity: CustomFieldSetModel} = useLatestEntity(initEntity, 'customFieldSets')

  const dispatch = useDispatch()

  const { callbacks } = useContext(PageContext)

  const { creating, loading } = useSelector(reduxState => reduxState.customFieldSets)

  const customFieldFilters = [
    { key: 'owner_id', value: customFieldSet.id },
    { key: 'owner_type', value: 'CustomFieldSet' },
  ]

  const filteredCustomFields = useSelector((reduxState) => {
    const filtered = multiFilteredObjectArraySelector(reduxState, 'customFields', customFieldFilters)
    const sorted = sortArrayBy(filtered, 'asc', 'label')
    return sorted
  })

  const customFieldCount = filteredCustomFields.length

  const hasCustomFields = !!filteredCustomFields.length

  const systemIsOwner = customFieldSet.owner_type === 'System'
  const { isAdmin } = useCurrentUser()
  const customFieldSetCanBeDeleted = isAdmin && !systemIsOwner

  return {
    callbacks: {
      createCustomFieldSet: (
        customFieldSetParams: Partial<CustomFieldSetModel>,
        entityOptions?: CustomFieldSetRequestOptions,
      ) => (
        createCustomFieldSet({ customFieldSetParams, dispatch, requestOptions: entityOptions })
      ),
      deleteCustomFieldSet: () => deleteCustomFieldSet({ dispatch, customFieldSet }),
      duplicateCustomFieldSet: () => launchModal({
        callbacks,
        modalKey: 'DuplicateCustomFieldSetModal',
        payload: { customFieldSet },
      }),
      duplicateCustomFieldSetForOwner: (
        ownerParams: OwnerParams,
        entityOptions?: CustomFieldSetRequestOptions,
      ) => duplicateCustomFieldSetForOwner({
        customFieldSet,
        dispatch,
        ownerParams,
        requestOptions: entityOptions,
      }),
      updateCustomFieldSet: (
        customFieldSetParams: Partial<CustomFieldSetModel>,
        entityOptions?: CustomFieldSetRequestOptions,
      ) => (
        updateCustomFieldSet({
          customFieldSet, customFieldSetParams, dispatch, requestOptions: entityOptions,
        })
      ),
    },
    creating,
    customFieldCount,
    customFieldSet,
    customFieldSetCanBeDeleted,
    filteredCustomFields,
    hasCustomFields,
    loading,
    urls: generateUrls(customFieldSet),
  }
}

export default useCustomFieldSet
