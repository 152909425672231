export default {
  default: {
    buttons: {
      save: 'Save',
    },
    header: {
      title: 'Sort',
      titleSecondLine: 'Sections',
    },
    formFieldLabels: {
      firstName: '* First Name',
      lastName: '* Last Name',
      email: '* Email',
      mobile: 'Mobile',
      timeZone: '* Time Zone',
    },
  },
}
