import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons'

import { Box } from '@campaignhub/suit-theme'

import type { ImageModel } from '@models/image'

import Carousel from '../Carousel'

interface ModalControllerProps {
  callbacks: {
    closeModal: () => void,
  },
  carouselImages: ImageModel[],
  style: Record<string, string | number>,
}

const CarouselModal = (props: ModalControllerProps) => {
  const { callbacks: { closeModal }, carouselImages, style } = props

  return (
    <Box
      alignItems="center"
      backgroundColor="white"
      bottom="0"
      justifyContent="center"
      left="0"
      padding="large"
      position="fixed"
      right="0"
      top="0"
      zIndex="10"
    >
      <Carousel images={carouselImages} style={style} />

      <Box
        as="a"
        color="darkGrey"
        fontSize="xxlarge"
        onClick={closeModal}
        position="absolute"
        top="large"
        right="large"
        width="auto"
        style={{ cursor: 'pointer' }}
      >
        <FontAwesomeIcon icon={faTimes} />
      </Box>
    </Box>
  )
}

export default CarouselModal
