import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities, deleteEntity } from '@redux/entities'

import api from '@functions/api'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/digitalPage/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/digitalPage/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/digitalPage/FETCH_FAILURE'

// Initial State
const initialState = {
  errors: [],
  loaded: false,
  loadedForKeys: [],
  loading: false,
  result: {},
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    result: payload.result,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

// Action Creators
export function fetchLeaderboard(options){
  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = api('/digital_pages/leaderboard.json', options)
      .then(({ data }) => {
        dispatch(fetchSuccess({ result: data.data }))

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        errors: [],
        loaded: true,
        loading: false,
        result: action.result || state.result,
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
