import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBullseyeArrow } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Image, ListItem, Text,
} from '@campaignhub/suit-theme'
import { digObject } from '@campaignhub/javascript-utils'

import useTargetAudience from '@hooks/useTargetAudience'

import placeHolderImage from './assets/placeholderImage.png'

const TargetAudience = (props) => {
  const {
    lastItem,
    targetAudience,
    targetAudience: {
      description,
      title,
    },
  } = props

  const {
    callbacks: { createOrEditTargetAudience },
    defaultImage,
  } = useTargetAudience(targetAudience)

  const defaultImageUrl = digObject(defaultImage, 'sizes.thumb_200x120', '')

  return (
    <ListItem
      boxProps={{ as: 'a', borderBottom: lastItem ? 'none' : '1px dashed', padding: 'large' }}
      onClick={createOrEditTargetAudience}
      showAngleIcon
    >
      <Box
        alignItems="center"
        backgroundColor="whiteGrey"
        border="1px solid"
        borderColor="hoverGrey"
        borderRadius={5}
        height={60}
        justifyContent="center"
        marginRight="large"
        width={100}
      >
        {!defaultImageUrl && <FontAwesomeIcon icon={faBullseyeArrow} />}

        {defaultImageUrl
            && (
            <Box borderRadius={5} backgroundImage={`url(${placeHolderImage})`} >
              <Image borderRadius={5} height={60} marginRight="medium" url={defaultImageUrl} />
            </Box>
            )}
      </Box>

      <Box flexDirection="column" justifyContent="center" minWidth="0">
        <Text fontSize="small">{title}</Text>

        {description && (
          <Text color="bodyFontLightColor" fontSize="xsmall" lineHeight={1.3} paddingTop="medium">
            {description}
          </Text>
        )}
      </Box>
    </ListItem>
  )
}

TargetAudience.propTypes = {
  lastItem: PropTypes.bool.isRequired,
  targetAudience: PropTypes.object.isRequired,
}

export default TargetAudience
