import React from 'react'

import { BlankState } from '@campaignhub/suit-theme'

import imageLibrariesUrl from './assets/no-image-libraries.svg'

const ImageLibrariesBlankState = () => (
  <BlankState imageUrl={imageLibrariesUrl}>
    <BlankState.Title>
      No Image Libraries
    </BlankState.Title>

    <BlankState.Paragraph>
      You either haven't created any asset libraries, or you need to adjust your filters to return more results.
    </BlankState.Paragraph>
  </BlankState>
)

export default ImageLibrariesBlankState
