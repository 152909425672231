import { Box, Checkbox, ListItem, Text } from '@campaignhub/suit-theme'

type UserProps = {
  callbacks: {
    toggleItem: (id: number) => void
  }
  selected: boolean
  user: {
    id: number
    full_name: string
  }
}

const ToggleableUserListItem = (props: UserProps) => {
  const {
    selected,
    callbacks: { toggleItem },
    user: { id, full_name },
  } = props

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        lineHeight: '1.3',
      }}
      disableHover
    >
      <Box paddingY="medium" width="auto">
        <Checkbox checked={selected} onClick={() => toggleItem(id)} />
      </Box>

      <Box alignItems={['flex-start', 'center']} flexDirection={['column', 'row']}>
        <Box flexDirection="column" lineHeight="1.3" marginRight="auto" width="auto">
          <Text fontSize="small">{full_name}</Text>
        </Box>
      </Box>
    </ListItem>
  )
}

export default ToggleableUserListItem
