import React from 'react'
import PropTypes from 'prop-types'

import { Box, ListItem, Text } from '@campaignhub/suit-theme'

import useTargetAudience from '@hooks/useTargetAudience'

const TargetAudienceListItem = (props) => {
  const { targetAudience } = props
  const {
    owner_title, title,
  } = targetAudience

  const {
    callbacks: { createOrEditTargetAudience },
  } = useTargetAudience(targetAudience)

  return (
    <ListItem
      boxProps={{
        as: 'a',
        alignItems: 'center',
        borderBottom: '1px dashed',
        padding: 'large',
      }}
      showAngleIcon
      onClick={() => createOrEditTargetAudience()}
    >
      <Box flexDirection="column">
        <Text fontSize="small" marginBottom="small">
          {title}
        </Text>

        <Text color="faintGrey" fontSize="xsmall">
          {owner_title}
        </Text>
      </Box>
    </ListItem>
  )
}

TargetAudienceListItem.propTypes = {
  targetAudience: PropTypes.object.isRequired,
}

export default TargetAudienceListItem
