import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@campaignhub/suit-theme'

const IconInCircle = (props) => {
  const {
    backgroundColor, children, color, fontSize, icon, height, width, ...restProps
  } = props

  return (
    <Box
      alignItems="center"
      backgroundColor={backgroundColor}
      borderRadius="100%"
      color={color}
      fontSize={fontSize}
      height={height}
      justifyContent="center"
      width={width}
      {...restProps}
    >
      {children}
    </Box>
  )
}

IconInCircle.propTypes = {
  backgroundColor: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  icon: PropTypes.node,
  height: PropTypes.number,
  width: PropTypes.number,
}

IconInCircle.defaultProps = {
  color: '#6180BB',
  fontSize: 'xsmall',
  height: 24,
  width: 24,
  backgroundColor: '#F2F5FB',
}

export default IconInCircle
