type GenerateKeyFromParams = {
  [key: string]: string | number | boolean,
}

export const sortParams = (params: GenerateKeyFromParams): GenerateKeyFromParams => {
  const sortedKeys = Object.keys(params).sort()

  return sortedKeys.reduce((sortedParams, key) => {
    sortedParams[key] = params[key]
    return sortedParams
  }, {})
}

const generateKeyFromParams = (params: GenerateKeyFromParams) => {
  const sortedReportKeys = sortParams(params)

  return JSON.stringify(sortedReportKeys)
}

export default generateKeyFromParams
