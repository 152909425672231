import React from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Link, ListItem, Text,
} from '@campaignhub/suit-theme'

import useAttachment from '@hooks/useAttachment'
import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const confirmDelete = (e, deleteFn, strings) => {
  e.stopPropagation()
  swal
    .fire({
      title: strings.swal?.title,
      text: strings.swal?.text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: strings.swal?.confirmButtonText,
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        deleteFn()
      }
    })
}

const Attachment = (props) => {
  const { attachment } = props

  const {
    callbacks: { deleteAttachment, editAttachment },
    fileName,
    title,
    urls: { downloadUrl },
  } = useAttachment(attachment)

  const { strings } = useLocalization(localizedStrings)

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        border: '1px solid',
        borderRadius: 5,
        marginBottom: 'large',
        padding: 'large',
      }}
      forceBottomBorder
      onClick={editAttachment}
      showAngleIcon
    >
      <Box flexDirection="column" width="75%">
        <Text variant="ellipsis" fontSize="small" color="bodyFontColor">
          {title}
        </Text>

        <Text variant="ellipsis" marginTop="medium" fontSize="xsmall" color="faintGrey">
          {fileName}
        </Text>
      </Box>

      <Box marginLeft="auto" justifyContent="flex-end">
        {downloadUrl && (
          <Link
            href={downloadUrl}
            greyLink
            target="_blank"
            textProps={{ marginLeft: 'medium' }}
          >
            <FontAwesomeIcon icon={faDownload} />
          </Link>
        )}

        <Link onClick={e => confirmDelete(e, deleteAttachment, strings)} greyLink textProps={{ marginLeft: 'medium' }}>
          <FontAwesomeIcon icon={faTrashAlt} />
        </Link>
      </Box>
    </ListItem>
  )
}

Attachment.propTypes = {
  attachment: PropTypes.object.isRequired,
}

export default Attachment
