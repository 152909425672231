import { useContext } from 'react'
import {
  Box, Button, FormField, ModalContext, SectionDivider, SidebarModal, SidebarNoticeBox,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import GoogleAddressSearch from '@components/GoogleAddressSearch'
import VideoModule from '@components/VideoModule'

import useDigitalPage from '@hooks/useDigitalPage'
import useLocalization from '@hooks/useLocalization'
import useMixpanel from '@hooks/useMixpanel'
import useVideoModules from '@hooks/useVideoModules'

import type { MixPanelType } from '@hooks/useMixpanel'

import type { DigitalPageModel } from '@models/digitalPage'

import useSuburbData from './hooks/useSuburbData'

import localizedStrings from './localizedStrings'
import SuburbListItem from './components/SuburbListItem/index'

type HandleChartUpdateAndTrackParams = {
  addedSuburbsIntegrationPlatforms: string[],
  digital_page_id: number,
  digitalPageParams: any,
  dispatchMixpanelEvent?: MixPanelType,
  updateDigitalPage: (arg1: Partial<DigitalPageModel>, arg2: () => void) => void,
  updateDigitalPageFn: () => void,
}

const handleSuburbDataUpdate = (params: HandleChartUpdateAndTrackParams) => {
  const {
    addedSuburbsIntegrationPlatforms,
    digital_page_id,
    digitalPageParams,
    dispatchMixpanelEvent,
    updateDigitalPage,
    updateDigitalPageFn,
  } = params

  if (dispatchMixpanelEvent){
    dispatchMixpanelEvent('Suburb Data Saved', { digital_page_id, platforms: addedSuburbsIntegrationPlatforms })
  }
  updateDigitalPage(digitalPageParams, updateDigitalPageFn)
}

interface GetSuburbDataModalProps {
  callbacks: {
    updateDigitalPage: (arg1: Partial<DigitalPageModel>, arg2: () => void) => void,
    closeModal: () => void,
  },
  disableOverlay: boolean,
  disableAnimation: boolean,
  showModal: boolean,
}

const MODAL_KEY = 'GetSuburbDataModal'

const GetSuburbDataModal = (props: GetSuburbDataModalProps) => {
  const {
    callbacks, disableAnimation, disableOverlay, showModal,
  } = props
  const { closeModal, updateDigitalPage } = callbacks

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext
  const { digitalPage: initDigitalPage } = digObject(modalData, MODAL_KEY, {})

  const {
    callbacks: { updateDigitalPage: updateDigitalPageFn },
    digitalPage,
    updating,
  } = useDigitalPage(initDigitalPage)

  const {
    addedSuburbs,
    addedSuburbsIntegrationPlatforms,
    callbacks: {
      deleteSuburb,
      fetchSuburbData,
      refreshSuburbData,
    },
    filteredPlatforms,
    hasAddedSuburbs,
    hasSelectedSuburb,
    loading,
    saveEnabled,
    selectedIntegrationPlatformKey,
    selectedSuburb,
    setState,
  } = useSuburbData({
    digitalPage,
    organizationId: digitalPage.owner_id,
  })

  const digitalPageParams = {
    options: {
      suburb_data: addedSuburbs.map(suburbObject => suburbObject.key),
    },
  }

  const videoModulePayload = useVideoModules({ key: 'suburbData' })
  const { videosPayload } = videoModulePayload

  const { callbacks: { dispatchMixpanelEvent } } = useMixpanel()

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal
      callbacks={callbacks}
      disableAnimation={disableAnimation}
      disableOverlay={disableOverlay}
      modalKey={MODAL_KEY}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings?.sideBarModalHeader?.title || 'Suburb'}
        titleSecondLine={strings?.sideBarModalHeader?.titleSecondLine || 'Data'}
      />

      <SidebarModal.Content>
        {!hasAddedSuburbs && (
          <SidebarNoticeBox>
            <SidebarNoticeBox.Title>{strings?.sideBarNoticeBox?.title || 'Add Suburb Data'}</SidebarNoticeBox.Title>
            <SidebarNoticeBox.Paragraph boxProps={{ marginBottom: 'large' }}>
              {strings.sideBarNoticeBox?.paragraph
                  || 'To import suburb data for your page please search for the desired suburb and click Fetch Data. This will add the suburb data to the page.'}
            </SidebarNoticeBox.Paragraph>
          </SidebarNoticeBox>
        )}

        <Box flexDirection="column" flexShrink={0}>
          <FormField direction="column" label={strings?.formFieldLabels?.platformLabel || 'Platform'}>
            <select
              data-validate-field-on="change"
              onChange={e => setState({ selectedIntegrationPlatformKey: e.target.value })}
              value={selectedIntegrationPlatformKey}
            >
              <option value="">{strings.options || 'Please Select...'}</option>
              {filteredPlatforms.map(entity => (
                <option key={entity.id} value={entity.key}>
                  {entity.title}
                </option>
              ))}
            </select>
          </FormField>

          {selectedIntegrationPlatformKey && (
            <FormField
              direction="column"
              label={strings?.searchSuburb?.searchSuburb || 'Search Suburb'}
              marginTop="large"
            >
              <GoogleAddressSearch
                callbacks={{
                  selectItem: suburb => setState({ selectedSuburb: suburb }),
                }}
              />
            </FormField>
          )}

          {hasSelectedSuburb && (
            <FormField
              label={strings?.formFieldLabels?.suburb || 'Suburb'}
              marginTop="large"
            >
              <Box flexDirection={['column', 'row']}>
                <SuburbListItem boxProps={{ marginTop: 0 }} suburb={selectedSuburb} />

                <Button
                  buttonStyle="secondaryEdit"
                  disabled={!selectedIntegrationPlatformKey}
                  loading={loading}
                  marginLeft={[0, 'small']}
                  marginTop={['large', 0]}
                  onClick={() => fetchSuburbData()}
                  size="medium"
                  width={['100%', '32%']}
                >
                  {strings?.buttons?.fetch || 'Fetch Data'}
                </Button>
              </Box>
            </FormField>
          )}

          {hasAddedSuburbs && (
            <>
              <SectionDivider boxProps={{ marginTop: 'large' }}>
                {strings.addedSuburbs || 'Added Suburb Data'}
              </SectionDivider>

              {addedSuburbs.map(suburb => (
                <SuburbListItem
                  callbacks={{ deleteSuburb, refreshSuburbData }}
                  key={suburb.key}
                  suburb={suburb}
                />
              ))}
            </>
          )}
        </Box>

        <Box marginTop="large">
          <VideoModule videosPayload={videosPayload} />
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="secondary"
          onClick={() => closeModal()}
          size="medium"
          style={{ marginRight: 4, width: 'calc(50% - 4px)' }}
        >
          {strings?.buttons?.cancel || 'Cancel'}
        </Button>

        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={updating}
          loadingBubbleColor="white"
          onClick={() => handleSuburbDataUpdate({
            addedSuburbsIntegrationPlatforms,
            digital_page_id: digitalPage?.id,
            digitalPageParams,
            dispatchMixpanelEvent,
            updateDigitalPage,
            updateDigitalPageFn,
          })}
          size="medium"
          style={{ marginLeft: 4, width: 'calc(50% - 4px)' }}
        >
          {strings?.buttons?.save || 'Save'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: GetSuburbDataModalProps) => (
  <SidebarModal.RenderController {...props}>
    <GetSuburbDataModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
