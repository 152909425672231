import { EntitiesState } from '../entities'
import * as ModuleStates from './types'

// START MODULE IMPORTS
import * as activities from './activity'
import * as addresses from './address'
import * as agreements from './agreement'
import * as apiLogs from './apiLog'
import * as assetLibraries from './assetLibrary'
import * as attachments from './attachment'
import * as awards from './award'
import * as brands from './brand'
import * as caseStudies from './caseStudy'
import * as charts from './chart'
import * as comparables from './comparable'
import * as contacts from './contact'
import * as countries from './country'
import * as currentUser from './currentUser'
import * as customFieldSets from './customFieldSet'
import * as customFields from './customField'
import * as dataStoreItems from './dataStoreItem'
import * as digitalPageMetricCollections from './digitalPageMetricCollection'
import * as digitalPageViews from './digitalPageView'
import * as digitalPages from './digitalPage'
import * as digitalTemplateBuilder from './digitalTemplateBuilder'
import * as digitalTemplateComponents from './digitalTemplateComponent'
import * as digitalTemplatePages from './digitalTemplatePage'
import * as digitalTemplateTypes from './digitalTemplateType'
import * as digitalTemplates from './digitalTemplate'
import * as digitalTemplatesOrganizations from './digitalTemplatesOrganization'
import * as documentRecipients from './documentRecipient'
import * as documentTemplatePageItems from './documentTemplatePageItem'
import * as documentTemplatePages from './documentTemplatePage'
import * as documentTemplates from './documentTemplate'
import * as entities from '../entities'
import * as eventCalendarTemplates from './eventCalendarTemplate'
import * as eventCalendars from './eventCalendar'
import * as eventGroups from './eventGroup'
import * as events from './event'
import * as formTemplates from './formTemplate'
import * as images from './image'
import * as integrationPlatforms from './integrationPlatform'
import * as integrations from './integration'
import * as leaderboard from './leaderboard'
import * as navigation from './navigation'
import * as organizationTypes from './organizationType'
import * as organizations from './organization'
import * as organizationsUsers from './organizationsUser'
import * as projectTypes from './projectType'
import * as projects from './project'
import * as quoteItems from './quoteItem'
import * as quoteSections from './quoteSection'
import * as quoteTemplates from './quoteTemplate'
import * as quotes from './quote'
import * as reviews from './review'
import * as roles from './role'
import * as sessionLocks from './sessionLock'
import * as shareLinks from './shareLink'
import * as statuses from './status'
import * as suburbs from './suburb'
import * as systems from './system'
import * as tags from './tag'
import * as targetAudiences from './targetAudience'
import * as teams from './team'
import * as userTypes from './userType'
import * as users from './user'
// END MODULE IMPORTS

export type RootReducerState = {
// START ROOT REDUCER STATE
  activities: ModuleStates.ActivityModuleState,
  addresses: ModuleStates.AddressModuleState,
  apiLogs: ModuleStates.ApiLogModuleState,
  assetLibraries: ModuleStates.AssetLibraryModuleState,
  attachments: ModuleStates.AttachmentModuleState,
  awards: ModuleStates.AwardModuleState,
  brands: ModuleStates.BrandModuleState,
  caseStudies: ModuleStates.CaseStudyModuleState,
  comparables: ModuleStates.ComparableModuleState,
  contacts: ModuleStates.ContactModuleState,
  currentUser: ModuleStates.CurrentUserModuleState,
  customFields: ModuleStates.CustomFieldModuleState,
  customFieldSets: ModuleStates.CustomFieldSetModuleState,
  entities: EntitiesState,
  eventCalendars: ModuleStates.EventCalendarModuleState,
  eventGroups: ModuleStates.EventGroupModuleState,
  events: ModuleStates.EventModuleState,
  formTemplates: ModuleStates.FormTemplateModuleState,
  images: ModuleStates.ImageModuleState,
  integrationPlatforms: ModuleStates.IntegrationPlatformModuleState,
  integrations: ModuleStates.IntegrationModuleState,
  navigation: { showNavigation: boolean },
  organizations: ModuleStates.OrganizationModuleState,
  organizationsUsers: ModuleStates.OrganizationsUserModuleState,
  organizationTypes: ModuleStates.OrganizationTypeModuleState,
  projects: ModuleStates.ProjectModuleState,
  quoteSections: ModuleStates.QuoteSectionModuleState,
  quoteTemplates: ModuleStates.QuoteTemplateModuleState,
  quotes: ModuleStates.QuoteModuleState,
  reviews: ModuleStates.ReviewModuleState,
  roles: ModuleStates.RoleModuleState,
  sessionLocks: ModuleStates.SessionLockModuleState,
  shareLinks: ModuleStates.ShareLinkModuleState,
  statuses: ModuleStates.StatusModuleState,
  systems: ModuleStates.SystemModuleState,
  tags: ModuleStates.TagModuleState,
  targetAudiences: ModuleStates.TargetAudienceModuleState,
  teams: ModuleStates.TeamModuleState,
  users: ModuleStates.UserModuleState,
  userTypes: ModuleStates.UserTypeModuleState,
// END ROOT REDUCER STATE
}

const modules = {
// START MODULES LIST
  activities,
  addresses,
  agreements,
  apiLogs,
  assetLibraries,
  attachments,
  awards,
  brands,
  caseStudies,
  charts,
  comparables,
  contacts,
  countries,
  currentUser,
  customFieldSets,
  customFields,
  dataStoreItems,
  digitalPageMetricCollections,
  digitalPageViews,
  digitalPages,
  digitalTemplateBuilder,
  digitalTemplateComponents,
  digitalTemplatePages,
  digitalTemplateTypes,
  digitalTemplates,
  digitalTemplatesOrganizations,
  documentRecipients,
  documentTemplatePageItems,
  documentTemplatePages,
  documentTemplates,
  entities,
  eventCalendarTemplates,
  eventCalendars,
  eventGroups,
  events,
  formTemplates,
  images,
  integrationPlatforms,
  integrations,
  leaderboard,
  navigation,
  organizationTypes,
  organizations,
  organizationsUsers,
  projectTypes,
  projects,
  quoteItems,
  quoteSections,
  quoteTemplates,
  quotes,
  reviews,
  roles,
  sessionLocks,
  shareLinks,
  statuses,
  suburbs,
  systems,
  tags,
  targetAudiences,
  teams,
  userTypes,
  users,
// END MODULES LIST
}

export const reducers = Object.keys(modules).reduce((acc, moduleKey) => {
  const module = modules[moduleKey]
  acc[moduleKey] = module.default

  return acc
}, {})

export default modules
