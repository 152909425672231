import React from 'react'
import PropTypes from 'prop-types'

import { faCopy, faFileAlt } from '@fortawesome/pro-light-svg-icons'

import { Box, PageHeader } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'
import useNumericParams from '@hooks/useNumericParams'
import useQuote from '@hooks/useQuote'

import QuoteBuilder from '@components/QuoteBuilder'

import localizedStrings from './localizedStrings'

const PageContent = () => {
  const { quoteId } = useNumericParams()

  const {
    urls: { editQuoteTemplateUrl, quoteTemplateListUrl },
  } = useQuote({ id: quoteId })

  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      <PageHeader
        activeTabBarItemKey="edit"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        nestedNavigation
        tabBarItems={[
          {
            href: quoteTemplateListUrl,
            icon: faCopy,
            key: 'list',
            title: strings.title || 'All Templates',
          },
          {
            href: editQuoteTemplateUrl,
            icon: faFileAlt,
            key: 'edit',
            title: `Quote #${quoteId}`,
          },
        ]}
        title={strings.title || 'Edit Quote Template'}
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <QuoteBuilder quoteId={quoteId} />
      </Box>
    </>
  )
}

PageContent.propTypes = {}

export default PageContent
