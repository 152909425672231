import { useEffect } from 'react'

import { useLatestEntity } from '@campaignhub/react-hooks'

import useCurrentUser from '@hooks/useCurrentUser'
import useDispatch from '@hooks/useDispatch'
import useSelector from '@hooks/useSelector'

import { baseURL } from '@functions/api'

import * as sessionLockActions from '@redux/modules/sessionLock'

import type { AppDispatch } from '@redux/store'
import type { SessionLockModel, SessionLockRequestOptions } from '@models/types'

const currentTime = () => new Date().toISOString()

type CreateSessionLockParams = {
  dispatch: AppDispatch,
  requestOptions?: SessionLockRequestOptions,
  sessionLockParams: Partial<SessionLockModel>,
}

const createSessionLock = (params: CreateSessionLockParams) => {
  const { dispatch, sessionLockParams, requestOptions } = params
  const { createSessionLock: createFn } = sessionLockActions

  return dispatch(createFn(sessionLockParams, requestOptions))
}

export const useRelations = (sessionLock: Partial<SessionLockModel> = {}) => {
  const { locked_by_user_id } = sessionLock

  const { users } = useSelector(reduxState => reduxState.entities)

  const user = locked_by_user_id ? users[locked_by_user_id] || {} : {}

  return {
    user,
  }
}

function useSessionLock(initEntity: Partial<SessionLockModel> = {}) {
  const { entity: sessionLock }: { entity: SessionLockModel} = useLatestEntity(initEntity, 'sessionLocks')

  const dispatch = useDispatch()

  const { currentUser } = useCurrentUser()
  const sessionLockedByCurrentUser = sessionLock.id && sessionLock.locked_by_user_id === currentUser.id

  // Handle Unload
  const handleUnloadEvent = () => {
    const { unlockSessionLock } = sessionLockActions

    const updatedSessionLock = {
      ...sessionLock,
      dates: {
        ...sessionLock.dates,
        unlocked: {
          date_time_with_timezone: currentTime(),
        },
      },
    }

    navigator.sendBeacon(`${baseURL}/session_locks/${sessionLock.id}/unlock`)
    dispatch(unlockSessionLock(updatedSessionLock))
  }

  // Setup Unload Listener
  useEffect(() => {
    if (!sessionLock.id || !sessionLockedByCurrentUser){
      return undefined
    }

    window.addEventListener('beforeunload', handleUnloadEvent)
    return () => {
      handleUnloadEvent()
      window.removeEventListener('beforeunload', handleUnloadEvent)
    }
  }, [sessionLock.id, sessionLockedByCurrentUser])

  return {
    callbacks: {
      createSessionLock: (sessionLockParams: Partial<SessionLockModel>, entityOptions?: SessionLockRequestOptions) => (
        createSessionLock({ sessionLockParams, dispatch, requestOptions: entityOptions })
      ),
    },
    sessionLock,
    sessionLockedByCurrentUser,
  }
}

export default useSessionLock
