import { useContext } from 'react'

import { useLatestEntity } from '@campaignhub/react-hooks'

import PageContext from '@contexts/pageContext'

const viewDigitalPageMetricCollection = (digitalPageMetricCollection, showViewDigitalPageMetricCollectionModal) => new Promise((resolve, reject) => {
  if (showViewDigitalPageMetricCollectionModal){
    const payload = {
      digitalPageMetricCollection,
    }

    showViewDigitalPageMetricCollectionModal(payload)

    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showViewDigitalPageMetricCollectionModal not defined in PageContext callbacks'))
})

const useDigitalPageMetricCollection = (initDigitalPageMetricCollection = {}) => {
  const { entity: digitalPageMetricCollection } = useLatestEntity(
    initDigitalPageMetricCollection,
    'digitalPageMetricCollections',
  )

  const pageContext = useContext(PageContext)
  const { callbacks } = pageContext
  const { showViewDigitalPageMetricCollectionModal } = callbacks || {}

  return {
    callbacks: {
      viewDigitalPageMetricCollection: () => viewDigitalPageMetricCollection(digitalPageMetricCollection, showViewDigitalPageMetricCollectionModal),
    },
    digitalPageMetricCollection,
  }
}

export default useDigitalPageMetricCollection
