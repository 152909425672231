import { useEffect } from 'react'
import { useSetState } from '@campaignhub/react-hooks'
import { digObject, toggleArray } from '@campaignhub/javascript-utils'

import useEventCalendars from '@hooks/useEventCalendars'
import { useRelations as useDigitalPageRelations } from '@hooks/useDigitalPage'

import { DigitalPageModel } from '@models/digitalPage'
import { EventCalendarModel } from '@models/types'

type DefaultStateType = {
  selectedCalendarIds: number[],
}

const defaultState: DefaultStateType = {
  selectedCalendarIds: [],
}

type SetState = (state: Partial<typeof defaultState>) => void

type ToggleEventCalendar = {
  calendarId: number,
  setState: SetState,
  state: Partial<DefaultStateType>,
}

const toggleEventCalendar = (params: ToggleEventCalendar) => {
  const { calendarId, state, setState } = params
  const { selectedCalendarIds } = state
  const updatedEventCalendarIds: number[] = toggleArray(selectedCalendarIds, calendarId)

  setState({ selectedCalendarIds: updatedEventCalendarIds })
}

type ToggleAllEventCalendars = {
  calendars: EventCalendarModel[],
  setState: SetState,
  state: Partial<DefaultStateType>,
}

const toggleAllEventCalendars = (params: ToggleAllEventCalendars) => {
  const { calendars, state, setState } = params
  const { selectedCalendarIds } = state

  if (calendars.length === selectedCalendarIds?.length){
    setState({ selectedCalendarIds: [] })
    return
  }

  setState({ selectedCalendarIds: calendars.map(calendar => (calendar.id)) })
}

type UseManageEventCalendarsOptionsType = {
    performHttpRequests?: boolean,
}

// eslint-disable-next-line max-len
function useManageEventCalendars(digitalPage: Partial<DigitalPageModel>, options: UseManageEventCalendarsOptionsType = {}) {
  const { performHttpRequests } = options || {}

  const [state, setState] = useSetState(defaultState)
  const { selectedCalendarIds } = state

  const digitalPageEventCalendarIds = digObject(digitalPage, 'event_calendar_ids', [])

  const { project } = useDigitalPageRelations(digitalPage)

  const {
    filteredEventCalendars,
    hasEventCalendars,
    loading,
  } = useEventCalendars({
    filters: {
      ownerId: project.organization_id,
      ownerType: 'Organization',
      subjectId: project.id,
      subjectType: 'Project',
    },
    performHttpRequests,
  })

  useEffect(() => {
    if (digitalPage.id){
      setState({ selectedCalendarIds: digitalPageEventCalendarIds })
    }
  }, [digitalPage.id])

  return {
    callbacks: {
      toggleEventCalendar: (id: number) => toggleEventCalendar({ calendarId: id, state, setState }),
      toggleAllEventCalendars: () => toggleAllEventCalendars({ calendars: filteredEventCalendars, state, setState }),
    },
    filteredEventCalendars,
    hasEventCalendars,
    loading,
    selectedCalendarIds,
  }
}

export default useManageEventCalendars
