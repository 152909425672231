import React from 'react'

import { BlankState } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

import projectImageUrl from './assets/no-projects.svg'

const ProjectsBlankState = () => {
  const { strings } = useLocalization(localizedStrings)

  return (
    <BlankState boxProps={{ marginBottom: 'large' }} imageUrl={projectImageUrl}>
      <BlankState.Title>{strings.title || 'No Projects Created'}</BlankState.Title>

      <BlankState.Paragraph>{strings.paragraph || "You either haven't created any projects, or you need to adjust your filters to return more results. If you haven't started yet, you can create one now by clicking the button at the top of the page."}</BlankState.Paragraph>
    </BlankState>
  )
}

export default ProjectsBlankState
