import React from 'react'

import { BlankState } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

import agreementsImageUrl from './assets/agreements.svg'

const AgreementBlankState = () => {
  const { strings } = useLocalization(localizedStrings)

  return (
    <BlankState imageUrl={agreementsImageUrl}>
      <BlankState.Title>
        {strings.title || 'Could not Generate PDF Preview'}
      </BlankState.Title>

      <BlankState.Paragraph>
        {strings.paragraph || 'An error has occured causing the PDF not to generate. Please refresh your browser, if the issue persists please contact support.'}
      </BlankState.Paragraph>
    </BlankState>
  )
}

export default AgreementBlankState
