import { faHome } from '@fortawesome/pro-light-svg-icons'

import useLocalization from '@hooks/useLocalization'
import useReduxAction from '@hooks/useReduxAction'

import { Box, DashboardModule, Link } from '@campaignhub/suit-theme'

import useProjects from '@hooks/useProjects'

import type { OrganizationModel } from '@models/organization'

import CompactList from './CompactList'
import LargeList from './LargeList'

import ProjectImageUrl from './assets/projects.svg'

import localizedStrings from './localizedStrings'

export const icon = faHome

export const configOptions = (organizations: OrganizationModel[], strings: Record<string, {}>) => ({
  title: {
    component: 'input',
    key: 'title',
    label: strings.labelTitle?.title || 'Title',
    optionKey: 'customization',
  },
  organization: {
    component: 'select',
    key: 'organization_id',
    label: strings.organization?.label || 'Organization',
    optionKey: 'queryParams',
    values: organizations.map(organization => ({
      key: organization.id,
      label: organization.title,
    })),
  },
  display_mode: {
    component: 'select',
    key: 'display_mode',
    label: strings.displayMode?.label || 'Display Mode',
    optionKey: 'customization',
    values: [
      { key: 'compact-list', label: strings.displayModeValues?.labelListCompact || 'List (Compact)' },
      { key: 'large-list', label: strings.displayModeValues?.labelListLarge || 'List (Large)' },
    ],
  },
  limit: {
    component: 'select',
    key: 'limit',
    label: strings.limit?.label || 'Limit',
    optionKey: 'queryParams',
    values: [
      { key: 5, label: '5' },
      { key: 10, label: '10' },
      { key: 25, label: '25' },
      { key: 50, label: '50' },
    ],
  },
})

const defaultRequestOptions = {
  include_project_address: true,
  include_project_lead_users: true,
  include_project_teams: true,
}

interface ProjectsProps {
  customOptions: {
    customization: {
      display_mode: string,
      title: string,
    },
    queryParams: {
      limit: number,
      organization_id: number,
    },
  },
  editing: boolean,
  parentId: string,
}

const Projects = (props: ProjectsProps) => {
  const {
    customOptions,
    customOptions: {
      queryParams: { limit, organization_id },
    },
    editing,
    parentId,
  } = props

  const requestOptions = {
    ...defaultRequestOptions,
    ...customOptions.queryParams,
  }

  useReduxAction('projects', 'loadProjects', requestOptions, [parentId, JSON.stringify(requestOptions)])

  const { filteredProjects, hasFilteredProjects, loading } = useProjects({
    filters: {
      organization_id,
    },
    limit,
  })

  const { customization } = customOptions
  const { display_mode, title } = customization || {}

  const { strings } = useLocalization(localizedStrings)

  return (
    <DashboardModule
      boxProps={{ marginBottom: 0 }}
      editing={editing}
      headerRight={(
        <Link href="#/overviews/projects?status=current" textProps={{ fontSize: 'small' }}>
          {strings.manage || 'Manage'}
        </Link>
      )}
      loading={loading}
      title={title || strings.title?.title || 'Projects'}
    >
      {!loading && !hasFilteredProjects && (
        <DashboardModule.BlankState imageUrl={ProjectImageUrl}>
          <DashboardModule.BlankState.Title>
            {strings.blankState?.title || 'No Projects to Display'}
          </DashboardModule.BlankState.Title>

          <DashboardModule.BlankState.Paragraph>
            {strings.blankState?.paragraph
              // eslint-disable-next-line max-len
              || 'You either have no projects or the filters defined returned no results. Create a new project or change your filters.'}
          </DashboardModule.BlankState.Paragraph>
        </DashboardModule.BlankState>
      )}

      {!loading && hasFilteredProjects && (
        <Box flexDirection="column" flexWrap="wrap">
          {filteredProjects.map((project) => {
            const ListComponent = display_mode === 'compact-list' ? CompactList : LargeList

            return <ListComponent key={project.id} project={project} />
          })}
        </Box>
      )}
    </DashboardModule>
  )
}

export default Projects
