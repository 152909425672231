import { MainContent, TopBar } from '@campaignhub/suit-theme'

import useCurrentUser from '@hooks/useCurrentUser'
import useMainNavigation from '@hooks/useMainNavigation'

import DashboardSearch from '@components/DashboardSearch'
import LoggedInUser from '@components/LoggedInUser'
import MainNavigation from '@sections/Admin/components/MainNavigation'

import DashboardRoutes from './routes'
import Reports from '../SystemManager/screens/Reports'

const Dashboard = () => {
  const { callbacks: { toggleNavigation } } = useMainNavigation()
  const { isBrandUser } = useCurrentUser()

  return (
    <>
      <TopBar
        callbacks={{ toggleNavigation }}
        loggedInUserComponent={<LoggedInUser />}
        searchComponent={<DashboardSearch searchEntities={['Brand', 'DigitalTemplate', 'Organization', 'User']} />}
      />

      <MainNavigation />

      <MainContent offset={{ top: TopBar.topBarHeight }}>
        {/* TODO: This is a temporary solution whilst the BrandAdmin Overview page is being built */}
        {/* TODO: Once the Overview page is built, this should be removed. */}
        {isBrandUser ? <Reports /> : <DashboardRoutes />}
      </MainContent>
    </>
  )
}

export default Dashboard
