export default {
  default: {
    deleteButton: 'Delete',
    loading: 'Loading ...',
    swal: {
      title: 'Delete Quote?',
      text: 'Are you sure?',
      confirmButtonText: 'Yes, Delete it.',
    },
  },
}
