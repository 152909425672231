import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import { Box, Image } from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useImage from '@hooks/useImage'

import PlaceHolderImage from './assets/placeholderImage.png'
import styles from './styles.module.scss'

const Thumbnail = (props) => {
  const {
    creating,
    callbacks: {
      confirmDelete,
      selectImage,
    },
    image,
    viewMode,
  } = props

  const { callbacks: { deleteImage } } = useImage(image)

  const imageUrl = digObject(image, 'sizes.thumb_660x360')

  const { owner_type: imageOwnerType } = image

  return (
    <Box className={styles.root} backgroundImage={`url(${PlaceHolderImage})`} borderRadius={5} position="relative">
      {imageOwnerType !== 'Brand' && (
      <Box className={styles.button} onClick={() => confirmDelete(deleteImage)} variant="white" width="35px">
        <FontAwesomeIcon icon={faTrashAlt} />
      </Box>
      )}
      <Image
        boxProps={{
          alignItems: 'center',
          onClick: creating ? null : () => selectImage(image),
        }}
        borderRadius={5}
        height={viewMode === 'large' ? 200 : 110}
        style={{ cursor: 'pointer' }}
        url={imageUrl}
        width="100%"
      />
    </Box>
  )
}

Thumbnail.propTypes = {
  creating: PropTypes.bool,
  callbacks: PropTypes.shape({
    confirmDelete: PropTypes.func.isRequired,
    selectImage: PropTypes.func.isRequired,
  }).isRequired,
  image: PropTypes.object.isRequired,
  viewMode: PropTypes.oneOf(['small', 'large']).isRequired,
}

export default Thumbnail
