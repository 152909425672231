import { useContext } from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faArrowDown, faArrowUp } from '@fortawesome/pro-light-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'
import {
  Box, Button, FormField, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'
import useQuoteSection, { useQuoteSectionForm } from '@hooks/useQuoteSection'

import localizedStrings from './localizedStrings'

const confirmDelete = (deleteQuoteSection, deleteFn, strings) => {
  swal
    .fire({
      title: strings.sweetAlert?.title || 'Delete Section?',
      text: strings.sweetAlert?.text || 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: strings.sweetAlert?.confirm || 'Yes, Delete it.',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        deleteQuoteSection(deleteFn)
      }
    })
}

const moveSection = (params) => {
  const {
    callbacks, currentIndex, direction, moveFn, sectionId,
  } = params

  const { closeModal } = callbacks
  const newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1

  moveFn(sectionId, currentIndex, newIndex).then(() => {
    // Need to close the modal so that when can reset the index when editing the section again
    closeModal()
  })
}

const CreateOrEditQuoteSectionModal = (props) => {
  const {
    callbacks,
    callbacks: { createQuoteSection, deleteQuoteSection, updateQuoteSection },
    clickSafeZone,
    modalKey,
    quoteSection: newQuoteSection,
    showModal,
  } = props

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, `${modalKey}`, {})

  const {
    callbacks: modalCallbacks, quoteSection: existingQuoteSection, first, index, last,
  } = modalPayload

  const { createQuoteSection: createFn, updateQuoteSection: updateFn, moveQuoteSectionId: moveFn } = modalCallbacks || {}

  const quoteSection = existingQuoteSection || newQuoteSection
  const newRecord = !quoteSection.id

  const quoteSectionPayload = useQuoteSection(quoteSection)
  const {
    callbacks: { deleteQuoteSection: deleteFn },
    creating,
    deleting,
    updating,
  } = quoteSectionPayload

  const quoteSectionFormPayload = useQuoteSectionForm(quoteSection)
  const {
    entityState,
    entityState: {
      footer,
      header,
      options: { hide_dates, hide_prices, hide_subtotal },
      title,
    },
    handlers,
    saveEnabled,
  } = quoteSectionFormPayload

  const actionFn = newRecord
    ? () => createQuoteSection(entityState, createFn)
    : () => updateQuoteSection(entityState, updateFn)

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal
      callbacks={callbacks}
      clickSafeZone={clickSafeZone}
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header
        callbacks={callbacks}
        title={newRecord ? strings.createTitle || 'Create' : strings.editTitle || 'Edit'}
        titleSecondLine={strings.titleSecondLine || 'Quote Section'}
      />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <FormField direction="column" label={strings.formFieldLabels?.title || '* Title'}>
            <input name="title" type="text" value={title} {...handlers} />
          </FormField>

          <FormField direction="column" label={strings.formFieldLabels?.header || 'Header'} marginTop="large">
            <textarea name="header" value={header} style={{ height: 80 }} {...handlers} />
          </FormField>

          <FormField direction="column" label={strings.formFieldLabels?.footer || 'Footer'} marginTop="large">
            <textarea name="footer" value={footer} style={{ height: 80 }} {...handlers} />
          </FormField>

          <FormField direction="column" label={strings.formFieldLabels?.hideDates || 'Hide Dates'} marginTop="large">
            <select data-value-type="boolean" name="options.hide_dates" value={hide_dates} {...handlers}>
              <option value="false">{strings.formFieldLabels?.no || 'No'}</option>
              <option value="true">{strings.formFieldLabels?.yes || 'Yes'}</option>
            </select>
          </FormField>

          <FormField direction="column" label={strings.formFieldLabels?.hidePrices || 'Hide Prices'} marginTop="large">
            <select data-value-type="boolean" name="options.hide_prices" value={hide_prices} {...handlers}>
              <option value="false">{strings.formFieldLabels?.no || 'No'}</option>
              <option value="true">{strings.formFieldLabels?.yes || 'Yes'}</option>
            </select>
          </FormField>

          <FormField
            direction="column"
            label={strings.formFieldLabels?.hideSubtotal || 'Hide Subtotal'}
            marginTop="large"
          >
            <select data-value-type="boolean" name="options.hide_subtotal" value={hide_subtotal} {...handlers}>
              <option value="false">{strings.formFieldLabels?.no || 'No'}</option>
              <option value="true">{strings.formFieldLabels?.yes || 'Yes'}</option>
            </select>
          </FormField>

          {!(first && last) && !newRecord && (
            <FormField
              direction="column"
              label={strings.formFieldLabels?.position || 'Position'}
              marginTop="large"
            >
              {!first && (
                <Button
                  buttonStyle="secondaryUtility"
                  icon={<FontAwesomeIcon icon={faArrowUp} />}
                  onClick={() => moveSection({
                    callbacks, currentIndex: index, direction: 'up', moveFn, sectionId: quoteSection.id,
                  })}
                  size="medium"
                  style={{ marginBottom: 8, width: '100%' }}
                  text="Move Section Up"
                >
                  {strings.button?.moveUp || 'Move Section Up'}
                </Button>
              )}

              {!last && (
                <Button
                  buttonStyle="secondaryUtility"
                  icon={<FontAwesomeIcon icon={faArrowDown} />}
                  onClick={() => moveSection({
                    callbacks, currentIndex: index, direction: 'down', moveFn, sectionId: quoteSection.id,
                  })}
                  size="medium"
                  style={{ marginBottom: 8, width: '100%' }}
                >
                  {strings.button?.moveDown || 'Move Section Down'}
                </Button>
              )}
            </FormField>
          )}

          {!newRecord && (
            <FormField
              direction="column"
              label={strings.formFieldLabels?.moreOptions || 'More Options'}
              marginTop="large"
            >
              <Button
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faTrashAlt} />}
                marginBottom="medium"
                onClick={() => confirmDelete(deleteQuoteSection, deleteFn, strings)}
                size="medium"
              >
                {strings.deleteButton || 'Delete Section'}
              </Button>
            </FormField>
          )}
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={creating || deleting || updating}
          loadingBubbleColor="white"
          onClick={actionFn}
          size="large"
        >
          {strings.saveButton || 'Save Section'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

CreateOrEditQuoteSectionModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  clickSafeZone: PropTypes.bool,
  modalKey: PropTypes.string,
  quoteSection: PropTypes.object,
  showModal: PropTypes.bool,
}

CreateOrEditQuoteSectionModal.defaultProps = {
  modalKey: 'CreateOrEditQuoteSectionModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <CreateOrEditQuoteSectionModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
