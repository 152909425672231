import { useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import cloneDeep from 'lodash/cloneDeep'

import { digObject, sortArrayBy } from '@campaignhub/javascript-utils'
import {
  useForm, useLatestEntity, useThunkDispatch, useWatchEntityUpdates,
} from '@campaignhub/react-hooks'

import generateRedirectUrl from '@functions/generateRedirectUrl'

import defaultFormState, { requiredFields } from '@models/documentTemplate'

import { availableFeatures } from '@functions/agreement'

import * as documentTemplateActions from '@redux/modules/documentTemplate'

const watchEntityKeys = ['documentRecipients', 'documentTemplatePageItems', 'documentTemplatePages', 'images']

export function generateUrls(documentTemplate = {}){
  const { id } = documentTemplate

  return {
    editDocumentTemplateUrl: `#/documentTemplates/${id}/edit?redirect=${generateRedirectUrl()}`,
    documentTemplatesIndexUrl: '#/admin/documentTemplates',
    draftDocumentTemplates: '#/admin/documentTemplates?status=draft',
    publishedDocumentTemplates: '#/admin/documentTemplates?status=published',
  }
}

const deleteDocumentTemplate = (documentTemplate, dispatch) => {
  const { deleteDocumentTemplate: deleteFn } = documentTemplateActions

  return dispatch(deleteFn(documentTemplate)).then((response) => {
    const { success } = response

    if (success){
      const { documentTemplatesIndexUrl } = generateUrls()

      return {
        ...response,
        redirectUrl: documentTemplatesIndexUrl,
      }
    }
  })
}

const createDocumentTemplate = (documentTemplateParams, dispatch) => {
  const { createDocumentTemplate: createFn } = documentTemplateActions
  const { options } = documentTemplateParams || {}

  const updatedParams = {
    ...documentTemplateParams,
    options: JSON.stringify(options),
  }

  return dispatch(createFn(updatedParams)).then((response) => {
    const { success, data } = response
    if (success){
      const {
        entity: { id },
      } = data

      const { editDocumentTemplateUrl } = generateUrls({ id })

      return {
        success,
        data,
        redirectUrl: editDocumentTemplateUrl,
      }
    }

    return response
  })
}

const updateDocumentTemplate = (documentTemplate, documentTemplateParams, dispatch, requestOptions) => {
  const { updateDocumentTemplate: updateFn } = documentTemplateActions
  const { data } = documentTemplateParams

  const updatedParams = {
    ...documentTemplateParams,
  }

  if (data){
    const documentTemplateOptions = documentTemplate.data || {}

    updatedParams.data = JSON.stringify({
      ...cloneDeep(documentTemplateOptions),
      ...data,
    })
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

export function useDocumentTemplateForm(documentTemplate = {}, options = {}){
  const { customRequiredFields = [] } = options

  const { creating, loading, updating } = useSelector(reduxState => reduxState.documentTemplates)

  const documentTemplateForm = useForm(
    defaultFormState,
    { entity: documentTemplate, requiredFields: [...requiredFields, ...customRequiredFields], validateOn: 'change' },
    [documentTemplate.id, documentTemplate.cache_key],
  )

  return {
    ...documentTemplateForm,
    creating,
    loading,
    updating,
  }
}

function useDocumentTemplate(initEntity){
  const { entity: documentTemplate } = useLatestEntity(initEntity, 'documentTemplates')
  const { id } = documentTemplate

  const {
    updatedEntities: {
      documentRecipients: documentRecipientsUpdatedAt,
      documentTemplatePageItems: documentTemplatePageItemsUpdatedAt,
      documentTemplatePages: documentTemplatePagesUpdatedAt,
      images: imagesUpdatedAt,
    },
  } = useWatchEntityUpdates(watchEntityKeys)

  const dispatch = useThunkDispatch()

  const entitiesRef = useRef()
  entitiesRef.current = useSelector(reduxState => reduxState.entities)

  const {
    documentRecipients,
    documentTemplatePageItems,
    documentTemplatePages,
    images,
  } = entitiesRef.current

  // Pages
  const filteredPages = useMemo(() => {
    const array = Object.values(documentTemplatePages)
    const filtered = array.filter((documentTemplatePage) => {
      const { document_template_id } = documentTemplatePage
      return document_template_id === id
    })

    const sorted = sortArrayBy(filtered, 'asc', 'sort')

    return sorted
  }, [id, documentTemplatePagesUpdatedAt])

  // Page Preview Images
  const filteredPagePreviewImages = useMemo(() => {
    const array = Object.values(images)
    const filtered = array.filter((image) => {
      const { subject_id, subject_type } = image
      return subject_id === id && subject_type === 'DocumentTemplate'
    })

    const sorted = sortArrayBy(filtered, 'asc', 'sort')

    return sorted
  }, [id, imagesUpdatedAt])

  // Recipients
  const filteredRecipients = useMemo(() => {
    const array = Object.values(documentRecipients)
    const filtered = array.filter((documentRecipient) => {
      const { owner_id, owner_type } = documentRecipient
      return owner_id === id && owner_type === 'DocumentTemplate'
    })

    const sorted = sortArrayBy(filtered, 'asc', 'sort')

    return sorted
  }, [id, documentRecipientsUpdatedAt])

  const enabledFeatures = availableFeatures.filter((feature) => {
    const enabled = Object.values(documentTemplatePageItems).some((pageItem) => {
      const rawContext = digObject(pageItem, 'options.data_context', '')
      const context = rawContext.split('.')[0]

      return context === feature.key
    })

    return enabled
  })

  const hasPages = !!filteredPages.length
  const hasRecipients = !!filteredRecipients.length

  return {
    callbacks: {
      deleteDocumentTemplate: () => deleteDocumentTemplate(documentTemplate, dispatch),
      updateDocumentTemplate: (documentTemplateParams, requestOptions) => updateDocumentTemplate(documentTemplate, documentTemplateParams, dispatch, requestOptions),
      createDocumentTemplate: params => createDocumentTemplate(params, dispatch),
    },
    documentTemplate,
    enabledFeatures,
    filteredPagePreviewImages,
    filteredPages,
    filteredRecipients,
    hasPages,
    hasRecipients,
  }
}

export default useDocumentTemplate
