import { digObject } from '@campaignhub/javascript-utils'

import { Box, Image } from '@campaignhub/suit-theme'

import useImage from '@hooks/useImage'

import type { ImageModel } from '@models/image'

import placeholderImageUrl from '../../assets/default_campaign_image_400x400.png'

interface GridItemProps {
  image: ImageModel,
  updateDefaultImage: (image: ImageModel) => void,
}

const GridItem = (props: GridItemProps) => {
  const { image, updateDefaultImage } = props

  const imageUrl = digObject(image, 'sizes.thumb_200x120', '')

  const { callbacks: { editImage } } = useImage(image)

  return (
    <Box
      onClick={() => editImage(() => updateDefaultImage(image))}
      style={{ cursor: 'pointer' }}
    >
      <Image
        borderRadius={5}
        height={123}
        placeholderUrl={placeholderImageUrl}
        url={imageUrl}
        width="100%"
      />
    </Box>
  )
}

export default GridItem
