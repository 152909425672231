import PropTypes from 'prop-types'
import swal from 'sweetalert2'
import { toast } from 'react-toastify'

import {
  Box, Button, FileInput, FormField, SidebarModal,
} from '@campaignhub/suit-theme'

import useDigitalTemplatePage, { useDigitalTemplatePageForm } from '@hooks/useDigitalTemplatePage'

import AuthenticatedDownload from '@components/AuthenticatedDownload'

const confirmImport = (importLayoutFn, entityState) => {
  swal
    .fire({
      title: 'Import Layout',
      text: 'Are you sure? You will lose all other data. ',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, import it.',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        importLayoutFn(entityState)
      }
    })
}

const importExternalComponentStyles = async (importExternalComponentStylesFn) => {
  await importExternalComponentStylesFn()
  toast('Component styles created.')
}

const importExternalImages = async (importExternalImagesFn) => {
  await importExternalImagesFn()
  toast('Images are downloading in background.')
}

const ImportAndExport = (props) => {
  const {
    digitalTemplatePayload: { selectedDigitalTemplatePage },
  } = props

  const { entityState, setEntityState } = useDigitalTemplatePageForm(selectedDigitalTemplatePage)

  const {
    callbacks: {
      importLayout: importLayoutFn,
      importExternalImages: importExternalImagesFn,
      importExternalComponentStyles: importExternalComponentStylesFn,
    },
  } = useDigitalTemplatePage(selectedDigitalTemplatePage)

  return (
    <SidebarModal.ExpandableSectionBox label="Import/Export">
      <FormField label="Export File">
        <AuthenticatedDownload>
          <Button
            as="a"
            buttonStyle="ghostCreate"
            href={`/digital_template_pages/${selectedDigitalTemplatePage.id}/export_layout_data.json`}
            size="medium"
          >
            Export File
          </Button>
        </AuthenticatedDownload>
      </FormField>

      <FormField label="Import File (.json)" boxProps={{ marginTop: 'large' }}>
        <Box flexDirection="column">
          <FileInput
            name="import_file"
            input={{
              onChange: fileData => setEntityState({ layout_file: fileData }),
            }}
          />

          <Button
            as="a"
            buttonStyle="ghostCreate"
            onClick={() => confirmImport(importLayoutFn, entityState)}
            marginTop="medium"
            size="medium"
          >
            Import File
          </Button>
        </Box>
      </FormField>

      <FormField label="Import External Items" boxProps={{ marginTop: 'large' }}>
        <Button
          as="a"
          buttonStyle="ghostCreate"
          onClick={() => importExternalImages(importExternalImagesFn)}
          size="medium"
        >
          Import Images
        </Button>

        <Button
          as="a"
          buttonStyle="ghostCreate"
          marginTop="medium"
          onClick={() => importExternalComponentStyles(importExternalComponentStylesFn)}
          size="medium"
        >
          Import Component Styles
        </Button>
      </FormField>
    </SidebarModal.ExpandableSectionBox>
  )
}

ImportAndExport.propTypes = {
  digitalTemplatePayload: PropTypes.object.isRequired,
}

export default ImportAndExport
