import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, Button, FormField, SidebarModal,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'
import useVideoPageItem from './hooks/useVideoPageItem'

const Video = (props) => {
  const { callbacks, digitalPageItemPayload } = props
  const { updateDigitalPageItemCustomData } = callbacks || {}

  const { callbacks: { updatePageItemCustomData: updateFn } } = digitalPageItemPayload

  const videoPayload = useVideoPageItem(digitalPageItemPayload)
  const {
    callbacks: { autoFillVideoDetails },
    formPayload: {
      entityState,
      entityState: { videoId, videoProvider, videoUrl },
      handlers,
      setEntityState,
    },
  } = videoPayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      <SidebarModal.Header
        callbacks={callbacks}
        disableAnimation
        title={strings.title || 'Edit'}
        titleSecondLine={strings.titleSecondLine || 'Video'}
      />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <FormField direction="column" boxProps={{ marginBottom: 'large' }} label={strings.formFieldLabels?.videoUrl || 'URL'}>
            <input
              name="videoUrl"
              onBlur={autoFillVideoDetails}
              onChange={e => setEntityState({ videoUrl: e.target.value })}
              type="text"
              value={videoUrl}
            />
          </FormField>
        </Box>

        <Box flexDirection="row">
          <FormField
            direction="column"
            boxProps={{ marginBottom: 'large', marginRight: 'small', width: 'calc(50% - 4px)' }}
            label={strings.formFieldLabels?.videoProvider || 'Provider'}
          >
            <select name="videoProvider" value={videoProvider} {...handlers} data-validate-field-on="change">
              <option value="">{strings.selectVideoProvider?.selectDefault || 'Please Select...'}</option>
              <option value="youtube">{strings.selectVideoProvider?.youtube || 'Youtube'}</option>
              <option value="vimeo">{strings.selectVideoProvider?.vimeo || 'Vimeo'}</option>
            </select>
          </FormField>

          <FormField
            direction="column"
            boxProps={{ marginBottom: 'large', marginLeft: 'small', width: 'calc(50% - 4px)' }}
            label={strings.formFieldLabels?.videoId || 'Video ID'}
          >
            <input data-validate-field-on="change" name="videoId" type="text" value={videoId} {...handlers} />
          </FormField>
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          onClick={() => updateDigitalPageItemCustomData(entityState, updateFn)}
          size="large"
        >
          {strings.buttons?.save || 'Save'}
        </Button>
      </SidebarModal.Footer>
    </>
  )
}

Video.propTypes = {
  callbacks: PropTypes.object.isRequired,
  digitalPageItemPayload: PropTypes.object.isRequired,
}

export default Video
