import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'
import { toast } from 'react-toastify'

import { useModals, useSetState } from '@campaignhub/react-hooks'
import { MainContent, ModalContext } from '@campaignhub/suit-theme'

import handleCallbackAction from '@functions/handleCallbackAction'

import CreateOrEditCustomFieldModal from '@modals/CreateOrEditCustomFieldModal'
import CreateOrEditDocumentRecipientModal from '@modals/CreateOrEditDocumentRecipientModal'

import useEntityCustomField from '@hooks/useEntityCustomField'
import useIntercom from '@hooks/useIntercom'
import useLocalization from '@hooks/useLocalization'

import useDocumentTemplateBuilder, { defaultRequestOptions } from './hooks/useDocumentTemplateBuilder'

import AddDocumentTemplateFieldModal from './modals/AddDocumentTemplateFieldModal'
import EditDocumentTemplateFieldModal from './modals/EditDocumentTemplateFieldModal'
import ManageDocumentTemplateBuilderModal from './modals/ManageDocumentTemplateBuilderModal'

import BuilderContextProvider from './components/BuilderContextProvider'
import Canvas from './components/Canvas'
import Header from './components/Header'

import localizedStrings from './localizedStrings'

const createDocumentRecipient = (documentRecipient, createFn, setState, strings) => {
  createFn(documentRecipient).then((result) => {
    const { errors, success } = result
    if (!success){
      toast.warn(errors[0])
      return
    }

    toast(strings.toasts?.recipient?.created || 'Recipient Created.')

    setState({ showCreateOrEditDocumentRecipientModal: false })
  })
}

const updateDocumentTemplate = (documentTemplate, updateFn, setState, strings) => {
  updateFn(documentTemplate, defaultRequestOptions.documentTemplate).then(({ success, errors }) => {
    if (!success){
      toast.warning(errors[0])
      return
    }

    setState({ showCreateDocumentTemplateModal: false })
    toast(strings.toasts?.documentTemplate?.updated || 'Document Template Updated.')
  })
}

const deleteDocumentTemplate = (deleteFn, strings) => {
  deleteFn().then(({ success, errors, redirectUrl }) => {
    if (!success){
      toast.warning(errors[0])
      return
    }
    if (redirectUrl){
      toast(strings.toasts?.documentTemplate?.deleted || 'Document Template Deleted.')
      window.location.href = redirectUrl
    }
  })
}

const deleteDocumentTemplatePageItem = (deleteFn, setState, strings) => {
  deleteFn().then((result) => {
    const { errors, success } = result
    if (!success){
      toast.warn(errors[0])
      return
    }

    toast(strings.toasts?.field?.deleted || 'Field Deleted.')

    setState({ showEditDocumentTemplateFieldModal: false })
  })
}

const updateDocumentRecipient = (documentRecipient, updateFn, setState, strings) => {
  updateFn(documentRecipient).then((result) => {
    const { errors, success } = result
    if (!success){
      toast.warn(errors[0])
      return
    }

    toast(strings.toasts?.recipient?.updated || 'Recipient Updated.')

    setState({ showCreateOrEditDocumentRecipientModal: false })
  })
}

const updateDocumentTemplatePageItem = (documentTemplatePageItem, updateFn, setState, strings) => {
  updateFn(documentTemplatePageItem).then((result) => {
    const { errors, success } = result
    if (!success){
      toast.warn(errors[0])
      return
    }

    toast(strings.toasts?.field?.updated || 'Field Updated.')

    setState({ showEditDocumentTemplateFieldModal: false })
  })
}

const deleteAndReassignPageItems = (targetRecipientId, deleteFn, setState, strings) => {
  deleteFn(targetRecipientId).then((result) => {
    const { errors, success } = result
    if (!success){
      toast.warn(errors[0])
      return
    }

    toast(strings.toasts?.recipient?.deleted || 'Recipient Deleted and Items Reassigned')

    setState({ showCreateOrEditDocumentRecipientModal: false })
  })
}

const callbacks = (component, setState, strings) => {
  const componentCallbacks = {
    AddDocumentTemplateFieldModal: {
      closeModal: () => setState({ showAddDocumentTemplateFieldModal: false }),
    },
    CreateOrEditCustomFieldModal: {
      closeModal: () => setState({ showCreateOrEditCustomFieldModal: false }),
      createCustomField: payload => handleCallbackAction(payload),
    },
    CreateOrEditDocumentRecipientModal: {
      closeModal: () => setState({ showCreateOrEditDocumentRecipientModal: false }),
      createDocumentRecipient: (params, createFn) => createDocumentRecipient(params, createFn, setState, strings),
      deleteAndReassignPageItems: (targetRecipientId, deleteFn) => deleteAndReassignPageItems(targetRecipientId, deleteFn, setState, strings),
      updateDocumentRecipient: (params, updateFn) => updateDocumentRecipient(params, updateFn, setState, strings),
    },
    EditDocumentTemplateFieldModal: {
      closeModal: () => setState({ showEditDocumentTemplateFieldModal: false }),
      deleteDocumentTemplatePageItem: deleteFn => deleteDocumentTemplatePageItem(deleteFn, setState, strings),
      updateDocumentTemplatePageItem: (params, updateFn) => updateDocumentTemplatePageItem(params, updateFn, setState, strings),
    },
    ManageDocumentTemplateBuilderModal: {
      closeModal: () => setState({ showManageDocumentTemplateBuilderModal: false }),
      deleteDocumentTemplate: deleteFn => deleteDocumentTemplate(deleteFn, setState, strings),
      updateDocumentTemplate: (params, updateFn) => updateDocumentTemplate(params, updateFn, setState, strings),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  showAddDocumentTemplateFieldModal: false,
  showCreateOrEditDocumentRecipientModal: false,
  showEditDocumentTemplateFieldModal: false,
  showManageDocumentTemplateBuilderModal: false,
}

const DocumentTemplateBuilder = (props) => {
  const { documentTemplateId } = props

  const [state, setState] = useSetState(defaultState)
  const {
    showAddDocumentTemplateFieldModal,
    showCreateOrEditDocumentRecipientModal,
    showEditDocumentTemplateFieldModal,
    showManageDocumentTemplateBuilderModal,
  } = state

  const modalContext = useModals()
  const {
    callbacks: { registerModal, setModalData },
  } = modalContext

  const { strings } = useLocalization(localizedStrings)

  const entityCustomFieldPayload = useEntityCustomField({
    callbacks: {
      setupLocalCallbacks: setModalState => callbacks('CreateOrEditCustomFieldModal', setModalState, strings),
    },
  })
  const {
    callbacks: { addCustomField },
  } = entityCustomFieldPayload

  const documentTemplateBuilderPayload = useDocumentTemplateBuilder({
    callbacks: {
      addCustomField,
      closeModal: modalKey => setState({ [`show${modalKey}`]: false }),
      showAddDocumentTemplateFieldModal: () => setState({ showAddDocumentTemplateFieldModal: true }),
      showCreateOrEditDocumentRecipientModal: (payload) => {
        setModalData('CreateOrEditDocumentRecipientModal', payload)
        setState({ showCreateOrEditDocumentRecipientModal: true })
      },
      showEditDocumentTemplateFieldModal: () => setState({ showEditDocumentTemplateFieldModal: true }),
    },
    documentTemplateId,
  })

  const { isMobile } = documentTemplateBuilderPayload

  useEffect(() => {
    // Wait for the register function otherwise the modal
    // doesn't get added to the open modals array
    if (!isMobile && registerModal){
      setState({
        showManageDocumentTemplateBuilderModal: true,
      })
    }
  }, [isMobile])

  useIntercom({ horizontalPadding: 400 })

  return (
    <div id="main-stage" style={{ position: 'relative' }}>
      <Helmet>
        <title>{strings.title || 'Document Template Builder | Engage'}</title>
      </Helmet>

      <BuilderContextProvider value={documentTemplateBuilderPayload}>
        <ModalContext.Provider value={modalContext}>
          <MainContent
            offset={{ left: 0, top: 71 }}
            style={{ minHeight: 'calc(100vh - 71px)' }}
            width={!isMobile ? 'calc(100% - 375px)' : '100%'}
          >
            <Header documentTemplateBuilderPayload={documentTemplateBuilderPayload} />

            <Canvas />
          </MainContent>

          <AddDocumentTemplateFieldModal
            callbacks={callbacks('AddDocumentTemplateFieldModal', setState)}
            showModal={showAddDocumentTemplateFieldModal}
          />

          <CreateOrEditCustomFieldModal hiddenFieldKeys={['hint', 'placeholder']} {...entityCustomFieldPayload} />

          <CreateOrEditDocumentRecipientModal
            callbacks={callbacks('CreateOrEditDocumentRecipientModal', setState, strings)}
            disableOverlay
            documentRecipient={{ owner_id: documentTemplateId, owner_type: 'DocumentTemplate' }}
            showModal={showCreateOrEditDocumentRecipientModal}
          />

          <EditDocumentTemplateFieldModal
            callbacks={callbacks('EditDocumentTemplateFieldModal', setState, strings)}
            showModal={showEditDocumentTemplateFieldModal}
          />

          <ManageDocumentTemplateBuilderModal
            callbacks={callbacks('ManageDocumentTemplateBuilderModal', setState, strings)}
            showModal={showManageDocumentTemplateBuilderModal}
          />
        </ModalContext.Provider>
      </BuilderContextProvider>
    </div>
  )
}

DocumentTemplateBuilder.propTypes = {
  documentTemplateId: PropTypes.number,
}

export default DocumentTemplateBuilder
