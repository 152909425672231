import React from 'react'
import PropTypes from 'prop-types'

import { digObject } from '@campaignhub/javascript-utils'

import useImage from '@hooks/useImage'

import {
  Box, Image,
} from '@campaignhub/suit-theme'

import PlaceHolderImage from './assets/placeholderImage.png'

const Thumbnail = (props) => {
  const {
    bulkSelecting,
    callbacks: { bulkSelectImageId },
    image,
    selectionIndex,
  } = props || {}

  const { callbacks: { selectImage } } = useImage(image)

  const imageUrl = digObject(image, 'sizes.thumb_400x240', '')

  const isSelected = selectionIndex !== -1

  return (
    <Box flexDirection="column" backgroundImage={`url(${PlaceHolderImage})`} borderRadius={5} flexShrink="0">
      <Image
        borderRadius={5}
        forwardProps={{
          onClick: bulkSelecting ? () => bulkSelectImageId(image.id) : selectImage,
        }}
        height={['180px', '140px']}
        url={imageUrl}
        width="100%"
      >
        <Box
          alignItems="center"
          backgroundColor={isSelected ? 'rgba(0, 0, 0, 0.5)' : 'none'}
          borderRadius={5}
          height="100%"
          justifyContent="center"
          position="relative"
          style={{ cursor: 'pointer' }}
        >
          {isSelected && (
            <Box
              alignItems="center"
              backgroundColor="rgba(0, 0, 0, 0.5)"
              border="2px solid white"
              borderRadius="100%"
              color="white"
              height="40px"
              justifyContent="center"
              width="40px"
            >
              {selectionIndex + 1}
            </Box>
          )}
        </Box>
      </Image>
    </Box>
  )
}

Thumbnail.propTypes = {
  bulkSelecting: PropTypes.bool.isRequired,
  callbacks: PropTypes.shape({
    bulkSelectImageId: PropTypes.func.isRequired,
  }).isRequired,
  image: PropTypes.object.isRequired,
  selectionIndex: PropTypes.number.isRequired,
}

export default Thumbnail
