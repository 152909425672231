import { DateTime } from 'luxon'

import { digObject, sortArrayBy } from '@campaignhub/javascript-utils'

function getDayCountFromDates(startDateObj, endDateObj){
  if (!startDateObj || !endDateObj){
    return 0
  }

  const { days } = endDateObj.diff(startDateObj, 'days').toObject()
  return Math.ceil(days)
}

function getDayCountFromDuration(durationInDays){
  return durationInDays || 0
}

function getCellLabel(cellDate, firstCell, firstDayOfMonth, daysFromStart){
  if (cellDate){
    return cellDate.toLocaleString({
      month: (firstCell || firstDayOfMonth) ? 'short' : undefined,
      weekday: 'short',
      day: 'numeric',
    })
  }

  return `Day ${daysFromStart + 1}`
}

function buildEventGroupCellData(params){
  const {
    daysFromStart, durationInDays, events, startDateObj, endDateObj,
  } = params

  const cellCount = startDateObj
    ? getDayCountFromDates(startDateObj, endDateObj)
    : getDayCountFromDuration(durationInDays)

  const array = Array.from(Array(cellCount)).map((_, index) => {
    const cellDate = startDateObj ? startDateObj.plus({ days: index }) : null
    const { day: monthDay } = cellDate ? cellDate.toObject() : {}

    const firstCell = index === 0
    const firstDayOfMonth = monthDay === 1

    const cellDaysFromStart = daysFromStart + index

    const filteredEvents = events.filter((event) => {
      const { days_from_start: eventDaysFromStart } = event

      const eventStartDateString = digObject(event, 'dates.start.date_time_with_timezone')
      const eventStartDateObj = DateTime.fromISO(eventStartDateString)

      const matchesDate = eventStartDateObj && cellDate ? eventStartDateObj.hasSame(cellDate, 'day') : false
      const matchesDaysFromStart = cellDaysFromStart === eventDaysFromStart

      return eventStartDateString ? matchesDate : matchesDaysFromStart
    })

    const sortedEvents = sortArrayBy(filteredEvents, 'asc', (event) => {
      const startDateInteger = digObject(event, 'dates.start.integer')
      const startTime = digObject(event, 'data.start_time')

      return startDateInteger || startTime
    })

    return {
      date: cellDate,
      daysFromStart: cellDaysFromStart,
      events: sortedEvents,
      id: index,
      label: getCellLabel(cellDate, firstCell, firstDayOfMonth, cellDaysFromStart),
      firstDayOfMonth,
    }
  })

  return array
}

export default buildEventGroupCellData
