export default {
  default: {
    buttons: {
      deleteCaseStudy: 'Delete Case Study',
      editProject: 'Edit Project',
      removeFromProjects: 'Remove from Projects',
      save: 'Save',
      unarchiveCaseStudy: 'Unarchive Case Study',
    },
    formFieldLabels: {
      caseStudyDescription: 'Case Study Description',
      caseStudyImages: 'Case Study Images',
      findAProject: 'Find a Project',
      imageUpload: 'Case Study Image',
      moreOptions: 'More Options',
      title: 'PROJECTS',
    },
    projectSearchPlaceholder: 'Search for a project',
    sideBarModalHeader: {
      titleCreate: 'Create',
      titleSecondLine: 'Case Study',
      titleUpdate: 'Update',
    },
    sweetAlert: {
      delete: {
        confirmButton: 'Yes, delete it.',
        text: 'Are you sure? ',
        title: 'Delete Case Study?',
      },
      deleteFromProjects: {
        confirmButton: 'Yes, remove it.',
        text: 'Are you sure? ',
        title: 'Remove from Projects?',
      },
      toggleCaseStudyStatus: {
        unarchiveButton: 'Yes, unarchive it.',
        text: 'Are you sure? ',
        unarchiveTitle: 'Unarchive Case Study?',
      },
    },
    toast: {
      caseStudyUpdated: 'Case Study Updated Successfully',
      caseStudyCreated: 'Case Study Created Successfully',
      deleteCaseStudyPayload: 'Case Study Deleted Successfully',
      deletedFromProjects: 'Case Study Removed from Projects',
      toggleCaseStudyStatus: {
        unarchive: 'Case Study Unarchived Successfully',
      },
    },
  },
}
