import {
  Box, Image, ListItem, Text,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useAward from '@hooks/useAward'

import { AwardModel } from '@models/award'
import defaultCampaignImageUrl from './assets/default_campaign_image_75x75.png'

interface AwardProps {
  award: AwardModel,
}
const Award = (props: AwardProps) => {
  const { award } = props

  const awardPayload = useAward(award)
  const {
    award: { body, title },
    callbacks: { createOrEditAward },
    defaultImage,
  } = awardPayload

  const defaultImageUrl = digObject(defaultImage, 'sizes.thumb_120x80', '')

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        as: 'a',
        borderBottom: '1px dashed',
        padding: 'large',
      }}
      onClick={() => createOrEditAward({})}
      showAngleIcon
      forceBottomBorder
    >
      <Image
        borderRadius={5}
        height={40}
        marginRight="medium"
        placeholderUrl={defaultCampaignImageUrl}
        url={defaultImageUrl}
        width={60}
      />

      <Box flexDirection="column" justifyContent="center" lineHeight={1.3} marginLeft="medium" width="65%">
        <Text color="bodyFontColor" fontSize="xsmall" marginBottom="small" variant="ellipsis">
          {title}
        </Text>

        <Text color="faintGrey" fontSize="xsmall" variant="ellipsis">
          {body}
        </Text>
      </Box>
    </ListItem>
  )
}

export default Award
