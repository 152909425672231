import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, DashboardModule, Grid, SectionDivider,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useCurrentUser from '@hooks/useCurrentUser'
import useLocalization from '@hooks/useLocalization'
import useOrganizationsUser from '@hooks/useOrganizationsUser'

import Field from '../Field'
import NestedArrayField from '../NestedArrayField'
import NestedObjectField from '../NestedObjectField'

import localizedStrings from './localisedStrings'

const wrapChildren = (params, children) => {
  const {
    callbacks, customFieldGroup, customFieldSet, isAdmin, isCurrentUserAdminForOrganization, sidebar, strings,
  } = params

  const { label, tag } = customFieldGroup

  const { addCustomField } = callbacks || {}

  if (sidebar){
    return (
      <Box flexDirection="column">
        <SectionDivider boxProps={{ marginY: 'large' }}>{label}</SectionDivider>
        <>
          {children}

          {addCustomField && isCurrentUserAdminForOrganization && (
            <Button
              buttonStyle="secondaryEdit"
              onClick={() => addCustomField({
                customFieldSetEntityType: customFieldSet.entity_type,
                customFieldSetId: customFieldSet.id,
                tag,
              })}
              size="medium"
            >
              {strings.buttons?.addField || 'Add Field'}
            </Button>
          )}
        </>
      </Box>
    )
  }

  return (
    <DashboardModule
      headerRight={
        addCustomField && (isAdmin || isCurrentUserAdminForOrganization) ? (
          <DashboardModule.Link
            textProps={{ color: 'lightGrey', fontSize: 'xsmall' }}
            onClick={() => addCustomField({
              customFieldSetEntityType: customFieldSet.entity_type,
              customFieldSetId: customFieldSet.id,
              tag,
            })}
          >
            <FontAwesomeIcon icon={faPlus} /> {strings.buttons?.addField || 'Add Field'}
          </DashboardModule.Link>
        ) : null
      }
      title={label}
    >
      {children}
    </DashboardModule>
  )
}

const FieldGroup = (props) => {
  const {
    children,
    customFieldGroup,
    customFieldGroup: { fieldType, items },
    customFieldsForm,
    customFieldsForm: { customFieldSet },
    entityCustomFieldPayload: { callbacks },
    nestedDataStoreArrayItemPayload,
    sidebar,
  } = props

  const { isAdmin } = useCurrentUser()

  const { isCurrentUserAdminForOrganization } = useOrganizationsUser()

  const { strings } = useLocalization(localizedStrings)

  if (typeof children === 'function'){
    return children({
      customFieldGroup,
    })
  }

  if (fieldType === 'json_object_array'){
    return items.map(customField => (
      <NestedArrayField
        customFieldGroup={customFieldGroup}
        customFieldsForm={customFieldsForm}
        customField={customField}
        nestedDataStoreArrayItemPayload={nestedDataStoreArrayItemPayload}
        key={customField.id}
      />
    ))
  }

  return wrapChildren(
    {
      callbacks,
      customFieldGroup,
      customFieldSet,
      isAdmin,
      isCurrentUserAdminForOrganization,
      sidebar,
      strings,
    },
    <Grid
      gridGap="medium"
      gridTemplateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
      margin={sidebar ? 0 : 'large'}
      marginBottom={sidebar ? 0 : 'medium'}
      width={['auto', '100%']}
    >
      {items.map((customField) => {
        const { field_type } = customField

        if (field_type === 'json_object'){
          const nestedFields = digObject(customField, 'data.fields', [])

          return nestedFields.map(nestedField => (
            <NestedObjectField
              customField={customField}
              customFieldsForm={customFieldsForm}
              key={nestedField.key}
              nestedField={nestedField}
            />
          ))
        }

        return <Field customField={customField} customFieldsForm={customFieldsForm} key={customField.id} />
      })}
    </Grid>,
  )
}

FieldGroup.propTypes = {
  children: PropTypes.func,
  customFieldGroup: PropTypes.shape({
    label: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
  }).isRequired,
  customFieldsForm: PropTypes.object.isRequired,
  sidebar: PropTypes.bool,
}

export default FieldGroup
