import React, { Fragment, useContext } from 'react'
import PropTypes from 'prop-types'

import {
  faAd,
  faBuilding,
  faCalendarAlt,
  faCheckSquare,
  faCoins,
  faDrawSquare,
  faFolders,
  faHome,
  faSignature,
} from '@fortawesome/pro-solid-svg-icons'

import {
  Box, Button, FormField, SelectBox, SidebarModal,
} from '@campaignhub/suit-theme'

import { recipientColors } from '@hooks/useDocumentRecipient'
import useLocalization from '@hooks/useLocalization'

import BuilderContext from '../../contexts/builderContext'

import SelectRecipient from '../../components/SelectRecipient'

import useAddDocumentTemplateField, { entityTypes } from './hooks/useAddDocumentTemplateField'

import DocumentTemplateField from './components/DocumentTemplateField'

import localizedStrings from './localizedStrings'

const entityTypeIcons = {
  'project_comparables.all_sold': faHome,
  quotes: faCoins,
  organization: faBuilding,
  project: faFolders,
}

const AddDocumentTemplateFieldModal = (props) => {
  const { callbacks, modalKey, showModal } = props

  const builderContext = useContext(BuilderContext)
  const {
    callbacks: { addCustomField },
    documentTemplatePayload: { filteredRecipients },
  } = builderContext

  const addDocumentTemplateFieldPayload = useAddDocumentTemplateField({ documentRecipients: filteredRecipients })
  const {
    callbacks: { setState },
    hasEntityShortcodes,
    isSelectedEntityContextual,
    selectedEntityIndex,
    selectedEntityKey,
    selectedEntityType,
    selectedRecipientId,
    selectedRecipientIndex,
    shortcodesForEntityKey,
    shortcodesForRecipient,
  } = addDocumentTemplateFieldPayload

  const recipientColor = recipientColors[selectedRecipientIndex] || ''

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal callbacks={callbacks} disableOverlay modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.header?.title || 'Add Document'}
        titleSecondLine={strings.header?.secondLine || 'Field'}
      />

      <SidebarModal.Content hasFooter={false}>
        <Box flexDirection="column" flexShrink={0}>
          <FormField direction="column" label={strings.formField?.completedBy || 'Completed By'}>
            <SelectRecipient
              callbacks={{
                selectItem: recipient => setState({ selectedRecipientId: recipient.id }),
              }}
              documentRecipients={filteredRecipients}
              selectedRecipientId={selectedRecipientId}
            />
          </FormField>

          <FormField direction="column" label={strings.formField?.recipient || 'Recipient'} marginTop="large">
            <DocumentTemplateField
              documentRecipientId={selectedRecipientId}
              icon={faSignature}
              iconColor={recipientColor}
              itemKey="signature"
              itemType="signature"
              label={strings.documentTemplateField?.signature || 'Signature'}
            />

            <DocumentTemplateField
              documentRecipientId={selectedRecipientId}
              icon={faAd}
              iconColor={recipientColor}
              itemKey="initial"
              itemType="initial"
              label={strings.documentTemplateField?.initial || 'Initial'}
            />

            <DocumentTemplateField
              documentRecipientId={selectedRecipientId}
              icon={faCalendarAlt}
              iconColor={recipientColor}
              itemKey="date"
              itemType="date"
              label={strings.documentTemplateField?.date || 'Date'}
            />

            {shortcodesForRecipient.map((shortcode) => {
              const { key, label } = shortcode

              return (
                <DocumentTemplateField
                  documentRecipientId={selectedRecipientId}
                  icon={faDrawSquare}
                  iconColor={recipientColor}
                  itemKey={key}
                  itemType="text"
                  key={key}
                  label={label}
                />
              )
            })}
          </FormField>

          <FormField direction="column" label={strings.formField?.autoPopulated || 'Auto Populated'} marginTop="large">
            <SelectBox
              callbacks={{
                selectItem: entity => setState({ selectedEntityKey: entity.key }),
              }}
              items={entityTypes.map(entityType => ({ ...entityType, icon: entityTypeIcons[entityType.key] }))}
              selectedItemKey={selectedEntityKey}
              style={{ zIndex: 2 }}
              ulStyle={{ maxHeight: 300, overflow: 'auto' }}
            />

            {isSelectedEntityContextual && (
              <select
                value={selectedEntityIndex}
                onChange={e => setState({ selectedEntityIndex: Number(e.target.value) })}
                style={{ marginTop: 8 }}
              >
                {[...Array(selectedEntityType.indexLimit)].map((_, i) => (
                  <option key={i} value={i}>
                    {selectedEntityType.singular} {i + 1}
                  </option>
                ))}
              </select>
            )}

            {hasEntityShortcodes && (
              <Box
                borderTop="1px dashed"
                borderColor="lineColor"
                flexDirection="column"
                marginTop="medium"
                paddingTop="medium"
              >
                {shortcodesForEntityKey.map((shortcode) => {
                  const {
                    children, custom_field_id, key, label, value,
                  } = shortcode

                  return (
                    <Fragment key={key}>
                      <DocumentTemplateField
                        documentRecipientId={selectedRecipientId}
                        icon={faDrawSquare}
                        iconColor={recipientColor}
                        itemContext={selectedEntityKey}
                        itemContextIndex={selectedEntityIndex}
                        itemCustomFieldId={custom_field_id}
                        itemKey={key}
                        itemType="text"
                        label={label}
                        value={value}
                      />

                      {children && children.map(childShortcode => (
                        <DocumentTemplateField
                          boxProps={{ marginLeft: 'large', width: 'calc(100% - 16px)' }}
                          documentRecipientId={selectedRecipientId}
                          icon={faCheckSquare}
                          iconColor={recipientColor}
                          itemContext={selectedEntityKey}
                          itemContextIndex={selectedEntityIndex}
                          itemCustomFieldId={custom_field_id}
                          itemKey={childShortcode.key}
                          itemRawValue={childShortcode.rawValue}
                          itemType="checkbox"
                          key={`${key}_${childShortcode.key}`}
                          label={`${label} - ${childShortcode.label}`}
                          value={childShortcode.value}
                        />
                      ))}
                    </Fragment>
                  )
                })}
              </Box>
            )}

            <Button
              buttonStyle="secondaryEdit"
              onClick={() => addCustomField({
                customFieldSetEntityType: selectedEntityType.entityType,
                tag: 'Custom Fields',
              })}
              size="medium"
            >
              {strings.button?.addCustomField || 'Add Custom Field'}
            </Button>
          </FormField>
        </Box>
      </SidebarModal.Content>
    </SidebarModal>
  )
}

AddDocumentTemplateFieldModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

AddDocumentTemplateFieldModal.defaultProps = {
  modalKey: 'AddDocumentTemplateFieldModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <AddDocumentTemplateFieldModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
