import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './styles.module.scss'

const Placeholder = React.memo((props) => {
  const {
    id, isSelected, item_type, onClick,
  } = props

  return (
    <div
      className={classnames(styles.root, isSelected ? styles.selected : null)}
      style={{
        marginTop: item_type === 'quotes' ? 30 : 0,
        height: item_type === 'video' ? '100%' : null,
      }}
      onClick={onClick}
    >
      <div className={styles.label}>
        {item_type} {id}
      </div>
      <div className={styles.title}>{item_type} Container</div>
    </div>
  )
})

Placeholder.propTypes = {
  id: PropTypes.string,
  isSelected: PropTypes.bool,
  item_type: PropTypes.string,
  onClick: PropTypes.func,
}

export default Placeholder
