import PropTypes from 'prop-types'

import BarGraphIcon from '@components/Icons/BarGraphIcon'
import DisabledGraphIcon from '@components/Icons/DisabledGraphIcon'

import {
  Box, ListItem, StatusBadge, Text,
} from '@campaignhub/suit-theme'

import useShareLink from '@hooks/useShareLink'
import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const ShareLink = (props) => {
  const {
    shareLink,
    shareLink: {
      title, tracking_enabled, views,
    },
  } = props

  const {
    isAcceptedShareLink,
    callbacks: { editShareLink, viewShareLinkAnalytics },
  } = useShareLink(shareLink)

  const GraphIcon = tracking_enabled ? BarGraphIcon : DisabledGraphIcon

  const {
    callbacks: { formatCount },
    strings,
  } = useLocalization(localizedStrings)

  return (
    <ListItem
      boxProps={{
        as: 'a',
        alignItems: 'center',
        borderBottom: '1px dashed',
        padding: 'large',
      }}
      onClick={tracking_enabled ? viewShareLinkAnalytics : editShareLink}
      showAngleIcon
    >
      <Box
        alignItems="center"
        flexShrink={0}
        height={20}
        justifyContent="center"
        marginRight="medium"
        width={20}
      >
        <GraphIcon height={16} width={16} fillColor="#8C8C8E" />
      </Box>

      <Box flexDirection="column">
        <Text color="bodyFontColor" fontSize="small" lineHeight="1.3">
          {title || strings.untitled || 'Untitled Link'}
        </Text>
      </Box>

      <Box flexDirection="column" alignItems="end" justifyContent="center" width="auto">
        {isAcceptedShareLink && (
        <StatusBadge
          color="green"
          ghost
          boxProps={{ marginBottom: 'small' }}
          text={strings.accepted || 'Accepted'}
        />
        )}

        <Text
          color="bodyFontLightColor"
          fontSize="xsmall"
          style={{ whiteSpace: 'nowrap' }}
          textAlign="center"
          width="100%"
        >
          {formatCount('viewCount', { count: views })}
        </Text>
      </Box>
    </ListItem>
  )
}

ShareLink.propTypes = {
  shareLink: PropTypes.shape({
    title: PropTypes.string,
    tracking_enabled: PropTypes.bool,
    views: PropTypes.number,
  }).isRequired,
}

export default ShareLink
