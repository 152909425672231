import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import {
  useDebounce, useDeepEffect, useSetState, useThunkDispatch,
} from '@campaignhub/react-hooks'

import { digObject, sortArrayBy, toggleArray } from '@campaignhub/javascript-utils'

import * as reviewActions from '@redux/modules/review'

import useEntityDefaultIntegrationPlatform from '@hooks/useEntityDefaultIntegrationPlatform'
import useIntegrations from '@hooks/useIntegrations'
import useIntegrationPlatforms from '@hooks/useIntegrationPlatforms'
import useOrganizationSelector from '@hooks/useOrganizationSelector'

const toggleReview = (id, state, setState) => {
  const { selectedReviewIds } = state

  const updatedReviewIds = toggleArray(selectedReviewIds, id)

  setState({ selectedReviewIds: updatedReviewIds })
}

const toggleAllReviews = (reviews, state, setState) => {
  const { selectedReviewIds } = state

  if (selectedReviewIds.length){
    setState({ selectedReviewIds: [] })
    return
  }

  setState({ selectedReviewIds: reviews.map(review => review.id) })
}

const filterReviews = (string, externalReviews, setState) => {
  if (!string){
    setState({ filteredReviews: externalReviews })
  }

  if (externalReviews){
    const filtered_reviews = externalReviews.filter(review => review.address_title.toLowerCase().match(string))
    setState({ filteredReviews: filtered_reviews })
  }
}

const loadFromExternalPlatform = (externalPlatformKey, requestOptions, dispatch, setState) => {
  const { loadFromExternalPlatform: loadFromExternalPlatformFn } = reviewActions

  dispatch(loadFromExternalPlatformFn(externalPlatformKey, requestOptions)).then(({ success, data }) => {
    if (success){
      setState({ externalReviews: data, loading: false })
    }
    setState({ loading: false })
  })
}

const bulkImportFromExternalPlatform = (externalPlatformKey, payload, dispatch, requestOptions) => {
  const { bulkImportFromExternalPlatform: importFn } = reviewActions
  const { externalIds, organizationId } = payload

  const updatePayload = {
    externalIds,
    organizationId,
  }

  return dispatch(importFn(externalPlatformKey, updatePayload, requestOptions))
}

const getAlreadyImportedReviewIds = (reduxReviews, selectedOrganizationId, selectedIntegrationPlatformKey) => {
  const alreadyImportedExternalIds = Object.values(reduxReviews).reduce((acc, review) => {
    const source_platform = digObject(review, 'data.source_platform')
    const external_id = digObject(review, `data.external_ids.${source_platform}`)
    const ownerId = digObject(review, 'owner_id')

    if (
      ownerId === selectedOrganizationId
      && source_platform === selectedIntegrationPlatformKey
      && external_id
    ){
      acc.push(external_id)
    }

    return acc
  }, [])

  return alreadyImportedExternalIds
}

const defaultState = {
  externalReviews: [],
  filteredReviews: [],
  loading: false,
  selectedIntegrationPlatformKey: '',
  selectedReviewIds: [],
  string: '',
}

const useImportReviews = () => {
  const [state, setState] = useSetState(defaultState)
  const {
    externalReviews, filteredReviews, loading, selectedIntegrationPlatformKey, selectedReviewIds, string,
  } = state

  const dispatch = useThunkDispatch()

  const debouncedString = useDebounce(string, 300)

  const {
    callbacks: { selectOrganizationId },
    selectedOrganization,
    sortedOrganizationsWhereAdmin,
  } = useOrganizationSelector()

  const {
    filteredPlatforms,
    hasIntegrations,
    urls: integrationsUrls,
  } = useIntegrations({
    featureKeys: ['import_reviews'],
    filters: {
      owner_id: selectedOrganization.id,
      owner_type: 'Organization',
    },
  })

  const { defaultPlatformKey } = useEntityDefaultIntegrationPlatform({ featureKey: 'import_reviews' })

  // Integration Platform
  const { callbacks: { findIntegrationPlatformByKey } } = useIntegrationPlatforms()
  const selectedIntegrationPlatform = findIntegrationPlatformByKey(selectedIntegrationPlatformKey)

  const {
    entities: { reviews: reduxReviews },
  } = useSelector(reduxState => reduxState)

  // Create an array of IDs for reviews that have already been imported
  // Push the external ID if the review is in the right organization, has
  // been imported from the right platform, and has an external_id
  const alreadyImportedExternalIds = getAlreadyImportedReviewIds(
    reduxReviews,
    selectedOrganization.id,
    selectedIntegrationPlatformKey,
  )

  // Re-fetch reviews whenever integration platform or search string change
  useEffect(() => {
    if (selectedIntegrationPlatformKey){
      const fetchRequestParams = {
        limit: 100,
        organization_id: selectedOrganization.id,
        string: debouncedString,
      }

      loadFromExternalPlatform(selectedIntegrationPlatformKey, fetchRequestParams, dispatch, setState)
      setState({ externalReviews: [], loading: true })
    }
  }, [selectedIntegrationPlatformKey, selectedOrganization.id, debouncedString])

  // Select all reviews that have not already been imported
  useDeepEffect(() => {
    const notImportedExternalIds = externalReviews
      .filter(externalReview => !alreadyImportedExternalIds.includes(externalReview.id))
      .map(externalReview => externalReview.id)

    setState({ selectedReviewIds: notImportedExternalIds })
  }, [externalReviews])

  // Check for a user/org's default integration platform, if none auto select first integration platform if only one available
  useEffect(() => {
    if (defaultPlatformKey){
      setState({ selectedIntegrationPlatformKey: defaultPlatformKey })
      return
    }

    if (filteredPlatforms.length === 1){
      const selectedPlatform = filteredPlatforms[0]
      setState({ selectedIntegrationPlatformKey: selectedPlatform.key })
    }
  }, [defaultPlatformKey, filteredPlatforms.length])

  // set filtered reviews to externalReviews
  useEffect(() => {
    setState({ filteredReviews: externalReviews })
  }, [externalReviews])

  return {
    alreadyImportedExternalIds,
    callbacks: {
      bulkImportFromExternalPlatform: (payload, requestOptions) => bulkImportFromExternalPlatform(
        selectedIntegrationPlatformKey,
        payload,
        dispatch,
        requestOptions,
      ),
      filterReviews: str => filterReviews(str, externalReviews, setState),
      selectOrganizationId,
      setState,
      toggleAllReviews: () => toggleAllReviews(externalReviews, state, setState),
      toggleReview: id => toggleReview(id, state, setState),
    },
    externalReviews: sortArrayBy(externalReviews, 'asc', 'full_name'),
    filteredPlatforms,
    filteredReviews,
    hasExternalReviews: !!externalReviews.length,
    hasIntegrations,
    hasSelectedReviews: !!selectedReviewIds.length,
    integrationsUrls,
    loading,
    selectedIntegrationPlatform,
    selectedIntegrationPlatformKey,
    selectedOrganization,
    selectedReviewIds,
    sortedOrganizationsWhereAdmin,
    string,
  }
}

export default useImportReviews
