import { useAuth0 } from '@auth0/auth0-react'
import { Helmet } from 'react-helmet-async'

import { faHome } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Columns, DashboardModule, PageHeader,
} from '@campaignhub/suit-theme'

import useCurrentUser from '@hooks/useCurrentUser'

import ImpersonateUser from './components/ImpersonateUser'

const Overview = () => {
  const { logout } = useAuth0()
  const { isAdmin } = useCurrentUser()

  return (
    <>
      <Helmet>
        <title>Dashboard | Engage</title>
      </Helmet>

      <PageHeader
        activeTabBarItemKey="dashboard"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        tabBarItems={[
          {
            href: '#/',
            icon: faHome,
            key: 'dashboard',
            title: 'Dashboard',
          },
        ]}
        title="Admin Dashboard"
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            {isAdmin && <ImpersonateUser />}
          </Columns.Main>

          <Columns.Sidebar>
            <DashboardModule title="Actions">
              <Box flexDirection="column" padding="large">
                <Button buttonStyle="secondary" onClick={logout} size="medium">
                  Log Out
                </Button>
              </Box>
            </DashboardModule>
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

export default Overview
