import { useEffect } from 'react'

import { useSetState, useThunkDispatch } from '@campaignhub/react-hooks'
import { sortArrayBy } from '@campaignhub/javascript-utils'

import useAgreement from '@hooks/useAgreement'
import useOrganizationSelector from '@hooks/useOrganizationSelector'

import * as agreementActions from '@redux/modules/agreement'

const loadExternalAgreementEvents = (params) => {
  const {
    agreement, dispatch, electronicSigningPlatformKey, selectedOrganization, setState,
  } = params

  const { loadExternalAgreementEvents: loadFn } = agreementActions

  const options = {
    integration_platform_key: electronicSigningPlatformKey,
    organization_id: selectedOrganization.id,
  }

  setState({ loading: true })

  return dispatch(loadFn(agreement, options)).then((response) => {
    const { success, data } = response

    if (success){
      setState({ loading: false, events: data })
    }

    setState({ loading: false })
  })
}

const defaultState = {
  loading: false,
  events: [],
}

function useExternalAgreementEvents(agreement){
  const [state, setState] = useSetState(defaultState)
  const { events, loading } = state

  const dispatch = useThunkDispatch()

  const sortedEvents = sortArrayBy(events, 'desc', 'date_time_with_timezone')

  const { electronicSigningPlatformKey } = useAgreement(agreement)

  const { selectedOrganization } = useOrganizationSelector()

  useEffect(() => {
    if (agreement.id){
      loadExternalAgreementEvents({
        agreement,
        dispatch,
        electronicSigningPlatformKey,
        selectedOrganization,
        setState,
      })
    }
  }, [agreement.id])

  return {
    loading,
    sortedEvents,
  }
}

export default useExternalAgreementEvents
