import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImages } from '@fortawesome/pro-light-svg-icons'

import { FormField, InputSearch, UserProfileImage } from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useLocalization from '@hooks/useLocalization'

import ModalProject from '@components/ModalComponents/ModalProject'

import localizedStrings from './localizedStrings'

const mapResults = (comparables, addresses, images, strings) => {
  const results = {
    groups: [
      {
        key: 'comparables',
        title: strings.mapResults?.title || 'COMPARABLES',
        items: Object.values(comparables).map((comparable) => {
          const address = addresses[comparable.address_id] || {}
          const image = images[comparable.default_image_id] || {}
          const imageUrl = digObject(image, 'sizes.thumb_90x90')

          return {
            ...comparable,
            iconLeft: (
              <UserProfileImage imageUrl={imageUrl}>
                <FontAwesomeIcon icon={faImages} />
              </UserProfileImage>
            ),
            id: comparable.id,
            key: `ProjectComparable-${comparable.id}`,
            text: address.full_address,
          }
        }),
      },
    ],
  }

  return results
}

const ProjectComparable = (props) => {
  const {
    imageSelectPayload: {
      callbacks: { selectEntity },
      images,
      selectedEntity,
    },
  } = props

  const { addresses, comparables } = useSelector(reduxState => reduxState.entities)
  const address = addresses[selectedEntity.address_id] || {}

  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      <FormField label={strings.formFieldLabels?.findComparable || 'Find a Comparable'}>
        <InputSearch
          boxProps={{ marginBottom: 'large' }}
          callbacks={{ selectItem: item => selectEntity({ type: 'ProjectComparable', id: item.id }) }}
          closeOnSelect
          placeholder={strings.comparableSearchPlaceholder || 'Search for a comparable'}
          results={mapResults(comparables, addresses, images, strings)}
          showOnFocus
        />
      </FormField>

      {!!selectedEntity.id && (
        <ModalProject
          callbacks={{ removeProject: () => selectEntity({ type: 'ProjectComparable', id: 0 }) }}
          project={{ ...selectedEntity, title: address.full_address }}
        />
      )}
    </>
  )
}

ProjectComparable.propTypes = {
  imageSelectPayload: PropTypes.shape({
    callbacks: PropTypes.shape({
      selectEntity: PropTypes.func.isRequired,
    }).isRequired,
    images: PropTypes.object.isRequired,
    selectedEntity: PropTypes.object.isRequired,
  }).isRequired,
}

export default ProjectComparable
