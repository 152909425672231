export default {
  default: {
    buttons: {
      save: 'Save',
      delete: 'Delete Contact',
      address: 'Add Address',
    },
    header: {
      title: {
        create: 'Create',
        edit: 'Edit',
      },
      titleSecondLine: 'Contact',
    },
    contactOwner: 'Contact Owner',
    formFieldLabels: {
      contactAddress: 'Contact Address',
      contactOwner: '* Contact Owner',
      contactViewers: 'Contact Viewers',
      email: '* Email',
      firstName: '* First Name',
      lastName: '* Last Name',
      mobile: '* Mobile',
      moreOptions: 'More Options',
      phone: 'Phone',
      userType: '* User Type',
    },
    sweetAlert: {
      confirm: 'Yes, delete it.',
      text: 'Are you sure?',
      title: 'Delete Contact?',
    },
    toast: {
      contactUpdated: 'Contact Updated Successfully',
      contactCreated: 'Contact Created Successfully',
      contactDeleted: 'Contact Deleted Successfully',
    },
  },
}
