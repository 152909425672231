import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faDownload, faHistory } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, FormField, ModalContext, SectionDivider, SidebarModal,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useAgreement from '@hooks/useAgreement'
import useAgreementProgress from '@hooks/useAgreementProgress'
import useLocalization from '@hooks/useLocalization'

import AgreementDetails from './components/AgreementDetails'
import DocumentRecipient from './components/DocumentRecipient'

import localizedStrings from './localizedStrings'

const confirmVoidAgreement = (voidAgreementCallback, voidFn, strings) => {
  swal
    .fire({
      confirmButtonColor: '#DD6B55',
      confirmButtonText: strings.swal?.confirmButtonText || 'Yes, void it.',
      icon: 'warning',
      input: 'text',
      inputPlaceholder: strings.swal?.inputPlaceholder || 'Please add reason for voiding',
      inputValidator: (value) => {
        if (!value){
          return strings.swal?.inputPlaceholder || 'Please add reason for voiding.'
        }

        return null
      },
      showCancelButton: true,
      text:
        strings.swal?.text || 'Voiding the agreement will notify signers and will require you to resend the document.',
      title: strings.swal?.title || 'Void Agreement?',
    })
    .then(({ value }) => {
      if (value){
        voidAgreementCallback(value, voidFn)
      }
    })
}

const AgreementProgressModal = (props) => {
  const { callbacks, modalKey, showModal } = props
  const { generateExternalAgreementSigningUrl, voidExternalAgreement } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const { agreement: initAgreement } = digObject(modalData, modalKey, {})

  const agreementProgressPayload = useAgreementProgress(initAgreement)
  const { agreement, recipientTemporaryStatuses } = agreementProgressPayload

  const agreementPayload = useAgreement(agreement)
  const {
    agreement: { urls },
    callbacks: {
      viewAgreementEvents,
      generateExternalAgreementSigningUrl: generateUrlFn,
      voidExternalAgreement: voidFn,
    },
    groupedRecipients,
    isSent,
    isSyncedWithElectronicSigningPlatform,
    updating,
  } = agreementPayload

  const { external_pdf: externalPdfUrl } = urls || {}

  const recipientGroupKeys = Object.keys(groupedRecipients)

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.sideBarModalHeader?.title || 'Agreement'}
        titleSecondLine={strings.sideBarModalHeader?.titleSecondLine || 'Progress'}
      />

      <SidebarModal.Content boxProps={{ padding: 0 }} hasFooter={false}>
        <AgreementDetails agreementPayload={agreementPayload} />

        <Box flexDirection="column" flexShrink={0} padding="large">
          {recipientGroupKeys.map((groupKey, index) => {
            const lastGroup = recipientGroupKeys.length === index + 1
            const documentRecipients = groupedRecipients[groupKey]

            if (documentRecipients.length){
              return (
                <Box flexDirection="column" key={groupKey}>
                  <SectionDivider boxProps={{ marginTop: lastGroup ? 'medium' : 'none' }}>
                    {strings[groupKey] || groupKey}
                  </SectionDivider>

                  {documentRecipients.map(documentRecipient => (
                    <DocumentRecipient
                      agreementPayload={agreementPayload}
                      callbacks={{
                        generateSigningUrl: () => generateExternalAgreementSigningUrl(documentRecipient.id, generateUrlFn),
                      }}
                      documentRecipient={documentRecipient}
                      temporaryStatus={recipientTemporaryStatuses[documentRecipient.id]}
                      key={documentRecipient.id}
                    />
                  ))}
                </Box>
              )
            }

            return null
          })}

          <FormField
            direction="column"
            label={strings.formFieldLabels?.moreOptions || 'More Options'}
            marginTop="large"
          >
            {isSyncedWithElectronicSigningPlatform && (
              <Button
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faHistory} />}
                marginBottom="medium"
                onClick={() => viewAgreementEvents()}
                size="medium"
                width="100%"
              >
                {strings.buttons?.history || 'View History'}
              </Button>
            )}

            {!!externalPdfUrl && (
              <Button
                as="a"
                buttonStyle="secondaryUtility"
                href={externalPdfUrl}
                icon={<FontAwesomeIcon icon={faDownload} />}
                marginBottom="medium"
                size="medium"
                width="100%"
              >
                {strings.buttons?.download || 'Download Agreement'}
              </Button>
            )}

            {isSyncedWithElectronicSigningPlatform && isSent && (
              <Button
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faBan} />}
                loading={updating}
                onClick={() => confirmVoidAgreement(voidExternalAgreement, voidFn, strings)}
                size="medium"
                width="100%"
              >
                {strings.buttons?.void || 'Void Agreement'}
              </Button>
            )}
          </FormField>
        </Box>
      </SidebarModal.Content>
    </SidebarModal>
  )
}

AgreementProgressModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

AgreementProgressModal.defaultProps = {
  modalKey: 'AgreementProgressModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <AgreementProgressModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
