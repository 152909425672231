import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import {
  Box, Button, ModalContext, SidebarModal, SortableList,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useDigitalTemplatePage from '@hooks/useDigitalTemplatePage'
import useLocalization from '@hooks/useLocalization'

import SectionGroup from './components/SectionGroup'

import localizedStrings from './localizedStrings'

const EditDigitalTemplateSectionSortModal = (props) => {
  const { callbacks, modalKey, showModal } = props
  const { deleteEntity, updateGroupSectionSort } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const { callbacks: modalCallbacks, digitalTemplatePage } = digObject(modalData, `${modalKey}`, {})
  const { deleteEntity: deleteFn } = modalCallbacks || {}

  const {
    callbacks: { modifySectionSort, updateGroupSectionSort: updateSortFn },
    sortedGroupSections,
    updating,
  } = useDigitalTemplatePage(digitalTemplatePage)

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal
      callbacks={callbacks}
      disableAnimation
      disableOverlay
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.header?.title || 'Sort'}
        titleSecondLine={strings.header?.titleSecondLine || 'Sections'}
      />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <SortableList
            callbacks={{
              onDragEnd: (fromIndex, toIndex, payload) => modifySectionSort(fromIndex, toIndex, payload),
            }}
          >
            {Object.keys(sortedGroupSections).map((sectionGroupKey, index) => {
              const sectionIds = sortedGroupSections[sectionGroupKey] || []

              return (
                <SectionGroup
                  callbacks={{ deleteEntity: entity => deleteEntity(entity, deleteFn) }}
                  index={index}
                  key={sectionGroupKey}
                  sectionIds={sectionIds}
                  sectionGroupKey={sectionGroupKey}
                />
              )
            })}
          </SortableList>
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          loading={updating}
          onClick={() => updateGroupSectionSort(updateSortFn)}
          size="large"
        >
          {strings.buttons?.save || 'Save'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

EditDigitalTemplateSectionSortModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

EditDigitalTemplateSectionSortModal.defaultProps = {
  modalKey: 'EditDigitalTemplateSectionSortModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <EditDigitalTemplateSectionSortModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
