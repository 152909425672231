export default {
  default: {
    headers: {
      tracking: 'Link Tracking',
      notifications: 'Notifications ',
    },
    toggleButtons: {
      tracking: {
        title: 'Tracking and Insights',
        text: 'Views will be tracked and recorded',
      },
      notifications: {
        title: 'Notifications',
        text: 'Notify users when this link is viewed',
      },
    },
  },
}
