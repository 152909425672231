import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { deleteEntity, updateEntities } from '@redux/entities'

import api from '@functions/api'

import type { EventGroupModel } from '@models/types'
import type { AppDispatch } from '@redux/store'
import type {
  Action, DeleteParams, EntityOptions, Errors, UpdateParams,
} from '@redux/modules/types'

import { handleError } from '../utils'

const CREATE_REQUEST = 'realbase/eventGroup/CREATE_REQUEST'
const CREATE_SUCCESS = 'realbase/eventGroup/CREATE_SUCCESS'
const CREATE_FAILURE = 'realbase/eventGroup/CREATE_FAILURE'

const UPDATE_REQUEST = 'realbase/eventGroup/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realbase/eventGroup/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realbase/eventGroup/UPDATE_FAILURE'

const DELETE_REQUEST = 'realbase/eventGroup/DELETE_REQUEST'
const DELETE_SUCCESS = 'realbase/eventGroup/DELETE_SUCCESS'
const DELETE_FAILURE = 'realbase/eventGroup/DELETE_FAILURE'

export type EventGroupModuleState = {
  creating: boolean,
  deleting: boolean,
  errors: string[],
  updating: boolean,
}

// Initial State
const initialState: EventGroupModuleState = {
  creating: false,
  deleting: false,
  errors: [],
  updating: false,
}

// Actions
export function createRequest() {
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess() {
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors: Errors = []) {
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest() {
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess() {
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors: Errors = []) {
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest() {
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess() {
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors: Errors = []) {
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Action Creators
export function createEventGroup(eventGroup: Partial<EventGroupModel>, options: EntityOptions = {}) {
  const { event_calendar_id } = eventGroup

  const config = {
    method: 'POST',
    body: JSON.stringify({
      event_group: eventGroup,
    }),
  }

  return (dispatch: AppDispatch) => {
    dispatch(createRequest())

    const promise = api(`/event_calendars/${event_calendar_id}/event_groups.json`, options, config)
      .then(({ data }: { data: { entity: EventGroupModel } }) => {
        const normalizedJson = normalize(data.entity, Schemas.EVENT_GROUP)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function updateEventGroup(
  eventGroup: UpdateParams<EventGroupModel>,
  options: EntityOptions = {},
) {
  const { event_calendar_id, id } = eventGroup

  const config = {
    method: 'PUT',
    body: JSON.stringify({
      event_group: eventGroup,
    }),
  }

  return (dispatch: AppDispatch) => {
    dispatch(updateRequest())

    const promise = api(`/event_calendars/${event_calendar_id}/event_groups/${id}.json`, options, config)
      .then(({ data }: { data: { entity: EventGroupModel } }) => {
        const normalizedJson = normalize(data.entity, Schemas.EVENT_GROUP)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function deleteEventGroup(eventGroup: DeleteParams<EventGroupModel>) {
  const { event_calendar_id, id } = eventGroup

  const config = {
    method: 'DELETE',
  }

  return (dispatch: AppDispatch) => {
    dispatch(deleteRequest())

    const promise = api(`/event_calendars/${event_calendar_id}/event_groups/${id}.json`, {}, config)
      .then(({ data }: { data: { entity: EventGroupModel } }) => {
        const normalizedJson = normalize(data.entity, Schemas.EVENT_GROUP)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(deleteFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(
  state: EventGroupModuleState = initialState,
  action: Action = { type: '' },
): EventGroupModuleState {
  switch (action.type){
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors || [],
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors || [],
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors || [],
      }
    default:
      return state
  }
}
