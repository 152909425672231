import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp, faAngleDown } from '@fortawesome/pro-light-svg-icons'

import styles from './styles.module.scss'

const renderButton = (params, direction) => {
  const { children: { key }, updateCallback } = params
  return (
    <a
      className={styles.toggle}
      onClick={() => updateCallback(key, direction)}
      style={{
        bottom: direction === 'down' ? 30 : 'auto',
        left: 30,
        top: direction === 'up' ? 30 : 'auto',
      }}
      tabIndex="0"
    >
      <FontAwesomeIcon icon={direction === 'up' ? faAngleUp : faAngleDown} />
    </a>
  )
}

const SortableSectionWrapper = (props) => {
  const { children } = props

  return (
    <div className={styles.root}>
      {renderButton(props, 'up')}
      {children}
      {renderButton(props, 'down')}
    </div>
  )
}

SortableSectionWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SortableSectionWrapper
