export default {
  default: {
    createFromTemplate: {
      title: 'Use a Template',
      subtitle: 'Choose from saved templates',
    },
    createNew: {
      title: 'New Form',
      subtitle: 'Start with a blank canvas',
    },
  },
}
