import React from 'react'

import { BlankState } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

import eventCalendarsImageUrl from './assets/event-calendars.svg'

const EventCalendarsBlankState = () => {
  const { strings } = useLocalization(localizedStrings)

  return (
    <BlankState imageUrl={eventCalendarsImageUrl}>
      <BlankState.Title>
        {strings.title || 'No Calendars Available'}
      </BlankState.Title>

      <BlankState.Paragraph>
        {strings.paragraph || 'There are no calendars available in this project yet. If you’re integrated with a marketing platform that exports calendars you can import them here, or you can create one from scratch.'}
      </BlankState.Paragraph>
    </BlankState>
  )
}

export default EventCalendarsBlankState
