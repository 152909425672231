import React from 'react'
import PropTypes from 'prop-types'

const ProjectIcon = (props) => {
  const { fillColor, height, width } = props

  return (
    <svg width={width} height={height} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M35.6636 40.2191H24.7926C24.5085 39.2979 23.7804 38.5698 22.8593 38.2858V34.7181H27.1992C27.6737 34.7181 28.0585 34.3333 28.0585 33.8587C28.0585 33.3841 27.6737 32.9994 27.1992 32.9994H3.60977C2.56729 32.9994 1.71919 32.1513 1.71919 31.1088V21.2262C1.71919 20.7516 1.33438 20.3669 0.859841 20.3669C0.385307 20.3669 0.000488281 20.7516 0.000488281 21.2262V31.1088C0.000488281 33.099 1.6196 34.7181 3.60977 34.7181H21.1406V38.2858C20.2194 38.5698 19.4913 39.2979 19.2073 40.2191H8.33621C7.86168 40.2191 7.47686 40.6038 7.47686 41.0784C7.47686 41.553 7.86168 41.9378 8.33621 41.9378H19.2073C19.5751 43.1307 20.6878 44.0002 21.9999 44.0002C23.3121 44.0002 24.4248 43.1307 24.7926 41.9378H35.6636C36.1382 41.9378 36.523 41.553 36.523 41.0784C36.523 40.6038 36.1383 40.2191 35.6636 40.2191V40.2191ZM21.9999 42.2815C21.3365 42.2815 20.7968 41.7418 20.7968 41.0784C20.7968 40.415 21.3365 39.8753 21.9999 39.8753C22.6633 39.8753 23.203 40.415 23.203 41.0784C23.203 41.7418 22.6633 42.2815 21.9999 42.2815Z"
          fill={fillColor}
        />
        <path
          d="M40.5632 6.87921V6.35921C40.5632 4.36904 38.9441 2.74993 36.954 2.74993H17.1672L15.4805 1.05984C14.7986 0.376397 13.8913 0 12.9258 0H3.61105C1.62088 0 0.00177002 1.61911 0.00177002 3.60928V13.4918C0.00177002 13.9665 0.386588 14.3512 0.861123 14.3512C1.33566 14.3512 1.72048 13.9665 1.72048 13.4918V3.60928C1.72048 2.5668 2.56857 1.71871 3.61105 1.71871H12.9258C13.4316 1.71871 13.9068 1.91584 14.264 2.27385L20.3188 8.34123C20.48 8.5027 20.6988 8.59353 20.927 8.59353H40.3914C41.4339 8.59353 42.282 9.44163 42.282 10.4841V31.1086C42.282 32.1511 41.4339 32.9992 40.3914 32.9992H34.9347C34.4601 32.9992 34.0753 33.3839 34.0753 33.8585C34.0753 34.3331 34.4601 34.7179 34.9347 34.7179H40.3914C42.3816 34.7179 44.0007 33.0988 44.0007 31.1086V10.4841C44.0007 8.55159 42.4739 6.96927 40.5632 6.87921ZM18.8824 4.46864H36.954C37.9964 4.46864 38.8445 5.31673 38.8445 6.35921V6.87482H21.2836L18.8824 4.46864Z"
          fill={fillColor}
        />
        <path
          d="M34.2027 15.1249C34.2027 14.6503 33.8179 14.2656 33.3433 14.2656H10.6564C10.1819 14.2656 9.79706 14.6503 9.79706 15.1249V22.6872C9.79706 23.1619 10.1819 23.5466 10.6564 23.5466H33.3433C33.8179 23.5466 34.2027 23.1619 34.2027 22.6872V15.1249ZM32.484 21.8279H11.5158V15.9843H32.484V21.8279Z"
          fill={fillColor}
        />
        <path
          d="M26.6689 19.765C26.8958 19.765 27.1166 19.673 27.2764 19.5132C27.4371 19.3533 27.5283 19.1316 27.5283 18.9056C27.5283 18.6796 27.4371 18.4579 27.2764 18.298C27.1166 18.1382 26.8957 18.0462 26.6689 18.0462C26.4429 18.0462 26.2211 18.1382 26.0613 18.298C25.9015 18.4579 25.8095 18.6796 25.8095 18.9056C25.8095 19.1316 25.9014 19.3533 26.0613 19.5132C26.2212 19.673 26.443 19.765 26.6689 19.765Z"
          fill={fillColor}
        />
        <path
          d="M22 19.765C22.226 19.765 22.4477 19.673 22.6075 19.5132C22.7673 19.3533 22.8594 19.1316 22.8594 18.9056C22.8594 18.6796 22.7674 18.4579 22.6075 18.298C22.4477 18.1382 22.226 18.0462 22 18.0462C21.774 18.0462 21.5523 18.1382 21.3924 18.298C21.2326 18.4579 21.1406 18.6796 21.1406 18.9056C21.1406 19.1316 21.2325 19.3533 21.3924 19.5132C21.5524 19.673 21.774 19.765 22 19.765Z"
          fill={fillColor}
        />
        <path
          d="M17.3311 19.765C17.5571 19.765 17.7788 19.673 17.9386 19.5132C18.0984 19.3533 18.1904 19.1316 18.1904 18.9056C18.1904 18.6796 18.0985 18.4579 17.9386 18.298C17.7788 18.1382 17.5571 18.0462 17.3311 18.0462C17.105 18.0462 16.8833 18.1382 16.7235 18.298C16.5637 18.4579 16.4717 18.6796 16.4717 18.9056C16.4717 19.1316 16.5636 19.3533 16.7235 19.5132C16.8833 19.673 17.105 19.765 17.3311 19.765Z"
          fill={fillColor}
        />
        <path
          d="M34.2027 26.4685C34.2027 25.9938 33.8179 25.6091 33.3434 25.6091H19.9948C19.5203 25.6091 19.1354 25.9938 19.1354 26.4685C19.1354 26.9431 19.5203 27.3278 19.9948 27.3278H33.3434C33.818 27.3278 34.2027 26.9431 34.2027 26.4685Z"
          fill={fillColor}
        />
        <path
          d="M14.7177 25.8609C14.5579 26.0208 14.4659 26.2425 14.4659 26.4685C14.4659 26.6945 14.5578 26.9162 14.7177 27.0761C14.8777 27.2359 15.0993 27.3279 15.3253 27.3279C15.5522 27.3279 15.7731 27.2359 15.9328 27.0761C16.0935 26.9162 16.1847 26.6945 16.1847 26.4685C16.1847 26.2425 16.0935 26.0208 15.9328 25.8609C15.7731 25.7011 15.5521 25.6091 15.3253 25.6091C15.0994 25.6091 14.8776 25.7011 14.7177 25.8609Z"
          fill={fillColor}
        />
        <path
          d="M10.0489 27.0761C10.2087 27.2359 10.4304 27.3279 10.6564 27.3279C10.8824 27.3279 11.1042 27.2359 11.2639 27.0761C11.4238 26.9162 11.5158 26.6945 11.5158 26.4685C11.5158 26.2425 11.4239 26.0208 11.2639 25.8609C11.1042 25.7011 10.8824 25.6091 10.6564 25.6091C10.4304 25.6091 10.2087 25.7011 10.0489 25.8609C9.88901 26.0208 9.79706 26.2425 9.79706 26.4685C9.79706 26.6945 9.88901 26.9162 10.0489 27.0761V27.0761Z"
          fill={fillColor}
        />
        <path
          d="M0.859863 18.2178C1.08588 18.2178 1.3076 18.1258 1.46736 17.966C1.6272 17.8061 1.71924 17.5844 1.71924 17.3584C1.71924 17.1324 1.62729 16.9107 1.46736 16.7508C1.3076 16.591 1.08588 16.499 0.859863 16.499C0.633848 16.499 0.412129 16.591 0.252285 16.7508C0.0924414 16.9107 0.000488281 17.1324 0.000488281 17.3584C0.000488281 17.5844 0.0923555 17.8061 0.252285 17.966C0.412215 18.1258 0.633848 18.2178 0.859863 18.2178Z"
          fill={fillColor}
        />
        <path
          d="M30.4586 33.2508C30.2988 33.4107 30.2068 33.6324 30.2068 33.8584C30.2068 34.0844 30.2987 34.3061 30.4586 34.466C30.6186 34.6258 30.8402 34.7178 31.0662 34.7178C31.2922 34.7178 31.514 34.6258 31.6737 34.466C31.8336 34.3061 31.9256 34.0844 31.9256 33.8584C31.9256 33.6324 31.8336 33.4107 31.6737 33.2508C31.514 33.091 31.2922 32.999 31.0662 32.999C30.8402 32.999 30.6185 33.091 30.4586 33.2508Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="44" height="44" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

ProjectIcon.propTypes = {
  fillColor: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
}

ProjectIcon.defaultProps = {
  fillColor: '#000000',
  height: 40,
  width: 40,
}

export default ProjectIcon
