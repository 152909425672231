import { useMemo } from 'react'
import deepmerge from 'deepmerge'
import { formatDefaultLocale } from 'd3-format'
import { DateTime } from 'luxon'

import { digObject } from '@campaignhub/javascript-utils'

import useOrganizationSelector from '@hooks/useOrganizationSelector'

import { replaceGlobalString, replaceString } from '@functions/localization'
import { hyphenCaseToSnakeCase } from '@functions/string'

import currencyFormats from '@sections/Client/localization/currencyFormats'
import globalStringsByType from '@sections/Client/localization/globalStrings'

import { EntitiesState } from '@redux/entities'

type ComponentStrings = {
  default: Record<string, string | Record<string, string>>,
}

const matchPattern = /(\{[\w.w|w,|]+\})/
const globalMatchPattern = /\{.*?global.\w.*?\}/

const isObject = (value: any) => typeof value === 'object' && value !== null

const formatString = (
  value: string,
  globalStrings: Record<string, string | Record<string, string>>,
  values: Record<string, string> | string,
) => {
  if (typeof value !== 'string') return ''

  return value.split(matchPattern)
    .filter(string => !!string)
    .map((string) => {
      // matches any any word inside {} and replaces with correct value
      // {global.digitalTemplateType.title}
      if (string.match(globalMatchPattern)) return replaceGlobalString(string, globalStrings, values)
      // {digitalPageId}
      if (string.match(matchPattern)) return replaceString(string, values)
      return string
    })
    .join('')
}

const formatCount = (
  key: string,
  componentStrings: Record<string, string | Record<string, string>>,
  customValues: { count: string },
) => {
  const stringsObject = componentStrings[key] || {}
  const { none, one, many } = stringsObject
  const { count } = customValues

  const number = count === undefined ? 0 : count

  switch (Number(number)){
    case 0:
      return formatString(none, {}, customValues)
    case 1:
      return formatString(one, {}, customValues)
    default:
      return formatString(many, {}, customValues)
  }
}

type FormatObjectWithEntitiesParams = {
  componentStrings: Record<string, string | Record<string, string>>,
  entities: Partial<EntitiesState>,
  globalStrings: Record<string, string | Record<string, string>>,
  organizationTypeKey: string,
}

const formatObjectWithEntities = (params: FormatObjectWithEntitiesParams) => {
  const {
    componentStrings, entities, globalStrings, organizationTypeKey,
  } = params
  const keys = Object.keys(componentStrings)

  // loops through each level of the object until it finds a string
  const formattedStrings = keys.reduce((formattedObject, key) => {
    const value = componentStrings[key]

    if (isObject(value)){
      formattedObject[key] = formatObjectWithEntities({
        componentStrings: value, globalStrings, entities, organizationTypeKey,
      })
    } else {
      formattedObject[key] = organizationTypeKey ? formatString(value, globalStrings, entities) : ''
    }

    return formattedObject
  }, {})

  return formattedStrings
}

type GetLocalStringsParams = {
  componentStrings: ComponentStrings,
  languageKey: string,
  organizationTypeKey: string,
}

const getLocalStrings = (params: GetLocalStringsParams): Record<string, string | Record<string, string>> => {
  const { componentStrings, languageKey, organizationTypeKey } = params

  const defaultStrings = digObject(componentStrings, 'default', {})
  if (!organizationTypeKey) return defaultStrings

  const customStrings = digObject(componentStrings, `${languageKey}.${organizationTypeKey}`, {})

  return deepmerge(defaultStrings, customStrings)
}

type GetGlobalStringsParams = {
  globalStrings: object,
  languageKey: string,
}
const getGlobalStrings = (params: GetGlobalStringsParams): Record<string, string | Record<string, string>> => {
  const { globalStrings, languageKey } = params

  const defaultStrings = digObject(globalStrings, 'default', {})
  const customStrings = digObject(globalStrings, languageKey, {})

  return deepmerge(defaultStrings, customStrings)
}

const formatDate = (dateTime: string, organizationLocale: string, format: string) => {
  const defaultFormat = {
    weekday: 'short', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit',
  }
  return DateTime.fromISO(dateTime).setLocale(organizationLocale).toLocaleString(format || defaultFormat)
}

function useLocalization(componentStrings: ComponentStrings, entities: Partial<EntitiesState> = {}) {
  const { organizationType, selectedOrganization } = useOrganizationSelector()

  const organizationLocale = digObject(selectedOrganization, 'options.language', 'en-AU')
  const languageKey = hyphenCaseToSnakeCase(organizationLocale).toLowerCase()

  const localStrings = getLocalStrings({ componentStrings, languageKey, organizationTypeKey: organizationType.key })
  const globalStrings = getGlobalStrings({ globalStrings: globalStringsByType, languageKey })

  const strings = useMemo(() => (
    formatObjectWithEntities({
      componentStrings: localStrings,
      entities: { ...entities, organizationType },
      globalStrings,
      organizationTypeKey: organizationType.key,
    })
  ), [organizationType.key, JSON.stringify(entities)])

  const currencyFormat = digObject(currencyFormats, languageKey, currencyFormats.default)
  const locale = formatDefaultLocale(currencyFormat)

  return {
    callbacks: {
      formatCount: (key: string, customValues: { count: string }) => formatCount(key, localStrings, customValues),
      formatCurrency: (number: number, format = '$,.2f') => locale.format(format)(number),
      formatDate: (dateTime: string, format: string) => formatDate(dateTime, organizationLocale, format),
      formatNumber: (number: number, format = '') => locale.format(`,${format}`)(number),
      formatString: (
        string: string,
        customValues: Record<string, string> | string,
      ) => formatString(string, globalStrings, customValues),
    },
    strings,
  }
}

export default useLocalization
