import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import cloneDeep from 'lodash/cloneDeep'
import isMobileBrowser from 'is-mobile'

import { useSetState, useThunkDispatch, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import { digObject } from '@campaignhub/javascript-utils'

import {
  copyEntity, findEntity, itemPartials, updateEntityIds,
} from '@functions/digitalTemplate'

import generateID from '@functions/generateID'
import denormalizeEntity from '@functions/denormalizeEntity'

import useCurrentUser from '@hooks/useCurrentUser'
import useDigitalEditorSelectedEntity from '@hooks/useDigitalEditorSelectedEntity'
import useDigitalPageDataStoreItems from '@hooks/useDigitalPageDataStoreItems'
import useDigitalPage from '@hooks/useDigitalPage'
import useDigitalTemplateFonts from '@hooks/useDigitalTemplateFonts'
import useDigitalTemplate, { getDigitalTemplateEnabledFeatures } from '@hooks/useDigitalTemplate'
import useReduxAction from '@hooks/useReduxAction'

import * as digitalTemplateActions from '@redux/modules/digitalTemplate'
import * as digitalTemplatePageActions from '@redux/modules/digitalTemplatePage'
import * as editorActions from '@redux/modules/digitalTemplateBuilder/editor'
import * as imageActions from '@redux/modules/image'

import digitalRendererRequestOptions from '@sections/Client/packs/DigitalRenderer/defaultRequestOptions'

const watchEntityKeys = ['digitalTemplatePageSectionGroups', 'digitalTemplatePages', 'digitalTemplates']

// Not declaring in DigitalTemplateBuilder index as
// we dont want to load all of the relations when rendering the
// list view.

export const defaultRequestOptions = {
  attachment: {
    include_attachment_file: true,
  },
  digitalPage: {
    include_digital_page_assigned_images: true,
    include_digital_page_comparables: true,
    include_digital_page_data_store_items: true,
    include_digital_page_default_share_links: true,
    include_digital_page_digital_template: true,
    include_digital_page_image_sizes: ['thumb_640x360', 'thumb_1280x720', 'thumb_2000x1500', 'thumb_3500x2500'],
    include_digital_page_reviews: true,
    include_digital_page_share_links: true,
    include_digital_page_subject: true,
  },
  digitalTemplate: {
    include_digital_template_digital_template_components: true,
    include_digital_template_image_sizes: ['thumb_640x360', 'thumb_1280x720', 'thumb_2000x1500', 'thumb_3500x2500'],
    include_digital_template_images: true,
    include_digital_template_page_ids: true,
    include_digital_template_pages: true,
  },
  digitalTemplateComponent: {
    include_digital_template_component_digital_template_ids: true,
  },
  digitalTemplatePage: {
    include_digital_template_page_assigned_images: true,
    include_digital_template_page_attachments: true,
    include_digital_template_page_image_sizes: [
      'thumb_640x360',
      'thumb_1280x720',
      'thumb_2000x1500',
      'thumb_3500x2500',
    ],
    include_digital_template_page_images: true,
    include_digital_template_page_section_groups: true,
  },
}

const autoSelectPage = (digitalTemplate, selectedPageId, dispatch) => {
  const { selectPageId: selectFn } = digitalTemplatePageActions

  const pages = digitalTemplate.pages || []
  const firstPageId = pages[0]

  if ((!selectedPageId || selectedPageId !== firstPageId) && firstPageId){
    dispatch(selectFn(firstPageId))
  }
}

const addImage = (data, entity, dispatch) => {
  const { updateCanvasItem: updateFn } = editorActions
  const {
    dataset,
    entity: { type: targetType },
    itemType,
  } = data

  const updatedEntity = cloneDeep(entity)

  if (itemType === 'image.id'){
    const { imageId } = dataset || {}

    updatedEntity.image = {
      id: Number(imageId),
    }
  }

  return dispatch(updateFn(targetType, updatedEntity, defaultRequestOptions.digitalTemplatePage))
}

const buildPartial = (data, entities) => {
  const {
    dataset: { digitalTemplateComponentId, entityId, entityType },
    dropIndex,
    itemType,
  } = data

  // Dropping copied item
  if (entityId){
    return copyEntity(entityType, entityId, entities)
  }

  // Dropping saved component
  if (digitalTemplateComponentId){
    const { digitalTemplateComponents } = entities
    const digitalTemplateComponent = digitalTemplateComponents[digitalTemplateComponentId] || {}
    const { data: { component } } = digitalTemplateComponent || {}

    const duplicatedComponent = updateEntityIds(component)

    return {
      ...duplicatedComponent,
      componentId: digitalTemplateComponentId,
    }
  }

  // Adding a new item
  return {
    ...itemPartials[itemType],
    id: generateID(),
    options: {
      ...itemPartials[itemType].options,
      sort: dropIndex,
    },
  }
}

const addCanvasItem = (data, entity, entities, dispatch) => {
  const { updateCanvasItem: updateFn } = editorActions
  const {
    dropIndex,
    entity: { type: targetType },
  } = data

  const updatedEntity = cloneDeep(entity)

  const partial = buildPartial(data, entities)

  const itemsKey = targetType === 'digitalTemplatePageSectionGroup' ? 'sections' : 'items'

  const items = updatedEntity[itemsKey] ? [...updatedEntity[itemsKey].filter(item => item)] : []
  items.splice(dropIndex, 0, partial)

  updatedEntity[itemsKey] = items

  return dispatch(updateFn(targetType, updatedEntity))
}

const manageDigitalTemplateAssets = (digitalTemplate, dispatch, showManageDigitalTemplateAssetsModal) => {
  const { deleteImage: deleteImageFn } = imageActions

  return new Promise((resolve, reject) => {
    if (showManageDigitalTemplateAssetsModal){
      const payload = {
        callbacks: {
          deleteImage: image => dispatch(deleteImageFn(image)),
        },
        digitalTemplate,
      }

      showManageDigitalTemplateAssetsModal(payload)

      return resolve({ success: true, result: payload })
    }

    return reject(new Error('showManageDigitalTemplateAssetsModal not defined in PageContext callbacks'))
  })
}

const addItem = showAddDigitalTemplatePageItemModal => new Promise((resolve, reject) => {
  if (showAddDigitalTemplatePageItemModal){
    showAddDigitalTemplatePageItemModal()

    return resolve({ success: true })
  }

  return reject(new Error('showAddDigitalTemplatePageItemModal not defined in PageContext callbacks'))
})

const createSectionGroup = (digitalTemplatePage, entitiesRef, dispatch) => {
  const { updateCanvasItem: updateFn } = editorActions

  const partial = {
    ...itemPartials.sectionGroup,
    id: `group-${generateID()}`,
  }

  const denormalizedEntity = denormalizeEntity('digitalTemplatePage', digitalTemplatePage, entitiesRef.current)
  const updatedEntity = cloneDeep(denormalizedEntity)

  const sectionGroups = updatedEntity.section_groups ? [...updatedEntity.section_groups.filter(group => group)] : []
  sectionGroups.push(partial)

  updatedEntity.section_groups = sectionGroups

  dispatch(updateFn('digitalTemplatePage', updatedEntity))
}

const createDigitalTemplateComponent = (digitalTemplate, selectedEntity, showCreateDigitalTemplateComponentModal) => new Promise((resolve, reject) => {
  if (showCreateDigitalTemplateComponentModal){
    const payload = {
      digitalTemplate,
      selectedEntity,
    }

    showCreateDigitalTemplateComponentModal(payload)

    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showCreateDigitalTemplateComponentModal not defined in PageContext callbacks'))
})

const editDigitalTemplateSettings = (
  digitalTemplate,
  digitalTemplatePage,
  callbacks,
  dispatch,
  showEditDigitalTemplateModal,
) => {
  const { updateDigitalTemplate: updateFn } = digitalTemplateActions

  return new Promise((resolve, reject) => {
    if (showEditDigitalTemplateModal){
      const payload = {
        callbacks: {
          updateDigitalTemplate: updatedTemplate => dispatch(updateFn(updatedTemplate)),
          ...callbacks,
        },
        digitalTemplate,
      }

      showEditDigitalTemplateModal(payload)

      return resolve({ success: true, result: payload })
    }

    return reject(new Error('showEditDigitalTemplateModal not defined in PageContext callbacks'))
  })
}

const dropItem = (data, entitiesRef, dispatch) => {
  const {
    entity: { id: targetId, type: targetType },
    itemType,
  } = data

  const targetEntity = findEntity(targetType, targetId, entitiesRef.current)
  const denormalizedEntity = denormalizeEntity(targetType, targetEntity, entitiesRef.current)

  // Dropping Image ID
  if (itemType === 'image.id'){
    return addImage(data, denormalizedEntity, dispatch)
  }

  // Dropping Canvas Item
  return addCanvasItem(data, denormalizedEntity, entitiesRef.current, dispatch)
}

const updateEntityParam = (entityType, entity, key, value, dispatch) => {
  const { updateCanvasItem: updateFn } = editorActions

  const updatedEntity = {
    ...cloneDeep(entity),
    [key]: value,
  }

  return dispatch(updateFn(entityType, updatedEntity))
}

const copySelectedCanvasItem = (dispatch, selectedCanvasItem) => {
  const { copyCanvasItem: copyFn } = editorActions
  dispatch(copyFn(selectedCanvasItem))
}

const editSectionSort = (digitalTemplatePage, showEditDigitalTemplateSectionSortModal, dispatch) => new Promise((resolve, reject) => {
  if (showEditDigitalTemplateSectionSortModal){
    const { deleteCanvasItem } = editorActions

    const payload = {
      callbacks: {
        deleteEntity: entity => dispatch(deleteCanvasItem('digitalTemplatePageSection', entity)),
      },
      digitalTemplatePage,
    }

    showEditDigitalTemplateSectionSortModal(payload)

    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showEditDigitalTemplateSectionSortModal not defined in PageContext callbacks'))
})

const selectCanvasItem = (
  digitalTemplate,
  item,
  e,
  dispatch,
  showEditDigitalTemplatePageItemModal,
  itemOptions = {},
) => {
  const { deleteCanvasItem: deleteFn, selectCanvasItem: selectFn, updateCanvasItem: updateFn } = editorActions

  const { type } = item || {}

  e.stopPropagation()

  dispatch(selectFn(item))

  return new Promise((resolve, reject) => {
    if (showEditDigitalTemplatePageItemModal){
      const payload = {
        callbacks: {
          deleteEntity: entity => dispatch(deleteFn(type, entity)),
          updateEntity: updatedEntity => dispatch(updateFn(type, updatedEntity)),
          updateEntityParam: (entity, key, value) => updateEntityParam(type, entity, key, value, dispatch),
        },
        digitalTemplate,
        selectedCanvasItem: item,
        ...itemOptions,
      }

      showEditDigitalTemplatePageItemModal(payload)

      return resolve({ success: true, result: payload })
    }

    return reject(new Error('showEditDigitalTemplatePageItemModal not defined in PageContext callbacks'))
  })
}

const toggleCarousel = (key, index, setState) => {
  setState(state => ({
    ...state,
    [key]: !state[key],
    [`${key}SelectedIndex`]: index ? Number(index) : 0,
  }))
}

const defaultState = {
  previewing: false,
  targetDevice: 'default',
  toggleableComponents: {},
}

function useDigitalTemplateEditor(digitalTemplateId, options = {}){
  const { callbacks } = options || {}
  const {
    showAddDigitalTemplatePageItemModal,
    showCreateDigitalTemplateComponentModal,
    showCreateOrEditComponentStyleModal,
    showEditDigitalTemplateModal,
    showEditDigitalTemplatePageItemModal,
    showEditDigitalTemplateSectionSortModal,
    showManageDigitalTemplateAssetsModal,
    showSessionLockedModal,
  } = callbacks || {}

  const dispatch = useThunkDispatch()

  const [state, setState] = useSetState(defaultState)
  const { previewing, targetDevice, toggleableComponents } = state

  const isMobile = isMobileBrowser()
  const isTablet = isMobileBrowser({ tablet: true })

  const isMobileDevice = isMobile || isTablet

  const selectedPageId = useSelector(reduxState => reduxState.digitalTemplatePages.selectedId)
  const loadingDigitalTemplates = useSelector(reduxState => reduxState.digitalTemplates.loading)

  // In order to get the latest entities and avoiding stale state
  // we use use ref to get the current state
  const entitiesRef = useRef()
  entitiesRef.current = useSelector(reduxState => reduxState.entities)
  const {
    digitalPages, digitalPageShortcodeData, digitalTemplatePages, digitalTemplates, organizationShortcodeLists,
  } = entitiesRef.current

  const digitalTemplate = digitalTemplates[digitalTemplateId] || {}
  const digitalTemplatePage = digitalTemplatePages[selectedPageId] || {}

  const {
    updatedEntities: { digitalTemplates: digitalTemplatesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  // Load Template
  useReduxAction(
    'digitalTemplates',
    'loadDigitalTemplate',
    {
      ...defaultRequestOptions.attachment,
      ...defaultRequestOptions.digitalTemplate,
      ...defaultRequestOptions.digitalPage,
      ...defaultRequestOptions.digitalTemplateComponent,
      ...defaultRequestOptions.digitalTemplatePage,
    },
    [digitalTemplateId],
    {
      dispatchAction: (action, requestOptions) => action(digitalTemplateId, requestOptions),
      shouldPerformFn: (entityReducer) => {
        const { loadedIds, loading } = entityReducer
        return digitalTemplateId && !loadedIds.includes(digitalTemplateId) && !loading
      },
    },
  )

  // Load Template Fonts
  useDigitalTemplateFonts(digitalTemplate)

  const digitalTemplatePayload = useDigitalTemplate(digitalTemplate)
  const {
    callbacks: { deleteUnusedDigitalTemplateComponents, updateDigitalTemplate },
  } = digitalTemplatePayload

  // Load Template Usage
  useReduxAction('digitalTemplates', 'loadDigitalTemplateUsage', {}, [digitalTemplateId], {
    dispatchAction: (action, requestOptions) => action(digitalTemplateId, requestOptions),
    shouldPerformFn: (entityReducer) => {
      const { loading } = entityReducer
      return digitalTemplateId && !loading
    },
  })

  // Load Template Currnet Usage in Production
  const {
    data: digitalTemplateCurrentUsagePayload,
  } = useReduxAction('digitalTemplates', 'loadDigitalTemplateCurrentUsage', {}, [digitalTemplateId], {
    dispatchAction: (action, requestOptions) => action(digitalTemplateId, requestOptions),
    shouldPerformFn: (entityReducer) => {
      const { loading } = entityReducer
      return digitalTemplateId && !loading
    },
  })

  // Load Shortcode Data
  useReduxAction(
    'digitalPages',
    'loadDigitalPageShortcodeData',
    {
      digital_template_id: digitalTemplate.id,
    },
    [digitalTemplate.id, previewing],
    {
      dispatchAction: (action, requestOptions) => action({ id: 'sandbox' }, requestOptions),
      shouldPerformFn: (entityReducer) => {
        const { loadedShortcodeDataIds, loading } = entityReducer
        return digitalTemplate.id && !loadedShortcodeDataIds.includes('sandbox') && !loading && previewing
      },
    },
  )

  // Load Shortcode List
  useReduxAction('organizations', 'loadShortcodeList', {}, [digitalTemplate.id, previewing], {
    dispatchAction: (action, requestOptions) => action({ id: 'sandbox' }, requestOptions),
    shouldPerformFn: ({ loadedShortcodeListIds, loading }) => !loadedShortcodeListIds.includes('sandbox') && !loading,
  })

  // Load Digital Page
  useReduxAction(
    'digitalPages',
    'loadDigitalPage',
    {
      ...defaultRequestOptions.digitalPage,
      ...digitalRendererRequestOptions.attachment,
      ...digitalRendererRequestOptions.caseStudy,
      ...digitalRendererRequestOptions.comparable,
      ...digitalRendererRequestOptions.digitalTemplate,
      ...digitalRendererRequestOptions.digitalTemplatePage,
      ...digitalRendererRequestOptions.project,
      ...digitalRendererRequestOptions.review,
      ...digitalRendererRequestOptions.shareLink,
      ...digitalRendererRequestOptions.targetAudience,
      ...digitalRendererRequestOptions.team,
      ...digitalRendererRequestOptions.user,
    },
    [previewing],
    {
      dispatchAction: (action, requestOptions) => action('sandbox', requestOptions),
      shouldPerformFn: (entityReducer) => {
        const { loadedIds, loading } = entityReducer
        return !loadedIds.includes('sandbox') && !loading && previewing
      },
    },
  )

  const sandboxDigitalPageId = useSelector(reduxState => reduxState.digitalPages.sandboxId)
  const shortcodeData = digitalPageShortcodeData[sandboxDigitalPageId] || {}

  const sandboxOrganizationId = useSelector(reduxState => reduxState.organizations.sandboxId)
  const shortcodeList = organizationShortcodeLists[sandboxOrganizationId] || {}
  const shortcodeListArray = Object.values(shortcodeList).filter(item => typeof item === 'object' && item !== null)

  const digitalPage = digitalPages[sandboxDigitalPageId] || {}

  const { currentUser } = useCurrentUser()
  const digitalPagePayload = useDigitalPage(digitalPage)
  const {
    callbacks: {
      loadDigitalPageShortcodeData,
    },
  } = digitalPagePayload

  // Data Store
  const dataStoreItemPayload = useDigitalPageDataStoreItems({
    callbacks: {
      loadDigitalPageShortcodeData: () => loadDigitalPageShortcodeData(),
    },
    currentUser,
    digitalPage,
    digitalTemplate,
  })
  const {
    callbacks: { createOrUpdateDataStoreItem },
    dataStoreItems,
  } = dataStoreItemPayload

  // Autoselect Page
  useEffect(
    () => autoSelectPage(digitalTemplate, selectedPageId, dispatch),
    [digitalTemplate.id, digitalTemplatesUpdatedAt],
  )

  // Selected Entities
  const copiedEntity = useDigitalEditorSelectedEntity('copiedCanvasItem')
  const { selectedEntity } = useDigitalEditorSelectedEntity('selectedCanvasItem')

  // Digital Template - We need to return enabledFeatures to be able to see them in previewMode
  const { enabledFeatures } = getDigitalTemplateEnabledFeatures(digitalTemplate)

  return {
    callbacks: {
      addItem: () => addItem(showAddDigitalTemplatePageItemModal),
      copyItem: item => copySelectedCanvasItem(dispatch, item),
      createDigitalTemplateComponent: selectedEntity => createDigitalTemplateComponent(digitalTemplate, selectedEntity, showCreateDigitalTemplateComponentModal),
      createOrUpdateDataStoreItem,
      createSectionGroup: () => createSectionGroup(digitalTemplatePage, entitiesRef, dispatch),
      deleteUnusedDigitalTemplateComponents,
      dropItem: data => dropItem(data, entitiesRef, dispatch),
      editDigitalTemplateSettings: () => editDigitalTemplateSettings(
        digitalTemplate,
        digitalTemplatePage,
        callbacks,
        dispatch,
        showEditDigitalTemplateModal,
      ),
      editSectionSort: () => editSectionSort(digitalTemplatePage, showEditDigitalTemplateSectionSortModal, dispatch),
      manageDigitalTemplateAssets: () => manageDigitalTemplateAssets(digitalTemplate, dispatch, showManageDigitalTemplateAssetsModal),
      selectCanvasItem: (item, e, itemOptions) => selectCanvasItem(digitalTemplate, item, e, dispatch, showEditDigitalTemplatePageItemModal, itemOptions),
      setTargetDevice: deviceKey => setState({ targetDevice: deviceKey }),
      showCreateOrEditComponentStyleModal,
      showSessionLockedModal,
      toggleCarousel: (key, index) => toggleCarousel(key, index, setState),
      togglePreviewMode: () => setState({ previewing: !previewing }),
      updateDigitalTemplate,
    },
    copiedEntity,
    currentUser,
    customData: {},
    dataStoreItems,
    digitalPage,
    digitalTemplate,
    digitalTemplateCurrentUsagePayload,
    digitalTemplatePage,
    enabledFeatures,
    isMobileDevice,
    loading: loadingDigitalTemplates,
    previewing,
    sandboxDigitalPageId,
    selectedEntity,
    shortcodeData,
    shortcodeListArray,
    targetDevice,
    toggleableComponents,
  }
}

export default useDigitalTemplateEditor
