import { useSelector } from 'react-redux'

import { findEntity } from '@functions/digitalTemplate'

const getSelectedEntity = (key, editorState, entities) => {
  const selectedItem = editorState[key]
  const { type, id } = selectedItem || {}

  return findEntity(type, id, entities)
}

function useDigitalEditorSelectedEntity(key){
  const entities = useSelector(reduxState => reduxState.entities)
  const editorState = useSelector(reduxState => reduxState.digitalTemplateBuilder.editor)

  const selectedEntity = getSelectedEntity(key, editorState, entities)

  return {
    editorState,
    selectedEntity,
  }
}

export default useDigitalEditorSelectedEntity
