import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { faHandHoldingUsd } from '@fortawesome/pro-light-svg-icons'

import useReduxAction from '@hooks/useReduxAction'
import useLocalization from '@hooks/useLocalization'
import useDigitalPages from '@hooks/useDigitalPages'

import { Box, DashboardModule, Link } from '@campaignhub/suit-theme'

import CompactList from './CompactList'
import LargeList from './LargeList'
import Tiles from './Tiles'

import DigitalPageImageUrl from './assets/digital-pages.svg'

import localizedStrings from './localizedStrings'

export const icon = faHandHoldingUsd

export const configOptions = strings => ({
  title: {
    component: 'input',
    key: 'title',
    label: strings.labelTitle?.title || 'Title',
    optionKey: 'customization',
  },
  status: {
    component: 'select',
    key: 'status',
    label: strings.status?.label || 'Status',
    optionKey: 'queryParams',
    values: [
      { key: 'all', label: strings.statusValues?.all || 'All Statuses' },
      { key: 'accepted', label: strings.statusValues?.accepted || 'Accepted' },
      { key: 'pending', label: strings.statusValues?.pending || 'Pending' },
      { key: 'processed', label: strings.statusValues?.processed || 'Processed' },
      { key: 'complete', label: strings.statusValues?.complete || 'Complete' },
      { key: 'sent', label: strings.statusValues?.sent || 'Sent' },
      { key: 'rejected', label: strings.statusValues?.rejected || 'Rejected' },
    ],
  },
  display_mode: {
    component: 'select',
    key: 'display_mode',
    label: strings.displayMode?.label || 'Display Mode',
    optionKey: 'customization',
    values: [
      { key: 'compact-list', label: strings.displayModeValues?.labelListCompact || 'List (Compact)' },
      { key: 'large-list', label: strings.displayModeValues?.labelListLarge || 'List (Large)' },
      { key: 'tiles', label: strings.displayModeValues?.labelTile || 'Tiles' },
    ],
  },
  limit: {
    component: 'select',
    key: 'limit',
    label: strings.limit?.label || 'Limit',
    optionKey: 'queryParams',
    values: [
      { key: 5, label: '5' },
      { key: 10, label: '10' },
      { key: 25, label: '25' },
      { key: 50, label: '50' },
    ],
  },
})

const defaultRequestOptions = {
  include_digital_page_digital_template: true,
  include_digital_page_subject: true,
  include_digital_page_total_views: true,
  include_digital_template_preview_image: true,
  include_digital_template_type: true,
  include_image_sizes: ['thumb_200x120'],
  include_project_address: true,
  include_project_teams: true,
}

// Use digital pages from redux state so it's easy to keep track and modify them
const mapDigitalPages = (data, digitalPages) => data.map(d => digitalPages[d.id]).filter(d => d)

const DigitalPages = (props) => {
  const {
    customOptions,
    customOptions: {
      queryParams: {
        limit, status, subject_id, subject_type,
      },
    },
    editing,
    parentId,
  } = props

  const requestOptions = {
    ...defaultRequestOptions,
    ...customOptions.queryParams,
  }
  const { loading } = useReduxAction('digitalPages', 'loadDigitalPages', requestOptions, [
    parentId,
    JSON.stringify(requestOptions),
    {
      // Need to load if redirected from main to project overview screen
      shouldPerformFn: ({ errors }) => !errors.length,
    },
  ])

  const digitalPagesPayload = useDigitalPages({
    limit,
    status,
    subject_id,
    subject_type,
  })

  const { hasFilteredDigitalPages, filteredDigitalPages } = digitalPagesPayload

  const { digitalPages } = useSelector(reduxState => reduxState.entities)

  const { customization } = customOptions
  const { display_mode, title } = customization || {}

  const { strings } = useLocalization(localizedStrings)

  const { projectId } = useParams()
  const baseUrl = projectId ? `#/projects/${projectId}` : '#/overviews'

  return (
    <DashboardModule
      boxProps={{ marginBottom: 0 }}
      editing={editing}
      headerRight={(
        <Link href={`${baseUrl}/digitalPages?digitalTemplateType=proposal`} textProps={{ fontSize: 'small' }}>
          {strings.manage || 'Manage'}
        </Link>
      )}
      loading={loading}
      title={title || strings.title?.title || 'Digital Pages'}
    >
      {!loading && !hasFilteredDigitalPages && (
        <DashboardModule.BlankState imageUrl={DigitalPageImageUrl}>
          <DashboardModule.BlankState.Title>
            {strings.blankState?.title || 'No Digital Pages Created'}
          </DashboardModule.BlankState.Title>

          <DashboardModule.BlankState.Paragraph>
            {strings.blankState?.paragraphOne
              || 'Digital pages can be used to collect and store information, present property and agency information to customers and to build agreements and websites.'}
          </DashboardModule.BlankState.Paragraph>

          <DashboardModule.BlankState.Paragraph>
            {strings.blankState?.paragraphTwo
              || 'Agreements, Forms and Homepages and can be located in the Tools section in the project menu.'}
          </DashboardModule.BlankState.Paragraph>
        </DashboardModule.BlankState>
      )}

      {!loading && hasFilteredDigitalPages && (
        <Box flexDirection="column" flexWrap="wrap">
          {display_mode === 'compact-list'
            && mapDigitalPages(filteredDigitalPages, digitalPages).map(digitalPage => (
              <CompactList key={digitalPage.id} digitalPage={digitalPage} />
            ))}
          {display_mode === 'large-list'
            && mapDigitalPages(filteredDigitalPages, digitalPages).map(digitalPage => (
              <LargeList key={digitalPage.id} digitalPage={digitalPage} />
            ))}
          {display_mode !== 'compact-list' && display_mode !== 'large-list' && (
            <Tiles filteredDigitalPages={filteredDigitalPages} />
          )}
        </Box>
      )}
    </DashboardModule>
  )
}

DigitalPages.propTypes = {
  customOptions: PropTypes.object.isRequired,
  editing: PropTypes.bool,
  parentId: PropTypes.string,
}

export default DigitalPages
