import { useContext } from 'react'

import { DateTime } from 'luxon'

import { digObject } from '@campaignhub/javascript-utils'
import {
  useForm, useRealtimeSubscription, useSetState, useUniqueId,
} from '@campaignhub/react-hooks'

import RealtimeContext from '@contexts/realtimeContext'

import useCurrentUser from '@hooks/useCurrentUser'
import useOrganizationSelector from '@hooks/useOrganizationSelector'
import useReduxAction from '@hooks/useReduxAction'

import generateKeyFromParams from '@functions/generateKeyFromParams'
import { queueReportExport } from '@functions/reporting'
import { isOrganizationUser } from '@functions/user'

import reportModels from '../models/reportModels'

const generateUrls = () => ({
  reportsIndexUrlAdmin: '#/systemManager/reports',
})

const generateReportItems = (state, isBrandUser = false) => {
  const {
    brand_id,
    hidden,
    integration_platform_key,
    organization_id,
    owner_id,
    owner_type,
    production_ready,
    status_key,
    template_type_id,
    user_id,
  } = state

  const reportItems = [
    {
      key: 'digital_page_count_per_organization',
      params: {
        brand_id,
        subject_id: brand_id,
        subject_type: brand_id ? 'Brand' : null,
      },
      report_group: 'digital_page',
      title: 'Digital Pages Per Organisation',
      url: '/reports/digital_pages/count_per_organization',
    },
    {
      key: 'digital_page_count_per_user',
      params: {
        brand_id,
        organization_id,
        subject_id: brand_id,
        subject_type: brand_id ? 'Brand' : null,
      },
      report_group: 'digital_page',
      title: 'Digital Pages Per User',
      url: '/reports/digital_pages/count_per_user',
    },
    {
      key: 'digital_pages_status',
      params: {
        brand_id,
        organization_id,
        status_key: status_key || 'pending',
        subject_id: brand_id,
        subject_type: brand_id ? 'Brand' : null,
      },
      report_group: 'digital_page',
      title: 'Digital Pages Status',
      url: '/reports/digital_pages/digital_pages_status',
    },
    {
      key: 'digital_template_created',
      params: {
        brand_id,
        owner_id,
        owner_type,
        production_ready,
        template_type_id,
        user_id,
      },
      report_group: 'digital_template',
      title: 'Digital Templates Created',
      url: '/reports/digital_templates/created',
    },
    {
      key: 'enabled_integrations',
      params: {
        brand_id,
        integration_platform_key,
        organization_id,
      },
      report_group: 'integration',
      title: 'Enabled Integration',
      url: '/reports/integrations/enabled_integrations',
    },
    {
      key: 'organization_created',
      params: {
        brand_id,
      },
      report_group: 'organization',
      title: 'Organisations Created',
      url: '/reports/organizations/created',
    },
    !isBrandUser && {
      key: 'template_use_per_organization',
      params: {
        brand_id,
      },
      report_group: 'organization',
      title: 'Template Use Per Organisation',
      url: '/reports/digital_pages/template_use_per_organization',
    },
    {
      key: 'users_created',
      params: {
        brand_id,
        hidden,
        organization_id,
        subject_id: brand_id,
        subject_type: 'Brand',
      },
      report_group: 'user',
      title: 'Users Created',
      url: '/reports/users/created',
    },
    {
      key: 'users_with_multiple_organization',
      params: {
        brand_id,
      },
      report_group: 'user',
      title: 'Users With Multiple Organization',
      url: '/reports/users/with_multiple_organizations',
    },
  ].filter(Boolean)

  return reportItems
}

const getOwner = (userRoleKey, selectedOrganization) => {
  if (isOrganizationUser(userRoleKey)){
    return {
      owner_id: selectedOrganization.id,
      owner_type: 'Organization',
    }
  }

  return {}
}

const generateReport = (reportTypeKey, state, userRoleKey, selectedOrganization, reportKey, setState) => {
  const {
    start_date, end_date,
  } = state

  setState({ loading: true })

  const { owner_id, owner_type } = getOwner(userRoleKey, selectedOrganization)

  const reportItems = generateReportItems(state)
  const selectedItem = reportItems.find(item => item.key === reportTypeKey)

  const {
    url, params = {},
  } = selectedItem || {}

  if (url){
    const exportUrl = `${url}.json`
    const options = {
      end_date,
      key: reportKey,
      owner_id,
      owner_type,
      report_type: reportTypeKey,
      start_date,
      ...params,
    }

    return queueReportExport(exportUrl, options)
  }

  setState({ loading: false })

  return new Promise(resolve => resolve({ success: false, errors: ['Generate Report Failed'] }))
}

const processingComplete = (data, setState) => {
  setState({ loading: false })

  const link = digObject(data, 'payload.file.url', '')
  if (link) window.open(link, '_blank')
}

const defaultState = {
  loading: false,
  selectedReportGroupKey: '',
  selectedReportItemKey: '',
}

const buildReportEntity = (defaultFormState, selectedReportItemKey, userRoleKey) => {
  if (selectedReportItemKey === 'users_created' && isOrganizationUser(userRoleKey)){
    return {
      ...defaultFormState,
      end_date: DateTime.now().toISODate(),
      start_date: DateTime.now().minus({ years: 5 }).toISODate(),
    }
  }

  return defaultFormState
}

function useReporting(){
  const realtimeContext = useContext(RealtimeContext)
  const { clientRef } = realtimeContext

  const { selectedOrganization } = useOrganizationSelector()
  const { isBrandUser, userRoleKey } = useCurrentUser()

  const [state, setState] = useSetState(defaultState)
  const { loading, selectedReportGroupKey, selectedReportItemKey } = state

  const reportItems = generateReportItems({}, isBrandUser)
  const selectedReportItem = reportItems.find(item => item.key === selectedReportItemKey) || {}

  const { defaultState: defaultFormState = {}, requiredFields = [] } = reportModels[selectedReportItemKey] || {}

  const entity = buildReportEntity(defaultFormState, selectedReportItemKey, userRoleKey)
  const reportingForm = useForm(defaultFormState, { entity, requiredFields, validateOn: 'change' }, [selectedReportItem.key])

  const entityState = digObject(reportingForm, 'entityState', {})

  const [uuid] = useUniqueId({
    persistKey: window.location.pathname,
  })

  const fieldKeys = Object.keys(entityState)

  useReduxAction('brands', 'loadBrands', {}, [fieldKeys.includes('brand_id')], {
    shouldPerformFn: ({ loaded }) => fieldKeys.includes('brand_id') && !loaded,
  })

  const reportKey = `${uuid}${generateKeyFromParams(entityState)}`

  // Realtime/Websocket Connection
  useRealtimeSubscription({
    apiKey: process.env.REACT_APP_ABLY_PUBLIC_KEY,
    callbacks: {
      success: data => processingComplete(data, setState),
    },
    channelName: 'reports',
    clientRef,
    eventName: `report.${reportKey}.build.complete`,
  }, [reportKey])

  const reportGroups = [
    { key: 'digital_page', title: 'Digital Page' },
    { key: 'digital_template', title: 'Digital Template' },
    !isBrandUser && { key: 'integration', title: 'Integration' },
    { key: 'organization', title: 'Organization' },
    { key: 'user', title: 'User' },
  ].filter(Boolean)

  return {
    callbacks: {
      generateReport: () => generateReport(
        selectedReportItemKey,
        { ...entityState },
        userRoleKey,
        selectedOrganization,
        reportKey,
        setState,
      ),
      setState,
      shouldRenderField: key => Object.keys(defaultFormState).includes(key),
    },
    loading,
    reportGroups,
    reportingForm,
    reportItems,
    selectedReportGroupKey,
    selectedReportItemKey,
    urls: generateUrls(),
  }
}

export default useReporting
