type SetState = (payload: {}) => void

function openModalAndCloseAllOthers(openModalKey: string, modalKeys: string[], setState: SetState) {
  if (openModalKey){
    setState({ [`show${openModalKey}`]: true })
  }

  // Push to end of call stack to avoid modal flashing
  setTimeout(() => {
    const newState = modalKeys.reduce((acc, modalKey) => {
      if (openModalKey !== modalKey){
        acc[`show${modalKey}`] = false
      }

      return acc
    }, {})

    setState(newState)
  }, 0)
}

export default openModalAndCloseAllOthers
