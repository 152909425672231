import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Box, CustomizableDashboard } from '@campaignhub/suit-theme'

import VideoModule from '@components/VideoModule'

import useVideoModules from '@hooks/useVideoModules'

import Tools from './Tools'

import styles from './styles.module.scss'

const ContentSidebar = React.forwardRef((props, ref) => {
  const {
    callbacks, draggablePlaceholder, id, isDraggingOver, modules, projectId,
  } = props

  const videoModulePayload = useVideoModules({ key: 'projectOverview' })
  const { videosPayload } = videoModulePayload

  return (
    <Box
      className={classnames(styles.root, isDraggingOver ? styles.draggingOver : null)}
      flexDirection="column"
      width={['100%', '100%', '375px']}
    >
      <Tools callbacks={callbacks} projectId={projectId} />

      <Box marginTop="large">
        <VideoModule videosPayload={videosPayload} />
      </Box>

      <div ref={ref} style={{ flexGrow: 1, minHeight: '100vh' }}>
        {modules && <CustomizableDashboard.ModuleRenderer modules={modules} parentId={id} />}
        {draggablePlaceholder}
      </div>
    </Box>
  )
})

ContentSidebar.propTypes = {
  callbacks: PropTypes.object,
  draggablePlaceholder: PropTypes.object,
  id: PropTypes.string,
  isDraggingOver: PropTypes.bool,
  modules: PropTypes.array,
  projectId: PropTypes.number,
}

export default ContentSidebar
