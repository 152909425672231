import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGripVertical } from '@fortawesome/pro-solid-svg-icons'

import {
  Box, DynamicInput, FormField, Text,
} from '@campaignhub/suit-theme'

import useCustomField from '@hooks/useCustomField'

import styles from './styles.module.scss'

const CustomField = React.forwardRef((props, ref) => {
  const {
    callbacks: {
      editFormField,
    },
    customField,
    dragHandleProps,
    dragSnapshot,
    draggableProps,
    last,
  } = props

  const { isDragging } = dragSnapshot || {}

  const itemPayload = useCustomField(customField)

  const {
    customField: {
      field_type, hint, key, input_type, label, placeholder, required,
    },
    optionValues,
  } = itemPayload

  return (
    <Box
      backgroundColor="white"
      border="1px solid"
      borderColor="lineColor"
      borderRadius={5}
      className={classnames(styles.root, isDragging ? styles.dragging : null)}
      flexDirection="column"
      isDragging={isDragging}
      marginBottom={last ? 'none' : 'large'}
      onClick={() => editFormField(customField)}
      padding="large"
      paddingLeft="xlarge"
      ref={ref}
      width="100%"
      {...draggableProps}
      {...dragHandleProps}
    >
      <Box className={styles.dragHandle} color="#cacaca" fontSize="small">
        <FontAwesomeIcon icon={faGripVertical} />
      </Box>

      <Box alignItems="center">
        <Text>{required && '* '}{label}</Text>
        <Text color="bodyFontLightColor" fontSize="small" fontStyle="italic" marginLeft="medium">
          {key}
        </Text>
      </Box>

      <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="medium">
        {hint}
      </Text>

      <Box marginTop="medium">
        <FormField direction="column">
          <DynamicInput
            currentValue=""
            inputComponent={field_type}
            inputType={input_type}
            placeholder={placeholder}
            style={{ height: field_type === 'textarea' ? 80 : null }}
            values={optionValues.map(item => ({ key: item.key, label: item.value }))}
          />
        </FormField>
      </Box>
    </Box>
  )
})

CustomField.propTypes = {
  callbacks: PropTypes.shape({
    editFormField: PropTypes.func.isRequired,
  }).isRequired,
  customField: PropTypes.object.isRequired,
  dragHandleProps: PropTypes.object,
  dragSnapshot: PropTypes.object,
  draggableProps: PropTypes.object,
  last: PropTypes.bool,
}

export default CustomField
