import PropTypes from 'prop-types'

import {
  Box, Button, Checkbox, FormField, ListItem, SidebarModal,
} from '@campaignhub/suit-theme'

import useBuildDigitalPageMetrics from './hooks/useBuildDigitalPageMetrics'

const BuildDigitalPageMetricsModal = (props) => {
  const { callbacks, modalKey, showModal } = props

  const { buildDigitalPageMetrics, closeModal } = callbacks || {}

  const {
    callbacks: { buildDigitalPageMetrics: buildFn, toggleEntityKey },
    defaultEntityKeys,
    state: { selectedEntityKeys },
  } = useBuildDigitalPageMetrics()

  const buildDigitalPageMetricsPayload = {
    callbacks: {
      action: buildFn,
      afterAction: closeModal,
    },
    toastText: 'Metric Collections Successfully Built',
  }

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header callbacks={callbacks} title="Build" titleSecondLine="Metrics" />
      <SidebarModal.Content>
        <FormField direction="column" label="Build Monthly Metrics for Selected Entities">
          {defaultEntityKeys.map(entityKey => (
            <ListItem.WithCheckbox
              boxProps={{ border: 'none' }}
              checkbox={
                <Checkbox checked={selectedEntityKeys.includes(entityKey)} onClick={() => toggleEntityKey(entityKey)} />
              }
              onClick={() => toggleEntityKey(entityKey)}
              key={entityKey}
            >
              <Box color="bodyFontLightColor" fontSize="small">
                {entityKey}
              </Box>
            </ListItem.WithCheckbox>
          ))}
        </FormField>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          onClick={() => buildDigitalPageMetrics(buildDigitalPageMetricsPayload)}
          size="large"
        >
          Build Metrics
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

BuildDigitalPageMetricsModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

BuildDigitalPageMetricsModal.defaultProps = {
  modalKey: 'BuildDigitalPageMetricsModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <BuildDigitalPageMetricsModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
