import { denormalize, normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities } from '@redux/entities'

import api from '@functions/api'

import { handleError } from '../utils'

const UPDATE_REQUEST = 'realbase/digitalTemplatePage/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realbase/digitalTemplatePage/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realbase/digitalTemplatePage/UPDATE_FAILURE'

const DELETE_REQUEST = 'realbase/digitalTemplatePage/DELETE_REQUEST'
const DELETE_SUCCESS = 'realbase/digitalTemplatePage/DELETE_SUCCESS'
const DELETE_FAILURE = 'realbase/digitalTemplatePage/DELETE_FAILURE'

const SELECT_PAGE_ID = 'realbase/digitalTemplatePage/SELECT_PAGE_ID'

// Initial State
const initialState = {
  updating: false,
  selectedId: null,
  deleting: false,
  errors: [],
}

// Actions
export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function selectPageId(pageId){
  return (dispatch) => {
    dispatch({
      type: SELECT_PAGE_ID,
      selectedId: pageId,
    })
  }
}

export function deleteRequest(){
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess(){
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors = []){
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Action Creators
export function updateDigitalTemplatePage(digitalTemplatePage, options = {}){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      digital_template_page: digitalTemplatePage,
    }),
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/digital_template_pages/${digitalTemplatePage.id}.json`, options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.DIGITAL_TEMPLATE_PAGE)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))
        return { success: true, errors }
      })

    return promise
  }
}

export function copySectionToSectionGroup(digitalTemplatePage, payload, options = {}){
  const { index, sectionGroupId, section } = payload || {}

  return (dispatch, getState) => {
    const state = getState()

    dispatch(updateRequest())

    // Denormalize the section so we pass the full object to the server
    const denormalizedSection = denormalize(section, Schemas.DIGITAL_TEMPLATE_PAGE_SECTION, state.entities)

    const config = {
      method: 'PUT',
      body: {
        index: index || 0,
        section_data: JSON.stringify(denormalizedSection),
        section_group_id: sectionGroupId,
      },
    }

    const promise = api(`/digital_template_pages/${digitalTemplatePage.id}/copy_section_to_group.json`, options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.DIGITAL_TEMPLATE_PAGE)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))
        return { success: true, errors }
      })

    return promise
  }
}

export function importDigitalTemplatePageLayout(digitalTemplatePage, options){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      digital_template_page: digitalTemplatePage,
    }),
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const url = `/digital_template_pages/${digitalTemplatePage.id}/import_layout_data.json`
    const promise = api(url, options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.DIGITAL_TEMPLATE_PAGE)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))
        return { success: true, errors }
      })

    return promise
  }
}

export function importDigitalTemplatePageExternalComponentStyles(digitalTemplatePage, options){
  const config = {
    method: 'PUT',
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const url = `/digital_template_pages/${digitalTemplatePage.id}/import_external_component_styles.json`
    const promise = api(url, options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.DIGITAL_TEMPLATE_PAGE)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))
        return { success: true, errors }
      })

    return promise
  }
}

export function importDigitalTemplatePageExternalImages(digitalTemplatePage, options){
  const config = {
    method: 'PUT',
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const url = `/digital_template_pages/${digitalTemplatePage.id}/import_external_images.json`
    const promise = api(url, options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.DIGITAL_TEMPLATE_PAGE)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))
        return { success: true, errors }
      })

    return promise
  }
}

export function createOrUpdatePageItemAttachment(attachment, digitalTemplatePage, options){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      attachment,
      digital_template_page_id: digitalTemplatePage.id,
    }),
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const url = `/digital_template_pages/${digitalTemplatePage.id}/create_or_update_attachment_for_item.json`
    const promise = api(url, options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.DIGITAL_TEMPLATE)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))
        return { success: true, errors }
      })

    return promise
  }
}

export function deleteDigitalTemplateItemAttachments(digitalTemplatePage, digitalTemplateItemIds){
  const config = {
    method: 'DELETE',
    body: JSON.stringify({
      digital_template_item_ids: digitalTemplateItemIds,
    }),
  }

  return (dispatch) => {
    dispatch(deleteRequest())

    const promise = api(`/digital_templates/${digitalTemplatePage.id}/destroy_item_attachments.json`, {}, config)
      .then(({ data }) => ({ success: true, data }))
      .catch((data) => {
        const errors = handleError(data)
        dispatch(deleteFailure(errors))
        return { success: true, errors }
      })
    return promise
  }
}

export function restoreLayoutFileFromAttachment(digitalTemplatePage, attachmentId, options){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      attachment_id: attachmentId,
    }),
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const url = `/digital_template_pages/${digitalTemplatePage.id}/restore_layout_from_attachment.json`
    const promise = api(url, options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.DIGITAL_TEMPLATE_PAGE)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))
        return { success: true, errors }
      })

    return promise
  }
}

export function updateDigitalPageGroupSectionSort(digitalTemplatePage, sortedGroupSections, options = {}){
  const { id } = digitalTemplatePage

  const config = {
    method: 'PUT',
    body: JSON.stringify({
      sorted_group_sections: sortedGroupSections,
    }),
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/digital_template_pages/${id}/update_group_section_sort.json`, options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.DIGITAL_TEMPLATE_PAGE)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))
        return { success: true, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      }
    case SELECT_PAGE_ID:
      return {
        ...state,
        selectedId: action.selectedId,
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
