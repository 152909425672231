import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faPlus } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Checkbox, ListItem, SortableList, Text,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'
import useQuoteItem from '@hooks/useQuoteItem'
import useQuoteSection from '@hooks/useQuoteSection'

import QuoteItem from '../QuoteItem'
import localizedStrings from './localizedStrings'

const QuoteSection = React.forwardRef((props, ref) => {
  const {
    callbacks,
    callbacks: { showCreateOrEditQuoteItemModal, showCreateOrEditQuoteSectionModal },
    first,
    index,
    last,
    lastIndex,
    quoteSection,
    sortedQuoteItemIds,
  } = props

  const sectionPayload = useQuoteSection(quoteSection, {
    callbacks: {
      showCreateOrEditQuoteSectionModal,
    },
  })

  const {
    callbacks: { addQuoteSection, editQuoteSection, moveQuoteSectionId },
    hideDates,
    hidePrices,
    hideSubtotal,
    quoteItems,
    quoteSection: {
      footer, header, id, quote_id, title,
    },
    subtotal,
  } = sectionPayload

  const {
    callbacks: { addQuoteItem },
  } = useQuoteItem(
    { quote_id, quote_section_id: id },
    {
      callbacks: {
        showCreateOrEditQuoteItemModal,
      },
    },
  )

  const modalPayload = {
    callbacks: { moveQuoteSectionId },
    first,
    index,
    last,
    lastIndex,
  }

  const {
    callbacks: { formatCurrency },
    strings,
  } = useLocalization(localizedStrings)

  return (
    <Box flexDirection="column" padding="large" ref={ref}>
      <ListItem boxProps={{ paddingY: 'large' }} onClick={() => editQuoteSection(modalPayload)} showAngleIcon>
        <Text fontSize="large">{title}</Text>

        {!hideSubtotal && (
          <Text fontSize="large" marginLeft="auto">
            {formatCurrency(subtotal)}
          </Text>
        )}
      </ListItem>

      {header && (
        <Text color="bodyFontLightColor" fontSize="small" lineHeight="1.3" paddingY="large">
          {header}
        </Text>
      )}

      <SortableList.Dropzone droppableId={`Section-${id}`}>
        <Box flexDirection="column">
          {!sortedQuoteItemIds.length && (
            <ListItem
              boxProps={{
                alignItems: 'center',
                backgroundColor: 'white',
                borderBottom: '1px dashed',
                borderColor: 'lineColor',
                paddingY: 'medium',
              }}
              onClick={() => addQuoteItem(quoteSection)}
              showAngleIcon
            >
              <Checkbox checked={false} iconDefault={faTimesCircle} />

              <Box flexDirection="column">
                <Text color="bodyFontColor" fontSize="small" lineHeight="1.3">
                  {strings.noItem || 'No items added to section'}
                </Text>
              </Box>

              <Text color="bodyFontLightColor" fontSize="small" lineHeight="1.3" marginLeft="auto">
                {strings.add || 'Add'}
              </Text>
            </ListItem>
          )}

          {sortedQuoteItemIds.map((quoteItemId, itemIndex) => {
            const quoteItem = quoteItems[quoteItemId] || {}

            return (
              <SortableList.Item draggableId={`Item-${quoteItem.id}`} key={quoteItem.id} index={itemIndex}>
                <QuoteItem callbacks={callbacks} hideDates={hideDates} hidePrices={hidePrices} quoteItem={quoteItem} />
              </SortableList.Item>
            )
          })}
        </Box>
      </SortableList.Dropzone>

      {footer && (
        <Text color="bodyFontLightColor" fontSize="small" lineHeight="1.3" paddingY="large">
          {footer}
        </Text>
      )}

      <Box borderTop="1px dashed" borderColor="lineColor" flexDirection={['column', 'row']} paddingTop="large">
        <Box width={['100%', 'auto']}>
          <Button
            buttonStyle="secondaryUtility"
            icon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => addQuoteSection(lastIndex, index)}
            size="small"
            width="auto"
          >
            {strings.newSection || 'New Section'}
          </Button>
        </Box>

        <Box marginTop={['medium', 0]} marginLeft="auto" width={['100%', 'auto']}>
          <Button
            buttonStyle="primaryEdit"
            icon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => addQuoteItem(quoteSection)}
            size="small"
            width="auto"
          >
            {strings.addItem || 'Add Item'}
          </Button>
        </Box>
      </Box>
    </Box>
  )
})

QuoteSection.propTypes = {
  callbacks: PropTypes.shape({
    showCreateOrEditQuoteItemModal: PropTypes.func.isRequired,
    showCreateOrEditQuoteSectionModal: PropTypes.func.isRequired,
  }).isRequired,
  first: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  last: PropTypes.number.isRequired,
  lastIndex: PropTypes.number.isRequired,
  quoteSection: PropTypes.object.isRequired,
  sortedQuoteItemIds: PropTypes.array.isRequired,
}

export default QuoteSection
