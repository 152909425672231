import { useContext } from 'react'

import PageContext from '@contexts/pageContext'

type Options = {
  entity: object,
  updateFn: (updatedParams: {}, requestOptions?: {}) => void,
}

type ManageEntityUserPermissionParams = {
  entity: object,
  showManageEntityUserPermissionsModal: (payload: {}) => void,
  updateFn: (updatedParams: {}, requestOptions?: {}) => void,
}

const manageEntityUserPermission = (params: ManageEntityUserPermissionParams) => new Promise((resolve, reject) => {
  const {
    entity, updateFn, showManageEntityUserPermissionsModal,
  } = params

  // eslint-disable-next-line @typescript-eslint/space-before-blocks
  if (showManageEntityUserPermissionsModal) {
    const payload = {
      callbacks: {
        updateFn: (updatedParams, requestOptions) => updateFn(updatedParams, requestOptions),
      },
      entity,
    }

    showManageEntityUserPermissionsModal(payload)

    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showManageEntityUserPermissionsModal not defined in PageContext callbacks'))
})

function useManageEntityUserPermissions(options: Options) {
  const { entity, updateFn } = options || {}

  const { callbacks } = useContext(PageContext)
  const { showManageEntityUserPermissionsModal } = callbacks || {}

  return {
    callbacks: {
      manageEntityUserPermission: () => manageEntityUserPermission({
        entity,
        updateFn,
        showManageEntityUserPermissionsModal,
      }),
    },
  }
}

export default useManageEntityUserPermissions
