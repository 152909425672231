import { Box, Image, Text } from '@campaignhub/suit-theme'

import icons from './assets/icons'

interface AboutIntegrationItemProps {
  description: string,
  iconKey: string,
  title: string,
}

const AboutIntegrationItem = (props: AboutIntegrationItemProps) => {
  const { description, iconKey, title } = props

  const iconUrl = icons[iconKey]

  return (
    <Box marginTop="large">
      <Image boxProps={{ marginRight: 'medium' }} height={40} width={40} url={iconUrl} />

      <Box flexDirection="column">
        <Text fontSize="medium" marginBottom="small">
          {title}
        </Text>

        <Text color="bodyFontLightColor" lineHeight="1.3" fontSize="small">
          {description}
        </Text>
      </Box>
    </Box>
  )
}

export default AboutIntegrationItem
