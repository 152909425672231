import { useContext } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faSort } from '@fortawesome/pro-light-svg-icons'

import { Box, Button, DashboardModule } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import useAwards from '@hooks/useAwards'
import useLocalization from '@hooks/useLocalization'

import Award from './Award'
import localizedStrings from './localizedStrings'

interface AwardProps {
  ownerId: number,
  subjectId: number,
  subjectType: 'User' | 'Organization',
}

const Awards = (props: AwardProps) => {
  const { ownerId, subjectId, subjectType } = props

  const awardsPayload = useAwards({
    filters: {
      owner_id: ownerId,
      owner_type: 'Organization',
      subject_id: subjectId,
      subject_type: subjectType,
    },
    performHttpRequests: true,
    requestOptions: {
      include_award_data_store_items: true,
      include_award_default_image: true,
      include_image_sizes: ['thumb_120x80', 'thumb_660x360'],
    },
  })

  const {
    callbacks: { editAwardSort },
    filteredAwards,
    hasAwards,
    loading,
  } = awardsPayload

  const pageContext = useContext(PageContext)

  const {
    callbacks: { showCreateOrEditAwardModal },
  } = pageContext

  const { strings } = useLocalization(localizedStrings)

  return (
    <DashboardModule
      boxProps={{ marginBottom: 'large' }}
      loading={loading}
      headerRight={(
        <DashboardModule.Link textProps={{ color: 'lightGrey', fontSize: 'xsmall' }} onClick={() => editAwardSort()}>
          <FontAwesomeIcon icon={faSort} /> {strings.sortAward || 'Sort Awards'}
        </DashboardModule.Link>
      )}
      title={strings.title || 'Awards'}
    >
      <Box flexDirection="column">
        {hasAwards && (
          <Box flexDirection="column" maxHeight={415} overflow="auto">
            {filteredAwards.map(award => (
              <Award key={award.id} award={award} />
            ))}
          </Box>
        )}

        <Button
          as="a"
          buttonStyle="secondaryUtility"
          icon={<FontAwesomeIcon icon={faPlus} />}
          onClick={() => showCreateOrEditAwardModal()}
          size="medium"
          style={{ margin: 16, width: 'calc(100% - 32px)' }}
        >
          {strings.createButton || 'Create Award'}
        </Button>
      </Box>
    </DashboardModule>
  )
}

export default Awards
