export default {
  default: {
    buttons: {
      deleteComparable: 'Delete Comparable',
      enterAddress: 'Enter Address',
      save: 'Save',
      manageImages: 'Manage Images',
    },
    formFieldLabels: {
      image: 'Comparable Images',
      moreOptions: 'More Options',
      source: 'Source',
    },
    sideBarModalHeader: {
      titleCreate: 'Create',
      titleSecondLine: 'Comparable',
      titleUpdate: 'Update',
    },
    sourceValues: {
      market: 'Market',
      organization: '{global.organizationType.shortName.singular}',
    },
    sweetAlert: {
      confirm: 'Yes, delete it.',
      text: 'Are you sure?',
      title: 'Delete Comparable?',
    },
    toast: {
      comparableCreated: 'Comparable Created Successfully',
      comparableDeleted: 'Comparable Deleted Successfully',
      comparableUpdated: 'Comparable Updated Successfully',
    },
  },
}
