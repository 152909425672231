import { useContext, useMemo } from 'react'

import { useForm, useWatchEntityUpdates } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import { launchModal } from '@campaignhub/javascript-utils'

import useCurrentUser from '@hooks/useCurrentUser'
import useDispatch from '@hooks/useDispatch'
import useOrganizationSelector from '@hooks/useOrganizationSelector'
import useSelector from '@hooks/useSelector'

import filterUnselectedEntities from '@functions/filterUnselectedEntities'
import { isAdminForOrganizationId, isOwnerForOrganizationId } from '@functions/user'

import * as organizationsUserActions from '@redux/modules/organizationsUser'

import defaultFormState, { requiredFields } from '@models/organizationsUser'

import PageContext from '@contexts/pageContext'

import type { AppDispatch } from '@redux/store'
import type { OrganizationsUserModel, OrganizationsUserRequestOptions, UserModel } from '@models/types'

const watchEntityKeys = ['organizationsUsers']

type CreateOrganizationsUserParams = {
  dispatch: AppDispatch,
  organizationsUserParams: Partial<OrganizationsUserModel>,
  requestOptions?: OrganizationsUserRequestOptions,
}

const createOrganizationsUser = (params: CreateOrganizationsUserParams) => {
  const { dispatch, organizationsUserParams, requestOptions } = params
  const { createOrganizationsUser: createFn } = organizationsUserActions

  return dispatch(createFn(organizationsUserParams, requestOptions))
}

type UpdateOrganizationsUserParams = {
  dispatch: AppDispatch,
  organizationsUser: Partial<OrganizationsUserModel>,
  organizationsUserParams: Partial<OrganizationsUserModel>,
  requestOptions?: OrganizationsUserRequestOptions,
}

const updateOrganizationsUser = (params: UpdateOrganizationsUserParams) => {
  const {
    dispatch, organizationsUser, organizationsUserParams, requestOptions,
  } = params
  const { updateOrganizationsUser: updateFn } = organizationsUserActions

  const updatedParams = {
    id: organizationsUser.id,
    ...organizationsUserParams,
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

type DeleteOrganizationsUserParams = {
  dispatch: AppDispatch,
  isCurrentOrganization: boolean,
  organizationsUser: Partial<OrganizationsUserModel>,
}

const deleteOrganizationsUser = (params: DeleteOrganizationsUserParams) => {
  const { dispatch, isCurrentOrganization, organizationsUser } = params
  const { deleteOrganizationsUser: deleteFn } = organizationsUserActions

  return dispatch(deleteFn(organizationsUser)).then((response) => {
    const { success } = response
    if (success && isCurrentOrganization){
      return {
        success,
        redirectUrl: '#/admin/users',
      }
    }

    return response
  })
}

type RemoveUserFromOrganizationParams = {
  dispatch: AppDispatch,
  isAdmin: boolean | string,
  isCurrentOrganization: boolean,
  organizationsUser: Partial<OrganizationsUserModel>,
  targetUserId: number,
  user: Partial<UserModel>,
}

const removeUserFromOrganization = (params: RemoveUserFromOrganizationParams) => {
  const {
    dispatch, isAdmin, isCurrentOrganization, organizationsUser, targetUserId, user,
  } = params
  const { removeUserFromOrganization: deleteFn } = organizationsUserActions

  return dispatch(deleteFn(organizationsUser, targetUserId, user)).then((response) => {
    const { success } = response
    if (success && isCurrentOrganization){
      return {
        success,
        redirectUrl: isAdmin ? `#/admin/users/${'#/admin/users'.user_id}/edit` : '#/admin/users',
      }
    }

    return response
  })
}

type SetOrganizationsUserParams = {
  dispatch: AppDispatch,
  organizationsUser: Partial<OrganizationsUserModel>,
}

const setOrganizationsUserAsDefault = (params: SetOrganizationsUserParams) => {
  const { dispatch, organizationsUser } = params
  const { setOrganizationsUserAsDefault: setDefaultFn } = organizationsUserActions

  return dispatch(setDefaultFn(organizationsUser))
}

type HandleUserLevelChangeParams = {
  setEntityState: (params: Record<string, boolean>) => void,
  userLevelKey: string,
}

const handleUserLevelChange = (params: HandleUserLevelChangeParams) => {
  const { setEntityState, userLevelKey } = params

  if (userLevelKey === 'owner') return setEntityState({ admin: true, owner: true })
  if (userLevelKey === 'admin') return setEntityState({ admin: true, owner: false })

  return setEntityState({ admin: false, owner: false })
}

const getUserLevelKey = (entityState: { admin: boolean, owner: boolean }) => {
  const { admin, owner } = entityState

  if (admin && owner) return 'owner'
  if (admin && !owner) return 'admin'
  return 'user'
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useOrganizationsUserForm(
  organizationsUser: Partial<OrganizationsUserModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const organizationsUserForm = useForm(
    defaultFormState,
    { entity: organizationsUser, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [organizationsUser.id, organizationsUser.cache_key],
  )

  return {
    ...organizationsUserForm,
  }
}

export const useRelations = (organizationsUser: Partial<OrganizationsUserModel> = {}) => {
  const { organization_id, user_id } = organizationsUser

  const { organizations, users } = useSelector(reduxState => reduxState.entities)

  const organization = organization_id ? organizations[organization_id] || {} : {}
  const user = user_id ? users[user_id] || {} : {}

  return {
    organization,
    user,
  }
}

type UseOrganizationsUserOptions = {
  organization_id?: number,
  user_id?: number,
}

function useOrganizationsUser(options: UseOrganizationsUserOptions = {}) {
  const { organization_id, user_id } = options

  const dispatch = useDispatch()

  const {
    updatedEntities: { organizationsUsers: organizationsUsersUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { callbacks } = useContext(PageContext)

  const { selectedOrganization } = useOrganizationSelector()
  const { currentUser, isAdmin } = useCurrentUser()

  const { entities } = useSelector(reduxState => reduxState)
  const { organizationsUsers } = entities

  const { creating, updating } = useSelector(reduxState => reduxState.organizationsUsers)

  const userId = user_id || currentUser.id
  const organizationId = organization_id || selectedOrganization.id

  const organizationsUser = useMemo(() => {
    const array = Object.values(organizationsUsers)
    const entity = array.find(user => user.user_id === userId && user.organization_id === organizationId)

    return entity || { organization_id: organizationId, user_id: userId }
  }, [userId, organizationId, organizationsUsersUpdatedAt])

  const { admin, owner } = organizationsUser

  const { organization, user } = useRelations(organizationsUser)
  const { users_where_admin_ids = [], users_where_owner_ids = [] } = organization
  const { organization_ids } = user

  const availableOrganizationsToJoin = filterUnselectedEntities(entities, 'organizations', organization_ids)
  const hasAvailableOrganizationsToJoin = availableOrganizationsToJoin?.length > 0

  // we need to know if there are more than one admin/owner in the selected organization
  const hasMultipleAdmins = users_where_admin_ids.length > 1
  const hasMultipleOwners = users_where_owner_ids.length > 1

  // if the user is an owner and there are multiple owners
  const canOwnerLeave = owner && hasMultipleOwners

  // if the user is an admin and there are multiple admins
  const canAdminLeave = (admin && !owner && hasMultipleAdmins) || canOwnerLeave

  const canUserLeave = !owner && !admin ? true : canAdminLeave

  const isCurrentOrganization = organization_id === selectedOrganization.id

  const isSelf = user ? currentUser.id === user.id : false

  const isCurrentUserAdminForOrganization = isAdminForOrganizationId(currentUser, organizationId)
  const isCurrentUserOwnerForOrganization = isOwnerForOrganizationId(currentUser, organizationId)

  return {
    availableOrganizationsToJoin,
    callbacks: {
      addUserToOrganization: () => launchModal({
        callbacks,
        modalKey: 'AddUserToOrganizationModal',
        payload: {
          callbacks: {
            createOrganizationsUser: (
              organizationsUserParams: Partial<OrganizationsUserModel>,
              entityOptions?: OrganizationsUserRequestOptions,
            ) => (
              createOrganizationsUser({ dispatch, organizationsUserParams, requestOptions: entityOptions })
            ),
          },
          organizationsUser,
        },
      }),
      editOrganizationsUser: () => launchModal({
        callbacks,
        modalKey: 'EditOrganizationsUserModal',
        payload: {
          callbacks: {
            createOrganizationsUser: (
              organizationsUserParams: Partial<OrganizationsUserModel>,
              entityOptions?: OrganizationsUserRequestOptions,
            ) => (
              createOrganizationsUser({ dispatch, organizationsUserParams, requestOptions: entityOptions })
            ),
            deleteOrganizationsUser: () => deleteOrganizationsUser({
              dispatch, isCurrentOrganization, organizationsUser,
            }),
            setOrganizationsUserAsDefault: () => setOrganizationsUserAsDefault({ dispatch, organizationsUser }),
            updateOrganizationsUser: (
              organizationsUserParams: Partial<OrganizationsUserModel>,
              entityOptions?: OrganizationsUserRequestOptions,
            ) => (
              updateOrganizationsUser({
                dispatch, organizationsUser, organizationsUserParams, requestOptions: entityOptions,
              })
            ),
          },
          organizationsUser,
        },
      }),
      removeOrganizationsUser: () => launchModal({
        callbacks,
        modalKey: 'RemoveOrganizationsUserModal',
        payload: {
          callbacks: {
            removeUserFromOrganization: (targetUserId: number) => removeUserFromOrganization({
              dispatch, isAdmin, isCurrentOrganization, organizationsUser, targetUserId, user,
            }),
          },
          organizationsUser,
        },
      }),
      getUserLevelKey,
      handleUserLevelChange,
    },
    canAdminLeave,
    canOwnerLeave,
    canUserLeave,
    creating,
    currentUser,
    hasAvailableOrganizationsToJoin,
    hasMultipleAdmins,
    hasMultipleOwners,
    isAdmin: admin,
    isCurrentOrganization,
    isCurrentUserAdminForOrganization,
    isCurrentUserOwnerForOrganization,
    isOwner: owner,
    isSelf,
    organization,
    organizationsUser,
    selectedOrganization,
    updating,
    user,
  }
}

export default useOrganizationsUser
