import React from 'react'
import PropTypes from 'prop-types'

const BarGraphIcon = (props) => {
  const { fillColor, height, width } = props

  return (
    <svg width={width} height={height} viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.5625 11.25H17.1875C17.3438 11.25 17.5 11.1328 17.5 10.9375V1.5625C17.5 1.40625 17.3438 1.25 17.1875 1.25H16.5625C16.3672 1.25 16.25 1.40625 16.25 1.5625V10.9375C16.25 11.1328 16.3672 11.25 16.5625 11.25ZM12.8125 11.25H13.4375C13.5938 11.25 13.75 11.1328 13.75 10.9375V5.3125C13.75 5.15625 13.5938 5 13.4375 5H12.8125C12.6172 5 12.5 5.15625 12.5 5.3125V10.9375C12.5 11.1328 12.6172 11.25 12.8125 11.25ZM5.3125 11.25H5.9375C6.09375 11.25 6.25 11.1328 6.25 10.9375V7.8125C6.25 7.65625 6.09375 7.5 5.9375 7.5H5.3125C5.11719 7.5 5 7.65625 5 7.8125V10.9375C5 11.1328 5.11719 11.25 5.3125 11.25ZM9.0625 11.25H9.6875C9.84375 11.25 10 11.1328 10 10.9375V2.8125C10 2.65625 9.84375 2.5 9.6875 2.5H9.0625C8.86719 2.5 8.75 2.65625 8.75 2.8125V10.9375C8.75 11.1328 8.86719 11.25 9.0625 11.25ZM19.6875 13.75H1.25V0.3125C1.25 0.15625 1.09375 0 0.9375 0H0.3125C0.117188 0 0 0.15625 0 0.3125V14.375C0 14.7266 0.273438 15 0.625 15H19.6875C19.8438 15 20 14.8828 20 14.6875V14.0625C20 13.9062 19.8438 13.75 19.6875 13.75Z"
        fill={fillColor}
      />
    </svg>
  )
}

BarGraphIcon.propTypes = {
  fillColor: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
}

BarGraphIcon.defaultProps = {
  fillColor: '#000000',
  height: 40,
  width: 40,
}

export default BarGraphIcon
