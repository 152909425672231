import { multiFilteredObjectArraySelector, sortArrayBy } from '@campaignhub/javascript-utils'

import useSelector from '@hooks/useSelector'

type UseAddressesOptions = {
  owner_id?: number,
  owner_type?: string,
}

function useAddresses(options: UseAddressesOptions = {}) {
  const { owner_id, owner_type } = options

  const addressFilters = [
    { key: 'owner_id', value: owner_id },
    { key: 'owner_type', value: owner_type },
  ]

  const filteredAddresses = useSelector(reduxState => (
    multiFilteredObjectArraySelector(reduxState, 'addresses', addressFilters)
  ))

  const { loading } = useSelector(reduxState => reduxState.addresses)

  const sortedAddresses = sortArrayBy(filteredAddresses, 'asc', 'title')

  return {
    filteredAddresses: sortedAddresses,
    loading,
  }
}

export default useAddresses
