import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'
import { matchFilterString, multiFilteredObjectArraySelector, sortArrayBy } from '@campaignhub/javascript-utils'

import defaultRequestOptions from '@sections/Client/defaultRequestOptions'

import useReduxAction from '@hooks/useReduxAction'

const watchEntityKeys = ['documentTemplates']

function useDocumentTemplates(options = {}){
  const {
    entityKey,
    ownerId,
    ownerType,
    pageFilters = {},
    performHttpRequests,
    production_ready: filterProductionReady,
  } = options || {}

  const { string } = pageFilters

  const {
    updatedEntities: { documentTemplates: documentTemplatesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  useReduxAction(
    'documentTemplates',
    'loadDocumentTemplates',
    {
      entityKey,
      owner_id: ownerId,
      owner_type: ownerType,
      production_ready: filterProductionReady,
      ...defaultRequestOptions.documentTemplate,
      ...defaultRequestOptions.image,
      ...pageFilters,
    },
    [performHttpRequests, JSON.stringify(options)],
    {
      shouldPerformFn: (entityReducer) => {
        const { loadedForKeys } = entityReducer
        return performHttpRequests && !loadedForKeys.includes(entityKey)
      },
    },
  )

  const { documentTemplates } = useSelector(reduxState => reduxState.entities)

  const filteredDocumentTemplates = useMemo(() => {
    const filtered = Object.values(documentTemplates).filter((documentTemplate) => {
      const {
        owner_id, owner_type, production_ready, title,
      } = documentTemplate

      const ownerMatch = ownerId ? owner_id === ownerId && owner_type === ownerType : true
      const productionStatus = typeof filterProductionReady !== 'undefined' ? String(filterProductionReady) === String(production_ready) : true
      const titleMatch = matchFilterString(title, string)

      return (
        ownerMatch
        && productionStatus
        && titleMatch
      )
    })

    return sortArrayBy(filtered, 'asc', 'title')
  }, [documentTemplatesUpdatedAt, JSON.stringify(options)])

  const systemTemplates = useSelector(reduxState => (
    multiFilteredObjectArraySelector(reduxState, 'documentTemplates', [{ key: 'owner_type', value: 'System' }])
  ))

  const hasDocumentTemplates = !!filteredDocumentTemplates.length
  const hasSystemTemplates = !!systemTemplates.length

  const { loading } = useSelector(state => state.documentTemplates)

  return {
    filteredDocumentTemplates,
    hasDocumentTemplates,
    hasSystemTemplates,
    loading,
    systemTemplates,
  }
}

export default useDocumentTemplates
