export default {
  default: {
    buttons: {
      delete: 'Delete Agreement',
      save: 'Save',
    },
    header: {
      title: 'Edit',
      titleSecondLine: 'Agreement',
    },
    formFieldLabels: {
      digitalTemplate: 'Document Template',
      moreOptions: 'More Options',
      status: 'Status',
      title: '* Title',
    },
    option: 'Please Select...',
  },
}
