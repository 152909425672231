import { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import PropTypes from 'prop-types'

import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons'

import {
  Box,
  Button,
  Columns,
  DashboardModule,
  LoadingModule,
  PageHeader,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import useEventCalendar from '@hooks/useEventCalendar'
import useEventCalendars from '@hooks/useEventCalendars'
import useLocalization from '@hooks/useLocalization'
import useVideoModules from '@hooks/useVideoModules'

import PageFilterModule from '@components/PageFilterModule'
import VideoModule from '@components/VideoModule'

import EventCalendarTemplate from '../EventCalendarTemplate'
import EventCalendarTemplatesBlankState from '../EventCalendarTemplatesBlankState'
import localizedStrings from './localizedStrings'

const buildPageFilterFields = (strings) => {
  const style = {
    marginBottom: 16,
  }

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        style,
      },
      key: 'string',
      label: strings.filters?.title || 'Filter by Title',
    },
  ]
}

const PageContent = (props) => {
  const { pageFilters, selectedOrganization } = props

  const eventCalendarsPayload = useEventCalendars({
    filters: {
      isSavedTemplate: true,
      ownerId: selectedOrganization.id,
      ownerType: 'Organization',
      ...pageFilters,
    },
  })

  const {
    filteredEventCalendars,
    hasEventCalendars,
    loadingTemplates,
  } = eventCalendarsPayload

  const pageContext = useContext(PageContext)
  const {
    callbacks: { resetFilters, updateFilters },
  } = pageContext

  const initTemplate = {
    owner_id: selectedOrganization.id,
    owner_type: 'Organization',
    saved_template: true,
  }

  const eventCalendarPayload = useEventCalendar(initTemplate)
  const { callbacks: { createTemplate }, urls: { eventCalendarsIndexUrl } } = eventCalendarPayload

  const videoModulePayload = useVideoModules({ key: 'calendarTemplates' })
  const { videosPayload } = videoModulePayload

  const { strings } = useLocalization(localizedStrings)

  const title = strings.title ? `${strings.title} | Engage` : 'Engage'

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <PageHeader
        actionContent={(
          <Button buttonStyle="primaryCreate" onClick={() => createTemplate()} size="medium">
            {strings.createButton || 'Create'}
          </Button>
        )}
        activeTabBarItemKey="event-calendar-templates"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        nestedNavigation
        tabBarItems={[
          {
            href: eventCalendarsIndexUrl,
            icon: faCalendarAlt,
            key: 'event-calendar-templates',
            title: strings.title || 'Calendar Templates',
          },
        ]}
        title={strings.title || 'Calendar Templates'}
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loadingTemplates} times={3} />

            {!loadingTemplates && !hasEventCalendars && <EventCalendarTemplatesBlankState />}

            {!loadingTemplates && hasEventCalendars && (
              <DashboardModule title={strings.title || 'Calendar Templates'}>
                <Box flexDirection="column">
                  <Box flexDirection="column">
                    {filteredEventCalendars.map(template => (
                      <EventCalendarTemplate key={template.id} eventCalendarTemplate={template} />
                    ))}
                  </Box>
                </Box>
              </DashboardModule>
            )}
          </Columns.Main>

          <Columns.Sidebar>
            <PageFilterModule
              callbacks={{ resetFilters, updateFilters }}
              filterFields={buildPageFilterFields(strings)}
              pageFilters={pageFilters}
              title={strings.filtersTitle || 'Filters'}
            />

            <VideoModule videosPayload={videosPayload} />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

PageContent.propTypes = {
  pageFilters: PropTypes.object.isRequired,
  selectedOrganization: PropTypes.object.isRequired,
}

export default PageContent
