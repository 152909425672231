import React from 'react'
import PropTypes from 'prop-types'

import { Box, SectionDivider, Text } from '@campaignhub/suit-theme'

import ToggleButton from '@components/ToggleButton'

const ToggleSection = (props) => {
  const {
    callbacks, enabled, sectionTitle, text, title,
  } = props
  const { toggleFn } = callbacks

  return (
    <Box flexDirection="column">
      <SectionDivider boxProps={{ marginY: 'medium' }}>{sectionTitle}</SectionDivider>

      <ToggleButton
        boxProps={{ alignItems: 'center', justifyContent: 'space-between' }}
        enabled={enabled}
        onClick={() => toggleFn()}
      >
        <Text color={enabled ? 'bodyFontColor' : 'faintGrey'} fontSize="small">
          {title}
        </Text>

        <Text
          color={enabled ? 'bodyFontLightColor' : 'faintGrey'}
          fontSize="xsmall"
          lineHeight={1.3}
          marginTop="medium"
        >
          {text}
        </Text>
      </ToggleButton>
    </Box>
  )
}

ToggleSection.propTypes = {
  callbacks: PropTypes.object.isRequired,
  enabled: PropTypes.bool.isRequired,
  sectionTitle: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default ToggleSection
