import React from 'react'
import PropTypes from 'prop-types'

import {
  Button, SidebarModal,
} from '@campaignhub/suit-theme'

import useDigitalPageMap from '@hooks/useDigitalPageMap'
import useLocalization from '@hooks/useLocalization'

import MapLocations from '@components/MapLocations'

import localizedStrings from './localizedStrings'

const Map = (props) => {
  const { callbacks, digitalPageItemPayload } = props
  const { updateDigitalPageItemCustomData } = callbacks || {}

  const {
    callbacks: { updatePageItemCustomData: updateFn },
  } = digitalPageItemPayload

  const locationPayload = useDigitalPageMap({ ...digitalPageItemPayload })
  const { isComparablesMap, locations } = locationPayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      <SidebarModal.Header
        callbacks={callbacks}
        disableAnimation
        title={strings.title || 'Edit'}
        titleSecondLine={strings.titleSecondLine || 'Map'}
      />
      <SidebarModal.Content>
        <MapLocations locationPayload={locationPayload} />
      </SidebarModal.Content>

      {!isComparablesMap && (
      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          onClick={() => updateDigitalPageItemCustomData({ locations }, updateFn)}
          size="large"
        >
          {strings.buttons?.save || 'Save'}
        </Button>
      </SidebarModal.Footer>
      )}

    </>
  )
}

Map.propTypes = {
  callbacks: PropTypes.object.isRequired,
  digitalPageItemPayload: PropTypes.object.isRequired,
}

export default Map
