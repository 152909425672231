export default {
  default: {
    buttons: {
      browse: {
        subtitle: 'Browse similar properties nearby',
        title: 'Browse Comparables',
      },
      create: {
        subtitle: 'Create your own property reference',
        title: 'Add Manual Comparable',
      },
      search: {
        subtitle: 'Find a specific property',
        title: 'Search Exact Match',
      },
      clone: {
        subtitle: 'Clone from existing comparables',
        title: 'Use Existing Comparables',
      },
    },
  },
}
