import React from 'react'
import PropTypes from 'prop-types'

import { BlankState } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'
import { useRelations } from '@hooks/useProject'

import localizedStrings from './localizedStrings'

import agreementImageUrl from './assets/no-agreements.svg'
import formImageUrl from './assets/no-forms.svg'
import micrositeImageUrl from './assets/no-microsites.svg'
import proposalImageUrl from './assets/no-proposals.svg'

const renderImage = (digitalTemplateType) => {
  if (digitalTemplateType === 'agreement') return agreementImageUrl
  if (digitalTemplateType === 'form') return formImageUrl
  if (digitalTemplateType === 'microsite') return micrositeImageUrl
  return proposalImageUrl
}

const DigitalPageBlankState = (props) => {
  const { digitalTemplateTypeKey, projectId } = props

  const { projectType } = useRelations({ id: projectId })

  const digitalTemplateType = { key: digitalTemplateTypeKey }
  const { strings } = useLocalization(localizedStrings, { digitalTemplateType, projectType })

  return (
    <BlankState imageUrl={renderImage(digitalTemplateType)}>
      <BlankState.Title>
        {strings.title}
      </BlankState.Title>
      <BlankState.Paragraph>{strings[digitalTemplateTypeKey]?.paragraph}</BlankState.Paragraph>
    </BlankState>
  )
}

DigitalPageBlankState.propTypes = {
  digitalTemplateTypeKey: PropTypes.string.isRequired,
  projectId: PropTypes.number.isRequired,
}

export default DigitalPageBlankState
