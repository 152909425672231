import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import useLocalization from '@hooks/useLocalization'
import styles from './styles.module.scss'

import EngageLogo from './assets/EngageLogo.svg'
import EngageLogoDark from './assets/EngageLogoDark.svg'
import localizedStrings from './localizedStrings'

const TemplateFooter = (props) => {
  const { dark, link, style } = props

  const strings = useLocalization(localizedStrings)

  return (
    <footer className={dark ? classnames(styles.root, styles.dark) : styles.root} style={style}>
      <span className={styles.madeBy}>{strings.built || 'Built With'}</span>
      <a href={link}>
        <img alt="Logo" className={styles.logo} height={20} src={dark ? EngageLogo : EngageLogoDark} />
      </a>
    </footer>
  )
}

TemplateFooter.propTypes = {
  dark: PropTypes.bool,
  link: PropTypes.string.isRequired,
  style: PropTypes.object,
}

export default TemplateFooter
