import { useEffect, useMemo } from 'react'

import { useModals, useSetState, useFilters } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'
import { Helmet } from 'react-helmet-async'

import PageContext from '@contexts/pageContext'
import handleCallbackAction from '@functions/handleCallbackAction'

import defaultRequestOptions from '@sections/Admin/packs/SystemManager/defaultRequestOptions'

import CreateOrEditTargetAudienceModal from '@modals/CreateOrEditTargetAudienceModal'

import useReduxAction from '@hooks/useReduxAction'

import useBrandSelector from '@hooks/useBrandSelector'
import PageContent from './components/PageContent'

const callbacks = (component, setState) => {
  const componentCallbacks = {
    CreateOrEditTargetAudienceModal: {
      closeModal: () => setState({ showCreateOrEditTargetAudienceModal: false }),
      createTargetAudience: payload => handleCallbackAction(payload),
      deleteTargetAudience: payload => handleCallbackAction(payload),
      updateTargetAudience: payload => handleCallbackAction(payload),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  showCreateOrEditTargetAudienceModal: false,
}
const TargetAudience = () => {
  const [state, setState] = useSetState(defaultState)
  const { showCreateOrEditTargetAudienceModal } = state

  const filterPayload = useFilters()

  const {
    callbacks: filterCallbacks,
    defaultFilters,
    pageFilters,
  } = filterPayload

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  useReduxAction(
    'targetAudiences',
    'loadTargetAudiences',
    {
      ...defaultRequestOptions.targetAudience,
      ...defaultRequestOptions.image,
      ...pageFilters,
    },
    [JSON.stringify(pageFilters)],
  )

  useReduxAction('brands', 'loadBrands', {})

  const { selectedBrand } = useBrandSelector()

  useEffect(() => {
    if (selectedBrand.length === 1){
      setState({ pageFilters: { brand_id: selectedBrand?.id } })
    }
  }, [selectedBrand.length])

  const pageContext = useMemo(() => ({
    callbacks: {
      ...filterCallbacks,
      showCreateOrEditTargetAudienceModal: (payload) => {
        setModalData('CreateOrEditTargetAudienceModal', payload)
        setState({ showCreateOrEditTargetAudienceModal: true })
      },
    },
    defaultFilters,
  }), [])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <Helmet>
          <title>Target Audiences | Engage</title>
        </Helmet>

        <PageContent pageFilters={pageFilters} />

        <CreateOrEditTargetAudienceModal
          callbacks={callbacks('CreateOrEditTargetAudienceModal', setState)}
          showModal={showCreateOrEditTargetAudienceModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}
export default TargetAudience
