import { ReactNode } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faSignOut } from '@fortawesome/pro-light-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'

import { Box, Link, Text } from '@campaignhub/suit-theme'

import handleCallbackAction from '@functions/handleCallbackAction'

import useUserImpersonation from '@hooks/useUserImpersonation'
import useOrganization from '@hooks/useOrganization'

export type LoginAsOrganizationPayloadType = {
  callbacks: {
    action: () => void,
    afterAction: (data: string) => void,
  },
}

interface SearchResultProps {
  icon: ReactNode,
  result: {
    canLogin: boolean,
    entityType: string,
    href: string,
    priority?: number,
    subtitle: string,
    title: string,
  },
}

const SearchResult = (props: SearchResultProps) => {
  const {
    icon, result: {
      canLogin, href, priority, subtitle, title,
    },
    result,
  } = props

  const organizationId = digObject(result, 'organizationId', null)

  const organizationPayload = useOrganization({})
  const {
    callbacks: { generateSupportToken },
  } = organizationPayload || {}

  const userImpersonationPayload = useUserImpersonation()
  const { callbacks: { impersonateUser } } = userImpersonationPayload

  const loginAsOrganizationPayload: LoginAsOrganizationPayloadType = {
    callbacks: {
      action: () => generateSupportToken(organizationId),
      afterAction: ({ response: { data } }) => impersonateUser(data),
    },
  }

  return (
    <li>
      <a href={href}>
        <Box
          alignItems="center"
          color="blue"
          fontSize="medium"
          justifyContent="center"
          marginRight="large"
          width={20}
        >
          <FontAwesomeIcon icon={icon || faHome} />
        </Box>

        <Box
          alignItems="flexStart"
          flexDirection="column"
          justifyContent="center"
          opacity={priority === 0 ? '0.5' : '1'}
        >
          <Text color="bodyFontColor" fontSize="small">
            {title}
          </Text>

          <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="medium">
            {subtitle}
          </Text>
        </Box>

        {canLogin && (
          <Box alignItems="center" width="auto" style={{ whiteSpace: 'nowrap' }}>
            <Link
              onClick={() => handleCallbackAction(loginAsOrganizationPayload)}
              marginRight="medium"
              textProps={{
                fontSize: 'xsmall', paddingLeft: 'small', marginTop: 'medium', width: 'fit-content',
              }}
            >
              <FontAwesomeIcon icon={faSignOut} /> &nbsp; Login
            </Link>
          </Box>
        )}
      </a>
    </li>
  )
}

export default SearchResult
