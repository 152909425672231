import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendarPlus, faCheckCircle, faPencil, faTrashAlt,
} from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, FormField, SidebarModal,
} from '@campaignhub/suit-theme'

import { useDocumentTemplateForm } from '@hooks/useDocumentTemplate'

import useLocalization from '@hooks/useLocalization'

import BuilderContext from '../../contexts/builderContext'

import DocumentRecipients from './components/DocumentRecipients'

import localizedStrings from './localizedStrings'

const confirmDelete = (deleteDocumentTemplate, deleteFn, strings) => {
  swal
    .fire({
      title: strings.swal?.title || 'Delete Document Template?',
      text: strings.swal?.text || 'Are you sure?',
      icon: strings.swal?.icon || 'warning',
      showCancelButton: true,
      confirmButtonText: strings.swal?.confirmButtonText || 'Yes, delete it',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        deleteDocumentTemplate(deleteFn)
      }
    })
}

const ManageDocumentTemplateBuilderModal = (props) => {
  const {
    callbacks,
    callbacks: { deleteDocumentTemplate, updateDocumentTemplate },
    modalKey,
    showModal,
  } = props

  const builderContext = useContext(BuilderContext)
  const {
    callbacks: {
      showAddDocumentTemplateFieldModal,
      showCreateOrEditDocumentRecipientModal,
    },
    documentTemplatePayload,
    documentTemplatePayload: {
      callbacks: { deleteDocumentTemplate: deleteFn, updateDocumentTemplate: updateFn },
      documentTemplate,
      hasPages,
    },
    isMobile,
  } = builderContext

  const documentTemplateFormPayload = useDocumentTemplateForm(documentTemplate)
  const {
    entityState,
    entityState: {
      id,
      production_ready,
      title,
    },
    handlers,
    saveEnabled,
    updating,
  } = documentTemplateFormPayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal
      callbacks={callbacks}
      disableAnimation
      disableOverlay
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      {isMobile && (
        <SidebarModal.Header
          callbacks={callbacks}
          title={strings.sideBarModalHeader?.title || 'Manage'}
          titleSecondLine={strings.sideBarModalHeader?.titleSecondLine || 'Document Template'}
        />
      )}

      <SidebarModal.Content boxProps={{ padding: 0 }}>
        <Box flexDirection="column">
          <Box borderBottom="1px solid" borderColor="lineColor" padding="large">
            <Button
              buttonStyle="primaryEdit"
              disabled={!hasPages}
              flexShrink="1"
              icon={<FontAwesomeIcon icon={faCalendarPlus} />}
              marginLeft="small"
              onClick={() => showAddDocumentTemplateFieldModal()}
              size="medium"
            >
              {strings.buttons?.add || 'Add Field'}
            </Button>
          </Box>
        </Box>

        <Box flexDirection="column" padding="large">
          <FormField direction="column" label={strings.formFieldLabels?.title || '* Title'}>
            <input name="title" type="text" value={title} {...handlers} />
          </FormField>

          <FormField
            direction="column"
            label={strings.formFieldLabels?.roles || '* Recipient/Signer Roles'}
            marginTop="large"
          >
            <DocumentRecipients
              callbacks={{ showCreateOrEditDocumentRecipientModal }}
              documentTemplatePayload={documentTemplatePayload}
            />
          </FormField>

          <FormField
            direction="column"
            label={strings.formFieldLabels?.moreOptions || 'More Options'}
            marginTop="large"
          >
            <Button
              buttonStyle="secondaryUtility"
              icon={<FontAwesomeIcon icon={production_ready ? faPencil : faCheckCircle} />}
              marginBottom="medium"
              onClick={() => updateDocumentTemplate({ id, production_ready: !production_ready }, updateFn)}
              size="medium"
              width="100%"
            >
              {production_ready
                ? strings?.button?.setDraft || 'Set As Draft'
                : strings?.button?.setPublished || 'Set As Published'}
            </Button>

            <Button
              buttonStyle="secondaryUtility"
              icon={<FontAwesomeIcon icon={faTrashAlt} />}
              onClick={() => confirmDelete(deleteDocumentTemplate, deleteFn, strings)}
              size="medium"
              width="100%"
            >
              {strings.buttons?.delete || 'Delete Template'}
            </Button>
          </FormField>
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={updating}
          onClick={() => updateDocumentTemplate(entityState, updateFn)}
          size="large"
        >
          {strings.buttons?.save || 'Save Template'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

ManageDocumentTemplateBuilderModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

ManageDocumentTemplateBuilderModal.defaultProps = {
  modalKey: 'ManageDocumentTemplateBuilderModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <ManageDocumentTemplateBuilderModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
