export default {
  default: {
    button: {
      view: 'View',
    },
    formFieldLabel: {
      responseFor: 'Response For',
      responseReceived: 'Response Received',
    },
    title: 'Form',
    titleSecondLine: 'Response',
  },
}
