import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'

import {
  Box, Button, FormField, ModalContext, SidebarModal, Text,
} from '@campaignhub/suit-theme'

import useAttachment, { useAttachmentForm } from '@hooks/useAttachment'
import useEntityCustomFieldsForm from '@hooks/useEntityCustomFieldsForm'
import useLocalization from '@hooks/useLocalization'
import useOrganizationSelector from '@hooks/useOrganizationSelector'

import EntityCustomFields from '@components/EntityCustomFields'

import localizedStrings from './localizedStrings'

const confirmDelete = (deleteCallback, deleteFn, strings) => {
  swal
    .fire({
      title: strings.swal?.title,
      text: strings.swal?.text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: strings.swal?.confirmButtonText,
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        deleteCallback(deleteFn)
      }
    })
}

const EditAttachmentModal = (props) => {
  const {
    callbacks,
    callbacks: { deleteAttachment, updateAttachment },
    modalKey,
    showModal,
  } = props

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const { selectedOrganization } = useOrganizationSelector()

  const { attachment, filterCustomFieldsBy } = digObject(modalData, modalKey, {})

  const {
    callbacks: { deleteAttachment: deleteFn, updateAttachment: updateFn },
    deleting,
    generating,
    updating,
    urls: { downloadUrl },
  } = useAttachment(attachment)

  const attachmentFormPayload = useAttachmentForm(attachment)
  const {
    entityState,
    entityState: {
      data: { description, file_name, title },
    },
    handlers,
    saveEnabled,
  } = attachmentFormPayload

  const customFieldsForm = useEntityCustomFieldsForm(
    { id: attachment.id, type: 'Attachment' },
    selectedOrganization,
    {
      hidden: !Object.values(filterCustomFieldsBy || {}).length,
      performHttpRequests: true,
    },
  )

  const {
    callbacks: { buildNestedValues },
  } = customFieldsForm

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.modalHeader?.title || 'Edit'}
        titleSecondLine={strings.modalHeader?.titleSecondLine || 'Attachment'}
      />

      <SidebarModal.Content>
        <FormField direction="column" label={strings.formFieldLabels?.attachmentTitle || 'Attachment Title'}>
          <input name="data.title" type="text" value={title} {...handlers} />
        </FormField>

        <FormField direction="column" label={strings.formFieldLabels?.attachment || 'Attachment'} marginTop="large">
          <Box padding="medium" variant="whiteGrey">
            <Text fontSize="small" variant="ellipsis">
              {file_name}
            </Text>
          </Box>
        </FormField>

        <FormField
          direction="column"
          label={strings.formFieldLabels?.attachmentDescription || 'Attachment Description'}
          marginTop="large"
        >
          <textarea name="data.description" style={{ height: 100 }} value={description} {...handlers} />
        </FormField>

        <EntityCustomFields customFieldsForm={customFieldsForm} sidebar />

        <FormField direction="column" label={strings.formFieldLabels?.moreOptions || 'More Options'} marginTop="large">
          {downloadUrl && (
            <Button
              as="a"
              buttonStyle="secondaryUtility"
              ghost
              icon={<FontAwesomeIcon icon={faDownload} />}
              loading={generating}
              marginBottom="medium"
              href={downloadUrl}
              size="medium"
            >
              {strings.buttonText?.download || 'Download'}
            </Button>
          )}

          <Button
            buttonStyle="secondaryUtility"
            ghost
            icon={<FontAwesomeIcon icon={faTrashAlt} />}
            loading={deleting}
            onClick={() => confirmDelete(deleteAttachment, deleteFn, strings)}
            size="medium"
          >
            {strings.buttonText?.delete || 'Delete'}
          </Button>
        </FormField>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={updating}
          loadingBubbleColor="white"
          onClick={() => updateAttachment(entityState, buildNestedValues(), updateFn)}
          size="large"
        >
          {strings.buttonText?.save || 'Save'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

EditAttachmentModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

EditAttachmentModal.defaultProps = {
  modalKey: 'EditAttachmentModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <EditAttachmentModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
