export default {
  default: {
    buttons: {
      removePlatform: 'Remove Platform',
      save: 'Save Platform',
    },
    formFieldLabels: {
      id: '* ID',
      moreOptions: 'More Options',
      platform: '* Platform',
    },
    pleaseSelect: 'Please Select...',
  },
}
