export type IntegrationModel = {
  cache_key: number,
  id: number,
  access_secret: string,
  access_token: string,
  data: {},
  dates: {
    created: {
      date_time_with_timezone: string,
    },
    expires:{
      date_time_with_timezone: string,
    },
    updated: {
      date_time_with_timezone: string,
    },
  },
  integration_platform_id: number,
  integration_scope: string,
  options: {},
  owner_id: number,
  owner_type: string,
  subject_id: number,
  subject_type: string,
}

export type IntegrationRequestOptions = {}

const state = {
  data: {},
  id: null,
  integration_platform_id: null,
  integration_scope: '',
  options: {},
  owner_id: null,
  owner_type: '',
  subject_id: null,
  subject_type: '',
}

export const requiredFields = [
  { key: 'integration_platform_id' },
  { key: 'owner_id' },
  { key: 'owner_type' },
  {
    key: 'subject_id',
    validation: (
      value: string,
      entityState: IntegrationModel,
    ) => (entityState.integration_scope === 'user' ? !!value : true),
  },
]

export default state
