import { digObject } from '@campaignhub/javascript-utils'

import { snakeCaseToCamelCase } from '@functions/string'

const digStringMatch = /\{(.[^||}]*)/
const changesMatch = /\|(.[^|]*)/

const globalEntityStringMatch = /\{global.(.[^||}]*)/

export const replaceGlobalString = (string, globalStrings, entities) => {
  // returns 'organization.title' from '{global.organization.title|l|}'
  const entityString = string.match(globalEntityStringMatch)[1]
  if (!entityString) return ''

  const [entityType, ...fields] = entityString.split('.')
  const entityStrings = globalStrings[entityType]

  const selectedEntity = entities[entityType] || {}
  const key = snakeCaseToCamelCase(selectedEntity.key)

  let globalValue = digObject(entityStrings, `${key}.${fields.join('.')}`, string)

  // returns ['l'] from '{global.organization.title|l|}'
  const changes = string.match(changesMatch)

  if (!changes || globalValue.match(globalEntityStringMatch)) return globalValue
  if (changes[0].includes('l')) globalValue = globalValue.toLowerCase()
  if (changes[0].includes('u')) globalValue = globalValue.toUpperCase()

  return globalValue
}

export const replaceString = (string, customValues) => {
  // returns 'digitalTemplateId' 'from {digitalTemplateId}'
  const key = string.match(digStringMatch)[1]
  const customValue = digObject(customValues, key, '')

  if (!Object.keys(customValues).includes(key)) return string

  return customValue
}
