import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'
import { toast } from 'react-toastify'

import { MainContent, ModalContext } from '@campaignhub/suit-theme'
import { useModals, useSetState, useShowModal } from '@campaignhub/react-hooks'
import { digObject, getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'

import openModalAndCloseAllOthers from '@functions/openModalAndCloseAllOthers'

import handleCallbackAction from '@functions/handleCallbackAction'

import CreateOrEditAddressModal from '@modals/CreateOrEditAddressModal'
import CreateOrEditComparableModal from '@modals/CreateOrEditComparableModal'
import CreateOrEditContactModal from '@modals/CreateOrEditContactModal'
import CreateOrEditDocumentRecipientModal from '@modals/CreateOrEditDocumentRecipientModal'
import CreateQuoteModal from '@modals/CreateQuoteModal'
import EditImageModal from '@modals/EditImageModal'
import EditImagesModal from '@modals/EditImagesModal'
import EditUserModal from '@modals/EditUserModal'
import FindComparablesModal from '@modals/FindComparablesModal'
import ManageComparablesModal from '@modals/ManageComparablesModal'
import ManageDocumentRecipientsModal from '@modals/ManageDocumentRecipientsModal'
import ManageEntityQuotesModal from '@modals/ManageEntityQuotesModal'

import { useRelations } from '@hooks/useAgreement'
import useIntercom from '@hooks/useIntercom'
import useLocalization from '@hooks/useLocalization'

import useAgreementBuilder, { defaultRequestOptions } from './hooks/useAgreementBuilder'

import BuildAgreementContext from './contexts/buildAgreementContext'

import BuildingAgreementModal from './modals/BuildingAgreementModal'
import EditAgreementModal from './modals/EditAgreementModal'
import ManageAgreementBuilderModal from './modals/ManageAgreementBuilderModal'

import BuildAgreementContextProvider from './components/BuildAgreementContextProvider'
import Canvas from './components/Canvas'
import Header from './components/Header'
import PageContextProvider from './components/PageContextProvider'

import localizedStrings from './localizedStrings'

const modalKeys = [
  'BuildingAgreementModal',
  'CreateOrEditAddressModal',
  'CreateOrEditComparableModal',
  'CreateOrEditContactModal',
  'CreateOrEditDocumentRecipientModal',
  'CreateQuoteModal',
  'EditAgreementModal',
  'EditUserModal',
  'FindComparablesModal',
  'ManageAgreementBuilderModal',
  'ManageComparablesModal',
  'ManageDocumentRecipientsModal',
  'ManageEntityQuotesModal',
]

const updateAgreement = (agreementParams, updateFn, setState, strings) => {
  updateFn(agreementParams, defaultRequestOptions.agreement).then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    toast(strings.toasts?.agreement?.updated || 'Agreement Updated.')

    openModalAndCloseAllOthers('ManageAgreementBuilderModal', modalKeys, setState)
  })
}

const deleteAgreement = (deleteFn, setState, strings) => {
  deleteFn().then(({ success, errors, redirectUrl }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    if (redirectUrl){
      window.location.href = redirectUrl
    }

    setState({ showEditAgreementModal: false })

    toast(strings.toasts?.agreement?.deleted || 'Agreement Deleted.')
  })
}

const sendExternalAgreement = (sendFn, strings) => {
  sendFn('AgreementProgressModal').then(({ success, errors, redirectUrl }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    if (redirectUrl){
      window.location.href = redirectUrl
    }

    toast.success(strings.toasts?.agreement?.sent || 'Agreement Sent for Signing.')
  })
}

const deleteExternalAgreement = (deleteFn, strings) => {
  deleteFn().then((result) => {
    const { errors, success } = result
    if (!success){
      toast.warn(errors[0])
      return
    }

    toast(strings.toasts?.agreement?.updated || 'Agreement Updated.')
  })
}

const createDocumentRecipient = (documentRecipient, createFn, loadShortcodeDataFn, setState, strings) => {
  createFn(documentRecipient).then((result) => {
    const { errors, success } = result
    if (!success){
      toast.warn(errors[0])
      return
    }

    toast(strings.toasts?.recipient?.created || 'Recipient Created.')

    setState({ showCreateOrEditDocumentRecipientModal: false })

    loadShortcodeDataFn()
  })
}

const deleteDocumentRecipient = (deleteFn, setState, strings) => {
  deleteFn().then((result) => {
    const { errors, success } = result
    if (!success){
      toast.warn(errors[0])
      return
    }

    toast(strings.toasts?.recipient?.deleted || 'Recipient Deleted.')

    setState({ showManageDocumentRecipientsModal: false })
  })
}

const updateDocumentRecipient = (recipientParams, updateFn, loadShortcodeDataFn, setState, strings) => {
  updateFn(recipientParams).then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    toast(strings.toasts?.recipient?.updated || 'Recipient Updated.')

    setState({ showCreateOrEditDocumentRecipientModal: false })

    loadShortcodeDataFn()
  })
}

const updateRecipientSortOrder = (sortedRecipientIds, updateFn, loadShortcodeDataFn, setState, strings) => {
  updateFn(sortedRecipientIds).then(({ success, errors }) => {
    if (!success && errors){
      toast.warn(errors[0])
      return
    }

    if (loadShortcodeDataFn) loadShortcodeDataFn()

    toast(strings.toasts?.agreement?.sortOrder || 'Sort Order has been updated.')

    setState({ showManageDocumentRecipientsModal: false })
  })
}

const updateUser = (payload, loadShortcodeDataFn, handleCallbackActionFn) => {
  handleCallbackActionFn(payload)
  loadShortcodeDataFn()
}

const updateComparableSortOrder = (sortOrder, updateFn, loadShortcodeDataFn, setState, strings) => {
  updateFn(sortOrder, defaultRequestOptions.comparable).then(({ success, errors }) => {
    if (!success && errors){
      toast.warn(errors[0])
      return
    }

    toast(strings.toasts?.comparable?.sortUpdated || 'Comparable Sort Updated.')

    setState({ showManageComparablesModal: false })

    loadShortcodeDataFn()
  })
}

const afterImportExternalQuoteIds = (agreement, response, updateAgreementFn) => {
  const { data } = response

  const {
    entity: { id: quoteId },
  } = data

  const existingQuoteIds = digObject(agreement, 'quote_ids', [])
  if (!existingQuoteIds.includes(quoteId)){
    existingQuoteIds.push(quoteId)
  }

  updateAgreementFn({ quote_ids: existingQuoteIds }, defaultRequestOptions.agreement).then(
    ({ success, errors }) => {
      if (!success){
        toast.warn(errors[0])
      }
    },
  )
}

const callbacks = (component, setState, stringsPayload, customPayload = {}) => {
  const { strings } = stringsPayload

  const { agreement, loadShortcodeDataFn, updateAgreementFn } = customPayload

  const componentCallbacks = {
    CreateOrEditAddressModal: {
      closeModal: () => setState({ showCreateOrEditAddressModal: false }),
      createAddress: payload => handleCallbackAction(payload),
      updateAddress: payload => handleCallbackAction(payload),
      loadShortcodeData: () => loadShortcodeDataFn,
    },
    CreateOrEditComparableModal: {
      closeModal: () => setState({ showCreateOrEditComparableModal: false, showManageAgreementBuilderModal: true }),
      createComparable: payload => handleCallbackAction({ ...payload, requestOptions: defaultRequestOptions.comparable }),
      deleteComparable: payload => handleCallbackAction(payload),
      updateComparable: payload => handleCallbackAction({ ...payload, requestOptions: defaultRequestOptions.comparable }),
      loadShortcodeData: () => loadShortcodeDataFn,
    },
    CreateOrEditContactModal: {
      closeModal: () => setState({ showCreateOrEditContactModal: false }),
      createContact: payload => handleCallbackAction({ ...payload, requestOptions: defaultRequestOptions.contact }),
      deleteContact: payload => handleCallbackAction({ ...payload, requestOptions: defaultRequestOptions.contact }),
      updateContact: payload => handleCallbackAction({ ...payload, requestOptions: defaultRequestOptions.contact }),
      loadShortcodeData: () => loadShortcodeDataFn,
    },
    CreateQuoteModal: {
      closeModal: () => setState({ showCreateQuoteModal: false, showManageDigitalPageFeaturesModal: true }),
      createQuoteFromTemplate: payload => handleCallbackAction(payload),
      createQuote: payload => handleCallbackAction(payload),
      importExternalQuoteId: payload => handleCallbackAction({ ...payload, requestOptions: defaultRequestOptions.quote }),
      afterImportExternalQuoteIds: response => afterImportExternalQuoteIds(agreement, response, updateAgreementFn),
    },
    EditAgreementModal: {
      closeModal: () => setState({ showEditAgreementModal: false }),
      deleteAgreement: deleteFn => deleteAgreement(deleteFn, setState, strings),
      updateAgreement: (params, updateFn) => updateAgreement(params, updateFn, setState, strings),
    },
    EditImageModal: {
      closeModal: () => setState({ showEditImageModal: false }),
      deleteImage: payload => handleCallbackAction(payload),
    },
    EditImagesModal: {
      closeModal: () => setState({ showEditImagesModal: false }),
    },
    EditUserModal: {
      closeModal: () => setState({ showEditUserModal: false }),
      updateUser: payload => updateUser(payload, loadShortcodeDataFn, handleCallbackAction),
    },
    CreateOrEditDocumentRecipientModal: {
      closeModal: () => setState({ showCreateOrEditDocumentRecipientModal: false }),
      createDocumentRecipient: (params, createFn) => createDocumentRecipient(params, createFn, loadShortcodeDataFn, setState, strings),
      updateDocumentRecipient: (params, updateFn) => updateDocumentRecipient(params, updateFn, loadShortcodeDataFn, setState, strings),
    },
    FindComparablesModal: {
      closeModal: () => setState({ showFindComparablesModal: false, showManageAgreementBuilderModal: true }),
    },
    ManageAgreementBuilderModal: {
      closeModal: () => setState({ showManageAgreementBuilderModal: false }),
      deleteExternalAgreement: deleteFn => deleteExternalAgreement(deleteFn, strings),
      sendExternalAgreement: sendFn => sendExternalAgreement(sendFn, strings),
    },
    ManageComparablesModal: {
      closeModal: () => setState({ showManageComparablesModal: false, showManageAgreementBuilderModal: true }),
      updateComparableSortOrder: (sortOrder, updateFn) => updateComparableSortOrder(sortOrder, updateFn, loadShortcodeDataFn, setState, strings),
    },
    ManageDocumentRecipientsModal: {
      closeModal: () => setState({ showManageDocumentRecipientsModal: false }),
      deleteDocumentRecipient: deleteFn => deleteDocumentRecipient(deleteFn, loadShortcodeDataFn, setState, strings),
      updateRecipientSortOrder: (sortedRecipientIds, updateFn) => updateRecipientSortOrder(sortedRecipientIds, updateFn, loadShortcodeDataFn, setState, strings),
    },
    ManageEntityQuotesModal: {
      closeModal: () => setState({ showManageEntityQuotesModal: false, showManageDigitalPageFeaturesModal: true }),
      showCreateQuotesModal: () => setState({ showCreateQuoteModal: true }),
      updateEntity: (params, updateFn) => updateAgreement(params, updateFn, setState, strings),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  showCreateOrEditAddressModal: false,
  showCreateOrEditComparableModal: false,
  showCreateOrEditContactModal: false,
  showCreateOrEditDocumentRecipientModal: false,
  showCreateQuoteModal: false,
  showEditAgreementModal: false,
  showEditImageModal: false,
  showEditImagesModal: false,
  showEditUserModal: false,
  showFindComparablesModal: false,
  showManageAgreementBuilderModal: false,
  showManageComparablesModal: false,
  showManageDocumentRecipientsModal: false,
  showManageEntityQuotesModal: false,
}

const AgreementBuilder = (props) => {
  const { agreementId } = props

  const [state, setState] = useSetState(defaultState)
  const {
    showCreateOrEditAddressModal,
    showCreateOrEditComparableModal,
    showCreateOrEditContactModal,
    showCreateOrEditDocumentRecipientModal,
    showCreateQuoteModal,
    showEditAgreementModal,
    showEditImageModal,
    showEditImagesModal,
    showEditUserModal,
    showFindComparablesModal,
    showManageAgreementBuilderModal,
    showManageComparablesModal,
    showManageDocumentRecipientsModal,
    showManageEntityQuotesModal,
  } = state

  const modalContext = useModals()
  const {
    callbacks: { registerModal, setModalData },
  } = modalContext

  const agreementBuilderPayload = useAgreementBuilder({
    agreementId,
    callbacks: {
      showCreateOrEditAddressModal: (payload) => {
        setModalData('CreateOrEditAddressModal', payload)
        setState({ showCreateOrEditAddressModal: true })
      },
      showCreateOrEditComparableModal: (payload) => {
        setModalData('CreateOrEditComparableModal', payload)
        setState({ showCreateOrEditComparableModal: true })
      },
      showCreateOrEditContactModal: (payload) => {
        setModalData('CreateOrEditContactModal', payload)
        setState({ showCreateOrEditContactModal: true })
      },
      showEditAgreementModal: (payload) => {
        setModalData('EditAgreementModal', payload)
        setState({ showEditAgreementModal: true })
      },
      showEditImageModal: (payload) => {
        setModalData('EditImageModal', payload)
        setState({ showEditImageModal: true })
      },
      showEditImagesModal: (payload) => {
        setModalData('EditImagesModal', payload)
        setState({ showEditImagesModal: true })
      },
      showEditUserModal: (payload) => {
        setModalData('EditUserModal', payload)
        setState({ showEditUserModal: true })
      },
      showCreateOrEditDocumentRecipientModal: (payload) => {
        setModalData('CreateOrEditDocumentRecipientModal', payload)
        setState({ showCreateOrEditDocumentRecipientModal: true })
      },
      showFindComparablesModal: (payload) => {
        setModalData('FindComparablesModal', payload)
        setState({ showFindComparablesModal: true })
      },
      showManageAgreementBuilderModal: (payload) => {
        setModalData('ManageAgreementBuilderModal', payload)
        setState({ showManageAgreementBuilderModal: true })
      },
      showManageComparablesModal: (payload) => {
        setModalData('ManageComparablesModal', payload)
        setState({ showManageComparablesModal: true })
      },
      showManageDocumentRecipientsModal: (payload) => {
        setModalData('ManageDocumentRecipientsModal', payload)
        setState({ showManageDocumentRecipientsModal: true })
      },
      showManageEntityQuotesModal: (payload) => {
        setModalData('ManageEntityQuotesModal', payload)
        setState({ showManageEntityQuotesModal: true })
      },
    },
  })

  const {
    agreementPayload: {
      agreement,
      callbacks: { loadAgreementShortcodeData: loadShortcodeDataFn, updateAgreement: updateAgreementFn },
    },
    isMobile,
  } = agreementBuilderPayload

  const { project } = useRelations(agreement)

  useEffect(() => {
    // Wait for the register function otherwise the modal
    // doesn't get added to the open modals array
    if (!isMobile && registerModal){
      setState({
        showManageAgreementBuilderModal: true,
      })
    }
  }, [isMobile])

  const { showModal } = parsePermittedQueryParams(getQueryParamsFromHash(), ['showModal'])

  // Automatically show modal
  useShowModal({
    modalKey: showModal,
    options: {
      callbacks: agreementBuilderPayload.callbacks,
    },
  })

  const stringsPayload = useLocalization(localizedStrings)
  const { strings } = stringsPayload

  useIntercom({ horizontalPadding: 400 })

  return (
    <div id="main-stage" style={{ position: 'relative' }}>
      <Helmet>
        <title>{strings.title || 'Agreement Builder | Engage'}</title>
      </Helmet>

      <BuildAgreementContextProvider>
        <PageContextProvider value={agreementBuilderPayload}>
          <ModalContext.Provider value={modalContext}>
            <MainContent
              offset={{ left: 0, top: 71 }}
              style={{ minHeight: 'calc(100vh - 71px)' }}
              width={!isMobile ? 'calc(100% - 375px)' : '100%'}
            >
              <Header agreementBuilderPayload={agreementBuilderPayload} />

              <Canvas />
            </MainContent>

            <BuildingAgreementModal context={BuildAgreementContext} />

            <CreateOrEditAddressModal
              callbacks={callbacks('CreateOrEditAddressModal', setState, stringsPayload, { loadShortcodeDataFn })}
              disableAnimation
              disableOverlay
              hiddenFields={['country', 'title']}
              showModal={showCreateOrEditAddressModal}
            />

            <CreateOrEditComparableModal
              callbacks={callbacks('CreateOrEditComparableModal', setState, stringsPayload, { loadShortcodeDataFn })}
              showModal={showCreateOrEditComparableModal}
            />

            <CreateOrEditContactModal
              callbacks={callbacks('CreateOrEditContactModal', setState, stringsPayload, { loadShortcodeDataFn })}
              disableDelete
              showModal={showCreateOrEditContactModal}
            />

            <CreateOrEditDocumentRecipientModal
              callbacks={callbacks('CreateOrEditDocumentRecipientModal', setState, stringsPayload, { loadShortcodeDataFn })}
              documentRecipient={{ owner_id: agreementId, owner_type: 'Agreement' }}
              showModal={showCreateOrEditDocumentRecipientModal}
            />

            <CreateQuoteModal
              callbacks={callbacks('CreateQuoteModal', setState, stringsPayload, { agreement, updateAgreementFn })}
              disableAnimation
              disableOverlay
              project={project}
              showModal={showCreateQuoteModal}
            />

            <EditAgreementModal
              callbacks={callbacks('EditAgreementModal', setState, stringsPayload)}
              showModal={showEditAgreementModal}
            />

            <EditImageModal
              callbacks={callbacks('EditImageModal', setState, stringsPayload)}
              showModal={showEditImageModal}
            />

            <EditImagesModal
              callbacks={callbacks('EditImagesModal', setState)}
              showModal={showEditImagesModal}
            />

            <EditUserModal
              callbacks={callbacks('EditUserModal', setState, stringsPayload, { loadShortcodeDataFn })}
              disableAnimation
              showModal={showEditUserModal}
            />

            <FindComparablesModal
              callbacks={callbacks('FindComparablesModal', setState, stringsPayload)}
              disableAnimation
              projectId={project.id}
              showModal={showFindComparablesModal}
            />

            <ManageAgreementBuilderModal
              callbacks={callbacks('ManageAgreementBuilderModal', setState, stringsPayload)}
              showModal={showManageAgreementBuilderModal}
            />

            <ManageEntityQuotesModal
              callbacks={callbacks('ManageEntityQuotesModal', setState, stringsPayload)}
              disableAnimation
              disableOverlay
              showModal={showManageEntityQuotesModal}
              subjectId={project.id}
              subjectType="Project"
            />

            <ManageComparablesModal
              callbacks={callbacks('ManageComparablesModal', setState, stringsPayload, { loadShortcodeDataFn })}
              disableAnimation
              disableOverlay
              project={project}
              showModal={showManageComparablesModal}
              status="sold"
            />

            <ManageDocumentRecipientsModal
              callbacks={callbacks('ManageDocumentRecipientsModal', setState, stringsPayload, { loadShortcodeDataFn })}
              disableOverlay
              ownerId={agreementId}
              ownerType="Agreement"
              showModal={showManageDocumentRecipientsModal}
            />
          </ModalContext.Provider>
        </PageContextProvider>
      </BuildAgreementContextProvider>
    </div>
  )
}

AgreementBuilder.propTypes = {
  agreementId: PropTypes.number.isRequired,
}

export default AgreementBuilder
