import React from 'react'
import PropTypes from 'prop-types'

import {
  FormField, SidebarModal,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useImage from '@hooks/useImage'

import ModalImageUpload from '@components/ModalComponents/ModalImageUpload'

const TemplateImage = (props) => {
  const {
    image, imageLabel, imageType, ownerId, ownerType, subjectId, subjectType,
  } = props

  const {
    callbacks: { deleteImage },
    deleting,
  } = useImage(image)

  const imageUrl = digObject(image, 'image_url')

  return (
    <SidebarModal.ExpandableSectionBox label={imageLabel}>
      <ModalImageUpload
        imageLabel={imageLabel}
        imageSizes={['thumb_660x360']}
        imageType={imageType}
        imageUrl={imageUrl}
        ownerId={ownerId}
        ownerType={ownerType}
        subjectId={subjectId}
        subjectType={subjectType}
      />

      {!!imageUrl && (
        <FormField marginTop="large">
          <ModalImageUpload.ReplaceImage loading={deleting} onClick={deleteImage} />
        </FormField>
      )}
    </SidebarModal.ExpandableSectionBox>
  )
}

TemplateImage.propTypes = {
  image: PropTypes.object,
  imageLabel: PropTypes.string.isRequired,
  imageType: PropTypes.string.isRequired,
  ownerId: PropTypes.number.isRequired,
  ownerType: PropTypes.string.isRequired,
  subjectId: PropTypes.number.isRequired,
  subjectType: PropTypes.string.isRequired,
}

export default TemplateImage
