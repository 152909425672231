export default {
  default: {
    toast: {
      deleted: 'Page item deleted successfully.',
      sectionAdded: 'Section added to template successfully.',
      sorted: 'Sections sorted successfully.',
      updated: 'Template updated successfully.',
    },
  },
}
