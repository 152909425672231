import React from 'react'
import PropTypes from 'prop-types'

import { Button, FormField, SidebarModal } from '@campaignhub/suit-theme'

const Footer = (props) => {
  const {
    digitalTemplateFormPayload: {
      callbacks: { updateDigitalTemplate },
      entityState,
      entityState: { options },
      handlers,
      updating,
    },
  } = props

  const {
    footer_link,
    footer_theme,
  } = options || {}

  return (
    <SidebarModal.ExpandableSectionBox label="Footer">
      <FormField boxProps={{ marginBottom: 'large' }} label="Footer Theme">
        <select name="options.footer_theme" value={footer_theme} {...handlers}>
          <option value="">Default</option>
          <option value="dark">Dark</option>
          <option value="light">Light</option>
        </select>
      </FormField>

      <FormField boxProps={{ marginBottom: 'large' }} label="Link">
        <input name="options.footer_link" type="text" value={footer_link} {...handlers} />
      </FormField>

      <Button
        buttonStyle="ghostCreate"
        loading={updating}
        onClick={() => updateDigitalTemplate(entityState)}
        size="medium"
      >
        Save Footer Settings
      </Button>
    </SidebarModal.ExpandableSectionBox>
  )
}

Footer.propTypes = {
  digitalTemplateFormPayload: PropTypes.shape({
    callbacks: PropTypes.shape({
      updateDigitalTemplate: PropTypes.func.isRequired,
    }),
    entityState: PropTypes.object,
    handlers: PropTypes.object,
    updating: PropTypes.bool,
  }).isRequired,
}

export default Footer
