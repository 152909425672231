export type DigitalPageModel = {
id: number,
title: string,
status_id: number,
}

const state = {
  id: null,
  title: '',
  status_id: null,
}

export const requiredFields = [
  { key: 'title' },
]

export default state
