import { useContext, useMemo } from 'react'
import cloneDeep from 'lodash/cloneDeep'

import { useForm, useLatestEntity, useWatchEntityUpdates } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import {
  digObject, launchModal, multiFilteredObjectArraySelector, sortArrayBy,
} from '@campaignhub/javascript-utils'

import useDispatch from '@hooks/useDispatch'
import useSelector from '@hooks/useSelector'

import * as caseStudyActions from '@redux/modules/caseStudy'

import defaultFormState, { requiredFields } from '@models/caseStudy'

import PageContext from '@contexts/pageContext'

import type { AppDispatch } from '@redux/store'
import type { DeleteParams } from '@redux/modules/types'
import type { CaseStudyModel, CaseStudyRequestOptions } from '@models/types'
import { CustomFieldModel } from '@models/customField'

const watchEntityKeys = ['images']

const generateUrls = () => ({
  caseStudiesIndexUrl: '#/admin/caseStudies?hidden=false',
  hiddenCaseStudiesIndexUrl: '#/admin/caseStudies?hidden=true',
})

type CreateCaseStudyParams = {
  payload:{
    caseStudyParams: Partial<CaseStudyModel>,
    customFields: Partial<CustomFieldModel>,
  },
  dispatch: AppDispatch,
  requestOptions?: CaseStudyRequestOptions,
}

const createCaseStudy = (params: CreateCaseStudyParams) => {
  const { dispatch, payload, requestOptions } = params
  const { caseStudyParams, customFields } = payload

  const { createCaseStudy: createFn } = caseStudyActions

  const { data } = caseStudyParams
  const newCaseStudy = {
    ...caseStudyParams,
    data: JSON.stringify(data),
  }

  return dispatch(createFn(newCaseStudy, customFields, requestOptions))
}

type UpdateCaseStudyParams = {
  caseStudy: CaseStudyModel,
  dispatch: AppDispatch,
  payload:{
    caseStudyParams: Partial<CaseStudyModel>,
    customFields: Partial<CustomFieldModel>,
  },
  requestOptions?: CaseStudyRequestOptions,
}

const updateCaseStudy = (params: UpdateCaseStudyParams) => {
  const {
    dispatch, caseStudy, payload, requestOptions,
  } = params
  const { caseStudyParams, customFields } = payload
  const { data } = caseStudyParams

  const { updateCaseStudy: updateFn } = caseStudyActions

  const updatedParams = {
    id: caseStudy.id,
    ...caseStudyParams,
  }

  if (data){
    const caseStudyData = caseStudy.data || {}
    updatedParams.data = JSON.stringify({
      ...cloneDeep(caseStudyData),
      ...data,
    })
  }

  return dispatch(updateFn(updatedParams, customFields, requestOptions))
}

type DeleteCaseStudyParams = {
  caseStudy: DeleteParams<CaseStudyModel>,
  dispatch: AppDispatch,
}

const deleteCaseStudy = (params: DeleteCaseStudyParams) => {
  const { dispatch, caseStudy } = params
  const { deleteCaseStudy: deleteFn } = caseStudyActions

  return dispatch(deleteFn(caseStudy))
}

type DeleteFromProjectsParams = {
  caseStudy: DeleteParams<CaseStudyModel>,
  dispatch: AppDispatch,
}

const deleteFromProjects = (params: DeleteFromProjectsParams) => {
  const { dispatch, caseStudy } = params
  const { deleteFromProjects: deleteFromProjectsFn } = caseStudyActions

  return dispatch(deleteFromProjectsFn(caseStudy))
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useCaseStudyForm(
  caseStudy: Partial<CaseStudyModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const caseStudyForm = useForm(
    defaultFormState,
    { entity: caseStudy, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [caseStudy.id, caseStudy.cache_key],
  )

  return {
    ...caseStudyForm,
  }
}

export const useRelations = (caseStudy: Partial<CaseStudyModel> = {}) => {
  const {
    address_id,
    subject_id,
    subject_type,
  } = caseStudy

  const userIds = digObject(caseStudy, 'user_ids', [])

  const {
    addresses,
    projects,
    users,
  } = useSelector(reduxState => reduxState.entities)

  const caseStudyUsers = userIds.map(id => users[id] || {})
  const project = subject_type === 'Project' && subject_id ? projects[subject_id] || {} : {}
  const caseStudyAddress = address_id ? addresses[address_id] || {} : {}
  const projectAddress = project.address_id ? addresses[project.address_id] || {} : {}
  const address = caseStudyAddress.id ? caseStudyAddress : projectAddress

  return {
    address,
    caseStudyAddress,
    caseStudyUsers,
    project,
    projectAddress,
  }
}

function useCaseStudy(initEntity: Partial<CaseStudyModel> = {}) {
  const { entity: caseStudy }: { entity: CaseStudyModel} = useLatestEntity(initEntity, 'caseStudies')

  const {
    updatedEntities: {
      images: imagesUpdatedAt,
    },
  } = useWatchEntityUpdates(watchEntityKeys)

  const dispatch = useDispatch()

  const { callbacks } = useContext(PageContext)

  const {
    address,
    caseStudyUsers,
    project,
  } = useRelations(caseStudy)

  const {
    entities,
    caseStudies: { creating, loading, updating },
  } = useSelector(reduxState => reduxState)

  const imageFilters = [
    { key: 'subject_id', value: caseStudy.id },
    { key: 'subject_type', value: 'CaseStudy' },
  ]

  const filteredImages = useMemo(() => {
    const filtered = multiFilteredObjectArraySelector({ entities }, 'images', imageFilters)
    const sorted = sortArrayBy(filtered, 'asc', 'sort')

    return sorted
  }, [imagesUpdatedAt, caseStudy.id])

  const defaultImage = filteredImages[0] || {}

  const hasProject = !!Object.keys(project).length

  return {
    address,
    callbacks: {
      createCaseStudy: (
        payload:{
          caseStudyParams: Partial<CaseStudyModel>,
          customFields: Partial<CustomFieldModel>,
        },
        entityOptions?: CaseStudyRequestOptions,
      ) => (
        createCaseStudy({ payload, dispatch, requestOptions: entityOptions })
      ),
      createOrEditCaseStudy: (customPayload: {}) => launchModal({
        callbacks,
        modalKey: 'CreateOrEditCaseStudyModal',
        payload: { caseStudy, ...customPayload },
      }),
      deleteCaseStudy: () => deleteCaseStudy({ dispatch, caseStudy }),
      deleteFromProjects: () => deleteFromProjects({ dispatch, caseStudy }),
      updateCaseStudy: (
        payload:{
          caseStudyParams: Partial<CaseStudyModel>,
          customFields: Partial<CustomFieldModel>,
        },
        entityOptions?: CaseStudyRequestOptions,
      ) => (
        updateCaseStudy({
          caseStudy, payload, dispatch, requestOptions: entityOptions,
        })
      ),
    },
    caseStudy,
    caseStudyUsers,
    creating,
    defaultImage,
    filteredImages,
    hasProject,
    loading,
    project,
    updating,
    urls: generateUrls(),
  }
}

export default useCaseStudy
