import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers } from '@fortawesome/pro-light-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'

import {
  Box, Image, ListItem, Text,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'
import useTeam from '@hooks/useTeam'

import localizedStrings from './localizedStrings'
import placeholderImage from './assets/teams-placeholder.png'

const Team = (props) => {
  const { team } = props
  const { title } = team

  const { defaultImage, teamMembers } = useTeam(team)

  const imageUrl = digObject(defaultImage, 'sizes.thumb_200x120', '')
  const teamMemberCount = teamMembers.length

  const {
    callbacks: { formatCount },
  } = useLocalization(localizedStrings)

  return (
    <ListItem boxProps={{ borderBottom: '1px solid', padding: 'large' }} disableHover forceBottomBorder>
      <Box width={110} marginRight="large">
        <Image
          borderRadius={5}
          height={60}
          marginRight="medium"
          placeholderUrl={placeholderImage}
          url={imageUrl}
          width={100}
        />
      </Box>

      <Box flexDirection="column" justifyContent="center" minWidth="0">
        <Text fontSize="small">{title}</Text>

        <Text color="lightGrey" fontSize="xsmall" paddingTop="medium" variant="ellipsis">
          <Box alignItems="center">
            <FontAwesomeIcon icon={faUsers} style={{ marginRight: 5 }} />
            {formatCount('teamMemberCount', { count: teamMemberCount })}
          </Box>
        </Text>
      </Box>
    </ListItem>
  )
}

Team.propTypes = {
  team: PropTypes.object.isRequired,
}

export default Team
