import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { digObject, toggleArray } from '@campaignhub/javascript-utils'

import { useSetState } from '@campaignhub/react-hooks'

import useComparables from '@hooks/useComparables'
import { useRelations } from '@hooks/useProject'

const toggleLocation = (location, state, setState) => {
  const { digitalPageLocations } = state

  const updatedLocations = toggleArray(digitalPageLocations, location, { deepCompare: true })

  setState({ digitalPageLocations: updatedLocations })
}

const getMapLocationSources = templateBuilderLocations => templateBuilderLocations.map((location => location.source))

const getComparablesLocations = (addresses, filteredComparables) => {
  const comparableAddressIDs = Object.values(filteredComparables).map((comparable => comparable.address_id))

  const comparablesAddress = Object.values(addresses).filter(
    address => comparableAddressIDs.includes(address.id) && address.owner_type === 'ProjectComparable',
  )

  return comparablesAddress.map(address => ({ ...address, source: 'project_comparables' }))
}

const getProjectLocations = (addresses, project) => {
  const projectAddress = [addresses[project?.address_id]]

  return projectAddress.map(address => ({ ...address, source: 'project' }))
}

const getOrganizationsLocations = (addresses, organization) => {
  const organizationAddressesArray = Object.values(addresses)
    .filter(address => organization?.addresses?.includes(address.id) && address.owner_type === 'Organization')

  return organizationAddressesArray.map(address => ({ ...address, source: 'organization' }))
}

const defaultState = {
  comparablesLocations: [],
  digitalPageLocations: [],
}

function useDigitalPageMap(options = {}){
  const {
    digitalPagePayload,
    image: markerImage,
    pageItemData,
    templatePageItemPayload,
  } = options || {}

  const [state, setState] = useSetState(defaultState)
  const {
    comparablesLocations, digitalPageLocations,
  } = state

  const pageLocations = digObject(pageItemData, 'locations', [])

  const templateBuilderLocations = digObject(templatePageItemPayload, 'digitalTemplatePageItem.data.locations', [])

  const project = digObject(digitalPagePayload, 'project', {})

  const { addresses, images } = useSelector(reduxState => reduxState.entities)
  const { organization } = useRelations(project)

  const mapLocationSources = getMapLocationSources(templateBuilderLocations)

  // for map location source project_comparables
  const projectComparablesLocations = templateBuilderLocations.filter(
    location => location.source === 'project_comparables',
  )

  const { filteredComparables } = useComparables({
    filters: {
      comparableSource: projectComparablesLocations[0]?.comparable_source || 'all',
      comparableStatus: projectComparablesLocations[0]?.comparable_status || 'all',
      projectId: project?.id,
    },
    performHttpRequests: true,
  })

  const filteredComparablesLocations = getComparablesLocations(addresses, filteredComparables)

  useEffect(() => {
    if (mapLocationSources.includes('project_comparables') && filteredComparablesLocations.length){
      setState({ comparablesLocations: filteredComparablesLocations })
    }
  }, [filteredComparablesLocations.length, mapLocationSources.length])

  // for map location custom or source project or oraganizations
  const customLocations = mapLocationSources.includes('custom')
    ? templateBuilderLocations.filter(location => location.source === 'custom')
    : []

  const organizationsLocations = mapLocationSources.includes('organization')
    ? getOrganizationsLocations(addresses, organization)
    : []

  const projectLocations = mapLocationSources.includes('project') ? getProjectLocations(addresses, project) : []

  useEffect(() => {
    const digitalPageCurrentLocations = pageLocations.length
      ? pageLocations
      : [...customLocations, ...organizationsLocations, ...projectLocations]

    setState({ digitalPageLocations: digitalPageCurrentLocations })
  }, [mapLocationSources.length, pageLocations.length])

  const isComparablesMap = mapLocationSources.includes('project_comparables')

  const locations = isComparablesMap ? comparablesLocations : digitalPageLocations || []

  const mapMarkerImage = images[markerImage?.id] || {}

  return {
    callbacks: {
      toggleLocation: location => toggleLocation(location, state, setState),
    },
    isComparablesMap,
    comparablesLocations,
    locations,
    mapMarkerImage,
  }
}

export default useDigitalPageMap
