import React from 'react'
import PropTypes from 'prop-types'
import { Duration } from 'luxon'

import { Box } from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useLocalization from '@hooks/useLocalization'

import PieChartIcon from '@components/Icons/PieChartIcon'
import Statistic from '@components/Statistic'
import TimeWithPageIcon from '@components/Icons/TimeWithPageIcon'
import UserIcon from '@components/Icons/UserIcon'

import localizedStrings from './localizedStrings'

const Statistics = (props) => {
  const { shareLink } = props

  const analytics = digObject(shareLink, 'analytics', {})
  const { views } = shareLink
  const { max_read_percentage, total_views, total_seconds_on_page } = analytics

  const {
    values: { minutes, seconds },
  } = Duration.fromObject({ seconds: total_seconds_on_page }).shiftTo('minutes', 'seconds')

  const {
    strings,
    callbacks: { formatNumber, formatString },
  } = useLocalization(localizedStrings)

  return (
    <Box flexDirection="row" flexWrap="wrap">
      <Statistic
        fullWidth
        icon={<TimeWithPageIcon width={44} height={44} fillColor="#8C8C8E" />}
        label={strings.labels?.totalReadTime || 'Total Read Time'}
        value={
          minutes > 0
            ? `${formatNumber(minutes, '.0f')}m ${formatNumber(seconds, '.0f')}s`
            : `${formatNumber(seconds, '.0f')}s`
        }
      />

      <Statistic
        icon={<PieChartIcon width={44} height={44} fillColor="#8C8C8E" />}
        label={strings.labels?.completion || 'Completion'}
        value={formatString(strings.readPercentage, { max_read_percentage })}
      />

      <Statistic
        boxProps={{ marginLeft: 'auto' }}
        icon={<UserIcon width={44} height={44} fillColor="#8C8C8E" />}
        label={strings.labels?.views || 'Views'}
        value={String(views)}
      />
    </Box>
  )
}

Statistics.propTypes = {
  shareLink: PropTypes.object.isRequired,
}

export default Statistics
