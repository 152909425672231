import { useContext } from 'react'

import { useForm, useLatestEntity } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import { launchModal } from '@campaignhub/javascript-utils'

import useDispatch from '@hooks/useDispatch'
import useSelector from '@hooks/useSelector'

import * as contactActions from '@redux/modules/contact'

import defaultFormState, { requiredFields } from '@models/contact'

import PageContext from '@contexts/pageContext'

import type { AppDispatch } from '@redux/store'
import type { DeleteParams } from '@redux/modules/types'
import type { ContactModel, ContactRequestOptions, CustomFieldModel } from '@models/types'

type CreateContactParams = {
  contactParams: {
    contact: Partial<ContactModel>,
    customFields: CustomFieldModel[],
  },
  dispatch: AppDispatch,
  requestOptions?: ContactRequestOptions,
}

const createContact = (params: CreateContactParams) => {
  const { contactParams, dispatch, requestOptions } = params
  const { createContact: createFn } = contactActions

  return dispatch(createFn(contactParams, requestOptions))
}

type UpdateContactParams = {
  contact: ContactModel,
  contactParams: {
    contact: Partial<ContactModel>,
    customFields: CustomFieldModel[],
  },
  dispatch: AppDispatch,
  requestOptions?: ContactRequestOptions,
}

const updateContact = (params: UpdateContactParams) => {
  const {
    contact, contactParams, dispatch, requestOptions,
  } = params
  const { updateContact: updateFn } = contactActions

  const updatedParams = {
    id: contact.id,
    ...contactParams,
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

type DeleteContactParams = {
  contact: DeleteParams<ContactModel>,
  dispatch: AppDispatch,
}

const deleteContact = (params: DeleteContactParams) => {
  const { dispatch, contact } = params
  const { deleteContact: deleteFn } = contactActions

  return dispatch(deleteFn(contact))
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useContactForm(
  contact: Partial<ContactModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const contactForm = useForm(
    defaultFormState,
    { entity: contact, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [contact.id, contact.cache_key],
  )

  return {
    ...contactForm,
  }
}

export const useRelations = (contact: Partial<ContactModel> = {}) => {
  const {
    address_id,
    user_type_id,
    users_with_read_access_ids,
    users_with_write_access_ids,
  } = contact

  const entities = useSelector(reduxState => reduxState.entities)
  const {
    addresses,
    userTypes,
    users,
  } = entities

  const address = address_id ? addresses[address_id] || {} : {}
  const userType = user_type_id ? userTypes[user_type_id] || {} : {}

  const readAccessUsers = Object.values(users).filter(user => users_with_read_access_ids?.includes(user.id))
  const writeAccessUsers = Object.values(users).filter(user => users_with_write_access_ids?.includes(user.id))

  return {
    address,
    userType,
    readAccessUsers,
    writeAccessUsers,
  }
}

function useContact(initEntity: Partial<ContactModel> = {}) {
  const { entity: contact }: { entity: ContactModel} = useLatestEntity(initEntity, 'contacts')

  const dispatch = useDispatch()

  const { callbacks } = useContext(PageContext)

  const {
    address, userType, readAccessUsers, writeAccessUsers,
  } = useRelations(contact)

  const { creating, updating } = useSelector(reduxState => reduxState.contacts)

  return {
    address,
    callbacks: {
      createContact: (
        contactParams: {
          contact: Partial<ContactModel>,
          customFields: CustomFieldModel[],
        },
        entityOptions?: ContactRequestOptions,
      ) => (
        createContact({ contactParams, dispatch, requestOptions: entityOptions })
      ),
      createOrEditContact: (payloadCallbacks: { afterActionCallback: ()=> void }) => launchModal({
        callbacks,
        modalKey: 'CreateOrEditContactModal',
        payload: {
          contact,
          callbacks: {
            afterActionCallback: payloadCallbacks.afterActionCallback,
            createContact: (
              contactParams: {
                contact: Partial<ContactModel>,
                customFields: CustomFieldModel[],
              },
              entityOptions?: ContactRequestOptions,
            ) => (
              createContact({ contactParams, dispatch, requestOptions: entityOptions })
            ),
            deleteContact: () => deleteContact({ dispatch, contact }),
            updateContact: (
              contactParams: {
                contact: Partial<ContactModel>,
                customFields: CustomFieldModel[],
              },
              entityOptions?: ContactRequestOptions,
            ) => (
              updateContact({
                contact, contactParams, dispatch, requestOptions: entityOptions,
              })
            ),
          },
        },
      }),
      deleteContact: () => deleteContact({ dispatch, contact }),
      updateContact: (
        contactParams: {
          contact: Partial<ContactModel>,
          customFields: CustomFieldModel[],
        },
        entityOptions?: ContactRequestOptions,
      ) => (
        updateContact({
          contact, contactParams, dispatch, requestOptions: entityOptions,
        })
      ),
    },
    contact,
    creating,
    readAccessUsers,
    updating,
    userType,
    writeAccessUsers,
  }
}

export default useContact
