export default {
  default: {
    title: {
      title: 'Projects',
    },
    labelTitle: {
      label: 'Title',
    },
    displayMode: {
      label: 'Display Mode',
    },
    displayModeValues: {
      labelListCompact: 'List (Compact)',
      labelListLarge: 'List (Large)',
    },
    limit: {
      label: 'Limit',
    },
    comparable: {
      agency: 'Agency',
      all: 'All',
      market: 'Market',
    },
    blankState: {
      title: 'No Comparables',
      paragraph: "This project has no comparable properties assigned. You can search your own organization's listings, market sales and listings or create your own custom comparables if they're missing from the data supplier.",
    },
    manage: 'Manage',

  },
}
