import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities, deleteEntity } from '@redux/entities'

import api from '@functions/api'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/digitalTemplate/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/digitalTemplate/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/digitalTemplate/FETCH_FAILURE'

const SEARCH_REQUEST = 'realbase/digitalTemplate/SEARCH_REQUEST'
const SEARCH_SUCCESS = 'realbase/digitalTemplate/SEARCH_SUCCESS'
const SEARCH_FAILURE = 'realbase/digitalTemplate/SEARCH_FAILURE'

const CREATE_REQUEST = 'realbase/digitalTemplate/CREATE_REQUEST'
const CREATE_SUCCESS = 'realbase/digitalTemplate/CREATE_SUCCESS'
const CREATE_FAILURE = 'realbase/digitalTemplate/CREATE_FAILURE'

const UPDATE_REQUEST = 'realbase/digitalTemplate/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realbase/digitalTemplate/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realbase/digitalTemplate/UPDATE_FAILURE'

const DELETE_REQUEST = 'realbase/digitalTemplate/DELETE_REQUEST'
const DELETE_SUCCESS = 'realbase/digitalTemplate/DELETE_SUCCESS'
const DELETE_FAILURE = 'realbase/digitalTemplate/DELETE_FAILURE'

// Initial State
const initialState = {
  creating: false,
  currentUsage: {},
  deleting: false,
  errors: [],
  loaded: false,
  loadedForKeys: [],
  loadedIds: [],
  loading: false,
  searching: false,
  updating: false,
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    currentUsage: payload.currentUsage,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function searchRequest(){
  return {
    type: SEARCH_REQUEST,
  }
}

export function searchSuccess(){
  return {
    type: SEARCH_SUCCESS,
  }
}

export function searchFailure(errors = []){
  return {
    type: SEARCH_FAILURE,
    errors,
  }
}

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(){
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest(){
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess(){
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors = []){
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Action Creators
export function loadDigitalTemplates(options){
  const { entityKey } = options || {}

  return (dispatch, getState) => {
    const loadedForKeys = [...getState().digitalTemplates.loadedForKeys]
    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('/digital_templates.json', options)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entities, Schemas.DIGITAL_TEMPLATE_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function searchDigitalTemplates(options){
  return (dispatch) => {
    dispatch(searchRequest())

    const promise = api('/digital_templates.json', options)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entities, Schemas.DIGITAL_TEMPLATE_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(searchSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(searchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function loadDigitalTemplate(digitalTemplateId, options = {}){
  return (dispatch, getState) => {
    const loadedIds = [...getState().digitalTemplates.loadedIds]
    if (!loadedIds.includes(digitalTemplateId)){
      loadedIds.push(digitalTemplateId)
    }

    dispatch(fetchRequest({ loadedIds }))

    const promise = api(`/digital_templates/${digitalTemplateId}.json`, options)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.DIGITAL_TEMPLATE)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))
        return { success: false, errors }
      })

    return promise
  }
}

export function loadDigitalTemplateUsage(digitalTemplateId, options){
  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = api(`/digital_templates/${digitalTemplateId}/current_usage.json`, options)
      .then(({ data }) => {
        dispatch(fetchSuccess({ currentUsage: data }))

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function loadDigitalTemplateCurrentUsage(digitalTemplateId, options){
  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = api(`/digital_templates/${digitalTemplateId}/live_template_usage.json`, options)
      .then(({ data }) => {
        dispatch(fetchSuccess({ result: data.data }))

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}


export function createDigitalTemplate(digitalTemplate, options){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      digital_template: digitalTemplate,
    }),
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api('/digital_templates.json', options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.DIGITAL_TEMPLATE)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function updateDigitalTemplate(digitalTemplate, options){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      digital_template: digitalTemplate,
    }),
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/digital_templates/${digitalTemplate.id}.json`, options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.DIGITAL_TEMPLATE)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function duplicateDigitalTemplate(digitalTemplate, options){
  const config = {
    method: 'POST',
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api(`/digital_templates/${digitalTemplate.id}/duplicate.json`, options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.DIGITAL_TEMPLATE)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function deleteUnusedDigitalTemplateComponents(digitalTemplate, options){
  const config = {
    method: 'POST',
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api(
      `/digital_templates/${digitalTemplate.id}/delete_unused_digital_template_components.json`,
      options,
      config,
    )
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.DIGITAL_TEMPLATE)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function removeComponentStyle(digitalTemplate, digitalTemplateComponent, options){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      digital_template_component_id: digitalTemplateComponent?.id,
    }),
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api(
      `/digital_templates/${digitalTemplate.id}/remove_component_style.json`,
      options,
      config,
    )
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.DIGITAL_TEMPLATE)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function forkDigitalTemplate(originalTemplate, options){
  const config = {
    method: 'POST',
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api(`/digital_templates/${originalTemplate.id}/fork.json`, options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.DIGITAL_TEMPLATE)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function forkDigitalTemplateComponent(digital_template, originalTemplateComponent, options){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      digital_template_component_id: originalTemplateComponent?.id,
    }),
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api(`/digital_templates/${digital_template.id}/fork_component.json`, options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.DIGITAL_TEMPLATE)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function newVersionDigitalTemplate(digitalTemplate, options){
  const config = {
    method: 'POST',
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api(`/digital_templates/${digitalTemplate.id}/new_version.json`, options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.DIGITAL_TEMPLATE)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        const existingPage = {
          ...digitalTemplate,
          hidden: true,
        }

        const existingNormalized = normalize(existingPage, Schemas.DIGITAL_TEMPLATE)
        dispatch(updateEntities(existingNormalized))

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function createOrUpdateComponentStyles(digitalTemplate, options){
  const config = {
    method: 'POST',
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api(
      `/digital_templates/${digitalTemplate.id}/create_or_update_component_styles.json`,
      options,
      config,
    )
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.DIGITAL_TEMPLATE)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function deleteDigitalTemplate(digitalTemplate){
  const config = {
    method: 'DELETE',
  }

  return (dispatch) => {
    dispatch(deleteRequest())

    const promise = api(`/digital_templates/${digitalTemplate.id}.json`, {}, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.DIGITAL_TEMPLATE)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(deleteFailure(errors))

        return { success: false, errors }
      })
    return promise
  }
}

export function unlockSessionLocks(digitalTemplate){
  const config = {
    method: 'POST',
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api(`/digital_templates/${digitalTemplate.id}/unlock_session_locks.json`, {}, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.DIGITAL_TEMPLATE)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function toggleProductionReady(digitalTemplate, options = {}){
  const config = {
    method: 'PUT',
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api(`/digital_templates/${digitalTemplate.id}/toggle_production_ready.json`, options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.DIGITAL_TEMPLATE)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedIds: action.loadedIds || state.loadedIds,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        currentUsage: action.currentUsage || [],
        errors: [],
        loaded: true,
        loading: false,
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case SEARCH_REQUEST:
      return {
        ...state,
        searching: true,
      }
    case SEARCH_SUCCESS:
      return {
        ...state,
        errors: [],
        searching: false,
      }
    case SEARCH_FAILURE:
      return {
        ...state,
        searching: false,
        errors: action.errors,
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors,
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
