import { digObject } from '@campaignhub/javascript-utils'

export default function getSortedSectionKeys(digitalPage, components){
  const sortedSectionKeys = digObject(digitalPage, 'options.sorted_section_keys', [])

  const unsortedComponentKeys = components
    .filter(component => !sortedSectionKeys?.includes(component.key))
    .map(component => component.key)

  if (unsortedComponentKeys.length === components.length){
    return components.map(component => component.key)
  }

  if (digitalPage && sortedSectionKeys){
    const keyCountsMatch = components.length === sortedSectionKeys.length
    if (keyCountsMatch && !unsortedComponentKeys.length) return sortedSectionKeys
  }

  if (sortedSectionKeys.length){
    return [...sortedSectionKeys, ...unsortedComponentKeys]
  }

  return components.map(component => component.key)
}
