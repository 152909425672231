export default {
  default: {
    events: {
      type: 'count',
      none: 'No Events',
      one: '{count} Event',
      many: '{count} Events',
    },
    title: 'Title',
  },
}
