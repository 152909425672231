import { useContext } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import {
  Box, Button, FormField, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import { useAddressForm } from '@hooks/useAddress'
import useLocalization from '@hooks/useLocalization'

import EntitySelectBox from '@components/EntitySelectBox'
import GoogleAddressSearch from '@components/GoogleAddressSearch'
import FieldHolder from '@components/FieldHolder'

import localizedStrings from './localizedStrings'

const createOrUpdateAddress = (params) => {
  const {
    callbacks, createFn, entityState, strings, updateFn,
  } = params

  const {
    closeModal, createAddress, updateAddress, loadShortcodeDataFn,
  } = callbacks

  const toastText = entityState.id
    ? strings.toast?.addressUpdated || 'Address Updated Successfully'
    : strings.toast?.addressCreated || 'Address Created Successfully'

  const actionFn = entityState.id ? updateFn : createFn

  const afterActionCallback = () => {
    closeModal()
    if (loadShortcodeDataFn) loadShortcodeDataFn()
  }

  const addressPayload = {
    callbacks: {
      action: actionFn,
      afterAction: () => afterActionCallback(),
    },
    entityParams: entityState,
    toastText,
  }

  return entityState.id ? updateAddress(addressPayload) : createAddress(addressPayload)
}

const CreateOrEditAddressModal = (props) => {
  const {
    callbacks, disableAnimation, disableOverlay, headerTitle, hiddenFields, modalKey, showModal,
  } = props

  const { closeModal } = callbacks

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const addressPayload = digObject(modalData, `${modalKey}`, {})
  const {
    address,
    callbacks: { createAddress: createFn, updateAddress: updateFn },
  } = addressPayload

  const addressFormPayload = useAddressForm(address)

  const { creating, updating } = useSelector(reduxState => reduxState.addresses)

  const {
    entityState,
    entityState: {
      country_id, lot_number, postcode, region_name, state_name, street_name, street_number, title, unit_number,
    },
    errors,
    handlers,
    saveEnabled,
    setEntityState,
  } = addressFormPayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal
      callbacks={callbacks}
      clickSafeZone
      disableAnimation={disableAnimation}
      disableOverlay={disableOverlay}
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header
        callbacks={callbacks}
        title={
          headerTitle
          || (address.id
            ? strings?.sideBarModalHeader?.update || 'Update'
            : strings?.sideBarModalHeader?.create || 'Create')
        }
        titleSecondLine={strings?.sideBarModalHeader?.titleSecondLine || 'Address'}
      />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <FormField direction="column" boxProps={{ marginBottom: 'large' }} label="Search Address">
            <GoogleAddressSearch
              callbacks={{
                selectItem: mappedAddress => setEntityState(mappedAddress),
              }}
            />
          </FormField>

          {!hiddenFields.includes('title') && (
            <FormField
              direction="column"
              boxProps={{ marginBottom: 'large' }}
              errorMessage={errors.title}
              label={strings?.formFieldLabels?.title || 'Title'}
            >
              <input name="title" placeholder="e.g Office" type="text" value={title} {...handlers} />
            </FormField>
          )}

          <Box>
            {!hiddenFields.includes('lot_number') && (
            <FieldHolder
              boxProps={{ marginBottom: 'large' }}
              errorMessage={errors.lot_number}
              halfWidth
              label={strings?.formFieldLabels?.lotNumber || 'Lot Number'}
            >
              <input name="lot_number" type="text" value={lot_number} {...handlers} />
            </FieldHolder>
            )}
          </Box>

          <Box>
            {!hiddenFields.includes('unit_number') && (
              <FieldHolder
                boxProps={{ marginBottom: 'large' }}
                errorMessage={errors.unit_number}
                halfWidth
                label={strings?.formFieldLabels?.unitNumber || 'Unit Number'}
              >
                <input name="unit_number" type="text" value={unit_number} {...handlers} />
              </FieldHolder>
            )}

            {!hiddenFields.includes('street_number') && (
              <FieldHolder
                boxProps={{ marginBottom: 'large' }}
                errorMessage={errors.street_number}
                halfWidth
                hideErrorMessage
                label={strings?.formFieldLabels?.streetNumber || '* Street Number'}
                lastInput
              >
                <input name="street_number" type="text" value={street_number} {...handlers} />
              </FieldHolder>
            )}
          </Box>

          {!hiddenFields.includes('street_name') && (
            <FormField
              direction="column"
              boxProps={{ marginBottom: 'large' }}
              errorMessage={errors.street_name}
              label={strings?.formFieldLabels?.streetName || '* Street Name'}
            >
              <input name="street_name" type="text" value={street_name} {...handlers} />
            </FormField>
          )}

          {!hiddenFields.includes('region_name') && (
            <FormField
              direction="column"
              boxProps={{ marginBottom: 'large' }}
              errorMessage={errors.region_name}
              label={strings?.formFieldLabels?.suburb || '* Suburb'}
            >
              <input name="region_name" type="text" value={region_name} {...handlers} />
            </FormField>
          )}

          <Box>
            {!hiddenFields.includes('postcode') && (
              <FieldHolder
                boxProps={{ marginBottom: 'large' }}
                errorMessage={errors.postcode}
                halfWidth
                label={strings?.formFieldLabels?.postcode || '* Post Code'}
              >
                <input name="postcode" type="text" value={postcode} {...handlers} />
              </FieldHolder>
            )}

            {!hiddenFields.includes('state_name') && (
              <FieldHolder
                boxProps={{ marginBottom: 'large' }}
                errorMessage={errors.state_name}
                halfWidth
                label={strings?.formFieldLabels?.state || '* State'}
                lastInput
              >
                <input name="state_name" type="text" value={state_name} {...handlers} data-validate-field-on="change" />
              </FieldHolder>
            )}
          </Box>

          {!hiddenFields.includes('country') && (
            <FormField
              direction="column"
              boxProps={{ marginBottom: 'large' }}
              errorMessage={errors.country_id}
              label={strings?.formFieldLabels?.country || 'Country'}
            >
              <EntitySelectBox
                entityKey="countries"
                name="country_id"
                sortDirection="asc"
                sortKey="name"
                value={country_id}
                {...handlers}
              />
            </FormField>
          )}

        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="secondary"
          onClick={closeModal}
          size="medium"
          style={{ marginRight: 4, width: 'calc(50% - 4px)' }}
        >
          {strings?.buttons?.cancel || 'Cancel'}
        </Button>

        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={creating || updating}
          loadingBubbleColor="white"
          onClick={() => createOrUpdateAddress({
            callbacks,
            createFn,
            entityState,
            strings,
            updateFn,
          })}
          size="medium"
          style={{ marginLeft: 4, width: 'calc(50% - 4px)' }}
        >
          {strings?.buttons?.save || 'Save'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

CreateOrEditAddressModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  customRequiredFields: PropTypes.array,
  disableAnimation: PropTypes.bool,
  disableOverlay: PropTypes.bool,
  headerTitle: PropTypes.string,
  hiddenFields: PropTypes.array,
  modalKey: PropTypes.string,
  organizationId: PropTypes.string,
  showModal: PropTypes.bool,
}

CreateOrEditAddressModal.defaultProps = {
  hiddenFields: ['title'],
  modalKey: 'CreateOrEditAddressModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <CreateOrEditAddressModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
