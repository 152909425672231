export default {
  default: {
    button: {
      customize: 'Customise Dashboard',
    },
    VideoModule: {
      title: 'Support Token',
      paragraph: 'What is the Generate Support Token feature?',
      titleTwo: 'How to customise your dashboard',
      paragraphTwo: 'Add new sections to customise the main screen.',
    },
    tools: 'Tools',
  },
}
