import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faDesktop, faMobile, faPrint, faTablet,
} from '@fortawesome/pro-light-svg-icons'

import { Button, IconWithMenu } from '@campaignhub/suit-theme'

const renderIcon = (targetDevice) => {
  if (targetDevice === 'mobile'){
    return (
      <Button buttonStyle="secondaryUtility" icon={<FontAwesomeIcon icon={faMobile} />} size="medium">
        Mobile
      </Button>
    )
  }

  if (targetDevice === 'tablet'){
    return (
      <Button buttonStyle="secondaryUtility" icon={<FontAwesomeIcon icon={faTablet} />} size="medium">
        Tablet
      </Button>
    )
  }

  if (targetDevice === 'print'){
    return (
      <Button buttonStyle="secondaryUtility" icon={<FontAwesomeIcon icon={faPrint} />} size="medium">
        Print
      </Button>
    )
  }

  return (
    <Button buttonStyle="secondaryUtility" icon={<FontAwesomeIcon icon={faDesktop} />} size="medium">
      Desktop
    </Button>
  )
}

const ToggleTargetDeviceButton = (props) => {
  const {
    boxProps,
    callbacks: { setTargetDevice },
    targetDevice,
  } = props

  return (
    <IconWithMenu icon={renderIcon(targetDevice)} boxProps={boxProps}>
      <IconWithMenu.Menu listStyle={{ right: '-7px' }}>
        <a key="desktop" onClick={() => setTargetDevice('default')}>
          <FontAwesomeIcon icon={faDesktop} /> Desktop
        </a>

        <a key="mobile" onClick={() => setTargetDevice('mobile')}>
          <FontAwesomeIcon icon={faMobile} /> Mobile
        </a>

        <a key="tablet" onClick={() => setTargetDevice('tablet')}>
          <FontAwesomeIcon icon={faTablet} /> Tablet
        </a>

        <a key="print" onClick={() => setTargetDevice('print')}>
          <FontAwesomeIcon icon={faPrint} /> Print
        </a>
      </IconWithMenu.Menu>
    </IconWithMenu>
  )
}

ToggleTargetDeviceButton.propTypes = {
  boxProps: PropTypes.object,
  callbacks: PropTypes.shape({
    setTargetDevice: PropTypes.func,
  }).isRequired,
  targetDevice: PropTypes.string,
}

export default ToggleTargetDeviceButton
