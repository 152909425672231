import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import {
  ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useProject, { useProjectForm } from '@hooks/useProject'

import ExternalPlatforms from './screens/ExternalPlatforms'
import SourcePlatform from './screens/SourcePlatform'

const ManageProjectExternalPlatformsModal = (props) => {
  const {
    callbacks,
    callbacks: { closeModal, updateProject },
    project: initProject,
    modalKey,
    showModal,
  } = props

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const { callbacks: modalCallbacks, platformType } = digObject(modalData, modalKey, {})
  const { updateProject: updateFn } = modalCallbacks || {}

  const {
    project,
    updating,
  } = useProject(initProject)

  const projectFormPayload = useProjectForm(project)

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      {platformType === 'export' && (
        <ExternalPlatforms
          callbacks={{
            closeModal,
            submitFn: projectParam => updateProject(projectParam, 'Export Platforms', updateFn),
          }}
          project={project}
          updating={updating}
        />
      )}

      {platformType === 'source' && (
        <SourcePlatform
          callbacks={{
            closeModal,
            submitFn: projectParam => updateProject(projectParam, 'Source Platform', updateFn),
          }}
          project={project}
          projectFormPayload={projectFormPayload}
          updating={updating}
        />
      )}
    </SidebarModal>
  )
}

ManageProjectExternalPlatformsModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  project: PropTypes.object,
  showModal: PropTypes.bool,
}

ManageProjectExternalPlatformsModal.defaultProps = {
  modalKey: 'ManageProjectExternalPlatformsModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <ManageProjectExternalPlatformsModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
