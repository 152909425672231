import { useEffect } from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, FormField, Link, SidebarModal,
} from '@campaignhub/suit-theme'

import { useSetState } from '@campaignhub/react-hooks'

import { stringToKey } from '@campaignhub/javascript-utils'

import useCurrentUser from '@hooks/useCurrentUser'
import useCustomField, { useCustomFieldForm } from '@hooks/useCustomField'
import useLocalization from '@hooks/useLocalization'

import FieldOptionValues from '@components/FieldOptionValues'

import CustomFieldSetKeySelector from './components/CustomFieldSetKeySelector'
import FieldTags from './components/FieldTags'
import Fields from './components/Fields'

import localizedStrings from './localizedStrings'

const createOrUpdateCustomField = (params) => {
  const {
    callbacks,
    createFn,
    createForOwnerFn,
    entityState,
    isActionCreateForOwner,
    loadShortcodeListFn,
    selectedOrganization,
    strings,
    updateFn,
  } = params

  const { createCustomField, closeModal, updateCustomField } = callbacks

  const toastText = entityState.id
    ? strings.toast?.customFieldUpdated || 'Custom Field Updated Successfully'
    : strings.toast?.customFieldCreated || 'Custom Field Created Successfully'

  const createFnc = isActionCreateForOwner ? createForOwnerFn : createFn
  const actionFn = entityState.id ? updateFn : createFnc

  const afterActionFn = () => {
    closeModal()

    if (loadShortcodeListFn && !!selectedOrganization.id){
      loadShortcodeListFn()
    }
  }

  const customFieldPayload = {
    callbacks: {
      action: actionFn,
      afterAction: afterActionFn,
    },
    entityParams: entityState,
    shouldRedirect: true,
    requestOptions: { include_custom_field_set_fields: true },
    toastText,
  }

  return entityState.id ? updateCustomField(customFieldPayload) : createCustomField(customFieldPayload)
}

const confirmDelete = (params) => {
  const {
    customField, deleteCustomField, deleteFn, closeModal, strings,
  } = params

  const deleteCustomFieldPayload = {
    callbacks: {
      action: deleteFn,
      afterAction: closeModal,
    },
    entityParams: customField,
    toastText: strings.toast?.customFieldDeleted || 'Custom Field Deleted Successfully',
  }

  swal
    .fire({
      title: strings.sweetAlert?.title || 'Delete Custom Field?',
      text: strings.sweetAlert?.text || 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: strings.sweetAlert?.confirm || 'Yes, delete it.',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        deleteCustomField(deleteCustomFieldPayload)
      }
    })
}

const defaultState = {
  viewMode: 'advanced',
}

const CreateOrEditCustomFieldModal = (props) => {
  const {
    callbacks,
    callbacks: {
      closeModal,
      createCustomFieldForOwner: createForOwnerFn,
      deleteCustomField,
      loadOrganizationShortcodeList: loadShortcodeListFn,
      setCustomFieldSetKey,
    },
    clickSafeZone,
    customField: initCustomField,
    customFieldSet,
    customFieldSetFilterType,
    customFieldSetIsBrandOwned,
    customFieldSetIsSystemOwned,
    customFieldSetKey,
    duplicateForOwner,
    hiddenFieldKeys,
    isOrganizationUser,
    modalKey,
    selectedOrganization,
    showModal,
    viewMode: initViewMode,
  } = props

  const [state, setState] = useSetState(defaultState)
  const { viewMode } = state

  const customFieldPayload = useCustomField(initCustomField)
  const {
    callbacks: { createCustomField: createFn, deleteCustomField: deleteFn, updateCustomField: updateFn },
    creating,
    customField,
    updating,
  } = customFieldPayload

  const newRecord = !customField.id

  const customFieldForm = useCustomFieldForm(customField)
  const {
    entityState,
    entityState: {
      field_type,
      hint,
      input_type,
      key,
      label,
      options,
      options: { width },
      placeholder,
      required,
    },
    handlers,
    saveEnabled,
    setEntityState,
  } = customFieldForm

  const { isAdmin } = useCurrentUser()

  useEffect(() => {
    setState({ viewMode: initViewMode || 'advanced' })
  }, [])

  const isActionCreateForOwner = duplicateForOwner || customFieldSetIsBrandOwned || customFieldSetIsSystemOwned

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal
      callbacks={callbacks}
      clickSafeZone={clickSafeZone}
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header
        callbacks={callbacks}
        title={
          newRecord
            ? strings.sideBarModalHeader?.titleCreate || 'Create'
            : strings.sideBarModalHeader?.titleUpdate || 'Update'
        }
        titleSecondLine={strings.sideBarModalHeader?.titleSecondLine || 'Field'}
      />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <Box marginLeft="auto" width="auto">
            <Link
              onClick={() => setState({ viewMode: viewMode === 'advanced' ? 'basic' : 'advanced' })}
              style={{ marginLeft: 'auto' }}
              textProps={{ fontSize: 'xsmall' }}
            >
              {viewMode === 'advanced'
                ? strings.viewMode?.basic || 'Basic View'
                : strings.viewMode?.advanced || 'Advanced View'}
            </Link>
          </Box>

          {!customFieldSet?.id && customFieldSetFilterType && (
            <CustomFieldSetKeySelector
              callbacks={{
                setCustomFieldSetKey,
              }}
              customFieldSetKey={customFieldSetKey}
              customFieldSetFilterType={customFieldSetFilterType}
            />
          )}

          <FormField
            direction="column"
            boxProps={{ marginBottom: 'large' }}
            label={strings.formFieldLabels?.fieldLabel || '* Field Label'}
          >
            <input
              name="label"
              onBlur={e => setEntityState({ key: stringToKey(e.target.value) })}
              onChange={e => setEntityState({ label: e.target.value })}
              placeholder={strings.formFieldLabels?.formLabelPlaceholder || 'e.g Bedrooms'}
              type="text"
              value={label || ''}
            />
          </FormField>

          {viewMode === 'advanced' && (
            <>
              {!hiddenFieldKeys.includes('key') && (
                <FormField
                  direction="column"
                  boxProps={{ marginBottom: 'large' }}
                  label={strings.formFieldLabels?.fieldKey || '* Field Key'}
                >
                  <input
                    name="key"
                    placeholder={strings.formFieldLabels?.formKeyPlaceholder || 'e.g property_bedrooms'}
                    type="text"
                    value={key || ''}
                    {...handlers}
                  />
                </FormField>
              )}

              {!hiddenFieldKeys.includes('hint') && (
                <FormField direction="column" boxProps={{ marginBottom: 'large' }} label="Hint">
                  <input name="hint" type="text" value={hint || ''} {...handlers} />
                </FormField>
              )}

              {!hiddenFieldKeys.includes('placeholder') && (
                <FormField direction="column" boxProps={{ marginBottom: 'large' }} label="Placeholder">
                  <input name="placeholder" type="text" value={placeholder || ''} {...handlers} />
                </FormField>
              )}

              {!hiddenFieldKeys.includes('required') && (
                <FormField
                  direction="column"
                  boxProps={{ marginBottom: 'large' }}
                  label={strings.formFieldLabels?.required || 'Required'}
                >
                  <select name="required" value={required || 'false'} data-value-type="boolean" {...handlers}>
                    <option value="false">{strings.formFieldOptions?.no || 'No'}</option>
                    <option value="true">{strings.formFieldOptions?.yes || 'Yes'}</option>
                  </select>
                </FormField>
              )}

              <FormField
                boxProps={{ marginBottom: 'large' }}
                direction="column"
                label={strings.formFieldLabels?.fieldType || '* Field Type'}
              >
                <select name="field_type" value={field_type || ''} {...handlers}>
                  <option value="">{strings.formFieldOptions?.selectOption || 'Please Select...'}</option>
                  <option value="input">{strings.formFieldOptions?.input || 'Input'}</option>
                  <option value="select">{strings.formFieldOptions?.selectBox || 'Select Box'}</option>
                  <option value="textarea">{strings.formFieldOptions?.textArea || 'Text Area'}</option>

                  {isAdmin && (
                    <>
                      <option value="json_object">{strings.formFieldOptions?.jsonObject || 'JSON Object'}</option>
                      <option value="json_object_array">
                        {strings.formFieldOptions?.jsonObjectArray || 'JSON Object Array'}
                      </option>
                    </>
                  )}
                </select>
              </FormField>

              {field_type.includes('json_object', 'json_object_array') && (
                <Fields customFieldForm={customFieldForm} customFieldPayload={customFieldPayload} />
              )}

              {field_type === 'input' && (
                <FormField
                  boxProps={{ marginBottom: 'large' }}
                  direction="column"
                  label={strings.formFieldLabels?.inputType || 'Input Type'}
                >
                  <select name="input_type" value={input_type || ''} {...handlers}>
                    <option value="">{strings.formFieldOptions?.selectOption || 'Please Select...'}</option>
                    <option value="date">{strings.formFieldOptions?.date || 'Date'}</option>
                    <option value="datetime-local">{strings.formFieldOptions?.dateTime || 'Date Time'}</option>
                    <option value="email">{strings.formFieldOptions?.email || 'Email'}</option>
                    <option value="number">{strings.formFieldOptions?.number || 'Number'}</option>
                    <option value="text">{strings.formFieldOptions?.text || 'Text'}</option>
                    <option value="time">{strings.formFieldOptions?.time || 'Time'}</option>
                    <option value="url">{strings.formFieldOptions?.url || 'Url'}</option>
                  </select>
                </FormField>
              )}

              {!hiddenFieldKeys.includes('width') && (
                <FormField
                  boxProps={{ marginBottom: 'large' }}
                  direction="column"
                  label={strings.formFieldLabels?.fieldWidth || 'Field Width'}
                >
                  <select data-value-type="number" name="options.width" value={width || ''} {...handlers}>
                    <option value="100">100%</option>
                    <option value="75">75%</option>
                    <option value="50">50%</option>
                    <option value="25">25%</option>
                  </select>
                </FormField>
              )}
            </>
          )}

          {!hiddenFieldKeys.includes('tags') && <FieldTags callbacks={{ setEntityState }} options={options} />}
        </Box>

        {field_type === 'select' && (
          <>
            <SidebarModal.Separator />

            <FieldOptionValues callbacks={{ setEntityState }} options={options} />
          </>
        )}

        {!newRecord && (!isOrganizationUser || !customFieldSetIsSystemOwned) && (
          <FormField direction="column" label={strings.formFieldLabels?.moreOptions || 'More Options'}>
            <Box flexDirection="column" flexShrink={0}>
              <Button
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faTrashAlt} />}
                onClick={() => confirmDelete({
                  customField,
                  deleteCustomField,
                  deleteFn,
                  closeModal,
                  strings,
                })}
                size="medium"
              >
                {strings.buttons?.delete || 'Delete Custom Field'}
              </Button>
            </Box>
          </FormField>
        )}
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={creating || updating}
          onClick={() => createOrUpdateCustomField({
            callbacks,
            createFn,
            createForOwnerFn,
            entityState,
            isActionCreateForOwner,
            loadShortcodeListFn,
            selectedOrganization,
            strings,
            updateFn,
          })}
          size="large"
        >
          {strings.buttons?.save || 'Save'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

CreateOrEditCustomFieldModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  clickSafeZone: PropTypes.bool,
  customField: PropTypes.object.isRequired,
  customFieldSet: PropTypes.object,
  customFieldSetFilterType: PropTypes.string,
  customFieldSetIsBrandOwned: PropTypes.bool,
  customFieldSetIsSystemOwned: PropTypes.bool,
  customFieldSetKey: PropTypes.string,
  duplicateForOwner: PropTypes.bool,
  hiddenFieldKeys: PropTypes.array,
  isOrganizationUser: PropTypes.bool,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
  viewMode: PropTypes.string,
}

CreateOrEditCustomFieldModal.defaultProps = {
  hiddenFieldKeys: [],
  modalKey: 'CreateOrEditCustomFieldModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <CreateOrEditCustomFieldModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
