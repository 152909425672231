export default {
  default: {
    addressModalHeaderTitle: 'Project',
    tabs: {
      archived: 'Archived',
      current: 'Current',
    },
    filters: {
      createDateAfter: 'Created After',
      createDateBefore: 'Created Before',
      defaultSelectLabel: 'All',
      filtersTitle: 'Filters',
      limit: 'Filter by Limit',
      organization: 'Filter by {global.organizationType.shortName.singular}',
      projectType: 'Filter by Project Type',
      string: 'Filter by Title',
      user: 'User',
    },
    VideoModule: {
      title: 'How to create a project',
      paragraph: "Need to start a proposal? You'll need to create a project first. Learn how by watching this short tutorial.",
    },
    title: 'Projects',
    createText: 'Create',
  },
}
