import { useEffect } from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons'

import {
  Box, Button, FormField, Link, Text,
} from '@campaignhub/suit-theme'

import { useSetState } from '@campaignhub/react-hooks'

import { digObject, stringToKey } from '@campaignhub/javascript-utils'

import useFieldOptionValues from '@hooks/useFieldOptionValues'

const defaultState = {
  fieldKey: '',
  fieldLabel: '',
}

const FieldOptionValues = (props) => {
  const { callbacks: { setEntityState }, options } = props

  const [state, setState] = useSetState(defaultState)
  const { fieldKey, fieldLabel } = state

  const optionValues = digObject(options, 'option_values', [])

  useEffect(() => {
    setState({ ...defaultState })
  }, [optionValues.length])

  const {
    callbacks: { toggleOptionValues },
    optionValueExists,
  } = useFieldOptionValues(options, { fieldKey, setEntityState })

  return (
    <>
      <Box
        border="1px solid"
        borderColor="lineColor"
        flexDirection="column"
        marginTop="large"
        padding="large"
      >
        <Box flexDirection="column">
          <FormField boxProps={{ marginBottom: 'large' }} direction="column" label="Label">
            <input
              onChange={e => setState({ fieldLabel: e.target.value })}
              onBlur={e => setState({ fieldKey: stringToKey(e.target.value) })}
              type="text"
              value={fieldLabel}
            />
          </FormField>

          <FormField boxProps={{ marginBottom: 'large' }} direction="column" label="Key">
            <input onChange={e => setState({ fieldKey: e.target.value })} type="text" value={fieldKey} />
          </FormField>

          <Button
            buttonStyle="ghostCreate"
            disabled={optionValueExists}
            onClick={() => toggleOptionValues(fieldKey, fieldLabel)}
            size="medium"
            style={{ width: 'auto' }}
          >
            {optionValueExists ? 'Value Already Exists' : 'Add Value'}
          </Button>
        </Box>
      </Box>

      {!!optionValues.length && (
        <FormField direction="column" label="Option Values" marginTop="large">
          <Box flexDirection="column" marginBottom="large">
            {optionValues.map(option => (
              <Box
                alignItems="center"
                key={option.key}
                color="bodyFontColor"
                fontSize="small"
                justifyContent="space-between"
                marginBottom="medium"
                padding="medium"
                variant="whiteGrey"
              >
                <Box flexDirection="column" minWidth="0">
                  <Text fontSize="small" variant="ellipsis">
                    {option.value}
                  </Text>
                  <Text color="bodyFontLightColor" fontSize="xsmall" variant="ellipsis">
                    {option.key}
                  </Text>
                </Box>
                <Link greyLink onClick={() => toggleOptionValues(option.key, option.value)}>
                  <FontAwesomeIcon icon={faTrashAlt} />
                </Link>
              </Box>
            ))}
          </Box>
        </FormField>
      )}

    </>
  )
}

FieldOptionValues.propTypes = {
  callbacks: PropTypes.shape({
    setEntityState: PropTypes.func.isRequired,
  }),
  options: PropTypes.object.isRequired,
}

export default FieldOptionValues
