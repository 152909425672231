import React from 'react'
import PropTypes from 'prop-types'

import { Box, FormField } from '@campaignhub/suit-theme'

import { stringToTitleCase } from '@functions/string'

import useLocalization from '@hooks/useLocalization'

import FieldHolder from '@components/FieldHolder'
import GoogleAddressSearch from '@components/GoogleAddressSearch'

import SelectedListItem from '../SelectedListItem'

import localizedStrings from './localizedStrings'

const MapLocations = (props) => {
  const { locationPayload } = props

  const {
    callbacks: { toggleLocation },
    isComparablesMap,
    locations,
  } = locationPayload

  const { strings } = useLocalization(localizedStrings)

  const locationsListTitle = isComparablesMap
    ? strings.comparablesLocationsLabel || 'Comparables Locations'
    : strings.selectedLocationLabel || 'Selected Locations'
  return (
    <Box flexDirection="column" flexShrink={0}>
      {!isComparablesMap
      && (
      <FormField
        direction="column"
        boxProps={{ marginBottom: 'large' }}
        label={strings.searchLabel || 'Search Address'}
      >

        <GoogleAddressSearch
          callbacks={{
            selectItem: location => toggleLocation({ ...location, source: 'custom' }),
          }}
        />
      </FormField>
      )}

      {!!locations.length && (
        <FieldHolder boxProps={{ marginBottom: 'large' }} label={locationsListTitle}>
          {locations.map((location, index) => {
            const {
              full_address, region_name, state_name, street_number, street_name, source,
            } = location

            const isSourceCustomOrComparables = source === 'custom' || source === 'project_comparables'

            const title = isSourceCustomOrComparables ? `${street_number} ${street_name}` : stringToTitleCase(source)
            const subTitle = isSourceCustomOrComparables
              ? `${region_name} ${state_name}`
              : full_address || strings?.address || 'Address'

            return (
              <SelectedListItem
                boxProps={{ marginBottom: 'medium' }}
                callbacks={{ toggleItem: () => toggleLocation(location) }}
                canDelete={source !== 'project_comparables'}
                title={title}
                subTitle={subTitle}
                key={location.google_place_id || `${source}-${index}`}
              />
            )
          })}
        </FieldHolder>
      )}
    </Box>
  )
}

MapLocations.propTypes = {
  locationPayload: PropTypes.object.isRequired,
}
export default MapLocations
