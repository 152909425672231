import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Image, Link, ListItem, Text,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useFeature from '@hooks/useFeature'
import useUser, { useRelations } from '@hooks/useUser'

const User = (props) => {
  const { isFirstUser, user, user: { full_name, mobile } } = props

  const {
    defaultImage,
  } = useRelations(user)

  const { callbacks: { editUser } } = useUser(user)

  const { canEditUser } = useFeature({ user })

  const imageUrl = digObject(defaultImage, 'sizes.thumb_90x90', '')

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        borderBottom: '1px dashed',
        padding: 'large',
        paddingTop: isFirstUser ? 'medium' : 'large',
      }}
      disableHover
    >
      <Image
        boxProps={{ backgroundColor: 'hoverLightGrey', fontSize: 'small' }}
        circle
        height={30}
        url={imageUrl}
        width={30}
      />

      <Box flexDirection="column" marginLeft="large">
        <Text fontSize="small" marginBottom="small">
          {full_name}
        </Text>

        <Text color="faintGrey" fontSize="xsmall">
          {mobile}
        </Text>
      </Box>

      {canEditUser && (
        <Link greyLink onClick={() => editUser()} textProps={{ fontSize: 'small' }}>
          <FontAwesomeIcon icon={faPencil} />
        </Link>
      )}
    </ListItem>
  )
}

User.propTypes = {
  isFirstUser: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    full_name: PropTypes.string,
    mobile: PropTypes.string,
  }).isRequired,
}

export default User
