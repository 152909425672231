import { useLatestEntity, useThunkDispatch } from '@campaignhub/react-hooks'

import * as dataStoreItemActions from '@redux/modules/dataStoreItem'

export const createOrUpdateDataStoreItem = (dataStoreItem, dispatch, requestOptions) => {
  const { createOrUpdateDataStoreItem: createOrUpdateFn } = dataStoreItemActions
  return dispatch(createOrUpdateFn(dataStoreItem, requestOptions))
}

function useDataStoreItem(initEntity){
  const { entity: dataStoreItem } = useLatestEntity(initEntity, 'dataStoreItems')

  const dispatch = useThunkDispatch()

  return {
    callbacks: {
      createOrUpdateDataStoreItem: (params, requestOptions) => createOrUpdateDataStoreItem(params, dispatch, requestOptions),
    },
    dataStoreItem,
  }
}

export default useDataStoreItem
