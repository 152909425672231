export default {
  default: {
    readPercentage: '{max_read_percentage}%',
    labels: {
      views: 'Views',
      completion: 'Completion',
      totalReadTime: 'Total Read Time',
    },
  },
}
