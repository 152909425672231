import useQuote from '@hooks/useQuote'
import useReduxAction from '@hooks/useReduxAction'

export const defaultRequestOptions = {
  quote: {
    include_quote_items: true,
    include_quote_sections: true,
  },
  quoteItem: {
    include_quote_item_quote_total: true,
  },
}

function useQuoteBuilder(options = {}){
  const { callbacks, callbacks: { showCreateOrEditQuoteModal }, quoteId } = options || {}

  // Load Quote
  useReduxAction(
    'quotes',
    'loadQuote',
    defaultRequestOptions.quote,
    [quoteId],
    {
      dispatchAction: (action, requestOptions) => action(quoteId, requestOptions),
      shouldPerformFn: (entityReducer) => {
        const { loadedIds } = entityReducer
        return quoteId && !loadedIds.includes(quoteId)
      },
    },
  )

  const initQuote = { id: quoteId }

  const quotePayload = useQuote(initQuote, {
    callbacks: {
      showCreateOrEditQuoteModal,
    },
  })

  return {
    callbacks,
    quotePayload,
  }
}

export default useQuoteBuilder
