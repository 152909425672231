export default {
  default: {
    addTeam: 'Add Team',
    create: 'Create',
    title: 'Teams',
    filters: 'Filters',
    defaultSelectLabel: 'All',
    titleLabel: 'Filter by Title',
    userLabel: 'User',
    VideoModule: {
      title: 'How to create Teams',
      paragraph: 'Use the teams function so that everyone can have access to the account and proposals. Your team will then automatically show in future proposal documents.',
    },
  },
}
