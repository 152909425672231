import { useEffect } from 'react'

import { useLatestEntity, useThunkDispatch } from '@campaignhub/react-hooks'
import { digObject } from '@campaignhub/javascript-utils'

import useEntityExternalPlatforms from '@hooks/useEntityExternalPlatforms'
import { importProject as importProjectFn } from '@hooks/useProject'
import useMixpanel from '@hooks/useMixpanel'

import type { AppDispatch } from '@redux/store'
import type { MixPanelType } from '@hooks/useMixpanel'
import type { ProjectModel, ProjectRequestOptions } from '@models/types'

type ImportProjectParams = {
  dispatch: AppDispatch,
  externalId: number,
  externalPlatformKey: string,
  project: ProjectModel,
  dispatchMixpanelEvent: MixPanelType,
  sourcePlatformOptions: {},
}

const importProject = (payload: ImportProjectParams, requestOptions: ProjectRequestOptions) => {
  const {
    dispatch, dispatchMixpanelEvent, externalId, externalPlatformKey, project, sourcePlatformOptions,
  } = payload

  const mergedOptions = {
    ...requestOptions,
    ...sourcePlatformOptions,
  }

  return importProjectFn({
    dispatch,
    dispatchMixpanelEvent,
    externalId,
    integrationPlatformKey: externalPlatformKey,
    organizationId: project.organization_id,
    requestOptions: mergedOptions,
  })
}

export type SupportedFeaturesType = {
  key: string,
  label: string,
  supported: boolean,
}

function useProjectSourcePlatformSync(initProject: Partial<ProjectModel>, requestOptions: Record<string, string> = {}) {
  const { entity: project } = useLatestEntity(initProject, 'projects')

  const dispatch = useThunkDispatch()

  const { callbacks: { dispatchMixpanelEvent } } = useMixpanel()

  const externalPlatformPayload = useEntityExternalPlatforms(project)
  const {
    sourcePlatform: {
      externalId,
      externalPlatformKey,
      integrationPlatform,
    },
    sourcePlatformOptions,
  } = externalPlatformPayload

  const supportedFeatures: SupportedFeaturesType[] = digObject(integrationPlatform, 'supported_features', [])
  const syncProjects = supportedFeatures.some(supported_feature => supported_feature.key === 'sync_projects')

  useEffect(() => {
    if (externalId && externalPlatformKey && syncProjects){
      importProject({
        dispatch,
        dispatchMixpanelEvent,
        externalId,
        externalPlatformKey,
        project,
        sourcePlatformOptions,
      }, requestOptions)
    }
  }, [externalId, externalPlatformKey, syncProjects])

  return {
    project,
  }
}

export default useProjectSourcePlatformSync
