import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { sortArrayBy } from '@campaignhub/javascript-utils'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const sortEntities = (entities, sortDirection, sortKey) => {
  const array = Object.values(entities)
  const sorted = sortArrayBy(array, sortDirection, sortKey)

  return sorted
}

const EntitySelectBox = (props) => {
  const {
    entityKey, entityDisplayKey, sortDirection, sortKey, ...restProps
  } = props

  const entities = useSelector(reduxState => reduxState.entities[entityKey])

  const { strings } = useLocalization(localizedStrings)

  if (!entities){
    return null
  }

  const sortedEntities = sortEntities(entities, sortDirection, sortKey)

  return (
    <select data-validate-field-on="change" {...restProps}>
      <option value="">{strings.option || 'Please Select...'}</option>
      {sortedEntities.map(entity => (
        <option key={entity.id} value={entity.id}>
          {entity[entityDisplayKey]}
        </option>
      ))}
    </select>
  )
}

EntitySelectBox.propTypes = {
  entityKey: PropTypes.string.isRequired,
  entityDisplayKey: PropTypes.string,
  sortDirection: PropTypes.string.isRequired,
  sortKey: PropTypes.string.isRequired,
}

EntitySelectBox.defaultProps = {
  entityDisplayKey: 'title',
}

export default EntitySelectBox
