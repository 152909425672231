import React from 'react'
import PropTypes from 'prop-types'

import { BlankState } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

import agreementImageUrl from './assets/no-agreements.svg'
import formImageUrl from './assets/no-forms.svg'
import micrositeImageUrl from './assets/no-microsites.svg'
import proposalImageUrl from './assets/no-proposals.svg'

const renderImage = (digitalTemplateType) => {
  if (digitalTemplateType === 'agreement') return agreementImageUrl
  if (digitalTemplateType === 'form') return formImageUrl
  if (digitalTemplateType === 'microsite') return micrositeImageUrl
  return proposalImageUrl
}

const DigitalPageBlankState = (props) => {
  const { digitalTemplateType } = props

  const { strings } = useLocalization(localizedStrings)

  const templateTypeStrings = strings[digitalTemplateType] || {}

  return (
    <BlankState boxProps={{ marginBottom: 'large' }} imageUrl={renderImage(digitalTemplateType)}>
      <BlankState.Title>{templateTypeStrings.title || 'No Proposal Created'}</BlankState.Title>

      <BlankState.Paragraph>{templateTypeStrings.paragraph || 'Create a proposal to get started and send it to your client. Proposals can be used to win listings, or educate and inform potential customers.'}</BlankState.Paragraph>
    </BlankState>
  )
}

DigitalPageBlankState.propTypes = {
  digitalTemplateType: PropTypes.string.isRequired,
}

export default DigitalPageBlankState
