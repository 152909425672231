import { useContext } from 'react'

import { ModalContext } from '@campaignhub/suit-theme'

import useShareLinks from '@hooks/useShareLinks'

const setShareLinkModalData = (shareLinkId, modalData, filteredShareLinks, setModalData) => {
  const { EditShareLinkModal } = modalData
  const shareLink = filteredShareLinks.find(shareLink => shareLink.id === shareLinkId)

  const newEditShareLinkModalData = {
    ...EditShareLinkModal,
    shareLink,
  }

  setModalData('EditShareLinkModal', newEditShareLinkModalData)
}

const useSelectShareLink = (shareLink = {}) => {
  const { subject_id, subject_type } = shareLink
  const { filteredShareLinks } = useShareLinks({
    filters: {
      printLink: false,
      subjectId: subject_id,
      subjectType: subject_type,
    },
  })

  const modalContext = useContext(ModalContext)
  const {
    callbacks: { setModalData },
    modalData,
  } = modalContext

  return {
    callbacks: {
      setShareLinkModalData: shareLinkId => setShareLinkModalData(shareLinkId, modalData, filteredShareLinks, setModalData),
    },
  }
}

export default useSelectShareLink
