import { useMemo } from 'react'

import { useSetState } from '@campaignhub/react-hooks'

import PageContext from '@contexts/pageContext'

import PageContent from './components/PageContent'

const defaultState = {
  pageFilters: {},
}

const ApiLogs = () => {
  const [state, setState] = useSetState(defaultState)
  const { pageFilters } = state

  const pageContext = useMemo(
    () => ({
      callbacks: {
        resetFilters: (resetFn) => {
          if (resetFn) resetFn()
          setState({ pageFilters: {} })
        },
        updateFilters: (filters) => { setState({ pageFilters: filters }) },
      },
    }),
    [],
  )

  return (
    <PageContext.Provider value={pageContext}>
      <PageContent pageFilters={pageFilters} />
    </PageContext.Provider>
  )
}

export default ApiLogs
