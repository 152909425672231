import { useLatestEntity } from '@campaignhub/react-hooks'

import { digObject } from '@campaignhub/javascript-utils'

function useAgreementFeature(featureKey, initShortcodeData){
  const { entity: agreementShortcodeData } = useLatestEntity(initShortcodeData, 'agreementShortcodeData')

  const featureStatuses = digObject(agreementShortcodeData, 'feature_completion_statuses', {})
  const featureCompleted = featureStatuses[featureKey] || false

  return {
    featureCompleted,
  }
}

export default useAgreementFeature
