export default {
  default: {
    title: 'Analytics',
  },
  VideoModule: {
    title: 'Data Tracking',
    titleTwo: 'Proposal Data & Analytics',
    paragraph: 'Learn what data is available for tracking within your Engage platform.',
    paragraphTwo: 'A powerful part of the system. An important tool that allows you to view and track where your vendors spend the most time on the proposal.',
  },
}
