import { useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'

import { useSetState } from '@campaignhub/react-hooks'

import PageContext from '@contexts/pageContext'

import ImportUsersModal from '@modals/ImportUsersModal'

import handleCallbackAction from '@functions/handleCallbackAction'

import PageContent from './components/PageContent'

const callbacks = (component, setState) => {
  const componentCallbacks = {
    ImportUsersModal: {
      closeModal: () => setState({ showImportUsersModal: false }),
      importUsers: payload => handleCallbackAction(payload),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  pageFilters: {},
  performHttpRequests: false,
  showImportUsersModal: false,
}

const UserList = () => {
  const [state, setState] = useSetState(defaultState)

  const { pageFilters, showImportUsersModal } = state

  const entities = useSelector(reduxState => reduxState.entities)
  const { brands } = entities
  const brandsArray = Object.values(brands)

  useEffect(() => {
    if (brandsArray.length === 1){
      const id = brandsArray[0]?.id
      setState({ pageFilters: { brand_id: id } })
    }
  }, [brandsArray.length])

  const pageContext = useMemo(
    () => ({
      callbacks: {
        showImportUsersModal: () => setState({ showImportUsersModal: true }),
        resetFilters: (resetFn) => {
          if (resetFn) resetFn()
          setState({ pageFilters: {} })
        },
        updateFilters: (filters) => {
          setState({ pageFilters: filters })
        },
      },
    }),
    [],
  )

  return (
    <>
      <Helmet>
        <title>Users | Engage</title>
      </Helmet>

      <PageContext.Provider value={pageContext}>
        <PageContent pageFilters={pageFilters} />

        <ImportUsersModal callbacks={callbacks('ImportUsersModal', setState)} showModal={showImportUsersModal} />
      </PageContext.Provider>
    </>
  )
}

export default UserList
