import { sortArrayBy } from '@campaignhub/javascript-utils'

const groupAndSortTypes = (types) => {
  const array = Object.values(types)
  const sorted = sortArrayBy(array, 'asc', 'title')

  const parentTypes = sorted.filter(type => !type.parent_id)
  const childrenTypes = sorted.filter(type => type.parent_id)

  const groups = {}

  // Setup parent types
  parentTypes.forEach((parentType) => {
    const {
      id, key, title,
    } = parentType

    // Setup Primary Category
    if (!groups[id]){
      groups[id] = {
        id,
        key,
        title,
        subtypes: [],
      }
    }
  })

  // Add Subtypes
  childrenTypes.forEach((childType) => {
    const { parent_id } = childType

    if (groups[parent_id]){
      groups[parent_id].subtypes.push(childType)
    }
  })

  return groups
}

export default groupAndSortTypes
