export default {
  default: {
    buttons: {
      cancel: 'Cancel',
      delete: 'Delete Recipient',
    },
    formFieldLabels: {
      role: 'Role',
      userType: 'Type',
      moreOptions: 'More Options',
    },
    selectOptions: {
      organizationOwner: '{global.organizationType.shortName.singular} Owner',
      pleaseSelect: 'Please Select...',
      projectContact: 'Project Contact',
      projectLeadUser: 'Project Lead User',
      projectSupportUser: 'Project Support User',
    },
  },
}
