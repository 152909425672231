import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { Box } from '@campaignhub/suit-theme'

import DigitalTemplateContext from '@contexts/digitalTemplateContext'

import useDigitalTemplate from '@hooks/useDigitalTemplate'
import useDigitalTemplatePage from '@hooks/useDigitalTemplatePage'

import SectionGroup from './SectionGroup'
import TemplateCurrentUsage from './components/TemplateCurrentUsage'

import styles from './styles.module.scss'

const handleKeyDown = (e, props) => {
  const {
    digitalEditorPayload: {
      callbacks: { copyItem },
      selectedEntity,
    },
  } = props

  if (e.ctrlKey && e.keyCode === 67){
    e.preventDefault()
    copyItem(selectedEntity)
  }
}

const EditorCanvas = (props) => {
  const {
    digitalEditorPayload: {
      callbacks,
      callbacks: { createSectionGroup },
      digitalTemplate,
      digitalTemplatePage,
      selectedEntity,
      targetDevice,
    },
  } = props

  const digitalTemplatePayload = useDigitalTemplate(digitalTemplate)
  const {
    componentStyles, currentTemplateUsage, firstPageId, shouldShowCurrentTemplateUsage,
  } = digitalTemplatePayload

  const digitalTemplatePagePayload = useDigitalTemplatePage(digitalTemplatePage)
  const { sortedSectionGroups } = digitalTemplatePagePayload

  const keyDownFn = e => handleKeyDown(e, props)

  useEffect(() => {
    document.addEventListener('keydown', keyDownFn)

    return () => document.removeEventListener('keydown', keyDownFn)
  }, [keyDownFn])

  return (
    <DigitalTemplateContext.Provider value={{ componentStyles, targetDevice }}>
      {shouldShowCurrentTemplateUsage
      && <TemplateCurrentUsage firstPageId={firstPageId} currentTemplateUsage={currentTemplateUsage} />}

      <Box
        alignSelf="center"
        backgroundColor="white"
        border="1px solid"
        borderColor="lineColor"
        flexDirection="column"
        lineHeight="1.5"
        style={{ border: '1px solid #d8d9d9', boxShadow: '2px 2px 8px rgba(0, 0, 0, 0.05)' }}
        width={targetDevice === 'mobile' ? 375 : '100%'}
      >
        {sortedSectionGroups.map(sectionGroup => (
          <SectionGroup
            callbacks={callbacks}
            digitalTemplatePageSectionGroup={sectionGroup}
            key={sectionGroup.id}
            selectedCanvasItem={selectedEntity}
          />
        ))}

        <Box className={styles.addGroup} onClick={() => createSectionGroup()}>
          Add New Group
        </Box>
      </Box>
    </DigitalTemplateContext.Provider>
  )
}

EditorCanvas.propTypes = {
  digitalEditorPayload: PropTypes.shape({
    callbacks: PropTypes.shape({
      copyItem: PropTypes.func,
      createSectionGroup: PropTypes.func.isRequired,
      dropItem: PropTypes.func.isRequired,
      selectCanvasItem: PropTypes.func.isRequired,
    }).isRequired,
    digitalTemplate: PropTypes.object,
    digitalTemplatePage: PropTypes.object,
    selectedEntity: PropTypes.object,
    targetDevice: PropTypes.string,
  }).isRequired,
}

export default EditorCanvas
