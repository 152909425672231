export default {
  default: {
    buttons: {
      maximize: 'Maximize',
    },
    titles: {
      onboardingTasks: 'Onboarding Tasks',
    },
  },
}
