import React from 'react'

import { BlankState } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import blankFileUrl from './assets/no-filelibraries.svg'
import localizedStrings from './localizedStrings'

const FileLibrariesBlankState = () => {
  const { strings } = useLocalization(localizedStrings)

  return (
    <BlankState imageUrl={blankFileUrl}>
      <BlankState.Title>
        {strings.title || 'No Document or File Libraries'}
      </BlankState.Title>

      <BlankState.Paragraph>
        {strings.paragraph || 'You don’t have any document or file libraries in your agency yet. You can store and call documents from your libraries into marketing pages.'}
      </BlankState.Paragraph>
    </BlankState>
  )
}

export default FileLibrariesBlankState
