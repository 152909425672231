export default {
  default: {
    allQuotes: 'All Quotes',
    buttons: {
      goBack: 'Go Back',
    },
    editQuote: 'Edit Quote',
    quote: 'Quote',
  },
}
