import FieldHolder from '@components/FieldHolder'
import Input from '@components/Input'

interface GridPropertiesProps {
  itemStyle: {
    alignItems: string,
    justifyContent: string,
  },
  selectedEntity: {
    id: number,
  },
  styleProps: {},
  updateStyle: (name: string, value: string, styleProps: {}) => void,
}

const GridProperties = (props: GridPropertiesProps) => {
  const {
    itemStyle, styleProps, selectedEntity, updateStyle,
  } = props

  return (
    <>
      <Input
        boxProps={{ marginBottom: 'large' }}
        data={itemStyle}
        entityId={selectedEntity.id}
        halfWidth
        propertyKey="gridTemplateRows"
        label="Grid Rows"
        updateCallback={(name, value) => updateStyle(name, value, styleProps)}
      />

      <Input
        boxProps={{ marginBottom: 'large' }}
        data={itemStyle}
        entityId={selectedEntity.id}
        halfWidth
        last
        propertyKey="gridTemplateColumns"
        label="Grid Columns"
        updateCallback={(name, value) => updateStyle(name, value, styleProps)}
      />

      <Input
        boxProps={{ marginBottom: 'large' }}
        data={itemStyle}
        entityId={selectedEntity.id}
        halfWidth
        propertyKey="gridRowGap"
        label="Grid Row Gap"
        updateCallback={(name, value) => updateStyle(name, value, styleProps)}
      />

      <Input
        boxProps={{ marginBottom: 'large' }}
        data={itemStyle}
        entityId={selectedEntity.id}
        halfWidth
        last
        propertyKey="gridColumnGap"
        label="Grid Column Gap"
        updateCallback={(name, value) => updateStyle(name, value, styleProps)}
      />

      <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth label="Align Items">
        <select
          onChange={e => updateStyle('alignItems', e.target.value, styleProps)}
          value={itemStyle.alignItems || ''}
        >
          <option value="">Default</option>
          <option value="start">Start</option>
          <option value="center">Center</option>
          <option value="end">End</option>
          <option value="baseline">Baseline</option>
        </select>
      </FieldHolder>

      <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth label="Justify Content" last>
        <select
          onChange={e => updateStyle('justifyContent', e.target.value, styleProps)}
          value={itemStyle.justifyContent || ''}
        >
          <option value="">Default</option>
          <option value="start">Start</option>
          <option value="center">Center</option>
          <option value="end">End</option>
          <option value="space-around">Space Around</option>
          <option value="space-between">Space Between</option>
          <option value="space-evenly">Space Evenly</option>
        </select>
      </FieldHolder>
    </>
  )
}

export default GridProperties
