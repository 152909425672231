export default {
  default: {
    filters: {
      filterByTitle: 'Filter by Title',
      project: 'Project',
      status: 'Status',
      statusTitle: '{global.status.title}',
    },
    agreementTitle: 'Agreements',
    createButton: 'Create',
    defaultSelectLabel: 'All',
    externalDocumentTitle: 'External Documents',
    integrationPartner: {
      description: 'Learn how to generate an agreement with RTA integration',
      integrationPlatformKey: 'real_time_agent',
      title: 'Sign up to Real Time Agent',
    },
    pageFilterModuleTitle: 'Filters',
  },
}
