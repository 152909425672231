import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons'

import {
  Box, Button, FormField, Link, Text,
} from '@campaignhub/suit-theme'

import useRenderCondition from '@hooks/useRenderCondition'

const RenderConditionGroup = (props) => {
  const { callbacks, groupKey, renderConditions } = props

  const { addCondition, deleteCondition, deleteGroup } = callbacks || {}

  const renderConditionPayload = useRenderCondition()
  const {
    callbacks: { mapOperatorText },
    entityState,
    entityState: { operator, string, value },
    handlers,
    setEntityState,
    saveEnabled,
  } = renderConditionPayload

  return (
    <Box flexDirection="column" marginTop="large">
      <FormField
        label={`Render Conditions - ${groupKey}`}
        labelRight={(
          <Link greyLink onClick={() => deleteGroup(groupKey)} textProps={{ fontSize: 'xsmall' }}>
            Delete Group
          </Link>
        )}
      >
        {!!renderConditions.length && (
          <Box flexDirection="column" marginBottom="large">
            {renderConditions.map(condition => (
              <Box
                alignItems="center"
                key={condition.id}
                color="bodyFontColor"
                fontSize="small"
                marginBottom="medium"
                padding="medium"
                variant="whiteGrey"
              >
                <Box flexDirection="column">
                  <Text marginRight="small" variant="twoLineEllipsis">
                    {condition.string}
                  </Text>

                  <Text color="blue" marginRight="small" variant="twoLineEllipsis">
                    {mapOperatorText(condition.operator)}
                  </Text>

                  <Text color="bodyFontLightColor" variant="twoLineEllipsis">
                    {condition.value}
                  </Text>
                </Box>

                <Link greyLink onClick={() => deleteCondition(condition, groupKey)} textProps={{ marginLeft: 'auto' }}>
                  <FontAwesomeIcon icon={faTrashAlt} />
                </Link>
              </Box>
            ))}
          </Box>
        )}
      </FormField>

      <FormField boxProps={{ marginBottom: 'large' }} label="Add Condition">
        <Box flexDirection="column">
          <input
            data-validate-field-on="change"
            placeholder="e.g {{bedrooms}}"
            style={{ marginBottom: 8 }}
            value={string}
            name="string"
            {...handlers}
          />

          <select
            data-validate-field-on="change"
            onChange={e => setEntityState({ operator: e.target.value })}
            name="operator"
            style={{ marginBottom: 8 }}
            value={operator}
            {...handlers}
          >
            <option value="">Select...</option>
            <option value="present">Is Present</option>
            <option value="empty">Is Empty</option>
            <option value="eq">Equal To</option>
            <option value="unequal">Not Equal To</option>
            <option value="greater">Greater Than</option>
            <option value="less">Less Than</option>
            <option value="includes">Includes</option>
            <option value="excludes">Excludes</option>
          </select>

          <input
            data-validate-fied-on="change"
            name="value"
            placeholder="optional"
            style={{ marginBottom: 8 }}
            value={value}
            {...handlers}
          />

          <Button
            buttonStyle="ghostCreate"
            disabled={!saveEnabled}
            onClick={() => addCondition(entityState, groupKey)}
            size="medium"
            style={{ width: 'auto' }}
          >
            Add Condition
          </Button>
        </Box>
      </FormField>
    </Box>
  )
}

RenderConditionGroup.propTypes = {
  callbacks: PropTypes.shape({
    addCondition: PropTypes.func.isRequired,
    deleteCondition: PropTypes.func.isRequired,
  }).isRequired,
  groupKey: PropTypes.string.isRequired,
  renderConditions: PropTypes.array.isRequired,
}

export default RenderConditionGroup
