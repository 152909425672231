export default {
  default: {
    buttons: {
      add: 'Add',
      customMobile: 'Add Custom Mobile',
    },
    select: {
      option: 'Select...',
    },
  },
}
