const defaultRequestOptions = {
  attachment: {
    include_attachment_data_store_items: true,
    include_attachment_file: true,
  },
  address: {
    include_address_owner: true,
  },
  comparable: {
    include_comparable_address: true,
    include_comparable_data_store_items: true,
    include_comparable_default_image: true,
    include_comparable_images: true,
    include_comparable_image_sizes: [
      'thumb_90x90',
      'thumb_120x80',
      'thumb_200x120',
      'thumb_640x360',
      'thumb_660x360',
      'thumb_1280x720',
      'thumb_2000x1500',
      'thumb_3500x2500',
    ],
  },
  contact: {
    include_contact_address: true,
    include_contact_project_ids: true,
    include_contact_users_with_read_access: true,
    include_contact_users_with_write_access: true,
  },
  digitalPage: {
    include_digital_page_default_share_links: true,
    include_digital_page_digital_template: true,
    include_digital_page_share_links: true,
    include_digital_page_total_views: true,
  },
  digitalTemplate: {
    include_digital_template_permission_ids: true,
    include_digital_template_preview_image: true,
    include_digital_template_type: true,
  },
  image: {
    include_image_sizes: ['thumb_90x90', 'thumb_200x120', 'thumb_660x360'],
  },
  project: {
    include_project_address: true,
    include_project_analytics: true,
    include_project_contacts: true,
    include_project_data_store_items: true,
    include_project_lead_users: true,
    include_project_support_users: true,
    include_project_teams: true,
  },
  projectImage: {
    include_image_sizes: ['thumb_200x120', 'thumb_400x240', 'thumb_660x360', 'thumb_1320x720'],
  },
  shareLink: {
    include_share_link_paths: true,
  },
  team: {
    include_team_cover_image: true,
    include_team_default_image: true,
    include_team_lead_users: true,
    include_team_member_user: true,
    include_team_support_users: true,
    include_team_team_members: true,
  },
  user: {
    include_user_default_image: true,
    include_user_organization_ids: true,
    include_user_role: true,
  },
}

export default defaultRequestOptions
