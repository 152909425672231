import { useSelector } from 'react-redux'

import { useSetState } from '@campaignhub/react-hooks'

import { digObject, toggleArray } from '@campaignhub/javascript-utils'

import generateID from '@functions/generateID'

import type { CustomFieldModel, NestedFieldModel } from '@models/customField'

const setupNestedDataStoreItemState = (nestedFields: NestedFieldModel[]) => {
  const dataStoreState = nestedFields.reduce((acc, fieldObject) => {
    const { key } = fieldObject

    acc[key] = ''
    acc.id = generateID()

    return acc
  }, {})

  return dataStoreState
}

type EntityState = {
  [key: string]: any,
}

type CustomFieldsForm = {
  callbacks: any,
  entityState: EntityState,
  setEntityState: (state: EntityState) => void,
}

type CreateOrUpdateItemParams = {
  customField: CustomFieldModel,
  customFieldsForm: CustomFieldsForm,
  nestedItem: NestedFieldModel,
}

const createOrUpdateNestedItem = (params: CreateOrUpdateItemParams) => {
  const {
    customField,
    nestedItem,
    customFieldsForm: { entityState, setEntityState },
  } = params

  const currentValues = digObject(entityState, customField.key)
  const updatedValues = toggleArray(currentValues, nestedItem, { useObjectKey: 'id' })

  return setEntityState({ [customField.key]: updatedValues })
}

type DeleteItemParams = {
  customFieldKey: string,
  nestedItem: NestedFieldModel,
  customFieldsForm: {
    entityState: EntityState,
    setEntityState: (state: EntityState) => void,
  },
}

const deleteNestedItem = (params: DeleteItemParams) => {
  const {
    customFieldKey,
    nestedItem,
    customFieldsForm: { entityState, setEntityState },
  } = params
  const currentValues = digObject(entityState, customFieldKey)

  const updatedValues = toggleArray(currentValues, nestedItem, { deepCompare: true })

  return setEntityState({ [customFieldKey]: updatedValues })
}

type CreateOrEditNestedDataStoreItemParams = {
  customFieldId: string | number,
  nestedDataStoreItemId: string | number,
}

type State = {
  customFieldId?: number | string | null,
  nestedDataStoreItemId?: number | string | null,
  showCreateOrEditNestedDataStoreArrayItem: boolean,
}

type SetState = {
  setState: (state: State) => void,
}

const createOrEditNestedDataStoreArrayItem = (params: CreateOrEditNestedDataStoreItemParams & SetState) => {
  const { customFieldId, nestedDataStoreItemId, setState } = params

  return setState({
    customFieldId,
    nestedDataStoreItemId,
    showCreateOrEditNestedDataStoreArrayItem: true,
  })
}

const defaultState: State = {
  customFieldId: null,
  nestedDataStoreItemId: null,
  showCreateOrEditNestedDataStoreArrayItem: false,
}

function useNestedDataStoreArrayItem(options: { customFieldsForm: CustomFieldsForm }) {
  const {
    customFieldsForm,
    customFieldsForm: { entityState },
  } = options || {}

  const { callbacks } = customFieldsForm || {}

  const [state, setState] = useSetState(defaultState)
  const { customFieldId, nestedDataStoreItemId, showCreateOrEditNestedDataStoreArrayItem } = state

  const entities = useSelector(reduxState => reduxState.entities)
  const { customFields } = entities

  const customField = customFields[customFieldId] || {}

  const nestedFields = digObject(customField, 'data.fields')
  const nestedDataStoreItemValues = customFieldId ? digObject(entityState, `${customField?.key}`, []) : []

  const nestedDataStoreItem = nestedDataStoreItemValues.find(dataStoreItemValue => dataStoreItemValue.id === nestedDataStoreItemId) || {}

  return {
    ...state,
    callbacks: {
      createOrEditNestedDataStoreArrayItem: (params: CreateOrEditNestedDataStoreItemParams) => createOrEditNestedDataStoreArrayItem({
        ...params,
        setState,
      }),
      closeModal: () => setState({ showCreateOrEditNestedDataStoreArrayItem: false }),
      setupNestedDataStoreItemState: () => setupNestedDataStoreItemState(nestedFields),
      createOrUpdateNestedItem: (nestedItem: NestedFieldModel) => createOrUpdateNestedItem({
        customField,
        customFieldsForm,
        nestedItem,
      }),
      deleteNestedItem: (customFieldKey: string, nestedItem: NestedFieldModel) => deleteNestedItem({
        customFieldKey,
        customFieldsForm,
        nestedItem,
      }),
      ...callbacks,
    },
    customField,
    customFieldsForm,
    nestedDataStoreItem,
    nestedDataStoreItemValues,
    nestedFields,
    showModal: showCreateOrEditNestedDataStoreArrayItem,
  }
}

export default useNestedDataStoreArrayItem
