import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, Image, ListItem, Text,
} from '@campaignhub/suit-theme'

import { useRelations } from '@hooks/useDigitalTemplate'

import DigitalPagePlaceholderImage from '@components/DigitalPagePlaceholderImage'

const DigitalTemplateListItem = (props) => {
  const { leaderboard } = props
  const {
    digital_template_id,
    digital_template_type_id,
    image: previewImageUrl,
    digital_page_count,
    template_title,
    view_count,
  } = leaderboard || {}

  const digitalTemplatePayload = useRelations({ id: digital_template_id, digital_template_type_id })
  const {
    digitalTemplateType: { key: digitalTemplateTypeKey, title: digitalTemplateTypeTitle },
  } = digitalTemplatePayload

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        borderBottom: '1px solid',
        padding: 'large',
      }}
      disableHover
    >
      <Box marginRight="medium" width="fit-content">
        {previewImageUrl ? (
          <Image
            boxProps={{ backgroundColor: 'hoverLightGrey', fontSize: 'small' }}
            height={30}
            borderRadius={5}
            url={previewImageUrl}
            width={40}
          />
        ) : (
          <Box backgroundColor="#F2F9FB" borderRadius={5}>
            <DigitalPagePlaceholderImage
              color="#61ABBB"
              digitalTemplateTypeKey={digitalTemplateTypeKey}
              height="30px"
              width="40px"
            />
          </Box>
        )}
      </Box>

      <Box justifyContent="space-between" flex={1} lineHeight={1.3} width="calc(100% - 48px)">
        <Box width="calc(100% - 60px)" overflow="hidden">
          <Text fontSize="small" width="fit-content" variant="ellipsis">
            {template_title}
          </Text>

          <Box flex="1 2" overflow="hidden">
            <Text color="bodyFontLightColor" fontSize="small" maxWidth="fit-content" variant="ellipsis">
              &nbsp;{`(${digitalTemplateTypeTitle})`}
            </Text>
          </Box>
        </Box>

        <Box fontSize="small" width="fit-content">
          <Box justifyContent="center" marginRight="large" width={20}>
            {digital_page_count}
          </Box>

          <Box justifyContent="center" width={20}>
            {view_count}
          </Box>
        </Box>
      </Box>
    </ListItem>
  )
}

DigitalTemplateListItem.propTypes = {
  leaderboard: PropTypes.object.isRequired,
}

export default DigitalTemplateListItem
