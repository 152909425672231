export type PermittedTagsType = {
  key: string,
  value: string,
}

type Tags = {
  [key: string]: PermittedTagsType[],
}

const getPermittedTags = (entity: string): PermittedTagsType[] => {
  const tags: Tags = {
    User: [
      {
        key: 'award', value: 'Award',
      },
      {
        key: 'logo', value: 'Logo',
      },
      {
        key: 'monogram', value: 'Monogram',
      },
      {
        key: 'profile', value: 'Profile',
      },
      {
        key: 'signature', value: 'Signature',
      },
    ],
    Project: [
      {
        key: 'plan', value: 'Plan',
      },
      {
        key: 'photo', value: 'Photo',
      },
    ],
  }

  return tags[entity] || []
}

export default getPermittedTags
