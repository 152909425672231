import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { DateTime } from 'luxon'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'
import {
  digObject, matchFilterArrayIncludes, matchFilterKey, matchFilterNumber, matchFilterString, sortArrayBy,
} from '@campaignhub/javascript-utils'

import sortEntitiesByDate from '@functions/sortEntitiesByDate'

const watchEntityKeys = ['digitalPages', 'digitalTemplates']

const matchFilterStartDate = (date, filterDate) => {
  if (!!date && !!filterDate) return date >= filterDate
}

const matchFilterEndDate = (date, filterDate) => {
  if (!!date && !!filterDate) return date <= filterDate
}

function useDigitalPages(options = {}){
  const {
    created_date_end: filterCreateEndDateString,
    created_date_start: filterCreateStartDateString,
    digital_template_id: filteredDigitalTemplateId,
    digital_template_type_key: filterDigitalTemplateTypeKey,
    engagementStatus,
    limit,
    owner_id: filterOwnerId,
    owner_type: filterOwnerType,
    status: filterStatusKey,
    string,
    subject_id: filterSubjectId,
    subject_type: filterSubjectType,
    templateTypeKey,
    user_id: filterUserId,
  } = options || {}

  const {
    updatedEntities: { digitalPages: digitalPagesUpdateAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const entities = useSelector(state => state.entities)
  const {
    digitalPages, digitalTemplates, digitalTemplateTypes, projects, statuses,
  } = entities

  const { loading } = useSelector(state => state.digitalPages)

  const filterCreateEndDate = filterCreateEndDateString
    ? DateTime.fromFormat(filterCreateEndDateString, 'yyyy-MM-dd')
    : null
  const filterCreateStartDate = filterCreateStartDateString
    ? DateTime.fromFormat(filterCreateStartDateString, 'yyyy-MM-dd')
    : null

  const filteredDigitalPages = useMemo(() => {
    const filtered = Object.values(digitalPages).filter((digitalPage) => {
      const {
        dates,
        digital_template_id,
        engagement_status,
        owner_id,
        owner_type,
        status_id,
        subject_id,
        subject_type,
        title: digitalPageTitle,
      } = digitalPage

      const createdDateString = digObject(dates, 'created.date_time_with_timezone')
      const createDate = createdDateString ? DateTime.fromISO(createdDateString) : null
      const digitalTemplate = digitalTemplates[digital_template_id] || {}
      const digitalTemplateType = digitalTemplateTypes[digitalTemplate.digital_template_type_id] || {}
      const parentTemplateType = digitalTemplateTypes[digitalTemplateType.parent_id] || {}
      const project = subject_type === 'Project' ? projects[subject_id] || {} : {}
      const { combined_user_ids, title } = project
      const status = statuses[status_id] || {}

      const createdAfterDateMatch = filterCreateStartDate ? matchFilterEndDate(createDate, filterCreateStartDate) : true
      const createBeforeDateMatch = filterCreateEndDate ? matchFilterStartDate(createDate, filterCreateEndDate) : true
      const digitalTemplateTypeMatch = matchFilterKey(digitalTemplateType.key, filterDigitalTemplateTypeKey)
      const engagementStatusMatch = matchFilterKey(engagement_status, engagementStatus)
      const ownerIdMatch = matchFilterNumber(owner_id, filterOwnerId)
      const ownerTypeMatch = filterOwnerType ? owner_type === filterOwnerType : true
      const statusMatch = filterStatusKey && filterStatusKey !== 'all' ? status.key === filterStatusKey : true
      const projectStringMatch = title ? matchFilterString(title, string) : true
      const digitalPageStringMatch = digitalPageTitle ? matchFilterString(digitalPageTitle, string) : true
      const titleMatch = projectStringMatch || digitalPageStringMatch
      const subjectIdMatch = matchFilterNumber(subject_id, filterSubjectId)
      const digitalTemplateIdMatch = matchFilterNumber(digital_template_id, filteredDigitalTemplateId)
      const subjectTypeMatch = filterSubjectType ? subject_type === filterSubjectType : true
      const templateTypeMatch = matchFilterKey(parentTemplateType.key, templateTypeKey)
      const userIdMatch = filterUserId ? matchFilterArrayIncludes(combined_user_ids, Number(filterUserId)) : true

      return createdAfterDateMatch
      && createBeforeDateMatch
      && digitalTemplateIdMatch
      && digitalTemplateTypeMatch
      && engagementStatusMatch
      && ownerIdMatch
      && ownerTypeMatch
      && statusMatch
      && subjectIdMatch
      && subjectTypeMatch
      && templateTypeMatch
      && titleMatch
      && userIdMatch
    })

    if (limit){
      return sortEntitiesByDate(filtered, 'desc', 'updated').slice(0, limit)
    }

    return sortArrayBy(filtered, 'desc', 'id')
  }, [digitalPagesUpdateAt, JSON.stringify(options)])

  const hasFilteredDigitalPages = !!filteredDigitalPages.length

  return {
    filteredDigitalPages,
    hasFilteredDigitalPages,
    loading,
  }
}

export default useDigitalPages
