import { useContext, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

import { faArchive, faHome } from '@fortawesome/pro-light-svg-icons'
import { useModals, useSetState } from '@campaignhub/react-hooks'

import {
  Box, Button, Columns, DashboardModule, LoadingModule, ModalContext, PageHeader,
} from '@campaignhub/suit-theme'

import { getQueryParamsFromHash, parsePermittedQueryParams, sortArrayBy } from '@campaignhub/javascript-utils'

import useLocalization from '@hooks/useLocalization'
import useMixpanel from '@hooks/useMixpanel'
import useProjects from '@hooks/useProjects'
import useReduxAction from '@hooks/useReduxAction'
import useVideoModules from '@hooks/useVideoModules'
import { generateUrls } from '@hooks/useProject'

import handleCallbackAction from '@functions/handleCallbackAction'

import CreateOrEditAddressModal from '@modals/CreateOrEditAddressModal'
import CreateProjectModal from '@modals/CreateProjectModal'

import PageContext from '@contexts/pageContext'
import PageFilterModule from '@components/PageFilterModule'
import VideoModule from '@components/VideoModule'

import defaultRequestOptions from '@sections/Client/packs/Dashboard/defaultRequestOptions'

import ProjectsBlankState from '../ProjectsBlankState'
import Project from '../Project'

import localizedStrings from './localizedStrings'

const buildPageFilterFields = (entities, strings) => {
  const { organizations, projectTypes, users } = entities

  const style = {
    marginBottom: 16,
  }

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        style,
      },
      key: 'string',
      label: strings.filters?.string || 'Filter by Title',
    },
    {
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: strings.filters?.defaultSelectLabel || 'All',
        inputComponent: 'select',
        style,
      },
      key: 'organization_id',
      label: strings.filters?.organization || 'Organisation',
      values: sortArrayBy(Object.values(organizations), 'asc', 'title').map(organization => ({
        key: organization.id,
        label: organization.title,
      })),
    },
    {
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: strings.filters?.defaultSelectLabel || 'All',
        inputComponent: 'select',
        style,
      },
      key: 'user_id',
      label: strings.filters?.user || 'User',
      values: sortArrayBy(Object.values(users), 'asc', 'full_name').map(user => ({
        key: user.id,
        label: user.full_name,
      })),
    },
    {
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: strings.filters?.defaultSelectLabel || 'All',
        inputComponent: 'select',
        style,
      },
      key: 'project_type_id',
      label: strings.filters?.projectType || 'Filter by Project Type',
      values: sortArrayBy(Object.values(projectTypes), 'asc', 'title').map(projectType => ({
        key: projectType.id,
        label: projectType.title,
      })),
    },
    {
      component: 'DynamicInput',
      componentProps: {
        dateFormat: 'DD/MM/YYYY',
        dateParseFormat: 'YYYY-MM-DD',
        fieldName: 'created_date_start',
        inputType: 'date',
        style,
      },
      key: 'created_date_start',
      label: strings.filters?.createDateBefore || 'Created Before',
    },
    {
      component: 'DynamicInput',
      componentProps: {
        dateFormat: 'DD/MM/YYYY',
        dateParseFormat: 'YYYY-MM-DD',
        fieldName: 'created_date_end',
        inputType: 'date',
        style,
      },
      key: 'created_date_end',
      label: strings.filters?.createDateAfter || 'Created After',
    },
    {
      entityKey: 'limit',
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: '50',
        inputComponent: 'select',
        style,
      },
      key: 'limit',
      label: strings.filters?.limit || 'Filter by Limit',
      values: [
        { key: 100, label: '100' },
        { key: 150, label: '150' },
        { key: 200, label: '200' },
      ],
    },
  ]
}

const createProject = (projectParams, customFields, createFn) => {
  createFn(projectParams, customFields).then(({ success, errors, redirectUrl }) => {
    if (!success){
      toast.warn(errors[0])
      return
    }

    if (redirectUrl){
      window.location.href = redirectUrl
    }
  })
}

const importProject = (externalId, integrationPlatformKey, organizationId, importFn, requestOptions) => {
  importFn(externalId, integrationPlatformKey, organizationId, requestOptions).then(
    ({ success, errors, redirectUrl }) => {
      if (!success){
        toast.warn(errors[0])
        return
      }

      if (redirectUrl){
        window.location.href = redirectUrl
      }
    },
  )
}

const callbacks = (component, setState) => {
  const componentCallbacks = {
    CreateOrEditAddressModal: {
      closeModal: () => setState({ showCreateOrEditAddressModal: false }),
      createAddress: payload => handleCallbackAction(payload),
      updateAddress: payload => handleCallbackAction(payload),
    },
    CreateProjectModal: {
      closeModal: () => setState({ showCreateProjectModal: false }),
      importProject,
      submitAction: (projectParams, customFields, createFn) => {
        createProject(projectParams, customFields, createFn)
      },
    },
  }

  return componentCallbacks[component] || {}
}
const defaultState = {
  showCreateOrEditAddressModal: false,
  showCreateProjectModal: false,
}

const PageContent = (props) => {
  const { pageFilters } = props

  const [state, setState] = useSetState(defaultState)

  const { showCreateOrEditAddressModal, showCreateProjectModal } = state
  // useLocation to refresh the window.location. If not used location won't reload if URL changes
  useLocation()

  const { status } = parsePermittedQueryParams(getQueryParamsFromHash(), ['status'])

  const { loading } = useReduxAction(
    'projects',
    'loadProjects',
    {
      ...defaultRequestOptions.project,
      ...pageFilters,
      status,
    },
    [status, JSON.stringify(pageFilters)],
  )
  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext
  const filterContext = useContext(PageContext)

  const pageContext = useMemo(() => (
    {
      callbacks: {
        showCreateOrEditAddressModal: (payload) => {
          setModalData('CreateOrEditAddressModal', payload)
          setState({ showCreateOrEditAddressModal: true })
        },
      },
    }
  ), [])

  const {
    callbacks: { resetFilters, updateFilters },
  } = filterContext

  const { filteredProjects, hasFilteredProjects } = useProjects({
    filters: {
      status,
      ...pageFilters,
    },
  })

  useEffect(() => resetFilters(), [status])

  const entities = useSelector(reduxState => reduxState.entities)

  const videoModulePayload = useVideoModules({ key: 'projectDashboard' })
  const { videosPayload } = videoModulePayload

  const { overviewArchived, overviewCurrent } = generateUrls()

  const { strings } = useLocalization(localizedStrings)

  const { callbacks: { dispatchMixpanelEvent } } = useMixpanel()

  useEffect(() => {
    dispatchMixpanelEvent('Project Overview Load')
  }, [])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageHeader
          actionContent={(
            <Button
              buttonStyle="primaryCreate"
              onClick={() => setState({ showCreateProjectModal: true })}
              size="medium"
              style={{ marginRight: 8, width: 'auto' }}
            >
              {strings.createText || 'Create'}
            </Button>
          )}
          activeTabBarItemKey={status || 'current'}
          boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
          tabBarItems={[
            {
              href: overviewCurrent,
              icon: faHome,
              key: 'current',
              title: strings.tabs?.current || 'Current',
            },
            {
              href: overviewArchived,
              icon: faArchive,
              key: 'archived',
              title: strings.tabs?.archived || 'Archived',
            },
          ]}
          title={strings.title || 'Projects'}
        />

        <Box paddingX="large" paddingTop={[112, 105]}>
          <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
            <Columns.Main>
              <LoadingModule loading={loading} times={3} />
              {!hasFilteredProjects && !loading && <ProjectsBlankState />}

              {!loading && hasFilteredProjects && (
                <DashboardModule title={strings.title || 'Projects'}>
                  <Box flexDirection="column">
                    {filteredProjects.map(project => (
                      <Project key={project.id} project={project} />
                    ))}
                  </Box>
                </DashboardModule>
              )}
            </Columns.Main>

            <Columns.Sidebar>
              <PageFilterModule
                callbacks={{ resetFilters, updateFilters }}
                filterFields={buildPageFilterFields(entities, strings)}
                pageFilters={pageFilters}
                title={strings.filters?.filtersTitle || 'Filters'}
              />

              <VideoModule videosPayload={videosPayload} />
            </Columns.Sidebar>
          </Columns>
        </Box>

        <CreateProjectModal
          callbacks={callbacks('CreateProjectModal', setState)}
          showModal={showCreateProjectModal}
        />

        <CreateOrEditAddressModal
          callbacks={callbacks('CreateOrEditAddressModal', setState)}
          headerTitle={strings.addressModalHeaderTitle || 'Project'}
          hiddenFields={['title']}
          showModal={showCreateOrEditAddressModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

PageContent.propTypes = {
  pageFilters: PropTypes.object.isRequired,
}

export default PageContent
