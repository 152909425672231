export default {
  default: {
    toast: {
      agreement: {
        created: 'Agreement Created.',
        voided: 'Agreement Voided.',
      },
      contact: {
        created: 'Contact Created Successfully.',
        deleted: 'Contact Deleted Successfully.',
        updated: 'Contact Updated Successfully.',
      },
      project: {
        updated: 'Updated Successfully.',
      },
    },
  },
}
