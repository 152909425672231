export type AwardModel = {
  cache_key: number,
  id: number,
  owner_id: number,
  owner_type: string,
  subject_id?: number,
  subject_type?: string,
  title: string,
}

export type AwardRequestOptions = {
  include_award_default_image?: boolean,
  include_award_data_store_items?: boolean,
}

const state = {
  id: null,
  body: '',
  owner_id: null,
  owner_type: '',
  subject_id: null,
  subject_type: '',
  title: '',
}

export const requiredFields = [
  { key: 'owner_id' },
  { key: 'owner_type' },
  { key: 'subject_id' },
  { key: 'subject_type' },
  { key: 'title' },
]

export default state
