import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import { Box, ListItem } from '@campaignhub/suit-theme'

import useFormResponse from '@hooks/useFormResponse'
import useLocalization from '@hooks/useLocalization'

import FormResponseFields from '../FormResponseFields'
import localizedStrings from './localizedStrings'

const FormResponse = (props) => {
  const { formResponse } = props

  const {
    dates: {
      created: { date_time_with_timezone },
    },
  } = formResponse

  const formResponsePayload = useFormResponse(formResponse)
  const {
    callbacks: { editFormResponse },
    digitalTemplate: { title },
    formResponseValues,
  } = formResponsePayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <ListItem
      boxProps={{
        as: 'a',
        alignItems: 'center',
        borderBottom: '1px solid',
        padding: 'large',
      }}
      onClick={() => editFormResponse()}
      showAngleIcon
    >
      <Box flexDirection="column">
        <Box alignItems="center" color="bodyFontLightColor" flexDirection={['column', 'row']} marginBottom="small">
          <Box fontSize="small" marginBottom={['medium', '0']}>{title}</Box>

          <Box fontSize="xsmall" style={{ whiteSpace: 'nowrap' }} width="auto">
            {`${strings.received || 'Received'} ${DateTime.fromISO(date_time_with_timezone).toFormat('ff a')}`}
          </Box>
        </Box>

        <Box flexDirection={['column-reverse', 'row']}>
          <Box flexDirection="column">
            <FormResponseFields responseFields={formResponseValues} />
          </Box>
        </Box>
      </Box>
    </ListItem>
  )
}

FormResponse.propTypes = {
  formResponse: PropTypes.object,
}

export default FormResponse
