import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { digObject, sortArrayBy } from '@campaignhub/javascript-utils'
import { useThunkDispatch } from '@campaignhub/react-hooks'

import { AppDispatch } from '@redux/store'
import type { OrganizationModel, UserModel } from '@models/types'

import * as organizationActions from '@redux/modules/organization'

import useCurrentUser from '@hooks/useCurrentUser'
import useAddress from './useAddress'

const selectOrganizationId = (organizationId: number, dispatch: AppDispatch) => {
  const { selectOrganizationId: selectFn } = organizationActions
  return dispatch(selectFn(organizationId))
}

const autoSelectAdminOrganization = (
  organizationsWhereAdminIds: number[],
  currentUser: UserModel,
  dispatch: AppDispatch,
) => {
  const { default_organization_id } = currentUser

  if (!organizationsWhereAdminIds.length){
    return
  }

  // If user is admin of default Organization, select that
  if (default_organization_id && organizationsWhereAdminIds.includes(default_organization_id)){
    selectOrganizationId(default_organization_id, dispatch)
    return
  }

  // Select first admin Organization ID
  selectOrganizationId(organizationsWhereAdminIds[0], dispatch)
}

const autoSelectOrganization = (
  organizations: OrganizationModel[],
  defaultOrganizationId: number,
  dispatch: AppDispatch,
) => {
  // Ensure default exists before selecting
  let defaultOrganization = {}

  if (defaultOrganizationId){
    defaultOrganization = organizations.find(organization => organization.id === defaultOrganizationId) || {}
  }

  const organizationId = defaultOrganization.id || digObject(organizations[0], 'id')

  selectOrganizationId(organizationId, dispatch)
}

type UseOrganizationSelectorOptions = {
  autoSelect?: boolean,
  autoSelectAdmin?: boolean,
}

function useOrganizationSelector(options: UseOrganizationSelectorOptions = {}) {
  const { autoSelect, autoSelectAdmin } = options

  const dispatch = useThunkDispatch()

  const { organizations, organizationTypes, projectTypes } = useSelector(reduxState => reduxState.entities)
  const { loading: loadingOrganizations, selectedId } = useSelector(state => state.organizations)

  const { currentUser, loaded: currentUserLoaded, organizationsWhereAdminIds } = useCurrentUser()
  const { default_organization_id } = currentUser || {}

  const selectedOrganization: OrganizationModel = organizations[selectedId] || {}
  const sortedOrganizations: OrganizationModel[] = sortArrayBy(Object.values(organizations), 'asc', 'title')
  const sortedOrganizationsWhereAdmin = sortedOrganizations.filter(
    organization => organizationsWhereAdminIds.includes(organization.id),
  )

  useEffect(() => {
    if (autoSelect && currentUserLoaded && !selectedId && sortedOrganizations.length){
      autoSelectOrganization(sortedOrganizations, default_organization_id, dispatch)
    }
  }, [sortedOrganizations.length, currentUserLoaded, autoSelect])

  useEffect(() => {
    if (autoSelectAdmin && selectedOrganization.id && !organizationsWhereAdminIds.includes(selectedOrganization.id)){
      autoSelectAdminOrganization(organizationsWhereAdminIds, currentUser, dispatch)
    }
  }, [autoSelectAdmin, selectedOrganization.id])

  const organizationType = organizationTypes[selectedOrganization.organization_type_id] || {}

  const defaultProjectType = projectTypes[organizationType.default_project_type_id] || {}

  const isSelectedOrganizationAdmin = organizationsWhereAdminIds.includes(selectedOrganization.id)

  const { address } = useAddress({ id: selectedOrganization?.default_address_id })

  return {
    callbacks: {
      selectOrganizationId: (id: number) => selectOrganizationId(id, dispatch),
    },
    defaultProjectType,
    hasMultipleOrganizations: sortedOrganizations.length > 1,
    hasMultipleOrganizationsWhereAdmin: sortedOrganizationsWhereAdmin.length > 1,
    isSelectedOrganizationAdmin,
    loadingOrganizations,
    organizationType,
    organizations,
    selectedId,
    selectedOrganization,
    selectedOrganizationAddress: address,
    sortedOrganizations,
    sortedOrganizationsWhereAdmin,
  }
}

export default useOrganizationSelector
