import React from 'react'
import PropTypes from 'prop-types'

import useNumericParams from '@hooks/useNumericParams'

import DocumentTemplateBuilder from '@components/DocumentTemplateBuilder'

const DocumentBuilder = () => {
  const { documentTemplateId } = useNumericParams()

  return (
    <DocumentTemplateBuilder documentTemplateId={documentTemplateId} />
  )
}

DocumentBuilder.propTypes = {}

export default DocumentBuilder
