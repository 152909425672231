import { useMemo } from 'react'

import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

import { useModals, useSetState } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import handleCallbackAction from '@functions/handleCallbackAction'

import CreateOrEditAddressModal from '@modals/CreateOrEditAddressModal'
import CreateOrEditContactModal from '@modals/CreateOrEditContactModal'
import CreateOrEditTeamModal from '@modals/CreateOrEditTeamModal'
import EditAttachmentModal from '@modals/EditAttachmentModal'
import EditUserModal from '@modals/EditUserModal'
import ManageProjectAttachmentsModal from '@modals/ManageProjectAttachmentsModal'
import ManageProjectContactsModal from '@modals/ManageProjectContactsModal'
import ManageProjectExternalPlatformsModal from '@modals/ManageProjectExternalPlatformsModal'
import ManageProjectTeamsModal from '@modals/ManageProjectTeamsModal'
import ManageProjectUsersModal from '@modals/ManageProjectUsersModal'

import defaultRequestOptions from '@sections/Client/packs/Project/defaultRequestOptions'

import useLocalization from '@hooks/useLocalization'

import PageContent from './components/PageContent'
import localizedStrings from './localizedStrings'

const deleteAttachment = (deleteFn, setState, strings) => {
  deleteFn().then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    toast(strings.attachmentDeleted || 'Document Deleted Successfully')
    setState({ showEditAttachmentModal: false })
  })
}

const updateAttachment = (attachmentParams, customFields, updateFn, setState, strings) => {
  updateFn(attachmentParams, customFields, defaultRequestOptions.attachment).then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    toast(strings.attachmentUpdated || 'Document Updated Successfully')
    setState({ showEditAttachmentModal: false })
  })
}

const updateProject = (params, customFields, updateKey, updateFn, setState, strings) => {
  updateFn(params, customFields, {
    ...defaultRequestOptions.contact,
    ...defaultRequestOptions.project,
    ...defaultRequestOptions.team,
  }).then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    toast(`${updateKey} ${strings.toast?.project?.updated}` || 'Updated Successfully')
    setState({
      showManageProjectExternalPlatformsModal: false,
      showManageProjectContactsModal: false,
      showManageProjectTeamsModal: false,
      showManageProjectUsersModal: false,
    })
  })
}

const callbacks = (component, setState, strings) => {
  const componentCallbacks = {
    CreateOrEditAddressModal: {
      closeModal: () => setState({ showCreateOrEditAddressModal: false }),
      createAddress: payload => handleCallbackAction(payload),
      updateAddress: payload => handleCallbackAction(payload),
    },
    CreateOrEditContactModal: {
      closeModal: () => setState({ showCreateOrEditContactModal: false }),
      createContact: payload => handleCallbackAction({ ...payload, requestOptions: defaultRequestOptions.contact }),
      deleteContact: payload => handleCallbackAction({ ...payload, requestOptions: defaultRequestOptions.contact }),
      updateContact: payload => handleCallbackAction({ ...payload, requestOptions: defaultRequestOptions.contact }),
    },
    CreateOrEditTeamModal: {
      closeModal: () => setState({ showCreateOrEditTeamModal: false }),
      createTeam: payload => handleCallbackAction(payload),
      updateTeam: payload => handleCallbackAction(payload),
    },
    EditAttachmentModal: {
      closeModal: () => setState({ showEditAttachmentModal: false }),
      deleteAttachment: deleteFn => deleteAttachment(deleteFn, setState, strings),
      updateAttachment: (params, customFields, updateFn) => updateAttachment(params, customFields, updateFn, setState, strings),
    },
    EditUserModal: {
      closeModal: () => setState({ showEditUserModal: false }),
      updateUser: payload => handleCallbackAction({ ...payload, requestOptions: defaultRequestOptions.user }),
    },
    ManageProjectAttachmentsModal: {
      closeModal: () => setState({ showManageProjectAttachmentsModal: false }),
    },
    ManageProjectContactsModal: {
      closeModal: () => setState({ showManageProjectContactsModal: false }),
      updateProject: (params, updateKey, updateFn) => updateProject(params, [], updateKey, updateFn, setState, strings),
    },
    ManageProjectExternalPlatformsModal: {
      closeModal: () => setState({ showManageProjectExternalPlatformsModal: false }),
      updateProject: (params, updateKey, updateFn) => updateProject(params, [], updateKey, updateFn, setState, strings),
    },
    ManageProjectTeamsModal: {
      closeModal: () => setState({ showManageProjectTeamsModal: false }),
      updateProject: (params, updateKey, updateFn) => updateProject(params, [], updateKey, updateFn, setState, strings),
    },
    ManageProjectUsersModal: {
      closeModal: () => setState({ showManageProjectUsersModal: false }),
      updateProject: (params, updateKey, updateFn) => updateProject(params, [], updateKey, updateFn, setState, strings),
    },
    PageContent: {
      updateProject: (params, customFields, updateFn) => updateProject(params, customFields, 'Project', updateFn, setState, strings),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  showCreateOrEditAddressModal: false,
  showEditAttachmentModal: false,
  showCreateOrEditContactModal: false,
  showCreateOrEditTeamModal: false,
  showEditUserModal: false,
  showManageProjectAttachmentsModal: false,
  showManageProjectContactsModal: false,
  showManageProjectExternalPlatformsModal: false,
  showManageProjectTeamsModal: false,
  showManageProjectUsersModal: false,
}

const Details = (props) => {
  const { projectId } = props

  const [state, setState] = useSetState(defaultState)
  const {
    showCreateOrEditAddressModal,
    showEditAttachmentModal,
    showCreateOrEditContactModal,
    showCreateOrEditTeamModal,
    showEditUserModal,
    showManageProjectAttachmentsModal,
    showManageProjectContactsModal,
    showManageProjectExternalPlatformsModal,
    showManageProjectTeamsModal,
    showManageProjectUsersModal,
  } = state

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const pageContext = useMemo(
    () => ({
      callbacks: {
        showCreateOrEditAddressModal: (payload) => {
          setModalData('CreateOrEditAddressModal', payload)
          setState({ showCreateOrEditAddressModal: true })
        },
        showCreateOrEditContactModal: (payload) => {
          setModalData('CreateOrEditContactModal', payload)
          setState({ showCreateOrEditContactModal: true })
        },
        showCreateOrEditTeamModal: (payload) => {
          setModalData('CreateOrEditTeamModal', payload)
          setState({ showCreateOrEditTeamModal: true })
        },
        showEditAttachmentModal: (payload) => {
          setModalData('EditAttachmentModal', payload)
          setState({ showEditAttachmentModal: true })
        },
        showEditUserModal: (payload) => {
          setModalData('EditUserModal', payload)
          setState({ showEditUserModal: true })
        },
        showManageProjectAttachmentsModal: (payload) => {
          setModalData('ManageProjectAttachmentsModal', payload)
          setState({ showManageProjectAttachmentsModal: true })
        },
        showManageProjectContactsModal: (payload) => {
          setModalData('ManageProjectContactsModal', payload)
          setState({ showManageProjectContactsModal: true })
        },
        showManageProjectExternalPlatformsModal: (payload) => {
          setModalData('ManageProjectExternalPlatformsModal', payload)
          setState({ showManageProjectExternalPlatformsModal: true })
        },
        showManageProjectTeamsModal: (payload) => {
          setModalData('ManageProjectTeamsModal', payload)
          setState({ showManageProjectTeamsModal: true })
        },
        showManageProjectUsersModal: (payload) => {
          setModalData('ManageProjectUsersModal', payload)
          setState({ showManageProjectUsersModal: true })
        },
      },
    }),
    [modalContext],
  )

  const { strings } = useLocalization(localizedStrings)

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent callbacks={callbacks('PageContent', setState, strings)} projectId={projectId} />

        <CreateOrEditAddressModal
          callbacks={callbacks('CreateOrEditAddressModal', setState, strings)}
          hiddenFields={['title']}
          showModal={showCreateOrEditAddressModal}
        />

        <CreateOrEditContactModal
          callbacks={callbacks('CreateOrEditContactModal', setState, strings)}
          showModal={showCreateOrEditContactModal}
        />

        <CreateOrEditTeamModal
          callbacks={callbacks('CreateOrEditTeamModal', setState, strings)}
          showModal={showCreateOrEditTeamModal}
        />

        <EditAttachmentModal
          callbacks={callbacks('EditAttachmentModal', setState, strings)}
          showModal={showEditAttachmentModal}
        />

        <EditUserModal callbacks={callbacks('EditUserModal', setState, strings)} showModal={showEditUserModal} />

        <ManageProjectAttachmentsModal
          callbacks={callbacks('ManageProjectAttachmentsModal', setState, strings)}
          project={{ id: projectId }}
          showModal={showManageProjectAttachmentsModal}
        />

        <ManageProjectContactsModal
          callbacks={callbacks('ManageProjectContactsModal', setState, strings)}
          project={{ id: projectId }}
          showModal={showManageProjectContactsModal}
        />

        <ManageProjectExternalPlatformsModal
          callbacks={callbacks('ManageProjectExternalPlatformsModal', setState, strings)}
          project={{ id: projectId }}
          showModal={showManageProjectExternalPlatformsModal}
        />

        <ManageProjectTeamsModal
          callbacks={callbacks('ManageProjectTeamsModal', setState, strings)}
          project={{ id: projectId }}
          showModal={showManageProjectTeamsModal}
        />

        <ManageProjectUsersModal
          callbacks={callbacks('ManageProjectUsersModal', setState, strings)}
          project={{ id: projectId }}
          showModal={showManageProjectUsersModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

Details.propTypes = {
  projectId: PropTypes.number.isRequired,
}

export default Details
