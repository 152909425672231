import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faToggleOn, faToggleOff } from '@fortawesome/pro-light-svg-icons'

import { LoadingBubbles } from '@campaignhub/suit-theme'

import ToggleableSectionWrapper from '@components/digitalRenderer/components/ToggleableSectionWrapper'

import styles from './styles.module.scss'

const renderToggle = (params) => {
  const {
    editing, enabled, toggleSection, updating,
  } = params

  if (editing && updating){
    return (
      <span className={styles.toggle}>
        <LoadingBubbles />
      </span>
    )
  }

  if (editing){
    return (
      <a className={styles.toggle} onClick={() => toggleSection()} tabIndex="0">
        {enabled ? 'Enabled' : 'Disabled'}
        <FontAwesomeIcon icon={enabled ? faToggleOn : faToggleOff} className={styles.icon} />
      </a>
    )
  }

  return null
}

const ToggleableSection = (props) => {
  const {
    children, isSidebar, isToggleable, sectionId,
  } = props

  return (
    <ToggleableSectionWrapper
      sectionId={sectionId}
      render={params => (
        <div className={classnames(
          styles.root,
          isSidebar ? styles.sidebar : null,
          !params.enabled ? styles.disabled : null,
        )}
        >
          {isToggleable && renderToggle(params)}
          {children}
        </div>
      )}
    />
  )
}

ToggleableSection.propTypes = {
  children: PropTypes.node.isRequired,
  isSidebar: PropTypes.bool,
  isToggleable: PropTypes.bool,
  sectionId: PropTypes.string.isRequired,
}

ToggleableSection.defaultProps = {
  isToggleable: true,
}

export default ToggleableSection
