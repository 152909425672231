import { useContext } from 'react'
import PropTypes from 'prop-types'

import {
  Box, Button, FormField, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import { useSetState } from '@campaignhub/react-hooks'

import { digObject } from '@campaignhub/javascript-utils'

import useCustomFieldSet from '@hooks/useCustomFieldSet'

import EntitySelector from '@components/EntitySelector'

import defaultRequestOptions from '@sections/Admin/packs/SystemManager/defaultRequestOptions'

const defaultState = {
  ownerId: null,
  ownerType: 'Brand',
}

const DuplicateCustomFieldSetModal = (props) => {
  const { callbacks, modalKey, showModal } = props
  const { closeModal, duplicateCustomFieldSetForOwner } = callbacks || {}

  const [state, setState] = useSetState(defaultState)
  const { ownerId, ownerType } = state

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const { customFieldSet: initCustomFieldSet } = digObject(modalData, `${modalKey}`, {})

  const {
    callbacks: { duplicateCustomFieldSetForOwner: duplicateFn },
    creating,
    customFieldSet: { entity_type, key, title },
  } = useCustomFieldSet(initCustomFieldSet)

  const duplicateCustomFieldSetPayload = {
    callbacks: {
      action: duplicateFn,
      afterAction: closeModal,
    },
    entityParams: state,
    requestOptions: defaultRequestOptions.customFieldSet,
    shouldRedirect: true,
    toastText: 'Custom Field Set Duplicated Successfully',
  }

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header callbacks={callbacks} title="Duplicate" titleSecondLine="Custom Field Set" />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <>
            <FormField direction="column" boxProps={{ marginBottom: 'large' }} label="Owner Type">
              <select
                name="ownerType"
                value={ownerType}
                onChange={e => setState({ ownerType: e.target.value, ownerId: null })}
                data-validate-field-on="change"
              >
                <option value="Organization">Organization</option>
                <option value="Brand">Brand</option>
              </select>
            </FormField>

            <EntitySelector
              boxProps={{ marginBottom: 'large' }}
              callbacks={{
                selectItem: entity => setState({ ownerId: entity ? entity.id : null }),
              }}
              entityKey={ownerType === 'Brand' ? 'brands' : 'organizations'}
              selectedItemId={ownerId}
            />
          </>

          <FormField direction="column" boxProps={{ marginBottom: 'large' }} label="Field Set Title">
            <input
              disabled
              data-validate-field-on="change"
              name="title"
              type="text"
              value={title}
            />
          </FormField>

          <FormField direction="column" boxProps={{ marginBottom: 'large' }} label="Key">
            <input disabled name="key" type="text" value={key || ''} />
          </FormField>

          <FormField direction="column" boxProps={{ marginBottom: 'large' }} label="Entity Type">
            <input disabled name="key" type="text" value={entity_type} />
          </FormField>
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!ownerId || !ownerType}
          loading={creating}
          onClick={() => duplicateCustomFieldSetForOwner(duplicateCustomFieldSetPayload)}
          size="large"
        >
          Duplicate
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

DuplicateCustomFieldSetModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

DuplicateCustomFieldSetModal.defaultProps = {
  modalKey: 'DuplicateCustomFieldSetModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <DuplicateCustomFieldSetModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
