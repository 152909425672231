import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faPencil } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Image, Link, ListItem, Text,
} from '@campaignhub/suit-theme'

import { createStreetAddressString } from '@hooks/useAddress'

const ModalAddress = (props) => {
  const {
    address,
    address: { region_name },
    callbacks: { editAddress },
    disableEdit,
  } = props

  const streetAddress = createStreetAddressString(address)

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        paddingBottom: 'large',
      }}
      disableHover
    >
      <Image boxProps={{ backgroundColor: 'hoverLightGrey', fontSize: 'small' }} circle height={30} width={30}>
        <FontAwesomeIcon icon={faBuilding} />
      </Image>

      <Box flexDirection="column" marginLeft="large">
        <Text fontSize="small" marginBottom="small">
          {streetAddress}
        </Text>

        <Text color="faintGrey" fontSize="xsmall">
          {region_name}
        </Text>
      </Box>

      {!disableEdit && (
      <Link greyLink onClick={editAddress} textProps={{ fontSize: 'small' }}>
        <FontAwesomeIcon icon={faPencil} />
      </Link>
      )}
    </ListItem>
  )
}

ModalAddress.propTypes = {
  address: PropTypes.object.isRequired,
  callbacks: PropTypes.object,
  disableEdit: PropTypes.bool,
}

export default ModalAddress
