import { useContext } from 'react'
import PropTypes from 'prop-types'

import swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, FormField, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import { useSetState } from '@campaignhub/react-hooks'
import useCurrentUser from '@hooks/useCurrentUser'

import useDigitalTemplate from '@hooks/useDigitalTemplate'
import useDigitalTemplateComponent, { useDigitalTemplateComponentForm } from '@hooks/useDigitalTemplateComponent'
import useNumericParams from '@hooks/useNumericParams'

import EntitySelector from '@components/EntitySelector'
import FieldHolder from '@components/FieldHolder'

const confirmUpdateComponentStyle = (params) => {
  const { entityState, updateDigitalTemplateComponent, updateFn } = params
  swal
    .fire({
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Yes, Update it.',
      icon: 'warning',
      showCancelButton: true,
      text: 'Are you sure? This component style is being used in more than one template',
      title: 'Update Component Style?',
      input: 'text',
      inputPlaceholder: 'Please add reason for updating.',
      inputValidator: (value) => {
        if (!value){
          return 'Please add reason for updating.'
        }

        return null
      },
    })
    .then(({ value }) => {
      if (value){
        updateDigitalTemplateComponent(entityState, updateFn, { action_reason: value })
      }
    })
}

const createOrUpdateComponent = (params) => {
  const {
    createFn, entityState, needUpdateConfirmation, props, updateFn,
  } = params
  const {
    callbacks: { createDigitalTemplateComponentStyle, updateDigitalTemplateComponent },
  } = props

  if (entityState.id){
    if (needUpdateConfirmation) return confirmUpdateComponentStyle({ entityState, updateDigitalTemplateComponent, updateFn })

    return updateDigitalTemplateComponent(entityState, updateFn)
  }

  return createDigitalTemplateComponentStyle(entityState, createFn)
}

const defaultState = {
  targetDevice: 'default',
}

const CreateOrEditComponentStyleModal = (props) => {
  const { callbacks, modalKey, showModal } = props

  const { forkDigitalTemplateComponent } = callbacks || {}

  const { digitalTemplateId } = useNumericParams()

  const [state, setState] = useSetState(defaultState)
  const { targetDevice } = state

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const { digitalTemplateComponent = {} } = digObject(modalData, `${modalKey}`, {})
  const { digitalTemplate } = digObject(modalData, 'EditDigitalTemplateModal', {})

  const digitalTemplatePayload = useDigitalTemplate(digitalTemplate)
  const {
    callbacks: { forkDigitalTemplateComponent: forkFn },
  } = digitalTemplatePayload

  const {
    callbacks: { createDigitalTemplateComponentStyle: createFn, updateDigitalTemplateComponent: updateFn },
    creating,
    updating,
    needUpdateConfirmation,
  } = useDigitalTemplateComponent(digitalTemplateComponent)

  const digitalTemplateComponentForm = useDigitalTemplateComponentForm(digitalTemplateComponent)
  const {
    entityState,
    entityState: {
      brand_id,
      data: { style, type },
      description,
      id: componentStyleId,
      locked,
      title,
    },
    handlers,
    saveEnabled,
    setEntityState,
  } = digitalTemplateComponentForm

  const { isAdmin, isMasterAdmin } = useCurrentUser()

  const componentStyle = style[targetDevice] || {}
  const {
    backgroundColor,
    color,
    fontFamily,
    fontSize,
    fontStyle,
    fontWeight,
    gridColumnGap,
    gridRowGap,
    gridTemplateColumns,
    gridTemplateRows,
    letterSpacing,
    limit,
    lineHeight,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    textAlign,
    textTransform,
  } = componentStyle

  const isEditMode = !!digitalTemplateComponent.id

  const editingDisabled = locked && !isMasterAdmin

  const shouldShowOverlayAndAnimation = !!digitalTemplateId

  return (
    <SidebarModal
      callbacks={callbacks}
      disableAnimation={shouldShowOverlayAndAnimation}
      disableOverlay={shouldShowOverlayAndAnimation}
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header
        callbacks={callbacks}
        title={isEditMode ? 'Edit' : 'Create'}
        titleSecondLine={isEditMode ? `Component Style #${componentStyleId}` : 'Component Style'}
      />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          {isAdmin && (
            <EntitySelector
              boxProps={{ marginBottom: 'large' }}
              callbacks={{
                selectItem: brand => setEntityState({ brand_id: brand ? brand.id : null }),
              }}
              disabled={editingDisabled}
              entityKey="brands"
              selectedItemId={Number(brand_id)}
            />
          )}

          <FormField direction="column" boxProps={{ marginBottom: 'large' }} label="Type">
            <select disabled={editingDisabled} name="data.type" value={type} {...handlers}>
              <option value="">Default</option>
              <option value="grid">Grid</option>
            </select>
          </FormField>

          <FormField direction="column" boxProps={{ marginBottom: 'large' }} label="Locked">
            <select disabled={editingDisabled} data-value-type="boolean" name="locked" value={locked} {...handlers} />
          </FormField>

          <FormField
            direction="column"
            boxProps={{ marginBottom: 'large' }}
            data-validate-field-on="change"
            label="* Title"
          >
            <input disabled={editingDisabled} name="title" type="text" value={title} {...handlers} />
          </FormField>

          <FormField direction="column" boxProps={{ marginBottom: 'large' }} label="Description">
            <textarea disabled={editingDisabled} name="description" type="text" value={description} {...handlers} />
          </FormField>

          <FormField direction="column" boxProps={{ marginBottom: 'large' }} label="Device Style">
            <select
              disabled={editingDisabled}
              onChange={e => setState({ targetDevice: e.target.value })}
              value={targetDevice}
            >
              <option value="default">Desktop</option>
              <option value="mobile">Mobile</option>
              <option value="tablet">Tablet</option>
              <option value="print">Print</option>
            </select>
          </FormField>

          {type !== 'grid' && (
            <>
              <Box flexDirection="row" flexShrink={0}>
                <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth label="Font Family">
                  <input
                    disabled={editingDisabled}
                    name={`data.style.${targetDevice}.fontFamily`}
                    type="text"
                    value={fontFamily || ''}
                    {...handlers}
                  />
                </FieldHolder>

                <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth label="Font Size" last>
                  <input
                    disabled={editingDisabled}
                    name={`data.style.${targetDevice}.fontSize`}
                    type="text"
                    value={fontSize || ''}
                    {...handlers}
                  />
                </FieldHolder>
              </Box>

              <Box flexDirection="row" flexShrink={0}>
                <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth label="Font Weight">
                  <input
                    disabled={editingDisabled}
                    name={`data.style.${targetDevice}.fontWeight`}
                    type="text"
                    value={fontWeight || ''}
                    {...handlers}
                  />
                </FieldHolder>

                <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth label="Color" last>
                  <input
                    disabled={editingDisabled}
                    name={`data.style.${targetDevice}.color`}
                    type="text"
                    value={color || ''}
                    {...handlers}
                  />
                </FieldHolder>
              </Box>

              <Box flexDirection="row" flexShrink={0}>
                <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth label="Line Height">
                  <input
                    disabled={editingDisabled}
                    name={`data.style.${targetDevice}.lineHeight`}
                    type="text"
                    value={lineHeight || ''}
                    {...handlers}
                  />
                </FieldHolder>

                <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth label="Letter Spacing" last>
                  <input
                    disabled={editingDisabled}
                    name={`data.style.${targetDevice}.letterSpacing`}
                    type="text"
                    value={letterSpacing || ''}
                    {...handlers}
                  />
                </FieldHolder>
              </Box>

              <Box flexDirection="row" flexShrink={0}>
                <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth label="Text Align">
                  <input
                    disabled={editingDisabled}
                    name={`data.style.${targetDevice}.textAlign`}
                    type="text"
                    value={textAlign || ''}
                    {...handlers}
                  />
                </FieldHolder>

                <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth label="Text Transform" last>
                  <input
                    disabled={editingDisabled}
                    name={`data.style.${targetDevice}.textTransform`}
                    type="text"
                    value={textTransform || ''}
                    {...handlers}
                  />
                </FieldHolder>
              </Box>

              <Box flexDirection="row" flexShrink={0}>
                <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth label="Font Style">
                  <input
                    disabled={editingDisabled}
                    name={`data.style.${targetDevice}.fontStyle`}
                    type="text"
                    value={fontStyle || ''}
                    {...handlers}
                  />
                </FieldHolder>

                <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth label="Background Color" last>
                  <input
                    disabled={editingDisabled}
                    name={`data.style.${targetDevice}.backgroundColor`}
                    type="text"
                    value={backgroundColor || ''}
                    {...handlers}
                  />
                </FieldHolder>
              </Box>

              <Box flexDirection="row" flexShrink={0}>
                <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth label="Padding Top">
                  <input
                    disabled={editingDisabled}
                    name={`data.style.${targetDevice}.paddingTop`}
                    type="text"
                    value={paddingTop || ''}
                    {...handlers}
                  />
                </FieldHolder>

                <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth last label="Padding Right">
                  <input
                    disabled={editingDisabled}
                    name={`data.style.${targetDevice}.paddingRight`}
                    type="text"
                    value={paddingRight || ''}
                    {...handlers}
                  />
                </FieldHolder>
              </Box>

              <Box flexDirection="row" flexShrink={0}>
                <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth label="Padding Bottom">
                  <input
                    disabled={editingDisabled}
                    name={`data.style.${targetDevice}.paddingBottom`}
                    type="text"
                    value={paddingBottom || ''}
                    {...handlers}
                  />
                </FieldHolder>

                <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth last label="Padding Left">
                  <input
                    disabled={editingDisabled}
                    name={`data.style.${targetDevice}.paddingLeft`}
                    type="text"
                    value={paddingLeft || ''}
                    {...handlers}
                  />
                </FieldHolder>
              </Box>
            </>
          )}

          {type === 'grid' && (
            <>
              <Box flexDirection="row" flexShrink={0}>
                <FieldHolder boxProps={{ marginBottom: 'large' }} label="Limit">
                  <input
                    disabled={editingDisabled}
                    name={`data.style.${targetDevice}.limit`}
                    type="number"
                    value={limit || ''}
                    {...handlers}
                  />
                </FieldHolder>
              </Box>

              <Box flexDirection="row" flexShrink={0}>
                <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth label="Grid Template Rows">
                  <input
                    disabled={editingDisabled}
                    name={`data.style.${targetDevice}.gridTemplateRows`}
                    type="text"
                    value={gridTemplateRows || ''}
                    {...handlers}
                  />
                </FieldHolder>

                <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth last label="Grid Template Columns">
                  <input
                    disabled={editingDisabled}
                    name={`data.style.${targetDevice}.gridTemplateColumns`}
                    type="text"
                    value={gridTemplateColumns || ''}
                    {...handlers}
                  />
                </FieldHolder>
              </Box>

              <Box flexDirection="row" flexShrink={0}>
                <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth label="Grid Row Gap">
                  <input
                    disabled={editingDisabled}
                    name={`data.style.${targetDevice}.gridRowGap`}
                    type="text"
                    value={gridRowGap || ''}
                    {...handlers}
                  />
                </FieldHolder>

                <FieldHolder boxProps={{ marginBottom: 'large' }} halfWidth last label="Grid Column Gap">
                  <input
                    disabled={editingDisabled}
                    name={`data.style.${targetDevice}.gridColumnGap`}
                    type="text"
                    value={gridColumnGap || ''}
                    {...handlers}
                  />
                </FieldHolder>
              </Box>
            </>
          )}

          {isEditMode && (
            <FormField direction="column" label="More Options">
              <Button
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faCopy} />}
                onClick={() => forkDigitalTemplateComponent(entityState, forkFn)}
                size="medium"
              >
                Clone Component Style
              </Button>
            </FormField>
          )}
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle={isEditMode ? 'primaryEdit' : 'primaryCreate'}
          disabled={!saveEnabled}
          loading={creating || updating}
          onClick={() => createOrUpdateComponent({
            createFn, entityState, needUpdateConfirmation, props, updateFn,
          })}
          size="large"
        >
          {isEditMode ? 'Update Component Style' : 'Create Component Style'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

CreateOrEditComponentStyleModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

CreateOrEditComponentStyleModal.defaultProps = {
  modalKey: 'CreateOrEditComponentStyleModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <CreateOrEditComponentStyleModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
