export default {
  default: {
    buttonText: {
      close: 'Close',
    },
    formFieldLabels: {
      imageSource: 'Image Source',
      imageUpload: 'an Image',
    },
    selectBoxOptions: {
      comparable: 'Comparable',
      imageLibrary: 'Image Library',
      organization: '{global.organizationType.shortName.singular}',
      project: 'Project',
      user: 'User',
    },
    sideBarModalHeader: {
      title: 'Select',
      titleSecondLine: 'Image',
    },
    swal: {
      title: 'Delete Image?',
      text: 'Are you sure?',
      confirmButtonText: 'Yes, delete it.',
    },
    viewMode: {
      small: 'View Small Tiles',
      large: 'View Large Tiles',
    },
  },
}
