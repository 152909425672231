import React from 'react'
import PropTypes from 'prop-types'

import { Search as SearchBase } from '@campaignhub/suit-theme'

import searchEndpoint from '@functions/search'

const Search = (props) => {
  const { callbacks, ...restProps } = props

  return (
    <SearchBase
      callbacks={{ ...callbacks, search: searchEndpoint }}
      {...restProps}
    />
  )
}

Search.propTypes = {
  boxProps: PropTypes.object,
  callbacks: PropTypes.shape({
    mapResults: PropTypes.func.isRequired,
    selectItem: PropTypes.func.isRequired,
  }).isRequired,
  debounceInterval: PropTypes.number,
  endpoint: PropTypes.string,
  forwardProps: PropTypes.object,
  placeholder: PropTypes.string,
  requestOptions: PropTypes.object,
}

export default Search
