export default {
  default: {
    title: {
      title: 'Digital Pages',
    },
    labelTitle: {
      label: 'Title',
    },
    status: {
      label: 'Status',
    },
    statusValues: {
      all: 'All Statuses',
      accepted: 'Accepted',
      pending: 'Pending',
      processed: 'Processed',
      complete: 'Complete',
      sent: 'Sent',
      rejected: 'Rejected',
    },
    displayMode: {
      label: 'Display Mode',
    },
    displayModeValues: {
      labelListCompact: 'List (Compact)',
      labelListLarge: 'List (Large)',
      labelTile: 'Tiles',
    },
    limit: {
      label: 'Limit',
    },
    blankState: {
      title: 'No Digital Pages Created',
      paragraphOne: 'Digital pages can be used to collect and store information, present property and agency information to customers and to build agreements and websites.',
      paragraphTwo: 'Agreements, Forms and Homepages and can be located in the Tools section in the project menu.',
    },
    manage: 'Manage',

  },
}
