import { useEffect, useState } from 'react'
import Uppy from '@uppy/core'
import AwsS3 from '@uppy/aws-s3'

import { useThunkDispatch } from '@campaignhub/react-hooks'

import api from '@functions/api'

import { processUploadedPdf } from '@redux/modules/documentTemplate'

import { defaultRequestOptions } from './useDocumentTemplateBuilder'

import '@uppy/core/dist/style.css'
import '@uppy/drag-drop/dist/style.css'

const initializeUppy = (documentTemplate, dispatch, options = {}) => {
  const requestOptions = {
    ...defaultRequestOptions.documentTemplate,
    ...defaultRequestOptions.documentTemplatePage,
  }

  const uppy = new Uppy({
    autoProceed: true,
    debug: false,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ['.pdf'],
    },
  })
    .use(AwsS3, {
      getUploadParameters(file){
        const uploadRequestOptions = {
          content_type: file.type,
          file_name: file.name,
        }

        const config = {
          method: 'GET',
        }

        // Send off request to API -> Shrine -> S3 to give us back upload params
        return api('attachments/presign', uploadRequestOptions, config)
          .then(response => response?.data)
      },
    })
    .on('upload-success', (file) => {
      const attachment = {
        file: {
          id: file.meta.key.match(/cache\/\w+\/(.+)/)[1], // object key without prefix,
          storage: 'cache',
          metadata: {
            size: file.size,
            filename: file.name,
            mime_type: file.type,
          },
        },
        file_name: file.name,
        file_size: file.size,
        file_type: file.type,
        title: file.name,
      }

      dispatch(processUploadedPdf(documentTemplate, attachment, requestOptions))
    })

  return uppy
}

function useDocumentTemplateFileUpload(documentTemplate, options = {}, deps = []){
  const [uppy, setUppy] = useState(undefined)

  const dispatch = useThunkDispatch()

  useEffect(() => {
    setUppy(initializeUppy(documentTemplate, dispatch, options))

    return () => {
      if (uppy){
        uppy.close()
        setUppy(undefined)
      }
    }
  }, [...deps])

  return uppy
}

export default useDocumentTemplateFileUpload
