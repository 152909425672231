import React from 'react'
import PropTypes from 'prop-types'

import { DashboardGuideModule } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import guideImageUrl from '../../assets/onboarding-header.svg'

import localizedStrings from './localizedStrings'

const Header = (props) => {
  const {
    onboardingTasksPayload: { incompleteTasksCount, userName },
  } = props

  const {
    callbacks: { formatCount },
    strings,
  } = useLocalization(localizedStrings)

  return (
    <DashboardGuideModule
      boxProps={{
        border: 0,
        borderColor: 'lineColor',
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        borderRadius: 0,
      }}
      imageUrl={guideImageUrl}
    >
      <DashboardGuideModule.Title>{strings?.titles?.welcome || 'Welcome to Engage'}</DashboardGuideModule.Title>

      <DashboardGuideModule.Paragraph>
        {formatCount('stepsToComplete', { count: incompleteTasksCount, name: userName }) || `Welcome ${userName}`}
      </DashboardGuideModule.Paragraph>
    </DashboardGuideModule>
  )
}

Header.propTypes = {
  onboardingTasksPayload: PropTypes.shape({
    userName: PropTypes.string,
    incompleteTasksCount: PropTypes.number,
  }).isRequired,
}

export default Header
