import {
  Box, LoadingModule, Text,
} from '@campaignhub/suit-theme'

import EntitySelector from '@components/EntitySelector'

import useComparables from '@hooks/useComparables'
import useLocalization from '@hooks/useLocalization'

import CloneComparableResult from '../CloneComparableResult'

import localizedStrings from './localizedStrings'

interface CloneComparablesProps {
  projectId: number,
  useFindComparablesPayload: {
    callbacks: {
      setModalState: Function,
    },
    cloneFromProjectId: number,
  },
}

const CloneComparables = (props: CloneComparablesProps) => {
  const { projectId, useFindComparablesPayload } = props

  const {
    callbacks: {
      setModalState,
    },
    cloneFromProjectId,
  } = useFindComparablesPayload

  const useTargetComparablesPayload = useComparables({
    filters: {
      projectId,
    },
  })
  const { filteredComparables, loading } = useTargetComparablesPayload

  const useSourceComparablesPayload = useComparables({
    filters: {
      projectId: cloneFromProjectId,
    },
  })
  const {
    hasComparables: hasSourceProjectComparables,
    filteredComparables: sourceProjectComparables,
  } = useSourceComparablesPayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <Box flexDirection="column">
      <EntitySelector
        boxProps={{ marginBottom: 'large' }}
        callbacks={{
          selectItem: project => setModalState({ cloneFromProjectId: project?.id }),
        }}
        entityKey="projects"
        selectedItemId={cloneFromProjectId}
      />

      <LoadingModule boxProps={{ marginTop: 'large' }} loading={loading} times={3} />

      {!!cloneFromProjectId && !hasSourceProjectComparables && !loading && (
      <Box flexDirection="column">
        <Text color="faintGrey" fontSize="small" marginBottom="large" marginTop="medium">
          {strings.noAvailableComparables || 'No Available Comparables'}
        </Text>
      </Box>
      )}

      {!!cloneFromProjectId && hasSourceProjectComparables && !loading && (
      <Box flexDirection="column">
        {sourceProjectComparables.map(sourceProjectComparable => (
          <CloneComparableResult
            filteredComparables={filteredComparables}
            sourceComparable={sourceProjectComparable}
            targetProjectId={projectId}
          />
        ))}
      </Box>
      )}
    </Box>
  )
}

export default CloneComparables
