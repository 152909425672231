import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { digObject } from '@campaignhub/javascript-utils'
import { useSetState, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useDocumentTemplate from '@hooks/useDocumentTemplate'
import useReduxAction from '@hooks/useReduxAction'

import calculateOptimalZoom from '../functions/calculateOptimalZoom'

const watchEntityKeys = ['documentTemplatePages', 'documentTemplates']

const availableZooms = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.25, 2.5, 2.75, 3, 4, 5, 6, 10]

export const defaultRequestOptions = {
  documentTemplate: {
    include_document_template_page_preview_image_sizes: ['thumb_2000x1500'],
    include_document_template_page_preview_images: true,
    include_document_template_pages: true,
    include_document_template_recipients: true,
  },
  documentTemplatePage: {
    include_document_template_page_items: true,
  },
}

const changeZoom = (zoom, setState) => {
  setState({
    zoom,
  })
}

const performZoomAction = (actionType, state, setState) => {
  const { zoom } = state

  const index = availableZooms.indexOf(zoom)
  const newIndex = actionType === 'increase' ? index + 1 : index - 1

  const newZoom = availableZooms[newIndex] || zoom

  changeZoom(newZoom, setState)
}

const defaultState = {
  selectedTemplatePageId: null,
  selectedTemplatePageItemId: null,
  zoom: 1,
}

function useDocumentTemplateBuilder(options = {}){
  const { callbacks, documentTemplateId } = options || {}
  const { closeModal, showEditDocumentTemplateFieldModal } = callbacks || {}

  const [state, setState] = useSetState(defaultState)
  const { selectedTemplatePageId, selectedTemplatePageItemId, zoom } = state

  const {
    updatedEntities: {
      documentTemplatePages: documentTemplatePagesUpdatedAt,
      documentTemplates: documentTemplatesUpdatedAt,
    },
  } = useWatchEntityUpdates(watchEntityKeys)

  // Load Document Template
  useReduxAction(
    'documentTemplates',
    'loadDocumentTemplate',
    { ...defaultRequestOptions.documentTemplate, ...defaultRequestOptions.documentTemplatePage },
    [documentTemplateId],
    {
      dispatchAction: (action, requestOptions) => action(documentTemplateId, requestOptions),
      shouldPerformFn: (entityReducer) => {
        const { loadedIds } = entityReducer
        return documentTemplateId && !loadedIds.includes(documentTemplateId)
      },
    },
  )

  const { updating } = useSelector(reduxState => reduxState.documentTemplates)

  const entities = useSelector(reduxState => reduxState.entities)
  const { documentTemplatePages } = entities

  const selectedDocumentTemplatePage = documentTemplatePages[selectedTemplatePageId] || {}
  const { height, width } = selectedDocumentTemplatePage

  const initDocumentTemplate = { id: documentTemplateId }

  const documentTemplatePayload = useDocumentTemplate(initDocumentTemplate)
  const { filteredPages } = documentTemplatePayload

  // Auto Select First Page
  useEffect(() => {
    if (!selectedTemplatePageId && filteredPages.length){
      setState({
        selectedTemplatePageId: digObject(filteredPages, '0.id'),
      })
    }
  }, [documentTemplateId, documentTemplatesUpdatedAt, documentTemplatePagesUpdatedAt])

  // Show Edit Page Item Modal
  useEffect(() => {
    if (selectedTemplatePageItemId && showEditDocumentTemplateFieldModal){
      showEditDocumentTemplateFieldModal()
    }

    if (!selectedTemplatePageItemId && closeModal){
      closeModal('EditDocumentTemplateFieldModal')
    }
  }, [selectedTemplatePageItemId])

  return {
    availableZooms,
    callbacks: {
      performZoomAction: actionType => performZoomAction(actionType, state, setState),
      selectTemplatePageItemId: templatePageItemId => setState({ selectedTemplatePageItemId: templatePageItemId }),
      selectZoomValue: value => changeZoom(value, setState),
      zoomToBestFit: () => changeZoom(calculateOptimalZoom(height, width), setState),
      ...callbacks,
    },
    documentTemplatePayload,
    selectedDocumentTemplatePage,
    selectedTemplatePageId,
    selectedTemplatePageItemId,
    updating,
    zoom,
  }
}

export default useDocumentTemplateBuilder
