import { useEffect, useMemo } from 'react'

import { useSetState } from '@campaignhub/react-hooks'

import PageContext from '@contexts/pageContext'

import useBrandSelector from '@hooks/useBrandSelector'
import useCurrentUser from '@hooks/useCurrentUser'

import useMixpanel from '@hooks/useMixpanel'
import useOrganizationSelector from '@hooks/useOrganizationSelector'

import handleCallbackAction from '@functions/handleCallbackAction'

import CreateFormTemplateModal from '@modals/CreateFormTemplateModal'

import PageContent from './components/PageContent'

const callbacks = (component, setState) => {
  const componentCallbacks = {
    CreateFormTemplateModal: {
      closeModal: () => setState({ showCreateFormTemplateModal: false }),
      createFormTemplate: payloads => handleCallbackAction(payloads),
      duplicateFormTemplate: payloads => handleCallbackAction(payloads),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  pageFilters: {},
  showCreateFormTemplateModal: false,
}

const FormTemplates = () => {
  const [state, setState] = useSetState(defaultState)
  const { pageFilters, showCreateFormTemplateModal } = state

  const { selectedOrganization } = useOrganizationSelector()

  const { selectedBrand } = useBrandSelector({ autoSelect: true })

  useEffect(() => {
    if (selectedBrand.length === 1){
      setState({ pageFilters: { brand_id: selectedBrand?.id } })
    }
  }, [selectedBrand.length])

  const pageContext = useMemo(
    () => ({
      callbacks: {
        resetFilters: (resetFn) => {
          if (resetFn) resetFn()
          setState({ pageFilters: {} })
        },
        showCreateFormTemplateModal: () => setState({ showCreateFormTemplateModal: true }),
        updateFilters: (filters) => {
          setState({ pageFilters: filters })
        },
      },
    }),
    [],
  )

  const { isBrandUser, currentUser } = useCurrentUser()
  const { brand: userBrand } = currentUser

  const owner_id = isBrandUser ? userBrand.id : selectedOrganization.id || {}
  const owner_type = isBrandUser ? 'Brand' : ''

  // Tracks the page load event
  const { callbacks: { dispatchMixpanelEvent } } = useMixpanel()
  useEffect(() => {
    if (isBrandUser) dispatchMixpanelEvent('Form Templates Load')
  }, [])

  return (
    <PageContext.Provider value={pageContext}>
      <PageContent pageFilters={pageFilters} selectedOrganization={selectedOrganization} />

      <CreateFormTemplateModal
        callbacks={callbacks('CreateFormTemplateModal', setState)}
        ownerId={owner_id}
        ownerType={owner_type}
        showModal={showCreateFormTemplateModal}
      />
    </PageContext.Provider>
  )
}

export default FormTemplates
