import { useMemo } from 'react'

import { useModals, useSetState } from '@campaignhub/react-hooks'
import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import useReduxAction from '@hooks/useReduxAction'
import handleCallbackAction from '@functions/handleCallbackAction'

import EditUserSortModal from '@modals/EditUserSortModal'
import ImportUsersModal from '@modals/ImportUsersModal'

import defaultRequestOptions from '@sections/Client/packs/Admin/defaultRequestOptions'

import PageContent from './components/PageContent'

const callbacks = (component, setState) => {
  const componentCallbacks = {
    EditUserSortModal: {
      closeModal: () => setState({ showEditUserSortModal: false }),
      updateUserSort: payload => handleCallbackAction({ ...payload, requestOptions: defaultRequestOptions.organization }),
    },
    ImportUsersModal: {
      closeModal: () => setState({ showImportUsersModal: false }),
      importUsers: payload => handleCallbackAction(payload),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  pageFilters: {},
  showEditUserSortModal: false,
  showImportUsersModal: false,
}

const UsersList = () => {
  const [state, setState] = useSetState(defaultState)
  const { pageFilters, showEditUserSortModal, showImportUsersModal } = state

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  useReduxAction(
    'users',
    'loadUsers',
    { ...defaultRequestOptions.image, ...defaultRequestOptions.user, ...pageFilters },
    [JSON.stringify(pageFilters)],
    {
      shouldPerformFn: (entityReducer) => {
        const { errors, loaded, loading } = entityReducer
        return !loaded && !loading && !errors.length
      },
    },
  )

  const pageContext = useMemo(
    () => ({
      callbacks: {
        resetFilters: (resetFn) => {
          if (resetFn) resetFn()
          setState({ pageFilters: {} })
        },
        showCreateOrganizationModal: () => setState({ showCreateOrganizationModal: true }),
        showEditUserSortModal: (payload) => {
          setModalData('EditUserSortModal', payload)
          setState({ showEditUserSortModal: true })
        },
        showImportUsersModal: () => setState({ showImportUsersModal: true }),
        updateFilters: (filters) => {
          setState({ pageFilters: filters })
        },
      },
    }),
    [],
  )

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent pageFilters={pageFilters} />

        <EditUserSortModal callbacks={callbacks('EditUserSortModal', setState)} showModal={showEditUserSortModal} />

        <ImportUsersModal callbacks={callbacks('ImportUsersModal', setState)} showModal={showImportUsersModal} />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default UsersList
