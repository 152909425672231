import PropTypes from 'prop-types'

import { Box, DashboardModule, FormField } from '@campaignhub/suit-theme'

import useCurrentUser from '@hooks/useCurrentUser'

import countryCallingCodes from '@functions/countryCallingCodes'

const UserDetails = (props) => {
  const { userForm } = props

  const {
    entityState: {
      country_calling_code, email, first_name, job_title, last_name, mobile, realbase_type, realbase_id,
    },
    errors,
    handlers,
  } = userForm

  const { isAdmin } = useCurrentUser()

  return (
    <DashboardModule boxProps={{ marginBottom: 'large' }} title="User Details">
      <Box flexDirection="column" padding="large">
        <Box flexDirection={['column', 'row']} marginBottom={[0, 'large']}>
          <FormField
            label="* First Name"
            boxProps={{ flex: 1, paddingBottom: ['large', 0], marginRight: [0, 'medium'] }}
          >
            <input type="text" value={first_name} name="first_name" disabled {...handlers} />
          </FormField>

          <FormField label="* Last Name" boxProps={{ flex: 1 }}>
            <input type="text" value={last_name} name="last_name" disabled {...handlers} />
          </FormField>
        </Box>

        <Box flexDirection={['column', 'row']}>
          <Box width={['100%', 'calc(50% - 4px)']} marginBottom="medium" marginRight={[0, 'medium']}>
            <FormField label="Mobile" boxProps={{ flex: 1 }}>
              <Box style={{ width: '100%', marginRight: 'small', flexDirection: 'row' }}>
                <select
                  name="country_calling_code"
                  disabled
                  style={{ marginRight: 5 }}
                  value={country_calling_code || '+61'}
                  {...handlers}
                >
                  <option value="">Please Select...</option>

                  {countryCallingCodes.map(({ key, value, title }) => (
                    <option key={key} value={value}>
                      {title}
                    </option>
                  ))}
                </select>

                <FormField style={{ width: '70%' }}>
                  <input type="text" value={mobile} name="mobile" disabled {...handlers} />
                </FormField>
              </Box>
            </FormField>
          </Box>

          <FormField label="Job Title" boxProps={{ flex: 1 }}>
            <input type="text" value={job_title} name="job_title" disabled {...handlers} />
          </FormField>
        </Box>

        <FormField boxProps={{ flex: 1, marginBottom: 'large' }} label="* Email">
          <input type="text" value={email} name="email" disabled {...handlers} />
        </FormField>

        <Box flexDirection={['column', 'row']}>
          <FormField
            label="Realbase Type"
            errorMessage={errors.realbase_type}
            boxProps={{ flex: 1, paddingBottom: ['large', 0], marginRight: [0, 'medium'] }}
          >
            <select
              data-validate-field-on="change"
              disabled={!isAdmin}
              name="realbase_type"
              value={realbase_type}
              {...handlers}
            >
              <option value="">Please Select...</option>
              <option key="user" value="RealbaseResource::User">
                User
              </option>
            </select>
          </FormField>

          <FormField label="Realbase ID" boxProps={{ flex: 1 }}>
            <input disabled={!isAdmin} name="realbase_id" type="text" value={realbase_id} {...handlers} />
          </FormField>
        </Box>
      </Box>
    </DashboardModule>
  )
}

UserDetails.propTypes = {
  userForm: PropTypes.object.isRequired,
}

export default UserDetails
