import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage } from '@fortawesome/pro-light-svg-icons'

import { SidebarNoticeBox } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from '../../localizedStrings'

const SortableNoticeBox = () => {
  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarNoticeBox boxProps={{ height: 'auto', marginBottom: 'medium' }}>
      <SidebarNoticeBox.Title>
        <FontAwesomeIcon icon={faImage} /> {strings.sideBarNoticeBox.title || 'Change Image Sequence'}
      </SidebarNoticeBox.Title>

      <SidebarNoticeBox.Paragraph>
        {strings.sideBarNoticeBox.description
        // eslint-disable-next-line max-len
        || 'Drag and drop images in the order of your choice to resequence them. Top 20 images will show on the proposal.'}
      </SidebarNoticeBox.Paragraph>
    </SidebarNoticeBox>
  )
}
export default SortableNoticeBox
