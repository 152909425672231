import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faDownload } from '@fortawesome/pro-light-svg-icons'

import {
  Button, IconWithMenu, Link, ListItemWithImage, StatusBadge, Text,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'
import useQuote from '@hooks/useQuote'

import { QuoteModel } from '@models/quote'

import localizedStrings from './localizedStrings'

interface QuoteListItemProps {
  quote: QuoteModel,
}

const QuoteListItem = (props: QuoteListItemProps) => {
  const { quote } = props

  const {
    callbacks: { downloadPdf },
    externalId,
    integrationPlatform,
    isExternal,
    quote: {
      dates: {
        updated: { date_time_with_timezone },
      },
      title,
      total,
    },
    urls: { editQuoteUrl },
  } = useQuote(quote)

  const {
    callbacks: { formatCurrency, formatDate, formatString },
    strings,
  } = useLocalization(localizedStrings, { integrationPlatform })

  return (
    <ListItemWithImage>
      <ListItemWithImage.Header href={editQuoteUrl} marginRight="0" showAngleIcon>
        <ListItemWithImage.HeaderLeft>
          <ListItemWithImage.HeaderText>{title}</ListItemWithImage.HeaderText>
          <Text color="bodyFontLightColor" fontSize="xsmall">
            {formatDate(date_time_with_timezone)}
          </Text>
        </ListItemWithImage.HeaderLeft>

        <ListItemWithImage.HeaderRight>
          <StatusBadge color="orange" ghost text="Draft" />
        </ListItemWithImage.HeaderRight>
      </ListItemWithImage.Header>

      <ListItemWithImage.Footer>
        {isExternal && (
          <ListItemWithImage.FooterLeft>
            <ListItemWithImage.FooterText boxProps={{ color: 'mysteryGrey' }}>
              {formatString(strings.importedFrom, { externalId })}
            </ListItemWithImage.FooterText>
          </ListItemWithImage.FooterLeft>
        )}

        <ListItemWithImage.FooterRight>
          <Text marginRight="large">{formatCurrency(total)}</Text>

          {isExternal && (
            <IconWithMenu
              icon={(
                <Button
                  buttonStyle="secondaryUtility"
                  icon={<FontAwesomeIcon icon={faCog} />}
                  size="medium"
                  height={37}
                />
              )}
              style={{ marginLeft: 'auto', width: 'auto' }}
            >
              <IconWithMenu.Menu closeOnClick listStyle={{ right: '-7px' }}>
                <Link key="preview-edit" onClick={() => downloadPdf()}>
                  <FontAwesomeIcon icon={faDownload} /> {strings.menu?.downloadPdf}
                </Link>
              </IconWithMenu.Menu>
            </IconWithMenu>
          )}
        </ListItemWithImage.FooterRight>
      </ListItemWithImage.Footer>
    </ListItemWithImage>
  )
}

export default QuoteListItem
