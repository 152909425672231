import PropTypes from 'prop-types'

import { faArchive } from '@fortawesome/pro-light-svg-icons'

import { Box, DashboardModule, Link } from '@campaignhub/suit-theme'

import useAttachments from '@hooks/useAttachments'
import useLocalization from '@hooks/useLocalization'
import useOrganizationSelector from '@hooks/useOrganizationSelector'
import useProject from '@hooks/useProject'

import CompactList from './CompactList'
import LargeList from './LargeList'

import documentBlank from './assets/documents.svg'

import localizedStrings from './localizedStrings'

export const icon = faArchive

export const configOptions = strings => ({
  title: {
    component: 'input',
    key: 'title',
    label: strings.labelTitle?.title || 'Title',
    optionKey: 'customization',
  },
  display_mode: {
    component: 'select',
    key: 'display_mode',
    label: strings.displayMode?.label || 'Display Mode',
    optionKey: 'customization',
    values: [
      { key: 'compact-list', label: strings.displayModeValues?.labelListCompact || 'List (Compact)' },
      { key: 'large-list', label: strings.displayModeValues?.labelListLarge || 'List (Large)' },
    ],
  },
  limit: {
    component: 'select',
    key: 'limit',
    label: strings.limit?.label || 'Limit',
    optionKey: 'queryParams',
    values: [
      { key: 5, label: '5' },
      { key: 10, label: '10' },
      { key: 25, label: '25' },
      { key: 50, label: '50' },
    ],
  },
})

const requestOptions = {
  include_attachment_data_store_items: true,
  include_attachment_file: true,
}

const Documents = (props) => {
  const {
    customOptions,
    customOptions: { queryParams: { limit, subject_id: subjectId, subject_type: subjectType } },
    editing,
    parentId,
  } = props

  const { selectedOrganization } = useOrganizationSelector()

  const attachmentsPayload = useAttachments(
    {
      filters: {
        limit,
        ownerId: selectedOrganization.id,
        ownerType: selectedOrganization.type,
        subjectId,
        subjectType,
      },
      requestOptions,
      performHttpRequests: !!parentId,
    },
  )
  const { filteredAttachments, hasAttachments } = attachmentsPayload

  const projectPayload = useProject({ id: subjectId })
  const {
    project,
    urls: { documentsUrl },
  } = projectPayload

  const { customization } = customOptions
  const { display_mode } = customization || {}

  const { strings } = useLocalization(localizedStrings)

  return (
    <DashboardModule
      boxProps={{ marginBottom: 0 }}
      editing={editing}
      headerRight={(
        <Link
          href={documentsUrl}
          textProps={{ fontSize: 'small' }}
        >
          {strings.manage || 'Manage'}
        </Link>
      )}
      title={strings.title?.title || 'Files'}
    >
      {!hasAttachments && (
        <DashboardModule.BlankState imageUrl={documentBlank}>
          <DashboardModule.BlankState.Title>
            {strings.blankState?.title || 'No Files Uploaded'}
          </DashboardModule.BlankState.Title>

          <DashboardModule.BlankState.Paragraph>
            {strings.blankState?.paragraph || 'You don’t have any files stored against this project. You can upload files and use them in digital pages to send more information to clients easily.'}
          </DashboardModule.BlankState.Paragraph>
        </DashboardModule.BlankState>
      )}

      {hasAttachments && (
        <Box flexDirection="column" flexWrap="wrap">
          {filteredAttachments.map((attachment) => {
            const ListComponent = display_mode === 'compact-list' ? CompactList : LargeList

            return <ListComponent key={attachment.id} attachment={attachment} project={project} />
          })}
        </Box>
      )}
    </DashboardModule>
  )
}

Documents.propTypes = {
  customOptions: PropTypes.object.isRequired,
  editing: PropTypes.bool,
  parentId: PropTypes.string,
}

export default Documents
