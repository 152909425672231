import useSelector from '@hooks/useSelector'

import type { ActivityModel } from '@models/types'

export const generateUrls = (activity?: Partial<ActivityModel>) => {
  const { id } = activity || {}

  return {
    downloadActivityUrl: `/activities/${id}/export_data.json`,
  }
}

export const useRelations = (activity: Partial<ActivityModel> = {}) => {
  const { user_id } = activity

  const { users } = useSelector(reduxState => reduxState.entities)

  const user = user_id ? users[user_id] || {} : {}

  return {
    user,
  }
}

function useActivity(activity: Partial<ActivityModel> = {}) {
  const { key } = activity

  const action = key?.split('.')[1]
  const color = action === 'deleted' ? 'red' : 'green'

  const { user } = useRelations(activity)

  return {
    action,
    color,
    urls: generateUrls(activity),
    user,
  }
}

export default useActivity
