const state = {
  id: null,
  owner_id: null,
  owner_type: 'Organization',
  production_ready: false,
  title: '',
}

export const requiredFields = [
  { key: 'owner_id' },
  { key: 'owner_type' },
  { key: 'title' },
]

export default state
