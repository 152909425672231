import React from 'react'
import PropTypes from 'prop-types'

import { Box, ListItem, Text } from '@campaignhub/suit-theme'

import { useRelations } from '@hooks/useOrganization'

import generateRedirectUrl from '@functions/generateRedirectUrl'

const OrganizationListItem = (props) => {
  const { organization } = props
  const { id, title, type } = organization

  const { brand } = useRelations(organization)
  const { title: brandTitle } = brand

  return (
    <ListItem
      boxProps={{ as: 'a', borderBottom: '1px dashed', padding: 'large' }}
      href={`#/systemManager/organizations/${id}/edit?redirect=${generateRedirectUrl()}`}
      showAngleIcon
    >
      <Box justifyContent="flex-start" minWidth="0" paddingRight="medium" width="auto">
        <Text fontSize="small" variant="ellipsis">
          {title}
        </Text>
      </Box>
      <Box display={['none', 'flex']} justifyContent="flex-end" marginLeft="auto" width="auto">
        <Text color="bodyFontLightColor" fontSize="small" lineHeight={1.3} style={{ whiteSpace: 'nowrap' }}>
          {brandTitle || type}
        </Text>
      </Box>
    </ListItem>
  )
}

OrganizationListItem.propTypes = {
  organization: PropTypes.object.isRequired,
}

export default OrganizationListItem
