import { useEffect } from 'react'
import { useSetState } from '@campaignhub/react-hooks'
import { deepSetObject, digObject } from '@campaignhub/javascript-utils'

import generateID from '@functions/generateID'

const setupRenderConditionGroups = (renderConditions) => {
  const conditionGroup = renderConditions.reduce((acc, renderCondition, index) => {
    const isAnArrayOfConditions = Array.isArray(renderCondition)

    if (isAnArrayOfConditions){
      acc[`${generateID()}${index}`] = renderCondition
    } else {
      acc[generateID()] = renderConditions
    }

    return acc
  }, {})

  return conditionGroup
}

const buildRenderConditionsArray = (renderConditionGroups) => {
  const renderConditionArray = Object.keys(renderConditionGroups).reduce((acc, conditionGroupKey) => {
    acc.push(renderConditionGroups[conditionGroupKey])

    return acc
  }, [])

  return renderConditionArray
}

const addCondition = (params) => {
  const {
    condition: { operator, string, value },
    groupKey,
    selectedEntity,
    setState,
    state: { renderConditionGroups },
    updateParam,
  } = params

  const updatedOptions = { ...selectedEntity.options }

  const renderConditionGroup = renderConditionGroups[groupKey] || {}
  const updatedRenderConditionGroup = [...renderConditionGroup]

  updatedRenderConditionGroup.push({
    id: generateID(),
    operator,
    string,
    value,
  })

  const updatedRenderConditionGroups = deepSetObject(renderConditionGroups, groupKey, updatedRenderConditionGroup) || {}
  setState({ renderConditionGroups: updatedRenderConditionGroups })

  updatedOptions.renderConditions = buildRenderConditionsArray(updatedRenderConditionGroups)

  updateParam('options', updatedOptions)
}

const deleteCondition = (params) => {
  const {
    condition,
    groupKey,
    selectedEntity,
    setState,
    state: { renderConditionGroups },
    updateParam,
  } = params

  const updatedOptions = { ...selectedEntity.options }
  const updatedConditions = [...updatedOptions.renderConditions]

  const renderConditionGroup = renderConditionGroups[groupKey] || {}
  const updatedRenderConditionGroup = [...renderConditionGroup]

  // Find item and remove from condition group
  const conditionIndex = updatedRenderConditionGroup.findIndex(c => c.id === condition.id)
  updatedRenderConditionGroup.splice(conditionIndex, 1)

  // Find existing group in render conditions and replace with updated group
  const groupIndex = updatedConditions.indexOf(renderConditionGroup)
  updatedConditions.splice(groupIndex, 1)
  updatedConditions.push(updatedRenderConditionGroup)

  setState({ renderConditionGroups: deepSetObject(renderConditionGroups, groupKey, updatedRenderConditionGroup) })

  updatedOptions.renderConditions = updatedConditions

  updateParam('options', updatedOptions)
}

const deleteGroup = (params) => {
  const {
    groupKey, state: { renderConditionGroups }, setState, selectedEntity, updateParam,
  } = params

  const updatedOptions = { ...selectedEntity.options }
  const updatedConditions = [...updatedOptions.renderConditions]

  const renderConditionGroup = renderConditionGroups[groupKey] || {}

  // Find existing group in render conditions and remove it
  const groupIndex = updatedConditions.indexOf(renderConditionGroup)
  updatedConditions.splice(groupIndex, 1)

  // Delete from state object
  const updatedrenderConditionGroups = { ...renderConditionGroups }
  delete updatedrenderConditionGroups[groupKey]

  setState({ renderConditionGroups: updatedrenderConditionGroups })

  updatedOptions.renderConditions = updatedConditions

  updateParam('options', updatedOptions)
}

const defaultState = {
  renderConditionGroups: {},
}

function useRenderConditionGroups(options = {}){
  const {
    selectedEntity,
    callbacks: { updateParam },
  } = options || {}

  const [state, setState] = useSetState(defaultState)
  const { renderConditionGroups } = state

  const entityOptions = digObject(selectedEntity, 'options', {})
  const renderConditions = digObject(entityOptions, 'renderConditions', [])

  useEffect(() => {
    if (selectedEntity.id){
      setState({ renderConditionGroups: setupRenderConditionGroups(renderConditions) })
    }
  }, [selectedEntity.id])

  return {
    callbacks: {
      addCondition: (condition, groupKey) => addCondition({
        condition,
        groupKey,
        selectedEntity,
        updateParam,
        state,
        setState,
      }),
      createConditionGroup: () => setState({
        renderConditionGroups: deepSetObject(renderConditionGroups, generateID(), []),
      }),
      deleteGroup: groupKey => deleteGroup({
        groupKey,
        selectedEntity,
        setState,
        state,
        updateParam,
      }),
      deleteCondition: (condition, groupKey) => deleteCondition({
        groupKey,
        selectedEntity,
        condition,
        updateParam,
        state,
        setState,
      }),
      setState,
    },
    renderConditionGroups,
  }
}

export default useRenderConditionGroups
