import { Box, Text, Image } from '@campaignhub/suit-theme'

import AboutIntegrationItem from './components/AboutIntegrationItem'

import imageUrl from './assets/rtaEngage.png'

const items = [
  {
    description: `Avoid delays and go straight from accepted proposal
    to preparing an agreement in seconds! This streamlined process 
    not only saves admin time but also helps close deals quicker.`,
    iconKey: 'savesYouTime',
    title: 'Saves you time',
  },
  {
    description: `RTA will allow you to add and manage your RealHub quotes. 
    You can edit quote line items and update the total cost, straight from 
    the RTA application.`,
    iconKey: 'marketingQuotes',
    title: 'Includes your marketing quotes',
  },
  {
    description: `The RTA and Engage integration can be set up by anyone,
    and can be done in under a minute. 
    This means you could have Agreement documents created in a matter of minutes.`,
    iconKey: 'setUp',
    title: 'Easy to set up',
  },
]

const AboutIntegration = () => (
  <Box marginTop="xlarge" flexDirection="column" borderRadius={5} backgroundColor="backgroundColor" padding="large">
    <Text fontSize="xlarge">About this integration</Text>

    <Box flexDirection={['column', 'row']} alignItems="center" justifyContent="space-between">
      <Box flexDirection="column" width={['100%', 'calc(100% - 432px)']}>
        {items.map(item => (
          <AboutIntegrationItem
            description={item.description}
            iconKey={item.iconKey}
            key={item.title}
            title={item.title}
          />
        ))}
      </Box>

      <Image
        height={170}
        borderRadius={5}
        width={['100%', '400px']}
        backgroundSize="contain"
        borderRadius={5}
        url={imageUrl}
      />
    </Box>
  </Box>
)

export default AboutIntegration
