import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import { Box, Link, ListItem } from '@campaignhub/suit-theme'

interface IntegrationPlatformProps {
  callbacks: {
    deselectIntegrationPlatform: () => void,
  },
  selectedIntegrationPlatform: {
    id: number,
    text: string,
  },
}

const IntegrationPlatform = (props: IntegrationPlatformProps) => {
  const { callbacks, selectedIntegrationPlatform } = props
  const { id, text } = selectedIntegrationPlatform

  const { deselectIntegrationPlatform } = callbacks || {}

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        paddingY: 'large',
      }}
      disableHover
      key={id}
    >
      <Box color="bodyFontLightColor" fontSize="small">
        {text}
      </Box>

      <Link
        greyLink
        onClick={() => deselectIntegrationPlatform()}
        textProps={{ fontSize: 'small' }}
      >
        <FontAwesomeIcon icon={faTrashAlt} />
      </Link>
    </ListItem>
  )
}

export default IntegrationPlatform
