import { useContext, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import swal from 'sweetalert2'
import { toast } from 'react-toastify'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendarAlt, faChartBar, faLink, faShare, faTrashAlt,
} from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, FormField, Line, ModalContext, SidebarModal, Text,
} from '@campaignhub/suit-theme'

import { useCopyInputValue } from '@campaignhub/react-hooks'
import { digObject } from '@campaignhub/javascript-utils'

import useDigitalPage from '@hooks/useDigitalPage'
import useLocalization from '@hooks/useLocalization'
import useVideoModules from '@hooks/useVideoModules'

import NotificationRecipients from '@components/ModalComponents/NotificationRecipients'
import ToggleButton from '@components/ToggleButton'
import VideoModule from '@components/VideoModule'

import useShareLink, { useShareLinkForm } from '@hooks/useShareLink'
import useShareLinks from '@hooks/useShareLinks'

import ShareLinkFeatureToggles from './components/ShareLinkFeatureToggles'

import useSelectShareLink from './hooks/useSelectShareLink'

import localizedStrings from './localizedStrings'

const copySuccess = (strings) => {
  toast(strings.toast?.copySuccess || 'Copied to clipboard')
}

const confirmDelete = (deleteFn, props, strings) => {
  const {
    callbacks: { deleteShareLink },
  } = props

  swal
    .fire({
      title: strings.swal?.title || 'Delete Share Link?',
      text: strings.swal?.text || 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: strings.swal?.confirmButtonText || 'Yes, delete it',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        deleteShareLink(deleteFn)
      }
    })
}

const EditShareLinkModal = (props) => {
  const {
    callbacks, disableAnalytics, disableAnimation, disableOverlay, disableSMS, modalKey, showModal,
  } = props
  const { updateShareLink } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const { shareLink } = digObject(modalData, `${modalKey}`, {})

  const {
    callbacks: {
      deleteShareLink: deleteFn, sendSMS, updateShareLink: updateFn, viewShareLinkAnalytics,
    },
    digitalPage,
    shareLinkUrl,
    updating,
  } = useShareLink(shareLink)

  const shareLinkFormPayload = useShareLinkForm(shareLink)
  const {
    entityState,
    entityState: {
      default: isDefault,
      enabled,
      expires_at,
      id: entityStateId,
      notifications_enabled,
      subject_id,
      subject_type,
      title,
      tracking_enabled,
    },
    errors,
    handlers,
    setEntityState,
  } = shareLinkFormPayload

  const { filteredShareLinks } = useShareLinks({
    filters: {
      printLink: false,
      subjectId: subject_id,
      subjectType: subject_type,
    },
  })

  const inputRef = useRef(null)
  const {
    callbacks: { copyValue },
  } = useCopyInputValue()

  const {
    callbacks: { updateDigitalPage },
  } = useDigitalPage(digitalPage)

  const {
    callbacks: { setShareLinkModalData },
  } = useSelectShareLink(shareLink)

  const firstSharedAt = digObject(digitalPage, 'dates.first_shared_at.date_time_with_timezone')

  useEffect(() => {
    if (!firstSharedAt && tracking_enabled){
      updateDigitalPage({ id: digitalPage.id, first_shared_at: DateTime.now().toISO() })
    }
  }, [tracking_enabled])

  const videoModulePayload = useVideoModules({ key: 'editShareLinks' })
  const { videosPayload } = videoModulePayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal
      callbacks={callbacks}
      disableAnimation={disableAnimation}
      disableOverlay={disableOverlay}
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.header?.title || 'Edit'}
        titleSecondLine={strings.header?.titleSecondLine || 'Share Link'}
      />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <FormField direction="column" label={strings.selectBoxLabel || 'Share Link'}>
            <select
              data-validate-field-on="change"
              onChange={e => setShareLinkModalData(Number(e.target.value))}
              value={entityStateId}
            >
              {filteredShareLinks.map(link => (
                <option key={link.id} value={link.id}>
                  {link.title}
                </option>
              ))}
            </select>
          </FormField>

          <Line style={{ marginLeft: -16, width: 'calc(100% + 32px)' }} />

          <ToggleButton
            boxProps={{ alignItems: 'center', justifyContent: 'space-between' }}
            enabled={enabled}
            onClick={() => setEntityState({ enabled: !enabled })}
          >
            <Text fontSize="small">
              {enabled
                ? strings.toggleButton?.enabledTitle || 'Link Active'
                : strings.toggleButton?.disabledTitle || 'Link Inactive'}
            </Text>

            <Text color="bodyFontLightColor" fontSize="xsmall" lineHeight={1.3} marginTop="medium">
              {enabled
                ? strings.toggleButton?.enabledText
                  || 'Your link is active and can be viewed by anyone with link access.'
                : strings.toggleButton?.disabledText || 'Your link is inactive and can no longer be viewed.'}
            </Text>
          </ToggleButton>

          {enabled && (
            <Box flexDirection="column">
              <FormField
                boxProps={{ marginBottom: 'large' }}
                direction="column"
                errorMessage={errors.title}
                label={strings.title || 'Title'}
              >
                <input data-validate-field-on="change" name="title" type="text" value={title} {...handlers} />
              </FormField>

              <FormField direction="column" boxProps={{ marginBottom: 'large' }} label={strings.link || 'Link'}>
                <input readOnly type="text" value={shareLinkUrl} ref={inputRef} />
              </FormField>

              <FormField
                direction="column"
                boxProps={{ marginBottom: 'large' }}
                label={expires_at ? strings.expiryDate || 'Expires:' : strings.setExpiry || 'Set Expiry Date:'}
              >
                <input name="expires_at" type="date" value={expires_at} {...handlers} />
              </FormField>
            </Box>
          )}

          <FormField direction="column" label={strings.moreOptions || 'More Options'} marginTop="large">
            <Box flexDirection="column" flexShrink={0}>
              {enabled && (
                <>
                  {expires_at && (
                    <Button
                      buttonStyle="secondaryUtility"
                      icon={<FontAwesomeIcon icon={faCalendarAlt} />}
                      marginBottom="medium"
                      onClick={() => setEntityState({ expires_at: '' })}
                      size="medium"
                    >
                      {strings.clearExpiry || 'Remove Expiry Date'}
                    </Button>
                  )}

                  {!disableSMS && (
                    <Button
                      buttonStyle="secondaryUtility"
                      icon={<FontAwesomeIcon icon={faShare} />}
                      marginBottom="medium"
                      onClick={() => sendSMS()}
                      size="medium"
                    >
                      {strings.sendButton || 'Send via SMS'}
                    </Button>
                  )}

                  <Button
                    buttonStyle="secondaryUtility"
                    icon={<FontAwesomeIcon icon={faLink} />}
                    marginBottom="medium"
                    onClick={() => copyValue(inputRef, () => copySuccess(strings))}
                    size="medium"
                  >
                    {strings.copyLinkButton || 'Copy Link'}
                  </Button>

                  {!disableAnalytics && tracking_enabled && (
                    <Button
                      buttonStyle="secondaryUtility"
                      icon={<FontAwesomeIcon icon={faChartBar} />}
                      marginBottom="medium"
                      onClick={viewShareLinkAnalytics}
                      size="medium"
                    >
                      {strings.viewAnalytics || 'View Analytics'}
                    </Button>
                  )}
                </>
              )}

              {!isDefault && (
                <Button
                  buttonStyle="secondaryUtility"
                  icon={<FontAwesomeIcon icon={faTrashAlt} />}
                  marginBottom="medium"
                  onClick={() => confirmDelete(deleteFn, props, strings)}
                  size="medium"
                >
                  {strings.deleteLinkButton || 'Delete Link'}
                </Button>
              )}
            </Box>
          </FormField>
        </Box>

        {enabled && <ShareLinkFeatureToggles shareLinkForm={shareLinkFormPayload} />}

        {notifications_enabled && (
          <NotificationRecipients entity={shareLink} entityForm={shareLinkFormPayload} useEntityHook={useShareLink} />
        )}

        <Box marginTop="large">
          <VideoModule videosPayload={videosPayload} />
        </Box>

      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          loading={updating}
          loadingBubbleColor="white"
          onClick={() => updateShareLink(entityState, updateFn)}
          size="large"
        >
          {strings.saveButton || 'Save'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

EditShareLinkModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  disableAnimation: PropTypes.bool,
  disableOverlay: PropTypes.bool,
  disableAnalytics: PropTypes.bool,
  disableSMS: PropTypes.bool,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

EditShareLinkModal.defaultProps = {
  modalKey: 'EditShareLinkModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <EditShareLinkModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
