import React from 'react'
import PropTypes from 'prop-types'

import { digObject } from '@campaignhub/javascript-utils'

import { FormField, Grid } from '@campaignhub/suit-theme'

import TemplateImage from '../../Gallery/TemplateImage'

const shouldRender = (itemType) => {
  const permittedItemTypes = [
    'map',
  ]
  return permittedItemTypes.includes(itemType)
}

const DefaultImage = (props) => {
  const {
    callbacks: { updateImage },
    digitalTemplatePayload: { templateImages },
    selectedEntity: { item_type, options },
  } = props

  const selectedImageId = digObject(options, 'marker.image.id')

  if (!shouldRender(item_type)) return null

  return (
    <FormField direction="column" boxProps={{ marginBottom: 'large' }} label="Map Marker Image">

      <Grid
        gridGap="medium"
        gridAutoRows="1fr"
        gridTemplateColumns="repeat(auto-fill, minmax(102px, 1fr))"
        gridTemplateRows="repeat(auto-fill, minmax(80px, 1fr))"
        marginBottom="medium"
      >
        {templateImages.map(image => (
          <TemplateImage
            key={image.id}
            image={image}
            onClick={() => (selectedImageId === image.id ? updateImage(null) : updateImage(image.id))}
            selected={selectedImageId === image.id}
          />
        ))}
      </Grid>
    </FormField>
  )
}

DefaultImage.propTypes = {
  callbacks: PropTypes.shape({
    updateImage: PropTypes.func.isRequired,
  }).isRequired,
  digitalTemplatePayload: PropTypes.shape({
    templateImages: PropTypes.array.isRequired,
  }).isRequired,
  selectedEntity: PropTypes.object.isRequired,
}

export default DefaultImage
