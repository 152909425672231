import { useMemo, useContext } from 'react'
import { useSelector } from 'react-redux'
import cloneDeep from 'lodash/cloneDeep'

import {
  digObject, multiFilteredObjectArraySelector, sortArrayBy, toggleArray,
} from '@campaignhub/javascript-utils'
import {
  useForm, useLatestEntity, useThunkDispatch, useWatchEntityUpdates,
} from '@campaignhub/react-hooks'

import PageContext from '@contexts/pageContext'

import defaultState, { requiredFields } from '@models/digitalTemplate'

import { availableFeatures } from '@functions/digitalTemplate'

import * as digitalTemplateActions from '@redux/modules/digitalTemplate'
import * as digitalTemplatesOrganizationActions from '@redux/modules/digitalTemplatesOrganization'

const watchEntityKeys = ['digitalTemplates', 'digitalTemplateComponents', 'images']

export const generateUrls = (digitalTemplate = {}) => {
  const { id } = digitalTemplate

  return {
    agencyArchivedDigitalTemplateUrl: '#/templates?status=archived&ownerType=Organization',
    agnecyDigitalTemplateUrl: '#/templates?status=current&ownerType=Organization',
    brandDigitalTemplateUrl: '#/templates?status=current&ownerType=System',
    customizeDigitalTemplateUrl: `#/templates/${id}/customize`,
    editDigitalTemplateUrl: `#/digitalTemplates/${id}/edit`,
    systemManagerTemplateList: '#/digitalTemplates/template-list',
  }
}

const bulkUpdatePermissions = (digitalTemplate, dispatch, organizationIds) => {
  const { bulkUpdateIds: bulkUpdateFn } = digitalTemplatesOrganizationActions
  return dispatch(bulkUpdateFn(digitalTemplate, organizationIds))
}

const toggleTemplateHidden = (digitalTemplate, dispatch, requestOptions) => {
  const { updateDigitalTemplate: updateFn } = digitalTemplateActions

  const updatedTemplate = {
    ...digitalTemplate,
    hidden: !digitalTemplate.hidden,
  }

  return dispatch(updateFn(updatedTemplate, requestOptions))
}

const createDigitalTemplate = (templateParams, dispatch, requestOptions) => {
  const { createDigitalTemplate: createFn } = digitalTemplateActions
  const { options } = templateParams || {}

  const updatedParams = {
    ...templateParams,
    options: JSON.stringify(options),
  }

  return dispatch(createFn(updatedParams, requestOptions)).then((response) => {
    const { success, data } = response
    if (success){
      const {
        entity: { id },
      } = data

      const { editDigitalTemplateUrl } = generateUrls({ id })

      return {
        success,
        data,
        redirectUrl: editDigitalTemplateUrl,
      }
    }

    return response
  })
}

const duplicateDigitalTemplate = (digitalTemplate, dispatch, requestOptions) => {
  const { duplicateDigitalTemplate: duplicateFn } = digitalTemplateActions
  return dispatch(duplicateFn(digitalTemplate, requestOptions))
}

const newVersionDigitalTemplate = (digitalTemplate, dispatch, requestOptions) => {
  const { newVersionDigitalTemplate: newVersionFn } = digitalTemplateActions
  return dispatch(newVersionFn(digitalTemplate, requestOptions))
}

const deleteUnusedDigitalTemplateComponents = (digitalTemplate, dispatch, requestOptions) => {
  const { deleteUnusedDigitalTemplateComponents: deleteComponentsFn } = digitalTemplateActions
  return dispatch(deleteComponentsFn(digitalTemplate, requestOptions))
}

const forkDigitalTemplate = (originalTemplate, owner, title, dispatch, requestOptions) => {
  const { forkDigitalTemplate: forkFn } = digitalTemplateActions

  const updatedRequestOptions = {
    ...requestOptions,
    owner_id: owner.id,
    owner_type: owner.type,
    title,
  }

  return dispatch(forkFn(originalTemplate, updatedRequestOptions)).then((response) => {
    const { success, data } = response
    if (success){
      const {
        entity: { id },
      } = data

      const { customizeDigitalTemplateUrl } = generateUrls({ id })

      return {
        success,
        data,
        redirectUrl: customizeDigitalTemplateUrl,
      }
    }

    return response
  })
}

const updateDigitalTemplate = (digitalTemplate, digitalTemplateParams, dispatch, requestOptions) => {
  const { updateDigitalTemplate: updateFn } = digitalTemplateActions
  const { options } = digitalTemplateParams

  const updatedParams = {
    ...digitalTemplateParams,
    id: digitalTemplate.id,
  }

  if (options){
    const templateOptions = digitalTemplate.options || {}
    updatedParams.options = JSON.stringify({
      ...cloneDeep(templateOptions),
      ...options,
    })
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

const removeComponentStyle = (params) => {
  const {
    digitalTemplate, digitalTemplateParams, dispatch, requestOptions,
  } = params

  const { removeComponentStyle: removeFn } = digitalTemplateActions
  return dispatch(removeFn(digitalTemplate, digitalTemplateParams, requestOptions))
}

const updateDigitalTemplateParam = (digitalTemplate, key, value, dispatch, requestOptions) => {
  const updatedTemplate = {
    id: digitalTemplate.id,
    [key]: value,
  }

  return updateDigitalTemplate(digitalTemplate, updatedTemplate, dispatch, requestOptions)
}

const unlockSessionLocks = (digitalTemplate, dispatch) => {
  const { unlockSessionLocks: unlockFn } = digitalTemplateActions
  return dispatch(unlockFn(digitalTemplate))
}

const toggleProductionReady = (digitalTemplate, options, dispatch) => {
  const { toggleProductionReady: toggleFn } = digitalTemplateActions
  return dispatch(toggleFn(digitalTemplate, options))
}

const toggleDigitalTemplateChartFeature = (featureKey, digitalTemplate, dispatch) => {
  const templateOptions = digitalTemplate.options || {}
  const chartFeatures = templateOptions.chart_features || []

  const updatedChartFeatures = toggleArray(chartFeatures, { key: featureKey }, { deepCompare: true })

  const updatedOptions = {
    ...templateOptions,
    chart_features: updatedChartFeatures,
  }

  updateDigitalTemplateParam(digitalTemplate, 'options', updatedOptions, dispatch)
}

const createOrUpdateComponentStyles = (params) => {
  const { digitalTemplate, dispatch, requestOptions } = params

  const { createOrUpdateComponentStyles: createOrUpdateFn } = digitalTemplateActions
  return dispatch(createOrUpdateFn(digitalTemplate, requestOptions))
}

const managePermissions = (digitalTemplate, dispatch, showManageDigitalTemplatePermissionsModal) => new Promise((resolve, reject) => {
  if (showManageDigitalTemplatePermissionsModal){
    const payload = {
      callbacks: {
        bulkUpdatePermissions: organizationIds => bulkUpdatePermissions(digitalTemplate, dispatch, organizationIds),
      },
      digitalTemplate,
    }

    showManageDigitalTemplatePermissionsModal(payload)

    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showManageDigitalTemplatePermissionsModal not defined in PageContext callbacks'))
})

const manageUserPermissions = (digitalTemplate, showManageDigitalTemplateUserPermissionsModal) => new Promise((resolve, reject) => {
  if (showManageDigitalTemplateUserPermissionsModal){
    const payload = {
      digitalTemplate,
    }

    showManageDigitalTemplateUserPermissionsModal(payload)

    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showManageDigitalTemplateUserPermissionsModal not defined in PageContext callbacks'))
})

const viewActivities = (digitalTemplate, showActivitiesModal) => new Promise((resolve, reject) => {
  if (showActivitiesModal){
    const payload = {
      filters: {
        recipient_id: digitalTemplate.id,
        recipient_type: 'DigitalTemplate',
      },
    }

    showActivitiesModal(payload)

    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showActivitiesModal not defined in PageContext callbacks'))
})

const modifyDigitalTemplateFonts = (action, font, digitalTemplate, dispatch) => {
  const templateOptions = digitalTemplate.options || {}
  const updatedFonts = templateOptions.fonts ? [...templateOptions.fonts] : []
  const index = updatedFonts.indexOf(font)

  if (action === 'add' && index === -1){
    updatedFonts.push(font)
  }

  if (action === 'delete' && index !== -1){
    updatedFonts.splice(index, 1)
  }

  const updatedOptions = {
    ...templateOptions,
    fonts: updatedFonts,
  }

  updateDigitalTemplateParam(digitalTemplate, 'options', updatedOptions, dispatch)
}

// This does not remove the feature if it exists
const setDigitalTemplateFeature = (featureKey, digitalTemplate, dispatch) => {
  const templateOptions = digitalTemplate.options || {}
  const templateFeatures = templateOptions.features || []

  const index = templateFeatures.findIndex(feature => feature.key === featureKey)
  if (index === -1){
    const updatedFeatures = [...templateFeatures, { key: featureKey }]

    const updatedOptions = {
      ...templateOptions,
      features: updatedFeatures,
    }

    updateDigitalTemplateParam(digitalTemplate, 'options', updatedOptions, dispatch)
  }
}

const toggleDigitalTemplateFeature = (featureKey, digitalTemplate, dispatch) => {
  const templateOptions = digitalTemplate.options || {}
  const templateFeatures = templateOptions.features || []

  const updatedFeatures = toggleArray(templateFeatures, { key: featureKey }, { deepCompare: true })

  const updatedOptions = {
    ...templateOptions,
    features: updatedFeatures,
  }

  updateDigitalTemplateParam(digitalTemplate, 'options', updatedOptions, dispatch)
}

const updateGalleries = (digitalTemplate, galleries, dispatch) => {
  const updatedDigitalTemplate = {
    options: {
      galleries,
    },
  }

  return updateDigitalTemplate(digitalTemplate, updatedDigitalTemplate, dispatch)
}

export function useDigitalTemplateForm(digitalTemplate = {}){
  const dispatch = useThunkDispatch()

  const digitalTemplateForm = useForm(defaultState, { entity: digitalTemplate, requiredFields }, [digitalTemplate.id])

  const { creating, loading, updating } = useSelector(reduxState => reduxState.digitalTemplates)

  return {
    ...digitalTemplateForm,
    callbacks: {
      updateDigitalTemplate: (updatedParams, requestOptions) => updateDigitalTemplate(digitalTemplate, updatedParams, dispatch, requestOptions),
      updateDigitalTemplateParam: (key, value, requestOptions) => updateDigitalTemplateParam(digitalTemplate, key, value, dispatch, requestOptions),
    },
    creating,
    loading,
    updating,
  }
}

const searchDigitalTemplates = (dispatch, options) => {
  const { searchDigitalTemplates: searchFn } = digitalTemplateActions
  return dispatch(searchFn(options))
}

export function getDigitalTemplateEnabledFeatures(digitalTemplate){
  const templateEnabledFeatures = digObject(digitalTemplate, 'options.features', [])

  // Supported and Enabled Features
  const supportedFeatures = templateEnabledFeatures
    .map(enabledFeature => availableFeatures.find(feature => feature.key === enabledFeature.key))
    .filter(f => f)

  const supportedFeatureKeys = supportedFeatures.map(feature => (feature ? feature.key : null))

  const enabledFeatures = availableFeatures.filter((feature) => {
    const { checkEnabledFn, key } = feature || {}
    return checkEnabledFn ? checkEnabledFn(supportedFeatureKeys) : supportedFeatureKeys.includes(key)
  })

  return {
    enabledFeatures,
  }
}

export const mergeComponentStyles = (legacyComponentStyles, componentStyles) => {
  const updatedlegacyStyles = legacyComponentStyles.map(legacyComponentStyle => ({
    ...legacyComponentStyle,
    title: `${legacyComponentStyle.title} - Legacy`,
  }))

  const mergedComponentStyles = [...updatedlegacyStyles, ...componentStyles]

  return sortArrayBy(mergedComponentStyles, 'asc', 'title')
}

export function useRelations(initDigitalTemplate){
  const { entity: digitalTemplate } = useLatestEntity(initDigitalTemplate, 'digitalTemplates')

  const {
    digital_template_type_id, origin_template_id, owner_id, owner_type, sidebar_template_id,
  } = digitalTemplate || {}

  const entities = useSelector(reduxState => reduxState.entities)
  const { digitalTemplates, digitalTemplateTypes, organizations } = entities

  const digitalTemplateType = digitalTemplateTypes[digital_template_type_id] || {}
  const originTemplate = digitalTemplates[origin_template_id] || {}
  const organization = owner_type === 'Organization' ? organizations[owner_id] || {} : {}
  const sidebarTemplate = digitalTemplates[sidebar_template_id] || {}

  return {
    digitalTemplateType,
    originTemplate,
    organization,
    sidebarTemplate,
  }
}

const forkDigitalTemplateComponent = (digitalTemplate, componentParams, dispatch, requestOptions) => {
  const { forkDigitalTemplateComponent: forkFn } = digitalTemplateActions

  return dispatch(forkFn(digitalTemplate, componentParams, requestOptions))
}

function useDigitalTemplate(initDigitalTemplate = {}){
  const { entity: digitalTemplate } = useLatestEntity(initDigitalTemplate, 'digitalTemplates')

  const dispatch = useThunkDispatch()

  const pageContext = useContext(PageContext)
  const { callbacks, digitalTemplateCurrentUsagePayload } = pageContext
  const {
    showActivitiesModal,
    showManageDigitalTemplatePermissionsModal,
    showManageDigitalTemplateUserPermissionsModal,
  } = callbacks || {}

  const entities = useSelector(reduxState => reduxState.entities)
  const { digitalTemplateComponents, digitalTemplatePages } = entities

  const pageIds = digObject(digitalTemplate, 'page_ids', [])
  const firstPageId = pageIds[0]

  // Current Template Usage
  const currentTemplateUsage = digObject(digitalTemplateCurrentUsagePayload, 'data.0', {})
  const shouldShowCurrentTemplateUsage = !!digitalTemplateCurrentUsagePayload?.data.length

  const selectedPageId = useSelector(reduxState => reduxState.digitalTemplatePages.selectedId)
  const selectedDigitalTemplatePage = digitalTemplatePages[selectedPageId] || {}

  const { creating, updating } = useSelector(reduxState => reduxState.digitalTemplates)

  const {
    updatedEntities: {
      digitalTemplateComponents: digitalTemplateComponentsUpdatedAt,
      digitalTemplates: digitalTemplateUpdatedAt,
      images: imagesUpdatedAt,
    },
  } = useWatchEntityUpdates(watchEntityKeys)

  const digitalTemplateRelations = useRelations(digitalTemplate)
  const {
    digitalTemplateType, originTemplate, organization, sidebarTemplate,
  } = digitalTemplateRelations

  // Share Image
  const shareImage = useMemo(() => {
    const filteredImages = multiFilteredObjectArraySelector({ entities }, 'images', [
      { key: 'image_type', value: 'share_image' },
      { key: 'subject_type', value: 'DigitalTemplate' },
      { key: 'subject_id', value: digitalTemplate.id },
    ])
    return filteredImages[0] || {}
  }, [digitalTemplate.id, digitalTemplateUpdatedAt, imagesUpdatedAt])

  // Template Images
  const templateImages = useMemo(() => {
    const filteredImages = multiFilteredObjectArraySelector({ entities }, 'images', [
      { key: 'image_type', value: 'template_image' },
      { key: 'subject_type', value: 'DigitalTemplate' },
      { key: 'subject_id', value: digitalTemplate.id },
    ])
    return filteredImages || []
  }, [digitalTemplate.id, digitalTemplateUpdatedAt, imagesUpdatedAt])

  // Preview Images
  const previewImage = useMemo(() => {
    const filteredImages = multiFilteredObjectArraySelector({ entities }, 'images', [
      { key: 'image_type', value: 'preview_image' },
      { key: 'subject_type', value: 'DigitalTemplate' },
      { key: 'subject_id', value: digitalTemplate.id },
    ])
    return filteredImages[0] || {}
  }, [digitalTemplate.id, digitalTemplateUpdatedAt, imagesUpdatedAt])

  const digitalTemplateOptions = digObject(digitalTemplate, 'options', {})
  const legacyComponentStyles = digObject(digitalTemplateOptions, 'component_styles.styles', [])

  const digitalTemplateComponentIds = digObject(digitalTemplate, 'digital_template_component_ids', [])

  const componentStyles = useMemo(() => {
    const array = Object.values(digitalTemplateComponents)
    const filteredComponentStyles = array.filter(
      component => digitalTemplateComponentIds.includes(component.id) && component.item_type === 'component_style',
    )

    return filteredComponentStyles
  }, [digitalTemplate.id, digitalTemplateUpdatedAt, digitalTemplateComponentsUpdatedAt])

  const componentPartials = useMemo(() => {
    const array = Object.values(digitalTemplateComponents)
    const filteredComponentPartials = array.filter(
      component => digitalTemplateComponentIds.includes(component.id) && component.item_type === 'component_partial',
    )

    return sortArrayBy(
      filteredComponentPartials,
      'asc',
      templateComponent => templateComponent.data?.component?.entity_type,
    )
  }, [digitalTemplate.id, digitalTemplateUpdatedAt, digitalTemplateComponentsUpdatedAt])

  const galleries = digitalTemplateOptions.galleries || {}

  const digitalTemplateChartFeatures = digObject(digitalTemplateOptions, 'chart_features', [])

  const isSidebarTemplate = digitalTemplateType.key === 'template_sidebar'

  return {
    callbacks: {
      createDigitalTemplate: (templateParams, requestOptions) => createDigitalTemplate(templateParams, dispatch, requestOptions),
      createOrUpdateComponentStyles: requestOptions => createOrUpdateComponentStyles({ digitalTemplate, dispatch, requestOptions }),
      deleteUnusedDigitalTemplateComponents: requestOptions => deleteUnusedDigitalTemplateComponents(digitalTemplate, dispatch, requestOptions),
      duplicateDigitalTemplate: requestOptions => duplicateDigitalTemplate(digitalTemplate, dispatch, requestOptions),
      forkDigitalTemplate: (originalTemplate, owner, title, requestOptions) => forkDigitalTemplate(originalTemplate, owner, title, dispatch, requestOptions),
      forkDigitalTemplateComponent: (componentParams, requestOptions) => forkDigitalTemplateComponent(digitalTemplate, componentParams, dispatch, requestOptions),
      managePermissions: () => managePermissions(digitalTemplate, dispatch, showManageDigitalTemplatePermissionsModal),
      manageUserPermissions: () => manageUserPermissions(digitalTemplate, showManageDigitalTemplateUserPermissionsModal),
      modifyDigitalTemplateFonts: (action, font) => modifyDigitalTemplateFonts(action, font, digitalTemplate, dispatch),
      newVersionDigitalTemplate: requestOptions => newVersionDigitalTemplate(digitalTemplate, dispatch, requestOptions),
      removeComponentStyle: (digitalTemplateParams, requestOptions) => removeComponentStyle({
        digitalTemplate, digitalTemplateParams, dispatch, requestOptions,
      }),
      searchDigitalTemplates: options => searchDigitalTemplates(dispatch, options),
      setDigitalTemplateFeature: featureKey => setDigitalTemplateFeature(featureKey, digitalTemplate, dispatch),
      toggleDigitalTemplateChartFeature: featureKey => toggleDigitalTemplateChartFeature(featureKey, digitalTemplate, dispatch),
      toggleDigitalTemplateFeature: featureKey => toggleDigitalTemplateFeature(featureKey, digitalTemplate, dispatch),
      toggleTemplateHidden: requestOptions => toggleTemplateHidden(digitalTemplate, dispatch, requestOptions),
      unlockSessionLocks: () => unlockSessionLocks(digitalTemplate, dispatch),
      toggleProductionReady: requestOptions => toggleProductionReady(digitalTemplate, requestOptions, dispatch),
      updateDigitalTemplate: (updatedParams, requestOptions) => updateDigitalTemplate(digitalTemplate, updatedParams, dispatch, requestOptions),
      updateDigitalTemplateParam: (key, value, requestOptions) => updateDigitalTemplateParam(digitalTemplate, key, value, dispatch, requestOptions),
      updateGalleries: updatedGalleries => updateGalleries(digitalTemplate, updatedGalleries, dispatch),
      viewActivities: () => viewActivities(digitalTemplate, showActivitiesModal),
    },
    componentPartials,
    componentStyles: mergeComponentStyles(legacyComponentStyles, componentStyles),
    creating,
    currentTemplateUsage,
    digitalTemplate,
    digitalTemplateChartFeatures,
    digitalTemplateOptions,
    digitalTemplateType,
    firstPageId,
    galleries,
    isSidebarTemplate,
    organization,
    originTemplate,
    previewImage,
    selectedDigitalTemplatePage,
    shareImage,
    shouldShowCurrentTemplateUsage,
    sidebarTemplate,
    templateImages,
    updating,
    urls: generateUrls(digitalTemplate),
  }
}

export default useDigitalTemplate
