import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { sortArrayBy } from '@campaignhub/javascript-utils'

import { useSetState, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'

const watchEntityKeys = ['images']

export const defaultRequestOptions = {
  assetLibrary: {
    include_asset_library_default_image: true,
    include_asset_library_images: true,
  },
  comparable: {
    include_comparable_address: true,
    include_comparable_default_image: true,
  },
  image: {
    include_image_sizes: ['thumb_90x90', 'thumb_200x120', 'thumb_660x360'],
  },
  organization: {
    include_organization_default_image: true,
    include_organization_images: true,
  },
  user: {
    include_user_default_image: true,
    include_user_images: true,
  },
}

const filterEntityImages = (entityType, entityId, images) => {
  const filtered = Object.values(images).filter(
    image => image
      && image.subject_type
      && image.subject_type === entityType
      && image.subject_id
      && image.subject_id === entityId,
  )

  return filtered
}

const getSelectedEntity = (entities, entityType, entityId) => {
  const {
    organizations, projects, comparables, assetLibraries, users,
  } = entities

  if (entityType === 'Organization'){
    return organizations[entityId] || {}
  }

  if (entityType === 'Project'){
    return projects[entityId] || {}
  }

  if (entityType === 'ProjectComparable'){
    return comparables[entityId] || {}
  }

  if (entityType === 'AssetLibrary'){
    return assetLibraries[entityId] || {}
  }

  if (entityType === 'User'){
    return users[entityId] || {}
  }

  return {}
}

const selectEntity = (entity, setState) => {
  const { id, type } = entity

  setState({
    selectedEntityId: id,
    selectedEntityType: type,
  })
}

const defaultState = {
  selectedEntityId: 0,
  selectedEntityType: 'Project',
}

function useImageSelect(options = {}){
  const {
    projectId,
    preselectedEntity: { id: preselectedId, type: preselectedType },
  } = options || {}

  const {
    updatedEntities: { images: imagesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys, { customUseSelectorFn: useSelector })

  const [state, setState] = useSetState(defaultState)
  const { selectedEntityType, selectedEntityId } = state

  // Load Entities
  const entityKey = `Project${projectId}`
  const { loading: loadingComparables } = useReduxAction(
    'comparables',
    'loadComparables',
    {
      ...defaultRequestOptions.comparable, ...defaultRequestOptions.image, project_id: projectId, entityKey,
    },
    [selectedEntityType, projectId],
    {
      shouldPerformFn: (entityReducer) => {
        const { loading, loadedForKeys, errors } = entityReducer
        return (
          selectedEntityType === 'ProjectComparable'
          && !loading
          && !loadedForKeys.includes(entityKey)
          && !errors.length
        )
      },
    },
  )

  const { loading: loadingImageLibraries } = useReduxAction(
    'assetLibraries',
    'loadAssetLibraries',
    { ...defaultRequestOptions.assetLibrary, ...defaultRequestOptions.image },
    [selectedEntityType],
    {
      shouldPerformFn: (entityReducer) => {
        const { loading, loaded, errors } = entityReducer
        return selectedEntityType === 'AssetLibrary' && !loading && !loaded && !errors.length
      },
    },
  )

  const { loading: loadingUsers } = useReduxAction(
    'users',
    'loadUsers',
    { ...defaultRequestOptions.user, ...defaultRequestOptions.image },
    [selectedEntityType],
    {
      shouldPerformFn: (entityReducer) => {
        const { loading, loaded, errors } = entityReducer
        return selectedEntityType === 'User' && !loading && !loaded && !errors.length
      },
    },
  )

  const { loading: loadingOrganizations } = useReduxAction(
    'organizations',
    'loadOrganizations',
    {
      ...defaultRequestOptions.organization,
      ...defaultRequestOptions.image,
    },
    [selectedEntityType],
    {
      shouldPerformFn: (entityReducer) => {
        const { loading, loaded, errors } = entityReducer
        return selectedEntityType === 'User' && !loading && !loaded && !errors.length
      },
    },
  )

  const entities = useSelector(reduxState => reduxState.entities)
  const { images } = entities

  // Preselect Entity
  useEffect(() => {
    if (preselectedType && preselectedId){
      setState({
        selectedEntityId: preselectedId,
        selectedEntityType: preselectedType,
      })
    }
  }, [preselectedType, preselectedId])

  useReduxAction(
    'images',
    'loadImages',
    {
      ...defaultRequestOptions.image,
      subject_id: selectedEntityId,
      subject_type: selectedEntityType,
    },
    [selectedEntityType, selectedEntityId],
    {
      shouldPerformFn: (entityReducer) => {
        const { loading, loadedForKeys } = entityReducer
        const imageEntityKey = `${selectedEntityType}${selectedEntityId}`

        return (
          selectedEntityId
          && selectedEntityType
          && !loading
          && !loadedForKeys.includes(imageEntityKey)
        )
      },
    },
  )

  // Filter Entity Images
  const filteredImages = useMemo(() => {
    const filtered = filterEntityImages(selectedEntityType, selectedEntityId, images)
    const sorted = sortArrayBy(filtered, 'asc', 'sort')
    return sorted
  }, [selectedEntityType, selectedEntityId, imagesUpdatedAt])

  const selectedEntity = getSelectedEntity(entities, selectedEntityType, selectedEntityId)

  const hasImages = !!filteredImages.length

  const loading = loadingComparables || loadingImageLibraries || loadingOrganizations || loadingUsers

  return {
    callbacks: {
      selectEntity: library => selectEntity(library, setState),
      setState,
    },
    filteredImages,
    hasImages,
    images,
    loading,
    selectedEntity,
    selectedEntityId,
    selectedEntityType,
  }
}

export default useImageSelect
