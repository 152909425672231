import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { DateTime } from 'luxon'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'
import { digObject, matchFilterNumber, sortArrayBy } from '@campaignhub/javascript-utils'

import useReduxAction from '@hooks/useReduxAction'

const watchEntityKeys = ['digitalPageMetricCollections']

const matchFilterStartDate = (date, filterStartDate) => {
  if (!!date && !!filterStartDate) return date >= filterStartDate
}

const matchFilterEndDate = (date, filterEndDate) => {
  if (!!date && !!filterEndDate) return date <= filterEndDate
}

function useDigitalPageMetricCollections(options = {}){
  const { filters = {}, requestOptions = {} } = options

  const {
    end_date: filterEndDateString,
    owner_id: ownerId,
    owner_type: ownerType,
    start_date: filterStartDateString,
  } = filters

  const {
    updatedEntities: { digitalPageMetricCollections: digitalPageMetricCollectionsUpdateAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const entities = useSelector(reduxState => reduxState.entities)
  const { digitalPageMetricCollections } = entities

  const { loading } = useSelector(reduxState => reduxState.digitalPageMetricCollections)

  const filterEndDate = filterEndDateString ? DateTime.fromFormat(filterEndDateString, 'yyyy-MM-dd') : null
  const filterStartDate = filterStartDateString ? DateTime.fromFormat(filterStartDateString, 'yyyy-MM-dd') : null

  const filteredDigitalPageMetricCollections = useMemo(() => {
    const filtered = Object.values(digitalPageMetricCollections).filter((digitalPageMetricCollection) => {
      const { dates, owner_id, owner_type } = digitalPageMetricCollection

      const endDateString = digObject(dates, 'end.date_time_with_timezone')
      const startDateString = digObject(dates, 'start.date_time_with_timezone')
      const endDate = endDateString ? DateTime.fromISO(endDateString) : null
      const startDate = startDateString ? DateTime.fromISO(startDateString) : null

      const endDateMatch = filterEndDate ? matchFilterEndDate(endDate, filterEndDate) : true
      const ownerIdMatch = matchFilterNumber(owner_id, ownerId)
      const ownerTypeMatch = ownerType ? owner_type === ownerType : true
      const startDateMatch = filterStartDate ? matchFilterStartDate(startDate, filterStartDate) : true

      return endDateMatch && ownerIdMatch && ownerTypeMatch && startDateMatch
    })

    return sortArrayBy(filtered, 'desc', 'id')
  }, [digitalPageMetricCollectionsUpdateAt, JSON.stringify(options)])

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredDigitalPageMetricCollections.length,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    performHttpRequests,
  } = loadMorePayload

  useReduxAction(
    'digitalPageMetricCollections',
    'loadDigitalPageMetricCollections',
    {
      ...filtersWithOffset,
      ...requestOptions,
    },
    [filtersWithOffset, JSON.stringify(filters), performHttpRequests],
  )

  const filteredDigitalPageMetricCollectionsCount = filteredDigitalPageMetricCollections.length
  const hasfilteredDigitalPageMetricCollections = !!filteredDigitalPageMetricCollections.length

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredDigitalPageMetricCollections,
    filteredDigitalPageMetricCollectionsCount,
    hasfilteredDigitalPageMetricCollections,
    loading,
  }
}

export default useDigitalPageMetricCollections
