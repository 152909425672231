import React from 'react'

import { BlankState } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

import blankEventCalendarsUrl from './assets/no-event-calendars.svg'

const EventCalendarsBlankState = () => {
  const { strings } = useLocalization(localizedStrings)

  return (
    <BlankState imageUrl={blankEventCalendarsUrl} sidebar>
      <BlankState.Paragraph>
        {strings.paragraph}
      </BlankState.Paragraph>
    </BlankState>
  )
}

export default EventCalendarsBlankState
