import React from 'react'
import Lottie from 'react-lottie'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { digObject } from '@campaignhub/javascript-utils'

import useDigitalTemplatePageItem from '@hooks/useDigitalTemplatePageItem'
import useDeviceStyle from '@hooks/useDeviceStyle'

import styles from './styles.module.scss'

const Animation = (props) => {
  const {
    id,
    isSelected,
    onClick,
    options: { animationLoop },
  } = props

  const style = useDeviceStyle(props)

  const { itemAttachment } = useDigitalTemplatePageItem({ digitalTemplatePageItemId: id })

  const attachmentURL = digObject(itemAttachment, 'file.url')

  const defaultOptions = {
    loop: animationLoop,
    autoplay: true,
    path: attachmentURL,
  }

  return (
    <div className={classnames(styles.root, isSelected ? styles.selected : null)} onClick={onClick} style={style}>
      <div className={styles.label}>Animation {id}</div>
      {!!attachmentURL && (
        <Lottie
          options={defaultOptions}
          width={style.width}
          height={style.height}
        />
      )}
    </div>
  )
}

Animation.propTypes = {
  id: PropTypes.string,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  options: PropTypes.object,
}

export default Animation
