import { useContext } from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faEye, faPlus } from '@fortawesome/pro-light-svg-icons'

import {
  Box,
  Button,
  FormField,
  InputSearch,
  LoadingModule,
  ModalContext,
  SectionDivider,
  SidebarModal,
  SidebarNoticeBox,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useComparable from '@hooks/useComparable'
import useDigitalPage from '@hooks/useDigitalPage'
import useLocalization from '@hooks/useLocalization'
import { useRelations } from '@hooks/useProject'

import DraggableComparables from './components/DraggableComparables'
import ComparableList from './components/ComparablesList'
import useManageComparables from './hooks/useManageComparables'

import localizedStrings from './localizedStrings'

const generateComparablesCategory = (comparableSource, comparableStatus, strings) => {
  if (comparableSource === 'all' && comparableStatus === 'all') return strings.allComparables
  if (comparableSource === 'all') return comparableStatus
  if (comparableStatus === 'all') return strings.source[comparableSource]
  return `${strings.source[comparableSource]} ${comparableStatus}`
}

const ManageComparablesModal = (props) => {
  const {
    callbacks,
    disableAnimation,
    disableOverlay,
    modalKey,
    project,
    showModal,
    source,
    status,
  } = props

  const { updateDigitalPageComparables } = callbacks

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const { callbacks: payloadCallbacks, featureOptions, digitalPage } = digObject(modalData, modalKey, {})
  const { loadShortcodeData: loadDataFn } = payloadCallbacks || {}

  const comparableSource = source || digObject(featureOptions, 'comparableSource', 'all')
  const comparableStatus = status || digObject(featureOptions, 'comparableStatus', 'all')

  const digitalPagePayload = useDigitalPage(digitalPage)
  const { callbacks: { updateDigitalPage: updateFn }, updating } = digitalPagePayload

  const manageComparablesPayload = useManageComparables(project, { digitalPage, comparableSource, comparableStatus })
  const {
    callbacks: {
      modifyComparablesSort,
      setState,
      toggleAllProjectComparable,
      toggleSelectedComparable,
    },
    loading,
    state: {
      comparableIds, filterString, selectedComparableIds, showSelectedProjectComparable,
    },
  } = manageComparablesPayload

  const hasComparableIds = !!comparableIds.length

  const { projectType } = useRelations(project)

  const {
    callbacks: { showFindComparablesModal },
  } = useComparable()

  const Component = showSelectedProjectComparable ? ComparableList : DraggableComparables

  const { strings } = useLocalization(localizedStrings, { projectType })

  return (
    <SidebarModal
      callbacks={callbacks}
      disableAnimation={disableAnimation}
      disableOverlay={disableOverlay}
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.sideBarModalHeader?.title || 'Manage'}
        titleSecondLine={strings.sideBarModalHeader?.titleSecondLine || 'Comparables'}
      />

      <SidebarModal.Content>
        <Box flexDirection="column">
          <LoadingModule boxProps={{ marginTop: 'large' }} loading={loading} times={3} />

          {!loading && (
            <FormField boxProps={{ marginBottom: hasComparableIds ? null : 'large' }} direction="column" label={strings.formFieldLabels?.filter || 'Filter Comparables'}>
              <InputSearch
                onChange={e => setState({ filterString: e.target.value })}
                value={filterString}
              />
            </FormField>
          )}

          {!loading && !hasComparableIds && (
            <SidebarNoticeBox>
              <SidebarNoticeBox.Title>{strings.noticeBox?.title || 'No Comparables Saved'}</SidebarNoticeBox.Title>
              <SidebarNoticeBox.Paragraph>
                {strings.noticeBox?.paragraph || 'This project has no comparables selected. Add one by clicking below.'}
              </SidebarNoticeBox.Paragraph>
            </SidebarNoticeBox>
          )}

          {comparableSource && comparableStatus && hasComparableIds && (
            <SectionDivider>{generateComparablesCategory(comparableSource, comparableStatus, strings)}</SectionDivider>
          )}

          { !loading && hasComparableIds && (
            <Component
              callbacks={{ modifyComparablesSort, toggleSelectedComparable }}
              comparableIds={comparableIds}
              selectedComparableIds={selectedComparableIds}
              showSelectedProjectComparable={showSelectedProjectComparable}
            />
          )}
        </Box>

        <FormField
          direction="column"
          label={hasComparableIds ? strings.formFieldLabels?.moreOptions || 'More Options' : null}
          marginTop={hasComparableIds ? 'large' : 0}
        >
          <Button
            icon={<FontAwesomeIcon icon={faCheckCircle} />}
            buttonStyle="secondaryUtility"
            onClick={() => toggleAllProjectComparable()}
            size="medium"
            width="100%"
          >
            {strings.buttons?.toggleAll || 'Toggle All'}
          </Button>

          <Button
            icon={<FontAwesomeIcon icon={faEye} />}
            marginTop="medium"
            buttonStyle="secondaryUtility"
            onClick={() => setState({ showSelectedProjectComparable: !showSelectedProjectComparable })}
            size="medium"
            width="100%"
          >
            {showSelectedProjectComparable
              ? strings.buttons?.showAll || 'Show All'
              : strings.buttons?.showSelected || 'Show Selected'}
          </Button>

          <Button
            icon={<FontAwesomeIcon icon={faPlus} />}
            marginTop="medium"
            buttonStyle="secondaryUtility"
            onClick={() => showFindComparablesModal()}
            size="medium"
            width="100%"
          >
            {strings.buttons?.addComparable || 'Add Comparable'}
          </Button>
        </FormField>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          loading={updating}
          loadingBubbleColor="white"
          onClick={() => updateDigitalPageComparables(
            updateFn,
            { id: digitalPage.id, comparable_ids: selectedComparableIds },
            loadDataFn,
          )}
          size="large"
        >
          {strings.buttons?.save || 'Save'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

ManageComparablesModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  disableAnimation: PropTypes.bool,
  disableOverlay: PropTypes.bool,
  modalKey: PropTypes.string,
  project: PropTypes.object,
  showModal: PropTypes.bool,
  source: PropTypes.string,
  status: PropTypes.string,
}

ManageComparablesModal.defaultProps = {
  modalKey: 'ManageComparablesModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <ManageComparablesModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
