export type TeamModel = {
  cache_key: number,
  id: number,
  description: string,
  grouped_user_ids?: number[] | string,
  owner_id: number,
  owner_type: string,
  hidden: boolean,
  lead_user_ids?: number[],
  realbase_id: number,
  realbase_type: string,
  support_user_ids?: number[],
  title: string,
}

export type TeamRequestOptions = {
  include_team_default_image?: boolean,
  include_team_team_members?: boolean,
  include_team_lead_users?: boolean,
  include_team_support_users?: boolean,
}

const state = {
  description: '',
  id: null,
  owner_id: null,
  owner_type: '',
  title: '',
  lead_user_ids: [],
  support_user_ids: [],
}

export const requiredFields = [
  { key: 'owner_id' },
  { key: 'owner_type' },
  { key: 'title' },
]

export default state
