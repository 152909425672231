import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedo, faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Link, ListItem, Text,
} from '@campaignhub/suit-theme'

import type { SuburbModel } from '../../hooks/useSuburbData'

interface SuburbListItemProps {
  boxProps?: Record<string, string>,
  callbacks: {
    deleteSuburb: (suburb: SuburbModel) => void,
    refreshSuburbData: (suburb: SuburbModel) => void,
  },
  suburb: SuburbModel,
}

const SuburbListItem = (props: SuburbListItemProps) => {
  const { boxProps, callbacks, suburb } = props

  const { deleteSuburb, refreshSuburbData } = callbacks || {}
  const {
    platform,
    postcode,
    region_name,
    state_name,
    title,
  } = suburb

  const selectedSuburbTitle = !title ? `${region_name} ${state_name} ${postcode}` : ''

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        border: '1px solid',
        justifyContent: 'center',
        marginTop: 'large',
        paddingX: 'large',
        paddingY: 'medium',
        width: '100%',
        ...boxProps,
      }}
      forceBottomBorder
      disableHover
    >
      <Box flexDirection="column">
        <Text color="bodyFontColor" fontSize="small">{title || selectedSuburbTitle}</Text>

        {platform && (
          <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="medium">{platform}</Text>
        )}
      </Box>

      {title && (
        <>
          <Link
            textProps={{ fontSize: 'small', marginRight: 'medium' }}
            greyLink
            key="resync"
            onClick={() => refreshSuburbData(suburb)}
          >
            <FontAwesomeIcon color="bodyFontColor" icon={faRedo} />
          </Link>

          <Link
            textProps={{ fontSize: 'small' }}
            greyLink
            key="delete"
            onClick={() => deleteSuburb(suburb)}
          >
            <FontAwesomeIcon color="bodyFontColor" icon={faTrashAlt} />
          </Link>
        </>

      )}
    </ListItem>
  )
}

export default SuburbListItem
