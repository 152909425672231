import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import {
  useDeepEffect, useLoadMore, useSetState, useThunkDispatch,
} from '@campaignhub/react-hooks'

import { digObject, sortArrayBy, toggleArray } from '@campaignhub/javascript-utils'

import * as organizationActions from '@redux/modules/organization'

import useEntityDefaultIntegrationPlatform from '@hooks/useEntityDefaultIntegrationPlatform'
import useIntegrations from '@hooks/useIntegrations'
import useIntegrationPlatforms from '@hooks/useIntegrationPlatforms'

const toggleAllOrganizations = (externalOrganizations, state, setState) => {
  const { selectedOrganizationsIds } = state

  if (selectedOrganizationsIds.length){
    setState({ selectedOrganizationsIds: [] })
    return
  }

  setState({ selectedOrganizationsIds: externalOrganizations.map(organization => organization.id) })
}

const toggleOrganization = (id, state, setState) => {
  const { selectedOrganizationsIds } = state
  const updatedOrganizationsIds = toggleArray(selectedOrganizationsIds, id)

  setState({ selectedOrganizationsIds: updatedOrganizationsIds })
}

const loadFromExternalPlatform = (externalPlatformKey, requestOptions, dispatch, setState) => {
  const { loadFromExternalPlatform: loadFromExternalPlatformFn } = organizationActions

  const { brand_id } = requestOptions

  if (brand_id){
    dispatch(loadFromExternalPlatformFn(externalPlatformKey, requestOptions)).then(({ success, data }) => {
      if (success){
        setState({ externalOrganizations: data, loading: false })
      }
      setState({ loading: false })
    })
  }
}

// eslint-disable-next-line max-len
const bulkImportFromExternalPlatform = (externalIds, dispatch, requestOptions, externalPlatformKey, state) => {
  const { bulkImportFromExternalPlatform: importFn } = organizationActions
  const { brandId } = state

  const payload = {
    brand_id: brandId,
    externalIds,
  }

  return dispatch(importFn(externalPlatformKey, payload, requestOptions))
}

// eslint-disable-next-line max-len
const getAlreadyImportedOrganizationIds = (reduxOrganizations, externalOrganizations, selectedIntegrationPlatformKey) => {
  const externalOrganizationsIds = externalOrganizations.map(externalOrganization => externalOrganization.id)
  const alreadyImportedExternalIds = Object.values(reduxOrganizations).reduce((acc, organization) => {
    const source_platform = digObject(organization, 'data.source_platform')
    const external_id = digObject(organization, `data.external_ids.${source_platform}`)

    if (
      source_platform === selectedIntegrationPlatformKey
      && externalOrganizationsIds.includes(external_id)
    ){
      acc.push(external_id)
    }

    return acc
  }, [])

  return alreadyImportedExternalIds
}
const defaultState = {
  active: true,
  brandId: null,
  externalOrganizations: [],
  limit: 50,
  loading: false,
  selectedOrganizationsIds: [],
  selectedIntegrationPlatformKey: '',
}

const useImportOrganizations = () => {
  const [state, setState] = useSetState(defaultState)
  const {
    active,
    brandId,
    externalOrganizations,
    limit,
    loading,
    selectedOrganizationsIds,
    selectedIntegrationPlatformKey,
  } = state

  const dispatch = useThunkDispatch()

  const {
    filteredPlatforms,
    hasIntegrations,
    urls: integrationsUrls,
  } = useIntegrations({ featureKeys: ['import_organizations'] })

  const { defaultPlatformKey } = useEntityDefaultIntegrationPlatform({ featureKey: 'import_organizations' })

  // Integration Platform
  const { callbacks: { findIntegrationPlatformByKey } } = useIntegrationPlatforms()
  const selectedIntegrationPlatform = findIntegrationPlatformByKey(selectedIntegrationPlatformKey)

  const {
    entities: { organizations: reduxOrganizations },
  } = useSelector(reduxState => reduxState)

  // Create an array of IDs for organization that have already been imported
  // Push the external ID if the organization is in the right organization, has
  // been imported from the right platform, and has an external_id
  const alreadyImportedExternalIds = getAlreadyImportedOrganizationIds(
    reduxOrganizations,
    externalOrganizations,
    selectedIntegrationPlatformKey,
  )

  const loadMorePayload = useLoadMore({
    loadedCount: externalOrganizations.length,
    filters: { active, brand_id: brandId, limit },
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
  } = loadMorePayload

  // Re-fetch organization whenever integration platform or brand change
  useEffect(() => {
    if (!!brandId && selectedIntegrationPlatformKey){
      const fetchRequestParams = {
        ...filtersWithOffset,
      }

      loadFromExternalPlatform(selectedIntegrationPlatformKey, fetchRequestParams, dispatch, setState)
      setState({ externalOrganizations: [], loading: true })
    } else {
      setState({ externalOrganizations: [], loading: false })
    }
  }, [filtersWithOffset, selectedIntegrationPlatformKey])

  // Select all organizations that have not already been imported
  useDeepEffect(() => {
    const notImportedExternalIds = externalOrganizations
      .filter(externalOrganization => !alreadyImportedExternalIds.includes(externalOrganization.id))
      .map(externalOrganization => externalOrganization.id)

    setState({ selectedOrganizationsIds: notImportedExternalIds })
  }, [externalOrganizations])

  // Check for a user/org's default integration platform,
  // if none auto select first integration platform if only one available
  useEffect(() => {
    if (defaultPlatformKey){
      setState({ selectedIntegrationPlatformKey: defaultPlatformKey })
      return
    }

    if (filteredPlatforms.length === 1){
      const selectedPlatform = filteredPlatforms[0]
      setState({ selectedIntegrationPlatformKey: selectedPlatform.key })
    }
  }, [defaultPlatformKey, filteredPlatforms.length])

  return {
    active,
    alreadyImportedExternalIds,
    brandId,
    callbacks: {
      bulkImportFromExternalPlatform: (externalIds, requestOptions) => bulkImportFromExternalPlatform(
        externalIds,
        dispatch,
        requestOptions,
        selectedIntegrationPlatformKey,
        state,
      ),
      loadMore,
      setState,
      toggleAllOrganizations: () => toggleAllOrganizations(externalOrganizations, state, setState),
      toggleOrganization: id => toggleOrganization(id, state, setState),
    },
    canLoadMore,
    externalOrganizations: sortArrayBy(externalOrganizations, 'asc', 'full_name'),
    filteredPlatforms,
    hasExternalOrganizations: !!externalOrganizations.length,
    hasIntegrations,
    hasSelectedOrganizations: !!selectedOrganizationsIds.length,
    integrationsUrls,
    limit,
    loading,
    selectedOrganizationsIds,
    selectedIntegrationPlatform,
    selectedIntegrationPlatformKey,
  }
}

export default useImportOrganizations
