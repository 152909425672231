import { useContext } from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faHome, faShare } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, FormField, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import PdfDownloadContext from '@contexts/pdfDownloadContext'

import DigitalTemplateTile from '@components/DigitalTemplateTile'

import { generateUrls } from '@hooks/useProject'
import useDigitalPage, { useDigitalPageForm } from '@hooks/useDigitalPage'
import useDigitalTemplate from '@hooks/useDigitalTemplate'
import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const EditDigitalPageModal = (props) => {
  const {
    callbacks, disableAnimation, disableOverlay, modalKey, showModal,
  } = props
  const { updateDigitalPage } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const { digitalPage: initDigitalPage } = digObject(modalData, `${modalKey}`, {})
  const {
    callbacks: {
      manageShareLinks,
      showSelectDigitalTemplateModal,
      updateDigitalPage: updateFn,
    },
    digitalPage,
    digitalTemplate,
    digitalTemplateType,
    filteredStatuses,
    parentDigitalTemplateType,
    project: { id: projectId },
    updating,
  } = useDigitalPage(initDigitalPage)

  const { previewImage } = useDigitalTemplate(digitalTemplate)

  const {
    entityState,
    entityState: { title, status_id },
    errors,
    handlers,
    saveEnabled,
  } = useDigitalPageForm(digitalPage)

  const pdfDownloadContext = useContext(PdfDownloadContext)
  const {
    callbacks: { buildPdf },
  } = pdfDownloadContext

  const { overviewUrl } = generateUrls({ id: projectId })

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal
      callbacks={callbacks}
      disableAnimation={disableAnimation}
      disableOverlay={disableOverlay}
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.header?.title || 'Edit'}
        titleSecondLine={digitalTemplateType.title || strings.header?.titleSecondLine}
      />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <FormField label={strings.formFieldLabels?.digitalTemplate || 'Page Template'}>
            <DigitalTemplateTile
              key={digitalTemplate.id}
              digitalTemplate={digitalTemplate}
              onClick={() => showSelectDigitalTemplateModal(parentDigitalTemplateType.key)}
              previewImage={previewImage}
            />
          </FormField>

          <FormField
            direction="column"
            boxProps={{ marginBottom: 'large' }}
            errorMessage={errors.title}
            label={strings.formFieldLabels?.title || 'Page Title'}
          >
            <input data-validate-field-on="change" name="title" type="text" value={title} {...handlers} />
          </FormField>

          <FormField
            direction="column"
            boxProps={{ marginBottom: 'large' }}
            errorMessage={errors.status_id}
            label={strings.formFieldLabels?.status || 'Status'}
          >
            <select name="status_id" value={status_id || ''} {...handlers} data-validate-field-on="change">
              <option value="">{strings.option || 'Please Select...'}</option>
              {filteredStatuses.map(status => (
                <option key={status.id} value={status.id}>
                  {status.title}
                </option>
              ))}
            </select>
          </FormField>
        </Box>

        <FormField direction="column" label={strings.formFieldLabels?.moreOptions || 'More Options'}>
          <Box flexDirection="column" flexShrink={0}>
            <Button
              buttonStyle="secondaryUtility"
              icon={<FontAwesomeIcon icon={faShare} />}
              onClick={() => manageShareLinks()}
              size="medium"
              marginBottom="medium"
            >
              {strings.buttons?.manageShareLinks || 'Manage Share Links'}
            </Button>

            <Button
              buttonStyle="secondaryUtility"
              onClick={() => buildPdf(digitalPage)}
              icon={<FontAwesomeIcon icon={faDownload} />}
              size="medium"
              marginBottom="medium"
            >
              {strings.buttons?.download || 'Download PDF'}
            </Button>

            <Button
              as="a"
              buttonStyle="secondaryUtility"
              href={overviewUrl}
              icon={<FontAwesomeIcon icon={faHome} />}
              size="medium"
              width="auto"
            >
              {strings?.goToProject || 'Go to Project'}
            </Button>
          </Box>
        </FormField>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={updating}
          loadingBubbleColor="white"
          onClick={() => updateDigitalPage(entityState, updateFn)}
          size="large"
        >
          {strings.buttons?.save || 'Save'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

EditDigitalPageModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  disableAnimation: PropTypes.bool,
  disableOverlay: PropTypes.bool,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

EditDigitalPageModal.defaultProps = {
  modalKey: 'EditDigitalPageModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <EditDigitalPageModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
