export default {
  default: {
    button: {
      save: 'Save',
    },
    sideBarModalHeader: {
      titleForm: 'Form',
      titleCreate: 'Create',
      titleSelect: 'Select',
      subtitleTemplate: 'Template',
      subtitleFormTemplate: 'Form Template',
    },
  },
}
