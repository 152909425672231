import { useSelector } from 'react-redux'

import {
  Box, ListItem, Text,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import useIntegrationPlatform from '@hooks/useIntegrationPlatform'

import IntegrationPlatformLogo from '@components/IntegrationPlatformLogo'

import type { IntegrationModel } from '@models/integration'
import Integration from '../Integration'

interface integrationPlatformProps {
  groupedIntegration: {
    id: number,
    integrations?: Array<IntegrationModel> | undefined,
  },
}

const IntegrationPlatform = (props: integrationPlatformProps) => {
  const {
    groupedIntegration: { id, integrations },
  } = props

  const { integrationPlatforms } = useSelector(reduxState => reduxState.entities)
  const integrationPlatform = integrationPlatforms[id] || {}
  const { key, title } = integrationPlatform

  const { supportedFeaturesCount } = useIntegrationPlatform(integrationPlatform)

  const {
    callbacks: { formatCount },
  } = useLocalization()

  if (integrations.length){
    return (
      <Box variant="white" marginBottom="large" flexDirection="column">
        <Box alignItems="center">
          <IntegrationPlatformLogo
            backgroundSize="contain"
            integrationPlatformKey={key}
            height={68}
            width={100}
          />

          <ListItem
            boxProps={{
              border: '1px solid',
              borderTop: 0,
              borderRight: 0,
              padding: 'large',
              alignItems: 'center',
            }}
            disableHover
          >
            <Box flexDirection="column" justifyContent="center">
              <Text fontSize="small">{title}</Text>
              <Box alignItems="center" fontSize="small" marginTop="medium">
                <Text color="bodyFontLightColor">
                  {formatCount('supportedFeatures', { count: supportedFeaturesCount })}
                </Text>
              </Box>
            </Box>
          </ListItem>
        </Box>

        <Box borderTop="1px solid" borderColor="lineColor" flexDirection="column" maxHeight={300} overflow="auto">
          {integrations.map(integration => (
            <Integration key={integration.id} integration={integration} />
          ))}
        </Box>
      </Box>
    )
  }

  return null
}

export default IntegrationPlatform
