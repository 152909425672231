import React from 'react'

import { BlankState } from '@campaignhub/suit-theme'
import useLocalization from '@hooks/useLocalization'

import BlankStateImageUrl from './assets/no-integrations.svg'
import localizedStrings from './localizedStrings'

const TemplateBlankState = () => {
  const { strings } = useLocalization(localizedStrings)

  return (
    <BlankState
      boxProps={{ flexDirection: 'column-reverse' }}
      contentProps={{ width: '100%', marginTop: 'large' }}
      forceMobile
      imageUrl={BlankStateImageUrl}
      sidebar
    >
      <BlankState.Title>{strings.title}</BlankState.Title>

      <BlankState.Paragraph>
        {strings.paragraph}
      </BlankState.Paragraph>
    </BlankState>
  )
}

export default TemplateBlankState
