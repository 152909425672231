export default {
  default: {
    remove: 'Remove',
    agebcyUser: 'Agency user',
    label: {
      targetUser: '* Target User',
    },
    toast: {
      orgainzationUserDeleted: 'User Removed from {global.organizationType.shortName.singular}',
    },
    sweetAlert: {
      confirmButton: 'Yes, remove from {global.organizationType.shortName.singular}.',
      text: 'Are you sure? ',
      title: 'Remove User From {global.organizationType.shortName.singular}?',
    },
    removeUser: 'Remove User',
    noticeBox: {
      description: 'Please select a user to transfer {user_name}\'s items to.',
      paragraphOne: 'All projects, case studies and contacts that are assigned to this user will be assigned to the new user selected.',
      paragraphTwo: 'Note: Any awards and integrations will be deleted, as well as the user being removed from any reviews and teams they are assigned to.',
      thisAction: 'This action ',
      boldText: 'cannot be undone.',
    },
  },
}
