import React from 'react'
import { Route, Routes } from 'react-router-dom'

import QuoteEdit from './screens/QuoteEdit'
import QuoteList from './screens/QuoteList'

const QuoteTemplates = () => (
  <Routes>
    <Route index element={<QuoteList />} />
    <Route path=":quoteId" element={<QuoteEdit />} />
  </Routes>
)

export default QuoteTemplates
