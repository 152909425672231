import {
  faAnalytics,
  faBuilding,
  faChartBar,
  faCog,
  faDatabase,
  faFileAlt,
  faFileChartLine,
  faGlobe,
  faHome,
  faImages,
  faNfcPen,
  faPencil,
  faTachometer,
  faUsers,
  faUserTag,
} from '@fortawesome/pro-light-svg-icons'

const generateMainNavigationItems = (isAdmin: boolean) => ({
  sections: [
    {
      key: 'dashboard',
      visible: true,
      items: [
        {
          href: '#/',
          icon: faTachometer,
          key: 'dashboard',
          title: 'Overview',
          visible: isAdmin,
        },
      ],
    },
    {
      key: 'admin',
      title: 'Admin',
      visible: isAdmin,
      items: [
        {
          href: '#/digitalTemplates/template-list',
          icon: faHome,
          key: 'digital-templates',
          title: 'Digital Templates',
          visible: true,
        },
        {
          href: '#/systemManager/formTemplates',
          icon: faFileAlt,
          key: 'form-templates',
          title: 'Form Templates',
          visible: true,
        },
        {
          href: '#/systemManager/componentStyles',
          icon: faNfcPen,
          key: 'component-styles',
          title: 'Component Styles',
          visible: true,
        },
      ],
    },
    {
      key: 'system-manager',
      title: isAdmin ? 'System Manager' : 'Brand Manager',
      visible: true,
      items: [
        {
          href: '#/systemManager/analytics',
          icon: faChartBar,
          key: 'analytics',
          title: 'Analytics',
          visible: isAdmin,
        },
        {
          href: '#/systemManager/apiLogs',
          icon: faDatabase,
          key: 'apiLogs',
          title: 'API Logs',
          visible: isAdmin,
        },
        {
          href: '#/systemManager/brands',
          icon: faBuilding,
          key: 'brands',
          title: 'Brands',
          visible: isAdmin,
        },
        {
          href: '#/systemManager/customFieldSets',
          icon: faPencil,
          key: 'custom-fields',
          title: 'Custom Fields',
          visible: isAdmin,
        },
        {
          href: '#/systemManager/organizations',
          icon: faGlobe,
          key: 'organizations',
          title: 'Organizations',
          visible: true,
        },
        {
          href: '#/systemManager/reports',
          icon: faFileChartLine,
          key: 'reports',
          title: 'Reports',
          visible: true,
        },
        {
          href: '#/systemManager/targetAudiences',
          icon: faUserTag,
          key: 'target-audiences',
          title: 'Target Audiences',
          visible: isAdmin,
        },
        {
          href: '#/systemManager/users',
          icon: faUsers,
          key: 'users',
          title: 'Users',
          visible: isAdmin,
        },
      ],
    },
    {
      key: 'libraries',
      title: 'Libraries',
      visible: true,
      items: [
        {
          href: '#/systemManager/imageLibraries',
          icon: faImages,
          key: 'image-libraries',
          title: 'Image Library',
          visible: isAdmin,
        },
        {
          href: '#/systemManager/dataLibraries',
          icon: faAnalytics,
          key: 'data-libraries',
          title: 'Data Library',
          visible: true,
        },
      ],
    },
    {
      key: 'advanced',
      title: 'Advanced',
      visible: isAdmin,
      items: [
        {
          href: '#/systemManager/integrations',
          icon: faCog,
          key: 'integrations',
          title: 'Integrations',
          visible: true,
        },
      ],
    },
  ],
})

export default generateMainNavigationItems
