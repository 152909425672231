const defaultRequestOptions = {
  attachment: {
    include_attachment_file: true,
  },
  digitalTemplate: {
    include_digital_template_digital_template_components: true,
    include_digital_template_origin_template: true,
    include_digital_template_pages: true,
    include_digital_template_images: true,
    include_digital_template_image_sizes: ['thumb_640x360', 'thumb_1280x720', 'thumb_2000x1500', 'thumb_3500x2500'],
  },
  digitalTemplatePage: {
    include_digital_template_page_attachments: true,
    include_digital_template_page_images: true,
    include_digital_template_page_section_groups: true,
  },
}

export default defaultRequestOptions
