import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, Button, FormField, SidebarModal, SidebarNoticeBox,
} from '@campaignhub/suit-theme'

import useUserImpersonation from '@hooks/useUserImpersonation'

const Admin = (props) => {
  const { supportToken, user } = props

  const userImpersonationPayload = useUserImpersonation()
  const { callbacks: { impersonateUser } } = userImpersonationPayload

  return (
    <>
      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <SidebarNoticeBox>
            <SidebarNoticeBox.Title>Support Token Supplied</SidebarNoticeBox.Title>

            <SidebarNoticeBox.Paragraph>
              A user has supplied a support token so that you can assist them. Please note that tokens will expire in 24
              hours.
            </SidebarNoticeBox.Paragraph>
          </SidebarNoticeBox>

          <FormField direction="column" label="User">
            <input defaultValue={user.full_name} disabled />
          </FormField>

          <FormField direction="column" label="Support Token" marginTop="large">
            <input defaultValue={supportToken} disabled />
          </FormField>
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryEdit"
          disabled={!supportToken}
          onClick={() => impersonateUser(supportToken)}
          size="large"
        >
          Login as User
        </Button>
      </SidebarModal.Footer>
    </>
  )
}

Admin.propTypes = {
  supportToken: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
}

export default Admin
