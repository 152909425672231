export default {
  default: {
    teamMemberCount: {
      one: '1 Member',
      many: '{count} Members',
      none: '0 Members',
      type: 'count',
    },
  },
}
