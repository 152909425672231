import PropTypes from 'prop-types'

import { faCalendar } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Columns, LoadingModule, PageHeader,
} from '@campaignhub/suit-theme'

import useEventCalendar from '@hooks/useEventCalendar'
import useEventCalendars from '@hooks/useEventCalendars'
import useLocalization from '@hooks/useLocalization'
import useVideoModules from '@hooks/useVideoModules'

import VideoModule from '@components/VideoModule'

import EventCalendarBlankState from '../EventCalendarBlankState'
import EventCalendarListItem from '../EventCalendarListItem'

import localizedStrings from './localizedStrings'

const PageContent = (props) => {
  const { project, projectId } = props

  const eventCalendarsPayload = useEventCalendars({
    filters: {
      ownerId: project.organization_id,
      ownerType: 'Organization',
      subjectId: projectId,
      subjectType: 'Project',
    },
    performHttpRequests: !!project.organization_id,
  })

  const {
    filteredEventCalendars, hasEventCalendars, loaded, loading,
  } = eventCalendarsPayload

  const {
    callbacks: { createCalendar },
    urls: { eventsIndexUrl },
  } = useEventCalendar({
    owner_id: project.organization_id,
    owner_type: 'Organization',
    subject_id: projectId,
    subject_type: 'Project',
  })

  const videoModulePayload = useVideoModules({ key: 'events' })
  const { videosPayload } = videoModulePayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      <PageHeader
        actionContent={(
          <Button buttonStyle="primaryCreate" onClick={() => createCalendar()} size="medium" width="auto">
            {strings.buttons?.create || 'Create'}
          </Button>
        )}
        activeTabBarItemKey="events"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        nestedNavigation
        tabBarItems={[
          {
            href: eventsIndexUrl,
            icon: faCalendar,
            key: 'events',
            title: strings.title || 'Event Calendar List',
          },
        ]}
        title={strings.title || 'Event Calendar'}
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && loaded && !hasEventCalendars && <EventCalendarBlankState />}

            {!loading
              && loaded
              && hasEventCalendars
              && filteredEventCalendars.map(calendar => (
                <EventCalendarListItem key={calendar.id} eventCalendar={calendar} />
              ))}
          </Columns.Main>

          <Columns.Sidebar>
            <VideoModule marginTop={['large', 0]} videosPayload={videosPayload} />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

PageContent.propTypes = {
  project: PropTypes.object.isRequired,
  projectId: PropTypes.number,
}

export default PageContent
