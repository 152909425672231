import React from 'react'

import { BlankState } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

import documentsImageUrl from './assets/documents.svg'

const DocumentsBlankState = () => {
  const { strings } = useLocalization(localizedStrings)

  return (
    <BlankState imageUrl={documentsImageUrl}>
      <BlankState.Title>{strings.title || 'No Files Uploaded'}</BlankState.Title>

      <BlankState.Paragraph>
        {strings.paragraph
          || 'You don’t have any files stored against this project. You can upload files and use them in digital pages to send more information to clients easily.'}
      </BlankState.Paragraph>
    </BlankState>
  )
}

export default DocumentsBlankState
