import { useContext } from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'
import { DateTime } from 'luxon'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'
import {
  Box, Button, FormField, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'
import useQuote from '@hooks/useQuote'
import useQuoteItem, { useQuoteItemForm } from '@hooks/useQuoteItem'

import handleCallbackAction from '@functions/handleCallbackAction'
import localizedStrings from './localizedStrings'

const confirmDelete = (deleteQuoteItem, deleteFn, strings) => {
  swal
    .fire({
      title: strings.sweetAlert?.title || 'Delete Item?',
      text: strings.sweetAlert?.text || 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: strings.sweetAlert?.confirm || 'Yes, Delete it.',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        deleteQuoteItem(deleteFn)
      }
    })
}

const CreateOrEditQuoteItemModal = (props) => {
  const {
    callbacks,
    callbacks: { closeModal, deleteQuoteItem },
    clickSafeZone,
    modalKey,
    showModal,
  } = props

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, `${modalKey}`, {})
  const { callbacks: modalCallbacks, quoteItem = {} } = modalPayload
  const { createQuoteItem: createFn, updateQuoteItem: updateFn } = modalCallbacks || {}

  const newRecord = !quoteItem.id

  const quoteItemPayload = useQuoteItem(quoteItem)
  const {
    callbacks: { deleteQuoteItem: deleteFn },
    creating,
    deleting,
    updating,
  } = quoteItemPayload

  const quoteItemFormPayload = useQuoteItemForm(quoteItem)
  const {
    entityState,
    entityState: {
      description, price, item_date, title,
    },
    errors,
    handlers,
    saveEnabled,
  } = quoteItemFormPayload

  const { quote: { saved_template } } = useQuote({ id: entityState?.quote_id })
  const quoteType = saved_template ? 'Template' : 'Quote'

  const { strings } = useLocalization(localizedStrings)

  const toastText = newRecord
    ? strings.toastText?.created || 'Quote Saved and New Line Added.'
    : strings.toastText?.updated || 'Quote Item Updated.'

  const createOrUpdateQuoteItemPayload = {
    callbacks: {
      action: newRecord ? createFn : updateFn,
      afterAction: () => closeModal(),
    },
    entityParams: { ...entityState, quoteType },
    requestOptions: {
      include_quote_item_quote_total: true,
    },
    toastText,
  }

  return (
    <SidebarModal
      callbacks={callbacks}
      clickSafeZone={clickSafeZone}
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header
        callbacks={callbacks}
        title={newRecord ? strings.createTitle || 'Create' : strings.editTitle || 'Edit'}
        titleSecondLine={strings.titleSecondLine || 'Quote Item'}
      />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <FormField direction="column" errorMessage={errors.title} label={strings.formFieldLabels?.title || '* Title'}>
            <input name="title" type="text" value={title} {...handlers} />
          </FormField>

          <FormField
            direction="column"
            errorMessage={errors.price}
            label={strings.formFieldLabels?.price || 'Price'}
            marginTop="large"
          >
            <input name="price" type="number" value={price} {...handlers} />
          </FormField>

          <FormField
            direction="column"
            errorMessage={errors.item_date}
            label={strings.formFieldLabels?.date || 'Date'}
            marginTop="large"
          >
            <input
              format="yyyy-MM-dd"
              min={DateTime.now().toFormat('yyyy-MM-dd')}
              name="item_date"
              type="date"
              value={item_date}
              {...handlers}
            />
          </FormField>

          <FormField direction="column" label={strings.formFieldLabels?.description || 'Description'} marginTop="large">
            <textarea name="description" type="text" value={description} style={{ height: 80 }} {...handlers} />
          </FormField>

          {!newRecord && (
            <FormField
              direction="column"
              label={strings.formFieldLabels?.moreOptions || 'More Options'}
              marginTop="large"
            >
              <Button
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faTrashAlt} />}
                marginBottom="medium"
                onClick={() => confirmDelete(deleteQuoteItem, deleteFn, strings)}
                size="medium"
              >
                {strings.deleteButton || 'Delete Item'}
              </Button>
            </FormField>
          )}
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={creating || deleting || updating}
          loadingBubbleColor="white"
          onClick={() => handleCallbackAction(createOrUpdateQuoteItemPayload)}
          size="large"
        >
          {strings.saveButton || 'Save Item'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

CreateOrEditQuoteItemModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  clickSafeZone: PropTypes.bool,
  modalKey: PropTypes.string,
  quoteItem: PropTypes.object,
  showModal: PropTypes.bool,
}

CreateOrEditQuoteItemModal.defaultProps = {
  modalKey: 'CreateOrEditQuoteItemModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <CreateOrEditQuoteItemModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
