import { useMemo } from 'react'

import {
  digObject, matchFilterArrayIncludes, matchFilterString, sortArrayBy,
} from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { ReviewModel, ReviewRequestOptions } from '@models/types'
import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['reviews']

type ReviewFilters = {
  full_address?: string,
  hidden?: boolean,
  user_id?: number,
}

type UseReviewsOptions = {
  filters?: ReviewFilters,
  performHttpRequests?: boolean,
  requestOptions?: ReviewRequestOptions,
}

function useReviews(options: UseReviewsOptions = {}) {
  const { filters = {}, requestOptions } = options
  const {
    full_address: filterFullAddress,
    hidden: filterHidden,
    user_id: filterUserId,
  } = filters

  const { addresses, reviews } = useSelector(reduxState => reduxState.entities)

  const {
    updatedEntities: { reviews: reviewsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const filteredReviews = useMemo(() => {
    const filtered = Object.values(reviews).filter((review: ReviewModel) => {
      const { address_id, hidden } = review
      const userIds = digObject(review, 'user_ids', [])
      const reviewAddress = address_id ? addresses[address_id] || {} : {}

      const addressMatch = matchFilterString(reviewAddress.full_address, filterFullAddress)
      const matchHidden = typeof filterHidden !== 'undefined' ? hidden === filterHidden : true
      const userMatch = matchFilterArrayIncludes(userIds, Number(filterUserId))

      return addressMatch && matchHidden && userMatch
    })

    return sortArrayBy(filtered, 'asc', 'title')
  }, [reviewsUpdatedAt, JSON.stringify(filters)])

  const filteredReviewsCount = filteredReviews.length
  const hasFilteredReviews = !!filteredReviewsCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredReviewsCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingReviews } = useReduxAction(
    'reviews',
    'loadReviews',
    {
      ...requestOptions,
      ...filtersWithOffset,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredReviews,
    filteredReviewsCount,
    hasReviews: hasFilteredReviews,
    loading: loadingReviews,
    reviews,
  }
}

export default useReviews
