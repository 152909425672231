import { useMemo } from 'react'

import { useModals, useSetState } from '@campaignhub/react-hooks'
import { ModalContext } from '@campaignhub/suit-theme'

import handleCallbackAction from '@functions/handleCallbackAction'

import PageContext from '@contexts/pageContext'

import CreateOrEditAddressModal from '@modals/CreateOrEditAddressModal'

import PageContent from './components/PageContent'

const callbacks = (component, setState) => {
  const componentCallbacks = {
    CreateOrEditAddressModal: {
      closeModal: () => setState({ showCreateOrEditAddressModal: false }),
      createAddress: payload => handleCallbackAction(payload),
      updateAddress: payload => handleCallbackAction(payload),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  showCreateOrEditAddressModal: false,
}

const OrganizationEdit = () => {
  const [state, setState] = useSetState(defaultState)
  const { showCreateOrEditAddressModal } = state

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const pageContext = useMemo(
    () => ({
      callbacks: {
        showCreateOrEditAddressModal: (payload) => {
          setModalData('CreateOrEditAddressModal', payload)
          setState({ showCreateOrEditAddressModal: true })
        },
      },
    }),
    [],
  )

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent />

        <CreateOrEditAddressModal
          callbacks={callbacks('CreateOrEditAddressModal', setState)}
          hiddenFields={[]}
          showModal={showCreateOrEditAddressModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default OrganizationEdit
