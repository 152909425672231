import React from 'react'
import PropTypes from 'prop-types'

import { Box, Button, DashboardModule, Link } from '@campaignhub/suit-theme'

import useEntityExternalPlatforms from '@hooks/useEntityExternalPlatforms'
import useLocalization from '@hooks/useLocalization'

import ExternalPlatform from './ExternalPlatform'

import localizedStrings from './localizedStrings'

const ExternalPlatforms = (props) => {
  const { callbacks: { manageProjectExternalPlatforms }, project } = props

  const externalPlatformPayload = useEntityExternalPlatforms(project)
  const {
    externalPlatforms, hasExternalPlatforms, hasSourcePlatform, sourcePlatform,
  } = externalPlatformPayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      <DashboardModule
        headerRight={hasSourcePlatform && (
          <Link onClick={() => manageProjectExternalPlatforms('source')} textProps={{ fontSize: 'small' }}>
            {strings.manage || 'Manage'}
          </Link>
        )}
        title={strings.sourcePlatform || 'Source Platform'}
      >
        <Box flexDirection="column">
          {hasSourcePlatform && (
            <ExternalPlatform
              externalId={sourcePlatform.externalId}
              integrationPlatform={sourcePlatform.integrationPlatform}
            />
          )}

          {!hasSourcePlatform && (
            <Box padding="large">
              <Button
                buttonStyle="secondaryUtility"
                onClick={() => manageProjectExternalPlatforms('source')}
                size="medium"
              >
                {strings.buttons?.addExport || 'Add Source Platform'}
              </Button>
            </Box>
          )}
        </Box>
      </DashboardModule>

      <DashboardModule
        headerRight={hasExternalPlatforms && (
          <Link onClick={() => manageProjectExternalPlatforms('export')} textProps={{ fontSize: 'small' }}>
            {strings.manage || 'Manage'}
          </Link>
        )}
        title={strings.connectedPlatforms || 'Connected Platforms'}
      >
        <Box flexDirection="column">
          {externalPlatforms.map((exportPlatform) => {
            const { externalId, integrationPlatform } = exportPlatform

            return (
              <ExternalPlatform
                key={integrationPlatform.id}
                externalId={externalId}
                integrationPlatform={integrationPlatform}
              />
            )
          })}

          {!hasExternalPlatforms && (
            <Box padding="large">
              <Button
                buttonStyle="secondaryUtility"
                onClick={() => manageProjectExternalPlatforms('export')}
                size="medium"
              >
                {strings.buttons?.addConnected || 'Add Connected Platform'}
              </Button>
            </Box>
          )}
        </Box>
      </DashboardModule>
    </>
  )
}

ExternalPlatforms.propTypes = {
  callbacks: PropTypes.shape({
    manageProjectExternalPlatforms: PropTypes.func.isRequired,
  }).isRequired,
  project: PropTypes.object.isRequired,
}

export default ExternalPlatforms
