import PropTypes from 'prop-types'

const YouTubePlayer = (props) => {
  const {
    showControls, videoId, width, height,
  } = props

  return (
    <iframe
      allow="autoplay; encrypted-media"
      allowFullScreen
      frameBorder="0"
      height={height}
      src={`https://www.youtube.com/embed/${videoId}?rel=0&amp;controls=${showControls ? 1 : 0}&amp;showinfo=0`}
      title="Video"
      width={width}
    />
  )
}

YouTubePlayer.propTypes = {
  videoId: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  showControls: PropTypes.bool,
}

YouTubePlayer.defaultProps = {
  showControls: false,
}

export default YouTubePlayer
