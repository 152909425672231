import { useMemo } from 'react'
import { toast } from 'react-toastify'

import { useModals, useSetState } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import useReduxAction from '@hooks/useReduxAction'

import ActivitiesModal from '@modals/ActivitiesModal'
import CreateOrEditComponentStyleModal from '@modals/CreateOrEditComponentStyleModal'

import PageContent from './components/PageContent'

const createDigitalTemplateComponentStyle = (params) => {
  const { componentParams, createFn, setState } = params
  createFn(componentParams).then(({ success, errors }) => {
    if (!success){
      toast.warning(errors[0])
      return
    }
    toast('Component Style Created.')
    setState({ showCreateOrEditComponentStyleModal: false })
  })
}

const forkDigitalTemplateComponent = (params) => {
  const { componentParams, forkFn, setState } = params

  forkFn(componentParams).then(({ success, errors }) => {
    if (!success){
      toast.warn(errors[0])
      return
    }

    toast('Component Style Cloned.')
    setState({ showCreateOrEditComponentStyleModal: false })
  })
}

const updateDigitalTemplateComponent = (params) => {
  const {
    componentParams, requestOptions, setState, updateFn,
  } = params
  updateFn(componentParams, requestOptions).then(({ success, errors }) => {
    if (!success){
      toast.warning(errors[0])
      return
    }
    toast('Component Style Updated.')
    setState({ showCreateOrEditComponentStyleModal: false })
  })
}

const callbacks = (component, setState) => {
  const componentCallbacks = {
    ActivitiesModal: {
      closeModal: () => setState({ showActivitiesModal: false }),
    },
    CreateOrEditComponentStyleModal: {
      closeModal: () => setState({ showCreateOrEditComponentStyleModal: false }),
      createDigitalTemplateComponentStyle: (componentParams, createFn) => createDigitalTemplateComponentStyle({
        componentParams,
        createFn,
        setState,
      }),
      updateDigitalTemplateComponent: (componentParams, updateFn, requestOptions) => updateDigitalTemplateComponent({
        componentParams, requestOptions, setState, updateFn,
      }),
      forkDigitalTemplateComponent: (componentParams, forkFn) => forkDigitalTemplateComponent({
        componentParams,
        forkFn,
        setState,
      }),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  pageFilters: {},
  showActivitiesModal: false,
  showCreateOrEditComponentStyleModal: false,
}

const ComponentStyles = () => {
  const [state, setState] = useSetState(defaultState)
  const { pageFilters, showActivitiesModal, showCreateOrEditComponentStyleModal } = state

  useReduxAction('brands', 'loadBrands', { ...pageFilters }, [JSON.stringify(pageFilters)])

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const pageContext = useMemo(
    () => ({
      callbacks: {
        showActivitiesModal: (payload) => {
          setModalData('ActivitiesModal', payload)
          setState({ showActivitiesModal: true })
        },
        resetFilters: (resetFn) => {
          if (resetFn) resetFn()
          setState({ pageFilters: {} })
        },
        showCreateOrEditComponentStyleModal: (payload) => {
          setModalData('CreateOrEditComponentStyleModal', payload)
          setState({ showCreateOrEditComponentStyleModal: true })
        },
        updateFilters: (filters) => {
          setState({ pageFilters: filters })
        },
      },
    }),
    [],
  )

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent pageFilters={pageFilters} />

        <ActivitiesModal callbacks={callbacks('ActivitiesModal', setState)} showModal={showActivitiesModal} />

        <CreateOrEditComponentStyleModal
          callbacks={callbacks('CreateOrEditComponentStyleModal', setState, {})}
          showModal={showCreateOrEditComponentStyleModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default ComponentStyles
