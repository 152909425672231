export default {
  default: {
    formFieldLabels: {
      findAssetLibrary: 'Find Image Library',
    },
    mapResults: {
      organization: {
        title: '{global.organizationType.shortName.singular}',
        secondaryText: '{global.organizationType.shortName.singular} Library',
      },
      brand: {
        title: 'Brand',
        secondaryText: 'Brand Library',
      },
    },
    imageLibrarySearchPlaceholder: 'Search for image library',
  },
}
