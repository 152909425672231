import { useSelector } from 'react-redux'

import { useLatestEntity } from '@campaignhub/react-hooks'

export function mapEntityIdsToArray(sections, entities){
  if (!sections) return []

  return sections.map((section) => {
    if (typeof section === 'object'){
      return section
    }

    return entities[section]
  }).filter(s => s)
}

export const sortSections = sectionArray => sectionArray.sort((a, b) => {
  const sortA = a.options ? a.options.sort : 0
  const sortB = b.options ? b.options.sort : 0

  return sortA > sortB ? 1 : -1
})

function useDigitalTemplatePageSectionGroup(initSectionGroup, options = {}){
  const { useSuppliedEntity } = options || {}

  const { entity: latestSectionGroup } = useLatestEntity(initSectionGroup, 'digitalTemplatePageSectionGroups')
  const sectionGroup = useSuppliedEntity ? initSectionGroup : latestSectionGroup

  const { id, options: sectionGroupOptions, sections } = sectionGroup
  const { sticky, sortable } = sectionGroupOptions || {}

  const entities = useSelector(reduxState => reduxState.entities)
  const { digitalTemplatePageSections } = entities

  const editorEntity = { type: 'digitalTemplatePageSectionGroup', id }

  const sectionArray = mapEntityIdsToArray(sections, digitalTemplatePageSections)
  const sortedSections = sortSections(sectionArray)

  return {
    digitalTemplatePageSectionGroup: sectionGroup,
    editorEntity,
    sortable: sortable || false,
    sortedSections,
    sticky: sticky || false,
  }
}

export default useDigitalTemplatePageSectionGroup
