import { useMemo } from 'react'

import {
  matchFilterKey, matchFilterNumber, sortArrayBy,
} from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { ShareLinkModel, ShareLinkRequestOptions } from '@models/types'
import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['shareLinks']

type ShareLinkFilters = {
  printLink?: boolean,
  subjectId?: number,
  subjectType?: string,
  trackingEnabled?: boolean,
}

type UseShareLinksOptions = {
  filters?: ShareLinkFilters,
  performHttpRequests?: boolean,
  requestOptions?: ShareLinkRequestOptions,
}

function useShareLinks(options: UseShareLinksOptions = {}) {
  const { filters = {}, requestOptions } = options
  const {
    printLink: filterPrintLink,
    subjectId: filterSubjectId,
    subjectType: filterSubjectType,
    trackingEnabled: filterTrackingEnabled,
  } = filters

  const {
    updatedEntities: { shareLinks: shareLinksUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { shareLinks } = useSelector(reduxState => reduxState.entities)

  const filteredShareLinks = useMemo(() => {
    const filtered = Object.values(shareLinks).filter((shareLink: ShareLinkModel) => {
      const {
        subject_id, subject_type, tracking_enabled, print_link,
      } = shareLink

      const subjectIdMatch = matchFilterNumber(subject_id, filterSubjectId)
      const subjectTypeMatch = matchFilterKey(subject_type, filterSubjectType)
      const trackingMatch = filterTrackingEnabled ? filterTrackingEnabled === tracking_enabled : true
      const printMatch = typeof filterPrintLink !== 'undefined' ? filterPrintLink === !!print_link : true

      return printMatch && subjectIdMatch && subjectTypeMatch && trackingMatch
    })

    return sortArrayBy(filtered, 'asc', 'default')
  }, [shareLinksUpdatedAt, JSON.stringify(filters)])

  const filteredShareLinksCount = filteredShareLinks.length
  const hasFilteredShareLinks = !!filteredShareLinksCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredShareLinksCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingShareLinks } = useReduxAction(
    'shareLinks',
    'loadShareLinks',
    {
      ...requestOptions,
      ...filtersWithOffset,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredShareLinks,
    filteredShareLinksCount,
    hasFilteredShareLinks,
    loading: loadingShareLinks,
  }
}

export default useShareLinks
