import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import { Box, ListItem, Text } from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const DocumentTemplate = (props) => {
  const {
    callbacks: { selectDocumentTemplate },
    documentTemplate: { dates, id, title },
  } = props

  const createdDateTime = digObject(dates, 'created.date_time_with_timezone')

  const {
    callbacks: { formatDate, formatString },
    strings,
  } = useLocalization(localizedStrings)

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        borderBottom: '1px dashed',
        paddingX: 'medium',
        paddingY: 'medium',
      }}
      onClick={() => selectDocumentTemplate()}
      showAngleIcon
    >
      <Box justifyContent="center" flexDirection="column">
        <Text fontSize="small" lineHeight={1.3}>
          {title}
        </Text>

        <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="medium">
          {`${formatString(strings.templateId, { id })} | ${strings.created || 'Created'} ${formatDate(
            createdDateTime,
            DateTime.DATE_FULL,
          )}`}
        </Text>
      </Box>
    </ListItem>
  )
}

DocumentTemplate.propTypes = {
  callbacks: PropTypes.shape({
    selectDocumentTemplate: PropTypes.func.isRequired,
  }).isRequired,
  documentTemplate: PropTypes.shape({
    dates: PropTypes.object.isRequired,
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
}

export default DocumentTemplate
