import { Schemas } from '@redux/schema'

const entitySchema = {
  digitalTemplatePage: Schemas.DIGITAL_TEMPLATE_PAGE,
  digitalTemplatePageItem: Schemas.DIGITAL_TEMPLATE_PAGE_ITEM,
  digitalTemplatePageSection: Schemas.DIGITAL_TEMPLATE_PAGE_SECTION,
  digitalTemplatePageSectionGroup: Schemas.DIGITAL_TEMPLATE_PAGE_SECTION_GROUP,
}

export default entitySchema
