const events = [
  { key: 'case_studies.fetch', label: 'case_studies.fetch' },
  { key: 'case_study.import', label: 'case_study.import' },
  { key: 'comparables.fetch', label: 'comparables.fetch' },
  { key: 'comparables.import', label: 'comparables.import' },
  { key: 'comparables.search', label: 'comparables.search' },
  { key: 'digital_page.convert_to_pdf', label: 'digital_page.convert_to_pdf' },
  { key: 'digitalpage.send_sms', label: 'digitalpage.send_sms' },
  { key: 'project.publish', label: 'project.publish' },
  { key: 'project.send_sms', label: 'project.send_sms' },
  { key: 'projects.fetch', label: 'projects.fetch' },
  { key: 'projects.search', label: 'projects.search' },
  { key: 'quotes.fetch', label: 'quotes.fetch' },
  { key: 'quotes.management_url', label: 'quotes.management_url' },
  { key: 'quotes.pdf_url', label: 'quotes.pdf_url' },
  { key: 'suburb.fetch_data', label: 'suburb.fetch_data' },
  { key: 'user.fetch', label: 'user.fetch' },
  { key: 'users.fetch', label: 'users.fetch' },
]

export default events
