import { useMemo } from 'react'

import { useModals, useSetState } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import useOrganizationSelector from '@hooks/useOrganizationSelector'
import useReduxAction from '@hooks/useReduxAction'

import handleCallbackAction from '@functions/handleCallbackAction'

import CreateFormTemplateModal from '@modals/CreateFormTemplateModal'

import PageContent from './components/PageContent'

const callbacks = (component, setState) => {
  const componentCallbacks = {
    CreateFormTemplateModal: {
      closeModal: () => setState({ showCreateFormTemplateModal: false }),
      createFormTemplate: payloads => handleCallbackAction(payloads),
      duplicateFormTemplate: payloads => handleCallbackAction(payloads),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  pageFilters: {},
  showCreateFormTemplateModal: false,
}

const FormTemplates = () => {
  const [state, setState] = useSetState(defaultState)
  const { pageFilters, showCreateFormTemplateModal } = state

  useReduxAction('formTemplates', 'loadFormTemplates', { ...pageFilters }, [JSON.stringify(pageFilters)])

  const { selectedOrganization } = useOrganizationSelector()

  const modalContext = useModals()

  const pageContext = useMemo(() => (
    {
      callbacks: {
        resetFilters: (resetFn) => {
          if (resetFn) resetFn()
          setState({ pageFilters: {} })
        },
        showCreateFormTemplateModal: () => setState({ showCreateFormTemplateModal: true }),
        updateFilters: (filters) => { setState({ pageFilters: filters }) },
      },
    }
  ), [])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent pageFilters={pageFilters} selectedOrganization={selectedOrganization} />

        <CreateFormTemplateModal
          callbacks={callbacks('CreateFormTemplateModal', setState)}
          selectedOrganization={selectedOrganization}
          ownerId={selectedOrganization.id}
          ownerType="Organization"
          showModal={showCreateFormTemplateModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default FormTemplates
