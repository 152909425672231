import { useEffect } from 'react'
import { toast } from 'react-toastify'

import { useLocalStorage, useSetState, useThunkDispatch } from '@campaignhub/react-hooks'
import { digObject, sortArrayBy } from '@campaignhub/javascript-utils'

import useCurrentUser from '@hooks/useCurrentUser'
import useUser from '@hooks/useUser'

import type { AppDispatch } from '@redux/store'
import type { UserModel } from '@models/user'

import * as userActions from '@redux/modules/user'

type UserOnboardingTasks = {
 completed: boolean,
 count: number,
 sort: number,
}

type DefaultState = {
  loaded: boolean,
  loading: boolean,
  minimized: boolean,
  showCompleted: boolean,
  userOnboardingTasks: {
    [key: string]: UserOnboardingTasks,
  },
}

const defaultState: DefaultState = {
  loaded: false,
  loading: false,
  minimized: false,
  showCompleted: false,
  userOnboardingTasks: {},
}

const onboardingTasksDetails = {
  asset_libraries: {
    title: 'Build your first file or image library',
    body: 'Add images and files to re-use across all your marketing templates',
    href: '#/admin/imageLibraries',
  },
  case_studies: {
    title: 'Create Case Studies',
    body: 'Highlight some of your special results to sell your services',
    href: '#/admin/caseStudies?hidden=false',
  },
  proposals: {
    title: 'Create a Proposal or Prelist',
    body: 'Add a proposal to a project',
    href: '#/overviews/digitalPages?digitalTemplateType=proposal',
  },
  crm_integrations: {
    title: 'Connect your organisation to your CRM',
    body: 'Integrate your engage account with a CRM for property listings',
    href: '#/admin/integrations',
  },
  projects: {
    title: 'Create a Project',
    body: 'Add images and files to your account',
    href: '#/overviews/projects?status=current',
  },
  reviews: {
    title: 'Create Reviews',
    body: 'Add images and files to your account',
    href: '#/admin/reviews?hidden=false',
  },
  target_audiences: {
    title: 'Create Target Audiences',
    body: 'Customise the likely audiences you’ll be targeting in your area',
    href: '#/admin/targetAudiences',
  },
  teams: {
    title: 'Create your first team',
    body: 'Add team members or invite new users to your organisation',
    href: '#/admin/teams',
  },
  users: {
    title: 'Invite Users',
    body: 'Create new users in your organization',
    href: '#/admin/users',
  },
}

type SetState = (state: Partial<typeof defaultState>) => void

const getUserOnboardingTasks = (
  user: UserModel,
  setState:SetState,
  dispatch: AppDispatch,
  requestOptions: Record<string, any>,
) => {
  const { loadUserOnboardingTasks: loadFn } = userActions

  return dispatch(loadFn(user, requestOptions)).then(({ success, data, errors }) => {
    if (!success){
      toast.warn(errors[0])
      return
    }

    setState({ loaded: true, loading: false, userOnboardingTasks: data })
  })
}

type Value = {
  minimized: boolean,
}

type SetValue = (state: Value) => void

const toggleOnboardingTasksModule = (value: Value, setValue: SetValue, setState: SetState) => {
  const { minimized } = value

  setState({
    minimized: !minimized,
  })

  setValue({ ...value, minimized: !minimized })
}

type UseUserOnboardingOptionsType = {
  performHttpRequests?: boolean,
}

type OnboardingTasksType = {
  [key: string]: {
    body: string,
    completed: boolean,
    count: number,
    href: number,
    key: string,
    sort: number,
    title: string,
  },
}

function useUserOnboardingTasks(options: UseUserOnboardingOptionsType = {}) {
  const [state, setState] = useSetState(defaultState)
  const {
    loaded, loading, minimized, userOnboardingTasks, showCompleted,
  } = state

  const dispatch = useThunkDispatch()

  const { currentUser } = useCurrentUser()

  const { callbacks: { updateUser }, user } = useUser(currentUser)
  const onboardingCompleted: boolean = digObject(user, 'options.onboarding_completed', false)

  const { callbacks: { setValue }, value } = useLocalStorage('engage:onboarding-settings')

  useEffect(() => {
    setState({
      minimized: value.minimized,
    })
  }, [])

  useEffect(() => {
    if (currentUser.id && !onboardingCompleted){
      getUserOnboardingTasks(currentUser, setState, dispatch, options)
    }
  }, [currentUser.id, onboardingCompleted])

  const onboardingTasks: OnboardingTasksType = Object.keys(userOnboardingTasks).reduce((acc, key: string) => {
    const item = userOnboardingTasks[key] || {}
    const { completed, sort } = item

    const taskDetails = onboardingTasksDetails[key] || {}
    const updatedSort = completed ? sort * 100 : sort

    acc[key] = {
      ...item, ...taskDetails, key, sort: updatedSort,
    }

    return acc
  }, {})

  const filteredTasks = Object.keys(onboardingTasks)
    .map(key => onboardingTasks[key])
    .filter(task => (showCompleted ? task : !task.completed))

  const sortedTasks = sortArrayBy(filteredTasks, 'asc', 'sort')

  const incompleteTasks = Object.values(onboardingTasks).filter(task => !task.completed)
  const incompleteTasksCount = incompleteTasks.length

  useEffect(() => {
    if (incompleteTasksCount === 0 && loaded && user.id){
      updateUser({ userParams: { options: { onboarding_completed: true } }, customFields: [] })
    }
  }, [user.id, incompleteTasksCount, loaded])

  return {
    callbacks: {
      showCompleted: () => setState({ showCompleted: !showCompleted }),
      toggleOnboardingTasksModule: () => toggleOnboardingTasksModule(value, setValue, setState),
    },
    loaded,
    loading,
    minimized,
    onboardingCompleted,
    sortedTasks,
    userName: currentUser.first_name,
    incompleteTasksCount: incompleteTasks.length,
  }
}

export default useUserOnboardingTasks
