import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, DashboardGuideModule, DashboardModule, FileUploadZone, LoadingModule,
} from '@campaignhub/suit-theme'

import useDocumentTemplateFileUpload from '../../../hooks/useDocumentTemplateFileUpload'

import guideImageUrl from './assets/upload-file.svg'

const DocumentTemplateBlankState = (props) => {
  const { documentTemplate, loading } = props

  const uppy = useDocumentTemplateFileUpload(documentTemplate, {}, [documentTemplate.id])

  return (
    <>
      <DashboardGuideModule boxProps={{ marginBottom: 'large' }} imageUrl={guideImageUrl}>
        <DashboardGuideModule.Title>Upload Your Document</DashboardGuideModule.Title>
        <DashboardGuideModule.Paragraph>
          Upload a PDF document to get started. We will extract the pages and display them below.
        </DashboardGuideModule.Paragraph>
      </DashboardGuideModule>

      <DashboardModule title="Upload File">
        <Box padding="large">
          <LoadingModule boxProps={{ marginBottom: 0 }} loading={loading} />
          {!loading && <FileUploadZone boxProps={{ width: ['auto', '100%'] }} uppy={uppy} />}
        </Box>
      </DashboardModule>
    </>
  )
}

DocumentTemplateBlankState.propTypes = {
  documentTemplate: PropTypes.object.isRequired,
  loading: PropTypes.bool,
}

export default DocumentTemplateBlankState
