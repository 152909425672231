/* eslint-disable max-len */
import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/pro-light-svg-icons'

import { BlankState, Button, Box } from '@campaignhub/suit-theme'

import dataSetsLibraries from './assets/no-data-sets.svg'

const DataSetsBlankState = () => (
  <BlankState imageUrl={dataSetsLibraries}>
    <BlankState.Title>
      No Data Sets
    </BlankState.Title>

    <BlankState.Paragraph>
      The Advanced Data Chart feature allows you to create dynamic charts within your proposals using your own data sets uploaded via CSV files.
    </BlankState.Paragraph>

    <BlankState.Paragraph>
      Click the button below for detailed instructions on creating a data set in the correct format, ensuring you present unique data tailored specifically to your proposal needs.
    </BlankState.Paragraph>

    <BlankState.Actions>
      <Box width="auto">
        <Button
          as="a"
          buttonStyle="ghostEdit"
          target="_blank"
          width="auto"
          href="https://intercom.help/realbase-engage/en/articles/9270093-how-to-upload-data-for-the-advanced-data-chart"
          ghost
          icon={<FontAwesomeIcon icon={faEye} aria-hidden="true" />}
          aria-label="View detailed instructions"
          size="medium"
        >
          View Instructions
        </Button>
      </Box>

    </BlankState.Actions>
  </BlankState>
)

export default DataSetsBlankState
