import React from 'react'
import PropTypes from 'prop-types'

import { BlankState, Button } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'
import useTargetAudiences from '@hooks/useTargetAudiences'

import localizedStrings from './localizedStrings'

import blankTargetAudiencesUrl from './assets/no-target-audiences.svg'

const TargetAudiencesBlankState = (props) => {
  const { organization, ownerType } = props
  const {
    callbacks: { copyTargetAudiencesToOrganization },
  } = useTargetAudiences({})

  const isOwnerBrand = ownerType && ownerType === 'Brand'

  const { strings } = useLocalization(localizedStrings)

  return (
    <BlankState boxProps={{ marginBottom: ['large', 0] }} imageUrl={blankTargetAudiencesUrl}>
      <BlankState.Title>{strings.title || 'Target Audiences'}</BlankState.Title>

      <BlankState.Paragraph>
        {strings.paragraph || 'Target audiences have not been created yet. Get started by clicking the button below'}
      </BlankState.Paragraph>

      {!isOwnerBrand && (
        <BlankState.Actions>
          <Button
            buttonStyle="primaryCreate"
            ghost
            onClick={() => copyTargetAudiencesToOrganization(organization)}
            size="medium"
            width="auto"
          >
            {strings.createButton || 'Create Target Audiences'}
          </Button>
        </BlankState.Actions>
      )}
    </BlankState>
  )
}

TargetAudiencesBlankState.propTypes = {
  organization: PropTypes.object.isRequired,
  ownerType: PropTypes.string.isRequired,
}

export default TargetAudiencesBlankState
