export default {
  default: {
    bulkSelectButton: 'Bulk Select Images',
    deleteButton: 'Delete',
    deselectAllButton: 'Deselect All',
    saveImageSort: 'Save Image Sort',
    selectAllButton: 'Select All',
    swal: {
      title: 'Delete Selected Images?',
      text: 'Are you sure? They cannot be recovered.',
      confirmButtonText: 'Yes, delete them.',
    },
    tools: 'Tools',
  },
}
