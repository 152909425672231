import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import { Link, SidebarModal } from '@campaignhub/suit-theme'
import { digObject } from '@campaignhub/javascript-utils'

import formatCountryCodeAndMobile from '@functions/formatCountryCodeAndMobile'

import useUser from '@hooks/useUser'
import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const enabledNotifications = (props, user, strings) => {
  const { showCustomEmail, showCustomMobile } = props
  const disabledNotifications = digObject(user, 'options.disabled_notifications.share_link_viewed', [])

  if (!disabledNotifications.length && showCustomEmail && showCustomMobile) return strings.subtitles?.user?.allEnabled || 'Email & SMS'
  if (disabledNotifications.includes('email') || !showCustomEmail) return strings.subtitles?.user?.smsOnly || 'SMS Only'
  if (disabledNotifications.includes('sms') || !showCustomMobile) return strings.subtitles?.user?.emailOnly || 'Email Only'

  return strings.subtitles?.user?.allEnabled || 'Email & SMS'
}

const generateRecipientProps = (props, userPayload, strings) => {
  const { recipient } = props
  const { defaultImage, user } = userPayload

  const imageUrl = digObject(defaultImage, 'sizes.thumb_90x90', '')

  if (recipient.type === 'user'){
    return {
      imageUrl,
      key: user.id,
      subtitle: enabledNotifications(props, user, strings),
      title: user.full_name,
    }
  }

  if (recipient.type === 'email'){
    return {
      key: recipient.email,
      subtitle: strings.subtitles?.email || 'Custom Email',
      title: recipient.email,
    }
  }

  if (recipient.type === 'sms'){
    return {
      key: recipient.mobile,
      subtitle: strings.subtitles?.mobile || 'Custom Mobile',
      title: formatCountryCodeAndMobile(recipient.country_calling_code, recipient.mobile),
    }
  }

  return null
}

const Recipient = (props) => {
  const {
    callbacks: { toggleRecipient },
    recipient,
  } = props

  const userId = recipient.type === 'user' && recipient.id
  const userPayload = useUser({ id: userId })

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal.User disableHover {...generateRecipientProps(props, userPayload, strings)}>
      <Link greyLink onClick={toggleRecipient} textProps={{ fontSize: 'small' }}>
        <FontAwesomeIcon icon={faTrashAlt} />
      </Link>
    </SidebarModal.User>
  )
}

Recipient.propTypes = {
  callbacks: PropTypes.shape({
    toggleRecipient: PropTypes.func,
  }).isRequired,
  recipient: PropTypes.object.isRequired,
  showCustomEmail: PropTypes.bool,
  showCustomMobile: PropTypes.bool,
}

export default Recipient
