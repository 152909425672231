import { Box } from '@campaignhub/suit-theme'

import Input from '@components/Input'

import QuadInput from '../QuadInput'

interface SizeAndPositioningProps {
  itemStyle: {
    key: string,
  },
  selectedEntity: {
    id: number,
    item_type: string,
  },
  styleProps: {},
  updateStyle: (name: string, value: string, styleProps: {}) => void,
}

const SizeAndPositioning = (props: SizeAndPositioningProps) => {
  const {
    itemStyle, styleProps, selectedEntity, updateStyle,
  } = props

  return (
    <Box marginTop="large" flexDirection="row" flexWrap="wrap">
      <Input
        boxProps={{ marginBottom: 'large' }}
        data={itemStyle}
        entityId={selectedEntity.id}
        halfWidth
        label="Width"
        propertyKey="width"
        updateCallback={(name, value) => updateStyle(name, value, styleProps)}
      />

      <Input
        boxProps={{ marginBottom: 'large' }}
        data={itemStyle}
        entityId={selectedEntity.id}
        halfWidth
        label="Height"
        last
        propertyKey="height"
        updateCallback={(name, value) => updateStyle(name, value, styleProps)}
      />

      {selectedEntity.item_type === 'imageCarousel'
      && (
        <>
          <Input
            boxProps={{ marginBottom: 'large' }}
            data={itemStyle}
            entityId={selectedEntity.id}
            halfWidth
            label="Carousel Width"
            propertyKey="carousel_width"
            updateCallback={(name, value) => updateStyle(name, value, styleProps)}
          />

          <Input
            boxProps={{ marginBottom: 'large' }}
            data={itemStyle}
            entityId={selectedEntity.id}
            halfWidth
            label="Carousel Height"
            last
            propertyKey="carousel_height"
            updateCallback={(name, value) => updateStyle(name, value, styleProps)}
          />
        </>
      )}
      <Input
        boxProps={{ marginBottom: 'large' }}
        data={itemStyle}
        entityId={selectedEntity.id}
        halfWidth
        label="Min Width"
        propertyKey="minWidth"
        updateCallback={(name, value) => updateStyle(name, value, styleProps)}
      />

      <Input
        boxProps={{ marginBottom: 'large' }}
        data={itemStyle}
        entityId={selectedEntity.id}
        halfWidth
        label="Min Height"
        last
        propertyKey="minHeight"
        updateCallback={(name, value) => updateStyle(name, value, styleProps)}
      />

      <Input
        boxProps={{ marginBottom: 'large' }}
        data={itemStyle}
        entityId={selectedEntity.id}
        halfWidth
        label="Max Width"
        propertyKey="maxWidth"
        updateCallback={(name, value) => updateStyle(name, value, styleProps)}
      />

      <Input
        boxProps={{ marginBottom: 'large' }}
        data={itemStyle}
        entityId={selectedEntity.id}
        halfWidth
        label="Max Height"
        last
        propertyKey="maxHeight"
        updateCallback={(name, value) => updateStyle(name, value, styleProps)}
      />

      <QuadInput
        boxProps={{ marginBottom: 'large' }}
        entityId={selectedEntity.id}
        itemStyle={itemStyle}
        propertyKeys={['marginTop', 'marginRight', 'marginBottom', 'marginLeft']}
        label="Margin"
        updateCallback={(name, value) => updateStyle(name, value, styleProps)}
      />

      <QuadInput
        boxProps={{ marginBottom: 'large' }}
        entityId={selectedEntity.id}
        itemStyle={itemStyle}
        propertyKeys={['paddingTop', 'paddingRight', 'paddingBottom', 'paddingLeft']}
        label="Padding"
        updateCallback={(name, value) => updateStyle(name, value, styleProps)}
      />
    </Box>
  )
}

export default SizeAndPositioning
