import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-light-svg-icons'

import { useDropTarget } from '@campaignhub/react-hooks'
import { Box, Button, Text } from '@campaignhub/suit-theme'

import useEvent from '@hooks/useEvent'

import Event from '../../Event'

const GroupCell = (props) => {
  const {
    callbacks: { dragDropEvent },
    cellData,
    cellData: {
      date, daysFromStart, events, label,
    },
    eventGroup,
    lastColumn,
    lastRow,
  } = props

  const dropTargetRef = useRef(null)

  const { isActive } = useDropTarget(dropTargetRef, eventGroup, {
    acceptedTypes: ['event'],
    callbacks: {
      dropItem: dropData => dragDropEvent({ dropData, cellData }),
    },
  })

  const initEvent = {
    cache_key: `${eventGroup.id}-${daysFromStart}`,
    days_from_start: daysFromStart,
    end_date: date ? `${date.toFormat('yyyy-MM-dd')}T10:00` : '',
    event_calendar_id: eventGroup.event_calendar_id,
    event_group_id: eventGroup.id,
    start_date: date ? `${date.toFormat('yyyy-MM-dd')}T09:00` : '',
  }

  const eventPayload = useEvent(initEvent)
  const { callbacks: { createOrEditEvent } } = eventPayload

  return (
    <Box
      backgroundColor={isActive ? 'aliceblue' : null}
      borderBottom={lastRow ? null : '1px solid'}
      borderRight={lastColumn ? null : '1px solid'}
      borderColor="lineColor"
      flexDirection="column"
      padding="medium"
      position="relative"
      ref={dropTargetRef}
    >
      <Text color="bodyFontLightColor" fontSize="xxsmall" marginBottom="medium">
        {label}
      </Text>

      {events.map(event => (
        <Event key={event.id} event={event} />
      ))}

      <Box marginTop="auto">
        <Button buttonStyle="secondaryEdit" onClick={createOrEditEvent} size="small" width="auto">
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      </Box>
    </Box>
  )
}

GroupCell.propTypes = {
  callbacks: PropTypes.shape({
    dragDropEvent: PropTypes.func.isRequired,
  }).isRequired,
  cellData: PropTypes.object.isRequired,
  eventGroup: PropTypes.object.isRequired,
  lastColumn: PropTypes.bool,
  lastRow: PropTypes.bool,
}

export default GroupCell
