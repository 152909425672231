import { toast } from 'react-toastify'

import useDispatch from '@hooks/useDispatch'
import useMixpanel, { MixPanelType } from '@hooks/useMixpanel'
import useOrganizationSelector from '@hooks/useOrganizationSelector'

import * as projectActions from '@redux/modules/project'

import type { AppDispatch } from '@redux/store'
import type { ProjectRequestOptions } from '@models/project'

import defaultRequestOptions from '@sections/Client/packs/Project/defaultRequestOptions'

type ImportProjectParamsType = {
  dispatch: AppDispatch,
  dispatchMixpanelEvent: MixPanelType,
  integrationPlatformKey: string,
  organizationId: number,
  propertyId: number,
  requestOptions?: ProjectRequestOptions,
}

const importProject = (params: ImportProjectParamsType) => {
  const {
    dispatch, dispatchMixpanelEvent, integrationPlatformKey, organizationId, propertyId, requestOptions,
  } = params

  const { importProject: importFn } = projectActions

  dispatch(importFn(propertyId, integrationPlatformKey, organizationId, requestOptions)).then((response) => {
    const { data, success } = response

    if (success){
      const { entity: { id } } = data || {}

      dispatchMixpanelEvent(`Project Created from ${integrationPlatformKey}`)

      toast('Project Created Successfully')
      window.location.href = `#/projects/${id}/edit`

      return
    }

    toast.warn('Something went wrong')
    window.location.href = '#/'
  })
}

const useImportExternalProject = (propertyId: number) => {
  const dispatch = useDispatch()

  const { selectedId } = useOrganizationSelector()

  const { callbacks: { dispatchMixpanelEvent } } = useMixpanel()

  return {
    callbacks: {
      importProject: (integration_platform_key: string) => importProject({
        propertyId,
        dispatch,
        dispatchMixpanelEvent,
        integrationPlatformKey: integration_platform_key,
        organizationId: selectedId,
        requestOptions: { ...defaultRequestOptions.project },
      }),
    },
  }
}

export default useImportExternalProject
