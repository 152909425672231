import { useContext } from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faHistory, faPencil } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, IconWithMenu, Link, ListItem, Text,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useBrand from '@hooks/useBrand'
import useDigitalTemplateComponent from '@hooks/useDigitalTemplateComponent'

import PageContext from '@contexts/pageContext'

const ComponentStyle = (props) => {
  const { componentStyle } = props
  const { brand_id, title } = componentStyle

  const clonedFromId = digObject(componentStyle, 'data.cloned_from_id')

  const brandPayload = useBrand({ id: brand_id })
  const {
    brand: { title: brandTitle },
  } = brandPayload

  const pageContext = useContext(PageContext)
  const {
    callbacks: { showCreateOrEditComponentStyleModal },
  } = pageContext || {}

  const digitalTemplateComponentPayload = useDigitalTemplateComponent(componentStyle)
  const { callbacks: { viewActivities } } = digitalTemplateComponentPayload

  return (
    <ListItem
      boxProps={{ alignItems: 'center', borderBottom: '1px dashed', padding: 'large' }}
      disableHover
    >
      <Box flexDirection="column" justifyContent="center" minWidth="0">
        <Text fontSize="small">{title}</Text>

        {clonedFromId && (
          <Text fontSize="xsmall" marginTop="medium" color="faintGrey">
            {`Cloned from ID #${clonedFromId} `}
          </Text>
        )}
      </Box>

      <Box marginRight="medium" width="auto" flexDirection="row">
        <Text color="bodyFontLightColor" fontSize="small" style={{ whiteSpace: 'nowrap' }}>
          {brandTitle}
        </Text>
      </Box>
      <IconWithMenu
        icon={(
          <Button
            buttonStyle="secondaryUtility"
            icon={<FontAwesomeIcon icon={faCog} />}
            size="medium"
            height={37}
          />
        )}
        style={{ marginLeft: 'auto', width: 'auto' }}
      >
        <IconWithMenu.Menu closeOnClick listStyle={{ right: '-7px' }}>
          <Link
            onClick={() => showCreateOrEditComponentStyleModal({ digitalTemplateComponent: componentStyle })}
            key="edit_component_style"
          >
            <FontAwesomeIcon icon={faPencil} /> Edit
          </Link>

          <Link
            onClick={() => viewActivities()}
            key="activty_log"
          >
            <FontAwesomeIcon icon={faHistory} /> Activity Log
          </Link>
        </IconWithMenu.Menu>
      </IconWithMenu>
    </ListItem>
  )
}

ComponentStyle.propTypes = {
  componentStyle: PropTypes.object.isRequired,
}

export default ComponentStyle
