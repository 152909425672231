import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/pro-light-svg-icons'

import {
  Box, FormField, Link, LoadingModule, Text,
} from '@campaignhub/suit-theme'

import useComparables from '@hooks/useComparables'
import useLocalization from '@hooks/useLocalization'

import BrowseResults from '../BrowseResults'
import ManualIdMatch from '../ManualIdMatch'
import PropertySuggestion from '../PropertySuggestion'

import localizedStrings from './localizedStrings'

const getLabelRight = (externalId, selectedIntegrationPlatformKey, updateProjectExternalId, strings, formatString) => {
  if (!selectedIntegrationPlatformKey) return null
  if (!externalId) return <Text color="faintGrey" fontSize="xsmall">{strings.labels?.noTarget || 'No Target'}</Text>
  return (
    <Link disabledUnderline onClick={() => updateProjectExternalId(null)} textProps={{ fontSize: 'xsmall' }}>
      {formatString(strings.labels?.externalId, { externalId })} <FontAwesomeIcon icon={faPencil} />
    </Link>
  )
}

const BrowseComparables = (props) => {
  const { projectId, useFindComparablesPayload } = props
  const {
    callbacks: {
      setModalState,
      updateProjectExternalId,
    },
    errors,
    externalId,
    filteredPlatforms,
    hasPropertyMatchSuggestions,
    isMatchedWithDataProvider,
    propertyMatchSuggestions,
    searching,
    selectedIntegrationPlatformKey,
    useAuthorizeIntegrationPayload,
  } = useFindComparablesPayload

  const {
    integrationExpired,
  } = useAuthorizeIntegrationPayload || {}

  const useComparablesPayload = useComparables({
    filters: {
      projectId,
    },
  })
  const { filteredComparables } = useComparablesPayload

  const {
    callbacks: { formatString },
    strings,
  } = useLocalization(localizedStrings)

  return (
    <Box flexDirection="column">
      <FormField
        direction="column"
        errorMessage={errors.length ? errors[0] : null}
        label={strings.labels?.platform || 'Platform'}
        labelRight={getLabelRight(externalId, selectedIntegrationPlatformKey, updateProjectExternalId, strings, formatString)}
      >
        <select
          data-validate-field-on="change"
          onChange={e => setModalState({ selectedIntegrationPlatformKey: e.target.value })}
          value={selectedIntegrationPlatformKey}
        >
          <option value="">{strings.options?.pleaseSelect || 'Please select...'}</option>
          {filteredPlatforms.map(entity => (
            <option key={entity.id} value={entity.key}>
              {entity.title}
            </option>
          ))}
        </select>
      </FormField>

      <LoadingModule boxProps={{ marginTop: 'large' }} loading={!externalId && searching} />

      {!integrationExpired && !searching && !isMatchedWithDataProvider && selectedIntegrationPlatformKey && (
        <>
          <FormField
            direction="column"
            label={strings.labels?.potentialMatches || 'Potential Matches'}
            marginTop="large"
          >
            {!hasPropertyMatchSuggestions && (
              <Text color="faintGrey" fontSize="small" marginBottom="large" marginTop="medium">
                {strings.noAvailableProperties || 'No Available Properties'}
              </Text>
            )}

            {propertyMatchSuggestions.map((suggestion, index) => (
              <PropertySuggestion
                comparable={suggestion}
                isLast={index === propertyMatchSuggestions.length - 1}
                key={suggestion.id}
                onClickFn={updateProjectExternalId}
              />
            ))}
          </FormField>

          <ManualIdMatch useFindComparablesPayload={useFindComparablesPayload} />
        </>
      )}

      {isMatchedWithDataProvider && selectedIntegrationPlatformKey && (
        <BrowseResults
          filteredComparables={filteredComparables}
          useFindComparablesPayload={useFindComparablesPayload}
        />
      )}
    </Box>
  )
}

BrowseComparables.propTypes = {
  projectId: PropTypes.number.isRequired,
  useFindComparablesPayload: PropTypes.object.isRequired,
}

export default BrowseComparables
