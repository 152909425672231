import React from 'react'

import { BlankState } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

import blankImageUrl from './assets/no-templates.svg'

const TemplateListBlankState = () => {
  const { strings } = useLocalization(localizedStrings)

  return (
    <BlankState
      boxProps={{ flexDirection: 'column-reverse' }}
      contentProps={{ width: '100%', marginTop: 'large' }}
      forceMobile
      imageUrl={blankImageUrl}
      sidebar
    >
      <BlankState.Title>{strings.blankState?.title || 'No Templates'}</BlankState.Title>

      <BlankState.Paragraph>
        {strings.blankState?.paragraph || 'Try adjusting your filters to return more results.'}
      </BlankState.Paragraph>
    </BlankState>
  )
}

export default TemplateListBlankState
