export default {
  default: {
    sms: {
      title: 'SMS Notification (When Lead User)',
    },
    email: {
      title: 'Email Notification',
    },
  },
}
