import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import {
  useDebounce, useDeepEffect, useSetState, useThunkDispatch,
} from '@campaignhub/react-hooks'

import { digObject, sortArrayBy, toggleArray } from '@campaignhub/javascript-utils'

import * as userActions from '@redux/modules/user'

import useEntityDefaultIntegrationPlatform from '@hooks/useEntityDefaultIntegrationPlatform'
import useIntegrations from '@hooks/useIntegrations'
import useIntegrationPlatforms from '@hooks/useIntegrationPlatforms'
import useOrganizationSelector from '@hooks/useOrganizationSelector'

const toggleUser = (id, state, setState) => {
  const { selectedUserIds } = state

  const updatedUserIds = toggleArray(selectedUserIds, id)

  setState({ selectedUserIds: updatedUserIds })
}

const toggleAllUsers = (users, state, setState) => {
  const { selectedUserIds } = state

  if (selectedUserIds.length){
    setState({ selectedUserIds: [] })
    return
  }

  setState({ selectedUserIds: users.map(user => user.id) })
}

const loadFromExternalPlatform = (externalPlatformKey, requestOptions, dispatch, setState) => {
  const { loadFromExternalPlatform: loadFromExternalPlatformFn } = userActions

  dispatch(loadFromExternalPlatformFn(externalPlatformKey, requestOptions)).then(({ success, data }) => {
    if (success){
      setState({ externalUsers: data, loading: false })
    }
    setState({ loading: false })
  })
}

const bulkImportFromExternalPlatform = (externalPlatformKey, payload, dispatch, requestOptions) => {
  const { bulkImportFromExternalPlatform: importFn } = userActions
  const updatedPayload = {
    externalIds: payload.externalIds,
    organizationId: payload.organizationId,
  }

  return dispatch(importFn(externalPlatformKey, updatedPayload, requestOptions))
}

const getAlreadyImportedUserIds = (reduxUsers, selectedOrganization, selectedIntegrationPlatformKey) => {
  const alreadyImportedExternalIds = Object.values(reduxUsers).reduce((acc, user) => {
    const source_platform = digObject(user, 'data.source_platform')
    const external_id = digObject(user, `data.external_ids.${source_platform}`)
    const organizationIds = digObject(user, 'organization_ids', [])

    if (
      organizationIds.includes(selectedOrganization.id)
      && source_platform === selectedIntegrationPlatformKey
      && external_id
    ){
      acc.push(external_id)
    }

    return acc
  }, [])

  return alreadyImportedExternalIds
}

const defaultState = {
  externalUsers: [],
  loading: false,
  selectedIntegrationPlatformKey: '',
  selectedUserIds: [],
  string: '',
}

const useImportUsers = () => {
  const [state, setState] = useSetState(defaultState)
  const {
    externalUsers, loading, selectedIntegrationPlatformKey, selectedUserIds, string,
  } = state

  const dispatch = useThunkDispatch()

  const debouncedString = useDebounce(string, 300)

  const {
    filteredPlatforms,
    hasIntegrations,
    urls: integrationsUrls,
  } = useIntegrations({ featureKeys: ['import_users'] })

  // Integration Platform
  const { callbacks: { findIntegrationPlatformByKey } } = useIntegrationPlatforms()
  const selectedIntegrationPlatform = findIntegrationPlatformByKey(selectedIntegrationPlatformKey)

  const {
    callbacks: { selectOrganizationId },
    selectedId,
    selectedOrganization,
    sortedOrganizationsWhereAdmin,
  } = useOrganizationSelector()

  const {
    entities: { users: reduxUsers },
  } = useSelector(reduxState => reduxState)

  // Create an array of IDs for users that have already been imported
  // Push the external ID if the user is in the right organization, has
  // been imported from the right platform, and has an external_id
  const alreadyImportedExternalIds = getAlreadyImportedUserIds(
    reduxUsers,
    selectedOrganization,
    selectedIntegrationPlatformKey,
  )

  const { defaultPlatformKey } = useEntityDefaultIntegrationPlatform({ featureKey: 'import_users' })

  // Re-fetch users whenever integration platform or search string change
  useEffect(() => {
    if (selectedIntegrationPlatformKey && !!selectedOrganization.id){
      const fetchRequestParams = {
        limit: 100,
        organization_id: selectedOrganization.id,
        string: debouncedString,
      }

      loadFromExternalPlatform(selectedIntegrationPlatformKey, fetchRequestParams, dispatch, setState)
      setState({ externalUsers: [], loading: true })
    }
  }, [selectedIntegrationPlatformKey, selectedOrganization.id, debouncedString])

  // Select all users that have not already been imported
  useDeepEffect(() => {
    const notImportedExternalIds = externalUsers
      .filter(externalUser => !alreadyImportedExternalIds.includes(externalUser.id))
      .map(externalUser => externalUser.id)

    setState({ selectedUserIds: notImportedExternalIds })
  }, [externalUsers])

  // Check for a user/org's default integration platform, if none auto select first integration platform if only one available
  useEffect(() => {
    if (defaultPlatformKey){
      setState({ selectedIntegrationPlatformKey: defaultPlatformKey })
      return
    }

    if (filteredPlatforms.length === 1){
      const selectedPlatform = filteredPlatforms[0]
      setState({ selectedIntegrationPlatformKey: selectedPlatform.key })
    }
  }, [defaultPlatformKey, filteredPlatforms.length])

  return {
    alreadyImportedExternalIds,
    callbacks: {
      bulkImportFromExternalPlatform: (payload, requestOptions) => bulkImportFromExternalPlatform(
        selectedIntegrationPlatformKey,
        payload,
        dispatch,
        requestOptions,
      ),
      selectOrganizationId,
      setState,
      toggleAllUsers: () => toggleAllUsers(externalUsers, state, setState),
      toggleUser: id => toggleUser(id, state, setState),
    },
    externalUsers: sortArrayBy(externalUsers, 'asc', 'full_name'),
    filteredPlatforms,
    hasExternalUsers: !!externalUsers.length,
    hasIntegrations,
    hasSelectedUsers: !!selectedUserIds.length,
    integrationsUrls,
    loading,
    selectedId,
    selectedIntegrationPlatform,
    selectedIntegrationPlatformKey,
    selectedOrganization,
    selectedUserIds,
    sortedOrganizationsWhereAdmin,
    string,
  }
}

export default useImportUsers
