export default {
  default: {
    swal: {
      title: 'Delete Image?',
      text: 'Are you sure?',
      confirmationButtonText: 'Yes, delete it',
    },
  },
  nonEditable: 'Selected image is not editable.',
  titleTemplate: 'Template',
  titleTemplateAssets: 'Template Assets',
  titleTemplateSecondLine: 'Assets',
  titleUploadAsset: 'Upload Asset',
}
