import React from 'react'
import { Route, Routes } from 'react-router-dom'

import TemplateCustomizer from './screens/TemplateCustomizer'
import TemplateList from './screens/TemplateList'

const TemplatesRoutes = () => (
  <Routes>
    <Route path=":digitalTemplateId/customize" element={<TemplateCustomizer />} />
    <Route index element={<TemplateList />} />
  </Routes>
)

export default TemplatesRoutes
