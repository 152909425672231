import { useMemo } from 'react'

import { matchFilterKey, sortArrayBy } from '@campaignhub/javascript-utils'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { UserTypeModel } from '@models/types'

const watchEntityKeys = ['userTypes']

type UserTypeFilters = {
  subjectType?: string,
}

type UseUserTypesOptions = {
  filters?: UserTypeFilters,
  performHttpRequests?: boolean,
}

function useUserTypes(options: UseUserTypesOptions) {
  const { filters = {}, performHttpRequests } = options
  const {
    subjectType: filterSubjectType,
  } = filters

  const {
    updatedEntities: { userTypes: userTypesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  useReduxAction(
    'userTypes',
    'loadUserTypes',
    {},
    [performHttpRequests],
    { shouldPerformFn: ({ loading }) => performHttpRequests && !loading },
  )

  const { userTypes } = useSelector(reduxState => reduxState.entities)

  const filteredUserTypes = useMemo(() => {
    const filtered = Object.values(userTypes).filter((userType: UserTypeModel) => {
      const { subject_type } = userType

      const subjectTypeMatch = matchFilterKey(subject_type, filterSubjectType)

      return subjectTypeMatch
    })

    return sortArrayBy(filtered, 'asc', 'title')
  }, [userTypesUpdatedAt, JSON.stringify(filters)])

  return {
    filteredUserTypes,
  }
}

export default useUserTypes
