import { useForm } from '@campaignhub/react-hooks'

const mapOperatorText = (operator: string) => {
  if (operator === 'present') return 'is present'
  if (operator === 'empty') return 'is empty'
  if (operator === 'eq') return 'is equal to'
  if (operator === 'unequal') return 'is unequal to'

  return operator
}

const defaultState = {
  operator: '',
  string: '',
  value: '',
}

const requiredFields = [{ key: 'operator' }, { key: 'string' }]

function useRenderCondition() {
  const renderConditionForm = useForm(defaultState, { entity: defaultState, requiredFields }, [])

  return {
    callbacks: {
      mapOperatorText: (operator: string) => mapOperatorText(operator),
    },
    ...renderConditionForm,
  }
}

export default useRenderCondition
