import React from 'react'
import PropTypes from 'prop-types'

import { Box, Button, SidebarNoticeBox } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const ExternalPlatformsBlankState = (props) => {
	const { callbacks: { addPlatform } } = props

	const { strings } = useLocalization(localizedStrings)

	return (
		<Box flexDirection="column" flexShrink={0}>
			<SidebarNoticeBox>
				<SidebarNoticeBox.Title>{strings.noticeBox?.title || 'No External Platforms'}</SidebarNoticeBox.Title>
				<SidebarNoticeBox.Paragraph>
					{strings.noticeBox?.paragraph || 'External Platforms can be connected for things such as exporting projects and importing comparables.'}
				</SidebarNoticeBox.Paragraph>
			</SidebarNoticeBox>

			<Button
				buttonStyle="secondaryUtility"
				onClick={addPlatform}
				size="medium"
			>
				{strings.buttons?.addPlatform || 'Add External Platform'}
			</Button>
		</Box>
	)
}

ExternalPlatformsBlankState.propTypes = {
	callbacks: PropTypes.shape({
		addPlatform: PropTypes.func.isRequired,
	}).isRequired,
}

export default ExternalPlatformsBlankState
