import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelope, faFile, faPhone, faPaperclip,
} from '@fortawesome/pro-light-svg-icons'

import {
  Box, Image, ListItem, Text,
} from '@campaignhub/suit-theme'
import { snakeToTitleCase } from '@campaignhub/javascript-utils'

import useLocalization from '@hooks/useLocalization'

const icons = {
  call_user: faPhone,
  download_pdf: faFile,
  download_attachment: faPaperclip,
  email_user: faEnvelope,
}

const Interaction = (props) => {
  const {
    interaction: {
      date,
      key,
      meta,
    },
  } = props

  const createdDate = DateTime.fromISO(date).toFormat("yyyy-MM-dd'T'HH:mm")

  const {
    callbacks: { formatDate },
  } = useLocalization()

  return (
    <ListItem
      boxProps={{ alignItems: 'center', borderBottom: '1px dashed', paddingY: 'large' }}
      disableHover
      forceBottomBorder
    >
      <Box flexDirection="column">
        <Text fontSize="xsmall">{snakeToTitleCase(key)}</Text>

        {!!meta && (
          <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="small">
            {meta.title}
          </Text>
        )}

        <Text
          color="bodyFontLightColor"
          fontSize="xxsmall"
          marginTop="small"
          style={{ textTransform: 'uppercase', flexShrink: 0 }}
        >
          {formatDate(createdDate, {
            weekday: 'short',
            day: 'numeric',
            month: 'long',
            hour: '2-digit',
            minute: '2-digit',
          })}
        </Text>
      </Box>

      <Box
        color="bodyFontLightColor"
        alignItems="center"
        justifyContent="center"
        flexShrink={0}
        height={35}
        width={35}
      >
        <Box alignItems="center" justifyContent="center" flexShrink={0} height={35} width={35} marginRight="large">
          <Image boxProps={{ backgroundColor: 'hoverLightGrey' }} circle width={35}>
            <FontAwesomeIcon icon={icons[key] || faFile} />
          </Image>
        </Box>
      </Box>
    </ListItem>
  )
}

Interaction.propTypes = {
  interaction: PropTypes.shape({
    date: PropTypes.number,
    key: PropTypes.string,
    meta: PropTypes.object,
  }).isRequired,
}

export default Interaction
