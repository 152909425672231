export default {
  default: {
    button: 'Add Target Audience',
    createButton: 'Create',
    filterByNameLabel: 'Filter by Name',
    filters: 'Filters',
    helmetTitle: 'Target Audiences | Engage',
    limitLabel: 'Limit',
    titleOrganization: 'Target Audiences (Agency)',
    titleBrand: 'Target Audiences (Brand)',
    VideoModule: {
      title: 'Target Audiences',
      paragraph: 'What is a target audience, why should you use it and how you can use through the Engage platform.',
    },
  },
}
