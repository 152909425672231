import React from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'

import {
  Button, FormField,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const confirmPropertyMatchOverride = (modalPayload, strings, formatString) => {
  const {
    selectedIntegrationPlatform: { title },
  } = modalPayload

  swal.fire({
    title: strings.swal?.title || 'Manually Set Property Match?',
    text: formatString(strings.swal?.text, { title }) || `Are you sure? You are manually setting or overriding the ${title || 'Data Provider'} property match id. This can be undone by clicking 'Reset Property Match'.`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: strings.swal?.confirm || 'Yes, Set Property Match Id.',
    confirmButtonColor: '#e2001a',
    showClass: 'slide-from-top',
  }).then(({ value }) => {
    if (value){
      const {
        callbacks: {
          setModalState,
          updateProjectExternalId,
        },
        propertyMatchId,
        showManualPropertyMatchId,
      } = modalPayload

      updateProjectExternalId(propertyMatchId)
      setModalState({
        propertyMatchId: '',
        showManualPropertyMatchId: !showManualPropertyMatchId,
      })
    }
  })
}

const ManualIdMatch = (props) => {
  const { useFindComparablesPayload } = props
  const {
    callbacks: {
      setModalState,
    },
    propertyMatchId,
    selectedIntegrationPlatformKey,
  } = useFindComparablesPayload

  const {
    callbacks: { formatString },
    strings,
  } = useLocalization(localizedStrings)

  return (
    <>
      {selectedIntegrationPlatformKey && (
        <FormField direction="column" label={strings.label || 'Manual ID Match'} marginTop="large">
          <input
            onChange={e => setModalState({ propertyMatchId: e.target.value })}
            placeholder={strings.placeholder || 'E.g. AAX2101'}
            type="text"
            value={propertyMatchId}
            style={{ marginBottom: 8 }}
          />

          <Button
            buttonStyle="primaryEdit"
            onClick={() => confirmPropertyMatchOverride(useFindComparablesPayload, strings, formatString)}
            size="medium"
          >
            {strings.buttonText || 'Manually Set Property Match Id'}
          </Button>
        </FormField>
      )}
    </>
  )
}

ManualIdMatch.propTypes = {
  useFindComparablesPayload: PropTypes.object.isRequired,
}

export default ManualIdMatch
