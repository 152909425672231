import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, Button, FormField, SidebarModal,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import useTextPageItem from './hooks/useTextPageItem'
import localizedStrings from './localizedStrings'

const Text = (props) => {
  const { callbacks, digitalPageItemPayload } = props
  const { updateDigitalPageItemCustomData } = callbacks || {}

  const {
    callbacks: { updatePageItemCustomData: updateFn },
  } = digitalPageItemPayload

  const textPayload = useTextPageItem(digitalPageItemPayload)
  const {
    formPayload: {
      entityState,
      entityState: { value },
      handlers,
    },
  } = textPayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <>
      <SidebarModal.Header
        callbacks={callbacks}
        disableAnimation
        title={strings.title || 'Edit'}
        titleSecondLine={strings.titleSecondLine || 'Text'}
      />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0}>
          <FormField direction="column" boxProps={{ marginBottom: 'large' }} label={strings.formFieldLabels?.value || 'Value'}>
            <textarea name="value" style={{ height: 300 }} type="text" value={value} {...handlers} />
          </FormField>
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          onClick={() => updateDigitalPageItemCustomData(entityState, updateFn)}
          size="large"
        >
          {strings.buttons?.save || 'Save'}
        </Button>
      </SidebarModal.Footer>
    </>
  )
}

Text.propTypes = {
  callbacks: PropTypes.object.isRequired,
  digitalPageItemPayload: PropTypes.object.isRequired,
}

export default Text
