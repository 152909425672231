const countryCallingCodes = [
  {
    id: 'AU', key: 'AU', value: '+61', title: '+61 (AU)',
  },
  {
    id: 'NZ', key: 'NZ', value: '+64', title: '+64 (NZ)',
  },
  {
    id: 'PH', key: 'PH', value: '+63', title: '+63 (PH)',
  },
]

export default countryCallingCodes
