export default {
  default: {
    label: 'Options',
    fieldHolders: {
      videoProvider: 'Video Provider',
    },
    selectValues: {
      select: 'Select...',
      vimeo: 'Vimeo',
      youtube: 'YouTube',
    },
  },
}
