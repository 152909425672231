import { digObject } from '@campaignhub/javascript-utils'

import { isAdminForOrganizationId } from '@functions/user'

import type { UserModel } from '@models/user'

import useCurrentUser from '@hooks/useCurrentUser'

export type UseFeaturePayload = {
  user: Partial<UserModel>,
}

function useFeature(options: UseFeaturePayload) {
  const { user } = options || {}
  const { currentUser } = useCurrentUser()

  const userOrganizationIds: number[] = digObject(user, 'organization_ids', [])
  const canEditUser: boolean = userOrganizationIds.some((id: number) => isAdminForOrganizationId(currentUser, id))

  const isSelf = user ? currentUser.id === user.id : false

  return {
    canEditUser,
    isSelf,
  }
}

export default useFeature
