import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { deleteEntity, updateEntities } from '@redux/entities'

import api from '@functions/api'

import type { ComparableModel, CustomFieldModel } from '@models/types'
import type { AppDispatch } from '@redux/store'
import type {
  Action, DeleteParams, EntityOptions, Errors, FetchRequestPayload, RootReducerState, UpdateParams,
} from '@redux/modules/types'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/comparable/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/comparable/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/comparable/FETCH_FAILURE'

const CREATE_REQUEST = 'realbase/comparable/CREATE_REQUEST'
const CREATE_SUCCESS = 'realbase/comparable/CREATE_SUCCESS'
const CREATE_FAILURE = 'realbase/comparable/CREATE_FAILURE'

const UPDATE_REQUEST = 'realbase/comparable/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realbase/comparable/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realbase/comparable/UPDATE_FAILURE'

const DELETE_REQUEST = 'realbase/comparable/DELETE_REQUEST'
const DELETE_SUCCESS = 'realbase/comparable/DELETE_SUCCESS'
const DELETE_FAILURE = 'realbase/comparable/DELETE_FAILURE'

export type ComparableModuleState = {
  creating: boolean,
  deleting: boolean,
  errors: string[],
  loaded: boolean,
  loadedForKeys: string[],
  loadedIds: number[],
  loading: boolean,
  updating: boolean,
}

// Initial State
const initialState: ComparableModuleState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loadedIds: [],
  loadedForKeys: [],
  loading: false,
  updating: false,
}

// Actions
export function fetchRequest(payload: FetchRequestPayload = {}) {
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess() {
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors: Errors = []) {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest() {
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess() {
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors: Errors = []) {
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest() {
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess() {
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors: Errors = []) {
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest() {
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess() {
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors: Errors = []) {
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Action Creators
export function loadComparables(options: EntityOptions = {}) {
  const { entityKey } = options

  return (dispatch: AppDispatch, getState: () => RootReducerState) => {
    const loadedForKeys = [...getState().comparables.loadedForKeys]

    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('/project_comparables.json', options)
      .then(({ data }: { data: { entities: ComparableModel[] } }) => {
        const normalizedJson = normalize(data.entities, Schemas.COMPARABLE_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function createComparable(
  comparable: Partial<ComparableModel>,
  customFields: CustomFieldModel[],
  options: EntityOptions = {},
) {
  const config = {
    method: 'POST',
    body: JSON.stringify({
      data_store_item_values: customFields,
      project_comparable: comparable,
    }),
  }

  return (dispatch: AppDispatch) => {
    dispatch(createRequest())

    const promise = api('/project_comparables.json', options, config)
      .then(({ data }: { data: { entity: ComparableModel } }) => {
        const normalizedJson = normalize(data.entity, Schemas.COMPARABLE)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function cloneComparable(sourceComparableId: number, targetProjectId: number, options: EntityOptions = {}) {
  const config = {
    method: 'POST',
    body: JSON.stringify({
      source_comparable_id: sourceComparableId,
      target_project_id: targetProjectId,
    }),
  }

  return (dispatch: AppDispatch) => {
    dispatch(createRequest())

    const promise = api('/project_comparables/clone_comparable.json', options, config)
      .then(({ data }: { data: { entity: ComparableModel } }) => {
        const normalizedJson = normalize(data.entity, Schemas.COMPARABLE)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function updateComparable(
  comparable: UpdateParams<ComparableModel>,
  customFields: CustomFieldModel[],
  options: EntityOptions = {},
) {
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      data_store_item_values: customFields,
      project_comparable: comparable,
    }),
  }

  return (dispatch: AppDispatch) => {
    dispatch(updateRequest())

    const promise = api(`/project_comparables/${comparable.id}.json`, options, config)
      .then(({ data }: { data: { entity: ComparableModel } }) => {
        const normalizedJson = normalize(data.entity, Schemas.COMPARABLE)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function deleteComparable(comparable: DeleteParams<ComparableModel>) {
  const config = {
    method: 'DELETE',
  }

  return (dispatch: AppDispatch) => {
    dispatch(deleteRequest())

    const promise = api(`/project_comparables/${comparable.id}.json`, {}, config)
      .then(({ data }: { data: { entity: ComparableModel } }) => {
        const normalizedJson = normalize(data.entity, Schemas.COMPARABLE)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(deleteFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function combinedSearch(externalPlatformKey: string, string: string) {
  return (dispatch: AppDispatch) => {
    dispatch(fetchRequest())

    const promise = api(`/project_comparables/combined_search/${externalPlatformKey}.json`, string)
      .then(({ data }: { data: { data: ComparableModel } }) => {
        dispatch(fetchSuccess())

        return { success: true as const, data: data.data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function createComparableFromProject(projectId: number, comparableOwnerId: number, options: EntityOptions = {}) {
  const config = {
    method: 'POST',
    body: JSON.stringify({
      comparable_owner_id: comparableOwnerId,
      project_id: projectId,
    }),
  }

  return (dispatch: AppDispatch) => {
    dispatch(createRequest())

    const promise = api('/project_comparables/create_from_project.json', options, config)
      .then(({ data }: { data: { entity: ComparableModel } }) => {
        const normalizedJson = normalize(data.entity, Schemas.COMPARABLE)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

type ImportComparablePayload = {
  distance: number,
  externalId: number,
  organizationId: number,
  projectId: number,
}

export function importComparable(
  externalPlatformKey: string,
  payload: ImportComparablePayload,
  options: EntityOptions = {},
) {
  const {
    distance, externalId, organizationId, projectId,
  } = payload

  const config = {
    method: 'POST',
    body: JSON.stringify({
      distance,
      external_id: externalId,
      organization_id: organizationId,
      project_id: projectId,
    }),
  }

  return (dispatch: AppDispatch) => {
    dispatch(createRequest())

    const promise = api(
      `/project_comparables/import_from_external_platform/${externalPlatformKey}.json`,
      options,
      config,
    )
      .then(({ data }: { data: { entity: ComparableModel } }) => {
        const normalizedJson = normalize(data.entity, Schemas.COMPARABLE)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function searchInExternalPlatform(externalPlatformKey: string, payload: object) {
  return (dispatch: AppDispatch) => {
    dispatch(fetchRequest())

    const promise = api(`/project_comparables/search_in_external_platform/${externalPlatformKey}.json`, payload)
      .then(({ data }: { data: { data: ComparableModel } }) => {
        dispatch(fetchSuccess())

        return { success: true as const, data: data.data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

type FetchFromExternalPlatformPayload = {
  externalId: number,
  comparableType: string,
  organizationId: number,
  projectAddress: {
    postcode: number,
    state_name: string,
    region_name: string,
  },
}

export function fetchFromExternalPlatform(
  externalPlatformKey: string,
  payload: FetchFromExternalPlatformPayload,
  filters = {},
) {
  const {
    externalId, comparableType, organizationId, projectAddress,
  } = payload

  const params = {
    external_id: externalId,
    comparable_type: comparableType,
    organization_id: organizationId,
    post_code: projectAddress?.postcode,
    state_name: projectAddress?.state_name,
    suburb_name: projectAddress?.region_name,
    ...filters,
  }

  return (dispatch: AppDispatch) => {
    dispatch(fetchRequest())

    const promise = api(`/project_comparables/fetch_from_external_platform/${externalPlatformKey}.json`, params)
      .then(({ data }: { data: { data: ComparableModel } }) => {
        dispatch(fetchSuccess())

        return { success: true as const, data: data.data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function searchProjects(filters = {}) {
  return (dispatch: AppDispatch) => {
    dispatch(fetchRequest())

    const promise = api('/project_comparables/project_search.json', filters)
      .then(({ data }) => {
        dispatch(fetchSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function updateSortOrder(sortOrder: number, options: EntityOptions = {}) {
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      sort_order: sortOrder,
    }),
  }

  return (dispatch: AppDispatch) => {
    dispatch(updateRequest())

    const promise = api('/project_comparables/update_sort_order.json', options, config)
      .then(({ data }: { data: { entities: ComparableModel[] } }) => {
        const normalizedJson = normalize(data.entities, Schemas.COMPARABLE_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(
  state: ComparableModuleState = initialState,
  action: Action = { type: '' },
): ComparableModuleState {
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loadedIds: action.loadedIds || state.loadedIds,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors || [],
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors || [],
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors || [],
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors || [],
      }
    default:
      return state
  }
}
