import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Duration } from 'luxon'

import {
  Box, FormField, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import { digObject, snakeToTitleCase } from '@campaignhub/javascript-utils'

import PieChartIcon from '@components/Icons/PieChartIcon'
import Statistic from '@components/Statistic'
import TimeWithPageIcon from '@components/Icons/TimeWithPageIcon'
import UserIcon from '@components/Icons/UserIcon'

import useLocalization from '@hooks/useLocalization'

import Metric from './Metric'

const metricKeys = [
  'average_proposals_accepted_per_user',
  'average_proposals_created_per_project',
  'maximum_proposals_created_by_user',
  'total_proposals_accepted',
  'total_proposals_created',
]

const ViewDigitalPageMetricCollectionModal = (props) => {
  const { callbacks, modalKey, showModal } = props

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const { digitalPageMetricCollection } = digObject(modalData, `${modalKey}`, {})
  const {
    average_proposal_read_percentage,
    average_proposal_seconds_on_page,
    average_proposals_created_per_user,
    key,
    owner_title,
    owner_type,
  } = digitalPageMetricCollection

  const {
    values: { minutes, seconds },
  } = Duration.fromObject({ seconds: average_proposal_seconds_on_page }).shiftTo('minutes', 'seconds')

  const {
    callbacks: { formatNumber },
  } = useLocalization({})

  return (
    <SidebarModal callbacks={callbacks} hasFooter={false} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header callbacks={callbacks} title="Metric" titleSecondLine="Collection" />

      <SidebarModal.Content>
        <Box flexDirection="row" flexWrap="wrap">
          <Statistic
            fullWidth
            icon={<TimeWithPageIcon width={44} height={44} fillColor="#8C8C8E" />}
            label="Average Time on Page"
            value={
              minutes > 0
                ? `${formatNumber(minutes, '.0f')}m ${formatNumber(seconds, '.0f')}s`
                : `${formatNumber(seconds, '.0f')}s`
            }
          />

          <Statistic
            icon={<PieChartIcon width={44} height={44} fillColor="#8C8C8E" />}
            label="Average Read"
            value={`${average_proposal_read_percentage}%`}
          />

          <Statistic
            boxProps={{ marginLeft: 'auto' }}
            icon={<UserIcon width={44} height={44} fillColor="#8C8C8E" />}
            label="Avg. per User"
            value={String(average_proposals_created_per_user)}
          />
        </Box>

        <FormField direction="column" label={`${owner_title} (${owner_type}) - ${key}`} marginTop="large">
          {metricKeys.map((metricKey) => {
            const value = digitalPageMetricCollection[metricKey] || ''

            return (
              <Metric key={metricKey} label={snakeToTitleCase(metricKey)} metricKey={metricKey} value={String(value)} />
            )
          })}
        </FormField>
      </SidebarModal.Content>
    </SidebarModal>
  )
}

ViewDigitalPageMetricCollectionModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

ViewDigitalPageMetricCollectionModal.defaultProps = {
  modalKey: 'ViewDigitalPageMetricCollectionModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <ViewDigitalPageMetricCollectionModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
