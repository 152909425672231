import React from 'react'
import PropTypes from 'prop-types'

import { digObject } from '@campaignhub/javascript-utils'

import {
  Box, Image, ListItem, StatusBadge, Text,
} from '@campaignhub/suit-theme'

import { statusColor } from '@functions/status'

import useDigitalTemplate from '@hooks/useDigitalTemplate'

import placeholderImageUrl from './assets/template_preview_tile.svg'

const PageDetails = (props) => {
  const {
    digitalPagePayload: {
      callbacks: { editDigitalPage },
      digitalPage: { title },
      digitalTemplate,
      status,
    },
  } = props

  const { previewImage } = useDigitalTemplate(digitalTemplate)
  const previewImageUrl = digObject(previewImage, 'sizes.thumb_660x360', '')

  return (
    <ListItem boxProps={{ padding: 'large' }} onClick={editDigitalPage} showAngleIcon>
      <Box flexDirection="column">
        <Box flexDirection="row">
          <Box width={60} marginRight="medium">
            <Image
              borderRadius={5}
              height={45}
              marginRight="medium"
              placeholderUrl={placeholderImageUrl}
              url={previewImageUrl}
              width={60}
            />
          </Box>

          <Box justifyContent="center" flexDirection="column" width="calc(100% - 75px)">
            <Text color="bodyFontLightColor" fontSize="small" marginBottom="small" variant="ellipsis">
              {title}
            </Text>
            <StatusBadge color={statusColor(status.key)} ghost text={status.title} />
          </Box>
        </Box>
      </Box>
    </ListItem>
  )
}

PageDetails.propTypes = {
  digitalPagePayload: PropTypes.shape({
    callbacks: PropTypes.shape({
      editDigitalPage: PropTypes.func,
    }).isRequired,
    digitalTemplate: PropTypes.object,
    digitalPage: PropTypes.object,
    status: PropTypes.object,
  }).isRequired,
}

export default PageDetails
