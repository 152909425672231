export default {
  default: {
    buttons: {
      save: 'Save',
      create: 'Create Case Study',
    },
    formFieldLabels: {
      searchCaseStudies: 'Search Case Studies',
      moreOptions: 'More Options',
    },
    noticeBox: {
      title: 'Search Case Studies',
      paragraph: 'Case Studies assigned to your account can be searched above. If you have not created any yet click the button below',
    },
    search: {
      noResults: {
        subtitle: 'Try searching again',
        title: 'No Case Studies found',
      },
      title: 'Case Studies',
    },
    sideBarModalHeader: {
      title: 'Case',
      titleSecondLine: 'Studies',
    },
  },
}
