import { useEffect } from 'react'

import { useSetState, useDebounce } from '@campaignhub/react-hooks'

import { digObject } from '@campaignhub/javascript-utils'

import useReduxAction from '@hooks/useReduxAction'

import { DigitalPageModel } from '@models/digitalPage'

const defaultState = {
  filterDataSeriesString: '',
  filterDataSetString: '',
  selectedDataSeries: '',
  selectedDataSeriesKey: '',
  selectedDataSet: '',
  selectedDataSetKey: '',
}

export type ChartDataArrayType = {
  chart_number?: number,
  data_series?: string,
  data_set_key?: string,
  data_series_key?: string,
  data_set?: string,
  key?: string,
}

export type SelectedDataSetParams = {
  chartNumber?: number,
  setState: (payload: {}) => void,
  chartDataArray: ChartDataArrayType[],
}

const setChartState = (params:SelectedDataSetParams) => {
  const { chartDataArray, chartNumber, setState } = params

  const filteredChartData = chartDataArray?.find(item => Number(item?.chart_number) === Number(chartNumber))

  setState({
    selectedDataSet: filteredChartData?.data_set,
    selectedDataSeries: filteredChartData?.data_series,
    selectedDataSetKey: filteredChartData?.data_set_key,
    selectedDataSeriesKey: filteredChartData?.data_series_key,
  })
}

export type BuildChartDataPayloadParams = {
  chartNumber?: number,
  digitalPage?: Partial<DigitalPageModel>,
  chartData: ChartDataArrayType[],
  state: Record<string, string | number>,
}

const buildChartDataPayload = (params: BuildChartDataPayloadParams) => {
  const {
    chartData,
    chartNumber,
    digitalPage,
    state: {
      selectedDataSeries,
      selectedDataSeriesKey,
      selectedDataSet,
      selectedDataSetKey,
    },
  } = params

  const newPayload = {
    chart_number: chartNumber,
    data_series_key: selectedDataSeriesKey,
    data_series: selectedDataSeries,
    data_set_key: selectedDataSetKey,
    data_set: selectedDataSet,
    key: `digital_page_${digitalPage?.id}_chart_number_${chartNumber}`,
  }

  const duplicateIndex = chartData.findIndex(item => Number(item.chart_number) === Number(chartNumber))

  if (duplicateIndex !== -1){
    chartData[duplicateIndex] = newPayload
  } else {
    chartData.push(newPayload)
  }

  const digitalPageParams = {
    options: {
      chart_data: [...chartData],
    },
  }

  return digitalPageParams
}

export type DigitalPageChartDataOptions = {
  chartNumber?: number,
  digitalPage?: DigitalPageModel,
}

function useDigitalPageChartData(options: DigitalPageChartDataOptions = {}) {
  const { digitalPage, chartNumber } = options

  const [state, setState] = useSetState(defaultState)

  const {
    filterDataSeriesString,
    filterDataSetString,
    selectedDataSet,
    selectedDataSetKey,
  } = state

  const chartData = digObject(digitalPage!, 'options.chart_data', [])

  const dataSetdebouncedString = useDebounce(filterDataSetString, 300)
  const dataSeriesdebouncedString = useDebounce(filterDataSeriesString, 300)

  const { data: dataSetsPayload } = useReduxAction(
    'digitalPages',
    'fetchDigitalPageDataSets',
    {
      string: dataSetdebouncedString,
    },
    [dataSetdebouncedString],
    {
      dispatchAction: (action, requestOptions) => action(digitalPage?.id, requestOptions),
      shouldPerformFn: () => !!dataSetdebouncedString,
    },
  )

  const { data: dataSeriesPayload } = useReduxAction(
    'digitalPages',
    'fetchDigitalPageDataSeries',
    {
      string: dataSeriesdebouncedString,
      data_set_string: selectedDataSet,
    },
    [selectedDataSetKey],
    {
      dispatchAction: (action, requestOptions) => action(digitalPage?.id, requestOptions),
      shouldPerformFn: () => !!selectedDataSetKey,
    },
  )

  useEffect(() => {
    if (chartData.length){
      setChartState({ chartDataArray: chartData, chartNumber, setState })
    }
  }, [chartData.length, chartNumber])

  return {
    callbacks: {
      setState,
      buildChartDataPayload: () => buildChartDataPayload({
        chartData, chartNumber, digitalPage, state,
      }),
    },
    dataSeriesPayload,
    dataSetsPayload,
    chartData,
    state,
  }
}

export default useDigitalPageChartData
