import { denormalize, normalize } from 'normalizr'
import { digObject } from '@campaignhub/javascript-utils'

import { Schemas } from '@redux/schema'
import entitySchema from '@redux/entitySchema'
import { updateEntities, deleteEntity } from '@redux/entities'

import api from '@functions/api'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/digitalTemplateComponent/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/digitalTemplateComponent/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/digitalTemplateComponent/FETCH_FAILURE'

const CREATE_REQUEST = 'realbase/digitalTemplateComponent/CREATE_REQUEST'
const CREATE_SUCCESS = 'realbase/digitalTemplateComponent/CREATE_SUCCESS'
const CREATE_FAILURE = 'realbase/digitalTemplateComponent/CREATE_FAILURE'

const UPDATE_REQUEST = 'realbase/digitalTemplateComponent/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realbase/digitalTemplateComponent/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realbase/digitalTemplateComponent/UPDATE_FAILURE'

const DELETE_REQUEST = 'realbase/digitalTemplateComponent/DELETE_REQUEST'
const DELETE_SUCCESS = 'realbase/digitalTemplateComponent/DELETE_SUCCESS'
const DELETE_FAILURE = 'realbase/digitalTemplateComponent/DELETE_FAILURE'

// Initial State
const initialState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loadedForKeys: [],
  loadedIds: [],
  loading: false,
  updating: false,
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess(){
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(){
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest(){
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess(){
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors = []){
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Action Creators
export function loadDigitalTemplateComponents(options = {}){
  const { entityKey } = options || {}

  return (dispatch, getState) => {
    const loadedForKeys = [...getState().digitalTemplateComponents.loadedForKeys]
    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('/digital_template_components.json', options)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entities, Schemas.DIGITAL_TEMPLATE_COMPONENT_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function loadDigitalTemplateComponent(digitalTemplateComponentId, options = {}){
  return (dispatch, getState) => {
    const state = getState()
    const loadedIds = [...state.digitalTemplateComponents.loadedIds]

    if (digitalTemplateComponentId && !loadedIds.includes(digitalTemplateComponentId)){
      loadedIds.push(digitalTemplateComponentId)
    }

    // Set Loading
    dispatch(fetchRequest({ loadedIds }))

    const promise = api(`/digital_template_components/${digitalTemplateComponentId}.json`, options)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.DIGITAL_TEMPLATE_COMPONENT)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function createDigitalTemplateComponent(digitalTemplateComponent, options = {}){
  return (dispatch, getState) => {
    const state = getState()

    dispatch(createRequest())

    let updatedDigitalTemplateComponent = digitalTemplateComponent

    // Denormalize the item so we pass the full object to the server
    const entityType = digObject(digitalTemplateComponent, 'data.component.entityType')
    if (entityType){
      const component = digObject(digitalTemplateComponent, 'data.component', {})
      const denormalizedComponent = denormalize(component, entitySchema[entityType], state.entities)

      updatedDigitalTemplateComponent = {
        ...digitalTemplateComponent,
        data: JSON.stringify({
          ...digitalTemplateComponent.data,
          component: denormalizedComponent,
        }),
      }
    }

    const config = {
      method: 'POST',
      body: JSON.stringify({
        digital_template_component: updatedDigitalTemplateComponent,
      }),
    }

    const promise = api('/digital_template_components.json', options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.DIGITAL_TEMPLATE_COMPONENT)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function createDigitalTemplateComponentStyle(digitalTemplateComponent, options){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      digital_template_component: digitalTemplateComponent,
    }),
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api('/digital_template_components.json', options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.DIGITAL_TEMPLATE_COMPONENT)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function updateDigitalTemplateComponent(digitalTemplateComponent, options = {}){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      digital_template_component: digitalTemplateComponent,
    }),
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/digital_template_components/${digitalTemplateComponent.id}.json`, options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.DIGITAL_TEMPLATE_COMPONENT)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function deleteDigitalTemplateComponent(digitalTemplateComponent){
  const config = {
    method: 'DELETE',
  }

  return (dispatch) => {
    dispatch(deleteRequest())

    const promise = api(`/digital_template_components/${digitalTemplateComponent.id}.json`, {}, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.DIGITAL_TEMPLATE_COMPONENT)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(deleteFailure(errors))

        return { success: false, errors }
      })
    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors,
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
