import { useMemo } from 'react'

import { useSetState } from '@campaignhub/react-hooks'

import PageContext from '@contexts/pageContext'

import useBrandSelector from '@hooks/useBrandSelector'
import useReduxAction from '@hooks/useReduxAction'

import handleCallbackAction from '@functions/handleCallbackAction'

import CreateCustomFieldSetModal from '@modals/CreateCustomFieldSetModal'

import defaultRequestOptions from '@sections/Admin/packs/SystemManager/defaultRequestOptions'

import PageContent from './components/PageContent'

const callbacks = (component, setState) => {
  const componentCallbacks = {
    CreateCustomFieldSetModal: {
      closeModal: () => setState({ showCreateCustomFieldSetModal: false }),
      createCustomFieldSet: payload => handleCallbackAction(payload),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  pageFilters: {},
  showCreateCustomFieldSetModal: false,
}

const CustomFieldSetList = () => {
  const [state, setState] = useSetState(defaultState)
  const { pageFilters, showCreateCustomFieldSetModal } = state

  const { selectedBrand } = useBrandSelector()

  useReduxAction('brands', 'loadBrands', {})

  useReduxAction(
    'customFieldSets',
    'loadCustomFieldSets',
    { ...defaultRequestOptions.customFieldSet, ...pageFilters },
    [JSON.stringify(pageFilters)],
  )

  const pageContext = useMemo(
    () => ({
      callbacks: {
        resetFilters: (resetFn) => {
          if (resetFn) resetFn()
          setState({ pageFilters: {} })
        },
        showCreateCustomFieldSetModal: () => setState({ showCreateCustomFieldSetModal: true }),
        updateFilters: (filters) => {
          setState({ pageFilters: filters })
        },
      },
    }),
    [],
  )

  return (
    <PageContext.Provider value={pageContext}>
      <PageContent pageFilters={pageFilters} />

      <CreateCustomFieldSetModal
        customFieldSet={{
          owner_id: selectedBrand.id ? selectedBrand.id : null,
          owner_type: selectedBrand.id ? 'Brand' : '',
        }}
        callbacks={callbacks('CreateCustomFieldSetModal', setState)}
        showModal={showCreateCustomFieldSetModal}
      />
    </PageContext.Provider>
  )
}

export default CustomFieldSetList
