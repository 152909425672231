import { Box, Link, Text } from '@campaignhub/suit-theme'

export interface LoadMoreFooterProps {
  boxProps?: object,
  callbacks: {
    loadMore: () => void,
  },
  canLoadMore: boolean,
  entityCount: number,
  loading: boolean,
  showBorderTop?: boolean,
  strings?: {
    buttonText: string,
    resultsText: string,
  },
}

const LoadMoreFooter = (props: LoadMoreFooterProps) => {
  const {
    boxProps,
    callbacks: {
      loadMore,
    },
    canLoadMore,
    entityCount,
    showBorderTop,
    strings,
  } = props

  const resultsText = entityCount === 1 ? 'Showing 1 Result' : `Showing ${entityCount} Results`

  if (!entityCount && !canLoadMore) return null

  return (
    <Box
      alignItems="flex-start"
      borderTop={showBorderTop ? '1px solid' : 'none'}
      borderColor="lineColor"
      display="flex"
      flexDirection="row"
      paddingY="medium"
      {...boxProps}
    >
      {!!entityCount && (
        <Text color="faintGrey" fontSize="xsmall">
          {strings?.resultsText || resultsText}
        </Text>
      )}

      <Box
        marginLeft="auto"
        width="fit-content"
      >
        {canLoadMore && (
          <Link onClick={() => loadMore()} textProps={{ fontSize: 'xsmall' }}>
            {strings?.buttonText || 'Load More'}
          </Link>
        )}
      </Box>
    </Box>
  )
}

LoadMoreFooter.defaultProps = {
  showBorderTop: true,
  strings: {},
}

export default LoadMoreFooter
