import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { LoadingModule, ModalContext, SidebarModal } from '@campaignhub/suit-theme'
import { digObject } from '@campaignhub/javascript-utils'

import useDocumentTemplates from '@hooks/useDocumentTemplates'
import useLocalization from '@hooks/useLocalization'
import useOrganizationSelector from '@hooks/useOrganizationSelector'

import DocumentTemplateBlankState from './components/DocumentTemplateBlankState'
import DocumentTemplate from './components/DocumentTemplate'

import localizedStrings from './localizedStrings'

const SelectDocumentTemplateModal = (props) => {
  const {
    callbacks, disableAnimation, disableOverlay, modalKey, showModal,
  } = props
  const { selectDocumentTemplateId } = callbacks

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const { callbacks: modalCallbacks } = digObject(modalData, `${modalKey}`, {})
  const { selectDocumentTemplateId: selectFn } = modalCallbacks || {}

  const { selectedOrganization } = useOrganizationSelector()

  const documentTemplatesPayload = useDocumentTemplates({
    ownerId: selectedOrganization.id,
    ownerType: 'Organization',
    performHttpRequests: true,
    production_ready: true,
  })

  const {
    hasDocumentTemplates,
    filteredDocumentTemplates,
    loading,
  } = documentTemplatesPayload

  const { strings } = useLocalization(localizedStrings)

  return (
    <SidebarModal
      callbacks={callbacks}
      disableAnimation={disableAnimation}
      disableOverlay={disableOverlay}
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header
        callbacks={callbacks}
        title={strings.header?.title || 'Select'}
        titleSecondLine={strings.header?.titleSecondLine || 'Template'}
      />

      <SidebarModal.Content hasFooter={false}>
        <LoadingModule loading={loading} times={3} />

        {!loading && !hasDocumentTemplates && (
          <DocumentTemplateBlankState />
        )}

        {!loading
          && hasDocumentTemplates
          && filteredDocumentTemplates.map(documentTemplate => (
            <DocumentTemplate
              callbacks={{
                selectDocumentTemplate: () => selectDocumentTemplateId(documentTemplate.id, selectFn),
              }}
              key={documentTemplate.id}
              documentTemplate={documentTemplate}
            />
          ))}
      </SidebarModal.Content>
    </SidebarModal>
  )
}

SelectDocumentTemplateModal.propTypes = {
  callbacks: PropTypes.shape({
    selectDocumentTemplateId: PropTypes.func.isRequired,
  }).isRequired,
  disableAnimation: PropTypes.bool,
  disableOverlay: PropTypes.bool,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool.isRequired,
}

SelectDocumentTemplateModal.defaultProps = {
  modalKey: 'SelectDocumentTemplateModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <SelectDocumentTemplateModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
