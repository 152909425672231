import { useEffect, useState } from 'react'
import isMobileBrowser from 'is-mobile'
import cloneDeep from 'lodash/cloneDeep'

import { digObject } from '@campaignhub/javascript-utils'

import useDigitalPageView from '@hooks/useDigitalPageView'
import useShareLink from '@hooks/useShareLink'
import { getSectionIds } from '@hooks/useDigitalTemplatePage'

const createDigitalPageView = (payload, createFn, setState) => {
  const { digitalPage: { id, owner_type, owner_id }, shareLink, uuid } = payload

  const digitalPageView = {
    digital_page_id: id,
    owner_id,
    owner_type,
    share_link_id: shareLink.id,
    uuid,
  }

  createFn(digitalPageView, { token: shareLink.token }).then((response) => {
    const { success, data } = response

    if (success && data){
      const digitalPageViewId = digObject(data, 'entity.id')

      setState(currentState => ({
        ...currentState,
        digitalPageViewId,
      }))
    }
  })
}

const attachPageViewAnalytics = (shareLink, analyticsData, attachAnalyticsFn) => {
  const {
    completedFeatureKeys, enabledSectionCount, interactions, pageHeight, readPercentage, secondsOnPage,
  } = analyticsData

  const payload = {
    completed_feature_keys: completedFeatureKeys,
    enabled_section_count: enabledSectionCount,
    interactions,
    page_height: pageHeight,
    read_percentage: readPercentage,
    seconds_on_page: secondsOnPage,
  }

  attachAnalyticsFn(payload, shareLink.token)
}

const currentTime = () => new Date().getTime()

const getEnabledPageSectionCount = (digitalTemplatePage, digitalPage, digitalTemplatePageSectionGroups) => {
  const availableSectionIds = getSectionIds(digitalTemplatePage, digitalTemplatePageSectionGroups)
  const disabledSectionIds = digObject(digitalPage, 'options.disabled_sections', [])
  const enabledSectionIds = availableSectionIds.filter(sectionId => !disabledSectionIds.includes(sectionId))

  return enabledSectionIds.length
}

const getScrollableArea = () => {
  const { body } = document
  const html = document.documentElement

  return Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight) || null
}

const getAnalyticsData = (state, options) => {
  const { trackScrollPosition } = options || {}
  const {
    interactions, completedFeatureKeys, enabledSectionCount, maxRatio, startTime,
  } = state

  const data = {
    completedFeatureKeys,
    enabledSectionCount,
    interactions,
    pageHeight: getScrollableArea(),
    secondsOnPage: Number(currentTime() - startTime) / 1000,
  }

  if (trackScrollPosition){
    data.readPercentage = maxRatio * 100
  }

  return data
}

const logAnalyticsEvent = (payload, state, setState, callbackFn) => {
  const updatedInteractions = [...cloneDeep(state.interactions)]
  updatedInteractions.push(payload)

  setState(currentState => ({
    ...currentState,
    interactions: updatedInteractions,
  }))

  if (callbackFn) callbackFn()
}

const getWindowHeight = () => window.innerHeight || document.documentElement.clientHeight

const getScrollOffset = () => (window.pageYOffset !== undefined
  ? window.pageYOffset
  : (document.documentElement || document.body.parentNode || document.body).scrollTop)

const isMobile = isMobileBrowser()

const defaultState = {
  interactions: [],
  completedFeatureKeys: [],
  digitalPageViewId: null,
  enabledSectionCount: 0,
  maxRatio: 0,
  maxY: 0,
  startTime: currentTime(),
  unloadProcessed: false,
}

function useAnalyticsTracker(options = {}){
  const {
    completedFeatureKeys, digitalTemplatePageSectionGroups, digitalPage, digitalTemplatePage, shareLink, trackScrollPosition, uuid,
  } = options || {}

  // Need to use setState instead of useSetState here so we can reference the latest value from callback
  const [state, setState] = useState(defaultState)
  const {
    digitalPageViewId, interactions, maxRatio, maxY, unloadProcessed,
  } = state

  const shareLinkPayload = useShareLink(shareLink)
  const { trackingEnabled } = shareLinkPayload

  const digitalPageViewPayload = useDigitalPageView({ id: digitalPageViewId })
  const {
    callbacks: { createDigitalPageView: createViewFn, attachAnalyticsData: attachAnalyticsFn },
    digitalPageView,
  } = digitalPageViewPayload

  // Get Enabled Sections
  useEffect(() => {
    if (digitalPage.id && digitalTemplatePage.id){
      const enabledSectionCount = getEnabledPageSectionCount(digitalTemplatePage, digitalPage, digitalTemplatePageSectionGroups)

      setState(currentState => ({
        ...currentState,
        completedFeatureKeys,
        enabledSectionCount,
      }))
    }
  }, [digitalPage.id, digitalTemplatePage.id])

  // Create the view and store ID
  useEffect(() => {
    if (digitalPage.id && trackingEnabled && uuid){
      createDigitalPageView({
        digitalPage,
        shareLink,
        uuid,
      }, createViewFn, setState)
    }
  }, [digitalPage.id, trackingEnabled, uuid])

  // Handle Unload
  const handleUnloadEvent = () => {
    if (digitalPageView.id && !unloadProcessed){
      const analyticsData = getAnalyticsData(state, options)
      attachPageViewAnalytics(shareLink, analyticsData, attachAnalyticsFn)
    }
  }

  // Handle Visibility Change
  const handleVisibilityChangeEvent = () => {
    if (document.visibilityState === 'hidden'){
      handleUnloadEvent()
    }

    if (document.visibilityState === 'visible'){
      setState(defaultState)
    }
  }

  const trackScrollState = () => {
    const yPosition = getScrollOffset() + getWindowHeight()
    setState((currentState) => {
      const { maxY: stateMaxY } = currentState

      return {
        ...currentState,
        maxY: yPosition > stateMaxY ? yPosition : stateMaxY,
      }
    })
  }

  // Setup Scroll Listener
  useEffect(() => {
    if (trackingEnabled && trackScrollPosition){
      document.addEventListener('scroll', trackScrollState)
      return () => document.removeEventListener('scroll', trackScrollState)
    }

    return undefined
  }, [trackingEnabled, trackScrollPosition])

  // Set Max Read Ratio
  useEffect(() => {
    if (trackingEnabled){
      const scrollableArea = getScrollableArea()

      setState((currentState) => {
        const { maxY: stateMaxY } = currentState

        return {
          ...currentState,
          maxRatio: parseFloat(Number(stateMaxY / scrollableArea).toFixed(2)),
        }
      })
    }
  }, [trackingEnabled, maxY, setState])

  // Setup Unload Listener
  useEffect(() => {
    if (!trackingEnabled || !digitalPageView.id){
      return undefined
    }

    if (isMobile){
      document.addEventListener('visibilitychange', handleVisibilityChangeEvent)
      return () => document.removeEventListener('visibilitychange', handleVisibilityChangeEvent)
    }

    if (window.attachEvent){
      window.attachEvent('onbeforeunload', handleUnloadEvent)
      return () => window.detachEvent('onbeforeunload', handleUnloadEvent)
    }

    window.addEventListener('beforeunload', handleUnloadEvent)
    return () => {
      window.removeEventListener('beforeunload', handleUnloadEvent)
    }
  }, [trackingEnabled, digitalPageView.id, maxRatio, interactions.length, unloadProcessed, setState])

  return {
    callbacks: {
      logAnalyticsEvent: (itemPayload, callbackFn) => logAnalyticsEvent(itemPayload, state, setState, callbackFn),
    },
    ...state,
  }
}

export default useAnalyticsTracker
