export default {
  default: {
    addressModalHeaderTitle: 'Project',
    customizableDashboard: {
      main: 'Main',
      sidebar: 'Sidebar',
    },
    digitalPages: {
      title: 'Digital Pages',
    },
    leaderboard: {
      templatesTitle: 'Most Engaging Templates',
      usersTitle: 'Proposals Leaderboard',
    },
    helmet: {
      title: 'Dashboard',
    },
    pageHeader: {
      create: 'Create',
      customize: 'Customize Dashboard',
      dashboard: 'Dashboard',
      save: 'Save Dashboard',
      title: 'Dashboard',
    },
    organization: {
      label: '{global.organizationType.shortName.singular}',
    },
    dashboardModuleNotice: {
      buttonText: 'Revoke Access',
      title: 'Onboarding Mode',
      body: 'Onboarding mode allows our support team to access your account to assist with account setup. It can be turned off by selecting revoke access.',
    },
    projects: {
      title: 'Projects',
    },
    toast: {
      user: {
        updated: 'User updated successfully',
      },
      organization: {
        revokeAccess: 'Access Revoked Successfully',
      },
    },
  },
}
