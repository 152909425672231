import { Box, Button, Text } from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faTriangleExclamation } from '@fortawesome/pro-light-svg-icons'

import AuthenticatedDownload from '@components/AuthenticatedDownload'

interface TemplateCurrentUsageProps {
  currentTemplateUsage: {
    brand_title: string,
    digital_pages_count: number,
    organizations_count: number,
  },
  firstPageId: number,
}

const renderDigitalPageCountText = (digital_pages_count: number) => {
  if (digital_pages_count > 1) return `${digital_pages_count} Digital Pages built from this template.`

  return `${digital_pages_count} Digital Page built from this template.`
}

const renderOrganizationCountText = (organizations_count: number, brand_title: string) => {
  if (organizations_count > 1) return `${organizations_count} organizations from ${brand_title} are using this.`

  return `${organizations_count} organization from ${brand_title} is using this.`
}

const TemplateCurrentUsage = (props: TemplateCurrentUsageProps) => {
  const { currentTemplateUsage, firstPageId } = props

  const {
    brand_title, digital_pages_count, organizations_count,
  } = currentTemplateUsage

  return (
    <Box
      backgroundColor="white"
      padding="large"
      border="1px solid"
      borderRadius={5}
      marginBottom="large"
      borderColor="lineColor"
      flexDirection="column"
    >
      <Box flexDirection="row" alignItems="center" width="auto">
        <Box flexDirection="column">
          <Box alignItems="center">
            <FontAwesomeIcon icon={faTriangleExclamation} color="orange" />

            <Text fontSize="small" fontWeight="bold" marginLeft="small" color="orange">This is a Live template</Text>
          </Box>

          <Box flexDirection="column">
            <Box width="fit-content" marginTop="medium">
              <Text fontSize="small" variant="ellipsis">{renderDigitalPageCountText(digital_pages_count)}</Text>

              <Text
                fontSize="small"
                marginRight="small"
                variant="ellipsis"
              >
                {renderOrganizationCountText(organizations_count, brand_title)}
              </Text>
            </Box>
          </Box>
        </Box>

        <AuthenticatedDownload>
          <Button
            as="a"
            icon={<FontAwesomeIcon icon={faDownload} />}
            marginLeft="small"
            size="medium"
            width="auto"
            buttonStyle="primaryUtility"
            href={`/digital_template_pages/${firstPageId}/export_layout_data.json`}
          >
            Download JSON
          </Button>
        </AuthenticatedDownload>

      </Box>

    </Box>
  )
}

export default TemplateCurrentUsage
