import { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library, findIconDefinition } from '@fortawesome/fontawesome-svg-core'

import { fal } from '@fortawesome/pro-light-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'
import { useSetState } from '@campaignhub/react-hooks'
import { Button as BaseButton } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'
import PdfDownloadContext from '@contexts/pdfDownloadContext'

import replaceTextShortCodes from '@functions/replaceTextShortCodes'
import { shouldRenderPageItem } from '@functions/digitalTemplatePageItem'

import useDeviceStyle from '@hooks/useDeviceStyle'

import replaceCustomData from '@components/digitalRenderer/utils/replaceCustomData'

library.add(fal)

const currentTime = () => new Date().toISOString()

const getIcon = async (iconValue, setState, isShareLink) => {
  try {
    const iconDefinition = findIconDefinition({
      prefix: 'fal',
      iconName: iconValue.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase().replace(/^fa-/, ''),
    })

    if (iconDefinition) setState({ icon: iconDefinition })
  } catch {
    if (!isShareLink) toast.warning(`Icon ${iconValue} not found`)
  }
}

const callUser = (context, callbacks) => {
  const { full_name, mobile } = context
  const { logAnalyticsEvent } = callbacks

  if (logAnalyticsEvent){
    logAnalyticsEvent({
      date: currentTime(),
      key: 'call_user',
      meta: { title: `${full_name} ${mobile}` },
    })
  }

  window.location.href = `tel:${mobile}`
}

const downloadAttachment = (context, callbacks) => {
  const { download_url, file_name, title } = context
  const { logAnalyticsEvent } = callbacks

  if (logAnalyticsEvent){
    logAnalyticsEvent({
      date: currentTime(),
      key: 'download_attachment',
      meta: { file_name, title },
    })
  }

  window.location = download_url
}

const downloadQuote = (context, callbacks) => {
  const { downloadPdfFromShareLink, logAnalyticsEvent } = callbacks || {}
  const {
    id, shareLinkToken, source_platform: sourcePlatformKey, title,
  } = context || {}

  const externalPlatformId = digObject(context, `external_ids.${sourcePlatformKey}`)

  if (!sourcePlatformKey || !externalPlatformId){
    return
  }

  const digitalPageId = digObject(context, 'digital_page.id')
  const organizationId = digObject(context, 'organization.id')

  const analyticsPayload = {
    date: currentTime(),
    key: 'download_quote',
    meta: { title },
  }

  logAnalyticsEvent(analyticsPayload, () => {
    downloadPdfFromShareLink(id, shareLinkToken, {
      digital_page_id: digitalPageId,
      organization_id: organizationId,
    })
  })
}

const emailUser = (context, callbacks) => {
  const { email, project } = context
  const { logAnalyticsEvent } = callbacks

  if (logAnalyticsEvent){
    logAnalyticsEvent({
      date: currentTime(),
      key: 'email_user',
      meta: { title: email },
    })
  }

  window.location.href = `mailto:${email}?Subject=${project.title}`
}

const toggleCarousel = (props) => {
  const {
    callbacks: { toggleCarousel: toggleCarouselFn },
    options,
  } = props

  const { carouselKey } = options || {}

  if (toggleCarouselFn && carouselKey){
    toggleCarouselFn(carouselKey, 0)
  }
}

const updateDataStore = async (props) => {
  const {
    callbacks: { createOrUpdateDataStoreItem: createOrUpdateFn, updateShortcodeDataSubject: updateSubjectFn },
    options,
  } = props

  const {
    inputDataStoreBehaviour, inputDataStoreKey, inputDataStoreSubject, inputDataStoreValue,
  } = options || {}

  const payload = {
    behaviour: inputDataStoreBehaviour || 'replace',
    data: {
      hidden: true,
    },
    key: inputDataStoreKey,
    label: inputDataStoreKey,
    subjectType: inputDataStoreSubject,
    value: inputDataStoreValue,
  }

  updateSubjectFn(inputDataStoreSubject, inputDataStoreKey, inputDataStoreValue)

  createOrUpdateFn(payload)
}

const processMultiple = (context, callbacks, props) => {
  const { options } = props
  const { triggers } = options || {}

  if (triggers){
    triggers.forEach(({ key }) => {
      // eslint-disable-next-line no-use-before-define
      const triggerFn = triggerFunction(key, context, callbacks, props)
      if (triggerFn) triggerFn()
    })
  }
}

const setState = (props) => {
  const { callbacks: { setState: setStateFn }, options } = props
  const { stateKey, stateValue } = options || {}

  if (setStateFn){
    setStateFn({ [stateKey]: stateValue })
  }
}

const acceptDigitalPage = (context, callbacks) => {
  const { digital_page, shareLinkToken } = context
  const { acceptDigitalPage: acceptFn, logAnalyticsEvent } = callbacks

  if (logAnalyticsEvent){
    logAnalyticsEvent({
      date: currentTime(),
      key: 'digital_page_accepted',
      meta: { id: digital_page.id },
    })
  }

  acceptFn(shareLinkToken)
}

const triggerFunction = (trigger, context, callbacks, props) => {
  const { onClick } = callbacks || {}

  if (onClick) return onClick
  if (trigger === 'acceptDigitalPage') return () => acceptDigitalPage(context, callbacks)
  if (trigger === 'callUser') return () => callUser(context, callbacks)
  if (trigger === 'downloadAttachment') return () => downloadAttachment(context, callbacks)
  if (trigger === 'downloadQuote') return () => downloadQuote(context, callbacks)
  if (trigger === 'emailUser') return () => emailUser(context, callbacks)
  if (trigger === 'multiple') return () => processMultiple(context, callbacks, props)
  if (trigger === 'setState') return () => setState(props)
  if (trigger === 'toggleCarousel') return () => toggleCarousel(props)
  if (trigger === 'updateDataStore') return () => updateDataStore(props)

  return callbacks[trigger]
}

const getValue = (customizingTemplate, id, pageContext, rawValue, contextData) => {
  if (customizingTemplate){
    return rawValue
  }

  return replaceCustomData(id, pageContext) || replaceTextShortCodes(rawValue, contextData)
}

const defaultState = {
  icon: null,
}

const Button = (props) => {
  const {
    callbacks: componentCallbacks, context: componentContext, id, options,
  } = props

  const {
    buttonStyle,
    href,
    icon: iconValue,
    iconMargin,
    iconPosition,
    renderConditions,
    text,
    textProps,
    trigger,
    ...buttonOptions
  } = options || {}

  const [state, setState] = useSetState(defaultState)
  const { icon } = state

  const pageContext = useContext(PageContext)
  const {
    callbacks,
    callbacks: { logAnalyticsEvent },
    customizingTemplate,
    digitalPage,
    isShareLink,
    shareLinkToken,
    shortcodeData,
  } = pageContext || {}

  const pdfDownloadContext = useContext(PdfDownloadContext)
  const { callbacks: pdfDownloadCallbacks } = pdfDownloadContext
  const { buildPdf } = pdfDownloadCallbacks || {}

  const mergedCallbacks = {
    ...callbacks,
    ...componentCallbacks,
    buildPdf: () => logAnalyticsEvent({ date: currentTime(), key: 'download_pdf' }, () => buildPdf(digitalPage, { token: shareLinkToken })),
  }

  const contextData = { ...shortcodeData, ...componentContext, shareLinkToken }

  // Link Value
  const link = getValue(customizingTemplate, id, pageContext, href, contextData)

  // Button Text Value
  const buttonText = getValue(customizingTemplate, id, pageContext, text, contextData)

  const style = useDeviceStyle(props)

  useEffect(() => {
    if (iconValue) getIcon(iconValue, setState, isShareLink)
  }, [iconValue])

  const onClick = triggerFunction(trigger, contextData, mergedCallbacks, props)

  // Should Render
  const shouldRender = shouldRenderPageItem(renderConditions, contextData, options)
  if (!shouldRender) return null

  const disabled = digObject(componentContext, 'buttonDisabled', false)
  const loading = digObject(componentContext, 'buttonLoading', false)

  return (
    <BaseButton
      as="a"
      buttonStyle={buttonStyle}
      disabled={disabled}
      flexDirection={iconPosition === 'right' ? 'row-reverse' : 'row'}
      flexGap="100px"
      href={onClick ? null : link}
      icon={icon && <FontAwesomeIcon icon={icon} />}
      iconMargin={iconMargin}
      loading={loading}
      onClick={!disabled ? onClick : null}
      style={style}
      {...buttonOptions}
    >
      {buttonText}
    </BaseButton>
  )
}

Button.propTypes = {
  data: PropTypes.object,
  options: PropTypes.object,
  style: PropTypes.object,
}

Button.defaultProps = {
  data: {},
  options: {},
}

export default Button
