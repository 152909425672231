export default {
  default: {
    toast: {
      agreement: {
        created: 'Agreement Created.',
        voided: 'Agreement Voided.',
      },
    },
  },
}
