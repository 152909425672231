export default {
  default: {
    buttons: {
      create: 'Create Team',
      save: 'Save',
    },
    formFieldLabels: {
      moreOptions: 'More Options',
      searchTeams: 'Search Teams',
    },
    noticeBox: {
      title: 'Search Teams',
      paragraph: 'Teams assigned to your account can be searched above. If you have not created any yet click the button below',
    },
    sideBarModalHeader: {
      title: 'Manage',
      titleSecondLine: 'Team',
    },
  },
}
