export default {
  default: {
    availableCaseStudies: 'Available Case Studies',
    blankState: {
      paragraph: 'To import case studies from another platform you’ll need to set up a suitable integration. Integrations can be managed in your client admin.',
    },
    deselectAllButton: 'Deselect All',
    importCaseStudiesIntoLabel: 'Importing Case Studies into',
    importCaseStudiesButton: 'Import Case Studies',
    moreOptionsLabel: 'More Options',
    noticeBox: {
      title: 'Nothing to Import',
      paragraph: 'No Case Studies Available. Create a case study in {integrationPlatformTitle} for it to be made available here.',
    },
    platformLabel: 'Platform',
    searchCaseStudiesLabel: 'Search Case Studies',
    selectAllButton: 'Select All',
    selectCaseStudiesLabel: 'Select Case Studies',
    title: 'Import',
    titleSecondLine: 'Case Studies',
    options: 'Please Select...',
    toast: {
      importCaseStudies: 'Import Case Studies Queued',
    },
  },
}
