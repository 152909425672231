import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons'

import { BlankState, Button } from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'

import BlankStateImageUrl from './assets/no-templates.svg'
import localizedStrings from './localizedStrings'

const TemplateBlankState = (props) => {
  const { digitalTemplateType } = props

  const { strings } = useLocalization(localizedStrings)

  return (
    <BlankState
      boxProps={{ flexDirection: 'column-reverse' }}
      contentProps={{ width: '100%', marginTop: 'large' }}
      forceMobile
      imageUrl={BlankStateImageUrl}
      sidebar
    >
      <BlankState.Title>{strings.blankState?.title || 'No Templates'}</BlankState.Title>

      <BlankState.Paragraph>
        {strings.blankState?.firstParagraph || 'Please contact support to get a'} {digitalTemplateType}{' '}
        {strings.blankState?.secondParagraph || 'template created or assigned to your account.'}
      </BlankState.Paragraph>

      <BlankState.Actions>
        <Button
          as="a"
          buttonStyle="secondaryUtility"
          ghost
          href="#/templates"
          icon={<FontAwesomeIcon icon={faInfoCircle} />}
          size="medium"
        >
          {strings.button || 'Customise Templates'}
        </Button>
      </BlankState.Actions>
    </BlankState>
  )
}

TemplateBlankState.propTypes = {
  digitalTemplateType: PropTypes.string.isRequired,
}

export default TemplateBlankState
