import React from 'react'
import PropTypes from 'prop-types'

const VimeoPlayer = (props) => {
  const { videoId, width, height } = props

  return (
    <iframe
      align="top"
      allowFullScreen
      frameBorder="0"
      height={height}
      mozallowfullscreen="true"
      src={`https://player.vimeo.com/video/${videoId}?title=0&byline=0&portrait=0`}
      title="Video"
      webkitallowfullscreen="true"
      width={width}
    />
  )
}

VimeoPlayer.propTypes = {
  videoId: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
}

export default VimeoPlayer
