export default {
  default: {
    blankState: {
      building: {
        button: 'Please Wait...',
        paragraphOne: 'Have a quick break while we build your document. Larger, more image dense files require compiling to produce an optimized file that can be printed locally or by one of your print suppliers.',
        paragraphTwo: 'Please wait while we compile your PDF in the background. It should only take a few moments. If you experience any issues please contact support.',
      },
      buildComplete: {
        button: 'Download PDF',
        formFieldLabel: 'File Url',
        paragraph: 'Congratulations. Your PDF is now available for download at the following url, or by clicking the download button below.',
      },
    },
    header: {
      building: {
        title: 'Building',
        titleSecondLine: 'PDF',
      },
      buildComplete: {
        title: 'PDF',
        titleSecondLine: 'Ready',
      },
    },
  },
}
