import { useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'

import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCog, faDownload, faEye, faPencil, faPlus, faReply,
} from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, LoadingModule, PageHeader,
} from '@campaignhub/suit-theme'

import ToggleTargetDeviceButton from '@components/ToggleTargetDeviceButton'

import PdfDownloadContext from '@contexts/pdfDownloadContext'

import useCurrentUser from '@hooks/useCurrentUser'
import useDigitalPage from '@hooks/useDigitalPage'
import useSessionLock from '@hooks/useSessionLock'
import useSessionLocks from '@hooks/useSessionLocks'

import EditorCanvas from '../EditorCanvas'
import PreviewCanvas from '../PreviewCanvas'

const handleGoBack = (params) => {
  const { deleteUnusedDigitalTemplateComponents } = params

  deleteUnusedDigitalTemplateComponents()

  window.location.href = '#/digitalTemplates/template-list'
}

const PageContent = (props) => {
  const { digitalEditorPayload, digitalTemplateId } = props
  const {
    callbacks: {
      addItem,
      deleteUnusedDigitalTemplateComponents,
      editDigitalTemplateSettings,
      setTargetDevice,
      showSessionLockedModal,
      togglePreviewMode,
    },
    digitalTemplate,
    digitalTemplatePage,
    isMobileDevice,
    loading,
    previewing,
    sandboxDigitalPageId,
    targetDevice,
  } = digitalEditorPayload

  const { digitalPage } = useDigitalPage({ id: sandboxDigitalPageId })

  const pdfDownloadContext = useContext(PdfDownloadContext)
  const {
    callbacks: { buildPdf },
  } = pdfDownloadContext

  const { currentUser } = useCurrentUser()

  const sessionLocksPayload = useSessionLocks({
    filters: {
      excludeUnlocked: true,
      subjectId: digitalTemplateId,
      subjectType: 'DigitalTemplate',
    },
    limit: 1,
    performHttpRequests: true,
  })
  const { loaded, recentSessionLock } = sessionLocksPayload

  const sessionLockPayload = useSessionLock(recentSessionLock)
  const {
    callbacks: { createSessionLock },
    sessionLockedByCurrentUser,
  } = sessionLockPayload

  const loadCompleted = !!digitalTemplatePage.id && loaded && !sessionLockedByCurrentUser

  useEffect(() => {
    if (loadCompleted && !recentSessionLock.id){
      createSessionLock({
        locked_by_user_id: currentUser.id,
        subject_id: digitalTemplate.id,
        subject_type: 'DigitalTemplate',
      })
    }
    if (loadCompleted && recentSessionLock.id){
      showSessionLockedModal({ sessionLock: recentSessionLock })
    }
    if (!isMobileDevice && digitalTemplate.id && sessionLockedByCurrentUser){
      editDigitalTemplateSettings()
    }
  }, [digitalTemplatePage.id, recentSessionLock.id, sessionLockedByCurrentUser])

  return (
    <>
      <Helmet>
        <title>{`Template #${digitalTemplateId}`} | Engage</title>
      </Helmet>

      <PageHeader
        actionContent={(
          <>
            {!isMobileDevice && !previewing && (
              <Button
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faPlus} />}
                marginLeft="small"
                onClick={() => addItem()}
                size="medium"
                width="auto"
              >
                Add Item
              </Button>
            )}

            {!previewing && (
              <>
                <Button
                  buttonStyle="secondaryUtility"
                  height={37}
                  icon={<FontAwesomeIcon icon={faCog} />}
                  marginLeft="small"
                  onClick={() => editDigitalTemplateSettings()}
                  size="medium"
                  width="auto"
                >
                  {!isMobileDevice && 'Settings'}
                </Button>

                <Button
                  buttonStyle="primaryCreate"
                  onClick={() => handleGoBack({ deleteUnusedDigitalTemplateComponents })}
                  icon={<FontAwesomeIcon icon={faReply} />}
                  marginLeft="small"
                  size="medium"
                  width="auto"
                >
                  Save & Go Back
                </Button>
              </>
            )}

            {previewing && (
              <>
                <ToggleTargetDeviceButton
                  boxProps={{ width: 'calc(50% - 4px)' }}
                  callbacks={{ setTargetDevice }}
                  targetDevice={targetDevice}
                />

                <Button
                  buttonStyle="secondaryUtility"
                  icon={<FontAwesomeIcon icon={faDownload} />}
                  marginLeft="small"
                  onClick={() => buildPdf(digitalPage)}
                  size="medium"
                  width="auto"
                >
                  PDF
                </Button>
              </>
            )}

            <Button
              buttonStyle="primaryEdit"
              icon={<FontAwesomeIcon icon={previewing ? faPencil : faEye} />}
              marginLeft="small"
              onClick={() => togglePreviewMode()}
              size="medium"
              width="auto"
            >
              {previewing ? 'Edit' : 'Preview'}
            </Button>
          </>
        )}
        boxProps={{ justifyContent: 'flex-start' }}
        offset={{ left: 0, top: 0 }}
        title={`Template #${digitalTemplateId}`}
        width={isMobileDevice || previewing ? '100%' : 'calc(100% - 375px)'}
      />

      <Box flexDirection="column" justifyContent="center" marginTop="large" paddingTop={70} paddingX="large">
        <LoadingModule loading={loading} times={3} />

        {digitalTemplateId && !loading && !previewing && <EditorCanvas digitalEditorPayload={digitalEditorPayload} />}

        {digitalTemplateId && !loading && previewing && <PreviewCanvas digitalEditorPayload={digitalEditorPayload} />}
      </Box>
    </>
  )
}

PageContent.propTypes = {
  digitalEditorPayload: PropTypes.shape({
    callbacks: PropTypes.shape({
      addItem: PropTypes.func,
      deleteUnusedDigitalTemplateComponents: PropTypes.func,
      editDigitalTemplateSettings: PropTypes.func,
      manageDigitalTemplateAssets: PropTypes.func,
      setTargetDevice: PropTypes.func,
      showSessionLockedModal: PropTypes.func,
      togglePreviewMode: PropTypes.func,
    }),
    digitalTemplate: PropTypes.object,
    digitalTemplatePage: PropTypes.object,
    isMobileDevice: PropTypes.bool,
    loading: PropTypes.bool,
    previewing: PropTypes.bool,
    recentSessionLock: PropTypes.object,
    sandboxDigitalPageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    targetDevice: PropTypes.string,
  }).isRequired,
  digitalTemplateId: PropTypes.number,
}

export default PageContent
