import { useSetState, useThunkDispatch } from '@campaignhub/react-hooks'

import * as currentUserActions from '@redux/modules/currentUser'
import * as userActions from '@redux/modules/user'

import { defaultRequestOptions } from './useCurrentUser'

const impersonateUser = (supportToken, dispatch) => {
  const { hydrateCurrentUser: hydrateFn } = currentUserActions
  const { impersonateUser: impersonateFn } = userActions

  return dispatch(impersonateFn(supportToken, defaultRequestOptions)).then((response) => {
    const { success, data } = response
    if (success){
      dispatch(hydrateFn(data.entity))
    }

    window.location.hash = '/'

    return response
  })
}

const unlinkImpersonation = (dispatch) => {
  const { hydrateCurrentUser: hydrateFn } = currentUserActions
  const { unlinkImpersonation: unlinkFn } = userActions

  return dispatch(unlinkFn(defaultRequestOptions)).then((response) => {
    const { success, data } = response
    if (success){
      dispatch(hydrateFn(data.entity))
    }

    window.location.hash = '/'

    return response
  })
}

const defaultState = {
  supportToken: '',
}

function useUserImpersonation(){
  const [state, setState] = useSetState(defaultState)
  const { supportToken } = state

  const dispatch = useThunkDispatch()

  return {
    callbacks: {
      impersonateUser: suppliedToken => impersonateUser(suppliedToken || supportToken, dispatch),
      setState,
      unlinkImpersonation: () => unlinkImpersonation(dispatch),
    },
    supportToken,
  }
}

export default useUserImpersonation
