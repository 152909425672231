import React from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { faGlobe } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Columns, DashboardModule, LoadingModule, PageHeader, Text,
} from '@campaignhub/suit-theme'

import useBrand, { useBrandForm } from '@hooks/useBrand'
import useEntityCustomFieldsForm from '@hooks/useEntityCustomFieldsForm'
import useNumericParams from '@hooks/useNumericParams'
import useOrganizations from '@hooks/useOrganizations'
import useReduxAction from '@hooks/useReduxAction'

import defaultRequestOptions from '@sections/Admin/packs/SystemManager/defaultRequestOptions'

import EntityCustomFields from '@components/EntityCustomFields'
import OrganizationListItem from '@components/OrganizationListItem'

import BrandDetails from '../BrandDetails'

const updateBrand = (entityParams, customFields, updateFn) => {
  updateFn(entityParams, customFields).then(({ success, errors }) => {
    if (!success && errors){
      toast.warn(errors[0])
      return
    }

    toast('Brand has been updated!')
  })
}

const PageContent = () => {
  const { brandId } = useNumericParams()

  useReduxAction(
    'brands',
    'loadBrand',
    {
      ...defaultRequestOptions.brand,
    },
    [brandId],
    {
      dispatchAction: action => action(brandId, { ...defaultRequestOptions.brand }),
      shouldPerformFn: (entityReducer) => {
        const { loadedIds, loading } = entityReducer
        return brandId && !loadedIds.includes(brandId) && !loading
      },
    },
  )

  const { brands } = useSelector(reduxState => reduxState.entities)
  const initBrand = brands[brandId] || {}

  const brandPayload = useBrand(initBrand)
  const {
    brand,
    callbacks: { updateBrand: updateFn },
    loading,
    updating,
    urls: { brandsIndexUrl },
  } = brandPayload

  const useOrganizationsPayload = useOrganizations({ filters: { brand_id: brand.id }, performHttpRequests: true })
  const { filteredOrganizations, hasOrganizations } = useOrganizationsPayload

  const brandForm = useBrandForm(brand)
  const { entityState, saveEnabled } = brandForm

  const customFieldsForm = useEntityCustomFieldsForm(
    brand, // this is the entity
    brand, // this is the owner
    { performHttpRequests: true },
  )

  const {
    callbacks: { buildNestedValues },
  } = customFieldsForm

  return (
    <>
      <PageHeader
        actionContent={(
          <Button
            buttonStyle="secondaryUtility"
            as="a"
            href={brandsIndexUrl}
            size="medium"
          >
            <Box display={['flex', 'none']}>Back</Box>
            <Box display={['none', 'flex']}>Back to Brands</Box>
          </Button>
        )}
        activeTabBarItemKey="organisation"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        tabBarItems={[
          {
            href: '#/',
            icon: faGlobe,
            key: 'brand',
            title: 'Brand',
          },
        ]}
        title="Brand Details"
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>

            <LoadingModule loading={loading} times={3} />

            {!loading && (
              <>
                <BrandDetails brandForm={brandForm} />

                {hasOrganizations && (
                  <DashboardModule title="Organizations">
                    <Box flexDirection="column" maxHeight={300} overflow="auto">
                      {filteredOrganizations.map(organization => (
                        <OrganizationListItem key={organization.id} organization={organization} />
                      ))}
                    </Box>
                  </DashboardModule>
                )}

                <EntityCustomFields customFieldsForm={customFieldsForm} />

                <Box
                  variant="white"
                  padding="large"
                  style={{ alignItems: 'center', justifyContent: 'space-between', marginBottom: 'large' }}
                >
                  <Text color="bodyFontLightColor" display={['none', 'block']} fontSize="small">
                    Save Brand Details
                  </Text>

                  <Box width={['100%', 175]}>
                    <Button
                      buttonStyle="primaryCreate"
                      disabled={!saveEnabled}
                      loading={updating}
                      onClick={() => updateBrand(entityState, buildNestedValues(), updateFn)}
                      size="medium"
                      style={{ width: '100%' }}
                    >
                      Save Details
                    </Button>
                  </Box>
                </Box>
              </>
            )}
          </Columns.Main>
        </Columns>
      </Box>
    </>
  )
}

export default PageContent
