import { useContext } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'
import { DateTime } from 'luxon'
import swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck, faDatabase, faDownload, faSms, faTimes,
} from '@fortawesome/pro-light-svg-icons'

import { digObject, sortArrayBy } from '@campaignhub/javascript-utils'

import {
  Box, Button, Columns, DashboardModule, Link, LoadingModule, PageHeader, Table, Tools,
} from '@campaignhub/suit-theme'

import PageFilterModule from '@components/PageFilterModule'

import PageContext from '@contexts/pageContext'

import useApiLogs, { generateUrls } from '@hooks/useApiLogs'
import useIntegrationPlatforms from '@hooks/useIntegrationPlatforms'
import useSystem from '@hooks/useSystem'

import events from '../../utils/events'
import ApiLogsBlankState from '../ApiLogsBlankState'

const buildPageFilterFields = (entities) => {
  const style = {
    marginBottom: 16,
  }

  const boxProps = {
    marginBottom: 'large',
  }

  const { integrationPlatforms } = entities
  const integrationPlatformsArray = Object.values(integrationPlatforms)
  const sortedIntegrationPlatforms = sortArrayBy(integrationPlatformsArray, 'asc', 'key')

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: 'All',
        inputComponent: 'select',
        style,
      },
      key: 'event',
      label: 'Filter by Event',
      values: events,
    },
    {
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: 'All',
        inputComponent: 'select',
        style,
      },
      key: 'success',
      label: 'Filter by Success',
      values: [
        { key: 'true', label: 'Yes' },
        { key: 'false', label: 'No' },
      ],
    },
    {
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: 'All',
        inputComponent: 'select',
        style,
      },
      key: 'integration_platform_id',
      label: 'Filter by Integration Platform',
      values: sortedIntegrationPlatforms.map(platform => ({ key: platform.id, label: platform.title })),
    },
    {
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: 'All',
        inputComponent: 'select',
        style,
      },
      key: 'owner_type',
      label: 'Owner Type',
      values: [
        { key: 'Brand', label: 'Brand' },
        { key: 'Organization', label: 'Organization' },
        { key: 'System', label: 'System' },
        { key: 'User', label: 'User' },
      ],
    },
    {
      callbacks: {
        shouldRender: state => state.owner_type === 'Brand',
      },
      component: 'EntitySelector',
      componentProps: {
        entityKey: 'brands',
        boxProps,
      },
      key: 'owner_id',
    },
    {
      callbacks: {
        shouldRender: state => state.owner_type === 'Organization',
      },
      component: 'EntitySelector',
      componentProps: {
        entityKey: 'organizations',
        boxProps,
      },
      key: 'owner_id',
    },
    {
      callbacks: {
        shouldRender: state => state.owner_type === 'User',
      },
      component: 'EntitySelector',
      componentProps: {
        entityKey: 'users',
        entityTitleKey: 'full_name',
        boxProps,
      },
      key: 'owner_id',
    },
    {
      entityKey: 'limit',
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: '100',
        inputComponent: 'select',
        style,
      },
      key: 'limit',
      label: 'Limit',
      values: [
        { key: 150, label: '150' },
        { key: 200, label: '200' },
      ],
    },
  ]
}

const mapApiLogs = (apiLogs, integrationPlatforms) => {
  const mappedLogs = apiLogs.map((apiLog) => {
    const integrationPlatform = integrationPlatforms[apiLog.integration_platform_id] || {}
    const createdDateTime = digObject(apiLog, 'dates.created.date_time_with_timezone')

    return {
      dateCreated: DateTime.fromISO(createdDateTime).toFormat('dd/MM/yy\u00A0T'), // non-breaking space so string cannot be broken over 2 lines
      download: apiLog,
      event: apiLog.event,
      integrationPlatform: integrationPlatform.title,
      method: apiLog.http_method,
      owner: `${apiLog.owner_type}\u00A0#${apiLog.owner_id}`,
      status: apiLog.status_code,
      subject: `${apiLog.subject_type}\u00A0#${apiLog.subject_id}`,
      success: apiLog.success,
      url: apiLog.url,
    }
  })

  return mappedLogs
}

const downloadJSON = (apiLog) => {
  const { id } = apiLog
  const json = JSON.stringify(apiLog)

  const url = window.URL.createObjectURL(new Blob([json]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `api_log_${id}`)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

const renderDownload = apiLog => (
  <Link onClick={() => downloadJSON(apiLog)}>
    <FontAwesomeIcon icon={faDownload} />
  </Link>
)

const renderSuccess = success => <FontAwesomeIcon icon={success ? faCheck : faTimes} />

const columns = [
  { dataKey: 'dateCreated', title: 'Date' },
  { dataKey: 'integrationPlatform', title: 'Integration Platform' },
  { dataKey: 'event', title: 'Event' },
  { dataKey: 'owner', title: 'Owner' },
  { dataKey: 'subject', title: 'Subject' },
  { dataKey: 'method', title: 'Method' },
  { dataKey: 'url', title: 'URL' },
  {
    cellProps: { textAlign: 'center' },
    dataKey: 'status',
    title: 'Status',
  },
  {
    cellProps: { textAlign: 'center' },
    dataKey: 'success',
    render: columnValue => renderSuccess(columnValue),
    title: 'Success',
  },
  {
    cellProps: { textAlign: 'center' },
    dataKey: 'download',
    render: columnValue => renderDownload(columnValue),
    title: 'Download',
  },
]

const confirmDisableSms = (systemParams, updateFn, smsProcessingDisabled) => {
  swal
    .fire({
      title: smsProcessingDisabled ? 'Enable SMS?' : 'Disable SMS',
      text: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: smsProcessingDisabled ? 'Yes, Enable it.' : 'Yes Disable it',
      confirmButtonColor: smsProcessingDisabled ? '#43ac6a' : '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        updateFn(systemParams)
      }
    })
}

const PageContent = (props) => {
  const { pageFilters } = props

  const { entities } = useSelector(reduxState => reduxState)

  const pageContext = useContext(PageContext)
  const {
    callbacks: { resetFilters, updateFilters },
  } = pageContext

  const {
    callbacks: { loadMore },
    canLoadMore,
    filteredApiLogs,
    hasApiLogs,
    loading,
  } = useApiLogs({ filters: pageFilters, performHttpRequests: true })

  const systemPayload = useSystem()
  const {
    callbacks: { updateSystem },
    smsProcessingDisabled,
    system: { id },
  } = systemPayload

  const { integrationPlatforms } = useIntegrationPlatforms()

  const buttonText = smsProcessingDisabled ? 'Enable SMS' : 'Disable SMS'

  const { apiLogsIndexUrlAdmin } = generateUrls()

  return (
    <>
      <Helmet>
        <title>API Logs | Engage</title>
      </Helmet>

      <PageHeader
        activeTabBarItemKey="api-logs"
        boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
        tabBarItems={[
          {
            href: apiLogsIndexUrlAdmin,
            icon: faDatabase,
            key: 'api-logs',
            title: 'API Logs',
          },
        ]}
        title="API Logs"
      />

      <Box flexDirection="column" paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && !hasApiLogs && <ApiLogsBlankState />}

            {!loading && hasApiLogs && (
            <Box flexDirection="column">
              <Box flexDirection="column">
                <Table columns={columns} data={mapApiLogs(filteredApiLogs, integrationPlatforms)} />
              </Box>

              <Box background="white" borderRadius={5}>
                <DashboardModule.LoadMoreFooter
                  callbacks={{ loadMore }}
                  canLoadMore={canLoadMore}
                  entityCount={filteredApiLogs.length}
                  loading={loading}
                />
              </Box>
            </Box>
            )}
          </Columns.Main>

          <Columns.Sidebar>
            <Tools>
              <Button
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faSms} />}
                marginTop="large"
                onClick={() => confirmDisableSms(
                  {
                    id,
                    sms_processing_disabled: !smsProcessingDisabled,
                  },
                  updateSystem,
                  smsProcessingDisabled,
                )}
                size="medium"
              >
                {buttonText}
              </Button>
            </Tools>

            <PageFilterModule
              callbacks={{ resetFilters, updateFilters }}
              filterFields={buildPageFilterFields(entities)}
              pageFilters={pageFilters}
              title="Filters"
            />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

PageContent.propTypes = {
  pageFilters: PropTypes.object.isRequired,
}

export default PageContent
