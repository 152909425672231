import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/pro-light-svg-icons'
import { faShare } from '@fortawesome/pro-solid-svg-icons'

import { Box, Button } from '@campaignhub/suit-theme'

import PdfDownloadContext from '@contexts/pdfDownloadContext'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const HeaderActionButtons = (props) => {
  const {
    boxProps,
    digitalPagePayload: {
      callbacks: { manageShareLinks },
      digitalPage,
    },
  } = props

  const pdfDownloadContext = useContext(PdfDownloadContext)
  const { callbacks: { buildPdf } } = pdfDownloadContext

  const { strings } = useLocalization(localizedStrings)

  return (
    <Box flexDirection="row" {...boxProps}>
      <Button
        buttonStyle="secondaryUtility"
        onClick={() => buildPdf(digitalPage)}
        icon={<FontAwesomeIcon icon={faDownload} />}
        size="medium"
        style={{ marginRight: 4, width: 'calc(50% - 4px)' }}
      >
        {strings.downloadButton}
      </Button>

      <Button
        buttonStyle="primaryEdit"
        icon={<FontAwesomeIcon icon={faShare} />}
        onClick={manageShareLinks}
        size="medium"
        style={{ marginLeft: 4, width: 'calc(50% - 4px)' }}
      >
        {strings.shareButton}
      </Button>
    </Box>
  )
}

HeaderActionButtons.propTypes = {
  boxProps: PropTypes.object,
  digitalPagePayload: PropTypes.object.isRequired,
}

export default HeaderActionButtons
