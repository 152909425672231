import React from 'react'
import classnames from 'classnames'
import {
  ButtonBack, ButtonNext, CarouselProvider, DotGroup,
} from 'pure-react-carousel'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-light-svg-icons'

import useDeviceStyle from '@hooks/useDeviceStyle'

import useCarousel from '../hooks/useCarousel'

import ModalController from '../ModalController'
import SlidesRenderer from '../SlidesRenderer'

import styles from './styles.module.scss'

const wrapComponent = (params, children) => {
  const { id, isModal, toggleCarousel } = params

  if (isModal){
    return (
      <ModalController callbacks={{ closeModal: () => toggleCarousel(id) }}>
        {children}
      </ModalController>
    )
  }

  // Inline
  return children
}

const WrappedCarousel = (props) => {
  const {
    callbacks, children, customizingTemplate, editing, id, modalIsOpen, options, shortcodeData,
  } = props

  const { toggleCarousel } = callbacks || {}

  const style = useDeviceStyle(props)

  const carouselPayload = useCarousel(id, options)
  const {
    autoplay,
    callbacks: carouselCallbacks,
    dataLoopValue,
    height,
    isModal,
    slideCount,
    visibleSlides,
    width,
  } = carouselPayload

  if (isModal && !modalIsOpen) return null

  if (customizingTemplate || editing){
    return (
      <div style={style}>
        {React.Children.map(children, (child) => {
          const itemType = child?.props?.item_type

          if (itemType === 'dataArrayLoop'){
            return React.cloneElement(child, {
              options: {
                ...child.options,
                limit: 1,
              },
            })
          }

          return child
        })}
      </div>
    )
  }

  return wrapComponent(
    { id, isModal, toggleCarousel },
    <div style={style}>
      <CarouselProvider
        className={styles.carousel}
        infinite
        isIntrinsicHeight
        isPlaying={autoplay}
        naturalSlideWidth={width}
        naturalSlideHeight={height}
        totalSlides={slideCount}
        visibleSlides={visibleSlides}
      >
        {isModal && (
          <ButtonBack className={classnames(styles.navigationButton, styles.left)}>
            <span className={styles.inner}>
              <FontAwesomeIcon icon={faAngleLeft} />
            </span>
          </ButtonBack>
        )}

        <SlidesRenderer
          callbacks={carouselCallbacks}
          dataLoopValue={dataLoopValue}
          id={id}
          shortcodeData={shortcodeData}
        >
          {children}
        </SlidesRenderer>

        <DotGroup className={classnames(styles.dotGroup, modalIsOpen ? styles.modal : null)} />

        {isModal && (
          <ButtonNext className={classnames(styles.navigationButton, styles.right)}>
            <span className={styles.inner}>
              <FontAwesomeIcon icon={faAngleRight} />
            </span>
          </ButtonNext>
        )}
      </CarouselProvider>
    </div>,
  )
}

export default WrappedCarousel
