import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, DashboardModule, Link,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'
import useReduxAction from '@hooks/useReduxAction'
import useTeams from '@hooks/useTeams'
import { generateUrls } from '@hooks/useTeam'

import defaultRequestOptions from '@sections/Client/packs/Admin/defaultRequestOptions'

import Team from './Team'
import localizedStrings from './localizedStrings'

const Teams = (props) => {
  const { selectedUserId } = props

  const entityKey = `User${selectedUserId}`

  useReduxAction(
    'teams',
    'loadTeams',
    {
      ...defaultRequestOptions.team,
      include_image_sizes: ['thumb_200x120'],
      entityKey,
      user_id: selectedUserId,
    },
    [selectedUserId],
    { shouldPerformFn: ({ loadedForKeys }) => selectedUserId && !loadedForKeys.includes(entityKey) },
  )

  const filterOptions = {
    user_id: selectedUserId,
  }

  const { teamsIndexUrl } = generateUrls()

  const { filteredTeams, hasTeams, loading } = useTeams({ filters: filterOptions })

  const { strings } = useLocalization(localizedStrings)

  return (
    <DashboardModule
      boxProps={{ marginBottom: 'large' }}
      headerRight={hasTeams && (
      <Link href={teamsIndexUrl} textProps={{ fontSize: 'small' }}>
        {strings.manage || 'Manage'}
      </Link>
      )}
      loading={loading}
      title={strings.title || 'Teams'}
    >
      <Box flexDirection="column">
        <Box flexDirection="column" maxHeight={415} overflow="auto">
          {filteredTeams.map(team => (
            <Team key={team.id} team={team} />
          ))}
        </Box>

        {!hasTeams && (
        <Button
          as="a"
          buttonStyle="secondaryUtility"
          href={teamsIndexUrl}
          icon={<FontAwesomeIcon icon={faCog} />}
          size="medium"
          style={{ margin: 16, width: 'calc(100% - 32px)' }}
        >
          {strings.ManageButton || 'Manage Teams'}
        </Button>
        )}
      </Box>
    </DashboardModule>
  )
}

Teams.propTypes = {
  selectedUserId: PropTypes.number,
}

export default Teams
