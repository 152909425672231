export default {
  default: {
    agentsAdvice: 'Agents Advice',
    buttons: {
      addComparable: 'Add Comparable',
      added: 'Added',
      remove: 'Remove',
    },
    distance: 'km away',
    undisclosed: ' - Undisclosed',
    toastText: {
      cloned: 'Comparable Cloned Successfully',
    },
  },
}
