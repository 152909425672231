import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/pro-solid-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'

import {
  Box, Button, FormField, Link, Text,
} from '@campaignhub/suit-theme'

const Fields = (props) => {
  const {
    customFieldForm,
    customFieldForm: { entityState },
    customFieldPayload: { callbacks },
  } = props

  const { createOrEditNestedField } = callbacks || {}

  const fields = digObject(entityState, 'data.fields', [])

  return (
    <Box flexDirection="column" marginBottom="large">
      {!!fields.length && (
        <FormField boxProps={{ marginBottom: 'medium' }} direction="column" label="Fields">
          {fields.map((field) => {
            const {
              key, input_type, field_type, label,
            } = field

            return (
              <Box
                alignItems="center"
                color="bodyFontColor"
                fontSize="small"
                justifyContent="space-between"
                key={key}
                marginBottom="medium"
                padding="medium"
                variant="whiteGrey"
              >
                <Box flexDirection="row">
                  <Text marginRight="small" variant="ellipsis" marginRight="auto">
                    {label}
                  </Text>

                  <Text variant="ellipsis">{field_type}</Text>

                  {input_type && (
                    <Text color="bodyFontLightColor" fontSize="small" marginLeft="large" variant="ellipsis">
                      {input_type}
                    </Text>
                  )}
                </Box>

                <Link
                  greyLink
                  onClick={() => createOrEditNestedField({ customFieldForm, field })}
                  textProps={{ marginLeft: 'large' }}
                >
                  <FontAwesomeIcon icon={faPencil} />
                </Link>
              </Box>
            )
          })}
        </FormField>
      )}

      <Button
        buttonStyle="ghostCreate"
        onClick={() => createOrEditNestedField({ customFieldForm, field: {} })}
        size="medium"
      >
        Add
      </Button>
    </Box>
  )
}

export default Fields
