import React from 'react'
import PropTypes from 'prop-types'
import copy from 'copy-to-clipboard'
import { toast } from 'react-toastify'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChartBar, faLink, faSignalSlash,
} from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Image, SidebarModal,
} from '@campaignhub/suit-theme'

import useLocalization from '@hooks/useLocalization'
import useShareLink from '@hooks/useShareLink'

import localizedStrings from './localizedStrings'

const copyShareLinkUrl = (shareLinkUrl, strings) => {
  copy(shareLinkUrl)
  toast(strings.toast || 'Share Link Url Copied to Clipboard')
}

const ShareLink = (props) => {
  const { isFirst, shareLink } = props
  const { title, tracking_enabled, views } = shareLink

  const {
    callbacks: { editShareLink },
    shareLinkUrl,
  } = useShareLink(shareLink)

  const { callbacks: { formatCount }, strings } = useLocalization(localizedStrings)

  const trackingStatus = tracking_enabled
    ? strings.trackingEnabled || 'Tracking Enabled'
    : strings.trackingDisabled || 'Tracking Disabled'

  return (
    <Box
      border="1px solid"
      borderColor="lineColor"
      borderRadius="5px"
      flexDirection="column"
      marginTop={isFirst ? 'none' : 'large'}
    >
      <SidebarModal.Button
        boxProps={{
          border: 'none',
          borderBottom: '1px solid',
          borderBottomColor: 'lineColor',
          borderRadius: 'none',
          marginBottom: 'none',
          paddingY: ['medium', 'large'],
        }}
        iconLeft={(
          <Image
            boxProps={{ backgroundColor: 'hoverLightGrey' }}
            circle
            width={35}
          >
            <FontAwesomeIcon icon={tracking_enabled ? faChartBar : faSignalSlash} />
          </Image>
        )}
        onClick={() => editShareLink()}
        showAngleIcon
        subtitle={
          tracking_enabled
            ? `${trackingStatus} | ${formatCount('viewCount', { count: views })}`
            : `${trackingStatus}`
        }
        title={title || strings.untitled || 'Untitled Link'}
      />

      <Box padding="medium">
        <Button
          buttonStyle="secondaryEdit"
          icon={<FontAwesomeIcon icon={faLink} />}
          onClick={() => copyShareLinkUrl(shareLinkUrl, strings)}
          size="medium"
        >
          {strings.copyLink || 'Copy Link'}
        </Button>
      </Box>
    </Box>
  )
}

ShareLink.propTypes = {
  isFirst: PropTypes.bool,
  shareLink: PropTypes.object.isRequired,
}

export default ShareLink
