import PropTypes from 'prop-types'
import queryString from 'query-string'

import {
  Box, FileUploadZone, FormField, Image,
} from '@campaignhub/suit-theme'

import useUppyImageUpload from '@hooks/useUppyImageUpload'

import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

import ReplaceImage from './ReplaceImage'

import placeHolderImage from './assets/placeholderImage.png'

const ModalImageUpload = (props) => {
  const {
    imageLabel,
    imageSizes,
    imageType,
    imageUrl,
    onUpload,
    ownerId,
    ownerType,
    subjectId,
    subjectType,
  } = props

  const string = imageSizes.length
    ? queryString.stringify({ include_image_sizes: imageSizes }, { arrayFormat: 'bracket' })
    : ''

  const options = {
    endpoint: `images.json?${string}`,
    image_type: imageType,
    owner_id: ownerId,
    owner_type: ownerType,
    subject_id: subjectId,
    subject_type: subjectType,
  }

  const uppy = useUppyImageUpload(options, [imageType, subjectId, subjectType], onUpload)

  const { strings } = useLocalization(localizedStrings)

  const label = imageUrl ? imageLabel : `${strings.label} ${imageLabel}`

  return (
    <Box flexDirection="column" label={imageLabel} marginTop="large">
      <FormField label={label}>
        {!imageUrl && <FileUploadZone uppy={uppy} />}

        {!!imageUrl && (
          <Box backgroundImage={`url(${placeHolderImage})`} borderRadius={5}>
            <Image borderRadius={5} height="180px" url={imageUrl} />
          </Box>
        )}
      </FormField>
    </Box>
  )
}

ModalImageUpload.propTypes = {
  imageLabel: PropTypes.string.isRequired,
  imageSizes: PropTypes.array,
  imageType: PropTypes.string,
  imageUrl: PropTypes.string,
  onUpload: PropTypes.func,
  ownerId: PropTypes.number.isRequired,
  ownerType: PropTypes.string.isRequired,
  subjectId: PropTypes.number,
  subjectType: PropTypes.string.isRequired,
}

ModalImageUpload.defaultProps = {
  imageSizes: [],
}

ModalImageUpload.ReplaceImage = ReplaceImage

export default ModalImageUpload
