import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-light-svg-icons'

import { Button } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import { shouldRenderPageItem } from '@functions/digitalTemplatePageItem'

import useDeviceStyle from '@hooks/useDeviceStyle'
import useDigitalTemplatePageSection from '@hooks/useDigitalTemplatePageSection'

import ToggleableSection from '@components/digitalRenderer/components/ToggleableSection'

import ContentHolder from '../../ContentHolder'
import ElementContainer from '../../ElementContainer'

import styles from './styles.module.scss'

const wrapContent = (disableContentHolder, contentWidthId, children) => {
  if (disableContentHolder) return children

  return <ContentHolder contentWidthId={contentWidthId}>{children}</ContentHolder>
}

const Section = (props) => {
  const {
    callbacks,
    callbacks: { selectCanvasItem },
    context: componentContext,
    index,
    isSidebar,
    section: initSection,
    sectionGroup,
  } = props

  const sectionPayload = useDigitalTemplatePageSection(initSection)
  const {
    digitalTemplatePageSection,
    digitalTemplatePageItems,
    editorEntity,
    options,
  } = sectionPayload

  const {
    anchor,
    autoscaled,
    autoscaleOffset,
    contentWidthId,
    disableContentHolder,
    loopLimit,
    renderConditions,
    toggleable,
  } = options

  const style = useDeviceStyle(digitalTemplatePageSection)

  const pageContext = useContext(PageContext)
  const {
    callbacks: { selectTemplateSection, viewSourceTemplateSections },
    customizingTemplate,
    explodeSections,
    shortcodeData,
  } = pageContext || {}

  const contextData = { ...shortcodeData, ...componentContext }

  // Should Render
  const shouldRender = shouldRenderPageItem(renderConditions, contextData, options)
  if (!shouldRender) return null

  return (
    <ElementContainer
      callbacks={callbacks}
      itemEntities={digitalTemplatePageItems}
      mergeItemOptions={autoscaled ? { offset: autoscaleOffset * loopLimit } : null}
      section={digitalTemplatePageSection}
      renderNested
      render={({ itemComponents }) => (
        <ToggleableSection isSidebar={isSidebar} isToggleable={toggleable} sectionId={digitalTemplatePageSection.id}>
          <div
            id={anchor}
            className={classnames(
              styles.root,
              customizingTemplate ? styles.customizingTemplate : null,
              explodeSections ? styles.explodeSections : null,
            )}
            onClick={selectCanvasItem ? e => selectCanvasItem(editorEntity, e) : null}
            style={style}
          >
            {wrapContent(
              disableContentHolder,
              contentWidthId,
              itemComponents.map(component => component),
            )}

            {explodeSections && (
              <div className={styles.selectSection}>
                <Button
                  buttonStyle="primaryEdit"
                  icon={<FontAwesomeIcon icon={faPlus} />}
                  onClick={() => selectTemplateSection(digitalTemplatePageSection)}
                  size="medium"
                  width="auto"
                >
                  Select Section
                </Button>
              </div>
            )}

            {customizingTemplate && (
              <a
                className={styles.addSection}
                onClick={(e) => {
                  e.stopPropagation()
                  viewSourceTemplateSections({
                    index: index + 1,
                    sectionGroup,
                  })
                }}
              >
                <div className={styles.iconHolder}>
                  <FontAwesomeIcon icon={faPlus} />
                </div>
              </a>
            )}
          </div>
        </ToggleableSection>
      )}
    />
  )
}

Section.propTypes = {
  callbacks: PropTypes.object.isRequired,
  context: PropTypes.object,
  index: PropTypes.number.isRequired,
  isSidebar: PropTypes.bool,
  section: PropTypes.object.isRequired,
  sectionGroup: PropTypes.object.isRequired,
}

export default Section
