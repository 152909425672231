import videoModulesLibrary, { VideoModulesLibraryType} from '@functions/videoModulesLibrary'

interface OptionsProps {
  key: string,
}

function useVideoModules(options: OptionsProps):VideoModulesLibraryType {
  const { key: videoModuleKey } = options || {}

  const videosPayload = videoModulesLibrary[videoModuleKey] || []

  return {
    videosPayload,
  }
}

export default useVideoModules
