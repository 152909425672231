import { useContext } from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendarPlus, faCheckCircle, faPencil, faTrashAlt,
} from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, FormField, SidebarModal,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import EntitySelector from '@components/EntitySelector'
import NotificationRecipients from '@components/ModalComponents/NotificationRecipients'
import ToggleSection from '@components/ModalComponents/ToggleSection'

import useCurrentUser from '@hooks/useCurrentUser'
import useFormTemplate, { useFormTemplateForm } from '@hooks/useFormTemplate'
import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const confirmDelete = (params) => {
  const {
    closeModal, deleteFormTemplate, deleteFn, strings,
  } = params

  const deleteFormTemplatePayload = {
    callbacks: {
      action: deleteFn,
      afterAction: closeModal,
    },
    shouldRedirect: true,
    toastText: strings.toast?.formDeleted || 'Form Template Deleted Successfully',
  }
  swal
    .fire({
      title: strings?.swal?.title || 'Delete Form?',
      text: strings?.swal?.text || 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: strings?.swal?.confirmButtonText || 'Yes, delete it',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        deleteFormTemplate(deleteFormTemplatePayload)
      }
    })
}

const ManageFormBuilderModal = (props) => {
  const {
    callbacks,
    callbacks: {
      addFormField, closeModal, deleteFormTemplate, updateFormTemplate,
    },
    modalKey,
    showModal,
  } = props

  const pageContext = useContext(PageContext)
  const {
    formTemplatePayload: {
      callbacks: { deleteFormTemplate: deleteFn, updateFormTemplate: updateFn },
      formTemplate,
    },
    isMobile,
  } = pageContext

  const { isAdmin } = useCurrentUser()

  const formTemplateFormPayload = useFormTemplateForm(formTemplate)
  const {
    entityState,
    entityState: {
      notifications_enabled, owner_id, owner_type, production_ready, title,
    },
    handlers,
    loading,
    saveEnabled,
    setEntityState,
    updating,
  } = formTemplateFormPayload

  const { strings } = useLocalization(localizedStrings)

  const updateFormTemplatePayload = {
    callbacks: {
      action: updateFn,
    },
    entityParams: entityState,
    toastText: strings.toast?.formUpdated || 'Form Template Updated Successfully',
  }

  return (
    <SidebarModal
      callbacks={callbacks}
      disableAnimation
      disableOverlay
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      {isMobile && (
        <SidebarModal.Header
          callbacks={callbacks}
          title={strings?.sideBarModalHeader?.title || 'Manage'}
          titleSecondLine={strings?.sideBarModalHeader?.titleSecondLine || 'Form'}
        />
      )}

      <SidebarModal.Content boxProps={{ padding: 0 }} hasHeader={isMobile}>
        <Box flexDirection="column">
          <Box borderBottom="1px solid" borderColor="lineColor" padding="large">
            <Button
              buttonStyle="primaryEdit"
              flexShrink="1"
              icon={<FontAwesomeIcon icon={faCalendarPlus} />}
              marginLeft="small"
              onClick={addFormField}
              size="medium"
            >
              {strings?.button?.addField || 'Add Field'}
            </Button>
          </Box>
        </Box>

        <Box flexDirection="column" padding="large">
          <FormField direction="column" label={strings?.formFieldLabels?.title || '* Title'}>
            <input name="title" type="text" value={title} {...handlers} />
          </FormField>

          {isAdmin && (
            <>
              <FormField
                direction="column"
                label={strings.formFieldLabels?.ownerType || 'Owner Type'}
                marginTop="large"
              >
                <select data-validate-field-on="change" name="owner_type" value={owner_type} {...handlers}>
                  <option value="Brand">{strings.optionValues?.ownerType?.brand || 'Brand'}</option>
                  <option value="Organization">
                    {strings.optionValues?.ownerType?.organization || 'Organization'}
                  </option>
                  <option value="System">{strings.optionValues?.ownerType?.system || 'System'}</option>
                </select>
              </FormField>

              {owner_type !== 'System' && (
                <EntitySelector
                  boxProps={{ marginTop: 'large' }}
                  callbacks={{
                    selectItem: entity => setEntityState({ owner_id: entity ? entity.id : null }),
                  }}
                  entityKey={owner_type === 'Brand' ? 'brands' : 'organizations'}
                  selectedItemId={Number(owner_id)}
                />
              )}
            </>
          )}

          <ToggleSection
            callbacks={{
              toggleFn: () => setEntityState({ notifications_enabled: !notifications_enabled }),
            }}
            enabled={notifications_enabled}
            sectionTitle={strings.notifications?.title || 'Notifications'}
            text={strings.notifications?.text || 'Notify users of new form responses (email)'}
            title={strings.notifications?.title || 'Notifications'}
          />

          {notifications_enabled && (
            <NotificationRecipients
              entity={formTemplate}
              entityForm={formTemplateFormPayload}
              permittedCustomRecipientTypes={['email']}
              useEntityHook={useFormTemplate}
            />
          )}

          <FormField
            direction="column"
            label={strings?.formFieldLabels?.moreOptions || 'More Options'}
            marginTop="large"
          >
            <Button
              buttonStyle="secondaryUtility"
              onClick={() => setEntityState({ production_ready: !production_ready })}
              icon={<FontAwesomeIcon icon={production_ready ? faPencil : faCheckCircle} />}
              loading={loading}
              marginBottom="medium"
              size="medium"
              width="100%"
            >
              {production_ready
                ? strings?.button?.setDraft || 'Set As Draft'
                : strings?.button?.setPublished || 'Set As Published'}
            </Button>

            <Button
              buttonStyle="secondaryUtility"
              onClick={() => confirmDelete({
                closeModal,
                deleteFormTemplate,
                deleteFn,
                strings,
              })}
              icon={<FontAwesomeIcon icon={faTrashAlt} />}
              size="medium"
              width="100%"
            >
              {strings?.button?.delete || 'Delete Form'}
            </Button>
          </FormField>
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={updating}
          onClick={() => updateFormTemplate(updateFormTemplatePayload)}
          size="large"
        >
          {strings?.button?.save || 'Save Form'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

ManageFormBuilderModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

ManageFormBuilderModal.defaultProps = {
  modalKey: 'ManageFormBuilderModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <ManageFormBuilderModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
